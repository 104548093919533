import { faGear } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextField, Typography, Autocomplete, Modal, Box, Checkbox, Drawer, Switch, OutlinedInput } from '@mui/material';
import { Col, Divider, Row } from 'antd';
import React, { useState, useEffect } from 'react';
import { getItems, updateItem } from './utils';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

const containerStyle = {
    width: "100%",
    height: "100%",
};





const TimelineSettingsDrawer = (props) => {
    const { attributes, widgetKey, userInfo, setMadeChanges, item, setItem, updateAttributes, deleteWidget } = props;
    const [cameras, setCameras] = useState([]);

    useEffect(() => {
        getCameras()
    }, []);

    async function getCameras() {
        const cameras = await getItems(userInfo, 'timelapseCameras');
        console.log(cameras)
        if (cameras) {
            setCameras(cameras);
        }
    }

    const _updateItem = async (key, value) => {
        console.log("UPDATE ITEM")
        const response = await updateItem(userInfo, widgetKey, 'timelineWidgets', key, value);

    }








    return (
        <Drawer
            anchor="right"
            open={props.open}
            onClose={props.closeSettings}
            style={{ zIndex: 1000, }}

        >
            <div style={{ width: 500, height: '100%', padding: 20, }}>
                <Row align="middle" style={{ paddingTop: 20 }} gutter={[4, 4]}>
                    <Col span={12}>
                        <Typography variant="h6" style={{ fontWeight: 600, }}>
                            Timeline Settings
                        </Typography>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={props.closeSettings}
                            style={{
                                textTransform: 'none',
                                backgroundColor: '#404050'
                            }}
                        >
                            Save Widget
                        </Button>
                    </Col>
                    <Col span={24} style={{ paddingTop: 5 }}>
                        <Divider style={{ marginTop: 0, marginBotton: 0 }} />
                    </Col>
                    <Col span={24} style={{ paddingTop: 10 }}>
                        <Typography variant="body1" fontSize={16} fontWeight={500}>
                            Widget Title
                        </Typography>
                        <OutlinedInput
                            fullWidth
                            margin="dense"
                            size="small"
                            placeholder="Title"
                            value={item?.title}
                            onChange={(e) => {
                                setMadeChanges(true);
                                attributes.title = e.target.value;
                                _updateItem('title', e.target.value)
                                setItem({ ...item, title: e.target.value });
                            }}
                        />

                    </Col>
                    <Col span={24} style={{ paddingTop: 10 }}>
                        <Typography variant="body1" fontSize={16} fontWeight={500}>
                            Camera
                        </Typography>
                        <Autocomplete
                            size='small'
                            margin="dense"
                            fullWidth
                            id="combo-box-demo"
                            options={cameras}
                            value={cameras.find((camera) => camera?.camera?.id === item?.camera?.id)}
                            getOptionLabel={(option) => option?.name || ''}
                            renderInput={(params) => <TextField {...params} placeholder="Cameras" variant="outlined" />}
                            //change no options to no cameras found
                            noOptionsText="No Cameras Found"
                            onChange={(e, value) => {
                                const camera = {
                                    name: value?.camera?.name,
                                    id: value?.camera?.id,
                                    ucid: value?.camera?.ucid,
                                    cameraId: value?.camera?.cameraId,
                                    itemID: value?.id
                                }
                                setMadeChanges(true);
                                _updateItem('camera', camera)
                                setItem({ ...item, camera });
                                console.log({ ...item, camera })
                            }}
                        />
                    </Col>
                    <Col span={24} style={{ paddingTop: 10 }}>
                        <Typography variant="body1" fontSize={16} fontWeight={500}>
                            Autoplay
                        </Typography>
                        <Switch
                            checked={item?.autoplay}
                            onChange={(e) => {
                                const autoplay = e.target.checked;
                                setMadeChanges(true);
                                _updateItem('autoplay', autoplay)
                                setItem({ ...item, autoplay });
                            }}
                        />
                    </Col>
                    <Col span={24} style={{ paddingTop: 10 }}>
                        <Typography variant="body1" fontSize={16} fontWeight={500}>
                            Autoplay Seconds
                        </Typography>
                        <OutlinedInput
                            size='small'
                            margin="dense"
                            id="combo-box-demo"
                            value={item?.autoplaySeconds}
                            onChange={(e) => {
                                const autoplaySeconds = e.target.value;
                                setMadeChanges(true);
                                _updateItem('autoplaySeconds', autoplaySeconds)
                                setItem({ ...item, autoplaySeconds });
                            }}
                            placeholder="Autoplay Seconds"
                            variant="outlined"
                            type="number"
                        />
                    </Col>
                    <Col span={24} style={{ paddingTop: 10 }}>
                        <Typography variant="body1" fontSize={16} fontWeight={500}>
                            Transparent Background
                        </Typography>
                        <Checkbox
                            checked={item?.transparentBackground}
                            onChange={(e) => {
                                const transparentBackground = e.target.checked;
                                setMadeChanges(true);
                                _updateItem('transparentBackground', transparentBackground)
                                attributes.transparentBackground = transparentBackground;
                                setItem({ ...item, transparentBackground });
                            }}
                        />
                    </Col>
                    <Col span={24} style={{ paddingTop: 10 }}>
                        <Typography variant="body1" fontSize={16} fontWeight={500}>
                            Start Today
                        </Typography>
                        <Checkbox
                            checked={item?.startToday || true}
                            onChange={(e) => {
                                const startToday = e.target.checked;
                                setMadeChanges(true);
                                _updateItem('startToday', startToday)
                                setItem({ ...item, startToday });
                            }}
                        />
                        {item?.startToday === false &&
                            <div>  <Typography variant="body1" fontSize={16} fontWeight={500}>
                                Start Today
                            </Typography>
                                <DatePicker portalId="root-portal" dateFormat="MM/dd/yyyy"
                                    selected={[undefined, '', "Invalid date"].includes(item.startDate) ? new Date() : new Date(moment(item.startDate, 'x'))}
                                    onChange={(date) => {

                                        const startDate = moment(date).format('x');
                                        setMadeChanges(true);
                                        _updateItem('startDate', startDate)
                                        setItem({ ...item, startDate });
                                    }} />
                            </div>
                        }
                    </Col>
                    <Col span={24}>
                        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                    </Col>
                    <Col span={24} style={{ paddingTop: 10 }}>
                        <Button
                            variant="contained"
                            style={{
                                textTransform: 'none',
                                backgroundColor: '#404050',
                                color: 'white',

                            }}
                            onClick={() => {
                                setMadeChanges(false);
                                props.closeSettings();
                                deleteWidget(userInfo, widgetKey);
                            }}
                        >
                            Delete Widget
                        </Button>
                    </Col>
                </Row>
            </div>

        </Drawer>

    )

}

export default TimelineSettingsDrawer;