import React from "react";
import axios from "axios";
import moment from 'moment-timezone';

export const fetchWeather = async (account) => {

   
    const apiKey = 'e60406d8d1152d5b1e8413bb058aa602';
    const proxyurl = "https://mycorslake.herokuapp.com/";
    const url = `https://api.openweathermap.org/data/2.5/forecast?lat=${account?.lat}&lon=${account?.lng}&appid=${apiKey}&units=imperial`;

    //timezone based on lat/lng
    const timezone = await getTimezoneByLatLng(account?.lat, account?.lng);
 

    const weatherAPI = `https://api.weather.gov/points/${account?.lat},${account?.lng}`

   

    try {
        const response2 = await axios.get(proxyurl + weatherAPI);
      

        //get forecast for gridpoint
        const forecastUrl = response2.data.properties.forecast;
        const forecastResponse = await axios.get(proxyurl + forecastUrl);
      
        const forecastPeriods = forecastResponse?.data?.properties?.periods;
     
        const res = calculateDailyWeatherFromWeatherGov(forecastPeriods, forecastResponse?.data?.properties?.timeZone);

        const currentUrl = `${response2?.data?.properties?.forecast}`

        const currentResponse = await axios.get(proxyurl + currentUrl)

   
        const forecastArray = Object.values(res);
        const firstDay = forecastArray.shift();



        return {
            today: firstDay, forecast: forecastArray,
        };
    } catch (error) {
        console.log(error);
        return null;
    }

};






async function getTimezoneByLatLng(lat, lng) {
    const apiKey = 'AIzaSyCK_YL8JW9IlU0bczOrJguMjTj_oa6yfb8'; // Replace with your Google API Key
    const url = `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${Math.floor(Date.now() / 1000)}&key=${apiKey}`;

    try {
        const proxyurl = "https://mycorslake.herokuapp.com/";
        const response = await axios.get(proxyurl + url);
        const data = response.data;
        if (data.status === 'OK') {
            return {
                timeZoneId: data.timeZoneId,
                timeZoneName: data.timeZoneName,
            };
        } else {
            throw new Error(data.status);
        }
    } catch (error) {
        console.error('Error fetching timezone:', error);
        return null;
    }
}


function calculateDailyWeatherFromWeatherGov(forecastPeriods, timeZone) {
    const dailyData = {};

    forecastPeriods.forEach((period, i) => {
        // Extract the day from the start time
        const day = period.startTime.split('T')[0];

        // Initialize the day in the dictionary if it doesn't exist
        if (!dailyData[day]) {
            const isNewDay = i === 0 || day !== forecastPeriods[i - 1].startTime.split('T')[0];
            console.log(isNewDay)
            dailyData[day] = {
                icon: isNewDay ? period.icon : null,
                minTemp: period.temperature,
                maxTemp: period.temperature,
                precipProb: [],
                ///example Mon, Tue, Wed
                day: moment.tz(day, timeZone).format('ddd'),
                forecast: isNewDay ? period.shortForecast : null,
                date: moment.tz(day, timeZone).format('MM/DD/YYYY'),
                isToday: moment.tz(day, timeZone).isSame(moment.tz(timeZone), 'day'),
                isTomorrow: moment.tz(day, timeZone).isSame(moment.tz(timeZone).add(1, 'days'), 'day')
            };
        }

        // Update min and max temperature
        dailyData[day].minTemp = Math.min(dailyData[day].minTemp, period.temperature);
        dailyData[day].maxTemp = Math.max(dailyData[day].maxTemp, period.temperature);

        // Update precipitation probabilities
        if (period.probabilityOfPrecipitation.value !== null) {
            dailyData[day].precipProb.push(period.probabilityOfPrecipitation.value);
        }
    });

    // Calculate the average precipitation probability for each day
    Object.keys(dailyData).forEach(day => {
        const precipProbArray = dailyData[day].precipProb;
        const avgPrecipProb = precipProbArray.length > 0
            ? precipProbArray.reduce((a, b) => a + b, 0) / precipProbArray.length
            : null;
        dailyData[day].avgPrecipProb = avgPrecipProb;
        delete dailyData[day].precipProb; // Remove the array, as we now have the average
    });

    return dailyData;
}



