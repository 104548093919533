import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SearchIcon from '@mui/icons-material/Search';
import { Button, CircularProgress, Divider, Fab, IconButton, Input, InputAdornment, Paper, Typography } from '@mui/material';
import { Col, Popconfirm, Row } from 'antd';
import { collection, doc, getDocs, getFirestore, onSnapshot, query, setDoc, updateDoc, where } from 'firebase/firestore';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import "../../extra/hoverStyle.css";
import LabParameters from '../../extra/labParameters.json';
import Units from '../../extra/units.json';
import ParameterModal from './parameterModal';
import axios, * as others from 'axios';
import queryString from 'query-string';

export default class Parameters extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            data: [],
            filteredData: [],
            filters: [],
            searchText: '',
            loading: true,
            type: this.props.type,
            currentItem: {},
            openModal: false,
            nextPage: '',
            token: '',
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0)
        this.queryData()


    }





    queryData = async () => {
        const { userInfo, type } = this.state;
        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'parameters'));
        const unsubscribe = onSnapshot(queryData, async (querySnapshot) => {
            const data = [];
            querySnapshot.forEach((doc) => {
                if (doc.data().archived === undefined || doc.data().archived === false)
                {
                    data.push({ ...doc.data(), label: doc.data().name })
                }
             
            });
            data.sort((a, b) => a.name.localeCompare(b.name))
            this.setState({ data, loading: false })

            this.filterData(data, '', [])
        });


    }
    uploadData = async (data) => {
        const { userInfo, type } = this.state;
        const db = getFirestore();
        data.map(async (d, i) => {
            const parameterRef = doc(db, 'defaultParameters', d.key);
            await setDoc(parameterRef, d).then(() => {
                console.log(i)
            })
        })

    }
    getDefaults = async () => {
        const { userInfo, type } = this.state;
        const db = getFirestore();
        const queryData = query(collection(db, "defaultParameters"),);
        const data = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            data.push({ ...doc.data(), key: uuid() })


        });
        console.log(data);

        data.map(async (d, i) => {
            const docRef = doc(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'parameters', d.key);
            await setDoc(docRef, d)
            console.log(i)
        })

        data.sort((a, b) => a.name.localeCompare(b.name))
        this.setState({ data, loading: false })

        this.filterData(data, '', [])

    }

    filterData = (data, searchText, filters) => {
        const filteredData = data.filter(d => d.name.match(new RegExp(searchText, "i")))
        this.setState({ filteredData })

    }


    updateCurrentItem = (boo, val) => {
        const { currentItem } = this.state;
        currentItem[boo] = val;
        this.setState({ currentItem })
    }



    render() {

        const { userInfo, account, data, filteredData, loading, searchText, filters, type, currentItem, accounts, openModal, screenWidth, } = this.state;
        const db = getFirestore();

        const CreateItemButton = () => {
            let navigate = useNavigate();

            return (
                <Fab style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="medium"
                    onClick={async () => {
                        this.setState({ openModal: true, currentItem: { tempKey: uuid(), }, })
                    }}>
                    <AddIcon />
                </Fab>
            )
        }

        const EditItem = ({ item }) => {
            let navigate = useNavigate();
            return (
                <IconButton style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="small"
                    onClick={async () => {
                        this.setState({ openModal: true, currentItem: item })
                    }}>
                    <EditIcon />
                </IconButton>
            )
        }

        const DeleteItem = ({ item }) => {
            let navigate = useNavigate();
            return (
                <Popconfirm
                    title="Are you sure to delete this parameter?"
                    onConfirm={async () => {

                        const itemRef = doc(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'parameters', item.key);
                        await updateDoc(itemRef, {
                            archived: true
                        }).then(() => {
                            const newData = filteredData.filter((f) => f.key !== item.key)
                            this.setState({ filteredData: newData })
                            this.props.openSnack('success', 'Parameter removed.')
                        })


                    }}
                    okText="Yes"
                    cancelText="No"
                >
                    <IconButton style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="small"
                    >
                        <DeleteForeverIcon />
                    </IconButton>
                </Popconfirm>


            )
        }

        const RowItem = ({ item }) => {
            return (
                <Col span={24} style={{ cursor: 'pointer', padding: 2 }} className="rowHover"
                >
                    <Row gutter={[8, 8]} align="middle" >

                        <Col xs={14} sm={20} onClick={async () => {
                            this.setState({ currentItem: item, openModal: true })
                        }}>
                            <Typography fontWeight={400} variant='body1'>{item.name} {item.units !== undefined ? `(${item.unitLabel})` : ''} </Typography>
                        </Col>

                        <Col xs={10} sm={4} style={{ textAlign: 'right' }}>
                            <EditItem item={item} /> <DeleteItem item={item} />
                        </Col>


                        <Col span={24}>
                            <Divider />
                        </Col>

                    </Row>
                </Col>
            )
        }

        const CreateParameter = () => {
            return (
                <Button size="small" style={{ padding: 10, borderRadius: 4, textTransform: 'none', fontSize: 14, }}
                    variant='contained' color="primary"
                    onClick={async () => {
                        const { currentItem } = this.state;

                        currentItem.name = currentItem.name || "";


                        const params = data.map((d) => d.name);



                        const checks = [
                            currentItem.name.length === 0 ? false : true,
                            params.includes(currentItem.name) ? false : true,
                        ]

                        if (currentItem.name.length === 0) {
                            this.props.openSnack('error', "Parameter name required!")
                        }
                        if (params.includes(currentItem.name)) {
                            this.props.openSnack('error', "Parameter name already exists!")
                        }


                        if (!checks.includes(false)) {
                            console.log("good?")
                            console.log(currentItem)

                            const key = currentItem.key || currentItem.tempKey;
                            currentItem.archived = currentItem.archived || false;

                            currentItem.account = userInfo.currentAccount;
                            currentItem.company = userInfo.currentCompany;


                            const docRef = doc(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'parameters', key);

                            if (currentItem.key) {
                                await updateDoc(docRef, currentItem).then(() => {
                                    const index = filteredData.map((d) => d.key).indexOf(currentItem.key)
                                    filteredData[index] = currentItem;
                                    this.setState({ filteredData: [...filteredData], openModal: false, currentItem: {}, })
                                }).then(() => {
                                    this.props.openSnack('success', currentItem.key ? 'Parameter updated!' : "Parameter created!")
                                })
                            }
                            else {
                                await setDoc(docRef, {
                                    ...currentItem,
                                    key: currentItem.tempKey
                                }).then(() => {
                                    filteredData.push({ ...currentItem, key: currentItem.tempKey })
                                    this.setState({ filteredData, openModal: false, currentItem: {}, })
                                }).then(() => {
                                    this.props.openSnack('success', currentItem.key ? 'Parameter updated!' : "Parameter created!")
                                })
                            }


                        }




                    }}>{currentItem.key ? 'Update Parameter' : "Create Parameter"}</Button>
            )
        }

        const UnitsArray = Object.values(Units).map((p, i) => {
            return ({
                label: p,
                key: Object.keys(Units)[i]
            })
        });

        const LPArray = Object.values(LabParameters).map((p, i) => {
            return ({
                label: p,
                key: Object.keys(LabParameters)[i]
            })
        });




        return (
            <Row style={{}} align="middle">
                <Col span={23}>
                    <Row align="middle">
                        <Col xs={16} sm={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>
                            <Typography variant="h4">Parameters</Typography>
                        </Col>
                        <Col xs={6} sm={4} style={{ paddingTop: 30, paddingBottom: 15, textAlign: 'right', position: 'relative', zIndex: 0 }}>
                            {loading ? <CircularProgress /> : <CreateItemButton />}
                        </Col>
                        <Col span={24}>
                            <Divider />
                        </Col>
                    </Row>
                </Col>



                <Col span={23} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 30 }}>
                    {loading ? <CircularProgress /> :
                        <Paper elevation={16} style={{ padding: 20, borderRadius: 12 }}>
                            <Row>
                                <Col xs={24} sm={24} style={{ paddingBottom: 8 }}>
                                    {["EuEZ93ByFZYs8bSUXoYsTt1zu0m2", 'eTevYsI9mQMxhtntd95HmRGasNx2', 'z4y8XVrxYGT7rO4R01Y8tQ2F5z83', 't9bOfGDqwDaQTYV6i008opscpjz2'].includes(userInfo.id) && (
                                        <Button variant="contained" color="primary" onClick={() => {
                                            this.getDefaults()
                                        }}>Get Defaults</Button>
                                    )}


                                </Col>
                                <Col xs={24} sm={12} style={{ paddingBottom: 8 }}>
                                    <Input fullWidth
                                        autoComplete='off'
                                        value={searchText}
                                        onChange={(e) => {
                                            this.setState({ searchText: e.target.value })
                                            if (e.target.value.length > 1) {
                                                this.filterData(data, e.target.value, filters)
                                            }
                                            else {
                                                this.filterData(data, '', filters)
                                            }
                                        }}
                                        id="input-with-icon-adornment"
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        }
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    edge="end"
                                                    onClick={() => {
                                                        this.setState({ searchText: '' })
                                                        this.filterData(data, '', filters)
                                                    }}
                                                >
                                                    <HighlightOffIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </Col>
                                <Col xs={0} sm={12} style={{ textAlign: 'right' }}>
                                    <Typography variant='h6'>Actions</Typography>
                                </Col>

                                <Col span={24}>
                                    <Row align='middle' >
                                        {filteredData.map((d) => {
                                            return (
                                                <RowItem item={d} />

                                            )

                                        })}
                                    </Row>
                                </Col>
                            </Row>
                        </Paper>
                    }
                </Col>

                <ParameterModal openSnack={this.props.openSnack} account={account} userInfo={userInfo} openModal={openModal} onClose={() => { this.setState({ openModal: false, currentItem: {} }) }} currentItem={currentItem} />




            </Row >
        );
    }
} 