import { Button, Checkbox, Divider, OutlinedInput, Typography, Select, MenuItem, CircularProgress, Breadcrumbs, FormGroup, FormControlLabel, Autocomplete, Chip, TextField, IconButton, Fab, ButtonGroup } from '@mui/material';
import { Col, Popconfirm, Row } from 'antd';
import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { fullMobile } from './../util';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, } from 'firebase/firestore';

import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import uuid from 'react-uuid';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';

import DatePicker from "react-datepicker";
import queryString from 'query-string';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { queryPSItems } from '../../../firebase/config';
import { DeleteForever } from '@mui/icons-material';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { faTablets, faToolbox } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
var randomColor = require('randomcolor');



export default function PSChemical(props) {
    const { userInfo, screenWidth, openSnack, company, account, item, setItem, updateItem } = props;
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);







    console.log(item)


    const typeOptions = [
        { label: "Liquid", key: 'liquid' },
        { label: "Powder", key: 'powder' },
        { label: "Pellet", key: 'pellet' },
    ]


    return (
        <Row gutter={[12, 12]} >
            <Col {...fullMobile(20, 20)} style={{ paddingTop: 10 }}>
                <Typography variant='h6' fontWeight={500} fontSize={20}>Chemical Item</Typography>



            </Col>
            <Col {...fullMobile(24, 24)} style={{ paddingTop: 10 }}>
                <Divider />
            </Col>
            <Col {...fullMobile(24, 24)} style={{}}>
                <Row align="middle" gutter={[4, 4]}>
                    <Col {...fullMobile(24, 24)} style={{}}>
                        <Typography variant="body1" fontWeight={500} component="h4"  >
                            Chemical Type
                        </Typography>

                    </Col>
                    {Object.values(item).length > 0 && (<Col {...fullMobile(24, 24)} style={{}}>
                        <Autocomplete
                            size="small"
                            disablePortal
                            id="combo-box-demo"
                            value={typeOptions.find((a) => a.key === item?.chemicalType)?.label}
                            onsele
                            options={typeOptions}
                            renderInput={(params) => <TextField {...params} placeholder="Type" />}
                            onChange={(a, b) => {
                                updateItem(`chemicalType`, b.key)
                            }}

                        />
                    </Col>)}

                </Row>

            </Col>
            <Col {...fullMobile(12, 24)} style={{}}>
                <Row align="middle" gutter={[4, 4]}>
                    <Col {...fullMobile(24, 24)} style={{}}>
                        <Typography variant="body1" fontWeight={500} component="h4"  >
                            EPA ID
                        </Typography>

                    </Col>
                    <Col {...fullMobile(24, 24)} style={{}}>
                        <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"

                            value={item.chemEPA}
                            onChange={(e) => {
                                setItem({ ...item, chemEPA: e.target.value })
                            }} />
                    </Col>

                </Row>

            </Col>
            <Col {...fullMobile(12, 24)} style={{}}>
                <Row align="middle" gutter={[4, 4]}>
                    <Col {...fullMobile(24, 24)} style={{}}>
                        <Typography variant="body1" fontWeight={500} component="h4"  >
                            State #
                        </Typography>

                    </Col>
                    <Col {...fullMobile(24, 24)} style={{}}>
                        <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"

                            value={item.chemState}
                            onChange={(e) => {
                                setItem({ ...item, chemState: e.target.value })
                            }} />
                    </Col>

                </Row>

            </Col>
            <Col {...fullMobile(12, 24)} style={{}}>
                <Row align="middle" gutter={[4, 4]}>
                    <Col {...fullMobile(24, 24)} style={{}}>
                        <Typography variant="body1" fontWeight={500} component="h4"  >
                            Active Ingredient
                        </Typography>

                    </Col>
                    <Col {...fullMobile(24, 24)} style={{}}>
                        <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"

                            value={item.chemAI}
                            onChange={(e) => {
                                setItem({ ...item, chemAI: e.target.value })
                            }} />
                    </Col>

                </Row>

            </Col>
            <Col {...fullMobile(12, 24)} style={{}}>
                <Row align="middle" gutter={[4, 4]}>
                    <Col {...fullMobile(24, 24)} style={{}}>
                        <Typography variant="body1" fontWeight={500} component="h4"  >
                            Default Units
                        </Typography>

                    </Col>
                    <Col {...fullMobile(24, 24)} style={{}}>
                        <Autocomplete
                            style={{ backgroundColor: '#ffffff' }}
                            size="small"
                            id="combo-box-demo"
                            value={item.defaultUnits}
                            options={["mg",
                                "g",
                                "kg",
                                "oz",
                                "lb",
                                "fl-oz",
                                "cup",
                                "pnt",
                                "qt",
                                "gal",
                                "ft3",
                            ].sort((a, b) => a.localeCompare(b)).map((g) => { return ({ key: g, label: g }) })}
                            renderInput={(params) => <TextField {...params} placeholder="Units" />}
                            onChange={(a, d) => {
                                setItem({ ...item, defaultUnits: d.key })


                            }}

                        />
                    </Col>

                </Row>

            </Col>
            <Col {...fullMobile(12, 24)} style={{}}>
                <Row align="middle" gutter={[4, 4]}>
                    <Col {...fullMobile(24, 24)} style={{}}>
                        <Typography variant="body1" fontWeight={500} component="h4"  >
                            Instructions
                        </Typography>

                    </Col>
                    <Col {...fullMobile(24, 24)} style={{}}>
                        <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                            multiline={true}
                            rows={4}
                            value={item.chemInstructions}
                            onChange={(e) => {
                                setItem({ ...item, chemInstructions: e.target.value })
                            }} />
                    </Col>

                </Row>

            </Col>
            <Col {...fullMobile(12, 24)} style={{}}>
                <Row align="middle" gutter={[4, 4]}>
                    <Col {...fullMobile(24, 24)} style={{}}>
                        <Typography variant="body1" fontWeight={500} component="h4"  >
                            PPE Instructions
                        </Typography>

                    </Col>
                    <Col {...fullMobile(24, 24)} style={{}}>
                        <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                            multiline={true}
                            rows={4}
                            value={item.ppeInformation}
                            onChange={(e) => {
                                setItem({ ...item, ppeInformation: e.target.value })
                            }} />
                    </Col>

                </Row>

            </Col>
            <Col {...fullMobile(24, 24)} style={{}}>
                <Row align="middle" gutter={[4, 4]}>
                    <Col {...fullMobile(24, 24)} style={{}}>
                        <Typography variant="body1" fontWeight={500} component="h4"  >
                            Additional Information
                        </Typography>

                    </Col>
                    <Col {...fullMobile(24, 24)} style={{}}>
                        <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                            multiline={true}
                            rows={4}
                            value={item.chemAdditaional}
                            onChange={(e) => {
                                setItem({ ...item, chemAdditaional: e.target.value })
                            }} />
                    </Col>

                </Row>

            </Col>



        </Row>
    )



}