import React from "react";


import { Button, ButtonGroup } from '@mui/material';
import { Col, Row } from 'antd';
import './logComponent.css';



const PortalTabs = (
    {
        loggedIn,
        page,
        changePage,
        log,
        openSnack,
        userInfo,
        currentPublicSite
    }

) => {






    return (

        <Row >
            <Col xs={24} sm={24} style={{
                height: '70px',
                borderBottom: '1px solid rgba(0,0,0,0.2)',
                display: 'flex',
                alignItems: 'flex-end', // Adjusted this line
                justifyContent: 'flex-start', // Adjusted this line for better readability
            }}>
                <ButtonGroup size="small" aria-label="small button group" style={{
                    marginLeft: 10
                }}>
                    <Button
                        key="log"
                        onClick={(e) => changePage('sites')}
                        color='primary'
                        variant={null}
                        style={{
                            height: '50px',
                            textTransform: 'none',
                            borderRadius: 0,
                            fontWeight: 400,
                            fontSize: 18,
                            color: page === 'sites' ? '#6fa2f1' : null,
                            borderBottom: page === 'sites' ? '3px solid #3f51b5' : null
                        }}
                    >
                        Sites
                    </Button>
                    <Button
                        key="account"
                        onClick={(e) => {
                            if (currentPublicSite === null) {
                                openSnack('error', 'Please pick site to view dashboard')
                                return
                            }
                            changePage('account')
                        }}
                        color='primary'
                        variant={null}
                        style={{
                            height: '50px',
                            textTransform: 'none',
                            borderRadius: 0,
                            fontWeight: 400,
                            fontSize: 18,
                            color: currentPublicSite === null ? 'rgba(0,0,0,0.4)' : page === 'account' ? '#6fa2f1' : null,
                            borderBottom: page === 'account' ? '3px solid #3f51b5' : null
                        }}
                    >
                        Dashboard
                    </Button>
                    <Button
                        key="documents"
                        onClick={(e) => {
                            if (currentPublicSite === null) {
                                openSnack('error', 'Please pick site to view documents')
                                return
                            }
                            changePage('documents')
                        }}
                        color='primary'
                        variant={null}
                        style={{
                            height: '50px',
                            textTransform: 'none',
                            borderRadius: 0,
                            fontWeight: 400,
                            fontSize: 18,
                            color: currentPublicSite === null ? 'rgba(0,0,0,0.4)' : page === 'documents' ? '#6fa2f1' : null,
                            borderBottom: page === 'documents' ? '3px solid #3f51b5' : null
                        }}
                    >
                        Documents
                    </Button>



                </ButtonGroup>
            </Col>


        </Row >

    );
};

export default PortalTabs;
