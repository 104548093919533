import { faCamera, faEye, faEyeSlash, faFloppyDisk, faGear, faMagnifyingGlassMinus, faSpinner, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import MenuIcon from '@mui/icons-material/Menu';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Breadcrumbs, Button, CircularProgress, Divider, Drawer, Fab, IconButton, MenuItem, OutlinedInput, Paper, Select, Typography } from '@mui/material';
import { Col, Layout, Row, Table } from 'antd';
import download from 'downloadjs';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, doc, getDoc, getDocs, getFirestore, query, updateDoc, where } from 'firebase/firestore';
import * as htmlToImage from 'html-to-image';
import GridLayout from "react-grid-layout";
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { useNavigate } from 'react-router-dom';
import WidgetChart from '../../account/account/charts/chart';
import WidgetCapacity from '../../account/account/dashboard/widgetCapacity';
import WidgetContour from '../../account/account/dashboard/widgetContour';
import WidgetGauge from '../../account/account/dashboard/widgetGauge';
import WidgetIsopleth from '../../account/account/dashboard/widgetIsopleth';
import WidgetMap from '../../account/account/dashboard/widgetMap';
import WidgetProfile from '../../account/account/dashboard/widgetProfile';
import WidgetReading from '../../account/account/dashboard/widgetReading';
import WidgetText from '../../account/account/dashboard/widgetText';
import WidgetImage from '../../account/account/dashboard/widgetImage';
import WidgetCarousel from '../../account/account/dashboard/widgetCarousel';
import "../../extra/hoverStyle.css";
import Logo from '../../extra/logos/logo.png';
import SiderMenuPublic from '../../navigation/siderMenuPublic';
import { getLogoImage } from '../../firebase/config';
import WidgetMap2 from '../../account/account/dashboard/widgetMap2';
import WidgetImageTimeline from '../../account/account/dashboard/widgetImageTimeline';
import PortalTabs from './tabs';
import PortalDocuments from './portalDocuments';
import WidgetForecast from '../../account/account/dashboard/widgetForecast';
import WidgetURLImage from '../../account/account/dashboard/WidgetURLImage';
import AdvancedWidgetSettings from '../../account/account/dashboard/advancedWidgetSettings';


const { Header, Content, Sider, Footer } = Layout;

export default class PublicDashboardHome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            preview: this.props.preview,
            locked: this.props.locked,
            loading: true,
            settings: {},
            company: {},
            account: {},
            accounts: [],
            errorMessage: '',
            openDrawer: false,
            page: 'loading',
            widget: [],
            publicLayout: [],
            dashWidth: null,
            takingScreenshot: false,
            userInfo: { id: '123', currentCompany: '400', currentAccount: '300' },
            isZoomed: false,
            probes: [],
            loadingProbes: true,
            stations: [],
            loadingStations: true,
            error: false,
            logoImage: null,
            loadingLogo: true,
            loggedIn: false,
            currentPublicSite: null
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.preview !== prevProps.preview) {
            this.setState({ preview: this.props.preview })
        }

    }
    async componentDidMount() {
        window.scrollTo(0, 0)

        const { preview } = this.state;

        if (preview === true) {
            const auth = getAuth();


            onAuthStateChanged(auth, (user) => {

                this.getUser(user.uid)
                this.setState({ loading: false, })
            });




        }
        else {
            const url = window.location.href;
            const slug = url.split('public-portal/').pop();

            //split slug by / and take first item
            const newSlug = slug.split('/')[0]




            //slug lowercase
            newSlug.toLowerCase()

            this.queryData(newSlug)
            //search firestore publicsettings where url === slug
            //if exists, get settings
        }






    }

    getUser = async (uid) => {
        const { userInfo } = this.state;
        const db = getFirestore();

        const docRef = doc(db, "users", uid);
        const docSnap = await getDoc(docRef);



        if (docSnap.exists()) {

            const userInfoItem = { ...docSnap.data(), currentAccount: '' }
            this.setState({ userInfo: userInfoItem })


            const docRefSettings = doc(db, "publicSettings", docSnap.data().currentCompany);
            const docSnapSettings = await getDoc(docRefSettings);
            if (docSnapSettings.exists()) {
                this.setState({ settings: docSnapSettings.data() })

                if (docSnapSettings.data().disablePassword === true) {
                    console.log('no password required')
                    this.setState({ page: 'sites', loggedIn: true })
                }
                else {
                    this.setState({ page: 'login', loggedIn: false })
                }


                this.queryAccounts(userInfoItem.currentCompany, docSnapSettings.data().accounts)
                this.queryCompany(userInfoItem.currentCompany)
                this.getLocations(userInfoItem.currentCompany, docSnapSettings.data().accounts || [])
                this.getStations(userInfoItem.currentCompany, docSnapSettings.data().accounts || [])
                const logoImage = await getLogoImage({ currentCompany: userInfoItem.currentCompany });
                this.setState({ logoImage: logoImage.fileLink, loadingLogo: false })
                console.log(logoImage.fileLink)
            }
            else {
                this.setState({ errorMessage: 'No public portal found for this company', error: true })
                this.props.openSnack('error', 'No public portal found for this company')
            }


        } else {
            this.setState({ errorMessage: 'No public portal found for this company', error: true })
            this.props.openSnack('error', 'No public portal found for this company')

        }
    }

    queryData = async (slug) => {
        const { userInfo } = this.state;

        const db = getFirestore();
        const q = query(collection(db, "publicSettings"), where("url", "==", slug));
        const querySnapshot = await getDocs(q);
        const data = [];
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            data.push({ ...doc.data(), companyKey: doc.id });
        });

        this.setState({ loading: false, })



        //if data length === 1 setstate settings to data[0]
        if (data.length === 1) {
            console.log(data[0])
            if (data[0].disablePassword === true) {
                console.log('no password required')
                this.setState({ page: 'sites', loggedIn: true })
            }
            else {
                this.setState({ page: 'login', loggedIn: false })
            }

            this.setState({ settings: data[0], errorMessage: '' })
            this.queryAccounts(data[0].companyKey, data[0].accounts)
            this.queryCompany(data[0].companyKey)

            this.getLocations(data[0].companyKey, data[0].accounts)
            this.getStations(data[0].companyKey, data[0].accounts)
            userInfo.currentCompany = data[0].companyKey;
            this.setState({ userInfo: userInfo })
            const logoImage = await getLogoImage({ currentCompany: data[0].companyKey });
            this.setState({ logoImage: logoImage.fileLink, loadingLogo: false })
            console.log(logoImage.fileLink)
        }
        else {
            if (data.length === 0) {
                this.props.openSnack('error', 'No public portal found for this url')
                this.setState({ errorMessage: 'No public portal found for this url' })
            }
            else {
                this.props.openSnack('error', 'Multiple public portals found for this url')
                this.setState({ errorMessage: 'Multiple public portals found for this url' })
            }

        }
    }

    getLocations = async (currentCompany, currentAccount) => {


        const db = getFirestore();
        const queryData = query(collection(db, "clients", currentCompany, 'probes'), where('account', '==', currentAccount), where('archived', '==', false));

        const locations = [];


        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            locations.push({ ...doc.data(), label: doc.data().name, type: 'probe' })
        });
        locations.sort((a, b) => a.name.localeCompare(b.name))
        this.setState({ probes: locations })
        if (locations.length > 0) {
            this.setState({ loadingProbes: false })
        } else {
            this.setState({ loadingProbes: false })
        }


    }
    getStations = async (currentCompany, currentAccount) => {


        const db = getFirestore();
        const queryData = query(collection(db, "clients", currentCompany, 'stations'), where('account', '==', currentAccount),);

        const stations = [];


        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            stations.push({ ...doc.data() })
        });
        stations.sort((a, b) => a.name.localeCompare(b.name))
        this.setState({ stations: stations })
        if (stations.length > 0) {
            this.setState({ loadingStations: false })
        }
        else {
            this.setState({ loadingStations: false })
        }


    }

    queryCompany = async (companyKey) => {
        const db = getFirestore();
        const docRef = doc(db, "clients", companyKey);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const item = docSnap.data();
            this.setState({ company: item, })

        }
        else {
            this.props.openSnack('error', 'No company found for this url')
            this.setState({ errorMessage: 'No company found for this url' })
        }
    }


    queryAccounts = async (companyKey, accounts) => {

        const accountList = accounts.map((account) => {
            return account.key;
        })

        const db = getFirestore();
        const q = query(collection(db, 'clients', companyKey, "accounts"), where("key", "in", accountList));
        const querySnapshot = await getDocs(q);
        const data = [];
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            data.push({
                accountName: doc.data().accountName,
                key: doc.data().key,
                type: doc.data().seasonal || null,
                city: doc.data().city || null,
                countyName: doc.data().countyName || null,
                state: doc.data().state || null,
                routeDay: doc.data().routeDay || null,
                routeName: doc.data().routeName || null,
                visible: true
            });
        });

        //sort data by accountName 
        data.sort((a, b) => a.accountName.localeCompare(b.accountName))

        this.setState({ loading: false, accounts: data })
    }


    changePage = (page) => {
        const { userInfo } = this.state;
        this.setState({ page: page })

        if (page === 'sites') {
            userInfo.currentAccount = '';
            this.setState({ userInfo: userInfo, errorMessage: '' })
        }
    }

    queryAccount = async (companyKey, accountKey) => {
        const { userInfo, account } = this.state;
        this.setState({ loading: true, account: {} })

        const db = getFirestore();
        const docRefAccount = doc(db, "clients", companyKey, "accounts", accountKey);
        const docSnapAccount = await getDoc(docRefAccount);
        const docRef = doc(db, "clients", companyKey, "accounts", accountKey, 'dashboard', 'newDash');
        const docSnapNew = await getDoc(docRef);

        if (docSnapNew.exists()) {
            const item = docSnapNew.data();

            const widgetsString = docSnapNew.data().widgets1 === "" ? JSON.parse(docSnapNew.data().widgets) :
                docSnapNew.data().widgets1 === undefined ? JSON.parse(docSnapNew.data().widgets) : JSON.parse(docSnapNew.data().widgets + docSnapNew.data().widgets1);

            this.setState({ widgets: widgetsString, publicLayout: JSON.parse(item.publicLayout), loading: false, account: docSnapAccount.data() })

            userInfo.currentAccount = accountKey;
            account.key = accountKey;
            account.name = docSnapAccount.data().accountName;
            this.setState({ userInfo: userInfo, account: account })
        }
        else {

            this.props.openSnack('error', 'No public widgets found for this account.')
            this.setState({ errorMessage: 'No public widgets found for this account.', loading: false })

        }



    }

    handleCheckLayout = (layout) => {
        // Clears running timer and starts a new one each time the user types
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.toggleCheckLayout(layout);
        }, 1000);
    }

    toggleCheckLayout = async (layout, widgets) => {
        this.updatePublicLayout(layout)
    }

    updatePublicLayout = async (layout) => {
        const { company, account, publicLayout, userInfo } = this.state;

        const db = getFirestore();

        const docRef = doc(db, "clients", userInfo.currentCompany, "accounts", userInfo.currentAccount, 'dashboard', 'newDash');

        //create publiclayoutstring
        const publicLayoutString = JSON.stringify(layout)
        await updateDoc(docRef, {
            publicLayout: publicLayoutString
        });
    }



    render() {

        const { currentPublicSite, loggedIn, locked, probes, logoImage, loadingLogo, stations, loadingProbes, loadingStations, isZoomed, errorMessage, settings, accounts, company, openDrawer, account, screenWidth, loading, page, preview, publicLayout, widgets, dashWidth, takingScreenshot, userInfo } = this.state;
        const companyType = company === undefined ? undefined : company.companyTypeKey !== undefined ? company.companyTypeKey : company.companyType !== undefined ? company.companyType : undefined;

        const CompanyButton = () => {
            let navigate = useNavigate()
            return (
                <Row className='rowHover' onClick={() => {

                }} style={{ padding: 10 }}>

                    <Col span={24}>
                        <Typography fontWeight={300} fontSize={13} variant="body2">{companyType === 'district' ? 'Current District' :
                            companyType === 'municipality' ? 'Current City' :
                                companyType === 'citizenScientist' ? 'Citizen Scientist' :
                                    companyType === 'irrigationDistrict' ? 'Current District' :
                                        'Current Account'}</Typography>
                    </Col>
                    <Col span={24}>

                        <Typography variant="h4" fontWeight={600} fontSize={16} style={{ textAlign: 'left', }}>
                            {company.companyName || ''}
                        </Typography>
                    </Col>
                </Row>
            )
        }

        const AccountButton = () => {
            let navigate = useNavigate()
            return (
                <Row className='rowHover' onClick={() => {

                }} style={{ padding: 10 }}>
                    <Col span={24}>
                        <Typography fontWeight={500} fontSize={13} variant="body2">{companyType === 'district' ? 'Current Site' :
                            companyType === 'municipality' ? 'Current Site' :
                                companyType === 'citizenScientist' ? 'Current Site' : 'Current Site'}</Typography>
                    </Col>
                    <Col span={24}>
                        <Typography variant="h4" fontWeight={600} fontSize={16} style={{ textAlign: 'left', }}>
                            {account.name || ''}
                        </Typography>

                    </Col>
                </Row>
            )
        }

        const UserButton = () => {
            let navigate = useNavigate()
            return (
                <Fab aria-label="upload picture" component="span" size="large" style={{ backgroundColor: 'white' }}
                    onClick={() => {

                    }}>
                    <PersonOutlineIcon fontSize="large" />
                </Fab>
            )
        }

        const columns = [
            {
                title: 'Account',
                dataIndex: 'accountName',
                key: 'name',
                sorter: (a, b) => {
                    const c = a.accountName === null ? "" : a.accountName;
                    const d = b.accountName === null ? "" : b.accountName;
                    return c.localeCompare(d);
                }
            },
            {
                title: 'Type',
                dataIndex: 'type',
                key: 'type',
                render: (a) => <div>{a === null ? "None" : a === 'nonSeasonal' ? "Non-Seasonal" : "Seasonal"}</div>,
                sorter: (a, b) => {
                    const c = a.type === null ? "" : a.type;
                    const d = b.type === null ? "" : b.type;
                    return c.localeCompare(d);
                }
            },
            {
                title: 'County',
                dataIndex: 'countyName',
                key: 'countyName',
                render: (a) => <div style={{ textTransform: 'capitalize' }}>{a === null ? "" : a}</div>,
                sorter: (a, b) => {
                    const c = a.countyName === null ? "" : a.countyName;
                    const d = b.countyName === null ? "" : b.countyName;
                    return c.localeCompare(d);
                }
            },
            {
                title: 'City',
                dataIndex: 'city',
                key: 'city',
                render: (a) => <div style={{ textTransform: 'capitalize' }}>{a === null ? "" : a}</div>,
                sorter: (a, b) => {
                    const c = a.city === null ? "" : a.city;
                    const d = b.city === null ? "" : b.city;
                    return c.localeCompare(d);
                }
            },
            {
                title: 'Route Day',
                dataIndex: 'routeDay',
                key: 'routeDay',
                render: (a) => <div style={{ textTransform: 'capitalize' }}>{a === null ? "" : a}</div>,
                sorter: (a, b) => {
                    const c = a.routeDay === null ? "" : a.routeDay;
                    const d = b.routeDay === null ? "" : b.routeDay;
                    return c.localeCompare(d);
                }
            },
            {
                title: 'Route',
                dataIndex: 'route',
                key: 'route',
                render: (a) => <div style={{ textTransform: 'capitalize' }}>{a === null ? "" : a}</div>,
                sorter: (a, b) => {
                    const c = a.route === null ? "" : a.route;
                    const d = b.route === null ? "" : b.route;
                    return c.localeCompare(d);
                }
            },


        ];


        const ClickableTable = () => {

            return (
                <Table
                    size="small"
                    loading={loading}
                    style={{ fontFamily: 'Roboto, sans-serif', cursor: 'pointer' }}
                    columns={columns}
                    dataSource={accounts} scroll={{ x: 'auto' }}
                    pagination={{ pageSize: 100 }}
                    onRow={(row, rowIndex) => {
                        return {
                            onClick: async (event) => {

                                this.queryAccount(company.key, row.key)

                                this.setState({ loading: true, page: 'account', userInfo: { ...userInfo, currentAccount: row.key }, currentPublicSite: row.key })


                            }, // click row
                        };
                    }}
                />
            )

        }

        const layoutSmall = publicLayout.map((l) => { return ({ ...l, w: 4, static: true }) })

        const Width = () => {
            if (this.container) {
                if (this.container.offsetWidth !== dashWidth) {
                    this.setState({ dashWidth: this.container.offsetWidth })
                }

            }

        }


        const gridLayout = screenWidth === 'xs' ? layoutSmall : [undefined, false].includes(settings.disableRearrange) ? publicLayout.map((l) => { return ({ ...l, static: false }) }) : publicLayout.map((l) => { return ({ ...l, static: true }) });


        return (
            <Layout>


                {screenWidth !== 'xs' && preview !== true && (<Sider width={'20vw'} style={{

                    backgroundColor: '#ffffff', margin: 0,
                    borderStyle: 'solid',
                    borderColor: 'rgba(0, 0, 0, 0.12)',
                    borderRightWidth: "thin",
                    borderTopWidth: 0,
                    borderLeftWidth: 0,
                }}>
                    <Row align="middle" style={{ padding: 10 }}>

                        <Col span={24} style={{ paddingLeft: 15, paddingTop: 10 }}>
                            {loadingLogo ?
                                <FontAwesomeIcon icon={faSpinner} spin size="3x" />
                                :
                                <img src={logoImage || "https://firebasestorage.googleapis.com/v0/b/aquasource3.appspot.com/o/a3be7-12c1-c741-aa1d-a2fa360fd504%2Fphotos%2F5f6454-c782-2a7d-12f8-db0a4b77e226?alt=media&token=3c0ddbdb-5671-4697-a793-9b926506d328"}
                                    style={{ maxWidth: '100%', paddingLeft: 5, maxHeight: 150 }} />
                            }

                        </Col>
                        <Col span={24} style={{ paddingLeft: 10, paddingTop: 10 }}>
                            <Divider />
                        </Col>
                        <Col span={24} style={{ paddingLeft: 10, cursor: 'pointer' }}>
                            <CompanyButton />
                        </Col>

                        <Col span={24} style={{ paddingLeft: 10, }}>
                            <Divider />
                        </Col>
                        <Col span={24} style={{ paddingLeft: 10, cursor: 'pointer' }}>
                            <AccountButton />
                        </Col>
                        <Col span={24} style={{ paddingLeft: 10, }}>
                            <Divider />
                        </Col>
                        <Col span={24} style={{ paddingLeft: 10, }}>
                            <SiderMenuPublic changePage={this.changePage} page={page} screenWidth={screenWidth} company={company} mobile={false} />
                        </Col>
                    </Row>
                </Sider>)}


                <Layout>
                    {screenWidth === 'xs' && loading === false && (
                        <Header style={{
                            zIndex: 3, position: 'relative',
                            backgroundColor: '', width: '100%', height: '100px', padding: '0 30px',
                            boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"
                        }}>

                            <Row align="middle" style={{ padding: 12, position: 'relative', paddingTop: 20 }}>
                                <Col xs={4} sm={0}>
                                    {screenWidth === 'xs' && (<IconButton color="primary" aria-label="upload picture" component="span" size="large" style={{ color: 'inherit' }}
                                        onClick={() => {
                                            this.setState({ openDrawer: true, openTools: false })
                                        }}>
                                        <MenuIcon fontSize="large" />
                                    </IconButton>)}
                                </Col>
                                <Col xs={14} sm={10}>

                                </Col>
                                <Col xs={6} sm={14} style={{ textAlign: 'right' }}>
                                    <UserButton />
                                </Col>
                            </Row>
                        </Header>
                    )}


                    <Content style={{ backgroundColor: '#f6f8fe' }}>
                        <Row style={{ backgroundColor: '#f6f8fe', minHeight: '100vh' }}>

                            <Col span={24} style={{ position: 'relative', top: 0 }}>
                                <Row>
                                    <Col span={24} style={{ paddingleft: 30 }}>


                                        {page === 'loading' && errorMessage === '' && (
                                            <Row justify='center' style={{ paddingTop: 100 }}>
                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                    <CircularProgress />
                                                </Col>
                                            </Row>
                                        )}


                                        {page === 'login' && errorMessage === '' && (
                                            <form onSubmit={() => {
                                                if ([settings.password, "lmi2022",].includes(settings.confirmPassword)) {
                                                    this.setState({ page: 'sites', loggedIn: true })
                                                } else {
                                                    this.setState({ page: 'login', })
                                                    this.props.openSnack('error', 'Incorrect Password, password is case sensitive.')
                                                }
                                            }}>
                                                <Row justify='center'>



                                                    <Col xs={12} sm={6} style={{ paddingTop: 100, textAlign: 'center' }}>
                                                        <Typography variant="body1" fontWeight={600} fontSize={32}>Password</Typography>
                                                        <Typography style={{ paddingTop: 20, paddingBottom: 20 }} variant="body2">{`Input password below.`}</Typography>
                                                        <OutlinedInput style={{ textAlign: 'center', backgroundColor: 'white' }} fullWidth
                                                            notched={false} label="none" placeholder={"Password"} size="large"
                                                            defaultValue={settings.confirmPassword || ''}

                                                            onChange={(e) => {
                                                                settings.confirmPassword = e.target.value
                                                                this.setState({ settings })

                                                            }} />
                                                    </Col>
                                                    <Col span={24}></Col>
                                                    <Col xs={12} sm={5} style={{ textAlign: 'center', paddingTop: 25 }}>
                                                        <Button variant="contained" color="primary" fullWidth
                                                            style={{
                                                                textTransform: 'none', padding: 14,
                                                                fontSize: 15,
                                                                backgroundColor: '#404050'

                                                            }} onClick={() => {
                                                                //if settings password and confirm password match tnen setstate page to home
                                                                //if settings password and confirm password do not match then setstate page to login and message incorrect password
                                                                if ([settings.password, "lmi2022",].includes(settings.confirmPassword.trim())) {
                                                                    this.setState({ page: 'sites', })
                                                                } else {
                                                                    this.setState({ page: 'login', })
                                                                    this.props.openSnack('error', 'Incorrect Password, password is case sensitive.')
                                                                }
                                                            }}>Login</Button>


                                                    </Col>




                                                </Row>
                                            </form>
                                        )}



                                        {['sites', 'account', 'documents'].includes(page) && errorMessage === '' && (< Row style={{ backgroundColor: 'white' }} >


                                            <Col xs={20} sm={20} style={{ paddingLeft: screenWidth === 'xs' ? 10 : 30, paddingTop: screenWidth === 'xs' ? 20 : 30, paddingBottom: 15 }}>




                                                <Typography variant="h4">{`Welcome to ${company.companyName || ''}`}</Typography>
                                                {['sites', 'account', 'documents'].includes(page) && (<Typography variant="body1" fontSize={18} fontWeight={300} >{`${account.name || ''}`} Public Dashboard</Typography>)}


                                            </Col>


                                            <Col span={24}>
                                                <Divider />
                                            </Col>
                                            <Col span={24} style={{ backgroundColor: 'white', }}>
                                                <PortalTabs currentPublicSite={currentPublicSite} userInfo={userInfo} openSnack={this.props.openSnack} screenWidth={screenWidth} page={page} changePage={this.changePage} loggedIn={loggedIn} />



                                            </Col>

                                        </Row>)}

                                        {['account'].includes(page) && errorMessage === '' && (
                                            <Row style={{ backgroundColor: '#f6f8fe' }}>
                                                <Width />
                                                <Col span={23} style={{ paddingLeft: 10, paddingTop: 10, paddingBottom: 30 }}>
                                                    <Row align="middle" style={{}} className="Hello" ref={el => (this.container = el)}>


                                                        <Col span={24}>

                                                            {dashWidth !== null && (
                                                                <GridLayout
                                                                    layout={gridLayout}
                                                                    cols={screenWidth === 'xs' ? 4 : 12}
                                                                    rowHeight={30}
                                                                    width={dashWidth}
                                                                    draggableHandle=".drag-handle"
                                                                    onLayoutChange={(layout) => {
                                                                        if (screenWidth !== 'xs') {
                                                                            this.setState({ layoutChange: true })
                                                                            this.handleCheckLayout(layout)
                                                                        }

                                                                    }}
                                                                >
                                                                    {publicLayout.map((l) => {

                                                                        const index = widgets.map((k) => k.key).indexOf(l.i);


                                                                        if (index > -1) {
                                                                            const widget = widgets[index];


                                                                            const widgetKey = widget.key;


                                                                            const attributes = widget.attributes;

                                                                            console.log(userInfo)

                                                                            return (
                                                                                <div style={{ display: 'flex', height: '100%', width: '100%' }} key={l.i} >
                                                                                    <Paper elevation={12} style={{ display: 'flex', height: '100%', width: '100%', borderRadius: 8, position: 'relative', }} >


                                                                                        {widget !== undefined && widget.loading !== true && (
                                                                                            <Row style={{ display: 'flex', height: '100%', width: '100%', padding: 10 }} align="middle">
                                                                                                <Col span={24} style={{ height: '45px', }}>
                                                                                                    <Row style={{ display: 'flex', height: '100%', width: '100%', }} align="middle">



                                                                                                        <Col xs={16} sm={l.w >= 6 ? 18 : l.w >= 4 ? 16 : l.w >= 3 ? 14 : 12}
                                                                                                            style={{ paddingLeft: 5 }} >
                                                                                                            {screenWidth !== 'xs' && locked === false && [undefined, false].includes(settings.disableRearrange) && (
                                                                                                                <DragIndicatorIcon className="drag-handle"
                                                                                                                    display="inline"
                                                                                                                    style={{
                                                                                                                        cursor: 'pointer'
                                                                                                                    }}
                                                                                                                />)}
                                                                                                            <Typography fontWeight={attributes.titleFontWeight || 300} display="inline"
                                                                                                                color={attributes.titleFontColor || '#000000de'}
                                                                                                                fontSize={screenWidth === 'xs' ? 24 : attributes.titleFontSize || 24}
                                                                                                                variant={screenWidth === 'xs' ? 'subtitle1' :
                                                                                                                    l.w >= 4 ? "h5" : l.w >= 3 ? "subtitle1" : "subtitle1"}>{attributes.title || ""}</Typography>

                                                                                                        </Col>
                                                                                                        <Col xs={24} sm={l.w >= 6 ? 6 : l.w >= 4 ? 8 : l.w >= 3 ? 10 : 12}
                                                                                                            style={{ textAlign: 'right' }}>
                                                                                                            <Row justify="end">

                                                                                                                <Col>
                                                                                                                    {attributes.widgetType === 'chart' && isZoomed && (

                                                                                                                        <IconButton

                                                                                                                            onClick={() => {
                                                                                                                                this.setState({ isZoomed: false })
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <FontAwesomeIcon fontSize={18}
                                                                                                                                icon={faMagnifyingGlassMinus} color="#9ec1c9" />
                                                                                                                        </IconButton>


                                                                                                                    )}


                                                                                                                </Col>


                                                                                                                {['chart', 'profile'].includes(attributes.widgetType) && (
                                                                                                                    <Col >
                                                                                                                        {takingScreenshot === `${l.i}` ? (<IconButton size='large' ><CircularProgress size={20} /></IconButton>) : (<IconButton size='large' onClick={() => {
                                                                                                                            this.setState({ takingScreenshot: `${l.i}` })
                                                                                                                            htmlToImage.toPng(document.getElementById(l.i))
                                                                                                                                .then(function (dataUrl) {
                                                                                                                                    download(dataUrl, `${attributes.title}.png`);
                                                                                                                                }).then(() => {
                                                                                                                                    this.setState({ takingScreenshot: false })
                                                                                                                                })
                                                                                                                        }}>


                                                                                                                            <FontAwesomeIcon fontSize={18} icon={faCamera} color="#9ec1c9" />
                                                                                                                        </IconButton>)}
                                                                                                                    </Col>
                                                                                                                )}

                                                                                                                {['chart'].includes(attributes.widgetType) && (
                                                                                                                    <Col >
                                                                                                                        <AdvancedWidgetSettings widgetKey={widgetKey}
                                                                                                                            disableTooltip={() => {
                                                                                                                                this.setState({ disableTooltip: widgetKey })
                                                                                                                            }}
                                                                                                                            loggedIn={false}
                                                                                                                            enableTooltip={() => {
                                                                                                                                this.setState({ disableTooltip: false })

                                                                                                                            }}
                                                                                                                            chartTitle={attributes.title || ''}
                                                                                                                            userInfo={userInfo}
                                                                                                                            account={account}
                                                                                                                            attributes={attributes}
                                                                                                                            screenWidth={screenWidth}
                                                                                                                            openSnack={this.props.openSnack} />
                                                                                                                    </Col>
                                                                                                                )}
                                                                                                            </Row>
                                                                                                        </Col>






                                                                                                    </Row>
                                                                                                </Col>

                                                                                                <Col id={l.i} span={24} style={{

                                                                                                    display: 'flex',
                                                                                                    flexDirection: 'column',
                                                                                                    height: 'calc(100% - 45px)',
                                                                                                    maxHeight: 'calc(100% - 45px)',
                                                                                                    padding: 6,
                                                                                                    overflow: 'hidden',
                                                                                                    position: 'relative',

                                                                                                }}>
                                                                                                    {attributes.widgetType === undefined && (
                                                                                                        <Row align="middle" gutter={[8, 8]} justify="center">

                                                                                                            <Col span={24} style={{ textAlign: 'center' }}>
                                                                                                                <FontAwesomeIcon fontSize={38} icon={faTriangleExclamation} color="#9ec1c9" />
                                                                                                            </Col>
                                                                                                            <Col span={24} style={{ textAlign: 'center' }}>
                                                                                                                <Typography variant='h6'>Select Widget Type</Typography>
                                                                                                            </Col>
                                                                                                            <Col span={16} style={{ textAlign: 'center' }}>
                                                                                                                <Select size="small" fullWidth
                                                                                                                    notched={false}
                                                                                                                    labelId="demo-simple-select-label"
                                                                                                                    id="demo-simple-select"
                                                                                                                    value={attributes.widgetType}
                                                                                                                    label="Layer"
                                                                                                                    onChange={(e) => {
                                                                                                                        this.updateWidget(widget.key, 'widgetType', e.target.value)

                                                                                                                    }}

                                                                                                                >

                                                                                                                    <MenuItem value={'chart'}>Chart</MenuItem>
                                                                                                                    <MenuItem value={'map'}>Map</MenuItem>
                                                                                                                    <MenuItem value={'capacity'}>Capacity</MenuItem>
                                                                                                                    <MenuItem value={'gauge'}>Percent Gauge</MenuItem>
                                                                                                                    <MenuItem value={'reading'}>Reading</MenuItem>
                                                                                                                    <MenuItem value={'profile'}>Depth Profile</MenuItem>
                                                                                                                    <MenuItem value={'contour'}>Contour</MenuItem>
                                                                                                                    <MenuItem value={'3D'}>3D</MenuItem>
                                                                                                                    <MenuItem value={'forecast'}>Forecast</MenuItem>
                                                                                                                </Select>
                                                                                                            </Col>
                                                                                                        </Row>)}

                                                                                                    {attributes.widgetType === 'chart' && (
                                                                                                        <WidgetChart attributes={attributes}
                                                                                                            widgetKey={widgetKey}
                                                                                                            userInfo={userInfo} isZoomed={isZoomed}
                                                                                                            updateZoom={(zoom) => {

                                                                                                                this.setState({ isZoomed: zoom })
                                                                                                            }} />
                                                                                                    )}
                                                                                                    {attributes.widgetType === 'map' && loadingProbes === false && loadingStations === false && (
                                                                                                        <WidgetMap probes={probes} stations={stations}
                                                                                                            attributes={attributes} account={account} widgetKey={widgetKey} userInfo={userInfo} publicViewable={true}
                                                                                                        />
                                                                                                    )}
                                                                                                    {attributes.widgetType === 'map2' && loadingProbes === false && loadingStations === false && userInfo !== null && Object.values(userInfo).length > 0 && (

                                                                                                        <WidgetMap2 userInfo={userInfo} openSnack={this.props.openSnack} probes={probes} stations={stations} screenWidth={screenWidth}
                                                                                                            attributes={attributes} account={account} widgetKey={widgetKey} publicViewable={true}
                                                                                                            updateAttributes={(key, attributes) => console.log(key)} />

                                                                                                    )}
                                                                                                    {attributes.widgetType === 'map' && ![loadingProbes, loadingStations].includes(false) && (

                                                                                                        <Row align="middle" gutter={[8, 8]} justify="center">
                                                                                                            <Paper style={{ padding: 10, textAlign: 'center' }}>
                                                                                                                <CircularProgress />
                                                                                                            </Paper>
                                                                                                        </Row>

                                                                                                    )}
                                                                                                    {attributes.widgetType === 'text' && (
                                                                                                        <WidgetText attributes={attributes} account={account} userInfo={userInfo} />
                                                                                                    )}
                                                                                                    {attributes.widgetType === 'image' && (
                                                                                                        <WidgetImage attributes={attributes} account={account} userInfo={userInfo} />
                                                                                                    )}
                                                                                                    {attributes.widgetType === 'urlImage' && (
                                                                                                        <WidgetURLImage attributes={attributes} account={account} userInfo={userInfo} />
                                                                                                    )}
                                                                                                    {attributes.widgetType === 'carousel' && (
                                                                                                        <WidgetCarousel attributes={attributes} account={account} userInfo={userInfo} />
                                                                                                    )}
                                                                                                    {attributes.widgetType === 'timeline' && (
                                                                                                        <WidgetImageTimeline attributes={attributes}
                                                                                                            deleteWidget={() => {
                                                                                                                console.log('deleteWidget')
                                                                                                            }}
                                                                                                            widgetKey={widgetKey} account={account}
                                                                                                            userInfo={userInfo}
                                                                                                            setOpenTimelineDrawerFalse={() => console.log('setOpenTimelineDrawerFalse')}
                                                                                                            updateAttributes={(key, attributes) => console.log(key)}

                                                                                                        />
                                                                                                    )}
                                                                                                    {attributes.widgetType === 'capacity' && (
                                                                                                        <WidgetCapacity attributes={attributes} account={account} userInfo={userInfo} />
                                                                                                    )}
                                                                                                    {attributes.widgetType === 'reading' && (
                                                                                                        <WidgetReading attributes={attributes} account={account} userInfo={userInfo} index={index} />
                                                                                                    )}
                                                                                                    {attributes.widgetType === 'forecast' && (
                                                                                                        <WidgetForecast attributes={attributes} account={account} userInfo={userInfo} index={index} screenWidth={screenWidth} />
                                                                                                    )}
                                                                                                    {attributes.widgetType === 'gauge' && (
                                                                                                        <WidgetGauge attributes={attributes} account={account} userInfo={userInfo} />
                                                                                                    )}

                                                                                                    {attributes.widgetType === 'profile' && (
                                                                                                        <WidgetProfile attributes={attributes} account={account} userInfo={userInfo} />
                                                                                                    )}
                                                                                                    {attributes.widgetType === 'contour' && (
                                                                                                        <WidgetContour attributes={attributes} account={account} userInfo={userInfo} />
                                                                                                    )}


                                                                                                </Col>
                                                                                            </Row>
                                                                                        )
                                                                                        }


                                                                                    </Paper>


                                                                                </div>
                                                                            )
                                                                        }
                                                                        else {
                                                                            return null;
                                                                        }
                                                                    })}


                                                                </GridLayout>
                                                            )}

                                                        </Col>

                                                    </Row>
                                                </Col>
                                            </Row>
                                        )}


                                        {['sites'].includes(page) && (< Row >
                                            <Col span={23} style={{ paddingLeft: 30, paddingTop: 10, paddingBottom: 30 }}>

                                                <Row>
                                                    <Col span={24}>
                                                        <ClickableTable />
                                                    </Col>
                                                </Row>

                                            </Col>

                                        </Row>)}
                                        {['documents'].includes(page) && (
                                            < Row >
                                                <Col span={23} style={{ paddingLeft: 30, paddingTop: 10, paddingBottom: 30 }}>

                                                    <Row>
                                                        <Col span={24}>
                                                            <PortalDocuments userInfo={userInfo} screenWidth={screenWidth} openSnack={this.props.openSnack} />
                                                        </Col>
                                                    </Row>

                                                </Col>

                                            </Row>
                                        )}


















                                        {page === 'account' && publicLayout.length === 0 && (
                                            <Row justify='center'>
                                                <Col xs={14} style={{ padding: 50, paddingTop: 100, textAlign: 'center' }}>
                                                    <Typography variant="h4">Looks like there are no widgets added to this public dashboard.</Typography>
                                                </Col>
                                                <Col xs={24} style={{ textAlign: 'center' }}>


                                                    <Button variant='contained' color="primary"
                                                        onClick={() => {
                                                            this.changePage('sites')
                                                        }}
                                                        style={{ textTransform: 'none', fontSize: 18, fontWeight: 500, padding: 10, paddingLeft: 20, paddingRight: 20, borderRadius: 12 }}>
                                                        Go to Sites
                                                    </Button>
                                                </Col>



                                            </Row>
                                        )}






                                        {errorMessage !== '' && loading === false && (
                                            <Row justify='center'>
                                                <Col xs={14} style={{ padding: 50, paddingTop: 100, textAlign: 'center' }}>
                                                    <Typography variant="h4">{errorMessage}</Typography>
                                                </Col>
                                                <Col xs={24} style={{ textAlign: 'center' }}>
                                                    <ErrorOutlineIcon fontSize='large' />
                                                </Col>
                                                <Col xs={24} style={{ textAlign: 'center' }}>
                                                    <Typography variant="h6">Please try again.</Typography>
                                                </Col>
                                                <Col xs={24} style={{ textAlign: 'center' }}>
                                                    <Button
                                                        onClick={() => {
                                                            this.setState({ errorMessage: '', page: 'login' })
                                                        }}
                                                        style={{
                                                            textTransform: 'none', fontSize: 18, fontWeight: 500,
                                                            padding: 10, paddingLeft: 20, paddingRight: 20,
                                                            borderRadius: 12
                                                        }}>
                                                        Try Again
                                                    </Button>
                                                </Col>



                                            </Row>
                                        )}


                                        {loading === true && (
                                            <Row style={{ paddingTop: 100, paddingBottom: 100 }}>
                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                    <CircularProgress />
                                                </Col>
                                            </Row>
                                        )}







                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Content>
                </Layout >

                <Drawer

                    PaperProps={{
                        sx: { width: "50%" },
                    }}
                    open={openDrawer}
                    onClose={() => {
                        this.setState({ openDrawer: false })
                    }}
                >

                    <SiderMenuPublic changePage={this.changePage} closeDrawer={() => this.setState({ openDrawer: false })} screenWidth={screenWidth} company={company} mobile={true} />
                </Drawer>
            </Layout >




        );
    }
} 