import { Breadcrumbs, Button, ButtonGroup, Divider, MenuItem, OutlinedInput, Typography, Select, TextField } from '@mui/material';
import { Col, Modal, Row, } from 'antd';
import React, { useEffect, useState, useMemo } from 'react';
import { fullMobile } from '../util';
import 'react-data-grid/lib/styles.css';
import uuid from 'react-uuid';
import { memo } from 'react';
import moment from 'moment-timezone';
import { Link, useNavigate } from 'react-router-dom';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Box } from '@mui/system';
import { getFirestore, getDoc, doc, setDoc, updateDoc } from 'firebase/firestore';
import { queryEditEquipment } from '../../firebase/config';
import PumpItem from './equipmentTypes.jsx/pumpItem';
import EquipmentFilesTable from './equipmentFilesTable';
import FountainItem from './equipmentTypes.jsx/fountain';
import AeratorItem from './equipmentTypes.jsx/aeratorItem';
import { getDownloadURL, getStorage, ref, uploadBytesResumable, put } from "firebase/storage";
//create style of row paddingTop and bottom 15
//create style of row paddingTop and bottom 15

const style = {
    row: {
        paddingTop: 15,
        paddingBottom: 15,
        paddingLeft: 15,
        paddingRight: 15,
        //align center in div


    }
}





export default function EditEquipmentItem(props) {
    const { userInfo, screenWidth, openSnack, company, account, staffRef, usersRef } = props;
    const [item, setItem] = useState({})
    const [loading, setLoading] = useState(true)

    const [currentButton, setCurrentButton] = useState('general');


    useEffect(() => {
        window.scrollTo(0, 0)
        getInfo()
    }, [])
    const db = getFirestore()

    async function getInfo() {
        const editItem = await queryEditEquipment(userInfo)
        console.log(editItem)

        if ([null, undefined].includes(editItem)) {
            setItem({
                tempKey: uuid(),
                name: '',
                description: '',
                type: '',
                account: userInfo.currentAccount,
                company: userInfo.currentCompany,
                files: [],

            })
            setLoading(false)
        }
        else {
            setItem(editItem)
            setLoading(false)

        }



    }






    //create 50 rows with the schema above columns
    //create array from 0 to 50




    async function handleUpdateItem(boo, val) {
        const newItem = { ...item }
        if (boo) {
            newItem[boo] = val
        }
        setItem(newItem)
        console.log(newItem)
    }

    const SaveButton = () => {
        let navigate = useNavigate();
        const storage = getStorage();
        return (
            <Button variant={'contained'} display="inline"

                style={{
                    padding: 4, paddingTop: 8, paddingBottom: 8,
                    textTransform: 'none', backgroundColor: '#404050', fontSize: 13,
                }}
                onClick={async () => {
                    const newItem = { ...item }
                    console.log(newItem)
                    console.log(userInfo)
                    const docRef = doc(db, 'clients', userInfo.currentCompany, 'equipment', item?.tempKey);

                    if (item?.key === undefined) {
                        newItem.key = item?.tempKey
                        newItem.createdBy = userInfo.id
                        newItem.createdOn = moment().format()
                        console.log(newItem)

                        






                        await setDoc(docRef, newItem).then(() => {


                        }).then(() => {
                            openSnack('success', 'Equipment Created')
                            navigate('/equipmentList')
                        })
                    }
                    else {
                       


                        newItem.updatedBy = userInfo.id
                        newItem.updatedOn = moment().format()
                        console.log(newItem)
                        await updateDoc(docRef, newItem).then(() => {
                            openSnack('success', 'Equipment Updated')
                            navigate('/equipmentList')
                        })
                    }



                }}
            >
                {item?.key === undefined ? 'Create Equipment' : 'Update Equipment'
                }
            </Button >
        )
    }


    const equipmentTypes = [
        { label: 'Pump', key: 'pump' },
        { label: 'Vehicle', key: 'vehicle' },
        { label: 'Electrical Vault', key: 'electricalVault' },
        { label: 'Flow Valve', key: 'flowValve' },
        { label: 'Aerator', key: 'aerator' },
        { label: 'Fountain', key: 'fountain' },
        { label: 'Monitoring Equipment', key: 'monitoringEquipment' },
    ];


    return (

        <div style={{ fontFamily: 'Roboto, sans-serif', backgroundColor: '#f6f8fe', minHeight: '100vh' }}>
            <Row style={{ padding: 20, paddingTop: 50 }} >
                <Col {...fullMobile(24, 24)}>


                    <Breadcrumbs aria-label="breadcrumb">

                        <Link to="/equipmentList" style={{ color: '#404050', fontWeight: 500 }} >
                            Click to Return to Equipment list
                        </Link>

                    </Breadcrumbs>
                </Col>
                <Col {...fullMobile(24, 24)} style={{ paddingTop: 50, paddingBottom: 10 }}>
                    <Typography variant="h3" style={{ fontWeight: 300, color: '#404050' }}>
                        Edit {item?.name}
                    </Typography>

                </Col>
                <Col {...fullMobile(24, 24)}>
                    <Divider />
                </Col>
                <Col {...fullMobile(24, 24)} style={{ paddingTop: 10, paddingBottom: 10 }}>
                    <ButtonGroup variant="link" aria-label="contained primary button group">
                        {[
                            { name: 'general', label: 'General' },
                            //history
                            { name: 'history', label: 'History' },

                        ].map((item, index) => {
                            return (
                                <Button style={{
                                    color: '#404050', textTransform: 'none', fontSize: 14, borderRadius: 0,
                                    //add orange bottom border if current button is users
                                    borderBottom: currentButton === item.name ? '2px solid #f57c00' : 'none'
                                }}
                                    disabled={//history 
                                        item.name === 'history' ? true : false}
                                    onClick={() => {
                                        setCurrentButton(item.name)
                                        console.log(item.name)
                                    }}
                                >
                                    {item.label}
                                </Button>
                            )
                        })}

                    </ButtonGroup>

                </Col>
                <Col {...fullMobile(24, 24)} >
                    <Divider />
                </Col>




                <Col {...fullMobile(24, 24)} style={{ paddingTop: 15, paddingLeft: 30, paddingRight: 30 }}>
                    <Row gutter={[12, 12]} style={{ paddingBottom: 20, paddingTop: 10 }}>
                        <Col {...fullMobile(12, 24)}>
                            <Row >
                                <Col {...fullMobile(24, 24)} style={style.row} >
                                    <Typography variant="h5" style={{ fontWeight: 500, color: '#404050' }}>
                                        EQUIPMENT INFORMATION
                                    </Typography>
                                </Col>
                                <Col {...fullMobile(24, 24)} style={{ ...style.row, backgroundColor: 'white' }} >
                                    <Typography variant="body1" style={{ fontWeight: 600, color: '#404050' }}>
                                        Name
                                    </Typography>
                                    <OutlinedInput
                                        size="small"
                                        fullWidth
                                        id="outlined-adornment-amount"
                                        value={item?.name}
                                        onChange={(e) => {
                                            handleUpdateItem('name', e.target.value,)
                                        }}

                                    />
                                </Col>
                                <Col {...fullMobile(24, 24)} style={{ ...style.row, backgroundColor: 'white' }} >
                                    <Typography variant="body1" style={{ fontWeight: 600, color: '#404050' }}>
                                        Description
                                    </Typography>
                                    <OutlinedInput
                                        size="small"
                                        fullWidth
                                        multiline
                                        rows={4}
                                        id="outlined-adornment-amount"
                                        value={item?.description}
                                        onChange={(e) => {
                                            handleUpdateItem('description', e.target.value,)
                                        }}

                                    />
                                </Col>

                            </Row>
                        </Col>
                        <Col {...fullMobile(12, 24)}>
                            <Row >
                                <Col {...fullMobile(24, 24)} style={style.row} >
                                    <Typography variant="h5" style={{ fontWeight: 500, color: '#404050' }}>
                                        ADDITIONAL INFORMATION
                                    </Typography>
                                </Col>
                                <Col {...fullMobile(24, 24)} style={{ ...style.row, backgroundColor: 'white' }} >
                                    <Typography variant="body1" style={{ fontWeight: 600, color: '#404050' }}>
                                        Type
                                    </Typography>
                                    {loading === false && (<Select
                                        size="small"
                                        fullWidth
                                        value={item?.type}
                                        onChange={(e) => {
                                            handleUpdateItem('type', e.target.value,)
                                        }}
                                    >
                                        {equipmentTypes.sort((a, b) => a.label.localeCompare(b.label))
                                            .map((item, index) => {
                                                return (
                                                    <MenuItem key={index} value={item.key}>{item.label}</MenuItem>
                                                )
                                            })}
                                    </Select>)}
                                </Col>



                            </Row>
                        </Col>


                        {
                            item?.type === 'pump' && loading === false && (
                                <Col {...fullMobile(24, 24)}>
                                    <PumpItem screenWidth={screenWidth} userInfo={userInfo}
                                        openSnack={openSnack} item={item} updateItem={handleUpdateItem} />
                                </Col>
                            )
                        }
                        {
                            item?.type === 'fountain' && loading === false && (
                                <Col {...fullMobile(24, 24)}>
                                    <FountainItem screenWidth={screenWidth} userInfo={userInfo}
                                        openSnack={openSnack} item={item} updateItem={handleUpdateItem} />
                                </Col>
                            )
                        }
                        {
                            item?.type === 'aerator' && loading === false && (
                                <Col {...fullMobile(24, 24)}>
                                    <AeratorItem screenWidth={screenWidth} userInfo={userInfo}
                                        openSnack={openSnack} item={item} updateItem={handleUpdateItem} />
                                </Col>
                            )
                        }


                        <Col {...fullMobile(24, 24)} style={{}} >
                            <Divider />
                        </Col>
                        <Col {...fullMobile(24, 24)} style={{}} >
                            <EquipmentFilesTable screenWidth={screenWidth} userInfo={userInfo} openSnack={openSnack} itemRef={item}
                                updateItem={handleUpdateItem} />

                        </Col>



                    </Row>




                </Col>



                <Col {...fullMobile(24, 24)} style={{ paddingTop: 15, paddingLeft: 30, paddingRight: 30, textAlign: 'right' }} >
                    <SaveButton />

                </Col>
















            </Row >


        </div >

    );
}