import { collection, collectionGroup, doc, getDoc, getDocs, getFirestore, limit, onSnapshot, orderBy, query, setDoc, updateDoc, where, writeBatch } from "firebase/firestore";
import moment from 'moment-timezone';
import axios, * as others from 'axios';
import uuid from "react-uuid";


export const fetchData = async (userInfo, route) => {
    try {
        const db = getFirestore();
        const data = [];
        const queryData = query(collection(db, "clients", userInfo.currentCompany, route), where('archived', '==', false));
        const snapData = await getDocs(queryData);
        snapData.forEach((doc) => {
            data.push({ ...doc.data(), id: doc.id, label: doc.data().keyName });
        });
        /* sortby keyName */
        data.sort((a, b) => a.keyName.localeCompare(b.keyName));

        return data;
    }
    catch (error) {
        console.log('Error fetching data:', error);
    }
}

export const archiveData = async (userInfo, route, ids) => {
    try {
        const db = getFirestore();
        const batch = writeBatch(db);
        ids.forEach(id => {
            const docRef = doc(db, "clients", userInfo.currentCompany, route, id);
            batch.update(docRef, { archived: true });
        });
        await batch.commit();
    }
    catch (error) {
        console.log('Error deleting data:', error);
    }
}

export const updateData = async (userInfo, route, data) => {
    try {
        const db = getFirestore();
        /* update single document */

        const docRef = doc(db, "clients", userInfo.currentCompany, route, data.id);
        await updateDoc(docRef, data);

    }
    catch (error) {
        console.log('Error updating data:', error);
    }
}

export const createData = async (userInfo, route, data) => {
    try {
        const db = getFirestore();
        /* create single document */
        console.log(route)
        console.log(data)
        await setDoc(doc(db, "clients", userInfo.currentCompany, route, data.id), data);


    }
    catch (error) {
        console.log('Error creating data:', error);
    }
}


export const createKeys = async (userInfo, route) => {
    try {
        const db = getFirestore();

        /* get the default key doc and add it to all clients */
        const keyDoc = await getDoc(doc(db, "clients", "e467d47-003-6463-6e6b-ee08215ca686", 'probeApiKeys', "f56328da-1d67-28aa-6780-1fd38664b3cb"));
        const keyData = keyDoc.data();


        const data = [];
        const queryData = query(collection(db, "clients"));
        const snapData = await getDocs(queryData);
        snapData.forEach((doc) => {
            data.push(doc.id);
        });

        console.log(data) /* add this api to all clients */
        const singleTest = [data[0]];
        data.forEach(async (id, i) => {
            const newKey = { ...keyData, company: id, created: moment().format() };
            await setDoc(doc(db, "clients", id, 'probeApiKeys', newKey.id), newKey);

        });


    }
    catch (error) {
        console.log('Error fetching data:', error);
    }
}
export const createProbeAPIKey = async (companyKey) => {
    try {
        const db = getFirestore();
        /* get the default key doc and add it to all clients */
        const keyDoc = await getDoc(doc(db, "clients", "e467d47-003-6463-6e6b-ee08215ca686", 'probeApiKeys', "f56328da-1d67-28aa-6780-1fd38664b3cb"));
        const keyData = keyDoc.data();



        /* add this api to all clients */
        const singleTest = [companyKey];
        singleTest.forEach(async id => {

            const newKey = { ...keyData, company: id, created: moment().format() };
            await setDoc(doc(db, "clients", id, 'probeApiKeys', newKey.id), newKey);

        });
        return true;


    }
    catch (error) {
        console.log('Error fetching data:', error);
    }
}


export const updateStreamAccounts = async () => {
    try {
        const db = getFirestore();

        const data = [];
        const queryData = query(collectionGroup(db, "accounts"));
        const snapData = await getDocs(queryData);
        snapData.forEach((doc) => {
            data.push({ id: doc.id, path: doc.ref.path });
        });



        // Example of updating a document using its reference
        for (const item of data) {
            const docRef = doc(db, item.path); // Assuming `item.path` is the full path
            await updateDoc(docRef, {
                streamsProbesEditable: false
            });
        }



    }
    catch (error) {
        console.log('Error fetching data:', error);
    }
}
export const updateStations = async () => {
    try {
        const db = getFirestore();

        const keyDoc = await getDoc(doc(db, "clients", "e467d47-003-6463-6e6b-ee08215ca686", 'probeApiKeys', "f56328da-1d67-28aa-6780-1fd38664b3cb"));
        const keyData = keyDoc.data();

        const data = [];
        const queryData = query(collectionGroup(db, "stations"));
        const snapData = await getDocs(queryData);
        snapData.forEach((doc) => {
            data.push({ id: doc.id, path: doc.ref.path, children: doc.data().children });
        });
        console.log(data)

        data.forEach(async (item, i) => {
            console.log(i)
            /* update each item in children */
            const updatedChildren = item.children.map(child => ({
                ...child,
                sensor: {
                    ...child.sensor,
                    api_key: keyData.client_secret,
                    api_key_id: keyData.id,
                    api_key_client_id: keyData.client_id,
                    api_key_client_secret: keyData.client_secret,
                }
            }));

            // Update the document
            const docRef = doc(db, item.path); // Ensure item.path is correct
            await updateDoc(docRef, { children: updatedChildren });
        });
    }
    catch (error) {
        console.log('Error fetching data:', error);
    }
}
export const updateStationsAPIKey = async (userInfo, stream, openSnack) => {
    try {
        const db = getFirestore();
        console.log(stream);
        const data = [];
        const queryData = query(collection(db, "clients", userInfo.currentCompany, "stations"));
        const snapData = await getDocs(queryData);
        snapData.forEach((doc) => {
            data.push({ id: doc.id, path: doc.ref.path, children: doc.data().children });
        });
        console.log(data);
        /* filter out data that children have length 0 */
        const filterByChildrenLength = data.filter(item => item.children.length > 0);
        filterByChildrenLength.forEach(async (item, i) => {
            console.log(i);
            /* update each item in children */
            const updatedChildren = item.children.map(child => {
                if (child.sensor && child.sensor.key === stream.id) {
                    return {
                        ...child,
                        sensor: {
                            ...child.sensor,
                            api_key: stream.api_key_client_secret || null,
                            api_key_id: stream.api_key_id || null,
                            api_key_client_id: stream.api_key_client_id || null,
                            api_key_client_secret: stream.client_secret || null,
                        }
                    };
                }
                return child;
            });
            // Update the document
            const docRef = doc(db, item.path);
            // Ensure item.path is correct
            await updateDoc(docRef, { children: updatedChildren });
        });
    } catch (error) {
        console.log('Error fetching data:', error);
    }
};




export const updateProbes = async () => {
    try {
        const db = getFirestore();

        const data = [];
        const queryData = query(collectionGroup(db, "probes"));
        const snapData = await getDocs(queryData);
        snapData.forEach((doc) => {
            data.push({ id: doc.id, path: doc.ref.path });
        });



        const keyDoc = await getDoc(doc(db, "clients", "e467d47-003-6463-6e6b-ee08215ca686", 'probeApiKeys', "f56328da-1d67-28aa-6780-1fd38664b3cb"));
        const keyData = keyDoc.data();


        data.forEach(async (item, i) => {

            const docRef = doc(db, item.path); // Assuming `item.path` is the full path
            await updateDoc(docRef, {
                api_key: keyData.client_secret,
                api_key_id: keyData.id,
                api_key_client_id: keyData.client_id,
                api_key_client_secret: keyData.client_secret,
            });
        })







    }
    catch (error) {
        console.log('Error fetching data:', error);
    }
}