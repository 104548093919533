

import {
    Autocomplete,
    Badge,
    Box,
    Button,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    OutlinedInput,
    Popover,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import React from 'react';
import { faCheckCircle, faCircle, faCopy, faEdit, faEye, faEyeSlash, faSquare, faSquareCheck, faGear } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faDownload, faEllipsisH, faEllipsisV, faList, faPlus, faTags, faTrash } from '@fortawesome/free-solid-svg-icons';
import * as Papa from 'papaparse';
import moment from 'moment-timezone';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';

import SearchIcon from '@mui/icons-material/Search';
import uuid from 'react-uuid';
import { doc, getFirestore, setDoc } from 'firebase/firestore';

const HeaderOptions = ({ unitsArray, header, sortedData, visibleHeaders, deleteSelectedData, selectedData, setBulkEditor, getNestedProperty, setCurrentCell, setHeaders, headers, data, setData, openSnack,
    setParametersModal, setCodesModal, deleteHeader, parameters, setParameters, userInfo,
    type
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [searchQuery, setSearchQuery] = React.useState('');
    const [filteredUnits, setFilteredUnits] = React.useState(unitsArray);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setCurrentCell(null);
    };



    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;




    return (
        <div style={{
            display: 'inline',

        }}>
            <IconButton onClick={handleClick}

            >
                <Tooltip title="More Options" placement="bottom">
                    <FontAwesomeIcon icon={faEllipsisV}
                        size="sm" style={{ color: 'gray', cursor: 'pointer' }} />
                </Tooltip>



            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}

                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <List sx={{
                    width: 300,
                    padding: 0,
                    paddingBottom: 2,

                }}>
                    <Box sx={{ px: 2, mt: 2 }}>
                        <Typography variant="body1" color="black" fontSize={16} fontWeight={600}>Settings</Typography>
                        <Typography sx={{ pt: 2 }} variant="body1" color="black" fontSize={14} fontWeight={500}>Decimal Places</Typography>
                        <OutlinedInput
                            type="number"
                            sx={{
                                width: 80
                            }}
                            min={0}
                            max={10}  // Set max to 10
                            value={header?.decimals}
                            onChange={(e) => {
                                let value = e.target.value;

                                // Enforce the bounds [0, 10]
                                if (value < 0) {
                                    value = 0;
                                } else if (value > 10) {
                                    value = 10;
                                }

                                const newHeaders = headers.map((h) => {
                                    if (h.headerName === header.headerName) {
                                        return { ...h, decimals: value }
                                    }
                                    return h;
                                });
                                setHeaders(newHeaders);
                            }}
                            size="small"
                        />




                    </Box>












                </List>




            </Popover>
        </div>
    );
};

export default HeaderOptions;
