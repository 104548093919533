'use strict';
import { Button, Divider, Typography, TextField, Box, OutlinedInput, IconButton, CircularProgress } from '@mui/material';
import { Col, Row, Modal, Skeleton } from 'antd';
import React, { forwardRef, memo, useEffect, useImperativeHandle, useMemo, useRef, useState, useCallback } from 'react';
import { fullMobile } from './util';

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import moment from 'moment';
import MUIDataTable from "mui-datatables";
import DatePicker from "react-datepicker";
import uuid from 'react-uuid';
import { queryParameters, querySites, queryStations, queryLogData, queryAccounts } from '../../firebase/config';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, } from 'firebase/firestore';
import './tableStyle.css';
import "react-datepicker/dist/react-datepicker.css";
import ProbeParameters from '../../extra/probeParameters.json';
import Units from '../../extra/units.json';
import { CloudDownload, DeleteForever, RemoveRedEye, VisibilityOff } from '@mui/icons-material';
import axios, * as others from 'axios';
import { CSVLink } from 'react-csv';
var randomColor = require('randomcolor');


const getMuiTheme = () =>
    createTheme({
        components: {
            MUIDataTable: {
                styleOverrides: {
                    root: {
                        padding: '8px',
                        boxShadow: 'none'
                    }
                }
            },
            MuiTableRow: {
                styleOverrides: {
                    root: {
                        zIndex: 100,
                        cursor: 'pointer',
                        fontSize: '10px',
                        '&:nth-child(even)': {
                            backgroundColor: '#40405024'
                        },
                        "&:hover": {
                            backgroundColor: "#9ec1c933"
                        }


                    },


                }
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        fontSize: '12px',
                        padding: '2px'
                    }
                }
            }

        }
    });




export default function QueryStationData(props) {
    const { userInfo, screenWidth, openSnack, company, account, updateLog, logRef, status, } = props;

    const [data, setData] = useState([]);
    const [editingRow, setEditingRow] = useState(null);
    const [parameters, setParameters] = useState([]);
    const [probeParameters, setProbeParameters] = useState([]);
    const [sites, setSites] = useState([]);
    const [unitList, setUnitList] = useState([]);
    const [parameterModal, setParameterModal] = useState(false);
    const [siteModal, setSiteModal] = useState(false);
    const [parameterItem, setParameterItem] = useState({});
    const [siteItem, setSiteItem] = useState({});
    const [stations, setStations] = useState([]);
    const [loading, setLoading] = useState(false);
    const inputRef = useRef();
    const [parameterFilters, setParameterFilters] = useState([]);
    const [nodeFilters, setNodeFilters] = useState([]);
    const [startDate, setStartDate] = useState(new Date(moment().subtract('1', 'month').format('L')));
    const [endDate, setEndDate] = useState(new Date());
    const [accounts, setAccounts] = useState([]);
    const [saving, setSaving] = useState(false);










    useEffect(() => {
        getInfo()
        const unitList = Object.values(Units).map((u, i) => {
            return ({ label: u, key: u, unitid: Object.keys(Units)[i] })
        })
        setUnitList(unitList)


        const PPArray = Object.values(ProbeParameters).map((p, i) => {
            return ({
                label: p, key: Object.keys(ProbeParameters)[i]
            })
        });
        setProbeParameters(PPArray)


    }, [])


    function queryData() {
        setLoading(true)
        const params = parameterFilters.map((p) => p.key);
        const locals = nodeFilters.map((p) => p.id);

        const paramsList = params.map(p => `'${p}'`);
        const localsList = locals.map(p => `'${p}'`);
        const paramsWrapped = `(${paramsList.join(',')})`
        const localsWrapped = `(${localsList.join(',')})`

        const items = 'time, timestamp, locationid, parameterid, value, key, nodeid, unitid, account, company, logkey';


        const start = Number(moment(startDate).format('X'));
        const end = Number(moment(endDate).format('X'));

        const both = `SELECT ${items} FROM ${'node_data_new2'} WHERE parameterid IN ${paramsWrapped} AND  ${'nodeid'} IN ${localsWrapped} and timestamp >= ${start} 
        and timestamp <= ${end} ORDER BY time DESC; `
        const localRaw = `SELECT ${items} FROM ${'node_data_new2'} WHERE ${'nodeid'} IN ${localsWrapped} and timestamp >= ${start}
        and timestamp <= ${end} ORDER BY time DESC; `
        const paramRaw = `SELECT ${items} FROM ${'node_data_new2'} WHERE parameterid IN ${paramsWrapped} and timestamp >= ${start} 
        and timestamp <= ${end} ORDER BY time DESC; `;

        const raw = params.length > 0 && locals.length > 0 ? both :
            params.length === 0 && locals.length > 0 ? localRaw :
                params.length > 0 && locals.length === 0 ? paramRaw : `SELECT time, timestamp, value, locationlabel, locationid, parameterid, parameterlabel, 
            account, company FROM ${'node_data_new2'} WHERE timestamp >= ${start} 
            and timestamp <= ${end} ORDER BY time DESC;`


        const proxyUrl = "https://mycorslake.herokuapp.com/";
        axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
            raw
        }).then(async (response) => {
            // handle success
            setData(response.data)
            setLoading(false)

            openSnack('success', 'Data Queryed!')
        }).catch((error) => {
            console.log(error)
            openSnack('error', 'Error Querying Data!')
            setLoading(false)
        })


    }













    async function getInfo() {
        console.log('get info')
        const parameters = await queryParameters(userInfo)

        setParameters(parameters)
        const sites = await querySites(userInfo)
        setSites(sites)
        const stations = await queryStations(userInfo, ['grab', 'both', 'sensor'])
        console.log(stations)
        setStations(stations)
        const accounts = await queryAccounts(userInfo)
        setAccounts(accounts)

    }



    function updateData(key, item, val) {
        const index = data.map((e) => e.key).indexOf(key);
        const newData = [...data];
        newData[index][item] = val;
        setData(newData);

    }



    //if valueref is unfocused, update the data
    useEffect(() => {

    }, [inputRef.current])


    const gridRef = useRef(); // Optional - for accessing Grid's API

    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: (option) => option.label,
    });

    async function updateDatabase(key) {
        const item = data.find((d) => d.key === key)
        if (item !== undefined &&
            item.parameterid !== '' &&
            item.nodeid !== '' &&
            item.value !== ''
        ) {
            const proxyUrl = "https://mycorslake.herokuapp.com/";
            axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                raw: `UPDATE node_data_new2 SET 
                nodeid = '${item.nodeid}'
                , parameterid = '${item.parameterid}'
                , value = '${item.value}'
                , unitid = '${item.unitid}'
                , time = '${item.time}'
                , timestamp = '${item.timestamp}'
                , locationid = '${item.nodeid}'

                WHERE key = '${item.key}'
                ; `
            }).then(async (response) => {
                // handle success
                console.log('updated')
            }).catch((error) => {
                console.log(error)
                if (item !== undefined &&
                    item.parameterid !== '' &&
                    item.nodeid !== '' &&
                    item.value !== ''
                ) {
                    purgeData([item], true)
                }
            })
        }
    }


    const options = {
        filter: false,
        searchable: false,
        search: false,
        searchable: false,
        viewColumns: false,
        download: false,
        print: false,
        draggable: true,
        rowsPerPage: 50,
        rowsPerPageOptions: [50, 100, 200, 500, 1000],
        selectableRows: screenWidth === 'xs' ? false : status === 'built' ? false : true,
        filterType: 'dropdown',
        responsive: 'stacked',
        onRowClick: (rowData, rowMeta, b) => {
            console.log(editingRow)
            updateDatabase(editingRow)
            const clickedRow = gridRef.current.state.data[rowMeta.dataIndex];
            const editingkey = clickedRow.data[0];
            setEditingRow(editingkey)


        },
        onRowsDelete: (rows, b) => {
            //use rows.lookup to get the index of the rows to be deleted and remove them from data
            const rowsToDelete = Object.keys(rows.lookup);
            const newData = data.filter((row, index) => {
                return !rowsToDelete.includes(index.toString());
            });
            setData(newData);
            const deletedKeys = rowsToDelete.map((index) => data[index].key);
            const proxyUrl = "https://mycorslake.herokuapp.com/";
            axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                raw: `DELETE FROM node_data_new2 
                    WHERE key IN (${deletedKeys.map((e) => `'${e}'`).join(',')});`

            }).then(async (response) => {
                openSnack('success', 'Data deleted!')
            }).catch((error) => {
                openSnack('error', 'Error deleting data!')
            })

        }
    };

    const columns = [

        {
            name: 'key',
            label: "Edit",

            options: {
                //hide column
                display: false,
                filter: false,
                sort: false,
                empty: true,

            }
        },
        {
            name: "time",
            label: 'Date',
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const key = tableMeta.rowData[0];
                    const editing = key === editingRow;
                    const index = data.map((e) => e.key).indexOf(key);
                    const time = data[index].time
                    if (editing && status !== 'built') {

                        return (

                            <DatePicker dateFormat="MM/dd/yyyy hh:mm a" style={{ width: '200px' }} showTimeSelect timeFormat="h:mm a" timeIntervals={1}
                                timeCaption="Time"
                                portalId="root-portal"
                                selected={[undefined, '', "Invalid date"].includes(time) ? new Date() :
                                    new Date(moment(time, 'YYYY-MM-DD HH:mm').format('MM/DD/YYYY hh:mm a'))}
                                onChange={(date) => {

                                    const unix = moment(date).format('x');
                                    updateData(key, 'time', moment(unix, 'x').tz("America/Los_Angeles").format("YYYY-MM-DD HH:mm:ss"));
                                    updateData(key, 'timestamp', moment(unix, 'x').format('X'));





                                }} />

                        )
                    }

                    else {
                        return (
                            <div>{moment(time, 'YYYY-MM-DD HH:mm').format('MM/DD/YYYY h:mm a')}</div>
                        )
                    }
                }


            }
        },
        {
            name: "parameterid",
            label: "Parameter",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const key = tableMeta.rowData[0];
                    const editing = key === editingRow;
                    if (editing && status !== 'built') {
                        const index =  [...probeParameters, ...parameters].map((e) => e.key).indexOf(value);
                        const label = index === -1 ? '' : [...probeParameters, ...parameters][index].label;
                        return (

                            <Autocomplete
                                style={{ width: 200 }}
                                inputProps={{ style: { width: 200 } }}
                                size="small"
                                id="combo-box-demo"
                                defaultValue={label}
                                disableClearable={true}
                                options={[...probeParameters, ...parameters]}
                                filterOptions={(options, _ref) => [...filterOptions(options, _ref), { label: 'Add New', key: 'Add New', value: 'addNew' },]}
                                noOptionsText={<Button fullWidth variant='contained' color='primary' style={{ textTransform: 'none' }}
                                    onClick={() => {
                                        setParameterModal(true)
                                        setParameterItem({ tempKey: uuid(), depthFromBottom: 0 });
                                    }}
                                >+ Create Parameter</Button>}
                                renderInput={(params) => <TextField {...params} label="Parameters" size="small" />}
                                renderOption={(params) => {

                                    if (params.key === 'Add New') {
                                        return (
                                            <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 4 }}>
                                                <Button fullWidth variant='contained' color='primary' style={{ textTransform: 'none' }} onClick={(e) => {
                                                    setParameterModal(true)
                                                    setParameterItem({ tempKey: uuid(), depthFromBottom: 0 });


                                                }}>+ Create Parameter</Button>
                                            </Box>

                                        )
                                    }
                                    else {
                                        return (
                                            <Typography {...params}>{params.key}</Typography>
                                        )
                                    }
                                }}
                                onChange={(a, d) => {
                                    if (d.key === 'Add New') {
                                        setParameterModal(true)
                                        setParameterItem({ tempKey: uuid(), depthFromBottom: 0 });
                                    }
                                    else {
                                        updateData(key, 'parameterid', d.key);
                                        updateData(key, 'parameterLabel', d.label);
                                    }

                                }}

                            />



                        )
                    }

                    else {
                        const index = [...probeParameters, ...parameters].map((e) => e.key).indexOf(value);
                        const label = index === -1 ? '' : [...probeParameters, ...parameters][index].label;
                        return (
                            <div>{label}</div>
                        )
                    }
                }

            }
        },
        {
            name: "nodeid",
            label: "Site",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const key = tableMeta.rowData[0];
                    const editing = key === editingRow;
                    if (editing && status !== 'built') {

                        const index = stations.map((e) => e.id).indexOf(value);

                        const label = index === -1 ? '' : stations[index].label;

                        return (



                            <Autocomplete
                                style={{ width: 200 }}
                                inputProps={{ style: { width: 200 } }}
                                size="small"
                                id="combo-box-demo"
                                disableClearable={true}
                                value={label}
                                options={stations}
                                filterOptions={(options, _ref) => [...filterOptions(options, _ref),]}



                                //render input as id
                                renderInput={(params) => <TextField {...params} label="Sites" size="small" />}
                                renderOption={(params) => {

                                    return (
                                        <Typography {...params}>{params.key}</Typography>
                                    )
                                }}
                                onChange={(a, d) => {
                                    if (d.key === 'Add New') {
                                        setSiteModal(true)
                                        setSiteItem({ tempKey: uuid(), });
                                    }
                                    else {

                                        updateData(key, 'nodeid', d.id);
                                        updateData(key, 'locationLabel', d.label);
                                    }

                                }}

                            />
                        )
                    }

                    else {
                        const index = stations.map((e) => e.id).indexOf(value);
                        const label = index === -1 ? '' : stations[index].label;
                        return (
                            <div>{label}</div>
                        )
                    }
                }
            }
        },
        {
            name: "value",
            label: "Value",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {

                    const key = tableMeta.rowData[0];
                    const editing = key === editingRow;
                    if (editing && status !== 'built') {

                        return (

                            <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                inputRef={inputRef}
                                id={`${key}value`}
                                width={80}
                                inputProps={{ style: { width: 100 } }}
                                placeholder="Value"
                                defaultValue={value}
                                type="number"
                                onChange={(e) => {
                                    updateData(key, 'value', e.target.value === "" ? "" : Number(e.target.value))
                                }} />
                        )
                    }

                    else {
                        return (
                            <div>{value}</div>
                        )
                    }
                }

            }
        },
        {
            name: "unitid",
            label: "Units",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {


                    const key = tableMeta.rowData[0];
                    const editing = key === editingRow;
                    if (editing && status !== 'built') {
                        const index = unitList.map((e) => e.key).indexOf(value);
                        const label = index === -1 ? '' : unitList[index].label;
                        return (

                            <Autocomplete
                                size="small"
                                id="combo-box-demo"
                                disableClearable={false}
                                value={label}
                                options={unitList}
                                renderInput={(params) => <TextField {...params} label="Units" size="small" />}
                                renderOption={(params) => {
                                    return (
                                        <Typography {...params}>{params.key}</Typography>
                                    )
                                }}
                                onChange={(a, d) => {
                                    if (d) {
                                        updateData(key, 'unitid', d.key);
                                    }
                                    else {
                                        updateData(key, 'unitid', '');
                                    }

                                }}
                            />



                        )
                    }

                    else {
                        const index = unitList.map((e) => e.key).indexOf(value);
                        const label = index === -1 ? '' : unitList[index].label;
                        return (
                            <div>{label}</div>
                        )
                    }
                }
            }
        },





    ];


    const AddRowButton = memo(() => {
        const [date, setDate] = useState(new Date());
        const [parameter, setParameter] = useState('');
        const [location, setLocation] = useState('');
        const [value, setValue] = useState('');
        const [unit, setUnit] = useState('');

        const handleAddRow = () => {
            const newDate = moment(date).format('YYYY-MM-DD HH:mm');
            const newData = [{ key: uuid(), time: newDate, parameterid: parameter, nodeid: location, value: value, unitid: unit, editing: false, logkey: '', account: userInfo.currentAccount, company: userInfo.currentCompany }, ...data,];
            setData(newData);
            setParameter('');
            setLocation('');
            setValue('');
            setUnit('');
            setDate(new Date());
        }

        return (
            <Button size="small"
                variant="contained" color="primary"
                style={{ textTransform: 'none', fontSize: 14, backgroundColor: '#404050' }}
                onClick={handleAddRow} >+ Add Row</Button >
        )
    })


    const CreateParameter = ({ parameterItem, userInfo, account }) => {
        return (
            <Button size="small" style={{ padding: 10, borderRadius: 4, textTransform: 'none', fontSize: 14, }}
                variant='contained' color="primary"
                onClick={async () => {
                    const db = getFirestore()
                    const currentItem = parameterItem;
                    currentItem.name = parameterItem.name || "";
                    const params = data.map((d) => d.name);
                    const checks = [
                        currentItem.name.length === 0 ? false : true,
                        params.includes(currentItem.name) ? false : true,
                    ]
                    if (currentItem.name.length === 0) {
                        this.props.openSnack('error', "Parameter name required!")
                    }
                    if (params.includes(currentItem.name)) {
                        this.props.openSnack('error', "Parameter name already exists!")
                    }
                    if (!checks.includes(false)) {

                        const key = currentItem.key || currentItem.tempKey;
                        currentItem.archived = currentItem.archived || false;

                        currentItem.account = userInfo.currentAccount;
                        currentItem.company = userInfo.currentCompany;


                        const docRef = doc(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'parameters', key);

                        if (currentItem.key) {
                            await updateDoc(docRef, currentItem).then(() => {
                                openSnack('success', currentItem.key ? 'Parameter updated!' : "Parameter created!")
                            })
                        }
                        else {
                            await setDoc(docRef, {
                                ...currentItem,
                                key: currentItem.tempKey
                            }).then(async () => {
                                const parameters = await queryParameters(userInfo);
                                setParameters(parameters);
                                openSnack('success', currentItem.key ? 'Parameter updated!' : "Parameter created!")
                                setParameterModal(false)
                            })
                        }


                    }




                }}>{parameterItem.key ? 'Update Parameter' : "Create Parameter"}</Button>
        )
    }


    function EnterClick(e) {
        //if (e.key === 'Enter') change editing row to null
        if (e.key === 'Enter') {
            setEditingRow(null);
        }

        //if (e.key === 'Escape') change editing row to null
        if (e.key === 'Escape') {

            setEditingRow(null);
        }

    }


    function updateTimeframe(time, units) {
        const { startDate, endDate } = this.state;
        setStartDate(new Date(moment().subtract(time, units).format('L')));
        setEndDate(new Date());



    }


    function dataFromAsyncProcess() {
        console.log(company)

        const newData = data.filter((row) => {
            return row.parameterid !== undefined && row.nodeid !== undefined && row.value !== undefined && row.parameterid !== '' && row.nodeid !== '' && row.value !== ''
        });

        const csvData = newData.map((d) => {
            const station = stations.filter((s) => s.key === d.locationid)[0];
            const parameter = [...probeParameters, ...parameters].filter((p) => p.key === d.parameterid)[0];
            const accountName = accounts.filter((a) => a.key === d.account)[0].accountName;
            const companyName = company.companyName;


            return {
                time: d.time,
                node: station ? station.name : '',
                parameter: parameter ? parameter.name : '',
                value: d.value,
                unit: d.unitid,
                account: accountName,
                company: companyName,
                logkey: d.logkey,
                key: d.key,
                nodeid: d.nodeid,
                parameterid: d.parameterid,
                unitid: d.unitid,
            }
        })
        return csvData;


    }

    function purgeData(list, add) {
        setSaving(true);
        //remove all rows that are missing any data or have undefined data for parameterid, nodeid, or value
        const newData = list.filter((row) => {
            return row.parameterid !== undefined && row.nodeid !== undefined && row.value !== undefined && row.parameterid !== '' && row.nodeid !== '' && row.value !== ''
        });
        if (add === false) {
            setData(newData);
        }


        console.log(stations)
        const newDataList = newData.map((row) => {
            const station = stations.find((s) => s.id === row.nodeid)
            const lat = station.lat || 0
            const lng = station.lng || 0

            const item = {
                time: `'${row.time}'`,
                timestamp: Number(moment(row.time, "YYYY-MM-DD HH:mm").format("X")),
                locationid: `'${row.locationid}'`,
                unitid: `'${row.unitid || ''}'`,
                parameterid: `'${row.parameterid}'`,
                value: row.value,
                account: `'${row.account || userInfo.currentAccount}'`,
                company: `'${row.company || userInfo.currentCompany}'`,
                nodeid: `'${row.nodeid}'`,
                key: `'${uuid()}'`,
                longitude: row.lng || lng,
                latitude: row.lat || lat,
                logkey: `'${row.key}'`,
            }
            return item
        })



        const hourlyVals = newDataList.map((h) => `(${Object.values(h)})`);
        const hourlyString = hourlyVals.join();

        const proxyUrl = "https://mycorslake.herokuapp.com/";
        axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
            raw: `INSERT INTO node_data_new2 VALUES ${hourlyString} ON CONFLICT DO NOTHING; `
        }).then(async (response) => {
            setSaving(false);
        }).catch((error) => {
            console.log(error)
            setSaving(false);
        }
        )



    }


    return (
        <Row gutter={[12, 12]} align="middle" >
            < EnterClick />
            <Col {...fullMobile(12, 12)} style={{ paddingTop: 30 }}>
                <Typography display="inline" variant='h5' fontWeight={500} fontSize={screenWidth === 'xs' ? 16 : 22}>Query Station Data</Typography>



            </Col>
            <Col {...fullMobile(12, 12)} style={{ paddingTop: 30, textAlign: 'right' }}>
                {status !== 'built' && (<AddRowButton />)}


            </Col>
            <Col xs={24} sm={12} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                <Autocomplete
                    size="small"
                    disableClearable={false}
                    id="combo-box-demo"
                    value={parameterFilters || []}
                    ChipProps={{ color: 'primary' }}
                    multiple
                    disableCloseOnSelect
                    options={[...probeParameters, ...parameters].sort((a, b) => a.label.localeCompare(b.label))}
                    filterOptions={(options, _ref) => [...filterOptions(options, _ref),
                    { label: 'Select All', key: 'Select All', value: 'addNew' },
                    { label: 'Unselect All', key: 'Unselect All', value: 'addNew' },
                    ]}
                    filterSelectedOptions
                    renderInput={(params) => <TextField {...params} label="Parameters" size="small" />}
                    renderOption={(params) => {

                        if (['Select All', 'Unselect All'].includes(params.key)) {
                            const label = params.key === 'Select All' ? 'Select All' : 'Unselect All'
                            return (
                                <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 4 }}>
                                    <Button fullWidth variant='contained' color={params.key === 'Select All' ? 'primary' : 'secondary'} style={{ textTransform: 'none' }} onClick={(e) => {

                                        if (params.key === 'Unselect All') {
                                            setParameterFilters([])
                                        } else {
                                            setParameterFilters([...probeParameters, ...parameters])
                                        }


                                    }}>{label}</Button>
                                </Box>

                            )
                        }
                        else {
                            return (
                                <Typography {...params}>{params.key}</Typography>
                            )
                        }
                    }}
                    onChange={(a, b) => {
                        setParameterFilters(b)
                    }}

                />
            </Col>
            <Col xs={24} sm={12} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                <Autocomplete
                    size="small"
                    disableClearable={false}
                    id="combo-box-demo"
                    value={nodeFilters || []}
                    ChipProps={{ color: 'primary' }}
                    multiple
                    disableCloseOnSelect
                    options={stations.sort((a, b) => a.name.localeCompare(b.name))}
                    filterOptions={(options, _ref) => [...filterOptions(options, _ref),
                    { label: 'Select All', key: 'Select All', value: 'addNew' },
                    { label: 'Unselect All', key: 'Unselect All', value: 'addNew' },
                    ]}
                    filterSelectedOptions
                    renderInput={(params) => <TextField {...params} label="Stations" size="small" />}
                    renderOption={(params) => {

                        if (['Select All', 'Unselect All'].includes(params.key)) {
                            const label = params.key === 'Select All' ? 'Select All' : 'Unselect All'
                            return (
                                <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 4 }}>
                                    <Button fullWidth variant='contained' color={params.key === 'Select All' ? 'primary' : 'secondary'} style={{ textTransform: 'none' }} onClick={(e) => {

                                        if (params.key === 'Unselect All') {
                                            setNodeFilters([])
                                        } else {
                                            setNodeFilters(stations)
                                        }


                                    }}>{label}</Button>
                                </Box>

                            )
                        }
                        else {
                            return (
                                <Typography {...params}>{params.key}</Typography>
                            )
                        }
                    }}
                    onChange={(a, b) => {
                        setNodeFilters(b)
                    }}

                />
            </Col>

            <Col sm={8} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                <Typography variant='body1'>Start Date</Typography>
                <DatePicker timeCaption="Time" showTimeSelect timeFormat="h:mm a" timeIntervals={1}
                    dateFormat="MM/dd/yyyy hh:mm a" portalId="root-portal" selected={startDate} onChange={(date) => {

                        setStartDate(date)
                    }} />

            </Col>
            <Col sm={8} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                <Typography variant='body1'>End Date</Typography>
                <DatePicker timeCaption="Time" showTimeSelect timeFormat="h:mm a" timeIntervals={1}
                    dateFormat="MM/dd/yyyy hh:mm a" portalId="root-portal" selected={endDate} onChange={(date) => {

                        setEndDate(date)
                    }} />

            </Col>
            <Col sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                <Typography variant='body1'>Date Range</Typography>
                <Row gutter={[12, 12]} align="middle">
                    <Col>
                        <Button variant="outlined" color="primary"
                            style={{ textTransform: 'none', padding: 8, fontSize: 14 }}
                            onClick={() => updateTimeframe('1', 'week')} >Last Week</Button>
                    </Col>
                    <Col>
                        <Button variant="outlined" color="primary"
                            style={{ textTransform: 'none', padding: 8, fontSize: 14 }}
                            onClick={() => updateTimeframe('1', 'month')} >Last Month</Button>
                    </Col>
                    <Col>
                        <Button variant="outlined" color="primary"
                            style={{ textTransform: 'none', padding: 8, fontSize: 14 }}
                            onClick={() => updateTimeframe('3', 'month')}>3 Months</Button>
                    </Col>
                    <Col>
                        <Button variant="outlined" color="primary"
                            style={{ textTransform: 'none', padding: 8, fontSize: 14 }}
                            onClick={() => updateTimeframe('6', 'month')}>6 Months</Button>
                    </Col>
                </Row>

            </Col>

            {[...parameterFilters, ...nodeFilters].length > 0 && [...nodeFilters].length > 0 && startDate !== undefined && endDate !== undefined && (
                <Col sm={18} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                    {loading ? <CircularProgress /> : <Button style={{ textTransform: 'none', fontSize: 14 }} variant="contained" color="primary"
                        onClick={async () => {
                            queryData()
                        }}>Query Data</Button>}


                </Col>)}
            {[...parameterFilters, ...nodeFilters].length > 0 && [...nodeFilters].length > 0 && startDate !== undefined && endDate !== undefined && (
                <Col sm={3} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                    {loading ? <CircularProgress /> : <CSVLink data={dataFromAsyncProcess()} filename={"data-query.csv"}>
                        <Button variant='outlined'>
                            <CloudDownload />
                        </Button>
                    </CSVLink>}


                </Col>)}
            {[...parameterFilters, ...nodeFilters].length > 0 && [...nodeFilters].length > 0 && startDate !== undefined && endDate !== undefined && (
                <Col sm={3} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                    {loading ? <CircularProgress /> : <Button variant='outlined' onClick={() => {
                        purgeData(data, false)
                    }}>
                        Save Data
                    </Button>}


                </Col>)}


            {loading && (
                <Col {...fullMobile(24, 24)} style={{ paddingTop: 10, }}>
                    <Skeleton variant="rect" width={'100%'} animation="wave" height={300} />
                </Col>
            )}

            {!loading && (

                <Col onKeyPress={EnterClick} id="table" {...fullMobile(24, 24)} style={{ paddingTop: 10, position: 'relative', zIndex: 10 }}>
                    <ThemeProvider theme={getMuiTheme()}>
                        <MUIDataTable ref={gridRef} data={data} columns={screenWidth === 'xs' ? [...columns, {
                            name: 'key',
                            label: "Delete",
                            options: {
                                filter: true,
                                sort: false,
                                empty: true,
                                customBodyRender: (value, b, updateValue) => {

                                    return (
                                        <div>
                                            <IconButton size="small" aria-label="delete" color={'inherit'} onClick={() => {
                                                //delete this item from data
                                                const index = data.map((e, i) => e.key).indexOf(value);
                                                const item = data[index];

                                                const deletedKeys = [item.key];
                                                const rowsToDelete = [item.key];
                                                const newData = data.filter((e) => !rowsToDelete.includes(e.key));
                                                setData(newData);
                                                const proxyUrl = "https://mycorslake.herokuapp.com/";
                                                axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                                                    raw: `DELETE FROM node_data_new2 
                    WHERE key IN (${deletedKeys.map((e) => `'${e}'`).join(',')});`

                                                }).then(async (response) => {
                                                    openSnack('success', 'Data deleted!')
                                                }).catch((error) => {
                                                    openSnack('error', 'Error deleting data!')
                                                })



                                            }}>
                                                <DeleteForever />
                                            </IconButton>

                                        </div>

                                    )

                                }
                            }
                        },] : columns} options={options} />
                    </ThemeProvider>

                </Col>

            )}

            <Col {...fullMobile(24, 24)}>
                <Divider style={{
                    borderColor: 'rgb(148, 186, 195)',
                    borderBottomWidth: 'medium',

                }} />
            </Col>

            <Modal title="" visible={parameterModal} onCancel={() => {
                setParameterModal(false)
                setParameterItem({})

            }} footer={null} width={'60%'} style={{ borderRadius: 40 }} >
                <Row align="middle" gutter={[16, 16]} >
                    <Col xs={{ offset: 0, span: 24 }} sm={{ offset: 6, span: 18 }} style={{ paddingBottom: 12, }}>
                        <Typography variant='h5'>Parameter</Typography>
                    </Col>
                    <Col xs={{ offset: 0, span: 24 }} sm={{ offset: 6, span: 18 }} style={{ paddingBottom: 12, }}>
                        <Typography fontWeight={300} variant='body1'>Reminder, you will be able to associate all water quality samples with either the parameter ID or parameter name.
                            The parameter name will be associated as case sensitive for all uploaded CSV files.</Typography>
                    </Col>


                    <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                        <Typography variant="body1">Name<span style={{ color: 'red' }}>*</span></Typography>
                    </Col>
                    <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                        <OutlinedInput fullWidth notched={false} label="none" placeholder={"Name"} size="small"
                            value={parameterItem?.name || ''}
                            onChange={(e) => {
                                setParameterItem({ ...parameterItem, name: e.target.value })


                            }} />
                    </Col>






                    < Col xs={{ offset: 0, span: 24 }} sm={{ offset: 6, span: 18 }} style={{ paddingBottom: 12, }}>
                        <CreateParameter parameterItem={parameterItem} userInfo={userInfo} account={account} />
                    </Col>





                </Row>
            </Modal >



        </Row>
    )



}