'use strict';
import { Button, Divider, Typography, TextField, Box, OutlinedInput, IconButton, CircularProgress, Step, Select, MenuItem } from '@mui/material';
import { Col, Row, Modal, Skeleton, Steps } from 'antd';
import React, { CSSProperties, forwardRef, memo, useEffect, useImperativeHandle, useMemo, useRef, useState, useCallback } from 'react';
import { fullMobile } from './util';

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import moment from 'moment';
import MUIDataTable from "mui-datatables";
import DatePicker from "react-datepicker";
import uuid from 'react-uuid';
import { queryParameters, querySites, queryStations, queryLogData, queryAccounts } from '../../firebase/config';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, } from 'firebase/firestore';
import './tableStyle.css';
import "react-datepicker/dist/react-datepicker.css";
import ProbeParameters from '../../extra/probeParameters.json';
import Units from '../../extra/units.json';
import { CloudDownload, DeleteForever, RemoveRedEye, VisibilityOff } from '@mui/icons-material';
import axios, * as others from 'axios';
import { CSVLink } from 'react-csv';
import { codes } from './newData/codes';

import {
    useCSVReader,
    lightenDarkenColor,
    formatFileSize,
} from 'react-papaparse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';



const GREY = '#CCC';
const GREY_LIGHT = 'rgba(255, 255, 255, 0.4)';
const DEFAULT_REMOVE_HOVER_COLOR = '#A01919';
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(
    DEFAULT_REMOVE_HOVER_COLOR,
    40
);
const GREY_DIM = '#686868';

const styles = {
    zone: {
        alignItems: 'center',
        border: `2px dashed ${GREY}`,
        borderRadius: 20,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        padding: 20,
        backgroundColor: 'white'
    },
    file: {
        background: 'linear-gradient(to bottom, #EEE, #DDD)',
        borderRadius: 20,
        display: 'flex',
        height: 120,
        width: 120,
        position: 'relative',
        zIndex: 10,
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: 'white'
    },
    info: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 10,
        paddingRight: 10,
    },
    size: {
        backgroundColor: GREY_LIGHT,
        borderRadius: 3,
        marginBottom: '0.5em',
        justifyContent: 'center',
        display: 'flex',
    },
    name: {
        backgroundColor: GREY_LIGHT,
        borderRadius: 3,
        fontSize: 12,
        marginBottom: '0.5em',
    },
    progressBar: {
        bottom: 14,
        position: 'absolute',
        width: '100%',
        paddingLeft: 10,
        paddingRight: 10,
    },
    zoneHover: {
        borderColor: GREY_DIM,
        backgroundColor: 'white'
    },
    default: {
        borderColor: GREY,
    },
    remove: {
        height: 23,
        position: 'absolute',
        right: 6,
        top: 6,
        width: 23,
    },
};


var randomColor = require('randomcolor');


const getMuiTheme = () =>
    createTheme({
        components: {
            MUIDataTable: {
                styleOverrides: {
                    root: {
                        padding: '8px',
                        boxShadow: 'none'
                    }
                }
            },
            MuiTableRow: {
                styleOverrides: {
                    root: {
                        zIndex: 100,
                        cursor: 'pointer',
                        fontSize: '10px',
                        '&:nth-child(even)': {
                            backgroundColor: '#40405024'
                        },
                        "&:hover": {
                            backgroundColor: "#9ec1c933"
                        }


                    },


                }
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        fontSize: '12px',
                        padding: '2px'
                    }
                }
            }

        }
    });




export default function UploadDataNew(props) {
    const { CSVReader } = useCSVReader();
    const [zoneHover, setZoneHover] = useState(false);
    const [removeHoverColor, setRemoveHoverColor] = useState(
        DEFAULT_REMOVE_HOVER_COLOR
    );
    const { userInfo, screenWidth, openSnack, company, account, updateLog, logRef, status, } = props;

    const [data, setData] = useState([]);
    const [editingRow, setEditingRow] = useState(null);
    const [parameters, setParameters] = useState([]);
    const [probeParameters, setProbeParameters] = useState([]);
    const [sites, setSites] = useState([]);
    const [unitList, setUnitList] = useState([]);
    const [parameterModal, setParameterModal] = useState(false);
    const [siteModal, setSiteModal] = useState(false);
    const [parameterItem, setParameterItem] = useState({});
    const [siteItem, setSiteItem] = useState({});
    const [stations, setStations] = useState([]);
    const [loading, setLoading] = useState(false);
    const inputRef = useRef();
    const [parameterFilters, setParameterFilters] = useState([]);
    const [nodeFilters, setNodeFilters] = useState([]);
    const [startDate, setStartDate] = useState(new Date(moment().subtract('1', 'month').format('L')));
    const [endDate, setEndDate] = useState(new Date());
    const [accounts, setAccounts] = useState([]);
    const [saving, setSaving] = useState(false);
    const [step, setStep] = useState('upload');
    const [subStep, setSubStep] = useState('dataType');
    const csvReaderRef = useRef();
    const [dataType, setDataType] = useState('');
    const [keys, setKeys] = useState([]);
    const [dateMap, setDateMap] = useState('');
    const [parameterMap, setParameterMap] = useState('');
    const [valueMap, setValueMap] = useState('');
    const [nodeMap, setNodeMap] = useState('');
    const [unitMap, setUnitMap] = useState('');
    const [codeMap, setCodeMap] = useState('');
    const [dateFormat, setDateFormat] = useState('MM/DD/YYYY');
    const [fileData, setFileData] = useState([]);
    const [multipleData, setMultipleData] = useState([]);











    useEffect(() => {
        getInfo()
        const unitList = Object.values(Units).map((u, i) => {
            return ({ label: u, key: u, unitid: Object.keys(Units)[i] })
        })
        setUnitList(unitList)


        const PPArray = Object.values(ProbeParameters).map((p, i) => {
            return ({
                name: p, label: p, key: Object.keys(ProbeParameters)[i], parameterType: 'Probe Parameter'
            })
        });
        setProbeParameters(PPArray)


    }, [])
















    async function getInfo() {

        const parameters = await queryParameters(userInfo)
        parameters.forEach((p, i) => {
            p.parameterType = 'Custom Paramter'
        })

        setParameters(parameters)
        const sites = await querySites(userInfo)
        setSites(sites)
        const stations = await queryStations(userInfo, ['grab', 'both', 'sensor'])

        setStations(stations)
        const accounts = await queryAccounts(userInfo)
        setAccounts(accounts)

    }



    function updateData(key, item, val) {
        const index = data.map((e) => e.key).indexOf(key);
        const newData = [...data];
        newData[index][item] = val;
        setData(newData);

    }



    //if valueref is unfocused, update the data
    useEffect(() => {

    }, [inputRef.current])


    const gridRef = useRef(); // Optional - for accessing Grid's API

    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: (option) => option.label,
    });




    const options = {
        filter: false,
        searchable: false,
        search: false,
        searchable: false,
        viewColumns: false,
        download: false,
        print: false,
        draggable: true,
        rowsPerPage: 50,
        rowsPerPageOptions: [50, 100, 200, 500, 1000],
        selectableRows: screenWidth === 'xs' ? false : status === 'built' ? false : true,
        filterType: 'dropdown',
        responsive: 'stacked',
        onRowClick: (rowData, rowMeta, b) => {


            const clickedRow = gridRef.current.state.data[rowMeta.rowIndex];
            const editingkey = clickedRow.data[0];
            setEditingRow(editingkey)


        },
        onRowsDelete: (rows, b) => {
            //use rows.lookup to get the index of the rows to be deleted and remove them from data
            const rowsToDelete = Object.keys(rows.lookup);
            const newData = data.filter((row, index) => {
                return !rowsToDelete.includes(index.toString());
            });
            setData(newData);


        }
    };

    const columns = [

        {
            name: 'key',
            label: "Edit",

            options: {
                //hide column
                display: false,
                filter: false,
                sort: false,
                empty: true,

            }
        },
        {
            name: "time",
            label: 'Date',
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const key = tableMeta.rowData[0];
                    const editing = key === editingRow;
                    const index = data.map((e) => e.key).indexOf(key);
                    const time = data[index].time
                    if (editing && status !== 'built') {

                        return (

                            <DatePicker dateFormat="MM/dd/yyyy hh:mm a" style={{ width: '200px' }} showTimeSelect timeFormat="h:mm a" timeIntervals={1}
                                timeCaption="Time"
                                portalId="root-portal"
                                selected={[undefined, '', "Invalid date"].includes(time) ? new Date() :
                                    new Date(moment(time, 'YYYY-MM-DD HH:mm').format('MM/DD/YYYY hh:mm a'))}
                                onChange={(date) => {

                                    const unix = moment(date).format('x');
                                    updateData(key, 'time', moment(unix, 'x').tz("America/Los_Angeles").format("YYYY-MM-DD HH:mm:ss"));
                                    updateData(key, 'timestamp', moment(unix, 'x').format('X'));





                                }} />

                        )
                    }

                    else {
                        return (
                            <div style={{ color: value === 'Invalid date' ? 'red' : 'inherit' }}>
                                {value === 'Invalid date' ? 'No date' : moment(time, 'YYYY-MM-DD HH:mm').format('MM/DD/YYYY h:mm a')}</div>
                        )
                    }
                }


            }
        },
        {
            name: "parameterid",
            label: "Parameter",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const key = tableMeta.rowData[0];
                    const editing = key === editingRow;
                    if (editing && status !== 'built') {
                        const index = [...probeParameters, ...parameters].map((e) => e.key).indexOf(value);

                        const label = index === -1 ? '' : [...probeParameters, ...parameters][index].label;
                        return (

                            <Autocomplete
                                style={{ width: 200 }}
                                inputProps={{ style: { width: 200 } }}
                                size="small"
                                id="combo-box-demo"
                                defaultValue={label}
                                disableClearable={true}
                                options={[...probeParameters, ...parameters].sort((a, b) => a.label.localeCompare(b.label))}
                                filterOptions={(options, _ref) => [...filterOptions(options, _ref), { label: 'Add New', key: 'Add New', value: 'addNew' },]}
                                noOptionsText={<Button fullWidth variant='contained' color='primary' style={{ textTransform: 'none' }}
                                    onClick={() => {
                                        setParameterModal(true)
                                        setParameterItem({ tempKey: uuid(), depthFromBottom: 0 });
                                    }}
                                >+ Create Parameter</Button>}
                                renderInput={(params) => <TextField {...params} placeholder="Parameters" size="small" />}
                                renderOption={(params) => {

                                    if (params.key === 'Add New') {
                                        return (
                                            <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 4 }}>
                                                <Button fullWidth variant='contained' color='primary' style={{ textTransform: 'none' }} onClick={(e) => {
                                                    setParameterModal(true)
                                                    setParameterItem({ tempKey: uuid(), depthFromBottom: 0 });


                                                }}>+ Create Parameter</Button>
                                            </Box>

                                        )
                                    }
                                    else {
                                        return (
                                            <Typography {...params}>{params.key}</Typography>
                                        )
                                    }
                                }}
                                onChange={(a, d) => {
                                    if (d.key === 'Add New') {
                                        setParameterModal(true)
                                        setParameterItem({ tempKey: uuid(), depthFromBottom: 0 });
                                    }
                                    else {
                                        updateData(key, 'parameterid', d.key);
                                        updateData(key, 'parameterLabel', d.label);
                                    }

                                }}

                            />



                        )
                    }

                    else {
                        const index = [...probeParameters, ...parameters].map((e) => e.key).indexOf(value);
                        const label = index === -1 ? 'No data' : [...probeParameters, ...parameters][index].label;
                        return (
                            <div style={{ color: label === 'No data' ? 'red' : 'inherit' }}>{label}</div>
                        )
                    }
                }

            }
        },
        {
            name: "nodeid",
            label: "Node",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const key = tableMeta.rowData[0];
                    const editing = key === editingRow;
                    if (editing && status !== 'built') {

                        const index = stations.map((e) => e.id).indexOf(value);

                        const label = index === -1 ? '' : stations[index].label;



                        return (



                            <Autocomplete
                                style={{ width: 200 }}
                                inputProps={{ style: { width: 200 } }}
                                size="small"
                                id="combo-box-demo"
                                disableClearable={true}
                                value={label}
                                renderGroup={(params) => (
                                    <Box key={params.key}>
                                        <Typography fontWeight={800} fontSize={14} p={1}>
                                            {params.group}
                                        </Typography>
                                        {params.children}
                                    </Box>
                                )}
                                groupBy={(option) => option.stationLabel || ''}
                                options={stations}
                                filterOptions={(options, _ref) => [...filterOptions(options, _ref),]}



                                //render input as id
                                renderInput={(params) => <TextField {...params} placeholder="Nodes" size="small" />}
                                renderOption={(params) => {

                                    return (
                                        <Typography {...params}>{params.key}</Typography>
                                    )
                                }}
                                onChange={(a, d) => {
                                    if (d.key === 'Add New') {
                                        setSiteModal(true)
                                        setSiteItem({ tempKey: uuid(), });
                                    }
                                    else {

                                        updateData(key, 'nodeid', d.id);
                                        updateData(key, 'locationLabel', d.label);
                                    }

                                }}

                            />
                        )
                    }

                    else {
                        const index = stations.map((e) => e.id).indexOf(value);

                        const label = index === -1 ? 'No data' : stations[index].label;
                        return (
                            <div style={{ color: label === 'No data' ? 'red' : 'inherit' }}>{label}</div>
                        )
                    }
                }
            }
        },
        {
            name: "value",
            label: "Value",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {

                    const key = tableMeta.rowData[0];
                    const editing = key === editingRow;
                    if (editing && status !== 'built') {

                        return (

                            <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                inputRef={inputRef}
                                id={`${key}value`}
                                width={80}
                                inputProps={{ style: { width: 100 } }}
                                placeholder="Value"
                                defaultValue={value}
                                type="number"
                                onChange={(e) => {
                                    updateData(key, 'value', e.target.value === "" ? "" : Number(e.target.value))
                                }} />
                        )
                    }

                    else {
                        return (
                            <div style={{ color: value === 'No data' ? 'red' : 'inherit' }}>{value === "" ? "No data" : value}</div>
                        )
                    }
                }

            }
        },
        {
            name: "unitid",
            label: "Units",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {


                    const key = tableMeta.rowData[0];
                    const editing = key === editingRow;
                    if (editing && status !== 'built') {
                        const index = unitList.map((e) => e.key).indexOf(value);
                        const label = index === -1 ? '' : unitList[index].label;
                        return (

                            <Autocomplete
                                size="small"
                                id="combo-box-demo"
                                disableClearable={false}
                                value={label}
                                options={unitList}
                                renderInput={(params) => <TextField {...params} placeholder="Units" size="small" />}
                                renderOption={(params) => {
                                    return (
                                        <Typography {...params}>{params.key}</Typography>
                                    )
                                }}
                                onChange={(a, d) => {
                                    if (d) {
                                        updateData(key, 'unitid', d.key);
                                    }
                                    else {
                                        updateData(key, 'unitid', '');
                                    }

                                }}
                            />



                        )
                    }

                    else {
                        const index = unitList.map((e) => e.key).indexOf(value);
                        const label = index === -1 ? '' : unitList[index].label;
                        return (
                            <div>{label}</div>
                        )
                    }
                }
            }
        },
        {
            name: "tags",
            label: "Codes",
            options: {
                filter: false,

                customBodyRender: (value, tableMeta, updateValue) => {


                    const key = tableMeta.rowData[0];
                    const editing = key === editingRow;
                    if (editing && status !== 'built') {
                        const index = unitList.map((e) => e.key).indexOf(value);
                        const tags = index === -1 ? '' : unitList[index].tags;
                        return (

                            <Autocomplete
                                multiple
                                fullWidth
                                autoFocus
                                disableCloseOnSelect
                                ChipProps={{ size: 'small', variant: 'outlined', color: 'primary', fontSize: 10 }}
                                value={value === undefined ? [] :
                                    value.map((t) => {
                                        return codes.find((c) => c.value === t)
                                    })
                                }
                                placeholder="Codes"
                                label="Codes"
                                onChange={(e, value) => {
                                    updateData(key, 'tags', value.map((v) => v.value))
                                }}
                                options={codes.sort((a, b) => a.label.localeCompare(b.label))}
                                getOptionLabel={(option) => option.label}
                                renderInput={(params) => (
                                    <TextField

                                        {...params}
                                        variant="standard"
                                        placeholder="Codes"
                                    />
                                )}
                            />



                        )
                    }

                    else {
                        if (value !== undefined && value.length > 0) {
                            const tags = value.map((t) => {
                                return codes.find((c) => c.value === t)
                            })
                            return (
                                <div>{tags.map((t) => t.label).join(', ')}</div>
                            )
                        }
                        else {
                            return (
                                <div></div>
                            )
                        }
                    }
                }






            }
        },





    ];


    const AddRowButton = memo(() => {
        const [date, setDate] = useState(new Date());
        const [parameter, setParameter] = useState('');
        const [location, setLocation] = useState('');
        const [value, setValue] = useState('');
        const [unit, setUnit] = useState('');

        const handleAddRow = () => {
            const newDate = moment(date).format('YYYY-MM-DD HH:mm');
            const newData = [{ key: uuid(), time: newDate, parameterid: parameter, nodeid: location, value: value, unitid: unit, editing: false, logkey: '', account: userInfo.currentAccount, company: userInfo.currentCompany }, ...data,];
            setData(newData);
            setParameter('');
            setLocation('');
            setValue('');
            setUnit('');
            setDate(new Date());
        }

        return (
            <Button size="small"
                variant="contained" color="primary"
                style={{ textTransform: 'none', fontSize: 14, backgroundColor: '#404050' }}
                onClick={handleAddRow} >+ Add Row</Button >
        )
    })


    const CreateParameter = ({ parameterItem, userInfo, account }) => {
        return (
            <Button size="small" style={{ padding: 10, borderRadius: 4, textTransform: 'none', fontSize: 14, }}
                variant='contained' color="primary"
                onClick={async () => {
                    const db = getFirestore()
                    const currentItem = parameterItem;
                    currentItem.name = parameterItem.name || "";
                    const params = data.map((d) => d.name);
                    const checks = [
                        currentItem.name.length === 0 ? false : true,
                        params.includes(currentItem.name) ? false : true,
                    ]
                    if (currentItem.name.length === 0) {
                        this.props.openSnack('error', "Parameter name required!")
                    }
                    if (params.includes(currentItem.name)) {
                        this.props.openSnack('error', "Parameter name already exists!")
                    }
                    if (!checks.includes(false)) {

                        const key = currentItem.key || currentItem.tempKey;
                        currentItem.archived = currentItem.archived || false;

                        currentItem.account = userInfo.currentAccount;
                        currentItem.company = userInfo.currentCompany;


                        const docRef = doc(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'parameters', key);

                        if (currentItem.key) {
                            await updateDoc(docRef, currentItem).then(() => {
                                openSnack('success', currentItem.key ? 'Parameter updated!' : "Parameter created!")
                            })
                        }
                        else {
                            await setDoc(docRef, {
                                ...currentItem,
                                key: currentItem.tempKey
                            }).then(async () => {
                                const parameters = await queryParameters(userInfo);
                                setParameters(parameters);
                                openSnack('success', currentItem.key ? 'Parameter updated!' : "Parameter created!")
                                setParameterModal(false)
                            })
                        }


                    }




                }}>{parameterItem.key ? 'Update Parameter' : "Create Parameter"}</Button>
        )
    }


    function EnterClick(e) {
        //if (e.key === 'Enter') change editing row to null
        if (e.key === 'Enter') {
            setEditingRow(null);
        }

        //if (e.key === 'Escape') change editing row to null
        if (e.key === 'Escape') {

            setEditingRow(null);
        }

    }







    function purgeData() {
        setSaving(true);
        //remove all rows that are missing any data or have undefined data for parameterid, nodeid, or value
        const newData = data.filter((row) => {
            return row.parameterid !== undefined && row.nodeid !== undefined && row.value !== undefined && row.parameterid !== '' && row.nodeid !== '' && row.value !== ''
        });
        setData(newData);


        const newDataList = newData.map((row) => {
            const station = stations.find((s) => s.id === row.nodeid)
            const lat = station.lat || 0
            const lng = station.lng || 0

            //remove commas from value and change to number if string
            const newValue = typeof row.value === 'string' ? row.value.replace(/,/g, '') : row.value
            const value = typeof newValue === 'string' ? Number(newValue) : newValue;
            const index = unitList.map((e) => e.key).indexOf(row.unitid);
            const unitid = index === -1 ? '' : unitList[index].unitid;




            const item = {
                time: `'${row.time}'`,
                timestamp: Number(moment(row.time, "YYYY-MM-DD HH:mm").format("X")),
                locationid: `'${row.nodeid}'`,
                unitid: `'${unitid}'`,
                parameterid: `'${row.parameterid}'`,
                value: value,
                account: `'${userInfo.currentAccount}'`,
                company: `'${userInfo.currentCompany}'`,
                nodeid: `'${row.nodeid}'`,
                key: `'${row.key}'`,
                longitude: lng,
                latitude: lat,
                logkey: `'none'`,
                label: `''`,
                tags: `'${JSON.stringify(row.tags)}'`,
            }
            return item
        })


        console.log(newDataList)
        const alValues = newDataList.map((h, i) => {
            if (h.value === NaN) {
                console.log(i)
                setSaving(false);
                console.log('nan check failed')
                openSnack('error', 'Error saving data! Check that there are no blank cells in your csv.')
                return
            }
        });





        const hourlyVals = newDataList.map((h) => `(${Object.values(h)})`);
        const hourlyString = hourlyVals.join();

        //check for letters nan in hourlyString
        //if found return 100 previous characters
        const nanCheck = hourlyString.search('NaN');
        console.log(nanCheck)
        var error = ''
        if (nanCheck !== -1) {
            setSaving(false);
            console.log('nan check failed')
            console.log(hourlyString.slice(nanCheck - 200, nanCheck + 200))


            const errorString = hourlyString.slice(nanCheck - 200, nanCheck + 200)
            //find the date in error string
            error = errorString.match(/(\d{4}-\d{2}-\d{2})/g)[0]
            console.log(error)
            openSnack('error', `Error saving data! Check that there for the date ${error}`)
            return
        }


        const proxyUrl = "https://mycorslake.herokuapp.com/";
        axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
            raw: `INSERT INTO node_data_new2 VALUES ${hourlyString} ON CONFLICT DO NOTHING; `
        }).then(async (response) => {
            setSaving(false);
            openSnack('success', 'Data saved!')
            setData([]);
            setStep('upload');
            setSubStep('dataType');
        }).catch((error) => {
            console.log(error)
            setSaving(false);
            openSnack('error', `Error saving data! Check that there for the date ${error}`)
        }
        )



    }




    return (
        <Row gutter={[12, 12]} align="middle" >
            < EnterClick />
            <Col {...fullMobile(12, 12)} style={{ paddingTop: 30 }}>
                <Typography display="inline" variant='h5' fontWeight={500} fontSize={screenWidth === 'xs' ? 16 : 22}>Upload Data</Typography>



            </Col>

            <Col span={24}>
                <Steps current={step === 'upload' ? 0 : step === 'review' ? 1 : step === 'final' ? 2 : 0}>
                    <Step title="Upload" description="Upload a file." />
                    <Step title="Review" description="Review the data." />
                    <Step title="Submit" description="Add your data to your database." />
                </Steps>
            </Col>
            <Col span={24}>
                {step === 'upload' && (<CSVReader
                    ref={csvReaderRef}
                    onUploadAccepted={(results) => {


                        //map through each row and replace any with '' with 'No data'
                        const dataList = results.data.map((row) => {
                            //replace any empty cells with 'No data' in each row
                            const newRow = [];
                            for (let i = 0; i < row.length; i++) {
                                if (row[i] === '') {
                                    newRow.push('No data')
                                }
                                else {
                                    newRow.push(row[i])
                                }
                            }
                            return newRow

                        });

                        console.log(dataList)




                        //get first row of data
                        const firstRow = dataList[0];

                        //if first row length is less than 3, then it is not a valid file
                        if (firstRow.length < 3) {
                            openSnack('error', 'Invalid file format. Please upload a valid file.')
                            openSnack('error', 'Make sure your first row is the correct headers')
                            setZoneHover(false);
                            //remove file from csvReaderRef
                            csvReaderRef.current.removeFile();


                        }
                        else {
                            setZoneHover(false);
                            //go to next step
                            setStep('review');
                            setSubStep('dataType')
                            setKeys(firstRow);
                            const data = dataList.slice(1);
                            setFileData(data);
                        }





                    }}
                    onDragOver={(event) => {
                        event.preventDefault();
                        setZoneHover(true);
                    }}
                    onDragLeave={(event) => {
                        event.preventDefault();
                        setZoneHover(false);
                    }}
                >
                    {({
                        getRootProps,
                        acceptedFile,
                        ProgressBar,
                        getRemoveFileProps,
                        Remove,
                    }) => (
                        <>
                            <div
                                {...getRootProps()}
                                style={Object.assign(
                                    {},
                                    styles.zone,
                                    zoneHover && styles.zoneHover
                                )}
                            >
                                {acceptedFile ? (
                                    <>
                                        <div style={styles.file}>
                                            <div style={styles.info}>
                                                <span style={styles.size}>
                                                    {formatFileSize(acceptedFile.size)}
                                                </span>
                                                <span style={styles.name}>{acceptedFile.name}</span>
                                            </div>
                                            <div style={styles.progressBar}>
                                                <ProgressBar />
                                            </div>
                                            <div
                                                {...getRemoveFileProps()}
                                                style={styles.remove}
                                                onMouseOver={(event) => {
                                                    event.preventDefault();
                                                    setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);
                                                }}
                                                onMouseOut={(event) => {
                                                    event.preventDefault();
                                                    setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);
                                                }}
                                            >
                                                <Remove color={removeHoverColor} />
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    'Drop CSV file here or click to upload'
                                )}
                            </div>
                        </>
                    )}
                </CSVReader>)}
            </Col>
            <Col span={24}>
                {step === 'review' && (
                    <Row gutter={[12, 12]} align="middle" >
                        <Col span={19}>
                            <Typography display="inline" variant='h5' fontWeight={500} fontSize={screenWidth === 'xs' ? 16 : 22}>Review Data</Typography>
                        </Col>
                        <Col span={2}>
                            <Button
                                size="small"
                                variant="contained" color="primary"
                                style={{ textTransform: 'none', fontSize: 14, backgroundColor: '#404050' }}
                                onClick={() => {




                                    setStep(subStep === 'review' ? 'review' : subStep === 'mapColumns' ? 'review' : 'upload')
                                    setSubStep(subStep === 'review' ? 'mapColumns' : subStep === 'mapColumns' ? 'dataType' : 'review')







                                }}  >Back</Button>
                        </Col>
                        {subStep === 'review' && step === 'review' && (<Col span={3} style={{ textAlign: 'right' }}>
                            {saving ? <CircularProgress /> : <Button
                                size="small"
                                variant="contained" color="primary"
                                style={{ textTransform: 'none', fontSize: 14, backgroundColor: '#404050' }}
                                onClick={() => {

                                    purgeData()


                                }}  >Save Data</Button>
                            }
                        </Col>)}
                        {subStep === 'dataType' && step === 'review' && (
                            <Col span={24} style={{ textAlign: 'center' }}>

                                <Row gutter={[16, 16]} align="middle" justify='center' style={{ marginTop: 20, }}>
                                    <Col span={24} >
                                        <Typography display="inline" variant='h5' fontWeight={500} fontSize={screenWidth === 'xs' ? 16 : 22}>What type of data is this?</Typography>
                                    </Col>
                                    <Col >
                                        <Button
                                            display="inline"
                                            size="small"
                                            variant="contained" color="primary"
                                            style={{ textTransform: 'none', fontSize: 14, backgroundColor: '#404050' }}
                                            onClick={() => {
                                                setSubStep('mapColumns');
                                                setDataType('individual');
                                            }}  >Each Row is a single data point</Button>
                                    </Col>
                                    <Col >
                                        <Button
                                            display="inline"
                                            size="small"
                                            variant="contained" color="primary"
                                            style={{ textTransform: 'none', fontSize: 14, backgroundColor: '#404050' }}
                                            onClick={() => {
                                                setSubStep('mapColumns');
                                                setDataType('multiple');
                                                const list = keys.map((key, index) => {
                                                    return {
                                                        key: key,
                                                        index: index,
                                                        parameter: {}
                                                    }
                                                })
                                                setMultipleData(list)

                                            }}  >Each Row has multiple data points</Button>

                                    </Col>
                                </Row>



                            </Col>
                        )}
                        {subStep === 'mapColumns' && step === 'review' && (
                            <Col span={24} style={{ textAlign: 'left' }}>

                                <Row gutter={[16, 16]} align="middle" justify={'center'} style={{ marginTop: 20, }}>
                                    <Col span={24} >
                                        <Typography display="inline" variant='h5' fontWeight={500} fontSize={screenWidth === 'xs' ? 16 : 22}>Map Columns</Typography>
                                    </Col>
                                    <Col span={24} >
                                        <Row align="middle" gutter={[4, 4]}>
                                            <Col {...fullMobile(24, 24)} style={{}}>
                                                <Typography variant="body1" fontWeight={600} fontSize={16} component="h4"  >
                                                    Date <span style={{ color: 'red' }}>*</span>
                                                </Typography>

                                            </Col>
                                            <Col {...fullMobile(20, 20)} style={{}}>
                                                <Autocomplete
                                                    size="small"
                                                    disableClearable={true}
                                                    id="combo-box-demo"
                                                    value={dateMap || ''}
                                                    style={{ backgroundColor: 'white' }}
                                                    options={keys}
                                                    filterSelectedOptions
                                                    renderInput={(params) => <TextField {...params} placeholder="Dates" size="small" />}
                                                    onChange={(a, b) => {

                                                        setDateMap(b);
                                                    }}

                                                />
                                            </Col>

                                        </Row>
                                    </Col>
                                    <Col span={24} >
                                        <Row align="middle" gutter={[4, 4]}>
                                            <Col {...fullMobile(24, 24)} style={{}}>
                                                <Typography variant="body1" fontWeight={600} fontSize={16} component="h4"  >
                                                    Date Format <span style={{ color: 'red' }}>*</span>
                                                </Typography>

                                            </Col>
                                            <Col {...fullMobile(20, 20)} style={{}}>
                                                <Select size="small" fullWidth
                                                    notched={false}
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={dateFormat || ''}
                                                    label="Layer"
                                                    style={{ backgroundColor: 'white' }}
                                                    onChange={(e) => {
                                                        setDateFormat(e.target.value)
                                                    }}

                                                >
                                                    <MenuItem value={'MM/DD/YYYY'}>MM/DD/YYYY</MenuItem>
                                                    <MenuItem value={'YYYY/MM/DD'}>YYYY/MM/DD</MenuItem>
                                                    <MenuItem value={'YYYY-MM-DD'}>YYYY-MM-DD</MenuItem>
                                                    <MenuItem value={'MM-DD-YYYY'}>MM-DD-YYYY</MenuItem>
                                                    <MenuItem value={'YYYY-MM-DD h:mm'}>YYYY-MM-DD HH:mm</MenuItem>
                                                    <MenuItem value={'MM/DD/YYYY h:mm'}>MM/DD/YYYY HH:mm</MenuItem>
                                                    <MenuItem value={'YYYY/MM/DD h:mm'}>YYYY/MM/DD HH:mm</MenuItem>
                                                    <MenuItem value={'YYYY/DD/MM h:mm'}>YYYY/DD/MM HH:mm</MenuItem>
                                                    <MenuItem value={'YYYY-DD-MM h:mm'}>YYYY/DD/MM HH:mm</MenuItem>
                                                </Select>
                                            </Col>

                                        </Row>
                                    </Col>
                                    <Col span={24} >
                                        <Row align="middle" gutter={[4, 4]}>
                                            <Col {...fullMobile(24, 24)} style={{}}>
                                                <Typography variant="body1" fontWeight={600} fontSize={16} component="h4"  >
                                                    Nodes <span style={{ color: 'red' }}>*</span>
                                                </Typography>

                                            </Col>
                                            <Col {...fullMobile(20, 20)} style={{}}>
                                                <Autocomplete
                                                    size="small"
                                                    disableClearable={true}
                                                    id="combo-box-demo"
                                                    value={nodeMap || ''}
                                                    style={{ backgroundColor: 'white' }}
                                                    options={keys}
                                                    filterSelectedOptions
                                                    renderInput={(params) => <TextField {...params} placeholder="Nodes" size="small" />}
                                                    onChange={(a, b) => {

                                                        setNodeMap(b);
                                                    }}

                                                />
                                            </Col>

                                        </Row>
                                    </Col>
                                    {dataType === 'individual' && (<Col span={24} >
                                        <Row align="middle" gutter={[4, 4]}>
                                            <Col {...fullMobile(24, 24)} style={{}}>
                                                <Typography variant="body1" fontWeight={600} fontSize={16} component="h4"  >
                                                    Parameters <span style={{ color: 'red' }}>*</span>
                                                </Typography>

                                            </Col>
                                            <Col {...fullMobile(20, 20)} style={{}}>
                                                <Autocomplete
                                                    size="small"
                                                    disableClearable={true}
                                                    id="combo-box-demo"
                                                    value={parameterMap || ''}
                                                    style={{ backgroundColor: 'white' }}
                                                    options={keys}
                                                    filterSelectedOptions
                                                    renderInput={(params) => <TextField {...params} placeholder="Parameters" size="small" />}
                                                    onChange={(a, b) => {

                                                        setParameterMap(b);
                                                    }}

                                                />
                                            </Col>

                                        </Row>
                                    </Col>)}
                                    {dataType === 'individual' && (<Col span={24} >
                                        <Row align="middle" gutter={[4, 4]}>
                                            <Col {...fullMobile(24, 24)} style={{}}>
                                                <Typography variant="body1" fontWeight={600} fontSize={16} component="h4"  >
                                                    Values <span style={{ color: 'red' }}>*</span>
                                                </Typography>

                                            </Col>
                                            <Col {...fullMobile(20, 20)} style={{}}>
                                                <Autocomplete
                                                    size="small"
                                                    disableClearable={true}
                                                    id="combo-box-demo"
                                                    value={valueMap || ''}
                                                    style={{ backgroundColor: 'white' }}
                                                    options={keys}
                                                    filterSelectedOptions
                                                    renderInput={(params) => <TextField {...params} placeholder="Values" size="small" />}
                                                    onChange={(a, b) => {

                                                        setValueMap(b);
                                                    }}

                                                />
                                            </Col>

                                        </Row>
                                    </Col>)}
                                    {dataType === 'individual' && (<Col span={24} >
                                        <Row align="middle" gutter={[4, 4]}>
                                            <Col {...fullMobile(24, 24)} style={{}}>
                                                <Typography variant="body1" fontWeight={600} fontSize={16} component="h4"  >
                                                    Units
                                                </Typography>

                                            </Col>
                                            <Col {...fullMobile(20, 20)} style={{}}>
                                                <Autocomplete
                                                    size="small"
                                                    disableClearable={true}
                                                    id="combo-box-demo"
                                                    value={unitMap || ''}
                                                    style={{ backgroundColor: 'white' }}
                                                    options={keys}
                                                    filterSelectedOptions
                                                    renderInput={(params) => <TextField {...params} placeholder="Units" size="small" />}
                                                    onChange={(a, b) => {

                                                        setUnitMap(b);
                                                    }}

                                                />
                                            </Col>

                                        </Row>
                                    </Col>)}
                                    {dataType === 'individual' && (<Col span={24} >
                                        <Row align="middle" gutter={[4, 4]}>
                                            <Col {...fullMobile(24, 24)} style={{}}>
                                                <Typography variant="body1" fontWeight={600} fontSize={16} component="h4"  >
                                                    Codes
                                                </Typography>

                                            </Col>
                                            <Col {...fullMobile(20, 20)} style={{}}>
                                                <Autocomplete
                                                    size="small"
                                                    disableClearable={true}
                                                    id="combo-box-demo"
                                                    value={codeMap || ''}
                                                    style={{ backgroundColor: 'white' }}
                                                    options={keys}
                                                    filterSelectedOptions
                                                    renderInput={(params) => <TextField {...params} placeholder="Units" size="small" />}
                                                    onChange={(a, b) => {

                                                        setCodeMap(b);
                                                    }}

                                                />
                                            </Col>

                                        </Row>
                                    </Col>)}

                                    {dataType === 'multiple' && dateMap !== '' && nodeMap !== '' && (



                                        multipleData.filter((item) => ![dateMap, nodeMap].includes(item.key)).map((item, index) => {
                                            return (
                                                <Col span={24} >
                                                    <Row align="middle" gutter={[4, 4]}>
                                                        <Col {...fullMobile(24, 24)} style={{}}>
                                                            <Typography variant="body1" fontWeight={600} fontSize={16} component="h4"  >
                                                                {item.key}
                                                            </Typography>

                                                        </Col>
                                                        <Col {...fullMobile(20, 20)} style={{}}>
                                                            <Autocomplete
                                                                sx={{
                                                                    groupLabel: {
                                                                        fontWeight: 'bold',
                                                                        fontSize: 20
                                                                    }
                                                                }
                                                                }
                                                                renderGroup={(params) => (
                                                                    <Box key={params.key}>
                                                                        <Typography fontWeight={800} fontSize={14} p={1}>
                                                                            {params.group}
                                                                        </Typography>
                                                                        {params.children}
                                                                    </Box>
                                                                )}
                                                                groupBy={(option) => option.parameterType || ''}
                                                                size="small"
                                                                style={{ backgroundColor: 'white' }}
                                                                disableClearable={true}
                                                                id="combo-box-demo"
                                                                value={[...probeParameters, ...parameters].find((l) => l.key === item.parameter?.key) || ''}
                                                                options={
                                                                    [...parameters, ...probeParameters]

                                                                }
                                                                filterSelectedOptions
                                                                renderInput={(params) => <TextField {...params} placeholder={item.key} size="small" />}
                                                                onChange={(a, b) => {

                                                                    const index = multipleData.findIndex((l) => l.key === item.key);

                                                                    multipleData[index].parameter = b;
                                                                    setMultipleData([...multipleData]);
                                                                }}

                                                            />
                                                        </Col>
                                                        <Col {...fullMobile(4, 4)} style={{}}>
                                                            <Button
                                                                size="large"
                                                                style={{ textTransform: 'none', fontSize: 14, color: 'white', backgroundColor: '#404050' }}
                                                                onClick={() => {
                                                                    const index = multipleData.findIndex((l) => l.key === item.key);
                                                                    multipleData.splice(index, 1);
                                                                    setMultipleData([...multipleData]);
                                                                    openSnack('success', 'Parameter removed')
                                                                }}
                                                            >
                                                                <FontAwesomeIcon icon={faTrashAlt} />
                                                            </Button>
                                                        </Col>


                                                    </Row>
                                                </Col>
                                            )
                                        })






                                    )}


                                    {dataType === 'individual' && parameterMap !== '' && valueMap !== '' && dateMap !== '' && nodeMap !== '' && (
                                        <Col span={24} style={{ textAlign: 'left' }}>
                                            <Button
                                                display="inline"
                                                size="large"
                                                variant="contained" color="primary"
                                                style={{ textTransform: 'none', fontSize: 18, paddingTop: 10, backgroundColor: '#404050' }}
                                                onClick={() => {
                                                    setSubStep('review');
                                                    setStep('review');
                                                    setDataType('individual');



                                                    const valueIndex = keys.indexOf(valueMap);
                                                    const parameterIndex = keys.indexOf(parameterMap);
                                                    const dateIndex = keys.indexOf(dateMap);
                                                    const nodeIndex = keys.indexOf(nodeMap);
                                                    const unitIndex = keys.indexOf(unitMap);
                                                    const codeIndex = keys.indexOf(codeMap);
                                                    console.log(fileData)
                                                    //filter out any item from fileData that is ['No data'], undefined, or null
                                                    const filteredData = fileData.filter((item) => {
                                                        return item[valueIndex] !== 'No data' && item[valueIndex] !== undefined && item[valueIndex] !== null
                                                    })


                                                    const newData = filteredData.map((item) => {
                                                        const station = stations.find((station) => station.name === item[nodeIndex]);
                                                      
                                                        const trimmedParameter = item[parameterIndex]?.trim();
                                                        const parameter = [...probeParameters, ...parameters].find((parameter) => parameter.name === trimmedParameter);



                                                        return {

                                                            time: moment(item[dateIndex], dateFormat).format("YYYY-MM-DD HH:mm"),
                                                            timestamp: Number(moment(item[dateIndex], dateFormat).format("X")),
                                                            locationid: station !== undefined ? station.id : '',
                                                            unitid: unitIndex !== -1 ? item[unitIndex] : '',
                                                            parameterid: parameter !== undefined ? parameter.key : '',
                                                            value: item[valueIndex] || '',
                                                            account: userInfo.currentAccount,
                                                            company: userInfo.currentCompany,
                                                            nodeid: station !== undefined ? station.id : '',
                                                            key: uuid(),
                                                            longitude: station !== undefined ? station.lng : 0,
                                                            latitude: station !== undefined ? station.lat : 0,
                                                            logkey: 'none',
                                                            tags: codeIndex !== -1 ? item[codeIndex] : []
                                                        }
                                                    })



                                                    setData(newData);







                                                }}  >Submit and Review Data</Button>
                                        </Col>
                                    )}

                                    {dataType === 'multiple' && dateMap !== '' && nodeMap !== '' && (
                                        <Col span={24} style={{ textAlign: 'left' }}>
                                            <Button
                                                display="inline"
                                                size="large"

                                                disabled={
                                                    multipleData.map((item) => item.parameter).includes('')
                                                }
                                                variant="contained" color="primary"
                                                style={{
                                                    textTransform: 'none', fontSize: 18, paddingTop: 10,
                                                    //backgroundColor is gray if disabled

                                                    backgroundColor: multipleData.map((item) => item.parameter).includes('') ? '#404050' : '#404050'
                                                }}
                                                onClick={() => {
                                                    setSubStep('review');
                                                    setStep('review');
                                                    setDataType('multiple');


                                                    const filterData = multipleData.filter((item) => ![dateMap, nodeMap].includes(item.key))

                                                    const indexList = filterData.map((item) => item.index);




                                                    const dateIndex = keys.indexOf(dateMap);
                                                    const nodeIndex = keys.indexOf(nodeMap);





                                                    const newData = [];

                                                    fileData.forEach((item) => {

                                                        item.forEach((item2, index) => {



                                                            if (indexList.includes(index) && item2 !== '' && item2 !== undefined && item2 !== 'No data') {
                                                                const parameter = filterData.find((item) => item.index === index);
                                                                const station = stations.find((station) => station.name === item[nodeIndex]);
                                                                
                                                                const itemRow = {
                                                                    time: moment(item[dateIndex], dateFormat).format("YYYY-MM-DD HH:mm"),
                                                                    timestamp: Number(moment(item[dateIndex], dateFormat).format("X")),
                                                                    locationid: station !== undefined ? station.id : '',
                                                                    unitid: '',
                                                                    parameterid: parameter !== undefined && parameter.parameter !== undefined ? parameter?.parameter?.key : '',
                                                                    value: item2.includes("<") ? item2.replace("<", "") : item2.includes(">") ? item2.replace(">", "") : item2,
                                                                    account: userInfo.currentAccount,
                                                                    company: userInfo.currentCompany,
                                                                    nodeid: station !== undefined ? station.id : '',
                                                                    key: uuid(),
                                                                    longitude: station !== undefined ? station.lng : 0,
                                                                    latitude: station !== undefined ? station.lat : 0,
                                                                    logkey: 'none',
                                                                    tags: item2.includes("<") ? ['ND'] : item2.includes(">") ? ['OR'] : []
                                                                }
                                                                newData.push(itemRow);

                                                                if (item2 === NaN) {
                                                                    console.log(itemRow)
                                                                }
                                                            }
                                                        })
                                                    })


                                                    //get all values for new data

                                                    const allValues = newData.map((item) => item.value);
                                                    console.log(allValues)



                                                    setData(newData);




                                                }}  >Submit and Review Data</Button>
                                        </Col>
                                    )}



                                </Row>



                            </Col>
                        )}

                        {data.length > 0 && step === 'review' && subStep === 'review' && (

                            <Col onKeyPress={EnterClick} id="table" {...fullMobile(24, 24)} style={{ paddingTop: 10, position: 'relative', zIndex: 10 }}>
                                <ThemeProvider theme={getMuiTheme()}>
                                    <MUIDataTable ref={gridRef} data={data} columns={columns} options={options} />
                                </ThemeProvider>

                            </Col>

                        )}

                    </Row>


                )}
            </Col>







        </Row >
    )



}