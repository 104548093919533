import React, { useState } from "react";
import { Avatar, IconButton, TextField, Button, Box, Typography, Tooltip } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import { Col, Row } from "antd";

const CommentsSection = ({ comments, deleteComment, updateComment, setCurrentTask, currentTask }) => {
    const [editedComment, setEditedComment] = useState("");
    const [editingIndex, setEditingIndex] = useState(null);

    const handleEdit = (index, commentText) => {
        setEditingIndex(index);
        setEditedComment(commentText);
    };

    const handleSave = (index) => {
        updateComment(index, editedComment);
        setEditingIndex(null);
    };

    return (
        <div>
            {comments && comments.length > 0 ? (
                comments.map((comment, index) => (
                    <Row key={index} style={{
                        alignItems: 'center', marginBottom: 15, paddingBottom: 5,
                        borderBottom: '1px solid rgba(0,0,0,0.2)'
                    }}>
                        <Col span={4}>

                            <Avatar sx={{ bgcolor: '#3f51b5', marginRight: 1 }}>
                                {comment.authorInitial ? comment.authorInitial[0] : "?"}
                            </Avatar>



                        </Col>
                        <Col span={16}>
                            <Row>
                                <Col span={24}>
                                    <Typography color="textSecondary" style={{ marginRight: 8 }}>
                                        Last Updated: {moment(comment.lastEdited, 'x').format('MM/DD/YY h:mm a')}
                                    </Typography>
                                </Col>
                                <Col span={24}>

                                    {editingIndex === index ? (
                                        <div style={{ flex: 1 }}>
                                            <TextField
                                                fullWidth
                                                value={editedComment}
                                                onChange={(e) => {
                                                    setEditedComment(e.target.value)
                                                }}
                                            />
                                            <Button onClick={() => handleSave(index)}>Save</Button>
                                            <Button onClick={() => setEditingIndex(null)}>Cancel</Button>
                                        </div>
                                    ) : (
                                        <>
                                            <Typography fontWeight={500} style={{ flex: 1 }}>{comment.text}</Typography>

                                        </>
                                    )}
                                </Col>
                            </Row>


                        </Col>
                        <Col span={4} style={{ display: 'inline' }}>

                            <Tooltip title="Edit" style={{ display: 'inline' }}>
                                <IconButton onClick={() => handleEdit(index, comment.text)}>
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete" style={{ display: 'inline' }}>
                                <IconButton onClick={() => deleteComment(index)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </Col>


                    </Row>
                ))
            ) : (
                <Typography variant="body1" color="textSecondary">
                    No comments available.
                </Typography>
            )}
        </div>
    );
};

export default CommentsSection;
