import DeleteForever from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, CircularProgress, IconButton, OutlinedInput, TextField, Typography } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Col, Modal, Row } from 'antd';
import { collection, doc, getFirestore, onSnapshot, query, setDoc, updateDoc, where } from 'firebase/firestore';
import moment from 'moment';
import MUIDataTable from "mui-datatables";
import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import uuid from 'react-uuid';
import Units from '../../../extra/units.json';
import SiteModal from '../../../waterQuality/samplingSites/siteModal';
import './tableStyle.css';
import { fullMobile } from './util';
import axios, * as others from 'axios';


export default class FLWaterQuality extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            doc: this.props.doc,
            dataList: [],
            tempData: [],
            preview: this.props.preview,
            loading: false,
            editRow: undefined,
            parameters: [],
            sites: [],
            parameterModal: false,
            siteModal: false,
            parameterItem: {},
            siteItem: {},
            unitList: [],
            testKey: this.props.testKey,
            uploadingData: false,
            saveWaterQualityData: this.props.saveWaterQualityData,
            status: this.props.status,
            logKey: this.props.logKey,
            createWaterQualityData: this.props.createWaterQualityData,
            queryingData: true,

        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.wqData !== prevProps.wqData) {

            if (this.props.wqData === false) {
                if (this.state.tempData.length === 0 && this.state.dataList.length === 0) {

                    this.props.updateWQ(true)
                    this.setState({ wqData: true })
                }

            }
            else {
                this.setState({ wqData: this.props.wqData })
            }



        }
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
        if (this.props.status !== prevProps.status) {

            this.setState({ status: this.props.status })
        }
        if (this.props.logKey !== prevProps.logKey) {
            console.log(this.props.logKey)
            this.setState({ logKey: this.props.logKey })
            if (this.props.logKey !== undefined) {
                this.queryData(this.props.logKey)
            }

        }

        if (this.props.saveWaterQualityData !== prevProps.saveWaterQualityData) {

            this.setState({ saveWaterQualityData: this.props.saveWaterQualityData })
            if (this.props.saveWaterQualityData === true && this.props.logKey !== undefined) {
                const newData = this.state.dataList.filter((d) => d.logkey === undefined);
                const oldData = this.state.dataList.filter((d) => d.logkey !== undefined);

                this.createData(newData, this.props.logKey)
                this.saveData(oldData, this.props.logKey)
            }
        }
        if (this.props.createWaterQualityData !== prevProps.createWaterQualityData) {

            this.setState({ createWaterQualityData: this.props.createWaterQualityData })
            if (this.props.createWaterQualityData.status === true) {
                const newData = this.state.dataList.filter((d) => d.logkey === undefined);

                this.createData(newData, this.props.createWaterQualityData.key)
            }
        }






    }
    componentDidMount() {
        const { logKey } = this.state;
        console.log(logKey)
        this.queryParameters()

        const unitList = Object.values(Units).map((u, i) => {
            return ({ label: u, key: u, unitid: Object.keys(Units)[i] })
        })
        this.setState({ unitList: unitList })

        if (logKey !== undefined) {
            this.queryData(logKey)

        }
        else {
            this.setState({ queryingData: false })
        }

        /* const proxyUrl = "https://mycorslake.herokuapp.com/";
        axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
            raw: `ALTER TABLE lab_data 
            ADD COLUMN logkey TEXT NULL;`
        }).then(async (response) => {
            // handle success
            console.log(response);
            this.props.openSnack('success', 'Data deleted!')


        }).catch((error) => {
            console.log(error)
        }) */




    }


    queryData = async (key) => {
        console.log("QUERY")
        const proxyUrl = "https://mycorslake.herokuapp.com/";
        axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
            raw: `SELECT * FROM lab_data WHERE logkey = '${key}';`
        }).then(async (response) => {
            // handle success
            const newData = response.data.map((d) => {
                const key = d.key || uuid();
                if ([undefined, null].includes(d.key)) {

                    const proxyUrl = "https://mycorslake.herokuapp.com/";
                    axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                        raw: `UPDATE lab_data SET 
                        key = '${key}'
                        WHERE time = '${d.time}'
                        AND parameterid = '${d.parameterid}'
                        AND locationid = '${d.locationid}'
                        ; `
                    }).then(async (response) => {
                        // handle success
                        console.log(response)
                    }).catch((error) => {
                        console.log(error)
                    })
                }
                return ({ ...d, key, edit: false, edited: false, created: true })
            })

            this.setState({ dataList: newData, uploadingData: false, loading: false, queryingData: false })
        }).catch((error) => {
            console.log(error)
        })
    }


    createData = async (data, key) => {
        console.log("CREATE");
        console.log(key);
        const { userInfo } = this.state;
        const hourly = [];

        const filteredData = data.filter((d) => {
            return (d.locationid.length !== 0 && d.locationid !== '' && d.value !== '' && d.value !== null)
        })

        if (filteredData.length !== data.length) {
            this.props.openSnack('error', 'Data missing parameter or location!')
        }

        //if filteredData length === 0 then turn off uploadingData
        if (filteredData.length === 0) {
            this.setState({ uploadingData: false, editRow: '123' })
        }

        filteredData.map((f) => {

            const item = {
                time: `'${f.time}'`,
                timestamp: f.timestamp,
                locationid: `'${f.locationid}'`,
                locationLabel: `'${f.locationLabel}'`,
                unitid: `'units'`,
                parameterid: `'${f.parameterid}'`,
                parameterLabel: `'${f.parameterLabel}'`,
                nd: `'${f.value === "ND" ? "true" : 'false'}'`,
                meta: `'${JSON.stringify(f)}'`,
                account: `'${userInfo.currentAccount}'`,
                company: `'${userInfo.currentCompany}'`,
                value: Number(f.value === "ND" ? "0" : f.value),
                logkey: `'${f.logkey !== undefined ? f.logkey : key}'`,
                key: `'${f.key}'`,


            }
            hourly.push(item)
        })

        console.log(hourly);

        if (hourly.length !== 0) {
            const hourlyVals = hourly.map((h) => `(${Object.values(h)})`);
            const hourlyString = hourlyVals.join();
            console.log(hourlyString)

            const proxyUrl = "https://mycorslake.herokuapp.com/";
            axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                raw: `INSERT INTO lab_data VALUES ${hourlyString} ON CONFLICT DO NOTHING; `
            }).then(async (response) => {
                this.props.openSnack('success', 'Water quality data saved!')
                this.props.updateWQ(true)
                this.setState({ wqData: true })
                console.log("CREATED")

                filteredData.forEach((f) => {
                    const index = this.state.dataList.findIndex((d) => d.key === f.key);
                    this.state.dataList[index].created = true;
                    this.setState({ dataList: this.state.dataList, uploadingData: false, editRow: '123' })
                })


            }).catch((error) => {
                console.log(error)
            })
        }

        if (hourly.length === 0)
        {
            this.props.updateWQ(true)
        }
    }








    saveData = async (dataList, testKey) => {
        const { userInfo, tempData, } = this.state;
        //set  uploadingData true
        console.log(dataList)
        this.setState({ uploadingData: true })
        //filter datalist if location id length does not equal '' and location id does not equal '' and value does not equal '' and value is not null
        const filteredData = dataList.filter((d) => {
            return (d.locationid.length !== 0 && d.locationid !== '' && d.value !== '' && d.value !== null)
        })

        if (filteredData.length !== dataList.length) {
            this.props.openSnack('error', 'Data missing parameter or location!')
        }

        //if filteredData length === 0 then turn off uploadingData
        if (filteredData.length === 0) {
            this.setState({ uploadingData: false, editRow: '123' })
        }

        filteredData.forEach(async (f, i) => {
            const checks = [f.oldTime === f.time, f.oldLocationid === f.locationid, f.oldParameterid === f.parameterid].filter((f) => f === true)


            if (checks.length === 3) {
                console.log("UPDATE")
                const raw = `UPDATE lab_data SET 
            value = '${f.value}' 
            WHERE time = '${f.time}'
            AND parameterid = '${f.parameterid}'
            AND locationid = '${f.locationid}'
            ; `


                const proxyUrl = "https://mycorslake.herokuapp.com/";
                axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                    raw
                }).then(async (response) => {
                    if (i === filteredData.length - 1) {
                        this.setState({ uploadingData: false, editRow: '123' })
                        this.props.doneSaving()

                    }
                    this.props.openSnack('success', 'Water quality data saved!')
                }).catch((error) => console.log(error))
            }
            else {



                const item = {
                    time: `'${f.time}'`,
                    timestamp: f.timestamp,
                    locationid: `'${f.locationid}'`,
                    locationLabel: `'${f.locationLabel}'`,
                    unitid: `'units'`,
                    parameterid: `'${f.parameterid}'`,
                    parameterLabel: `'${f.parameterLabel}'`,
                    nd: `'${f.value === "ND" ? "true" : 'false'}'`,
                    meta: `'${JSON.stringify(f)}'`,
                    account: `'${userInfo.currentAccount}'`,
                    company: `'${userInfo.currentCompany}'`,
                    value: Number(f.value === "ND" ? "0" : f.value),
                    logkey: `'${f.logkey || testKey}'`


                }

                const hourlyVals = [item].map((h) => `(${Object.values(h)})`);
                const hourlyString = hourlyVals.join();

                console.log(item)
                console.log("INSERT")
                const proxyUrl = "https://mycorslake.herokuapp.com/";
                axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                    raw: `INSERT INTO lab_data VALUES ${hourlyString}; `
                }).then(async (response) => {
                    this.props.openSnack('success', 'Water quality data saved!')
                    // handle success

                    if (f.created === true) {
                        console.log("DELETE")

                        const rawD = `DELETE FROM lab_data 
                        WHERE time = '${f.oldTime || f.time}'
                        AND parameterid = '${f.oldParameterid || f.parameterid}'
                        AND locationid = '${f.oldLocationid || f.locationid}'
                        ; `
                        const proxyUrl = "https://mycorslake.herokuapp.com/";
                        axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                            raw: rawD
                        }).then(async (response) => {
                            // handle success


                        })

                    }
                    else {
                        const index = dataList.findIndex((d) => d.key === f.key)
                        dataList[index].created = true;
                        this.setState({ dataList: dataList })
                    }

                    if (i === filteredData.length - 1) {
                        this.setState({ uploadingData: false, editRow: '123' })
                        this.props.doneSaving()

                    }







                }).catch((error) => {
                    console.log(error)
                    this.setState({ uploadingData: false, editRow: '123' })
                    if (i === filteredData.length - 1) {
                        this.setState({ uploadingData: false, editRow: '123' })
                        this.props.doneSaving()

                    }
                })

            }


        })

    }





    queryParameters = async () => {
        const { userInfo, type } = this.state;
        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'parameters'),);
        const unsubscribe = onSnapshot(queryData, async (querySnapshot) => {
            const data = [];
            querySnapshot.forEach((doc) => {
                data.push({ ...doc.data(), label: doc.data().name })
            });
            data.sort((a, b) => a.name.localeCompare(b.name))
            this.setState({ parameters: data, })

            this.querySites()
        });
    }

    querySites = async () => {
        const { userInfo, type } = this.state;
        const db = getFirestore();

        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'sampleSites'), where('account', '==', userInfo.currentAccount), where('archived', "!=", true));
        const unsubscribe = onSnapshot(queryData, async (querySnapshot) => {
            const data = [];
            querySnapshot.forEach((doc) => {
                data.push({ ...doc.data(), label: doc.data().name })
            });
            data.sort((a, b) => a.name.localeCompare(b.name))
            this.setState({ sites: data, })

        });





    }




    addData = () => {
        const { dataList, userInfo, testKey, tempData, logKey } = this.state;
        var time = moment().tz("America/Los_Angeles").format("YYYY-MM-DD HH:mm:ss");

        const tempKey = uuid()
        const item = {
            time,
            timestamp: Number(moment().format('X')),
            locationid: '',
            locationLabel: '',
            unitid: '',
            parameterid: '',
            parameterLabel: '',
            nd: 'false',
            account: userInfo.currentAccount,
            company: userInfo.currentCompany,
            value: '',
            tempKey,
            key: tempKey,
            parameterUnits: '',
            locationBorder: '',
            parameterBorder: '',
            errorLabelP: '',
            errorLabelS: '',
            edit: false,
            logkey: logKey,
            created: false
        }

        dataList.push(item)
        this.setState({ dataList })




    }

    updateTable = (key, boo, val) => {
        const { dataList, tempData, testKey } = this.state;



        const index = dataList.findIndex((f) => f.key === key);

        dataList[index][boo] = val;

        this.setState({ dataList })


    }

    setParameterItem = (item) => { this.setState({ parameterItem: item }) }
    setSiteItem = (item) => { this.setState({ siteItem: item }) }

    getMuiTheme = () =>
        createTheme({
            components: {
                MUIDataTable: {
                    styleOverrides: {
                        root: {

                            boxShadow: 'none'
                        }
                    }
                },
                MuiTableRow: {
                    styleOverrides: {
                        root: {
                            zIndex: 100,
                            cursor: 'pointer',
                            fontSize: '10px',
                            '&:nth-child(even)': {
                                backgroundColor: 'rgba(0,0,0,0.08)'
                            },
                            padding: 3

                        },
                        hover: {
                            backgroundColor: 'green'
                        }

                    }
                },
                MuiTableCell: {
                    styleOverrides: {
                        root: {
                            fontSize: '12px',
                            padding: 3,
                        }
                    }
                },
                MuiAutocomplete: {
                    styleOverrides: {
                        root: {
                            fontSize: '12px',
                        },
                        input: {
                            fontSize: '12px',
                        },


                    }
                },
                MuiInputBase: {
                    styleOverrides: {
                        root: {
                            fontSize: '12px',
                        }
                    }
                },
                MuiInput: {
                    styleOverrides: {
                        root: {
                            fontSize: '12px',

                        },

                    }
                }

            }
        });


    deleteData = (item, index) => {
        const { dataList, tempData, testKey } = this.state;
        const d = item;
        const proxyUrl = "https://mycorslake.herokuapp.com/";
        axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
            raw: `DELETE FROM lab_data 
            WHERE key IN (${[d.key].map((e) => `'${e}'`).join(',')});`
        }).then(async (response) => {
            // handle success

            this.props.openSnack('success', 'Data deleted!')

            dataList.splice(index, 1)
            this.setState({ dataList })


        }).catch((error) => {
            console.log(error)
        })
    }



    render() {

        const { account, preview, screenWidth, dataList, loading, editRow, parameters, sites, testKey, logKey, tempData, uploadingData,
            parameterModal, parameterItem, userInfo, siteItem, siteModal, unitList, saveWaterQualityData, status, queryingData } = this.state;

        const filterOptions = createFilterOptions({
            matchFrom: 'start',
            stringify: (option) => option.label,
        });

        const data = dataList;

        const options = {
            filter: false,
            searchable: false,
            search: false,
            searchable: false,
            viewColumns: false,
            download: false,
            print: false,
            draggable: true,
            rowsPerPage: 50,
            rowsPerPageOptions: [10, 50, 100],
            selectableRows: screenWidth === 'xs' ? false : status === 'built' ? false : true,
            filterType: 'dropdown',
            responsive: 'stacked',
            onRowClick: (rowData, rowMeta, b) => {

                if (status === 'creating') {
                    const index = data.map((f, i) => i).indexOf(rowMeta.dataIndex);
                    const d = data[index];

                    if (d.key !== editRow) {

                        this.updateTable(d.key, 'edit', !d.edit);
                        this.updateTable(d.key, 'oldTime', d.time);
                        this.updateTable(d.key, 'oldTimestamp', d.timestamp);
                        this.updateTable(d.key, 'oldParameterid', d.parameterid);
                        this.updateTable(d.key, 'oldLocationid', d.locationid);

                        this.setState({ editRow: this.state.editRow === d.key ? undefined : d.key })
                    }
                }

            },
            onRowsDelete: (rows, b) => {
                console.log(logKey)
                if (logKey === undefined) {
                    const temp = dataList;
                    rows.data.sort((a, b) => b.index - a.index).forEach((item) => {
                        temp.splice(item.dataIndex, 1)
                        this.setState({ dataList: temp })
                    })

                }
                else {
                    console.log(b)
                    const remainingKeys = b.map((e) => e[0]);
                    const deletedData = dataList.filter((e) => !remainingKeys.includes(e.key));

                    const deletedKeys = deletedData.map((e) => e.key);

                    console.log(deletedKeys)
                    const proxyUrl = "https://mycorslake.herokuapp.com/";
                    axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                        raw: `DELETE FROM lab_data 
                    WHERE key IN (${deletedKeys.map((e) => `'${e}'`).join(',')});`

                    }).then(async (response) => {
                        // handle success

                        this.props.openSnack('success', 'Data deleted!')
                        const newData = dataList.filter((e) => !deletedKeys.includes(e.key));
                        this.setState({ dataList: newData })




                    }).catch((error) => { 
                        console.log(error)
                    })
                }




            }
        };

        const keyColumn = 0;

        const columns = [

            {
                name: 'key',
                label: "Edit",
                options: {
                    filter: false,
                    display: status === 'creating' ? true : false,
                    sort: false,
                    empty: true,
                    customBodyRender: (value, b, updateValue) => {
                        const index = data.map((e) => e.key).indexOf(value);
                        const d = data[index];
                        if (status === 'creating') {


                            return (
                                <div>
                                    <IconButton size="small" aria-label="delete" color={editRow === b.rowData[keyColumn] ? 'primary' : 'inherit'} onClick={() => {

                                        this.updateTable(b.rowData[keyColumn], 'edit', !d.edit);
                                        this.updateTable(b.rowData[keyColumn], 'oldTime', d.time);
                                        this.updateTable(b.rowData[keyColumn], 'oldTimestamp', d.timestamp);
                                        this.updateTable(b.rowData[keyColumn], 'oldParameterid', d.parameterid);
                                        this.updateTable(b.rowData[keyColumn], 'oldLocationid', d.locationid);

                                        this.setState({ editRow: this.state.editRow === b.rowData[keyColumn] ? undefined : b.rowData[keyColumn] })
                                    }}>
                                        <EditIcon />
                                    </IconButton>

                                </div>

                            );
                        }
                        else {
                            return (
                                <div></div>
                            )

                        }
                    }
                }
            },
            {
                name: "time",
                label: 'Date',
                options: {
                    filter: false,

                    customBodyRender: (a, b, c) => {

                        const index = data.map((e) => e.key).indexOf(b.rowData[keyColumn]);
                        const time = data[index].time
                        if (editRow === b.rowData[keyColumn]) {

                            return (

                                <DatePicker dateFormat="MM/dd/yyyy hh:mm a" style={{ width: '200px' }} showTimeSelect timeFormat="h:mm a" timeIntervals={1}
                                    timeCaption="Time"
                                    portalId="root-portal"
                                    selected={[undefined, '', "Invalid date"].includes(time) ? new Date() :
                                        new Date(moment(time, 'YYYY-MM-DD HH:mm').format('MM/DD/YYYY hh:mm a'))}
                                    onChange={(date) => {

                                        const unix = moment(date).format('x');
                                        this.updateTable(b.rowData[keyColumn], 'time', moment(unix, 'x').tz("America/Los_Angeles").format("YYYY-MM-DD HH:mm:ss"));
                                        this.updateTable(b.rowData[keyColumn], 'timestamp', moment(unix, 'x').format('X'));
                                        this.updateTable(b.rowData[keyColumn], 'edited', true);


                                    }} />

                            )
                        }

                        else {
                            return (
                                <div>{moment(time, 'YYYY-MM-DD HH:mm').format('MM/DD/YYYY h:mm a')}</div>
                            )
                        }
                    }
                }
            },
            {
                name: "parameterid",
                label: "Parameter",
                options: {
                    filter: false,
                    customBodyRender: (a, b, c) => {

                        if (editRow === b.rowData[keyColumn]) {
                            const index = parameters.map((e) => e.key).indexOf(a);
                            const label = index === -1 ? '' : parameters[index].label;
                            return (

                                <Autocomplete
                                    size="small"
                                    id="combo-box-demo"
                                    defaultValue={label}
                                    disableClearable={true}
                                    options={parameters}
                                    filterOptions={(options, _ref) => [...filterOptions(options, _ref), { label: 'Add New', key: 'Add New', value: 'addNew' },]}
                                    noOptionsText={<Button fullWidth variant='contained' color='primary' style={{ textTransform: 'none' }}
                                        onClick={() => {
                                            this.setState({ parameterModal: true })
                                        }}
                                    >+ Create Parameter</Button>}
                                    renderInput={(params) => <TextField {...params} label="Parameters" size="small" />}
                                    renderOption={(params) => {

                                        if (params.key === 'Add New') {
                                            return (
                                                <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 4 }}>
                                                    <Button fullWidth variant='contained' color='primary' style={{ textTransform: 'none' }} onClick={(e) => {
                                                        this.setState({ parameterModal: true })
                                                        this.setParameterItem({ tempKey: uuid(), depthFromBottom: 0 });


                                                    }}>+ Create Parameter</Button>
                                                </Box>

                                            )
                                        }
                                        else {
                                            return (
                                                <Typography {...params}>{params.key}</Typography>
                                            )
                                        }
                                    }}
                                    onChange={(a, d) => {

                                        this.updateTable(b.rowData[keyColumn], 'parameterLabel', d.label);
                                        this.updateTable(b.rowData[keyColumn], 'parameterid', d.key);
                                        this.updateTable(b.rowData[keyColumn], 'parameterUnits', d.unitLabel || '');
                                        this.updateTable(b.rowData[keyColumn], 'parameterBorder', null);

                                    }}

                                />



                            )
                        }

                        else {
                            const index = parameters.map((e) => e.key).indexOf(a);
                            const label = index === -1 ? '' : parameters[index].label;
                            return (
                                <div>{label}</div>
                            )
                        }
                    }
                }
            },
            {
                name: "locationid",
                label: "Site",
                options: {
                    filter: false,
                    customBodyRender: (a, b, c) => {

                        if (editRow === b.rowData[keyColumn]) {

                            const index = sites.map((e) => e.key).indexOf(a);

                            const label = index === -1 ? '' : sites[index].label;

                            return (



                                <Autocomplete
                                    size="small"
                                    id="combo-box-demo"
                                    disableClearable={true}
                                    value={label}
                                    options={sites}
                                    filterOptions={(options, _ref) => [...filterOptions(options, _ref), { label: 'Add New', key: 'Add New', value: 'addNew' },]}
                                    noOptionsText={<Button fullWidth variant='contained' color='primary' style={{ textTransform: 'none' }}
                                        onClick={() => {
                                            this.setState({ siteModal: true, siteItem: { tempKey: uuid() } })
                                        }}
                                    >+ Create Site</Button>}
                                    renderInput={(params) => <TextField {...params} label="Site" size="small" />}
                                    renderOption={(params) => {

                                        if (params.key === 'Add New') {
                                            return (
                                                <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 4 }}>
                                                    <Button fullWidth variant='contained' color='primary' style={{ textTransform: 'none' }} onClick={(e) => {
                                                        this.setState({ siteModal: true, siteItem: { tempKey: uuid(), } })



                                                    }}>+ Create Site</Button>
                                                </Box>

                                            )
                                        }
                                        else {
                                            return (
                                                <Typography {...params}>{params.key}</Typography>
                                            )
                                        }
                                    }}
                                    onChange={(a, d) => {

                                        if (b.key === 'Add New') {
                                            this.setState({ siteModal: true, siteItem: { tempKey: uuid(), } })
                                        }
                                        else {
                                            this.updateTable(b.rowData[keyColumn], 'locationLabel', d.label)
                                            this.updateTable(b.rowData[keyColumn], 'locationid', d.key)
                                            this.updateTable(b.rowData[keyColumn], 'locationBorder', null)
                                        }

                                    }}

                                />
                            )
                        }

                        else {
                            const index = sites.map((e) => e.key).indexOf(a);
                            const label = index === -1 ? '' : sites[index].label;
                            return (
                                <div>{label}</div>
                            )
                        }
                    }
                }
            },
            {
                name: "value",
                label: "Value",
                options: {
                    filter: false,

                    customBodyRender: (a, b, c) => {

                        if (editRow === b.rowData[keyColumn]) {

                            return (

                                <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                    id={`${b.rowData[keyColumn]}value`}
                                    defaultValue={a}
                                    type="number"
                                    onChange={(e) => {
                                        this.updateTable(b.rowData[keyColumn], 'value', e.target.value === "" ? "" : Number(e.target.value))
                                    }} />
                            )
                        }

                        else {
                            return (
                                <div>{a}</div>
                            )
                        }
                    }
                }
            },
            {
                name: "unitid",
                label: "Units",
                options: {
                    filter: false,
                    customBodyRender: (a, b, c) => {

                        if (editRow === b.rowData[keyColumn]) {
                            const index = unitList.map((e) => e.key).indexOf(a);
                            const label = index === -1 ? '' : unitList[index].label;
                            return (

                                <Autocomplete
                                    size="small"
                                    id="combo-box-demo"
                                    disableClearable={true}
                                    value={label}
                                    options={unitList}

                                    renderInput={(params) => <TextField {...params} label="Units" size="small" />}
                                    renderOption={(params) => {
                                        return (
                                            <Typography {...params}>{params.key}</Typography>
                                        )
                                    }}
                                    onChange={(a, d) => {
                                        this.updateTable(b.rowData[keyColumn], 'unitid', d.key)
                                    }}
                                />



                            )
                        }

                        else {
                            const index = unitList.map((e) => e.key).indexOf(a);
                            const label = index === -1 ? '' : unitList[index].label;
                            return (
                                <div>{label}</div>
                            )
                        }
                    }
                }
            },





        ];


        const CreateParameter = ({ parameterItem, userInfo, account }) => {
            return (
                <Button size="small" style={{ padding: 10, borderRadius: 4, textTransform: 'none', fontSize: 14, }}
                    variant='contained' color="primary"
                    onClick={async () => {
                        const db = getFirestore()
                        const currentItem = parameterItem;

                        currentItem.name = parameterItem.name || "";


                        const params = data.map((d) => d.name);



                        const checks = [
                            currentItem.name.length === 0 ? false : true,
                            params.includes(currentItem.name) ? false : true,
                        ]

                        if (currentItem.name.length === 0) {
                            this.props.openSnack('error', "Parameter name required!")
                        }
                        if (params.includes(currentItem.name)) {
                            this.props.openSnack('error', "Parameter name already exists!")
                        }


                        if (!checks.includes(false)) {

                            const key = currentItem.key || currentItem.tempKey;
                            currentItem.archived = currentItem.archived || false;

                            currentItem.account = userInfo.currentAccount;
                            currentItem.company = userInfo.currentCompany;


                            const docRef = doc(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'parameters', key);

                            if (currentItem.key) {
                                await updateDoc(docRef, currentItem).then(() => {
                                    this.props.openSnack('success', currentItem.key ? 'Parameter updated!' : "Parameter created!")
                                })
                            }
                            else {
                                await setDoc(docRef, {
                                    ...currentItem,
                                    key: currentItem.tempKey
                                }).then(() => {
                                    this.props.openSnack('success', currentItem.key ? 'Parameter updated!' : "Parameter created!")
                                    this.setState({ parameterModal: false })
                                })
                            }


                        }




                    }}>{parameterItem.key ? 'Update Parameter' : "Create Parameter"}</Button>
            )
        }



        return (
            <Row style={{}} >
                <Col {...fullMobile(16, 24)}  >
                    <Typography variant="h2" style={{ fontSize: 32, }}>Water Quality</Typography>
                </Col>
                <Col {...fullMobile(8, 24)} style={{ textAlign: 'right' }}>
                    {status === 'creating' && loading === false && (<Button size="small" style={{ textTransform: 'none', padding: 8, fontSize: 14, borderRadius: 24 }} variant="contained" color="primary"
                        fullWidth={screenWidth === 'xs' ? true : false}
                        onClick={() => {
                            this.addData()
                            this.props.openSnack('success', 'Item Added')
                        }}>+ Add Data</Button>)}
                </Col>
                <Col {...fullMobile(24, 24)} style={{ paddingTop: 8 }}>
                    {data !== undefined && data.length > 0 && uploadingData === false && (
                        <ThemeProvider theme={this.getMuiTheme()}>
                            <MUIDataTable data={data} columns={screenWidth === 'xs' ? [...columns, {
                                name: 'key',
                                label: "Delete",
                                options: {
                                    filter: true,
                                    sort: false,
                                    empty: true,
                                    customBodyRender: (value, b, updateValue) => {
                                        if (status === 'creating') {
                                            return (
                                                <div>
                                                    <IconButton size="small" aria-label="delete" color={'inherit'} onClick={() => {
                                                        //delete this item from data
                                                        const index = data.map((e, i) => e.key).indexOf(value);
                                                        const item = data[index];

                                                        this.deleteData(item, index)



                                                    }}>
                                                        <DeleteForever />
                                                    </IconButton>

                                                </div>

                                            )
                                        }
                                        else {
                                            return (
                                                <div></div>
                                            )
                                        }
                                    }
                                }
                            },] : columns} options={options} />
                        </ThemeProvider>
                    )}
                    {data === undefined || data.length === 0 && queryingData !== true && (
                        <Typography variant='body1'>Add Water Quality Data</Typography>
                    )}

                    {uploadingData === true && (
                        <CircularProgress />)}
                    {queryingData === true && (
                        <CircularProgress />)}
                </Col>

                <Modal title="" visible={parameterModal} onCancel={() => {
                    this.setState({ parameterModal: false, parameterItem: {} })

                }} footer={null} width={'60%'} style={{ borderRadius: 40 }} >
                    <Row align="middle" gutter={[16, 16]} >
                        <Col xs={{ offset: 0, span: 24 }} sm={{ offset: 6, span: 18 }} style={{ paddingBottom: 12, }}>
                            <Typography variant='h5'>Parameter</Typography>
                        </Col>
                        <Col xs={{ offset: 0, span: 24 }} sm={{ offset: 6, span: 18 }} style={{ paddingBottom: 12, }}>
                            <Typography fontWeight={300} variant='body1'>Reminder, you will be able to associate all water quality samples with either the parameter ID or parameter name.
                                The parameter name will be associated as case sensitive for all uploaded CSV files.</Typography>
                        </Col>


                        <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                            <Typography variant="body1">Name<span style={{ color: 'red' }}>*</span></Typography>
                        </Col>
                        <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                            <OutlinedInput fullWidth notched={false} label="none" placeholder={"Name"} size="small"
                                value={parameterItem.name || ''}
                                onChange={(e) => {
                                    this.setState({ parameterItem: { ...parameterItem, name: e.target.value } })


                                }} />
                        </Col>






                        < Col xs={{ offset: 0, span: 24 }} sm={{ offset: 6, span: 18 }} style={{ paddingBottom: 12, }}>
                            <CreateParameter parameterItem={parameterItem} userInfo={userInfo} account={account} />
                        </Col>



                    </Row>
                </Modal >

                <SiteModal openModal={siteModal} account={account} userInfo={userInfo} openSnack={this.props.openSnack}
                    screenWidth={screenWidth} onClose={() => { this.setState({ siteModal: false, siteItem: {} }) }} currentItem={siteItem} />

            </Row>
        );
    }
} 