import { Col, Row } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { fullMobile } from '../../util';
import HighchartsReact from "highcharts-react-official";
import highchartsMore from "highcharts/highcharts-more.js";
import Highcharts from "highcharts/highcharts.js";
import moment from 'moment';
import { CircularProgress, Divider, Typography } from '@mui/material';
import axios, * as others from 'axios';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
var convert = require('convert-units')
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/data')(Highcharts);




export default function WidgetProfile(props) {
    const { userInfo, screenWidth, openSnack, widgetRef, layoutRef, updateZoom, zoomRef } = props;
    const [layout, setLayout] = useState(layoutRef);
    const [widget, setWidget] = useState(widgetRef);
    const chartDiv = useRef(null);
    const plotRef = useRef(null);
    const chartRef = useRef(null);
    const [loading, setLoading] = useState(true);
    const [zoom, setZoom] = useState(zoomRef);
    const [chartOptions, setChartOptions] = useState({
        credits: {
            enabled: false
        },
        exporting: {
            enabled: false
        },
        stockTools: {
            gui: {
                enabled: false
            }
        },
        chart: {
            resetZoomButton: {
                theme: {
                   
                }
            },





            zoomType: 'x',

            height: null,
            animation: false
        },
        title: {
            text: null
        },
        legend: {
            itemStyle: {
                fontSize: '14px',
                fontFamily: 'Roboto, sans-serif'
            },
        },


        series: [],
        yAxis: widget.axes,
        xAxis: widget.xAxes,
        tooltip: {
            xDateFormat: '%b %d, %Y %I:%M %p',
            shared: true
        },
        plotOptions: {
            series: {
                animation: false
            },


        },
        time: {
            useUTC: false,
        }
    });


    useEffect(() => {
        console.log(widget)
        if (zoomRef === false) {
            setLoading(true)
            queryData(widget)
        }


    }, [zoomRef])




    



    useEffect(() => {
        setLoading(true)
        setWidget(widgetRef)
    }, [widgetRef])
    useEffect(() => {
        setLayout(layoutRef)
    }, [layoutRef])




    //create chart
    useEffect(() => {
        if (widget) {
            //creeate chart

            let chart = Highcharts.chart(chartDiv.current, chartOptions);
            chartRef.current = chart;
            const dateInterval = widget.dateInterval;
            //update chart shareAxis

            chartRef.current.update({
                chart: {
                    ...chartOptions.chart,
                    shareAxis: widget.shareAxis
                },
                legend: {
                    enabled: widget.legend,
                    itemStyle: {
                        fontSize: `${widget.legendFontSize || 14}px`,
                        fontFamily: 'Roboto, sans-serif'
                    },
                },
               


            }, false, false, false)



            setLoading(true)
            console.log("UPDATE ZOOM")
            addAxes(widget)


        }
    }, [widget])

    useEffect(() => {


    }, [])

    //if height or width of chart div changes update chart
    useEffect(() => {
        if (chartRef.current) {
            //find out what percent of the parent div is infoDiv and set plotRef height to 100% - infoDiv height

            chartRef.current.reflow();





        }
    }, [chartDiv.current?.clientHeight, chartDiv.current?.clientWidth])

    //add axes
    async function addAxes(widget) {

        const axes = widget.axes;

        //add axes to chart
        if (axes) {
            //check if axes are already added
            axes.forEach((axis, index) => {
                const item = {
                    ...axis,
                    reversed: true,
                    min: axis.customRange ? axis.min : null,
                    max: axis.customRange ? axis.max : null,
                    title: {
                        text: axis.label || null,
                        style: { fontSize: `${axis.fontSize || 12}px` },
                    },
                    labels: {

                        style: { fontSize: `${axis.fontSize || 12}px` },

                        autoRotation: [-10, -20, -30, -40, -50, -60, -70, -80, -90, -100, -110, -120, -130, -140, -150, -160, -170, -180],
                        rotation: axis.labelAngle || 0,
                        fontFamily: 'Roboto, sans-serif'
                    },

                }
                //if axis is not already added
                if (!chartRef.current.yAxis[index]) {
                    //add axis

                    chartRef.current.addAxis({
                        ...item,
                    }, false, false, false);
                }
                else {
                    //update axis

                    chartRef.current.yAxis[index].update({
                        ...item,

                    }, false);
                }
            });
            //redraw chart
            chartRef.current.redraw();
            addXAxes(widget)
        }
    }
    async function addXAxes(widget) {

        const axes = widget.xAxes;
        console.log(axes)

        //add axes to chart
        if (axes) {
            //check if axes are already added
            axes.forEach((axis, index) => {
                const item = {
                    ...axis,
                    min: axis.customRange ? axis.min : null,
                    max: axis.customRange ? axis.max : null,
                    title: {
                        text: axis.label || null,
                        style: { fontSize: `${axis.fontSize || 12}px` },
                    },
                    labels: {

                        style: { fontSize: `${axis.fontSize || 12}px` },

                        autoRotation: [-10, -20, -30, -40, -50, -60, -70, -80, -90, -100, -110, -120, -130, -140, -150, -160, -170, -180],
                        rotation: axis.labelAngle || 0,
                        fontFamily: 'Roboto, sans-serif'
                    },

                }
                //if axis is not already added
                if (!chartRef.current.xAxis[index]) {
                    //add axis

                    chartRef.current.addAxis({
                        ...item,
                    }, false, false, false);
                }
                else {
                    //update axis

                    chartRef.current.xAxis[index].update({
                        ...item,

                    }, false);
                }
            });
            //redraw chart
            chartRef.current.redraw();
            addReferences(widget)
        }
    }


    //add references
    async function addReferences(widget) {
        const references = widget.references;
        //add references to chart
        if (references) {
            //remove all references
            chartRef.current.xAxis[0].removePlotLine();
            //remove all from each yAxis
            chartRef.current.yAxis.forEach((axis, index) => {
                axis.removePlotLine();
            });


            if (references.length === 0) {
                queryData(widget)
            }
            //check if references are already added
            references.forEach((reference, index) => {
                const item = {
                    id: reference.id,

                    dashStyle: reference.dashStyle || 'solid',
                    color: reference.color || '#000000',
                    value: reference.orientation === 'vertical' ? reference.date : reference.yValue,
                    width: reference.width || 3,
                    zIndex: 5,
                    label: {
                        align: reference.textAlign || 'left',
                        verticalAlign: reference.verticalAlign || 'top',
                        text: reference.label || null,
                        style: {
                            color: reference.color || '#000000',
                            fontSize: `${reference.fontSize || 14}px`,
                            fontFamily: 'Roboto, sans-serif'
                        }
                    }
                }
                //if reference is not already added
                //add reference to xAxis if orientation is vertical
                if (reference.orientation === 'vertical') {
                    //if reference is not already added
                    const index = chartRef.current.xAxis[0].plotLinesAndBands.findIndex(plotLine => plotLine.id === reference.id);
                    if (index === -1) {
                        //add reference
                        chartRef.current.xAxis[0].addPlotLine({
                            ...item,

                        },);
                    }
                    else {
                        //update reference
                        chartRef.current.xAxis[0].plotLinesAndBands[index].update({
                            ...item,
                        },);

                    }

                }
                else {

                    //add reference to associated yAxis, yaxis not xaxis
                    const yAxisIndex = widget.axes.findIndex(yAxis => yAxis.axisId === reference.yAxis);
                    console.log(yAxisIndex)
                    //if yAxis is found
                    if (yAxisIndex > -1) {
                        console.log(reference)
                        console.log(yAxisIndex)
                        //if reference is not already added
                        if (!chartRef.current.yAxis[yAxisIndex].plotLinesAndBands[index]) {
                            //add reference
                            chartRef.current.yAxis[yAxisIndex].addPlotLine({
                                ...item,
                            },);
                        }
                        else {
                            //update reference
                            chartRef.current.yAxis[yAxisIndex].plotLinesAndBands[index].update({
                                ...item,
                            },);
                        }
                    }
                }

                //if last reference is added
                if (index === references.length - 1) {
                    //redraw chart
                    chartRef.current.redraw();
                    queryData(widget)
                }
            });
        }

    }









    async function queryData(widget, start, end) {

        const series = widget.profiles || [];

        function someAsyncFunction(q, index) {
            console.log(q)
            const item = q;
            const decimals = item?.decimals || 2;
            const offset = item?.offset || 0;


            return new Promise(async (resolve, reject) => {

                const db = getFirestore();
                const docRef = doc(db, 'clients', userInfo.currentCompany, 'profiles', q.profileid);
                const docSnap = await getDoc(docRef);
                const data = docSnap.data();
                console.log(data)

                //change data data to array of arrays of value and depth
                const convertData = data.data.map((item) => {
                    return [item.value, item.depth]
                })

                //check if series is already in chart by id, if it is then update data, if not then add series
                if (chartRef.current.get(item.key)) {
                    console.log(convertData.length)
                    chartRef.current.get(item.key).setData(convertData, true, true)
                    //chart is not zooming out when new data is added

                } else {
                    chartRef.current.addSeries({
                        id: item.key,
                        name: data.profileName || `Series ${index}`,
                        data: convertData,
                        type: item.type,
                        color: item.color,
                        yAxis: item.yAxis || 0,
                        xAxis: item.xAxis || 0,
                        visible: item.visible,
                        marker: {
                            enabled: item.dots,
                        },
                        tooltip: {
                            valueDecimals: decimals,
                            valueSuffix: item.unitsLabel ? ` ${item.unitsLabel}` : '',
                        },
                        zIndex: item.zIndex,
                        dashStyle: item.dashStyle,
                        lineWidth: item.lineWidth,
                        fillOpacity: item.fillOpacity,
                        fillColor: item.fillColor,
                        negativeColor: item.negativeColor,
                        threshold: item.threshold,
                        step: item.step,



                    })
                }


                resolve(data);



            });
        }











        const resultArray = await Promise.all(series.map(async (i, index) => someAsyncFunction(i, index + 1)));
        console.log(resultArray)
        setLoading(false);



    }

    const attributes = widget;




    //if loading is a string show error message

    return (

        //create div that adjust with size of parent div
        <div style={{ width: '100%', height: '100%', position: 'relative', }}>

            {loading === true && (<div style={{
                zIndex: 100,
                height: '100%',
                top: '0%',
                textAlign: 'center',
                position: 'absolute',
                width: '100%',
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                //center div vertically and horizontally
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',

            }}>
                <Row gutter={[12, 12]}>
                    <Col>
                        <Typography variant="h6" style={{ color: 'gray', paddingBottom: 20 }}>
                            Query Data...
                        </Typography>
                        <CircularProgress />
                    </Col>

                </Row>



            </div>)}
            <div ref={chartDiv} style={{ width: '100%', height: '100%', position: 'relative', }}>


                <div id={`${widget.key}`} ref={plotRef} style={{
                    height: '50%',
                    width: '100%',
                    alignContent: 'center',
                    position: 'relative'
                }}>

                </div>

            </div>




        </div >

    );




}