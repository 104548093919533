import React from 'react';
import { ChromePicker } from 'react-color';

class SketchExample extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            color: this.props.item.layerColor,
            item: this.props.item
        };
    }

    componentDidUpdate(prevProps) {

        if (this.props.item !== prevProps.item) {
            this.setState({ item: this.props.item, color: this.props.item.layerColor })
        }

    }

    handleChange = (color) => {
        this.props.changeColor(color.rgb)
        this.setState({ color: color.rgb })
    };

    render() {

        const { color } = this.state;

        return (
            <ChromePicker color={color} onChange={this.handleChange} />
        )
    }
}

export default SketchExample