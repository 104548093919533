import React, { useEffect } from "react";
import { Autocomplete, Input, TextField } from "@mui/material";
import Units from '../../../extra/units.json';

const UnitsSelect = ({
    currentStream,
    setCurrentStream,
    headerKey,
    options
}) => {

    const [unitList, setUnitList] = React.useState([]);

    useEffect(() => {
        const unitList = Object.values(Units).map((u, i) => {
            return ({ label: u, key: u, unitid: Object.keys(Units)[i] })
        })
        //sort based on key
        unitList.sort((a, b) => a.key.localeCompare(b.key))
        setUnitList(unitList)
    }, [])
    const [value, setValue] = React.useState(currentStream?.[headerKey]);  // Renamed from 'age' to 'value'

    const handleChange = (event) => {
        let val = event;
        console.log({ ...currentStream, [headerKey]: val })
        setCurrentStream({ ...currentStream, [headerKey]: val })
        setValue(val);
    };

    return (
        <Autocomplete
            sx={{
                groupLabel: {
                    fontWeight: 'bold',
                    fontSize: 20
                }
            }
            }

            size="small"
            style={{ backgroundColor: 'white' }}
            disableClearable={true}
            id="combo-box-demo"
            value={value}
            options={
                unitList

            }
            filterSelectedOptions
            renderInput={(params) => (
                <TextField 
                    {...params} 
                    placeholder={"Parameters"} 
                    size="small"
                    inputProps={{
                        ...params.inputProps,
                        style: {
                            color: 'black',
                            fontWeight: 500
                        }
                    }} 
                />
            )}
            onChange={(a, b) => {
                handleChange(b?.key)


            }}

        />
    );
};

export default UnitsSelect;
