import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Checkbox, CircularProgress, Divider, Fab, IconButton, Input, InputAdornment, OutlinedInput, Paper, Typography } from '@mui/material';
import { Col, Modal, Popconfirm, Row } from 'antd';
import { collection, deleteDoc, doc, getDocs, getFirestore, query, setDoc, updateDoc } from 'firebase/firestore';
import moment from 'moment';
import React from 'react';
import DatePicker from "react-datepicker";
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import "../../extra/hoverStyle.css";
import axios, * as others from 'axios';
import queryString from 'query-string';

export default class References extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            data: [],
            filteredData: [],
            filters: [],
            searchText: '',
            loading: true,
            type: this.props.type,
            currentItem: {},
            openModal: false,
            nextPage: '',
            token: '',
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0)
        this.queryData()


    }





    queryData = async () => {
        const { userInfo, type } = this.state;
        const db = getFirestore();
        console.log(userInfo);
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'references'),);
        const data = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            data.push(doc.data())


        });
        console.log(data);
        data.sort((a, b) => a.label.localeCompare(b.label))
        this.setState({ data, loading: false })

        this.filterData(data, '', [])
    }

    filterData = (data, searchText, filters) => {
        const filteredData = data.filter(d => d.label.match(new RegExp(searchText, "i")))
        this.setState({ filteredData })

    }


    updateCurrentItem = (boo, val) => {
        const { currentItem } = this.state;
        currentItem[boo] = val;
        this.setState({ currentItem })
    }



    render() {

        const { userInfo, account, data, filteredData, loading, searchText, filters, type, currentItem, accounts, openModal, screenWidth, } = this.state;
        const db = getFirestore();

        const CreateItemButton = () => {
            let navigate = useNavigate();

            return (
                <Fab style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="medium"
                    onClick={async () => {
                        this.setState({ openModal: true, currentItem: { tempKey: uuid(), horizontal: false, date: moment().format('x') }, })
                    }}>
                    <AddIcon />
                </Fab>
            )
        }

        const EditItem = ({ item }) => {
            let navigate = useNavigate();
            return (
                <IconButton style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="small"
                    onClick={async () => {
                        console.log(item)
                        this.setState({ openModal: true, currentItem: item })
                    }}>
                    <EditIcon />
                </IconButton>
            )
        }

        const DeleteItem = ({ item }) => {
            let navigate = useNavigate();
            return (
                <Popconfirm
                    title="Are you sure to delete this reference?"
                    onConfirm={async () => {
                        await deleteDoc(doc(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'references', item.key)).then(() => {
                            const newData = filteredData.filter((f) => f.key !== item.key)
                            this.setState({ filteredData: newData })
                            this.props.openSnack('success', 'Reference removed.')
                        })

                    }}
                    okText="Yes"
                    cancelText="No"
                >
                    <IconButton style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="small"
                    >
                        <DeleteForeverIcon />
                    </IconButton>
                </Popconfirm>


            )
        }

        const RowItem = ({ item }) => {
            return (
                <Col span={24} style={{ cursor: 'pointer', padding: 4 }} className="rowHover"
                >
                    <Row gutter={[8, 8]} align="middle" >

                        <Col xs={14} sm={20} onClick={async () => {
                            this.setState({ currentItem: item, openModal: true })
                        }}>
                            <Typography fontWeight={500} variant='subtitle1'>{item.label}</Typography>
                            {!item.horizontal && (<Typography fontSize={13} variant='body1'>{moment(item.date, 'x').format('MM/DD/YYYY hh:mm a')}</Typography>)}
                            {item.horizontal && (<Typography fontSize={13} variant='body1'>Y Value: {item.yValue}</Typography>)}


                        </Col>
                        <Col xs={10} sm={4} style={{ textAlign: 'right' }}>
                            <EditItem item={item} /> <DeleteItem item={item} />
                        </Col>


                        <Col span={24}>
                            <Divider />
                        </Col>

                    </Row>
                </Col>
            )
        }

        const CreateReference = () => {
            return (
                <Button size="small" style={{ padding: 10, borderRadius: 4, textTransform: 'none', fontSize: 14, }}
                    variant='contained' color="primary"
                    onClick={async () => {
                        const { currentItem } = this.state;

                        currentItem.label = currentItem.label || "";




                        const checks = [
                            currentItem.label.length === 0 ? false : true,
                        ]

                        if (currentItem.label.length === 0) {
                            this.props.openSnack('error', "Reference label required!")
                        }

                        if (!checks.includes(false)) {

                            const key = currentItem.key || currentItem.tempKey;
                            currentItem.archived = currentItem.archived || false;
                            currentItem.date = currentItem.date || moment().format('x');
                            currentItem.horizontal = currentItem.horizontal || false;
                            currentItem.yValue = currentItem.yValue || 0;

                            currentItem.account = userInfo.currentAccount;
                            currentItem.company = userInfo.currentCompany;


                            const docRef = doc(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'references', key);

                            if (currentItem.key) {
                                await updateDoc(docRef, currentItem).then(() => {
                                    const index = filteredData.map((d) => d.key).indexOf(currentItem.key)
                                    filteredData[index] = currentItem;
                                    this.setState({ filteredData: [...filteredData], openModal: false, currentItem: {}, })
                                }).then(() => {
                                    this.props.openSnack('success', currentItem.key ? 'Reference updated!' : "Reference created!")
                                })
                            }
                            else {
                                await setDoc(docRef, {
                                    ...currentItem,
                                    key: currentItem.tempKey
                                }).then(() => {
                                    filteredData.push({ ...currentItem, key: currentItem.tempKey })
                                    this.setState({ filteredData, openModal: false, currentItem: {}, })
                                }).then(() => {
                                    this.props.openSnack('success', currentItem.key ? 'Reference updated!' : "Reference created!")
                                })
                            }


                        }




                    }}>{currentItem.key ? 'Update Reference' : "Create Reference"}</Button>
            )
        }




        return (
            <Row style={{}} align="middle">
                <Col span={23}>
                    <Row align="middle">
                        <Col xs={16} sm={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>
                            <Typography variant="h4">References</Typography>
                        </Col>
                        <Col xs={6} sm={4} style={{ paddingTop: 30, paddingBottom: 15, textAlign: 'right', position: 'relative', zIndex: 0 }}>
                            {loading ? <CircularProgress /> : <CreateItemButton />}
                        </Col>
                        <Col span={24}>
                            <Divider />
                        </Col>
                    </Row>
                </Col>

                <Col span={23} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 30 }}>
                    {loading ? <CircularProgress /> :
                        <Paper elevation={16} style={{ padding: 20, borderRadius: 12 }}>
                            <Row>
                                <Col xs={24} sm={12} style={{ paddingBottom: 8 }}>
                                    <Input fullWidth
                                        autoComplete='off'
                                        value={searchText}
                                        onChange={(e) => {
                                            this.setState({ searchText: e.target.value })
                                            if (e.target.value.length > 1) {
                                                this.filterData(data, e.target.value, filters)
                                            }
                                            else {
                                                this.filterData(data, '', filters)
                                            }
                                        }}
                                        id="input-with-icon-adornment"
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        }
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    edge="end"
                                                    onClick={() => {
                                                        this.setState({ searchText: '' })
                                                        this.filterData(data, '', filters)
                                                    }}
                                                >
                                                    <HighlightOffIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </Col>
                                <Col xs={0} sm={12} style={{ textAlign: 'right' }}>
                                    <Typography variant='h6'>Actions</Typography>
                                </Col>

                                <Col span={24}>
                                    <Row align='middle' >
                                        {filteredData.map((d) => {
                                            return (
                                                <RowItem item={d} />

                                            )

                                        })}
                                    </Row>
                                </Col>
                            </Row>
                        </Paper>
                    }
                </Col>

                <Modal title="" visible={openModal} onCancel={() => this.setState({ openModal: false })} footer={null} width={'60%'} style={{ borderRadius: 40 }} >
                    <Row align="middle" gutter={[16, 16]} >
                        <Col xs={{ offset: 0, span: 24 }} sm={{ offset: 6, span: 18 }} style={{ paddingBottom: 12, }}>
                            <Typography variant='h5'>Reference</Typography>
                        </Col>


                        <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                            <Typography variant="body1">Reference Label<span style={{ color: 'red' }}>*</span></Typography>
                        </Col>
                        <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                            <OutlinedInput fullWidth notched={false} label="none" placeholder={"Label"} size="small"
                                value={currentItem.label || ''}
                                onChange={(e) => {
                                    this.updateCurrentItem(`label`, e.target.value)
                                }} />
                        </Col>


                        <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                            <Typography variant="body1">Description</Typography>
                        </Col>
                        <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                            <OutlinedInput fullWidth notched={false} label="none" placeholder={"Description"} size="small"
                                multiline={true}
                                rows={4}
                                value={currentItem.description || ''}
                                onChange={(e) => {
                                    this.updateCurrentItem(`description`, e.target.value)
                                }} />
                        </Col>

                        <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                            <Typography variant="body1">Horizontal</Typography>
                        </Col>
                        <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                            <Checkbox checked={currentItem.horizontal}
                                onChange={(e) => {
                                    this.updateCurrentItem(`horizontal`, e.target.checked)
                                }} />
                        </Col>

                        {currentItem.horizontal === false && (<Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                            <Typography variant="body1">Date</Typography>
                        </Col>)}
                        {currentItem.horizontal === false && (<Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                            

                            <DatePicker timeCaption="Time" showTimeSelect timeFormat="h:mm a" timeIntervals={1}
                                dateFormat="MM/dd/yyyy hh:mm a" portalId="root-portal" selected={new Date(moment(currentItem.date, 'x').format('LLL') || moment().format('LLL'))} onChange={(date) => {

                                    console.log(date);
                                    console.log();
                                    this.updateCurrentItem('date', moment(date).format('x'))
                                }} />


                        </Col>)}

                        {currentItem.horizontal === true && (<Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                            <Typography variant="body1">Y Value</Typography>
                        </Col>)}
                        {currentItem.horizontal === true && (<Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                            <OutlinedInput notched={false} label="none" placeholder={""} size="small"
                                value={currentItem.yValue}
                                type="number"
                                inputProps={{ min: 0 }}
                                onChange={(e) => {
                                    this.updateCurrentItem('yValue', e.target.value === "" ? "" : Number(e.target.value))
                                }} />
                        </Col>)}

                        {currentItem.horizontal === false && (<Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                            <Typography variant="body1">X Value</Typography>
                        </Col>)}
                        {currentItem.horizontal === false && (<Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                            <OutlinedInput notched={false} label="none" placeholder={""} size="small"
                                value={currentItem.xValue}
                                type="number"
                                inputProps={{ min: 0 }}
                                onChange={(e) => {
                                    this.updateCurrentItem('xValue', e.target.value === "" ? "" : Number(e.target.value))
                                }} />
                        </Col>)}



                        < Col xs={{ offset: 0, span: 24 }} sm={{ offset: 6, span: 18 }} style={{ paddingBottom: 12, }}>
                            <CreateReference />
                        </Col>

                    </Row>
                </Modal >




            </Row >
        );
    }
} 