import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Autocomplete, Button, TextField, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import DatePicker from "react-datepicker";
import { retrieveProbeData } from './utils';
import moment from 'moment-timezone';

const RetrieveData = (props) => {
    const { userInfo, stations, probes } = props;
    const [node, setNode] = useState(null);
    const [probe, setProbe] = useState(null);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [loading, setLoading] = useState(false);
    const [dataDate, setDataDate] = useState(null);

    useEffect(() => {
        console.log(stations)
        console.log(probes)
    }, [])

    async function updateDate(newDate) {
        setDataDate(moment(newDate, 'X').format('MM/DD/YYYY h:mm a'));
    }

    return (
        <Row style={{ paddingTop: 20 }}>
            <Col span={24}>
                <Typography variant="body1" fontWeight={600}>
                    Station
                </Typography>

                <Autocomplete
                    disablePortal
                    size="small"
                    margin="dense"
                    id="combo-box-demo"
                    options={stations}
                    sx={{ width: 300 }}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => <TextField {...params} placeholder="Station" />}
                    onChange={(event, value) => setNode(value)}
                />
            </Col>
            <Col span={24} style={{ paddingTop: 15 }}>
                <Typography variant="body1" fontWeight={600}>
                    Probe
                </Typography>

                <Autocomplete
                    disablePortal
                    size="small"
                    margin="dense"
                    id="combo-box-demo"
                    options={probes}
                    sx={{ width: 300 }}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => <TextField {...params} placeholder="Station" />}
                    onChange={(event, value) => setProbe(value)}
                />
            </Col>
            <Col span={24} style={{ paddingTop: 15 }}>
                <Typography variant="body1" fontWeight={600}>
                    Start Date
                </Typography>

                <DatePicker portalId="root-portal"
                    showTimeSelect
                    timeFormat="h:mm a"
                    timeIntervals={1}
                    timeCaption="time"
                    dateFormat="MM/dd/yyyy h:mm a"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                />

            </Col>
            <Col span={24} style={{ paddingTop: 15 }}>
                <Typography variant="body1" fontWeight={600}>
                    End Date
                </Typography>

                <DatePicker portalId="root-portal"
                    showTimeSelect
                    timeFormat="h:mm a"
                    timeIntervals={1}
                    timeCaption="time"
                    dateFormat="MM/dd/yyyy h:mm a"
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                />

            </Col>
            <Col span={24} style={{ paddingTop: 15 }}>
                {loading ?
                    <>
                        <FontAwesomeIcon icon={faSpinner} spin />
                        <Typography variant="body1" fontWeight={400} style={{ paddingLeft: 10 }}>
                            Got data through {dataDate}
                        </Typography>
                        <Typography variant="body1" fontWeight={600} style={{ paddingLeft: 10 }}>
                            This may take a couple minutes to get all historical probe data.  Please do not leave page until retrieval is complete.
                        </Typography>
                    </>
                    :
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        style={{
                            textTransform: 'none',
                            backgroundColor: '#404050'
                        }}
                        onClick={async () => {
                            console.log(node)
                            console.log(probe)
                            console.log(startDate)
                            setLoading(true)

                            const sensorData = {
                                account: "7ccc9e03-2225-1f6c-2fbc-3b518ed61d84",
                                api_key: "11d28cb08c2444a2966931244ee43e08",
                                api_key_client_id: "testNew",
                                api_key_client_secret: "11d28cb08c2444a2966931244ee43e08",
                                api_key_id: "f56328da-1d67-28aa-6780-1fd38664b3cb",
                                archived: true,
                                company: "Pine Mountain Lake312aac68-2c8a-eb52-1fc1-9f415fe59f45",
                                companyKey: "Pine Mountain Lake312aac68-2c8a-eb52-1fc1-9f415fe59f45",
                                depthFromBottom: 0,
                                description: "\"PML-Epi-968376\"",
                                hydroID: 5393393777770496,
                                hydroName: "PML-Epi-968376",
                                id: 5393393777770496,
                                inactive: true,
                                key: "e53d7d67-f6a9-ce80-29c3-6398a07f64b4",
                                lastRecorded: "N/A",
                                lastUpdated: "1704216771058",
                                lat: 40.58833,
                                lon: -105.06587,
                                name: "PML-Epi-968376",
                                nodeid: null,
                                position: 5,
                                recording: true,
                                selected: false,
                                startRecordingTime: "1680760227835",
                                tempKey: "e53d7d67-f6a9-ce80-29c3-6398a07f64b4",
                                timezone: "US/Pacific",
                                type: "RDO"
                            };


                            const response = await retrieveProbeData(userInfo, node, probe, startDate, endDate, updateDate);
                            console.log(response)
                            setLoading(false)

                        }}
                    >
                        Retrieve Data
                    </Button>
                }
            </Col>
        </Row>
    )
}

export default RetrieveData;