
import { Box, Card, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import LogNumber from "./logNumber";
import BasicInfo from "./basicInfo";
import LogWeather from "./logWeather";
import MultiDayTable from "./multiDayTable";
import Items from "./items";





const LogBody = ({
    openSnack,
    userInfo,
    screenWidth,
    log,
    setLog,
    account,
    company,
    staff,
    accountUsers,
    stocks,
    setStocks,
    items,
    setItems,
    accounts,
    stations,
    setStations,
}) => {
   
    const props = {
        openSnack,
        userInfo,
        screenWidth,
        log,
        setLog,
        account,
        company,
        staff,
        accountUsers,
        stocks,
        setStocks,
        items,
        setItems,
        accounts,
        stations,
        setStations
    }

    const shouldUsePaper = screenWidth === 'xs' ? false : true;





    return (

        <Box sx={{
            flexGrow: 1, p: screenWidth === 'xs' ? 2 : 6
        }}>
            <Paper elevation={shouldUsePaper ? 12 : 0} sx={{
                borderRadius: 3,
            }}>
                <Grid container spacing={2}
                    sx={{ p: screenWidth === 'xs' ? 0 : 2 }}
                >

                    <Grid item xs={12} sm={6} >
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <LogNumber key="logNumber" {...props} />
                            </Grid>
                            {/* <Grid item xs={12} sm={12}>
                                <LogWeather key="logWeather" {...props} />
                            </Grid> */}
                        </Grid>

                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <BasicInfo key="basicInfo" {...props} />
                    </Grid>
                   {/*  <Grid item xs={12} sm={12}>
                        <MultiDayTable key="multiDayTable" {...props} />
                    </Grid> */}
                    <Grid item xs={12} sm={12}>
                        <Items key="logItems" {...props} />
                    </Grid>

                </Grid>
            </Paper>

        </Box >

    )
}

export default LogBody;