import { Breadcrumbs, Button, ButtonGroup, Divider, MenuItem, OutlinedInput, Typography, Select, TextField, Table, Menu, Popper, Box, CircularProgress } from '@mui/material';
import { Col, Modal, Row, } from 'antd';
import React, { useEffect, useState, useMemo, useRef } from 'react';
import { fullMobile } from '../util';
import 'react-data-grid/lib/styles.css';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { getFirestore, getDoc, doc, setDoc, updateDoc } from 'firebase/firestore';
import AddIcon from '@mui/icons-material/Add';
import { faDownload, faFile, faFileCirclePlus, faFolder, faFolderOpen, faFolderPlus, faImage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import uuid from 'react-uuid';
import moment from 'moment-timezone';
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { Tree } from 'react-arborist';
import download from 'downloadjs';
import { getDownloadURL, getStorage, ref, uploadBytesResumable, put } from "firebase/storage";
//create style of row paddingTop and bottom 15
//create style of row paddingTop and bottom 15

const style = {
    row: {
        paddingTop: 15,
        paddingBottom: 15,
        paddingLeft: 15,
        paddingRight: 15,
        //align center in div


    }
}





export default function EquipmentFilesTable(props) {
    const { userInfo, screenWidth, openSnack, company, account, staffRef, usersRef, updateItem, itemRef } = props;
    const [item, setItem] = useState({})
    const [data, setData] = useState([])
    const [masterData, setMasterData] = useState([])
    const [folder, setFolder] = useState({})
    const [currentButton, setCurrentButton] = useState('general');
    const [createFolderModal, setCreateFolderModal] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [currentFolder, setCurrentFolder] = useState('root')
    const [currentChildren, setCurrentChildren] = useState([])
    const inputRef = useRef(null);
    const treeRef = useRef(null);
    const folderNameRef = useRef(null);
    const open = Boolean(anchorEl);



    useEffect(() => {
        setMasterData(itemRef?.files || [])

    }, [itemRef])



    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (type) => {
        if (type === 'folder') {
            setCreateFolderModal(true)
            const key = uuid()
            setFolder({
                name: '',
                description: '',
                key,
                id: key,
                children: [],
                type: 'folder',
                createdOn: moment().format('x'),
                editing: false,
                parent: currentFolder,
                expanded: false,
            })
            //focus on folderNameRef


        }
        setAnchorEl(null);
    };

    useEffect(() => {
        window.scrollTo(0, 0)
        getInfo()
    }, [])
    const db = getFirestore()

    async function getInfo() {




    }



    const handleChange = async (event) => {
        const fileList = event.target.files;
        const filesArr = [];

        for (let i = 0; i < fileList.length; i++) {
            const file = fileList[i];
            const key = uuid()
            const storage = getStorage();
            const storageRef = ref(storage, `clients/${userInfo.currentCompany}/equipment/${key}/${file.name}`);

            const item = {
                name: file.name,
                description: '',
                key,
                id: key,
                children: [],
                type: 'file',
                createdOn: moment().format('x'),
                editing: false,
                parent: currentFolder,
                expanded: false,
                url: 'loading',
                uploaded: true,
            }
            const items = [...masterData, item]
            setMasterData([...masterData, item])

            const uploadTask = uploadBytesResumable(storageRef, file);
            uploadTask.on('state_changed',
                (snapshot) => {
                    switch (snapshot.state) {
                        case 'paused':
                            console.log('Upload is paused');
                            break;
                        case 'running':
                            console.log('Upload is running');
                            break;


                    }
                },
                (error) => {
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {

                        //update url in masterData
                        const index = items.findIndex(item => item.key === key)
                        console.log(index)
                        items[index].url = downloadURL
                        setMasterData([...items])







                    });
                })



        }



        console.log(filesArr)
        handleClose('file')

    };

    async function createFolder() {
        if (folder.name === '') {
            openSnack('error', 'Please enter a name for the folder')
        } else {
            //add folder to files array of equipment
            if (currentFolder === 'root') {
                //add to root
                data.push(folder)
                setData([...data])
                updateItem('files', data)
                //updatet treeRef data

                //data with and delete all children
                masterData.push(folder)
                setMasterData([...masterData])
                console.log(masterData)

            }
            else {






            }
        }
    }

    async function createFile() {
        if (folder.name === '') {
            openSnack('error', 'Please enter a name for the folder')
        } else {
            //add folder to files array of equipment
            if (currentFolder === 'root') {
                //add to root
                data.push(folder)
                setData([...data])
                updateItem('files', data)
                //updatet treeRef data

                //data with and delete all children
                masterData.push(folder)
                setMasterData([...masterData])
                console.log(masterData)

            }
            else {






            }
        }
    }

    //find currnet folder parents


    function Node({ node, style, dragHandle }) {
        /* This node instance can do many things. See the API reference. */

        return (
            <div style={style} ref={dragHandle} onClick={() => {
                setCurrentFolder(node.data.key)


            }}>

                {node.data.type === 'folder' ?
                    <FontAwesomeIcon icon={faFolder} style={{ marginRight: 8 }} />
                    :
                    <FontAwesomeIcon icon={faFile} style={{ marginRight: 8 }} />
                }
                {node.data.name}


            </div>
        );
    }


    return (

        <Row style={{ backgroundColor: 'white' }}>

            <Col span={24} style={{ textAlign: 'right', paddingTop: 8, paddingRight: 8 }}>
                <Button
                    variant="contained" component="label"
                    style={{
                        width: 100,
                        padding: 4, paddingTop: 8, paddingBottom: 8,
                        textTransform: 'none', backgroundColor: '#404050', fontSize: 13,
                    }}
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    <AddIcon /> Add
                </Button>

                <Popper id={'popper'} open={open} anchorEl={anchorEl}
                    onMouseLeave={() => handleClose('file')}

                >
                    <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}>
                        <Button
                            variant="link" component="label"
                            style={{
                                padding: 4, paddingTop: 8, paddingBottom: 8,
                                textTransform: 'none', color: '#404050',
                            }}
                        >
                            <input hidden
                                //accepts any file type
                                accept="*"

                                multiple type="file"

                                //once files are uploaded console log the files
                                onChange={handleChange}



                            />

                            <FontAwesomeIcon icon={faFileCirclePlus} size="1.5x" style={{ marginRight: 5 }} />
                            <Typography variant="body1" style={{ fontWeight: 400, color: '#404050' }}>
                                File Upload
                            </Typography>

                        </Button>
                        <Divider />
                        {/*  {currentFolder === "root" && (<Button
                            variant="link" component="label"
                            style={{
                                padding: 4, paddingTop: 8, paddingBottom: 8,
                                textTransform: 'none', color: '#404050',
                            }}
                            onClick={() => handleClose('folder')}
                        >
                            <FontAwesomeIcon icon={faFolderPlus} size="1.5x" style={{ marginRight: 5 }} />
                            <Typography variant="body1" style={{ fontWeight: 400, color: '#404050' }}>
                                Create Folder
                            </Typography>

                        </Button>
                        )} */}


                    </Box>
                </Popper>



            </Col>
            {/*   <Col span={6} style={{
                padding: 6,
                border: '1px solid #e0e0e0',
                backgroundColor: '#f5f5f5',
            }}>

                <Row>
                    <Col span={24}>
                        <Tree
                            ref={treeRef}
                            data={data} >
                            {Node}
                        </Tree>
                    </Col>
                </Row>

            </Col> */}
            <Col span={24}>
                <Row>
                    <Col span={24}>
                        <Typography variant="h6" display="inline" style={{ fontWeight: 400, color: '#404050', paddingTop: 8, paddingLeft: 8, cursor: 'pointer' }}

                            onClick={() => setCurrentFolder('root')}
                        >
                            Root   {currentFolder !== 'root' && (' >')}
                        </Typography>


                    </Col>
                    <Col span={24}>
                        <Table>
                            <TableHead>
                                <TableRow>

                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>File Name</TableCell>
                                    <TableCell>Created On</TableCell>
                                    <TableCell>Delete</TableCell>


                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {masterData.filter((r) => r.parent === currentFolder).sort((a, b) => a.name.localeCompare(b.name))
                                    .map((row, index) => {


                                        const backgroundColor = index % 2 === 0 ? '#f8f8f8' : 'white';
                                        return (
                                            <TableRow
                                                key={row.key}
                                                sx={{
                                                    '&:last-child td, &:last-child th': { border: '1px solid rgba(0,0,0,0.2)' },
                                                    //onhover change background color
                                                    '&:hover': {
                                                        backgroundColor: '#40405036'
                                                    },
                                                    height: 20,
                                                    backgroundColor: backgroundColor,
                                                    cursor: 'pointer',
                                                    paddingTop: 0,
                                                    paddingBottom: 0,
                                                }}
                                                style={{
                                                    paddingTop: 0,
                                                    paddingBottom: 0,
                                                }}
                                            >


                                                <TableCell
                                                    style={{
                                                        cursor: 'pointer',
                                                        width: 50,
                                                        paddingTop: 0,
                                                        paddingBottom: 0,

                                                    }}
                                                    onClick={() => {
                                                        const newData = masterData
                                                        const itemIndex = newData.findIndex(x => x.key === row.key)
                                                        newData[itemIndex].editing = !newData[itemIndex].editing
                                                        setMasterData([...newData])
                                                        //focus on inputRef
                                                        inputRef.current.focus()

                                                    }}

                                                >

                                                    <FontAwesomeIcon icon={faPenToSquare} size="1x" style={{ marginRight: 5, color: '#404050', }} />

                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        cursor: 'pointer',
                                                        width: 50,
                                                        paddingTop: 0,
                                                        paddingBottom: 0,

                                                    }}
                                                    onClick={() => {

                                                        //native download of row.url
                                                        window.open(row.url, '_blank')







                                                    }}

                                                >
                                                    {row.url === 'loading' ? <CircularProgress size={20} /> :
                                                        <FontAwesomeIcon icon={faDownload} size="1x" style={{ marginRight: 5, color: '#404050', }} />
                                                    }

                                                </TableCell>

                                                <TableCell component="th" scope="row"
                                                    style={{
                                                        cursor: 'pointer',
                                                        paddingTop: 0,
                                                        paddingBottom: 0,
                                                    }}
                                                    //if double clicked change to edit mode
                                                    onDoubleClick={() => {
                                                        if (row.type === 'folder') {
                                                            if (inputRef.current) {
                                                                inputRef.current.blur()
                                                            }
                                                            setTimeout(() => {
                                                                setCurrentFolder(row.key)


                                                            }, 100);

                                                            //blur inputRef




                                                        }
                                                    }}

                                                    //on single click change current folder
                                                    onClick={() => {
                                                        console.log('single clicked')

                                                    }}


                                                >


                                                    {row.type === 'folder' ?
                                                        <FontAwesomeIcon icon={faFolder} size="2x" style={{ marginRight: 5, color: '#404050', }} /> :
                                                        row.type === 'image' ?
                                                            <FontAwesomeIcon icon={faImage} size="2x" style={{ marginRight: 5, color: '#404050', }} /> :
                                                            <FontAwesomeIcon icon={faFile} size="2x" style={{ marginRight: 5, color: '#404050', }} />}


                                                    {row.editing ?
                                                        <OutlinedInput
                                                            ref={inputRef}
                                                            size="small"
                                                            defaultValue={row?.name}

                                                            onBlur={(e) => {
                                                                const newData = masterData
                                                                const itemIndex = newData.findIndex(x => x.key === row.key)
                                                                newData[itemIndex].editing = !newData[itemIndex].editing
                                                                newData[itemIndex].name = e.target.value
                                                                setMasterData([...newData])
                                                                updateItem('files', newData)



                                                            }}
                                                        />
                                                        :
                                                        row.name

                                                    }
                                                </TableCell>
                                                <TableCell style={{
                                                    paddingTop: 0,
                                                    paddingBottom: 0,
                                                }}>{moment(row.createdOn, 'x').format('MM-DD-YYYY hh:mm a')}</TableCell>
                                                <TableCell style={{
                                                    paddingTop: 0,
                                                    paddingBottom: 0,
                                                }}>
                                                    <Button
                                                        size="small"
                                                        variant="contained" component="label"
                                                        style={{
                                                            padding: 4, paddingTop: 8, paddingBottom: 8,
                                                            textTransform: 'none', backgroundColor: '#404050', fontSize: 13,
                                                        }}
                                                        onClick={() => {
                                                            const newData = data
                                                            newData.splice(index, 1)
                                                            setData([...newData])
                                                            updateItem('files', newData)
                                                        }}
                                                    >
                                                        Delete
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                            </TableBody>
                        </Table>

                    </Col>
                </Row>

            </Col>


            <Modal title="" visible={createFolderModal} footer={null}
                onOk={() => setCreateFolderModal(false)} onCancel={() => setCreateFolderModal(false)}>

                <Row>

                    <Col span={24}>
                        <Typography variant='h5'>Create Folder</Typography>
                    </Col>

                    <Col span={24} style={{ paddingTop: 20 }}>
                        <Typography variant="body1" style={{ fontWeight: 600, color: '#404050' }}>
                            Folder Name
                        </Typography>
                        <OutlinedInput

                            size="small"
                            fullWidth
                            id="outlined-adornment-amount"
                            value={folder?.name}
                            onChange={(e) => {
                                setFolder({ ...folder, name: e.target.value })
                            }}

                        />
                    </Col>
                    <Col span={24} style={{ paddingTop: 40 }}>
                        <Row justify={'end'}>
                            <Col>
                                <Button
                                    variant="link" component="label"
                                    style={{
                                        padding: 4, paddingTop: 8, paddingBottom: 8,
                                        textTransform: 'none', color: '#404050', fontSize: 13,
                                    }}
                                    onClick={() => {
                                        setCreateFolderModal(false)
                                        setFolder({})
                                    }}
                                >
                                    Cancel
                                </Button>

                            </Col>

                            <Col style={{ paddingLeft: 10 }}>
                                <Button

                                    variant="contained" component="label"
                                    style={{
                                        width: 100,
                                        padding: 4, paddingTop: 8, paddingBottom: 8,
                                        textTransform: 'none', backgroundColor: '#404050', fontSize: 13,
                                    }}
                                    onClick={() => {
                                        setCreateFolderModal(false)
                                        createFolder(folder)

                                    }}
                                >
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </Col>

                </Row>

            </Modal>


        </Row >

    );
}