import React, { useEffect, useState } from "react";
import {
    Box, Grid, Typography, OutlinedInput, FormControl, InputLabel, Select, MenuItem,
    InputAdornment
} from '@mui/material';
import StaffPickerSingle from "../../../../staffPickerSingle";


const LeadStaff = (
    {
        record,
        items,
        setItems,
        screenWidth,
        openSnack,
        userInfo,
        updateRecord,
        stocks,
        setStocks,
        accounts,
        accountUsers

    }

) => {
    const [isFocused, setIsFocused] = useState(false);
    const props = {
        accountUsers,
        record,
        updateRecord,
        userInfo,
        openSnack,
    }



    return (
        <>
            <Typography variant="h6" component="div" color={'rgba(0,0,0,0.8)'} fontWeight={700} fontSize={13}>
                Lead Staff
            </Typography>
            <Grid container alignItems="flex-end" style={{ marginBottom: 10 }}>
                <Grid item xs={12}>

                    <StaffPickerSingle {...props} type="flItem" />
                </Grid>

            </Grid>

        </>
    );
};

export default LeadStaff;
