import { collection, getDocs, getDoc, addDoc, updateDoc, doc, getFirestore, deleteDoc, writeBatch, setDoc, where, query, orderBy, limit, startAfter, getCountFromServer } from 'firebase/firestore';
import { getStorage, ref, getMetadata, uploadBytes, getDownloadURL } from "firebase/storage";
import uuid from 'react-uuid';
import axios, * as others from 'axios';





export const getItems = async (userInfo, path) => {
    const db = getFirestore();
    try {
        const itemsRef = collection(db, "clients", userInfo.currentCompany, path);
        const itemsQuery = query(itemsRef, where("account", "==", userInfo.currentAccount));
        const itemsSnapshot = await getDocs(itemsQuery);
        const items = itemsSnapshot.docs.map((doc) => ({ ...doc.data() }));
        console.log(items)
        return items;
    } catch (error) {
        return [];
    }
};




export const deleteItems = async (ids, userInfo, path) => {
    const db = getFirestore();
    try {
        const batch = writeBatch(db);
        ids.forEach((id) => {
            const itemRef = doc(db, 'clients', userInfo.currentCompany, path, id);
            batch.delete(itemRef);
        });
        await batch.commit();
        return 'success'
    } catch (error) {
        console.error('Error deleting items:', error);
    }
};




export const createItem = async (item, userInfo) => {
    const db = getFirestore();
    try {
        const itemData = {
            ...item,
            id: item.tempid,
            account: userInfo.currentAccount,
            company: userInfo.currentCompany,
            createdBy: userInfo.email,
        };
        const itemRef = doc(db, 'clients', itemData.company, 'timelapseCameras', itemData.id);
        await setDoc(itemRef, itemData);
        if (item?.getHistoricalImages === true) {
            console.log('getting historical images')
            fetchPhotos(itemData, userInfo)

        }
        return itemData;
    }
    catch (error) {

        console.error('Error adding item:', error);
    }
};

export const updateItem = async (item, userInfo) => {
    const db = getFirestore();
    try {
        const itemData = {
            ...item,
            account: userInfo.currentAccount,
            company: userInfo.currentCompany,
            createdBy: userInfo.email,
        };
        const itemRef = doc(db, 'clients', itemData.company, 'timelapseCameras', itemData.id);
        await updateDoc(itemRef, itemData);
        return itemData;
    }
    catch (error) {

        console.error('Error updating item:', error);
    }
}
export const fetchCameras = async (item, userInfo) => {
    const db = getFirestore();
    const headers = {
        'Content-Type': 'application/json',
    }
    try {
        const API_BASE = "https://restapi.spypoint.com/api/v3"
        const LOGIN = `${API_BASE}/user/login`
        const CAMERAS = `${API_BASE}/camera/all`
        const PHOTOS = `${API_BASE}/photo/all`
        const FILTERS = `${API_BASE}/photo/filters`
        console.log('fetching cameras')
        console.log(item.username, item.password)


        const proxyUrl = "https://mycorslake.herokuapp.com/";
        const credentialRes = await fetch(proxyUrl + LOGIN, {
            method: "POST",
            headers,
            body: JSON.stringify({
                username: item.username,
                password: item.password,
            }),
        })


        if (credentialRes.status !== 200) {
            throw new Error(
                `HTTP Error: Status ${credentialRes.status} | ${credentialRes.statusText}`
            )
        }

        const { token } = await credentialRes.json()
        headers.authorization = `Bearer ${token}`



        async function _get(apiEndpoint) {
            const data = await fetch(proxyUrl + apiEndpoint, {
                headers,
            })
            return data.json()
        }

        const cameras = await _get(CAMERAS)

        return cameras;
    }
    catch (error) {

        console.error('Error fetching cameras', error);
        return [];
    }
}

export const fetchPhotos = async (item, userInfo) => {
    const headers = { 'Content-Type': 'application/json' };
    const API_BASE = "https://restapi.spypoint.com/api/v3";
    const proxyUrl = "https://mycorslake.herokuapp.com/";
    const limit = 30;  // define a limit variable here

    const _post = async (cameraId, dateEnd = "2100-01-01T00:00:00.000Z") => {
        const data = await fetch(proxyUrl + `${API_BASE}/photo/all`, {
            method: "POST",
            headers,
            body: JSON.stringify({
                camera: [cameraId],
                dateEnd,
                favorite: false,
                hd: false,
                limit,  // use limit variable here
            }),
        })
        return data.json()
    }

    try {
        console.log('fetching cameras', item.username, item.password);
        const credentialRes = await fetch(proxyUrl + `${API_BASE}/user/login`, {
            method: "POST",
            headers,
            body: JSON.stringify({
                username: item.username,
                password: item.password,
            }),
        });

        if (!credentialRes.ok) {
            throw new Error(`HTTP Error: Status ${credentialRes.status} | ${credentialRes.statusText}`);
        }

        const { token } = await credentialRes.json();
        headers.authorization = `Bearer ${token}`;

        let images;
        let dateEnd = "2100-01-01T00:00:00.000Z";
        do {
            images = await _post(item?.camera?.id, dateEnd);
            console.log(images);
            if (images.photos.length > 0) {
                // set the dateEnd to the date of the last photo in the fetched batch
                dateEnd = images.photos[images.photos.length - 1].date;
                const proxyUrl = "https://mycorslake.herokuapp.com/";
                axios.post(proxyUrl + "https://us-central1-aquasource3.cloudfunctions.net/uploadTimeplasePhotos", {
                    userInfo: userInfo,
                    photos: images.photos,
                    itemID: item.id,
                }, {
                    headers: { "Content-Type": "application/json" }
                })
                    .then(response => {
                        console.log(response);
                    })
                    .catch(error => {
                        console.error(error);
                    });
            }
        } while (images.photos.length === limit);  // check against limit here

        return 'success';
    } catch (error) {
        console.error('Error fetching cameras', error);
        return [];
    }
}

export const fetchStream = async (item, userInfo, path, startAfterDoc, photoLimit) => {
    console.log(item.id)
    const db = getFirestore();
    try {
        let itemsRef = collection(db, "clients", userInfo.currentCompany, path);
        let itemsQuery = query(itemsRef, where('itemID', "==", item.id), orderBy("date", "desc"), limit(photoLimit));
        if (startAfterDoc) {
            itemsQuery = query(itemsRef, where('itemID', "==", item.id), orderBy("date", "desc"), startAfter(startAfterDoc), limit(photoLimit));
        }
        const itemsSnapshot = await getDocs(itemsQuery);
        const items = itemsSnapshot.docs.map((doc) => ({ ...doc.data() }));
        const lastVisible = itemsSnapshot.docs[itemsSnapshot.docs.length - 1];
        console.log(lastVisible)
        return { items, lastVisible };
    } catch (error) {
        console.error("Error fetching stream:", error);
        return { items: [], lastVisible: null };
    }
}
export const fetchCount = async (item, userInfo, path,) => {
    console.log(item.id)
    const db = getFirestore();
    try {
        let itemsRef = collection(db, "clients", userInfo.currentCompany, path);



        let itemsQuery = query(itemsRef, where('itemID', "==", item.id));
        const itemsSnapshot = await getCountFromServer(itemsQuery);
        console.log('count: ', itemsSnapshot.data().count);



        return { count: itemsSnapshot.data().count };
    } catch (error) {
        console.error("Error fetching stream:", error);
        return { count: 'error getting images count' };
    }
}






