import React from "react";
import { Autocomplete, Box, Grid, TextField, Typography } from '@mui/material';

const ValueCell = (props) => {
    const { header, headers, row, currentCell, keyDown, type, updateRow } = props;
  
    const editing = currentCell?.selectedID === `${row?.id}??${header.name}` && currentCell !== null;
    const value = Number(row?.[header.name]) || row?.[header.name];


    if (editing || type === 'bulk') {
        return (
            <TextField
                sx={{
                    ".inputRoot": {
                        fontSize: 14,
                        fontWeight: 500,
                    },
                    input: {
                        py: 1.5,
                        px: 1
                    },
                }}
                autoFocus={type === 'bulk' ? false : true}
                value={row?.[header.name]}
                onChange={(e) => {
                    const newRow = { ...row, [header.name]: e.target.value };
                    updateRow(newRow);
                }}
                onKeyDown={(e) => {
                    if (!['Enter', 'Tab'].includes(e.key)) return;
                    if (type === 'bulk') return;
                    keyDown(e, row);
                }}
            />
        );
    }
    else if (value === '') {

        return (
            <Typography
                variant="body2"
                fontWeight={500}
                fontSize={13}
                color={"red"}
            >
                --
            </Typography>

        )
    }
    else {
        return (
            <Typography
                variant="body2"
                fontWeight={500}
                fontSize={13}
                color={isNaN(value) ? "red" : "inherit"}
            >
                {value}
            </Typography>
        );
    }
};

export default ValueCell;
