
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Collapse, Divider, List, ListItem, ListItemText, Typography, Button, Slide, ListItemIcon, Box } from '@mui/material';
import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import AccountTools from './accountTools';
import { getFirestore, doc, getDoc, updateDoc, setDoc, arrayUnion } from 'firebase/firestore';
import { withStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MapIcon from '@mui/icons-material/Map';
import TaskIcon from '@mui/icons-material/Task';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ScienceIcon from '@mui/icons-material/Science';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import WaterIcon from '@mui/icons-material/Water';
import { PhotoAlbumOutlined } from '@mui/icons-material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { faCaretDown, faCaretUp, faDashboard, faFileContract, faFlask, faHandHoldingDroplet, faMap, faMapLocationDot, faTasks, faUserShield, faWater } from '@fortawesome/free-solid-svg-icons';
import { faImages } from '@fortawesome/free-regular-svg-icons';
import { Font } from '@react-pdf/renderer';


export default class SiderMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            productsCollapse: false,
            publicPortal: false,
            publicPortalAccount: false,
            reportsCollapse: false,
            accountCore: false,
            accountAccount: false,
            accountWaterQuality: false,
            companyTools: false,
            accountTools: false,
            openAccount: false,
            staffInfo: {},
            company: this.props.company,
            fieldLogs: false,
            chemicals: false,
            mobile: this.props.mobile,
            pathname: this.props.pathname,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.pathname !== prevProps.pathname) {
            this.setState({ pathname: this.props.pathname })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.company !== prevProps.company) {

            this.setState({ company: this.props.company })
        }
        if (this.props.mobile !== prevProps.mobile) {

            this.setState({ mobile: this.props.mobile })
        }
    }
    componentDidMount() {
        const { userInfo, company, pathname } = this.state;


        this.getStaff(userInfo)
    }

    getStaff = async (userInfo) => {

        const db = getFirestore()


        const docRef = doc(db, "clients", userInfo.currentCompany, 'staff', userInfo.id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {

            this.setState({ staffInfo: docSnap.data(), loading: false })
        }

    }

    closeDrawer = () => {
        const { screenWidth } = this.state;

        {
            if (screenWidth === 'xs') {
                this.props.closeDrawer()
            }
        }

    }

    render() {



        const { company, productsCollapse, publicPortal, publicPortalAccount, reportsCollapse, companyTools, accountTools, userInfo,
            screenWidth, accountCore, accountWaterQuality, accountAccount, staffInfo, fieldLogs, chemicals, mobile, pathname } = this.state;


        const companyType = company === undefined ? undefined : company.companyTypeKey !== undefined ? company.companyTypeKey : undefined;

        const LogOutButton = () => {
            let navigate = useNavigate();
            return (
                <Button style={{ textTransform: 'none' }} fullWidth variant="contained" color="inherit"
                    onClick={() => {
                        const auth = getAuth();
                        navigate('/')
                        signOut(auth).then(() => {
                            this.props.authChange('signedOut')

                        }).then(() => {

                        }).catch((error) => {
                            // An error happened.
                        });
                    }}>
                    Log Out
                </Button>
            )
        }


        const selectedColor = '#9ec1c9';
        const notSelectedColor = '#505050';
        const selectedTextColor = '#9ec1c9';

        const waterQualityPages = [
            { name: "Sampling Stations", path: "/stations" },
            { name: "Data Streams", path: "/dataStreams" },
            { name: "Data", path: "/newWQHome" },
            { name: "References", path: "/references" },
            { name: "Alerts", path: "/alerts" },

            { name: "Parameters", path: "/parameters" },
            { name: "Depth Profiles", path: "/depthProfiles" },
            { name: "Data Management", path: "/dataManagement" },
        ]



        return (
            <List sx={{
                paddingTop: 0,
                marginTop: 0,

            }}>

                {userInfo.currentAccount !== '' && (
                    <Link to="/account" style={{ textDecoration: 'none', color: 'inherit', }} onClick={() => this.closeDrawer()}>


                        <ListItem button key={"Accounts"}
                            sx={{
                                color: pathname === "/account" ? selectedColor : notSelectedColor,
                                backgroundColor: pathname === "/account" ? '#edf6f7' : 'transparent',
                                borderLeft: `6px solid ${pathname === "/account" ? selectedColor : 'rgba(0,0,0,0)'}`,

                            }}   >
                            {screenWidth !== 'md' && (<ListItemIcon>
                                <FontAwesomeIcon icon={faDashboard} size="lg" color={pathname === "/account" ? selectedTextColor : notSelectedColor} />
                            </ListItemIcon>)}
                            <Typography variant="body1" fontWeight={pathname === "/account" ? 800 : 500} fontSize={17} color={pathname === "/account" ? selectedTextColor : notSelectedColor}>
                                Dashboard
                            </Typography>

                        </ListItem>
                    </Link>

                )}


                <Link to="/" style={{ textDecoration: 'none', color: 'inherit', }} onClick={() => this.closeDrawer()}>


                    <ListItem button key={"Accounts"}
                        sx={{
                            color: pathname === "/" ? selectedColor : notSelectedColor,
                            backgroundColor: pathname === "/" ? '#edf6f7' : 'transparent',
                            borderLeft: `6px solid ${pathname === "/" ? selectedColor : 'rgba(0,0,0,0)'}`,

                        }}   >
                        {screenWidth !== 'md' && (<ListItemIcon>
                            <FontAwesomeIcon icon={faMapLocationDot} size="lg" color={pathname === "/" ? selectedTextColor : notSelectedColor} />
                        </ListItemIcon>)}
                        <Typography variant="body1" fontWeight={pathname === "/" ? 700 : 500} fontSize={17} color={pathname === "/" ? selectedTextColor : notSelectedColor}>
                            {['district', 'municipality', 'citizenScientist'].includes(companyType) ? 'Sites' : 'Sites'}
                        </Typography>




                    </ListItem>
                </Link>
                <Link to="/tasks" style={{ textDecoration: 'none', color: 'inherit', }}>
                    <ListItem button key={"tasks"}
                        sx={{
                            color: pathname === "/tasks" ? selectedColor : notSelectedColor,
                            backgroundColor: pathname === "/tasks" ? '#edf6f7' : 'transparent',
                            borderLeft: `6px solid ${pathname === "/tasks" ? selectedColor : 'rgba(0,0,0,0)'}`,

                        }}   >
                        {screenWidth !== 'md' && (<ListItemIcon>
                            <FontAwesomeIcon icon={faTasks} size="lg" color={pathname === "/tasks" ? selectedTextColor : notSelectedColor} />
                        </ListItemIcon>)}
                        <Typography variant="body1" fontWeight={pathname === "/tasks" ? 800 : 500} fontSize={17} color={pathname === "/tasks" ? selectedTextColor : notSelectedColor}>
                            Tasks
                        </Typography>

                    </ListItem>

                </Link>



                {userInfo.currentAccount !== '' && pathname !== '/' && (
                    <Link to="/fieldLogsNew" style={{ textDecoration: 'none', color: 'inherit' }} onClick={() => this.closeDrawer()} >

                        <ListItem button key={"fieldLogsNew"}
                            sx={{
                                color: pathname === "/fieldLogsNew" ? selectedColor : notSelectedColor,
                                backgroundColor: pathname === "/fieldLogsNew" ? '#edf6f7' : 'transparent',
                                borderLeft: `6px solid ${pathname === "/fieldLogsNew" ? selectedColor : 'rgba(0,0,0,0)'}`,

                            }}   >
                            {screenWidth !== 'md' && (<ListItemIcon>
                                <FontAwesomeIcon icon={faFileContract} size="lg" color={pathname === "/fieldLogsNew" ? selectedTextColor : notSelectedColor} />
                            </ListItemIcon>)}
                            <Typography variant="body1" fontWeight={pathname === "/fieldLogsNew" ? 800 : 500} fontSize={17} color={pathname === "/fieldLogsNew" ? selectedTextColor : notSelectedColor}>
                                Field Logs
                            </Typography>

                        </ListItem>


                    </Link>
                )}
                {userInfo.currentAccount !== '' && pathname !== '/' && (
                    <Link to="/images" style={{ textDecoration: 'none', color: 'inherit' }} onClick={() => this.closeDrawer()} >
                        <ListItem button key={"photos"}
                            sx={{
                                color: pathname === "/images" ? selectedColor : notSelectedColor,
                                backgroundColor: pathname === "/images" ? '#edf6f7' : 'transparent',
                                borderLeft: `6px solid ${pathname === "/images" ? selectedColor : 'rgba(0,0,0,0)'}`,

                            }}   >
                            {screenWidth !== 'md' && (<ListItemIcon>
                                <FontAwesomeIcon icon={faImages} size="lg" color={pathname === "/images" ? selectedTextColor : notSelectedColor} />
                            </ListItemIcon>)}
                            <Typography variant="body1" fontWeight={pathname === "/images" ? 800 : 500} fontSize={17} color={pathname === "/images" ? selectedTextColor : notSelectedColor}>
                                Photos
                            </Typography>

                        </ListItem>

                    </Link>
                )}





                <Link to="/chemicalApplications" style={{ textDecoration: 'none', color: 'inherit' }} onClick={() => this.closeDrawer()} >
                    <ListItem button key={"chemicalApplications"}
                        sx={{
                            color: pathname === "/chemicalApplications" ? selectedColor : notSelectedColor,
                            backgroundColor: pathname === "/chemicalApplications" ? '#edf6f7' : 'transparent',
                            borderLeft: `6px solid ${pathname === "/chemicalApplications" ? selectedColor : 'rgba(0,0,0,0)'}`,

                        }}   >
                        {screenWidth !== 'md' && (<ListItemIcon>
                            <FontAwesomeIcon icon={faFlask} size="lg" color={pathname === "/chemicalApplications" ? selectedTextColor : notSelectedColor} />
                        </ListItemIcon>)}
                        <Typography variant="body1" fontWeight={pathname === "/chemicalApplications" ? 800 : 500} fontSize={17} color={pathname === "/chemicalApplications" ? selectedTextColor : notSelectedColor}>
                            Chemicals
                        </Typography>

                    </ListItem>


                </Link>












                <ListItem
                    button
                    key={"companyTools"}
                    style={{
                        flex: 1,
                        borderLeft: `6px solid ${pathname === "/9812398y1293y8192378" ? selectedColor : 'rgba(0,0,0,0)'}`,
                    }}
                    onClick={() => { this.setState({ companyTools: !companyTools }) }}
                >
                    <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                        <Box display="flex" alignItems="center">
                            {screenWidth !== 'md' && (
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faUserShield} size="lg" color="#404050" />
                                </ListItemIcon>
                            )}
                            <Typography color="black" variant="body1" fontWeight={700} fontSize={17}>
                                {companyType === 'district' ? 'District Tools' :
                                    companyType === 'municipality' ? 'City Tools' : 'Account Tools'}
                            </Typography>
                        </Box>
                        {companyTools ?
                            <FontAwesomeIcon icon={faCaretUp} color='#404050' size="large" />
                            : <FontAwesomeIcon icon={faCaretDown} color='#404050' size="large" />
                        }
                    </Box>
                </ListItem>







                <Collapse in={companyTools} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <Link to="/companyInfo" style={{ textDecoration: 'none', color: 'inherit' }} onClick={() => this.closeDrawer()} >
                            <ListItem button key={"companyInfo"}
                                sx={{
                                    pl: 4,
                                    color: pathname === "/companyInfo" ? selectedColor : notSelectedColor,
                                    backgroundColor: pathname === "/companyInfo" ? '#edf6f7' : 'transparent',
                                    borderLeft: `6px solid ${pathname === "/companyInfo" ? selectedColor : 'rgba(0,0,0,0)'}`,

                                }}   >

                                <Typography variant="body1" fontWeight={pathname === "/companyInfo" ? 800 : 500} fontSize={17} color={pathname === "/companyInfo" ? selectedTextColor : notSelectedColor}>
                                    {companyType === 'district' ? 'District Info' :
                                        companyType === 'municipality' ? 'City Info' : 'Account Info'}
                                </Typography>
                            </ListItem>


                        </Link>
                        <Link to="/accountStaffHome" style={{ textDecoration: 'none', color: 'inherit' }} onClick={() => this.closeDrawer()} >
                            <ListItem button key={"accountStaffHome"}
                                sx={{
                                    pl: 4,
                                    color: pathname === "/accountStaffHome" ? selectedColor : notSelectedColor,
                                    backgroundColor: pathname === "/accountStaffHome" ? '#edf6f7' : 'transparent',
                                    borderLeft: `6px solid ${pathname === "/accountStaffHome" ? selectedColor : 'rgba(0,0,0,0)'}`,

                                }}   >

                                <Typography variant="body1" fontWeight={pathname === "/accountStaffHome" ? 800 : 500} fontSize={17} color={pathname === "/accountStaffHome" ? selectedTextColor : notSelectedColor}>
                                    {companyType === 'district' ? 'District Staff' :
                                        companyType === 'municipality' ? 'City Staff' : 'Account Staff'}
                                </Typography>
                            </ListItem>

                        </Link>

                        <Link to="/psHome" style={{ textDecoration: 'none', color: 'inherit' }} onClick={() => this.closeDrawer()} >

                            <ListItem button key={"psHome"}
                                sx={{
                                    pl: 4,
                                    color: pathname === "/psHome" ? selectedColor : notSelectedColor,
                                    backgroundColor: pathname === "/psHome" ? '#edf6f7' : 'transparent',
                                    borderLeft: `6px solid ${pathname === "/psHome" ? selectedColor : 'rgba(0,0,0,0)'}`,

                                }}   >

                                <Typography variant="body1" fontWeight={pathname === "/psHome" ? 800 : 500} fontSize={17} color={pathname === "/psHome" ? selectedTextColor : notSelectedColor}>
                                    Products & Services
                                </Typography>
                            </ListItem>


                        </Link>
                        <Link to="/productStocks" style={{ textDecoration: 'none', color: 'inherit' }} onClick={() => this.closeDrawer()} >
                            <ListItem button key={"productStocks"}
                                sx={{
                                    pl: 4,
                                    color: pathname === "/productStocks" ? selectedColor : notSelectedColor,
                                    backgroundColor: pathname === "/productStocks" ? '#edf6f7' : 'transparent',
                                    borderLeft: `6px solid ${pathname === "/productStocks" ? selectedColor : 'rgba(0,0,0,0)'}`,

                                }}   >

                                <Typography variant="body1" fontWeight={pathname === "/productStocks" ? 800 : 500} fontSize={17} color={pathname === "/productStocks" ? selectedTextColor : notSelectedColor}>
                                    Inventory
                                </Typography>
                            </ListItem>

                        </Link>
                        <Link to="/codes" style={{ textDecoration: 'none', color: 'inherit' }} onClick={() => this.closeDrawer()}>
                            <ListItem button key={"codes"}
                                sx={{
                                    pl: 4,
                                    color: pathname === "/codes" ? selectedColor : notSelectedColor,
                                    backgroundColor: pathname === "/codes" ? '#edf6f7' : 'transparent',
                                    borderLeft: `6px solid ${pathname === "/codes" ? selectedColor : 'rgba(0,0,0,0)'}`,

                                }}   >

                                <Typography variant="body1" fontWeight={pathname === "/codes" ? 800 : 500} fontSize={17} color={pathname === "/codes" ? selectedTextColor : notSelectedColor}>
                                    Codes
                                </Typography>
                            </ListItem>

                        </Link>

                        <Link to="/publicSettings" style={{ textDecoration: 'none', color: 'inherit' }} onClick={() => this.closeDrawer()} >
                            <ListItem button key={"publicSettings"}
                                sx={{
                                    pl: 4,
                                    color: pathname === "/publicSettings" ? selectedColor : notSelectedColor,
                                    backgroundColor: pathname === "/publicSettings" ? '#edf6f7' : 'transparent',
                                    borderLeft: `6px solid ${pathname === "/publicSettings" ? selectedColor : 'rgba(0,0,0,0)'}`,

                                }}   >

                                <Typography variant="body1" fontWeight={pathname === "/publicSettings" ? 800 : 500} fontSize={17} color={pathname === "/publicSettings" ? selectedTextColor : notSelectedColor}>
                                    Public Portal
                                </Typography>
                            </ListItem>


                        </Link>


                        <ListItem
                            button
                            sx={{ pl: 4 }}
                            key={"reportsCollapse"}
                            style={{
                                flex: 1,
                            }}
                            onClick={() => { this.setState({ reportsCollapse: !reportsCollapse }) }}
                        >
                            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                                <Box display="flex" alignItems="center">

                                    <Typography color="black" variant="body1" fontWeight={700} fontSize={17}>
                                        Reports
                                    </Typography>
                                </Box>
                                {reportsCollapse ?
                                    <FontAwesomeIcon icon={faCaretUp} color='#404050' size="large" />
                                    : <FontAwesomeIcon icon={faCaretDown} color='#404050' size="large" />
                                }
                            </Box>
                        </ListItem>


                        <Collapse in={reportsCollapse} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>

                                <Link to="/PSReports" style={{ textDecoration: 'none', color: 'inherit' }} onClick={() => this.closeDrawer()} >
                                    <ListItem button key={"PSReports"}
                                        sx={{
                                            pl: 8,
                                            color: pathname === "/PSReports" ? selectedColor : notSelectedColor,
                                            backgroundColor: pathname === "/PSReports" ? '#edf6f7' : 'transparent',
                                            borderLeft: `6px solid ${pathname === "/PSReports" ? selectedColor : 'rgba(0,0,0,0)'}`,

                                        }}   >

                                        <Typography variant="body1" fontWeight={pathname === "/PSReports" ? 800 : 500} fontSize={17} color={pathname === "/PSReports" ? selectedTextColor : notSelectedColor}>
                                            Products & Services
                                        </Typography>
                                    </ListItem>

                                </Link>

                                <Link to="/fieldLogsNewCompany" style={{ textDecoration: 'none', color: 'inherit' }} onClick={() => this.closeDrawer()} >

                                    <ListItem button key={"fieldLogsNewCompany"}
                                        sx={{
                                            pl: 8,
                                            color: pathname === "/fieldLogsNewCompany" ? selectedColor : notSelectedColor,
                                            backgroundColor: pathname === "/fieldLogsNewCompany" ? '#edf6f7' : 'transparent',
                                            borderLeft: `6px solid ${pathname === "/fieldLogsNewCompany" ? selectedColor : 'rgba(0,0,0,0)'}`,

                                        }}   >

                                        <Typography variant="body1" fontWeight={pathname === "/fieldLogsNewCompany" ? 800 : 500} fontSize={17} color={pathname === "/fieldLogsNewCompany" ? selectedTextColor : notSelectedColor}>
                                            Field Logs
                                        </Typography>
                                    </ListItem>


                                </Link>

                                <Link to="/chemicalApplicationsCompany" style={{ textDecoration: 'none', color: 'inherit' }} onClick={() => this.closeDrawer()} >
                                    <ListItem button key={"chemicalApplicationsCompany"}
                                        sx={{
                                            pl: 8,
                                            color: pathname === "/chemicalApplicationsCompany" ? selectedColor : notSelectedColor,
                                            backgroundColor: pathname === "/chemicalApplicationsCompany" ? '#edf6f7' : 'transparent',
                                            borderLeft: `6px solid ${pathname === "/chemicalApplicationsCompany" ? selectedColor : 'rgba(0,0,0,0)'}`,

                                        }}   >

                                        <Typography variant="body1" fontWeight={pathname === "/chemicalApplicationsCompany" ? 800 : 500} fontSize={17} color={pathname === "/chemicalApplicationsCompany" ? selectedTextColor : notSelectedColor}>
                                            Chemical Applications
                                        </Typography>
                                    </ListItem>

                                </Link>


                            </List>
                        </Collapse>




                    </List>

                </Collapse>



                {userInfo.currentAccount !== '' && pathname !== '/' && (

                    <ListItem
                        button
                        key={"accountTools"}
                        style={{
                            flex: 1,
                            borderLeft: `6px solid ${pathname === "/9812398y1293y8192378" ? selectedColor : 'rgba(0,0,0,0)'}`,
                        }}
                        onClick={() => { this.setState({ accountTools: !accountTools }) }}
                    >
                        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                            <Box display="flex" alignItems="center">
                                {screenWidth !== 'md' && (<ListItemIcon>
                                    <FontAwesomeIcon icon={faWater} color='#404050' size="large" />
                                </ListItemIcon>)}
                                <Typography color="black" variant="body1" fontWeight={700} fontSize={17}>
                                    {companyType === 'district' ? 'Lake Tools' :
                                        companyType === 'municipality' ? 'Lake Tools' :
                                            companyType === 'irrigationDistrict' ? 'Canal Tools' :
                                                'Lake Tools'}
                                </Typography>
                            </Box>
                            {accountTools ?
                                <FontAwesomeIcon icon={faCaretUp} color='#404050' size="large" />
                                : <FontAwesomeIcon icon={faCaretDown} color='#404050' size="large" />
                            }
                        </Box>
                    </ListItem>


                )}


                {userInfo.currentAccount !== '' && (
                    <Collapse in={accountTools} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>

                            <Link to="/personalDashboard" style={{ textDecoration: 'none', color: 'inherit' }} onClick={() => this.closeDrawer()} >
                                <ListItem button key={"personalDashboard"}
                                    sx={{
                                        pl: 4,
                                        color: pathname === "/personalDashboard" ? selectedColor : notSelectedColor,
                                        backgroundColor: pathname === "/personalDashboard" ? '#edf6f7' : 'transparent',
                                        borderLeft: `6px solid ${pathname === "/personalDashboard" ? selectedColor : 'rgba(0,0,0,0)'}`,

                                    }}   >

                                    <Typography variant="body1" fontWeight={pathname === "/personalDashboard" ? 800 : 500} fontSize={17} color={pathname === "/personalDashboard" ? selectedTextColor : notSelectedColor}>
                                        {companyType === 'district' ? 'Personal Dashboard' :
                                            companyType === 'municipality' ? 'Personal Dashboard' : 'Personal Dashboard'}
                                    </Typography>
                                </ListItem>

                            </Link>

                            <Link to="/accountDirectory" style={{ textDecoration: 'none', color: 'inherit' }} onClick={() => this.closeDrawer()} >
                                <ListItem button key={"accountDirectory"}
                                    sx={{
                                        pl: 4,
                                        color: pathname === "/accountDirectory" ? selectedColor : notSelectedColor,
                                        backgroundColor: pathname === "/accountDirectory" ? '#edf6f7' : 'transparent',
                                        borderLeft: `6px solid ${pathname === "/accountDirectory" ? selectedColor : 'rgba(0,0,0,0)'}`,

                                    }}   >

                                    <Typography variant="body1" fontWeight={pathname === "/accountDirectory" ? 800 : 500} fontSize={17} color={pathname === "/accountDirectory" ? selectedTextColor : notSelectedColor}>
                                        Directory
                                    </Typography>
                                </ListItem>

                            </Link>




                            <Link to="/siteInfoHome/home" style={{ textDecoration: 'none', color: 'inherit' }} onClick={() => this.closeDrawer()} >
                                <ListItem button key={"home"}
                                    sx={{
                                        pl: 4,
                                        color: pathname === "/siteInfoHome/home" ? selectedColor : notSelectedColor,
                                        backgroundColor: pathname === "/siteInfoHome/home" ? '#edf6f7' : 'transparent',
                                        borderLeft: `6px solid ${pathname === "/siteInfoHome/home" ? selectedColor : 'rgba(0,0,0,0)'}`,

                                    }}   >

                                    <Typography variant="body1" fontWeight={pathname === "/siteInfoHome/home" ? 800 : 500} fontSize={17} color={pathname === "/siteInfoHome/home" ? selectedTextColor : notSelectedColor}>
                                        Site Info
                                    </Typography>
                                </ListItem>

                            </Link>



                            <ListItem
                                button
                                sx={{ pl: 4 }}
                                key={"accountCore"}
                                style={{
                                    flex: 1,
                                    borderLeft: `6px solid ${pathname === "/12342121" ? selectedColor : 'rgba(0,0,0,0)'}`,
                                }}
                                onClick={() => { this.setState({ accountCore: !accountCore }) }}
                            >
                                <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                                    <Box display="flex" alignItems="center">

                                        <Typography color="black" variant="body1" fontWeight={700} fontSize={17}>
                                            Core Tools
                                        </Typography>
                                    </Box>
                                    {accountCore ?
                                        <FontAwesomeIcon icon={faCaretUp} color='#404050' size="large" />
                                        : <FontAwesomeIcon icon={faCaretDown} color='#404050' size="large" />
                                    }
                                </Box>
                            </ListItem>


                            <Collapse in={accountCore} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {/*   {["EuEZ93ByFZYs8bSUXoYsTt1zu0m2", 'eTevYsI9mQMxhtntd95HmRGasNx2', 'PQWFmTLCP9XqafR9ogNI3Fg1sH43'].includes(userInfo.id) && (<Link to="/accountFieldLogs" style={{ textDecoration: 'none', color: 'inherit' }} onClick={() => this.closeDrawer()} >
                                        <ListItem button sx={{ pl: 8 }} >

                                            <ListItemText primary="Field Logs" />
                                        </ListItem>
                                    </Link>)} */}


                                    <Link to="/equipmentList" style={{ textDecoration: 'none', color: 'inherit' }} onClick={() => this.closeDrawer()}>
                                        <ListItem button key={"equipmentList"}
                                            sx={{
                                                pl: 8,
                                                color: pathname === "/equipmentList" ? selectedColor : notSelectedColor,
                                                backgroundColor: pathname === "/equipmentList" ? '#edf6f7' : 'transparent',
                                                borderLeft: `6px solid ${pathname === "/equipmentList" ? selectedColor : 'rgba(0,0,0,0)'}`,

                                            }}   >

                                            <Typography variant="body1" fontWeight={pathname === "/equipmentList" ? 800 : 500} fontSize={17} color={pathname === "/equipmentList" ? selectedTextColor : notSelectedColor}>
                                                Equipment List
                                            </Typography>
                                        </ListItem>


                                    </Link>
                                    <Link to="/newDocumentsHome" style={{ textDecoration: 'none', color: 'inherit' }} onClick={() => this.closeDrawer()}>
                                        <ListItem button key={"documents"}
                                            sx={{
                                                pl: 8,
                                                color: pathname === "/newDocumentsHome" ? selectedColor : notSelectedColor,
                                                backgroundColor: pathname === "/newDocumentsHome" ? '#edf6f7' : 'transparent',
                                                borderLeft: `6px solid ${pathname === "/newDocumentsHome" ? selectedColor : 'rgba(0,0,0,0)'}`,

                                            }}   >

                                            <Typography variant="body1" fontWeight={pathname === "/newDocumentsHome" ? 800 : 500} fontSize={17} color={pathname === "/newDocumentsHome" ? selectedTextColor : notSelectedColor}>
                                                Documents
                                            </Typography>
                                        </ListItem>

                                    </Link>




                                </List>
                            </Collapse>


                            {/*   {['38cfab-0eb0-0c3e-f466-71ad3c4865', 'e467d47-003-6463-6e6b-ee08215ca686'].includes(userInfo.currentCompany) && (<ListItem button key={"publicPortalAccount"} onClick={() => { this.setState({ publicPortalAccount: !publicPortalAccount }) }} sx={{ pl: 4 }}>
                                <ListItemText primary={'Public Portal'} />
                                {publicPortalAccount ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>)}
                            {['38cfab-0eb0-0c3e-f466-71ad3c4865', 'e467d47-003-6463-6e6b-ee08215ca686'].includes(userInfo.currentCompany) && (<Collapse in={publicPortalAccount} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <Link to="/publicDashboard" style={{ textDecoration: 'none', color: 'inherit' }} onClick={() => this.closeDrawer()} >
                                        <ListItem button sx={{ pl: 8 }} >

                                            <ListItemText primary="Public Dashboard" />
                                        </ListItem>
                                    </Link>



                                </List>
                            </Collapse>)} */}





                        </List>

                    </Collapse>

                )}

                {userInfo.currentAccount !== '' && pathname !== '/' && (

                    <ListItem
                        button
                        key={"waterQuality"}
                        style={{
                            flex: 1,
                            borderLeft: `6px solid ${pathname === "/12342121" ? selectedColor : 'rgba(0,0,0,0)'}`,
                        }}
                        onClick={() => { this.setState({ accountWaterQuality: !accountWaterQuality }) }}
                    >
                        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                            <Box display="flex" alignItems="center">
                                {screenWidth !== 'md' && (<ListItemIcon>
                                    <FontAwesomeIcon icon={faHandHoldingDroplet} color='#404050' size="large" />
                                </ListItemIcon>)}
                                <Typography color="black" variant="body1" fontWeight={700} fontSize={17}>
                                    Water Quality
                                </Typography>
                            </Box>
                            {accountTools ?
                                <FontAwesomeIcon icon={faCaretUp} color='#404050' size="large" />
                                : <FontAwesomeIcon icon={faCaretDown} color='#404050' size="large" />
                            }
                        </Box>
                    </ListItem>


                )}


                {userInfo.currentAccount !== '' && (




                    <Collapse in={accountWaterQuality} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>


                            {waterQualityPages.map((page, index) => {
                                return (
                                    <Link key={`key-${index}`} to={page.path} style={{ textDecoration: 'none', color: 'inherit' }} onClick={() => this.closeDrawer()} >
                                        <ListItem button key={page.path}
                                            sx={{
                                                pl: 4,
                                                color: pathname === page.path ? selectedColor : notSelectedColor,
                                                backgroundColor: pathname === page.path ? '#edf6f7' : 'transparent',
                                                borderLeft: `6px solid ${pathname === page.path ? selectedColor : 'rgba(0,0,0,0)'}`,

                                            }}   >

                                            <Typography variant="body1" fontWeight={pathname === page.path ? 800 : 500} fontSize={17} color={pathname === page.path ? selectedTextColor : notSelectedColor}>
                                                {page.name}
                                            </Typography>
                                        </ListItem>
                                    </Link>
                                )
                            }
                            )
                            }








                        </List>
                    </Collapse>

                )}




                <Divider />

                {screenWidth === 'xs' && (
                    <Link to="/userProfile" style={{ textDecoration: 'none', color: 'inherit' }} >
                        <ListItem button key={"User"}>
                            <ListItemText primary={'User'} />
                        </ListItem>
                    </Link>
                )}

                {screenWidth === 'xs' && (<Link to="/changeAccount" style={{ textDecoration: 'none', color: 'inherit' }} onClick={() => this.closeDrawer()} >
                    <ListItem button key={"Change Company"}>
                        <ListItemText sx={{
                            root: {
                                fontSize: 8
                            }
                        }} primary={'Change Account'} />
                    </ListItem>
                </Link>
                )}

                {["EuEZ93ByFZYs8bSUXoYsTt1zu0m2", 'eTevYsI9mQMxhtntd95HmRGasNx2', 'z4y8XVrxYGT7rO4R01Y8tQ2F5z83', 't9bOfGDqwDaQTYV6i008opscpjz2'].includes(userInfo.id) && screenWidth === 'xs' && (
                    <Link to="/createSite" style={{ textDecoration: 'none', color: 'inherit' }} onClick={() => this.closeDrawer()} >
                        <ListItem button key={"Create Client"}>
                            <ListItemText primary={'Create Account'} />
                        </ListItem>
                    </Link>
                )}




                {screenWidth === 'xs' && (<Divider />)}

                {screenWidth === 'xs' && (<ListItem>
                    <LogOutButton />
                </ListItem>)}

            </List>
        );
    }
}


