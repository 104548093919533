import React, { useEffect } from "react";
import { Input, Typography } from "@mui/material";
import moment from 'moment-timezone';

const StringNone = ({
    currentStream,
    setCurrentStream,
    headerKey,
    options
}) => {
    const [value, setValue] = React.useState(currentStream?.[headerKey]);  // Renamed from 'age' to 'value'

    useEffect(() => {
        setValue(currentStream?.[headerKey]);
    }, [currentStream]);


    return (
        <Typography variant="body2" sx={{ fontWeight: 500, fontSize: 14, color: 'black' }}>
            {value}
        </Typography>
    );
};

export default StringNone;
