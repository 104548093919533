'use strict';
import { Button, Divider, Typography, TextField, Box, OutlinedInput, IconButton, TableSortLabel, InputAdornment } from '@mui/material';
import { Col, Row, Modal, Skeleton } from 'antd';
import React, { forwardRef, memo, useEffect, useImperativeHandle, useMemo, useRef, useState, useCallback } from 'react';
import { fullMobile } from '../../util';

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import moment from 'moment';
import MUIDataTable from "mui-datatables";
import DatePicker from "react-datepicker";
import uuid from 'react-uuid';
import { queryParameters, querySites, queryStations, queryLogData } from '../../../../firebase/config';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, } from 'firebase/firestore';
import './tableStyle.css';
import "react-datepicker/dist/react-datepicker.css";

import { DeleteForever, RemoveRedEye, VisibilityOff } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';



const getMuiTheme = () =>
    createTheme({
        components: {
            MUIDataTable: {
                styleOverrides: {
                    root: {
                        padding: '8px',
                        boxShadow: 'none'
                    }
                }
            },
            MuiTableRow: {
                styleOverrides: {
                    root: {
                        zIndex: 100,
                        cursor: 'pointer',
                        fontSize: '10px',
                        '&:nth-child(even)': {
                            backgroundColor: '#f4f4f499'
                        },
                        "&:hover": {
                            backgroundColor: "#9ec1c933"
                        }


                    },


                }
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        fontSize: '12px',
                        padding: '2px'
                    }
                }
            }

        }
    });




const LogMaterialsTable = (props) => {
    const { userInfo, screenWidth, openSnack, company, account, updateLog, log, status, } = props;

    const [data, setData] = useState([]);
    const [deleting, setDeleting] = useState(false);
    const [loading, setLoading] = useState(false);
    const inputRef = useRef();

    useEffect(() => {
        if (log.materials !== undefined) {
            setData(log.materials || []);
            localStorage.setItem('logMaterials', JSON.stringify(log.materials || []))
        }
        else {
            setData([]);
            localStorage.setItem('logMaterials', JSON.stringify([]))
        }
    }, [log])








    const options = {
        filter: false,
        searchable: false,
        search: false,
        searchable: false,
        viewColumns: false,
        download: false,
        print: false,
        draggable: true,
        rowsPerPage: 50,
        rowsPerPageOptions: [50, 100, 200, 500, 1000],
        selectableRows: screenWidth === 'xs' ? false : status === 'built' ? false : true,
        filterType: 'dropdown',
        responsive: screenWidth === 'md' ? 'stacked' : 'standard',

        onRowsDelete: (rows, b) => {
            console.log(rows)
            const newData = [...data];
            rows.data.forEach((row) => {
                console.log(row.dataIndex)
                newData.splice(row.dataIndex, 1)


            })
            setData(newData);
            setDeleting(true)
            updateLog('materials', [...newData])
            localStorage.setItem('logMaterials', JSON.stringify(newData))
            setTimeout(() => {
                setDeleting(false)
            }
                , 500)






        }
    };

    const columns = [

        {
            name: 'key',
            label: "Edit",

            options: {
                //hide column
                display: false,
                filter: false,
                sort: false,
                empty: true,

            }
        },
        {
            name: 'name',
            label: "Material Name",

            options: {
                width: 150,
                //hide column
                customBodyRender: (value, tableMeta, updateValue) => {
                    return status === 'built' ? (
                        <Typography variant="body2">{value}</Typography>
                    )
                        :
                        (<OutlinedInput
                            style={{ backgroundColor: 'white' }}
                            sx={{
                                backgroundColor: 'white'
                            }}
                            size="small"
                            type="text"
                            defaultValue={value}
                            onBlur={(e) => {
                                const newData = [...data];
                                const index = newData.findIndex((item) => item.key === tableMeta.rowData[0]);
                                newData[index].name = e.target.value;
                                setData(newData);
                                updateLog('materials', newData)
                                localStorage.setItem('logMaterials', JSON.stringify(newData))
                            }}
                        />
                        )
                }

            }
        },
        {
            name: 'location',
            label: "Location of Purchase",

            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return status === 'built' ? (
                        <Typography variant="body2">{value}</Typography>
                    ) :
                        (<OutlinedInput
                            style={{ backgroundColor: 'white' }}
                            sx={{
                                backgroundColor: 'white'
                            }}
                            size="small"
                            type="text"
                            defaultValue={value}
                            onBlur={(e) => {
                                const newData = [...data];
                                const index = newData.findIndex((item) => item.key === tableMeta.rowData[0]);
                                newData[index].location = e.target.value;
                                setData(newData);
                                updateLog('materials', newData)
                                localStorage.setItem('logMaterials', JSON.stringify(newData))
                            }}
                        />
                        )

                },

            }
        },
        {
            name: 'price',
            label: "Price",

            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return status === 'built' ? (
                        <Typography variant="body2">${value || 0}</Typography>
                    ) :
                        (<OutlinedInput
                            style={{ backgroundColor: 'white' }}
                            sx={{
                                backgroundColor: 'white'
                            }}
                            size="small"
                            type="number"
                            defaultValue={value}
                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            onBlur={(e) => {
                                const newData = [...data];
                                const index = newData.findIndex((item) => item.key === tableMeta.rowData[0]);
                                newData[index].price = e.target.value;
                                setData(newData);
                                updateLog('materials', newData)
                                localStorage.setItem('logMaterials', JSON.stringify(newData))
                            }}
                        />
                        )

                },
                customHeadLabelRender: (columnMeta, handleToggleColumn) => {
                    // Calculate the total hours
                    const totalPrice = data.reduce((sum, row) => sum + parseFloat(row.price || 0), 0);

                    return (
                        <TableSortLabel
                            {...columnMeta}
                            onClick={handleToggleColumn}
                        >
                            {columnMeta.label} Total: ${Number(totalPrice).toFixed(2)}
                        </TableSortLabel>
                    );
                },
            }
        },








    ];


    const AddRowButton = memo(() => {
        const [date, setDate] = useState(new Date());

        const handleAddRow = () => {
            const newDate = moment(date).format('x');
            const newData = [...data, { key: uuid(), name: '', location: null, price: null }];
            console.log(newData)
            setData(newData);
            updateLog('materials', newData)
            localStorage.setItem('logMaterials', JSON.stringify(newData))

        }

        return (
            <Button size="small"
                variant="contained" color="primary"
                style={{ textTransform: 'none', fontSize: 14, backgroundColor: '#404050' }}
                onClick={handleAddRow} >+ Add Material</Button >
        )
    })







    return (
        <Row gutter={[12, 12]} align="middle" >

            <Col {...fullMobile(12, 12)} style={{ paddingTop: 30 }}>
                <Typography display="inline" variant='h5' fontWeight={500} fontSize={screenWidth === 'xs' ? 16 : 22}>Materials</Typography>
                {status === 'creating' && (<IconButton display="inline" size="small" onClick={() => {
                    updateLog('hideMaterials', !log?.hideMaterials)
                }}>
                    {log?.hideMaterials ? <VisibilityOff /> : <RemoveRedEye />}
                </IconButton>)}


            </Col>
            <Col {...fullMobile(12, 12)} style={{ paddingTop: 30, textAlign: 'right' }}>
                {status !== 'built' && (<AddRowButton />)}


            </Col>
            {loading && (
                <Col {...fullMobile(24, 24)} style={{ paddingTop: 10, }}>
                    <Skeleton width={400} height={100} sx={{ animationDuration: "0.3s" }} />
                </Col>
            )}

            {!log.hideMaterials && data.length > 0 && deleting === false && (

                <Col id="table" {...fullMobile(24, 24)} style={{ paddingTop: 10, position: 'relative', zIndex: 10 }}>
                    <ThemeProvider theme={getMuiTheme()}>
                        <MUIDataTable data={data} columns={screenWidth === 'xs' ? [...columns, {
                            name: 'key',
                            label: "Delete",
                            options: {
                                filter: true,
                                sort: false,
                                empty: true,

                            }
                        },] : columns} options={options} />
                    </ThemeProvider>

                </Col>

            )}

            {deleting && (
                <Col {...fullMobile(24, 24)} style={{ paddingTop: 10, }}>
                    <FontAwesomeIcon icon={faSpinner} spin size="2x" /> Deleting...
                </Col>
            )}

            <Col {...fullMobile(24, 24)}>
                <Divider style={{
                    borderColor: 'rgb(148, 186, 195)',
                    borderBottomWidth: 'medium',

                }} />
            </Col>





        </Row>
    )



}

export default LogMaterialsTable