import { Autocomplete, TextField, Typography } from "@mui/material";
import React from "react";
import uuid from "react-uuid";

const AlertRowEmailDistribution = ({ index, alert, updateAlert, staff }) => {
    const [emailDistribution, setEmailDistribution] = React.useState(alert.emailDistribution || []);
    const [customDistribution, setCustomDistribution] = React.useState(alert.customDistribution || []);
    const [inputValue, setInputValue] = React.useState("");

    const handleDeleteTag = (idToDelete) => {
        const newEmailDistribution = emailDistribution.filter((id) => id !== idToDelete);
        setEmailDistribution(newEmailDistribution);
        updateAlert(alert.id, "emailDistribution", newEmailDistribution);
        updateAlert(alert.id, "fullEmailDistribution", newEmailDistribution);
    };

    const handleDeleteCustomEmail = (idToDelete) => {
        const newCustomDistribution = customDistribution.filter((person) => person.id !== idToDelete);
        setCustomDistribution(newCustomDistribution);
        updateAlert(alert.id, "customDistribution", newCustomDistribution);
    };

    const options = [
        ...staff.sort((a, b) => -b?.firstName.localeCompare(a?.firstName)),
        ...customDistribution,
        { id: "select-all", firstName: "Select All" },
    ];

    const getOptionLabel = (option) =>
        option.id === "select-all"
            ? "Select All"
            : `${option.firstName} ${option.lastName} (${option.email})`;

    const renderOption = (props, option) => {
        if (option.id === "select-all") {
            return (
                <li {...props} style={{ fontSize: 14, fontWeight: 'bold', textAlign: 'center' }}>
                    {getOptionLabel(option)}
                </li>
            );
        }
        else if (option.optionType === 'custom') {
            return (
                <li {...props} style={{ fontSize: 14, textAlign: 'center' }}>
                    <span style={{ marginRight: 8 }}>
                        {option.email}

                    </span>
                    <span
                        style={{ cursor: "pointer", fontSize: 10, marginLeft: 4 }}
                        onClick={() => handleDeleteCustomEmail(option.id)}
                    >
                        X
                    </span>
                </li>
            );
        }
        return (
            <li {...props} style={{ fontSize: 14 }}>
                {getOptionLabel(option)}
            </li>
        );
    };

    const handleOnChange = (event, newValue) => {
        if (newValue && newValue[newValue.length - 1]?.id === "select-all") {
            const allIds = [...staff, ...customDistribution].map((person) => person.id);
            setEmailDistribution(allIds);
            updateAlert(alert.id, "emailDistribution", allIds);
            updateAlert(alert.id, "fullEmailDistribution", [...staff, ...customDistribution])

        } else {
            const newEmailDistribution = newValue?.map((person) => person.id || person) || [];
            setEmailDistribution(newEmailDistribution);
            updateAlert(alert.id, "emailDistribution", newEmailDistribution);
            updateAlert(alert.id, "fullEmailDistribution", newValue)
        }
    };

    const handleRenderTags = (value, getTagProps) =>
        value.map((option, index) => (
            <div
                {...getTagProps({ index })}
                style={{
                    backgroundColor: "#e1e1eef5",
                    color: "black",
                    fontSize: 12,
                    fontWeight: 600,
                    padding: 4,
                    paddingLeft: 8,
                    paddingRight: 8,
                    borderRadius: 8,
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <span style={{ marginRight: 8 }}>
                    <Typography fontSize={12} fontWeight={600} variant="body1">
                        {getOptionLabel(option)}
                    </Typography>
                </span>
                <span
                    style={{ cursor: "pointer", fontSize: 10, marginLeft: 4 }}
                    onClick={() => handleDeleteTag(option.id)}
                >
                    X
                </span>
            </div>
        ));

    const handleInputValueChange = (event, newInputValue) => {
        setInputValue(newInputValue);
        if (newInputValue.includes(';')) {
            const newEmail = newInputValue.replace(';', '').trim();
            if (newEmail && !emailDistribution.includes(newEmail)) {
                const newItem = { id: uuid(), email: newEmail, firstName: '', lastName: '', optionType: 'custom' }
                setEmailDistribution([...emailDistribution, newItem.id]);
                setCustomDistribution([...customDistribution, newItem]);
                setInputValue("");
                updateAlert(alert.id, "emailDistribution", [...emailDistribution, newItem.id]);
                updateAlert(alert.id, "customDistribution", [...customDistribution, newItem]);
                updateAlert(alert.id, "fullEmailDistribution", [...emailDistribution, newItem])
            }
        }
    };

    const addNewEmailOnKey = (key, inputValue) => {
        if (key === ' ' || key === 'Tab' || key === 'Enter') {
            const newEmail = inputValue.trim();
            if (newEmail && !emailDistribution.includes(newEmail)) {
                const newItem = { id: uuid(), email: newEmail, firstName: '', lastName: '', optionType: 'custom' }
                setEmailDistribution([...emailDistribution, newItem.id]);
                setCustomDistribution([...customDistribution, newItem]);
                setInputValue("");
                updateAlert(alert.id, "emailDistribution", [...emailDistribution, newItem.id]);
                updateAlert(alert.id, "customDistribution", [...customDistribution, newItem]);
                updateAlert(alert.id, "fullEmailDistribution", [...emailDistribution, newItem])
            }
        }
    };



    return (
        <Autocomplete
            style={{ backgroundColor: "white" }}
            size="small"
            multiple
            disableCloseOnSelect
            freeSolo
            options={options}
            getOptionLabel={getOptionLabel}
            value={[...staff, ...customDistribution].filter((person) => emailDistribution.includes(person.id))}
            onChange={handleOnChange}
            renderOption={renderOption}
            renderTags={handleRenderTags}
            inputValue={inputValue}
            onInputChange={handleInputValueChange}
            renderInput={(params) => <TextField
                {...params}
                placeholder="Distribution"
                onKeyDown={(e) => {
                    if (e.key === ' ' || e.key === 'Tab' || e.key === 'Enter') {
                        e.preventDefault();
                        addNewEmailOnKey(e.key, inputValue);
                    }
                }}
            />}
        />
    );

};

export default AlertRowEmailDistribution;