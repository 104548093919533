import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SearchIcon from '@mui/icons-material/Search';
import { Divider, Fab, IconButton, Input, InputAdornment, Paper, Typography } from '@mui/material';
import { Col, Popconfirm, Row } from 'antd';
import { collection, doc, getDocs, getFirestore, query, setDoc, updateDoc, where } from 'firebase/firestore';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import "../../extra/hoverStyle.css";

export default class EquipmentList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            data: [],
            filteredData: [],
            filters: [],
            searchText: '',
            loading: true,
            type: this.props.type,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0)
        this.queryData()
    }

    queryData = async () => {
        const { userInfo, type } = this.state;
        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'equipment'), where('account', '==', userInfo.currentAccount), where('archived', '==', false));
        const data = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            data.push({
                archived: doc.data().archived,
                description: doc.data().description || '',
                name: doc.data().name || '',
                key: doc.data().key || '',
                visible: true
            })


        });
        console.log(data);
        this.setState({ data, loading: false })

        this.filterData(data, '', [])
    }

    filterData = (data, searchText, filters) => {
        const filteredData = data.filter(d => d.name.match(new RegExp(searchText, "i")))
        this.setState({ filteredData })

    }

    render() {

        const { userInfo, account, data, filteredData, loading, searchText, filters, type } = this.state;
        const db = getFirestore();
        const CreateItemButton = () => {
            let navigate = useNavigate();

            return (
                <Fab style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="medium"
                    onClick={async () => {
                        const itemRef = doc(db, "users", userInfo.id, 'currentItems', 'currentEquipment');
                        await setDoc(itemRef, {
                            key: 'none'
                        }).then(() => {
                            navigate('/equipment')
                        })
                    }}>
                    <AddIcon />
                </Fab>
            )
        }
        const EditItem = ({ item }) => {
            let navigate = useNavigate();
            return (
                <IconButton style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="small"
                    onClick={async () => {
                        const itemRef = doc(db, "users", userInfo.id, 'currentItems', 'currentEquipment');
                        await setDoc(itemRef, {
                            key: item.key
                        }).then(() => {
                            navigate('/equipment')
                        })
                    }}>
                    <EditIcon />
                </IconButton>
            )
        }

        const DeleteItem = ({ item }) => {
            let navigate = useNavigate();
            return (
                <Popconfirm
                    title="Are you sure to delete this equipment item?"
                    onConfirm={async () => {
                        const itemRef = doc(db, "clients", userInfo.currentCompany, 'equipment', item.key);
                        await updateDoc(itemRef, {
                            archived: true
                        }).then(() => {
                            const newData = filteredData.filter((f) => f.key !== item.key)
                            this.setState({ filteredData: newData })
                        })

                    }}
                    okText="Yes"
                    cancelText="No"
                >
                    <IconButton style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="small"
                    >
                        <DeleteForeverIcon />
                    </IconButton>
                </Popconfirm>


            )
        }

        const RowItem = ({ item }) => {
            let navigate = useNavigate();
            return (
                <Col span={24} style={{ cursor: 'pointer', padding: 8 }} className="rowHover"
                >
                    <Row gutter={[8, 8]} align="middle" >

                        <Col xs={14} sm={20} onClick={async () => {
                            const itemRef = doc(db, "users", userInfo.id, 'currentItems', 'currentEquipment');
                            await setDoc(itemRef, {
                                key: item.key
                            }).then(() => {
                                navigate('/equipment')
                            }).catch((err) => {
                                console.log(err)
                            })
                        }}>
                            <Typography variant='h6'>{item.name}</Typography>  <Typography variant='bod1'>{item.description}</Typography>
                        </Col>
                        <Col xs={10} sm={4} style={{ textAlign: 'right' }}>
                            <EditItem item={item} /> <DeleteItem item={item} />
                        </Col>


                        <Col span={24}>
                            <Divider /> 
                        </Col>

                    </Row>
                </Col>
            )
        }

        return (
            <Row style={{}} align="middle">

                <Col span={23}>
                    <Row align="middle">
                        <Col xs={16} sm={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>
                            <Typography variant="h4">Your Equipment</Typography>
                        </Col>
                        <Col xs={6} sm={4} style={{ paddingTop: 30, paddingBottom: 15, textAlign: 'right' }}>
                            <CreateItemButton />
                        </Col>
                        <Col span={24}>
                            <Divider />
                        </Col>
                    </Row>
                </Col>





                <Col span={23} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 30 }}>
                    <Paper elevation={16} style={{ padding: 20, borderRadius: 12 }}>
                        <Row>
                            <Col xs={24} sm={12} style={{ paddingBottom: 8 }}>
                                <Input fullWidth
                                    autoComplete='off'
                                    value={searchText}
                                    onChange={(e) => {
                                        this.setState({ searchText: e.target.value })
                                        if (e.target.value.length > 1) {
                                            this.filterData(data, e.target.value, filters)
                                        }
                                        else {
                                            this.filterData(data, '', filters)
                                        }
                                    }}
                                    id="input-with-icon-adornment"
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    }
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                onClick={() => {
                                                    this.setState({ searchText: '' })
                                                    this.filterData(data, '', filters)
                                                }}
                                            >
                                                <HighlightOffIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </Col>
                            <Col xs={0} sm={12} style={{ textAlign: 'right' }}>
                                <Typography variant='h6'>Actions</Typography>
                            </Col>

                            <Col span={24}>
                                <Row align='middle' >
                                    {filteredData.map((d) => {
                                        return (
                                            <RowItem item={d} />

                                        )

                                    })}
                                </Row>
                            </Col>
                        </Row>
                    </Paper>
                </Col>




            </Row>
        );
    }
} 