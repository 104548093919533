import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { IconButton, List, Tooltip, ListItem } from '@mui/material';
import { faCopy } from '@fortawesome/free-regular-svg-icons';

export default function TaskDrawerMore({ convertToTemplate, duplicateTask, currentTask }) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div style={{
            display: 'inline',
        }}>
            <IconButton
                onClick={handleClick}
            >
                <Tooltip title="More" placement="bottom">
                    <FontAwesomeIcon size="sm" icon={faEllipsisH} style={{ color: 'rgba(0,0,0,0.5)' }} />
                </Tooltip>

            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}

                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <List sx={{
                    padding: 0, maxHeight: 250, overflow: 'auto'

                }}>
                    <ListItem
                        key={'createTemplate'}
                        dense
                        button
                        onClick={() => convertToTemplate()}
                        sx={{
                            borderBottom: '1px solid #e0e0e0',
                            paddingTop: 2,
                            paddingBottom: 2,

                        }}
                    >
                        <FontAwesomeIcon icon={faArrowsRotate} style={{ marginRight: 10 }} />
                        <Typography variant="body1" fontSize={14} fontWeight={400}>Convert task to template</Typography>

                    </ListItem>
                    <ListItem
                        key={'createTemplate'}
                        dense
                        button
                        onClick={() => {
                            duplicateTask(currentTask)
                            handleClose()
                        }}
                        sx={{
                            borderBottom: '1px solid #e0e0e0',
                            paddingTop: 2,
                            paddingBottom: 2,

                        }}
                    >
                        <FontAwesomeIcon icon={faCopy} style={{ marginRight: 10 }} />
                        <Typography variant="body1" fontSize={14} fontWeight={400}>Duplicate</Typography>

                    </ListItem>
                </List>




            </Popover>
        </div>
    );
}