import React from 'react';
import { Col, Row } from 'antd';
import { Breadcrumbs, Button, OutlinedInput, Paper, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { setDefaultOptions, loadModules, } from 'esri-loader';
import Search from "@arcgis/core/widgets/Search";

export default class UpdateMap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            map: this.props.map,
            view: this.props.view,
        };
    }

    componentDidUpdate(prevProps) {


    }
    componentDidMount() {
        const { account, map, view } = this.state;
        view.on("pointer-up", (evt) => {

            this.props.updateMap({ center: { lat: view.center.latitude, lon: view.center.longitude }, zoom: view.zoom })
        });
        view.on("mouse-wheel", (evt) => {
            this.props.updateMap({ center: { lat: view.center.latitude, lon: view.center.longitude }, zoom: view.zoom })
        });

    }






    render() {

        const { } = this.state;

        return null;
    }
} 