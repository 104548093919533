import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { Typography } from '@mui/material';

export default function WidgetImage(props) {
  const { attributes } = props;
  const [loading, setLoading] = useState(true);
  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    console.log(attributes.image)
    if (attributes.image) {
     
      fetchImageDimensions(attributes.image);
    } else {
      console.log(attributes.image)
      setLoading('noData');
    }
  }, []);

  async function fetchImageDimensions(src) {
    const img = new Image();
    img.onload = () => {
      setImageDimensions({ width: img.width, height: img.height });
      setLoading(false);
    };
    img.src = src;
  }

  if (loading === true) {
    return (
      <div>
        <Typography>Loading Data</Typography>
      </div>
    );
  } else {
    const aspectRatio = imageDimensions.width / imageDimensions.height;
    return (
      <Row gutter={[8, 8]}>
        <Col span={24}>
          {loading === 'noData' ? (
            <div
              style={{
                height: '100%',
                width: '100%',
                justifyContent: 'center',
                display: 'flex',
                alignContent: 'center',
              }}
            >
              <Typography variant="body1" fontSize={22} fontWeight={600}>
                No Image
              </Typography>
            </div>
          ) : (
            <div
              style={{
                height: '100%',
                justifyContent: 'center',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  width: '100%',
                  maxWidth: '100%',
                  position: 'relative',
                  paddingBottom: `${(1 / aspectRatio) * 100}%`,
                  overflow: 'hidden',
                }}
              >
                <img
                  src={attributes.image}
                  alt="Widget Image"
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                  }}
                />
              </div>
            </div>
          )}
        </Col>
      </Row>
    );
  }
}
