import React, { useState, useEffect } from "react";


import { Drawer, Button, Input, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, IconButton, Tooltip, DialogActions, Grid, Box } from '@mui/material';
import { makeStyles } from "@mui/styles";
import "react-datepicker/dist/react-datepicker.css";
import './drawerStyle.css';

import StationDetailsV2 from "./stationDetailsV2";
import { Col, Row } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightFromBracket, faCaretUp, faCheckCircle, faCrosshairs, faEye, faEyeSlash, faTrash } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import ColorSelect from "./drawerComponents/colorSelect";
import { fullMobile } from "./util";
import NodeSelect from "./drawerComponents/nodeSelect";
import NodeDetails from "./drawerComponents/nodeDetails";
const useStyles = makeStyles({
    // ... your existing styles
    drawer: {
        pointerEvents: 'none', // Makes the drawer non-blocking
    },
    content: {
        pointerEvents: 'auto', // Makes the content interactive

        // ... other styles
    },
    closeButton: {
        position: 'absolute',
        top: 20,
        right: 20,
        pointerEvents: 'auto', // Ensure the button is clickable
    },
    underline: {
        "&:before": {
            borderBottom: "none", // Remove default underline
        },
        "&:after": {
            borderBottom: "none", // Remove default underline when focused
        },
        "&:hover:before": {
            borderBottom: "1px solid rgba(0, 0, 0, 0.42)", // Add underline on hover
        },
    },
    commentBox: {
        "&:before": {
            borderBottom: "none", // Remove default underline
        },
        "&:after": {
            borderBottom: "none", // Remove default underline when focused
        },
        "&:hover:before": {
            borderBottom: "none", // Add underline on hover
        },
    },
    // ... other styles
});

const StationDrawer = (
    {
        open,
        setOpen,
        userInfo,
        screenWidth,
        updateItems,
        deleteItem,
        updateChildren,
        probes,
        swapItems,
        items,
        restoreItem,
        osts,
        archiveItem,
        loadingProbes,
        loadingStations,
        openSnack,
        currentItem,
        currentChild,
        updateArchivedItems,
        setCurrentChild,
        company,
        account,
        setCurrentItem,
        centerMapOnStation

    }

) => {
    console.log(items)
    const [stationName, setStationName] = useState(currentItem?.name);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [step, setStep] = useState(null);
    const classes = useStyles();
    // Function to close the drawer
    const handleClose = () => {
        setCurrentItem(null);
    };

    useEffect(() => {
        if (currentItem !== null && currentItem !== undefined) {
            setStationName(currentItem.name)
        }
    }, [currentItem])

    const props = {
        items,
        setStep,
        step,
        currentItem,
        setCurrentItem,
        currentChild,
        setCurrentChild,
        userInfo
    }

    return (
        <Drawer
            BackdropProps={{ invisible: true }}
            anchor={'right'}
            open={currentItem !== null && currentItem !== undefined}
            hideBackdrop={true}
            className={classes.drawer}

        >
            <div
                className={classes.content}
                style={{
                    height: '100vh',
                    position: 'relative',
                    width: screenWidth === 'xs' ? '90vw' : '35vw',
                }}
            >
                <div
                    style={{
                        padding: 10,
                        paddingTop: 15,
                        paddingBottom: 5,
                        boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.55)',
                        height: '10vh',
                        display: 'flex',
                        width: '100%',
                        alignContent: 'center',
                        alignItems: 'center',

                    }}
                >
                    <Row
                        align={'bottom'}
                        style={{
                            width: '100%',
                            display: 'flex',
                            //align items to the bottom of the row


                        }}>
                        <Col span={12}>
                            <Button color={currentItem?.archived ? 'primary' : 'inherit'}
                                variant={currentItem?.archived ? 'contained' : 'outlined'}
                                style={{
                                    height: 30,
                                    textTransform: 'none',
                                    backgroundColor: currentItem?.archived ? '#3f51b5' : 'white',
                                }}
                                onClick={() => {
                                    setCurrentItem({ ...currentItem, archived: !currentItem?.archived })

                                }}>
                                <FontAwesomeIcon
                                    icon={currentItem?.archived ? faCheckCircle : faCircle}
                                    style={{
                                        color: currentItem?.archived ? 'white' : 'black',
                                        marginRight: 10
                                    }} />

                                {currentItem?.archived ? 'Archived' : 'Mark Archived'}
                            </Button>
                        </Col>
                        {currentItem?.id !== undefined && (
                            <Col xs={12} style={{
                                textAlign: 'right',
                                marginTop: 10,
                                display: 'inline'
                            }} >


                                <IconButton
                                    onClick={() => {
                                        setCurrentItem({ ...currentItem, visible: !currentItem?.visible })
                                        updateItems({ ...currentItem, visible: !currentItem?.visible });

                                    }}
                                >
                                    <Tooltip title={currentItem?.visible === true ? "Hide from map" : "Show on map"}
                                        placement="bottom">

                                        <FontAwesomeIcon fontSize={18}
                                            icon={currentItem?.visible === true ? faEye : faEyeSlash} color="#9ec1c9" />

                                    </Tooltip>

                                </IconButton>
                                <IconButton
                                    onClick={() => {
                                        centerMapOnStation(currentItem)
                                    }}
                                >
                                    <Tooltip title="Center on map" placement="bottom">
                                        <FontAwesomeIcon fontSize={20} style={{ cursor: 'pointer' }}
                                            icon={faCrosshairs} color="#404050" />
                                    </Tooltip>

                                </IconButton>
                                <IconButton
                                    onClick={() => {
                                        handleClose();
                                    }}
                                >
                                    <Tooltip title="Delete Task" placement="bottom">
                                        <FontAwesomeIcon size="sm" icon={faTrash} style={{ color: 'rgba(0,0,0,0.5)', marginRight: 8 }} />
                                    </Tooltip>

                                </IconButton>


                                <IconButton
                                    onClick={() => {
                                        setCurrentItem(null)
                                    }}
                                >
                                    <Tooltip title="Close" placement="bottom">
                                        <FontAwesomeIcon size="sm" icon={faArrowRightFromBracket} style={{ color: 'rgba(0,0,0,0.5)', }} />
                                    </Tooltip>

                                </IconButton>


                            </Col>
                        )}

                        <Dialog
                            open={deleteDialog}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                Are you sure you want to delete this task?
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Deleting these tasks will remove them from calendar as well.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button style={{
                                    textTransform: 'none',
                                }} onClick={handleClose}>Cancel</Button>
                                <Button
                                    style={{
                                        fontWeight: 600,
                                        textTransform: 'none',
                                        color: 'red'
                                    }}

                                    onClick={() => {

                                        handleClose();
                                    }}
                                    autoFocus>
                                    Delete
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Row>
                </div>

                <div
                    style={{
                        padding: 15,

                        border: '1px solid rgba(0,0,0,0.1)',
                        width: '100%',

                        overflowY: 'auto',
                        overflowX: 'hidden',
                    }}
                >
                    <Grid container spacing={2} alignItems="center" style={{ width: '100%' }}>
                        <Grid item xs={12} md={8} style={{ display: 'flex', alignItems: 'center' }}>
                            <Input
                                maxRows={4}
                                multiline
                                fullWidth
                                placeholder="Enter Task Name"
                                style={{
                                    fontWeight: 600,
                                    fontSize: 28,
                                    color: "black",
                                }}
                                notched={false}
                                label="none"
                                value={currentItem?.name}
                                onChange={(e) => setCurrentItem({ ...currentItem, name: e.target.value })}
                                classes={{ underline: classes.underline }} // Apply custom styles here
                            />
                        </Grid>
                        <Grid item xs={6} md={2} style={{
                            display: 'flex', alignItems: 'center',
                            justifyContent: 'center'
                        }}> {/* Adjust justifyContent as needed */}
                            <ColorSelect
                                currentItem={currentItem}
                                setCurrentItem={setCurrentItem}
                                updateItems={updateItems}
                                headerKey="color"
                                type="drawer" />
                        </Grid>
                        <Grid item xs={6} md={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Tooltip title="Extra info" placement="bottom">
                                <IconButton
                                    onClick={() => {
                                        setCurrentItem({ ...currentItem, expanded: !currentItem?.expanded })
                                    }}>
                                    <FontAwesomeIcon
                                        size="xl"
                                        style

                                        ={{
                                            cursor: 'pointer',
                                            transform: currentItem?.expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                                            transition: 'transform 0.3s ease-in-out'
                                        }}
                                        icon={faCaretUp} color="#404050" />
                                </IconButton>
                            </Tooltip>
                        </Grid>

                        {currentItem?.expanded === true && (
                            <Grid item xs={12} md={12} style={{}}>

                                <Typography variant="body1" display="inline" fontSize={13} fontWeight={600}
                                >Latitude:</Typography>
                                <Input value={currentItem?.lat}
                                    disabled={currentItem?.name === 'Archived' || currentItem?.archived === true}
                                    style={{ fontSize: 12, fontWeight: 400 }}
                                    type="number"
                                    onChange={(e) => {
                                        setCurrentItem({ ...currentItem, lat: Number(e.target.value) });
                                        updateItems({ ...currentItem, lat: Number(e.target.value) });
                                    }} />
                                <Typography variant="body1" display="inline" fontSize={13}
                                    fontWeight={600} >Longitude:</Typography>
                                <Input value={currentItem?.lng}
                                    disabled={currentItem?.name === 'Archived' || currentItem?.archived === true}
                                    style={{ fontSize: 12, fontWeight: 400 }}
                                    type="number"
                                    onChange={(e) => {
                                        if (currentItem === null || currentItem === undefined) {
                                            return
                                        }
                                        const newItem = { ...currentItem }
                                        newItem.lng = Number(e.target.value)
                                        setCurrentItem(newItem);
                                        updateItems(newItem);
                                    }} />


                            </Grid>
                        )}
                    </Grid>
                </div>
                <div
                    style={{
                        padding: 15,

                        border: '1px solid rgba(0,0,0,0.1)',
                        width: '100%',

                        overflowY: 'auto',
                        overflowX: 'hidden',
                    }}
                >
                    <Grid container alignItems="center" style={{ width: '100%' }}>
                        <Grid item xs={12} md={3} style={{ display: 'flex', alignItems: 'center' }}>
                            <Button variant="link"
                                style={{
                                    textTransform: 'none',
                                    color: 'black',
                                    display: 'inline',
                                    padding: 0,
                                    fontSize: 16,
                                    fontWeight: currentChild === null ? 600 : 400,
                                    cursor: 'pointer'
                                }}
                                onClick={() => {
                                    setCurrentChild(null)
                                    setStep(null)
                                }}>
                                Nodes
                            </Button>
                        </Grid>
                        {currentChild !== null && (<Grid item xs={12} md={3} style={{ display: 'flex', alignItems: 'center' }}>
                            <Button variant="link"
                                style={{
                                    border: '1px solid gray',
                                    textTransform: 'none',
                                    color: 'black',
                                    display: 'inline',
                                    padding: 0,
                                    fontSize: 16,
                                    fontWeight: 600,
                                    cursor: 'pointer'
                                }}
                                onClick={() => {
                                    setStep(null)

                                }}>
                                {currentChild?.name}
                            </Button>
                        </Grid>)}
                        {currentChild !== null && step === 'alerts' &&
                            (<Grid item xs={12} md={3} style={{ display: 'flex', alignItems: 'center' }}>
                                <Button variant="link"
                                    style={{
                                        textTransform: 'none',
                                        color: 'black',
                                        display: 'inline',
                                        padding: 0,
                                        fontSize: 16,
                                        fontWeight: 600,
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                        setStep(null)

                                    }}>
                                    Alerts (0)
                                </Button>
                            </Grid>)}
                        {currentChild !== null && step === 'offsets' &&
                            (<Grid item xs={12} md={3} style={{ display: 'flex', alignItems: 'center' }}>
                                <Button variant="link"
                                    style={{
                                        textTransform: 'none',
                                        color: 'black',
                                        display: 'inline',
                                        padding: 0,
                                        fontSize: 16,
                                        fontWeight: 600,
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                        setStep(null)

                                    }}>
                                    Offsets (0)
                                </Button>
                            </Grid>)}


                    </Grid>
                </div>
                <Box
                    sx={{ px: 2 }}
                    style={{



                        width: '100%',

                        overflowY: 'auto',
                        overflowX: 'hidden',
                    }}
                >
                    <Grid container alignItems="center" style={{ width: '100%' }}>
                        <Grid item xs={12} md={12} style={{}}>


                            {currentChild === null && (
                                <Box sx={{ py: 2 }}>
                                    <Typography variant="body1" fontSize={13} fontWeight={600}
                                    >Select node:</Typography>
                                    <NodeSelect
                                        {...props}
                                    />
                                </Box>
                            )}
                            {currentChild !== null && step === null && (
                                <Box sx={{ py: 2 }}>

                                    <NodeDetails
                                        {...props}
                                    />
                                </Box>
                            )}
                            {currentChild !== null && step === 'alerts' && (
                                <Box sx={{ py: 2 }}>

                                    Alerts
                                </Box>
                            )}
                            {currentChild !== null && step === 'offsets' && (
                                <Box sx={{ py: 2 }}>

                                    Offsets
                                </Box>
                            )}




                        </Grid>



                    </Grid>
                </Box>




            </div>
        </Drawer >
    );
};

export default StationDrawer;
