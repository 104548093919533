import { Button, Checkbox, Divider, OutlinedInput, Typography, Select, MenuItem, CircularProgress, Paper, FormControl, InputLabel } from '@mui/material';
import { Col, Row } from 'antd';
import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, } from 'firebase/firestore';

import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import uuid from 'react-uuid';


import queryString from 'query-string';
import axios from 'axios';

import { fullMobile } from '../waterQuality/waterQualityData/util';



export default function SiteToAccount({ userInfo, screenWidth, openSnack, company, account }) {
    const props = { userInfo, screenWidth, openSnack, company, account, };
    const [loading, setLoading] = useState(true);

    const [oldSite, setOldSite] = useState('e0bad48-f13-26ee-601-c67bc4f2b16a');
    const [oldAccount, setOldAccount] = useState('73d35d5-8230-deb5-fece-734a75a518');
    const [newSite, setNewSite] = useState('e0bad48-f13-26ee-601-c67bc4f2b16a');
    const [newAccount, setNewAccount] = useState('187 Kings Mountain Rdd9877438-f870-d528-197b-53edb61674a8');

    useEffect(() => {
        mergeOldSiteToNewSite();
    }, []);


    const mergeOldSiteToNewSite = async () => {
        const db = getFirestore();

        /*  //get doc of old site
         const oldSiteDoc = await getDoc(doc(db, 'clients', oldAccount, 'accounts', oldSite));
         const oldSiteData = oldSiteDoc.data();
         console.log(oldSiteData);
 
         //update new site with old site data
         await setDoc(doc(db, 'clients', newAccount, 'accounts', newSite), oldSiteData).then(() => {
             console.log('updated new site with old site data');
         }).catch((error) => {
             console.log(error);
         });
 
 
 
         //get accountMap Doc of oldSite
         const oldSiteAccountMapDoc = await getDoc(doc(db, 'clients', oldAccount, 'accounts', oldSite, 'accountMap', 'layers'));
         const oldSiteAccountMapData = oldSiteAccountMapDoc.data();
         console.log(oldSiteAccountMapData);
 
         //update new site accountMap with old site accountMap data
         await setDoc(doc(db, 'clients', newAccount, 'accounts', newSite, 'accountMap', 'layers'), oldSiteAccountMapData).then(() => {
             console.log('updated new site with old site data');
         }).catch((error) => {
             console.log(error);
         });
 
 
         //get dashboard Doc of oldSite
         const oldSiteDashboardDoc = await getDoc(doc(db, 'clients', oldAccount, 'accounts', oldSite, 'dashboard', 'dashboard'));
         const oldSiteDashboardData = oldSiteDashboardDoc.data();
         console.log(oldSiteDashboardData);
 
         //update new site dashboard with old site dashboard data
         await setDoc(doc(db, 'clients', newAccount, 'accounts', newSite, 'dashboard', 'dashboard'), oldSiteDashboardData).then(() => {
             console.log('updated new site with old site data');
         }).catch((error) => {
             console.log(error);
         });
 
         //get newDash doc of oldSite
         const oldSiteNewDashDoc = await getDoc(doc(db, 'clients', oldAccount, 'accounts', oldSite, 'dashboard', 'newDash'));
         const oldSiteNewDashData = oldSiteNewDashDoc.data();
         console.log(oldSiteNewDashData);
 
         //update new site newDash with old site newDash data
         await setDoc(doc(db, 'clients', newAccount, 'accounts', newSite, 'dashboard', 'newDash'), oldSiteNewDashData).then(() => {
             console.log('updated new site with old site data');
         }).catch((error) => {
             console.log(error);
         });
 
 
         //get collection of parameters of oldSite
         const oldSiteParametersCol = await getDocs(query(collection(db, 'clients', oldAccount, 'accounts', oldSite, 'parameters')));
         const oldSiteParametersData = oldSiteParametersCol.docs.map(doc => { return ({ ...doc.data(), account: newSite, company: newAccount }) });
         console.log(oldSiteParametersData);
 
         //create new collection of parameters of newSite
         oldSiteParametersData.forEach(async (parameter) => {
             await setDoc(doc(db, 'clients', newAccount, 'accounts', newSite, 'parameters', parameter.key), parameter).then(() => {
                 console.log('updated new site with old site data');
             }).catch((error) => {
                 console.log(error);
             });
         });
 
 
         //get dashboard doc of oldSite
         const oldSitePreferencesDashboardDoc = await getDoc(doc(db, 'clients', oldAccount, 'accounts', oldSite, 'preferences', 'dashboard'));
         const oldSitePreferencesDashboardData = oldSitePreferencesDashboardDoc.data();
         console.log(oldSitePreferencesDashboardData);
 
         //setDoc of newSite dashboard
         await setDoc(doc(db, 'clients', newAccount, 'accounts', newSite, 'preferences', 'dashboard'), oldSitePreferencesDashboardData).then(() => {
             console.log('updated new site with old site data');
         }).catch((error) => {
             console.log(error);
         });
 
 
         //get dashboardLabGraphs doc of oldSite
         const oldSitePreferencesDashboardLabGraphsDoc = await getDoc(doc(db, 'clients', oldAccount, 'accounts', oldSite, 'preferences', 'dashboardLabGraphs'));
         const oldSitePreferencesDashboardLabGraphsData = oldSitePreferencesDashboardLabGraphsDoc.data();
         console.log(oldSitePreferencesDashboardLabGraphsData);
 
         //setDoc of newSite dashboardLabGraphs
         await setDoc(doc(db, 'clients', newAccount, 'accounts', newSite, 'preferences', 'dashboardLabGraphs'), oldSitePreferencesDashboardLabGraphsData).then(() => {
             console.log('updated new site with old site data');
         }).catch((error) => {
             console.log(error);
         });
 
         //get widgets doc of oldSite
         const oldSitePreferencesWidgetsDoc = await getDoc(doc(db, 'clients', oldAccount, 'accounts', oldSite, 'preferences', 'widgets'));
         const oldSitePreferencesWidgetsData = oldSitePreferencesWidgetsDoc.data();
         console.log(oldSitePreferencesWidgetsData);
 
         //create new collection of widgets of newSite
         oldSitePreferencesWidgetsData.forEach(async (widget) => {
             await setDoc(doc(db, 'clients', newAccount, 'accounts', newSite, 'preferences', 'widgets', widget.key), widget).then(() => {
                 console.log('updated new site with old site data');
             }).catch((error) => {
                 console.log(error);
             });
         });
 
 
 
 
        
 
 
         //get collection of references of oldSite
         const oldSiteReferencesCol = await getDocs(query(collection(db, 'clients', oldAccount, 'accounts', oldSite, 'references')));
         const oldSiteReferencesData = oldSiteReferencesCol.docs.map(doc => { return ({ ...doc.data(), account: newSite, company: newAccount }) });
         console.log(oldSiteReferencesData);
 
         //create new collection of references of newSite
         oldSiteReferencesData.forEach(async (reference) => {
             await setDoc(doc(db, 'clients', newAccount, 'accounts', newSite, 'references', reference.key), reference).then(() => {
                 console.log('updated new site with old site data');
             }).catch((error) => {
                 console.log(error);
             });
         });
 
 
         //get stationMapData doc of oldSite
         const oldSiteStationMapDataDoc = await getDoc(doc(db, 'clients', oldAccount, 'accounts', oldSite, 'stationMapData', 'stationMapData'));
         const oldSiteStationMapDataData = oldSiteStationMapDataDoc.data();
         console.log(oldSiteStationMapDataData);
 
         //setDoc of newSite stationMapData
         await setDoc(doc(db, 'clients', newAccount, 'accounts', newSite, 'stationMapData', 'stationMapData'), oldSiteStationMapDataData).then(() => {
             console.log('updated new site with old site data');
         }).catch((error) => {
             console.log(error);
         });
 
 
         //get collection of userGenerated of oldSite
         const oldSiteUserGeneratedCol = await getDocs(query(collection(db, 'clients', oldAccount, 'accounts', oldSite, 'userGenerated')));
         const oldSiteUserGeneratedData = oldSiteUserGeneratedCol.docs.map(doc => { return ({ ...doc.data(), account: newSite, company: newAccount }) });
         console.log(oldSiteUserGeneratedData);
 
         //create new collection of userGenerated of newSite
         oldSiteUserGeneratedData.forEach(async (userGenerated) => {
             await setDoc(doc(db, 'clients', newAccount, 'accounts', newSite, 'userGenerated', userGenerated.key), userGenerated).then(() => {
                 console.log('updated new site with old site data');
             }).catch((error) => {
                 console.log(error);
             });
         });
 
 
 

        //get collection of albums of oldSite
        const oldSiteAlbumsCol = await getDocs(query(collection(db, 'clients', oldAccount, 'albums'), where('account', '==', oldSite)));
        const oldSiteAlbumsData = oldSiteAlbumsCol.docs.map(doc => { return ({ ...doc.data(), account: newSite, company: newAccount }) });
        console.log(oldSiteAlbumsData);

        //create new collection of albums of newSite
        oldSiteAlbumsData.forEach(async (album) => {
            await setDoc(doc(db, 'clients', newAccount, 'albums', album.key), album).then(() => {
                console.log('updated new site with old site data');
            }).catch((error) => {
                console.log(error);
            });
        });



        //get collection of chemicalApplications of oldSite
        const oldSiteChemicalApplicationsCol = await getDocs(query(collection(db, 'clients', oldAccount, 'chemicalApplications'), where('accountKey', '==', oldSite)));
        const oldSiteChemicalApplicationsData = oldSiteChemicalApplicationsCol.docs.map(doc => { return ({ ...doc.data(), account: newSite, company: newAccount }) });
        console.log(oldSiteChemicalApplicationsData);

        //create new collection of chemicalApplications of newSite
        oldSiteChemicalApplicationsData.forEach(async (chemicalApplication) => {
            await setDoc(doc(db, 'clients', newAccount, 'chemicalApplications', chemicalApplication.key), chemicalApplication).then(() => {
                console.log('updated new site with old site data');
            }).catch((error) => {
                console.log(error);
            });
        });


        //get collection of fieldLogsNew of oldSite
        const oldSiteFieldLogsNewCol = await getDocs(query(collection(db, 'clients', oldAccount, 'fieldLogsNew'), where('accountKey', '==', oldSite)));
        const oldSiteFieldLogsNewData = oldSiteFieldLogsNewCol.docs.map(doc => { return ({ ...doc.data(), account: newSite, company: newAccount }) });
        console.log(oldSiteFieldLogsNewData);

        //create new collection of fieldLogsNew of newSite
        oldSiteFieldLogsNewData.forEach(async (fieldLogNew) => {
            await setDoc(doc(db, 'clients', newAccount, 'fieldLogsNew', fieldLogNew.key), fieldLogNew).then(() => {
                console.log('updated new site with old site data');
            }).catch((error) => {
                console.log(error);
            });
        });


        //get collection of flItems of oldSite
        const oldSiteFlItemsCol = await getDocs(query(collection(db, 'clients', oldAccount, 'flItems'), where('account', '==', oldSite)));
        const oldSiteFlItemsData = oldSiteFlItemsCol.docs.map(doc => { return ({ ...doc.data(), account: newSite, company: newAccount }) });
        console.log(oldSiteFlItemsData);

        //create new collection of flItems of newSite
        oldSiteFlItemsData.forEach(async (flItem) => {
            await setDoc(doc(db, 'clients', newAccount, 'flItems', flItem.key), flItem).then(() => {
                console.log('updated new site with old site data');
            }).catch((error) => {
                console.log(error);
            });
        });


        //get collection of photos of oldSite
        const oldSitePhotosCol = await getDocs(query(collection(db, 'clients', oldAccount, 'photos'), where('account', '==', oldSite)));
        const oldSitePhotosData = oldSitePhotosCol.docs.map(doc => { return ({ ...doc.data(), account: newSite, company: newAccount }) });
        console.log(oldSitePhotosData);

        //create new collection of photos of newSite
        oldSitePhotosData.forEach(async (photo) => {
            await setDoc(doc(db, 'clients', newAccount, 'photos', photo.key), photo).then(() => {
                console.log('updated new site with old site data');
            }).catch((error) => {
                console.log(error);
            });
        });


        //get collection of stations of oldSite
        const oldSiteStationsCol = await getDocs(query(collection(db, 'clients', oldAccount, 'stations'), where('account', '==', oldSite)));
        const oldSiteStationsData = oldSiteStationsCol.docs.map(doc => { return ({ ...doc.data(), account: newSite, company: newAccount }) });
        console.log(oldSiteStationsData);

        //create new collection of stations of newSite
        oldSiteStationsData.forEach(async (station) => {
            await setDoc(doc(db, 'clients', newAccount, 'stations', station.key), station).then(() => {
                console.log('updated new site with old site data');
            }).catch((error) => {
                console.log(error);
            });
        });


*/

/*         //get collection of probes of oldSite
        const oldSiteProbesCol = await getDocs(query(collection(db, 'clients', oldAccount, 'accounts', oldSite, 'probes')));
        const oldSiteProbesData = oldSiteProbesCol.docs.map(doc => { return ({ ...doc.data(), account: newSite, companyKey: newAccount }) });
        console.log(oldSiteProbesData);

        //create new collection of probes of newSite
        oldSiteProbesData.forEach(async (probe) => {
            await setDoc(doc(db, 'clients', newAccount, 'accounts', newSite, 'probes', probe.key), probe).then(() => {
                console.log('updated new site with old site data');
            }).catch((error) => {
                console.log(error);
            });
        }); */

        //get collection of stations of oldSite
        const oldSiteStationsCol = await getDocs(query(collection(db, 'clients', oldAccount, 'probes'), where('account', '==', oldSite)));
        const oldSiteStationsData = oldSiteStationsCol.docs.map(doc => { return ({ ...doc.data(), account: newSite, companyKey: newAccount }) });
        console.log(oldSiteStationsData);

        //create new collection of stations of newSite
        oldSiteStationsData.forEach(async (station) => {
            await setDoc(doc(db, 'clients', newAccount, 'probes', station.key), station).then(() => {
                console.log('updated new site with old site data');
            }).catch((error) => {
                console.log(error);
            });
        });



    }





















    //SITE LEVEL
    /////////////////////////////////////////////////////
    //accountMap -- layers
    //dashboard -- dashboard --newDash
    //parameters 
    //preferences --dashboard --dashboardlabGraphs --widgets
    //probes 
    //references
    //stationMapData --stationMapData
    //userGenerated 

    //ACCOUNT LEVEL
    ////////////////////////////////////////////////////////
    //albums with oldsite as account
    //chemicalApplications with oldsite as accountKey
    //fieldLogsNew with oldsite as accountKey
    //flItems with oldsite as account
    //photos with oldsite as account
    //stations with oldsite as account


    //PURE SITE INFO
    //update raw site info to match old site






    return (
        <div style={{ fontFamily: 'Roboto, sans-serif', backgroundColor: '#f6f8fe', minHeight: '100vh' }}>
            <Row style={{ padding: 30 }} align="middle" >
                <Col {...fullMobile(20, 24)}>
                    <Typography variant='h3' fontWeight={500} fontSize={36}>Site to Account</Typography>
                    <Typography style={{ paddingTop: 5 }} variant='h5' fontWeight={300} fontSize={18}>Make a site an account.</Typography>

                </Col>




            </Row>
        </div>
    );
}