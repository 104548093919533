import React, { useRef, useState } from "react";


import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { faChartLine, faSpinner, faSquare, faToggleOff, faToggleOn, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogTitle, IconButton, Tooltip, Typography } from '@mui/material';
import { Col } from 'antd';
import moment from 'moment-timezone';
import './streamList.css';
import WifiIcon from '@mui/icons-material/Wifi';
import WifiOffIcon from '@mui/icons-material/WifiOff';


import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";





const StreamsTableMulti = (
    {
        streams,
        setStreams,
        screenWidth,
        showInactive,
        loading,
        setCurrentStream,
        deleteStream,
        updateStream,
        setStreamDrawer,
        duplicateStream,
        updateStreamsPosition,
        headers,
        getNestedProperty,
        selectable,
        sortedStreams,
        sortItem,
        setSortItem,
        ProbeParameters,
        loadingMeta,
        openChart,
        prefKey
    }

) => {

    const [hoverKey, setHoverKey] = useState(null);
    const [undoHistory, setUndoHistory] = useState([]);
    const [redoHistory, setRedoHistory] = useState([]);
    const [initialWidth, setInitialWidth] = useState(null);
    const [lastClickedIndex, setLastClickedIndex] = useState(null);
    const [deleteDialog, setDeleteDialog] = useState(false);

    const textRef = useRef(null);









    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    function onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            streams,
            result.source.index,
            result.destination.index
        );


        setSortItem(null)
        updateStreamsPosition(items);
    }

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer


        // change background colour if dragging


        // styles we need to apply on draggables
        ...draggableStyle
    });

    const getListStyle = isDraggingOver => ({



    });


    const handleClose = () => {
        setDeleteDialog(false);
    };

    return (






        <Col span={24} style={{ paddingBottom: 30, paddingRight: 0, marginRight: 0 }}>


            <DragDropContext onDragEnd={(source) => onDragEnd(source)}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                        >
                            {sortedStreams?.map((l, i) => {
                                const stream = l;
                                const StreamIcon = ({ stream, handleStreamUpdate }) => (
                                    <FontAwesomeIcon
                                        size='2xl'
                                        icon={stream.inactive ? faToggleOff : faToggleOn}
                                        style={{
                                            color: stream.inactive ? 'orange' : '#19d319',
                                            cursor: 'pointer',

                                        }}
                                        onClick={() => {
                                            const newStream = { ...stream, inactive: !stream.inactive };
                                            updateStream(newStream, 'inactive');
                                        }}
                                    />
                                );

                                const CheckboxIcon = ({ stream, handleStreamUpdate, index }) => (
                                    <Checkbox
                                        defaultChecked={stream.selected}
                                        sx={{
                                            color: '#3f51b5',
                                            '&.Mui-checked': {
                                                color: '#3f51b5',
                                            },
                                            '& .MuiSvgIcon-root': { fontSize: 24 }
                                        }}
                                        onChange={(e) => {
                                            const shiftKeyPressed = e.nativeEvent.shiftKey;



                                            if (shiftKeyPressed && lastClickedIndex !== null) {
                                                // Find the starting and ending position based on stream position
                                                const startPosition = lastClickedIndex;
                                                const endPosition = index;

                                                console.log(startPosition)
                                                console.log(endPosition)


                                                const sortedPositions = [startPosition, endPosition].sort((a, b) => a - b);



                                                // Loop to set selected state to true for all rows between startPosition and endPosition
                                                let newPositionState = sortedStreams[startPosition].selected;

                                                const newSortedStreams = sortedStreams.map((stream, i) => {
                                                    if (i >= sortedPositions[0] && i <= sortedPositions[1]) {
                                                        return { ...stream, selected: newPositionState };
                                                    }
                                                    return stream;
                                                }
                                                );
                                                setStreams(JSON.parse(JSON.stringify(newSortedStreams)));
                                            } else {
                                                // If shift key is not pressed, set this row as the first clicked row, but only setFristClickedIndex if the checkbox is not already selected
                                                const position = index;

                                                if (!stream.selected) {
                                                    setLastClickedIndex(position);
                                                }


                                                // Toggle selected state for the clicked row
                                                const newStream = { ...stream, selected: !stream.selected };
                                                updateStream(newStream, 'selected');
                                            }
                                        }}
                                    />
                                );




                                if ((showInactive || !l.inactive) && !l.filtered) {
                                    return (
                                        <Draggable key={l.id} draggableId={l.id} index={i}>
                                            {(provided, snapshot) => (
                                                <div
                                                    key={l.id}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                >
                                                    <div style={{
                                                        display: 'inline-flex',
                                                        width: headers?.filter((header) => header.visible === true).length === 1 ? '100%' : `auto`,
                                                        //nowrap
                                                        flexWrap: 'nowrap',
                                                        cursor: 'pointer',

                                                    }}
                                                        className={`row-hover-effect ${l.selected ? "sortable-selected" : ""}`}
                                                        onMouseEnter={() => setHoverKey(l.id)}
                                                        onMouseLeave={() => setHoverKey(null)}
                                                    >
                                                        <div className="box" style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            width: 20,
                                                            borderBottom: '1px solid rgba(0,0,0,0.2)'
                                                        }}>
                                                            <div className="my-handle" style={{ display: 'inline' }}>
                                                                <DragIndicatorIcon />
                                                            </div>

                                                        </div>

                                                        {selectable && (<div className="box" style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            width: 60,
                                                            borderBottom: '1px solid rgba(0,0,0,0.2)'
                                                        }}>
                                                            <CheckboxIcon stream={l} updateStream={updateStream} index={i} />
                                                        </div>
                                                        )}
                                                        <div className="box" style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            width: 60,
                                                            borderBottom: '1px solid rgba(0,0,0,0.2)'
                                                        }}>

                                                            <StreamIcon stream={l} updateStream={updateStream} />
                                                        </div>



                                                        {headers?.map((header, i) => {
                                                            const nestValue = getNestedProperty(l, header.id, header.getLabel, header.optionType, header?.options, header?.decimals);
                                                            const value = nestValue;

                                                            if (header.visible === false) return null;



                                                            return (
                                                                <div
                                                                    className="box"
                                                                    style={{
                                                                        width: headers.filter((header) => header.visible === true).length === 1 ? '100%' : `${header.width}px`,
                                                                        borderLeft: '1px solid rgba(0,0,0,0.2)',
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'left',
                                                                        padding: 8,
                                                                        borderBottom: '1px solid rgba(0,0,0,0.2)'
                                                                    }}
                                                                    onClick={() => {
                                                                        setCurrentStream(l);
                                                                        setStreamDrawer(true);
                                                                    }}
                                                                >
                                                                    {header.optionType === 'colorSelect' && (
                                                                        <FontAwesomeIcon icon={faSquare} color={value} size="lg" style={{ marginRight: 10 }} />
                                                                    )}
                                                                    {header.optionType === 'status' && (
                                                                        value === true ?
                                                                            <>
                                                                                <WifiIcon /> Connected
                                                                            </> :
                                                                            <>
                                                                                <WifiOffIcon /> No connection
                                                                            </>

                                                                    )}
                                                                    {loadingMeta && ['elevation', 'latitude', 'longitude', 'lat', 'lon', 'lastRecorded'].includes(header.id) ?

                                                                        <FontAwesomeIcon icon={faSpinner} spin={true} /> :


                                                                        <Typography variant="body2" fontWeight={500} fontSize={13}>
                                                                            <span ref={textRef}>
                                                                                {header.type === 'date' || header.optionType === 'dateSelect' ?
                                                                                    (value !== undefined ? moment(value, 'x').format(
                                                                                        screenWidth === 'xs' ? 'MMM DD, YY ha' : 'MM/DD/YY h:mm a') : "No Date") :
                                                                                    header.optionType === 'money' ? `$${value || ''}` : value
                                                                                }
                                                                                {header?.id === 'elevation' && ' ft'}
                                                                            </span>
                                                                        </Typography>
                                                                    }
                                                                </div>
                                                            );
                                                        })}

                                                        <div className="box fixed-column" style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            paddingRight: 10,
                                                            paddingLeft: 10,
                                                            width: 150,
                                                            borderBottom: '1px solid rgba(0,0,0,0.2)',
                                                            borderLeft: '1px solid rgba(0,0,0,0.2)',
                                                            //little left box shadow
                                                            boxShadow: 'inset 5px 0px 5px -5px rgba(0,0,0,0.2)'
                                                        }}>
                                                            {['obscapeWeatherStation', 'tideStation'].includes(prefKey) && (<IconButton size="sm">

                                                                <Tooltip title="Preview" placement="bottom">
                                                                    <FontAwesomeIcon icon={faChartLine} size="xs" style={{ color: 'gray', cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            console.log(l)
                                                                            openChart(l)
                                                                        }} />
                                                                </Tooltip>

                                                            </IconButton>
                                                            )}
                                                            <IconButton size="sm">

                                                                <Tooltip title="Duplicate" placement="bottom">
                                                                    <FontAwesomeIcon icon={faCopy} size="xs" style={{ color: 'gray', cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            duplicateStream(l)
                                                                        }} />
                                                                </Tooltip>

                                                            </IconButton>




                                                            <IconButton onClick={() => {
                                                                setDeleteDialog(stream)
                                                                console.log(stream)
                                                            }}>
                                                                <Tooltip title="Delete" placement="bottom">
                                                                    <FontAwesomeIcon icon={faTrash} size="xs" style={{ color: 'gray', cursor: 'pointer' }}
                                                                    />
                                                                </Tooltip>

                                                            </IconButton>

                                                            <Dialog
                                                                open={deleteDialog !== false ? true : false}
                                                                onClose={handleClose}
                                                                aria-labelledby="alert-dialog-title"
                                                                aria-describedby="alert-dialog-description"
                                                            >
                                                                <DialogTitle id="alert-dialog-title">
                                                                    Are you sure you want to delete stream?
                                                                </DialogTitle>

                                                                <DialogActions>
                                                                    <Button style={{
                                                                        textTransform: 'none',
                                                                    }} onClick={handleClose}>Cancel</Button>
                                                                    <Button
                                                                        style={{
                                                                            fontWeight: 600,
                                                                            textTransform: 'none',
                                                                            color: 'red'
                                                                        }}

                                                                        onClick={() => {
                                                                            deleteStream(deleteDialog);
                                                                            handleClose();
                                                                        }}
                                                                        autoFocus>
                                                                        Delete
                                                                    </Button>
                                                                </DialogActions>
                                                            </Dialog>

                                                        </div>


                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>




                                    );
                                } else {
                                    return <div key={l.id}></div>;
                                }
                            })}


                        </div>
                    )
                    }
                </Droppable>
            </DragDropContext>

            {loading === false && streams?.length === 0 && (<Col span={24} style={{ paddingBottom: 30, textAlign: 'center' }}>
                <Typography variant="h4" fontSize={24} fontWeight={600}>No Streams</Typography>
            </Col>)}
            {loading && (<Col span={24} style={{ paddingBottom: 30, textAlign: 'center' }}>
                <CircularProgress />
            </Col>)}

        </Col>





    );
};

export default StreamsTableMulti;
