import React, { useEffect } from "react";
import { Autocomplete, Input, MenuItem, Select, TextField } from "@mui/material";

const UnitsSystemPicker = ({
    currentStream,
    setCurrentStream,
    headerKey,
    options,
}) => {

    const [value, setValue] = React.useState(currentStream?.[headerKey]);  // Renamed from 'age' to 'value'


    const handleChange = (selectedOption) => {
        if (selectedOption) {
            console.log({ ...currentStream, [headerKey]: selectedOption });
            setCurrentStream({ ...currentStream, [headerKey]: selectedOption });
            setValue(selectedOption);  // <- Set the entire option as the value
        } else {
            setValue(null);
        }
    };



    return (
        <Select
            size="small"
            fullWidth
            notched={false}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={value}
            label="Type"
            onChange={(e) => {
                handleChange(e.target.value)
            }}
        >
            {[
                "US/Imperial",
                "Metric",
            ].map((l) => {
                return (
                    <MenuItem value={l}>{l}</MenuItem>
                )
            })}
        </Select>
    );
};

export default UnitsSystemPicker;
