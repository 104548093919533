import { faBuilding, faComment, faComments, faFile, faFileLines, faQuestionCircle, faUser } from '@fortawesome/free-regular-svg-icons';
import { faAdd, faArrowRight, faBars, faChartLine, faDatabase, faGauge, faGear, faMoneyBill, faPeopleGroup, faPlus, faQuestion, faSignOut, faSort, faTable } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popper, Typography, Button, Box, Divider, ClickAwayListener, Paper, OutlinedInput, List, Menu, ListItem, Avatar } from '@mui/material';
import { Col, Row } from 'antd';
import { getAuth, signOut } from 'firebase/auth';
import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { fullMobile } from '../extra/util';
import './styles.css';
import { collection, doc, getFirestore, onSnapshot, query, updateDoc, where } from 'firebase/firestore';




export default function NewUserButton({ openSnack, userRef, companyRef, accountRef, type }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [footerAnchorEl, setFooterAnchorEl] = useState(null);
    const [projectSearch, setProjectSearch] = useState('');
    const projectSearchRef = useRef(null);
    const projectRef = useRef(null);
    const footerRef = useRef(null);
    const [accounts, setAccounts] = useState([]);
    const [filterData, setFilterData] = useState([]);

    const [account, setAccount] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    const [company, setCompany] = useState(null);

    const [isOpen, setIsOpen] = useState(false);
    const [isFooterOpen, setIsFooterOpen] = useState(false);


    useEffect(() => {
        if (companyRef !== undefined) {
            setCompany(companyRef)

        }
    }, [companyRef])
    useEffect(() => {
        if (accountRef !== undefined) {

        }
    }, [accountRef])
    useEffect(() => {
        if (userRef !== undefined) {
            setUserInfo(userRef)

        }
    }, [userRef])

    useEffect(() => {

    }, [])




    const handleClick = (event) => {
        setAnchorEl(anchorEl === null ? event.currentTarget : null);
    };
    const handleClose = (type) => {
        setAnchorEl(null);
    };

    useEffect(() => {
        //set anchorEl to projectRef
        setAnchorEl(projectRef.current)
        setFooterAnchorEl(footerRef.current)
    }, [])


    let navigate = useNavigate();
    let location = useLocation();
    const db = getFirestore();
    const companyType = company === undefined ? undefined : company?.companyTypeKey !== undefined ? company?.companyTypeKey : company?.companyType !== undefined ? company?.companyType : undefined;



    return (
        <Row   >


            <Col  {...fullMobile(24)} style={{ position: 'relative', textAlign: 'left', marginTop: 10, marginBottom: 10 }}

            >

                <ListItem button
                    ref={projectRef}
                    onClick={() => setIsOpen(!isOpen)}
                    sx={{


                    }} style={{ height: 50 }} >
                    <Typography variant="body1" fontWeight={500} fontSize={16} style={{ textAlign: 'left', }}>
                        <FontAwesomeIcon icon={faUser} color="#404050" size="lg" style={{ marginRight: 10 }} />  Profile
                    </Typography>
                </ListItem>


                {isOpen && (
                    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
                        <Popper
                            id={'popper'}
                            open={isOpen}
                            anchorEl={anchorEl}

                            style={{ zIndex: 1000, width: 240 }}
                        >
                            <Box sx={{
                                p: 0, bgcolor: 'background.paper',
                                borderRadius: 2,
                                border: '1px solid #e0e0e0',
                                boxShadow: 1,

                            }}>
                                <Row style={{}}>
                                    <Col span={24} style={{ textAlign: 'center', padding: 8, borderRadius: '2px 2px 0px 0px', backgroundColor: 'rgba(0,0,0,0.05)', height: 80 }}>
                                        <Row>
                                            <Col span={8}>
                                                <Avatar sx={{ width: 60, height: 60, }} //circle with first name initial
                                                    style={{ backgroundColor: '#404050', color: '#fff', fontSize: 20, fontWeight: 500, textTransform: 'uppercase', }}>
                                                    {userInfo && userInfo?.firstName && userInfo?.firstName[0]}
                                                </Avatar>
                                            </Col>
                                            <Col span={16} style={{ textAlign: 'left', }}>
                                                <Typography variant="body1" fontWeight={500} fontSize={16} style={{ textAlign: 'left', }}>
                                                    {userInfo && userInfo?.firstName} {userInfo && userInfo?.lastName}
                                                </Typography>
                                                <Typography variant="body1" fontWeight={400} fontSize={14} style={{ textAlign: 'left', cursor: 'pointer' }}

                                                    onClick={() => {
                                                        //if location is userProfile go to top of page
                                                        setIsOpen(false)
                                                        if (location.pathname === '/userProfile') {
                                                            window.scrollTo(0, 0)
                                                            return
                                                        }
                                                        navigate('/userProfile')
                                                    }}
                                                >
                                                    <Link>Edit profile</Link>
                                                </Typography>
                                            </Col>

                                        </Row>

                                    </Col>
                                    <Col span={24} style={{ textAlign: 'center', }}>

                                        <List sx={{ width: 240 }}>


                                            <ListItem button sx={{
                                                borderBottom: '1px solid #e0e0e0',
                                                height: 40,
                                            }}
                                                onClick={() => {
                                                    //if location is change company go to top of page
                                                    setIsOpen(false)
                                                    if (location.pathname === '/changeAccount') {
                                                        window.scrollTo(0, 0)
                                                        return
                                                    }
                                                    navigate('/changeAccount')
                                                }}

                                            >
                                                <Typography variant="body1" fontWeight={400} fontSize={14} style={{ textAlign: 'left', }}>
                                                    <FontAwesomeIcon icon={faPeopleGroup} color="#404050" size="lg" style={{ marginRight: 10 }} />  Change Account
                                                </Typography>
                                            </ListItem>
                                            {["EuEZ93ByFZYs8bSUXoYsTt1zu0m2", 'eTevYsI9mQMxhtntd95HmRGasNx2', 'z4y8XVrxYGT7rO4R01Y8tQ2F5z83', 't9bOfGDqwDaQTYV6i008opscpjz2'].includes(userInfo.id) && (<ListItem button sx={{
                                                borderBottom: '1px solid #e0e0e0',
                                                height: 40,
                                            }}
                                                onClick={() => {
                                                    //if location is change company go to top of page
                                                    setIsOpen(false)
                                                    if (location.pathname === '/createAccount') {
                                                        window.scrollTo(0, 0)
                                                        return
                                                    }
                                                    navigate('/createAccount')
                                                }}

                                            >
                                                <Typography variant="body1" fontWeight={400} fontSize={14} style={{ textAlign: 'left', }}>
                                                    <FontAwesomeIcon icon={faAdd} color="#404050" size="lg" style={{ marginRight: 10 }} />  Create Account
                                                </Typography>
                                            </ListItem>
                                            )}
                                            <ListItem button sx={{
                                                borderBottom: '1px solid #e0e0e0',
                                                height: 40,
                                            }}

                                                onClick={() => {
                                                    const auth = getAuth()
                                                    signOut(auth).then(() => {
                                                        // Sign-out successful.
                                                        console.log('Sign-out successful.')
                                                        navigate('/')
                                                    }).catch((error) => {
                                                        // An error happened.
                                                        console.log('An error happened.')
                                                    });
                                                }}



                                            >
                                                <Typography variant="body1" fontWeight={400} fontSize={14} style={{ textAlign: 'left', }}>
                                                    <FontAwesomeIcon icon={faSignOut} color="#404050" size="lg" style={{ marginRight: 10 }} />  Sign Out
                                                </Typography>
                                            </ListItem>


                                        </List>

                                    </Col>
                                </Row>
                            </Box>
                        </Popper>
                    </ClickAwayListener>
                )}

            </Col>


        </Row >
    )




}
