import React from "react";


import { Button, ButtonGroup } from '@mui/material';
import { Col, Row } from 'antd';




const PhotosHeaders = (
    {
        searchQuery,
        setSearchQuery,
        activeTab,
        setActiveTab,
        screenWidth
    }

) => {






    return (
        <Row style={{}}>
            <Col span={24}>


                <Row >
                    <Col xs={24} sm={24} style={{
                        height: '70px',
                        borderBottom: '1px solid rgba(0,0,0,0.2)',
                        display: 'flex',
                        alignItems: 'flex-end', // Adjusted this line
                        justifyContent: 'flex-start', // Adjusted this line for better readability
                    }}>
                        <ButtonGroup size="small" aria-label="small button group" style={{
                            marginLeft: 10
                        }}>

                            <Button
                                key='albums'
                                onClick={(e) => setActiveTab('albums')}
                                color='primary'
                                variant={null}
                                style={{
                                    textTransform: 'none',
                                    borderRadius: 0,
                                    height: '50px',
                                    fontWeight: 400,
                                    fontSize: 18,
                                    color: activeTab === 'albums' ? '#6fa2f1' : null,
                                    borderBottom: activeTab === 'albums' ? '3px solid #3f51b5' : null
                                }}
                            >
                                Albums
                            </Button>
                            <Button
                                key="timeline"
                                onClick={(e) => setActiveTab('timeline')}
                                color='primary'
                                variant={null}
                                style={{
                                    height: '50px',
                                    textTransform: 'none',
                                    borderRadius: 0,
                                    fontWeight: 400,
                                    fontSize: 18,
                                    color: activeTab === 'timeline' ? '#6fa2f1' : null,
                                    borderBottom: activeTab === 'timeline' ? '3px solid #3f51b5' : null
                                }}
                            >
                                Timeline
                            </Button>
                        </ButtonGroup>
                    </Col>



                </Row>

            </Col >



        </Row >
    );
};

export default PhotosHeaders;
