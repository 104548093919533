import React from "react";
import { InfoWindow } from "@react-google-maps/api";
import { Typography } from "@mui/material";
import { SpeedUnit, convertUnits } from 'measurement-unit-converter';
import convert from 'convert-units';
import moment from 'moment-timezone';

const MapMarkerPopup = ({ currentStream, prefKey, showInfoWindow, toggleInfoWindow, center, displayName, windDirection }) => {
    const convertTemperature = (tempCelsius) => {
        return currentStream?.unitsSystem === "US/Imperial"
            ? convert(tempCelsius).from('C').to('F')?.toFixed(1) + '°F'
            : tempCelsius + '°C';
    };

    const convertWindSpeed = (speedMetersPerSecond) => {
        return currentStream?.unitsSystem === "US/Imperial"
            ? convertUnits(speedMetersPerSecond, SpeedUnit.METER_PER_SECOND, SpeedUnit.MILE_PER_HOUR).toFixed(1) + ' mph'
            : speedMetersPerSecond?.toFixed(1) + ' m/s';
    };

    const convertHumidity = (humidity) => {
        const numberHumi = Number(humidity * 100);
        return numberHumi?.toFixed(1) + '%';
    };






    const humidity = convertHumidity(currentStream?.lastData?.relativeHumidity);
    const temperature = convertTemperature(currentStream?.lastData?.airTemperature);
    const windSpeed = convertWindSpeed(currentStream?.lastData?.windSpeed);

    return (
        showInfoWindow && prefKey === 'obscapeWeatherStation' && (
            <InfoWindow position={center} onCloseClick={toggleInfoWindow}>
                <div>
                    <Typography variant="body1" fontSize={14} fontWeight={700}>
                        {displayName}
                    </Typography>
                    <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '5px' }}>
                        <tbody>
                            <tr>
                                <td style={{ border: '1px solid #ddd', padding: '3px' }}><Typography variant="body1" fontSize={13} fontWeight={600}>Temp:</Typography></td>
                                <td style={{ border: '1px solid #ddd', padding: '3px' }}><Typography variant="body1" fontSize={13} fontWeight={400}>{temperature}</Typography></td>
                            </tr>
                            <tr>
                                <td style={{ border: '1px solid #ddd', padding: '3px' }}><Typography variant="body1" fontSize={13} fontWeight={600}>Humidity:</Typography></td>
                                <td style={{ border: '1px solid #ddd', padding: '3px' }}><Typography variant="body1" fontSize={13} fontWeight={400}>{humidity}</Typography></td>
                            </tr>
                            <tr>
                                <td style={{ border: '1px solid #ddd', padding: '3px' }}><Typography variant="body1" fontSize={13} fontWeight={600}>Time:</Typography></td>
                                <td style={{ border: '1px solid #ddd', padding: '3px' }}><Typography variant="body1" fontSize={13} fontWeight={400}>{moment(currentStream?.lastData?.time, 'X').format('lll')}</Typography></td>
                            </tr>
                            <tr>
                                <td style={{ border: '1px solid #ddd', padding: '3px' }}><Typography variant="body1" fontSize={13} fontWeight={600}>Wind Speed:</Typography></td>
                                <td style={{ border: '1px solid #ddd', padding: '3px' }}><Typography variant="body1" fontSize={13} fontWeight={400}>{windSpeed}</Typography></td>
                            </tr>
                            <tr>
                                <td style={{ border: '1px solid #ddd', padding: '3px' }}><Typography variant="body1" fontSize={13} fontWeight={600}>Wind Direction:</Typography></td>
                                <td style={{ border: '1px solid #ddd', padding: '3px' }}><Typography variant="body1" fontSize={13} fontWeight={400}>{windDirection}</Typography></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </InfoWindow>
        )
    );
};

export default MapMarkerPopup;
