import React, { useEffect, useState } from "react";
import { Divider, Box, Grid, Typography, IconButton, Badge, Tooltip, Button } from "@mui/material";
import CreateItem from "../components/folderComponents/createItem";
import SearchFolders from "../components/folderComponents/searchFolders";
import FolderEditor from "../components/folderComponents/folderEditor";
import { countFolderImagesFunction, countFoldersFunction, createFolderFunction, deleteFolderFunction, fetchFolders, updateFolderFunction, updateFolderImageFunction, updateParentIdFunction } from "../utils/folderFunctions";
import FoldersList from "../components/folderComponents/folderList";
import uuid from "react-uuid";
import FolderBreadCrumbs from "../components/folderComponents/folderBreadCrumbs";
import { faArrowAltCircleUp, faCloudArrowUp, faSpinner, faTrash, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDropzone } from 'react-dropzone';
import FilesList from "../components/filesComponents/fileList";
import { createFilesFunction, fetchFiles } from "../utils/filesFunctions";
import UploadFilesModal from "../components/filesComponents/uploadFilesModal";

import withScrolling from 'react-dnd-scrolling';
import { deleteItemsFunction, fetchParentFolderFunction, moveFilesUpDirectoryFunction, updateItemsFuction } from "../utils/miscFunctions";
import FileEditor from "../components/filesComponents/fileEditor";
const ScrollingComponent = withScrolling('div');


const FoldersHome = ({ screenWidth, openSnack, userInfo, publicPortal }) => {
    const [searchQuery, setSearchQuery] = useState("");
    const [currentFolder, setCurrentFolder] = useState(null);
    const [currentFile, setCurrentFile] = useState(null);
    const [currentParentFolder, setCurrentParentFolder] = useState(null);
    const [folders, setFolders] = useState([]);
    const [files, setFiles] = useState([]);
    const [fecthingFolders, setFecthingFolders] = useState(false);
    const [folderBreadCrumbs, setFolderBreadCrumbs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filteredFolders, setFilteredFolders] = useState([]);
    const [filteredFiles, setFilteredFiles] = useState([]);
    const [draggingOver, setDraggingOver] = useState(false);
    const [uploadProgress, setUploadProgress] = useState({});
    const [uploadModal, setUploadModal] = useState(false);
    const [dragExisting, setDragExisting] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                window.scrollTo(0, 0)
                setLoading(true);
                let folders = await fetchFolders(userInfo, currentParentFolder, setFecthingFolders, openSnack, publicPortal);
                console.log(folders)
                const files = await fetchFiles(userInfo, currentParentFolder, setFecthingFolders, openSnack);




                // Use Promise.all to wait for all promises to resolve
                const foldersWithCounts = await Promise.all(folders.map(async (folder) => {
                    const imageCount = await countFolderImagesFunction(folder, userInfo);
                    const folderCount = await countFoldersFunction(folder, userInfo);
                    // Return a new object with the counts included
                    return {
                        ...folder,
                        imageCount,
                        folderCount
                    };
                }));

                foldersWithCounts.sort((a, b) => a.name.localeCompare(b.name));
                setFolders(foldersWithCounts);
                setFilteredFolders(foldersWithCounts);
                setFiles(files);
                setFilteredFiles(files);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false); // Ensure loading is set to false after operations complete
            }
        };

        setSelectedFiles([]);
        fetchData();
    }, [currentParentFolder]); // Only re-run the effect if currentParentFolder changes



    const updateFolder = (folder) => {
        const newFolders = folders.map((a) => {
            if (a.folder_id === folder.folder_id) {
                return folder;
            }
            return a;
        });
        setFolders(newFolders);
        updateFolderFunction(folder, userInfo, setCurrentFolder, openSnack);
    };

    const updateFile = async (file) => {
        const newFiles = files.map((a) => {
            if (a.file_id === file.file_id) {
                return file;
            }
            return a;
        });
        setFiles(newFiles);
        setCurrentFile(null)

        await updateItemsFuction('file', newFiles, userInfo, openSnack);
    };

    const createFolder = (folder) => {
        const newFolders = [folder, ...folders];
        setFolders(newFolders);
        createFolderFunction(folder, currentParentFolder, userInfo, setCurrentFolder, openSnack);
    };
    const deleteFolder = (folder) => {
        const newFolders = folders.filter((a) => a.folder_id !== folder.folder_id);
        setFolders(newFolders);
        deleteFolderFunction(folder, userInfo, setCurrentFolder, openSnack);
    };

    const updateParentId = (type, id, newParentFolderId) => {
        let updateEntity;
        let setEntity;

        // Determine the type and set the respective entities and state setters
        if (type === 'folder') {
            updateEntity = folders;
            setEntity = setFolders;
        } else if (type === 'file') {
            updateEntity = files;
            setEntity = setFiles;
        } else {
            // If the type is not recognized, handle the error
            console.error(`Unrecognized type: ${type}`);
            return;
        }

        // Filter out the entity with the given ID
        const newEntities = updateEntity.filter(entity => entity[`${type}_id`] !== id);
        setEntity(newEntities);

        // Call the update function with the correct parameters
        updateParentIdFunction(type, id, newParentFolderId, userInfo, setCurrentFolder, openSnack);
    };

    const moveUpDirectory = async (type, item) => {
        const parentFolder = await fetchParentFolderFunction(item.parent_folder_id, userInfo, openSnack);
        item.parent_folder_id = parentFolder.parent_folder_id;
        if (type === 'folder') {
            const newFolders = folders.filter((a) => a.folder_id !== item.folder_id);
            setFolders(newFolders);
            setCurrentParentFolder(parentFolder.parent_folder_id)
            setFolderBreadCrumbs(folderBreadCrumbs.slice(0, folderBreadCrumbs.length - 1))
            await updateItems('folder', [item]);
        }
        else if (type === 'file') {
            const newFiles = files.filter((a) => a.file_id !== item.file_id);
            setFiles(newFiles);
            setSelectedFiles((prev) => prev.filter((a) => a !== item.file_id))
            await updateItems('file', [item]);
        }



    }

    const moveFilesUpDirectory = async (selectedFiles) => {
        const stayFiles = files.filter((a) => !selectedFiles.includes(a.file_id));
        const newFiles = files.filter((a) => selectedFiles.includes(a.file_id)).map((file) => {
            return { ...file, parent_folder_id: currentParentFolder.parent_folder_id || null };
        });
        setFiles(stayFiles)
        setSelectedFiles([])
        await updateItemsFuction('file', newFiles, userInfo, openSnack);

    }


    const createFiles = async (newFiles) => {
        const lastFile = await createFilesFunction(newFiles, files, setFiles, currentParentFolder, userInfo, openSnack, setUploadProgress)



    }


    const updateItems = async (type, newItems) => {

        await updateItemsFuction(type, newItems, userInfo, openSnack);
    };
    const deleteItems = async (type, newItems) => {

        const newFiles = files.filter((a) => !newItems.includes(a.file_id));
        setFiles(newFiles);
        setSelectedFiles([])
        await deleteItemsFunction(type, newItems, userInfo, openSnack);
    };



    const props = {
        screenWidth, openSnack, userInfo, folders,
        setCurrentFolder,
        setCurrentParentFolder,
        folderBreadCrumbs,
        setFolderBreadCrumbs,
        deleteFolder,
        filteredFolders,
        setFilteredFolders,
        updateParentId,
        moveUpDirectory,
        currentFile,
        setCurrentFile,
        files,
        setFiles,
        filteredFiles,
        setFilteredFiles,
        uploadProgress,
        setUploadProgress,
        setUploadModal,
        setDragExisting,
        uploadModal,
        setUploadModal,
        selectedFiles,
        setSelectedFiles,
        updateItems,
        deleteItems,
        updateFile,
        setFolders,
        screenWidth,
        moveFilesUpDirectory,
        updateFolder,
        publicPortal

    };



    useEffect(() => {
        const filteredFolders = folders.filter((folder) => {
            if (searchQuery === "") {
                return true;
            } else if (folder.name.toLowerCase().includes(searchQuery.toLowerCase())) {
                return true;
            } else {
                return false;
            }
        });
        setFilteredFolders(filteredFolders);
    }, [searchQuery, folders]);









    if (loading) {
        return (
            <Box py={2}>
                <Grid container justifyContent="center">
                    <Grid item style={{ textAlign: 'center' }}>
                        <FontAwesomeIcon icon={faSpinner} spin size="xl" />
                        <Typography variant="h6" style={{ marginTop: 20 }}>
                            Loading your folder and files...
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        );
    }
    else {

        return (


            <Box >
                <Grid container alignItems="center" >
                    {publicPortal === false && (<Grid item xs={6} sm={6} md={3} sx={{ p: 1, mt: 1, }}  >
                        <CreateItem setCurrentFolder={setCurrentFolder} screenWidth={screenWidth} setUploadModal={setUploadModal} />
                    </Grid>
                    )}
                    <Grid item xs={6} sm={3} sx={{ p: 1, mt: 1, }} >
                        <SearchFolders searchQuery={searchQuery} setSearchQuery={setSearchQuery} screenWidth={screenWidth} />
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ textAlign: "right" }} sx={{ p: 1, mt: 1, }} >
                        <Box>
                            {selectedFiles.length > 0 && (
                                <IconButton variant="link"
                                    style={{ marginRight: 15 }}
                                    onClick={() => {
                                        if (publicPortal === true) {
                                            openSnack('error', 'You do not have permission to delete files')
                                            return
                                        }
                                        deleteItems('files', selectedFiles)
                                    }}>
                                    <Tooltip title="Delete">
                                        <Badge badgeContent={selectedFiles.length} color="error">
                                            <FontAwesomeIcon icon={faTrash} color="gray" size="xl" />
                                        </Badge>
                                    </Tooltip>
                                </IconButton>
                            )}
                            {selectedFiles.length > 0 && currentParentFolder !== null && (
                                <IconButton variant="link"
                                    style={{ marginRight: 15 }}
                                    onClick={() => {
                                        if (publicPortal === true) {
                                            openSnack('error', 'You do not have permission to move files')
                                            return
                                        }
                                        moveFilesUpDirectory(selectedFiles)
                                    }}>
                                    <Tooltip title="Move Up Directory">
                                        <Badge badgeContent={selectedFiles.length} color="success">
                                            <FontAwesomeIcon icon={faArrowAltCircleUp} color="gray" size="xl" />
                                        </Badge>
                                    </Tooltip>
                                </IconButton>
                            )}

                        </Box>
                    </Grid>
                </Grid>
                <Box pt={1}>
                    <Divider />
                </Box> 
                <Grid container>
                    <Grid item xs={12} sx={{ p: 1, mt: 1, }}>
                        <FolderBreadCrumbs {...props} />
                    </Grid>
                    <Grid item xs={12} sx={{ p: 1, mt: 1, }} >
                        {uploadModal && (<UploadFilesModal {...props}
                            createFiles={createFiles}

                        />)}

                        <DndProvider backend={HTML5Backend}>
                            <ScrollingComponent className="container">
                                <Typography style={{ marginLeft: 10, }} variant="h5" fontSize={16} fontWeight={700}>
                                    Folders
                                </Typography>

                                <FoldersList {...props} />
                                <Box style={{ backgroundColor: 'white' }}>
                                    <Typography style={{ marginLeft: 10, display: "inline" }} variant="h5" fontSize={16} fontWeight={700} >
                                        Files
                                    </Typography>
                                    <Button variant="link" style={{ marginLeft: 10, textTransform: 'none', display: "inline" }} onClick={() => {
                                        //select all files
                                        setSelectedFiles(files.map((file) => file.file_id))
                                    }
                                    }>
                                        <Typography variant="body1" fontSize={14} fontWeight={500} >
                                            Select All
                                        </Typography>
                                    </Button>
                                    <Button variant="link" style={{ marginLeft: 10, textTransform: 'none', display: "inline" }} onClick={() => {
                                        //select all files
                                        setSelectedFiles([])
                                    }
                                    }>
                                        <Typography variant="body1" fontSize={14} fontWeight={500} >
                                            Deselect All
                                        </Typography>
                                    </Button>


                                </Box>
                                <FilesList {...props} />
                            </ScrollingComponent>
                        </DndProvider>




                    </Grid>
                </Grid>
                <FolderEditor
                    currentFolder={currentFolder}
                    setCurrentFolder={setCurrentFolder}
                    updateFolder={updateFolder}
                    createFolder={createFolder}
                    openSnack={openSnack}
                    screenWidth={screenWidth}
                />
                <FileEditor
                    currentFile={currentFile}
                    setCurrentFile={setCurrentFile}
                    updateFile={updateFile}
                    openSnack={openSnack}
                    screenWidth={screenWidth}
                />
            </Box >


        );
    }
};

export default FoldersHome;
