// Layer.jsx
import React, { useEffect, useRef, useState } from 'react';
import { Typography, IconButton, Switch, Button, ClickAwayListener, Popper, Box, List, ListItem, Divider } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Col, Row } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faChevronDown, faChevronUp, faEdit, faEllipsisV, faGripVertical, faPlus } from '@fortawesome/free-solid-svg-icons';
import LayerModal from './LayerModal';
import CreateLayerItemButton from './CreateLayerItemButton';
import { createLayerItem, deleteLayerItem, updateLayerItem } from './utils';
import { ReactSortable } from 'react-sortablejs';
import { fullMobile } from '../settings/util';
import LayerItem from './LayerItem';

const Layer = ({ layer, handleDelete, handleToggle, company, widgetKey, handleToggleLayerItem, setLayers, getLayers, openSnack, userInfo, setCurrentDrawingLayer, currentDrawingLayer, currentLayer, 
    setCurrentLayer }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const anchorRef = useRef(null);
    

    useEffect(() => {
        setAnchorEl(anchorRef.current)
    }, [])


    const onLayerItemsEnd = (newOrder) => {
        handleToggle(layer.id, { layerItems: newOrder });
    };

    const handleDeleteLayerItem = async (itemId) => {
        const success = await deleteLayerItem(userInfo.currentCompany, widgetKey, layer.id, itemId, setLayers);
        if (success) {
            getLayers();
            openSnack('success', 'Layer deleted successfully');
        }
    };



 

    return (
        <Row style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            border: '1px solid rgba(0, 0, 0, 0.3)',
            backgroundColor: '#f5f5f5',
            paddingLeft: 5
        }}
            align="middle"
        >
            <Col span={2} className='handle' style={{ cursor: 'grab' }} >
                <FontAwesomeIcon icon={faGripVertical} style={{ marginRight: 10 }} />
            </Col>
            <Col span={1}>
                <Divider orientation="vertical" flexItem sx={{ color: 'black' }} />
            </Col>
            <Col span={21} style={{ paddingTop: 8 }}>
                <Row align="middle">
                    <Col span={4}>
                        <Switch
                            size='small'
                            checked={layer.visible}
                            onChange={() => {
                                handleToggle(layer.id, { visible: !layer.visible })

                            }}
                        />
                    </Col>
                    <Col span={20} >
                        <Typography variant="body1" fontSize={18} fontWeight={600}>
                            {layer.name}
                        </Typography>
                    </Col>

                    <Col>
                        <CreateLayerItemButton onClick={() => createLayerItem(company, widgetKey, layer.id, setLayers, layer.layerItems.length)} />

                    </Col>
                    <Col>
                        <IconButton
                            color="inherit"
                            ref={anchorRef}

                            onClick={(e) => {
                                console.log(!isOpen)
                                setIsOpen(!isOpen)
                            }}
                        >
                            <Typography variant='body1' fontSize={16} fontWeight={400} color='black'>
                                <FontAwesomeIcon icon={faEllipsisV} />
                            </Typography>
                        </IconButton>

                    </Col>
                    <Col>
                        <IconButton
                            color="inherit"
                            onClick={(e) => {
                                handleToggle(layer.id, { expanded: !layer.expanded })
                            }}
                        >
                            <Typography variant='body1' fontSize={16} fontWeight={400} color='black'>
                                <FontAwesomeIcon
                                    icon={faChevronDown}
                                    style={{
                                        transition: 'transform 0.2s ease-in-out',
                                        transform: layer.expanded ? 'rotate(0deg)' : 'rotate(180deg)',
                                    }}
                                />
                            </Typography>
                        </IconButton>

                    </Col>

                </Row>



            </Col>

            <Col {...fullMobile(24, 24)} style={{ paddingTop: 5 }}>
                {layer.expanded && (
                    <Divider orientation="horizontal" flexItem sx={{ color: 'black' }} />
                )}
            </Col>
            <Col {...fullMobile(24, 24)} style={{ paddingTop: 5, paddingLeft: 10 }}>
                {layer.expanded && (
                    <Typography variant="body1" fontSize={16} fontWeight={600}>
                        Items
                    </Typography>
                )}
            </Col>
            <Col {...fullMobile(24, 24)} style={{ paddingTop: 5, paddingLeft: 10, paddingRight: 5, paddingBottom: 10, }}>
                {layer.expanded && (
                    <ReactSortable
                        list={layer.layerItems}
                        setList={(newOrder) => onLayerItemsEnd(newOrder)}
                        handle=".handle"
                        animation={150}
                    >
                        {layer.layerItems.map((item) => (
                           
                            <LayerItem key={item.id} item={item} userInfo={userInfo} currentLayer={currentLayer} setCurrentLayer={setCurrentLayer} layer={layer}
                                handleDeleteLayerItem={handleDeleteLayerItem}  openSnack={openSnack}
                                setCurrentDrawingLayer={setCurrentDrawingLayer} 
                                currentDrawingLayer={currentDrawingLayer}
                                handleToggleLayerItem={handleToggleLayerItem} setLayers={setLayers} company={userInfo.currentCompany} widgetKey={widgetKey} />
                        ))}
                    </ReactSortable>
                )}
                {layer.expanded && layer.layerItems.length === 0 && (
                    <Typography variant="body1" fontSize={16} fontWeight={500}>
                        No items
                    </Typography>
                )}
            </Col>












            <LayerModal isOpen={isOpen} layer={layer} handleToggle={handleToggle} setOpen={setIsOpen} handleDelete={handleDelete} />


        </Row>
    );
};

export default Layer;
