import React, { useState } from 'react';
import { Box, Tabs, Tab, List, ListItemButton, ListItemIcon, ListItemText, Collapse, ListSubheader, Typography } from '@mui/material';

import { styled } from '@mui/material/styles';
import ToolCollapseComponent from '../tabTools/toolCollapseComponent';



const TabBox = (props) => {
    return (
        <Box sx={{
            marginTop: 2,
           
            border: '#676767'
        }}>
            <ToolCollapseComponent toolName="select" toolLabel="Select" {...props} />
            {/* <ToolCollapseComponent toolName="transform" toolLabel="Transform" {...props} /> */}
            <ToolCollapseComponent toolName="tooltip" toolLabel="Tooltip" {...props} />
        </Box>
    );
};


export default TabBox;
