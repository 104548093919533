import { Autocomplete, Button, Divider, FormControl, IconButton, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { fullMobile } from '../util';

import AddIcon from '@mui/icons-material/Add';
import uuid from 'react-uuid';

import { DeleteOutline, DragIndicator } from '@mui/icons-material';
import { ReactSortable } from 'react-sortablejs';
import ProbeParameters from '../../../extra/probeParameters.json';
import { queryEquipment, queryParameters, queryProbes, queryStations } from '../../../firebase/config';
import { InspectionHeaderInfo, AddInspectionItem } from './inspectionHeaderInfo';
import { InspectionTable } from './inspectionTable';
var randomColor = require('randomcolor');



export default function InspectionItemHome(props) {
    const { userInfo, screenWidth, openSnack, company, account, itemRef, updateItem } = props;
    const [item, setItem] = useState({});
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [inspectionItems, setInspectionItems] = useState([]);
    const [PPArray, setPPArray] = useState([]);
    const [parameters, setParameters] = useState([]);
    const [nodes, setNodes] = useState([]);
    const [probes, setProbes] = useState([]);
    const [equipment, setEquipment] = useState([]);
    useEffect(() => {

        getInfo()
    }, [])

    useEffect(() => {
        if (itemRef) {
            setItem(itemRef);
            setInspectionItems(itemRef.inspectionItems !== undefined ? JSON.parse(itemRef.inspectionItems) : [])

        }
    }, [itemRef])

    async function getInfo() {
        const PPArray = Object.values(ProbeParameters).map((p, i) => {
            return ({
                label: p,
                key: Object.keys(ProbeParameters)[i]
            })
        });
        setPPArray(PPArray)
        const parameters = await queryParameters(userInfo)
        setParameters(parameters)
        const nodes = await queryStations(userInfo, ['grab', 'both', 'sensor'])
        console.log(nodes)
        setNodes(nodes)

        const probes = await queryProbes(userInfo)

        setProbes(probes)

        const equipment = await queryEquipment(userInfo)
        setEquipment(equipment)





    }









    function updateInsepctionItem(index, key, value) {
        let newInspectionItems = [...inspectionItems];
        newInspectionItems[index][key] = value;
        setInspectionItems(newInspectionItems);
        updateItem('inspectionItems', JSON.stringify(newInspectionItems))
    }



    return (
        <Row gutter={[4, 4]} align="middle">
            <Col span={24}>
                <InspectionHeaderInfo inspectionItems={inspectionItems} />
            </Col>

            <Col {...fullMobile(24, 24)} style={{ paddingBottom: 5 }}>
                <InspectionTable inspectionItems={inspectionItems} openSnack={openSnack} userInfo={userInfo} updateInsepctionItem={updateInsepctionItem} setInspectionItems={setInspectionItems} item={item} 
                equipment={equipment} parameters={parameters} nodes={nodes} probes={probes} PPArray={PPArray}
                 />
            </Col >

            <Col {...fullMobile(24, 24)} style={{ paddingTop: 10, paddingBottom: 40 }}>
                <AddInspectionItem inspectionItems={inspectionItems} userInfo={userInfo} setInspectionItems={setInspectionItems} />
            </Col>




            <Col {...fullMobile(24, 24)} style={{ paddingTop: 5, paddingBottom: 5 }}>
                <Divider />
            </Col>



        </Row >
    )



}