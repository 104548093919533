import React, { useEffect, useState } from "react";


import { Divider, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import { collection, collectionGroup, doc, getDoc, getDocs, getFirestore, limit, onSnapshot, orderBy, query, setDoc, updateDoc, where, writeBatch } from "firebase/firestore";
import moment from 'moment-timezone';
import uuid from "react-uuid";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getCompanyPreferences, getLatestdata, getStreamPreferences, getStreams, updateStreamPreferences } from "../streamFunctions";
import StreamDrawer from "../streamDrawer";
import MobileStreamsTable from "../mobileStreamsTable";
import StreamsTable from "../streamTable";
import StreamsTableHeader from "../streamTableHeader";
import ProbeParameters from '../../../extra/probeParameters.json';
import ReturnToDataStreams from "../returnToStreamsButton";
import axios, * as others from 'axios';
import PreviewChartModal from "../previewChartModal";



const CamerasHome = ({
    screenWidth,
    userInfo,
    account,
    openSnack
}) => {

    const [listKey, setListKey] = useState('timelapseCameras');
    const [prefKey, setPrefKey] = useState('camera');
    const [activeTab, setActiveTab] = useState('streams');
    const [sortType, setSortType] = useState('');

    const [streams, setStreams] = useState([]);
    const [streamTemplates, setStreamTemplates] = useState([]);
    const [gettingData, setGettingData] = useState(false);
    const [loading, setLoading] = useState(true);
    const [currentStream, setCurrentStream] = useState(null);
    const [streamDrawer, setStreamDrawer] = useState(false);
    const [bulkEditor, setBulkEditor] = useState(false);
    const [columnEditor, setColumnEditor] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [loadingMeta, setLoadingMeta] = useState(true);
    const [defaultPreferences, setdefaultPreferences] = useState({
        sortItem: null,
        columnFilters: {},
        showInactive: true,
        showArchived: false,

    });
    const [preferences, setPreferences] = useState({});
    const [previewChart, setPreviewChart] = useState(false);

    const [defaultCompanyPreferences, setDefaultCompanyPreferences] = useState({

        headers: [
            { position: 0, headerName: "Label", id: "name", getLabel: false, width: 200, visible: true, minWidth: 130, type: 'string', mobile: true, mobileWidth: '60%', optionType: 'string' },
            { position: 2, headerName: "Last Recorded", id: "lastRecorded", getLabel: false, width: 130, visible: true, minWidth: 120, type: 'date', optionType: 'none' },




        ]
    })
    const [companyPreferences, setCompanyPreferences] = useState({});


    useEffect(() => {
        window.scrollTo(0, 0)
        getInfo()



        /*  updateStreamPreferences(userInfo, defaultPreferences, null, prefKey)
         updateStreamPreferences(userInfo, defaultCompanyPreferences, 'company', prefKey) */


    }, [])



    async function getInfo() {




        const streamPreferences = await getStreamPreferences(userInfo, prefKey);
        setPreferences(streamPreferences === 'none' ? defaultPreferences : streamPreferences)
        if (streamPreferences === 'none') {
            updateStreamPreferences(userInfo, defaultPreferences, prefKey)
        }

        const companyPreferences = await getCompanyPreferences(userInfo, prefKey);
        setCompanyPreferences(companyPreferences === 'none' ? defaultCompanyPreferences : companyPreferences)
        if (defaultCompanyPreferences === 'none') {
            updateStreamPreferences(userInfo, defaultCompanyPreferences, 'company', prefKey)
        }
        setLoading(false)

        const streams = await getStreams(userInfo, listKey);
        console.log(streams);
        setStreams(streams);

        const proxyurl = "https://mycorslake.herokuapp.com/";

        const db = getFirestore();

        // Using Promise.all to handle multiple asynchronous operations
        const updatedStreamsPromises = streams.map(async s => {

            const ref = collection(db, "clients", userInfo.currentCompany, 'timelapseImages');

            let queryRef;
            queryRef = query(ref, where('itemID', "==", s.id), orderBy("date", "desc"), limit(1));


            const snap = await getDocs(queryRef);
            const data = snap.docs.map((doc) => doc.data());


            const lastRecorded = data[0];
            console.log(lastRecorded)
            return {
                ...s,
                lastRecorded: lastRecorded.date ? moment(lastRecorded.date, 'YYYY-MM-DD HH:mm').format('x') : null,
            };
        });

        // Wait for all promises to resolve
        const updatedStreams = await Promise.all(updatedStreamsPromises);
        console.log(updatedStreams)
        setStreams(updatedStreams);
        setLoadingMeta(false);



    }


    async function updateStreamsPosition(streams) {
        console.log(streams)
        setStreams(streams)
        const db = getFirestore();
        streams?.forEach(async (stream, i) => {
            await updateDoc(doc(db, 'clients', userInfo.currentCompany, listKey, stream.id), { position: i })
        })
    }





    async function deleteStream(stream) {
        const db = getFirestore();
        await updateDoc(doc(db, 'clients', userInfo.currentCompany, listKey, stream.id), { archived: true });
        openSnack('success', 'Stream Deleted')
        const updatedStreams = streams?.filter((t) => t.id !== stream.id)
        updateStreamsPosition(updatedStreams)
        setCurrentStream(null)
        setStreamDrawer(false)

    }

    async function deleteSelectedStreams(deleteStreams) {

        const db = getFirestore();

        // Using batch to perform multiple updates in one go for efficiency
        const batch = writeBatch(db);

        // Iterate over streams to delete and add them to the batch
        deleteStreams.forEach(stream => {
            const streamRef = doc(db, 'clients', userInfo.currentCompany, listKey, stream.id);
            batch.update(streamRef, { archived: true });
        });

        // Commit the batch
        await batch.commit();

        openSnack('success', `${deleteStreams.length} Stream(s) Deleted`);

        // Filter out the deleted streams from the local state
        const updatedStreams = streams?.filter(t => !deleteStreams.some(dt => dt.id === t.id));

        updateStreamsPosition(updatedStreams);
        setCurrentStream(null);
        setStreamDrawer(false);
    }


    async function duplicateStream(t) {
        const stream = { ...t };
        stream.id = uuid();
        stream.creator = userInfo.id;
        stream.name = `${stream.name} (Copy)`;
        stream.created = moment().format('x');

        const db = getFirestore();
        await setDoc(doc(db, 'clients', userInfo.currentCompany, listKey, stream.id), stream);
        openSnack('success', 'Stream Duplicated');
        setStreamDrawer(false);

        const newStreams = [...streams];
        const originalStreamIndex = newStreams.findIndex(stream => stream.id === t.id);
        newStreams.splice(originalStreamIndex + 1, 0, stream);
        updateStreamsPosition(newStreams);
    }



    async function convertToTemplate(stream) {
        const db = getFirestore();
        const newStream = { ...stream };
        newStream.template = true;
        await setDoc(doc(db, 'clients', userInfo.currentCompany, `${listKey}_templates`, newStream.id), newStream);
        const newStreamTemplates = [...streamTemplates, newStream];
        setStreamTemplates(newStreamTemplates);
        openSnack('success', 'Stream Converted to Template')
        setCurrentStream(null)
        setStreamDrawer(false)

    }

    async function deleteStreamTemplate(stream) {
        const db = getFirestore();
        await updateDoc(doc(db, 'clients', userInfo.currentCompany, `${listKey}_templates`, stream.id), { archived: true });
        openSnack('success', 'Stream Template Deleted')
        const updatedStreamTemplates = streamTemplates?.filter((t) => t.id !== stream.id)
        setStreamTemplates(updatedStreamTemplates)
        setCurrentStream(null)
        setStreamDrawer(false)
    }





    async function createStream(stream) {
        const db = getFirestore();
        const baseStream = {
            id: uuid(),
            creator: userInfo.id,
            account: userInfo.currentAccount,
            company: userInfo.currentCompany,
            created: moment().format('x'),
            archived: false,
            inactive: false,
            inactivatedBy: null,
            archivedBy: null,
            template: false,
            ...stream  // overwrite with actual stream details
        };


        await setDoc(doc(db, 'clients', userInfo.currentCompany, listKey, baseStream.id), baseStream);
        updateStreamsPosition([baseStream, ...streams]);
        const resposne = await getLatestdata(userInfo, prefKey, openSnack, setGettingData);

        openSnack('success', 'Stream Created');
        setStreamDrawer(false);
    }



    async function updateStream(stream, type) {
        if (stream?.id) {

            console.log(stream)

            stream.lastUpdated = moment().format('x');
            const db = getFirestore();
            await updateDoc(doc(db, 'clients', userInfo.currentCompany, listKey, stream.id), {
                ...stream,
                nodeid: stream.nodeid || null,
            });
            if (type === "inactive") {
                openSnack('success', 'Stream Inactive!')
            }
            else {
                openSnack('success', 'Stream Updated')
            }
            setStreamDrawer(false)
            const updatedStreams = streams.map((t) => {
                if (t.id === stream.id) {
                    return stream
                } else {
                    return t
                }
            })
            setStreams(updatedStreams)
        }
        else {
            setCurrentStream(null)
            setStreamDrawer(false)
        }
    }



    async function updateMultipleStreams(selectedStreams, bulkStream) {
        const db = getFirestore();
        const batch = writeBatch(db);

        // Iterate through each selected stream.
        selectedStreams.forEach(stream => {
            const streamRef = doc(db, 'clients', userInfo.currentCompany, listKey, stream.id);

            // For each stream, iterate through each property in bulkStream and update it.
            for (const [key, value] of Object.entries(bulkStream)) {
                batch.update(streamRef, { [key]: value });
            }
        });

        await batch.commit();
        openSnack('success', 'Streams Updated!');

        // Update local state for the streams.
        const updatedStreams = streams.map(stream => {
            if (selectedStreams.some(selectedStream => selectedStream.id === stream.id)) {
                return { ...stream, ...bulkStream };  // Spreading in bulkStream will overwrite properties in stream.
            }
            return stream;
        });
        setStreams(JSON.parse(JSON.stringify(updatedStreams)));
    }





    const getNestedProperty = (obj, path, getLabel, optionType, options, decimals) => {


        const firstValue = path?.split('.').reduce((acc, part) => acc && acc[part], obj);
        const value = getLabel === true ? firstValue?.label : firstValue;


        if (!optionType || !options) {
            return value;
        }

        if (optionType === 'money') {
            return value !== undefined ? Number(value)?.toFixed(2) : value;
        }
        if (optionType === 'colorSelect') {
            console.log(value)
            return value;
        }
        if (optionType === 'number') {
            return value !== undefined ? Number(value)?.toFixed(decimals) : value;
        }
        if (optionType === 'percent') {
            return value !== undefined ? `${Number(value)}%` : value;
        }

        if (optionType === 'singleSelect') {
            const matchingOption = options.find(option => option.id === value);
            return matchingOption ? matchingOption.name : value;
        }

        if (optionType === 'multiSelect' && Array.isArray(value)) {
            const matchingOptions = value.map(id => {
                const option = options.find(option => option.id === id);
                return option ? option.name : id;
            });
            // Return as comma-separated string, if you want it as an array just return `matchingOptions`
            return matchingOptions.join(', ');
        }

        return value;
    };


    const applyFiltersAndSearch = (streams, searchQuery, headers, columnFilters, showInactive) => {


        // Remove any columnFilter that has an array of 0
        Object.keys(columnFilters).forEach(key => {
            if (columnFilters[key].length === 0) {
                delete columnFilters[key];
            }
        });

        // Start by filtering only visible headers
        const visibleHeaders = headers?.filter(header => header.visible);

        // Now, consider column filters and the search query
        return streams?.filter(stream => {

            if (!showInactive && stream.inactive) {
                return false;
            }

            const searchMatch = visibleHeaders?.some(header => {

                const value = getNestedProperty(stream, header.id, header.getLabel, header?.optionType, header?.options, header?.decimals);
                let searchValue;
                if (header.type === 'date' && value !== undefined) {
                    searchValue = moment(value, 'x').format(
                        screenWidth === 'xs' ? 'MMM DD, YY ha' : 'MM/DD/YY ha'
                    );
                } else {
                    searchValue = value !== undefined ? value : "No Value"; // Adjust here
                }

                return (
                    searchValue.toString().toLowerCase().includes(searchQuery.toLowerCase())
                );
            });

            const columnFilterMatch = visibleHeaders?.every(header => {
                const value = getNestedProperty(stream, header.id, header.getLabel, header?.optionType, header?.options, header?.decimals);

                let filterValue;
                if (header.type === 'date' && value !== undefined) {
                    filterValue = moment(value, 'x').format(
                        screenWidth === 'xs' ? 'MMM DD, YY ha' : 'MM/DD/YY ha'
                    );
                } else {
                    filterValue = value !== undefined ? value : "No Value"; // Adjust here
                }

                return columnFilters[header.id]
                    ? columnFilters[header.id].includes(filterValue)
                    : true;
            });

            return searchMatch && columnFilterMatch;
        });
    };


    function deleteHeader(header) {
        const newHeaders = companyPreferences?.headers?.filter((h) => h.id !== header.id)
        const newPreferences = {
            ...preferences,
            headers: newHeaders
        };
        setCompanyPreferences(newPreferences);
        // Update Firestore
        updateStreamPreferences(userInfo, newPreferences, 'company', prefKey);
    }



    const filteredStreams = applyFiltersAndSearch(
        streams,
        searchQuery,
        companyPreferences?.headers,
        preferences?.columnFilters || {},
        preferences?.showInactive
    );





    const props = {
        activeTab,
        setActiveTab,
        streams,
        setStreams,
        loading,
        setLoading,
        currentStream,
        setCurrentStream,
        userInfo,
        account,
        openSnack,
        screenWidth,
        deleteStream,
        updateStream,
        createStream,
        streamDrawer,
        setStreamDrawer,
        duplicateStream,
        updateStreamsPosition,
        preferences,
        setPreferences,
        searchQuery,
        setSearchQuery,
        getNestedProperty,
        duplicateStream,
        convertToTemplate,
        deleteSelectedStreams,
        bulkEditor,
        setBulkEditor,
        updateMultipleStreams,
        streamTemplates,
        deleteStreamTemplate,
        columnEditor,
        setColumnEditor,
        deleteHeader,
        ProbeParameters,
        gettingData,
        setGettingData,
        loadingMeta,





    }


    if (loading) {
        return (
            <Row style={{ height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
                {/* Using the full height of the viewport to center content */}
                <Col span={24} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

                    {/* FontAwesome Spinner */}
                    <FontAwesomeIcon icon={faSpinner} spin size="3x" />

                    <Typography variant="h6" style={{ marginTop: 20 }}>
                        Loading your streams...
                    </Typography>

                </Col>
            </Row>
        )
    }






    else {
        return (
            <Row style={{}} >
                <Col span={24} style={{}}>
                    <Row align="middle">
                        <Col xs={24} sm={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 5 }}>
                            <Typography variant="h4" fontWeight={600} fontSize={30} color="black">Time Lapse Cameras</Typography>
                        </Col>
                        <Col xs={24} sm={20} style={{ paddingLeft: 20, }}>
                            <ReturnToDataStreams />
                        </Col>

                        <Col span={24}>
                            <StreamsTableHeader {...props}

                            />
                        </Col>


                        {activeTab === 'streams' && screenWidth !== 'xs' && (
                            <Col span={24}>
                                <StreamsTable  {...props} prefKey={prefKey}
                                    sortItem={preferences?.sortItem}
                                    setSortItem={(newSortItem) => {
                                        const newPreferences = {
                                            ...preferences,
                                            sortItem: newSortItem
                                        };

                                        setPreferences(newPreferences);
                                        // Update Firestore
                                        updateStreamPreferences(userInfo, newPreferences, null, prefKey);

                                    }}
                                    showInactive={preferences?.showInactive}
                                    setShowInactive={(e) => {
                                        const newPreferences = {
                                            ...preferences,
                                            showInactive: e
                                        };
                                        setPreferences(newPreferences);
                                        // Update Firestore
                                        updateStreamPreferences(userInfo, newPreferences, null, prefKey);
                                    }}
                                    selectable={preferences?.selectable}
                                    setSelectable={(e) => {
                                        const newPreferences = {
                                            ...preferences,
                                            selectable: e
                                        };
                                        setPreferences(newPreferences);
                                        // Update Firestore
                                        updateStreamPreferences(userInfo, newPreferences, null, prefKey);
                                        //make all streams unselected
                                        const updatedStreams = streams.map((stream) => {
                                            return (
                                                {
                                                    ...stream,
                                                    selected: false
                                                }
                                            )
                                        })
                                        setStreams(updatedStreams)
                                    }}



                                    streams={filteredStreams}
                                    fullStreams={streams}
                                    columnFilters={preferences?.columnFilters}
                                    setColumnFilters={(newFilters) => {
                                        const newPreferences = {
                                            ...preferences,
                                            columnFilters: newFilters
                                        };
                                        setPreferences(newPreferences);
                                        // Update Firestore
                                        updateStreamPreferences(userInfo, newPreferences, null, prefKey);
                                    }}
                                    headers={companyPreferences?.headers}
                                    setHeaders={(newHeaders) => {
                                        const newPreferences = {
                                            ...preferences,
                                            headers: newHeaders
                                        };
                                        setCompanyPreferences(newPreferences);
                                        // Update Firestore
                                        updateStreamPreferences(userInfo, newPreferences, 'company', prefKey);
                                    }}

                                    openChart={(stream) => {

                                        setPreviewChart(stream)


                                    }}




                                />
                            </Col>
                        )}
                        {activeTab === 'streams' && screenWidth === 'xs' && (
                            <Col span={24}>
                                <MobileStreamsTable  {...props}
                                    sortItem={preferences?.sortItem}
                                    setSortItem={(newSortItem) => {
                                        const newPreferences = {
                                            ...preferences,
                                            sortItem: newSortItem
                                        };
                                        setPreferences(newPreferences);
                                        // Update Firestore
                                        updateStreamPreferences(userInfo, newPreferences, null, prefKey);

                                    }}
                                    showInactive={preferences?.showInactive}
                                    setShowInactive={(e) => {
                                        const newPreferences = {
                                            ...preferences,
                                            showInactive: e
                                        };
                                        setPreferences(newPreferences);
                                        // Update Firestore
                                        updateStreamPreferences(userInfo, newPreferences, null, prefKey);
                                    }}
                                    headers={companyPreferences?.headers}
                                    streams={filteredStreams}
                                    fullStreams={streams}
                                    columnFilters={preferences?.columnFilters}
                                    setColumnFilters={(newFilters) => {
                                        const newPreferences = {
                                            ...preferences,
                                            columnFilters: newFilters
                                        };
                                        setPreferences(newPreferences);
                                        // Update Firestore
                                        updateStreamPreferences(userInfo, newPreferences, null, prefKey);
                                    }}

                                    setHeaders={(newHeaders) => {
                                        const newPreferences = {
                                            ...preferences,
                                            headers: newHeaders
                                        };
                                        setCompanyPreferences(newPreferences);
                                        // Update Firestore
                                        updateStreamPreferences(userInfo, newPreferences, 'company', prefKey);
                                    }}
                                />
                            </Col>
                        )}




                        <StreamDrawer {...props} headers={companyPreferences?.headers}
                            prefKey={prefKey}
                            setHeaders={(newHeaders) => {
                                const newPreferences = {
                                    ...companyPreferences,
                                    headers: newHeaders
                                };
                                setCompanyPreferences(newPreferences);
                                // Update Firestore
                                updateStreamPreferences(userInfo, newPreferences, 'company', prefKey);
                            }}
                            setStreamTemplates={setStreamTemplates}
                            streamTemplates={streamTemplates}

                        />










                    </Row>
                </Col >

            </Row >
        );
    }

};

export default CamerasHome;
