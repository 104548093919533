import EditIcon from '@mui/icons-material/Edit';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { Autocomplete, Breadcrumbs, Button, Checkbox, CircularProgress, Divider, Input, List, ListItemButton, ListItemIcon, ListItemText, OutlinedInput, Paper, TextField, Typography } from '@mui/material';
import { Col, Drawer, Modal, Row } from 'antd';
import { collection, doc, getDoc, getDocs, getFirestore, onSnapshot, query, setDoc, updateDoc, where } from 'firebase/firestore';
import moment from 'moment';
import React from 'react';
import { AlphaPicker, GithubPicker } from 'react-color';
import { Link } from 'react-router-dom';
import uuid from 'react-uuid';
import ApplicationInfo from './applicationComponents/applicationInfo';
import MyApp from './applicationComponents/appMap';
import CheckTimeWeather from './applicationComponents/checkTimeWeather';
import FooterButtons from './applicationComponents/footerButtons';
import ControlsInfo from './applicationComponents/gatesControl';
import GeneralInfo from './applicationComponents/generalInfo';
import WaterConditions from './applicationComponents/waterConditions';
import { fullMobile } from './util';
var randomColor = require('randomcolor');

export default class Application extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            company: this.props.company,
            doc: {},
            loading: true,
            preview: false,
            spinning: { on: false, label: '' },
            distributionList: [],
            showDistribution: false,
            colorModal: false,
            modalIndex: undefined,
            printScreen: false,
            accounts: [],
            updateWeather: false
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
        if (this.props.company !== prevProps.company) {
            this.setState({ company: this.props.company })
        }

    }
    componentDidMount() {
        window.scrollTo(0, 0)
        const { userInfo } = this.state;
        this.queryDataDoc(userInfo);
        this.queryAccounts(userInfo);
    }

    queryDataDoc = async (userInfo) => {
        const { type } = this.state;
        const db = getFirestore();
        const docRef = doc(db, "users", userInfo.id, 'currentItems', 'currentFieldDoc');
        const docSnap = await getDoc(docRef);

        console.log(docSnap.data())

        if (docSnap.data() === undefined || docSnap.data().key === 'none') {
            console.log("SET DOC")
            this.setState({
                doc: {
                    tempKey: uuid(),
                    status: 'edit',
                    archived: false,
                    type: 'applicationLog',
                    company: userInfo.currentCompany,
                    startDate: moment().format('x'),
                    preCloseTime: moment().format('x'),
                    endDate: moment().add(1, 'hours').format('x'),
                    postOpenTime: moment().add(1, 'hours').format('x'),
                    controlsApplicable: false,
                    graphics: JSON.stringify([]),
                    layerColor: randomColor({
                        format: 'rgba',
                        alpha: 0.5 // e.g. 'rgba(9, 1, 107, 0.6482447960879654)'
                    })
                }, loading: false
            })

        }
        else {
            this.getDocItem(docSnap.data().key)
        }
    }
    getDocItem = async (key) => {
        const { userInfo } = this.state;
        const db = getFirestore();
        const docRef = doc(db, "clients", userInfo.currentCompany, 'fieldDocs', key);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {

            this.setState({ doc: docSnap.data(), loading: false, preview: docSnap.data().status === 'edit' ? false : true })

        }
    }

    queryDistribution = async () => {
        const { userInfo, type } = this.state;
        const db = getFirestore()
        const queryStaff = query(collection(db, "users",), where('accounts', 'array-contains', userInfo.currentAccount));
        const users = [];
        const snapStaff = await getDocs(queryStaff);
        snapStaff.forEach((doc) => {
            const item = doc.data();
            delete item.accounts;
            delete item.companies;
            users.push({ firstName: item.firstName, lastName: item.lastName, id: item.id, email: item.email })

        });



        const distributionList = []

        const queryStaffList = query(collection(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'staff',));
        const staffSnap = await getDocs(queryStaffList);
        staffSnap.forEach((doc) => {


            const user = users.filter((u) => u.id === doc.data().key)[0];

            const item = { ...user, flDistribution: doc.data().flDistribution };
            distributionList.push(item)

        });

        const queryCustom = query(collection(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'customDistribution',));
        const snapCustom = await getDocs(queryCustom);
        snapCustom.forEach((doc) => {
            distributionList.push(doc.data())

        });



        this.setState({ distributionList, })

    }

    updateDoc = (boo, val) => {
        const { doc } = this.state;
        doc[boo] = val;
        this.setState({ doc, preview: doc.status === 'edit' ? false : true })
    }

    updateEntireDoc = (value) => {
        this.setState({ doc: value, preview: value.status === 'edit' ? false : true })
    }

    addProduct = () => {
        const { doc } = this.state;

        const product = {
            key: uuid(),
            product: '',
            psType: 'productApplication',
            itemType: 'productApplication',
            archived: false

        }
        if (doc.products === undefined) {
            console.log('ADD PRODUCT')
            doc.products = [product];
            console.log(doc)
            this.setState({ doc: { ...doc } })
        }
        else {
            doc.products.push(product);
            this.setState({ doc: { ...doc } })
        }
    }

    createLog = async (status) => {


        const { userInfo, company, account, loading } = this.state;


        function getFirstLetters(str) {
            const firstLetters = str
                .split(' ')
                .map(word => word[0])
                .join('');

            return firstLetters;
        }
        console.log(this.state.doc);
        this.setState({ spinning: { on: true, label: 'Creating Item' } })


        const db = getFirestore();
        const docRef = doc(db, "clients", userInfo.currentCompany, 'fieldDocs', this.state.doc.key);


        await updateDoc(docRef, {
            status: status
        }).then(() => {

            if (this.state.doc.products !== undefined) {
                this.state.doc.products.map(async (l) => {

                    var dateFormat = moment(this.state.doc.startDate, 'x').format('MM/DD/YYYY');
                    var timeFormat = moment(this.state.doc.startDate, 'x').format('h:mm a');

                    var dateTime = moment(dateFormat + ' ' + timeFormat, 'MM/DD/YYYY h:mm a').format('x');
                    const LogID = getFirstLetters(company.companyName) + "-" + getFirstLetters(this.state.doc.account.accountName) + "-" + moment(this.state.doc.startDate, 'x').format('MM-DD-YYYY') + "-" + "APP";

                    const totalArea = loading === false && this.state.doc.graphics !== undefined ? JSON.parse(this.state.doc.graphics).length === 0 ? 0 : Number(Number(JSON.parse(this.state.doc.graphics).map((g) => !['point'].includes(g.attributes.shapeType) ? g.attributes.area : 0).reduce((partialSum, a) => partialSum + a, 0)).toFixed(2)).toLocaleString() : 0;
                    const totalPerimeter = loading === false && this.state.doc.graphics !== undefined ? JSON.parse(this.state.doc.graphics).length === 0 ? 0 : Number(Number(JSON.parse(this.state.doc.graphics).map((g) => !['point'].includes(g.attributes.shapeType) ? g.attributes.length : 0).reduce((partialSum, a) => partialSum + a, 0)).toFixed(0)).toLocaleString() : 0;


                    const line = {
                        ...l,
                        totalArea,
                        totalPerimeter,
                        weather: this.state.doc.weather,
                        date: dateTime, docKey: this.state.doc.key,
                        account: this.state.doc.account.key,
                        company: userInfo.currentCompany,
                        productKey: l.product !== undefined ? l.product.key : '',
                        logID: LogID
                    }
                    const docRef = doc(db, "clients", userInfo.currentCompany, 'flItems', line.key);
                    await setDoc(docRef, line)

                })
            }



        }).then(() => {
            this.updateDoc('status', 'created');
            this.setState({ spinning: { on: false, label: '' } })
            this.props.openSnack('success', "Log is officially created!")
        })



    }

    updateStatus = async (status) => {
        const { userInfo, } = this.state;
        const db = getFirestore();
        const docRef = doc(db, "clients", userInfo.currentCompany, 'fieldDocs', this.state.doc.key);
        await updateDoc(docRef, {
            status: status
        }).then(() => {
            this.props.openSnack('success', "Status has been changed!")
        })


    }

    updateDistribution = (index, boo, val) => {
        const { distributionList } = this.state;

        distributionList[index][boo] = val;
        this.setState({ distributionList })

    }

    queryAccounts = async () => {
        const { userInfo, type } = this.state;
        const db = getFirestore();


        const q = query(collection(db, "clients", userInfo.currentCompany, 'accounts'), where('archived', '==', false));
        const qPublic = query(collection(db, "clients", userInfo.currentCompany, 'accounts'), where('archived', '==', false), where('publicAccount', '==', true));

        const unsubscribe = onSnapshot(type === 'publice' ? qPublic : q, (snap) => {
            const data = [];
            snap.forEach((doc) => {

                if (type === 'public') {
                    data.push({
                        accountName: doc.data().accountName,
                        address: doc.data().address,
                        label: doc.data().accountName,
                        key: doc.data().key,
                        type: doc.data().seasonal || null,
                        city: doc.data().city || null,
                        countyName: doc.data().countyName || null,
                        state: doc.data().state || null,
                        routeDay: doc.data().routeDay || null,
                        routeName: doc.data().routeName || null,
                        visible: true
                    })
                }
                else {
                    if (["EuEZ93ByFZYs8bSUXoYsTt1zu0m2", 'eTevYsI9mQMxhtntd95HmRGasNx2', 'PQWFmTLCP9XqafR9ogNI3Fg1sH43', 'z4y8XVrxYGT7rO4R01Y8tQ2F5z83', 't9bOfGDqwDaQTYV6i008opscpjz2'].includes(userInfo.id)) {
                        data.push({
                            accountName: doc.data().accountName,
                            label: doc.data().accountName,
                            address: doc.data().address,
                            key: doc.data().key,
                            type: doc.data().seasonal || null,
                            city: doc.data().city || null,
                            countyName: doc.data().countyName || null,
                            state: doc.data().state || null,
                            routeDay: doc.data().routeDay || null,
                            routeName: doc.data().routeName || null,
                            visible: true
                        })
                    }
                    else {
                        if (userInfo.accounts.includes(doc.data().key)) {
                            data.push({
                                accountName: doc.data().accountName,
                                label: doc.data().accountName,
                                address: doc.data().address,
                                key: doc.data().key,
                                type: doc.data().seasonal || null,
                                city: doc.data().city || null,
                                countyName: doc.data().countyName || null,
                                state: doc.data().state || null,
                                routeDay: doc.data().routeDay || null,
                                routeName: doc.data().routeName || null,
                                visible: true
                            })
                        }
                    }
                }

            });
            data.sort((a, b) => a.accountName.localeCompare(b.accountName))
            this.setState({ accounts: data, loading: false })



        });



    }

    weatherUpdated = () => {
        this.setState({ updateWeather: false })
    }

    render() {

        const { account, screenWidth, company, doc, userInfo, loading, preview, distributionList, accounts,
            spinning, showDistribution, colorModal, modalIndex, printScreen, updateWeather } = this.state;

        const Loading = (props) => {
            return (
                <div style={{ minHeight: '100vh' }}>
                    <Row justify="space-around" gutter={[32, 8]} align="middle" style={{ paddingTop: 18, }}>

                        <Col span={24} style={{ textAlign: 'center', fontSize: 24, fontFamily: 'Roboto, sans-serif', }}>
                            <CircularProgress />
                        </Col>
                    </Row>
                </div>
            )
        }


        const uniqueEmails = distributionList.filter((item, index, self) => self.indexOf(item) == index);

        const totalArea = loading === false && doc.graphics !== undefined ? JSON.parse(doc.graphics).length === 0 ? 0 : Number(Number(JSON.parse(doc.graphics).map((g) => !['point'].includes(g.attributes.shapeType) ? g.attributes.area : 0).reduce((partialSum, a) => partialSum + a, 0)).toFixed(2)).toLocaleString() : 0;
        const totalPerimeter = loading === false && doc.graphics !== undefined ? JSON.parse(doc.graphics).length === 0 ? 0 : Number(Number(JSON.parse(doc.graphics).map((g) => !['point'].includes(g.attributes.shapeType) ? g.attributes.length : 0).reduce((partialSum, a) => partialSum + a, 0)).toFixed(0)).toLocaleString() : 0;

        return (


            <Row align="middle">

                <Col xs={16} sm={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>
                    <Typography variant="h4">Application</Typography>
                </Col>
                <Col xs={6} sm={4} style={{ paddingTop: 30, paddingBottom: 15, textAlign: 'right' }}>

                </Col>
                <Col span={24}>
                    <Divider />
                </Col>
                <Col span={22}>
                    <Breadcrumbs>
                        <Link to="/chemicalApplications" style={{ paddingLeft: 30, textDecoration: 'none', color: 'inherit', }}>
                            Chemical Applications
                        </Link>

                    </Breadcrumbs>
                </Col>

                {doc.status === 'edit' && (
                    <Col span={23} style={{ paddingLeft: 30, }}>
                        <Typography fontWeight={500} fontSize={32} variant={screenWidth === 'xs' ? 'h4' : "h3"}>{doc.key ? "Edit Application Log" : "New Application Log"}</Typography>

                    </Col>)}
                {doc.status !== 'edit' && (
                    <Col span={23} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 30 }}>
                        <Row align="middle" gutter={[12, 12]}>

                            <Col xs={24} sm={4}>
                                <Typography variant='h5'>Status</Typography>
                                <Typography variant='body1'>{doc.status === 'draft' ? "Draft" : doc.status === 'created' ? "Created" : "Distributed"}</Typography>

                            </Col>

                            <Col xs={24} sm={6}>
                                <Typography variant='h5'>Account</Typography>
                                <Typography variant='body1'>{account.accountName}</Typography>

                            </Col>



                            <Col span={24} style={{}} >
                                <Row align="middle" gutter={[24, 24]}>
                                    <Col span={24} >

                                        <Paper elevation={12} style={{ borderRadius: 12, padding: 40 }}>
                                            <Row align="middle" gutter={[12, 12]}>
                                                <Col xs={0} sm={4}>
                                                    <PostAddIcon fontSize="large" color="primary" />
                                                </Col>
                                                <Col xs={12} sm={10}>
                                                    <Typography variant='h6'>Create</Typography>
                                                    {doc.status === 'draft' ?
                                                        <Typography variant='body1'>This application is still a draft application. Hit the create button in order to complete and distribute.</Typography>
                                                        : <Typography variant='body1'>This application has been created! If you edit the application it will revert back to a draft!</Typography>
                                                    }
                                                </Col>
                                                <Col xs={12} sm={10}>
                                                    {spinning.on === true ? <Row align="middle" gutter={[12, 12]}>
                                                        <Col span={24} style={{ textAlign: 'right' }}>{spinning.label} <CircularProgress /></Col>
                                                    </Row>
                                                        :

                                                        <Row align="middle" gutter={[12, 12]}>


                                                            <Col xs={24} sm={12} >



                                                                {doc.status === 'draft' && (
                                                                    <Button size="small" style={{ padding: 10, borderRadius: 4, textTransform: 'none', fontSize: 14, }}
                                                                        fullWidth variant='contained' color="primary"
                                                                        onClick={() => {
                                                                            this.createLog('created');

                                                                        }}>Create Application</Button>
                                                                )
                                                                }

                                                            </Col>
                                                            <Col xs={24} sm={12}>
                                                                <Button size="small" style={{ padding: 10, borderRadius: 4, textTransform: 'none', fontSize: 14, }}
                                                                    fullWidth variant='outlined' color="primary"
                                                                    onClick={() => {
                                                                        this.updateStatus('edit');
                                                                        this.updateDoc('status', 'edit')
                                                                    }}><EditIcon />Edit Application</Button>
                                                            </Col>
                                                        </Row>
                                                    }

                                                </Col>
                                            </Row>



                                        </Paper>
                                    </Col>

                                    {/*  {['created', 'distributed'].includes(doc.status) && (
                                        <Col span={24} >

                                            <Paper elevation={12} style={{ borderRadius: 12, padding: 40 }}>
                                                <Row align="middle" gutter={[12, 12]}>
                                                    <Col xs={0} sm={4}>
                                                        <EmailIcon fontSize="large" color="primary" />
                                                    </Col>
                                                    <Col xs={12} sm={10}>
                                                        <Typography variant='h6'>Send</Typography>
                                                        <Typography variant='body1'>This application has been created! Click distribute to send out!</Typography>
                                                        {uniqueEmails.filter((f) => f.flDistribution === true)
                                                            .map((g) => {

                                                                return (`${g.firstName !== undefined ? `${g.firstName || ''} ${g.lastName || ''}` : g.label} (${g.email})`)
                                                            }).join(', ')}
                                                    </Col>
                                                    <Col xs={12} sm={10}>
                                                        <Row align="middle" gutter={[12, 12]}>
                                                            <Col xs={24} sm={12}>

                                                            </Col>
                                                            <Col xs={24} sm={12}>
                                                                <Button size="small" style={{ padding: 10, borderRadius: 4, textTransform: 'none', fontSize: 14, }}
                                                                    fullWidth variant='outlined' color="primary" onClick={() => { this.setState({ showDistribution: true }) }}>Add Emails</Button>
                                                            </Col>
                                                        </Row>

                                                    </Col>
                                                </Row>



                                            </Paper>
                                        </Col>)} */}

                                    {/*  {['created', 'distributed'].includes(doc.status) && (
                                        <Col span={24} >

                                            <Paper elevation={12} style={{ borderRadius: 12, padding: 40 }}>
                                                <Row align="middle" gutter={[12, 12]}>
                                                    <Col xs={0} sm={4}>
                                                        <DownloadIcon fontSize="large" color="primary" />
                                                    </Col>
                                                    <Col xs={12} sm={10}>
                                                        <Typography variant='h6'>Download</Typography>
                                                        <Typography variant='body1'>This application has been created! Click to download!</Typography>
                                                    </Col>
                                                    <Col xs={12} sm={10}>
                                                        <Row align="middle" gutter={[12, 12]}>
                                                            <Col xs={24} sm={12}>

                                                            </Col>
                                                            <Col xs={24} sm={12}>



                                                            </Col>
                                                        </Row>

                                                    </Col>
                                                </Row>



                                            </Paper>
                                        </Col>)} */}
                                </Row>




                            </Col>




                        </Row>












                    </Col>)}
                <Col span={23} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 30 }}>


                    <Paper elevation={12} style={{ borderRadius: 12, padding: 40 }}>
                        <Row gutter={[12, 12]}  >
                            <Col {...fullMobile(12, 24)} style={{ position: 'relative', }}>

                                <Typography variant={screenWidth === 'xs' ? 'h6' : 'h4'} style={{ position: 'absolute', bottom: 0 }}>Chemical Application Report</Typography>


                            </Col>






                            {loading === false && (
                                <Col {...fullMobile(12, 24)} >
                                    <Row gutter={[4, 4]} style={{ paddingTop: 20, paddingBottom: screenWidth === 'xs' ? 5 : 20 }}>

                                        {preview === false && loading === false &&
                                            (<Col xs={24} >
                                                <Autocomplete
                                                    size="small"
                                                    id="combo-box-demo" 
                                                    defaultValue={doc.account}
                                                    disableClearable={true}
                                                    options={accounts}
                                                    filterSelectedOptions
                                                    renderInput={(params) => <TextField {...params} label="Account" />}
                                                    onChange={(a, b) => {
                                                        this.updateDoc('account', b)
                                                        this.updateDoc('accountKey', b.key)
                                                        this.setState({ updateWeather: true })
                                                        console.log(b)
                                                    }}

                                                /></Col>)}
                                        <Col xs={24} style={{ textAlign: screenWidth === 'xs' ? 'left' : "right" }} >
                                            <Typography fontWeight={500} variant="h5">{doc.account !== undefined ? doc.account.accountName : ''}</Typography>
                                        </Col>
                                        <Col xs={24} style={{ textAlign: screenWidth === 'xs' ? 'left' : "right" }} >
                                            <Typography variant="h5">{company.companyName}</Typography>
                                        </Col>

                                        <Col xs={24} style={{ textAlign: screenWidth === 'xs' ? 'left' : "right" }} >
                                            <Typography variant="body1">{doc.account !== undefined ? doc.account.address : ''}</Typography>
                                        </Col>
                                        <Col xs={24} style={{ textAlign: screenWidth === 'xs' ? 'left' : "right" }} >
                                            <Typography variant="body1" style={{ textTransform: 'capitalize' }}>{`${doc.account !== undefined ? `${doc.account.countyName} County` : ''}`}</Typography>
                                        </Col>



                                    </Row>

                                </Col>
                            )}
                            <Col xs={24} >
                                <Divider style={{
                                    borderColor: 'rgb(148, 186, 195)',
                                    borderBottomWidth: 'medium',

                                }} />
                            </Col>
                            <Col xs={20} >
                                <Typography variant="h2" style={{ fontSize: 32, }}>General Info</Typography>
                            </Col>
                            <Col span={4}>
                                <Row align="middle" justify={screenWidth === 'xs' ? 'center' : 'end'}>
                                    <Col><Typography variant="body1">Regulatory</Typography></Col>
                                    <Col><Checkbox checked={doc.regulatory} disabled={preview}
                                        onChange={(e) => {
                                            this.updateDoc('regulatory', e.target.checked)
                                        }} /></Col> 

                                </Row>
                            </Col>

                            {loading === false && (<Col {...fullMobile(12, 24)}    >
                                <CheckTimeWeather weather={doc.weather || {}} userInfo={userInfo} account={account} item={doc} updateWeather={updateWeather} 
                                weatherUpdated={this.weatherUpdated} doc={doc}
                                    updateDoc={this.updateDoc} screenWidth={screenWidth} date={doc.startDate} />

                            </Col>)}

                            {Object.values(doc).length > 0 && loading === false && (<Col {...fullMobile(12, 24)} style={{ paddingLeft: screenWidth === 'xs' ? 0 : 20, paddingTop: screenWidth === 'xs' ? 0 : 0, paddingRight: 20, paddingBottom: 20 }}>
                                <GeneralInfo account={account} company={company} preview={preview} item={doc} screenWidth={screenWidth} userInfo={userInfo} updateItem={this.updateDoc} updateEntireItem={this.updateEntireDoc} />

                            </Col>)}
                            <Col xs={24} >
                                <Divider style={{
                                    borderColor: 'rgb(148, 186, 195)',
                                    borderBottomWidth: 'medium',

                                }} />
                            </Col>
                            <Col xs={24} style={{ paddingTop: 10 }} >
                                <Typography variant="h2" style={{ fontSize: 32, }}>Water Conditions</Typography>
                            </Col>
                            {Object.values(doc).length > 0 && (<Col {...fullMobile(24)} style={{ paddingBottom: 20 }}>
                                <WaterConditions preview={preview} item={doc} screenWidth={screenWidth} userInfo={userInfo} updateItem={this.updateDoc} updateEntireItem={this.updateEntireDoc} />

                            </Col>)}
                            <Col xs={24} >
                                <Divider style={{
                                    borderColor: 'rgb(148, 186, 195)',
                                    borderBottomWidth: 'medium',

                                }} />
                            </Col>
                            <Col {...fullMobile(16, 24)} style={{ paddingTop: 10 }} >
                                <Typography variant="h2" style={{ fontSize: 32, }}>Application Info</Typography>
                            </Col>


                            <Col {...fullMobile(8, 24)} style={{ textAlign: 'right' }}>
                                {preview === false && (<Button style={{ textTransform: 'none', padding: 8, fontSize: 16, borderRadius: 36 }} variant="contained" color="primary"
                                    fullWidth={screenWidth === 'xs' ? true : false}
                                    onClick={() => {
                                        this.addProduct()
                                        this.props.openSnack('success', 'Product Added')
                                    }}>+ Add Herbicide/Adjuvant</Button>)}
                            </Col>
                            <Col {...fullMobile(14, 24)} style={{}}>
                                <Row gutter={[16, 16]}>
                                    <Col {...fullMobile(24, 24)}>
                                        <Typography variant="body1" fontWeight={600}>Target Species:</Typography>
                                        {preview ? <Typography variant="body1">{doc.targetSpecies}</Typography> : <OutlinedInput
                                            fullWidth notched={false} label="none" placeholder={"Target Species"} size="small"

                                            value={doc.targetSpecies}
                                            onChange={(e) => {
                                                this.updateDoc(`targetSpecies`, e.target.value)
                                            }} />}
                                    </Col>
                                    <Col {...fullMobile(8, 12)} style={{}}>
                                        <Typography variant="body1" fontWeight={600}>Total Area (ac)</Typography>
                                        <Typography variant="body1" >{totalArea}</Typography>


                                    </Col>
                                    <Col {...fullMobile(8, 12)} style={{}}>
                                        <Typography variant="body1" fontWeight={600}>Total Linear Ft</Typography>
                                        <Typography variant="body1" >{totalPerimeter}</Typography>


                                    </Col>
                                    <Col {...fullMobile(8, 12)} style={{}}>
                                        <Typography variant="body1" fontWeight={600}>Color</Typography>
                                        {preview ?
                                            <Button style={{ height: 30, width: 20, padding: 10, backgroundColor: doc.layerColor }} variant="contained" color="inherit"
                                            ></Button>
                                            :
                                            <Button style={{ height: 30, width: 20, padding: 10, backgroundColor: doc.layerColor }} variant="contained" color="inherit"
                                                onClick={() => {
                                                    this.setState({ colorModal: true, modalIndex: 1 })
                                                }}></Button>
                                        }
                                    </Col>


                                </Row>
                            </Col>

                            <Col {...fullMobile(10, 24)} style={{}}>
                                {loading === false && Object.values(doc).length > 0 && (

                                    preview ? <img src={doc.imgurl} style={{ width: '95%', height: '400px', borderRadius: 16, }} />

                                        :

                                        <MyApp account={account} graphics={doc.graphics} screenWidth={screenWidth} userInfo={userInfo} item={doc} layerColor={doc.layerColor}
                                            updateGraphics={(graphics) => this.updateDoc(`graphics`, graphics)}
                                            sendImage={(url) => {
                                                this.updateDoc(`imgurl`, url);

                                            }} />)}
                            </Col>
                            {Object.values(doc).length > 0 && loading === false && (<Col {...fullMobile(24, 24)} style={{}}>
                                <ApplicationInfo account={account} preview={preview} item={doc} screenWidth={screenWidth} userInfo={userInfo} updateItem={this.updateDoc} updateEntireItem={this.updateEntireDoc} />

                            </Col>)}


                            <Col xs={24} >
                                <Divider style={{
                                    borderColor: 'rgb(148, 186, 195)',
                                    borderBottomWidth: 'medium',

                                }} />
                            </Col>
                            <Col xs={20} style={{ paddingTop: 10 }} >
                                <Typography variant="h2" style={{ fontSize: screenWidth === 'xs' ? 24 : 32, }}>Gates, Control Structures, and Weirs</Typography>
                            </Col>
                            <Col span={4}>
                                <Row align="middle" justify={screenWidth === 'xs' ? 'center' : 'end'}>
                                    <Col><Typography variant="body1">N/A</Typography></Col>
                                    <Col><Checkbox checked={doc.controlsApplicable} disabled={preview}
                                        onChange={(e) => {
                                            this.updateDoc('controlsApplicable', e.target.checked)
                                        }} /></Col>

                                </Row>
                            </Col>
                            {Object.values(doc).length > 0 && doc.controlsApplicable === false && (<Col {...fullMobile(24)} style={{}}>
                                <ControlsInfo preview={preview} item={doc} screenWidth={screenWidth} userInfo={userInfo} updateItem={this.updateDoc} updateEntireItem={this.updateEntireDoc} />

                            </Col>)}

                            <Col xs={24} >
                                <Divider style={{
                                    borderColor: 'rgb(148, 186, 195)',
                                    borderBottomWidth: 'medium',

                                }} />
                            </Col>
                            <Col xs={20} style={{ paddingTop: 10 }} >
                                <Typography variant="h2" style={{ fontSize: screenWidth === 'xs' ? 24 : 32, }}>Additional Notes</Typography>
                            </Col>
                            <Col xs={20} style={{ paddingTop: 10 }} >
                                {preview ? <Typography variant="body1">{doc.fieldNotes}</Typography> :
                                    <Input fullWidth notched={false} label="none" placeholder={"Additional Notes"} size="large"
                                        disableUnderline={true}
                                        multiline={true}
                                        rows={4}
                                        value={doc.fieldNotes}
                                        onChange={(e) => {
                                            this.updateDoc(`fieldNotes`, e.target.value)
                                        }} />
                                }
                            </Col>

                            <Col span={24} style={{ paddingTop: 24, pddingBottom: 24 }}>
                                {preview === false && (<FooterButtons item={doc} screenWidth={screenWidth} userInfo={userInfo} openSnack={this.props.openSnack} updateEntireItem={this.updateEntireDoc} />)}
                            </Col>

                        </Row>
                    </Paper>
                </Col>

                <Drawer

                    visible={showDistribution}
                    onClose={() => { this.setState({ showDistribution: false }) }}

                >
                    <List >
                        {distributionList.map((d, i) => {



                            return (
                                <ListItemButton onClick={() => this.updateDistribution(i, 'flDistribution', !d.flDistribution)} dense>
                                    <ListItemIcon>
                                        <Checkbox

                                            checked={d.flDistribution}
                                            color="primary"

                                            onChange={(e) => {
                                                this.updateDistribution(i, 'flDistribution', e.target.checked)
                                            }}

                                        />
                                    </ListItemIcon>
                                    <ListItemText id={i} primary={d.firstName !== undefined ? `${d.firstName || ''} ${d.lastName || ''}` : d.label}
                                        secondary={d.email} />

                                </ListItemButton>
                            )
                        })}








                    </List>


                </Drawer>

                <Modal title="" visible={colorModal} footer={null} onCancel={() => {
                    this.setState({ colorModal: false, modalIndex: undefined })
                }}>

                    {modalIndex !== undefined && (
                        <Row gutter={[16, 16]} >
                            <Col span={24}>
                                <GithubPicker color={doc.layerColor} onChangeComplete={(color) => {
                                    const rgb = doc.layerColor.substring(4, doc.layerColor.length - 1)
                                        .replace(/ /g, '')
                                        .split(',');
                                    console.log(rgb)
                                    const layerColor = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${rgb[3]})`;

                                    this.updateDoc('layerColor', layerColor)


                                }} />
                            </Col>
                            <Col span={24}>
                                <AlphaPicker color={doc.layerColor} onChangeComplete={(color) => {

                                    this.updateDoc(`layerColor`, `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`)
                                }} />
                            </Col>
                        </Row>
                    )}



                </Modal>
            </Row >

        );
    }
} 