import React, { useEffect, useRef, useState } from "react";


import { CircularProgress, Typography } from '@mui/material';
import { Col } from 'antd';
import './taskList.css';

import moment from 'moment-timezone';
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import uuid from "react-uuid";


import TableRow from "./dataTableRow";
import ProbeParameters from "../../../../../../../extra/probeParams";
import { createDatabaseData } from "../databaseFunctions";

 

const DataTableMulti = (
    {
        data,
        setData,
        loading,
        deleteData,
        updateDataPosition,
        headers,
        setSortItem,
        stations,
        parameters,
        codes,
        unitsArray,
        currentCell,
        setCurrentCell,
        setErrorModal,
        currentRow,
        setCurrentRow,
        checkErrors,
        handleNextPage,
        handlePrevPage,
        startIndex,
        endIndex,
        currentPage,
        rowsPerPage,
        fullData,
        openSnack,
        updatingData,
        setUpdatingData,
        userInfo,
        getNestedProperty
    }

) => {

    const [lastClickedIndex, setLastClickedIndex] = useState(null);









    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    function onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            data,
            result.source.index,
            result.destination.index
        );


        setSortItem(null)
        updateDataPosition(items);
    }

    const getItemStyle = (isDragging, draggableStyle) => ({
        ...draggableStyle
    });

    const getListStyle = isDraggingOver => ({



    });







    async function duplicateData(data) {
        const newData = JSON.parse(JSON.stringify(fullData));
        const index = newData.findIndex((item) => item.id === data.id);
        const newKey = uuid();
        const newItem = { ...data, id: newKey, key: newKey, selected: false };

        // Create an object to keep track of duplicate times
        let matchingTimes = [];

        newData.forEach((item) => {
            if (moment(item.time, 'YYYY-MM-DD HH:mm:ss.SSS').format('YYYY-MM-DD HH:mm') === moment(newItem.time, 'YYYY-MM-DD HH:mm:ss.SSS').format('YYYY-MM-DD HH:mm')) {
                matchingTimes.push(item);
            }
        });


        console.log(matchingTimes)

        // Calculate how many milliseconds to add to newItem.time
        let millisecondsToAdd = matchingTimes.length;
        console.log('millisecondsToAdd', millisecondsToAdd);

        // Update newItem.time and newItem.timestamp based on the number of items with the same time
        newItem.time = moment(newItem.time, 'YYYY-MM-DD HH:mm:ss.SSS').add(millisecondsToAdd, 'milliseconds').format('YYYY-MM-DD HH:mm:ss.SSS');
        newItem.timestamp = Number(moment(newItem.time, 'YYYY-MM-DD HH:mm:ss.SSS').format('x'));

        newData.splice(index + 1, 0, newItem);



        console.log(newData); // Should print newData with unique times down to the millisecond
        setData(newData);

        const createResponse = await createDatabaseData(newItem, 'node_data_new2', [...parameters, ...ProbeParameters], stations, codes, unitsArray, openSnack, setUpdatingData, userInfo);
        matchingTimes = [];
    }




    async function keyDown(e, row) {

        const header = headers.find((h) => h.name === currentCell?.selectedID.split('??')[1]);

        const headerIndex = headers.findIndex((h) => h.name === header.name);
        const rowIndex = data.findIndex((item) => item.id === row?.id);

        if (e.key === 'Enter') {
            const nextRowIndex = e.shiftKey ? rowIndex - 1 : rowIndex + 1;
            const nextRow = data[nextRowIndex];
            if (nextRow) {
                setCurrentCell({ ...nextRow, selectedID: `${nextRow.id}??${header.name}` });
            } else {
                setCurrentCell(null);
            }
        } else if (e.key === 'Tab') {
            e.preventDefault();  // Prevent the default tab behavior

            const nextHeaderIndex = e.shiftKey ? headerIndex - 1 : headerIndex + 1;
            const nextHeader = headers[nextHeaderIndex];

            if (nextHeader) {
                setCurrentCell({ ...row, selectedID: `${row.id}??${nextHeader.name}` });
            } else {
                setCurrentCell(null);
            }
        }
    }





    return (






        <Col span={24} style={{ paddingBottom: 30, paddingRight: 0, marginRight: 0 }}>


            <DragDropContext onDragEnd={(source) => onDragEnd(source)}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}

                        >
                            {[...data]?.slice(startIndex, endIndex).map((row, i) => {







                                return (
                                    <Draggable key={row.id} draggableId={row.id} index={i} id={row.id}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}

                                                id={row.id}
                                            >
                                                <Col span={24} style={{ paddingBottom: 0, paddingRight: 0, marginRight: 0 }}>
                                                    <TableRow rowData={row} headers={headers} data={data} currentCell={currentCell} stations={stations} setErrorModal={setErrorModal} fullData={fullData}
                                                        setCurrentCell={setCurrentCell} parameters={parameters} unitsArray={unitsArray} keyDown={keyDown} setData={setData} checkErrors={checkErrors}
                                                        codes={codes} duplicateData={duplicateData} deleteData={deleteData} lastClickedIndex={lastClickedIndex} openSnack={openSnack} userInfo={userInfo}
                                                        setLastClickedIndex={setLastClickedIndex} updatingData={updatingData} setUpdatingData={setUpdatingData} getNestedProperty={getNestedProperty}

                                                    />
                                                </Col>

                                            </div>
                                        )
                                        }
                                    </Draggable>




                                );

                            })}


                        </div>
                    )
                    }
                </Droppable>
            </DragDropContext>



            {
                loading && (<Col span={24} style={{ paddingBottom: 30, textAlign: 'center' }}>
                    <CircularProgress />
                </Col>)
            }

        </Col >





    );
};

export default DataTableMulti;
