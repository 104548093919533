import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { DndProvider, useDrag, useDrop, useDragLayer } from 'react-dnd';
import { deletePhotosFunction } from "../../utils/photosFunctions";
import PhotoCover from "./photoCover";
import PhotoLoadingCover from "../../pages/photoLoadingCover";
import moment from 'moment-timezone';
import LightboxComponent from "./lightBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleUp } from "@fortawesome/free-solid-svg-icons";





const Timeline = ({ screenWidth, openSnack, userInfo, photos }) => {

    const [lastIndex, setLastIndex] = useState(0);
    const [isLightboxOpen, setIsLightboxOpen] = React.useState(false);
    const [currentImageIndex, setCurrentImageIndex] = React.useState(0);


    const handlePhotoClick = photo => {
        const index = photos.findIndex(p => p.photo_id === photo.photo_id);
        setCurrentImageIndex(index);
        setIsLightboxOpen(true);
    };

    // Function to scroll to the top of the page
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };


    // Function to group photos by month
    const groupPhotosByMonth = (photos) => {
        return photos.reduce((acc, photo) => {
            const monthYear = moment(photo.created_date, 'x').format('MMMM YYYY');
            if (!acc[monthYear]) {
                acc[monthYear] = [];
            }
            acc[monthYear].push(photo);
            return acc;
        }, {});
    };

    // Sorted and grouped photos by month
    const sortedPhotos = photos;
    const groupedPhotos = groupPhotosByMonth(sortedPhotos);



    if (photos.length === 0) {
        return (
            <Box p={1}>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6" fontSize={18} fontWeight={700}>
                            No photos to display
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        )

    } else {
        return (
            <Box p={1}>

                {isLightboxOpen && (
                    <LightboxComponent
                        isLightboxOpen={isLightboxOpen}
                        setIsLightboxOpen={setIsLightboxOpen}
                        image={photos[currentImageIndex].large}
                        alt={photos[currentImageIndex].name}
                        screenWidth={screenWidth}
                        nextImage={() => setCurrentImageIndex((currentImageIndex + 1) % photos.length)}
                        prevImage={() => setCurrentImageIndex((currentImageIndex + photos.length - 1) % photos.length)}
                    />

                )}

                <Grid container alignItems="center" spacing={2}>
                    {Object.keys(groupedPhotos).map((monthYear, index) => (
                        <React.Fragment key={monthYear}>

                            <Grid item xs={12}>
                                <Typography variant="h6" fontSize={22} fontWeight={500}>
                                    {monthYear}
                                </Typography>
                                <Divider sx={{ width: '100%', my: 1 }} />
                            </Grid>
                            {groupedPhotos[monthYear].map((photo, i) => (
                                <Grid key={photo.photo_id} item xs={6} sm={4} md={3}>
                                    <PhotoCover
                                        albumBreadCrumbs={[]}
                                        photo={photo}
                                        index={index}
                                        handlePhotoClick={() => handlePhotoClick(photo)}
                                        selectedPhotos={[]}
                                        setSelectedPhotos={() => console.log('select')}
                                        selected={[].includes(photo?.photo_id)}
                                        deletePhotos={() => console.log('select')}
                                        state={'idle'}
                                        imageSrc={photo?.medium}
                                        title={photo?.name}
                                        type="timeline"
                                        selectMultiplePhotos={() => console.log('select')}
                                        setLastIndex={() => console.log('select')}
                                    // Style based on isDragging

                                    />
                                </Grid>
                            ))}
                        </React.Fragment>
                    ))}
                </Grid>
                <Button
                    onClick={scrollToTop}
                    style={{
                        position: 'fixed',
                        bottom: 20,
                        right: 20,
                        zIndex: 1000 // Ensure it's above other elements
                    }}
                >
                    <FontAwesomeIcon icon={faArrowCircleUp} size="2x" style={{ color: '#1bff0f' }} />
                </Button>
            </Box>
        );
    }
};

export default Timeline;