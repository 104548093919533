import { Button, Checkbox, Divider, OutlinedInput, Typography, Select, MenuItem, CircularProgress, Paper, FormControl, InputLabel, Fab, IconButton } from '@mui/material';
import { Col, Drawer, Row } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment-timezone';
import { fullMobile } from './settings/util';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, } from 'firebase/firestore';
import { faCamera, faEye, faEyeSlash, faFloppyDisk, faGear, faMagnifyingGlassMinus, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import uuid from 'react-uuid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddIcon from '@mui/icons-material/Add';
import ProbeParameters from '../../../extra/probeParameters.json';

import html2canvas from 'html2canvas';
import { queryParameters, queryProbes, querySites, queryStations } from '../../../firebase/config';
import download from 'downloadjs';
import GridLayout from "react-grid-layout";
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

var randomColor = require('randomcolor');

export default function CurrentWidget(props) {
    const { userInfo, screenWidth, openSnack, company, account, PPArray, parameters, nodes, probes, widgetRef } = props;
    const [widget, setWidget] = useState(null);


    const db = getFirestore();
    useEffect(() => {
        window.scrollTo(0, 0);

    }, [])

    //update if widget changes
    useEffect(() => {
        if (widgetRef) {
            console.log(widgetRef)
            setWidget(widgetRef)
        }
    }, [widgetRef])



    return (

        <Row style={{ padding: 30 }} align="middle" gutter={[12, 12]}>
          

        </Row>

    );
}