import React, { useEffect, useState } from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import './editorStyles.css';
import './WidgetURLImageStyle.css';
import axios, * as others from 'axios';

export default function WidgetURLImage({ attributes }) {
  const [loading, setLoading] = useState(true);
  const [imageURL, setImageURL] = useState(null);

  useEffect(() => {
    fetchImage(attributes?.url);
    console.log('camera loaded?')
    setLoading(false);
  }, [attributes]);




  async function fetchImage(imageUrl) {
    console.log("GET IMAGE")
    try {
      const proxyUrl = "https://mycorslake.herokuapp.com/";
      const response = await axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/fetchImage', {
        imageUrl: imageUrl,
      }, {
        responseType: 'blob' // Important to handle binary data correctly
      });
      console.log(response)
      // Create a local URL for the fetched image blob
      const localUrl = URL.createObjectURL(response.data);

      setImageURL(localUrl);
    } catch (error) {
      console.log('Error fetching image:', error);
    }
  };

  if (loading) {
    return (
      <div className="loading">
        <CircularProgress />
        <Typography variant="body1">Loading Data</Typography>
      </div>
    );
  }


  return (
    <Row gutter={[8, 8]}>
      <Col span={24}>
        {![null, undefined, ""].includes(imageURL) ? (
          <div className="image-widget">
            <img src={imageURL} alt="Snapshot" />
          </div>
        ) : (
          <div className="no-data">
            <Typography variant="body1">No Image</Typography>
          </div>
        )}
      </Col>
    </Row>
  );
}
