// Layer.jsx
import React, { useEffect, useRef, useState } from 'react';
import { Typography, IconButton, Switch, Button, ClickAwayListener, Popper, Box, List, ListItem, Modal, Backdrop, OutlinedInput, Divider, Select, MenuItem, Autocomplete, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Col, Row } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { fullMobile } from '../settings/util';
import { getFirestore, query, where, getDocs } from 'firebase/firestore';
import { getNodesList } from './utils';
import DataColor from '../settings/dataColor';
import DataColorGithub from '../settings/dataColorGithubMapIcon';
import KMLFiles from './kmlFile';

var randomColor = require('randomcolor');
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '1px solid rgba(0,0,0,0.05)',
    borderRadius: 4,
    boxShadow: 24,
    p: 4,
};

const LayerItemModal = ({ isOpen, setOpen, item, handleDelete, handleToggle, userInfo, layer, openSnack }) => {
    const [nodes, setNodes] = useState(null);

    useEffect(() => {
        getNodes();
        console.log(layer)
    }, [])

    async function getNodes() {
        const nodesList = await getNodesList(userInfo)
        setNodes(nodesList);


    }



    return (


        <Modal
            open={isOpen}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            BackdropComponent={Backdrop}
            BackdropProps={{
                style: {
                    backgroundColor: 'rgba(0, 0, 0, 0.0)', // Change the opacity here
                },
            }}

        >
            <Box sx={style}>
                <Row>

                    <Col {...fullMobile(24, 24)}>
                        <Typography fontSize={13} variant="body1">Item Name</Typography>
                        <OutlinedInput
                            size="small"
                            id="name"
                            defaultValue={item.name}
                            sx={{ width: '100%' }}
                            onBlur={(e) => {
                                handleToggle(layer.id, item.id, { name: e.target.value })
                            }}
                        />

                    </Col>
                    <Col {...fullMobile(24, 24)} style={{ paddingTop: 5 }}>
                        <Typography fontSize={13} variant="body1">Item Type</Typography>
                        <Select
                            size="small"
                            id="type"
                            defaultValue={item.type}
                            sx={{ width: '100%' }}
                            onChange={(e) => {
                                handleToggle(layer.id, item.id, { type: e.target.value })
                            }}
                        >
                            <MenuItem value="kml">KML Link</MenuItem>
                            <MenuItem value="kmlFile">KML File</MenuItem>
                            <MenuItem value="node">Node</MenuItem>

                        </Select>

                    </Col>
                    {item.type === 'drawing' && (
                        <Col {...fullMobile(24, 24)} style={{ paddingTop: 5 }}>
                            <Typography fontSize={13} variant="body1">Layer Color</Typography>
                            <DataColorGithub color={item?.layerItemAttributes?.layerColor || 'orange'} updateData={(boo, val) => {
                                handleToggle(layer.id, item.id, {
                                    layerItemAttributes: {
                                        ...item.layerItemAttributes,
                                        layerColor: val
                                    }
                                })
                            }} />
                        </Col>
                    )}
                    {item.type === 'kml' && (
                        <Col {...fullMobile(24, 24)} style={{ paddingTop: 5 }}>
                            <Typography fontSize={13} variant="body1">KML URL</Typography>
                            <OutlinedInput
                                size="small"
                                id="url"
                                defaultValue={item.url}
                                sx={{ width: '100%' }}
                                onBlur={(e) => {
                                    handleToggle(layer.id, item.id, { url: e.target.value })
                                }}
                            />
                        </Col>
                    )}
                    {item.type === 'kmlFile' && (
                        <Col {...fullMobile(24, 24)} openSnack={openSnack} style={{ paddingTop: 5 }}>
                            <KMLFiles userInfo={userInfo} openSnack={openSnack} item={item}
                                handleRemove={(id) => {
                                    const kmls = item.kmls.filter((kml) => kml.key !== id)
                                    console.log(kmls)
                                    handleToggle(layer.id, item.id, { kmls: item.kmls.filter((kml) => kml.key !== id) })
                                }}

                                handleToggle={(boo) => {
                                    console.log("trigger")
                                    console.log(boo)
                                    handleToggle(layer.id, item.id, { kmls:  boo})
                                }} />
                        </Col>
                    )}
                    {item.type === 'node' && nodes !== null && (
                        <Col {...fullMobile(24, 24)} style={{ paddingTop: 5 }}>
                            <Typography fontSize={13} variant="body1">Nodes</Typography>
                            <Autocomplete
                                sx={{
                                    groupLabel: {
                                        fontWeight: 'bold',
                                        fontSize: 20
                                    }
                                }
                                }
                                renderGroup={(params) => (
                                    <Box key={params.key}>
                                        <Typography fontWeight={800} fontSize={14} p={1}>
                                            {params.group}
                                        </Typography>
                                        {params.children}
                                    </Box>
                                )}
                                disableCloseOnSelect
                                groupBy={(option) => option.stationLabel || ''}
                                multiple
                                key={nodes.length}
                                id="tags-standard"
                                options={nodes}
                                getOptionLabel={(option) => option.label}
                                defaultValue={nodes.filter((node) => item?.layerItemAttributes?.nodes?.map((d) => d?.id).includes(node.id))}
                                renderInput={(params) => (
                                    <TextField
                                        margin='dense'
                                        size="small"
                                        {...params}
                                        variant="outlined"
                                        placeholder="Nodes"
                                    />
                                )}
                                onChange={(e, b) => {
                                    handleToggle(layer.id, item.id, {
                                        layerItemAttributes: {
                                            ...item.layerItemAttributes,
                                            nodes: b.map((node) => {
                                                const existNode = item?.layerItemAttributes?.nodes?.find((d) => d?.id === node.id)
                                                return ({
                                                    id: node.id,
                                                    color: randomColor(),
                                                    name: node.name,
                                                    marker: 'default',
                                                    ...existNode,

                                                })
                                            })
                                        }
                                    })

                                }}

                            />
                        </Col>
                    )}

                    {item?.layerItemAttributes?.nodes?.length > 0 && (
                        <Col {...fullMobile(24, 24)} style={{ paddingTop: 5, maxHeight: 250, overflowY: 'auto', width: '100%' }}>
                            {item?.layerItemAttributes?.nodes?.map((nodeItem) => {

                                return (
                                    <Row style={{ paddingTop: 8 }}>
                                        <Col {...fullMobile(5, 5)}>
                                            <Typography fontSize={12} fontWeight={600} variant="body1">Node Name</Typography>
                                            {nodeItem?.name}
                                        </Col>
                                        <Col {...fullMobile(6, 6)}>
                                            <Typography fontSize={12} fontWeight={600} variant="body1">Label</Typography>
                                            <OutlinedInput
                                                size="small"
                                                id="label"
                                                defaultValue={nodeItem?.label || nodeItem?.name}
                                                sx={{ width: '100%' }}
                                                onBlur={(e) => {
                                                    handleToggle(layer.id, item.id, {
                                                        layerItemAttributes: {
                                                            ...item.layerItemAttributes,
                                                            nodes: item.layerItemAttributes.nodes.map((node) => {
                                                                if (node.id === nodeItem.id) {
                                                                    return ({
                                                                        ...node,
                                                                        label: e.target.value
                                                                    })
                                                                }
                                                                return node
                                                            }
                                                            )
                                                        }
                                                    })
                                                }
                                                }
                                            />
                                        </Col>
                                        <Col {...fullMobile(3, 3)} style={{ paddingLeft: 4 }}>
                                            <Typography fontSize={12} fontWeight={600} variant="body1">Color</Typography>
                                            <DataColorGithub color={nodeItem.color} updateData={(boo, val) => {
                                                handleToggle(layer.id, item.id, {
                                                    layerItemAttributes: {
                                                        ...item.layerItemAttributes,
                                                        nodes: item.layerItemAttributes.nodes.map((node) => {
                                                            if (node.id === nodeItem.id) {
                                                                return ({
                                                                    ...node,
                                                                    color: val
                                                                })
                                                            }
                                                            return node
                                                        }
                                                        )
                                                    }
                                                })
                                            }} />
                                        </Col>
                                        <Col {...fullMobile(3, 3)} style={{ paddingLeft: 4 }}>
                                            <Typography fontSize={12} fontWeight={600} variant="body1">Font Color</Typography>
                                            <DataColorGithub color={nodeItem?.fontColor} updateData={(boo, val) => {
                                                handleToggle(layer.id, item.id, {
                                                    layerItemAttributes: {
                                                        ...item.layerItemAttributes,
                                                        nodes: item.layerItemAttributes.nodes.map((node) => {
                                                            if (node.id === nodeItem.id) {
                                                                return ({
                                                                    ...node,
                                                                    fontColor: val
                                                                })
                                                            }
                                                            return node
                                                        }
                                                        )
                                                    }
                                                })
                                            }} />
                                        </Col>
                                        {/* <Col {...fullMobile(5, 5)} style={{ paddingLeft: 4 }}>
                                            <Typography fontSize={12} fontWeight={600} variant="body1">Marker</Typography>
                                            <Select
                                                size="small"
                                                id="type"
                                                defaultValue={nodeItem?.marker || 'default'}
                                                sx={{ width: '100%' }}
                                                onChange={(e) => {
                                                    handleToggle(item.id, {
                                                        layerItemAttributes: {
                                                            ...item.layerItemAttributes,
                                                            nodes: item.layerItemAttributes.nodes.map((node) => {
                                                                if (node.id === nodeItem.id) {
                                                                    return ({
                                                                        ...node,
                                                                        marker: e.target.value
                                                                    })
                                                                }
                                                                return node
                                                            }
                                                            )
                                                        }
                                                    })
                                                }
                                                }
                                            >
                                                <MenuItem value="circle">Circle</MenuItem>
                                                <MenuItem value="square">Square</MenuItem>
                                                <MenuItem value="triangle">Triangle</MenuItem>
                                                <MenuItem value="star">Star</MenuItem>
                                                <MenuItem value="cross">Cross</MenuItem>
                                                <MenuItem value="diamond">Diamond</MenuItem>
                                                <MenuItem value="buoy">Buoy</MenuItem>
                                                <MenuItem value="default">default</MenuItem>
                                            </Select>
                                        </Col> */}
                                        <Col {...fullMobile(4, 4)} style={{ paddingLeft: 4 }}>
                                            <Typography fontSize={12} fontWeight={600} variant="body1">Padding</Typography>
                                            <OutlinedInput
                                                size="small"
                                                id="label"
                                                defaultValue={nodeItem?.padding || 0}
                                                sx={{ width: '100%' }}
                                                type="number"
                                                onBlur={(e) => {
                                                    handleToggle(layer.id, item.id, {
                                                        layerItemAttributes: {
                                                            ...item.layerItemAttributes,
                                                            nodes: item.layerItemAttributes.nodes.map((node) => {
                                                                if (node.id === nodeItem.id) {
                                                                    return ({
                                                                        ...node,
                                                                        padding: e.target.value
                                                                    })
                                                                }
                                                                return node
                                                            }
                                                            )
                                                        }
                                                    })
                                                }
                                                }
                                            />
                                        </Col>
                                        <Col {...fullMobile(3, 3)} style={{ paddingLeft: 4 }}>
                                            <Typography fontSize={12} fontWeight={600} variant="body1">Scale</Typography>
                                            <OutlinedInput
                                                size="small"
                                                id="label"
                                                defaultValue={nodeItem?.scale || 1}
                                                sx={{ width: '100%' }}
                                                type="number"
                                                onBlur={(e) => {
                                                    handleToggle(layer.id, item.id, {
                                                        layerItemAttributes: {
                                                            ...item.layerItemAttributes,
                                                            nodes: item.layerItemAttributes.nodes.map((node) => {
                                                                if (node.id === nodeItem.id) {
                                                                    return ({
                                                                        ...node,
                                                                        scale: e.target.value
                                                                    })
                                                                }
                                                                return node
                                                            }
                                                            )
                                                        }
                                                    })
                                                }
                                                }
                                            />
                                        </Col>
                                        <Col {...fullMobile(24, 24)} style={{ paddingTop: 4 }}>
                                            <Divider />
                                        </Col>

                                    </Row>
                                )
                            })}
                        </Col>
                    )}







                    <Col span={12} style={{ textAlign: 'right', paddingTop: 20 }}>
                        <Button
                            variant='outlined'
                            color='error'

                            onClick={() => {

                                handleDelete(item.id);
                                setOpen(false);
                            }}
                        >
                            Delete
                        </Button>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right', paddingTop: 20 }}>
                        <Button
                            variant='contained'
                            style={{ backgroundColor: '#404050' }}
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            Save
                        </Button>
                    </Col>


                </Row>



            </Box>
        </Modal >
    );

};

export default LayerItemModal;
