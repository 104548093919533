import { Button, Checkbox, Divider, OutlinedInput, Typography, Select, MenuItem, CircularProgress, Autocomplete, TextField, Switch, ButtonGroup, Paper } from '@mui/material';
import { Col, Row } from 'antd';
import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { fullMobile } from '../extra/util';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, } from 'firebase/firestore';

import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import uuid from 'react-uuid';




import { faCamera, faCloud, faCloudRain, faMicrochip, faO, faWater } from '@fortawesome/free-solid-svg-icons';

import queryString from 'query-string';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
var randomColor = require('randomcolor');

export default function DataStreamsHome({ userInfo, screenWidth, openSnack, company, account }) {
    const [activePaper, setActivePaper] = useState(null)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])




    let navigate = useNavigate();


    return (
        <Row style={{ fontFamily: 'Roboto, sans-serif', paddingLeft: 10, paddingTop: 10, marginLeft: 2, }} >


            <Col span={23}>
                <Row align="middle">
                    <Col xs={16} sm={20} style={{ paddingTop: 30, paddingBottom: 15 }}>
                        <Typography variant="h4">Data Streams</Typography>
                    </Col>

                    <Col span={24}>
                        <Divider />
                    </Col>
                </Row>
            </Col>

            <Col span={23} >

                <Row gutter={[12, 12]}>
                    {[
                        { title: 'Probes', navigation: '/probes', icon: faMicrochip, description: 'Manage your probes and their data streams.' },
                        { title: 'Weather Underground', navigation: '/weatherUnderground', icon: faCloud, description: 'Manage your Weather Underground data streams.' },
                        { title: 'Tidal Stations', navigation: '/tidalStations', icon: faWater, description: 'Manage your Tidal Stations data streams.' },
                        { title: 'NOAA Station', navigation: '/rainGauges', icon: faCloudRain, description: 'Manage your NOAA Gauges data streams.' },
                        { title: 'Time Lapse Cameras', navigation: '/timeLapse', icon: faCamera, description: 'Manage your time lapse camera.' },
                        { title: 'Oxygen Saturation Technology', navigation: '/ost', icon: faO, description: 'Manage your OST devices.' },
                        { title: 'Obscape Weather Station', navigation: '/obscapeWeather', icon: faCloudRain, description: 'Manage your Obscape weather station devices' },
                    ].map((item, index) => {
                        return (
                            <Col xs={24} sm={12} md={8} lg={8} xl={8} key={index}>
                                <Paper elevation={3}
                                    onClick={() => { navigate(item.navigation) }}
                                    onMouseEnter={() => { setActivePaper(item.title) }}
                                    onMouseLeave={() => { setActivePaper(null) }}
                                    style={{
                                        padding: 10, borderRadius: 10, height: 200, display: 'flex', flexDirection: 'column', justifyContent: 'space-between',
                                        alignItems: 'center',
                                        justifyContent: 'center', display: 'flex', cursor: 'pointer', height: '100%',
                                        backgroundColor: activePaper === item.title ? '#5385AD' : '#404050', color: '#fff', padding: '30px', fontWeight: 700,
                                        transition: 'box-shadow 400ms, transform 400ms',
                                        boxShadow: activePaper === item.title ? '0 4px 8px 0 rgba(0, 0, 0, 0.2)' : 'inset 0 0 0 1px #d9d9d9',
                                        transform: activePaper === item.title ? 'scale(1.02)' : 'scale(1)',
                                        transformOrigin: 'center',
                                    }}>
                                    <Row align="middle">
                                        <Col span={24} style={{ textAlign: 'center' }}>
                                            <FontAwesomeIcon fontSize={18} size="2x"
                                                icon={item.icon} color="#9ec1c9" />
                                        </Col>
                                        <Col span={24} style={{ textAlign: 'center' }}>
                                            <Typography variant="h6">{item.title}</Typography>
                                        </Col>
                                        <Col span={24} style={{ textAlign: 'center' }}>
                                            <Typography variant="body2">{item.description}</Typography>
                                        </Col>
                                    </Row>
                                </Paper>
                            </Col>
                        )
                    })
                    }
                </Row>

            </Col>





        </Row>
    );
}