import React, { useState, useEffect } from 'react';

import FileTree from './FileTree/FileTree';
import AddItem from './AddItem/AddItem';
import { getFolderStructure } from '../firebase/firestore';
import { Col, Row } from 'antd';
import { fullMobile } from '../../util';
import { Divider, Typography } from '@mui/material';

 
const App = (props) => {
  const { userInfo, account, screenWidth, openSnack } = props;
  const [folderStructure, setFolderStructure] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [currentPath, setCurrentPath] = useState([]);

  useEffect(() => {
   
  }, []);

  const updateCurrentPath = (newPath) => {
    setCurrentPath(newPath);
    console.log(newPath)
  };



  return (



    <Row style={{}} >

      <Col {...fullMobile(23, 23)} style={{ padding: 15 }}>
        <Typography variant="h4">Your Documents</Typography>
      </Col>
      <Col {...fullMobile(24, 24)}>
        <Divider />
      </Col>

      <Col {...fullMobile(23, 23)} style={{ padding: 15 }}>



        <h2>{selectedFolder?.name}</h2>
        <FileTree
          openSnack={openSnack}
          folderStructure={folderStructure}
          selectedFolder={selectedFolder}
          setSelectedFolder={(e) => {
            console.log(e)
          }}
          userInfo={userInfo}
          currentPath={currentPath}
          updateCurrentPath={updateCurrentPath}
        />


      </Col>
    </Row>

  );
};

export default App;
