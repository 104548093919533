import React, { useEffect, useState } from "react";


import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import SettingsIcon from '@mui/icons-material/Settings';
import { Autocomplete, Box, Button, ButtonGroup, Checkbox, CircularProgress, Divider, IconButton, InputAdornment, MenuItem, OutlinedInput, Select, TextField, Typography, Drawer, Input } from '@mui/material';
import { Col, Popconfirm, Row, Switch } from 'antd';
import moment from 'moment-timezone';
import { ReactSortable } from 'react-sortablejs';
import uuid from 'react-uuid';
import { faCheckCircle, faCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './taskList.css'
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { faTrash } from '@fortawesome/free-solid-svg-icons';



const TasksSearch = (
    {
        searchQuery,
        setSearchQuery,
    }

) => {






    return (

        <OutlinedInput
            size="small"
            style={{
                borderRadius: 16,
                marginRight: 10,
            }}
            value={searchQuery}
            fullWidth
            placeholder='Search'
            startAdornment={
                <InputAdornment position="start">
                    <SearchIcon />
                </InputAdornment>
            }
            endAdornment={
                <InputAdornment position="end">
                    {searchQuery !== '' && (
                        <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                            onClick={() =>
                                setSearchQuery('')
                            }
                        >
                            <ClearIcon />
                        </IconButton>
                    )}
                </InputAdornment>
            }
            onChange={(e) => setSearchQuery(e.target.value)}
        />


    );
};

export default TasksSearch;
