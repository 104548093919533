// LayersDrawer.jsx
import React, { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import { Col, Row } from 'antd';
import { fullMobile } from '../settings/util';
import { Backdrop, Divider, Typography } from '@mui/material';
import CreateLayerButton from './CreateLayerButton';
import { createLayer, deleteLayer, updateLayer } from './utils';
import Layer from './Layer';
import { ReactSortable } from 'react-sortablejs';

const LayersDrawer = ({ open, onClose, getLayers, userInfo, widgetKey, layers, setLayers, openSnack, screenWidth, currentDrawingLayer, setCurrentDrawingLayer,
currentLayer, setCurrentLayer, handleToggleLayerItem
}) => {
    
    const drawerContentStyle = {
        width: screenWidth === 'sm' ? '30vw' : screenWidth === 'md' ? '25vw' : '20vw',
        padding: '0px',
        boxSizing: 'border-box',
    };

    const layersList = layers.filter((layer) => layer.type === 'layer');

    const handleDelete = async (layerId) => {
        const success = await deleteLayer(layerId, userInfo.currentCompany, widgetKey, setLayers);
        if (success) {
            getLayers();
            openSnack('success', 'Layer deleted successfully');
        }
    };

    const handleToggle = async (layerId, updateData) => {
        // Call updateLayer with the new visible value
        
        await updateLayer(layerId, userInfo.currentCompany, widgetKey, updateData, setLayers);
        getLayers();
    };

    const handleSort = (newLayersList) => {
        setLayers((prevState) => {
            const otherLayers = prevState.filter((layer) => layer.type !== 'layer');
            return [...otherLayers, ...newLayersList];
        });
    };


    return (
        <Drawer anchor="left" open={open} onClose={onClose}
            sx={{
                '& .MuiDrawer-paper': {
                    backgroundColor: '#f6f8fe'

                }
            }}
            BackdropComponent={Backdrop}
            BackdropProps={{
                style: {
                    backgroundColor: 'rgba(0, 0, 0, 0.0)', // Change the opacity here
                },
            }}
            


        >
            <Row style={drawerContentStyle} align="middle">
                <Col {...fullMobile(16, 16)} style={{ paddingLeft: 10, paddingTop: 10 }}>
                    <Typography variant="h6" fontSize={24} fontWeight={500}>Layers</Typography>
                </Col>
                <Col {...fullMobile(8, 8)} style={{ paddingRight: 10, paddingTop: 10, textAlign: 'right' }}>
                    <CreateLayerButton onClick={() => createLayer('layer', userInfo.currentCompany, widgetKey, setLayers, layersList.length)} />
                </Col>



                <Col {...fullMobile(24, 24)} style={{ paddingTop: 15 }}>
                    <ReactSortable
                        handle='.handle'
                        list={layersList}
                        setList={handleSort}
                        animation={150}
                        delayOnTouchStart={true}
                        delay={2}
                    >
                        {layersList.map((layer) => (
                            <Layer key={layer.id} layer={layer} openSnack={openSnack} currentLayer={currentLayer} setCurrentLayer={(id) => {
                                console.log(id)
                                setCurrentLayer(id)
                            }} handleToggleLayerItem={handleToggleLayerItem}
                            getLayers={getLayers} userInfo={userInfo} currentDrawingLayer={currentDrawingLayer} setCurrentDrawingLayer={(id) => {
                                console.log(id)
                                setCurrentDrawingLayer(id)
                            }}
                                handleDelete={handleDelete} handleToggle={handleToggle} setLayers={setLayers} company={userInfo.currentCompany} widgetKey={widgetKey} />
                        ))}
                    </ReactSortable>
                </Col>
            </Row>
        </Drawer>
    );
};

export default LayersDrawer;
