import {
    Box,
    Container,
    Grid,
    MenuItem,
    Select,
    Typography
} from "@mui/material";
import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";






const SourceSelection = ({ source, setSource }) => {





    return (


        <Col span={24} style={{

            padding: 10
        }}>
            <Typography variant="h6" fontWeight={700} fontSize={14}>
                DATA SOURCE
            </Typography>
            <Row gutter={[0, 4]}>
                <Col span={24} >

                    <Select
                        style={{ fontWeight: 800, width: '100%' }}
                        notched={false}

                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={source}
                        placeholder="Select Data Source"
                        label="Source"
                        fullWidth
                        onChange={(e) => {
                            setSource(e.target.value)
                        }}
                    >
                        <MenuItem value={'node'}>Node Data</MenuItem>

                    </Select>
                </Col>

            </Row>



        </Col >



    );


};

export default SourceSelection;
