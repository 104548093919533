import React, { useEffect, useState } from "react";


import { faBox, faBucket, faCheck, faFish, faFlask, faListCheck, faMagnifyingGlass, faPlus, faWheatAlt, faWrench, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, ButtonGroup, FormControl, IconButton, InputLabel, MenuItem, Modal, OutlinedInput, Select, Typography } from '@mui/material';
import { Col, Row } from 'antd';



const ItemSubTypeSelector = (
    {
        screenWidth,
        newItem,
        setNewItem



    }

) => {
    const [isFocused, setIsFocused] = useState(false);







    const serviceItems = [
        { value: 'General', key: 'general', icon: faWrench },
        { value: 'Harvest', key: 'harvest', icon: faWheatAlt },
        { value: 'Maintenance', key: 'maintenance', icon: faWrench },
        { value: 'Monitoring', key: 'monitoring', icon: faMagnifyingGlass },
        { value: 'Other', key: 'other', icon: faWrench },
        { value: 'Water Quality', key: 'waterQuality', icon: faListCheck },
    ];
    const productItems = [
        { value: 'Bacterial', key: 'bacterial', icon: faBox },
        { value: 'Chemical', key: 'chemical', icon: faFlask },
        { value: 'Dye', key: 'dye', icon: faBucket },
        { value: 'Equipment', key: 'equipment', icon: faWrench },
        { value: 'Fish', key: 'fish', icon: faFish },
        { value: 'Material', key: 'material', icon: faBox },
        { value: 'Other', key: 'other', icon: faBox },
    ];


    const menuItems = newItem?.psType === 'products' ? productItems : newItem?.psType === 'services' ? serviceItems : [];

    return (
        <>




            <Col xs={24}  >
                <Typography variant="h6" component="div" color={'rgba(0,0,0,0.6)'} fontWeight={600} fontSize={16}>
                    {newItem?.psType === 'products' ? 'Product' : newItem?.psType === 'services' ? 'Service' : 'Template'} Type
                </Typography>
                <FormControl fullWidth variant="standard">
                    {!newItem?.psType && !isFocused && (
                        <InputLabel id="demo-simple-select-standard-label"
                            style={{
                                marginLeft: 15, marginTop: 2, fontSize: 16,
                                color: 'rgba(0,0,0,0.4)',
                            }}
                        >
                            Select
                        </InputLabel>
                    )}
                    <Select
                        style={{
                            borderRadius: 12,
                            marginTop: screenWidth === 'xs' ? 2 : 5,
                            marginBottom: 20,
                            fontSize: 16,
                            width: '100%',
                            //no wrap
                            whiteSpace: 'nowrap',
                            display: 'inline-block',
                        }}
                        fullWidth
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                        placeholder="Select"
                        variant="outlined"
                        value={newItem.subType}
                        onChange={(e) => {
                            const newNew = { ...newItem };
                            newNew.subType = e.target.value;
                            setNewItem(newNew);
                        }}

                        renderValue={(selectedValue) => {
                            const selectedItem = menuItems.find(item => item.value === selectedValue);
                            if (!selectedItem) return null;

                            return (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <FontAwesomeIcon icon={selectedItem.icon} size="lg" style={{ marginRight: '10px' }} />
                                    <Typography variant="body1" component="span" color={'rgba(0,0,0,0.6)'} fontWeight={600} fontSize={16}>
                                        {selectedItem.value}
                                    </Typography>
                                </div>
                            );
                        }}
                    >
                        {
                            menuItems
                                .map(item => {


                                    return (
                                        <MenuItem
                                            sx={{
                                                p: 1
                                            }} key={item.value} value={item.value}>
                                            {newItem?.itemSubType === item.value ?
                                                <FontAwesomeIcon icon={faCheck} size="lg" style={{ marginRight: '10px' }} />
                                                : <div style={{ width: 24, height: 24, marginRight: 10 }}></div>}
                                            <FontAwesomeIcon icon={item.icon} size="lg" style={{ marginRight: '10px' }} />
                                            <Typography variant="h6" component="div" color={'rgba(0,0,0,0.6)'} fontWeight={600} fontSize={16}>
                                                {item.value}
                                            </Typography>
                                        </MenuItem>
                                    )
                                })}
                    </Select>
                </FormControl>
            </Col>
        </>



    );
};

export default ItemSubTypeSelector;
