const permissionsList = [
    { value: 'fieldLogs', label: 'Field Logs', level: '1' },
    { value: 'chemicalLogs', label: 'Chemical Logs', level: '1' },
    { value: 'equipmentLogs', label: 'Equipment Logs', level: '1' },
    { value: 'photos', label: 'Photos', level: '1' },
    { value: 'documents', label: 'Documents', level: '1' },
    { value: 'tasks', label: 'Tasks', level: '1' },
    { value: 'publicPortal', label: 'Public Portal', level: '1' },
    { value: 'siteDirectory', label: 'Site Directory', level: '1' },
    { value: 'siteInfo', label: 'Site Info', level: '1' },
    { value: 'samplingStations', label: 'Sampling Stations', level: '1' },
    { value: 'dataStreams', label: 'Data Streams', level: '1' },
    { value: 'dataQuery', label: 'Data Query', level: '1' },
    { value: 'dataManagement', label: 'Data Management', level: '1' },
    { value: 'parameters', label: 'Parameters', level: '1' },
    { value: 'references', label: 'References', level: '1' },
    { value: 'alerts', label: 'Alerts', level: '1' },
    { value: 'reports', label: 'Reports', level: '1' },
    { value: 'adminInfo', label: 'Admin Info', level: '1' },
    { value: 'sites', label: 'Sites', level: '1' },
    { value: 'adminStaff', label: 'Admin Staff', level: '1' },
]

const states = [
    { value: 'AL', label: 'Alabama' },
    { value: 'AK', label: 'Alaska' },
    { value: 'AZ', label: 'Arizona' },
    { value: 'AR', label: 'Arkansas' },
    { value: 'CA', label: 'California' },
    { value: 'CO', label: 'Colorado' },
    { value: 'CT', label: 'Connecticut' },
    { value: 'DE', label: 'Delaware' },
    { value: 'DC', label: 'District Of Columbia' },
    { value: 'FL', label: 'Florida' },
    { value: 'GA', label: 'Georgia' },
    { value: 'HI', label: 'Hawaii' },
    { value: 'ID', label: 'Idaho' },
    { value: 'IL', label: 'Illinois' },
    { value: 'IN', label: 'Indiana' },
    { value: 'IA', label: 'Iowa' },
    { value: 'KS', label: 'Kansas' },
    { value: 'KY', label: 'Kentucky' },
    { value: 'LA', label: 'Louisiana' },
    { value: 'ME', label: 'Maine' },
    { value: 'MD', label: 'Maryland' },
    { value: 'MA', label: 'Massachusetts' },
    { value: 'MI', label: 'Michigan' },
    { value: 'MN', label: 'Minnesota' },
    { value: 'MS', label: 'Mississippi' },
    { value: 'MO', label: 'Missouri' },
    { value: 'MT', label: 'Montana' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NV', label: 'Nevada' },
    { value: 'NH', label: 'New Hampshire' },
    { value: 'NJ', label: 'New Jersey' },
    { value: 'NM', label: 'New Mexico' },
    { value: 'NY', label: 'New York' },
    { value: 'NC', label: 'North Carolina' },
    { value: 'ND', label: 'North Dakota' },
    { value: 'OH', label: 'Ohio' },
    { value: 'OK', label: 'Oklahoma' },
    { value: 'OR', label: 'Oregon' },
    { value: 'PA', label: 'Pennsylvania' },
    { value: 'RI', label: 'Rhode Island' },
    { value: 'SC', label: 'South Carolina' },
    { value: 'SD', label: 'South Dakota' },
    { value: 'TN', label: 'Tennessee' },
    { value: 'TX', label: 'Texas' },
    { value: 'UT', label: 'Utah' },
    { value: 'VT', label: 'Vermont' }

]

export { permissionsList, states }