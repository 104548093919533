import { Col, Row } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { fullMobile } from '../../util';
import HighchartsReact from "highcharts-react-official";
import highchartsMore from "highcharts/highcharts-more.js";
import Highcharts from "highcharts/highcharts.js";
import moment from 'moment';
import { CircularProgress, Divider, Typography } from '@mui/material';
import axios, * as others from 'axios';
var convert = require('convert-units')
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/data')(Highcharts);




export default function WidgetReading(props) {
    const { userInfo, screenWidth, openSnack, widgetRef, layoutRef } = props;
    const [layout, setLayout] = useState(layoutRef);
    const [widget, setWidget] = useState(widgetRef);
    const chartRef = useRef(null);
    const chartDiv = useRef(null);
    const infoDiv = useRef(null);
    const plotRef = useRef(null);
    const [readingTime, setReadingTime] = useState(null);
    const [loading, setLoading] = useState(true);
    const [latestReading, setLatestReading] = useState(null);
    const [chartOptions, setChartOptions] = useState({
        credits: {
            enabled: false
        },
        exporting: {
            enabled: false
        },
        stockTools: {
            gui: {
                enabled: false
            }
        },
        chart: {
            resetZoomButton: {
                position: {
                    align: 'left',
                    x: 20,
                    y: -20
                }
            },
            style: {
                fontFamily: 'Roboto, sans-serif'
            },
            animation: false
        },
        title: {
            text: null
        },
        legend: {
            enabled: false
        },
        series: [
            {
                type: 'spline',
                data: []
            }
        ],


        yAxis: [{
            id: 0, gridLineColor: 'transparent', title: { text: null }

        }],
        xAxis: {
            type: 'datetime',
            plotLines: [],
            title: { text: null },
            minorGridLineWidth: 0,
            lineColor: 'transparent',
            gridLineColor: 'transparent',
            minorGridLineColor: 'transparent',
        },
        tooltip: {
            xDateFormat: '%b %d, %Y %I:%M %p',
            shared: true
        },
        plotOptions: {
            series: {
                animation: false
            },
        },
        time: {
            useUTC: false,
        }
    },);



    useEffect(() => {
        setWidget(widgetRef)
    }, [widgetRef])
    useEffect(() => {
        setLayout(layoutRef)
    }, [layoutRef])




    //create chart
    useEffect(() => {
        if (widget) {
            let chart = Highcharts.chart(widget.single.key, chartOptions);
            //create reference to chart
            chartRef.current = chart;
            //get data for chart
            queryData(widget)

        }
    }, [widget])

    useEffect(() => {


    }, [])

    //if height or width of chart div changes update chart
    useEffect(() => {
        if (chartRef.current) {
            //find out what percent of the parent div is infoDiv and set plotRef height to 100% - infoDiv height
            let infoDivHeight = infoDiv.current.clientHeight;
            let chartDivHeight = chartDiv.current.clientHeight;
            let percent = infoDivHeight / chartDivHeight;
            let plotRefHeight = layout?.h < 8 ? 100 : 100 - (percent * 100);
            plotRef.current.style.height = `${plotRefHeight}%`;

            //reflow chart
            chartRef.current.reflow();





        }
    }, [chartDiv.current?.clientHeight, chartDiv.current?.clientWidth])

    async function queryData(widget) {
        console.log(widget.single)
        console.log(widget)
        const proxyUrl = "https://mycorslake.herokuapp.com/";
        const item = widget.single;

        const endDate = item?.endToday === true ? moment().format('YYYY-MM-DD HH:mm:ss') : moment(item?.endDate, 'x').format('YYYY-MM-DD HH:mm:ss')

        console.log(endDate)




        const startDate = item?.timeframe === 'month' ? moment(endDate, 'YYYY-MM-DD HH:mm:ss').subtract(30, 'days').format('YYYY-MM-DD') :
            item?.timeframe === 'week' ? moment(endDate, 'YYYY-MM-DD HH:mm:ss').subtract(7, 'days').format('YYYY-MM-DD') :
                item?.timeframe === 'day' ? moment(endDate, 'YYYY-MM-DD HH:mm:ss').subtract(1, 'days').format('YYYY-MM-DD') :
                    item?.timeframe === 'quarter' ? moment(endDate, 'YYYY-MM-DD HH:mm:ss').subtract(90, 'days').format('YYYY-MM-DD') :
                        item?.timeframe === '6months' ? moment(endDate, 'YYYY-MM-DD HH:mm:ss').subtract(180, 'days').format('YYYY-MM-DD') :
                            item?.timeframe === 'year' ? moment(endDate, 'YYYY-MM-DD HH:mm:ss').subtract(365, 'days').format('YYYY-MM-DD') :
                                item?.timeframe === 'all' ? moment(endDate, 'YYYY-MM-DD HH:mm:ss').subtract(3650, 'days').format('YYYY-MM-DD')
                                    : moment(endDate, 'YYYY-MM-DD HH:mm:ss').subtract(30, 'days').format('YYYY-MM-DD');

        console.log(startDate)


        //create enddate, if endToday is true end date is current time else it is item.endDate
        
        const raw = `SELECT * FROM ${item?.dataType === 'probe' ? 'probe_data' : 'node_data_new2'}
        WHERE ${item?.dataType === 'probe' ? 'locationid' : 'nodeid'} = '${item?.nodeid}'and parameterid = '${item?.parameterid}'
        AND time >= '${startDate}' AND time <= '${endDate}'
        ORDER BY "time" DESC
        ;`
        axios.post(proxyUrl + `https://us-central1-aquasource3.cloudfunctions.net/widgetsFire2/sqlRead`, {
            raw
        }).then(async (response) => {
            let data = response.data;
            const offset = item?.offset || 0;


            const convertData = [];
            const decimals = item?.decimals || 2;
            data.forEach((d) => {
                const value =
                    item.parameterid === '3' ? Number(convert(d.value.toFixed(4)).from('m').to(['m', 'ft'].includes(item.units) ? item.units : 'm')) :
                        item.parameterid === '1' ? Number(convert(d.value.toFixed(4)).from('C').to(['C', 'F'].includes(item.units) ? item.units : 'C')) :
                            item.parameterid === '19' ? Number(d.value.toFixed(4)) * 1000 :
                                !['1', '3', '19'].includes(item.parameterid) ? Number(d.value.toFixed(4)) : Number(d.value.toFixed(4));

                const finalValue = value + offset;
                convertData.push([Number(moment(d.time, 'YYYY-MM-DD HH:mm').format('x')), finalValue])
            })



            const latestReading = Number(convertData[0][1].toFixed(decimals));
            const latestTime = convertData[0][0];

            setLatestReading(latestReading);

            //change tooltip to show only one decimal
            chartRef.current.tooltip.options.valueDecimals = decimals;








            setReadingTime(latestTime);
            setLoading(false);

            //set chart data
            chartRef.current.series[0].setData(convertData);
            //update color of series to item.color
            chartRef.current.series[0].update({
                color: item.color,
                type: item?.type || 'spline',
                opacity: item?.opacity || 1,
                fillOpacity: item?.fillOpacity || 0.3,
                fillColor: {
                    linearGradient: [0, 0, 0, 300],
                    stops: [
                        [0, Highcharts.Color(item.color).setOpacity(item?.fillOpacity || 0.3).get('rgba')],
                        [1, Highcharts.Color(item.color).setOpacity(0).get('rgba')]
                    ]
                },
                lineWidth: item?.lineWidth || 2,
                //tootlip decimals and label
                name: item?.label !== '' ? item?.label : undefined,
                tooltip: {
                    valueDecimals: decimals,
                    valueSuffix: ` ${item?.unitsLabel || ''}`,

                },
            })
            //ad y axis label
            chartRef.current.yAxis[0].update({
                title: {
                    text: `${item?.axisLabel || ''}`
                }
            })






        }).catch((error) => {
            console.log(error)
            setLoading('No Data Found')
        })
    }

    const item = widget.single;
    //if loading is a string show error message

    return (

        //create div that adjust with size of parent div

        <div ref={chartDiv} style={{ width: '100%', height: '100%', position: 'relative', }}>
            <div ref={infoDiv} style={{

                position: 'relative'
            }}>
                <Row align="middle" gutter={[8, 8]}>
                    <Col span={12}>
                        <Typography display="inline" variant={
                            layout?.h < 7 ? 'h6' :
                                layout?.w < 4 ? 'h6' : 'h4'
                        } fontWeight={500} style={{ color: 'rgba(0,0,0,0.7)', }}>
                            {latestReading}
                        </Typography>
                        <Typography display="inline" variant={
                            layout?.h < 7 ? 'body1' :
                                layout?.w < 4 ? 'body1' : 'body1'
                        } fontWeight={300} style={{ color: 'rgba(0,0,0,0.7)', }}>
                            {item?.unitsLabel ? ` ${item?.unitsLabel}` : ''}
                        </Typography>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        {loading === false && (
                            <Typography display="inline" variant={'h5'} fontWeight={500} fontSize={15} style={{ color: 'rgba(0,0,0,0.7)', }}>
                                {moment(readingTime, 'x').format('LLL')}
                            </Typography>)}
                    </Col>
                    <Col span={24}>
                        <Divider sx={{ borderBottomWidth: 5, borderColor: item.color || '#405040' }} />
                    </Col>
                    <Col span={24}>
                        <Typography display="inline" variant={'body1'} fontWeight={500} fontSize={14} style={{ color: 'rgba(0,0,0,0.7)', }}>
                            Last {item.timeframe === undefined ? '' : item.timeframe === 'week' ? 'Week' :
                                item.timeframe === 'month' ? 'Month' : item.timeframe === 'year' ? 'Year' : item.timeframe === 'day' ? 'Day' :
                                    item.timeframe === 'quarter' ? 'Quarter' :
                                        item.timeframe === '6months' ? '6 Months' : item.timeframe === '12months' ? '12 Months' : 'Month'

                            }
                        </Typography>


                    </Col>
                </Row>
            </div>

            <div id={`${widget.single.key}`} ref={plotRef} style={{
                height: '50%',
                width: item.axisLabel !== undefined && item.axisLabel !== '' ? '95%' : '100%',
                alignContent: 'center',
                position: 'relative'
            }}>

            </div>

            <div style={{


                position: 'absolute',
                top: '35%',
                textAlign: 'center',
                width: '100%',

            }}>

                {loading === true ? <CircularProgress /> : null
                }
                {typeof loading === 'string' && (
                    <Typography variant={'body1'} fontWeight={500} fontSize={14} style={{ color: 'rgba(0,0,0,0.7)', }}>
                        {loading}
                    </Typography>
                )}


            </div>

        </div >

    );




}