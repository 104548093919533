import { faBuilding, faComment, faComments, faFile, faFileLines, faQuestionCircle, faUser } from '@fortawesome/free-regular-svg-icons';
import { faAdd, faArrowRight, faBars, faChartLine, faDatabase, faGauge, faGear, faMoneyBill, faPeopleGroup, faPlus, faQuestion, faSignOut, faSort, faTable, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popper, Typography, Button, Box, Divider, ClickAwayListener, Paper, OutlinedInput, List, Menu, ListItem, Avatar, IconButton, Chip } from '@mui/material';
import { Col, Popconfirm, Row } from 'antd';
import { getAuth, signOut } from 'firebase/auth';
import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './styles.css';
import { collection, doc, getFirestore, onSnapshot, query, updateDoc, where, getDoc } from 'firebase/firestore';
import { fullMobile } from '../../../waterQuality/stations/util';

import axios, * as others from 'axios';
import uuid from 'react-uuid';


export default function AddToPortal({ openSnack, userRef, companyRef, accountRef, type, widgets, fullLayout, currentWidgetKey }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [footerAnchorEl, setFooterAnchorEl] = useState(null);
    const [projectSearch, setProjectSearch] = useState('');
    const projectSearchRef = useRef(null);
    const projectRef = useRef(null);
    const footerRef = useRef(null);
    const [accounts, setAccounts] = useState([]);
    const [filterData, setFilterData] = useState([]);

    const [account, setAccount] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    const [company, setCompany] = useState(null);

    const [isOpen, setIsOpen] = useState(false);
    const [isFooterOpen, setIsFooterOpen] = useState(false);
    const [widget, setWidget] = useState(null);
    const [layout, setLayout] = useState(null);
    const [portals, setPortals] = useState([]);
    const [searchText, setSearchText] = useState('');



    useEffect(() => {
        if (companyRef !== undefined) {
            setCompany(companyRef)

        }
    }, [companyRef])
    useEffect(() => {
        if (accountRef !== undefined) {

        }
    }, [accountRef])
    useEffect(() => {
        if (userRef !== undefined) {
            setUserInfo(userRef)

            const db = getFirestore();
            const q = query(collection(db, "publicPortals"), where("account", "==", userRef.currentAccount));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const portals = [];
                querySnapshot.forEach((doc) => {
                    portals.push({
                        ...doc.data(),
                        widgets: JSON.parse(doc.data().widgets + doc.data().widgets1),
                        layout: JSON.parse(doc.data().layout),
                        widgetKeys: JSON.parse(doc.data().layout).map((l) => l.i),
                        id: doc.id
                    });







                });
                console.log(portals)
                setPortals(portals);
            });
            return unsubscribe;

        }
    }, [userRef])

    useEffect(() => {
        const widget = widgets.filter((w) => w.key === currentWidgetKey)[0];
        const layout = fullLayout.filter((l) => l.i === currentWidgetKey)[0];
        setWidget(widget)
        setLayout(layout)



    }, [widgets, currentWidgetKey, fullLayout])




    const handleClick = (event) => {
        setAnchorEl(anchorEl === null ? event.currentTarget : null);
    };
    const handleClose = (type) => {
        setAnchorEl(null);
    };

    useEffect(() => {
        //set anchorEl to projectRef
        setAnchorEl(projectRef.current)
        setFooterAnchorEl(footerRef.current)
    }, [])





    const clearChartData = (chartData) => {
        return chartData.map((d) => ({ ...d, data: [] }));
    };

    const prepareWidgetsForUpdate = (widgets) => {
        return widgets.map((w) => {
            const newChartData = clearChartData(w.attributes.chartData);
            const newAttributes = { ...w.attributes, chartData: newChartData };
            return { ...w, attributes: newAttributes };
        });
    };

    const splitWidgetsString = (widgetsString) => {
        const halfLength = Math.floor(widgetsString.length / 2);
        const firstHalf = widgetsString.substring(0, halfLength);
        const secondHalf = widgetsString.substring(halfLength, widgetsString.length);
        return { firstHalf, secondHalf };
    };



    const handleSearch = (event) => {
        setSearchText(event.target.value);
    };

    const getFilteredData = () => {
        const regex = new RegExp(searchText, 'i');
        const filteredData = portals.filter((item) => regex.test(item.name));
        return filteredData.slice(0, 3);
    };

    const handleAdd = (portalid, widgetid,) => {
        //add widgetid to the associated portals widgetKeys array
        const newPortals = portals.map((portal) => {
            if (portal.id === portalid) {
                //if the widget is already in the array, remove it
                if (portal.widgetKeys.includes(widgetid)) {
                    const newWidgetKeys = portal.widgetKeys.filter((key) => key !== widgetid);
                    const newWidgets = portal.widgets.filter((w) => w.key !== widgetid);
                    const newLayout = portal.layout.filter((l) => l.i !== widgetid);



                    return { ...portal, widgetKeys: newWidgetKeys, widgets: newWidgets, layout: newLayout };
                }
                //if the widget is not in the array, add it
                else {
                    const newWidgetKeys = [...portal.widgetKeys, widgetid];
                    const newWidgets = [...portal.widgets, widget];
                    const newLayout = [...portal.layout, layout];

                    return { ...portal, widgetKeys: newWidgetKeys, widgets: newWidgets, layout: newLayout };
                }

            } else {
                return portal;
            }
        });
        setPortals(newPortals);

        newPortals.forEach((portal) => {
            console.log(portal)
            const widgets = portal.widgets;
            const fullLayout = portal.layout;
            updateWidgets(widgets, portal.id);
            updateLayout(fullLayout, portal.id);
        });

    };


    async function updateWidgets(widgets, portalid) {
        console.log(widgets)
        console.log(portalid)
        const newWidgets = prepareWidgetsForUpdate(widgets);
        const widgetsString = JSON.stringify(newWidgets);
        const { firstHalf, secondHalf } = splitWidgetsString(widgetsString);

        const db = getFirestore();
        const docRef = doc(db, "publicPortals", portalid);
        await updateDoc(docRef, {
            widgets: firstHalf,
            widgets1: secondHalf,
        });


        openSnack('success', 'Added to portal!');
    };






    async function updateLayout(layout, portalid) {
        console.log(layout)
        console.log(portalid)
        const layoutString = JSON.stringify(layout);

        const db = getFirestore();
        const docRef = doc(db, "publicPortals", portalid);
        await updateDoc(docRef, {
            layout: layoutString,
        });


        openSnack('success', 'Added to portal!');
    };




    return (
        <Row   >


            <Col  {...fullMobile(24)} style={{ position: 'relative', textAlign: 'left', marginTop: 10, marginBottom: 10, }}

            >

                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ borderRadius: 0, textTransform: 'none', backgroundColor: '#404050' }}
                    ref={projectRef}
                    onClick={() => setIsOpen(!isOpen)}
                >

                    Add to Site Portals
                </Button>





                {isOpen && (
                    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
                        <Popper
                            id={'popper'}
                            open={isOpen}
                            anchorEl={anchorEl}

                            style={{ zIndex: 1000, }}
                        >
                            <Box sx={{
                                p: 0, bgcolor: 'background.paper',
                                borderRadius: 2,
                                border: '1px solid #e0e0e0',
                                boxShadow: 1,

                            }}>
                                <Row style={{}}>
                                    <Col span={24} style={{ textAlign: 'center', padding: 8, borderRadius: '2px 2px 0px 0px', backgroundColor: 'white', }}>
                                        <List >
                                            <ListItem sx={{ borderBottom: '1px solid #e0e0e0', }}>
                                                <OutlinedInput
                                                    fullWidth
                                                    sx={{
                                                        fontFamily: 'Roboto',
                                                    }}
                                                    size="small"
                                                    placeholder="Search"
                                                    variant="outlined"
                                                    value={searchText}
                                                    onChange={handleSearch}
                                                />
                                            </ListItem>
                                            {getFilteredData().map((item) => (
                                                <ListItem
                                                    key={item.id}
                                                    sx={{ borderBottom: '1px solid #e0e0e0', fontFamily: 'Roboto', fontSize: 14, fontWeight: 400, color: '#404040', }}
                                                >
                                                    <Chip display="inline" size="small" variant="outlined"
                                                        color={item.widgetKeys.includes(widget.id) ? 'warning' : 'primary'}
                                                        label={item.widgetKeys.includes(widget.id) ? 'Remove' : 'Add'} style={{ marginRight: 10, }} onClick={() => handleAdd(item.id, widget.id)} />
                                                    {item.name}
                                                </ListItem>
                                            ))}
                                        </List>



                                    </Col>

                                </Row>
                            </Box>
                        </Popper>
                    </ClickAwayListener>
                )}

            </Col>


        </Row >
    )




}
