


import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';

class CRUDController {


    create(data) {
        // Assuming this should return a Promise
        return Promise.resolve('success');
    }

    read() {
        // Assuming this should return a Promise
        return Promise.resolve([
            {
                id: 1,
                date: '2023-05-15',
                time: '10:00:00',
                location: 'San Francisco, CA',
                measurement_value: 100,
            },
            {
                id: 2,
                date: '2023-05-16',
                time: '11:00:00',
                location: 'New York, NY',
                measurement_value: 200,
            },
            {
                id: 3,
                date: '2023-05-17',
                time: '12:00:00',
                location: 'London, UK',
                measurement_value: 300,
            }]);
    }

    update(data) {
        console.log(data);
        // Assuming this should return a Promise
        return Promise.resolve('success');
    }

    delete(id) {
        // Assuming this should return a Promise
        return Promise.resolve(`${id}`);
    }
}


const Spreadsheet = ({ controller, data, columns }) => {
    console.log(data)
    const [editing, setEditing] = useState(false);
    const [editingRow, setEditingRow] = useState(null);
    const [editingColumn, setEditingColumn] = useState(null);

    const handleEdit = (row, column) => {
        setEditing(true);
        setEditingRow(row);
        setEditingColumn(column);
    };

    const handleCancel = () => {
        setEditing(false);
    };

    const handleSave = () => {
        const value = document.getElementById(`${editingRow}-${editingColumn}`).value;
        controller.update({
            row: editingRow,
            column: editingColumn,
            value: value,
        });
        setEditing(false);
    };

    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    {columns.map(column => (
                        <th key={column.id}>{column.header}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {data?.map((row, rowIndex) => (
                    <tr key={row.id}>
                        {columns.map((column, columnIndex) => (
                            <td
                                key={columnIndex}
                                onClick={() => handleEdit(rowIndex, columnIndex)}
                                className={editing && rowIndex === editingRow && columnIndex === editingColumn ? 'editing' : ''}
                            >
                                {row[column.id]}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
            <tfoot>
                <tr>
                    <td colSpan={columns.length}>
                        <button onClick={handleSave} disabled={!editing}>Save</button>
                        <button onClick={handleCancel}>Cancel</button>
                    </td>
                </tr>
            </tfoot>
        </Table>
    );
};

Spreadsheet.defaultProps = {
    columns: [
        {
            id: 'date',
            header: 'Date',
        },
        {
            id: 'time',
            header: 'Time',
        },
        {
            id: 'location',
            header: 'Location',
        },
        {
            id: 'measurement_value',
            header: 'Measurement Value',
        },
    ],
};

const SpreadsheetTest = () => {
    const [data, setData] = useState([]);
    const [controller, setController] = useState(null);

    useEffect(() => {
        const controllerInstance = new CRUDController('db'); // assuming 'db' as a placeholder
        setController(controllerInstance);
        controllerInstance.read().then(data => setData(data));
    }, []);

    return (
        <div>
            <Spreadsheet controller={controller} data={data} />
        </div>
    );
};

export default SpreadsheetTest;