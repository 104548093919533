import { Switch, Typography } from '@mui/material';
import { Col } from 'antd';
import React, { useEffect, useRef, useState } from 'react';

import MyLocationIcon from '@mui/icons-material/MyLocation';
import HomeIcon from '@mui/icons-material/Home';
import * as ReactDOMServer from 'react-dom/server';
import {
    DrawingManager, GoogleMap,
    StandaloneSearchBox, useJsApiLoader
} from '@react-google-maps/api';

const lib = ['places', 'visualization', 'drawing'];
export default function SiteMap({ userInfo, screenWidth, openSnack, company, mapInfo, updateMap, account, lat, lng, showProbes, showDrawings, edit, updateSC, probes }) {
    const [searchBox, setSearchBox] = useState(null);
    const [drawingManager, setDM] = useState(null);
    const [center, setCenter] = useState({ lat: -33, lng: 120 });
    const [zoom, setZoom] = useState(12);
    const [shapes, setShapes] = useState(mapInfo !== undefined ? JSON.parse(mapInfo) : []);
    const [initialLoad, setInitialLoad] = useState(true);
    const [accountInfo, setAccountInfo] = useState({});
    const [visibleProbes, setVisibleProbes] = useState(showProbes || false);
    const [visibleDrawings, setVisibleDrawings] = useState(showDrawings);
    const [editDrawings, setEditDrawings] = useState(edit);
    const [drawings, setDrawings] = useState([]);
    const [locations, setLocations] = useState(probes);
    const [markers, setMarkers] = useState([]);

    const mapRef = useRef(null);
    useEffect(() => {
        setCenter({ lat: account.lat, lng: account.lng })
        setZoom(16)
        setAccountInfo(account)
    }, []);

    useEffect(() => {





    }, []);








    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        libraries: lib,
        googleMapsApiKey: "AIzaSyC6fuuMNV3Wvo5M3_AXavGzKDYMBcuPooA",

    })

    function addCurrentLocationButton() {
        const html = ReactDOMServer.renderToString(<MyLocationIcon />);


        var currentLocationButton = document.createElement('button');
        currentLocationButton.innerHTML = html;
        currentLocationButton.style.cursor = 'pointer';
        currentLocationButton.classList.add('custom-map-control-button');
        mapRef.current.controls[window.google.maps.ControlPosition.RIGHT_CENTER].push(currentLocationButton);
        currentLocationButton.addEventListener('click', () => {
            // Try HTML5 geolocation.
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const pos = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        };
                        mapRef.current.setCenter(pos);
                    },
                    () => {
                        handleLocationError(true, infoWindow, mapRef.current.getCenter());
                    }
                );
            } else {
                // Browser doesn't support Geolocation
                handleLocationError(false, infoWindow, mapRef.current.getCenter());
            }
        });
    }

    function handleLocationError(browserHasGeolocation, infoWindow, pos) {
        infoWindow.setPosition(pos);
        infoWindow.setContent(
            browserHasGeolocation

                ? "Error: The Geolocation service failed."
                : "Error: Your browser doesn't support geolocation."
        );
        infoWindow.open(mapRef.current);
    }


    const infoWindow = new window.google.maps.InfoWindow();

    function addToAccountLocationButton(info) {
        const html = ReactDOMServer.renderToString(<HomeIcon />);


        var currentLocationButton = document.createElement('button');
        //change cursor to pointer
        currentLocationButton.style.cursor = 'pointer';
        currentLocationButton.innerHTML = html;
        currentLocationButton.classList.add('custom-map-control-button');
        mapRef.current.controls[window.google.maps.ControlPosition.RIGHT_CENTER].push(currentLocationButton);

        currentLocationButton.addEventListener('click', (e) => {
           
            mapRef.current.setCenter({ lat: lat, lng: lng });

        });
    }



    function addShapes(shapes, showDrawings, editDrawings) {
        shapes.forEach(shape => {
       
            if (shape.type === 'polygon') {
                const polyCoords = shape.path.map(path => {
                    const coord = new window.google.maps.LatLng(path.lat, path.lng);
                    return coord;
                });





                const poly = new window.google.maps.Polygon({
                    path: polyCoords,
                    fillColor: '#1976d2',
                    fillOpacity: 0.3,
                    strokeWeight: 3,
                    editable: editDrawings,
                    draggable: editDrawings,
                    geodesic: true,
                    zIndex: 1,
                    uniqueKey: shape.uniqueKey
                });
                poly.setMap(showDrawings ? mapRef.current : null);
                setDrawings([...drawings, poly]);
                addDeleteVertextFunction(poly);
                addDeletePolygonFunction(poly);
                addUpdateCoordsFunction(poly);
                attachPolygonInfoWindow(poly);
            }
        });
    }


    const onPlacesChanged = () => {
        const places = searchBox.getPlaces();
        
        //change center of map to new place
        setCenter({ lat: places[0].geometry.location.lat(), lng: places[0].geometry.location.lng() });



    };


    const onSBLoad = ref => {
        setSearchBox(ref);
    };
    const onDMLoad = ref => {
        setDM(ref);
    };

    function attachPolygonInfoWindow(polygon) {
        var infoWindow = new window.google.maps.InfoWindow();
        window.google.maps.event.addListener(polygon, 'mouseover', function (e) {
            const acres = window.google.maps.geometry.spherical.computeArea(polygon.getPath()) / 4046.85642;
            infoWindow.setContent(`Area: ${acres.toFixed(2)} acres <br /> Right Click to Delete`);

            //setposition to be center of polygon
            const bounds = new window.google.maps.LatLngBounds();
            polygon.getPath().forEach(function (element, index) { bounds.extend(element); });
            infoWindow.setPosition(bounds.getCenter());
            var map = polygon.getMap();
            infoWindow.open(map);
        });
        window.google.maps.event.addListener(polygon, 'mouseout', function (e) {
            infoWindow.close();
        });
    }

    function addUpdateCoordsFunction(polygon) {

        //when polygon changes
        window.google.maps.event.addListener(polygon.getPath(), 'set_at', () => {
            //remove polygon from shapes and add new one

            const polygonInfo = {
                type: 'polygon',
                path: polygon.getPath().getArray(),
                fillColor: '#1976d2',
                fillOpacity: 0.3,
                strokeWeight: 3,
                editable: true,
                draggable: true,
                geodesic: true,
                zIndex: 1,
                uniqueKey: polygon.uniqueKey
            };

            createUpdateTimer(polygon, polygonInfo);
       



        });
    }

    function createUpdateTimer(polygon, polygonInfo) {
        if (window.updateTimer) {
            clearTimeout(window.updateTimer);
        }
        window.updateTimer = setTimeout(() => {
            const filterShapes = shapes.filter(s => s.uniqueKey !== polygon.uniqueKey);
            setShapes(filterShapes);
            updateMap(JSON.stringify([...filterShapes, polygonInfo]))
        
        }, 250);
    }

    function addDeleteVertextFunction(polygon) {
        window.google.maps.event.addListener(polygon, 'rightclick', function (event) {

            if (event.vertex == undefined) {
                return;
            }

            //if menu is display do nothing

            polygon.getPath().removeAt(event.vertex);
            const polygonInfo = {
                type: 'polygon',
                path: polygon.getPath().getArray(),
                fillColor: '#1976d2',
                fillOpacity: 0.3,
                strokeWeight: 3,
                editable: true,
                draggable: true,
                geodesic: true,
                zIndex: 1,
                uniqueKey: polygon.uniqueKey
            };
            setShapes(shapes.filter(s => s.uniqueKey !== polygon.uniqueKey));
            setShapes([...shapes, polygonInfo]);
            updateMap(JSON.stringify([...shapes, polygonInfo]))
        });
    }


    function addDeletePolygonFunction(polygon) {
        //right click to open context menu
        window.google.maps.event.addListener(polygon, 'rightclick', function (event) {

            //remove div if exists
            var menu = document.getElementById(`menu-${polygon.uniqueKey}`);
            if (menu) {
                menu.parentNode.removeChild(menu);
            }
        
            //create div and show next to polygon

            var menu = document.createElement('div');
            menu.id = `menu-${polygon.uniqueKey}`;
            menu.style.position = 'absolute';
            menu.style.width = '100px';
            menu.style.height = '100px';
            menu.style.background = 'white';
            menu.style.border = '1px solid black';
            menu.style.zIndex = 1000;
            menu.style.display = 'block';
            menu.innerHTML = 'Delete';
            menu.style.cursor = 'pointer';
            menu.style.padding = '5px';
            menu.style.boxShadow = '0 0 5px rgba(0,0,0,0.5)';
            menu.style.borderRadius = '5px';
            menu.style.fontSize = '12px';
            menu.style.fontFamily = 'Arial';
            menu.style.textAlign = 'center';
            menu.style.color = 'black';
            menu.style.backgroundColor = 'white';
            menu.style.opacity = '0.9';

            //add div to map if vertex was not clicked
            if (event.vertex != undefined) {
                return;
            }

            //remove menu from map if already exists
            if (menu.parentNode) {
                menu.parentNode.removeChild(menu);
            }

            mapRef.current.controls[window.google.maps.ControlPosition.TOP_LEFT].push(menu);

            //delete polygon on click
            window.google.maps.event.addDomListener(menu, 'click', function () {
                polygon.setMap(null);
                menu.style.display = 'none';
                //delete polygon from shapes
                setDrawings([...drawings, polygon]);
                setShapes(shapes.filter(shape => shape.uniqueKey !== polygon.uniqueKey));
                updateMap(JSON.stringify(shapes.filter(shape => shape.uniqueKey !== polygon.uniqueKey)))
            });

            //close menu if right click again
            window.google.maps.event.addListener(mapRef.current, 'rightclick', (event) => {
              
                if (event.vertex !== undefined) {
                    return;
                }
                menu.style.display = 'none';
            });



            //close context menu
            window.google.maps.event.addListener(mapRef.current, 'click', function () {
                menu.style.display = 'none';
            });
        });
    }





    const onPolygonComplete = polygon => {


        polygon.uniqueKey = Math.floor(Math.random() * 1000000);

        setDrawings([...drawings, polygon]);

        //add polygon to shapes
        const polygonInfo = {
            type: 'polygon',
            path: polygon.getPath().getArray(),
            fillColor: '#1976d2',
            fillOpacity: 0.3,
            strokeWeight: 3,
            editable: true,
            draggable: true,
            geodesic: true,
            zIndex: 1,
            uniqueKey: polygon.uniqueKey
        };
     
        setShapes([...shapes, polygonInfo]);
        

        updateMap(JSON.stringify([...shapes, polygonInfo]))
        //select pointer after drawing
        drawingManager.setDrawingMode(null);
        addDeletePolygonFunction(polygon);
        addDeleteVertextFunction(polygon);
        addUpdateCoordsFunction(polygon);

      
        const sqMeters = window.google.maps.geometry.spherical.computeArea(polygon.getPath());
        const sqKm = sqMeters / 1000000;
        const sqMiles = sqMeters / 2589988.11;
        const sqFeet = sqMeters * 10.7639;
        const acres = sqMeters / 4046.86;

      




        //add label to polygon







    }






    function addMeasureTool() {
        const measureTool = new window.google.maps.drawing.DrawingManager({
            drawingMode: null,
            drawingControl: true,
            drawingControlOptions: {
                position: window.google.maps.ControlPosition.TOP_RIGHT,
                drawingModes: ['polyline']
            },
            polylineOptions: {
                strokeColor: '#FF0000',
                strokeWeight: 3,
                editable: true,
                draggable: true,
                geodesic: true,
                zIndex: 1
            }
        });
        measureTool.setMap(mapRef.current);
        window.google.maps.event.addListener(measureTool, 'polylinecomplete', function (polyline) {
          
            const meters = window.google.maps.geometry.spherical.computeLength(polyline.getPath());
            const km = meters / 1000;
            const miles = meters / 1609.34;
            const feet = meters * 3.28084;
            const yards = meters * 1.09361;

          
            //add label of feet to polyline
            const label = new window.google.maps.InfoWindow({
                content: `${feet.toFixed(2)} ft`,
                position: polyline.getPath().getArray()[0]

            });
            label.open(mapRef.current);
            polyline.label = label;
            polyline.addListener('drag', function () {
                polyline.label.setPosition(polyline.getPath().getArray()[0])
                polyline.label.setContent(`${window.google.maps.geometry.spherical.computeLength(polyline.getPath()).toFixed(2)} ft`)
            }
            )
            polyline.addListener('dragend', function () {
                polyline.label.setPosition(polyline.getPath().getArray()[0])
                polyline.label.setContent(`${window.google.maps.geometry.spherical.computeLength(polyline.getPath()).toFixed(2)} ft`)
            }
            )
            //set_at
            polyline.getPath().addListener('set_at', function () {
                polyline.label.setPosition(polyline.getPath().getArray()[0])
                polyline.label.setContent(`${window.google.maps.geometry.spherical.computeLength(polyline.getPath()).toFixed(2)} ft`)
            })

        });
    }

    function addLocations(locations, visible) {
     
        locations.forEach(location => {
            const marker = new window.google.maps.Marker({
                position: { lat: location.lat, lng: location.lon },
                map: visible ? mapRef.current : null,
                icon: {
                    url: 'https://firebasestorage.googleapis.com/v0/b/aquasource3.appspot.com/o/fieldLogsNew14ab01e-bafa-8db3-67f3-58a6b525a7d5?alt=media&token=a843ea24-b2e8-4a65-91ef-a5bbe78b268a',
                    scaledSize: new window.google.maps.Size(60, 60),
                    origin: new window.google.maps.Point(0, 0),
                    anchor: new window.google.maps.Point(10, 10)
                },
            })
            markers.push(marker)
            setMarkers(markers)
        })
    }

    function addShowProbesButton(list) {

        //create a button in the bottom left of the map that toggles the visibility of the probes
        const showProbesButton = document.createElement('button');
        showProbesButton.textContent = 'Show Probes';
        // increase text size and add padding
        showProbesButton.style.fontSize = '14px';
        showProbesButton.style.padding = '8px';
        //add border radius and white background
        showProbesButton.style.borderRadius = '4px';
        showProbesButton.style.backgroundColor = 'white';
        //add box shadow
        showProbesButton.style.boxShadow = '0 1px 4px rgba(0,0,0,0.3)';
        showProbesButton.classList.add('custom-map-control-button');
        mapRef.current.controls[window.google.maps.ControlPosition.LEFT_BOTTOM].push(showProbesButton);
        showProbesButton.addEventListener('click', () => {
            if (showProbesButton.textContent == 'Show Probes') {
                showProbesButton.textContent = 'Hide Probes';
                //show probes
                list.forEach(item => {
                    if (item.type == 'probe') {
                       
                        item.marker.setVisible(true)
                    }
                })
            } else {
                showProbesButton.textContent = 'Show Probes';
                //hide probes
                list.forEach(item => {
                    if (item.type == 'probe') {
                        item.marker.setVisible(false)
                    }
                })
            }
        });

    }

    function handleLoad(map) {
        mapRef.current = map;
        addCurrentLocationButton();
        addToAccountLocationButton();
        addShapes(JSON.parse(mapInfo), visibleDrawings, editDrawings);
        addMeasureTool()
        addLocations(locations, visibleProbes)
        if (probes.length > 0) {
            //add marker for each probe but set them to invisible
            probes.forEach(probe => {

                if (probe.recording !== undefined && probe.recording == true) {
                    const svgMarker = {
                        path: "M27.648-41.399q0-3.816-2.7-6.516t-6.516-2.7-6.516 2.7-2.7 6.516 2.7 6.516 6.516 2.7 6.516-2.7 2.7-6.516zm9.216 0q0 3.924-1.188 6.444l-13.104 27.864q-.576 1.188-1.71 1.872t-2.43.684-2.43-.684-1.674-1.872l-13.14-27.864q-1.188-2.52-1.188-6.444 0-7.632 5.4-13.032t13.032-5.4 13.032 5.4 5.4 13.032z",
                        fillColor: "blue",
                        fillOpacity: 1,
                        strokeWeight: 0,
                        rotation: 0,
                        scale: .8,
                        anchor: new window.google.maps.Point(0, 0),
                        labelOrigin: new window.google.maps.Point(15, 10)
                    };

                    //create name with line breaks every 20 characters <br />
                    let name = probe.name
                    let nameArray = name.split('')
                    let nameWithBreaks = ''
                    for (let i = 0; i < nameArray.length; i++) {
                        if (i % 20 == 0 && i != 0) {
                            nameWithBreaks += '<br />'
                        }
                        nameWithBreaks += nameArray[i]
                    }


                    const marker = new window.google.maps.Marker({
                        position: { lat: probe.lat, lng: probe.lon },
                        map: mapRef.current,
                        icon: {
                            ...svgMarker,
                            fillColor: 'red',
                            strokeColor: 'white',
                            strokeWeight: 0.5,
                        },

                        visible: false
                    });
                    probe.marker = marker;

                    //when marker is clicked, open info window showing probe name
                    marker.addListener('click', () => {
                        const infoWindow = new window.google.maps.InfoWindow({
                            content: `<div style="font-size: 14px; font-weight: 600; color: black; text-align: center;">${nameWithBreaks}</div>`
                        });
                        infoWindow.open(mapRef.current, marker);

                        //close info window when map is clicked
                        mapRef.current.addListener('click', () => {
                            infoWindow.close();
                        }
                        )
                        //close info window when another marker is clicked
                        marker.addListener('click', () => {
                            infoWindow.close();
                        }
                        )
                        //close info window if x is clicked
                        infoWindow.addListener('closeclick', () => {
                            infoWindow.close();
                        }
                        )
                    })




                    probe.type = 'probe'
                }
            })





            addShowProbesButton(probes)
        }


    }


    function handleShowProbes(e) {
        setVisibleProbes(e.target.checked)
        updateSC('showProbes', e.target.checked)
      
        if (e.target.checked) {
            markers.forEach(marker => {
                marker.setMap(mapRef.current)
            })
        }
        else {
            markers.forEach(marker => {
                marker.setMap(null)
            })
        }
    }
    function handleShowDrawings(e) {
        setVisibleDrawings(e.target.checked)
        updateSC('showDrawings', e.target.checked)

        if (e.target.checked) {
            drawings.forEach(drawing => {
                drawing.setMap(mapRef.current)
            })

        }
        else {
            drawings.forEach(drawing => {
                drawing.setMap(null)
            })
        }
    }
    function handleEditDrawings(e) {
        setEditDrawings(e.target.checked)
        updateSC('editDrawings', e.target.checked)

        if (e.target.checked) {
            //change each drawing to editable
            drawings.forEach(drawing => {
                drawing.setEditable(true)
                //change no dragging for drawing
                drawing.setDraggable(true)

            })

        }
        else {

            drawings.forEach(drawing => {
                drawing.setEditable(false)
                //change no dragging for drawing
                drawing.setDraggable(false)
            })

        }
    }





    return isLoaded && lat !== undefined && lng !== undefined ? (
        <div style={{ height: '100%', width: '100%', paddingBottom: 20 }}>
            <Col span={24}>
                <Typography display={'inline'} variant={'body1'} style={{ marginRight: 10 }}>Show Probes</Typography>
                <Switch display={'inline'} checked={visibleProbes} onChange={handleShowProbes} />
                <Typography display={'inline'} variant={'body1'} style={{ marginRight: 10, paddingLeft: 20 }}>Show Drawings</Typography>
                <Switch display={'inline'} checked={visibleDrawings} onChange={handleShowDrawings} />
                <Typography display={'inline'} variant={'body1'} style={{ marginRight: 10, paddingLeft: 20 }}>Edit Drawings</Typography>
                <Switch display={'inline'} checked={editDrawings} onChange={handleEditDrawings} />
            </Col>

            <GoogleMap
                ref={mapRef}
                mapContainerStyle={{ height: '600px', width: '100%' }}
                center={center}
                onLoad={handleLoad}
                zoom={zoom}
                mapTypeId="satellite"
                controlSize={32}
                options={{
                    scaleControl: true,
                    //satellite view
                    mapTypeId: 'satellite',
                    //disable street view
                    streetViewControl: false,
                    //disable zoom control

                    rotateControl: false,
                    //disable fullscreen control
                    fullscreenControl: false,

                    tilt: 0,
                    //disable keyboard shortcuts


                }}




            >
                {/* Child components, such as markers, info windows, etc. */}


                {visibleDrawings && (<DrawingManager
                    onLoad={onDMLoad}
                    onPolygonComplete={onPolygonComplete}
                    options={{
                        drawingControl: true,
                        drawingControlOptions: {
                            position: window.google.maps.ControlPosition.LEFT_CENTER,
                            drawingModes: ['polygon'],
                            //change size of controls
                            controlSize: 32,


                        },
                        controlSize: 32,
                        polygonOptions: {
                            fillColor: '#1976d2',
                            fillOpacity: 0.3,
                            strokeWeight: 3,
                            clickable: true,
                            draggable: true,
                            editable: true,
                            zIndex: 1,

                        },
                    }}
                />)}








                <>
                    <StandaloneSearchBox
                        onPlacesChanged={onPlacesChanged}
                        onLoad={onSBLoad}
                    >
                        <input
                            type="text"
                            placeholder="Search Places"
                            style={{
                                boxSizing: 'border-box',
                                border: `1px solid transparent`,
                                width: `270px`,
                                height: `40px`,
                                padding: `0 12px`,
                                borderRadius: `3px`,
                                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                fontSize: `14px`,
                                outline: `none`,
                                margin: 'center',
                                textOverflow: `ellipses`,
                                position: 'absolute',
                                top: '0px',
                                marginLeft: '50%'
                            }}
                        />
                    </StandaloneSearchBox>
                </>
            </GoogleMap>

        </div >
    ) : <></>


}
