

import {
    Badge,
    IconButton,
    Tooltip
} from '@mui/material';
import React from 'react';
import { faCheckCircle, faCircle, faCopy, faEye, faEyeSlash, faSquare, faSquareCheck } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const SelectableTool = ({ selectable, setSelectable, streams, totalSelected }) => {
   


    return (
        <IconButton onClick={() => setSelectable(!selectable)}

        >
            {totalSelected > 0 && selectable ? (
                <Badge badgeContent={totalSelected} color="error">
                    <Tooltip title="Toggle Selectable" placement="bottom">
                        <FontAwesomeIcon icon={selectable ? faSquareCheck : faSquare}
                            size="md" style={{ color: '#3f51b5', cursor: 'pointer' }} />
                    </Tooltip>
                </Badge>
            ) : (
                <Tooltip title="Toggle Selectable" placement="bottom">
                    <FontAwesomeIcon icon={selectable ? faSquareCheck : faSquare}
                        size="md" style={{ color: '#3f51b5', cursor: 'pointer' }} />
                </Tooltip>
            )}



        </IconButton>
    );
};

export default SelectableTool;
