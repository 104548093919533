'use strict';
import { Button, Divider, Typography, TextField, Box, OutlinedInput, IconButton, TableSortLabel } from '@mui/material';
import { Col, Row, Modal, Skeleton } from 'antd';
import React, { forwardRef, memo, useEffect, useImperativeHandle, useMemo, useRef, useState, useCallback } from 'react';
import { fullMobile } from '../../util';

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import moment from 'moment';
import MUIDataTable from "mui-datatables";
import DatePicker from "react-datepicker";
import uuid from 'react-uuid';
import { queryParameters, querySites, queryStations, queryLogData } from '../../../../firebase/config';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, } from 'firebase/firestore';
import './tableStyle.css';
import "react-datepicker/dist/react-datepicker.css";
import SiteModal from '../../../../waterQuality/samplingSites/siteModal';
import Units from '../../../../extra/units.json';
import { DeleteForever, RemoveRedEye, VisibilityOff } from '@mui/icons-material';
import axios, * as others from 'axios';

import ProbeParameters from '../../../../extra/probeParameters.json';

var randomColor = require('randomcolor');


const getMuiTheme = () =>
    createTheme({
        components: {
            MUIDataTable: {
                styleOverrides: {
                    root: {
                        padding: '8px',
                        boxShadow: 'none'
                    }
                }
            },
            MuiTableRow: {
                styleOverrides: {
                    root: {
                        zIndex: 100,
                        cursor: 'pointer',
                        fontSize: '10px',
                        '&:nth-child(even)': {
                            backgroundColor: '#40405024'
                        },
                        "&:hover": {
                            backgroundColor: "#9ec1c933"
                        }


                    },


                }
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        fontSize: '12px',
                        padding: '2px'
                    }
                }
            }

        }
    });




export default function LogMultiDay(props) {
    const { userInfo, screenWidth, openSnack, company, account, updateLog, logRef, status, } = props;
    const [log, setLog] = useState(logRef);
    const [data, setData] = useState([]);
    const [editingRow, setEditingRow] = useState(null);
    const [parameters, setParameters] = useState([]);
    const [sites, setSites] = useState([]);
    const [unitList, setUnitList] = useState([]);
    const [parameterModal, setParameterModal] = useState(false);
    const [siteModal, setSiteModal] = useState(false);
    const [parameterItem, setParameterItem] = useState({});
    const [siteItem, setSiteItem] = useState({});
    const [stations, setStations] = useState([]);
    const [loading, setLoading] = useState(false);
    const inputRef = useRef();

    useEffect(() => {
        if (logRef.multiDayList !== undefined) {
            setData(logRef.multiDayList || []);
            localStorage.setItem('multiDayList', JSON.stringify(logRef.multiDayList || []))

        }
        else {
            setData([]);
            localStorage.setItem('multiDayList', JSON.stringify([]))
        }
    }, [])











    useEffect(() => {
        if (status === 'built') {


            console.log('store WQ')
        }
    }, [status])

    useEffect(() => {



    }, [])


    useEffect(() => {
        setLog(logRef)
        if (log.key === undefined && logRef.key !== undefined) {

        }
    }, [logRef])




    //if valueref is unfocused, update the data
    useEffect(() => {

    }, [inputRef.current])




    const options = {
        filter: false,
        searchable: false,
        search: false,
        searchable: false,
        viewColumns: false,
        download: false,
        print: false,
        draggable: true,
        rowsPerPage: 50,
        rowsPerPageOptions: [50, 100, 200, 500, 1000],
        selectableRows: screenWidth === 'xs' ? false : status === 'built' ? false : true,
        filterType: 'dropdown',
        responsive: screenWidth === 'md' ? 'stacked' : 'standard',

        onRowsDelete: (rows, b) => {
            rows.data.sort((a, b) => b.index - a.index)
            const newData = [...data];
            rows.data.forEach((row) => {

                newData.splice(row.index, 1);

            })
            setData(newData);





        }
    };

    const columns = [

        {
            name: 'key',
            label: "Edit",

            options: {
                //hide column
                display: false,
                filter: false,
                sort: false,
                empty: true,

            }
        },
        {
            name: 'time',
            label: "Start Time",

            options: {
                width: 150,
                //hide column
                customBodyRender: (value, tableMeta, updateValue) => {
                    return status === 'built' ? (
                        <Typography variant="body2">{moment(value, 'x').format('MM/DD/YYYY hh:mm a')}</Typography>
                    )
                        :
                        (
                            <DatePicker style={{ height: '50px' }} portalId="root-portal" size="large" showTimeSelect timeFormat="h:mm a" timeIntervals={1} timeCaption="time" dateFormat="MM/dd/yyyy h:mm a"

                                selected={[undefined, '', "Invalid date"].includes(value) ? new Date() : new Date(moment(value, 'x'))}
                                onChange={(date) => {
                                    const unix = moment(date).format('x');
                                    const newData = [...data];
                                    const index = newData.findIndex((item) => item.key === tableMeta.rowData[0]);
                                    newData[index].time = unix;
                                    setData(newData);
                                    updateLog('multiDayList', newData)
                                    localStorage.setItem('multiDayList', JSON.stringify(newData))
                                }}
                            />
                        )
                }

            }
        },
        {
            name: 'hours',
            label: "Hours",

            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return status === 'built' ? (
                        <Typography variant="body2">{value}</Typography>
                    ) :
                        (<OutlinedInput
                            style={{ backgroundColor: 'white' }}
                            sx={{
                                backgroundColor: 'white'
                            }}
                            size="small"
                            type="number"
                            value={value}
                            onChange={(e) => {
                                const newData = [...data];
                                const index = newData.findIndex((item) => item.key === tableMeta.rowData[0]);
                                newData[index].hours = e.target.value;
                                setData(newData);
                                updateLog('multiDayList', newData)
                                localStorage.setItem('multiDayList', JSON.stringify(newData))
                            }}
                        />
                        )

                },
                customHeadLabelRender: (columnMeta, handleToggleColumn) => {
                    // Calculate the total hours
                    const totalHours = data.reduce((sum, row) => sum + parseFloat(row.hours || 0), 0);

                    return (
                        <TableSortLabel
                            {...columnMeta}
                            onClick={handleToggleColumn}
                        >
                            {columnMeta.label} (Total: {totalHours})
                        </TableSortLabel>
                    );
                },
            }
        },








    ];


    const AddRowButton = memo(() => {
        const [date, setDate] = useState(new Date());

        const handleAddRow = () => {
            const newDate = moment(date).format('x');
            const newData = [...data, { key: uuid(), time: newDate, location: null, hours: null }];
            console.log(newData)
            setData(newData);
            updateLog('multiDayList', newData)
            localStorage.setItem('multiDayList', JSON.stringify(newData))

        }

        return (
            <Button size="small"
                variant="contained" color="primary"
                style={{ textTransform: 'none', fontSize: 14, backgroundColor: '#404050' }}
                onClick={handleAddRow} >+ Add Row</Button >
        )
    })







    return (
        <Row gutter={[12, 12]} align="middle" >

            <Col {...fullMobile(12, 12)} style={{ paddingTop: 30 }}>
                <Typography display="inline" variant='h5' fontWeight={500} fontSize={screenWidth === 'xs' ? 16 : 22}>Multi Day</Typography>
                {status === 'creating' && (<IconButton display="inline" size="small" onClick={() => {
                    updateLog('hideMultiDay', !log?.hideMultiDay)
                }}>
                    {log?.hideMultiDay ? <VisibilityOff /> : <RemoveRedEye />}
                </IconButton>)}


            </Col>
            <Col {...fullMobile(12, 12)} style={{ paddingTop: 30, textAlign: 'right' }}>
                {status !== 'built' && (<AddRowButton />)}


            </Col>
            {loading && (
                <Col {...fullMobile(24, 24)} style={{ paddingTop: 10, }}>
                    <Skeleton width={400} height={100} sx={{ animationDuration: "0.3s" }} />
                </Col>
            )}

            {!log.hideMultiDay && data.length > 0 && (

                <Col id="table" {...fullMobile(24, 24)} style={{ paddingTop: 10, position: 'relative', zIndex: 10 }}>
                    <ThemeProvider theme={getMuiTheme()}>
                        <MUIDataTable data={data} columns={screenWidth === 'xs' ? [...columns, {
                            name: 'key',
                            label: "Delete",
                            options: {
                                filter: true,
                                sort: false,
                                empty: true,

                            }
                        },] : columns} options={options} />
                    </ThemeProvider>

                </Col>

            )}

            <Col {...fullMobile(24, 24)}>
                <Divider style={{
                    borderColor: 'rgb(148, 186, 195)',
                    borderBottomWidth: 'medium',

                }} />
            </Col>





        </Row>
    )



}