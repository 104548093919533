import React, { useEffect, useState } from "react";


import { Box, Checkbox, Divider, Grid, IconButton, Typography } from '@mui/material';
import { Col, Row, Table } from 'antd';
import { collection, collectionGroup, doc, getDoc, getDocs, getFirestore, onSnapshot, query, setDoc, updateDoc, where, writeBatch } from "firebase/firestore";
import moment from 'moment-timezone';
import uuid from "react-uuid";
import { faCheck, faSpinner, faSquareCheck, } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon, } from "@fortawesome/react-fontawesome";

import './newStyle.css';
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { useNavigate } from "react-router-dom";
import MobileLogsTable from "./mobileLogsTable";




const DataTable = (
    {
        prefKey,
        logs,
        setLogs,
        screenWidth,
        loading,
        currentLog,
        filteredLogs,
        headers,
        selectedLogs,
        setSelectedLogs
    }

) => {




    const navigate = useNavigate();
    const navigatePage = (id) => {
        navigate(`/newLogHome/${id}`);
    };

    const props = {
        prefKey, logs, setLogs, screenWidth, loading, currentLog, filteredLogs, headers, selectedLogs,
        setSelectedLogs
    };


    if (screenWidth !== 'xs') {
        return (
            <Table
                className="custom-logs-table"
                size="small"
                loading={loading}
                style={{ fontFamily: 'Roboto, sans-serif', cursor: 'pointer' }}
                columns={headers}
                dataSource={filteredLogs} scroll={{ x: 'auto' }}
                pagination={{ pageSize: 100 }}
                onRow={(row, rowIndex) => {
                    return {
                        onClick: () => {
                            navigatePage(row.id);
                        }, // click row
                    };
                }}
            />

        );
    }
    else {
        return (
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={1} sm={1} style={{ textAlign: 'center' }}></Grid>
                    <Grid item xs={10} sm={10}>
                        <Typography variant="body1" color="black" fontSize={20} fontWeight={600} sx={{ mt: 3 }}>
                            Logs Table
                        </Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} style={{ textAlign: 'center' }}></Grid>
                    <Grid item xs={1} sm={1} style={{ textAlign: 'center' }}></Grid>
                    <Grid item xs={10} sm={10} style={{ textAlign: 'center' }}>
                        <Box style={{
                            height: '90vh',
                            overflowY: 'scroll',
                        }}>
                            <MobileLogsTable {...props} />
                        </Box>
                    </Grid>
                    <Grid item xs={1} sm={1} style={{ textAlign: 'center' }}></Grid>

                </Grid>

            </Box>

        )
    }



};

export default DataTable;
