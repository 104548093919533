import { Map } from '@esri/react-arcgis';
import { Button, CircularProgress } from '@mui/material';
import { Col, Row } from 'antd';
import { loadModules } from 'esri-loader';
import React from 'react';
import SketchExample from '../../../blocks/colorpicker';
import Location from '../../../blocks/gis/location';
import Screenshot from '../../../blocks/gis/screenshot';
import SearchBar from '../../../blocks/gis/searchBar';
import SketchLayer from '../../../blocks/gis/sketchLayer';

loadModules(['esri/config',])
    .then(([config,]) => {
        config.apiKey = "AAPK65ed7997d0524b6582730da31dc1f8986spR1Pjc0oR3qn-NVYrj0jI7Y6156OU89gRRDdPUWC1gjMayxwOkEmxo2aoZH56R";
    })
    .catch(err => {
        // handle any errors
        console.error(err);
    });

export default class MapDrawer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            item: this.props.item,
            logKey: this.props.logKey,
            printScreen: this.props.printScreen,
            printingScreen: this.props.printingScreen,
            graphics: []
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
        if (this.props.item !== prevProps.item) {
            console.log(this.props.item);
            console.log(this.props.item.graphics !== undefined ? JSON.parse(this.props.item.graphics) : []);
            this.setState({ item: this.props.item, graphics: this.props.item.graphics !== undefined ? JSON.parse(this.props.item.graphics) : [] })
        }
        if (this.props.printScreen !== prevProps.printScreen) {
            if (this.props.printScreen === true) {
                this.setState({ printingScreen: true })
            }
            this.setState({ printScreen: this.props.printScreen })
        }
        if (this.props.printingScreen !== prevProps.printingScreen) {
            this.setState({ printingScreen: this.props.printingScreen })
        }

    }
    componentDidMount() {
    }

    changeColor = (color) => {
        const { item } = this.state;
        item.layerColor = color;
        this.setState({ item })
    }

    printScreen = () => {

        this.setState({ printScreen: true, printingScreen: true })

        setTimeout(() => {
            this.setState({ printScreen: false })
        }, 1000);

    }

    render() {

        const { account, screenWidth, item, logKey, printScreen, userInfo, printingScreen, graphics } = this.state;

        return (
            <Row gutter={[12, 12]} style={{ height: screenWidth === 'xs' ? '60%' : '70%' }} align="middle">
                <Col span={24}>
                    {printingScreen ? <div><CircularProgress />Saving Map!</div> :
                        <Button fullWidth variant='contained' color="primary" onClick={() => this.printScreen()}>Save Map</Button>
                    }
                </Col>
                <Col span={24} style={{ display: 'flex', height: '100%' }} id={`map-${item.key}`} >
                    <Map className="Map" style={{ width: '100%' }}
                        mapProperties={{ basemap: 'arcgis-imagery' }}

                        viewProperties={{
                            center: [account.lng, account.lat], 
                            zoom: 16,
                            ui: {
                                components: ["zoom",],
                            }
                        }}>

                        <SketchLayer map={this.map} view={this.view} item={item} layerColor={item.layerColor}
                            graphics={graphics}
                            logKey={logKey} addGraphics={this.props.addGraphics} />
                        <Screenshot map={this.map} view={this.view} printScreen={printScreen} item={item} userInfo={userInfo} sendImage={(a, b,) => {
                            this.setState({ printingScreen: false })
                            this.props.sendImage(a, b,)
                        }} printingScreen={printingScreen} /> 

                        <SearchBar map={this.map} view={this.view} />
                        <Location map={this.map} view={this.view} />

                    </Map>
                </Col>
                <Col span={24} style={{}}>
                    Map Settings
                </Col>
                <Col span={24} style={{ paddingBottom: 30 }}>
                    <SketchExample item={item} changeColor={this.changeColor} />
                </Col>

            </Row>
        );
    }
} 