import React, { useEffect, useState, useRef } from "react";


import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { faCheckCircle, faCircle, faCopy, faEye, faEyeSlash, faSquareCheck } from '@fortawesome/free-regular-svg-icons';
import { faFilter, faRedo, faSquare, faTrash, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Button, CircularProgress, Divider, IconButton, Tooltip, Typography, Badge, Switch, Checkbox } from '@mui/material';
import { Col, Row } from 'antd';
import moment from 'moment-timezone';
import { Resizable } from 'react-resizable';
import { ReactSortable } from 'react-sortablejs';
import './taskList.css';
import ColumnSelection from "./columnSelection";

import FilterTable from "./filterTable";
import TaskCreateList from "./taskCreateList";


import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";

 



const TasksTableMulti = (
    {
        tasks,
        setTasks,
        screenWidth,
        showCompleted,
        loading,
        setCurrentTask,
        deleteTask,
        updateTask,
        setTaskDrawer,
        duplicateTask,
        updateTasksPosition,
        headers,
        getNestedProperty,
        selectable,
        sortedTasks,
        sortItem,
        setSortItem
    }

) => {

    const [hoverKey, setHoverKey] = useState(null);
    const [undoHistory, setUndoHistory] = useState([]);
    const [redoHistory, setRedoHistory] = useState([]);
    const [initialWidth, setInitialWidth] = useState(null);
    const [lastClickedIndex, setLastClickedIndex] = useState(null);

    const textRef = useRef(null);









    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    function onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            tasks,
            result.source.index,
            result.destination.index
        );


        setSortItem(null)
        updateTasksPosition(items);
    }

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer


        // change background colour if dragging


        // styles we need to apply on draggables
        ...draggableStyle
    });

    const getListStyle = isDraggingOver => ({



    });

    return (






        <Col span={24} style={{ paddingBottom: 30, paddingRight: 0, marginRight: 0 }}>


            <DragDropContext onDragEnd={(source) => onDragEnd(source)}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                        >
                            {sortedTasks?.map((l, i) => {
                                const TaskIcon = ({ task, handleTaskUpdate }) => (
                                    <FontAwesomeIcon
                                        size='2xl'
                                        icon={task.completed ? faCheckCircle : faCircle}
                                        style={{
                                            color: task.completed ? '#1bd81b' : 'grey',
                                            cursor: 'pointer',

                                        }}
                                        onClick={() => {
                                            const newTask = { ...task, completed: !task.completed };
                                            updateTask(newTask, 'completed');
                                        }}
                                    />
                                );

                                const CheckboxIcon = ({ task, handleTaskUpdate, index }) => (
                                    <Checkbox
                                        defaultChecked={task.selected}
                                        sx={{
                                            color: '#3f51b5',
                                            '&.Mui-checked': {
                                                color: '#3f51b5',
                                            },
                                            '& .MuiSvgIcon-root': { fontSize: 24 }
                                        }}
                                        onChange={(e) => {
                                            const shiftKeyPressed = e.nativeEvent.shiftKey;



                                            if (shiftKeyPressed && lastClickedIndex !== null) {
                                                // Find the starting and ending position based on task position
                                                const startPosition = lastClickedIndex;
                                                const endPosition = index;

                                                console.log(startPosition)
                                                console.log(endPosition)


                                                const sortedPositions = [startPosition, endPosition].sort((a, b) => a - b);



                                                // Loop to set selected state to true for all rows between startPosition and endPosition
                                                let newPositionState = sortedTasks[startPosition].selected;

                                                const newSortedTasks = sortedTasks.map((task, i) => {
                                                    if (i >= sortedPositions[0] && i <= sortedPositions[1]) {
                                                        return { ...task, selected: newPositionState };
                                                    }
                                                    return task;
                                                }
                                                );
                                                setTasks(JSON.parse(JSON.stringify(newSortedTasks)));
                                            } else {
                                                // If shift key is not pressed, set this row as the first clicked row, but only setFristClickedIndex if the checkbox is not already selected
                                                const position = index;

                                                if (!task.selected) {
                                                    setLastClickedIndex(position);
                                                }


                                                // Toggle selected state for the clicked row
                                                const newTask = { ...task, selected: !task.selected };
                                                updateTask(newTask, 'selected');
                                            }
                                        }}
                                    />
                                );




                                if ((showCompleted || !l.completed) && !l.filtered) {
                                    return (
                                        <Draggable key={l.id} draggableId={l.id} index={i}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                >
                                                    <div style={{
                                                        display: 'inline-flex',
                                                        width: headers?.filter((header) => header.visible === true).length === 1 ? '100%' : `auto`,
                                                        //nowrap
                                                        flexWrap: 'nowrap',
                                                        cursor: 'pointer',

                                                    }}
                                                        className={`row-hover-effect ${l.selected ? "sortable-selected" : ""}`}
                                                        onMouseEnter={() => setHoverKey(l.id)}
                                                        onMouseLeave={() => setHoverKey(null)}
                                                    >
                                                        <div className="box" style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            width: 20,
                                                            borderBottom: '1px solid rgba(0,0,0,0.2)'
                                                        }}>
                                                            <div className="my-handle" style={{ display: 'inline' }}>
                                                                <DragIndicatorIcon />
                                                            </div>

                                                        </div>

                                                        {selectable && (<div className="box" style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            width: 60,
                                                            borderBottom: '1px solid rgba(0,0,0,0.2)'
                                                        }}>
                                                            <CheckboxIcon task={l} updateTask={updateTask} index={i} />
                                                        </div>
                                                        )}
                                                        <div className="box" style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            width: 60,
                                                            borderBottom: '1px solid rgba(0,0,0,0.2)'
                                                        }}>

                                                            <TaskIcon task={l} updateTask={updateTask} />
                                                        </div>



                                                        {headers?.map((header, i) => {

                                                            const value = getNestedProperty(l, header.id, header.getLabel, header.optionType, header?.options, header?.decimals);



                                                            if (header.visible === false) return null;
 
                                                            return (
                                                                <div className="box" style={{
                                                                    width: headers.filter((header) => header.visible === true).length === 1 ? '100%' : `${header.width}px`,
                                                                    borderLeft: '1px solid rgba(0,0,0,0.2)',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'left',
                                                                    padding: 8,
                                                                    borderBottom: '1px solid rgba(0,0,0,0.2)'
                                                                }}
                                                                    onClick={() => {
                                                                        setCurrentTask(l);
                                                                        setTaskDrawer(true);
                                                                    }}

                                                                >
                                                                    {header.optionType === 'colorSelect' && (
                                                                        <FontAwesomeIcon icon={faSquare} color={value} size="lg" style={{
                                                                            marginRight: 10
                                                                        }} />
                                                                    )}
                                                                    <Typography variant="body2" fontWeight={500} fontSize={13}>
                                                                        <span ref={textRef}>{header.type === 'date' || header.optionType === 'dateSelect' ?
                                                                            (value !== undefined ? moment(value, 'x').format(
                                                                                screenWidth === 'xs' ? 'MMM DD, YY ha' : 'MM/DD/YY ha') : "No Date") :
                                                                            header.optionType === 'money' ? `$${value || ''}` : value
                                                                        }
                                                                        </span>
                                                                    </Typography>
                                                                </div>
                                                            )
                                                        })}

                                                        <div className="box fixed-column" style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            paddingRight: 10,
                                                            paddingLeft: 10,
                                                            width: 150,
                                                            borderBottom: '1px solid rgba(0,0,0,0.2)',
                                                            borderLeft: '1px solid rgba(0,0,0,0.2)',
                                                            //little left box shadow
                                                            boxShadow: 'inset 5px 0px 5px -5px rgba(0,0,0,0.2)'
                                                        }}>
                                                            <IconButton size="sm">

                                                                <Tooltip title="Duplicate" placement="bottom">
                                                                    <FontAwesomeIcon icon={faCopy} size="xs" style={{ color: 'gray', cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            duplicateTask(l)
                                                                        }} />
                                                                </Tooltip>

                                                            </IconButton>
                                                            <IconButton size="sm">

                                                                <Tooltip title="Delete" placement="bottom">
                                                                    <FontAwesomeIcon icon={faTrash} size="xs" style={{ color: 'gray', cursor: 'pointer' }}
                                                                        onClick={() => { deleteTask(l) }} />
                                                                </Tooltip>

                                                            </IconButton>
                                                        </div>


                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>




                                    );
                                } else {
                                    return <div key={i}></div>;
                                }
                            })}


                        </div>
                    )
                    }
                </Droppable>
            </DragDropContext>

            {loading === false && tasks?.length === 0 && (<Col span={24} style={{ paddingBottom: 30, textAlign: 'center' }}>
                <Typography variant="h4" fontSize={24} fontWeight={600}>No Tasks</Typography>
            </Col>)}
            {loading && (<Col span={24} style={{ paddingBottom: 30, textAlign: 'center' }}>
                <CircularProgress />
            </Col>)}

        </Col>





    );
};

export default TasksTableMulti;
