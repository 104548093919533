import React, { useEffect, useState } from "react";


import { faBox, faBucket, faCheck, faCircleInfo, faFish, faFlask, faInfo, faListCheck, faMagnifyingGlass, faPlus, faWheatAlt, faWrench, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Autocomplete, Box, Button, ButtonGroup, Divider, FormControl, Grid, IconButton, Input, InputLabel, MenuItem, Modal, OutlinedInput, Select, TextField, Tooltip, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import { makeStyles } from "@mui/styles";
import StocksSelect from "../../../../../../tasks/components/drawercomponents/stockSelect";



const NodeSelection = (
    {
        record,
        items,
        setItems,
        screenWidth,
        openSnack,
        userInfo,
        updateRecord,
        stocks,
        setStocks,
        accounts,
        stations,
        setStations,
        setDisableDrag





    }

) => {
    const [isFocused, setIsFocused] = useState(false);




    return (
        <>


            <Box display="flex" alignItems="center" gap={1}>
                <Typography variant="h6" component="div" color={'rgba(0,0,0,0.8)'} fontWeight={700} fontSize={13}>
                    Location
                </Typography>
                <Tooltip title="This ties this field log item to this node/location for reporting needs">

                    <FontAwesomeIcon icon={faCircleInfo} style={{ marginTop: -6, }} />

                </Tooltip>
            </Box>
            <Autocomplete
                fullWidth
                size="small"
                style={{ backgroundColor: '#ffffff' }}
                disableCloseOnSelect={false}
                groupBy={(option) => option?.stationLabel}
                id="combo-box-demo"
                value={stations.find(x => x.id === record?.location)?.label}

                renderGroup={(params) => {
                    return (
                        <Box key={params.key}>
                            <Typography fontWeight={800} fontSize={16} p={1}>
                                {params.group}
                            </Typography>
                            {React.Children.map(params.children, (child, index) => {
                                return (
                                    <Box style={{
                                        borderBottom: '1px solid #e0e0e0'
                                    }}>
                                        {child}
                                    </Box>
                                )
                            })}
                        </Box>
                    )
                }
                }
                options={stations?.sort((a, b) => {
                    // Convert to strings and lowercase
                    const aValue = String(a.label).toLowerCase();
                    const bValue = String(b.label).toLowerCase();

                    // Perform the sorting
                    if (aValue < bValue) return -1;
                    if (aValue > bValue) return 1;
                    return 0;
                })}

                onClose={(e) => {
                    console.log('BLURRED')
                    e.stopPropagation();
                    e.preventDefault();
                    setDisableDrag(false);
                }}



                renderInput={(params) => {
                    return (
                        <TextField {...params} placeholder="Location" variant={'outlined'}
                            onClick={(e) => {
                                console.log('CLICKED')
                                e.stopPropagation();
                                e.preventDefault();
                                setDisableDrag(true);
                            }}
                            sx={{
                                '& .MuiInputBase-root': {
                                    color: 'black',
                                    fontWeight: 500,
                                    fontSize: 14,
                                    backgroundColor: '#ffffff',

                                },

                            }} />
                    )
                }}

                onChange={(a, d) => {


                    updateRecord({ ...record, location: d?.id || null })





                }}

            />








        </>



    );
};

export default NodeSelection;
