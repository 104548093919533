import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextField, Typography, Autocomplete, Modal, Box, Checkbox, Select, MenuItem, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { fetchCameras } from './firestore';
import { Col, Row } from 'antd';
import AlertRowEmailDistribution from '../stations/AlertRowEmailDistribution';
import AlertRowSMSDistribution from '../stations/AlertRowSMSDistribution';
import { updateNewAlert } from '../stations/firestore';
import AlertSnoozeDatePicker from '../stations/AlertSnoozeDatePicker';
import moment from 'moment-timezone';
const EditAlert = ({
    screenWidth,
    openModal,
    setOpenModal,
    type,
    handleCreate,
    handleSave,
    currentItem,
    setCurrentItem,
    openSnack,
    parameters,
    staff,
    updateAlert,
    updateFullAlert,
    updateNotification,
    userInfo

}) => {


    const [open, setOpen] = useState(false);
    const [emailDistribution, setEmailDistribution] = useState(currentItem?.emailDistribution || []);
    const [smsDistribution, setSmsDistribution] = useState(currentItem?.smsDistribution || []);
    const [fullEmailDistribution, setFullEmailDistribution] = useState(currentItem?.emailDistribution || []);
    const [fullSmsDistribution, setFullSMSDistribution] = useState(currentItem?.fullSMSDistribution || []);
    const [snoozeDate, setSnoozeDate] = useState(currentItem?.snoozeDate === null ? undefined : currentItem?.snoozeDate || undefined);

    useEffect(() => {
        console.log(currentItem)
        setSnoozeDate(currentItem?.snoozeDate === null ? undefined : currentItem?.snoozeDate || undefined)
        setEmailDistribution(currentItem?.emailDistribution || []);
        setSmsDistribution(currentItem?.smsDistribution || []);
        setFullEmailDistribution(currentItem?.fullEmailDistribution || []);
        setFullSMSDistribution(currentItem?.fullSMSDistribution || []);

    }, [currentItem])

    const handleClose = () => {
        setOpen(false);
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };



    return (
        <Dialog open={openModal} onClose={() => setOpenModal(false)}
            PaperProps={{
                style: {
                    backgroundColor: '#f7f7f7',

                },
            }}>
            <DialogTitle style={{ paddingBottom: 1 }}>{type === 'new' ? "Create an Alert" : "Edit an Alert"}</DialogTitle>
            <form
                style={{ width: screenWidth === 'xs' ? '90%' : '500px' }}

            >
                <DialogContent>
                    <DialogContentText>
                        {type === 'new' ? "Enter a name for the new alert." : "Edit your alert information."}
                    </DialogContentText>
                    <Row gutter={[8, 8]}>
                        <Col span={20} style={{}}>

                            <Typography variant="body1" fontSize={15} fontWeight={500} style={{ marginTop: 12, paddingBottom: 0 }}>
                                Alert Name<span style={{ color: 'red' }}>*</span>
                            </Typography>
                            <TextField
                                style={{ paddingTop: 0, marginTop: 0, backgroundColor: '#fff' }}
                                size="small"
                                autoFocus
                                margin="dense"
                                placeholder="Camera Name"
                                type="text"
                                fullWidth
                                value={currentItem?.name}
                                onChange={(e) => {
                                    setCurrentItem({ ...currentItem, name: e.target.value });
                                }}

                            />
                        </Col>
                        <Col span={4} style={{ textAlign: 'center' }}>
                            <Typography variant="body1" fontSize={15} fontWeight={500} style={{ marginTop: 12, paddingBottom: 0 }}>
                                Active
                            </Typography>
                            <Checkbox checked={currentItem.active}


                                onChange={(e) => {
                                    setCurrentItem({ ...currentItem, active: e.target.checked })
                                }

                                } />
                        </Col>

                    </Row>

                    <Typography variant="body1" fontSize={15} fontWeight={500} style={{ marginTop: 12, paddingBottom: 0 }}>
                        Parameter<span style={{ color: 'red' }}>*</span>
                    </Typography>



                    {currentItem.type !== 'locationDrift' && (<Autocomplete
                        style={{ backgroundColor: 'white' }}
                        size="small"
                        options={parameters.sort((a, b) => -b.label.localeCompare(a.label))}
                        getOptionLabel={(option) => option.label}
                        value={parameters.find((param) => param.id === currentItem.parameter) || null}
                        onChange={(event, newValue) => {
                            setCurrentItem({ ...currentItem, parameter: newValue ? newValue.id : null });
                        }
                        }
                        renderOption={(props, option) => (
                            <li {...props} style={{ fontSize: 14 }}>
                                {option.label}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} placeholder="Parameter" />
                        )}
                    />)}


                    <Row gutter={[8, 8]}>
                        <Col span={12}>
                            <Typography variant="body1" fontSize={15} fontWeight={500} style={{ marginTop: 12, paddingBottom: 0 }}>
                                Value<span style={{ color: 'red' }}>*</span>
                            </Typography>
                            {currentItem.type !== 'locationDrift' && (<TextField
                                fullWidth
                                style={{ backgroundColor: 'white' }}
                                size="small"
                                type="number"
                                placeholder="Value"
                                defaultValue={currentItem.value}
                                onChange={(e) => {
                                    if (e.target.value !== "") {

                                    } else {

                                    }

                                }}
                                onBlur={(e) => {
                                    if (e.target.value !== "") {
                                        setCurrentItem({ ...currentItem, value: e.target.value })
                                    } else {
                                        setCurrentItem({ ...currentItem, value: 0 })
                                    }
                                }}
                            />)}
                        </Col>

                        <Col span={12}>
                            <Typography variant="body1" fontSize={15} fontWeight={500} style={{ marginTop: 12, paddingBottom: 0 }}>
                                Type
                            </Typography>
                            <Select
                                style={{ minWidth: "140px", backgroundColor: 'white' }}
                                size="small"
                                defaultValue={currentItem.type}
                                placeholder="Type"
                                onChange={(e) => {
                                    setCurrentItem({ ...currentItem, type: e.target.value })
                                }

                                }
                            >
                                <MenuItem value={"above"}>Above Threshold</MenuItem>
                                <MenuItem value={"below"}>Below Threshold</MenuItem>
                                {/* <MenuItem value={"locationDrift"}>Location Drift</MenuItem> */}
                            </Select>
                        </Col>
                        <Col span={12}>
                            <Typography variant="body1" fontSize={15} fontWeight={500} style={{ marginTop: 12, paddingBottom: 0 }}>
                                Frequency
                                <Tooltip title="One off will disable the alert once triggered.">
                                    <FontAwesomeIcon icon={faCircleInfo} style={{ marginLeft: 5 }} color="#404050" />
                                </Tooltip>
                            </Typography>
                            <Select
                                style={{ minWidth: "140px", backgroundColor: 'white' }}
                                size="small"
                                defaultValue={currentItem.alertType || 'continuous'}
                                placeholder="Type"
                                onChange={(e) => {
                                    console.log("UPDATE ALERTTYPE")
                                    setCurrentItem({ ...currentItem, alertType: e.target.value })
                                }

                                }
                            >
                                <MenuItem value={"continuous"}>Continuous</MenuItem>
                                <MenuItem value={"oneOff"}>One off</MenuItem>
                                <MenuItem value={"daily"}>Daily</MenuItem>
                                <MenuItem value={"weekly"}>Weekly</MenuItem>
                                {/* <MenuItem value={"locationDrift"}>Location Drift</MenuItem> */}
                            </Select>
                        </Col>

                        <Col span={24}>
                            <Typography fontSize={13} fontWeight={600} variant="body1">Email Distribution<span style={{ color: 'red' }}>*</span></Typography>
                            <AlertRowEmailDistribution alert={currentItem} updateAlert={async (a, b, c) => {
                                if (b === 'emailDistribution') {
                                    setEmailDistribution(c)
                                }
                                else {
                                    setFullEmailDistribution(c)
                                }
                            }} staff={staff} />

                        </Col>
                        <Col span={24}>
                            <Typography fontSize={13} fontWeight={600} variant="body1">SMS Distribution<span style={{ color: 'red' }}>*</span></Typography>
                            <Row>
                                <Col span={4}>
                                    <Checkbox checked={currentItem.sms}


                                        onChange={(e) => {
                                            setCurrentItem({ ...currentItem, sms: e.target.checked })
                                        }

                                        } />
                                </Col>
                                <Col span={20}>
                                    <AlertRowSMSDistribution alert={currentItem} updateAlert={async (a, b, c) => {
                                        if (b === 'smsDistribution') {
                                            setSmsDistribution(c)
                                        }
                                        else {
                                            setFullSMSDistribution(c)
                                        }
                                    }} staff={staff} />
                                </Col>

                            </Row>

                        </Col>
                        <Col span={24}>

                            <Typography fontSize={13} fontWeight={600} variant="body1">Snooze
                                <Tooltip title="Alert silenced up to this date.">
                                    <FontAwesomeIcon icon={faCircleInfo} style={{ marginLeft: 5 }} color="#404050" />
                                </Tooltip>
                                {moment().isBefore(snoozeDate) && (' zzz')}
                                <AlertSnoozeDatePicker alert={{ id: currentItem.id, snoozeDate }} updateAlert={async (a, b, c) => {
                                    setSnoozeDate(c)
                                }} />
                            </Typography>

                        </Col>
                    </Row>




                </DialogContent>
                <DialogActions>
                    <Button style={{
                        textTransform: 'none',
                        color: '#404050',
                    }} onClick={() => setOpenModal(false)}>Cancel</Button>
                    <Button
                        style={{
                            backgroundColor: '#404050',
                            color: 'white',
                            textTransform: 'none',
                        }}
                        onClick={async (e) => {
                            setOpenModal(false);
                            const item = {
                                name: currentItem.name,
                                parameter: currentItem.parameter,
                                value: currentItem.value,
                                type: currentItem.type,
                                active: currentItem.active,
                                alertType: currentItem.alertType || 'continuous',
                                emailDistribution: emailDistribution || [],
                                fullEmailDistribution: fullEmailDistribution || [],
                                smsDistribution: smsDistribution || [],
                                fullSMSDistribution: fullSmsDistribution || [],
                                sms: currentItem.sms || false,
                                id: currentItem.id || null,
                                snoozeDate: snoozeDate || null,
                            }
                            console.log(item)
                            console.log(currentItem)
                            updateAlert(item)
                            updateFullAlert({
                                ...currentItem,
                                snoozeDate: snoozeDate || null,
                                emailDistribution: emailDistribution || [],
                                fullEmailDistribution: fullEmailDistribution || [],
                                smsDistribution: smsDistribution || [],
                                fullSMSDistribution: fullSmsDistribution || [],

                            })

                        }}
                    >
                        {type === 'new' ? "Create Alert" : "Save Alert"}
                    </Button>

                </DialogActions>

            </form>
        </Dialog>
    );
};

export default EditAlert;
