import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { deleteFilesFunction } from "../../utils/filesFunctions";
import FileCover from "./fileCover";
import { useDropzone } from "react-dropzone";





const UploadFilesModal = ({
    screenWidth,
    openSnack,
    userInfo,
    files,
    uploadModal,
    setUploadModal,
    createFiles,


}) => {
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [dragOver, setDragOver] = useState(false);


    const { getRootProps, getInputProps } = useDropzone({
        onDrop: async (acceptedFiles) => {
            console.log(acceptedFiles)
            setUploadModal(false)
            createFiles(acceptedFiles)
        },
        onDragEnter: () => {
            setDragOver(true)
        },
        onDragLeave: () => {
            setDragOver(false)
        },
        accept: 'application/*, image/*, video/*, audio/*',  // Add this line to accept only certain image types
        // Optional: if you want to disable opening the file dialog on click
        // Optional: if you want to disable opening the file dialog with the keyboard
        // ... other configurations
    });



    return (

        <div style={{ marginTop: 20, marginBottom: 20 }}>


            <Modal title="" open={uploadModal}



                onClose={() => {
                    setUploadModal(false)


                }} footer={null} width={'60%'}  >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: screenWidth === 'xs' ? '90%' : '50%',
                    maxHeight: '70vh',
                    bgcolor: 'background.paper',
                    border: '1px solid rgba(0, 0, 0, .3)',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: '24px',
                    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
                }}>

                    <div {...getRootProps()}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: 20,
                            borderRadius: 5,
                            border: dragOver ? '2px dashed rgba(0,0,0,0.8)' : '2px dashed rgba(0,0,0,0.4)',
                            borderRadius: 5,
                            cursor: 'pointer',
                            backgroundColor: dragOver ? 'rgba(0,0,0,0.3)' : 'white',
                            height: 400,
                            width: '100%',
                            marginBottom: 20,
                            marginTop: 20
                        }}



                    >
                        <input {...getInputProps()} />

                        <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography variant="h5" align="center" fontSize={screenWidth === 'xs' ? 20 : 24} fontWeight={700} style={{ color: 'rgba(0,0,0,0.7)', display: 'block', width: '100%' }}>
                                Drag and drop files here
                            </Typography>
                            <br />
                            <Typography variant="h5" align="center" fontSize={screenWidth === 'xs' ? 16 : 20} fontWeight={600} style={{ color: 'rgba(0,0,0,0.7)', display: 'block', width: '100%' }}>
                                Click to add files
                            </Typography>
                        </Box>


                    </div>




















                </Box>
            </Modal >
        </div >

    );

};

export default UploadFilesModal;
