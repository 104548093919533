import { Divider, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import { getFirestore } from 'firebase/firestore';
import React, { useEffect, useRef, useState } from 'react';
import { fullMobile } from '../util';
import { getStaff, getUsers } from './components/firestore';
import StaffTable from './components/staffTable';







export default function AccountStaffHome({ userInfo, screenWidth, openSnack, company, account }) {
    const [loading, setLoading] = useState(true);
    const [locations, setLocations] = useState([]);
    const [data, setData] = useState([]);
    const gridRef = useRef();




    useEffect(() => {
        console.log(company)
        window.scrollTo(0, 0)



    }, []);






    return (
        <Row style={{ fontFamily: 'Roboto, sans-serif', }} align="middle" >
            <Col {...fullMobile(24, 24)} style={{ padding: 20 }}>
                <Typography variant="h4" component="h4" display="ineline" gutterBottom>
                    Account Staff
                </Typography>
            </Col>
            <Col {...fullMobile(24, 24)} style={{}}>
                <Divider />

            </Col>
            <Col {...fullMobile(24, 24)} style={{ paddingLeft: 20, paddingTop: 20 }}>
                <StaffTable
                    userInfo={userInfo}
                    openSnack={openSnack}
                />

            </Col>
            <Col {...fullMobile(24, 24)} style={{ paddingLeft: 20, paddingRight: 20 }}>

            </Col>





        </Row>
    );
}