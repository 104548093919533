import React, { useState } from "react";


import { Button, ButtonGroup, Grid, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import '../../dataTable.css';
import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";



const CSVUploadSteps = (
    {
        step,
        setStep,
        uploadType,
        setUploadType,
        setActiveTab,
        activeTab,
        screenWidth,
        steps,
    }

) => {

    const stepOrder = ["Current", "Waiting", "Complete"];




    const StepComponent = ({ stepStatus, stepTitle, stepDescription }) => {

        return (

            <Grid item xs={12} sm={6} md={3} key={stepStatus}


                sx={{
                    borderBottom: stepStatus === "Current" ? "3px solid #3f51b5"
                        : "1px solid #d3d3d3",
                    px: 2

                }}
            >
                <Row align="middle">
                    <FontAwesomeIcon icon={["Current", "Waiting"].includes(stepStatus) ? faCircle : faCircleCheck}

                        style={{
                            fontSize: 16,
                            color: stepStatus === "Current" ? "#3d51b5" : stepStatus === "Completed" ? "green" : "#979797",
                            marginRight: 10
                        }} />
                    <Typography variant="h6" fontWeight={700} fontSize={12} gutterBottom component="div"
                        style={{
                            display: 'inline',
                            color: stepStatus === "Current" ? "inherit" : stepStatus === "Completed" ? 'green' : "#979797",
                        }}
                        sx={{
                            pt: 1
                        }}>
                        {stepStatus}
                    </Typography>
                </Row>


                <Typography variant="h5" fontWeight={600} fontSize={16} gutterBottom component="div"
                    style={{
                        color: ["Current", "Complete"].includes(stepStatus) ? "inherit" : "#979797",
                    }}>
                    {stepTitle}
                </Typography>

                <Typography variant="body1" fontSize={13} gutterBottom component="div" style={{
                    color: ["Current", "Complete"].includes(stepStatus) ? "inherit" : "#979797",
                }}>
                    {stepDescription}
                </Typography>


            </Grid>
        )
    }

    return (
        <Row>
            <Grid container  sx={{
                mt: 1
            }} >
                {steps.map((s) => (
                    <StepComponent
                        stepStatus={s.stepStatus}
                        stepTitle={s.stepTitle}
                        stepDescription={s.stepDescription}
                        step={step}
                    />
                ))}

            </Grid>
        </Row>

    );
};

export default CSVUploadSteps;
