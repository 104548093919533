import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SearchIcon from '@mui/icons-material/Search';
import { Divider, Fab, IconButton, Input, InputAdornment, Paper, Typography } from '@mui/material';
import { Col, Popconfirm, Row, Table, Tag } from 'antd';
import { collection, doc, getDocs, getFirestore, query, setDoc, updateDoc, where } from 'firebase/firestore';
import moment from 'moment';
import React from 'react';
import { useNavigate } from 'react-router-dom';


export default class FieldLogs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            data: [],
            filteredData: [],
            filters: [],
            searchText: '',
            loading: true,
            level: this.props.level,
            accounts: [],
            accountsLoad: true
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
            this.queryData()
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0)
        const { userInfo } = this.state;
        if (Object.values(userInfo).length === 0) {
            this.props.getUser()
        } else {
            this.queryData()
            this.queryAccounts()
        }

    }

    queryData = async () => {
        const { userInfo, level } = this.state;
        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'fieldLogs'), where('archived', '==', false));
        const queryAccountData = query(collection(db, "clients", userInfo.currentCompany, 'fieldLogs'), );
        const data = [];
        const snap = await getDocs(level === 'account' ? queryAccountData : queryData);
        snap.forEach((doc) => {
            data.push({
                status: doc.data().status,
                account: doc.data().account,
                logNumber: doc.data().logNumber,
                date: doc.data().date || null,
                leadStaff: doc.data().leadStaff || null,
                description: doc.data().lineItems || null,
                key: doc.data().key,
            })


        });

        data.sort((a, b) => b.date - a.date)
        this.setState({ data, loading: false })

        this.filterData(data, '', [])
    }

    queryAccounts = async () => {
        const { userInfo } = this.state;
        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'accounts'));
        const accounts = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            accounts.push({
                accountName: doc.data().accountName,
                key: doc.data().key,
                type: doc.data().seasonal || null,
                city: doc.data().city || null,
                countyName: doc.data().countyName || null,
                state: doc.data().state || null,
                routeDay: doc.data().routeDay || null,
                routeName: doc.data().routeName || null,
                visible: true
            })


        });
        accounts.sort((a, b) => a.accountName.localeCompare(b.accountName))
        this.setState({ accounts, accountsLoad: false })


    }

    filterData = (data, searchText, filters) => {
        if (searchText.length === 0 && filters.length === 0) {
            const filteredData = data.map((d) => { return ({ ...d, visible: true }) })
            this.setState({ filteredData, })
        }
        else {
            const filteredData = data.filter(d => d.accountName.match(new RegExp(searchText, "i")))
            this.setState({ filteredData })
        }

    }

    render() {

        const { userInfo, data, filteredData, loading, searchText, filters, level, account, accounts, accountsLoad } = this.state;
        const db = getFirestore();
        const ViewItem = ({ row }) => {
            let navigate = useNavigate();
            return (
                <IconButton color="primary" size="small" onClick={async () => {

                    const userRef = doc(db, "users", userInfo.id, 'currentItems', 'currentFieldLog');
                    await setDoc(userRef, {
                        key: row.key
                    }).then(() => {
                        navigate("/fieldLog");
                    })
                }}>
                    <EditIcon color="inherit" />
                </IconButton>
            )

        }
        const DeleteItem = ({ row }) => {
            let navigate = useNavigate();
            return (
                <Popconfirm
                    title="Are you sure to delete this field log?"
                    onConfirm={async (e) => {
                        e.stopPropagation();
                        const logRef = doc(db, "clients", userInfo.currentCompany, 'fieldLogs', row.key);

                        await updateDoc(logRef, {
                            archived: true,

                        }).then(async () => {

                            const queryAccountData = query(collection(db, "clients", userInfo.currentCompany, 'flItems'), where('logKey', '==', row.key));
                            const data = [];
                            const snap = await getDocs(queryAccountData);
                            snap.forEach(async (doc) => {

                                data.push(doc.data())


                            });


                            data.map(async (d) => {
                                const itemRef = doc(db, "clients", userInfo.currentCompany, 'flItems', d.key);

                                await updateDoc(itemRef, {
                                    archived: true,
                                })
                            })


                        }).then(async () => {
                            this.props.openSnack('success', 'Log removed.');
                            const newData = filteredData.filter((f) => f.key !== row.key)
                            this.setState({ filteredData: newData })
                        })




                    }}
                    okText="Yes"
                    cancelText="No"
                >
                    <IconButton style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="small"
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <DeleteForeverIcon />
                    </IconButton>
                </Popconfirm>


            )
        }


        const CreateItemButton = () => {
            let navigate = useNavigate();

            return (
                <Fab style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="medium"
                    onClick={async () => {
                        const userRef = doc(db, "users", userInfo.id, 'currentItems', 'currentFieldLog');
                        await setDoc(userRef, {
                            key: 'none'
                        }).then(() => {
                            navigate("/fieldLog");
                        })
                    }}>
                    <AddIcon />
                </Fab>
            )
        }


        const columns = [
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (a) => {

                    return (
                        <Tag
                            color={a === 'edit' ? "blue" : a === 'draft' ? 'blue' : a === 'created' ? 'orange' : 'green'}>
                            {a === 'edit' ? "Draft" : a === 'draft' ? 'Draft' : a === 'created' ? 'Created' : 'Distributed'}</Tag>
                    )
                },
                sorter: (a, b) => {
                    const c = a.status === null ? "" : a.status;
                    const d = b.status === null ? "" : b.status;
                    return c.localeCompare(d);
                }
            },
            {
                title: 'Log #',
                dataIndex: 'logNumber',
                key: 'logNumber',
                sorter: (a, b) => {
                    const c = a.logNumber === null ? "" : a.logNumber;
                    const d = b.logNumber === null ? "" : b.logNumber;
                    return c - d;
                }
            },
            {
                title: 'Date',
                dataIndex: 'date',
                key: 'date',
                render: (a) => <div >{moment(a, 'x').format('MM/DD/YYYY')}</div>,
                sorter: (a, b) => {
                    const c = a.date === null ? "" : a.date;
                    const d = b.date === null ? "" : b.date;
                    return c - d;
                }
            },


           
            {
                title: '',
                dataIndex: '',
                key: 'delete',
                id: 'delete',

                render: (a, row) => <DeleteItem row={row} style={{ position: 'relative', zIndex: 10 }} />,
            },



        ];
        const companyColumns = [
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (a) => {
                    return (
                        <Tag color={a === 'draft' ? 'blue' : a === 'created' ? 'red' : 'green'}>{a === 'draft' ? 'Draft' : a === 'created' ? 'Created' : 'Distributed'}</Tag>
                    )
                },
                sorter: (a, b) => {
                    const c = a.status === null ? "" : a.status;
                    const d = b.status === null ? "" : b.status;
                    return c.localeCompare(d);
                }
            },
            {
                title: 'Account',
                dataIndex: 'account',
                key: 'account',
                render: (a) => {
                    const account = accounts.filter(b => b.key === a)[0];
                    if (account) {
                        return (<div>{account.accountName}</div>)
                    }
                    else {
                        return (<div></div>)
                    }


                },
                sorter: (a, b) => {
                    const c = a.account === null ? "" : a.account;
                    const d = b.account === null ? "" : b.account;
                    return c.localeCompare(d);
                }
            },
            {
                title: 'Log #',
                dataIndex: 'logNumber',
                key: 'logNumber',
                sorter: (a, b) => {
                    const c = a.logNumber === null ? "" : a.logNumber;
                    const d = b.logNumber === null ? "" : b.logNumber;
                    return c - d;
                }
            },
            {
                title: 'Date',
                dataIndex: 'date',
                key: 'date',
                render: (a) => <div >{moment(a, 'x').format('MM/DD/YYYY')}</div>,
                sorter: (a, b) => {
                    const c = a.date === null ? "" : a.date;
                    const d = b.date === null ? "" : b.date;
                    return c - d;
                }
            },


            {
                title: '',
                dataIndex: '',
                key: 'none',
                render: (a, row) => <ViewItem row={row} />,
            },





        ];

        const ClickableTable = () => {
            let navigate = useNavigate();
            return (
                <Table
                    size="small"
                    loading={loading}
                    style={{ fontFamily: 'Roboto, sans-serif', cursor: 'pointer' }}
                    columns={level === 'account' ? columns : companyColumns}
                    dataSource={filteredData} scroll={{ x: 'auto' }}
                    pagination={{ pageSize: 100 }}
                  onRow={(row, rowIndex) => {
                      
                      return {
                          onClick: async (event) => {
                              console.log(event)
                              const db = getFirestore();
                              const userRef = doc(db, "users", userInfo.id, 'currentItems', 'currentFieldLog');
                              await setDoc(userRef, {
                                  key: row.key
                              }).then(() => {
                                  navigate("/fieldLog");
                              })
                          }, // click row
                      };
                  }}
                />
            )

        }

        return (
            <Row style={{ position: 'relative', top: 0 }}>

                <Col span={23}>
                    <Row align="middle">
                        <Col xs={16} sm={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>
                            <Typography variant="h4">Your Field Logs</Typography>
                        </Col>
                        {level === 'account' && (<Col xs={6} sm={4} style={{ paddingTop: 30, paddingBottom: 15, textAlign: 'right' }}>
                            <CreateItemButton />
                        </Col>)}
                        <Col span={24}>
                            <Divider />
                        </Col>
                    </Row>
                </Col>



                <Col span={23} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 30 }}>
                    <Paper elevation={16} style={{ padding: 20, borderRadius: 12 }}>
                        <Row>
                            <Col xs={24} sm={12} style={{ paddingBottom: 12 }}>
                                <Input fullWidth
                                    autoComplete='off'
                                    value={searchText}
                                    onChange={(e) => {
                                        this.setState({ searchText: e.target.value })
                                        if (e.target.value.length > 2) {
                                            this.filterData(data, e.target.value, filters)
                                        }
                                        else {
                                            this.filterData(data, '', filters)
                                        }
                                    }}
                                    id="input-with-icon-adornment"
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    }
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                onClick={() => {
                                                    this.setState({ searchText: '' })
                                                    this.filterData(data, '', filters)
                                                }}
                                            >
                                                <HighlightOffIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </Col>
                            <Col span={24}>
                                {accountsLoad === false && (
                                    <ClickableTable />
                                )}
                            </Col>
                        </Row>
                    </Paper>


                </Col>
            </Row>
        );
    }
} 