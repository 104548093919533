import { CircularProgress, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';




var randomColor = require('randomcolor');

export default function WidgetSiteInfo(props) {
    const { userInfo, screenWidth, openSnack, company, account, attributes, } = props;
    const [loading, setLoading] = useState(true);
    const [item, setItem] = useState({});
    const [openSettings, setOpenSettings] = useState(false);

    const db = getFirestore();


    useEffect(() => {
        console.log(attributes)
        getInfo()
    }, [])

    const defaultSiteInfoList = [
        { label: 'Access Code', value: 'accessCode', index: 0, visible: true },
        { label: 'Address', value: 'address', index: 1, visible: true },
        { label: 'Site Contact', value: 'projectContact', index: 2, visible: true },
        { label: 'Site Phone', value: 'projectPhone', index: 3, visible: true },
        { label: 'Description', value: 'description', index: 5, visible: true },
    ]

    async function getInfo() {




        const userRef = doc(db, "users", userInfo.id,);
        const userSnap = await getDoc(userRef);

        const docRef = doc(db, "clients", userInfo.currentCompany, 'accounts', userSnap.data().currentAccount);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            console.log(docSnap.data())
            setItem(docSnap.data())
            setLoading(false)
        }


    }








    const siteInfoList = attributes?.siteInfoList ? attributes.siteInfoList : defaultSiteInfoList



    const Loading = () => { if (loading) { return <div className="loading"><CircularProgress /> <Typography variant="body1">Loading Data</Typography></div> } }


    const bordercolor = 'rgba(0,0,0,0.3)'

    //if loading true show loading screen
    if (loading) { return <Loading /> } else {
        return (
            <Row gutter={[8, 8]} >

                {siteInfoList.map((listItem, index) => {
                    if (listItem.visible) {
                        return (
                            <Col span={24}>
                                <Typography variant="body1" fontWeight={700} fontSize={13}>{listItem.label}</Typography>
                                <Typography variant="body1" fontWeight={400} fontSize={13}
                                    style={{
                                        padding: 5,
                                        borderRadius: 5,
                                        minHeight: 12,
                                        border: `1px solid ${bordercolor}`,
                                    }}>{item?.[listItem.value]}</Typography>
                            </Col>
                        )
                    }
                })}








            </Row >

        );

    }
}