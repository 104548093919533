import { collection, doc, getCountFromServer, getDocs, getFirestore, query, setDoc, where, writeBatch } from "firebase/firestore"
import uuid from "react-uuid";
import Pica from 'pica';
import { getStorage, ref, getMetadata, getDownloadURL, uploadBytes, uploadBytesResumable } from "firebase/storage";
import exifr from 'exifr';
import moment from 'moment-timezone';



// This function resizes one image to small, medium, and large, then uploads them
// This function resizes one image to small, medium, and large, then uploads them
async function resizeAndUpload(file, userInfo, currentParentAlbum, photoId, setUploadProgress) {
    const pica = Pica();
    const storage = getStorage();

    const sizes = [
        { name: 'small', maxSize: 320 },
        { name: 'medium', maxSize: 640 },
        { name: 'large', maxSize: 1280 },
    ];

    const fileType = file.type.split('/')[1];
    const image = new Image();
    image.src = URL.createObjectURL(file);

    await new Promise((resolve, reject) => {
        image.onload = resolve;
        image.onerror = reject;
    });

    const originalAspectRatio = image.width / image.height;

    const uploadPromises = sizes.map(({ name, maxSize }) => {
        return new Promise(async (resolve, reject) => {
            let targetWidth, targetHeight;
            if (originalAspectRatio > 1) {
                targetWidth = maxSize;
                targetHeight = Math.round(maxSize / originalAspectRatio);
            } else {
                targetWidth = Math.round(maxSize * originalAspectRatio);
                targetHeight = maxSize;
            }

            // Create a canvas element for each size
            const canvas = document.createElement('canvas');
            canvas.width = targetWidth;
            canvas.height = targetHeight;

            await pica.resize(image, canvas, {
                unsharpAmount: 80,
                unsharpRadius: 0.6,
                unsharpThreshold: 2
            });

            const blob = await pica.toBlob(canvas, `image/${fileType}`, 0.90);

            const uniqueFileName = `${photoId}_${name}.${fileType}`;
            const fileRef = ref(storage, `photos_new/${userInfo.currentCompany}/${uniqueFileName}`);
            const uploadTask = uploadBytesResumable(fileRef, blob);

            uploadTask.on('state_changed',
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setUploadProgress(prevProgress => ({
                        ...prevProgress,
                        [photoId]: {
                            ...prevProgress[photoId],
                            [name]: progress
                        }
                    }));
                },
                (error) => {
                    reject(error);
                },
                async () => {
                    const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                    resolve({ [name]: downloadURL });
                }
            );
        });
    });

    const urls = await Promise.all(uploadPromises);

    const photoData = Object.assign({}, ...urls, {
        account: userInfo.currentAccount,
        company: userInfo.currentCompany,
        created_date: Number(moment().format('x')),
        is_public: true,
        tags: [],
        status: 'active',
        creator: userInfo.id,
        parent_album_id: currentParentAlbum?.album_id || null,
        name: file.name,
        type: file.type,
        size: file.size,
        logkey: null
    });

    return photoData;
}





// This function will be responsible for handling photo uploads and Firestore updates
export const createPhotosFunction = async (newPhotos, photos, setPhotos, currentParentAlbum, userInfo, openSnack, setUploadProgress) => {
    try {
        // Initialize Firestore
        const db = getFirestore();



        // Create placeholders for new photos
        const placeholders = newPhotos.map(file => {
            const photoId = uuid();
            return {
                photo_id: photoId,
                account: userInfo.currentAccount,
                company: userInfo.currentCompany,
                created_date: Number(moment().format('x')),
                is_public: true,
                tags: [],
                status: 'active',
                creator: userInfo.id,
                parent_album_id: currentParentAlbum?.album_id || null,
                small: null,
                medium: null,
                large: null,
                name: file.name,
                type: file.type,
                size: file.size,
                logkey: null,
                isLoading: true, // This is the new flag
            };
        });

        // Add placeholders to the state immediately
        setPhotos((prevPhotos) => [...placeholders, ...prevPhotos,]);

        // Map over newPhotos to create an array of upload tasks
        const uploadTasks = newPhotos.map(async (file, index) => {
            const photoId = placeholders[index].photo_id;

            // Create a new promise to handle EXIF data extraction

            const metadata = await exifr.parse(file, true)

            // Use the exifPromise to wait for EXIF data before uploading

            // Continue with resize and upload after getting EXIF data
            return resizeAndUpload(file, userInfo, currentParentAlbum, photoId, setUploadProgress).then(photoData => {
                const photosRef = doc(db, "clients", userInfo.currentCompany, "photos_new", photoId);

                return setDoc(photosRef, { ...photoData, photo_id: photoId }).then(() => {
                    return { ...photoData, photo_id: photoId, isLoading: false };
                });
            });

        });

        // Wait for all uploads and Firestore document creations to complete
        const uploadedPhotos = await Promise.all(uploadTasks);


        // Update the photos state with the final data
        setPhotos((prevPhotos) => {
            return prevPhotos.map(photo => {
                const uploadedPhoto = uploadedPhotos.find(uPhoto => uPhoto.photo_id === photo.photo_id);
                // If the photo has finished uploading, update its data
                return uploadedPhoto ? { ...photo, ...uploadedPhoto } : photo;
            });
        });
        //match the final state of photos
        const lastPhoto = uploadedPhotos[uploadedPhotos.length - 1];




        openSnack('success', 'Photos uploaded and created successfully');
        return lastPhoto;
    } catch (e) {
        openSnack('error', 'Error creating photos');
    }
};

export const deletePhotosFunction = async (photosToDelete, userInfo, openSnack, photos, setPhotos) => {
    try {
        const db = getFirestore();
        const batch = writeBatch(db);

        // Queue all delete operations in the batch
        photosToDelete.forEach((photo) => {
            const photoRef = doc(db, "clients", userInfo.currentCompany, "photos_new", photo.photo_id);
            batch.update(photoRef, { status: 'deleted', deleted_date: new Date() });
        });

        // Commit the batch
        await batch.commit();

        // Filter out the deleted photos from the current state
        const updatedPhotos = photos.filter(photo =>
            !photosToDelete.some(deletedPhoto => deletedPhoto.photo_id === photo.photo_id)
        );

        // Update the state with the filtered photos

        setPhotos(updatedPhotos);

        openSnack('success', `${photosToDelete.length} photo(s) deleted successfully.`);
    } catch (e) {
        openSnack('error', 'Error deleting photo(s).');
    }
};






export const updatePhotoFunction = async (photo, userInfo, setCurrentPhoto, openSnack, setPhotos) => {
    try {
        const db = getFirestore();
        const photoRef = doc(db, "clients", userInfo.currentCompany, "photos_new", photo.photo_id);

        // Update the photo in Firestore
        await setDoc(photoRef, photo, { merge: true });

        // Update the local state with the new photo details
        setPhotos((prevPhotos) => {
            return prevPhotos.map((p) => (p.photo_id === photo.photo_id ? photo : p));
        });

        // Optionally clear the current photo or set it to the updated photo
        setCurrentPhoto(photo); // or setCurrentPhoto(null) if you wish to clear it

        openSnack('success', 'Photo updated successfully');
    } catch (e) {
        openSnack('error', 'Error updating photo');
    }
};

export const updatePhotosFunction = async (photosToUpdate, userInfo, openSnack, setPhotos) => {
    try {
        const db = getFirestore();
        const batch = writeBatch(db);

        // Queue all update operations in the batch
        photosToUpdate.forEach((photo) => {
            const photoRef = doc(db, "clients", userInfo.currentCompany, "photos_new", photo.photo_id);
            batch.set(photoRef, photo, { merge: true });
        });

        // Commit the batch
        await batch.commit();

        // Update the local state with the new photos details
        setPhotos((prevPhotos) => {
            return prevPhotos.map((prevPhoto) => {
                const updatedPhoto = photosToUpdate.find(p => p.photo_id === prevPhoto.photo_id);
                return updatedPhoto ? updatedPhoto : prevPhoto;
            });
        });

        openSnack('success', `${photosToUpdate.length} photo(s) updated successfully.`);
    } catch (e) {
        openSnack('error', 'Error updating photo(s)');
    }
};






export async function fetchPhotos(userInfo, currentParentAlbum, setFetchingPhotos, openSnack) {
    try {
        const db = getFirestore();
        const ref = collection(db, "clients", userInfo.currentCompany, 'photos_new');

        let queryRef;
        if (currentParentAlbum) {
            queryRef = query(ref, where("account", "==", userInfo.currentAccount), where("parent_album_id", "==", currentParentAlbum.album_id), where("status", "!=", 'deleted'));
        } else {
            queryRef = query(ref, where("account", "==", userInfo.currentAccount), where("parent_album_id", "==", null), where("status", "!=", 'deleted'));
        }

        const snap = await getDocs(queryRef);
        const data = snap.docs.map((doc) => ({ ...doc.data(), photo_id: doc.id }));

        openSnack('success', 'Photos retrieved successfully');
        setFetchingPhotos(false);
        return data;

    } catch (error) {
        openSnack('error', 'Error retrieving photos');
        setFetchingPhotos(false);
        return [];
    }
};

