import React from 'react';
import { Card, Typography, IconButton, Popover, ListItem, List, CardMedia, CardHeader, Box, Checkbox, CardContent, LinearProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faEllipsisV, faEdit, faArrowUp, faTrash } from '@fortawesome/free-solid-svg-icons';


const useStyles = makeStyles({
  card: {
    position: 'relative',
    borderRadius: '12px',
    overflow: 'hidden',
    cursor: 'grab',
    background: '#F2F5FC',
    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, border 0.3s ease-in-out',
    '&:hover': {
      transform: 'scale(1.03)',
      boxShadow: '0 5px 15px rgba(0, 0, 0, 0.2)',
      border: '1px solid rgba(0, 0, 0, 0.2)',
    },
  },
  media: {
    height: 200,
    width: '100%',
    objectFit: 'cover',
  },
  header: { // Renamed from 'footer' to 'header'
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px 16px',
    background: '#F2F5FC',
    color: 'black',
    borderTopLeftRadius: '12px',
    borderTopRightRadius: '12px',
  },
  title: {
    flexGrow: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  icon: {
    // ... other styles remain unchanged
  },
  ellipsis: {
    // ... other styles remain unchanged
  },
});

const FileLoadingCover = ({
  imageSrc,
  title,
  file,
  progress,



}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;






  return (
    <Card classes={{ root: classes.card }}
      style={{
        border:  'none',
      }} >
      <CardHeader
        avatar={
          <FontAwesomeIcon icon={faImage} size="xl" color="red" />
        }

        title={<Typography variant="body1" color="black" fontSize={14} fontWeight={600}>
          {title?.length > 20 ? title?.substring(0, 17) + '...' : title}
        </Typography>}
      />
      <CardContent>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
              <LinearProgress variant="determinate" value={progress} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <Typography variant="body2" color="text.secondary">{`${Math.round(
                progress,
              )}%`}</Typography>
            </Box>
          </Box>
        </Box>
      </CardContent>




    </Card>
  );
};

export default FileLoadingCover;



