import { Autocomplete, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';
import React from 'react';

export default class ChemProduct extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            type: this.props.type,
            item: this.props.lineItem,
            index: this.props.index,
            loading: true,
            preview: this.props.preview,
            stocks: []
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
        if (this.props.index !== prevProps.index) {
            this.setState({ index: this.props.index })
        }
        if (this.props.preview !== prevProps.preview) {
            this.setState({ preview: this.props.preview })
        }

    }
    componentDidMount() {
        const { userInfo } = this.state;
        this.getStocks()
    }

    getStocks = async () => {
        const { userInfo, type } = this.state;
        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'productStock'), where('archived', '==', false));
        const data = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            data.push({ ...doc.data(), label: doc.data().name })


        });
        console.log(data);
        data.sort((a, b) => a.name.localeCompare(b.name))
        this.setState({ stocks: data, loading: false })


    }




    updateItem = (key, boo, val) => {
        this.props.updateChemItem(key, boo, val)
    }

    render() {

        const { item, screenWidth, type, loading, index, preview, stocks } = this.state;




        return (
            <Row gutter={[12, 12]} align="middle">
                <Col xs={24} sm={12}>
                    <Row>
                        <Col span={24}>
                            <Typography fontSize={13} variant="body1">Target Species</Typography>
                        </Col>
                        <Col span={24}>
                            {preview ?
                                <Typography variant='body2'>{item.targetSpecies || ''}</Typography> :
                                <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"

                                    value={item.targetSpecies}
                                    onChange={(e) => {
                                        this.updateItem(item.key, `targetSpecies`, e.target.value)
                                    }} />}
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} sm={12}>
                    <Row>
                        <Col span={24}>
                            <Typography fontSize={13} variant="body1">Application Method</Typography>
                        </Col>
                        <Col span={24}>
                            {preview ?
                                <Typography variant='body2'>{item.applicationMethod !== undefined ? item.applicationMethod.label : ''}</Typography> :
                                <Autocomplete
                                    size="small"
                                    disablePortal
                                    id="combo-box-demo"
                                    value={item.applicationMethod}
                                    hideLabel={true}
                                    options={[
                                        { label: "Boat", key: 'airBoat' },
                                        { label: "UTV/Gator", key: 'UTV/Gator' },
                                        { label: "Backpack Sprayer", key: 'backpackSprayer' },
                                        { label: "Backpack Blower", key: 'backpackBlower' },
                                        { label: "Hand Broadcast", key: 'handBroadcast' },
                                        { label: "Spray Rig", key: 'sprayRig' },
                                    ]}
                                    renderInput={(params) => <TextField {...params} label="Method" />}
                                    onChange={(a, b) => {
                                        this.updateItem(item.key, `applicationMethod`, b)
                                    }}

                                />}
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} sm={12}>
                    <Row>
                        <Col span={24}>
                            <Typography fontSize={13} variant="body1">Dosage Rate</Typography>
                        </Col>
                        <Col span={24}>
                            {preview ?
                                <Typography variant='body2'>{item.dosageRate || ''}{item.dosageRateUnits}</Typography> :

                                <Row>
                                    <Col xs={16} sm={18}>
                                        <OutlinedInput notched={false} label="none" placeholder={"Dosage"} size="small" fullWidth
                                            type="number"
                                            inputProps={{ min: 0 }}
                                            value={item.dosageRate}
                                            onChange={(e) => {
                                                this.updateItem(item.key, `dosageRate`, e.target.value === "" ? "" : Number(e.target.value))
                                            }} />
                                    </Col>
                                    <Col xs={8} sm={6}>
                                        <Select size="small" width={100} fullWidth
                                            notched={false}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={item.dosageRateUnits}
                                            label="Age"
                                            onChange={(a) => {
                                                this.updateItem(item.key, `dosageRateUnits`, a.target.value)
                                            }}
                                        >
                                            <MenuItem value={'ppm'}>ppm</MenuItem>
                                            <MenuItem value={'ppb'}>ppb</MenuItem>
                                        </Select>

                                    </Col>
                                </Row>}



                        </Col>
                    </Row>
                </Col>

                <Col xs={24} sm={12}>
                    <Row>
                        <Col span={24}>
                            <Typography fontSize={13} variant="body1">Stock Location</Typography>
                        </Col>
                        <Col span={24}>
                            {preview ?
                                <Typography variant='body2'>{item.supplyStock !== undefined ? item.supplyStock.label : ''}</Typography> :
                                <Autocomplete
                                    size="small"
                                    disablePortal
                                    id="combo-box-demo"
                                    value={item.supplyStock}
                                    hideLabel={true}
                                    options={stocks}
                                    renderInput={(params) => <TextField {...params} label="Stock" />}
                                    onChange={(a, b) => {
                                        this.updateItem(item.key, `supplyStock`, b)
                                    }}

                                />}
                        </Col>
                    </Row>
                </Col>








            </Row>
        );
    }
} 