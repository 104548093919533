import { Button, Checkbox, Divider, OutlinedInput, Typography, Select, MenuItem, CircularProgress, Breadcrumbs } from '@mui/material';
import { Col, Row } from 'antd';
import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { fullMobile } from './../../util';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, } from 'firebase/firestore';

import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import uuid from 'react-uuid';



import queryString from 'query-string';
import axios from 'axios';
import { Link } from 'react-router-dom';
var randomColor = require('randomcolor');

export default function LogHeader(props) {
    const { userInfo, screenWidth, openSnack, company, account, updateStatus, updateWeatherProps } = props;








    return (
        <Row gutter={[12, 12]} >
            <Col {...fullMobile(12, 12)}>
                <Typography variant='h3' fontWeight={500} fontSize={36}>Chemical Application</Typography>
            </Col>
            <Col {...fullMobile(12, 12)} style={{ textAlign: 'right' }}>
                <Typography variant='h5' fontWeight={500} fontSize={24}>{company.companyName || ''}</Typography>
                <Typography variant='h5' fontWeight={400} fontSize={22}>{account.accountName || ''}</Typography>
                <Typography variant='h6' fontWeight={400} fontSize={18}>{account.address || ''}</Typography>
            </Col>
            <Col {...fullMobile(24, 24)}>
                <Divider style={{
                    borderColor: 'rgb(148, 186, 195)',
                    borderBottomWidth: 'medium',

                }} />
            </Col>
        </Row>
    )



}