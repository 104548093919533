import React, { useState } from "react";


import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import "react-datepicker/dist/react-datepicker.css";



const SiteSelect = (
    {
        accounts,
        currentTask,
        setCurrentTask,
        headerKey

    }

) => {




    return (

        <Autocomplete
            fullWidth
            style={{
                fontWeight: 600,
                fontSize: 28,
                color: 'black'
            }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            size="small"
            notched={false}
            options={accounts.sort((a, b) => -b.label.localeCompare(a.label))}
            defaultValue={currentTask?.[headerKey]}
            hiddenLabel={true}
            renderInput={(params) => <TextField {...params} placeholder="Site" variant="standard"
                sx={{
                    '& .MuiInputBase-root': {
                        color: 'black',
                        fontWeight: 500,
                        fontSize: 14,
                    },

                }}
            />}
            onChange={(e, b) =>
                setCurrentTask({ ...currentTask, [headerKey]: b, })
            }

        />


    );
};

export default SiteSelect;
