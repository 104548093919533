import React, { useState } from 'react';
import { IconButton, Tooltip, Badge, Dialog, DialogTitle, DialogActions, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';


const DeleteItemsButton = ({
    selectedRows,
    setSelectedRows,
    rows,
    updateRowPositions,
    deleteSelectedData,
    selectedData
}) => {

    const [deleteDialog, setDeleteDialog] = useState(false);

    const handleClose = () => {
        setDeleteDialog(false);
    };



    return (

        <>
            <IconButton onClick={() => {
                setDeleteDialog(true)

            }}
                disabled={selectedRows.length === 0 ? true : false}
            >
                <Tooltip title="Delete Selected" placement="bottom">
                    <Badge badgeContent={selectedRows.length} color="error" style={{ marginRight: 10 }}>
                        <FontAwesomeIcon icon={faTrash} size="md" style={{ color: 'gray', cursor: 'pointer' }} />
                    </Badge>
                </Tooltip>

            </IconButton>

            <Dialog
                open={deleteDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Are you sure you want to delete {selectedRows.length} rield log items?
                </DialogTitle>

                <DialogActions>
                    <Button style={{
                        textTransform: 'none',
                    }} onClick={handleClose}>Cancel</Button>
                    <Button
                        style={{
                            fontWeight: 600,
                            textTransform: 'none',
                            color: 'red'
                        }}

                        onClick={() => {
                            selectedRows.forEach(row => {
                                rows.splice(rows.findIndex(x => x?.id === row?.id), 1);
                            })
                            updateRowPositions([...rows])
                            setSelectedRows([])
                            handleClose();
                        }}
                        autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default DeleteItemsButton;