import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';




export const uploadFile = async (file, path) => {
  const storage = getStorage();
  try {
    const storageRef = ref(storage, path);
    await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(storageRef);
    const size = file.size;
    return ({ downloadURL, size });
  } catch (error) {
    console.error('Error uploading file:', error);
    return null;
  }
};

export const getFileDownloadURL = async (path) => {
  const storage = getStorage();
  try {
    const storageRef = ref(storage, path);
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;
  } catch (error) {
    console.error('Error getting file download URL:', error);
    return null;
  }
};
