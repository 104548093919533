import React from 'react';
import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroup, faPlus } from '@fortawesome/free-solid-svg-icons';
import { IconButton, Typography } from '@mui/material';

export default function CreateLayerItemButton({ onClick }) {
    return (
        <IconButton

            color="inherit"
            onClick={onClick}
        >
           <FontAwesomeIcon icon={faPlus} size="2xs" style={{ marginRight: 10 }} />
        </IconButton>

    );
}