
import React, { useState } from "react";


import { Autocomplete, Button, TextField } from '@mui/material';
import { useNavigate } from "react-router-dom";


const StaffPickerMultiple = ({
    accountUsers,
    log,
    setLog,
    record,
    updateRecord,
    type

}) => {

    const [value, setValue] = useState(type === 'flItem' ? accountUsers.filter(s => record?.otherStaff?.includes(s.id)) : accountUsers.filter(s => log?.otherStaff?.includes(s.id)))
    return (
        <Autocomplete
            size="small"
            multiple={true}
            id="combo-box-demo"
            value={value}
            disableCloseOnSelect
            options={accountUsers}
            renderInput={(params) => <TextField {...params} placeholder="Staff" />}
            style={{
                backgroundColor: 'white',
            }}
            ChipProps={{
                color: 'primary',
                size: 'large',
                sx: {
                    fontWeight: 500,
                    fontSize: 10,
                    backgroundColor: '#3f51b5',
                    '& .MuiChip-deleteIcon': {
                        color: 'white', // Set the color of the delete icon to white
                    },
                }
            }

            }
            onBlur={() => {
                if (type === 'flItem') {
                    updateRecord({ ...record, otherStaff: value.map(s => s.id) })
                    return
                }
                setLog({ ...log, otherStaff: value.map(s => s.id) })
            }}

            onChange={(a, b) => {
                if (type === 'flItem') {
                    console.log(b)
                    updateRecord({ ...record, otherStaff: b.map(s => s.id) })

                }
                setValue(b)


            }}

        />
    )
}

export default StaffPickerMultiple;