import { collection, doc, getDoc, getDocs, getFirestore, onSnapshot, orderBy, query, setDoc, updateDoc, where } from "firebase/firestore";
import moment from 'moment-timezone';
import axios, * as others from 'axios';



export const fetchLog = async (id, listKey, userInfo) => {
    try {


        const db = getFirestore();
        const docRef = doc(db, "clients", userInfo.currentCompany, listKey, id);
        const docSnap = await getDoc(docRef);
        let log = null;
        if (docSnap.exists()) {
            log = docSnap.data();
            console.log(log)
        }
        console.log(log)
        return log;
    }
    catch (error) {
        console.error("Error querying data: ", error);
        return null;
    }
}


export const fetchInfo = async (userInfo, type) => {
    try {
        const db = getFirestore();
        const docSnap = await getDoc(type === 'company' ? doc(db, "clients", userInfo.currentCompany) : doc(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount))
        let item = null;
        if (docSnap.exists()) {
            item = docSnap.data();
        }
        return item;
    }
    catch (error) {
        console.error("Error querying data: ", error);
        return null;
    }
}



