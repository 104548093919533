import { Col, Row } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { fullMobile } from '../../util';
import Highcharts from "highcharts/highcharts.js";
import moment from 'moment';
import { CircularProgress, Divider, Typography } from '@mui/material';
import axios, * as others from 'axios';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import HomeIcon from '@mui/icons-material/Home';
import * as ReactDOMServer from 'react-dom/server';
import {
    DrawingManager, GoogleMap, KmlLayer, StandaloneSearchBox, useJsApiLoader
} from '@react-google-maps/api';
var convert = require('convert-units')
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/data')(Highcharts);

const lib = ['places', 'visualization', 'drawing'];


export default function WidgetMap(props) {
    const { userInfo, screenWidth, openSnack, widgetRef, layoutRef, updateZoom, zoomRef, probes, nodes, account, kmlLayersRef, stations, updateWidget } = props;
    const [layout, setLayout] = useState(layoutRef);
    const [widget, setWidget] = useState(widgetRef);
    const chartDiv = useRef(null);
    const mapRef = useRef(null);
    const [loading, setLoading] = useState(true);
    const [map, setMap] = useState(null);
    const [searchBox, setSearchBox] = useState(null);
    const [kmlLayers, setKMLLayers] = useState([]);
    const [center, setCenter] = useState({
        lat: widget.lat || account.lat,
        lng: widget.lng || account.lng
    });
    const [zoom, setZoom] = useState(widget.zoom || 16);

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        libraries: lib,
        googleMapsApiKey: "AIzaSyC6fuuMNV3Wvo5M3_AXavGzKDYMBcuPooA",

    })

    const svgMarker = {
        path: "M27.648-41.399q0-3.816-2.7-6.516t-6.516-2.7-6.516 2.7-2.7 6.516 2.7 6.516 6.516 2.7 6.516-2.7 2.7-6.516zm9.216 0q0 3.924-1.188 6.444l-13.104 27.864q-.576 1.188-1.71 1.872t-2.43.684-2.43-.684-1.674-1.872l-13.14-27.864q-1.188-2.52-1.188-6.444 0-7.632 5.4-13.032t13.032-5.4 13.032 5.4 5.4 13.032z",
        fillColor: "blue",
        fillOpacity: 1,
        strokeWeight: 0,
        rotation: 0,
        scale: .8, 
        anchor: new window.google.maps.Point(0, 0),
        labelOrigin: new window.google.maps.Point(15, 10)
    };



    useEffect(() => {

    }, [kmlLayersRef])


    useEffect(() => {
        kmlLayers  || [].forEach(kml => {
            kml.setMap(null);
        })
        const newLayers = [];
        kmlLayersRef || [].forEach(kml => {
            const kmlLayer = new window.google.maps.KmlLayer({
                url: kml.url,
                key: kml.key,
                visible: kml.visible,
                name: kml.name,
                preserveViewport: true,
                suppressInfoWindows: true,
                zIndex: 1000,
            });
            newLayers.push(kmlLayer);
        })
        setKMLLayers(newLayers);
        updateKML(newLayers);
        setWidget(widgetRef)

    }, [widgetRef])
    useEffect(() => {
        setLayout(layoutRef)
    }, [layoutRef])




    async function handleLoad(map) {
        //set map to mapRef
        setMap(mapRef.current);
        mapRef.current = map;


        //add current location button if location button does not exist
        if (document.querySelector('.custom-map-control-button') === null) {

            addCurrentLocationButton();
        }

        //add account location button if location button does not exist
        if (document.querySelector('.custom-map-control-button') === null) {
            addToAccountLocationButton();
        }


        setLoading(false);

        //addvisible KML layers
        //create list of kmls
        let kmlList = [];
        if (widget.kmlLayers) {
            widget.kmlLayers.forEach(kml => {
                const kmlLayer = new window.google.maps.KmlLayer({
                    url: kml.url,
                    key: kml.key,
                    visible: kml.visible,
                    name: kml.name,
                    preserveViewport: true,
                    suppressInfoWindows: true,
                    zIndex: 1000,
                });
                kmlList.push(kmlLayer);
            })
        }
        //add kmls to map
        setKMLLayers(kmlList);
        updateKML(kmlList);



        if (probes.length > 0) {
            //add marker for each probe but set them to invisible
            probes.forEach(probe => {

                if (probe.recording !== undefined && probe.recording == true) {
                    const svgMarker = {
                        path: "M27.648-41.399q0-3.816-2.7-6.516t-6.516-2.7-6.516 2.7-2.7 6.516 2.7 6.516 6.516 2.7 6.516-2.7 2.7-6.516zm9.216 0q0 3.924-1.188 6.444l-13.104 27.864q-.576 1.188-1.71 1.872t-2.43.684-2.43-.684-1.674-1.872l-13.14-27.864q-1.188-2.52-1.188-6.444 0-7.632 5.4-13.032t13.032-5.4 13.032 5.4 5.4 13.032z",
                        fillColor: "blue",
                        fillOpacity: 1,
                        strokeWeight: 0,
                        rotation: 0,
                        scale: .8,
                        anchor: new window.google.maps.Point(0, 0),
                        labelOrigin: new window.google.maps.Point(15, 10)
                    };

                    //create name with line breaks every 20 characters <br />
                    let name = probe.name
                    let nameArray = name.split('')
                    let nameWithBreaks = ''
                    for (let i = 0; i < nameArray.length; i++) {
                        if (i % 20 == 0 && i != 0) {
                            nameWithBreaks += '<br />'
                        }
                        nameWithBreaks += nameArray[i]
                    }


                    const marker = new window.google.maps.Marker({
                        position: { lat: probe.lat, lng: probe.lon },
                        map: mapRef.current,
                        icon: {
                            ...svgMarker,
                            fillColor: 'red',
                            strokeColor: 'white',
                            strokeWeight: 0.5,
                        },

                        visible: widget.showProbes || false,
                    });
                    probe.marker = marker;

                    //when marker is clicked, open info window showing probe name
                    marker.addListener('click', () => {
                        const infoWindow = new window.google.maps.InfoWindow({
                            content: `<div style="font-size: 14px; font-weight: 600; color: black; text-align: center;">${nameWithBreaks}</div>`
                        });
                        infoWindow.open(mapRef.current, marker);

                        //close info window when map is clicked
                        mapRef.current.addListener('click', () => {
                            infoWindow.close();
                        }
                        )
                        //close info window when another marker is clicked
                        marker.addListener('click', () => {
                            infoWindow.close();
                        }
                        )
                        //close info window if x is clicked
                        infoWindow.addListener('closeclick', () => {
                            infoWindow.close();
                        }
                        )
                    })




                    probe.type = 'probe'
                }
            })





            addShowProbesButton(probes)
        }
        if (stations.length > 0) {
            //add marker for each probe but set them to invisible
            stations.forEach(item => {

                const marker = new window.google.maps.Marker({
                    position: { lat: item.lat, lng: item.lng },
                    map: mapRef.current,
                    draggable: false,
                    icon: {
                        ...svgMarker,
                        fillColor: item.color,
                        strokeColor: item.exists == false ? 'yellow' : 'white',
                        strokeWeight: item.exists == false ? 2 : 0.5,

                    },
                    visible: widget.showStations || false,


                    //label black text with white background
                    label: {
                        text: item.name,
                        color: 'white',
                        fontSize: '15px',
                        fontWeight: 'bold',
                        backgroundColor: 'white',
                        strokeColor: 'white',
                        strokeWeight: '4px',
                        padding: '20px',
                    },

                });
                item.marker = marker;
                //when marker is clicked, open info window showing probe name
                marker.addListener('click', () => {
                    const infoWindow = new window.google.maps.InfoWindow({
                        content: `<div style="font-size: 14px; font-weight: 600; color: black; text-align: center;">${item.name}</div>`
                    });
                    infoWindow.open(mapRef.current, marker);

                    //close info window when map is clicked
                    mapRef.current.addListener('click', () => {
                        infoWindow.close();
                    }
                    )
                    //close info window when another marker is clicked
                    marker.addListener('click', () => {
                        infoWindow.close();
                    }
                    )
                    //close info window if x is clicked
                    infoWindow.addListener('closeclick', () => {
                        infoWindow.close();
                    }
                    )
                })
                item.type = 'node'





            })



            addShowNodesButton(stations)
        }






    }

    async function updateKML(kmlList) {

        kmlList.forEach(kml => {
            if (kml.visible) {
                kml.setMap(mapRef.current);
            }
            else {
                kml.setMap(null);
            }
        })
    }

    function addCurrentLocationButton() {
        const html = ReactDOMServer.renderToString(<MyLocationIcon />);


        var currentLocationButton = document.createElement('button');
        currentLocationButton.innerHTML = html;
        currentLocationButton.style.cursor = 'pointer';
        currentLocationButton.classList.add('custom-map-control-button');
        mapRef.current.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(currentLocationButton);
        currentLocationButton.addEventListener('click', () => {
            // Try HTML5 geolocation.
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {

                        const lat = position.coords.latitude;
                        const lng = position.coords.longitude;
                        //current zoom
                        const zoom = mapRef.current.getZoom();


                        mapRef.current.setCenter({ lat, lng });
                        mapRef.current.setZoom(zoom);
                    },
                    () => {
                        handleLocationError(true, infoWindow, mapRef.current.getCenter());
                    }
                );
            } else {
                // Browser doesn't support Geolocation
                handleLocationError(false, infoWindow, mapRef.current.getCenter());
            }
        });
    }

    function handleLocationError(browserHasGeolocation, infoWindow, pos) {
        infoWindow.setPosition(pos);
        infoWindow.setContent(
            browserHasGeolocation

                ? "Error: The Geolocation service failed."
                : "Error: Your browser doesn't support geolocation."
        );
        infoWindow.open(mapRef.current);
    }


    const infoWindow = new window.google.maps.InfoWindow();

    function addToAccountLocationButton(info) {
        const html = ReactDOMServer.renderToString(<HomeIcon />);


        var currentLocationButton = document.createElement('button');
        //change cursor to pointer
        currentLocationButton.style.cursor = 'pointer';
        currentLocationButton.innerHTML = html;
        currentLocationButton.classList.add('custom-map-control-button');
        mapRef.current.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(currentLocationButton);

        currentLocationButton.addEventListener('click', (e) => {

            mapRef.current.setCenter({ lat: account.lat, lng: account.lng });




        });
    }


    function addShowProbesButton(list) {
        //create a button in the bottom left of the map that toggles the visibility of the probes
        const showProbesButton = document.createElement('button');
        showProbesButton.textContent = !widget.showProbes ? 'Show Probes' : 'Hide Probes';
        // increase text size and add padding
        showProbesButton.style.fontSize = '14px';
        showProbesButton.style.padding = '8px';
        //add border radius and white background
        showProbesButton.style.borderRadius = '4px';
        showProbesButton.style.backgroundColor = 'white';
        //add box shadow
        showProbesButton.style.boxShadow = '0 1px 4px rgba(0,0,0,0.3)';
        showProbesButton.classList.add('custom-map-control-button');
        mapRef.current.controls[window.google.maps.ControlPosition.LEFT_BOTTOM].push(showProbesButton);
        showProbesButton.addEventListener('click', () => {

            updateWidget(widget.key, 'showProbes', showProbesButton.textContent === 'Show Probes')

            if (showProbesButton.textContent == 'Show Probes') {
                showProbesButton.textContent = 'Hide Probes';
                //show probes
                list.forEach(item => {
                    if (item.type == 'probe') {

                        item.marker.setVisible(true)
                    }
                })
            } else {
                showProbesButton.textContent = 'Show Probes';
                //hide probes
                list.forEach(item => {
                    if (item.type == 'probe') {
                        item.marker.setVisible(false)
                    }
                })
            }
        });

    }
    function addShowNodesButton(list) {
        //create a button in the bottom left of the map that toggles the visibility of the probes
        const showProbesButton = document.createElement('button');
        showProbesButton.textContent = !widget.showStations ? 'Show Nodes' : 'Hide Nodes';
        // increase text size and add padding
        showProbesButton.style.fontSize = '14px';
        showProbesButton.style.padding = '8px';
        //add border radius and white background
        showProbesButton.style.borderRadius = '4px';
        showProbesButton.style.backgroundColor = 'white';
        //add box shadow
        showProbesButton.style.boxShadow = '0 1px 4px rgba(0,0,0,0.3)';
        showProbesButton.classList.add('custom-map-control-button');
        mapRef.current.controls[window.google.maps.ControlPosition.LEFT_BOTTOM].push(showProbesButton);
        showProbesButton.addEventListener('click', () => {

            updateWidget(widget.key, 'showStations', showProbesButton.textContent === 'Show Nodes')

            if (showProbesButton.textContent == 'Show Nodes') {
                showProbesButton.textContent = 'Hide Nodes';
                //show probes
                list.forEach(item => {
                    if (item.type == 'node') {

                        item.marker.setVisible(true)
                    }
                })
            } else {
                showProbesButton.textContent = 'Show Nodes';
                //hide probes
                list.forEach(item => {
                    if (item.type == 'node') {
                        item.marker.setVisible(false)
                    }
                })
            }
        });

    }





    const onSBLoad = ref => {
        setSearchBox(ref);
    };




    const onPlacesChanged = () => {
        const places = searchBox.getPlaces();
        //change center of map to new place
        setCenter({ lat: places[0].geometry.location.lat(), lng: places[0].geometry.location.lng() });
        setZoom(13);
    };





    //if loading is a string show error message

    return (

        //create div that adjust with size of parent div
        <div style={{ width: '100%', height: '100%', position: 'relative', }}

            onMouseLeave={() => {
                //get center and zoom of map
                const center = mapRef.current.getCenter();
                //change to lat and lng
                const lat = center.lat();
                const lng = center.lng();
                const zoom = mapRef.current.getZoom();
                updateWidget(widget.key, 'center', {lat, lng});
                updateWidget(widget.key, 'zoom', zoom);
            }}


        >

            {loading === true && (<div style={{
                zIndex: 100,
                height: '100%',
                top: '0%',
                textAlign: 'center',
                position: 'absolute',
                width: '100%',
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                //center div vertically and horizontally
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',

            }}>
                <Row gutter={[12, 12]}>
                    <Col>
                        <Typography variant="h6" style={{ color: 'gray', paddingBottom: 20 }}>
                            Loading Map...
                        </Typography>
                        <CircularProgress />
                    </Col>

                </Row>



            </div>)}
            <div ref={chartDiv} style={{ width: '100%', height: '100%', position: 'relative', }}>


                {isLoaded && (
                    <div style={{ height: '100%', width: '100%', paddingBottom: 20 }}>

                        <GoogleMap
                            mapContainerStyle={{ height: '100%', width: '100%' }}
                            center={center}
                            onLoad={handleLoad}
                            zoom={zoom}
                            mapTypeId={widget.mapType || 'satellite'}
                            controlSize={32}
                            options={{
                                scaleControl: true,
                                //satellite view
                                mapTypeId: 'satellite',
                                //disable street view
                                streetViewControl: false,
                                //disable zoom control

                                rotateControl: false,
                                //disable fullscreen control
                                fullscreenControl: false,

                                tilt: 0,
                                //disable keyboard shortcuts


                            }}


                        >











                            <>
                                <StandaloneSearchBox
                                    onPlacesChanged={onPlacesChanged}
                                    onLoad={onSBLoad}

                                >
                                    <input
                                        type="text"
                                        placeholder="Search Places"
                                        style={{
                                            boxSizing: 'border-box',
                                            border: `1px solid transparent`,
                                            width: `270px`,
                                            height: `40px`,
                                            padding: `0 12px`,
                                            borderRadius: `3px`,
                                            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                            fontSize: `14px`,
                                            outline: `none`,
                                            margin: 'center',
                                            textOverflow: `ellipses`,
                                            position: 'absolute',
                                            top: '0px',
                                            marginTop: 10,
                                            //aling right
                                            right: 10,
                                        }}
                                    />
                                </StandaloneSearchBox>
                            </>
                        </GoogleMap>

                    </div >
                )}

            </div>




        </div >

    );




}