import axios, * as others from 'axios';
import TidalParameters from '../../../extra/tidalParameters';
import moment from 'moment-timezone';
import uuid from 'react-uuid';


export const getWunderData = async (userInfo, streams) => {
    const proxyurl = "https://mycorslake.herokuapp.com/";
    const apiUrl = `https://us-central1-aquasource3.cloudfunctions.net/getUndergroundData`;

    if (!apiUrl) return;

    // Create an array of promises for each stream
    const promises = streams.map(async (stream) => {
        try {
            const response = await axios.post(proxyurl + apiUrl, {
                stream,
                days: [7]
            });
            return response.data;
        } catch (err) {
            console.error(err);
            return null; // or some error object if you prefer
        }
    });

    // Wait for all promises to resolve
    const results = await Promise.all(promises);

    console.log(results);

    return results;
}

