import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import InfoIcon from '@mui/icons-material/Info';
import { Button, Divider, IconButton, OutlinedInput, Pagination, TablePagination, Tooltip, Typography } from '@mui/material';
import { Col, Popconfirm, Row } from 'antd';
import { collection, deleteDoc, doc, endAt, endBefore, getDoc, getDocs, getFirestore, limit, limitToLast, onSnapshot, orderBy, query, setDoc, startAfter, startAt, updateDoc, where } from 'firebase/firestore';
import moment from 'moment';
import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from 'react-router-dom';
import '../../extra/hoverStyle.css';
import Lightbox from 'react-image-lightbox';
import './timeline.css'

export default class PhotoTimeline extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            type: this.props.type,
            item: this.props.item,
            photos: [],
            loading: true,
            progressBars: {},
            storageURL: this.props.storageURL,
            sort: false,
            photoList: [],
            photoIndex: 0,
            isOpen: false,
            lastVisible: null,
            firstVisible: null,
            queryCursor: null,
            pageSize: 10,
            page: 0,
            rowsPerPage: 10,
            photosCount: null,
            deleteIndex: null
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
        if (this.props.albumKey !== prevProps.albumKey) {

            if (this.props.albumKey !== undefined) {
                this.queryPhotos(this.props.albumKey)
            }
            this.setState({ albumKey: this.props.albumKey })
        }
        if (this.props.photos !== prevProps.photos) {

            this.setState({ photos: this.props.photos })
        }
        if (this.props.item !== prevProps.item) {
            this.setState({ item: this.props.item, files: this.props.item.files !== undefined ? this.props.item.files : [], })
        }

    }
    componentDidMount() {
        const { userInfo, albumKey, photos, rowsPerPage } = this.state;

        this.getTimelinePreferences()

    }

    getTimelinePreferences = async () => {
        const { userInfo, item, pageSize, sort } = this.state;
        const db = getFirestore();
        const queryData = doc(db, "users", userInfo.id, "preferences", "photoTimeline", "photoTimeline", userInfo.currentAccount);
        const docSnap = await getDoc(queryData);
        console.log('create new doc')
        if (docSnap.exists()) {
            const data = docSnap.data();
            console.log(data)
            this.setState({ rowsPerPage: data.rowsPerPage, page: data.page, sort: data.sort, loading: false })
            this.queryPhotos(data.rowsPerPage, sort === true ? 'asc' : 'desc')
            this.countPhotos()
        } else {
            this.setState({ loading: false })
            //setdoc of queryData 
            console.log('create new doc')
            await setDoc(queryData, { rowsPerPage: 10, page: 0, sort: false })
            this.queryPhotos(10, 'desc')
            this.countPhotos()
        }
    }




    countPhotos = async () => {
        const { userInfo, item, pageSize } = this.state;
        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'photos'), where("account", '==', userInfo.currentAccount));
        const unsubscribe = onSnapshot(queryData, async (querySnapshot) => {
            const photosCount = querySnapshot.size;
            this.setState({
                photosCount
            })
        });
    }

    queryPhotos = async (rowsPerPage,) => {
        const { userInfo, item, page, } = this.state;
        const db = getFirestore();


        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'photos'), where("account", '==', userInfo.currentAccount),
            orderBy('timestamp', 'desc'), limit(rowsPerPage));
        const unsubscribe = onSnapshot(queryData, async (querySnapshot) => {
            const data = [];
            querySnapshot.forEach((doc) => {
                data.push({ ...doc.data(), label: doc.data().name || '', date: doc.data().date !== undefined ? doc.data().date : moment().format('x'), })
            });
            const photos = data.map((d) => {
                return ({ ...d, fileLink: d.fileLink || d.url })
            })
            const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
            this.setState({ photos, loading: false, lastVisible })
        });
    }

    queryMorePhotos = async (pageType,) => {
        console.log(pageType)
        const { userInfo, item, lastVisible, page, rowsPerPage, firstVisible, sort } = this.state;
        const direction = sort === true ? 'asc' : 'desc';
        const db = getFirestore();
        let queryData;
        if (pageType === 'next') {
            queryData = query(collection(db, "clients", userInfo.currentCompany, 'photos'), where("account", '==', userInfo.currentAccount),
                orderBy('timestamp', direction), limit(rowsPerPage), startAfter(lastVisible));
        } else {
            queryData = query(collection(db, "clients", userInfo.currentCompany, 'photos'), where("account", '==', userInfo.currentAccount),
                orderBy('timestamp', direction), limitToLast(rowsPerPage), endBefore(firstVisible));
        }
        const unsubscribe = onSnapshot(queryData, async (querySnapshot) => {
            const data = [];
            querySnapshot.forEach((doc) => {
                data.push({ ...doc.data(), label: doc.data().name || '', date: doc.data().date !== undefined ? doc.data().date : moment().format('x'), })
            });
            const photos = data.map((d) => {
                return ({ ...d, fileLink: d.fileLink || d.url })
            })
            const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
            const firstVisible = querySnapshot.docs[0];
            console.log(firstVisible.data().key)
            this.setState({ photos, loading: false, lastVisible, firstVisible })
        });
    }

    sortPhotos = async (sort) => {
        const { userInfo, item, page, rowsPerPage, firstVisible, lastVisible } = this.state;
        const direction = sort === true ? 'asc' : 'desc';
        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'photos'), where("account", '==', userInfo.currentAccount),
            orderBy('timestamp', direction), limit(rowsPerPage), startAt(direction === 'asc' ? lastVisible : firstVisible));
        const unsubscribe = onSnapshot(queryData, async (querySnapshot) => {
            const data = [];
            querySnapshot.forEach((doc) => {
                data.push({ ...doc.data(), label: doc.data().name || '', date: doc.data().date !== undefined ? doc.data().date : moment().format('x') })
            });
            const photos = data.map((d) => {
                return ({ ...d, fileLink: d.fileLink || d.url })
            })
            const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
            const firstVisible = querySnapshot.docs[0];
            this.setState({ photos, loading: false, lastVisible, firstVisible, sort })
        });
    }






    updateAllPhotos = async () => {
        const { userInfo, item } = this.state;
        const db = getFirestore();
        const queryClients = query(collection(db, "clients"));
        const unsubscribe = onSnapshot(queryClients, async (querySnapshot) => {
            const data = [];
            querySnapshot.forEach((doc) => {
                data.push(doc.data().key)
            });




            data.forEach(async (d) => {
                const photos = [];
                const queryData = query(collection(db, "clients", d, 'photos'));
                //get photos
                const querySnapshot = await getDocs(queryData);
                querySnapshot.forEach((doc) => {
                    photos.push(doc.data())
                })

                photos.forEach(async (p) => {

                    //update each photos company to d.key and timestamp to Number(moment(doc.data().date, 'x').format('x'))
                    const photoRef = doc(db, "clients", d, 'photos', p.key);
                    await updateDoc(photoRef, {
                        company: d,
                        timestamp: Number(moment(p.date, 'x').format('x'))
                    }).then(() => {
                        console.log('updated')
                    })


                })
            })

        });
    }





    updateFile = async (index, boo, val, key) => {
        const { userInfo, photos } = this.state;
        photos[index][boo] = val;
        this.setState({ photos })

        this.handleCheck(index, boo, val, key)

    }



    handleCheck = (index, boo, val, key) => {
        // Clears running timer and starts a new one each time the user types
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.toggleCheck(index, boo, val, key);
        }, 1000);
    }

    toggleCheck = async (index, boo, val, key) => {
        const { userInfo, photos } = this.state;
        const db = getFirestore();
        const photoRef = doc(db, "clients", userInfo.currentCompany, 'photos', key);
        this.props.openSnack("success", 'Images Saved!')
        await updateDoc(photoRef, { [boo]: val })



    }

    addThumbNail = async (photo) => {
        const { userInfo, item } = this.state;
        const db = getFirestore();

        const albumRef = doc(db, "clients", userInfo.currentCompany, 'albums', item.key);

        await updateDoc(albumRef, { 'thumbNail': photo })

    }

    handleChangePage = (event, newPage) => {
        const { page } = this.state;
        const pageType = newPage > page ? 'next' : 'prev'
        this.setState({ page: newPage });
        this.queryMorePhotos(pageType)
        this.updatePhotoTimelinePreferences('page', newPage)
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: +event.target.value });
        this.setState({ page: 0 });
        console.log(+event.target.value)
        this.queryPhotos(+event.target.value)
        console.log("change rows per page")
        this.updatePhotoTimelinePreferences('rowsPerPage', +event.target.value)
        this.updatePhotoTimelinePreferences('page', 0)

    };

    updatePhotoTimelinePreferences = async (key, val) => {
        const { userInfo } = this.state;
        const db = getFirestore();
        const userRef = doc(db, "users", userInfo.id, "preferences", "photoTimeline", "photoTimeline", userInfo.currentAccount);
        await updateDoc(userRef, { [key]: val })
    }

    render() {

        const { deleteIndex, photosCount, page, rowsPerPage, item, screenWidth, photos, progressBars, userInfo, preview, storageURL, sort, photoList, photoIndex, isOpen } = this.state;

        const DeleteItem = ({ item, fieldLog }) => {
            let navigate = useNavigate();
            return (
                <Popconfirm
               
                    title={`Are you sure to delete this photo?${fieldLog === true ? ' This will remove it from the field log as well' : ''} `}
                    onConfirm={async () => {
                        const db = getFirestore()
                        await deleteDoc(doc(db, "clients", userInfo.currentCompany, 'photos', item.key)).then(() => {

                            const newPhotos = photos.filter((f) => f.key !== item.key)
                            this.setState({ photos: newPhotos })
                            this.props.openSnack('success', 'Photo deleted.')
                        })

                    }}
                    okText="Yes"
                    cancelText="No"
                >
                    <IconButton style={{ textTransform: 'none' }} fullWidth variant="contained" size="small"
                    >
                        <DeleteForeverIcon />
                    </IconButton>
                </Popconfirm>


            )
        }


        const photoGallery = photos;

        const photosMonthChange = photoGallery.map((photo, index) => {
            const date = photo.date;
            const month = moment(date, 'x').month() + 1;
            const monthLabel = moment(date, 'x').format('MMMM');

            const year = moment(date, 'x').year();

            if (index === 0) {

                return ([{ type: 'title', date: photo.date }, { ...photo }])
            }
            //if previous month does not equal current month, add title
            if (moment(photoGallery[index - 1].date, 'x').month() + 1 !== month) {
                return ([{ type: 'newMonth', date: photo.date }, { ...photo }])
            }
            if (index > 0 && moment(photoGallery[index - 1].date, 'x').month() + 1 === month) {
                return ([{ ...photo }])
            }
        }).flat(1)



        return (
            <Row gutter={[12, 12]} align="middle" >
                <Col sm={24}>
                    <Row align="middle" gutter={[24, 24]}>




                        {isOpen && (
                            <Lightbox
                                mainSrc={photos[photoIndex].fileLink}
                                nextSrc={photos[(photoIndex + 1) % photos.length].fileLink}
                                prevSrc={photos[(photoIndex + photos.length - 1) % photos.length].fileLink}
                                onCloseRequest={() => this.setState({ isOpen: false })}
                                onMovePrevRequest={() =>
                                    this.setState({
                                        photoIndex: (photoIndex + photos.length - 1) % photos.length,
                                    })
                                }
                                onMoveNextRequest={() =>
                                    this.setState({
                                        photoIndex: (photoIndex + 1) % photos.length,
                                    })
                                }
                            />
                        )}



                        {photosMonthChange.map((p, i) => {


                            const date = p.date;
                            const monthLabel = moment(date, 'x').format('MMMM');

                            const year = moment(date, 'x').year();


                            if (p.type === 'title') {
                                return (
                                    <Col sm={24} key={i} style={{ paddingBottom: 10 }}>
                                        <Row gutter={[12, 12]}>
                                            <Col span={12}>
                                                <Typography variant="h4">{monthLabel} {year}</Typography>
                                            </Col>

                                            <Col span={12} style={{ textAlign: 'right' }}>
                                                <TablePagination
                                                    component="div"
                                                    count={photosCount}
                                                    page={page}
                                                    onPageChange={this.handleChangePage}
                                                    rowsPerPage={rowsPerPage}
                                                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                                                />
                                            </Col>
                                            <Col span={24}>
                                                <Divider sx={{ borderBottomWidth: 2, borderColor: 'rgba(0,0,0,0.4)' }} />
                                            </Col>
                                        </Row>


                                    </Col>
                                )
                            }
                            if (p.type === 'newMonth') {
                                return (
                                    <Col sm={24} key={i} style={{ paddingBottom: 10 }}>
                                        <Typography variant="h4">{monthLabel} {year}</Typography>
                                        <Divider sx={{ borderBottomWidth: 2, borderColor: 'rgba(0,0,0,0.4)' }} />
                                    </Col>
                                )

                            }

                            if (p.type !== 'title' && p.type !== 'newMonth') {
                                console.log(p)

                                return (



                                    <Col xs={12} sm={6} style={{ borderRadius: 8, }}>
                                        <Row align="middle" gutter={[8, 8]} style={{ position: 'relative', bottom: 0 }}>
                                            {/*  <Col span={24}>
                                                <OutlinedInput fullWidth notched={false} label="none" placeholder={"Description"} size="small"

                                                    value={p.description}
                                                    onChange={(e) => {
                                                        this.updateFile(i, `description`, e.target.value, p.key)
                                                    }} />
                                            </Col> */}

                                            <Col span={24} style={{ cursor: 'pointer' }} onClick={() => {

                                            }} >

                                                <div
                                                    onMouseEnter={() => {
                                                        this.setState({ deleteIndex: i })
                                                    }}
                                                    onMouseLeave={() => {
                                                        this.setState({ deleteIndex: null })
                                                    }}

                                                    style={{
                                                        position: 'absolute', top: 0, right: 0, padding: 4,
                                                        zIndex: 20,
                                                        backgroundColor: deleteIndex === i ? 'white' : 'rgba(0,0,0,0.4)', borderRadius: '0 0 0 8px'
                                                    }}>
                                                    {p.logkey === undefined ?
                                                        <DeleteItem item={p} fieldLog={false} />
                                                        :
                                                        <Tooltip title="Delete in Field Log">
                                                            <DeleteItem item={p} fieldLog={true} />
                                                        </Tooltip>}
                                                </div>

                                                <img src={p.fileLink} style={{ width: '100%', maxHeight: '200px', borderRadius: 6 }}
                                                    onClick={() => {
                                                        const photoIndex = photos.findIndex((f) => f.key === p.key)

                                                        this.setState({ isOpen: true, photoIndex })
                                                    }} />


                                            </Col>


                                            <Col span={24}>
                                                <DatePicker portalId="root-portal" showTimeSelect timeFormat="h:mm a" timeIntervals={1} timeCaption="time" dateFormat="MM/dd/yyyy h:mm a"
                                                    selected={[undefined, '', "Invalid date"].includes(p.date) ? new Date() : new Date(moment(date, 'x'))}
                                                    onChange={(date) => {

                                                        const unix = moment(date).format('x');
                                                        this.updateFile(i, `date`, unix, p.key)


                                                    }}
                                                />
                                            </Col>






                                        </Row>

                                    </Col>




                                )
                            }
                        })}

                        <Col span={24}>
                            <Divider sx={{ borderBottomWidth: 2, borderColor: 'rgba(0,0,0,0.4)' }} />
                        </Col>
                        <Col span={24} style={{ textAlign: 'right' }} justify="end">
                            <TablePagination
                                component="div"
                                count={photosCount}
                                page={page}
                                onPageChange={this.handleChangePage}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                            />
                        </Col>


                    </Row>
                </Col>











            </Row >
        );
    }
} 