
import { CircularProgress, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import moment from 'moment';
import React from 'react';
import "weather-icons/css/weather-icons.css";
import { fullMobile } from './util';


import axios, * as others from 'axios';
var convert = require('convert-units')
const apiKey = 'c66109d21021ed0af40cf459d9fe5d37';


export default class CheckTimeWeather extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            date: this.props.date,
            time: this.props.time,
            weather: {},
            icon: '',
            loading: true,
            updateWeather: this.props.updateWeather,
            item: this.props.item,
            doc: this.props.doc,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.item !== prevProps.item) {
            this.setState({ item: this.props.item })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
        if (this.props.date !== prevProps.date) {


            this.setState({ date: this.props.date })
        }

        if (this.props.weather !== prevProps.weather) {
            this.setState({ weather: this.props.weather })
        }
        if (this.props.doc !== prevProps.doc) {
            this.setState({ doc: this.props.doc })
        }
        if (this.props.updateWeather !== prevProps.updateWeather) {
            this.setState({ updateWeather: this.props.updateWeather })

            if (this.props.updateWeather === true) {
              
                this.getWeather(this.props.date, this.props.time)
                this.props.weatherUpdated()
            }
        }

    }
    componentDidMount() {
        window.scrollTo(0, 0);
        const { date, time, weather, doc } = this.state;


        this.checkDoc(doc)

   







    }

    checkDoc = (doc) => {
        const { date, time, weather, } = this.state;
        if (Object.values(doc).length > 0) {
            if (doc.weather !== undefined) {
                if (Object.values(doc.weather).length === 0) {
                    this.getWeather(date)
                }
                else {
                  
                    this.setState({ weather: doc.weather, icon: doc.weather.icon || doc.weather.weathericon, loading: false })
                }
            }
            else {
                this.getWeather(date)
            }

        }
        else {
            setTimeout(() => {
                this.checkDoc(this.state.doc)
            }, 500);

        }
    }


    getWeather = (date, time) => {
        this.setState({ loading: true })
    

        const { account, item, userInfo } = this.state;

        var dateFormat = moment(date, 'x').format('MM/DD/YYYY h:mm a');

        var dateTime = moment(dateFormat, 'MM/DD/YYYY h:mm a').format('X');

        const itemAccount = item.account || {};





        const proxyUrl = "https://mycorslake.herokuapp.com/";
        axios.get(proxyUrl + 'https://api.openweathermap.org/data/2.5/weather?', {
            params: {
                lat: itemAccount.lat || account.lat,
                lon: itemAccount.lng || account.lng,
                appid: '30573b68170d7f4400c7ac9c1c671ccf',
                units: 'metric',
                dt: Number(dateTime),
            }
        })
            .then((response) => {
            
                const data = response.data;
                var time = moment(date, 'x').tz("America/Los_Angeles").format("YYYY-MM-DD HH:mm:ss");
                const item = {
                    time: `'${time}'`,
                    dt: Number(moment(date, 'x').tz("America/Los_Angeles").format("X")),
                    timestamp: 123,
                    account: `'${userInfo.currentAccount}'`,
                    company: `'${userInfo.currentCompany}'`,
                    temp: data.main.temp,
                    feels_like: data.main.feels_like,
                    pressure: data.main.pressure,
                    humidity: data.main.humidity,
                    dew_point: `'dew'`,
                    uvi: `'d.uvi'`,
                    clouds: data.clouds.all,
                    visibility: data.visibility || 0,
                    wind_speed: data.wind.speed || 0,
                    wind_gust: data.wind.gust || 0,
                    wind_deg: data.wind.deg || 0,
                    rain: data.precipitation ? data.precipitation.value : 0,
                    weather: `'${data.weather[0].main || ''}'`,
                    weatherId: data.weather[0].id || 0,
                    weatherMain: `'${data.weather[0].main || ''}'`,
                    weatherDescription: `'${data.weather[0].description || 0}'`,
                    weatherIcon: `'${data.weather[0].icon || 0}'`,

                }

           
            })

        axios.get(proxyUrl + 'https://api.openweathermap.org/data/3.0/onecall?', {
            params: {
                lat: itemAccount.lat || account.lat,
                lon: itemAccount.lng || account.lng,
                appid: '30573b68170d7f4400c7ac9c1c671ccf',
                units: 'imperial',
                dt: Number(dateTime),
            }
        })
            .then((response) => {



                const weather = { ...response.data.current, ...response.data.current.weather[0], };

                var prefix = 'wi wi-';
                var code = response.data.current.weather[0].id;
                var icon = response.data.current.weather[0].icon;
          

                var index = Math.round(((weather.wind_deg %= 360) < 0 ? weather.wind_deg + 360 : weather.wind_deg) / 45) % 8;
                const windDirection = ["North", "North-West", "West", "South-West", "South", "South-East", "East", "North-East"][index];

                // Finally tack on the prefix.
                weather.windDirection = windDirection;
                weather.weatherImage = 'none';
                this.props.updateDoc('weather', weather)
                this.props.weatherUpdated()
                this.setState({ weather, icon, loading: false })
           



            })
            .catch((error, message) => {

             

                const proxyUrl = "https://mycorslake.herokuapp.com/";
                axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgetsFire3/sqlRead', {
                    raw: `SELECT * FROM weather_data WHERE account = '${userInfo.currentAccount}' AND time >= '${moment(date, 'x').format('YYYY-MM-DD HH:mm:ss')}'
                      ORDER BY time LIMIT 1;`

                })
                    .then(async (response) => {
                        if (response.data.length > 0) {
                            const weather = { ...response.data[0] };
                            var index = Math.round(((weather.wind_deg %= 360) < 0 ? weather.wind_deg + 360 : weather.wind_deg) / 45) % 8;
                            const windDirection = ["North", "North-West", "West", "South-West", "South", "South-East", "East", "North-East"][index];

                            // Finally tack on the prefix.
                            weather.windDirection = windDirection;
                            weather.weatherImage = 'none';
                            weather.description = weather.weathermain;
                            //convert to fahrenheit
                            weather.temp = Math.round((weather.temp * 9 / 5) + 32);

                         

                            this.props.updateDoc('weather', weather)
                            this.props.weatherUpdated()
                            this.setState({ weather, icon: weather.weathericon, loading: false })



                        }

                    })
                    .catch((error) => {
                        console.log(error)
                    }
                    )



            })
            .then(function () {
                // always executed
            }).catch((error) => {
                console.log(error)
            })

    }

    render() {

        const { account, weather, icon, screenWidth, loading, doc } = this.state;

        var dict = {
            '01d': 'wi-day-sunny',
            '02d': 'wi-day-cloudy',
            '03d': 'wi-cloud',
            '04d': 'wi-cloudy',
            '09d': 'wi-showers',
            '10d': 'wi-day-rain-mix',
            '11d': 'wi-thunderstorm',
            '13d': 'wi-snow',
            '50d': 'wi-fog',
            '01n': 'wi-night-clear', 
            '02n': 'wi-night-alt-cloudy',
            '03n': 'wi-night-alt-cloudy-high',
            '04n': 'wi-cloudy',
            '09n': 'wi-night-alt-sprinkle',
            '10n': 'wi-night-alt-showers',
            '11n': 'wi-night-alt-thunderstorm',
            '13n': 'wi-night-alt-snow',
            '50n': 'wi-night-fog'
        };

        if (loading === true) {
            return (
                <Row align="middle" gutter={[8, 8]} style={{ paddingTop: 10 }} >
                    <Col {...fullMobile(6, 12)}>
                        <CircularProgress />
                    </Col>
                </Row>
            )

        }
        else {

            const temperature = weather.temp > 0 && weather.temp < 150 ? weather.temp : convert(weather.temp).from('K').to('F');
          
            return (
                <Row align="middle" gutter={[8, 8]} style={{ paddingTop: 10 }} >
                    <Col {...fullMobile(6, 12)}>
                        <Typography variant='h4'>{temperature.toFixed(0)} F</Typography>
                        <Typography variant='h6' style={{ textTransform: 'capitalize' }}>{weather.description}</Typography>
                        <Typography variant='body1' style={{}}>Wind: {convert(weather.wind_speed).from('m/s').to('m/h').toFixed(0)} mph {weather.windDirection || ''}</Typography>
                        < Typography variant='body1' style={{ textTransform: 'capitalize' }}>{weather.humidity}% Humidity</Typography>
                    </Col>
                    <Col sm={12} style={{ textAlign: 'center', justifyContent: 'center', alignContent: 'center', fontSize: 48 }}  >
                        <i style={{ textAlign: 'center', justifyContent: 'center', alignContent: 'center', }} id='my-node' class={`wi ${dict[icon]}`}></i>
                    </Col>

                </Row>

            );
        }


    }
} 
