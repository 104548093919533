import React from 'react';
import { Col, Row, Drawer, Popconfirm, } from 'antd';
import {
    Breadcrumbs, Button, OutlinedInput, Paper, Typography, Select, MenuItem, IconButton, Divider, TextField, FormControl, FormGroup, FormControlLabel, Collapse,
    Checkbox, Fab, Autocomplete, CardContent, Box, Menu, InputLabel, ButtonGroup
} from '@mui/material';
import { Link } from 'react-router-dom';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { ReactSortable } from 'react-sortablejs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVial, faLocationCrosshairs, faFillDrip, faChartArea, faChartBar, faChartLine, faChartColumn, faGear, faCopy, faTrash, faSquareFull, faGripLinesVertical, faGripLines, faEllipsisH, faMinus } from '@fortawesome/free-solid-svg-icons';
import uuid from 'react-uuid';
import DataColor from './dataColor';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc } from 'firebase/firestore';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import moment from 'moment';
import ProbeParameters from '../../../../extra/probeParameters.json';
import LabParameters from '../../../../extra/labParameters.json';
import Units from '../../../../extra/units.json';
import WeatherParameters from '../../../../extra/weatherParameters.json';
import DefaultDatePicker from '../../../../blocks/defaultDatePicker';
import { fullMobile } from './util'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataColorGithub from './dataColorGithub';
import DataColorCompact from './dataColorCompact';
import { queryParameters } from '../../../../firebase/config';
import ParametersLoad from './parametersLoad';
import NodeSelect from './NodeSelect';
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import axios from 'axios';
import DataColorChrome from './dataColorChrome';
import SeriesConditions from './chartSettingsConditions/seriesConditions';

var randomColor = require('randomcolor');
export default class ChartSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            attributes: this.props.attributes,
            currentWidgetKey: this.props.currentWidgetKey,
            locations: [],
            references: [],
            parameters: [],
            sites: [],
            activeTab: 'data',
            readData: this.props.readData
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
        if (this.props.attributes !== prevProps.attributes) {

            this.setState({ attributes: this.props.attributes })
        }
        if (this.props.currentWidgetKey !== prevProps.currentWidgetKey) {

            this.setState({ currentWidgetKey: this.props.currentWidgetKey, })
        }
        if (this.props.readData !== prevProps.readData) {
            this.setState({ readData: this.props.readData })
            if (this.props.readData === true) {
                const newAttributes = { ...this.state.attributes }

                this.props.updateAttributes(this.state.attributes, this.props.currentWidgetKey)
                this.setState({ readData: false })
            }
        }


    }
    componentDidMount() {
        const { attributes } = this.state;
        this.getLocations()
        this.getReferences()
        this.getParameters();


    }

    getLocations = async () => {
        const { userInfo } = this.state;

        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'probes'), where('account', '==', userInfo.currentAccount), where('archived', '==', false));
        const queryDataSites = query(collection(db, "clients", userInfo.currentCompany, 'sampleSites'), where('account', '==', userInfo.currentAccount),);
        const queryDataStations = query(collection(db, "clients", userInfo.currentCompany, 'tidalStations'), where('account', '==', userInfo.currentAccount),);
        const queryGauges = query(collection(db, "clients", userInfo.currentCompany, 'rainGauges'), where('account', '==', userInfo.currentAccount),);
        const queryWunders = query(collection(db, "clients", userInfo.currentCompany, 'wundergrounds'), where('account', '==', userInfo.currentAccount), where('inactive', '==', false), where("archived", '==', false));
        const queryObscapes = query(collection(db, "clients", userInfo.currentCompany, 'obscapeWeatherStations'), where('account', '==', userInfo.currentAccount), where('inactive', '==', false), where("archived", '==', false));
        const queryStations = query(collection(db, "clients", userInfo.currentCompany, 'stations'), where('account', '==', userInfo.currentAccount),);
        const locations = [];


        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            locations.push({ ...doc.data(), label: doc.data().name, type: 'probe' })
        });
        const snapSites = await getDocs(queryDataSites);
        snapSites.forEach((doc) => {
            locations.push({ ...doc.data(), label: doc.data().name, type: 'sample' })
        });

        const snapStations = await getDocs(queryDataStations);
        snapStations.forEach((doc) => {
            locations.push({ ...doc.data(), stationLabel: 'Tide Stations', label: doc.data().name, type: 'station' })
        });
        const snapGauges = await getDocs(queryGauges);
        snapGauges.forEach((doc) => {
            locations.push({ ...doc.data(), label: doc.data().name, type: 'gauge' })
        });
        const snapWunders = await getDocs(queryWunders);
        snapWunders.forEach((doc) => {
            locations.push({ ...doc.data(), stationLabel: 'Weather Undergrounds', name: doc.data().name || doc.data().stationName, label: doc.data().name || doc.data().stationName, type: 'wunder' })
        });
        const snapObscapes = await getDocs(queryObscapes);
        snapObscapes.forEach((doc) => {
            locations.push({ ...doc.data(), stationLabel: 'Obscape Weather Stations', name: doc.data().name || doc.data().stationName, label: doc.data().name || doc.data().stationName, type: 'obscapeWeatherStation' })
        });

        const snapNodes = await getDocs(queryStations);
        snapNodes.forEach((doc) => {

            if (doc.data().archived !== true && doc.data().children.length > 0) {
                const children = doc.data().children;
                //for each child create a node and add to locations

                children.forEach((child) => {
                    locations.push({ ...child, stationLabel: doc.data().name, label: child.name, type: 'node', stationType: child.type })
                })

            }

        });




        locations.push({ label: 'Weather', key: 'Weather', name: 'Weather', type: 'weather' })
        locations.sort((a, b) => a.name.localeCompare(b.name))


        this.setState({ locations, })

    }
    getReferences = async () => {
        const { userInfo, attributes } = this.state;

        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'references'));
        const references = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            references.push(doc.data())
        });

        references.sort((a, b) => a.label.localeCompare(b.label))
        this.setState({ references, })

        console.log(references)

        console.log(attributes?.references)


    }

    updateWidget = (boo, val) => {
        const { attributes } = this.state;
        attributes[boo] = val;
        this.setState({ attributes })


    }

    updateData = (index, boo, val) => {
        const { attributes } = this.state;
        attributes.chartData[index][boo] = val;
        this.updateWidget(attributes)

    }
    updateAxis = (index, boo, val) => {
        const { attributes } = this.state;


        attributes.axises[index][boo] = val;
        this.updateWidget(attributes)

    }

    updateReference = (index, boo, val) => {
        const { attributes } = this.state;
        attributes.references[index][boo] = val;
        console.log(attributes.references[index])
        this.updateWidget(attributes)
    }

    createAlias = (index, val) => {
        const { attributes } = this.state;
        attributes.references[index] = { ...attributes.references[index], useAlias: val };
        this.updateWidget(attributes)
    }



    getParameters = async () => {
        const { userInfo, type } = this.state;
        const db = getFirestore();
        const parameters = await queryParameters(userInfo)
        parameters.sort((a, b) => a.name.localeCompare(b.name))
        this.setState({ parameters, })
    }

    handleCheck = (index, boo, val,) => {
        // Clears running timer and starts a new one each time the user types
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.toggleCheck(index, boo, val,);
        }, 1000);
    }

    toggleCheck = async (index, boo, val) => {
        this.updateData(index, boo, val)
    }
    handleCheck1 = (index, boo, val,) => {
        // Clears running timer and starts a new one each time the user types
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.toggleCheck1(index, boo, val,);
        }, 800);
    }

    toggleCheck1 = async (index, boo, val) => {
        this.updateReference(index, boo, val)
    }
    handleCheckAxis = (index, boo, val,) => {
        // Clears running timer and starts a new one each time the user types
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.toggleCheckAxis(index, boo, val,);
        }, 800);
    }

    toggleCheckAxis = async (index, boo, val) => {
        this.updateAxis(index, boo, val)
    }
    handleCheckWidget = (boo, val,) => {
        // Clears running timer and starts a new one each time the user types
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.toggleCheckWidget(boo, val,);
        }, 800);
    }

    toggleCheckWidget = async (boo, val) => {
        this.updateWidget(boo, val)
    }

    duplicateSeries = (index) => {
        const { attributes } = this.state;
        const newSeries = { ...attributes.chartData[index] }
        newSeries.key = uuid()
        attributes.chartData.push(newSeries)
        this.setState({ attributes })
        this.props.openSnack('success', `Series duplicated.`)
    }

    render() {

        const { account, attributes, screenWidth, locations, expanded, activeTab, references, currentWidgetKey, parameters, userInfo } = this.state;

        const DeleteItem = ({ row, type }) => {
            return (
                <Popconfirm
                    title={`Are you sure to delete this ${type === 'chartData' ? 'series' : type}?`}
                    onConfirm={async () => {

                        if (type === 'axises') {
                            const checkSeries = attributes.chartData.filter((s) => s.yAxis === row.axisId);
                            if (checkSeries.length > 0) {
                                this.props.openSnack('error', `Cannot delete, a series is associated to this axis.`)
                            }
                            else {
                                this.props.openSnack('success', `${type === 'axises' ? "Axes" : "Data"} deleted.`)
                                const newData = attributes[type].filter((f) => f.key !== row.key);
                                attributes[type] = newData;
                                this.setState({ attributes })
                            }
                        }
                        else {
                            this.props.openSnack('success', `${type === 'axises' ? "Axes" : "Data"} deleted.`)
                            const newData = attributes[type].filter((f) => f.key !== row.key);
                            attributes[type] = newData;
                            this.setState({ attributes })
                        }




                    }}
                    okText="Yes"
                    cancelText="No"
                >
                    <IconButton style={{ textTransform: 'none', color: 'red' }} fullWidth variant="contained" size="small"
                    >
                        <FontAwesomeIcon icon={faTrash} />
                    </IconButton>
                </Popconfirm>


            )
        }


        const PPArray = Object.values(ProbeParameters).map((p, i) => {
            return ({
                label: p,
                key: Object.keys(ProbeParameters)[i]
            })
        });

        const WPArray = Object.values(WeatherParameters).map((p, i) => {
            return ({
                label: p,
                key: Object.keys(WeatherParameters)[i]
            })
        });
        const UnitsArray = Object.values(Units).map((p, i) => {
            return ({
                label: p,
                key: Object.keys(Units)[i]
            })
        });


        const chartDataMap = (d, i, key) => {
            const input = d.decimals === 0 || d.decimals ? d.decimals : '';



            const gutter = [8, 8]
            const verticalOffset = { marginTop: 20 }
            return (
                <Row gutter={gutter} style={{ backgroundColor: i % 2 === 0 ? 'inherit' : 'rgba(0,1,0,0.03)', paddingTop: 8 }} key={d.key}>
                    {/* Drag Component */}
                    <Col {...fullMobile(2, undefined, { xs: 0 })} className="my-handle">
                        <DragIndicatorIcon />
                    </Col>
                    {/* Data input section */}
                    <Col {...fullMobile(22)}>
                        <Row align="middle" gutter={gutter} style={{ paddingBottom: 8 }}>
                            <Col span={24}>
                                {/* Row 1 */}
                                <Row gutter={gutter}>
                                    <Col {...fullMobile(6)}>

                                        <Typography fontSize={13} fontWeight={600} variant="body1">Data Type<span style={{ color: 'red' }}>*</span></Typography>


                                        <Select size="small" fullWidth
                                            notched={false}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={d.dataType || ''}
                                            label="Layer"
                                            onChange={(e) => {
                                                this.updateData(i, 'dataType', e.target.value)
                                            }}
                                            style={{
                                                backgroundColor: '#ffffff',
                                            }}

                                        >
                                            <MenuItem value={'node'}>Node/Station</MenuItem>
                                            <MenuItem value={'weather'}>Weather</MenuItem>
                                            <MenuItem value={'wunder'}>Weather Underground</MenuItem>
                                            <MenuItem value={'obscapeWeatherStation'}>Obscape Weather Station</MenuItem>
                                            <MenuItem value={'station'}>Tide Station</MenuItem>
                                            <MenuItem value={'gauge'}>Rain Gauge</MenuItem>

                                        </Select>



                                    </Col>

                                    <Col {...fullMobile(8, 20)}>
                                        <Typography fontSize={13} fontWeight={600} variant="body1">Node/Location<span style={{ color: 'red' }}>*</span></Typography>
                                        <NodeSelect
                                            dataType={d.dataType}
                                            updateData={(a, b, c) => {
                                                this.setState({ updatingDepth: true })

                                                this.updateData(a, b, c)
                                                setTimeout(() => {
                                                    this.setState({ updatingDepth: false })
                                                }
                                                    , 250)
                                            }}
                                            index={i}
                                            locations={locations}
                                            location={d.location}
                                        />
                                    </Col>
                                    <Col {...fullMobile(8)} >
                                        <Typography fontSize={13} fontWeight={600} variant="body1">Parameter<span style={{ color: 'red' }}>*</span></Typography>
                                        <ParametersLoad userInfo={userInfo} nodeid={d.locationid} dataType={d.dataType} parameterid={d.parameterid}
                                            updateData={(boo, val) => {

                                                this.updateData(i, boo, val)

                                            }} />
                                    </Col>
                                    <Col {...fullMobile(2, 4)} >
                                        <IconButton style={{ ...verticalOffset }} onClick={() => {
                                            this.updateData(i, 'advancedSettings', d.advancedSettings ? !d.advancedSettings : true)
                                        }}>
                                            <FontAwesomeIcon fontSize={18} icon={faGear} color="#9ec1c9" />
                                        </IconButton>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={24}>
                                {/* Row 2 */}
                                <Row gutter={gutter}>
                                    <Col {...fullMobile(6)}>
                                        <Row>
                                            <Col span={24}>
                                                <Typography fontSize={13} variant="body1">Label</Typography>
                                            </Col>
                                            <Col span={24}>
                                                <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small" style={{
                                                    backgroundColor: '#ffffff',
                                                }}
                                                    defaultValue={d.label || ''}
                                                    onBlur={(e) => { this.handleCheck(i, 'label', e.target.value) }}
                                                    onChange={(e) => {
                                                        this.handleCheck(i, 'label', e.target.value)
                                                    }} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col {...fullMobile(6)}>
                                        <Row>
                                            <Col span={24}>
                                                <Typography fontSize={13} variant="body1">Suffix</Typography>
                                            </Col>
                                            <Col span={24}>
                                                <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small" style={{
                                                    backgroundColor: '#ffffff',
                                                }}
                                                    defaultValue={d.suffix || ''}
                                                    onBlur={(e) => { this.handleCheck(i, 'suffix', e.target.value) }}
                                                    onChange={(e) => {
                                                        this.handleCheck(i, 'suffix', e.target.value)
                                                    }} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col {...fullMobile(3, 10)}>
                                        <Select size="small" fullWidth
                                            style={{ ...verticalOffset, backgroundColor: '#ffffff', }}
                                            notched={false}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={d?.typeHidden || d.type || 'spline'}
                                            label="Layer"

                                            onChange={(e) => {
                                                this.updateData(i, 'type', e.target.value)
                                                this.updateData(i, 'typeHidden', e.target.value)


                                            }}

                                        >
                                            <MenuItem value={'spline'}><FontAwesomeIcon fontSize={14} icon={faChartLine} color="rgba(0,0,0,0.8)" /></MenuItem>
                                            <MenuItem value={'column'}><FontAwesomeIcon fontSize={14} icon={faChartColumn} color="rgba(0,0,0,0.8)" /></MenuItem>
                                            <MenuItem value={'area'}><FontAwesomeIcon fontSize={14} icon={faChartArea} color="rgba(0,0,0,0.8)" /></MenuItem>
                                            <MenuItem value={'scatter'}><ScatterPlotIcon fontSize='small' color="rgba(0,0,0,0.8)" /></MenuItem>

                                        </Select>
                                    </Col>
                                    <Col {...fullMobile(3, 8)} style={{ paddingTop: 12 }}>
                                        <Row>
                                            <Col span={24}>
                                                <Typography fontSize={13} variant="body1">Color</Typography>
                                            </Col>
                                            <Col span={24}>
                                                <DataColorCompact color={d.color} updateData={(boo, val) => this.updateData(i, boo, val)} />
                                            </Col>
                                        </Row>


                                    </Col>
                                    <Col {...fullMobile(3, 8)} style={{ paddingTop: 12 }}>
                                        <Row>
                                            <Col span={24}>
                                                <Button size="small" variant="contained" color="primary" onClick={() => {
                                                    //duplicate series
                                                    this.duplicateSeries(i)

                                                }}
                                                    style={{
                                                        color: 'white',
                                                        backgroundColor: '#404050',

                                                    }}
                                                >
                                                    <FontAwesomeIcon fontSize={14} icon={faCopy} color="white" />
                                                </Button>
                                            </Col>
                                        </Row>




                                    </Col>
                                    <Col {...fullMobile(2, 6)} style={{ paddingTop: 12 }}>
                                        <DeleteItem row={d} type="chartData" />
                                    </Col>
                                    {/* Advanced settings */}
                                    <Col {...fullMobile(22)}>
                                        <Row style={{ paddingBottom: 8 }}>
                                            <Col span={24}>
                                                <Collapse in={d.advancedSettings || false} timeout="auto" unmountOnExit>
                                                    <Row align="middle" gutter={[8, 8]}>
                                                        <Col span={24} style={{ paddingTop: 8 }}>
                                                            <Typography fontSize={14} fontWeight={500} variant="body1">Advanced</Typography>
                                                        </Col>
                                                        {/* Dash */}
                                                        <Col {...fullMobile(8, 12)}>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <Typography fontSize={13} variant="body1">Dash</Typography>
                                                                </Col>
                                                                <Col span={24}>
                                                                    <Select size="small" fullWidth
                                                                        notched={false}
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        value={d.dashed || 'Solid'}
                                                                        label="Dash"
                                                                        style={{
                                                                            backgroundColor: 'white'
                                                                        }}
                                                                        onChange={(e) => {
                                                                            this.updateData(i, 'dashed', e.target.value)
                                                                        }}

                                                                    >
                                                                        <MenuItem value={'Solid'}>
                                                                            <FontAwesomeIcon icon={faMinus} style={{ marginLeft: 15, transform: "scaleX(3)" }} />
                                                                        </MenuItem>
                                                                        <MenuItem value={'ShortDash'}>
                                                                            <FontAwesomeIcon icon={faMinus} style={{ transform: "scaleX(.8)" }} />
                                                                            <FontAwesomeIcon icon={faMinus} style={{ marginLeft: 5, transform: "scaleX(.8)" }} />

                                                                        </MenuItem>
                                                                        <MenuItem value={'Dash'}>
                                                                            <FontAwesomeIcon icon={faMinus} style={{ marginLeft: 2, }} />
                                                                            <FontAwesomeIcon icon={faMinus} style={{ marginLeft: 5, }} />
                                                                        </MenuItem>
                                                                        <MenuItem value={'LongDash'}>
                                                                            <FontAwesomeIcon icon={faMinus} style={{ marginLeft: 4, transform: "scaleX(2)" }} />
                                                                            <FontAwesomeIcon icon={faMinus} style={{ marginLeft: 20, transform: "scaleX(2)" }} />
                                                                        </MenuItem>
                                                                        <MenuItem value={'Dot'}>
                                                                            <FontAwesomeIcon icon={faEllipsisH} />
                                                                        </MenuItem>
                                                                    </Select>
                                                                </Col>
                                                            </Row>
                                                        </Col>

                                                        {/* Units */}
                                                        <Col {...fullMobile(8, 12)}>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <Typography fontSize={13} variant="body1">Units</Typography>
                                                                </Col>
                                                                <Col span={24}>
                                                                    <Select size="small" fullWidth
                                                                        notched={false}
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        value={d.units || ''}
                                                                        label="Units"
                                                                        style={{
                                                                            backgroundColor: 'white'
                                                                        }}
                                                                        onChange={(e) => {
                                                                            this.updateData(i, 'units', e.target.value)
                                                                        }}

                                                                    >


                                                                        {['airTemperature', 'temperaturelow', 'temperaturehigh', '1'].includes(d.parameterid) && (
                                                                            ['C', 'F'].map((f) => {
                                                                                return (
                                                                                    <MenuItem value={f}>{f}</MenuItem>
                                                                                )
                                                                            })
                                                                        )}
                                                                        {['precipitation'].includes(d.parameterid) && (
                                                                            ['in', 'mm'].map((f) => {
                                                                                return (
                                                                                    <MenuItem value={f}>{f}</MenuItem>
                                                                                )
                                                                            })
                                                                        )}
                                                                        {['windSpeed'].includes(d.parameterid) && (
                                                                            ['mph', 'm/s'].map((f) => {
                                                                                return (
                                                                                    <MenuItem value={f}>{f}</MenuItem>
                                                                                )
                                                                            })
                                                                        )}
                                                                        {d.parameterid === '3' && (
                                                                            ['m', 'ft'].map((f) => {
                                                                                return (
                                                                                    <MenuItem value={f}>{f}</MenuItem>
                                                                                )
                                                                            })
                                                                        )}



                                                                    </Select>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        {/* Line width */}
                                                        <Col {...fullMobile(8, 10)}>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <Typography fontSize={13} variant="body1">Line Width</Typography>
                                                                </Col>
                                                                <Col span={24}>
                                                                    <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                                        defaultValue={d.lineWidth || 2}
                                                                        type="number"
                                                                        inputProps={{ min: 0 }}
                                                                        style={{
                                                                            backgroundColor: 'white'
                                                                        }}
                                                                        onChange={(e) => {
                                                                            this.handleCheck(i, 'lineWidth', e.target.value === "" ? "" : Number(e.target.value))
                                                                        }} />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        {/* Dots */}

                                                        {/* Opacity */}
                                                        <Col {...fullMobile(8, 10)}>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <Typography fontSize={13} variant="body1">Opacity</Typography>
                                                                </Col>
                                                                <Col span={24}>
                                                                    <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"

                                                                        defaultValue={d.opacity || ''}
                                                                        type="number"
                                                                        inputProps={{ min: 0 }}
                                                                        style={{
                                                                            backgroundColor: 'white'
                                                                        }}
                                                                        onChange={(e) => {
                                                                            this.handleCheck(i, 'opacity', e.target.value === "" ? "" : Number(e.target.value))
                                                                        }} />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        {/* Column Width */}
                                                        {d.type === 'column' && (<Col span={8}>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <Typography fontSize={13} variant="body1">Column Width</Typography>
                                                                </Col>
                                                                <Col span={24}>
                                                                    <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                                        defaultValue={d.columnWidth || 10}
                                                                        type="number"
                                                                        inputProps={{ min: 0 }}
                                                                        style={{
                                                                            backgroundColor: 'white'
                                                                        }}
                                                                        onChange={(e) => {
                                                                            this.handleCheck(i, 'columnWidth', e.target.value === "" ? "" : Number(e.target.value))
                                                                        }} />
                                                                </Col>
                                                            </Row>
                                                        </Col>)}
                                                        {d.type === 'column' && (<Col span={8}>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <Typography fontSize={13} variant="body1">Column Opacity</Typography>
                                                                </Col>
                                                                <Col span={24}>
                                                                    <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                                        defaultValue={d.opacity || 1}
                                                                        type="number"
                                                                        inputProps={{ min: 0, max: 1, step: 0.1 }}
                                                                        style={{
                                                                            backgroundColor: 'white'
                                                                        }}
                                                                        onChange={(e) => {
                                                                            this.handleCheck(i, 'opacity', e.target.value === "" ? "" : Number(e.target.value))
                                                                        }} />
                                                                </Col>
                                                            </Row>
                                                        </Col>)}
                                                        {/* Dec. Places */}
                                                        <Col {...fullMobile(8, 12)}>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <Typography fontSize={13} variant="body1">Dec. Places</Typography>
                                                                </Col>
                                                                <Col span={24}>
                                                                    <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                                        id={`${d.key}value`}
                                                                        defaultValue={input}
                                                                        type="number"
                                                                        style={{
                                                                            backgroundColor: 'white'
                                                                        }}
                                                                        onChange={(e) => {
                                                                            this.handleCheck(i, 'decimals', e.target.value === "" ? Number(0) : e.target.value === "0" ? Number(0) : Number(e.target.value))
                                                                        }} />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        {/* Offset */}
                                                        <Col {...fullMobile(8, 12)}>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <Typography fontSize={13} variant="body1">Offset</Typography>
                                                                </Col>
                                                                <Col span={24}>
                                                                    <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                                        defaultValue={d.offset || ''}
                                                                        type="number"
                                                                        style={{
                                                                            backgroundColor: 'white'
                                                                        }}
                                                                        onChange={(e) => {
                                                                            this.handleCheck(i, 'offset', e.target.value === "" ? "" : Number(e.target.value))
                                                                        }} />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col {...fullMobile(4, 12)}>
                                                            <Row>
                                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                                    <Typography fontSize={13} variant="body1">Show in Legend</Typography>
                                                                </Col>
                                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                                    <Checkbox defaultChecked={d.showInLegend === undefined ? true : d.showInLegend}
                                                                        style={{
                                                                            backgroundColor: 'white'
                                                                        }}
                                                                        onChange={(e) => {
                                                                            this.updateData(i, 'showInLegend', e.target.checked)
                                                                        }} />
                                                                </Col>
                                                            </Row>
                                                        </Col>

                                                        <Col {...fullMobile(12, 12)}>
                                                            <Row>
                                                                <Col span={24} >
                                                                    <Typography fontSize={13} variant="body1">Data Source</Typography>
                                                                </Col>
                                                                <Col span={24} >
                                                                    <Select size="small" fullWidth
                                                                        notched={false}
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        value={d.dataSourceType || 'reading'}
                                                                        style={{
                                                                            backgroundColor: 'white'
                                                                        }}
                                                                        label="Dash"
                                                                        onChange={(e) => {
                                                                            this.updateData(i, 'dataSourceType', e.target.value)
                                                                        }}

                                                                    >
                                                                        <MenuItem value={'reading'}>Reading</MenuItem>
                                                                        <MenuItem value={'readingWithOffset'}>Reading w/ offset</MenuItem>
                                                                        <MenuItem value={'offset'}>Offset only</MenuItem>



                                                                    </Select>
                                                                </Col>
                                                            </Row>
                                                        </Col>

                                                        <Col span={24} style={{ paddingTop: 8 }}>
                                                            <Typography fontSize={14} fontWeight={500} variant="body2">Axis Options</Typography>
                                                        </Col>
                                                        <Col span={8}>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <Typography fontSize={13} variant="body1">Y-Axis ID</Typography>
                                                                </Col>
                                                                <Col span={24}>

                                                                    <Select size="small" fullWidth
                                                                        notched={false}
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        value={d.yAxis || 0}
                                                                        style={{
                                                                            backgroundColor: 'white'
                                                                        }}
                                                                        label="Dash"
                                                                        onChange={(e) => {
                                                                            this.updateData(i, 'yAxis', e.target.value)
                                                                        }}

                                                                    >
                                                                        {attributes.axises.map((a) => {

                                                                            return (
                                                                                <MenuItem value={a.axisId}>{a.label || a.axisId}</MenuItem>
                                                                            )
                                                                        })}


                                                                    </Select>


                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col {...fullMobile(4)}>
                                                            <Typography fontSize={14} fontWeight={500} variant="body1">Data Group</Typography>
                                                        </Col>
                                                        <Col {...fullMobile(4)}>
                                                            <Row>
                                                                <Col {...fullMobile(24, 12)} style={{ textAlign: 'center' }}>
                                                                    <Typography fontSize={13} variant="body1">Custom Interval</Typography>
                                                                </Col>
                                                                <Col {...fullMobile(24, 2)} style={{ textAlign: 'center' }}>
                                                                    <Checkbox checked={d.customInterval || false}
                                                                        onChange={(e) => {
                                                                            this.updateData(i, 'customInterval', e.target.checked)

                                                                        }} />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col {...fullMobile(8)}>
                                                            {d.customInterval === true && (<Row>
                                                                <Col span={24}>
                                                                    <Typography fontSize={13} variant="body1">Grouping by Hours</Typography>
                                                                </Col>
                                                                <Col span={24}>
                                                                    <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                                        value={d.customIntervalVal || 4}
                                                                        type="number"
                                                                        style={{
                                                                            backgroundColor: 'white'
                                                                        }}
                                                                        inputProps={{ min: 0.001 }}
                                                                        onChange={(e) => {
                                                                            this.updateData(i, 'customIntervalVal', e.target.value === "" ? "" : Number(e.target.value))
                                                                        }} />
                                                                </Col>
                                                            </Row>)}
                                                        </Col>


                                                        <Col span={24} style={{ paddingTop: 8 }}>
                                                            <Typography fontSize={14} fontWeight={500} variant="body2">Marker Options</Typography>
                                                        </Col>
                                                        <Col {...fullMobile(8, 4)}>
                                                            <Row>
                                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                                    <Typography fontSize={13} variant="body1">Dots</Typography>
                                                                </Col>
                                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                                    <Checkbox checked={d.dots}
                                                                        style={{
                                                                            backgroundColor: 'white'
                                                                        }}
                                                                        onChange={(e) => {
                                                                            this.updateData(i, 'dots', e.target.checked)
                                                                        }} />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col span={4}>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <Typography fontSize={13} variant="body1">Fill</Typography>
                                                                </Col>
                                                                <Col span={24}>
                                                                    <DataColorCompact color={d.markerFill} updateData={(boo, val) => this.updateData(i, 'markerFill', val)} />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col span={4}>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <Typography fontSize={13} variant="body1">Line</Typography>
                                                                </Col>
                                                                <Col span={24}>
                                                                    <DataColorCompact color={d.markerLine} updateData={(boo, val) => this.updateData(i, 'markerLine', val)} />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        {d.dataType === 'node' && (
                                                            <Col span={24} style={{ paddingTop: 8 }}>
                                                                <SeriesConditions conditionsForSeries={d?.conditions || []} series={d} userInfo={userInfo} index={i} updateData={(a) => {
                                                                    this.updateData(i, 'conditions', a)
                                                                }} />
                                                            </Col>
                                                        )}

                                                    </Row>
                                                </Collapse>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    {/* Horizontal divider */}
                    <Col span={24}>
                        <Divider />
                    </Col>
                </Row>
            )
        }

        const axisMap = (d, i) => {
            const ticks = d.tickAmount === 0 || d.tickAmount ? d.tickAmount : '';
            const tickIntervals = d.tickInterval === 0 || d.tickInterval ? d.tickInterval : '';
            const minorTickInterval = d.minorTickInterval === 0 || d.minorTickInterval ? d.minorTickInterval : '';

            return (
                <Row align="middle" gutter={[8, 8]} style={{ paddingBottom: 5 }} key={d.key}>
                    <Col {...fullMobile(2, undefined, { xs: 0 })} className="my-handle">
                        <DragIndicatorIcon />
                    </Col>
                    <Col {...fullMobile(4, 12)}>
                        <Row>
                            <Col span={24}>
                                <Typography fontSize={13} variant="body1">ID</Typography>
                            </Col>
                            <Col span={24}>
                                <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                    defaultValue={d.axisId}
                                    disabled
                                    type="number"
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col {...fullMobile(6, 12)}>
                        <Row>
                            <Col span={24}>
                                <Typography fontSize={13} variant="body1">Label</Typography>
                            </Col>
                            <Col span={24}>
                                <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                    defaultValue={d.label || ''}
                                    onChange={(e) => {
                                        this.handleCheckAxis(i, 'label', e.target.value)
                                    }} />
                            </Col>
                        </Row>
                    </Col>
                    <Col {...fullMobile(4, 12)}>
                        <Row>
                            <Col span={24} style={{ textAlign: 'center' }}>
                                <Typography fontSize={13} variant="body1">Opposite</Typography>
                            </Col>
                            <Col span={24} style={{ textAlign: 'center' }}>
                                <Checkbox checked={d.opposite}
                                    onChange={(e) => {
                                        this.updateAxis(i, 'opposite', e.target.checked)
                                    }} />
                            </Col>
                        </Row>
                    </Col>
                    <Col {...fullMobile(4, 12)} >
                        <IconButton onClick={() => {
                            this.updateAxis(i, 'advancedSettings', d.advancedSettings ? !d.advancedSettings : true)
                        }}>
                            <FontAwesomeIcon fontSize={18} icon={faGear} color="#9ec1c9" />
                        </IconButton>
                    </Col>
                    <Col span={4}>
                        {d.axisId !== 0 && (<DeleteItem row={d} type="axises" />)}
                    </Col>
                    <Col offset={2} {...fullMobile(22)}>
                        <Collapse in={d.advancedSettings || false} timeout="auto" unmountOnExit>
                            <Row align="middle" gutter={[8, 8]}>
                                <Col span={24} style={{ paddingTop: 8 }}>
                                    <Typography fontSize={14} fontWeight={500} variant="body1">Advanced</Typography>
                                </Col>
                                <Col span={12}>
                                    <Row>
                                        <Col span={24}>
                                            <Typography fontSize={13} variant="body1">Custom Range</Typography>
                                        </Col>
                                        <Col span={24}>
                                            <ButtonGroup variant="outlined"
                                                size="small" aria-label="contained primary button group">
                                                <Button style={{
                                                    color: d.customRange === false && d.autoRange === false ? 'white' : 'black',
                                                    textTransform: 'none',
                                                    //add orange bottom border if current button is users
                                                    backgroundColor: d.customRange === false && d.autoRange === false ? '#3f51b5' : 'white',
                                                }}
                                                    onClick={() => {
                                                        this.updateAxis(i, 'customRange', false)
                                                        this.updateAxis(i, 'autoRange', false)

                                                    }}
                                                >
                                                    None
                                                </Button>
                                                <Button style={{
                                                    color: d.customRange === true ? 'white' : 'black',
                                                    textTransform: 'none',
                                                    //add orange bottom border if current button is users
                                                    backgroundColor: d.customRange === true ? '#3f51b5' : 'white',
                                                }}
                                                    onClick={() => {
                                                        this.updateAxis(i, 'customRange', true)
                                                        this.updateAxis(i, 'autoRange', false)

                                                    }}
                                                >
                                                    Custom
                                                </Button>
                                                <Button style={{
                                                    color: d.autoRange === true ? 'white' : 'black',
                                                    textTransform: 'none',
                                                    //add orange bottom border if current button is users
                                                    backgroundColor: d.autoRange === true ? '#3f51b5' : 'white',
                                                }}
                                                    onClick={() => {
                                                        this.updateAxis(i, 'autoRange', true)
                                                        this.updateAxis(i, 'customRange', false)

                                                    }}
                                                >
                                                    Auto Range
                                                </Button>

                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                </Col>

                                {/*  {d.autoRange === true && (
                                    <Col span={12}>
                                        <Row>
                                            <Col span={24}>
                                                <Typography fontSize={13} variant="body1">Min/Max Padding</Typography>
                                            </Col>
                                            <Col span={12}>
                                                <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                    defaultValue={d.minMaxPadding}
                                                    type="number"
                                                    inputProps={{ min: 0 }}
                                                    onChange={(e) => {
                                                        this.handleCheckAxis(i, 'minMaxPadding', e.target.value === "" ? "" : Number(e.target.value))
                                                    }} />
                                            </Col>
                                        </Row>
                                    </Col>)} */}

                                <Col span={6}>
                                    {d.customRange === true && (<Row>
                                        <Col span={24}>
                                            <Typography fontSize={13} variant="body1">Min</Typography>
                                        </Col>
                                        <Col span={24}>
                                            <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                defaultValue={d.min}
                                                type="number"
                                                inputProps={{ min: 0 }}
                                                onChange={(e) => {
                                                    this.handleCheckAxis(i, 'min', e.target.value === "" ? "" : Number(e.target.value))
                                                }} />
                                        </Col>
                                    </Row>)}
                                </Col>

                                <Col span={6}>
                                    {d.customRange === true && (<Row>
                                        <Col span={24}>
                                            <Typography fontSize={13} variant="body1">Max</Typography>
                                        </Col>
                                        <Col span={24}>
                                            <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                defaultValue={d.max}
                                                type="number"
                                                inputProps={{ min: 0 }}
                                                onChange={(e) => {
                                                    this.handleCheckAxis(i, 'max', e.target.value === "" ? "" : Number(e.target.value))
                                                }} />
                                        </Col>
                                    </Row>)}
                                </Col>

                                <Col {...fullMobile(6)}>
                                    <Row>
                                        <Col span={24}>
                                            <Typography fontSize={13} variant="body1">Dash</Typography>
                                        </Col>
                                        <Col span={24}>
                                            <Select size="small" fullWidth
                                                notched={false}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={d.gridlineDashed || 'Solid'}
                                                label="Dash"
                                                onChange={(e) => {
                                                    this.handleCheckAxis(i, 'gridlineDashed', e.target.value)
                                                }}

                                            >
                                                <MenuItem value={'Solid'}>
                                                    <FontAwesomeIcon icon={faMinus} style={{ marginLeft: 15, transform: "scaleX(3)" }} />
                                                </MenuItem>
                                                <MenuItem value={'ShortDash'}>
                                                    <FontAwesomeIcon icon={faMinus} style={{ transform: "scaleX(.8)" }} />
                                                    <FontAwesomeIcon icon={faMinus} style={{ marginLeft: 5, transform: "scaleX(.8)" }} />

                                                </MenuItem>
                                                <MenuItem value={'Dash'}>
                                                    <FontAwesomeIcon icon={faMinus} style={{ marginLeft: 2, }} />
                                                    <FontAwesomeIcon icon={faMinus} style={{ marginLeft: 5, }} />
                                                </MenuItem>
                                                <MenuItem value={'LongDash'}>
                                                    <FontAwesomeIcon icon={faMinus} style={{ marginLeft: 4, transform: "scaleX(2)" }} />
                                                    <FontAwesomeIcon icon={faMinus} style={{ marginLeft: 20, transform: "scaleX(2)" }} />
                                                </MenuItem>
                                                <MenuItem value={'Dot'}>
                                                    <FontAwesomeIcon icon={faEllipsisH} />
                                                </MenuItem>
                                            </Select>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col {...fullMobile(6, 12)}>
                                    <Row>
                                        <Col span={24} style={{ textAlign: 'center' }}>
                                            <Typography fontSize={13} variant="body1">Font Size</Typography>
                                        </Col>
                                        <Col span={24} style={{ textAlign: 'center' }}>
                                            <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                defaultValue={d.fontSize || 12}
                                                type="number"
                                                inputProps={{ min: 0 }}
                                                onChange={(e) => {
                                                    this.handleCheckAxis(i, 'fontSize', e.target.value === "" ? "" : Number(e.target.value))
                                                }} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col {...fullMobile(6, 12)}>
                                    <Row>
                                        <Col span={24}>
                                            <Typography fontSize={13} variant="body1">Grid Width</Typography>
                                        </Col>
                                        <Col span={24}>
                                            <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                defaultValue={d.gridLineWidth}
                                                type="number"
                                                inputProps={{ min: 0 }}
                                                onChange={(e) => {
                                                    this.handleCheckAxis(i, 'gridLineWidth', e.target.value === "" ? "" : Number(e.target.value))
                                                }} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col {...fullMobile(6, 12)}>
                                    <Row>
                                        <Col span={24}>
                                            <Typography fontSize={13} variant="body1">Label Rotation</Typography>
                                        </Col>
                                        <Col span={24}>
                                            <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                defaultValue={d.labelRotation}
                                                type="number"
                                                inputProps={{ min: 0 }}
                                                onChange={(e) => {
                                                    this.handleCheckAxis(i, 'labelRotation', e.target.value === "" ? "" : Number(e.target.value))
                                                }} />
                                        </Col>
                                    </Row>
                                </Col>

                                <Col {...fullMobile(6, 12)}>
                                    <Row>
                                        <Col span={24} style={{ textAlign: 'center' }}>
                                            <Typography fontSize={13} variant="body1">Align Ticks</Typography>
                                        </Col>
                                        <Col span={24} style={{ textAlign: 'center' }}>
                                            <Checkbox checked={d.alignTicks}
                                                onChange={(e) => {
                                                    this.handleCheckAxis(i, 'alignTicks', e.target.checked)
                                                }} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col {...fullMobile(6, 12)}>
                                    <Row>
                                        <Col span={24} style={{ textAlign: 'center' }}>
                                            <Typography fontSize={13} variant="body1">End On Tick</Typography>
                                        </Col>
                                        <Col span={24} style={{ textAlign: 'center' }}>
                                            <Checkbox checked={d.endOnTick}
                                                onChange={(e) => {
                                                    this.updateAxis(i, 'endOnTick', e.target.checked)
                                                }} />
                                        </Col>
                                    </Row>
                                </Col>

                                <Col {...fullMobile(8, 12)}>
                                    <Row>
                                        <Col span={24} style={{ textAlign: 'center' }}>
                                            <Typography fontSize={13} variant="body1">Reversed</Typography>
                                        </Col>
                                        <Col span={24} style={{ textAlign: 'center' }}>
                                            <Checkbox checked={d.reversed}
                                                onChange={(e) => {
                                                    this.updateAxis(i, 'reversed', e.target.checked)
                                                }} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col {...fullMobile(4, 12)}>
                                    <Row>
                                        <Col span={24}>
                                            <Typography fontSize={13} variant="body1">Custom Ticks</Typography>
                                        </Col>
                                        <Col span={24}>
                                            <Checkbox checked={d.customTicks}
                                                onChange={(e) => {
                                                    this.updateAxis(i, 'customTicks', e.target.checked)
                                                }} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={4}>
                                    {d.customTicks === true && (<Row>
                                        <Col span={24}>
                                            <Typography fontSize={13} variant="body1">Tick Amount</Typography>
                                        </Col>
                                        <Col span={24}>
                                            <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                id={`${d.key}value`}
                                                defaultValue={ticks}
                                                type="number"
                                                onChange={(e) => {
                                                    this.handleCheckAxis(i, 'tickAmount', e.target.value === "" ? Number(0) : e.target.value === "0" ? Number(0) : Number(e.target.value))
                                                }} />
                                        </Col>
                                    </Row>)}
                                </Col>
                                <Col span={4}>
                                    {d.customTicks === true && (<Row>
                                        <Col span={24}>
                                            <Typography fontSize={13} variant="body1">Tick Interval</Typography>
                                        </Col>
                                        <Col span={24}>
                                            <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                id={`${d.key}value`}
                                                defaultValue={tickIntervals}
                                                type="number"
                                                onChange={(e) => {
                                                    this.handleCheckAxis(i, 'tickInterval', e.target.value === "" ? Number(0) : e.target.value === "0" ? Number(0) : Number(e.target.value))
                                                }} />
                                        </Col>
                                    </Row>)}
                                </Col>
                                <Col span={4}>
                                    {d.customTicks === true && (<Row>
                                        <Col span={24}>
                                            <Typography fontSize={13} variant="body1">Minor Tick Interval</Typography>
                                        </Col>
                                        <Col span={24}>
                                            <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                id={`${d.key}value`}
                                                defaultValue={minorTickInterval}
                                                type="number"
                                                onChange={(e) => {
                                                    this.handleCheckAxis(i, 'minorTickInterval', e.target.value === "" ? Number(0) : e.target.value === "0" ? Number(0) : Number(e.target.value))
                                                }} />
                                        </Col>
                                    </Row>)}
                                </Col>


                                <Col span={24} style={{ paddingTop: 8 }}>
                                    <Typography fontSize={14} fontWeight={500} variant="body2">Color</Typography>
                                </Col>
                                <Col span={6}>
                                    <Row>
                                        <Col span={24}>
                                            <Typography fontSize={13} variant="body1">Axis Color</Typography>
                                        </Col>
                                        <Col span={24}>
                                            <DataColorCompact color={d.axisColor} updateData={(boo, val) => this.updateAxis(i, 'axisColor', val)} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={6}>
                                    <Row>
                                        <Col span={24}>
                                            <Typography fontSize={13} variant="body1">Grid Color</Typography>
                                        </Col>
                                        <Col span={24}>
                                            <DataColorCompact color={d.gridLineColor} updateData={(boo, val) => this.updateAxis(i, 'gridLineColor', val)} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={6}>
                                    <Row>
                                        <Col span={24}>
                                            <Typography fontSize={13} variant="body1">Minor Grid Color</Typography>
                                        </Col>
                                        <Col span={24}>
                                            <DataColorCompact color={d.minorGridLineColor} updateData={(boo, val) => this.updateAxis(i, 'minorGridLineColor', val)} />
                                        </Col>
                                    </Row>
                                </Col>

                            </Row>
                        </Collapse>
                    </Col>
                    <Col span={24}>
                        <Divider />
                    </Col>

                </Row>
            )
        }

        const referencesMap = (d, i) => {


            return (
                <Row align="middle" gutter={[8, 8]} style={{ paddingBottom: 5 }} key={d.key}>
                    <Col {...fullMobile(2, undefined, { xs: 0 })} className="my-handle">
                        <DragIndicatorIcon />
                    </Col>
                    <Col {...fullMobile(4, 12)}>
                        <Row>
                            <Col span={24}>
                                <Typography fontSize={13} variant="body1">Axis ID</Typography>
                            </Col>
                            <Col span={24}>

                                <Select size="small" fullWidth
                                    notched={false}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={d.yAxis || 0}
                                    style={{
                                        backgroundColor: 'white'
                                    }}
                                    label="Dash"
                                    onChange={(e) => {
                                        this.updateReference(i, 'yAxis', e.target.value === "" ? "" : Number(e.target.value))
                                    }}

                                >
                                    {attributes.axises.map((a) => {

                                        return (
                                            <MenuItem value={a.axisId}>{a.label || a.axisId}</MenuItem>
                                        )
                                    })}


                                </Select>




                            </Col>
                        </Row>
                    </Col>
                    <Col {...fullMobile(12, 12)}>
                        <Row>
                            <Col span={24}>
                                <Typography fontSize={13} variant="body1">Reference</Typography>
                            </Col>
                            <Col span={24}>
                                <Autocomplete
                                    disableClearable
                                    size="small"
                                    disablePortal
                                    id="combo-box-demo"
                                    options={references}
                                    value={references.find((option) => option.key === d.referenceid) || ''}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Reference"
                                            variant="outlined"
                                        />
                                    )}
                                    onChange={(a, b) => {
                                        this.updateReference(i, 'referenceid', b.key);
                                        this.updateReference(i, 'horizontal', b.horizontal || false);
                                        this.updateReference(i, 'yValue', b.yValue || false);
                                        this.updateReference(i, 'date', b.date || false);
                                        this.updateReference(i, 'reference', b);
                                        this.updateReference(i, 'title', b.label);
                                    }}

                                />
                            </Col>
                        </Row>
                    </Col>

                    <Col span={2} >
                        <IconButton onClick={() => {
                            this.updateReference(i, 'advancedSettings', d.advancedSettings ? !d.advancedSettings : true)
                        }}>
                            <FontAwesomeIcon fontSize={18} icon={faGear} color="#9ec1c9" />
                        </IconButton>
                    </Col>
                    <Col span={2} >
                        <Typography fontSize={13} variant="body1">Active</Typography>
                        <Checkbox
                            defaultChecked={d.active === undefined ? true : d.active}
                            onChange={(e) => {

                                this.updateReference(i, 'active', e.target.checked)
                            }}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />


                    </Col>
                    <Col {...fullMobile(2, 12)}>
                        {d.axisId !== 0 && (<DeleteItem row={d} type="references" />)}
                    </Col>
                    <Col offset={2} {...fullMobile(22)}>
                        <Collapse in={d.advancedSettings || false} timeout="auto" unmountOnExit>
                            <Row align="middle" gutter={[8, 8]}>
                                <Col span={24} style={{ paddingTop: 8 }}>
                                    <Typography fontSize={14} fontWeight={500} variant="body1">Advanced</Typography>
                                </Col>
                                {d.horizontal !== undefined && d.horizontal === true && (
                                    <Col {...fullMobile(8)}>
                                        <Row>
                                            <Col span={24}>
                                                <Typography fontSize={13} variant="body1">y Value</Typography>
                                            </Col>
                                            <Col span={24}>
                                                <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                    defaultValue={d.yValue}
                                                    type="number"
                                                    inputProps={{ min: 0 }}
                                                    onChange={(e) => {
                                                        this.handleCheck1(i, 'yValue', e.target.value === "" ? "" : Number(e.target.value))
                                                    }} />
                                            </Col>
                                        </Row>
                                    </Col>
                                )}
                                {d.horizontal !== undefined && d.horizontal === false && (
                                    <Col {...fullMobile(8)}>
                                        <Row>
                                            <Col span={24}>
                                                <Typography fontSize={13} variant="body1">Date</Typography>
                                            </Col>
                                            <Col span={24}>
                                                <DatePicker portalId="root-portal" showTimeSelect timeFormat="h:mm a" timeIntervals={1} timeCaption="time" dateFormat="MM/dd/yyyy h:mm a"
                                                    selected={[undefined, '', "Invalid date"].includes(d.date) ? new Date() : new Date(moment(d.date, 'x'))}
                                                    onChange={(date) => {

                                                        if (date === null) { this.handleCheck1(i, 'date', moment().format('x')) }
                                                        else { const unix = moment(date).format('x'); this.handleCheck1(i, 'date', unix) }
                                                    }} />
                                            </Col>
                                        </Row>
                                    </Col>
                                )}
                                <Col {...fullMobile(8, 12)}>
                                    <Row>
                                        <Col span={24}>
                                            <Typography fontSize={13} variant="body1">Dash</Typography>
                                        </Col>
                                        <Col span={24}>
                                            <Select size="small" fullWidth
                                                notched={false}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={d.dashStyle || 'Solid'}
                                                label="Dash"
                                                onChange={(e) => {
                                                    this.updateReference(i, 'dashStyle', e.target.value)
                                                }}

                                            >
                                                <MenuItem value={'Solid'}>
                                                    <FontAwesomeIcon icon={faMinus} style={{ marginLeft: 15, transform: "scaleX(3)" }} />
                                                </MenuItem>
                                                <MenuItem value={'ShortDash'}>
                                                    <FontAwesomeIcon icon={faMinus} style={{ transform: "scaleX(.8)" }} />
                                                    <FontAwesomeIcon icon={faMinus} style={{ marginLeft: 5, transform: "scaleX(.8)" }} />

                                                </MenuItem>
                                                <MenuItem value={'Dash'}>
                                                    <FontAwesomeIcon icon={faMinus} style={{ marginLeft: 2, }} />
                                                    <FontAwesomeIcon icon={faMinus} style={{ marginLeft: 5, }} />
                                                </MenuItem>
                                                <MenuItem value={'LongDash'}>
                                                    <FontAwesomeIcon icon={faMinus} style={{ marginLeft: 4, transform: "scaleX(2)" }} />
                                                    <FontAwesomeIcon icon={faMinus} style={{ marginLeft: 20, transform: "scaleX(2)" }} />
                                                </MenuItem>
                                                <MenuItem value={'Dot'}>
                                                    <FontAwesomeIcon icon={faEllipsisH} />
                                                </MenuItem>
                                            </Select>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col {...fullMobile(8, 12)}>
                                    <Row>
                                        <Col span={24}>
                                            <Typography fontSize={13} variant="body1">Text Align</Typography>
                                        </Col>
                                        <Col span={24}>
                                            <Select size="small" fullWidth
                                                notched={false}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={d.textAlign || 'Solid'}
                                                label="Dash"
                                                onChange={(e) => {
                                                    this.updateReference(i, 'textAlign', e.target.value)
                                                }}

                                            >
                                                <MenuItem value={'left'}>Left</MenuItem>
                                                <MenuItem value={'center'}>Center</MenuItem>
                                                <MenuItem value={'right'}>Right</MenuItem>
                                            </Select>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col {...fullMobile(8, 12)}>
                                    <Row>
                                        <Col span={24}>
                                            <Typography fontSize={13} variant="body1">Vertical Align</Typography>
                                        </Col>
                                        <Col span={24}>
                                            <Select size="small" fullWidth
                                                notched={false}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={d.verticalAlign || 'Solid'}
                                                label="Dash"
                                                onChange={(e) => {
                                                    this.updateReference(i, 'verticalAlign', e.target.value)
                                                }}

                                            >
                                                <MenuItem value={'top'}>Top</MenuItem>
                                                <MenuItem value={'middle'}>Middle</MenuItem>
                                                <MenuItem value={'bottom'}>Bottom</MenuItem>
                                            </Select>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col {...fullMobile(8, 12)}>
                                    <Row>
                                        <Col span={24}>
                                            <Typography fontSize={13} variant="body1">Label Rotation</Typography>
                                        </Col>
                                        <Col span={24}>
                                            <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                defaultValue={d.labelRotation}
                                                type="number"
                                                inputProps={{ min: 0 }}
                                                onChange={(e) => {
                                                    this.handleCheck1(i, 'labelRotation', e.target.value === "" ? "" : Number(e.target.value))
                                                }} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col {...fullMobile(8, 12)}>
                                    <Row>
                                        <Col span={24}>
                                            <Typography fontSize={13} variant="body1">Layer</Typography>
                                        </Col>
                                        <Col span={24}>
                                            <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                defaultValue={d.layer}
                                                type="number"
                                                inputProps={{ min: 0 }}
                                                onChange={(e) => {
                                                    this.handleCheck1(i, 'layer', e.target.value === "" ? "" : Number(e.target.value))
                                                }} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col {...fullMobile(8, 12)}>
                                    <Row>
                                        <Col span={24}>
                                            <Typography fontSize={13} variant="body1">Offset</Typography>
                                        </Col>
                                        <Col span={24}>
                                            <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                defaultValue={d.offset}
                                                type="number"
                                                inputProps={{ min: 0 }}
                                                onChange={(e) => {
                                                    this.handleCheck1(i, 'offset', e.target.value === "" ? "" : Number(e.target.value))
                                                }} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col {...fullMobile(8, 12)}>
                                    <Row>
                                        <Col span={24}>
                                            <Typography fontSize={13} variant="body1">Line Width</Typography>
                                        </Col>
                                        <Col span={24}>
                                            <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                defaultValue={d.width}
                                                type="number"
                                                inputProps={{ min: 0 }}
                                                onChange={(e) => {
                                                    this.handleCheck1(i, 'width', e.target.value === "" ? "" : Number(e.target.value))
                                                }} />
                                        </Col>
                                    </Row>
                                </Col>

                                <Col {...fullMobile(8, 12)}>
                                    <Row>
                                        <Col span={16}>
                                            <Typography fontSize={13} variant="body1">Alias</Typography>
                                        </Col>
                                        <Col span={8} style={{ textAlign: 'center' }}>
                                            <Typography fontSize={11} variant="body1">Active</Typography>
                                        </Col>
                                        <Col span={16}>
                                            <OutlinedInput display="inline" notched={false} label="none" placeholder={""} size="small"
                                                defaultValue={d.alias}
                                                onChange={(e) => {
                                                    this.handleCheck1(i, 'alias', e.target.value)
                                                }} />

                                        </Col>
                                        <Col span={8} style={{ textAlign: 'center' }}>
                                            <Checkbox display="inline" checked={d.useAlias}
                                                onChange={(e) => {
                                                    if (d.useAlias === undefined) {
                                                        this.createAlias(i, e.target.checked)
                                                    }
                                                    else {
                                                        this.updateReference(i, 'useAlias', e.target.checked)
                                                    }

                                                }} />

                                        </Col>
                                    </Row>
                                </Col>


                                <Col {...fullMobile(6, 12)}>
                                    <Row>
                                        <Col span={24}>
                                            <Row>
                                                <Col span={24}>
                                                    <Typography fontSize={13} variant="body1">Reference Color</Typography>
                                                </Col>
                                                <Col span={24}>
                                                    <DataColorCompact key="referenceColor" color={d.color} updateData={(boo, val) => this.updateReference(i, 'color', val)} />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col {...fullMobile(6, 12)}>
                                    <Row>
                                        <Col span={24}>
                                            <Row>
                                                <Col span={24}>
                                                    <Typography fontSize={13} variant="body1">Font Color</Typography>
                                                </Col>
                                                <Col span={24}>
                                                    <DataColorCompact key="fontColor" color={d.fontColor} updateData={(boo, val) => this.updateReference(i, 'fontColor', val)} />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col {...fullMobile(6, 12)}>
                                    <Row>
                                        <Col span={24}>
                                            <Typography fontSize={13} variant="body1">Font Size</Typography>
                                        </Col>
                                        <Col span={24}>
                                            <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                defaultValue={d.fontSize || 10}
                                                type="number"
                                                inputProps={{ min: 10 }}
                                                onChange={(e) => {
                                                    this.handleCheck1(i, 'fontSize', e.target.value === "" ? "" : Number(e.target.value))
                                                }} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col {...fullMobile(6, 12)}>
                                    <Row>
                                        <Col span={24}>
                                            <Typography fontSize={13} variant="body1">Font Weight</Typography>
                                        </Col>
                                        <Col span={24}>
                                            <Select size="small" fullWidth
                                                notched={false}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={d.fontWeight || 400}
                                                label="Dash"
                                                onChange={(e) => {
                                                    this.updateReference(i, 'fontWeight', e.target.value)
                                                }}

                                            >
                                                <MenuItem value={400}>400</MenuItem>
                                                <MenuItem value={500}>500</MenuItem>
                                                <MenuItem value={600}>600</MenuItem>
                                                <MenuItem value={700}>700</MenuItem>
                                                <MenuItem value={800}>800</MenuItem>
                                            </Select>
                                        </Col>
                                    </Row>
                                </Col>


                            </Row>
                        </Collapse>
                    </Col>
                    <Col span={24}>
                        <Divider />
                    </Col>

                </Row>
            )
        }

        return (
            <Row justify="center" align="middle">
                <Col span={16}>
                    <Typography fontSize={16} variant='subtitle2'>Chart Settings</Typography>
                </Col>
                <Col span={8}>
                    {["EuEZ93ByFZYs8bSUXoYsTt1zu0m2", 'eTevYsI9mQMxhtntd95HmRGasNx2', 'PQWFmTLCP9XqafR9ogNI3Fg1sH43', 'z4y8XVrxYGT7rO4R01Y8tQ2F5z83', 't9bOfGDqwDaQTYV6i008opscpjz2'].includes(userInfo.id) && (
                        <Button
                            onClick={async () => {
                                const proxyurl = "https://mycorslake.herokuapp.com/";
                                const response = await axios.post(proxyurl + `https://us-central1-aquasource3.cloudfunctions.net/getStations_new`, {
                                    companies: [
                                        userInfo.currentCompany
                                    ],
                                });



                            }}>

                            Test Get Data
                        </Button>
                    )}
                </Col>
                <Col span={24}>
                    <TabContext value={activeTab}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={(e, tab) => this.setState({ activeTab: tab })} aria-label="lab API tabs example">
                                <Tab style={{ textTransform: 'none' }} label="Data" value="data" />
                                <Tab style={{ textTransform: 'none' }} label="Chart" value="chart" />
                                <Tab style={{ textTransform: 'none' }} label="Axes" value="axises" />
                                <Tab style={{ textTransform: 'none' }} label="References" value="references" />
                            </TabList>
                        </Box>
                        <TabPanel value="chart">

                            <Col xs={24} style={{ paddingTop: 12 }} >
                                <Row gutter={[8, 8]}>
                                    <Col span={24}>
                                        <Typography fontSize={14} fontWeight={600} variant="body1">Settings</Typography>
                                    </Col>
                                    <Col span={24}>
                                        <FormControl component="settings">

                                            <FormGroup row>

                                                {[
                                                    { label: 'Share Axis', key: 'shareAxis' },
                                                    { label: 'Align Ticks', key: 'allignTicks' },
                                                    { label: 'Legend', key: 'legend' },
                                                    { label: 'X-Axis Padding', key: 'xPadding' },


                                                ].map((r) => {
                                                    return (
                                                        <FormControlLabel
                                                            value={r.label}
                                                            control={
                                                                <Checkbox checked={attributes[r.key]}
                                                                    onChange={(e) => {
                                                                        if (r.key === 'allignTicks' && e.target.checked) {
                                                                            this.props.openSnack('warning', 'Allign Ticks will change Start & End on tick to true')
                                                                        }

                                                                        this.updateWidget(r.key, e.target.checked)
                                                                    }} />
                                                            }
                                                            label={r.label}
                                                            labelPlacement="start"
                                                        />
                                                    )
                                                })}




                                            </FormGroup>
                                        </FormControl>
                                    </Col>
                                    <Col span={24}>
                                        <Divider />
                                    </Col>
                                    <Col span={24}>
                                        <Typography fontSize={14} fontWeight={600} variant="body1">Tooltip</Typography>
                                    </Col>
                                    <Col span={8}>
                                        <FormControl component="tooltipSettings" fullWidth>
                                            <InputLabel id="tooltip-settings-label">Tooltip Settings</InputLabel>
                                            <Select
                                                labelId="tooltip-settings-label"
                                                id="tooltip-settings-select"
                                                value={attributes?.showTooltip || 'showTooltip'}
                                                label="Tooltip Settings"
                                                onChange={(e) => {
                                                    this.updateWidget('showTooltip', e.target.value)
                                                }}
                                                size="small"
                                                displayEmpty
                                            >
                                                <MenuItem value={'showTooltip'}>Show Tooltip</MenuItem>
                                                <MenuItem value={'noTooltip'}>No Tooltip</MenuItem>
                                                <MenuItem value={'timeOnly'}>Time Only</MenuItem>
                                                <MenuItem value={'noTooltipSingleLine'}>No Tooltip (single line)</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Col>
                                    <Col span={24}>
                                        <Divider />
                                    </Col>
                                    <Col span={24}>
                                        <Typography fontSize={14} fontWeight={600} variant="body1">Crosshair</Typography>
                                    </Col>
                                    <Col span={24}>
                                        <Row gutter={[4, 4]} align="middle">
                                            <Col span={4}>
                                                <Row>
                                                    <Col span={24} style={{ textAlign: 'left' }}>
                                                        <Typography fontSize={13} variant="body1">X Line width</Typography>
                                                    </Col>
                                                    <Col span={24} style={{ textAlign: 'center' }}>
                                                        <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                            value={attributes.xLineWidth || 10}
                                                            type="number"
                                                            inputProps={{ min: 0 }}
                                                            onChange={(e) => {
                                                                this.updateWidget('xLineWidth', e.target.value === "" ? "" : Number(e.target.value))
                                                            }} />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col span={4}>
                                                <Row>
                                                    <Col span={24} style={{ textAlign: 'left' }}>
                                                        <Typography fontSize={13} variant="body1">X Line Color</Typography>
                                                    </Col>
                                                    <Col span={24} style={{ textAlign: 'center' }}>
                                                        <DataColorChrome color={attributes.xLineColor || '#2651d214'}
                                                            updateData={(boo, val) =>
                                                                this.updateWidget('xLineColor', val)}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col span={4}>
                                                <Row>
                                                    <Col span={24} style={{ textAlign: 'left' }}>
                                                        <Typography fontSize={13} variant="body1">Y Line width</Typography>
                                                    </Col>
                                                    <Col span={24} style={{ textAlign: 'center' }}>
                                                        <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                            value={attributes.yLineWidth || 0}
                                                            type="number"
                                                            inputProps={{ min: 0 }}
                                                            onChange={(e) => {
                                                                this.updateWidget('yLineWidth', e.target.value === "" ? "" : Number(e.target.value))
                                                            }} />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col span={4}>
                                                <Row>
                                                    <Col span={24} style={{ textAlign: 'left' }}>
                                                        <Typography fontSize={13} variant="body1">Y Line Color</Typography>
                                                    </Col>
                                                    <Col span={24} style={{ textAlign: 'center' }}>
                                                        <DataColorChrome color={attributes.yLineColor || '#000000'}
                                                            updateData={(boo, val) =>
                                                                this.updateWidget('yLineColor', val)}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col span={6}>
                                                <Row>
                                                    <Col span={24} style={{ textAlign: 'left' }}>
                                                        <Typography fontSize={13} variant="body1">Y-Axis</Typography>
                                                    </Col>
                                                    <Col span={24} style={{ textAlign: 'center' }}>
                                                        <Select size="small" fullWidth
                                                            notched={false}
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={attributes.yCross || 0}
                                                            style={{
                                                                backgroundColor: 'white'
                                                            }}
                                                            label="Dash"
                                                            onChange={(e) => {
                                                                this.updateWidget('yCross', e.target.value)
                                                            }}

                                                        >
                                                            {attributes.axises.map((a) => {

                                                                return (
                                                                    <MenuItem value={a.axisId}>{a.label || a.axisId}</MenuItem>
                                                                )
                                                            })}


                                                        </Select>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col span={4}>
                                                <Row>
                                                    <Col span={24} style={{ textAlign: 'left' }}>
                                                        <Typography fontSize={13} variant="body1">Z-index</Typography>
                                                    </Col>
                                                    <Col span={24} style={{ textAlign: 'center' }}>
                                                        <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                            value={attributes.crosshairZIndex || 0}
                                                            type="number"
                                                            inputProps={{ min: 0 }}
                                                            onChange={(e) => {
                                                                this.updateWidget('crosshairZIndex', e.target.value === "" ? "" : Number(e.target.value))
                                                            }} />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col span={4}>
                                                <Button
                                                    variant='contained'
                                                    style={{
                                                        textTransform: 'none',
                                                        backgroundColor: '#3f51b5',
                                                    }}
                                                    onClick={() => {
                                                        /* return to default */
                                                        this.updateWidget('xLineWidth', 10)
                                                        this.updateWidget('xLineColor', '#2651d214')
                                                        this.updateWidget('yLineWidth', 0)
                                                        this.updateWidget('yLineColor', '#000000')
                                                        this.updateWidget('crosshairZIndex', 0)
                                                        this.updateWidget('yCross', 0)
                                                    }}>Reset</Button>
                                            </Col>

                                        </Row>

                                    </Col>
                                    <Col span={24}>
                                        <Divider />
                                    </Col>
                                    <Col span={24}>
                                        <Typography fontSize={14} fontWeight={600} variant="body1">Title</Typography>
                                    </Col>

                                    <Col {...fullMobile(6, 12)} style={{ textAlign: 'center' }}>
                                        <Row>
                                            <Col span={24} style={{ textAlign: 'center' }}>
                                                <Typography fontSize={13} variant="body1">Title Font Size</Typography>
                                            </Col>
                                            <Col span={24} style={{ textAlign: 'center' }}>
                                                <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                    defaultValue={attributes.titleFontSize || 24}
                                                    type="number"
                                                    inputProps={{ min: 0 }}
                                                    onChange={(e) => {
                                                        this.updateWidget('titleFontSize', e.target.value === "" ? "" : Number(e.target.value))
                                                    }} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col {...fullMobile(6, 12)} style={{ textAlign: 'center' }}>
                                        <Row>
                                            <Col span={24} style={{ textAlign: 'center' }}>
                                                <Typography fontSize={13} variant="body1">Title Font Weight</Typography>
                                            </Col>
                                            <Col span={24} style={{ textAlign: 'center' }}>
                                                <Select size="small" fullWidth
                                                    notched={false}
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={attributes.titleFontWeight || 300}
                                                    label="Layer"
                                                    onChange={(e) => {
                                                        this.updateWidget('titleFontWeight', e.target.value)
                                                    }}

                                                >
                                                    <MenuItem value={400}>400</MenuItem>
                                                    <MenuItem value={500}>500</MenuItem>
                                                    <MenuItem value={600}>600</MenuItem>
                                                    <MenuItem value={700}>700</MenuItem>
                                                    <MenuItem value={800}>800</MenuItem>
                                                </Select>
                                            </Col>
                                        </Row>

                                    </Col>
                                    <Col {...fullMobile(6, 12)} style={{ textAlign: 'center' }}>
                                        <Row>
                                            <Col span={24} style={{ textAlign: 'left' }}>
                                                <Typography fontSize={13} variant="body1">Title Font Color</Typography>
                                            </Col>
                                            <Col span={24} style={{ textAlign: 'center' }}>
                                                <DataColorCompact color={attributes.titleFontColor || '#000000'}
                                                    updateData={(boo, val) =>
                                                        this.updateWidget('titleFontColor', val)}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col {...fullMobile(6, 12)} style={{ textAlign: 'center' }}>
                                        <Row>
                                            <Col span={24} style={{ textAlign: 'left' }}>
                                                <Typography fontSize={13} variant="body1">X Axis Font Size</Typography>
                                            </Col>
                                            <Col span={24} style={{ textAlign: 'center' }}>
                                                <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                    defaultValue={attributes.xAxisFontSize || 12}
                                                    type="number"
                                                    inputProps={{ min: 0 }}
                                                    onChange={(e) => {
                                                        this.updateWidget('xAxisFontSize', e.target.value === "" ? "" : Number(e.target.value))
                                                    }} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col {...fullMobile(6, 12)} style={{ textAlign: 'center' }}>
                                        <Row>
                                            <Col span={24} style={{ textAlign: 'left' }}>
                                                <Typography fontSize={13} variant="body1">X Axis Label Color</Typography>
                                            </Col>
                                            <Col span={24} style={{ textAlign: 'center' }}>
                                                <DataColorCompact color={attributes.xAxisLabelColor || '#000000'}
                                                    updateData={(boo, val) =>
                                                        this.updateWidget('xAxisLabelColor', val)}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col {...fullMobile(6, 12)} style={{ textAlign: 'center' }}>
                                        <Row>
                                            <Col span={24} style={{ textAlign: 'left' }}>
                                                <Typography fontSize={13} variant="body1">X Axis Color</Typography>
                                            </Col>
                                            <Col span={24} style={{ textAlign: 'center' }}>
                                                <DataColorCompact color={attributes.xAxisColor || '#000000'}
                                                    updateData={(boo, val) =>
                                                        this.updateWidget('xAxisColor', val)}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </TabPanel>
                        <TabPanel value="data" style={{ height: "auto" }}>
                            <Col xs={24} style={{ paddingTop: 4 }} >
                                <Row align="middle" gutter={[12, 12]}>
                                    <Col {...fullMobile(4)}>
                                        <Typography fontSize={14} fontWeight={500} variant="body1">Date</Typography>
                                    </Col>
                                    <Col {...fullMobile(4, 10)}>
                                        <Row>
                                            <Col span={24} style={{ textAlign: 'center' }}>
                                                <Typography fontSize={13} variant="body1">End Date Today</Typography>
                                            </Col>
                                            <Col span={24} style={{ textAlign: 'center' }}>
                                                <Checkbox checked={attributes.endToday}
                                                    onChange={(e) => {
                                                        this.updateWidget('endToday', e.target.checked)

                                                    }} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col {...fullMobile(8, 14)}>
                                        <Row>
                                            <Col span={24}>
                                                <Typography fontSize={13} variant="body1">Start Date</Typography>
                                            </Col>
                                            <Col span={24}>

                                                <DatePicker portalId="root-portal"
                                                    showTimeSelect
                                                    timeFormat="h:mm a"
                                                    timeIntervals={1}
                                                    timeCaption="time"
                                                    dateFormat="MM/dd/yyyy h:mm a"
                                                    selected={
                                                        [undefined, '', "Invalid date"].includes(attributes.startDate) ? new Date() : new Date(moment(attributes.startDate, 'x'))}
                                                    onChange={(date) => {

                                                        if (date === null) {
                                                            this.handleCheckWidget('startDate', moment().format('x'))
                                                        }
                                                        else {
                                                            const unix = moment(date).format('x');
                                                            this.handleCheckWidget('startDate', unix)
                                                        }

                                                    }} />


                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col {...fullMobile(8, 14)}>
                                        <Row>
                                            <Col span={24}>
                                                <Typography fontSize={13} variant="body1">End Date</Typography>
                                            </Col>
                                            <Col span={24}>
                                                {attributes.endToday === true ?
                                                    <Typography fontSize={14} variant="body1">{moment().format('L')}</Typography>
                                                    :
                                                    <DatePicker portalId="root-portal"
                                                        showTimeSelect
                                                        timeFormat="h:mm a"
                                                        timeIntervals={1}
                                                        timeCaption="time"
                                                        dateFormat="MM/dd/yyyy h:mm a"
                                                        selected={
                                                            [undefined, '', "Invalid date"].includes(attributes.endDate) ? new Date() : new Date(moment(attributes.endDate, 'x'))}
                                                        onChange={(date) => {

                                                            if (date === null) {
                                                                this.handleCheckWidget('endDate', moment().format('x'))
                                                            }
                                                            else {
                                                                const unix = moment(date).format('x');
                                                                this.handleCheckWidget('endDate', unix)
                                                            }

                                                        }} />
                                                }

                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col {...fullMobile(4)}>
                                        <Typography fontSize={14} fontWeight={500} variant="body1"></Typography>
                                    </Col>
                                    <Col {...fullMobile(20)}>
                                        <Row gutter={[8, 8]}>

                                            {[
                                                { label: '1-Week', value: 1, units: 'weeks' },
                                                { label: '1-Month', value: 1, units: 'months' },
                                                { label: '3-Month', value: 3, units: 'months' },
                                                { label: '6-Month', value: 6, units: 'months' },
                                                { label: '1-Year', value: 1, units: 'year' },
                                            ].map((p) => {
                                                return (
                                                    <Col>
                                                        <Button variant="outlined" color="inherit" style={{ textTransform: 'none' }} size="small"
                                                            onClick={() => {
                                                                this.updateWidget('startDate', moment().subtract(p.value, p.units).format('x'))
                                                                this.updateWidget('endDate', moment().format('x'))
                                                            }}>{p.label}</Button>
                                                    </Col>
                                                )
                                            })}



                                        </Row>

                                    </Col>



                                    <Col span={20}>
                                        <Divider />
                                    </Col>
                                    <Col span={20}>
                                        <Typography fontSize={14} fontWeight={500} variant="body1">Data Series</Typography>
                                    </Col>
                                    <Col span={4}>
                                        <Button variant={'contained'} display="inline"
                                            size='small'
                                            style={{
                                                textTransform: 'none', backgroundColor: '#404050', fontSize: 14,
                                            }} onClick={() => {
                                                const item = {
                                                    key: uuid(),
                                                    locationid: '',
                                                    parameterid: '',
                                                    color: randomColor(),
                                                    markerFill: randomColor(),
                                                    markerLine: randomColor(),
                                                    type: 'spline',
                                                    dashed: 'Solid',
                                                    lineWidth: 2,
                                                    yAxis: 0,
                                                    tooltip: {},
                                                    marker: {},
                                                    offset: 0,
                                                    units: ''
                                                };
                                                if (attributes.chartData) {
                                                    attributes.chartData.push(item);
                                                    this.setState({ attributes });

                                                }
                                                else {
                                                    attributes.chartData = [];
                                                    attributes.chartData.push(item);
                                                    this.setState({ attributes });

                                                }
                                            }}>
                                            <AddIcon /> Series
                                        </Button>
                                    </Col>

                                    <Col span={24} style={{ paddingTop: 6 }}>
                                        <Divider />
                                    </Col>

                                    <Col span={24}>
                                        {attributes.chartData !== undefined && attributes.chartData.length > 0 && (
                                            <ReactSortable
                                                handle=".my-handle"
                                                animation={500}
                                                list={attributes.chartData}
                                                setList={(newState) => {
                                                    attributes.chartData = newState;
                                                    this.setState({ attributes })
                                                }}
                                            >
                                                {attributes.chartData.map(chartDataMap)}
                                            </ReactSortable>)}
                                    </Col>
                                    <Col span={24} style={{ paddingTop: 6 }}>
                                        <Button variant={'contained'} display="inline"
                                            fullWidth
                                            style={{
                                                textTransform: 'none', backgroundColor: '#404050', fontSize: 14,
                                            }}
                                            onClick={() => {
                                                const item = {
                                                    key: uuid(),
                                                    locationid: '',
                                                    parameterid: '',
                                                    color: randomColor(),
                                                    markerFill: randomColor(),
                                                    markerLine: randomColor(),
                                                    type: 'spline',
                                                    dashed: 'Solid',
                                                    lineWidth: 2,
                                                    yAxis: 0,
                                                    tooltip: {},
                                                    marker: {},
                                                    offset: 0,
                                                    units: ''
                                                };
                                                if (attributes.chartData) {
                                                    attributes.chartData.push(item);
                                                    this.setState({ attributes });

                                                }
                                                else {
                                                    attributes.chartData = [];
                                                    attributes.chartData.push(item);
                                                    this.setState({ attributes });

                                                }
                                            }}>

                                            <AddIcon />  Add Series
                                        </Button>
                                    </Col>
                                </Row>
                            </Col ></TabPanel>
                        <TabPanel value="axises">
                            <Col xs={24} style={{ paddingTop: 4 }} >
                                <Row align="middle" gutter={[12, 12]}>
                                    <Col span={20}>
                                        <Typography fontSize={14} fontWeight={500} variant="body1">Axes</Typography>
                                    </Col>
                                    <Col span={4}>
                                        <Button variant={'contained'} display="inline"
                                            size='small'
                                            style={{
                                                textTransform: 'none', backgroundColor: '#404050', fontSize: 14,
                                            }} onClick={() => {
                                                const item = {
                                                    key: uuid(),
                                                    axisId: attributes.axises !== undefined ? Math.max.apply(Math, attributes.axises.map((o) => { return o.axisId + 1; })) : 0,
                                                    label: '',
                                                    opposite: false,
                                                    customRange: false,
                                                    min: 0,
                                                    max: 100,
                                                    labelRotation: 270,
                                                    axisColor: '#000000',
                                                    gridLineColor: '#000000',
                                                    gridLineDashStyle: 'Solid',
                                                    gridLineWidth: 0.3,
                                                    alignTicks: true,
                                                    endOntick: true,
                                                    reversed: false,
                                                    advancedSettings: false,
                                                    title: {
                                                        text: ''
                                                    }
                                                };

                                                if (attributes.axises) {
                                                    attributes.axises.push(item);
                                                    this.setState({ attributes });

                                                }
                                                else {
                                                    attributes.axises = [];
                                                    attributes.axises.push(item);
                                                    this.setState({ attributes });

                                                }
                                            }}>
                                            <AddIcon /> Axis
                                        </Button>
                                    </Col>

                                    <Col span={24} style={{ paddingTop: 6 }}>
                                        <Divider />
                                    </Col>
                                    <Col span={24} style={{ paddingTop: 6 }}>

                                        {attributes.axises !== undefined && attributes.axises.length > 0 && (
                                            <ReactSortable
                                                handle=".my-handle"
                                                animation={500}
                                                list={attributes.axises}
                                                setList={(newState) => {
                                                    attributes.axises = newState;
                                                    this.setState({ attributes })
                                                }}
                                            >

                                                {attributes.axises.map(axisMap)}
                                            </ReactSortable>)}
                                    </Col>


                                </Row>
                            </Col>
                        </TabPanel>
                        <TabPanel value="references">
                            <Col xs={24} style={{ paddingTop: 4 }} >
                                <Row align="middle" gutter={[12, 12]}>
                                    <Col span={20}>
                                        <Typography fontSize={14} fontWeight={500} variant="body1">References</Typography>
                                    </Col>
                                    <Col span={4}>
                                        <Button variant={'contained'} display="inline"
                                            size='small'
                                            style={{
                                                textTransform: 'none', backgroundColor: '#404050', fontSize: 14,
                                            }} onClick={() => {
                                                const item = {
                                                    key: uuid(),
                                                    yAxis: 0,
                                                    reference: null,
                                                    date: moment().format('x'),
                                                    labelRotation: 270,
                                                    color: '#000000',
                                                    referenceDashed: 'Solid',
                                                    width: 3,
                                                    layer: 20,
                                                    dashStyle: 'Solid',
                                                    verticalAlign: 'top',
                                                    textAlign: 'left',
                                                    offset: 0,
                                                    advancedSettings: false,
                                                    useAlias: false,
                                                    title: '',
                                                    alias: '',
                                                    label: {},
                                                    active: true
                                                };

                                                if (attributes.references) {
                                                    attributes.references.push(item);
                                                    this.setState({ attributes });

                                                }
                                                else {
                                                    attributes.references = [];
                                                    attributes.references.push(item);
                                                    this.setState({ attributes });

                                                }
                                            }}>
                                            <AddIcon /> Reference
                                        </Button>
                                    </Col>

                                    <Col span={24} style={{ paddingTop: 6 }}>
                                        <Divider />
                                    </Col>
                                    <Col span={24} style={{ paddingTop: 6 }}>

                                        {attributes.references !== undefined && attributes.references.length > 0 && (
                                            <ReactSortable
                                                handle=".my-handle"
                                                animation={500}
                                                list={attributes.references}
                                                setList={(newState) => {
                                                    attributes.references = newState;
                                                    this.setState({ attributes })
                                                }}
                                            >
                                                {attributes.references.map(referencesMap)}
                                            </ReactSortable>)}
                                    </Col>


                                </Row>
                            </Col>
                        </TabPanel>
                    </TabContext >
                </Col >







            </Row >
        );
    }
} 