import { faFile, faFolderOpen } from '@fortawesome/free-regular-svg-icons';
import { faArrowsUpToLine, faEdit, faEllipsisV, faSpinner, faSync, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Breadcrumbs, Button, ClickAwayListener, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, List, ListItem, Popper, TableCell, TextField, ThemeProvider, Typography, createTheme } from '@mui/material';
import { Col, Row } from 'antd';
import MUIDataTable from "mui-datatables";
import React, { useEffect, useRef, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { deleteFiles, deleteFolders, getFiles, getFolderStructure, getItems, moveLevel, renameItem } from '../../firebase/firestore';
import AddItem from '../AddItem/AddItem';
import YourCustomRowComponent from './CustomRow';
import './FileTree.css';
import RenameFile from './RenameFile';
import CustomTableHeader from './CustomTableHeader';
import YourCustomHeadComponent from './CustomTableHeader';
import Lightbox from 'react-image-lightbox';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';


const FileTree = ({ setSelectedFolder, openSnack, userInfo }) => {
  const [folderStructure, setFolderStructure] = useState([]);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [breadcrumb, setBreadcrumb] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxImage, setLightboxImage] = useState(null);
  const anchorRefs = useRef(new Map()).current;
  const [renameModalOpen, setRenameModalOpen] = useState(false);
  const [renameValue, setRenameValue] = useState('');
  const [renameId, setRenameId] = useState(null);
  const [renameType, setRenameType] = useState(null);
  const [deletedItems, setDeletedItems] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [folders, setFolders] = useState([]);
  const [files, setFiles] = useState([]);
  const [sortDirection, setSortDirection] = useState('asc');
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [lastDoc, setLastDoc] = useState(null);




  useEffect(() => {
    refreshFolderStructure(null);
  }, []);

  const refreshFolderStructure = async (folderId) => {
    const path = folderId === null ? 'root' : folderId;
    const itemFolders = await getItems(userInfo.currentCompany, userInfo.currentAccount, path, 'imageAlbums');
    const { folders, lastDocRef } = await getFiles(userInfo.currentCompany, userInfo.currentAccount, path, 'images', 'none', pageSize, null);
    
    setFiles((prevFiles) => [...prevFiles, ...folders]);

    setHasMore(folders.length === 0 ? false : true);

    setFolders([...itemFolders]);
    setFiles([...folders]);
    setPage(lastDocRef);


  };

  const navigateToFolder = (folderId) => {

    setSelectedRows([]);
    setCurrentFolder(folderId);
    updateBreadcrumb(folderId);
    setSelectedFolder(folderId)
    setPage(null)
    refreshFolderStructure(folderId);
    window.scrollTo(0, 0);
  };

  const updateBreadcrumb = (folderId) => {
    setBreadcrumb((prevBreadcrumb) => {
      if (folderId === null) {
        return [];
      }
      const folderIndex = prevBreadcrumb.findIndex((item) => item.id === folderId);
      if (folderIndex === -1) {
        const folder = folders.find((folder) => folder.id === folderId);
        return [...prevBreadcrumb, { id: folder.id, name: folder.name }];
      }
      return prevBreadcrumb.slice(0, folderIndex + 1);
    });
  };

  const foldersInCurrentFolder = folders.filter((folder) => folder.parent !== undefined);

  const handleRename = async () => {
    if (renameValue === '') {
      openSnack('error', 'Please enter a name');
      return;
    }

    const folder = folders.find((folder) => folder.id === renameId);
    const type = folder.type === 'file' && folder.parent === null ? 'nullFile' : folder.type;

    await renameItem(renameValue, type, folder.parent, renameId, userInfo.currentCompany);

    setRenameModalOpen(false);
    refreshFolderStructure(currentFolder);
    setRenameValue('');
    setRenameId(null);
    setRenameType(null);
  };



  const BreadcrumbButtons = ({ items, navigateToFolder }) => {
    return (
      <Breadcrumbs aria-label="breadcrumb"
        //symbol to be > not /
        separator={<Typography variant='body1' fontSize={24} fontWeight={300} color='black'>></Typography>}
      >
        <Button color="primary" className='breadcrumb-button'
          style={{
            textTransform: 'none',
            fontSize: 22,
            fontWeight: 500,
            color: '#404050'
          }}
          onClick={() => navigateToFolder(null)}>
          <Typography variant='body1' fontSize={24} fontWeight={500} color='#404050'>Root Directory</Typography>
        </Button>
        {items.map((item, index) => (
          <React.Fragment key={item.id}>
            {index < items.length - 1 ? (
              <Button color="primary" className='breadcrumb-button'
                style={{
                  textTransform: 'none',

                }}
                onClick={() => navigateToFolder(item.id)}>
                <Typography variant='body1' fontSize={24} fontWeight={500} color='#404050'>{item.name}</Typography>


              </Button>
            ) : (
              <Typography variant='body1' fontSize={24} fontWeight={300} color='black'>{item.name}</Typography>
            )}
          </React.Fragment>
        ))}
      </Breadcrumbs>
    );
  };





  const handleSelectRow = (isChecked, rowId) => {


    if (isChecked) {
     
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, rowId]);
    } else {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((row) => row !== rowId)
      );
    }
  };



  const handleDelete = async () => {
    const selectedItems = [...files, ...folders].filter((item) => selectedRows.includes(item.id));

    const deletePromises = selectedItems.map((item) => {
      if (item.type === 'folder') {
        return deleteFolders([item], userInfo.currentCompany);
      } else {
        
        return deleteFiles([item], userInfo.currentCompany);
      }
    });

    // Wait for all deletion processes to complete
    await Promise.all(deletePromises);

    // Clear the selected rows and refresh the folder structure
    setSelectedRows([]);
    refreshFolderStructure(currentFolder);
  };



  const moveUpOneLevel = async () => {
    const selectedFolders = folders.filter((item) => selectedRows.includes(item.id));
    const selectedFiles = files.filter((item) => selectedRows.includes(item.id));
    const selectedItems = [...selectedFolders, ...selectedFiles];

    // Move folders and files
    for (const item of selectedItems) {
      await moveLevel(userInfo.currentCompany, item, item.type === 'folder' ? 'imageAlbums' : 'images');
    }

    // Clear the selected rows and refresh the folder structure
    setSelectedRows([]);
    refreshFolderStructure(currentFolder);
  };

  const findImageIndex = (url) => {
    const index = files.findIndex((file) => file.downloadUrl === url);
    return index;
  };

  async function openLightbox(item) {

    setLightboxImage(item);
    setLightboxOpen(true);
  }


  const SortButton = ({ sortType, sortDirection, setSortDirection }) => {
    const handleSort = () => {
      const sortedFolders = [...folders].sort((a, b) => {
        if (sortType === 'name') {
          return sortDirection === 'asc' ? b.name.localeCompare(a.name) : a.name.localeCompare(b.name);
        } else {
          const dateTakenA = moment(a.dateTaken, 'MM/DD/YYYY HH:mm:ss').valueOf();
          const dateTakenB = moment(b.dateTaken, 'MM/DD/YYYY HH:mm:ss').valueOf();
          return sortDirection === 'asc' ? dateTakenB - dateTakenA : dateTakenA - dateTakenB;
        }
      });

      const sortedFiles = [...files].sort((a, b) => {
        if (sortType === 'name') {
          return sortDirection === 'asc' ? b.name.localeCompare(a.name) : a.name.localeCompare(b.name);
        } else {
          const dateTakenA = moment(a.dateTaken, 'MM/DD/YYYY HH:mm:ss').valueOf();
          const dateTakenB = moment(b.dateTaken, 'MM/DD/YYYY HH:mm:ss').valueOf();
          return sortDirection === 'asc' ? dateTakenB - dateTakenA : dateTakenA - dateTakenB;
        }
      });

      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
      setFolders(sortedFolders);
      setFiles(sortedFiles);
      setFolderStructure([...sortedFolders, ...sortedFiles]);
    };

    const arrow = sortDirection === 'asc' ? '▲' : '▼';

    return (
      <Button
        onClick={handleSort}
        style={{
          textTransform: 'none',
          fontSize: 12,
          fontWeight: 500,
          color: '#404050',
          backgroundColor: 'white',
          border: '1px solid #404050',
          borderRadius: 5,
          padding: '5px 10px',
        }}
      >
        <Typography variant='body1' fontSize={12} fontWeight={500} color='#404050'>
          Sort by {sortType === 'name' ? 'Name' : 'Date'}
        </Typography>
        <Typography variant='body1' fontSize={12} fontWeight={500} color='#404050'>{arrow}</Typography>
      </Button>
    );
  };


  const loadMoreFiles = async () => {
    const path = currentFolder === null ? 'root' : currentFolder;
    const { folders, lastDocRef } = await getFiles(userInfo.currentCompany, userInfo.currentAccount, path, 'images', 'none', pageSize, page);
    setFiles((prevFiles) => [...prevFiles, ...folders]);
    setPage(lastDocRef);
    setHasMore(folders.length === 0 ? false : true);
  };



  const selectedFolders = [...folders, ...files].filter((folder) => selectedRows.includes(folder.id));

  const SelectAllButton = () => {

    return (
      <Button
        onClick={() => {
        
          if (selectedRows.length !== files.length) {
            const allRows = [...files].map((item) => item.id);
            setSelectedRows(allRows);
          } else {
            setSelectedRows([]);
          }
        }}
        style={{
          textTransform: 'none',
          fontSize: 12,
          fontWeight: 500,
          color: '#404050',
          backgroundColor: 'white',
          border: '1px solid #404050',
          borderRadius: 5,
          padding: '5px 10px',
        }}
      >
        <Typography variant='body1' fontSize={12} fontWeight={500} color='#404050'>
          {selectedRows.length !== files.length ? 'Select All' : 'Deselect All'}
        </Typography>
      </Button>
    );

  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Row className="folder-structure">
        <Col span={20}>
          <BreadcrumbButtons items={breadcrumb} navigateToFolder={navigateToFolder} />
        </Col>
        <Col xs={24} sm={4} style={{ textAlign: 'right' }}>
          <AddItem
            userInfo={userInfo}
            openSnack={openSnack}
            selectedFolder={currentFolder}
            refreshFolderStructure={refreshFolderStructure}
          />
        </Col>
        <Col span={24} style={{ paddingBottom: 15, textAlign: 'right' }}>
          {selectedRows.length > 0 && (
            <Button
              size="large"
              onClick={() => handleDelete()}
              style={{
                textTransform: 'none',
                border: '1px solid red',
                backgroundColor: 'white',
              }}
            >
              <Typography variant='body1' fontSize={14} fontWeight={500} color='#404050'>
                <FontAwesomeIcon icon={faTrash} size="sm" style={{ marginRight: 10 }} />
                Delete {selectedFolders.length} Items
              </Typography>
            </Button>
          )}

          {selectedRows.length > 0 && currentFolder !== null && (<Button
            size="large"
            onClick={() => moveUpOneLevel()}
            style={{
              textTransform: 'none',
              border: '1px solid #404050',
              backgroundColor: 'white',
              marginLeft: 10,
            }}
          >
            <Typography variant='body1' fontSize={14} fontWeight={500} color='#404050'>
              <FontAwesomeIcon icon={faArrowsUpToLine} size="sm" style={{ marginRight: 10, }} />
              Up One Level
            </Typography>
          </Button>)}
        </Col>
        {['folder',].map((type) => {
          return (
            <Col span={24}>
              {type === 'file' && (
                <Row justify={'end'} gutter={[4, 4]} style={{ padding: 10 }}>
                  <Col >
                    <SortButton sortType={'name'} sortDirection={sortDirection} setSortDirection={setSortDirection} />
                  </Col>
                  <Col >
                    <SortButton sortType={'date'} sortDirection={sortDirection} setSortDirection={setSortDirection} />
                  </Col>
                </Row>
              )}



              <Row gutter={[12, 12]}>
                {folders.length > 0 && (
                  folders.filter((f) => f.type === 'folder').map((f, i) => {
                    return (
                      <Col xs={12} sm={6}>
                        <YourCustomRowComponent
                          key={f.id}
                          name={f.name}
                          id={f.id}
                          downloadUrl={f.downloadUrl}
                          type={f.type}
                          size={f.size}
                          parent={currentFolder}
                          index={f.index}
                          selected={selectedRows.includes(f.id)}
                          refreshFolderStructure={refreshFolderStructure}
                          setRenameModalOpen={setRenameModalOpen}
                          setRenameValue={setRenameValue}
                          setRenameId={setRenameId}
                          setRenameType={setRenameType}
                          openSnack={openSnack}
                          company={userInfo.currentCompany}
                          navigateToFolder={navigateToFolder}
                          dateTaken={f.dateTaken}
                          updatedDate={f.updatedDate}
                          onSelectRow={(isChecked, id) => handleSelectRow(isChecked, id)}
                          openLightbox={() => {
                           
                            openLightbox(f);
                          }}
                        />
                      </Col>
                    );
                  })

                )
                }
              </Row>

              {lightboxOpen && (
                <Lightbox
                  mainSrc={lightboxImage.downloadUrl}
                  nextSrc={files[(findImageIndex(lightboxImage.downloadUrl) + 1) % files.length]?.downloadUrl}
                  prevSrc={files[(findImageIndex(lightboxImage.downloadUrl) - 1 + files.length) % files.length]?.downloadUrl}
                  onCloseRequest={() => setLightboxOpen(false)}
                  onMovePrevRequest={() => {
                    const newIndex = (findImageIndex(lightboxImage.downloadUrl) - 1 + files.length) % files.length;
                    setLightboxImage(files[newIndex]);
                  }}
                  onMoveNextRequest={() => {
                    const newIndex = (findImageIndex(lightboxImage.downloadUrl) + 1) % files.length;
                    setLightboxImage(files[newIndex]);
                  }}
                  toolbarButtons={[
                    <div style={{ color: "white", fontSize: "20px", fontWeight: 500 }}>
                      {lightboxImage.name} | Date: {moment(lightboxImage.dateTaken).format('MM/DD/YYYY')}
                    </div>,
                  ]}
                />
              )}

              {type === 'folder' && (
                <Row>
                  <Col span={24} style={{ paddingBottom: 20, paddingTop: 20 }}>
                    <Divider />
                  </Col>
                </Row>
              )}



            </Col>
          )
        })



        }

        <SelectAllButton /> 
        <Button display="inline" variant='outlined' variant='link' 
        style={{
          textTransform: 'none',

        }}
        onClick={() => refreshFolderStructure(currentFolder)}>
          <FontAwesomeIcon icon={faSync} size="sm" style={{ marginRight: 10 }} />
        </Button>


        
        <Col span={24}>
        <InfiniteScroll
          dataLength={files.length}
          next={loadMoreFiles}
          hasMore={hasMore}
          loader={<Typography
            variant='body1'
            fontSize={14}
            fontWeight={500}
            color='#404050'
          >
            Loading photos...
            <FontAwesomeIcon icon={faSpinner} size="sm" style={{ marginLeft: 10 }} spin />
          </Typography>}
          endMessage={
            <Typography
              variant='body1'
              fontSize={14}
              fontWeight={500}
              color='#404050'
            >
              <b>Yay! You have seen it all</b>
            </Typography>
          }
        >
          <Row gutter={[12, 12]}>
            {
              files.map((f, i) => {
                return (
                  <Col xs={12} sm={6}>
                    <YourCustomRowComponent
                      key={f.id}
                      name={f.name}
                      id={f.id}
                      downloadUrl={f.downloadUrl}
                      url250={f.url250 || undefined}
                      url500={f.url500 || undefined}
                      type={f.type}
                      size={f.size}
                      parent={currentFolder}
                      index={f.index}
                      selected={selectedRows.includes(f.id)}
                      refreshFolderStructure={refreshFolderStructure}
                      setRenameModalOpen={setRenameModalOpen}
                      setRenameValue={setRenameValue}
                      setRenameId={setRenameId}
                      setRenameType={setRenameType}
                      openSnack={openSnack}
                      company={userInfo.currentCompany}
                      navigateToFolder={navigateToFolder}
                      dateTaken={f.dateTaken}
                      updatedDate={f.updatedDate}
                      onSelectRow={(isChecked, id) => handleSelectRow(isChecked, id)}
                      openLightbox={() => {
                    
                        openLightbox(f);
                      }}
                    />
                  </Col>
                );
              })


            }
          </Row>
        </InfiniteScroll>
        </Col>





        <RenameFile
          renameModalOpen={renameModalOpen}
          setRenameModalOpen={setRenameModalOpen}
          renameValue={renameValue}
          setRenameValue={setRenameValue}
          handleRename={handleRename}
        />


      </Row>
    </DndProvider>
  );
};

export default FileTree;
