import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Divider, Fab, IconButton, Typography } from '@mui/material';
import { Col, Popconfirm, Row } from 'antd';
import { collection, deleteDoc, doc, getDocs, getFirestore, query, setDoc, updateDoc, where } from 'firebase/firestore';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import "../../extra/hoverStyle.css";

export default class AlbumsNew extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            data: [],
            filteredData: [],
            filters: [],
            searchText: '',
            loading: true,
            type: 'albums'
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0)
        this.queryData()
    }

    queryData = async () => {
        const { userInfo, type } = this.state;
        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'albums',), where('account', '==', userInfo.currentAccount), where('archived', '==', false));
        const data = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            data.push(doc.data())


        });
        console.log(data);

        data.sort((a, b) => a.name.localeCompare(b.name))
        this.setState({ data, loading: false })

        this.filterData(data, '', [])
    }

    filterData = (data, searchText, filters) => {
        const filteredData = data.filter(d => d.name.match(new RegExp(searchText, "i")))
        this.setState({ filteredData })

    }

    render() {

        const { userInfo, account, data, filteredData, loading, searchText, filters, type } = this.state;
        const db = getFirestore();


        const CreateItemButton = () => {
            let navigate = useNavigate();

            return (
                <Fab style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="medium"
                    onClick={async () => {
                        const itemRef = doc(db, "users", userInfo.id, 'currentItems', 'currentAlbum');
                        await setDoc(itemRef, {
                            key: 'none',
                        }).then(() => {
                            navigate('/album')
                        })
                    }
                    }>
                    <AddIcon />
                </Fab >
            )
        }

        const EditItem = ({ item }) => {
            let navigate = useNavigate();
            return (
                <IconButton style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="small"
                    onClick={async () => {
                        const itemRef = doc(db, "users", userInfo.id, 'currentItems', 'currentAlbum');
                        await setDoc(itemRef, {
                            key: item.key
                        }).then(() => {
                            navigate('/album')
                        })
                    }}>
                    <EditIcon />
                </IconButton>
            )
        }

        const DeleteItem = ({ item }) => {
            let navigate = useNavigate();
            return (
                <Popconfirm
                    title="Are you sure to delete this album?"
                    onConfirm={async () => {
                        const itemRef = doc(db, "clients", userInfo.currentCompany, 'albums', item.key);
                        await updateDoc(itemRef, {
                            archived: true
                        }).then(async () => {

                            const queryData = query(collection(db, "clients", userInfo.currentCompany, 'photos'), where('albumKey', '==', item.key));

                            const snap = await getDocs(queryData);
                            snap.forEach(async (doc) => {
                                await deleteDoc(doc(db, "clients", userInfo.currentCompany, 'photos', doc.data().key))


                            });


                        }).then(() => {
                            this.props.openSnack('success', 'Album deleted.')
                            const newData = filteredData.filter((f) => f.key !== item.key)
                            this.setState({ filteredData: newData })
                        })

                    }}
                    okText="Yes"
                    cancelText="No"
                >
                    <IconButton style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="small"
                    >
                        <DeleteForeverIcon />
                    </IconButton>
                </Popconfirm>


            )
        }




        const RowItem = ({ item }) => {
            let navigate = useNavigate();
            return (
                <Col xs={12} sm={6} style={{ cursor: 'pointer', padding: 8 }} className="rowHover"
                >
                    <Row gutter={[8, 8]} align="middle"  >



                        <Col span={24} onClick={async () => {
                            console.log(item)
                            const itemRef = doc(db, "users", userInfo.id, 'currentItems', 'currentAlbum');
                            await setDoc(itemRef, {
                                key: item.key
                            }).then(() => {
                                navigate('/album')
                            }).catch((err) => {
                                console.log(err)
                            })
                        }} >
                            {item.thumbNail && (
                                <img src={item.thumbNail.fileLink || ''} alt="No Photos" style={{ width: '100%', borderRadius: 6 }} />
                            )}

                        </Col>
                        <Col span={20} onClick={async () => {
                            console.log(item)
                            const itemRef = doc(db, "users", userInfo.id, 'currentItems', 'currentAlbum');
                            await setDoc(itemRef, {
                                key: item.key
                            }).then(() => {
                                navigate('/album')
                            }).catch((err) => {
                                console.log(err)
                            })
                        }}>
                            <Typography variant='h6'>{item.name}</Typography>
                            <Typography variant='bod1'>{item.description}</Typography>

                        </Col>


                        <Col sm={4} style={{ textAlign: 'right' }}>
                        <EditItem item={item} /> <DeleteItem item={item} />
                        </Col>




                    </Row>
                </Col>
            )
        }

        return (
            <Row style={{}} align="middle">
                <Col span={23}>
                    <Row align="middle">

                        <Col xs={16} sm={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>
                            <Typography variant="h4">Your Photo Albums</Typography>
                        </Col>
                        <Col xs={6} sm={4} style={{ paddingTop: 30, paddingBottom: 15, textAlign: 'right' }}>
                            <CreateItemButton />
                        </Col>
                        <Col span={24}>
                            <Divider />
                        </Col>
                    </Row>
                </Col>





                <Col span={23} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 30 }}>
                    <Row gutter={[8, 8]} align="middle" justify="start" style={{ width: '100%' }}>
                        <Col>
                            <Button style={{ textTransform: 'none' }} variant={type === 'timeline' ? "contained" : 'outlined'} onClick={() => {
                                this.setState({ type: 'timeline' })
                            }} >Timeline</Button>
                        </Col>
                        <Col>
                            <Button style={{ textTransform: 'none' }}  variant={type === 'albums' ? "contained" : 'outlined'}onClick={() => {
                                this.setState({ type: 'albums' })
                            }}>Albums</Button>
                        </Col>
                    </Row>
                </Col>




            </Row>
        );
    }
} 