import { Breadcrumbs, Button, ButtonGroup, Divider, MenuItem, OutlinedInput, Typography, Select, TextField } from '@mui/material';
import { Col, Modal, Row, } from 'antd';
import React, { useEffect, useState, useMemo } from 'react';
import { fullMobile } from '../util';
import 'react-data-grid/lib/styles.css';
import uuid from 'react-uuid';
import { memo } from 'react';
import moment from 'moment-timezone';
import { queryEditAccountPerson, queryPermissionTemplates } from '../../../firebase/config';
import { Link } from 'react-router-dom';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Box } from '@mui/system';
import './table.css'
import { permissionsList, states } from './permissions'
import { getFirestore, getDoc, doc, setDoc, updateDoc, deleteDoc } from 'firebase/firestore';
//create style of row paddingTop and bottom 15
//create style of row paddingTop and bottom 15

const style = {
    row: {
        paddingTop: 15,
        paddingBottom: 15,
        paddingLeft: 15,
        paddingRight: 15,
        //align center in div
        display: 'flex',
        alignItems: 'center',

    }
}





export default function PermissionTemplates(props) {
    const { userInfo, screenWidth, openSnack, company, account, staffRef, usersRef } = props;
    const [person, setPerson] = useState({})
    const [permissionTemplates, setPermissionTemplates] = useState([])
    const [permission, setPermission] = useState(null)
    const [permissionModal, setPermissionModal] = useState(false)
    const [permissions, setPermissions] = useState(permissionsList)
    const [currentTemplate, setCurrentTemplate] = useState(null)

    useEffect(() => {
        window.scrollTo(0, 0)
        getInfo()
        console.log(permissions)
    }, [])
    const db = getFirestore()

    async function getInfo() {



        const permissionTemplates = await queryPermissionTemplates(userInfo)
        console.log(permissionTemplates)
        setPermissionTemplates(permissionTemplates)
    }






    async function handleUpdatePermission(val, index) {
        const newPermissions = [...permissions]
        newPermissions[index].level = val
        setPermissions(newPermissions)
    }

    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: (option) => option.label,
    });


    return (

        <div style={{ fontFamily: 'Roboto, sans-serif', backgroundColor: '#f6f8fe', minHeight: '100vh' }}>
            <Row style={{ padding: 20, paddingTop: 50 }} >
                <Col {...fullMobile(24, 24)}>


                    <Breadcrumbs aria-label="breadcrumb">

                        <Link to="/accountDirectory" style={{ color: '#404050', fontWeight: 500 }} >
                            Click to Return to Site Directory
                        </Link>

                    </Breadcrumbs>
                </Col>
                <Col {...fullMobile(24, 24)} style={{ paddingTop: 50, paddingBottom: 10 }}>
                    <Typography variant="h3" style={{ fontWeight: 300, color: '#404050' }}>
                        Edit Permission Templates
                    </Typography>

                </Col>
                <Col {...fullMobile(24, 24)}>
                    <Divider />
                </Col>



                <Col {...fullMobile(24, 24)} style={{ paddingTop: 15 }}>
                    <Row gutter={[12, 12]} align="middle" style={{ paddingBottom: 20, paddingTop: 10 }}>
                        <Col {...fullMobile(12, 12)}>
                            <Typography variant="h5" style={{ fontWeight: 500, color: '#404050' }}>
                                SITE PERMISSIONS TEMPLATE:
                            </Typography>
                        </Col>
                        <Col {...fullMobile(8, 8)}>
                            <Autocomplete
                                style={{ width: 300, backgroundColor: '#fff' }}
                                size="small"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                hiddenLabel="true"
                                disableClearable={true}
                                options={permissionTemplates}
                                value={currentTemplate || null}
                                filterOptions={(options, _ref) => [...filterOptions(options, _ref), { label: 'Add New', key: 'Add New', value: 'addNew' },]}
                                onChange={(a, b) => {

                                    setCurrentTemplate(b)
                                    setPermissions(b.permissions)
                                }}
                                noOptionsText={<Button fullWidth variant='contained' color='primary' style={{ textTransform: 'none' }}
                                    onClick={() => {
                                        setPermissionModal(true)
                                        setPermission({ key: uuid(), label: '' });
                                    }}
                                >+ Create Template</Button>}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Templates"
                                        variant="outlined"
                                    />
                                )}
                                renderOption={(params) => {

                                    if (params.key === 'Add New') {
                                        return (
                                            <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 4 }}>
                                                <Button fullWidth variant='contained' color='primary' style={{ textTransform: 'none' }} onClick={(e) => {
                                                    setPermissionModal(true)
                                                    setPermission({ key: uuid(), label: '' });
                                                }}>+ Create Template from Selected</Button>
                                            </Box>

                                        )
                                    }
                                    else {
                                        return (
                                            <Typography {...params}>{params.key}</Typography>
                                        )
                                    }
                                }}
                            />

                        </Col>
                        {currentTemplate !== null && (<Col {...fullMobile(4, 4)}>
                            <Button variant={'contained'} display="inline"

                                style={{
                                    textTransform: 'none', backgroundColor: '#404050', fontSize: 14, padding: 12
                                }}
                                onClick={async () => {
                                    const docRef = doc(db, "clients", userInfo.currentCompany, 'accounts', account.key, 'permissionTemplates', currentTemplate.key);
                                    await updateDoc(docRef, {
                                        label: currentTemplate.label,
                                        permissions: permissions
                                    }).then(() => {
                                        const index = permissionTemplates.findIndex((item) => item.key === currentTemplate.key)
                                        const newTemplates = [...permissionTemplates]
                                        newTemplates[index] = { ...currentTemplate, permissions: permissions }
                                        setPermissionTemplates(newTemplates)
                                        openSnack('success', 'Template Updated')
                                    }).catch((error) => {
                                        openSnack('error', 'Error Updating Template')
                                    })


                                }}
                            >
                                Update Template
                            </Button>
                        </Col>)}

                    </Row>
                </Col>
                {currentTemplate !== null && (<Col {...fullMobile(24, 24)} style={{ paddingTop: 15 }}>
                    <Typography variant="h5" fontSize={14} style={{ fontWeight: 500, color: '#404050' }}>
                        Template Name:
                    </Typography>
                    <OutlinedInput
                        style={{ width: 400, backgroundColor: '#fff' }}
                        size="small"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        hiddenLabel="true"
                        disableClearable={true}
                        value={currentTemplate?.label}
                        onChange={(e) => {
                            setCurrentTemplate({ ...currentTemplate, label: e.target.value })
                        }}
                        placeholder="Template Name"

                    />

                </Col>)}


                <Col {...fullMobile(24, 24)} style={{ paddingTop: 10 }}>

                    <Row style={{ backgroundColor: 'white', }} align="middle" >
                        <table
                            style={{
                                width: '100%',
                                //center all text inside cells
                                textAlign: 'center',
                                //center all text inside cells

                            }}
                            id="directory_permissions_table"
                            data-directory-admin-warning="Permission cannot change while user is Directory Admin"
                            data-provider="project"
                            data-start-directory-admin="true"
                        >
                            <thead>
                                <tr style={{
                                    backgroundColor: '#f8f8f8',
                                    //row height 20
                                    height: '40px',
                                }} >
                                    <th className='permissionth' width="20%">Name</th>
                                    <th className='permissionth' width="20%" >None</th>
                                    <th className='permissionth' width="20%" >Read Only</th>
                                    <th className='permissionth' width="20%" >Standard</th>
                                    <th className='permissionth' width="20%"  >Admin</th>
                                </tr>
                            </thead>
                            <tbody>

                                {permissions?.length > 0 && permissions.map((permission, index) => {
                                    const levels = ['1', '2', '3', '4'];
                                    return (
                                        <tr data-domain-name={permission.label} className='permissiontr'>
                                            <td className='permissiontd'>
                                                <Typography variant="subitle1" style={{ fontWeight: 400, color: '#404050' }}>
                                                    {permission.label}
                                                </Typography>
                                            </td>

                                            {levels.map((level) => {
                                                return (
                                                    <td className='permissiontd'><input type="radio"
                                                        value={level}
                                                        //disabled if permission.level is less than 4
                                                        checked={permission.level === level}
                                                        onChange={(e) => {
                                                            //change permission level
                                                            handleUpdatePermission(e.target.value, index)

                                                        }}
                                                    /></td>
                                                )
                                            })
                                            }
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                        </table>

                    </Row>
                </Col>

                {currentTemplate !== null && (<Col {...fullMobile(24, 24)} style={{ paddingTop: 30, textAlign: 'right' }}>
                    <Button variant={'contained'} display="inline"

                        style={{
                            textTransform: 'none', backgroundColor: '#404050', fontSize: 14, padding: 12
                        }}
                        onClick={async () => {
                            const docRef = doc(db, "clients", userInfo.currentCompany, 'accounts', account.key, 'permissionTemplates', currentTemplate.key);
                            await deleteDoc(docRef).then(() => {
                                //remove from list
                                const index = permissionTemplates.findIndex((item) => item.key === currentTemplate.key)
                                const newTemplates = [...permissionTemplates]
                                newTemplates.splice(index, 1)
                                setPermissionTemplates(newTemplates)
                                setPermissions(permissionsList)
                                setCurrentTemplate(null)
                                openSnack('success', 'Template Deleted')
                            }).catch((error) => {
                                openSnack('error', 'Error Deleting Template')
                            })


                        }}
                    >
                        Delete Template
                    </Button>
                </Col>)}











            </Row >

            <Modal title="" visible={permissionModal} onCancel={() => {
                setPermissionModal(false)
                setPermission(null);

            }} footer={null} width={'60%'} style={{ borderRadius: 40 }} >
                <Row style={{ padding: 20 }}>
                    <Col {...fullMobile(24, 24)} style={{}}>
                        <Typography variant="h5" style={{ fontWeight: 500, color: '#404050' }}>
                            Create New Template
                        </Typography>
                        <Typography variant="subtitle1" style={{ fontWeight: 300, color: '#404050', paddingTop: 5 }}>
                            Template will be based off selected permissions
                        </Typography>
                    </Col>
                    <Col {...fullMobile(24, 24)} style={{ paddingTop: 20 }}>
                        <Typography variant="body1" style={{ fontWeight: 500, color: '#404050' }}>
                            Template Name
                        </Typography>
                        <OutlinedInput
                            size="small"
                            style={{ width: 300 }}
                            id="outlined-adornment-amount"
                            value={permission?.label}
                            onChange={(e) => {
                                setPermission({ ...permission, label: e.target.value })
                            }}
                        />
                    </Col>
                    <Col {...fullMobile(24, 24)} style={{ paddingTop: 20 }}>
                        <Button variant='contained'
                            color='primary'
                            style={{ textTransform: 'none', backgroundColor: '#404050' }}
                            onClick={async () => {
                                const permissionTemplate = {
                                    ...permission,
                                    permissions: permissions,
                                }
                                console.log(permissionTemplate)

                                if (permissionTemplate.label !== '') {

                                    const docRef = doc(db, "clients", userInfo.currentCompany, 'accounts', account.key, 'permissionTemplates', permissionTemplate.key);
                                    await setDoc(docRef, permissionTemplate).then(() => {
                                        setPermissionModal(false)
                                        setCurrentTemplate(permissionTemplate);
                                        //add to permissionTemplates
                                        const newTemplates = [...permissionTemplates, permissionTemplate];
                                        setPermissionTemplates(newTemplates);
                                    }).catch((error) => {
                                        console.error("Error adding document: ", error);
                                    })
                                }
                                else {
                                    openSnack('error', 'Please enter a name for the template')
                                }
                            }}
                        >Save Template</Button>

                    </Col>


                </Row>

            </Modal >
        </div >

    );
}