import { CircularProgress, Typography, Checkbox, OutlinedInput, MenuItem, Select, Divider } from '@mui/material';
import { Col, Row } from 'antd';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { getFirestore } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import React, { useEffect, useRef, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { Image, ImageButton } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './styleText.css'; // import the CSS file for custom styles
import { fullMobile } from './util';
import DataColorCompact from './dataColorCompact';

export default function TitleSettings(props) {
    const {
        attributes,
        updateWidget,
    } = props;






    return (
        <Row align="middle" style={{ paddingTop: 5 }} gutter={[4, 4]}>


            <Col span={24}>
                <Divider />
            </Col>


            <Col {...fullMobile(6, 12)} style={{ textAlign: 'center' }}>
                <Row>
                    <Col span={24} style={{ textAlign: 'left' }}>
                        <Typography fontSize={13} fontWeight={600} variant="body1">Title Font Size</Typography>
                    </Col>
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                            defaultValue={attributes.titleFontSize || 24}
                            type="number"
                            inputProps={{ min: 0 }}
                            onChange={(e) => {
                                updateWidget('titleFontSize', e.target.value === "" ? "" : Number(e.target.value))
                            }} />
                    </Col>
                </Row>
            </Col>
            <Col {...fullMobile(6, 12)} style={{ textAlign: 'center' }}>
                <Row>
                    <Col span={24} style={{ textAlign: 'left' }}>
                        <Typography fontSize={13} fontWeight={600} variant="body1">Title Font Weight</Typography>
                    </Col>
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <Select size="small" fullWidth
                            notched={false}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            defaultValue={attributes.titleFontWeight || 300}
                            label="Layer"
                            onChange={(e) => {
                                updateWidget('titleFontWeight', e.target.value)
                            }}

                        >
                            <MenuItem value={400}>400</MenuItem>
                            <MenuItem value={500}>500</MenuItem>
                            <MenuItem value={600}>600</MenuItem>
                            <MenuItem value={700}>700</MenuItem>
                            <MenuItem value={800}>800</MenuItem>
                        </Select>
                    </Col>
                </Row>

            </Col>
            <Col {...fullMobile(6, 12)} style={{ textAlign: 'center' }}>
                <Row>
                    <Col span={24} style={{ textAlign: 'left' }}>
                        <Typography fontSize={13} fontWeight={600} variant="body1">Title Font Color</Typography>
                    </Col>
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <DataColorCompact color={attributes.titleFontColor || '#000000'}
                            updateData={(boo, val) =>
                                updateWidget('titleFontColor', val)}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );

}
