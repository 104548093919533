


import {
    Button, Checkbox, Divider, OutlinedInput, Typography, Select, MenuItem, CircularProgress, Dialog, DialogTitle, Grid,
    DialogActions, Tooltip, DialogContent, Box, Input
} from '@mui/material';
import { Col, Row } from 'antd';
import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { fullMobile } from '../../../publicPortal/util'; // Assuming this is where your util functions are
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import uuid from 'react-uuid';
import { createData, updateData } from './firebaseFunctions';
import { testTokenConnection } from '../drawerComponents/componentFunctions';

const KeyDialog = ({ currentKey, setCurrentKey, keyDialog, setKeyDialog, keys, setKeys, openSnack, userInfo, route }) => {
    const [loading, setLoading] = useState(true);



    useEffect(() => {
        // Your existing useEffect code. You might initialize the 'currentKey' state here if needed.
    }, []);

    function handleClose() {
        setKeyDialog(false);
        setCurrentKey(null);
    }

    const onSubmit = (keyInfo) => {
        console.log(keyInfo)
        /* check if valid */
        if (!isValid(keyInfo)) {
            openSnack('error', 'Please fill in all required fields');
            return;
        }
        const isUpdatingKey = keyInfo.id !== undefined;
        console.log(isUpdatingKey)
        if (isUpdatingKey) {
            updateKey(keyInfo);
            //remove locations from localStorage
            localStorage.removeItem('locations');
        }
        else {
            createKey(keyInfo, openSnack);
            //remove locations from localStorage
            localStorage.removeItem('locations');
        }
    };

    async function updateKey(keyInfo) {
        try {
            /* check if name exists other that a key with the same id */
            const nameExists = keys.find(k => k.keyName === keyInfo.keyName);
            console.log(nameExists)
            console.log(keyInfo)
            if (nameExists && nameExists.id !== keyInfo.id) {
                openSnack('error', 'Key name already exists');
                return;
            }
            const updatedKeys = keys.map(k => k.id === keyInfo.id ? keyInfo : k);
            setKeys(updatedKeys);
            setKeyDialog(false);
            setCurrentKey(null);
            const res = await testTokenConnection({ api_key_client_id: keyInfo.client_id, api_key_client_secret: keyInfo.client_secret }, openSnack);
            console.log(res)
            keyInfo.connection = res;
            await updateData(userInfo, route, keyInfo);
            openSnack('success', 'Key updated successfully');
        }
        catch (error) {
            console.log('Error updating key:', error);
        }

    }

    async function createKey(keyInfo, openSnack) {
        try {
            const nameExists = keys.find(k => k.keyName === keyInfo.keyName);
            console.log('creating key?')
            if (nameExists) {
                openSnack('error', 'Key name already exists'); 
                return;
            }
            console.log(keyInfo)
            keyInfo.id = uuid();
            keyInfo.company = userInfo.currentCompany;
            keyInfo.creator = userInfo.email;
            keyInfo.createData = new Date();
            keyInfo.archived = false;
            setKeys([{ ...keyInfo }, ...keys]);
            setKeyDialog(false);
            setCurrentKey(null);
            const res = await testTokenConnection({ api_key_client_id: keyInfo.client_id, api_key_client_secret: keyInfo.client_secret }, openSnack);
            console.log(res)
            keyInfo.connection = res;
            await createData(userInfo, route, keyInfo);
            openSnack('success', 'Key created successfully');
        }
        catch (error) {
            console.log(error);
        }
    }



    const isValid = (keyInfo) => {
        return (
            keyInfo?.keyName && keyInfo?.keyName.length > 0 &&
            keyInfo?.type && keyInfo?.type.length > 0 &&
            keyInfo?.client_id && keyInfo?.client_id.length > 0 &&
            keyInfo?.client_secret && keyInfo?.client_secret.length > 0
        );
    }




    return (
        <Dialog open={keyDialog} onClose={handleClose}  >
            <DialogTitle>Key Credentials</DialogTitle>
            <DialogContent style={{ width: 400 }}>

                <Box>
                    <Grid container spacing={2} style={{}}>
                        <Grid item xs={12} sm={12}>
                            <Typography variant='body1' fontWeight={600} color={'rgba(0,0,0,0.9)'} fontSize={13}>
                                Key Name <span style={{ color: 'red' }}>*</span>
                            </Typography>
                            <Input

                                autoComplete="off"
                                fullWidth
                                disabled={currentKey?.deletable === false}
                                variant="standard"
                                id="string-input"
                                size="small"
                                margin="dense"
                                placeholder="Enter key name"
                                value={currentKey?.keyName || ''} // To prefill the form
                                onChange={(e) => {
                                    setCurrentKey({ ...currentKey, keyName: e.target.value })
                                }}
                                sx={{ /* Your styling */ }}
                            />

                        </Grid>

                        {/* ... Other input fields similarly modified */}

                        <Grid item xs={12} sm={12}>
                            <Typography variant='body1' fontWeight={600} color={'rgba(0,0,0,0.9)'} fontSize={13}>
                                Type <span style={{ color: 'red' }}>*</span>
                            </Typography>
                            <Select

                                fullWidth
                                variant="outlined"
                                id="string-input"
                                disabled={currentKey?.deletable === false}
                                size="small"
                                margin="dense"
                                value={currentKey?.type || ''} // Ensure this defaults to '' for the "Select type" placeholder to show initially
                                onChange={(e) => {
                                    setCurrentKey({ ...currentKey, type: e.target.value });
                                }}
                                displayEmpty
                                renderValue={(selected) => {
                                    if (selected === '') {
                                        return <em>Select type</em>; // Placeholder text when nothing is selected
                                    }
                                    // Display the selected item's text
                                    const selectedItem = [
                                        <MenuItem value={'insitu'}>In-Situ</MenuItem>,
                                        <MenuItem value={'nebraskaTelemetrics'}>Nebraska Telemetrics</MenuItem>].find(item => item.props.value === selected);
                                    return selectedItem ? selectedItem.props.children : '';
                                }}
                                sx={{ /* Your styling */ }}
                            >
                                {/* Do not include the placeholder as an actual MenuItem here */}
                                <MenuItem value={'insitu'}>In-Situ</MenuItem>
                                {/*   <MenuItem value={'nebraskaTelemetrics'}>Nebraska Telemetrics</MenuItem> */}
                            </Select>


                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} style={{ paddingTop: 20 }}>
                        <Typography variant='body1' fontWeight={600} color={'rgba(0,0,0,0.9)'} fontSize={13}>
                            Client ID <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <Input

                            autoComplete="off"
                            fullWidth
                            /* disable autocomplete */
                            variant="standard"
                            id="string-input"
                            disabled={currentKey?.deletable === false}
                            size="small"
                            margin="dense"
                            placeholder="Enter user name"
                            value={currentKey?.client_id || ''} // To prefill the form
                            onChange={(e) => {
                                setCurrentKey({ ...currentKey, client_id: e.target.value })
                            }}
                            sx={{ /* Your styling */ }}
                        />

                    </Grid>
                    <Grid item xs={12} sm={12} style={{ paddingTop: 10 }}>
                        <Typography variant='body1' fontWeight={600} color={'rgba(0,0,0,0.9)'} fontSize={13}>
                            Client secret <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <Input

                            autoComplete="off"
                            fullWidth
                            variant="standard"
                            id="string-input"
                            type={currentKey?.id ? 'password' : 'text'}
                            disabled={currentKey?.deletable === false}
                            size="small"
                            margin="dense"
                            placeholder="Enter key name"
                            value={currentKey?.client_secret || ''} // To prefill the form
                            onChange={(e) => {
                                setCurrentKey({ ...currentKey, client_secret: e.target.value })
                            }}
                            sx={{ /* Your styling */ }}
                        />

                    </Grid>
                </Box>
                <DialogActions>
                    <Button style={{ fontWeight: 600, textTransform: 'none', fontSize: 14, color: 'red' }} onClick={handleClose}>Cancel</Button>
                    <Button


                        style={{
                            border: isValid(currentKey) ? '1px solid #3f51b5' : '1px solid rgba(0,0,0,0.7)',
                            color: isValid(currentKey) ? '#3f51b5' : 'rgba(0,0,0,0.4)',
                            fontWeight: 600, textTransform: 'none', fontSize: 14
                        }}
                        onClick={() => onSubmit(currentKey)}



                    >
                        {currentKey?.id ? 'Update key' : 'Create key'}
                    </Button>
                </DialogActions>

            </DialogContent>
        </Dialog>
    );
}

export default KeyDialog; 
