import { collection, getDocs, getDoc, addDoc, updateDoc, doc, getFirestore, deleteDoc, writeBatch, setDoc, where, query, } from 'firebase/firestore';
import { getStorage, ref, getMetadata, uploadBytes, getDownloadURL } from "firebase/storage";
import moment from 'moment';
import uuid from 'react-uuid';



export const getAlerts = async (company, account) => {
    const db = getFirestore();
    try {
        const alertRef = collection(db, "clients", company, 'historicalAlerts');
        const alertQuery = query(alertRef, where("account", "==", account));
        const alertSnapshot = await getDocs(alertQuery);
        const alerts = alertSnapshot.docs.map((doc) => ({
            
            ...doc.data(),
            id: doc.id,
        }));
        //sort by create date which is in format of MM/DD/YYYY HH:mm:ss
        alerts.sort((a, b) => {
            return moment(b.alertDate, 'MM/DD/YYYY HH:mm:ss').diff(moment(a.alertDate, 'MM/DD/YYYY HH:mm:ss'))
        })
        return alerts;
    } catch (error) {
        console.error("Error fetching alerts:", error);
        return [];
    }
};
export const getNodes = async (company, account) => {
    const db = getFirestore();
    try {
        const queryStations = query(collection(db, "clients", company, 'stations'), where('account', '==', account),);
        const snapNodes = await getDocs(queryStations);
        const nodes = [];
        snapNodes.forEach((doc) => {

            if (doc.data().archived !== true && doc.data().children.length > 0) {
                const children = doc.data().children;
                //for each child create a node and add to locations
                children.forEach((child) => {

                    nodes.push({
                        ...child,
                        lat: doc.data().lat,
                        lng: doc.data().lng, 
                        stationLabel: doc.data().name, label: child.name, type: 'node', stationType: child.type
                    })
                })

            }

        });
        console.log('nodes', nodes)
        return nodes;
    } catch (error) {
        console.log(error);
    }
}

// firestore.js
export const deleteAlerts = async (company, account, alerts) => {
    console.log(alerts)
    const db = getFirestore();
    try {
        const batch = writeBatch(db);

        alerts.forEach(async (alertId) => {
            console.log(alertId)
            const alertRef = doc(db, 'clients', company, 'historicalAlerts', alertId);
            batch.delete(alertRef);
        });

        await batch.commit();
    } catch (error) {
        console.error("Error deleting alerts: ", error);
    }
};



