import {
    Autocomplete,
    Divider,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import AlertTableRow from "./AlertTableRow";


const AlertsTable = ({ alerts, updateAlert, deleteAlert, duplicateAlert, parameters, staff }) => {





    const rowPadding = 4;

    return (

        alerts.map((alert, index) => (
            <div>
                <AlertTableRow key={alert.id} index={index} alert={alert} updateAlert={updateAlert} deleteAlert={deleteAlert}
                    duplicateAlert={duplicateAlert} parameters={parameters} staff={staff} />

                <Divider
                    //increase thickness to 2px
                    style={{ height: 1, backgroundColor: '#40405057' }}
                />

            </div>

        ))

    );
};

export default AlertsTable;
