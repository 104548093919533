import { Button, Checkbox, Divider, OutlinedInput, Typography, Select, MenuItem, CircularProgress, Breadcrumbs, FormGroup, FormControlLabel, Autocomplete, Chip, TextField, IconButton, Fab } from '@mui/material';
import { Col, Popconfirm, Row } from 'antd';
import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { fullMobile } from './util';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, } from 'firebase/firestore';

import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import uuid from 'react-uuid';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';

import DatePicker from "react-datepicker";
import queryString from 'query-string';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { queryPSItems } from '../../firebase/config';
import { DeleteForever } from '@mui/icons-material';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import './tableStyles.css'
import { faTablets, faToolbox } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
var randomColor = require('randomcolor');


const getMuiTheme = () =>
    createTheme({
        components: {
            MUIDataTable: {
                styleOverrides: {
                    root: {
                        padding: '8px',
                        boxShadow: 'none'
                    }
                }
            },
            MuiTableRow: {
                styleOverrides: {
                    height: '80px',
                    //onhover change background to red


                    root: {
                        height: '80px',
                        zIndex: 100,
                        cursor: 'pointer',
                        fontSize: '10px',
                        '&:nth-child(even)': {
                            backgroundColor: '#eaeaeade',

                        },




                    },
                    rowHeight: '40px'



                }
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        fontSize: '12px',
                        padding: '2px',

                    }
                }
            }

        }
    });


export default function PSHome(props) {
    const { userInfo, screenWidth, openSnack, company, account, } = props;
    const [data, setData] = useState([]);
    const [editingRow, setEditingRow] = useState(null);

    useEffect(() => {
        getInfo();
        window.scrollTo(0, 0);
    }, [])

    async function getInfo() {
        const data = await queryPSItems(userInfo);

        setData(data);

    }

    const db = getFirestore();
    let navigate = useNavigate();
    const columns = [
        {
            name: 'key',
            label: 'Name',
            options: {
                filter: false,

                customBodyRender: (a, tableMeta, updateValue) => {
                    const item = data.find(item => item.key === a);
                    return (
                        <div style={{ padding: 5 }}
                            onMouseEnter={() => {

                                setEditingRow(item.key);
                            }
                            }
                            onMouseLeave={() => {
                                setEditingRow(null);
                            }
                            }

                        >



                            <Typography display="inline" variant='h5' style={{ cursor: 'pointer', padding: 5, marginLeft: 10 }}
                                fontWeight={editingRow === item.key ? 600 : 400} fontSize={18}
                                onClick={async () => {

                                    const key = item.key;
                                    const itemRef = doc(db, "users", userInfo.id, 'currentItems', 'currentPS');
                                    await setDoc(itemRef, {
                                        key: key
                                    }).then(() => {
                                        const type = data.find(item => item.key === key).psType;
                                        navigate('/psItem')
                                    })
                                }}
                            > {item.name}</Typography>
                        </div>
                    )
                }


            }
        },
        {
            name: 'psType',
            label: 'Type',
            options: {
                filterType: 'checkbox',
                filter: true,
                customBodyRender: (a, tableMeta, updateValue) => {

                    const item = data.find(item => item.key === data[tableMeta.rowIndex].key);
                    console.log(item)


                    const uppercase = item.psType.charAt(0).toUpperCase() + item.psType.slice(1);
                    //remove last letter from upercase
                    const type = uppercase === 'Inspection' ? uppercase : uppercase.slice(0, -1);

                    return (
                        <div onMouseEnter={() => {

                            setEditingRow(item.key);
                        }
                        }
                            onMouseLeave={() => {
                                setEditingRow(null);
                            }
                            }>
                            <Chip label={type} display="inline" color={type === 'Service' ? 'primary' : 'success'} variant="outlined"
                                style={{ cursor: 'pointer', padding: 5, marginLeft: 10 }} fontWeight={editingRow === item.key ? 600 : 400} fontSize={18}
                                onClick={async () => {
                                    const key = item.key;
                                    const itemRef = doc(db, "users", userInfo.id, 'currentItems', 'currentPS');
                                    await setDoc(itemRef, {
                                        key: key
                                    }).then(() => {
                                        const type = data.find(item => item.key === key).psType;
                                        navigate('/psItem')
                                    })
                                }
                                }
                            />
                          
                        </div>
                    )
                }


            }
        },


        {
            name: 'key',
            label: 'Edit',
            options: {
                filter: false,
                viewColumns: false,
                customBodyRender: (a, tableMeta, updateValue) => {
                    return (
                        <IconButton style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="small" itemKey={a}
                            onClick={async () => {
                                const itemRef = doc(db, "users", userInfo.id, 'currentItems', 'currentPS');
                                await setDoc(itemRef, {
                                    key: a
                                }).then(() => {
                                    const type = data.find(item => item.key === a).psType;
                                    navigate('/psItem')
                                })
                            }}>
                            <EditIcon />
                        </IconButton>
                    )
                }
            },
        },

    ]


    const CreateItemButton = () => {
        let navigate = useNavigate();

        return (
            <Fab style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="medium"
                onClick={async () => {
                    const itemRef = doc(db, "users", userInfo.id, 'currentItems', 'currentPS');
                    await setDoc(itemRef, {
                        key: 'none'
                    }).then(() => {
                        navigate('/psItem')
                    })
                }}>
                <AddIcon />
            </Fab>
        )
    }

    return (
        <Row gutter={[12, 12]} style={{ padding: screenWidth === 'xs' ? 15 : 30 }}>
            <Col {...fullMobile(20, 20)} style={{ paddingTop: 10 }}>
                <Typography variant='h2' fontWeight={500} fontSize={28}>Products and Services</Typography>


            </Col>
            <Col {...fullMobile(4, 4)} style={{ paddingTop: 10, textAlign: 'right' }}>
                <CreateItemButton />
            </Col>
            <Col {...fullMobile(24, 24)} style={{ paddingTop: 10 }}>
                <Divider />
            </Col>
            <Col {...fullMobile(24, 24)} style={{ paddingTop: 10 }}>
                <ThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        title={''}
                        data={data}
                        columns={columns}


                        options={{
                            rowsPerPage: 50,
                            selectableRows: false,
                            rowsPerPageOptions: [50, 100, 200, 500, 1000],
                            async onRowClick(a, b) {
                                const db = getFirestore();

                                const itemRef = doc(db, "users", userInfo.id, 'currentItems', 'currentPS');
                                await setDoc(itemRef, {
                                    key: a[a.length - 1].props.itemKey
                                }).then(() => {

                                    navigate('/psItem')
                                })




                            }
                        }}

                    />
                </ThemeProvider>
            </Col>


        </Row>
    )



}