import { Button, Checkbox, Divider, OutlinedInput, Typography, Select, MenuItem, CircularProgress, IconButton, Popover, Tooltip } from '@mui/material';
import { Col, Row } from 'antd';
import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { fullMobile } from '../../../publicPortal/util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faPenToSquare, faPlus, faTrashAlt, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import './keyRow.css';



const KeyRow = ({ userInfo, screenWidth, openSnack, company, account, currentKey, setCurrentKey, keyDialog, setKeyDialog, keyData, deleteKeys, index }) => {

    // Dynamically set the background color for odd-indexed rows
    const rowStyle = {
        backgroundColor: index % 2 === 0 ? 'transparent' : '#f7f7f7', // Light gray for odd rows
        fontFamily: 'Roboto, sans-serif',
    };


    useEffect(() => {



    }, []);









    const [deletePopoverAnchorEl, setDeletePopoverAnchorEl] = useState(null);
    const [keyToDelete, setKeyToDelete] = useState(null);

    const openDeletePopover = (event, keyData) => {
        setDeletePopoverAnchorEl(event.currentTarget);
        setKeyToDelete(keyData);
    };

    const closeDeletePopover = () => {
        setDeletePopoverAnchorEl(null);
        setKeyToDelete(null);
    };

    const confirmDelete = () => {
        deleteKeys([keyToDelete]);
        closeDeletePopover();

    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: screenWidth === 'xs' ? '90vw' : '60vw',
        bgcolor: 'background.paper',
        border: '1px solid #000',
        borderRadius: '10px',
        boxShadow: 24,
        maxHeight: '90vh',
        p: 0,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
    };


    return (
        <Row className="key-row-hover" style={{ ...rowStyle, cursor: 'pointer' }} align="middle"

            onClick={(e) => {
                console.log(keyData)
                e.stopPropagation();
                e.preventDefault();
                setCurrentKey(keyData);
                setKeyDialog(true);
            }}
        >


            <Col {...fullMobile(6, 6)} style={{ paddingLeft: 12 }}>
                <Typography variant='body1' fontWeight={600} color={'rgba(0,0,0,0.8)'} fontSize={13}>
                    {keyData.keyName}
                </Typography>

            </Col>
            <Col {...fullMobile(4, 4)} style={{}}>
                <Typography variant='body1' fontWeight={600} color={'rgba(0,0,0,0.8)'} fontSize={13}>
                    {keyData.type === 'insitu' ? 'In-Situ' : keyData.type === 'nebraskaTelemetrics' ? 'Nebraska Telemetrics' : 'Unknown'}
                </Typography>
            </Col>
            <Col {...fullMobile(4, 4)} >
                <FontAwesomeIcon size="lg" style={{ marginLeft: 12 }} icon={keyData?.connection === 'error' ? faXmarkCircle : faCircleCheck} color={keyData?.connection === 'error' ? 'red' : 'green'} />
            </Col>
            <Col {...fullMobile(4, 4)} style={{}}>
                <Typography variant='body1' fontWeight={600} color={'rgba(0,0,0,0.8)'} fontSize={13}>
                    Credentials
                </Typography>
            </Col>


            <Col {...fullMobile(4, 4)} style={{ textAlign: 'center' }}>
                <Tooltip title="Edit Key" placement="top">
                    <IconButton


                        onClick={() => {
                            console.log(keyData)
                            setCurrentKey(keyData);
                            setKeyDialog(true);
                        }}
                    >
                        <FontAwesomeIcon icon={faPenToSquare} color="#3f51b5" />

                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete Key" placement="top">
                    <IconButton
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation(); // prevents the ListItem's click event from firing
                            if (keyData?.deletable === false) {
                                openSnack('error', 'This key cannot be deleted',);
                                return;
                            }

                            openDeletePopover(e, keyData);
                        }}
                        size="small"
                    >
                        <FontAwesomeIcon icon={faTrashAlt} color="#f58383" />
                    </IconButton>
                </Tooltip>

                <Popover
                    open={deletePopoverAnchorEl}
                    anchorEl={deletePopoverAnchorEl}
                    onClose={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        closeDeletePopover();

                    }}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <div style={{ padding: '16px', maxWidth: '300px' }}>
                        <Typography variant="h6" fontSize={15}
                            fontWeight={700}

                        >Are you sure you want to delete this api key?</Typography>
                        <Typography variant="body1" fontSize={13} fontWeight={400} style={{ marginTop: '8px', marginBottom: 10 }}>
                            Deleting {`${keyData?.keyName}`}  will remove it from all active data streams. This can not be undone.
                        </Typography>

                        <Button onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            confirmDelete();
                        }}
                            variant="contained"
                            color="error"
                            style={{
                                marginRight: 10,
                                textTransform: 'none',
                            }}
                        >Confirm delete</Button>
                        <Button onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            closeDeletePopover();
                        }

                        } style={{
                            border: '1px solid #3f51b5',
                            color: '#3f51b5',
                            textTransform: 'none'
                        }} variant="outlined">Cancel</Button>
                    </div>
                </Popover>

            </Col>
            <Col {...fullMobile(24, 24)} style={{ paddingTop: 5 }} >
                <Divider />
            </Col>



        </Row>
    );
}

export default KeyRow;