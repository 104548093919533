import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faPlus, faX } from '@fortawesome/free-solid-svg-icons';
import { IconButton, List, Tooltip, ListItem, OutlinedInput, InputAdornment, ListItemIcon } from '@mui/material';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import uuid from 'react-uuid';

export default function TaskCreateList({
    fullTasks,
    createTask,
    setCurrentTask,
    setTaskDrawer,
    taskTemplates,
    deleteTaskTemplate
}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [templateSearchTerm, setTemplateSearchTerm] = React.useState('')

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;



    return (
        <div style={{
            display: 'inline',
            marginLeft: 10,
            marginRight: 10,
        }}>
            <Button
                style={{
                    textTransform: 'none',
                    fontSize: 14,
                    color: 'rgba(0,0,0,0.9)',
                    fontWeight: 500,
                    padding: 8,
                    border: '1px solid rgba(0,0,0,0.2)',
                }}
                onClick={handleClick}
            >
                <FontAwesomeIcon icon={faPlus} style={{ color: 'rgba(0,0,0,0.5)', marginRight: 5 }} />
                Add new
                <CaretDownOutlined style={{
                    marginLeft: 15
                }} />
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}

                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <List sx={{
                    width: 300,
                    padding: 0,

                }}>
                    <ListItem
                        key={'createTemplate'}
                        dense
                        button
                        onClick={() => {
                            setCurrentTask(null)
                            setTaskDrawer('create')
                            setAnchorEl(null)
                        }}
                        sx={{
                            borderBottom: '1px solid #e0e0e0',
                            paddingTop: 2,
                            paddingBottom: 2,
                        }}
                    >
                        <Typography variant="body1" color="black" fontSize={16} fontWeight={500}> + Add Task</Typography>
                    </ListItem>
                    <ListItem
                        key={'createTemplate'}
                        dense

                        sx={{
                            borderBottom: '1px solid #e0e0e0',
                            paddingTop: 1,
                            paddingBottom: 1,
                        }}
                    >
                        <Typography variant="body1" fontSize={14} fontWeight={800}>Task Templates</Typography>
                    </ListItem>
                    <ListItem
                        key={'createTemplate'}
                        dense
                        sx={{
                            borderBottom: '1px solid #e0e0e0',
                            paddingTop: 1,
                            paddingBottom: 1,
                            display: 'flex',           // Make this a flex container
                            alignItems: 'center',      // Center items vertically
                            justifyContent: 'center',  // Center items horizontally
                        }}
                    >
                        <OutlinedInput
                            size="small"
                            style={{
                                borderRadius: 16,
                                width: '90%',
                            }}
                            value={templateSearchTerm}
                            placeholder='Search'
                            startAdornment={
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            }
                            endAdornment={
                                <InputAdornment position="end">
                                    {templateSearchTerm !== '' ? (
                                        <IconButton
                                            aria-label="clear search term"
                                            edge="end"
                                            onClick={() => setTemplateSearchTerm('')}
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    ) : (
                                        <IconButton style={{ visibility: 'hidden' }}>
                                            <ClearIcon />
                                        </IconButton>
                                    )}
                                </InputAdornment>
                            }
                            onChange={(e) => setTemplateSearchTerm(e.target.value)}
                        />
                    </ListItem>
                    {taskTemplates?.map((task, index) => {
                        if (task.title.toLowerCase().includes(templateSearchTerm.toLowerCase())) {
                            return (
                                <ListItem
                                    key={index}
                                    dense
                                    button
                                    onClick={() => {
                                        const newTask = { ...task }
                                        newTask.title = `${task.title} (from template)`
                                        newTask.id = uuid()
                                        createTask(newTask)
                                    }}
                                    sx={{
                                        borderBottom: '1px solid #e0e0e0',
                                        paddingTop: 1,
                                        paddingBottom: 1,
                                        display: 'flex',
                                        justifyContent: 'space-between', // Adjusted for proper alignment
                                    }}
                                >
                                    <Typography variant="body1" color="black" fontSize={14} fontWeight={600}>{task.title}</Typography>
                                    <IconButton
                                        onClick={(e) => {
                                            e.stopPropagation(); // Prevents the ListItem's click event from firing
                                            deleteTaskTemplate(task); // Assuming deleteTaskTemplate is a function you have that requires the task's id
                                        }}
                                        size="small"
                                    >
                                        <FontAwesomeIcon icon={faX} size="xs" />
                                    </IconButton>
                                </ListItem>
                            )
                        }
                    })}




                </List>




            </Popover>
        </div>
    );
}