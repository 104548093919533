import { collection, getDocs, getDoc, addDoc, updateDoc, doc, getFirestore, deleteDoc, writeBatch, setDoc, where, query, arrayRemove, arrayUnion } from 'firebase/firestore';
import { getStorage, ref, getMetadata } from "firebase/storage";
import uuid from 'react-uuid';

import axios, * as others from 'axios';
import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth';




export const getInvitation = async (id) => {
    const db = getFirestore();
    try {
        const invitation = await getDoc(doc(db, "users", id));
        if (invitation.exists()) {
            return invitation.data();
        } else {
            return null;
        }
    }
    catch (error) {
        console.log(error)
        return null;
    }

};

export const signupUser = async (invitation, navigate) => {
    console.log(invitation);

    const auth = getAuth();
    const db = getFirestore();
    try {
        createUserWithEmailAndPassword(auth, invitation.email, invitation.password)
            .then(async (userCredential) => {
                const user = userCredential.user;
                await deleteDoc(doc(db, "users", invitation.id))
                    .then(async () => {
                        const companies = invitation.companies;
                        const accounts = invitation.accounts;

                        delete invitation.password;
                        delete invitation.confirmPassword;

                        companies.forEach(async (company) => {
                            await deleteDoc(doc(db, "clients", company, 'staff', invitation.id));
                            const companyRef = doc(db, "clients", company, 'staff', user.uid);
                            await setDoc(companyRef, { id: user.uid, key: user.uid, adminLevel: invitation.adminLevel || 'none' });
                            accounts.forEach(async (account) => {
                                await deleteDoc(doc(db, "clients", company, 'accounts', account, 'staff', invitation.id));
                                const accountRef = doc(db, "clients", company, 'accounts', account, 'staff', user.uid);
                                await setDoc(accountRef, { key: user.uid, id: user.uid, adminLevel: invitation.adminLevel || 'none', flDistribution: false });
                            });
                        });

                        const item = { ...invitation, id: user.uid, key: user.uid, signedUp: true };

                        const docRef = doc(db, "users", user.uid);
                        delete item.password;
                        delete item.confirmPassword;
                        await setDoc(docRef, item);
                    });
            })
            .then(() => {
                setTimeout(() => {
                   
                    navigate('/')
                    window.location.reload();
                }, 1000);
                return 'success';
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                return errorMessage;
            });
    } catch (error) {
        console.log(error);
        return null;
    }
};





