
import { Col, Row, Table } from 'antd';
import moment from 'moment';
import React from 'react';
import "weather-icons/css/weather-icons.css";
import weatherIcons from './weatherIcons.json';

import axios, * as others from 'axios';
var convert = require('convert-units')


const apiKey = 'e60406d8d1152d5b1e8413bb058aa602';


export default class CurrentWeatherSideBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            weather: {},
            icon: '',
            preferences: {}
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }

    }
    componentDidMount() {
        window.scrollTo(0, 0);
        const { account } = this.state;
        axios.get('https://api.openweathermap.org/data/2.5/weather', {
            params: {
                lat: account.lat,
                lon: account.lng,
                appid: apiKey,
                units: 'imperial'
            }
        })
            .then((response) => {
                const weather = { ...response.data.main, ...response.data.weather[0], ...response.data.wind };

                var prefix = 'wi wi-';
                var code = response.data.weather[0].id;
                var icon = weatherIcons[code].icon;
             
                if (!(code > 699 && code < 800) && !(code > 899 && code < 1000)) {
                    icon = 'wi wi-day-' + icon;
                    console.log(icon);
                    this.setState({ icon })
                }
                else {
                    icon = prefix + icon;
                    this.setState({ icon })
                }

                // Finally tack on the prefix.

                this.setState({ weather })


            })
            .catch(function (error) {
                console.log(error);
            })
            .then(function () {
                // always executed
            });





    }



    render() {

        const { account, weather, icon, screenWidth } = this.state;

        const columns = [
            {
                title: 'item',
                dataIndex: 'item',
                key: 'item',
            },
            {
                title: 'data',
                dataIndex: 'data',
                key: 'data',
            },
        ]

        const data = [
            {
                key: '1',
                item: 'Date',
                data: moment().format('L'),
            },
            {
                key: '2',
                item: 'Time',
                data: moment().format('LT'),
            },
            {
                key: '3',
                item: 'Current Temp',
                data: `${weather.temp} F`,
            },
            {
                key: '3a',
                item: 'Temp Max',
                data: `${weather.temp_max} F`,
            },
            {
                key: '3b',
                item: 'Temp Min',
                data: `${weather.temp_min} F`,
            },
            {
                key: '4',
                item: 'Wind',
                data: `${weather.speed} mph`,
            },
            {
                key: '5',
                item: 'Humidity',
                data: `${weather.humidity}%`,
            },


        ];

        var dict = {
            '01d': 'wi-day-sunny',
            '02d': 'wi-day-cloudy',
            '03d': 'wi-cloud',
            '04d': 'wi-cloudy',
            '09d': 'wi-showers',
            '10d': 'wi-day-rain-mix',
            '11d': 'wi-thunderstorm',
            '13d': 'wi-snow',
            '50d': 'wi-fog',
            '01n': 'wi-night-clear',
            '02n': 'wi-night-alt-cloudy',
            '03n': 'wi-night-alt-cloudy-high',
            '04n': 'wi-cloudy',
            '09n': 'wi-night-alt-sprinkle',
            '10n': 'wi-night-alt-showers',
            '11n': 'wi-night-alt-thunderstorm',
            '13n': 'wi-night-alt-snow',
            '50n': 'wi-night-fog'
        };

        return (
            <Row align="middle" gutter={[8, 8]} style={{ paddingTop: 10 }}>
                <Col sm={24} style={{ textAlign: 'center', fontSize: 48 }}>
                    <i class={icon}></i>

                </Col>
                <Col sm={24} style={{ textAlign: 'center', textTransform: 'capitalize' }}>
                    {weather.description}

                </Col>

                <Col sm={24} style={{ textAlign: 'center', }}>

                    <Table size="small" showHeader={false} columns={columns} dataSource={data} />





                </Col >

            </Row >




        );
    }
} 
