import { Autocomplete, Button, Checkbox, Collapse, Divider, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material';
import { Row, Col } from 'antd';
import React, { useEffect, useState } from 'react';
import { ReactSortable } from 'react-sortablejs';
import uuid from 'react-uuid';
import { fullMobile } from '../../util';
import AddIcon from '@mui/icons-material/Add';
import { DeleteForever, DragIndicator } from '@mui/icons-material';
import { faVial, faLocationCrosshairs, faFillDrip, faChartArea, faChartBar, faChartLine, faChartColumn, faGear } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataColorCompact from '../settings/dataColorCompact';



var randomColor = require('randomcolor');

export default function Profiles(props) {
    const { userInfo, screenWidth, openSnack, widgetRef, updateCurrentWidget, nodes, PPArray, parameters, WPArray, profiles } = props;
    const [widget, setWidget] = useState(widgetRef);





    useEffect(() => {
        console.log(profiles)
    }, [])
    useEffect(() => {
        setWidget(widgetRef)
    }, [widgetRef])


    const AddSeriesButton = () => {
        return (
            <Button variant={'contained'} display="inline"
                fullWidth
                style={{
                    textTransform: 'none', backgroundColor: '#404050', fontSize: 14,
                }}
                onClick={() => {
                    const item = {
                        key: uuid(),
                        nodeid: '',
                        dataType: '',
                        parameterid: '',
                        profileid: '',
                        color: randomColor(),
                        markerFill: randomColor(),
                        markerLine: randomColor(),
                        type: 'spline',
                        dashed: 'Solid',
                        lineWidth: 2,
                        yAxis: 0,
                        xAxis: 0,
                        tooltip: {},
                        marker: {},
                        offset: 0,
                        units: '',
                        showInLegend: true,
                        opacity: 1,
                        decimals: 2,
                        offset: 0,
                        dots: false,
                        depth: 0,
                        label: '',
                        unitsLabel: '',
                    };
                    const newProfiles = [...widget.profiles || [], item];
                    setWidget({ ...widget, profiles: newProfiles });
                    updateCurrentWidget({ ...widget, profiles: newProfiles });
                }}
            >
                <AddIcon />  Add Profile
            </Button>

        )
    }


    async function updateInfo(index, key, val) {
        const newProfiles = [...widget.profiles];
        newProfiles[index][key] = val;
        setWidget({ ...widget, profiles: newProfiles });
        updateCurrentWidget({ ...widget, profiles: newProfiles });
    }

    const profilesList = widget.profiles || [];
    const xAxes = widget.xAxes || [];

    return (
        <Row
        >

            <Col {...fullMobile(24, 24)}>
                <ReactSortable
                    handle=".my-handle"
                    animation={500}
                    list={profilesList}
                    setList={(newState) => {
                        setWidget({ ...widget, profiles: newState });
                        updateCurrentWidget({ ...widget, profiles: newState });
                    }}
                >
                    {profilesList.map((item, index) => (
                        <Row key={item.key} align="middle" gutter={[4, 4]} style={{
                            padding: 10, paddingBottom: 0,
                            //if index is even then color is '#4040502e' else color is 'fff'
                            backgroundColor: index % 2 === 0 ? '#4747a00d' : 'fff',
                        }}>
                            <Col className='my-handle' style={{ cursor: 'pointer' }} {...fullMobile(1, 1)}>
                                <DragIndicator />
                            </Col>
                            <Col {...fullMobile(23, 23)}>
                                <Row gutter={[8, 8]}>
                                    <Col {...fullMobile(18, 18)}>
                                        <Row gutter={[8, 8]}>

                                            <Col  >
                                                <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                                    Profile
                                                </Typography>
                                                <Autocomplete
                                                    style={{ width: 300 }}
                                                    disableClearable
                                                    size="small"
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={profiles}
                                                    hiddenLabel="true"
                                                    value={profiles.find(a => a.key === item.profileid)?.profileName || ''}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            placeholder="Profile"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                    noOptionsText="Select Profile"
                                                    onChange={(a, b) => {
                                                        const newProfiles = [...profilesList];
                                                        newProfiles[index].profileid = b?.key;
                                                        setWidget({ ...widget, profiles: newProfiles });
                                                        updateCurrentWidget({ ...widget, profiles: newProfiles });

                                                    }}
                                                    InputProps={{ style: { fontSize: 6 } }}


                                                />

                                            </Col>



                                            {widget.widgetType === 'profile' && (<Col  >
                                                <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                                    Color
                                                </Typography>
                                                <DataColorCompact color={item.color} updateData={(a, color) => {
                                                    updateInfo(index, a, color);
                                                }} />
                                            </Col>)}

                                        </Row>

                                    </Col>




                                    <Col {...fullMobile(6, 6)} style={{ textAlign: 'right' }}>
                                        <Button variant={item.advancedSettings ? 'contained' : 'outlined'} display="inline"
                                            size="small"
                                            style={{
                                                textTransform: 'none', backgroundColor: item.advancedSettings ? 'inherit' : '#404050',
                                                color: item.advancedSettings ? '#404050' : 'white', border: '1px solid #404050',

                                                fontSize: 12,
                                                fontWeight: 300
                                            }}
                                            onClick={() => {
                                                //update this series' advanced settings variable    
                                                updateInfo(index, 'advancedSettings', !item.advancedSettings);

                                            }}
                                        >
                                            {item.advancedSettings ? 'Show Less' : 'Show More'}
                                        </Button>
                                        <Button variant={'link'} display="inline"
                                            size="small"
                                            style={{
                                                textTransform: 'none', color: '#404050', fontSize: 14, marginLeft: 8,
                                            }}
                                            onClick={() => {
                                                const newProfiles = [...profilesList];
                                                newProfiles.splice(index, 1);
                                                setWidget({ ...widget, profiles: newProfiles });
                                                updateCurrentWidget({ ...widget, profiles: newProfiles });
                                            }}
                                        >
                                            <DeleteForever />
                                        </Button>
                                    </Col>
                                    <Col {...fullMobile(24, 24)}>
                                        <Collapse in={item.advancedSettings || false} timeout="auto" unmountOnExit>
                                            <Row style={{ marginTop: 10 }} gutter={[12, 12]}>
                                                <Col >
                                                    <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                                        Label
                                                    </Typography>
                                                    <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                        style={{ width: 200 }}
                                                        defaultValue={item.label || ''}
                                                        onBlur={(e) => {
                                                            updateInfo(index, 'label', e.target.value);
                                                        }} />

                                                </Col>
                                                <Col >
                                                    <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                                        Units Label
                                                    </Typography>
                                                    <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                        style={{ width: 100 }}
                                                        defaultValue={item.unitsLabel || ''}
                                                        onBlur={(e) => {
                                                            updateInfo(index, 'unitsLabel', e.target.value);
                                                        }} />

                                                </Col>
                                                <Col >
                                                    <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                                        Dash
                                                    </Typography>
                                                    <Select size="small" fullWidth
                                                        notched={false}
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={item?.dashed || 'Solid'}
                                                        label="Dash"
                                                        onChange={(e) => {
                                                            updateInfo(index, 'dashed', e.target.value);
                                                        }}

                                                    >
                                                        <MenuItem value={'Solid'}>Solid</MenuItem>
                                                        <MenuItem value={'ShortDash'}>Short Dash</MenuItem>
                                                        <MenuItem value={'Dash'}>Dash</MenuItem>
                                                        <MenuItem value={'LongDash'}>Long Dash</MenuItem>
                                                        <MenuItem value={'Dot'}>Dot</MenuItem>
                                                    </Select>
                                                </Col>
                                                <Col >
                                                    <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                                        Units
                                                    </Typography>
                                                    <Select size="small" fullWidth
                                                        notched={false}
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={item?.units || ''}
                                                        label="Units"
                                                        placeholder="Units"
                                                        onChange={(e) => {
                                                            updateInfo(index, 'units', e.target.value);
                                                        }}

                                                    >

                                                        {item.parameterid === '1' && (
                                                            ['C', 'F'].map((f) => {
                                                                return (
                                                                    <MenuItem value={f}>{f}</MenuItem>
                                                                )
                                                            })
                                                        )}
                                                        {item.parameterid === '3' && (
                                                            ['m', 'ft'].map((f) => {
                                                                return (
                                                                    <MenuItem value={f}>{f}</MenuItem>
                                                                )
                                                            })
                                                        )}



                                                    </Select>
                                                </Col>
                                                <Col >
                                                    <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                                        Line Width
                                                    </Typography>
                                                    <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                        defaultValue={item?.lineWidth || 2}
                                                        style={{ width: 100 }}
                                                        type="number"
                                                        inputProps={{ min: 0 }}
                                                        onBlur={(e) => {
                                                            updateInfo(index, 'lineWidth', e.target.value === "" ? "" : Number(e.target.value));
                                                        }} />
                                                </Col>

                                                <Col >
                                                    <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                                        Opacity
                                                    </Typography>
                                                    <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                        style={{ width: 100 }}
                                                        defaultValue={item.opacity || ''}
                                                        type="number"
                                                        inputProps={{ min: 0 }}
                                                        onBlur={(e) => {
                                                            updateInfo(index, 'opacity', e.target.value === "" ? "" : Number(e.target.value));
                                                        }} />

                                                </Col>
                                                <Col >
                                                    <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                                        Dec. Places
                                                    </Typography>
                                                    <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                        style={{ width: 100 }}
                                                        defaultValue={item.decimals === 0 || item.decimals ? item.decimals : ''}
                                                        type="number"
                                                        inputProps={{ min: 0 }}
                                                        onBlur={(e) => {
                                                            updateInfo(index, 'decimals', e.target.value === "" ? "" : Number(e.target.value));
                                                        }} />

                                                </Col>
                                                <Col >
                                                    <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                                        Offset
                                                    </Typography>
                                                    <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                        style={{ width: 100 }}
                                                        defaultValue={item.offset || ''}
                                                        type="number"
                                                        inputProps={{ min: 0 }}
                                                        onBlur={(e) => {
                                                            updateInfo(index, 'offset', e.target.value === "" ? "" : Number(e.target.value));
                                                        }} />

                                                </Col>

                                                <Col >
                                                    <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                                        Show in Legend
                                                    </Typography>
                                                    <Checkbox checked={item?.showInLegend || false}
                                                        onChange={(e) => {
                                                            updateInfo(index, 'showInLegend', e.target.checked);
                                                        }}
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />

                                                </Col>
                                                <Col >
                                                    <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                                        Dots
                                                    </Typography>
                                                    <Checkbox
                                                        checked={item?.dots || false}
                                                        onChange={(e) => {
                                                            updateInfo(index, 'dots', e.target.checked);
                                                        }}
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />

                                                </Col>
                                                <Col  >
                                                    <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                                        Dot Color
                                                    </Typography>
                                                    <DataColorCompact color={item.markerFill} updateData={(a, color) => {
                                                        updateInfo(index, 'markerFill', color);
                                                    }} />
                                                </Col>
                                                <Col  >
                                                    <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                                        Y-Axis ID
                                                    </Typography>
                                                    <Select size="small" fullWidth
                                                        notched={false}
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={item.yAxis || 0}
                                                        style={{ width: 100 }}
                                                        label="Dash"
                                                        onChange={(e) => {
                                                            updateInfo(index, 'yAxis', e.target.value);
                                                        }}

                                                    >
                                                        {widget.axes.map((a) => {

                                                            return (
                                                                <MenuItem value={a.axisId}>{a.label || a.axisId}</MenuItem>
                                                            )
                                                        })}


                                                    </Select>
                                                </Col>
                                                <Col  >
                                                    <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                                        X-Axis ID
                                                    </Typography>
                                                    <Select size="small" fullWidth
                                                        notched={false}
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={item.xAxis || 0}
                                                        style={{ width: 100 }}
                                                        label="Dash"
                                                        onChange={(e) => {
                                                            updateInfo(index, 'xAxis', e.target.value);
                                                        }}

                                                    >
                                                        {xAxes.map((a) => {

                                                            return (
                                                                <MenuItem value={a.axisId}>{a.label || a.axisId}</MenuItem>
                                                            )
                                                        })}


                                                    </Select>
                                                </Col>

                                            </Row>

                                        </Collapse>
                                    </Col>
                                </Row>







                            </Col>





                            <Col span={24} style={{ paddingTop: 10, paddingBottom: 0, }}>
                                <Divider />
                            </Col>

                        </Row>
                    ))}

                </ReactSortable>
            </Col>
            <Col {...fullMobile(24, 24)} style={{ paddingTop: 20 }}>
                <AddSeriesButton />
            </Col>









        </Row>
    );
}