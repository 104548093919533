import React, { useEffect } from 'react';
import { Button, Card, CardContent, Dialog, DialogActions, DialogTitle, Grid, IconButton, List, ListItem, Popover, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { countAlbumImagesFunction } from '../../utils/albumFunctions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faDeleteLeft, faDownLeftAndUpRightToCenter, faEdit, faEllipsisH, faTrash } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles({
  card: {
    position: 'relative',
    borderRadius: '12px',
    overflow: 'hidden',
    cursor: 'grab',
    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, border 0.3s ease-in-out',
    '&:hover': {
      transform: 'scale(1.03)', // scale effect on hover
      boxShadow: '0 5px 15px rgba(0, 0, 0, 0.2)', // shadow effect on hover
      border: '1px solid rgba(0, 0, 0, 0.2)',
    },
  },
  media: {
    height: 250,
    width: '100%',
    objectFit: 'cover',
  },
  overlay: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    background: 'rgba(1, 1, 1, 0.3)', // slightly transparent black
    backdropFilter: 'blur(10px)', // increased blur value
    padding: 16,
  },
  title: {
    color: 'white', // white text
  },
  metaInfo: {
    color: 'white', // white text
  },
});

const AlbumCover = ({
  imageSrc,
  title,
  album,
  screenWidth,
  metaInfo,
  userInfo,
  setCurrentAlbum,
  setCurrentParentAlbum,
  albumBreadCrumbs,
  setAlbumBreadCrumbs,
  deleteAlbum,
  draggingId,
  combineTargetFor,
  moveUpDirectory,
  openSnack,
  state,
  selectedPhotos,
  movePhotosToAlbum
}) => {
  const classes = useStyles();
  const [imageCount, setImageCount] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dragging, setDragging] = React.useState(false);
  const [deleteDialog, setDeleteDialog] = React.useState(false);






  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(null);
  };

  const handleDeleteClose = () => {
    setDeleteDialog(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;


  return (
    <Card className={classes.card}
      onMouseDown={() => setDragging(true)}
      onMouseUp={() => setDragging(false)}

      style={{
        border: dragging === true ? '6px solid #ed8d8d' : state === 'dropTarget' ? '6px solid #506ff5' : '1px solid gray',
      }}


      onClick={() => {
        setCurrentParentAlbum(album)
        setCurrentAlbum(null)
        const newBreadcrumbs = [...albumBreadCrumbs, album]
        setAlbumBreadCrumbs(newBreadcrumbs)
      }}>
      <img src={imageSrc} alt="Album cover" className={classes.media} />
      <CardContent className={classes.overlay}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={9} >
            <Typography variant="h6" fontSize={22} className={classes.title}>{title}</Typography>
            <Typography variant="body2" fontSize={14} className={classes.metaInfo}>{album?.imageCount} Photos</Typography>
            <Typography variant="body2" fontSize={14} className={classes.metaInfo}>{album?.albumCount} Albums</Typography>
          </Grid>
          <Grid item xs={3} >
            <IconButton variant="link"
              size={screenWidth === 'xs' ? 'small' : 'medium'}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleClick(e)
              }}
              style={{
                backgroundColor: 'rgba(0,0,0,0.5)',
              }}>
              <Tooltip title="More options">
                <FontAwesomeIcon icon={faEllipsisH} size="xl" style={{ color: 'white', }} />
              </Tooltip>
            </IconButton>
          </Grid>

        </Grid>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={(e) => handleClose(e)}

          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <List sx={{
            width: 300,
            padding: 0,

          }}>

            <ListItem
              key={'createTemplate'}
              dense
              button
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setAnchorEl(null)
                setCurrentAlbum(album)
              }}
              sx={{
                borderBottom: '1px solid #e0e0e0',
                paddingTop: 1,
                paddingBottom: 1,
              }}
            >
              <FontAwesomeIcon icon={faEdit} size="lg" style={{ color: 'gray', marginRight: 10 }} />
              <Typography variant="body1" color="black" fontSize={16} fontWeight={500}>Rename</Typography>
            </ListItem>
            <ListItem
              key={'moveUp'}
              dense
              button

              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setAnchorEl(null)
                if (albumBreadCrumbs.length === 0) {
                  openSnack('error', 'Cannot move up from root album')
                  return
                }
                moveUpDirectory('album', album)
              }}
              sx={{
                borderBottom: '1px solid #e0e0e0',
                paddingTop: 1,
                paddingBottom: 1,
              }}
            >
              <FontAwesomeIcon icon={faArrowUp} size="lg" style={{ color: 'gray', marginRight: 10 }} />
              <Typography variant="body1" color={albumBreadCrumbs.length === 0 ? 'gray' : 'black'}
                fontSize={16} fontWeight={500}>Move up directory</Typography>
            </ListItem>
            <ListItem
              key={'createTemplate'}
              dense
              button

              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setAnchorEl(null)
                if (selectedPhotos.length === 0) {
                  openSnack('error', 'No photos selected')
                  return
                }
                movePhotosToAlbum(selectedPhotos, album)


              }}
              sx={{
                borderBottom: '1px solid #e0e0e0',
                paddingTop: 1,
                paddingBottom: 1,
              }}
            >
              <FontAwesomeIcon icon={faDownLeftAndUpRightToCenter} size="lg" style={{ color: 'gray', marginRight: 10 }} />
              <Typography variant="body1" color={selectedPhotos.length === 0 ? 'gray' : 'black'}
                fontSize={16} fontWeight={500}>Send photos here</Typography>
            </ListItem>
            <ListItem
              key={'createTemplate'}
              dense
              button
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setAnchorEl(null)
                setDeleteDialog(true)

              }}
              sx={{
                borderBottom: '1px solid #e0e0e0',
                paddingTop: 1,
                paddingBottom: 1,
              }}
            >
              <FontAwesomeIcon icon={faTrash} size="lg" style={{ color: 'gray', marginRight: 10 }} />
              <Typography variant="body1" color="black" fontSize={16} fontWeight={500}>Delete</Typography>
            </ListItem>





          </List>




        </Popover>

        <Dialog
          open={deleteDialog}
          onClose={handleDeleteClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Are you sure you want to delete this album?
            Deleting this album will delete all albums and photos inside it.
          </DialogTitle>

          <DialogActions>
            <Button style={{
              textTransform: 'none',
            }} onClick={handleDeleteClose}>Cancel</Button>
            <Button
              style={{
                fontWeight: 600,
                textTransform: 'none',
                color: 'red'
              }}

              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setAnchorEl(null)
                deleteAlbum(album)
                handleDeleteClose();
              }}
              autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>

      </CardContent>
    </Card>
  );
};

export default AlbumCover;
