import {
    Autocomplete,
    Collapse,
    IconButton,
    ListItemButton,
    ListItemText,
    OutlinedInput,
    Select,
    TextField,
    Typography
} from "@mui/material";
import React, { useState } from "react";

import {
    faCaretDown,
    faCaretUp,
    faCircleCheck
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-regular-svg-icons";

const AlertItem = ({ alert, open, onClick, parameters, updateAlert }) => {
    const [active, setActive] = useState(alert.active)
    return (
        <div
            style={{
                border: `1px solid ${alert.color || "#404050"}`,
                borderRadius: 4,
                marginBottom: 6,
                padding: 2,
                backgroundColor: alert.color || "#404050",
            }}
        >
            <ListItemButton
                onClick={onClick}
                sx={{
                    fontWeight: 600,
                    paddingLeft: 0,
                    paddingBottom: 0,
                    paddingTop: 0,
                }}
            >
                <ListItemText
                    primary={
                        <Typography
                            fontSize={13} fontWeight={600} variant="body1"
                            color="white"
                        >
                            <IconButton
                                style={{ marginRight: '10px' }}
                                variant="outlined"
                                size="small"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setActive(!active)
                                    updateAlert(alert.id, 'active', !active)

                                }}

                            >
                                <FontAwesomeIcon icon={active ? faCircleCheck : faCircle
                                } color={'white'} />
                            </IconButton>

                            {alert.name}
                        </Typography>
                    }
                    sx={{
                        paddingLeft: 0,
                    }}
                />
                {open ? (
                    <FontAwesomeIcon color={'white'} icon={faCaretDown} />
                ) : (
                    <FontAwesomeIcon color={'white'} icon={faCaretUp} />
                )}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit style={{ backgroundColor: 'white', padding: 4 }}>
                <Typography fontSize={13} fontWeight={600} variant="body1">Parameter: {
                    alert.type === 'locationDrift' ? 'Lat/Lon' :
                        parameters.find(p => p.id === alert.parameter)?.label}</Typography>
                <Typography fontSize={13} fontWeight={600} variant="body1">Value: {alert.type === 'locationDrift' ? '50 ft off' : alert.value}</Typography>
                <Typography fontSize={13} fontWeight={600} variant="body1">Type: {alert.type === 'locationDrift' ? 'Drift' : alert.type === 'above' ? 'Above' : 'Below'}</Typography>
                <Typography fontSize={13} fontWeight={600} variant="body1">Frequency: 
                {alert?.alertType === undefined ? ' Continuous' : alert?.alertType === 'continuous' ? ' Continuous' : 
                                            alert?.alertType === 'daily' ? ' Daily' : alert?.alertType === 'weekly' ? ' Weekly' : ' One Off'}
                </Typography>

            </Collapse>
        </div>
    );
};

export default AlertItem;
