import { Button, Typography } from '@mui/material';
import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, { attr } from 'highcharts/highstock';
import moment from 'moment-timezone';
import React from 'react';
import './chart.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faExclamationTriangle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import axios, * as others from 'axios';
import ExportChartCSV from './exportChartCSV';
import merge from "deepmerge";
import unitsList from '../../../extra/units.json'
import { SpeedUnit, convertUnits } from 'measurement-unit-converter';
import './chartStyles.css';
var convert = require('convert-units')
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/data')(Highcharts);


const parameterMap = {
    '1': {
        default: 'C',
    },
    '3': {
        default: 'm',

    },
    '4': {
        default: 'm',
    },
    '5': {
        default: 'm',
    },
    '8': {
        default: 'm',
    },
    '19': {
        from: '',
        to: ''
    }
};



export default class WidgetChart extends React.Component {
    constructor(props) {
        super(props);
        this.chartRef = React.createRef();
        this.state = {
            type: this.props.type,
            loading: true,
            attributes: this.props.attributes,
            userInfo: this.props.userInfo,
            height: null,
            series: {},
            queryInfo: {},
            legendSize: this.props.legendSize,
            referenceSize: this.props.referenceSize,
            axisSize: this.props.axisSize,
            yAxisSize: this.props.yAxisSize,
            xAxisAngle: this.props.xAxisAngle,
            chartHeight: this.props.chartHeight,
            chartWidth: this.props.chartWidth,
            dateInterval: this.props.dateInterval,
            disableTooltip: this.props.disableTooltip,
            widgetKey: this.props.widgetKey,
            chartOptions: {
                credits: {
                    enabled: false
                },
                exporting: {
                    enabled: false
                },
                stockTools: {
                    gui: {
                        enabled: false
                    }
                },
                chart: {
                    alignTicks: true,
                    type: 'line',
                    resetZoomButton: {
                        theme: {
                            style: {
                                display: 'none'

                            }
                        }
                    },
                    zooming: {
                        mouseWheel: {
                            enabled: false
                        }
                    },




                    zoomType: 'x',

                    height: null,
                    animation: false
                },
                title: {
                    text: null
                },
                legend: {
                    itemStyle: {
                        fontSize: this.props.legendSize !== undefined ? `${this.props.legendSize}px` : '14px',
                        fontWeight: '500',
                        fontFamily: 'Roboto, sans-serif'
                    },
                },


                series: [],
                yAxis: [{ id: 0 }],
                xAxis: {
                    type: 'datetime',
                    startOnTick: false,
                    endOnTick: false,
                    plotLines: [],
                    crosshair: {
                        width: 10,
                        color: '#2651d214',
                        dashStyle: 'solid',
                        zIndex: 0,
                    },

                    title: {
                        text: undefined,
                        style: {
                            fontSize: '12px',  // Increase size as needed
                            fontWeight: '800',  // Set font weight to 500
                            color: 'black'
                        }

                    },
                    labels: {
                        style: {
                            fontSize: '12px',  // Increase size as needed
                            fontWeight: '700',  // Set font weight to 500
                            color: 'black'
                        }
                    },
                },
                tooltip: {
                    enabled: true,
                    xDateFormat: '%b %d, %Y %I:%M %p',
                    shared: true,
                    crosshairs: true,
                    outside: true,
                    useHTML: true, // Enable HTML in tooltips
                    formatter: function () {

                        var s = `<b style="font-family: Roboto; font-size: 14px; font-weight: 800;">${moment(this.x).format('MMM DD, YYYY hh:mm A')}</b>`;



                        this.points.forEach(function (point) {
                            // Start with the raw value
                            let valueFormatted = point.y;

                            // Format the value based on defined decimals
                            if (point.series.tooltipOptions.valueDecimals !== undefined) {
                                /* make absolute value of  point.series.tooltipOptions.valueDecimals*/
                                const absValueDecimals = Math.abs(point.series.tooltipOptions.valueDecimals);
                                valueFormatted = valueFormatted.toFixed(absValueDecimals);
                            }

                            // Add a suffix if it's defined, otherwise, don't add anything
                            valueFormatted += point.series.tooltipOptions.valueSuffix ? point.series.tooltipOptions.valueSuffix : "";

                            // Use toLocaleString for formatting if no decimals are specified
                            if (point.series.tooltipOptions.valueDecimals === undefined) {
                                valueFormatted = parseFloat(valueFormatted).toLocaleString();
                            }

                            // Concatenate the tooltip string
                            s += `<br/><span style="color:${point.series.color}; font-size: 15px;">●</span> <b style="font-family: Roboto; font-size: 13px;">${point.series.name}:</b> <span style="font-family: Roboto; font-size: 13px;">${valueFormatted}</span>`;
                        });

                        return s;
                    },
                    style: {
                        fontFamily: 'Roboto', // Ensure the tooltip font is Roboto
                        zIndex: 500,
                    }
                },



                plotOptions: {
                    series: {
                        animation: false
                    },
                    column: {
                        pointWidth: 20 // set the column width for column series
                    },

                },
                time: {
                    useUTC: false,
                }
            },
            isZoomed: false,




        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })

        }
        if (this.props.isZoomed !== prevProps.isZoomed) {
            this.setState({ isZoomed: this.props.isZoomed })

            if (this.props.isZoomed === false) {
                this.zoomOut()

            }

        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.attributes !== prevProps.attributes) {
            this.updateAttributes(this.props.attributes)
            this.setState({ attributes: this.props.attributes })
        }
        if (this.props.legendSize !== prevProps.legendSize) {
            const { chartOptions } = this.state;
            this.setState({ legendSize: this.props.legendSize })
            chartOptions.legend.itemStyle = { fontSize: `${this.props.legendSize}px` }
            this.setState({ chartOptions: chartOptions })
        }
        if (this.props.referenceSize !== prevProps.referenceSize) {
            this.setState({ referenceSize: this.props.referenceSize })
        }
        if (this.props.axisSize !== prevProps.axisSize) {
            this.setState({ axisSize: this.props.axisSize })
        }




    }

    zoomOut = () => {

        if (this.chartRef && this.chartRef.current) {
            this.chartRef.current.chart.zoomOut();
            this.props.updateZoom(false)
        }
    }
    componentDidMount() {
        localStorage.removeItem(`${this.state?.widgetKey}-zoom_range`)
        const { attributes, chartOptions, queryInfo, series, legendSize, axisSize, yAxisSize, xAxisAngle, referenceSize, chartHeight, chartWidth, dateInterval, type } = this.state;

        this.setState({ height: this.container.offsetHeight, });
        this.updateChartSub('chart', 'height', this.container.offsetHeight)
        setTimeout(() => {
            if (this.chartRef && this.chartRef.current && legendSize !== undefined) {
                chartOptions.legend.itemStyle = { fontSize: `${legendSize}px` }


                this.setState({ chartOptions: chartOptions })
            }

            //update x axis labels
            if (this.chartRef && this.chartRef.current && axisSize !== undefined) {
                //how many ticks are there on the x axis

                //change tick interval to monthly
                chartOptions.xAxis.tickInterval = dateInterval !== undefined ?
                    dateInterval === 'monthly' ? 30 * 24 * 3600 * 1000 :
                        dateInterval === 'weekly' ? 7 * 24 * 3600 * 1000 :
                            dateInterval === 'daily' ? 24 * 3600 * 1000 :
                                dateInterval === 'hourly' ? 3600 * 1000 :
                                    //biweekly
                                    dateInterval === 'biweekly' ? 14 * 24 * 3600 * 1000 :
                                        //quarterly
                                        dateInterval === 'quarterly' ? 90 * 24 * 3600 * 1000 :
                                            //semiannually
                                            dateInterval === 'semiannually' ? 180 * 24 * 3600 * 1000 :
                                                //bimonthly
                                                dateInterval === 'bimonthly' ? 60 * 24 * 3600 * 1000 :
                                                    //annually
                                                    dateInterval === 'yearly' ? 365 * 24 * 3600 * 1000 : 365 * 24 * 3600 * 1000
                    : 365 * 24 * 3600 * 1000;
                //do not show last label

                //offset labels if there are more than 8

                //turn auto rotation on








                chartOptions.xAxis.labels = {

                    style: { fontSize: `${axisSize}px` },

                    autoRotation: [-10, -20, -30, -40, -50, -60, -70, -80, -90, -100, -110, -120, -130, -140, -150, -160, -170, -180],
                    rotation: xAxisAngle,
                    fontFamily: 'Roboto, sans-serif',
                }



                //turn auto rotation on

                this.setState({ chartOptions: chartOptions })
            }

            //update y axis ticks and labels for all y axes
            if (this.chartRef && this.chartRef.current && yAxisSize !== undefined) {

                for (let i = 0; i < chartOptions.yAxis.length; i++) {
                    chartOptions.yAxis[i].labels = { style: { fontSize: `${yAxisSize}px` } }
                    //update title size
                    chartOptions.yAxis[i].title.style = { fontSize: `${yAxisSize}px` }



                    this.setState({ chartOptions: chartOptions })
                }
            }
            if (this.chartRef && this.chartRef.current && referenceSize !== undefined) {
                //update font size for all reference lines for both x and y axes
                for (let i = 0; i < chartOptions.xAxis.plotLines.length; i++) {
                    chartOptions.xAxis.plotLines[i].label.style = { fontSize: `${referenceSize}px`, fontFamily: 'Roboto, sans-serif' }
                }

                for (let i = 0; i < chartOptions.yAxis.length; i++) {
                    for (let j = 0; j < chartOptions.yAxis[i].plotLines.length; j++) {
                        chartOptions.yAxis[i].plotLines[j].label.style = { fontSize: `${referenceSize}px`, fontFamily: 'Roboto, sans-serif' }
                    }
                }
                this.setState({ chartOptions: chartOptions })


            }

            //change chart height to chartHeight

            if (type === 'customScreenshot') {
                chartOptions.chart.height = chartHeight * 96
                chartOptions.chart.width = chartWidth * 96
                this.setState({ chartOptions: chartOptions })
            }





        }, 350);



        this.updateAttributes(attributes)



    }



    updateAttributes = (attributes) => {

        const { chartOptions } = this.state;

        const qi = {
            interval: null,
            units: 'hours',
            start: moment(attributes.startDate, 'x').startOf("day").format('MM-DD-YYYY HH:mm'),
            iStart: moment(attributes.startDate, 'x').startOf("day").format('x'),
            end: attributes.endToday === true ? moment().endOf("day").format('MM-DD-YYYY HH:mm') : moment(attributes.endDate, 'x').endOf("day").format('MM-DD-YYYY HH:mm'),
            iEnd: attributes.endToday === true ? moment().endOf("day").format('x') : moment(attributes.endDate, 'x').endOf("day").format('x'),

            customInterval: attributes.customInterval || undefined,
            customIntervalVal: attributes.customIntervalVal || undefined,

        };

        this.setState({ queryInfo: qi })

        //map all chartData to have q.yAxis =  0;

        // Example function to be called when series is hidden
        const onSeriesHide = (e) => {

            const seriesKey = e.target.options.key;
            /* change visible attribute for this series */
            const index = attributes.chartData.map((s) => s.key).indexOf(seriesKey);

            chartOptions.series[index].visible = false;
            this.setState({ chartOptions })
            /* update attributes */

            // Add your custom logic here
        };

        // Example function to be called when series is shown
        const onSeriesShow = (e) => {

            const seriesKey = e.target.options.key;
            /* change visible attribute for this series */
            const index = chartOptions.series.map((s) => s.key).indexOf(seriesKey);
            chartOptions.series[index].visible = true;
            this.setState({ chartOptions })
            // Add your custom logic here
        };

        const series = attributes.chartData.map(series => ({
            ...series, // Spread existing series properties
            events: {
                hide: onSeriesHide,
                show: onSeriesShow,
            },
        }));

        this.getRange(qi, series)


        chartOptions.chart.events = {

            //console.log loaded when load
            load: () => {

            },


            selection: (event) => {
                if (event.resetSelection) {
                    this.setState({ isZoomed: false })
                    this.props.updateZoom(false)
                } else {
                    this.setState({ isZoomed: true })
                    this.props.updateZoom(true)
                }
                if (event.xAxis != null) {
                    this.state.queryInfo.start = moment(Math.ceil(event.xAxis[0].min), 'x').format('MM-DD-YYYY HH:mm');
                    this.state.queryInfo.end = moment(Math.ceil(event.xAxis[0].max), 'x').format('MM-DD-YYYY HH:mm');
                    this.getRange(this.state.queryInfo, this.state.chartOptions.series)
                    /* create localStorage of widgetKeys zoom range */
                    localStorage.setItem(`${this.state.widgetKey}-zoom_range`, JSON.stringify({ start: this.state.queryInfo.start, end: this.state.queryInfo.end }))

                }

                if (event.xAxis === undefined) {


                    this.state.queryInfo.start = moment(this.state.queryInfo.iStart, 'x').format('MM-DD-YYYY HH:mm');
                    this.state.queryInfo.end = moment(this.state.queryInfo.iEnd, 'x').format('MM-DD-YYYY HH:mm');
                    this.getRange(this.state.queryInfo, this.state.chartOptions.series)
                    /* remove localStorage zoom */
                    localStorage.removeItem(`${this.state.widgetKey}-zoom_range`)


                }



            }
        };




        chartOptions.series = series;




        this.setState({ chartOptions, loading: attributes.chartData.length === 0 ? false : true, })

    }





    updateChart = (boo, val) => {
        const { chartOptions } = this.state;
        chartOptions[boo] = val;
        this.setState({ chartOptions })

    }
    updateChartSub = (boo, a, val) => {
        const { chartOptions } = this.state;
        chartOptions[boo][a] = val;
        this.setState({ chartOptions })

    }




    querySample = async (q, queryInfo) => {
        const { chartOptions, series, userInfo } = this.state;





        const proxyUrl = "https://mycorslake.herokuapp.com/";
        axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/databaseFetch', {
            raw: `SELECT time, timestamp, value, locationlabel, locationid, parameterid, parameterlabel, account
            FROM lab_data
            WHERE locationid = '${q.locationid}'and parameterid = '${q.parameterid}'
            and time >= '${queryInfo.start}' 
            and time <= '${queryInfo.end}' 
            ORDER BY "time" DESC;`
        })
            .then(async (response) => {

                q.data = response.data.map((d) => {
                    const offset = q.offset || 0;
                    const value = Number(d.value.toFixed(4)) + offset;
                    return ([d.timestamp * 1000, value])
                });

                q.data.sort((a, b,) => a[0] - b[0]);

                const index = chartOptions.series.map((s) => s.key).indexOf(q.key);
                q.name = q.label || null;
                q.key = q.key;

                q.tooltip.valueSuffix = q.suffix === undefined ? null : ` ${q.suffix}`;
                q.tooltip.valueDecimals = q.decimals || null;
                q.marker.lineColor = q.markerLine || null;
                q.marker.fillColor = q.markerFill || null;
                q.pointWidth = q.columnWidth || 3;
                q.dashStyle = q.dashed || null;
                q.lineWidth = q.lineWidth || 2;
                q.marker.enabled = q.dots || false;
                q.opacity = q.opacity || 1;

                q.fillColor = {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                    },
                    stops: [
                        [0, Highcharts.color(q.color).setOpacity(0.2).get('rgba')],
                        [1, Highcharts.color(q.color).setOpacity(0).get('rgba')]
                    ]
                }



                chartOptions.series[index] = q;
                this.setState({ chartOptions, loading: false })




            })
            .catch((error) => {
                // handle error



            })
            .then(function () {
                // always executed
            });

    }
    queryData = (q, queryInfo, i) => {
        const { chartOptions, series, userInfo } = this.state;



        this.setState({ queryInfo })
        const proxyUrl = "https://mycorslake.herokuapp.com/";

        const customBucket = queryInfo.customInterval === true ?
            `SELECT time_bucket('${queryInfo.customIntervalVal || 1} hours', time) as "t"
            ,locationid, avg(timestamp) as "x", avg(value) as "y"
            FROM probe_data
            WHERE locationid = '${q.locationid}'and parameterid = '${q.parameterid}'
            and time >= '${queryInfo.start}' 
            and time <= '${queryInfo.end}' 
            GROUP BY "t", locationid
            ORDER BY "t" DESC;`
            :

            `SELECT time_bucket('${q.interval || 1} hours', time) as "t"
            ,locationid, avg(timestamp) as "x", avg(value) as "y"
            FROM probe_data
            WHERE locationid = '${q.locationid}'and parameterid = '${q.parameterid}'
            and time >= '${queryInfo.start}' 
            and time <= '${queryInfo.end}' 

            GROUP BY "t", locationid
            ORDER BY "t" DESC;`;

        const queryString = "widgetsFire5";



        axios.post(proxyUrl + `https://us-central1-aquasource3.cloudfunctions.net/databaseFetch`, {
            raw: customBucket
        })
            .then(async (response) => {

                let offset = q.offset || 0;
                q.data = response.data.map((d) => {
                    let value = Number(d.y);


                    if (d.unitid !== undefined && d.unitid !== null && d.unitid !== '' && q.units) {
                        let parameter = parameterMap[q.parameterid] || {};
                        let defaultUnit = parameter.default || '';


                        const unitLabel = unitsList[d.unitid] ? unitsList[d.unitid] : defaultUnit;

                        value = Number(convert(d.y.toFixed(4)).from(unitLabel).to(q.units));
                    }
                    else {
                        value = q.parameterid === '19' ? Number(value.toFixed(4)) * 1000 : Number(value.toFixed(4));

                    }

                    value += offset;
                    const newX = Number(moment(d.t, "YYYY-MM-DD HH:mm:ss").format("x"))
                    return ([newX, value]);
                });


                q.data.sort((a, b,) => a[0] - b[0])



                const index = chartOptions.series.map((s) => s.key).indexOf(q.key);

                const input = q.decimals === 0 || q.decimals ? q.decimals : 2;

                q.name = q.label || null;
                q.key = q.key;
                q.tooltip.valueSuffix = q.suffix === undefined ? null : ` ${q.suffix}`;
                q.tooltip.valueDecimals = input;
                q.marker.lineColor = q.markerLine || null;
                q.marker.fillColor = q.markerFill || null;
                q.dashStyle = q.dashed || null;
                q.lineWidth = q.lineWidth || 2;
                q.pointWidth = q.columnWidth || 3;
                q.opacity = q.opacity || 1;
                q.marker.enabled = q.dots || false;
                q.fillColor = {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                    },
                    stops: [
                        [0, Highcharts.color(q.color).setOpacity(0.2).get('rgba')],
                        [1, Highcharts.color(q.color).setOpacity(0).get('rgba')]
                    ]
                };



                chartOptions.series[index] = q;
                this.setState({ chartOptions, loading: false })




            })
            .catch((error) => {
                // handle error


            })
            .then(function () {
                // always executed
            });

    }
    queryDataNode = (q, queryInfo, i) => {

        const { chartOptions, series, userInfo, attributes, disableTooltip } = this.state;

        try {

            this.setState({ queryInfo })
            const proxyUrl = "https://mycorslake.herokuapp.com/";



            const timezone = q?.location?.sensor?.timezone || 'US/Pacific';

            let value = 'avg(value) as "y", COALESCE(avg(offsetvalue) , 0) as "offset", tags';



            let conditionStr = "";
            if (q.conditions && q.conditions.length > 0) {
                q.conditions.forEach((cond) => {
                    if (cond.conditionType === 'value' && cond.action !== 'highlight') {
                        conditionStr += ` AND value 
                         ${cond.action === 'hide' && cond.type == '>' ? '<' :
                                cond.action === 'hide' && cond.type === '<' ? '>' :
                                    cond.type}
                         ${cond.value || 0}`;
                    } else if (cond.conditionType === 'tag' && cond.action !== 'highlight') {
                        cond.tags.forEach((tag) => {
                            conditionStr += ` AND tags ${cond.action === 'hide' ? 'NOT LIKE' : 'LIKE'} '%\\"${tag}\\"%'`;
                        });
                    }
                });
            }



            try {



                const customBucket = q.parameter?.key && q.parameter?.key.length > 5 ?
                    `SELECT time as "t"
             ,nodeid, unitid,  avg(timestamp) as "x", ${value}
             FROM node_data_new2
             WHERE nodeid = '${q.locationid}'and parameterid = '${q.parameterid}' 
             and time >= '${queryInfo.start}' 
             and time <= '${queryInfo.end}' 
             ${conditionStr}
 
             GROUP BY "t", nodeid, unitid, tags
             ORDER BY "t" DESC;` :

                    queryInfo.customInterval === true ?
                        `SELECT time_bucket('${queryInfo.customIntervalVal || 1} hours', time) as "t"
            ,nodeid,  unitid,  avg(timestamp) as "x", ${value}
            FROM node_data_new2
            WHERE nodeid = '${q.locationid}'and parameterid = '${q.parameterid}'
            and time >= '${queryInfo.start}' 
            and time <= '${queryInfo.end}' 
            ${conditionStr}
            GROUP BY "t", nodeid, unitid, tags
            ORDER BY "t" DESC;`
                        :
                        userInfo.currentCompany === 'completewaters91b71f99-8959-54d5-85a9-a82d0615430c'
                            ?
                            `SELECT time_bucket('${q.interval || 1} hours', time) as "t"
                            ,nodeid, unitid, avg(timestamp) as "x", ${value}
                            FROM node_data_new2
                            WHERE nodeid = '${q.locationid}'and parameterid = '${q.parameterid}'
                            and time >= '${queryInfo.start}' 
                            and time <= '${queryInfo.end}' 
                            ${conditionStr}
                
                            GROUP BY "t", nodeid, unitid, tags
                            ORDER BY "t" DESC;`
                            :

                            `SELECT time_bucket('${q.interval || 1} hours', time) as "t"
             ,nodeid, unitid, avg(timestamp) as "x", ${value}
             FROM node_data_new2
             WHERE nodeid = '${q.locationid}'and parameterid = '${q.parameterid}'
             and time >= '${queryInfo.start}' 
             and time <= '${queryInfo.end}' 
             ${conditionStr}
 
             GROUP BY "t", nodeid, unitid, tags
             ORDER BY "t" DESC;`
                    ;





                let highlightObjects = [];

                if (q.conditions && q.conditions.length > 0) {
                    q.conditions.forEach((cond) => {
                        if (cond.conditionType === 'value' && cond.action === 'highlight') {
                            highlightObjects.push({
                                conditionType: 'value',
                                value: Number(cond.value),
                                type: cond.type,
                                color: cond.color, // Assuming you have a color specified in your condition
                                radius: cond.radius ? Number(cond.radius) : 4, // Default radius if not specified
                            });
                        } else if (cond.conditionType === 'tag' && cond.action === 'highlight') {
                            highlightObjects.push({
                                conditionType: 'tag',
                                tags: cond.tags,
                                color: cond.color,
                                radius: cond.radius ? Number(cond.radius) : 4,
                            });
                        }
                    });
                }
                q.marker.enabled = q.typeHidden === 'scatter' ? true : q.dots || false;

                // Function to apply highlight properties to marker
                function applyHighlight(markerProps, highlight) {

                    markerProps.enabled = true;
                    markerProps.fillColor = highlight.color;
                    markerProps.radius = highlight.radius;
                    markerProps.lineColor = highlight.lineColor || markerProps.lineColor; // Use highlight.lineColor if specified
                }

                const functionNameMap = {
                    0: "databaseFetchLarge2",
                    1: "databaseFetch3",
                    2: "databaseFetch4",
                    3: "databaseFetch5",
                    4: "databaseFetchLarge2",
                    5: "databaseFetch3",
                    6: "databaseFetch4",
                    7: "databaseFetch5",
                    8: "databaseFetchLarge2",
                    9: "databaseFetch3",
                    10: "databaseFetch4",
                    11: "databaseFetch5",

                };


                const queryString = functionNameMap[i] || "databaseFetchLarge2";
                axios.post(proxyUrl + `https://us-central1-aquasource3.cloudfunctions.net/${queryString}`, {
                    raw: customBucket
                })
                    .then(async (response) => {





                        let offset = q.offset || 0;


                        q.data = response.data.map((d) => {
                            let value = Number(d.y);







                            if (["38", "35", "1", "2"].includes(d.unitid) && d.unitid !== undefined && d.unitid !== null && d.unitid !== '' && q.units) {
                                let parameter = parameterMap[q.parameterid] || {};
                                let defaultUnit = parameter.default || '';


                                const unitLabel = unitsList[d.unitid] ? unitsList[d.unitid] : defaultUnit;

                                value = Number(convert(value.toFixed(4)).from(unitLabel).to(q.units));
                            }
                            else if (q.parameterid === '19') {
                                if (value < 20) {
                                    value = value * 1000;
                                }
                                value = Number(value.toFixed(4));
                            }



                            if (q?.dataSourceType === 'readingWithOffset') {
                                value += Number(d.offset)
                            }
                            if (q?.dataSourceType === 'offset') {
                                value = Number(d.offset);
                            }






                            const newX = Number(moment(d.t, "YYYY-MM-DD HH:mm:ss").tz(timezone).format("x"));
                            let markerProps = { enabled: q.marker.enabled, radius: 3 }; // Default marker properties

                            highlightObjects.forEach(hl => {
                                let conditionMet = false;
                                if (hl.conditionType === 'value') {
                                    switch (hl.type) {
                                        case '>': conditionMet = value > hl.value; break;
                                        case '<': conditionMet = value < hl.value; break;
                                        case '=': conditionMet = value === hl.value; break;
                                        // Add other comparison types as needed
                                    }
                                } else if (hl.conditionType === 'tag') {
                                    conditionMet = hl.tags.some(tag => d.tags && d.tags.includes(tag)); // Assuming d.tags is an array of tags
                                }

                                // Apply highlight if condition is met
                                if (conditionMet) {
                                    applyHighlight(markerProps, hl);
                                }
                            });

                            return ({
                                x: newX,
                                y: value + offset,
                                custom: { offset: d?.offset || 0 },
                                marker: markerProps
                            });
                        });



                        q.data.sort((a, b,) => a.x - b.x)



                        const index = chartOptions.series.map((s) => s.key).indexOf(q.key);

                        const input = q.decimals === 0 || q.decimals ? q.decimals : 2;

                        q.name = q.label || null;
                        q.key = q.key;
                        q.tooltip.valueSuffix = q.suffix === undefined ? null : ` ${q.suffix}`;
                        q.tooltip.valueDecimals = input;
                        q.marker.lineColor = q.markerLine || null;
                        q.marker.fillColor = q.markerFill || null;
                        q.dashStyle = q.dashed || null;
                        q.lineWidth = q.typeHidden === 'scatter' ? 0 : q.lineWidth || 2;
                        q.pointWidth = q.columnWidth || 3;
                        q.opacity = q.opacity || 1;


                        if (q.typeHidden === 'scatter') {
                            q.marker.symbol = q.typeHidden === 'scatter' ? 'circle' : null;
                        }

                        q.type = q.typeHidden === 'scatter' ? 'spline' : q.type;
                        q.fillColor = {
                            linearGradient: {
                                x1: 0,
                                y1: 0,
                                x2: 0,
                                y2: 1
                            },
                            stops: [
                                [0, Highcharts.color(q.color).setOpacity(0.2).get('rgba')],
                                [1, Highcharts.color(q.color).setOpacity(0).get('rgba')]
                            ]
                        }
                        q.states = {
                            ...q.states,
                            hover: {
                                lineWidthPlus: q.typeHidden === 'scatter' ? 0 : q.lineWidth || 2,
                            }
                        }

                        if (attributes?.showTooltip === 'noTooltip') {
                            q.states = {
                                ...q.states,
                                inactive: {
                                    opacity: 1
                                }
                            }
                        }

                        if (disableTooltip) {
                            q.tooltip = {
                                ...q.tooltip,
                                enabled: false
                            }
                        }




                        chartOptions.series[index] = q;
                        this.setState({ chartOptions, loading: false })
                        /* update attributes */
                        attributes.chartData = chartOptions.series;
                        this.setState({ attributes })




                    })
                    .catch((error) => {
                        // handle error
                        console.log(error);


                    })
                    .then(function () {
                        // always executed
                    });

            }
            catch (error) {
                console.log(error)
            }

        }
        catch (error) {
            console.log(error)
        }

    }
    queryWeatherData = (q, queryInfo) => {



        const { chartOptions, series, userInfo, attributes } = this.state;



        this.setState({ queryInfo })
        const proxyUrl = "https://mycorslake.herokuapp.com/";
        axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/databaseFetch', {
            raw: `SELECT time_bucket('${q.customIntervalVal || 1} hours', time) as "t"
            ,account, avg(dt) as "x", avg(temp) as "y", sum(rain) as "r"
            FROM weather_data
            WHERE account = '${userInfo.currentAccount}'
            and time >= '${queryInfo.start}' 
            and time <= '${queryInfo.end}' 
            GROUP BY "t", account
            ORDER BY "t" DESC;`
        })
            .then(async (response) => {



                if (q.parameterid === '1') {
                    q.data = response.data.map((d) => {
                        const offset = q.offset || 0;
                        const value = Number(convert(d.y.toFixed(4)).from('C').to(['C', 'F'].includes(q.units) ? q.units : 'C')) + offset;
                        return ([d.x * 1000, value])
                    });
                }
                if (q.parameterid === '2') {
                    const rainReadings = response?.data?.filter((d) => d.r !== null && d.r !== undefined && d.r !== 0);

                    q.data = rainReadings.map((d) => {
                        const offset = q.offset || 0;

                        const value = Number((convert(d.r).from('mm').to('in')).toFixed(2)) + offset;

                        /* make value only have two decimals */

                        if (value < 0.025) {
                            return ([d.x * 1000, 0])
                        }
                        return ([d.x * 1000, value])
                    });
                }

                q.data.sort((a, b,) => a[0] - b[0])

                const index = chartOptions.series.map((s) => s.key).indexOf(q.key);
                q.name = q.label || null;

                q.tooltip.valueSuffix = q.suffix === undefined ? null : ` ${q.suffix}`;
                q.tooltip.valueDecimals = q.decimals || null;
                q.marker.lineColor = q.markerLine || null;
                q.marker.fillColor = q.markerFill || null;
                q.dashStyle = q.dashed || null;
                q.lineWidth = q.typeHidden === 'scatter' ? 0 : q.lineWidth || 2;
                q.pointWidth = q.columnWidth || 3;
                q.opacity = q.opacity || 1;
                q.marker.enabled = q.typeHidden === 'scatter' ? true : q.dots || false;
                q.fillColor = {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                    },
                    stops: [
                        [0, Highcharts.color(q.color).setOpacity(0.2).get('rgba')],
                        [1, Highcharts.color(q.color).setOpacity(0).get('rgba')]
                    ]
                }
                q.states = {
                    ...q.states,
                    hover: {
                        lineWidthPlus: q.typeHidden === 'scatter' ? 0 : q.lineWidth || 2,
                    }
                }

                if (attributes?.showTooltip === 'noTooltip') {
                    q.states = {
                        ...q.states,
                        inactive: {
                            opacity: 1
                        }
                    }
                }
                chartOptions.series[index] = q;
                this.setState({ chartOptions, loading: false })


            })
            .catch((error) => {
                // handle error
                console.log(error);


            })
            .then(function () {
                // always executed
            });




    }
    queryGauge = (q, queryInfo) => {

        console.log(q)
        console.log(queryInfo)

        const { chartOptions, series, userInfo, attributes } = this.state;


        this.setState({ queryInfo })
        const proxyUrl = "https://mycorslake.herokuapp.com/";

        console.log(
            `SELECT * 
            FROM gauge_data
            WHERE station = '${q.locationid}' AND datatype = 'PRCP'
            and time >= '${queryInfo.start}' 
            and time <= '${queryInfo.end}' 
            ORDER BY "time" DESC;`

        )
        axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/databaseFetch', {
            raw: `SELECT * 
            FROM gauge_data
            WHERE station = '${q.locationid}' AND datatype = 'PRCP'
            and time >= '${queryInfo.start}' 
            and time <= '${queryInfo.end}' 
            ORDER BY "time" DESC;`
        })
            .then(async (response) => {
                console.log(response)


                q.data = response.data.map((d) => {
                    const offset = q.offset || 0;
                    const value = Number(d.value.toFixed(4)) + offset;
                    const x = Number(moment(d.time, 'YYYY-MM-DD HH:mm:ss').format('x'));
                    return ([x, value])
                });

                q.data.sort((a, b,) => a[0] - b[0])

                const index = chartOptions.series.map((s) => s.key).indexOf(q.key);
                q.name = q.label || null;

                q.tooltip.valueSuffix = q.suffix === undefined ? null : ` ${q.suffix}`;
                q.tooltip.valueDecimals = q.decimals || null;
                q.marker.lineColor = q.markerLine || null;
                q.marker.fillColor = q.markerFill || null;
                q.dashStyle = q.dashed || null;
                q.lineWidth = q.typeHidden === 'scatter' ? 0 : q.lineWidth || 2;
                q.pointWidth = q.columnWidth || 3;
                q.opacity = q.opacity || 1;
                q.marker.enabled = q.typeHidden === 'scatter' ? true : q.dots || false;

                q.fillColor = {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                    },
                    stops: [
                        [0, Highcharts.color(q.color).setOpacity(0.3).get('rgba')],
                        [1, Highcharts.color(q.color).setOpacity(0.01).get('rgba')]
                    ]
                }
                q.states = {
                    ...q.states,
                    hover: {
                        lineWidthPlus: q.typeHidden === 'scatter' ? 0 : q.lineWidth || 2,
                    }
                }

                if (attributes?.showTooltip === 'noTooltip') {
                    q.states = {
                        ...q.states,
                        inactive: {
                            opacity: 1
                        }
                    }
                }
                chartOptions.series[index] = q;
                this.setState({ chartOptions, loading: false })


            })
            .catch((error) => {
                // handle error
                console.log(error);


            })
            .then(function () {
                // always executed
            });




    }
    queryStationData = (q, queryInfo) => {



        const { chartOptions, series, userInfo, attributes, disableTooltip } = this.state;



        this.setState({ queryInfo })
        const proxyUrl = "https://mycorslake.herokuapp.com/";

        const customBucket = `SELECT *
        FROM tide_station_data_new
        WHERE streamid = '${q.location.id}'
        and time >= '${queryInfo.start}' 
        and time <= '${queryInfo.end}' 
        ORDER BY "time" DESC;`;







        axios.post(proxyUrl + `https://us-central1-aquasource3.cloudfunctions.net/databaseFetch`, {
            raw: customBucket
        })
            .then(async (response) => {
                if (q.parameter.product === 'water_level') {

                }

                q.data = response.data.map((d) => {
                    const value = d[q.parameter.product] === null ? null : Number(d[q.parameter.product]);
                    const x = moment(d.time, 'YYYY-MM-DD HH:mm:ss').format('x');
                    return ([Number(x), value])
                });

                q.data.sort((a, b,) => a[0] - b[0])



                const index = chartOptions.series.map((s) => s.key).indexOf(q.key);

                const input = q.decimals === 0 || q.decimals ? q.decimals : 2;

                q.name = q.label || null;

                q.tooltip.valueSuffix = q.suffix === undefined ? null : ` ${q.suffix}`;
                q.tooltip.valueDecimals = input;
                q.marker.lineColor = q.markerLine || null;
                q.marker.fillColor = q.markerFill || null;
                q.dashStyle = q.dashed || null;

                q.lineWidth = q.typeHidden === 'scatter' ? 0 : q.lineWidth || 2;
                q.pointWidth = q.columnWidth || 3;
                q.opacity = q.opacity || 1;
                q.marker.enabled = q.typeHidden === 'scatter' ? true : q.dots || false;

                q.fillColor = {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                    },
                    stops: [
                        [0, Highcharts.color(q.color).setOpacity(0.2).get('rgba')],
                        [1, Highcharts.color(q.color).setOpacity(0).get('rgba')]
                    ]
                }
                q.states = {
                    ...q.states,
                    hover: {
                        lineWidthPlus: q.typeHidden === 'scatter' ? 0 : q.lineWidth || 2,
                    }
                }

                if (attributes?.showTooltip === 'noTooltip') {
                    q.states = {
                        ...q.states,
                        inactive: {
                            opacity: 1
                        }
                    }
                }

                if (disableTooltip) {
                    q.tooltip = {
                        ...q.tooltip,
                        enabled: false
                    }
                }
                chartOptions.series[index] = q;
                this.setState({ chartOptions, loading: false })


            })
            .catch((error) => {
                // handle error
                console.log(error);


            })
            .then(function () {
                // always executed
            });




    }
    queryWunderData = (q, queryInfo) => {



        const { chartOptions, series, userInfo, attributes, disableTooltip } = this.state;


        this.setState({ queryInfo })
        const proxyUrl = "https://mycorslake.herokuapp.com/";

        const customBucket = `SELECT time as "t", ${q.parameter.key} as "y"
        FROM wunderground_weather_new2
        WHERE streamid = '${q.location.id}'
        and time >= '${queryInfo.start}' 
        and time <= '${queryInfo.end}' 
        ORDER BY "t" DESC;`;

        const queryString = 'widgetsFire2';

        axios.post(proxyUrl + `https://us-central1-aquasource3.cloudfunctions.net/databaseFetch`, {
            raw: customBucket
        })
            .then(async (response) => {

                q.data = response.data.map((d) => {
                    const value = ['temperaturelow', 'temperaturehigh'].includes(q.parameter.key) ? Number(convert(d.y).from('F').to(q.units || 'F')) :
                        ['precipitation'].includes(q.parameter.key) ? Number(convert(d.y).from('in').to(q.units || 'in')) : Number(d.y);
                    const x = moment(d.t, 'YYYY-MM-DD HH:mm:ss').format('x');
                    return ([Number(x), value])
                });

                q.data.sort((a, b,) => a[0] - b[0])



                const index = chartOptions.series.map((s) => s.key).indexOf(q.key);

                const input = q.decimals === 0 || q.decimals ? q.decimals : q?.parameter?.key === 'precipitation' ? 2 : 0;

                q.name = q.label || null;

                q.tooltip.valueSuffix = q.suffix === undefined ? null : ` ${q.suffix}`;
                q.tooltip.valueDecimals = input;
                q.marker.lineColor = q.markerLine || null;
                q.marker.fillColor = q.markerFill || null;
                q.dashStyle = q.dashed || null;
                q.lineWidth = q.typeHidden === 'scatter' ? 0 : q.lineWidth || 2;
                q.pointWidth = q.columnWidth || 3;
                q.opacity = q.opacity || 1;
                q.marker.enabled = q.typeHidden === 'scatter' ? true : q.dots || false;
                q.fillColor = {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                    },
                    stops: [
                        [0, Highcharts.color(q.color).setOpacity(0.2).get('rgba')],
                        [1, Highcharts.color(q.color).setOpacity(0).get('rgba')]
                    ]
                }
                q.states = {
                    ...q.states,
                    hover: {
                        lineWidthPlus: q.typeHidden === 'scatter' ? 0 : q.lineWidth || 2,
                    }
                }

                if (attributes?.showTooltip === 'noTooltip') {
                    q.states = {
                        ...q.states,
                        inactive: {
                            opacity: 1
                        }
                    }
                }

                if (disableTooltip) {
                    q.tooltip = {
                        ...q.tooltip,
                        enabled: false
                    }
                }

                chartOptions.series[index] = q;
                this.setState({ chartOptions, loading: false })


            })
            .catch((error) => {
                // handle error



            })
            .then(function () {
                // always executed
            });
    }

    queryObscapeWeatherStationData = (q, queryInfo) => {



        const { chartOptions, series, userInfo, attributes, disableTooltip } = this.state;


        this.setState({ queryInfo })
        const proxyUrl = "https://mycorslake.herokuapp.com/";

        const customBucket = `SELECT time as "t", ${q.parameter.name} as "y"
        FROM obscape_new2
        WHERE streamid = '${q.location.id}'
        and time >= '${queryInfo.start}' 
        and time <= '${queryInfo.end}' 
        ORDER BY "t" DESC;`;

        const queryString = 'widgetsFire2';



        axios.post(proxyUrl + `https://us-central1-aquasource3.cloudfunctions.net/databaseFetch`, {
            raw: customBucket
        })
            .then(async (response) => {


                const distanceUnitsSystem = q.units || (q.location?.unitsSystem === 'US/Imperial' ? 'in' : 'mm');
                const tempUnitsSystem = q.units || (q.location?.unitsSystem === 'US/Imperial' ? 'F' : 'C');
                const speedUnitsSystem = q.units === 'mph' ? SpeedUnit.MILE_PER_HOUR : (q.location?.unitsSystem === 'US/Imperial' ? SpeedUnit.MILE_PER_HOUR : SpeedUnit.METER_PER_SECOND);

                if (['windSpeed'].includes(q.parameter.name)) {

                }

                q.data = response.data.map((d, i) => {
                    const value = ['airTemperature'].includes(q.parameter.name) ?
                        Number(convert(d.y).from('C').to(tempUnitsSystem)) :
                        ['precipitation'].includes(q.parameter.name)
                            ? Number(convert(d.y).from('mm').to(distanceUnitsSystem || 'in')) :
                            ['windSpeed'].includes(q.parameter.name)
                                ? Number(convertUnits(d.y, SpeedUnit.METER_PER_SECOND, speedUnitsSystem))
                                : Number(d.y);
                    const x = moment(d.t, 'YYYY-MM-DD HH:mm:ss').format('x');

                    return ([Number(x), value])
                });



                q.data.sort((a, b,) => a[0] - b[0])



                const index = chartOptions.series.map((s) => s.key).indexOf(q.key);

                const input = q.decimals === 0 || q.decimals ? q.decimals : q?.parameter?.key === 'precipitation' ? 2 : 0;

                q.name = q.label || null;

                q.tooltip.valueSuffix = q.suffix === undefined ? null : ` ${q.suffix}`;
                q.tooltip.valueDecimals = input;
                q.marker.lineColor = q.markerLine || null;
                q.marker.fillColor = q.markerFill || null;
                q.dashStyle = q.dashed || null;
                q.lineWidth = q.lineWidth || 2;
                q.lineWidth = q.typeHidden === 'scatter' ? 0 : q.lineWidth || 2;
                q.pointWidth = q.columnWidth || 3;
                q.opacity = q.opacity || 1;
                q.marker.enabled = q.typeHidden === 'scatter' ? true : q.dots || false;
                q.fillColor = {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                    },
                    stops: [
                        [0, Highcharts.color(q.color).setOpacity(0.2).get('rgba')],
                        [1, Highcharts.color(q.color).setOpacity(0).get('rgba')]
                    ]
                }
                q.states = {
                    ...q.states,
                    hover: {
                        lineWidthPlus: q.typeHidden === 'scatter' ? 0 : q.lineWidth || 2,
                    }
                }

                if (attributes?.showTooltip === 'noTooltip') {
                    q.states = {
                        ...q.states,
                        inactive: {
                            opacity: 1
                        }
                    }
                }

                if (disableTooltip) {
                    q.tooltip = {
                        ...q.tooltip,
                        enabled: false
                    }
                }

                chartOptions.series[index] = q;
                this.setState({ chartOptions, loading: false })


            })
            .catch((error) => {
                // handle error



            })
            .then(function () {
                // always executed
            });
    }




    getRange = async (queryInfo, series) => {
        try {
            const { chartOptions, attributes, userInfo, widgetKey } = this.state;
            const proxyUrl = "https://mycorslake.herokuapp.com/";
            /* set localStorage */
            localStorage.setItem(`queryInfo-${widgetKey}`, JSON.stringify(queryInfo));

            chartOptions.legend.enabled = attributes.legend || true;
            chartOptions.tooltip.shared = attributes.shareAxis || false;
            chartOptions.allignTicks = attributes.allignTicks || false;



            const updateAxises = attributes.axises.map((a) => {


                return (
                    {
                        ...a,
                        labels: {
                            style: {
                                fontWeight: '600',
                                fontSize: a.fontSize !== undefined ? `${a.fontSize}px` : '12px',
                                color: a.axisColor || 'black', fontFamily: 'inter, sans-serif'
                            }
                        },
                        tickAmount: a.customTicks !== true ? undefined : a.tickAmount === 0 ? undefined : a.tickAmount,
                        tickInterval: a.customTicks !== true ? undefined : a.tickInterval === 0 ? undefined : a.tickInterval,
                        minorTickInterval: a.customTicks !== true ? undefined : a.minorTickInterval === 0 ? undefined : a.minorTickInterval,
                        minorGridLineColor: a.minorGridLineColor || undefined,
                        gridLineColor: a.gridLineColor || 'black',
                        min: a.customRange === true ? a.min : a.autoRange === true ? null : 0,
                        max: a.customRange === true ? a.max : a.autoRange === true ? null : null,
                        id: a.axisId,
                        allowDecimals: false,
                        title: {
                            text: a.label,
                            style: {
                                fontWeight: '600',
                                color: a.axisColor || 'black', fontFamily: 'inter, sans-serif'
                            }


                        },
                        plotLines: [],
                        startOnTick: attributes?.allignTicks === true ? true : [a.customRange, a.customTicks].includes(true) ? true : false,
                        endOnTick: attributes?.allignTicks === true ? true : [a.customRange, a.customTicks].includes(true) ? true : false,
                        allowDecimals: true
                    }
                )
            })
            try {
                const db = getFirestore();
                const queryData = query(collection(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'references'));
                const references = [];
                const snap = await getDocs(queryData);
                snap.forEach((doc) => {
                    references.push(doc.data())
                });




                const yPlots = attributes.references.filter((r) => r.horizontal === true);
                const xPlots = attributes.references.filter((r) => r.horizontal === false);

                const updatedY = yPlots.map((f) => {
                    const ref = references.find((d) => d.key === f.reference?.key);

                    return ({
                        ...f, value: f.yValue, zIndex: 50,
                        visible: f.active === undefined ? true : f.active === false ? false : true,
                        label: {
                            text: f.useAlias ? f.alias : ref?.label, textAlign: f.textAlign, verticalAlign: f.verticalAlign,
                            style: {
                                /* show f.fontColor, and if f.fontColor does not exist show f.color and if f.color does not exist show black */
                                color: f.fontColor || f.color || 'black',
                                fontWeight: f.fontWeight || '400',
                                fontSize: f.fontSize ? `${f.fontSize}px` : '12px',


                            }
                        },

                    })
                })
                const updatedX = xPlots.filter((f) => f.active === true || f.active === undefined).map((f) => {
                    const ref = references.find((d) => d.key === f.reference?.key);

                    return ({
                        ...f,
                        value: Number(f.date), zIndex: 50,
                        width: f.active === undefined ? f.width : f.active === false ? 0 : f.width,

                        label: {
                            text: f.useAlias ? f.alias : ref?.label, textAlign: f.textAlign, verticalAlign: f.verticalAlign,
                            style: {
                                color: f.fontColor || f.color || 'black',
                                fontWeight: f.fontWeight || '400',
                                fontSize: f.fontSize ? `${f.fontSize}px` : '12px',


                            }

                        }
                    })


                })


                updatedY.map((y) => {
                    const index = updateAxises.map((d) => d.id).indexOf(y.yAxis);
                    if (y.active !== undefined && y.active === false) return;
                    updateAxises[index]?.plotLines.push(y)
                })
                updateAxises.plotLines = updatedY;
                chartOptions.xAxis.plotLines = updatedX;
                chartOptions.xAxis.maxPadding = attributes.xPadding === undefined ? 0 : attributes.xPadding === true ? 0.25 : 0;
                chartOptions.xAxis.minPadding = attributes.xPadding === undefined ? 0 : attributes.xPadding === true ? 0.25 : 0;
                chartOptions.xAxis.labels.style.fontSize = attributes.xAxisFontSize || '12px';
                chartOptions.xAxis.labels.style.color = attributes.xAxisLabelColor || 'black';
                chartOptions.xAxis.labels.style.fontFamily = 'inter, sans-serif';
                chartOptions.xAxis.lineColor = attributes.xAxisColor || 'black';
                chartOptions.xAxis.tickColor = attributes.xAxisColor || 'black';
                chartOptions.xAxis.gridLineColor = attributes.xAxisGridColor || 'black';

                chartOptions.xAxis.crosshair = {
                    ...chartOptions.xAxis.crosshair,
                    width: attributes.xLineWidth || 10,
                    color: attributes.xLineColor || '#2651d214',
                    zIndex: attributes?.crosshairZIndex || 0,
                }




                if (attributes?.showTooltip === 'noTooltip') {
                    chartOptions.tooltip = {
                        enabled: true,
                        xDateFormat: '%b %d, %Y %I:%M %p',
                        shared: true,
                        crosshairs: true,
                        outside: true,
                        useHTML: true, // Enable HTML in tooltips
                        formatter: function () {
                            // Returning an empty string or space to keep the tooltip technically "enabled"
                            return ' ';
                        },
                        style: {
                            fontFamily: 'Roboto', // Ensure the tooltip font is Roboto
                            zIndex: 500,
                            opacity: 0, // Make tooltip fully transparent
                            pointerEvents: 'none', // Prevent interaction with the tooltip
                        }
                    }
                }
                if (attributes?.showTooltip === 'timeOnly') {
                    chartOptions.tooltip = {

                        enabled: true,
                        useHTML: true,
                        positioner: function (labelWidth, labelHeight, point) {
                            // Position the tooltip at the top of the chart
                            // You might need to adjust the x and y values depending on the exact placement you need
                            return {
                                /* x should be mouse x */
                                x: point.plotX + this.chart.plotLeft - labelWidth / 2,
                                y: this.chart.plotTop
                            };
                        },
                        // Additional tooltip options as needed
                        shared: true,
                        crosshairs: true,

                        formatter: function () {
                            var s = `<b style="font-family: Roboto; font-size: 14px; font-weight: 800;">${moment(this.x).format('MMM DD, YYYY hh:mm A')}</b>`;



                            return s;
                        },
                        style: {
                            fontFamily: 'Roboto', // Ensure the tooltip font is Roboto
                            zIndex: 9999999,
                            opacity: 0,
                        }
                    }
                }
                if (attributes?.showTooltip === 'noTooltipSingleLine') {
                    chartOptions.tooltip = {
                        enabled: false,
                    }
                }



                chartOptions.yAxis = updateAxises;
                /* check that the ycross axis exists before adding the crosshair */

                if (updateAxises.map((d) => d.id).includes(attributes?.yCross)) {

                    chartOptions.yAxis[attributes?.yCross].crosshair = {
                        ...chartOptions.xAxis.crosshair,
                        width: attributes.yLineWidth || 0,
                        color: attributes.yLineColor || '#2651d214',
                        zIndex: attributes?.crosshairZIndex || 0,
                    }
                }



                this.setState({ chartOptions })




                series.map(async (q, i) => {




                    if (q.dataType === 'probe') {


                        axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/databaseFetch', {
                            raw: `
                    (SELECT time
                        FROM probe_data
                        WHERE locationid = '${q.locationid}'and parameterid = '${q.parameterid}'
                        and time >= '${queryInfo.start}' and time <= '${queryInfo.end}' 
                        ORDER BY time DESC
                        LIMIT 1)
                        
                        UNION ALL
                        
                        (SELECT time
                        FROM probe_data
                        WHERE locationid = '${q.locationid}'and parameterid = '${q.parameterid}'
                        and time >= '${queryInfo.start}' and time <= '${queryInfo.end}'  
                        ORDER BY time ASC    
                        LIMIT 1);`
                        })
                            .then(async (response) => {
                                // handle success
                                const data = response.data;

                                var now = moment(data[0].time);
                                var end = moment(data[1].time);
                                var duration = moment.duration(now.diff(end));

                                var hours = duration.asHours();
                                const seriesLength = 500;
                                const slice = Math.floor(hours / seriesLength)
                                const sliceLength = slice === 0 ? 1 : slice



                                q.interval = sliceLength;



                                this.queryData(q, queryInfo, i)



                            })
                            .catch((error) => {
                                // handle error

                            })
                            .then(function () {
                                // always executed
                            });
                    }

                    if (q.dataType === 'node') {



                        try {
                            axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/databaseFetch', {
                                raw: `
                    (SELECT time
                        FROM node_data_new2
                        WHERE nodeid = '${q.locationid}'and parameterid = '${q.parameterid}'
                        and time >= '${queryInfo.start}' and time <= '${queryInfo.end}' 
                        ORDER BY time DESC
                        LIMIT 1)
                        
                        UNION ALL
                        
                        (SELECT time
                        FROM node_data_new2
                        WHERE nodeid = '${q.locationid}'and parameterid = '${q.parameterid}'
                        and time >= '${queryInfo.start}' and time <= '${queryInfo.end}'  
                        ORDER BY time ASC    
                        LIMIT 1);`
                            })
                                .then(async (response) => {
                                    // handle success

                                    const data = response.data;



                                    var now = moment(data[0].time);
                                    var end = moment(data[1].time);
                                    var duration = moment.duration(now.diff(end));

                                    var hours = duration.asHours();
                                    const seriesLength = 500;
                                    const slice = Math.floor(hours / seriesLength)
                                    const sliceLength = slice === 0 ? 1 : slice



                                    q.interval = sliceLength;


                                    this.queryDataNode(q, queryInfo, i)




                                })
                                .catch((error) => {
                                    // handle error

                                    console.log(error)

                                })
                                .then(function () {
                                    // always executed
                                });

                        }
                        catch (error) {
                            console.log(error)
                        }
                    }


                    if (q.dataType === 'weather') {



                        axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/databaseFetch', {
                            raw: `
                    (SELECT time
                        FROM weather_data
                        WHERE account = '${userInfo.currentAccount}'
                        and time >= '${queryInfo.start}' and time <= '${queryInfo.end}' 
                        ORDER BY time DESC
                        LIMIT 1)
                        
                        UNION ALL
                        
                        (SELECT time
                        FROM weather_data
                        WHERE account = '${userInfo.currentAccount}'
                        and time >= '${queryInfo.start}' and time <= '${queryInfo.end}'  
                        ORDER BY time ASC    
                        LIMIT 1);`
                        })
                            .then(async (response) => {
                                // handle success
                                const data = response.data;

                                var now = moment(data[0].time);
                                var end = moment(data[1].time);
                                var duration = moment.duration(now.diff(end));
                                var hours = duration.asHours();
                                const seriesLength = 300;
                                const slice = await Math.floor(hours / seriesLength)
                                const sliceLength = slice === 0 ? 1 : slice

                                q.interval = sliceLength;



                                this.queryWeatherData(q, queryInfo)



                            })
                            .catch((error, item) => {
                                // handle error


                            })
                            .then(function () {
                                // always executed
                            });
                    }

                    if (q.dataType === 'station') {



                        axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/databaseFetch', {
                            raw: `
                    (SELECT time
                        FROM tide_station_data_new
                        WHERE streamid = '${q.location.id}'
                        and time >= '${queryInfo.start}' and time <= '${queryInfo.end}' 
                        ORDER BY time DESC
                        LIMIT 1)
                        
                        UNION ALL
                        
                        (SELECT time
                        FROM tide_station_data_new
                        WHERE streamid = '${q.location.id}'
                        and time >= '${queryInfo.start}' and time <= '${queryInfo.end}'  
                        ORDER BY time ASC    
                        LIMIT 1);`
                        })
                            .then(async (response) => {
                                // handle success
                                const data = response.data;

                                var now = moment(data[0].time);
                                var end = moment(data[1].time);
                                var duration = moment.duration(now.diff(end));
                                var hours = duration.asHours();
                                const seriesLength = 300;
                                const slice = await Math.floor(hours / seriesLength)
                                const sliceLength = slice === 0 ? 1 : slice

                                q.interval = sliceLength;



                                this.queryStationData(q, queryInfo)



                            })
                            .catch((error, item) => {
                                // handle error


                            })
                            .then(function () {
                                // always executed
                            });
                    }
                    if (q.dataType === 'wunder') {

                        this.queryWunderData(q, queryInfo)
                    }
                    if (q.dataType === 'obscapeWeatherStation') {

                        this.queryObscapeWeatherStationData(q, queryInfo)
                    }

                    if (q.dataType === 'sample') {




                        this.querySample(q, queryInfo)


                    }
                    if (q.dataType === 'gauge') {


                        console.log(q)


                        this.queryGauge(q, queryInfo)


                    }

                })
            }
            catch (error) {
                console.log(error)
            }

        }
        catch (error) {
            console.log(error)
        }


    }


    createShadeBar = () => {
        const chart = this.chartRef.current.chart;
        const shadeBar = chart.renderer
            .rect(0, chart.plotTop, 1, chart.plotHeight)
            .attr({
                fill: "rgba(0, 0, 0, 2)",
                zIndex: 5,
            })
            .add();

        return shadeBar;
    };



    render() {

        const { account, chartOptions, hoverData, loading, height, dimensions, isZoomed, userInfo, type } = this.state;


        const Height = () => {
            if (this.container && type === undefined) {
                if (this.container.offsetHeight !== height) {
                    this.updateChartSub('chart', 'height', this.container.offsetHeight)
                    this.setState({ height: this.container.offsetHeight })
                }

            }

        }

        if (loading) {



            return (
                <div
                    className="chart-container"
                    ref={el => (this.container = el)}
                    style={{
                        height: "95%",
                        width: "100%",
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                    }}
                >
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <FontAwesomeIcon icon={faSpinner} color="#404050" spin size="3x" />
                    </div>
                    <div style={{ marginTop: "10px" }}>
                        <Typography
                            variant="body1"
                            fontSize={13}
                            fontWeight={400}
                            style={{ color: "#404050", textAlign: "center" }}
                        >
                            Loading chart...
                        </Typography>
                    </div>
                </div>

            )
        } else {


            return (
                <div
                    className="chart-container"
                    ref={el => (this.container = el)}
                    style={{ height: "97%", width: "100%", position: "relative" }}
                >

                    <Height />
                    {chartOptions?.series.length > 0 && (<HighchartsReact
                        constructorType={"chart"}
                        highcharts={Highcharts}
                        ref={this.chartRef}
                        options={chartOptions}
                        allowChartUpdate={true}
                        immutable={false}
                        updateArgs={[true, true, true]}
                    />
                    )}
                    {chartOptions?.series.length === 0 && (
                        <div
                            style={{
                                height: "100%",
                                width: "100%",
                                position: "absolute",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                            }}
                        >
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <FontAwesomeIcon icon={faExclamationTriangle} color="#404050" size="3x" />
                            </div>
                            <div style={{ marginTop: "10px" }}>
                                <Typography
                                    variant="body1"
                                    fontSize={14}
                                    fontWeight={400}
                                    style={{ color: "#404050", textAlign: "center" }}
                                >
                                    No data series selected
                                </Typography>
                            </div>
                            <div style={{ marginTop: "10px" }}>
                                <Typography
                                    variant="caption"
                                    fontSize={12}
                                    fontWeight={400}
                                    style={{ color: "#404050", textAlign: "center" }}
                                >
                                    Go to the settings tab to add data series.
                                </Typography>
                            </div>
                        </div>
                    )
                    }

                </div>

            );
        }
    }
} 