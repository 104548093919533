import React, { useEffect, useState } from "react";
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Divider, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import DocumentsHeaders from "./documentsHeaders";
import FoldersHome from "./pages/foldersHome";
import { mergeOldFilesFunction, mergeOldFoldersFunction } from "./utils/miscFunctions";

const DocumentsHomeNew = ({
    screenWidth,
    userInfo,
    openSnack,
    publicPortal
}) => {

    console.log(publicPortal)


    const [activeTab, setActiveTab] = useState('folders');
    const [loading, setLoading] = useState(true);
    const [preferences, setPreferences] = useState({});
    const [companyPreferences, setCompanyPreferences] = useState({});


    async function mergeOldFiles() {
        const mergeFolders = await mergeOldFoldersFunction(userInfo, openSnack);
        const mergeFiles = await mergeOldFilesFunction(userInfo, openSnack);
        console.log(mergeFolders)
        console.log(mergeFiles)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        getInfo()

    }, [])



    async function getInfo() {

        setTimeout(() => {
            setLoading(false)
        }, 1000);

    }

    const props = {
        screenWidth,
        userInfo,
        openSnack,
        preferences,
        setPreferences,
        companyPreferences,
        setCompanyPreferences,
        activeTab,
        setActiveTab,
        publicPortal


    }




    if (loading) {
        return (
            <Row style={{ height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
                {/* Using the full height of the viewport to center content */}
                <Col span={24} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

                    {/* FontAwesome Spinner */}
                    <FontAwesomeIcon icon={faSpinner} spin size="3x" />

                    <Typography variant="h6" style={{ marginTop: 20 }}>
                        Loading your files...
                    </Typography>

                </Col>
            </Row>
        )
    }






    else {
        return (
            <Row style={{}} >
                <Col span={24} style={{ backgroundColor: 'white'}}>
                    <Row align="middle">
                        {/* <Col xs={16} sm={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>
                            <Button onClick={mergeOldFiles}>Merge Old Photos</Button>
                        </Col> */}
                        <Col xs={16} sm={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>
                            <Typography variant="h4" fontWeight={600} fontSize={30} color="black">Files</Typography>
                        </Col>

                        <Col span={24}>
                            <Divider />
                        </Col>

                       {/*  <Col span={24} >
                            <DocumentsHeaders {...props}

                            />
                        </Col> */}


                        {activeTab === 'folders' && (
                            <Col span={24} style={{ backgroundColor: 'white'}} >
                                <DndProvider backend={HTML5Backend}>
                                    <FoldersHome {...props} />
                                </DndProvider>
                            </Col>
                        )}








                    </Row>
                </Col>

            </Row >
        );
    }

};

export default DocumentsHomeNew;
