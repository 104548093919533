
import { Box, Card, Grid, Paper, Typography, Button, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import ItemCreator from "./itemCreator";
import ItemCreatorModal from "./itemCreatorModal";



const ItemPicker = (
    {
        record,
        items,
        setItems,
        screenWidth,
        openSnack,
        userInfo,
        updateRecord
    }
) => {
    const [itemModal, setItemModal] = React.useState(false);

    const [createItemModal, setCreateItemModal] = useState(false);

    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: (option) => option.label,
    });



    const sortedItemsByType = items.sort((a, b) => {
        if (a.psType < b.psType) {
            return 1;
        }
        if (a.psType > b.psType) {
            return -1;
        }
        return 0;
    })



    return (
        <>


            <Autocomplete
                size="small"
                id="combo-box-demo"
                value={sortedItemsByType.find(x => (x.id || x.key) === record.item)?.name || ''}
                disableClearable={false}
                options={sortedItemsByType}
                groupBy={(option) => option.psType || ""}
                renderGroup={(params) => {
                    return (
                        <Box key={params.key}>
                            <Typography fontWeight={800} fontSize={16} p={1}>
                                {params.group === 'inspection' ? 'Templates' :
                                    params.group === 'products' ? 'Products' :
                                        params.group === 'other' ? null :
                                            params.group === 'none' ? null :
                                                'Services'
                                }
                            </Typography>
                            {React.Children.map(params.children, (child, index) => {
                                return (
                                    <Box style={{
                                        borderBottom: '1px solid #e0e0e0'
                                    }}>
                                        {child}
                                    </Box>
                                )
                            })}
                        </Box>
                    )
                }
                }

                filterOptions={(options, _ref) => [...filterOptions(options, _ref), { label: 'Add New', key: 'Add New', value: 'addNew', psType: 'none' },]}
                noOptionsText={<ItemCreator
                    items={items}
                    setItems={setItems}
                    screenWidth={screenWidth}
                    setCreateItemModal={setCreateItemModal}
                />}
                style={{ backgroundColor: 'white' }}
                renderInput={(params) => <TextField
                    {...params}
                    placeholder="Product/Services"
                    InputProps={{
                        ...params.InputProps,
                        style: { fontWeight: 'bold', fontSize: 16 }, // Apply bold style to the input text
                    }}
                />}
                renderOption={(props, option, state) => {
                    const isAddNewItem = option.key === 'Add New';
                    /* alternate background color every other item */
                    /* props.id is combo-box-demo-option-0 and i just want the number after option- */
                    const index = props.id.split('option-')[1];
 

                    return (
                        <Box
                            {...props}
                            sx={{
                                borderBottom: '1px solid #ccc',
                                backgroundColor: index % 2 === 0 ? 'E7E7E7' : 'white',
                                '&:hover': {
                                    backgroundColor: '#f5f5f5',
                                },
                            }}

                        >
                            {isAddNewItem ? (
                                <ItemCreator
                                    items={items}
                                    setItems={setItems}
                                    screenWidth={screenWidth}
                                    setCreateItemModal={setCreateItemModal}
                                />
                            ) : (
                                <Typography>{option.label}</Typography>
                            )}
                        </Box>
                    );
                }}
                onChange={(a, d) => {
                    console.log(d)
                    const newRecord = {
                        ...record,
                        item: d?.id || d?.key || null,
                        psType: d?.psType || null,
                        subType: d?.productType?.label || d?.serviceType?.label || d?.subType,
                        itemDescription: d?.description || null,
                        chemAI: d?.chemAI || null,
                        chemEPA: d?.chemEPA || null,
                        chemState: d?.chemState || null,
                        chemicalType: d?.chemicalType || null,
                        units: d?.defaultUnits || null,


                    }
                    updateRecord(newRecord)


                }}

            />
            <ItemCreatorModal
                createItemModal={createItemModal}
                setCreateItemModal={setCreateItemModal}
                screenWidth={screenWidth}
                openSnack={openSnack}
                userInfo={userInfo}
                items={items}
                setItems={setItems}
                updateRecord={updateRecord}
                record={record}
            />



        </>



    )
}

export default ItemPicker;