import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PublicIcon from '@mui/icons-material/Public';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Divider, IconButton, InputAdornment, OutlinedInput, Typography } from '@mui/material';
import { Col, Drawer, Modal, Popconfirm, Row } from 'antd';
import { collection, deleteDoc, doc, getDocs, getFirestore, query, where } from 'firebase/firestore';
import React from 'react';
import { ReactSortable } from "react-sortablejs";
import uuid from 'react-uuid';
import '../../../extra/hoverStyle.css';
import ChemProduct from './chemProduct';
import GraphicSpatialList from './graphicSpatialList';
import MapDrawer from './mapDrawer';




export default class ServicesProducts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            openItems: false,
            item: this.props.item,
            lineItems: this.props.item.lineItems || [],
            searchText: '',
            data: [],
            filteredData: [],
            openDrawer: false,
            currentLineItem: {},
            printScreen: this.props.printScreen,
            getScreen: true,
            printingScreen: false,
            preview: this.props.preview,

        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
        if (this.props.item !== prevProps.item) {
            this.setState({ item: this.props.item })
        }
        if (this.props.printScreen !== prevProps.printScreen) {
            this.setState({ printScreen: this.props.printScreen })
        }
        if (this.props.preview !== prevProps.preview) {
            this.setState({ preview: this.props.preview })
        }

    }
    componentDidMount() {
        const { userInfo, lineItems } = this.state;
        this.queryData()
    }

    queryData = async () => {
        const { userInfo, type } = this.state;
        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'psItems'), where('archived', '==', false));
        const data = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            data.push({
                archived: doc.data().archived,
                description: doc.data().description || '',
                name: doc.data().name || '',
                key: doc.data().key || '',
                psType: doc.data().psType || '',
                inScope: doc.data().inScope || '',
                productType: doc.data().productType || '',
                serviceType: doc.data().serviceType || '',
                units: doc.data().units || '',
                visible: true
            })


        });
        data.sort((a, b) => a.name.localeCompare(b.name))
        this.filterData(data, '')
        this.setState({ data, loading: false })


    }

    filterData = (data, searchText,) => {
        const filteredData = data.filter(d => `${d.name}${d.description || ""}`.match(new RegExp(searchText, "i")))
        this.setState({ filteredData })

    }

    updateLineItem = (index, boo, val) => {
        const { lineItems } = this.state;
        lineItems[index][boo] = val;
        this.setState({ lineItems: [...lineItems] })
        this.props.updateItem('lineItems', lineItems)
    }

    updateChemItem = (key, boo, val) => {
        const { lineItems } = this.state;
        const index = lineItems.map((d) => d.key).indexOf(key);
        lineItems[index][boo] = val;
        this.setState({ lineItems: [...lineItems] })
        this.props.updateItem('lineItems', lineItems)

    }
    deleteThumb = (index,) => {
        const { lineItems } = this.state;
        delete lineItems[index].imageThumb;
        lineItems[index].graphics = "[]";
        this.setState({ lineItems: [...lineItems] })
        this.props.updateItem('lineItems', lineItems)
    }
    addGraphics = (val, key) => {
        console.log(JSON.parse(val))
        const { lineItems } = this.state;
        const index = lineItems.map((d) => d.key).indexOf(key);
        lineItems[index].graphics = val;

        this.setState({ lineItems: [...lineItems] })
        this.props.updateItem('lineItems', [...lineItems])
    }

    sendImage = (downloadURL, key, dataUrl) => {
        console.log(downloadURL)
        const { lineItems } = this.state;
        const index = lineItems.map((d) => d.key).indexOf(key);
        this.updateLineItem(index, 'imageThumb', { fileLink: downloadURL, })
        this.setState({ printingScreen: false })
        setTimeout(() => {
            this.setState({ openDrawer: false, printScreen: false, currentLineItem: {} })
        }, 250);
    }


    render() {

        const { screenWidth, openItems, data, searchText, filteredData, lineItems, userInfo, currentLineItem, openDrawer, account, item, printScreen, printingScreen, preview } = this.state;



        const RowItem = ({ row }) => {
            return (
                <Col span={24} style={{ cursor: 'pointer', padding: 8 }} className="rowHover"
                >
                    <Row gutter={[8, 8]} align="middle" >
                        <Col xs={24} sm={20} onClick={async () => {
                            const newItem = {
                                ...row,
                                dosageRateUnits: 'ppm',
                                account: userInfo.currentAccount,
                                company: userInfo.currentCompany,
                                itemType: 'useProduct',
                                archived: false,
                                layerColor: {
                                    r: '245',
                                    g: '161',
                                    b: '39',
                                    a: '0.5',
                                },
                                logKey: item.key || item.tempKey,
                                itemKey: row.key,
                                key: uuid()
                            };

                            lineItems.push(newItem)
                            this.props.updateItem('lineItems', lineItems)
                            this.setState({ lineItems });


                        }}>
                            <Typography fontWeight={500} variant='subtitle1'>{row.name}</Typography>  <Typography variant='body2'>{row.description}</Typography>
                        </Col>
                        <Col span={24}>
                            <Divider />
                        </Col>

                    </Row>
                </Col>
            )
        }




        return (
            <Row >
                <Col span={24} style={{ backgroundColor: 'rgb(236, 240, 243)', padding: 18 }}>
                    <Row align="middle" gutter={[8, 8]} >

                        <Col xs={24} sm={14}>
                            <Typography variant='h5'>Services & Products</Typography>
                        </Col>
                        <Col xs={0} sm={3}>
                            {/*    <Typography variant='body1'>Quantity</Typography> */}
                        </Col>
                        <Col xs={0} sm={3}>
                            {/*   <Typography variant='body1'>Price</Typography> */}
                        </Col>
                        <Col xs={0} sm={3}>
                            {/*   <Typography variant='body1'>Amount</Typography> */}
                        </Col>

                    </Row>

                </Col>




                {lineItems.length > 0 && (<Col span={24}  >
                    <Row >
                        <Col span={24}  >
                            <ReactSortable
                                handle=".my-handle"
                                animation={500}
                                list={lineItems}
                                setList={(newState) => this.setState({ lineItems: newState })}
                            >
                                {lineItems.map((l, i) => {
                                    const price = l.price || 0;
                                    const quantity = l.quantity || 1;
                                    const amount = price * quantity;
                                    const getUnits = l.units === undefined ? '' : l.units.label || '';
                                    const units = getUnits === 'Gallons' ? "gal" : getUnits === 'Quarts' ? "qt" : getUnits === 'Pounds' ? "lb" : getUnits === 'Ounces' ? "oz" : getUnits;

                                    return (
                                        <Row >
                                            <Col span={24} style={{ padding: 18 }} className={preview ? null : 'rowHover'}>
                                                <Row align="middle" gutter={[12, 12]} >
                                                    {preview ? <Col xs={2} sm={1}></Col> : <Col xs={2} sm={1} className="my-handle">
                                                        <DragIndicatorIcon />
                                                    </Col>}
                                                    <Col xs={20} sm={9}>
                                                        <Typography variant='h6'>{l.name}</Typography>

                                                        <Typography fontSize={12} variant='body2'><b>Type:</b> {l.psType === 'services' ? "Service" : "Product"} |  <b>Category:</b> {l.psType === 'services' ? l.serviceType !== undefined ? l.serviceType.label : "" : l.productType !== undefined ? l.productType.label : ""}</Typography>



                                                        {preview ?
                                                            <Typography fontSize={12} variant='body2'>{l.description}</Typography> :
                                                            <OutlinedInput fullWidth notched={false} label="none" placeholder={"Description"} size="small"
                                                                multiline={true}
                                                                rows={4}
                                                                value={l.description}
                                                                style={{ fontSize: 13 }}
                                                                onChange={(e) => {
                                                                    this.updateLineItem(i, `description`, e.target.value)
                                                                }} />}
                                                    </Col>

                                                    {preview ?

                                                        <Col xs={{ offset: 2, span: 20 }} sm={{ offset: 0, span: 4 }}>
                                                            {l.imageThumb === undefined ?
                                                                null

                                                                :
                                                                <Row align="middle" gutter={[8, 8]}>
                                                                    <Col span={24}>

                                                                        <img src={l.imageThumb.fileLink} id={`${i}`} style={{ width: '100%', borderRadius: 6 }} />

                                                                    </Col>


                                                                </Row>


                                                            }

                                                        </Col>
                                                        :

                                                        <Col xs={{ offset: 2, span: 20 }} sm={{ offset: 0, span: 6 }}>
                                                            {l.imageThumb === undefined ?
                                                                <Button variant="link" color="primary" fullWidth style={{ padding: 10, textTransform: 'none', position: 'relative', bottom: 0 }}
                                                                    onClick={() => {
                                                                        this.setState({ currentLineItem: l, openDrawer: true, printingScreen: false })
                                                                    }}>
                                                                    <div>
                                                                        Click to Add Map
                                                                        <br />
                                                                        <Typography variant="body2">
                                                                            <PublicIcon />
                                                                        </Typography>
                                                                    </div>
                                                                </Button>

                                                                :
                                                                <Row align="middle" gutter={[8, 8]}>
                                                                    <Col span={24}>
                                                                        <Button variant="link" color="primary" fullWidth
                                                                            style={{ width: '100%', padding: 0, textTransform: 'none', position: 'relative', bottom: 0 }}
                                                                            onClick={() => {
                                                                                this.setState({ currentLineItem: l, openDrawer: true, printingScreen: false })
                                                                            }}>
                                                                            <img src={l.imageThumb.fileLink} style={{ width: '100%', borderRadius: 6 }} />
                                                                        </Button>
                                                                    </Col>
                                                                    <Col span={24}>
                                                                        <Button size="small" variant='contained' color="secondary" fullWidth style={{ padding: 6, textTransform: 'none' }}
                                                                            onClick={() => {
                                                                                this.deleteThumb(i)
                                                                            }}
                                                                        >
                                                                            Remove
                                                                        </Button>
                                                                    </Col>




                                                                </Row>


                                                            }

                                                        </Col>


                                                    }


                                                    <Col xs={{ offset: 2, span: 20 }} sm={{ offset: 0, span: 6 }}>

                                                        <Row align="middle">
                                                            <Col span={l.psType === 'services' ? 0 : 24}>
                                                                <Typography fontSize={13} variant="body1">Quantity</Typography>
                                                            </Col>
                                                            <Col span={l.psType === 'services' ? 0 : 24}>
                                                                {preview ?
                                                                    <Typography variant='body2'>{l.quantity || '0'} {units}</Typography> :
                                                                    <OutlinedInput notched={false} label="none" placeholder={"qty"} size="small"
                                                                        type="number"
                                                                        endAdornment={
                                                                            <InputAdornment position="end">
                                                                                <Typography variant='body2' fontSize={11}>{units}</Typography>
                                                                            </InputAdornment>
                                                                        }

                                                                        inputProps={{ min: 0 }}
                                                                        value={l.quantity}
                                                                        onChange={(e) => {
                                                                            this.updateLineItem(i, `quantity`, e.target.value === "" ? "" : Number(e.target.value))
                                                                        }} />}
                                                            </Col>
                                                            <Col span={24} style={{ paddingTop: 12 }}>
                                                                <Typography fontSize={13} variant="body1">In-Scope</Typography>
                                                            </Col>
                                                            <Col span={24}>
                                                                {preview ?
                                                                    <Typography variant='body2'>{l.inScope === true ? "In-Scope" : 'Out of Scope'}</Typography>

                                                                    :

                                                                    <Button size="small" variant={l.inScope === true ? 'contained' : 'outlined'} color={l.inScope === true ? 'primary' : 'secondary'} fullWidth style={{ padding: 6, textTransform: 'none' }}
                                                                        onClick={() => {
                                                                            this.updateLineItem(i, `inScope`, !l.inScope)
                                                                        }}
                                                                    >
                                                                        {l.inScope === true ? 'In Scope' : 'Out of Scope'}
                                                                    </Button>

                                                                }
                                                            </Col>
                                                        </Row>



                                                    </Col>






                                                    {l.productType && l.productType.label === 'Chemical' && (
                                                        <Col xs={{ offset: 2, span: 20 }} sm={{ offset: 1, span: 21 }} >
                                                            <ChemProduct preview={preview} lineItem={l} screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} updateChemItem={this.updateChemItem} index={i} />
                                                        </Col>

                                                    )}
                                                    {l.productType && l.productType.label === 'Bacterial' && (
                                                        <Col xs={{ offset: 2, span: 20 }} sm={{ offset: 1, span: 21 }} >
                                                            <ChemProduct preview={preview} lineItem={l} screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} updateChemItem={this.updateChemItem} index={i} />
                                                        </Col>

                                                    )}
                                                    {l.graphics !== undefined && (
                                                        <Col xs={{ offset: 2, span: 20 }} sm={{ offset: 1, span: 21 }} >
                                                            <GraphicSpatialList lineItem={l}
                                                                graphics={l.graphics !== undefined ? JSON.parse(l.graphics) : []} screenWidth={screenWidth} />
                                                        </Col>

                                                    )}

                                                    <Col xs={{ offset: 2, span: 4 }} sm={{ offset: 2, span: 4 }} style={{ textAlign: 'left' }}>
                                                        {!preview && (<Popconfirm
                                                            title="Are you sure to delete this item?"
                                                            onConfirm={async () => {
                                                                const db = getFirestore();
                                                                await deleteDoc(doc(db, "clients", userInfo.currentCompany, 'flItems', l.key)).then(() => {
                                                                    const newLines = lineItems.filter((f) => f.key !== l.key);
                                                                    this.setState({ lineItems: newLines })
                                                                    this.props.updateItem('lineItems', newLines)
                                                                })



                                                            }}
                                                            okText="Yes"
                                                            cancelText="No"
                                                        >
                                                            <IconButton style={{ textTransform: 'none', position: 'relative', zIndex: 5 }} fullWidth variant="contained" color="primary" size="small"
                                                            >
                                                                <DeleteForeverIcon />
                                                            </IconButton>
                                                        </Popconfirm>)}
                                                    </Col>









                                                </Row>

                                            </Col>
                                            <Col xs={24} >
                                                <Divider style={{
                                                    borderColor: 'rgb(148, 186, 195)',
                                                    borderBottomWidth: 'small',
                                                    paddingTop: '0px'
                                                }} />
                                            </Col>

                                        </Row>
                                    )
                                })}
                            </ReactSortable>

                        </Col>
                    </Row>
                </Col >)
                }

                {!preview && (<Col span={24} style={{ padding: 24 }} className='rowHover' >
                    <Row align="middle" gutter={[8, 8]} style={{ cursor: 'pointer' }} onClick={() => this.setState({ openItems: true })}>
                        <Col xs={24} style={{ textAlign: screenWidth === 'xs' ? 'center' : 'left', }} >
                            <Typography variant='h6' color="rgb(25, 118, 210)">Click to Add Service/Product</Typography>
                        </Col>
                    </Row>
                </Col>)}









                <Drawer
                    width={screenWidth === 'xs' ? '90%' : '75%'}
                    visible={openDrawer}
                    onClose={() => {
                        const index = item.lineItems.map((d) => d.key).indexOf(currentLineItem.key);
                        if ([undefined, "[]"].includes(item.lineItems[index].graphics)) {
                            this.setState({ openDrawer: false, currentLineItem: {} })
                        }
                        else {
                            this.setState({ printScreen: true, printingScreen: false, })
                        }
                    }}

                >

                    {Object.values(currentLineItem).length > 0 && (
                        <MapDrawer item={currentLineItem} printScreen={printScreen} sendImage={this.sendImage} printingScreen={printingScreen}
                            account={account} addGraphics={this.addGraphics}
                            screenWidth={screenWidth}
                            userInfo={userInfo} closeDrawer={() => this.setState({ openDrawer: false })} logKey={item.key || item.tempKey} />
                    )}
                </Drawer>















                <Modal title="" visible={openItems} onCancel={() => this.setState({ openItems: false })} footer={null} width={'80%'} style={{ borderRadius: 40 }} >
                    <Row align="middle" >
                        <Col xs={24} style={{ paddingBottom: 12 }}>
                            <Typography variant='h5'>Services & Products</Typography>
                        </Col>

                        <Col xs={24} style={{ paddingBottom: 12 }}>
                            <OutlinedInput fullWidth
                                autoComplete='off'
                                notched={false} label="none"
                                value={searchText}
                                onChange={(e) => {
                                    this.setState({ searchText: e.target.value })
                                    if (e.target.value.length > 0) {
                                        this.filterData(data, e.target.value,)
                                    }
                                    else {
                                        this.filterData(data, '',)
                                    }
                                }}
                                id="input-with-icon-adornment"
                                startAdornment={
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                }
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            edge="end"
                                            onClick={() => {
                                                this.setState({ searchText: '' })
                                                this.filterData(data, '',)
                                            }}
                                        >
                                            <HighlightOffIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </Col>
                        <Col span={24}>
                            <Row>
                                {filteredData.filter((f) => !lineItems.map((d) => d.itemKey).includes(f.key)).map((d) => {

                                    return (
                                        <RowItem row={d} />
                                    )

                                })}
                            </Row>

                        </Col>

                    </Row>
                </Modal>

            </Row >
        );
    }
} 