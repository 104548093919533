import React, { useEffect, useState } from "react";


import { TextField, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import "react-datepicker/dist/react-datepicker.css";
import { faCheckCircle, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fetchCameras } from "../../../timelapse/firestore";



const CameraSelect = (
    {
        currentStream,
        setCurrentStream,
        headerKey,



    }

) => {

    const [value, setValue] = React.useState(undefined);
    const [loadingCameras, setLoadingCameras] = useState(true);
    const [cameras, setCameras] = useState([]);

    useEffect(() => {
        console.log('newProbe')
        setValue(currentStream?.[headerKey]);
    }, [currentStream]);




    return (

        <Autocomplete
            fullWidth
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            size="small"
            notched={false}
            noOptionsText={
                loadingCameras ? (
                    <Typography variant="h6" fontSize={14} style={{ display: 'inline', }}>
                        Loading Cameras...
                        <FontAwesomeIcon icon={faSpinner} spin style={{ color: 'gray' }} />
                    </Typography>
                ) : (
                    "No options, double check your credentials"
                )
            }
            renderOption={(props, option, { selected }) => (
                <div {...props}>
                    {loadingCameras ? (
                        <Typography variant="h6" fontSize={14} style={{ display: 'inline', }}>
                            Loading Cameras...
                            <FontAwesomeIcon icon={faSpinner} spin style={{ color: 'gray' }} />
                        </Typography>
                    ) : (
                        option.label
                    )}
                </div>
            )}
            onOpen={async () => {
                setCameras([]);
                setLoadingCameras(true);
                const cameras = await fetchCameras(currentStream);
                console.log(cameras);
                cameras.forEach(camera => {
                    camera.label = camera?.config?.name;
                });
                setCameras(cameras);
                setLoadingCameras(false);
            }}




            options={cameras?.sort((a, b) => {
                // Convert to strings and lowercase
                const aValue = String(a.label).toLowerCase();
                const bValue = String(b.label).toLowerCase();

                // Perform the sorting
                if (aValue < bValue) return -1;
                if (aValue > bValue) return 1;
                return 0;
            })}
            value={value}
            hiddenLabel={true}


            renderInput={(params) => <TextField {...params} placeholder="Camera" variant="outlined"
                sx={{
                    '& .MuiInputBase-root': {
                        color: 'black',
                        fontWeight: 500,
                        fontSize: 14,
                    },

                }} />}
            onChange={(e, b) => {
                setValue(b)
                setCurrentStream({
                    ...currentStream,
                    [headerKey]: {
                        cameraId: b?.id,
                        id: b?.id,
                        name: b?.config?.name,
                        ucid: b?.ucid,
                    }

                })

                console.log({
                    ...currentStream,
                    [headerKey]: b,
                    hydroID: Number(b?.id),
                    hydroName: b?.name
                })
            }
            }

        />


    );

};

export default CameraSelect;
