import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, Button, Divider, Fab, IconButton, Input, InputAdornment, OutlinedInput, Paper, TextField, Typography } from '@mui/material';
import { Col, Modal, Popconfirm, Row } from 'antd';
import { collection, doc, getDocs, getFirestore, query, setDoc, updateDoc, where } from 'firebase/firestore';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import "../../extra/hoverStyle.css";

export default class ProductStocks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            data: [],
            filteredData: [],
            filters: [],
            searchText: '',
            loading: true,
            type: this.props.type,
            currentItem: {},
            openModal: false,
            accounts: []
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0)
        this.queryData()
        this.queryAccounts()
    }

    queryData = async () => {
        const { userInfo, type } = this.state;
        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'productStock'), where('archived', '==', false));
        const data = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            data.push(doc.data())


        });
        console.log(data);
        this.setState({ data, loading: false })

        this.filterData(data, '', [])
    }

    filterData = (data, searchText, filters) => {
        const filteredData = data.filter(d => d.name.match(new RegExp(searchText, "i")))
        this.setState({ filteredData })

    }

    updateCurrentItem = (boo, val) => {
        const { currentItem } = this.state;
        currentItem[boo] = val;
        this.setState({ currentItem })
    }

    queryAccounts = async () => {
        const { userInfo } = this.state;
        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'accounts'));
        const accounts = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            const account = { ...doc.data(), label: doc.data().accountName };
            delete account.positions;
            delete account.users;
            accounts.push(account)


        });
        accounts.sort((a, b) => a.accountName.localeCompare(b.accountName))
        this.setState({ accounts, accountsLoad: false })


    }

    render() {

        const { userInfo, account, data, filteredData, loading, searchText, filters, type, currentItem, accounts, openModal, screenWidth } = this.state;
        const db = getFirestore();
        const CreateItemButton = () => {
            let navigate = useNavigate();

            return (
                <Fab style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="medium"
                    onClick={async () => {
                        this.setState({ openModal: true, currentItem: { tempKey: uuid() } })
                    }}>
                    <AddIcon />
                </Fab>
            )
        }
        const EditItem = ({ item }) => {
            let navigate = useNavigate();
            return (
                <IconButton style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="small"
                    onClick={async () => {
                        const itemRef = doc(db, "users", userInfo.id, 'currentItems', 'currentPS');
                        await setDoc(itemRef, {
                            key: item.key
                        }).then(() => {
                            navigate('/stockHome')
                        })
                    }}>
                    <EditIcon />
                </IconButton>
            )
        }

        const DeleteItem = ({ item }) => {
            let navigate = useNavigate();
            return (
                <Popconfirm
                    title="Are you sure to delete this stock?"
                    onConfirm={async () => {
                        const itemRef = doc(db, "clients", userInfo.currentCompany, 'productStock', item.key);
                        await updateDoc(itemRef, {
                            archived: true
                        }).then(() => {
                            const newData = filteredData.filter((f) => f.key !== item.key)
                            this.setState({ filteredData: newData })
                            this.props.openSnack('success', 'Stock removed.')
                        })

                    }}
                    okText="Yes"
                    cancelText="No"
                >
                    <IconButton style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="small"
                    >
                        <DeleteForeverIcon />
                    </IconButton>
                </Popconfirm>


            )
        }

        const RowItem = ({ item }) => {
            let navigate = useNavigate();
            return (
                <Col span={24} style={{ cursor: 'pointer', padding: 8 }} className="rowHover"
                >
                    <Row gutter={[8, 8]} align="middle" >

                        <Col xs={14} sm={20} onClick={async () => {
                           
                        }}>
                            <Typography variant='h6'>{item.name}</Typography>  <Typography variant='bod1'>{item.description}</Typography>
                        </Col>
                        <Col xs={10} sm={4} style={{ textAlign: 'right' }}>
                            <EditItem item={item} /> <DeleteItem item={item} />
                        </Col>


                        <Col span={24}>
                            <Divider />
                        </Col>

                    </Row>
                </Col>
            )
        }

        const CreateStock = () => {
            return (
                <Button size="small" style={{ padding: 10, borderRadius: 4, textTransform: 'none', fontSize: 14, }}
                    variant='contained' color="primary"
                    onClick={async () => {
                        const { currentItem } = this.state;
                        console.log(currentItem)
                        const key = currentItem.key || currentItem.tempKey;
                        currentItem.archived = currentItem.archived || false;
                        currentItem.key = key;
                        const docRef = doc(db, "clients", userInfo.currentCompany, 'productStock', key);


                        const checks = [
                            currentItem.name.length === 0 ? false : true,
                            currentItem.location === undefined ? false : currentItem.location === null ? false : true
                        ]

                        if (currentItem.name.length === 0) {
                            this.props.openSnack('error', "Log number required!")
                        }
                        if ([undefined, null].includes(currentItem.location)) {
                            this.props.openSnack('error', "Location staff is required!")
                        }

                        if (!checks.includes(false)) {

                            if (currentItem.tempKey === undefined) {
                                await updateDoc(docRef, currentItem).then(() => {
                                    this.setState({ openModal: false })
                                    this.props.openSnack('success', 'Stock updated!')
                                })
                            } else {
                                console.log("ADD STOCK")
                                delete currentItem.tempKey;
                                await setDoc(docRef, currentItem).then(() => {
                                    filteredData.push(currentItem)
                                    this.setState({ filteredData, openModal: false })
                                }).then(() => {
                                    this.setState({ currentItem: {} })
                                    this.props.openSnack('success', currentItem.key ? 'Stock updated!' : "Stock created!")
                                })
                            }
                        }




                    }}>{currentItem.key ? 'Update Stock' : "Create Stock"}</Button>
            )
        }

        return ( 
            <Row style={{}} align="middle">

                <Col span={23}>
                    <Row align="middle">
                        <Col xs={16} sm={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>
                            <Typography variant="h4">Your Product Stocks</Typography>
                        </Col>
                        <Col xs={6} sm={4} style={{ paddingTop: 30, paddingBottom: 15, textAlign: 'right' }}>
                            <CreateItemButton />
                        </Col>
                        <Col span={24}>
                            <Divider />
                        </Col>
                    </Row>
                </Col>



                <Col span={23} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 30 }}>
                    <Paper elevation={16} style={{ padding: 20, borderRadius: 12 }}>
                        <Row>
                            <Col xs={24} sm={12} style={{ paddingBottom: 8 }}>
                                <Input fullWidth
                                    autoComplete='off'
                                    value={searchText}
                                    onChange={(e) => {
                                        this.setState({ searchText: e.target.value })
                                        if (e.target.value.length > 1) {
                                            this.filterData(data, e.target.value, filters)
                                        }
                                        else {
                                            this.filterData(data, '', filters)
                                        }
                                    }}
                                    id="input-with-icon-adornment"
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    }
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                onClick={() => {
                                                    this.setState({ searchText: '' })
                                                    this.filterData(data, '', filters)
                                                }}
                                            >
                                                <HighlightOffIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </Col>
                            <Col xs={0} sm={12} style={{ textAlign: 'right' }}>
                                <Typography variant='h6'>Actions</Typography>
                            </Col>

                            <Col span={24}>
                                <Row align='middle' >
                                    {filteredData.map((d) => {
                                        return (
                                            <RowItem item={d} />

                                        )

                                    })}
                                </Row>
                            </Col>
                        </Row>
                    </Paper>
                </Col>

                <Modal title="" visible={openModal} onCancel={() => this.setState({ openModal: false, currentItem: {} })} footer={null} width={'50%'} style={{ borderRadius: 40 }} >
                    <Row align="middle" gutter={[16, 16]} >
                        <Col xs={{ offset: 0, span: 24 }} sm={{ offset: 6, span: 18 }} style={{ paddingBottom: 12, }}>
                            <Typography variant='h5'>Stock</Typography>
                        </Col>


                        <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                            <Typography variant="body1">Stock Name<span style={{ color: 'red' }}>*</span></Typography>
                        </Col>
                        <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                            <OutlinedInput fullWidth notched={false} label="none" placeholder={"Name"} size="small"
                                value={currentItem.name || ''}
                                onChange={(e) => {
                                    this.updateCurrentItem(`name`, e.target.value)
                                }} />
                        </Col>
                        <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                            <Typography variant="body1">Description</Typography>
                        </Col>
                        <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                            <OutlinedInput fullWidth notched={false} label="none" placeholder={"Description"} size="small"
                                multiline={true}
                                rows={4}
                                value={currentItem.description || ''}
                                onChange={(e) => {
                                    this.updateCurrentItem(`description`, e.target.value)
                                }} />
                        </Col>
                        <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                            <Typography variant="body1">Location<span style={{ color: 'red' }}>*</span></Typography>
                        </Col>
                        <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                            <Autocomplete
                                size="small"
                                disablePortal
                                id="combo-box-demo"
                                value={currentItem.location || ''}
                                options={accounts}
                                renderInput={(params) => <TextField {...params} label="Location" />}
                                onChange={(a, b) => {
                                    this.updateCurrentItem(`location`, b)
                                }}

                            />
                        </Col>

                        <Col xs={{ offset: 0, span: 24 }} sm={{ offset: 6, span: 18 }} style={{ paddingBottom: 12, }}>
                            <CreateStock />
                        </Col>

                    </Row>
                </Modal>




            </Row>
        );
    }
} 