import { Button, ButtonGroup, CircularProgress, Divider, Typography, OutlinedInput, Autocomplete, TextField, Select, MenuItem, IconButton } from '@mui/material';
import { Col, Modal, Row } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, } from 'firebase/firestore';

import AddIcon from '@mui/icons-material/Add';
import { useNavigate, useParams } from 'react-router-dom';
import uuid from 'react-uuid';
import { fullMobile } from '../stations/util';
import 'react-data-grid/lib/styles.css';
import DataGrid, { SelectColumn, textEditor, Column, CheckboxFormatterProps, SortColumn, SortStatusProps } from 'react-data-grid';
import { deleteAlerts, getAlerts, getNodes, updateSetAlert } from './firestore';
import ProbeParameters from '../../extra/probeParameters.json';
import moment from 'moment';
import './DataGridStyles.css';
import AlarmsTable from './alarmsTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import EditAlert from './editAlarm';
import { getStaff, updateNewAlert } from '../stations/firestore';
import HistoricalAlertsHome from '../historicalAlerts/HistoricalAlertsHome';


var randomColor = require('randomcolor');

const AlertsHome = (props) => {
    const { userInfo, screenWidth, openSnack, company, account, level, changeCompany, updateUserInfo } = props;
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);
    const [loadingItems, setLoadingItems] = useState(false);
    const [item, setItem] = useState({})
    const [currentButton, setCurrentButton] = useState('alerts')
    const [addItemModal, setAdditemModal] = useState(false)
    const [rows, setRows] = useState([])
    const [parameters, setParameters] = useState([])
    const [sorts, setSorts] = useState([]);
    const [searchInput, setSearchInput] = useState('')
    const [currentItem, setCurrentItem] = useState({})
    const [openModal, setOpenModal] = useState(false)
    const [staff, setStaff] = useState([]);
    let { alertId, duration, snooze, companyKey, accountKey } = useParams();





    const db = getFirestore()





    useEffect(() => {
        console.log("THIS THING ON?")
        console.log(companyKey)
        console.log(accountKey)
        console.log(userInfo)
        console.log(accountKey !== userInfo.currentAccount)
        console.log(companyKey !== userInfo.currentCompany)

        if ([userInfo.currentCompany !== companyKey, userInfo.currentAccount !== accountKey].includes(false)) {
            console.log("NOT GETTING SHIT")
            console.log(userInfo.currentAccount)
            console.log(accountKey)
            console.log(userInfo.currentCompany)
            console.log(companyKey)
            console.log(accountKey !== userInfo.currentAccount)
            console.log(companyKey !== userInfo.currentCompany)

            console.log(userInfo)
            updateUserInfo('currentAccount', accountKey)
            updateUserInfo('currentCompany', companyKey)

            changeCompany(companyKey)




        } else {
            console.log('working?')
            getInfo()
            window.scrollTo(0, 0);
        }


    }, [])

    async function getInfo() {
        const alertsData = await getAlerts(userInfo.currentCompany, userInfo.currentAccount);
        const nodes = await getNodes(userInfo.currentCompany, userInfo.currentAccount);




        const parameters = Object.values(ProbeParameters).map((parameter, index) => {
            return { id: Object.keys(ProbeParameters)[index], label: parameter }
        })

        const updatedAlerts = alertsData.map(alert => {
            return {
                ...alert,
                node: nodes.find(node => node.id === alert.node),
                value: alert.value === undefined ? undefined : alert.value === 'none' ? undefined : Number(Number(alert.value).toFixed(1)),
                parameterLabel: parameters.find(parameter => parameter.id === alert.parameter)?.label || 'zzzzzzzzzzzzzzzzz',
                snoozeDate: alert.id === alertId ? moment().tz('America/Los_Angeles').add(duration, 'hours').format('YYYY-MM-DD HH:mm:ss') : alert.snoozeDate || undefined,
            };
        });

        console.log(updatedAlerts);
        setRows(updatedAlerts);
        setParameters(parameters)
        const staff = await getStaff(userInfo.currentCompany, userInfo.currentAccount);
        setStaff(staff);
        setLoading(false)

        if (alertId && updatedAlerts.find(alert => alert.id === alertId)) {
            const snoozeDate = moment().tz('America/Los_Angeles').add(duration, 'hours').format('YYYY-MM-DD HH:mm:ss')
            const newAlert = { ...updatedAlerts.find(alert => alert.id === alertId), snoozeDate }
            console.log(newAlert)
            setCurrentItem(newAlert)

            updateAlert({ id: alertId, snoozeDate })
            console.log(rows)
            setOpenModal(true)
        }

        if (alertId && !updatedAlerts.find(alert => alert.id === alertId)) {
            openSnack('error', 'Alert not found, it may no longer exist.')
        }
    }


    async function updateAlert(alert) {
        console.log("UPDATING ALERT")
        console.log(alert)
        await updateSetAlert(userInfo, alert);
        openSnack('success', 'Alert updated successfully')

    }
    async function updateFullAlert(alert) {
        //find alert in rows and update it
        const updatedRows = rows.map(row => {
            if (row.id === alert.id) {
                return alert;
            }
            return row;
        }
        );
        setRows([...updatedRows]);


    }

    async function updateNotification(id, boo, val) {
        console.log("THIS THING WORKING??")
        console.log(id, boo, val)
        await updateNewAlert(userInfo.currentCompany, id, boo, val);
        setCurrentItem({ ...currentItem, [boo]: val })

    }




    return (
        <div style={{ fontFamily: 'Roboto, sans-serif', backgroundColor: '#f6f8fe', minHeight: '100vh' }}>
            <Row  >
                <Col {...fullMobile(23, 23)} style={{
                    paddingTop: 30,
                    paddingLeft: 30,
                    overflow: 'auto',


                }}>
                    <Row align="middle" >
                        <Col {...fullMobile(20, 24)}>
                            <Typography variant='h3' fontWeight={500} fontSize={36}>Alerts</Typography>
                            <Typography style={{ paddingTop: 5 }} variant='h5' fontWeight={300} fontSize={18}>All the alerts site.</Typography>

                        </Col>
                        <Col {...fullMobile(4, 24)} style={{


                        }}>
                            {/* <AddItemButton /> */}

                        </Col>

                        <Col {...fullMobile(24, 24)} style={{ paddingTop: 10, paddingBottom: 10 }}>
                            <Divider />
                        </Col>
                        <Col {...fullMobile(24, 24)} style={{ paddingTop: 10, paddingBottom: 10 }}>
                            <ButtonGroup variant="link" aria-label="contained primary button group">
                                {[
                                    { name: 'alerts', label: 'Alerts' },
                                    { name: 'historicalAlerts', label: 'Alert History' },
                                ].map((item, index) => {
                                    return (
                                        <Button style={{
                                            color: '#404050', textTransform: 'none', fontSize: 14, borderRadius: 0,
                                            //add orange bottom border if current button is users
                                            borderBottom: currentButton === item.name ? '2px solid #f57c00' : 'none'
                                        }}
                                            onClick={() => {
                                                setCurrentButton(item.name)
                                                console.log(item.name)
                                            }}
                                        >
                                            {item.label}
                                        </Button>
                                    )
                                })}

                            </ButtonGroup>

                        </Col>
                        <Col {...fullMobile(24, 24)} >
                            <Divider />
                        </Col>
                        {currentButton === 'alerts' && (<Col {...fullMobile(24, 24)} style={{ padding: 20, paddinbBottom: 5 }} >

                            <OutlinedInput
                                margin='dense'
                                size="small"
                                onChange={(e) => setSearchInput(e.target.value)}
                                value={searchInput}
                                placeholder="Search"
                                sx={{
                                    backgroundColor: 'white'
                                }}
                                endAdornment={<IconButton position="end">
                                    <FontAwesomeIcon size='xs' icon={faSearch} style={{ color: 'gray' }} />
                                </IconButton>}
                            />

                        </Col>
                        )}


                        {currentButton === 'alerts' && (
                            <Col {...fullMobile(24, 24)} style={{ padding: 8, }}>

                                <AlarmsTable
                                    rows={rows}
                                    setRows={setRows}
                                    parameters={parameters}
                                    loading={loading}
                                    userInfo={userInfo}
                                    checkboxSelect={true}
                                    searchInput={searchInput}
                                    setCurrentItem={setCurrentItem}
                                    setOpenModal={setOpenModal}
                                    openSnack={openSnack}
                                    screenWidth={screenWidth}
                                />


                            </Col>
                        )}
                        {currentButton === 'historicalAlerts' && (
                            <Col {...fullMobile(24, 24)} style={{ padding: 8, }}>

                                <HistoricalAlertsHome
                                    screenWidth={screenWidth}
                                    userInfo={userInfo}
                                    openSnack={openSnack}

                                />


                            </Col>
                        )}
                    </Row>

                </Col>




                <EditAlert
                    staff={staff}
                    parameters={parameters}
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    currentItem={currentItem}
                    setCurrentItem={setCurrentItem}
                    userInfo={userInfo}
                    screenWidth={screenWidth}
                    updateAlert={updateAlert}
                    updateFullAlert={updateFullAlert}
                    updateNotification={updateNotification}
                    openSnack={openSnack}
                />











            </Row >
        </div >
    );
}

export default AlertsHome;