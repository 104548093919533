import { Divider, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';
import React from 'react';
import { CSVLink } from "react-csv";
import Units from '../extra/units.json';
import axios, * as others from 'axios';
var moment = require('moment-timezone');
var convert = require('convert-units')

var unique = require('array-unique');

export default class QueryOldLabData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      screenWidth: this.props.screenWidth,
      userInfo: this.props.userInfo, 
      account: this.props.account,
      parameters: [],
      dataCSV: [],
      sites: [],
      dateFormat: 'MM/DD/YYYY',
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.screenWidth !== prevProps.screenWidth) {
      this.setState({ screenWidth: this.props.screenWidth })
    }
    if (this.props.userInfo !== prevProps.userInfo) {
      this.setState({ userInfo: this.props.userInfo })
    }
    if (this.props.account !== prevProps.account) {
      this.setState({ account: this.props.account })
    }

  }

  getParameters = async () => {
    const { userInfo, type } = this.state;
    const db = getFirestore();
    const queryData = query(collection(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'parameters'));
    const parameters = [];
    const snap = await getDocs(queryData);
    snap.forEach((doc) => {
      parameters.push({ ...doc.data(), label: doc.data().name })


    });
    console.log(parameters);
    parameters.sort((a, b) => a.name.localeCompare(b.name))
    this.setState({ parameters, })


  }
  getSites = async () => {
    const { userInfo, type } = this.state;
    const db = getFirestore();
    const queryData = query(collection(db, "clients", userInfo.currentCompany, 'sampleSites'), where('account', '==', userInfo.currentAccount),);
    const sites = [];
    const snap = await getDocs(queryData);
    snap.forEach((doc) => {
      sites.push({ ...doc.data(), label: doc.data().name })


    });
    sites.sort((a, b) => a.name.localeCompare(b.name))
    this.setState({ sites, loading: false })


  }

  componentDidMount() {
    window.scrollTo(0, 0)
    const data = [];
    const list = []

    list.map((d) => {


      var time = moment(d.timestamp, 'X').tz("America/New_York").format("YYYY-MM-DD HH:mm");

      data.push({
        time: `'${time}'`,
        timestamp: d.timestamp,
        locationid: `'${d.locationid}'`,
        unitid: `'${d.unitid}'`,
        parameterid: `'${d.parameterid}'`,
        value: d.value
      })
    });

    const hourlyVals = data.map((h) => `(${Object.values(h)})`);
    const hourlyString = hourlyVals.join();

    console.log(hourlyString)

    const proxyUrl = "https://mycorslake.herokuapp.com/";
    axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
      raw: `INSERT INTO probe_data VALUES ${hourlyString} ON CONFLICT DO NOTHING; `
    }).then(async (response) => {
      // handle success
      console.log(response)
    })

    this.getParameters();
    this.getSites();

    setTimeout(() => {
      this.addWeatherData();
    }, 1000);

    const paramsList = ["Temperature C",
      "Dissolved Oxygen",
      "Iron",
      "ortho-Phosphate as P",
      "Total Phosphorus as P",
      "Iron, dissolved",
      "Nitrate as N",
      "TKN as N",
      "Ammonia, total as N",
      "Chlorophyll a",
      "Secchi",
      "Manganese",
      "Total Alkalinity",
      "Hardness",
      "Rain",
      "Nitrate & Nitrite as N",
      "Bicarbonate",
      "Phosphorus, Dissolved (As P)",
      "ortho-Phosphate as P, dissolved",
      "Conductivity",
      "pH",
      "Nitrate as NO3¯",
      "Nitrite as NO2¯",
      "Water Temp",
      "Iron, Dissolved",
      "Orthophosphate as P",
      "Orthophosphate as P, Dissolved",
      "ph"].map((r) => { return ({ name: r, }) })







  }

  queryData = async (sa) => {
    const { userInfo, parameters, sites, dateFormat } = this.state;
    const db = getFirestore();

    const parameterList = [
      'TKN as N',
      'Dissolved Oxygen',
      'Ammonia, total as N',
      'Secchi',
      'Temperature C',
      'Total Alkalinity',
      'Nitrate & Nitrite as N',
      'Total Phosphorus as P',
      'Manganese',
      'ortho-Phosphate as P',
      'Iron',
      'Nitrate as N',
      'Iron, dissolved',
      'Conductivity',
      'Rain',
      'Water Temp',
      'Orthophosphate as P, Dissolved',
      'Bicarbonate',
      'Iron, Dissolved',
      'Hardness',
      'ortho-Phosphate as P, dissolved',
      'Chlorophyll a',
      'Nitrite as NO2¯',
      'Nitrate as NO3¯',
      'Phosphorus, Dissolved (As P)',
      'Orthophosphate as P',
      'pH',
      'ph']
    const queryData = query(collection(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'labData'),
      where('analyte', 'in', ["Iron", "Iron, Dissolved", "Iron, dissolved"])
    );
    const data = [];
    const analytes = [];
    const snap = await getDocs(queryData);

    snap.forEach((doc) => {
      var time = moment(doc.data().timestamp, 'X').tz("America/Los_Angeles").format("YYYY-MM-DD HH:mm") === "Invalid date" ? moment(doc.data().date, 'MM/DD/YYYY').tz("America/Los_Angeles").format("YYYY-MM-DD HH:mm") : moment(doc.data().timestamp, 'X').tz("America/Los_Angeles").format("YYYY-MM-DD HH:mm");
      const timestamp = doc.data().timestamp || Number(moment(doc.data().date, 'MM/DD/YYYY').format("X"))

      if (time === "Invalid date") {
        console.log(doc.data())
      }

      const parameter = parameters.filter((p) => p.name === doc.data().analyte)[0];
      const site = sites.filter((p) => p.name === doc.data().sampid)[0];
      const parameterid = parameter !== undefined ? parameter.key : undefined;
      const locationid = site !== undefined ? site.key : undefined;
      const parameterLabel = parameter !== undefined ? parameter.label : undefined;
      const locationLabel = site !== undefined ? site.label : undefined;

      const item = {
        time: `'${time}'`,
        timestamp: timestamp,
        locationid: `'${locationid}'`,
        locationLabel: `'${locationLabel}'`,
        unitid: `'units'`,
        parameterid: `'${parameterid}'`,
        parameterLabel: `'${parameterLabel}'`,
        nd: `'${doc.data().finalval === "ND" ? 'true' : 'false'}'`,
        meta: `'${JSON.stringify(doc.data())}'`,
        account: `'${userInfo.currentAccount}'`,
        company: `'${userInfo.currentCompany}'`,
        value: Number(doc.data().finalval),



      }

      data.push(item);

      /*  analytes.push(doc.data().parameter || doc.data().analyte) */





    })
    console.log(unique(analytes));
    console.log(data);


    const hourlyVals = data.map((h) => `(${Object.values(h)})`);
    const hourlyString = hourlyVals.join();

    console.log(hourlyString)

    /*  const proxyUrl = "https://mycorslake.herokuapp.com/";
     axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
       raw: `INSERT INTO lab_data VALUES ${hourlyString} ON CONFLICT DO NOTHING; `
     }).then(async (response) => {
       // handle success
       console.log(response)
     }) */









  }

  updateParameter = (index, boo, val) => {
    const { parameters } = this.state;

    parameters[index][boo] = val;
    this.setState({ parameters })
  }


  queryData1 = async () => {
    const { userInfo, locationMap, parameterMap, parameterFilters, locationFilters } = this.state;
    this.setState({ querying: true })






    const raw = `SELECT time, value, locationlabel, locationid, parameterid, parameterlabel, account FROM lab_data WHERE parameterlabel = 'Rain' and account = '${userInfo.currentAccount}';`

    this.queryThis(raw)





  }

  queryThis = (raw) => {

    const proxyUrl = "https://mycorslake.herokuapp.com/";
    axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
      raw
    }).then(async (response) => {
      // handle success
      console.log(response)
      console.log(response.data);

      console.log(response.data.map((d) => d.parameterlabel))



      this.props.openSnack('success', 'Data Queryed!')
    }).catch((error) => {
      console.log(error)
    })

  }


  addWeatherData = () => {
    const { userInfo } = this.state;
    const list = []


    const hourly = list.map((d) => {
      var dt = moment(d.time, "YYYY-MM-DD HH:mm:ss").tz("America/Los_Angeles").format('X');
      return (
        {
          time: `'${d.time}'`,
          dt: dt,
          timestamp: 123,
          account: `'${userInfo.currentAccount}'`,
          company: `'${userInfo.currentCompany}'`,
          rain: Number(convert(d.value.toFixed(4)).from('in').to('mm')),


        })
    })


    const hourlyVals = hourly.map((h) => `(${Object.values(h)})`);
    const hourlyString = hourlyVals.join();

    console.log(hourlyString)

    const proxyUrl = "https://mycorslake.herokuapp.com/";
    axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
      raw: `INSERT INTO weather_data (time, dt, timestamp, account, company, rain) VALUES ${hourlyString} ON CONFLICT DO NOTHING; `
    }).then(async (response) => {
      // handle success
      console.log(response)
    })





  }

  render() {

    const { account, parameters, screenWidth, userInfo, dataCSV } = this.state;

    const UnitsArray = Object.values(Units).map((p, i) => {
      return ({
        label: p,
        key: Object.keys(Units)[i]
      })
    });

    return (
      <Row style={{}} >
        <Col span={23}>
          <Row align="middle">
            <Col xs={16} sm={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>
              <Typography variant="h4">Query Old Lab Data</Typography>
            </Col>
            <Col xs={6} sm={4} style={{ paddingTop: 30, paddingBottom: 15, textAlign: 'right' }}>

            </Col>
            <Col span={24}>
              <Divider />
            </Col>
          </Row>


          {/* 
                     {parameters.map((r, i) => {

                        return (
                            <Row>
                                <Col span={8}>
                                    {r.name}
                                </Col>
                                <Col span={8}>
                                    <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                        <Autocomplete
                                            disableClearable
                                            size="small"
                                            disablePortal
                                            id="combo-box-demo"
                                            options={
                                                UnitsArray}
                                            value={r.units || ''}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    size="small"
                                                    label="Units"
                                                    variant="outlined"
                                                />
                                            )}
                                            onChange={(a, b) => {
                                                this.updateParameter(i, 'unitid', b.key);
                                                this.updateParameter(i, 'unitLabel', b.label);
                                                this.updateParameter(i, 'units', b);
                                            }}

                                        />
                                    </Col>
                                </Col>
                            </Row>
                        )

                    })}
                </Col>

                <Col span={24}>
                    <Button variant="contained" color="primary"
                        onClick={async () => {
                            console.log(parameters)

                            const db = getFirestore();
                            parameters.map(async (p, i) => {
                                const key = uuid();
                                const item = { ...p, key };
                                const docRef = doc(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'parameters', key);

                                await setDoc(docRef, item).then(() => {
                                    this.props.openSnack('success', "Parameter created!")
                                }).then(() => {
                                    if (i + 1 === parameters.length) {
                                        console.log("ALL DONE")
                                    }
                                })
                            })





                        }}>Add Parameters</Button> */}
        </Col>

        <Col span={24}>
          <CSVLink data={dataCSV}>Download me</CSVLink>
        </Col>



      </Row >
    );
  }
} 