import React from 'react';
import { Col, Row, Popconfirm, Modal } from 'antd';
import { Breadcrumbs, Button, OutlinedInput, Paper, Typography, Input, InputAdornment, IconButton, Fab, Divider, Select, MenuItem, Checkbox, TextField, Autocomplete, CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, onSnapshot } from 'firebase/firestore';
import "../extra/hoverStyle.css";
import ReInvite from '../extra/reInvite';
import { getAuth, deleteUser } from "firebase/auth";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AccountsUpdate from './accountsUpdate';
import AddUsers from './addUsers';


export default class CompanyStaff extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            company: null,
            data: [],
            staff: [],
            filteredData: [],
            inviteUsers: [],
            filters: [],
            searchText: '',
            loading: true,
            type: this.props.type,
            openModal: false,
            currentItem: {},
            addStatus: 'existing',
            invitation: {},
            staffInfo: {},
            sort: false,
            accountsModal: false,
            currentUser: {},

        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0)
        this.getCompany()
    }

    getCompany = async () => {
        const { userInfo, type } = this.state;
        const db = getFirestore()
        const docRef = doc(db, "clients", userInfo.currentCompany);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            this.setState({ company: docSnap.data() })
            this.queryStaff();
        } else {
            console.log("No such document!");
        }
    }




    queryUsers = async (staff) => {
        const { userInfo, type } = this.state;
        const db = getFirestore()



        const queryStaff = query(collection(db, "users",), where('companies', 'array-contains', userInfo.currentCompany));
        const unsubscribe = onSnapshot(queryStaff, async (querySnapshot) => {
            const data = [];
            querySnapshot.forEach((doc) => {
                console.log(doc.data())
                const person = staff.filter((s) => s.id === doc.id)[0] === undefined ? staff.filter((s) => s.key === doc.id)[0] : staff.filter((s) => s.id === doc.id)[0]
                const adminLevel = person === undefined ? 'none' : person.adminLevel;
                const fullName = doc.data().firstName + ' ' + doc.data().lastName;
                const edit = doc.data().id;
                //push all to data with companyAdminLevel and fullName
                data.push({ ...doc.data(), adminLevel, fullName, edit })
                console.log(data)

            });
            //sort data by fullName
            data.sort((a, b) => (a.fullName > b.fullName) ? 1 : -1)
            this.setState({ data, loading: false })
        });


    }

    queryStaff = async () => {
        const { userInfo, type } = this.state;
        const db = getFirestore()

        const queryStaff = query(collection(db, "clients", userInfo.currentCompany, 'staff',));
        const unsubscribe = onSnapshot(queryStaff, async (querySnapshot) => {
            const staff = [];
            querySnapshot.forEach((doc) => { 
                console.log(doc.data())
                staff.push(doc.data())
            });
            this.setState({ staff, })
            this.queryUsers(staff);
        });


    }


    updateStaff = async (key, boo, val) => {
        console.log(key)
        console.log(boo)
        console.log(val)
        const { userInfo, type, staff, data } = this.state;
        const index = data.map((s) => s.id).indexOf(key);
        data[index][boo] = val;
        console.log(data);
        this.setState({ data })
        const db = getFirestore()
        const queryStaff = doc(db, "clients", userInfo.currentCompany, 'staff', key);
        await updateDoc(queryStaff, {
            [boo]: val
        }).then(() => {
            this.props.openSnack('success', 'Staff Updated')



        }) 
    }


    addToUsers = async (user) => {
        const { data, staff } = this.state;
        this.setState({ data: [...data, user] })
    }


    render() {

        const { userInfo, account, data, filteredData, loading, searchText, filters, type, staff, openModal, screenWidth, invitation,
            currentItem, addStatus, inviteUsers, companyStaff, staffInfo, sort, accountsModal, currentUser, company } = this.state;
        const db = getFirestore();

        const CreateItemButton = () => {
            let navigate = useNavigate();

            return (
                <Button style={{ textTransform: 'none', backgroundColor: '#404050' }} fullWidth variant="contained" color="primary" size="medium"
                    onClick={async () => {
                        this.setState({ openModal: true, currentItem: {} })
                    }}>
                    Add User
                </Button>
            )
        }







        const XTable = ({ columns, header, data }) => {
            let navigate = useNavigate();
            const getMuiTheme = () =>
                createTheme({
                    components: {
                        MUIDataTable: {
                            styleOverrides: {
                                root: {
                                    padding: '8px',
                                    boxShadow: 'none'
                                }
                            }
                        },
                        MuiTableRow: {
                            styleOverrides: {
                                root: {
                                    zIndex: 100,
                                    cursor: 'pointer',
                                    fontSize: '10px',
                                    '&:nth-child(even)': {
                                        backgroundColor: '#eeeeee52'
                                    },
                                    "&:hover": {
                                        backgroundColor: "#9ec1c933"
                                    }


                                },


                            }
                        },
                        MuiTableCell: {
                            styleOverrides: {
                                root: {
                                    fontSize: '12px',
                                    padding: '2px'
                                }
                            }
                        }

                    }
                });

            const keyIndex = 5;

            const companyColumns = [




                {
                    name: 'fullName',
                    label: 'Name',
                    options: {
                        filter: false,

                    },
                },
                {
                    name: 'email',
                    label: 'Email',
                    options: {
                        filter: false,

                    },
                },
                /*  {
                     name: 'adminLevel',
                     label: 'Admin Level',
                     options: {
                         filter: false,
                         customBodyRender: this.renderValue = (a, b) => {
 
                             const id = b.rowData[keyIndex];
 
 
                             //if id is userInfo.id then return null
                             if (id === userInfo.id) {
                                 return null
                             } else {
                                 return (
                                     <Select
                                         label="none"
                                         size="small"
                                         notched={false}
                                         value={a}
                                         onChange={(e) => this.updateStaff(id, 'adminLevel', e.target.value)}
                                     >
                                         <MenuItem value={'admin'}>Admin</MenuItem>
                                         <MenuItem value={'customer'}>Customer</MenuItem>
                                         <MenuItem value={'client'}>Client</MenuItem>
                                         <MenuItem value={'contractor'}>Contractor</MenuItem>
                                         <MenuItem value={'none'}>None</MenuItem>
                                     </Select>
                                 )
                             }
 
 
                         }
                     },
 
                 }, */

                {
                    label: 'Accounts', name: 'edit',
                    options: {

                        filter: false,
                        viewColumns: false,
                        customBodyRender: this.renderValue = (a, b) => {
                            const id = a;
                            const item = this.state.data.find((f) => f.id === id)
                            const currentUser = this.state.data.find((f) => f.id === userInfo.id)

                            console.log(userInfo)
                            console.log(currentUser)
                            console.log(item)

                            if (userInfo.id === "eTevYsI9mQMxhtntd95HmRGasNx2" || currentUser !== undefined && currentUser.adminLevel !== undefined && currentUser.adminLevel === 'admin' && item.signedUp === true) {
                                return (
                                    <Button
                                        size="small"
                                        style={{
                                            textTransform: 'none',

                                            backgroundColor: '#404050',
                                            fontSize: 10
                                        }
                                        }

                                        variant="contained" color="primary" itemKey={a}
                                        onClick={() => {
                                            this.setState({ accountsModal: true, currentUser: item })
                                        }
                                        }
                                    > Accounts</Button >
                                )
                            } else {
                                return null;
                            }


                        }
                    },
                },
                {
                    label: 'Invited', name: 'signedUp',
                    options: {

                        filter: false,
                        viewColumns: false,
                        customBodyRender: this.renderValue = (a, b) => {
                            const id = a;
                            const item = this.state.data.find((f) => f.id === id)
                            const currentUser = this.state.data.find((f) => f.id === userInfo.id)
                            console.log(currentUser)
                            console.log(a)
                            if (a === false) {
                                return (
                                    <ReInvite screenWidth={screenWidth} userInfo={userInfo} invitation={{ email: currentUser?.email, id: currentUser?.id }}
                                        openSnack={this.props.openSnack} />
                                )
                            } else {
                                return null;
                            }


                        }
                    },
                },


                {
                    label: 'Delete', name: 'edit',
                    options: {

                        filter: false,
                        viewColumns: false,
                        customBodyRender: this.renderValue = (a, b) => {
                            const id = a;
                            const item = this.state.data.find((f) => f.id === id)

                            if (id === userInfo.id) {
                                return null
                            } else {
                                return (
                                    <Popconfirm
                                        title={`Are you sure to delete this ${item?.signedUp ? 'staff member' : 'invite'}? This will completely remove them from this company and every account for this company!`}
                                        onConfirm={async () => {

                                            await deleteDoc(doc(db, "clients", userInfo.currentCompany, 'staff', item.id))
                                                .then(async () => {

                                                    //get accounts in currentcompany
                                                    const queryAccounts = query(collection(db, "clients", userInfo.currentCompany, 'accounts',));
                                                    const accounts = [];
                                                    const snapAccounts = await getDocs(queryAccounts);
                                                    snapAccounts.forEach(async (docSnap) => {
                                                        accounts.push(docSnap.data())

                                                        //if item.accounts includes doc.data().key delete user from it
                                                        if (item.accounts.includes(docSnap.data().key)) {
                                                            await deleteDoc(doc(db, "clients", userInfo.currentCompany, 'accounts', docSnap.data().key, 'staff', item.id))

                                                            const queryUser = doc(db, "users", item.id);
                                                            updateDoc(queryUser, {
                                                                accounts: arrayRemove(docSnap.data().key),
                                                            })
                                                        }


                                                    });

                                                    this.props.openSnack('success', 'Staff removed.')
                                                    const newData = this.state.data.filter((f) => f.id !== item.id)
                                                    this.setState({ data: newData })







                                                }).then(() => {
                                                    //remove account from user accounts array
                                                    const queryUser = doc(db, "users", item.id);
                                                    updateDoc(queryUser, {
                                                        accounts: arrayRemove(userInfo.currentAccount),
                                                        companies: arrayRemove(userInfo.currentCompany)
                                                    })
                                                })









                                        }}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <Button
                                            size="small"
                                            style={{
                                                textTransform: 'none',

                                                color: 'red',
                                                border: '1px solid red',
                                                fontSize: 10
                                            }
                                            } variant="outlined"
                                        >
                                            <DeleteForeverIcon />
                                        </Button>
                                    </Popconfirm>
                                )
                            }


                        }
                    },
                },
            ]



            return (
                <ThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        title={''}
                        data={this.state.data}
                        columns={companyColumns}

                        options={{
                            rowsPerPage: 50,
                            pagination: {
                                rowsPerPage: 50,
                            },
                            textLabels: {
                                body: {
                                    noMatch: <div style={{}}>{loading ? "Loading Data" : "No Records Found"} {loading && (<CircularProgress />)}</div>
                                }
                            },
                            downloadOptions: {
                                filename: 'Applications'
                            },
                            print: false,
                            selectableRows: false,
                            viewColumns: false,

                        }}

                    />
                </ThemeProvider>
            );
        }


        return (
            <Row style={{}} align="middle">

                <Col span={23}>
                    <Row align="middle">
                        <Col xs={16} sm={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>
                            <Typography variant="h4">Client Staff</Typography>
                        </Col>
                        <Col xs={6} sm={4} style={{ paddingTop: 30, paddingBottom: 15, textAlign: 'right' }}>
                            <CreateItemButton />
                        </Col>
                        <Col span={24}>
                            <Divider />
                        </Col>
                    </Row>
                </Col>





                <Col span={23} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 30 }}>

                    <Row>



                        <Col span={24}>
                            <XTable />
                        </Col>
                    </Row>

                </Col>

                <Modal title="" visible={accountsModal} onCancel={() => {
                    this.setState({ accountsModal: false, currentUser: {}, })

                }} footer={null} width={'60%'} style={{ borderRadius: 40 }} >

                    <AccountsUpdate currentUser={JSON.stringify(currentUser)} userInfo={userInfo} />
                </Modal >

                <Modal title="" visible={openModal} onCancel={() => this.setState({ openModal: false })} footer={null} width={screenWidth === 'xs' ? '80%' : '50%'} style={{ borderRadius: 40 }} >
                    <AddUsers company={company} closeModal={() => this.setState({ openModal: false })} userInfo={userInfo} screenWidth={screenWidth} openSnack={this.props.openSnack}
                        addToUsers={this.addToUsers} />

                </Modal>



            </Row>
        );
    }
} 