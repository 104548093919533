import { Button, Divider, OutlinedInput, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import React from 'react';

import DatePicker from "react-datepicker";
import { fullMobile } from './util';

import axios, * as others from 'axios';
var moment = require('moment-timezone');


export default class AddWeather extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            startDate: new Date(moment().subtract('1', 'month').format('L')),
            endDate: new Date(),
            insertDate: new Date(),
            data: [],
            insertDateString: '',
            insertRain: 0,
            insertTemp: 0,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }

    }
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    queryWeather = () => {
        const { userInfo, startDate, endDate } = this.state;

        const start = moment(startDate).startOf("day").format('MM-DD-YYYY HH:mm');
        const end = moment(endDate).endOf("day").format('MM-DD-YYYY HH:mm');

        const proxyUrl = "https://mycorslake.herokuapp.com/";
        axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
            raw: `SELECT * FROM weather_data
            WHERE account = '${userInfo.currentAccount}'
            and time >= '${start}' 
            and time <= '${end}' 
            ORDER BY "time" DESC;`
        })
            .then(async (response) => {
                console.log(response)
                this.setState({ data: response.data })
            });
    }
    queryWeatherBefore = () => {
        const { userInfo, startDate, endDate } = this.state;

        const start = moment(startDate).startOf("day").format('MM-DD-YYYY HH:mm');
        const end = moment(endDate).endOf("day").format('MM-DD-YYYY HH:mm');

        const proxyUrl = "https://mycorslake.herokuapp.com/";
        axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
            raw: `SELECT * FROM weather_data
            WHERE account = '${userInfo.currentAccount}'
            and rain != 0
            ORDER BY "time" DESC;`
        })
            .then(async (response) => {
                console.log(response)
                this.setState({ data: response.data })
            });
    }

    updateWeather = (boo) => {
        const { userInfo, } = this.state;
        const proxyUrl = "https://mycorslake.herokuapp.com/";
        axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
            raw: `UPDATE weather_data
            SET temp = ${boo.temp}, rain = ${boo.rain}, dt = ${Number(moment(boo.time, 'YYYY-MM-DD HH:mm:ss').tz("America/Los_Angeles").format('X'))}
            WHERE time = '${boo.time}'
              AND account = '${userInfo.currentAccount}';`,
        })
            .then(async (response) => {

                console.log(response)
                this.props.openSnack("success", 'Data Updated!')



            })
            .catch((error) => {
                // handle error
                console.log(error);


            })
            .then(function () {
                // always executed
            });


    }
    insertWeather = (boo) => {
        const { userInfo, insertDateString, insertTemp, insertRain } = this.state;
        const proxyUrl = "https://mycorslake.herokuapp.com/";
        axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
            raw: `INSERT INTO weather_data(time, account, temp, rain)
            VALUES ('${insertDateString}', '${userInfo.currentAccount}', ${insertTemp}, ${insertRain});`,

        })
            .then(async (response) => {

                console.log(response)
                this.props.openSnack("success", 'Data Inserted!')



            })
            .catch((error) => {
                // handle error
                console.log(error);


            })
            .then(function () {
                // always executed
            });


    }

    deleteWeather = () => {

    }

    updateItem = (index, boo, val) => {
        const { data } = this.state;
        data[index][boo] = val;
        this.setState({ data })
        console.log(data);
    }

    render() {

        const { account, startDate, endDate, data, insertDateString, insertDate, insertTemp, insertRain } = this.state;

        return (
            <Row style={{}} >
                <Col span={23}>
                    <Row align="middle">
                        <Col xs={16} sm={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>
                            <Typography variant="h4">Add Weather</Typography>
                        </Col>
                        <Col xs={6} sm={4} style={{ paddingTop: 30, paddingBottom: 15, textAlign: 'right' }}>

                        </Col>
                        <Col span={24}>
                            <Divider />
                        </Col>
                        <Col {...fullMobile(8, 12)} style={{}}>
                            <Typography variant="body1" fontSize={13} fontWeight={600}>Start Date</Typography>

                            <DatePicker portalId="root-portal" size="large" showTimeSelect timeFormat="h:mm a" timeIntervals={15} timeCaption="time" dateFormat="MM/dd/yyyy h:mm a"
                                selected={startDate}
                                onChange={(date) => {
                                    this.setState({ startDate: date })
                                }} />
                        </Col>
                        <Col {...fullMobile(8, 12)} style={{}}>
                            <Typography variant="body1" fontSize={13} fontWeight={600}>End Date</Typography>

                            <DatePicker portalId="root-portal" size="large" showTimeSelect timeFormat="h:mm a" timeIntervals={15} timeCaption="time" dateFormat="MM/dd/yyyy h:mm a"
                                selected={endDate}
                                onChange={(date) => {
                                    this.setState({ endDate: date })
                                }} />
                        </Col>
                        <Col {...fullMobile(24, 24)} style={{}}>
                            <Typography variant="body1" fontSize={13} fontWeight={600}>Query</Typography>

                            <Button color="primary" variant="contained" style={{ textTransform: 'none', padding: 12 }} onClick={() => this.queryWeatherBefore()}>Query Data</Button>
                        </Col>


                        <Col {...fullMobile(8, 12)} style={{}}>
                            <Typography variant="body1" fontSize={13} fontWeight={600}>Start Date</Typography>

                            <DatePicker portalId="root-portal" size="large" showTimeSelect timeFormat="h:mm a" timeIntervals={15} timeCaption="time" dateFormat="MM/dd/yyyy h:mm a"
                                selected={insertDate}
                                onChange={(date) => {
                                    this.setState({ insertDate: date, insertDateString: moment(date).tz("America/Los_Angeles").format('YYYY-MM-DD HH:mm:ss') })
                                }} />
                        </Col>

                        <Col {...fullMobile(4, 4)} style={{}}>
                            <Typography variant="body1" fontSize={13} fontWeight={600}>Temp</Typography>
                            <OutlinedInput fullWidth notched={false} label="none" placeholder={"Temp"} size="small"
                                value={insertTemp}
                                type='number'
                                onChange={(e) => {
                                    this.setState({ insertTemp: Number(e.target.value) })
                                }} />
                        </Col>
                        <Col {...fullMobile(4, 4)} style={{}}>
                            <Typography variant="body1" fontSize={13} fontWeight={600}>Rain</Typography>
                            <OutlinedInput fullWidth notched={false} label="none" placeholder={"Rain"} size="small"
                                value={insertRain}
                                type='number'
                                onChange={(e) => {
                                    this.setState({ insertRain: Number(e.target.value) })
                                }} />
                        </Col>
                        <Col {...fullMobile(4, 4)} style={{}}>
                            <Button onClick={() => {
                                this.insertWeather()
                            }}>Insert</Button>
                        </Col>





                        <Col {...fullMobile(24, 24)} style={{}}>
                            {data.map((d, i) => {
                                return (
                                    <Row>
                                        <Col {...fullMobile(4, 4)} style={{}}>
                                            <DatePicker portalId="root-portal" size="large" showTimeSelect timeFormat="h:mm a" timeIntervals={15} timeCaption="time" dateFormat="MM/dd/yyyy h:mm a"
                                                selected={new Date(moment(d.time, 'YYYY-MM-DD HH:mm:ss').format('L'))}
                                                onChange={(date) => {

                                                    this.updateItem(i, `time`, moment(date).format('YYYY-MM-DD HH:mm:ss'))
                                                }} />
                                        </Col>
                                        <Col {...fullMobile(4, 4)} style={{}}>
                                            <OutlinedInput fullWidth notched={false} label="none" placeholder={"Temp"} size="small"
                                                value={d.temp}
                                                type='number'
                                                onChange={(e) => {
                                                    this.updateItem(i, `temp`, Number(e.target.value))
                                                }} />
                                        </Col>
                                        <Col {...fullMobile(4, 4)} style={{}}>
                                            <OutlinedInput fullWidth notched={false} label="none" placeholder={"Rain"} size="small"
                                                value={d.rain}
                                                type='number'
                                                onChange={(e) => {
                                                    this.updateItem(i, `rain`, Number(e.target.value))
                                                }} />
                                        </Col>
                                        <Col {...fullMobile(4, 4)} style={{}}>
                                            <Button onClick={() => {
                                                this.updateWeather(d)
                                            }}>Update</Button>
                                        </Col>
                                        <Col span={24}>
                                            <Divider />
                                        </Col>
                                    </Row>
                                )
                            })}
                        </Col>

                    </Row>
                </Col>

            </Row >
        );
    }
} 