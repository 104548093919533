import React from "react";


import { Button, ButtonGroup } from '@mui/material'; 
import { Col, Row } from 'antd';
import './logComponent.css';



const LogTabs = (
    {
        activeTab,
        setActiveTab,
        log,
        openSnack,
        screenWidth
    }

) => {






    return (

        <Row style={{
            width: screenWidth === 'xs' ? '100vw' : '100%',
            overflowX: 'auto',
        }}>
            <Col xs={24} sm={24} style={{
                height: '70px',
                borderBottom: '1px solid rgba(0,0,0,0.2)',
                display: 'flex',

                alignItems: 'flex-end', // Adjusted this line
                justifyContent: 'flex-start', // Adjusted this line for better readability
            }}>
                <ButtonGroup size="small" aria-label="small button group" style={{
                    marginLeft: 10
                }}>
                    <Button
                        key="log"
                        onClick={(e) => setActiveTab('log')}
                        color='primary'
                        variant={null}
                        style={{
                            height: '50px',
                            textTransform: 'none',
                            borderRadius: 0,
                            fontWeight: 400,
                            fontSize: 18,
                            color: activeTab === 'log' ? '#6fa2f1' : null,
                            paddingBottom: 10,
                            borderBottom: activeTab === 'log' ? '3px solid #3f51b5' : null
                        }}
                    >
                        Log
                    </Button>
                    <Button
                        key="history"
                        onClick={(e) => {
                            if (log?.status === 'draft') {
                                openSnack('error', 'Please create your log before viewing history')
                                return
                            }
                            setActiveTab('history')
                        }}
                        color='primary'
                        variant={null}
                        style={{
                            height: '50px',
                            textTransform: 'none',
                            borderRadius: 0,
                            fontWeight: 400,
                            fontSize: 18,
                            color: log?.status === 'draft' ? 'rgba(0,0,0,0.4)' : activeTab === 'history' ? '#6fa2f1' : null,
                            paddingBottom: 10,
                            borderBottom: activeTab === 'history' ? '3px solid #3f51b5' : null
                        }}
                    >
                        History
                    </Button>
                    <Button
                        key="distribute"
                        onClick={(e) => {
                            if (log?.status === 'draft') {
                                openSnack('error', 'Please create your log before viewing history')
                                return
                            }
                            setActiveTab('distribute')
                        }}
                        color='primary'
                        variant={null}
                        style={{
                            height: '50px',
                            textTransform: 'none',
                            borderRadius: 0,
                            fontWeight: 400,
                            fontSize: 18,
                            color: log?.status === 'draft'? 'rgba(0,0,0,0.4)' : activeTab === 'distribute' ? '#6fa2f1' : null,
                            paddingBottom: 10,
                            borderBottom: activeTab === 'distribute' ? '3px solid #3f51b5' : null
                        }}
                    >
                        Distribute
                    </Button>
                    <Button
                        key="preview"
                        onClick={(e) => {
                            if (log?.status === 'draft') {
                                openSnack('error', 'Please create your log before viewing history')
                                return
                            }
                            setActiveTab('preview')
                        }}
                        color='primary'
                        variant={null}
                        style={{
                            height: '50px',
                            textTransform: 'none',
                            borderRadius: 0,
                            fontWeight: 400,
                            fontSize: 18,
                            color: log?.status === 'draft' ? 'rgba(0,0,0,0.4)' : activeTab === 'preview' ? '#6fa2f1' : null,
                            paddingBottom: 10,
                            borderBottom: activeTab === 'preview' ? '3px solid #3f51b5' : null
                        }}
                    >
                        Preview
                    </Button>


                </ButtonGroup>
            </Col>


        </Row>

    );
};

export default LogTabs;
