import React from "react";
import moment from 'moment-timezone';
import axios, * as others from 'axios';

export const getNestedProperty = (obj, path, getLabel, optionType, options, decimals) => {


    const firstValue = path?.split('.').reduce((acc, part) => acc && acc[part], obj);
    const value = getLabel === true ? firstValue?.label : firstValue;


    if (!optionType || !options) {
        return value;
    }

    if (optionType === 'money') {
        return value !== undefined ? Number(value)?.toFixed(2) : value;
    }
    if (optionType === 'colorSelect') {
        console.log(value)
        return value;
    }
    if (optionType === 'number') {
        return value !== undefined ? Number(value)?.toFixed(decimals) : value;
    }
    if (optionType === 'percent') {
        return value !== undefined ? `${Number(value)}%` : value;
    }

    if (optionType === 'singleSelect') {
        const matchingOption = options.find(option => option.id === value);
        return matchingOption ? matchingOption.name : value;
    }

    if (optionType === 'multiSelect' && Array.isArray(value)) {
        const matchingOptions = value.map(id => {
            const option = options.find(option => option.id === id);
            return option ? option.name : id;
        });
        // Return as comma-separated string, if you want it as an array just return `matchingOptions`
        return matchingOptions.join(', ');
    }

    return value;
};


export const applyFiltersAndSearch = (logs, searchQuery, headers, columnFilters, screenWidth) => {

    console.log(logs)
    console.log(headers)
    console.log(columnFilters)
    // Remove any columnFilter that has an array of 0
    Object.keys(columnFilters).forEach(key => {
        if (columnFilters[key].length === 0) {
            delete columnFilters[key];
        }
    });

    // Start by filtering only visible headers
    const visibleHeaders = headers?.filter(header => header.visible);

    // Now, consider column filters and the search query
    return logs?.filter(log => {

        if (log.inactive) {
            return false;
        }

        const searchMatch = visibleHeaders?.some(header => {

            const value = getNestedProperty(log, header.id, header.getLabel, header?.optionType, header?.options, header?.decimals);
            let searchValue;
            if (header.type === 'date' && value !== undefined) {
                searchValue = moment(value, 'x').format(
                    screenWidth === 'xs' ? 'MMM DD, YY ha' : 'MM/DD/YY ha'
                );
            } else {
                searchValue = value !== undefined ? value : "No Value"; // Adjust here
            }

            return (
                searchValue.toString().toLowerCase().includes(searchQuery.toLowerCase())
            );
        });

        const columnFilterMatch = visibleHeaders?.every(header => {
            const value = getNestedProperty(log, header.id, header.getLabel, header?.optionType, header?.options, header?.decimals);

            let filterValue;
            if (header.type === 'date' && value !== undefined) {
                filterValue = moment(value, 'x').format(
                    screenWidth === 'xs' ? 'MMM DD, YY ha' : 'MM/DD/YY ha'
                );
            } else {
                filterValue = value !== undefined ? value : "No Value"; // Adjust here
            }

            return columnFilters[header.id]
                ? columnFilters[header.id].includes(filterValue)
                : true;
        });

        return searchMatch && columnFilterMatch;
    });
};