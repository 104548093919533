import React, { useEffect, useRef, useState } from 'react';

import TextSymbol from "@arcgis/core/symbols/TextSymbol";
import Graphic from "@arcgis/core/Graphic";
import * as geometryEngine from "@arcgis/core/geometry/geometryEngine.js";
import Geometry from "@arcgis/core/geometry/Geometry.js";
import Symbol from "@arcgis/core/symbols/Symbol.js";
import SimpleFillSymbol from "@arcgis/core/symbols/SimpleFillSymbol.js";
import SimpleLineSymbol from "@arcgis/core/symbols/SimpleLineSymbol.js";
import SimpleMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol.js";
import { formatArea, formatLength, generateShapeLabel } from './utils';
import { doc, getDoc, getFirestore, setDoc, updateDoc } from 'firebase/firestore';


export function createLabel(graphicsLayer, graphic, showLabels, labelAttributes, layerColor, mapUnits, userInfo, record) {
    console.log("CREATING A NEW LABEL")
    /* check if label exists  */
    let number;


    number = graphic.attributes.label;



    let label;  // The label text
    const geometry = graphic.geometry;

    label = generateShapeLabel(geometry, mapUnits, label, number);







    // Assign the same unique ID to the graphic and its label
    graphic.attributes.uniqueId = number;


    // Create a text symbol for the label
    const textSymbol = new TextSymbol({
        text: label,
        color: labelAttributes?.color || "black",
        haloColor: labelAttributes?.haloColor || "white",
        haloSize: "2px",
        font: {  // autocast as Font
            size: labelAttributes?.font?.size || 16,
            family: "sans-serif"
        },
        yoffset: -10,  // Adjust y offset as needed

    });

    // Determine a suitable position for the label
    let labelPosition;
    if (geometry.type === "point") {
        labelPosition = geometry;
    } else if (geometry.extent) {
        labelPosition = geometry.extent.center;
    }



    if (labelPosition) {
        const labelGraphic = new Graphic({
            geometry: labelPosition,
            symbol: textSymbol,
            visible: showLabels,
            attributes: {
                "type": "label",
                "uniqueId": number,  // Assign the unique ID to the label
                "itemid": graphic.uid,
                "labelNumber": number,
                "uuid": graphic.attributes.uuid,
            }
        });


        graphicsLayer.add(labelGraphic);
        
        saveGraphicsLayer({ graphicsLayer, labelAttributes, layerColor, showLabels, mapUnits, userInfo, record })


    }
}

export function deleteLabel(graphicsLayer, graphics, labelAttributes, layerColor, showLabels, mapUnits, userInfo, record) {


    // Find the label graphics for the graphics to be removed
    const labelGraphics = graphicsLayer.graphics.filter(graphic => {


        return graphics.some(g => g.attributes.uuid === graphic.attributes.uuid) && graphic.attributes.type === 'label';
    });



    // Remove the label graphics
    labelGraphics.forEach(labelGraphic => {
        graphicsLayer.remove(labelGraphic);
    });
    saveGraphicsLayer({ graphicsLayer, labelAttributes, layerColor, showLabels, mapUnits, userInfo, record })




}

export function updateLabelLocation(graphicsLayer, graphic, labelAttributes, layerColor, mapUnits, showLabels, userInfo, record) {

    try {
        // Find the label graphic
        const labelGraphic = graphicsLayer.graphics.find(g => g.attributes.uuid === graphic.attributes.uuid && g.attributes.type === 'label');

        // Update the label graphic's geometry
        labelGraphic.geometry = graphic.geometry;
        saveGraphicsLayer({ graphicsLayer, labelAttributes, layerColor, showLabels, mapUnits, userInfo, record })
    }
    catch (err) {
        console.log(err);
    }
}


export function updateLabelToCenter(graphicsLayer, graphic, labelAttributes, layerColor, mapUnits, showLabels, userInfo, record) {

    try {
        // Find the label graphic
        const labelGraphic = graphicsLayer.graphics.find(g => g.attributes.uuid === graphic.attributes.uuid && g.attributes.type === 'label');
        /* if label does not exist create it at center */
        if (!labelGraphic) {
            createLabel(graphicsLayer, graphic, showLabels, labelAttributes, layerColor, mapUnits, userInfo, record);
            return;
        }
        // Update the label graphic's geometry
        labelGraphic.geometry = graphic.geometry.type === 'point' ? graphic.geometry : graphic.geometry.extent.center;
    
        // Replace the old graphic with the updated one in the layer
        graphicsLayer.remove(labelGraphic);
        graphicsLayer.add(labelGraphic);
        saveGraphicsLayer({ graphicsLayer, labelAttributes, layerColor, showLabels, mapUnits, userInfo, record })
    }
    catch (err) {
        console.log(err);
    }
}










export function updateLabel(graphicsLayer, graphics, showLabels, labelAttributes, layerColor, mapUnits, userInfo, record) {
    // Delete the old label and create a new one 
    console.log('UPDATING LABEL')
    try {


        graphics.forEach(graphic => {
            if (graphic?.attributes?.type === 'label') {
                updateLabelLocation(graphicsLayer, graphic, labelAttributes, layerColor, mapUnits, showLabels, userInfo, record);
                return;
            }
            /* update label to center of graphic */
            updateLabelToCenter(graphicsLayer, graphic, labelAttributes, layerColor, mapUnits, showLabels, userInfo, record);
        });
    }
    catch (err) {
        console.log(err);
    }
}


export async function saveGraphicsLayer({ graphicsLayer, labelAttributes, layerColor, showLabels, mapUnits, userInfo, record }) {

    try {



        /* localStorage set graphicsLayer */
        const graphicsToStore = graphicsLayer.graphics.toArray().map(graphic => {


            return {
                geometry: {
                    type: graphic.geometry.type,
                    ...graphic.geometry.toJSON(),
                },
                visible: graphic.visible,
                symbol: graphic?.symbol?.toJSON(),
                attributes: graphic.attributes
            };
        });


        const db = getFirestore();
        const docRef = doc(db, "clients", userInfo.currentCompany, 'flGraphics', record.id);
        await setDoc(docRef, {
            graphicsLayer: JSON.stringify(graphicsToStore),
            labelAttributes,
            layerColor,
            showLabels,
            mapUnits,
            id: record.id,
            company: userInfo.currentCompany,
            account: userInfo.currentAccount,
            status: 'active',
        });
    }
    catch (err) {
        console.log(err)
    }

}

export async function readGraphicsLayer(graphicsLayer, userInfo, record) {




    const db = getFirestore();
    const docRef = doc(db, "clients", userInfo.currentCompany, 'flGraphics', record.id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        let storedGraphics = JSON.parse(docSnap.data()?.graphicsLayer);

       

        storedGraphics.forEach(graphicData => {
            // Determine the correct symbol class based on the symbol type
            let symbol;
            switch (graphicData.symbol.type) {
                case "esriSFS": // Simple Fill Symbol
                    symbol = SimpleFillSymbol.fromJSON(graphicData.symbol);
                    break;
                case "esriSLS": // Simple Line Symbol
                    symbol = SimpleLineSymbol.fromJSON(graphicData.symbol);
                    break;
                case "esriSMS": // Simple Marker Symbol
                    symbol = SimpleMarkerSymbol.fromJSON(graphicData.symbol);
                    break;
                case "esriTS": // Text Symbol
                    symbol = TextSymbol.fromJSON(graphicData.symbol);


                    break;
                // Add cases for other symbol types as necessary
            }

            const graphic = new Graphic({
                geometry: {
                    rings: graphicData?.geometry?.rings,
                    paths: graphicData?.geometry?.paths,
                    x: graphicData?.geometry?.x,
                    y: graphicData?.geometry?.y,
                    type: graphicData?.geometry?.type,
                    ...graphicData?.geometry
                },
                symbol: symbol,
                visible: graphicData.visible,
                attributes: graphicData.attributes
            });
            graphicsLayer.add(graphic);
        });


        return {
            labelAttributes: docSnap.data()?.labelAttributes || {
                font: {  // autocast as Font
                    size: 16,
                    family: "sans-serif"
                },
                color: "#ffffff",
                haloColor: "#000000",
                haloSize: "2px",
                yoffset: -10,  // Adjust y offset as needed
            },
            layerColor: docSnap.data()?.layerColor || { rgb: { r: 248, g: 252, b: 3, a: .8 }, hex: '#f8fc03' },
            showLabels: docSnap.data()?.showLabels || true,
            mapUnits: docSnap.data()?.mapUnits || { area: 'acres', distance: 'feet' },
        }


    } else {
        console.log("No such document!");
        // docSnap.data() will be undefined in this case
        return {
            labelAttributes: {
                font: {  // autocast as Font
                    size: 16,
                    family: "sans-serif"
                },
                color: "#ffffff",
                haloColor: "#000000",
                haloSize: "2px",
                yoffset: -10,  // Adjust y offset as needed
            },
            layerColor: { rgb: { r: 248, g: 252, b: 3, a: .8 }, hex: '#f8fc03' },
            showLabels: true,
            mapUnits: { area: 'acres', distance: 'feet' },
        }
    }



}


export async function fetchMapInfo(userInfo, record) {

   
    const db = getFirestore();
    const docRef = doc(db, "clients", userInfo.currentCompany, 'flGraphics', record.id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        let storedGraphics = JSON.parse(docSnap.data()?.graphicsLayer);

       

        storedGraphics.forEach(graphicData => {
            // Determine the correct symbol class based on the symbol type
            let symbol;
            switch (graphicData.symbol.type) {
                case "esriSFS": // Simple Fill Symbol
                    symbol = SimpleFillSymbol.fromJSON(graphicData.symbol);
                    break;
                case "esriSLS": // Simple Line Symbol
                    symbol = SimpleLineSymbol.fromJSON(graphicData.symbol);
                    break;
                case "esriSMS": // Simple Marker Symbol
                    symbol = SimpleMarkerSymbol.fromJSON(graphicData.symbol);
                    break;
                case "esriTS": // Text Symbol
                    symbol = TextSymbol.fromJSON(graphicData.symbol);


                    break;
                // Add cases for other symbol types as necessary
            }



        });


        return {
            labelAttributes: docSnap.data()?.labelAttributes || {
                font: {  // autocast as Font
                    size: 16,
                    family: "sans-serif"
                },
                color: "#ffffff",
                haloColor: "#000000",
                haloSize: "2px",
                yoffset: -10,  // Adjust y offset as needed
            },
            layerColor: docSnap.data()?.layerColor || { rgb: { r: 248, g: 252, b: 3, a: .8 }, hex: '#f8fc03' },
            showLabels: docSnap.data()?.showLabels || true,
            mapUnits: docSnap.data()?.mapUnits || { area: 'acres', distance: 'feet' },
            graphics: storedGraphics
        }


    } else {
        console.log("No such document!");
        // docSnap.data() will be undefined in this case
        return {
            labelAttributes: {
                font: {  // autocast as Font
                    size: 16,
                    family: "sans-serif"
                },
                color: "#ffffff",
                haloColor: "#000000",
                haloSize: "2px",
                yoffset: -10,  // Adjust y offset as needed
            },
            layerColor: { rgb: { r: 248, g: 252, b: 3, a: .8 }, hex: '#f8fc03' },
            showLabels: true,
            mapUnits: { area: 'acres', distance: 'feet' },
            graphics: []
        }
    }



}


export async function setGraphicsToLayer(graphicsLayer, graphics, getGraphics, view) {


    let storedGraphics = graphics;

    storedGraphics.forEach(graphicData => {
        // Determine the correct symbol class based on the symbol type
        let symbol;
        switch (graphicData.symbol.type) {
            case "esriSFS": // Simple Fill Symbol
                symbol = SimpleFillSymbol.fromJSON(graphicData.symbol);
                break;
            case "esriSLS": // Simple Line Symbol
                symbol = SimpleLineSymbol.fromJSON(graphicData.symbol);
                break;
            case "esriSMS": // Simple Marker Symbol
                symbol = SimpleMarkerSymbol.fromJSON(graphicData.symbol);
                break;
            case "esriTS": // Text Symbol
                symbol = TextSymbol.fromJSON(graphicData.symbol);


                break;
            // Add cases for other symbol types as necessary
        }

        const graphic = new Graphic({
            geometry: {
                rings: graphicData?.geometry?.rings,
                paths: graphicData?.geometry?.paths,
                x: graphicData?.geometry?.x,
                y: graphicData?.geometry?.y,
                type: graphicData?.geometry?.type,
                ...graphicData?.geometry
            },
            symbol: symbol,
            visible: graphicData.visible,
            attributes: graphicData.attributes
        });
        graphicsLayer.add(graphic);
    });


    getGraphics(graphicsLayer, view);

}


export async function fetchMapDefaults(userInfo, record) {

    try {

        const mapInfoDefaults = {
            labelAttributes: {
                font: {  // autocast as Font
                    size: 16,
                    family: "sans-serif"
                },
                color: "#ffffff",
                haloColor: "#000000",
                haloSize: "2px",
                yoffset: -10,  // Adjust y offset as needed
            },
            layerColor: { rgb: { r: 248, g: 252, b: 3, a: .8 }, hex: '#f8fc03' },
            showLabels: true,
            mapUnits: { area: 'acres', distance: 'feet' },
            graphics: []
        }

        const db = getFirestore();
        const docRef = doc(db, "clients", userInfo.currentCompany, 'flGraphics', record.id);
        await setDoc(docRef, {
            graphicsLayer: JSON.stringify([]),
            labelAttributes: mapInfoDefaults.labelAttributes,
            layerColor: mapInfoDefaults.layerColor,
            showLabels: mapInfoDefaults.showLabels,
            mapUnits: mapInfoDefaults.mapUnits,
            id: record.id,
            company: userInfo.currentCompany,
            account: userInfo.currentAccount,
            status: 'active',
        });



        return mapInfoDefaults;
    }
    catch (err) {
        console.log(err)
    }
}
export async function removeMapGraphics(userInfo, record) {

    try {

        const mapInfoDefaults = {
            labelAttributes: {
                font: {  // autocast as Font
                    size: 16,
                    family: "sans-serif"
                },
                color: "#ffffff",
                haloColor: "#000000",
                haloSize: "2px",
                yoffset: -10,  // Adjust y offset as needed
            },
            layerColor: { rgb: { r: 248, g: 252, b: 3, a: .8 }, hex: '#f8fc03' },
            showLabels: true,
            mapUnits: { area: 'acres', distance: 'feet' },
            graphics: []
        }

        const db = getFirestore();
        const docRef = doc(db, "clients", userInfo.currentCompany, 'flGraphics', record.id);
        await updateDoc(docRef, {
            graphicsLayer: JSON.stringify([]),
        });



        return 'success';
    }
    catch (err) {
        console.log(err)
    }
}





