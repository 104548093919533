import { Divider, Paper, Typography } from '@mui/material';
import { Col, Layout, Row } from 'antd';

import React from 'react';
import { Fade } from 'react-reveal';
// import hero from './homepageAssets/hero.png';
// import hero from './homepageAssets/footer.png';
import { Link } from 'react-router-dom';
// modules styles

import LogIn from '../navigation/logIn';
import LoginHome from '../navigation/login/loginHome';
import dash1 from './dashPhotos/1.png';
import dash2 from './dashPhotos/2.png';
import dash3 from './dashPhotos/3.JPG';
import bg6 from './homepageAssets/bg6.png';
import iso2 from './homepageAssets/iso2.jpg';
import { default as logo1, default as logoColor } from './homepageAssets/ltlogo.png';
import logo from './homepageAssets/ltlogowhite.png';
import Tablet1 from './images/tablet1.png';
import background1 from './backgroundImage1.png'
import logo2 from './LTLogo.png'

const { Header, Footer, Sider, Content } = Layout;





export default class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      click: false,
      screenWidth: this.props.screenWidth
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.screenWidth !== prevProps.screenWidth) {
      this.setState({ screenWidth: this.props.screenWidth })
    }
  }


  handleClick = () => {
    // setClick(!click);
    // this.setState(
    //   {click: !false}

    // )
    this.setState({ click: !this.state.click });
  }



  componentDidMount() {
  }

  render() {
    const { click, screenWidth } = this.state;

    const Background1 = () => {
      return (
        <Col
          xs={24}
          sm={12}
          style={{
            height: '100vh',
            background: `url(${background1})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat', textAlign: 'right'
          }}
        >


          <Row >

            {screenWidth !== 'xs' && (<Link to="/">
              <div style={{
                textAlign: 'center',
                position: 'absolute', top: 20, left: 20,

              }}>
                <img src={logo2} style={{ height: 40 }} />
                <Typography variant="body1" fontWeight={300} style={{ color: 'rgba(0,0,0,.6)', position: 'relative', marginTop: 0, marginBottom: 3, paddingLeft: 40 }}>
                  Your eyes on the water.
                </Typography>
              </div>
              {/* LT */}
            </Link>)}





          </Row>

        </Col>
      )
    }

    return (
      <Layout>

        <Content style={{ backgroundColor: 'white' }}>

          <Row style={{ padding: 0, minHeight: '100vh' }}>

            {screenWidth !== 'xs' && (
              <Background1 />
            )}


            <Col xs={24} sm={12} >
              <Row style={{ padding: 0 }}>
                {screenWidth === 'xs' && (<Col span={24} style={{ paddingTop: 40, textAlign: 'center' }}>
                  <Link to="/">
                  <img src={logo2} style={{ height: 40 }} />
                    {/* LT */}
                  </Link>
                </Col>)}

                <Col
                  xs={24}
                  sm={{ offset: 4, span: 20 }}
                  style={{ paddingTop: screenWidth === 'xs' ? 0 : 100 }}
                >

                  <LoginHome
                    authChange={this.props.authChange}
                    openSnack={this.props.openSnack}
                  />
                </Col>
              </Row>

            </Col>


            {screenWidth === 'xs' && (
              <Background1 />
            )}


           {/*  <Col xs={24} style={{ padding: 20, }}>
              <Row style={{ paddingTop: 40 }} justify='space-around'>

                <Col
                  xs={22} sm={12}
                  style={{ textAlign: 'center' }}

                >
                  <Typography
                    variant={'h3'}

                    fontWeight={500}
                  >
                    Products that Simplify Work
                    from Data Collection to Reporting
                  </Typography>
                </Col>
              </Row>
              <Row style={{ paddingTop: 36 }} gutter={[36, 36]}>

                <Col
                  xs={24}
                  sm={8}


                >
                  <Paper elevation={16} style={{ padding: 0, borderRadius: 12 }}>
                    <Row>
                      <Col span={24} style={{ padding: 4 }} >
                        <img src={dash3} style={{ width: '100%' }} />
                      </Col>
                      <Col span={24} style={{ textAlign: 'left', padding: 12 }}>
                        <Typography variant={'h3'} fontSize={28} fontWeight={500}>
                          Data Collection
                        </Typography>
                      </Col>
                      <Col span={24} style={{ textAlign: 'left', padding: 12 }}>
                        <Typography variant={'body1'} >
                          Use our drag and drop and automatic data uplaods to simply your data collection needs.
                        </Typography>

                      </Col>
                      <Col span={24} style={{ backgroundColor: '#404050', padding: 16 }}>
                        <Typography variant={'h3'} fontSize={22} fontWeight={500} style={{ color: 'white', }}>
                          Learn More
                        </Typography>
                      </Col>
                    </Row>
                  </Paper>
                </Col>
                <Col
                  xs={24}
                  sm={8}


                >
                  <Paper elevation={16} style={{ padding: 0, borderRadius: 12 }}>
                    <Row>
                      <Col span={24} style={{ padding: 4 }} >
                        <img src={dash2} style={{ width: '100%' }} />
                      </Col>
                      <Col span={24} style={{ textAlign: 'left', padding: 12 }}>
                        <Typography variant={'h3'} fontSize={28} fontWeight={500}>
                          Data Visualization
                        </Typography>
                      </Col>
                      <Col span={24} style={{ textAlign: 'left', padding: 12 }}>
                        <Typography variant={'body1'} >
                          Quickly build charts and graphs to visualize your water quality data.  Overlay real-time weather, real-time water quality data, and lab
                          data together to test hypotheses and understand trends.
                        </Typography>

                      </Col>
                      <Col span={24} style={{ backgroundColor: '#404050', padding: 16 }}>
                        <Typography variant={'h3'} fontSize={22} fontWeight={500} style={{ color: 'white', }}>
                          Learn More
                        </Typography>
                      </Col>
                    </Row>
                  </Paper>
                </Col>
                <Col
                  xs={24}
                  sm={8}


                >
                  <Paper elevation={16} style={{ padding: 0, borderRadius: 12 }}>
                    <Row>
                      <Col span={24} style={{ padding: 4 }} >
                        <img src={dash1} style={{ width: '100%' }} />
                      </Col>
                      <Col span={24} style={{ textAlign: 'left', padding: 12 }}>
                        <Typography variant={'h3'} fontSize={28} fontWeight={500}>
                          Track Field Work
                        </Typography>
                      </Col>
                      <Col span={24} style={{ textAlign: 'left', padding: 12 }}>
                        <Typography variant={'body1'} >
                          Build field logs to track work that occurred onsite.  Quickly add services and products to each field log to never forget what
                          happened onsite.
                        </Typography>

                      </Col>
                      <Col span={24} style={{ backgroundColor: '#404050', padding: 16 }}>
                        <Typography variant={'h3'} fontSize={22} fontWeight={500} style={{ color: 'white', }}>
                          Learn More
                        </Typography>
                      </Col>
                    </Row>
                  </Paper>
                </Col>
              </Row>

            </Col>







            <Col style={{ padding: '16px', margin: '10rem auto 5rem auto', width: '75%' }}>
              <Row
                style={{ paddingTop: 8, paddingBottom: 24 }}
                gutter={[24, 24]}
                align="middle"
              >


                <Col xs={0} sm={12} style={{ textAlign: 'center' }}>
                  <Fade bottom>
                    <img
                      src={Tablet1}
                      alt="dash"
                      style={{ maxWidth: '100%', height: '75vh' }}
                    />
                  </Fade>



                </Col>

                <Col xs={{ offset: 1 }} sm={10} style={{}}>
                  <Fade bottom>
                    <Typography
                      variant={'h4'}
                      style={{ marginBottom: '2rem' }}
                      fontWeight={500}
                    >
                      No More clipboard
                    </Typography>
                  </Fade>
                  <Fade bottom>
                    <Typography
                      variant={'p'}
                      style={{}}
                      fontWeight={400}
                      fontSize={16}
                    >
                      It is time to start leveraging the LakeTech platform to
                      properly manage your water body.  Document, measure, and plan,
                      all  of your management actvities from your new cloud-based
                      management system.
                    </Typography>

                  </Fade>

                </Col>
                <Col xs={{ offset: 1 }} sm={10} style={{}}>
                  <Fade bottom>
                    <Typography
                      variant={'h4'}
                      style={{ marginBottom: '2rem' }}
                      fontWeight={500}
                    >
                      Introducing LakeLink!
                    </Typography>
                  </Fade>
                  <Fade bottom>
                    <Typography
                      variant={'p'}
                      style={{}}
                      fontWeight={400}
                      fontSize={16}
                    >
                      LakeLink offers clients the ability to track dissolved oxygen and temperature throughout a water column over time.
                      This system allows users to identify thermoclines in real-time. Contact us today to learn more!
                    </Typography>

                  </Fade>

                </Col>
                <Col xs={0} sm={13} style={{ textAlign: 'center' }}>
                  <Fade bottom>
                    <img
                      src={iso2}

                      alt="dash"
                      style={{ maxWidth: '100%', resizeMode: 'contain', flex: 1 }}
                    />

                  </Fade>

                </Col>





              </Row>
            </Col> */}










          </Row>
        </Content>
        {/* <Footer style={{ padding: 0, paddingTop: 2 }}>
          <Row style={{
            height: 200,
            backgroundColor: 'white',
            padding: 20,
          }}>
            <Col offset={2} span={22} style={{ textAlign: 'left' }}>
              <img src={logo1} height={50} />
            </Col>
            <Col offset={2} span={6} style={{ textAlign: 'left' }}>
              <Typography variant="h3" fontWeight={500} fontSize={18}>Products</Typography>
            </Col>
            <Col span={1} style={{ textAlign: 'center' }}>
              <Divider orientation="vertical" />
            </Col>
            <Col span={6} style={{ textAlign: 'left' }}>
              <Typography variant="h3" fontWeight={500} fontSize={18}>Solutions</Typography>
            </Col>
            <Col span={1} style={{ textAlign: 'center' }}>
              <Divider orientation="vertical" />
            </Col>
            <Col span={6} style={{ textAlign: 'left' }}>
              <Typography variant="h3" fontWeight={500} fontSize={18}>Customers</Typography>
            </Col>
            <Col span={1} style={{ textAlign: 'center' }}>
              <Divider orientation="vertical" />
            </Col>


          </Row>
        </Footer> */}

      </Layout >


    );
  }
} 