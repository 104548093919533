import React from 'react';
import {
    IconButton,
    Tooltip,
    Popover,
    List,
    ListItem,
    ListItemText,
    Checkbox,
    Typography,
    Button,
    OutlinedInput,
    InputAdornment,
} from '@mui/material';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';


const ColumnSelection = ({ headers, setHeaders, openSnack }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [searchTerm, setSearchTerm] = React.useState("");



    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleToggle = (id) => () => {
        const newHeaders = [...headers];
        const currentlyVisibleHeaders = headers.filter(header => header.visible).length;
        const index = newHeaders.findIndex((header) => header.id === id);
        // If this header is currently visible and it's the last one, prevent toggling
        if (currentlyVisibleHeaders === 1 && newHeaders[index].visible) {
            openSnack('error', 'You must have at least one visible column');
        } else {
            // Otherwise, toggle the visibility

            newHeaders[index].visible = !newHeaders[index].visible;
            setHeaders(newHeaders);
        }
    };


    const handleSelectAll = () => {
        const newHeaders = headers.map((header) => ({
            ...header,
            visible: true,
        }));
        setHeaders(newHeaders);
    };

    const resizeAll = () => {
        //return all to their default width
        const newHeaders = headers.map((header) => ({
            ...header,
            width: header.minWidth,
        }));
        setHeaders(newHeaders);
    };



    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div style={{ display: 'inline' }}>
            <IconButton aria-label="Column Selection" onClick={handleClick}>
                <Tooltip title="Column Selection">
                    <ViewWeekIcon fontSize="large" sx={{ color: '#3f51b5' }} />
                </Tooltip>
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Typography variant="h6" fontWeight={700} fontSize={18} sx={{ padding: 1, borderBottom: '1px solid #e0e0e0' }}>
                    Column Selection
                </Typography>
                <Button style={{ textTransform: 'none' }} variant="text" color="primary" onClick={handleSelectAll}>
                    Select All
                </Button>
                <Button style={{ textTransform: 'none' }} variant="text" color="secondary" onClick={resizeAll}>
                    Resize All
                </Button>
                <List sx={{
                    padding: 0, maxHeight: 350, overflow: 'auto'

                }}>
                    <ListItem
                        key={'columnSearch'}
                        dense
                        sx={{
                            borderBottom: '1px solid #e0e0e0',
                            paddingTop: 1,
                            paddingBottom: 1,
                            display: 'flex',           // Make this a flex container
                            alignItems: 'center',      // Center items vertically
                            justifyContent: 'center',  // Center items horizontally
                        }}
                    >
                        <OutlinedInput
                            size="small"
                            style={{
                                borderRadius: 16,
                                width: '90%',
                            }}
                            value={searchTerm}
                            placeholder='Search'
                            startAdornment={
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            }
                            endAdornment={
                                <InputAdornment position="end">
                                    {searchTerm !== '' ? (
                                        <IconButton
                                            aria-label="clear search term"
                                            edge="end"
                                            onClick={() => setSearchTerm('')}
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    ) : (
                                        <IconButton style={{ visibility: 'hidden' }}>
                                            <ClearIcon />
                                        </IconButton>
                                    )}
                                </InputAdornment>
                            }
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </ListItem>
                    {headers
                        ?.filter(header => header.headerName.toLowerCase().includes(searchTerm.toLowerCase()))
                        .sort((a, b) => a.position - b.position)
                        .map((header, index) => (
                            <ListItem
                                key={index}
                                dense
                                button
                                onClick={handleToggle(header.id)}
                                sx={{
                                    borderBottom: '1px solid #e0e0e0',
                                    paddingTop: 0,
                                    paddingBottom: 0,

                                }}
                            >
                                <Checkbox edge="start" checked={header.visible} tabIndex={-1} disableRipple />
                                <Typography variant="body1" fontSize={16} fontWeight={500}>{header.headerName}</Typography>

                            </ListItem>
                        ))}
                </List>
            </Popover>
        </div>
    );
};

export default ColumnSelection;
