import { OutlinedInput, Typography, Autocomplete, TextField, Chip, Checkbox } from '@mui/material';
import { Col, Row } from 'antd';
import React from 'react';
import { getFirestore, doc, getDoc, updateDoc, setDoc, query, collection, where, getDocs, } from 'firebase/firestore';

import moment from 'moment';
import DatePicker from "react-datepicker";
import '../hoverStyle.css';
import { fullMobile } from './util'

export default class WaterConditions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            loading: true,
            testKey: this.props.testKey,
            tempData: {},
            dataItem: {},
            saveWaterQualityData: this.props.saveWaterQualityData,
            status: this.props.status,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
        if (this.props.preview !== prevProps.preview) {
            this.setState({ preview: this.props.preview })
        }
        if (this.props.status !== prevProps.status) {
            this.setState({ status: this.props.status })
        }
        if (this.props.testKey !== prevProps.testKey) {

            if (this.props.testKey !== undefined) {
         
                this.setState({ uploadingData: true, })
                this.updateData(this.props.testKey)
                this.queryData(this.props.testKey)
            }
            this.setState({ testKey: this.props.testKey })
        }
        if (this.props.saveWaterQualityData !== prevProps.saveWaterQualityData) {

            this.setState({ saveWaterQualityData: this.props.saveWaterQualityData })
            if (this.props.saveWaterQualityData === true && this.state.testKey !== undefined) {
                this.saveData()
            }
        }


    }
    componentDidMount() {
        const { userInfo, testKey } = this.state;



        if (testKey !== undefined) {
            this.queryData(testKey)
        }

    }

    queryData = async (testKey) => {
        const { userInfo, } = this.state;
       
        const db = getFirestore();
        const docRef = doc(db, "clients", userInfo.currentCompany, 'waterConditions', testKey);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {


            this.setState({ dataItem: docSnap.data() })
        } else {
            console.log("No such document!");
        }
    }

    updateData = async (key) => {
        const { userInfo, tempData, dataItem } = this.state;
        //set  uploadingData true
        this.setState({ uploadingData: true })
        const db = getFirestore();
        const docRef = doc(db, "clients", userInfo.currentCompany, 'waterConditions', key);

        var time = dataItem.time === undefined ? moment().tz("America/Los_Angeles").format("YYYY-MM-DD HH:mm") : dataItem.time;
        dataItem.time = time;
        await setDoc(docRef, tempData).then(() => {
           
            this.setState({ uploadingData: false })

        })
    }
    saveData = async () => {
        const { userInfo, tempData, dataItem, testKey } = this.state;
        //set  uploadingData true
        this.setState({ uploadingData: true })
        const db = getFirestore();
        const docRef = doc(db, "clients", userInfo.currentCompany, 'waterConditions', testKey);
        await setDoc(docRef, dataItem).then(() => {
            console.log("Document successfully written!");
            this.setState({ uploadingData: false })

        })


    }

    addData = (boo, val) => {
        const { dataItem, userInfo, testKey, tempData } = this.state;

        if (testKey === undefined) {
            tempData[boo] = val
            this.setState({ tempData })

        }
        else {
            dataItem[boo] = val
            this.setState({ dataItem })
        }


    }






    render() {

        const { item, screenWidth, preview, dataItem, testKey, tempData, status } = this.state;

        const data = testKey === undefined ? tempData : dataItem;


        return (
            <Row gutter={[24, 24]} style={{ paddingTop: 10 }} align="middle"  >
                <Col {...fullMobile(24, 24)}  >
                    <Typography variant="h2" style={{ fontSize: 32, }}>Water Conditions</Typography>
                </Col>

                <Col {...fullMobile(8, 24)} style={{}}>
                    <Typography variant="body1" fontWeight={600}>Water Body Type:</Typography>
                    {status === 'built' ? <Typography variant="body1">{data.waterBodyType === undefined ? '' : data.waterBodyType.label}</Typography> : <Autocomplete
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        value={data.waterBodyType}
                        hideLabel={true}
                        options={[
                            { label: "Basin", key: 'basin' },
                            { label: "Creek", key: 'creek' },
                            { label: "Ditch", key: 'ditch' },
                            { label: "Drain", key: 'drain' },
                            { label: "Lagoon", key: 'lagoon' },
                            { label: "Lake", key: 'lake' },
                            { label: "Lined Canal", key: 'linedCanal' },
                            { label: "Pond", key: 'pond' },
                            { label: "Reservoir", key: 'reservoir' },
                            { label: "Unlined Canal", key: 'unlinedCanal' },
                        ]}
                        renderInput={(params) => <TextField {...params} label="Water Body Type" />}
                        onChange={(a, b) => {
                            this.addData(`waterBodyType`, b)
                        }}

                    />}
                </Col>
                <Col {...fullMobile(8, 12)} style={{}}>
                    <Typography variant="body1" fontWeight={600}>Water Temp (F):</Typography>
                    {status === 'built' ? <Typography variant="body1">{data.waterTemp}</Typography> : <OutlinedInput
                        fullWidth notched={false} label="none" placeholder={"Water Temp"} size="small"
                        defaultValue={data.waterTemp || ''}
                        type="number"
                        onChange={(e) => {
                            this.addData('waterTemp', e.target.value === "" ? Number(0) : e.target.value === "0" ? Number(0) : Number(e.target.value))
                        }} />}
                </Col>
                <Col {...fullMobile(8, 12)} style={{}}>
                    <Typography variant="body1" fontWeight={600}>Water Depth (ft):</Typography>
                    {status === 'built' ? <Typography variant="body1">{data.waterDepth}</Typography> : <OutlinedInput
                        fullWidth notched={false} label="none" placeholder={"Water Depth"} size="small"
                        defaultValue={data.waterDepth}
                        type="number"
                        onChange={(e) => {
                            this.addData('waterDepth', e.target.value === "" ? Number(0) : e.target.value === "0" ? Number(0) : Number(e.target.value))
                        }} />}
                </Col>
                <Col {...fullMobile(8, 12)} style={{}}>
                    <Typography variant="body1" fontWeight={600}>Water Flow (ft/s cfs):</Typography>
                    {status === 'built' ? <Typography variant="body1">{data.waterFlow}</Typography> : <OutlinedInput
                        fullWidth notched={false} label="none" placeholder={"Water Flow"} size="small"
                        defaultValue={data.waterFlow}
                        type="number"
                        onChange={(e) => {
                            this.addData('waterFlow', e.target.value === "" ? Number(0) : e.target.value === "0" ? Number(0) : Number(e.target.value))
                        }} />}
                </Col>
                <Col {...fullMobile(8, 12)} style={{}}>
                    <Typography variant="body1" fontWeight={600}>Percent Weed Cover:</Typography>
                    {status === 'built' ? <Typography variant="body1">{data.percentWeedCover}</Typography> : <OutlinedInput
                        fullWidth notched={false} label="none" placeholder={"Water Depth"} size="small"
                        defaultValue={data.percentWeedCover}
                        type="number"
                        onChange={(e) => {
                            this.addData('percentWeedCover', e.target.value === "" ? Number(0) : e.target.value === "0" ? Number(0) : Number(e.target.value))
                        }} />}
                </Col>

                <Col {...fullMobile(8, 12)} style={{}}>
                    <Typography variant="body1" fontWeight={600}>Water Color:</Typography>
                    {status === 'built' ? <Typography variant="body1">{data.waterColor === undefined ? '' : data.waterColor.label}</Typography> :
                        <Autocomplete
                            size="small"
                            disablePortal
                            id="combo-box-demo"
                            value={data.waterColor}
                            hideLabel={true}
                            options={[
                                { label: "Blue", key: 'blue' },
                                { label: "Green", key: 'green' },
                                { label: "Brown", key: 'brown' },
                            ]}
                            renderInput={(params) => <TextField {...params} label="Water Color" />}
                            onChange={(a, b) => {
                                this.addData(`waterColor`, b)
                            }}

                        />}
                </Col>
                <Col {...fullMobile(8, 12)} style={{}}>
                    <Typography variant="body1" fontWeight={600}>Water Clarity:</Typography>
                    {status === 'built' ? <Typography variant="body1">{data.waterClarity === undefined ? '' : data.waterClarity.label}</Typography> :
                        <Autocomplete
                            size="small"
                            disablePortal
                            id="combo-box-demo"
                            value={data.waterClarity}
                            hideLabel={true}
                            options={[
                                { label: "Poor", key: 'poor' },
                                { label: "Fair", key: 'fair' },
                                { label: "Good", key: 'Good' },
                            ]}
                            renderInput={(params) => <TextField {...params} label="Water Clarity" />}
                            onChange={(a, b) => {
                                this.addData(`waterClarity`, b)
                            }}

                        />}
                </Col>
                <Col {...fullMobile(8, 24)} style={{}}>
                    <Typography variant="body1" fontWeight={600}>Water Sheen(check if present):</Typography>
                    {status === 'built' ? <Typography variant="body1">{data.waterSheen === true ? "Sheen" : "No Sheen"}</Typography> :
                        <Row align="middle">
                            <Col><Checkbox checked={data.waterSheen}
                                onChange={(e) => {
                                    this.addData('waterSheen', e.target.checked)
                                }} /></Col>
                            <Col>
                                <Typography variant="body1">{data.waterSheen === true ? "Sheen Present" : "No Sheen"}</Typography>
                            </Col>
                        </Row>}
                </Col>




















            </Row >
        );
    }
} 