import React, { useEffect, useState } from "react";


import { Badge, Box, Button, Checkbox, Divider, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { Col, Row, Table } from 'antd';
import moment from 'moment';
import './newStyle.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import uuid from "react-uuid";
import { ItemsTableHeaders } from "./itemsTableHeaders";
import ItemsTableData from "./itemsTableData";
import CreateItem from "./createItem";
import DeleteItemsButton from "./components/deleteItemsButton";
import { fetchWeather } from "./tableData/components/weatherComponents/utils/functions";


const ItemsTable = ({
    screenWidth,
    userInfo,
    account,
    openSnack,
    setLog,
    log,
    stocks,
    items,
    setStocks,
    setItems,
    accounts,
    stations,
    setStations,
    accountUsers,
}) => {

    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [editingRecord, setEditingRecord] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);



    async function updateRecord(newRecord) {
        const index = rows.findIndex(x => x?.id === newRecord?.id);
        rows[index] = newRecord;
        updateRowPositions([...rows])
    }





    async function duplicateRow(record) {
        const index = rows.findIndex(x => x?.id === record?.id);
        const newRow = { ...record, id: uuid() };
        rows.splice(index + 1, 0, newRow);
        updateRowPositions([...rows])

    }

    async function deleteRow(record) {
        rows.splice(rows.findIndex(x => x?.id === record?.id), 1);
        updateRowPositions([...rows])
    }

    async function addItem() {

        const currentWeather = await fetchWeather(account);

        rows.push({
            id: uuid(),
            days: [
                { date: log?.startDate, hours: null, id: uuid() },
            ],
            totalHours: null,
            company: userInfo?.currentCompany,
            account: userInfo?.currentAccount,
            item: null,
            field_log_id: log?.id,
            psType: null,
            quantity: null,
            units: null,
            cost: null,
            total: null,
            notes: null,
            leadStaff: log?.leadStaff || null,
            otherStaff: [],
            node: null,
            stock: null,
            graphics: null,
            mapPreferences: null,
            mapEditorPreferences: null,
            comments: null,
            attachments: null,
            map: null,
            mapUrl: null,

            temp: currentWeather?.tempF,
            wind: currentWeather?.wind,
            dailyRainAmount: currentWeather?.rain || 0,
            raining: currentWeather?.raining > 0 ? true : false,
            rainfallUnits: 'in',

            temperatureUnits: 'F',
            windUnits: 'mph',
            humidity: null,

            windDirection: null,

            showMap: false,
            showAdvanced: false,
            showWeather: false,
            showWaterQuality: false,
            hide: false,
            targetSpecies: null,


        })
        console.log(rows)
        updateRowPositions([...rows])

    }

    async function updateRowPositions(newRows) {
        const updatedRows = newRows.map((row, index) => {
            return (
                {
                    ...row, position: index
                }
            )
        })
        setRows(updatedRows)
    }


    return (
        <Row style={{}} onClick={() => {
            setEditingRecord(null)
        }} >
            <Col span={24} style={{}}>
                <Row align="middle">


                    <Col span={24} justify="end" style={{ paddingBottom: 5 }} >
                        <Row align="middle" justify="space-between" style={{}}>
                            <Col xs={24} sm={8}>
                                <Typography variant="h6" fontSize={20} style={{ fontWeight: 'bold' }}>
                                    Field log items
                                </Typography>
                            </Col>

                            <Col span={16} style={{ textAlign: 'right' }}>


                                {selectedRows.length > 0 && (
                                    <DeleteItemsButton selectedRows={selectedRows} setSelectedRows={setSelectedRows} rows={rows} updateRowPositions={updateRowPositions} />


                                )}

                                {screenWidth !== 'xs' && (

                                    <CreateItem addItem={addItem} log={log} openSnack={openSnack} />
                                )}
                            </Col>
                            <Col xs={24} sm={24}>
                                <Button variant="link" style={{ textTransform: 'none', display: "inline" }} onClick={() => {
                                    //select all files
                                    setSelectedRows([...rows].map(row => row.id))
                                }
                                }>
                                    <Typography variant="body1" fontSize={14} fontWeight={500} >
                                        Select All
                                    </Typography>
                                </Button>
                                <Button variant="link" style={{ marginLeft: 10, textTransform: 'none', display: "inline" }} onClick={() => {
                                    //select all files
                                    setSelectedRows([])
                                }
                                }>
                                    <Typography variant="body1" fontSize={14} fontWeight={500} >
                                        Deselect All
                                    </Typography>
                                </Button>
                                {selectedRows.length > 0 && (


                                    <Button variant="link" style={{ marginLeft: 10, textTransform: 'none', display: "inline" }} onClick={() => {

                                    }
                                    }>




                                        <Typography variant="body1" fontSize={14} fontWeight={500} >
                                            Bulk Edit ({selectedRows.length})
                                        </Typography>
                                    </Button>

                                )}
                            </Col>
                        </Row>

                    </Col>

                    <Col span={24}>
                        <ItemsTableData
                            rows={rows}
                            setRows={setRows}
                            setEditingRecord={setEditingRecord}
                            editingRecord={editingRecord}
                            selectedRows={selectedRows}
                            setSelectedRows={setSelectedRows}
                            setLog={setLog}
                            log={log}
                            deleteRow={deleteRow}
                            duplicateRow={duplicateRow}
                            screenWidth={screenWidth}
                            stocks={stocks}
                            items={items}
                            setStocks={setStocks}
                            setItems={setItems}
                            userInfo={userInfo}
                            openSnack={openSnack}
                            updateRecord={updateRecord}
                            updateRowPositions={updateRowPositions}
                            accounts={accounts}
                            stations={stations}
                            setStations={setStations}
                            accountUsers={accountUsers}
                            account={account}

                        />
                    </Col>
                    {/* If no items or items length is 0 show a no items added to field log message */}

                    {(rows?.length === 0) && (
                        <Col span={24} style={{ textAlign: 'center', paddingTop: 20 }}>
                            <Typography variant="body2" fontSize={18} fontWeight={400} >
                                No items added to field log
                            </Typography>
                        </Col>
                    )}



                    {screenWidth === 'xs' && (
                        <Col span={24} >
                            <Button
                                fullWidth
                                style={{ backgroundColor: '#3f51b5', color: 'white', textTransform: 'none' }}
                                onClick={() => {
                                    addItem()


                                }}
                            >
                                <FontAwesomeIcon color="white" icon={faPlus} /> Add item
                            </Button>
                        </Col>
                    )}



                </Row>
            </Col >

        </Row >
    );


};

export default ItemsTable;
