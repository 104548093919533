export const codes = [
    {
        "label": "B",
        "value": "B",
        "description": "B - Analyte found in reagent blank, indicates possible reagent or background contamination"
    },
    {
        "label": "BLM",
        "value": "BLM",
        "description": "BLM - Compound unidentified or below the RL due to overdilution"
    },
    {
        "label": "BLNO",
        "value": "BLNO",
        "description": "BLNO - Low number of organisms, may impact CSCI calculation"
    },
    {
        "label": "BNTX",
        "value": "BNTX",
        "description": "BNTX - Sample did not contain organisms for taxonomic identification needed to conduct calculations"
    },
    {
        "label": "BRK",
        "value": "BRK",
        "description": "BRK - Sample not analyzed, sample container broken"
    },
    {
        "label": "BV",
        "value": "BV",
        "description": "BV - Sample received after holding time expired"
    },
    {
        "label": "BX",
        "value": "BX",
        "description": "BX - Sample stored at improper temperature"
    },
    {
        "label": "BY",
        "value": "BY",
        "description": "BY - Sample received at improper temperature"
    },
    {
        "label": "BZ",
        "value": "BZ",
        "description": "BZ - Sample preserved improperly"
    },
    {
        "label": "BZ15",
        "value": "BZ15",
        "description": "BZ15 - Sample not filtered within 15 minutes"
    },
    {
        "label": "C",
        "value": "C",
        "description": "C - Pesticide result confirmed using GC/MS- EPA Flag"
    },
    {
        "label": "CIN",
        "value": "CIN",
        "description": "CIN - Value outside confidence interval"
    },
    {
        "label": "CJ",
        "value": "CJ",
        "description": "CJ - Analyte concentration is in excess of the instrument calibration; considered estimated"
    },
    {
        "label": "CNP",
        "value": "CNP",
        "description": "CNP - calibration not performed"
    },
    {
        "label": "CS",
        "value": "CS",
        "description": "CS - QC criteria not met due to analyte concentration near RL"
    },
    {
        "label": "D",
        "value": "D",
        "description": "D - EPA Flag - Analytes analyzed at a secondary dilution"
    },
    {
        "label": "DB",
        "value": "DB",
        "description": "DB - QA results outside of acceptance limits due to matrix effects"
    },
    {
        "label": "DF",
        "value": "DF",
        "description": "DF - Reporting limits elevated due to matrix interferences"
    },
    {
        "label": "DO",
        "value": "DO",
        "description": "DO - Coelution"
    },
    {
        "label": "DRM",
        "value": "DRM",
        "description": "DRM - Spike amount less than 5X the MDL"
    },
    {
        "label": "F",
        "value": "F",
        "description": "F - Sample mistakenly filtered"
    },
    {
        "label": "FCL",
        "value": "FCL",
        "description": "FCL - Field calibration not performed within 24 hours before use"
    },
    {
        "label": "FDC",
        "value": "FDC",
        "description": "FDC - Drift check not acceptable"
    },
    {
        "label": "FDI",
        "value": "FDI",
        "description": "FDI - Field instrument displaced but still deployed in water"
    },
    {
        "label": "FDP",
        "value": "FDP",
        "description": "FDP - Field duplicate RPD above QC limit"
    },
    {
        "label": "FEU",
        "value": "FEU",
        "description": "FEU - Equipment Unavailable"
    },
    {
        "label": "FIA",
        "value": "FIA",
        "description": "FIA - Location was inaccessible to obtain a measurement"
    },
    {
        "label": "FIB",
        "value": "FIB",
        "description": "FIB - Field instrument buried"
    },
    {
        "label": "FIF",
        "value": "FIF",
        "description": "FIF - Instrument/Probe Failure"
    },
    {
        "label": "FIO",
        "value": "FIO",
        "description": "FIO - Field instrument above water level"
    },
    {
        "label": "FIP",
        "value": "FIP",
        "description": "FIP - Field instrument vandalized, fouled, or otherwise in poor condition"
    },
    {
        "label": "FS",
        "value": "FS",
        "description": "FS - Too Shallow for probe measurement"
    },
    {
        "label": "FTD",
        "value": "FTD",
        "description": "FTD - Location was too deep to obtain a measurement"
    },
    {
        "label": "FTT",
        "value": "FTT",
        "description": "FTT - Water too turbid to measure"
    },
    {
        "label": "FUD",
        "value": "FUD",
        "description": "FUD - Unable to deploy instrument"
    },
    {
        "label": "H",
        "value": "H",
        "description": "H - A holding time violation has occurred."
    },
    {
        "label": "J",
        "value": "J",
        "description": "J - Estimated value - EPA Flag"
    },
    {
        "label": "LST",
        "value": "LST",
        "description": "LST - Sample was lost or destroyed"
    },
    {
        "label": "MN",
        "value": "MN",
        "description": "MN - Method procedures not followed"
    },
    {
        "label": "NBC",
        "value": "NBC",
        "description": "NBC - Value not blank corrected"
    },
    {
        "label": "ND",
        "value": "ND",
        "description": "Non-detect"
    },
    {
        "label": "None",
        "value": "None",
        "description": "None - None - No QA Qualifier"
    },
    {
        "label": "NR",
        "value": "NR",
        "description": "NR - Not Recorded"
    },
    {
        "label": "OR",
        "value": "OR",
        "description": "Out of Range"
    },
    {
        "label": "OV",
        "value": "OV",
        "description": "OV - Result is averaged"
    },
    {
        "label": "Q",
        "value": "Q",
        "description": "Q - Questionable result"
    },
    {
        "label": "R",
        "value": "R",
        "description": "R - Data rejected - EPA Flag"
    },
    {
        "label": "VQ",
        "value": "VQ",
        "description": "VQ - Questionable Result, flagged by QAO"
    },
    {
        "label": "VQCA",
        "value": "VQCA",
        "description": "VQCA - QA/QC protocols were not met for accuracy, flagged by QAO"
    },
    {
        "label": "VQCP",
        "value": "VQCP",
        "description": "VQCP - QA/QC protocols were not met for precision, flagged by QAO"
    },
    {
        "label": "VR",
        "value": "VR",
        "description": "VR - Data rejected - EPA Flag, flagged by QAO"
    }
]

export default {
    codes,
}