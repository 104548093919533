

import {
    Badge,
    IconButton,
    List,
    ListItem,
    Popover,
    Tooltip,
    Typography
} from '@mui/material';
import React from 'react';
import { faCheckCircle, faCircle, faCopy, faEdit, faEye, faEyeSlash, faSquare, faSquareCheck, } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsSpin, faDatabase, faDownload, faEllipsisH, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import * as Papa from 'papaparse';
import moment from 'moment-timezone';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import axios, * as others from 'axios';



const MoreItemOptions = ({
    record,
    duplicateRow,
    deleteRow,
    openSnack
}) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        if (record?.item === null) {
            openSnack('error', 'Please select an item first')
            return
        }
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;






    return (
        <div style={{
            display: 'inline',

        }}>
            <IconButton onClick={handleClick}

            >
                <Tooltip title="More Options" placement="bottom">
                    <FontAwesomeIcon icon={faEllipsisH}
                        size="md" style={{
                            color: record?.item === null ? 'gray' : '#3f51b5',
                            cursor: 'pointer'
                        }} />
                </Tooltip>



            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}

                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <List sx={{
                    width: 300,
                    padding: 0,

                }}>
                    <ListItem
                        key={'duplicate'}
                        dense
                        button
                        onClick={() => {
                            duplicateRow(record);
                            handleClose();
                        }}
                        sx={{
                            borderBottom: '1px solid #e0e0e0',
                            paddingTop: 1,
                            paddingBottom: 1,
                        }}
                    >
                        <FontAwesomeIcon icon={faCopy} style={{ color: '#3f51b5', marginRight: 10 }} />
                        <Typography variant="body1" color="black" fontSize={16} fontWeight={500}>Duplicate</Typography>
                    </ListItem>
                    <ListItem
                        key={'duplicate'}
                        dense
                        button
                        onClick={() => {
                          
                        }}
                        sx={{
                            borderBottom: '1px solid #e0e0e0',
                            paddingTop: 1,
                            paddingBottom: 1,
                        }}
                    >
                        <FontAwesomeIcon icon={faPlus} style={{ color: '#3f51b5', marginRight: 10 }} />
                        <Typography variant="body1" color="black" fontSize={16} fontWeight={500}>Save as template</Typography>
                    </ListItem>
                    <ListItem
                        key={'bulkEdit'}
                        dense
                        button
                        onClick={() => {
                            deleteRow(record);
                            handleClose();
                        }}
                        sx={{
                            borderBottom: '1px solid #e0e0e0',
                            paddingTop: 1,
                            paddingBottom: 1,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrash} style={{ color: '#3f51b5', marginRight: 10 }} />
                        <Typography variant="body1" color="black" fontSize={16} fontWeight={500}>Delete</Typography>
                    </ListItem>







                </List>




            </Popover>
        </div>
    );
};

export default MoreItemOptions;
