import { Breadcrumbs, Button, ButtonGroup, Divider, MenuItem, OutlinedInput, Typography, Select, TextField } from '@mui/material';
import { Col, Modal, Row, } from 'antd';
import React, { useEffect, useState, useMemo } from 'react';
import { fullMobile } from './util';
import 'react-data-grid/lib/styles.css';
import uuid from 'react-uuid';
import { memo } from 'react';
import moment from 'moment-timezone';
import { Link, useNavigate } from 'react-router-dom';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Box } from '@mui/system';
import { getFirestore, getDoc, doc, setDoc, updateDoc } from 'firebase/firestore';
import { queryAccounts, queryEditStock, queryItems } from '../../firebase/config';
import StockHome from './stockHome';
//create style of row paddingTop and bottom 15
//create style of row paddingTop and bottom 15
import AddIcon from '@mui/icons-material/Add';
import { stockChart } from 'highcharts';
import DatePicker from "react-datepicker";
import CurrentAmounts from './currentAmount';
const style = {
    row: {
        paddingTop: 15,
        paddingBottom: 15,
        paddingLeft: 15,
        paddingRight: 15,
        //align center in div
        display: 'flex',
        alignItems: 'center',

    }
}





export default function EditStock(props) {
    const { userInfo, screenWidth, openSnack, company, account, staffRef, usersRef, updateAccount, updateUserInfo } = props;
    const [item, setItem] = useState({})
    const [accounts, setAccounts] = useState([])
    const [currentButton, setCurrentButton] = useState('general')
    const [supplyModal, setSupplyModal] = useState(false)
    const [supply, setSupply] = useState({})
    const [items, setItems] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [supplyUnits, setSupplyUnits] = useState(null)
    const db = getFirestore();

    useEffect(() => {
        window.scrollTo(0, 0)
        getInfo()
    }, [])

    useEffect(() => {
        console.log(supply.units);
    }, [supply.units]);

    async function getInfo() {
        const item = await queryEditStock(userInfo)
        console.log(item)
        setItem(item)

        const accounts = await queryAccounts(userInfo)
        //sort by accountName
        accounts.sort((a, b) => (a.accountName > b.accountName) ? 1 : -1)
        //filter out archived accounts
        const newAccounts = accounts.filter((item) => item.archived === false)
        setAccounts(newAccounts)

        const items = await queryItems(userInfo)
        setItems(items)



    }



    //create 50 rows with the schema above columns
    //create array from 0 to 50








    async function handleUpdateItem(boo, val) {
        const newItem = { ...item }
        if (boo) {
            newItem[boo] = val
        }
        setItem(newItem)
    }




    const SaveButton = () => {


        return (
            <Button variant={'contained'} display="inline"

                style={{
                    padding: 4, paddingTop: 8, paddingBottom: 8,
                    textTransform: 'none', backgroundColor: '#404050', fontSize: 13,
                }}
                onClick={async () => {
                    const newItem = { ...item }

                    const docRef = doc(db, 'clients', userInfo.currentCompany, 'productStock', item.key);
                    await updateDoc(docRef, newItem);
                    openSnack('success', 'Stock Updated')
                }}
            >
                Save Stock
            </Button>
        )
    }

    const DeleteButton = () => {
        let navigate = useNavigate()

        return (
            <Button variant={'contained'} display="inline" size="large"
                style={{
                    padding: 4, paddingTop: 8, paddingBottom: 8, border: '1px solid red',
                    textTransform: 'none', backgroundColor: '#404050', fontSize: 13,
                }}
                onClick={async () => {
                    const newItem = { ...item }
                    newItem.archived = true
                    const docRef = doc(db, 'clients', userInfo.currentCompany, 'productStock', item.key);
                    await updateDoc(docRef, newItem);
                    openSnack('success', 'Stock Deleted')
                    navigate('/productStocks')

                }}
            >
                Delete Stock
            </Button>

        )
    }

    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: (option) => option.label,
    });



    return (

        <div style={{ fontFamily: 'Roboto, sans-serif', backgroundColor: '#f6f8fe', minHeight: '100vh' }}>
            <Row style={{ padding: 20, paddingTop: 50 }} >
                <Col {...fullMobile(24, 24)}>


                    <Breadcrumbs aria-label="breadcrumb">

                        <Link to="/productStocks" style={{ color: '#404050', fontWeight: 500 }} >
                            Click to Return to Stocks
                        </Link>

                    </Breadcrumbs>
                </Col>
                <Col {...fullMobile(24, 24)} style={{ paddingTop: 50, paddingBottom: 10 }}>
                    <Typography variant="h3" style={{ fontWeight: 300, color: '#404050' }}>
                        Edit {item.name}
                    </Typography>

                </Col>
                <Col {...fullMobile(24, 24)}>
                    <Divider />
                </Col>
                <Col {...fullMobile(24, 24)} style={{ paddingTop: 10, paddingBottom: 10 }}>
                    <ButtonGroup variant="link" aria-label="contained primary button group">

                        {[{ name: 'general', label: 'General' },
                        { name: 'history', label: 'History' },
                        { name: 'currentAmounts', label: 'Current Amounts' }
                        ].map((item, index) => {
                            return (
                                <Button style={{
                                    color: '#404050', textTransform: 'none', fontSize: 14, borderRadius: 0,
                                    //add orange bottom border if current button is users
                                    borderBottom: currentButton === item.name ? '2px solid #f57c00' : 'none'
                                }}
                                    onClick={() => {
                                        setCurrentButton(item.name)
                                        console.log(item.name)
                                    }}
                                >
                                    {item.label}
                                </Button>
                            )
                        })}
                    </ButtonGroup>

                </Col>
                <Col {...fullMobile(24, 24)} >
                    <Divider />
                </Col>




                {currentButton === 'general' && (<Col {...fullMobile(24, 24)} style={{ paddingTop: 15 }}>
                    <Row gutter={[12, 12]} align="middle" style={{ paddingBottom: 20, paddingTop: 10 }}>
                        <Col {...fullMobile(12, 12)}>
                            <Typography variant="h5" style={{ fontWeight: 500, color: '#404050' }}>
                                STOCK INFORMATION
                            </Typography>
                        </Col>
                        <Col {...fullMobile(12, 12)}>
                            <Typography variant="h5" style={{ fontWeight: 500, color: '#404050', textAlign: 'right' }}>
                                <DeleteButton />

                            </Typography>
                        </Col>
                    </Row>
                    <Row style={{ backgroundColor: 'white', }} align="middle" >
                        <Col {...fullMobile(12, 12)}>
                            <Row gutter={[12, 12]} align="middle" >
                                <Col {...fullMobile(8, 8)} style={style.row} >
                                    <Typography variant="body1" style={{ fontWeight: 400, color: '#404050' }}>
                                        Name
                                    </Typography>
                                </Col>
                                <Col {...fullMobile(16, 16)} style={style.row} >
                                    <OutlinedInput
                                        size="small"
                                        fullWidth
                                        id="outlined-adornment-amount"
                                        value={item.name}
                                        onChange={(e) => {
                                            handleUpdateItem('name', e.target.value,)
                                        }}

                                    />
                                </Col>
                                <Col {...fullMobile(8, 8)} style={style.row} >
                                    <Typography variant="body1" style={{ fontWeight: 400, color: '#404050' }}>
                                        Description
                                    </Typography>
                                </Col>
                                <Col {...fullMobile(16, 16)} style={style.row} >
                                    <OutlinedInput
                                        size="small"
                                        fullWidth
                                        multiline
                                        rows={4}
                                        id="outlined-adornment-amount"
                                        value={item.description}
                                        onChange={(e) => {
                                            handleUpdateItem('description', e.target.value,)
                                        }}

                                    />
                                </Col>
                                <Col {...fullMobile(8, 8)} style={style.row} >
                                    <Typography variant="body1" style={{ fontWeight: 400, color: '#404050' }}>
                                        Location
                                    </Typography>
                                </Col>
                                <Col {...fullMobile(16, 16)} style={style.row} >
                                    <Autocomplete
                                        size="small"
                                        style={{ width: 300 }}
                                        disablePortal
                                        id="combo-box-demo"
                                        //use accountName as value
                                        getOptionLabel={(option) => option.accountName}
                                        value={item.location || null}
                                        options={accounts}
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                placeholder="Select Location"
                                            />}
                                        onChange={(a, b) => {
                                            setItem({
                                                ...item,
                                                location: {
                                                    ...b,
                                                    label: b.accountName,
                                                }
                                            })
                                        }}

                                    />
                                </Col>

                            </Row>
                        </Col>
                    </Row>



                </Col>)}


                {currentButton === 'general' && (
                    <Col {...fullMobile(24, 24)} style={{ paddingTop: 15, paddingLeft: 30, paddingRight: 30, textAlign: 'right' }} >
                        <SaveButton />

                    </Col>)}

                {currentButton === 'history' && (<Col {...fullMobile(24, 24)} style={{ paddingTop: 15 }}>
                    <Row gutter={[12, 12]} align="middle" style={{ paddingBottom: 20, paddingTop: 10 }}>
                        <Col {...fullMobile(20, 20)}>
                            <Typography variant="h5" style={{ fontWeight: 500, color: '#404050' }}>
                                STOCK HISTORY
                            </Typography>
                        </Col>
                        <Col {...fullMobile(4, 4)}>
                            <Button variant={'contained'} display="inline"
                                fullWidth
                                style={{
                                    padding: 4, paddingTop: 8, paddingBottom: 8,
                                    textTransform: 'none', backgroundColor: '#404050', fontSize: 13,
                                }}
                                onClick={() => {
                                    setSupplyModal(true)
                                    setSupply({
                                        time: moment().format('YYYY-MM-DD HH:mm'),
                                        supplyStock: item,
                                        quantity: 0,
                                        user: userInfo.id,
                                        psType: 'supply',
                                        item: null,
                                        description: '',
                                        company: userInfo.currentCompany,
                                        account: null,
                                        key: uuid()
                                    })

                                }}
                            >
                                <AddIcon fontSize='small' />  Add Supply
                            </Button>
                        </Col>
                    </Row>
                    <Row style={{ backgroundColor: 'white', }} align="middle" >
                        <Col {...fullMobile(24, 24)}>
                            <StockHome screenWidth={screenWidth} userInfo={userInfo} openSnack={openSnack} account={account} company={company}
                                refresh={refresh}
                                updateRefresh={() => setRefresh(false)}
                                updateAccount={updateAccount}
                                updateUserInfo={updateUserInfo}
                            />
                        </Col>
                    </Row>



                </Col>)}
                {currentButton === 'currentAmounts' && (<Col {...fullMobile(24, 24)} style={{ paddingTop: 15 }}>
                    <Row gutter={[12, 12]} align="middle" style={{ paddingBottom: 20, paddingTop: 10 }}>
                        <Col {...fullMobile(20, 20)}>
                            <Typography variant="h5" style={{ fontWeight: 500, color: '#404050' }}>
                                Current Amounts
                            </Typography>
                        </Col>

                    </Row>
                    <Row style={{ backgroundColor: 'white', }} align="middle" >
                        <Col {...fullMobile(24, 24)}>
                            <CurrentAmounts screenWidth={screenWidth} userInfo={userInfo} openSnack={openSnack} account={account} company={company}
                                refresh={refresh}
                                updateRefresh={() => setRefresh(false)}
                                updateAccount={updateAccount}
                                updateUserInfo={updateUserInfo}
                            />
                        </Col>
                    </Row>



                </Col>)}











            </Row >

            <Modal title="" visible={supplyModal} onCancel={() => {
                setSupplyModal(false)
                setSupply({})
            }} footer={null} width={screenWidth === 'xs' ? '80%' : '50%'} style={{ borderRadius: 40 }} >
                <Row gutter={[4, 4]} align="middle" >
                    <Col style={{}}>
                        <Typography variant='h5' display={"inline"}>
                            Add Supply
                        </Typography>

                    </Col>



                    <Col {...fullMobile(24, 24)}  >
                        <Row >
                            <Col {...fullMobile(24, 24)} style={{ paddingTop: 15 }}  >
                                <Typography variant="body2" display="inline" fontWeight={600} fontSize={14} style={{ color: '#404050' }}>
                                    Product Name<span style={{ color: 'red' }}>*</span>
                                </Typography>
                            </Col>
                            <Col {...fullMobile(24, 24)}  >
                                <Autocomplete
                                    size="small"
                                    style={{ backgroundColor: '#ffffff' }}
                                    disableCloseOnSelect={false}
                                    id="combo-box-demo"
                                    value={supply.item || null}
                                    disableClearable={false}
                                    options={items.filter(item => item.psType === 'products').sort((a, b) => -b.psType.localeCompare(a.psType))}
                                    groupBy={(option) => option.psType || ""}
                                    renderGroup={(params) => (
                                        <Box key={params.key}>
                                            <Typography fontWeight={800} fontSize={16} p={1}>
                                                {params.group === 'inspection' ? 'Templates' :
                                                    params.group === 'products' ? 'Products' :
                                                        params.group === 'other' ? null :
                                                            'Services'
                                                }
                                            </Typography>
                                            {React.Children.map(params.children, (child, index) => {
                                                return (
                                                    <Box style={{
                                                        borderBottom: '1px solid #e0e0e0'
                                                    }}>
                                                        {child}
                                                    </Box>
                                                )
                                            })}
                                        </Box>
                                    )}
                                    filterOptions={(options, _ref) => [...filterOptions(options, _ref),]}
                                    renderInput={(params) => {
                                        return (
                                            <TextField {...params} placeholder="Item" variant="outlined" />
                                        )
                                    }}
                                    onChange={(a, d) => {
                                        console.log(d);
                                        if (d?.defaultUnits) {
                                            console.log(d.defaultUnits);
                                            setSupply({ ...supply, item: d, units: { key: d.defaultUnits, label: d.defaultUnits } });
                                            setSupplyUnits(d.defaultUnits)
                                        } else {
                                            setSupply({ ...supply, item: d });
                                            setSupplyUnits(null)
                                        }
                                    }}
                                />




                            </Col>
                            <Col {...fullMobile(24, 24)} style={{ paddingTop: 15 }}  >
                                <Typography variant="body2" display="inline" fontWeight={600} fontSize={14} style={{ color: '#404050' }}>
                                    Date<span style={{ color: 'red' }}>*</span>
                                </Typography>
                            </Col>
                            <Col {...fullMobile(24, 24)}  >
                                <DatePicker timeCaption="Time" showTimeSelect timeFormat="h:mm a" timeIntervals={1}
                                    dateFormat="MM/dd/yyyy hh:mm a" portalId="root-portal"
                                    selected={new Date(moment(supply?.time, 'YYYY-MM-DD HH:mm'))} onChange={(date) => {
                                        setSupply({ ...supply, time: moment(date).format('YYYY-MM-DD HH:mm') })



                                    }} />
                            </Col>
                            <Col {...fullMobile(24, 24)} style={{ paddingTop: 15 }} >
                                <Typography variant="body2" display="inline" fontWeight={600} fontSize={14} style={{ color: '#404050' }}>
                                    Amount<span style={{ color: 'red' }}>*</span>
                                </Typography>
                            </Col>
                            <Col {...fullMobile(24, 24)}  >
                                <OutlinedInput
                                    size="small"
                                    style={{ width: 300 }}
                                    type="number"
                                    id="outlined-adornment-amount"
                                    value={supply?.quantity}
                                    onChange={(e) => {
                                        setSupply({ ...supply, quantity: e.target.value === "" ? "" : Number(e.target.value) })
                                    }}

                                />
                            </Col>
                            <Col {...fullMobile(24, 24)} style={{ paddingTop: 15 }} >
                                <Typography variant="body2" display="inline" fontWeight={600} fontSize={14} style={{ color: '#404050' }}>
                                    Units<span style={{ color: 'red' }}>*</span>
                                </Typography>
                            </Col>
                            <Col {...fullMobile(24, 24)}  >
                                <Autocomplete
                                    size="small"
                                    id="combo-box-demo"
                                    value={supplyUnits}
                                    options={["mg",
                                        "g",
                                        "kg",
                                        "oz",
                                        "lb",
                                        "fl-oz",
                                        "cup",
                                        "pnt",
                                        "qt",
                                        "gal",
                                        "ft3",
                                    ].sort((a, b) => a.localeCompare(b)).map((g) => { return ({ key: g, label: g }) })}
                                    renderInput={(params) => <TextField {...params} placeholder="Units" />}
                                    onChange={(a, d) => {
                                        setSupply({ ...supply, units: d });
                                        setSupplyUnits(d.key)
                                    }}

                                />
                            </Col>

                            <Col {...fullMobile(24, 24)} style={{ paddingTop: 25 }}  >
                                {
                                    supply?.item !== null &&
                                    supply.date !== null &&
                                    supply.quantity !== 0 &&
                                    supply.units !== null &&

                                    (
                                        <Button variant='contained'
                                            color='primary'
                                            style={{ textTransform: 'none', backgroundColor: '#404050', }}
                                            onClick={async () => {
                                                const newSupply = { ...supply }
                                                const docRef = doc(db, "clients", userInfo.currentCompany, 'flItems', newSupply.key);
                                                await setDoc(docRef, newSupply).then(() => {
                                                    setSupplyModal(false)
                                                    setSupply({})
                                                    setRefresh(true)
                                                    openSnack('success', 'Supply Added')

                                                }).catch((error) => {
                                                    console.error("Error updating document: ", error);
                                                });

                                            }}
                                        >
                                            Add Supply
                                        </Button>


                                    )

                                }
                            </Col>

                        </Row>
                    </Col>


















                </Row>
            </Modal>


        </div >

    );
}