import { Button, Checkbox, Divider, OutlinedInput, Typography, Select, MenuItem, CircularProgress, Breadcrumbs, Paper } from '@mui/material';
import { Col, Row } from 'antd';
import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { fullMobile } from './../util';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, orderBy, limit } from 'firebase/firestore';

import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import uuid from 'react-uuid';



import queryString from 'query-string';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { getUser, getStaff, getAccounts } from '../../../firebase/config';
import { ArrowUpwardOutlined } from '@mui/icons-material';
import ApplicationHeaderButtons from './components/headerButtons';
import LogGeneralInfo from './components/logGeneralInfo';
import LogHeader from './components/logHeader';
import LogWaterConditions from './components/logWaterConditions';
import LogApplicationInfo from './components/logApplicationInfo';
import LogWaterQuality from './components/logWaterQualityApplication';
import LogPhotos from './components/logPhotosApplication';
import LogNotes from './components/logNotes';
import LogChemicals from './components/logChemicals';


var randomColor = require('randomcolor');

export default function NewApplicationHome({ userInfo, screenWidth, openSnack, company, account, reports, updateUser, }) {
    const [status, setStatus] = useState('creating');
    const [log, setLog] = useState({})
    const [staff, setStaff] = useState([])
    const [accounts, setAccounts] = useState([])
    const [loading, setLoading] = useState(true)
    const [logHistory, setLogHistory] = useState([])
    const props = { userInfo, screenWidth, openSnack, company, account, };


    useEffect(() => {

        window.scrollTo(0, 0)
        getUserInfo()


    }, []);

    async function getUserInfo() {
        queryDataDoc()
        const user = await getUser(userInfo.id);
        const staff = await getStaff(userInfo.currentAccount);
        setStaff(staff)
        const accounts = await getAccounts(userInfo.id, userInfo.currentCompany, userInfo.accounts);
        setAccounts(accounts)

    }

    async function queryDataDoc() {
        const db = getFirestore();
        const docRef = doc(db, "users", userInfo.id, 'currentItems', 'currentFieldDoc');
        const docSnap = await getDoc(docRef);

        const queryAccountData = query(collection(db, "clients", userInfo.currentCompany, 'fieldDocs'),
            where('archived', '==', false), where('accountKey', '==', userInfo.currentAccount), orderBy("lognumber", "desc"), limit(1));
        const data = [];

        const snap = await getDocs(queryAccountData);
        snap.forEach((doc) => {
            data.push({ ...doc.data() })
        });

        //if data length is 0, set lognumber to 1
        const lognumber = data.length === 0 ? 1 : data[0].lognumber + 1;


        if (docSnap.data() === undefined || docSnap.data().key === 'none') {

            const key = uuid();
            const doc = {
                tempKey: key,
                logKeyPR: `${key}-pr`,
                logKeyEV: `${key}-ev`,
                logKeyPO: `${key}-po`,
                status: 'edit',
                archived: false,
                type: 'applicationLog',
                company: userInfo.currentCompany,
                startDate: moment().format('x'),
                endDate: moment().add(1, 'hours').format('x'),
                startDate: moment().format('x'),
                preCloseTime: moment().format('x'),
                endDate: moment().add(1, 'hours').format('x'),
                postOpenTime: moment().add(1, 'hours').format('x'),
                photos: JSON.stringify([]),
                preview: false,
                controlsApplicable: false,
                lognumber,
                account: account,
                companyName: company.companyName,
                accountKey: account.key,
                hideWQ: false,
                hidePS: false,
                hidePhotos: false,
                history: [],
                product: '',
            };
            setLog(doc)
            setLoading(false)

        }

        else {
            getDocItem(docSnap.data().key, lognumber)

        }
    }

    async function getDocItem(key, lognumber) {
        const db = getFirestore();
        const docRef = doc(db, "clients", userInfo.currentCompany, 'fieldDocs', key);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const item = docSnap.data();

            setLog(item)
            setLoading(false)
            setStatus(item.status)
            setLogHistory(item.history)

        }
        else {

            setLoading(false)
            const doc = {
                tempKey: key,
                status: 'edit',
                archived: false,
                type: 'applicationLog',
                company: userInfo.currentCompany,
                startDate: moment().format('x'),
                endDate: moment().add(1, 'hours').format('x'),
                startDate: moment().format('x'),
                preCloseTime: moment().format('x'),
                endDate: moment().add(1, 'hours').format('x'),
                postOpenTime: moment().add(1, 'hours').format('x'),
                photos: JSON.stringify([]),
                preview: false,
                controlsApplicable: false,
                lognumber,
                account: account,
                companyName: company.companyName,
                accountKey: account.key,
                hideWQ: false,
                hidePS: false,
                hidePhotos: false,
                history: [],
                staticUrl: ''
            };
            setLog(doc)
        }

    }


    const ToTopButton = () => {
        //create a button that scrolls to the top of the page and is located in the bottom right corner of the screen
        return (
            <div style={{ position: 'fixed', bottom: 20, right: 20, zIndex: 1000, cursor: 'pointer' }}>
                <button onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} style={{ backgroundColor: '#f6f8fe', cursor: 'pointer', border: 'none', borderRadius: 5, padding: 10, boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)' }}>
                    <ArrowUpwardOutlined style={{ fontSize: 20 }} />
                </button>
            </div>
        )
    }




    async function updateStatus(newStatus) {

        setStatus(newStatus);
        const db = getFirestore();
        const docRef = doc(db, "clients", userInfo.currentCompany, 'fieldDocs', log.key || log.tempKey);
        setDoc(docRef, {
            ...log,
            status: newStatus,
            key: log.key || log.tempKey,
            history: logHistory || []
        }).then(() => {

            if (log.history.length === 0) {
                const message = `${userInfo.firstName || ''} ${userInfo.lastName || ''} has created this field log.`
                updateLog('key', log.tempKey)
                updateHistory(message)
            }
            else {
                const message = `${userInfo.firstName || ''} ${userInfo.lastName || ''} has changed this log to status of ${newStatus}.`;
                updateHistory(message)
            }


        })

    }


    async function updateHistory(message) {
        const db = getFirestore();
        const docRef = doc(db, "clients", userInfo.currentCompany, 'fieldDocs', log.tempKey);

        const messageItem = {
            key: uuid(),
            message,
            user: `${userInfo.firstName || ''} ${userInfo.lastName || ''}`,
            date: moment().format('MM/DD/YYYY h:mm a'),
            type: 'Status Change',

        }

        const itemString = JSON.stringify(messageItem);
        //update history with arrayunion
        await updateDoc(docRef, {
            history: arrayUnion(itemString)
        }).then(() => {
            setLogHistory([...logHistory, itemString])
        })
    }




    function updateLog(boo, val) {

        if (boo) {
            log[boo] = val;
            setLog({ ...log })

        }
    }

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </div>
        )
    }
    else {



        return (
            <Row align="middle" style={{
                fontFamily: 'Roboto, sans-serif', padding: screenWidth === 'xs' ? 15 : 40,
                backgroundColor: '#f6f8fe',//align content to top

            }} >
                <Col {...fullMobile(12, 24)} style={{ paddingTop: 10, }}>
                    <Breadcrumbs>
                        {reports === 'psReports' && (<Link to="/PSReports" style={{ textDecoration: 'none', color: 'inherit', }}>
                            Back to Reports
                        </Link>)}
                        {reports === 'company' && (<Link to="/chemicalApplicationsCompany" style={{ textDecoration: 'none', color: 'inherit', }}>
                            Back to Reports
                        </Link>)}
                        <Link to="/chemicalApplications" style={{ textDecoration: 'none', color: 'inherit', }}>
                            Back to Applications
                        </Link>



                    </Breadcrumbs>
                </Col>
                <Col {...fullMobile(12, 24)} style={{ paddingTop: 10, textAlign: 'right' }}>
                    <ApplicationHeaderButtons {...props} status={status} logRef={log} updateStatus={updateStatus} updateLog={updateLog} company={company} />
                </Col>
                <Col {...fullMobile(24, 24)} style={{ paddingTop: 10, }}>
                    <Paper style={{ padding: 20, backgroundColor: 'white' }}>
                        <LogHeader {...props} status={status} />
                        <LogGeneralInfo {...props} status={status} logRef={log} staffRef={staff} updateLog={updateLog} updateHistory={updateHistory} updateWeatherItem={updateLog} />
                        <LogWaterConditions {...props} status={status} logRef={log} updateLog={updateLog} />
                        <LogApplicationInfo {...props} status={status} logRef={log} updateLog={updateLog} />
                        <LogWaterQuality {...props} status={status} logRef={log} updateLog={updateLog} stage="pre" />
                        <LogChemicals {...props} status={status} logRef={log} updateLog={updateLog} />
                        {log.npdes && (
                            <LogWaterQuality {...props} status={status} logRef={log} updateLog={updateLog} stage="event" />
                        )}
                        {log.npdes && (
                            <LogWaterQuality {...props} status={status} logRef={log} updateLog={updateLog} stage="post" />
                        )}

                        <LogPhotos {...props} status={status} logRef={log} updateLog={updateLog} />
                        <LogNotes {...props} status={status} logRef={log} updateLog={updateLog} />

                    </Paper>
                </Col>




                <Col {...fullMobile(24, 24)} style={{ paddingTop: 10, textAlign: 'right' }}>
                    <ApplicationHeaderButtons {...props} status={status} logRef={log} updateStatus={updateStatus} updateLog={updateLog} company={company} />
                </Col>

                <ToTopButton />






            </Row>
        );
    }
}