import React, { useState } from "react";


import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle, faSpinner, faTrash, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { Col, Row } from 'antd';

import ColumnSelection from "./columnSelection";
import './logList.css';




import FilterTable from "./filterTable";
import MoreTableOptions from "./moreTableOptionsTool";
import SelectableTool from "./selctableTool";
import CreateItem from "../createItem";





const LogsSubHeader = (
    {
        prefKey,
        logs,
        setLogs,
        showInactive,
        currentLog,
        setCurrentLog,
        deleteLog,
        updateLog,
        setLogDrawer,
        duplicateLog,
        updateLogsPosition,
        headers,
        setHeaders,
        openSnack,
        getNestedProperty,
        columnFilters,
        setColumnFilters,
        fullLogs,
        sortItem,
        setSortItem,
        selectable,
        setSelectable,
        deleteSelectedLogs,
        staff,
        accounts,
        updateMultipleLogs,
        items,
        setItems,
        stocks,
        userInfo,
        logTemplates,
        setColumnEditor,
        deleteHeader,

        ProbeParameters,
        gettingData,
        setGettingData,
        selectedLogs,
        screenWidth,
        statusFilter,
        setStatusFilter
    }

) => {


    const [initialWidth, setInitialWidth] = useState(null);
    const [deleteDialog, setDeleteDialog] = useState(false);





    const props = {
        headers,
        setHeaders,
        openSnack,
        headers, setHeaders, logs, initialWidth, getNestedProperty,
        columnFilters,
        setColumnFilters,
        fullLogs,
        setLogs,
        selectable,
        setSelectable,
        updateLog,
        setCurrentLog,
        currentLog,
        showInactive,
        setLogDrawer,
        deleteSelectedLogs,
        duplicateLog,
        deleteLog,
        updateLogsPosition,
        sortItem,
        setSortItem,
        staff,
        accounts,
        updateMultipleLogs,
        getNestedProperty,
        items,
        setItems,
        stocks,
        userInfo,
        logTemplates,
        deleteHeader,
        ProbeParameters,
        gettingData,
        setGettingData

    }









    let sortedLogs = [...logs]; // make a copy of logs array

    // Extract the sorting key and direction from the sortItem string
    if (sortItem) {
        const sortKey = sortItem.split(',')[0].split(': ')[1];
        const sortDirection = sortItem.split(',')[1].split(': ')[1];

        const sortedHeader = headers.find(header => header.id === sortKey);

        //if undefined, setSortItem to null
        if (sortedHeader === undefined) {
            setSortItem(null);
            return;
        }

        //filter out any undefined logs




        sortedLogs = sortedLogs.sort((a, b) => {
            let valueA = getNestedProperty(a, sortKey, sortedHeader?.getLabel, sortedHeader?.optionType, sortedHeader?.options, sortedHeader?.decimals);
            let valueB = getNestedProperty(b, sortKey, sortedHeader?.getLabel, sortedHeader?.optionType, sortedHeader?.options, sortedHeader?.decimals);



            if (sortedHeader.optionType === 'number' || sortedHeader.optionType === 'money') {
                valueA = valueA === undefined ? undefined : Number(valueA);
                valueB = valueB === undefined ? undefined : Number(valueB);
            }
            if (sortedHeader.optionType === 'percent') {
                let removepercent = valueA?.replace('%', '');
                valueA = removepercent === undefined ? undefined : Number(removepercent);
                removepercent = valueB?.replace('%', '');
                valueB = removepercent === undefined ? undefined : Number(removepercent);
            }



            // Handle cases where one or both values are undefined
            if (valueA === undefined && valueB !== undefined) {
                return 1;
            }
            if (valueB === undefined && valueA !== undefined) {
                return -1;
            }
            if (valueA === undefined && valueB === undefined) {
                return 0;
            }

            // Continue with your normal comparison for non-undefined values
            if (valueA < valueB) {
                return sortDirection === 'asc' ? -1 : 1;
            }
            if (valueA > valueB) {
                return sortDirection === 'asc' ? 1 : -1;
            }
            return 0;
        });
    }




    const buttonStyle = {
        borderRadius: 12,
        textTransform: 'none',
        margin: '0 8px',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
            boxShadow: 'none',
        },
    };



    const visibleHeaders = headers?.filter((header) => header.visible === true);




    const handleClose = () => {
        setDeleteDialog(false);
    };

    return (


        <Col span={24} style={{ paddingTop: 15, paddingBottom: 15, paddingLeft: 15, paddingRight: 15 }}>
            <Grid container spacing={0} style={{ width: '100%', margin: 0 }}>
                <Grid item xs={12} sm={10} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <CreateItem screenWidth={screenWidth} />
                </Grid>
                {/*  <Grid item xs={12} sm={8} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative', width: '100%', py: 2 }}>
                      
                        <Box sx={{ position: 'absolute', top: '50%', left: 0, width: '100%', borderTop: '1px solid #d8d8d8', zIndex: 1 }} />

                       
                        <Box sx={{ display: 'flex', alignItems: 'center', zIndex: 2, backgroundColor: '#f1f5fe', borderRadius: 4, padding: 2, }}>
                            <Button variant="contained" sx={{
                                borderRadius: 12,
                                textTransform: 'none',
                                margin: '0 8px',
                                boxShadow: 'none',
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                    boxShadow: 'none',
                                }
                                , backgroundColor: statusFilter === 'Saved' ? 'white' : 'transparent',
                                border: statusFilter === 'Saved' ? '1px solid #3f51b5' : '1px solid #d8d8d8'
                            }}
                                onClick={() => setStatusFilter('Saved')}
                            >
                                <Typography variant="body1" color="black" fontSize={16} fontWeight={statusFilter === 'Saved' ? 600 : 400}>Saved</Typography>
                            </Button>
                            <Button variant="contained" sx={{

                                borderRadius: 12,
                                textTransform: 'none',
                                margin: '0 8px',
                                boxShadow: 'none',
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                    boxShadow: 'none',
                                }
                                , backgroundColor: statusFilter === 'Draft' ? 'white' : 'transparent',
                                border: statusFilter === 'Draft' ? '1px solid #3f51b5' : '1px solid #d8d8d8'
                            }}
                                onClick={() => setStatusFilter('Draft')}
                            >
                                <Typography variant="body1" color="black" fontSize={16} fontWeight={statusFilter === 'Draft' ? 600 : 400}>Draft</Typography>
                            </Button>
                        </Box>
                    </Box>
                </Grid> */}
                <Grid item xs={12} sm={2}
                    style={{ textAlign: 'right', }}>
                    <MoreTableOptions prefKey={prefKey} {...props} selectedLogs={selectedLogs} setColumnEditor={setColumnEditor} sortedLogs={sortedLogs} visibleHeaders={visibleHeaders} />

                    {selectedLogs.length > 0 && (
                        <IconButton onClick={() => setDeleteDialog(true)}
                            disabled={selectedLogs.length === 0 ? true : false}
                        >
                            <Tooltip title="Delete Selected" placement="bottom">
                                <Badge badgeContent={selectedLogs.length} color="error" style={{ marginRight: 10 }}>
                                    <FontAwesomeIcon icon={faTrash} size="md" style={{ color: 'gray', cursor: 'pointer' }} />
                                </Badge>
                            </Tooltip>

                        </IconButton>
                    )}
                    <FilterTable {...props} />
                </Grid>

            </Grid>
            <Dialog
                open={deleteDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Are you sure you want to delete {selectedLogs.length} logs?
                </DialogTitle>

                <DialogActions>
                    <Button style={{
                        textTransform: 'none',
                    }} onClick={handleClose}>Cancel</Button>
                    <Button
                        style={{
                            fontWeight: 600,
                            textTransform: 'none',
                            color: 'red'
                        }}

                        onClick={() => {
                            deleteSelectedLogs(selectedLogs);
                            handleClose();
                        }}
                        autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

        </Col >




    );
};

export default LogsSubHeader;
