import EditIcon from '@mui/icons-material/Edit';
import { Button, CircularProgress, Divider, IconButton, Paper, Typography } from '@mui/material';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SearchIcon from '@mui/icons-material/Search';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Col, Modal, Popconfirm, Row } from 'antd';
import { collection, doc, getDocs, getFirestore, query, setDoc, updateDoc, where } from 'firebase/firestore';
import moment from 'moment';
import MUIDataTable from "mui-datatables";
import React from 'react';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';


import PreviewProfile from './previewProfile';
import './style.css';

import axios, * as others from 'axios';

export default class DepthProfiles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            data: [],
            loading: true,
            level: 'account',
            accounts: [],
            parameters: [],
            sites: [],
            accountsLoad: true,
            currentItem: {},
            activeTab: 'profiles',
            profileData: [],
            activeStep: 0,

            currentItem: {},
            previewModal: false,
            infoModal: false,


        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
            this.queryData()
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0)
        const { userInfo } = this.state;
        if (Object.values(userInfo).length === 0) {
            this.props.getUser()
        } else {

            this.queryParameters()
        }

    }

    queryData = async () => {
        const { userInfo, level } = this.state;
        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'profiles'), where('archived', '==', false));
        const queryAccountData = query(collection(db, "clients", userInfo.currentCompany, 'profiles'), where('archived', '==', false), where('account', '==', userInfo.currentAccount));
        const data = [];
        const dataList = [];
        const snap = await getDocs(level === 'account' ? queryAccountData : queryData);
        snap.forEach((doc) => {
            const units = doc.data().units || {};
            data.push({
                ...doc.data(), siteName: doc.data().site !== undefined ? doc.data().site.name : '',

            })




        });
        console.log(data)
        data.sort((a, b) => b.date - a.date)
        dataList.sort((a, b) => Number(b.date) - Number(a.date))
        this.setState({ data, loading: false })


    }

    queryAccounts = async () => {
        const { userInfo } = this.state;
        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'accounts'));
        const accounts = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            accounts.push({
                accountName: doc.data().accountName,
                key: doc.data().key,
                type: doc.data().seasonal || null,
                city: doc.data().city || null,
                countyName: doc.data().countyName || null,
                state: doc.data().state || null,
                routeDay: doc.data().routeDay || null,
                routeName: doc.data().routeName || null,
                visible: true
            })


        });
        console.log(accounts);
        accounts.sort((a, b) => a.accountName.localeCompare(b.accountName))
        this.setState({ accounts, })
        this.queryData()


    }


    queryParameters = async () => {
        const { userInfo, type } = this.state;
        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'parameters'),);
        const data = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            data.push({ ...doc.data(), label: doc.data().name })


        });
        console.log(data);

        data.sort((a, b) => a.name.localeCompare(b.name))
        this.setState({ parameters: data, })

        this.querySites()
    }
    querySites = async () => {
        const { userInfo, type } = this.state;
        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'sampleSites'), where('account', '==', userInfo.currentAccount), where('archived', "!=", true));
        const data = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            data.push({ ...doc.data(), label: doc.data().name })


        });
        console.log(data);
        data.sort((a, b) => a.name.localeCompare(b.name))
        this.setState({ sites: data, })

        this.queryAccounts()
    }



    updateCurrentItem = (boo, val) => {
        const { currentItem } = this.state;
        currentItem[boo] = val;
        this.setState({ currentItem })
    }

    updateProfileData = (index, boo, val) => {
        const { profileData } = this.state;

        if (boo === 'depthColumn') {
            const newProfileData = profileData.map((d, i) => {
                return ({ ...d, depthColumn: i === index ? true : false })
            })
            this.setState({ profileData: newProfileData })
        }
        else {
            profileData[index][boo] = val;
            this.setState({ profileData })
        }

    }

    depthInfo = (boo, val) => {
        const { depthInfo } = this.state;
        depthInfo[boo] = val;
        this.setState({ depthInfo })
    }


    createProfiles = async () => {
        const db = getFirestore();
        const { profileData, depthInfo, userInfo, account, data } = this.state;





        const depthIndex = profileData.map((d) => d.depthColumn).indexOf(true);
        console.log(depthIndex);
        console.log(profileData[depthIndex].data)

        const profiles = [];
        const results = [];
        profileData.map(async (d) => {





            if (d.depthColumn === false) {

                const key = uuid();
                const profile = {
                    site: depthInfo.site,
                    archived: false,
                    account: userInfo.currentAccount,
                    company: userInfo.currentCompany,
                    key,
                    date: depthInfo.date || moment().format('x'),
                    name: depthInfo.name || '',
                    parameterkey: d.newParameter.key,
                    description: depthInfo.description || '',
                    lat: depthInfo.lat === 0 ? account.lat : Number(depthInfo.lat),
                    lng: depthInfo.lng === 0 ? account.lng : Number(depthInfo.lng),
                }
                console.log(profile)


                console.log(d)


                d.data.map((a, i) => {


                    var time = moment(profile.date, 'x').format("YYYY-MM-DD HH:mm");
                    var timestamp = moment(profile.date, 'x').format("x");

                    results.push({
                        time: `'${time}'`,
                        dt: 123,
                        account: `'${userInfo.currentAccount}'`,
                        company: `'${userInfo.currentCompany}'`,
                        profilekey: `'${profile.key}'`,
                        key: `'${profile.key}'`,
                        parameterkey: `'${d.newParameter.key}'`,
                        depth: Number(profileData[depthIndex].data[i]),
                        value: Number(a),
                        lat: profile.lat,
                        lng: profile.lng

                    })




                })





                const docRef = doc(db, "clients", userInfo.currentCompany, 'depthProfiles', profile.key);
                await setDoc(docRef, profile).then(() => {
                    console.log("PROFILE ADDED")
                    this.props.openSnack("success", 'Profile Created!')


                }).catch((error) => {
                    console.log(error)
                })




            }







        })
        console.log(results)
        const hourlyVals = results.map((h) => `(${Object.values(h)})`);
        const hourlyString = hourlyVals.join();



        console.log(hourlyString)
        const proxyUrl = "https://mycorslake.herokuapp.com/";
        axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
            raw: `INSERT INTO depth_profile VALUES ${hourlyString} ON CONFLICT DO NOTHING; `
        })
            .then(async (response) => {
                // handle success
                console.log(response)




            });

        this.setState({ openModal: false, profileData: [], depthInfo: { graphics: JSON.stringify([]), lat: 0, lng: 0, name: '' } })
        this.queryData()


    }

    closeModals = () => {
        this.setState({ openModal: false, currentItem: {}, infoModal: false, previewModal: false })
    }



    render() {

        const { userInfo, data, loading, accounts, account, accountsLoad, stocks, currentItem, sites, infoModal, previewModal,
            openModal, screenWidth, products, parameters, activeTab, profileData, activeStep, depthInfo } = this.state;
        const db = getFirestore();
        const ViewItem = ({ row }) => {
            let navigate = useNavigate();
            return (
                <IconButton color="primary" size="small" onClick={async () => {

                    const userRef = doc(db, "users", userInfo.id, 'currentItems', 'currentFieldLog');
                    await setDoc(userRef, {
                        key: row.logKey
                    }).then(async () => {
                        const userRef = doc(db, "users", userInfo.id);
                        userInfo.currentAccount = row.account;
                        this.props.updateUserInfo('currentAccount', row.account);
                        this.setState({ userInfo })
                        await updateDoc(userRef, {
                            currentAccount: row.account
                        }).then(() => {
                            navigate("/fieldLog");
                        })
                    })
                }}>
                    <EditIcon color="inherit" />
                </IconButton>
            )

        }







        const AddDP = () => {

            let navigate = useNavigate();
            return (
                <Button style={{ textTransform: 'none', padding: 16, fontSize: 16 }} fullWidth variant="contained" color="primary"
                    onClick={() => {

                        navigate('/profile')

                    }}>+ Depth Profiles </Button>
            )


        }










        const XTable = ({ columns, header, data, openModal, accounts, products, parameters, loading }) => {
            let navigate = useNavigate();
            const getMuiTheme = () =>
                createTheme({
                    components: {
                        MUIDataTable: {
                            styleOverrides: {
                                root: {
                                    padding: '8px',
                                    boxShadow: 'none'
                                }
                            }
                        },
                        MuiTableRow: {
                            styleOverrides: {
                                root: {
                                    cursor: 'pointer',
                                    fontSize: '10px'

                                },
                                hover: {
                                    backgroundColor: 'green'
                                }

                            }
                        },
                        MuiTableCell: {
                            styleOverrides: {
                                root: {
                                    fontSize: '12px',
                                    padding: '2px'
                                }
                            }
                        }

                    }
                });




            const companyColumns = [


                {
                    name: 'profileName',
                    label: "Name",
                    options: {
                        filter: false,

                    }
                },

                {
                    name: 'parameterkey',
                    label: 'Parameter',
                    options: {
                        filter: true,
                        customBodyRender: this.renderValue = (a, b) => parameters.filter((f) => f.key === a)[0].name,
                    }
                },
                {
                    name: 'date',
                    label: "Date",
                    options: {
                        filter: false,
                        customBodyRender: this.renderValue = (a, b) => moment(a, 'x').format('MM/DD/YYYY')
                    }
                },


                {
                    name: 'account',
                    label: 'Account',
                    options: {
                        filter: true,
                        customBodyRender: this.renderValue = (a, b) => accounts.filter((f) => f.key === a)[0].accountName,

                    },
                },

                {
                    name: 'siteName',
                    label: 'Site',
                    options: {
                        filter: true,


                    },
                },
                {
                    name: 'key',
                    label: 'Preview',
                    options: {
                        filter: false,
                        viewColumns: false,
                        customBodyRender: this.renderValue = (a) => {
                            return (
                                <IconButton style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="small" itemKey={a}
                                    onClick={async () => {
                                        const index = data.map((d) => d.key).indexOf(a)
                                        this.setState({ currentItem: data[index], previewModal: true })
                                    }}>
                                    <SearchIcon />
                                </IconButton>
                            )
                        }
                    },
                },

                {
                    name: 'key',
                    label: 'Delete',
                    options: {
                        filter: false,
                        viewColumns: false,
                        customBodyRender: this.renderValue = (a) => {
                            return (
                                <Popconfirm itemKey={a}
                                    title="Are you sure to delete this item?"
                                    onConfirm={async () => {
                                        const itemRef = doc(db, "clients", userInfo.currentCompany, 'profiles', a);
                                        await updateDoc(itemRef, {
                                            archived: true
                                        }).then(() => {
                                            const newData = data.filter((f) => f.key !== a)
                                            this.setState({ data: newData })
                                        }).then(() => {
                                            this.props.openSnack('success', 'Profile Removed')
                                        })

                                    }}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <IconButton style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="small" itemKey={a}
                                    >
                                        <DeleteForeverIcon />
                                    </IconButton>
                                </Popconfirm>
                            )
                        }
                    },
                },






            ]



            return (
                <ThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable

                        title={''}
                        data={this.state.data}
                        columns={companyColumns}

                        options={{
                            rowsPerPage: 50,
                            textLabels: {
                                body: {
                                    noMatch: <div style={{}}>{loading ? "Loading Data" : "No Records Found"} {loading && (<CircularProgress />)}</div>
                                }
                            },
                            async onRowClick(a, b) {




                            },
                            downloadOptions: {
                                filename: 'Applications'
                            },
                            print: false,
                            selectableRows: false,

                        }}

                    />
                </ThemeProvider>
            );
        }



        return (
            <Row style={{ position: 'relative', top: 0 }}>



                <Col span={23}>
                    <Row align="middle">
                        <Col xs={16} sm={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>
                            <Typography variant="h4">Depth Profiles</Typography>
                        </Col>
                        <Col xs={6} sm={4} style={{ paddingTop: 30, paddingBottom: 15, textAlign: 'right' }}>
                            <AddDP />
                        </Col>
                        <Col span={24}>
                            <Divider />
                        </Col>
                    </Row>
                </Col>



                <Col span={23} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 30 }}>

                    <Paper elevation={16} style={{ padding: 20, borderRadius: 12 }}>
                        <Row>
                            <Col span={24} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 30 }}>
                                <XTable parameters={parameters} data={data} openModal={(item) => this.setState({ openModal: true, currentItem: item })} accounts={accounts} products={products} loading={loading} />

                            </Col>

                            {/* <Col span={24}>
                                <TabContext value={activeTab}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <TabList onChange={(e, tab) => this.setState({ activeTab: tab })} aria-label="charts">
                                            <Tab style={{ textTransform: 'none' }} label="Profiles" value="profiles" />
                                            <Tab style={{ textTransform: 'none' }} label="Query" value="query" />

                                        </TabList>
                                    </Box>

                                    <TabPanel value="profiles">
                                      
                                    </TabPanel>
                                    <TabPanel value="query">
                                        <Col span={24} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 30 }}>
                                            Charts

                                        </Col>
                                    </TabPanel>
                                </TabContext>
                            </Col> */}




                        </Row>
                    </Paper>

                </Col>

                <Modal title="" visible={openModal} onCancel={() => this.closeModals()} footer={null} width={'50%'} style={{ borderRadius: 40 }} >

                </Modal>

                <Modal title="" visible={previewModal} onCancel={() => this.closeModals()} footer={null} width={'80%'} height={'80%'} style={{ borderRadius: 40 }} >
                    <Row align="middle" gutter={[16, 16]} style={{ padding: 10, paddingBottom: 30 }} >
                        <Col xs={{ offset: 0, span: 24 }} sm={23} style={{ paddingBottom: 12, }}>
                            <Typography variant='h5'>Preview Profile</Typography>
                        </Col>
                        <Col xs={{ offset: 0, span: 24 }} sm={23} style={{ paddingBottom: 12, }}>
                            <PreviewProfile screenWidth={screenWidth} account={account} currentItem={currentItem} userInfo={userInfo} parameters={parameters} openSnack={this.props.openSnack} />
                        </Col>










                    </Row>
                </Modal>
                <Modal title="" visible={infoModal} onCancel={() => this.closeModals()} footer={null} width={'50%'} style={{ borderRadius: 40 }} >
                    <Row align="middle" gutter={[16, 16]} style={{ padding: 10, paddingBottom: 30 }} >
                        <Col xs={{ offset: 0, span: 24 }} sm={23} style={{ paddingBottom: 12, }}>
                            <Typography variant='h5'>Info Profile</Typography>
                        </Col>










                    </Row>
                </Modal>
            </Row>
        );
    }
} 