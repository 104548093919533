import { Button, Checkbox, Divider, OutlinedInput, Typography, Select, MenuItem, CircularProgress, Autocomplete, TextField, Switch, ButtonGroup } from '@mui/material';
import { Col, Popconfirm, Row } from 'antd';
import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { fullMobile } from './../util';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, } from 'firebase/firestore';

import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import uuid from 'react-uuid';


import DatePicker from "react-datepicker";
import queryString from 'query-string';
import axios from 'axios';
var randomColor = require('randomcolor');

export default function DeleteDataManagement({ userInfo, screenWidth, openSnack, company, account, probesRef, stationsRef }) {
    const [stations, setStations] = useState(stationsRef)
    const [probes, setProbes] = useState(probesRef)
    const [loadingStations, setLoadingStations] = useState(true)
    const [loadingProbes, setLoadingProbes] = useState(true)
    const [fromID, setFromID] = useState(null)
    const [toID, setToID] = useState(null)
    const [fromType, setFromType] = useState('probe')
    const [toType, setToType] = useState('probe')
    const [startDate, setStartDate] = useState(moment().subtract(1, 'days').format('x'))
    const [endDate, setEndDate] = useState(moment().format('x'))
    const [merging, setMerging] = useState(false)

    useEffect(() => {


    }, [])



    async function handleDelete() {
        setMerging(true)
        console.log(fromID)
        console.log(toID)
        //convert start and end date to 
        //get data from fromID
        const newStartDate = moment(startDate, 'x').tz("America/Los_Angeles").format("YYYY-MM-DD HH:mm");
        const newEndDate = moment(endDate, 'x').tz("America/Los_Angeles").format("YYYY-MM-DD HH:mm");
        const proxyUrl = "https://mycorslake.herokuapp.com/";

        axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgetsFire2/sqlRead', {
            raw: `
             DELETE FROM ${fromType === 'probe' ? 'probe_data' : 'node_data_new2'}
            WHERE ${fromType === 'probe' ? 'locationid' : 'nodeid'} = '${fromID.id}' AND time BETWEEN '${newStartDate}' AND '${newEndDate}';`
        }).then((res) => {


            openSnack("success", "Data deleted")
            setFromID(null)
            setMerging(false)



        }).catch((err) => {

            setMerging(false)
            openSnack("error", "Error merging data")
        })
    }


    const Checks = () => {
        const checksList = [fromID, fromType, startDate, endDate]
        const showDescription = true;
        for (let i = 0; i < checksList.length; i++) {
            if (checksList[i] === null) {
                return false
            }
        }
        //check if start date is before end date and also if they are not the same and also if start date is not in the future
        if (startDate >= endDate || startDate === endDate || startDate > moment().format('x')) {
            return false
        }


        console.log("checks passed")


        return (
            <Col span={23}>
                {merging ? <CircularProgress /> :
                    <Row align="middle" >
                        <Col xs={16} sm={20} style={{}}>
                            <Typography variant="h6">Are you sure you want to delete this data?</Typography>
                        </Col>

                        <Col span={24}>
                            <Popconfirm
                                title={<Row style={{ width: '33vw' }}>
                                    <Col span={24}>
                                        <Typography variant="h6">Are you sure you want to delete this data?</Typography>
                                    </Col>
                                    <Col span={24}>
                                        <Typography variant="body1">This action cannot be undone.</Typography>
                                    </Col>
                                    <Col span={24}>
                                        <Typography variant="body1">This action will delete the data from the "From" probe/node.</Typography>
                                    </Col>

                                </Row>}
                                onConfirm={handleDelete}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button variant="contained" color="primary"
                                    size="large"
                                    style={{
                                        textTransform: 'none',
                                        color: 'white',
                                        backgroundColor: '#404050',
                                        border: '1px solid red'
                                    }}


                                >Delete</Button>
                            </Popconfirm>
                        </Col>
                    </Row >
                }
            </Col >
        )


    }


    return (
        <Row style={{ fontFamily: 'Roboto, sans-serif', paddingLeft: 10, paddingTop: 10, marginLeft: 2, }} gutter={[12, 12]}>



            <Col span={23}>
                <Row align="middle">
                    <Col xs={16} sm={20} style={{}}>
                        <Typography variant="h6">From</Typography>
                    </Col>

                    <Col span={24}>

                        <Row align="middle" gutter={[12, 12]}>
                            <Col >
                                <ButtonGroup display="inline" variant="contained" aria-label="outlined primary button group"
                                    size="large"
                                >
                                    {["Probe", "Station"].map((item, index) => {
                                        return (
                                            <Button
                                                size="large"
                                                style={{
                                                    textTransform: 'none',
                                                    color: fromType === item.toLowerCase() ? 'white' : '#404050',
                                                    backgroundColor: fromType === item.toLowerCase() ? '#404050' : 'white',
                                                }}
                                                key={index}
                                                onClick={() => {
                                                    setFromType(item.toLowerCase())
                                                    setFromID(null)
                                                }}

                                                variant={fromType === item.toLowerCase() ? "contained" : "outlined"}
                                            >
                                                {item}
                                            </Button>
                                        )
                                    })}
                                </ButtonGroup>
                            </Col>

                            <Col span={12} >

                                <Autocomplete
                                    display="inline"
                                    style={{ marginTop: 10, width: 200 }}
                                    size="large"
                                    disablePortal
                                    id="combo-box-demo"
                                    options={fromType === 'probe' ? probes : stations}
                                    value={fromID}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={fromType === 'probe' ? "Select Probe" : "Select Station"}
                                            variant="outlined"
                                        />
                                    )}
                                    getOptionLabel={(option) => option.label}
                                    onChange={(a, b) => {
                                        setFromID(b)

                                    }}
                                    InputProps={{ style: { fontSize: 6 } }}


                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>



            <Col span={23}>
                <Row align="middle">
                    <Col xs={16} sm={20} style={{}}>
                        <Typography variant="h6">Time Range</Typography>
                    </Col>
                    <Col span={24}>
                        <Row gutter={[12, 12]} align="middle">
                            <Col >
                                <Typography fontSize={13} variant="body1">Start Date</Typography>
                            </Col>
                            <Col >

                                <DatePicker portalId="root-portal"
                                    showTimeSelect
                                    timeFormat="h:mm a"
                                    timeIntervals={1}
                                    timeCaption="time"
                                    dateFormat="MM/dd/yyyy h:mm a"
                                    selected={
                                        [undefined, '', "Invalid date", null].includes(startDate) ? new Date() : new Date(moment(startDate, 'x'))}
                                    onChange={(date) => {
                                        setStartDate(moment(date).format('x'))


                                    }} />


                            </Col>
                            <Col >
                                <Typography fontSize={13} variant="body1">End Date</Typography>
                            </Col>
                            <Col >

                                <DatePicker portalId="root-portal"
                                    showTimeSelect
                                    timeFormat="h:mm a"
                                    timeIntervals={1}
                                    timeCaption="time"
                                    dateFormat="MM/dd/yyyy h:mm a"
                                    selected={
                                        [undefined, '', "Invalid date", null].includes(endDate) ? new Date() : new Date(moment(endDate, 'x'))}
                                    onChange={(date) => {
                                        setEndDate(moment(date).format('x'))


                                    }} />


                            </Col>
                        </Row>
                    </Col>


                </Row>
            </Col>
            <Col span={23}>
                <Divider />
            </Col>
            <Col span={23}>
                <Row align="middle">
                    <Col xs={16} sm={20} style={{}}>
                        <Typography variant="h6">Summary</Typography>
                    </Col>
                    <Col span={24}>
                        <Row align="middle" gutter={[12, 12]}>
                            <Col span={24} >
                                <Typography variant="body1">From: {fromID ? fromID.label : ""}</Typography>
                            </Col>
                            <Col span={24} >
                                <Typography variant="body1">Start Date: {startDate ? moment(startDate, 'x').format('lll') : ""}</Typography>
                            </Col>
                            <Col span={24} >
                                <Typography variant="body1">End Date: {endDate ? moment(endDate, 'x').format('lll') : ""}</Typography>
                            </Col>

                        </Row>
                    </Col>
                </Row>
            </Col>
            <Col span={23}>
                <Divider />
            </Col>
            <Col xs={16} sm={20} style={{}}>
                <Typography variant="body1">
                    Be sure that the start date is before the end date, and that the start date is not in the future.

                </Typography>
            </Col>
            <Checks />





        </Row>
    );
}