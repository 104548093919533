import React, { useEffect, useState } from "react";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import CreateItem from "./components/createItem";
import LogsTable from "./pages/logTable";

const FieldLogsHomeNew = ({
    screenWidth,
    userInfo,
    account,
    openSnack
}) => {


    const [activeTab, setActiveTab] = useState('logs');
    const [loading, setLoading] = useState(true);
    const [preferences, setPreferences] = useState({});
    const [companyPreferences, setCompanyPreferences] = useState({});




    useEffect(() => {
        window.scrollTo(0, 0)
        getInfo()


        console.log('new logs')




    }, [])



    async function getInfo() {

        

        setTimeout(() => {
            setLoading(false)
        }, 1000);

    }

    const props = {
        screenWidth,
        userInfo,
        account,
        openSnack,
        preferences,
        setPreferences,
        companyPreferences,
        setCompanyPreferences,
        activeTab,
        setActiveTab,


    }




    if (loading) {
        return (
            <Row style={{ height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
                {/* Using the full height of the viewport to center content */}
                <Col span={24} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

                    {/* FontAwesome Spinner */}
                    <FontAwesomeIcon icon={faSpinner} spin size="3x" />

                    <Typography variant="h6" style={{ marginTop: 20 }}>
                        Loading your field logs...
                    </Typography>

                </Col>
            </Row>
        )
    }






    else {
        return (
            <Row style={{}} >
                <Col span={24} style={{}}>
                    <Row align="middle">

                        <Col xs={16} sm={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>
                            <Typography variant="h4" fontWeight={600} fontSize={30} color="black">Field Logs</Typography>
                        </Col>

                        <Col span={24}>
                            <Divider />
                        </Col>






                        {activeTab === 'logs' && (
                            <Col span={24}  >
                                <LogsTable {...props} />
                            </Col>
                        )}










                    </Row>
                </Col>

            </Row >
        );
    }

};

export default FieldLogsHomeNew;
