import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { Typography } from '@mui/material';
import { GoogleMap, LoadScript, useJsApiLoader } from "@react-google-maps/api";
import { createMap, getLayers, getMapInfo, handleCurrentLocation, updateInitialMapAttributes, updateLayerItem, updateMapCenter } from './map2Components/utils';
import ReturnHomeButton from './map2Components/ReturnHomeButton';
import CurrentLocationButton from './map2Components/CurrentLocationButton';
import './widgetMap2.css';
import AddLayersButton from './map2Components/AddLayersButton';
import LayersDrawer from './map2Components/LayersDrawer';
import MapLayers from './map2Components/MapLayers';
import DrawingSystem from './map2Components/DrawingSystem';



const containerStyle = {
    width: "100%",
    height: "100%",
};
 




export default function WidgetMap2(props) {
    const { attributes, account, widgetKey, userInfo, openSnack, screenWidth, } = props;
    const [currentDrawingLayer, setCurrentDrawingLayer] = useState(null);
    const [currentLayer, setCurrentLayer] = useState(null);
    const [loading, setLoading] = useState(false);
    const { lat, lng } = account;
    const [center, setCenter] = useState({ lat, lng });
    const [layers, setLayers] = useState([]);
    const [zoom, setZoom] = useState(18);
    const [mapInstance, setMapInstance] = useState(null);
    const [layersDrawerOpen, setLayersDrawerOpen] = useState(false);




    useEffect(() => {
        getMap() 
    }, []);

    const handleLayersButtonClick = () => {
        setLayersDrawerOpen(true);
    };

    const handleLayersDrawerClose = () => {
        setLayersDrawerOpen(false);
    };

    async function getMap() {
        const mapInfo = await getMapInfo(userInfo?.currentCompany, widgetKey, setCenter, setZoom, setLayers, setLoading);
        if (mapInfo === null) {
            createMap(userInfo?.currentCompany, widgetKey, account)
            setLoading(false);
        }
    }

    const handleMapLoad = (map) => {
        setMapInstance(map);
        setLoading(false);

        if (map) {
            //add a kml with the url https://firebasestorage.googleapis.com/v0/b/aquasource3.appspot.com/o/files%2FLake%20Stanleyf8e52971-bffd-1c7b-09a6-1baeb379a0c7%2Fwidgets%2FBathy.kml?alt=media&token=fb6e79c0-631d-4c33-a4cf-bb96888aef66

     

        }

    };

    const handleMapUpdate = (newCenter, newZoom) => {
        setCenter(newCenter);
        setZoom(newZoom);
    };
    const handleReturnHome = () => {
        const newCenter = { lat, lng };

        if (mapInstance) {
            mapInstance.setZoom(zoom - 2);
            setTimeout(() => {
                mapInstance.panTo(newCenter);
                mapInstance.setZoom(18);
            }, 500);
        }

        setCenter(newCenter);
    };



    const handleToggleLayerItem = async (layerId, itemId, updateData) => {
        // Call updateLayer with the new visible value

        await updateLayerItem(layerId, itemId, userInfo.currentCompany, widgetKey, updateData, setLayers);
        getLayers();
    };



    if (loading) {
        return (
            <div>
                <Typography>Loading map</Typography>
            </div>
        );
    } else {
        return (
            <div style={{ height: '100%', width: '100%', paddingBottom: 20, position: 'relative' }}>
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={zoom}
                    options={{
                        fullscreenControl: false,
                        mapTypeId: window.google.maps.MapTypeId.SATELLITE,
                        streetViewControl: false,
                        mapTypeControlOptions: {
                            mapTypeIds: ['satellite', 'roadmap'],
                        },
                    }}
                    tilt={0}
                    onLoad={handleMapLoad}

                    onDragEnd={() => updateMapCenter(mapInstance, handleMapUpdate, userInfo?.currentCompany, widgetKey)}
                    onZoomChanged={() => updateMapCenter(mapInstance, handleMapUpdate, userInfo?.currentCompany, widgetKey)}
                >

                    <MapLayers layers={layers} mapRef={mapInstance}
                        setLayers={setLayers} userInfo={userInfo}
                        widgetKey={widgetKey} currentLayer={currentLayer}
                        handleToggle={handleToggleLayerItem}
                        currentDrawingLayer={currentDrawingLayer}
                    />
                    <DrawingSystem mapRef={mapInstance} layers={layers} setLayers={setLayers} userInfo={userInfo} widgetKey={widgetKey} currentLayer={currentLayer} handleToggle={handleToggleLayerItem}
                        currentDrawingLayer={currentDrawingLayer} />


                </GoogleMap>
                <ReturnHomeButton onClick={() => handleReturnHome(account, setCenter, updateMapCenter, handleMapUpdate, userInfo, widgetKey)} />
                <CurrentLocationButton onClick={() => handleCurrentLocation(setCenter, updateMapCenter, handleMapUpdate, userInfo, widgetKey)} />
                <AddLayersButton onClick={handleLayersButtonClick} />


                <LayersDrawer userInfo={userInfo} layers={layers} widgetKey={widgetKey} openSnack={openSnack} screenWidth={screenWidth} currentDrawingLayer={currentDrawingLayer}
                    setCurrentDrawingLayer={setCurrentDrawingLayer} currentLayer={currentLayer} setCurrentLayer={setCurrentLayer} handleToggleLayerItem={handleToggleLayerItem}
                    open={layersDrawerOpen} onClose={handleLayersDrawerClose} getLayers={() => getLayers(setLayers, userInfo?.currentCompany, widgetKey)} setLayers={setLayers} />


            </div>
        )
    }
}