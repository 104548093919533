import React, { useRef } from 'react';
import { Circle, Marker, Polygon, Polyline } from '@react-google-maps/api';

const DrawnLayers = ({ drawnObjects, onShapeDrag, onShapeClick, onShapeDelete, layerColor, editing, mapRef }) => {
    const circleRefs = useRef([]);

    const drawnObjectElements = drawnObjects?.map((obj, index) => {
        const color = obj.color || 'orange';


        const handleShapeDrag = (e) => {
            console.log(e)

        };

        const handleShapeClick = () => {
            if (onShapeClick) {
                onShapeClick(index);
            }
        };

        switch (obj.type) {
            case 'marker':
                return (
                    <Marker
                        key={`drawnMarker-${index}`}
                        position={obj.position}
                        icon={{ fillColor: color }}
                        draggable={editing}
                        onDragEnd={handleShapeDrag}
                        onClick={handleShapeClick}
                        onRightClick={() => onShapeDelete(index)}
                    />
                );
            case 'polyline':
                return (
                    <Polyline
                        key={`drawnPolyline-${index}`}
                        path={obj.path}
                        options={{
                            strokeColor: color,
                            strokeWeight: 2,
                        }}
                        editable={editing}
                        onClick={handleShapeClick}
                        onDragEnd={handleShapeDrag}
                        onRightClick={() => onShapeDelete(index)}
                    />
                );
            case 'polygon':
                return (
                    <Polygon
                        key={`drawnPolygon-${index}`}
                        paths={obj.paths}
                        options={{
                            fillColor: color,
                            fillOpacity: 0.5,
                            strokeColor: color,
                            strokeWeight: 2,
                        }}
                        editable={editing}
                        onClick={handleShapeClick}
                        onDragEnd={handleShapeDrag}
                        onRightClick={() => onShapeDelete(index)}
                    />
                );
            case 'circle':
                console.log(obj)


                const circle = new window.google.maps.Circle({
                    key: `drawnCircle-${index}`,
                    center: obj.center,
                    radius: obj.radius,
                    strokeColor: color,
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: color,
                    fillOpacity: 0.35,
                    editable: editing,
                    draggable: editing,
                    map: mapRef,
                });
                circle.setMap(mapRef);
                window.google.maps.event.addListener(circle, 'set_at', (a) => {
                    console.log('set_at')
                    console.log(a)
                });
                return null
            default:
                return null;
        }
    });

    return <>{drawnObjectElements}</>;
};

export default DrawnLayers;
