const weatherUndergroundParameters = [
    { key: 'time', label: 'Time' },
    { key: 'temperaturehigh', label: 'Temperature High', title: "Temperature High" },
    { key: 'temperatureavg', label: 'Temperature Avg', title: "Temperature Avg" },
    { key: 'temperaturelow', label: 'Temperature Low', title: "Temperature Low" },
    { key: 'dewpointhigh', label: 'Dew Point High', title: "Dew Point High" },
    { key: 'dewpointavg', label: 'Dew Point Avg', title: "Dew Point Avg" },
    { key: 'dewpointlow', label: 'Dew Point Low', title: "Dew Point Low" },
    { key: 'humidityhigh', label: 'Humidity High', title: "Humidity High" },
    { key: 'humidityavg', label: 'Humidity Avg', title: "Humidity Avg" },
    { key: 'humiditylow', label: 'Humidity Low', title: "Humidity Low" },
    { key: 'windspeedhigh', label: 'Wind Speed High', title: "Wind Speed High" },
    { key: 'windspeedavg', label: 'Wind Speed Avg', title: "Wind Speed Avg" },
    { key: 'windspeedlow', label: 'Wind Speed Low', title: "Wind Speed Low" },
    { key: 'pressurehigh', label: 'Pressure High', title: "Pressure High" },
    { key: 'pressurelow', label: 'Pressure Low', title: "Pressure Low" },
    { key: 'precipitation', label: 'Precipitation', title: "Precipitation" },
    { key: 'account', label: 'Account', title: "Account" },
    { key: 'company', label: 'Company', title: "Company" },
    { key: 'location', label: 'Location', title: "Location" },
    { key: 'key', label: 'Key', title: "Key" },
    { key: 'lat', label: 'Lat', title: "Lat" },
    { key: 'lng', label: 'Lng' }
]

export default weatherUndergroundParameters;
