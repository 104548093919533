import { faGear, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@mui/material';
import { Col, Row, Typography } from 'antd';
import React, { useState, useEffect } from 'react';
import TimelineSettingsDrawer from './imageTimeline/settings';
import { createItem, getItem } from './imageTimeline/utils';
import TimelineCarousel from './imageTimeline/carousel';



const containerStyle = {
    width: "100%",
    height: "100%",
};





const WidgetImageTimeline = (props) => {
    const { attributes, account, widgetKey, userInfo, openSnack, screenWidth, openTimelineDrawer, setOpenTimelineDrawerFalse, updateAttributes } = props;
    const [currentDrawingLayer, setCurrentDrawingLayer] = useState(null);
    const [currentLayer, setCurrentLayer] = useState(null);
    const [loading, setLoading] = useState(true);
    const [item, setItem] = useState(null);
    const [madeChanges, setMadeChanges] = useState(false);

    const [settingsDrawerOpen, setSettingsDrawerOpen] = useState(false);


    useEffect(() => {
        console.log(attributes)
        getWidget()
    }, []);

    useEffect(() => {
        if (openTimelineDrawer) {
            openSettings();
        }
        setOpenTimelineDrawerFalse()
    }, [openTimelineDrawer]);

    async function getWidget() {
        const item = await getItem(userInfo, widgetKey, 'timelineWidgets', setLoading);
        if (item) {
            const data = item.data();
            console.log(data)
            setItem(data);
        } else {
            const item = await createItem(userInfo, widgetKey, 'timelineWidgets', setLoading);

            setItem(item);
        }
    }







    const openSettings = () => {
        setSettingsDrawerOpen(true);
    }

    const closeSettings = () => {
        setSettingsDrawerOpen(false);

        if (madeChanges) {
            updateAttributes(attributes)
            setLoading(true);
            getWidget();
            setMadeChanges(false);
        }

    }




    if (loading) {
        return (
            <div style={{ height: '100%', width: '100%', paddingBottom: 20, position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <FontAwesomeIcon icon={faSpinner} spin size={'3x'} color="#9ec1c9" />
                <Typography style={{ marginLeft: 10 }} variant="h6">Loading...</Typography>

            </div>
        );
    } else {

        return (
            <div style={{ height: '100%', width: '100%', paddingBottom: 20, position: 'relative' }}>
                <Row>
                    <Col span={24} style={{ textAlign: 'right' }}>
                        <TimelineCarousel
                            item={item}
                            userInfo={userInfo}
                            widgetKey={widgetKey}
                        />
                    </Col>
                </Row>
                <TimelineSettingsDrawer
                    open={settingsDrawerOpen}
                    closeSettings={closeSettings}
                    setMadeChanges={setMadeChanges}
                    item={item}
                    setItem={setItem}
                    userInfo={userInfo}
                    widgetKey={widgetKey}
                    attributes={attributes}
                    deleteWidget={props.deleteWidget}
                />


            </div>
        )
    }

}

export default WidgetImageTimeline;