import { collection, getDocs, getDoc, addDoc, updateDoc, doc, getFirestore, deleteDoc, writeBatch, setDoc, where, query, orderBy, limit, startAfter, getCountFromServer, serverTimestamp } from 'firebase/firestore';
import { getStorage, ref, getMetadata } from "firebase/storage";
import uuid from 'react-uuid';


export const getItem = async (userInfo, id, path, setLoading) => {
    const db = getFirestore();

    try {
        const itemRef = doc(db, 'clients', userInfo.currentCompany, path, id);
        const itemSnap = await getDoc(itemRef);
        if (itemSnap.exists()) {
            console.log('found')
            setLoading(false);
            return itemSnap;
        } else {
            return null;
        }

        // Add your Firestore code here
    } catch (error) {
        // Handle error
        return null;
    }
};
export const createItem = async (userInfo, id, path, setLoading) => {
    const db = getFirestore();

    try {
        const itemRef = doc(db, 'clients', userInfo.currentCompany, path, id);
        const newItem = {
            id: id,
            created: serverTimestamp(),
            camera: null,
            company: userInfo.currentCompany,
            account: userInfo.currentAccount,
            startToday: true,
        }

        await setDoc(itemRef, newItem);
        console.log('created')
        setLoading(false);
        return newItem;



        // Add your Firestore code here
    } catch (error) {
        // Handle error
        return null;
    }
};

export const getItems = async (userInfo, path) => {
    const db = getFirestore();
    try {
        console.log("SEARCHING FOR ITEMS")
        console.log(userInfo)
        console.log(path)

        const itemsRef = collection(db, "clients", userInfo.currentCompany, path);
        const itemsQuery = query(itemsRef, where("account", "==", userInfo.currentAccount));
        const itemsSnapshot = await getDocs(itemsQuery);
        const items = itemsSnapshot.docs.map((doc) => ({ ...doc.data() }));
        console.log(items)
        return items;
    } catch (error) {
        return [];
    }
};

export const updateItem = async (userInfo, id, path, key, value) => {
    const db = getFirestore();
    console.log(userInfo)
    console.log(id)
    console.log(path)
    console.log(key)
    console.log(value)
    try {
        const itemRef = doc(db, 'clients', userInfo.currentCompany, path, id);
        await updateDoc(itemRef, {
            [key]: value
        });
        console.log('updated')
        return 'success'
    }
    catch (error) {
        return 'error'
    }
}

export const fetchStream = async (item, userInfo, path, startAfterDoc, photoLimit) => {
    console.log(item.id)
    const db = getFirestore();
    try {
        let itemsRef = collection(db, "clients", userInfo.currentCompany, path);
        let itemsQuery = query(itemsRef, where('itemID', "==", item.id), orderBy("date", "desc"), limit(photoLimit));
        if (startAfterDoc) {
            itemsQuery = query(itemsRef, where('itemID', "==", item.id), orderBy("date", "desc"), startAfter(startAfterDoc), limit(photoLimit));
        }
        const itemsSnapshot = await getDocs(itemsQuery);
        const items = itemsSnapshot.docs.map((doc) => ({ ...doc.data() }));
        const lastVisible = itemsSnapshot.docs[itemsSnapshot.docs.length - 1];
        console.log(lastVisible)
        return { items, lastVisible };
    } catch (error) {
        console.error("Error fetching stream:", error);
        return { items: [], lastVisible: null };
    }
}



















