import { Breadcrumbs, Button, ButtonGroup, Divider, MenuItem, OutlinedInput, Typography, Select, TextField } from '@mui/material';
import { Col, Modal, Row, } from 'antd';
import React, { useEffect, useState, useMemo } from 'react';
import { fullMobile } from '../util';
import 'react-data-grid/lib/styles.css';
import uuid from 'react-uuid';
import { memo } from 'react';
import moment from 'moment-timezone';
import { queryAccountStaff, queryEditAccountPerson, queryEditDistributionGroup, queryPermissionTemplates, queryUsers } from '../../../firebase/config';
import { Link } from 'react-router-dom';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Box } from '@mui/system';
import './table.css'
import { permissionsList, states } from './permissions'
import { getFirestore, getDoc, doc, setDoc, updateDoc } from 'firebase/firestore';
//create style of row paddingTop and bottom 15
//create style of row paddingTop and bottom 15

const style = {
    row: {
        paddingTop: 15,
        paddingBottom: 15,
        paddingLeft: 15,
        paddingRight: 15,
        //align center in div
        display: 'flex',
        alignItems: 'center',

    }
}





export default function EditDistributionGroup(props) {
    const { userInfo, screenWidth, openSnack, company, account, staffRef, usersRef } = props;
    const [group, setGroup] = useState({})
    const [users, setUsers] = useState([]);
    const [staff, setStaff] = useState([]);
    const [combined, setCombined] = useState([])

    useEffect(() => {
        window.scrollTo(0, 0)
        getInfo()
    }, [])
    const db = getFirestore()

    async function getInfo() {
        const editGroup = await queryEditDistributionGroup(userInfo)
        console.log(editGroup)
        setGroup(editGroup)

        const users = await queryUsers(userInfo)
        setUsers(users)

        const staff = await queryAccountStaff(userInfo)
        setStaff(staff)

        combineUsersAndStaff(users, staff)

    }


    useEffect(() => {

    }, [usersRef])

    useEffect(() => {

    }, [staffRef])


    function combineUsersAndStaff(users, staff) {
        //find matching ids in users and staff and combine them into single object and add to data
        console.log(users)
        console.log(staff)
        let combined = []
        for (let i = 0; i < users.length; i++) {
            for (let j = 0; j < staff.length; j++) {
                const staffid = staff[j].key || staff[j].id;
                if (users[i].id === staffid) {
                    combined.push({ ...users[i], ...staff[j] })
                }
            }
        }
        console.log(combined)
        setCombined(combined)
    }


    //create 50 rows with the schema above columns
    //create array from 0 to 50




    async function handleUpdateGroup(boo, val) {
        const newGroup = { ...group }
        if (boo) {
            newGroup[boo] = val
        }
        setGroup(newGroup)
    }

    const SaveButton = () => {


        return (
            <Button variant={'contained'} display="inline"

                style={{
                    padding: 4, paddingTop: 8, paddingBottom: 8,
                    textTransform: 'none', backgroundColor: '#404050', fontSize: 13,
                }}
                onClick={async () => {
                    const newGroup = { ...group }
                    const docRef = doc(db, 'clients', userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'distributionGroups', group.key);
                    await updateDoc(docRef, newGroup);
                    openSnack('success', 'Group Updated')
                }}
            >
                Save Group
            </Button>
        )
    }





    return (

        <div style={{ fontFamily: 'Roboto, sans-serif', backgroundColor: '#f6f8fe', minHeight: '100vh' }}>
            <Row style={{ padding: 20, paddingTop: 50 }} >
                <Col {...fullMobile(24, 24)}>


                    <Breadcrumbs aria-label="breadcrumb">

                        <Link to="/accountDirectory" style={{ color: '#404050', fontWeight: 500 }} >
                            Click to Return to Site Directory
                        </Link>

                    </Breadcrumbs>
                </Col>
                <Col {...fullMobile(24, 24)} style={{ paddingTop: 50, paddingBottom: 10 }}>
                    <Typography variant="h3" style={{ fontWeight: 300, color: '#404050' }}>
                        Edit {group.name}
                    </Typography>

                </Col>
                <Col {...fullMobile(24, 24)}>
                    <Divider />
                </Col>
                <Col {...fullMobile(24, 24)} style={{ paddingTop: 10, paddingBottom: 10 }}>
                    <ButtonGroup variant="link" aria-label="contained primary button group">
                        <Button style={{ color: '#404050', textTransform: 'none' }} >
                            General
                        </Button>
                    </ButtonGroup>

                </Col>
                <Col {...fullMobile(24, 24)} >
                    <Divider />
                </Col>




                <Col {...fullMobile(24, 24)} style={{ paddingTop: 15, paddingLeft: 30, paddingRight: 30 }}>
                    <Row gutter={[12, 12]} align="middle" style={{ paddingBottom: 20, paddingTop: 10 }}>
                        <Col {...fullMobile(12, 12)}>
                            <Typography variant="h5" style={{ fontWeight: 500, color: '#404050' }}>
                                GROUP INFORMATION
                            </Typography>
                        </Col>
                        <Col {...fullMobile(12, 12)}>
                            <Typography variant="h5" style={{ fontWeight: 500, color: '#404050' }}>
                                MEMBER INFORMATION
                            </Typography>
                        </Col>
                    </Row>
                    <Row style={{ backgroundColor: 'white', }}  >
                        <Col {...fullMobile(12, 12)}>
                            <Row gutter={[12, 12]} align="middle" >
                                <Col {...fullMobile(8, 8)} style={style.row} >
                                    <Typography variant="body1" style={{ fontWeight: 400, color: '#404050' }}>
                                        Name
                                    </Typography>
                                </Col>
                                <Col {...fullMobile(16, 16)} style={style.row} >
                                    <OutlinedInput
                                        size="small"
                                        fullWidth
                                        id="outlined-adornment-amount"
                                        value={group.name}
                                        onChange={(e) => {
                                            handleUpdateGroup('name', e.target.value,)
                                        }}

                                    />
                                </Col>
                                <Col {...fullMobile(8, 8)} style={style.row} >
                                    <Typography variant="body1" style={{ fontWeight: 400, color: '#404050' }}>
                                        Description
                                    </Typography>
                                </Col>
                                <Col {...fullMobile(16, 16)} style={style.row} >
                                    <OutlinedInput
                                        size="small"
                                        fullWidth
                                        multiline
                                        rows={4}
                                        id="outlined-adornment-amount"
                                        value={group.description}
                                        onChange={(e) => {
                                            handleUpdateGroup('description', e.target.value,)
                                        }}

                                    />
                                </Col>

                            </Row>
                        </Col>
                        <Col {...fullMobile(12, 12)}>
                            <Row gutter={[12, 12]} align="middle" >
                                <Col {...fullMobile(8, 8)} style={style.row} >
                                    <Typography variant="body1" style={{ fontWeight: 400, color: '#404050' }}>
                                        Members
                                    </Typography>
                                </Col>
                                <Col {...fullMobile(16, 16)} style={style.row} >
                                    {combined.length > 0 && (<Autocomplete
                                        multiple
                                        disableCloseOnSelect
                                        fullWidth
                                        id="tags-standard"
                                        options={combined}
                                        getOptionLabel={(option) => option.label}
                                        value={group.members}
                                        onChange={(event, newValue) => {
                                            //take newvalue and remove all information from each object except for id, label, and key
                                            //then set group.members to newvalue

                                            const newMembers = newValue.map((member) => {
                                                const { id, label, key } = member
                                                return { id, label, key }
                                            })
                                            handleUpdateGroup('members', newMembers)

                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                placeholder="Members"
                                            />
                                        )}
                                    />)}
                                </Col>

                            </Row>
                        </Col>




                    </Row>



                </Col>

                <Col {...fullMobile(24, 24)} style={{ paddingTop: 15, paddingLeft: 30, paddingRight: 30, textAlign: 'right' }} >
                    <SaveButton />

                </Col>
















            </Row >


        </div >

    );
}