import React, { useEffect } from "react";
import { Input } from "@mui/material";

const HydroIDInput = ({
    currentStream,
    setCurrentStream,
    headerKey,
    options,
    hydroProbes
}) => {
    const [value, setValue] = React.useState(currentStream?.[headerKey]);  // Renamed from 'age' to 'value'

    const handleChange = (event) => {
        let val = event.target.value;
        console.log(hydroProbes.length)
        const probe = hydroProbes.find(probe => probe.id === Number(val));
        console.log(probe)
        console.log(probe?.gps?.latitude)
        console.log(probe?.gps?.longitude)
        if (probe) {
            setCurrentStream({ ...currentStream, [headerKey]: Number(val), probe: probe, hydroName: probe.name, lat: probe?.gps?.latitude, lon: probe?.gps?.longitude })
        } else {
            setCurrentStream({ ...currentStream, [headerKey]: Number(val), probe: null, hydroName: null, lat: null, lon: null })
        }
        setValue(val);
    };

    useEffect(() => {
        setValue(currentStream?.[headerKey]);
    }, [currentStream]);

    return (
        <Input
            fullWidth
            variant="standard"
            id="string-input"
            size="small"
            margin="dense"
            placeholder="Enter text"
            value={value}
            onChange={handleChange}
            sx={{
                '& .MuiInputBase-input': {
                    color: 'black',
                    fontWeight: 500,
                    fontSize: 14,
                },
            }}
        />
    );
};

export default HydroIDInput;
