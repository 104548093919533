import { Autocomplete, Button, Divider, FormControl, IconButton, Input, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { fullMobile } from '../util';

import AddIcon from '@mui/icons-material/Add';
import uuid from 'react-uuid';

import { DeleteOutline, DragIndicator } from '@mui/icons-material';
import { ReactSortable } from 'react-sortablejs';
import ProbeParameters from '../../../extra/probeParameters.json';
import { queryEquipment, queryParameters, queryProbes, queryStations } from '../../../firebase/config';
import { faCopy, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DropDownOptions } from './dropdownOptions';






const InspectionTable = ({ inspectionItems, updateInsepctionItem, setInspectionItems, item,
    equipment,
    parameters,
    nodes,
    probes,
    PPArray,
    userInfo,
    openSnack,
}) => {



    return (
        <ReactSortable
            list={inspectionItems}
            setList={setInspectionItems}
            animation={500}
            handle=".my-handle"
        >
            {inspectionItems.map((inspectionItem, index) => {
                return (

                    <Row key={item.id}
                        //change background color if index is even
                        style={{
                            backgroundColor:
                                inspectionItem.type === 'section' ? '#ffffff' :

                                    index % 2 === 0 ? '#f5f5f5' : '#ffffff',
                            //thick black bottom border if inspectionItem is a section
                            borderBottom: inspectionItem.type === 'section' ? '2px solid #404050' : 'none',
                            paddingTop: inspectionItem.type === 'section' ? 30 : 0,
                        }}

                    >
                        <Col span={22} style={{ padding: 5, paddingTop: 10, paddingBottom: 10 }}>
                            <Row align="middle" gutter={[8, 8]}>
                                <Col className="my-handle" {...fullMobile(1, 1)} style={{ cursor: 'grab' }}>

                                    <DragIndicator />
                                </Col>
                                <Col {...fullMobile(22, 22)} style={{}}>
                                    <Row align="middle" gutter={[8, 8]}>
                                        <Col {...fullMobile(12, 12)} style={{}}>
                                            {inspectionItem.type === 'section' ? null : (
                                                <Typography variant='body1' fontWeight={600} color="#404050" fontSize={16}>Item Label</Typography>
                                            )}
                                            {inspectionItem.type === 'section' ? <Input
                                                fullWidth
                                                size="small"
                                                style={{
                                                    fontWeight: 700,
                                                    fontSize: 22,
                                                    backgroundColor: '#ffffff',
                                                }}
                                                placeholder="Item Label"
                                                value={inspectionItem.label}
                                                onChange={(e) => {
                                                    updateInsepctionItem(index, 'label', e.target.value)
                                                }}
                                            /> : (
                                                <OutlinedInput
                                                    fullWidth
                                                    size="small"
                                                    style={{
                                                        fontWeight: 500,
                                                        backgroundColor: '#ffffff',
                                                    }}
                                                    placeholder="Item Label"
                                                    value={inspectionItem.label}
                                                    onChange={(e) => {
                                                        updateInsepctionItem(index, 'label', e.target.value)
                                                    }}
                                                />
                                            )}

                                        </Col>

                                        <Col {...fullMobile(5, 5)} style={{}}>
                                            <Typography variant='body1' fontWeight={600} color="#404050" fontSize={16}>Item Type</Typography>
                                            <Select
                                                size="small"
                                                margin='dense'
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                placeholder='Item Type'
                                                value={inspectionItem.type}
                                                style={{
                                                    fontWeight: 500,
                                                    backgroundColor: '#ffffff',
                                                }}
                                                onChange={(e) => {
                                                    updateInsepctionItem(index, 'type', e.target.value)
                                                }}
                                                fullWidth
                                            >
                                                <MenuItem value={'observation'}>Observation</MenuItem>
                                                <MenuItem value={'data'}>Data</MenuItem>
                                                <MenuItem value={'checkbox'}>Checkbox</MenuItem>
                                                <MenuItem value={'select'}>Dropdown Single Select</MenuItem>
                                                <MenuItem value={'selectMulti'}>Dropdown Multi Select</MenuItem>
                                                <MenuItem value={'equipment'}>Equipment</MenuItem>
                                                <MenuItem value={'section'}>Section</MenuItem>

                                            </Select>

                                        </Col>

                                        <Col {...fullMobile(7, 7)} style={{}}>
                                            {inspectionItem.type.includes('select') && (
                                                <Row>
                                                    <Col span={24}>
                                                        <Typography variant='body1' fontWeight={600} color="#404050" fontSize={16}>Dropdown Options</Typography>
                                                        <DropDownOptions userInfo={userInfo} openSnack={openSnack} setOptions={(options) => {
                                                            updateInsepctionItem(index, 'dropdownOptions', options)
                                                        }}
                                                            options={inspectionItem.dropdownOptions || []}

                                                        />
                                                    </Col>
                                                </Row>

                                            )}




                                        </Col>

                                        {inspectionItem.type !== 'section' && (
                                            <Col {...fullMobile(24, 24)} style={{}}>
                                                {
                                                    inspectionItem.type === 'observation' ?
                                                        <Typography variant='body1' fontWeight={400} fontSize={14} color="gray">This will create an editable text box. </Typography>
                                                        : inspectionItem.type === 'data' ?
                                                            <Typography variant='body1' fontWeight={400} fontSize={14} color="gray">This will create a data entry box. </Typography>
                                                            : inspectionItem.type === 'checkbox' ?
                                                                <Typography variant='body1' fontWeight={400} fontSize={14} color="gray">This will create a checkbox. </Typography>
                                                                : inspectionItem.type === 'select' ?
                                                                    <Typography variant='body1' fontWeight={400} fontSize={14} color="gray">This will create a dropdown menu with a single select. </Typography>
                                                                    : inspectionItem.type === 'selectMulti' ?
                                                                        <Typography variant='body1' fontWeight={400} fontSize={14} color="gray">This will create a dropdown menu with a multiple select. </Typography>
                                                                        : inspectionItem.type === 'equipment' ?
                                                                            <Typography variant='body1' fontWeight={400} fontSize={14} color="gray">This will create a dropdown menu of equipment. </Typography>
                                                                            : null


                                                }

                                            </Col>
                                        )}





                                        {inspectionItem.type === 'equipment' && (
                                            <Col {...fullMobile(8, 8)} style={{}}>
                                                <Typography variant='body1' fontWeight={600} color="#404050" fontSize={16}>Equipment Item: </Typography>
                                                <Autocomplete

                                                    disableClearable
                                                    size="small"
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={equipment.sort((a, b) => -b.label.localeCompare(a.label))}
                                                    value={equipment.find((a) => a.key === inspectionItem.equipment)?.label || null}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            style={{
                                                                fontWeight: 500,
                                                            }}
                                                            placeholder="Equipment Item"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                    onChange={(a, b) => {
                                                        updateInsepctionItem(index, 'equipment', b.key)
                                                    }}
                                                />


                                            </Col>
                                        )}

                                        {inspectionItem.type === 'data' && (
                                            <Col {...fullMobile(8, 8)} style={{}}>
                                                <Typography variant='body1' fontWeight={600} color="#404050" fontSize={16}>Parameter: </Typography>
                                                <Autocomplete

                                                    disableClearable
                                                    size="small"
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={[...PPArray, ...parameters].sort((a, b) => -b.label.localeCompare(a.label))}
                                                    value={[...PPArray, ...parameters].find((a) => a.key === inspectionItem.parameterid)?.label || null}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            style={{
                                                                fontWeight: 500,
                                                            }}
                                                            placeholder="Parameter"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                    onChange={(a, b) => {
                                                        updateInsepctionItem(index, 'parameterid', b.key)
                                                    }}
                                                />


                                            </Col>
                                        )}
                                        {inspectionItem.type === 'data' && (
                                            <Col {...fullMobile(8, 8)} style={{}}>
                                                <Typography variant='body1' fontWeight={600} color="#404050" fontSize={16}>Node: </Typography>
                                                <Autocomplete

                                                    disableClearable
                                                    size="small"
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={nodes.sort((a, b) => -b.label.localeCompare(a.label))}
                                                    value={nodes.find((a) => a.id === inspectionItem.nodeid)?.label || null}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            placeholder="Source"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                    onChange={(a, b) => {
                                                        updateInsepctionItem(index, 'nodeid', b.id)
                                                    }}
                                                    InputProps={{ style: { fontSize: 6 } }}


                                                />


                                            </Col>
                                        )}
                                    </Row>

                                </Col>





                            </Row>

                        </Col>
                        <Col span={2} style={{ textAlign: 'right' }}>
                            <IconButton display="inline" onClick={() => {
                                let newInspectionItems = [...inspectionItems];
                                newInspectionItems.splice(index, 1);
                                setInspectionItems(newInspectionItems);
                            }}>
                                <FontAwesomeIcon icon={faTrashAlt} color='#e33030bf' size="sm" />
                            </IconButton>

                            <IconButton display="inline" onClick={() => {
                                //duplicate the item
                                let newInspectionItems = [...inspectionItems];
                                newInspectionItems.splice(index, 0, { ...inspectionItem, id: uuid() });
                                setInspectionItems(newInspectionItems);
                            }}>
                                <FontAwesomeIcon icon={faCopy} color='#404050' size="sm" style={{ marginLeft: 5 }} />
                            </IconButton>
                        </Col>



                        <Col {...fullMobile(24, 24)} style={{ paddingTop: 5 }}>
                            <Divider />
                        </Col>

                    </Row >

                )
            })}

        </ReactSortable >

    )

}



export { InspectionTable };