import React, { useState } from "react";


import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle, faRedo, faSpinner, faTrash, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Tooltip, Typography } from '@mui/material';
import { Col, Row } from 'antd';

import ColumnSelection from "./columnSelection";
import './streamList.css';


import StreamCreateList from "./streamCreateList";

import FilterTable from "./filterTable";
import MoreTableOptions from "./moreTableOptionsTool";
import SelectableTool from "./selctableTool";
import BulkEditStreams from "./bulkEditStreams";





const StreamsSubHeader = (
    {
        account,
        prefKey,
        type,
        streams,
        sortType,
        setStreams,
        setSortType,
        screenWidth,
        showInactive,
        setShowInactive,
        loading,
        setLoading,
        currentStream,
        setCurrentStream,
        deleteStream,
        updateStream,
        setStreamDrawer,
        duplicateStream,
        updateStreamsPosition,
        headers,
        setHeaders,
        openSnack,
        getNestedProperty,
        columnFilters,
        setColumnFilters,
        fullStreams,
        sortItem,
        setSortItem,
        createStream,
        selectable,
        setSelectable,
        deleteSelectedStreams,
        bulkEditor,
        setBulkEditor,
        staff,
        accounts,
        updateMultipleStreams,
        items,
        setItems,
        stocks,
        userInfo,
        streamTemplates,
        deleteStreamTemplate,
        columnEditor,
        setColumnEditor,
        deleteHeader,
        undoHistory,
        setUndoHistory,
        redoHistory,
        setRedoHistory,
        ProbeParameters,
        gettingData,
        setGettingData,
        loadingProbes
    }

) => {

    const [hoverKey, setHoverKey] = useState(null);
    const [headersHistory, setHeadersHistory] = useState([headers]);
    const [currentIndex, setCurrentIndex] = useState(0);

    const [initialWidth, setInitialWidth] = useState(null);
    const [firstClickedIndex, setFirstClickedIndex] = useState(null);
    const [allSelected, setAllSelected] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);








    const props = {
        headers,
        setHeaders,
        openSnack,
        headers, setHeaders, redoHistory, setRedoHistory, undoHistory, setUndoHistory, streams, initialWidth, getNestedProperty,
        columnFilters,
        setColumnFilters,
        fullStreams,
        setStreams,
        selectable,
        setSelectable,
        updateStream,
        setCurrentStream,
        currentStream,
        showInactive,
        setStreamDrawer,
        deleteSelectedStreams,
        duplicateStream,
        deleteStream,
        updateStreamsPosition,
        sortItem,
        setSortItem,
        setBulkEditor,
        bulkEditor,
        staff,
        account,
        accounts,
        updateMultipleStreams,
        getNestedProperty,
        items,
        setItems,
        stocks,
        userInfo,
        streamTemplates,
        deleteHeader,
        ProbeParameters,
        gettingData,
        setGettingData

    }









    let sortedStreams = [...streams]; // make a copy of streams array

    // Extract the sorting key and direction from the sortItem string
    if (sortItem) {
        const sortKey = sortItem.split(',')[0].split(': ')[1];
        const sortDirection = sortItem.split(',')[1].split(': ')[1];

        const sortedHeader = headers.find(header => header.id === sortKey);

        //if undefined, setSortItem to null
        if (sortedHeader === undefined) {
            setSortItem(null);
            return;
        }

        //filter out any undefined streams




        sortedStreams = sortedStreams.sort((a, b) => {
            let valueA = getNestedProperty(a, sortKey, sortedHeader?.getLabel, sortedHeader?.optionType, sortedHeader?.options, sortedHeader?.decimals);
            let valueB = getNestedProperty(b, sortKey, sortedHeader?.getLabel, sortedHeader?.optionType, sortedHeader?.options, sortedHeader?.decimals);



            if (sortedHeader.optionType === 'number' || sortedHeader.optionType === 'money') {
                valueA = valueA === undefined ? undefined : Number(valueA);
                valueB = valueB === undefined ? undefined : Number(valueB);
            }
            if (sortedHeader.optionType === 'percent') {
                let removepercent = valueA?.replace('%', '');
                valueA = removepercent === undefined ? undefined : Number(removepercent);
                removepercent = valueB?.replace('%', '');
                valueB = removepercent === undefined ? undefined : Number(removepercent);
            }



            // Handle cases where one or both values are undefined
            if (valueA === undefined && valueB !== undefined) {
                return 1;
            }
            if (valueB === undefined && valueA !== undefined) {
                return -1;
            }
            if (valueA === undefined && valueB === undefined) {
                return 0;
            }

            // Continue with your normal comparison for non-undefined values
            if (valueA < valueB) {
                return sortDirection === 'asc' ? -1 : 1;
            }
            if (valueA > valueB) {
                return sortDirection === 'asc' ? 1 : -1;
            }
            return 0;
        });
    }






    // ... (existing code in the return statement)

    const redo = () => {
        try {
            if (redoHistory.length === 0) return;
            const nextHistory = redoHistory.pop();
            setUndoHistory([...undoHistory, headers]); // Save the current state to undo
            setHeaders(nextHistory);  // Set headers to the next saved state
            setRedoHistory(redoHistory);  // Update redo history
        }
        catch (error) {
            console.error(error);
        }
    };

    const undo = () => {
        if (undoHistory.length === 0) return;
        const lastHistory = undoHistory.pop()
        setRedoHistory([...redoHistory, headers]); // Save the current state to redo

        lastHistory.forEach((header, i) => {
            header.position = i;
        });
        setHeaders(lastHistory);  // Set headers to the last saved state

        setUndoHistory(undoHistory);  // Update undo history
    };


    const selectedStreams = streams?.filter(stream => stream.selected === true);


    const visibleHeaders = headers?.filter((header) => header.visible === true);




    const handleClose = () => {
        setDeleteDialog(false);
    };

    return (

        <Col span={24} style={{ paddingTop: 15, paddingBottom: 15 }}>
            <Row>
                <Col span={12} style={{
                    display: 'inline'
                }}>

                    <StreamCreateList
                        userInfo={userInfo}
                        openSnack={openSnack}
                        account={account}
                        prefKey={prefKey}
                        createStream={createStream}
                        fullStreams={fullStreams}
                        setCurrentStream={setCurrentStream}
                        setStreamDrawer={setStreamDrawer}
                        streamTemplates={streamTemplates}
                        deleteStreamTemplate={deleteStreamTemplate}
                    />


                    {prefKey = 'probe' && (<Typography variant="h6" style={{ display: 'inline', marginLeft: 10, marginRight: 10 }}>
                        {loadingProbes ? 'Loading Probes...' : ''}
                        <FontAwesomeIcon icon={loadingProbes ? faSpinner : faCheckCircle} size='xl' spin={loadingProbes} style={{ color: loadingProbes ? '#3f51b5' : '#3f51b5' }} />
                    </Typography>)}



                </Col>
                <BulkEditStreams {...props} bulkEditor={bulkEditor} setBulkEditor={setBulkEditor} selectedStreams={selectedStreams} openSnack={openSnack} />
                <Col span={12} style={{ textAlign: 'right' }}>


                    <MoreTableOptions prefKey={prefKey} {...props} selectedStreams={selectedStreams} setColumnEditor={setColumnEditor} setBulkEditor={setBulkEditor} sortedStreams={sortedStreams} visibleHeaders={visibleHeaders} />

                    {selectedStreams.length > 0 && (
                        <IconButton onClick={() => setDeleteDialog(true)}
                            disabled={selectedStreams.length === 0 ? true : false}
                        >
                            <Tooltip title="Delete Selected" placement="bottom">
                                <FontAwesomeIcon icon={faTrash} size="md" style={{ color: 'gray', cursor: 'pointer' }} />
                            </Tooltip>

                        </IconButton>
                    )}
                    <Dialog
                        open={deleteDialog}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            Are you sure you want to delete {selectedStreams.length} streams?
                        </DialogTitle>

                        <DialogActions>
                            <Button style={{
                                textTransform: 'none',
                            }} onClick={handleClose}>Cancel</Button>
                            <Button
                                style={{
                                    fontWeight: 600,
                                    textTransform: 'none',
                                    color: 'red'
                                }}

                                onClick={() => {
                                    deleteSelectedStreams(selectedStreams);
                                    handleClose();
                                }}
                                autoFocus>
                                Delete
                            </Button>
                        </DialogActions>
                    </Dialog>



                    <SelectableTool {...props} totalSelected={selectedStreams.length} />


                    <IconButton onClick={() => setShowInactive(!showInactive)}

                    >
                        <Tooltip title="Toggle Inactive" placement="bottom">
                            <FontAwesomeIcon icon={showInactive ? faEye : faEyeSlash}
                                size="md" style={{ color: '#3f51b5', cursor: 'pointer' }} />
                        </Tooltip>

                    </IconButton>
                    <FilterTable {...props} />
                    {type === 'table' && (<ColumnSelection {...props} />)}

                    {type === 'table' && (<IconButton onClick={undo}
                        disabled={undoHistory.length === 0 ? true : false}
                    >
                        <Tooltip title="Undo" placement="bottom">
                            <FontAwesomeIcon icon={faUndo} size="xs" style={{ color: undoHistory.length === 0 ? "white" : '#3f51b5', cursor: 'pointer' }} />
                        </Tooltip>

                    </IconButton>
                    )}





                    {type === 'table' && (<IconButton onClick={redo}
                        disabled={redoHistory.length === 0 ? true : false}

                    >
                        <Tooltip title="Redo" placement="bottom">
                            <FontAwesomeIcon icon={faRedo} size="xs" style={{ color: redoHistory.length === 0 ? "white" : '#3f51b5', cursor: 'pointer' }} />
                        </Tooltip>
                    </IconButton>
                    )}
                </Col>
            </Row>


        </Col >




    );
};

export default StreamsSubHeader;
