import React, { useState, useRef, useEffect } from "react";
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Typography,
    Button,
    Container,
    Grid,
    CardActions,
    Autocomplete,
    TextField,
    Select,
    MenuItem,
    OutlinedInput,
    InputAdornment,
    IconButton
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnchorCircleXmark, faCamera, faCircleCheck, faCircleXmark, faComment, faFileCsv } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import './taskList.css';
import { formatCodesData } from "../uploadCSV/functions";
import SearchIcon from '@mui/icons-material/Search';

const MapDataMulti = (props) => {
    const {

        templates,
        setTemplates,
        template,
        setTemplate,
        steps,
        setSteps,
        currentStep,
        item,
        setItem,
        nextStep,
        backStep,
        screenWidth,
        openSnack,
        userInfo,
        uploading,
        setUploading,
        codes,
        setCodes,
        parameters,
        setParameters,
        stations,
        setStations,
        unitsArray,
        setData,
        data,
        meta,
        setMeta,
        setHeaders,
        headers,
        checkErrors



    } = props;


    const [filteredHeaders, setFilteredHeaders] = useState(headers);

    const [requiredHeaders, setRequiredHeaders] = useState([
        {
            label: "Date",
            value: "date",
            included: false,
            required: true
        },
        {
            label: "Nodes",
            value: "node",
            included: false,
            required: true
        },

    ]);
    const [searchQuery, setSearchQuery] = useState('');
    const requiredKeys = [...requiredHeaders].filter((r) => r.required === true).map(header => header.value);
    const usedHeaders = [...headers].filter((r) => r.included === true).map(header => header.mappedField);


    async function validateMappedFields() {
        const newHeaders = [...headers];
        const mappedFields = newHeaders.filter((f) => f.included === true).map(header => header.mappedFieldLabel);

        const requiredFields = requiredHeaders.filter(header => header.required).map(header => header.label);
        const missingFields = requiredFields.filter(field => !mappedFields.includes(field));

        console.log(data)

        if (missingFields.length > 0) {
            openSnack('error', `Please map the following fields: ${missingFields.join(', ')}`);
        } else {
            // Filter out headers with original names
            const headersWithOriginalNames = newHeaders.filter((h) => h?.originalName);

            // Update data based on headers with original names
            const newData = [...data];
            newData.forEach((item) => {
                headersWithOriginalNames.forEach((header) => {
                    if (item.hasOwnProperty(header.originalName)) {
                        item[header.name] = item[header.originalName];
                        delete item[header.originalName];
                    }
                });
            });

            console.log(newData)

            setData(newData);

            const codeHeader = newHeaders.find(header => header.mappedField === 'codes' && header.included === true);
            const dateHeader = newHeaders.find(header => header.mappedField === 'date' && header.included === true);

            if (codeHeader !== undefined) {
                const formattedData = await formatCodesData({ data: newData, headers: newHeaders });

                formattedData.forEach((row) => {
                    const { errorsCount, warningsCount } = checkErrors(row);
                    row.errorsCount = errorsCount;
                    row.warningsCount = warningsCount;
                    row.issues = errorsCount + warningsCount;
                })
                console.log(formattedData)
                setData(formattedData);
                nextStep();
            } else if (dateHeader) {
                // Use filter to remove rows where the date is an empty string
                const filteredData = newData.filter(row => row[dateHeader.name] !== '');

                // Then apply the same logic to add errorsCount, warningsCount, and issues
                filteredData.forEach(row => {
                    const { errorsCount, warningsCount } = checkErrors(row);
                    row.errorsCount = errorsCount;
                    row.warningsCount = warningsCount;
                    row.issues = errorsCount + warningsCount;
                });

                console.log(filteredData);
                setData(filteredData); // Set the filtered data without the empty date rows
                nextStep();
            } else {
                // Handle the case where there is no dateHeader defined
                console.log(newData)
                nextStep();
            }
        }
    }





    const CardItem = ({ header, item, index, }) => {
        //remove blanks from tableData
        console.log(header.name)
        console.log(header)
        const headerName = header.originalName || header.name
        const tableData = data.filter(row => row[headerName] !== "" && row[headerName] !== undefined && row[headerName] !== null) || [];
        const [isHovered, setIsHovered] = useState(null);
        console.log(tableData)

        return (
            <Card
                key={`${header.name}-${index}`}
                // Attach ref
                variant="outlined"
                style={{ display: 'flex', flexDirection: 'column' }}  // Using flexbox

                sx={{
                    height: '100%',
                    borderRadius: 2,
                    cursor: 'pointer',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                    border: header.included ? '3px solid #3d51b5' : '1px solid #9f9f9f',
                    transition: 'box-shadow 0.3s',
                    '&:hover': {
                        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)'
                    },
                }}
            >


                {/* Card Header */}
                <CardHeader sx={{

                }}
                    title={
                        <Box>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={11} sm={11} md={11}>
                                    <Typography variant="h6" fontWeight={700} fontSize={18}>
                                        {header?.name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1} sm={1} md={1} >
                                    <Typography variant="h6" fontWeight={400} fontSize={14} color={'#9f9f9f'}>
                                        <FontAwesomeIcon size="xl" icon={
                                            header?.included === false ? faCircleXmark :
                                                header?.included === true && header?.mappedField ?
                                                    faCircleCheck : faCircle

                                        } color={header?.included === false ? 'red' : header?.included === true && header?.mappedField ? 'green' : '#9f9f9f'}
                                            onClick={() => {
                                                const newHeaders = [...headers];
                                                newHeaders[index].included = !newHeaders[index].included;
                                                setHeaders(newHeaders);
                                            }}
                                        />
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>

                    }
                />

                {/* Card Content */}
                <CardContent style={{ flex: 1, }}
                    sx={{
                        pt: 0, mt: 0
                    }}>  {/* Add flex: 1 to occupy available space */}
                    <Autocomplete
                        size="small"
                        options={requiredHeaders}
                        sx={{
                            ".inputRoot": {
                                fontSize: 14,
                                fontWeight: 500,
                            },
                            input: {
                                padding: 0,
                                height: 30,
                            },
                        }}
                        InputPro
                        renderInput={(params) => <TextField {...params} placeholder="Mapped Field"
                            margin="dense"
                            sx={{
                                ".MuiInputBase-root": {
                                    height: 50,
                                    fontSize: 16,
                                    fontWeight: 600,
                                },
                            }}

                        />}
                        defaultValue={requiredHeaders.find((option) => option.value === header?.mappedField) || null}
                        onChange={(event, newValue) => {
                            console.log(newValue)
                            if (newValue === null) {
                                const newHeaders = [...headers];
                                newHeaders[index].mappedField = null;
                                newHeaders[index].mappedFieldLabel = null;
                                setHeaders(newHeaders);
                                return;
                            }
                            if (usedHeaders.includes(newValue?.value)) {
                                openSnack('error', `This field is already mapped to another column`);
                                return;
                            }

                            const newHeaders = [...headers];
                            const i = newHeaders.findIndex((h) => h.name === header.name);
                            newHeaders[i].mappedField = newValue?.value;
                            newHeaders[i].mappedFieldLabel = newValue?.label;
                            newHeaders[index].required = newValue?.required || false;

                            setHeaders(newHeaders);
                        }}
                        renderOption={(props, option) => {
                            const index = requiredHeaders.indexOf(option);


                            return (
                                <li {...props} style={{
                                    backgroundColor: usedHeaders.includes(option.value) ? '#f2f4f7' : isHovered === `${header.name} -${index} ` ? '#eef1ff' : 'white',
                                    color: usedHeaders.includes(option.value) ? 'gray' : 'black',


                                }}

                                    onMouseEnter={() => setIsHovered(`${header.name} -${index} `)}

                                >
                                    <Typography variant="body1" fontSize={16} fontWeight={500}>
                                        {option.label}
                                        {option.required && <span style={{ color: 'red' }}>*</span>}
                                    </Typography>
                                </li>
                            )
                        }}
                    />

                    {header.mappedField === "date" && (
                        <Typography variant="body1" fontWeight={600} fontSize={16} component="h4"  >
                            Date Format <span style={{ color: 'red' }}>*</span>
                        </Typography>
                    )}
                    {header.mappedField === "date" && (
                        <Select fullWidth
                            notched={false}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={header?.dateFormat || 'MM/DD/YYYY'}
                            label="Layer"
                            style={{ backgroundColor: 'white' }}
                            onChange={(e) => {
                                const newHeaders = [...headers];
                                newHeaders[index].dateFormat = e.target.value;
                                setHeaders(newHeaders);
                            }}

                        >
                            <MenuItem value={'MM/DD/YYYY'}>MM/DD/YYYY</MenuItem>
                            <MenuItem value={'YYYY/MM/DD'}>YYYY/MM/DD</MenuItem>
                            <MenuItem value={'YYYY-MM-DD'}>YYYY-MM-DD</MenuItem>
                            <MenuItem value={'MM-DD-YYYY'}>MM-DD-YYYY</MenuItem>
                            <MenuItem value={'YYYY-MM-DD h:mm'}>YYYY-MM-DD HH:mm</MenuItem>
                            <MenuItem value={'MM/DD/YYYY h:mm'}>MM/DD/YYYY HH:mm</MenuItem>
                            <MenuItem value={'YYYY/MM/DD h:mm'}>YYYY/MM/DD HH:mm</MenuItem>
                            <MenuItem value={'YYYY/DD/MM h:mm'}>YYYY/DD/MM HH:mm</MenuItem>
                            <MenuItem value={'YYYY-DD-MM h:mm'}>YYYY/DD/MM HH:mm</MenuItem>
                        </Select>
                    )}

                    {!["date", 'node'].includes(header.mappedField) && (
                        <Autocomplete
                            size="small"
                            margin="dense"
                            options={unitsArray.sort((a, b) => a.label.localeCompare(b.label))}
                            sx={{
                                ".inputRoot": {
                                    fontSize: 14,
                                    fontWeight: 500,
                                },
                                input: {
                                    padding: 0,
                                    height: 30,
                                },
                            }}
                            InputPro
                            renderInput={(params) => <TextField {...params} placeholder="Units"
                                margin="dense"
                                sx={{
                                    ".MuiInputBase-root": {
                                        height: 50,
                                        fontSize: 16,
                                        fontWeight: 600,
                                    },
                                }}

                            />}
                            value={unitsArray.find((u) => u.label === header.units)}
                            onChange={(event, newValue) => {
                                console.log(newValue)
                                if (newValue === null) {
                                    const newHeaders = [...headers];
                                    const i = newHeaders.findIndex((h) => h.name === header.name);
                                    console.log(i)
                                    newHeaders[i].units = null;
                                    setHeaders(newHeaders);
                                    return;
                                }
                                const newHeaders = [...headers];
                                const i = newHeaders.findIndex((h) => h.name === header.name);
                                console.log(i)
                                newHeaders[i].units = newValue.label;

                                setHeaders(newHeaders);


                            }}

                        />
                    )}







                    {/* Show the first couple options from the data here*/}
                    <Typography variant="body1" fontWeight={700} fontSize={14} style={{ paddingTop: 10 }}>
                        Sample Data
                    </Typography>
                    {tableData?.slice(0, 3).map((row, ind) => {

                        return (
                            <Grid containeralignItems="center" key={ind}>
                                <Grid item key={ind}
                                    style={{

                                        padding: 3
                                    }}
                                    sx={{

                                    }}>
                                    <Typography variant="body1" fontWeight={500} fontSize={14} >
                                        {row[headerName]}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )
                    })}
                    {tableData?.length > 3 && (
                        <Typography variant="body1" fontWeight={500} fontSize={14} style={{ paddingLeft: 3 }}>
                            ...
                        </Typography>
                    )}

                    {tableData?.length === 0 && (
                        <Typography variant="body1" fontWeight={500} fontSize={14} style={{ paddingLeft: 3 }}>
                            No data found
                        </Typography>
                    )}



                </CardContent>

                {/* Upload Button */}
                <CardActions>
                    <Button size="small"

                        style={{
                            textTransform: 'none',
                            color: requiredKeys.includes(header.mappedField) ? 'gray' : '#3f51b5',
                            fontWeight: 600,
                            fontSize: 14,
                        }}
                        onClick={() => {
                            if (requiredKeys.includes(header.mappedField)) {
                                openSnack('error', `This field is required`);
                                return;
                            }
                            const newHeaders = [...headers];
                            newHeaders[index].included = !newHeaders[index].included;
                            setHeaders(newHeaders);
                        }}
                    >

                        {header?.included ? "Do not include" : "Include"}
                    </Button>

                </CardActions>
            </Card >
        )
    }


    return (
        <Box sx={{
            mt: 1,
            mb: 8,
            px: '5vw'
        }}>




            <Grid container spacing={3} mt={4}>
                <Grid item xs={12} sm={12} md={12} lg={12} key={'search'}>
                    <OutlinedInput
                        sx={{
                            mt: 1,
                            mb: 1,
                            width: '100%',
                            height: '50px',
                            borderRadius: 8
                        }}
                        placeholder="Search headers"
                        value={searchQuery}
                        startAdornment={
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        }
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    size='small'
                                    aria-label="toggle password visibility"

                                    onClick={() => {
                                        setFilteredHeaders(headers);
                                        setSearchQuery('');
                                    }}
                                >
                                    X
                                </IconButton>
                            </InputAdornment>
                        }
                        fullWidth
                        onChange={(e) => {
                            setSearchQuery(e.target.value);
                            if (e.target.value === '') {
                                setFilteredHeaders(headers);
                                return;
                            }
                            const filteredHeaders = headers.filter((param) => {
                                return param.name.toLowerCase().includes(e.target.value.toLowerCase());
                            });
                            setFilteredHeaders(filteredHeaders);
                        }}
                    />

                </Grid>
                {filteredHeaders
                    .map((header, index) => (
                        <Grid item xs={12} sm={6} md={6} lg={4} key={index}>
                            <CardItem
                                index={index}
                                header={header}
                                item={item}

                            />
                        </Grid>
                    ))}

                <Grid item xs={12} key={'submit'} sx={{ mt: 2 }}>
                    <Button variant="contained" color="primary"
                        fullWidth

                        onClick={() => validateMappedFields()}
                        style={{
                            textTransform: 'none',
                            backgroundColor: '#3f51b5',
                            height: 50,
                            fontWeight: 600,
                            fontSize: 14,
                        }}>
                        Review Data
                    </Button>
                </Grid>
            </Grid>
        </Box>

    );

};

export default MapDataMulti;
