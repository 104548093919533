import { Button, CircularProgress, OutlinedInput, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { deleteDoc, doc, getDoc, getFirestore, setDoc } from 'firebase/firestore';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import axios, * as others from 'axios';

export default class Invited extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            invitation: {},
            creating: false
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }

    }
    async componentDidMount() {
        window.scrollTo(0, 0)
        const db = getFirestore();
        console.log(window.location.href) 
        const url = window.location.href;
        const slug = url.split('invited/').pop();
        console.log(slug)

        const docRef = doc(db, "users", slug);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {


            const email = docSnap.data().email;
            const emailString = email.toString();
            const lowercaseEmail = emailString.toLowerCase();
            //remove spaces from lowercaseEmail
            const emailNoSpaces = lowercaseEmail.replace(/\s/g, '');


            this.setState({ invitation: { ...docSnap.data(), email: emailNoSpaces } })
        }


    }

    updateInvitation = (boo, val) => {
        const { invitation } = this.state;
        invitation[boo] = val;
        this.setState({ invitation })
    }



    render() {

        const { invitation, screenWidth, creating } = this.state;


        const SignUpButton = () => {
            let navigate = useNavigate();

            return (
                <Button size="small" style={{ padding: 10, borderRadius: 4, textTransform: 'none', fontSize: 14, }}
                    variant='contained' color="primary"
                    onClick={async () => {

                        const db = getFirestore();
                        const checks = [
                            invitation.firstName.length === 0 ? false : true,
                            invitation.lastName.length === 0 ? false : true,
                            invitation.email.length === 0 ? false : true,
                            invitation.password.length === 0 ? false : true,
                            invitation.password !== invitation.confirmPassword ? false : true,
                        ]

                        if (invitation.firstName.length === 0) {
                            this.props.openSnack('error', "First name required!")
                        }
                        if (invitation.lastName.length === 0) {
                            this.props.openSnack('error', "Last name required!")
                        }
                        if (invitation.email.length === 0) {
                            this.props.openSnack('error', "Email required!")
                        }
                        if (invitation.password.length === 0) {
                            this.props.openSnack('error', "Password required!")
                        }
                        if (invitation.password !== invitation.confirmPassword) {
                            this.props.openSnack('error', "Passwords do not match")
                        }
                        if (!checks.includes(false)) {

                            this.setState({ creating: true })


                            console.log(invitation)



                            const auth = getAuth();
                            const db = getFirestore();

                            createUserWithEmailAndPassword(auth, invitation.email, invitation.password)
                                .then(async (userCredential) => {
                                    // Signed in  
                                    const user = userCredential.user;

                                    await deleteDoc(doc(db, "users", invitation.id))
                                        .then(async () => {

                                            if (invitation.addType === 'company') {
                                                await deleteDoc(doc(db, "clients", invitation.currentCompany, 'staff', invitation.id))
                                                    .then(async () => {

                                                        const item = { ...invitation, id: user.uid, key: user.uid, signedUp: true };

                                                        const docRef = doc(db, "users",  user.uid);
                                                        await setDoc(docRef, item).then(async () => {
                                                            const companyRef = doc(db, "clients", invitation.currentCompany, 'staff',  user.uid);
                                                            await setDoc(companyRef, { id: user.uid, adminLevel: invitation.adminLevel }).then(() => {
                                                                setTimeout(() => {
                                                                    navigate('/')
                                                                    window.location.reload();
                                                                }, 1000);
                                                            })
                                                        })
                                                    })


                                            }
                                            else {

                                                await deleteDoc(doc(db, "clients", invitation.currentCompany, 'accounts', invitation.currentAccount, 'staff', invitation.id))
                                                    .then(async () => {

                                                        await deleteDoc(doc(db, "clients", invitation.currentCompany, 'staff', invitation.id))
                                                            .then(async () => {

                                                                const item = { ...invitation, id: user.uid, key: user.uid, signedUp: true };

                                                                const docRef = doc(db, "users",  user.uid);
                                                                delete item.password;
                                                                delete item.confirmPassword;
                                                                await setDoc(docRef, item).then(async () => {
                                                                    const accountRef = doc(db, "clients", invitation.currentCompany, 'accounts', invitation.currentAccount, 'staff',  user.uid);
                                                                    await setDoc(accountRef, { key: user.uid, id: user.uid, adminLevel: invitation.adminLevel, flDistribution: false }).then(async () => {
                                                                        const accountRef = doc(db, "clients", invitation.currentCompany, 'staff',  user.uid);
                                                                        await setDoc(accountRef, { id: user.uid, key: user.uid, adminLevel: 'none' }).then(() => {
                                                                            setTimeout(() => {
                                                                                navigate('/')
                                                                                window.location.reload();
                                                                            }, 1000);
                                                                        })

                                                                    })
                                                                })
                                                            })
                                                    })


                                            }



                                        })






                                    // ...
                                })
                                .catch((error) => {
                                    const errorCode = error.code;
                                    const errorMessage = error.message;
                                    console.log(errorCode);
                                    console.log(errorMessage);

                                    if (errorCode === 'auth/email-already-in-use') {
                                        this.props.openSnack('error', "Email already in use")
                                    }
                                    if (errorCode === 'auth/invalid-email') {
                                        this.props.openSnack('error', "Invalid email, contact eli@laketech.com")
                                    }

                                    // ..
                                });




                        }

                    }
                    }>Sign Up!</Button >
            )
        }

        return (
            <Row align="middle" gutter={[12, 12]} style={{ paddingTop: 30, padding: 20 }}>
                <Col sm={24} md={{ offset: 6, span: 18 }}>
                    <Typography variant="h2">Welcome to Laketech!</Typography>
                </Col>
                <Col sm={24} md={{ offset: 6, span: 18 }}>
                    <Typography variant="h5">Sign up below</Typography>
                </Col>
                <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                    <Typography variant="body1">First Name<span style={{ color: 'red' }}>*</span></Typography>
                </Col>
                <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                    <OutlinedInput fullWidth notched={false} label="none" placeholder={"First Name"} size="small"
                        value={invitation.firstName || ''}
                        onChange={(e) => {
                            this.updateInvitation(`firstName`, e.target.value)
                        }} />
                </Col>
                <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                    <Typography variant="body1">Last Name<span style={{ color: 'red' }}>*</span></Typography>
                </Col>
                <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                    <OutlinedInput fullWidth notched={false} label="none" placeholder={"Last Name"} size="small"
                        value={invitation.lastName || ''}
                        onChange={(e) => {
                            this.updateInvitation(`lastName`, e.target.value)
                        }} />
                </Col>
                <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                    <Typography variant="body1">Email<span style={{ color: 'red' }}>*</span></Typography>
                </Col>
                <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                    <OutlinedInput fullWidth notched={false} label="none" placeholder={"Email"} size="small"
                        value={invitation.email || ''}
                        disabled
                    />
                </Col>
                <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                    <Typography variant="body1">Company</Typography>
                </Col>
                <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                    <OutlinedInput fullWidth notched={false} label="none" placeholder={"Company"} size="small"
                        value={invitation.company || ''}
                        onChange={(e) => {
                            this.updateInvitation(`company`, e.target.value)
                        }} />
                </Col>
                <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                    <Typography variant="body1">Password<span style={{ color: 'red' }}>*</span></Typography>
                </Col>
                <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                    <OutlinedInput fullWidth notched={false} label="none" type='password' placeholder={"Password"} size="small"
                        value={invitation.password || ''}
                        onChange={(e) => {
                            this.updateInvitation(`password`, e.target.value)
                        }}

                    />
                </Col>
                <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                    <Typography variant="body1">Confirm Password<span style={{ color: 'red' }}>*</span></Typography>
                </Col>
                <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                    <OutlinedInput fullWidth notched={false} label="none" type='password' placeholder={"Password"} size="small"
                        value={invitation.confirmPassword || ''}
                        onChange={(e) => {
                            this.updateInvitation(`confirmPassword`, e.target.value)
                        }}

                    />
                </Col>


                <Col xs={{ offset: 0, span: 24 }} sm={{ offset: 6, span: 18 }} style={{}}>
                    {creating ? <CircularProgress /> : <SignUpButton />}
                </Col>

            </Row>

        );
    }
} 