import React, { useEffect, useState } from "react";
import {
    Box, Grid, Typography, OutlinedInput, FormControl, InputLabel, Select, MenuItem,
    InputAdornment,
    Button
} from '@mui/material';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { fetchWeather } from "./utils/functions";


const GetCurrentWeather = (
    {
        record,
        items,
        setItems,
        screenWidth,
        openSnack,
        userInfo,
        updateRecord,
        stocks,
        setStocks,
        accounts,
        account





    }

) => {



    return (
        <>

            <Button variant="outlined" size="small"
                style={{
                    marginLeft: 10,
                    textTransform: "none",
                    backgroundColor: "#3f51b5",
                    color: "white",
                    fontSize: 8
                }}
                onClick={async () => {
                    const weather = await fetchWeather(account);
                    console.log(weather)
                    updateRecord({
                        ...record,
                        temp: weather?.tempF,
                        wind: weather?.wind,
                        dailyRainAmount: weather?.rain || 0,
                        raining: weather?.raining > 0 ? true : false,
                    })
                }}
            >Get current Weather
            </Button >



        </>
    );
};

export default GetCurrentWeather;
