import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';
import axios from 'axios';
import moment from 'moment-timezone';
import { ParameterMap } from './dataEditor/utils/chartOptions';
import unitsList from '../../../extra/units.json'
var convert = require('convert-units')

async function getLocations(userInfo) {

    const db = getFirestore();
    const queryData = query(collection(db, "clients", userInfo.currentCompany, 'probes'), where('account', '==', userInfo.currentAccount), where('archived', '==', false));
    const queryDataSites = query(collection(db, "clients", userInfo.currentCompany, 'sampleSites'), where('account', '==', userInfo.currentAccount),);
    const queryDataStations = query(collection(db, "clients", userInfo.currentCompany, 'tidalStations'), where('account', '==', userInfo.currentAccount),);
    const queryGauges = query(collection(db, "clients", userInfo.currentCompany, 'rainGauges'), where('account', '==', userInfo.currentAccount),);
    const queryWunders = query(collection(db, "clients", userInfo.currentCompany, 'wundergrounds'), where('account', '==', userInfo.currentAccount),);
    const queryStations = query(collection(db, "clients", userInfo.currentCompany, 'stations'), where('account', '==', userInfo.currentAccount),);
    const locations = [];


    const snap = await getDocs(queryData);
    snap.forEach((doc) => {
        locations.push({ ...doc.data(), label: doc.data().name, type: 'probe' })
    });
    const snapSites = await getDocs(queryDataSites);
    snapSites.forEach((doc) => {
        locations.push({ ...doc.data(), label: doc.data().name, type: 'sample' })
    });

    const snapStations = await getDocs(queryDataStations);
    snapStations.forEach((doc) => {
        locations.push({ ...doc.data(), label: doc.data().name, type: 'station' })
    });
    const snapGauges = await getDocs(queryGauges);
    snapGauges.forEach((doc) => {
        locations.push({ ...doc.data(), label: doc.data().name, type: 'gauge' })
    });
    const snapWunders = await getDocs(queryWunders);
    snapWunders.forEach((doc) => {
        locations.push({ ...doc.data(), name: doc.data().stationName, label: doc.data().stationName, type: 'wunder' })
    });

    const snapNodes = await getDocs(queryStations);
    snapNodes.forEach((doc) => {

        if (doc.data().archived !== true && doc.data().children.length > 0) {
            const children = doc.data().children;
            //for each child create a node and add to locations

            children.forEach((child) => {
                locations.push({ ...child, stationLabel: doc.data().name, label: child.name, type: 'node', stationType: child.type })
            })

        }

    });




    locations.push({ label: 'Weather', key: 'Weather', name: 'Weather', type: 'weather' })
    locations.sort((a, b) => a.name.localeCompare(b.name))

    return locations;
}

export async function downloadSeriesAsCSV(series, userInfo, attributes, type, chartTitle, widgetKey) {
    try {

        console.log(series)
        console.log(widgetKey)
        console.log(userInfo)

        const queryInfo = await JSON.parse(localStorage.getItem(`queryInfo-${widgetKey}`));
        console.log(queryInfo)

        // Create header row
        const headerRow = [
            "Series Name",
            "Location",
            "Parameter",
            "Timestamp",
            "Value",
            "Offset"
        ];

        const locations = await getLocations(userInfo);
        const visibleSeries = series.filter(serie => [undefined, true].includes(serie.visible));
        if (type === 'all') {
            await queryAll(visibleSeries, userInfo, locations, headerRow, attributes, chartTitle, widgetKey, queryInfo)
            return;
        }
        /* get queryInfo localStorage */





        // Create rows for each data point in each series



        const rows = visibleSeries.flatMap(serie => {
            const { name, locationid, parameter, data } = serie;
            console.log(serie)
            // Utility function to sanitize CSV fields
            const sanitizeForCSV = (str) => {
                if (str == null) return ''; // Convert undefined or null to empty string
                return str.replace(/,/g, '').replace(/"/g, '""'); // Remove commas and escape double quotes
            };

            return data.map(point => {
                const locationName = locations.find(location => location.id === locationid)?.name || '';
                const removeCommaLocation = sanitizeForCSV(locationName);
                return [
                    sanitizeForCSV(name || `Series`),
                    removeCommaLocation,
                    parameter?.label || parameter?.title || 'No parameter name',
                    moment(point.x || point[0], 'x').format("YYYY-MM-DD HH:mm"),
                    point.y || point[1],
                    point.offset || point[2] || 0,
                ];
            });
        });
        //sort rows by moment
        rows.sort((a, b) => moment(b[3], 'YYYY-MM-DD HH:mm').diff(moment(a[3], 'YYYY-MM-DD HH:mm')))



        // Combine header and rows to create CSV content
        const csvContent =
            headerRow.join(",") +
            "\n" +
            rows.map(row => row.join(",")).join("\n");

        // Create a Blob from the CSV content
        const csvBlob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

        // Create a link element, set its href to the Blob URL, and trigger a download
        const link = document.createElement("a");
        const url = URL.createObjectURL(csvBlob);
        link.href = url;
        link.setAttribute("download", `LakeTech-${chartTitle}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    }
    catch (error) {
        console.log(error)
    }
}


async function queryAll(series, userInfo, locations, headerRow, attributes, chartTitle, widgetKey, queryInfo) {
    try {
        /* make a unique copy of series */
        const seriesCopy = series.map(item => ({ ...item }));


        let value = 'value as "y", COALESCE(offsetvalue, 0) as "offset", key, tags, account, company';
        let endDate = queryInfo?.end;
        let startDate = queryInfo?.start;

        const proxyUrl = "https://mycorslake.herokuapp.com/";
        const baseUrl = `https://us-central1-aquasource3.cloudfunctions.net/widgetsFire3/sqlRead`;

        const requests = seriesCopy?.map(item => {
            const sqlQuery = buildSqlQuery(item, queryInfo, value, endDate, startDate);
            return axios.post(`${proxyUrl}${baseUrl}`, { raw: sqlQuery }).then(response => ({
                data: response.data,
                series: item
            }));
        });

        const results = await Promise.allSettled(requests);

        results.forEach((result, index) => {
            if (result.status === 'fulfilled') {
                const { data, series } = result.value;
                console.log(series)

                const isValidUnitId = ["38", "35", "1", "2"].includes(series.unitid) && series.unitid && series.units;
                let parameter = isValidUnitId ? ParameterMap[series.parameterid] || {} : {};
                let defaultUnit = parameter.default || '';
                const unitLabel = isValidUnitId && unitsList[series.unitid] ? unitsList[series.unitid] : defaultUnit;

                // Assuming dataSourceType and offset are properties of the series or similarly accessible
                series.data = convertData({
                    data, series, isValidUnitId, unitLabel, dataSourceType: series.dataSourceType, offset: series.offset, locations
                });
                /* sort series data by ascending [0] order */

                console.log(series)



            }
        });

        // After your existing results.forEach loop in the queryAll function...

        try {
            // Step 1: Aggregate Data for CSV
            const csvData = results.reduce((acc, result) => {
                if (result.status === 'fulfilled' && result.value.data.length) {
                    acc.push(...result.value.series.data);
                }
                return acc;
            }, []);

            csvData.sort((a, b) => moment(b.date, 'YYYY-MM-DD HH:mm').diff(moment(a.date, 'YYYY-MM-DD HH:mm')))



            // Function to escape and quote CSV field values
            function escapeCSVField(value) {
                if (value == null) value = ""; // Convert null or undefined to empty string
                return `"${String(value).replace(/"/g, '""')}"`; // Escape double quotes and enclose in quotes
            }

            // Step 2: Generate CSV Content with escaped fields
            const csvContent = [headerRow, ...csvData.map(item => [
                escapeCSVField(item.seriesName),
                escapeCSVField(item.nodeid),
                `"${item.parameterid}"`,
                item.date,
                item.y, // Assuming numeric, so no need to escape
                item.offset // Assuming numeric, so no need to escape
            ].join(','))].join('\n');

            // Step 3: Download the CSV
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', `LakeTech-${chartTitle}.csv`);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error generating or downloading CSV:', error);
        }


    }
    catch (error) {
        console.log(error)
    }
}


// Helper function to build SQL query
function buildSqlQuery(item, queryInfo, value, endDate, startDate) {


    return `SELECT time as "t", nodeid, unitid, timestamp as "x", ${value}
            FROM node_data_new2
            WHERE nodeid = '${item.locationid}' and parameterid = '${item?.parameterid}'
            and time >= '${startDate}'
            and time <= '${endDate}'
            ORDER BY "t" DESC;`;
}

// Adjusted to include handling for offsets based on dataSourceType
function convertData({
    data,
    series,
    isValidUnitId,
    unitLabel,
    dataSourceType,
    offset,
    locations



}) {

    const timezone = series?.location?.sensor?.timezone || 'US/Pacific';

    console.log(timezone)
    console.log(data)
    console.log(series)

    return data.map((item) => {
        let value = Number(item?.y);

        // Convert and fix the value to 4 decimal places, and convert units if valid
        if (isValidUnitId) {
            value = Number(convert(value.toFixed(4)).from(unitLabel).to(series.units));
        } else {
            value = Number(value.toFixed(4));
        }

        // Adjust value based on dataSourceType
        if (dataSourceType === 'readingWithOffset') {
            value += Number(offset || 0);  // Add offset to value if dataSourceType is 'readingWithOffset'
        } else if (dataSourceType === 'offset') {
            value = Number(offset || 0);  // Set value to offset if dataSourceType is 'offset'
        }

        const newX = moment(item.t, "YYYY-MM-DD HH:mm:ss").tz(timezone).format("YYYY-MM-DD HH:mm");


        return {
            date: newX, // The x value
            y: value, // The y value adjusted as necessary
            id: item?.key, // Include the id from item.key
            offset: item?.offset,// Include any other data you need, such as offset
            key: item.key, // Include the key for reference
            seriesName: series.name || 'series', // Include the series key for reference
            nodeid: series.location?.name || 'No location name',
            parameterid: series.parameter?.label || series.parameter?.title || 'No parameter name',
            unitid: series.unitid,
            tags: JSON.parse(item.tags) || [],
            account: item?.account,
            company: item?.company

        };
    });
}