import React, { useState, useRef } from "react";
import { Box, Breadcrumbs, Button, ClickAwayListener, TableRow, TableCell, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, List, ListItem, Popper, TextField, ThemeProvider, Typography, createTheme, Checkbox } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEllipsisV, faFileAlt, faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { useDrop, useDrag } from 'react-dnd';
import { Row } from "antd";
import { moveFile, moveFolder } from "../../firebase/firestore";
import { faFile } from "@fortawesome/free-regular-svg-icons";

const YourCustomRowComponent = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [checked, setChecked] = useState(false);
    const anchorRefs = useRef(new Map()).current;

    const { name, id, type, size, downloadUrl, parent, selected, index, refreshFolderStructure, setRename, setRenameModalOpen,
        setRenameValue, setRenameId, setRenameType, openSnack, company, navigateToFolder, onSelectRow } = props;



    const [{ isDragging }, drag] = useDrag(() => ({
        type: type === 'folder' ? 'folder' : 'file',
        item: { name: name, id, type: type, size: size, },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    }));

    const [{ canDrop, isOver }, drop] = useDrop(() => ({
        accept: ['file', 'folder'],
        drop: (item, monitor) => {
            console.log(item.parent)
            // Properties of the item being dragged
            const draggedItemId = item.id;
            const draggedItemName = item.name;
            const draggedItemType = item.type;
            const draggedItemSize = item.size;
            const draggedParent = item.parent;

            // Properties of the item being dropped on
            const droppedOnItemId = id;
            const droppedOnItemName = name; 
            const droppedOnItemType = type;
            const droppedOnItemSize = size;


            const draggedItem = { id: draggedItemId, name: draggedItemName, type: draggedItemType, size: draggedItemSize, parent: draggedParent };
            const droppedOnItem = { id: droppedOnItemId, name: droppedOnItemName, type: droppedOnItemType, size: droppedOnItemSize, };


            console.log(`Dropped ${item.name} which is a ${item.type} onto ${name} which is a ${type}`)

            console.log(item.type)

            //do not allow drop on self
            if (draggedItemId === droppedOnItemId) {
                openSnack('error', 'Cannot move folder into itself!')
                return;
            }

            if (draggedItemType === 'file' && droppedOnItemType === 'file') {
                openSnack('error', 'Cannot move file into another file!')
                return;
            }
            if (draggedItemType === 'folder' && droppedOnItemType === 'file') {
                openSnack('error', 'Cannot move folder into a file!')
                return;
            } 
            console.log(type)
            if (draggedItemType === 'folder') {
                moveItemFolder(draggedItem, droppedOnItem, company);
            }
            else {
                moveItem(draggedItem, droppedOnItem, company);
            }



            // Your logic for handling the drop event goes here
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    }));


    async function moveItem(draggedItem, droppedOnItem, company) {
        const move = await moveFile(draggedItem, droppedOnItem, company);
        console.log(move);
        if (move === 'success') {
            openSnack('success', 'Moved folder successfully!')
            console.log(parent)
            refreshFolderStructure(parent);
        }
        else {
            openSnack('error', 'Cannot move folder into itself!')
        }
    }


    async function moveItemFolder(draggedItem, droppedOnItem, company) {
        const move = await moveFolder(draggedItem, droppedOnItem, company);
        console.log(move);
        if (move === 'success') {
            openSnack('success', 'Moved folder successfully!')
            console.log(parent)
            refreshFolderStructure(parent);
        }
        else {
            openSnack('error', 'Cannot move folder into itself!')
        }
    }

    const isActive = canDrop && isOver;
    const backgroundColor = isActive ? '#e2f1fb4f' : //alternate color based on index
        index % 2 === 0 ? '#eeeeee52' : '#ffffff';

    return (
        //create this as a draggable component

        <TableRow key={id} style={{
            opacity: isDragging ? 0.5 : 1, width: '100%', backgroundColor,
            //rowheight 30
            height: 40,
        }}
            //draggable and droppable
            ref={(node) => drag(drop(node))}
            onClick={() => {
                console.log('clicked')
                const typeOf = type;
                if (typeOf === 'folder') {
                    console.log(id)
                    navigateToFolder(id);
                }
                else {
                    //open file
                    window.open(downloadUrl, '_blank');
                }

            }}
        >
            <TableCell style={{ width: 60, textAlign: 'center' }} onClick={(e) => {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
            }}>
                <Checkbox
                    checked={selected}
                    onChange={(event) => {
                        event.stopPropagation();
                        event.nativeEvent.stopImmediatePropagation();
                        onSelectRow(event.target.checked, id)
                        // Add your logic to handle the selection change here
                    }}
                /></TableCell>
            <TableCell>
                {type === 'folder' ?
                    <Typography variant='body1' fontSize={16} fontWeight={400} color='black'>
                        <FontAwesomeIcon icon={faFolderOpen} style={{ marginRight: '10px' }} />{name}</Typography>
                    :
                    <Typography variant='body1' fontSize={16} fontWeight={400} color='black'>
                        <FontAwesomeIcon icon={faFile} style={{ marginRight: '10px' }} />{name}</Typography>

                }
            </TableCell>
            <TableCell >
                {type === 'folder' ?
                    <Typography variant='body1' fontSize={16} fontWeight={400} color='black'>Folder</Typography>
                    :
                    <Typography variant='body1' fontSize={16} fontWeight={400} color='black'>File</Typography>

                }
            </TableCell>
            <TableCell >
                {type === 'folder' ?
                    <Typography variant='body1' fontSize={16} fontWeight={400} color='black'>--</Typography>
                    :
                    <Typography variant='body1' fontSize={16} fontWeight={400} color='black'>{Number(size / 1000000).toFixed(2)} MB</Typography>
                }
            </TableCell>
            <TableCell align="center">
                
                <div>
                    <Button
                        ref={(node) => anchorRefs.set(index, node)}
                        style={{ width: 20 }}
                        onClick={(e) => {
                            e.stopPropagation();

                            setIsOpen((prev) => ({ ...prev, [index]: !prev[index] }));
                        }}
                    >
                        <Typography variant='body1' fontSize={16} fontWeight={400} color='black'>
                            <FontAwesomeIcon icon={faEllipsisV} />
                        </Typography>
                    </Button>
                    {isOpen[index] && (
                        <ClickAwayListener onClickAway={() => setIsOpen((prev) => ({ ...prev, [index]: false }))}>
                            <Popper
                                id={'popper'}
                                open={isOpen[index]}
                                anchorEl={anchorRefs.get(index)}
                                style={{ zIndex: 1000, width: 240 }}
                            >
                                <Box sx={{
                                    p: 0, bgcolor: 'background.paper',
                                    borderRadius: 2,
                                    border: '1px solid #e0e0e0',
                                    boxShadow: 1,

                                }}>
                                    <Row style={{}}>
                                        <List
                                            component="nav"
                                            aria-labelledby="nested-list-subheader"
                                            sx={{
                                                width: '100%',
                                                maxWidth: 360,
                                                bgcolor: 'background.paper',
                                                position: 'relative',
                                                overflow: 'auto',
                                                maxHeight: 300,
                                            }}
                                        >
                                            <ListItem button onClick={(e) => {
                                                e.stopPropagation();
                                                console.log('clicked');
                                                setIsOpen(false);
                                                setRenameModalOpen(true);
                                                setRenameValue(name);
                                                setRenameId(id);
                                                setRenameType(type);
                                            }}>
                                                <Typography variant='body1' fontSize={13} fontWeight={600} color='black'>
                                                    <FontAwesomeIcon icon={faEdit} style={{ marginRight: '10px' }} />
                                                    Rename</Typography>
                                            </ListItem>
                                        </List>

                                    </Row>
                                </Box>
                            </Popper>
                        </ClickAwayListener>
                    )}


                </div>
            </TableCell>
        </TableRow >
    );
}

export default YourCustomRowComponent;