import React from "react";


import { Autocomplete, Box, Grid, TextField, Typography } from '@mui/material';



const SiteCell = (props) => {


    const { stations, header, headers, row, currentCell, keyDown, type, updateRow } = props;

    const editing = currentCell?.selectedID === `${row?.id}??${header.name}` && currentCell !== null;
    const value = row?.[header.name];

 

    if (editing || type === 'bulk') {
        return (
            <Autocomplete
                sx={{
                    ".inputRoot": {
                        fontSize: 13,
                        fontWeight: 500,
                        // <- 100% height here
                    },
                    input: {
                        m: 0,
                        py: 2,
                        px: 1,
                        height: 10,  // <- 100% height here
                    },
                }}
                groupBy={(option) => option.stationLabel}
                renderGroup={({ group, children }) => {
                    return (
                        <div>
                            <Box sx={{ fontWeight: 'bold', marginLeft: 1 }}>{group}</Box>
                            {children}
                        </div>
                    );
                }}
                autoFocus
                fullWidth

                options={stations}
                value={stations.find((option) => option.key === row?.[header.name])}
                onChange={(e, b) => {
                    const value = b?.label;
                    if (value === null) {
                        const newRow = { ...row, [header.name]: null };
                        updateRow(newRow);
                        return;
                    }
                    const newRow = { ...row, [header.name]: value };
                    updateRow(newRow);
                }}
                openOnFocus={type === 'bulk' ? false : true}
                onFocus={(e) => e.target.select()}
                renderInput={(params) => <TextField autoFocus {...params} placeholder="Site" />}
                onKeyDown={(e) => {
                    if (!['Enter', 'Tab'].includes(e.key)) return;
                    if (type === 'bulk') return;
                    keyDown(e, row);

                }}

            />
        )
    }
    else {

        return (

            <Typography variant="body2" fontWeight={500} fontSize={13} color={stations.find((option) => option.label === value) ? "inherit" : "red"}>
                {stations.find((option) => option.label === value)?.label || (value === "" ? "--" : value)}
            </Typography>

        )
    }
};

export default SiteCell;
