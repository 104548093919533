import React from "react";


import { Button, ButtonGroup } from '@mui/material';
import { Col, Row } from 'antd';
import './streamList.css';
import StreamsSearch from "./streamsSearch";



const StreamsTableHeader = (
    {
        searchQuery,
        setSearchQuery,
        activeTab,
        setActiveTab,
        screenWidth,
        prefKey
    }

) => {






    return (
        <Row style={{}}>
            <Col span={24}>


                <Row >
                    <Col xs={24} sm={18} style={{
                        height: '70px',
                        borderBottom: '1px solid rgba(0,0,0,0.2)',
                        display: 'flex',
                        alignItems: 'flex-end', // Adjusted this line
                        justifyContent: 'flex-start', // Adjusted this line for better readability
                    }}>
                        <ButtonGroup size="small" aria-label="small button group" style={{
                            marginLeft: 10
                        }}>
                            <Button
                                key="streams"
                                onClick={(e) => setActiveTab('streams')}
                                color='primary'
                                variant={null}
                                style={{
                                    height: '50px',
                                    textTransform: 'none',
                                    borderRadius: 0,
                                    fontWeight: 400,
                                    fontSize: 18,
                                    color: activeTab === 'streams' ? '#6fa2f1' : null,
                                    borderBottom: activeTab === 'streams' ? '3px solid #3f51b5' : null
                                }}
                            >
                                Streams
                            </Button>
                            
                        </ButtonGroup>
                    </Col>

                    <Col xs={24} sm={6} style={{
                        height: '70px', display: 'flex', alignItems: 'center', justifyContent: 'right',
                        borderBottom: screenWidth === 'xs' ? 'none' :
                            '1px solid rgba(0,0,0,0.2)', paddingLeft: screenWidth === 'xs' ? 10 : 0
                    }}>
                        <StreamsSearch searchQuery={searchQuery}
                            setSearchQuery={setSearchQuery}
                        />

                    </Col>

                </Row>

            </Col >



        </Row >
    );
};

export default StreamsTableHeader;
