import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Autocomplete, Button, Chip, CircularProgress, IconButton, OutlinedInput, TextField, Typography } from '@mui/material';
import { Col, Popconfirm, Progress, Row } from 'antd';
import { doc, getDoc, getFirestore, setDoc, updateDoc } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import moment from 'moment';
import React from 'react';
import Dropzone from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import BacteriaItem from './products/bacteriaItem';
import ChemicalItem from './products/chemicalItem';
import DyeItem from './products/dyeItem';
import EquipmentItem from './products/equipmentItem';
import FishItem from './products/fishItem';

export default class ItemForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            type: this.props.type,
            item: {},
            loading: true,
            files: [],
            progressBars: {},
            newItem: this.props.newItem,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
        if (this.props.type !== prevProps.type) {
            this.setState({ type: this.props.type })
        }

    }
    componentDidMount() {

        const { userInfo } = this.state;
        this.queryDataDoc(userInfo);
    }

    queryDataDoc = async (userInfo) => {
        const { type, newItem } = this.state;
        const db = getFirestore();

        const docRef = doc(db, "users", userInfo.id, 'currentItems', 'currentPS');
        const docSnap = await getDoc(docRef);

        if (newItem === true || docSnap.data().key === 'none') {
            this.setState({ item: { tempKey: uuid(), psType: type === 'service' ? 'services' : 'products', archived: false, }, loading: false })
        }
        else {
            this.getDocItem(docSnap.data().key)
        }
    }

    getDocItem = async (key) => {
        const { userInfo } = this.state;
        const db = getFirestore();
        const docRef = doc(db, "clients", userInfo.currentCompany, 'psItems', key);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            console.log(docSnap.data())
            this.setState({ item: docSnap.data(), loading: false })
        }
    }

    updateItem = (boo, val) => {
        const { item } = this.state;
        item[boo] = val;

        this.setState({ item })
    }
    updateEntireItem = (value) => {
        this.setState({ item: value })
    }

    render() {

        const { userInfo, item, screenWidth, type, loading, files, progressBars, newItem } = this.state;
 
        const SubmitButton = () => {
            const { item, userInfo } = this.state;
            let navigate = useNavigate();
            return (
                <Button style={{
                    textTransform: 'none', padding: 16, fontSize: 16,
                    backgroundColor: '#3f51b5',
                }} fullWidth variant="contained" color="primary"
                    onClick={async () => {
                        if (item.name === undefined || item.name.length === 0) {
                            this.props.openSnack('error', "Name required")
                        }
                        else {
                            const db = getFirestore();
                            const key = item.key || item.tempKey;
                            const docRef = doc(db, "clients", userInfo.currentCompany, 'psItems', key);

                            if (item.key) {
                                await updateDoc(docRef, item).then(() => {
                                    if (newItem === true) {
                                        this.setState({ item: { tempKey: uuid(), psType: type === 'service' ? 'services' : 'products', archived: false, } })
                                        this.props.closeModal()
                                    }
                                    else {
                                        navigate(type === 'service' ? "/services" : '/products');
                                    }
                                }).then(() => {
                                    this.props.openSnack('success', item.key ? "Updated item!" : "Created item!")
                                })
                            }
                            else {
                                await setDoc(docRef, { ...item, key: item.tempKey }).then(() => {
                                    if (newItem === true) {
                                        this.setState({ item: { tempKey: uuid(), psType: type === 'service' ? 'services' : 'products', archived: false, } })
                                        this.props.closeModal()
                                    }
                                    else {
                                        navigate(type === 'service' ? "/services" : '/products');
                                    }

                                }).then(() => {
                                    this.props.openSnack('success', item.key ? "Updated item!" : "Created item!")
                                })
                            }



                        }

                    }
                    }>
                    {item.key ? `Update ${type === 'service' ? 'Service' : 'Product'}` : `Create ${type === 'service' ? 'Service' : 'Product'}`}
                </Button >
            )
        }

        const serviceItems = [
            { label: 'Chemical', key: 'application' },
            { label: 'General', key: 'general' },
            { label: 'Harvest', key: 'harvest' },
            { label: 'Maintenance', key: 'harvest' },
            { label: 'Monitoring', key: 'monitoring' },
            { label: 'Other', key: 'other' },
            { label: 'Water Quality', key: 'waterQuality' },
        ];
        const productItems = [
            { label: 'Bacterial', key: 'bacterial' },
            { label: 'Chemical', key: 'chemical' },
            { label: 'Dye', key: 'dye' },
            { label: 'Equipment', key: 'equipment' },
            { label: 'Fish', key: 'fish' },
            { label: 'Material', key: 'material' },
            { label: 'Other', key: 'other' },
        ];

        const Loading = (props) => {
            return (
                <div style={{ minHeight: '100vh' }}>
                    <Row justify="space-around" gutter={[32, 8]} align="middle" style={{ paddingTop: 18, }}>

                        <Col span={24} style={{ textAlign: 'center', fontSize: 24, fontFamily: 'Roboto, sans-serif', }}>
                            <CircularProgress />
                        </Col>
                    </Row>
                </div>
            )
        }

        const DeleteFile = ({ file }) => {
            return (
                <Popconfirm
                    title="Are you sure to delete this file?"
                    onConfirm={async () => {
                        console.log(item)

                        item.files = [];
                        this.setState({ item })

                    }}
                    okText="Yes"
                    cancelText="No"
                >
                    <IconButton style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="small"
                    >
                        <DeleteForeverIcon />
                    </IconButton>
                </Popconfirm>


            )
        }

        const productKey = item.productType !== undefined ? item.productType.key : '';
        console.log(productKey)



        return (
            <Row gutter={[screenWidth === 'xs' ? 4 : 24, screenWidth === 'xs' ? 4 : 24]} align="middle">
                <Col xs={24} sm={{ offset: 6, span: 20 }}>
                    <Typography variant="h4"> {type === 'service' ? 'Service' : 'Product'}</Typography>
                </Col>

                <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                    <Typography variant="body1">Name<span style={{ color: 'red' }}>*</span></Typography>
                </Col>
                <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                    <OutlinedInput fullWidth notched={false} label="none" placeholder={"Name"} size="small"
                        value={item.name}
                        onChange={(e) => {
                            this.updateItem(`name`, e.target.value)
                        }} />
                </Col>
                <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                    <Typography variant="body1">Description</Typography>
                </Col>
                <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                    <OutlinedInput fullWidth notched={false} label="none" placeholder={"Description"} size="small"
                        multiline={true}
                        rows={4}
                        value={item.description}
                        onChange={(e) => {
                            this.updateItem(`description`, e.target.value)
                        }} />
                </Col>
                <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                    <Typography variant="body1">Type</Typography>
                </Col>
                <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                    <Autocomplete
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={type === 'service' ? serviceItems : productItems}
                        defaultValue={type === 'service' ? item.serviceType || '' : item.productType || ''}
                        renderInput={(params) => <TextField {...params} label="Type" />}
                        onChange={(a, b) => {
                            console.log(b);
                            this.updateItem(type === 'product' ? 'productType' : 'serviceType', b)
                        }}

                    />
                </Col>



                {item.productType && item.productType.label === 'Fish' && (
                    <Col xs={24} >
                        <FishItem type={type} screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} item={item} updateEntireItem={this.updateEntireItem} />
                    </Col>
                )}

                {item.productType && item.productType.label === 'Chemical' && (
                    <Col xs={24} >
                        <ChemicalItem type={type} screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} item={item} updateEntireItem={this.updateEntireItem} />
                    </Col>

                )}
                {item.productType && item.productType.label === 'Equipment' && (
                    <Col xs={24} >
                        <EquipmentItem type={type} screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} item={item} updateEntireItem={this.updateEntireItem} />
                    </Col>
                )}
                {item.productType && item.productType.label === 'Dye' && (
                    <Col xs={24} >
                        <DyeItem type={type} screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} item={item} updateEntireItem={this.updateEntireItem} />
                    </Col>
                )}
                {item.productType && item.productType.label === 'Bacterial' && (
                    <Col xs={24} >
                        <BacteriaItem type={type} screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} item={item} updateEntireItem={this.updateEntireItem} />
                    </Col>
                )}


                <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                    <Typography variant="body1">File</Typography>
                </Col>
                <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                    <Row gutter={[4, 4]} align="middle">


                        {item.files !== undefined && item.files.length > 0 && (item.files.map((p, i) => {

                            return (
                                <Col xs={24} sm={24} style={{ borderRadius: 8, paddingBottom: 12 }}>
                                    <Row align="middle" gutter={[8, 8]}>
                                        <Col span={20} >
                                            <Chip color="primary" label={p.fileName} onClick={() => {
                                                window.open(
                                                    p.fileLink,
                                                    '_blank' // <- This is what makes it open in a new window.
                                                );
                                            }} />

                                        </Col>

                                        <Col span={4}>
                                            <DeleteFile file={p} />
                                        </Col>

                                    </Row>
                                </Col>

                            )
                        }))}

                        {Object.values(progressBars).length > 0 && (<Col xs={24} sm={24} >
                            {Object.values(progressBars).map((p) => {
                                return (
                                    <Col span={24}>
                                        {p.progress > 0 && (
                                            <div> {p.fileName} <Progress percent={p.progress} /></div>
                                        )}
                                    </Col>

                                )
                            })}
                        </Col>)}

                        <Col className='fileUpload' xs={24} sm={24} style={{ border: '1px dashed gray', borderRadius: 8 }}>
                            <Dropzone multiple={false} onDrop={(acceptedFiles) => {

                                acceptedFiles.map((f) => {

                                    const storage = getStorage();
                                    console.log(userInfo);
                                    console.log(item);
                                    const key = item.tempKey || item.key;

                                    const storageRef = ref(storage, `/products/${userInfo.currentAccount}/${key}`);

                                    const uploadTask = uploadBytesResumable(storageRef, f);
                                    uploadTask.on('state_changed',
                                        (snapshot) => {

                                            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                                            console.log('Upload is ' + progress + '% done');

                                            progressBars[key] = { key, progress, fileName: f.name }

                                            this.setState({ progressBars })

                                            switch (snapshot.state) {
                                                case 'paused':
                                                    console.log('Upload is paused');
                                                    break;
                                                case 'running':
                                                    console.log('Upload is running');
                                                    break;
                                            }
                                        },
                                        (error) => {
                                        },
                                        () => {
                                            getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {



                                                const file = {
                                                    fileCreateDate: moment().format('X'),
                                                    date: moment().format('X'),
                                                    fileCreator: `${userInfo.firstName || ""} ${userInfo.lastname}`,
                                                    fileCreatorKey: userInfo.id,
                                                    fileLink: downloadURL,
                                                    fileName: f.name,
                                                    fileType: f.type,
                                                    description: '',
                                                    key,
                                                };

                                                item.files = [file];





                                                delete progressBars[key];
                                                this.setState({ progressBars, item })









                                            });
                                        }
                                    );






                                })
                            }} >
                                {({ getRootProps, getInputProps }) => (
                                    <section>
                                        <div {...getRootProps()}>

                                            <Row align="middle">

                                                <Col span={24} style={{ padding: 6, textAlign: 'center' }}>
                                                    <FontAwesomeIcon fontSize={18} icon={faCloudArrowUp} color="#9ec1c9" />
                                                </Col>
                                                <Col span={24} style={{ padding: 6, textAlign: 'center' }}>
                                                    <Typography variant='h6'>Upload File</Typography>
                                                </Col> <input {...getInputProps()} /></Row>
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                        </Col>

                    </Row>

                </Col>




                <Col xs={24} sm={{ offset: 6, span: 6 }} style={{ textAlign: 'left' }}>
                    <SubmitButton />
                </Col>



            </Row>
        );
    }
} 