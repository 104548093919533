import { Backdrop, Box, Button, CardMedia, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { DndProvider, useDrag, useDrop, useDragLayer } from 'react-dnd';
import { deleteFilesFunction } from "../../utils/filesFunctions";
import FileCover from "./fileCover";
import FileLoadingCover from "../../pages/fileLoadingCover";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import LightboxComponent from "./lightBox";
import pdfPlaceholder from './pdfPlaceholder.JPG'
import videoPlaceholder from './videoPlaceholder.JPG'





const FilesList = ({
    screenWidth,
    openSnack,
    userInfo,
    files,
    filteredFiles,
    setCurrentFile,
    setFilteredFiles,
    updateFileParentId,
    uploadProgress,
    setFiles,
    folderBreadCrumbs,
    setFolderBreadCrumbs,
    moveUpDirectory,
    selectedFiles,
    setSelectedFiles,
    updateFile,
    currentFile,
    moveFilesUpDirectory,
    publicPortal
}) => {



    const [lastIndex, setLastIndex] = useState(0);
    const [isLightboxOpen, setIsLightboxOpen] = React.useState(false);
    const [currentImageIndex, setCurrentImageIndex] = React.useState(0);


    const props = {
        screenWidth, openSnack, userInfo, files, setCurrentFile, currentFile, folderBreadCrumbs, setFolderBreadCrumbs, publicPortal,
        moveUpDirectory, selectedFiles, setSelectedFiles, selectedFiles, setSelectedFiles, lastIndex, setLastIndex
    };

    const handleFileClick = index => {
        setCurrentImageIndex(index);
        setIsLightboxOpen(true);
    };
    // File component that is draggable
    const DraggableFile = ({ file, deleteFiles, index, selectMultipleFiles, ...props }) => {
        const [{ isDragging }, dragRef] = useDrag({
            type: 'file',
            item: { id: file.file_id, type: 'file' },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),

            }),
            end: (item, monitor) => {
                console.log(`end drag: ${file.file_id}`)
                const dropResult = monitor.getDropResult();


            },
        });






        return (
            <Grid ref={dragRef} key={file.file_id} item xs={6} sm={4} md={3}>
                <FileCover ref={dragRef}
                    {...props}
                    file={file}
                    index={index}
                    moveFilesUpDirectory={moveFilesUpDirectory}
                    handleFileClick={handleFileClick}
                    selected={selectedFiles.includes(file.file_id)}
                    deleteFiles={deleteFiles}
                    state={isDragging ? 'dragging' : 'idle'}
                    imageSrc={file.medium || (file?.fileType === 'application/pdf' ? pdfPlaceholder : file?.fileType?.includes('video') ? file?.screenshot : null)}
                    title={file.name}
                    selectMultipleFiles={selectMultipleFiles}
                    // Style based on isDragging
                    style={{ opacity: isDragging ? 0.8 : 1, cursor: 'move', border: isDragging ? '6px solid red' : 'none' }}
                />
            </Grid>

        );
    };



    if (files.length === 0) {
        return (
            <Box p={1}>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={12} md={12} sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '20vh'

                    }}>
                        <Typography variant="h3" fontSize={18} fontWeight={700} align="center">
                            {publicPortal === true ? 'No files found' : 'No files found, click the upload button to add files'}
                        </Typography>
                    </Grid>
                </Grid>
            </Box >
        )
    }
    else {

        return (

            <Box p={1} >

                {isLightboxOpen && (
                    <LightboxComponent
                        isLightboxOpen={isLightboxOpen}
                        setIsLightboxOpen={setIsLightboxOpen}
                        file={files[currentImageIndex]}
                        image={files[currentImageIndex].large}
                        alt={files[currentImageIndex].name}
                        screenWidth={screenWidth}
                        nextImage={() => setCurrentImageIndex((currentImageIndex + 1) % files.length)}
                        prevImage={() => setCurrentImageIndex((currentImageIndex + files.length - 1) % files.length)}
                    />

                )}


                <Grid container alignItems="center" spacing={2}>
                    {files.map((file, index) => {

                        return (


                            file.isLoading ? (
                                <Grid key={file.file_id} item xs={6} sm={4} md={3}>
                                    <FileLoadingCover
                                        file={file}
                                        title={file?.name}
                                        progress={file?.fileType.includes('image') ? Number(`${Math.round(Math.min(
                                            uploadProgress[file.file_id]?.small || 0,
                                            uploadProgress[file.file_id]?.medium || 0,
                                            uploadProgress[file.file_id]?.large || 0,
                                        ))} `)
                                            :
                                            Number(`${Math.round(Math.min(
                                                uploadProgress[file.file_id]?.path || 0,
                                            ))} `)

                                        }
                                    />

                                </Grid>
                            ) : (

  

                                <DraggableFile key={file.file_id} file={file}
                                    {...props} imageSrc={file.medium} title={file?.name}
                                    selectedFiles={selectedFiles} index={index}
                                    setSelectedFiles={setSelectedFiles}
                                    handleFileClick={handleFileClick}
                                    moveFilesUpDirectory={moveFilesUpDirectory}
                                    selectMultipleFiles={(rangeStart, rangeEnd) => {
                                        console.log(rangeStart)
                                        console.log(rangeEnd)
                                        const range = [...Array(rangeEnd - rangeStart + 1).keys()].map(i => i + rangeStart);
                                        console.log(range)
                                        const newSelectedFiles = range.map(i => files[i].file_id)
                                        console.log(newSelectedFiles)
                                        setSelectedFiles(prev => [...Array.from(new Set([...prev, ...newSelectedFiles]))])
                                    }}
                                    deleteFiles={(filesToDelete) => {
                                        console.log(filesToDelete)
                                        deleteFilesFunction(filesToDelete, userInfo, openSnack, files, setFiles)

                                    }}
                                />


                            )


                        )
                    })}
                </Grid>
            </Box>

        );
    }
};

export default FilesList;
