import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, Button, Checkbox, CircularProgress, Divider, OutlinedInput, Paper, TextField, Typography } from '@mui/material';
import { Col, Popconfirm, Row } from 'antd';
import { arrayUnion, collection, doc, getDoc, getDocs, getFirestore, query, setDoc, updateDoc, where } from 'firebase/firestore';
import moment from 'moment';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import LocationSelect from '../blocks/gis/locationSelect';
import CACounties from '../extra/CACountyList.json';
import LocationSearch from '../extra/locationSearch';
import NYCounties from '../extra/NYCountyList.json';
import {
    FloridaCounties,
    MassachusettsCounties,
    MichiganCounties,
    TennesseeCounties,
    WisconsinCounties
} from '../extra/counties';
import axios, * as others from 'axios';
var randomColor = require('randomcolor');

export default class AccountInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            type: this.props.type,
            loading: true,
            creating: false,
            deleting: false,
            item: {},
            dataList: [],
            references: [],
            layout: [],
            widgets: [],
            loadingCounties: false,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }


    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        const { userInfo } = this.state;

        const db = getFirestore();
        const queryData = query(collection(db, 'clients', "e467d47-003-6463-6e6b-ee08215ca686", 'accounts', 'f63cd38-4a4d-0e03-fec-d215efab2534', 'references'),);
        const data = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            data.push({ ...doc.data(), key: uuid() })


        });



        const docRef = doc(db, 'clients', "e467d47-003-6463-6e6b-ee08215ca686", 'accounts', 'f63cd38-4a4d-0e03-fec-d215efab2534', 'dashboard', 'dashboard');
        const docSnap = await getDoc(docRef);





        if (docSnap.exists()) {
            const layout = JSON.parse(docSnap.data().layout)


            const widgets = docSnap.data().widgets1 === "" ? JSON.parse(docSnap.data().widgets) :
                docSnap.data().widgets1 === undefined ? JSON.parse(docSnap.data().widgets) : JSON.parse(docSnap.data().widgets + docSnap.data().widgets1);

            console.log(layout)
            console.log(widgets);



            this.setState({ references: data, layout, widgets })

        }









        this.queryDataDoc(userInfo);







    }

    queryDataDoc = async (userInfo) => {
        const { type } = this.state;
        const db = getFirestore();

        const docRef = doc(db, "users", userInfo.id,);
        const docSnap = await getDoc(docRef);

        if (docSnap.data().currentAccount === 'none') {
            this.setState({ item: { tempKey: uuid(), company: userInfo.currentCompany, companyKey: userInfo.currentCompany }, loading: false })
        }
        else {
            this.getDocItem(docSnap.data().currentAccount)
        }
    }

    getDocItem = async (key) => {
        const { userInfo } = this.state;
        const db = getFirestore();
        const docRef = doc(db, "clients", userInfo.currentCompany, 'accounts', key);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            console.log(docSnap.data())
            this.setState({ item: docSnap.data(), loading: false })
        }
    }

    fillLatLng = (latLng) => {
        const { item, widgets } = this.state;
        item.lat = latLng.lat;
        item.lng = latLng.lng;

        console.log(item)
        const index = widgets.map((w) => w.attributes.widgetType).indexOf('map');
        console.log(index);
        widgets[index].attributes.center = [latLng.lat, latLng.lng];
        console.log(widgets)
        this.setState({ item, gps: [item.lng, item.lat], widgets });

    }

    updateGPS = (data) => {
        const { item, widgets } = this.state;
        item.lat = data.center.lat;
        item.lng = data.center.lon;
        const index = widgets.map((w) => w.attributes.widgetType).indexOf('map');
        console.log(index);
        widgets[index].attributes.center = [item.lat, item.lng];
        console.log(widgets)
        this.setState({ item, widgets });
    }
    fillAddress = (address) => {
        const { item } = this.state;
        item.address = address.formatted_address;
        const types = ["street", "city", "countyName", "countyCode", "state", "postal_code"];
        console.log(item)
        console.log(address)
        this.setState({ loadingCounties: true })
        types.map((t) => {
            delete item[t];
        })


        address.address_components.map((a) => {
            if (a.types[0] === "locality") {
                item.city = a.short_name;
            }

            if (a.types[0] === "administrative_area_level_1") {
                this.setState({
                    countyList:
                        a.long_name === "California" ? CACounties :
                            //if "Michigan" then "MichiganCounties"
                            a.long_name === "Michigan" ? MichiganCounties :
                                a.long_name === "Wisconsin" ? WisconsinCounties :
                                    a.long_name === "Florida" ? FloridaCounties :
                                        a.long_name === 'Tennesssee' ? TennesseeCounties :

                                            a.long_name === "New York" ? NYCounties : []
                })


                setTimeout(() => {
                    this.setState({ loadingCounties: false })

                }, 1000);

                if (a.long_name !== "California" && a.long_name !== "New York") {
                    delete item.countyName;
                    delete item.countyCode;
                    this.setState({ item })
                }
                item.stateABV = a.short_name;
                item.state = a.long_name;
            }
            if (a.types[0] === "administrative_area_level_2") {
                const countyName = a.short_name.substr(0, a.short_name.indexOf(' '));
                //item.countyName = a.short_name, but lowercase
                item.countyName = countyName.toLowerCase();
                console.log(item.countyName)

            }
            if (a.types[0] === "postal_code") {
                item.postal = a.short_name;
            }
            if (a.types[0] === "route") {
                item.route = a.short_name;
            }
            if (a.types[0] === "street_number") {
                item.streetNumber = a.short_name;
            }
            if (address.address_components.length < 8) {
                item.street = item.route;
            }
            if (address.address_components.length < 7) {
                item.street = "";
            }
            else {
                item.street = `${item.streetNumber || ""}${item.streetNumber ? " " : ""}${item.route || ""}`;

            }


            this.setState({ item });
        })




    }

    updateItem = (boo, val) => {
        const { item } = this.state;
        item[boo] = val;

        this.setState({ item })
    }

    render() {

        const { account, type, screenWidth, item, loading, creating, deleting, dataList, references, layout, widgets, loadingCounties } = this.state;
        const statesList = [
            { "label": "Alabama", "alpha-2": "AL" },
            { "label": "Alaska", "alpha-2": "AK" },
            { "label": "Arizona", "alpha-2": "AZ" },
            { "label": "Arkansas", "alpha-2": "AR" },
            { "label": "California", "alpha-2": "CA" },
            { "label": "Colorado", "alpha-2": "CO" },
            { "label": "Connecticut", "alpha-2": "CT" },
            { "label": "Delaware", "alpha-2": "DE" },
            { "label": "District of Columbia", "alpha-2": "DC" },
            { "label": "Florida", "alpha-2": "FL" },
            { "label": "Georgia", "alpha-2": "GA" },
            { "label": "Hawaii", "alpha-2": "HI" },
            { "label": "Idaho", "alpha-2": "ID" },
            { "label": "Illinois", "alpha-2": "IL" },
            { "label": "Indiana", "alpha-2": "IN" },
            { "label": "Iowa", "alpha-2": "IA" },
            { "label": "Kansa", "alpha-2": "KS" },
            { "label": "Kentucky", "alpha-2": "KY" },
            { "label": "Lousiana", "alpha-2": "LA" },
            { "label": "Maine", "alpha-2": "ME" },
            { "label": "Maryland", "alpha-2": "MD" },
            { "label": "Massachusetts", "alpha-2": "MA" },
            { "label": "Michigan", "alpha-2": "MI" },
            { "label": "Minnesota", "alpha-2": "MN" },
            { "label": "Mississippi", "alpha-2": "MS" },
            { "label": "Missouri", "alpha-2": "MO" },
            { "label": "Montana", "alpha-2": "MT" },
            { "label": "Nebraska", "alpha-2": "NE" },
            { "label": "Nevada", "alpha-2": "NV" },
            { "label": "New Hampshire", "alpha-2": "NH" },
            { "label": "New Jersey", "alpha-2": "NJ" },
            { "label": "New Mexico", "alpha-2": "NM" },
            { "label": "New York", "alpha-2": "NY" },
            { "label": "North Carolina", "alpha-2": "NC" },
            { "label": "North Dakota", "alpha-2": "ND" },
            { "label": "Ohio", "alpha-2": "OH" },
            { "label": "Oklahoma", "alpha-2": "OK" },
            { "label": "Oregon", "alpha-2": "OR" },
            { "label": "Pennsylvania", "alpha-2": "PA" },
            { "label": "Rhode Island", "alpha-2": "RI" },
            { "label": "South Carolina", "alpha-2": "SC" },
            { "label": "South Dakota", "alpha-2": "SD" },
            { "label": "Tennessee", "alpha-2": "TN" },
            { "label": "Texas", "alpha-2": "TX" },
            { "label": "Utah", "alpha-2": "UT" },
            { "label": "Vermont", "alpha-2": "VT" },
            { "label": "Virginia", "alpha-2": "VA" },
            { "label": "Washington", "alpha-2": "WA" },
            { "label": "West Virginia", "alpha-2": "WV" },
            { "label": "Wisconsin", "alpha-2": "WI" },
            { "label": "Wyoming", "alpha-2": "WY" }
        ]

        const SubmitButton = () => {
            const { item, userInfo } = this.state;
            let navigate = useNavigate();
            return (
                <Button style={{ textTransform: 'none', padding: 16, fontSize: 16, backgroundColor: '#404050' }} fullWidth variant="contained" color="primary"
                    onClick={async () => {
                        this.setState({ creating: true })
                        if (item.accountName === undefined || item.accountName.length === 0) {
                            this.props.openSnack('error', "Account name is required")
                            this.setState({ creating: false })
                        }
                        else {
                            console.log(item);

                            const account = {
                                ...item,
                                archived: false,
                                account: item.tempKey,
                                key: item.tempKey,
                                createdBy: userInfo.id,
                                users: [userInfo.id],
                                routeDay: item.routeDay || '',
                                routeName: item.routeName || '',
                                seasonal: item.seasonal || '',
                                createdDate: moment().format('X'),
                                streamsProbesEditable: false,
                            }

                            const db = getFirestore();

                            const docRef = doc(db, "clients", userInfo.currentCompany, 'accounts', account.key);
                            await setDoc(docRef, account).then(async () => {

                                const staffRef = doc(db, "clients", userInfo.currentCompany, 'accounts', account.key, 'staff', userInfo.id);
                                await setDoc(staffRef, { key: userInfo.id, flDistribution: false, adminLevel: 'admin' })


                            }).then(async () => {
                                const userRef = doc(db, "users", userInfo.id,);

                                await updateDoc(userRef, {
                                    currentAccount: account.key,
                                    accounts: arrayUnion(account.key)

                                }).then(async () => {
                                    //create photo album for field logs
                                    const item = { key: uuid(), name: 'Field Log Photos', description: '', archived: false, account: account.key, company: userInfo.currentCompany }
                                    const docRef = doc(db, "clients", userInfo.currentCompany, 'albums', item.key);
                                    await setDoc(docRef, item).then(() => console.log("ALBUM CREATED")).then(async () => {
                                        const itemRef = doc(db, "users", userInfo.id, 'currentItems', 'currentAlbum');
                                        await setDoc(itemRef, {
                                            key: item.key,
                                        })
                                    })
                                }).
                                    then(async () => {

                                        references.map(async (r) => {
                                            const key = r.key;

                                            r.account = account.key;
                                            r.company = userInfo.currentCompany;


                                            const docRef = doc(db, "clients", userInfo.currentCompany, 'accounts', account.key, 'references', key);

                                            await setDoc(docRef, {
                                                ...r,
                                                key
                                            })
                                        })



                                    }).then(async () => {
                                        const stationkey = uuid();
                                        const newStation = {
                                            name: `New Station`,
                                            key: stationkey,
                                            id: stationkey,
                                            children: [
                                               
                                            ],
                                            type: [],
                                            depth: 0,
                                            offset: 0,
                                            sensor: null,
                                            depthUnit: 'ft',
                                            offsetUnit: 'ft',
                                            sensorType: '',
                                            lat: account.lat,
                                            lng: account.lng,
                                            color: randomColor({ luminosity: 'light', format: 'rgba', alpha: 0.9 }),
                                            visible: true,
                                            archived: false,
                                            exists: false,
                                            account: account.key,
                                            company: userInfo.currentCompany,
                                            accountIndex: 0,
                                            deleteItem: false,





                                        }

                                        const db = getFirestore();
                                        const docRef = doc(db, "clients", userInfo.currentCompany, 'stations', newStation.key);
                                        await setDoc(docRef, newStation);
                                    })

                                    .then(() => {
                                        const proxyUrl = "https://mycorslake.herokuapp.com/";
                                        axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/getWeather', {
                                            hours: 24,
                                            lat: item.lat,
                                            lon: item.lng,
                                            account: account.key,
                                            company: userInfo.currentCompany

                                        })
                                            .then(async (response) => {
                                                console.log(response)
                                            })
                                    }).then(async () => {
                                        const docRef = doc(db, "clients", userInfo.currentCompany, 'accounts', account.key, 'dashboard', 'dashboard');

                                        const newWidgets = widgets.map((w) => {


                                            if (w.attributes.widgetType === 'chart') {
                                                const newChartData = w.attributes.chartData.map((d) => {

                                                    return ({
                                                        ...d,
                                                        data: []
                                                    })
                                                })

                                                const newAttributes = { ...w.attributes, chartData: newChartData }
                                                return ({ ...w, attributes: newAttributes })
                                            }
                                            else {
                                                return ({ ...w })
                                            }

                                        })

                                        const layoutString = JSON.stringify(layout);
                                        const widgetsString = JSON.stringify(newWidgets);
                                        var somevalue = widgetsString.substring(0, Math.floor(widgetsString.length / 2)); // seperates string from 10 characters 
                                        var lessthan = widgetsString.substring(Math.floor(widgetsString.length / 2), widgetsString.length); // seperates string from 10 characters negative

                                        await setDoc(docRef, { layout: layoutString, widgets: somevalue, widgets1: lessthan, })
                                    }).then(async () => {
                                        const { userInfo, type } = this.state;
                                        const db = getFirestore();
                                        const queryData = query(collection(db, 'clients', "e467d47-003-6463-6e6b-ee08215ca686", 'accounts', 'f63cd38-4a4d-0e03-fec-d215efab2534', "parameters"),
                                        );
                                        const data = [];
                                        const snap = await getDocs(queryData);
                                        snap.forEach((doc) => {
                                            if (doc.data().archived === undefined || doc.data().archived === false) {
                                                data.push({ ...doc.data(), key: uuid() })
                                            }



                                        });


                                        data.map(async (d, i) => {
                                            const docRef = doc(db, "clients", userInfo.currentCompany, 'accounts', account.key, 'parameters', d.key);
                                            await setDoc(docRef, d)

                                        })

                                        data.sort((a, b) => a.name.localeCompare(b.name))
                                        this.setState({ data, loading: false })
                                        this.props.openSnack('success', 'Account Created!')

                                    }).then(() => {
                                        this.setState({ creating: false })
                                        this.props.updateUserInfo('currentAccount', account.key)
                                        navigate('/account')
                                    })
                            })
                        }

                    }
                    }>
                    {item.key ? `Update Site` : `Create Site`}
                </Button >
            )
        }
        const DeleteButton = () => {
            const { item, userInfo } = this.state;
            let navigate = useNavigate();
            return (

                <Popconfirm
                    title="Are you sure to delete this account?"
                    onConfirm={async () => {
                        this.setState({ deleting: true })

                        const db = getFirestore();
                        const docRef = doc(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount);
                        await updateDoc(docRef, { archived: true }).then(async () => {
                            this.props.openSnack('success', 'Account Deleted!')

                        }).then(async () => {
                            const userRef = doc(db, "users", userInfo.id);

                            // Set the "capital" field of the city 'DC'
                            await updateDoc(userRef, {
                                currentAccount: ''
                            })
                        })
                            .then(() => {
                                this.setState({ creating: false })
                                this.props.updateUserInfo('currentAccount', '', true)
                                navigate('/')
                            })


                    }
                    }>

                    <Button style={{ textTransform: 'none', padding: 16, fontSize: 16 }} fullWidth variant="contained" color="secondary"
                    >
                        {item.key ? `Delete Account` : `Delete Account`}
                    </Button >
                </Popconfirm >
            )
        }

        const UpdateButton = () => {
            const { item, userInfo } = this.state;
            return (
                <Button style={{ textTransform: 'none', padding: 16, fontSize: 16, backgroundColor: '#404050' }} fullWidth variant="contained" color="primary"
                    onClick={async () => {
                        if (item.accountName === undefined || item.accountName.length === 0) {
                            this.props.openSnack('error', "Account name is required")
                        }
                        else {


                            const account = {
                                ...item,
                            }

                            const db = getFirestore();
                            const docRef = doc(db, "clients", userInfo.currentCompany, 'accounts', account.key);
                            await updateDoc(docRef, account).then(async () => {
                                this.props.openSnack('success', 'Site Updated!')

                            })
                        }

                    }
                    }>
                    {item.key ? `Update Site` : `Create Site`}
                </Button >
            )
        }



        const Loading = (props) => {
            return (
                <div style={{ minHeight: '100vh' }}>
                    <Row justify="space-around" gutter={[32, 8]} align="middle" style={{ paddingTop: 18, }}>

                        <Col span={24} style={{ textAlign: 'center', fontSize: 24, fontFamily: 'Roboto, sans-serif', }}>
                            <CircularProgress />
                        </Col>
                    </Row>
                </div>
            )
        }



        return (
            <Row style={{}}>


                {type === 'create' && (<Col span={23}>
                    <Row align="middle">
                        <Col xs={16} sm={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>
                            <Typography variant="h4">Create New Site</Typography>
                        </Col>
                        <Col xs={6} sm={4} style={{ paddingTop: 30, paddingBottom: 15, textAlign: 'right' }}>

                        </Col>
                        <Col span={24}>
                            <Divider />
                        </Col>
                    </Row>
                </Col>)}





                <Col span={23} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 30 }}>

                    {loading ? <Loading /> : <Row gutter={[screenWidth === 'xs' ? 4 : 24, screenWidth === 'xs' ? 4 : 24]} align="middle">
                        <Col xs={24} sm={{ offset: 4, span: 20 }}>
                            <Typography variant="h4">Site</Typography>
                        </Col>
                        <Col xs={24} sm={{ offset: 4, span: 20 }}>

                            <Typography variant="body1">{type === 'create' ? "Add all the information to create a new site!" : "Here is all your site information."}</Typography>

                        </Col>

                        <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                            <Typography variant="body1">Site Name<span style={{ color: 'red' }}>*</span></Typography>
                        </Col>
                        <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                            <OutlinedInput fullWidth notched={false} label="none" placeholder={"Name"} size="small"
                                value={item.accountName}
                                onChange={(e) => {
                                    this.updateItem(`accountName`, e.target.value)
                                }} />
                        </Col>
                        {/*  <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                            <Typography variant="body1">Public Site</Typography>
                        </Col>
                        <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                            <Checkbox checked={item.publicAccount || false}
                                onChange={(e) => {
                                    this.updateItem(`publicAccount`, e.target.checked)
                                }} />

                        </Col> */}
                        <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                            <Typography variant="body1">Site Type</Typography>
                        </Col>
                        <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                            <Autocomplete
                                size="small"
                                disablePortal
                                id="combo-box-demo"
                                options={[
                                    { label: 'Lake', key: 'lake' },
                                    { label: 'Pond', key: 'pond' },
                                    { label: 'Lagoon', key: 'lagoon' },
                                    { label: 'Fountain', key: 'fountain' },
                                    { label: 'Stream', key: 'stream' },
                                    { label: 'River', key: 'river' },
                                    { label: 'Reservoir', key: 'reservoir' },
                                    { label: 'Canal', key: 'canal' },



                                ].sort((a, b) => a.label > b.label ? 1 : -1)}
                                defaultValue={{ label: item.siteType || '' }}
                                renderInput={(params) => <TextField {...params} placeholder="Site Type" />}
                                onChange={(a, b) => {
                                    this.updateItem('siteType', b.key)
                                }}

                            />
                        </Col>

                        <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                            <Typography variant="body1">Location Search<SearchIcon size="small" /></Typography>
                        </Col>
                        <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                            <LocationSearch fillAddress={this.fillAddress} fillLatLng={this.fillLatLng} size={"small"} />
                        </Col>
                        <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                            <Typography variant="body1">Street</Typography>
                        </Col>
                        <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                            <OutlinedInput fullWidth notched={false} placeholder={"Street"} size="small"
                                value={item.street}
                                onChange={(e) => {
                                    this.updateItem(`street`, e.target.value)
                                }} />
                        </Col>
                        <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                            <Typography variant="body1">City</Typography>
                        </Col>
                        <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                            <OutlinedInput fullWidth notched={false} placeholder={"City"} size="small"
                                value={item.city}
                                onChange={(e) => {
                                    this.updateItem(`city`, e.target.value)
                                }} />
                        </Col>
                        <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                            <Typography variant="body1">State</Typography>
                        </Col>
                        <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                            <Autocomplete
                                size="small"
                                disablePortal
                                id="combo-box-demo"
                                options={statesList}
                                defaultValue={{ label: item.state || '' }}
                                value={{ label: item.state || '' }}
                                renderInput={(params) => <TextField {...params} placeholder="State" />}
                                onChange={(a, b) => {
                                    this.updateItem('state', b.label)
                                }}

                            />
                        </Col>
                        <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                            <Typography variant="body1">Zip Code</Typography>
                        </Col>
                        <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                            <OutlinedInput fullWidth notched={false} placeholder={"Zip Code"} size="small"
                                value={item.postal}
                                onChange={(e) => {
                                    this.updateItem(`postal`, e.target.value)
                                }} />
                        </Col>
                        <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                            <Typography variant="body1">County</Typography>
                        </Col>
                        <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                            {loadingCounties === false && (
                                <Autocomplete
                                    size="small"
                                    disablePortal
                                    id="combo-box-demo"
                                    options={
                                        item.state === undefined ? [] :
                                            item.state === 'California' ? CACounties :
                                                item.state === 'New York' ? NYCounties :
                                                    item.state === 'Wisconsin' ? WisconsinCounties :
                                                        item.state === 'Michigan' ? MichiganCounties :
                                                            item.state === 'Florida' ? FloridaCounties :
                                                                item.state === 'Tennessee' ? TennesseeCounties :

                                                                    NYCounties
                                    }
                                    defaultValue={{ countyName: item.countyName || '' }}
                                    value={{ countyName: item.countyName || '' }}
                                    renderInput={(params) => <TextField  {...params} placeholder="County" />}
                                    getOptionLabel={(option) => `${option.countyName.charAt(0).toUpperCase() + option.countyName.slice(1).toLowerCase()}`}
                                    onChange={(a, b) => {
                                        this.updateItem('countyName', b.countyName)
                                    }}

                                />)}

                        </Col>
                        <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                            <Typography variant="body1">Latitude</Typography>
                        </Col>
                        <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                            <OutlinedInput fullWidth notched={false} placeholder={"Latitude"} size="small"
                                value={item.lat}
                                type="number"
                                inputProps={{ min: 0 }}
                                onChange={(e) => {
                                    this.updateItem(`lat`, e.target.value === "" ? "" : Number(e.target.value))
                                }} />

                        </Col>
                        <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                            <Typography variant="body1">Longitude</Typography>
                        </Col>
                        <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                            <OutlinedInput fullWidth notched={false} placeholder={"Longitude"} size="small"
                                value={item.lng}
                                type="number"
                                inputProps={{ min: 0 }}
                                onChange={(e) => {
                                    this.updateItem(`lng`, e.target.value === "" ? "" : Number(e.target.value))
                                }} />

                        </Col>
                        <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                            <Typography variant="body1">Seasonal</Typography>
                        </Col>
                        <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                            <Autocomplete
                                size="small"
                                disablePortal
                                id="combo-box-demo"
                                options={[{ label: 'Seasonal', key: 'seasonal' }, { label: 'Non-Seasonal', key: 'nonSeasonal' }]}
                                defaultValue={{ label: item.seasonal || '' }}
                                renderInput={(params) => <TextField {...params} placeholder="Seasonal" />}
                                onChange={(a, b) => {
                                    this.updateItem('seasonal', b.key)
                                }}

                            />
                        </Col>
                        <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                            <Typography variant="body1">Site Contact</Typography>
                        </Col>
                        <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                            <OutlinedInput fullWidth notched={false} placeholder={"Contact"} size="small"
                                value={item?.siteContact}
                                inputProps={{ min: 0 }}
                                onChange={(e) => {
                                    this.updateItem(`siteContact`, e.target.value)
                                }} />
                        </Col>
                        <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                            <Typography variant="body1">Site Phone</Typography>
                        </Col>
                        <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                            <OutlinedInput fullWidth notched={false} placeholder={"Phone"} size="small"
                                value={item?.sitePhone}
                                inputProps={{ min: 0 }}
                                onChange={(e) => {
                                    this.updateItem(`sitePhone`, e.target.value)
                                }} />
                        </Col>
                        <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                            <Typography variant="body1">Access Code</Typography>
                        </Col>
                        <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                            <OutlinedInput fullWidth notched={false} placeholder={"Code"} size="small"
                                value={item?.accessCode}
                                inputProps={{ min: 0 }}
                                onChange={(e) => {
                                    this.updateItem(`accessCode`, e.target.value)
                                }} />
                        </Col>
                        <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                            <Typography variant="body1">Description</Typography>
                        </Col>
                        <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                            <OutlinedInput fullWidth notched={false} placeholder={"Description"} size="small"
                                value={item?.description}
                                multiline
                                rows={2}
                                onChange={(e) => {
                                    this.updateItem(`description`, e.target.value)
                                }} />
                        </Col>
                        <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                            <Typography variant="body1">Route Day</Typography>
                        </Col>
                        <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                            <Autocomplete
                                size="small"
                                disablePortal
                                id="combo-box-demo"
                                options={[
                                    { label: 'Monday', key: 'monday' },
                                    { label: 'Tuesday', key: 'tuesday' },
                                    { label: 'Wednesday', key: 'wednesday' },
                                    { label: 'Thursday', key: 'thursday' },
                                    { label: 'Friday', key: 'friday' },
                                    { label: 'Saturday', key: 'saturday' },
                                    { label: 'Sunday', key: 'sunday' },
                                ]}
                                defaultValue={{ label: item.routeDay || '' }}
                                renderInput={(params) => <TextField {...params} placeholder="Route Day" />}
                                onChange={(a, b) => {
                                    this.updateItem('routeDay', b.key)
                                }}

                            />
                        </Col>

                        <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                            <Typography variant="body1">GPS Select</Typography>
                        </Col>
                        <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                            <LocationSelect item={item} gps={[Number(item.lng || -117.86652654979824), Number(item.lat || 33.90407648104795)]} zoom={16} updateGPS={this.updateGPS} />

                        </Col>




                        <Col xs={24} sm={{ offset: 6, span: 6 }} style={{ textAlign: 'left' }}>
                            {item.key ? <UpdateButton /> : creating === true ? <CircularProgress /> : <SubmitButton />}
                        </Col>
                        <Col xs={24} sm={{ offset: 6, span: 6 }} style={{ textAlign: 'left' }}>
                            {deleting && (<CircularProgress />)}
                            {item.key && !deleting && (<DeleteButton />)}
                        </Col>

                        {/*   <Col span={24}>
                                <Button onClick={async () => {
                                    const db = getFirestore();
                                    const museums = query(collectionGroup(db, 'accounts'));
                                    const querySnapshot = await getDocs(museums);
                                    const data = [];
                                    const companyKeys = [];
                                    querySnapshot.forEach(async (doc) => {

                                        data.push(doc.data())
                                        companyKeys.push(doc.data().companyKey)


                                    });
                                    console.log(data)
                                    console.log(companyKeys)

                                    this.setState({ dataList: data })

                                    setTimeout(() => {
                                        data.map(async (d, i) => {
                                            if (d.companyKey !== undefined) {
                                                const userRef = doc(db, "clients", d.companyKey, 'accounts', d.key);

                                                await updateDoc(userRef, {
                                                    archived: false,

                                                })
                                                console.log(i)



                                            }
                                        })
                                    }, 2500);




                                }}>Get Accounts</Button>
                            </Col> */}




                    </Row>}

                </Col>
            </Row >
        );
    }
} 