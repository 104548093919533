import React, { useEffect, useState } from "react";


import { faBox, faBucket, faCheck, faFish, faFlask, faListCheck, faMagnifyingGlass, faPlus, faWheatAlt, faWrench, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, ButtonGroup, Divider, FormControl, Grid, IconButton, Input, InputLabel, MenuItem, Modal, OutlinedInput, Select, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import { makeStyles } from "@mui/styles";
import StocksSelect from "../../../../../../tasks/components/drawercomponents/stockSelect";
import HideOptions from "./hideOtherInformation";
import MapHome from "./mapComponents/mapHome";
import GraphicsList from "./mapComponents/graphicsList";
import MapEditor from "./mapComponents/mapEditor";



const MapBox = (
    {
        record,
        items,
        setItems,
        screenWidth,
        openSnack,
        userInfo,
        updateRecord,
        stocks,
        setStocks,
        accounts,
        account,
        setDisableDrag

    }

) => {


    const props = {
        record,
        items,
        setItems,
        screenWidth,
        openSnack,
        userInfo,
        updateRecord,
        stocks,
        setStocks,
        accounts,
        account,
        setDisableDrag,
    };
    return (
        <>


            <Grid container spacing={1}>
                <Grid item xs={12} md={12}
                    style={{
                        /* flex and space-between */
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}>
                    <Typography variant="h6" component="div" color={'rgba(0,0,0,0.8)'} fontWeight={800} fontSize={15} style={{ display: 'inline' }}>
                        Map
                    </Typography>
                    <HideOptions  {...props} val={'showMap'} />
                </Grid>
                <Grid item xs={12} md={12}>
                    <MapHome {...props} />

                </Grid>
               




            </Grid>



         



        </>



    );
};

export default MapBox;
