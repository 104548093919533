import { Autocomplete, Button, Divider, OutlinedInput, TextField, Typography } from '@mui/material';
import { Col, Popconfirm, Progress, Row } from 'antd';
import { doc, getDoc, getFirestore, updateDoc } from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import React from 'react';
import Dropzone from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
export default class CompanyInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            uid: this.props.uid,
            userInfo: this.props.userInfo,
            docData: {},
            progressBars: {},
            loading: true
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.uid !== prevProps.uid) {
            this.setState({ uid: this.props.uid })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
    }


    componentDidMount() {
        window.scrollTo(0, 0)
        const { uid, userInfo } = this.state;
        this.getDocQuery(userInfo.currentCompany)

    }

    getDocQuery = async (id) => {
        const db = getFirestore();

        const docRef = doc(db, "clients", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            this.setState({ docData: docSnap.data(), loading: false })
        } else {
        }
    }

    updateState = (item, value) => {
        const { docData } = this.state;
        docData[item] = value;
        this.setState({ docData })
    }

    render() {
        const { screenWidth, userInfo, docData, progressBars, loading } = this.state;

        const UpdateButton = () => {

            return (
                <Button style={{ textTransform: 'none', padding: 16, fontSize: 16 }} fullWidth={screenWidth === 'xs' ? true : false} variant="contained" color="primary"
                    onClick={async () => {
                        const db = getFirestore();
                        const queryRef = doc(db, "clients", docData.key);
                        console.log(docData)

                        await updateDoc(queryRef, docData);
                        this.props.openSnack('success', 'Client Updated!');


                    }
                    }>
                    Update Company
                </Button >
            )
        }
        const DeleteButton = () => {
            let navigate = useNavigate();
            return (
                <Popconfirm
                    title="Are you sure to delete this company? This cannot be undone!"
                    onConfirm={async () => {

                        console.log(userInfo.currentCompany)
                        const db = getFirestore();
                        const itemRef = doc(db, "clients", userInfo.currentCompany,);
                        await updateDoc(itemRef, {
                            archived: true
                        }).then(() => {
                            this.props.clearAccount()
                            this.props.changeCompany('none')
                            this.props.updateUserInfo('currentAccount', '');
                            this.props.updateUserInfo('currentCompany', '');
                        }).then(() => {
                            navigate('/changeCompany')
                        }).catch((err) => {
                            console.log(err)
                        })

                    }}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button style={{ textTransform: 'none', padding: 16, fontSize: 16 }} fullWidth={screenWidth === 'xs' ? true : false} variant="contained" color="secondary"
                    >
                        Delete Company
                    </Button >
                </Popconfirm>

            )
        }
        console.log(docData.companyType)

        return (
            <Row>
                <Col span={23}>
                    <Row align="middle">
                        <Col xs={16} sm={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>
                            <Typography variant="h4">Update Client</Typography>
                        </Col>

                        <Col span={24}>
                            <Divider />
                        </Col>
                    </Row>
                </Col>

                <Col span={23} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 30 }}>

                    <Row gutter={[12, 12]}>
                        <Col span={24}>
                            <Typography variant="h5">Client Details</Typography>
                        </Col>
                        <Col span={24}>
                            {[
                                { label: 'Client Name', item: 'companyName' },
                            ].map((f) => {
                                return (
                                    <Row style={{ paddingTop: 20 }}>
                                        <Col span={24}>
                                            <Typography variant="subtitle1">{f.label}</Typography>
                                        </Col>
                                        <Col xs={24} sm={13}>
                                            <OutlinedInput fullWidth notched={false} label="none" placeholder={f.label} size="small"
                                                value={docData[f.item]}
                                                onChange={(e) => {
                                                    this.updateState(`${f.item}`, e.target.value)
                                                }} />
                                        </Col>
                                    </Row>
                                )

                            })}
                        </Col>

                        <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                            {loading === false && (<Autocomplete
                                size="small"
                                disableClearable={true}
                                disablePortal

                                id="combo-box-demo"
                                options={[
                                    { label: 'Municipality', key: 'municipality' },
                                    { label: 'District', key: 'district' },
                                    { label: 'Lake Manager', key: 'lakeManager' },
                                    { label: 'Golf Course', key: 'golfCourse' },
                                    { label: 'Winery', key: 'winery' },
                                    { label: 'Citizen Scientist', key: 'citizenScientist' },
                                    { label: 'Irrigation District', key: 'irrigationDistrict' },
                                ]}
                                defaultValue={docData.companyType}
                                renderInput={(params) => <TextField {...params} label="Client Type" />}
                                onChange={(a, b) => {
                                    this.updateState('companyType', b)
                                    this.updateState('companyTypeKey', b.key)
                                }}

                            />)}
                        </Col>

                        <Col span={24}>
                            <Dropzone multiple={false} onDrop={(acceptedFiles) => {

                                acceptedFiles.forEach((f) => {
                                    var id = uuid();


                                    const storage = getStorage();
                                    const storageRef = ref(storage, `${userInfo.currentCompany}/logo/`);

                                    const uploadTask = uploadBytesResumable(storageRef, f);
                                    uploadTask.on('state_changed',
                                        (snapshot) => {
                                            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                                            console.log('Upload is ' + progress + '% done');

                                            progressBars[id] = { id, progress, fileName: f.name }

                                            this.setState({ progressBars })
                                            switch (snapshot.state) {
                                                case 'paused':
                                                    console.log('Upload is paused');
                                                    break;
                                                case 'running':
                                                    console.log('Upload is running');
                                                    break;
                                            }
                                        },
                                        (error) => {
                                        },
                                        () => {
                                            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                                                console.log('File available at', downloadURL);

                                                const fileName = f.name;
                                                const fileType = f.type;
                                                const fileLink = downloadURL;
                                                console.log(fileName)
                                                console.log(fileType)
                                                console.log(fileLink)
                                                delete progressBars[id];
                                                docData.fileName = fileName;
                                                docData.fileType = fileType;
                                                docData.fileLink = fileLink;
                                                this.setState({ progressBars, docData })
                                            });
                                        }
                                    );


                                })





                            }}>
                                {({ getRootProps, getInputProps }) => (
                                    <section  >
                                        <div {...getRootProps()} style={{ border: '1px gray dashed', borderRadius: 8, padding: 10, cursor: 'pointer' }}  >

                                            <input {...getInputProps()} />
                                            <img style={{
                                                maxHeight: '80px', maxWidth: '100%',
                                                boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px -1px, rgba(0, 0, 0, 0.14) 0px 5px 8px 0px, rgba(0, 0, 0, 0.12) 0px 1px 14px 0px'
                                            }} src={docData.fileLink} />





                                        </div>

                                    </section>
                                )}
                            </Dropzone>
                        </Col>
                        <Col span={20} offset={4}>
                            {Object.values(progressBars).map((p) => {
                                return (
                                    <Col span={24}>
                                        {p.progress > 0 && (
                                            <div> {p.fileName} <Progress percent={p.progress} /></div>
                                        )}
                                    </Col>

                                )
                            })}
                        </Col>

                        <Col xs={24} sm={6} style={{ paddingTop: 25 }}>
                            <UpdateButton />
                        </Col>
                        {['EuEZ93ByFZYs8bSUXoYsTt1zu0m2', 'eTevYsI9mQMxhtntd95HmRGasNx2'].includes(userInfo.id) && (<Col xs={24} sm={6} style={{ paddingTop: 25 }}>
                            <DeleteButton />
                        </Col>)}



                    </Row>

                </Col>
            </Row>
        );
    }
} 