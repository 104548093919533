import { Button, Checkbox, Divider, OutlinedInput, Typography, Select, MenuItem, CircularProgress, Autocomplete, TextField, Switch, ButtonGroup, InputAdornment, LinearProgress } from '@mui/material';
import { Col, Popconfirm, Row } from 'antd';
import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { fullMobile } from './../util';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, } from 'firebase/firestore';

import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import uuid from 'react-uuid';


import DatePicker from "react-datepicker";
import queryString from 'query-string';
import axios from 'axios';
var randomColor = require('randomcolor');

export default function MergeDataManagement({ userInfo, screenWidth, openSnack, company, account, probesRef, stationsRef }) {
    const [stations, setStations] = useState(stationsRef)
    const [probes, setProbes] = useState(probesRef)
    const [loadingStations, setLoadingStations] = useState(true)
    const [loadingProbes, setLoadingProbes] = useState(true)
    const [fromID, setFromID] = useState(null)
    const [fromProbeId, setFromProbeId] = useState('')
    const [toID, setToID] = useState(null)
    const [fromType, setFromType] = useState('probe')
    const [toType, setToType] = useState('probe')
    const [startDate, setStartDate] = useState(moment().subtract(1, 'days').format('x'))
    const [endDate, setEndDate] = useState(moment().format('x'))
    const [merging, setMerging] = useState(false)
    const [recordsAdded, setRecordsAdded] = useState(0)
    const [recordCount, setRecordCount] = useState(0)

    useEffect(() => {


    }, [])

    async function countRecords(start, end) {
        const proxyUrl = "https://mycorslake.herokuapp.com/";

        const raw1 = `
        SELECT 
   COUNT(*) 
   FROM probe_data
   WHERE locationid = '${fromID.id}'  AND time >= '${start}' AND time <= '${end}'
   ;`


        axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
            raw: raw1
        }).then(async (response) => {
            // handle success
            console.log(response)
            setRecordCount(response.data[0].count)
        })
            .catch((error) => {
                // handle error
                console.log(error);
            })
    }



    async function handleMerge(startTime, recordAddedAmount) {
        setMerging(true)

        //convert start and end date to 
        //get data from fromID
        const newStartDate = moment(startDate, 'x').tz("America/Los_Angeles").format("YYYY-MM-DD HH:mm");
        const newEndDate = moment(endDate, 'x').tz("America/Los_Angeles").format("YYYY-MM-DD HH:mm");


        const proxyUrl = "https://mycorslake.herokuapp.com/";
        countRecords(newStartDate, newEndDate)




        const raw = `SELECT * FROM ${fromType === 'probe' ? 'probe_data' : fromType === 'probe id' ? 'probe_data' : 'node_data_new2'} 
        WHERE ${fromType === 'probe' ? 'locationid' : fromType === 'probe id' ? 'locationid' : 'nodeid'} = '${fromType === 'probe id' ? fromProbeId : fromID.id}' 
        AND time >= '${startTime === null ? newStartDate : startTime}' AND time <= '${newEndDate}'
        ORDER BY time ASC LIMIT 2500
        `


        console.log(raw)
        axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgetsFire2/sqlRead', {
            raw
        }).then((res) => {

            //get time of last record and first record
            const lastRecordTime = res.data[res.data.length - 1].time
            const firstRecordTime = res.data[0].time
            console.log(lastRecordTime)
            console.log(firstRecordTime)

            if (res.data.length === 0) {
                openSnack("error", "No data found")
                setMerging(false)
                return
            } else {
                //create new key for each data point and add latitude and longitude
                const fromItem = fromType === 'probe id' ? fromProbeId : fromID.id
                const probe = probes.find((probe) => probe.id === fromItem)

                const lng = probe !== undefined && probe.lon !== undefined ? probe.lon : 0
                const lat = probe !== undefined && probe.lat !== undefined ? probe.lat : 0

                const newData = res.data.map((dataPoint) => {
                    if (toType === 'probe') {
                        return {
                            time: `'${dataPoint.time}'`,
                            timestamp: dataPoint.timestamp,
                            locationid: `'${fromType === 'probe' ? toID.id : fromType === 'probe id' ? toID.id : dataPoint.locationid}'`,
                            unitid: `'${dataPoint.unitid}'`,
                            parameterid: `'${dataPoint.parameterid}'`,
                            value: dataPoint.value,
                            key: `'${dataPoint.key || uuid()}'`,
                        }
                    }
                    else {
                        return {

                            time: `'${dataPoint.time}'`,
                            timestamp: dataPoint.timestamp,
                            locationid: `'${dataPoint.locationid}'`,
                            unitid: `'${dataPoint.unitid}'`,
                            parameterid: `'${dataPoint.parameterid}'`,
                            value: dataPoint.value,
                            account: `'${userInfo.currentAccount}'`,
                            company: `'${userInfo.currentCompany}'`,
                            nodeid: `'${toID.id}'`,
                            key: `'${dataPoint.key || uuid()}'`,
                            longitude: lng,
                            latitude: lat,
                            logkey: `'none'`

                        }
                    }
                })


                const hourlyVals = newData.map((h) => `(${Object.values(h)})`);
                const hourlyString = hourlyVals.join();
                axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgetsFire2/sqlread', {
                    raw: `INSERT INTO ${toType === 'probe' ? 'probe_data' : 'node_data_new2'} VALUES ${hourlyString} ON CONFLICT DO NOTHING`
                }).then((res) => {

                    //get earliest time
                    if (newData.length > 0) {
                        const earliestTime = newData[0].time
                        const latestTime = newData[newData.length - 1].time

                        console.log(latestTime)
                        console.log(startTime)
                        //add newData.length to recordsAdded
                        setRecordsAdded(recordAddedAmount + newData.length)



                        if (moment(latestTime, 'YYYY-MM-DD HH:mm:ss').tz("America/Los_Angeles").format("YYYY-MM-DD HH:mm") !== startTime) {
                            handleMerge(moment(latestTime, 'YYYY-MM-DD HH:mm:ss').tz("America/Los_Angeles").format("YYYY-MM-DD HH:mm"), recordAddedAmount + newData.length)
                        }
                        else {
                            openSnack("success", "Data merged successfully")
                            //clear fields
                            setFromID(null)
                            setToID(null)
                            setMerging(false)
                            setRecordCount(0)
                            setRecordsAdded(0)
                        }

                    }
                    else {
                        openSnack("success", "Data merged successfully")
                        //clear fields
                        setFromID(null)
                        setToID(null)
                        setMerging(false)
                    }







                }).catch((err) => {
                    console.log(err)
                    openSnack("error", "Error merging data")

                    setMerging(false)
                })
            }





        }).catch((err) => {
            console.log(err)
            setMerging(false)
            openSnack("error", "Error merging data")
        })
    }


    const Checks = () => {
        const fromIDItem = fromType === 'probe id' ? fromProbeId : fromID?.id
        const checksList = [fromType, toID, fromType, toType, startDate, endDate]
        const showDescription = true;

        for (let i = 0; i < checksList.length; i++) {
            if (checksList[i] === null) {
                return false
            }
        }
        //check if start date is before end date and also if they are not the same and also if start date is not in the future
        if (startDate >= endDate || startDate === endDate || startDate > moment().format('x')) {
            return false
        }

        //check if fromId.id and toId.id are not the same

        if (fromIDItem === toID.id) {
            return false
        }



        return (
            <Col span={23}>
                {merging ?
                    <Row align="middle" gutter={[12, 12]}>
                        <Col span={24}>
                            <CircularProgress display="inline" />
                            <Typography variant="body1" display="inline">Merging data...</Typography>
                            <Typography variant="body1" display="inline" style={{ marginLeft: 10 }}>Records added: {recordsAdded} / {recordCount}</Typography>
                            <Typography variant="body1" display="inline" style={{ marginLeft: 10 }}>Percent Completed: {Math.round((recordsAdded / recordCount) * 100)}%</Typography>
                        </Col>
                        <Col span={24}>
                            <LinearProgress variant="determinate" value={(recordsAdded / recordCount) * 100} />
                        </Col>
                    </Row>

                    :
                    <Row align="middle" gutter={[12, 12]}>
                        <Col xs={16} sm={20} style={{}}>
                            <Typography variant="h6">Are you sure you want to merge this data?</Typography>
                        </Col>

                        <Col span={24}>
                            <Popconfirm
                                title={<Row style={{ width: '33vw' }}>
                                    <Col span={24}>
                                        <Typography variant="h6">Are you sure you want to merge this data?</Typography>
                                    </Col>
                                    <Col span={24}>
                                        <Typography variant="body1">This action cannot be undone.</Typography>
                                    </Col>
                                    <Col span={24}>
                                        <Typography variant="body1">This action will not delete the data from the "From" probe/node, but only transfered to the "To" probe/node.</Typography>
                                    </Col>

                                </Row>}
                                onConfirm={() => {
                                    handleMerge(null, 0)
                                }}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button variant="contained" color="primary"
                                size="large"
                                style={{
                                    textTransform: 'none',
                                    color: 'white',
                                    backgroundColor: '#404050',
                                    border: '1px solid red'
                                }}
                                 >Merge</Button>
                            </Popconfirm>
                        </Col>
                    </Row >
                }
            </Col >
        )


    }


    return (
        <Row style={{ fontFamily: 'Roboto, sans-serif',  }} >



            <Col span={23}>
                <Row align="middle">
                    <Col xs={16} sm={20} style={{}}>
                        <Typography variant="h6">From</Typography>
                    </Col>

                    <Col span={24}>

                        <Row align="middle" gutter={[12, 12]}>
                            <Col >
                                <ButtonGroup display="inline" variant="contained" aria-label="outlined primary button group"
                                size="large"
                                 >
                                    {["Probe", "Station", "Probe ID"].map((item, index) => {
                                        return (
                                            <Button
                                            size="large"
                                            style={{
                                                textTransform: 'none',
                                                color: fromType === item.toLowerCase() ? 'white' : '#404050',
                                                backgroundColor: fromType === item.toLowerCase() ? '#404050' : 'white',
                                            }}
                                                key={index}
                                                onClick={() => {
                                                    setFromType(item.toLowerCase())
                                                    setFromID(null)
                                                }}
                                               
                                                variant={fromType === item.toLowerCase() ? "contained" : "outlined"}
                                            >
                                                {item}
                                            </Button>
                                        )
                                    })}
                                </ButtonGroup>
                            </Col>

                            <Col >


                                {['probe', 'station'].includes(fromType) && (<Autocomplete
                                    display="inline"
                                    style={{ marginTop: 10, width: 200 }}
                                    size="large"
                                    disablePortal
                                    id="combo-box-demo"
                                    options={fromType === 'probe' ? probes : stations}
                                    value={fromID}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={fromType === 'probe' ? "Select Probe" : "Select Station"}
                                            variant="outlined"
                                        />
                                    )}
                                    getOptionLabel={(option) => option.label}
                                    onChange={(a, b) => {
                                        console.log(b)
                                        setFromID(b)

                                    }}
                                    InputProps={{ style: { fontSize: 6 } }}


                                />)}
                                {['probe id'].includes(fromType) && (
                                    <OutlinedInput
                                        style={{ width: 300 }}
                                        size="large"
                                        id="outlined-adornment-amount"
                                        value={fromID}
                                        onChange={(e) => {
                                            setFromProbeId(e.target.value)
                                        }}
                                        startAdornment={<InputAdornment position="start">ID</InputAdornment>}
                                        labelWidth={0}
                                    />

                                )}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>

            <Col span={23}>
                <Row align="middle">
                    <Col xs={16} sm={20} style={{}}>
                        <Typography variant="h6">To</Typography>
                    </Col>

                    <Col span={24}>

                        <Row align="middle" gutter={[12, 12]}>
                            <Col >
                                <ButtonGroup display="inline" variant="contained" aria-label="outlined primary button group"
                                 size="large" >
                                    {["Probe", "Station"].map((item, index) => {
                                        return (
                                            <Button
                                                key={index}
                                                size="large"
                                                style={{
                                                  textTransform: 'none',
                                                    color: toType === item.toLowerCase() ? 'white' : '#404050',
                                                    backgroundColor: toType === item.toLowerCase() ? '#404050' : 'white',
                                                }}
                                                onClick={() => {
                                                    setToType(item.toLowerCase())
                                                    setToID(null)
                                                }}
                                                
                                                variant={toType === item.toLowerCase() ? "contained" : "outlined"}
                                            >
                                                {item}
                                            </Button>
                                        )
                                    })}
                                </ButtonGroup>
                            </Col>

                            <Col >

                                <Autocomplete
                                    display="inline"
                                    style={{ marginTop: 10, width: 200 }}
                                    size="large"
                                    disablePortal
                                    id="combo-box-demo"
                                    options={toType === 'probe' ? probes : stations}
                                    value={toID}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={toType === 'probe' ? "Select Probe" : "Select Station"}
                                            variant="outlined"
                                        />
                                    )}
                                    getOptionLabel={(option) => option.label}
                                    onChange={(a, b) => {
                                        setToID(b)

                                    }}
                                    InputProps={{ style: { fontSize: 6 } }}


                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>

            <Col span={23}>
                <Row align="middle">
                    <Col xs={16} sm={20} style={{}}>
                        <Typography variant="h6">Time Range</Typography>
                    </Col>
                    <Col span={24}>
                        <Row gutter={[12, 12]} align="middle">
                            <Col >
                                <Typography fontSize={13} variant="body1">Start Date</Typography>
                            </Col>
                            <Col >

                                <DatePicker portalId="root-portal"
                                    showTimeSelect
                                    timeFormat="h:mm a"
                                    timeIntervals={1}
                                    timeCaption="time"
                                    dateFormat="MM/dd/yyyy h:mm a"
                                    selected={
                                        [undefined, '', "Invalid date", null].includes(startDate) ? new Date() : new Date(moment(startDate, 'x'))}
                                    onChange={(date) => {
                                        setStartDate(moment(date).format('x'))


                                    }} />


                            </Col>
                            <Col >
                                <Typography fontSize={13} variant="body1">End Date</Typography>
                            </Col>
                            <Col >

                                <DatePicker portalId="root-portal"
                                    showTimeSelect
                                    timeFormat="h:mm a"
                                    timeIntervals={1}
                                    timeCaption="time"
                                    dateFormat="MM/dd/yyyy h:mm a"
                                    selected={
                                        [undefined, '', "Invalid date", null].includes(endDate) ? new Date() : new Date(moment(endDate, 'x'))}
                                    onChange={(date) => {
                                        setEndDate(moment(date).format('x'))


                                    }} />


                            </Col>
                        </Row>
                    </Col>


                </Row>
            </Col>
            <Col span={23}>
                <Divider />
            </Col>
            <Col span={23}>
                <Row align="middle">
                    <Col xs={16} sm={20} style={{}}>
                        <Typography variant="h6">Summary</Typography>
                    </Col>
                    <Col span={24}>
                        <Row align="middle" gutter={[12, 12]}>
                            <Col span={24} >
                                <Typography variant="body1">From: {fromType === 'probe id' ? fromProbeId : fromID ? fromID.label : ""}</Typography>
                            </Col>
                            <Col span={24} >
                                <Typography variant="body1">To: {toID ? toID.label : ""}</Typography>
                            </Col>
                            <Col span={24} >
                                <Typography variant="body1">Start Date: {startDate ? moment(startDate, 'x').format('lll') : ""}</Typography>
                            </Col>
                            <Col span={24} >
                                <Typography variant="body1">End Date: {endDate ? moment(endDate, 'x').format('lll') : ""}</Typography>
                            </Col>

                        </Row>
                    </Col>
                </Row>
            </Col>
            <Col span={23}>
                <Divider />
            </Col>
            <Col xs={16} sm={20} style={{}}>
                <Typography variant="body1">
                    Be sure that the from and to are not the same, that the start date is before the end date, and that the start date is not in the future.

                </Typography>
            </Col>
            <Checks />





        </Row>
    );
}