import { Button, Checkbox, Divider, OutlinedInput, Typography, Select, MenuItem, CircularProgress, Paper, FormControl, InputLabel, IconButton } from '@mui/material';
import { Col, Row } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment-timezone';
import { fullMobile } from '../util';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, } from 'firebase/firestore';

import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import uuid from 'react-uuid';

import { Tree } from "react-arborist";


import queryString from 'query-string';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDatabase, faMapLocationDot, faMapMarked, faVialCircleCheck, faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import { faSquare, } from '@fortawesome/free-regular-svg-icons';
var randomColor = require('randomcolor');

export default function QueryItem(props) {
    const { userInfo, screenWidth, openSnack, company, account, updateLog, logRef, status, PPArray, WPArray, rainGauges, parameters, nodes, probes, queryRef, } = props;
    const [query, setQuery] = useState(queryRef);

    useEffect(() => {

    }, [])
    const parametersList = [...PPArray, ...parameters].sort((a, b) => a.label.localeCompare(b.label))



    //change query.parameters to a list of labels from parametersList

    const queryParameters = query.parameters.map(p => {
        const parameter = parametersList.find(pp => pp.key === p)
        return parameter.label !== undefined ? parameter.label : 'Unknown Parameter';
    })
    //join queryParameters into a string with commas, but use & for the last one if there are more than 2 and use and if there are only 2
    const queryParametersString = queryParameters.length > 2 ? queryParameters.slice(0, -1).join(', ') + ', & ' + queryParameters.slice(-1) : queryParameters.length === 2 ? queryParameters.join(' & ') : queryParameters.join(', ')


    const queryNodes = query.nodes.map(n => {
        const node = nodes.find(nn => nn.id === n)
        return node.label !== undefined ? node.label : 'Unknown Node';
    })
    const queryNodesString = queryNodes.length > 2 ? queryNodes.slice(0, -1).join(', ') + ', & ' + queryNodes.slice(-1) : queryNodes.length === 2 ? queryNodes.join(' & ') : queryNodes.join(', ')

    const queryProbes = query.probes.map(p => {
        const probe = probes.find(pp => pp.id === Number(p))
        return probe.name !== undefined ? probe.name : 'Unknown Probe';
    })
    const queryProbesString = queryProbes.length > 2 ? queryProbes.slice(0, -1).join(', ') + ', & ' + queryProbes.slice(-1) : queryProbes.length === 2 ? queryProbes.join(' & ') : queryProbes.join(', ')

    const queryGauges = query.rainGauges.map(g => {
        const gauge = rainGauges.find(gg => gg.key === g)
        return gauge?.name !== undefined ? gauge?.name : 'Unknown Gauge';
    })
    const queryGaugesString = queryGauges.length > 2 ? queryGauges.slice(0, -1).join(', ') + ', & ' + queryGauges.slice(-1) : queryGauges.length === 2 ? queryGauges.join(' & ') : queryGauges.join(', ')

    const queryWPs = query.rainParameters.map(wp => {
        const WP = WPArray.find(wwp => wwp.key === wp)
        return WP.label !== undefined ? WP.label : 'Unknown Parameter';
    })
    const queryWPsString = queryWPs.length > 2 ? queryWPs.slice(0, -1).join(', ') + ', & ' + queryWPs.slice(-1) : queryWPs.length === 2 ? queryWPs.join(' & ') : queryWPs.join(', ')

  



    useEffect(() => {
        setQuery(queryRef)
    }, [queryRef])



    const h1Size = 14;
    const h2Size = 16;
    const color = '#404050'

    return (
        <Row align="middle" gutter={[4, 4]}>
            {query.showQuery === true && (<Col span={24}>
                <Typography variant="h6" display={'inline'} fontWeight={400} fontSize={h1Size}>
                    Select from
                </Typography>
                <Typography variant="h6" display={'inline'} color={color} fontWeight={600} fontSize={h1Size}>
                    {` ${queryRef.dataSource || ''} `}
                </Typography>
                <Typography variant="h6" display={'inline'} fontWeight={400} fontSize={h1Size}>
                    where data is after
                </Typography>
                <Typography variant="h6" display={'inline'} color={color} fontWeight={600} fontSize={h1Size}>
                    {` ${queryRef.startDate ? moment(queryRef.startDate).format('MM/DD/YYYY hh:mm a') : ''} `}
                </Typography>
                <Typography variant="h6" display={'inline'} fontWeight={400} fontSize={h1Size}>
                    and before
                </Typography>
                <Typography variant="h6" display={'inline'} color={color} fontWeight={600} fontSize={h1Size}>
                    {` ${queryRef.endDate ? moment(queryRef.endDate).format('MM/DD/YYYY hh:mm a') : ''} `}
                </Typography>
                <Typography variant="h6" display={'inline'} fontWeight={400} fontSize={h1Size}>
                    where the parameters are
                </Typography>
                {['probe', 'node'].includes(query.dataSource) && (
                    <Typography variant="h6" display={'inline'} color={color} fontWeight={600} fontSize={h1Size}>
                        {` ${queryParametersString !== '' ? queryParametersString : 'all'} `}
                    </Typography>
                )}
                {query.dataSource === 'rain' && (
                    <Typography variant="h6" display={'inline'} color={color} fontWeight={600} fontSize={h1Size}>
                        {` ${queryWPsString !== '' ? queryWPsString : 'all'} `}
                    </Typography>
                )}
                <Typography variant="h6" display={'inline'} fontWeight={400} fontSize={h1Size}>
                    at
                </Typography>
                {query.dataSource === 'node' && (<Typography variant="h6" display={'inline'} color={color} fontWeight={600} fontSize={h1Size}>
                    {` ${queryNodesString !== '' ? queryNodesString : 'all'} `}
                </Typography>)}
                {query.dataSource === 'probe' && (<Typography variant="h6" display={'inline'} color={color} fontWeight={600} fontSize={h1Size}>
                    {` ${queryProbesString !== '' ? queryProbesString : 'all'} `}
                </Typography>)}
                {query.dataSource === 'rain' && (<Typography variant="h6" display={'inline'} color={color} fontWeight={600} fontSize={h1Size}>
                    {` ${queryGaugesString !== '' ? queryGaugesString : 'all'} `}
                </Typography>)}




            </Col>)}









        </Row>
    );
}