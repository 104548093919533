import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Box,
    Container,
    Grid
} from "@mui/material";
import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { unitsArray } from "../../../extra/unitsArray";
import { queryCodes, queryParameters, queryStations } from "../../../firebase/config";
import CSVUploadSteps from "../uploadCSV/csvUploadSteps";
import { backStepFunc, getTemplatesMulti, nextStepFunc } from "../uploadCSV/functions";
import UploadHeader from "../uploadCSV/uploadHeader";
import UploadComponentMulti from "./uploadComponentMultiple";
import MapDataMulti from "./mapDataMulti";
import DataTableMulti from "./dataTableHeader";



const UploadCSVMultipleHome = ({ screenWidth, openSnack, userInfo, uploading, setUploading, uploadType, returnHome }) => {
    const [templates, setTemplates] = useState([]);
    const [template, setTemplate] = useState(null);
    const [item, setItem] = useState(null);
    const [data, setData] = useState(null);
    const [headers, setHeaders] = useState([]);
    const [codes, setCodes] = useState([]);
    const [parameters, setParameters] = useState([]);
    const [stations, setStations] = useState([]);
    const [meta, setMeta] = useState({});
    const [loading, setLoading] = useState(false);
    const [headerErrors, setHeaderErrors] = useState(0);




    useEffect(() => {
        window.scrollTo(0, 0)

        getInfo(userInfo);
    }, []);

    async function getInfo(userInfo) {

        const parameters = await queryParameters(userInfo)
        parameters.forEach((p, i) => {
            p.parameterType = 'Custom Paramter';
        })
        setParameters(parameters)
        const stations = await queryStations(userInfo, ['grab', 'both', 'sensor'])

        setStations(stations)
        const codes = await queryCodes(userInfo)

        setCodes(codes)

        const templates = await getTemplatesMulti(userInfo)
        setTemplates(templates)

    }



    const [steps, setSteps] = useState([
        {
            step: "upload",
            stepStatus: "Current",
            stepTitle: "Upload your CSV file",
            stepDescription: "Please upload your CSV file into the system"
        },
        {
            step: "map",
            stepStatus: "Waiting",
            stepTitle: "Map Data",
            stepDescription: "Please map your data to the appropriate fields"
        },
        {
            step: "review",
            stepStatus: "Waiting",
            stepTitle: "Review Data",
            stepDescription: "Please review your data"
        },
        {
            step: "confirm",
            stepStatus: "Waiting",
            stepTitle: "Confirm",
            stepDescription: "Please confirm your data"
        }
    ]);







    const nextStep = () => nextStepFunc(steps, setSteps);
    const backStep = () => backStepFunc(steps, setSteps);

    const currentStep = steps.find(step => step.stepStatus === "Current");

    function checkErrors(data) {
        let warningsCount = 0;

        // List of mapped fields to be checked
        const fieldsToCheck = ['node'];
        const fieldsToCheckWarnings = ['units', 'codes'];

        // Corresponding lists of valid options
        const validOptions = {
            'node': stations,
        };

        // Count the errors
        let errorsCount = fieldsToCheck.reduce((count, field) => {
            const header = headers.find((header) => header.mappedField === field);
            const options = validOptions[field];
            const check = options.find((option) => option.label === data?.[header.name]);

            return count + (check ? 0 : 1);
        }, 0);

        const dateHeader = headers.find((header) => header.mappedField === 'date');
        const date = data?.[dateHeader?.name];
        if (isNaN(new Date(date).getTime())) {
            errorsCount++;
        }
        
 
        const nonRequiredHeaders = headers.filter((header) => header.required === undefined);


        //count how many are blank
        const blankCount = nonRequiredHeaders.reduce((count, header) => {
            const value = data?.[header.name];
            return count + (value === undefined || value === '' ? 1 : 0);
        }, 0);

        warningsCount += blankCount;






        // Check if 'value' is a number










        return { errorsCount, warningsCount };


    }

    const props = {
        templates,
        setTemplates,
        template,
        setTemplate,
        steps,
        setSteps,
        currentStep,
        item,
        setItem,
        nextStep,
        backStep,
        screenWidth,
        openSnack,
        userInfo,
        uploading,
        setUploading,
        codes,
        setCodes,
        parameters,
        setParameters,
        stations,
        setStations,
        unitsArray,
        setData,
        data,
        meta,
        setMeta,
        setHeaders,
        headers,
        returnHome,
        uploadType,
        deleteHeader,
        headerErrors,
        setHeaderErrors,
        checkErrors



    };


    useEffect(() => {
        //when steps goes back to first step, clear data
        if (currentStep.step === "finished") {
            setData(null)
            setHeaders([])
            setMeta({})
            setTemplate(null)
            setSteps(
                [
                    {
                        step: "upload",
                        stepStatus: "Current",
                        stepTitle: "Upload your CSV file",
                        stepDescription: "Please upload your CSV file into the system"
                    },
                    {
                        step: "map",
                        stepStatus: "Waiting",
                        stepTitle: "Map Data",
                        stepDescription: "Please map your data to the appropriate fields"
                    },
                    {
                        step: "review",
                        stepStatus: "Waiting",
                        stepTitle: "Review Data",
                        stepDescription: "Please review your data"
                    },
                    {
                        step: "confirm",
                        stepStatus: "Waiting",
                        stepTitle: "Confirm",
                        stepDescription: "Please confirm your data"
                    }
                ]
            )
        }
    }, [currentStep])



    async function deleteHeader(header) {
        const newHeaders = headers.filter(h => h.name !== header.name)
        setHeaders(newHeaders)
        //remove all the data that has this header
        const newData = data.map(d => {
            const newD = { ...d }
            delete newD[header.name]
            return newD
        }
        )
        setData(newData)
    }


    if (loading) {
        return (
            <Container sx={{
                p: 0,
                m: 0
            }}>
                <Box sx={{}}>

                    <Grid container spacing={3} mt={4}>
                        <FontAwesomeIcon icon={faSpinner} spin size="3x" color="#3f51b5" />
                    </Grid>
                </Box>
            </Container>
        )
    }
    else {





        return (

            <Row sx={{}}>
                <Col span={24}>
                    <CSVUploadSteps steps={steps} />
                </Col>
                <Col span={24} >
                    <Box sx={{ px: 1 }}>
                        <UploadHeader {...props} />
                    </Box>
                </Col>



                <Col span={24} >
                    {currentStep.step === "upload" && (<UploadComponentMulti {...props} />)}
                    {currentStep.step === "map" && (<MapDataMulti {...props} />)}
                    {currentStep.step === "review" && (<DataTableMulti {...props} />)}
                </Col>

                <Grid container spacing={3} mt={4}>

                </Grid>
            </Row>

        );
    }

};

export default UploadCSVMultipleHome;
