import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import MapComponent from "./mapForEditor";
import MapV2 from "./mapV2";
import { fetchMapDefaults, fetchMapInfo, readGraphicsLayer, removeMapGraphics } from "./symbology/labels";
import MoreMapOptions from "./moreMapOptions";

function MapEditor({ account, setDisableDrag, record, updateRecord, open, setOpen, userInfo, getLatestMapInfo, type }) {

    const [loading, setLoading] = useState(true);

    const [mapInfo, setMapInfo] = useState({});

 
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90vw',
        height: '90vh',
        bgcolor: 'background.paper',
        border: '1px solid rgba(0, 0, 0, .3)',
        boxShadow: 10,
        p: 2,
        borderRadius: '2px',
        display: 'flex', // Added for flex layout
        flexDirection: 'column', // Stack children vertically
    };

    const handleClose = () => {
        setOpen(false);
        getLatestMapInfo();
    };


    async function getMapInfo(userInfo, record) {
        const mapInfo = await fetchMapInfo(userInfo, record);
        console.log("MAP INFO", mapInfo)
        setMapInfo(mapInfo);
        setLoading(false);

    }



    useEffect(() => {
        if (open === true) {
            /* get Map Info */
            getMapInfo(userInfo, record);
        }

    }, [open])

    async function resetMapOptions() {
        setLoading(true);
        const mapInfo = await fetchMapDefaults(userInfo, record);
        setMapInfo(mapInfo);

        setTimeout(() => {
            setLoading(false);
        }, 1000)

    }

    async function deleteAllGraphics() {

        /* update graphicsLayer to [] */
        setLoading(true);
        await removeMapGraphics(userInfo, record);
        mapInfo.graphicsLayer = [];
        setMapInfo(mapInfo);

        setTimeout(() => {
            setLoading(false);
        }, 1000)
    }


    const props = {
        account,
        setDisableDrag,
        record,
        updateRecord,
        open,
        userInfo,
        mapInfo,
        resetMapOptions,
        deleteAllGraphics,
        type,
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {/* Use flex container to ensure components distribute space correctly */}
                    <Box style={{
                        padding: '20px',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2" fontSize={22} fontWeight={600} >
                            Map Editor
                        </Typography>
                        <Box>
                            <Button
                                variant="outlined"
                                style={{
                                    color: '#3f51b5',
                                    marginRight: '10px',
                                    textTransform: 'none',
                                }}
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                style={{
                                    backgroundColor: '#3f51b5',
                                    color: 'white',
                                    textTransform: 'none',
                                }}
                                onClick={handleClose}
                            >
                                <FontAwesomeIcon icon={faFloppyDisk} size="lg" style={{ marginRight: '5px' }} /> Save
                            </Button>
                            <MoreMapOptions {...props} />
                        </Box>
                    </Box>

                    {/* Map Section with flexGrow to take remaining space */}
                    <Box sx={{
                        flexGrow: 1,
                        width: '100%',
                    }}>
                        {loading === false ? <MapComponent {...props} /> : null}
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}

export default MapEditor;
