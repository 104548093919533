import React, { useEffect } from "react";
import { Input, Typography } from "@mui/material";
import moment from 'moment-timezone';
import WifiIcon from '@mui/icons-material/Wifi';
import WifiOffIcon from '@mui/icons-material/WifiOff';
const Status = ({
    currentStream,
    setCurrentStream,
    headerKey,
    options
}) => {
    const [value, setValue] = React.useState(currentStream?.[headerKey]);  // Renamed from 'age' to 'value'

    useEffect(() => {
        setValue(currentStream?.[headerKey]);
    }, [currentStream]);


    return (
        <>
            {value === true ? <>
                <WifiIcon /> Connected
            </> :
                <>
                    <WifiOffIcon /> No connection
                </>}

        </>
    );
};

export default Status;
