import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Popper, ClickAwayListener, Box, ListItem, Typography } from '@mui/material';
import { Col, List, Popconfirm, Row } from 'antd';
import React, { useRef, useState, useEffect } from 'react';

const DeleteStaff = ({ getInfo, userInfo, openSnack, deleteStaff, staffKey }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const projectRef = useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);



    useEffect(() => {
        //set anchorEl to projectRef
        setAnchorEl(projectRef.current)

    }, [])



    return (
        <div>
            {
                isOpen && (
                    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
                        <Popper
                            id={'popper'}
                            open={isOpen}
                            anchorEl={projectRef?.current}
                            placement="bottom-start"

                            style={{ zIndex: 1000, width: 240 }}
                        >
                            <Box sx={{
                                p: 0, bgcolor: 'background.paper',
                                borderRadius: 2,
                                border: '1px solid #e0e0e0',
                                boxShadow: 1,

                            }}>
                                <Row style={{}}>
                                    <Col span={24} style={{ textAlign: 'center', padding: 8, borderRadius: '2px 2px 0px 0px', backgroundColor: 'white', height: 80 }}>

                                        <List sx={{ width: 240 }}>


                                            <ListItem  sx={{
                                                borderBottom: '1px solid #e0e0e0',
                                                height: 40,
                                            }}

                                            >
                                                <Typography variant="body1" fontWeight={400} fontSize={14} style={{ textAlign: 'left', }}>
                                                    Are you sure you want to delete this staff?
                                                </Typography>
                                            </ListItem>
                                            <ListItem button sx={{
                                                borderBottom: '1px solid #e0e0e0',
                                                height: 40,
                                            }}
                                                onClick={() => {
                                                    deleteStaff(staffKey)
                                                    setIsOpen(false)

                                                }}

                                            >
                                                <Typography variant="body1" fontWeight={400} fontSize={14} style={{ textAlign: 'left', }}>
                                                    <FontAwesomeIcon icon={faTrash} color="#404050" size="lg" style={{ marginRight: 10 }} />  Remove Staff
                                                </Typography>
                                            </ListItem>



                                        </List>


                                    </Col>

                                </Row>
                            </Box>
                        </Popper>
                    </ClickAwayListener>
                )
            }

            <Button
                ref={projectRef}
                size="small"
                style={{
                    textTransform: 'none',

                    color: 'red',
                    border: '1px solid red',
                    fontSize: 10
                }
                } variant="outlined"
                onClick={() => isOpen ? setIsOpen(false) : setIsOpen(true)}
            >
                Delete
            </Button>
        </div>

    );

};

export default DeleteStaff;
