import React, { KeyboardEventHandler, useRef, useState, useCallback, useId, FC } from 'react'
import { MenuItemProps, MenuItem, Menu, MenuProps, Typography, ListItemIcon, Badge } from '@mui/material'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCaretLeft } from '@fortawesome/free-solid-svg-icons';
type MoreMenuItemProps = {
    button?: true
    label: string 
    MenuProps?: Omit<MenuProps, 'open' | 'onClose' | 'anchorEl' | 'onKeyDown'>
} & Omit<MenuItemProps, 'onKeyDown' | 'onMouseEnter' | 'onMouseLeave'>

const MoreMenuItem: FC<MoreMenuItemProps> = ({ label, children, id, MenuProps, numberOfFilters,
    ...other }) => {
    const [isOpen, setIsOpen] = useState(false)
    const open = useCallback(() => setIsOpen(true), [])
    const close = useCallback(() => setIsOpen(false), [])

    const menuItemRef = useRef<HTMLLIElement>(null)
    const menuItemId = useId()
    const normMenuItemId = id ?? menuItemId

    const handleItemKeyDown: KeyboardEventHandler<HTMLLIElement> = (ev) => {
        if ((ev.key !== 'ArrowRight' && ev.key !== 'Enter') || ev.ctrlKey || ev.shiftKey || ev.altKey || ev.metaKey) return
        ev.preventDefault()
        ev.stopPropagation()
        setIsOpen(true)
    }

    const handleMenuKeyDown: KeyboardEventHandler<HTMLDivElement> = (ev) => {
        ev.stopPropagation()
        if ((ev.key !== 'ArrowLeft' && ev.key !== 'Escape') || ev.ctrlKey || ev.shiftKey || ev.altKey || ev.metaKey) return
        ev.preventDefault()
        setIsOpen(false)
    }

    const [isHovered, setIsHovered] = useState(false);

    return (
        <MenuItem
            {...other}
            onKeyDown={handleItemKeyDown}
            ref={menuItemRef}

            sx={[{
                justifyContent: 'flex-start',
                borderBottom: '1px solid #e0e0e0',
                paddingTop: 1,
                paddingBottom: 1,

            }, isOpen && { backgroundColor: 'action.hover' }]}
            onMouseEnter={() => {
                setIsHovered(true);  // Set isHovered to true when mouse enters
                open();
            }}
            onMouseLeave={() => {
                setIsHovered(false);  // Set isHovered to false when mouse leaves
                close();
            }}
            id={normMenuItemId}
        >


            <FontAwesomeIcon
                icon={faCaretLeft}
                style={{
                    marginRight: 8,
                    opacity: isHovered ? 1 : 0  // Set opacity based on isHovered state
                }}
            />


            <Typography variant="body1" fontSize={16} fontWeight={500}>
                {label}
            </Typography>
            {numberOfFilters > 0 && (
                <Badge badgeContent={numberOfFilters} color="error" sx={{ paddingLeft: 2 }}>
                </Badge>
            )}

            <Menu
                TransitionProps={{ onExited: () => menuItemRef.current?.focus() }} // If you need a submenu more than one level deep, it’s probably better to use context to check if it’s a top level component to focus only once
                disableRestoreFocus
                onKeyDown={handleMenuKeyDown}

                sx={{

                    pointerEvents: 'none',
                    '& .MuiList-root': {
                        pointerEvents: 'auto',
                        padding: 0,
                        marginTop: 1
                    },
                    '& .MuiMenu-paper': {
                        paddingTop: 0,
                        marginTop: -2

                    }

                }}
                MenuListProps={{
                    ...MenuProps?.MenuListProps,

                    'aria-labelledby': normMenuItemId
                }}
                anchorEl={menuItemRef.current}
                open={isOpen}
                onClose={close}
                anchorOrigin={MenuProps?.anchorOrigin ?? { vertical: 'top', horizontal: 'left' }}
                transformOrigin={
                    MenuProps?.transformOrigin ?? {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                }
            >

                {children}

            </Menu>
        </MenuItem >
    )
}

export default MoreMenuItem
