import DeleteForever from '@mui/icons-material/DeleteForever';
import { Button, Chip, CircularProgress, Divider, IconButton, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Col, Popconfirm, Row } from 'antd';
import { collection, collectionGroup, doc, getDocs, getFirestore, query, setDoc, updateDoc, where } from 'firebase/firestore';
import moment from 'moment';
import MUIDataTable from "mui-datatables";
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './hoverStyle.css';
import { fullMobile } from './util';
import axios, * as others from 'axios';
import DoneIcon from '@mui/icons-material/Done';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

export default class FieldLogsNew extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            type: 'account',
            accounts: [],
            loading: true,
            level: this.props.level,
            updateAccount: this.props.updateAccount,
            updateUserInfo: this.props.updateUserInfo,
            data: [],
            reports: this.props.reports
        };
    }





    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }

    }
    componentDidMount() {

        const { userInfo, screenWidth } = this.state;
        window.scrollTo(0, 0)

        this.queryAccounts()

    }

    queryData = async (accounts) => {

        const { userInfo, level, } = this.state;
        const db = getFirestore();

        /*   const queryitems = query(collectionGroup(db, 'flItems'),);
          const querySnap = await getDocs(queryitems);
          const items = [];
          querySnap.forEach(async (doc) => {
              items.push({
                  ...doc.data(),
              })
          });
  
  
  
          console.log(items)
  
          //check each item and see if item.item is a string or not
          items.forEach(async (item) => {
              if (typeof item.item === 'string') {
                  console.log('string')
              }
              else {
                  console.log('not string')
                  console.log(item.item.key)
                  //update item
                  const itemRef = doc(db, "clients", item.company, 'flItems', item.key);
                  await updateDoc(itemRef, {
                      item: item.item.key,
                  }).then(() => {
                      console.log('updated')
                  })
              }
          }) */





        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'fieldLogsNew'), where('archived', '==', false));
        const queryAccountData = query(collection(db, "clients", userInfo.currentCompany, 'fieldLogsNew'), where('archived', '==', false), where('accountKey', '==', userInfo.currentAccount));
        const data = [];
        const snap = await getDocs(level === 'account' ? queryAccountData : queryData);
        snap.forEach((doc) => {

            const account = accounts.filter(b => b.key === doc.data().accountKey)[0];


            data.push({
                ...doc.data(), accountLabel: account ? account.accountName : 'N/A',
                startDate: moment(doc.data().startDate, 'x').format('MM/DD/YYYY'),
                sortDateNumber: Number(moment(doc.data().startDate, 'x')),
                billed: doc.data().billed || false,
            })


        });

        console.log(data)

        data.sort((a, b) => Number(b.sortDateNumber) - Number(a.sortDateNumber));
        this.setState({ data, })
        setTimeout(() => {
            this.setState({ loading: false })
        }, (250));


    }

    queryAccounts = async () => {
        const { userInfo } = this.state;
        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'accounts'));
        const accounts = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            accounts.push({
                accountName: doc.data().accountName,
                key: doc.data().key,
            })


        });

        this.setState({ accounts, })
        this.queryData(accounts)


    }

    render() {

        const { account, type, data, userInfo, accounts, loading, screenWidth, level, reports } = this.state;






        const XTable = ({ columns, header, data, loading }) => {
            let navigate = useNavigate();
            const getMuiTheme = () =>
                createTheme({
                    components: {
                        MUIDataTable: {
                            styleOverrides: {
                                root: {
                                    padding: '8px',
                                    boxShadow: 'none'
                                }
                            }
                        },
                        MuiTableRow: {
                            styleOverrides: {
                                root: {
                                    zIndex: 100,
                                    cursor: 'pointer',
                                    fontSize: '10px',
                                    '&:nth-child(even)': {
                                        backgroundColor: '#eeeeee52'
                                    },
                                    "&:hover": {
                                        backgroundColor: "#9ec1c933"
                                    }


                                },


                            }
                        },
                        MuiTableCell: {
                            styleOverrides: {
                                root: {
                                    fontSize: '12px',
                                    padding: '2px'
                                }
                            }
                        }

                    }
                });




            const companyColumns = [

                {
                    name: 'lognumber',
                    label: '#',
                    options: {
                        filter: false,

                    }
                },
                {
                    name: 'accountLabel',
                    label: 'Account',
                    options: {
                        filter: true,

                    },
                },
                {
                    name: 'startDate',
                    label: 'Date',
                    options: {
                        filter: false,

                    }
                },

                {
                    name: 'status',
                    label: 'Status',
                    options: {
                        filter: false,
                        customBodyRender: this.renderValue = (a) => {
                            const status = a === 'built' ? 'Built' : 'Created';

                            if (status === 'Built') {
                                return (
                                    <Chip
                                        label={status} color="primary" variant="outlined"
                                        onClick={() => { }}
                                        onDelete={() => { }}
                                        deleteIcon={<DoneIcon />}
                                    />
                                )
                            }
                            else {
                                return (
                                    <Chip
                                        label={status} color="secondary" variant="outlined"
                                        onClick={() => { }}
                                        onDelete={() => { }}
                                        deleteIcon={<ModelTrainingIcon />}
                                    />
                                )
                            }
                        }
                    }
                },
                {
                    name: 'items',
                    label: 'Items',
                    options: {
                        filter: false,
                        customBodyRender: this.renderValue = (a) => {
                            if (a !== undefined) {
                                return (
                                    <Typography fontSize={12}>{a || ''}</Typography>

                                )
                            }
                            else {
                                return (
                                    <Typography>{''}</Typography>
                                )
                            }

                        }
                    }
                },


                {
                    name: 'key',
                    label: 'Edit',
                    options: {
                        disableExport: true,
                        filter: false,
                        viewColumns: false,
                        customBodyRender: this.renderValue = (a) => {
                            return (
                                <Button
                                    style={{
                                        textTransform: 'none', backgroundColor: '#404050',
                                        fontSize: 11
                                    }}
                                    variant="contained" color="primary" itemKey={a}
                                    onClick={async (e) => {
                                        e.stopPropagation();
                                        const db = getFirestore()
                                        const userRef = doc(db, "users", userInfo.id, 'currentItems', 'currentFieldLogNew');
                                        await setDoc(userRef, {
                                            key: a
                                        }).then(() => {
                                            navigate("/newFieldLogHome");
                                        })
                                    }}>Edit</Button>
                            )
                        }
                    },
                },
            ]





            const billedColumn = {
                name: 'billed',
                label: 'Billed',
                options: {
                    filter: true,
                    customBodyRender: this.renderValue = (a, b, c) => {
                        return (
                            <div style={{ textAlign: 'center' }}>
                                <FontAwesomeIcon icon={a ? faCheck : faTimes} style={{ color: a ? 'green' : 'red' }} />
                            </div>
                        )
                    }
                },
            }


            if (['Waterworks Aquatic Management2d876562-17f9-3d51-eaa4-ea3cc595f53c', 'e467d47-003-6463-6e6b-ee08215ca686'].includes(userInfo.currentCompany)) {
                companyColumns.splice(3, 0, billedColumn); // 3 because array index starts from 0
            }

            const newColumns = companyColumns;

            return (
                <ThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        title={''}
                        data={this.state.data}
                        columns={screenWidth === 'xs' ? [...newColumns, {
                            name: 'key',
                            label: "Delete",
                            options: {
                                filter: true,
                                sort: false,
                                empty: true,
                                customBodyRender: (value, b, updateValue) => {
                                    return (
                                        <div>
                                            <Popconfirm
                                                title="Are you sure to delete this log?"
                                                onConfirm={async () => {

                                                    //delete this item from data
                                                    const index = this.state.data.map((e, i) => e.key).indexOf(value);
                                                    const log = this.state.data[index];
                                                    const db = getFirestore()
                                                    const userRef = doc(db, "clients", userInfo.currentCompany, 'fieldLogsNew', log.key);
                                                    updateDoc(userRef, {
                                                        archived: true
                                                    }).then(() => {
                                                        const proxyUrl = "https://mycorslake.herokuapp.com/";
                                                        axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                                                            raw: `DELETE FROM lab_data WHERE logkey = '${log.key}';`
                                                        }).then(async (response) => {
                                                            // handle success

                                                            this.props.openSnack('success', 'Log deleted!')

                                                            this.state.data.splice(index, 1);
                                                            this.setState({ data: this.state.data })


                                                        }).catch((error) => {
                                                            console.log(error)
                                                        })




                                                    }).then(async () => {
                                                        //getdocs flitems logkey = log.key
                                                        const q = query(collection(db, "clients", userInfo.currentCompany, 'flItems'), where("logkey", "==", log.key));
                                                        const querySnapshot = await getDocs(q);
                                                        const dataList = [];
                                                        querySnapshot.forEach((doc) => {
                                                            //update doc to arvhived
                                                            const userRef = doc(db, "clients", userInfo.currentCompany, 'flItems', doc.key);
                                                            updateDoc(userRef, {
                                                                archived: true
                                                            }).then(() => {
                                                                console.log('deleted')
                                                            })
                                                        });
                                                    }).then(async () => {
                                                        //get photos from 'photos' where logkey = log.key and delete
                                                        const q = query(collection(db, "clients", userInfo.currentCompany, 'photos'), where("logkey", "==", log.key));
                                                        const querySnapshot = await getDocs(q);
                                                        const dataList = [];
                                                        querySnapshot.forEach((doc) => {
                                                            //update doc to arvhived
                                                            const userRef = doc(db, "clients", userInfo.currentCompany, 'photos', doc.key);
                                                            updateDoc(userRef, {
                                                                archived: true
                                                            }).then(() => {
                                                                console.log('deleted')
                                                            })
                                                        });
                                                    })






                                                }
                                                }>

                                                <IconButton size="small" aria-label="delete" color={'inherit'} >
                                                    <DeleteForever />
                                                </IconButton>
                                            </Popconfirm >



                                        </div>

                                    );
                                }
                            }
                        },] : newColumns}



                        options={{
                            rowsPerPage: 50,

                            rowsPerPageOptions: [10, 50, 100],
                            textLabels: {
                                body: {
                                    noMatch: <div style={{}}>{loading === true ? "Loading Data" : "No records found"} {loading === true && (<CircularProgress />)}</div>
                                }
                            },
                            selectableRows: screenWidth === 'xs' ? false : true,
                            responsive: screenWidth === 'xs' ? 'stacked' : 'standard',
                            onRowsDelete: (rows) => {


                                rows.data.sort((a, b) => b.index - a.index).forEach((item) => {

                                    const log = this.state.data[item.index];


                                    const db = getFirestore()
                                    const userRef = doc(db, "clients", userInfo.currentCompany, 'fieldLogsNew', log.key);
                                    updateDoc(userRef, {
                                        archived: true
                                    }).then(() => {
                                        const proxyUrl = "https://mycorslake.herokuapp.com/";
                                        axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                                            raw: `DELETE FROM node_data_new2 WHERE logkey = '${log.key}';`
                                        }).then(async (response) => {
                                            // handle success

                                            this.props.openSnack('success', 'Log deleted!')

                                            this.state.data.splice(item.index, 1);
                                            this.setState({ data: this.state.data })


                                        }).catch((error) => {
                                            console.log(error)
                                        })




                                    }).then(async () => {
                                        //getdocs flitems logkey = log.key
                                        const q = query(collection(db, "clients", userInfo.currentCompany, 'flItems'), where("logkey", "==", log.key));
                                        const querySnapshot = await getDocs(q);
                                        const dataList = [];
                                        querySnapshot.forEach((row) => {
                                            //update doc to arvhived
                                            const userRef = doc(db, "clients", userInfo.currentCompany, 'flItems', row.data().key);
                                            updateDoc(userRef, {
                                                archived: true
                                            }).then(() => {

                                            })
                                        });
                                    })


                                })




                            },
                            onRowClick: async (a, b) => {


                                const db = getFirestore()
                                const userRef = doc(db, "users", userInfo.id, 'currentItems', 'currentFieldLogNew');
                                const key = a[a.length - 1].props.itemKey

                                const item = this.state.data.find((item) => item.key === key)



                                await setDoc(userRef, {
                                    key: key
                                }).then(() => {
                                    if (level === 'company') {
                                        this.props.updateAccount(userInfo.currentCompany, item.accountKey || item.account)
                                        this.props.updateUserInfo('currentAccount', item.accountKey || item.account)
                                        const accountRef = doc(db, "users", userInfo.id,);
                                        updateDoc(accountRef, {
                                            currentAccount: item.accountKey || item.account
                                        }).then(() => {
                                            navigate("/newFieldLogHome-company");
                                        })



                                    }
                                    else {
                                        navigate("/newFieldLogHome");
                                    }

                                })

                            }
                        }}

                    />
                </ThemeProvider>
            );
        }

        const CreateItemButton = () => {
            let navigate = useNavigate();
            const db = getFirestore();
            return (
                <Button style={{

                    textTransform: 'none',
                    padding: 12,
                    fontFamily: 'Roboto, sans-serif',
                    fontSize: '14px',
                    backgroundColor: '#404050',
                }}
                    variant="contained" color="primary" onClick={async () => {
                        const userRef = doc(db, "users", userInfo.id, 'currentItems', 'currentFieldLogNew');
                        await setDoc(userRef, {
                            key: 'none'
                        }).then(() => {
                            navigate("/newFieldLogHome");
                        })
                    }}>+ Create Log</Button>
            )
        }

        return (
            <Row style={{}}>
                <Col span={23}>
                    <Row align="middle">
                        <Col {...fullMobile(20, 16)} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>
                            <Typography variant="h4">Field Logs</Typography>
                        </Col>

                        <Col {...fullMobile(4, 8)} style={{ paddingTop: 30, paddingBottom: 15, textAlign: 'right' }}>
                            {level === 'account' && (<CreateItemButton />)}
                        </Col>
                        <Col span={24}>
                            <Divider />
                        </Col>
                        <Col span={24} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 30 }}>
                            <XTable loading={loading} />

                        </Col>
                    </Row>
                </Col>

            </Row >
        );
    }
}







