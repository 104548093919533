import React, { useState } from "react";
import { GoogleMap, Marker, InfoWindow, OverlayView } from "@react-google-maps/api";
import { Typography } from "@mui/material";
import MapMarkerPopup from "./mapMarkerPopup";

const containerStyle = {
    width: "100%",
    height: "400px",
};

const getPixelPositionOffset = (width, height) => ({
    x: -(width / 2),
    y: -(height / 2) + 7, // Offset by 5 pixels downward
});


const Map = ({ currentStream, prefKey }) => {
    const [showInfoWindow, setShowInfoWindow] = useState(false);


    const displayName = currentStream?.name?.substr(0, 12) + (currentStream?.name?.length > 12 ? '...' : '');

    const center = {
        lat: Number(currentStream?.latitude || currentStream?.lat || 0),
        lng: Number(currentStream?.longitude || currentStream?.lon || 0),
    };

    const toggleInfoWindow = () => {
        setShowInfoWindow(!showInfoWindow);
    };

    const formatWindDirection = (direction) => {
        if (direction >= 0 && direction <= 22.5) return 'N';
        if (direction > 22.5 && direction <= 67.5) return 'NE';
        if (direction > 67.5 && direction <= 112.5) return 'E';
        if (direction > 112.5 && direction <= 157.5) return 'SE';
        if (direction > 157.5 && direction <= 202.5) return 'S';
        if (direction > 202.5 && direction <= 247.5) return 'SW';
        if (direction > 247.5 && direction <= 292.5) return 'W';
        if (direction > 292.5 && direction <= 337.5) return 'NW';
        if (direction > 337.5 && direction <= 360) return 'N';


        return 'Unknown'; // Default case
    };

    const windDirection = formatWindDirection(currentStream?.lastData?.windDirection);
    console.log(currentStream)


    return (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={17}
            mapTypeId="satellite"
            mapTypeControl={false}
            onClick={() => {
                setShowInfoWindow(false);
            }}
        >
            <Marker position={center} onClick={toggleInfoWindow}>
                <OverlayView
                    position={center}
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    getPixelPositionOffset={getPixelPositionOffset}
                >
                    <div style={{ color: 'white', fontWeight: 600, fontSize: 14, padding: '2px 5px', borderRadius: '3px' }}>
                        {displayName}
                    </div>
                </OverlayView>
                {currentStream?.lastData && (<MapMarkerPopup
                    currentStream={currentStream}
                    prefKey={prefKey}
                    showInfoWindow={showInfoWindow}
                    toggleInfoWindow={toggleInfoWindow}
                    center={center}
                    displayName={displayName}
                    windDirection={windDirection}
                />
                )}
            </Marker>
        </GoogleMap>
    );
};

export default Map;
