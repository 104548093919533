import React, { useState, useEffect } from 'react';
import { Box, ListItemButton, ListItemText, Collapse, Grid, ButtonGroup, Button, Typography, Tooltip } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsUpDownLeftRight, faCircleCheck, faComputerMouse, faMagnifyingGlassPlus } from '@fortawesome/free-solid-svg-icons';
import { faCircle, faMessage, faObjectUngroup, faTrashCan } from '@fortawesome/free-regular-svg-icons';

const TooltipTool = ({ toolName, toolLabel,  tooltip, setTooltip }) => {
    const [open, setOpen] = useState(true);



    const handleClick = () => {
        setOpen(!open);
    };

    const handleButtonClick = (value) => {
        setTooltip(value); // Update the state to the selected button's value
    };





    return (
        <>
            <ListItemButton onClick={handleClick} sx={{ color: 'white', '&:hover': { bgcolor: 'transparent' }, mx: 0, pl: 1 }}>
                <ListItemText primary={toolLabel} sx={{ color: 'white', fontWeight: 'bold', my: 0, py: 0, '& .MuiListItemText-root': { m: 0, p: 0 }, '& .MuiListItemText-primary': { fontWeight: 800, fontSize: 20 } }} />
                {open ? <ExpandLess color="inherit" /> : <ExpandMore color="inherit" />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Box component="div" sx={{ color: 'white', px: 1, py: 1 }}>
                    <Grid container
                        /* horizontally align items middle */
                        alignItems="center"
                        spacing={1}>
                        <Grid item xs={9}>
                            <Typography variant="body1" sx={{ color: 'white' }}>Visible
                                {tooltip === true ?
                                    <FontAwesomeIcon icon={faCircleCheck} size="lg" style={{ color: 'white', marginLeft: 5 }} /> :
                                    <FontAwesomeIcon icon={faCircle} size="lg" style={{ color: 'white', marginLeft: 5 }} />
                                }

                            </Typography>
                        </Grid>
                        <Grid item xs={3} style={{ textAlign: 'right' }}>
                            <ButtonGroup variant="contained" aria-label="Basic button group" sx={{

                            }}>
                                <Tooltip title="Change tooltip visibility" placement="top">
                                    <Button
                                        style={{ backgroundColor: tooltip === true ? 'black' : '#424242', border: '1px solid #424242' }}
                                        onClick={() => handleButtonClick(!tooltip)} >
                                        <FontAwesomeIcon icon={faMessage} size="xl" style={{ color: 'white' }} />
                                    </Button>
                                </Tooltip>


                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </Box>
            </Collapse>
        </>
    );
};

export default TooltipTool;
