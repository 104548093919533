import React, { useState, useEffect } from 'react';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, } from 'firebase/firestore';
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import moment from 'moment';
import { queryEquipment, queryItems, queryParameters, queryStations, queryStocks } from '../../../../firebase/config';
import * as htmlToImage from 'html-to-image';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import ProbeParameters from '../../../../extra/probeParameters.json';
import Units from '../../../../extra/units.json';
var convert = require('convert-units')



function getDataUri(url) {
    return new Promise(resolve => {
        var image = new Image();
        image.setAttribute('crossOrigin', 'anonymous'); //getting images from external domain

        image.onload = function () {
            var canvas = document.createElement('canvas');
            canvas.width = this.naturalWidth;
            canvas.height = this.naturalHeight;

            //next three lines for white background in case png has a transparent background
            var ctx = canvas.getContext('2d');
            ctx.fillStyle = '#fff';  /// set white fill style
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            canvas.getContext('2d').drawImage(this, 0, 0);

            resolve(canvas.toDataURL('image/jpeg'));
        };

        image.src = url;
    })
}



export const MakePDF = async (log, userInfo, openSnack, type, company) => {

    const pdf = new jsPDF({

    });
    const equipmentList = await queryEquipment(userInfo)



    const PPArray = Object.values(ProbeParameters).map((p, i) => {
        return ({
            label: p,
            name: p,
            key: Object.keys(ProbeParameters)[i]
        })
    });

    const unitList = Object.values(Units).map((u, i) => {
        return ({ label: u, name: u, key: u, unitid: Object.keys(Units)[i] })
    })


    const items = await queryItems(userInfo)
    const stocks = await queryStocks(userInfo)
    const stations = await queryStations(userInfo, ['grab', 'both', 'sensor'])
    const parameters = await queryParameters(userInfo)
    const weatherIcon = await GetWeatherIcon(log, type)
    const getLogo = await getLogoImage(userInfo);
    const wqData = await GetWQData(log, pdf, weatherIcon);
    const multiDayList = await GetMultiDayList(log, pdf, weatherIcon);
    const lineData = await GetLineItemData(log, pdf, weatherIcon);

    const photos = await GetPhotos(log, pdf, weatherIcon);
    const header = await MakeHeader(pdf, log, weatherIcon, wqData, lineData, photos, items, stocks, openSnack, stations, parameters, type, userInfo,
        company, getLogo, equipmentList, PPArray, unitList, multiDayList);

    if (type === 'email') {
        return header.output('blob');
    }
    else {
        return header
    }
};

export const getLogoImage = async (userInfo) => {
    const db = getFirestore();
    const docRef = doc(db, "clients", userInfo.currentCompany);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        if (docSnap.data().logo !== undefined) {

            const getLogo = await getDownloadURL(ref(getStorage(), `${userInfo.currentCompany}/logo/`));
            //if not found getlogo will be undefined
            var photourl = await getDataUri(getLogo);
            return photourl;
        }
        else {
            return null;
        }
    } else {
        return null;
    }

}

export const GetWeatherIcon = async (log, type) => {
    const dataUrl = log?.weather?.weatherImage


    if (type === 'email') {
        return dataUrl;
    }
    else {
        if ([undefined, 'none'].includes(dataUrl)) {
            const node = document.getElementById('my-node');
            const dataUrl = await htmlToImage.toPng(node);
            return dataUrl;
        }

        return dataUrl;
    }


}


export const GetWQData = async (log, pdf, weatherIcon) => {

    //get waterQualityData from local storage
    const waterQualityData = JSON.parse(localStorage.getItem('waterQualityData'));

    if (waterQualityData !== null && waterQualityData !== undefined) {
        const newData = waterQualityData.filter((row) => {
            return row.parameterid !== undefined && row.locationid !== undefined && row.value !== undefined && row.parameterid !== '' && row.locationid !== '' && row.value !== ''
        });
        return newData;
    }
    else {

        return [];
    }


}
export const GetMultiDayList = async (log, pdf, weatherIcon) => {

    //get waterQualityData from local storage
    const multiDayList = JSON.parse(localStorage.getItem('multiDayList'));
    console.log(multiDayList)


    if (multiDayList !== null && multiDayList !== undefined) {
        return multiDayList;
    }
    else {

        return [];
    }


}

export const GetLineItemData = async (log, pdf, weatherIcon) => {

    //get lineItemData from local storage
    const lineItemData = JSON.parse(localStorage.getItem('lineItemData'));


    if (lineItemData !== null) {
        const newData = lineItemData.filter((row) => {
            return row.item !== undefined
        });
        return newData;
    }
    else {
        return lineItemData;
    }


}

export const GetPhotos = async (log, pdf, weatherIcon) => {

    //get photos from local storage
    const photos = JSON.parse(localStorage.getItem('logPhotos'));


    const newData = photos.map(async (photo) => {
        var photourl = await getDataUri(photo.url);

        return ({ ...photo, url: photourl })

    });


    //resolve all promises in newData
    const resolvedData = await Promise.all(newData);

    //get unique resolvedData by key    
    const unique = [...new Map(resolvedData.map(item => [item['key'], item])).values()];


    return unique;

}









export const MakeHeader = async (pdf, log, weatherIcon, wqData, lineData, photos, items, stocks, openSnack, stations, parameters, type, userInfo, company, logo,
    equipmentList, PPArray, unitList, multiDayList) => {
    function getFirstLetters(str) {
        if (str) {
            const firstLetters = str
                .split(' ')
                .map(word => word[0])
                .join('');

            return firstLetters;
        }
        else return '';
    }

    try {

        //create image from weatherIcon
        const weatherImage = new Image();
        weatherImage.src = weatherIcon;



        const LogID = getFirstLetters(log.companyName || company.companyName) + "-" +
            `${log?.account !== undefined ? getFirstLetters(log?.account === undefined ? '' : `${log?.account?.accountName}`) : ''}`
            + '-' + moment(log?.startDate, 'x').format('MM-DD-YYYY') + "-" + "LOG";


        const startY = 1;
        //add autotable to pdf with "Field Log" in first cell with font size 18


        const topArray = [
            [
                {
                    dataKey: 'logo', content: '',
                    colSpan: 3, rowSpan: 1, styles: {}
                },
                { dataKey: 'header', content: '', colSpan: 3, rowSpan: 1, styles: { halign: 'center', } },

            ],

            [
                {
                    dataKey: 'line', content: '',
                    colSpan: 6, rowSpan: 1, styles: { lineWidth: 0, lineColor: 'rgb(148, 186, 195)' }
                },


            ],
        ]

        const generalArray = [
            [
                {
                    dataKey: 'weather', content: '',
                    colSpan: 3, rowSpan: 1, styles: {}
                },
                { dataKey: 'generalInfo', content: '', colSpan: 3, rowSpan: 1, styles: { halign: 'center', } },

            ],
        ]

        const lineArray = [
            [
                {
                    dataKey: 'line', content: '',
                    colSpan: 6, rowSpan: 1, styles: { lineWidth: 0, lineColor: 'rgb(148, 186, 195)' }
                },


            ],
        ]

        const multiDayArray = [
            [
                {
                    dataKey: 'wq', content: 'Multi-Day',
                    colSpan: 6, rowSpan: 1, styles: { textColor: 'black', fontStyle: 'bold', fontSize: 18, cellPadding: { top: 3, right: 0, bottom: 1, left: 0 }, }
                },

            ],
            [
                { dataKey: 'multiDayTable', content: '', colSpan: 6, rowSpan: 1, styles: { halign: 'center', } },

            ],
        ]

        const wqArray = [
            [
                {
                    dataKey: 'wq', content: 'Water Quality',
                    colSpan: 6, rowSpan: 1, styles: { textColor: 'black', fontStyle: 'bold', fontSize: 18, cellPadding: { top: 3, right: 0, bottom: 1, left: 0 }, }
                },

            ],
            [
                { dataKey: 'wqtable', content: '', colSpan: 6, rowSpan: 1, styles: { halign: 'center', } },

            ],
        ]


        const showWQ = wqData.length === 0 || [null, undefined].includes(wqData) ? false : true;
        const showMulti = multiDayList.length === 0 || [null, undefined].includes(multiDayList) ? false : true;

        // Utilizing an array to push only the necessary elements
        let body = [...topArray, ...generalArray, ...lineArray];

        if (multiDayList?.length) {
            body = [...body, ...multiDayArray, ...lineArray];
        }

        if (wqData?.length) {
            body = [...body, ...wqArray];
        }






        pdf.autoTable({
            pageBreak: 'auto',
            rowPageBreak: 'auto',
            columns: ['', '', '', '', '', '',],
            body: body,
            didParseCell: function (data) {
                if (data?.cell?.raw?.dataKey === 'header') {
                    data.row.height = 25;
                }
                if (data?.cell?.raw?.dataKey === 'generalInfo') {
                    const additionalStaffLabels = log.additionalStaff !== undefined && log.additionalStaff.length > 0 ?
                        log.additionalStaff.map((staff) => {
                            return staff.label
                        }) : ["No additional staff"]
                    //count the number of characters in additionalStaffLabels join
                    const additionalStaffLabelsLength = additionalStaffLabels.join('').length;
                    //divide this by 33
                    const additionalStaffLabelsHeight = Math.ceil(additionalStaffLabelsLength / 33);
                    //multiply by 5
                    const additionalStaffLabelsHeightMultiplier = additionalStaffLabelsHeight * 5;
                    data.row.height = 40 + additionalStaffLabelsHeightMultiplier;
                }

                if (data?.cell?.raw?.dataKey === 'wqtable') {
                    //row height should be 20 + (number of rows in wqData * 10), if wqdata = null then row height should be 20
                    const wqDataHeight = wqData !== null && wqData !== undefined ? 15 + (wqData.length * 10) : 15;
                    data.row.height = wqDataHeight;
                }
                if (data?.cell?.raw?.dataKey === 'multiDayTable') {
                    //row height should be 20 + (number of rows in wqData * 10), if wqdata = null then row height should be 20
                    const wqDataHeight = multiDayList !== null && multiDayList !== undefined ? 15 + (multiDayList.length * 8) : 15;
                    data.row.height = wqDataHeight;
                }


            },
            didDrawCell: function (data) {
                if (data?.cell?.raw?.dataKey === 'id') {
                }
                if (data?.cell?.raw?.dataKey === 'line') {
                    //add a line to the bottom of the cell with color lineColor: 'rgb(148, 186, 195)' and line width to 3
                    pdf.setLineWidth(1);
                    pdf.setDrawColor(148, 186, 195);
                    pdf.line(data.cell.x, data.cell.y + data.cell.height, data.cell.x + data.cell.width, data.cell.y + data.cell.height);

                }
                if (data?.cell?.raw?.dataKey === 'logo') {
                    //add logo to pdf if logo is not null or not undefined
                    if (logo !== null && logo !== undefined) {
                        //get the ratio of the logo width to the logo height and then identify it's orientation (portrait or landscape) and set the width and height of the logo accordingly
                        const logoRatio = pdf.getImageProperties(logo).width / pdf.getImageProperties(logo).height;
                        const logoOrientation = logoRatio > 1 ? 'landscape' : 'portrait';
                        const width = logoOrientation === 'landscape' ? 65 : 20 * logoRatio;
                        const height = logoOrientation === 'landscape' ? 65 / logoRatio : 20;





                        pdf.addImage(logo, 'PNG', data.cell.x, data.cell.y, width, height);





                    }

                }






                if (data?.cell?.raw?.dataKey === 'header') {
                    pdf.autoTable({
                        showHead: 'never',
                        theme: 'plain',
                        startY: data.cell.y + 1,
                        margin: { left: data.cell.x },
                        //width of table is the width of the cell
                        tableWidth: data.cell.width,
                        styles: {
                            padding: 0,
                            overflow: 'linebreak',
                            halign: 'right',
                        },
                        columns: [
                            { dataKey: 'id', header: 'ID', styles: {} },

                        ],

                        body: [
                            [{ dataKey: 'name', content: `Field Log`, colSpan: 1, rowSpan: 1, styles: { cellPadding: 0, textColor: 'black', fontSize: 18, } }],
                            [{ dataKey: 'name', content: `${log?.companyName || company.companyName}`, colSpan: 1, rowSpan: 1, styles: { cellPadding: 0, textColor: 'black', fontSize: 18, } }],
                            [{ dataKey: 'name', content: `${log?.account?.accountName}`, colSpan: 1, rowSpan: 1, styles: { cellPadding: 0, } }],
                            [{ dataKey: 'name', content: `${log?.account?.address}`, colSpan: 1, rowSpan: 1, styles: { cellPadding: 0, } }],

                        ],
                    })
                }
                if (data?.cell?.raw?.dataKey === 'weather') {
                    const temperature = log?.weather?.temp > 0 && log?.weather?.temp < 150 ? log?.weather?.temp : convert(log?.weather?.temp).from('K').to('F');
                    //capitalize log?.weather.description
                    const description = log?.weather.description.charAt(0).toUpperCase() + log?.weather.description.slice(1);

                    pdf.autoTable({
                        showHead: 'never',
                        theme: 'plain',
                        startY: data.cell.y + 1,
                        margin: { left: data.cell.x },
                        //width of table is the width of the cell
                        tableWidth: data.cell.width,
                        styles: {
                            padding: 0,
                            overflow: 'linebreak',
                            halign: 'left',
                        },
                        columns: [
                            '', '', '', '', ''

                        ],

                        body: [
                            [{ dataKey: 'name', content: `General Info`, colSpan: 5, rowSpan: 1, styles: { cellPadding: { top: 3, right: 0, bottom: 2, left: 0 }, textColor: 'black', fontSize: 18, } }],

                            [{ dataKey: 'name', content: `${temperature.toFixed(0)} F`, colSpan: 3, rowSpan: 1, styles: { fontStyle: 'bold', cellPadding: { top: 4, right: 0, bottom: 4, left: 0 }, fontSize: 18, textColor: 'black' } },
                            { dataKey: 'weatherImage', content: ``, colSpan: 2, rowSpan: 3, styles: { cellPadding: 0, } }],

                            [{ dataKey: 'name', content: `${description}`, colSpan: 3, rowSpan: 1, styles: { cellPadding: { top: 2, right: 0, bottom: 2, left: 0 }, fontSize: 13, } },
                            ],

                            [{
                                dataKey: 'name', content: `${convert(log?.weather.wind_speed).from('m/s').to('m/h').toFixed(0)} mph ${log?.weather.windDirection || ''}`,
                                colSpan: 3, rowSpan: 1, styles: { cellPadding: { top: 2, right: 0, bottom: 2, left: 0 }, fontSize: 13, }
                            },
                            ],


                        ],
                        didDrawCell: async (data) => {
                            if (data?.cell?.raw?.dataKey === 'weatherImage') {
                                const ratio = pdf.getImageProperties(weatherImage).height / pdf.getImageProperties(weatherImage).width;
                                const width = 30;
                                const height = width * ratio;
                                pdf.addImage(weatherImage, 'PNG', data.cell.x, data.cell.y + 6, 20, 20);
                            }
                        }
                    })
                }
                if (data?.cell?.raw?.dataKey === 'generalInfo') {
                    const additionalStaffLabels = log.additionalStaff !== undefined && log.additionalStaff.length > 0 ?
                        log.additionalStaff.map((staff) => {
                            return staff.label
                        }) : ["No additional staff"]



                    pdf.autoTable({
                        showHead: 'never',
                        theme: 'plain',
                        startY: data.cell.y + 1,
                        margin: { left: data.cell.x },
                        //width of table is the width of the cell
                        tableWidth: data.cell.width,
                        styles: {
                            padding: 0,
                            overflow: 'linebreak',
                            halign: 'right',
                            fontSize: 12,
                            textColor: 'black',
                            cellPadding: 1
                        },
                        columns: [
                            { dataKey: 'id', header: 'ID', styles: { lineWidth: 0.05 } },

                        ],

                        body: [
                            [{ dataKey: 'name', content: `Log ID:  ${LogID}`, colSpan: 1, rowSpan: 1, styles: {} }],
                            [{ dataKey: 'name', content: `Log Number:  ${log?.lognumber}`, colSpan: 1, rowSpan: 1, styles: {} }],
                            [{ dataKey: 'name', content: `Start Date/Time: ${moment(log?.startDate, 'x').format('MM-DD-YYYY hh:mm a')}`, colSpan: 1, rowSpan: 1, styles: {} }],
                            [{ dataKey: 'name', content: `End Date/Time: ${moment(log?.endDate, 'x').format('MM-DD-YYYY hh:mm a')}`, colSpan: 1, rowSpan: 1, styles: {} }],
                            [{ dataKey: 'name', content: `Lead Staff: ${log?.leadStaff?.label}`, colSpan: 1, rowSpan: 1, styles: {} }],
                            [{ dataKey: 'name', content: `Additional Staff: ${additionalStaffLabels.join()}`, colSpan: 1, rowSpan: 1, styles: {} }],


                        ],

                    })
                }

                if (data?.cell?.raw?.dataKey === 'wqtable') {
                    const wqTable = [null, undefined].includes(wqData) ? [] : wqData.map((row) => {

                        const stationsIndex = stations.map((station) => station.id.toString()).indexOf(row.nodeid.toString())

                        const station = stations.find((station) => station.id === row.nodeid)

                        row.locationLabel = station.name || ''

                        row.parameterLabel = [...PPArray, ...parameters].find((parameter) => parameter.key === row.parameterid)?.name || ''
                        return [moment(row.time, 'YYYY-MM-DD HH:mm').format('MM-DD-YYYY h:mm a'), row.parameterLabel, row.locationLabel, row.value, row.unitid || '']
                    })



                    if (wqData.length === 0 || [null, undefined].includes(wqData)) {
                        //center text
                        pdf.setFontSize(12)
                        pdf.setTextColor(0, 0, 0)

                        pdf.text('No Water Quality Data', data.cell.x + data.cell.width / 2, data.cell.y + 10, { align: 'center' })
                    }
                    else {
                        pdf.autoTable({
                            head: [["Date", "Parameter", "Station", "Value", "Units"]],
                            body: [

                                ...wqTable


                            ],

                            headStyles: { fontStyle: 'bold' },
                            startY: data.cell.y + 1,
                            margin: 1,
                            theme: "striped",
                            margin: { left: data.cell.x },
                            //width of table is the width of the cell
                            tableWidth: data.cell.width,

                        })
                    }
                }
                if (data?.cell?.raw?.dataKey === 'multiDayTable') {
                    console.log(wqData)
                    console.log(multiDayList)
                    const wqTable = [null, undefined].includes(multiDayList) ? [] : multiDayList.map((row) => {

                        return [moment(row.time, 'x').format('MM-DD-YYYY h:mm a'), row.hours]
                    })

                    console.log(wqTable)

                    if (multiDayList.length === 0 || [null, undefined].includes(multiDayList)) {
                        //center text
                        pdf.setFontSize(12)
                        pdf.setTextColor(0, 0, 0)

                        pdf.text('No Water Multi Day Data', data.cell.x + data.cell.width / 2, data.cell.y + 10, { align: 'center' })
                    }
                    else {
                        const totalHours = multiDayList.reduce((sum, row) => sum + parseFloat(row.hours || 0), 0);
                        pdf.autoTable({
                            head: [["Date", `Hours (total: ${totalHours})`,]],
                            body: [

                                ...wqTable


                            ],

                            headStyles: { fontStyle: 'bold' },
                            startY: data.cell.y + 1,
                            margin: 1,
                            theme: "striped",
                            margin: { left: data.cell.x },
                            //width of table is the width of the cell
                            tableWidth: data.cell.width,

                        })
                    }
                }




            },






            startY: startY,
            theme: 'plain',

            styles: {
                fontSize: 20,
                halign: 'left',
                valign: 'top',
                padding: 0,
                overflow: 'linebreak',
                columnWidth: 'wrap',
            },
            columnStyles: {

            },
            headStyles: {
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
            },
        });

        //get the bottom of the table and add 10px to it
        const bottom = pdf.lastAutoTable.finalY;

        const lineTable = lineData === null ? [] : lineData.map((row, i) => {




            const item = items.find((item) => item.key === row.item)
            const label = items.find((item) => item.key === row.item)?.label;
            const mapItems = row.mapItems !== undefined && row.mapItems.length > 0 ? row.mapItems : [];

            const mapItemsContent = mapItems.map((mapItem) => {
                if (mapItem.type === 'polygon') {
                    return (`Polygon: ${mapItem?.label || 'No Polygon'} Area: ${mapItem?.area.toFixed(2) || 'No Area'} ac`
                    )
                }
                if (mapItem.type === 'marker') {
                    return (`Marker: ${mapItem?.label || 'No Marker'}`
                    )
                }
            })

            const mapItemsContentString = mapItemsContent.join();


            const row1 = row.showMap ?
                [{ dataKey: 'name', content: `${label}`, colSpan: 3, rowSpan: 1, styles: { fontStyle: 'bold', fontSize: 14, cellPadding: { top: 3, right: 0, bottom: 2, left: 0 }, } },
                { dataKey: row.staticUrl, content: "map", rowSpan: 3, colSpan: 3, styles: { halign: 'center', cellPadding: { top: 0, right: 0, bottom: 0, left: 0 }, } }] :

                [{ dataKey: 'name', content: `${label}`, colSpan: 6, rowSpan: 1, styles: { fontStyle: 'bold', fontSize: 14, cellPadding: { top: 3, right: 0, bottom: 2, left: 0 }, } }]

            const row2 = row.showMap ?
                [{
                    dataKey: 'notes',
                    content:
                        `Notes: ${row?.description || ''}  ${mapItemsContentString}`,

                    colSpan: 3, rowSpan: 1, styles: { cellPadding: { top: 0, right: 0, bottom: 0, left: 0 }, }
                },



                ]
                : [{ dataKey: 'name', content: `Notes: ${row?.description || ''}`, colSpan: 6, rowSpan: 1, styles: { cellPadding: { top: 2, right: 0, bottom: 0, left: 0 }, } }]

            const row3 = row.showMap ?
                [{ dataKey: 'name', content: `Amount: ${row?.quantity || 'No Amount'} ${row?.units?.label || 'No Units'}   Concentration: ${row?.concentration} ${row?.concentrationUnits?.label}`, colSpan: 3, rowSpan: 1, styles: {} }] :
                [{ dataKey: 'name', content: `Amount: ${row?.quantity || 'No Amount'} ${row?.units?.label || 'No Units'}   Concentration: ${row?.concentration} ${row?.concentrationUnits?.label}`, colSpan: 6, rowSpan: 1, styles: {} }]

            const row4 = row.showMap ?
                [{ dataKey: 'name', content: `Application Method: ${row?.applicationMethod?.label || 'No Application Method'}   Percent Covered: ${row?.percentApplied || 'No record'}`, colSpan: 3, rowSpan: 1, styles: {} }] :
                [{ dataKey: 'name', content: `Application Method: ${row?.applicationMethod?.label || 'No Application Method'}   Percent Covered: ${row?.percentApplied || 'No record'}`, colSpan: 6, rowSpan: 1, styles: {} }]

            const row5 = row.showMap ?
                [{ dataKey: 'name', content: `Stock: ${row?.supplyStock?.label || 'No stock record'}`, colSpan: 3, rowSpan: 1, styles: {} }] :
                [{ dataKey: 'name', content: `Stock: ${row?.supplyStock?.label || 'No stock record'}`, colSpan: 6, rowSpan: 1, styles: {} }]

            const row6 = [
                {
                    dataKey: row.showMap && item.psType === 'services' ? 'lineMapService' : row.showMap && item.psType === 'products' ? 'lineMapProduct' : 'line', content: '',
                    colSpan: 6, rowSpan: 1, styles: { lineWidth: 0, lineColor: 'rgb(148, 186, 195)', cellPadding: { top: 0, right: 0, bottom: 0, left: 0 }, }
                },
            ];


            const row7 = [

            ]


            const inspectionItemsList = row.inspectionItems === undefined ? [] : row.inspectionItems;

            const inspectionItems = [];




            inspectionItemsList.forEach((item) => {
                console.log(item)

                const station = stations.find((n) => n.id === item?.nodeid);
                const parameter = [...PPArray, ...parameters].find((p) => p.key === item?.parameterid);

                let content = '';
                let secondaryContent = '';

                switch (item?.type) {
                    case 'select':
                        const selected = item.dropdownOptions?.find((o) => o.id === item?.selected) || 'none';
                        content = `${selected.label || ''}`;
                        break;
                    case 'selectMulti':
                        const selectedOptions = item?.selectedMulti?.map(id => item.dropdownOptions.find(option => option.id === id)?.label || '');
                        content = selectedOptions.join(', ');
                        break;
                    case 'observation':
                        content = `${item?.observation || ''}`;
                        break;
                    case 'checkbox':
                        content = `${item?.checked === true ? 'check' : 'no-check'}`;
                        break;
                    case 'data':
                        content = `${`Node: ${station?.label} and Parameter: ${parameter?.label}`} | ${item?.value || ''} ${unitList.find((u) => u.unitid === item?.unitid)?.label || ''}`;

                        break;
                    case 'section':
                        content = `section`;

                        break;
                    case 'equipment':
                        const equipment = equipmentList.find((e) => e.key === item?.equipment);
                        content = `${equipment?.label} | ${item?.equipmentObservation || ''}`;

                        break;
                }

                if (content) {
                    console.log(item?.label)
                    if (content === 'section') {
                        inspectionItems.push([
                            {
                                dataKey: 'section', content: `${item?.label || 'No Inspection Item'}:`, colSpan: 6, rowSpan: 1,
                                styles: { cellPadding: { top: 4, right: 0, bottom: 4, left: 2 }, fontStyle: 'bold', fontSize: 16 }
                            },


                        ]);

                    } else {
                        inspectionItems.push([
                            {
                                dataKey: 'border', content: `${item?.label || 'No Inspection Item'}:`, colSpan: 2, rowSpan: 1,
                                styles: { cellPadding: { top: 1, right: 0, bottom: 1, left: 2 }, fontStyle: 'bold' }
                            },
                            {
                                dataKey: 'border', content: `${content || ''}`, colSpan: 4, rowSpan: 1,
                                styles: { cellPadding: { top: 1, right: 0, bottom: 1, left: 2 }, }
                            },

                        ]);
                    }

                }


            });






            const lineTable = item.psType === 'services' ? [row1, row2, row6, row7] :
                item.psType === 'inspection' ? [row1, ...inspectionItems, row2] :

                    [row1, row2, row3, row4, row5, row6, row7];
            return lineTable
        })



        const flatLineTable = lineTable.flat(1)

        //add text of Product/Services



        pdf.autoTable({
            pageBreak: 'auto',
            rowPageBreak: 'avoid',
            columns: ['', '', '', '', '', '',],
            body: [
                [
                    {
                        dataKey: 'line', content: '',
                        colSpan: 6, rowSpan: 1, styles: { lineWidth: 0, lineColor: 'rgb(148, 186, 195)', cellPadding: { top: 0, right: 0, bottom: 0, left: 0 }, }
                    },

                ],
                [
                    {
                        dataKey: 'wq', content: 'Product/Services',
                        colSpan: 6, rowSpan: 1, styles: { textColor: 'black', fontStyle: 'bold', fontSize: 18, cellPadding: { top: 3, right: 0, bottom: 1, left: 0 }, }
                    },

                ],
                ...flatLineTable,

            ],
            didParseCell: (data) => {


                if (data?.cell?.raw?.dataKey === 'lineMapService') {
                    data.row.height = 50;
                }
                if (data?.cell?.raw?.dataKey === 'lineMapProduct') {
                    data.row.height = 30;
                }
                if (data?.cell?.raw?.dataKey === 'line') {
                    data.row.height = 15;
                }



            },

            didDrawCell: function (data) {
                if (['lineMapService', 'lineMapProduct', 'line'].includes(data?.cell?.raw?.dataKey)) {
                    //add a line to the bottom of the cell with color lineColor: 'rgb(148, 186, 195)' and line width to 3
                    pdf.setLineWidth(1);
                    pdf.setDrawColor(148, 186, 195);
                    pdf.line(data.cell.x, data.cell.y + data.cell.height, data.cell.x + data.cell.width, data.cell.y + data.cell.height);

                }
                if (data?.cell?.raw?.content === 'map') {

                    pdf.addImage(data?.cell?.raw?.dataKey, 'PNG', data.cell.x + 2, data.cell.y + 2, 60, 60);
                }
                if (data?.cell?.raw?.dataKey === 'line') {
                    //add a line to the bottom of the cell with color lineColor: 'rgb(148, 186, 195)' and line width to 3
                    pdf.setLineWidth(1);
                    pdf.setDrawColor(148, 186, 195);
                    pdf.line(data.cell.x, data.cell.y + data.cell.height, data.cell.x + data.cell.width, data.cell.y + data.cell.height);

                }
                if (data?.cell?.raw?.dataKey === 'border') {
                    //add a line to the bottom of the cell with color lineColor: 'rgb(148, 186, 195)' and line width to 3
                    pdf.setLineWidth(0.3);
                    pdf.setDrawColor(211, 211, 211);
                    pdf.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height, 'S')

                }
                if (data?.cell?.raw?.dataKey === 'section') {
                    // add a thick line to the bottom of the cell
                    pdf.setLineWidth(1);
                    pdf.setDrawColor(0, 0, 0); // change the color as needed
                    pdf.line(data.cell.x, data.cell.y + data.cell.height - 2, data.cell.x + data.cell.width, data.cell.y + data.cell.height - 2);
                }

            },






            startY: bottom,
            theme: 'plain',


            styles: {
                fontSize: 12,
                halign: 'left',
                valign: 'top',
                padding: 0,
                overflow: 'linebreak',
                columnWidth: 'wrap',
                fontSize: 12,
                halign: 'left',
                valign: 'top',
                padding: 0,
                overflow: 'linebreak',
                columnWidth: 'wrap',
                cellPadding: { top: 1, right: 1, bottom: 1, left: 1 }, // add padding

            },
            columnStyles: {

            },
            headStyles: {
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
            },
        });

        const newBottomForNotes = pdf.lastAutoTable.finalY + 10;


        pdf.setFont(undefined, 'bold')
        pdf.text('Notes', 15, newBottomForNotes + 5, { fontSize: 18, fontStyle: 'bold' });
        pdf.setFont(undefined, 'normal')



        const descriptionItem = log?.fieldNotes || 'No notes';

        var output = "";
        for (var i = 0; i < descriptionItem.length; i++) {
            if (descriptionItem.charCodeAt(i) <= 127) {
                output += descriptionItem.charAt(i);
            }
        }


        pdf.autoTable({

            columns: [''],
            body: [
                [{ dataKey: 'notes', content: output, colSpan: 1, rowSpan: 1, styles: {} }],
                [
                    {
                        dataKey: 'line', content: '',
                        colSpan: 1, rowSpan: 1, styles: { lineWidth: 0, lineColor: 'rgb(148, 186, 195)' }
                    },


                ],
            ],
            didParseCell: (data) => {


                if (data?.cell?.raw?.dataKey === 'line') {
                    data.row.height = 15;
                }


            },
            didDrawCell: function (data) {
                if (['line'].includes(data?.cell?.raw?.dataKey)) {
                    //add a line to the bottom of the cell with color lineColor: 'rgb(148, 186, 195)' and line width to 3
                    pdf.setLineWidth(1);
                    pdf.setDrawColor(148, 186, 195);
                    pdf.line(data.cell.x, data.cell.y + data.cell.height, data.cell.x + data.cell.width, data.cell.y + data.cell.height);

                }

            },
            startY: newBottomForNotes + 5,
            theme: 'plain',

            styles: {
                overflow: 'linebreak',
                halign: 'left',
            },
            columnStyles: {

            },
            headStyles: {
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
            },
        });



        const newBottom = pdf.lastAutoTable.finalY + 10;

        //if new bottom is within one inch of the bottom of the page, add a new page, and then adjust photos to be to top of page
        if (newBottom > 275) {
            pdf.addPage();

        }



        //add text of Photos
        pdf.setFont(undefined, 'bold')
        pdf.text('Photos', 15, newBottom > 275 ? 30 : newBottom + 5, { fontSize: 18, });
        pdf.setFont(undefined, 'normal')



        const photoList = photos.map((photo, index) => {
            return (
                { dataKey: photo.key, content: '', photo: photo, colSpan: 1, rowSpan: 1, styles: { lineWidth: 0, lineColor: 'rgb(148, 186, 195)', cellPadding: { top: 1, right: 1, bottom: 1, left: 1 }, } }
            )


        })

        //split photolist into an array of arrays of 2
        const photoListSplit = photoList.reduce((acc, val, i) => {
            if (i % 2 === 0) {
                acc.push([val]);
            } else {
                acc[acc.length - 1].push(val);
            }
            return acc;
        }, []);



        const photoList1 = await Promise.all(photos.map(async (photo, index) => {
            const url = await getDataUri(photo.downloadURL);
            return {
                colSpan: 1,
                rowSpan: 1,
                description: photo.description || 'No Description',
                url
            }
        }));

        //split photolist into an array of arrays of 2
        const photoListSplit1 = photoList1.reduce((acc, val, i) => {
            if (i % 2 === 0) {
                acc.push([val]);
            } else {
                acc[acc.length - 1].push(val);
            }
            return acc;
        }, []);








        pdf.autoTable({
            pageBreak: 'auto',
            rowPageBreak: 'avoid',
            columns: ['', '',],
            body: photoListSplit1,
            didParseCell: (data) => {
                if (data?.cell?.raw) {
                    const url = data?.cell?.raw.url;
                    const description = data?.cell?.raw.description || "No Description";

                    const orientation = pdf.getImageProperties(url).height > pdf.getImageProperties(url).width ? 'portrait' : 'landscape';
                    const width = orientation === 'landscape' ? 80 : pdf.getImageProperties(url).width * 80 / pdf.getImageProperties(url).height;
                    const height = orientation === 'landscape' ? pdf.getImageProperties(url).height * 80 / pdf.getImageProperties(url).width : 80;

                    // Calculate text height
                    const textWidth = width;  // max width available for the text
                    const splitDescription = pdf.splitTextToSize(description, textWidth, { fontSize: 11 });
                    const textHeight = splitDescription.length * 5;  // Assume 5 units of height per line

                    data.row.height = Math.max(data.row.height, height + 5 + textHeight);
                }
            },
            didDrawCell: (data) => {
                if (data?.cell?.raw) {
                    const url = data?.cell?.raw.url;
                    const description = data?.cell?.raw.description || "No Description";

                    const orientation = pdf.getImageProperties(url).height > pdf.getImageProperties(url).width ? 'portrait' : 'landscape';
                    const width = orientation === 'landscape' ? 80 : pdf.getImageProperties(url).width * 80 / pdf.getImageProperties(url).height;
                    const height = orientation === 'landscape' ? pdf.getImageProperties(url).height * 80 / pdf.getImageProperties(url).width : 80;

                    pdf.addImage(url, 'PNG', data.cell.x + 2, data.cell.y, width, height);

                    //add text to bottom of photo
                    const textWidth = width;  // max width available for the text
                    const splitDescription = pdf.splitTextToSize(description, textWidth, { fontSize: 11 });
                    for (let i = 0; i < splitDescription.length; i++) {
                        pdf.text(splitDescription[i], data.cell.x + 2, data.cell.y + height + 5 + (i * 5), { fontSize: 11 });
                    }
                }
            },







            startY: newBottom > 275 ? 30 : newBottom + 5,
            theme: 'plain',

            styles: {
                fontSize: 12,
                halign: 'left',
                valign: 'top',
                padding: 0,
                overflow: 'linebreak',
                columnWidth: 'wrap',
            },
            columnStyles: {

            },
            headStyles: {
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
            },
        });





        //wait for downloadurl to be generated and then return it




        return pdf;


    }
    catch (error) {
        console.log(error)
        openSnack('error', 'Error generating PDF')

    }






};







