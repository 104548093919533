import React, { useState, useEffect } from 'react';
import { Button, Checkbox, Card, FormGroup, FormControlLabel, Slider, Typography, Divider, MenuItem, Select } from '@mui/material';
import DataColorGithub from './dataColorGithub';
import DataColorGithubFont from './dataColorGithubFont';

function CustomExpandPanel({
    showLabels,
    setShowLabels,
    layerColor,
    setLayerColor,
    updateGraphicsLayerAttributes,
    labelAttributes,
    setLabelAttributes,
    mapUnits,
    setMapUnits

}) {
    const [labelsVisible, setLabelsVisible] = useState(showLabels);
    const [opacity, setOpacity] = useState(layerColor?.rgb?.a * 100);
    const [color, setColor] = useState(layerColor);
    const [fontSize, setFontSize] = useState(labelAttributes?.font?.size);
    const [units, setUnits] = useState(mapUnits);
    const [fontColor, setFontColor] = useState(labelAttributes?.color);
    const [haloColor, setHaloColor] = useState(labelAttributes?.haloColor);



    useEffect(() => {
        setColor(layerColor);
        setOpacity(layerColor?.rgb?.a * 100);
        setLabelsVisible(showLabels);

    }, [layerColor, showLabels])


    const handleLabelVisibilityChange = (event) => {
        setLabelsVisible(event.target.checked);
        setShowLabels(event.target.checked);
        updateGraphicsLayerAttributes('showLabels', event.target.checked);

    };





    return (
        <Card
            style={{ width: '100%', height: '100%', padding: '10px' }}
            sx={{
                boxShadow: 3,
                border: '1px solid #ccc',
            }}

        >

            <Typography variant='body1' fontWeight={500} fontSize={18} style={{ display: 'inline' }}>
                Symbology
            </Typography>
            <Divider />

            <div>


                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={labelsVisible}
                                onChange={handleLabelVisibilityChange}
                            />
                        }
                        label="Show Labels"
                    />
                </FormGroup>
                <Typography variant='body1' fontWeight={500} style={{ display: 'inline' }}>
                    Layer Opacity <Typography style={{ display: 'inline' }} variant='body1' fontWeight={600}>{Number(opacity).toFixed(0)}%</Typography>
                </Typography>
                <Slider
                    value={opacity}
                    onChange={(event, newValue) => {
                        setOpacity(newValue);
                        const newColor = { ...color, rgb: { ...color.rgb, a: newValue / 100 } };
                        updateGraphicsLayerAttributes('color', newColor)
                    }}
                    aria-labelledby="opacity-slider"
                />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}> {/* Add a Flex container */}
                <Typography variant='body1' fontWeight={500} style={{ marginRight: 8 }}> {/* Add some margin for spacing */}
                    Layer Color
                </Typography>
                <DataColorGithub
                    layerColor={layerColor}
                    color={color}
                    onChange={(col) => {
                        setLayerColor({ ...col, rgb: { ...col.rgb, a: opacity / 100 } })
                        setColor(color);
                        console.log(opacity / 100)
                        const newColor = { ...col, rgb: { ...col.rgb, a: opacity / 100 } };
                        updateGraphicsLayerAttributes('color', newColor);
                        setLabelAttributes('color', { ...labelAttributes, color: fontColor, haloColor: haloColor, size: fontSize });
                    }}
                />
            </div>

            <Typography variant='body1' fontWeight={800} fontSize={14} color="black" style={{ marginTop: 15 }}>
                Labels
            </Typography>
            <Divider />

            <Typography variant='body1' fontWeight={500} style={{ display: 'inline' }}>
                Font Size <Typography style={{ display: 'inline' }} variant='body1' fontWeight={600}>{fontSize}px</Typography>
            </Typography>
            <Slider
                value={fontSize}
                onChange={(event, newValue) => {
                    setFontSize(newValue);


                }}
                onChangeCommitted={(event, newValue) => {
                    setFontSize(newValue);
                    setLabelAttributes('font', { ...labelAttributes, font: { ...labelAttributes?.font, size: newValue, } });
                }}
                min={10}
                max={50}

                aria-labelledby="opacity-slider"
            />
            <div style={{ display: 'flex', alignItems: 'center' }}> {/* Add a Flex container */}
                <Typography variant='body1' fontWeight={500} style={{ marginRight: 8 }}> {/* Add some margin for spacing */}
                    Font Color
                </Typography>
                <DataColorGithubFont
                    fontColor={fontColor}
                    onChange={(color) => {
                        setFontColor(color);
                        setLabelAttributes('color', { ...labelAttributes, color: color, haloColor: haloColor, size: fontSize });
                    }}
                />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}> {/* Add a Flex container */}
                <Typography variant='body1' fontWeight={500} style={{ marginRight: 8 }}> {/* Add some margin for spacing */}
                    Halo Color
                </Typography>
                <DataColorGithubFont
                    fontColor={haloColor}
                    onChange={(color) => {
                        setHaloColor(color);
                        setLabelAttributes('haloColor', { ...labelAttributes, haloColor: color, color: fontColor, size: fontSize });

                    }}
                />
            </div>

            <Typography variant='body1' fontWeight={800} fontSize={14} color="black" style={{ marginTop: 15 }}>
                Unit Settings
            </Typography>
            <Divider />
            <div style={{ display: 'flex', alignItems: 'center' }}> {/* Add a Flex container */}
                <Typography variant='body1' fontWeight={500} style={{ marginRight: 8 }}> {/* Add some margin for spacing */}
                    Area Units
                </Typography>
                <Select
                    size="small"
                    value={units?.area}
                    onChange={(event) => {
                        setMapUnits('area', event.target.value);
                        setUnits({ ...units, area: event.target.value });
                    }}
                >
                    <MenuItem value={'acres'}>Acres</MenuItem>

                    <MenuItem value={'square-feet'}>Square Feet</MenuItem>
                    <MenuItem value={'hectares'}>Hectares</MenuItem>
                    <MenuItem value={'square-meters'}>Square Meters</MenuItem>
                    <MenuItem value={'square-yards'}>Square Yards</MenuItem>
                </Select>

            </div>

            <div style={{ display: 'flex', alignItems: 'center', paddingTop: 5 }}>
                <Typography variant='body1' fontWeight={500} style={{ marginRight: 8 }}>
                    Distance Units
                </Typography>
                <Select
                    size="small"
                    value={units?.distance}
                    onChange={(event) => {
                        setMapUnits('distance', event.target.value);
                        setUnits({ ...units, distance: event.target.value });
                    }}
                >
                    <MenuItem value={'feet'}>Feet</MenuItem>
                    <MenuItem value={'miles'}>Miles</MenuItem>
                    <MenuItem value={'meters'}>Meters</MenuItem>
                    <MenuItem value={'kilometers'}>Kilometers</MenuItem>
                    <MenuItem value={'yards'}>Yards</MenuItem>
                </Select>
            </div>

        </Card>
    );
}

export default CustomExpandPanel;
