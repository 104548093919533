import React, { useState } from "react";


import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { faCheckCircle, faCircle, faCopy, faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { faSpinner, faToggleOff, faToggleOn, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { CircularProgress, Divider, IconButton, Tooltip, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import moment from 'moment-timezone';
import { ReactSortable } from 'react-sortablejs';
import './streamList.css';
import StreamCreateList from "./streamCreateList";
import FilterTable from "./filterTable";


const MobileStreamsTable = (
    {
        streams,
        sortType,
        setStreams,
        setSortType,
        screenWidth,
        showInactive,
        loading,
        setLoading,
        currentStream,
        setCurrentStream,
        deleteStream,
        updateStream,
        setStreamDrawer,
        duplicateStream,
        updateStreamsPosition,
        headers,
        setHeaders,
        sortItem,
        setSortItem,
        fullStreams,
        getNestedProperty,
        createStream,
        setShowInactive,
        openSnack,
        columnFilters,
        setColumnFilters,
        prefKey,
        loadingProbes

    }

) => {

    const [hoverKey, setHoverKey] = useState(null);



    async function handleSort(key, compareFunction) {

        const newSortType = sortType.endsWith('Up') ? `${key}Down` : `${key}Up`;

        const newStreams = streams.sort((a, b) => {
            const comparison = compareFunction(a, b);
            return sortType.endsWith('Up') ? comparison : -comparison;
        });

        setStreams(newStreams);
        setSortType(newSortType);
    };

    const iconStyle = (type) => ({
        fontSize: sortType === type ? 18 : 0,
        color: sortType === type ? 'black' : 'black',
    });



    console.log(streams)

    let sortedStreams = [...streams]; // make a copy of streams array

    // Extract the sorting key and direction from the sortItem string
    if (sortItem) {
        const sortKey = sortItem.split(',')[0].split(': ')[1];
        const sortDirection = sortItem.split(',')[1].split(': ')[1];

        sortedStreams = sortedStreams.sort((a, b) => {
            const valueA = getNestedProperty(a, sortKey);
            const valueB = getNestedProperty(b, sortKey);

            // Assume valueA and valueB can be strings or numbers
            if (valueA < valueB) {
                return sortDirection === 'asc' ? -1 : 1;
            }
            if (valueA > valueB) {
                return sortDirection === 'asc' ? 1 : -1;
            }
            return 0;
        });
    }

    console.log(sortedStreams)

    const props = {
        headers,
        setHeaders,
        openSnack,
        headers, setHeaders, streams, getNestedProperty,
        columnFilters,
        setColumnFilters,
        fullStreams,
        screenWidth
    }


    return (
        <Row style={{}}>
            <Col span={24}>


                <Row align="middle" >

                    <Col span={12} style={{
                        display: 'inline'
                    }}>



                        <StreamCreateList
                            createStream={createStream}
                            fullStreams={fullStreams}
                            setCurrentStream={setCurrentStream}
                            setStreamDrawer={setStreamDrawer}
                        />
                        {prefKey = 'probe' && (<Typography variant="h6" style={{ display: 'inline', marginLeft: 10, marginRight: 10 }}>
                            {loadingProbes ? 'Loading Probes...' : ''}
                            <FontAwesomeIcon icon={loadingProbes ? faSpinner : faCheckCircle} size='xl' spin={loadingProbes} style={{ color: loadingProbes ? '#3f51b5' : '#3f51b5' }} />
                        </Typography>)}


                    </Col>

                    <Col span={12} style={{ textAlign: 'right' }}>
                        <IconButton onClick={() => setShowInactive(!showInactive)}

                        >
                            <Tooltip name="Toggle Inactive" placement="bottom">
                                <FontAwesomeIcon icon={showInactive ? faEye : faEyeSlash}
                                    size="md" style={{ color: '#3f51b5', cursor: 'pointer' }} />
                            </Tooltip>

                        </IconButton>
                        <FilterTable {...props} />


                    </Col>



                    <Col span={24} style={{ marginTop: 10, paddingTop: 5, paddingBottom: 5, backgroundColor: '#f7f7f7' }}>
                        <Row align="middle" gutter={[12, 12]} >



                            <Col xs={24} style={{
                                cursor: 'pointer',
                            }}>
                                <Row align="middle" gutter={[12, 12]}>

                                    <Col xs={3} sm={0} style={{
                                        //space items to either side of the screen
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'left',

                                    }}>

                                    </Col>
                                    <Col span={2} style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',



                                    }} >
                                        <IconButton
                                            onClick={() => {
                                                console.log("SORTING CLICKED");
                                                if (sortItem === `key: inactive, direction: asc`) {
                                                    setSortItem(`key: inactive, direction: desc`);
                                                } else {
                                                    setSortItem(`key: inactive, direction: asc`);
                                                }
                                            }}
                                        >
                                            {
                                                sortItem === `key: inactive, direction: asc` ? (
                                                    <CaretUpOutlined style={{ color: '#3f51b5', fontSize: 16 }} />
                                                ) : sortItem === `key: inactive, direction: desc` ? (
                                                    <CaretDownOutlined style={{ color: '#3f51b5', fontSize: 16 }} />
                                                ) : (
                                                    <CaretDownOutlined style={{ color: 'rgba(0,0,0,0.2)', fontSize: 16 }} />
                                                )
                                            }
                                        </IconButton>
                                    </Col>
                                    <Col xs={19} onClick={() => handleSort('name', (a, b) => a.name.localeCompare(b.name))} style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'left'
                                    }}>
                                        <Typography variant="body1" fontWeight={700} fontSize={screenWidth === 'xs' ? 14 : 20}>
                                            Name
                                            <IconButton
                                                onClick={() => {
                                                    console.log("SORTING CLICKED");
                                                    if (sortItem === `key: name, direction: asc`) {
                                                        setSortItem(`key: name, direction: desc`);
                                                    } else {
                                                        setSortItem(`key: name, direction: asc`);
                                                    }
                                                }}
                                            >
                                                {
                                                    sortItem === `key: name, direction: asc` ? (
                                                        <CaretUpOutlined style={{ color: '#3f51b5', fontSize: 16 }} />
                                                    ) : sortItem === `key: name, direction: desc` ? (
                                                        <CaretDownOutlined style={{ color: '#3f51b5', fontSize: 16 }} />
                                                    ) : (
                                                        <CaretDownOutlined style={{ color: 'rgba(0,0,0,0.2)', fontSize: 16 }} />
                                                    )
                                                }
                                            </IconButton>
                                        </Typography>
                                    </Col>



                                </Row>
                            </Col>

                        </Row>
                    </Col>

                    <Col span={24} style={{ paddingBottom: 30, paddingRight: 0, marginRight: 0 }}>

                        <ReactSortable
                            handle=".my"
                            animation={500}
                            list={sortedStreams}
                            setList={(newState) => {

                                updateStreamsPosition(newState);

                            }}
                        >
                            {sortedStreams?.map((l, i) => {
                                const StreamIcon = ({ stream, handleStreamUpdate }) => (
                                    <FontAwesomeIcon
                                        size='xl'
                                        icon={stream.inactive ? faToggleOff : faToggleOn}
                                        style={{
                                            color: stream.inactive ? 'orange' : '#19d319',
                                            cursor: 'pointer',
                                            paddingRight: 0,
                                            paddingLeft: 5,
                                        }}
                                        onClick={() => {
                                            const newStream = { ...stream, inactive: !stream.inactive };
                                            updateStream(newStream);
                                        }}
                                    />
                                );

                                if ((showInactive || !l.inactive) && !l.filtered) {
                                    return (
                                        <Col key={i} span={24} style={{}}>
                                            <Row align="middle" gutter={[0, 0]} className="row-hover-effect" style={{
                                                border: '1px solid rgba(0,0,0,0.2)', borderLeft: null, borderRight: null, paddingTop: 0,
                                                margin: 0
                                            }}
                                                onMouseEnter={() => setHoverKey(l.id)}
                                                onMouseLeave={() => setHoverKey(null)}
                                            >


                                                <Col xs={3} sm={0} style={{
                                                    //space items to either side of the screen
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'left',

                                                }}>

                                                    <div className="my" >
                                                        <DragIndicatorIcon />
                                                    </div>
                                                </Col>
                                                <Col xs={2} sm={0} style={{
                                                    //space items to either side of the screen
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',

                                                }}>

                                                    <StreamIcon stream={l} updateStream={updateStream} />
                                                </Col>


                                                <Col span={19} style={{ cursor: 'pointer', margin: 0, padding: 0, height: '100%', }}

                                                    onClick={() => {
                                                        setCurrentStream(l)
                                                        setStreamDrawer('update')
                                                    }}>
                                                    <Row

                                                        gutter={[0, 0]}
                                                        style={{
                                                            margin: 0,
                                                            padding: 0,
                                                            height: '100%',
                                                            display: 'flex',
                                                        }}
                                                    >
                                                        <Col xs={24} style={{

                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'left',
                                                            padding: 8,

                                                        }}>
                                                            <Typography variant="body2" fontWeight={500} fontSize={15}>{l.name}</Typography>
                                                        </Col>






                                                    </Row>

                                                </Col>

                                            </Row>
                                        </Col>
                                    );
                                } else {
                                    return <div key={i}></div>;
                                }
                            })}
                        </ReactSortable>
                    </Col>



                    {loading === false && streams?.length === 0 && (<Col span={24} style={{ paddingBottom: 30, textAlign: 'center' }}>
                        <Typography variant="h4" fontSize={24} fontWeight={600}>No Streams</Typography>
                    </Col>)}
                    {loading && (<Col span={24} style={{ paddingBottom: 30, textAlign: 'center' }}>
                        <CircularProgress />
                    </Col>)}


                </Row>
            </Col >



        </Row >
    );
};

export default MobileStreamsTable;
