import {
    Button, Checkbox, Divider, OutlinedInput, Typography, Select, MenuItem, CircularProgress, Breadcrumbs, FormGroup, FormControlLabel, Autocomplete, Box,
    Chip, TextField, IconButton, Fab, ButtonGroup
} from '@mui/material';
import { Col, Popconfirm, Row } from 'antd';
import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { fullMobile } from './util';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, } from 'firebase/firestore';

import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import uuid from 'react-uuid';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";

import DatePicker from "react-datepicker";
import queryString from 'query-string';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { queryPSItems } from '../../firebase/config';
import { DeleteForever } from '@mui/icons-material';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import './tableStyles.css'
import { faFile, faTablets, faToolbox } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PSChemical from './psItemTypes/chemicalItem';
import PSFish from './psItemTypes/fishItem';
import PSEquipment from './psItemTypes/equipmentItem';
import PSDye from './psItemTypes/dyeItem';
import PSBacteria from './psItemTypes/bacteriaItem';
import PSMaterial from './psItemTypes/materialItem';
import { faCloudArrowUp, faMapLocationDot } from '@fortawesome/free-solid-svg-icons';
import Dropzone from 'react-dropzone';
import InspectionItemHome from './inspectionItem/inspectionItemHome';
var randomColor = require('randomcolor');



export default function PSItem(props) {
    const { userInfo, screenWidth, openSnack, company, account, appItem, closeModal } = props;
    const [item, setItem] = useState({});
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isDragActive, setIsDragActive] = useState(false);
    const [isFileActive, setIsFileActive] = useState('');

    useEffect(() => {
        getInfo();
        window.scrollTo(0, 0);
    }, [])

    async function getInfo() {
        queryDataDoc()
        const data = await queryPSItems(userInfo);

        setData(data);

    }

    async function queryDataDoc() {
        const db = getFirestore();
        const docRef = doc(db, "users", userInfo.id, 'currentItems', 'currentPS');
        const docSnap = await getDoc(docRef);

        if (docSnap.data().key === 'none' || appItem === true) {
            setItem({
                tempKey: uuid(),
                psType: 'none',
                archived: false,
                files: []
            })
            setLoading(false)
        }
        else {
            getDocItem(docSnap.data().key)
        }
    }

    async function getDocItem(key) {
        const db = getFirestore();
        const docRef = doc(db, "clients", userInfo.currentCompany, 'psItems', key);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {

            setItem(docSnap.data())
            setLoading(false)
        }
    }


    const serviceItems = [
        { label: 'General', key: 'general' },
        { label: 'Harvest', key: 'harvest' },
        { label: 'Maintenance', key: 'harvest' },
        { label: 'Monitoring', key: 'monitoring' },
        { label: 'Other', key: 'other' },
        { label: 'Water Quality', key: 'waterQuality' },
    ];
    const productItems = [
        { label: 'Bacterial', key: 'bacterial' },
        { label: 'Chemical', key: 'chemical' },
        { label: 'Dye', key: 'dye' },
        { label: 'Equipment', key: 'equipment' },
        { label: 'Fish', key: 'fish' },
        { label: 'Material', key: 'material' },
        { label: 'Other', key: 'other' },
    ];


    function updateItem(boo, val) {

        setItem({
            ...item,
            [boo]: val
        })
    }

    const DeleteFileButton = ({ file }) => {
        return (
            //create a button that is in the top right corner of the image
            <Button
                style={{ position: 'absolute', top: 0, right: 0, zIndex: 1, borderRadius: 12 }}
                onClick={() => {
                    //delete photo, if log.key exists, delete from firebase, if not, delete from data

                    const storage = getStorage();
                    const storageRef = ref(storage, `/products/${userInfo.currentAccount}/${file.key}`);
                    deleteObject(storageRef).then(() => {
                        // File deleted successfully
                        //update data
                        const newData = item.files.filter((d) => d.key !== file.key)
                        setItem({
                            ...item,
                            files: newData
                        })


                    }).catch((error) => {
                        // Uh-oh, an error occurred!
                    });



                }}
            >
                <DeleteForever />
            </Button>
        )
    }

    const File = ({ file }) => {

        return (
            <Col {...fullMobile(24, 24)} style={{
                cursor: 'pointer',
                width: '100%', border: '1px solid #d9d9d9', borderRadius: 12, justifyContent: 'center', alignItems: 'center',
                transition: 'box-shadow 400ms, transform 400ms',
                boxShadow: isFileActive === file.key ? '0 4px 8px 0 rgba(0, 0, 0, 0.2)' : 'inset 0 0 0 1px #d9d9d9',
                transform: isFileActive === file.key ? 'scale(1.05)' : 'scale(1)',
                objectFit: 'cover', borderRadius: 12
            }} className="card-container"
                //if mouse over, change the background color

                onMouseEnter={() => {
                    setIsFileActive(file.key)
                }}
                onMouseLeave={() => {
                    setIsFileActive('')
                }}
                onClick={() => {
                    const downloadurl = file.fileLink;
                    window.open(downloadurl, '_blank');

                }}


            >
                <Row gutter={[12, 12]} align="middle" style={{ height: '100%' }}>
                    <DeleteFileButton file={file} />
                    <Col span={24}
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                        <FontAwesomeIcon icon={faFile} size='3x' />
                    </Col>
                    <Col span={24}

                    >
                        <Typography variant='h6' fontWeight={500} fontSize={16} style={{ textAlign: 'center' }}>{file.fileName}</Typography>



                    </Col>



                </Row>
            </Col>
        )
    }

    let navigate = useNavigate();
    const SubmitButton = () => {

        return (
            <Button variant={'contained'} display="inline"
                fullWidth
                style={{
                    textTransform: 'none',
                    backgroundColor: '#404050', fontSize: 14,

                }}
                onClick={() => {
                    console.log(item)
                    if (item?.name) {
                        console.log(item)
                        if (item?.psType === 'none') {
                            openSnack('error', 'Please select a type')
                        }
                        else {
                            if (item?.key) {
                                updatePSItem(item, userInfo)

                            }
                            else {
                                console.log('create item')
                                createPSItem(item, userInfo)

                            }
                        }
                    }
                    else {
                        openSnack('error', 'Please enter a name')
                    }


                }}>{item.key ? 'Update Item' : 'Create Item'}</Button>
        )
    }
    const DeleteButton = () => {
        let navigate = useNavigate();
        return (
            <Popconfirm
                title="Are you sure to delete this item?"
                onConfirm={async () => {
                    const db = getFirestore();
                    const itemRef = doc(db, "clients", userInfo.currentCompany, 'psItems', item?.key);
                    await updateDoc(itemRef, {
                        archived: true
                    }).then(() => {
                        //delete from data
                        openSnack('success', 'Item Deleted')
                        navigate('/psHome')
                    })

                }}
                okText="Yes"
                cancelText="No"
            >
                <Button variant={'contained'} display="inline"
                    fullWidth
                    style={{
                        textTransform: 'none',
                        border: '1px solid red',
                        backgroundColor: '#404050', fontSize: 14,

                    }}
                    onClick={() => {



                    }}>Delete Item</Button>
            </Popconfirm>

        )
    }
    function updatePSItem(item, userInfo) {
        const db = getFirestore();
        const docRef = doc(db, "clients", userInfo.currentCompany, 'psItems', item.key);
        updateDoc(docRef, item).then(() => {
            openSnack('success', 'Item Updated')
            if (appItem) {
                closeModal()
            }
            else {
                navigate('/psHome')
            }

        }
        ).catch((error) => {
            openSnack('error', 'Error Updating Item')
        }
        );
    }
    function createPSItem(item, userInfo) {
        const db = getFirestore();
        const docRef = doc(db, "clients", userInfo.currentCompany, 'psItems', item.tempKey);
        console.log(item)
        setDoc(docRef, {
            ...item,
            key: item.tempKey,
        }).then(() => {
            updateItem('key', item.tempKey)
            openSnack('success', 'Item Created')
            if (appItem) {
                closeModal()
            }
            else {
                navigate('/psHome')
            }
        }
        ).catch((error) => {
            openSnack('error', 'Error Creating Item')
        })

    }
    function duplicateItem(item, userInfo) {
        const db = getFirestore();
        const newKey = uuid();
        const docRef = doc(db, "clients", 'Lake Wildwood62fdf56-ec3b-4d-88-60e287aa122', 'psItems', newKey);
        console.log(item)

        setDoc(docRef, {
            ...item,
            key: newKey,
            tempKey: newKey,
            name: `(PMLA Copy) ${item.name}`,
        })

    }

    const typesList = appItem == true ? [
        { psType: 'products', label: 'Product' },] :
        [
            { psType: 'services', label: 'Service' },
            { psType: 'products', label: 'Product' },
            { psType: 'inspection', label: 'Template' },
        ];

    const productTypes = appItem == true ? [
        { label: 'Chemical', key: 'chemical' },

    ] : productItems;


    return (
        <Row style={{
            padding: screenWidth === 'xs' ? 15 : 40,
            paddingRight: 80
        }}>
            {appItem !== true && (<Col {...fullMobile(20, 20)} style={{ paddingTop: 10 }}>
                <Breadcrumbs>
                    <Link to="/" style={{ textDecoration: 'none', color: 'inherit', }}>
                        Accounts
                    </Link>
 
                    <Link to="/psHome" style={{ textDecoration: 'none', color: 'inherit', }} >Products and Services</Link>

                </Breadcrumbs>
            </Col>)}

            <Col {...fullMobile(20, 20)} style={{ paddingTop: 10 }}>
                <Typography variant='h2' fontWeight={500} fontSize={28}>{item.key ? 'Update Item' : 'Create Item'}</Typography>
                <Typography variant='body1' fontWeight={300} fontSize={16}>
                    {item.key ? 'Update' : 'Create'} a product or services that you use/perform on your sites for you clients and customers.</Typography>


            </Col>
            <Col {...fullMobile(4, 4)} style={{ paddingTop: 15 }}  >
                {item.key && (<DeleteButton />)}
            </Col>
            <Col {...fullMobile(24, 24)} style={{ paddingTop: 10 }}>
                <Divider />
            </Col>
            <Col {...fullMobile(24, 24)} style={{}}>
                <Row align="middle" gutter={[4, 4]}>
                    <Col {...fullMobile(24, 24)} style={{}}>
                        <Typography variant="body1" fontWeight={500} component="h4"  >
                            Name
                        </Typography>

                    </Col>
                    <Col {...fullMobile(24, 24)} style={{}}>
                        <OutlinedInput
                            fullWidth
                            notched={false}
                            size="small"
                            placeholder="Name"
                            label="none"
                            onChange={(e) => {
                                setItem({
                                    ...item,
                                    name: e.target.value
                                })
                            }
                            }
                            value={item?.name}
                        />
                    </Col>

                </Row>

            </Col>
            <Col {...fullMobile(24, 24)} style={{}}>
                <Row align="middle" gutter={[4, 4]}>
                    <Col {...fullMobile(24, 24)} style={{}}>
                        <Typography variant="body1" fontWeight={500} component="h4"  >
                            Description
                        </Typography>

                    </Col>
                    <Col {...fullMobile(24, 24)} style={{}}>
                        <OutlinedInput
                            fullWidth
                            rows={4}
                            multiline={true}
                            notched={false}
                            size="small"
                            placeholder="Name"
                            label="none"
                            onChange={(e) => {
                                setItem({
                                    ...item,
                                    description: e.target.value
                                })
                            }
                            }
                            value={item?.description}
                        />
                    </Col>

                </Row>

            </Col>
            <Col {...fullMobile(24, 24)} style={{}}>
                <Row align="middle" gutter={[4, 4]}>
                    <Col {...fullMobile(24, 24)} style={{}}>
                        <Typography variant="body1" fontWeight={500} component="h4"  >
                            Type
                        </Typography>

                    </Col>
                    <Col {...fullMobile(24, 24)} style={{ paddingBottom: 10 }}>
                        <ButtonGroup >
                            {



                                typesList.map((button, index) => {
                                    return (


                                        <Button variant={item?.psType === button.psType ? 'contained' : 'outlined'} display="inline"
                                            style={{
                                                textTransform: 'none',
                                                color: item?.psType === button.psType ? '#fff' : '#000',
                                                backgroundColor: item?.psType === button.psType ? '#404050' : 'inherit ', fontSize: 14
                                            }}
                                            onClick={() => {
                                                setItem({
                                                    ...item,
                                                    psType: button.psType,
                                                    serviceType: null,
                                                    productType: null
                                                })
                                            }}>{button.label}</Button>
                                    )
                                })}

                        </ButtonGroup>
                    </Col>

                </Row>

            </Col>

            {
                ['inspection'].includes(item?.psType) && (
                    <Col {...fullMobile(24, 24)} style={{}}>
                        <Row align="middle" gutter={[4, 4]}>


                            <Col xs={24} >
                                <InspectionItemHome screenWidth={screenWidth} userInfo={userInfo} openSnack={openSnack} itemRef={item} updateItem={updateItem} />
                            </Col>



                        </Row>
                    </Col>
                )
            }







            {
                ['services', 'products'].includes(item?.psType) && (<Col {...fullMobile(24, 24)} style={{}}>
                    <Row align="middle" gutter={[4, 4]}>
                        <Col {...fullMobile(24, 24)} style={{}}>
                            <Typography variant="body1" fontWeight={500} component="h4"  >
                                {item?.psType === 'services' ? 'Service Type' : 'Product Type'}
                            </Typography>

                        </Col>
                        <Col {...fullMobile(24, 24)} style={{}}>
                            <Autocomplete
                                size="small"
                                disablePortal
                                id="combo-box-demo"
                                options={item?.psType === 'services' ? serviceItems : productTypes}
                                value={item?.psType === 'services' ? item?.serviceType : item?.productType}
                                renderInput={(params) => <TextField {...params} placeholder="Type" />}
                                onChange={(a, b) => {

                                    if (item?.psType === 'services') {
                                        setItem({
                                            ...item,
                                            serviceType: b
                                        })
                                    }
                                    else {
                                        setItem({
                                            ...item,
                                            productType: b
                                        })
                                    }
                                }}

                            />
                        </Col>

                    </Row>

                </Col>)
            }

            {
                item?.productType?.label === 'Chemical' && (
                    <Col xs={24} >
                        <PSChemical screenWidth={screenWidth} userInfo={userInfo} openSnack={openSnack} item={item} setItem={setItem} updateItem={updateItem} />
                    </Col>
                )
            }
            {
                item?.productType?.label === 'Fish' && (
                    <Col xs={24} >
                        <PSFish screenWidth={screenWidth} userInfo={userInfo} openSnack={openSnack} item={item} setItem={setItem} updateItem={updateItem} />
                    </Col>
                )
            }
            {
                item?.productType?.label === 'Equipment' && (
                    <Col xs={24} >
                        <PSEquipment screenWidth={screenWidth} userInfo={userInfo} openSnack={openSnack} item={item} setItem={setItem} updateItem={updateItem} />
                    </Col>
                )
            }
            {
                item?.productType?.label === 'Dye' && (
                    <Col xs={24} >
                        <PSDye screenWidth={screenWidth} userInfo={userInfo} openSnack={openSnack} item={item} setItem={setItem} updateItem={updateItem} />
                    </Col>
                )
            }
            {
                item?.productType?.label === 'Bacterial' && (
                    <Col xs={24} >
                        <PSBacteria screenWidth={screenWidth} userInfo={userInfo} openSnack={openSnack} item={item} setItem={setItem} updateItem={updateItem} />
                    </Col>
                )
            }
            {
                item?.productType?.label === 'Material' && (
                    <Col xs={24} >
                        <PSMaterial screenWidth={screenWidth} userInfo={userInfo} openSnack={openSnack} item={item} setItem={setItem} updateItem={updateItem} />
                    </Col>
                )
            }
            {
                item?.psType === 'services' && (
                    <Col {...fullMobile(24, 24)} style={{}}>
                        <Row align="middle" gutter={[4, 4]}>
                            <Col {...fullMobile(24, 24)} style={{}}>
                                <Typography variant="body1" fontWeight={500} component="h4"  >
                                    Additional Information
                                </Typography>

                            </Col>
                            <Col {...fullMobile(24, 24)} style={{}}>
                                <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                    multiline={true}
                                    rows={4}
                                    value={item.serviceInformation}
                                    onChange={(e) => {
                                        updateItem(`serviceInformation`, e.target.value)
                                    }} />
                            </Col>

                        </Row>

                    </Col>
                )
            }

            {
                item?.files !== undefined && item?.files.length > 0 && (item.files.map((d, i) => {


                    return (
                        <Col {...fullMobile(6, 12)} style={{ borderRadius: 12, paddingTop: 15 }} className="card-container" >
                            {d.progress < 100 && (
                                <Col {...fullMobile(8, 12)} style={{
                                    borderRadius: 12, borderRadius: 12, alignItems: 'center',
                                    justifyContent: 'center', display: 'flex', cursor: 'pointer', height: '100%',
                                    backgroundColor: '#fff', color: '#f47e42', padding: '30px', fontWeight: 600, fontSize: '18px',
                                    boxShadow: isDragActive ? '0 8px 16px 0 rgba(0, 0, 0, 0.2)' : 'inset 0 0 0 1px #d9d9d9',
                                }} className="card-container"  >
                                    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                                        <CircularProgress variant="determinate" value={d.progress} />
                                        <Box
                                            sx={{
                                                top: 0,
                                                left: 0,
                                                bottom: 0,
                                                right: 0,
                                                position: 'absolute',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <Typography variant="caption" component="div" color="text.secondary">
                                                {`${Math.round(d.progress)}%`}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Col>)}

                            {d.progress === 100 && (
                                <File file={d} />
                            )}
                            {d.progress === undefined && (
                                <File file={d} />
                            )}
                        </Col>
                    )
                }))
            }

            < Col {...fullMobile(24, 24)} style={{ paddingTop: 15 }} className="card-container"
                //if dragging over, change the background color

                onDragEnter={() => setIsDragActive(true)}
                onDragLeave={() => setIsDragActive(false)}
                onDragOver={() => setIsDragActive(true)}
                onDrop={() => setIsDragActive(false)}
                onMouseOver={() => setIsDragActive(true)}
                onMouseLeave={() => setIsDragActive(false)}

            >
                <Dropzone multiple={true} onDrop={(acceptedFiles) => {
                    const storage = getStorage();

                    acceptedFiles.map((f, i) => {
                        const key = uuid()

                        const file = {
                            fileCreateDate: moment().format('X'),
                            date: moment().format('X'),
                            fileCreator: `${userInfo.firstName || ""} ${userInfo.lastname}`,
                            fileCreatorKey: userInfo.id,
                            fileLink: '',
                            fileName: f.name,
                            fileType: f.type,
                            description: '',
                            key,
                            progress: 0
                        };

                        item.files.push(file)
                        updateItem(item)


                        const storage = getStorage();


                        const storageRef = ref(storage, `/products/${userInfo.currentAccount}/${key}`);


                        const uploadTask = uploadBytesResumable(storageRef, f);
                        uploadTask.on('state_changed',
                            (snapshot) => {
                                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

                                const index = item.files.findIndex((d) => d.key === key)
                                item.files[index].progress = progress
                                updateItem('files', [...item.files])


                                switch (snapshot.state) {
                                    case 'paused':
                                        console.log('Upload is paused');
                                        break;
                                    case 'running':
                                        console.log('Upload is running');
                                        break;
                                }
                            },
                            (error) => {
                                console.log(error)
                            },
                            () => {
                                getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {

                                    const index = item.files.findIndex((d) => d.key === key)
                                    item.files[index].fileLink = downloadURL;
                                    item.files[index].progress = 100
                                    updateItem('files', [...item.files])










                                });
                            }
                        )




                    })
                }} >
                    {({ getRootProps, getInputProps }) => (
                        <section style={{ height: '100%' }}>
                            <div {...getRootProps()} style={{
                                borderRadius: 12, alignItems: 'center',
                                backgroundColor: 'white', padding: '30px', fontWeight: 700,
                                transition: 'box-shadow 400ms, transform 400ms',
                                boxShadow: isDragActive ? '0 4px 8px 0 rgba(0, 0, 0, 0.2)' : 'inset 0 0 0 1px #d9d9d9',
                                transform: isDragActive ? 'scale(1.05)' : 'scale(1)',
                                cursor: 'pointer',
                                border: isDragActive ? '2px solid #404050' : '1px solid #d9d9d9',


                            }}>


                                <input {...getInputProps()} />
                                <Row gutter={[12, 12]}>
                                    <Col span={24} style={{ textAlign: 'center' }}>
                                        <FontAwesomeIcon fontSize={28} icon={faCloudArrowUp} color="#9ec1c9" />
                                    </Col>
                                    <Col span={24} style={{ textAlign: 'center', color: '#404050', }}>
                                        Upload Files</Col>
                                </Row>


                            </div>
                        </section>
                    )}
                </Dropzone>
            </Col>

            <Col {...fullMobile(24, 24)} style={{ paddingTop: 15 }}  >
                <SubmitButton />
            </Col>



        </Row >
    )



}