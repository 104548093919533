import React, { useState } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight, faEllipsisH, faPlus, faX } from '@fortawesome/free-solid-svg-icons';
import { IconButton, List, Tooltip, ListItem, OutlinedInput, InputAdornment, ListItemIcon, Box } from '@mui/material';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import uuid from 'react-uuid';

export default function AlbumBreadCrumbs({
    albumBreadCrumbs,
    setAlbumBreadCrumbs,
    setCurrentAlbum,
    setCurrentParentAlbum,

}) {




    return (
        <>
            {albumBreadCrumbs.length === 0 && (
                <>
                    <Button variant="link">
                        <Typography style={{
                            textTransform: 'none',
                            fontSize: 18,
                            color: 'rgba(0,0,0,0.9)',
                            fontWeight: 700,


                        }}>
                            Root Album
                        </Typography>
                    </Button>
                </>
            )}
            {albumBreadCrumbs.length > 0 && (
                <>
                    <Button variant="link" style={{ display: 'inline' }}>
                        <Typography style={{
                            textTransform: 'none',
                            fontSize: 18,
                            color: albumBreadCrumbs.length > 0 ? 'rgba(0,0,0,0.5)' : 'rgba(0,0,0,0.9)',
                            fontWeight: albumBreadCrumbs.length > 0 ? 500 : 700,

                            display: 'inline'
                        }}
                            onClick={() => {
                                setAlbumBreadCrumbs([])
                                setCurrentAlbum(null)
                                setCurrentParentAlbum(null)

                            }}

                        >
                            Root Album
                        </Typography>
                        <FontAwesomeIcon icon={faChevronRight}
                            color={albumBreadCrumbs.length > 0 ? 'rgba(0,0,0,0.5)' : 'rgba(0,0,0,0.9)'}
                            size="lg"
                            style={{ marginLeft: 15, display: 'inline' }} />
                    </Button>
                    {albumBreadCrumbs.map((album, index) => (
                        <Button variant="link" style={{ display: 'inline' }}>



                            <Typography style={{
                                textTransform: 'none',
                                fontSize: 18,
                                color: albumBreadCrumbs.length - 1 === index ? 'rgba(0,0,0,0.9)' : 'rgba(0,0,0,0.5)',
                                fontWeight: albumBreadCrumbs.length - 1 === index ? 700 : 500,
                                display: 'inline'


                            }}
                                onClick={() => {
                                    const newBreadcrumbs = [...albumBreadCrumbs]
                                    newBreadcrumbs.splice(index + 1, newBreadcrumbs.length)
                                    setAlbumBreadCrumbs(newBreadcrumbs)
                                    setCurrentParentAlbum(album)
                                }}



                            >
                                {album.name}
                            </Typography>
                            <FontAwesomeIcon icon={albumBreadCrumbs.length - 1 === index ? faChevronDown : faChevronRight}
                                style={{ marginLeft: 15, display: 'inline', paddingBottom: 2 }} size="lg"
                                color={albumBreadCrumbs.length - 1 === index ? 'black' : 'rgba(0,0,0,0.5)'}
                            />


                        </Button>
                    ))}
                </>
            )}






        </>
    );
}