import React, { useState } from 'react';
import '../styles.css';
import { Button, Grid, IconButton, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material';
import ItemColorPicker from './colorPicker';
import { faCheck, faEquals, faGreaterThan, faGreaterThanEqual, faLessThan, faLessThanEqual, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



var randomColor = require('randomcolor');


const ColorRanges = ({ colorRanges, setColorRanges, openSnack }) => {
    const [rangeValidated, setRangeValidated] = useState(false);
    // Function to update the range details
    const updateRange = (index, key, value) => {
        const newRanges = [...colorRanges];
        newRanges[index][key] = value;
        setColorRanges(newRanges);
        rangeValidation(newRanges)
    }

    function rangeValidation(newRanges) {
        let isValid = true;
        let errorMessage = '';

        for (let i = 0; i < newRanges.length; i++) {
            const rangeA = newRanges[i];

            for (let j = 0; j < newRanges.length; j++) {
                if (i === j) continue; // Skip comparing the range with itself

                const rangeB = newRanges[j];

                // Check if there's an overlap
                if (rangesOverlap(rangeA, rangeB)) {
                    isValid = false;
                    errorMessage = `Range ${i + 1} overlaps with Range ${j + 1}.`;
                    break;
                }
            }
            console.log(isValid)

            if (!isValid) {
                setRangeValidated(false);
                openSnack('error', errorMessage)

            }
            else {
                setRangeValidated(true);
                openSnack('success', 'Ranges are validated')
            }
        }

        if (!isValid) {
            console.error(errorMessage);
            // Optionally, use openSnack or other methods to display the error
        }

        return isValid;
    }

    function rangesOverlap(rangeA, rangeB) {
        // Convert min and max values to numbers
        const minA = Number(rangeA.min), maxA = Number(rangeA.max);
        const minB = Number(rangeB.min), maxB = Number(rangeB.max);

        switch (rangeA.type) {
            case 'greater_than':
                if (rangeB.type === 'less_than' || rangeB.type === 'less_or_equal') {
                    return minA < maxB;
                }
                break;
            case 'less_than':
                if (rangeB.type === 'greater_than' || rangeB.type === 'greater_or_equal') {
                    return maxA > minB;
                }
                break;
            case 'greater_or_equal':
                if (rangeB.type === 'less_than' || rangeB.type === 'less_or_equal') {
                    return minA <= maxB;
                }
                break;
            case 'less_or_equal':
                if (rangeB.type === 'greater_than' || rangeB.type === 'greater_or_equal') {
                    return maxA >= minB;
                }
                break;
            case 'between':
                if (rangeB.type === 'between') {
                    return !(maxA <= minB || maxB <= minA);
                } else if (rangeB.type === 'greater_than' || rangeB.type === 'greater_or_equal') {
                    return maxA > minB;
                } else if (rangeB.type === 'less_than' || rangeB.type === 'less_or_equal') {
                    return minA < maxB;
                }
                break;
            case 'equals':
                if (rangeB.type === 'equals') {
                    return minA === minB || maxA === maxB;
                } else if (rangeB.type === 'greater_than' || rangeB.type === 'greater_or_equal') {
                    return maxA >= minB;
                } else if (rangeB.type === 'less_than' || rangeB.type === 'less_or_equal') {
                    return minA <= maxB;
                }
                break;
            // Add more cases as needed
        }
        return false;
    }



    return (
        <Grid container alignItems="center">
            <Grid item xs={8}>
                <Typography fontSize={13} fontWeight={600} variant="body1">Custom color ranges (double check your ranges do not clash)
                </Typography>
            </Grid>

            <Grid item xs={4}>
                <FontAwesomeIcon icon={rangeValidated ? faCheck : faTimes}
                    size="xl" color={rangeValidated ? 'green' : 'red'} onClick={rangeValidation} />
            </Grid>
            <Grid item xs={12}>
                <Grid container justify="center" alignItems="center" >
                    <Grid item xs={2}>
                        <Typography fontSize={13} fontWeight={600} variant="body1">Color</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography fontSize={13} fontWeight={600} variant="body1">Min</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography fontSize={13} fontWeight={600} variant="body1">Type</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography fontSize={13} fontWeight={600} variant="body1">Max</Typography>
                    </Grid>
                    <Grid item xs={2}>

                    </Grid>
                </Grid>
                {colorRanges.map((item, index) => {
                    return (
                        <Grid container justify="center" alignItems="center" style={{ border: '1px solid gray' }}>
                            <Grid item xs={2}>
                                <ItemColorPicker color={item.color} setColor={(color) => updateRange(index, 'color', color)} />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    type="number"
                                    value={item.min}
                                    onChange={(e) => updateRange(index, 'min', e.target.value)}
                                    fullWidth
                                    disabled={['less_than', 'less_or_equal'].includes(item.type)}
                                />
                            </Grid>

                            <Grid item xs={2}>
                                <Select
                                    value={item.type}
                                    onChange={(e) => updateRange(index, 'type', e.target.value)}
                                    fullWidth
                                >
                                    <MenuItem value="greater_than">
                                        <FontAwesomeIcon fontSize={18} size="sm" icon={faGreaterThan} />
                                    </MenuItem> {/* Greater Than */}
                                    <MenuItem value="less_than">
                                        <FontAwesomeIcon fontSize={18} size="sm" icon={faLessThan} />
                                    </MenuItem> {/* Less Than */}
                                    <MenuItem value="greater_or_equal">
                                        <FontAwesomeIcon fontSize={18} size="sm" icon={faGreaterThanEqual} />
                                    </MenuItem> {/* Greater or Equal */}
                                    <MenuItem value="less_or_equal">
                                        <FontAwesomeIcon fontSize={18} size="sm" icon={faLessThanEqual} />
                                    </MenuItem> {/* Less or Equal */}

                                    <MenuItem value="equals">
                                        <FontAwesomeIcon fontSize={18} size="sm" icon={faEquals} />
                                    </MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    type="number"
                                    value={item.max}
                                    onChange={(e) => updateRange(index, 'max', e.target.value)}
                                    fullWidth
                                    disabled={['greater_than', 'greater_or_equal'].includes(item.type)}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Tooltip title="Delete Color Range">
                                    <IconButton onClick={() => {
                                        const newRanges = [...colorRanges];
                                        if (newRanges.length > 1) {
                                            newRanges.splice(colorRanges.indexOf(item), 1);
                                            setColorRanges(newRanges);
                                            rangeValidation(newRanges)
                                        }
                                        else {
                                            openSnack('error', `At least one color range is required`)
                                        }
                                    }}>
                                        <FontAwesomeIcon fontSize={18} icon={faTrash} color="#3f51b5" />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    )
                })}
                <Grid container justify="center" alignItems="center" style={{ border: '1px dashed gray' }}>
                    <Grid item xs={12}>
                        <Button variant="outlined" color="inherit" fullWidth style={{ textTransform: 'none', border: 'none', height: '40px' }} size="small"
                            onClick={() => {
                                const newRanges = [...colorRanges];
                                /* random rgb color */
                                let random = randomColor({ format: 'rgba', alpha: 1 });
                                console.log(random)
                                newRanges.push({
                                    color: random,
                                    min: 0,
                                    max: 0,
                                    type: 'greater_than',
                                })
                                setColorRanges(newRanges);
                                rangeValidation(newRanges)
                            }

                            }
                        >
                            <Typography fontSize={13} fontWeight={600} variant="body1"> Click to Add Color Range</Typography>
                        </Button>
                    </Grid>

                </Grid>

            </Grid>

        </Grid>
    );
}

export default ColorRanges;
