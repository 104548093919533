import { faArrowRight, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Divider, OutlinedInput, Typography } from '@mui/material';
import { Row, Col } from 'antd';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { fullMobile } from '../../extra/util';
import { getAuth, GoogleAuthProvider, sendPasswordResetEmail, signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { getFirestore, collection, doc, getDoc, setDoc, serverTimestamp, updateDoc } from "firebase/firestore";



function LoginForm({ openSnack }) {
    const [user, setUser] = useState({});
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        const auth = getAuth();
        signInWithEmailAndPassword(auth, user.email, user.password)
            .then(async (userCredential) => {
                // Signed in 
                const user = userCredential.user;
                // ...
                //redirect to home page
                navigate('/');
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage)
                if (errorCode === 'auth/user-not-found') {
                    openSnack('error', 'User not found')
                }
                if (errorCode === 'auth/wrong-password') {
                    openSnack('error', 'Wrong password')
                }


            });
    }

    let navigate = useNavigate()

    const google = 'https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg';


    const ForgotPassword = () => {
        return (
            <Button

                variant="link"
                size="small"
                style={{

                    marginTop: 10,
                    textTransform: 'none',
                    color: 'blue'

                }}

                onClick={() => {
                    //sign in user with email and password
                    const auth = getAuth();
                    const emailAddress = user.email;

                    sendPasswordResetEmail(auth, emailAddress)
                        .then(() => {
                            // Password reset email sent!
                            // ..
                            openSnack('success', `Password reset email sent to ${emailAddress}!`)
                        })
                        .catch((error) => {
                            const errorCode = error.code;
                            const errorMessage = error.message;
                            // ..
                            openSnack('error', errorMessage)
                        });



                }}

            >
                <Typography variant="body1" display="inline" fontWeight={400} fontSize={14} style={{}}>
                    Forgot your password?
                </Typography>
            </Button>
        )
    }

    return (

        <form onSubmit={handleSubmit} style={{ width: '100%' }} >
            <Row style={{ padding: 40 }} >

                <Col {...fullMobile(24, 24)} style={{ textAlign: 'left' }}>
                    <Typography variant="h4" fontWeight={700} style={{ color: 'rgba(0,0,0,0.9)', position: 'relative' }}>
                        Sign in to your account
                    </Typography>


                </Col>
                {/* <Col {...fullMobile(24, 24)} style={{ textAlign: 'left', paddingTop: 10 }}>
                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: '#ff8f00',
                            textTransform: 'none',
                            width: '100%',
                            marginTop: 20,
                            border: '1px solid rgba(0,0,0,0.1)',
                            borderRadius: 5,
                            padding: 10,

                            fontWeight: 600,
                            //on hover change text color to blue
                            '&:hover': {
                                backgroundColor: '#ffa300',

                            },


                        }}
                        onClick={() => {
                            const db = getFirestore();
                            const auth = getAuth();
                            const provider = new GoogleAuthProvider();
                            signInWithPopup(auth, provider)
                                .then(async (result) => {
                                    const user = result.user;
                                    // This gives you a Google Access Token. You can use it to access the Google API.

                                    //check if user exists
                                    const docRef = doc(db, "users", user.uid);
                                    const docSnap = await getDoc(docRef);

                                    if (!docSnap.exists()) {
                                        //create user
                                        const newUser = await createUser(user, 'google')
                                        if (newUser === null) {
                                            navigate('/')
                                        }
                                    }
                                    else {
                                        //update last login
                                        await updateDoc(doc(db, "users", user.uid), {
                                            lastLogin: serverTimestamp(),
                                        }).then(() => {

                                            //redirect to home page
                                            navigate('/');

                                        })
                                            .catch((error) => {

                                            }
                                            );
                                    }
                                }).catch((error) => {
                                    // Handle Errors here.
                                    const errorCode = error.code;
                                    const errorMessage = error.message;
                                    // The email of the user's account used.
                                    const email = error.email;
                                    // The AuthCredential type that was used.
                                    const credential = GoogleAuthProvider.credentialFromError(error);
                                    // ...
                                });




                        }}
                    >
                        <img src={google} style={{ width: 20, height: 20, marginRight: 10 }} />
                        <Typography variant="body1" fontSize={16} fontWeight={600} style={{ color: 'white', position: 'relative' }}>
                            Continue with Google
                        </Typography>

                    </Button>

                </Col> */}
                {/*  <Col {...fullMobile(24, 24)} style={{ textAlign: 'center', paddingTop: 5 }}>
                    <Row>
                        <Col {...fullMobile(8, 8)}>
                            <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                        </Col>
                        <Col {...fullMobile(8, 8)} style={{ textAlign: 'center' }}>
                            <Typography variant="body1" fontSize={12} style={{ color: 'rgba(0,0,0,0.7)', position: 'relative', marginTop: 10, marginBottom: 10 }}>
                                Or sign in with email
                            </Typography>
                        </Col>
                        <Col {...fullMobile(8, 8)}>
                            <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                        </Col>
                    </Row>
                </Col> */}

                <Col {...fullMobile(24, 24)} style={{ textAlign: 'left', paddingTop: 10 }}>
                    <Typography variant="body1" fontWeight={600} fontSize={14} style={{ color: 'rgba(0,0,0,0.7)', position: 'relative', marginTop: 0, marginBottom: 3 }}>
                        Email address
                    </Typography>
                    <OutlinedInput
                        size="small"
                        value={user?.email}
                        onChange={(e) => setUser({ ...user, email: e.target.value })}
                        style={{ width: '100%', borderRadius: 5, }}
                        placeholder="Email"
                    />


                </Col>
                <Col {...fullMobile(24, 24)} style={{ textAlign: 'left', paddingTop: 10 }}>
                    <Typography variant="body1" fontWeight={600} fontSize={14} style={{ color: 'rgba(0,0,0,0.7)', position: 'relative', marginTop: 0, marginBottom: 3 }}>
                        Password
                    </Typography>
                    <OutlinedInput
                        size="small"
                        type="password"
                        value={user?.password}
                        onChange={(e) => setUser({ ...user, password: e.target.value })}
                        style={{ width: '100%', borderRadius: 5, }}
                        placeholder="Password"
                    />


                </Col>
                <Col {...fullMobile(24, 24)} style={{ textAlign: 'left', paddingTop: 10 }}>
                    {loading === 'loggingIn' ?
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            <FontAwesomeIcon icon={faSpinner} spin size='2x' style={{ color: '#404050', position: 'relative', marginTop: 0, marginBottom: 3 }} />
                        </div>
                        :
                        <Button
                            type="submit"
                            variant="contained"
                            style={{
                                textTransform: 'none',
                                color: 'white',
                                width: '100%', marginTop: 20,
                                backgroundColor: '#404050'


                            }}

                            onClick={() => {
                                //sign in user with email and password
                                const auth = getAuth();
                                setLoading('loggingIn')
                                signInWithEmailAndPassword(auth, user.email, user.password)
                                    .then(async (userCredential) => {
                                        // Signed in 
                                        const user = userCredential.user;
                                        // ...
                                        //redirect to home page
                                        navigate('/');
                                        setLoading('')
                                    })
                                    .catch((error) => {
                                        const errorCode = error.code;
                                        const errorMessage = error.message;
                                        console.log(errorCode, errorMessage)
                                        if (errorCode === 'auth/user-not-found') {
                                            openSnack('error', 'User not found')

                                        }
                                        if (errorCode === 'auth/wrong-password') {
                                            openSnack('error', 'Wrong password')
                                        }
                                        setLoading('')


                                    });

                            }}

                        >
                            <Typography variant="body1" display="inline" fontWeight={700} fontSize={16} style={{ color: 'white', position: 'relative', marginTop: 0, marginBottom: 3 }}>
                                Continue with email <FontAwesomeIcon icon={faArrowRight} style={{ marginLeft: 10 }} />
                            </Typography>
                        </Button>
                    }


                </Col>
                <Col {...fullMobile(24, 24)} style={{ textAlign: 'right', paddingTop: 10 }}>
                    <ForgotPassword />


                </Col>
                {/*   <Col {...fullMobile(24, 24)} style={{ textAlign: 'center', paddingTop: 15 }}>
                    <Typography variant="body1" fontSize={14} style={{ color: 'rgba(0,0,0,0.7)', position: 'relative', marginTop: 10, marginBottom: 10 }}>
                        Don't have an account? <Link to="/signup" style={{ color: '#ff8f00' }}>Sign up</Link>
                    </Typography>
                </Col> */}

            </Row>

        </form >

    );
}

export default LoginForm;