import React, { useEffect, useState } from "react";
import { Autocomplete, Box, Grid, TextField, Typography } from '@mui/material';

const ValueCell = (props) => {
    const { header, headers, row, currentCell, keyDown, type, updateRow } = props;

    const editing = currentCell?.selectedID === `${row?.id}??${header.name}` && currentCell !== null;
    const value = Number(row?.[header.name]) || row?.[header.name];

    if (header.name === 'Actual Conductivity-sensor') {
        console.log(value)
    }


    const [result, setResult] = useState(Number(row?.[header.name]) || row?.[header.name]);

    useEffect(() => {
        if (Number(result) !== Number(value)) {
            console.log(result)
            console.log(value)
            console.log("UPDATING HERE VALUE")
            const newRow = { ...row, [header.name]: result };
            updateRow(newRow);

        }
    }, [currentCell]);

    if (editing || type === 'bulk') {
        return (
            <TextField
                sx={{
                    ".inputRoot": {
                        fontSize: 14,
                        fontWeight: 500,
                    },
                    input: {
                        py: 1.5,
                        px: 1
                    },
                }}
                autoFocus={type === 'bulk' ? false : true}
                value={result}
                onChange={(e) => {
                    console.log(e.target.value)
                    setResult(e.target.value);
                    if (type === 'bulk') {
                        const newRow = { ...row, [header.name]: e.target.value };
                        updateRow(newRow);

                    }
                    const newRow = { ...row, [header.name]: e.target.value };
                    updateRow(newRow);
                    
                }}
                onKeyDown={(e) => {
                    if (!['Enter', 'Tab'].includes(e.key)) return;
                    if (type === 'bulk') return;
                    keyDown(e, row);
                }}
            />
        );
    }
    else if (value === '') {
        return (
            <Typography
                variant="body2"
                fontWeight={500}
                fontSize={13}
                color={"red"}
            >
                --
            </Typography>
        );
    }
    else {
        return (
            <Typography
                variant="body2"
                fontWeight={500}
                fontSize={13}
                color={isNaN(value) ? "red" : "inherit"}
            >
                {header?.decimals ? Number(value).toFixed(header?.decimals) : Number(value)}
            </Typography>
        );
    }
};

export default ValueCell;
