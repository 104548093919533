import React from 'react';
import { Col, Row } from 'antd';
import { Breadcrumbs, Button, OutlinedInput, Paper, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { setDefaultOptions, loadModules, } from 'esri-loader';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, uploadString } from "firebase/storage";
import moment from 'moment';
import uuid from 'react-uuid';

import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';

export default class Screenshot extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            map: this.props.map,
            view: this.props.view,
            printScreen: this.props.printScreen,
            userInfo: this.props.userInfo,
            item: this.props.item,

        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.printScreen !== prevProps.printScreen) {
            if (this.props.printScreen === true) {
                this.printScreen()
            }
            this.setState({ printScreen: this.props.printScreen })
        }
        if (this.props.item !== prevProps.item) {
            console.log(this.props.item)
            this.setState({ item: this.props.item })
        }

    }
    componentDidMount() {

    }

    printScreen = () => {
        const { account, map, view, item, userInfo, } = this.state;
        loadModules([])
            .then(([]) => {






                view.takeScreenshot({
                    format: "png",
                    quality: 70
                }).then(async (screenshot) => {

                    console.log(screenshot);

                    const storage = getStorage();
                    const key = uuid();
                    console.log(key);
                    const storageRef = ref(storage, `screenshotMap/${key}`);

                    const blob = await (await fetch(screenshot.dataUrl)).blob();

                    const file = new File([blob], 'fileName.jpg', { type: "image/jpeg", lastModified: new Date() });
                    console.log(storageRef)

                    const uploadTask = uploadBytesResumable(storageRef, file);
                    uploadTask.on('state_changed',
                        (snapshot) => {
                            console.log(snapshot)

                            switch (snapshot.state) {
                                case 'paused':

                                    break;
                                case 'running':

                                    break;
                            }
                        },
                        (error) => {
                            console.log(error)
                        },
                        () => {
                            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                                console.log(downloadURL)
                                this.props.sendImage(downloadURL, item.key,)


                            });
                        }
                    );





                });

            })
    }

    render() {



        const { } = this.state;

        return null;
    }
} 