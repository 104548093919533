import SimpleRenderer from "@arcgis/core/renderers/SimpleRenderer";
import SimpleFillSymbol from "@arcgis/core/symbols/SimpleFillSymbol";
import SimpleMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol";

// Symbol for polygons (and circles)
export const createPolygonSymbol = (layerColor) => {
    return {
        type: "simple-fill", // autocasts as new SimpleFillSymbol()
        color: [
            layerColor?.rgb?.r,
            layerColor?.rgb?.g,
            layerColor?.rgb?.b,
            0.8
        ],  // Use layerColor here
        outline: {
            // autocasts as new SimpleLineSymbol()
            color: "white",
            width: 2
        }
    };
};


export const createPolylineSymbol = (layerColor) => {
    return {
        type: "simple-line",  // autocasts as new SimpleLineSymbol()
        color: layerColor?.rgb,  // Use layerColor here
        width: "4px"
    };
};

export const createPointSymbol = (layerColor) => {
    return {
        type: "simple-marker",  // autocasts as new SimpleMarkerSymbol()
        style: "square",
        color: [
            layerColor?.rgb?.r,
            layerColor?.rgb?.g,
            layerColor?.rgb?.b,
        ],  // Use layerColor here for the marker itself
        size: "10px",  // pixels
        outline: {  // autocasts as new SimpleLineSymbol()
            color: [255, 255, 0], // Consider if you want to control outline color too
            width: 3  // points
        }
    };
};