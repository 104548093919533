import { Button, Divider, OutlinedInput, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import React from 'react';
import uuid from 'react-uuid';
import axios, * as others from 'axios';

export default class MergeData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            oldProbe: '',
            newProbe: ''
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }

    }
    componentDidMount() {
        window.scrollTo(0, 0)
    }


    mergeData = () => {
        const { oldProbe, newProbe } = this.state;

        const checks = [oldProbe, newProbe].filter((f) => f !== '');

        if (checks.length === 2) {
            const proxyUrl = "https://mycorslake.herokuapp.com/";
            axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                raw: `UPDATE probe_data
                SET locationid = '${newProbe}'
                WHERE locationid = '${oldProbe}';`,
            })
                .then(async (response) => {

                    console.log(response)




                })
                .catch((error) => {
                    // handle error
                    console.log(error);


                })
                .then(function () {
                    // always executed
                });
        }
        else {
            this.props.openSnack('error', 'Enter all inputs!')
        }
    }

    addToNew = () => {
        const { oldProbe, newProbe } = this.state;

        const checks = [oldProbe, newProbe].filter((f) => f !== '');

        if (checks.length === 2) {
            const proxyUrl = "https://mycorslake.herokuapp.com/";
            axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                //get all data from probe_data where locationid = oldProbe limit 100
                raw: `SELECT * FROM probe_data WHERE locationid = '${oldProbe}';`,
            })
                .then(async (response) => {
                    console.log(response.data.length)

                    //change the location id for each row to newProbe
                    const data = response.data.map((m) => {
                        return ({
                            time: `'${m.time}'`,
                            timestamp: m.timestamp,
                            locationid: `'${newProbe}'`,
                            unitid: `'${m.unitid}'`,
                            parameterid: `'${m.parameterid}'`,
                            value: m.value,
                            key: `'${uuid()}'`
                        });
                    })

                    const hourlyVals = data.map((h) => `(${Object.values(h)})`);
                    const hourlyString = hourlyVals.join();
                    console.log(hourlyString)

                    axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                        raw: `INSERT INTO probe_data VALUES ${hourlyString} ON CONFLICT DO NOTHING; `
                    })
                        .then(async (response) => {
                            // handle success
                            console.log(response)




                        });



                })
                .catch((error) => {
                    // handle error
                    console.log(error);

                })
        }
    }

    checkData = () => {
        const { oldProbe, newProbe } = this.state;
        const checks = [oldProbe, newProbe].filter((f) => f !== '');

        if (checks.length === 2) {
            const proxyUrl = "https://mycorslake.herokuapp.com/";
            console.log(oldProbe)
            axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                //get all data from probe_data where locationid = oldProbe limit 100
                raw: `SELECT * FROM probe_data WHERE locationid = '${oldProbe}';`,
            })
                .then(async (response) => {
                    console.log(response.data.length)

                }).catch((error) => {
                    console.log(error);
                })
        }
    }

    deleteData = () => {
        const { oldProbe, newProbe } = this.state;
        const checks = [oldProbe, newProbe].filter((f) => f !== '');

        if (checks.length === 2) {
            const proxyUrl = "https://mycorslake.herokuapp.com/";
            axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                //delete all data at oldProbe
                raw: `DELETE FROM probe_data WHERE locationid = '${oldProbe}';`,
            }).then(async (response) => {
                console.log(response)
            }).catch((error) => {
                console.log(error);
            })
        }
    }


    render() {

        const { account, oldProbe, newProbe, screenWidth } = this.state;

        return (
            <Row style={{}}>
                <Col span={23}>
                    <Row align="middle">
                        <Col xs={16} sm={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>
                            <Typography variant="h4">Merge Probe Data</Typography>
                        </Col>
                        <Col xs={6} sm={4} style={{ paddingTop: 30, paddingBottom: 15, textAlign: 'right' }}>

                        </Col>
                        <Col span={24}>
                            <Divider />
                        </Col>

                        <Col span={23} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 30 }}>

                            <Row align="middle" gutter={[12, 12]}>
                                <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                                    <Typography variant="body1">Old Probe<span style={{ color: 'red' }}>*</span></Typography>
                                </Col>
                                <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                    <OutlinedInput fullWidth notched={false} label="none" placeholder={"Old Probe"} size="small"
                                        value={oldProbe}
                                        onChange={(e) => {
                                            this.setState({ oldProbe: e.target.value })
                                        }} />
                                </Col>
                                <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                                    <Typography variant="body1">New Probe<span style={{ color: 'red' }}>*</span></Typography>
                                </Col>
                                <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                    <OutlinedInput fullWidth notched={false} label="none" placeholder={"New Probe"} size="small"
                                        value={newProbe}
                                        onChange={(e) => {
                                            this.setState({ newProbe: e.target.value })
                                        }} />
                                </Col>
                                <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>

                                </Col>
                                <Col xs={16} sm={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>
                                    <Typography variant="h6">This erase old probe's data and bring it over to a new probe</Typography>
                                </Col>

                                <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                    <Button color="primary" variant="contained" style={{ padding: 15, textTransform: 'none' }}
                                        onClick={() => this.mergeData()}>Merge</Button>
                                </Col>

                                <Col xs={16} sm={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>
                                    <Typography variant="h6">This copy old probe's data and bring it over to a new probe</Typography>
                                </Col>

                                <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                    <Button color="primary" variant="contained" style={{ padding: 15, textTransform: 'none' }}
                                        onClick={() => this.addToNew()}>Add To New</Button>
                                </Col>
                                <Col xs={16} sm={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>
                                    <Typography variant="h6">Check for Old Data</Typography>
                                </Col>

                                <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                    <Button color="primary" variant="contained" style={{ padding: 15, textTransform: 'none' }}
                                        onClick={() => this.checkData()}>Check for Old</Button>
                                </Col>
                                <Col xs={16} sm={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>
                                    <Typography variant="h6">Delete Old Data</Typography>
                                </Col>

                                <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                    <Button color="primary" variant="contained" style={{ padding: 15, textTransform: 'none' }}
                                        onClick={() => this.deleteData()}>Delete Old Data</Button>
                                </Col>


                            </Row>

                        </Col>

                    </Row>
                </Col>

            </Row>
        );
    }
} 