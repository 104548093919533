import { CircularProgress, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { fullMobile } from '../../../waterQuality/stations/util';
import { convertFromRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { stateToHTML } from 'draft-js-export-html';
import './editorStyles.css';
import { useRef } from 'react';





export default function WidgetText(props) {
  const { userInfo, screenWidth, openSnack, company, account, attributes, } = props;
  const [loading, setLoading] = useState(true);
  const [item, setItem] = useState({});
  const [openSettings, setOpenSettings] = useState(false);
  const editorRef = useRef();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const db = getFirestore();

  document.querySelectorAll('[data-offset-key]').forEach((element) => {
    element.style.backgroundColor = 'transparent';
  });


  useEffect(() => {
    console.log(attributes);

    document.querySelectorAll('[data-offset-key]').forEach((element) => {
      element.style.backgroundColor = 'transparent';
    });

    if (attributes.transparentBackground) {

    }


    const addEventListenerToEditor = () => {
      if (editorRef.current) {
        const editorContent = editorRef.current.editor.editor;
        editorContent.addEventListener('click', handleLinkClick);
      }
    };

    const removeEventListenerFromEditor = () => {
      if (editorRef.current) {
        const editorContent = editorRef.current.editor.editor;
        editorContent.removeEventListener('click', handleLinkClick);
      }
    };

    if (editorRef.current) {
      addEventListenerToEditor();
    } else {
      const intervalId = setInterval(() => {
        if (editorRef.current) {
          clearInterval(intervalId);
          addEventListenerToEditor();
        }
      }, 100);
    }

    return () => {
      removeEventListenerFromEditor();
    };
  }, []);

  const handleLinkClick = (e) => {
    let target = e.target;
    while (target && target.tagName !== 'A') {
      target = target.parentElement;
    }



    if (target && target.tagName === 'A') {
      e.preventDefault();
      window.open(target.href, '_blank');
    }
  };



  useEffect(() => {

    getInfo();

    if (attributes.text) {
      const contentState = convertFromRaw(JSON.parse(attributes.text));
      setEditorState(EditorState.createWithContent(contentState));
      //get plain text
      const text = contentState.getPlainText();

      if (text === undefined || text === null || text === '') {
        setLoading('noData')
      }
      else {
        setLoading(false);
      }

    }
    else {

      setLoading('noData');
    }
  }, []);



  async function getInfo() {





  }







  const Loading = () => { if (loading) { return <div className="loading"><CircularProgress /> <Typography variant="body1">Loading Data</Typography></div> } }


  const bordercolor = 'rgba(0,0,0,0.3)'

  //if loading true show loading screen
  if (loading == true) { return <Loading /> } else {

    return (
      <Row gutter={[8, 8]} >



        <Col {...fullMobile(24, 24)}>
          {loading === 'noData' && (

            <div style={{
              height: '100%',
              width: '100%',
              justifyContent: 'center',
              display: 'flex',
              alignContent: 'center'
            }}>
              <Typography variant='body1' fontSize={22} fontWeight={600}>
                No Data
              </Typography>
            </div>
          )}
          <Editor
            ref={editorRef}
            editorState={editorState}
            editorStyle={{ backgroundColor: 'transparent' }}
            toolbarHidden={true}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
          />

        </Col>





      </Row >

    );

  }
}