import React from 'react';
import { Col, Row } from 'antd';
import {
    Collapse, Select, MenuItem,
    Autocomplete, Breadcrumbs, Button, OutlinedInput, Paper, TextField, Typography, Input,
    InputAdornment, Checkbox, CircularProgress, List, ListItemButton, ListItemText, Divider
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { getFirestore, doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import uuid from 'react-uuid';
import TypicalMap from './map';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import LayerColor from './layerColor';
import Layer from './layer';
import SearchBar from '../../blocks/gis/searchBar';
import ZoomIcon from '../../blocks/gis/zoomIcon';
import { Map, Scene } from '@esri/react-arcgis';
import Screenshot from '../../blocks/gis/screenshot';
import { setDefaultOptions, loadModules, } from 'esri-loader';
import Location from '../../blocks/gis/location';
loadModules(['esri/config',])
    .then(([config,]) => {
        config.apiKey = "AAPK65ed7997d0524b6582730da31dc1f8986spR1Pjc0oR3qn-NVYrj0jI7Y6156OU89gRRDdPUWC1gjMayxwOkEmxo2aoZH56R";
    })
    .catch(err => {
        // handle any errors
        console.error(err);
    });


export default class AccountMap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            type: this.props.type,
            map: {},
            loading: true,
            openZones: false,
            openSites: false,
            openOther: false,
            currentMapItem: {},
            currentMapLayer: ''
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }

    }
    componentDidMount() {
        window.scrollTo(0, 0);
        const { userInfo } = this.state;
        this.queryMap();
    }

    queryMap = async () => {
        const { type, userInfo } = this.state;
        const db = getFirestore();

        const docRef = doc(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'map', 'map');
        const docSnap = await getDoc(docRef);
        console.log(docSnap.data())

        if (docSnap.exists()) {
            this.setState({ map: docSnap.data(), loading: false })
        }
        else {
            const map = {
                sampleSites: {
                    key: 'sampleSites',
                    visible: 'true',
                    layerColor: {
                        r: '100',
                        g: '161',
                        b: '39',
                        a: '0.5',
                    },
                    graphics: ''
                }, zones: {
                    key: 'zones',
                    visible: 'true',
                    layerColor: {
                        r: '245',
                        g: '100',
                        b: '39',
                        a: '0.5',
                    },
                    graphics: ''
                }, other: {
                    key: 'other',
                    visible: 'true',
                    layerColor: {
                        r: '245',
                        g: '161',
                        b: '100',
                        a: '0.5',
                    },
                    graphics: ''
                }
            };
            const docRef = doc(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'map', 'map');
            await setDoc(docRef, map);
            this.setState({
                map: map, loading: false
            })
        }


    }

    addGraphics = (layer, graphics) => {
        const { map } = this.state;
        map[layer].graphics = graphics;
        this.setState({ map })
    }

    updateColor = (color) => {
        console.log(color)
        const { map, currentMapLayer } = this.state;
        map[currentMapLayer].layerColor = color;
        this.setState({ map })
    }



    render() {

        const { userInfo, item, screenWidth, type, loading, account, map, openZones, openSites, openOther, currentMapItem, currentMapLayer } = this.state;







        return (
            <Row style={{}}>
                <Col span={22}>
                    <Breadcrumbs>
                        <Link to="/" style={{ textDecoration: 'none', color: 'inherit', }}>
                            Accounts
                        </Link>
                        <Link to="/account" style={{ textDecoration: 'none', color: 'inherit', }} >
                            {account.accountName}
                        </Link>



                    </Breadcrumbs>
                </Col>
                <Col offset={2} span={20} style={{ paddingTop: 15 }}>
                    <Typography variant="h5">Account map</Typography>

                </Col>
                <Col span={24} style={{ paddingTop: 15 }}>
                    <Paper elevation={12} style={{ borderRadius: 16, }}>


                        <Row gutter={[8, 8]} style={{ height: '90vh' }} >
                            <Col span={6} style={{ padding: 40, paddingTop: 40 }}>
                                <Row align='middle'>
                                    <Col span={7}>
                                        Layer
                                    </Col>
                                    <Col span={17}>
                                        <Select size="small" fullWidth
                                            notched={false}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={currentMapLayer}
                                            label="Layer"
                                            onChange={(a) => {
                                                this.setState({ currentMapLayer: a.target.value })
                                            }}

                                        >
                                            <MenuItem value={'sampleSites'}>Sample Sites</MenuItem>
                                            <MenuItem value={'zones'}>Zones</MenuItem>
                                            <MenuItem value={'other'}>Other</MenuItem>
                                        </Select>
                                    </Col>

                                    {map[currentMapLayer] !== undefined && map[currentMapLayer].layerColor !== undefined && (<Col span={24} style={{}}>
                                        {currentMapLayer === 'sampleSites' ? "Sample Sites" : currentMapLayer === 'zones' ? "Zones" : 'Other'}
                                        <LayerColor color={map[currentMapLayer].layerColor} updateColor={this.updateColor} />
                                    </Col>)}
                                    <Col span={24}>
                                        <Divider />
                                    </Col>
                                    <Col span={24} style={{}}>
                                        {map[currentMapLayer] !== undefined && map[currentMapLayer].graphics !== '' && (
                                            <Row gutter={[16, 16]} style={{ overflowY: 'auto', maxHeight: 400 }}>

                                                {JSON.parse(map[currentMapLayer].graphics).map((c, i) => {

                                                    return (
                                                        <Col span={24}>
                                                            <Row>
                                                                <Col span={24}>
                                                                    Title: <Input fullWidth
                                                                        autoComplete='off'
                                                                        value={c.attributes.title}
                                                                        onChange={(e) => {
                                                                            const graphics = JSON.parse(map[currentMapLayer].graphics);
                                                                            graphics[i].attributes.title = e.target.value;
                                                                            const string = JSON.stringify(graphics);
                                                                            map[currentMapLayer].graphics = string;
                                                                            this.setState({ map: { ...map } })



                                                                        }}

                                                                    />
                                                                </Col>
                                                                <Col span={24}>
                                                                    Details <br />
                                                                    Lat: {c.attributes.lat.toFixed(6)}<br />
                                                                    Lon: {c.attributes.lon.toFixed(6)}<br />
                                                                    Area: {c.attributes.area.toFixed(2)} {c.attributes.areaUnits}<br />
                                                                    Length: {c.attributes.length.toFixed(3)} {c.attributes.lengthUnits}<br />
                                                                </Col>
                                                                <Col span={24}>
                                                                    <Divider />
                                                                </Col>
                                                            </Row>

                                                        </Col>
                                                    )
                                                })}
                                            </Row>
                                        )}

                                    </Col>

                                </Row>
                            </Col>


                            {loading === false && (
                                  <Col span={18} style={{ display: 'flex', height: '100%', borderRadius: 16, }}>
                                    <Map className="Map" style={{ width: '100%', borderRadius: 16, }}
                                        mapProperties={{ basemap: 'arcgis-imagery' }}

                                        viewProperties={{
                                            center: [account.lng, account.lat],
                                            zoom: 16,
                                            ui: []

                                        }}>

                                        
                                        

                                        <SearchBar map={this.map} view={this.view} />
                                        <ZoomIcon map={this.map} view={this.view} />
                                        <Location map={this.map} view={this.view} />

                                    </Map>
                                </Col>
                            )}


                        </Row>
                    </Paper >

                </Col >


            </Row >
        );
    }
} 