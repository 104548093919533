import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteForever from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Button, CircularProgress, Divider, IconButton, OutlinedInput, TextField, Typography } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import { Col, Row, Steps } from 'antd';
import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';
import moment from 'moment';
import MUIDataTable from "mui-datatables";
import React from 'react';
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import uuid from 'react-uuid';
import "../../extra/hoverStyle.css";
import ProbeParameters from '../../extra/probeParameters.json';
import Units from '../../extra/units.json';
import WeatherParameters from '../../extra/weatherParameters.json';
import UploadData from './uploadData';
import axios, * as others from 'axios';
import queryString from 'query-string';
import QueryStationData from './queryStationData';
import UploadDataNew from './uploadDataNew';

const { Step } = Steps;


export default class QueryData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            company: this.props.company,
            openSnack: this.props.openSnack,
            data: [],
            filteredData: [],
            filters: [],
            searchText: '',
            loading: true,
            type: this.props.type,
            currentItem: {},
            openModal: false,
            probesList: [],
            nextPage: '',
            token: '',
            matchProbe: [],
            activeTab: 'probe',
            step: 'filter',
            keys: [],
            data: [],
            dateFormat: 'MM/DD/YYYY',
            parameterMap: '',
            locationMap: '',
            valueMap: '',
            dateMap: '',
            templates: [],
            previewData: [],
            probeData: [],
            parameters: [],
            sites: [],
            probes: [],
            gauges: [],
            uploading: false,
            parameterFilters: [],
            locationFilters: [],
            gaugeFilters: [],
            querying: [],
            startDate: new Date(moment().subtract('1', 'month').format('L')),
            endDate: new Date(),
            foundFiles: '',
            raw: '',
            sortType: '',
            editRow: undefined,
            unitList: [],
            weatherData: [],
            rainData: [],
            stations: [],
            queryType: 'probe',

        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
        if (this.props.company !== prevProps.company) {
            this.setState({ company: this.props.company })
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0)
        this.getTemplates();
        this.getParameters();
        this.getSites();
        this.getProbes();
        this.getStations()
        this.getGauges();
        const unitList = Object.values(Units).map((u, i) => {
            return ({ label: u, key: u, unitid: Object.keys(Units)[i] })
        })
        this.setState({ unitList: unitList })

        /*  const proxyUrl = "https://mycorslake.herokuapp.com/";
         axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
             raw: 'ALTER TABLE lab_data ADD COLUMN key TEXT NULL;'
         }).then(async (response) => {
             // handle success
             console.log(response)
         }) */



        /*         const rawD = `DELETE FROM lab_data 
                WHERE time < '"2022-09-10 16:00:00"'
                AND locationid IN ('5869835823874048', '5602780260335616', '6365549607780352')
                ; `
        
                console.log(rawD)
                const proxyUrl = "https://mycorslake.herokuapp.com/";
                axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                    raw: rawD
                }).then(async (response) => {
                    // handle success
                    console.log(response)
        
                }).catch((error) => {
                    // handle error
                    console.log(error);
                }) */

    }

    getTemplates = async () => {
        const { userInfo } = this.state;
        const db = getFirestore();

        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'templates',));
        const templates = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            templates.push({ ...doc.data() })


        });
        this.setState({ templates, loading: false })


    }
    getParameters = async () => {
        const { userInfo, type } = this.state;
        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'parameters'));
        const parameters = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            parameters.push({ ...doc.data(), label: doc.data().name })


        });
        parameters.sort((a, b) => a.name.localeCompare(b.name))
        this.setState({ parameters, })


    }
    getSites = async () => {
        const { userInfo, type } = this.state;
        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'sampleSites'), where('account', '==', userInfo.currentAccount),);
        const sites = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            sites.push({ ...doc.data(), label: doc.data().name })


        });
        sites.sort((a, b) => a.name.localeCompare(b.name))
        this.setState({ sites, loading: false })


    }
    getProbes = async () => {
        const { userInfo, type } = this.state;
        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'probes'), where('account', '==', userInfo.currentAccount), where('archived', '==', false));
        const probes = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            probes.push({ ...doc.data(), label: doc.data().name })


        });

        probes.sort((a, b) => a.name.localeCompare(b.name))
        this.setState({ probes, })


    }

    getStations = async () => {
        const { userInfo, type } = this.state;
        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'stations'), where('account', '==', userInfo.currentAccount),);
        const stations = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            if (doc.data().archived !== true && doc.data().children.length > 0) {
                const children = doc.data().children;
                //for each child create a node and add to locations
                children.forEach((child) => {
                    stations.push({ ...child, label: `${doc.data().name}-${child.name}`, type: 'node' })
                })

            }
        })
        console.log(stations)
        stations.sort((a, b) => a.label.localeCompare(b.label))
        this.setState({ stations, })

    }

    getGauges = async () => {
        const { userInfo, type } = this.state;
        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'rainGauges'), where('account', '==', userInfo.currentAccount), where('archived', '==', false));
        const gauges = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            gauges.push({ ...doc.data(), label: doc.data().id })


        });

        gauges.sort((a, b) => a.name.localeCompare(b.name))
        this.setState({ gauges, })


    }

    getLocations = async () => {
        const { userInfo } = this.state;

        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'probes'), where('account', '==', userInfo.currentAccount), where('archived', '==', false));
        const queryDataSites = query(collection(db, "clients", userInfo.currentCompany, 'sampleSites'), where('account', '==', userInfo.currentAccount),);
        const locations = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            locations.push({ ...doc.data(), label: doc.data().name, type: 'probe' })
        });
        const snapSites = await getDocs(queryDataSites);
        snapSites.forEach((doc) => {
            locations.push({ ...doc.data(), label: doc.data().name, type: 'sample' })
        });
        locations.push({ label: 'Weather', key: 'Weather', name: 'Weather', type: 'weather' })
        locations.sort((a, b) => a.name.localeCompare(b.name))
        this.setState({ locations, })

    }



    queryData = async () => {
        const { userInfo, locationMap, parameterMap, parameterFilters, locationFilters } = this.state;
        this.setState({ querying: true })

        const params = parameterFilters.map((p) => p.name);
        const locals = locationFilters.map((p) => p.name);




        const paramsList = params.map(p => `'${p}'`);
        const localsList = locals.map(p => `'${p}'`);
        const paramsWrapped = `(${paramsList.join(',')})`
        const localsWrapped = `(${localsList.join(',')})`




        const both = `SELECT time, timestamp, value, locationlabel, locationid, parameterid, parameterlabel, account, company, meta, key FROM lab_data WHERE parameterlabel IN ${paramsWrapped} AND locationlabel IN ${localsWrapped} AND account = '${userInfo.currentAccount}'; `
        const localRaw = `SELECT time, timestamp, value, locationlabel, locationid, parameterid, parameterlabel, account, company, meta, key FROM lab_data WHERE locationlabel IN ${localsWrapped} AND account = '${userInfo.currentAccount}'; `
        const paramRaw = `SELECT time, timestamp, value, locationlabel, locationid, parameterid, parameterlabel, account, company, meta, key FROM lab_data WHERE parameterlabel IN ${paramsWrapped} AND account = '${userInfo.currentAccount}'; `

        const raw = params.length > 0 && locals.length > 0 ? both :
            params.length === 0 && locals.length > 0 ? localRaw :
                params.length > 0 && locals.length === 0 ? paramRaw : `SELECT time, timestamp, value, locationlabel, locationid, parameterid, parameterlabel, account, company, key FROM lab_data WHERE account = '${userInfo.currentAccount}';`




        this.queryThis(raw)





    }
    queryProbeData = async (type) => {
        const { userInfo, locationMap, parameterMap, parameterFilters, locationFilters, startDate, endDate } = this.state;
        this.setState({ querying: true })

        const params = parameterFilters.map((p) => p.key);
        const locals = locationFilters.map((p) => p.id);




        const paramsList = params.map(p => `'${p}'`);
        const localsList = locals.map(p => `'${p}'`);
        const paramsWrapped = `(${paramsList.join(',')})`
        const localsWrapped = `(${localsList.join(',')})`



        const items = type === 'probe' ? 'time, timestamp, locationid, parameterid, value, key, unitid' : 'time, timestamp, locationid, parameterid, value, key, nodeid, unitid';


        const start = Number(moment(startDate).format('X'));
        const end = Number(moment(endDate).format('X'));


        const both = `SELECT ${items} FROM ${type === 'probe' ? 'probe_data' : 'node_data_new'} WHERE parameterid IN ${paramsWrapped} AND  ${type === 'probe' ? 'locationid' : 'nodeid'} IN ${localsWrapped} and timestamp >= ${start} 
        and timestamp <= ${end}; `
        const localRaw = `SELECT ${items} FROM ${type === 'probe' ? 'probe_data' : 'node_data_new'} WHERE ${type === 'probe' ? 'locationid' : 'nodeid'} IN ${localsWrapped} and timestamp >= ${start}
        and timestamp <= ${end}; `
        const paramRaw = `SELECT ${items} FROM ${type === 'probe' ? 'probe_data' : 'node_data_new'} WHERE parameterid IN ${paramsWrapped} and timestamp >= ${start} 
        and timestamp <= ${end}; `

        const raw = params.length > 0 && locals.length > 0 ? both :
            params.length === 0 && locals.length > 0 ? localRaw :
                params.length > 0 && locals.length === 0 ? paramRaw : `SELECT time, timestamp, value, locationlabel, locationid, parameterid, parameterlabel, 
                account, company FROM ${type === 'probe' ? 'probe_data' : 'node_data_new'} WHERE timestamp >= ${start} 
                and timestamp <= ${end};`




        this.queryProbeThis(raw, type)





    }


    queryWeatherData = async () => {
        const { userInfo, locationMap, parameterMap, parameterFilters, locationFilters, startDate, endDate } = this.state;
        this.setState({ querying: true })

        const items = 'time, timestamp, temp';

        const start = moment(startDate).format('YYYY-MM-DD HH:mm:ss');
        const end = moment(endDate).format('YYYY-MM-DD HH:mm:ss');

        console.log(start)
        console.log(end)
        const both = `SELECT time 
        ,account, temp
        FROM weather_data
        WHERE account = '${userInfo.currentAccount}'
        and time >= '${start}' 
            and time <= '${end}' 
        ;`


        const raw = both;




        this.queryWeatherThis(raw)

    }
    queryRainData = async () => {
        const { userInfo, locationMap, parameterMap, parameterFilters, locationFilters, startDate, endDate, gaugeFilters } = this.state;
        this.setState({ querying: true })

        const items = 'time, timestamp, temp';

        const start = moment(startDate).format('YYYY-MM-DD HH:mm:ss');
        const end = moment(endDate).format('YYYY-MM-DD HH:mm:ss');
        const locals = gaugeFilters.map((p) => p.id);
        const localsList = locals.map(p => `'${p}'`);
        const localsWrapped = `(${localsList.join(',')})`

        console.log(start)
        console.log(end)
        const both = `SELECT * 
        FROM gauge_data
        WHERE account = '${userInfo.currentAccount}' and
        station IN ${localsWrapped}
        and time >= '${start}' 
            and time <= '${end}' 
        ;`


        const raw = both;




        this.queryRainThis(raw)

    }



    queryThis = (raw) => {

        const proxyUrl = "https://mycorslake.herokuapp.com/";
        axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
            raw
        }).then(async (response) => {
            // handle success
            console.log(response)
            const { data } = response;

            this.setState({
                foundFiles: response.data.length,
            })
            setTimeout(() => {

                const datalist = response.data.map((d) => {
                    const key = d.key || uuid();
                    if ([undefined, null].includes(d.key)) {

                        const proxyUrl = "https://mycorslake.herokuapp.com/";
                        axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                            raw: `UPDATE lab_data SET 
                            key = '${key}'
                            WHERE time = '${d.time}'
                            AND parameterid = '${d.parameterid}'
                            AND locationid = '${d.locationid}'
                            ; `
                        }).then(async (response) => {
                            // handle success
                            console.log(response)
                        }).catch((error) => {
                            console.log(error)
                        })
                    }
                    return ({ ...d, key, edit: false, edited: false })
                })
                //sort datalist by timestamp
                datalist.sort((a, b) => b.timestamp - a.timestamp)

                this.setState({ foundFiles: '', querying: false, previewData: datalist })

            }, 50);



            this.props.openSnack('success', 'Data Queryed!')
        }).catch((error) => {
            console.log(error)
        })

    }

    queryProbeThis = (raw, type) => {
        console.log(raw)
        const proxyUrl = "https://mycorslake.herokuapp.com/";
        axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
            raw
        }).then(async (response) => {
            // handle success

            const { data } = response;

            this.setState({
                foundFiles: response.data.length,
            })
            setTimeout(() => {
                console.log(response.data)
                const datalist = response.data.map((d) => {
                    const key = d.key === 'null' ? uuid() : d.key === null ? uuid() : d.key === undefined ? uuid() : d.key;
                    if ([undefined, null, "null"].includes(d.key)) {
                        const location = type === 'probe' ? d.locationid : d.nodeid;
                        const proxyUrl = "https://mycorslake.herokuapp.com/";
                        axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                            raw: `UPDATE ${type === 'probe' ? 'probe_data' : 'node_data_new'} SET 
                            key = '${key}'
                            WHERE time = '${d.time}'
                            AND parameterid = '${d.parameterid}'
                            AND ${type === 'probe' ? 'locationid' : 'nodeid'} = '${location}'
                            ; `
                        }).then(async (response) => {
                            // handle success
                            console.log(response)
                        }).catch((error) => {
                            console.log(error)
                        })
                    }
                    return ({ ...d, key, edit: false, edited: false })


                })
                //sort datalist by timestamp
                datalist.sort((a, b) => b.timestamp - a.timestamp)

                this.setState({ foundFiles: '', querying: false, probeData: datalist, queryType: type })

            }, 50);



            this.props.openSnack('success', 'Data Queryed!')
        }).catch((error) => {
            console.log(error)
        })

    }
    queryWeatherThis = (raw) => {

        const proxyUrl = "https://mycorslake.herokuapp.com/";
        axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
            raw
        }).then(async (response) => {
            // handle success
            console.log(response)
            const { data } = response;

            this.setState({
                foundFiles: response.data.length,
            })
            setTimeout(() => {

                const datalist = response.data.map((d) => { return ({ ...d, key: uuid(), edit: false, edited: false }) })
                //sort datalist by timestamp
                datalist.sort((a, b) => b.timestamp - a.timestamp)

                this.setState({ foundFiles: '', querying: false, weatherData: datalist })

            }, 50);



            this.props.openSnack('success', 'Data Queryed!')
        }).catch((error) => {
            console.log(error)
        })

    }
    queryRainThis = (raw) => {

        const proxyUrl = "https://mycorslake.herokuapp.com/";
        axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
            raw
        }).then(async (response) => {
            // handle success
            console.log(response)
            const { data } = response;

            this.setState({
                foundFiles: response.data.length,
            })
            setTimeout(() => {

                const datalist = response.data.map((d) => { return ({ ...d, edit: false, edited: false }) })
                //sort datalist by timestamp
                datalist.sort((a, b) => b.timestamp - a.timestamp)

                this.setState({ foundFiles: '', querying: false, rainData: datalist })

            }, 50);



            this.props.openSnack('success', 'Data Queryed!')
        }).catch((error) => {
            console.log(error)
        })

    }

    updateData = (key, boo, val) => {
        const { previewData, tempData, testKey } = this.state;

        const index = previewData.findIndex((f) => f.key === key);
        previewData[index][boo] = val;
        this.setState({ previewData })


    }

    updateProbeData = (key, boo, val) => {
        const { probeData, tempData, testKey, queryType } = this.state;

        const index = probeData.findIndex((f) => f.key === key);
        probeData[index][boo] = val;
        this.setState({ probeData })


    }
    updateRainData = (key, boo, val) => {
        const { rainData, tempData, testKey } = this.state;

        const index = rainData.findIndex((f) => f.key === key);
        rainData[index][boo] = val;
        this.setState({ rainData })


    }


    saveData = (item) => {
        const { previewData, userInfo } = this.state;


        const d = item;
        const checks = [d.oldTime === d.time, d.oldLocationid === d.locationid, d.oldParameterid === d.parameterid].filter((f) => f === true)



        if (checks.length === 3) {
            const raw = `UPDATE lab_data SET 
        value = '${d.value}'
        WHERE time = '${d.time}'
        AND parameterid = '${d.parameterid}'
        AND locationid = '${d.locationid}'
        ; `

            const proxyUrl = "https://mycorslake.herokuapp.com/";
            axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                raw
            }).then(async (response) => {
                // handle success
                this.updateData(d.key, 'edited', false)
                this.updateData(d.key, 'edit', false)
                this.props.openSnack('success', 'Data Saved!')
            }).catch((error) => console.log(error))
        }
        else {




            const item = {
                time: `'${d.time}'`,
                timestamp: d.timestamp,
                locationid: `'${d.locationid}'`,
                locationlabel: `'${d.locationlabel}'`,
                unitid: `'units'`,
                parameterid: `'${d.parameterid}'`,
                parameterlabel: `'${d.parameterlabel}'`,
                nd: `'${d.value === "ND" ? "true" : 'false'}'`,
                meta: `'${JSON.stringify(d)}'`,
                account: `'${d.account}'`,
                company: `'${d.company}'`,
                value: Number(d.value === "ND" ? "0" : d.value),
                logkey: `'123'`
            }



            const hourlyVals = [item].map((h) => `(${Object.values(h)})`);
            const hourlyString = hourlyVals.join();



            const proxyUrl = "https://mycorslake.herokuapp.com/";
            axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                raw: `INSERT INTO lab_data VALUES ${hourlyString}; `
            }).then(async (response) => {
                // handle success
                const rawD = `DELETE FROM lab_data 
        WHERE time = '${d.oldTime || d.time}'
        AND parameterid = '${d.oldParameterid || d.parameterid}'
        AND locationid = '${d.oldLocationid || d.locationid}'
        ; `


                const proxyUrl = "https://mycorslake.herokuapp.com/";
                axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                    raw: rawD
                }).then(async (response) => {
                    // handle success
                    this.updateData(d.key, 'edited', false)
                    this.updateData(d.key, 'edit', false)
                    this.props.openSnack('success', 'Data Saved!')

                })
            }).catch((error) => console.log(error))


        }





    }

    saveProbeData = (item) => {
        const { previewData, userInfo, queryType } = this.state;


        const d = item;
        const checks = [d.oldTime === d.time, d.oldLocationid === d.locationid, d.oldParameterid === d.parameterid].filter((f) => f === true)




        if (checks.length === 3) {
            const raw = `UPDATE ${queryType === 'probe' ? 'probe_data' : 'node_data_new'} SET 
        value = '${d.value}'
        WHERE time = '${d.time}'
        AND parameterid = '${d.parameterid}'
        AND locationid = '${d.locationid}'
        ; `


            const proxyUrl = "https://mycorslake.herokuapp.com/";
            axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                raw
            }).then(async (response) => {
                // handle success
                this.updateProbeData(d.key, 'edited', false)
                this.updateProbeData(d.key, 'edit', false)
                this.props.openSnack('success', 'Data Saved!')
            }).catch((error) => console.log(error))
        }
        else {




            const item = {
                time: `'${d.time}'`,
                timestamp: d.timestamp,
                locationid: `'${d.locationid}'`,
                unitid: `'units'`,
                parameterid: `'${d.parameterid}'`,
                value: Number(d.value === "ND" ? "0" : d.value),

            }




            const hourlyVals = [item].map((h) => `(${Object.values(h)})`);
            const hourlyString = hourlyVals.join();



            const proxyUrl = "https://mycorslake.herokuapp.com/";
            axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                raw: `INSERT INTO  ${queryType === 'probe' ? 'probe_data' : 'node_data_new'} VALUES ${hourlyString}; `
            }).then(async (response) => {
                // handle success
                const rawD = `DELETE FROM  ${queryType === 'probe' ? 'probe_data' : 'node_data_new'} 
        WHERE time = '${d.oldTime || d.time}'
        AND parameterid = '${d.oldParameterid || d.parameterid}'
        AND locationid = '${d.oldLocationid || d.locationid}'
        ; `


                const proxyUrl = "https://mycorslake.herokuapp.com/";
                axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                    raw: rawD
                }).then(async (response) => {
                    // handle success
                    this.updateProbeData(d.key, 'edited', false)
                    this.updateProbeData(d.key, 'edit', false)
                    this.props.openSnack('success', 'Data Saved!')

                })
            }).catch((error) => console.log(error))


        }





    }

    saveRainData = (item) => {
        const { previewData, userInfo } = this.state;


        const d = item;
        const checks = [d.oldTime === d.time,].filter((f) => f === true)




        if (checks.length === 1) {
            console.log(d.value)
            console.log(d.key)
            const raw = `UPDATE gauge_data SET 
        value = '${d.value}'
        WHERE key = '${d.key}'
        ; `


            const proxyUrl = "https://mycorslake.herokuapp.com/";
            axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                raw
            }).then(async (response) => {
                // handle success
                this.updateRainData(d.key, 'edited', false)
                this.updateRainData(d.key, 'edit', false)
                this.props.openSnack('success', 'Data Saved!')
            }).catch((error) => console.log(error))
        }
        else {




            const item1 = {
                time: `'${d.time}'`,
                account: `'${d.account}'`,
                company: `'${d.company}'`,
                key: `'${uuid()}'`,
                datatype: `'${d.datatype}'`,
                station: `'${d.station}'`,
                attributes: `'123'`,
                value: Number(d.value),
                lat: d.lat,
                lng: d.lng


            }




            const hourlyVals = [item1].map((h) => `(${Object.values(h)})`);
            const hourlyString = hourlyVals.join();



            const proxyUrl = "https://mycorslake.herokuapp.com/";
            axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                raw: `INSERT INTO gauge_data VALUES ${hourlyString}; `
            }).then(async (response) => {
                // handle success
                const rawD = `DELETE FROM gauge_data 
        WHERE key = '${item.key}'
        ; `


                const proxyUrl = "https://mycorslake.herokuapp.com/";
                axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                    raw: rawD
                }).then(async (response) => {
                    // handle success
                    this.updateRainData(d.key, 'edited', false)
                    this.updateRainData(d.key, 'edit', false)
                    this.props.openSnack('success', 'Data Saved!')

                })
            }).catch((error) => console.log(error))


        }





    }


    updateTimeframe = (time, units) => {
        const { startDate, endDate } = this.state;
        this.setState({
            startDate: new Date(moment().subtract(time, units).format('L')),
            endDate: new Date()
        })


    }

    handleCheck = (index, boo, val,) => {
        // Clears running timer and starts a new one each time the user types
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.toggleCheck(index, boo, val,);
        }, 800);
    }

    toggleCheck = async (index, boo, val) => {
        this.updateData(index, boo, val)
        this.updateData(index, 'edited', true)
    }


    handleData = (items) => {
        // Clears running timer and starts a new one each time the user types

        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            const key = Object.values(items)[0];

            Object.keys(items).map((item, i) => {
                if (item !== 'key') {

                    this.toggleData(key, item, Object.values(items)[i]);
                }
            })



        }, 800);
    }


    toggleData = async (key, boo, val) => {
        const { previewData } = this.state;
        const index = previewData.findIndex((f) => f.key === key)


        previewData[index][boo] = val;
        this.saveData(previewData[index])
        this.setState({ previewData })

    }
    handleProbeData = (items) => {
        // Clears running timer and starts a new one each time the user types
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            const key = Object.values(items)[0];

            Object.keys(items).map((item, i) => {
                if (item !== 'key') {

                    this.toggleProbeData(key, item, Object.values(items)[i]);
                }
            })



        }, 250);
    }

    toggleProbeData = async (key, boo, val) => {
        const { probeData } = this.state;
        const index = probeData.findIndex((f) => f.key === key)

        probeData[index][boo] = val;
        this.saveProbeData(probeData[index])
        this.setState({ probeData })

    }
    handleRainData = (items) => {
        // Clears running timer and starts a new one each time the user types
        const key = Object.values(items)[0];
        Object.keys(items).map((item, i) => {
            if (item !== 'key') {

                this.toggleRainData(key, item, Object.values(items)[i]);
            }
        })
    }

    toggleRainData = async (key, boo, val) => {
        const { rainData } = this.state;
        const index = rainData.findIndex((f) => f.key === key)

        rainData[index][boo] = val;
        this.saveRainData(rainData[index])
        this.setState({ rainData })

    }


    getMuiTheme = () =>
        createTheme({
            components: {
                MUIDataTable: {
                    styleOverrides: {
                        root: {

                            boxShadow: 'none'
                        }
                    }
                },
                MuiTableRow: {
                    styleOverrides: {
                        root: {
                            zIndex: 100,
                            cursor: 'pointer',
                            fontSize: '10px',
                            '&:nth-child(even)': {
                                backgroundColor: 'rgba(0,0,0,0.08)'
                            },
                            padding: 3

                        },
                        hover: {
                            backgroundColor: 'green'
                        }

                    }
                },
                MuiTableCell: {
                    styleOverrides: {
                        root: {
                            fontSize: '12px',
                            padding: 3,
                        }
                    }
                },
                MuiAutocomplete: {
                    styleOverrides: {
                        root: {
                            fontSize: '12px',
                        },
                        input: {
                            fontSize: '12px',
                        },


                    }
                },
                MuiInputBase: {
                    styleOverrides: {
                        root: {
                            fontSize: '12px',
                        }
                    }
                },
                MuiInput: {
                    styleOverrides: {
                        root: {
                            fontSize: '12px',

                        },

                    }
                }

            }
        });


    deleteData = async (item, index) => {
        const { previewData, tempData, testKey } = this.state;
        const d = item;

        const proxyUrl = "https://mycorslake.herokuapp.com/";
        axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
            raw: `DELETE FROM lab_data 
            WHERE key IN (${[d.key].map((e) => `'${e}'`).join(',')});`
        }).then(async (response) => {
            // handle success

            this.props.openSnack('success', 'Data deleted!')

            previewData.splice(index, 1)
            this.setState({ previewData })



        }).catch((error) => {
            console.log(error)
        })



    }
    deleteProbeData = async (item, index) => {
        const { probeData, tempData, testKey, queryType } = this.state;
        const d = item;

        const proxyUrl = "https://mycorslake.herokuapp.com/";
        axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
            raw: `DELETE FROM ${queryType === 'probe' ? 'probe_data' : 'node_data_new'} 
            WHERE key IN (${[d.key].map((e) => `'${e}'`).join(',')});`
        }).then(async (response) => {
            // handle success

            this.props.openSnack('success', 'Data deleted!')

            const filteredData = probeData.filter((f) => f.key !== d.key)
            this.setState({ probeData: filteredData })




        }).catch((error) => {
            console.log(error)
        })



    }



    render() {

        const { company, openSnack, queryType, stations, userInfo, account, activeTab, step, probeData, keys, data, dateFormat, dateMap, unitList, valueMap, parameterMap, locationMap, screenWidth, templates, uploading,
            previewData, parameters, sites, parameterFilters, locationFilters, gaugeFilters, querying, probes, gauges, startDate, endDate, foundFiles, sortType, editRow, weatherData, rainData } = this.state;
        const db = getFirestore();

        const filterOptions = createFilterOptions({
            matchFrom: 'start',
            stringify: (option) => option.label,
        });

        const checks = [
            Object.values(parameterMap).length > 0 ? true : false,
            Object.values(locationMap).length > 0 ? true : false,
        ]


        const PPArray = Object.values(ProbeParameters).map((p, i) => {
            return ({
                label: p,
                key: Object.keys(ProbeParameters)[i]
            })
        });

        const WPArray = Object.values(WeatherParameters).map((p, i) => {
            return ({
                label: p,
                key: Object.keys(WeatherParameters)[i]
            })
        });





        const options = {
            filter: false,
            searchable: false,
            search: false,
            searchable: false,
            viewColumns: false,
            download: false,
            print: false,
            draggable: true,
            rowsPerPage: 50,
            rowsPerPageOptions: [10, 50, 100],
            selectableRows: screenWidth === 'xs' ? false : true,
            filterType: 'dropdown',
            responsive: 'stacked',
            onRowClick: (rowData, rowMeta, b) => {


                const index = previewData.map((f, i) => i).indexOf(rowMeta.dataIndex);
                const d = previewData[index];

                if (d.key !== editRow) {

                    this.updateData(d.key, 'edit', !d.edit);
                    this.updateData(d.key, 'oldTime', d.time);
                    this.updateData(d.key, 'oldTimestamp', d.timestamp);
                    this.updateData(d.key, 'oldParameterid', d.parameterid);
                    this.updateData(d.key, 'oldLocationid', d.locationid);

                    this.setState({ editRow: this.state.editRow === d.key ? undefined : d.key })
                }


            },
            onRowsDelete: (rows, b) => {
                console.log(b)
                const remainingKeys = b.map((e) => e[0]);
                const deletedData = previewData.filter((e) => !remainingKeys.includes(e.key));

                const deletedKeys = deletedData.map((e) => e.key);

                console.log(deletedKeys)
                const proxyUrl = "https://mycorslake.herokuapp.com/";
                axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                    raw: `DELETE FROM lab_data 
                    WHERE key IN (${deletedKeys.map((e) => `'${e}'`).join(',')});`

                }).then(async (response) => {
                    // handle success

                    this.props.openSnack('success', 'Data deleted!')
                    const newData = previewData.filter((e) => !deletedKeys.includes(e.key));
                    this.setState({ previewData: newData })




                }).catch((error) => {
                    console.log(error)
                })


            }
        };



        const keyColumn = 0;


        const columns = [

            {
                name: 'key',
                label: "Edit",
                options: {
                    filter: false,
                    display: true,
                    sort: false,
                    empty: true,
                    customBodyRender: (value, b, updateValue) => {
                        const index = previewData.map((e) => e.key).indexOf(value);
                        const d = previewData[index];



                        return (
                            <div>
                                <IconButton size="small" aria-label="delete" color={editRow === b.rowData[keyColumn] ? 'primary' : 'inherit'} onClick={() => {

                                    this.updateData(b.rowData[keyColumn], 'edit', !d.edit);
                                    this.updateData(b.rowData[keyColumn], 'oldTime', d.time);
                                    this.updateData(b.rowData[keyColumn], 'oldTimestamp', d.timestamp);
                                    this.updateData(b.rowData[keyColumn], 'oldParameterid', d.parameterid);
                                    this.updateData(b.rowData[keyColumn], 'oldLocationid', d.locationid);

                                    this.setState({ editRow: this.state.editRow === b.rowData[keyColumn] ? undefined : b.rowData[keyColumn] })
                                }}>
                                    <EditIcon />
                                </IconButton>

                            </div>

                        );

                    }
                }
            },
            {
                name: "time",
                label: 'Date',
                options: {
                    filter: false,

                    customBodyRender: (a, b, c) => {

                        const index = previewData.map((e) => e.key).indexOf(b.rowData[keyColumn]);
                        const time = previewData[index].time
                        if (editRow === b.rowData[keyColumn]) {

                            return (

                                <DatePicker dateFormat="MM/dd/yyyy hh:mm a" style={{ width: '200px' }} showTimeSelect timeFormat="h:mm a" timeIntervals={1}
                                    timeCaption="Time"
                                    portalId="root-portal"
                                    selected={[undefined, '', "Invalid date"].includes(time) ? new Date() :
                                        new Date(moment(time, 'YYYY-MM-DD HH:mm').format('MM/DD/YYYY hh:mm a'))}
                                    onChange={(date) => {


                                        const unix = moment(date).format('x');


                                        const changeItems = {
                                            key: b.rowData[keyColumn],
                                            time: moment(unix, 'x').tz("America/Los_Angeles").format("YYYY-MM-DD HH:mm"),
                                            timestamp: Number(moment(unix, 'x').format('X')),
                                            edit: true
                                        }
                                        this.handleData(changeItems)



                                    }} />

                            )
                        }

                        else {
                            return (
                                <div>{moment(time, 'YYYY-MM-DD HH:mm').format('MM/DD/YYYY h:mm a')}</div>
                            )
                        }
                    }
                }
            },
            {
                name: "parameterid",
                label: "Parameter",
                options: {
                    filter: false,
                    customBodyRender: (a, b, c) => {

                        if (editRow === b.rowData[keyColumn]) {
                            const index = parameters.map((e) => e.key).indexOf(a);
                            const label = index === -1 ? '' : parameters[index].label;
                            return (

                                <Autocomplete
                                    size="small"
                                    id="combo-box-demo"
                                    defaultValue={label}
                                    disableClearable={true}
                                    options={parameters}
                                    filterOptions={(options, _ref) => [...filterOptions(options, _ref), { label: 'Add New', key: 'Add New', value: 'addNew' },]}
                                    noOptionsText={<Button fullWidth variant='contained' color='primary' style={{ textTransform: 'none' }}
                                        onClick={() => {
                                            this.setState({ parameterModal: true })
                                        }}
                                    >+ Create Site</Button>}
                                    renderInput={(params) => <TextField {...params} label="Parameters" size="small" />}
                                    renderOption={(params) => {

                                        if (params.key === 'Add New') {
                                            return (
                                                <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 4 }}>
                                                    <Button fullWidth variant='contained' color='primary' style={{ textTransform: 'none' }} onClick={(e) => {
                                                        this.setState({ parameterModal: true })
                                                        this.setParameterItem({ tempKey: uuid(), depthFromBottom: 0 });


                                                    }}>+ Create Parameter</Button>
                                                </Box>

                                            )
                                        }
                                        else {
                                            return (
                                                <Typography {...params}>{params.key}</Typography>
                                            )
                                        }
                                    }}
                                    onChange={(a, d) => {

                                        const changeItems = {
                                            key: b.rowData[keyColumn],
                                            parameterLabel: d.label,
                                            parameterid: d.key,
                                            edit: true,
                                            parameterUnits: d.unitLabel || '',
                                            parameterBorder: null,

                                        }
                                        this.handleData(changeItems)



                                    }}

                                />



                            )
                        }

                        else {
                            const index = parameters.map((e) => e.key).indexOf(a);
                            const label = index === -1 ? '' : parameters[index].label;
                            return (
                                <div>{label}</div>
                            )
                        }
                    }
                }
            },
            {
                name: "locationid",
                label: "Site",
                options: {
                    filter: false,
                    customBodyRender: (a, b, c) => {

                        if (editRow === b.rowData[keyColumn]) {

                            const index = sites.map((e) => e.key).indexOf(a);

                            const label = index === -1 ? '' : sites[index].label;

                            return (



                                <Autocomplete
                                    size="small"
                                    id="combo-box-demo"
                                    disableClearable={true}
                                    value={label}
                                    options={sites}
                                    filterOptions={(options, _ref) => [...filterOptions(options, _ref), { label: 'Add New', key: 'Add New', value: 'addNew' },]}
                                    noOptionsText={<Button fullWidth variant='contained' color='primary' style={{ textTransform: 'none' }}
                                        onClick={() => {
                                            this.setState({ siteModal: true, siteItem: { tempKey: uuid() } })
                                        }}
                                    >+ Create Site</Button>}
                                    renderInput={(params) => <TextField {...params} label="Site" size="small" />}
                                    renderOption={(params) => {

                                        if (params.key === 'Add New') {
                                            return (
                                                <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 4 }}>
                                                    <Button fullWidth variant='contained' color='primary' style={{ textTransform: 'none' }} onClick={(e) => {
                                                        this.setState({ siteModal: true, siteItem: { tempKey: uuid(), } })



                                                    }}>+ Create Site</Button>
                                                </Box>

                                            )
                                        }
                                        else {
                                            return (
                                                <Typography {...params}>{params.key}</Typography>
                                            )
                                        }
                                    }}
                                    onChange={(a, d) => {

                                        if (b.key === 'Add New') {
                                            this.setState({ siteModal: true, siteItem: { tempKey: uuid(), } })
                                        }
                                        else {

                                            const changeItems = {
                                                key: b.rowData[keyColumn],
                                                locationLabel: d.label,
                                                locationid: d.key,
                                                edit: true,
                                                locationBorder: null,

                                            }
                                            this.handleData(changeItems)


                                        }

                                    }}

                                />
                            )
                        }

                        else {
                            const index = sites.map((e) => e.key).indexOf(a);
                            const label = index === -1 ? '' : sites[index].label;
                            return (
                                <div>{label}</div>
                            )
                        }
                    }
                }
            },
            {
                name: "value",
                label: "Value",
                options: {
                    filter: false,

                    customBodyRender: (a, b, c) => {

                        if (editRow === b.rowData[keyColumn]) {

                            return (

                                <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                    id={`${b.rowData[keyColumn]}value`}
                                    defaultValue={a}
                                    type="number"
                                    onChange={(e) => {
                                        const changeItems = {
                                            key: b.rowData[keyColumn],
                                            value: e.target.value === "" ? "" : Number(e.target.value),
                                            edit: true,

                                        }
                                        this.handleData(changeItems)
                                    }} />
                            )
                        }

                        else {
                            return (
                                <div>{a}</div>
                            )
                        }
                    }
                }
            },
            {
                name: "unitid",
                label: "Units",
                options: {
                    filter: false,
                    customBodyRender: (a, b, c) => {

                        if (editRow === b.rowData[keyColumn]) {
                            const index = unitList.map((e) => e.unitid).indexOf(a);
                            const label = index === -1 ? '' : unitList[index].label;
                            return (

                                <Autocomplete
                                    size="small"
                                    id="combo-box-demo"
                                    disableClearable={true}
                                    value={label}
                                    options={unitList}

                                    renderInput={(params) => <TextField {...params} label="Units" size="small" />}
                                    renderOption={(params) => {
                                        return (
                                            <Typography {...params}>{params.key}</Typography>
                                        )
                                    }}
                                    onChange={(a, d) => {
                                        const changeItems = {
                                            key: b.rowData[keyColumn],
                                            unitid: d.key,
                                            edit: true,

                                        }
                                        this.handleData(changeItems)
                                    }}
                                />



                            )
                        }

                        else {
                            const index = unitList.map((e) => e.unitlist).indexOf(a);
                            const label = index === -1 ? '' : unitList[index].label;
                            return (
                                <div>{label}</div>
                            )
                        }
                    }
                }
            },





        ];



        const probeOptions = {
            filter: false,
            searchable: false,
            search: false,
            searchable: false,
            viewColumns: false,
            download: false,
            print: false,
            draggable: true,
            rowsPerPage: 50,
            rowsPerPageOptions: [10, 50, 100],
            selectableRows: screenWidth === 'xs' ? false : true,
            filterType: 'dropdown',
            responsive: 'stacked',
            onRowClick: (rowData, rowMeta, b) => {


                const index = probeData.map((f, i) => i).indexOf(rowMeta.dataIndex);
                const d = probeData[index];

                if (d.key !== editRow) {

                    this.updateProbeData(d.key, 'edit', !d.edit);
                    this.updateProbeData(d.key, 'oldTime', d.time);
                    this.updateProbeData(d.key, 'oldTimestamp', d.timestamp);
                    this.updateProbeData(d.key, 'oldParameterid', d.parameterid);
                    this.updateProbeData(d.key, 'oldLocationid', d.locationid);

                    this.setState({ editRow: this.state.editRow === d.key ? undefined : d.key })
                }


            },
            onRowsDelete: (rows, b) => {


                const remainingKeys = b.map((e) => e[0]);
                const deletedData = probeData.filter((e) => !remainingKeys.includes(e.key));

                const deletedKeys = deletedData.map((e) => e.key);


                const proxyUrl = "https://mycorslake.herokuapp.com/";
                axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                    raw: `DELETE FROM ${queryType === 'probe' ? 'probe_data' : 'node_data_new'} 
                    WHERE key IN (${deletedKeys.map((e) => `'${e}'`).join(',')});`

                }).then(async (response) => {
                    // handle success

                    this.props.openSnack('success', 'Data deleted!')
                    //filter probedata by deletedKeys
                    const newProbeData = probeData.filter((e) => !deletedKeys.includes(e.key));
                    this.setState({ probeData: newProbeData })




                }).catch((error) => {
                    console.log(error)
                })



            }
        };

        const probeColumns = [

            {
                name: 'key',
                label: "Edit",
                options: {
                    filter: false,
                    display: true,
                    sort: false,
                    empty: true,
                    customBodyRender: (value, b, updateValue) => {
                        const index = probeData.map((e) => e.key).indexOf(value);
                        const d = probeData[index];



                        return (
                            <div>
                                <IconButton size="small" aria-label="delete" color={editRow === b.rowData[keyColumn] ? 'primary' : 'inherit'} onClick={() => {

                                    this.updateProbeData(b.rowData[keyColumn], 'edit', !d.edit);
                                    this.updateProbeData(b.rowData[keyColumn], 'oldTime', d.time);
                                    this.updateProbeData(b.rowData[keyColumn], 'oldTimestamp', d.timestamp);
                                    this.updateProbeData(b.rowData[keyColumn], 'oldParameterid', d.parameterid);
                                    this.updateProbeData(b.rowData[keyColumn], 'oldLocationid', d.locationid);

                                    this.setState({ editRow: this.state.editRow === b.rowData[keyColumn] ? undefined : b.rowData[keyColumn] })
                                }}>
                                    <EditIcon />
                                </IconButton>

                            </div>

                        );

                    }
                }
            },
            {
                name: "time",
                label: 'Date',
                options: {
                    filter: false,

                    customBodyRender: (a, b, c) => {

                        const index = probeData.map((e) => e.key).indexOf(b.rowData[keyColumn]);
                        const time = probeData[index].time

                        if (editRow === b.rowData[keyColumn]) {

                            return (

                                <DatePicker dateFormat="MM/dd/yyyy hh:mm a" style={{ width: '200px' }} showTimeSelect timeFormat="hh:mm a" timeIntervals={1}
                                    timeCaption="Time"
                                    portalId="root-portal"
                                    selected={[undefined, '', "Invalid date"].includes(time) ? new Date() :
                                        new Date(moment(time, 'YYYY-MM-DD HH:mm').format('MM/DD/YYYY hh:mm a'))}
                                    onChange={(date) => {

                                        const unix = moment(date).format('x');


                                        const changeItems = {
                                            key: b.rowData[keyColumn],
                                            time: moment(unix, 'x').tz("America/Los_Angeles").format("YYYY-MM-DD HH:mm"),
                                            timestamp: Number(moment(unix, 'x').format('X')),
                                            edit: true
                                        }
                                        this.handleProbeData(changeItems)





                                    }}





                                />

                            )
                        }

                        else {
                            return (
                                <div>{moment(time, 'YYYY-MM-DD HH:mm').format('MM/DD/YYYY hh:mm a')}</div>
                            )
                        }
                    }
                }
            },
            {
                name: "parameterid",
                label: "Parameter",
                options: {
                    filter: false,
                    customBodyRender: (a, b, c) => {

                        if (editRow === b.rowData[keyColumn]) {
                            const index = PPArray.map((e) => e.key).indexOf(a);
                            console.log(index)
                            const label = index === -1 ? '' : PPArray[index].label;
                            return (

                                <Autocomplete
                                    size="small"
                                    id="combo-box-demo"
                                    value={label}
                                    disableClearable={true}
                                    options={PPArray.sort((a, b) => a.label.localeCompare(b.label))}
                                    filterOptions={(options, _ref) => [...filterOptions(options, _ref), { label: 'Add New', key: 'Add New', value: 'addNew' },]}
                                    noOptionsText={<Button fullWidth variant='contained' color='primary' style={{ textTransform: 'none' }}
                                        onClick={() => {
                                            this.setState({ parameterModal: true })
                                        }}
                                    >+ Create Site</Button>}
                                    renderInput={(params) => <TextField {...params} label="Parameters" size="small" />}
                                    renderOption={(params) => {

                                        if (params.key === 'Add New') {
                                            return (
                                                <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 4 }}>
                                                    <Button fullWidth variant='contained' color='primary' style={{ textTransform: 'none' }} onClick={(e) => {
                                                        this.setState({ parameterModal: true })
                                                        this.setParameterItem({ tempKey: uuid(), depthFromBottom: 0 });


                                                    }}>+ Create Parameter</Button>
                                                </Box>

                                            )
                                        }
                                        else {
                                            return (
                                                <Typography {...params}>{params.key}</Typography>
                                            )
                                        }
                                    }}
                                    onChange={(a, d) => {

                                        const changeItems = {
                                            key: b.rowData[keyColumn],
                                            parameterLabel: d.label,
                                            parameterid: d.key,
                                            edit: true,
                                            parameterUnits: d.unitLabel || '',
                                            parameterBorder: null,

                                        }
                                        this.handleProbeData(changeItems)



                                    }}

                                />



                            )
                        }

                        else {
                            const index = PPArray.map((e) => e.key).indexOf(a);
                            const label = index === -1 ? '' : PPArray[index].label;
                            return (
                                <div>{label}</div>
                            )
                        }
                    }
                }
            },
            {
                name: "locationid",
                label: "Probe",
                options: {
                    filter: false,
                    customBodyRender: (a, b, c) => {

                        if (editRow === b.rowData[keyColumn]) {

                            const index = probes.map((e) => e.id).indexOf(Number(a));

                            const label = index === -1 ? '' : probes[index].label;

                            return (



                                <Autocomplete
                                    size="small"
                                    id="combo-box-demo"
                                    disableClearable={true}
                                    value={label}
                                    options={probes}
                                    filterOptions={(options, _ref) => [...filterOptions(options, _ref), { label: 'Add New', key: 'Add New', value: 'addNew' },]}
                                    noOptionsText={<Button fullWidth variant='contained' color='primary' style={{ textTransform: 'none' }}
                                        onClick={() => {
                                            this.setState({ siteModal: true, siteItem: { tempKey: uuid() } })
                                        }}
                                    >+ Create Site</Button>}
                                    renderInput={(params) => <TextField {...params} label="Probe" size="small" />}
                                    renderOption={(params) => {

                                        if (params.key === 'Add New') {
                                            return (
                                                <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 4 }}>
                                                    <Button fullWidth variant='contained' color='primary' style={{ textTransform: 'none' }} onClick={(e) => {
                                                        this.setState({ siteModal: true, siteItem: { tempKey: uuid(), } })



                                                    }}>+ Create Site</Button>
                                                </Box>

                                            )
                                        }
                                        else {
                                            return (
                                                <Typography {...params}>{params.key}</Typography>
                                            )
                                        }
                                    }}
                                    onChange={(a, d) => {

                                        if (b.key === 'Add New') {
                                            this.setState({ siteModal: true, siteItem: { tempKey: uuid(), } })
                                        }
                                        else {
                                            const changeItems = {
                                                key: b.rowData[keyColumn],
                                                locationLabel: d.label,
                                                locationid: d.key,
                                                edit: true,
                                                locationBorder: null,

                                            }
                                            this.handleProbeData(changeItems)


                                        }

                                    }}

                                />
                            )
                        }

                        else {
                            const index = probes.map((e) => e.id).indexOf(Number(a));
                            const label = index === -1 ? '' : probes[index].label;
                            return (
                                <div>{label}</div>
                            )
                        }
                    }
                }
            },
            {
                name: "value",
                label: "Value",
                options: {
                    filter: false,

                    customBodyRender: (a, b, c) => {

                        if (editRow === b.rowData[keyColumn]) {

                            return (

                                <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                    id={`${b.rowData[keyColumn]}value`}
                                    defaultValue={a}
                                    type="number"

                                    onBlur={(e) => {
                                        const changeItems = {
                                            key: b.rowData[keyColumn],
                                            value: e.target.value,
                                            edit: true,


                                        }
                                        this.handleProbeData(changeItems)

                                    }}
                                />
                            )
                        }

                        else {
                            return (
                                <div>{a}</div>
                            )
                        }
                    }
                }
            },
            {
                name: "unitid",
                label: "Units",
                options: {
                    filter: false,
                    customBodyRender: (a, b, c) => {

                        if (editRow === b.rowData[keyColumn]) {
                            const index = unitList.map((e) => e.unitid).indexOf(a);
                            const label = index === -1 ? '' : unitList[index].label;
                            return (

                                <Autocomplete
                                    size="small"
                                    id="combo-box-demo"
                                    disableClearable={true}
                                    value={label}
                                    options={unitList}

                                    renderInput={(params) => <TextField {...params} label="Units" size="small" />}
                                    renderOption={(params) => {
                                        return (
                                            <Typography {...params}>{params.key}</Typography>
                                        )
                                    }}
                                    onChange={(a, d) => {
                                        const changeItems = {
                                            key: b.rowData[keyColumn],
                                            unitid: d.key,
                                            edit: true,

                                        }
                                        this.handleProbeData(changeItems)
                                    }}
                                />



                            )
                        }

                        else {
                            const index = unitList.map((e) => e.unitid).indexOf(a);
                            const label = index === -1 ? '' : unitList[index].label;
                            return (
                                <div>{label}</div>
                            )
                        }
                    }
                }
            },





        ];

        const nodeColumns = [

            {
                name: 'key',
                label: "Edit",
                options: {
                    filter: false,
                    display: true,
                    sort: false,
                    empty: true,
                    customBodyRender: (value, b, updateValue) => {
                        const index = probeData.map((e) => e.key).indexOf(value);
                        const d = probeData[index];



                        return (
                            <div>
                                <IconButton size="small" aria-label="delete" color={editRow === b.rowData[keyColumn] ? 'primary' : 'inherit'} onClick={() => {

                                    this.updateProbeData(b.rowData[keyColumn], 'edit', !d.edit);
                                    this.updateProbeData(b.rowData[keyColumn], 'oldTime', d.time);
                                    this.updateProbeData(b.rowData[keyColumn], 'oldTimestamp', d.timestamp);
                                    this.updateProbeData(b.rowData[keyColumn], 'oldParameterid', d.parameterid);
                                    this.updateProbeData(b.rowData[keyColumn], 'oldLocationid', d.locationid);

                                    this.setState({ editRow: this.state.editRow === b.rowData[keyColumn] ? undefined : b.rowData[keyColumn] })
                                }}>
                                    <EditIcon />
                                </IconButton>

                            </div>

                        );

                    }
                }
            },
            {
                name: "time",
                label: 'Date',
                options: {
                    filter: false,

                    customBodyRender: (a, b, c) => {

                        const index = probeData.map((e) => e.key).indexOf(b.rowData[keyColumn]);
                        const time = probeData[index].time

                        if (editRow === b.rowData[keyColumn]) {

                            return (

                                <DatePicker dateFormat="MM/dd/yyyy hh:mm a" style={{ width: '200px' }} showTimeSelect timeFormat="hh:mm a" timeIntervals={1}
                                    timeCaption="Time"
                                    portalId="root-portal"
                                    selected={[undefined, '', "Invalid date"].includes(time) ? new Date() :
                                        new Date(moment(time, 'YYYY-MM-DD HH:mm').format('MM/DD/YYYY hh:mm a'))}
                                    onChange={(date) => {

                                        const unix = moment(date).format('x');


                                        const changeItems = {
                                            key: b.rowData[keyColumn],
                                            time: moment(unix, 'x').tz("America/Los_Angeles").format("YYYY-MM-DD HH:mm"),
                                            timestamp: Number(moment(unix, 'x').format('X')),
                                            edit: true
                                        }
                                        this.handleProbeData(changeItems)





                                    }}





                                />

                            )
                        }

                        else {
                            return (
                                <div>{moment(time, 'YYYY-MM-DD HH:mm').format('MM/DD/YYYY hh:mm a')}</div>
                            )
                        }
                    }
                }
            },
            {
                name: "parameterid",
                label: "Parameter",
                options: {
                    filter: false,
                    customBodyRender: (a, b, c) => {

                        if (editRow === b.rowData[keyColumn]) {
                            const index = [...PPArray, ...parameters].map((e) => e.key).indexOf(a);
                            const label = index === -1 ? '' : [...PPArray, ...parameters][index].label;
                            return (

                                <Autocomplete
                                    size="small"
                                    id="combo-box-demo"
                                    value={label}
                                    disableClearable={true}
                                    options={[...PPArray, ...parameters].sort((a, b) => a.label.localeCompare(b.label))}
                                    filterOptions={(options, _ref) => [...filterOptions(options, _ref), { label: 'Add New', key: 'Add New', value: 'addNew' },]}
                                    noOptionsText={<Button fullWidth variant='contained' color='primary' style={{ textTransform: 'none' }}
                                        onClick={() => {
                                            this.setState({ parameterModal: true })
                                        }}
                                    >+ Create Site</Button>}
                                    renderInput={(params) => <TextField {...params} label="Parameters" size="small" />}
                                    renderOption={(params) => {

                                        if (params.key === 'Add New') {
                                            return (
                                                <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 4 }}>
                                                    <Button fullWidth variant='contained' color='primary' style={{ textTransform: 'none' }} onClick={(e) => {
                                                        this.setState({ parameterModal: true })
                                                        this.setParameterItem({ tempKey: uuid(), depthFromBottom: 0 });


                                                    }}>+ Create Parameter</Button>
                                                </Box>

                                            )
                                        }
                                        else {
                                            return (
                                                <Typography {...params}>{params.key}</Typography>
                                            )
                                        }
                                    }}
                                    onChange={(a, d) => {

                                        const changeItems = {
                                            key: b.rowData[keyColumn],
                                            parameterLabel: d.label,
                                            parameterid: d.key,
                                            edit: true,
                                            parameterUnits: d.unitLabel || '',
                                            parameterBorder: null,

                                        }
                                        this.handleProbeData(changeItems)



                                    }}

                                />



                            )
                        }

                        else {
                            const index = [...PPArray, ...parameters].map((e) => e.key).indexOf(a);
                            const label = index === -1 ? '' : [...PPArray, ...parameters][index].label;
                            return (
                                <div>{label}</div>
                            )
                        }
                    }
                }
            },
            {
                name: "nodeid",
                label: "Node",
                options: {
                    filter: false,
                    customBodyRender: (a, b, c) => {
                        const probes = stations;
                        if (editRow === b.rowData[keyColumn]) {

                            const index = probes.map((e) => e.id).indexOf(a);

                            const label = index === -1 ? '' : probes[index].label;
                            console.log(label)

                            return (



                                <Autocomplete
                                    size="small"
                                    id="combo-box-demo"
                                    disableClearable={true}
                                    value={label}
                                    options={probes}
                                    filterOptions={(options, _ref) => [...filterOptions(options, _ref), { label: 'Add New', key: 'Add New', value: 'addNew' },]}
                                    noOptionsText={<Button fullWidth variant='contained' color='primary' style={{ textTransform: 'none' }}
                                        onClick={() => {
                                            this.setState({ siteModal: true, siteItem: { tempKey: uuid() } })
                                        }}
                                    >+ Create Site</Button>}
                                    renderInput={(params) => <TextField {...params} label="Probe" size="small" />}
                                    renderOption={(params) => {

                                        if (params.key === 'Add New') {
                                            return (
                                                <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 4 }}>
                                                    <Button fullWidth variant='contained' color='primary' style={{ textTransform: 'none' }} onClick={(e) => {
                                                        this.setState({ siteModal: true, siteItem: { tempKey: uuid(), } })



                                                    }}>+ Create Site</Button>
                                                </Box>

                                            )
                                        }
                                        else {
                                            return (
                                                <Typography {...params}>{params.key}</Typography>
                                            )
                                        }
                                    }}
                                    onChange={(a, d) => {

                                        if (b.key === 'Add New') {
                                            this.setState({ siteModal: true, siteItem: { tempKey: uuid(), } })
                                        }
                                        else {
                                            const changeItems = {
                                                key: b.rowData[keyColumn],
                                                locationLabel: d.label,
                                                locationid: d.key,
                                                edit: true,
                                                locationBorder: null,

                                            }
                                            this.handleProbeData(changeItems)


                                        }

                                    }}

                                />
                            )
                        }

                        else {
                            const index = probes.map((e) => e.id).indexOf(a);
                            const label = index === -1 ? '' : probes[index].label;
                            return (
                                <div>{label}</div>
                            )
                        }
                    }
                }
            },
            {
                name: "value",
                label: "Value",
                options: {
                    filter: false,

                    customBodyRender: (a, b, c) => {

                        if (editRow === b.rowData[keyColumn]) {

                            return (

                                <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                    id={`${b.rowData[keyColumn]}value`}
                                    defaultValue={a}
                                    type="number"

                                    onBlur={(e) => {
                                        const changeItems = {
                                            key: b.rowData[keyColumn],
                                            value: e.target.value,
                                            edit: true,


                                        }
                                        this.handleProbeData(changeItems)

                                    }}
                                />
                            )
                        }

                        else {
                            return (
                                <div>{a}</div>
                            )
                        }
                    }
                }
            },
            {
                name: "unitid",
                label: "Units",
                options: {
                    filter: false,
                    customBodyRender: (a, b, c) => {

                        const index = unitList.map((e) => e.unitid).indexOf(a);

                        const label = index === -1 ? '' : unitList[index].label;
                        return (
                            <div>{label}</div>
                        )
                    }
                }
            },





        ];

        const weatherOptions = {
            filter: false,
            searchable: false,
            search: false,
            searchable: false,
            viewColumns: false,
            download: false,
            print: false,
            draggable: true,
            rowsPerPage: 50,
            rowsPerPageOptions: [10, 50, 100],
            selectableRows: screenWidth === 'xs' ? false : true,
            filterType: 'dropdown',
            responsive: 'stacked',

        };

        const weatherColumns = [
            {
                name: 'key',
                label: "Edit",
                options: {
                    filter: false,
                    display: true,
                    sort: false,
                    empty: true,
                    customBodyRender: (value, b, updateValue) => {
                        const index = probeData.map((e) => e.key).indexOf(value);
                        const d = probeData[index];



                        return (
                            <div>


                            </div>

                        );

                    }
                }
            },

            {
                name: "time",
                label: 'Date',
                options: {
                    filter: false,

                    customBodyRender: (a, b, c) => {
                        const index = weatherData.map((e) => e.key).indexOf(b.rowData[keyColumn]);
                        const time = weatherData[index].time
                        return (
                            <div>{moment(time, 'YYYY-MM-DD HH:mm').format('MM/DD/YYYY hh:mm a')}</div>
                        )
                    }
                }
            },

            {
                name: "temp",
                label: 'Temp',

            },









        ];

        const rainOptions = {
            filter: false,
            searchable: false,
            search: false,
            searchable: false,
            viewColumns: false,
            download: false,
            print: false,
            draggable: true,
            rowsPerPage: 50,
            rowsPerPageOptions: [10, 50, 100],
            selectableRows: screenWidth === 'xs' ? false : true,
            filterType: 'dropdown',
            responsive: 'stacked',
            onRowClick: (rowData, rowMeta, b) => {


                const index = rainData.map((f, i) => i).indexOf(rowMeta.dataIndex);
                const d = rainData[index];

                if (d.key !== editRow) {

                    this.updateRainData(d.key, 'edit', !d.edit);
                    this.updateRainData(d.key, 'oldTime', d.time);
                    this.updateRainData(d.key, 'oldTimestamp', d.timestamp);
                    this.updateRainData(d.key, 'oldParameterid', d.parameterid);
                    this.updateRainData(d.key, 'oldLocationid', d.locationid);

                    this.setState({ editRow: this.state.editRow === d.key ? undefined : d.key })
                }


            },
            onRowsDelete: (rows, b) => {


                const remainingKeys = b.map((e) => e[0]);
                const deletedData = rainData.filter((e) => !remainingKeys.includes(e.key));

                const deletedKeys = deletedData.map((e) => e.key);


                const proxyUrl = "https://mycorslake.herokuapp.com/";
                axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                    raw: `DELETE FROM gauge_data 
                    WHERE key IN (${deletedKeys.map((e) => `'${e}'`).join(',')});`

                }).then(async (response) => {
                    // handle success

                    this.props.openSnack('success', 'Data deleted!')
                    //filter probedata by deletedKeys
                    const newProbeData = rainData.filter((e) => !deletedKeys.includes(e.key));
                    this.setState({ rainData: newProbeData })




                }).catch((error) => {
                    console.log(error)
                })



            }

        };

        const rainColumns = [
            {
                name: 'key',
                label: "Edit",
                options: {
                    filter: false,
                    display: true,
                    sort: false,
                    empty: true,
                    customBodyRender: (value, b, updateValue) => {
                        const index = rainData.map((e) => e.key).indexOf(value);
                        const d = rainData[index];



                        return (
                            <div>
                                <IconButton size="small" aria-label="delete" color={editRow === b.rowData[keyColumn] ? 'primary' : 'inherit'} onClick={() => {

                                    this.updateRainData(b.rowData[keyColumn], 'edit', !d.edit);
                                    this.updateRainData(b.rowData[keyColumn], 'oldTime', d.time);
                                    this.updateRainData(b.rowData[keyColumn], 'oldTimestamp', d.timestamp);
                                    this.updateRainData(b.rowData[keyColumn], 'oldParameterid', d.parameterid);
                                    this.updateRainData(b.rowData[keyColumn], 'oldLocationid', d.locationid);

                                    this.setState({ editRow: this.state.editRow === b.rowData[keyColumn] ? undefined : b.rowData[keyColumn] })
                                }}>
                                    <EditIcon />
                                </IconButton>

                            </div>

                        );

                    }
                }
            },

            {
                name: "time",
                label: 'Date',
                options: {
                    filter: false,

                    customBodyRender: (a, b, c) => {

                        const index = rainData.map((e) => e.key).indexOf(b.rowData[keyColumn]);
                        const time = rainData[index].time

                        if (editRow === b.rowData[keyColumn]) {

                            return (

                                <DatePicker dateFormat="MM/dd/yyyy" style={{ width: '200px' }}
                                    timeCaption="Time"
                                    portalId="root-portal"
                                    selected={[undefined, '', "Invalid date"].includes(time) ? new Date() :
                                        new Date(moment(time, 'YYYY-MM-DD HH:mm').format('MM/DD/YYYY hh:mm a'))}
                                    onChange={(date) => {

                                        const unix = moment(date).format('x');


                                        const changeItems = {
                                            key: b.rowData[keyColumn],
                                            time: moment(unix, 'x').tz("America/Los_Angeles").format("YYYY-MM-DD HH:mm"),
                                            timestamp: Number(moment(unix, 'x').format('X')),
                                            edit: true
                                        }
                                        this.handleRainData(changeItems)





                                    }}





                                />

                            )
                        }

                        else {
                            return (
                                <div>{moment(time, 'YYYY-MM-DD HH:mm').format('MM/DD/YYYY')}</div>
                            )
                        }
                    }
                }
            },

            {
                name: "value",
                label: 'Rainfall (in)',
                options: {
                    filter: false,

                    customBodyRender: (a, b, c) => {

                        if (editRow === b.rowData[keyColumn]) {

                            return (

                                <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                    id={`${b.rowData[keyColumn]}value`}
                                    defaultValue={a}
                                    type="number"

                                    onBlur={(e) => {
                                        const changeItems = {
                                            key: b.rowData[keyColumn],
                                            value: e.target.value,
                                            edit: true,


                                        }
                                        this.handleRainData(changeItems)

                                    }}
                                />
                            )
                        }

                        else {
                            return (
                                <div>{a}</div>
                            )
                        }
                    }
                }

            },









        ];


        const csvProbe = probeData.map((p) => {
            const parameterIndex = PPArray.map((e) => e.key).indexOf(p.parameterid);
            const parameterLabel = parameterIndex === -1 ? '' : PPArray[parameterIndex].label;

            const probeIndex = probes.map((e) => e.id).indexOf(Number(p.locationid));
            const label = probeIndex === -1 ? '' : probes[probeIndex].label;
            return {
                'Parameter': parameterLabel,
                'Date': p.time,
                'Probe': label,
                'Value': p.value,
                'Units': p.unitid,
                'Account': account.accountName,
                'parameterid': p.parameterid,
                'locationid': p.locationid,
                'accountid': userInfo.currentAccount,
                'companyid': userInfo.currentCompany,

            }
        })
        const csvNode = probeData.map((p) => {
            const parameterIndex = PPArray.map((e) => e.key).indexOf(p.parameterid);
            const parameterLabel = parameterIndex === -1 ? '' : PPArray[parameterIndex].label;

            const probeIndex = stations.map((e) => e.id).indexOf(Number(p.nodeid));
            const label = probeIndex === -1 ? '' : stations[probeIndex].label;
            return {
                'Parameter': parameterLabel,
                'Date': p.time,
                'Node': label,
                'Value': p.value,
                'Units': p.unitid,
                'Account': account.accountName,
                'parameterid': p.parameterid,
                'locationid': p.locationid,
                'nodeid': p.nodeid,
                'accountid': userInfo.currentAccount,
                'companyid': userInfo.currentCompany,

            }
        })
        const csvWeather = weatherData.map((p) => {
            return {
                'Date': p.time,
                'Temp': p.temp,


            }
        })
        const csvRain = rainData.map((p) => {
            return {
                'Date': p.time,
                'Rainfall': p.value,


            }
        })




        return (
            <Row style={{}} align="middle">

                <Col span={23}>
                    <Row align="middle">
                        <Col xs={16} sm={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>
                            <Typography variant="h4">Data</Typography>
                        </Col>
                        <Col xs={6} sm={4} style={{ paddingTop: 30, paddingBottom: 15, textAlign: 'right', position: 'relative', zIndex: 0 }}>

                        </Col>
                        <Col span={24}>
                            <Divider />
                        </Col>

                    </Row>
                </Col>
                <Col span={23} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 30 }}>
                    <TabContext value={activeTab}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={(e, tab) => this.setState({ activeTab: tab, previewData: [], probeData: [], parameterFilters: [], locationFilters: [] })} aria-label="lab API tabs example">
                                <Tab style={{ textTransform: 'none' }} label="Query Probe Data" value="probe" />
                                <Tab style={{ textTransform: 'none' }} label="Query Station Data" value="node" />

                                <Tab style={{ textTransform: 'none' }} label="Query Weather data" value="weather" />
                                <Tab style={{ textTransform: 'none' }} label="Rain Data" value="rain" />

                                <Tab style={{ textTransform: 'none' }} label="Add Data" value="addData" />

                            </TabList>
                        </Box>

                        <TabPanel value={'probe'}>
                            <Row gutter={[12, 12]}>


                                <Col span={24} style={{ paddingTop: 20 }}>
                                    {step === 'filter' && (

                                        <Row gutter={[12, 12]} align="middle">
                                            <Col sm={4} style={{ paddingBottom: 12, }}>
                                                <Typography variant='h6'>Probe Filters</Typography>
                                            </Col>



                                            <Col xs={24} sm={8} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                                <Autocomplete
                                                    size="small"
                                                    disableClearable={false}
                                                    id="combo-box-demo"
                                                    value={parameterFilters || []}
                                                    ChipProps={{ color: 'primary' }}
                                                    multiple
                                                    disableCloseOnSelect
                                                    options={PPArray.sort((a, b) => a.label.localeCompare(b.label))}
                                                    filterOptions={(options, _ref) => [...filterOptions(options, _ref),
                                                    { label: 'Select All', key: 'Select All', value: 'addNew' },
                                                    { label: 'Unselect All', key: 'Unselect All', value: 'addNew' },
                                                    ]}
                                                    filterSelectedOptions
                                                    renderInput={(params) => <TextField {...params} label="Parameters" size="small" />}
                                                    renderOption={(params) => {

                                                        if (['Select All', 'Unselect All'].includes(params.key)) {
                                                            const label = params.key === 'Select All' ? 'Select All' : 'Unselect All'
                                                            return (
                                                                <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 4 }}>
                                                                    <Button fullWidth variant='contained' color={params.key === 'Select All' ? 'primary' : 'secondary'} style={{ textTransform: 'none' }} onClick={(e) => {
                                                                        this.setState({ parameterFilters: PPArray })
                                                                        if (params.key === 'Unselect All') {
                                                                            this.setState({ parameterFilters: [] })
                                                                        } else {
                                                                            this.setState({ parameterFilters: PPArray })
                                                                        }


                                                                    }}>{label}</Button>
                                                                </Box>

                                                            )
                                                        }
                                                        else {
                                                            return (
                                                                <Typography {...params}>{params.key}</Typography>
                                                            )
                                                        }
                                                    }}
                                                    onChange={(a, b) => {
                                                        this.setState({ parameterFilters: b })
                                                    }}

                                                />
                                            </Col>




                                            <Col xs={24} sm={8} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                                <Autocomplete
                                                    size="small"
                                                    disableCloseOnSelect
                                                    disableClearable={false}
                                                    id="combo-box-demo"
                                                    value={locationFilters || ''}
                                                    multiple
                                                    ChipProps={{ color: 'primary' }}
                                                    options={probes}
                                                    filterSelectedOptions
                                                    renderInput={(params) => <TextField {...params} label="Probes" size="small" />}
                                                    onChange={(a, b) => {

                                                        this.setState({ locationFilters: b })
                                                    }}

                                                />
                                            </Col>
                                            <Col sm={4} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>

                                            </Col>
                                            <Col sm={4} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>

                                            </Col>
                                            <Col sm={8} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                                <Typography variant='body1'>Start Date</Typography>
                                                <DatePicker timeCaption="Time" showTimeSelect timeFormat="h:mm a" timeIntervals={1}
                                                    dateFormat="MM/dd/yyyy hh:mm a" portalId="root-portal" selected={startDate} onChange={(date) => {

                                                        this.setState({ startDate: date })
                                                    }} />

                                            </Col>
                                            <Col sm={8} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                                <Typography variant='body1'>End Date</Typography>
                                                <DatePicker timeCaption="Time" showTimeSelect timeFormat="h:mm a" timeIntervals={1}
                                                    dateFormat="MM/dd/yyyy hh:mm a" portalId="root-portal" selected={endDate} onChange={(date) => {

                                                        this.setState({ endDate: date })
                                                    }} />

                                            </Col>
                                            <Col sm={4} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>

                                            </Col>
                                            <Col sm={4} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>

                                            </Col>
                                            <Col sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                                <Typography variant='body1'>Date Range</Typography>
                                                <Row gutter={[12, 12]} align="middle">
                                                    <Col>
                                                        <Button variant="outlined" color="primary"
                                                            style={{ textTransform: 'none', padding: 8, fontSize: 14 }}
                                                            onClick={() => this.updateTimeframe('1', 'week')} >Last Week</Button>
                                                    </Col>
                                                    <Col>
                                                        <Button variant="outlined" color="primary"
                                                            style={{ textTransform: 'none', padding: 8, fontSize: 14 }}
                                                            onClick={() => this.updateTimeframe('1', 'month')} >Last Month</Button>
                                                    </Col>
                                                    <Col>
                                                        <Button variant="outlined" color="primary"
                                                            style={{ textTransform: 'none', padding: 8, fontSize: 14 }}
                                                            onClick={() => this.updateTimeframe('3', 'month')}>3 Months</Button>
                                                    </Col>
                                                    <Col>
                                                        <Button variant="outlined" color="primary"
                                                            style={{ textTransform: 'none', padding: 8, fontSize: 14 }}
                                                            onClick={() => this.updateTimeframe('6', 'month')}>6 Months</Button>
                                                    </Col>
                                                </Row>

                                            </Col>
                                            <Col sm={4} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>

                                            </Col>
                                            <Col sm={4} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>

                                            </Col>
                                            <Col span={16}>
                                                <Divider /></Col>

                                            <Col sm={4} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>

                                            </Col>
                                            {[...parameterFilters, ...locationFilters].length > 0 && [...locationFilters].length > 0 && startDate !== undefined && endDate !== undefined && (
                                                <Col offset={4} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                                    <Button style={{ textTransform: 'none', padding: 14, fontSize: 14 }} variant="contained" color="primary"
                                                        onClick={async () => {
                                                            this.queryProbeData('probe')
                                                        }}>Query Data</Button>


                                                </Col>)}

                                            {querying === true && (
                                                <Col offset={4} sm={8} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                                    <CircularProgress /> Loading {foundFiles} data points! :)


                                                </Col>
                                            )}


                                        </Row>

                                    )}
                                </Col>
                                <Col span={24} style={{ paddingTop: 20 }}>


                                    <Row gutter={[12, 12]} align="middle">
                                        <Col span={20} style={{ paddingBottom: 12, }}>
                                            <Typography variant='h6'>Review Data</Typography>
                                        </Col>
                                        <Col span={4} style={{ paddingBottom: 12, textAlign: 'right' }}>
                                            {probeData.length > 0 && (<CSVLink data={csvProbe} filename={"data-query.csv"}>
                                                <IconButton>
                                                    <CloudDownloadIcon />
                                                </IconButton>
                                            </CSVLink>)}
                                        </Col>
                                        <Col span={24} style={{}}>
                                            <Divider />
                                        </Col>
                                        <Col span={24} style={{ paddingTop: 8 }}>
                                            {probeData !== undefined && probeData.length > 0 && (
                                                <ThemeProvider theme={this.getMuiTheme()}>
                                                    <MUIDataTable data={probeData}
                                                        columns={screenWidth === 'xs' ? [...probeColumns, {
                                                            name: 'key',
                                                            label: "Delete",
                                                            options: {
                                                                filter: true,

                                                                sort: false,
                                                                empty: true,
                                                                customBodyRender: (value, b, updateValue) => {

                                                                    return (
                                                                        <div>
                                                                            <IconButton size="small" aria-label="delete" color={'inherit'} onClick={() => {
                                                                                //delete this item from data
                                                                                const index = probeData.map((e, i) => e.key).indexOf(value);
                                                                                const item = probeData[index];

                                                                                this.deleteProbeData(item, index)



                                                                            }}>
                                                                                <DeleteForever />
                                                                            </IconButton>

                                                                        </div>

                                                                    )

                                                                }
                                                            }
                                                        },] : probeColumns} options={probeOptions} />
                                                </ThemeProvider>
                                            )}
                                            {probeData === undefined || probeData.length === 0 && (
                                                <Typography variant='body1'>Probe Data</Typography>
                                            )}

                                        </Col>









                                    </Row>


                                </Col>

                            </Row>
                        </TabPanel>
                        <TabPanel value={'node'}>



                            <Row gutter={[12, 12]}>
                                <Col span={24}>
                                    <QueryStationData userInfo={userInfo} openSnack={openSnack} screenWidth={screenWidth} account={account} company={company} />
                                </Col>





                            </Row>
                        </TabPanel>
                        <TabPanel value="weather">
                            <Row gutter={[12, 12]}>
                                <Col span={24}>
                                    <Row gutter={[12, 12]} align="middle">
                                        <Col sm={4} style={{ paddingBottom: 12, }}>
                                            <Typography variant='h6'>Weather </Typography>
                                        </Col>



                                        <Col sm={8} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                            <Typography variant='body1'>Start Date</Typography>
                                            <DatePicker timeCaption="Time" showTimeSelect timeFormat="h:mm a" timeIntervals={1}
                                                dateFormat="MM/dd/yyyy hh:mm a" portalId="root-portal" selected={startDate} onChange={(date) => {

                                                    this.setState({ startDate: date })
                                                }} />

                                        </Col>
                                        <Col sm={8} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                            <Typography variant='body1'>End Date</Typography>
                                            <DatePicker timeCaption="Time" showTimeSelect timeFormat="h:mm a" timeIntervals={1}
                                                dateFormat="MM/dd/yyyy hh:mm a" portalId="root-portal" selected={endDate} onChange={(date) => {

                                                    this.setState({ endDate: date })
                                                }} />

                                        </Col>
                                        <Col sm={4} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>

                                        </Col>
                                        <Col sm={4} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>

                                        </Col>
                                        <Col sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                            <Typography variant='body1'>Date Range</Typography>
                                            <Row gutter={[12, 12]} align="middle">
                                                <Col>
                                                    <Button variant="outlined" color="primary"
                                                        style={{ textTransform: 'none', padding: 8, fontSize: 14 }}
                                                        onClick={() => this.updateTimeframe('1', 'week')} >Last Week</Button>
                                                </Col>
                                                <Col>
                                                    <Button variant="outlined" color="primary"
                                                        style={{ textTransform: 'none', padding: 8, fontSize: 14 }}
                                                        onClick={() => this.updateTimeframe('1', 'month')} >Last Month</Button>
                                                </Col>
                                                <Col>
                                                    <Button variant="outlined" color="primary"
                                                        style={{ textTransform: 'none', padding: 8, fontSize: 14 }}
                                                        onClick={() => this.updateTimeframe('3', 'month')}>3 Months</Button>
                                                </Col>
                                                <Col>
                                                    <Button variant="outlined" color="primary"
                                                        style={{ textTransform: 'none', padding: 8, fontSize: 14 }}
                                                        onClick={() => this.updateTimeframe('6', 'month')}>6 Months</Button>
                                                </Col>
                                            </Row>

                                        </Col>
                                        <Col sm={4} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>

                                        </Col>
                                        <Col sm={4} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>

                                        </Col>
                                        <Col span={16}>
                                            <Divider /></Col>

                                        <Col sm={4} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>

                                        </Col>
                                        {startDate !== undefined && endDate !== undefined && (
                                            <Col offset={4} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                                <Button style={{ textTransform: 'none', padding: 14, fontSize: 14 }} variant="contained" color="primary"
                                                    onClick={async () => {
                                                        this.queryWeatherData()
                                                    }}>Query Data</Button>


                                            </Col>)}

                                        {querying === true && (
                                            <Col offset={4} sm={8} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                                <CircularProgress /> Loading {foundFiles} data points! :)


                                            </Col>
                                        )}


                                    </Row>
                                </Col>
                                <Col span={24} style={{ paddingTop: 20 }}>


                                    <Row gutter={[12, 12]} align="middle">
                                        <Col span={20} style={{ paddingBottom: 12, }}>
                                            <Typography variant='h6'>Review Data</Typography>
                                        </Col>
                                        <Col span={4} style={{ paddingBottom: 12, textAlign: 'right' }}>
                                            {weatherData.length > 0 && (<CSVLink data={csvWeather} filename={"data-query.csv"}>
                                                <IconButton>
                                                    <CloudDownloadIcon />
                                                </IconButton>
                                            </CSVLink>)}
                                        </Col>
                                        <Col span={24} style={{}}>
                                            <Divider />
                                        </Col>
                                        <Col span={24} style={{ paddingTop: 8 }}>
                                            {weatherData !== undefined && weatherData.length > 0 && (
                                                <ThemeProvider theme={this.getMuiTheme()}>
                                                    <MUIDataTable data={weatherData}
                                                        columns={weatherColumns} options={weatherOptions} />
                                                </ThemeProvider>
                                            )}
                                            {weatherData === undefined || weatherData.length === 0 && (
                                                <Typography variant='body1'>Weather Data</Typography>
                                            )}

                                        </Col>









                                    </Row>


                                </Col>
                            </Row>

                        </TabPanel>

                        <TabPanel value="rain">
                            <Row gutter={[12, 12]}>
                                <Col span={24}>
                                    <Row gutter={[12, 12]} align="middle">
                                        <Col sm={4} style={{ paddingBottom: 12, }}>
                                            <Typography variant='h6'>Rain</Typography>
                                        </Col>

                                        <Col sm={8} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                            <Autocomplete
                                                size="small"
                                                disableCloseOnSelect
                                                disableClearable={false}
                                                id="combo-box-demo"
                                                value={gaugeFilters || ''}
                                                multiple
                                                ChipProps={{ color: 'primary' }}
                                                options={gauges}
                                                filterSelectedOptions
                                                renderInput={(params) => <TextField {...params} label="Gauge" size="small" />}
                                                onChange={(a, b) => {

                                                    this.setState({ gaugeFilters: b })
                                                }}

                                            />
                                        </Col>
                                        <Col sm={12} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>

                                        </Col>
                                        <Col sm={4} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>

                                        </Col>



                                        <Col sm={8} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                            <Typography variant='body1'>Start Date</Typography>
                                            <DatePicker timeCaption="Time" showTimeSelect timeFormat="h:mm a" timeIntervals={1}
                                                dateFormat="MM/dd/yyyy hh:mm a" portalId="root-portal" selected={startDate} onChange={(date) => {

                                                    this.setState({ startDate: date })
                                                }} />

                                        </Col>
                                        <Col sm={8} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                            <Typography variant='body1'>End Date</Typography>
                                            <DatePicker timeCaption="Time" showTimeSelect timeFormat="h:mm a" timeIntervals={1}
                                                dateFormat="MM/dd/yyyy hh:mm a" portalId="root-portal" selected={endDate} onChange={(date) => {

                                                    this.setState({ endDate: date })
                                                }} />

                                        </Col>
                                        <Col sm={4} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>

                                        </Col>
                                        <Col sm={4} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>

                                        </Col>
                                        <Col sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                            <Typography variant='body1'>Date Range</Typography>
                                            <Row gutter={[12, 12]} align="middle">
                                                <Col>
                                                    <Button variant="outlined" color="primary"
                                                        style={{ textTransform: 'none', padding: 8, fontSize: 14 }}
                                                        onClick={() => this.updateTimeframe('1', 'week')} >Last Week</Button>
                                                </Col>
                                                <Col>
                                                    <Button variant="outlined" color="primary"
                                                        style={{ textTransform: 'none', padding: 8, fontSize: 14 }}
                                                        onClick={() => this.updateTimeframe('1', 'month')} >Last Month</Button>
                                                </Col>
                                                <Col>
                                                    <Button variant="outlined" color="primary"
                                                        style={{ textTransform: 'none', padding: 8, fontSize: 14 }}
                                                        onClick={() => this.updateTimeframe('3', 'month')}>3 Months</Button>
                                                </Col>
                                                <Col>
                                                    <Button variant="outlined" color="primary"
                                                        style={{ textTransform: 'none', padding: 8, fontSize: 14 }}
                                                        onClick={() => this.updateTimeframe('6', 'month')}>6 Months</Button>
                                                </Col>
                                            </Row>

                                        </Col>
                                        <Col sm={4} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>

                                        </Col>
                                        <Col sm={4} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>

                                        </Col>
                                        <Col span={16}>
                                            <Divider /></Col>

                                        <Col sm={4} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>

                                        </Col>
                                        {[...gaugeFilters].length > 0 && startDate !== undefined && endDate !== undefined && (
                                            <Col offset={4} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                                <Button style={{ textTransform: 'none', padding: 14, fontSize: 14 }} variant="contained" color="primary"
                                                    onClick={async () => {
                                                        this.queryRainData()
                                                    }}>Query Data</Button>


                                            </Col>)}

                                        {querying === true && (
                                            <Col offset={4} sm={8} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                                <CircularProgress /> Loading {foundFiles} data points! :)


                                            </Col>
                                        )}


                                    </Row>
                                </Col>
                                <Col span={24} style={{ paddingTop: 20 }}>


                                    <Row gutter={[12, 12]} align="middle">
                                        <Col span={20} style={{ paddingBottom: 12, }}>
                                            <Typography variant='h6'>Review Data</Typography>
                                        </Col>
                                        <Col span={4} style={{ paddingBottom: 12, textAlign: 'right' }}>
                                            {rainData.length > 0 && (<CSVLink data={csvRain} filename={"data-query.csv"}>
                                                <IconButton>
                                                    <CloudDownloadIcon />
                                                </IconButton>
                                            </CSVLink>)}
                                        </Col>
                                        <Col span={24} style={{}}>
                                            <Divider />
                                        </Col>
                                        <Col span={24} style={{ paddingTop: 8 }}>
                                            {rainData !== undefined && rainData.length > 0 && (
                                                <ThemeProvider theme={this.getMuiTheme()}>
                                                    <MUIDataTable data={rainData}
                                                        columns={rainColumns} options={rainOptions} />
                                                </ThemeProvider>
                                            )}
                                            {rainData === undefined || rainData.length === 0 && (
                                                <Typography variant='body1'>Rain Data</Typography>
                                            )}

                                        </Col>









                                    </Row>


                                </Col>
                            </Row>

                        </TabPanel>

                        <TabPanel value="addData">
                            <Row gutter={[12, 12]}>
                                <Col span={24}>
                                    <UploadDataNew screenWidth={screenWidth} userInfo={userInfo} openSnack={this.props.openSnack} company={company} account={account} />
                                </Col>
                            </Row>

                        </TabPanel>

                    </TabContext>
                </Col>








            </Row>
        );
    }
} 