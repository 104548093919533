import React from 'react';
import { Col, Row } from 'antd';
import { Breadcrumbs, Button, OutlinedInput, Paper, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { setDefaultOptions, loadModules, } from 'esri-loader';
import Search from "@arcgis/core/widgets/Search";

export default class SearchBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            map: this.props.map,
            view: this.props.view,
        };
    }

    componentDidUpdate(prevProps) {


    }
    componentDidMount() {
        const { account, map, view } = this.state;
        const item = view.ui.find('searchWidget');
        if (item === null) {
            this.addSearch()
        }
    }

    addSearch = () => {
        const { view } = this.state;
        const SearchWidget = new Search({   
            view: view,
            id: 'searchWidget'
        });
        view.ui.add(SearchWidget, {
            position: "top-right",
            index: 2
        });
    }

    render() {

        const { } = this.state;

        return null;
    }
} 