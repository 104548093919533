import { faCamera, faEye, faEyeSlash, faFloppyDisk, faGear, faMagnifyingGlassMinus, faTriangleExclamation, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddIcon from '@mui/icons-material/Add';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Autocomplete, Backdrop, Box, Button, CircularProgress, Divider, Fab, IconButton, MenuItem, OutlinedInput, Paper, Select, Slider, TextField, Tooltip, Typography } from '@mui/material';
import { Col, Drawer, Modal, Popconfirm, Row, Spin } from 'antd';
import download from 'downloadjs';
import { collection, doc, getDoc, getDocs, getFirestore, query, setDoc, updateDoc, where } from 'firebase/firestore';
import * as htmlToImage from 'html-to-image';
import _, { cloneDeep } from "lodash";
import moment from 'moment';
import React from 'react';
import GridLayout from "react-grid-layout";
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { useNavigate } from 'react-router-dom';
import uuid from "react-uuid";
import CurrentWeatherSideBarMobile from "../../../blocks/currentWeatherSideBarMobile";
import WidgetChart from '../charts/chart';
import CapacitySettings from './settings/capacitySettings';
import ChartSettings from './settings/chartSettings';
import ChartStockSettings from './settings/chartStockSettings ';
import ContourSettings from './settings/countourSettings';
import GaugeSettings from './settings/gaugeSettings';
import IsoplethSettings from './settings/isoplethSettings';
import MapSettings from './settings/mapSettings';
import ProfileSettings from './settings/profileSettings';
import ReadingSettings from './settings/readingSettings';
import WidgetCapacity from './widgetCapacity';
import WidgetContour from './widgetContour';
import WidgetGauge from './widgetGauge';
import WidgetIsopleth from './widgetIsopleth';
import WidgetMap from './widgetMap';
import WidgetProfile from './widgetProfile';
import WidgetReading from './widgetReading';

import axios, * as others from 'axios';
import queryString from 'query-string';
import { getAuth } from 'firebase/auth';
import html2canvas from 'html2canvas';
import { useEffect } from 'react';
import DetectPress from './detectPress';
import WidgetSiteInfo from './widgetSiteInfo';
import SiteInfoSettings from './settings/siteInfoSettings';
import AddToMaster from './dashboardPersonalAddToMaster';
import TextSettings from './settings/textSettings';
import WidgetText from './widgetText';
import ImageSettings from './settings/imageSettings';
import WidgetImage from './widgetImage';
import WidgetCarousel from './widgetCarousel';
import CarouselSettings from './settings/carouselSettings';
import WidgetMap2 from './widgetMap2';
import WidgetImageTimeline from './widgetImageTimeline';
import ForecastSettings from './settings/forecastSettings';
import WidgetForecast from './widgetForecast';
import ScreenShotPopover from './screenShotPopover';
import WidgetProfileNew from './widgetProfilesNew';
import WidgetURLImage from './WidgetURLImage';
import URLImageSettings from './settings/urlImageSettings';
import AdvancedWidgetSettings from './advancedWidgetSettings';
export default class DashboardContentPortal extends React.Component {
    constructor(props) {
        super(props);
        //create gridref
        this.gridRef = React.createRef();
        this.state = {
            portal: this.props.portal,
            accountID: this.props.accountID,
            screenWidth: this.props.screenWidth,
            portalInfo: {},
            dashWidth: null,
            layout: [],
            widgets: [],
            publicLayout: [],
            layoutChange: false,
            currentWidgetKey: '',
            currentAttributes: {},
            openDrawer: false,
            loading: false,
            type: this.props.type,
            title: '',
            readData: false,
            showBackdrop: false,
            takingScreenshot: false,
            isZoomed: false,
            probes: [],
            loadingProbes: true,
            stations: [],
            loadingStations: true,
            currentChart: '',
            openScreenshotModal: false,
            legendSize: 14,
            axisSize: 14,
            yAxisSize: 14,
            referenceSize: 14,
            chartHeight: 4,
            chartWidth: 6,
            xAxisAngle: 0,
            dateInterval: 'monthly',
            loggedIn: false,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {

            this.setState({ screenWidth: this.props.screenWidth })
        }

        if (this.props.type !== prevProps.type) {
            this.setState({ type: this.props.type })
        }

    }
    async componentDidMount() {
        window.scrollTo(0, 0)
        const { screenWidth, layout, type } = this.state;

        //check if user is logged in
        const auth = getAuth();
        if (auth.currentUser) {
            this.setState({ loggedIn: true })
        }


        this.setState({ dashWidth: this.container.offsetWidth, });


        this.getPortal()

    }


    getPortal = async () => {
        const { type, portal } = this.state;


        const db = getFirestore();

        //collectionRef in publicPortals where account is equal to accountID and url is equal to portal
        const queryData = query(collection(db, "publicPortals"), where('account', '==', this.props.accountID), where('url', '==', portal));

        const snap = await getDocs(queryData);

        snap.forEach((doc) => {
            if (doc.exists()) {
                this.queryAccount(doc.data().company, doc.data().account)
                this.getLayout(type, doc.data().account, doc.data().company, doc.data().id)
                this.getLocations(doc.data().account, doc.data().company, doc.data().id)
                this.getStations(doc.data().account, doc.data().company, doc.data().id)
                this.setState({ portalInfo: doc.data() })
            } else {
                console.log("No such document!");
            }

        });



    }

    queryAccount = async (companyKey, accountKey) => {
        const { userInfo, account } = this.state;
        this.setState({ loading: true, account: {} })

        const db = getFirestore();
        const docRefAccount = doc(db, "clients", companyKey, "accounts", accountKey);
        const docSnapAccount = await getDoc(docRefAccount);
        const docRef = doc(db, "clients", companyKey, "accounts", accountKey, 'dashboard', 'newDash');
        const docSnapNew = await getDoc(docRef);

        if (docSnapNew.exists()) {

            this.setState({ account: docSnapAccount.data() })


        }
        else {

            this.props.openSnack('error', 'No public widgets found for this account.')
            this.setState({ errorMessage: 'No public widgets found for this account.', loading: false })

        }



    }

    getLocations = async (account, company) => {



        const db = getFirestore();
        const queryData = query(collection(db, "clients", company, 'probes'), where('account', '==', account), where('archived', '==', false));

        const locations = [];


        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            locations.push({ ...doc.data(), label: doc.data().name, type: 'probe' })
        });
        locations.sort((a, b) => a.name.localeCompare(b.name))
        this.setState({ probes: locations })
        if (locations.length > 0) {
            this.setState({ loadingProbes: false })
        } else {
            this.setState({ loadingProbes: false })
        }


    }
    getStations = async (account, company) => {



        const db = getFirestore();
        const queryData = query(collection(db, "clients", company, 'stations'), where('account', '==', account),);

        const stations = [];


        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            stations.push({ ...doc.data() })
        });
        stations.sort((a, b) => a.name.localeCompare(b.name))
        this.setState({ stations: stations })
        if (stations.length > 0) {
            this.setState({ loadingStations: false })
        }
        else {
            this.setState({ loadingStations: false })
        }


    }


    renderContent() {
        const { dimensions } = this.state;


    }

    onRemoveItem = (i) => {
        const layout = _.reject(this.state.layout, { i: i })
        const widgets = _.reject(this.state.widgets, { key: i })
        this.setState({
            layout,
            widgets,
        });
        this.onClose()
        this.updateLayout(layout)
        this.updateWidgets(widgets)


    }

    getLayout = async (type, account, company, id) => {
        const { portal } = this.state;

        const db = getFirestore();



        const docRefCheck = doc(db, "publicPortals", id);

        const docSnapNew = await getDoc(docRefCheck);



        if (docSnapNew.exists()) {



            const widgetsString = docSnapNew.data().widgets1 === "" ? JSON.parse(docSnapNew.data().widgets) :
                docSnapNew.data().widgets1 === undefined ? JSON.parse(docSnapNew.data().widgets) : JSON.parse(docSnapNew.data().widgets + docSnapNew.data().widgets1);


            const layoutString = JSON.parse(docSnapNew.data().layout);

            //if loggedIn === false set all to not draggable
            const newLayout = layoutString.map((item) => {
                if (this.state.loggedIn === false) {
                    return { ...item, isDraggable: false, isResizable: false }
                } else {
                    return { ...item, isDraggable: true, isResizable: true }
                }
            })


            this.setState({ widgets: widgetsString, layout: newLayout, })




        }
        else {
            //create public layout string

            this.setState({ layout: [], widgets: [], publicLayout: [] })



            const layoutString = JSON.stringify([]);

            const widgetsString = JSON.stringify([]);
            var somevalue = widgetsString.substring(0, Math.floor(widgetsString.length / 2)); // seperates string from 10 characters 
            var lessthan = widgetsString.substring(Math.floor(widgetsString.length / 2), widgetsString.length); // seperates string from 10 characters negative

            await setDoc(docRefCheck, { widgets: somevalue, widgets1: lessthan, layout: layoutString, })





        }



















    }



    handleCheckLayout = (layout) => {
        // Clears running timer and starts a new one each time the user types
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.toggleCheckLayout(layout);
        }, 250);
    }

    toggleCheckLayout = async (layout,) => {
        this.updateLayout(layout,)

    }



    updateLayout = async (layout) => {
        const { portalInfo } = this.state
        const layoutString = JSON.stringify(layout);

        const db = getFirestore();
        const docRef = doc(db, "publicPortals", portalInfo.id);
        await updateDoc(docRef, {
            layout: layoutString,
        });


        this.props.openSnack('success', 'Layout updated!');
    };

    updateWidgets = async (widgets) => {
        const { portalInfo } = this.state
        const clearChartData = (chartData) => {
            return chartData.map((d) => ({ ...d, data: [] }));
        };

        const prepareWidgetsForUpdate = (widgets) => {
            return widgets.map((w) => {
                const newChartData = clearChartData(w.attributes.chartData);
                const newAttributes = { ...w.attributes, chartData: newChartData };
                return { ...w, attributes: newAttributes };
            });
        };

        const splitWidgetsString = (widgetsString) => {
            const halfLength = Math.floor(widgetsString.length / 2);
            const firstHalf = widgetsString.substring(0, halfLength);
            const secondHalf = widgetsString.substring(halfLength, widgetsString.length);
            return { firstHalf, secondHalf };
        };


        const newWidgets = prepareWidgetsForUpdate(widgets);
        const widgetsString = JSON.stringify(newWidgets);
        const { firstHalf, secondHalf } = splitWidgetsString(widgetsString);

        const db = getFirestore();
        const docRef = doc(db, "publicPortals", portalInfo.id);
        await updateDoc(docRef, {
            widgets: firstHalf,
            widgets1: secondHalf,
        });


        this.props.openSnack('success', 'Widgets updated!');
    };


    updateWidget = (key, boo, val) => {
        const { widgets, userInfo, layout } = this.state;
        const index = widgets.map((k) => k.key).indexOf(key);
        widgets[index].attributes[boo] = val;
        this.setState({ widgets: [...widgets] })
        this.updateWidgets([...widgets])
    }


    saveWidgetAttributes = (key, val, close, sl, map) => {

        const { widgets, userInfo, layout, currentWidgetKey } = this.state;
        const index = widgets.map((k) => k.key).indexOf(currentWidgetKey);
        const currentWidget = widgets[index];
        //update current widget attributes
        currentWidget.attributes = { ...val };

        //update widgets
        widgets[index] = currentWidget;


        this.setState({ widgets: [...widgets], })
        if (sl === true) {
            this.updateWidgets(widgets)
            widgets[index].loading = true;
            this.setState({ widgets: [...widgets], })
            setTimeout(() => {
                widgets[index].loading = false;
                this.setState({ widgets: [...widgets], showBackdrop: false })
            }, 250);

        }

        if (close === true) {
            this.onClose();
            this.setState({ showBackdrop: false })
        }
        if (map === true) {
            this.updateWidgets(widgets)
            this.setState({ showBackdrop: false })
        }


    }


    updateCurrentAttributes = (attributes, key) => {
        this.setState({ currentAttributes: attributes })

    }

    onClose = () => {
        this.setState({
            openDrawer: false,
            currentWidgetKey: '',
            currentAttributes: {},
            title: ''
        });
    };

    duplicateWidget = (currentWidgetKey) => {
        const { layout, widgets } = this.state;

        const l = layout.filter((f) => f.i === currentWidgetKey)[0];
        const w = widgets.filter((f) => f.key === currentWidgetKey)[0];

        const key = `${uuid()}`;



        //duplicate w and add to the bottom of the layout
        const newLayout = [...layout, {
            i: key,
            x: l.x,
            y: l.y + 1,
            w: l.w,
            h: l.h,
            minW: l.minW,
            minH: l.minH,
            maxW: l.maxW,
            maxH: l.maxH,
            moved: false,
            static: false,
            isDraggable: true,
            isResizable: true,
        }]
        const newWidget = cloneDeep(w);
        newWidget.key = key;
        newWidget.attributes.title = `${w.attributes.title} (Copy)`;
        const newWidgets = [...widgets, newWidget]
        this.setState({
            layout: newLayout,
            widgets: newWidgets,
            currentWidgetKey: null,
            currentAttributes: null,
            openDrawer: false,
            title: ''
        })
        this.props.openSnack('success', 'Widget Duplicated!')

        setTimeout(() => {
            this.setState({
                currentWidgetKey: null,
                currentAttributes: null,
                openDrawer: false,
                title: ''
            })


        }, 250);











    }
















    handleCheck = (title) => {
        // Clears running timer and starts a new one each time the user types
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.toggleCheck(title);
        }, 1000);
    }

    toggleCheck = async (title) => {
        this.setState({ title })



    }



    render() {

        const { portalInfo, loggedIn, legendSize, referenceSize, axisSize, yAxisSize, xAxisAngle, dateInterval, chartHeight, chartWidth, openScreenshotModal, probes, currentChart, stations, loadingProbes, loadingStations, isZoomed, account, layout, type, dimensions, readData, showBackdrop, dashWidth, screenWidth, widgets, userInfo, layoutChange, openDrawer, currentWidgetKey, currentAttributes, loading, title, takingScreenshot } = this.state;


        const AddWidgetButton = () => {
            let navigate = useNavigate();

            return (
                <Fab style={{ textTransform: 'none', backgroundColor: '#404050' }} fullWidth variant="contained" color="primary" size="medium"
                    onClick={async () => {
                        const key = uuid()

                        const axises = [
                            {
                                key: uuid(),
                                axisId: 0,
                                label: '',
                                opposite: false,
                                customRange: false,
                                min: 0,
                                max: 100,
                                labelRotation: 270,
                                axisColor: '#000000',
                                gridLineColor: '#000000',
                                gridLineDashStyle: 'Solid',
                                gridLineWidth: 0.3,
                                alignTicks: true,
                                endOnTick: true,
                                reversed: false,
                                advancedSettings: false,
                                title: {
                                    text: ''
                                }
                            }
                        ]


                        this.setState({
                            // Add a new item. It must have a unique key!
                            layout: this.state.layout.concat({
                                i: "n" + key,
                                x: 0,
                                y: Infinity, // puts it at the bottom
                                w: 4, h: 6, minH: 2, minW: 2,
                            }),
                            widgets: this.state.widgets.concat({
                                key: "n" + key,
                                attributes: {
                                    legend: true,
                                    shareAxis: true,
                                    rangeSelectors: true,
                                    customDepth: false,
                                    timeline: true,
                                    chartData: [],
                                    references: [],
                                    axises,
                                    endToday: true,
                                    startDate: moment().subtract(1, 'years').format('x'),
                                    endDate: moment().format('x')
                                }
                            }),
                            // Increment the counter to ensure key is always unique.

                        });
                        //scroll to bottom smoothly
                        setTimeout(() => {
                            window.scrollTo({
                                //top should be where the widget got added to the layout
                                top: this.gridRef.current.getBoundingClientRect().bottom,
                                behavior: 'smooth'
                            });
                        }, 250)




                    }}>
                    <AddIcon />
                </Fab>
            )
        }
        const SaveLayoutButton = () => {

            return (
                <Button style={{ textTransform: 'none', padding: 12, color: '#9ec1c9' }} size='medium' variant="outlined" color="inherit"
                    onClick={() => {
                        this.updateLayout(layout)
                        this.updateWidgets(widgets)
                    }} >
                    Save <FontAwesomeIcon fontSize={24} style={{ paddingLeft: 10 }} icon={faFloppyDisk} color="#9ec1c9" />
                </Button>
            )
        }

        const Width = () => {
            if (this.container) {
                if (this.container.offsetWidth !== dashWidth) {
                    this.setState({ dashWidth: this.container.offsetWidth })
                }

            }



        }






        const layoutSmall = layout.map((l) => { return ({ ...l, w: 4, static: true }) })



        const Widget = (legendSize) => {

            const widget = widgets.find(widget => widget.key === currentWidgetKey)
            const layoutItem = layout.find(layout => layout.i === currentWidgetKey)

            const attributes = widget.attributes


            if (attributes.widgetType === 'chart') {
                return (
                    <div id='customChart' style={{
                        overflowX: 'auto',
                        overflowY: 'auto',
                    }}>
                        <WidgetChart
                            legendSize={legendSize.legendSize}
                            referenceSize={referenceSize}
                            axisSize={axisSize}
                            yAxisSize={yAxisSize}
                            xAxisAngle={xAxisAngle}
                            chartHeight={chartHeight}
                            chartWidth={chartWidth}
                            dateInterval={dateInterval}

                            attributes={attributes} userInfo={{
                                currentAccount: portalInfo.account,
                                currentCompany: portalInfo.company,
                            }} />
                    </div>
                )
            }

            if (attributes.widgetType === 'contour') {
                return (
                    <div style={{ overflow: 'auto', height: '100%', width: '100%' }}>
                        <WidgetContour
                            legendSize={legendSize}
                            referenceSize={referenceSize}
                            axisSize={axisSize}
                            yAxisSize={yAxisSize}
                            xAxisAngle={xAxisAngle}
                            chartHeight={chartHeight}
                            chartWidth={chartWidth}
                            dateInterval={dateInterval}
                            attributes={attributes} userInfo={{
                                currentAccount: portalInfo.account,
                                currentCompany: portalInfo.company,
                            }} />
                    </div>
                )
            }

        }


        const BackSiteInfo = () => {
            let navigate = useNavigate();

            return (
                <Button variant="link" color="inherit" style={{ textTransform: 'none', padding: 0, fontSize: 15 }}
                    onClick={() => navigate('/siteInfoHome/portals')}>
                    <Typography variant='body1' fontSize={16} fontWeight={500} color="blue" >Back to site info</Typography>
                </Button>
            )
        }
        const BackHome = () => {
            let navigate = useNavigate();

            return (
                <Button variant="link" color="inherit" style={{ textTransform: 'none', padding: 0, fontSize: 15 }}
                    onClick={() => navigate('/')}>
                    <Typography variant='body1' fontSize={16} fontWeight={300} color="blue" >Back to login page</Typography>
                </Button>
            )
        }




        return (
            <div style={{ fontFamily: 'Roboto, sans-serif', backgroundColor: '#f6f8fe', minHeight: '100vh' }}>
                <Row style={{}} >

                    <Width />

                    <Col span={23}>
                        <Row align="middle">

                            <Col xs={12} sm={16} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>

                                <Typography variant='h3' fontSize={36} fontWeight={500} >{portalInfo?.name}</Typography>
                            </Col>
                            <Col xs={6} sm={8} style={{ paddingTop: 30, paddingBottom: 15, textAlign: 'right', position: 'relative', zIndex: 0 }}>
                                {loggedIn ? <BackSiteInfo /> : <BackHome />}
                                {type !== 'open' && loggedIn ? <AddWidgetButton /> :
                                    null}
                            </Col>



                            <Col span={24}>
                                <Divider />
                            </Col>
                        </Row>
                    </Col>
                    <Col span={23} style={{ paddingLeft: 10, paddingTop: 10, paddingBottom: 30 }}>
                        <Row align="middle" style={{}} className="Hello" ref={el => (this.container = el)}>
                            <Col span={24} style={{ padding: 10 }}>

                            </Col>
                            <Col span={24} ref={this.gridRef}>
                                {dashWidth !== null && (

                                    <GridLayout

                                        layout={['xs', 'sm', 'md'].includes(screenWidth) ? layoutSmall : layout}
                                        cols={['xs', 'sm', 'md'].includes(screenWidth) ? 4 : 12}
                                        rowHeight={30}
                                        //make all static

                                        width={dashWidth}
                                        draggableHandle=".drag-handle"
                                        onLayoutChange={(layout) => {
                                            if (!['xs', 'sm', 'md'].includes(screenWidth)) {

                                                this.setState({ layout })
                                                this.handleCheckLayout(layout)

                                            }

                                        }}
                                    >
                                        {layout.map((l, i) => {






                                            const index = widgets.map((k) => k.key).indexOf(l.i);

                                            const widget = widgets[index];

                                            const widgetKey = widget === undefined ? '' : widget.key;


                                            const attributes = widget === undefined ? {} : widget.attributes;



                                            if (widget !== undefined) {
                                                return (
                                                    <div style={{ display: 'flex', height: '100%', width: '100%' }} key={l.i}
                                                    //when mouse in make l.i currentChart, but when mouse out, make currentChart null


                                                    >
                                                        <Paper elevation={12} style={{
                                                            display: 'flex', height: '100%', width: '100%', borderRadius: 8, position: 'relative',
                                                            boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.2)'

                                                        }}
                                                        >


                                                            {widget !== undefined && widget.loading !== true && (
                                                                <Row style={{ display: 'flex', height: '100%', width: '100%', padding: 10 }} align="middle"
                                                                    id={`${l.i}_title`}>
                                                                    <Col span={24} >
                                                                        <Row style={{ display: 'flex', height: '100%', width: '100%', }} align="middle">

                                                                            <Col xs={16} sm={l.w >= 6 ? 18 : l.w >= 4 ? 16 : l.w >= 3 ? 14 : 12}
                                                                                style={{ paddingLeft: 5 }} >
                                                                                {screenWidth !== 'xs' && type !== 'open' && loggedIn === true && (<DragIndicatorIcon className="drag-handle"
                                                                                    display="inline"
                                                                                    style={{
                                                                                        cursor: 'pointer'
                                                                                    }}
                                                                                />)}
                                                                                <Typography fontWeight={attributes.titleFontWeight || 300}
                                                                                    color={attributes.titleFontColor || '#000000de'} display="inline"
                                                                                    fontSize={screenWidth === 'xs' ? 24 : attributes.titleFontSize || 24}
                                                                                    variant={screenWidth === 'xs' ? 'subtitle1' :
                                                                                        l.w >= 4 ? "h5" : l.w >= 3 ? "subtitle1" : "subtitle1"}>{attributes.title || ""}</Typography>

                                                                            </Col>
                                                                            <Col xs={24} sm={l.w >= 6 ? 6 : l.w >= 4 ? 8 : l.w >= 3 ? 10 : 12}
                                                                                style={{ textAlign: 'right' }}>
                                                                                <Row justify="end" align='middle'>


                                                                                    <Col>
                                                                                        {attributes.widgetType === 'chart' && isZoomed && (

                                                                                            <IconButton

                                                                                                onClick={() => {
                                                                                                    this.setState({ isZoomed: false })
                                                                                                }}
                                                                                            >
                                                                                                <FontAwesomeIcon fontSize={18}
                                                                                                    icon={faMagnifyingGlassMinus} color="#9ec1c9" />
                                                                                            </IconButton>


                                                                                        )}
                                                                                        {/*  {attributes.widgetType !== undefined && (
                                                                                            <AddToMaster widget={widget} layout={l} userRef={userInfo} openSnack={this.props.openSnack} />
                                                                                        )} */}
                                                                                    </Col>
                                                                                    <Col onClick={() => {

                                                                                        this.setState({
                                                                                            openDrawer: true,
                                                                                            currentWidgetKey: widget.key,
                                                                                            currentAttributes: attributes,
                                                                                            title: attributes.title
                                                                                        })
                                                                                    }
                                                                                    }>
                                                                                        {attributes.widgetType !== undefined && (

                                                                                            type !== 'open' && loggedIn && (
                                                                                                <Tooltip title="Settings" placement="top">
                                                                                                    <IconButton >
                                                                                                        <FontAwesomeIcon fontSize={18} icon={faGear} color="#9ec1c9" />
                                                                                                    </IconButton>
                                                                                                </Tooltip>
                                                                                            )

                                                                                        )}
                                                                                    </Col>
                                                                                    {['profile',].includes(attributes.widgetType) && (
                                                                                        <Col >
                                                                                            {takingScreenshot === `${l.i}` ? (<IconButton  ><CircularProgress size={20} /></IconButton>) : (

                                                                                                <IconButton size='large' onClick={() => {
                                                                                                    this.setState({ takingScreenshot: `${l.i}` })

                                                                                                    //create a high quality image of the chart with html2canvas

                                                                                                    html2canvas(document.getElementById(l.i), {
                                                                                                        scale: 5,
                                                                                                        useCORS: true,
                                                                                                        allowTaint: true,


                                                                                                    }).then((canvas) => {
                                                                                                        const dataUrl = canvas.toDataURL('image/png');
                                                                                                        download(dataUrl, `HQ-Chart_${portalInfo.account
                                                                                                            }_${attributes.title}_${moment().format('YYYY-MM-DD')}.png`);
                                                                                                    }).then(() => {
                                                                                                        this.setState({ takingScreenshot: false })
                                                                                                    })















                                                                                                }}>


                                                                                                    <FontAwesomeIcon fontSize={18} icon={faCamera} color="#9ec1c9" />
                                                                                                </IconButton>

                                                                                            )}
                                                                                        </Col>
                                                                                    )}
                                                                                    {['chart', 'contour', 'profile', 'reading'].includes(attributes.widgetType) && (
                                                                                        <Col >
                                                                                            {[`${l.i}`, `${l.i}_title`].includes(takingScreenshot) ? (<IconButton  ><CircularProgress size={20} /></IconButton>) : (

                                                                                                <div>
                                                                                                    <ScreenShotPopover type="public" widgetKey={widgetKey} customScreenshot={(type) => {
                                                                                                        const screenshotKey = type === 'noTitle' ? `${l.i}` : `${l.i}_title`
                                                                                                        this.setState({
                                                                                                            openScreenshotModal: true,
                                                                                                            currentWidgetKey: `${screenshotKey}`,
                                                                                                        })
                                                                                                    }} takeScreenshot={async (type) => {
                                                                                                        const screenshotKey = type === 'noTitle' ? `${l.i}` : `${l.i}_title`
                                                                                                        this.setState({ takingScreenshot: screenshotKey })

                                                                                                        //create a high quality image of the chart with html2canvas

                                                                                                        setTimeout(() => {
                                                                                                            html2canvas(document.getElementById(screenshotKey), {
                                                                                                                scale: 5,
                                                                                                                useCORS: true,
                                                                                                                allowTaint: true,


                                                                                                            }).then((canvas) => {
                                                                                                                const dataUrl = canvas.toDataURL('image/png');
                                                                                                                download(dataUrl, `HQ-Chart_${account.accountName}_${moment().format('YYYY-MM-DD')}.png`);
                                                                                                            }).then(() => {
                                                                                                                this.setState({
                                                                                                                    takingScreenshot: false,
                                                                                                                    openScreenshotModal: false,

                                                                                                                })
                                                                                                            })
                                                                                                        }, 1000);
                                                                                                    }} />
                                                                                                </div>



                                                                                            )}
                                                                                        </Col>
                                                                                    )}
                                                                                    {['chart'].includes(attributes.widgetType) && (
                                                                                        <Col >
                                                                                            <AdvancedWidgetSettings widgetKey={widgetKey}
                                                                                                disableTooltip={() => {
                                                                                                    this.setState({ disableTooltip: widgetKey })
                                                                                                }}
                                                                                                loggedIn={loggedIn}
                                                                                                enableTooltip={() => {
                                                                                                    this.setState({ disableTooltip: false })

                                                                                                }}
                                                                                                chartTitle={attributes.title || ''}

                                                                                                account={account}
                                                                                                attributes={attributes} userInfo={{
                                                                                                    currentAccount: portalInfo.account,
                                                                                                    currentCompany: portalInfo.company,
                                                                                                }}
                                                                                                screenWidth={screenWidth}
                                                                                                openSnack={this.props.openSnack} />
                                                                                        </Col>
                                                                                    )}
                                                                                </Row>
                                                                            </Col>






                                                                        </Row>
                                                                    </Col>

                                                                    <Col id={l.i} span={24} style={{

                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        height: 'calc(100% - 45px)',
                                                                        maxHeight: 'calc(100% - 45px)',
                                                                        padding: 6,
                                                                        overflow: 'hidden',
                                                                        position: 'relative',

                                                                    }}>
                                                                        {attributes.widgetType === undefined && (
                                                                            <Row align="middle" gutter={[8, 8]} justify="center">

                                                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                                                    <FontAwesomeIcon fontSize={38} icon={faTriangleExclamation} color="#9ec1c9" />
                                                                                </Col>
                                                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                                                    <Typography variant='h6'>Select Widget Type</Typography>
                                                                                </Col>
                                                                                <Col span={16} style={{ textAlign: 'center' }}>
                                                                                    <Autocomplete
                                                                                        disableClearable
                                                                                        size="small"
                                                                                        id="combo-box-demo"
                                                                                        renderGroup={(params) => (
                                                                                            <Box key={params.key}>
                                                                                                <Typography fontWeight={800} fontSize={14} p={1}>
                                                                                                    {params.group}
                                                                                                </Typography>
                                                                                                {params.children}
                                                                                            </Box>
                                                                                        )}
                                                                                        groupBy={(option) =>
                                                                                            option.widgetType ? option.widgetType : "No Widget Type"
                                                                                        }
                                                                                        options={[
                                                                                            { value: 'chart', label: 'Chart', widgetType: 'Data' },
                                                                                            { value: 'capacity', label: 'Capacity', widgetType: 'Data' },
                                                                                            { value: 'gauge', label: 'Percent Gauge', widgetType: 'Data' },
                                                                                            { value: 'reading', label: 'Reading', widgetType: 'Data' },
                                                                                            { value: 'profile', label: 'Depth Profile', widgetType: 'Data' },
                                                                                            { value: 'contour', label: 'Isopleth', widgetType: 'Data' },
                                                                                            /* { value: 'horizontalBar', label: 'Horizontal Bar', widgetType: 'Data' }, */
                                                                                            { value: 'map2', label: 'Map', widgetType: 'Spatial' },
                                                                                            { value: 'image', label: 'Image', widgetType: 'Media' },
                                                                                            { value: 'carousel', label: 'Image Carousel', widgetType: 'Media' },
                                                                                            { value: 'timeline', label: 'Image Timeline', widgetType: 'Media' },
                                                                                            { value: 'text', label: 'Text', widgetType: 'Media' },
                                                                                            { value: 'siteInfo', label: 'Site Info', widgetType: 'Media' },
                                                                                            { value: 'urlImage', label: 'Web Camera', widgetType: 'Media' },


                                                                                        ]}
                                                                                        value={
                                                                                            attributes?.widgetType === 'chart' ? "Chart" :
                                                                                                attributes?.widgetType === 'capacity' ? "Capacity" :
                                                                                                    attributes?.widgetType === 'gauge' ? "Percent Gauge" :
                                                                                                        attributes?.widgetType === 'reading' ? "Reading" :
                                                                                                            attributes?.widgetType === 'profile' ? "Depth Profile" :
                                                                                                                attributes?.widgetType === 'contour' ? "Isopleth" :
                                                                                                                    attributes?.widgetType === 'horizontalBar' ? "Horizontal Bar" :
                                                                                                                        attributes?.widgetType === 'map2' ? "Map" :
                                                                                                                            attributes?.widgetType === 'image' ? "Image" :
                                                                                                                                attributes?.widgetType === 'carousel' ? "Image Carousel" :
                                                                                                                                    attributes?.widgetType === 'timeline' ? "Image Timeline" :
                                                                                                                                        attributes?.widgetType === 'text' ? "Text" :
                                                                                                                                            attributes?.widgetType === 'siteInfo' ? "Site Info" :
                                                                                                                                                attributes?.widgetType === 'urlImage' ? "URL Image" :
                                                                                                                                                    null
                                                                                        }
                                                                                        renderInput={(params) => (
                                                                                            <TextField {...params} placeholder="Widget Type" variant="outlined" />
                                                                                        )}
                                                                                        onChange={(a, b) => {
                                                                                            if (b !== null) {
                                                                                                this.updateWidget(widget.key, 'widgetType', b.value)
                                                                                            }
                                                                                        }}
                                                                                        InputProps={{ style: { fontSize: 6 } }}
                                                                                    />
                                                                                </Col>
                                                                            </Row>)}

                                                                        {attributes.widgetType === 'chart' && (

                                                                            <WidgetChart attributes={attributes}
                                                                                widgetKey={widgetKey}

                                                                                userInfo={{
                                                                                    currentAccount: portalInfo.account,
                                                                                    currentCompany: portalInfo.company,
                                                                                }} isZoomed={isZoomed}
                                                                                updateZoom={(zoom) => {

                                                                                    this.setState({ isZoomed: zoom })
                                                                                }} />


                                                                        )}

                                                                        {attributes.widgetType === 'map' && loadingProbes === false && loadingStations === false && (

                                                                            <WidgetMap probes={probes} stations={stations} attributes={attributes} account={account} widgetKey={widgetKey} publicViewable={false}
                                                                                updateAttributes={(key, attributes) => this.saveWidgetAttributes(key, attributes, false, false, true)} />

                                                                        )}
                                                                        {attributes.widgetType === 'map2' && loadingProbes === false && loadingStations === false && userInfo !== null &&
                                                                            Object.values(account).length > 0 && (

                                                                                <WidgetMap2 userInfo={{
                                                                                    currentAccount: portalInfo.account,
                                                                                    currentCompany: portalInfo.company,
                                                                                }} openSnack={this.props.openSnack} probes={probes} stations={stations} screenWidth={screenWidth}
                                                                                    attributes={attributes} account={account} widgetKey={widgetKey} publicViewable={false}
                                                                                    updateAttributes={(key, attributes) => this.saveWidgetAttributes(key, attributes, false, false, true)} />

                                                                            )}
                                                                        {attributes.widgetType === 'map' && ![loadingProbes, loadingStations].includes(false) && (

                                                                            <Row align="middle" gutter={[8, 8]} justify="center">
                                                                                <Paper style={{ padding: 10, textAlign: 'center' }}>
                                                                                    <CircularProgress />
                                                                                </Paper>
                                                                            </Row>

                                                                        )}





                                                                        {attributes.widgetType === 'capacity' && (
                                                                            <WidgetCapacity attributes={attributes} account={account} userInfo={{
                                                                                currentAccount: portalInfo.account,
                                                                                currentCompany: portalInfo.company,
                                                                            }} />
                                                                        )}
                                                                        {attributes.widgetType === 'reading' && (
                                                                            <WidgetReading attributes={attributes} spacing={l} account={account} userInfo={{
                                                                                currentAccount: portalInfo.account,
                                                                                currentCompany: portalInfo.company,
                                                                            }} index={index} />
                                                                        )}
                                                                        {attributes.widgetType === 'forecast' && (
                                                                            <WidgetForecast
                                                                                screenWidth={screenWidth}

                                                                                attributes={attributes} spacing={l} account={account} userInfo={{
                                                                                    currentAccount: portalInfo.account,
                                                                                    currentCompany: portalInfo.company,
                                                                                }} index={index} />
                                                                        )}
                                                                        {attributes.widgetType === 'gauge' && (
                                                                            <WidgetGauge attributes={attributes} account={account} userInfo={{
                                                                                currentAccount: portalInfo.account,
                                                                                currentCompany: portalInfo.company,
                                                                            }} />
                                                                        )}
                                                                        {attributes.widgetType === 'isopleth' && (
                                                                            <WidgetIsopleth attributes={attributes} account={account} userInfo={{
                                                                                currentAccount: portalInfo.account,
                                                                                currentCompany: portalInfo.company,
                                                                            }} />
                                                                        )}
                                                                        {attributes.widgetType === 'text' && (
                                                                            <WidgetText attributes={attributes} account={account} userInfo={{
                                                                                currentAccount: portalInfo.account,
                                                                                currentCompany: portalInfo.company,
                                                                            }} />
                                                                        )}
                                                                        {attributes.widgetType === 'image' && (
                                                                            <WidgetImage attributes={attributes} account={account} userInfo={{
                                                                                currentAccount: portalInfo.account,
                                                                                currentCompany: portalInfo.company,
                                                                            }} />
                                                                        )}
                                                                        {attributes.widgetType === 'urlImage' && (
                                                                            <WidgetURLImage attributes={attributes} account={account} userInfo={{
                                                                                currentAccount: portalInfo.account,
                                                                                currentCompany: portalInfo.company,
                                                                            }} />
                                                                        )}
                                                                        {attributes.widgetType === 'carousel' && Object.values(portalInfo).length > 0 && (
                                                                            <WidgetCarousel attributes={attributes} account={account} userInfo={{
                                                                                currentAccount: portalInfo.account,
                                                                                currentCompany: portalInfo.company,
                                                                            }} />
                                                                        )}
                                                                        {attributes.widgetType === 'profile' && (
                                                                            <WidgetProfileNew attributes={attributes} account={account} userInfo={{
                                                                                currentAccount: portalInfo.account,
                                                                                currentCompany: portalInfo.company,
                                                                            }} />
                                                                        )}
                                                                        {attributes.widgetType === 'timeline' && (
                                                                            <WidgetImageTimeline attributes={attributes} widgetKey={widgetKey} account={account}
                                                                                userInfo={{
                                                                                    currentAccount: portalInfo.account,
                                                                                    currentCompany: portalInfo.company,
                                                                                }}

                                                                            />
                                                                        )}
                                                                        {attributes.widgetType === 'contour' && (
                                                                            <WidgetContour attributes={attributes} account={account} userInfo={{
                                                                                currentAccount: portalInfo.account,
                                                                                currentCompany: portalInfo.company,
                                                                            }} />
                                                                        )}
                                                                        {attributes.widgetType === 'siteInfo' && (
                                                                            <WidgetSiteInfo attributes={attributes} account={account} userInfo={{
                                                                                currentAccount: portalInfo.account,
                                                                                currentCompany: portalInfo.company,
                                                                            }}
                                                                            />
                                                                        )}
                                                                        {/*   {attributes.widgetType === '3D' && (
                                                                        <Widget3D attributes={attributes} account={account} userInfo={userInfo} />
                                                                    )}
                                                                    {attributes.widgetType === 'stock' && (
                                                                        <WidgetChartStock attributes={attributes} account={account} userInfo={userInfo} />
                                                                    )} */}
                                                                        {/*   {attributes.widgetType === 'esri' && (
                                                                        <WidgetMapESRI attributes={attributes} account={account} userInfo={userInfo} />
                                                                    )} */}

                                                                    </Col>
                                                                </Row>
                                                            )
                                                            }


                                                        </Paper>


                                                    </div>
                                                )
                                            }
                                        })}


                                    </GridLayout>
                                )}
                            </Col>

                            <Drawer
                                bodyStyle={{ padding: 0, height: '100%', overflow: 'auto' }}
                                title=""
                                width={screenWidth === 'xs' ? '80vw' : currentAttributes?.widgetType === 'profile' ? '75vw' : '60vw'}
                                placement={'right'}
                                closable={false}
                                onClose={() => {
                                    this.saveWidgetAttributes(currentWidgetKey, currentAttributes, true, true)
                                    this.setState({ readData: true, })
                                }}
                                visible={openDrawer}
                                key={'right'}

                            >
                                <Row justify="center" align="middle">
                                    <Col span={16}>
                                        <Typography variant='h6'>Widget Settings</Typography>
                                    </Col>
                                    <Col span={6} style={{ textAlign: 'right' }}>

                                        <Button style={{ textTransform: 'none', padding: 12, color: '#9ec1c9' }} size='medium' variant="outlined" color="inherit"
                                            onClick={() => {
                                                const newAttributes = { ...currentAttributes, title }

                                                newAttributes?.chartData?.map((d) => {
                                                    return ({ ...d, type: d?.typeHidden || d?.type })
                                                })
                                                this.saveWidgetAttributes(currentWidgetKey, newAttributes, true, true)
                                                this.setState({ title: '', showBackdrop: true })
                                            }
                                            } >
                                            Save <FontAwesomeIcon fontSize={24} style={{ paddingLeft: 10 }} icon={faFloppyDisk} color="#9ec1c9" />
                                        </Button>
                                    </Col>
                                    <Col span={22} style={{ paddingTop: 6 }}>
                                        <Divider />
                                    </Col>
                                    <Col xs={22} style={{ paddingTop: 12 }} >
                                        {openDrawer && (
                                            <Row>
                                                <Col span={24}>
                                                    <Typography fontSize={13} variant="body1">Widget Title</Typography>
                                                </Col>
                                                <Col span={24}>
                                                    <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                        defaultValue={title}
                                                        onChange={(e) => {
                                                            this.handleCheck(e.target.value)
                                                        }} />
                                                </Col>
                                            </Row>
                                        )}
                                    </Col>
                                    <Col xs={22} style={{ paddingTop: 12 }} >
                                        <Row>
                                            <Col span={24}>
                                                <Typography fontSize={13} variant="body1">Type</Typography>
                                            </Col>
                                            <Col span={24}>

                                                <Autocomplete
                                                    disableClearable
                                                    size="small"
                                                    id="combo-box-demo"
                                                    renderGroup={(params) => (
                                                        <Box key={params.key}>
                                                            <Typography fontWeight={800} fontSize={14} p={1}>
                                                                {params.group}
                                                            </Typography>
                                                            {params.children}
                                                        </Box>
                                                    )}
                                                    groupBy={(option) =>
                                                        option.widgetType ? option.widgetType : "No Widget Type"
                                                    }
                                                    options={[
                                                        { value: 'chart', label: 'Chart', widgetType: 'Data' },
                                                        { value: 'capacity', label: 'Capacity', widgetType: 'Data' },
                                                        { value: 'gauge', label: 'Percent Gauge', widgetType: 'Data' },
                                                        { value: 'reading', label: 'Reading', widgetType: 'Data' },
                                                        { value: 'profile', label: 'Depth Profile', widgetType: 'Data' },
                                                        { value: 'contour', label: 'Isopleth', widgetType: 'Data' },
                                                        { value: 'horizontalBar', label: 'Horizontal Bar', widgetType: 'Data' },
                                                        { value: 'map2', label: 'Map', widgetType: 'Spatial' },
                                                        { value: 'image', label: 'Image', widgetType: 'Media' },
                                                        { value: 'carousel', label: 'Image Carousel', widgetType: 'Media' },
                                                        { value: 'timeline', label: 'Image Timeline', widgetType: 'Media' },
                                                        { value: 'text', label: 'Text', widgetType: 'Media' },
                                                        { value: 'siteInfo', label: 'Site Info', widgetType: 'Media' },

                                                    ]}
                                                    value={
                                                        currentAttributes?.widgetType === 'chart' ? "Chart" :
                                                            currentAttributes?.widgetType === 'capacity' ? "Capacity" :
                                                                currentAttributes?.widgetType === 'gauge' ? "Percent Gauge" :
                                                                    currentAttributes?.widgetType === 'reading' ? "Reading" :
                                                                        currentAttributes?.widgetType === 'profile' ? "Depth Profile" :
                                                                            currentAttributes?.widgetType === 'contour' ? "Isopleth" :
                                                                                currentAttributes?.widgetType === 'horizontalBar' ? "Horizontal Bar" :
                                                                                    currentAttributes?.widgetType === 'map2' ? "Map" :
                                                                                        currentAttributes?.widgetType === 'image' ? "Image" :
                                                                                            currentAttributes?.widgetType === 'carousel' ? "Image Carousel" :
                                                                                                currentAttributes?.widgetType === 'timeline' ? "Image Timeline" :
                                                                                                    currentAttributes?.widgetType === 'text' ? "Text" :
                                                                                                        currentAttributes?.widgetType === 'siteInfo' ? "Site Info" :
                                                                                                            null
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField {...params} placeholder="Widget Type" variant="outlined" />
                                                    )}
                                                    onChange={(a, b) => {
                                                        if (b !== null) {
                                                            this.updateWidget(currentWidgetKey, 'widgetType', b.value)
                                                        }
                                                    }}
                                                    InputProps={{ style: { fontSize: 6 } }}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>



                                    <Col span={22} style={{ paddingTop: 8 }}>
                                        {currentAttributes?.widgetType === 'chart' && (<ChartSettings currentWidgetKey={currentWidgetKey} readData={readData}
                                            userInfo={{
                                                currentAccount: portalInfo.account,
                                                currentCompany: portalInfo.company,
                                            }} account={account} screenWidth={screenWidth} openSnack={this.props.openSnack}
                                            attributes={currentAttributes} updateAttributes={((attributes, key) => {

                                                this.saveWidgetAttributes(key, attributes, true, true)
                                                this.setState({ title: '' })
                                            })} />)}
                                    </Col>
                                    <Col span={22} style={{ paddingTop: 8 }}>
                                        {currentAttributes?.widgetType === 'forecast' && (<ForecastSettings currentWidgetKey={currentWidgetKey} readData={readData}
                                            userInfo={userInfo} account={account} screenWidth={screenWidth} openSnack={this.props.openSnack}
                                            attributes={currentAttributes} updateAttributes={((attributes, key) => {

                                                this.saveWidgetAttributes(key, attributes, true, true)
                                                this.setState({ title: '' })
                                            })} />)}
                                    </Col>
                                    <Col span={22} style={{ paddingTop: 8 }}>
                                        {currentAttributes?.widgetType === 'stock' && (<ChartStockSettings currentWidgetKey={currentWidgetKey} readData={readData}
                                            userInfo={{
                                                currentAccount: portalInfo.account,
                                                currentCompany: portalInfo.company,
                                            }} account={account} screenWidth={screenWidth} openSnack={this.props.openSnack}
                                            attributes={currentAttributes} updateAttributes={(() => {
                                                const newAttributes = { ...currentAttributes, title }
                                                this.saveWidgetAttributes(currentWidgetKey, newAttributes, true, true)
                                                this.setState({ title: '' })
                                            })} />)}
                                    </Col>
                                    <Col span={22} style={{ paddingTop: 8 }}>
                                        {currentAttributes?.widgetType === 'contour' && (<ContourSettings currentWidgetKey={currentWidgetKey} readData={readData}
                                            userInfo={{
                                                currentAccount: portalInfo.account,
                                                currentCompany: portalInfo.company,
                                            }} account={account} screenWidth={screenWidth} openSnack={this.props.openSnack}
                                            attributes={currentAttributes} updateAttributes={(() => {
                                                const newAttributes = { ...currentAttributes, title }
                                                this.saveWidgetAttributes(currentWidgetKey, newAttributes, true, true)
                                                this.setState({ title: '' })
                                            })} />)}
                                    </Col>
                                    <Col span={22} style={{ paddingTop: 8 }}>
                                        {currentAttributes?.widgetType === 'map' && (<MapSettings currentWidgetKey={currentWidgetKey}
                                            userInfo={{
                                                currentAccount: portalInfo.account,
                                                currentCompany: portalInfo.company,
                                            }} account={account} screenWidth={screenWidth} openSnack={this.props.openSnack}
                                            attributes={currentAttributes} updateAttributes={((attributes, key) => this.saveWidgetAttributes(key, attributes, false, false))} />)}
                                    </Col>
                                    <Col span={22} style={{ paddingTop: 8 }}>
                                        {currentAttributes?.widgetType === 'esri' && (<MapSettings currentWidgetKey={currentWidgetKey}
                                            userInfo={{
                                                currentAccount: portalInfo.account,
                                                currentCompany: portalInfo.company,
                                            }} account={account} screenWidth={screenWidth} openSnack={this.props.openSnack}
                                            attributes={currentAttributes} updateAttributes={((attributes, key) => this.saveWidgetAttributes(key, attributes, false, false))} />)}
                                    </Col>

                                    <Col span={22} style={{}}>
                                        {currentAttributes?.widgetType === 'capacity' && (<CapacitySettings currentWidgetKey={currentWidgetKey}
                                            userInfo={{
                                                currentAccount: portalInfo.account,
                                                currentCompany: portalInfo.company,
                                            }} account={account} screenWidth={screenWidth} openSnack={this.props.openSnack}
                                            attributes={currentAttributes} updateAttributes={((attributes, key) => this.saveWidgetAttributes(key, attributes, false, false))} />)}
                                    </Col>
                                    <Col span={22} style={{}}>
                                        {currentAttributes?.widgetType === 'reading' && (<ReadingSettings currentWidgetKey={currentWidgetKey} readData={readData}
                                            userInfo={{
                                                currentAccount: portalInfo.account,
                                                currentCompany: portalInfo.company,
                                            }} account={account} screenWidth={screenWidth} openSnack={this.props.openSnack}
                                            attributes={currentAttributes}
                                            updateAttributes={(() => {
                                                const newAttributes = { ...currentAttributes, title }
                                                this.saveWidgetAttributes(currentWidgetKey, newAttributes, true, true)
                                                this.setState({ title: '' })
                                            })} />)}
                                    </Col>
                                    <Col span={22} style={{}}>
                                        {currentAttributes?.widgetType === 'isopleth' && (<IsoplethSettings currentWidgetKey={currentWidgetKey} readData={readData}
                                            userInfo={{
                                                currentAccount: portalInfo.account,
                                                currentCompany: portalInfo.company,
                                            }} account={account} screenWidth={screenWidth} openSnack={this.props.openSnack}
                                            attributes={currentAttributes}
                                            updateAttributes={((attributes, key) => {
                                                /* remove scatter from attributes if series is scatter */

                                                this.saveWidgetAttributes(key, attributes, true, true)
                                                this.setState({ title: '' })
                                            })} />)}
                                    </Col>
                                    <Col span={22} style={{}}>
                                        {currentAttributes?.widgetType === 'text' && (<TextSettings currentWidgetKey={currentWidgetKey} readData={readData}
                                            userInfo={{
                                                currentAccount: portalInfo.account,
                                                currentCompany: portalInfo.company,
                                            }} account={account} screenWidth={screenWidth} openSnack={this.props.openSnack}
                                            attributes={currentAttributes}
                                            updateAttributes={(() => {
                                                const newAttributes = { ...currentAttributes, title }
                                                this.saveWidgetAttributes(currentWidgetKey, newAttributes, true, true)
                                                this.setState({ title: '' })
                                            })} />)}
                                    </Col>
                                    <Col span={22} style={{}}>
                                        {currentAttributes?.widgetType === 'image' && (<ImageSettings currentWidgetKey={currentWidgetKey} readData={readData}
                                            userInfo={{
                                                currentAccount: portalInfo.account,
                                                currentCompany: portalInfo.company,
                                            }} account={account} screenWidth={screenWidth} openSnack={this.props.openSnack}
                                            attributes={currentAttributes} updateAttributes={(() => {
                                                const newAttributes = { ...currentAttributes, title }
                                                this.saveWidgetAttributes(currentWidgetKey, newAttributes, true, true)
                                                this.setState({ title: '' })
                                            })} />)}
                                    </Col>
                                    <Col span={22} style={{}}>
                                        {currentAttributes?.widgetType === 'urlImage' && (<URLImageSettings currentWidgetKey={currentWidgetKey} readData={readData}
                                            userInfo={{
                                                currentAccount: portalInfo.account,
                                                currentCompany: portalInfo.company,
                                            }} account={account} screenWidth={screenWidth} openSnack={this.props.openSnack}
                                            attributes={currentAttributes} updateAttributes={(() => {
                                                const newAttributes = { ...currentAttributes, title }
                                                this.saveWidgetAttributes(currentWidgetKey, newAttributes, true, true)
                                                this.setState({ title: '' })
                                            })} />)}
                                    </Col>
                                    <Col span={22} style={{}}>
                                        {currentAttributes?.widgetType === 'carousel' && (<CarouselSettings currentWidgetKey={currentWidgetKey} readData={readData}
                                            userInfo={{
                                                currentAccount: portalInfo.account,
                                                currentCompany: portalInfo.company,
                                            }} account={account} screenWidth={screenWidth} openSnack={this.props.openSnack}
                                            attributes={currentAttributes} updateAttributes={(() => {
                                                const newAttributes = { ...currentAttributes, title }
                                                this.saveWidgetAttributes(currentWidgetKey, newAttributes, true, true)
                                                this.setState({ title: '' })
                                            })} />)}
                                    </Col>
                                    <Col span={22} style={{}}>
                                        {currentAttributes?.widgetType === 'gauge' && (<GaugeSettings currentWidgetKey={currentWidgetKey} readData={readData}
                                            userInfo={{
                                                currentAccount: portalInfo.account,
                                                currentCompany: portalInfo.company,
                                            }} account={account} screenWidth={screenWidth} openSnack={this.props.openSnack}
                                            attributes={currentAttributes} updateAttributes={(() => {
                                                const newAttributes = { ...currentAttributes, title }
                                                this.saveWidgetAttributes(currentWidgetKey, newAttributes, true, true)
                                                this.setState({ title: '' })
                                            })} />)}
                                    </Col>
                                    <Col span={22} style={{}}>
                                        {currentAttributes?.widgetType === 'profile' && (<ProfileSettings currentWidgetKey={currentWidgetKey} readData={readData}
                                            userInfo={{
                                                currentAccount: portalInfo.account,
                                                currentCompany: portalInfo.company,
                                            }} account={account} screenWidth={screenWidth} openSnack={this.props.openSnack}
                                            attributes={currentAttributes} updateAttributes={(() => {
                                                const newAttributes = { ...currentAttributes, title }
                                                this.saveWidgetAttributes(currentWidgetKey, newAttributes, true, true)
                                                this.setState({ title: '' })
                                            })} />)}
                                    </Col>
                                    <Col span={22} style={{}}>
                                        {currentAttributes?.widgetType === 'siteInfo' && (<SiteInfoSettings currentWidgetKey={currentWidgetKey} readData={readData}
                                            userInfo={{
                                                currentAccount: portalInfo.account,
                                                currentCompany: portalInfo.company,
                                            }} account={account} screenWidth={screenWidth} openSnack={this.props.openSnack}
                                            attributes={currentAttributes} updateAttributes={(() => {
                                                const newAttributes = { ...currentAttributes, title }
                                                this.saveWidgetAttributes(currentWidgetKey, newAttributes, true, true)
                                                this.setState({ title: '' })
                                            })} />)}
                                    </Col>

                                    <Col span={22} style={{ paddingTop: 6 }}>
                                        <Divider />
                                    </Col>

                                    <Col style={{ paddingTop: 24, paddingLeft: 20 }}>
                                        <Popconfirm
                                            title="Are you sure to duplicate this widget?"
                                            onConfirm={() => this.duplicateWidget(currentWidgetKey)}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button style={{ textTransform: 'none', padding: 12, border: '1px solid #404050', color: '#404050' }} size='medium' variant="outlined" color="primary"
                                            >
                                                Duplicate Widget
                                            </Button>
                                        </Popconfirm>

                                    </Col>

                                    <Col style={{ paddingTop: 24, paddingLeft: 10, textAlign: 'right', paddingRight: 20 }}>
                                        <Popconfirm
                                            title="Are you sure to delete this widget?"
                                            onConfirm={() => this.onRemoveItem(currentWidgetKey)}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button style={{ textTransform: 'none', padding: 12, border: '1px solid red', color: 'red' }} size='medium' variant="outlined" color="secondary"
                                            >
                                                Delete Widget
                                            </Button>
                                        </Popconfirm>

                                    </Col>





                                </Row>
                            </Drawer>




                        </Row>
                    </Col >

                    <Backdrop
                        sx={{ color: '#fff', textAlign: 'center', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={showBackdrop}

                    >
                        <Row>
                            <Col span={24}>
                                <Spin size='large' />
                            </Col>
                            <Col span={24}> <Typography variant='h5'>Saving Widget...</Typography></Col>
                        </Row>

                    </Backdrop>

                    <Modal title="" visible={openScreenshotModal} onCancel={() => this.setState({ openScreenshotModal: false })} footer={null} height={'80%'} width={'80%'} style={{ borderRadius: 40 }} >
                        <Row gutter={[4, 4]}>
                            <Col span={19}>



                                {portalInfo?.account && portalInfo?.company && (
                                    <Widget legendSize={legendSize} />
                                )}







                            </Col>
                            <Col span={1}>
                                <Divider orientation='vertical' />
                            </Col>
                            <Col span={4} style={{ textAlign: 'left' }}>
                                <Row>
                                    <Col span={24} style={{ paddingTop: 20 }}>
                                        <Typography variant='body1'>Legend Size</Typography>
                                        <OutlinedInput
                                            size='small'
                                            value={legendSize}
                                            onChange={(e) => {
                                                this.setState({ legendSize: e.target.value })
                                            }}
                                            type='number'
                                            inputProps={{
                                                min: 4,
                                                max: 32,
                                                step: 1
                                            }}
                                        />

                                        <Typography display="inline" variant='body2'>px</Typography>

                                        <Typography style={{ marginTop: 10 }} variant='body1'>X Axis Size</Typography>
                                        <OutlinedInput
                                            size='small'
                                            value={axisSize}
                                            onChange={(e) => {
                                                this.setState({ axisSize: e.target.value })
                                            }}
                                            type='number'
                                            inputProps={{
                                                min: 4,
                                                max: 32,
                                                step: 1
                                            }}
                                        />

                                        <Typography display="inline" variant='body2'>px</Typography>
                                        <Typography style={{ marginTop: 10 }} variant='body1'>X Axis Angle</Typography>
                                        <OutlinedInput
                                            size='small'
                                            value={xAxisAngle}
                                            onChange={(e) => {
                                                this.setState({ xAxisAngle: e.target.value })
                                            }}
                                            type='number'
                                            inputProps={{
                                                min: -360,
                                                max: 360,
                                                step: 1
                                            }}
                                        />

                                        <Typography display="inline" variant='body2'>degrees</Typography>
                                        <Typography style={{ marginTop: 10 }} variant='body1'>Y Axis Size</Typography>
                                        <OutlinedInput
                                            size='small'
                                            value={yAxisSize}
                                            onChange={(e) => {
                                                this.setState({ yAxisSize: e.target.value })
                                            }}
                                            type='number'
                                            inputProps={{
                                                min: 4,
                                                max: 32,
                                                step: 1
                                            }}
                                        />

                                        <Typography display="inline" variant='body2'>px</Typography>

                                        <Typography style={{ marginTop: 10 }} variant='body1'>Reference Size</Typography>
                                        <OutlinedInput
                                            size='small'
                                            value={referenceSize}
                                            onChange={(e) => {
                                                this.setState({ referenceSize: e.target.value })
                                            }}
                                            type='number'
                                            inputProps={{
                                                min: 4,
                                                max: 32,
                                                step: 1
                                            }}
                                        />
                                        <Typography display="inline" variant='body2'>px</Typography>
                                        <Typography variant='body1'>Height</Typography>
                                        <OutlinedInput
                                            size='small' display="inline"
                                            value={chartHeight}
                                            onChange={(e) => {
                                                this.setState({ chartHeight: e.target.value })
                                            }}
                                            type='number'
                                            inputProps={{
                                                min: 1,
                                                max: 12,
                                                step: 1
                                            }}
                                        />  <Typography display="inline" variant='body2'>in</Typography>
                                        <Typography variant='body1'>Width</Typography>
                                        <OutlinedInput
                                            size='small' display="inline"
                                            value={chartWidth}
                                            onChange={(e) => {
                                                this.setState({ chartWidth: e.target.value })
                                            }}
                                            type='number'
                                            inputProps={{
                                                min: 1,
                                                max: 12,
                                                step: 1
                                            }}
                                        />  <Typography display="inline" variant='body2'>in</Typography>

                                        <Typography variant='body1'>Date Interval</Typography>
                                        <Select
                                            size='small'
                                            value={dateInterval}
                                            onChange={(e) => {
                                                this.setState({ dateInterval: e.target.value })
                                            }}
                                            style={{ width: '100%' }}
                                        >
                                            <MenuItem value='day'>Daily</MenuItem>
                                            <MenuItem value='week'>Weekly</MenuItem>
                                            <MenuItem value='biweekly'>Biweekly</MenuItem>
                                            <MenuItem value='monthly'>Monthly</MenuItem>
                                            <MenuItem value='bimonthly'>Bimonthly</MenuItem>
                                            <MenuItem value='quarterly'>Quarterly</MenuItem>
                                            <MenuItem value='semiannually'>Semi Annually</MenuItem>
                                            <MenuItem value='yearly'>Yearly</MenuItem>


                                        </Select>


                                    </Col>

                                </Row>




                                <Button variant="outlined" color="primary" size="small" display="inline"
                                    style={{ textTransform: 'none', border: '1px solid #404050', color: '#404050', marginTop: 20 }}

                                    onClick={() => {
                                        this.setState({ takingScreenshot: currentWidgetKey })

                                        //create a high quality image of the chart with html2canvas

                                        html2canvas(document.getElementById('customChart'), {
                                            scale: 5,
                                            useCORS: true,
                                            allowTaint: true,


                                        }).then((canvas) => {
                                            const dataUrl = canvas.toDataURL('image/png');
                                            download(dataUrl, `HQ-Chart_${portalInfo.account}_${moment().format('YYYY-MM-DD')}.png`);
                                        }).then(() => {
                                            this.setState({
                                                takingScreenshot: false,
                                                openScreenshotModal: false,

                                            })
                                        })

                                    }}>Take Screenshot</Button>
                            </Col>
                        </Row>
                    </Modal>

                </Row >
            </div>
        );
    }
}