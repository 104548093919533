import React from 'react';
import { Col, Row } from 'antd';
import { Button, CircularProgress } from '@mui/material';
import { jsPDF } from "jspdf";
import EmailIcon from '@mui/icons-material/Email';
import DownloadIcon from '@mui/icons-material/Download';
import * as htmlToImage from 'html-to-image';
import moment from 'moment';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import uuid from 'react-uuid';
import axios, * as others from 'axios';

const leftPadding = 40
const topPadding = 40;
const rightPadding = 40
const bottomPadding = 10
const WIDTH = 595.28;
const HEIGHT = 841.89;
const defaultFontSize = 12;


var convert = require('convert-units')


/**
 *
 * UTIL functions
 */

function addText(doc: jsPDF, options: any) {
    let { text, x, y, textColor, fontSize, fontStyle, textOptions } = options;
    if (textColor) {
        doc.setTextColor(textColor)
    }
    if (fontSize) {
        doc.setFontSize(fontSize)
    }
    if (fontStyle) {
        doc.setFont(undefined, fontStyle)
    }
    if (textOptions) {
        doc.text(text, x, y, textOptions)
        return
    }
    doc.text(text, x, y)
    doc.setFont(undefined, "normal")
    doc.setFontSize(defaultFontSize)
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const horizontalLine = (doc: jsPDF, x: number, y: number, width: number, colors: number[] = [148, 186, 195]) => {
    doc.setLineWidth(.5);
    doc.setDrawColor(colors[0], colors[1], colors[2]);
    doc.line(x, y, x + width, y)
}

const textSettings = (doc, settings) => {
    doc.setFontSize(settings.fontSize || 12)
    doc.setTextColor(...settings.textColor || "#000000")
    doc.setFont(undefined, settings.fontStyle || "normal")
}

function multilineTextWithBold(doc, options: { text: string, x: number, y: number, width: number, lineSpacing?: number, fontSize?: number }) {

    const isBoldOpen = (arrayLength, valueBefore = false) => {
        const isEven = arrayLength % 2 === 0;
        return valueBefore !== isEven;
    }

    let { lineSpacing, fontSize } = options = {
        x: leftPadding,
        y: 0,
        width: WIDTH - leftPadding - rightPadding,
        lineSpacing: 14,
        fontSize: 12,
        ...options
    }
    let startX = options.x;
    let startY = options.y;
    textSettings(doc, options)
    let textMap = doc.splitTextToSize(
        options.text,
        options.width,
    );

    const startXCached = startX;
    let boldOpen = false;
    textMap.map((text, i) => {
        if (text) {
            const arrayOfNormalAndBoldText = text.split('**');
            const boldStr = 'bold';
            const normalOr = 'normal';
            arrayOfNormalAndBoldText.map((textItems, j) => {
                doc.setFont(undefined, boldOpen ? normalOr : boldStr);
                if (j % 2 === 0) {
                    doc.setFont(undefined, boldOpen ? boldStr : normalOr);
                }
                doc.text(textItems, startX, startY);
                startX = startX + doc.getStringUnitWidth(textItems) * fontSize;
            });
            boldOpen = isBoldOpen(arrayOfNormalAndBoldText.length, boldOpen);
            startX = startXCached;
            startY += lineSpacing;
        }
    });
    return startY - lineSpacing;
}

function checkForNewPage(doc: jsPDF, lastY: number, defaultReturn: number) {
    if (lastY > HEIGHT - bottomPadding) {
        doc.addPage();
        doc.setPage(doc.getNumberOfPages());
        return topPadding
    }
    return defaultReturn
}

function getDataUri(url) {
    return new Promise(resolve => {
        var image = new Image();
        image.setAttribute('crossOrigin', 'anonymous'); //getting images from external domain

        image.onload = function () {
            var canvas = document.createElement('canvas');
            canvas.width = this.naturalWidth;
            canvas.height = this.naturalHeight;

            //next three lines for white background in case png has a transparent background
            var ctx = canvas.getContext('2d');
            ctx.fillStyle = '#fff';  /// set white fill style
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            canvas.getContext('2d').drawImage(this, 0, 0);

            resolve(canvas.toDataURL('image/jpeg'));
        };

        image.src = url;
    })
}

async function addHeaderSection(doc: jsPDF, state: any) {
    const { company, account, userInfo, item, type, images, imagesFiles, distributionList } = state;
    const weather = item.weather;

    // describe the texts
    let verticalPadding = 8;
    let fontSize = 12;
    doc.setFontSize(defaultFontSize)
    const textHeight = doc.getTextDimensions(company.companyName).h
    const firstPageExtraTopPadding = 25;
    let startY = topPadding + firstPageExtraTopPadding
    const accountYOffset = 8
    const weatherYOffset = 12
    const descriptionYOffset = 8;
    account.address = doc.splitTextToSize(account.address || "", (WIDTH - leftPadding - rightPadding) / 2)
    const addressHeight = account.address.length * 16;
    let yOffset = 20 + accountYOffset + weatherYOffset + addressHeight;
    const column1Data = [
        {
            text: company.companyName,
            x: leftPadding,
            y: startY,
            fontSize: 20
        },
        {
            text: account.accountName,
            x: leftPadding,
            y: startY + textHeight + verticalPadding + accountYOffset,
            fontSize: 24
        },
        {
            text: account.address || "",
            x: leftPadding,
            y: startY + (textHeight + verticalPadding) * 2 + accountYOffset
        },
        {
            text: `${account.countyName || ''} County`,
            x: leftPadding,
            y: startY + (textHeight + verticalPadding) * 2 + accountYOffset + addressHeight
        },
        {
            text: `${convert(weather.temp).from('K').to('F').toFixed(0)} F`,
            x: leftPadding,
            y: startY + (textHeight + verticalPadding) * 4 + yOffset,
            fontSize: 36
        },
        {
            text: weather.description.split(" ").map(capitalizeFirstLetter).join(" "),
            x: leftPadding,
            y: startY + (textHeight + verticalPadding) * 5 + yOffset + descriptionYOffset,
            fontSize: 20
        },
        {
            text: `Wind: ${convert(weather.wind_speed).from('m/s').to('m/h').toFixed(0)} mph`,
            x: leftPadding,
            y: startY + (textHeight + verticalPadding) * 6 + yOffset + descriptionYOffset,
            fontSize
        },
        {
            text: `${weather.humidity}% Humidity`,
            x: leftPadding,
            y: startY + (textHeight + verticalPadding) * 7 + yOffset + descriptionYOffset,
            fontSize
        }
    ]

    column1Data.forEach(d => {
        addText(doc, d)
    })

    // add the weather image


    const url = await htmlToImage.toPng(document.getElementById('my-node'))
        .then((dataUrl) => {
            return dataUrl
        });

    console.log(url)

    if (url !== 'data:,') {
        var weatherImage = await getDataUri(url);

        const ratio = doc.getImageProperties(weatherImage).width / doc.getImageProperties(weatherImage).height;
        const ww = 60
        const wh = 60
        doc.addImage(weatherImage, WIDTH / 2 - (ww * ratio), startY + (textHeight + verticalPadding) * 2 + yOffset + descriptionYOffset, ww * ratio, wh);
    }

    fontSize = 10
    doc.setFontSize(fontSize)
    // add second column
    let startX = WIDTH / 2 + 120
    verticalPadding = 14

    //add the data for the labels
    const column2Data = [
        {
            text: "Log Number",
            x: startX,
            y: startY,
            textOptions: {
                align: "right"
            }
        },
        {
            text: "Log Date",
            x: startX,
            y: startY + textHeight + verticalPadding,
            textOptions: {
                align: "right"
            }
        },
        {
            text: "Time in",
            x: startX,
            y: startY + (textHeight + verticalPadding) * 2,
            textOptions: {
                align: "right"
            }
        },
        {
            text: "Lead Staff",
            x: startX,
            y: startY + (textHeight + verticalPadding) * 3,
            textOptions: {
                align: "right"
            }
        },
        {
            text: `Additional Staff`,
            x: startX,
            y: startY + (textHeight + verticalPadding) * 4,
            textOptions: {
                align: "right"
            }
        }
    ]
    column2Data.forEach(d => {
        addText(doc, d)
    })

    const fieldValueSpacing = 16;
    startX += fieldValueSpacing;
    startY += 1.5

    doc.setTextColor("#000000")
    //add the values for the labels
    const column2DataLabelValues = [
        {
            text: `${item.logNumber}`,
            x: startX,
            y: startY,
            fontSize
        },
        {
            text: `${moment(item.date, 'x').format('L')}`,
            x: startX,
            y: startY + textHeight + verticalPadding,
            fontSize
        },
        {
            text: `${moment(item.date, 'x').format('LT')}`,
            x: startX,
            y: startY + (textHeight + verticalPadding) * 2,
            fontSize
        },
        {
            text: `${item.leadStaff.label}`,
            x: startX,
            y: startY + (textHeight + verticalPadding) * 3,
            fontSize
        }
    ]
    if (item.additionalStaff && item.additionalStaff.length > 0) {
        const value = item.additionalStaff.map((g) => (`${g.label}`)).join(', ')
        const textSplit = doc.splitTextToSize(value, WIDTH - rightPadding - startX)//as string[]).join(', ')
        column2DataLabelValues.push({
            text: textSplit,
            x: startX,
            y: startY + (textHeight + verticalPadding) * 4,
            fontSize
        })
    }
    column2DataLabelValues.forEach(d => {
        addText(doc, d)
    })


    // add horizontal dividers
    doc.setLineWidth(2);
    doc.setDrawColor(148, 186, 195);
    startY = topPadding + firstPageExtraTopPadding + (textHeight + verticalPadding) * 2.5 + 18 + account.address.length * 8
    doc.line(leftPadding, startY, WIDTH / 2, startY)

    doc.setLineWidth(0.6);
    doc.setDrawColor(0, 0, 0);
    startY = topPadding + firstPageExtraTopPadding + (textHeight + verticalPadding) * 6 + yOffset + descriptionYOffset
    doc.line(leftPadding, startY, WIDTH / 2, startY);

    return new Promise((resolve, _) => {
        resolve(startY);
    })
}

export async function generatePDF(state) {

    const { imagesFiles, item } = state
    const { lineItems } = item
    const doc = new jsPDF({
        unit: "pt"
    });

    let lastY = await addHeaderSection(doc, state)

    const fieldLogDetails = imagesFiles.map((imageFile, index) => ({
        url: imageFile.url,
        description: item.files[index].description
    }))

    lastY = await addServicesAndProducts(doc, lastY, lineItems);

    lastY = await addNotes(doc, lastY + 15, item.fieldNotes)

    await addFieldLogPhotos(doc, lastY, fieldLogDetails)


    return new Promise((resolve) => {
        resolve(doc)
    })
}

async function addServicesAndProducts(doc: jsPDF, lastY: number, lineItems) {
    let startY = lastY + 60;
    horizontalLine(doc, leftPadding, lastY + 30, WIDTH - leftPadding - rightPadding)

    startY = checkForNewPage(doc, startY, startY);

    let fontSize = 20;
    doc.setFontSize(fontSize)
    doc.text(`Services & Products`, leftPadding, startY);

    const newItems = lineItems === undefined ? [] : lineItems > 0 ? lineItems : [];

    const sAndPData = newItems.map(lineItem => {
        const {
            quantity,
            units,
            inScope,
            targetSpecies,
            applicationMethod,
            dosageRate,
            dosageRateUnits,
            supplyStock,
            graphics
        } = lineItem
        const type = lineItem.psType.toLowerCase()
        const graphicsData = (JSON.parse(graphics || "[]")).map(gData => {
            const { attributes } = gData
            const { area, areaUnits, length, lengthUnits, lat, lon } = attributes

            const areaValue = `Area: ${area} ${areaUnits}`
            const areaText = areaValue ? `${areaValue}\n` : ""

            const lengthValue = `Perimeter: ${length} ${lengthUnits}`
            const lengthText = lengthValue ? `${lengthValue}\n` : ""

            const latValue = `Lat: ${lat}`
            const latText = latValue ? `${latValue}\n` : ""

            const lonValue = `Lon: ${lon}`
            const lonText = latValue ? `${lonValue}\n` : ""

            return `${areaText}${lengthText}${latText}${lonText}`
        })

        return {
            title: lineItem.name,
            titleDescription: `**Type:** ${type === 'products' ? "Product" : "Service"} | **Category:**  ${type === 'products' ? lineItem.productType.label : lineItem.serviceType.label} `,
            imageURL: lineItem.imageThumb || "",
            description: lineItem.description || "",
            quantity: quantity || "0",
            units: units || "",
            inScope: Boolean(inScope) ? "In-Scope" : "Out of Scope",
            targetSpecies: targetSpecies || "",
            applicationMethod: applicationMethod ? applicationMethod.label : "",
            dosageRate: `${dosageRate || ""} ${dosageRateUnits || ""}`,
            stockLocation: supplyStock ? supplyStock.name : "",
            graphicsData: graphicsData,
            type: type
        }
    })

    startY += 28
    fontSize = 14;
    doc.setFontSize(fontSize)
    let startX = leftPadding;
    const defaultWidth = (WIDTH - leftPadding - rightPadding) / 2.5

    async function estimateHeight(d, startY, discardPage: boolean = true) {
        const defaultStartY = startY;
        const { title, titleDescription, description, imageURL } = d;
        doc.setFont(undefined, 'bold')
        doc.text(title, startX, startY)
        doc.setFont(undefined, 'normal')
        const imageY = startY;
        startY += 18
        startY = multilineTextWithBold(doc, {
            text: titleDescription,
            x: startX,
            y: startY,
            width: defaultWidth,
            fontSize: 10
        })

        startY += 20
        startY = multilineTextWithBold(doc, {
            text: description,
            x: startX,
            y: startY,
            width: defaultWidth,
            fontSize: 10
        })

        const imageWidth = 260;
        const imageHeight = 150;
        const firstColumnLastY = startY;
        if (imageURL) {
            const imageData = await getDataUri(imageURL.fileLink)
            doc.addImage(imageData, 'JPEG', startX + defaultWidth + 30, imageY, imageWidth, imageHeight, uuid(), 'FAST');
        }

        if (discardPage) {
            const pageCount = doc.getNumberOfPages();
            doc.deletePage(pageCount);
        }
        return (Math.max(firstColumnLastY, imageY + imageHeight)) - defaultStartY;
    }

    for (const d of sAndPData) {
        doc.addPage()
        doc.setPage(doc.getNumberOfPages())
        // temporarily calculate the height that will be required
        const height = await estimateHeight(d, topPadding)
        startY = checkForNewPage(doc, startY + height, startY)
        await estimateHeight(d, startY, false)

        // add other key value
        const verticalPadding = 10;
        const keyValueHeight = 30;
        let { targetSpecies, applicationMethod, type, graphicsData, dosageRate, stockLocation, quantity } = d;

        if (type === 'products') {

            doc.setLineHeightFactor(1.6);
            startY += height + 20
            let textY = checkForNewPage(doc, startY + verticalPadding + keyValueHeight, startY + verticalPadding)
            const columnWidth = (WIDTH - leftPadding - rightPadding) / 3

            multilineTextWithBold(doc, {
                text: `**Quantity**\n${quantity}`,
                x: startX,
                y: textY,
                fontSize: 12
            })

            multilineTextWithBold(doc, {
                text: `**Target Species**\n${targetSpecies}`,
                x: startX + columnWidth,
                y: textY,
                fontSize: 12
            })

            multilineTextWithBold(doc, {
                text: `**Application Method**\n${applicationMethod || ""}`,
                x: startX + columnWidth * 2,
                y: textY,
                fontSize: 12
            })
            startY = textY + 30
            textY = checkForNewPage(doc, startY + verticalPadding + keyValueHeight, startY + verticalPadding)
            multilineTextWithBold(doc, {
                text: `**Dosage Rate**\n${dosageRate}`,
                x: startX + columnWidth,
                y: textY,
                fontSize: 12
            })

            multilineTextWithBold(doc, {
                text: `**Stock Location**\n${stockLocation || ""}`,
                x: startX + columnWidth * 2,
                y: textY,
                fontSize: 12
            })
        }

        const rectHeight = 80;
        const rectWidth = 210;

        // draw the graphics
        graphicsData = graphicsData || []
        startY += 60
        for (let i = 0; i < graphicsData.length; i++) {
            doc.setLineHeightFactor(1.6);
            doc.setFontSize(10)
            startY += verticalPadding
            startY = checkForNewPage(doc, startY + rectHeight, startY);
            doc.roundedRect(startX, startY, rectWidth, rectHeight, 3, 3, 'D')
            doc.text(graphicsData[i], startX + 12, startY + 20)
            startY += rectHeight
        }
        doc.setLineHeightFactor(1);
        horizontalLine(doc, leftPadding, startY + 20, WIDTH - leftPadding - rightPadding)

        doc.setFontSize(fontSize)
        startX = leftPadding;
        startY += 40
    }

    return startY
}

async function addNotes(doc: jsPDF, lastY: number, notes: string) {
    const title = "Notes"

    function estimateHeight(startY, discardPage: boolean = true) {
        if (discardPage) {
            doc.addPage();
            doc.setPage(doc.getNumberOfPages())
        }
        const defaultStartY = startY;
        startY += 15
        doc.setFont(undefined, 'bold')
        doc.text(title || "", leftPadding, startY + 9)
        doc.setFont(undefined, 'normal')
        startY += 28

        startY = multilineTextWithBold(doc, {
            text: notes || "",
            x: leftPadding,
            y: startY,
            width: WIDTH - leftPadding - rightPadding,
            fontSize: 10
        })

        if (discardPage) {
            const pageCount = doc.getNumberOfPages();
            doc.deletePage(pageCount);
        }
        return startY - defaultStartY;
    }


    const noteHeight = estimateHeight(lastY)
    let startY = checkForNewPage(doc, lastY + noteHeight, lastY)
    horizontalLine(doc, leftPadding, startY - 10, WIDTH - leftPadding - rightPadding)
    let fontSize = 20;
    doc.setFontSize(fontSize)
    estimateHeight(lastY, false)
    return startY + noteHeight
}


async function addFieldLogPhotos(doc: jsPDF, lastY: number, images: { url: string, description: string }[]) {

    let fontSize = 10;
    doc.setFontSize(fontSize)

    const imagePaddingX = 20;
    let dimensions = images.map(d => {
        const defaultWidth = (WIDTH - leftPadding - rightPadding - rightPadding) / 3;
        const defaultHeight = (WIDTH - leftPadding - rightPadding - rightPadding) / 3

        // calculate the height that will be required by the description of the image
        const descriptionTextArray = doc.splitTextToSize(d.description || "", defaultWidth)
        const descriptionTextHeight = (12) * descriptionTextArray.length
        return {
            data: d,
            descriptionArray: descriptionTextArray,
            descriptionTextHeight: descriptionTextHeight,
            imageWidth: defaultWidth,
            imageHeight: defaultHeight,
            width: defaultWidth,
            height: defaultHeight + descriptionTextHeight,
            verticalSpacing: descriptionTextArray.length > 0 ? 20 : 0,
            x: 0
        }
    })

    /*
    * while images not empty
    *    row = []
    *    while image can fit to current row
    *        add it to row, remove it from images
    *    base on the height in the selected row, if they can fit to the current row, draw, otherwise, addNewPage then draw
    * */

    let startY = lastY + 30;
    //startY = checkForNewPage(doc, startY, startY);
    let titleDrawn = false;

    const imageTopPadding = 20;
    let count = 0
    while (count < dimensions.length) {
        const row = [];
        let startX = leftPadding;
        const lastX = WIDTH - rightPadding;
        while (count < dimensions.length && startX + dimensions[count].width <= lastX) {
            const dim = dimensions[count++]
            dim.x = startX
            row.push(dim)
            startX += dim.width + imagePaddingX
        }
        startX = leftPadding;
        // determine if new page is required
        const maxHeight = Math.max(...row.map(d => d.height))
        const marginBottom = titleDrawn ? 0 : 40
        const marginTop = titleDrawn ? 0 : 20
        startY = checkForNewPage(doc, startY + imageTopPadding + maxHeight + marginTop + marginBottom, startY)
        if (!titleDrawn) {
            horizontalLine(doc, leftPadding, startY - 10, WIDTH - leftPadding - rightPadding)
            startY += marginTop
            fontSize = 20;
            doc.setFontSize(fontSize)
            doc.text(`Field Log Photos`, leftPadding, startY);
            titleDrawn = true
            fontSize = 10
            doc.setFontSize(fontSize)
            startY += marginBottom
        }
        for (const r of row) {
            doc.addImage(r.data.url, r.x, startY, r.imageWidth, r.imageHeight);
            doc.text(r.descriptionArray, r.x, startY + r.imageHeight + r.verticalSpacing)
        }
        startY += maxHeight + imageTopPadding
    }
}


export default class MakePDF extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            company: this.props.company,
            type: this.props.type,
            creating: true,
            item: this.props.item,
            images: [],
            imagesFiles: [],
            imageList: [],
            distributionList: [],
            progress: 0
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
        if (this.props.company !== prevProps.company) {
            this.setState({ company: this.props.company })
        }
        if (this.props.item !== prevProps.item) {
            this.setState({ item: this.props.item })
        }
        if (this.props.distributionList !== prevProps.distributionList) {
            this.setState({ distributionList: this.props.distributionList })
        }

    }

    async componentDidMount() {
        window.scrollTo(0, 0)


        function toDataURL(url, callback) {
            var xhr = new XMLHttpRequest();
            xhr.onload = function () {

                var reader = new FileReader();
                reader.onloadend = function () {
                    callback(reader.result);
                }
                reader.readAsDataURL(xhr.response);
            };
            xhr.open('GET', url);
            xhr.responseType = 'blob';
            xhr.send();
        }


        const { item } = this.state;
        const images = []
        const imagesFiles = []
        const items = []

        if (item.files !== undefined && item.files.length > 0) {
            item.files.map((l) => {
                items.push({ key: l.key, type: 'file', url: l.fileLink, b64: '' })
            });
        }
        if (item !== undefined && item.lineItems !== undefined && item.lineItems.length > 0) {
            item.lineItems.map((l) => {
                items.push({ key: l.key, type: 'map', url: l.imageThumb !== undefined && l.imageThumb.fileLink !== undefined ? l.imageThumb.fileLink : '', b64: '' })
            });
        }



        if (item.files === undefined) {
            this.setState({ creating: false })
        }

        setTimeout(() => {
            items.map(async (l, i) => {
                const url = l.url;

                toDataURL(url, async (dataUrl) => {

                    var weather = await getDataUri(dataUrl);


                    if (l.type == 'file') {
                        imagesFiles.push({ key: `${i}`, url: weather })
                    } else {
                        images.push({ key: `${i}`, url: weather })
                    }


                    if (i + 1 === items.length) {
                        this.setState({ creating: false })
                    }


                })

            })


            this.setState({ images, imagesFiles })
        }, 1000);
    }

    createPDF = async () => {
        const { userInfo, type, distributionList } = this.state;
        this.setState({ creating: true })
        let d = await generatePDF(this.state)
        if (type === 'download') {
            d.save("Field_Log.pdf");
            this.setState({ creating: false })
        }

        if (type === 'email') {
            const blob = d.output('blob');
            var id = uuid()
            const storage = getStorage();
            const storageRef = ref(storage, `${userInfo.currentCompany}/fieldLogs/${id}`);

            const uploadTask = uploadBytesResumable(storageRef, blob);
            uploadTask.on('state_changed',
                (snapshot) => {
                    var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    this.setState({ progress })
                    console.log('Upload is ' + progress + '% done');


                    switch (snapshot.state) {
                        case 'paused':
                            console.log('Upload is paused');
                            break;
                        case 'running':
                            console.log('Upload is running');
                            break;
                    }
                },
                (error) => {
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        console.log('File available at', downloadURL);

                        const body = `<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml"
                        xmlns:o="urn:schemas-microsoft-com:office:office">
                    
                    <head>
                        <!-- NAME: SELL PRODUCTS -->
                        <!--[if gte mso 15]>
                            <xml>
                                <o:OfficeDocumentSettings>
                                <o:AllowPNG/>
                                <o:PixelsPerInch>96</o:PixelsPerInch>
                                </o:OfficeDocumentSettings>
                            </xml>
                            <![endif]-->
                        <meta charset="UTF-8">
                        <meta http-equiv="X-UA-Compatible" content="IE=edge">
                        <meta name="viewport" content="width=device-width, initial-scale=1">
                        <title>*|MC:SUBJECT|*</title>
                    
                        <style type="text/css">
                            p {
                                margin: 10px 0;
                                padding: 0;
                            }
                    
                            table {
                                border-collapse: collapse;
                            }
                    
                            h1,
                            h2,
                            h3,
                            h4,
                            h5,
                            h6 {
                                display: block;
                                margin: 0;
                                padding: 0;
                            }
                    
                            img,
                            a img {
                                border: 0;
                                height: auto;
                                outline: none;
                                text-decoration: none;
                            }
                    
                            body,
                            #bodyTable,
                            #bodyCell {
                                height: 100%;
                                margin: 0;
                                padding: 0;
                                width: 100%;
                            }
                    
                            .mcnPreviewText {
                                display: none !important;
                            }
                    
                            #outlook a {
                                padding: 0;
                            }
                    
                            img {
                                -ms-interpolation-mode: bicubic;
                            }
                    
                            table {
                                mso-table-lspace: 0pt;
                                mso-table-rspace: 0pt;
                            }
                    
                            .ReadMsgBody {
                                width: 100%;
                            }
                    
                            .ExternalClass {
                                width: 100%;
                            }
                    
                            p,
                            a,
                            li,
                            td,
                            blockquote {
                                mso-line-height-rule: exactly;
                            }
                    
                            a[href^=tel],
                            a[href^=sms] {
                                color: inherit;
                                cursor: default;
                                text-decoration: none;
                            }
                    
                            p,
                            a,
                            li,
                            td,
                            body,
                            table,
                            blockquote {
                                -ms-text-size-adjust: 100%;
                                -webkit-text-size-adjust: 100%;
                            }
                    
                            .ExternalClass,
                            .ExternalClass p,
                            .ExternalClass td,
                            .ExternalClass div,
                            .ExternalClass span,
                            .ExternalClass font {
                                line-height: 100%;
                            }
                    
                            a[x-apple-data-detectors] {
                                color: inherit !important;
                                text-decoration: none !important;
                                font-size: inherit !important;
                                font-family: inherit !important;
                                font-weight: inherit !important;
                                line-height: inherit !important;
                            }
                    
                            .templateContainer {
                                max-width: 600px !important;
                            }
                    
                            a.mcnButton {
                                display: block;
                            }
                    
                            .mcnImage,
                            .mcnRetinaImage {
                                vertical-align: bottom;
                            }
                    
                            .mcnTextContent {
                                word-break: break-word;
                            }
                    
                            .mcnTextContent img {
                                height: auto !important;
                            }
                    
                            .mcnDividerBlock {
                                table-layout: fixed !important;
                            }
                    
                            /*
                        @tab Page
                        @section Heading 1
                        @style heading 1
                        */
                            h1 {
                                /*@editable*/
                                color: #222222;
                                /*@editable*/
                                font-family: Helvetica;
                                /*@editable*/
                                font-size: 40px;
                                /*@editable*/
                                font-style: normal;
                                /*@editable*/
                                font-weight: bold;
                                /*@editable*/
                                line-height: 150%;
                                /*@editable*/
                                letter-spacing: normal;
                                /*@editable*/
                                text-align: center;
                            }
                    
                            /*
                        @tab Page
                        @section Heading 2
                        @style heading 2
                        */
                            h2 {
                                /*@editable*/
                                color: #222222;
                                /*@editable*/
                                font-family: Helvetica;
                                /*@editable*/
                                font-size: 34px;
                                /*@editable*/
                                font-style: normal;
                                /*@editable*/
                                font-weight: bold;
                                /*@editable*/
                                line-height: 150%;
                                /*@editable*/
                                letter-spacing: normal;
                                /*@editable*/
                                text-align: left;
                            }
                    
                            /*
                        @tab Page
                        @section Heading 3
                        @style heading 3
                        */
                            h3 {
                                /*@editable*/
                                color: #444444;
                                /*@editable*/
                                font-family: Helvetica;
                                /*@editable*/
                                font-size: 22px;
                                /*@editable*/
                                font-style: normal;
                                /*@editable*/
                                font-weight: bold;
                                /*@editable*/
                                line-height: 150%;
                                /*@editable*/
                                letter-spacing: normal;
                                /*@editable*/
                                text-align: left;
                            }
                    
                            /*
                        @tab Page
                        @section Heading 4
                        @style heading 4
                        */
                            h4 {
                                /*@editable*/
                                color: #949494;
                                /*@editable*/
                                font-family: Georgia;
                                /*@editable*/
                                font-size: 20px;
                                /*@editable*/
                                font-style: italic;
                                /*@editable*/
                                font-weight: normal;
                                /*@editable*/
                                line-height: 125%;
                                /*@editable*/
                                letter-spacing: normal;
                                /*@editable*/
                                text-align: left;
                            }
                    
                            /*
                        @tab Header
                        @section Header Container Style
                        */
                            #templateHeader {
                                /*@editable*/
                                background-color: #F7F7F7;
                                /*@editable*/
                                background-image: none;
                                /*@editable*/
                                background-repeat: no-repeat;
                                /*@editable*/
                                background-position: center;
                                /*@editable*/
                                background-size: cover;
                                /*@editable*/
                                border-top: 0;
                                /*@editable*/
                                border-bottom: 0;
                                /*@editable*/
                                padding-top: 45px;
                                /*@editable*/
                                padding-bottom: 45px;
                            }
                    
                            /*
                        @tab Header
                        @section Header Interior Style
                        */
                            .headerContainer {
                                /*@editable*/
                                background-color: transparent;
                                /*@editable*/
                                background-image: none;
                                /*@editable*/
                                background-repeat: no-repeat;
                                /*@editable*/
                                background-position: center;
                                /*@editable*/
                                background-size: cover;
                                /*@editable*/
                                border-top: 0;
                                /*@editable*/
                                border-bottom: 0;
                                /*@editable*/
                                padding-top: 0;
                                /*@editable*/
                                padding-bottom: 0;
                            }
                    
                            /*
                        @tab Header
                        @section Header Text
                        */
                            .headerContainer .mcnTextContent,
                            .headerContainer .mcnTextContent p {
                                /*@editable*/
                                color: #757575;
                                /*@editable*/
                                font-family: Helvetica;
                                /*@editable*/
                                font-size: 16px;
                                /*@editable*/
                                line-height: 150%;
                                /*@editable*/
                                text-align: left;
                            }
                    
                            /*
                        @tab Header
                        @section Header Link
                        */
                            .headerContainer .mcnTextContent a,
                            .headerContainer .mcnTextContent p a {
                                /*@editable*/
                                color: #007C89;
                                /*@editable*/
                                font-weight: normal;
                                /*@editable*/
                                text-decoration: underline;
                            }
                    
                            /*
                        @tab Body
                        @section Body Container Style
                        */
                            #templateBody {
                                /*@editable*/
                                background-color: #FFFFFF;
                                /*@editable*/
                                background-image: none;
                                /*@editable*/
                                background-repeat: no-repeat;
                                /*@editable*/
                                background-position: center;
                                /*@editable*/
                                background-size: cover;
                                /*@editable*/
                                border-top: 0;
                                /*@editable*/
                                border-bottom: 0;
                                /*@editable*/
                                padding-top: 36px;
                                /*@editable*/
                                padding-bottom: 45px;
                            }
                    
                            /*
                        @tab Body
                        @section Body Interior Style
                        */
                            .bodyContainer {
                                /*@editable*/
                                background-color: transparent;
                                /*@editable*/
                                background-image: none;
                                /*@editable*/
                                background-repeat: no-repeat;
                                /*@editable*/
                                background-position: center;
                                /*@editable*/
                                background-size: cover;
                                /*@editable*/
                                border-top: 0;
                                /*@editable*/
                                border-bottom: 0;
                                /*@editable*/
                                padding-top: 0;
                                /*@editable*/
                                padding-bottom: 0;
                            }
                    
                            /*
                        @tab Body
                        @section Body Text
                        */
                            .bodyContainer .mcnTextContent,
                            .bodyContainer .mcnTextContent p {
                                /*@editable*/
                                color: #757575;
                                /*@editable*/
                                font-family: Helvetica;
                                /*@editable*/
                                font-size: 16px;
                                /*@editable*/
                                line-height: 150%;
                                /*@editable*/
                                text-align: left;
                            }
                    
                            /*
                        @tab Body
                        @section Body Link
                        */
                            .bodyContainer .mcnTextContent a,
                            .bodyContainer .mcnTextContent p a {
                                /*@editable*/
                                color: #007C89;
                                /*@editable*/
                                font-weight: normal;
                                /*@editable*/
                                text-decoration: underline;
                            }
                    
                            /*
                        @tab Footer
                        @section Footer Style
                        */
                            #templateFooter {
                                /*@editable*/
                                background-color: #333333;
                                /*@editable*/
                                background-image: none;
                                /*@editable*/
                                background-repeat: no-repeat;
                                /*@editable*/
                                background-position: center;
                                /*@editable*/
                                background-size: cover;
                                /*@editable*/
                                border-top: 0;
                                /*@editable*/
                                border-bottom: 0;
                                /*@editable*/
                                padding-top: 45px;
                                /*@editable*/
                                padding-bottom: 63px;
                            }
                    
                            /*
                        @tab Footer
                        @section Footer Interior Style
                        */
                            .footerContainer {
                                /*@editable*/
                                background-color: transparent;
                                /*@editable*/
                                background-image: none;
                                /*@editable*/
                                background-repeat: no-repeat;
                                /*@editable*/
                                background-position: center;
                                /*@editable*/
                                background-size: cover;
                                /*@editable*/
                                border-top: 0;
                                /*@editable*/
                                border-bottom: 0;
                                /*@editable*/
                                padding-top: 0;
                                /*@editable*/
                                padding-bottom: 0;
                            }
                    
                            /*
                        @tab Footer
                        @section Footer Text
                        */
                            .footerContainer .mcnTextContent,
                            .footerContainer .mcnTextContent p {
                                /*@editable*/
                                color: #FFFFFF;
                                /*@editable*/
                                font-family: Helvetica;
                                /*@editable*/
                                font-size: 12px;
                                /*@editable*/
                                line-height: 150%;
                                /*@editable*/
                                text-align: center;
                            }
                    
                            /*
                        @tab Footer
                        @section Footer Link
                        */
                            .footerContainer .mcnTextContent a,
                            .footerContainer .mcnTextContent p a {
                                /*@editable*/
                                color: #FFFFFF;
                                /*@editable*/
                                font-weight: normal;
                                /*@editable*/
                                text-decoration: underline;
                            }
                    
                            @media only screen and (min-width:768px) {
                                .templateContainer {
                                    width: 600px !important;
                                }
                    
                            }
                    
                            @media only screen and (max-width: 480px) {
                    
                                body,
                                table,
                                td,
                                p,
                                a,
                                li,
                                blockquote {
                                    -webkit-text-size-adjust: none !important;
                                }
                    
                            }
                    
                            @media only screen and (max-width: 480px) {
                                body {
                                    width: 100% !important;
                                    min-width: 100% !important;
                                }
                    
                            }
                    
                            @media only screen and (max-width: 480px) {
                                .mcnRetinaImage {
                                    max-width: 100% !important;
                                }
                    
                            }
                    
                            @media only screen and (max-width: 480px) {
                                .mcnImage {
                                    width: 100% !important;
                                }
                    
                            }
                    
                            @media only screen and (max-width: 480px) {
                    
                                .mcnCartContainer,
                                .mcnCaptionTopContent,
                                .mcnRecContentContainer,
                                .mcnCaptionBottomContent,
                                .mcnTextContentContainer,
                                .mcnBoxedTextContentContainer,
                                .mcnImageGroupContentContainer,
                                .mcnCaptionLeftTextContentContainer,
                                .mcnCaptionRightTextContentContainer,
                                .mcnCaptionLeftImageContentContainer,
                                .mcnCaptionRightImageContentContainer,
                                .mcnImageCardLeftTextContentContainer,
                                .mcnImageCardRightTextContentContainer,
                                .mcnImageCardLeftImageContentContainer,
                                .mcnImageCardRightImageContentContainer {
                                    max-width: 100% !important;
                                    width: 100% !important;
                                }
                    
                            }
                    
                            @media only screen and (max-width: 480px) {
                                .mcnBoxedTextContentContainer {
                                    min-width: 100% !important;
                                }
                    
                            }
                    
                            @media only screen and (max-width: 480px) {
                                .mcnImageGroupContent {
                                    padding: 9px !important;
                                }
                    
                            }
                    
                            @media only screen and (max-width: 480px) {
                    
                                .mcnCaptionLeftContentOuter .mcnTextContent,
                                .mcnCaptionRightContentOuter .mcnTextContent {
                                    padding-top: 9px !important;
                                }
                    
                            }
                    
                            @media only screen and (max-width: 480px) {
                    
                                .mcnImageCardTopImageContent,
                                .mcnCaptionBottomContent:last-child .mcnCaptionBottomImageContent,
                                .mcnCaptionBlockInner .mcnCaptionTopContent:last-child .mcnTextContent {
                                    padding-top: 18px !important;
                                }
                    
                            }
                    
                            @media only screen and (max-width: 480px) {
                                .mcnImageCardBottomImageContent {
                                    padding-bottom: 9px !important;
                                }
                    
                            }
                    
                            @media only screen and (max-width: 480px) {
                                .mcnImageGroupBlockInner {
                                    padding-top: 0 !important;
                                    padding-bottom: 0 !important;
                                }
                    
                            }
                    
                            @media only screen and (max-width: 480px) {
                                .mcnImageGroupBlockOuter {
                                    padding-top: 9px !important;
                                    padding-bottom: 9px !important;
                                }
                    
                            }
                    
                            @media only screen and (max-width: 480px) {
                    
                                .mcnTextContent,
                                .mcnBoxedTextContentColumn {
                                    padding-right: 18px !important;
                                    padding-left: 18px !important;
                                }
                    
                            }
                    
                            @media only screen and (max-width: 480px) {
                    
                                .mcnImageCardLeftImageContent,
                                .mcnImageCardRightImageContent {
                                    padding-right: 18px !important;
                                    padding-bottom: 0 !important;
                                    padding-left: 18px !important;
                                }
                    
                            }
                    
                            @media only screen and (max-width: 480px) {
                                .mcpreview-image-uploader {
                                    display: none !important;
                                    width: 100% !important;
                                }
                    
                            }
                    
                            @media only screen and (max-width: 480px) {
                    
                                /*
                        @tab Mobile Styles
                        @section Heading 1
                        @tip Make the first-level headings larger in size for better readability on small screens.
                        */
                                h1 {
                                    /*@editable*/
                                    font-size: 30px !important;
                                    /*@editable*/
                                    line-height: 125% !important;
                                }
                    
                            }
                    
                            @media only screen and (max-width: 480px) {
                    
                                /*
                        @tab Mobile Styles
                        @section Heading 2
                        @tip Make the second-level headings larger in size for better readability on small screens.
                        */
                                h2 {
                                    /*@editable*/
                                    font-size: 26px !important;
                                    /*@editable*/
                                    line-height: 125% !important;
                                }
                    
                            }
                    
                            @media only screen and (max-width: 480px) {
                    
                                /*
                        @tab Mobile Styles
                        @section Heading 3
                        @tip Make the third-level headings larger in size for better readability on small screens.
                        */
                                h3 {
                                    /*@editable*/
                                    font-size: 20px !important;
                                    /*@editable*/
                                    line-height: 150% !important;
                                }
                    
                            }
                    
                            @media only screen and (max-width: 480px) {
                    
                                /*
                        @tab Mobile Styles
                        @section Heading 4
                        @tip Make the fourth-level headings larger in size for better readability on small screens.
                        */
                                h4 {
                                    /*@editable*/
                                    font-size: 18px !important;
                                    /*@editable*/
                                    line-height: 150% !important;
                                }
                    
                            }
                    
                            @media only screen and (max-width: 480px) {
                    
                                /*
                        @tab Mobile Styles
                        @section Boxed Text
                        @tip Make the boxed text larger in size for better readability on small screens. We recommend a font size of at least 16px.
                        */
                                .mcnBoxedTextContentContainer .mcnTextContent,
                                .mcnBoxedTextContentContainer .mcnTextContent p {
                                    /*@editable*/
                                    font-size: 14px !important;
                                    /*@editable*/
                                    line-height: 150% !important;
                                }
                    
                            }
                    
                            @media only screen and (max-width: 480px) {
                    
                                /*
                        @tab Mobile Styles
                        @section Header Text
                        @tip Make the header text larger in size for better readability on small screens.
                        */
                                .headerContainer .mcnTextContent,
                                .headerContainer .mcnTextContent p {
                                    /*@editable*/
                                    font-size: 16px !important;
                                    /*@editable*/
                                    line-height: 150% !important;
                                }
                    
                            }
                    
                            @media only screen and (max-width: 480px) {
                    
                                /*
                        @tab Mobile Styles
                        @section Body Text
                        @tip Make the body text larger in size for better readability on small screens. We recommend a font size of at least 16px.
                        */
                                .bodyContainer .mcnTextContent,
                                .bodyContainer .mcnTextContent p {
                                    /*@editable*/
                                    font-size: 16px !important;
                                    /*@editable*/
                                    line-height: 150% !important;
                                }
                    
                            }
                    
                            @media only screen and (max-width: 480px) {
                    
                                /*
                        @tab Mobile Styles
                        @section Footer Text
                        @tip Make the footer content text larger in size for better readability on small screens.
                        */
                                .footerContainer .mcnTextContent,
                                .footerContainer .mcnTextContent p {
                                    /*@editable*/
                                    font-size: 14px !important;
                                    /*@editable*/
                                    line-height: 150% !important;
                                }
                    
                            }
                        </style>
                    </head>
                    
                    <body>
                    
                        <center>
                            <table align="center" border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable">
                                <tr>
                                    <td align="center" valign="top" id="bodyCell">
                                        <!-- BEGIN TEMPLATE // -->
                                        <table border="0" cellpadding="0" cellspacing="0" width="100%">
                                            <tr>
                                                <td align="center" valign="top" id="templateHeader" data-template-container>
                                                    <!--[if (gte mso 9)|(IE)]>
                                                        <table align="center" border="0" cellspacing="0" cellpadding="0" width="600" style="width:600px;">
                                                        <tr>
                                                        <td align="center" valign="top" width="600" style="width:600px;">
                                                        <![endif]-->
                                                    <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%"
                                                        class="templateContainer">
                                                        <tr>
                                                            <td valign="top" class="headerContainer"></td>
                                                        </tr>
                                                    </table>
                                                    <!--[if (gte mso 9)|(IE)]>
                                                        </td>
                                                        </tr>
                                                        </table>
                                                        <![endif]-->
                                                </td>
                                            </tr>
                                            <tr>
                                                <td align="center" valign="top" id="templateBody" data-template-container>
                                                    <!--[if (gte mso 9)|(IE)]>
                                                        <table align="center" border="0" cellspacing="0" cellpadding="0" width="600" style="width:600px;">
                                                        <tr>
                                                        <td align="center" valign="top" width="600" style="width:600px;">
                                                        <![endif]-->
                                                    <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%"
                                                        class="templateContainer">
                                                        <tr>
                                                            <td valign="top" class="bodyContainer">
                                                                <table border="0" cellpadding="0" cellspacing="0" width="100%"
                                                                    class="mcnTextBlock" style="min-width:100%;">
                                                                    <tbody class="mcnTextBlockOuter">
                                                                        <tr>
                                                                            <td valign="top" class="mcnTextBlockInner"
                                                                                style="padding-top:9px;">
                                                                                <!--[if mso]>
                                    <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                                    <tr>
                                    <![endif]-->
                    
                                                                                <!--[if mso]>
                                    <td valign="top" width="600" style="width:600px;">
                                    <![endif]-->
                                                                                <table align="left" border="0" cellpadding="0"
                                                                                    cellspacing="0" style="max-width:100%; min-width:100%;"
                                                                                    width="100%" class="mcnTextContentContainer">
                                                                                    <tbody>
                                                                                        <tr>
                    
                                                                                            <td valign="top" class="mcnTextContent"
                                                                                                style="padding-top:0; padding-right:18px; padding-bottom:9px; padding-left:18px;">
                    
                                                                                                <h3 style="text-align: center;">A new Field
                                                                                                    Log has been created!</h3>
                    
                                                                                                <p style="text-align: center;">Click below
                                                                                                    to view it.</p>
                    
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                                <!--[if mso]>
                                    </td>
                                    <![endif]-->
                    
                                                                                <!--[if mso]>
                                    </tr>
                                    </table>
                                    <![endif]-->
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                <table border="0" cellpadding="0" cellspacing="0" width="100%"
                                                                    class="mcnButtonBlock" style="min-width:100%;">
                                                                    <tbody class="mcnButtonBlockOuter">
                                                                        <tr>
                                                                            <td style="padding-top:0; padding-right:18px; padding-bottom:18px; padding-left:18px;"
                                                                                valign="top" align="center" class="mcnButtonBlockInner">
                                                                                <table border="0" cellpadding="0" cellspacing="0"
                                                                                    class="mcnButtonContentContainer"
                                                                                    style="border-collapse: separate !important;border-radius: 7px;background-color: #009FC7;">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td align="center" valign="middle"
                                                                                                class="mcnButtonContent"
                                                                                                style="font-family: Helvetica; font-size: 18px; padding: 18px;">
                                                                                                <a class="mcnButton " title="View Log"
                                                                                                    href="${downloadURL}"
                                                                                                    target="_blank"
                                                                                                    style="font-weight: bold;letter-spacing: -0.5px;line-height: 100%;text-align: center;text-decoration: none;color: #FFFFFF;">View
                                                                                                    Log</a>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    <!--[if (gte mso 9)|(IE)]>
                                                        </td>
                                                        </tr>
                                                        </table>
                                                        <![endif]-->
                                                </td>
                                            </tr>
                                            <tr>
                                                <td align="center" valign="top" id="templateFooter" data-template-container>
                                                    <!--[if (gte mso 9)|(IE)]>
                                                        <table align="center" border="0" cellspacing="0" cellpadding="0" width="600" style="width:600px;">
                                                        <tr>
                                                        <td align="center" valign="top" width="600" style="width:600px;">
                                                        <![endif]-->
                                                    <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%"
                                                        class="templateContainer">
                                                        <tr>
                                                            <td valign="top" class="footerContainer">
                                                                <table border="0" cellpadding="0" cellspacing="0" width="100%"
                                                                    class="mcnDividerBlock" style="min-width:100%;">
                                                                    <tbody class="mcnDividerBlockOuter">
                                                                        <tr>
                                                                            <td class="mcnDividerBlockInner"
                                                                                style="min-width:100%; padding:18px;">
                                                                                <table class="mcnDividerContent" border="0" cellpadding="0"
                                                                                    cellspacing="0" width="100%"
                                                                                    style="min-width: 100%;border-top: 2px solid #505050;">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <span></span>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                                <!--            
                                    <td class="mcnDividerBlockInner" style="padding: 18px;">
                                    <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
                    -->
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    <!--[if (gte mso 9)|(IE)]>
                                                        </td>
                                                        </tr>
                                                        </table>
                                                        <![endif]-->
                                                </td>
                                            </tr>
                                        </table>
                                        <!-- // END TEMPLATE -->
                                    </td>
                                </tr>
                            </table>
                        </center>
                        <script type="text/javascript" src="/G3O3BMfp/XF_/MU8/DyQTMix-ZF/iYL3DSw6YQ/Elk8RmwD/Tg8fT/xY-GTk"></script>
                    </body>
                    
                    </html>`;

                        const newD = distributionList.filter((f) => f.flDistribution === true);


                        newD.map((f, i) => {
                            console.log(f)

                            const proxyUrl = "https://mycorslake.herokuapp.com/";
                            axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sendEmail', {
                                recipient: f.email,
                                subject: 'Log has been created!',
                                from: 'LakeTech@laketech.com',
                                body
                            })
                                .then(async (response) => {
                                    console.log(response);

                                    if (i + 1 === newD.length) {
                                        this.setState({ creating: false })
                                        this.props.openSnack('success', "Emails sent!")
                                        this.props.updateProgress(0)
                                        this.setState({ progress: 0 })
                                    }


                                });

                        })
                        if (newD.length === 0) {
                            this.setState({ creating: false })
                            this.props.openSnack('error', "No Emails distribution!")
                            this.props.updateProgress(0)
                            this.setState({ progress: 0 })
                        }


                    });
                }
            );
        }

    }

    render() {

        const { account, creating, type, item, imageList, progress } = this.state;

        if (creating === true) {
            return (
                <Row>
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <CircularProgress />
                        {progress.toFixed(0)} %
                    </Col>
                </Row>

            )
        } else {
            return (
                <Row>
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <Button size="small"
                            style={{ padding: 10, borderRadius: 4, textTransform: 'none', fontSize: 14, }}
                            fullWidth variant='contained' color="primary" onClick={() => {
                                this.createPDF()
                            }}>{type === 'download' ? <DownloadIcon /> :
                                <EmailIcon />}{type === 'download' ? "Download Log" : "Distribute Log"}</Button>
                    </Col>

                    {/* {item.lineItems.map((d, i) => {

                        return (
                            <img src={d.imageThumb.fileLink} id={`${i}`} style={{ maxHeight: 100 }} onLoad={async () => {
                                const url = await htmlToImage.toPng(document.getElementById(`${i}`))
                                    .then((dataUrl) => {
                                        return dataUrl
                                    });



                                function getDataUri(url) {
                                    return new Promise(resolve => {
                                        var image = new Image();
                                        image.setAttribute('crossOrigin', 'anonymous'); //getting images from external domain

                                        image.onload = function () {
                                            var canvas = document.createElement('canvas');
                                            canvas.width = this.naturalWidth;
                                            canvas.height = this.naturalHeight;

                                            //next three lines for white background in case png has a transparent background
                                            var ctx = canvas.getContext('2d');
                                            ctx.fillStyle = '#fff';  /// set white fill style
                                            ctx.fillRect(0, 0, canvas.width, canvas.height);

                                            canvas.getContext('2d').drawImage(this, 0, 0);

                                            resolve(canvas.toDataURL('image/jpeg'));
                                        };

                                        image.src = url;
                                    })
                                }

                                var weather = await getDataUri(url);


                                imageList.push({ key: `${i}`, url: weather })



                                this.setState({ imageList })


                            }} />
                        )
                    })} */}


                </Row>


            );
        }


    }
} 