import React, { useState } from 'react'
import ReactDOM from 'react-dom/client'

import './index.css'

import {
  useReactTable,
  ColumnResizeMode,
  getCoreRowModel,
  ColumnDef,
  flexRender,
} from '@tanstack/react-table'
import { Typography } from '@mui/material'

type Task = {
  title: string
  status: string
  startDate: string
  assigneeId: string
  siteId: string
}



const defaultColumns: ColumnDef<Task>[] = [
  {
    accessorKey: 'status',
    header: '',
    footer: props => props.column.id,
  },
  {
    accessorKey: 'title',
    header: 'Task',
    footer: props => props.column.id,
  },
  {
    accessorKey: 'startDate',
    header: 'Date',
    footer: props => props.column.id,
  },
  {
    accessorKey: 'assigneeId',
    header: 'Assignee',
    footer: props => props.column.id,
  },
  {
    accessorKey: 'siteId',
    header: 'Site',
    footer: props => props.column.id,
  },
  {
    accessorKey: 'actions',
    header: '',
    footer: props => props.column.id,
  },
]

function ColumnSizingTable({
  tasks
}) {

  console.log(tasks)
  const [data, setData] = useState(() => [...tasks])
  const [columns] = useState<typeof defaultColumns>(() => [
    ...defaultColumns,
  ])

  const [columnResizeMode, setColumnResizeMode] =
    React.useState<ColumnResizeMode>('onChange')

  const rerender = React.useReducer(() => ({}), {})[1]

  const table = useReactTable({
    data,
    columns,
    columnResizeMode,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: true,
  })

  return (
    <div className="p-2">


      <div className="overflow-x-auto">
        <table
          {...{
            style: {
              width: table.getCenterTotalSize(),
            },
          }}
        >
          <thead >
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id} style={{ backgroundColor: '#f7f7f7' }}>
                {headerGroup.headers.map(header => (
                  <th
                    {...{
                      key: header.id,
                      colSpan: header.colSpan,
                      style: {
                        width: header.getSize(),
                        textAlign: 'left',
                      },
                    }}
                  >
                    <Typography variant="body1" fontWeight={700} fontSize={20}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    </Typography>
                    <div
                      {...{
                        onMouseDown: header.getResizeHandler(),
                        onTouchStart: header.getResizeHandler(),
                        className: `resizer ${header.column.getIsResizing() ? 'isResizing' : ''
                          }`,
                        style: {
                          transform:
                            columnResizeMode === 'onEnd' &&
                              header.column.getIsResizing()
                              ? `translateX(${table.getState().columnSizingInfo.deltaOffset
                              }px)`
                              : '',
                        },
                      }}
                    />
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <td
                    {...{
                      key: cell.id,
                      style: {
                        width: cell.column.getSize(),
                      },
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>




    </div>
  )
}

export default ColumnSizingTable;
