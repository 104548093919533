import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { Col, Row } from 'antd';
import React, { useState } from 'react';
import ValueCell from './rowComponents/valueCell';
import UnitsCell from './rowComponents/unitsCell';
import ParametersCell from './rowComponents/parametersCell';
import DateCell from './rowComponents/dateCell';
import SiteCell from './rowComponents/siteCell';
import CodeCell from './rowComponents/codeCell';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    maxHeight: '70vh',
    overflowY: 'scroll',
    bgcolor: 'background.paper',
    border: '1px solid rgba(0, 0, 0, .3)',
    boxShadow: 24,
    p: 4,
    borderRadius: '24px',
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
};

const BulkEditData = ({
    bulkEditor,
    setBulkEditor,
    data,
    selectedData,
    staff,
    accounts,
    currentData,
    setCurrentData,
    headers,
    openSnack,
    updateData,
    stocks,
    setStocks,
    items,
    userInfo,
    setItems,
    setData,
    updateMultipleData,
    unitsArray,
    codes,
    stations,
    parameters,
    sortedData


}) => {

    const handleOpen = () => setBulkEditor(true);
    const handleClose = () => setBulkEditor(false);
    const [bulkData, setBulkData] = useState(null);


    return (
        <div>
            <Modal
                open={bulkEditor}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{

                }}

                slotProps={{
                    backdrop: {
                        style: {
                            backgroundColor: 'transparent'
                        }
                    }
                }}
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" fontWeight={700} fontSize={28} component="h2">
                        Data Bulk Editor
                    </Typography>
                    <Typography id="modal-modal-description" variant="caption" fontSize={13} sx={{ mt: 2 }}>
                        Make changes to multiple data with the bulk editor. You are making changes to
                        <Typography variant="caption" fontWeight={700} fontSize={13} sx={{ display: 'inline' }}>
                            {` ${selectedData?.length} `}
                        </Typography>
                        data.
                    </Typography>
                    {headers?.map((header, index) => {


                        return (
                            <Row align={"middle"} style={{ marginTop: 20 }}>
                                <Col span={6}>
                                    <Typography id="modal-modal-description" variant="body1" fontWeight={600} fontSize={15}>
                                        {header.headerName}
                                    </Typography>
                                </Col>
                                <Col span={14}>
                                    {['value', 'offsetvalue'].includes(header?.optionType)
                                        && (
                                            <ValueCell
                                                header={header}
                                                row={bulkData}
                                                updateRow={(row) => setBulkData(row)}
                                                keyDown={null}
                                                type="bulk"
                                            />
                                        )
                                    }
                                    {header?.optionType === 'unitSelect' && (
                                        <UnitsCell
                                            header={header}
                                            row={bulkData}
                                            updateRow={(row) => setBulkData(row)}
                                            unitsArray={unitsArray}
                                            keyDown={null}
                                            type="bulk"
                                        />
                                    )
                                    }
                                    {header?.optionType === 'parameterSelect' && (
                                        <ParametersCell
                                            header={header}
                                            row={bulkData}
                                            updateRow={(row) => setBulkData(row)}
                                            parameters={parameters}
                                            keyDown={null}
                                            type="bulk"
                                        />
                                    )
                                    }
                                    {header?.optionType === 'date' && (
                                        <DateCell
                                            header={header}
                                            row={bulkData}
                                            updateRow={(row) => setBulkData(row)}
                                            keyDown={null}
                                            type="bulk"
                                        />
                                    )
                                    }
                                    {header?.optionType === 'nodeSelect' && (
                                        <SiteCell
                                            header={header}
                                            row={bulkData}
                                            updateRow={(row) => setBulkData(row)}
                                            keyDown={null}
                                            stations={stations}
                                            type="bulk"
                                        />
                                    )
                                    }
                                    {header?.optionType === 'codeSelect' && (
                                        <CodeCell
                                            header={header}
                                            row={bulkData}
                                            updateRow={(row) => setBulkData(row)}
                                            keyDown={null}
                                            codes={codes}
                                            type="bulk"
                                        />
                                    )
                                    }








                                </Col>

                                <Col span={4} style={{
                                    textAlign: 'right'
                                }}>
                                    <Button variant="contained" size="small"
                                        sx={{
                                            backgroundColor: '#3f51b5',
                                            color: 'white',
                                            '&:hover': {
                                                backgroundColor: '#3f51b5',
                                                color: 'white',
                                                opacity: 0.8
                                            },
                                            textTransform: 'none',
                                        }}

                                        onClick={() => {
                                            console.log(bulkData)
                                            updateMultipleData(selectedData, bulkData)
                                            setBulkData(null)
                                        }}>Apply</Button>
                                </Col>
                            </Row>
                        )
                    })}






                </Box>
            </Modal>
        </div >
    );
}

export default BulkEditData;
