import { Autocomplete, Button, Checkbox, Collapse, Divider, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material';
import { Row, Col } from 'antd';
import React, { useEffect, useState } from 'react';
import { ReactSortable } from 'react-sortablejs';
import uuid from 'react-uuid';
import { fullMobile } from '../../util';
import AddIcon from '@mui/icons-material/Add';
import { DeleteForever, DragIndicator } from '@mui/icons-material';
import { faVial, faLocationCrosshairs, faFillDrip, faChartArea, faChartBar, faChartLine, faChartColumn, faGear } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataColorCompact from '../settings/dataColorCompact';
import moment from 'moment-timezone';
import DatePicker from "react-datepicker";

var randomColor = require('randomcolor');

export default function IsoplethSettings(props) {
    const { userInfo, screenWidth, openSnack, widgetRef, updateCurrentWidget, nodes, PPArray, parameters, WPArray, references } = props;
    const [widget, setWidget] = useState(widgetRef);





    useEffect(() => {
        console.log(widgetRef)
    }, [])
    useEffect(() => {
        setWidget(widgetRef)
    }, [widgetRef])


    async function updateWidget(key, value) {
        var newWidget = { ...widget }
        newWidget[key] = value
        setWidget(newWidget)
        updateCurrentWidget(newWidget)
    }



    return (
        <Row gutter={[4, 4]}
        >

            <Col {...fullMobile(4)}>
                <Row>
                    <Col {...fullMobile(24, 12)} style={{ textAlign: 'center' }}>
                        <Typography fontSize={13} variant="body1">Color Bar</Typography>
                    </Col>
                    <Col {...fullMobile(24, 2)} style={{ textAlign: 'center' }}>
                        <Checkbox checked={widget?.colorBar || false}
                            onChange={(e) => {
                                updateWidget('colorBar', e.target.checked)

                            }} />
                    </Col>
                </Row>
            </Col>

            {widget?.colorBar === true && <Col {...fullMobile(4)}>
                <Row>
                    <Col {...fullMobile(24, 12)} style={{ textAlign: 'center' }}>
                        <Typography fontSize={13} variant="body1">Color Bar Auto</Typography>
                    </Col>
                    <Col {...fullMobile(24, 2)} style={{ textAlign: 'center' }}>
                        <Checkbox checked={widget?.colorBarAuto === undefined ? true : widget?.colorBarAuto}

                            onChange={(e) => {
                                updateWidget('colorBarAuto', e.target.checked)


                            }} />
                    </Col>


                </Row>
            </Col>}
            {widget?.colorBarAuto === false && <Col {...fullMobile(6)}>
                <Row>
                    <Col {...fullMobile(24, 12)} style={{ textAlign: 'center' }}>
                        <Typography fontSize={13} variant="body1">Color Bar Min</Typography>
                    </Col>
                    <Col {...fullMobile(24, 2)} style={{ textAlign: 'center' }}>
                        <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                            defaultValue={widget?.colorBarMin || 0}
                            type="number"

                            onBlur={(e) => {
                                updateWidget('colorBarMin', e.target.value === "" ? "" : Number(e.target.value))
                            }} />
                    </Col>


                </Row>
            </Col>}

            {widget?.colorBarAuto === false && <Col {...fullMobile(6)}>
                <Row>
                    <Col {...fullMobile(24, 12)} style={{ textAlign: 'center' }}>
                        <Typography fontSize={13} variant="body1">Color Bar Max</Typography>
                    </Col>
                    <Col {...fullMobile(24, 2)} style={{ textAlign: 'center' }}>
                        <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                            defaultValue={widget?.colorBarMax || 20}
                            type="number"

                            onBlur={(e) => {
                                updateWidget('colorBarMax', e.target.value === "" ? "" : Number(e.target.value))
                            }} />
                    </Col>


                </Row>
            </Col>}
            <Col {...fullMobile(12)}>
                <Row>
                    <Col {...fullMobile(24, 12)} style={{ textAlign: 'center' }}>
                        <Typography fontSize={13} variant="body1">Color Bar Theme</Typography>
                    </Col>
                    <Col {...fullMobile(24, 2)} style={{ textAlign: 'center' }}>
                        <Select size="small" fullWidth
                            notched={false}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={widget?.colorBarTheme || ''}
                            label="Layer"
                            onChange={(e) => {
                                updateWidget('colorBarTheme', e.target.value)
                            }}

                        >
                            <MenuItem value={'standard'}>Standard</MenuItem>
                            <MenuItem value={'reverseStandard'}>Standard Reverse</MenuItem>
                            <MenuItem value={'blueToRed'}>Blue(low) to Red(high)</MenuItem>
                            <MenuItem value={'redToBlue'}>Red(low) to Blue(high)</MenuItem>

                        </Select>
                    </Col>


                </Row>
            </Col>
            <Col >
                <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                    Depth Label
                </Typography>
                <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                    style={{ width: 200 }}
                    defaultValue={widget?.depthLabel || ''}
                    onBlur={(e) => {
                        updateWidget('depthLabel', e.target.value)
                    }} />

            </Col>
            <Col >
                <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                    Depth Units
                </Typography>
                <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                    style={{ width: 200 }}
                    defaultValue={widget?.depthUnits || ''}
                    onBlur={(e) => {
                        updateWidget('depthUnits', e.target.value)
                    }} />

            </Col>
            <Col >
                <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                    Color Bar Label
                </Typography>
                <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                    style={{ width: 200 }}
                    defaultValue={widget?.colorBarLabel || ''}
                    onBlur={(e) => {
                        updateWidget('colorBarLabel', e.target.value)
                    }} />

            </Col>
            <Col >
                <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                    Value Label
                </Typography>
                <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                    style={{ width: 200 }}
                    defaultValue={widget?.valueLabel || ''}
                    onBlur={(e) => {
                        updateWidget('valueLabel', e.target.value)
                    }} />

            </Col>
            <Col >
                <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                    Units Label
                </Typography>
                <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                    style={{ width: 200 }}
                    defaultValue={widget?.unitsLabel || ''}
                    onBlur={(e) => {
                        updateWidget('unitsLabel', e.target.value)
                    }} />

            </Col>









        </Row>
    );
}