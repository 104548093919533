import axios from 'axios';
import moment from 'moment-timezone';
import { ParameterMap } from './chartOptions';
import unitsList from '../../../../../extra/units.json'
import { SpeedUnit, convertUnits } from 'measurement-unit-converter';
import Highcharts, { attr } from 'highcharts/highstock';
var convert = require('convert-units')



const proxyUrl = "https://mycorslake.herokuapp.com/";
const baseUrl = `https://us-central1-aquasource3.cloudfunctions.net/databaseFetchLarge2`;

// Helper function to build SQL query
function buildSqlQuery(item, attributes, value, endDate, zoom_range) {

    let conditionStr = "";
    if (item.conditions && item.conditions.length > 0) {
        item.conditions.forEach((cond) => {
            if (cond.conditionType === 'value' && cond.action !== 'highlight') {
                conditionStr += ` AND value 
                 ${cond.action === 'hide' && cond.type == '>' ? '<' :
                        cond.action === 'hide' && cond.type === '<' ? '>' :
                            cond.type}
                 ${cond.value || 0}`;
            } else if (cond.conditionType === 'tag' && cond.action !== 'highlight') {
                cond.tags.forEach((tag) => {
                    conditionStr += ` AND tags ${cond.action === 'hide' ? 'NOT LIKE' : 'LIKE'} '%\\"${tag}\\"%'`;
                });
            }
        });
    }



    const startDateFormatted = zoom_range !== null ? moment(zoom_range?.start, 'MM-DD-YYYY HH:mm').format('YYYY-MM-DD HH:mm') : moment(attributes?.startDate, 'x').format('YYYY-MM-DD HH:mm');
    console.log(startDateFormatted)
    return `SELECT time as "t", nodeid, timestamp as "x", ${value}
            FROM node_data_new2
            WHERE nodeid = '${item.locationid}' and parameterid = '${item?.parameterid}'
            and time >= '${startDateFormatted}'
            and time <= '${endDate}'
            ${conditionStr}
            ORDER BY "t" DESC;`;
}

// Adjusted to include handling for offsets based on dataSourceType
function convertData({
    data,
    series,
    isValidUnitId,
    unitLabel,
    dataSourceType,
    offset,
    highlightObjects,
    applyHighlight



}) {

    const timezone = series?.location?.sensor?.timezone || 'US/Pacific';



    return data.map((item, i) => {

        let value = Number(item?.y);


        // Convert and fix the value to 4 decimal places, and convert units if valid
        if (isValidUnitId) {
            value = Number(convert(value.toFixed(4)).from(unitLabel).to(series.units));
        } else {
            value = Number(value.toFixed(4));
        }

        // Adjust value based on dataSourceType
        if (dataSourceType === 'readingWithOffset') {
            value += Number(item.offset || 0);  // Add offset to value if dataSourceType is 'readingWithOffset'
        } else if (dataSourceType === 'offset') {
            value = Number(item.offset || 0);  // Set value to offset if dataSourceType is 'offset'
        }

        //add series offset if it exists
        value += Number(offset || 0);

        const newX = Number(moment(item.t, "YYYY-MM-DD HH:mm:ss").tz(timezone).format("x"));
        let markerProps = { enabled: series.marker.enabled, radius: 3 }; // Default marker properties

        highlightObjects.forEach(hl => {
            let conditionMet = false;
            if (hl.conditionType === 'value') {
                switch (hl.type) {
                    case '>': conditionMet = value > hl.value; break;
                    case '<': conditionMet = value < hl.value; break;
                    case '=': conditionMet = value === hl.value; break;
                    // Add other comparison types as needed
                }
            } else if (hl.conditionType === 'tag') {
                conditionMet = hl.tags.some(tag => item.tags && item.tags.includes(tag)); // Assuming d.tags is an array of tags
            }

            // Apply highlight if condition is met
            if (conditionMet) {
                applyHighlight(markerProps, hl);
            }
        });

        return {
            x: newX, // The x value
            y: value, // The y value adjusted as necessary
            id: item?.key, // Include the id from item.key
            offset: item?.offset,// Include any other data you need, such as offset
            marker: markerProps,
            custom: {
                key: item.key, // Include the key for reference
                seriesKey: series.key, // Include the series key for reference
                nodeid: item.nodeid,
                parameterid: series.parameterid,
                unitid: item.unitid,
                tags: JSON.parse(item.tags) || [],
                account: item?.account,
                company: item?.company
            }
        };
    });
}


export async function readChartData({ attributes, chartOptions, setChartOptions, chartData, setChartData, zoom_range }) {
    try {
        console.log('load chart data')
        console.log(zoom_range)


        let value = 'value as "y", COALESCE(offsetvalue, 0) as "offset", key, tags, account, company, unitid';
        let endDate = zoom_range !== null ? moment(zoom_range?.end, 'MM-DD-YYYY HH:mm').format('YYYY-MM-DD HH:mm') :
            attributes?.endToday ? moment().format('YYYY-MM-DD HH:mm') : moment(attributes?.endDate, 'x').format('YYYY-MM-DD HH:mm');

        console.log(endDate)

        const requests = attributes?.chartData?.map(item => {

            const sqlQuery = buildSqlQuery(item, attributes, value, endDate, zoom_range);
            return axios.post(`${proxyUrl}${baseUrl}`, { raw: sqlQuery }).then(response => ({
                data: response.data,
                series: item
            }));


        });


        const results = await Promise.allSettled(requests);



        results.forEach((result, index) => {
            if (result.status === 'fulfilled') {
                const { data, series } = result.value;
                const unitid = data[0]?.unitid || undefined;

                const isValidUnitId = ["38", "35", "1", "2"].includes(unitid) && unitid;
                let parameter = isValidUnitId ? ParameterMap[series.parameterid] || {} : {};
                let defaultUnit = parameter.default || '';
                const unitLabel = isValidUnitId && unitsList[series.unitid] ? unitsList[series.unitid] : defaultUnit;

                let highlightObjects = [];

                if (series.conditions && series.conditions.length > 0) {
                    series.conditions.forEach((cond) => {
                        if (cond.conditionType === 'value' && cond.action === 'highlight') {
                            highlightObjects.push({
                                conditionType: 'value',
                                value: Number(cond.value),
                                type: cond.type,
                                color: cond.color, // Assuming you have a color specified in your condition
                                radius: cond.radius ? Number(cond.radius) : 4, // Default radius if not specified
                            });
                        } else if (cond.conditionType === 'tag' && cond.action === 'highlight') {
                            highlightObjects.push({
                                conditionType: 'tag',
                                tags: cond.tags,
                                color: cond.color,
                                radius: cond.radius ? Number(cond.radius) : 4,
                            });
                        }
                    });
                }
                series.marker.enabled = series.typeHidden === 'scatter' ? true : series.dots || true;

                // Function to apply highlight properties to marker
                function applyHighlight(markerProps, highlight) {
                    markerProps.enabled = true;
                    markerProps.fillColor = highlight.color;
                    markerProps.radius = highlight.radius;
                    markerProps.lineColor = highlight.lineColor || markerProps.lineColor; // Use highlight.lineColor if specified
                }

                // Assuming dataSourceType and offset are properties of the series or similarly accessible
                series.data = convertData({
                    data, series, isValidUnitId, unitLabel, dataSourceType: series.dataSourceType, offset: series.offset,
                    highlightObjects, applyHighlight
                });
                /* sort series data by ascending [0] order */

                setChartData((prev) => { return { ...prev, [series.key]: series.data } });
                const newChartOptions = { ...chartOptions };
                const newSeries = {
                    ...series,
                    key: series.key,
                    yAxis: series.yAxis,
                    marker: {
                        enabled: true,
                        lineColor: series.markerLine || null,
                        fillColor: series.markerFill || null,
                    },
                    dashStyle: series.dashed || null,
                    lineWidth: series.typeHidden === 'scatter' ? 0 : series.lineWidth || 2,
                    pointWidth: series.columnWidth || 3,
                    states: {
                        ...series.states,
                        hover: {
                            lineWidthPlus: series.typeHidden === 'scatter' ? 0 : series.lineWidth || 2,
                        }
                    },
                    type: series.typeHidden === 'scatter' ? 'spline' : series.type,
                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            y1: 0,
                            x2: 0,
                            y2: 1
                        },
                        stops: [
                            [0, Highcharts.color(series.color).setOpacity(0.2).get('rgba')],
                            [1, Highcharts.color(series.color).setOpacity(0).get('rgba')]
                        ]
                    },
                    allowPointSelect: false,


                }

                if (series.typeHidden === 'scatter') {
                    series.marker.symbol = series.typeHidden === 'scatter' ? 'circle' : null;
                }

                newChartOptions.series[index] = newSeries;

                setChartOptions(newChartOptions);
            } else {
                console.error('Failed to fetch data for series', index);
            }
        });
    } catch (error) {
        console.error('Error fetching chart data:', error);
    }
}



