import React from "react";


import { Button, ButtonGroup } from '@mui/material';
import { Col, Row } from 'antd';
import DocumentsHomeNew from "../../documentsNew/documentsHomeNew";



const PortalDocuments = (
    {
        userInfo,
        openSnack,
        screenWidth
    }

) => {






    return (

        <DocumentsHomeNew publicPortal={true} screenWidth={screenWidth} userInfo={userInfo} openSnack={openSnack} />

    );
};

export default PortalDocuments;
