import React from "react";
import { Input } from "@mui/material";
import SingleSelect from "./singleSelect";
import MultipleSelect from "./multipleSelect";
import NumberInput from "./numberInput";
import StringInput from "./stringInput";
import MoneyInput from "./moneyInput";
import PercentInput from "./percentInput";
import StaffSelect from "./staffSelect";
import ItemSelect from "./itemSelect";
import StocksSelect from "./stockSelect";
import SiteSelect from "./siteSelect";
import { Col, Row } from "antd";
import { Typography } from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes as faX } from "@fortawesome/free-solid-svg-icons";  // renaming faTimes to faX for clarity
import { IconButton } from "@mui/material";
import DateSelectComponent from "./dateSelectComponent";


const CustomHeadersSetup = ({
    currentTask,
    setCurrentTask,
    customHeaders,
    stocks,
    setStocks,
    accounts,
    staff,
    items,
    setItems,
    userInfo,

}) => {
  

    const handleRemove = (key) => {
        // remove the obj from currentTask

        const updatedTask = JSON.parse(JSON.stringify(currentTask));
        delete updatedTask[key];
        setCurrentTask(updatedTask);

    };


    const [amounts, setAmounts] = React.useState({});

    const handleAmountChange = (headerKey, value) => {
        setAmounts(prev => ({ ...prev, [headerKey]: value }));
        setCurrentTask(JSON.parse(JSON.stringify({ ...currentTask, [headerKey]: value })));
    };



    return (
        <>
            {customHeaders?.map((header, index) => {



                if (currentTask?.[header.id] === undefined) {
                    return null;
                }
                else {

                    return (
                        <Col key={header.id} span={24} style={{ paddingTop: 10 }}>
                            <Row align="middle" gutter={[12, 12]}>
                                <Col xs={7} sm={5}>
                                    <Typography variant='body1' fontWeight={500} color={'rgba(0,0,0,0.6)'} fontSize={13}>
                                        {header.headerName}
                                    </Typography>
                                </Col>
                                <Col xs={14} sm={17}>
                                    {header?.optionType === 'money' && (<MoneyInput headerKey={header.id} currentTask={currentTask} setCurrentTask={setCurrentTask} />)}
                                    {header?.optionType === 'percent' && (<PercentInput headerKey={header.id} currentTask={currentTask} setCurrentTask={setCurrentTask} />)}
                                    {header?.optionType === 'string' && (<StringInput headerKey={header.id} currentTask={currentTask} setCurrentTask={setCurrentTask} />)}
                                    {header?.optionType === 'number' && (<NumberInput headerKey={header.id} currentTask={currentTask} setCurrentTask={setCurrentTask} />)}
                                    {header?.optionType === 'singleSelect' && (<SingleSelect headerKey={header.id} currentTask={currentTask} setCurrentTask={setCurrentTask} options={header?.options} />)}
                                    {header?.optionType === 'multipleSelect' && (<MultipleSelect headerKey={header.id} currentTask={currentTask} setCurrentTask={setCurrentTask} options={header?.options} />)}
                                    {header?.optionType === 'staffSelect' && (<StaffSelect staff={staff} userInfo={userInfo} headerKey={header.id} currentTask={currentTask} setCurrentTask={setCurrentTask} />)}
                                    {header?.optionType === 'itemSelect' && (<ItemSelect itemType={header?.itemSelectType} setItems={setItems} userInfo={userInfo} items={items} headerKey={header.id} currentTask={currentTask} setCurrentTask={setCurrentTask} />)}
                                    {header?.optionType === 'stockSelect' && (<StocksSelect stocks={stocks} setStocks={setStocks} userInfo={userInfo} headerKey={header.id} currentTask={currentTask} setCurrentTask={setCurrentTask} />)}
                                    {header?.optionType === 'siteSelect' && (<SiteSelect userInfo={userInfo} accounts={accounts} headerKey={header.id} currentTask={currentTask} setCurrentTask={setCurrentTask} />)}
                                    {header?.optionType === 'dateSelect' && (<DateSelectComponent userInfo={userInfo} headerKey={header.id} currentTask={currentTask} setCurrentTask={setCurrentTask} />)}

                                </Col>
                                <Col sm={2}>
                                    <IconButton onClick={() => handleRemove(header.id)}>
                                        <FontAwesomeIcon icon={faX} />
                                    </IconButton>
                                </Col>
                            </Row>
                        </Col>










                    );
                }
            }
            )}
            {/* ... Your other hardcoded components here ... */}
        </>
    );
};

export default CustomHeadersSetup;
