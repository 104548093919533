import { CircularProgress, Typography, Checkbox, OutlinedInput, MenuItem, Select } from '@mui/material';
import { Col, Row } from 'antd';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { getFirestore } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import React, { useEffect, useRef, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { Image, ImageButton } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './styleText.css'; // import the CSS file for custom styles
import { fullMobile } from './util';
import DataColorCompact from './dataColorCompact';
import TitleSettings from './titleSettings';

export default function URLImageSettings(props) {
    const {
        userInfo,
        screenWidth,
        openSnack,
        company,
        account,
        attributes,
        currentWidgetKey,
        updateAttributes,
    } = props;
    const [loading, setLoading] = useState(true);

    const db = getFirestore();


    useEffect(() => {
        getInfo();
    }, []);



    async function getInfo() {
        setLoading(false);
    }





    const Loading = () => {
        if (loading) {
            return (
                <div className="loading">
                    <CircularProgress />
                    <Typography variant="body1">Loading Data</Typography>
                </div>
            );
        }
    };


    function updateWidget(boo, val) {
        attributes[boo] = val;



    }

    const ImageWidget = ({ url }) => {
        return (
            <div className="image-widget">
                <img src={url} alt="Snapshot" />
            </div>
        );
    };

    const imageUrl = "http://24.32.222.173:4443/snap.jpeg?rand=0.5123733981206966"


    if (loading) {
        return <Loading />;
    }
    else {
        return (
            <Row gutter={[8, 8]} style={{ height: '100%', width: '100%', display: 'flex' }}>

                <Col span={24} style={{ textAlign: 'center' }}>
                    <Row align="middle">
                        <Col span={24} style={{ textAlign: 'left' }}>
                            <Typography fontSize={13} fontWeight={600} variant="body1">Url Portal:</Typography>
                        </Col>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <OutlinedInput
                                fullWidth
                                size='small'
                                defaultValue={attributes.url}
                                onChange={(e) => {
                                    updateWidget('url', e.target.value);
                                }}
                            />

                        </Col>
                    </Row>
                </Col>
                <Col span={24} style={{ textAlign: 'center' }}>
                    <TitleSettings attributes={attributes} updateWidget={updateWidget} />
                </Col>
            </Row>
        );
    }
}
