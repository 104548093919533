import React, { useEffect } from 'react';
import { Box, Button, Card, CardContent, Dialog, DialogActions, DialogTitle, Grid, IconButton, List, ListItem, Popover, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { countFolderImagesFunction } from '../../utils/folderFunctions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faDeleteLeft, faDownLeftAndUpRightToCenter, faEdit, faEllipsisH, faEye, faEyeSlash, faFolder, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faFolderOpen } from '@fortawesome/free-regular-svg-icons';

const useStyles = makeStyles({
  card: {
    height: 180,
    border: '1px solid rgba(0,0,0.3)', // added border
    position: 'relative',
    borderRadius: '12px',
    overflow: 'hidden',
    cursor: 'grab',
    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, border 0.3s ease-in-out',
    '&:hover': {
      transform: 'scale(1.03)', // scale effect on hover
      boxShadow: '0 5px 15px rgba(0, 0, 0, 0.2)', // shadow effect on hover
      border: '1px solid rgba(0, 0, 0, 0.2)',
    },
  },
  media: {
    paddingTop: 10,
    height: 30,
    width: '100%',
    objectFit: 'cover',
  },
  overlay: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,

    padding: 12,

  },
  title: {
    color: '#404050', // white text
  },
  metaInfo: {
    color: 'white', // white text
  },
});

const FolderCover = ({
  imageSrc,
  title,
  folder,
  screenWidth,
  metaInfo,
  userInfo,
  setCurrentFolder,
  setCurrentParentFolder,
  folderBreadCrumbs,
  setFolderBreadCrumbs,
  deleteFolder,
  draggingId,
  combineTargetFor,
  moveUpDirectory,
  openSnack,
  state,
  selectedFiles,
  moveFilesToFolder,
  updateFolder,
  publicPortal
}) => {
  const classes = useStyles();
  const [imageCount, setImageCount] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dragging, setDragging] = React.useState(false);
  const [deleteDialog, setDeleteDialog] = React.useState(false);






  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(null);
  };

  const handleDeleteClose = () => {
    setDeleteDialog(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;


  return (
    <Card className={classes.card}
      onMouseDown={() => setDragging(true)}
      onMouseUp={() => setDragging(false)}

      style={{
        border: dragging === true ? '6px solid #ed8d8d' : state === 'dropTarget' ? '6px solid #506ff5' : '1px solid gray',
      }}


      onClick={() => {
        setCurrentParentFolder(folder)
        setCurrentFolder(null)
        const newBreadcrumbs = [...folderBreadCrumbs, folder]
        setFolderBreadCrumbs(newBreadcrumbs)
      }}>
      <Box style={{ width: '100%', textAlign: 'right' }}>
        <IconButton variant="link">
          <FontAwesomeIcon icon={folder.isPublic ? faEye : faEyeSlash}
            size='sm'
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              if (publicPortal === true) {
                openSnack('error', 'You do not have permission to change folder settings')
                return
              }
              updateFolder({ ...folder, isPublic: !folder.isPublic })
            }
            }

            style={{
              color: folder.color || '#404050',
              marginRight: 10,
            }} />
        </IconButton>
      </Box>
      <FontAwesomeIcon icon={faFolderOpen}  style={{
        color: folder.color || '#404050'
      }} className={classes.media} />
      <CardContent className={classes.overlay}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={9} >
            <Typography variant="h6" fontWeight={500} fontSize={16} className={classes.title}>{title}</Typography>

          </Grid>
          <Grid item xs={3} >
            <IconButton variant="link"
              size={screenWidth === 'xs' ? 'small' : 'small'}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleClick(e)
              }}
              style={{
                backgroundColor: 'rgba(0,0,0,0.5)',
              }}>
              <Tooltip title="More options">
                <FontAwesomeIcon icon={faEllipsisH} size="xl" style={{ color: 'white', }} />
              </Tooltip>
            </IconButton>
          </Grid>

        </Grid>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={(e) => handleClose(e)}

          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <List sx={{
            width: 300,
            padding: 0,

          }}>

            <ListItem
              key={'createTemplate'}
              dense
              button
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setAnchorEl(null)
                if (publicPortal === true) {
                  openSnack('error', 'You do not have permission to change folder settings')
                  return
                }
                setCurrentFolder(folder)
              }}
              sx={{
                borderBottom: '1px solid #e0e0e0',
                paddingTop: 1,
                paddingBottom: 1,
              }}
            >
              <FontAwesomeIcon icon={faEdit} size="lg" style={{ color: 'gray', marginRight: 10 }} />
              <Typography variant="body1" color="black" fontSize={16} fontWeight={500}>Rename</Typography>
            </ListItem>
            <ListItem
              key={'moveUp'}
              dense
              button

              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setAnchorEl(null)
                if (folderBreadCrumbs.length === 0) {
                  openSnack('error', 'Cannot move up from root folder')
                  return
                }
                if (publicPortal === true) {
                  openSnack('error', 'You do not have permission to change folder settings')
                  return
                }
                moveUpDirectory('folder', folder)
              }}
              sx={{
                borderBottom: '1px solid #e0e0e0',
                paddingTop: 1,
                paddingBottom: 1,
              }}
            >
              <FontAwesomeIcon icon={faArrowUp} size="lg" style={{ color: 'gray', marginRight: 10 }} />
              <Typography variant="body1" color={folderBreadCrumbs.length === 0 ? 'gray' : 'black'}
                fontSize={16} fontWeight={500}>Move up directory</Typography>
            </ListItem>
            <ListItem
              key={'createTemplate'}
              dense
              button

              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setAnchorEl(null)
                if (selectedFiles.length === 0) {
                  openSnack('error', 'No files selected')
                  return
                }
                if (publicPortal === true) {
                  openSnack('error', 'You do not have permission to change folder settings')
                  return
                }
                moveFilesToFolder(selectedFiles, folder)


              }}
              sx={{
                borderBottom: '1px solid #e0e0e0',
                paddingTop: 1,
                paddingBottom: 1,
              }}
            >
              <FontAwesomeIcon icon={faDownLeftAndUpRightToCenter} size="lg" style={{ color: 'gray', marginRight: 10 }} />
              <Typography variant="body1" color={selectedFiles.length === 0 ? 'gray' : 'black'}
                fontSize={16} fontWeight={500}>Send files here</Typography>
            </ListItem>
            <ListItem
              key={'createTemplate'}
              dense
              button
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setAnchorEl(null)
                if (publicPortal === true) {
                  openSnack('error', 'You do not have permission to delete folder')
                  return
                }
                setDeleteDialog(true)

              }}
              sx={{
                borderBottom: '1px solid #e0e0e0',
                paddingTop: 1,
                paddingBottom: 1,
              }}
            >
              <FontAwesomeIcon icon={faTrash} size="lg" style={{ color: 'gray', marginRight: 10 }} />
              <Typography variant="body1" color="black" fontSize={16} fontWeight={500}>Delete</Typography>
            </ListItem>





          </List>




        </Popover>

        <Dialog
          open={deleteDialog}
          onClose={handleDeleteClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Are you sure you want to delete this folder?
            Deleting this folder will delete all folders and files inside it.
          </DialogTitle>

          <DialogActions>
            <Button style={{
              textTransform: 'none',
            }} onClick={handleDeleteClose}>Cancel</Button>
            <Button
              style={{
                fontWeight: 600,
                textTransform: 'none',
                color: 'red'
              }}

              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setAnchorEl(null)
                deleteFolder(folder)
                handleDeleteClose();
              }}
              autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>

      </CardContent>
    </Card >
  );
};

export default FolderCover;
