import { Breadcrumbs, Button, ButtonGroup, Divider, MenuItem, OutlinedInput, Typography, Select, TextField } from '@mui/material';
import { Col, Modal, Row, } from 'antd';
import React, { useEffect, useState, useMemo } from 'react';
import { fullMobile } from '../util';
import 'react-data-grid/lib/styles.css';
import uuid from 'react-uuid';
import { memo } from 'react';
import moment from 'moment-timezone';
import { queryEditAccountPerson, queryPermissionTemplates } from '../../../firebase/config';
import { Link } from 'react-router-dom';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Box } from '@mui/system';
import './table.css'
import { permissionsList, states } from './permissions'
import { getFirestore, getDoc, doc, setDoc, updateDoc } from 'firebase/firestore';
//create style of row paddingTop and bottom 15
//create style of row paddingTop and bottom 15

const style = {
    row: {
        paddingTop: 15,
        paddingBottom: 15,
        paddingLeft: 15,
        paddingRight: 15,
        //align center in div
        display: 'flex',
        alignItems: 'center',

    }
}





export default function EditAccountPerson(props) {
    const { userInfo, screenWidth, openSnack, company, account, staffRef, usersRef } = props;
    const [person, setPerson] = useState({})
    const [permissionTemplates, setPermissionTemplates] = useState([])
    const [permission, setPermission] = useState(null)
    const [permissionModal, setPermissionModal] = useState(false)
    const [permissions, setPermissions] = useState(permissionsList)

    useEffect(() => {
        window.scrollTo(0, 0)
        getInfo()
        console.log(permissions)
    }, [])
    const db = getFirestore()

    async function getInfo() {
        const editPerson = await queryEditAccountPerson(userInfo)
        console.log(editPerson)


        //add permissions to person
        if (!editPerson.permissions) {
            editPerson.permissions = permissionsList
        }

        setPerson(editPerson)


        const permissionTemplates = await queryPermissionTemplates(userInfo)
        console.log(permissionTemplates)
        setPermissionTemplates(permissionTemplates)
    }


    useEffect(() => {

    }, [usersRef])

    useEffect(() => {

    }, [staffRef])

    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: (option) => option.label,
    });




    //create 50 rows with the schema above columns
    //create array from 0 to 50








    async function handleUpdatePerson(boo, val) {
        const newPerson = { ...person }
        if (boo) {
            newPerson[boo] = val
        }
        setPerson(newPerson)
    }

    async function handleUpdatePermission(val, index) {
        const newPerson = { ...person }
        newPerson.permissions[index].level = val
        console.log(newPerson)
        setPerson(newPerson)
    }


    const SaveButton = () => {


        return (
            <Button variant={'contained'} display="inline"

                style={{
                    padding: 4, paddingTop: 8, paddingBottom: 8,
                    textTransform: 'none', backgroundColor: '#404050', fontSize: 13,
                }}
                onClick={async () => {
                    const newPerson = { ...person }
                    console.log(newPerson)
                    const docRef = doc(db, 'clients', userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'staff', newPerson.id);
                    await updateDoc(docRef, {
                        permissions: newPerson.permissions
                    });
                    const userRef = doc(db, 'users', newPerson.id);
                    await updateDoc(userRef, {
                        firstName: newPerson.firstName,
                        lastName: newPerson.lastName,
                        company: newPerson.company || '',
                        companyAddress: newPerson.companyAddress || '',
                        companyCity: newPerson.companyCity || '',
                        companyState: newPerson.companyState || '',
                        companyZip: newPerson.companyZip || '',
                        phone: newPerson.phone || '',
                        title: newPerson.title || '',


                    });
                    openSnack('success', 'Staff Updated')

                }}
            >
                Save Staff
            </Button>
        )
    }



    return (

        <div style={{ fontFamily: 'Roboto, sans-serif', backgroundColor: '#f6f8fe', minHeight: '100vh' }}>
            <Row style={{ padding: 20, paddingTop: 50 }} >
                <Col {...fullMobile(24, 24)}>


                    <Breadcrumbs aria-label="breadcrumb">

                        <Link to="/accountDirectory" style={{ color: '#404050', fontWeight: 500 }} >
                            Click to Return to Site Directory
                        </Link>

                    </Breadcrumbs>
                </Col>
                <Col {...fullMobile(24, 24)} style={{ paddingTop: 50, paddingBottom: 10 }}>
                    <Typography variant="h3" style={{ fontWeight: 300, color: '#404050' }}>
                        Edit {person.firstName} {person.lastName}
                    </Typography>

                </Col>
                <Col {...fullMobile(24, 24)}>
                    <Divider />
                </Col>
                <Col {...fullMobile(24, 24)} style={{ paddingTop: 10, paddingBottom: 10 }}>
                    <ButtonGroup variant="link" aria-label="contained primary button group">
                        <Button style={{ color: '#404050', textTransform: 'none' }} >
                            General
                        </Button>
                    </ButtonGroup>

                </Col>
                <Col {...fullMobile(24, 24)} >
                    <Divider />
                </Col>




                <Col {...fullMobile(24, 24)} style={{ paddingTop: 15 }}>
                    <Row gutter={[12, 12]} align="middle" style={{ paddingBottom: 20, paddingTop: 10 }}>
                        <Col {...fullMobile(12, 24)}>
                            <Typography variant="h5" style={{ fontWeight: 500, color: '#404050' }}>
                                PERSONAL INFORMATION
                            </Typography>
                        </Col>





                        
                        <Col {...fullMobile(12, 12)}>
                            <Typography variant="h5" style={{ fontWeight: 500, color: '#404050' }}>
                                COMPANY INFORMATION
                            </Typography>
                        </Col>
                    </Row>
                    <Row style={{ backgroundColor: 'white', }} align="middle" >
                        <Col {...fullMobile(6, 6)} style={style.row} >
                            <Typography variant="body1" style={{ fontWeight: 400, color: '#404050' }}>
                                First Name
                            </Typography>
                        </Col>
                        <Col {...fullMobile(6, 6)} style={style.row} >
                            <OutlinedInput
                                size="small"
                                id="outlined-adornment-amount"
                                value={person.firstName}
                                onChange={(e) => {
                                    handleUpdatePerson('firstName', e.target.value)
                                }}


                            />
                        </Col>
                        <Col {...fullMobile(6, 6)} style={style.row} >
                            <Typography variant="body1" style={{ fontWeight: 400, color: '#404050' }}>
                                Company Name
                            </Typography>
                        </Col>
                        <Col {...fullMobile(6, 6)} style={style.row} >
                            <OutlinedInput
                                size="small"
                                id="outlined-adornment-amount"
                                value={person.company}
                                onChange={(e) => {
                                    handleUpdatePerson('company', e.target.value)
                                }}

                            />
                        </Col>
                        <Col span={24}>
                            <Divider />
                        </Col>
                        <Col {...fullMobile(6, 6)} style={style.row} >
                            <Typography variant="body1" style={{ fontWeight: 400, color: '#404050' }}>
                                Last Name
                            </Typography>
                        </Col>
                        <Col {...fullMobile(6, 6)} style={style.row} >
                            <OutlinedInput
                                size="small"
                                id="outlined-adornment-amount"
                                value={person.lastName}
                                onChange={(e) => {
                                    handleUpdatePerson( 'lastName', e.target.value)
                                }}

                            />
                        </Col>
                        <Col {...fullMobile(6, 6)} style={style.row} >
                            <Typography variant="body1" style={{ fontWeight: 400, color: '#404050' }}>
                                Company Address
                            </Typography>
                        </Col>
                        <Col {...fullMobile(6, 6)} style={style.row} >
                            <OutlinedInput
                                size="small"
                                id="outlined-adornment-amount"
                                value={person.companyAddress}
                                onChange={(e) => {
                                    handleUpdatePerson('companyAddress', e.target.value)
                                }}

                            />
                        </Col>

                        <Col span={24}>
                            <Divider />
                        </Col>

                        <Col {...fullMobile(6, 6)} style={style.row} >
                            <Typography variant="body1" style={{ fontWeight: 400, color: '#404050' }}>
                                Email
                            </Typography>
                        </Col>
                        <Col {...fullMobile(6, 6)} style={style.row} >
                            <OutlinedInput
                                size="small"
                                disabled={true}
                                id="outlined-adornment-amount"
                                value={person.email}
                                onChange={(e) => {
                                    handleUpdatePerson('email', e.target.value)
                                }}

                            />
                        </Col>
                        <Col {...fullMobile(6, 6)} style={style.row} >
                            <Typography variant="body1" style={{ fontWeight: 400, color: '#404050' }}>
                                Company City
                            </Typography>
                        </Col>
                        <Col {...fullMobile(6, 6)} style={style.row} >
                            <OutlinedInput
                                size="small"
                                id="outlined-adornment-amount"
                                value={person.companyCity}
                                onChange={(e) => {
                                    handleUpdatePerson('companyCity', e.target.value)
                                }}

                            />
                        </Col>
                        <Col span={24}>
                            <Divider />
                        </Col>
                        <Col {...fullMobile(6, 6)} style={style.row} >
                            <Typography variant="body1" style={{ fontWeight: 400, color: '#404050' }}>
                                Phone
                            </Typography>
                        </Col>
                        <Col {...fullMobile(6, 6)} style={style.row} >
                            <OutlinedInput
                                size="small"
                                id="outlined-adornment-amount"
                                value={person.phone}
                                onChange={(e) => {
                                    handleUpdatePerson('phone', e.target.value)
                                }}

                            />
                        </Col>
                        <Col {...fullMobile(6, 6)} style={style.row} >
                            <Typography variant="body1" style={{ fontWeight: 400, color: '#404050' }}>
                                Company State
                            </Typography>
                        </Col>
                        <Col {...fullMobile(6, 6)} style={style.row} >
                            <Select
                                size="small"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={person.companyState}
                                onChange={(e) => {
                                    handleUpdatePerson('companyState', e.target.value, )
                                }}
                            >
                                {states.map((state, index) => {
                                    return <MenuItem key={index} value={state.value}>{state.label}</MenuItem>
                                })}

                            </Select>
                        </Col>
                        <Col span={24}>
                            <Divider />
                        </Col>
                        <Col {...fullMobile(6, 6)} style={style.row} >
                            <Typography variant="body1" style={{ fontWeight: 400, color: '#404050' }}>
                                Title
                            </Typography>
                        </Col>
                        <Col {...fullMobile(6, 6)} style={style.row} >
                            <OutlinedInput
                                size="small"
                                id="outlined-adornment-amount"
                                value={person.title}
                                onChange={(e) => {
                                    handleUpdatePerson('title', e.target.value)
                                }}

                            />
                        </Col>
                        <Col {...fullMobile(6, 6)} style={style.row} >
                            <Typography variant="body1" style={{ fontWeight: 400, color: '#404050' }}>
                                Company Zip
                            </Typography>
                        </Col>
                        <Col {...fullMobile(6, 6)} style={style.row} >
                            <OutlinedInput
                                size="small"
                                id="outlined-adornment-amount"
                                value={person.companyZip}
                                onChange={(e) => {
                                    handleUpdatePerson('companyZip', e.target.value)
                                }}
                            />
                        </Col>

                        <Col span={24}>
                            <Divider />
                        </Col>
                        <Col {...fullMobile(6, 6)} style={style.row} >

                        </Col>
                        <Col {...fullMobile(6, 6)} style={style.row} >

                        </Col>
                        <Col {...fullMobile(6, 6)} style={style.row} >
                            <Typography variant="body1" style={{ fontWeight: 400, color: '#404050' }}>
                                Company Country
                            </Typography>
                        </Col>
                        <Col {...fullMobile(6, 6)} style={style.row} >
                            <OutlinedInput
                                size="small"
                                id="outlined-adornment-amount"
                                value={person.companyCountry}
                                onChange={(e) => {
                                    handleUpdatePerson('companyCountry', e.target.value)
                                }}
                            />
                        </Col>
                    </Row>



                </Col>

                <Col {...fullMobile(24, 24)} style={{ paddingTop: 15 }}>
                    <Row gutter={[12, 12]} align="middle" style={{ paddingBottom: 20, paddingTop: 10 }}>
                        <Col {...fullMobile(12, 12)}>
                            <Typography variant="h5" style={{ fontWeight: 500, color: '#404050' }}>
                                SITE PERMISSIONS TEMPLATE:
                            </Typography>
                        </Col>
                        <Col {...fullMobile(12, 12)}>
                            <Autocomplete
                                style={{ width: 300, backgroundColor: '#fff' }}
                                size="small"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                hiddenLabel="true"
                                disableClearable={true}
                                options={permissionTemplates}
                                value={permissionTemplates.find((template) => template.value === person.sitePermissionsTemplate)?.label || null}
                                filterOptions={(options, _ref) => [...filterOptions(options, _ref), { label: 'Add New', key: 'Add New', value: 'addNew' },]}
                                onChange={(a, b) => {

                                    handleUpdatePerson('sitePermissionsTemplate', b.key || null, )
                                    setPerson({ ...person, permissions: b.permissions })
                                }}
                                noOptionsText={<Button fullWidth variant='contained' color='primary' style={{ textTransform: 'none' }}
                                    onClick={() => {
                                        setPermissionModal(true)
                                        setPermission({ key: uuid(), label: '' });
                                    }}
                                >+ Create Template</Button>}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Templates"
                                        variant="outlined"
                                    />
                                )}
                                renderOption={(params) => {

                                    if (params.key === 'Add New') {
                                        return (
                                            <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 4 }}>
                                                <Button fullWidth variant='contained' color='primary' style={{ textTransform: 'none' }} onClick={(e) => {
                                                    setPermissionModal(true)
                                                    setPermission({ key: uuid(), label: '' });
                                                }}>+ Create Template from Selected</Button>
                                            </Box>

                                        )
                                    }
                                    else {
                                        return (
                                            <Typography {...params}>{params.key}</Typography>
                                        )
                                    }
                                }}
                            />

                        </Col>
                    </Row>
                </Col>


                <Col {...fullMobile(24, 24)} style={{ paddingTop: 15 }}>

                    <Row style={{ backgroundColor: 'white', }} align="middle" >
                        <table
                            style={{
                                width: '100%',
                                //center all text inside cells
                                textAlign: 'center',
                                //center all text inside cells

                            }}
                            id="directory_permissions_table"
                            data-directory-admin-warning="Permission cannot change while user is Directory Admin"
                            data-provider="project"
                            data-start-directory-admin="true"
                        >
                            <thead>
                                <tr style={{
                                    backgroundColor: '#f8f8f8',
                                    //row height 20
                                    height: '40px',
                                }} >
                                    <th className='permissionth' width="20%">Name</th>
                                    <th className='permissionth' width="20%" >None</th>
                                    <th className='permissionth' width="20%" >Read Only</th>
                                    <th className='permissionth' width="20%" >Standard</th>
                                    <th className='permissionth' width="20%"  >Admin</th>
                                </tr>
                            </thead>
                            <tbody>

                                {person?.permissions?.length > 0 && person?.permissions.map((permission, index) => {
                                    const levels = ['1', '2', '3', '4'];
                                    return (
                                        <tr data-domain-name={permission.label} className='permissiontr'>
                                            <td className='permissiontd'>
                                                <Typography variant="subitle1" style={{ fontWeight: 400, color: '#404050' }}>
                                                    {permission.label}
                                                </Typography>
                                            </td>

                                            {levels.map((level) => {
                                                return (
                                                    <td className='permissiontd'><input type="radio"
                                                        value={level}
                                                        //disabled if permission.level is less than 4
                                                        checked={permission.level === level}
                                                        onChange={(e) => {
                                                            //change permission level
                                                            handleUpdatePermission(e.target.value, index)

                                                        }}
                                                    /></td>
                                                )
                                            })
                                            }
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                        </table>

                    </Row>
                </Col>
                <Col {...fullMobile(24, 24)} style={{ paddingTop: 15, paddingLeft: 30, paddingRight: 30, textAlign: 'right' }} >
                    <SaveButton />

                </Col>











            </Row >

            <Modal title="" visible={permissionModal} onCancel={() => {
                setPermissionModal(false)
                setPermission(null);

            }} footer={null} width={'60%'} style={{ borderRadius: 40 }} >
                <Row style={{ padding: 20 }}>
                    <Col {...fullMobile(24, 24)} style={{}}>
                        <Typography variant="h5" style={{ fontWeight: 500, color: '#404050' }}>
                            Create New Template
                        </Typography>
                        <Typography variant="subtitle1" style={{ fontWeight: 300, color: '#404050', paddingTop: 5 }}>
                            Template will be based off selected permissions
                        </Typography>
                    </Col>
                    <Col {...fullMobile(24, 24)} style={{ paddingTop: 20 }}>
                        <Typography variant="body1" style={{ fontWeight: 500, color: '#404050' }}>
                            Template Name
                        </Typography>
                        <OutlinedInput
                            size="small"
                            style={{ width: 300 }}
                            id="outlined-adornment-amount"
                            value={permission?.label}
                            onChange={(e) => {
                                setPermission({ ...permission, label: e.target.value })
                            }}
                        />
                    </Col>
                    <Col {...fullMobile(24, 24)} style={{ paddingTop: 20 }}>
                        <Button variant='contained'
                            color='primary'
                            style={{ textTransform: 'none', backgroundColor: '#404050' }}
                            onClick={async () => {
                                const permissionTemplate = {
                                    ...permission,
                                    permissions: person.permissions,
                                }

                                if (permissionTemplate.label !== '') {

                                    const docRef = doc(db, "clients", userInfo.currentCompany, 'accounts', account.key, 'permissionTemplates', permission.key);
                                    await setDoc(docRef, permissionTemplate).then(() => {
                                        setPermissionModal(false)
                                        setPermission(null);
                                        //add to permissionTemplates
                                        const newTemplates = [...permissionTemplates, permissionTemplate];
                                        setPermissionTemplates(newTemplates);
                                    }).catch((error) => {
                                        console.error("Error adding document: ", error);
                                    })
                                }
                                else {
                                    openSnack('error', 'Please enter a name for the template')
                                }
                            }}
                        >Save Template</Button>

                    </Col>


                </Row>

            </Modal >
        </div >

    );
}