import { Button, Checkbox, Divider, OutlinedInput, Typography, Select, MenuItem, CircularProgress, Paper, FormControl, InputLabel, IconButton } from '@mui/material';
import { Col, Row } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment-timezone';
import { fullMobile } from '../util';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, } from 'firebase/firestore';

import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import uuid from 'react-uuid';

import { Tree } from "react-arborist";


import queryString from 'query-string';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDatabase, faMapLocationDot, faMapMarked, faVialCircleCheck, faSquareCheck, faDiagramProject } from '@fortawesome/free-solid-svg-icons';
import { faSquare, } from '@fortawesome/free-regular-svg-icons';
var randomColor = require('randomcolor');

export default function DataBar(props) {
    const { userInfo, screenWidth, openSnack, company, account, updateLog, logRef, status, PPArray, WPArray, parameters, nodes, probes, rainGauges, updateChildren, queryRef, unselectAllChildren } = props;
    const [loading, setLoading] = useState(true);
    const [treeSize, setTreeSize] = useState(null);
    const treeRef = useRef(null);
    const [query, setQuery] = useState(queryRef);
    const [searchTerm, setSearchTerm] = useState("");
    const [updating, setUpdating] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        setQuery(queryRef)
        if (queryRef.dataSource !== query.dataSource) {
            console.log('update')
            setUpdating(true)
            setTimeout(() => {
                updateData(queryRef)
            }, 250);
        }
    }, [queryRef])



    useEffect(() => {
        console.log(probes)
        updateData(queryRef)
    }, [])


    function updateData(queryRef) {





        const data0 = [

            {
                id: "clear",
                name: "Clear",
                children: []
            }]

        const data1 = [

            {
                id: "parameters",
                name: "Parameters",
                children: [
                    { key: 'Select All Parameters', label: 'Select All', selected: false },
                    ...parametersList]
                    .map((p, i) => {
                        return ({
                            id: p.key,
                            name: p.label,
                            selected: false,
                        })
                    }
                    )
                ,
            }]

        const data2 = [
            {
                id: "nodes",
                name: "Nodes",
                children: [
                    { id: 'Select All Nodes', label: 'Select All', selected: false },
                    ...nodes
                ].map((n, i) => {
                    return ({
                        id: n.id,
                        name: n.label,
                        selected: false,
                    })
                })
            }]

        const data3 = [
            {
                id: "probes",
                name: "Probes",
                children: [
                    ...probes


                ].map((n, i) => {

                    return ({
                        id: n.id.toString(),
                        name: n.name,
                        selected: false,
                    })
                })
            },
        ]
        const data4 = [
            {
                id: "rainGauges",
                name: "Rain Gauges",
                children: [
                    { key: 'Select All Rain Gauges', name: 'Select All', selected: false },
                    ...rainGauges


                ].map((n, i) => {

                    return ({
                        id: n.key.toString(),
                        name: n.name,
                        selected: false,
                    })
                })
            },
        ]

        const data5 = [

            {
                id: "rainParameters",
                name: "Rain Parameters",
                children: [
                    { key: 'Select All Rain Parameters', label: 'Select All', selected: false },
                    ...WPArray]
                    .map((p, i) => {
                        return ({
                            id: p.key,
                            name: p.label,
                            selected: false,
                        })
                    }
                    )
                ,
            }]

        const data = queryRef.dataSource === 'node' ? [...data0, ...data2, ...data1] :
            queryRef.dataSource === 'rain' ? [...data0, ...data4, ...data5] :

                [...data0, ...data3, ...data1]
        setData(data)
        setUpdating(false);
    }


    const parametersList = [...PPArray, ...parameters].sort((a, b) => a.label.localeCompare(b.label))








    function Node({ node, style, dragHandle }) {
        /* This node instance can do many things. See the API reference. */

        return (
            <div style={style}
                //open and close onclick
                onClick={() => {

                    if (node.data.id === "Select All Parameters" || node.data.id === "Select All Nodes" || node.data.id === "Select All Probes" || node.data.id === "Select All Rain Parameters" || node.data.id === "Select All Rain Gauges") {
                        node.data.selected = !node.data.selected;
                        //seletc all of parent's children
                        node.parent.children.forEach((child) => {
                            child.data.selected = node.data.selected;
                        }
                        )

                        const parentChildren = node.parent.children.map((child) => child.id);
                        //remove id from array that is either Select All Parameters or Select All Nodes
                        const filteredChildren = parentChildren.filter((child) => child !== "Select All Parameters" && child !== "Select All Nodes" && child !== "Select All Probes" && child !== "Select All Rain Parameters" && child !== "Select All Rain Gauges");

                        //add to parameters or nodes array for each child
                        if (node.data.id === "Select All Parameters") {
                            updateChildren('parameters', null, null, true, node.data.selected ? filteredChildren : []);
                        }
                        if (node.data.id === "Select All Nodes") {
                            updateChildren('nodes', null, null, true, node.data.selected ? filteredChildren : []);
                        }
                        if (node.data.id === "Select All probes") {
                            updateChildren('probes', null, null, true, node.data.selected ? filteredChildren : []);
                        }
                        if (node.data.id === "Select All Rain Parameters") {
                            updateChildren('rainParameters', null, null, true, node.data.selected ? filteredChildren : []);
                        }
                        if (node.data.id === "Select All Rain Gauges") {
                            updateChildren('rainGauges', null, null, true, node.data.selected ? filteredChildren : []);
                        }


                    }
                    else {



                        //close node
                        if (node.isOpen) {
                            node.close();
                        }
                        //open node
                        else {
                            node.open();
                        }
                        //update selected
                        node.data.selected = !node.data.selected;
                        //if parentid is 1

                        if (node.children === null && node?.parent?.data?.id === "parameters") {
                            updateChildren('parameters', node.data.selected, node.data.id);

                            //find Select All node and change to not selected
                            node.parent.children.forEach((child) => {
                                if (child.data.id === "Select All") {
                                    child.data.selected = false;
                                }
                            }
                            )
                        }
                        if (node.children === null && node?.parent?.data?.id === "nodes") {
                            updateChildren('nodes', node.data.selected, node.data.id);

                            //find Select All node and change to not selected
                            node.parent.children.forEach((child) => {
                                if (child.data.id === "Select All") {
                                    child.data.selected = false;
                                }
                            }
                            )
                        }
                        if (node.children === null && node?.parent?.data?.id === "probes") {
                            updateChildren('probes', node.data.selected, node.data.id);

                            //find Select All node and change to not selected
                            node.parent.children.forEach((child) => {
                                if (child.data.id === "Select All") {
                                    child.data.selected = false;
                                }
                            }
                            )
                        }

                        if (node.children === null && node?.parent?.data?.id === "rainGauges") {
                            console.log('update rain gauges')
                            updateChildren('rainGauges', node.data.selected, node.data.id);

                            //find Select All node and change to not selected
                            node.parent.children.forEach((child) => {
                                if (child.data.id === "Select All") {
                                    child.data.selected = false;
                                }
                            }
                            )
                        }

                        if (node.children === null && node?.parent?.data?.id === "rainParameters") {
                            updateChildren('rainParameters', node.data.selected, node.data.id);

                            //find Select All node and change to not selected
                            node.parent.children.forEach((child) => {
                                if (child.data.id === "Select All") {
                                    child.data.selected = false;
                                }
                            }
                            )
                        }



                    }




                }}

                ref={dragHandle}>
                {node.isLeaf && !node.data.selected && (<FontAwesomeIcon icon={faSquare} />)}
                {node.isLeaf && node.data.selected && (<FontAwesomeIcon icon={faSquareCheck} />)}
                {node.isLeaf && (' ')}
                {!node.isLeaf && ('  ')}
                {node.data.name === 'Parameters' && !node.isLeaf && (<FontAwesomeIcon icon={faVialCircleCheck} />)}
                {node.data.name === 'Clear' && !node.isLeaf && (
                    <Button
                        fullWidth
                        size="small"
                        style={{
                            textTransform: 'none',
                            backgroundColor: '#404050',

                        }}
                        variant="contained"
                        onClick={() => {
                            console.log('unselect')
                            //find parent nodes
                            const parents = treeRef.current.visibleNodes.filter((node) => node?.children?.length > 0);
                            console.log(parents)
                            unselectAllChildren()




                            parents.forEach((parent) => {
                                parent.data.selected = false;
                                parent.children.forEach((child) => {
                                    child.data.selected = false;
                                })
                            })

                        }}
                    >
                        Clear All
                    </Button>
                )}
                {node.data.name === 'Nodes' && !node.isLeaf && (<FontAwesomeIcon icon={faMapLocationDot} />)}
                {node.data.name === 'Probes' && !node.isLeaf && (<FontAwesomeIcon icon={faDiagramProject} />)}
                {node.isLeaf && (<Typography variant='body1' display="inline" fontWeight={400} color={'#5B5B5B'} fontSize={14}>{node.data.name}</Typography>)}
                {!node.isLeaf && node.data.name !== 'Clear' && (<Typography variant='body1' style={{ marginLeft: 5 }} display="inline"
                    fontWeight={600} fontSize={18}>{node.data.name} {`${node?.isOpen ? "(hide)" : '(show)'}`}</Typography>)}
            </div>
        );
    }



    function checkTreeSize() {
        if (treeRef.current) {
            setTreeSize(treeRef.current.visibleNodes.length);
        }
    }

    return (
        <Row style={{ padding: 20 }} align="middle" gutter={[4, 4]}>
            <Col span={24}>
                <Typography variant='body1' fontWeight={600} color={'#8D8D8D'} fontSize={13}>SEARCH ITEMS</Typography>

            </Col>

            <Col span={24}>
                <OutlinedInput
                    placeholder="Search"
                    value={searchTerm}
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                        setTimeout(() => {
                            checkTreeSize();
                        }, 100);

                    }}
                    style={{ width: '100%' }}
                    allowClear={true}
                    //end adornement to clear search
                    endAdornment={
                        <IconButton
                            onClick={() => setSearchTerm("")}
                            style={{ height: '100%', borderRadius: 0, borderLeft: '1px solid #D9D9D9', fontSize: 14, color: '#8D8D8D' }}
                        >
                            clear
                        </IconButton>
                    }
                />

            </Col>
            <Col span={24}>


            </Col>
            <Col span={24}>
                {treeSize === 0 && (<Typography variant='body1' fontWeight={600} color={'#8D8D8D'} fontSize={13}>No results found</Typography>)}
                {updating === true ? <CircularProgress /> :
                    <Tree
                        ref={treeRef}
                        initialData={data}
                        selectionFollowsFocus={false}
                        width={'100%'}
                        height={1000}
                        indent={24}
                        rowHeight={36}
                        overscanCount={1}
                        disableDrag
                        disableDrop
                        paddingTop={0}
                        paddingBottom={10}
                        padding={15 /* sets both */}
                        searchTerm={searchTerm}
                        searchFocusOffset={0}
                        emptyMessage="No results found"

                    >
                        {Node}
                    </Tree>
                }



            </Col>









        </Row>
    );
}