import { Typography } from '@mui/material';
import { Col, Row } from 'antd';

import React from 'react';

import { useCSVReader } from 'react-papaparse';
import uuid from 'react-uuid';

var randomColor = require('randomcolor');

export default function CSVReader({ getKeys, removeFile, updateProfiles }) {
  const { CSVReader } = useCSVReader();

  return (
    <Row>
      <Col span={24}>
        <CSVReader
          onUploadAccepted={async (results) => {

            const correctData = results.data;
            const newData = [];
            correctData.map((d) => {
              if (d.length > 1) {
                newData.push(d)
              }
            })
            console.log(newData);

            const filterData = newData.filter((f) => f !== undefined);



            const firstElement = filterData.shift();




            console.log(firstElement);





            const array = Array.from(Array(firstElement.length).keys());


            const arrayList = [];
            array.map((a) => {
              arrayList[a] = [];
            })
            filterData.map((a) => {
              a.map((p, i) => {
                const number = Number(p);
                arrayList[i].push(number);

              })
            })

            console.log(arrayList)


            const finalResults = [];
            firstElement.map((a, i) => {
              finalResults.push({
                oldParameter: a, label: a, depthColumn: false, newParameter: {}, data: arrayList[i], key: uuid(), type: 'spline',
                color: randomColor(), markerColor: randomColor()
              })
            })

            console.log(finalResults)

            updateProfiles(finalResults)

          }}
        >
          {({
            getRootProps,
            acceptedFile,
            ProgressBar,
            getRemoveFileProps,
          }) => (
            <>
              <Row align="middle" gutter={[12, 12]} style={{ display: 'flex', width: '100%', height: '100%', padding: 30 }}>
                {acceptedFile ? <Col span={24} type='button' {...getRootProps()} style={{
                  width: '100%', height: '100%',
                  borderRadius: 8, padding: 50,
                }} >

                  <Typography variant='h5' fontWeight={400}>File Loaded: {acceptedFile ? acceptedFile.name : "Drag and Drop File"}</Typography>
                </Col> : <Col span={24} type='button' {...getRootProps()} style={{
                  width: '100%', height: '100%', border: '1px dashed gray',
                  borderRadius: 8, padding: 40, textAlign: 'center', paddingTop: 10, paddingBottom: 20
                }} >

                  <Typography variant='h5' style={{ paddingBottom: 20 }} fontWeight={400}>{acceptedFile ? acceptedFile.name : "Drag and Drop File"}</Typography>
                </Col>}




              </Row>
              <ProgressBar />
            </>
          )}
        </CSVReader>
      </Col>
    </Row >
  );
}