import { faSquareCaretDown } from '@fortawesome/free-regular-svg-icons';
import { faCircleChevronDown, faSquareArrowUpRight } from '@fortawesome/free-solid-svg-icons';
import { Popover } from '@mui/material';
import { TwitterPicker } from 'react-color';
import { IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';


const ColorPickerButton = ({ initialColor, onChange, type, disabled }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [color, setColor] = React.useState(initialColor);

    useEffect(() => {
        setColor(initialColor);
    }, [initialColor]); 

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => { 
        setAnchorEl(null);
    };

    const handleColorChange = (newColor) => {
        setColor(newColor.hex);
        onChange(newColor.hex);
        console.log(newColor.hex);
        if (newColor?.hex?.length === 7) {
            handleClose();
        }

    };

    if (disabled) {
        return (
            <div>
                <FontAwesomeIcon icon={type === "single" ? faCircleChevronDown : faSquareCaretDown} color={color} size="lg" />

            </div>
        )
    }
    else {

        return (
            <div>
                <IconButton onClick={handleClick}>
                    <FontAwesomeIcon icon={type === "single" ? faCircleChevronDown : faSquareCaretDown} color={color} size="lg" />
                </IconButton>
                <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <TwitterPicker color={color} onChangeComplete={handleColorChange} />
                </Popover>
            </div>
        );
    }
};

export default ColorPickerButton;