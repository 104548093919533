import { Button, Checkbox, Divider, OutlinedInput, Typography, Select, MenuItem, CircularProgress, Paper } from '@mui/material';
import { Col, Row } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment-timezone';
import { fullMobile } from './util';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, } from 'firebase/firestore';
import { faBus, faBullseye, faCircleDot, faMapPin, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import uuid from 'react-uuid';

import axios, * as others from 'axios';
import {
    GoogleMap,
    StandaloneSearchBox,
    DrawingManager,
    LoadScript,
    HeatmapLayer,
    useJsApiLoader
} from '@react-google-maps/api';
import queryString from 'query-string';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import HomeIcon from '@mui/icons-material/Home';
import * as ReactDOMServer from 'react-dom/server';
import './map.css'

 


export default function StationsMap({ userInfo, screenWidth, openSnack, company, account, lat, lng, itemsProps, currentChildProps, swapItems,
    currentItemProps, selectStation, updateItems, updateCurrentItem, deleteItem, selectChild, probesRef, updateCenter }) {
    const [loading, setLoading] = useState(true);
    const [center, setCenter] = useState({ lat: -33, lng: 120 });
    const [zoom, setZoom] = useState(12);
    const [searchBox, setSearchBox] = useState(null);
    const [accountProps, setAccountProps] = useState(account);
    const [items, setItems] = useState(itemsProps);
    const [currentItem, setCurrentItem] = useState(currentItemProps);
    const [markers, setMarkers] = useState([]);
    const [currentChild, setCurrentChild] = useState(currentChildProps);
    const [probes, setProbes] = useState(probesRef);

    useEffect(() => {
        setCurrentChild(currentChildProps)
    }, [currentChildProps]) 

    useEffect(() => {
        setProbes(probesRef)
    }, [probesRef])


    const svgMarker = {
        path: "M27.648-41.399q0-3.816-2.7-6.516t-6.516-2.7-6.516 2.7-2.7 6.516 2.7 6.516 6.516 2.7 6.516-2.7 2.7-6.516zm9.216 0q0 3.924-1.188 6.444l-13.104 27.864q-.576 1.188-1.71 1.872t-2.43.684-2.43-.684-1.674-1.872l-13.14-27.864q-1.188-2.52-1.188-6.444 0-7.632 5.4-13.032t13.032-5.4 13.032 5.4 5.4 13.032z",
        fillColor: "blue",
        fillOpacity: 1,
        strokeWeight: 0,
        rotation: 0,
        scale: .8,
        anchor: new window.google.maps.Point(20, -2),
        labelOrigin: new window.google.maps.Point(20, 10)
    };

    const mapRef = useRef(null);
    useEffect(() => {



    }, []);


    async function getMapMetadata() {
        const db = getFirestore();
        const docRef = doc(db, "clients", userInfo.currentCompany, "accounts", userInfo.currentAccount, "stationMapData", "stationMapData");
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            const data = docSnap.data();
            setCenter({ lat: data.lat, lng: data.lng })
            setZoom(data.zoom)
            setLoading(false)
            updateCenter({ lat: data.lat, lng: data.lng })

        }
        else {
            setLoading(false)
            setCenter({ lat: account.lat, lng: account.lng })
            setZoom(16)
            updateCenter({ lat: account.lat, lng: account.lng })
        }
    }

    async function updateMapMetadata(center, zoom) {
        const db = getFirestore();

        const docRef = doc(db, "clients", userInfo.currentCompany, "accounts", userInfo.currentAccount, "stationMapData", "stationMapData");
        await setDoc(docRef, {
            lat: center.lat,
            lng: center.lng,
            zoom: zoom
        });


    }





    useEffect(() => {
        //if itemsProps.length less than items length, remove markers
       


        //check for added items and add markers
        itemsProps.forEach(item => {
            if (!items.find(i => i.key == item.key)) {
                addItemToMap(item)
            }
        })

        //check for removed items and remove markers
        items.forEach(item => {
            if (!itemsProps.find(i => i.key == item.key)) {
                removeItemFromMap(item)
            }
        })

        //check for updated items and update markers


        if (itemsProps.length === items.length) {

            itemsProps.forEach(item => {
                //check if item is already added to map, if it is do nothing
                const index = items.findIndex(x => x.key === item.key);

                if (item.marker && item.children !== undefined && index !== -1) {


                    //if children is not equal, update children
                    if (item.children !== items[index].children) {
                        updateMarkerChildren(item,)
                        items[index].children = item.children
                        setItems(items)

                    }
                    //if children is not equal, update children
                    if (item.archived !== items[index].archived) {
                        items[index].archived = item.archived
                        setItems(items)

                    }
                    //if item.visible is false, hide marker
                    if (item.visible === false) {

                        item.marker.setVisible(false)
                    }
                    else {

                        item.marker.setVisible(true)
                    }

                    if (item.color !== currentItem.color) {

                        updateMarkerColor(item, index)
                        items[index].color = item.color
                        setItems(items)

                    }
                    if (item.key === currentItem.key) {

                        updateMarkerName(item, index)
                        items[index].name = item.name
                        setItems(items)
                    }
                    if (item.lat !== currentItem.lat) {
                        updateMarkerLocation(item, index)
                        items[index].lat = item.lat
                        items[index].lng = item.lng
                        setItems(items)
                    }
                    if (item.centerMap) {
                        setCenter({ lat: item.lat, lng: item.lng })
                        item.centerMap = false
                        updateItems(item)
                        updateMapMetadata({ lat: item.lat, lng: item.lng }, zoom)
                    }

                    //if none of the top apply, do nothing







                }





            })
        }






    }, [itemsProps]);




    useEffect(() => {
        setCurrentItem(currentItemProps);
        highlightMarker(currentItemProps)

    }, [currentItemProps]);


    function updateMarkerChildren(item) {
        if (item.marker) {
            item.marker.setOptions({
                ...item.marker.options,
                children: item.children,
            });
        }
    }
    function updateLocation(item) {
 
        if (item.marker) {
            item.lat = item.marker.getPosition().lat()
            item.lng = item.marker.getPosition().lng()

            const index = itemsProps.findIndex(i => i.key == item.key);
      

            if (index > -1) {


                const db = getFirestore();
                const docRef = doc(db, "clients", userInfo.currentCompany, "stations", item.key);
                updateDoc(docRef, {
                    lat: item.lat,
                    lng: item.lng,
                    archived: item.archived
                }).then(() => {
                    console.log("Document successfully updated!");
                })

            }
        }
    }

    function updateMarkerColor(item) {

        if (item.marker) {

            item.marker.setIcon({
                //add background
                ...item.marker.icon,
                fillColor: item.color,

            });

        }
    }
    function updateMarkerName(item) {
        if (item.marker) {

            //update label
            item.marker.setLabel({
                ...item.marker.label,
                text: item.name,

            });
        }
    }

    function updateMarkerLocation(item) {

        if (item.marker) {
            item.marker.setPosition({ lat: item.lat, lng: item.lng })

        }
    }



    function highlightMarker(item) {




        if (mapRef.current) {
            //unhighlight all other markers
            items.forEach(marker => {
                if (marker.marker) {
                    marker.marker.setIcon({
                        ...marker.marker.icon,
                        strokeColor: item.key == marker.key ? 'yellow' : 'white',
                        strokeWeight: item.key == marker.key ? 2 : 0.5,
                    })
                }
            })



        }
    }

    function removeItemFromMap(item) {
        if (item.marker) {
            item.marker.setMap(null);
            item.marker = undefined;

            const newItems = [...items, item];
            //remove duplicate markers
            const uniqueItems = newItems.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.key === thing.key
                ))
            )
            setItems(uniqueItems)

        }
    }



    function addItemToMap(item) {


        if (mapRef.current && item.marker === undefined) {

            const marker = new window.google.maps.Marker({
                position: { lat: item.lat, lng: item.lng },
                map: mapRef.current,
                draggable: true,
                icon: {
                    ...svgMarker,
                    fillColor: item.color,
                    strokeColor: item.exists == false ? 'yellow' : 'white',
                    strokeWeight: item.exists == false ? 2 : 0.5,

                },


                //label black text with white background
                label: {
                    text: item.name,
                    color: 'white',
                    fontSize: '15px',
                    fontWeight: 'bold',
                    backgroundColor: 'white',
                    strokeColor: 'white',
                    strokeWeight: '4px',
                    padding: '20px',
                },

            });
            marker.addListener("click", () => {
              
                if (item.id !== itemsProps.id) {
                    selectChild({})
                }
                selectStation(item)


            });

            marker.addListener("dragend", () => {
                updateLocation(item)
                console.log('itmoving?')


            });

            //dragstart event select station
            marker.addListener("dragstart", () => {
                if (item.id !== itemsProps.id) {
                    selectChild({})
                }
                selectStation(item)

            });
            item.marker = marker;
            const newItems = [...items, item];
            //remove duplicate markers
            const uniqueItems = newItems.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.key === thing.key
                ))
            )

            //hide if item.visible is false
            if (item.visible == false) {
                item.marker.setVisible(false)
            }
         
            setItems(uniqueItems)







        }

    }




    function addCurrentLocationButton() {
        const html = ReactDOMServer.renderToString(<MyLocationIcon />);


        var currentLocationButton = document.createElement('button');
        currentLocationButton.innerHTML = html;
        currentLocationButton.style.cursor = 'pointer';
        // increase text size and add padding
        currentLocationButton.style.height = '40px';
        currentLocationButton.style.width = '40px';
        // increase text size and add padding
        currentLocationButton.style.padding = '2px';
        //add border radius and white background
        currentLocationButton.style.borderRadius = '4px';
        currentLocationButton.style.backgroundColor = 'white';
        //add box shadow
        currentLocationButton.style.boxShadow = '0 1px 4px rgba(0,0,0,0.3)';


        currentLocationButton.classList.add('custom-map-control-button');
        mapRef.current.controls[window.google.maps.ControlPosition.RIGHT_TOP].push(currentLocationButton);
        currentLocationButton.addEventListener('click', () => {
            // Try HTML5 geolocation.
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const pos = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        };
                        mapRef.current.setCenter(pos);
                        updateMapMetadata(pos, zoom)
                    },
                    () => {
                        handleLocationError(true, infoWindow, mapRef.current.getCenter());
                    }
                );
            } else {
                // Browser doesn't support Geolocation
                handleLocationError(false, infoWindow, mapRef.current.getCenter());
            }
        });
    }

    function handleLocationError(browserHasGeolocation, infoWindow, pos) {
        infoWindow.setPosition(pos);
        infoWindow.setContent(
            browserHasGeolocation

                ? "Error: The Geolocation service failed."
                : "Error: Your browser doesn't support geolocation."
        );
        infoWindow.open(mapRef.current);
    }


    const infoWindow = new window.google.maps.InfoWindow();

    function addToAccountLocationButton(info) {
        const html = ReactDOMServer.renderToString(<HomeIcon />);
        

        var currentLocationButton = document.createElement('button');
        //change cursor to pointer
        currentLocationButton.style.cursor = 'pointer';
        currentLocationButton.innerHTML = html;
        // increase text size and add padding
        currentLocationButton.style.height = '40px';
        currentLocationButton.style.width = '40px';
        // increase text size and add padding
        currentLocationButton.style.padding = '2px';
        //add border radius and white background
        currentLocationButton.style.borderRadius = '4px';
        currentLocationButton.style.backgroundColor = 'white';
        //add box shadow
        currentLocationButton.style.boxShadow = '0 1px 4px rgba(0,0,0,0.3)';




        currentLocationButton.classList.add('custom-map-control-button');
        mapRef.current.controls[window.google.maps.ControlPosition.RIGHT_TOP].push(currentLocationButton);

        currentLocationButton.addEventListener('click', (e) => {

            mapRef.current.setCenter({ lat: account.lat, lng: account.lng });
            updateMapMetadata({ lat: account.lat, lng: account.lng }, zoom)

        });
    }


    function checkForItemsToBeAdded(items) {
        if (items.length > 0) {
            items.forEach(item => {

                addItemToMap(item)
            })
        }
    }

    function addShowProbesButton(list) {
      
        //create a button in the bottom left of the map that toggles the visibility of the probes
        const showProbesButton = document.createElement('button');
        showProbesButton.textContent = 'Show Probes';
        // increase text size and add padding
        showProbesButton.style.fontSize = '14px';
        showProbesButton.style.padding = '8px';
        //add border radius and white background
        showProbesButton.style.borderRadius = '4px';
        showProbesButton.style.backgroundColor = 'white';
        //add box shadow
        showProbesButton.style.boxShadow = '0 1px 4px rgba(0,0,0,0.3)';
        showProbesButton.classList.add('custom-map-control-button');
        mapRef.current.controls[window.google.maps.ControlPosition.LEFT_BOTTOM].push(showProbesButton);
        showProbesButton.addEventListener('click', () => {
            if (showProbesButton.textContent == 'Show Probes') {
                showProbesButton.textContent = 'Hide Probes';
                //show probes
                list.forEach(item => {
                    if (item.type == 'probe') {
                        console.log(item)
                        item.marker.setVisible(true)
                    }
                })
            } else {
                showProbesButton.textContent = 'Show Probes';
                //hide probes
                list.forEach(item => {
                    if (item.type == 'probe') {
                        item.marker.setVisible(false)
                    }
                })
            }
        });

    }



    function handleLoad(map) {
        mapRef.current = map;
        addCurrentLocationButton();
        addToAccountLocationButton();

        addMapTracker()
        getMapMetadata()

        setTimeout(() => {


            //filter items do not equal -1

            checkForItemsToBeAdded(items)
        }, 500);

        if (probes.length > 0) {
            //add marker for each probe but set them to invisible
            probes.forEach(probe => {

                if (probe.recording !== undefined && probe.recording == true) {
                    const svgMarker = {
                        path: "M27.648-41.399q0-3.816-2.7-6.516t-6.516-2.7-6.516 2.7-2.7 6.516 2.7 6.516 6.516 2.7 6.516-2.7 2.7-6.516zm9.216 0q0 3.924-1.188 6.444l-13.104 27.864q-.576 1.188-1.71 1.872t-2.43.684-2.43-.684-1.674-1.872l-13.14-27.864q-1.188-2.52-1.188-6.444 0-7.632 5.4-13.032t13.032-5.4 13.032 5.4 5.4 13.032z",
                        fillColor: "blue",
                        fillOpacity: 1,
                        strokeWeight: 0,
                        rotation: 0,
                        scale: .8,
                        anchor: new window.google.maps.Point(15, 15),
                        labelOrigin: new window.google.maps.Point(15, 10)
                    };

                    //create name with line breaks every 20 characters <br />
                    let name = probe.name
                    let nameArray = name.split('')
                    let nameWithBreaks = ''
                    for (let i = 0; i < nameArray.length; i++) {
                        if (i % 20 == 0 && i != 0) {
                            nameWithBreaks += '<br />'
                        }
                        nameWithBreaks += nameArray[i]
                    }


                    const marker = new window.google.maps.Marker({
                        position: { lat: probe.lat, lng: probe.lon },
                        map: mapRef.current,
                        icon: {
                            ...svgMarker,
                            fillColor: 'red',
                            strokeColor: 'white',
                            strokeWeight: 0.5,
                        },

                        visible: false
                    });
                    probe.marker = marker;

                    //when marker is clicked, open info window showing probe name
                    marker.addListener('click', () => {
                        const infoWindow = new window.google.maps.InfoWindow({
                            content: `<div style="font-size: 14px; font-weight: 600; color: black; text-align: center;">${nameWithBreaks}</div>`
                        });
                        infoWindow.open(mapRef.current, marker);

                        //close info window when map is clicked
                        mapRef.current.addListener('click', () => {
                            infoWindow.close();
                        }
                        )
                        //close info window when another marker is clicked
                        marker.addListener('click', () => {
                            infoWindow.close();
                        }
                        )
                        //close info window if x is clicked
                        infoWindow.addListener('closeclick', () => {
                            infoWindow.close();
                        }
                        )
                    })




                    probe.type = 'probe'
                }
            })





            addShowProbesButton(probes)
        }



    }

    function addMapTracker() {
        mapRef.current.addListener('center_changed', () => {
            const center = mapRef.current.getCenter();
            const lat = center.lat();
            const lng = center.lng();
            const zoom = mapRef.current.getZoom();

            updateMapMetadata({ lat, lng }, zoom)




        });
        mapRef.current.addListener('zoom_changed', () => {
            const zoom = mapRef.current.getZoom();
            const center = mapRef.current.getCenter();
            const lat = center.lat();
            const lng = center.lng();
            updateMapMetadata({ lat, lng }, zoom)

        });

        //add listener for dragend 
        mapRef.current.addListener('dragend', () => {
            const center = mapRef.current.getCenter();
            const lat = center.lat();
            const lng = center.lng();
            updateCenter({ lat, lng })
        })
    }



    return (

        <Row style={{ fontFamily: 'Roboto, sans-serif', height: '95vh', }} gutter={[0, 0]}>
            <Col {...fullMobile(24, 24)} style={{}}>
                <Paper elevation={3} style={{ padding: 10, height: '100%', overflow: 'auto' }}>
                    {Object.keys(accountProps).length > 0 && (<div style={{ height: '100%', width: '100%', paddingBottom: 20 }}>


                        <GoogleMap
                            ref={mapRef}
                            mapContainerStyle={{ height: '100%', width: '100%' }}
                            center={center}
                            onLoad={handleLoad}
                            zoom={zoom}
                            mapTypeId="satellite"
                            controlSize={32}
                            options={{
                                scaleControl: true,
                                //satellite view
                                mapTypeId: 'satellite',
                                //disable street view
                                streetViewControl: false,
                                //disable zoom control
                                rotateControl: false,
                                //disable fullscreen control
                                fullscreenControl: false,
                                tilt: 0,
                                //disable keyboard shortcuts
                            }}
                        >





                        </GoogleMap>

                    </div >
                    )}
                </Paper>
            </Col>




        </Row>
    )


}