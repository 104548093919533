import { Autocomplete, OutlinedInput, TextField, Typography, Select, MenuItem, InputAdornment, Divider, IconButton } from '@mui/material';
import { Col, Row, Progress, Popconfirm } from 'antd';
import React from 'react';
import Dropzone from 'react-dropzone';
import uuid from 'react-uuid';
import '../../../extra/hoverStyle.css';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import moment from 'moment';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

export default class PhotoList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            type: this.props.type,
            item: this.props.item,
            files: this.props.item.files !== undefined ? this.props.item.files : [],
            loading: true,
            progressBars: {},
            preview: this.props.preview,
            storageURL: this.props.storageURL
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
        if (this.props.item !== prevProps.item) {
            this.setState({ item: this.props.item, files: this.props.item.files !== undefined ? this.props.item.files : [], })
        }
        if (this.props.preview !== prevProps.preview) {
            this.setState({ preview: this.props.preview })
        }

    }
    componentDidMount() {
        const { userInfo } = this.state;
    }




    updateFiles = (boo, val) => {
        this.props.updateItem(boo, val)
    }
    updateFile = (index, boo, val) => {
        const { files } = this.state;
        files[index][boo] = val;
        this.setState({ files })
        this.updateFiles('files', files)
    }

    render() {

        const { item, screenWidth, files, progressBars, userInfo, preview, storageURL } = this.state;

        const DeleteItem = ({ item }) => {

            return (
                <Popconfirm
                    title="Are you sure to delete this photo?"
                    onConfirm={async () => {
                        const filterFiles = files.filter((f) => f.key !== item.key);


                        this.setState({ progressBars, files: filterFiles })
                        this.updateFiles('files', filterFiles)

                    }}
                    okText="Yes"
                    cancelText="No"
                >
                    <IconButton style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="small"
                    >
                        <DeleteForeverIcon />
                    </IconButton>
                </Popconfirm>


            )
        }

        console.log(files)

        return (
            <Row gutter={[12, 12]} align="middle">
                <Col sm={24}>
                    <Row align="middle" gutter={[24, 24]}>
                        <Col xs={24} >
                            <Divider />
                        </Col>
                        <Col xs={24} >
                            <Typography variant="h6">Photos</Typography>
                        </Col>

                        {Object.values(progressBars).length > 0 && (<Col xs={12} sm={6} >
                            {Object.values(progressBars).map((p) => {
                                return (
                                    <Col span={24}>
                                        {p.progress > 0 && (
                                            <div> {p.fileName} <Progress percent={p.progress} /></div>
                                        )}
                                    </Col>

                                )
                            })}
                        </Col>)}

                        {preview ?
                            files.map((p, i) => {

                                return (
                                    <Col xs={12} sm={6} style={{ borderRadius: 8 }}>
                                        <Row align="middle" gutter={[8, 8]}>
                                            <Col span={24} >
                                                <img src={p.fileLink} style={{ width: '100%', borderRadius: 6 }} />

                                            </Col>
                                            <Col span={24}>
                                                <Typography variant='body1'>{p.description || ''}</Typography>

                                            </Col>

                                        </Row>
                                    </Col>

                                )
                            })

                            :

                            files.map((p, i) => {

                                return (
                                    <Col xs={12} sm={6} style={{ borderRadius: 8 }}>
                                        <Row align="middle" gutter={[8, 8]}>
                                            <Col span={24} >
                                                    <img src={p.fileLink} style={{ width: '100%', borderRadius: 6 }} />
                                         

                                            </Col>
                                            <Col span={20}>
                                                <OutlinedInput fullWidth notched={false} label="none" placeholder={"Description"} size="small"

                                                    value={item.description}
                                                    onChange={(e) => {
                                                        this.updateFile(i, `description`, e.target.value)
                                                    }} />
                                            </Col>
                                            <Col span={4}>

                                                <DeleteItem item={p} />

                                            </Col>

                                        </Row>
                                    </Col>

                                )
                            })}


                        {!preview && (<Col className='fileUpload' xs={{ offset: 0, span: 24 }} sm={{ offset: files.length > 0 ? 0 : 2, span: files.length > 0 ? 6 : 20 }} style={{ border: '1px dashed gray', borderRadius: 8 }}>
                            <Dropzone onDrop={(acceptedFiles) => {

                                acceptedFiles.map((f) => {

                                    const storage = getStorage();
                                    const key = uuid()

                                    const storageRef = ref(storage, `${storageURL}${key}`);

                                    const uploadTask = uploadBytesResumable(storageRef, f);
                                    uploadTask.on('state_changed',
                                        (snapshot) => {

                                            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                                            console.log('Upload is ' + progress + '% done');

                                            progressBars[key] = { key, progress, fileName: f.name }

                                            this.setState({ progressBars })

                                            switch (snapshot.state) {
                                                case 'paused':
                                                    console.log('Upload is paused');
                                                    break;
                                                case 'running':
                                                    console.log('Upload is running');
                                                    break;
                                            }
                                        },
                                        (error) => {
                                        },
                                        () => {
                                            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {


                                                const file = {
                                                    fileCreateDate: moment().format('X'),
                                                    fileCreator: `${userInfo.firstName || ""} ${userInfo.lastname}`,
                                                    fileCreatorKey: userInfo.id,
                                                    fileLink: downloadURL,
                                                    fileName: f.name,
                                                    fileType: f.type,
                                                    description: '',
                                                    key
                                                }
                                                console.log(file)
                                                files.push(file);

                                                delete progressBars[key];

                                                this.setState({ progressBars, files })
                                                this.updateFiles('files', files)


                                            });
                                        }
                                    );






                                })
                            }} >
                                {({ getRootProps, getInputProps }) => (
                                    <section>
                                        <div {...getRootProps()}>

                                            <Row align="middle">

                                                <Col span={24} style={{ padding: 6, textAlign: 'center' }}>
                                                    <AddAPhotoIcon color="primary" />
                                                </Col>
                                                <Col span={24} style={{ padding: 6, textAlign: 'center' }}>
                                                    <Typography variant='h6'>Upload Photos</Typography>
                                                </Col> <input {...getInputProps()} /></Row>
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                        </Col>)}
                    </Row>
                </Col>











            </Row>
        );
    }
} 