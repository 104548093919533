import { Button, Divider, OutlinedInput, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import moment from 'moment';
import React from 'react';
import axios, * as others from 'axios';

export default class MergeWeather extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            oldWeather: '',
            newWeather: '' 
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }

    }
    componentDidMount() {
        window.scrollTo(0, 0)
    }


    mergeData = () => {
        const { oldWeather, newWeather } = this.state;

        const checks = [oldWeather, newWeather].filter((f) => f !== '');

        const q = {
            oldCompany: '73d35d5-8230-deb5-fece-734a75a518',
            newCompany: 'Bourne Pondf7e37b6-d013-50c6-1d-faa64801f3e',
            oldAccount: '6be0d2-f130-cb36-3178-8b355ccbd50',
            newAccount: '7c128e-fb0f-bc1e-afd-7622078cf5f8',
        };

        if (checks.length === 2) {
            const proxyUrl = "https://mycorslake.herokuapp.com/";
            axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                raw: `SELECT *
                FROM weather_data
                WHERE account = '${q.oldAccount}'
                ;`
            })
                .then(async (response) => {


                    const newData = response.data.map((d) => {
                        var time = moment(d.dt, 'X').tz("America/Los_Angeles").format("YYYY-MM-DD HH:mm:ss");
                        return ({
                            time: `'${time}'`,
                            dt: d.dt,
                            timestamp: 123,
                            account: `'${q.newAccount}'`,
                            company: `'${q.newCompany}'`,
                            temp: d.temp,
                            feels_like: d.feels_like,
                            pressure: d.pressure,
                            humidity: d.humidity,
                            dew_point: d.dew_point,
                            uvi: d.uvi,
                            clouds: d.clouds,
                            visibility: d.visibility,
                            wind_speed: d.wind_speed || 0,
                            wind_gust: d.wind_gust || 0,
                            wind_deg: d.wind_deg || 0,
                            rain: d.rain,
                            weather: `'${d.weather || ''}'`,
                            weatherId: d.weather[0].id || 0,
                            weatherMain: `'${d.weathermain || ''}'`,
                            weatherDescription: `'${d.weather.description}'`,
                            weatherIcon: `'${d.weather.icon}'`,

                        })
                    })
                    console.log(newData)

                    const hourlyVals = newData.map((h) => `(${Object.values(h)})`);
                    const hourlyString = hourlyVals.join();
                    console.log(hourlyString)


                    axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                        raw: `INSERT INTO weather_data VALUES ${hourlyString} ON CONFLICT DO NOTHING; `
                    }).then(async (response) => {
                        // handle success
                        console.log(response)
                    })
                        .catch(function (error) {
                            // handle error
                            console.log(error);
                        })
                        .then(function () {
                            // always executed
                        });

                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                })
                .then(function () {
                    // always executed
                });


        }
        else {
            this.props.openSnack('error', 'Enter all inputs!')
        }
    }

    render() {

        const { account, newWeather, oldWeather, screenWidth } = this.state;

        return (
            <Row style={{}}>
                <Col span={23}>
                    <Row align="middle">
                        <Col xs={16} sm={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>
                            <Typography variant="h4">Merge Weather Data</Typography>
                        </Col>
                        <Col xs={6} sm={4} style={{ paddingTop: 30, paddingBottom: 15, textAlign: 'right' }}>

                        </Col>
                        <Col span={24}>
                            <Divider />
                        </Col>
                        <Col span={23} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 30 }}>

                            <Row align="middle" gutter={[12, 12]}>
                                <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                                    <Typography variant="body1">Old Weather<span style={{ color: 'red' }}>*</span></Typography>
                                </Col>
                                <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                    <OutlinedInput fullWidth notched={false} label="none" placeholder={"Old Weather"} size="small"
                                        value={oldWeather}
                                        onChange={(e) => {
                                            this.setState({ oldWeather: e.target.value })
                                        }} />
                                </Col>
                                <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                                    <Typography variant="body1">New Probe<span style={{ color: 'red' }}>*</span></Typography>
                                </Col>
                                <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                    <OutlinedInput fullWidth notched={false} label="none" placeholder={"New Weather"} size="small"
                                        value={newWeather}
                                        onChange={(e) => {
                                            this.setState({ newWeather: e.target.value })
                                        }} />
                                </Col>
                                <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>

                                </Col>
                                <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                    <Button color="primary" variant="contained" style={{ padding: 15, textTransform: 'none' }}
                                        onClick={() => this.mergeData()}>Merge</Button>
                                </Col>


                            </Row>

                        </Col>
                    </Row>
                </Col>

            </Row>
        );
    }
} 