import { Col, Row } from 'antd';
import React from 'react';
import { GithubPicker } from 'react-color';
import reactCSS from 'reactcss';

export default class TaskColor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            displayColorPicker: false,
            color: this.props.color,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.color !== prevProps.color) {
            this.setState({ color: this.props.color })
        }


    }

    componentDidMount


    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };

    handleClose = () => {
        this.setState({ displayColorPicker: false })
    };

    handleChange = (color) => {
        console.log(color)
        this.setState({ color: color.hex })
        console.log(color.hex)
        this.props.updateData("color", color.hex)
    };

    render() {

        const styles = reactCSS({
            'default': {
                color: {
                    width: '36px',
                    height: '14px',
                    borderRadius: '2px',
                    background: `${this.state.color}`,
                },
                swatch: {
                    leftPadding: 10,
                    padding: '5px',
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                },
                popover: {
                    position: 'absolute',
                    left: '-120px',
                    top: '-120px',
                    zIndex: '2',
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
            },
        });

        return (
            <Row>
                <Col style={styles.swatch} onClick={this.handleClick}>
                    <div style={styles.color} />
                </Col>
                {this.state.displayColorPicker ? <Col style={styles.popover}>
                    <div style={styles.cover} onClick={this.handleClose} />
                    <GithubPicker color={this.state.color} onChange={this.handleChange} />
                </Col> : null}

            </Row>
        )
    }
}

