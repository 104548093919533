import { Button, Checkbox, Divider, OutlinedInput, Typography, Select, MenuItem, CircularProgress, Paper, Input, FormControl, TextField, IconButton, Box, Modal } from '@mui/material';
import { Col, Popconfirm, Row } from 'antd';
import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { fullMobile } from './util';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, } from 'firebase/firestore';

import MUIDataTable from "mui-datatables";
import uuid from 'react-uuid';
import DataColorCompact from '../../account/account/dashboard/settings/dataColorCompact';
import axios, * as others from 'axios';
import queryString from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { createAlert, deleteNewAlert, getAlerts, getStaff, updateNewAlert } from './firestore';
import AlertsList from './AlertsList';
import AlertsTable from './AlertsTable';
import ProbeParameters from '../../extra/probeParameters.json';

const NodeAlerts = ({ nodeid, parentid, userInfo, updateAlerts, parameters }) => {
    const [alerts, setAlerts] = useState([]);
    const [showAlertsModal, setShowAlertsModal] = useState(false);
    const [staff, setStaff] = useState([]);
    const handleOpen = () => setShowAlertsModal(true);
    const handleClose = () => {
        setShowAlertsModal(false);
        getInfo();
    }

    useEffect(() => {
        getInfo();
    }, [nodeid]);

    async function getInfo() {
        const alerts = await getAlerts(userInfo.currentCompany, nodeid)
        console.log(alerts)
        setAlerts(alerts);

        updateAlerts()
        const staff = await getStaff(userInfo.currentCompany, userInfo.currentAccount);
        console.log(staff)
        setStaff(staff);
    }

    async function addAlert() {
        const alert = {
            id: uuid(),
            name: `New Alert ${alerts.length + 1}`,
            node: nodeid,
            station: parentid,
            company: userInfo.currentCompany,
            account: userInfo.currentAccount,
            createdDate: moment().format('MM/DD/YYYY HH:mm:ss'),
            createdBy: userInfo.id,
            parameter: null,
            value: '',
            type: null,
            active: true,
            snoozeDate: null,
            alertType: 'continuous',
        }
        await createAlert(alert);
        getInfo();
    }

    async function deleteAlert(alert) {
        console.log(alert.id)
        await deleteNewAlert(alert);
        getInfo();
    }

    async function duplicateAlert(alert) {
        const newAlert = {
            ...alert,
            id: uuid(),
            name: `${alert.name} (Copy)`,
            createdDate: moment().format('MM/DD/YYYY HH:mm:ss'),
            createdBy: userInfo.id,
        }
        await createAlert(newAlert);
        getInfo();
    }

    async function updateAlert(id, boo, val) {
        console.log(id, boo, val)
        await updateNewAlert(userInfo.currentCompany, id, boo, val);
        getInfo();

    }


    return (
        <Row>
            <Col span={24}>
                <Button
                    fullWidth
                    variant="outlined"
                    color="primary"
                    style={{
                        border: '1px solid #404050',
                        textTransform: 'none',
                        color: '#404050',

                    }}
                    onClick={() => setShowAlertsModal(true)}
                >Edit Alerts</Button>

            </Col>
            <Col span={24}>
                <AlertsList alerts={alerts} parameters={parameters} updateAlert={updateAlert} />
            </Col>

            <Modal
                open={showAlertsModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                bodyStyle={{ backgroundColor: 'white', padding: 0 }}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '75vw',
                    height: 600,
                    bgcolor: 'background.paper',
                    border: '1px solid #404050',
                    boxShadow: 24,
                    p: 0,
                    padding: 0
                }}>
                    <Row>
                        <Col span={12} style={{ padding: 12 }}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Node Alerts ({alerts.length})
                            </Typography>
                        </Col>
                        <Col span={12} style={{ textAlign: 'right', paddingBottom: 20, padding: 12 }}>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{
                                    backgroundColor: '#404050',
                                    textTransform: 'none',
                                    color: 'white',

                                }}
                                onClick={() => addAlert()}
                            >Create Alert +</Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                style={{
                                    border: '1px solid #404050',
                                    textTransform: 'none',
                                    color: '404050',
                                    marginLeft: '5px'

                                }}
                                onClick={() => handleClose()}
                            >Save Alerts</Button>
                        </Col>
                        <Col span={24}
                            style={{

                                overflowY: 'scroll',
                                height: 500,
                            }}>
                            <AlertsTable alerts={alerts} updateAlert={updateAlert} deleteAlert={deleteAlert} duplicateAlert={duplicateAlert} parameters={parameters} staff={staff} />
                        </Col>


                    </Row>


                </Box>
            </Modal>

        </Row>

    )
}

export default NodeAlerts;