import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { collection, getDocs, getDoc, addDoc, updateDoc, doc, getFirestore, deleteDoc, writeBatch, setDoc, where, query, orderBy, limit, startAfter } from 'firebase/firestore';




export const uploadFile = async (file, path, company, fileId) => {
  const storage = getStorage();
  const db = getFirestore();
  try {
    const storageRef = ref(storage, path);
    await uploadBytes(storageRef, file);
    const downloadUrl = await getDownloadURL(storageRef);
    const size = file.size;
    const fileRef = doc(db, 'clients', company, 'images', fileId);
    await updateDoc(fileRef, {
      status: 'uploaded',
      downloadUrl: [downloadUrl],
      size,
    });
    console.log('File uploaded successfully:', downloadUrl)
    return downloadUrl;

  } catch (error) {
    console.error('Error uploading file:', error);
    return null;
  }
};

export const getFileDownloadURL = async (path) => {
  const storage = getStorage();
  try {
    const storageRef = ref(storage, path);
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;
  } catch (error) {
    console.error('Error getting file download URL:', error);
    return null;
  }
};

export const uploadSingleFile = async (file, path) => {
  const storage = getStorage();
  try {
    const storageRef = ref(storage, path);
    await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(storageRef);
    const size = file.size;
    return ({ downloadURL, size });
  } catch (error) {
    console.error('Error uploading file:', error);
    return null;
  }
};
