import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { IconButton, CircularProgress, Typography, OutlinedInput, Button, Grid, Paper } from '@mui/material';
import { Col, Layout, Row } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import DashboardContentPortal from './dashboardPublicPortal';
import SideBar from './sideBar';
import { useNavigate, useParams } from 'react-router-dom';
import { collection, doc, getDoc, getDocs, getFirestore, query, setDoc, updateDoc, where } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Logo from './ltLogo.png';
import HeroImage from './park.png';

const { Header, Footer, Sider, Content } = Layout;

const DashboardPortal = (props) => {
    const [state, setState] = useState({
        screenWidth: props.screenWidth,
        userInfo: props.userInfo,
        account: props.account,
        company: props.company,
        type: props.type,
        preferences: { sideBar: true },
        loadContent: false
    });
    const [portalInfo, setPortalInfo] = useState({})
    const [loading, setLoading] = useState(true);
    const [password, setPassword] = useState('');
    const [loggedIn, setLoggedIn] = useState(false);
    let { portal } = useParams();
    let { accountID } = useParams();


    useEffect(() => {
        window.scrollTo(0, 0);
        console.log("THIS ON")
        getInfo()

        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user) {
                console.log("User is signed in.")
                setLoggedIn(true)
            } else {
                console.log("User is not signed in.")
                setLoggedIn(false)
            }
        });



    }, []);



    async function getInfo() {

        console.log("portal", portal)
        console.log("accountID", accountID)
        const { type } = state;
        if (type !== 'open') {
            //update loadContent: true in state
            setState(prevState => ({ ...prevState, loadContent: true }));
        } else {
            setState(prevState => ({ ...prevState, loadContent: true }));
        }

        if (portal !== undefined && accountID !== undefined) {
            const db = getFirestore();

            //collectionRef in publicPortals where account is equal to accountID and url is equal to portal
            const queryData = query(collection(db, "publicPortals"), where('account', '==', accountID), where('url', '==', portal));

            const snap = await getDocs(queryData);

            snap.forEach((doc) => {
                console.log(doc.id, " => ", doc.data());
                if (doc.exists()) {

                    console.log("Document data:", doc.data())
                    setPortalInfo(doc.data())
                    if (doc.data().protected === false) {
                        setLoading(false)
                    }
                    else {
                        console.log("Protected")
                        setLoading('Protected')
                    }



                } else {
                    console.log("No such document!");
                }

            });

        }
        else {
            setLoading('No Portal Found')
        }

    }


    let navigate = useNavigate()




    useEffect(() => {
        setState(prevState => ({ ...prevState, screenWidth: props.screenWidth }));
    }, [props.screenWidth]);

    useEffect(() => {
        setState(prevState => ({ ...prevState, userInfo: props.userInfo }));
    }, [props.userInfo]);

    useEffect(() => {
        setState(prevState => ({ ...prevState, account: props.account }));
    }, [props.account]);

    useEffect(() => {
        setState(prevState => ({ ...prevState, company: props.company }));
    }, [props.company]);

    useEffect(() => {
        setState(prevState => ({ ...prevState, type: props.type }));
    }, [props.type]);










    async function handleSubmit(e) {
        e.preventDefault();
        console.log("submit")
        if (password === portalInfo.password) {
            setLoading(false)
        }
        else {
            alert("Incorrect Password")
        }
    }

    function handleChange(e) {
        setPassword(e.target.value)
    }






    if (loading === true) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <FontAwesomeIcon icon={faSpinner} spin size="3x" />
            </div>

        )
    }
    if (loading === 'Protected') {

        return (
            <Grid container style={{ height: '100vh' }}>
                <Grid item xs={12} sm={4} style={{
                    display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh',
                    padding: props.screenWidth === 'xs' ? 20 : 0,

                    background: props.screenWidth === 'xs' ? 'linear-gradient(160deg, #404050 30%, #586574 70%)' : 'white'

                }}>
                    <form onSubmit={handleSubmit} style={{
                        textAlign: 'center',
                        textAlign: 'left',
                        border: props.screenWidth === 'xs' ? '1px solid rgba(0,0,0,0.1)' : 'none',
                        boxShadow: props.screenWidth === 'xs' ? '0px 0px 10px 0px rgba(0,0,0,0.1)' : 'none',
                        backgroundColor: 'white',
                        padding: 40,
                        borderRadius: 10,
                        bottomPadding: 20,


                    }}>
                        {/* insert logo */}
                        <img src={Logo} alt="Logo" style={{ width: '200px', marginBottom: '40px', paddingTop: 20 }} />

                        <Typography variant="h4" fontWeight={700} style={{ color: 'rgba(0,0,0,0.9)', position: 'relative', marginBottom: '40px', }}
                            fontSize={props.screenWidth === 'xs' ? 24 : 34} >{portalInfo?.name}</Typography>
                        <Typography variant="h4" fontWeight={700}
                            fontSize={props.screenWidth === 'xs' ? 20 : 30}


                            style={{ color: 'rgba(0,0,0,0.9)', position: 'relative', marginBottom: '20px' }}>
                            Sign in to your portal
                        </Typography>
                        <Typography variant="h4" style={{ marginBottom: '10px', }} fontSize={18} fontWeight={500}>Password Protected</Typography>
                        <Typography variant="h6" style={{ marginBottom: '20px', }} fontSize={14} fontWeight={700}>Enter Password below</Typography>
                        <OutlinedInput placeholder='Password' type="password" fullwidth
                            style={{ width: '100%' }}

                            value={password} onChange={handleChange} />
                        <br />
                        <div style={{ paddingBottom: 20 }} />
                        <Button
                            size="large"
                            fullWidth
                            style={{

                                textTransform: 'none',
                                backgroundColor: '#3f51b5',
                                color: 'white',
                                marginBottom: props.screenWidth === 'xs' ? 40 : 0,
                            }}
                            sx={{ px: 6 }}

                            type="submit">Submit</Button>


                    </form>
                </Grid>
                <Grid item xs={0} sm={8} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', }}>
                    <Grid container style={{
                        height: '100vh',
                        background: 'linear-gradient(160deg, #404050 30%, #586574 70%)' // Gradient applied here
                    }}>

                        {/* Left side with text and button */}
                        <Grid item xs={12} sm={6} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 30 }}>
                            <Typography variant="h6" style={{ color: '#404050' }}>LakeTech</Typography>
                            <Typography variant="h3" fontSize={34} fontWeight={700} style={{ color: 'white', marginBottom: '1rem' }}>
                                Transform your water quality data into actionable insights.
                            </Typography>
                            <Typography variant="body1" style={{ color: 'white', marginBottom: '2rem' }}>
                                We use data-driven project insights to help customers make informed decisions about their water quality.
                            </Typography>
                            <Button variant="contained" style={{ backgroundColor: '#404050', border: '1px solid white', color: 'white' }}
                                /* onclick go to laketech.com */
                                onClick={() => {
                                    window.open('https://www.laketech.com/', '_blank')
                                }}


                            >Learn More</Button>
                        </Grid>

                        {/* Right side with image */}
                        <Grid item xs={0} sm={6} style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 10 }}>
                            <Paper elevation={4} style={{ overflow: 'hidden' }}>
                                <img src={HeroImage} alt="Hero" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                            </Paper>


                        </Grid>

                    </Grid>
                </Grid>




                {/*    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <div
                    //this is in the top right corner of the screen
                    style={{ position: 'absolute', top: 10, left: 10, padding: 10, }}>

                    {loggedIn ? <Button variant="link" color="inherit" style={{ textTransform: 'none', padding: 0, fontSize: 15 }}
                        onClick={() => navigate('/siteInfoHome/portals')}>
                        <Typography variant='body1' fontSize={16} fontWeight={300} color="blue" >Back to site info</Typography>
                    </Button>
                        :
                        <Button variant="link" color="inherit" style={{ textTransform: 'none', padding: 0, fontSize: 15 }}
                            onClick={() => navigate('/')}>
                            <Typography variant='body1' fontSize={16} fontWeight={300} color="blue" >Back to login page</Typography>
                        </Button>}
                </div>



               

            </div> */}
            </Grid>










        )
    }
    else if (loading === 'No Account Found') {
        return (
            <div>
                <h1>No Account Found</h1>
            </div>

        )
    }
    else {

        return (
            <Layout>


                <Content style={{ backgroundColor: 'white', minHeight: '100vh', }}>

                    <Row>

                        < Col span={24}>
                            {state.loadContent && (
                                <DashboardContentPortal goBack={props.goBack} portal={portal} accountID={accountID} company={state.company}
                                    type={state.type} account={state.account} userInfo={state.userInfo} screenWidth={state.screenWidth} openSnack={props.openSnack} />)}
                        </Col>

                    </Row>


                </Content>



            </Layout >
        )
    }






}

export default DashboardPortal;

