import { collection, getDocs, getDoc, addDoc, updateDoc, doc, getFirestore, deleteDoc, writeBatch, setDoc, where, query, } from 'firebase/firestore';
import { getStorage, ref, getMetadata, uploadBytes, getDownloadURL } from "firebase/storage";
import moment from 'moment';
import uuid from 'react-uuid';



export const getAlerts = async (company, node) => {
    const db = getFirestore();
    try {
        const alertRef = collection(db, "clients", company, 'newAlerts');
        const alertQuery = query(alertRef, where("node", "==", node));
        const alertSnapshot = await getDocs(alertQuery);
        const alerts = alertSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        }));
        //sort by create date which is in format of MM/DD/YYYY HH:mm:ss
        alerts.sort((a, b) => {
            return moment(a.createdDate, 'MM/DD/YYYY HH:mm:ss').diff(moment(b.createdDate, 'MM/DD/YYYY HH:mm:ss'))
        })
        return alerts;
    } catch (error) {
        console.error("Error fetching alerts:", error);
        return [];
    }
};
export const getOffsets = async (company, node) => {
    const db = getFirestore();
    try {
        const ref = collection(db, "clients", company, 'newOffsets');
        const queryRef = query(ref, where("node", "==", node));
        const snapshot = await getDocs(queryRef);
        const items = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        }));
        //sort by create date which is in format of MM/DD/YYYY HH:mm:ss
        items.sort((a, b) => {
            return moment(a.endDate, 'MM/DD/YYYY HH:mm:ss').diff(moment(b.endDate, 'MM/DD/YYYY HH:mm:ss'))
        })
        return items;
    } catch (error) {
        console.error("Error fetching alerts:", error);
        return [];
    }
};
export const getNodeCodes = async (company, node) => {
    const db = getFirestore();
    try {
        const ref = collection(db, "clients", company, 'nodeCodes');
        const queryRef = query(ref, where("node", "==", node));
        const snapshot = await getDocs(queryRef);
        const items = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        }));

        return items;
    } catch (error) {
        console.error("Error fetching alerts:", error);
        return [];
    }
};

export const createAlert = async (alert) => {
    const db = getFirestore();

    try {
        console.log(alert)
        const alertRef = doc(db, 'clients', alert.company, 'newAlerts', alert.id);
        await setDoc(alertRef, alert);
        console.log('success')
        return 'success'
    }
    catch (error) {

        console.error('Error adding file:', error);
    }
};
export const createOffset = async (item) => {
    const db = getFirestore();

    try {
        console.log(item)
        const ref = doc(db, 'clients', item.company, 'newOffsets', item.id);
        await setDoc(ref, item);
        console.log('success')
        return 'success'
    }
    catch (error) {

        console.error('Error adding file:', error);
    }
};

export const deleteOffsets = async (items) => {
    console.log(items)
    const db = getFirestore();
    const batch = writeBatch(db);
    try {
        items.forEach((item) => {
            const ref = doc(db, 'clients', item.company, 'newOffsets', item.id);
            batch.delete(ref);
        });
        await batch.commit();
        return 'success';
    } catch (error) {
        console.error('Error deleting alerts:', error);
        return 'error';
    }
}

export const updateOffset = async (item, openSnack) => {
    const db = getFirestore();
    try {
        console.log("UPDATED")
        await updateDoc(doc(db, 'clients', item.company, 'newOffsets', item.id), item);
        openSnack('success', 'Offset updated successfully');
        return 'success';
    } catch (error) {
        console.error('Error updating alert:', error);
        return 'error';
    }
};





export const checkAlert = async (alert) => {
    const db = getFirestore();

    try {
        //check if alert exists in newAlerts if it does return true if not return false
        const alertRef = doc(db, 'clients', alert.company, 'newAlerts', alert.id);
        const alertDoc = await getDoc(alertRef);
        if (alertDoc.exists()) {
            return true
        } else {
            return false
        }
    }
    catch (error) {

        console.error('Error adding file:', error);
    }
};

export const deleteNewAlert = async (alert) => {
    const db = getFirestore();
    try {
        await deleteDoc(doc(db, 'clients', alert.company, 'newAlerts', alert.id));
        return 'success';
    } catch (error) {
        console.error('Error deleting alert:', error);
        return 'error';
    }
};

export const updateNewAlert = async (company, id, boo, val) => {
    const db = getFirestore();
    console.log("UPDATE NEW ALERT")
    console.log(boo, val)
    try {
        await updateDoc(doc(db, 'clients', company, 'newAlerts', id), {
            [boo]: val,
        });
        return 'success';
    } catch (error) {
        console.error('Error updating alert:', error);
        return 'error';
    }
};

export const updateNodeData = async (company, node, parent) => {
    const db = getFirestore();
    try {
        console.log(parent.key)
        console.log(company)
        const station = await getDoc(doc(db, 'clients', company, 'stations', parent.key));

        const stationData = station.data();
        //check if station exists
        if (station.exists()) {
            console.log('exists')
            //check if node exists within children
            //filter out node from children by node.id
            const children = stationData.children.filter((child) => child.id !== node.id);
            //add node to children
            children.push(node);
            //update station with new children
            await updateDoc(doc(db, 'clients', company, 'stations', parent.key), {
                children: children,
            });
            return 'success';
        } else {
            return 'error';
        }
    } catch (error) {
        console.error('Error fetching folder structure:', error);
        return 'error';
    }
}

export const getStaff = async (company, account) => {
    const db = getFirestore();

    try {
        const accountStaffRef = collection(db, 'clients', company, 'accounts', account, 'staff');
        const accountStaffSnapshot = await getDocs(accountStaffRef);
        const accountStaffIds = accountStaffSnapshot.docs.map((doc) => ({
            id: doc.id,

        }));

        // Split the accountStaffIds array into chunks of 10
        const chunkSize = 10;
        const accountStaffChunks = [];
        for (let i = 0; i < accountStaffIds.length; i += chunkSize) {
            const chunk = accountStaffIds.slice(i, i + chunkSize);
            accountStaffChunks.push(chunk);
        }

        // Fetch the corresponding users collection for each chunk of accountStaffIds
        const allUsers = [];
        for (const chunk of accountStaffChunks) {
            const userIds = chunk.map((staff) => staff.id);
            const usersQuery = query(collection(db, 'users'), where('id', "in", userIds));
            const usersSnapshot = await getDocs(usersQuery);
            const users = usersSnapshot.docs.map((doc) => {
                return {
                    id: doc.id,
                    email: doc.data().email,
                    firstName: doc.data().firstName,
                    lastName: doc.data().lastName,
                    cell: doc.data().cell || '',
                };
            });
            allUsers.push(users);
        }

        return allUsers.flat();
    } catch (error) {
        console.error('Error fetching staff:', error);
        return 'error';
    }
};