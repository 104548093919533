import { Button, ThemeProvider, createTheme } from '@mui/material';
import { Col, Modal, Row } from 'antd';
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from 'react';

import { deleteStaff, getStaff, getUsers, sendInviteEmail } from './firestore';
import DeleteStaff from './deleteStaff';
import AccountsUpdate from '../../accountsUpdate';
import ReinviteButton from './ReinviteButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import AddStaff from './addStaff';


const StaffTable = ({ setSelectedFolder, openSnack, userInfo }) => {
    const [data, setData] = useState([]);
    const [currentFolder, setCurrentFolder] = useState(null);
    const [inviting, setInviting] = useState(null)
    const [accountsModalOpen, setAccountsModalOpen] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [addStaffDialogOpen, setAddStaffDialogOpen] = useState(false);


    useEffect(() => {
        getInfo()





    }, [])

    const getInfo = async () => {
        const staff = await getStaff(userInfo.currentCompany, userInfo.currentAccount)
        const users = await getUsers(userInfo.currentCompany, userInfo.currentAccount, staff)

        console.log(users)
        //filter users with first name Greg, Eli, Maddy, Blesso
        const filteredUsers = users.filter(u => !['Greg', 'Eli', 'Maddy', 'Blesso'].includes(u.firstName))

        setData(filteredUsers);
        setLoading(false)



    };



    const deleteStaffMembers = async (staffKey) => {

        try {
            const staff = data.find(s => s.id === staffKey)
            const accounts = staff.accounts
            await deleteStaff(userInfo.currentCompany, staffKey, accounts)
            openSnack('success', 'Staff member deleted')
            getInfo()
        }
        catch (error) {
            console.log(error)
            openSnack('error', 'Error deleting staff member')
        }

    }

    const sendInvite = async (staffKey) => {

        try {
            setInviting(staffKey)
            await sendInviteEmail(staffKey, userInfo.id)
            setInviting(null)
            openSnack('success', 'Invite sent')
        }
        catch (error) {
            console.log(error)
            setInviting(null)
            openSnack('error', 'Error sending invite')
        }

    }




    const columns = [

        {
            name: "key",
            label: "",
            options: {
                filter: false,
                sort: true,
                display: 'excluded',

            },


        },
        {
            name: "fullName",
            label: "Name",
            options: {
                filter: false,
                sort: true,
            },

        },
        {
            name: "email",
            label: "Email",
            options: {
                filter: false,
                sort: true,
            },

        },
        {
            name: "id",
            label: "Sites",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{
                                textTransform: 'none',
                                fontSize: '12px',
                                backgroundColor: '#404050'
                            }}
                            onClick={() => {
                                setAccountsModalOpen(true)
                                const staff = data.find(s => s.id === value)

                                setCurrentUser(staff)
                            }}
                        >Sites</Button>


                    )
                }
            },

        },
        {
            name: "id",
            label: "Invited",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const signedUp = data.find(s => s.id === value)?.signedUp

                    if (signedUp === false) {
                        return (
                            <ReinviteButton
                                sendInvite={sendInvite}
                                staffKey={value}
                                inviting={inviting}

                            />
                        )
                    } else {
                        return null;
                    }


                }
            },

        },
        {
            name: "id",
            label: "Delete",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const staff = data.find(s => s.id === value)


                    return (
                        <DeleteStaff
                            staffKey={value}
                            userInfo={userInfo}
                            deleteStaff={deleteStaffMembers}
                        />
                    )
                }
            },

        },


    ];


    const getMuiTheme = () =>
        createTheme({
            components: {
                MUIDataTable: {
                    styleOverrides: {
                        root: {
                            padding: '8px',
                            boxShadow: 'none',
                            //minHeight = 400
                            minHeight: '400px',
                        }
                    }
                },
                MuiTableRow: {
                    styleOverrides: {
                        root: {
                            zIndex: 100,
                            cursor: 'pointer',
                            '&:nth-child(even)': {
                                backgroundColor: '#eeeeee52'
                            },
                            "&:hover": {
                                backgroundColor: "#9ec1c933"
                            }


                        },


                    }
                },
                MuiTableCell: {
                    styleOverrides: {
                        root: {
                            fontSize: '16px',
                            padding: '2px',
                            paddingTop: '8px',
                            paddingBottom: '8px',
                        }
                    }
                },


            }
        });



    const options = {

        download: false,
        print: false,
        filterType: null,
        rowsPerPage: 50,
        viewColumns: false,
        filter: false,
        rearangeableColumns: false,
        rowsPerPageOptions: [50, 100, 200],
        selectableRows: 'none',










    };












    return (

        <Row className="folder-structure">

            <Col span={24} style={{ textAlign: 'right', paddingRight: 30 }}>
                <AddStaff
                    userInfo={userInfo}
                    openSnack={openSnack}
                    getInfo={getInfo}

                />

            </Col>
            <Col span={24}>
                {loading === true ?
                    <div style={{ textAlign: 'center', marginTop: 100 }}>
                        <FontAwesomeIcon icon={faSpinner} spin size="3x" />
                    </div>
                    :


                    <ThemeProvider theme={getMuiTheme()}>
                        <MUIDataTable


                            data={data}
                            columns={columns}
                            options={options}
                        />
                    </ThemeProvider>
                }
            </Col>

            <Modal title="" visible={accountsModalOpen} onCancel={() => {
                setAccountsModalOpen(false)
                setCurrentUser(null)


            }} footer={null} width={'60%'} style={{ borderRadius: 40 }} >

                {currentUser !== null && (<AccountsUpdate currentUser={JSON.stringify(currentUser)} userInfo={userInfo} />)}
            </Modal >









        </Row>

    );
};

export default StaffTable;
