import axios, * as others from 'axios';
import TidalParameters from '../../../extra/tidalParameters';
import moment from 'moment-timezone';
import uuid from 'react-uuid';

export const getTideData = async (userInfo, stream) => {

    const proxyurl = "https://mycorslake.herokuapp.com/";

    try {



        const tz = 'LST';
        const datum = 'MLLW';
        const units = 'english';
        const format = 'json';

        const delay = (duration) => new Promise(resolve => setTimeout(resolve, duration));

        const getNestedData = (obj, path) => path?.split('.').reduce((acc, key) => acc?.[key], obj);

        const fetchData = async (product, endDate, stationID, datum, tz, units, format, dataKey, param) => {

            const url = `https://api.tidesandcurrents.noaa.gov/api/prod/datagetter?product=${product}&application=NOS.COOPS.TAC.WL&end_date=${endDate}&range=48&datum=MLLW&station=${stationID}&time_zone=LST&units=english&format=json`;
            const response = await axios.post(proxyurl + url);
            const responseData = response.data;

            if (param.product === "water_level") {
                console.log('get GPS')
                responseData.data.forEach((item) => {
                    item.lat = responseData.metadata.lat;
                    item.lon = responseData.metadata.lon;
                });
            }


            await delay(1000);  // Introduce a delay of 250 ms before resolving
            const nestedData = response?.data?.error ? [] : dataKey ? getNestedData(responseData, dataKey) : responseData.data;

            return nestedData;
        };


        const combineData = async (endDate, stationID) => {
            const allData = await Promise.all(TidalParameters.map(param => fetchData(param.product, endDate, stationID, datum, tz, units, format, param.dataKey, param)));

            const combinedData = {};

            allData.forEach((data, index) => {
                data.forEach(item => {
                    const time = item.t;


                    const product = TidalParameters[index].product;
                    const value = item[TidalParameters[index].valueKey];

                    if (!combinedData[time]) {
                        combinedData[time] = { time: time };
                    }

                    combinedData[time][product] = value;
                    combinedData[time]['stationID'] = stationID;
                    combinedData[time]['streamID'] = id;
                    combinedData[time]['company'] = stream.company;
                    combinedData[time]['account'] = stream.account;
                    combinedData[time]['key'] = uuid();

                    if (index === 2) {
                        combinedData[time].lat = item.lat;
                        combinedData[time].lon = item.lon;
                    }
                });
            });

            return Object.values(combinedData);
        };

        // Usage
        const endDate = moment().format('YYYYMMDD');
        const stationID = stream.stationID;
        const id = stream.id;
        const datalist = await combineData(endDate, stationID, id);
        console.log(datalist)
        function toSqlString(value) {
            if (value === undefined) {
                return 'NULL';
            }
            return `'${value}'`;
        }

        const updatedDataList = datalist.map((item) => {
            return {
                time: toSqlString(item.time),
                lat: toSqlString(item.lat || '0'),
                lon: toSqlString(item.lon || '0'),
                streamid: toSqlString(id),
                stationid: toSqlString(stationID),
                account: toSqlString(item.account),
                company: toSqlString(item.company),
                key: toSqlString(item.key),
                water_temperature: toSqlString(item.water_temperature),
                air_temperature: toSqlString(item.air_temperature),
                water_level: toSqlString(item.water_level),
                predictions: toSqlString(item.predictions),
                wind_speed: toSqlString(item.wind_speed),
                wind_direction: toSqlString(item.wind_direction),
                air_pressure: toSqlString(item.air_pressure),
                visibility: toSqlString(item.visibility),
                humidity: toSqlString(item.humidity),
                conductivity: toSqlString(item.conductivity),
                salinity: toSqlString(item.salinity)
            };
        });
        const hourlyVals = updatedDataList.map((h) => `(${Object.values(h)})`);
        const hourlyString = hourlyVals.join();

        console.log(`DATA LIST LENGTH: ${updatedDataList.length}`);
        console.log(updatedDataList)

        if (hourlyString.length === 0) {
            return;
        }

        return hourlyString;


    }
    catch (error) {
        console.log(error)

        return 'error'
    }
}

export const getLastRecorded = async (userInfo, prefKey, streams, setStreams, openSnack, setLoadingMeta) => {
    try {
        const proxyurl = "https://mycorslake.herokuapp.com/";
        const tz = 'LST';
        const datum = 'MLLW';
        const units = 'english';
        const format = 'json';
        const endDate = moment().format('YYYYMMDD');

        const newStreams = [];

        await Promise.all(streams.map(async (stream, index) => {

            if (stream.stationID) {
                console.log(stream)
                const url = `https://api.tidesandcurrents.noaa.gov/api/prod/datagetter?product=water_level&application=NOS.COOPS.TAC.WL&end_date=${endDate}&range=24&datum=MLLW&station=${stream.stationID}&time_zone=LST&units=english&format=json`;


                let response = await axios.post(proxyurl + url);
                let responseData = response.data;

                console.log(responseData)


                let lastRecorded = null;
                let lastDepth = null;


                if (responseData?.error) {
                    console.log("GET PREDICTIONS")
                    const url1 = `https://api.tidesandcurrents.noaa.gov/api/prod/datagetter?product=predictions&application=NOS.COOPS.TAC.WL&end_date=${endDate}&range=24&datum=MLLW&station=${stream.stationID}&time_zone=LST&units=english&format=json`;


                    response = await axios.post(proxyurl + url1);
                    responseData = response.data;



                    lastRecorded = responseData?.predictions[responseData?.predictions?.length - 1]?.t || 0;
                    lastDepth = responseData?.predictions[responseData?.predictions?.length - 1]?.v || 0;


                } else {
                    lastRecorded = responseData?.data[responseData?.data?.length - 1]?.t || 0;
                    lastDepth = responseData?.data[responseData?.data?.length - 1]?.v || 0;
                }

                const lat = responseData?.metadata?.lat || 0;
                const lon = responseData?.metadata?.lon || 0;
                console.log(lat)
                console.log(lon)
                stream.lastRecorded = lastRecorded ? moment(lastRecorded, 'YYYY-MM-DD HH:mm').format('x') : 'N/A';
                stream.latitude = lat;
                stream.longitude = lon;
                stream.elevation = lastDepth;

                newStreams.push(stream);
            }

        }));
        streams.sort((a, b) => a.name.localeCompare(b.name));
        setStreams([...streams]);
        setLoadingMeta(false);
        return streams;
    }
    catch (error) {
        console.log(error)
        setLoadingMeta(false);
    }
}

export const getTidePreviewData = async (userInfo, stream, endDate, datum, units, format, tz) => {
    try {
        const proxyurl = "https://mycorslake.herokuapp.com/";
        console.log("GETTING PREVIEW DATA")
        const tz = 'LST';
        const datum = 'MLLW';
        const units = 'english';
        const format = 'json';

        const delay = (duration) => new Promise(resolve => setTimeout(resolve, duration));

        const getNestedData = (obj, path) => path?.split('.').reduce((acc, key) => acc?.[key], obj);

        const fetchData = async (product, endDate, stationID, datum, tz, units, format, dataKey, param) => {
            console.log(product)
            try {

                const url = `https://api.tidesandcurrents.noaa.gov/api/prod/datagetter?product=${product}&application=NOS.COOPS.TAC.WL&end_date=${endDate}&range=48&datum=MLLW&station=${stationID}&time_zone=LST&units=english&format=json`;
                const response = await axios.post(proxyurl + url);
                const responseData = response.data;

                if (param.product === "water_level") {
                    console.log('get GPS')
                    responseData?.data?.forEach((item) => {
                        item.lat = responseData.metadata.lat;
                        item.lon = responseData.metadata.lon;
                    });
                }




                await delay(1000);  // Introduce a delay of 250 ms before resolving
                const nestedData = response?.data?.error ? [] : dataKey ? getNestedData(responseData, dataKey) : responseData.data;
                console.log(nestedData)
                return nestedData;
            }
            catch (error) {
                console.log(error)
            }
        };


        const combineData = async (endDate, stationID) => {
            const allData = await Promise.all(TidalParameters.map(param => fetchData(param.product, endDate, stationID, datum, tz, units, format, param.dataKey, param)));
            console.log(allData)
            const combinedData = {};

            allData.forEach((data, index) => {
                data.forEach(item => {
                    const time = item.t;


                    const product = TidalParameters[index].product;
                    const value = item[TidalParameters[index].valueKey];

                    if (!combinedData[time]) {
                        combinedData[time] = { time: time };
                    }

                    combinedData[time][product] = value;
                    combinedData[time]['stationID'] = stationID;
                    combinedData[time]['streamID'] = id;
                    combinedData[time]['company'] = userInfo.currentCompany;
                    combinedData[time]['account'] = userInfo.currentAccount;
                    combinedData[time]['key'] = uuid();

                    if (index === 2) {
                        combinedData[time].lat = item.lat;
                        combinedData[time].lon = item.lon;
                    }
                });
            });

            return Object.values(combinedData);
        };

        // Usage

        const stationID = stream.stationID;
        const id = stream.id;
        const datalist = await combineData(endDate, stationID, id);
        console.log(datalist)
        return datalist;


    }
    catch (error) {
        console.log(error)

        return 'error'
    }
}