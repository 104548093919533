import { Button, OutlinedInput, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import React from 'react';
import { useNavigate } from "react-router-dom";

export default class LogIn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            email: '',
            password: ''

        };
    }


    updateState = (item, value) => {
        this.setState({ [item]: value })
    }

    render() {
        const { email, password } = this.state;

        const auth = getAuth();



        return (

            <Row justify="space-around" >

                <Col xs={20} style={{ paddingBottom: 6 }} >
                    <Typography variant={'h3'} fontWeight={500} fontSize={32}>
                        Login
                    </Typography>
                </Col>
                <Col xs={20} style={{ paddingBottom: 24 }} >
                    <Typography variant="body1" fontWeight={300}>Software for water managers who want more.</Typography>
                </Col>
                <div>
                    <form
                        style={{ width: '100%' }}
                        onSubmit={(e) => {
                            console.log('submit')
                            
                            signInWithEmailAndPassword(auth, email, password)
                                .then((userCredential) => {
                                    this.props.authChange('signedIn')
                                })
                                .catch((error) => {
                                    const errorCode = error.code;
                                    const errorMessage = error.message;
                                    const slug = errorMessage.split('auth/').pop();

                                    this.props.openSnack('error', slug)
                                });

                        }}
                    >
                        <Col xs={20}  >
                            <Typography variant="h6" fontSize={14}>Email Address</Typography>
                        </Col>
                        <Col xs={20} style={{ paddingBottom: 12 }} >
                            <OutlinedInput fullWidth notched={false} label="none" placeholder='Email'
                                value={email}
                                onChange={(e) => {
                                    this.updateState('email', e.target.value)
                                }} />
                        </Col>
                        <Col xs={20}  >
                            <Typography fontSize={14} variant="h6">Password</Typography>
                        </Col>
                        <Col xs={20} style={{ paddingBottom: 24 }} >
                            <OutlinedInput fullWidth notched={false} label="none" placeholder='Password' type='password'
                                value={password}
                                onChange={(e) => {
                                    this.updateState('password', e.target.value)
                                }} />
                        </Col>
                        <Col xs={20} style={{ textAlign: 'right', paddingBottom: 6 }}   >
                            <Typography fontWeight={300} fontSize={12} variant="body1" style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    if (email.length === 0) {
                                        this.props.openSnack('error', 'Add an email address above.')
                                    }
                                    else {
                                        const auth = getAuth();
                                        sendPasswordResetEmail(auth, email)
                                            .then(() => {
                                                this.props.openSnack('success', 'Password reset email has been sent!')
                                            })
                                            .catch((error) => {
                                                const errorCode = error.code;
                                                const errorMessage = error.message;
                                                // ..
                                            });
                                    }
                                }} >Forgot password?</Typography>
                        </Col>
                    </form>
                </div>
                <Col xs={20}  >
                    <Button style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary"
                        onClick={() => {


                            signInWithEmailAndPassword(auth, email, password)
                                .then((userCredential) => {
                                    this.props.authChange('signedIn')
                                })
                                .catch((error) => {
                                    const errorCode = error.code;
                                    const errorMessage = error.message;
                                    const slug = errorMessage.split('auth/').pop();

                                    this.props.openSnack('error', slug)
                                });
                        }}>
                        Log In
                    </Button>
                </Col>


            </Row >
        );
    }
} 