import {
    Box,
    Button,
    Container,
    Grid,
    MenuItem,
    Select,
    Typography
} from "@mui/material";
import { Col, Collapse, Row } from "antd";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment-timezone';
import "./datePicker.css";



const DateSelection = ({
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    dateRange,
    setDateRange

}) => {


    const updateTimeframe = (value, unit) => {
        const now = new Date();
        let newStartDate = new Date();
        setDateRange(`${value}${unit}`)

        switch (unit) {
            case 'day':
                //UBTRACT DAYS
                newStartDate.setDate(now.getDate() - value);
                break;
            case 'week':
                newStartDate.setDate(now.getDate() - 7 * value);
                break;
            case 'month':
                newStartDate.setMonth(now.getMonth() - value);
                break;
            case 'year':
                newStartDate.setFullYear(now.getFullYear() - value);
                break;
            default:
                break;
        }

        setStartDate(newStartDate);
        setEndDate(now);
    };

    const buttonStyle = (value, unit) => ({
        textTransform: 'none',
        border: '1px solid #404050',
        color: dateRange === `${value}${unit}` ? 'white' : '#404050',
        backgroundColor: dateRange === `${value}${unit}` ? '#3d51b5' : 'transparent'
    });


    return (
        <Col span={24} style={{ padding: 10 }}>
            <Row>
                <Col span={24}>

                    <Row gutter={[12, 4]}>
                        <Col >
                            <Typography variant='body1' fontWeight={600} fontSize={13}>Start Date</Typography>
                            <DatePicker
                                timeCaption="Time"
                                showTimeSelect
                                timeFormat="h:mm a"
                                timeIntervals={1}
                                dateFormat="MM/dd/yyyy hh:mm a"
                                portalId="root-portal"
                                selected={startDate}
                                onChange={(date) => {
                                    setStartDate(date)
                                    setDateRange(null)
                                }}
                                customInput={<input style={{ fontWeight: 700 }} />}
                            />
                        </Col>
                        <Col >
                            <Typography variant='body1' fontWeight={600} fontSize={13}>End Date</Typography>
                            <DatePicker
                                timeCaption="Time"
                                showTimeSelect
                                timeFormat="h:mm a"
                                timeIntervals={1}
                                dateFormat="MM/dd/yyyy hh:mm a"
                                portalId="root-portal"
                                selected={endDate}
                                onChange={(date) => {
                                    setEndDate(date)
                                    setDateRange(null)
                                }}
                                customInput={<input style={{ fontWeight: 700 }} />}
                            />
                        </Col>

                    </Row>
                </Col>



                <Col span={24} style={{ marginTop: 5 }}>
                    <Row gutter={[4, 4]} align="middle" direction="column">
                        <Col >
                            <Button variant="outlined" color="primary" fullWidth
                                style={buttonStyle(1, 'day')}
                                onClick={() => updateTimeframe(1, 'day')}>Yesterday</Button>
                        </Col>
                        <Col >
                            <Button variant="outlined" color="primary" fullWidth
                                style={buttonStyle(1, 'week')}
                                onClick={() => updateTimeframe(1, 'week')}>Last Week</Button>
                        </Col>
                        <Col >
                            <Button variant="outlined" color="primary" fullWidth
                                style={buttonStyle(1, 'month')}
                                onClick={() => updateTimeframe(1, 'month')}>Last Month</Button>
                        </Col>

                        <Col>
                            <Button variant="outlined" color="primary" fullWidth
                                style={buttonStyle(6, 'month')}
                                onClick={() => updateTimeframe(6, 'month')}>6 Months</Button>
                        </Col>
                        <Col >
                            <Button variant="outlined" color="primary" fullWidth
                                style={buttonStyle(1, 'year')}
                                onClick={() => updateTimeframe(1, 'year')}>1 Year</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>

        </Col>
    );
};

export default DateSelection;
