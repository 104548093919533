import { collection, doc, getDoc, getDocs, getFirestore, onSnapshot, orderBy, query, setDoc, updateDoc, where } from "firebase/firestore";
import moment from 'moment-timezone';
import axios, * as others from 'axios';
import ProbeParameters from "../../../../../../extra/probeParams";
import uuid from "react-uuid";



export const getDatabasePreferences = async (userInfo, prefKey) => {
    try {
        const db = getFirestore();
        const docRef = doc(db, "users", userInfo.id, `${prefKey}Preferences`, userInfo.currentCompany);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            return docSnap.data();
        } else {
            // docSnap.data() will be undefined in this case
            return 'none'
        }
    } catch (error) {
        console.error("Error querying data: ", error);
    }
};

export async function updateDatabasePreferences(userInfo, preferences, prefKey) {
    try {
        const db = getFirestore();
        let docRef;


        docRef = doc(db, "users", userInfo.id, `${prefKey}Preferences`, userInfo.currentCompany);


        await setDoc(docRef, preferences);

    } catch (error) {
        console.error("Error updating document: ", error);
    }
};


export async function getDatabaseData(
    userInfo,
    selectedItems,
    items,
    startDate,
    endDate,
    source,
    setQuerying,
    parameters,
    stations,
    unitsArray,
    codes,
    openSnack
) {
    try {
        const selectedParameters = Object.values(selectedItems).filter(item => item.type === 'parameter');
        const selectedNodes = Object.values(selectedItems).filter(item => item.type === 'node');



        let parameterConditionStrs = [];





        const database = source === "node" ? "node_data_new2" : source === "wunderground" ? "wunderground_weather_new2" : "tide_station_data_new";


        let nodeConstraint = null;
        let combinedConstraint = null;
        let selectedColumns = null;


        if (source === "node") {
            if (selectedParameters.length > 0) {
                selectedParameters.forEach((p) => {
                    let conditionStr = "";
                    if (p.conditions && p.conditions.length > 0) {
                        p.conditions.forEach((cond) => {
                            conditionStr += ` AND value ${cond.type} ${cond.value || 0}`;
                        });
                    }
                    parameterConditionStrs.push(`(parameterid = '${p.key}'${conditionStr})`);
                });
            }

            let parameterConstraint = parameterConditionStrs.length > 0 ? `(${parameterConditionStrs.join(" OR ")})` : null;

            if (selectedNodes.length > 0) {
                const nodeKeys = selectedNodes.map((n) => `'${n.id}'`).join(", ");
                nodeConstraint = `nodeid IN (${nodeKeys})`;
            }

            if (selectedParameters.length === 0 && selectedNodes.length > 0) {
                combinedConstraint = nodeConstraint;
            } else if (selectedParameters.length > 0 && selectedNodes.length > 0) {
                combinedConstraint = `${parameterConstraint} AND ${nodeConstraint}`;
            } else {
                combinedConstraint = [parameterConstraint, nodeConstraint].filter(Boolean).join(" OR ");
            }
        }
        if (source === "wunderground") {
            if (selectedParameters.length > 0) {
                selectedParameters.forEach((p) => {
                    let conditionStr = "";
                    if (p.conditions && p.conditions.length > 0) {
                        p.conditions.forEach((cond) => {
                            conditionStr += ` AND value ${cond.type} ${cond.value || 0}`;
                        });
                    }
                    parameterConditionStrs.push(`(parameterid = '${p.key}'${conditionStr})`);
                });
            }
            console.log(selectedParameters)

            let parameterConstraint = parameterConditionStrs.length > 0 ? `(${parameterConditionStrs.join(" OR ")})` : null;
            const defaultColumns = ['key', 'time', 'streamid'];
            selectedColumns = [...defaultColumns, ...selectedParameters.map(p => p.key)].join(", ");
            if (selectedNodes.length > 0) {
                const nodeKeys = selectedNodes.map((n) => `'${n.id}'`).join(", ");
                nodeConstraint = `streamid IN (${nodeKeys})`;
            }

            combinedConstraint = [nodeConstraint].filter(Boolean).join(" OR ");
        }






        const query = `
          SELECT * FROM ${database}
          WHERE 
              (${combinedConstraint})
          AND 
              time BETWEEN '${moment(startDate).format("YYYY-MM-DD HH:mm")}' AND '${moment(endDate).format("YYYY-MM-DD HH:mm")}'
          ORDER BY time DESC
        `;






        const proxyUrl = "https://mycorslake.herokuapp.com/";
        const data = await axios
            .post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                raw: query,
            })
            .catch(error => {
                console.error("Axios error: ", error);
            });

        /*         const deleteQuery = `DELETE FROM ${database} WHERE parameterid = '${data.data[0].parameterid}'`
        
                await axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                    raw: deleteQuery,
                }); */

        openSnack('success', 'Data retrieved successfully')
        setQuerying(false);


        if (source === "node") {
            console.log(data.data)
            const modifiedData = data.data.map(item => ({
                ...item,
                selected: false,
                parameterid: [...parameters, ...ProbeParameters].find(p => p.key === item.parameterid)?.label || item.parameterid,
                nodeid: stations.find(s => s.id === item.nodeid)?.label || item.nodeid,
                unitid: unitsArray.find(u => u.key === item.unitid)?.label || item.unitid,
                tags: item.tags !== undefined ? JSON.parse(item.tags) : [],
                id: item.key
            }))

            console.log(modifiedData)
            return modifiedData;
        }
        if (source === "wunderground") {
            const modifiedData = data.data;

            console.log(modifiedData)
            return modifiedData;
        }






        // You can return the data if needed

    } catch (error) {
        console.error("Error updating document: ", error);
        openSnack('error', 'Error retrieving data')
        setQuerying(false);
        return [];
    }
};


export async function updateDatabaseData(row, database, parameters, stations, codes, unitsArray, openSnack, setUpdatingData) {
    try {
        console.log(row)


        const proxyUrl = "https://mycorslake.herokuapp.com/";
        if (row.key === undefined) {
            openSnack('error', 'Error updating data')
            return;
        }
        else {
            console.log(row.key)
            console.log(row.id)
            const query = `
        UPDATE ${database}
        SET 
            value = ${row.value},
            parameterid = '${parameters.find(p => p.label === row.parameterid)?.key || null}',
            nodeid = '${stations.find(s => s.label === row.nodeid)?.id || null}',
            unitid = '${unitsArray.find(u => u.label === row.unitid)?.key || ""}',
            tags = '${row.tags !== undefined ? JSON.stringify(row.tags) : '[]'}',
            offsetvalue = ${row.offsetvalue || 0}

        WHERE 
            key = '${row.key}'
        `;

            console.log(query);

            await axios
                .post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                    raw: query,
                })
                .catch(error => {
                    console.log("Axios error: ", error);
                });

            openSnack('success', 'Data updated successfully')
            setUpdatingData(false)
        }


    } catch (error) {
        console.error("Error updating document: ", error);
        setUpdatingData(false)
        openSnack('error', 'Error updating data')
    }
}


export async function updateMultipleDataRows(rows, database, parameters, stations, codes, unitsArray, openSnack, setUpdatingData, bulkData, ProbeParameters) {
    try {
        const keys = rows.map(row => `'${row.key || ''}'`).join(', ');
        const proxyUrl = "https://mycorslake.herokuapp.com/";


        console.log(rows);
        console.log(bulkData);
        console.log(database);
        console.log(keys);

        const deleteQuery = `DELETE FROM ${database} WHERE key IN (${keys})`;

        console.log(deleteQuery)
        await axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
            raw: deleteQuery,
        });



        const toSqlString = (value) => {
            if (value === undefined || value === null) {
                return 'null';
            }
            return `'${value}'`;
        };




        const newData = rows.map(l => {

            const parameter = [...parameters, ...ProbeParameters].find(p => p.label === (bulkData.parameterid || l.parameterid));
            const station = stations.find(s => s.label === (bulkData.nodeid || l.nodeid));
            const tags = bulkData.tags || l.tags;
            const offsetvalue = bulkData.offsetvalue || l.offsetvalue || 0;
            var time = moment(l.time, 'YYYY-MM-DD HH:mm').format("YYYY-MM-DD HH:mm");
            let timestamp = moment(time).format('X');

            return (
                {
                    time: `'${time}'`,
                    timestamp: Number(timestamp),
                    locationid: toSqlString(station?.id || null),
                    unitid: toSqlString(unitsArray.find(u => u.label === l.unitid)?.key || ""),
                    parameterid: toSqlString(parameter?.key || null),
                    value: Number(bulkData.value || l.value),
                    account: toSqlString(l.account),
                    company: toSqlString(l.company),
                    nodeid: toSqlString(station?.id || null),
                    key: toSqlString(uuid()),
                    latitude: Number(station.lat),
                    longitude: Number(station.lng),
                    logkey: toSqlString(l.logkey),
                    label: toSqlString(l.label),
                    tags: toSqlString(tags !== null ? JSON.stringify(tags) : '[]'),
                    offsetvalue: Number(offsetvalue),
                    archived: toSqlString('false')
                }
            )
        })




        const hourlyVals = newData.map((h) => `(${Object.values(h)})`);
        const hourlyString = hourlyVals.join();



        const insertQuery = `INSERT INTO node_data_new2 VALUES ${hourlyString} ON CONFLICT DO NOTHING;`;

        console.log(insertQuery)

        await updateData(insertQuery)

        openSnack('success', 'Data updated successfully');
        setUpdatingData(false);

    } catch (error) {
        console.log("Error updating document: ", error);
        setUpdatingData(false);
        openSnack('error', 'Error updating data');
    }
}


function updateData(query) {
    return new Promise((resolve, reject) => {
        const proxyUrl = "https://mycorslake.herokuapp.com/";
        axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', { raw: query }).then((t) => {
            console.log(t)
            resolve(t)
        }).catch(error => {
            console.log("Axios error: ", error);
            reject(error)
        });
    })
}







export async function updateTimeDatabaseRecored(row, database, openSnack, setUpdatingData) {
    try {
        const proxyUrl = "https://mycorslake.herokuapp.com/";
        if (row.key === undefined) {
            openSnack('error', 'Error updating data');
            return;
        } else {

            // Step 1: Retrieve existing record
            const selectQuery = `SELECT * FROM ${database} WHERE key = '${row.key}'`;
            const existingRecord = await axios
                .post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                    raw: selectQuery,
                });

            // Assuming existingRecord.data contains the retrieved row
            const oldRow = existingRecord.data[0];
            oldRow.id = oldRow.key;


            // Step 2: Delete old row
            const deleteQuery = `DELETE FROM ${database} WHERE key = '${row.key}'`;
            await axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                raw: deleteQuery,
            });


            oldRow.time = row.time;
            oldRow.value = row.value;
            oldRow.offsetvalue = row.offsetvalue || 0;

            const toSqlString = (value) => {
                if (value === undefined || value === null) {
                    return 'null';
                }
                return `'${value}'`;
            };

            console.log(oldRow.key)
            console.log(oldRow.id)
            console.log("UPDATED ROW")
            const newData = [oldRow].map(l => {



                return (
                    {
                        time: toSqlString(moment(l.time).tz("America/Los_Angeles").format('YYYY-MM-DD HH:mm:ss')),
                        timestamp: Number(l.timestamp),
                        locationid: toSqlString(l.locationid),
                        unitid: toSqlString(l.unitid),
                        parameterid: toSqlString(l.parameterid),
                        value: Number(l.value),
                        account: toSqlString(l.account),
                        company: toSqlString(l.company),
                        nodeid: toSqlString(l.nodeid),
                        key: toSqlString(l.key),
                        longitude: Number(l.longitude),
                        latitude: Number(l.latitude),
                        logkey: toSqlString(l.logkey),
                        label: toSqlString(l.label),
                        tags: toSqlString(row.tags !== null ? JSON.stringify(row.tags) : '[]'),
                        offsetvalue: Number(l.offsetvalue),
                        archived: toSqlString('false')
                    }
                )
            })


            console.log(newData)

            const hourlyVals = newData.map((h) => `(${Object.values(h)})`);
            const hourlyString = hourlyVals.join();


            const insertQuery = `INSERT INTO ${database} VALUES ${hourlyString} ON CONFLICT DO NOTHING;`;

            console.log(insertQuery)
            await axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', { raw: insertQuery }).catch(error => {
                console.log("Axios error: ", error);
            }
            );
            setUpdatingData(false)
            openSnack('success', 'Data updated successfully');
        }
    } catch (error) {
        console.error("Error updating document: ", error);
        openSnack('error', 'Error updating data');
        setUpdatingData(false)
    }
}

export async function deleteDatabaseData(keys, database, openSnack, setUpdatingData) {
    try {
        // Check if keys array is empty or null
        if (!keys || keys.length === 0) {
            openSnack('error', 'No keys provided for archiving');
            return;
        }

        const proxyUrl = "https://mycorslake.herokuapp.com/";

        // Create a string of keys to use in the SQL query
        const keyString = keys.map(key => `'${key}'`).join(", ");

        // SQL query to update the 'archived' field to 'true' for rows based on keys
        const query = `
        DELETE FROM  ${database}
            WHERE key IN (${keyString})
        `;

        // Execute the query
        await axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
            raw: query,
        });

        // Update the UI after archiving
        setUpdatingData(false);
        openSnack('success', 'Data archived successfully');
    } catch (error) {
        console.error("Error archiving data: ", error);
        setUpdatingData(false);
        openSnack('error', 'Error archiving data');
    }
}
export async function createDatabaseData(createdRow, database, parameters, stations, codes, unitsArray, openSnack, setUpdatingData, userInfo) {
    try {

        const row = { ...createdRow };
        const proxyUrl = "https://mycorslake.herokuapp.com/";
        row.account = userInfo.currentAccount;
        row.company = userInfo.currentCompany;
        const parameter = parameters.find(p => p.label === row.parameterid);
        const station = stations.find(s => s.label === row.nodeid);
        console.log(station)
        const unit = unitsArray.find(u => u.label === row.unitid);
        row.parameterid = parameter?.key || null;
        row.nodeid = station?.id || null;
        row.unitid = unit?.key || "";
        row.key = row.id;
        row.latitude = station?.lat || null;
        row.longitude = station?.lng || null;
        row.logkey = null;
        row.label = null;
        row.tags = row.tags !== undefined ? JSON.stringify(row.tags) : '[]';
        row.locationid = station?.id || null;

        console.log(row.key)
        console.log(row.id)

        const toSqlString = (value) => {
            if (value === undefined || value === null) {
                return 'null';
            }
            return `'${value}'`;
        };
        const newData = [row].map(l => {
            return (
                {
                    time: toSqlString(moment(l.time).tz("America/Los_Angeles").format('YYYY-MM-DD HH:mm:ss.SSS')),
                    timestamp: Number(moment(l.time).tz("America/Los_Angeles").format('X')),
                    locationid: toSqlString(l.locationid),
                    unitid: toSqlString(l.unitid),
                    parameterid: toSqlString(l.parameterid),
                    value: Number(l.value),
                    account: toSqlString(l.account),
                    company: toSqlString(l.company),
                    nodeid: toSqlString(l.nodeid),
                    key: toSqlString(l.key),
                    longitude: Number(l.longitude),
                    latitude: Number(l.latitude),
                    logkey: toSqlString(l.logkey),
                    label: toSqlString(l.label),
                    tags: toSqlString(l.tags),
                    offsetvalue: 0,
                    archived: toSqlString('false')
                }
            )
        })

        console.log(newData)

        const hourlyVals = newData.map((h) => `(${Object.values(h)})`);
        const hourlyString = hourlyVals.join();


        const insertQuery = `INSERT INTO ${database} VALUES ${hourlyString} ON CONFLICT DO NOTHING;`;

        console.log(insertQuery)
        await axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', { raw: insertQuery });
        console.log("Data created successfully")

        setUpdatingData(false);
        openSnack('success', 'Data created successfully');
        return 'success';
    } catch (error) {
        console.error("Error creating data: ", error);
        setUpdatingData(false);
        openSnack('error', 'Error creating data');
        return 'error';
    }
}
















