

export async function updateChartStyle({ attributes, chartOptions, setChartOptions }) {
    try {
        console.log(chartOptions)
        const newOptions = { ...chartOptions };

        const axisSize = attributes?.axisSize;
        const xAxisAngle = attributes?.xAxisAngle;
        const legendSize = attributes?.legendSize;

        newOptions.legend.itemStyle = { fontSize: `${legendSize}px` }



        newOptions.xAxis.labels = {
            style: { fontSize: `${axisSize}px` },
            autoRotation: [-10, -20, -30, -40, -50, -60, -70, -80, -90, -100, -110, -120, -130, -140, -150, -160, -170, -180],
            rotation: xAxisAngle,
            fontFamily: 'Roboto, sans-serif',
        }


        console.log(attributes)
        const yPlots = attributes?.references.filter((r) => r.horizontal === true);
        const xPlots = attributes?.references.filter((r) => r.horizontal === false);




        attributes.axises.map((axis, i) => {
            console.log(axis)


            newOptions.yAxis[i] = {
                id: axis.axisId,
                labels: {
                    style: {
                        fontWeight: '600',
                        fontSize: axis.fontSize !== undefined ? `${axis.fontSize}px` : '12px',
                        color: axis.axisColor || 'black', fontFamily: 'inter, sans-serif'

                    },
                    fontFamily: 'Roboto, sans-serif',
                },
                title: {
                    text: axis.label,
                    style: {
                        fontWeight: '600',
                        color: axis.axisColor || 'black', fontFamily: 'inter, sans-serif'
                    }
                },
                opposite: axis.opposite,
                min: axis.customRange === true ? axis.min : axis.autoRange === true ? null : 0,
                max: axis.customRange === true ? axis.max : axis.autoRange === true ? null : null,
                startOnTick: attributes?.allignTicks === true ? true : [axis.customRange, axis.customTicks].includes(true) ? true : false,
                endOnTick: attributes?.allignTicks === true ? true : [axis.customRange, axis.customTicks].includes(true) ? true : false,
                allowDecimals: true,
                plotLines: yPlots.filter((f) => f.yAxis === axis.axisId).map((f) => {
                    return ({
                        ...f,
                        value: f.yValue, zIndex: 50,
                        width: f.active === undefined ? f.width : f.active === false ? 0 : f.width,
                        label: {
                            text: f.useAlias ? f.alias : f.title,
                            textAlign: f.textAlign,
                            verticalAlign: f.verticalAlign,
                            style: {
                                color: f.fontColor || f.color || 'black',
                                fontWeight: f.fontWeight || '400',
                                fontSize: f.fontSize ? `${f.fontSize}px` : '12px',
                            }
                        }
                    })
                })

            }
        })



        const updatedX = xPlots.map((f) => {

            return ({
                ...f, value: Number(f.date), zIndex: 50, width: f.active === undefined ? f.width : f.active === false ? 0 : f.width,
                label: {
                    text: f.useAlias ? f.alias : f.title, textAlign: f.textAlign, verticalAlign: f.verticalAlign,
                    style: {
                        color: f.fontColor || f.color || 'black',
                        fontWeight: f.fontWeight || '400',
                        fontSize: f.fontSize ? `${f.fontSize}px` : '12px',
                    }
                }
            })
        })



        newOptions.xAxis.plotLines = updatedX;
        newOptions.xAxis.maxPadding = attributes.xPadding === undefined ? 0 : attributes.xPadding === true ? 0.25 : 0;
        newOptions.xAxis.minPadding = attributes.xPadding === undefined ? 0 : attributes.xPadding === true ? 0.25 : 0;
        newOptions.xAxis.labels.style.fontSize = attributes.xAxisFontSize || '12px';
        newOptions.xAxis.labels.style.color = attributes.xAxisLabelColor || 'black';
        newOptions.xAxis.labels.style.fontFamily = 'inter, sans-serif';
        newOptions.xAxis.lineColor = attributes.xAxisColor || 'black';
        newOptions.xAxis.tickColor = attributes.xAxisColor || 'black';
        newOptions.xAxis.gridLineColor = attributes.xAxisGridColor || 'black';
        console.log(newOptions)


        setChartOptions(newOptions);





    } catch (error) {
        console.log(error)
        console.error(error);
    }
}