import React, { useEffect, useState } from "react";


import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider, Typography } from '@mui/material';
import { Col, Row } from 'antd';

import { fetchCurrentWeather, fetchLocationData, fetchYesterdaySummary, fetchYesterdayWeather } from "./fetchFunctions";
import { fetchWunder } from "./weatherUndergroundFunction";
import { formatCurrentWeather, formatSummaryWeather } from "./openWeatherMapFunctions";
import axios, * as others from 'axios';


const WeatherFunctionsHome = ({
    screenWidth,
    userInfo,
    account,
    openSnack
}) => {


    const proxyUrl = "https://mycorslake.herokuapp.com/";
    const apiKey = 'e60406d8d1152d5b1e8413bb058aa602';
    const [cityId, setCityId] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0)
        getInfo()
         fetchWeather(account)

    }, [])



    async function getInfo() {
        const res = await axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/fetchOpenWeatherRequest')
        console.log(res.data)
       



    }

    const props = {
        screenWidth,
        userInfo,
        account,
        openSnack,
    }



    async function fetchWeather(ac) {
        const res1 = await axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/fetchOpenWeatherSummaryRequest')
        console.log(res1.data)
        const lat = ac?.lat;
        const lon = ac?.lon || ac?.lng;


        try {
            /* open weather map functions */
            const currentWeather = await fetchCurrentWeather({ lat, lon, proxyUrl, apiKey });
            const formattedCurrentWeather = await formatCurrentWeather(currentWeather, userInfo);
            console.log(formattedCurrentWeather)
            const yesterdaySummary = await fetchYesterdaySummary({ lat, lon, proxyUrl, apiKey });
            const formattedSummaryWeather = await formatSummaryWeather(yesterdaySummary, userInfo);
            console.log(formattedSummaryWeather)

            /* weather underground functions */
            const weatherUndergroundStation = 'KCAOAKLA575'

            /*  const wunderData = await fetchWunder({ stream: { stationName: weatherUndergroundStation } });
  */


        } catch (error) {
            console.error('Error fetching current weather:', error);
        }
    }










    return (
        <Row style={{}} >
            <Col span={24} style={{}}>
                <Row align="middle">
                    <Col xs={16} sm={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>
                        <Typography variant="h4" fontWeight={600} fontSize={30} color="black">Weather Functions</Typography>
                    </Col>

                    <Col span={24}>
                        <Divider />
                    </Col>










                </Row>
            </Col>

        </Row > 
    );


};

export default WeatherFunctionsHome;
