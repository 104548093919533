import React from "react";
import axios, * as others from 'axios';
import moment from 'moment-timezone';

export const fetchLocationData = async ({ lat, lon, proxyUrl, apiKey, setCityId }) => {

    const url = `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&exclude={part}&appid=${apiKey}&units=imperial`;

    try {
        const response = await axios.get(proxyUrl + url);
        console.log(response.data);
        setCityId(response.id);
        return
    } catch (error) {
        console.error('Error fetching current weather:', error);
    }
}

export const fetchCurrentWeather = async ({ lat, lon, proxyUrl, apiKey }) => {

    const url = `https://api.openweathermap.org/data/3.0/onecall?lat=${lat}&lon=${lon}&exclude={part}&appid=${apiKey}&units=imperial`;

    try {
        const response = await axios.get(proxyUrl + url);
        return response.data;
    } catch (error) {
        console.error('Error fetching current weather:', error);
    }
}

export const fetchYesterdayWeather = async ({ lat, lon, proxyUrl, apiKey }) => {
    const time = moment().subtract(1, 'days').format('X');
    const url = `https://api.openweathermap.org/data/3.0/onecall/timemachine?lat=${lat}&lon=${lon}&dt=${time}&appid=${apiKey}&units=imperial`;

    try {
        const response = await axios.get(proxyUrl + url);
        console.log(response.data);
    } catch (error) {
        console.error('Error fetching current weather:', error);
    }
}
export const fetchYesterdaySummary = async ({ lat, lon, proxyUrl, apiKey }) => {

    const url = `https://api.openweathermap.org/data/3.0/onecall/day_summary?lat=${lat}&lon=${lon}&date=${"2024-03-04"}&appid=${apiKey}&units=imperial`;

    try {
        const response = await axios.get(proxyUrl + url);
        return response.data;
    } catch (error) {
        console.error('Error fetching current weather:', error);
    }
}