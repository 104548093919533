import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextField, Autocomplete } from '@mui/material';
import { collection, getDocs, getFirestore, doc, deleteDoc } from 'firebase/firestore';

const PickTemplateModal = ({
    pickModalOpen,
    setPickModalOpen,
    handleUpdateTemplate,
    userInfo,
    openSnack,
    newTemplates,
    setNewTemplates
}) => {

    const [templates, setTemplates] = useState([]);
    const [loading, setLoading] = useState(false);
    const [template, setTemplate] = useState('');

    useEffect(() => {
        console.log(userInfo)
        getTemplates();
    }, [])
    useEffect(() => {
        if (newTemplates) {
            getTemplates();
            setNewTemplates(false);
        }
    }, [newTemplates])

    async function getTemplates() {
        const db = getFirestore();
        const templatesRef = collection(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'dropdownTemplates');
        const templatesSnapshot = await getDocs(templatesRef);
        const templatesList = templatesSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        console.log(templatesList)
        setTemplates(templatesList);
        setLoading(false);
    }

    const handleDeleteTemplate = async (templateId) => {
        const db = getFirestore();
        const templateRef = doc(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'dropdownTemplates', templateId);
        await deleteDoc(templateRef).then(() => {
            openSnack('success', 'Template Deleted')
        })
        // Update local state
        setTemplates(templates.filter(template => template.id !== templateId));
    }

    return (
        <Dialog open={pickModalOpen} onClose={() => setPickModalOpen(false)}>
            <DialogTitle>Template Name</DialogTitle>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    handleUpdateTemplate(template);
                }}
            >
                <DialogContent>
                    <DialogContentText>
                        Please pick template:
                    </DialogContentText>
                    {!loading ?
                        <Autocomplete
                            id="combo-box-demo"
                            options={templates}
                            getOptionLabel={(option) => option.name}
                            style={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} placeholder="Templates" variant="outlined" />}
                            onChange={(e, newValue) => {
                                setTemplate(newValue);
                            }}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    {option.name}
                                    <Button
                                        style={{
                                            marginLeft: '10px',
                                            textTransform: 'none'
                                        }}
                                        variant='outlined'
                                        size="small"
                                        margin="dense"
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            handleDeleteTemplate(option.id);
                                        }}
                                    >
                                        Delete Template
                                    </Button>
                                </li>
                            )}
                        />
                        : <p>Loading...</p>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setPickModalOpen(false)}>Cancel</Button>
                    <Button type="submit">Choose</Button>
                </DialogActions>
            </form>
        </Dialog >
    );
};

export default PickTemplateModal;
