import React, { useState } from "react";
import {
    Box, Typography, OutlinedInput,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton
} from '@mui/material';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./datePicker.css";
import moment from 'moment-timezone';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faX } from "@fortawesome/free-solid-svg-icons";

const WorkingDays = ({ record, updateRecord, days, setDays, openSnack, setDisableDrag }) => {
    // Assume this is the data for the working days - you might load this from props, state, or an API



    const handleHoursChange = (index, value) => {
        const newDays = [...days];
        // Make sure hours are always stored as numbers
        newDays[index].hours = value === '' ? 0 : Number(value); // or use parseFloat for decimal values
        setDays(newDays);

        // Calculate total hours and ensure it's a number
        const totalHours = newDays.reduce((total, day) => total + Number(day.hours), 0);
        updateRecord({ ...record, days: newDays, totalHours });
    };

    return (
        <>
            <TableContainer>
                <Table sx={{ width: '100%' }} aria-label="simple table" size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography variant="h6" component="div" color={'rgba(0,0,0,0.8)'} fontWeight={700} fontSize={13}>
                                    Date
                                </Typography>
                            </TableCell>
                            <TableCell >
                                <Typography variant="h6" component="div" color={'rgba(0,0,0,0.8)'} fontWeight={700} fontSize={13}>
                                    Hours
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography variant="h6" component="div" color={'rgba(0,0,0,0.8)'} fontWeight={700} fontSize={12}>
                                    Total Hours: {record?.totalHours?.toFixed(0)}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {days.map((day, index) => (
                            <TableRow
                                key={day.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">

                                    <DatePicker
                                        timeCaption="Time"
                                        showTimeSelect
                                        timeFormat="h:mm a"
                                        timeIntervals={1}
                                        dateFormat="MM/dd/yyyy hh:mm a"
                                        portalId="root-portal"
                                        selected={day?.date ? new Date(moment(day?.date, 'x')) : new Date()}
                                        onChange={(date) => {
                                            const newDays = [...days];
                                            /* find index by id */
                                            const i = newDays.findIndex((d) => d.id === day.id);
                                            newDays[i].date = moment(date).format('x');
                                            setDays(newDays)
                                            updateRecord({ ...record, days: newDays });

                                        }}
                                        customInput={<input style={{ fontWeight: 500, fontSize: 13 }} />}
                                    />
                                </TableCell>


                                <TableCell >
                                    <OutlinedInput
                                        type="number"
                                        margin="dense"
                                        size="small"
                                        /* make min 0 */
                                        inputProps={{ min: 0 }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            setDisableDrag(true);

                                        }}
                                        onBlur={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            setDisableDrag(false);
                                        }}
                                        value={day.hours}
                                        onChange={(e) => handleHoursChange(index, e.target.value)}
                                        style={{ width: '6rem', backgroundColor: 'white', }}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <IconButton
                                        onClick={() => {
                                            if (days.length === 1) {
                                                openSnack('error', 'You must have at least one working day')
                                                return;
                                            }
                                            const newDays = [...days];
                                            newDays.splice(index, 1);
                                            setDays(newDays)
                                            updateRecord({ ...record, days: newDays });
                                        }}
                                    >
                                        <FontAwesomeIcon size="xs" icon={faTrash} />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default WorkingDays;
