import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { useEffect } from 'react';
import { queryParameters } from '../../../../firebase/config';
import { getFirestore } from 'firebase/firestore';
import ProbeParameters from '../../../../extra/probeParameters.json';
import WeatherParameters from '../../../../extra/weatherParameters.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Typography, ListItem } from '@mui/material';
import { styled, } from '@mui/system';
import weatherUndergroundParameters from '../../../../extra/weatherUndergroundParameters';
import TidalParameters from '../../../../extra/tidalParameters';
import { obscapeParameters } from '../../../../extra/obscapeParameters';

const GroupItems = styled('ul')({
    padding: 0,
});

function sleep(delay = 0) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
}

export default function ParametersLoad({ userInfo, dataType, nodeid, parameterid, updateData }) {
    const [open, setOpen] = useState(false);
    const [PPArray, setPPArray] = useState([]);
    const [WPArray, setWPArray] = useState([]);
    const [options, setOptions] = useState([]);
    const [loadingSelect, setLoadingSelect] = useState(true);
    const [parameters, setParameters] = useState([])
    const loading = open && options.length === 0;


    useEffect(() => {
        getInfo()
    }, [])
    useEffect(() => {

        getInfo(dataType)
    }, [dataType])


    async function getInfo(dataTypeNew) {


        const parameters = await queryParameters(userInfo)
        parameters.sort((a, b) => a.name.localeCompare(b.name))
        setParameters(parameters)

        const PPArray = Object.values(ProbeParameters).map((p, i) => {
            return ({
                label: p,
                key: Object.keys(ProbeParameters)[i]
            })
        });

        setPPArray(PPArray)


        const WPArray = Object.values(WeatherParameters).map((p, i) => {
            return ({
                title: p,
                label: p,
                key: Object.keys(WeatherParameters)[i]
            })
        });
        setWPArray(WPArray)


        if (parameterid !== undefined && nodeid !== '' && nodeid !== undefined) {
            if (['node'].includes(dataTypeNew)) {
                const proxyUrl = "https://mycorslake.herokuapp.com/";
                axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgetsFire2/sqlRead', {
                    raw: `
                    SELECT DISTINCT parameterid FROM node_data_new2
                    WHERE nodeid = '${nodeid}';
                    `
                })
                    .then(async (response) => {
                        const data = response.data;
                        const filterData = data.filter((d) => d.parameterid !== 'undefined')
                        const options = filterData.map((d, i) => {

                            const label = [...PPArray, ...parameters].filter((p) => p.key === d.parameterid)[0]?.label || 'undefined'

                            return { title: label, key: d.parameterid }
                        })
                        //sort options by title
                        options.sort((a, b) => a.title.localeCompare(b.title))
                        await sleep(1e3); // For demo purposes.
                        setOptions(options);
                        setLoadingSelect(false)

                    }).catch((e) => {
                        setLoadingSelect(false)
                    })
            }
            if (['probe'].includes(dataTypeNew)) {
                const proxyUrl = "https://mycorslake.herokuapp.com/";
                axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgetsFire2/sqlRead', {
                    raw: `
                    SELECT DISTINCT parameterid FROM probe_data
                    WHERE locationid = '${nodeid}';
                    `
                })
                    .then(async (response) => {
                        const data = response.data;
                        const filterData = data.filter((d) => !['undefined', 'null', null].includes(d.parameterid))

                        const options = filterData.map((d, i) => {

                            const label = [...PPArray, ...parameters].filter((p) => p.key === d.parameterid)[0]?.label || 'undefined'

                            return { title: label, key: d.parameterid }
                        })



                        //sort options by title
                        const filterOptions = options.filter((f) => f.title !== 'undefined')
                        filterOptions.sort((a, b) => a.title.localeCompare(b.title))
                        await sleep(1e3); // For demo purposes.
                        setOptions(options);
                        setLoadingSelect(false)

                    }).catch((e) => {
                        setLoadingSelect(false)
                    })
            }
            if (['weather'].includes(dataTypeNew)) {
                const label = WPArray.filter((p) => p.key === parameterid)[0].label
                setOptions([{ title: label, key: parameterid }])
                setLoadingSelect(false)
            }
            if (['gauge'].includes(dataTypeNew)) {
                const label = WPArray.filter((p) => p.key === parameterid)[0].label
                setOptions([{ title: label, key: parameterid }])
                setLoadingSelect(false)
            }
            if (['wunder'].includes(dataTypeNew)) {
                //filter out key
                const parameters = weatherUndergroundParameters.filter((p) => !['account', 'company', 'location', 'time', 'key'].includes(p.key)).sort((a, b) => a.label.localeCompare(b.label))
                setOptions(parameters)
                setLoadingSelect(false)
            }
            if (['obscapeWeatherStation'].includes(dataTypeNew)) {
                //filter out key
                const parameters = obscapeParameters.filter((p) => !['account', 'company', 'location', 'time', 'key'].includes(p.key)).sort((a, b) => a.label.localeCompare(b.label))
                setOptions(parameters)
                setLoadingSelect(false)
            }
            if (['station'].includes(dataTypeNew)) {
                //filter out key
                const parameters = TidalParameters.map((p) => { return ({ ...p, title: p.label, key: p.product }) });
                parameters.sort((a, b) => a.title.localeCompare(b.title))
                setOptions(parameters)
                setLoadingSelect(false)
            }
        }
        else {
            setLoadingSelect(false)
        }

    }




    if (loadingSelect) {
        return (

            <FontAwesomeIcon icon={faSpinner} size="xs" spin />


        )
    }

    else {

        return (
            <Autocomplete
                display="inline"
                id="asynchronous-demo"
                size="small"
                disablePortal
                open={open}
                onOpen={async () => {
                    setOpen(true);


                    if (['node'].includes(dataType)) {
                        const proxyUrl = "https://mycorslake.herokuapp.com/";
                        axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgetsFire2/sqlRead', {
                            raw: `
                    SELECT DISTINCT parameterid FROM node_data_new2
                    WHERE nodeid = '${nodeid}';
                    `
                        })
                            .then(async (response) => {
                                const data = response.data;

                                const filterData = data.filter((d) => !['undefined', 'null', null].includes(d.parameterid))

                                const options = filterData.map((d, i) => {

                                    const label = [...PPArray, ...parameters].filter((p) => p.key === d.parameterid)[0]?.label || 'undefined'

                                    return { title: label, key: d.parameterid }
                                })



                                //sort options by title
                                const filterOptions = options.filter((f) => f.title !== 'undefined')
                                filterOptions.sort((a, b) => a.title.localeCompare(b.title))
                                await sleep(1e3); // For demo purposes.

                                setOptions(options);

                            })
                    }
                    if (['probe',].includes(dataType)) {
                        const proxyUrl = "https://mycorslake.herokuapp.com/";
                        axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgetsFire2/sqlRead', {
                            raw: `
                    SELECT DISTINCT parameterid FROM probe_data
                    WHERE locationid = '${nodeid}';
                    `
                        })
                            .then(async (response) => {
                                const data = response.data;
                                const filterData = data.filter((d) => d.parameterid !== 'undefined')
                                const options = filterData.map((d, i) => {

                                    const label = [...PPArray, ...parameters].filter((p) => p.key === d.parameterid)[0]?.label || 'undefined'

                                    return { title: label, key: d.parameterid }
                                })
                                //sort options by title
                                options.sort((a, b) => a.title.localeCompare(b.title))
                                await sleep(1e3); // For demo purposes.
                                setOptions(options);

                            })
                    }

                    if (['weather'].includes(dataType)) {
                        await sleep(1e3); // For demo purposes.
                        setOptions(WPArray);
                    }
                    if (['gauge'].includes(dataType)) {
                        await sleep(1e3); // For demo purposes.
                        setOptions(WPArray);
                    }
                    if (['wunder'].includes(dataType)) {
                        await sleep(1e3); // For demo purposes.
                        const parameters = weatherUndergroundParameters.filter((p) => !['account', 'company', 'location', 'time', 'key'].includes(p.key))

                        //filter keys of account, company, location, time
                        setOptions(parameters)
                    }
                    if (['station'].includes(dataType)) {
                        //filter out key
                        const parameters = TidalParameters.map((p) => { return ({ ...p, title: p.label, key: p.product }) });
                        parameters.sort((a, b) => a.title.localeCompare(b.title))
                        setOptions(parameters)
                        setLoadingSelect(false)
                    }
                    if (['obscapeWeatherStation'].includes(dataType)) {
                        //filter out key
                        const parameters = obscapeParameters.filter((p) => !['account', 'company', 'location', 'time', 'key'].includes(p.key)).sort((a, b) => a.label.localeCompare(b.label))
                        setOptions(parameters)
                        setLoadingSelect(false)
                    }



                }}
                onClose={() => {
                    setOpen(false);
                }}
                value={options.find((o) => o.key === parameterid)?.title ? options.find((o) => o.key === parameterid) : null}
                isOptionEqualToValue={(option, value) => option.title === value.title}
                options={options}
                onChange={(a, b) => {

                    updateData('parameterid', b?.key || null);
                    updateData('parameter', b);
                }}
                sx={{

                    '& + .MuiAutocomplete-popper .MuiAutocomplete-option:hover': {
                        // 👇 Customize the hover bg color here
                        backgroundColor: "#E3EEFA",
                    },
                    '& + .MuiAutocomplete-popper .MuiAutocomplete-option': {
                        // 👇 Customize the hover bg color here
                        fontWeight: 400,
                        fontSize: 18,
                        color: '#000000',
                        borderBottom: '2px solid #EFEFEF',
                    },
                    //selected option color
                    "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']": {
                        backgroundColor: "#E3EEFA",
                    }
                }}



                getOptionLabel={(option) => option.title}
                loading={loading}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder="Parameters"
                        style={{
                            backgroundColor: '#ffffff',
                        }}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            />
        );

    }
}
