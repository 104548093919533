import { Button, CircularProgress, Divider, Menu, MenuItem, OutlinedInput, Select, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import { collection, doc, getDoc, getDocs, getFirestore, query, setDoc, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { fullMobile } from './util';

import SiteMap from './siteMap';
import axios, * as others from 'axios';
import queryString from 'query-string';

export default function SiteCharacteristics({ userInfo, screenWidth, openSnack, company, account }) {
    const [loading, setLoading] = useState(true);
    const [locations, setLocations] = useState([]);
    const [siteCharacteristics, setSiteCharacteristics] = useState({
        averageDepth: '',
        maxDepth: '',
        averageDepthUnits: '',
        maxDepthUnits: '',
        areaUnits: '',
        area: '',
        volumeUnits: '',
        volume: '',
        map: '[]',
        showProbes: false,
        showDrawings: false,
        editDrawings: false,
    });
    const db = getFirestore();

    //volume and area should auto populate
    //volume will be acft/cubic feet/gal

    useEffect(() => {
        console.log(company)
        getLocations()
        getSiteCharacteristics()
        window.scrollTo(0, 0)

    }, []);


    async function getLocations() {



        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'probes'), where('account', '==', userInfo.currentAccount), where('archived', '==', false));

        const locations = [];


        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            locations.push({ ...doc.data(), label: doc.data().name, type: 'probe' })
        });
        locations.sort((a, b) => a.name.localeCompare(b.name))
        console.log(locations)
        setLocations(locations)


    }


    async function getSiteCharacteristics() {
        //get site characteristics
        const db = getFirestore();

        const docRef = doc(db, "clients", userInfo.currentCompany, "accounts", userInfo.currentAccount, "siteCharacteristics", "siteCharacteristics");
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            console.log(docSnap.data())
            setSiteCharacteristics(docSnap.data())
            setLoading(false)
        }
        else {
            setLoading(false)
        }

    }

    function saveCharacteristics() {
        console.log(siteCharacteristics)
        setDoc(doc(db, "clients", userInfo.currentCompany, "accounts", userInfo.currentAccount, "siteCharacteristics", "siteCharacteristics"), {
            ...siteCharacteristics
        }).then(() => {
          
            setLoading(false);
        }).catch((error) => {
          
            setLoading(false);
        })
    }

    function saveCharacteristicsNew(item) {
        console.log(siteCharacteristics)
        setDoc(doc(db, "clients", userInfo.currentCompany, "accounts", userInfo.currentAccount, "siteCharacteristics", "siteCharacteristics"), item).then(() => {
          
            setLoading(false);
        }).catch((error) => {
          
            setLoading(false);
        })
    }



    return (
        <Row style={{ fontFamily: 'Roboto, sans-serif', padding: 20 }} align="middle" gutter={[12, 12]}>
            
         

            <Col {...fullMobile(6, 24)} style={{}}>
                <Row align="middle" gutter={[4, 4]}>
                    <Col {...fullMobile(24, 24)} style={{}}>
                        <Typography variant="body1" fontWeight={500} component="h4"  >
                            Average Depth
                        </Typography>

                    </Col>
                    <Col {...fullMobile(24, 24)} style={{}}>
                        <OutlinedInput
                            notched={false}
                            size="small"
                            placeholder="Average Depth"
                            label="none"
                            onChange={(e) => {
                                setSiteCharacteristics({
                                    ...siteCharacteristics,
                                    averageDepth: e.target.value
                                })
                            }
                            }
                            endAdornment={(
                                <Select
                                    display="inline"
                                    variant='standard'
                                    size="small"
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    label="none"
                                    notched={false}
                                    onChange={(e) => {
                                        setSiteCharacteristics({
                                            ...siteCharacteristics,
                                            averageDepthUnits: e.target.value
                                        })
                                    }
                                    }
                                    value={siteCharacteristics.averageDepthUnits || ''}
                                >
                                    <MenuItem value={'feet'}>Feet</MenuItem>
                                    <MenuItem value={'inches'}>Inches</MenuItem>
                                    <MenuItem value={'meters'}>Meters</MenuItem>
                                </Select>
                            )}

                            value={siteCharacteristics.averageDepth === undefined ? '' : siteCharacteristics.averageDepth}
                        />
                    </Col>

                </Row>

            </Col>
            <Col {...fullMobile(6, 24)} style={{}}>
                <Row align="middle" gutter={[4, 4]}>
                    <Col {...fullMobile(24, 24)} style={{}}>
                        <Typography variant="body1" fontWeight={500} component="h4"  >
                            Max Depth
                        </Typography>

                    </Col>
                    <Col {...fullMobile(24, 24)} style={{}}>
                        <OutlinedInput
                        onBlur={saveCharacteristics }
                            notched={false}
                            size="small"
                            placeholder="Max Depth"
                            label="none"
                            onChange={(e) => {
                                setSiteCharacteristics({
                                    ...siteCharacteristics,
                                    maxDepth: e.target.value
                                })
                            }
                            }
                            endAdornment={(
                                <Select
                                    display="inline"
                                    variant='standard'
                                    size="small"
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    label="none"
                                    notched={false}
                                    onChange={(e) => {
                                        setSiteCharacteristics({
                                            ...siteCharacteristics,
                                            maxDepthUnits: e.target.value
                                        })
                                    }
                                    }
                                    value={siteCharacteristics.maxDepthUnits || ''}
                                >
                                    <MenuItem value={'feet'}>Feet</MenuItem>
                                    <MenuItem value={'inches'}>Inches</MenuItem>
                                    <MenuItem value={'meters'}>Meters</MenuItem>
                                </Select>
                            )}

                            value={siteCharacteristics.maxDepth || ''}
                        />
                    </Col>

                </Row>

            </Col>
            <Col {...fullMobile(6, 24)} style={{}}>
                <Row align="middle" gutter={[4, 4]}>
                    <Col {...fullMobile(24, 24)} style={{}}>
                        <Typography variant="body1" fontWeight={500} component="h4"  >
                            Area
                        </Typography>

                    </Col>
                    <Col {...fullMobile(24, 24)} style={{}}>
                        <OutlinedInput
                        onBlur={saveCharacteristics }
                            notched={false}
                            size="small"
                            placeholder="Area"
                            label="none"
                            onChange={(e) => {
                                setSiteCharacteristics({
                                    ...siteCharacteristics,
                                    area: e.target.value
                                })
                            }
                            }
                            endAdornment={(
                                <Select
                                    display="inline"
                                    variant='standard'
                                    size="small"
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    label="none"
                                    notched={false}
                                    onChange={(e) => {
                                        setSiteCharacteristics({
                                            ...siteCharacteristics,
                                            areaUnits: e.target.value
                                        })
                                    }
                                    }
                                    value={siteCharacteristics.areaUnits || ''}
                                >
                                    <MenuItem value={'acres'}>Acres</MenuItem>

                                    <MenuItem value={'squareMiles'}>Square Miles</MenuItem>
                                    <MenuItem value={'squareKilometers'}>Square Kilometers</MenuItem>
                                    <MenuItem value={'squareMeters'}>Square Meters</MenuItem>
                                    <MenuItem value={'hectares'}>Hectares</MenuItem>
                                </Select>
                            )}

                            value={siteCharacteristics.area || ''}
                        />
                    </Col>

                </Row>

            </Col>
            <Col {...fullMobile(6, 24)} style={{}}>
                <Row align="middle" gutter={[4, 4]}>
                    <Col {...fullMobile(24, 24)} style={{}}>
                        <Typography variant="body1" fontWeight={500} component="h4"  >
                            Volume
                        </Typography>

                    </Col>
                    <Col {...fullMobile(24, 24)} style={{}}>
                        <OutlinedInput
                            onBlur={saveCharacteristics }
                            notched={false}
                            size="small"
                            placeholder="Volume"
                            label="none"
                            onChange={(e) => {
                                setSiteCharacteristics({
                                    ...siteCharacteristics,
                                    volume: e.target.value
                                })
                            }
                            }
                            endAdornment={(
                                <Select
                                    display="inline"
                                    variant='standard'
                                    size="small"
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    label="none"
                                    notched={false}
                                    onChange={(e) => {
                                        setSiteCharacteristics({
                                            ...siteCharacteristics,
                                            volumeUnits: e.target.value
                                        })
                                    }
                                    }
                                    value={siteCharacteristics.volumeUnits || ''}
                                >

                                    <MenuItem value={'gallons'}>Gallons</MenuItem>
                                    <MenuItem value={'acreFeet'}>Acre Feet</MenuItem>
                                </Select>
                            )}

                            value={siteCharacteristics.volume || ''}
                        />
                    </Col>

                </Row>

            </Col>



 
         
            <Col {...fullMobile(24, 24)} style={{}}>
                <Divider />
            </Col>
            <Col {...fullMobile(24, 24)} style={{}}>
                {loading === false && (<SiteMap account={account} userInfo={userInfo} openSnack={openSnack} mapInfo={siteCharacteristics.map}
                    showProbes={siteCharacteristics.showProbes || false}
                    showDrawings={siteCharacteristics.showDrawings || false}
                    edit={siteCharacteristics.editDrawings || false}
                    probes={locations}
                    lat={account.lat} lng={account.lng}
                    updateSC={(boo, val) => {
                        console.log(boo, val)
                        setSiteCharacteristics({
                            ...siteCharacteristics,
                            [boo]: val
                        })
                        setTimeout(() => {
                            saveCharacteristicsNew({
                                ...siteCharacteristics,
                                [boo]: val
                            })
                           }, 500);
                    }}

                    updateMap={(mapInfo) => {
                        setSiteCharacteristics({
                            ...siteCharacteristics,
                            map: mapInfo
                        })
                       setTimeout(() => {
                        saveCharacteristicsNew({
                            ...siteCharacteristics,
                            map: mapInfo
                        })
                       }, 500);
                    }} />)}
            </Col>


        </Row>
    );
}