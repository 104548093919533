import { Button, OutlinedInput, Typography } from '@mui/material';
import { Col, Modal, Row } from 'antd';
import { doc, getDoc, getFirestore, setDoc } from 'firebase/firestore';
import React from 'react';

import moment from 'moment';
import { AlphaPicker, GithubPicker } from 'react-color';
import '../hoverStyle.css';
import ApplicationLineItems from './applicationLineItems';
import MyApp from './appMap';
import { fullMobile } from './util';
var randomColor = require('randomcolor');
export default class ApplicationMeta extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            status: this.props.status,
            loading: true,
            testKey: this.props.testKey,
            tempData: {},
            dataItem: {},
            saveWaterQualityData: this.props.saveWaterQualityData,
            colorModal: false,
            modalIndex: undefined
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
        if (this.props.preview !== prevProps.preview) {
            this.setState({ preview: this.props.preview })
        }
        if (this.props.status !== prevProps.status) {
            this.setState({ status: this.props.status })
        }
        if (this.props.testKey !== prevProps.testKey) {

            if (this.props.testKey !== undefined) {
            
                this.setState({ uploadingData: true, })
                this.updateData(this.props.testKey)
                this.queryData(this.props.testKey)
            }
            this.setState({ testKey: this.props.testKey })
        }
        if (this.props.saveWaterQualityData !== prevProps.saveWaterQualityData) {

            this.setState({ saveWaterQualityData: this.props.saveWaterQualityData })
            if (this.props.saveWaterQualityData === true && this.state.testKey !== undefined) {
                this.saveData()
            }
        }


    }
    componentDidMount() {
        const { userInfo, testKey } = this.state;



        if (testKey !== undefined) {
            this.queryData(testKey)
        }
        else {
           
            const item = {
                graphics: JSON.stringify([]),
                lineItems: [],
                layerColor: randomColor({
                    format: 'rgba',
                    alpha: 0.5 // e.g. 'rgba(9, 1, 107, 0.6482447960879654)'
                })
            }
            this.setState({ tempData: item, loading: false })
        }

    }

    queryData = async (testKey) => {
        const { userInfo, } = this.state;
      
        const db = getFirestore();
        const docRef = doc(db, "clients", userInfo.currentCompany, 'applicationMeta', testKey);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {


            this.setState({ dataItem: docSnap.data(), loading: false })
        } else {
       
            const item = {
                graphics: JSON.stringify([]),
                lineItems: [],
                layerColor: randomColor({
                    format: 'rgba',
                    alpha: 0.5 // e.g. 'rgba(9, 1, 107, 0.6482447960879654)'
                })
            }
            this.setState({ tempData: item, loading: false })
        }
    }

    updateData = async (key) => {
        const { userInfo, tempData, dataItem } = this.state;
        //set  uploadingData true
        this.setState({ uploadingData: true })
        const db = getFirestore();
        const docRef = doc(db, "clients", userInfo.currentCompany, 'applicationMeta', key);

        var time = dataItem.time === undefined ? moment().tz("America/Los_Angeles").format("YYYY-MM-DD HH:mm") : dataItem.time;
        dataItem.time = time;
        await setDoc(docRef, tempData).then(() => {
           
            this.setState({ uploadingData: false })

        })
    }
    saveData = async () => {
        const { userInfo, tempData, dataItem, testKey } = this.state;
        //set  uploadingData true
        this.setState({ uploadingData: true })
        const db = getFirestore();
        const docRef = doc(db, "clients", userInfo.currentCompany, 'applicationMeta', testKey);
        await setDoc(docRef, dataItem).then(() => {
        
            this.setState({ uploadingData: false })

        })


    }

    addData = (boo, val) => {
        const { dataItem, userInfo, testKey, tempData } = this.state;

        if (testKey === undefined) {
            tempData[boo] = val
            this.setState({ tempData })

        }
        else {
            dataItem[boo] = val
            this.setState({ dataItem })
        }


    }








    render() {

        const { item, screenWidth, preview, dataItem, testKey, tempData, loading, status, userInfo, account, colorModal, modalIndex, saveWaterQualityData } = this.state;

        const data = testKey === undefined ? tempData : dataItem;

        const totalArea = loading === false && data.graphics !== undefined ? JSON.parse(data.graphics).length === 0 ? 0 : Number(Number(JSON.parse(data.graphics).map((g) => !['point'].includes(g.attributes.shapeType) ? g.attributes.area : 0).reduce((partialSum, a) => partialSum + a, 0)).toFixed(2)).toLocaleString() : 0;
        const totalPerimeter = loading === false && data.graphics !== undefined ? JSON.parse(data.graphics).length === 0 ? 0 : Number(Number(JSON.parse(data.graphics).map((g) => !['point'].includes(g.attributes.shapeType) ? g.attributes.length : 0).reduce((partialSum, a) => partialSum + a, 0)).toFixed(0)).toLocaleString() : 0;


      
        return (
            <Row gutter={[24, 24]} style={{ paddingTop: 10 }}  >

                <Col {...fullMobile(16, 24)}  >
                    <Typography variant="h2" style={{ fontSize: 32, }}>Application Info</Typography>
                </Col>

                <Col sm={14} xs={24}>
                    <Row gutter={[24, 24]} style={{ paddingTop: 10 }}  >
                        <Col {...fullMobile(24, 24)}>
                            <Typography variant="body1" fontWeight={600}>Target Species:</Typography>
                            {status === 'built' ? <Typography variant="body1" fullWidth>{data.targetSpecies}</Typography> : <OutlinedInput
                                fullWidth notched={false} label="none" placeholder={"Target Species"} size="small"

                                value={data.targetSpecies}
                                onChange={(e) => {
                                    this.addData(`targetSpecies`, e.target.value)
                                }} />}
                        </Col>
                        <Col {...fullMobile(8, 12)} style={{}}>
                            <Typography variant="body1" fontWeight={600}>Total Area (ac)</Typography>
                            <Typography variant="body1" >{totalArea}</Typography>


                        </Col>
                        <Col {...fullMobile(8, 12)} style={{}}>
                            <Typography variant="body1" fontWeight={600}>Total Linear Ft</Typography>
                            <Typography variant="body1" >{totalPerimeter}</Typography>


                        </Col>
                        <Col {...fullMobile(8, 12)} style={{}}>
                            <Typography variant="body1" fontWeight={600}>Color</Typography>
                            {preview ?
                                <Button style={{ height: 30, width: 20, padding: 10, backgroundColor: data.layerColor }} variant="contained" color="inherit"
                                ></Button>
                                :
                                <Button style={{ height: 30, width: 20, padding: 10, backgroundColor: data.layerColor }} variant="contained" color="inherit"
                                    onClick={() => {
                                        this.setState({ colorModal: true, modalIndex: 1 })
                                    }}></Button>
                            }
                        </Col>
                        <Col xs={24} sm={0} style={{}}>
                            {loading === false && Object.values(data).length > 0 && (

                                status === 'built' ? <img src={data.imgurl} style={{ width: '95%', height: '400px', borderRadius: 16, }} />

                                    :

                                    <MyApp account={account} graphics={data.graphics} screenWidth={screenWidth} userInfo={userInfo} item={data} layerColor={data.layerColor}
                                        updateGraphics={(graphics) => this.addData(`graphics`, graphics)}
                                        sendImage={(url) => {
                                            this.addData(`imgurl`, url);

                                        }} />)}
                        </Col>



                    </Row>

                </Col>

                <Col xs={0} sm={10} style={{}}>
                    {loading === false && Object.values(data).length > 0 && (

                        status === 'built' ? <img src={data.imgurl} style={{ width: '95%', height: '400px', borderRadius: 16, }} />

                            :

                            <MyApp account={account} graphics={data.graphics} screenWidth={screenWidth} userInfo={userInfo} item={data} layerColor={data.layerColor}
                                updateGraphics={(graphics) => this.addData(`graphics`, graphics)}
                                sendImage={(url) => {
                                    this.addData(`imgurl`, url);

                                }} />)}
                </Col>

                {Object.values(data).length > 0 && (<Col {...fullMobile(24)} style={{ paddingBottom: 20 }}>
                    <ApplicationLineItems account={account} testKey={testKey} saveWaterQualityData={saveWaterQualityData}
                        screenWidth={screenWidth} userInfo={userInfo} openSnack={this.props.openSnack} status={status}
                        doneSaving={() => {

                            this.setState({ saveWaterQualityData: false })
                        }} />

                </Col>)}





                <Modal title="" visible={colorModal} footer={null} onCancel={() => {
                    this.setState({ colorModal: false, modalIndex: undefined })
                }}>

                    {modalIndex !== undefined && (
                        <Row gutter={[16, 16]} >
                            <Col span={24}>
                                <GithubPicker color={data.layerColor} onChangeComplete={(color) => {
                                    const rgb = data.layerColor.substring(4, data.layerColor.length - 1)
                                        .replace(/ /g, '')
                                        .split(',');
                                  
                                    const layerColor = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${rgb[3]})`;

                                    this.addData('layerColor', layerColor)


                                }} />
                            </Col>
                            <Col span={24}>
                                <AlphaPicker color={data.layerColor} onChangeComplete={(color) => {

                                    this.addData(`layerColor`, `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`)
                                }} />
                            </Col>
                        </Row>
                    )}



                </Modal>
















            </Row >
        );
    }
} 