

import {
    Badge,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    List,
    ListItem,
    Popover,
    Tooltip,
    Typography
} from '@mui/material';
import React, { useState } from 'react';
import { faCheckCircle, faCircle, faCopy, faEdit, faEye, faEyeSlash, faSquare, faSquareCheck, faGear } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faDownload, faEllipsisH, faList, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import * as Papa from 'papaparse';
import moment from 'moment-timezone';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';


const BulkDelete = ({ sortedData, visibleHeaders, deleteSelectedData, selectedData, setBulkEditor, getNestedProperty, setCurrentCell,
    setParametersModal,  setUpdatingData,
    type
}) => {

    const [deleteDialog, setDeleteDialog] = useState(false);

    const handleClose = () => {
        setDeleteDialog(false);
    };



    return (
        <div style={{
            display: 'inline',
            marginRight: 25,

        }}>
            {selectedData.length > 0 && (
                <Badge badgeContent={selectedData.length} color="error" style={{ paddingLeft: 7 }}>
                    <IconButton onClick={() => {
                        setDeleteDialog(true)
                    }}

                    >
                        <Tooltip title="Delete" placement="bottom">
                            <FontAwesomeIcon icon={faTrash}
                                size="md" style={{ color: 'gray', cursor: 'pointer' }} />
                        </Tooltip>



                    </IconButton>
                </Badge>
            )}
            <Dialog
                open={deleteDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Are you sure you want to delete {selectedData.length} data records?
                </DialogTitle>

                <DialogActions>
                    <Button style={{
                        textTransform: 'none',
                    }} onClick={handleClose}>Cancel</Button>
                    <Button
                        style={{
                            fontWeight: 600,
                            textTransform: 'none',
                            color: 'red'
                        }}

                        onClick={() => {
                            deleteSelectedData(selectedData);
                            handleClose();
                        }}
                        autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>



        </div>
    );
};

export default BulkDelete;
