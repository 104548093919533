import { Map } from '@esri/react-arcgis';
import { Button, OutlinedInput, Typography } from '@mui/material';
import { Col, Modal, Row } from 'antd';
import { doc, getFirestore, setDoc, updateDoc } from 'firebase/firestore';
import moment from 'moment';
import React from 'react';
import uuid from 'react-uuid';
import Location from '../../blocks/gis/location';
import RelocateMap from '../../blocks/gis/relocateMap';
import SearchBar from '../../blocks/gis/searchBar';
import ZoomIcon from '../../blocks/gis/zoomIcon';
import "../../extra/hoverStyle.css";
import axios, * as others from 'axios';
import queryString from 'query-string';

export default class TidalModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            data: [],
            filteredData: [],
            filters: [],
            searchText: '',
            loading: true,
            type: this.props.type,
            currentItem: this.props.currentItem,
            openModal: this.props.openModal,
            nextPage: '',
            token: '',

        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
        if (this.props.openModal !== prevProps.openModal) {
            this.setState({ openModal: this.props.openModal })
        }
        if (this.props.currentItem !== prevProps.currentItem) {
            this.setState({ currentItem: this.props.currentItem })
        }

    }
    componentDidMount() {
        const { userInfo, account } = this.state;



    }








    updateCurrentItem = (boo, val) => {
        const { currentItem } = this.state;
        currentItem[boo] = val;
        this.setState({ currentItem })
    }


    handleCheck = (val,) => {
        // Clears running timer and starts a new one each time the user types
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.toggleCheck(val,);
        }, 500);
    }

    toggleCheck = async (val) => {
        this.checkStation(val)
    }

    checkStation = async (val) => {
        const { currentItem } = this.state;

        const url = `https://api.tidesandcurrents.noaa.gov/mdapi/prod/webapi/stations/${val}.json?expand=notices&units=english`;

        axios.get(url,)

            .then((response) => {
                console.log(response.data)

                if (response.data.stations !== undefined) {
                    currentItem['name'] = response.data.stations[0].name;
                    currentItem['lat'] = response.data.stations[0].lat;
                    currentItem['lng'] = response.data.stations[0].lng;
                    currentItem['state'] = response.data.stations[0].state;
                    this.setState({ currentItem })
                    console.log(currentItem)

                    this.updateMap(response.data.stations[0].lat, response.data.stations[0].lng)
                }

            }
            )
            .catch((error) => {
                console.log(error);
            });
    }

    updateMap = (lat, lng) => {
        const { currentItem } = this.state;

        currentItem.gps = [lng, lat];

        this.setState({ currentItem })
    }



    render() {

        const { userInfo, account, data, filteredData, loading, searchText, filters, type, currentItem, accounts, openModal, screenWidth, } = this.state;
        const db = getFirestore();





        const CreateSite = () => {
            return (
                <Button size="small" style={{ padding: 10, borderRadius: 4, textTransform: 'none', fontSize: 14, }}
                    variant='contained' color="primary"
                    onClick={async () => {
                        const { currentItem } = this.state;

                        currentItem.name = currentItem.name || "";




                        const checks = [
                            currentItem.name.length === 0 ? false : true,

                        ]

                        if (currentItem.name.length === 0) {
                            this.props.openSnack('error', "Site name required!")
                        }


                        if (!checks.includes(false)) {


                            const key = currentItem.key || currentItem.tempKey;
                            currentItem.archived = currentItem.archived || false;

                            currentItem.account = userInfo.currentAccount;
                            currentItem.company = userInfo.currentCompany;


                            const docRef = doc(db, "clients", userInfo.currentCompany, 'tidalStations', key);

                            if (currentItem.key) {
                                await updateDoc(docRef, currentItem).then(() => {
                                    this.props.openSnack('success', currentItem.key ? 'Station updated!' : "Station created!")
                                })
                            }
                            else {
                                await setDoc(docRef, {
                                    ...currentItem,
                                    key: currentItem.tempKey
                                }).then(() => {
                                    this.props.openSnack('success', currentItem.key ? 'Station updated!' : "Station created!")
                                    this.props.onClose()
                                }).then(async () => {
                                    const startDate = moment().subtract(1, 'days').format('YYYYMMDD');
                                    const endDate = moment().format('YYYYMMDD');
                                    const url = `https://api.tidesandcurrents.noaa.gov/api/prod/datagetter?product=water_level&application=NOS.COOPS.TAC.WL&begin_date=${startDate}&end_date=${endDate}&datum=MLLW&station=${currentItem.id}&time_zone=LST&units=english&format=json`;

                                    const response = await axios.get(url,);


                                    const dataItems = [];

                                    response.data.data.map((item) => {
                                        var time = moment(item.t, 'YYYY-MM-DD HH:mm').tz("America/Los_Angeles").format("YYYY-MM-DD HH:mm");
                                        dataItems.push({
                                            time: `'${time}'`,
                                            id: `'${currentItem.id}'`,
                                            key: `'${uuid()}'`,
                                            value: Number(item.v),

                                        })
                                    })

                                    console.log(dataItems)


                                    const hourlyVals = dataItems.map((h) => `(${Object.values(h)})`);
                                    const hourlyString = hourlyVals.join();

                                    const proxyUrl = "https://mycorslake.herokuapp.com/";
                                    axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                                        raw: `INSERT INTO station_data_new VALUES ${hourlyString} ON CONFLICT DO NOTHING; `
                                    })
                                        .then(async (response) => {
                                            // handle success
                                            console.log(response)

                                        }).catch((error) => {
                                            // handle error
                                            console.log(error);
                                        })
                                })

                            }


                        }




                    }}>{currentItem.key ? 'Update Station' : "Create Station"}</Button>
            )
        }




        return (




            <Modal title="" visible={openModal} onCancel={() => {
                this.setState({ openModal: false })
                this.props.onClose()
            }} footer={null} width={'60%'} style={{ borderRadius: 40 }} >
                <Row align="middle" gutter={[16, 16]} >
                    <Col xs={{ offset: 0, span: 24 }} sm={{ offset: 6, span: 18 }} style={{ paddingBottom: 12, }}>
                        <Typography variant='h5'>Station</Typography>
                    </Col>


                    <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                        <Typography variant="body1">Station Name<span style={{ color: 'red' }}>*</span></Typography>
                    </Col>
                    <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                        <OutlinedInput fullWidth notched={false} label="none" placeholder={"Name"} size="small"
                            value={currentItem.name || ''}
                            onChange={(e) => {
                                this.updateCurrentItem(`name`, e.target.value)
                            }} />
                    </Col>
                    <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                        <Typography variant="body1">Station ID<span style={{ color: 'red' }}>*</span></Typography>
                    </Col>
                    <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                        <OutlinedInput fullWidth notched={false} label="none" placeholder={"ID"} size="small"
                            value={currentItem.id || ''}
                            onChange={(e) => {
                                this.updateCurrentItem(`id`, e.target.value)

                                this.handleCheck(e.target.value)
                            }} />
                    </Col>


                    <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                        <Typography variant="body1">Description</Typography>
                    </Col>
                    <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                        <OutlinedInput fullWidth notched={false} label="none" placeholder={"Description"} size="small"
                            multiline={true}
                            rows={4}
                            value={currentItem.description || ''}
                            onChange={(e) => {
                                this.updateCurrentItem(`description`, e.target.value)
                            }} />
                    </Col>

                    <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                        <Typography variant="body1">Map</Typography>
                    </Col>
                    <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0, display: 'flex', height: 300, }}>

                        <Map className="Map" style={{ width: '100%', height: '100%', borderRadius: 16, }}
                            mapProperties={{ basemap: 'arcgis-imagery' }}

                            viewProperties={{
                                center: [account.lng, account.lat],
                                zoom: 16,
                                ui: []

                            }}>

                            <RelocateMap view={this.view} gps={currentItem.gps || [account.lng, account.lat]} />







                            <SearchBar map={this.map} view={this.view} />
                            <ZoomIcon map={this.map} view={this.view} />
                            <Location map={this.map} view={this.view} />

                        </Map>

                    </Col>




                    < Col xs={{ offset: 0, span: 24 }} sm={{ offset: 6, span: 18 }} style={{ paddingBottom: 12, }}>
                        <CreateSite />
                    </Col>

                </Row>
            </Modal >





        );
    }
} 