import React, { useEffect, useState } from 'react';
import {
    CircularProgress,
    Typography,
    Checkbox,
    IconButton,
    TextField,
    Select,
    MenuItem
} from '@mui/material';
import { Col, Row } from 'antd';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faGripVertical, faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';
import { uploadFile } from './firestore';
import uuid from 'react-uuid';
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import './carouselSettings.css';
const DragHandle = sortableHandle(() => (
    <div
        className="image-handle"
        style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '20px',
            height: '20px',
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'grab',
        }}
    >
        <FontAwesomeIcon icon={faGripVertical} />
    </div>
));
const SortableItem = SortableElement(({ image, index, removeImage }) => (
    <Col key={image.key} span={6}>
        <div
            style={{
                position: 'relative',
                width: '100%',
                paddingTop: '100%',
                backgroundImage: `url(${image.downloadURL})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',

            }}
        >
            <DragHandle />
            <IconButton
                onClick={() => {
                    console.log('remove image', image);
                    removeImage(image.key);
                }}
                style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    zIndex: 100,
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    borderRadius: '50%',
                    width: '30px',
                    height: '30px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                X
            </IconButton>
        </div>
        <TextField
            style={{ paddingTop: 4 }}
            placeholder="Title"
            variant="outlined"
            size="small"
            fullWidth
            defaultValue={image.title}
            onBlur={(e) => {
                //update the images array
                image.title = e.target.value;
            }}
        />
        <TextField
            style={{ paddingTop: 4 }}
            placeholder="Description"
            variant="outlined"
            size="small"
            fullWidth
            multiline
            rows={2}
            defaultValue={image.description}
            onBlur={(e) => {
                //update the images array
                image.description = e.target.value;
            }}
        />
    </Col>
));

const SortableList = SortableContainer(({ images, removeImage }) => {
    return (
        <Row gutter={[8, 8]}>
            {images.map((image, index) => (
                <SortableItem key={`item-${image.key}`} index={index} image={image} removeImage={removeImage} />
            ))}
        </Row>
    );
});

export default function CarouselSettings(props) {
    const {
        userInfo,
        openSnack,
        attributes,
        updateAttributes,
    } = props;
    const [loading, setLoading] = useState(true);
    const [uploading, setUploading] = useState(false);
    const [images, setImages] = useState([]);

    useEffect(() => {
        getInfo();
        console.log(userInfo)
    }, []);

    async function getInfo() {
        if (attributes.carouselImages) {
            setImages(attributes.carouselImages);
            setLoading(false);
        }
        else {
            setLoading(false);
        }
    }

    const onDrop = async (acceptedFiles) => {
        acceptedFiles.forEach(async (file) => {
            const key = uuid();
            const path = `files/${userInfo.currentCompany}/widgets/${key}-${file.name}`;

            try {
                openSnack('success', 'File Uploading...');
                setUploading(true);
                const fileInfo = await uploadFile(file, path);


                images.push({
                    downloadURL: fileInfo.downloadURL,
                    title: '',
                    description: '',
                    key,
                });
                setImages(images);
                attributes.carouselImages = images;

                setUploading(false);
                openSnack('success', 'File Uploaded');
            } catch (error) {
                console.log(error);
                openSnack('error', 'File Upload Failed');
            }
        });
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const removeImage = (key) => {
        console.log(key)
        const newImages = images.filter((image) => image.key !== key);
        setImages(newImages);
        attributes.carouselImages = newImages;
    };



    const onSortEnd = ({ oldIndex, newIndex }) => {
        setImages(arrayMoveImmutable(images, oldIndex, newIndex));
        attributes.carouselImages = arrayMoveImmutable(images, oldIndex, newIndex);
    };

    if (loading) {
        return (
            <div className="loading">
                <CircularProgress />
                <Typography variant="body1">Loading Data</Typography>
            </div>
        );
    } else {
        return (
            <Row
                gutter={[8, 8]}
                style={{ height: '100%', width: '100%', display: 'flex' }}
            >
                <Col span={8}>
                    <Typography variant="body1" display="inline">Transparent Background</Typography>   <Checkbox display="inline"
                        defaultChecked={attributes.transparentBackground}
                        onChange={(e) => {
                            attributes.transparentBackground = e.target.checked;
                        }}
                    />
                </Col>
                <Col span={8}>
                    <Typography variant="body1" display="inline">Autoplay</Typography>   <Checkbox display="inline"
                        defaultChecked={attributes.autoplay}
                        onChange={(e) => {
                            attributes.autoplay = e.target.checked;
                        }}
                    />
                </Col>
                <Col span={8}>
                    <Typography variant="body1" display="inline">Object Fit
                        <Select
                            defaultValue={attributes.objectFit}
                            onChange={(e) => {
                                attributes.objectFit = e.target.value;
                            }}
                            style={{ width: '100%' }}
                        >
                            <MenuItem value="cover">Cover</MenuItem>
                            <MenuItem value="contain">Contain</MenuItem>
                            <MenuItem value="fill">Fill</MenuItem>
                            <MenuItem value="scale-down">Scale Down</MenuItem>
                        </Select>
                    </Typography>


                </Col>

                <Col span={24}>
                    {uploading === true ? (
                        <div className="dropzone drag-active">
                            <FontAwesomeIcon icon={faSpinner} spin />
                            <Typography variant="body1">Uploading...</Typography>
                        </div>
                    ) : (
                        <div
                            className={`dropzone${isDragActive ? ' drag-active' : ''}`}
                            {...getRootProps()}
                            style={{
                                minHeight: '100px',
                                cursor: 'pointer',
                                borderWidth: isDragActive ? '4px' : '2px', // Increase border width when dragging
                                borderStyle: 'dashed',
                                borderColor: '#ccc',
                                transition: 'border-width 0.2s ease-in-out', // Add transition for smooth border-width change,
                            }}
                        >
                            <input {...getInputProps({ multiple: true, accept: 'image/*' })} />
                            <Typography variant="body1" fontSize={16} fontWeight={400}>
                                Drag 'n' drop carousel images here or click to add images
                            </Typography>
                        </div>
                    )}
                </Col>

                <SortableList
                    axis="xy"
                    images={images}
                    onSortEnd={onSortEnd}
                    removeImage={removeImage}
                    helperClass="sortable-helper"
                />




            </Row>
        );
    }
}
