import React, { useEffect, useState } from "react";


import { Box, Button, ButtonGroup, Modal, TextField, Typography, IconButton, OutlinedInput, Select, MenuItem, FormControl, InputLabel, Checkbox, InputAdornment, ListItem } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Col, Row } from 'antd';
import uuid from 'react-uuid';
import '../taskList.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxesPacking, faBoxesStacked, faFlask, faMoneyBill, faPercent, faPlus, faX } from "@fortawesome/free-solid-svg-icons";
import { faCheck, faCircleChevronDown, faHouse, faHashtag, faFont } from "@fortawesome/free-solid-svg-icons";
import { faSquareCheck, faCalendar, faUser } from "@fortawesome/free-regular-svg-icons";
import CustomHeaderNumberFormat from "./customheaderComponents/numberFormat";
import SelectOptions from "./customheaderComponents/selectOptions";
import ItemSelectFormat from "./customheaderComponents/itemSelectFormat";
import { getFirestore } from "firebase/firestore";
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';


const CreateCustomHeader = (
    {
        screenWidth,
        headers,
        currentTask,
        setCurrentTask,
        userInfo,
        setStocks,
        openSnack,
        accounts,
        setHeaders,



    }

) => {
    const [customHeaderModal, setCustomHeaderModal] = useState(false);
    const [customHeader, setCustomHeader] = useState({});
    const [newItem, setNewItem] = useState({});
    const [activeTab, setActiveTab] = useState('create');
    const [isFocused, setIsFocused] = useState(false);
    const [customSearch, setCustomSearch] = useState('');








    const menuItems = [
        { label: 'Single Select', value: 'singleSelect', icon: faCircleChevronDown },
        { label: 'Multiple Select', value: 'multipleSelect', icon: faSquareCheck },
        { label: 'Number Input', value: 'number', icon: faHashtag, },
        { label: 'String Input', value: 'string', icon: faFont },
        { label: 'Money Input', value: 'money', icon: faMoneyBill }, // adjust as necessary
        { label: 'Percent Input', value: 'percent', icon: faPercent }, // adjust as necessary
        { label: 'Staff Select', value: 'staffSelect', icon: faUser },
        { label: 'Item Select', value: 'itemSelect', icon: faFlask }, // adjust as necessary
        { label: 'Stock Select', value: 'stockSelect', icon: faBoxesStacked }, // adjust as necessary
        { label: 'Site Select', value: 'siteSelect', icon: faHouse }, // adjust as necessary
        { label: 'Date Select', value: 'dateSelect', icon: faCalendar }
    ];

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: screenWidth === 'xs' ? '90vw' : '60vw',
        bgcolor: 'background.paper',
        border: '1px solid #000',
        borderRadius: '10px',
        boxShadow: 24,
        maxHeight: '90vh',
        p: 0,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
    };

    const rowFixedStyle = {
        flexShrink: 0,
    };

    const rowScrollableStyle = {
        flexGrow: 1,
        overflowY: 'auto',
        overflowX: 'hidden',
    };

    // New style for Row 3
    const row3Style = {
        flexShrink: 0,
        marginTop: 'auto',
    };




    const modalContentStyle = {
        display: 'flex',
        flexDirection: 'column',
        height: '90vh', // Make sure it takes the full height of the modal,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: screenWidth === 'xs' ? '90vw' : '60vw',
        bgcolor: 'background.paper',
        border: '1px solid #000',
    };

    // Adjusted style for the content area (Row 1 and Row 2)
    const contentAreaStyle = {
        flexGrow: 1,
        overflowX: 'hidden', // Hide horizontal scrollbar
        overflowY: 'auto', // Add scrolling if content is too long
        // ... other styles you might need
    };





    const renderComponent = (optionType, customHeader, setCustomHeader) => {
        switch (optionType) {
            case 'singleSelect':
                return <SelectOptions type="single" customHeader={customHeader} setCustomHeader={setCustomHeader} updateOptions={(options) => setCustomHeader({ ...customHeader, options: options })} />;
            case 'multipleSelect':
                return <SelectOptions type="multiple" customHeader={customHeader} setCustomHeader={setCustomHeader} updateOptions={(options) => setCustomHeader({ ...customHeader, options: options })} />;

            case 'number':
                return <CustomHeaderNumberFormat customHeader={customHeader} setCustomHeader={setCustomHeader} />;
            case 'itemSelect':
                return <ItemSelectFormat customHeader={customHeader} setCustomHeader={setCustomHeader} />;

            default:
                return null;
        }
    };

    const optionsAreValid =
        customHeader?.options?.length > 0 &&
        customHeader?.options?.every(option => option.name && option.name.trim() !== '');

    const customHeaders = headers?.filter(header => header?.customHeader === true);

    const formatHeaderName = (name) => name.toLowerCase().replace(/\s/g, '');





    return (
        <div style={{ marginTop: 20, marginBottom: 20 }}>

            <Button variant="outlined"
                style={{
                    textTransform: 'none',
                    border: '1px solid rgb(0, 0, 0, 0.2)',
                    color: 'rgb(0, 0, 0, 0.9)',
                }}
                color="info"
                onClick={() => {
                    setCustomHeaderModal(true)
                }}>
                <FontAwesomeIcon icon={faPlus} style={{ marginRight: '10px' }} />
                Add Custom Field
            </Button>



            <Modal title="" open={customHeaderModal}



                onClose={() => {
                    setCustomHeaderModal(false)


                }} footer={null} width={'60%'}  >
                <Box sx={modalContentStyle}>

                    <Box sx={contentAreaStyle}>
                        <Row style={rowFixedStyle} className="row1">
                            <Col span={20} style={{ padding: 30 }}>
                                <Typography variant="h6" component="div" fontWeight={600} fontSize={28}>
                                    Add field
                                </Typography>
                            </Col>
                            <Col span={4} style={{
                                textAlign: 'right',
                                paddingTop: 30, paddingRight: 20,
                            }}>
                                <IconButton
                                    style={{


                                    }} aria-label="close" onClick={() => {
                                        setCustomHeaderModal(false)
                                    }}>
                                    <FontAwesomeIcon icon={faX} size="md"
                                        onClick={() => {
                                            setCustomHeaderModal(false)
                                        }} />
                                </IconButton>
                            </Col>

                            <Col xs={24} sm={24} style={{
                                height: '50px',
                                borderBottom: '1px solid rgba(0,0,0,0.2)',
                                display: 'flex',
                                alignItems: 'flex-end', // Adjusted this line
                                justifyContent: 'flex-start', // Adjusted this line for better readability
                            }}>
                                <ButtonGroup size="small" aria-label="small button group" style={{
                                    marginLeft: screenWidth === 'xs' ? 20 : 40
                                }}>
                                    {[
                                        { label: 'Create new', value: 'create' },
                                        { label: 'Choose from library', value: 'choose' }
                                    ].map((item, index) => (
                                        <Button
                                            key={item.value}
                                            onClick={() => setActiveTab(item.value)}
                                            color='primary'
                                            variant={null}
                                            sx={{
                                                px: 0
                                            }}
                                            style={{
                                                marginRight: 20,
                                                height: '50px',
                                                textTransform: 'none',
                                                borderRadius: 0,
                                                fontWeight: activeTab === item.value ? 700 : 400,
                                                fontSize: 18,
                                                color: activeTab === item.value ? 'rgba(0,0,0,0.9)' : null,
                                                borderBottom: activeTab === item.value ? '3px solid rgba(0,0,0,0.9)' : '3px solid rgba(0,0,0,0.0)'
                                            }}
                                        >
                                            {item.label}
                                        </Button>
                                    ))}
                                </ButtonGroup>
                            </Col>

                        </Row>

                        {activeTab === 'create' && (
                            <Row style={rowFixedStyle} className="row1a">


                                <Col xs={24} sm={24}
                                    style={{
                                        paddingLeft: screenWidth === 'xs' ? 20 : 40,
                                        marginTop: 20,
                                        paddingRight: screenWidth === 'xs' ? 20 : 40,

                                    }} >
                                    <Row >
                                        <Col xs={24} sm={14} >
                                            <Typography variant="h6" component="div" color={'rgba(0,0,0,0.6)'} fontWeight={600} fontSize={16}>
                                                Field title
                                            </Typography>
                                            <OutlinedInput
                                                margin="dense"

                                                style={{
                                                    borderRadius: 12,
                                                    marginTop: screenWidth === 'xs' ? 2 : 5,
                                                    marginBottom: 20,
                                                    width: '100%',
                                                    fontSize: 16,
                                                }}
                                                value={customHeader.name}
                                                onChange={(e) => {
                                                    setCustomHeader({ ...customHeader, name: e.target.value })
                                                }}
                                                placeholder={'Field title'}
                                            />
                                        </Col>
                                        <Col xs={24} sm={{ span: 9, offset: 1 }} >
                                            <Typography variant="h6" component="div" color={'rgba(0,0,0,0.6)'} fontWeight={600} fontSize={16}>
                                                Field type
                                            </Typography>
                                            <FormControl fullWidth variant="standard">
                                                {!customHeader?.optionType && !isFocused && (
                                                    <InputLabel id="demo-simple-select-standard-label"
                                                        style={{
                                                            marginLeft: 15, marginTop: 2, fontSize: 16,
                                                            color: 'rgba(0,0,0,0.4)',

                                                        }}

                                                    >
                                                        Field Type
                                                    </InputLabel>
                                                )}
                                                <Select
                                                    style={{
                                                        borderRadius: 12,
                                                        marginTop: screenWidth === 'xs' ? 2 : 5,
                                                        marginBottom: 20,
                                                        fontSize: 16,
                                                        width: '100%',
                                                        //no wrap
                                                        whiteSpace: 'nowrap',
                                                        display: 'inline-block',
                                                    }}
                                                    fullWidth
                                                    onFocus={() => setIsFocused(true)}
                                                    onBlur={() => setIsFocused(false)}
                                                    placeholder="Select"
                                                    variant="outlined"
                                                    value={customHeader.optionType}
                                                    onChange={(e) => setCustomHeader({ ...customHeader, optionType: e.target.value })}

                                                    renderValue={(selectedValue) => {
                                                        const selectedItem = menuItems.find(item => item.value === selectedValue);
                                                        if (!selectedItem) return null;

                                                        return (
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <FontAwesomeIcon icon={selectedItem.icon} size="lg" style={{ marginRight: '10px' }} />
                                                                <Typography variant="body1" component="span" color={'rgba(0,0,0,0.6)'} fontWeight={600} fontSize={16}>
                                                                    {selectedItem.label}
                                                                </Typography>
                                                            </div>
                                                        );
                                                    }}
                                                >
                                                    {menuItems.map(item => (
                                                        <MenuItem
                                                            sx={{
                                                                p: 1
                                                            }} key={item.value} value={item.value}>
                                                            {customHeader.optionType === item.value ?
                                                                <FontAwesomeIcon icon={faCheck} size="lg" style={{ marginRight: '10px' }} />
                                                                : <div style={{ width: 24, height: 24, marginRight: 10 }}></div>}
                                                            <FontAwesomeIcon icon={item.icon} size="lg" style={{ marginRight: '10px' }} />
                                                            <Typography variant="h6" component="div" color={'rgba(0,0,0,0.6)'} fontWeight={600} fontSize={16}>
                                                                {item.label}
                                                            </Typography>
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Col>


                                    </Row>

                                </Col>

                            </Row>
                        )}
                        {activeTab === 'create' && (
                            <Row style={rowScrollableStyle} className="row2">
                                <Col xs={24} sm={24}
                                    style={{
                                        paddingLeft: screenWidth === 'xs' ? 20 : 40,
                                        paddingRight: screenWidth === 'xs' ? 20 : 40,

                                    }} >
                                    {renderComponent(customHeader.optionType, customHeader, setCustomHeader)}
                                </Col>

                            </Row>
                        )}
                        {activeTab !== 'create' && (
                            <Row style={rowScrollableStyle} className="row5">

                                <Col span={24} style={{
                                    padding: 20
                                }}>
                                    <OutlinedInput

                                        fullWidth
                                        style={{
                                            borderRadius: 16,
                                            fontSize: 16,


                                        }}
                                        sx={{
                                            px: 2,
                                            py: 0,

                                        }}

                                        value={customSearch}
                                        placeholder='Find a field in your account'
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        }
                                        endAdornment={
                                            <InputAdornment position="end">
                                                {customSearch !== '' ? (
                                                    <IconButton
                                                        aria-label="clear search term"
                                                        edge="end"
                                                        onClick={() => setCustomSearch('')}
                                                    >
                                                        <ClearIcon />
                                                    </IconButton>
                                                ) : (
                                                    // Transparent placeholder for the ClearIcon
                                                    <IconButton style={{ visibility: 'hidden' }}>
                                                        <ClearIcon />
                                                    </IconButton>
                                                )}
                                            </InputAdornment>
                                        }
                                        onChange={(e) => setCustomSearch(e.target.value)}


                                    />
                                </Col>
                                <Col span={24} style={{
                                    paddingLeft: 20,
                                    paddingRight: 20,
                                }}>
                                    {customHeaders?.map((header, index) => {


                                        if (header.headerName.toLowerCase().includes(customSearch.toLowerCase())) {
                                            return (
                                                <ListItem
                                                    key={index}
                                                    dense
                                                    button
                                                    onClick={() => {
                                                        //add to currentTask
                                                        const headersCheck = currentTask?.headers?.some(header => header.headerName === header.headerName);
                                                        if (headersCheck) {
                                                            openSnack('error', 'A field with this name already exists');
                                                            return;
                                                        }
                                                        setCurrentTask({
                                                            ...currentTask,
                                                            [header.id]: null
                                                        })
                                                        setCustomHeaderModal(false);





                                                    }}
                                                    sx={{
                                                        borderBottom: '1px solid #e0e0e0',
                                                        paddingTop: 1,
                                                        paddingBottom: 1,
                                                    }}
                                                >

                                                    <Typography variant="body1" color="black" fontSize={14} fontWeight={600}>
                                                        {`${header.headerName} (${header.optionType})`}

                                                    </Typography>

                                                </ListItem>
                                            )
                                        }
                                    })}
                                </Col>


                            </Row>
                        )}


                    </Box>
                    <Row style={row3Style} className="row3">
                        {/*  <Col xs={24} style={{ marginTop: 20, marginLeft: 40, display: 'inline' }}  >
                                <Checkbox
                                    sx={{
                                        '& .MuiSvgIcon-root': { fontSize: 28 },
                                        color: '#3f51b5',
                                        '&.Mui-checked': {
                                            color: '#3f51b5',
                                        },
                                    }}
                                    style={{ marginRight: 10, display: 'inline' }}
                                    //adding to customHeaderLibrary
                                    checked={customHeader?.saveToLibrary}
                                    onChange={(e) => {
                                        setCustomHeader({ ...customHeader, saveToLibrary: e.target.checked });
                                    }}
                                />
                                <Typography style={{ display: 'inline' }} variant="h6" component="div" color={'rgba(0,0,0,0.8)'} fontWeight={700} fontSize={16}>
                                    Save to library
                                </Typography>
                            </Col> */}
                        <Col xs={24} sm={24} style={{
                            borderTop: '1px solid rgba(0,0,0,0.2)',
                            marginTop: 20,
                            paddingTop: 20,
                            paddingBottom: 40,
                        }}>
                            <Row
                                justify="end"
                                align={'middle'}

                                style={{
                                    padding: 20,

                                }}>
                                <Col style={{

                                }}>
                                    <Button
                                        variant="default"

                                        style={{
                                            textTransform: 'none',
                                            borderRadius: 12,
                                            fontWeight: 500,
                                            fontSize: 16,
                                            marginRight: 20,

                                            height: 40,
                                            border: '1px solid rgba(0,0,0,0.4)',


                                        }}
                                        onClick={() => {
                                            setCustomHeaderModal(false)
                                        }}

                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        variant="contained"
                                        disabled={!customHeader.name || !customHeader.optionType || (['singleSelect', 'multipleSelect'].includes(customHeader.optionType) && !optionsAreValid)}
                                        style={{
                                            textTransform: 'none',
                                            borderRadius: 12,
                                            fontWeight: 500,
                                            fontSize: 16,


                                            height: 40,
                                            backgroundColor: !customHeader.name || !customHeader.optionType || (['singleSelect', 'multipleSelect'].includes(customHeader.optionType) && !optionsAreValid) ? 'rgba(0,0,0,0.2)' : '#3f51b5',
                                            color: !customHeader.name || !customHeader.optionType || (['singleSelect', 'multipleSelect'].includes(customHeader.optionType) && !optionsAreValid) ? 'rgba(0,0,0,0.4)' : 'white',
                                        }}
                                        onClick={() => {

                                            const newHeaders = [...headers];

                                            const headerExists = newHeaders.some(header => formatHeaderName(header.headerName) === formatHeaderName(customHeader.name));

                                            if (headerExists) {
                                                openSnack('error', 'A field with this name already exists');
                                            } else {
                                                const id = uuid();

                                                newHeaders.push({
                                                    position: newHeaders.length,
                                                    headerName: customHeader.name,
                                                    id,
                                                    getLabel: ['staffSelect', 'siteSelect', 'stockSelect', 'itemSelect'].includes(customHeader.optionType) ? true : false,
                                                    width: 250,
                                                    visible: true,
                                                    minWidth: 140,
                                                    type: customHeader.type || 'string',
                                                    mobile: false,
                                                    mobileWidth: '60%',
                                                    optionType: customHeader.optionType,
                                                    customHeader: true,
                                                    company: userInfo.currentCompany,
                                                    creator: userInfo.id,
                                                    archived: false,
                                                    options: customHeader.options || [],
                                                    itemSelectType: customHeader.itemSelectType || null,
                                                    decimals: customHeader.decimals || 0,
                                                });
                                                console.log(newHeaders)
                                                console.log(customHeader.optionType)
                                                console.log({
                                                    ...currentTask,
                                                    [id]: customHeader.optionType === 'dateSelect' ? '' : null,
                                                })
                                                setCurrentTask({
                                                    ...currentTask,
                                                    [id]: customHeader.optionType === 'dateSelect' ? '' : null,
                                                })



                                                setHeaders(newHeaders);
                                                setCustomHeader({});
                                                setCustomHeaderModal(false);
                                                openSnack('success', 'Field created successfully');

                                            }
                                        }}

                                    >
                                        Create field
                                    </Button>

                                </Col>

                            </Row>

                        </Col>




                    </Row>
                </Box>
            </Modal>



        </div >



    );
};

export default CreateCustomHeader;
