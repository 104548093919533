import { Breadcrumbs, Button, CircularProgress, Divider, Input, Paper, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import { collection, doc, getDoc, getDocs, getFirestore, limit, onSnapshot, orderBy, query, setDoc, updateDoc, where } from 'firebase/firestore';
import * as htmlToImage from 'html-to-image';
import moment from 'moment';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import ApplicationMeta from './applicationComponents/applicationMeta';
import ApplicationPDF from './applicationComponents/applicationPDF';
import CheckTimeWeather from './applicationComponents/checkTimeWeather';
import GeneralInfo from './applicationComponents/generalInfo';
import WaterConditions from './applicationComponents/waterConditions';
import FLPhotos from './fieldLogComponents/photos';
import FLWaterQuality from './fieldLogComponents/waterQuality';
import { fullMobile } from './util';

import axios, * as others from 'axios';
var randomColor = require('randomcolor');

export default class ApplicationNew extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            company: this.props.company,
            doc: {},
            loading: true,
            preview: false,
            spinning: { on: false, label: '' },
            distributionList: [],
            showDistribution: false,
            colorModal: false,
            modalIndex: undefined,
            printScreen: false,
            accounts: [],
            updateWeather: false,
            saving: false,
            type: 'Application',
            testKey: undefined,
            logKey: undefined,
            saveWaterQualityData: false,
            status: undefined,
            saveLog: false,
            createWaterQualityData: { status: false, key: undefined },
            weatherDate: null,
            wqData: true,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
        if (this.props.company !== prevProps.company) {
            this.setState({ company: this.props.company })
        }

    }
    componentDidMount() {
        window.scrollTo(0, 0)
        const { userInfo } = this.state;
        this.queryDataDoc(userInfo);
        this.queryAccounts(userInfo);

 
    }

    queryDataDoc = async (userInfo) => {
        const { type, account } = this.state;
        const db = getFirestore();
        const docRef = doc(db, "users", userInfo.id, 'currentItems', 'currentFieldDoc');
        const docSnap = await getDoc(docRef);



        if (docSnap.data() === undefined || docSnap.data().key === 'none') {

            //firestore query fieldlogsnew in order of lognumber
            const queryAccountData = query(collection(db, "clients", userInfo.currentCompany, 'fieldDocs'),
                where('archived', '==', false), where('accountKey', '==', userInfo.currentAccount), orderBy("lognumber", "desc"), limit(1));
            const data = [];

            const snap = await getDocs(queryAccountData);
            snap.forEach((doc) => {
                data.push({ ...doc.data() })
            });

            //if data length is 0, set lognumber to 1
            const lognumber = data.length === 0 ? 1 : data[0].lognumber + 1;

            this.setState({
                doc: {
                    tempKey: uuid(),
                    status: 'creating',
                    archived: false,
                    type: 'applicationLog',
                    company: userInfo.currentCompany,
                    startDate: moment().format('x'),
                    endDate: moment().add(1, 'hours').format('x'),
                    startDate: moment().format('x'),
                    preCloseTime: moment().format('x'),
                    endDate: moment().add(1, 'hours').format('x'),
                    postOpenTime: moment().add(1, 'hours').format('x'),
                    controlsApplicable: false,
                    preview: false,
                    lognumber,
                    account,
                    accountKey: account.key,



                }, loading: false, status: 'creating'
            })

        }
        else {
            this.getDocItem(docSnap.data().key)
            this.setState({ logKey: docSnap.data().key })
        }
    }
    getDocItem = async (key) => {
        const { userInfo } = this.state;
        const db = getFirestore();
        const docRef = doc(db, "clients", userInfo.currentCompany, 'fieldDocs', key);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const item = docSnap.data();
            this.setState({ doc: item, loading: false, status: docSnap.data().status === 'edit' ? 'creating' : docSnap.data().status, testKey: docSnap.data().key })

        }
    }



    updateDoc = (boo, val) => {
        const { doc } = this.state;
        doc[boo] = val;
        this.setState({ doc, preview: doc.status === 'edit' ? false : true })

    }

    updateEntireDoc = (value) => {
        this.setState({ doc: value, preview: value.status === 'edit' ? false : true })
    }



    updateStatus = async (status) => {
        const { userInfo, } = this.state;
        const db = getFirestore();
        const docRef = doc(db, "clients", userInfo.currentCompany, 'fieldDocs', this.state.doc.key);
        await updateDoc(docRef, {
            status: status
        }).then(() => {
            this.setState({ status })
            this.props.openSnack('success', "Status has been changed!")
        })


    }



    queryAccounts = async () => {
        const { userInfo, type } = this.state;
        const db = getFirestore();


        const q = query(collection(db, "clients", userInfo.currentCompany, 'accounts'), where('archived', '==', false));
        const qPublic = query(collection(db, "clients", userInfo.currentCompany, 'accounts'), where('archived', '==', false), where('publicAccount', '==', true));

        const unsubscribe = onSnapshot(type === 'publice' ? qPublic : q, (snap) => {
            const data = [];
            snap.forEach((doc) => {

                if (type === 'public') {
                    data.push({
                        accountName: doc.data().accountName,
                        address: doc.data().address,
                        label: doc.data().accountName,
                        key: doc.data().key,
                        type: doc.data().seasonal || null,
                        city: doc.data().city || null,
                        countyName: doc.data().countyName || null,
                        state: doc.data().state || null,
                        routeDay: doc.data().routeDay || null,
                        routeName: doc.data().routeName || null,
                        visible: true
                    })
                }
                else {
                    if (["EuEZ93ByFZYs8bSUXoYsTt1zu0m2", 'eTevYsI9mQMxhtntd95HmRGasNx2', 'PQWFmTLCP9XqafR9ogNI3Fg1sH43', 'z4y8XVrxYGT7rO4R01Y8tQ2F5z83', 't9bOfGDqwDaQTYV6i008opscpjz2'].includes(userInfo.id)) {
                        data.push({
                            accountName: doc.data().accountName,
                            label: doc.data().accountName,
                            address: doc.data().address,
                            key: doc.data().key,
                            type: doc.data().seasonal || null,
                            city: doc.data().city || null,
                            countyName: doc.data().countyName || null,
                            state: doc.data().state || null,
                            routeDay: doc.data().routeDay || null,
                            routeName: doc.data().routeName || null,
                            visible: true
                        })
                    }
                    else {
                        if (userInfo.accounts.includes(doc.data().key)) {
                            data.push({
                                accountName: doc.data().accountName,
                                label: doc.data().accountName,
                                address: doc.data().address,
                                key: doc.data().key,
                                type: doc.data().seasonal || null,
                                city: doc.data().city || null,
                                countyName: doc.data().countyName || null,
                                state: doc.data().state || null,
                                routeDay: doc.data().routeDay || null,
                                routeName: doc.data().routeName || null,
                                visible: true
                            })
                        }
                    }
                }

            });
            data.sort((a, b) => a.accountName.localeCompare(b.accountName))
            this.setState({ accounts: data, loading: false })



        });



    }

    weatherUpdated = () => {
        this.setState({ updateWeather: false })
    }



    saveLog = async (status) => {
        const { userInfo, type } = this.state;

        this.setState({ saving: true, saveLog: true })
        const item = this.state.doc;
        const checks = [
            item.leadStaff === undefined ? false : item.leadStaff === null ? false : true,
            item.account === undefined ? false : true,
        ]


        if ([undefined, null].includes(item.leadStaff)) {
            this.props.openSnack('error', "Lead staff is required!")
            this.setState({ saving: false })
        }
        if ([undefined, null].includes(item.account)) {
            this.props.openSnack('error', "Account is required!")
            this.setState({ saving: false })
        }
        if (!checks.includes(false)) {
            const db = getFirestore();
            const docRef = doc(db, "clients", userInfo.currentCompany, 'fieldDocs', item.tempKey);



            const url = await htmlToImage.toPng(document.getElementById('my-node'))
                .then((dataUrl) => {

                    return dataUrl
                }).catch((error) => {
                    console.error('oops, something went wrong!', error)
                })


            const weatherImage = await getDataUri(url)

            const newItem = {
                ...item,
                key: item.key || item.tempKey,
                status,
                weather: {
                    ...item.weather,
                    weatherImage
                }
            };

            console.log(newItem)

            function getDataUri(url) {
                return new Promise(resolve => {
                    var image = new Image();
                    image.setAttribute('crossOrigin', 'anonymous'); //getting images from external domain

                    image.onload = function () {
                        var canvas = document.createElement('canvas');
                        canvas.width = this.naturalWidth;
                        canvas.height = this.naturalHeight;

                        //next three lines for white background in case png has a transparent background
                        var ctx = canvas.getContext('2d');
                        ctx.fillStyle = '#fff';  /// set white fill style
                        ctx.fillRect(0, 0, canvas.width, canvas.height);

                        canvas.getContext('2d').drawImage(this, 0, 0);

                        resolve(canvas.toDataURL('image/jpeg'));
                    };

                    image.src = url;
                })
            }



            await setDoc(docRef, newItem).then(async () => {
                this.props.openSnack('success', item.key ? `${type} Saved!` : `${type} Saved!`)
                this.setState({ saving: false, })
                const userRef = doc(db, "users", userInfo.id, 'currentItems', 'currentFieldDoc');


                await setDoc(userRef, {
                    key: newItem.key
                })

                    .then(() => {
                        if (item.key === undefined) {

                            this.setState({ doc: newItem, testKey: newItem.key, saveWaterQualityData: true, savePhotos: true, status, wqData: false, createWaterQualityData: { status: true, key: item.tempKey } })
                        }
                        else {
                            this.setState({ doc: newItem, testKey: newItem.key, saveWaterQualityData: true, savePhotos: true, status })
                        }
                        if (status === 'built') {
                            window.scrollTo(0, 0);
                        }
                    })

            })

        }

    }


    updateWeather = (date) => {
        this.setState({ updateWeather: true, weatherDate: date })
    }



    render() {

        const { account, screenWidth, company, doc, userInfo, loading, preview, distributionList, accounts, saving, type, key, testKey, saveLog, logKey, createWaterQualityData,
            spinning, showDistribution, colorModal, modalIndex, printScreen, updateWeather, weatherDate, saveWaterQualityData, status, wqData } = this.state;





        const EmailLink = () => {
            let navigate = useNavigate();

            return (
                <Button style={{ textTransform: 'none', fontSize: 12, }} color="inherit" variant='outlined'
                    onClick={() => {
                        navigate('/emailApplication')
                    }}>Email</Button>
            )
        }
        return (


            <Row align="middle">

                <Col xs={16} sm={20} style={{ paddingLeft: screenWidth === 'xs' ? 10 : 30, paddingTop: screenWidth === 'xs' ? 20 : 30, paddingBottom: 15 }}>
                    <Typography variant="h4">Chemical Applications</Typography>
                </Col>
                <Col xs={6} sm={4} style={{ paddingTop: 30, paddingBottom: 15, textAlign: 'right' }}>

                </Col>
                <Col span={24}>
                    <Divider />
                </Col>
                <Col span={22} style={{ paddingTop: 10, }}>
                    <Breadcrumbs>
                        <Link to="/chemicalApplications" style={{ paddingLeft: screenWidth === 'xs' ? 10 : 30, textDecoration: 'none', cursor: 'pointer', color: 'inherit', }}>
                            Back to Applications
                        </Link>

                    </Breadcrumbs>
                </Col>


                {status === 'built' && (
                    <Col span={23} style={{ paddingLeft: screenWidth === 'xs' ? 10 : 30, paddingTop: 5, paddingBottom: 5 }}>
                        <Row gutter={[12, 12]} justify="end">
                            <Col>
                                <Button style={{ textTransform: 'none', fontSize: 12, }} color="inherit" variant='outlined'
                                    onClick={() => {
                                        this.updateStatus('creating')
                                    }}>Edit</Button>
                            </Col>


                            <EmailLink />

                            {wqData === true && (<ApplicationPDF emailDoc={null} testKey={testKey} startDate={doc.startDate} doc={doc} userInfo={userInfo} company={company} account={account} wqData={wqData}
                                type="export" doneSaving={() => this.setState({ saveLog: false })} saveLog={saveLog} />)}
                        </Row>
                    </Col>
                )}
                {status === 'creating' && (
                    <Col span={23} style={{ paddingLeft: screenWidth === 'xs' ? 10 : 30, paddingTop: 5, paddingBottom: 5 }}>
                        <Row gutter={[12, 12]} justify="end">
                            <Col>
                                <Button style={{ textTransform: 'none', fontSize: 12, }} color="inherit" variant='outlined'
                                    onClick={() => {
                                        this.saveLog('creating')
                                    }}>Save</Button>
                            </Col>
                            <Col>
                                {saving ? <CircularProgress /> : <Button style={{ textTransform: 'none', fontSize: 12, }} color="inherit" variant='outlined'
                                    onClick={() => {
                                        this.saveLog('built')
                                    }}>Save & Continue</Button>}
                            </Col>
                        </Row>
                    </Col>
                )}
                <Col span={23} style={{ paddingLeft: screenWidth === 'xs' ? 10 : 30, paddingTop: 15, paddingBottom: 30 }}>


                    <Paper elevation={12} style={{ borderRadius: 12, padding: screenWidth === 'xs' ? 20 : 40 }}>
                        <Row gutter={[12, 12]}  >
                            <Col {...fullMobile(12, 24)} style={{ position: 'relative', }}>

                                <Typography variant={screenWidth === 'xs' ? 'h6' : 'h4'} >Chemical Application</Typography>


                            </Col>






                            {loading === false && (
                                <Col {...fullMobile(12, 24)} >
                                    <Row gutter={[4, 4]} style={{ paddingTop: 20, paddingBottom: screenWidth === 'xs' ? 5 : 20 }}>

                                        <Col xs={24} style={{ textAlign: screenWidth === 'xs' ? 'left' : "right" }} >
                                            <Typography variant="h5">{company.companyName}</Typography>
                                        </Col>
                                        <Col xs={24} style={{ textAlign: screenWidth === 'xs' ? 'left' : "right" }} >
                                            <Typography fontWeight={500} variant="h5">{doc.account !== undefined ? doc.account.accountName : ''}</Typography>
                                        </Col>

                                        <Col xs={24} style={{ textAlign: screenWidth === 'xs' ? 'left' : "right" }} >
                                            <Typography variant="body1">{doc.account !== undefined ? doc.account.address : ''}</Typography>
                                        </Col>
                                        <Col xs={24} style={{ textAlign: screenWidth === 'xs' ? 'left' : "right" }} >
                                            <Typography variant="body1" style={{ textTransform: 'capitalize' }}>{`${doc.account !== undefined ? `${doc.account.countyName} County` : ''}`}</Typography>
                                        </Col>





                                    </Row>

                                </Col>
                            )}
                            <Col xs={24} >
                                <Divider style={{
                                    borderColor: 'rgb(148, 186, 195)',
                                    borderBottomWidth: 'medium',

                                }} />
                            </Col>
                            <Col xs={24} >
                                <Typography variant="h2" style={{ fontSize: 32, }}>General Info</Typography>
                            </Col>


                            {loading === false && doc.startDate !== undefined && (<Col {...fullMobile(12, 24)}    >
                                <CheckTimeWeather weather={doc.weather || {}} userInfo={userInfo} account={account} item={doc}
                                    updateWeather={updateWeather} weatherDate={weatherDate}
                                    weatherUpdated={this.weatherUpdated} doc={doc}
                                    updateDoc={this.updateDoc} screenWidth={screenWidth} date={doc.startDate} />

                            </Col>)}

                            {Object.values(doc).length > 0 && loading === false && (<Col {...fullMobile(12, 24)} style={{ paddingLeft: screenWidth === 'xs' ? 0 : 20, paddingTop: screenWidth === 'xs' ? 0 : 0, paddingRight: 20, paddingBottom: 20 }}>
                                <GeneralInfo account={account} company={company} preview={preview} item={doc} screenWidth={screenWidth}
                                    status={status} userInfo={userInfo} updateItem={this.updateDoc} updateEntireItem={this.updateEntireDoc}
                                    updateWeather={this.updateWeather} />

                            </Col>)}
                            <Col xs={24} >
                                <Divider style={{
                                    borderColor: 'rgb(148, 186, 195)',
                                    borderBottomWidth: 'medium',

                                }} />
                            </Col>
                            {Object.values(doc).length > 0 && (<Col {...fullMobile(24)} style={{ paddingBottom: 20 }}>
                                <WaterConditions account={account} key={key} testKey={testKey} saveWaterQualityData={saveWaterQualityData}
                                    screenWidth={screenWidth} userInfo={userInfo} openSnack={this.props.openSnack} status={status}
                                    doneSaving={() => {

                                        this.setState({ saveWaterQualityData: false })
                                    }} />

                            </Col>)}
                            <Col xs={24} >
                                <Divider style={{ 
                                    borderColor: 'rgb(148, 186, 195)',
                                    borderBottomWidth: 'medium',

                                }} />
                            </Col>
                            {Object.values(doc).length > 0 && (<Col {...fullMobile(24)} style={{ paddingBottom: 20 }}>
                                <ApplicationMeta account={account} key={key} testKey={testKey} saveWaterQualityData={saveWaterQualityData}
                                    screenWidth={screenWidth} userInfo={userInfo} openSnack={this.props.openSnack} status={status}
                                    doneSaving={() => {

                                        this.setState({ saveWaterQualityData: false })
                                    }} />

                            </Col>)}


                            <Col xs={24} >
                                <Divider style={{
                                    borderColor: 'rgb(148, 186, 195)',
                                    borderBottomWidth: 'medium',

                                }} />
                            </Col>



                            <Col {...fullMobile(24, 24)} style={{ paddingBottom: 20 }}>
                                <FLWaterQuality preview={preview} account={account} key={key} logKey={logKey} saveWaterQualityData={saveWaterQualityData} createWaterQualityData={createWaterQualityData}
                                    screenWidth={screenWidth} userInfo={userInfo} openSnack={this.props.openSnack} status={status}
                                    updateWQ={() => {
                                        this.setState({ wqData: true })
                                    }}
                                    doneSaving={() => {

                                        this.setState({ saveWaterQualityData: false })
                                    }}
                                />

                            </Col>

                            <Col xs={24} >
                                <Divider style={{
                                    borderColor: 'rgb(148, 186, 195)',
                                    borderBottomWidth: 'medium',

                                }} />
                            </Col>




                            {loading === false && (<Col {...fullMobile(24, 24)} style={{ paddingBottom: 20 }}>
                                <FLPhotos preview={preview} account={account} testKey={testKey} log={doc}
                                    screenWidth={screenWidth} userInfo={userInfo} openSnack={this.props.openSnack}
                                    saveWaterQualityData={saveWaterQualityData} status={status}
                                    doneSaving={() => {

                                        this.setState({ saveWaterQualityData: false })
                                    }} />

                            </Col>)}





                            <Col xs={24} >
                                <Divider style={{
                                    borderColor: 'rgb(148, 186, 195)',
                                    borderBottomWidth: 'medium',

                                }} />
                            </Col>

                            <Col xs={20} style={{ paddingTop: 10 }} >
                                <Typography variant="h2" style={{ fontSize: screenWidth === 'xs' ? 24 : 32, }}>Additional Notes</Typography>
                            </Col>
                            <Col xs={24} style={{ paddingTop: 10 }} >
                                {status === 'creating' ? <Typography variant="body1">{doc.fieldNotes}</Typography> :
                                    <Input fullWidth notched={false} label="none" placeholder={"Additional Notes"} size="large"
                                        disableUnderline={true}
                                        multiline={true}
                                        rows={4}
                                        value={doc.fieldNotes}
                                        onChange={(e) => {
                                            this.updateDoc(`fieldNotes`, e.target.value)
                                        }} />
                                }
                            </Col>

                            <Col span={24} style={{ paddingTop: 24, pddingBottom: 24 }}>
                                {status === 'creating' && (<Row align="middle" >
                                    <Col span={24} style={{ paddingTop: 18 }}>
                                        {saving ?
                                            <Row align="middle" justify='end' gutter={[18, 18]}>
                                                <Col sm={4}>
                                                    Saving ... <CircularProgress />
                                                </Col>
                                            </Row>
                                            :
                                            <Row align="middle" justify='end' gutter={[18, 18]}>

                                                <Col xs={24} sm={4}>
                                                    <Button variant='outlined' fullWidth color="primary" style={{ padding: 16, borderRadius: 12, textTransform: 'none', fontSize: 14, background: 'white' }} size="large"
                                                        onClick={() => {
                                                            this.saveLog('creating')
                                                        }}>Save Draft</Button>
                                                </Col>
                                                <Col xs={24} sm={6}>
                                                    <Button variant='contained' fullWidth color="primary" style={{ padding: 16, borderRadius: 12, textTransform: 'none', fontSize: 14, }} size="large"
                                                        onClick={() => {
                                                            this.saveLog('built')
                                                        }}>Save and Continue</Button>
                                                </Col>

                                            </Row>}
                                    </Col>

                                </Row>)}
                            </Col>

                        </Row>
                    </Paper>
                </Col>




            </Row >

        );
    }
} 