import { Button, CircularProgress, Divider, Paper, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Col, Row } from 'antd';
import { collection, doc, getDocs, getFirestore, query, setDoc, where } from 'firebase/firestore';
import moment from 'moment';
import MUIDataTable from "mui-datatables";
import React from 'react';
import { useNavigate } from 'react-router-dom';

export default class ServicesInventory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            data: [],
            filteredData: [],
            filters: [],
            searchText: '',
            loading: true,
            level: this.props.level,
            accounts: [],
            accountsLoad: true,
            services: [],
            loadingLog: undefined
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
            this.queryData()
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0)
        const { userInfo } = this.state;
        if (Object.values(userInfo).length === 0) {
            this.props.getUser()
        } else {

            this.queryAccounts()

        }

    }

    queryData = async (accounts, services) => {
        const { userInfo, level } = this.state;
        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'flItems'), where('psType', '==', 'services'));
        const queryAccountData = query(collection(db, "clients", userInfo.currentCompany, 'flItems'), where('psType', '==', 'services'), where('account', '==', userInfo.currentAccount));
        const data = [];
        const snap = await getDocs(level === 'account' ? queryAccountData : queryData);
        snap.forEach((doc) => {
            const account = doc.data().accountKey ? accounts.filter(b => b.key === doc.data().accountKey)[0]
                : accounts.filter(b => b.key === doc.data().account)[0];

            const service = doc.data().item !== undefined ? services.filter(b => b.key === doc.data().item)[0] :
                doc.data().product !== undefined ? services.filter(b => b.key === doc.data().product.key)[0] : services.filter(b => b.key === doc.data().itemKey)[0];

            const startDate = doc.data().timestamp !== undefined ? doc.data().timestamp : doc.data().time !== undefined ?
            moment(doc.data().time, "YYYY-MM-DD HH:mm").format('X') : doc.data().date;

            data.push({
                accountLabel: account ? account.accountName : 'N/A', serviceLabel: service ? service.name : 'N/A', startDate, Edit: doc.data().logkey || ''
            })
        });
        console.log(data)
        data.sort((a, b) => {
            return moment(a.startDate).isBefore(b.startDate) ? 1 : -1
        })
        this.setState({ data, loading: false })


    }

    queryAccounts = async () => {
        const { userInfo } = this.state;
        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'accounts'));
        const accounts = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            accounts.push({
                accountName: doc.data().accountName,
                key: doc.data().key,
                type: doc.data().seasonal || null,
                city: doc.data().city || null,
                countyName: doc.data().countyName || null,
                state: doc.data().state || null,
                routeDay: doc.data().routeDay || null,
                routeName: doc.data().routeName || null,
                visible: true
            })


        });
        accounts.sort((a, b) => a.accountName.localeCompare(b.accountName))
        this.setState({ accounts, accountsLoad: false })
        this.queryServices(accounts)



    }



    queryServices = async (accounts) => {
        const { userInfo, } = this.state;
        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'psItems'), where('psType', '==', 'services'),);
        const services = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            services.push(doc.data())


        });
        console.log(services)
        this.setState({ services, loading: false })
        this.queryData(accounts, services)


    }

    render() {

        const { userInfo, data, filteredData, loading, searchText, filters, level, account, accounts, accountsLoad, services, loadingLog } = this.state;
        const db = getFirestore();


        const XTable = ({ columns, header, data }) => {
            let navigate = useNavigate();
            const getMuiTheme = () =>
                createTheme({
                    components: {
                        MUIDataTable: {
                            styleOverrides: {
                                root: {
                                    padding: '8px',
                                    boxShadow: 'none'
                                }
                            }
                        },
                        MuiTableRow: {
                            styleOverrides: {
                                root: {
                                    zIndex: 100,
                                    cursor: 'pointer',
                                    fontSize: '10px',
                                    '&:nth-child(even)': {
                                        backgroundColor: '#eeeeee52'
                                    },
                                    "&:hover": {
                                        backgroundColor: "#9ec1c933"
                                    }


                                },


                            }
                        },
                        MuiTableCell: {
                            styleOverrides: {
                                root: {
                                    fontSize: '12px',
                                    padding: '2px'
                                }
                            }
                        }

                    }
                });



            const companyColumns = [


                {
                    name: 'startDate',
                    label: 'Date',
                    options: {
                        filter: false,
                        customBodyRender: this.renderValue = (a) => {
                            return (
                                moment(a, 'X').format('MM/DD/YYYY')
                            )
                        }
                    }
                },
                {
                    name: 'accountLabel',
                    label: 'Account',
                    options: {
                        filter: true,

                    },
                },
                {
                    name: 'serviceLabel',
                    label: 'Name',
                    options: {
                        filter: true,

                    },
                },


                {
                    label: 'Edit', name: 'Edit',
                    options: {

                        filter: false,
                        viewColumns: false,
                        customBodyRender: this.renderValue = (a) => {
                            if (loadingLog === a) {
                                return (
                                    <CircularProgress size={20} />
                                )
                            } else {
                                return (
                                    <Button style={{ textTransform: 'none', padding: 8, borderRadius: 24, fontSize: 10 }} variant="contained" color="primary" itemKey={a}
                                        onClick={async (e) => {
                                            this.setState({ loadingLog: a })
                                            e.stopPropagation();
                                            const db = getFirestore()
                                            const userRef = doc(db, "users", userInfo.id, 'currentItems', 'currentFieldDoc');
                                            await setDoc(userRef, {
                                                key: a
                                            }).then(() => {
                                                navigate("/newFieldLogHome");
                                            })
                                        }}>Edit</Button>
                                )
                            }

                        }
                    },
                },
            ]



            return (
                <ThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        title={''}
                        data={this.state.data}
                        columns={companyColumns}

                        options={{
                            textLabels: {
                                body: {
                                    noMatch: <div style={{}}>{loading ? "Loading Data" : "No Records Found"} {loading && (<CircularProgress />)}</div>
                                }
                            },
                            downloadOptions: {
                                filename: 'Services History'
                            },
                            print: false,
                            rowsPerPage: 40,
                            rowsPerPageOptions: [40, 80, 100],
                            selectableRows: false,
                            async onRowClick(a, b) {
                                console.log(a[a.length - 1].props.itemKey)

                                const itemKey = a[a.length - 1].props.itemKey;
                                console.log(itemKey)
                                const db = getFirestore()
                                const userRef = doc(db, "users", userInfo.id, 'currentItems', 'currentFieldDoc');
                                const userRefField = doc(db, "users", userInfo.id, 'currentItems', 'currentFieldLogNew');
                                await setDoc(userRefField, {
                                    key: itemKey
                                }).then(() => {

                                    navigate("/fieldLogNew");
                                }
                                )
                                



                            }

                        }}

                    />
                </ThemeProvider>
            );
        }


        return (
            <Row style={{ position: 'relative', top: 0 }}>
                <Col span={23}>
                    <Row align="middle">
                        <Col xs={16} sm={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>
                            <Typography variant="h4">Your Services History</Typography>
                        </Col>
                        <Col xs={6} sm={4} style={{ paddingTop: 30, paddingBottom: 15, textAlign: 'right' }}>

                        </Col>
                        <Col span={24}>
                            <Divider />
                        </Col>
                    </Row>
                </Col>



                <Col span={23} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 30 }}>


                    <Paper elevation={16} style={{ padding: 20, borderRadius: 12 }}>
                        <Row>

                            <Col span={24}>
                                {accountsLoad === false && (
                                    <XTable />
                                )}
                            </Col>
                        </Row>
                    </Paper>

                </Col>
            </Row>
        );
    }
} 