import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Drawer, Button, List, Divider, ListItem, ListItemButton, ListITemIcon, ListItemText, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { faArrowRightFromBracket, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DataEditorHeader = ({ openEditor, setOpenEditor }) => {





    return (
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={8} sm={true}> {/* Adjust the size as needed */}
                <Typography variant="body1" display="block" fontWeight={500} gutterBottom>
                    This is a BETA feature. Please use with caution. If there are too many data points the chart will not load. Try with a shorter timeframe.
                    This feature only works on node data at this time.
                </Typography>
            </Grid>
            <Grid item>
                <IconButton onClick={() => setOpenEditor(false)}>
                    <Tooltip title="Save" placement="bottom">
                        <FontAwesomeIcon size="xl" icon={faSave} style={{ color: '#3f51b5' }} />
                    </Tooltip>
                </IconButton>
            </Grid>
            <Grid item>
                <IconButton onClick={() => setOpenEditor(false)}>
                    <Tooltip title="Close" placement="bottom">
                        <FontAwesomeIcon size="xl" icon={faArrowRightFromBracket} style={{ color: 'rgba(0,0,0,0.5)' }} />
                    </Tooltip>
                </IconButton>
            </Grid>
        </Grid>

    );
}

export default DataEditorHeader;