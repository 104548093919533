import React, { useState } from "react";


import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import "react-datepicker/dist/react-datepicker.css";



const StaffSelect = (
    {
        staff,
        currentTask,
        setCurrentTask,
        headerKey

    }

) => {




    return (

        <Autocomplete
            fullWidth
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            size="small"
            notched={false}
            options={staff?.sort((a, b) => {
                // Convert to strings and lowercase
                const aValue = String(a.label).toLowerCase();
                const bValue = String(b.label).toLowerCase();

                // Perform the sorting
                if (aValue < bValue) return -1;
                if (aValue > bValue) return 1;
                return 0;
            })}
            defaultValue={currentTask?.[headerKey]}
            hiddenLabel={true}
            renderInput={(params) => <TextField {...params} placeholder="Assignee" variant="standard"
                sx={{
                    '& .MuiInputBase-root': {
                        color: 'black',
                        fontWeight: 500,
                        fontSize: 14,
                    },

                }} />}
            onChange={(e, b) =>
                setCurrentTask({ ...currentTask, [headerKey]: b })
            }

        />


    );
};

export default StaffSelect;
