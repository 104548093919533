import React from "react";
import { Input, Typography } from "@mui/material";
import moment from 'moment-timezone';
const DisplayString = ({
    currentStream,
    setCurrentStream,
    headerKey,
    options
}) => {
    const [value, setValue] = React.useState(currentStream?.[headerKey]);  // Renamed from 'age' to 'value'

 

    return (
        <Typography variant="body2" sx={{ fontWeight: 500, fontSize: 14, color: 'black' }}>
            {value ? moment(value, 'x').format('LLLL') : 'None'}
        </Typography>
    );
};

export default DisplayString;
