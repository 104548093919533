import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SearchIcon from '@mui/icons-material/Search';
import { Button, CircularProgress, Divider, Fab, IconButton, Input, InputAdornment, Paper, Typography } from '@mui/material';
import { Col, Popconfirm, Row } from 'antd';
import { collection, deleteDoc, doc, getFirestore, onSnapshot, query, where } from 'firebase/firestore';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import "../../extra/hoverStyle.css";

import GaugeModal from './gaugeModal';
import axios, * as others from 'axios';
import queryString from 'query-string';
var moment = require('moment-timezone');



export default class RainGauges extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            data: [],
            filteredData: [],
            filters: [],
            searchText: '',
            loading: true,
            type: this.props.type,
            currentItem: {},
            openModal: false,
            nextPage: '',
            token: '',
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0)
        this.queryData()






    }





    queryData = async () => {
        const { userInfo, type, loading } = this.state;
        const db = getFirestore();

        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'rainGauges'), where('account', '==', userInfo.currentAccount),);
        const unsubscribe = onSnapshot(queryData, async (querySnapshot) => {

            const data = [];
            querySnapshot.forEach((doc) => {
                data.push({ ...doc.data(), label: doc.data().name })
            });
            data.sort((a, b) => a.name.localeCompare(b.name))

            this.setState({ data, loading: false })

            console.log(data);

            /* data.map((d) => {
                console.log(d)
                const proxyUrl = "https://mycorslake.herokuapp.com/";
                const apiToken = 'kbWXuKcbrYjwGBpZUWFigDdjhSwwwvuy';
                const endpoint = `data`
                const datasetid = "GHCND"
                const baseUrl = `https://www.ncei.noaa.gov/cdo-web/api/v2/${endpoint}`;

                const times = [0, 2, 3, 4, 5, 6, 7]
                times.map(async (hours, i) => {

                    if (i !== 0) {

                        setTimeout(async () => {
                            const response = await axios.get(proxyUrl + baseUrl, {
                                headers: {
                                    'token': apiToken
                                },
                                params: {
                                    units: 'standard',
                                    datasetid: datasetid,
                                    stationid: d.id,
                                    startdate: moment().subtract(hours, 'days').startOf('day').format('YYYY-MM-DD'),
                                    enddate: moment().subtract(times[i - 1], 'days').endOf('day').format('YYYY-MM-DD'),
                                }



                            })
                                .then((response) => {

                                    return response
                                }
                                )
                                .catch((error) => {
                                    console.log(error)
                                })


                            const dataItems = [];

                            console.log(response)

                            if (response.data !== undefined && response.data.results !== undefined) {

                                response.data.results.map((item) => {

                                    var time = moment(item.date, 'YYYY-MM-DD HH:mm:ss').tz("America/Los_Angeles").format("YYYY-MM-DD HH:mm");
                                    dataItems.push({
                                        time: `'${time}'`,
                                        account: `'${userInfo.currentAccount}'`,
                                        company: `'${userInfo.currentCompany}'`,
                                        key: `'${uuid()}'`,
                                        datatype: `'${item.datatype}'`,
                                        station: `'${item.station}'`,
                                        attributes: `'123'`,
                                        value: Number(item.value),
                                        lat: d.lat,
                                        lng: d.lng

                                    })
                                })

                                console.log(dataItems)


                                const hourlyVals = dataItems.map((h) => `(${Object.values(h)})`);
                                const hourlyString = hourlyVals.join();
                                console.log(hourlyString)


                                axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                                    raw: `INSERT INTO gauge_data VALUES ${hourlyString} ON CONFLICT DO NOTHING; `
                                })
                                    .then(async (response) => {
                                        // handle success
                                        console.log(response)

                                    }).catch((error) => {
                                        // handle error
                                        console.log(error);
                                    })

                            }
                        }, 1000 * i);




                    }





                })



            }) */












            this.filterData(data, '', [])
        })






    }

    filterData = (data, searchText, filters) => {
        const filteredData = data.filter(d => d.name.match(new RegExp(searchText, "i")))
        this.setState({ filteredData, loading: false, })

    }


    updateCurrentItem = (boo, val) => {
        const { currentItem } = this.state;
        currentItem[boo] = val;
        this.setState({ currentItem })
    }



    render() {

        const { userInfo, account, data, filteredData, loading, searchText, filters, type, currentItem, accounts, openModal, screenWidth, } = this.state;
        const db = getFirestore();

        const CreateItemButton = () => {
            let navigate = useNavigate();

            return (
                <Fab style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="medium"
                    onClick={async () => {
                        this.setState({ openModal: true, currentItem: { tempKey: uuid(), depthFromBottom: 0 }, })
                    }}>
                    <AddIcon />
                </Fab>
            )
        }

        const EditItem = ({ item }) => {
            let navigate = useNavigate();
            return (
                <IconButton style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="small"
                    onClick={async () => {
                        this.setState({ openModal: true, currentItem: item })
                    }}>
                    <EditIcon />
                </IconButton>
            )
        }

        const DeleteItem = ({ item }) => {
            let navigate = useNavigate();
            return (
                <Popconfirm
                    title="Are you sure to delete this site?"
                    onConfirm={async () => {


                        await deleteDoc(doc(db, "clients", userInfo.currentCompany, 'rainGauges', item.key)).then(() => {
                            const newData = filteredData.filter((f) => f.key !== item.key)
                            this.setState({ filteredData: newData })
                            this.props.openSnack('success', 'Gauge removed.')
                        })



                    }}
                    okText="Yes"
                    cancelText="No"
                >
                    <IconButton style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="small"
                    >
                        <DeleteForeverIcon />
                    </IconButton>
                </Popconfirm>


            )
        }

        const RowItem = ({ item }) => {
            return (
                <Col span={24} style={{ cursor: 'pointer', padding: 4 }} className="rowHover"
                >
                    <Row gutter={[8, 8]} align="middle" >

                        <Col xs={0} sm={10} onClick={async () => {
                            this.setState({ currentItem: item, openModal: true })
                        }}>
                            <Typography fontWeight={500} variant='subtitle1'>{`${item.name} (${item.id})`}</Typography>


                        </Col>

                        <Col xs={10} sm={4} style={{ textAlign: 'right' }}>
                            <EditItem item={item} /> <DeleteItem item={item} />
                        </Col>


                        <Col span={24}>
                            <Divider />
                        </Col>

                    </Row>
                </Col>
            )
        }

        const ReturnToDataStreams = () => {
            let navigate = useNavigate()
            return (
                <Button size="small" style={{
                    padding: 10, borderRadius: 10, display: 'flex', flexDirection: 'column', justifyContent: 'space-between',
                    alignItems: 'center',
                    justifyContent: 'center', display: 'flex', cursor: 'pointer',
                    backgroundColor: '#404050', color: '#fff', fontWeight: 700,
                    transition: 'box-shadow 400ms, transform 400ms',
                    boxShadow: 'inset 0 0 0 1px #d9d9d9',
                    transform: 'scale(1)',
                    transformOrigin: 'center',
                    textTransform: 'none'
                }}

                    variant='contained'
                    onClick={async () => {
                        navigate('/dataStreams')
                    }}>Return to Data Streams</Button>


            )
        }




        return (
            <Row style={{}} align="middle">
                <Col span={23}>
                    <Row align="middle">
                        <Col xs={16} sm={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>
                            <Typography variant="h4">Rain Gauges</Typography>
                        </Col>
                        <Col xs={6} sm={4} style={{ paddingTop: 30, paddingBottom: 15, textAlign: 'right', position: 'relative', zIndex: 0 }}>
                            {loading ? <CircularProgress /> : <CreateItemButton />}
                        </Col>
                        <Col span={24}>
                            <Divider />
                        </Col>
                        <Col span={24} style={{ paddingLeft: 30, paddingBottom: 15, paddingTop: 15 }}>
                            <ReturnToDataStreams />
                        </Col>
                        <Col span={24} style={{ paddingLeft: 30, }}>
                            <Typography variant='body1'>Link to find public gauges: <a
                                href="https://www.ncdc.noaa.gov/cdo-web/datatools/findstation" target="_blank">Gauges</a></Typography>
                        </Col>
                    </Row>
                </Col>

                <Col span={23} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 30 }}>
                    {loading ? <CircularProgress /> :
                        <Paper elevation={16} style={{ padding: 20, borderRadius: 12 }}>
                            <Row>
                                <Col xs={24} sm={12} style={{ paddingBottom: 8 }}>
                                    <Input fullWidth
                                        autoComplete='off'
                                        value={searchText}
                                        onChange={(e) => {
                                            this.setState({ searchText: e.target.value })
                                            if (e.target.value.length > 1) {
                                                this.filterData(data, e.target.value, filters)
                                            }
                                            else {
                                                this.filterData(data, '', filters)
                                            }
                                        }}
                                        id="input-with-icon-adornment"
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        }
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    edge="end"
                                                    onClick={() => {
                                                        this.setState({ searchText: '' })
                                                        this.filterData(data, '', filters)
                                                    }}
                                                >
                                                    <HighlightOffIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </Col>
                                <Col xs={0} sm={12} style={{ textAlign: 'right' }}>
                                    <Typography variant='h6'>Actions</Typography>
                                </Col>

                                <Col span={24}>
                                    <Row align='middle' >
                                        {filteredData.map((d) => {
                                            return (
                                                <RowItem item={d} />

                                            )

                                        })}
                                    </Row>
                                </Col>
                            </Row>
                        </Paper>
                    }
                </Col>

                <GaugeModal openModal={openModal} account={account} userInfo={userInfo} openSnack={this.props.openSnack}
                    screenWidth={screenWidth} onClose={() => { this.setState({ openModal: false, currentItem: {} }) }} currentItem={currentItem} />




            </Row >
        );
    }
} 