import { Button, ButtonGroup, CircularProgress, Divider, Typography, OutlinedInput, Autocomplete, TextField, Select, MenuItem, Checkbox } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { fullMobile } from '../../dashboard/settings/util';
import { Col, Modal, Popconfirm, Row } from 'antd';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { SearchOutlined } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, orderBy, limit, writeBatch, onSnapshot } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import DataGrid, { SelectColumn, textEditor, Column, CheckboxFormatterProps, SortColumn, SortStatusProps } from 'react-data-grid';
import { useRef } from 'react';
import { useEffect } from 'react';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

function getComparator(sortColumn) {
    switch (sortColumn) {
        case 'name':
            return (a, b) => a.name.localeCompare(b.name);
        case 'url':
            return (a, b) => a.url.localeCompare(b.url);

        default:
            throw new Error(`unsupported sortColumn: "${sortColumn}"`);
    }
}

function ProtectedCheckboxFormatter({ value, onChange }) {
    return (
        <Checkbox
            checked={value}
            onChange={(e) => onChange(e.target.checked)}
        />
    );
}

const PublicPortalHome = ({ userInfo, openSnack, account }) => {
    const [loading, setLoading] = useState(false);
    const [sortColumns, setSortColumns] = useState([]);
    const [data, setData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRowsKeys, setSelectedRowsKeys] = useState([]);
    const [currentButton, setCurrentButton] = useState('portals')
    const gridRef = useRef(null);


    //get portals from db
    useEffect(() => {
        const db = getFirestore();
        const q = query(collection(db, "publicPortals"), where("account", "==", userInfo.currentAccount));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const portals = [];
            querySnapshot.forEach((doc) => {
                portals.push({ ...doc.data(), id: doc.id });
            });
            setData(portals);
            setLoading(false);
        });
        return unsubscribe;
    }, [userInfo.currentAccount]);



    const addPortalRow = async () => {
        const key = uuid()



        //create new portal in db
        const db = getFirestore();
        const newPortal = {
            name: `${account.accountName} Portal ${data.length + 1}`,
            url: '',
            protected: false,
            password: '',
            id: key,
            key,
            account: userInfo.currentAccount,
            company: userInfo.currentCompany,
        }
        setData([...data, newPortal]);
        const docRef = doc(db, "publicPortals", key);



        const layoutString = JSON.stringify([]);
        const widgetsString = JSON.stringify([]);
        var somevalue = widgetsString.substring(0, Math.floor(widgetsString.length / 2)); // seperates string from 10 characters 
        var lessthan = widgetsString.substring(Math.floor(widgetsString.length / 2), widgetsString.length); // seperates string from 10 characters negative

        await setDoc(docRef, {
            ...newPortal,
            layout: layoutString, widgets: somevalue, widgets1: lessthan,
        })




    };

    const updatePortal = (index, key, value) => {
        console.log(index, key, value)
        const newData = [...data];
        newData[index][key] = value;
        setData(newData);

        //update portal in db
        const db = getFirestore();
        const docRef = doc(db, "publicPortals", newData[index].id);
        updateDoc(docRef, {
            [key]: value,
        });





    };

    const db = getFirestore();
    const navigate = useNavigate();

    const sortedRows = useMemo(() => {
        if (sortColumns.length === 0) return data;

        return [...data].sort((a, b) => {
            for (const sort of sortColumns) {
                const comparator = getComparator(sort.columnKey);
                const compResult = comparator(a, b);
                if (compResult !== 0) {
                    return sort.direction === 'ASC' ? compResult : -compResult;
                }
            }
            return 0;
        });
    }, [data, sortColumns]);





    const columns = [
        SelectColumn,
        {
            key: 'name',
            name: 'Name',
            resizable: true,
            sortable: true,
            editor: ({ row, column, onRowChange }) => {
                return (
                    <input
                        //make this 100% width and full width of parent
                        style={{
                            width: '100%',
                            height: '100%',
                            border: 'none',
                            outline: 'none',
                            padding: '0px',
                            margin: '0px',

                        }}
                        autoFocus={true}
                        type="text"
                        className="rdg-text-editor"
                        defaultValue={row[column.key]}

                        onBlur={
                            (e) => {
                                onRowChange({ ...row, [column.key]: e.target.value });
                                const index = data.findIndex((item) => item.id === row.id);
                                updatePortal(index, column.key, e.target.value)
                            }
                        }
                    />
                )
            },


            //onchange event for the column


        },
        {
            key: 'url',
            name: 'Url*',
            resizable: true,

            editor: ({ row, column, onRowChange }) => {
                const handleInputChange = (e) => {
                    const regex = /^[a-zA-Z0-9_-]*$/; // regular expression to match only letters, numbers, underscores, and hyphens
                    //check if url matches any other urls in the db
                    const urlExists = data.find((item) => item.url === e.target.value && item.id !== row.id)

                    if (regex.test(e.target.value) && !urlExists) { // if input value matches the regex pattern
                        onRowChange({ ...row, [column.key]: e.target.value });
                        const index = data.findIndex((item) => item.id === row.id);
                        updatePortal(index, column.key, e.target.value)
                    }

                    if (urlExists) {
                        openSnack('error', 'Url already exists')
                    }
                }

                return (
                    <input
                        style={{
                            width: '100%',
                            height: '100%',
                            border: 'none',
                            outline: 'none',
                            padding: '0px',
                            margin: '0px',
                        }}
                        autoFocus={true}
                        value={row[column.key]}
                        type="text"
                        className="rdg-text-editor"
                        defaultValue={row[column.key]}
                        onChange={handleInputChange}
                    />
                )
            },
        },

        //onchange event for the column

        {
            key: 'password',
            name: 'Password',
            resizable: true,
            editor: ({ row, column, onRowChange }) => {
                return (
                    <input
                        style={{
                            width: '100%',
                            height: '100%',
                            border: 'none',
                            outline: 'none',
                            padding: '0px',
                            margin: '0px',
                        }}
                        autoFocus={true}
                        type="text"
                        className="rdg-text-editor"
                        defaultValue={row[column.key]}
                        onBlur={
                            (e) => {
                                onRowChange({ ...row, [column.key]: e.target.value });
                                const index = data.findIndex((item) => item.id === row.id);
                                updatePortal(index, column.key, e.target.value)
                            }
                        }
                    />
                )
            },
            sortable: false
            //onchange event for the column


        },
        {
            key: 'protected',
            name: 'Password Protected',
            resizable: false,
            sortable: false,
            formatter: ({ row, column }) => (
                <ProtectedCheckboxFormatter
                    value={row[column.key]}
                    onChange={(newValue) => {
                        // Handle checkbox change, e.g., update the state

                        const newData = [...data];
                        const index = newData.findIndex((item) => item.id === row.id);
                        newData[index][column.key] = newValue;
                        setData(newData);
                        //update portal in db
                        updatePortal(index, column.key, newValue)


                    }}
                />
            ),
        },
        {
            key: 'preview',
            name: 'Preview',
            resizable: false,
            sortable: false,
            formatter: ({ row, column }) => (
                <Button
                    variant="contained"
                    color="primary"
                    disabled={row.url === '' ? true : false}
                    size="small"
                    style={{
                        textTransform: 'none',
                        fontSize: '0.8rem',
                        color: 'white',
                        backgroundColor: row.url === '' ? 'grey' : '#404050',


                    }}

                    onClick={() => {

                        navigate(`/publicPortals/${row.account}/${row.url}`)

                    }
                    }
                >
                    Preview
                </Button>
            ),

        },
        {
            key: 'copyLink',
            name: 'Link',
            resizable: false,
            sortable: false,
            formatter: ({ row, column }) => (
                <Button
                    variant="contained"
                    color="primary"
                    disabled={row.url === '' ? true : false}
                    size="small"
                    style={{
                        textTransform: 'none',
                        fontSize: '0.8rem',
                        color: 'white',
                        backgroundColor: row.url === '' ? 'grey' : '#404050',


                    }}

                    onClick={() => {

                        //copy the link to the clipboard /publicPortals/${row.account}/${row.url}
                        const link = `https://www.my.lakeTech.com/publicPortals/${row.account}/${row.url}`;
                        navigator.clipboard.writeText(link);
                        openSnack('success', 'Link copied to clipboard')

                    }
                    }
                >
                    <FontAwesomeIcon icon={faCopy} />
                </Button>
            ),

        },
        {
            key: 'delete',
            name: 'Delete',
            resizable: false,
            sortable: false,
            formatter: ({ row, column }) => (
                <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    style={{
                        textTransform: 'none',
                        fontSize: '0.8rem',

                    }}

                    onClick={() => {
                        const newData = [...data];
                        const index = newData.findIndex((item) => item.id === row.id);
                        newData.splice(index, 1);
                        setData(newData);
                        //delete portal from db
                        const db = getFirestore();
                        const docRef = doc(db, "publicPortals", row.id);
                        deleteDoc(docRef);
                    }
                    }
                >
                    Delete
                </Button>
            ),

        },

    ]


    function rowKeyGetter(row) {
        return row.id;
    }
    //create array from selectedRows the data is like this Set(3) {0, 3, 4}[[Entries]]size: 3
    //so we need to convert it to an array

    function checkboxFormatter(
        { onChange, ...props }, ref
    ) {
        function handleChange(e) {
            onChange(e.target.checked, (e.nativeEvent).shiftKey);
        }

        return <input type="checkbox" ref={ref} {...props} onChange={handleChange} />;
    }

    function sortStatus({ sortDirection, priority }) {
        return (
            <>
                {sortDirection !== undefined ? (sortDirection === 'ASC' ? '\u2B9D' : '\u2B9F') : null}
                <span style={{
                    color: 'grey',
                    marginLeft: '2px'
                }}>{priority}</span>
            </>
        );
    }

    function rowHeight(p) {
        // should be based on the content of the row
        const rowHeight = 40;
        return rowHeight;
    }

    return (
        <div style={{ fontFamily: 'Roboto, sans-serif', backgroundColor: '#f6f8fe', minHeight: '100vh' }}>
            <Row  >



                <Col {...fullMobile(24, 24)} style={{
                    paddingTop: 30,
                    paddingLeft: 30,
                    paddingRight: 30,
                    overflow: 'auto',


                }}>
                    <Row align="middle" >
                        <Col {...fullMobile(20, 20)}>
                            <Typography variant='h3' fontWeight={500} fontSize={36}>Portals</Typography>
                            <Typography style={{ paddingTop: 5 }} variant='h5' fontWeight={300} fontSize={18}>Your portals as part of this site.</Typography>

                        </Col>
                        <Col {...fullMobile(4, 4)} style={{
                            textAlign: 'right'

                        }}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                style={{
                                    textTransform: 'none',
                                    fontSize: '0.8rem',
                                    backgroundColor: '#404050',
                                    color: 'white',
                                    fontWeight: 500,
                                    borderRadius: 5,
                                }}
                                onClick={addPortalRow}>
                                Add Portal
                            </Button>

                        </Col>

                        <Col {...fullMobile(24, 24)} style={{ paddingTop: 10, paddingBottom: 10 }}>
                            <Divider />
                        </Col>
                        <Col span={6} style={{ padding: 8, paddingLeft: 20, textAlign: 'right' }}>




                        </Col>


                        <Col span={24} style={{ padding: 8, paddingLeft: 30, textAlign: 'right' }}>







                            {selectedRowsKeys?.length > 0 && (
                                <Popconfirm
                                    title="Are you sure to delete these portals?"
                                    onConfirm={async () => {
                                        //get keys from data for selectedRowsList

                                        //delete from data
                                        const newData = data.filter((d) => {
                                            return !selectedRowsKeys.includes(d.key)
                                        })
                                        setData(newData)
                                        //delete from db
                                        const db = getFirestore();
                                        const batch = writeBatch(db);
                                        selectedRowsKeys.forEach((key) => {
                                            const docRef = doc(db, "publicPortals", key);
                                            batch.delete(docRef);
                                        })
                                        await batch.commit();


                                        //update selectedRowsKeys

                                        setSelectedRowsKeys([])
                                        setSelectedRows([])


                                    }
                                    }>

                                    <Button variant="contained" color="primary" size="small" display="inline"
                                        style={{ textTransform: 'none', border: '1px solid red', backgroundColor: 'red', color: 'white', marginLeft: 10 }}
                                    >Delete</Button>
                                </Popconfirm >

                            )}

                        </Col>
                    </Row>

                </Col>
                <Col {...fullMobile(24, 24)} style={{ backgroundColor: '#f6f8fe', paddingLeft: 30, paddingRight: 30 }}>
                    <DataGrid
                        rowHeight={rowHeight}

                        ref={gridRef}
                        style={{ minHeight: '100vh' }}
                        columns={columns}
                        rows={sortedRows}
                        selectedRows={new Set(selectedRows)}
                        renderers={{ sortStatus, checkboxFormatter }}
                        onSelectedRowsChange={(rows) => {


                            //change set to array and get keys from data
                            const selectedRowsKeys = Array.from(rows).map((r) => {
                                return data.find((d, i) => d.id === r)?.key
                            })
                            setSelectedRowsKeys(selectedRowsKeys)
                            setSelectedRows(rows);

                        }}
                        onSortColumnsChange={setSortColumns} // Add this line

                        rowKeyGetter={rowKeyGetter}
                        onRowClick={(row) => {

                        }}
                        defaultColumnOptions={{
                            sortable: true,
                            textwrap: 'wrap',
                        }}
                        enableVirtualization={true}
                        sortColumns={sortColumns}
                        onRowsChange={(rows) => {



                            setData(rows);


                        }}






                    />
                </Col>

















            </Row >
        </div >

    );
};

export default PublicPortalHome;
