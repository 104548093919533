import { Autocomplete, Button, Checkbox, Collapse, Divider, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material';
import { Row, Col } from 'antd';
import React, { useEffect, useState } from 'react';
import { ReactSortable } from 'react-sortablejs';
import uuid from 'react-uuid';
import { fullMobile } from '../../util';
import AddIcon from '@mui/icons-material/Add';
import { DeleteForever, DragIndicator } from '@mui/icons-material';
import { faVial, faLocationCrosshairs, faFillDrip, faChartArea, faChartBar, faChartLine, faChartColumn, faGear } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataColorCompact from '../settings/dataColorCompact';
import moment from 'moment-timezone';
import DatePicker from "react-datepicker";

var randomColor = require('randomcolor');

export default function MapSettings(props) {
    const { userInfo, screenWidth, openSnack, widgetRef, updateCurrentWidget, nodes, PPArray, parameters, WPArray, references } = props;
    const [widget, setWidget] = useState(widgetRef);





    useEffect(() => {
        console.log(widgetRef)
    }, [])
    useEffect(() => {
        setWidget(widgetRef)
    }, [widgetRef])






    return (
        <Row gutter={[4, 4]}
        >

            <Col   >
                <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                    Show Probes
                </Typography>
                <Checkbox
                    checked={widget.showProbes}
                    onChange={(e) => {
                        updateCurrentWidget({ ...widget, showProbes: e.target.checked })
                    }}
                />

            </Col>
            <Col >
                <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                    Show Stations
                </Typography>
                <Checkbox
                    checked={widget.showStations}
                    onChange={(e) => {
                        updateCurrentWidget({ ...widget, showStations: e.target.checked })
                    }}
                />

            </Col>
            









        </Row>
    );
}