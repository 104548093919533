import { collection, collectionGroup, doc, getCountFromServer, getDocs, getFirestore, query, setDoc, where, writeBatch } from "firebase/firestore"
import uuid from "react-uuid";
import Pica from 'pica';
import { getStorage, ref, getMetadata, getDownloadURL, uploadBytes, uploadBytesResumable } from "firebase/storage";
import moment from 'moment-timezone';

export const updateItemsFuction = async (type, itemsToUpdate, userInfo, openSnack) => {
    try {
        const db = getFirestore();
        const batch = writeBatch(db);
        // Queue all update operations in the batch
        itemsToUpdate.forEach((item) => {
            const itemRef = doc(db, "clients", userInfo.currentCompany, type === 'folder' ? "folders_new" : 'files_new', type === 'folder' ? item.folder_id : item.file_id);
            batch.set(itemRef, item, { merge: true });
        });
        // Commit the batch
        await batch.commit();



    }
    catch (e) {
        openSnack('error', 'Error updating folder')
    }
}

export const deleteItemsFunction = async (type, itemsToDelete, userInfo, openSnack) => {
    try {

        const db = getFirestore();
        const batch = writeBatch(db);

        // Queue all delete operations in the batch
        itemsToDelete.forEach((id) => {
            const fileRef = doc(db, "clients", userInfo.currentCompany, type === 'folder' ? "folders_new" : 'files_new', id);
            batch.update(fileRef, { status: 'deleted', deleted_date: new Date() });
        });

        // Commit the batch
        await batch.commit();



        openSnack('success', `${itemsToDelete.length} file(s) deleted successfully.`);
    } catch (e) {
        openSnack('error', 'Error deleting file(s).');
    }
};

export async function fetchParentFolderFunction(parent_id, userInfo, openSnack) {
    try {
        const db = getFirestore();
        const ref = collection(db, "clients", userInfo.currentCompany, 'folders_new');

        let queryRef;
        queryRef = query(ref, where("account", "==", userInfo.currentAccount), where("folder_id", "==", parent_id));


        const snap = await getDocs(queryRef);
        const data = snap.docs.map((doc) => doc.data());

        openSnack('success', 'Data retrieved successfully');

        return data[0];

    } catch (error) {
        console.log(error)
        console.error("Error updating document: ", error);
        openSnack('error', 'Error retrieving data');

        return [];
    }
};



// This function will be responsible for handling photo uploads and Firestore updates
export const mergeOldFoldersFunction = async (userInfo, openSnack) => {
    try {
        const db = getFirestore();
        const ref = collectionGroup(db, "folders");

        let queryRef;
        queryRef = query(ref, where("account", "==", userInfo.currentAccount));


        const snap = await getDocs(queryRef);
        const newAlbums = snap.docs.map((doc) => doc.data());

        // Fetch and process each photo
        const processedPhotos = await Promise.all(newAlbums.map(async (album) => {
            // Fetch the image from the URL
            try {

                const albumData = {
                    account: album.account || userInfo.currentAccount,
                    folder_id: album.key || album.id,
                    company: album.company || userInfo.currentCompany,
                    created_date: Number(moment().format('x')),
                    isPublic: false,
                    tags: [],
                    coverPhoto: null,
                    status: 'active',
                    creator: userInfo.id,
                    parent_folder_id: null,
                    name: album.name || null,
                    description: album.description || null,
                };

                console.log(albumData)

                // Update Firestore document

                const albumRef = doc(db, "clients", userInfo.currentCompany, "folders_new", albumData.folder_id);
                await setDoc(albumRef, albumData);



                return 'success';
            }
            catch (e) {
                console.log(e)
            }
        }));




        openSnack('success', 'Photos uploaded and created successfully');
        return 'success';
    } catch (e) {
        console.log(e)
        openSnack('error', 'Error creating photos');
        return 'error'
    }
};











// This function will be responsible for handling photo uploads and Firestore updates
export const mergeOldFilesFunction = async (userInfo, openSnack) => {
    try {
        const db = getFirestore();
        const ref = collectionGroup(db, "files");

        let queryRef;
        queryRef = query(ref, where("account", "==", userInfo.currentAccount));


        const snap = await getDocs(queryRef);
        const newPhotos = snap.docs.map((doc) => doc.data());

        console.log(newPhotos)

        // Fetch and process each photo
        const processedPhotos = await Promise.all(newPhotos.map(async (photo) => {
            // Fetch the image from the URL
            try {

                const photoData = {
                    account: photo.account || userInfo.currentAccount,
                    company: photo.company || userInfo.currentCompany,
                    created_date: photo.timestamp || Number(moment().format('x')),
                    is_public: true,
                    tags: [],
                    status: 'active',
                    creator: photo.fileCreator || userInfo.id,
                    parent_folder_id: photo.albumKey || photo.parent || null,
                    name: photo.name || photo.fileName || null,
                    type: photo.type || 'image/jpeg',
                    size: photo.size || null,
                    path: photo.fileLink || photo.downloadURL || photo.url || photo.downloadUrl || null,
                    file_id: photo.key || photo.id,
                    fileType: 'application/pdf'
                }

                // Update Firestore document

                console.log(photoData)

                const photoRef = doc(db, "clients", userInfo.currentCompany, "files_new", photoData.file_id);
                await setDoc(photoRef, photoData);

                return 'success';
            }
            catch (e) {
                console.log(e)
            }
        }));




        openSnack('success', 'Photos uploaded and created successfully');
        return 'success';
    } catch (e) {
        console.log(e)
        openSnack('error', 'Error creating photos');
        return 'error'
    }
};
