import { collection, getDocs, getDoc, query, where, addDoc, updateDoc, doc, getFirestore, deleteDoc, writeBatch, setDoc, Timestamp } from 'firebase/firestore';
import { getStorage, ref, getMetadata } from "firebase/storage";
import uuid from 'react-uuid';


export const getMapInfo = async (company, key, setCenter, setZoom, setLayers, setLoading) => {
    const db = getFirestore();

    try {
        const mapRef = doc(db, 'clients', company, 'widgetMaps', key);
        const mapSnapshot = await getDoc(mapRef);
        if (mapSnapshot.exists()) {
            const mapData = mapSnapshot.data();
            setCenter(mapData.mapCenter);
            setZoom(mapData.mapZoom);
            setLayers(mapData.layers || []);
            setLoading(false)
            return mapData;

        } else {
            return null;
        }

        // Add your Firestore code here
    } catch (error) {
        // Handle error
        return null;
    }
};
export const createMap = async (company, key, account) => {
    const db = getFirestore();

    try {
        const mapRef = doc(db, 'clients', company, 'widgetMaps', key);
        await setDoc(mapRef, {
            mapCenter: {
                lat: account.lat,
                lng: account.lng,
            },
            mapZoom: 18,
            layers: []
        });
        return true;



        // Add your Firestore code here
    } catch (error) {
        // Handle error
        return null;
    }
};

export const updateMapCenter = async (map, updateMap, company, key) => {
    const db = getFirestore();

    try {
        const newCenter = map.getCenter();
        const newZoom = map.getZoom();
        const center = {
            lat: newCenter.lat(),
            lng: newCenter.lng(),
        };

        updateMap(center, newZoom);
        const mapRef = doc(db, 'clients', company, 'widgetMaps', key);
        await updateDoc(mapRef, {
            mapCenter: center,
            mapZoom: newZoom,
        });


        // Add your Firestore code here
    } catch (error) {
        console.log(error)
        // Handle error
    }
};

export const updateInitialMapAttributes = (attributes, setCenter, setZoom) => {
    if (attributes.mapCenter) {
        setCenter({
            lat: attributes.mapCenter.lat,
            lng: attributes.mapCenter.lng,
        });
    }
    if (attributes.mapZoom) {
        setZoom(attributes.mapZoom);
    }
};

export const handleCurrentLocation = (setCenter, updateMapCenter, handleMapUpdate, userInfo, widgetKey) => {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const currentLocation = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };
                setCenter(currentLocation);
                updateMapCenter(null, handleMapUpdate, userInfo.currentCompany, widgetKey);
            },
            (error) => {
                console.error('Error obtaining current location:', error);
            },
        );
    } else {
        console.error('Geolocation is not supported by this browser.');
    }
};

export const getLayers = async (setLayers, company, key) => {
    const db = getFirestore();

    try {
        const mapRef = doc(db, 'clients', company, 'widgetMaps', key);
        const mapSnapshot = await getDoc(mapRef);
        if (mapSnapshot.exists()) {
            const mapData = mapSnapshot.data();
            setLayers(mapData.layers || []);
            return mapData;

        } else {
            return null;
        }

        // Add your Firestore code here
    } catch (error) {
        // Handle error
        return null;
    }
};


export const createLayerItem = async (company, widgetKey, layerId, setLayers, length) => {
    const db = getFirestore();

    try {
        const mapRef = doc(db, 'clients', company, 'widgetMaps', widgetKey);
        const mapSnapshot = await getDoc(mapRef);

        if (mapSnapshot.exists()) {
            const mapData = mapSnapshot.data();
            const layers = mapData.layers || [];

            const layerIndex = layers.findIndex((layer) => layer.id === layerId);

            if (layerIndex !== -1) {
                const newItem = {
                    id: uuid(),
                    name: `Item`,
                    createdAt: Timestamp.fromDate(new Date()),
                    layerItemType: null,
                    layerItemAttributes: {
                        layerColor: 'orange'
                    },
                    index: length,
                    visible: true,
                };

                const updatedLayers = [...layers];
                updatedLayers[layerIndex].layerItems = [
                    ...updatedLayers[layerIndex].layerItems,
                    newItem,
                ];

                await updateDoc(mapRef, {
                    layers: updatedLayers,
                });
                setLayers(updatedLayers);



                return newItem;
            } else {
                console.error('Layer not found');
                return null;
            }
        } else {
            console.error('Map not found');
            return null;
        }
    } catch (error) {
        console.error('Error creating layer item:', error);
        return null;
    }
};

export const deleteLayerItem = async (company, widgetKey, layerId, itemId, setLayers) => {
    const db = getFirestore();

    try {
        const mapRef = doc(db, 'clients', company, 'widgetMaps', widgetKey);
        const mapSnapshot = await getDoc(mapRef);

        if (mapSnapshot.exists()) {
            const mapData = mapSnapshot.data();
            const layers = mapData.layers || [];

            const layerIndex = layers.findIndex((layer) => layer.id === layerId);

            if (layerIndex !== -1) {
                const layerItems = layers[layerIndex].layerItems;
                const updatedLayerItems = layerItems.filter((item) => item.id !== itemId);

                const updatedLayers = [...layers];
                updatedLayers[layerIndex].layerItems = updatedLayerItems;

                await updateDoc(mapRef, {
                    layers: updatedLayers,
                });
                setLayers(updatedLayers);



                return true;
            } else {
                console.error('Layer not found');
                return false;
            }
        } else {
            console.error('Map not found');
            return false;
        }
    } catch (error) {
        console.error('Error deleting layer item:', error);
        return false;
    }
};

export const updateLayerItem = async (layerId, layerItemId, company, widgetKey, updateData, setLayers) => {
    const db = getFirestore();

    try {
        const mapRef = doc(db, 'clients', company, 'widgetMaps', widgetKey);
        const mapSnapshot = await getDoc(mapRef);

        if (mapSnapshot.exists()) {
            const mapData = mapSnapshot.data();
            const layers = mapData.layers || [];

            const updatedLayers = layers.map(layer => {
                if (layer.id === layerId) {
                    const updatedLayerItems = layer.layerItems.map(item => {
                        if (item.id === layerItemId) {
                            return {
                                ...item,
                                ...updateData,
                            };
                        } else {
                            return item;
                        }
                    });
                    return {
                        ...layer,
                        layerItems: updatedLayerItems,
                    };
                } else {
                    return layer;
                }
            });

            await updateDoc(mapRef, {
                layers: updatedLayers,
            });
            setLayers(updatedLayers);


            return true;
        } else {
            console.error('Map not found');
            return false;
        }
    } catch (error) {
        console.error(`Error updating layer item with ID ${layerItemId} in layer with ID ${layerId}:`, error);
        return false;
    }
};

export const createLayer = async (layerType, company, widgetKey, setLayers, length) => {
    const db = getFirestore();

    try {
        const sentenceCaseType = layerType.charAt(0).toUpperCase() + layerType.slice(1);
        const newLayer = {
            id: uuid(),
            type: layerType,
            name: `Layer`,
            createdAt: Timestamp.fromDate(new Date()),
            visible: true,
            expanded: true,
            layerItems: [],
            index: length,
            // Add any other layer-specific properties here
        };

        const mapRef = doc(db, 'clients', company, 'widgetMaps', widgetKey);
        const mapSnapshot = await getDoc(mapRef);

        if (mapSnapshot.exists()) {
            const mapData = mapSnapshot.data();
            const layers = mapData.layers || [];
            layers.push(newLayer);

            await updateDoc(mapRef, {
                layers: layers,
            });



            // Call setLayers to update the layers state in WidgetMap2.jsx
            setLayers(layers);

            return newLayer;
        } else {
            console.error('Map not found');
            return null;
        }
    } catch (error) {
        console.error('Error creating layer:', error);
        return null;
    }
};

export const deleteLayer = async (layerId, company, widgetKey, setLayers) => {
    const db = getFirestore();

    try {
        const mapRef = doc(db, 'clients', company, 'widgetMaps', widgetKey);
        const mapSnapshot = await getDoc(mapRef);

        if (mapSnapshot.exists()) {
            const mapData = mapSnapshot.data();
            const layers = mapData.layers || [];

            const updatedLayers = layers.filter(layer => layer.id !== layerId);

            await updateDoc(mapRef, {
                layers: updatedLayers,
            });
            setLayers(updatedLayers);


            return true;
        } else {
            console.error('Map not found');
            return false;
        }
    } catch (error) {
        console.error(`Error deleting layer with ID ${layerId}:`, error);
        return false;
    }
};
export const updateLayer = async (layerId, company, widgetKey, updateData, setLayers) => {
    const db = getFirestore();

    try {
        const mapRef = doc(db, 'clients', company, 'widgetMaps', widgetKey);
        const mapSnapshot = await getDoc(mapRef);

        if (mapSnapshot.exists()) {
            const mapData = mapSnapshot.data();
            const layers = mapData.layers || [];

            const updatedLayers = layers.map(layer => {
                if (layer.id === layerId) {
                    return {
                        ...layer,
                        ...updateData,
                    };
                } else {
                    return layer;
                }
            });

            await updateDoc(mapRef, {
                layers: updatedLayers,
            });
            setLayers(updatedLayers);


            return true;
        } else {
            console.error('Map not found');
            return false;
        }
    } catch (error) {
        console.error(`Error updating layer with ID ${layerId}:`, error);
        return false;
    }
};

export const getNodesList = async (userInfo) => {
    const db = getFirestore();
    try {
        const queryStations = query(collection(db, "clients", userInfo.currentCompany, 'stations'), where('account', '==', userInfo.currentAccount),);
        const snapNodes = await getDocs(queryStations);
        const nodes = [];
        snapNodes.forEach((doc) => {

            if (doc.data().archived !== true && doc.data().children.length > 0) {
                const children = doc.data().children;
                //for each child create a node and add to locations
                children.forEach((child) => {

                    nodes.push({
                        ...child,
                        lat: doc.data().lat,
                        lng: doc.data().lng,
                        id: child.key, stationLabel: doc.data().name, label: child.name, type: 'node', stationType: child.type
                    })
                })

            }

        });

        return nodes;
    } catch (error) {
        console.log(error);
    }
}















