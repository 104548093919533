import { Col, Row } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { fullMobile } from '../../util';
import HighchartsReact from "highcharts-react-official";
import highchartsMore from "highcharts/highcharts-more.js";
import Highcharts from "highcharts/highcharts.js";
import moment from 'moment';
import { CircularProgress, Divider, Typography } from '@mui/material';
import axios, * as others from 'axios';
import Plot from 'react-plotly.js';
var convert = require('convert-units')
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/data')(Highcharts);




export default function WidgetIsopleth(props) {
    const { userInfo, screenWidth, openSnack, widgetRef, layoutRef } = props;
    const [layout, setLayout] = useState(layoutRef);
    const [widget, setWidget] = useState(widgetRef);
    const chartDiv = useRef(null);
    const plotRef = useRef(null);
    const plotlyRef = useRef(null);
    const [loading, setLoading] = useState(true);
    const [x, setX] = useState([]);
    const [y, setY] = useState([]);
    const [z, setZ] = useState([]);




    useEffect(() => {
        setWidget(widgetRef)
    }, [widgetRef])
    useEffect(() => {
        setLayout(layoutRef)
    }, [layoutRef])




    //create chart
    useEffect(() => {
        if (widget) {

            queryData(widget)

        }
    }, [widget])

    useEffect(() => {


    }, [])

    //if height or width of chart div changes update chart
    useEffect(() => {

    }, [chartDiv.current?.clientHeight, chartDiv.current?.clientWidth])

    async function queryData(widget) {
        setLoading(true)
        const proxyUrl = "https://mycorslake.herokuapp.com/";

        const series = widget.series;
        const x = [];
        const y = [];
        const z = [];

        const startDate = moment(widget.startDate, 'x').format('YYYY-MM-DD HH:mm:ss')
        const endDate = moment(widget.endDate, 'x').format('YYYY-MM-DD HH:mm:ss')

        function someAsyncFunction(q) {

            return new Promise(async (resolve, reject) => {

                const item = q;
                console.log(q)
                const raw = `SELECT COUNT (*) FROM ${item?.dataType === 'probe' ? 'probe_data' : 'node_data_new2'}
            WHERE ${item?.dataType === 'probe' ? 'locationid' : 'nodeid'} = '${item?.nodeid}'and parameterid = '${item?.parameterid}'
            AND time >= '${startDate}' AND time <= '${endDate}'
        
        ;`

                axios.post(proxyUrl + `https://us-central1-aquasource3.cloudfunctions.net/widgetsFire2/sqlRead`, {
                    raw
                }).then(async (response) => {
                    let count = response.data[0].count;


                    //max series length should be 500 points, if count is more than 500 then time bucket data into intervals that will provide 500 points
                    const interval = Math.ceil(count / 1000)

                    const customBucketNode = `SELECT time_bucket('${interval} hours', time) as time, AVG(value) as value 
                FROM ${item?.dataType === 'probe' ? 'probe_data' : 'node_data_new2'}
                WHERE ${item?.dataType === 'probe' ? 'locationid' : 'nodeid'} = '${item?.nodeid}'and parameterid = '${item?.parameterid}'
                AND time >= '${startDate}' AND time <= '${endDate}'
                GROUP BY time_bucket('${interval} hours', time)
                ORDER BY time_bucket('${interval} hours', time) ASC;`

                    axios.post(proxyUrl + `https://us-central1-aquasource3.cloudfunctions.net/widgetsFire2/sqlRead`, {
                        raw: customBucketNode
                    }).then(async (response) => {
                        const data = response.data;
                        const convertData = [];
                        const decimals = item?.decimals || 2;
                        const offset = item?.offset || 0;
                        data.forEach((d) => {
                            const value =
                                item.parameterid === '3' ? Number(convert(d.value).from('m').to(['m', 'ft'].includes(item.units) ? item.units : 'm')) :
                                    item.parameterid === '1' ? Number(convert(d.value).from('C').to(['C', 'F'].includes(item.units) ? item.units : 'C')) :
                                        item.parameterid === '19' ? Number(d.value) * 1000 :
                                            !['1', '3', '19'].includes(item.parameterid) ? Number(d.value) : Number(d.value);

                            const finalValue = value + offset;
                            if (item.depth !== undefined && item.depth !== null && item.depth !== '') {
                                convertData.push([Number(moment(d.time, 'YYYY-MM-DD HH:mm').format('x')), item.depth, finalValue])
                            }
                        })
                        resolve({ depth: item.depth, data: convertData });

                    }).catch((error) => {
                        console.log(error)
                        setLoading(`No Data Found for ${item?.label}`)
                    })





                }).catch((error) => {
                    console.log(error)
                    setLoading(`No Data Found for ${item?.label}`)
                })


            });
        }











        const resultArray = await Promise.all(series.map(async (i) => someAsyncFunction(i)));

        resultArray.map((i) => i.data.sort((a, b) => a[0] - b[0]));


        //create a list of all unique times from each of the items in resultArray and combine them into one list
        const timesList = resultArray.map((i) => i.data.map((d) => d[0]));
        const timesListFlat = timesList.flat(1);
        const timesListUnique = [...new Set(timesListFlat)];


        //add null values to each item in resultArray for times that are not in the item
        const resultListArrayWithNulls = resultArray.map((i) => {
            const dataList = [];
            const data = i.data;
            timesListUnique.forEach((t, i) => {

                const index = data.map((d) => d[0]).indexOf(t);
                if (index > -1) {


                    dataList.push(data[index])
                } else {



                    dataList.push([t, i.depth, null])
                }
            }
            );

            return dataList;
        }
        );

        console.log(resultArray)


        //create an array of arrays of values for each depth
        const resultArrayValues = resultListArrayWithNulls.map((i) => i.map((d) => d[2]));

        const uniqueDepths = [...new Set(resultArray.map((i) => i.depth))];
      
        setX(timesListUnique)
        setY(uniqueDepths)
        setZ(resultArrayValues)
        setLoading(false)


    }

    const attributes = widget;

    const colorBarColor = attributes.colorBarTheme === undefined ? [
        [0, 'rgba(254, 73, 44, 0.9)'],
        [0.5, 'rgba(255, 208, 0, 0.6)'],
        [1, 'rgba(97, 201, 164, 0.9)']
    ] : attributes.colorBarTheme === 'standard' ? [
        [0, 'rgba(254, 73, 44, 0.9)'],
        [0.5, 'rgba(255, 208, 0, 0.6)'],
        [1, 'rgba(97, 201, 164, 0.9)']
    ] : attributes.colorBarTheme === 'reverseStandard' ?
        [
            [0, 'rgba(97, 201, 164, 0.9)'],
            [0.5, 'rgba(255, 208, 0, 0.6)'],
            [1, 'rgba(254, 73, 44, 0.9)']
        ] : attributes.colorBarTheme === 'blueToRed' ?
            [
                [0, 'rgba(0, 0, 255, 0.9)'],
                [0.5, 'rgba(255, 208, 0, 0.6)'],
                [1, 'rgba(255, 0, 0, 0.9)']
            ] : attributes.colorBarTheme === 'redToBlue' ?
                [
                    [0, 'rgba(255, 0, 0, 0.9)'],
                    [0.5, 'rgba(255, 208, 0, 0.6)'],
                    [1, 'rgba(0, 0, 255, 0.9)']
                ] : [
                    [0, 'rgba(254, 73, 44, 0.9)'],
                    [0.5, 'rgba(255, 208, 0, 0.6)'],
                    [1, 'rgba(97, 201, 164, 0.9)']
                ];


    //if loading is a string show error message

    return (

        //create div that adjust with size of parent div

        <div ref={chartDiv} style={{ width: '100%', height: '100%', position: 'relative', }}>


            <div ref={plotRef} style={{
                height: '100%',
                width: '100%',
                alignContent: 'center',
                position: 'relative'
            }}>
                {loading === false && (<Plot
                    ref={plotlyRef}

                    data={[{

                        z: [...z],
                        y: [...y],
                        x: [...x],
                        colorscale: colorBarColor,
                        zmax: attributes.colorBarMax || 20,
                        zmin: attributes.colorBarMin || 0,
                        zauto: attributes.colorBarAuto !== undefined ? attributes.colorBarAuto : true,
                        zsmooth: 'best',
                        type: attributes.showLines !== undefined ? attributes.showLines ? 'contour' : 'heatmap' : 'heatmap',
                        showscale: attributes.colorBar || true,
                        connectgaps: true,
                        colorbar: { title: { text: widget.colorBarLabel || '', standoff: 0, font: 'Roboto, sans-serif' } },
                        hovertemplate: `<b>${attributes?.depthLabel || 'Depth'}: %{y:.2f} ${attributes.depthUnits || ' '}</b><br><b>${attributes?.valueLabel || 'Value'}: %{z:.2f} ${attributes.unitsLabel || ' '}</b><br><b>Date: %{x}</b><br><extra></extra>`

                    }
                    ]}
                    layout={{
                        colorbar: { title: { text: 'mg/L', standoff: 0, font: 'Roboto, sans-serif' } },
                        title: null, autosize: true, autosize: true,
                        yaxis: {
                            //range reversed to show depth in descending order
                            autorange: 'reversed',
                            rangemode: 'nonnegative',
                            //add blue line at 0 depth
                            zerolinecolor: 'rgb(0, 0, 255)',
                            //make it thicker
                            zerolinewidth: 5,

                            title: { text: widget.depthUnits || '', standoff: 0, font: 'Roboto, sans-serif' }
                        },

                        xaxis: {
                            title: { text: attributes.xAxisLabel || '', standoff: 0, font: 'Roboto, sans-serif' },
                            type: 'date'
                        },
                        margin: {
                            l: 60,
                            r: 10,
                            b: 60,
                            t: 40,
                            pad: 5
                        }


                    }}
                    useResizeHandler={true}
                    style={{ width: "100%", height: "100%" }}

                />)}
            </div>

            <div style={{


                position: 'absolute',
                top: '35%',
                textAlign: 'center',
                width: '100%',

            }}>

                {loading === true ? <CircularProgress /> : null
                }
                {typeof loading === 'string' && (
                    <Typography variant={'body1'} fontWeight={500} fontSize={14} style={{ color: 'rgba(0,0,0,0.7)', }}>
                        {loading}
                    </Typography>
                )}


            </div>

        </div >

    );




}