import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Chip, Divider, Fab, IconButton, Input, InputAdornment, OutlinedInput, Paper, Typography } from '@mui/material';
import { Col, Modal, Popconfirm, Progress, Row } from 'antd';
import { collection, deleteDoc, doc, getDocs, getFirestore, query, setDoc, updateDoc, where } from 'firebase/firestore';
import moment from 'moment';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import "../../extra/hoverStyle.css";

import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import Dropzone from 'react-dropzone';

export default class Documents extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            data: [],
            filteredData: [],
            filters: [],
            searchText: '',
            loading: true,
            type: this.props.type,
            currentItem: {},
            openModal: false,
            files: [],
            progressBars: {},
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0)
        this.queryData()
    }

    queryData = async () => {
        const { userInfo, type } = this.state;
        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'documents'), where('account', '==', userInfo.currentAccount), where('archived', '==', false));
        const data = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            data.push(doc.data())


        });
        console.log(data);
        this.setState({ data, loading: false })

        this.filterData(data, '', [])
    }

    filterData = (data, searchText, filters) => {
        const filteredData = data.filter(d => d.name.match(new RegExp(searchText, "i")))
        this.setState({ filteredData })

    }

    updateCurrentItem = (boo, val) => {
        const { currentItem } = this.state;
        currentItem[boo] = val;
        this.setState({ currentItem })
    }



    render() {

        const { userInfo, account, data, filteredData, loading, searchText, filters, type, currentItem, accounts, openModal, screenWidth, files, progressBars } = this.state;
        const db = getFirestore();
        const CreateItemButton = () => {
            let navigate = useNavigate();

            return (
                <Fab style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="medium"
                    onClick={async () => {
                        this.setState({ openModal: true, currentItem: { tempKey: uuid() } })
                    }}>
                    <AddIcon />
                </Fab>
            )
        }
        const EditItem = ({ item }) => {
            let navigate = useNavigate();
            return (
                <IconButton style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="small"
                    onClick={async () => {
                        const itemRef = doc(db, "users", userInfo.id, 'currentItems', 'currentPS');
                        this.setState({ openModal: true, currentItem: item })
                    }}>
                    <EditIcon />
                </IconButton>
            )
        }

        const DeleteItem = ({ item }) => {
            let navigate = useNavigate();
            return (
                <Popconfirm
                    title="Are you sure to delete this document?"
                    onConfirm={async () => {
                        const itemRef = doc(db, "clients", userInfo.currentCompany, 'documents', item.key);
                        await updateDoc(itemRef, {
                            archived: true
                        }).then(() => {
                            const newData = filteredData.filter((f) => f.key !== item.key)
                            this.setState({ filteredData: newData })
                            this.props.openSnack('success', 'Document removed.')
                        })

                    }}
                    okText="Yes"
                    cancelText="No"
                >
                    <IconButton style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="small"
                    >
                        <DeleteForeverIcon />
                    </IconButton>
                </Popconfirm>


            )
        }
        const DeleteFile = ({ item }) => {
            return (
                <Popconfirm
                    title="Are you sure to delete this file?"
                    onConfirm={async () => {
                        console.log(item)

                        await deleteDoc(doc(db, "clients", userInfo.currentCompany, 'documents', item.key, 'files', item.key)).then(() => {
                            currentItem.files = [];
                            this.setState({ currentItem })
                            this.props.openSnack('success', 'File deleted!')
                        })

                    }}
                    okText="Yes"
                    cancelText="No"
                >
                    <IconButton style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="small"
                    >
                        <DeleteForeverIcon />
                    </IconButton>
                </Popconfirm>


            )
        }

        const RowItem = ({ item }) => {
            return (
                <Col span={24} style={{ cursor: 'pointer', padding: 8 }} className="rowHover"
                >
                    <Row gutter={[8, 8]} align="middle" >

                        <Col xs={14} sm={20} onClick={async () => {
                            this.setState({ currentItem: item, openModal: true })
                        }}>
                            <Typography variant='h6'>{item.name}</Typography>
                            <Typography variant='body1'>{item.description}</Typography>

                        </Col>
                        <Col xs={10} sm={4} style={{ textAlign: 'right' }}>
                            <EditItem item={item} /> <DeleteItem item={item} />
                        </Col>


                        <Col span={24}>
                            <Divider />
                        </Col>

                    </Row>
                </Col>
            )
        }

        const CreateDocument = () => {
            return (
                <Button size="small" style={{ padding: 10, borderRadius: 4, textTransform: 'none', fontSize: 14, }}
                    variant='contained' color="primary"
                    onClick={async () => {
                        const { currentItem } = this.state;
                        const key = currentItem.key || currentItem.tempKey;
                        currentItem.archived = currentItem.archived || false;
                        currentItem.account = userInfo.currentAccount;
                        currentItem.company = userInfo.currentCompany;
                        const docRef = doc(db, "clients", userInfo.currentCompany, 'documents', key);


                        const checks = [
                            currentItem.name.length === 0 ? false : true,
                        ]

                        if (currentItem.name.length === 0) {
                            this.props.openSnack('error', "Log number required!")
                        }

                        if (!checks.includes(false)) {

                            if (currentItem.key) {
                                await updateDoc(docRef, currentItem).then(() => {
                                    const index = filteredData.map((d) => d.key).indexOf(currentItem.key)
                                    filteredData[index] = currentItem;
                                    this.setState({ filteredData: [...filteredData], openModal: false, currentItem: {}, })
                                }).then(() => {
                                    this.props.openSnack('success', currentItem.key ? 'Document updated!' : "Document created!")
                                })
                            }
                            else {
                                await setDoc(docRef, {
                                    ...currentItem,
                                    key: currentItem.tempKey
                                }).then(() => {
                                    filteredData.push({ ...currentItem, key: currentItem.tempKey })
                                    this.setState({ filteredData, openModal: false, currentItem: {}, })
                                }).then(() => {
                                    this.props.openSnack('success', currentItem.key ? 'Document updated!' : "Document created!")
                                })
                            }
                        }




                    }}>{currentItem.key ? 'Update Document' : "Create Document"}</Button>
            )
        }

        return (
            <Row style={{}} align="middle">

                <Col span={23}>
                    <Row align="middle">
                        <Col xs={16} sm={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>
                            <Typography variant="h4">Your Documents</Typography>
                        </Col>
                        <Col xs={6} sm={4} style={{ paddingTop: 30, paddingBottom: 15, textAlign: 'right' }}>
                            <CreateItemButton />
                        </Col>
                        <Col span={24}>
                            <Divider />
                        </Col>
                    </Row>
                </Col>





                <Col span={23} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 30 }}>
                    <Paper elevation={16} style={{ padding: 20, borderRadius: 12 }}>
                        <Row>
                            <Col xs={24} sm={12} style={{ paddingBottom: 8 }}>
                                <Input fullWidth
                                    autoComplete='off'
                                    value={searchText}
                                    onChange={(e) => {
                                        this.setState({ searchText: e.target.value })
                                        if (e.target.value.length > 1) {
                                            this.filterData(data, e.target.value, filters)
                                        }
                                        else {
                                            this.filterData(data, '', filters)
                                        }
                                    }}
                                    id="input-with-icon-adornment"
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    }
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                onClick={() => {
                                                    this.setState({ searchText: '' })
                                                    this.filterData(data, '', filters)
                                                }}
                                            >
                                                <HighlightOffIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </Col>
                            <Col xs={0} sm={12} style={{ textAlign: 'right' }}>
                                <Typography variant='h6'>Actions</Typography>
                            </Col>

                            <Col span={24}>
                                <Row align='middle' >
                                    {filteredData.map((d) => {
                                        return (
                                            <RowItem item={d} />

                                        )

                                    })}
                                </Row>
                            </Col>
                        </Row>
                    </Paper>
                </Col>

                <Modal title="" visible={openModal} onCancel={() => this.setState({ openModal: false, currentItem: {} })} footer={null} width={'50%'} style={{ borderRadius: 40 }} >
                    <Row align="middle" gutter={[16, 16]} >
                        <Col xs={{ offset: 0, span: 24 }} sm={{ offset: 6, span: 18 }} style={{ paddingBottom: 12, }}>
                            <Typography variant='h5'>Document</Typography>
                        </Col>


                        <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                            <Typography variant="body1">Document Name<span style={{ color: 'red' }}>*</span></Typography>
                        </Col>
                        <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                            <OutlinedInput fullWidth notched={false} label="none" placeholder={"Name"} size="small"
                                value={currentItem.name || ''}
                                onChange={(e) => {
                                    this.updateCurrentItem(`name`, e.target.value)
                                }} />
                        </Col>
                        <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                            <Typography variant="body1">Description</Typography>
                        </Col>
                        <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                            <OutlinedInput fullWidth notched={false} label="none" placeholder={"Description"} size="small"
                                multiline={true}
                                rows={4}
                                value={currentItem.description || ''}
                                onChange={(e) => {
                                    this.updateCurrentItem(`description`, e.target.value)
                                }} />
                        </Col>
                        <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                            <Typography variant="body1">File</Typography>
                        </Col>
                        <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                            <Row gutter={[4, 4]} align="middle">


                                {currentItem.files !== undefined && (currentItem.files.map((p, i) => {

                                    return (
                                        <Col xs={24} sm={24} style={{ borderRadius: 8, paddingBottom: 12 }}>
                                            <Row align="middle" gutter={[8, 8]}>
                                                <Col span={20} >
                                                    <Chip color="primary" label={p.fileName} onClick={() => {
                                                        window.open(
                                                            p.fileLink,
                                                            '_blank' // <- This is what makes it open in a new window.
                                                        );
                                                    }} />

                                                </Col>

                                                <Col span={4}>
                                                    <DeleteFile item={p} />
                                                </Col>

                                            </Row>
                                        </Col>

                                    )
                                }))}

                                {Object.values(progressBars).length > 0 && (<Col xs={24} sm={24} >
                                    {Object.values(progressBars).map((p) => {
                                        return (
                                            <Col span={24}>
                                                {p.progress > 0 && (
                                                    <div> {p.fileName} <Progress percent={p.progress} /></div>
                                                )}
                                            </Col>

                                        )
                                    })}
                                </Col>)}

                                <Col className='fileUpload' xs={24} sm={24} style={{ border: '1px dashed gray', borderRadius: 8 }}>
                                    <Dropzone multiple={false} onDrop={(acceptedFiles) => {

                                        acceptedFiles.map((f) => {

                                            const storage = getStorage();
                                            console.log(userInfo);
                                            console.log(currentItem);
                                            const key = currentItem.tempKey || currentItem.key;

                                            const storageRef = ref(storage, `/documents/${userInfo.currentAccount}/${key}`);

                                            const uploadTask = uploadBytesResumable(storageRef, f);
                                            uploadTask.on('state_changed',
                                                (snapshot) => {

                                                    var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                                                    console.log('Upload is ' + progress + '% done');

                                                    progressBars[key] = { key, progress, fileName: f.name }

                                                    this.setState({ progressBars })

                                                    switch (snapshot.state) {
                                                        case 'paused':
                                                            console.log('Upload is paused');
                                                            break;
                                                        case 'running':
                                                            console.log('Upload is running');
                                                            break;
                                                    }
                                                },
                                                (error) => {
                                                },
                                                () => {
                                                    getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {



                                                        const file = {
                                                            fileCreateDate: moment().format('X'),
                                                            date: moment().format('X'),
                                                            fileCreator: `${userInfo.firstName || ""} ${userInfo.lastname}`,
                                                            fileCreatorKey: userInfo.id,
                                                            fileLink: downloadURL,
                                                            fileName: f.name,
                                                            fileType: f.type,
                                                            description: '',
                                                            key,
                                                        };

                                                        currentItem.files = [file];




                                                        const db = getFirestore()
                                                        const docRef = doc(db, "clients", userInfo.currentCompany, 'documents', key, 'files', file.key);
                                                        await setDoc(docRef, file)





                                                        delete progressBars[key];
                                                        this.setState({ progressBars, currentItem })









                                                    });
                                                }
                                            );






                                        })
                                    }} >
                                        {({ getRootProps, getInputProps }) => (
                                            <section>
                                                <div {...getRootProps()}>

                                                    <Row align="middle">

                                                        <Col span={24} style={{ padding: 6, textAlign: 'center' }}>
                                                            <FontAwesomeIcon fontSize={18} icon={faCloudArrowUp} color="#9ec1c9" />
                                                        </Col>
                                                        <Col span={24} style={{ padding: 6, textAlign: 'center' }}>
                                                            <Typography variant='h6'>Upload File</Typography>
                                                        </Col> <input {...getInputProps()} /></Row>
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                </Col>

                            </Row>

                        </Col>

                        <Col xs={{ offset: 0, span: 24 }} sm={{ offset: 6, span: 18 }} style={{ paddingBottom: 12, }}>
                            <CreateDocument />
                        </Col>

                    </Row>
                </Modal>




            </Row>
        );
    }
} 