import { faSquareCaretDown } from '@fortawesome/free-regular-svg-icons';
import { faCircleChevronDown, faSquare, faSquareArrowUpRight } from '@fortawesome/free-solid-svg-icons';
import { Button, Popover, Tooltip, Typography } from '@mui/material';
import { TwitterPicker } from 'react-color';
import { IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
var randomColor = require('randomcolor');


const ColorSelect = ({
    currentItem,
    setCurrentItem,
    headerKey,
    options,
    type,
    updateItems
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [value, setValue] = React.useState(currentItem?.[headerKey] || randomColor());



    useEffect(() => {
        if (currentItem?.[headerKey] === undefined && type === 'drawer') {
            setCurrentItem({ ...currentItem, [headerKey]: value })
        }
    }, [])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };



    const handleColorChange = (newColor) => {
        console.log(newColor)
        let val = newColor.rgb;
        val = `rgba(${val.r},${val.g},${val.b},${val.a})`
        setCurrentItem({ ...currentItem, [headerKey]: val })
        updateItems({ ...currentItem, [headerKey]: val })
        setValue(val);
    };



    return (
        <div style={{
            display: 'inline',
            justifyContent: 'center',
            alignItems: 'center',

        }}>
            <Tooltip title="Select Color" placement="top">
                <Button
                    variant='link'
                    style={{
                        textAlign: 'center',
                        textTransform: 'none',
                        color: 'inherit',
                        display: 'inline',
                        padding: 0,
                    }}

                    onClick={handleClick}>
                    <FontAwesomeIcon icon={faSquare} color={value} size="2xl" style={{


                    }} />

                </Button>
            </Tooltip>

            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <TwitterPicker color={value} onChangeComplete={handleColorChange} />
            </Popover>
        </div>
    );

};

export default ColorSelect;