import { Button, Checkbox, Divider, OutlinedInput, Typography, Select, MenuItem, CircularProgress, Autocomplete, TextField, Switch, ButtonGroup } from '@mui/material';
import { Col, Row } from 'antd';
import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { fullMobile } from './../util';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, collectionGroup, } from 'firebase/firestore';

import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import uuid from 'react-uuid';


import DatePicker from "react-datepicker";
import queryString from 'query-string';
import axios from 'axios';
import MergeDataManagement from './mergeData';
import DeleteDataManagement from './deleteData';
import { querySites } from '../../firebase/config';
import RetrieveData from './retrieveData';
var randomColor = require('randomcolor');

export default function DataManagementHome({ userInfo, screenWidth, openSnack, company, account }) {
    const [stations, setStations] = useState([])
    const [probes, setProbes] = useState([])
    const [loadingStations, setLoadingStations] = useState(true)
    const [loadingProbes, setLoadingProbes] = useState(true)
    const [fromID, setFromID] = useState(null)
    const [toID, setToID] = useState(null)
    const [fromType, setFromType] = useState('probe')
    const [toType, setToType] = useState('probe')
    const [startDate, setStartDate] = useState(moment().subtract(1, 'days').format('x'))
    const [endDate, setEndDate] = useState(moment().format('x'))
    const [type, setType] = useState('merge')

    useEffect(() => {
        window.scrollTo(0, 0)
        getProbes()
        getStations()
       /*  getAllStation() */

    }, [])

    async function getData(date, allStations, allParameters, allSampleSites) {
        const proxyUrl = "https://mycorslake.herokuapp.com/";
        axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
            raw: `SELECT * FROM lab_data
            WHERE account = '${userInfo.currentAccount}'
           


             LIMIT 2000;`
        }).then(async (response) => {
            console.log(response.data)

            //get the earliest time from response.data




        })
            .catch((error) => {
                console.log(error);

            });

    }


    async function createNodeData(data, allStations, allParameters, allSampleSites) {

        const uploadData = []

        data.forEach((row) => {

            const station = allStations.find((station) => station.name === row.locationlabel && station.account === row.account)
            const parameterIndex = allParameters.findIndex((parameter) => parameter.name === row.parameterlabel && parameter.account === row.account)



            if (station !== undefined) {
                uploadData.push(
                    {
                        time: `'${row.time}'`,
                        timestamp: Number(moment(row.time, "YYYY-MM-DD HH:mm").format("X")),
                        locationid: `'${station?.id}'`,
                        unitid: `'${row.unitid || ''}'`,
                        parameterid: `'${row.parameterid}'`,
                        value: row.value,
                        account: `'${row.account}'`,
                        company: `'${row.company}'`,
                        nodeid: `'${station?.id}'`,
                        key: `'${row.key || uuid()}'`,
                        longitude: station.lat,
                        latitude: station.lng,
                        logkey: `'${row.logkey}'`,
                        label: `''`,
                    }
                )
            }


        })

        console.log(uploadData.length)

        const hourlyVals = uploadData.map((h) => `(${Object.values(h)})`);
        const hourlyString = hourlyVals.join();

        const proxyUrl = "https://mycorslake.herokuapp.com/";
        axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
            raw: `INSERT INTO node_data_new2 VALUES ${hourlyString} ON CONFLICT DO NOTHING; `
        }).then(async (response) => {
            console.log(response)
        }).catch((error) => {
            console.log(error)
        }
        )


    }

    async function getProbes() {
        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'probes'), where('account', '==', userInfo.currentAccount), where('archived', '==', false));

        const locations = [];


        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            locations.push({ ...doc.data(), label: doc.data().name, type: 'probe' })
        });
        locations.sort((a, b) => a.name.localeCompare(b.name))
        setProbes(locations)

        if (locations.length > 0) {
            setLoadingProbes(false)
        }
        else {
            setLoadingProbes(false)
        }

    }

    async function getAllStation() {
        const db = getFirestore();
        const queryData = query(collectionGroup(db, 'stations'));
        const querySnapshot = await getDocs(queryData);
        const data = [];
        querySnapshot.forEach((doc) => {
            if (doc.data().children.length > 0) {
                const children = doc.data().children;
                console.log(doc.data().lat)
                //for each child create a node and add to locations
                children.forEach((child) => {
                    data.push({
                        ...child,
                        label: `${doc.data().name}-${child.name}`,
                        type: 'node',
                        stationType: child.type,
                        lat: doc.data().lat || 0,
                        lng: doc.data().lng || 0
                    })
                })

            }
        });

        getAllParameters(data)
    }
    async function getAllParameters(allStations) {
        const db = getFirestore();
        const queryData = query(collectionGroup(db, 'parameters'));
        const querySnapshot = await getDocs(queryData);
        const data = [];
        querySnapshot.forEach((doc) => {
            data.push(doc.data())
        });

        getAllSampleSites(allStations, data)
    }
    async function getAllSampleSites(allStations, allParameters) {
        const sites = await querySites(userInfo)


        console.log(allParameters)
        const LTCU = sites.filter(s => s.name === 'LTCU');
        const LTCUNode = allStations.filter(n => n.name === 'LTCU')

        console.log(LTCU)
        console.log(LTCUNode)

        getData('current', allStations, allParameters, sites)
    }


    async function getStations() {
        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'stations'), where('account', '==', userInfo.currentAccount),);
        const stations = [];
        const snap = await getDocs(queryData);
        snap.forEach(qs => {
            //add latitude and longitude to each child

            if (qs.data().archived !== true && qs.data().children.length > 0) {
                const children = qs.data().children;
                //for each child create a node and add to locations
                children.forEach((child) => {
                    stations.push({ ...child, label: `${qs.data().name}-${child.name}`, type: 'node', lat: qs.data().lat || 0,
                    lng: qs.data().lng || 0 })
                })

            }

        });


        if (stations.length > 0) {
            //sort by sort by name
            stations.sort((a, b) => a.label.localeCompare(b.label))
            console.log(stations)
            setStations(stations)
            setLoadingStations(false)
        }
        else {
            setLoadingStations(false)
        }

    }





    return (
        <Row style={{ fontFamily: 'Roboto, sans-serif', paddingLeft: 10, paddingTop: 10, marginLeft: 2, }} >


            <Col span={23}>
                <Row align="middle">
                    <Col xs={16} sm={20} style={{ paddingTop: 30, paddingBottom: 15 }}>
                        <Typography variant="h4">Data Management</Typography>
                    </Col>

                    <Col span={24}>
                        <Divider />
                    </Col>
                </Row>
            </Col>
            <Col span={23} style={{ paddingTop: 10 }}>
                <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group" style={{ textTransform: 'none' }}>
                    <Button
                        style={{
                            textTransform: 'none',
                            color: type === 'merge' ? 'white' : '#404050',
                            backgroundColor: type === 'merge' ? '#404050' : 'white',

                        }}
                        variant={type === 'merge' ? 'contained' : 'outlined'} onClick={() => setType('merge')}>Merge Data</Button>
                    <Button style={{
                        textTransform: 'none',
                        color: type === 'delete' ? 'white' : '#404050',
                        backgroundColor: type === 'delete' ? '#404050' : 'white',

                    }}
                        variant={type === 'delete' ? 'contained' : 'outlined'} onClick={() => setType('delete')}>Delete Data</Button>
                    <Button style={{
                        textTransform: 'none',
                        color: type === 'retrieve' ? 'white' : '#404050',
                        backgroundColor: type === 'retrieve' ? '#404050' : 'white',

                    }}
                        variant={type === 'retrieve' ? 'contained' : 'outlined'} onClick={() => setType('retrieve')}>Retrieve Data</Button>
                </ButtonGroup>
            </Col>

            {type === 'merge' && loadingProbes === false && loadingStations === false && (
                <Col span={24}>
                    <MergeDataManagement userInfo={userInfo} openSnack={openSnack} company={company} account={account} probesRef={probes} stationsRef={stations} />
                </Col>
            )}

            {type === 'delete' && loadingProbes === false && loadingStations === false && (
                <Col span={24}>
                    <DeleteDataManagement userInfo={userInfo} openSnack={openSnack} company={company} account={account} probesRef={probes} stationsRef={stations} />
                </Col>
            )}
            {type === 'retrieve' && loadingProbes === false && loadingStations === false && (
                <Col span={24}>
                    <RetrieveData userInfo={userInfo} openSnack={openSnack} company={company} account={account} probes={probes} stations={stations} />
                </Col>
            )}





        </Row>
    );
}