import React from 'react';
import { Col, Row } from 'antd';
import { Breadcrumbs, Button, OutlinedInput, Paper, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { setDefaultOptions, loadModules, } from 'esri-loader';
import Search from "@arcgis/core/widgets/Search";

export default class RelocateMap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            view: this.props.view,
        };
    }

    componentDidUpdate(prevProps) {

        if (this.props.gps !== prevProps.gps) {
            const view = this.state.view;
            view.goTo({
                center: this.props.gps,
            })

        }


    }
    componentDidMount() {

    }






    render() {

        const { } = this.state;

        return null;
    }
} 