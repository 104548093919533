import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Autocomplete, IconButton, OutlinedInput, TextField, Typography } from '@mui/material';
import { Col, Row, Tag } from 'antd';
import randomColor from 'randomcolor';
import React, { useState } from 'react';

import uuid from 'react-uuid';
import DropdownEditOptions from './dropdownEditOptions';
import { ReactSortable } from 'react-sortablejs';
import { fullMobile } from '../util';
import { DragIndicator } from '@mui/icons-material';
import DataColorGithub from '../../account/dashboard/settings/dataColorGithub';

const hexToRgb = (hex) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}


const DropDownOptions = ({ setOptions, options, userInfo, openSnack }) => {
    const [inputValue, setInputValue] = useState("");





    const addNewOptionOnKey = (key, inputValue) => {
        if (key === 'Tab' || key === 'Enter') {
            const newOption = inputValue.trim();
            if (newOption && !options.includes(newOption)) {
                const baseColor = randomColor({ luminosity: 'light', });
                const rgb = hexToRgb(baseColor);
                const tagColor = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.3)`; // 50% opacity
                const newItem = { id: uuid(), label: newOption, color: baseColor, tagColor: tagColor }
                setOptions([...options, newItem]);
                setInputValue("");
            }
        }
    };

    return (
        <Row>
            <Col span={16}>
                <OutlinedInput
                    size="small"
                    fullWidth
                    value={inputValue}
                    onChange={(e) => {
                        setInputValue(e.target.value);
                    }}

                    onKeyDown={(e) => {
                        if (e.key === 'Tab' || e.key === 'Enter') {
                            e.preventDefault();
                            addNewOptionOnKey(e.key, inputValue);

                        }
                    }}
                    placeholder="Add Option"
                />
            </Col>
            <Col span={4}>
                <IconButton

                    onClick={() => {
                        console.log('test')
                        addNewOptionOnKey('Tab', inputValue);
                    }}

                >
                    <FontAwesomeIcon icon={faPlus} color="#404050" />
                </IconButton>
            </Col>
            <Col span={4}>
                <DropdownEditOptions options={options} userInfo={userInfo} openSnack={openSnack} setOptions={setOptions} />
            </Col>
            <Col span={24}>
                <ReactSortable
                    list={options}
                    setList={setOptions}
                    animation={500}
                    handle=".my-handle"
                >



                    {
                        options.map((option, i) => {
                            return (
                                <Row key={option.id} gutter={[6, 6]} align="middle"
                                    //change background color if index is even
                                    style={{ backgroundColor: i % 2 === 0 ? '#f5f5f5' : '#ffffff', paddingBottom: 5, paddingTop: 5 }}

                                >
                                    <Col className="my-handle" {...fullMobile(2, 2)} style={{ cursor: 'grab' }}>

                                        <DragIndicator />
                                    </Col>
                                    <Col {...fullMobile(16, 16)} style={{ padding: 4 }}>
                                        <Tag color={option.tagColor} style={{
                                            width: '100%', height: 30,
                                            verticalAlign: 'middle',
                                        }} closable={true}
                                            closeIcon={<span style={{ color: 'red', fontWeight: 700, fontSize: 14, marginLeft: 5 }}>X</span>}

                                            onClose={() => {
                                                setOptions(options.filter((e) => {
                                                    return e.id !== option.id;
                                                }))
                                            }}>
                                            <span style={{ color: 'black', verticalAlign: 'middle', fontWeight: 700, fontSize: 18 }}>{option.label}</span>
                                        </Tag>

                                    </Col>
                                    <Col span={4}>
                                        <DataColorGithub color={option.color} updateData={(boo, val) => {
                                            const baseColor = val;
                                            const rgb = hexToRgb(baseColor);
                                            const tagColor = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.3)`; // 50% opacity
                                            const newItem = { ...option, color: baseColor, tagColor: tagColor }
                                            setOptions(options.map((e) => {
                                                if (e.id === option.id) {
                                                    return newItem;
                                                }
                                                return e;
                                            }))

                                        }} />
                                    </Col>
                                </Row>
                            )
                        })
                    }

                </ReactSortable>
            </Col>




        </Row >


    )
}



export { DropDownOptions };
