import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import { Drawer, Button, List, Divider, ListItem, ListItemButton, ListITemIcon, ListItemText, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { faArrowRightFromBracket, faSave, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { readChartData } from './utils/CRUDdata';
import ChartRenderer from './chartComponents.jsx/chartRenderer';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, { attr } from 'highcharts/highstock';
import { updateChartStyle } from './utils/chartStyleFunctions';
import './chartStyles.css';
import { addZoomFunctions } from './utils/chartOptions';
import { updateSelectTool } from './utils/toolFunctionsSelect';
import DataEditorTable from './dataEditorTable';
import moment from 'moment-timezone';

const DataEditorChart = ({ userInfo, attributes, openSnack, widgetKey, openEditor,
    chartData, setChartData, updatedData, setUpdatedData, screenWidth, account,
    tooltip, panning, panKey, zoomType, mouseWheel, selectType
}) => {





    const [isZoomed, setIsZoomed] = useState(false);
    const [loading, setLoading] = useState(true);
    const chartRef = useRef(null);
    const wrapperRef = useRef(null);
    const [chartHeight, setChartHeight] = useState(0)
    const [selectedPoints, setSelectedPoints] = useState([]);
    const [deletedPoints, setDeletedPoints] = useState([]);

    const [chartOptions, setChartOptions] = useState(
        {
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            stockTools: {
                gui: {
                    enabled: false
                }
            },
            chart: {
                events: {
                    selection: selectType === 'select' ? selectPointsByDrag : selectType === 'deselect' ? deselectPointsByDrag : null,
                    click: selectType === 'select' ? unselectByClick : selectType === 'deselect' ? deselectByClick : null,


                },
                zooming: {
                    mouseWheel: {
                        enabled: true,

                    }
                },


                zoomType: 'xy',

            },
            title: {
                text: null
            },
            legend: {
                itemStyle: {
                    fontSize: this?.props?.legendSize !== undefined ? `${this?.props?.legendSize}px` : '14px',
                    fontWeight: '500',
                    fontFamily: 'Roboto, sans-serif'
                },
            },


            series: [],
            yAxis: [{ id: 0 }],
            xAxis: {
                type: 'datetime',
                startOnTick: false,
                endOnTick: false,
                plotLines: [],
                crosshair: {
                    width: 10,
                    color: '#2651d214',
                    dashStyle: 'solid',
                    zIndex: 0,
                },

                title: {
                    text: undefined,
                    style: {
                        fontSize: '12px',  // Increase size as needed
                        fontWeight: '800',  // Set font weight to 500
                        color: 'black'
                    }

                },
                labels: {
                    style: {
                        fontSize: '12px',  // Increase size as needed
                        fontWeight: '700',  // Set font weight to 500
                        color: 'black'
                    }
                },
            },
            tooltip: {
                xDateFormat: '%b %d, %Y %I:%M %p',
                outside: true,
                enabled: false,
                shared: true,
                crosshairs: true,
                useHTML: true, // Enable HTML in tooltips
                formatter: function () {


                    var s = `<b style="font-family: Roboto; font-size: 14px; font-weight: 800;">${moment(this.x).format('MMM DD, YYYY hh:mm A')}</b>`;


                    this.points.forEach((point) => {
                        // Start with the raw value
                        let valueFormatted = point.y;

                        // Format the value based on defined decimals
                        if (point.series.tooltipOptions.valueDecimals !== undefined) {
                            valueFormatted = valueFormatted.toFixed(point.series.tooltipOptions.valueDecimals);
                        }

                        // Add a suffix if it's defined, otherwise, don't add anything
                        valueFormatted += point.series.tooltipOptions.valueSuffix ? point.series.tooltipOptions.valueSuffix : "";

                        // Use toLocaleString for formatting if no decimals are specified
                        if (point.series.tooltipOptions.valueDecimals === undefined) {
                            valueFormatted = parseFloat(valueFormatted).toLocaleString();
                        }

                        // Concatenate the tooltip string
                        s += `<br/><span style="color:${point.series.color}; font-size: 15px;">●</span> <b style="font-family: Roboto; font-size: 13px;">${point.series.name}:</b> <span style="font-family: Roboto; font-size: 13px;">${valueFormatted}</span>`;
                    });

                    return s;
                },
                style: {
                    fontFamily: 'Roboto', // Ensure the tooltip font is Roboto
                    zIndex: 500,
                }
            },



            plotOptions: {
                series: {
                    animation: false,
                    allowPointSelect: true,
                },
                column: {
                    pointWidth: 20 // set the column width for column series
                },

            },
            time: {
                useUTC: false,
            }
        }


    );


    function zoomOut() {

    }

    useEffect(() => {
        if (chartRef.current !== null) {
            const chart = chartRef.current.chart;
            chart.setSize(null, chartHeight, false);
        }
    }, [chartHeight])

    useEffect(() => {
        console.log('EDITING CHART')
    }, [])


    useEffect(() => {
        getInfo(attributes)
    }, [openEditor])
    useEffect(() => {
        console.log('SELECT TYPE CHANGED')
    }, [selectType])

    useEffect(() => {
        /* set all to not selected */
        if (chartRef.current !== null) {
            const chart = chartRef.current.chart;
            chart.series.forEach((serie) => {
                serie.points.forEach((point) => {
                    point.select(false, true);
                });
            }
            )


            checkForSelectedPointsInChart()
        }

    }, [])

    useEffect(() => {
        if (chartRef.current !== null) {


            const chart = chartRef.current.chart;
            /* update state of ChartOptions */
            setChartOptions(chartOptions => {
                return {
                    ...chartOptions,
                    chart: {
                        events: {
                            selection: selectType === 'select' ? selectPointsByDrag : selectType === 'deselect' ? deselectPointsByDrag : null,
                            click: selectType === 'select' ? unselectByClick : selectType === 'deselect' ? deselectByClick : null,
                        },
                        zoomType: selectType === 'select' ? 'xy' : selectType === 'deselect' ? 'xy' : selectType === 'zoom' ? 'x' : 'xy',
                        panning: { enabled: panning },
                        panKey: panKey,
                    },
                    tooltip: {
                        enabled: tooltip,
                    },
                    plotOptions: {
                        series: {
                            allowPointSelect: selectType === 'select' ? true : selectType === 'deselect' ? true : false,
                        },
                    },
                }
            })

        }
    }, [selectType, tooltip, panning, panKey, zoomType, mouseWheel])

    function toast(chart, text) {
        if (chart.toast) {
            chart.toast.destroy(); // Remove the existing toast before creating a new one
        }
        chart.toast = chart.renderer.label(text, 100, 120)
            .attr({
                fill: Highcharts.getOptions().colors[0],
                padding: 10,
                r: 5,
                zIndex: 8
            })
            .css({
                color: '#FFFFFF'
            })
            .add();
    }

    function selectPointsByDrag(e) {
        const chart = this; // 'this' context is the chart instance


        // Check if xAxis and yAxis properties exist and have at least one item
        if (!e.xAxis || e.xAxis.length === 0 || !e.yAxis || e.yAxis.length === 0) {
            return false; // Exit the function if the necessary data is missing
        }

        // Further check if the 'min' and 'max' properties exist in the first item of xAxis and yAxis arrays
        if (typeof e.xAxis[0].min === undefined || typeof e.xAxis[0].max === undefined ||
            typeof e.yAxis[0].min === undefined || typeof e.yAxis[0].max === undefined) {
            return false; // Exit the function if any of the min or max properties are missing
        }

        chart.series.forEach(series => {
            if (series.visible === true) {
                series.points.forEach(point => {
                    if (point.x >= e.xAxis[0].min && point.x <= e.xAxis[0].max &&
                        point.y >= e.yAxis[0].min && point.y <= e.yAxis[0].max) {
                        point.select(true, true);
                    }
                });
            }
        });


        checkForSelectedPointsInChart(); // Check if points are selected and log them to console
        return false; // Prevent chart from zooming
    }




    function deselectPointsByDrag(e) {

        const chart = this; // 'this' context is the chart instance

        // Necessary data check (same as your selectPointsByDrag function)
        if (!e.xAxis || e.xAxis.length === 0 || !e.yAxis || e.yAxis.length === 0) {
            return false;
        }
        if (typeof e.xAxis[0].min === undefined || typeof e.xAxis[0].max === undefined ||
            typeof e.yAxis[0].min === undefined || typeof e.yAxis[0].max === undefined) {
            return false;
        }

        chart.series.forEach(series => {
            if (series.visible === true) {
                series.points.forEach(point => {
                    if (point.x >= e.xAxis[0].min && point.x <= e.xAxis[0].max &&
                        point.y >= e.yAxis[0].min && point.y <= e.yAxis[0].max &&
                        point.selected) { // Check if the point is currently selected
                        point.select(false, true); // Deselect the point
                    }
                });
            }
        });

        checkForSelectedPointsInChart(); // Update if necessary

        return false; // Prevent default chart zoom
    }

    function deselectByClick(e, b) {


        try {
            const chart = this;
            const clickedPoint = e.point; // Get the point that was clicked


            // Check if the clicked point is selected
            if (clickedPoint && clickedPoint.selected) {
                clickedPoint.select(false, true); // Deselect the point

                checkForSelectedPointsInChart(); // Update if necessary

            }
        }
        catch (error) {
            console.log(error)
        }
    }


    function unselectByClick() {
        const chart = this; // 'this' context is the chart instance
        chart.getSelectedPoints().forEach(point => point.select(false));
        setSelectedPoints([]); // Clear selected points state
        localStorage.removeItem('selectedPoints'); // Clear selected points from local storage
        // Optionally, update toast to reflect no points are selected
        toast(chart, "No points selected.");
        /* delete toast */
        if (chart.toast) {
            chart.toast = chart.toast.destroy();
        }
    }




    async function getInfo(attributes) {
        try {
            const newAttributes = JSON.parse(JSON.stringify(attributes))

            console.log(newAttributes)
            console.log(chartOptions)
            /* check for zoom at local storage */
            const zoom_range = JSON.parse(localStorage.getItem(`${widgetKey}-zoom_range`)) || null;
            setLoading('loadingChartStyle')
            await updateChartStyle({ chartOptions, setChartOptions, attributes: newAttributes })
            setLoading('loadingChartData')
            await readChartData({ attributes: newAttributes, userInfo, widgetKey, openSnack, setChartOptions, chartOptions, chartData, setChartData, zoom_range })

            /* sort all data in chartOptions series by time */
            chartOptions.series.forEach((series) => {
                series.data.sort((a, b) => {
                    return a['x'] - b['x'];
                })
            })




            /* redraw chart */
            if (chartRef.current !== null) {
                const chart = chartRef.current.chart;
                chart.update(chartOptions, true, true, true);
            }
            setLoading(false);
        }
        catch (error) {
            openSnack('error', 'Error loading chart data')
            setLoading(false);
        }
        setTimeout(() => {
            updateChartHeight()
        }, 1000)
    }


    const chartWrapper = useRef(null);

    async function updateChartHeight() {
        if (chartWrapper.current) {
            setChartHeight(chartWrapper.current.clientHeight)
        }
    }

    /* detect change in chartWrapper height */
    useEffect(() => {
        if (chartWrapper.current) {
            setChartHeight(chartWrapper.current.clientHeight)
        }
    }
        , [chartWrapper.current?.clientHeight])


    const props = { userInfo, screenWidth, account, selectedPoints, setSelectedPoints, openSnack, deletedPoints, setDeletedPoints, unselectFromGraph }

    async function checkForSelectedPointsInChart() {
        if (chartRef.current === null) {
            return;
        }
        // Function to get selected points
        const getSelectedPoints = () => {
            const chart = chartRef.current.chart;
            let selected = [];

            chart.series.forEach((serie) => {
                selected = selected.concat(serie.points.filter(point => point.selected));
            });

            return selected;
        };

        // Example usage: Log selected points or set them to state
        const selected = getSelectedPoints();

        const fullSelected = selected.map(point => ({ ...point.options, ...point.options?.custom, selected: false, }))
        /* filter out any items in fullSelected that are {} */
        const filteredSelected = fullSelected.filter((point) => Object.keys(point).length !== 0);

        localStorage.setItem('selectedPoints', JSON.stringify(filteredSelected));
        setSelectedPoints(filteredSelected); // Storing selected points in state


    }

    async function unselectFromGraph(points, newData) {

        if (chartRef.current !== null) {
            try {
                // Assuming that chartRef.current contains the chart instance
                const chart = chartRef.current.chart;
                points.forEach((point) => {

                    //get series index by key
                    const seriesIndex = chart.series.findIndex((series) => series.options?.key === point.seriesKey);
                    //get point index by id


                    const pointIndex = chart.series[seriesIndex].data.findIndex((dataPoint) => dataPoint.options.id === point.id);
                    //update point

                    chart.series[seriesIndex].data[pointIndex].select(false, false);
                });
                /* select the following newData */
                newData.forEach((point) => {
                    //get series index by key
                    const seriesIndex = chart.series.findIndex((series) => series.options?.key === point.seriesKey);
                    //get point index by id
                    const pointIndex = chart.series[seriesIndex].data.findIndex((dataPoint) => dataPoint.options.id === point.id);
                    //update point
                    chart.series[seriesIndex].data[pointIndex].select(true, true);
                });
                checkForSelectedPointsInChart(); // Update if necessary
            }
            catch (error) {
                console.log(error)

            }
        }
    }


    useEffect(() => {
        const prevData = JSON.parse(localStorage.getItem('selectedPoints')) || [];
        if (JSON.stringify(selectedPoints) !== JSON.stringify(prevData)) {

            if (chartRef.current !== null) {
                try {
                    // Assuming that chartRef.current contains the chart instance
                    const chart = chartRef.current.chart;





                    selectedPoints.forEach((point) => {
                        //get series index by key

                        const seriesIndex = chart.series.findIndex((series) => series.options?.key === point.seriesKey);
                        //get point index by id
                        const pointIndex = chart.series[seriesIndex].data.findIndex((dataPoint) => dataPoint.options.id === point.id);
                        //update point
                        chart.series[seriesIndex].data[pointIndex].update(point.y);
                    });




                    // Save the new points to localStorage
                    localStorage.setItem('selectedPoints', JSON.stringify(selectedPoints));
                }
                catch (error) {
                    console.log(error)
                }
            }

        } else {
            console.log('Data matches prevData');
        }
    }, [selectedPoints, chartRef, chartOptions]);

    useEffect(() => {


        if (chartRef.current !== null) {
            try {
                // Assuming that chartRef.current contains the chart instance
                const chart = chartRef.current.chart;





                deletedPoints.forEach((point) => {
                    //get series index by key

                    const seriesIndex = chart.series.findIndex((series) => series.options?.key === point.seriesKey);
                    //get point index by id

                    const pointIndex = chart.series[seriesIndex].data.findIndex((dataPoint) => dataPoint.options.id === point.id);
                    //update point
                    chart.series[seriesIndex].data[pointIndex].remove();
                });





            }
            catch (error) {
                console.log(error)
            }
        }

    }, [deletedPoints]);



    if (loading !== false || chartOptions === null) {
        return (
            <Box style={{ position: 'relative', height: '90%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography variant="h6" fontWeight={600} fontSize={24} >Loading Chart </Typography>
                <FontAwesomeIcon icon={faSpinner} size="2xl" color="#3f51b5" spin />
            </Box>
        )
    }
    else {
        return (
            <>
                <Grid ref={chartWrapper} item style={{ flexGrow: 1, height: '55vh' }}> {/* Flex item that grows */}

                    <Box

                        style={{
                            border: '1px solid rgba(0,0,0,0.3)',
                            height: '100%',
                            width: '100%',

                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center', //


                        }}>

                        <HighchartsReact
                            constructorType={"chart"}
                            highcharts={Highcharts}
                            ref={chartRef}
                            options={chartOptions}
                            allowChartUpdate={true}
                            immutable={false}
                            updateArgs={[true, true, true]}
                        />

                    </Box>
                </Grid>
                <Grid item style={{ flexGrow: 1, }}> {/* Fixed height */}
                    <DataEditorTable {...props} />

                </Grid>

            </>




        );
    }

}

export default DataEditorChart;