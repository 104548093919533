import { Button, Checkbox, Divider, OutlinedInput, Typography, Select, MenuItem, CircularProgress, Paper } from '@mui/material';
import { Col, Row } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment-timezone';
import { fullMobile } from '../../util';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, } from 'firebase/firestore';
import { faBus, faBullseye, faCircleDot, faMapPin, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import uuid from 'react-uuid';
import html2canvas from 'html2canvas';
import axios, * as others from 'axios';
import {
    GoogleMap,
    StandaloneSearchBox,
    DrawingManager,
    LoadScript,
    HeatmapLayer,
    useJsApiLoader
} from '@react-google-maps/api';
import queryString from 'query-string';
import LogMapTable from './logMapTable';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import HomeIcon from '@mui/icons-material/Home';
import * as ReactDOMServer from 'react-dom/server';
const lib = ['places', 'visualization', 'drawing'];



export default function LogLineItemMap(props) {
    const { userInfo, screenWidth, openSnack, company, account, updateLog, logRef, status, updateMapItems, updateItem, dataItem } = props;
    const [loading, setLoading] = useState(true);
    const [center, setCenter] = useState(null);
    const [zoom, setZoom] = useState(null);
    const [accountProps, setAccountProps] = useState(account);
    const [labels, setLabels] = useState([]);
    const [mapItems, setMapItems] = useState([]);
    const [showMapImg, setShowMapImg] = useState(false);
    const [paths, setPaths] = useState([]);
    const [staticUrl, setStaticUrl] = useState('');
    const mapRef = useRef();




    useEffect(() => {

    }, [dataItem])

    function addMapItemstoMap(mapItems) {
        mapItems.forEach((item) => {

            if (item.type === 'polygon') {
                addPolygon(item)
            }
            if (item.type === 'marker' && item !== undefined) {
                addPolygonMarker(item)
            }

        })
    }


    function addPolygonMarker(item) {
        console.log(item)
        if (item !== undefined) {
            const markerCoords = JSON.parse(item?.position)
            const marker = new window.google.maps.Marker({
                position: markerCoords,
                label: {
                    text: item.label.toString(),
                    color: 'white',
                    fontSize: '12px',
                    fontWeight: 'bold',

                },
                map: mapRef.current,
                editable: true,
                draggable: true,
                key: item.key,
                zIndex: 1,
                fillColor: item.fillColor,
            });

            const markerInfo = {
                type: 'marker',
                position: JSON.stringify(marker.getPosition()),
                fillColor: '#b4d5e4',
                fillOpacity: .5,
                strokeWeight: 1,
                clickable: true,
                editable: true,
                draggable: true,
                zIndex: 1,
                geodesic: true,
                key: item.key,
                label: item.label,
                marker: marker,
            };

            mapItems.push(markerInfo);

            addMarkerMarker(marker, item.label);
        }

    }









    function addPolygon(item) {
        const pathCoords = JSON.parse(item.path)
        const polyCoords = pathCoords.map(path => {

            const coord = new window.google.maps.LatLng(path.lat, path.lng);
            return coord;
        });
        const newPolygon = new window.google.maps.Polygon({
            paths: polyCoords,
            strokeColor: item.strokeColor,
            strokeOpacity: item.strokeOpacity,
            strokeWeight: item.strokeWeight,
            fillColor: item.fillColor,
            fillOpacity: item.fillOpacity,
            clickable: true,
            editable: true,
            draggable: true,
            zIndex: 1,
            geodesic: true,
            map: mapRef.current,
            key: item.key,
        });

        const polygonInfo = {
            type: 'polygon',
            path: JSON.stringify(newPolygon.getPath().getArray()),
            fillColor: '#b4d5e4',
            fillOpacity: .5,
            strokeWeight: 1,
            clickable: true,
            editable: true,
            draggable: true,
            zIndex: 1,
            geodesic: true,
            key: item.key,
            label: item.label,
            area: 0,
            polygon: newPolygon,
        };

        mapItems.push(polygonInfo);

        calculateArea(polygonInfo.polygon);

        addMarker(newPolygon, item.label);




    }


    function addMarker(polygon, label) {
        const polygonCenter = polygon.getPath().getArray().reduce((acc, cur) => {
            return { lat: acc.lat + cur.lat(), lng: acc.lng + cur.lng() };
        }, { lat: 0, lng: 0 });

        polygonCenter.lat /= polygon.getPath().getLength();
        polygonCenter.lng /= polygon.getPath().getLength();
        const location = { lat: polygonCenter.lat, lng: polygonCenter.lng }


        const marker = new window.google.maps.Marker({
            position: location,
            label: {
                text: label.toString(),
                color: 'white',
                fontSize: '12px',
                fontWeight: 'bold',

            },
            map: mapRef.current,
        });

        //add to labels array
        labels.push(marker);

        addDeletePolygonFunction(polygon, marker, label);
        polygon.addListener('mouseup', () => {
            const polygonCenter = polygon.getPath().getArray().reduce((acc, cur) => {
                return { lat: acc.lat + cur.lat(), lng: acc.lng + cur.lng() };
            }, { lat: 0, lng: 0 });
            marker.setPosition({ lat: polygonCenter.lat / polygon.getPath().getArray().length, lng: polygonCenter.lng / polygon.getPath().getArray().length });
            updateStaticMap();
            calculateArea(polygon);
            updateCoords(polygon);

        })
        addDeleteVertextFunction(polygon, marker);
        updateStaticMap();

    }

    function addMarkerMarker(markerItem, label) {
        console.log(markerItem)

        console.log(markerItem.getPosition())
        const coord = JSON.parse(JSON.stringify(markerItem.getPosition()))
        const location = { lat: coord.lat, lng: coord.lng }

        console.log(location)


        const marker = new window.google.maps.Marker({
            position: location,
            editable: true,
            draggable: true,
            clickable: true,
            label: {
                text: label.toString(),
                color: 'white',
                fontSize: '12px',
                fontWeight: 'bold',

            },
            map: mapRef.current,
        });

        //add to labels array
        labels.push(marker);

        addDeletePolygonFunction(markerItem, marker, label);
        markerItem.addListener('mouseup', () => {


            const coord = JSON.parse(JSON.stringify(markerItem.getPosition()))
            marker.setPosition({ lat: coord.lat, lng: coord.lng });
            updateStaticMap();
            updateMarkerCoords(markerItem);

        })
        updateStaticMap();

    }


    function updateCoords(polygon) {
        const polygonInfo = mapItems.find(item => item.polygon === polygon);
        polygonInfo.path = JSON.stringify(polygon.getPath().getArray());
        updateStaticMap();
        //update mapitems
        updateMapItems(mapItems);

    }

    function updateMarkerCoords(marker) {
        const markerInfo = mapItems.find(item => item.marker === marker);
        markerInfo.path = JSON.stringify(marker.getPosition());
        updateStaticMap();
        //update mapitems
        updateMapItems(mapItems);

    }




    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        libraries: lib,
        googleMapsApiKey: "AIzaSyC6fuuMNV3Wvo5M3_AXavGzKDYMBcuPooA",

    })


    function addCurrentLocationButton() {
        //create html from <MyLocationIcon />
        const html = ReactDOMServer.renderToString(<MyLocationIcon />);


        var currentLocationButton = document.createElement('button');
        currentLocationButton.innerHTML = html;
        currentLocationButton.style.cursor = 'pointer';
        //button height and width to 40px
        currentLocationButton.style.height = '40px';
        currentLocationButton.style.width = '40px';
        // increase text size and add padding
        currentLocationButton.style.padding = '2px';
        //add border radius and white background
        currentLocationButton.style.borderRadius = '4px';
        currentLocationButton.style.backgroundColor = 'white';
        //add box shadow
        currentLocationButton.style.boxShadow = '0 1px 4px rgba(0,0,0,0.3)';


        currentLocationButton.classList.add('custom-map-control-button');
        mapRef.current.controls[window.google.maps.ControlPosition.RIGHT_TOP].push(currentLocationButton);
        currentLocationButton.addEventListener('click', () => {
            // Try HTML5 geolocation.
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const pos = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        };
                        mapRef.current.setCenter(pos);

                    },
                    () => {
                        handleLocationError(true, infoWindow, mapRef.current.getCenter());
                    }
                );
            } else {
                // Browser doesn't support Geolocation
                handleLocationError(false, infoWindow, mapRef.current.getCenter());
            }
        });
    }

    function handleLocationError(browserHasGeolocation, infoWindow, pos) {
        infoWindow.setPosition(pos);
        infoWindow.setContent(
            browserHasGeolocation

                ? "Error: The Geolocation service failed."
                : "Error: Your browser doesn't support geolocation."
        );
        infoWindow.open(mapRef.current);
    }


    const infoWindow = new window.google.maps.InfoWindow();

    function addToAccountLocationButton(info) {
        const html = ReactDOMServer.renderToString(<HomeIcon />);


        var currentLocationButton = document.createElement('button');
        currentLocationButton.innerHTML = html;
        currentLocationButton.style.cursor = 'pointer';
        // increase text size and add padding
        currentLocationButton.style.height = '40px';
        currentLocationButton.style.width = '40px';
        // increase text size and add padding
        currentLocationButton.style.padding = '2px';
        //add border radius and white background
        currentLocationButton.style.borderRadius = '4px';
        currentLocationButton.style.backgroundColor = 'white';
        //add box shadow
        currentLocationButton.style.boxShadow = '0 1px 4px rgba(0,0,0,0.3)';




        currentLocationButton.classList.add('custom-map-control-button');
        mapRef.current.controls[window.google.maps.ControlPosition.RIGHT_TOP].push(currentLocationButton);

        currentLocationButton.addEventListener('click', (e) => {

            mapRef.current.setCenter({ lat: account.lat, lng: account.lng });


        });
    }

    function addDeleteVertextFunction(polygon, label) {
        window.google.maps.event.addListener(polygon, 'rightclick', function (event) {

            if (event.vertex == undefined) {
                return;
            }

            //if menu is display do nothing

            polygon.getPath().removeAt(event.vertex);
            const polygonCenter = polygon.getPath().getArray().reduce((acc, cur) => {
                return { lat: acc.lat + cur.lat(), lng: acc.lng + cur.lng() };
            }, { lat: 0, lng: 0 });
            label.setPosition({ lat: polygonCenter.lat / polygon.getPath().getArray().length, lng: polygonCenter.lng / polygon.getPath().getArray().length });
            const index = mapItems.findIndex((item) => item.key === polygon.key);
            const polygonInfo = mapItems[index];
            calculateArea(polygonInfo.polygon);
            updateCoords(polygonInfo.polygon)
            updateStaticMap();


        });
    }

    function addDeletePolygonFunction(polygon, label, text) {
        //right click to open context menu
        window.google.maps.event.addListener(polygon, 'rightclick', function (event) {

            //remove div if exists
            const uniqueKey = uuid()
            var menu = document.getElementById(`menu-${uniqueKey}`);
            if (menu) {
                menu.parentNode.removeChild(menu);
            }
            //create div and show next to polygon

            //get label text from label which is a marker


            var menu = document.createElement('div');
            menu.id = `menu-${uniqueKey}`;
            menu.style.position = 'absolute';
            menu.style.width = '100px';
            menu.style.height = '60px';
            menu.style.background = 'white';
            menu.style.border = '1px solid black';
            menu.style.zIndex = 1000;
            menu.style.display = 'block';
            menu.innerHTML = `Delete Area ${text}`;
            menu.style.cursor = 'pointer';
            menu.style.padding = '5px';
            menu.style.boxShadow = '0 0 5px rgba(0,0,0,0.5)';
            menu.style.borderRadius = '5px';
            menu.style.fontSize = '12px';
            menu.style.fontFamily = 'Arial';
            menu.style.textAlign = 'center';
            menu.style.color = 'black';
            menu.style.backgroundColor = 'white';
            menu.style.opacity = '0.9';

            //add div to map if vertex was not clicked
            if (event.vertex != undefined) {
                return;
            }

            //remove menu from map if already exists
            if (menu.parentNode) {
                menu.parentNode.removeChild(menu);
            }

            mapRef.current.controls[window.google.maps.ControlPosition.TOP_LEFT].push(menu);

            //delete polygon and associated label on click
            menu.addEventListener('click', () => {
                polygon.setMap(null);
                label.setMap(null);
                //remove label from labels
                const index = labels.indexOf(label);
                if (index > -1) {
                    labels.splice(index, 1);
                }
                //remove polygon from mapItems
                const index2 = mapItems.map((item) => item.key).indexOf(polygon.key);

                if (index2 > -1) {
                    mapItems.splice(index2, 1);
                    setMapItems([...mapItems]);

                    updateMapItems([...mapItems]);

                }


                menu.style.display = 'none';
            });

            //close menu if right click again
            window.google.maps.event.addListener(mapRef.current, 'rightclick', (event) => {

                if (event.vertex !== undefined) {
                    return;
                }
                menu.style.display = 'none';
            });



            //close context menu
            window.google.maps.event.addListener(mapRef.current, 'click', function () {
                menu.style.display = 'none';
                updateStaticMap();
            });
        });
    }

    function calculateArea(polygon) {
        console.log(polygon)
        if (polygon !== undefined && window.google.maps.geometry !== undefined) {
            const area = window.google.maps.geometry.spherical.computeArea(polygon.getPath());
            //area to acres
            const areaInAcres = area * 0.000247105;
            //update area in mapitems
            const index = mapItems.findIndex(item => item.key === polygon.key);
            mapItems[index].area = areaInAcres;
            setMapItems([...mapItems]);

            updateMapItems([...mapItems]);
        }

    }

    function updateStaticMap() {




        const markersList = [];
        const pathsList = [];
        if (mapItems.length > 0) {
            mapItems.forEach((item, i) => {
                //get center of item.polygon

                console.log(item)

                if (item.polygon !== undefined && window.google.maps.geometry !== undefined) {


                    const polygonCenter = item.polygon.getPath().getArray().reduce((acc, cur) => {
                        return { lat: acc.lat + cur.lat(), lng: acc.lng + cur.lng() };
                    }, { lat: 0, lng: 0 });
                    const itemCenter = { lat: polygonCenter.lat / item.polygon.getPath().getArray().length, lng: polygonCenter.lng / item.polygon.getPath().getArray().length }
                    //get lat of center

                    const markerString = `&markers=color:red%7Clabel:${item.label}%7C${itemCenter.lat},${itemCenter.lng}${mapItems.length > 1 ? '%7C' : ''}`
                    markersList.push(markerString);
                    const pathString = `&path=fillcolor:0xB4D5e4%7Cweight:1%7Ccolor:blue%7Cenc:${window.google.maps.geometry.encoding.encodePath(item.polygon.getPath())}`
                    pathsList.push(pathString);
                }

                if (item.marker !== undefined) {
                    console.log(item.marker.position.lat())
                    console.log(item.marker.position.lng())
                    const markerString = `&markers=color:red%7Clabel:${item.label}%7C${item.marker.position.lat()},${item.marker.position.lng()}${mapItems.length > 1 ? '%7C' : ''}`
                    markersList.push(markerString);

                }






            })

            console.log(markersList)


            //create string ffrom markersList
            const markers = markersList.toString();
            //remove commas from pathList
            const pathListString = pathsList.toString().replace(/,/g, '');




            const center = mapRef.current.getCenter();
            if (center !== null) {
                const lat = center.lat();
                const lng = center.lng();
                const zoom = mapRef.current.getZoom();
                const url = `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=${zoom}&size=400x300&maptype=satellite${markers}${pathListString}&key=AIzaSyC6fuuMNV3Wvo5M3_AXavGzKDYMBcuPooA`;

                updateItem('staticUrl', url);
            }



        }
        else {

            const center = mapRef.current.getCenter();
            console.log(center);

            const lat = center === null ? account.lat : center.lat();
            const lng = center === null ? account.lng : center.lng();
            const zoom = center === null ? 16 : mapRef.current.getZoom();

            const url = `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=${zoom}&size=400x300&maptype=satellite&key=AIzaSyC6fuuMNV3Wvo5M3_AXavGzKDYMBcuPooA`;
            updateItem('staticUrl', url);



        }
    }






    function addDrawingManager() {
        const drawingManager = new window.google.maps.drawing.DrawingManager({
            drawingMode: window.google.maps.drawing.OverlayType.MARKER,
            drawingControl: true,
            drawingControlOptions: {
                position: window.google.maps.ControlPosition.LEFT_CENTER,
                drawingModes: ['polygon', 'marker'],
            },
            polygonOptions: {
                fillColor: '#b4d5e4',
                fillOpacity: .5,
                strokeWeight: 1,
                clickable: true,
                editable: true,
                draggable: true,
                zIndex: 1,
                geodesic: true,

            },
            markerOptions: {
                draggable: true,
                clickable: true,
                editable: true,
                geodesic: true,
                zIndex: 1,
                label: {
                    text: '',
                    color: 'white',
                    fontSize: '12px',
                    fontWeight: 'bold',
                },

            }

        });
        drawingManager.setMap(mapRef.current);
        drawingManager.setDrawingMode(null);






        window.google.maps.event.addListener(drawingManager, 'overlaycomplete', (e) => {
            //get path of polygon


            console.log(e.type)
            console.log(e)
            if (e.type !== window.google.maps.drawing.OverlayType.MARKER) {
                // Switch back to non-drawing mode after drawing a shape.
                drawingManager.setDrawingMode(null);
                // To hide:

            }


            //add key to e.overlay and add to map items
            const uniqueKey = uuid();

            e.overlay.key = uniqueKey;

            //marker label should be the next label number, but not 0 and not a duplicate
            const currentLabels = labels.map(label => label.label.text);

            let labelNumber = 1;
            while (currentLabels.includes(labelNumber.toString()) || labelNumber === 0) {
                labelNumber++;
            }


            if (e.type === 'polygon') {


                const polygonInfo = {
                    type: 'polygon',
                    path: JSON.stringify(e.overlay.getPath().getArray()),
                    fillColor: '#b4d5e4',
                    fillOpacity: .5,
                    strokeWeight: 1,
                    clickable: true,
                    editable: true,
                    draggable: true,
                    zIndex: 1,
                    geodesic: true,
                    key: uniqueKey,
                    label: labelNumber,
                    area: 0,
                    polygon: e.overlay,
                };



                mapItems.push(polygonInfo);
                calculateArea(polygonInfo.polygon);
                addMarker(e.overlay, labelNumber);

            }

            if (e.type === 'marker') {
                console.log(e.overlay.getPosition())
                console.log(JSON.stringify(e.overlay.getPosition()))
                //add a label to the marker
                e.overlay.label = {
                    text: labelNumber.toString(),
                    color: 'white',
                    fontSize: '12px',
                    fontWeight: 'bold',
                };


                const markerInfo = {
                    type: 'marker',
                    position: JSON.stringify(e.overlay.getPosition()),
                    draggable: true,
                    clickable: true,
                    zIndex: 1,
                    label: labelNumber,
                    key: uniqueKey,
                    marker: e.overlay,
                };
                mapItems.push(markerInfo);
                labels.push(e.overlay);

                const coord = JSON.parse(JSON.stringify(e.overlay.getPosition()))
                e.overlay.setPosition({ lat: coord.lat, lng: coord.lng });
                updateStaticMap();
                updateMarkerCoords(e.overlay);

                e.overlay.addListener('mouseup', () => {


                    const coord = JSON.parse(JSON.stringify(e.overlay.getPosition()))
                    e.overlay.setPosition({ lat: coord.lat, lng: coord.lng });
                    updateStaticMap();
                    updateMarkerCoords(e.overlay);

                })

                updateStaticMap();

            }














        });
    }

    function addShapes() {
        if (dataItem !== undefined) {

            setMapItems(dataItem.mapItems)

            if (dataItem.mapItems !== undefined && dataItem.mapItems.length > 0) {

                addMapItemstoMap(dataItem.mapItems)
            }

        }
        if (dataItem.center !== undefined) {
            //set map center to dataItem center
            mapRef.current.setCenter(dataItem.center)
            //set zoom to dataItem zoom


        }
    }



    function handleLoad(map) {
        mapRef.current = map;
        addCurrentLocationButton();
        addToAccountLocationButton();
        addDrawingManager();
        addMapTracker()
        setShowMapImg(true)
        updateStaticMap()
        addShapes()

        if (dataItem.center !== undefined) {
            //set map center to dataItem center
            mapRef.current.setCenter(dataItem.center)
            //set zoom to dataItem zoom

            mapRef.current.setZoom(dataItem.zoom)

        }
        else {
            mapRef.current.setZoom(16)
            //set center to account location
            mapRef.current.setCenter({ lat: account.lat, lng: account.lng })
        }


    }

    function addMapTracker(map) {

        //liste for ozom in or out on map and setZoom

        //add a listener for zoom_changed and dragend


        mapRef.current.addListener('zoom_changed', () => {
            const zoom = mapRef.current.getZoom();
            const center = mapRef.current.getCenter();
            if (center !== null) {
                const lat = center.lat();
                const lng = center.lng();

                updateItem('center', { lat: lat, lng: lng })
                updateItem('zoom', zoom);
                updateStaticMap()
            }


        })

        //add listener for dragend 
        mapRef.current.addListener('dragend', () => {
            const center = mapRef.current.getCenter();
            if (center !== null) {
                const lat = center.lat();
                const lng = center.lng();
                const zoom = mapRef.current.getZoom();
                updateItem('zoom', zoom);
                updateItem('center', { lat: lat, lng: lng })
                updateStaticMap()
            }



        })
    }







    if (isLoaded && Object.keys(accountProps).length > 0) {
        return (
            <div id="linemap" style={{ height: 300, width: '100%', paddingBottom: 20 }}>



                <GoogleMap
                    ref={mapRef}
                    mapContainerStyle={{ height: '100%', width: '100%' }}
                    center={center}
                    onLoad={handleLoad}
                    zoom={zoom}
                    mapTypeId="satellite"
                    controlSize={32}
                    options={{
                        scaleControl: true,
                        //satellite view
                        mapTypeId: 'satellite',
                        //disable street view
                        streetViewControl: false,
                        //disable zoom control
                        rotateControl: false,
                        //disable fullscreen control
                        fullscreenControl: false,
                        tilt: 0,
                        //disable keyboard shortcuts
                    }}
                >





                </GoogleMap>



            </div >
        )
    }

    return (

        <CircularProgress style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />

    )


}