import { Button, Checkbox, Divider, OutlinedInput, Typography, Select, MenuItem, CircularProgress, Breadcrumbs, FormGroup, FormControlLabel, Autocomplete, Chip, TextField, IconButton, Fab, ButtonGroup } from '@mui/material';
import { Col, Popconfirm, Row } from 'antd';
import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { fullMobile } from './../util';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, } from 'firebase/firestore';

import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import uuid from 'react-uuid';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';

import DatePicker from "react-datepicker";
import queryString from 'query-string';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { queryPSItems } from '../../../firebase/config';
import { DeleteForever } from '@mui/icons-material';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { faTablets, faToolbox } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
var randomColor = require('randomcolor');


const style = {
    row: {
        paddingTop: 15,
        paddingBottom: 15,
        paddingLeft: 15,
        paddingRight: 15,
        //align center in div


    }
}


export default function PumpItem(props) {
    const { userInfo, screenWidth, openSnack, company, account, item, updateItem } = props;
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        console.log(item)
    }, [])










    return (
        <Row >
            <Col {...fullMobile(24, 24)} style={style.row} >
                <Typography variant="h5" style={{ fontWeight: 500, color: '#404050' }}>
                    PUMP INFORMATION
                </Typography>
            </Col>
            <Col {...fullMobile(12, 24)} style={{ ...style.row, backgroundColor: 'white' }} >
                <Typography variant="body1" style={{ fontWeight: 600, color: '#404050' }}>
                    Serial #
                </Typography>
                <OutlinedInput
                    size="small"
                    fullWidth
                    id="outlined-adornment-amount"
                    defaultValue={item?.serialNumber}
                    sx={{
                        //disable chrome autocomplete


                    }}
                    onBlur={(e) => {
                        updateItem('serialNumber', e.target.value,)
                    }}

                />
            </Col>
            <Col {...fullMobile(12, 24)} style={{ ...style.row, backgroundColor: 'white' }} >
                <Typography variant="body1" style={{ fontWeight: 600, color: '#404050' }}>
                    Model
                </Typography>
                <OutlinedInput
                    size="small"
                    fullWidth
                    id="outlined-adornment-amount"
                    defaultValue={item?.model}
                    sx={{
                        //disable chrome autocomplete


                    }}
                    onBlur={(e) => {
                        updateItem('model', e.target.value,)
                    }}

                />
            </Col>
            <Col {...fullMobile(12, 24)} style={{ ...style.row, backgroundColor: 'white' }} >
                <Typography variant="body1" style={{ fontWeight: 600, color: '#404050' }}>
                    Manufacturer
                </Typography>
                <OutlinedInput
                    size="small"
                    fullWidth
                    id="outlined-adornment-amount"
                    defaultValue={item?.manufacturer}
                    sx={{
                        //disable chrome autocomplete


                    }}
                    onBlur={(e) => {
                        updateItem('manufacturer', e.target.value,)
                    }}

                />
            </Col>
            <Col {...fullMobile(12, 24)} style={{ ...style.row, backgroundColor: 'white' }} >
                <Typography variant="body1" style={{ fontWeight: 600, color: '#404050' }}>
                    Horse Power
                </Typography>
                <OutlinedInput
                    size="small"
                    fullWidth
                    id="outlined-adornment-amount"
                    defaultValue={item?.horsePower}
                    sx={{
                        //disable chrome autocomplete


                    }}
                    onBlur={(e) => {
                        updateItem('horsePower', e.target.value,)
                    }}

                />
            </Col>
            <Col {...fullMobile(24, 24)} style={{ ...style.row, backgroundColor: 'white' }} >
                <Typography variant="body1" fontWeight={500} component="h4"  >
                    Additional Information
                </Typography>


                <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                    multiline={true}
                    rows={4}
                    defaultValue={item?.pumpAdditional}
                    onBlur={(e) => {
                        updateItem(`pumpAdditional`, e.target.value)
                    }} />
            </Col>

        </Row>



    )



}