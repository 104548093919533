import React from "react";
import { Input, Typography } from "@mui/material";

const MoneyInput = ({
    currentTask,
    setCurrentTask,
    headerKey


}) => {
    const [amount, setAmount] = React.useState(currentTask?.[headerKey] || '');  // Renamed to 'amount' for clarity.

    const handleChange = (event) => {
        let val = event.target.value;


        console.log({ ...currentTask, [headerKey]: val })
        setCurrentTask({ ...currentTask, [headerKey]: val })
        setAmount(val);

    };

   

    return (
        <Input
            fullWidth
            variant="standard"
            id="money-input"
            type="number"
            size="small"
            margin="dense"
            placeholder="Enter amount"
            value={amount}
            onChange={handleChange}
            inputProps={{
                step: 0.01 // Allows for cent values.
            }}
            startAdornment={
                <span style={{ marginRight: 5, fontSize: 13, fontWeight: 500 }}>
                    <Typography variant="body2" fontWeight={500} fontSize={14}>$</Typography>
                </span>
            }
            sx={{
                '& .MuiInputBase-input': {
                    color: 'black',
                    fontWeight: 500,
                    fontSize: 14,
                },
            }}
        />
    );
};

export default MoneyInput;
