export const AlabamaCounties = [
    { "countyCode": "1", "countyName": "autauga" },
    { "countyCode": "2", "countyName": "baldwin" },
    { "countyCode": "3", "countyName": "barbour" },
    { "countyCode": "4", "countyName": "bibb" },
    { "countyCode": "5", "countyName": "blount" },
    { "countyCode": "6", "countyName": "bullock" },
    { "countyCode": "7", "countyName": "butler" },
    { "countyCode": "8", "countyName": "calhoun" },
    { "countyCode": "9", "countyName": "chambers" },
    { "countyCode": "10", "countyName": "cherokee" },
    { "countyCode": "11", "countyName": "chilton" },
    { "countyCode": "12", "countyName": "choctaw" },
    { "countyCode": "13", "countyName": "Clarke" },
    { "countyCode": "14", "countyName": "clay" },
    { "countyCode": "15", "countyName": "Cleburne" },
    { "countyCode": "16", "countyName": "Coffee " },
    { "countyCode": "17", "countyName": "Colbert " },
    { "countyCode": "18", "countyName": "Conecuh" },
    { "countyCode": "19", "countyName": "Coosa" },
    { "countyCode": "20", "countyName": "Covington" },
    { "countyCode": "21", "countyName": "Crenshaw" },
    { "countyCode": "22", "countyName": "Cullman" },
    { "countyCode": "23", "countyName": "Dale" },
    { "countyCode": "24", "countyName": "Dallas" },
    { "countyCode": "25", "countyName": "DeKalb " },
    { "countyCode": "26", "countyName": "Elmore" },
    { "countyCode": "27", "countyName": "Escambia" },
    { "countyCode": "28", "countyName": "Etowah" },
    { "countyCode": "29", "countyName": "Fayette" },
    { "countyCode": "30", "countyName": "Franklin" },
    { "countyCode": "31", "countyName": "Geneva " },
    { "countyCode": "32", "countyName": "Greene" },
    { "countyCode": "33", "countyName": "Hale" },
    { "countyCode": "34", "countyName": "Henry" },
    { "countyCode": "35", "countyName": "Houston" },
    { "countyCode": "36", "countyName": "Jackson" },
    { "countyCode": "37", "countyName": "Jefferson" },
    { "countyCode": "38", "countyName": "Lamar" },
    { "countyCode": "39", "countyName": "Lauderdale" },
    { "countyCode": "40", "countyName": "Lawrence" },
    { "countyCode": "41", "countyName": "Lee" },
    { "countyCode": "42", "countyName": "Limestone" },
    { "countyCode": "43", "countyName": "Lowndes" },
    { "countyCode": "44", "countyName": "Macon" },
    { "countyCode": "45", "countyName": "Madison" },
    { "countyCode": "46", "countyName": "Marengo" },
    { "countyCode": "47", "countyName": "Marion" },
    { "countyCode": "48", "countyName": "Marshall " },
    { "countyCode": "49", "countyName": "Mobile" },
    { "countyCode": "50", "countyName": "Monroe" },
    { "countyCode": "51", "countyName": "Montgomery " },
    { "countyCode": "52", "countyName": "Morgan " },
    { "countyCode": "53", "countyName": "Perry " },
    { "countyCode": "54", "countyName": "Pickens " },
    { "countyCode": "55", "countyName": "Pike" },
    { "countyCode": "56", "countyName": "Randolph " },
    { "countyCode": "57", "countyName": "Russell" },
    { "countyCode": "58", "countyName": "Saint " },
    { "countyCode": "59", "countyName": "Shelby " },
    { "countyCode": "60", "countyName": "Sumter" },
    { "countyCode": "61", "countyName": "Talladega" },
    { "countyCode": "62", "countyName": "Tallapoosa" },
    { "countyCode": "63", "countyName": "Tuscaloosa" },
    { "countyCode": "64", "countyName": "Walker" },
    { "countyCode": "65", "countyName": "Washington" },
    { "countyCode": "66", "countyName": "Wilcox" },
    { "countyCode": "67", "countyName": "Winston" },

];
export const AlaskaCounties = [
    { "countyCode": "1", "countyName": "Aleutians East" },
    { "countyCode": "2", "countyName": "Anchorage" },
    { "countyCode": "3", "countyName": "Bristol Bay" },
    { "countyCode": "4", "countyName": "Denali Borough" },
    { "countyCode": "5", "countyName": "Fairbanks North" },
    { "countyCode": "6", "countyName": "Haines " },
    { "countyCode": "7", "countyName": "Juneau " },
    { "countyCode": "8", "countyName": "Kenai Peninsula" },
    { "countyCode": "9", "countyName": "Ketchikan" },
    { "countyCode": "10", "countyName": "Kodiak " },
    { "countyCode": "11", "countyName": "Lake and Peninsula" },
    { "countyCode": "12", "countyName": "Matanuska-Susitna" },
    { "countyCode": "13", "countyName": "North Slope" },
    { "countyCode": "14", "countyName": "Northwest Arctic" },
    { "countyCode": "15", "countyName": "Petersburg" },
    { "countyCode": "16", "countyName": "Sitka" },
    { "countyCode": "17", "countyName": "Skagway" },
    { "countyCode": "18", "countyName": "Unorganized " },
    { "countyCode": "19", "countyName": "Wrangell" },
    { "countyCode": "20", "countyName": "Yakutat " }


];
export const AmericanSamoaCounties = [
    { "countyCode": "1", "countyName": "Eastern District" },
    { "countyCode": "2", "countyName": "Manu'a District" },
    { "countyCode": "3", "countyName": "Rose Atoll" },
    { "countyCode": "4", "countyName": "Swains Island" },
    { "countyCode": "5", "countyName": "Western District" }

];
export const ArizonaCounties = [
    { "countyCode": "1", "countyName": "Apache" },
    { "countyCode": "2", "countyName": "Cochise " },
    { "countyCode": "3", "countyName": "Coconino" },
    { "countyCode": "4", "countyName": "Gila" },
    { "countyCode": "5", "countyName": "Graham " },
    { "countyCode": "6", "countyName": "Greenlee" },
    { "countyCode": "7", "countyName": "La Paz" },
    { "countyCode": "8", "countyName": "Maricopa " },
    { "countyCode": "9", "countyName": "Mohave" },
    { "countyCode": "10", "countyName": "Navajo" },
    { "countyCode": "11", "countyName": "Pima" },
    { "countyCode": "12", "countyName": "Pinal " },
    { "countyCode": "13", "countyName": "Santa Cruz" },
    { "countyCode": "14", "countyName": "Yavapai" },
    { "countyCode": "14", "countyName": "Yuma" }

];
export const ArkansasCounties = [
    { "countyCode": "1", "countyName": "Arkansas" },
    { "countyCode": "2", "countyName": "Ashley" },
    { "countyCode": "3", "countyName": "Baxter " },
    { "countyCode": "4", "countyName": "Benton" },
    { "countyCode": "5", "countyName": "Boone" },
    { "countyCode": "6", "countyName": "Bradley" },
    { "countyCode": "7", "countyName": "Calhoun " },
    { "countyCode": "8", "countyName": "Carroll " },
    { "countyCode": "9", "countyName": "Chicot" },
    { "countyCode": "10", "countyName": "Clark" },
    { "countyCode": "11", "countyName": "Clay" },
    { "countyCode": "12", "countyName": "Cleburne" },
    { "countyCode": "13", "countyName": "Cleveland" },
    { "countyCode": "14", "countyName": "Columbia" },
    { "countyCode": "15", "countyName": "Conway " },
    { "countyCode": "16", "countyName": "Craighead" },
    { "countyCode": "17", "countyName": "Crawford" },
    { "countyCode": "18", "countyName": "Crittenden" },
    { "countyCode": "19", "countyName": "Cross" },
    { "countyCode": "20", "countyName": "Dallas" },
    { "countyCode": "21", "countyName": "Desha" },
    { "countyCode": "22", "countyName": "Drew" },
    { "countyCode": "23", "countyName": "Faulkner" },
    { "countyCode": "24", "countyName": "Franklin" },
    { "countyCode": "25", "countyName": "Fulton" },
    { "countyCode": "26", "countyName": "Garland" },
    { "countyCode": "27", "countyName": "Grant" },
    { "countyCode": "28", "countyName": "Greene" },
    { "countyCode": "29", "countyName": "Hempstead" },
    { "countyCode": "30", "countyName": "Hot Spring" },
    { "countyCode": "31", "countyName": "Howard" },
    { "countyCode": "32", "countyName": "Independence" },
    { "countyCode": "33", "countyName": "Izard" },
    { "countyCode": "34", "countyName": "Jackson" },
    { "countyCode": "35", "countyName": "Jefferson" },
    { "countyCode": "36", "countyName": "Johnson" },
    { "countyCode": "37", "countyName": "Lafayette" },
    { "countyCode": "38", "countyName": "Lawrence" },
    { "countyCode": "39", "countyName": "Lee" },
    { "countyCode": "40", "countyName": "Lincoln" },
    { "countyCode": "41", "countyName": "Little River" },
    { "countyCode": "42", "countyName": "Logan" },
    { "countyCode": "43", "countyName": "Lonoke" },
    { "countyCode": "44", "countyName": "Madison" },
    { "countyCode": "45", "countyName": "Marion" },
    { "countyCode": "46", "countyName": "Miller" },
    { "countyCode": "47", "countyName": "Mississippi" },
    { "countyCode": "48", "countyName": "Monroe" },
    { "countyCode": "49", "countyName": "Montgomery" },
    { "countyCode": "50", "countyName": "Nevada" },
    { "countyCode": "51", "countyName": "Newton" },
    { "countyCode": "52", "countyName": "Ouachita" },
    { "countyCode": "53", "countyName": "Perry" },
    { "countyCode": "54", "countyName": "Phillips" },
    { "countyCode": "55", "countyName": "Pike" },
    { "countyCode": "56", "countyName": "Poinsett" },
    { "countyCode": "57", "countyName": "Polk" },
    { "countyCode": "58", "countyName": "Pope" },
    { "countyCode": "59", "countyName": "Prairie" },
    { "countyCode": "60", "countyName": "Pulaski" },
    { "countyCode": "61", "countyName": "Randolph" },
    { "countyCode": "62", "countyName": "Saint Francis" },
    { "countyCode": "63", "countyName": "Saline" },
    { "countyCode": "64", "countyName": "Scott" },
    { "countyCode": "65", "countyName": "Searcy" },
    { "countyCode": "66", "countyName": "Sebastian" },
    { "countyCode": "67", "countyName": "Sevier" },
    { "countyCode": "68", "countyName": "Sharp" },
    { "countyCode": "69", "countyName": "Stone" },
    { "countyCode": "70", "countyName": "Union" },
    { "countyCode": "71", "countyName": "Van Buren" },
    { "countyCode": "72", "countyName": "Washington" },
    { "countyCode": "73", "countyName": "White" },
    { "countyCode": "74", "countyName": "Woodruff" },
    { "countyCode": "75", "countyName": "Yell" }

];
export const CaliforniaCounties = [
    { "countyCode": "1", "countyName": "alameda" },
    { "countyCode": "2", "countyName": "alpine" },
    { "countyCode": "3", "countyName": "amador" },
    { "countyCode": "4", "countyName": "butte" },
    { "countyCode": "5", "countyName": "calaveras" },
    { "countyCode": "6", "countyName": "colusa" },
    { "countyCode": "7", "countyName": "contra costa" },
    { "countyCode": "8", "countyName": "del norte" },
    { "countyCode": "9", "countyName": "el dorado" },
    { "countyCode": "10", "countyName": "fresno" },
    { "countyCode": "11", "countyName": "glenn" },
    { "countyCode": "12", "countyName": "humboldt" },
    { "countyCode": "13", "countyName": "imperial" },
    { "countyCode": "14", "countyName": "inyo" },
    { "countyCode": "15", "countyName": "kern" },
    { "countyCode": "16", "countyName": "kings" },
    { "countyCode": "17", "countyName": "lake" },
    { "countyCode": "18", "countyName": "lassen" },
    { "countyCode": "19", "countyName": "los angeles" },
    { "countyCode": "20", "countyName": "madera" },
    { "countyCode": "21", "countyName": "marin" },
    { "countyCode": "22", "countyName": "mariposa" },
    { "countyCode": "23", "countyName": "mendocino" },
    { "countyCode": "24", "countyName": "merced" },
    { "countyCode": "25", "countyName": "modoc" },
    { "countyCode": "26", "countyName": "mono" },
    { "countyCode": "27", "countyName": "monterey" },
    { "countyCode": "28", "countyName": "napa" },
    { "countyCode": "29", "countyName": "nevada" },
    { "countyCode": "30", "countyName": "orange" },
    { "countyCode": "31", "countyName": "placer" },
    { "countyCode": "32", "countyName": "plumas" },
    { "countyCode": "33", "countyName": "riverside" },
    { "countyCode": "34", "countyName": "sacramento" },
    { "countyCode": "35", "countyName": "san benito" },
    { "countyCode": "36", "countyName": "san bernardino" },
    { "countyCode": "37", "countyName": "san diego" },
    { "countyCode": "38", "countyName": "san francisco" },
    { "countyCode": "39", "countyName": "san joaquin" },
    { "countyCode": "40", "countyName": "san luis obispo" },
    { "countyCode": "41", "countyName": "san mateo" },
    { "countyCode": "42", "countyName": "santa barbara" },
    { "countyCode": "43", "countyName": "santa clara" },
    { "countyCode": "44", "countyName": "santa cruz" },
    { "countyCode": "45", "countyName": "shasta" },
    { "countyCode": "46", "countyName": "sierra" },
    { "countyCode": "47", "countyName": "siskiyou" },
    { "countyCode": "48", "countyName": "solano" },
    { "countyCode": "49", "countyName": "sonoma" },
    { "countyCode": "50", "countyName": "stanislaus" },
    { "countyCode": "51", "countyName": "sutter" },
    { "countyCode": "52", "countyName": "tehama" },
    { "countyCode": "53", "countyName": "trinity" },
    { "countyCode": "54", "countyName": "tulare" },
    { "countyCode": "55", "countyName": "tuolumne" },
    { "countyCode": "56", "countyName": "ventura" },
    { "countyCode": "57", "countyName": "yolo" },
    { "countyCode": "58", "countyName": "yuba" }

];
export const ColoradoCounties = [
    { "countyCode": "1", "countyName": "Adams" },
    { "countyCode": "2", "countyName": "Alamosa" },
    { "countyCode": "3", "countyName": "Arapahoe" },
    { "countyCode": "4", "countyName": "Archuleta" },
    { "countyCode": "5", "countyName": "Baca" },
    { "countyCode": "6", "countyName": "Bent" },
    { "countyCode": "7", "countyName": "Boulder" },
    { "countyCode": "8", "countyName": "The City and County of Broomfield" },
    { "countyCode": "9", "countyName": "Chaffee" },
    { "countyCode": "10", "countyName": "Cheyenne" },
    { "countyCode": "11", "countyName": "Clear Creek" },
    { "countyCode": "12", "countyName": "Conejos" },
    { "countyCode": "13", "countyName": "Costilla" },
    { "countyCode": "14", "countyName": "Crowley" },
    { "countyCode": "15", "countyName": "Custer" },
    { "countyCode": "16", "countyName": "Delta" },
    { "countyCode": "17", "countyName": "The City and County of Denver" },
    { "countyCode": "18", "countyName": "Dolores" },
    { "countyCode": "19", "countyName": "Douglas" },
    { "countyCode": "20", "countyName": "Eagle" },
    { "countyCode": "21", "countyName": "Elbert" },
    { "countyCode": "22", "countyName": "El Paso" },
    { "countyCode": "23", "countyName": "Fremont" },
    { "countyCode": "24", "countyName": "Garfield" },
    { "countyCode": "25", "countyName": "Gilpin" },
    { "countyCode": "26", "countyName": "Grand" },
    { "countyCode": "27", "countyName": "Gunnison" },
    { "countyCode": "28", "countyName": "Hinsdale" },
    { "countyCode": "29", "countyName": "Huerfano" },
    { "countyCode": "30", "countyName": "Jackson" },
    { "countyCode": "31", "countyName": "Jefferson" },
    { "countyCode": "32", "countyName": "Kiowa" },
    { "countyCode": "33", "countyName": "Kit Carson" },
    { "countyCode": "34", "countyName": "Lake" },
    { "countyCode": "35", "countyName": "La Plata" },
    { "countyCode": "36", "countyName": "Larimer" },
    { "countyCode": "37", "countyName": "Las Animas" },
    { "countyCode": "38", "countyName": "Lincoln" },
    { "countyCode": "39", "countyName": "Logan" },
    { "countyCode": "40", "countyName": "Mesa" },
    { "countyCode": "41", "countyName": "Mineral" },
    { "countyCode": "42", "countyName": "Moffat" },
    { "countyCode": "43", "countyName": "Montezuma" },
    { "countyCode": "44", "countyName": "Montrose" },
    { "countyCode": "45", "countyName": "Morgan" },
    { "countyCode": "46", "countyName": "Otero" },
    { "countyCode": "47", "countyName": "Ouray" },
    { "countyCode": "48", "countyName": "Park" },
    { "countyCode": "49", "countyName": "Phillips" },
    { "countyCode": "50", "countyName": "Pitkin" },
    { "countyCode": "51", "countyName": "Prowers" },
    { "countyCode": "52", "countyName": "Pueblo" },
    { "countyCode": "53", "countyName": "Rio Blanco" },
    { "countyCode": "54", "countyName": "Rio Grande" },
    { "countyCode": "55", "countyName": "Routt County" },
    { "countyCode": "56", "countyName": "Saguache" },
    { "countyCode": "57", "countyName": "San Juan" },
    { "countyCode": "58", "countyName": "San Miguel" },
    { "countyCode": "59", "countyName": "Sedgwick" },
    { "countyCode": "60", "countyName": "Summit" },
    { "countyCode": "61", "countyName": "Teller" },
    { "countyCode": "62", "countyName": "Washington" },
    { "countyCode": "63", "countyName": "Weld" },
    { "countyCode": "64", "countyName": "Yuma" }

];
export const ConnecticutCounties = [
    { "countyCode": "1", "countyName": "Fairfield" },
    { "countyCode": "2", "countyName": "Hartford" },
    { "countyCode": "3", "countyName": "Litchfield" },
    { "countyCode": "4", "countyName": "Middlesex" },
    { "countyCode": "5", "countyName": "New Haven" },
    { "countyCode": "6", "countyName": "New London" },
    { "countyCode": "7", "countyName": "Tolland" },
    { "countyCode": "7", "countyName": "Windham" }
];
export const DelawareCounties = [
    { "countyCode": "1", "countyName": "Kent" },
    { "countyCode": "2", "countyName": "New Castle" },
    { "countyCode": "3", "countyName": "Sussex" }
];
export const DistrictOfColumbiaCounties = [
    { "countyCode": "1", "countyName": "District of Columbia" }
];
export const FloridaCounties = [
    { "countyCode": "1", "countyName": "alachua" },
    { "countyCode": "2", "countyName": "baker" },
    { "countyCode": "3", "countyName": "bay" },
    { "countyCode": "4", "countyName": "bradford" },
    { "countyCode": "5", "countyName": "brevard" },
    { "countyCode": "6", "countyName": "broward" },
    { "countyCode": "7", "countyName": "calhoun" },
    { "countyCode": "8", "countyName": "charlotte" },
    { "countyCode": "9", "countyName": "citrus" },
    { "countyCode": "10", "countyName": "clay" },
    { "countyCode": "11", "countyName": "collier" },
    { "countyCode": "12", "countyName": "columbia" },
    { "countyCode": "13", "countyName": "desoto" },
    { "countyCode": "14", "countyName": "dixie" },
    { "countyCode": "15", "countyName": "duval" },
    { "countyCode": "16", "countyName": "escambia" },
    { "countyCode": "17", "countyName": "flagler" },
    { "countyCode": "18", "countyName": "franklin" },
    { "countyCode": "19", "countyName": "gadsden" },
    { "countyCode": "20", "countyName": "gilchrist" },
    { "countyCode": "21", "countyName": "glades" },
    { "countyCode": "22", "countyName": "gulf" },
    { "countyCode": "23", "countyName": "hamilton" },
    { "countyCode": "24", "countyName": "hardee" },
    { "countyCode": "25", "countyName": "hendry" },
    { "countyCode": "26", "countyName": "hernando" },
    { "countyCode": "27", "countyName": "highlands" },
    { "countyCode": "28", "countyName": "hillsborough" },
    { "countyCode": "29", "countyName": "holmes" },
    { "countyCode": "30", "countyName": "indian river" },
    { "countyCode": "31", "countyName": "jackson" },
    { "countyCode": "32", "countyName": "jefferson" },
    { "countyCode": "33", "countyName": "lafayette" },
    { "countyCode": "34", "countyName": "lake" },
    { "countyCode": "35", "countyName": "lee" },
    { "countyCode": "36", "countyName": "leon" },
    { "countyCode": "37", "countyName": "levy" },
    { "countyCode": "38", "countyName": "liberty" },
    { "countyCode": "39", "countyName": "madison" },
    { "countyCode": "40", "countyName": "manatee" },
    { "countyCode": "41", "countyName": "marion" },
    { "countyCode": "42", "countyName": "martin" },
    { "countyCode": "43", "countyName": "miami-dade" },
    { "countyCode": "44", "countyName": "monroe" },
    { "countyCode": "45", "countyName": "nassau" },
    { "countyCode": "46", "countyName": "okaloosa" },
    { "countyCode": "47", "countyName": "okeechobee" },
    { "countyCode": "48", "countyName": "orange" },
    { "countyCode": "49", "countyName": "osceola" },
    { "countyCode": "50", "countyName": "palm beach" },
    { "countyCode": "51", "countyName": "pasco" },
    { "countyCode": "52", "countyName": "pinellas" },
    { "countyCode": "53", "countyName": "polk" },
    { "countyCode": "54", "countyName": "putnam" },
    { "countyCode": "55", "countyName": "st. johns" },
    { "countyCode": "56", "countyName": "st. lucie" },
    { "countyCode": "57", "countyName": "santa rosa" },
    { "countyCode": "58", "countyName": "sarasota" },
    { "countyCode": "59", "countyName": "seminole" },
    { "countyCode": "60", "countyName": "sumter" },
    { "countyCode": "61", "countyName": "suwannee" },
    { "countyCode": "62", "countyName": "taylor" },
    { "countyCode": "63", "countyName": "union" },
    { "countyCode": "64", "countyName": "volusia" },
    { "countyCode": "65", "countyName": "wakulla" },
    { "countyCode": "66", "countyName": "walton" },
    { "countyCode": "67", "countyName": "washington" },
];
export const GeorgiaCounties = [
    { "countyCode": "1", "countyName": "Appling" },
    { "countyCode": "2", "countyName": "Atkinson" },
    { "countyCode": "3", "countyName": "Bacon" },
    { "countyCode": "4", "countyName": "Baker" },
    { "countyCode": "5", "countyName": "Baldwin" },
    { "countyCode": "6", "countyName": "Banks" },
    { "countyCode": "7", "countyName": "Barrow" },
    { "countyCode": "8", "countyName": "Bartow" },
    { "countyCode": "9", "countyName": "Ben Hill" },
    { "countyCode": "10", "countyName": "Berrien" },
    { "countyCode": "11", "countyName": "Bibb" },
    { "countyCode": "12", "countyName": "Bleckley" },
    { "countyCode": "13", "countyName": "Brantley" },
    { "countyCode": "14", "countyName": "Brooks" },
    { "countyCode": "15", "countyName": "Bryan" },
    { "countyCode": "16", "countyName": "Bulloch" },
    { "countyCode": "17", "countyName": "Burke" },
    { "countyCode": "18", "countyName": "Butts" },
    { "countyCode": "19", "countyName": "Calhoun" },
    { "countyCode": "20", "countyName": "Camden" },
    { "countyCode": "21", "countyName": "Candler" },
    { "countyCode": "22", "countyName": "Carroll" },
    { "countyCode": "23", "countyName": "Catoosa" },
    { "countyCode": "24", "countyName": "Charlton" },
    { "countyCode": "25", "countyName": "Chatham" },
    { "countyCode": "26", "countyName": "Chattahoochee" },
    { "countyCode": "27", "countyName": "Chattooga" },
    { "countyCode": "28", "countyName": "Cherokee" },
    { "countyCode": "29", "countyName": "Clarke" },
    { "countyCode": "30", "countyName": "Clay" },
    { "countyCode": "31", "countyName": "Clayton" },
    { "countyCode": "32", "countyName": "Clinch" },
    { "countyCode": "33", "countyName": "Cobb" },
    { "countyCode": "34", "countyName": "Coffee" },
    { "countyCode": "35", "countyName": "Colquitt" },
    { "countyCode": "36", "countyName": "Columbia" },
    { "countyCode": "37", "countyName": "Cook" },
    { "countyCode": "38", "countyName": "Coweta" },
    { "countyCode": "39", "countyName": "Crawford" },
    { "countyCode": "40", "countyName": "Crisp" },
    { "countyCode": "41", "countyName": "Dade" },
    { "countyCode": "42", "countyName": "Dawson" },
    { "countyCode": "43", "countyName": "Decatur" },
    { "countyCode": "44", "countyName": "DeKalb" },
    { "countyCode": "45", "countyName": "Dodge" },
    { "countyCode": "46", "countyName": "Dooly" },
    { "countyCode": "47", "countyName": "Dougherty" },
    { "countyCode": "48", "countyName": "Douglas" },
    { "countyCode": "49", "countyName": "Early" },
    { "countyCode": "50", "countyName": "Echols" },
    { "countyCode": "51", "countyName": "Effingham" },
    { "countyCode": "52", "countyName": "Elbert" },
    { "countyCode": "53", "countyName": "Emanuel" },
    { "countyCode": "54", "countyName": "Evans" },
    { "countyCode": "55", "countyName": "Fannin" },
    { "countyCode": "56", "countyName": "Fayette" },
    { "countyCode": "67", "countyName": "Floyd" },
    { "countyCode": "58", "countyName": "Forsyth" },
    { "countyCode": "59", "countyName": "Franklin" },
    { "countyCode": "60", "countyName": "Fulton" },
    { "countyCode": "61", "countyName": "Gilmer" },
    { "countyCode": "62", "countyName": "Glascock" },
    { "countyCode": "63", "countyName": "Glynn" },
    { "countyCode": "64", "countyName": "Gordon" },
    { "countyCode": "65", "countyName": "Grady" },
    { "countyCode": "66", "countyName": "Greene" },
    { "countyCode": "67", "countyName": "Gwinnett" },
    { "countyCode": "68", "countyName": "Habersham" },
    { "countyCode": "69", "countyName": "Hall" },
    { "countyCode": "70", "countyName": "Hancock" },
    { "countyCode": "71", "countyName": "Haralson" },
    { "countyCode": "72", "countyName": "Harris" },
    { "countyCode": "73", "countyName": "Hart" },
    { "countyCode": "74", "countyName": "Heard" },
    { "countyCode": "75", "countyName": "Henry" },
    { "countyCode": "76", "countyName": "Houston" },
    { "countyCode": "77", "countyName": "Irwin" },
    { "countyCode": "78", "countyName": "Jackson" },
    { "countyCode": "79", "countyName": "Jasper" },
    { "countyCode": "80", "countyName": "Jeff Davis" },
    { "countyCode": "81", "countyName": "Jefferson" },
    { "countyCode": "82", "countyName": "Jenkins" },
    { "countyCode": "83", "countyName": "Johnson" },
    { "countyCode": "84", "countyName": "Jones" },
    { "countyCode": "85", "countyName": "Lamar" },
    { "countyCode": "86", "countyName": "Lanier" },
    { "countyCode": "87", "countyName": "Laurens" },
    { "countyCode": "88", "countyName": "Lee" },
    { "countyCode": "89", "countyName": "Liberty" },
    { "countyCode": "90", "countyName": "Lincoln" },
    { "countyCode": "91", "countyName": "Long" },
    { "countyCode": "92", "countyName": "Lowndes" },
    { "countyCode": "93", "countyName": "Lumpkin" },
    { "countyCode": "94", "countyName": "Macon" },
    { "countyCode": "95", "countyName": "Madison" },
    { "countyCode": "96", "countyName": "Marion" },
    { "countyCode": "97", "countyName": "McDuffie" },
    { "countyCode": "98", "countyName": "McIntosh" },
    { "countyCode": "99", "countyName": "Meriwether" },
    { "countyCode": "100", "countyName": "Miller" },
    { "countyCode": "101", "countyName": "Mitchell" },
    { "countyCode": "102", "countyName": "Monroe" },
    { "countyCode": "103", "countyName": "Montgomery" },
    { "countyCode": "104", "countyName": "Morgan" },
    { "countyCode": "105", "countyName": "Murray" },
    { "countyCode": "106", "countyName": "Muscogee" },
    { "countyCode": "107", "countyName": "Newton" },
    { "countyCode": "108", "countyName": "Oconee" },
    { "countyCode": "109", "countyName": "Oglethorpe" },
    { "countyCode": "110", "countyName": "Paulding" },
    { "countyCode": "111", "countyName": "Peach" },
    { "countyCode": "112", "countyName": "Pickens" },
    { "countyCode": "113", "countyName": "Pierce" },
    { "countyCode": "114", "countyName": "Pike" },
    { "countyCode": "115", "countyName": "Polk" },
    { "countyCode": "116", "countyName": "Pulaski" },
    { "countyCode": "117", "countyName": "Putnam" },
    { "countyCode": "118", "countyName": "Quitman" },
    { "countyCode": "119", "countyName": "Rabun" },
    { "countyCode": "120", "countyName": "Randolph" },
    { "countyCode": "121", "countyName": "Richmond" },
    { "countyCode": "122", "countyName": "Rockdale" },
    { "countyCode": "123", "countyName": "Schley" },
    { "countyCode": "124", "countyName": "Screven" },
    { "countyCode": "125", "countyName": "Seminole" },
    { "countyCode": "126", "countyName": "Spalding" },
    { "countyCode": "127", "countyName": "Stephens" },
    { "countyCode": "128", "countyName": "Stewart" },
    { "countyCode": "129", "countyName": "Sumter" },
    { "countyCode": "130", "countyName": "Talbot" },
    { "countyCode": "131", "countyName": "Taliaferro" },
    { "countyCode": "132", "countyName": "Tattnall" },
    { "countyCode": "133", "countyName": "Taylor" },
    { "countyCode": "134", "countyName": "Telfair" },
    { "countyCode": "135", "countyName": "Terrell" },
    { "countyCode": "136", "countyName": "Thomas" },
    { "countyCode": "137", "countyName": "Tift" },
    { "countyCode": "138", "countyName": "Toombs" },
    { "countyCode": "139", "countyName": "Towns" },
    { "countyCode": "140", "countyName": "Treutlen" },
    { "countyCode": "141", "countyName": "Troup" },
    { "countyCode": "142", "countyName": "Turner" },
    { "countyCode": "143", "countyName": "Twiggs" },
    { "countyCode": "144", "countyName": "Union" },
    { "countyCode": "145", "countyName": "Upson" },
    { "countyCode": "146", "countyName": "Walker" },
    { "countyCode": "147", "countyName": "Walton" },
    { "countyCode": "148", "countyName": "Ware" },
    { "countyCode": "149", "countyName": "Warren" },
    { "countyCode": "150", "countyName": "Washington" },
    { "countyCode": "151", "countyName": "Wayne" },
    { "countyCode": "152", "countyName": "Webster" },
    { "countyCode": "153", "countyName": "Wheeler" },
    { "countyCode": "154", "countyName": "White" },
    { "countyCode": "155", "countyName": "Whitfield" },
    { "countyCode": "156", "countyName": "Wilcox" },
    { "countyCode": "157", "countyName": "Wilkes" },
    { "countyCode": "158", "countyName": "Wilkinson" },
    { "countyCode": "159", "countyName": "Worth" },
];
export const GuamCounties = [
    { "countyCode": "1", "countyName": "Guam" }
];
export const HawaiiCounties = [
    { "countyCode": "1", "countyName": "Hawaii" },
    { "countyCode": "2", "countyName": "Honolulu" },
    { "countyCode": "3", "countyName": "Kalawao" },
    { "countyCode": "4", "countyName": "Kauai" },
    { "countyCode": "5", "countyName": "Maui" }
];
export const IdahoCounties = [
    { "countyCode": "1", "countyName": "Ada" },
    { "countyCode": "2", "countyName": "Adams" },
    { "countyCode": "3", "countyName": "Bannock" },
    { "countyCode": "4", "countyName": "Bear Lake" },
    { "countyCode": "5", "countyName": "Benewah" },
    { "countyCode": "6", "countyName": "Bingham" },
    { "countyCode": "7", "countyName": "Blaine" },
    { "countyCode": "8", "countyName": "Boise" },
    { "countyCode": "9", "countyName": "Bonner" },
    { "countyCode": "10", "countyName": "Bonneville" },
    { "countyCode": "11", "countyName": "Boundary" },
    { "countyCode": "12", "countyName": "Butte" },
    { "countyCode": "13", "countyName": "Camas" },
    { "countyCode": "14", "countyName": "Canyon" },
    { "countyCode": "15", "countyName": "Caribou" },
    { "countyCode": "16", "countyName": "Cassia" },
    { "countyCode": "17", "countyName": "Clark" },
    { "countyCode": "18", "countyName": "Clearwater" },
    { "countyCode": "19", "countyName": "Custer" },
    { "countyCode": "20", "countyName": "Elmore" },
    { "countyCode": "21", "countyName": "Franklin" },
    { "countyCode": "22", "countyName": "Fremont" },
    { "countyCode": "23", "countyName": "Gem" },
    { "countyCode": "24", "countyName": "Gooding" },
    { "countyCode": "25", "countyName": "Idaho" },
    { "countyCode": "26", "countyName": "Jefferson" },
    { "countyCode": "27", "countyName": "Jerome" },
    { "countyCode": "28", "countyName": "Kootenai" },
    { "countyCode": "29", "countyName": "Latah" },
    { "countyCode": "30", "countyName": "Lemhi" },
    { "countyCode": "31", "countyName": "Lewis" },
    { "countyCode": "32", "countyName": "Lincoln" },
    { "countyCode": "33", "countyName": "Madison" },
    { "countyCode": "34", "countyName": "Minidoka" },
    { "countyCode": "35", "countyName": "Nez Perce" },
    { "countyCode": "36", "countyName": "Oneida" },
    { "countyCode": "37", "countyName": "Owyhee" },
    { "countyCode": "38", "countyName": "Payette" },
    { "countyCode": "39", "countyName": "Power" },
    { "countyCode": "40", "countyName": "Shoshone" },
    { "countyCode": "41", "countyName": "Teton" },
    { "countyCode": "42", "countyName": "Twin Falls" },
    { "countyCode": "43", "countyName": "Valley" },
    { "countyCode": "44", "countyName": "Washington" }
];
export const IllinoisCounties = [
    { "countyCode": "1", "countyName": "Adams" },
    { "countyCode": "2", "countyName": "Alexander" },
    { "countyCode": "3", "countyName": "Bond" },
    { "countyCode": "4", "countyName": "Boone" },
    { "countyCode": "5", "countyName": "Brown" },
    { "countyCode": "6", "countyName": "Bureau" },
    { "countyCode": "7", "countyName": "Calhoun" },
    { "countyCode": "8", "countyName": "Carroll" },
    { "countyCode": "9", "countyName": "Cass" },
    { "countyCode": "10", "countyName": "Champaign" },
    { "countyCode": "11", "countyName": "Christian" },
    { "countyCode": "12", "countyName": "Clark" },
    { "countyCode": "13", "countyName": "Clay" },
    { "countyCode": "14", "countyName": "Clinton" },
    { "countyCode": "15", "countyName": "Coles" },
    { "countyCode": "16", "countyName": "Cook" },
    { "countyCode": "17", "countyName": "Crawford" },
    { "countyCode": "18", "countyName": "Cumberland" },
    { "countyCode": "19", "countyName": "DeKalb" },
    { "countyCode": "20", "countyName": "DeWitt" },
    { "countyCode": "21", "countyName": "Douglas" },
    { "countyCode": "22", "countyName": "DuPage" },
    { "countyCode": "23", "countyName": "Edgar" },
    { "countyCode": "24", "countyName": "Edwards" },
    { "countyCode": "25", "countyName": "Effingham" },
    { "countyCode": "26", "countyName": "Fayette" },
    { "countyCode": "27", "countyName": "Ford" },
    { "countyCode": "28", "countyName": "Franklin" },
    { "countyCode": "29", "countyName": "Fulton" },
    { "countyCode": "30", "countyName": "Gallatin" },
    { "countyCode": "31", "countyName": "Greene" },
    { "countyCode": "32", "countyName": "Grundy" },
    { "countyCode": "33", "countyName": "Hamilton" },
    { "countyCode": "34", "countyName": "Hancock" },
    { "countyCode": "35", "countyName": "Hardin" },
    { "countyCode": "36", "countyName": "Henderson" },
    { "countyCode": "37", "countyName": "Henry" },
    { "countyCode": "38", "countyName": "Iroquois" },
    { "countyCode": "39", "countyName": "Jackson" },
    { "countyCode": "40", "countyName": "Jasper" },
    { "countyCode": "41", "countyName": "Jefferson" },
    { "countyCode": "42", "countyName": "Jersey" },
    { "countyCode": "43", "countyName": "Jo Daviess" },
    { "countyCode": "44", "countyName": "Johnson" },
    { "countyCode": "45", "countyName": "Kane" },
    { "countyCode": "46", "countyName": "Kankakee" },
    { "countyCode": "47", "countyName": "Kendall" },
    { "countyCode": "48", "countyName": "Knox" },
    { "countyCode": "49", "countyName": "Lake" },
    { "countyCode": "50", "countyName": "LaSalle" },
    { "countyCode": "51", "countyName": "Lawrence" },
    { "countyCode": "52", "countyName": "Lee" },
    { "countyCode": "53", "countyName": "Livingston" },
    { "countyCode": "54", "countyName": "Logan" },
    { "countyCode": "55", "countyName": "Macon" },
    { "countyCode": "56", "countyName": "Macoupin" },
    { "countyCode": "67", "countyName": "Madison" },
    { "countyCode": "58", "countyName": "Marion" },
    { "countyCode": "59", "countyName": "Marshall" },
    { "countyCode": "60", "countyName": "Mason" },
    { "countyCode": "61", "countyName": "Massac" },
    { "countyCode": "62", "countyName": "McDonough" },
    { "countyCode": "63", "countyName": "McHenry" },
    { "countyCode": "64", "countyName": "McLean" },
    { "countyCode": "65", "countyName": "Menard" },
    { "countyCode": "66", "countyName": "Mercer" },
    { "countyCode": "67", "countyName": "Monroe" },
    { "countyCode": "68", "countyName": "Montgomery" },
    { "countyCode": "69", "countyName": "Morgan" },
    { "countyCode": "70", "countyName": "Moultrie" },
    { "countyCode": "71", "countyName": "Ogle" },
    { "countyCode": "72", "countyName": "Peoria" },
    { "countyCode": "73", "countyName": "Perry" },
    { "countyCode": "74", "countyName": "Piatt" },
    { "countyCode": "75", "countyName": "Pike" },
    { "countyCode": "76", "countyName": "Pope" },
    { "countyCode": "77", "countyName": "Pulaski" },
    { "countyCode": "78", "countyName": "Putnam" },
    { "countyCode": "79", "countyName": "Randolph" },
    { "countyCode": "80", "countyName": "Richland" },
    { "countyCode": "81", "countyName": "Rock Island" },
    { "countyCode": "82", "countyName": "Saint Clair" },
    { "countyCode": "83", "countyName": "Saline" },
    { "countyCode": "84", "countyName": "Sangamon" },
    { "countyCode": "85", "countyName": "Schuyler" },
    { "countyCode": "86", "countyName": "Scott" },
    { "countyCode": "87", "countyName": "Shelby" },
    { "countyCode": "88", "countyName": "Stark" },
    { "countyCode": "89", "countyName": "Stephenson" },
    { "countyCode": "90", "countyName": "Tazewell" },
    { "countyCode": "91", "countyName": "Union" },
    { "countyCode": "92", "countyName": "Vermilion" },
    { "countyCode": "93", "countyName": "Wabash" },
    { "countyCode": "94", "countyName": "Warren" },
    { "countyCode": "95", "countyName": "Washington" },
    { "countyCode": "96", "countyName": "Wayne" },
    { "countyCode": "97", "countyName": "White" },
    { "countyCode": "98", "countyName": "Whiteside" },
    { "countyCode": "99", "countyName": "Will" },
    { "countyCode": "100", "countyName": "Williamson" },
    { "countyCode": "101", "countyName": "Winnebago" },
    { "countyCode": "102", "countyName": "Woodford" }
];
export const IndianaCounties = [
    { "countyCode": "1", "countyName": "Adams" },
    { "countyCode": "2", "countyName": "Allen" },
    { "countyCode": "3", "countyName": "Bartholomew" },
    { "countyCode": "4", "countyName": "Benton" },
    { "countyCode": "5", "countyName": "Blackford" },
    { "countyCode": "6", "countyName": "Boone" },
    { "countyCode": "7", "countyName": "Brown" },
    { "countyCode": "8", "countyName": "Carroll" },
    { "countyCode": "9", "countyName": "Cass" },
    { "countyCode": "10", "countyName": "Clark" },
    { "countyCode": "11", "countyName": "Clay" },
    { "countyCode": "12", "countyName": "Clinton" },
    { "countyCode": "13", "countyName": "Crawford" },
    { "countyCode": "14", "countyName": "Daviess" },
    { "countyCode": "15", "countyName": "Dearborn" },
    { "countyCode": "16", "countyName": "Decatur" },
    { "countyCode": "17", "countyName": "DeKalb" },
    { "countyCode": "18", "countyName": "Delaware" },
    { "countyCode": "19", "countyName": "Dubois" },
    { "countyCode": "20", "countyName": "Elkhart" },
    { "countyCode": "21", "countyName": "Fayette" },
    { "countyCode": "22", "countyName": "Floyd" },
    { "countyCode": "23", "countyName": "Fountain" },
    { "countyCode": "24", "countyName": "Franklin" },
    { "countyCode": "25", "countyName": "Fulton" },
    { "countyCode": "26", "countyName": "Gibson" },
    { "countyCode": "27", "countyName": "Grant" },
    { "countyCode": "28", "countyName": "Greene" },
    { "countyCode": "29", "countyName": "Hamilton" },
    { "countyCode": "30", "countyName": "Hancock" },
    { "countyCode": "31", "countyName": "Harrison" },
    { "countyCode": "32", "countyName": "Hendricks" },
    { "countyCode": "33", "countyName": "Henry" },
    { "countyCode": "34", "countyName": "Howard" },
    { "countyCode": "35", "countyName": "Huntington" },
    { "countyCode": "36", "countyName": "Jackson" },
    { "countyCode": "37", "countyName": "Jasper" },
    { "countyCode": "38", "countyName": "Jay" },
    { "countyCode": "39", "countyName": "Jefferson" },
    { "countyCode": "40", "countyName": "Jennings" },
    { "countyCode": "41", "countyName": "Johnson" },
    { "countyCode": "42", "countyName": "Knox" },
    { "countyCode": "43", "countyName": "Kosciusko" },
    { "countyCode": "44", "countyName": "LaGrange" },
    { "countyCode": "45", "countyName": "Lake" },
    { "countyCode": "46", "countyName": "LaPorte" },
    { "countyCode": "47", "countyName": "Lawrence" },
    { "countyCode": "48", "countyName": "Madison" },
    { "countyCode": "49", "countyName": "Marion" },
    { "countyCode": "50", "countyName": "Marshall" },
    { "countyCode": "51", "countyName": "Martin" },
    { "countyCode": "52", "countyName": "Miami" },
    { "countyCode": "53", "countyName": "Monroe" },
    { "countyCode": "54", "countyName": "Montgomery" },
    { "countyCode": "55", "countyName": "Morgan" },
    { "countyCode": "56", "countyName": "Newton" },
    { "countyCode": "67", "countyName": "Noble" },
    { "countyCode": "58", "countyName": "Ohio" },
    { "countyCode": "59", "countyName": "Orange" },
    { "countyCode": "60", "countyName": "Owen" },
    { "countyCode": "61", "countyName": "Parke" },
    { "countyCode": "62", "countyName": "Perry" },
    { "countyCode": "63", "countyName": "Pike" },
    { "countyCode": "64", "countyName": "Porter" },
    { "countyCode": "65", "countyName": "Posey" },
    { "countyCode": "66", "countyName": "Pulaski" },
    { "countyCode": "67", "countyName": "Putnam" },
    { "countyCode": "68", "countyName": "Randolph" },
    { "countyCode": "69", "countyName": "Ripley" },
    { "countyCode": "70", "countyName": "Rush" },
    { "countyCode": "71", "countyName": "Saint" },
    { "countyCode": "72", "countyName": "Scott" },
    { "countyCode": "73", "countyName": "Shelby" },
    { "countyCode": "74", "countyName": "Spencer" },
    { "countyCode": "75", "countyName": "Starke" },
    { "countyCode": "76", "countyName": "Steuben" },
    { "countyCode": "77", "countyName": "Sullivan" },
    { "countyCode": "78", "countyName": "Switzerland" },
    { "countyCode": "79", "countyName": "Tippecanoe" },
    { "countyCode": "80", "countyName": "Tipton" },
    { "countyCode": "81", "countyName": "Union" },
    { "countyCode": "82", "countyName": "Vanderburgh" },
    { "countyCode": "83", "countyName": "Vermillion" },
    { "countyCode": "84", "countyName": "Vigo" },
    { "countyCode": "85", "countyName": "Wabash" },
    { "countyCode": "86", "countyName": "Warren" },
    { "countyCode": "87", "countyName": "Warrick" },
    { "countyCode": "88", "countyName": "Washington" },
    { "countyCode": "89", "countyName": "Wayne" },
    { "countyCode": "90", "countyName": "Wells" },
    { "countyCode": "91", "countyName": "White" },
    { "countyCode": "92", "countyName": "Whitley" }
];
export const IowaCounties = [
    { "countyCode": "1", "countyName": "Adair" },
    { "countyCode": "2", "countyName": "Adams" },
    { "countyCode": "3", "countyName": "Allamakee" },
    { "countyCode": "4", "countyName": "Appanoose" },
    { "countyCode": "5", "countyName": "Audubon" },
    { "countyCode": "6", "countyName": "Benton" },
    { "countyCode": "7", "countyName": "Black Hawk" },
    { "countyCode": "8", "countyName": "Boone" },
    { "countyCode": "9", "countyName": "Bremer" },
    { "countyCode": "10", "countyName": "Buchanan" },
    { "countyCode": "11", "countyName": "Buena Vista" },
    { "countyCode": "12", "countyName": "Butler" },
    { "countyCode": "13", "countyName": "Calhoun" },
    { "countyCode": "14", "countyName": "Carroll" },
    { "countyCode": "15", "countyName": "Cass" },
    { "countyCode": "16", "countyName": "Cedar" },
    { "countyCode": "17", "countyName": "Cerro Gordo" },
    { "countyCode": "18", "countyName": "Cherokee" },
    { "countyCode": "19", "countyName": "Chickasaw" },
    { "countyCode": "20", "countyName": "Clarke" },
    { "countyCode": "21", "countyName": "Clay" },
    { "countyCode": "22", "countyName": "Clayton" },
    { "countyCode": "23", "countyName": "Clinton" },
    { "countyCode": "24", "countyName": "Crawford" },
    { "countyCode": "25", "countyName": "Dallas" },
    { "countyCode": "26", "countyName": "Davis" },
    { "countyCode": "27", "countyName": "Decatur" },
    { "countyCode": "28", "countyName": "Delaware" },
    { "countyCode": "29", "countyName": "Des Moines" },
    { "countyCode": "30", "countyName": "Dickinson" },
    { "countyCode": "31", "countyName": "Dubuque" },
    { "countyCode": "32", "countyName": "Emmet" },
    { "countyCode": "33", "countyName": "Fayette" },
    { "countyCode": "34", "countyName": "Floyd" },
    { "countyCode": "35", "countyName": "Franklin" },
    { "countyCode": "36", "countyName": "Fremont" },
    { "countyCode": "37", "countyName": "Greene" },
    { "countyCode": "38", "countyName": "Grundy" },
    { "countyCode": "39", "countyName": "Guthrie" },
    { "countyCode": "40", "countyName": "Hamilton" },
    { "countyCode": "41", "countyName": "Hancock" },
    { "countyCode": "42", "countyName": "Hardin" },
    { "countyCode": "43", "countyName": "Harrison" },
    { "countyCode": "44", "countyName": "Henry" },
    { "countyCode": "45", "countyName": "Howard" },
    { "countyCode": "46", "countyName": "Humboldt" },
    { "countyCode": "47", "countyName": "Ida" },
    { "countyCode": "48", "countyName": "Iowa" },
    { "countyCode": "49", "countyName": "Jackson" },
    { "countyCode": "50", "countyName": "Jasper" },
    { "countyCode": "51", "countyName": "Jefferson" },
    { "countyCode": "52", "countyName": "Johnson" },
    { "countyCode": "53", "countyName": "Jones" },
    { "countyCode": "54", "countyName": "Keokuk" },
    { "countyCode": "55", "countyName": "Kossuth" },
    { "countyCode": "56", "countyName": "Lee" },
    { "countyCode": "67", "countyName": "Linn" },
    { "countyCode": "58", "countyName": "Louisa" },
    { "countyCode": "59", "countyName": "Lucas" },
    { "countyCode": "60", "countyName": "Lyon" },
    { "countyCode": "61", "countyName": "Madison" },
    { "countyCode": "62", "countyName": "Mahaska" },
    { "countyCode": "63", "countyName": "Marion" },
    { "countyCode": "64", "countyName": "Marshall" },
    { "countyCode": "65", "countyName": "Mills" },
    { "countyCode": "66", "countyName": "Mitchell" },
    { "countyCode": "67", "countyName": "Monona" },
    { "countyCode": "68", "countyName": "Monroe" },
    { "countyCode": "69", "countyName": "Montgomery" },
    { "countyCode": "70", "countyName": "Muscatine" },
    { "countyCode": "71", "countyName": "O'Brien" },
    { "countyCode": "72", "countyName": "Osceola" },
    { "countyCode": "73", "countyName": "Page" },
    { "countyCode": "74", "countyName": "Palo Alto" },
    { "countyCode": "75", "countyName": "Plymouth" },
    { "countyCode": "76", "countyName": "Pocahontas" },
    { "countyCode": "77", "countyName": "Polk" },
    { "countyCode": "78", "countyName": "Pottawattamie" },
    { "countyCode": "79", "countyName": "Poweshiek" },
    { "countyCode": "80", "countyName": "Ringgold" },
    { "countyCode": "81", "countyName": "Sac" },
    { "countyCode": "82", "countyName": "Scott" },
    { "countyCode": "83", "countyName": "Shelby" },
    { "countyCode": "84", "countyName": "Sioux" },
    { "countyCode": "85", "countyName": "Story" },
    { "countyCode": "86", "countyName": "Tama" },
    { "countyCode": "87", "countyName": "Taylor" },
    { "countyCode": "88", "countyName": "Union" },
    { "countyCode": "89", "countyName": "Van Buren" },
    { "countyCode": "90", "countyName": "Wapello" },
    { "countyCode": "91", "countyName": "Warren" },
    { "countyCode": "92", "countyName": "Washington" },
    { "countyCode": "93", "countyName": "Wayne" },
    { "countyCode": "94", "countyName": "Webster" },
    { "countyCode": "95", "countyName": "Winnebago" },
    { "countyCode": "96", "countyName": "Winneshiek" },
    { "countyCode": "97", "countyName": "Woodbury" },
    { "countyCode": "98", "countyName": "Worth" },
    { "countyCode": "99", "countyName": "Wright" },
];
export const KansasCounties = [
    { "countyCode": "1", "countyName": "Allen" },
    { "countyCode": "2", "countyName": "Anderson" },
    { "countyCode": "3", "countyName": "Atchison" },
    { "countyCode": "4", "countyName": "Barber" },
    { "countyCode": "5", "countyName": "Barton" },
    { "countyCode": "6", "countyName": "Bourbon" },
    { "countyCode": "7", "countyName": "Brown" },
    { "countyCode": "8", "countyName": "Butler" },
    { "countyCode": "9", "countyName": "Chase" },
    { "countyCode": "10", "countyName": "Chautauqua" },
    { "countyCode": "11", "countyName": "Cherokee" },
    { "countyCode": "12", "countyName": "Cheyenne" },
    { "countyCode": "13", "countyName": "Clark" },
    { "countyCode": "14", "countyName": "Clay" },
    { "countyCode": "15", "countyName": "Cloud" },
    { "countyCode": "16", "countyName": "Coffey" },
    { "countyCode": "17", "countyName": "Comanche" },
    { "countyCode": "18", "countyName": "Cowley" },
    { "countyCode": "19", "countyName": "Crawford" },
    { "countyCode": "20", "countyName": "Decatur" },
    { "countyCode": "21", "countyName": "Dickinson" },
    { "countyCode": "22", "countyName": "Doniphan" },
    { "countyCode": "23", "countyName": "Douglas" },
    { "countyCode": "24", "countyName": "Edwards" },
    { "countyCode": "25", "countyName": "Elk" },
    { "countyCode": "26", "countyName": "Ellis" },
    { "countyCode": "27", "countyName": "Ellsworth" },
    { "countyCode": "28", "countyName": "Finney" },
    { "countyCode": "29", "countyName": "Ford" },
    { "countyCode": "30", "countyName": "Franklin" },
    { "countyCode": "31", "countyName": "Geary" },
    { "countyCode": "32", "countyName": "Gove" },
    { "countyCode": "33", "countyName": "Graham" },
    { "countyCode": "34", "countyName": "Grant" },
    { "countyCode": "35", "countyName": "Gray" },
    { "countyCode": "36", "countyName": "Greeley" },
    { "countyCode": "37", "countyName": "Greenwood" },
    { "countyCode": "38", "countyName": "Hamilton" },
    { "countyCode": "39", "countyName": "Harper" },
    { "countyCode": "40", "countyName": "Harvey" },
    { "countyCode": "41", "countyName": "Haskell" },
    { "countyCode": "42", "countyName": "Hodgeman" },
    { "countyCode": "43", "countyName": "Jackson" },
    { "countyCode": "44", "countyName": "Jefferson" },
    { "countyCode": "45", "countyName": "Jewell" },
    { "countyCode": "46", "countyName": "Johnson" },
    { "countyCode": "47", "countyName": "Kearny" },
    { "countyCode": "48", "countyName": "Kingman" },
    { "countyCode": "49", "countyName": "Kiowa" },
    { "countyCode": "50", "countyName": "Labette" },
    { "countyCode": "51", "countyName": "Lane" },
    { "countyCode": "52", "countyName": "Leavenworth" },
    { "countyCode": "53", "countyName": "Lincoln" },
    { "countyCode": "54", "countyName": "Linn" },
    { "countyCode": "55", "countyName": "Logan" },
    { "countyCode": "56", "countyName": "Lyon" },
    { "countyCode": "67", "countyName": "Marion" },
    { "countyCode": "58", "countyName": "Marshall" },
    { "countyCode": "59", "countyName": "McPherson" },
    { "countyCode": "60", "countyName": "Meade" },
    { "countyCode": "61", "countyName": "Miami" },
    { "countyCode": "62", "countyName": "Mitchell" },
    { "countyCode": "63", "countyName": "Montgomery" },
    { "countyCode": "64", "countyName": "Morris" },
    { "countyCode": "65", "countyName": "Morton" },
    { "countyCode": "66", "countyName": "Nemaha" },
    { "countyCode": "67", "countyName": "Neosho" },
    { "countyCode": "68", "countyName": "Ness" },
    { "countyCode": "69", "countyName": "Norton" },
    { "countyCode": "70", "countyName": "Osage" },
    { "countyCode": "71", "countyName": "Osborne" },
    { "countyCode": "72", "countyName": "Ottawa" },
    { "countyCode": "73", "countyName": "Pawnee" },
    { "countyCode": "74", "countyName": "Phillips" },
    { "countyCode": "75", "countyName": "Pottawatomie" },
    { "countyCode": "76", "countyName": "Pratt" },
    { "countyCode": "77", "countyName": "Rawlins" },
    { "countyCode": "78", "countyName": "Reno" },
    { "countyCode": "79", "countyName": "Republic" },
    { "countyCode": "80", "countyName": "Rice" },
    { "countyCode": "81", "countyName": "Riley" },
    { "countyCode": "82", "countyName": "Rooks" },
    { "countyCode": "83", "countyName": "Rush" },
    { "countyCode": "84", "countyName": "Russell" },
    { "countyCode": "85", "countyName": "Saline" },
    { "countyCode": "86", "countyName": "Scott" },
    { "countyCode": "87", "countyName": "Sedgwick" },
    { "countyCode": "88", "countyName": "Seward" },
    { "countyCode": "89", "countyName": "Shawnee" },
    { "countyCode": "90", "countyName": "Sheridan" },
    { "countyCode": "91", "countyName": "Sherman" },
    { "countyCode": "92", "countyName": "Smith" },
    { "countyCode": "93", "countyName": "Stafford" },
    { "countyCode": "94", "countyName": "Stanton" },
    { "countyCode": "95", "countyName": "Stevens" },
    { "countyCode": "96", "countyName": "Sumner" },
    { "countyCode": "97", "countyName": "Thomas" },
    { "countyCode": "98", "countyName": "Trego" },
    { "countyCode": "99", "countyName": "Wabaunsee" },
    { "countyCode": "100", "countyName": "Wallace" },
    { "countyCode": "101", "countyName": "Washington" },
    { "countyCode": "102", "countyName": "Wichita" },
    { "countyCode": "103", "countyName": "Wilson" },
    { "countyCode": "104", "countyName": "Woodson" },
    { "countyCode": "105", "countyName": "Wyandotte" },
];
export const KentuckyCounties = [
    { "countyCode": "1", "countyName": "Adair" },
    { "countyCode": "2", "countyName": "Allen" },
    { "countyCode": "3", "countyName": "Anderson" },
    { "countyCode": "4", "countyName": "Ballard" },
    { "countyCode": "5", "countyName": "Barren" },
    { "countyCode": "6", "countyName": "Bath" },
    { "countyCode": "7", "countyName": "Bell" },
    { "countyCode": "8", "countyName": "Boone" },
    { "countyCode": "9", "countyName": "Bourbon" },
    { "countyCode": "10", "countyName": "Boyd" },
    { "countyCode": "11", "countyName": "Boyle" },
    { "countyCode": "12", "countyName": "Bracken" },
    { "countyCode": "13", "countyName": "Breathitt" },
    { "countyCode": "14", "countyName": "Breckinridge" },
    { "countyCode": "15", "countyName": "Bullitt" },
    { "countyCode": "16", "countyName": "Butler" },
    { "countyCode": "17", "countyName": "Caldwell" },
    { "countyCode": "18", "countyName": "Calloway" },
    { "countyCode": "19", "countyName": "Campbell" },
    { "countyCode": "20", "countyName": "Carlisle" },
    { "countyCode": "21", "countyName": "Carroll" },
    { "countyCode": "22", "countyName": "Carter" },
    { "countyCode": "23", "countyName": "Casey" },
    { "countyCode": "24", "countyName": "Christian" },
    { "countyCode": "25", "countyName": "Clark" },
    { "countyCode": "26", "countyName": "Clay" },
    { "countyCode": "27", "countyName": "Clinton" },
    { "countyCode": "28", "countyName": "Crittenden" },
    { "countyCode": "29", "countyName": "Cumberland" },
    { "countyCode": "30", "countyName": "Daviess" },
    { "countyCode": "31", "countyName": "Edmonson" },
    { "countyCode": "32", "countyName": "Elliott" },
    { "countyCode": "33", "countyName": "Estill" },
    { "countyCode": "34", "countyName": "Fayette" },
    { "countyCode": "35", "countyName": "Fleming" },
    { "countyCode": "36", "countyName": "Floyd" },
    { "countyCode": "37", "countyName": "Franklin" },
    { "countyCode": "38", "countyName": "Fulton" },
    { "countyCode": "39", "countyName": "Gallatin" },
    { "countyCode": "40", "countyName": "Garrard" },
    { "countyCode": "41", "countyName": "Grant" },
    { "countyCode": "42", "countyName": "Graves" },
    { "countyCode": "43", "countyName": "Grayson" },
    { "countyCode": "44", "countyName": "Green" },
    { "countyCode": "45", "countyName": "Greenup" },
    { "countyCode": "46", "countyName": "Hancock" },
    { "countyCode": "47", "countyName": "Hardin" },
    { "countyCode": "48", "countyName": "Harlan" },
    { "countyCode": "49", "countyName": "Harrison" },
    { "countyCode": "50", "countyName": "Hart" },
    { "countyCode": "51", "countyName": "Henderson" },
    { "countyCode": "52", "countyName": "Henry" },
    { "countyCode": "53", "countyName": "Hickman" },
    { "countyCode": "54", "countyName": "Hopkins" },
    { "countyCode": "55", "countyName": "Jackson" },
    { "countyCode": "56", "countyName": "Jefferson" },
    { "countyCode": "67", "countyName": "Jessamine" },
    { "countyCode": "58", "countyName": "Johnson" },
    { "countyCode": "59", "countyName": "Kenton" },
    { "countyCode": "60", "countyName": "Knott" },
    { "countyCode": "61", "countyName": "Knox" },
    { "countyCode": "62", "countyName": "LaRue" },
    { "countyCode": "63", "countyName": "Laurel" },
    { "countyCode": "64", "countyName": "Lawrence" },
    { "countyCode": "65", "countyName": "Lee" },
    { "countyCode": "66", "countyName": "Leslie" },
    { "countyCode": "67", "countyName": "Letcher" },
    { "countyCode": "68", "countyName": "Lewis" },
    { "countyCode": "69", "countyName": "Lincoln" },
    { "countyCode": "70", "countyName": "Livingston" },
    { "countyCode": "71", "countyName": "Logan" },
    { "countyCode": "72", "countyName": "Lyon" },
    { "countyCode": "73", "countyName": "Madison" },
    { "countyCode": "74", "countyName": "Magoffin" },
    { "countyCode": "75", "countyName": "Marion" },
    { "countyCode": "76", "countyName": "Marshall" },
    { "countyCode": "77", "countyName": "Martin" },
    { "countyCode": "78", "countyName": "Mason" },
    { "countyCode": "79", "countyName": "McCracken" },
    { "countyCode": "80", "countyName": "McCreary" },
    { "countyCode": "81", "countyName": "McLean" },
    { "countyCode": "82", "countyName": "Meade" },
    { "countyCode": "83", "countyName": "Menifee" },
    { "countyCode": "84", "countyName": "Mercer" },
    { "countyCode": "85", "countyName": "Metcalfe" },
    { "countyCode": "86", "countyName": "Monroe" },
    { "countyCode": "87", "countyName": "Montgomery" },
    { "countyCode": "88", "countyName": "Morgan" },
    { "countyCode": "89", "countyName": "Muhlenberg" },
    { "countyCode": "90", "countyName": "Nelson" },
    { "countyCode": "91", "countyName": "Nicholas" },
    { "countyCode": "92", "countyName": "Ohio" },
    { "countyCode": "93", "countyName": "Oldham" },
    { "countyCode": "94", "countyName": "Owen" },
    { "countyCode": "95", "countyName": "Owsley" },
    { "countyCode": "96", "countyName": "Pendleton" },
    { "countyCode": "97", "countyName": "Perry" },
    { "countyCode": "98", "countyName": "Pike" },
    { "countyCode": "99", "countyName": "Powell" },
    { "countyCode": "100", "countyName": "Pulaski" },
    { "countyCode": "101", "countyName": "Robertson" },
    { "countyCode": "102", "countyName": "Rockcastle" },
    { "countyCode": "103", "countyName": "Rowan" },
    { "countyCode": "104", "countyName": "Russell" },
    { "countyCode": "105", "countyName": "Scott" },
    { "countyCode": "106", "countyName": "Shelby" },
    { "countyCode": "107", "countyName": "Simpson" },
    { "countyCode": "108", "countyName": "Spencer" },
    { "countyCode": "109", "countyName": "Taylor" },
    { "countyCode": "110", "countyName": "Todd" },
    { "countyCode": "111", "countyName": "Trigg" },
    { "countyCode": "112", "countyName": "Trimble" },
    { "countyCode": "113", "countyName": "Union" },
    { "countyCode": "114", "countyName": "Warren" },
    { "countyCode": "115", "countyName": "Washington" },
    { "countyCode": "116", "countyName": "Wayne" },
    { "countyCode": "117", "countyName": "Webster" },
    { "countyCode": "118", "countyName": "Whitley" },
    { "countyCode": "119", "countyName": "Wolfe" },
    { "countyCode": "120", "countyName": "Woodford" }
];
export const LouisianaCounties = [
    { "countyCode": "1", "countyName": "Acadia" },
    { "countyCode": "2", "countyName": "Allen" },
    { "countyCode": "3", "countyName": "Ascension" },
    { "countyCode": "4", "countyName": "Assumption" },
    { "countyCode": "5", "countyName": "Avoyelles" },
    { "countyCode": "6", "countyName": "Beauregard" },
    { "countyCode": "7", "countyName": "Bienville" },
    { "countyCode": "8", "countyName": "Bossier" },
    { "countyCode": "9", "countyName": "Caddo" },
    { "countyCode": "10", "countyName": "Calcasieu" },
    { "countyCode": "11", "countyName": "Caldwell" },
    { "countyCode": "12", "countyName": "Cameron" },
    { "countyCode": "13", "countyName": "Catahoula" },
    { "countyCode": "14", "countyName": "Claiborne" },
    { "countyCode": "15", "countyName": "Concordia" },
    { "countyCode": "16", "countyName": "DeSoto" },
    { "countyCode": "17", "countyName": "East Baton Rouge" },
    { "countyCode": "18", "countyName": "East Carroll" },
    { "countyCode": "19", "countyName": "East Feliciana" },
    { "countyCode": "20", "countyName": "Evangeline" },
    { "countyCode": "21", "countyName": "Franklin" },
    { "countyCode": "22", "countyName": "Grant" },
    { "countyCode": "23", "countyName": "Iberia" },
    { "countyCode": "24", "countyName": "Iberville" },
    { "countyCode": "25", "countyName": "Jackson" },
    { "countyCode": "26", "countyName": "Jefferson" },
    { "countyCode": "27", "countyName": "Jefferson Davis" },
    { "countyCode": "28", "countyName": "Lafayette" },
    { "countyCode": "29", "countyName": "Lafourche" },
    { "countyCode": "30", "countyName": "LaSalle" },
    { "countyCode": "31", "countyName": "Lincoln" },
    { "countyCode": "32", "countyName": "Livingston" },
    { "countyCode": "33", "countyName": "Madison" },
    { "countyCode": "34", "countyName": "Morehouse" },
    { "countyCode": "35", "countyName": "Natchitoches" },
    { "countyCode": "36", "countyName": "Orleans" },
    { "countyCode": "37", "countyName": "Ouachita" },
    { "countyCode": "38", "countyName": "Plaquemines" },
    { "countyCode": "39", "countyName": "Pointe Coupee" },
    { "countyCode": "40", "countyName": "Rapides" },
    { "countyCode": "41", "countyName": "Red River" },
    { "countyCode": "42", "countyName": "Richland" },
    { "countyCode": "43", "countyName": "Sabine" },
    { "countyCode": "44", "countyName": "Saint Bernard" },
    { "countyCode": "45", "countyName": "Saint Charles" },
    { "countyCode": "46", "countyName": "Saint Helena" },
    { "countyCode": "47", "countyName": "Saint James" },
    { "countyCode": "48", "countyName": "Saint John the Baptist" },
    { "countyCode": "49", "countyName": "Saint Landry" },
    { "countyCode": "50", "countyName": "Saint Martin" },
    { "countyCode": "51", "countyName": "Saint Mary" },
    { "countyCode": "52", "countyName": "Saint Tammany" },
    { "countyCode": "53", "countyName": "Tangipahoa" },
    { "countyCode": "54", "countyName": "Tensas" },
    { "countyCode": "55", "countyName": "Terrebonne" },
    { "countyCode": "56", "countyName": "Union" },
    { "countyCode": "67", "countyName": "Vermilion" },
    { "countyCode": "58", "countyName": "Vernon" },
    { "countyCode": "59", "countyName": "Washington" },
    { "countyCode": "60", "countyName": "Webster" },
    { "countyCode": "61", "countyName": "West Baton Rouge" },
    { "countyCode": "62", "countyName": "West Carroll" },
    { "countyCode": "63", "countyName": "West Feliciana" },
    { "countyCode": "64", "countyName": "Winn Parish" }
];
export const MaineCounties = [
    { "countyCode": "1", "countyName": "Androscoggin" },
    { "countyCode": "2", "countyName": "Aroostook" },
    { "countyCode": "3", "countyName": "Cumberland" },
    { "countyCode": "4", "countyName": "Franklin" },
    { "countyCode": "5", "countyName": "Hancock" },
    { "countyCode": "6", "countyName": "Kennebec" },
    { "countyCode": "7", "countyName": "Knox" },
    { "countyCode": "8", "countyName": "Lincoln" },
    { "countyCode": "9", "countyName": "Oxford" },
    { "countyCode": "10", "countyName": "Penobscot" },
    { "countyCode": "11", "countyName": "Piscataquis" },
    { "countyCode": "12", "countyName": "Sagadahoc" },
    { "countyCode": "13", "countyName": "Somerset" },
    { "countyCode": "14", "countyName": "Waldo" },
    { "countyCode": "15", "countyName": "Washington" },
    { "countyCode": "16", "countyName": "York" }
];
export const MarylandCounties = [
    { "countyCode": "1", "countyName": "Allegany" },
    { "countyCode": "2", "countyName": "Anne Arundel" },
    { "countyCode": "3", "countyName": "Baltimore" },
    { "countyCode": "4", "countyName": "Baltimore" },
    { "countyCode": "5", "countyName": "Calvert" },
    { "countyCode": "6", "countyName": "Caroline" },
    { "countyCode": "7", "countyName": "Carroll" },
    { "countyCode": "8", "countyName": "Cecil" },
    { "countyCode": "9", "countyName": "Charles" },
    { "countyCode": "10", "countyName": "Dorchester" },
    { "countyCode": "11", "countyName": "Frederick" },
    { "countyCode": "12", "countyName": "Garrett" },
    { "countyCode": "13", "countyName": "Harford" },
    { "countyCode": "14", "countyName": "Howard" },
    { "countyCode": "15", "countyName": "Kent" },
    { "countyCode": "16", "countyName": "Montgomery" },
    { "countyCode": "17", "countyName": "Prince George's" },
    { "countyCode": "18", "countyName": "Queen Anne's" },
    { "countyCode": "19", "countyName": "Saint Mary's" },
    { "countyCode": "20", "countyName": "Somerset" },
    { "countyCode": "21", "countyName": "Talbot" },
    { "countyCode": "22", "countyName": "Washington" },
    { "countyCode": "23", "countyName": "Wicomico" },
    { "countyCode": "24", "countyName": "Worcester" },
];
export const MassachusettsCounties = [
    { "countyCode": "1", "countyName": "Barnstable" },
    { "countyCode": "2", "countyName": "Berkshire" },
    { "countyCode": "3", "countyName": "Bristol" },
    { "countyCode": "4", "countyName": "Dukes" },
    { "countyCode": "5", "countyName": "Essex" },
    { "countyCode": "6", "countyName": "Franklin" },
    { "countyCode": "7", "countyName": "Hampden" },
    { "countyCode": "8", "countyName": "Hampshire" },
    { "countyCode": "9", "countyName": "Middlesex" },
    { "countyCode": "10", "countyName": "Nantucket" },
    { "countyCode": "11", "countyName": "Norfolk" },
    { "countyCode": "12", "countyName": "Plymouth" },
    { "countyCode": "13", "countyName": "Suffolk" },
    { "countyCode": "14", "countyName": "Worcester" }
];
export const MinnesotaCounties = [
    { "countyCode": "1", "countyName": "Aitkin" },
    { "countyCode": "2", "countyName": "Anoka" },
    { "countyCode": "3", "countyName": "Becker" },
    { "countyCode": "4", "countyName": "Beltrami" },
    { "countyCode": "5", "countyName": "Benton" },
    { "countyCode": "6", "countyName": "Big Stone" },
    { "countyCode": "7", "countyName": "Blue Earth" },
    { "countyCode": "8", "countyName": "Brown" },
    { "countyCode": "9", "countyName": "Carlton" },
    { "countyCode": "10", "countyName": "Carver" },
    { "countyCode": "11", "countyName": "Cass" },
    { "countyCode": "12", "countyName": "Chippewa" },
    { "countyCode": "13", "countyName": "Chisago" },
    { "countyCode": "14", "countyName": "Clay" },
    { "countyCode": "15", "countyName": "Clearwater" },
    { "countyCode": "16", "countyName": "Cook" },
    { "countyCode": "17", "countyName": "Cottonwood" },
    { "countyCode": "18", "countyName": "Crow Wing" },
    { "countyCode": "19", "countyName": "Dakota" },
    { "countyCode": "20", "countyName": "Dodge" },
    { "countyCode": "21", "countyName": "Douglas" },
    { "countyCode": "22", "countyName": "Faribault" },
    { "countyCode": "23", "countyName": "Fillmore" },
    { "countyCode": "24", "countyName": "Freeborn" },
    { "countyCode": "25", "countyName": "Goodhue" },
    { "countyCode": "26", "countyName": "Grant" },
    { "countyCode": "27", "countyName": "Hennepin" },
    { "countyCode": "28", "countyName": "Houston" },
    { "countyCode": "29", "countyName": "Hubbard" },
    { "countyCode": "30", "countyName": "Isanti" },
    { "countyCode": "31", "countyName": "Itasca" },
    { "countyCode": "32", "countyName": "Jackson" },
    { "countyCode": "33", "countyName": "Kanabec" },
    { "countyCode": "34", "countyName": "Kandiyohi" },
    { "countyCode": "35", "countyName": "Kittson" },
    { "countyCode": "36", "countyName": "Koochiching" },
    { "countyCode": "37", "countyName": "Lac qui Parle" },
    { "countyCode": "38", "countyName": "Lake" },
    { "countyCode": "39", "countyName": "Lake of the Woods" },
    { "countyCode": "40", "countyName": "Le Sueur" },
    { "countyCode": "41", "countyName": "Lincoln" },
    { "countyCode": "42", "countyName": "Lyon" },
    { "countyCode": "43", "countyName": "McLeod" },
    { "countyCode": "44", "countyName": "Mahnomen" },
    { "countyCode": "45", "countyName": "Marshall" },
    { "countyCode": "46", "countyName": "Martin" },
    { "countyCode": "47", "countyName": "Meeker" },
    { "countyCode": "48", "countyName": "Mille Lacs" },
    { "countyCode": "49", "countyName": "Morrison" },
    { "countyCode": "50", "countyName": "Mower" },
    { "countyCode": "51", "countyName": "Murray" },
    { "countyCode": "52", "countyName": "Nicollet" },
    { "countyCode": "53", "countyName": "Nobles" },
    { "countyCode": "54", "countyName": "Norman" },
    { "countyCode": "55", "countyName": "Olmsted" },
    { "countyCode": "56", "countyName": "Otter Tail" },
    { "countyCode": "57", "countyName": "Pennington" },
    { "countyCode": "58", "countyName": "Pine" },
    { "countyCode": "59", "countyName": "Pipestone" },
    { "countyCode": "60", "countyName": "Polk" },
    { "countyCode": "61", "countyName": "Pope" },
    { "countyCode": "62", "countyName": "Ramsey" },
    { "countyCode": "63", "countyName": "Red Lake" },
    { "countyCode": "64", "countyName": "Redwood" },
    { "countyCode": "65", "countyName": "Renville" },
    { "countyCode": "66", "countyName": "Rice" },
    { "countyCode": "67", "countyName": "Rock" },
    { "countyCode": "68", "countyName": "Roseau" },
    { "countyCode": "69", "countyName": "St. Louis" },
    { "countyCode": "70", "countyName": "Scott" },
    { "countyCode": "71", "countyName": "Sherburne" },
    { "countyCode": "72", "countyName": "Sibley" },
    { "countyCode": "73", "countyName": "Stearns" },
    { "countyCode": "74", "countyName": "Steele" },
    { "countyCode": "75", "countyName": "Stevens" },
    { "countyCode": "76", "countyName": "Swift" },
    { "countyCode": "77", "countyName": "Todd" },
    { "countyCode": "78", "countyName": "Traverse" },
    { "countyCode": "79", "countyName": "Wabasha" },
    { "countyCode": "80", "countyName": "Wadena" },
    { "countyCode": "81", "countyName": "Waseca" },
    { "countyCode": "82", "countyName": "Washington" },
    { "countyCode": "83", "countyName": "Watonwan" },
    { "countyCode": "84", "countyName": "Wilkin" },
    { "countyCode": "85", "countyName": "Winona" },
    { "countyCode": "86", "countyName": "Wright" },
    { "countyCode": "87", "countyName": "Yellow Medicine" }
];
export const MichiganCounties = [
    { "countyCode": "1", "countyName": 'alcona' },
    { "countyCode": "2", "countyName": 'alger' },
    { "countyCode": "3", "countyName": 'allegan' },
    { "countyCode": "4", "countyName": 'alpena' },
    { "countyCode": "5", "countyName": 'antrim' },
    { "countyCode": "6", "countyName": 'arenac' },
    { "countyCode": "7", "countyName": 'baraga' },
    { "countyCode": "8", "countyName": 'barry' },
    { "countyCode": "9", "countyName": 'bay' },
    { "countyCode": "10", "countyName": 'benzie' },
    { "countyCode": "11", "countyName": 'berrien' },
    { "countyCode": "12", "countyName": 'branch' },
    { "countyCode": "13", "countyName": 'calhoun' },
    { "countyCode": "14", "countyName": 'cass' },
    { "countyCode": "15", "countyName": 'charlevoix' },
    { "countyCode": "16", "countyName": 'cheboygan' },
    { "countyCode": "17", "countyName": 'chippewa' },
    { "countyCode": "18", "countyName": 'clare' },
    { "countyCode": "19", "countyName": 'clinton' },
    { "countyCode": "20", "countyName": 'crawford' },
    { "countyCode": "21", "countyName": 'delta' },
    { "countyCode": "22", "countyName": 'dickinson' },
    { "countyCode": "23", "countyName": 'eaton' },
    { "countyCode": "24", "countyName": 'emmet' },
    { "countyCode": "25", "countyName": 'genesee' },
    { "countyCode": "26", "countyName": 'gladwin' },
    { "countyCode": "27", "countyName": 'gogebic' },
    { "countyCode": "28", "countyName": 'grand traverse' },
    { "countyCode": "29", "countyName": 'gratiot' },
    { "countyCode": "30", "countyName": 'hillsdale' },
    { "countyCode": "31", "countyName": 'houghton' },
    { "countyCode": "32", "countyName": 'huron' },
    { "countyCode": "33", "countyName": 'ingham' },
    { "countyCode": "34", "countyName": 'ionia' },
    { "countyCode": "35", "countyName": 'iosco' },
    { "countyCode": "36", "countyName": 'iron' },
    { "countyCode": "37", "countyName": 'isabella' },
    { "countyCode": "38", "countyName": 'jackson' },
    { "countyCode": "39", "countyName": 'kalamazoo' },
    { "countyCode": "40", "countyName": 'kalkaska' },
    { "countyCode": "41", "countyName": 'kent' },
    { "countyCode": "42", "countyName": 'keweenaw' },
    { "countyCode": "43", "countyName": 'lake' },
    { "countyCode": "44", "countyName": 'lapeer' },
    { "countyCode": "45", "countyName": 'leelan' },
    { "countyCode": "46", "countyName": 'lenawee' },
    { "countyCode": "47", "countyName": 'livingston' },
    { "countyCode": "48", "countyName": 'lucassen' },
    { "countyCode": "49", "countyName": 'mackinac' },
    { "countyCode": "50", "countyName": 'macomb' },
    { "countyCode": "51", "countyName": 'manistee' },
    { "countyCode": "52", "countyName": 'marquette' },
    { "countyCode": "53", "countyName": 'mason' },
    { "countyCode": "54", "countyName": 'mecosta' },
    { "countyCode": "55", "countyName": 'menominee' },
    { "countyCode": "56", "countyName": 'midland' },
    { "countyCode": "57", "countyName": 'missaukee' },
    { "countyCode": "58", "countyName": 'monroe' },
    { "countyCode": "59", "countyName": 'montcalm' },
    { "countyCode": "60", "countyName": 'montmorency' },
    { "countyCode": "61", "countyName": 'muskegon' },
    { "countyCode": "62", "countyName": 'newaygo' },
    { "countyCode": "63", "countyName": 'oakland' },
    { "countyCode": "64", "countyName": 'oceana' },
    { "countyCode": "65", "countyName": 'ogemaw' },
    { "countyCode": "66", "countyName": 'ontonagon' },
    { "countyCode": "67", "countyName": 'osceola' },
    { "countyCode": "68", "countyName": 'oscoda' },
    { "countyCode": "69", "countyName": 'otsego' },
    { "countyCode": "70", "countyName": 'ottawa' },
    { "countyCode": "71", "countyName": 'presque isle' },
    { "countyCode": "72", "countyName": 'roscommon' },
    { "countyCode": "73", "countyName": 'saginaw' },
    { "countyCode": "74", "countyName": 'st clair' },
    { "countyCode": "75", "countyName": 'st joseph' },
    { "countyCode": "76", "countyName": 'sanilac' },
    { "countyCode": "77", "countyName": 'schoolcraft' },
    { "countyCode": "78", "countyName": 'shiawassee' },
    { "countyCode": "79", "countyName": 'tuscola' },
    { "countyCode": "80", "countyName": 'van buren' },
    { "countyCode": "81", "countyName": 'washtenaw' },
    { "countyCode": "82", "countyName": 'wayne' },
    { "countyCode": "83", "countyName": 'wexford' },
];
export const MississippiCounties = [
    { "countyCode": "1", "countyName": "Adams" },
    { "countyCode": "2", "countyName": "Alcorn" },
    { "countyCode": "3", "countyName": "Amite" },
    { "countyCode": "4", "countyName": "Attala" },
    { "countyCode": "5", "countyName": "Benton" },
    { "countyCode": "6", "countyName": "Bolivar" },
    { "countyCode": "7", "countyName": "Calhoun" },
    { "countyCode": "8", "countyName": "Carroll" },
    { "countyCode": "9", "countyName": "Chickasaw" },
    { "countyCode": "10", "countyName": "Choctaw" },
    { "countyCode": "11", "countyName": "Claiborne" },
    { "countyCode": "12", "countyName": "Clarke" },
    { "countyCode": "13", "countyName": "Clay" },
    { "countyCode": "14", "countyName": "Coahoma" },
    { "countyCode": "15", "countyName": "Copiah" },
    { "countyCode": "16", "countyName": "Covington" },
    { "countyCode": "17", "countyName": "DeSoto" },
    { "countyCode": "18", "countyName": "Forrest" },
    { "countyCode": "19", "countyName": "Franklin" },
    { "countyCode": "20", "countyName": "George" },
    { "countyCode": "21", "countyName": "Greene" },
    { "countyCode": "22", "countyName": "Grenada" },
    { "countyCode": "23", "countyName": "Hancock" },
    { "countyCode": "24", "countyName": "Harrison" },
    { "countyCode": "25", "countyName": "Hinds" },
    { "countyCode": "26", "countyName": "Holmes" },
    { "countyCode": "27", "countyName": "Humphreys" },
    { "countyCode": "28", "countyName": "Issaquena" },
    { "countyCode": "29", "countyName": "Itawamba" },
    { "countyCode": "30", "countyName": "Jackson" },
    { "countyCode": "31", "countyName": "Jasper" },
    { "countyCode": "32", "countyName": "Jefferson" },
    { "countyCode": "33", "countyName": "Jefferson Davis" },
    { "countyCode": "34", "countyName": "Jones" },
    { "countyCode": "35", "countyName": "Kemper" },
    { "countyCode": "36", "countyName": "Lafayette" },
    { "countyCode": "37", "countyName": "Lamar" },
    { "countyCode": "38", "countyName": "Lauderdale" },
    { "countyCode": "39", "countyName": "Lawrence" },
    { "countyCode": "40", "countyName": "Leake" },
    { "countyCode": "41", "countyName": "Lee" },
    { "countyCode": "42", "countyName": "Leflore" },
    { "countyCode": "43", "countyName": "Lincoln" },
    { "countyCode": "44", "countyName": "Lowndes" },
    { "countyCode": "45", "countyName": "Madison" },
    { "countyCode": "46", "countyName": "Marion" },
    { "countyCode": "47", "countyName": "Marshall" },
    { "countyCode": "48", "countyName": "Monroe" },
    { "countyCode": "49", "countyName": "Montgomery" },
    { "countyCode": "50", "countyName": "Neshoba" },
    { "countyCode": "51", "countyName": "Newton" },
    { "countyCode": "52", "countyName": "Noxubee" },
    { "countyCode": "53", "countyName": "Oktibbeha" },
    { "countyCode": "54", "countyName": "Panola" },
    { "countyCode": "55", "countyName": "Pearl River" },
    { "countyCode": "56", "countyName": "Perry" },
    { "countyCode": "57", "countyName": "Pike" },
    { "countyCode": "58", "countyName": "Pontotoc" },
    { "countyCode": "59", "countyName": "Prentiss" },
    { "countyCode": "60", "countyName": "Quitman" },
    { "countyCode": "61", "countyName": "Rankin" },
    { "countyCode": "62", "countyName": "Scott" },
    { "countyCode": "63", "countyName": "Sharkey" },
    { "countyCode": "64", "countyName": "Simpson" },
    { "countyCode": "65", "countyName": "Smith" },
    { "countyCode": "66", "countyName": "Stone" },
    { "countyCode": "67", "countyName": "Sunflower" },
    { "countyCode": "68", "countyName": "Tallahatchie" },
    { "countyCode": "69", "countyName": "Tate" },
    { "countyCode": "70", "countyName": "Tippah" },
    { "countyCode": "71", "countyName": "Tishomingo" },
    { "countyCode": "72", "countyName": "Tunica" },
    { "countyCode": "73", "countyName": "Union" },
    { "countyCode": "74", "countyName": "Walthall" },
    { "countyCode": "75", "countyName": "Warren" },
    { "countyCode": "76", "countyName": "Washington" },
    { "countyCode": "77", "countyName": "Wayne" },
    { "countyCode": "78", "countyName": "Webster" },
    { "countyCode": "79", "countyName": "Wilkinson" },
    { "countyCode": "80", "countyName": "Winston" },
    { "countyCode": "81", "countyName": "Yalobusha" },
    { "countyCode": "82", "countyName": "Yazoo"}
];
export const MissouriCounties = [
    { "countyCode": "1", "countyName": "Adair" },
    { "countyCode": "2", "countyName": "Andrew" },
    { "countyCode": "3", "countyName": "Atchison" },
    { "countyCode": "4", "countyName": "Audrain" },
    { "countyCode": "5", "countyName": "Barry" },
    { "countyCode": "6", "countyName": "Barton" },
    { "countyCode": "7", "countyName": "Bates" },
    { "countyCode": "8", "countyName": "Benton" },
    { "countyCode": "9", "countyName": "Bollinger" },
    { "countyCode": "10", "countyName": "Boone" },
    { "countyCode": "11", "countyName": "Buchanan" },
    { "countyCode": "12", "countyName": "Butler" },
    { "countyCode": "13", "countyName": "Caldwell" },
    { "countyCode": "14", "countyName": "Callaway" },
    { "countyCode": "15", "countyName": "Camden" },
    { "countyCode": "16", "countyName": "Cape Girardeau" },
    { "countyCode": "17", "countyName": "Carroll" },
    { "countyCode": "18", "countyName": "Carter" },
    { "countyCode": "19", "countyName": "Cass" },
    { "countyCode": "20", "countyName": "Cedar" },
    { "countyCode": "21", "countyName": "Chariton" },
    { "countyCode": "22", "countyName": "Christian" },
    { "countyCode": "23", "countyName": "Clark" },
    { "countyCode": "24", "countyName": "Clay" },
    { "countyCode": "25", "countyName": "Clinton" },
    { "countyCode": "26", "countyName": "Cole" },
    { "countyCode": "27", "countyName": "Cooper" },
    { "countyCode": "28", "countyName": "Crawford" },
    { "countyCode": "29", "countyName": "Dade" },
    { "countyCode": "30", "countyName": "Dallas" },
    { "countyCode": "31", "countyName": "Daviess" },
    { "countyCode": "32", "countyName": "DeKalb" },
    { "countyCode": "33", "countyName": "Dent" },
    { "countyCode": "34", "countyName": "Douglas" },
    { "countyCode": "35", "countyName": "Dunklin" },
    { "countyCode": "36", "countyName": "Franklin" },
    { "countyCode": "37", "countyName": "Gasconade" },
    { "countyCode": "38", "countyName": "Gentry" },
    { "countyCode": "39", "countyName": "Greene" },
    { "countyCode": "40", "countyName": "Grundy" },
    { "countyCode": "41", "countyName": "Harrison" },
    { "countyCode": "42", "countyName": "Henry" },
    { "countyCode": "43", "countyName": "Hickory" },
    { "countyCode": "44", "countyName": "Holt" },
    { "countyCode": "45", "countyName": "Howard" },
    { "countyCode": "46", "countyName": "Howell" },
    { "countyCode": "47", "countyName": "Iron" },
    { "countyCode": "48", "countyName": "Jackson" },
    { "countyCode": "49", "countyName": "Jasper" },
    { "countyCode": "50", "countyName": "Jefferson" },
    { "countyCode": "51", "countyName": "Johnson" },
    { "countyCode": "52", "countyName": "Knox" },
    { "countyCode": "53", "countyName": "Laclede" },
    { "countyCode": "54", "countyName": "Lafayette" },
    { "countyCode": "55", "countyName": "Lawrence" },
    { "countyCode": "56", "countyName": "Lewis" },
    { "countyCode": "57", "countyName": "Lincoln" },
    { "countyCode": "58", "countyName": "Linn" },
    { "countyCode": "59", "countyName": "Livingston" },
    { "countyCode": "60", "countyName": "McDonald" },
    { "countyCode": "61", "countyName": "Macon" },
    { "countyCode": "62", "countyName": "Madison" },
    { "countyCode": "63", "countyName": "Maries" },
    { "countyCode": "64", "countyName": "Marion" },
    { "countyCode": "65", "countyName": "Mercer" },
    { "countyCode": "66", "countyName": "Miller" },
    { "countyCode": "67", "countyName": "Mississippi" },
    { "countyCode": "68", "countyName": "Moniteau" },
    { "countyCode": "69", "countyName": "Monroe" },
    { "countyCode": "70", "countyName": "Montgomery" },
    { "countyCode": "71", "countyName": "Morgan" },
    { "countyCode": "72", "countyName": "New Madrid" },
    { "countyCode": "73", "countyName": "Newton" },
    { "countyCode": "74", "countyName": "Nodaway" },
    { "countyCode": "75", "countyName": "Oregon" },
    { "countyCode": "76", "countyName": "Osage" },
    { "countyCode": "77", "countyName": "Ozark" },
    { "countyCode": "78", "countyName": "Pemiscot" },
    { "countyCode": "79", "countyName": "Perry" },
    { "countyCode": "80", "countyName": "Pettis" },
    { "countyCode": "81", "countyName": "Phelps" },
    { "countyCode": "82", "countyName": "Pike" },
    { "countyCode": "83", "countyName": "Platte" },
    { "countyCode": "84", "countyName": "Polk" },
    { "countyCode": "85", "countyName": "Pulaski" },
    { "countyCode": "86", "countyName": "Putnam" },
    { "countyCode": "87", "countyName": "Ralls" },
    { "countyCode": "88", "countyName": "Randolph" },
    { "countyCode": "89", "countyName": "Ray" },
    { "countyCode": "90", "countyName": "Reynolds" },
    { "countyCode": "91", "countyName": "Ripley" },
    { "countyCode": "92", "countyName": "St. Charles" },
    { "countyCode": "93", "countyName": "St. Clair" },
    { "countyCode": "94", "countyName": "Ste. Genevieve" },
    { "countyCode": "95", "countyName": "St. Francois" },
    { "countyCode": "96", "countyName": "St. Louis" },
    { "countyCode": "97", "countyName": "Saline" },
    { "countyCode": "98", "countyName": "Schuyler" },
    { "countyCode": "99", "countyName": "Scotland" },
    { "countyCode": "100", "countyName": "Scott" },
    { "countyCode": "101", "countyName": "Shannon" },
    { "countyCode": "102", "countyName": "Shelby" },
    { "countyCode": "103", "countyName": "Stoddard" },
    { "countyCode": "104", "countyName": "Stone" },
    { "countyCode": "105", "countyName": "Sullivan" },
    { "countyCode": "106", "countyName": "Taney" },
    { "countyCode": "107", "countyName": "Texas" },
    { "countyCode": "108", "countyName": "Vernon" },
    { "countyCode": "109", "countyName": "Warren" },
    { "countyCode": "110", "countyName": "Washington" },
    { "countyCode": "111", "countyName": "Wayne" },
    { "countyCode": "112", "countyName": "Webster" },
    { "countyCode": "113", "countyName": "Worth" },
    { "countyCode": "114", "countyName": "Wright" },
];
export const MontanaCounties = [
    { "countyCode": "1", "countyName": "Beaverhead" },
    { "countyCode": "2", "countyName": "Big Horn" },
    { "countyCode": "3", "countyName": "Blaine" },
    { "countyCode": "4", "countyName": "Broadwater" },
    { "countyCode": "5", "countyName": "Carbon" },
    { "countyCode": "6", "countyName": "Carter" },
    { "countyCode": "7", "countyName": "Cascade" },
    { "countyCode": "8", "countyName": "Chouteau" },
    { "countyCode": "9", "countyName": "Custer" },
    { "countyCode": "10", "countyName": "Daniels" },
    { "countyCode": "11", "countyName": "Dawson" },
    { "countyCode": "12", "countyName": "Deer Lodge" },
    { "countyCode": "13", "countyName": "Fallon" },
    { "countyCode": "14", "countyName": "Fergus" },
    { "countyCode": "15", "countyName": "Flathead" },
    { "countyCode": "16", "countyName": "Gallatin" },
    { "countyCode": "17", "countyName": "Garfield" },
    { "countyCode": "18", "countyName": "Glacier" },
    { "countyCode": "19", "countyName": "Golden Valley" },
    { "countyCode": "20", "countyName": "Granite" },
    { "countyCode": "21", "countyName": "Hill" },
    { "countyCode": "22", "countyName": "Jefferson" },
    { "countyCode": "23", "countyName": "Judith Basin" },
    { "countyCode": "24", "countyName": "Lake" },
    { "countyCode": "25", "countyName": "Lewis and Clark" },
    { "countyCode": "26", "countyName": "Liberty" },
    { "countyCode": "27", "countyName": "Lincoln" },
    { "countyCode": "28", "countyName": "Madison" },
    { "countyCode": "29", "countyName": "McCone" },
    { "countyCode": "30", "countyName": "Meagher" },
    { "countyCode": "31", "countyName": "Mineral" },
    { "countyCode": "32", "countyName": "Missoula" },
    { "countyCode": "33", "countyName": "Musselshell" },
    { "countyCode": "34", "countyName": "Park" },
    { "countyCode": "35", "countyName": "Petroleum" },
    { "countyCode": "36", "countyName": "Phillips" },
    { "countyCode": "37", "countyName": "Pondera" },
    { "countyCode": "38", "countyName": "Powder River" },
    { "countyCode": "39", "countyName": "Powell" },
    { "countyCode": "40", "countyName": "Prairie" },
    { "countyCode": "41", "countyName": "Ravalli" },
    { "countyCode": "42", "countyName": "Richland" },
    { "countyCode": "43", "countyName": "Roosevelt" },
    { "countyCode": "44", "countyName": "Rosebud" },
    { "countyCode": "45", "countyName": "Sanders" },
    { "countyCode": "46", "countyName": "Sheridan" },
    { "countyCode": "47", "countyName": "Silver Bow" },
    { "countyCode": "48", "countyName": "Stillwater" },
    { "countyCode": "49", "countyName": "Sweet Grass" },
    { "countyCode": "50", "countyName": "Teton" },
    { "countyCode": "51", "countyName": "Toole" },
    { "countyCode": "52", "countyName": "Treasure" },
    { "countyCode": "53", "countyName": "Valley" },
    { "countyCode": "54", "countyName": "Wheatland" },
    { "countyCode": "55", "countyName": "Wibaux" },
    { "countyCode": "56", "countyName": "Yellowstone" },
];
export const NebraskaCounties = [
    { "countyCode": "1", "countyName": "Adams" },
    { "countyCode": "2", "countyName": "Antelope" },
    { "countyCode": "3", "countyName": "Arthur" },
    { "countyCode": "4", "countyName": "Banner" },
    { "countyCode": "5", "countyName": "Blaine" },
    { "countyCode": "6", "countyName": "Boone" },
    { "countyCode": "7", "countyName": "Box Butte" },
    { "countyCode": "8", "countyName": "Boyd" },
    { "countyCode": "9", "countyName": "Brown" },
    { "countyCode": "10", "countyName": "Buffalo" },
    { "countyCode": "11", "countyName": "Burt" },
    { "countyCode": "12", "countyName": "Butler" },
    { "countyCode": "13", "countyName": "Cass" },
    { "countyCode": "14", "countyName": "Cedar" },
    { "countyCode": "15", "countyName": "Chase" },
    { "countyCode": "16", "countyName": "Cherry" },
    { "countyCode": "17", "countyName": "Cheyenne" },
    { "countyCode": "18", "countyName": "Clay" },
    { "countyCode": "19", "countyName": "Colfax" },
    { "countyCode": "20", "countyName": "Cuming" },
    { "countyCode": "21", "countyName": "Custer" },
    { "countyCode": "22", "countyName": "Dakota" },
    { "countyCode": "23", "countyName": "Dawes" },
    { "countyCode": "24", "countyName": "Dawson" },
    { "countyCode": "25", "countyName": "Deuel" },
    { "countyCode": "26", "countyName": "Dixon" },
    { "countyCode": "27", "countyName": "Dodge" },
    { "countyCode": "28", "countyName": "Douglas" },
    { "countyCode": "29", "countyName": "Dundy" },
    { "countyCode": "30", "countyName": "Fillmore" },
    { "countyCode": "31", "countyName": "Franklin" },
    { "countyCode": "32", "countyName": "Frontier" },
    { "countyCode": "33", "countyName": "Furnas" },
    { "countyCode": "34", "countyName": "Gage" },
    { "countyCode": "35", "countyName": "Garden" },
    { "countyCode": "36", "countyName": "Garfield" },
    { "countyCode": "37", "countyName": "Gosper" },
    { "countyCode": "38", "countyName": "Grant" },
    { "countyCode": "39", "countyName": "Greeley" },
    { "countyCode": "40", "countyName": "Hall" },
    { "countyCode": "41", "countyName": "Hamilton" },
    { "countyCode": "42", "countyName": "Harlan" },
    { "countyCode": "43", "countyName": "Hayes" },
    { "countyCode": "44", "countyName": "Hitchcock" },
    { "countyCode": "45", "countyName": "Holt" },
    { "countyCode": "46", "countyName": "Hooker" },
    { "countyCode": "47", "countyName": "Howard" },
    { "countyCode": "48", "countyName": "Jefferson" },
    { "countyCode": "49", "countyName": "Johnson" },
    { "countyCode": "50", "countyName": "Kearney" },
    { "countyCode": "51", "countyName": "Keith" },
    { "countyCode": "52", "countyName": "Keya Paha" },
    { "countyCode": "53", "countyName": "Kimball" },
    { "countyCode": "54", "countyName": "Knox" },
    { "countyCode": "55", "countyName": "Lancaster" },
    { "countyCode": "56", "countyName": "Lincoln" },
    { "countyCode": "57", "countyName": "Logan" },
    { "countyCode": "58", "countyName": "Loup" },
    { "countyCode": "59", "countyName": "McPherson" },
    { "countyCode": "60", "countyName": "Madison" },
    { "countyCode": "61", "countyName": "Merrick" },
    { "countyCode": "62", "countyName": "Morrill" },
    { "countyCode": "63", "countyName": "Nance" },
    { "countyCode": "64", "countyName": "Nemaha" },
    { "countyCode": "65", "countyName": "Nuckolls" },
    { "countyCode": "66", "countyName": "Otoe" },
    { "countyCode": "67", "countyName": "Pawnee" },
    { "countyCode": "68", "countyName": "Perkins" },
    { "countyCode": "69", "countyName": "Phelps" },
    { "countyCode": "70", "countyName": "Pierce" },
    { "countyCode": "71", "countyName": "Platte" },
    { "countyCode": "72", "countyName": "Polk" },
    { "countyCode": "73", "countyName": "Red Willow" },
    { "countyCode": "74", "countyName": "Richardson" },
    { "countyCode": "75", "countyName": "Rock" },
    { "countyCode": "76", "countyName": "Saline" },
    { "countyCode": "77", "countyName": "Sarpy" },
    { "countyCode": "78", "countyName": "Saunders" },
    { "countyCode": "79", "countyName": "Scotts Bluff" },
    { "countyCode": "80", "countyName": "Seward" },
    { "countyCode": "81", "countyName": "Sheridan" },
    { "countyCode": "82", "countyName": "Sherman" },
    { "countyCode": "83", "countyName": "Sioux" },
    { "countyCode": "84", "countyName": "Stanton" },
    { "countyCode": "85", "countyName": "Thayer" },
    { "countyCode": "86", "countyName": "Thomas" },
    { "countyCode": "87", "countyName": "Thurston" },
    { "countyCode": "88", "countyName": "Valley" },
    { "countyCode": "89", "countyName": "Washington" },
    { "countyCode": "90", "countyName": "Wayne" },
    { "countyCode": "91", "countyName": "Webster" },
    { "countyCode": "92", "countyName": "Wheeler" },
    { "countyCode": "93", "countyName": "York" },
];
export const NevadaCounties = [
    { "countyCode": "1", "countyName": "Churchill" },
    { "countyCode": "2", "countyName": "Clark" },
    { "countyCode": "3", "countyName": "Douglas" },
    { "countyCode": "4", "countyName": "Elko" },
    { "countyCode": "5", "countyName": "Esmeralda" },
    { "countyCode": "6", "countyName": "Eureka" },
    { "countyCode": "7", "countyName": "Humboldt" },
    { "countyCode": "8", "countyName": "Lander" },
    { "countyCode": "9", "countyName": "Lincoln" },
    { "countyCode": "10", "countyName": "Lyon" },
    { "countyCode": "11", "countyName": "Mineral" },
    { "countyCode": "12", "countyName": "Nye" },
    { "countyCode": "13", "countyName": "Pershing" },
    { "countyCode": "14", "countyName": "Storey" },
    { "countyCode": "15", "countyName": "Washoe" },
    { "countyCode": "16", "countyName": "White Pine" },
];
export const NewYorkCounties = [
    { "countyCode": "1", "countyName": "albany" },
    { "countyCode": "2", "countyName": "allegany" },
    { "countyCode": "3", "countyName": "bronx" },
    { "countyCode": "4", "countyName": "broome" },
    { "countyCode": "5", "countyName": "cattaraugus" },
    { "countyCode": "6", "countyName": "cayuga" },
    { "countyCode": "7", "countyName": "chautauqua" },
    { "countyCode": "8", "countyName": "chemung" },
    { "countyCode": "9", "countyName": "chenango" },
    { "countyCode": "10", "countyName": "clinton" },
    { "countyCode": "11", "countyName": "columbia" },
    { "countyCode": "12", "countyName": "cortland" },
    { "countyCode": "13", "countyName": "delaware" },
    { "countyCode": "14", "countyName": "dutchess" },
    { "countyCode": "15", "countyName": "erie" },
    { "countyCode": "16", "countyName": "essex" },
    { "countyCode": "17", "countyName": "franklin" },
    { "countyCode": "18", "countyName": "fulton" },
    { "countyCode": "19", "countyName": "genesee" },
    { "countyCode": "20", "countyName": "greene" },
    { "countyCode": "21", "countyName": "hamilton" },
    { "countyCode": "22", "countyName": "herkimer" },
    { "countyCode": "23", "countyName": "jefferson" },
    { "countyCode": "24", "countyName": "kings" },
    { "countyCode": "25", "countyName": "lewis" },
    { "countyCode": "26", "countyName": "livingston" },
    { "countyCode": "27", "countyName": "madison" },
    { "countyCode": "28", "countyName": "monroe" },
    { "countyCode": "29", "countyName": "montgomery" },
    { "countyCode": "30", "countyName": "nassau" },
    { "countyCode": "31", "countyName": "new york" },
    { "countyCode": "32", "countyName": "niagara" },
    { "countyCode": "33", "countyName": "oneida" },
    { "countyCode": "34", "countyName": "onondaga" },
    { "countyCode": "35", "countyName": "ontario" },
    { "countyCode": "36", "countyName": "orange" },
    { "countyCode": "37", "countyName": "orleans" },
    { "countyCode": "38", "countyName": "oswego" },
    { "countyCode": "39", "countyName": "otsego" },
    { "countyCode": "40", "countyName": "putnam" },
    { "countyCode": "41", "countyName": "queens" },
    { "countyCode": "42", "countyName": "rensselaer" },
    { "countyCode": "43", "countyName": "richmond" },
    { "countyCode": "44", "countyName": "rockland" },
    { "countyCode": "45", "countyName": "st. lawrence" },
    { "countyCode": "46", "countyName": "saratoga" },
    { "countyCode": "47", "countyName": "schenectady" },
    { "countyCode": "48", "countyName": "schoharie" },
    { "countyCode": "49", "countyName": "schuyler" },
    { "countyCode": "50", "countyName": "seneca" },
    { "countyCode": "51", "countyName": "steuben" },
    { "countyCode": "52", "countyName": "suffolk" },
    { "countyCode": "53", "countyName": "sullivan" },
    { "countyCode": "54", "countyName": "tioga" },
    { "countyCode": "55", "countyName": "tompkins" },
    { "countyCode": "56", "countyName": "ulster" },
    { "countyCode": "57", "countyName": "warren" },
    { "countyCode": "58", "countyName": "washington" },
    { "countyCode": "59", "countyName": "wayne" },
    { "countyCode": "60", "countyName": "westchester" },
    { "countyCode": "61", "countyName": "wyoming" },
    { "countyCode": "62", "countyName": "yates" }

];
export const NewHampshireCounties = [
    { "countyCode": "1", "countyName": "Belknap" },
    { "countyCode": "2", "countyName": "Carroll" },
    { "countyCode": "3", "countyName": "Cheshire" },
    { "countyCode": "4", "countyName": "Coos" },
    { "countyCode": "5", "countyName": "Grafton" },
    { "countyCode": "6", "countyName": "Hillsborough" },
    { "countyCode": "7", "countyName": "Merrimack" },
    { "countyCode": "8", "countyName": "Rockingham" },
    { "countyCode": "9", "countyName": "Strafford" },
    { "countyCode": "10", "countyName": "Sullivan" },
];
export const NewJerseyCounties = [
    { "countyCode": "1", "countyName": "Atlantic" },
    { "countyCode": "2", "countyName": "Bergen" },
    { "countyCode": "3", "countyName": "Burlington" },
    { "countyCode": "4", "countyName": "Camden" },
    { "countyCode": "5", "countyName": "Cape May" },
    { "countyCode": "6", "countyName": "Cumberland" },
    { "countyCode": "7", "countyName": "Essex" },
    { "countyCode": "8", "countyName": "Gloucester" },
    { "countyCode": "9", "countyName": "Hudson" },
    { "countyCode": "10", "countyName": "Hunterdon" },
    { "countyCode": "11", "countyName": "Mercer" },
    { "countyCode": "12", "countyName": "Middlesex" },
    { "countyCode": "13", "countyName": "Monmouth" },
    { "countyCode": "14", "countyName": "Morris" },
    { "countyCode": "15", "countyName": "Ocean" },
    { "countyCode": "16", "countyName": "Passaic" },
    { "countyCode": "17", "countyName": "Salem" },
    { "countyCode": "18", "countyName": "Somerset" },
    { "countyCode": "19", "countyName": "Sussex" },
    { "countyCode": "20", "countyName": "Union" },
    { "countyCode": "21", "countyName": "Warren" },
];
export const NewMexicoCounties = [
    { "countyCode": "1", "countyName": "Bernalillo" },
    { "countyCode": "2", "countyName": "Catron" },
    { "countyCode": "3", "countyName": "Chaves" },
    { "countyCode": "4", "countyName": "Cibola" },
    { "countyCode": "5", "countyName": "Colfax" },
    { "countyCode": "6", "countyName": "Curry" },
    { "countyCode": "7", "countyName": "De Baca" },
    { "countyCode": "8", "countyName": "Dona Ana" },
    { "countyCode": "9", "countyName": "Eddy" },
    { "countyCode": "10", "countyName": "Grant" },
    { "countyCode": "11", "countyName": "Guadalupe" },
    { "countyCode": "12", "countyName": "Harding" },
    { "countyCode": "13", "countyName": "Hidalgo" },
    { "countyCode": "14", "countyName": "Lea" },
    { "countyCode": "15", "countyName": "Lincoln" },
    { "countyCode": "16", "countyName": "Los Alamos" },
    { "countyCode": "17", "countyName": "Luna" },
    { "countyCode": "18", "countyName": "McKinley" },
    { "countyCode": "19", "countyName": "Mora" },
    { "countyCode": "20", "countyName": "Otero" },
    { "countyCode": "21", "countyName": "Quay" },
    { "countyCode": "22", "countyName": "Rio Arriba" },
    { "countyCode": "23", "countyName": "Roosevelt" },
    { "countyCode": "24", "countyName": "Sandoval" },
    { "countyCode": "25", "countyName": "San Juan" },
    { "countyCode": "26", "countyName": "San Miguel" },
    { "countyCode": "27", "countyName": "Santa Fe" },
    { "countyCode": "28", "countyName": "Sierra" },
    { "countyCode": "29", "countyName": "Socorro" },
    { "countyCode": "30", "countyName": "Taos" },
    { "countyCode": "31", "countyName": "Torrance" },
    { "countyCode": "32", "countyName": "Union" },
    { "countyCode": "33", "countyName": "Valencia" },
];
export const NorthCarolinaCounties = [
    { "countyCode": "1", "countyName": "Alamance" },
    { "countyCode": "2", "countyName": "Alexander" },
    { "countyCode": "3", "countyName": "Alleghany" },
    { "countyCode": "4", "countyName": "Anson" },
    { "countyCode": "5", "countyName": "Ashe" },
    { "countyCode": "6", "countyName": "Avery" },
    { "countyCode": "7", "countyName": "Beaufort" },
    { "countyCode": "8", "countyName": "Bertie" },
    { "countyCode": "9", "countyName": "Bladen" },
    { "countyCode": "10", "countyName": "Brunswick" },
    { "countyCode": "11", "countyName": "Buncombe" },
    { "countyCode": "12", "countyName": "Burke" },
    { "countyCode": "13", "countyName": "Cabarrus" },
    { "countyCode": "14", "countyName": "Caldwell" },
    { "countyCode": "15", "countyName": "Camden" },
    { "countyCode": "16", "countyName": "Carteret" },
    { "countyCode": "17", "countyName": "Caswell" },
    { "countyCode": "18", "countyName": "Catawba" },
    { "countyCode": "19", "countyName": "Chatham" },
    { "countyCode": "20", "countyName": "Cherokee" },
    { "countyCode": "21", "countyName": "Chowan" },
    { "countyCode": "22", "countyName": "Clay" },
    { "countyCode": "23", "countyName": "Cleveland" },
    { "countyCode": "24", "countyName": "Columbus" },
    { "countyCode": "25", "countyName": "Craven" },
    { "countyCode": "26", "countyName": "Cumberland" },
    { "countyCode": "27", "countyName": "Currituck" },
    { "countyCode": "28", "countyName": "Dare" },
    { "countyCode": "29", "countyName": "Davidson" },
    { "countyCode": "30", "countyName": "Davie" },
    { "countyCode": "31", "countyName": "Duplin" },
    { "countyCode": "32", "countyName": "Durham" },
    { "countyCode": "33", "countyName": "Edgecombe" },
    { "countyCode": "34", "countyName": "Forsyth" },
    { "countyCode": "35", "countyName": "Franklin" },
    { "countyCode": "36", "countyName": "Gaston" },
    { "countyCode": "37", "countyName": "Gates" },
    { "countyCode": "38", "countyName": "Graham" },
    { "countyCode": "39", "countyName": "Granville" },
    { "countyCode": "40", "countyName": "Greene" },
    { "countyCode": "41", "countyName": "Guilford" },
    { "countyCode": "42", "countyName": "Halifax" },
    { "countyCode": "43", "countyName": "Harnett" },
    { "countyCode": "44", "countyName": "Haywood" },
    { "countyCode": "45", "countyName": "Henderson" },
    { "countyCode": "46", "countyName": "Hertford" },
    { "countyCode": "47", "countyName": "Hoke" },
    { "countyCode": "48", "countyName": "Hyde" },
    { "countyCode": "49", "countyName": "Iredell" },
    { "countyCode": "50", "countyName": "Jackson" },
    { "countyCode": "51", "countyName": "Johnston" },
    { "countyCode": "52", "countyName": "Jones" },
    { "countyCode": "53", "countyName": "Lee" },
    { "countyCode": "54", "countyName": "Lenoir" },
    { "countyCode": "55", "countyName": "Lincoln" },
    { "countyCode": "56", "countyName": "McDowell" },
    { "countyCode": "57", "countyName": "Macon" },
    { "countyCode": "58", "countyName": "Madison" },
    { "countyCode": "59", "countyName": "Martin" },
    { "countyCode": "60", "countyName": "Mecklenburg" },
    { "countyCode": "61", "countyName": "Mitchell" },
    { "countyCode": "62", "countyName": "Montgomery" },
    { "countyCode": "63", "countyName": "Moore" },
    { "countyCode": "64", "countyName": "Nash" },
    { "countyCode": "65", "countyName": "New Hanover" },
    { "countyCode": "66", "countyName": "Northampton" },
    { "countyCode": "67", "countyName": "Onslow" },
    { "countyCode": "68", "countyName": "Orange" },
    { "countyCode": "69", "countyName": "Pamlico" },
    { "countyCode": "70", "countyName": "Pasquotank" },
    { "countyCode": "71", "countyName": "Pender" },
    { "countyCode": "72", "countyName": "Perquimans" },
    { "countyCode": "73", "countyName": "Person" },
    { "countyCode": "74", "countyName": "Pitt" },
    { "countyCode": "75", "countyName": "Polk" },
    { "countyCode": "76", "countyName": "Randolph" },
    { "countyCode": "77", "countyName": "Richmond" },
    { "countyCode": "78", "countyName": "Robeson" },
    { "countyCode": "79", "countyName": "Rockingham" },
    { "countyCode": "80", "countyName": "Rowan" },
    { "countyCode": "81", "countyName": "Rutherford" },
    { "countyCode": "82", "countyName": "Sampson" },
    { "countyCode": "83", "countyName": "Scotland" },
    { "countyCode": "84", "countyName": "Stanly" },
    { "countyCode": "85", "countyName": "Stokes" },
    { "countyCode": "86", "countyName": "Surry" },
    { "countyCode": "87", "countyName": "Swain" },
    { "countyCode": "88", "countyName": "Transylvania" },
    { "countyCode": "89", "countyName": "Tyrrell" },
    { "countyCode": "90", "countyName": "Union" },
    { "countyCode": "91", "countyName": "Vance" },
    { "countyCode": "92", "countyName": "Wake" },
    { "countyCode": "93", "countyName": "Warren" },
    { "countyCode": "94", "countyName": "Washington" },
    { "countyCode": "95", "countyName": "Watauga" },
    { "countyCode": "96", "countyName": "Wayne" },
    { "countyCode": "97", "countyName": "Wilkes" },
    { "countyCode": "98", "countyName": "Wilson" },
    { "countyCode": "99", "countyName": "Yadkin" },
    { "countyCode": "100", "countyName": "Yancey" },
];
export const NorthDakotaCounties = [
    { "countyCode": "1", "countyName": "Adams" },
    { "countyCode": "2", "countyName": "Barnes" },
    { "countyCode": "3", "countyName": "Benson" },
    { "countyCode": "4", "countyName": "Billings" },
    { "countyCode": "5", "countyName": "Bottineau" },
    { "countyCode": "6", "countyName": "Bowman" },
    { "countyCode": "7", "countyName": "Burke" },
    { "countyCode": "8", "countyName": "Burleigh" },
    { "countyCode": "9", "countyName": "Cass" },
    { "countyCode": "10", "countyName": "Cavalier" },
    { "countyCode": "11", "countyName": "Dickey" },
    { "countyCode": "12", "countyName": "Divide" },
    { "countyCode": "13", "countyName": "Dunn" },
    { "countyCode": "14", "countyName": "Eddy" },
    { "countyCode": "15", "countyName": "Emmons" },
    { "countyCode": "16", "countyName": "Foster" },
    { "countyCode": "17", "countyName": "Golden Valley" },
    { "countyCode": "18", "countyName": "Grand Forks" },
    { "countyCode": "19", "countyName": "Grant" },
    { "countyCode": "20", "countyName": "Griggs" },
    { "countyCode": "21", "countyName": "Hettinger" },
    { "countyCode": "22", "countyName": "Kidder" },
    { "countyCode": "23", "countyName": "LaMoure" },
    { "countyCode": "24", "countyName": "Logan" },
    { "countyCode": "25", "countyName": "McHenry" },
    { "countyCode": "26", "countyName": "McIntosh" },
    { "countyCode": "27", "countyName": "McKenzie" },
    { "countyCode": "28", "countyName": "McLean" },
    { "countyCode": "29", "countyName": "Mercer" },
    { "countyCode": "30", "countyName": "Morton" },
    { "countyCode": "31", "countyName": "Mountrail" },
    { "countyCode": "32", "countyName": "Nelson" },
    { "countyCode": "33", "countyName": "Oliver" },
    { "countyCode": "34", "countyName": "Pembina" },
    { "countyCode": "35", "countyName": "Pierce" },
    { "countyCode": "36", "countyName": "Ramsey" },
    { "countyCode": "37", "countyName": "Ransom" },
    { "countyCode": "38", "countyName": "Renville" },
    { "countyCode": "39", "countyName": "Richland" },
    { "countyCode": "40", "countyName": "Rolette" },
    { "countyCode": "41", "countyName": "Sargent" },
    { "countyCode": "42", "countyName": "Sheridan" },
    { "countyCode": "43", "countyName": "Sioux" },
    { "countyCode": "44", "countyName": "Slope" },
    { "countyCode": "45", "countyName": "Stark" },
    { "countyCode": "46", "countyName": "Steele" },
    { "countyCode": "47", "countyName": "Stutsman" },
    { "countyCode": "48", "countyName": "Towner" },
    { "countyCode": "49", "countyName": "Traill" },
    { "countyCode": "50", "countyName": "Walsh" },
    { "countyCode": "51", "countyName": "Ward" },
    { "countyCode": "52", "countyName": "Wells" },
    { "countyCode": "53", "countyName": "Williams" },
];
export const OhioCounties = [
    { "countyCode": "1", "countyName": "Adams" },
    { "countyCode": "2", "countyName": "Allen" },
    { "countyCode": "3", "countyName": "Ashland" },
    { "countyCode": "4", "countyName": "Ashtabula" },
    { "countyCode": "5", "countyName": "Athens" },
    { "countyCode": "6", "countyName": "Auglaize" },
    { "countyCode": "7", "countyName": "Belmont" },
    { "countyCode": "8", "countyName": "Brown" },
    { "countyCode": "9", "countyName": "Butler" },
    { "countyCode": "10", "countyName": "Carroll" },
    { "countyCode": "11", "countyName": "Champaign" },
    { "countyCode": "12", "countyName": "Clark" },
    { "countyCode": "13", "countyName": "Clermont" },
    { "countyCode": "14", "countyName": "Clinton" },
    { "countyCode": "15", "countyName": "Columbiana" },
    { "countyCode": "16", "countyName": "Coshocton" },
    { "countyCode": "17", "countyName": "Crawford" },
    { "countyCode": "18", "countyName": "Cuyahoga" },
    { "countyCode": "19", "countyName": "Darke" },
    { "countyCode": "20", "countyName": "Defiance" },
    { "countyCode": "21", "countyName": "Delaware" },
    { "countyCode": "22", "countyName": "Erie" },
    { "countyCode": "23", "countyName": "Fairfield" },
    { "countyCode": "24", "countyName": "Fayette" },
    { "countyCode": "25", "countyName": "Franklin" },
    { "countyCode": "26", "countyName": "Fulton" },
    { "countyCode": "27", "countyName": "Gallia" },
    { "countyCode": "28", "countyName": "Geauga" },
    { "countyCode": "29", "countyName": "Greene" },
    { "countyCode": "30", "countyName": "Guernsey" },
    { "countyCode": "31", "countyName": "Hamilton" },
    { "countyCode": "32", "countyName": "Hancock" },
    { "countyCode": "33", "countyName": "Hardin" },
    { "countyCode": "34", "countyName": "Harrison" },
    { "countyCode": "35", "countyName": "Henry" },
    { "countyCode": "36", "countyName": "Highland" },
    { "countyCode": "37", "countyName": "Hocking" },
    { "countyCode": "38", "countyName": "Holmes" },
    { "countyCode": "39", "countyName": "Huron" },
    { "countyCode": "40", "countyName": "Jackson" },
    { "countyCode": "41", "countyName": "Jefferson" },
    { "countyCode": "42", "countyName": "Knox" },
    { "countyCode": "43", "countyName": "Lake" },
    { "countyCode": "44", "countyName": "Lawrence" },
    { "countyCode": "45", "countyName": "Licking" },
    { "countyCode": "46", "countyName": "Logan" },
    { "countyCode": "47", "countyName": "Lorain" },
    { "countyCode": "48", "countyName": "Lucas" },
    { "countyCode": "49", "countyName": "Madison" },
    { "countyCode": "50", "countyName": "Mahoning" },
    { "countyCode": "51", "countyName": "Marion" },
    { "countyCode": "52", "countyName": "Medina" },
    { "countyCode": "53", "countyName": "Meigs" },
    { "countyCode": "54", "countyName": "Mercer" },
    { "countyCode": "55", "countyName": "Miami" },
    { "countyCode": "56", "countyName": "Monroe" },
    { "countyCode": "57", "countyName": "Montgomery" },
    { "countyCode": "58", "countyName": "Morgan" },
    { "countyCode": "59", "countyName": "Morrow" },
    { "countyCode": "60", "countyName": "Muskingum" },
    { "countyCode": "61", "countyName": "Noble" },
    { "countyCode": "62", "countyName": "Ottawa" },
    { "countyCode": "63", "countyName": "Paulding" },
    { "countyCode": "64", "countyName": "Perry" },
    { "countyCode": "65", "countyName": "Pickaway" },
    { "countyCode": "66", "countyName": "Pike" },
    { "countyCode": "67", "countyName": "Portage" },
    { "countyCode": "68", "countyName": "Preble" },
    { "countyCode": "69", "countyName": "Putnam" },
    { "countyCode": "70", "countyName": "Richland" },
    { "countyCode": "71", "countyName": "Ross" },
    { "countyCode": "72", "countyName": "Sandusky" },
    { "countyCode": "73", "countyName": "Scioto" },
    { "countyCode": "74", "countyName": "Seneca" },
    { "countyCode": "75", "countyName": "Shelby" },
    { "countyCode": "76", "countyName": "Stark" },
    { "countyCode": "77", "countyName": "Summit" },
    { "countyCode": "78", "countyName": "Trumbull" },
    { "countyCode": "79", "countyName": "Tuscarawas" },
    { "countyCode": "80", "countyName": "Union" },
    { "countyCode": "81", "countyName": "Van Wert" },
    { "countyCode": "82", "countyName": "Vinton" },
    { "countyCode": "83", "countyName": "Warren" },
    { "countyCode": "84", "countyName": "Washington" },
    { "countyCode": "85", "countyName": "Wayne" },
    { "countyCode": "86", "countyName": "Williams" },
    { "countyCode": "87", "countyName": "Wood" },
    { "countyCode": "88", "countyName": "Wyandot" }
];
export const OklahomaCounties = [
    { "countyCode": "1", "countyName": "Adair" },
    { "countyCode": "2", "countyName": "Alfalfa" },
    { "countyCode": "3", "countyName": "Atoka" },
    { "countyCode": "4", "countyName": "Beaver" },
    { "countyCode": "5", "countyName": "Beckham" },
    { "countyCode": "6", "countyName": "Blaine" },
    { "countyCode": "7", "countyName": "Bryan" },
    { "countyCode": "8", "countyName": "Caddo" },
    { "countyCode": "9", "countyName": "Canadian" },
    { "countyCode": "10", "countyName": "Carter" },
    { "countyCode": "11", "countyName": "Cherokee" },
    { "countyCode": "12", "countyName": "Choctaw" },
    { "countyCode": "13", "countyName": "Cimarron" },
    { "countyCode": "14", "countyName": "Cleveland" },
    { "countyCode": "15", "countyName": "Coal" },
    { "countyCode": "16", "countyName": "Comanche" },
    { "countyCode": "17", "countyName": "Cotton" },
    { "countyCode": "18", "countyName": "Craig" },
    { "countyCode": "19", "countyName": "Creek" },
    { "countyCode": "20", "countyName": "Custer" },
    { "countyCode": "21", "countyName": "Delaware" },
    { "countyCode": "22", "countyName": "Dewey" },
    { "countyCode": "23", "countyName": "Ellis" },
    { "countyCode": "24", "countyName": "Garfield" },
    { "countyCode": "25", "countyName": "Garvin" },
    { "countyCode": "26", "countyName": "Grady" },
    { "countyCode": "27", "countyName": "Grant" },
    { "countyCode": "28", "countyName": "Greer" },
    { "countyCode": "29", "countyName": "Harmon" },
    { "countyCode": "30", "countyName": "Harper" },
    { "countyCode": "31", "countyName": "Haskell" },
    { "countyCode": "32", "countyName": "Hughes" },
    { "countyCode": "33", "countyName": "Jackson" },
    { "countyCode": "34", "countyName": "Jefferson" },
    { "countyCode": "35", "countyName": "Johnston" },
    { "countyCode": "36", "countyName": "Kay" },
    { "countyCode": "37", "countyName": "Kingfisher" },
    { "countyCode": "38", "countyName": "Kiowa" },
    { "countyCode": "39", "countyName": "Latimer" },
    { "countyCode": "40", "countyName": "Le Flore" },
    { "countyCode": "41", "countyName": "Lincoln" },
    { "countyCode": "42", "countyName": "Logan" },
    { "countyCode": "43", "countyName": "Love" },
    { "countyCode": "44", "countyName": "McClain" },
    { "countyCode": "45", "countyName": "McCurtain" },
    { "countyCode": "46", "countyName": "McIntosh" },
    { "countyCode": "47", "countyName": "Major" },
    { "countyCode": "48", "countyName": "Marshall" },
    { "countyCode": "49", "countyName": "Mayes" },
    { "countyCode": "50", "countyName": "Murray" },
    { "countyCode": "51", "countyName": "Muskogee" },
    { "countyCode": "52", "countyName": "Noble" },
    { "countyCode": "53", "countyName": "Nowata" },
    { "countyCode": "54", "countyName": "Okfuskee" },
    { "countyCode": "55", "countyName": "Oklahoma" },
    { "countyCode": "56", "countyName": "Okmulgee" },
    { "countyCode": "57", "countyName": "Osage" },
    { "countyCode": "58", "countyName": "Ottawa" },
    { "countyCode": "59", "countyName": "Pawnee" },
    { "countyCode": "60", "countyName": "Payne" },
    { "countyCode": "61", "countyName": "Pittsburg" },
    { "countyCode": "62", "countyName": "Pontotoc" },
    { "countyCode": "63", "countyName": "Pottawatomie" },
    { "countyCode": "64", "countyName": "Pushmataha" },
    { "countyCode": "65", "countyName": "Roger Mills" },
    { "countyCode": "66", "countyName": "Rogers" },
    { "countyCode": "67", "countyName": "Seminole" },
    { "countyCode": "68", "countyName": "Sequoyah" },
    { "countyCode": "69", "countyName": "Stephens" },
    { "countyCode": "70", "countyName": "Texas" },
    { "countyCode": "71", "countyName": "Tillman" },
    { "countyCode": "72", "countyName": "Tulsa" },
    { "countyCode": "73", "countyName": "Wagoner" },
    { "countyCode": "74", "countyName": "Washington" },
    { "countyCode": "75", "countyName": "Washita" },
    { "countyCode": "76", "countyName": "Woods" },
    { "countyCode": "77", "countyName": "Woodward" }
];
export const OregonCounties = [
    { "countyCode": "1", "countyName": "Baker" },
    { "countyCode": "2", "countyName": "Benton" },
    { "countyCode": "3", "countyName": "Clackamas" },
    { "countyCode": "4", "countyName": "Clatsop" },
    { "countyCode": "5", "countyName": "Columbia" },
    { "countyCode": "6", "countyName": "Coos" },
    { "countyCode": "7", "countyName": "Crook" },
    { "countyCode": "8", "countyName": "Curry" },
    { "countyCode": "9", "countyName": "Deschutes" },
    { "countyCode": "10", "countyName": "Douglas" },
    { "countyCode": "11", "countyName": "Gilliam" },
    { "countyCode": "12", "countyName": "Grant" },
    { "countyCode": "13", "countyName": "Harney" },
    { "countyCode": "14", "countyName": "Hood River" },
    { "countyCode": "15", "countyName": "Jackson" },
    { "countyCode": "16", "countyName": "Jefferson" },
    { "countyCode": "17", "countyName": "Josephine" },
    { "countyCode": "18", "countyName": "Klamath" },
    { "countyCode": "19", "countyName": "Lake" },
    { "countyCode": "20", "countyName": "Lane" },
    { "countyCode": "21", "countyName": "Lincoln" },
    { "countyCode": "22", "countyName": "Linn" },
    { "countyCode": "23", "countyName": "Malheur" },
    { "countyCode": "24", "countyName": "Marion" },
    { "countyCode": "25", "countyName": "Morrow" },
    { "countyCode": "26", "countyName": "Multnomah" },
    { "countyCode": "27", "countyName": "Polk" },
    { "countyCode": "28", "countyName": "Sherman" },
    { "countyCode": "29", "countyName": "Tillamook" },
    { "countyCode": "30", "countyName": "Umatilla" },
    { "countyCode": "31", "countyName": "Union" },
    { "countyCode": "32", "countyName": "Wallowa" },
    { "countyCode": "33", "countyName": "Wasco" },
    { "countyCode": "34", "countyName": "Washington" },
    { "countyCode": "35", "countyName": "Wheeler" },
    { "countyCode": "36", "countyName": "Yamhill" }
];
export const PennsylvaniaCounties = [
    { "countyCode": "1", "countyName": "Adams" },
    { "countyCode": "2", "countyName": "Allegheny" },
    { "countyCode": "3", "countyName": "Armstrong" },
    { "countyCode": "4", "countyName": "Beaver" },
    { "countyCode": "5", "countyName": "Bedford" },
    { "countyCode": "6", "countyName": "Berks" },
    { "countyCode": "7", "countyName": "Blair" },
    { "countyCode": "8", "countyName": "Bradford" },
    { "countyCode": "9", "countyName": "Bucks" },
    { "countyCode": "10", "countyName": "Butler" },
    { "countyCode": "11", "countyName": "Cambria" },
    { "countyCode": "12", "countyName": "Cameron" },
    { "countyCode": "13", "countyName": "Carbon" },
    { "countyCode": "14", "countyName": "Centre" },
    { "countyCode": "15", "countyName": "Chester" },
    { "countyCode": "16", "countyName": "Clarion" },
    { "countyCode": "17", "countyName": "Clearfield" },
    { "countyCode": "18", "countyName": "Clinton" },
    { "countyCode": "19", "countyName": "Columbia" },
    { "countyCode": "20", "countyName": "Crawford" },
    { "countyCode": "21", "countyName": "Cumberland" },
    { "countyCode": "22", "countyName": "Dauphin" },
    { "countyCode": "23", "countyName": "Delaware" },
    { "countyCode": "24", "countyName": "Elk" },
    { "countyCode": "25", "countyName": "Erie" },
    { "countyCode": "26", "countyName": "Fayette" },
    { "countyCode": "27", "countyName": "Forest" },
    { "countyCode": "28", "countyName": "Franklin" },
    { "countyCode": "29", "countyName": "Fulton" },
    { "countyCode": "30", "countyName": "Greene" },
    { "countyCode": "31", "countyName": "Huntingdon" },
    { "countyCode": "32", "countyName": "Indiana" },
    { "countyCode": "33", "countyName": "Jefferson" },
    { "countyCode": "34", "countyName": "Juniata" },
    { "countyCode": "35", "countyName": "Lackawanna" },
    { "countyCode": "36", "countyName": "Lancaster" },
    { "countyCode": "37", "countyName": "Lawrence" },
    { "countyCode": "38", "countyName": "Lebanon" },
    { "countyCode": "39", "countyName": "Lehigh" },
    { "countyCode": "40", "countyName": "Luzerne" },
    { "countyCode": "41", "countyName": "Lycoming" },
    { "countyCode": "42", "countyName": "McKean" },
    { "countyCode": "43", "countyName": "Mercer" },
    { "countyCode": "44", "countyName": "Mifflin" },
    { "countyCode": "45", "countyName": "Monroe" },
    { "countyCode": "46", "countyName": "Montgomery" },
    { "countyCode": "47", "countyName": "Montour" },
    { "countyCode": "48", "countyName": "Northampton" },
    { "countyCode": "49", "countyName": "Northumberland" },
    { "countyCode": "50", "countyName": "Perry" },
    { "countyCode": "51", "countyName": "Philadelphia" },
    { "countyCode": "52", "countyName": "Pike" },
    { "countyCode": "53", "countyName": "Potter" },
    { "countyCode": "54", "countyName": "Schuylkill" },
    { "countyCode": "55", "countyName": "Snyder" },
    { "countyCode": "56", "countyName": "Somerset" },
    { "countyCode": "57", "countyName": "Sullivan" },
    { "countyCode": "58", "countyName": "Susquehanna" },
    { "countyCode": "59", "countyName": "Tioga" },
    { "countyCode": "60", "countyName": "Union" },
    { "countyCode": "61", "countyName": "Venango" },
    { "countyCode": "62", "countyName": "Warren" },
    { "countyCode": "63", "countyName": "Washington" },
    { "countyCode": "64", "countyName": "Wayne" },
    { "countyCode": "65", "countyName": "Westmoreland" },
    { "countyCode": "66", "countyName": "Wyoming" },
    { "countyCode": "67", "countyName": "York" }
];
export const PuertoRicoCounties = [
    { "countyCode": "1", "countyName": "Adjuntas" },
    { "countyCode": "2", "countyName": "Aguada" },
    { "countyCode": "3", "countyName": "Aguadilla" },
    { "countyCode": "4", "countyName": "Aguas Buenas" },
    { "countyCode": "5", "countyName": "Aibonito" },
    { "countyCode": "6", "countyName": "Añasco" },
    { "countyCode": "7", "countyName": "Arecibo" },
    { "countyCode": "8", "countyName": "Arroyo" },
    { "countyCode": "9", "countyName": "Barceloneta" },
    { "countyCode": "10", "countyName": "Barranquitas" },
    { "countyCode": "11", "countyName": "Bayamón" },
    { "countyCode": "12", "countyName": "Cabo Rojo" },
    { "countyCode": "13", "countyName": "Caguas" },
    { "countyCode": "14", "countyName": "Camuy" },
    { "countyCode": "15", "countyName": "Canóvanas" },
    { "countyCode": "16", "countyName": "Carolina" },
    { "countyCode": "17", "countyName": "Cataño" },
    { "countyCode": "18", "countyName": "Cayey" },
    { "countyCode": "19", "countyName": "Ceiba" },
    { "countyCode": "20", "countyName": "Ciales" },
    { "countyCode": "21", "countyName": "Cidra" },
    { "countyCode": "22", "countyName": "Coamo" },
    { "countyCode": "23", "countyName": "Comerío" },
    { "countyCode": "24", "countyName": "Corozal" },
    { "countyCode": "25", "countyName": "Culebra" },
    { "countyCode": "26", "countyName": "Dorado" },
    { "countyCode": "27", "countyName": "Fajardo" },
    { "countyCode": "28", "countyName": "Florida" },
    { "countyCode": "29", "countyName": "Guanica" },
    { "countyCode": "30", "countyName": "Guayama" },
    { "countyCode": "31", "countyName": "Guayanilla" },
    { "countyCode": "32", "countyName": "Guaynabo" },
    { "countyCode": "33", "countyName": "Gurabo" },
    { "countyCode": "34", "countyName": "Hatillo" },
    { "countyCode": "35", "countyName": "Hormigueros" },
    { "countyCode": "36", "countyName": "Humacao" },
    { "countyCode": "37", "countyName": "Isabela" },
    { "countyCode": "38", "countyName": "Jayuya" },
    { "countyCode": "39", "countyName": "Juana Díaz" },
    { "countyCode": "40", "countyName": "Juncos" },
    { "countyCode": "41", "countyName": "Lajas" },
    { "countyCode": "42", "countyName": "Lares" },
    { "countyCode": "43", "countyName": "Las Marías" },
    { "countyCode": "44", "countyName": "Las Piedras" },
    { "countyCode": "45", "countyName": "Loiza" },
    { "countyCode": "46", "countyName": "Luquillo" },
    { "countyCode": "47", "countyName": "Manatí" },
    { "countyCode": "48", "countyName": "Maricao" },
    { "countyCode": "49", "countyName": "Maunabo" },
    { "countyCode": "50", "countyName": "Mayagüez" },
    { "countyCode": "51", "countyName": "Moca" },
    { "countyCode": "52", "countyName": "Morovis" },
    { "countyCode": "53", "countyName": "Naguabo" },
    { "countyCode": "54", "countyName": "Naranjito" },
    { "countyCode": "55", "countyName": "Orocovis" },
    { "countyCode": "56", "countyName": "Patillas" },
    { "countyCode": "57", "countyName": "Peñuelas" },
    { "countyCode": "58", "countyName": "Ponce" },
    { "countyCode": "59", "countyName": "Quebradillas" },
    { "countyCode": "60", "countyName": "Rincón" },
    { "countyCode": "61", "countyName": "Río Grande" },
    { "countyCode": "62", "countyName": "Sabana Grande" },
    { "countyCode": "63", "countyName": "Salinas" },
    { "countyCode": "64", "countyName": "San Germán" },
    { "countyCode": "65", "countyName": "San Juan" },
    { "countyCode": "66", "countyName": "San Lorenzo" },
    { "countyCode": "67", "countyName": "San Sebastián" },
    { "countyCode": "68", "countyName": "Santa Isabel" },
    { "countyCode": "69", "countyName": "Toa Alta" },
    { "countyCode": "70", "countyName": "Toa Baja" },
    { "countyCode": "71", "countyName": "Trujillo Alto" },
    { "countyCode": "72", "countyName": "Utuado" },
    { "countyCode": "73", "countyName": "Vega Alta" },
    { "countyCode": "74", "countyName": "Vega Baja" },
    { "countyCode": "75", "countyName": "Vieques" },
    { "countyCode": "76", "countyName": "Villalba" },
    { "countyCode": "77", "countyName": "Yabucoa" },
    { "countyCode": "78", "countyName": "Yauco" }
];
export const RhodeIslandCounties = [
    { "countyCode": "1", "countyName": "Bristol County" },
    { "countyCode": "2", "countyName": "Kent County" },
    { "countyCode": "3", "countyName": "Newport County" },
    { "countyCode": "4", "countyName": "Providence County" },
    { "countyCode": "5", "countyName": "Washington County" }
];
export const SouthCarolinaCounties = [
    { "countyCode": "1", "countyName": "Abbeville County" },
    { "countyCode": "2", "countyName": "Aiken County" },
    { "countyCode": "3", "countyName": "Allendale County" },
    { "countyCode": "4", "countyName": "Anderson County" },
    { "countyCode": "5", "countyName": "Bamberg County" },
    { "countyCode": "6", "countyName": "Barnwell County" },
    { "countyCode": "7", "countyName": "Beaufort County" },
    { "countyCode": "8", "countyName": "Berkeley County" },
    { "countyCode": "9", "countyName": "Calhoun County" },
    { "countyCode": "10", "countyName": "Charleston County" },
    { "countyCode": "11", "countyName": "Cherokee County" },
    { "countyCode": "12", "countyName": "Chester County" },
    { "countyCode": "13", "countyName": "Chesterfield County" },
    { "countyCode": "14", "countyName": "Clarendon County" },
    { "countyCode": "15", "countyName": "Colleton County" },
    { "countyCode": "16", "countyName": "Darlington County" },
    { "countyCode": "17", "countyName": "Dillon County" },
    { "countyCode": "18", "countyName": "Dorchester County" },
    { "countyCode": "19", "countyName": "Edgefield County" },
    { "countyCode": "20", "countyName": "Fairfield County" },
    { "countyCode": "21", "countyName": "Florence County" },
    { "countyCode": "22", "countyName": "Georgetown County" },
    { "countyCode": "23", "countyName": "Greenville County" },
    { "countyCode": "24", "countyName": "Greenwood County" },
    { "countyCode": "25", "countyName": "Hampton County" },
    { "countyCode": "26", "countyName": "Horry County" },
    { "countyCode": "27", "countyName": "Jasper County" },
    { "countyCode": "28", "countyName": "Kershaw County" },
    { "countyCode": "29", "countyName": "Lancaster County" },
    { "countyCode": "30", "countyName": "Laurens County" },
    { "countyCode": "31", "countyName": "Lee County" },
    { "countyCode": "32", "countyName": "Lexington County" },
    { "countyCode": "33", "countyName": "Marion County" },
    { "countyCode": "34", "countyName": "Marlboro County" },
    { "countyCode": "35", "countyName": "McCormick County" },
    { "countyCode": "36", "countyName": "Newberry County" },
    { "countyCode": "37", "countyName": "Oconee County" },
    { "countyCode": "38", "countyName": "Orangeburg County" },
    { "countyCode": "39", "countyName": "Pickens County" },
    { "countyCode": "40", "countyName": "Richland County" },
    { "countyCode": "41", "countyName": "Saluda County" },
    { "countyCode": "42", "countyName": "Spartanburg County" },
    { "countyCode": "43", "countyName": "Sumter County" },
    { "countyCode": "44", "countyName": "Union County" },
    { "countyCode": "45", "countyName": "Williamsburg County" },
    { "countyCode": "46", "countyName": "York County" }
];
export const SouthDakotaCounties = [
    { "countyCode": "1", "countyName": "Aurora County" },
    { "countyCode": "2", "countyName": "Beadle County" },
    { "countyCode": "3", "countyName": "Bennett County" },
    { "countyCode": "4", "countyName": "Bon Homme County" },
    { "countyCode": "5", "countyName": "Brookings County" },
    { "countyCode": "6", "countyName": "Brown County" },
    { "countyCode": "7", "countyName": "Brule County" },
    { "countyCode": "8", "countyName": "Buffalo County" },
    { "countyCode": "9", "countyName": "Butte County" },
    { "countyCode": "10", "countyName": "Campbell County" },
    { "countyCode": "11", "countyName": "Charles Mix County" },
    { "countyCode": "12", "countyName": "Clark County" },
    { "countyCode": "13", "countyName": "Clay County" },
    { "countyCode": "14", "countyName": "Codington County" },
    { "countyCode": "15", "countyName": "Corson County" },
    { "countyCode": "16", "countyName": "Custer County" },
    { "countyCode": "17", "countyName": "Davison County" },
    { "countyCode": "18", "countyName": "Day County" },
    { "countyCode": "19", "countyName": "Deuel County" },
    { "countyCode": "20", "countyName": "Dewey County" },
    { "countyCode": "21", "countyName": "Douglas County" },
    { "countyCode": "22", "countyName": "Edmunds County" },
    { "countyCode": "23", "countyName": "Fall River County" },
    { "countyCode": "24", "countyName": "Faulk County" },
    { "countyCode": "25", "countyName": "Grant County" },
    { "countyCode": "26", "countyName": "Gregory County" },
    { "countyCode": "27", "countyName": "Haakon County" },
    { "countyCode": "28", "countyName": "Hamlin County" },
    { "countyCode": "29", "countyName": "Hand County" },
    { "countyCode": "30", "countyName": "Hanson County" },
    { "countyCode": "31", "countyName": "Harding County" },
    { "countyCode": "32", "countyName": "Hughes County" },
    { "countyCode": "33", "countyName": "Hutchinson County" },
    { "countyCode": "34", "countyName": "Hyde County" },
    { "countyCode": "35", "countyName": "Jackson County" },
    { "countyCode": "36", "countyName": "Jerauld County" },
    { "countyCode": "37", "countyName": "Jones County" },
    { "countyCode": "38", "countyName": "Kingsbury County" },
    { "countyCode": "39", "countyName": "Lake County" },
    { "countyCode": "40", "countyName": "Lawrence County" },
    { "countyCode": "41", "countyName": "Lincoln County" },
    { "countyCode": "42", "countyName": "Lyman County" },
    { "countyCode": "43", "countyName": "Marshall County" },
    { "countyCode": "44", "countyName": "McCook County" },
    { "countyCode": "45", "countyName": "McPherson County" },
    { "countyCode": "46", "countyName": "Meade County" },
    { "countyCode": "47", "countyName": "Mellette County" },
    { "countyCode": "48", "countyName": "Miner County" },
    { "countyCode": "49", "countyName": "Minnehaha County" },
    { "countyCode": "50", "countyName": "Moody County" },
    { "countyCode": "51", "countyName": "Oglala Lakota County" },
    { "countyCode": "52", "countyName": "Pennington County" },
    { "countyCode": "53", "countyName": "Perkins County" },
    { "countyCode": "54", "countyName": "Potter County" },
    { "countyCode": "55", "countyName": "Roberts County" },
    { "countyCode": "56", "countyName": "Sanborn County" },
    { "countyCode": "57", "countyName": "Spink County" },
    { "countyCode": "58", "countyName": "Stanley County" },
    { "countyCode": "59", "countyName": "Sully County" },
    { "countyCode": "60", "countyName": "Todd County" },
    { "countyCode": "61", "countyName": "Tripp County" },
    { "countyCode": "62", "countyName": "Turner County" },
    { "countyCode": "63", "countyName": "Union County" },
    { "countyCode": "64", "countyName": "Walworth County" },
    { "countyCode": "65", "countyName": "Yankton County" },
    { "countyCode": "66", "countyName": "Ziebach County" }
];
export const TennesseeCounties = [
    { "countyCode": "1", "countyName": "anderson" },
    { "countyCode": "2", "countyName": "bedford" },
    { "countyCode": "3", "countyName": "benton" },
    { "countyCode": "4", "countyName": "bledsoe" },
    { "countyCode": "5", "countyName": "blount" },
    { "countyCode": "6", "countyName": "bradley" },
    { "countyCode": "7", "countyName": "campbell" },
    { "countyCode": "8", "countyName": "cannon" },
    { "countyCode": "9", "countyName": "carroll" },
    { "countyCode": "10", "countyName": "carter" },
    { "countyCode": "11", "countyName": "cheatham" },
    { "countyCode": "12", "countyName": "chester" },
    { "countyCode": "13", "countyName": "claiborne" },
    { "countyCode": "14", "countyName": "clay" },
    { "countyCode": "15", "countyName": "cocke" },
    { "countyCode": "16", "countyName": "coffee" },
    { "countyCode": "17", "countyName": "crockett" },
    { "countyCode": "18", "countyName": "cumberland" },
    { "countyCode": "19", "countyName": "davidson" },
    { "countyCode": "20", "countyName": "decatur" },
    { "countyCode": "21", "countyName": "dekalb" },
    { "countyCode": "22", "countyName": "dickson" },
    { "countyCode": "23", "countyName": "dyer" },
    { "countyCode": "24", "countyName": "fayette" },
    { "countyCode": "25", "countyName": "fentress" },
    { "countyCode": "26", "countyName": "franklin" },
    { "countyCode": "27", "countyName": "gibson" },
    { "countyCode": "28", "countyName": "giles" },
    { "countyCode": "29", "countyName": "grainger" },
    { "countyCode": "30", "countyName": "greene" },
    { "countyCode": "31", "countyName": "grundy" },
    { "countyCode": "32", "countyName": "hamblen" },
    { "countyCode": "33", "countyName": "hamilton" },
    { "countyCode": "34", "countyName": "hancock" },
    { "countyCode": "35", "countyName": "hardeman" },
    { "countyCode": "36", "countyName": "hardin" },
    { "countyCode": "37", "countyName": "hawkins" },
    { "countyCode": "38", "countyName": "haywood" },
    { "countyCode": "39", "countyName": "henderson" },
    { "countyCode": "40", "countyName": "henry" },
    { "countyCode": "41", "countyName": "hickman" },
    { "countyCode": "42", "countyName": "houston" },
    { "countyCode": "43", "countyName": "humphreys" },
    { "countyCode": "44", "countyName": "jackson" },
    { "countyCode": "45", "countyName": "jefferson" },
    { "countyCode": "46", "countyName": "johnson" },
    { "countyCode": "47", "countyName": "knox" },
    { "countyCode": "48", "countyName": "lake" },
    { "countyCode": "49", "countyName": "lauderdale" },
    { "countyCode": "50", "countyName": "lawrence" },
    { "countyCode": "51", "countyName": "lewis" },
    { "countyCode": "52", "countyName": "lincoln" },
    { "countyCode": "53", "countyName": "loudon" },
    { "countyCode": "54", "countyName": "mcminn" },
    { "countyCode": "55", "countyName": "mcnairy" },
    { "countyCode": "56", "countyName": "macon" },
    { "countyCode": "57", "countyName": "madison" },
    { "countyCode": "58", "countyName": "marion" },
    { "countyCode": "59", "countyName": "marshall" },
    { "countyCode": "60", "countyName": "maury" },
    { "countyCode": "61", "countyName": "meigs" },
    { "countyCode": "62", "countyName": "monroe" },
    { "countyCode": "63", "countyName": "montgomery" },
    { "countyCode": "64", "countyName": "moore" },
    { "countyCode": "65", "countyName": "morgan" },
    { "countyCode": "66", "countyName": "obion" },
    { "countyCode": "67", "countyName": "overton" },
    { "countyCode": "68", "countyName": "perry" },
    { "countyCode": "69", "countyName": "pickett" },
    { "countyCode": "70", "countyName": "polk" },
    { "countyCode": "71", "countyName": "putnam" },
    { "countyCode": "72", "countyName": "rhea" },
    { "countyCode": "73", "countyName": "roane" },
    { "countyCode": "74", "countyName": "robertson" },
    { "countyCode": "75", "countyName": "rutherford" },
    { "countyCode": "76", "countyName": "scott" },
    { "countyCode": "77", "countyName": "sequatchie" },
    { "countyCode": "78", "countyName": "sevier" },
    { "countyCode": "79", "countyName": "shelby" },
    { "countyCode": "80", "countyName": "smith" },
    { "countyCode": "81", "countyName": "stewart" },
    { "countyCode": "82", "countyName": "sullivan" },
    { "countyCode": "83", "countyName": "sumner" },
    { "countyCode": "84", "countyName": "tipton" },
    { "countyCode": "85", "countyName": "trousdale" },
    { "countyCode": "86", "countyName": "unicoi" },
    { "countyCode": "87", "countyName": "union" },
    { "countyCode": "88", "countyName": "van buren" },
    { "countyCode": "89", "countyName": "warren" },
    { "countyCode": "90", "countyName": "washington" },
    { "countyCode": "91", "countyName": "wayne" },
    { "countyCode": "92", "countyName": "weakley" },
    { "countyCode": "93", "countyName": "white" },
    { "countyCode": "94", "countyName": "williamson" },
    { "countyCode": "95", "countyName": "wilson" },
];
export const TexasCounties = [
    { "countyCode": "1", "countyName": "Anderson County" },
    { "countyCode": "2", "countyName": "Andrews County" },
    { "countyCode": "3", "countyName": "Angelina County" },
    { "countyCode": "4", "countyName": "Aransas County" },
    { "countyCode": "5", "countyName": "Archer County" },
    { "countyCode": "6", "countyName": "Armstrong County" },
    { "countyCode": "7", "countyName": "Atascosa County" },
    { "countyCode": "8", "countyName": "Austin County" },
    { "countyCode": "9", "countyName": "Bailey County" },
    { "countyCode": "10", "countyName": "Bandera County" },
    { "countyCode": "11", "countyName": "Bastrop County" },
    { "countyCode": "12", "countyName": "Baylor County" },
    { "countyCode": "13", "countyName": "Bee County" },
    { "countyCode": "14", "countyName": "Bell County" },
    { "countyCode": "15", "countyName": "Bexar County" },
    { "countyCode": "16", "countyName": "Blanco County" },
    { "countyCode": "17", "countyName": "Borden County" },
    { "countyCode": "18", "countyName": "Bosque County" },
    { "countyCode": "19", "countyName": "Bowie County" },
    { "countyCode": "20", "countyName": "Brazoria County" },
    { "countyCode": "21", "countyName": "Brazos County" },
    { "countyCode": "22", "countyName": "Brewster County" },
    { "countyCode": "23", "countyName": "Briscoe County" },
    { "countyCode": "24", "countyName": "Brooks County" },
    { "countyCode": "25", "countyName": "Brown County" },
    { "countyCode": "26", "countyName": "Burleson County" },
    { "countyCode": "27", "countyName": "Burnet County" },
    { "countyCode": "28", "countyName": "Caldwell County" },
    { "countyCode": "29", "countyName": "Calhoun County" },
    { "countyCode": "30", "countyName": "Callahan County" },
    { "countyCode": "31", "countyName": "Cameron County" },
    { "countyCode": "32", "countyName": "Camp County" },
    { "countyCode": "33", "countyName": "Carson County" },
    { "countyCode": "34", "countyName": "Cass County" },
    { "countyCode": "35", "countyName": "Castro County" },
    { "countyCode": "36", "countyName": "Chambers County" },
    { "countyCode": "37", "countyName": "Cherokee County" },
    { "countyCode": "38", "countyName": "Childress County" },
    { "countyCode": "39", "countyName": "Clay County" },
    { "countyCode": "40", "countyName": "Cochran County" },
    { "countyCode": "41", "countyName": "Coke County" },
    { "countyCode": "42", "countyName": "Coleman County" },
    { "countyCode": "43", "countyName": "Collin County" },
    { "countyCode": "44", "countyName": "Collingsworth County" },
    { "countyCode": "45", "countyName": "Colorado County" },
    { "countyCode": "46", "countyName": "Comal County" },
    { "countyCode": "47", "countyName": "Comanche County" },
    { "countyCode": "48", "countyName": "Concho County" },
    { "countyCode": "49", "countyName": "Cooke County" },
    { "countyCode": "50", "countyName": "Coryell County" },
    { "countyCode": "51", "countyName": "Cottle County" },
    { "countyCode": "52", "countyName": "Crane County" },
    { "countyCode": "53", "countyName": "Crockett County" },
    { "countyCode": "54", "countyName": "Crosby County" },
    { "countyCode": "55", "countyName": "Culberson County" },
    { "countyCode": "56", "countyName": "Dallam County" },
    { "countyCode": "57", "countyName": "Dallas County" },
    { "countyCode": "58", "countyName": "Dawson County" },
    { "countyCode": "59", "countyName": "Deaf Smith County" },
    { "countyCode": "60", "countyName": "Delta County" },
    { "countyCode": "61", "countyName": "Denton County" },
    { "countyCode": "62", "countyName": "DeWitt County" },
    { "countyCode": "63", "countyName": "Dickens County" },
    { "countyCode": "64", "countyName": "Dimmit County" },
    { "countyCode": "65", "countyName": "Donley County" },
    { "countyCode": "66", "countyName": "Duval County" },
    { "countyCode": "67", "countyName": "Eastland County" },
    { "countyCode": "68", "countyName": "Ector County" },
    { "countyCode": "69", "countyName": "Edwards County" },
    { "countyCode": "70", "countyName": "Ellis County" },
    { "countyCode": "71", "countyName": "El Paso County" },
    { "countyCode": "72", "countyName": "Erath County" },
    { "countyCode": "73", "countyName": "Falls County" },
    { "countyCode": "74", "countyName": "Fannin County" },
    { "countyCode": "75", "countyName": "Fayette County" },
    { "countyCode": "76", "countyName": "Fisher County" },
    { "countyCode": "77", "countyName": "Floyd County" },
    { "countyCode": "78", "countyName": "Foard County" },
    { "countyCode": "79", "countyName": "Fort Bend County" },
    { "countyCode": "80", "countyName": "Franklin County" },
    { "countyCode": "81", "countyName": "Freestone County" },
    { "countyCode": "82", "countyName": "Frio County" },
    { "countyCode": "83", "countyName": "Gaines County" },
    { "countyCode": "84", "countyName": "Galveston County" },
    { "countyCode": "85", "countyName": "Garza County" },
    { "countyCode": "86", "countyName": "Gillespie County" },
    { "countyCode": "87", "countyName": "Glasscock County" },
    { "countyCode": "88", "countyName": "Goliad County" },
    { "countyCode": "89", "countyName": "Gonzales County" },
    { "countyCode": "90", "countyName": "Gray County" },
    { "countyCode": "91", "countyName": "Grayson County" },
    { "countyCode": "92", "countyName": "Gregg County" },
    { "countyCode": "93", "countyName": "Grimes County" },
    { "countyCode": "94", "countyName": "Guadalupe County" },
    { "countyCode": "95", "countyName": "Hale County" },
    { "countyCode": "96", "countyName": "Hall County" },
    { "countyCode": "97", "countyName": "Hamilton County" },
    { "countyCode": "98", "countyName": "Hansford County" },
    { "countyCode": "99", "countyName": "Hardeman County" },
    { "countyCode": "100", "countyName": "Hardin County" },
    { "countyCode": "101", "countyName": "Harris County" },
    { "countyCode": "102", "countyName": "Harrison County" },
    { "countyCode": "103", "countyName": "Hartley County" },
    { "countyCode": "104", "countyName": "Haskell County" },
    { "countyCode": "105", "countyName": "Hays County" },
    { "countyCode": "106", "countyName": "Hemphill County" },
    { "countyCode": "107", "countyName": "Henderson County" },
    { "countyCode": "108", "countyName": "Hidalgo County" },
    { "countyCode": "109", "countyName": "Hill County" },
    { "countyCode": "110", "countyName": "Hockley County" },
    { "countyCode": "111", "countyName": "Hood County" },
    { "countyCode": "112", "countyName": "Hopkins County" },
    { "countyCode": "113", "countyName": "Houston County" },
    { "countyCode": "114", "countyName": "Howard County" },
    { "countyCode": "115", "countyName": "Hudspeth County" },
    { "countyCode": "116", "countyName": "Hunt County" },
    { "countyCode": "117", "countyName": "Hutchinson County" },
    { "countyCode": "118", "countyName": "Irion County" },
    { "countyCode": "119", "countyName": "Jack County" },
    { "countyCode": "120", "countyName": "Jackson County" },
    { "countyCode": "121", "countyName": "Jasper County" },
    { "countyCode": "122", "countyName": "Jeff Davis County" },
    { "countyCode": "123", "countyName": "Jefferson County" },
    { "countyCode": "124", "countyName": "Jim Hogg County" },
    { "countyCode": "125", "countyName": "Jim Wells County" },
    { "countyCode": "126", "countyName": "Johnson County" },
    { "countyCode": "127", "countyName": "Jones County" },
    { "countyCode": "128", "countyName": "Karnes County" },
    { "countyCode": "129", "countyName": "Kaufman County" },
    { "countyCode": "130", "countyName": "Kendall County" },
    { "countyCode": "131", "countyName": "Kenedy County" },
    { "countyCode": "132", "countyName": "Kent County" },
    { "countyCode": "133", "countyName": "Kerr County" },
    { "countyCode": "134", "countyName": "Kimble County" },
    { "countyCode": "135", "countyName": "King County" },
    { "countyCode": "136", "countyName": "Kinney County" },
    { "countyCode": "137", "countyName": "Kleberg County" },
    { "countyCode": "138", "countyName": "Knox County" },
    { "countyCode": "139", "countyName": "Lamar County" },
    { "countyCode": "140", "countyName": "Lamb County" },
    { "countyCode": "141", "countyName": "Lampasas County" },
    { "countyCode": "142", "countyName": "La Salle County" },
    { "countyCode": "143", "countyName": "Lavaca County" },
    { "countyCode": "144", "countyName": "Lee County" },
    { "countyCode": "145", "countyName": "Leon County" },
    { "countyCode": "146", "countyName": "Liberty County" },
    { "countyCode": "147", "countyName": "Limestone County" },
    { "countyCode": "148", "countyName": "Lipscomb County" },
    { "countyCode": "149", "countyName": "Live Oak County" },
    { "countyCode": "150", "countyName": "Llano County" },
    { "countyCode": "151", "countyName": "Loving County" },
    { "countyCode": "152", "countyName": "Lubbock County" },
    { "countyCode": "153", "countyName": "Lynn County" },
    { "countyCode": "154", "countyName": "McCulloch County" },
    { "countyCode": "155", "countyName": "McLennan County" },
    { "countyCode": "156", "countyName": "McMullen County" },
    { "countyCode": "157", "countyName": "Madison County" },
    { "countyCode": "158", "countyName": "Marion County" },
    { "countyCode": "159", "countyName": "Martin County" },
    { "countyCode": "160", "countyName": "Mason County" },
    { "countyCode": "161", "countyName": "Matagorda County" },
    { "countyCode": "162", "countyName": "Maverick County" },
    { "countyCode": "163", "countyName": "Medina County" },
    { "countyCode": "164", "countyName": "Menard County" },
    { "countyCode": "165", "countyName": "Midland County" },
    { "countyCode": "166", "countyName": "Milam County" },
    { "countyCode": "167", "countyName": "Mills County" },
    { "countyCode": "168", "countyName": "Mitchell County" },
    { "countyCode": "169", "countyName": "Montague County" },
    { "countyCode": "170", "countyName": "Montgomery County" },
    { "countyCode": "171", "countyName": "Moore County" },
    { "countyCode": "172", "countyName": "Morris County" },
    { "countyCode": "173", "countyName": "Motley County" },
    { "countyCode": "174", "countyName": "Nacogdoches County" },
    { "countyCode": "175", "countyName": "Navarro County" },
    { "countyCode": "176", "countyName": "Newton County" },
    { "countyCode": "177", "countyName": "Nolan County" },
    { "countyCode": "178", "countyName": "Nueces County" },
    { "countyCode": "179", "countyName": "Ochiltree County" },
    { "countyCode": "180", "countyName": "Oldham County" },
    { "countyCode": "181", "countyName": "Orange County" },
    { "countyCode": "182", "countyName": "Palo Pinto County" },
    { "countyCode": "183", "countyName": "Panola County" },
    { "countyCode": "184", "countyName": "Parker County" },
    { "countyCode": "185", "countyName": "Parmer County" },
    { "countyCode": "186", "countyName": "Pecos County" },
    { "countyCode": "187", "countyName": "Polk County" },
    { "countyCode": "188", "countyName": "Potter County" },
    { "countyCode": "189", "countyName": "Presidio County" },
    { "countyCode": "190", "countyName": "Rains County" },
    { "countyCode": "191", "countyName": "Randall County" },
    { "countyCode": "192", "countyName": "Reagan County" },
    { "countyCode": "193", "countyName": "Real County" },
    { "countyCode": "194", "countyName": "Red River County" },
    { "countyCode": "195", "countyName": "Reeves County" },
    { "countyCode": "196", "countyName": "Refugio County" },
    { "countyCode": "197", "countyName": "Roberts County" },
    { "countyCode": "198", "countyName": "Robertson County" },
    { "countyCode": "199", "countyName": "Rockwall County" },
    { "countyCode": "200", "countyName": "Runnels County" },
    { "countyCode": "201", "countyName": "Rusk County" },
    { "countyCode": "202", "countyName": "Sabine County" },
    { "countyCode": "203", "countyName": "San Augustine County" },
    { "countyCode": "204", "countyName": "San Jacinto County" },
    { "countyCode": "205", "countyName": "San Patricio County" },
    { "countyCode": "206", "countyName": "San Saba County" },
    { "countyCode": "207", "countyName": "Schleicher County" },
    { "countyCode": "208", "countyName": "Scurry County" },
    { "countyCode": "209", "countyName": "Shackelford County" },
    { "countyCode": "210", "countyName": "Shelby County" },
    { "countyCode": "211", "countyName": "Sherman County" },
    { "countyCode": "212", "countyName": "Smith County" },
    { "countyCode": "213", "countyName": "Somervell County" },
    { "countyCode": "214", "countyName": "Starr County" },
    { "countyCode": "215", "countyName": "Stephens County" },
    { "countyCode": "216", "countyName": "Sterling County" },
    { "countyCode": "217", "countyName": "Stonewall County" },
    { "countyCode": "218", "countyName": "Sutton County" },
    { "countyCode": "219", "countyName": "Swisher County" },
    { "countyCode": "220", "countyName": "Tarrant County" },
    { "countyCode": "221", "countyName": "Taylor County" },
    { "countyCode": "222", "countyName": "Terrell County" },
    { "countyCode": "223", "countyName": "Terry County" },
    { "countyCode": "224", "countyName": "Throckmorton County" },
    { "countyCode": "225", "countyName": "Titus County" },
    { "countyCode": "226", "countyName": "Tom Green County" },
    { "countyCode": "227", "countyName": "Travis County" },
    { "countyCode": "228", "countyName": "Trinity County" },
    { "countyCode": "229", "countyName": "Tyler County" },
    { "countyCode": "230", "countyName": "Upshur County" },
    { "countyCode": "231", "countyName": "Upton County" },
    { "countyCode": "232", "countyName": "Uvalde County" },
    { "countyCode": "233", "countyName": "Val Verde County" },
    { "countyCode": "234", "countyName": "Van Zandt County" },
    { "countyCode": "235", "countyName": "Victoria County" },
    { "countyCode": "236", "countyName": "Walker County" },
    { "countyCode": "237", "countyName": "Waller County" },
    { "countyCode": "238", "countyName": "Ward County" },
    { "countyCode": "239", "countyName": "Washington County" },
    { "countyCode": "240", "countyName": "Webb County" },
    { "countyCode": "241", "countyName": "Wharton County" },
    { "countyCode": "242", "countyName": "Wheeler County" },
    { "countyCode": "243", "countyName": "Wichita County" },
    { "countyCode": "244", "countyName": "Wilbarger County" },
    { "countyCode": "245", "countyName": "Willacy County" },
    { "countyCode": "246", "countyName": "Williamson County" },
    { "countyCode": "247", "countyName": "Wilson County" },
    { "countyCode": "248", "countyName": "Winkler County" },
    { "countyCode": "249", "countyName": "Wise County" },
    { "countyCode": "250", "countyName": "Wood County" },
    { "countyCode": "251", "countyName": "Yoakum County" },
    { "countyCode": "252", "countyName": "Young County" },
    { "countyCode": "253", "countyName": "Zapata County" },
    { "countyCode": "254", "countyName": "Zavala County" },
];
export const UtahCounties = [
    { "countyCode": "1", "countyName": "Beaver" },
    { "countyCode": "2", "countyName": "Box Elder" },
    { "countyCode": "3", "countyName": "Cache" },
    { "countyCode": "4", "countyName": "Carbon" },
    { "countyCode": "5", "countyName": "Daggett" },
    { "countyCode": "6", "countyName": "Davis" },
    { "countyCode": "7", "countyName": "Duchesne" },
    { "countyCode": "8", "countyName": "Emery" },
    { "countyCode": "9", "countyName": "Garfield" },
    { "countyCode": "10", "countyName": "Grand" },
    { "countyCode": "11", "countyName": "Iron" },
    { "countyCode": "12", "countyName": "Juab" },
    { "countyCode": "13", "countyName": "Kane" },
    { "countyCode": "14", "countyName": "Millard" },
    { "countyCode": "15", "countyName": "Morgan" },
    { "countyCode": "16", "countyName": "Piute" },
    { "countyCode": "17", "countyName": "Rich" },
    { "countyCode": "18", "countyName": "Salt Lake" },
    { "countyCode": "19", "countyName": "San Juan" },
    { "countyCode": "20", "countyName": "Sanpete" },
    { "countyCode": "21", "countyName": "Sevier" },
    { "countyCode": "22", "countyName": "Summit" },
    { "countyCode": "23", "countyName": "Tooele" },
    { "countyCode": "24", "countyName": "Uintah" },
    { "countyCode": "25", "countyName": "Utah" },
    { "countyCode": "26", "countyName": "Wasatch" },
    { "countyCode": "27", "countyName": "Washington" },
    { "countyCode": "28", "countyName": "Wayne" },
    { "countyCode": "29", "countyName": "Weber" },
];
export const VermontCounties = [
    { "countyCode": "1", "countyName": "Addison" },
    { "countyCode": "2", "countyName": "Bennington" },
    { "countyCode": "3", "countyName": "Caledonia" },
    { "countyCode": "4", "countyName": "Chittenden" },
    { "countyCode": "5", "countyName": "Essex" },
    { "countyCode": "6", "countyName": "Franklin" },
    { "countyCode": "7", "countyName": "Grand Isle" },
    { "countyCode": "8", "countyName": "Lamoille" },
    { "countyCode": "9", "countyName": "Orange" },
    { "countyCode": "10", "countyName": "Orleans" },
    { "countyCode": "11", "countyName": "Rutland" },
    { "countyCode": "12", "countyName": "Washington" },
    { "countyCode": "13", "countyName": "Windham" },
    { "countyCode": "14", "countyName": "Windsor" },
];
export const VirginIslandsUSACounties = [
    { "countyCode": "1", "countyName": "Saint Croix" },
    { "countyCode": "2", "countyName": "Saint John" },
    { "countyCode": "3", "countyName": "Saint Thomas" },
];
export const VirginiaCounties = [
    { "countyCode": "1", "countyName": "Accomack" },
    { "countyCode": "2", "countyName": "Albemarle" },
    { "countyCode": "3", "countyName": "Alleghany" },
    { "countyCode": "4", "countyName": "Amelia" },
    { "countyCode": "5", "countyName": "Amherst" },
    { "countyCode": "6", "countyName": "Appomattox" },
    { "countyCode": "7", "countyName": "Arlington" },
    { "countyCode": "8", "countyName": "Augusta" },
    { "countyCode": "9", "countyName": "Bath" },
    { "countyCode": "10", "countyName": "Bedford" },
    { "countyCode": "11", "countyName": "Bland" },
    { "countyCode": "12", "countyName": "Botetourt" },
    { "countyCode": "13", "countyName": "Brunswick" },
    { "countyCode": "14", "countyName": "Buchanan" },
    { "countyCode": "15", "countyName": "Buckingham" },
    { "countyCode": "16", "countyName": "Campbell" },
    { "countyCode": "17", "countyName": "Caroline" },
    { "countyCode": "18", "countyName": "Carroll" },
    { "countyCode": "19", "countyName": "Charles City" },
    { "countyCode": "20", "countyName": "Charlotte" },
    { "countyCode": "21", "countyName": "Chesterfield" },
    { "countyCode": "22", "countyName": "Clarke" },
    { "countyCode": "23", "countyName": "Craig" },
    { "countyCode": "24", "countyName": "Culpeper" },
    { "countyCode": "25", "countyName": "Cumberland" },
    { "countyCode": "26", "countyName": "Dickenson" },
    { "countyCode": "27", "countyName": "Dinwiddie" },
    { "countyCode": "28", "countyName": "Essex" },
    { "countyCode": "29", "countyName": "Fairfax" },
    { "countyCode": "30", "countyName": "Fauquier" },
    { "countyCode": "31", "countyName": "Floyd" },
    { "countyCode": "32", "countyName": "Fluvanna" },
    { "countyCode": "33", "countyName": "Franklin" },
    { "countyCode": "34", "countyName": "Frederick" },
    { "countyCode": "35", "countyName": "Giles" },
    { "countyCode": "36", "countyName": "Gloucester" },
    { "countyCode": "37", "countyName": "Goochland" },
    { "countyCode": "38", "countyName": "Grayson" },
    { "countyCode": "39", "countyName": "Greene" },
    { "countyCode": "40", "countyName": "Greensville" },
    { "countyCode": "41", "countyName": "Halifax" },
    { "countyCode": "42", "countyName": "Hanover" },
    { "countyCode": "43", "countyName": "Henrico" },
    { "countyCode": "44", "countyName": "Henry" },
    { "countyCode": "45", "countyName": "Highland" },
    { "countyCode": "46", "countyName": "Isle of Wight" },
    { "countyCode": "47", "countyName": "James City" },
    { "countyCode": "48", "countyName": "King and Queen" },
    { "countyCode": "49", "countyName": "King George" },
    { "countyCode": "50", "countyName": "King William" },
    { "countyCode": "51", "countyName": "Lancaster" },
    { "countyCode": "52", "countyName": "Lee" },
    { "countyCode": "53", "countyName": "Loudoun" },
    { "countyCode": "54", "countyName": "Louisa" },
    { "countyCode": "55", "countyName": "Lunenburg" },
    { "countyCode": "56", "countyName": "Madison" },
    { "countyCode": "57", "countyName": "Mathews" },
    { "countyCode": "58", "countyName": "Mecklenburg" },
    { "countyCode": "59", "countyName": "Middlesex" },
    { "countyCode": "60", "countyName": "Montgomery" },
    { "countyCode": "61", "countyName": "Nelson" },
    { "countyCode": "62", "countyName": "New Kent" },
    { "countyCode": "63", "countyName": "Northampton" },
    { "countyCode": "64", "countyName": "Northumberland" },
    { "countyCode": "65", "countyName": "Nottoway" },
    { "countyCode": "66", "countyName": "Orange" },
    { "countyCode": "67", "countyName": "Page" },
    { "countyCode": "68", "countyName": "Patrick" },
    { "countyCode": "69", "countyName": "Pittsylvania" },
    { "countyCode": "70", "countyName": "Powhatan" },
    { "countyCode": "71", "countyName": "Prince Edward" },
    { "countyCode": "72", "countyName": "Prince George" },
    { "countyCode": "73", "countyName": "Prince William" },
    { "countyCode": "74", "countyName": "Pulaski" },
    { "countyCode": "75", "countyName": "Rappahannock" },
    { "countyCode": "76", "countyName": "Richmond" },
    { "countyCode": "77", "countyName": "Roanoke" },
    { "countyCode": "78", "countyName": "Rockbridge" },
    { "countyCode": "79", "countyName": "Rockingham" },
    { "countyCode": "80", "countyName": "Russell" },
    { "countyCode": "81", "countyName": "Scott" },
    { "countyCode": "82", "countyName": "Shenandoah" },
    { "countyCode": "83", "countyName": "Smyth" },
    { "countyCode": "84", "countyName": "Southampton" },
    { "countyCode": "85", "countyName": "Spotsylvania" },
    { "countyCode": "86", "countyName": "Stafford" },
    { "countyCode": "87", "countyName": "Surry" },
    { "countyCode": "88", "countyName": "Sussex" },
    { "countyCode": "89", "countyName": "Tazewell" },
    { "countyCode": "90", "countyName": "Warren" },
    { "countyCode": "91", "countyName": "Washington" },
    { "countyCode": "92", "countyName": "Westmoreland" },
    { "countyCode": "93", "countyName": "Wise" },
    { "countyCode": "94", "countyName": "Wythe" },
    { "countyCode": "95", "countyName": "York" },
];
export const WisconsinCounties = [
    { "countyCode": "1", "countyName": "adams" },
    { "countyCode": "2", "countyName": "ashland" },
    { "countyCode": "3", "countyName": "barron" },
    { "countyCode": "4", "countyName": "bayfield" },
    { "countyCode": "5", "countyName": "brown" },
    { "countyCode": "6", "countyName": "buffalo" },
    { "countyCode": "7", "countyName": "burnett" },
    { "countyCode": "8", "countyName": "calumet" },
    { "countyCode": "9", "countyName": "chippewa" },
    { "countyCode": "10", "countyName": "clark" },
    { "countyCode": "11", "countyName": "columbia" },
    { "countyCode": "12", "countyName": "crawford" },
    { "countyCode": "13", "countyName": "dane" },
    { "countyCode": "14", "countyName": "dodge" },
    { "countyCode": "15", "countyName": "door" },
    { "countyCode": "16", "countyName": "douglas" },
    { "countyCode": "17", "countyName": "dunn" },
    { "countyCode": "18", "countyName": "eau claire" },
    { "countyCode": "19", "countyName": "florence" },
    { "countyCode": "20", "countyName": "fond du lac" },
    { "countyCode": "21", "countyName": "forest" },
    { "countyCode": "22", "countyName": "grant" },
    { "countyCode": "23", "countyName": "green" },
    { "countyCode": "24", "countyName": "green lake" },
    { "countyCode": "25", "countyName": "iowa" },
    { "countyCode": "26", "countyName": "iron" },
    { "countyCode": "27", "countyName": "jackson" },
    { "countyCode": "28", "countyName": "jefferson" },
    { "countyCode": "29", "countyName": "juneau" },
    { "countyCode": "30", "countyName": "kenosha" },
    { "countyCode": "31", "countyName": "kewaunee" },
    { "countyCode": "32", "countyName": "la crosse" },
    { "countyCode": "33", "countyName": "lafayette" },
    { "countyCode": "34", "countyName": "langlade" },
    { "countyCode": "35", "countyName": "lincoln" },
    { "countyCode": "36", "countyName": "manitowoc" },
    { "countyCode": "37", "countyName": "marathon" },
    { "countyCode": "38", "countyName": "marinette" },
    { "countyCode": "39", "countyName": "marquette" },
    { "countyCode": "40", "countyName": "menominee" },
    { "countyCode": "41", "countyName": "milwaukee" },
    { "countyCode": "42", "countyName": "monroe" },
    { "countyCode": "43", "countyName": "oconto" },
    { "countyCode": "44", "countyName": "oneida" },
    { "countyCode": "45", "countyName": "outagamie" },
    { "countyCode": "46", "countyName": "ozaukee" },
    { "countyCode": "47", "countyName": "pepin" },
    { "countyCode": "48", "countyName": "pierce" },
    { "countyCode": "49", "countyName": "polk" },
    { "countyCode": "50", "countyName": "portage" },
    { "countyCode": "51", "countyName": "price" },
    { "countyCode": "52", "countyName": "racine" },
    { "countyCode": "53", "countyName": "richland" },
    { "countyCode": "54", "countyName": "rock" },
    { "countyCode": "55", "countyName": "rusk" },
    { "countyCode": "56", "countyName": "st. croix" },
    { "countyCode": "57", "countyName": "sauk" },
    { "countyCode": "58", "countyName": "sawyer" },
    { "countyCode": "59", "countyName": "shawano" },
    { "countyCode": "60", "countyName": "sheboygan" },
    { "countyCode": "61", "countyName": "taylor" },
    { "countyCode": "62", "countyName": "trempealeau" },
    { "countyCode": "63", "countyName": "vernon" },
    { "countyCode": "64", "countyName": "vilas" },
    { "countyCode": "65", "countyName": "walworth" },
    { "countyCode": "66", "countyName": "washburn" },
    { "countyCode": "67", "countyName": "washington" },
    { "countyCode": "68", "countyName": "waukesha" },
    { "countyCode": "69", "countyName": "waupaca" },
    { "countyCode": "70", "countyName": "waushara" },
    { "countyCode": "71", "countyName": "winnebago" },
    { "countyCode": "72", "countyName": "wood" },
];
export const WashingtonCounties = [
    { "countyCode": "1", "countyName": "Adams" },
    { "countyCode": "2", "countyName": "Asotin" },
    { "countyCode": "3", "countyName": "Benton" },
    { "countyCode": "4", "countyName": "Chelan" },
    { "countyCode": "5", "countyName": "Clallam" },
    { "countyCode": "6", "countyName": "Clark" },
    { "countyCode": "7", "countyName": "Columbia" },
    { "countyCode": "8", "countyName": "Cowlitz" },
    { "countyCode": "9", "countyName": "Douglas" },
    { "countyCode": "10", "countyName": "Ferry" },
    { "countyCode": "11", "countyName": "Franklin" },
    { "countyCode": "12", "countyName": "Garfield" },
    { "countyCode": "13", "countyName": "Grant" },
    { "countyCode": "14", "countyName": "Grays Harbor" },
    { "countyCode": "15", "countyName": "Island" },
    { "countyCode": "16", "countyName": "Jefferson" },
    { "countyCode": "17", "countyName": "King" },
    { "countyCode": "18", "countyName": "Kitsap" },
    { "countyCode": "19", "countyName": "Kittitas" },
    { "countyCode": "20", "countyName": "Klickitat" },
    { "countyCode": "21", "countyName": "Lewis" },
    { "countyCode": "22", "countyName": "Lincoln" },
    { "countyCode": "23", "countyName": "Mason" },
    { "countyCode": "24", "countyName": "Okanogan" },
    { "countyCode": "25", "countyName": "Pacific" },
    { "countyCode": "26", "countyName": "Pend Oreille" },
    { "countyCode": "27", "countyName": "Pierce" },
    { "countyCode": "28", "countyName": "San Juan" },
    { "countyCode": "29", "countyName": "Skagit" },
    { "countyCode": "30", "countyName": "Skamania" },
    { "countyCode": "31", "countyName": "Snohomish" },
    { "countyCode": "32", "countyName": "Spokane" },
    { "countyCode": "33", "countyName": "Stevens" },
    { "countyCode": "34", "countyName": "Thurston" },
    { "countyCode": "35", "countyName": "Wahkiakum" },
    { "countyCode": "36", "countyName": "Walla Walla" },
    { "countyCode": "37", "countyName": "Whatcom" },
    { "countyCode": "38", "countyName": "Whitman" },
    { "countyCode": "39", "countyName": "Yakima" },
];
export const WestVirginiaCounties = [
    { "countyCode": "1", "countyName": "Barbour" },
    { "countyCode": "2", "countyName": "Berkeley" },
    { "countyCode": "3", "countyName": "Boone" },
    { "countyCode": "4", "countyName": "Braxton" },
    { "countyCode": "5", "countyName": "Brooke" },
    { "countyCode": "6", "countyName": "Cabell" },
    { "countyCode": "7", "countyName": "Calhoun" },
    { "countyCode": "8", "countyName": "Clay" },
    { "countyCode": "9", "countyName": "Doddridge" },
    { "countyCode": "10", "countyName": "Fayette" },
    { "countyCode": "11", "countyName": "Gilmer" },
    { "countyCode": "12", "countyName": "Grant" },
    { "countyCode": "13", "countyName": "Greenbrier" },
    { "countyCode": "14", "countyName": "Hampshire" },
    { "countyCode": "15", "countyName": "Hancock" },
    { "countyCode": "16", "countyName": "Hardy" },
    { "countyCode": "17", "countyName": "Harrison" },
    { "countyCode": "18", "countyName": "Jackson" },
    { "countyCode": "19", "countyName": "Jefferson" },
    { "countyCode": "20", "countyName": "Kanawha" },
    { "countyCode": "21", "countyName": "Lewis" },
    { "countyCode": "22", "countyName": "Lincoln" },
    { "countyCode": "23", "countyName": "Logan" },
    { "countyCode": "24", "countyName": "Marion" },
    { "countyCode": "25", "countyName": "Marshall" },
    { "countyCode": "26", "countyName": "Mason" },
    { "countyCode": "27", "countyName": "McDowell" },
    { "countyCode": "28", "countyName": "Mercer" },
    { "countyCode": "29", "countyName": "Mineral" },
    { "countyCode": "30", "countyName": "Mingo" },
    { "countyCode": "31", "countyName": "Monongalia" },
    { "countyCode": "32", "countyName": "Monroe" },
    { "countyCode": "33", "countyName": "Morgan" },
    { "countyCode": "34", "countyName": "Nicholas" },
    { "countyCode": "35", "countyName": "Ohio" },
    { "countyCode": "36", "countyName": "Pendleton" },
    { "countyCode": "37", "countyName": "Pleasants" },
    { "countyCode": "38", "countyName": "Pocahontas" },
    { "countyCode": "39", "countyName": "Preston" },
    { "countyCode": "40", "countyName": "Putnam" },
    { "countyCode": "41", "countyName": "Raleigh" },
    { "countyCode": "42", "countyName": "Randolph" },
    { "countyCode": "43", "countyName": "Ritchie" },
    { "countyCode": "44", "countyName": "Roane" },
    { "countyCode": "45", "countyName": "Summers" },
    { "countyCode": "46", "countyName": "Taylor" },
    { "countyCode": "47", "countyName": "Tucker" },
    { "countyCode": "48", "countyName": "Tyler" },
    { "countyCode": "49", "countyName": "Upshur" },
    { "countyCode": "50", "countyName": "Wayne" },
    { "countyCode": "51", "countyName": "Webster" },
    { "countyCode": "52", "countyName": "Wetzel" },
    { "countyCode": "53", "countyName": "Wirt" },
    { "countyCode": "54", "countyName": "Wood" },
    { "countyCode": "55", "countyName": "Wyoming" },
];
export const WyomingCounties = [
    { "countyCode": "1", "countyName": "Albany" },
    { "countyCode": "2", "countyName": "Big Horn" },
    { "countyCode": "3", "countyName": "Campbell" },
    { "countyCode": "4", "countyName": "Carbon" },
    { "countyCode": "5", "countyName": "Converse" },
    { "countyCode": "6", "countyName": "Crook" },
    { "countyCode": "7", "countyName": "Fremont" },
    { "countyCode": "8", "countyName": "Goshen" },
    { "countyCode": "9", "countyName": "Hot Springs" },
    { "countyCode": "10", "countyName": "Johnson" },
    { "countyCode": "11", "countyName": "Laramie" },
    { "countyCode": "12", "countyName": "Lincoln" },
    { "countyCode": "13", "countyName": "Natrona" },
    { "countyCode": "14", "countyName": "Niobrara" },
    { "countyCode": "15", "countyName": "Park" },
    { "countyCode": "16", "countyName": "Platte" },
    { "countyCode": "17", "countyName": "Sheridan" },
    { "countyCode": "18", "countyName": "Sublette" },
    { "countyCode": "19", "countyName": "Sweetwater" },
    { "countyCode": "20", "countyName": "Teton" },
    { "countyCode": "21", "countyName": "Uinta" },
    { "countyCode": "22", "countyName": "Washakie" },
    { "countyCode": "23", "countyName": "Weston" },
];




export default {

    AlabamaCounties,
    AlaskaCounties,
    AmericanSamoaCounties,
    ArizonaCounties,
    ArkansasCounties,
    CaliforniaCounties,
    ColoradoCounties,
    ConnecticutCounties,
    DelawareCounties,
    DistrictOfColumbiaCounties,
    FloridaCounties,
    GeorgiaCounties,
    GuamCounties,
    HawaiiCounties,
    IdahoCounties,
    IllinoisCounties,
    IndianaCounties,
    IowaCounties,
    KansasCounties,
    KentuckyCounties,
    LouisianaCounties,
    MaineCounties,
    MarylandCounties,
    MassachusettsCounties,
    MinnesotaCounties,
    MichiganCounties,
    MassachusettsCounties,
    MississippiCounties,
    MissouriCounties,
    MontanaCounties,
    NebraskaCounties,
    NevadaCounties,
    NewYorkCounties,
    NewHampshireCounties,
    NewJerseyCounties,
    NewMexicoCounties,
    NorthCarolinaCounties,
    NorthDakotaCounties,
    OhioCounties,
    OklahomaCounties,
    OregonCounties,
    PennsylvaniaCounties,
    PuertoRicoCounties,
    RhodeIslandCounties,
    SouthCarolinaCounties,
    SouthDakotaCounties,
    TennesseeCounties,
    TexasCounties,
    UtahCounties,
    VermontCounties,
    VirginIslandsUSACounties,
    VirginiaCounties,
    WisconsinCounties,
    WashingtonCounties,
    WestVirginiaCounties,
    WyomingCounties
    


}