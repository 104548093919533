import React, { useEffect, useState } from "react";
import MapComponent from "../../../../../../../mapComponent";
import { fetchMapInfo } from "./symbology/labels";
import { calculateTotalAreaAndLength } from "./symbology/utils";

function App({
    account,
    setDisableDrag,
    record,
    updateRecord,
    open,
    setOpen,
    userInfo,
    currentGraphic,
    setCurrentGraphic
}) {
    useEffect(() => {
        // Set up any configuration or global event listeners here
    }, []);

    const [loading, setLoading] = useState(true);

    const [mapInfo, setMapInfo] = useState({});


    async function getMapInfo(userInfo, record) {
        const mapInfo = await fetchMapInfo(userInfo, record);
        console.log("MAP INFO", mapInfo)
        setMapInfo(mapInfo);
        setLoading(false);

       
    }







    useEffect(() => {

        /* get Map Info */
        getMapInfo(userInfo, record);


    }, [])

    const props = {
        account,
        setDisableDrag,
        record,
        updateRecord,
        open,
        setOpen,
        userInfo,
        mapInfo,
        loading,
        currentGraphic,
        setCurrentGraphic
    };


    return (
        <div

            onMouseOver={() => {
                setDisableDrag(true)
            }}
            onMouseLeave={() => {
                setDisableDrag(false)
            }}

        >
            {loading === false ? <MapComponent {...props} /> : null}



        </div>
    );
}

export default App;
