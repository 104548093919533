import { Button, Checkbox, Divider, OutlinedInput, Typography, Select, MenuItem, CircularProgress } from '@mui/material';
import { Col, Row } from 'antd';
import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { fullMobile } from '../../../publicPortal/util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSpinner } from '@fortawesome/free-solid-svg-icons';
import KeyDialog from './keyDialog';
import KeyRow from './keyRow';
import { archiveData, fetchData } from './firebaseFunctions';


const KeysTable = ({ userInfo, screenWidth, openSnack, company, account, keys, setKeys }) => {

    const [loading, setLoading] = useState(true);

    const [keyDialog, setKeyDialog] = useState(false);
    const [currentKey, setCurrentKey] = useState(null)
    const [route, setRoute] = useState('probeApiKeys')


    useEffect(() => {
        const fetch = async () => {
            const keys = await fetchData(userInfo, route);
            setKeys(keys);
            setLoading(false);
        }
        fetch();



    }, []);



    async function deleteKeys(deleteKeys) {
        // Extract the IDs only once
        const deleteIds = deleteKeys.map(k => k.id);

        try {
            setLoading(true); // Consider setting loading to true at the start

            // Assuming archiveData is an async operation to archive/delete provided keys
            await archiveData(userInfo, route, deleteIds);

            // Filter out the keys that were deleted
            const newKeys = keys.filter(k => !deleteIds.includes(k.id));
            setKeys(newKeys); // Update state with the remaining keys
            //remove locations from local storage
            localStorage.removeItem('locations');
            openSnack('success', 'Key(s) deleted successfully');
        } catch (error) {
            console.log('Error deleting keys:', error);
            openSnack('error', 'Failed to delete key(s)'); // Assuming you have a mechanism like this for error feedback
        } finally {
            setLoading(false); // Ensure loading is set to false in both success and error scenarios
        }
    }







    const props = {
        userInfo, screenWidth, openSnack, company, account, keyDialog, setKeyDialog, currentKey, setCurrentKey,
        keys, setKeys, deleteKeys, route
    }


    if (loading) {
        return (
            <Row style={{ height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                {/* Using the full height of the viewport to center content */}
                <Col span={24} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

                    {/* FontAwesome Spinner */}
                    <FontAwesomeIcon icon={faSpinner} spin size="3x" />

                    <Typography variant="h6" style={{ marginTop: 20 }}>
                        Loading your keys...
                    </Typography>

                </Col>
            </Row>
        )
    }

    else {
        return (
            <Row style={{ fontFamily: 'Roboto, sans-serif', }} align="middle" >



                <Col {...fullMobile(6, 6)} style={{ paddingLeft: 12 }}>
                    <Typography variant='body1' fontWeight={700} color={'black'} fontSize={14}>
                        Key Name
                    </Typography>
                </Col>

                <Col {...fullMobile(4, 4)}>
                    <Typography variant='body1' fontWeight={700} color={'black'} fontSize={14}>
                        Type
                    </Typography>
                </Col>
                <Col {...fullMobile(4, 4)}>
                    <Typography variant='body1' fontWeight={700} color={'black'} fontSize={14}>
                        Connection
                    </Typography>
                </Col>

                <Col {...fullMobile(4, 4)}>
                    <Typography variant='body1' fontWeight={700} color={'black'} fontSize={14}>
                        Credentials
                    </Typography>
                </Col>

                <Col {...fullMobile(4, 4)} style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Button
                        style={{
                            textTransform: 'none',
                            color: 'white',
                            backgroundColor: '#3f51b5',
                        }}
                        onClick={() => {
                            setCurrentKey(null);
                            setKeyDialog(true);
                        }}
                    >
                        <FontAwesomeIcon icon={faPlus} style={{ marginRight: 10 }} />
                        New Key
                    </Button>
                </Col>
                <Col {...fullMobile(24, 24)} style={{ paddingTop: 10 }} >
                    <Divider /* color blakc thick */ color={'gray'} sx={{ borderBottomWidth: '2px' }} />
                </Col>
                {keys.length === 0 && (
                    <Col {...fullMobile(24, 24)} style={{ paddingTop: 20, textAlign: 'center' }}>
                        <Typography variant='body1' fontWeight={600} fontSize={15} color={'rgba(0,0,0,0.8)'} >
                            No keys found
                        </Typography>
                    </Col>
                )}

                {keys.map((key, index) => {
                    return (
                        <Col {...fullMobile(24, 24)} style={{}}>
                            <KeyRow key={key.id} keyData={key} {...props} index={index} />
                        </Col>
                    )
                }
                )}


                <KeyDialog {...props} />

            </Row>
        );
    }
}

export default KeysTable;