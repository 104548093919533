import { Button, ButtonGroup, CircularProgress, Divider, Typography, OutlinedInput, Autocomplete, TextField, Select, MenuItem } from '@mui/material';
import { Col, Modal, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { fullMobile } from './util';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, } from 'firebase/firestore';

import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import ItemsTable from './itemsTable';
import { queryPSItems } from '../../firebase/config';



var randomColor = require('randomcolor');

export default function PSHomeNew(props) {
    const { userInfo, screenWidth, openSnack, company, account, } = props;
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);
    const [loadingItems, setLoadingItems] = useState(false);
    const [item, setItem] = useState({})
    const [currentButton, setCurrentButton] = useState('items')
    const [addItemModal, setAdditemModal] = useState(false)
    const [accounts, setAccounts] = useState([])



    const db = getFirestore()


    useEffect(() => {
        window.scrollTo(0, 0);
        getInfo()
    }, [])

    async function getInfo() {

        const items = await queryPSItems(userInfo);



        setItems(items)
        setLoadingItems(false)





    }





    const CreateItemButton = () => {
        let navigate = useNavigate();

        return (
            <Button variant={'contained'} display="inline"
                fullWidth
                style={{
                    padding: 4, paddingTop: 8, paddingBottom: 8,
                    textTransform: 'none', backgroundColor: '#404050', fontSize: 13,
                }}
                onClick={async () => {
                    const itemRef = doc(db, "users", userInfo.id, 'currentItems', 'currentPS');
                    await setDoc(itemRef, {
                        key: 'none'
                    }).then(() => {
                        navigate('/psItem')
                    })
                }}
            >
                <AddIcon fontSize='small' />  Add Item
            </Button>
        )
    }





    return (
        <div style={{ fontFamily: 'Roboto, sans-serif', backgroundColor: '#f6f8fe', minHeight: '100vh' }}>
            <Row  >
                <Col span={19} style={{
                    paddingTop: 30,
                    paddingLeft: 30,
                    overflow: 'auto',


                }}>
                    <Row align="middle" >
                        <Col {...fullMobile(20, 24)}>
                            <Typography variant='h3' fontWeight={500} fontSize={36}>Products, Services, and Templates</Typography>
                            <Typography style={{ paddingTop: 5 }} variant='h5' fontWeight={300} fontSize={18}>Your items that can be applied to field logs.</Typography>

                        </Col>

                        <Col {...fullMobile(24, 24)} style={{ paddingTop: 10, paddingBottom: 10 }}>
                            <Divider />
                        </Col>
                        <Col {...fullMobile(24, 24)} style={{ paddingTop: 10, paddingBottom: 10 }}>
                            <ButtonGroup variant="link" aria-label="contained primary button group">
                                {[{ name: 'items', label: 'Items' }].map((item, index) => {
                                    return (
                                        <Button style={{
                                            color: '#404050', textTransform: 'none', fontSize: 14, borderRadius: 0,
                                            //add orange bottom border if current button is users
                                            borderBottom: currentButton === item.name ? '2px solid #f57c00' : 'none'
                                        }}
                                            onClick={() => {
                                                setCurrentButton(item.name)
                                                console.log(item.name)
                                            }}
                                        >
                                            {item.label}
                                        </Button>
                                    )
                                })}

                            </ButtonGroup>

                        </Col>
                        <Col {...fullMobile(24, 24)} >
                            <Divider />
                        </Col>
                        {loadingItems === false ?
                            <Col {...fullMobile(24, 24)} style={{ padding: 8 }}>
                                {currentButton === 'items' && (
                                    <ItemsTable {...props} combinedRef={items} deleteItem={(key) => {
                                        const newItems = items.filter((i) => i.key !== key)
                                        console.log('delete')
                                        setItems([...newItems])
                                    }} />
                                )}



                            </Col> :
                            <CircularProgress />}
                    </Row>

                </Col>

                <Col {...fullMobile(5, 5)} style={{
                    minHeight: '100vh',
                    backgroundColor: '#f5f5f5'

                }}>
                    <Row style={{ padding: 30, }} >
                        <Col {...fullMobile(24, 24)}>
                            <CreateItemButton />
                        </Col>

                    </Row>

                </Col>















            </Row >
        </div >
    );
}