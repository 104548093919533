import { CircularProgress, Typography } from '@mui/material';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import React from 'react';
import moment from 'moment';
import Units from '../../../extra/units.json';
import axios, * as others from 'axios';
var convert = require('convert-units')
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/data')(Highcharts);
require('highcharts/modules/funnel')(Highcharts);




export default class WidgetCapacity extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            attributes: this.props.attributes,
            userInfo: this.props.userInfo,
            index: this.props.index,
            height: null,
            series: {},
            queryInfo: {},
            chartOptions: {
                exporting: {
                    enabled: false
                },
                credits: {
                    enabled: false
                },
                chart: {
                    type: 'funnel',

                    style: {
                        fontFamily: 'Roboto, sans-serif'
                    },





                    height: null,

                },
                stockTools: {
                    gui: {
                        enabled: false
                    }
                },
                title: {
                    text: null
                },
                plotOptions: {
                    series: {
                        dataLabels: {
                            enabled: true,
                            inside: true,
                            softConnector: true
                        },

                        neckWidth: '50%',
                        neckHeight: '0%',
                        width: '100%'
                    }
                },

                legend: {
                    enabled: false
                },


                series: [{
                    tooltip: {},
                    data: []
                }],
            },
            capacityData: [],
            depthUnits: '',
            areaUnits: '',
            volumeUnits: '',
            drawdownPercent: '',
            volumePercent: '',
            date: '',
            loading: true,


        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.attributes !== prevProps.attributes) {
            this.updateAttributes(this.props.attributes)
            this.setState({ attributes: this.props.attributes })
        }



    }
    componentDidMount() {
        const { attributes, chartOptions, queryInfo, series, } = this.state;
        this.setState({ height: this.container.offsetHeight, });
        this.updateChartSub('chart', 'height', this.container.offsetHeight * .8)




        this.getDocItem()

        setTimeout(() => {
            this.updateAttributes(attributes)
        }, 500);




    }

    getDocItem = async () => {
        const { userInfo } = this.state;
        const db = getFirestore();
        const docRef = doc(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'bathymetry', 'bathymetry');
        const docSnap = await getDoc(docRef);



        if (docSnap.exists()) {
            this.setState({
                step: 'final',
                capacityData: docSnap.data().data,
                areaUnits: docSnap.data().areaUnits, volumeUnits: docSnap.data().volumeUnits, depthUnits: docSnap.data().depthUnits,
            })

          
        }
    }



    updateAttributes = (attributes) => {
        const { chartOptions } = this.state;
     

        const qi = {
            parameterid: attributes.parameterid,
            dataType: attributes.dataType,
            locationid: attributes.locationid,
            color: attributes.color || 'blue',
            colorDisplacement: attributes.colorDisplacement || 'orange',
            decimals: attributes.decimals || 0,
            suffix: attributes.suffix || '',
            offset: attributes.offset || 0,
        };


        this.queryData(qi)



        this.setState({ chartOptions })

    }





    updateChart = (boo, val) => {
        const { chartOptions } = this.state;
        chartOptions[boo] = val;
        this.setState({ chartOptions })

    }
    updateChartSub = (boo, a, val) => {
        const { chartOptions } = this.state;
        chartOptions[boo][a] = val;
        this.setState({ chartOptions })

    }





    queryData = (q) => {
        const { chartOptions, series, userInfo, capacityData, depthUnits, areaUnits, volumeUnits, index, attributes } = this.state;

    
        const proxyUrl = "https://mycorslake.herokuapp.com/";
        const i = index;

        const queryString = "widgetsFire5";



        axios.post(proxyUrl + `https://us-central1-aquasource3.cloudfunctions.net/${queryString}/sqlRead`, {
            raw: `SELECT * FROM ${q.dataType === 'probe' ? 'probe_data' : 'node_data_new2'}
            WHERE ${q.dataType === 'probe' ? 'locationid' : 'nodeid'} = '${q.locationid}'and parameterid = '${q.parameterid}'
            ORDER BY "time" DESC
            LIMIT 1;`
        })
            .then(async (response) => {
                console.log(response.data)
            

                q.data = [];
                q.tooltip = {};


      



                const UnitsArray = Object.values(Units).map((p, i) => {
                    return ({
                        label: p,
                        key: Object.keys(Units)[i]
                    })
                });

                //filter unitsArray by response.data[0].unitid
                const inputDepthUnit = UnitsArray.filter((f) => f.key === response.data[0].unitid)[0]?.label;
         

                const outputDepthUnit = attributes.depthUnits || 'ft';
              

                //set date
                const t = response.data[0];


                const measureType = attributes.measureType || 'Depth OF Water';

                //change to DD-MM-YYYY hh:mm a with moment
                const date = moment(t.time, 'YYYY-MM-DD HH:mm:ss').format('LLL');
                this.setState({ date })



                const r = { ...q };
                const d = response.data[0];
                const offset = q.offset || 0;
                const value = Number(convert(d.value.toFixed(4)).from(inputDepthUnit).to(outputDepthUnit)) + offset;
         


                //max depth of water is max depth of capacityData
                const sortCapacity = capacityData.sort((a, b) => b.depth - a.depth);
                const sortVolume = capacityData.sort((a, b) => b.volume - a.volume);

                const maxVolume = sortVolume[0].volume;
                const currentVolume = sortCapacity.filter((f) => f.depth <= value)[0].volume
            

                const drawdown = maxVolume - currentVolume;


                const vol1 = maxVolume;
                const vol2 = currentVolume;
                q.data.push(['Drawdown', drawdown]);
                q.data.push(['Volume', currentVolume]);

                const volumePercent = Math.round((currentVolume / maxVolume) * 100);
                const drawdownPercent = Math.round((drawdown / maxVolume) * 100);






                q.colors = [q.colorDisplacement || 'orange', q.color || '#404050']
                q.tooltip.valueSuffix = q.suffix === undefined ? null : ` ${q.suffix}`;
                q.tooltip.valueDecimals = q.decimals || 0;
                q.tooltip.pointFormatter = function () {
                    const value = this.y.toLocaleString()
                    return `${value} (${volumeUnits})`;
                }
                chartOptions.plotOptions.series.dataLabels.formatter = function () {
                    const value = Number(this.y.toFixed(0)).toLocaleString()
                    return `${value} (${volumeUnits})`;
                }



                chartOptions.series[0] = q;

                this.setState({ chartOptions, drawdownPercent, volumePercent, currentVolume, loading: false })












            })
            .catch((error) => {
                // handle error
                console.log(error);
                this.setState({ loading: false })


            })
            .then(function () {
                // always executed
            });

    }



    render() {

        const { account, chartOptions, hoverData, loading, height, dimensions, volumePercent, date, } = this.state;


        const Height = () => {
            if (this.container) {
                if (this.container.offsetHeight !== height) {
                    this.updateChartSub('chart', 'height', this.container.offsetHeight * 0.8)
                    this.setState({ height: this.container.offsetHeight })
                }

            }

        }

        //percent full of container


        const Loading = () => {
            if (loading) {
                return <div ref={el => (this.container = el)} style={{
                    height: '100%', width: '100%',
                    //center content
                    display: 'flex', justifyContent: 'center', alignItems: 'center'
                }}>
                    <CircularProgress /> <Typography variant="body1">Loading Data</Typography></div>
            }
        }




        //if loading true show loading screen
        if (loading) { return <Loading /> } else {
            return (
                <div ref={el => (this.container = el)} style={{ height: '100%', width: '100%', }}>
                    <Height />
                    <div style={{ width: '100%', padding: 15, textAlign: 'left' }}>


                        <Typography variant="body1" fontSize={16} fontWeight={700}>{Number(volumePercent).toFixed(0)}% Full</Typography>
                        <Typography variant="body1" fontSize={13} fontWeight={500} >{date}</Typography>




                    </div>
                    <HighchartsReact
                        constructorType={"chart"}
                        highcharts={Highcharts}
                        //exporting disable
                        exporting={{
                            enabled: false
                        }

                        }
                        ref={"chart"}
                        options={chartOptions}
                        allowChartUpdate={true}
                        immutable={false}
                        updateArgs={[true, true, true]}
                    />
                </div>

            );
        }
    }
} 