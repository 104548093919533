import React, { useState, useEffect, useRef } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextField, Typography, Autocomplete, Modal, Box, Checkbox } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import axios, * as others from 'axios';
import Chart from './hBarChartComponents/chart';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, { attr } from 'highcharts';
import ProbeParameters from '../../../extra/probeParameters.json';
const borderRadius = require("highcharts-border-radius");
borderRadius(Highcharts);
const HorizontalBarChart = ({
    attributes,
    stations

}) => {


    const [fetchedData, setFetchedData] = useState([]);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);




    useEffect(() => {


        const nodes = [];
        stations.forEach((doc) => {

            if (doc.archived !== true && doc.children.length > 0) {
                const children = doc.children;
                //for each child create a node and add to locations
                children.forEach((child) => {

                    nodes.push({
                        ...child,
                        lat: doc.lat,
                        lng: doc.lng,
                        id: child.key, stationLabel: doc.name, label: child.name, type: 'node', stationType: child.type
                    })
                })

            }

        });

      
        siftData(attributes.chartData, nodes)
    }, [])

    async function siftData(chartData, nodes) {
        const params = [];

        chartData.forEach((item, index) => {
            if (item.parameters && item.location?.key) {
               
                const locationName = nodes.filter((node) => node.id === item.location?.key)[0]?.name;
             
                const paramItem = { locationid: item.locationid, locationName, parameters: [], index };
                item.parameters.forEach((parameter) => {
                    paramItem.parameters.push({
                        key: parameter.key,
                        value: parameter.value,
                        color: parameter.parameterColor || '#404050',

                    })
                })
                params.push(paramItem)
            }
        })

        getData(params)
    }

    async function getData(params) {
        const data = [];
        const requests = params.map((item) => {
         

            const locationid = item.locationid;
            const locationName = item.locationName;
            const index = item.index;
            const colors = item.parameters.map(p => p.color || '#404050').join(",");  // Get a comma-separated list of colors
            const parameterIds = item.parameters.map(p => `'${p.key}'`).join(",");  // Get a comma-separated list of parameter ids

            const raw = `
                WITH LatestTimestamps AS (
                    SELECT 
                    nodeid,
                        parameterid,
                        MAX(time) as latest_time
                    FROM 
                        node_data_new2
                    WHERE 
                    nodeid = '${locationid}' AND parameterid IN (${parameterIds}) 
                    GROUP BY 
                    nodeid, parameterid
                )
    
                SELECT 
                    t.*
                FROM 
                    node_data_new2 t
                INNER JOIN 
                    LatestTimestamps lt ON t.nodeid = lt.nodeid AND t.parameterid = lt.parameterid AND t.time = lt.latest_time;
            `;

            const proxyUrl = "https://mycorslake.herokuapp.com/";
            return axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgetsFire2/sqlRead', {
                raw: raw
            })
                .then((response) => {

                    //can you make all data values only two decimal places
                    const updatedData = response.data.map((item, itemIndex) => {
                        return {
                            ...item,
                            value: Number(item.value.toFixed(2)),
                            color: colors.split(",")[itemIndex],


                        }
                    })

                    data.push({ locationid: locationid, locationName, data: updatedData, index })
                })
                .catch((error) => {
                    console.log(error);
                });
        });

        // Wait for all requests to complete
        await Promise.all(requests);

        // After all requests are complete
     
        //get each locationid
        const locationids = data.sort((a, b) => a.index - b.index
        ).map((item) => item.locationName);

        function transformData(array) {
            // A container to hold our intermediate results
            let intermediate = {};

            array.forEach(obj => {
                obj.data.forEach(item => {
                
                    const name = ProbeParameters[item.parameterid];
                    const color = item.color;


                    if (intermediate[name]) {
                        intermediate[name].data.push(item.value);
                    } else {
                        intermediate[name] = {
                            name: name,
                            data: [item.value],
                            color: color,

                        };
                    }
                });
            });

            // Convert the intermediate result object to an array
            return Object.values(intermediate);
        }

        const transformedData = transformData(data);
    

        //sort transformed data by index
        transformedData.sort((a, b) => a.index - b.index);




        setChartOptions(prevOptions => ({
            ...prevOptions,
            xAxis: {
                ...prevOptions.xAxis,
                categories: locationids,


            },
            series: transformedData

        }));

        setTimeout(() => {
            setLoading(false);
        }, 100);


    }




    const [chartOptions, setChartOptions] = useState({
        credits: {
            enabled: false
        },
        exporting: {
            enabled: false
        },
        stockTools: {
            gui: {
                enabled: false
            }
        },
        title: {
            text: null
        },

        chart: {
            style: {
                fontSize: '14px',
                fontFamily: 'Roboto, sans-serif'
            },
            type: 'bar',
            backgroundColor: 'white',

        },

        xAxis: {
            categories,
            title: {
                text: null
            },
            gridLineWidth: 1,
            lineWidth: 0,
            labels: {
                overflow: 'justify',
                style: {
                    fontSize: '14px',
                }
            },

        },
        legend: {
            itemStyle: {
                fontSize: '14px',
                fontFamily: 'Roboto, sans-serif'
            },
        },
        yAxis: {
            min: 0,
            crosshair: {
                width: 10,
                color: '#2651d214',
                dashStyle: 'solid',
                zIndex: 0,
            },
            title: {
                text: null,
                enabled: false
            },

            labels: {
                overflow: 'justify',
                style: {
                    fontSize: '14px',
                }

            },
            gridLineWidth: 0
        },
        plotOptions: {
            bar: {
                borderRadius: 5,
                dataLabels: {
                    enabled: true
                },
                groupPadding: 0
            }
        },
        series: [],

    })

    const boxRef = useRef(null);
    const chartRef = useRef(null);

    useEffect(() => {
        if (!boxRef.current || !boxRef.current.parentNode) return;

        const parentElement = boxRef.current.parentNode;

        const resizeObserver = new ResizeObserver(() => {
            const newHeight = parentElement.clientHeight * 0.97

            setChartOptions(prevOptions => ({
                ...prevOptions,
                chart: {
                    ...prevOptions.chart,
                    height: newHeight
                }
            }));
        });

        resizeObserver.observe(parentElement);

        return () => {
            // Clean up the observer on component unmount
            resizeObserver.disconnect();
        };
    }, [boxRef]);




    return (
        <Box
            ref={boxRef}
            style={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                justifyContent: loading ? 'center' : 'flex-start',
                alignItems: loading ? 'center' : 'stretch',
                backgroundColor: 'white',
            }}
        >
            {loading ?

                <FontAwesomeIcon icon={faSpinner} spin />
                :
                <HighchartsReact
                    ref={chartRef}
                    constructorType={"chart"}
                    highcharts={Highcharts}
                    options={chartOptions}
                    allowChartUpdate={true}
                    immutable={false}
                    updateArgs={[true, true, true]}
                />
            }
        </Box>
    );
};

export default HorizontalBarChart;