import React from 'react';
import { Col, Row } from 'antd';
import { Breadcrumbs, Button, OutlinedInput, Paper, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { setDefaultOptions, loadModules, } from 'esri-loader';
import Sketch from "@arcgis/core/widgets/Sketch";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import Graphic from "@arcgis/core/Graphic";
import * as geometryEngineAsync from "@arcgis/core/geometry/geometryEngineAsync";
import Geometry from "@arcgis/core/geometry/Geometry";
import SketchViewModel from "@arcgis/core/widgets/Sketch/SketchViewModel";
import uuid from 'react-uuid';

export default class Layer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            map: this.props.map,
            view: this.props.view,
            layerColor: this.props.layerColor,
            currentMapLayer: this.props.currentMapLayer,
            layer: this.props.layer,
            item: this.props.item,
            graphics: this.props.graphics !== '' ? JSON.parse(this.props.graphics) : [],
            sketch: {}

        };
    }

    componentDidUpdate(prevProps) {

        if (this.props.layerColor !== prevProps.layerColor) {
            this.changeColor(this.props.layerColor);
            this.changeGraphicsColor(this.props.layerColor);
            this.setState({ item: this.props.item, layerColor: this.props.layerColor })
        }
        if (this.props.graphics !== prevProps.graphics) {
          
            this.setState({ graphics: this.props.graphics !== '' ? JSON.parse(this.props.graphics) : [], layerColor: this.props.layerColor })
        }
        if (this.props.currentMapLayer !== prevProps.currentMapLayer) {
            this.checkCurrentLayer(this.props.currentMapLayer)
            this.setState({ currentMapLayer: this.props.currentMapLayer, })
        }
        if (this.props.item !== prevProps.item) {
            this.setState({ item: this.props.item, })
        }


    }
    componentDidMount() {
        const { account, map, view, currentMapLayer, layer } = this.state;
        console.log(this.props.graphics)
        this.addItem(layer)


    }

    addItem = (layer) => {
        const { view, map, layerColor, graphics, } = this.state;
        loadModules(["esri/widgets/Sketch",
            "esri/layers/GraphicsLayer",
            "esri/widgets/Sketch/SketchViewModel",
            "esri/geometry/geometryEngine",
            "esri/Graphic"])
            .then(([Sketch, GraphicsLayer, SketchViewModel, geometryEngine, Graphic]) => {

                const graphicsLayer = new GraphicsLayer({ title: layer, id: layer });
                map.add(graphicsLayer)

                const polygonSymbol = {
                    type: "simple-fill", // autocasts as new SimpleFillSymbol()
                    color: layerColor,
                    outline: {
                        // autocasts as new SimpleLineSymbol()
                        color: "white",
                        width: 2
                    }
                };

                let polylineSymbol = {
                    type: "simple-line",  // autocasts as new SimpleLineSymbol()
                    color: layerColor,
                    width: "4px",
                };

                let pointSymbol = {
                    type: "simple-marker",  // autocasts as new SimpleMarkerSymbol()
                    style: "square",
                    color: layerColor,
                    size: "8px",  // pixels
                    outline: {  // autocasts as new SimpleLineSymbol()
                        color: [255, 255, 0],
                        width: 3  // points
                    }
                };


                graphics.map((g, i) => {

                    const s = g.attributes.shapeType;
                    g.symbol = s === 'polygon' ? polygonSymbol : s === 'point' ? pointSymbol : polylineSymbol;
                    g.geometry.type = s;
                    let graphic = new Graphic({
                        geometry: g.geometry,
                        symbol: g.symbol,
                        attributes: g.attributes,
                        id: i

                    });
                    graphicsLayer.add(graphic);

                })





            })


    }
    addSketch = (currentMapLayer) => {
        const { view, map, layerColor, graphics, layer } = this.state;
        loadModules(["esri/widgets/Sketch",
            "esri/layers/GraphicsLayer",
            "esri/widgets/Sketch/SketchViewModel",
            "esri/geometry/geometryEngine",
            "esri/Graphic"])
            .then(([Sketch, GraphicsLayer, SketchViewModel, geometryEngine, Graphic]) => {


                const graphicsLayer = map.allLayers.find((g) => {
                    return g.title === layer;
                });


                const polygonSymbol = {
                    type: "simple-fill", // autocasts as new SimpleFillSymbol()
                    color: layerColor,
                    outline: {
                        // autocasts as new SimpleLineSymbol()
                        color: "white",
                        width: 2
                    }
                };

                let polylineSymbol = {
                    type: "simple-line",  // autocasts as new SimpleLineSymbol()
                    color: layerColor,
                    width: "4px",
                };

                let pointSymbol = {
                    type: "simple-marker",  // autocasts as new SimpleMarkerSymbol()
                    style: "square",
                    color: layerColor,
                    size: "8px",  // pixels
                    outline: {  // autocasts as new SimpleLineSymbol()
                        color: [255, 255, 0],
                        width: 3  // points
                    }
                };


                let sketchVM = new SketchViewModel({
                    layer: graphicsLayer,
                    pointSymbol,
                    polylineSymbol,
                    polygonSymbol,
                    view: view
                });

                view.when(() => {
                    const sketch = new Sketch({
                        layer: graphicsLayer,
                        view: view,
                        id: currentMapLayer,
                        creationMode: "single",
                        viewModel: sketchVM,
                        visibleElements: {
                            settingsMenu: false
                        }
                    });


                    sketch.on("create", (event => {
                        if (event.state === "complete") {
                            const attributes = {
                                type: "accountMap",
                                title: '',
                                key: uuid(),
                                visible: true,
                                area: geometryEngine.planarArea(event.graphic.geometry, 'acres'),
                                length: geometryEngine.planarLength(event.graphic.geometry, 'feet'),
                                areaUnits: "acres",
                                lengthUnits: "ft",
                                visible: true,
                                geometryType: event.graphic.geometry.spatialReference,
                                shapeType: event.tool === 'circle' ? 'polygon' : event.tool === 'point' ? 'point' : event.tool === 'polyline' ? 'polyline' : 'polygon',
                                lat: event.tool === 'point' ? event.graphic.geometry.latitude :
                                    event.tool === 'polyline' ? event.graphic.geometry.extent.center.latitude : event.graphic.geometry.centroid.latitude,
                                lon: event.tool === 'point' ? event.graphic.geometry.longitude :
                                    event.tool === 'polyline' ? event.graphic.geometry.extent.center.longitude : event.graphic.geometry.centroid.longitude
                            }
                            event.graphic.attributes = attributes;
                            const item = {
                                geometry: event.graphic.geometry,
                                symbol: event.graphic.symbol,
                                attributes

                            }
                            graphics.push(item);
                            this.stringGraphics(graphics)
                            this.setState({ graphics });
                        }
                    }))
                    sketch.on("update", (event => {
                        if (event.state === "complete") {

                            event.graphics.map((g) => {
                                console.log(event)
                                const item = {
                                    geometry: g.geometry,
                                    symbol: g.symbol,
                                    attributes: {
                                        ...g.attributes,
                                        area: geometryEngine.planarArea(g.geometry, 'acres'),
                                        length: geometryEngine.planarLength(g.geometry, 'feet'),
                                        lat: g.attributes.shapeType === 'point' ? g.geometry.latitude :
                                            g.attributes.shapeType === 'polyline' ? g.geometry.extent.center.latitude : g.geometry.centroid.latitude,
                                        lon: g.attributes.shapeType === 'point' ? g.geometry.longitude :
                                            g.attributes.shapeType === 'polyline' ? g.geometry.extent.center.longitude : g.geometry.centroid.longitude

                                    }
                                }
                                g.attributes = item.attributes;

                                const index = graphics.map((l) => l.attributes.key).indexOf(item.attributes.key);
                                graphics[index] = g;
                                this.stringGraphics(graphics)
                                this.setState({ graphics })

                            })

                        }
                    }))
                    sketch.on("delete", (event => {
                        event.graphics.map((g) => {
                            const filterGraphics = graphics.filter((l) => l.attributes.key !== g.attributes.key);
                            this.stringGraphics(filterGraphics)
                            this.setState({ graphics: filterGraphics })

                        })

                    }))
                    this.setState({ sketch })
                    view.ui.add(sketch, "top-right");
                });


            })


    }

    removeSketch = (currentMapLayer,) => {
        const { view, map, layerColor, graphics, sketch } = this.state;
        loadModules(["esri/widgets/Sketch",
            "esri/layers/GraphicsLayer",
            "esri/widgets/Sketch/SketchViewModel",
            "esri/geometry/geometryEngine",
            "esri/Graphic"])
            .then(([Sketch, GraphicsLayer, SketchViewModel, geometryEngine, Graphic]) => {




                view.ui.remove(sketch)



            })

    }

    newGraphics = (graphics, layerColor) => {
        const { view, map, logKey, currentMapLayer, layer } = this.state;
        loadModules(["esri/widgets/Sketch",
            "esri/layers/GraphicsLayer",
            "esri/widgets/Sketch/SketchViewModel",
            "esri/geometry/geometryEngine",
            "esri/Graphic"])
            .then(([Sketch, GraphicsLayer, SketchViewModel, geometryEngine, Graphic]) => {

                console.log(graphics)

                const graphicsLayer = map.allLayers.find((g) => {
                    return g.title === layer;
                });





                graphicsLayer.graphics.map((g) => {
                    graphicsLayer.remove(g)
                })



                const polygonSymbol = {
                    type: "simple-fill", // autocasts as new SimpleFillSymbol()
                    color: layerColor,
                    outline: {
                        // autocasts as new SimpleLineSymbol()
                        color: "white",
                        width: 2
                    }
                };

                let polylineSymbol = {
                    type: "simple-line",  // autocasts as new SimpleLineSymbol()
                    color: layerColor,
                    width: "4px",
                };

                let pointSymbol = {
                    type: "simple-marker",  // autocasts as new SimpleMarkerSymbol()
                    style: "square",
                    color: layerColor,
                    size: "8px",  // pixels
                    outline: {  // autocasts as new SimpleLineSymbol()
                        color: [255, 255, 0],
                        width: 3  // points
                    }
                };


                graphics.map((g, i) => {

                    const s = g.attributes.shapeType;
                    g.symbol = s === 'polygon' ? polygonSymbol : s === 'point' ? pointSymbol : polylineSymbol;
                    g.geometry.type = s;

                    let graphic = new Graphic({
                        geometry: g.geometry,
                        symbol: g.symbol,
                        attributes: g.attributes,
                        id: i

                    });

                    graphicsLayer.add(graphic);

                })


            })


    }

    stringGraphics = (graphics) => {
        const { item, layer } = this.state;
        const string = JSON.stringify(graphics);
        this.props.addGraphics(layer, string)


    }

    changeColor = (color) => {
        const { view, map, currentMapLayer, layer } = this.state;
        loadModules(["esri/widgets/Sketch",
            "esri/layers/GraphicsLayer",
            "esri/widgets/Sketch/SketchViewModel",
            "esri/Color"])
            .then(([Sketch, GraphicsLayer, SketchViewModel, Color]) => {

                const graphicsLayer = map.allLayers.find((g) => {
                    return g.title === layer;
                });


                const polygonSymbol = {
                    type: "simple-fill", // autocasts as new SimpleFillSymbol()
                    color: color,
                    outline: {
                        // autocasts as new SimpleLineSymbol()
                        color: "white",
                        width: 2
                    }
                };

                let polylineSymbol = {
                    type: "simple-line",  // autocasts as new SimpleLineSymbol()
                    color: color,
                    width: "4px",
                };

                let pointSymbol = {
                    type: "simple-marker",  // autocasts as new SimpleMarkerSymbol()
                    style: "square",
                    color: color,
                    size: "8px",  // pixels
                    outline: {  // autocasts as new SimpleLineSymbol()
                        color: [255, 255, 0],
                        width: 2  // points
                    }
                };

                let sketchVM = new SketchViewModel({
                    layer: graphicsLayer,
                    pointSymbol,
                    polylineSymbol,
                    polygonSymbol,
                    view: view
                });
                const sketch = view.ui.find(currentMapLayer);
                sketch.viewModel = sketchVM;




            })

    }

    changeGraphicsColor = (color) => {
        const { view, map, currentMapLayer, layer } = this.state;
        loadModules(["esri/widgets/Sketch",
            "esri/layers/GraphicsLayer",
            "esri/widgets/Sketch/SketchViewModel",
            "esri/Color",
            "esri/Graphic"])
            .then(([Sketch, GraphicsLayer, SketchViewModel, Color, Graphic]) => {

                const graphicsLayer = map.allLayers.find((g) => {
                    return g.title === layer;
                });



                const graphics = graphicsLayer.graphics;
                graphics.map((g, i) => {
                    if (g.geometry.rings !== undefined) {
                        g.symbol = {
                            type: "simple-fill",
                            color: color,
                            outline: {
                                color: "white",
                                width: 2
                            }
                        };
                    }
                    if (g.geometry.paths !== undefined) {
                        g.symbol = {
                            type: "simple-line",  // autocasts as new SimpleLineSymbol()
                            color: color,
                            width: "4px",
                        };
                    }
                    if (g.geometry.x !== undefined) {
                        g.symbol = {
                            type: "simple-marker",  // autocasts as new SimpleMarkerSymbol()
                            style: "square",
                            color: color,
                            size: "8px",  // pixels
                            outline: {  // autocasts as new SimpleLineSymbol()
                                color: [255, 255, 0],
                                width: 2  // points
                            }
                        };
                    }

                    g.color = color;

                })











            })
    }

    checkCurrentLayer = (currentMapLayer) => {
        const { layer } = this.state;

        if (layer === currentMapLayer) {

            this.addSketch(layer)
        } else {

            this.removeSketch(layer)
        }
    }

    render() {

        const { } = this.state;

        return null;
    }
} 