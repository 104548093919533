import { CircularProgress, Divider, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import Highcharts, { chart } from 'highcharts/highstock';

import React from 'react';
import ReadingChart from '../charts/readingChart';


import axios, * as others from 'axios';
import WidgetReadingHeader from './widgetReadingHeader';
import WdigetReadingHistory from './widgetReadingHistory';
import moment from 'moment';
import unitsList from '../../../extra/units.json'

var convert = require('convert-units')
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/data')(Highcharts);
require('highcharts/modules/funnel')(Highcharts);

const parameterMap = {
    '1': {
        default: 'C',
    },
    '3': {
        default: 'm',

    },
    '4': {
        default: 'm',
    },
    '5': {
        default: 'm',
    },
    '8': {
        default: 'm',
    },
    '19': {
        from: '',
        to: ''
    }
};


export default class WidgetReading extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            attributes: this.props.attributes,
            userInfo: this.props.userInfo,
            index: this.props.index,
            height: null,
            series: {},
            queryInfo: {},
            loading: true,
            chartOptions: {
                height: null,
                value: '',
                time: '',
                color: '',
                suffix: '',
            },
            spacing: this.props.spacing,
            historyData: null



        };

    }

    updateWidgetWidth = entries => {

        for (let entry of entries) {
            this.setState({ widgetWidth: entry.contentRect.width });
        }
    }

    componentDidUpdate(prevProps) {
        //if spacing changes setstate spacing
        if (this.props.spacing !== prevProps.spacing) {

            this.setState({ spacing: this.props.spacing })
        }



    }
    componentDidMount() {
        const { attributes, chartOptions, queryInfo, series, spacing } = this.state;



        setTimeout(() => {
            this.updateAttributes(attributes)
        }, 500);




    }




    updateAttributes = (attributes) => {
        const { chartOptions } = this.state;
        const input = attributes.decimals === 0 || attributes.decimals ? attributes.decimals : 4;
        const qi = {
            parameterid: attributes.parameterid,
            dataType: attributes.dataType,
            locationid: attributes.locationid,
            color: attributes.color || 'orange',
            suffix: attributes.suffix || '',
            offset: attributes.offset || 0,
            label: attributes.label || 0,
            decimals: input,
            units: attributes.units || '',
        };



        this.queryData(qi)



        this.setState({ chartOptions })

    }





    updateChart = (boo, val) => {
        const { chartOptions } = this.state;
        chartOptions[boo] = val;
        this.setState({ chartOptions })

    }
    updateChartSub = (boo, a, val) => {
        const { chartOptions } = this.state;
        chartOptions[a] = val;
        this.setState({ chartOptions })

    }





    queryData = (q,) => {

        const { chartOptions, series, userInfo, capacityData, depthUnits, areaUnits, volumeUnits, attributes, index } = this.state;

        const i = index;



        const queryString = "widgetsFire5";



        const functionNameMap = [
            "databaseFetch3",
            "databaseFetch4",
            "databaseFetch5",
        ]



        /* pick random number between 0 and 2 */
        const randomIndex = Math.floor(Math.random() * 3);
        const randomFunctionName = functionNameMap[randomIndex];
        const proxyUrl = "https://mycorslake.herokuapp.com/";

        axios.post(proxyUrl + `https://us-central1-aquasource3.cloudfunctions.net/${randomFunctionName}`, {
            raw: `SELECT * FROM ${q.dataType === 'probe' ? 'probe_data' : 'node_data_new2'}
            WHERE ${q.dataType === 'probe' ? 'locationid' : 'nodeid'} = '${q.locationid}'and parameterid = '${q.parameterid}'
            ORDER BY "time" DESC
            LIMIT 1;`
        })
            .then(async (response) => {




                const readingOffset = response.data[0].offsetvalue || 0;
                const offset = q.offset || 0;
                let value = Number(response.data[0].value);










                let time = Number(moment(response.data[0].time, 'YYYY-MM-DD HH:mm:ss').format('X'));
                let unit = response.data[0].unitid;


                const newUnit = q.units === "℃" ? "C" : q.units;
                //remove degree symbol from unit
                const newUnit2 = newUnit.replace(/[^a-zA-Z ]/g, "");


                if (["38", "35", "1", "2"].includes(unit) && unit !== undefined && unit !== null && unit !== '' && newUnit2) {
                    let parameter = parameterMap[q.parameterid] || {};
                    let defaultUnit = parameter.default || '';


                    const unitLabel = unitsList[unit] ? unitsList[unit] : defaultUnit;

                    value = Number(convert(value.toFixed(4)).from(unitLabel).to(newUnit2));
                }
                else {
                    value = q.parameterid === '19' ? Number(value.toFixed(4)) * 1000 : Number(value.toFixed(4));
                }

                if (attributes?.dataSourceType === 'readingWithOffset') {
                    value += readingOffset;
                }
                if (attributes?.dataSourceType === 'offset') {
                    value = readingOffset;
                }


                chartOptions.value = value;
                chartOptions.time = time;
                chartOptions.color = q.color || 'orange';
                chartOptions.suffix = q.suffix || '';
                chartOptions.label = q.label || '';
                chartOptions.decimals = q.decimals || 4;
                chartOptions.dataSourceType = attributes?.dataSourceType || 'reading';
                chartOptions.readingOffset = readingOffset || 0;



                this.setState({ chartOptions, loading: false })








            })
            .catch((error) => {
                // handle error
                console.log(error);
                this.setState({ loading: false })


            })
            .then(function () {
                // always executed
            });

        if (attributes.getHistory === true) {
            axios.post(proxyUrl + `https://us-central1-aquasource3.cloudfunctions.net/${queryString}/sqlRead`, {
                raw: `SELECT 
        time_bucket('7 day', time) as bucket, 
        MIN(value) as min_value, 
        MAX(value) as max_value, 
        AVG(value) as avg_value 
        FROM ${q.dataType === 'probe' ? 'probe_data' : 'node_data_new2'}
        WHERE ${q.dataType === 'probe' ? 'locationid' : 'nodeid'} = '${q.locationid}' and parameterid = '${q.parameterid}'
        AND time >= NOW() - interval '6 day'
        GROUP BY bucket
        ORDER BY bucket DESC;`
            })
                .then(async (response) => {
                    console.log(response.data)

                    this.setState({ historyData: { data: response.data, range: 'week' } })

                })
                .catch((error) => {
                    console.log(error);

                })
                .finally(() => {
                    // always executed
                });
        }

    }



    render() {

        const { widgetWidth, account, chartOptions, hoverData, loading, dimensions, attributes, height, spacing, historyData } = this.state;

        const Height = () => {
            if (this.container) {
                if (this.container.offsetHeight !== height) {

                    this.setState({ height: this.container.offsetHeight })
                }

            }

        }


        const Loading = () => { if (loading) { return <div className="loading"><CircularProgress /> <Typography variant="body1">Loading Data</Typography></div> } }

        //can you get the width of the widgetRef


        //if loading true show loading screen
        if (loading) { return <Loading /> } else {
            return (
                <Row style={{ height: '100%', width: '100%', display: 'flex' }} >


                    <Col span={24}>
                        <Divider style={{ paddingTop: 0, paddingBottom: 0, maringBottom: 0 }} />
                    </Col>
                    <WidgetReadingHeader attributes={attributes} chartOptions={chartOptions} historyData={historyData} />

                    <Col span={24}>
                        <Divider sx={{
                            borderBottomWidth: 5,
                            paddingTop: 0, paddingBottom: 0, marginBottom: 0, marginTop: 0,
                            borderColor: chartOptions.color || 'orange'
                        }} />
                    </Col>
                    {attributes.showChart && (<Col span={22} offset={2}>
                        <Typography variant="body2" fontSize={14} fontWeight={600} color={'rgba(0,0,0,0.8)'}>
                            Last {attributes.timeFrame === undefined ? '' : attributes.timeFrame === 'weeks' ? 'Week' :
                                attributes.timeFrame === 'days' ? 'Day' : "Month"}</Typography>
                    </Col>)}
                    {attributes.showChart && (<Col span={24} style={{
                        flexGrow: 1,
                        height: attributes.chartHeight !== undefined ? Number(attributes.chartHeight) : 175,
                    }}>
                        <ReadingChart
                            lastReading={chartOptions.time}
                            attributes={{
                                ...attributes, locationid: attributes.location?.id, parameterid: attributes.parameterid, color: chartOptions.color || 'orange', dataSourceType: attributes.dataSourceType
                            }}
                        />
                    </Col>)}


                </Row >

            );

        }




    }
} 