import React, { useEffect, useRef, useState } from 'react';

import MyLocationIcon from '@mui/icons-material/MyLocation';
import HomeIcon from '@mui/icons-material/Home';
import * as ReactDOMServer from 'react-dom/server';
import {
    DrawingManager, GoogleMap, KmlLayer, StandaloneSearchBox, useJsApiLoader
} from '@react-google-maps/api';
import uuid from 'react-uuid';
var axios = require('axios');

function WidgetMapGoogle({ attributes, account, height, widgetKey, updateAttributes, publicViewable, probes, stationsProps }) {
    const [searchBox, setSearchBox] = useState(null);
    const [drawingManager, setDM] = useState(null);
    const [center, setCenter] = useState({ lat: attributes.center !== undefined ? attributes.center[0] : account?.lat, lng: attributes.center !== undefined ? attributes.center[1] : account?.lng });
    const [zoom, setZoom] = useState(attributes.zoom !== undefined ? attributes.zoom : 12);
    const [mapHeight, setMapHeight] = useState(height);
    const [publicView, setPublicView] = useState(publicViewable);
    const mapRef = useRef(null);
    const [existingCenter, setExistingCenter] = useState({ lat: attributes.center !== undefined ? attributes.center[0] : account?.lat, lng: attributes.center !== undefined ? attributes.center[1] : account?.lng });
    const [existingZoom, setExistingZoom] = useState(attributes.zoom !== undefined ? attributes.zoom : 12);
    const [locations, setLocations] = useState(probes);
    const [markers, setMarkers] = useState([]);
    const [stations, setStations] = useState(stationsProps);




    useEffect(() => {
        setStations(stationsProps)
    }, [stationsProps]);

    useEffect(() => {
        
    }, []);

    const componentRef = useRef(null);
    useEffect(() => {
        setMapHeight(height)
    }, [height]);
    useEffect(() => {
        setPublicView(publicViewable)
    }, [publicViewable]);

    useEffect(() => {

        setLocations(probes)
        if (attributes.visibleProbes !== undefined && attributes.visibleProbes === true && probes !== undefined && probes.length > 0) {
            addLocations(probes, attributes.visibleProbes)
        }

    }, [probes]);



    const onPlacesChanged = () => {
        const places = searchBox.getPlaces();

        //change center of map to new place
        setCenter({ lat: places[0].geometry.location.lat(), lng: places[0].geometry.location.lng() });
        setZoom(13);
        handleCheck([places[0].geometry.location.lat(), places[0].geometry.location.lng()], 13);


    };


    const onSBLoad = ref => {
        setSearchBox(ref);
    };
    const onDMLoad = ref => {
        setDM(ref);
    };

    const onPolygonComplete = polygon => {

    }


    function handleCheck(center, zoom) {

        toggleCheck(center, zoom);

    }


    function toggleCheck(center, zoom) {

        attributes.center = center;
        attributes.zoom = zoom;

        updateAttributes(widgetKey, attributes)

    }

    function handleCenterChanged() {



        let timer = 0;
        clearTimeout(timer);
        timer = setTimeout(() => {
            if (!mapRef.current) return;
            //get lat and lng of center of map
            if (publicView === false) {


                const center = mapRef.current.getCenter();
                const lat = center.lat();
                const lng = center.lng();
                const zoom = mapRef.current.getZoom();
                if (JSON.stringify(existingCenter) === JSON.stringify({ lat, lng }) && existingZoom === zoom) {

                }
                else {
                    handleCheck([lat, lng], zoom);
                }
            }
        }, 1000);






    }

    function addLocations(locations, visible) {

        locations.forEach(location => {
            const marker = new window.google.maps.Marker({
                position: { lat: location.lat, lng: location.lon },
                map: visible ? mapRef.current : null,
                icon: {
                    url: 'https://firebasestorage.googleapis.com/v0/b/aquasource3.appspot.com/o/fieldLogsNew14ab01e-bafa-8db3-67f3-58a6b525a7d5?alt=media&token=a843ea24-b2e8-4a65-91ef-a5bbe78b268a',
                    scaledSize: new window.google.maps.Size(60, 60),
                    origin: new window.google.maps.Point(0, 0),
                    anchor: new window.google.maps.Point(20, 20),

                },
            })
            markers.push(marker)
            setMarkers(markers)
        })
    }


    function addCurrentLocationButton() {
        const html = ReactDOMServer.renderToString(<MyLocationIcon />);


        var currentLocationButton = document.createElement('button');
        currentLocationButton.innerHTML = html;
        currentLocationButton.style.cursor = 'pointer';
        currentLocationButton.classList.add('custom-map-control-button');
        mapRef.current.controls[window.google.maps.ControlPosition.RIGHT_TOP].push(currentLocationButton);
        currentLocationButton.addEventListener('click', () => {
            // Try HTML5 geolocation.
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {

                        const lat = position.coords.latitude;
                        const lng = position.coords.longitude;
                        //current zoom
                        const zoom = mapRef.current.getZoom();


                        mapRef.current.setCenter({ lat, lng });
                        mapRef.current.setZoom(zoom);
                        handleCheck([lat, lng], zoom);
                    },
                    () => {
                        handleLocationError(true, infoWindow, mapRef.current.getCenter());
                    }
                );
            } else {
                // Browser doesn't support Geolocation
                handleLocationError(false, infoWindow, mapRef.current.getCenter());
            }
        });
    }

    function handleLocationError(browserHasGeolocation, infoWindow, pos) {
        infoWindow.setPosition(pos);
        infoWindow.setContent(
            browserHasGeolocation

                ? "Error: The Geolocation service failed."
                : "Error: Your browser doesn't support geolocation."
        );
        infoWindow.open(mapRef.current);
    }


    const infoWindow = new window.google.maps.InfoWindow();

    function addToAccountLocationButton(info) {
        const html = ReactDOMServer.renderToString(<HomeIcon />);


        var currentLocationButton = document.createElement('button');
        //change cursor to pointer
        currentLocationButton.style.cursor = 'pointer';
        currentLocationButton.innerHTML = html;
        currentLocationButton.classList.add('custom-map-control-button');
        mapRef.current.controls[window.google.maps.ControlPosition.RIGHT_TOP].push(currentLocationButton);

        currentLocationButton.addEventListener('click', (e) => {

            mapRef.current.setCenter({ lat: account.lat, lng: account.lng });


            handleCheck([account.lat, account.lng], zoom);

        });
    }
    function handleLoad(map) {
        mapRef.current = map;

        //add current location button if location button does not exist
        if (document.querySelector('.custom-map-control-button') === null) {

            addCurrentLocationButton();
        }

        //add account location button if location button does not exist
        if (document.querySelector('.custom-map-control-button') === null) {
            addToAccountLocationButton();
        }




        if (stations.length > 0 && attributes.visibleStations !== undefined && attributes.visibleStations === true) {

            addStations(stations);
        }



        if (attributes.kmlLayers !== undefined && attributes.kmlLayers.length > 0) {
            addKmlLayers(attributes.kmlLayers);
        }

        if (probes.length > 0) {
            //add marker for each probe but set them to invisible
            probes.forEach(probe => {

                if (probe.recording !== undefined && probe.recording == true) {
                    const svgMarker = {
                        path: "M27.648-41.399q0-3.816-2.7-6.516t-6.516-2.7-6.516 2.7-2.7 6.516 2.7 6.516 6.516 2.7 6.516-2.7 2.7-6.516zm9.216 0q0 3.924-1.188 6.444l-13.104 27.864q-.576 1.188-1.71 1.872t-2.43.684-2.43-.684-1.674-1.872l-13.14-27.864q-1.188-2.52-1.188-6.444 0-7.632 5.4-13.032t13.032-5.4 13.032 5.4 5.4 13.032z",
                        fillColor: "blue",
                        fillOpacity: 1,
                        strokeWeight: 0,
                        rotation: 0,
                        scale: .8,
                        anchor: new window.google.maps.Point(0, 0),
                        labelOrigin: new window.google.maps.Point(15, 10)
                    };

                    //create name with line breaks every 20 characters <br />
                    let name = probe.name
                    let nameArray = name.split('')
                    let nameWithBreaks = ''
                    for (let i = 0; i < nameArray.length; i++) {
                        if (i % 20 == 0 && i != 0) {
                            nameWithBreaks += '<br />'
                        }
                        nameWithBreaks += nameArray[i]
                    }


                    const marker = new window.google.maps.Marker({
                        position: { lat: probe.lat, lng: probe.lon },
                        map: mapRef.current,
                        icon: {
                            ...svgMarker,
                            fillColor: 'red',
                            strokeColor: 'white',
                            strokeWeight: 0.5,
                        },

                        visible: false
                    });
                    probe.marker = marker;

                    //when marker is clicked, open info window showing probe name
                    marker.addListener('click', () => {
                        const infoWindow = new window.google.maps.InfoWindow({
                            content: `<div style="font-size: 14px; font-weight: 600; color: black; text-align: center;">${nameWithBreaks}</div>`
                        });
                        infoWindow.open(mapRef.current, marker);

                        //close info window when map is clicked
                        mapRef.current.addListener('click', () => {
                            infoWindow.close();
                        }
                        )
                        //close info window when another marker is clicked
                        marker.addListener('click', () => {
                            infoWindow.close();
                        }
                        )
                        //close info window if x is clicked
                        infoWindow.addListener('closeclick', () => {
                            infoWindow.close();
                        }
                        )
                    })




                    probe.type = 'probe'
                }
            })





            addShowProbesButton(probes)
        }
    }

    function addShowProbesButton(list) {
        //create a button in the bottom left of the map that toggles the visibility of the probes
        const showProbesButton = document.createElement('button');
        showProbesButton.textContent = 'Show Probes';
        // increase text size and add padding
        showProbesButton.style.fontSize = '14px';
        showProbesButton.style.padding = '8px';
        //add border radius and white background
        showProbesButton.style.borderRadius = '4px';
        showProbesButton.style.backgroundColor = 'white';
        //add box shadow
        showProbesButton.style.boxShadow = '0 1px 4px rgba(0,0,0,0.3)';
        showProbesButton.classList.add('custom-map-control-button');
        mapRef.current.controls[window.google.maps.ControlPosition.LEFT_BOTTOM].push(showProbesButton);
        showProbesButton.addEventListener('click', () => {
            if (showProbesButton.textContent == 'Show Probes') {
                showProbesButton.textContent = 'Hide Probes';
                //show probes
                list.forEach(item => {
                    if (item.type == 'probe') {

                        item.marker.setVisible(true)
                    }
                })
            } else {
                showProbesButton.textContent = 'Show Probes';
                //hide probes
                list.forEach(item => {
                    if (item.type == 'probe') {
                        item.marker.setVisible(false)
                    }
                })
            }
        });

    }

    function addKmlLayers(layers) {

        layers.forEach(layer => {
            if (layer.visible === false) {
                return;
            }
            const kmlLayer = new window.google.maps.KmlLayer({
                url: layer.url,
                key: uuid(),
                map: mapRef.current,
                preserveViewport: true,
                suppressInfoWindows: true,
            });
            kmlLayer.setMap(mapRef.current);
        })
    }


    function addStations(stations, visible) {

        const svgMarker = {
            path: "M27.648-41.399q0-3.816-2.7-6.516t-6.516-2.7-6.516 2.7-2.7 6.516 2.7 6.516 6.516 2.7 6.516-2.7 2.7-6.516zm9.216 0q0 3.924-1.188 6.444l-13.104 27.864q-.576 1.188-1.71 1.872t-2.43.684-2.43-.684-1.674-1.872l-13.14-27.864q-1.188-2.52-1.188-6.444 0-7.632 5.4-13.032t13.032-5.4 13.032 5.4 5.4 13.032z",
            fillColor: "blue",
            fillOpacity: 1,
            strokeWeight: 0,
            rotation: 0,
            scale: .8,
            anchor: new window.google.maps.Point(0, 0),
            labelOrigin: new window.google.maps.Point(15, 10)
        };
        stations.forEach(station => {
            if (mapRef.current && station.marker === undefined) {

                if (station.visible && station.archived === false) {


                    const marker = new window.google.maps.Marker({
                        position: { lat: station.lat, lng: station.lng },
                        map: mapRef.current,
                        icon: {
                            ...svgMarker,
                            fillColor: station.color,
                            strokeColor: station.exists == false ? 'yellow' : 'white',
                            strokeWeight: station.exists == false ? 2 : 0.5,

                        },


                        //label black text with white background
                        label: {
                            text: station.name,
                            color: 'white',
                            fontSize: '15px',
                            fontWeight: 'bold',
                            backgroundColor: 'white',
                            strokeColor: 'white',
                            strokeWeight: '4px',
                            padding: '20px',
                        },

                    });

                }









            }
        })
    }

    const KMLLayers = attributes.kmlLayers !== undefined ? attributes.kmlLayers : [];


    return (
        <div style={{ height: '100%', width: '100%', paddingBottom: 20 }}>

            <GoogleMap
                mapContainerStyle={{ height: mapHeight, width: '100%' }}
                center={center}
                onLoad={handleLoad}
                zoom={zoom}
                mapTypeId="satellite"
                onMouseOut={handleCenterChanged}
                controlSize={32}
                options={{
                    scaleControl: true,
                    //satellite view
                    mapTypeId: 'satellite',
                    //disable street view
                    streetViewControl: false,
                    //disable zoom control

                    rotateControl: false,
                    //disable fullscreen control
                    fullscreenControl: false,

                    tilt: 0,
                    //disable keyboard shortcuts


                }}


            >


                <DrawingManager
                    onLoad={onDMLoad}
                    onPolygonComplete={onPolygonComplete}
                    options={{
                        drawingControl: true,
                        drawingControlOptions: {
                            position: window.google.maps.ControlPosition.BOTTOM_LEFT,

                        },
                        polygonOptions: {
                            fillColor: '#ffff00',
                            fillOpacity: .3,
                            strokeWeight: 5,
                            clickable: true,
                            editable: true,
                            zIndex: 1,
                            draggable: true,

                            deleteable: true,
                        },
                    }}

                />






                {/*   <HeatmapLayer

                    data={[
                        new window.google.maps.LatLng(center.lat, center.lng),
                        new window.google.maps.LatLng(center.lat, center.lng),
                        new window.google.maps.LatLng(center.lat, center.lng),
                        new window.google.maps.LatLng(center.lat, center.lng),
                        new window.google.maps.LatLng(center.lat, center.lng),
                        new window.google.maps.LatLng(center.lat, center.lng),

                    ]}
                    options={{
                        radius: 20,
                    }}
                /> */}

                <>
                    <StandaloneSearchBox
                        onPlacesChanged={onPlacesChanged}
                        onLoad={onSBLoad}
                    >
                        <input
                            type="text"
                            placeholder="Search Places"
                            style={{
                                boxSizing: 'border-box',
                                border: `1px solid transparent`,
                                width: `270px`,
                                height: `40px`,
                                padding: `0 12px`,
                                borderRadius: `3px`,
                                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                fontSize: `14px`,
                                outline: `none`,
                                margin: 'center',
                                textOverflow: `ellipses`,
                                position: 'absolute',
                                top: '0px',
                                marginLeft: '50%'
                            }}
                        />
                    </StandaloneSearchBox>
                </>
            </GoogleMap>

        </div >
    )


}
export default WidgetMapGoogle;