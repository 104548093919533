import { Button, Divider, Grid, IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import { Col, Row } from 'antd';
import uuid from 'react-uuid';
import { doc, getFirestore, setDoc } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '1px solid rgba(0, 0, 0, .3)',
    boxShadow: 24,
    p: 4,
    borderRadius: '24px',
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
};

const CodesModal = ({
    codesModal,
    setCodesModal,
    codes,
    setCodes,
    openSnack,
    userInfo




}) => {

    const handleOpen = () => setCodesModal(true);
    const handleClose = () => setCodesModal(false);
    const [searchQuery, setSearchQuery] = useState('' || null)
    const [filteredCodes, setFilteredCodes] = useState(codes || null)
    const [newCode, setNewCode] = useState('');
    const [description, setDescription] = useState('' || null)
    const [onHover, setOnHover] = useState(null);

    useEffect(() => {
        setFilteredCodes(codes);
    }, [codes, codesModal]);









    return (
        <div>
            <Modal
                open={codesModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{

                }}

                slotProps={{
                    backdrop: {
                        style: {
                            backgroundColor: 'transparent'
                        }
                    }
                }}
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" fontWeight={700} fontSize={28} component="h2">
                        Codes editor
                    </Typography>
                    <Typography id="modal-modal-description" variant="caption" fontSize={13} sx={{ mt: 2 }}>
                        Make changes to your codes list.
                    </Typography>

                    <Typography sx={{ mt: 2, mb: 2 }} id="modal-modal-title" variant="body1" fontWeight={700} fontSize={14} component="h2">
                        Create new code
                    </Typography>


                    <Box>
                        <form
                            onSubmit={async (e) => {
                                e.preventDefault();  // Prevent form from reloading the page

                                if (newCode === '' || description === '' || newCode === null || description === null) {
                                    openSnack('error', 'Please fill all fields, code and description');
                                    return;
                                }



                                const newCodeItem = {
                                    company: userInfo.currentCompany,
                                    account: userInfo.currentAccount,
                                    key: uuid(),
                                    archived: false,
                                    name: newCode,
                                    label: newCode,
                                    description: description,
                                }
                                const updatedCodes = [newCodeItem, ...codes];
                                //check if code already exists
                                const exists = codes.find((param) => param.label.toLowerCase() === newCode.toLowerCase());
                                if (exists) {
                                    openSnack('error', 'Code already exists');
                                    return;
                                }
                                const db = getFirestore();
                                const docRef = doc(db, "clients", userInfo.currentCompany, 'codes', newCodeItem.key);
                                await setDoc(docRef, newCodeItem);
                                openSnack('success', 'Code added successfully');

                                setCodes(updatedCodes);
                                setNewCode('');  // Clear the input field
                                setDescription('');  // Clear the input field
                            }}
                        >
                            <Row align="middle" gutter={[16, 16]}>
                                <Col span={6}>
                                    <Typography id="modal-modal-title" variant="body1" fontWeight={500} fontSize={14} component="h2">
                                        Code
                                    </Typography>
                                </Col>
                                <Col span={18}>
                                    <OutlinedInput
                                        fullWidth
                                        size="small"
                                        value={newCode}
                                        placeholder="Add new code"
                                        onChange={(e) => {
                                            setNewCode(e.target.value);
                                        }}
                                    />
                                </Col>

                                <Col span={6}>
                                    <Typography id="modal-modal-title" variant="body1" fontWeight={500} fontSize={14} component="h2">
                                        Description
                                    </Typography>
                                </Col>
                                <Col span={18}>
                                    <OutlinedInput
                                        fullWidth
                                        size="small"
                                        value={description}
                                        placeholder="Add new description"
                                        onChange={(e) => {
                                            setDescription(e.target.value);
                                        }}
                                    />
                                </Col>

                                <Col span={24}>
                                    <Button
                                        fullWidth
                                      
                                        type="submit"
                                        style={{
                                            textTransform: 'none',
                                            backgroundColor: newCode === '' || description === '' || newCode === null || description === null ? '#f2f4f7' : '#3f51b5',
                                            color: newCode === '' || description === '' || newCode === null || description === null ? '#a6a6a6' : 'white',
                                            borderRadius: '5px',
                                        }}
                                     

                                    >
                                        Add
                                    </Button>
                                </Col>
                            </Row>
                        </form>
                    </Box>




                    <Typography sx={{ mt: 2 }} id="modal-modal-title" variant="body1" fontWeight={600} fontSize={14} component="h2">
                        Codes list
                    </Typography>

                    <Box
                        sx={{
                            mb: 0,
                            pb: 0,
                            px: 1
                        }}
                        style={{
                            borderTop: '1px solid #e0e0e0',
                            borderLeft: '1px solid #e0e0e0',
                            borderRight: '1px solid #e0e0e0',
                            borderRadius: '5px',
                        }}

                    >

                        <OutlinedInput
                            sx={{
                                mt: 1,
                                mb: 1,
                                width: '100%',
                                height: '40px',
                                borderRadius: 8
                            }}
                            value={searchQuery}
                            startAdornment={
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            }
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        size='small'
                                        aria-label="toggle password visibility"

                                        onClick={() => {
                                            setFilteredCodes(codes);
                                            setSearchQuery('');
                                        }}
                                    >
                                        X
                                    </IconButton>
                                </InputAdornment>
                            }


                            placeholder="Search codes"
                            onChange={(e) => {
                                setSearchQuery(e.target.value);
                                if (e.target.value === '') {
                                    setFilteredCodes(codes);
                                    return;
                                }
                                const filteredCodes = codes.filter((param) => {
                                    return param.label.toLowerCase().includes(e.target.value.toLowerCase());
                                });
                                setFilteredCodes(filteredCodes);
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            mt: 0
                        }}

                        style={{
                            height: '300px',
                            overflowY: 'scroll',
                            border: '1px solid #e0e0e0',
                            borderTop: 'none',
                            borderRadius: '5px',

                        }}>


                        {filteredCodes?.map((param, index) => {
                            return (
                                <Row key={param.key}
                                    style={{
                                        cursor: 'pointer',
                                        padding: 4
                                    }}
                                    onClick={() => {
                                        navigator.clipboard.writeText(param.label);
                                        openSnack('success', 'Copied to clipboard');
                                    }}

                                    onMouseEnter={(e) => {
                                        setOnHover(param.key)
                                        e.currentTarget.style.backgroundColor = '#f0f0f0'; // On mouse enter, change background color
                                    }}
                                    onMouseLeave={(e) => {
                                        setOnHover(null)
                                        e.currentTarget.style.backgroundColor = 'transparent'; // On mouse leave, reset background color
                                    }}>
                                    <Col span={24}>
                                        <Typography
                                            fontWeight={600}
                                            sx={{
                                                mt: 1,
                                                color: 'black',
                                                marginLeft: '1em' // Indent the warning text
                                            }}
                                            id="modal-modal-title"
                                            variant="body1"

                                            fontSize={14}
                                            component="span"
                                        >
                                            {param.label}

                                        </Typography>
                                        <Typography
                                            fontWeight={400}
                                            sx={{
                                                mt: 1,
                                                color: 'black',

                                            }}
                                            id="modal-modal-title"
                                            variant="caption"

                                            fontSize={14}
                                            component="span"
                                        >
                                            {param?.description && ` - ${param?.description}`}

                                        </Typography>
                                    </Col>

                                </Row>

                            );
                        })}
                    </Box>



                </Box>
            </Modal>
        </div >
    );
}

export default CodesModal;
