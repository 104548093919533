import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextField } from '@mui/material';

const SaveTemplateModal = ({
    saveModalOpen,
    setSaveModalOpen,
    templateValue,
    setTemplateValue,
    handleSaveTemplate,
}) => {
    return (
        <Dialog open={saveModalOpen} onClose={() => setSaveModalOpen(false)}>
            <DialogTitle>Template Name</DialogTitle>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSaveTemplate(templateValue);
                }}
            > 
                <DialogContent>
                    <DialogContentText>
                        Please enter a new name for the template:
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        placeholder="Template Name"
                        type="text"
                        fullWidth
                        defaultValue={templateValue}
                        onChange={(e) => setTemplateValue(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setSaveModalOpen(false)}>Cancel</Button>
                    <Button type="submit">Save</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default SaveTemplateModal;
