import { faPlus, faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumbs, Button, createTheme, ThemeProvider, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions } from '@mui/material';
import { Col, List, Popconfirm, Row } from 'antd';
import React, { useRef, useState, useEffect } from 'react';
import { addExistingStaff, checkEmail, sendInviteEmail } from './firestore';
import uuid from 'react-uuid';
import { doc, getFirestore, setDoc } from 'firebase/firestore';

const AddStaff = ({ userInfo, openSnack, getInfo }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const projectRef = useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [addStaffModal, setAddStaffModal] = useState(false);
    const [checkEmailModal, setCheckEmailModal] = useState(false);
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [company, setCompany] = useState('');
    const [loading, setLoading] = useState(false);



    useEffect(() => {
        //set anchorEl to projectRef
        setAnchorEl(projectRef.current)

    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading('inviting');

        // Email validation regex
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const lowerEmail = email.toLowerCase();

        // Check that email is valid, first name and last name are not empty, and company is not empty
        if (emailRegex.test(email) && firstName && lastName && company) {
            try {
                const user = {
                    accounts: [],
                    adminLevel: 'client',
                    companies: [userInfo.currentCompany],
                    company: company,
                    currentAccount: '',
                    currentCompany: userInfo.currentCompany,
                    displayName: '',
                    email: lowerEmail,
                    firstName: firstName || '',
                    initialCompany: userInfo.currentCompany,
                    invited: true,
                    lastName: lastName || '',
                    signedUp: false,
                    id: uuid().substring(0, 15),
                    addType: 'company'

                }
                const db = getFirestore();
                const docRef = doc(db, "users", user.id);
                await setDoc(docRef, user).then(async () => {
                    const accountRef = doc(db, "clients", userInfo.currentCompany, 'staff', user.id);
                    await setDoc(accountRef, { id: user.id, adminLevel: user.adminLevel })
                }).then(() => {
                    getInfo()
                }).then(async () => {
                    try {
                        await sendInviteEmail(user.id, userInfo.id)
                        setLoading(false);
                        openSnack('success', 'Email invitation sent');
                        setEmail('');
                        setFirstName('');
                        setLastName('');
                        setCompany('');
                        setAddStaffModal(false);
                    }
                    catch (error) {
                        console.log(error)
                        openSnack('error', 'Error sending email invitation');
                        setEmail('');
                        setFirstName('');
                        setLastName('');
                        setCompany('');
                        setAddStaffModal(false);
                    }


                })



            } catch (error) {
                console.error('Error inviting user:', error);
                setLoading(false);
                openSnack('error', 'Error inviting user');
            }
        } else {
            console.error('Invalid input');
            setLoading(false);
            openSnack('error', 'Please fill in all required fields with valid values');
        }
    }

    const handleEmailCheck = async (e) => {
        e.preventDefault();
        console.log('email check')

        // Email validation regex
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        //trim any possible spaces in front of email

        const trimmedEmail = email.trim();
        //lowercase email

        setEmail(trimmedEmail);


        if (emailRegex.test(trimmedEmail)) {
            setLoading('checkingEmail');

            try {
                const result = await checkEmail(trimmedEmail, userInfo.currentCompany)
                console.log(result)
                if (result === false) {
                    setCheckEmailModal(false);
                    setAddStaffModal(true);
                    setLoading(false);
                    openSnack('warning', 'Staff member not found, please create one.')
                }
                else if (result === 'exists') {
                    openSnack('warning', 'Staff member already exists in this account')
                    setCheckEmailModal(false);
                    setLoading(false);
                    setEmail('');
                }
                else {
                    try {
                        await addExistingStaff(result, userInfo.currentCompany)
                        openSnack('success', 'Staff member added')
                        getInfo()
                        setCheckEmailModal(false);
                        setLoading(false);
                        setEmail('');

                    }
                    catch (error) {
                        console.log(error)
                        openSnack('error', 'Error adding staff member')
                        setCheckEmailModal(false);
                        setLoading(false);
                    }
                }
            }
            catch (error) {
                console.log(error)
                openSnack('error', 'Error adding staff member')
                setCheckEmailModal(false);
                setLoading(false);
            }


        } else {
            console.error('Invalid email address');
            // You can display an error message or handle the invalid email case as needed
        }
    };



    return (
        <div>


            <Button
                ref={projectRef}
                size="large"
                style={{
                    textTransform: 'none',

                    backgroundColor: '#404050',
                }
                } variant="contained"
                onClick={() => setCheckEmailModal(true)}
            >
                <FontAwesomeIcon icon={faPlus} style={{ marginRight: 10 }} />  Add Staff
            </Button>

            <Dialog open={checkEmailModal} onClose={() => setCheckEmailModal(false)}>
                <DialogTitle>Add Staff</DialogTitle>
                <form onSubmit={(e) => handleEmailCheck(e, email)}>
                    <Row >
                        <Col span={24}>
                            <DialogContent style={{ paddingBottom: 0, paddingTop: 12 }}>
                                <DialogContentText style={{ paddingBottom: 0 }}>
                                    <Typography variant="body1" fontWeight={500} color="black">Email<span style={{ color: 'red' }}>*</span></Typography>
                                </DialogContentText>
                                <TextField
                                    autoFocus
                                    disabled={loading === 'checkingEmail'}
                                    style={{ width: 400 }}
                                    fullWidth
                                    size="small"
                                    margin="dense"
                                    id="email"
                                    type="email"
                                    variant="outlined"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />

                            </DialogContent>
                        </Col>
                    </Row>

                    <DialogActions style={{ paddingTop: 20 }}>
                        {loading !== 'checkingEmail' && (<Button
                            variant="link"
                            color="primary"
                            style={{
                                textTransform: "none",
                            }}

                            onClick={() => setCheckEmailModal(false)}
                        >Cancel</Button>)}

                        {loading !== 'checkingEmail' ?

                            <Button
                                variant="contained"
                                color="primary"
                                style={{
                                    backgroundColor: '#404050',
                                    textTransform: "none",
                                }}
                                type="submit"
                            >Check Email</Button>
                            :
                            <Button
                                variant="link"
                                color="primary"
                                style={{
                                    textTransform: "none",
                                }}

                            >
                                <FontAwesomeIcon icon={faSpinner} spin style={{ marginRight: 10 }} />  Checking Email
                            </Button>
                        }

                    </DialogActions>
                </form>
            </Dialog>













            <Dialog open={addStaffModal} onClose={() => setAddStaffModal(false)}>
                <DialogTitle>Add Staff</DialogTitle>
                <form
                    onSubmit={handleSubmit}
                >
                    <Row >
                        <Col span={24}>
                            <DialogContent style={{ paddingBottom: 0, paddingTop: 12 }}>
                                <DialogContentText style={{ paddingBottom: 0 }}>
                                    <Typography variant="body1" fontWeight={500} color="black">Email<span style={{ color: 'red' }}>*</span></Typography>

                                </DialogContentText>
                                <TextField

                                    disabled={true}
                                    size="small"
                                    fullWidth
                                    margin="dense"
                                    id="email"
                                    type="email"
                                    variant="outlined"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />

                            </DialogContent>
                        </Col>
                        <Col span={12}>
                            <DialogContent style={{ paddingBottom: 0, paddingTop: 12 }}>
                                <DialogContentText style={{ paddingBottom: 0 }}>
                                    <Typography variant="body1" fontWeight={500} color="black">First Name<span style={{ color: 'red' }}>*</span></Typography>
                                </DialogContentText>
                                <TextField
                                    autoFocus
                                    disabled={loading === 'checkingEmail'}

                                    size="small"
                                    fullWidth
                                    margin="dense"
                                    id="firstName"
                                    type="text"
                                    variant="outlined"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                />


                            </DialogContent>

                        </Col>
                        <Col span={12}>
                            <DialogContent style={{ paddingBottom: 0, paddingTop: 12 }}>
                                <DialogContentText style={{ paddingBottom: 0 }}>
                                    <Typography variant="body1" fontWeight={500} color="black">Last Name<span style={{ color: 'red' }}>*</span></Typography>
                                </DialogContentText>
                                <TextField

                                    disabled={loading === 'checkingEmail'}

                                    size="small"
                                    fullWidth
                                    margin="dense"
                                    id="lastName"
                                    type="text"
                                    variant="outlined"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                />

                            </DialogContent>

                        </Col>
                        <Col span={12}>
                            <DialogContent style={{ paddingBottom: 0, paddingTop: 12 }}>
                                <DialogContentText>
                                    <Typography variant="body1" fontWeight={500} color="black">Company<span style={{ color: 'red' }}>*</span></Typography>
                                </DialogContentText>
                                <TextField

                                    disabled={loading === 'checkingEmail'}

                                    size="small"
                                    fullWidth
                                    margin="dense"
                                    id="company"
                                    type="text"
                                    variant="outlined"
                                    value={company}
                                    onChange={(e) => setCompany(e.target.value)}
                                />

                            </DialogContent>

                        </Col>

                    </Row>











                    {loading === false && (
                        <DialogActions style={{ paddingTop: 20 }}>
                            <Button
                                variant="link"
                                color="primary"
                                style={{
                                    textTransform: "none",
                                }}

                                onClick={() => setAddStaffModal(false)}
                            >Cancel</Button>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{
                                    backgroundColor: '#404050',
                                    textTransform: "none",
                                }}

                                type="submit"
                            >Invite User</Button>

                        </DialogActions>
                    )}
                    {loading === 'inviting' && (
                        <DialogActions style={{ paddingTop: 20 }}>
                            <Button
                                variant="link"
                                color="primary"
                                disabled
                                style={{
                                    textTransform: "none",
                                }}


                            >Cancel</Button>
                            <Button
                                variant="link"
                                color="primary"
                                disabled
                                style={{
                                    backgroundColor: 'white',
                                    textTransform: "none",
                                }}


                            >
                                <FontAwesomeIcon icon={faSpinner} spin style={{ marginRight: 10 }} />  Inviting User

                            </Button>

                        </DialogActions>
                    )}
                </form>
            </Dialog>
        </div>

    );

};

export default AddStaff;
