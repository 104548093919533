import { faGear } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Autocomplete, Box, Checkbox, Collapse, Divider, IconButton, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material';
import Tab from '@mui/material/Tab';
import { Col, Popconfirm, Row } from 'antd';
import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';
import React from 'react';
import ProbeParameters from '../../../../extra/probeParameters.json';
import Units from '../../../../extra/units.json';
import WeatherParameters from '../../../../extra/weatherParameters.json';
import { queryParameters } from '../../../../firebase/config';
import DataColorCompact from './dataColorCompact';
import ParametersLoad from './parametersLoad';
import { fullMobile } from './util';
import NodeSelect from './NodeSelect';

var randomColor = require('randomcolor');
export default class ReadingSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            attributes: this.props.attributes,
            currentWidgetKey: this.props.currentWidgetKey,
            locations: [],
            references: [],
            parameters: [],
            sites: [],
            activeTab: 'data',
            readData: this.props.readData,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
        if (this.props.readData !== prevProps.readData) {
            this.setState({ readData: this.props.readData })
            if (this.props.readData === true) {
                this.props.updateAttributes()
                this.setState({ readData: false })
            }
        }

    }
    componentDidMount() {
        this.getLocations()
        this.getParameters();
    }

    getLocations = async () => {
        const { userInfo } = this.state;

        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'probes'), where('account', '==', userInfo.currentAccount), where('archived', '==', false));
        const queryDataSites = query(collection(db, "clients", userInfo.currentCompany, 'sampleSites'), where('account', '==', userInfo.currentAccount),);
        const queryStations = query(collection(db, "clients", userInfo.currentCompany, 'stations'), where('account', '==', userInfo.currentAccount),);
        const locations = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            locations.push({ ...doc.data(), label: doc.data().name, type: 'probe' })
        });
        const snapSites = await getDocs(queryDataSites);
        snapSites.forEach((doc) => {
            locations.push({ ...doc.data(), label: doc.data().name, type: 'sample' })
        });

        const snapNodes = await getDocs(queryStations);
        snapNodes.forEach((doc) => {
            if (doc.data().archived !== true && doc.data().children.length > 0) {
                const children = doc.data().children;
                //for each child create a node and add to locations
                console.log(children)
                children.forEach((child) => {

                    locations.push({ ...child, stationLabel: doc.data().name, label: child.name, type: 'node', stationType: child.type })
                })

            }

        });

        locations.sort((a, b) => a.name.localeCompare(b.name))
        this.setState({ locations, })

    }


    updateWidget = (boo, val) => {
        const { attributes, currentWidgetKey } = this.state;
        attributes[boo] = val;
        this.setState({ attributes })


    }

    updateData = (index, boo, val) => {
        const { attributes } = this.state;
        attributes.chartData[index][boo] = val;
        this.updateWidget(attributes)

    }

    getParameters = async () => {
        const { userInfo, type } = this.state;
        const db = getFirestore();
        const parameters = await queryParameters(userInfo)
        parameters.sort((a, b) => a.name.localeCompare(b.name))
        this.setState({ parameters, })


    }

    handleCheck = (boo, val,) => {
        // Clears running timer and starts a new one each time the user types
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.toggleCheck(boo, val,);
        }, 800);
    }

    toggleCheck = async (boo, val) => {
        this.updateWidget(boo, val)



    }

    render() {

        const { account, attributes, screenWidth, locations, expanded, activeTab, references, currentWidgetKey, parameters, readData, userInfo } = this.state;

        const DeleteItem = ({ row, type }) => {
            return (
                <Popconfirm
                    title={`Are you sure to delete this ${type}?`}
                    onConfirm={async () => {

                        if (type === 'axises') {
                            const checkSeries = attributes.chartData.filter((s) => s.yAxis === row.axisId);
                            if (checkSeries.length > 0) {
                                this.props.openSnack('error', `Cannot delete, a series is associated to this axis.`)
                            }
                            else {
                                this.props.openSnack('success', `${type === 'axises' ? "Axes" : "Data"} deleted.`)
                                const newData = attributes[type].filter((f) => f.key !== row.key);
                                attributes[type] = newData;
                                this.props.updateAttributes(attributes, currentWidgetKey)
                                this.setState({ attributes })
                            }
                        }
                        else {
                            this.props.openSnack('success', `${type === 'axises' ? "Axes" : "Data"} deleted.`)
                            const newData = attributes[type].filter((f) => f.key !== row.key);
                            attributes[type] = newData;
                            this.props.updateAttributes(attributes, currentWidgetKey)
                            this.setState({ attributes })
                        }




                    }}
                    okText="Yes"
                    cancelText="No"
                >
                    <IconButton style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="small"
                    >
                        <DeleteForeverIcon />
                    </IconButton>
                </Popconfirm>


            )
        }


        const PPArray = Object.values(ProbeParameters).map((p, i) => {
            return ({
                label: p,
                key: Object.keys(ProbeParameters)[i]
            })
        });

        const WPArray = Object.values(WeatherParameters).map((p, i) => {
            return ({
                label: p,
                key: Object.keys(WeatherParameters)[i]
            })
        });
        const UnitsArray = Object.values(Units).map((p, i) => {
            return ({
                label: p,
                key: Object.keys(Units)[i]
            })
        });

        const input = attributes.decimals === 0 || attributes.decimals ? attributes.decimals : '';


        return (











            <Row align="middle">
                <Col span={24}>
                    <Typography fontSize={16} variant='subtitle2'>Reading Settings</Typography>
                </Col>

                <Col {...fullMobile(8, 12)}>
                    <Row>
                        <Col span={24}>
                            <Typography fontSize={13} variant="body1">Time Frame</Typography>
                        </Col>
                        <Col span={24}>
                            <Select size="small" fullWidth
                                notched={false}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={attributes.timeFrame || ''}
                                label="Layer"
                                onChange={(e) => {
                                    this.updateWidget('timeFrame', e.target.value)
                                }}

                            >
                                <MenuItem value={'days'}>Latest Day</MenuItem>
                                <MenuItem value={'weeks'}>Latest Week</MenuItem>
                                <MenuItem value={'months'}>Latest Month</MenuItem>

                            </Select>
                        </Col>
                    </Row>


                </Col>
                <Col {...fullMobile(5, 12)}>
                    <Row>
                        <Col span={24}>
                            <Typography fontSize={13} variant="body1">Show Chart</Typography>
                        </Col>
                        <Col span={24}>
                            <Checkbox checked={attributes.showChart || false} onChange={(e) => {
                                this.updateWidget('showChart', e.target.checked)
                            }} />
                        </Col>
                    </Row>


                </Col>
                <Col {...fullMobile(5, 12)}>
                    <Row>
                        <Col span={24}>
                            <Typography fontSize={13} variant="body1">Get Week Data</Typography>
                        </Col>
                        <Col span={24}>
                            <Checkbox checked={attributes.getHistory || false} onChange={(e) => {
                                this.updateWidget('getHistory', e.target.checked)
                            }} />
                        </Col>
                    </Row>


                </Col>
                <Col {...fullMobile(5, 12)}>
                    <Row>
                        <Col span={24}>
                            <Typography fontSize={13} variant="body1">End Today</Typography>
                        </Col>
                        <Col span={24}>
                            <Checkbox checked={attributes.endToday}
                                onChange={(e) => {
                                    this.updateWidget('endToday', e.target.checked)

                                }} />
                        </Col>
                    </Row>


                </Col>
                {attributes.showChart && (<Col {...fullMobile(8, 12)}>
                    <Row>
                        <Col span={24}>
                            <Typography fontSize={13} variant="body1">Line Width</Typography>
                        </Col>
                        <Col span={24}>
                            <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                defaultValue={attributes.lineWidth || 2}
                                type="number"
                                inputProps={{ min: 0 }}
                                onChange={(e) => {
                                    this.handleCheck('lineWidth', e.target.value === "" ? "" : Number(e.target.value))
                                }} />
                        </Col>
                    </Row>


                </Col>)}

                {attributes.showChart && (<Col {...fullMobile(8, 12)}>
                    <Row>
                        <Col span={24}>
                            <Typography fontSize={13} variant="body1">Chart Height</Typography>
                        </Col>
                        <Col span={24}>
                            <Select size="small" fullWidth
                                notched={false}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={attributes.chartHeight || '175'}
                                label="Layer"
                                onChange={(e) => {
                                    this.updateWidget('chartHeight', e.target.value)
                                }}

                            >
                                <MenuItem value={'100'}>xs</MenuItem>
                                <MenuItem value={'175'}>sm</MenuItem>
                                <MenuItem value={'250'}>md</MenuItem>
                                <MenuItem value={'325'}>lg</MenuItem>
                                <MenuItem value={'400'}>xl</MenuItem>

                            </Select>
                        </Col>
                    </Row>


                </Col>)}
                <Row style={{ paddingTop: 5 }}>


                    <Col {...fullMobile(8, 12)}>
                        <Row>
                            <Col span={24}>
                                <Typography fontSize={13} variant="body1">Time Offset</Typography>
                            </Col>
                            <Col span={24}>
                                <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                    defaultValue={attributes.timeOffset || 0}
                                    type="number"
                                    inputProps={{ min: 0 }}
                                    onChange={(e) => {
                                        this.handleCheck('timeOffset', e.target.value === "" ? "" : Number(e.target.value))
                                    }} />

                            </Col>
                        </Row>


                    </Col>
                    <Col {...fullMobile(8, 12)}>
                        <Row>
                            <Col span={24}>
                                <Typography fontSize={12} variant="body1">Y-Axis Start at Zero</Typography>
                            </Col>
                            <Col span={24}>
                                <Checkbox checked={attributes.startAtZero || false} onChange={(e) => {
                                    this.updateWidget('startAtZero', e.target.checked)
                                }} />
                            </Col>
                        </Row>


                    </Col>
                </Row>





                <Col span={24}>
                    <TabContext value={activeTab}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={(e, tab) => this.setState({ activeTab: tab })} aria-label="lab API tabs example">
                                <Tab style={{ textTransform: 'none' }} label="Data" value="data" />

                            </TabList>
                        </Box>

                        <TabPanel value="data">
                            <Col xs={24} style={{ paddingTop: 4 }} >
                                <Row align="middle" gutter={[12, 12]}>



                                    <Col span={20}>
                                        <Typography fontSize={14} fontWeight={500} variant="body1">Data</Typography>
                                    </Col>
                                    <Col {...fullMobile(4, 4)} >
                                        <IconButton onClick={() => {
                                            this.updateWidget('advancedSettings', attributes.advancedSettings ? !attributes.advancedSettings : true)
                                        }}>
                                            <FontAwesomeIcon fontSize={18} icon={faGear} color="#9ec1c9" />
                                        </IconButton>
                                    </Col>
                                    <Col {...fullMobile(8, 12)}>
                                        <Typography fontSize={13} fontWeight={600} variant="body1">Data Type<span style={{ color: 'red' }}>*</span></Typography>

                                        <Select size="small" fullWidth
                                            notched={false}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={attributes.dataType || ''}
                                            label="Layer"
                                            onChange={(e) => {
                                                this.updateWidget('dataType', e.target.value)
                                            }}

                                        >
                                            <MenuItem value={'node'}>Node/Station</MenuItem>
                                            <MenuItem value={'weather'}>Weather</MenuItem>

                                        </Select>


                                    </Col>
                                    <Col {...fullMobile(8, 12)}>
                                        <Typography fontSize={13} fontWeight={600} variant="body1">Node/Location<span style={{ color: 'red' }}>*</span></Typography>
                                        <NodeSelect
                                            dataType={attributes.dataType}
                                            updateData={(a, b, c) => {
                                                this.updateWidget(b, c)
                                            }}
                                            index={'none'}
                                            locations={locations}
                                            location={attributes.location}
                                        />


                                    </Col>

                                    <Col {...fullMobile(8, 12)}>
                                        <Typography fontSize={13} fontWeight={600} variant="body1">Parameter<span style={{ color: 'red' }}>*</span></Typography>
                                        <ParametersLoad userInfo={userInfo} nodeid={attributes.locationid} dataType={attributes.dataType} parameterid={attributes.parameterid}
                                            updateData={(boo, val) => {
                                                this.updateWidget(boo, val)

                                            }} />


                                    </Col>



                                    <Col span={24}>
                                        <Row align="middle" gutter={[8, 8]} style={{ paddingBottom: 5 }} key={attributes.key}>


                                            <Col {...fullMobile(8, 12)}>
                                                <Row>
                                                    <Col span={24}>
                                                        <Typography fontSize={13} fontWeight={600} variant="body1">Label</Typography>
                                                    </Col>
                                                    <Col span={24}>
                                                        <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                            defaultValue={attributes.label}
                                                            onChange={(e) => {
                                                                this.handleCheck('label', e.target.value)
                                                            }} />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col {...fullMobile(8, 12)}>
                                                <Row>
                                                    <Col span={24}>
                                                        <Typography fontSize={13} fontWeight={600} variant="body1">Units</Typography>
                                                    </Col>
                                                    <Col span={24}>
                                                        <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                            defaultValue={attributes.units}
                                                            onChange={(e) => {
                                                                this.handleCheck('units', e.target.value)
                                                            }} />
                                                    </Col>
                                                </Row>
                                            </Col>





                                            <Col {...fullMobile(3, 6)}>
                                                <Row>
                                                    <Col span={24}>
                                                        <Typography fontSize={13} fontWeight={600} variant="body1">Color</Typography>
                                                    </Col>
                                                    <Col span={24}>
                                                        <DataColorCompact color={attributes.color} updateData={(boo, val) => this.updateWidget(boo, val)} />
                                                    </Col>
                                                </Row>


                                            </Col>

                                            <Col {...fullMobile(8, 12)}>
                                                <Row>
                                                    <Col span={24} >
                                                        <Typography fontSize={13} variant="body1">Data Source</Typography>
                                                    </Col>
                                                    <Col span={24} >
                                                        <Select size="small" fullWidth
                                                            notched={false}
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={attributes?.dataSourceType || 'reading'}
                                                            style={{
                                                                backgroundColor: 'white'
                                                            }}
                                                            label="Dash"
                                                            onChange={(e) => {
                                                                this.handleCheck('dataSourceType', e.target.value)
                                                            }}

                                                        >
                                                            <MenuItem value={'reading'}>Reading</MenuItem>
                                                            <MenuItem value={'readingWithOffset'}>Reading w/ offset</MenuItem>
                                                            <MenuItem value={'offset'}>Offset only</MenuItem>



                                                        </Select>
                                                    </Col>
                                                </Row>
                                            </Col>





                                            <Col {...fullMobile(22)}>
                                                <Collapse in={attributes.advancedSettings || false} timeout="auto" unmountOnExit>
                                                    <Row align="middle" gutter={[8, 8]}>
                                                        <Col span={24} style={{ paddingTop: 8 }}>
                                                            <Typography fontSize={14} fontWeight={500} variant="body1">Advanced</Typography>
                                                        </Col>

                                                        <Col {...fullMobile(8, 12)}>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <Typography fontSize={13} variant="body1">Unit Conversion</Typography>
                                                                </Col>
                                                                <Col span={24}>
                                                                    <Select size="small" fullWidth
                                                                        notched={false}
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        value={attributes.units || ''}
                                                                        label="Units"
                                                                        onChange={(e) => {
                                                                            this.updateWidget('units', e.target.value)
                                                                        }}

                                                                    >

                                                                        {attributes.parameterid === '1' && (
                                                                            ['C', 'F'].map((f) => {
                                                                                return (
                                                                                    <MenuItem value={f}>{f}</MenuItem>
                                                                                )
                                                                            })
                                                                        )}
                                                                        {attributes.parameterid === '3' && (
                                                                            ['m', 'ft'].map((f) => {
                                                                                return (
                                                                                    <MenuItem value={f}>{f}</MenuItem>
                                                                                )
                                                                            })
                                                                        )}



                                                                    </Select>
                                                                </Col>
                                                            </Row>
                                                        </Col>




                                                        <Col {...fullMobile(8, 12)}>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <Typography fontSize={13} variant="body1">Dec. Places</Typography>
                                                                </Col>
                                                                <Col span={24}>
                                                                    <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                                        id={`123value`}
                                                                        defaultValue={input}
                                                                        type="number"
                                                                        onChange={(e) => {

                                                                            this.handleCheck('decimals', e.target.value === "" ? Number(0) : e.target.value === "0" ? Number(0) : Number(e.target.value))
                                                                        }} />
                                                                </Col>
                                                            </Row>
                                                        </Col>

                                                        <Col {...fullMobile(8, 12)}>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <Typography fontSize={13} variant="body1">Offset</Typography>
                                                                </Col>
                                                                <Col span={24}>
                                                                    <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                                        value={attributes.offset || ''}
                                                                        type="number"
                                                                        onChange={(e) => {
                                                                            this.updateWidget('offset', e.target.value === "" ? "" : Number(e.target.value))
                                                                        }} />
                                                                </Col>
                                                            </Row>
                                                        </Col>







                                                    </Row>
                                                </Collapse>
                                            </Col>

                                            <Col span={24}>
                                                <Divider />
                                            </Col>


                                        </Row>
                                    </Col>
                                </Row>
                            </Col ></TabPanel>

                    </TabContext>
                </Col>







            </Row >
        );
    }
} 