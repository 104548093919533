import { faComment, faComments, faFile, faFileLines, faQuestionCircle, faUser } from '@fortawesome/free-regular-svg-icons';
import { faArrowRight, faBars, faChartLine, faDatabase, faGauge, faGear, faMoneyBill, faPeopleGroup, faPlus, faQuestion, faSignOut, faSort, faTable } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popper, Typography, Button, Box, Divider, ClickAwayListener, Paper, OutlinedInput, List, Menu, ListItem, Avatar } from '@mui/material';
import { Col, Row } from 'antd';
import { getAuth, signOut } from 'firebase/auth';
import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { fullMobile } from '../extra/util';
import './styles.css';
import { collection, doc, getFirestore, onSnapshot, query, updateDoc, where } from 'firebase/firestore';




export default function AccountButton({ openSnack, userRef, companyRef, accountRef, type, updateCurrentAccount }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [footerAnchorEl, setFooterAnchorEl] = useState(null);
    const [projectSearch, setProjectSearch] = useState('');
    const projectSearchRef = useRef(null);
    const projectRef = useRef(null);
    const footerRef = useRef(null);
    const [accounts, setAccounts] = useState([]);
    const [filterData, setFilterData] = useState([]);

    const [account, setAccount] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    const [company, setCompany] = useState(null);

    const [isOpen, setIsOpen] = useState(false);
    const [isFooterOpen, setIsFooterOpen] = useState(false);


    useEffect(() => {
        if (companyRef !== undefined) {
            setCompany(companyRef)
            getInfo(userRef)
        }
    }, [companyRef])
    useEffect(() => {
        if (accountRef !== undefined) {
            setAccount(accountRef)
        }
    }, [accountRef])
    useEffect(() => {
        if (userRef !== undefined) {
            setUserInfo(userRef)
            getInfo(userRef)
        }
    }, [userRef])

    useEffect(() => {
        getInfo(userRef)
    }, [])

    async function getInfo(userInfo) {


        const db = getFirestore();


        const q = query(collection(db, "clients", userInfo.currentCompany, 'accounts'), where('archived', '==', false));
        const qPublic = query(collection(db, "clients", userInfo.currentCompany, 'accounts'), where('archived', '==', false), where('publicAccount', '==', true));

        const unsubscribe = onSnapshot(type === 'publice' ? qPublic : q, (snap) => {
            const data = [];
            snap.forEach((doc) => {

                if (type === 'public') {
                    data.push({
                        accountName: doc.data().accountName,
                        key: doc.data().key,
                        type: doc.data().seasonal || null,
                        city: doc.data().city || null,
                        countyName: doc.data().countyName || null,
                        state: doc.data().state || null,
                        routeDay: doc.data().routeDay || null,
                        routeName: doc.data().routeName || null,
                        visible: true
                    })
                }
                else {
                    if (["EuEZ93ByFZYs8bSUXoYsTt1zu0m2", 'eTevYsI9mQMxhtntd95HmRGasNx2', 'PQWFmTLCP9XqafR9ogNI3Fg1sH43', 'z4y8XVrxYGT7rO4R01Y8tQ2F5z83', 't9bOfGDqwDaQTYV6i008opscpjz2'].includes(userInfo.id)) {
                        data.push({
                            accountName: doc.data().accountName,
                            key: doc.data().key,
                            type: doc.data().seasonal || null,
                            city: doc.data().city || null,
                            countyName: doc.data().countyName || null,
                            state: doc.data().state || null,
                            routeDay: doc.data().routeDay || null,
                            routeName: doc.data().routeName || null,
                            visible: true
                        })
                    }
                    else {
                        if (userInfo.accounts.includes(doc.data().key)) {
                            data.push({
                                accountName: doc.data().accountName,
                                key: doc.data().key,
                                type: doc.data().seasonal || null,
                                city: doc.data().city || null,
                                countyName: doc.data().countyName || null,
                                state: doc.data().state || null,
                                routeDay: doc.data().routeDay || null,
                                routeName: doc.data().routeName || null,
                                visible: true
                            })
                        }
                    }
                }

            });
            data.sort((a, b) => a.accountName.localeCompare(b.accountName))
            setAccounts(data);
            setFilterData(data);

        });




    }


    const handleClick = (event) => {
        setAnchorEl(anchorEl === null ? event.currentTarget : null);
    };
    const handleClose = (type) => {
        setAnchorEl(null);
    };

    useEffect(() => {
        //set anchorEl to projectRef
        setAnchorEl(projectRef.current)
        setFooterAnchorEl(footerRef.current)
    }, [])


    let navigate = useNavigate();
    let location = useLocation();
    const db = getFirestore();
    const companyType = company === undefined ? undefined : company?.companyTypeKey !== undefined ? company?.companyTypeKey : company?.companyType !== undefined ? company?.companyType : undefined;



    return (
        <Row   >


            <Col  {...fullMobile(24)} style={{ position: 'relative', textAlign: 'left', marginTop: 10, marginBottom: 10 }}

            >

                <div className='sidebar-header'
                    ref={projectRef}
                    style={{
                        border: '1px solid black',
                        textTransform: 'none',
                        borderRadius: 12,
                        paddingTop: 8,
                        paddingBottom: 8,
                        padding: 20,


                        //background is a gradient from top to bottom from rgba(0,0,0,0.1) to rgba(0,0,0,0.2)





                    }}

                    fullWidth={false}
                    onClick={(e) => {
                        setIsOpen(!isOpen)

                        //set projectSearchRef to focus

                        //this is not working to focus on the input any ideas?
                        setTimeout(() => {
                            projectSearchRef?.current?.focus()
                        }, 250);









                    }}


                >
                    <Row align="middle">
                        <Col span={20}>
                            <Typography variant="body1" fontWeight={400} fontSize={13} style={{ textAlign: 'left', }}>
                                {companyType === 'district' ? 'Current Site' :
                                    companyType === 'municipality' ? 'Current Site' :
                                        companyType === 'citizenScientist' ? 'Current Site' : 'Current Site'}
                            </Typography>
                            <Typography variant="h4" fontWeight={600} fontSize={16} style={{ textAlign: 'left', }}>
                                {account?.accountName || ''}
                            </Typography>
                        </Col>
                        <Col span={4} style={{ textAlign: 'right' }}>
                            <FontAwesomeIcon icon={faSort} size='2xs' color='#404050' />
                        </Col>
                    </Row>





                </div>
                {isOpen && (
                    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
                        <Popper
                            id={'popper'}
                            open={isOpen}
                            anchorEl={anchorEl}

                            style={{ zIndex: 1000, width: 240 }}
                        >
                            <Box sx={{
                                p: 0, bgcolor: 'background.paper',
                                borderRadius: 2,
                                border: '1px solid #e0e0e0',
                                boxShadow: 1,

                            }}>
                                <Row style={{}}>
                                    <Col span={24} style={{ textAlign: 'center', padding: 8 }}>
                                        <OutlinedInput
                                            sx={{
                                                //height to 40px
                                                height: 40,
                                            }}
                                            size="small"
                                            placeholder="Search Sites"
                                            style={{ borderRadius: 12, padding: 10, fontSize: 14, }}
                                            value={projectSearch}

                                            onChange={(e) => {
                                                //if e.target.value is empty, set filterData to data
                                                setProjectSearch(e.target.value)
                                                if (e.target.value === '') {
                                                    setFilterData(accounts)
                                                } else {
                                                    //use regex to filter data
                                                    let filtered = accounts.filter((item) => {
                                                        let regex = new RegExp(e.target.value, 'gi')
                                                        return item.accountName.match(regex)
                                                    })
                                                    setFilterData(filtered)
                                                }

                                            }}

                                            inputRef={projectSearchRef}

                                        />

                                    </Col>
                                    <Col span={24} style={{ textAlign: 'center', }}>

                                        <List sx={{ width: 240 }}>
                                            {//list only the first 5 items of account and map them
                                                filterData.slice(0, 5).map((item, index) => {
                                                    return (
                                                        <ListItem button sx={{
                                                            borderBottom: '1px solid #e0e0e0',
                                                            height: 40,
                                                        }}
                                                            onClick={async () => {
                                                                const userRef = doc(db, "users", userInfo.id);
                                                                await updateDoc(userRef, {
                                                                    currentAccount: item.key
                                                                }).then(() => {
                                                                    //update the current account
                                                                    setAccount(item)
                                                                    setIsOpen(false)
                                                                    updateCurrentAccount(item.key)

                                                                    //if location is not /account then navigate to /account, otherwise reload the page
                                                                    if (location.pathname !== '/account') {
                                                                        navigate('/account')
                                                                    } else {
                                                                        navigate('/loading')
                                                                        setTimeout(() => {
                                                                            navigate('/account')
                                                                        }, 100);
                                                                    }



                                                                    console.log(item)
                                                                }).catch((error) => {
                                                                    console.log(error)
                                                                })

                                                            }}

                                                        >
                                                            <Typography variant="body1" fontWeight={400} fontSize={14} style={{ textAlign: 'left', }}>
                                                                {item?.accountName || ''}
                                                            </Typography>
                                                        </ListItem>
                                                    )
                                                })
                                            }

                                            <ListItem button sx={{
                                                borderBottom: '1px solid #e0e0e0',
                                                height: 40,
                                            }}

                                                onClick={() => {
                                                    navigate('/')
                                                    setIsOpen(false)
                                                }}


                                            >
                                                <Typography variant="body1" fontWeight={500} fontSize={14} style={{ textAlign: 'left', }}>
                                                    See all sites <FontAwesomeIcon icon={faArrowRight} size="lg" style={{ marginLeft: 5 }} />
                                                </Typography>
                                            </ListItem>
                                            <ListItem button

                                                onClick={() => {
                                                    navigate('/createSite')
                                                    setIsOpen(false)
                                                }}


                                            >
                                                <Typography variant="body1" fontWeight={500} fontSize={14} style={{ textAlign: 'left', }}>
                                                    <FontAwesomeIcon icon={faPlus} size="lg" style={{ marginRight: 5 }} /> Add Site
                                                </Typography>
                                            </ListItem>

                                        </List>

                                    </Col>
                                </Row>
                            </Box>
                        </Popper>
                    </ClickAwayListener>
                )}

            </Col>


        </Row >
    )




}
