'use strict';

import React, {
    useCallback,
    useMemo,
    useRef,
    useState,
    StrictMode,
} from 'react';
import { createRoot } from 'react-dom/client';
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import './styles.css';

const GridExample = () => {
    const gridRef = useRef();
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const [rowData, setRowData] = useState();
    const [columnDefs, setColumnDefs] = useState([
        { field: 'athlete', width: 150, suppressSizeToFit: true },
        { field: 'age', width: 50, maxWidth: 50 },
        { colId: 'country', field: 'country', maxWidth: 300 },
        { field: 'year', width: 90 },
        { field: 'sport', width: 110 },
        { field: 'gold', width: 100 },
    ]);
    const autoSizeStrategy = useMemo(() => {
        return {
            type: 'fitGridWidth',
            defaultMinWidth: 100,
            columnLimits: [
                {
                    colId: 'country',
                    minWidth: 900,
                },
            ],
        };
    }, []);

    const onGridReady = useCallback((params) => {
        fetch('https://www.ag-grid.com/example-assets/small-olympic-winners.json')
            .then((resp) => resp.json())
            .then((data) => setRowData(data));
    }, []);

    const sizeToFit = useCallback(() => {
        gridRef.current.api.sizeColumnsToFit({
            defaultMinWidth: 100,
            columnLimits: [{ key: 'country', minWidth: 900 }],
        });
    }, []);

    console.log(rowData)

    return (
        <div style={containerStyle}>
            <div className="outer-div">
                <div className="button-bar">
                    <button onClick={sizeToFit}>Resize Columns to Fit Grid Width</button>
                </div>
                <div className="grid-wrapper">
                    <div
                        style={gridStyle}
                        className={
                            "ag-theme-quartz"
                        }
                    >
                        <AgGridReact
                            ref={gridRef}
                            rowData={rowData}
                            columnDefs={columnDefs}
                            autoSizeStrategy={autoSizeStrategy}
                            onGridReady={onGridReady}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GridExample;