import React from 'react';
import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroup, faPlus } from '@fortawesome/free-solid-svg-icons';
import { IconButton, Typography } from '@mui/material';

export default function CreateLayerButton({ onClick }) {
    return (
        <IconButton
            color="inherit"


            onClick={onClick}
        >
            <Typography variant='body1' fontSize={16} fontWeight={400} color='black'>
                <FontAwesomeIcon icon={faPlus} />
            </Typography>
        </IconButton>

    );
}