

import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lighten } from '@mui/system';
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Chip,
    Container,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import { Col, Row } from 'antd';
import React from 'react';
import DataColorChrome from '../../dataColorChrome';


const TagCondition = ({
    condition,
    conditionIndex,
    codes,
    updateConditions

}) => {


    const codeOptions = codes.map((c) => c.label);


    return (
        <Box style={{ width: '100%' }}>
            <Row align={'middle'} >
                <Col span={7}>
                    <Select
                        style={{ marginLeft: 5, backgroundColor: '#ffffff' }}
                        sx={{
                            fontWeight: 700,
                        }}
                        size="small"
                        variant="outlined"
                        value={condition.action || 'showOnly'}
                        onChange={(e) => {
                            const newCondition = { ...condition };
                            newCondition.action = e.target.value;
                            updateConditions(newCondition);


                        }}
                    >
                        <MenuItem value={'showOnly'}>Show Only</MenuItem>
                        <MenuItem value={'hide'}>Hide</MenuItem>
                        <MenuItem value={'highlight'}>Highlight</MenuItem>
                    </Select>
                </Col>
                <Col span={13}>
                    <Autocomplete

                        multiple
                        id="tags-standard"
                        options={codeOptions}
                        getOptionLabel={(option) => option}
                        defaultValue={condition.tags}
                        allowClear
                        disableCloseOnSelect
                        onChange={(e, b) => {
                            const newCondition = { ...condition };
                            newCondition.tags = b;
                            updateConditions(newCondition);

                        }}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip
                                    variant="outlined"
                                    label={option}
                                    {...getTagProps({ index })}
                                    sx={{
                                        border: `2px solid #3f51b5`,
                                        color: '#3f51b5',
                                        boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.1)',
                                        backgroundColor: 'white', // Lightens the color
                                        fontSize: '13px',
                                        fontWeight: 500,
                                        '&:hover': {
                                            backgroundColor: lighten('#3f51b5', 0.9),
                                        },

                                    }}
                                />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                /* background color white */
                                sx={{
                                    backgroundColor: 'white',
                                }}
                                variant="outlined"
                                size="small"
                            />
                        )}
                    />

                </Col>
                <Col span={4}>
                    {condition.action === 'highlight' &&
                        <DataColorChrome color={condition.color} updateData={(boo, val) => {
                            const newCondition = { ...condition };
                            newCondition.color = val;
                            updateConditions(newCondition);
                        }}
                        />
                    }
                </Col>
                <Col span={4}>
                    {condition.action === 'highlight' &&
                        <OutlinedInput
                            size="small"
                            style={{ marginLeft: 5, backgroundColor: '#ffffff' }}
                            sx={{
                                width: 90,
                            }}
                            type="number"
                            value={condition.radius}
                            onChange={(e) => {
                                const newCondition = { ...condition };
                                newCondition.radius = e.target.value;
                                updateConditions(newCondition);

                            }}
                        />
                    }
                </Col>
            </Row>


        </Box >
    );
};

export default TagCondition;
