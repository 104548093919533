import { faSquareCaretDown } from '@fortawesome/free-regular-svg-icons';
import { faCircleChevronDown, faSquare, faSquareArrowUpRight } from '@fortawesome/free-solid-svg-icons';
import { Button, Popover, Typography } from '@mui/material';
import { TwitterPicker } from 'react-color';
import { IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
var randomColor = require('randomcolor');


const ColorSelect = ({
    currentTask,
    setCurrentTask,
    headerKey,
    options,
    type
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [value, setValue] = React.useState(currentTask?.[headerKey] || randomColor());



    useEffect(() => {
        if (currentTask?.[headerKey] === undefined && type === 'drawer') {
            setCurrentTask({ ...currentTask, [headerKey]: value })
        }
    }, [])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };



    const handleColorChange = (newColor) => {
        let val = newColor.hex;
        setCurrentTask({ ...currentTask, [headerKey]: val })
        setValue(val);
    };



    return (
        <div style={{
            display: 'inline',
            justifyContent: 'center',
            alignItems: 'center',

        }}>
            <Button
                variant='link'
                style={{
                    textTransform: 'none',
                    color: 'inherit',
                    display: 'inline',
                    padding: 0,
                }}

                onClick={handleClick}>
                <FontAwesomeIcon icon={faSquare} color={value} size="2xl" style={{
                    marginRight: 10

                }} />

            </Button>
            {type !== 'station' && (
                <Typography variant="body1" fontWeight={600} fontSize={15} style={{ display: 'inline' }}>
                    {value}
                </Typography>
            )}
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <TwitterPicker color={value} onChangeComplete={handleColorChange} />
            </Popover>
        </div>
    );

};

export default ColorSelect;