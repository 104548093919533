import React, { useEffect, useState } from "react";
import { Box, Input, Modal, Typography } from "@mui/material";
import moment from 'moment-timezone';
import axios from 'axios';  // Import axios if it's not imported already



const API_BASE = "https://restapi.spypoint.com/api/v3";
const headers = { 'Content-Type': 'application/json' };
const limit = 6;
const proxyurl = "https://mycorslake.herokuapp.com/";

const CameraPhotoPreview = ({
    currentStream,
    setCurrentStream,
    headerKey,
    options,
    screenWidth
}) => {
    const [value, setValue] = useState(currentStream?.camera?.[headerKey]);
    const [photos, setPhotos] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [loadingPhotos, setLoadingPhotos] = useState(true);

    const handleOpen = () => setPhotoPreviewModal(true);
    const handleClose = () => setPhotoPreviewModal(false);
    const [photoPreviewModal, setPhotoPreviewModal] = useState(null);

    useEffect(() => {

        setValue(currentStream?.camera?.[headerKey]);
    }, [currentStream]);



    useEffect(() => {
        async function fetchPhotos() {
            try {

                const loginOptions = {
                    method: 'POST',
                    url: `${API_BASE}/user/login`,
                    headers,
                    data: {
                        username: currentStream?.username,
                        password: currentStream?.password,
                    },
                };

                const credentialRes = await axios(proxyurl + loginOptions.url, loginOptions);
                console.log(credentialRes);




                const getCameraPhotos = async (cameraId, dateEnd) => {
                    console.log(currentStream)
                    const options = {
                        method: 'POST',
                        url: `${API_BASE}/photo/all`,
                        headers: headers,
                        data: {
                            camera: [cameraId],
                            dateEnd,
                            favorite: false,
                            hd: false,
                            limit,
                        },
                    };
                    console.log(options)
                    console.log(proxyurl + options.url)

                    const response = await axios(proxyurl + options.url, options);

                    console.log('SUCCESS FETCHING')
                    return response.data;
                };
                const { token } = await credentialRes.data;
                headers.authorization = `Bearer ${token}`;
                let dateEnd = "2100-01-01T00:00:00.000Z";
                const photos = await getCameraPhotos(currentStream?.camera?.cameraId, dateEnd);

                console.log(photos)
                const photoList = photos.photos.map((photo) => {
                    return {
                        time: toSqlString(photo.originDate),
                        streamid: toSqlString(currentStream?.id),
                        camera: toSqlString(photo.camera),
                        account: toSqlString(currentStream?.account),
                        company: toSqlString(currentStream?.company),
                        key: toSqlString(photo.id),
                        date: toSqlString(photo.date),
                        originName: toSqlString(photo.originName),
                        originSize: photo.originSize,
                        largeHost: toSqlString(photo.large.host),
                        largePath: toSqlString(photo.large.path),
                        mediumHost: toSqlString(photo.medium.host),
                        mediumPath: toSqlString(photo.medium.path),
                    }
                });
                console.log(photoList)

                function toSqlString(value) {
                    if (value === undefined) {
                        return 'NULL';
                    }
                    return `'${value}'`;
                }

                const hourlyVals = photoList.map((h) => `(${Object.values(h)})`);
                const hourlyString = hourlyVals.join();



                const customBucket = `
        SELECT *
        FROM camera_table
        WHERE streamid = '${currentStream?.id}'
    `;

                console.log(customBucket)

                const response = await axios.post(proxyurl + `https://us-central1-aquasource3.cloudfunctions.net/widgetsFire4/sqlRead`, { raw: customBucket })
                console.log(response.data)


                /* const response = await axios.post(proxyurl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                    raw: `INSERT INTO camera_table VALUES ${hourlyString} ON CONFLICT DO NOTHING; `,
                });
                console.log('SUCCESS');
                console.log(response);
 */






                setPhotos(photos.photos);
                setLoadingPhotos(false);

            } catch (error) {
                setLoadingPhotos(false);
                console.error('Error fetching photos:', error);
            }
        }

        fetchPhotos();
    }, [currentStream]); // Re-fetch photos when currentStream changes.

    return (
        <>
            <style>
                {`
                .image-hover:hover {
                    transform: scale(1.02);
                }
                .ril__outer {
                    z-index: 9999999 !important;
                }
            `}
            </style>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }}>

                <div style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    gap: '10px',
                }}>

                    {photos.length === 0 && loadingPhotos === false && (
                        <Typography variant="body1" fontWeight={600} fontSize={15} sx={{ textAlign: 'center' }}>
                            No photos found
                        </Typography>
                    )}
                    {photos.length === 0 && loadingPhotos === true && (
                        <Typography variant="body1" fontWeight={600} fontSize={15} sx={{ textAlign: 'center' }}>
                            Loading photos...
                        </Typography>
                    )}
                    {photos.length > 0 && loadingPhotos === false && (photos.map((photo, index) => {
                        const imageUrl = `https://${photo.large.host}/${photo.large.path}`;
                        return (
                            <div key={index} onClick={() => {
                                setPhotoIndex(index)
                                setSelectedImage(imageUrl)
                                setPhotoPreviewModal(true)
                            }
                            }>
                                <img
                                    className="image-hover"
                                    src={imageUrl}
                                    alt={photo.originName}
                                    style={{
                                        width: '100%',
                                        display: 'block',
                                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                        borderRadius: '10px',
                                        transition: 'transform 0.3s ease-in-out',
                                        cursor: 'pointer',
                                    }}
                                />
                            </div>
                        );
                    }))}
                </div>

                <Modal
                    open={photoPreviewModal}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{

                    }}

                    slotProps={{
                        backdrop: {
                            style: {
                                backgroundColor: 'transparent'
                            }
                        }
                    }}
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: screenWidth === 'xs' ? '90%' : '80%',
                        bgcolor: 'background.paper',
                        border: '1px solid rgba(0, 0, 0, .3)',
                        boxShadow: 24,
                        p: 1,
                        borderRadius: '24px',
                        boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
                    }}>
                        <Typography id="modal-modal-title" variant="h6" fontWeight={700} fontSize={28} component="h2">
                            Photo Preview
                        </Typography>
                        <img src={selectedImage} style={{ width: '100%', height: 'auto', marginTop: 20 }} />


                    </Box>
                </Modal>
            </Box>
        </>
    );
};

export default CameraPhotoPreview;