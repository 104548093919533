import React from 'react';
import { Col, Row, Drawer, Popconfirm, } from 'antd';
import {
    Breadcrumbs, Button, OutlinedInput, Paper, Typography, Select, MenuItem, IconButton, Divider, TextField, FormControl, FormGroup, FormControlLabel, Collapse,
    Checkbox, Fab, Autocomplete, CardContent, Box
} from '@mui/material';
import { Link } from 'react-router-dom';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { ReactSortable } from 'react-sortablejs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVial, faLocationCrosshairs, faFillDrip, faChartArea, faChartBar, faChartLine, faChartColumn, faGear, faCopy, faTrash } from '@fortawesome/free-solid-svg-icons';
import uuid from 'react-uuid';
import DataColor from './dataColor';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc } from 'firebase/firestore';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import moment from 'moment';
import ProbeParameters from '../../../../extra/probeParameters.json';
import LabParameters from '../../../../extra/labParameters.json';
import Units from '../../../../extra/units.json';
import WeatherParameters from '../../../../extra/weatherParameters.json';
import DefaultDatePicker from '../../../../blocks/defaultDatePicker';
import { fullMobile } from './util'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataColorGithub from './dataColorGithub';
import DataColorCompact from './dataColorCompact';
import { queryParameters } from '../../../../firebase/config';
import ParametersLoad from './parametersLoad';
import NodeSelect from './NodeSelect';
import ParametersLoadMultiple from './parametersLoadMultiple';

var randomColor = require('randomcolor');
export default class HorizontalBarSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            attributes: this.props.attributes,
            currentWidgetKey: this.props.currentWidgetKey,
            locations: [],
            parameters: [],
            sites: [],
            activeTab: 'data',
            readData: this.props.readData
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
        if (this.props.attributes !== prevProps.attributes) {

            this.setState({ attributes: this.props.attributes })
        }
        if (this.props.currentWidgetKey !== prevProps.currentWidgetKey) {

            this.setState({ currentWidgetKey: this.props.currentWidgetKey, })
        }
        if (this.props.readData !== prevProps.readData) {
            this.setState({ readData: this.props.readData })
            if (this.props.readData === true) {
                this.props.updateAttributes(this.state.attributes, this.props.currentWidgetKey)
                this.setState({ readData: false })
            }
        }


    }
    componentDidMount() {
        const { attributes } = this.state;
        this.getLocations()

        this.getParameters();


    }

    getLocations = async () => {
        const { userInfo } = this.state;

        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'probes'), where('account', '==', userInfo.currentAccount), where('archived', '==', false));
        const queryDataSites = query(collection(db, "clients", userInfo.currentCompany, 'sampleSites'), where('account', '==', userInfo.currentAccount),);
        const queryDataStations = query(collection(db, "clients", userInfo.currentCompany, 'tidalStations'), where('account', '==', userInfo.currentAccount),);
        const queryGauges = query(collection(db, "clients", userInfo.currentCompany, 'rainGauges'), where('account', '==', userInfo.currentAccount),);
        const queryWunders = query(collection(db, "clients", userInfo.currentCompany, 'wundergrounds'), where('account', '==', userInfo.currentAccount),);
        const queryStations = query(collection(db, "clients", userInfo.currentCompany, 'stations'), where('account', '==', userInfo.currentAccount),);
        const locations = [];


        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            locations.push({ ...doc.data(), label: doc.data().name, type: 'probe' })
        });
        const snapSites = await getDocs(queryDataSites);
        snapSites.forEach((doc) => {
            locations.push({ ...doc.data(), label: doc.data().name, type: 'sample' })
        });

        const snapStations = await getDocs(queryDataStations);
        snapStations.forEach((doc) => {
            locations.push({ ...doc.data(), label: doc.data().name, type: 'station' })
        });
        const snapGauges = await getDocs(queryGauges);
        snapGauges.forEach((doc) => {
            locations.push({ ...doc.data(), label: doc.data().name, type: 'gauge' })
        });
        const snapWunders = await getDocs(queryWunders);
        snapWunders.forEach((doc) => {
            locations.push({ ...doc.data(), name: doc.data().stationName, label: doc.data().stationName, type: 'wunder' })
        });

        const snapNodes = await getDocs(queryStations);
        snapNodes.forEach((doc) => {

            if (doc.data().archived !== true && doc.data().children.length > 0) {
                const children = doc.data().children;
                //for each child create a node and add to locations

                children.forEach((child) => {
                    locations.push({ ...child, stationLabel: doc.data().name, label: child.name, type: 'node', stationType: child.type })
                })

            }

        });




        locations.push({ label: 'Weather', key: 'Weather', name: 'Weather', type: 'weather' })
        locations.sort((a, b) => a.name.localeCompare(b.name))
        this.setState({ locations, })

    }


    updateWidget = (boo, val) => {
        const { attributes } = this.state;
        attributes[boo] = val;
        this.setState({ attributes })


    }

    updateData = (index, boo, val) => {
        const { attributes } = this.state;
        attributes.chartData[index][boo] = val;
        this.updateWidget(attributes)

    }
    updateParameter = (index, parameterIndex, boo, val) => {
        const { attributes } = this.state;
        console.log(index, parameterIndex, boo, val)
        console.log(attributes.chartData[index].parameters[parameterIndex])


        // Update or add the value
        attributes.chartData[index].parameters[parameterIndex][boo] = val;

        this.updateWidget(attributes);
        this.updateWidget(attributes)

    }







    getParameters = async () => {
        const { userInfo, type } = this.state;
        const db = getFirestore();
        const parameters = await queryParameters(userInfo)
        parameters.sort((a, b) => a.name.localeCompare(b.name))
        this.setState({ parameters, })
    }

    handleCheck = (index, boo, val,) => {
        // Clears running timer and starts a new one each time the user types
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.toggleCheck(index, boo, val,);
        }, 1000);
    }

    toggleCheck = async (index, boo, val) => {
        this.updateData(index, boo, val)
    }


    handleCheckWidget = (boo, val,) => {
        // Clears running timer and starts a new one each time the user types
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.toggleCheckWidget(boo, val,);
        }, 800);
    }

    toggleCheckWidget = async (boo, val) => {
        this.updateWidget(boo, val)
    }

    duplicateSeries = (index) => {
        const { attributes } = this.state;
        const newSeries = { ...attributes.chartData[index] }
        newSeries.key = uuid()
        attributes.chartData.push(newSeries)
        this.setState({ attributes })
        this.props.openSnack('success', `Series duplicated.`)
    }

    render() {

        const { account, attributes, screenWidth, locations, expanded, activeTab, currentWidgetKey, parameters, userInfo } = this.state;

        const DeleteItem = ({ row, type }) => {
            return (
                <Popconfirm
                    title={`Are you sure to delete this ${type === 'chartData' ? 'series' : type}?`}
                    onConfirm={async () => {

                        if (type === 'axises') {
                            const checkSeries = attributes.chartData.filter((s) => s.yAxis === row.axisId);
                            if (checkSeries.length > 0) {
                                this.props.openSnack('error', `Cannot delete, a series is associated to this axis.`)
                            }
                            else {
                                this.props.openSnack('success', `${type === 'axises' ? "Axes" : "Data"} deleted.`)
                                const newData = attributes[type].filter((f) => f.key !== row.key);
                                attributes[type] = newData;
                                this.setState({ attributes })
                            }
                        }
                        else {
                            this.props.openSnack('success', `${type === 'axises' ? "Axes" : "Data"} deleted.`)
                            const newData = attributes[type].filter((f) => f.key !== row.key);
                            attributes[type] = newData;
                            this.setState({ attributes })
                        }




                    }}
                    okText="Yes"
                    cancelText="No"
                >
                    <IconButton style={{ textTransform: 'none', color: 'red' }} fullWidth variant="contained" size="small"
                    >
                        <FontAwesomeIcon icon={faTrash} />
                    </IconButton>
                </Popconfirm>


            )
        }





        const chartDataMap = (d, i) => {
            const input = d.decimals === 0 || d.decimals ? d.decimals : '';

            console.log(d)

            const gutter = [8, 8]
            const verticalOffset = { marginTop: 20 }
            return (
                <Row gutter={gutter} style={{ backgroundColor: i % 2 === 0 ? 'inherit' : 'rgba(0,1,0,0.05)', paddingTop: 8 }} key={d.key}>
                    {/* Drag Component */}
                    <Col {...fullMobile(2, undefined, { xs: 0 })} className="my-handle">
                        <DragIndicatorIcon />
                    </Col>
                    {/* Data input section */}
                    <Col {...fullMobile(22)}>
                        <Row align="middle" gutter={gutter} style={{ paddingBottom: 8 }}>
                            <Col span={24}>
                                {/* Row 1 */}
                                <Row gutter={gutter}>

                                    <Col {...fullMobile(6)}>

                                        <Typography fontSize={13} fontWeight={600} variant="body1">Data Type<span style={{ color: 'red' }}>*</span></Typography>


                                        <Select size="small" fullWidth
                                            notched={false}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={d.dataType || ''}
                                            label="Layer"
                                            onChange={(e) => {
                                                this.updateData(i, 'dataType', e.target.value)
                                            }}

                                        >
                                            <MenuItem value={'node'}>Node/Station</MenuItem>

                                        </Select>



                                    </Col>


                                    <Col {...fullMobile(8, 20)}>
                                        <Typography fontSize={13} fontWeight={600} variant="body1">Node/Location<span style={{ color: 'red' }}>*</span></Typography>
                                        <NodeSelect
                                            dataType={d.dataType}
                                            updateData={(a, b, c) => {
                                                this.setState({ updatingDepth: true })

                                                this.updateData(a, b, c)
                                                setTimeout(() => {
                                                    this.setState({ updatingDepth: false })
                                                }
                                                    , 250)
                                            }}
                                            index={i}
                                            locations={locations}
                                            location={d.location}
                                        />
                                    </Col>
                                    <Col {...fullMobile(6)}>
                                        <Row>
                                            <Col span={24}>
                                                <Typography fontSize={13} variant="body1">Label</Typography>
                                            </Col>
                                            <Col span={24}>
                                                <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                    defaultValue={d.label || ''}
                                                    onChange={(e) => {
                                                        this.handleCheck(i, 'label', e.target.value)
                                                    }} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col {...fullMobile(2, 4)} >
                                        <IconButton style={{ ...verticalOffset }} onClick={() => {
                                            this.updateData(i, 'advancedSettings', d.advancedSettings ? !d.advancedSettings : true)
                                        }}>
                                            <FontAwesomeIcon fontSize={18} icon={faGear} color="#9ec1c9" />
                                        </IconButton>
                                    </Col>
                                    <Col {...fullMobile(24)} >
                                        <Typography fontSize={13} fontWeight={600} variant="body1">Parameters<span style={{ color: 'red' }}>*</span></Typography>
                                        <ParametersLoadMultiple userInfo={userInfo} nodeid={d.locationid} dataType={d.dataType} widgetParameters={d.parameters}
                                            updateData={(boo, val) => {

                                                this.updateData(i, boo, val)

                                            }} />
                                    </Col>

                                </Row>
                            </Col>
                            <Col span={24}>
                                {/* Row 2 */}
                                <Row gutter={gutter}>




                                    <Col {...fullMobile(3, 8)} style={{ paddingTop: 12 }}>
                                        <Row>
                                            <Col span={24}>
                                                <Button size="small" variant="contained" color="primary" onClick={() => {
                                                    //duplicate series
                                                    this.duplicateSeries(i)

                                                }}
                                                    style={{
                                                        color: 'white',
                                                        backgroundColor: '#404050',

                                                    }}
                                                >
                                                    <FontAwesomeIcon fontSize={14} icon={faCopy} color="white" />
                                                </Button>
                                            </Col>
                                        </Row>




                                    </Col>
                                    <Col {...fullMobile(2, 6)} style={{ paddingTop: 12 }}>
                                        <DeleteItem row={d} type="chartData" />
                                    </Col>
                                    {/* Advanced settings */}
                                    <Col {...fullMobile(22)}>
                                        <Row style={{ paddingBottom: 8 }}>
                                            <Col span={24}>
                                                <Collapse in={d.advancedSettings || false} timeout="auto" unmountOnExit>
                                                    <Row align="middle" gutter={[8, 8]}>
                                                        <Col span={24} style={{ paddingTop: 8 }}>
                                                            <Typography fontSize={14} fontWeight={500} variant="body1">Advanced</Typography>
                                                        </Col>

                                                        {d.parameters && d.parameters.length > 0 && d.parameters.map((p, index) => {
                                                            console.log(p)
                                                            return (
                                                                <Col {...fullMobile(24)} key={index}>
                                                                    <Row align="middle">
                                                                        <Col span={12}>
                                                                            <Typography fontSize={14} fontWeight={500} variant="body1">{p.title}</Typography>
                                                                        </Col>
                                                                        <Col span={6}>
                                                                            <DataColorCompact
                                                                                color={p.parameterColor || '#404050'}
                                                                                updateData={(boo, val) =>
                                                                                    this.updateParameter(i, index, 'parameterColor', val)
                                                                                } />
                                                                        </Col>
                                                                        {['1', '3'].includes(p.key) && (
                                                                            <Col span={6}>
                                                                                <Select size="small" fullWidth
                                                                                    notched={false}
                                                                                    placeholder='Units'
                                                                                    labelId="demo-simple-select-label"
                                                                                    id="demo-simple-select"
                                                                                    value={p.units || ''}
                                                                                    label="Units"
                                                                                    onChange={(e) => {
                                                                                        this.updateParameter(i, index, 'units', e.target.value)
                                                                                    }}

                                                                                >

                                                                                    {p.key === '1' && (
                                                                                        ['C', 'F'].map((f) => {
                                                                                            return (
                                                                                                <MenuItem value={f}>{f}</MenuItem>
                                                                                            )
                                                                                        })
                                                                                    )}
                                                                                    {p.key === '3' && (
                                                                                        ['m', 'ft'].map((f) => {
                                                                                            return (
                                                                                                <MenuItem value={f}>{f}</MenuItem>
                                                                                            )
                                                                                        })
                                                                                    )}



                                                                                </Select>


                                                                            </Col>
                                                                        )}
                                                                        <Col span={24}>
                                                                            <Divider />
                                                                        </Col>
                                                                    </Row>
                                                                </Col>

                                                            )
                                                        }
                                                        )}













                                                    </Row>
                                                </Collapse>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    {/* Horizontal divider */}
                    <Col span={24}>
                        <Divider />
                    </Col>
                </Row>
            )
        }





        return (
            <Row justify="center" align="middle">
                <Col span={24}>
                    <Typography fontSize={16} variant='subtitle2'>Chart Settings</Typography>
                </Col>
                <Col span={24}>
                    <TabContext value={activeTab}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={(e, tab) => this.setState({ activeTab: tab })} aria-label="lab API tabs example">
                                <Tab style={{ textTransform: 'none' }} label="Data" value="data" />
                                <Tab style={{ textTransform: 'none' }} label="Chart" value="chart" />
                                <Tab style={{ textTransform: 'none' }} label="Axes" value="axises" />
                            </TabList>
                        </Box>
                        <TabPanel value="chart">
                            <Col xs={24} style={{ paddingTop: 12 }} >
                                <Row>
                                    <Col span={24}>
                                        <Typography fontSize={13} variant="body1">Chart Type<span style={{ color: 'red' }}>*</span></Typography>
                                    </Col>
                                    <Col span={24}>
                                        <Select size="small" fullWidth
                                            notched={false}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={attributes.chartType}
                                            label="Layer"
                                            onChange={(e) => {
                                                this.updateWidget('chartType', e.target.value)
                                            }}

                                        >
                                            <MenuItem value={'standard'}>Standard</MenuItem>
                                            {/* <MenuItem value={'storageVolume'}>Storage Volume</MenuItem> */}
                                        </Select>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={24} style={{ paddingTop: 12 }} >
                                <Row>
                                    <Col span={24}>
                                        <Typography fontSize={14} fontWeight={500} variant="body1">Settings</Typography>
                                    </Col>
                                    <Col span={24}>
                                        <FormControl component="settings">

                                            <FormGroup row>

                                                {[
                                                    { label: 'Share Axis', key: 'shareAxis' },
                                                    { label: 'Align Ticks', key: 'allignTicks' },
                                                    { label: 'Legend', key: 'legend' },
                                                    { label: 'X-Axis Padding', key: 'xPadding' },


                                                ].map((r) => {
                                                    return (
                                                        <FormControlLabel
                                                            value={r.label}
                                                            control={
                                                                <Checkbox checked={attributes[r.key]}
                                                                    onChange={(e) => {
                                                                        if (r.key === 'allignTicks' && e.target.checked) {
                                                                            this.props.openSnack('warning', 'Allign Ticks will change Start & End on tick to true')
                                                                        }

                                                                        this.updateWidget(r.key, e.target.checked)
                                                                    }} />
                                                            }
                                                            label={r.label}
                                                            labelPlacement="start"
                                                        />
                                                    )
                                                })}




                                            </FormGroup>
                                        </FormControl>
                                    </Col>
                                </Row>
                            </Col>
                        </TabPanel>
                        <TabPanel value="data" style={{ height: "auto" }}>
                            <Col xs={24} style={{ paddingTop: 4 }} >
                                <Row align="middle" gutter={[12, 12]}>




                                    <Col span={20}>
                                        <Typography fontSize={14} fontWeight={500} variant="body1">Data</Typography>
                                    </Col>
                                    <Col span={4}>
                                        <Button variant={'contained'} display="inline"
                                            size='small'
                                            style={{
                                                textTransform: 'none', backgroundColor: '#404050', fontSize: 14,
                                            }} onClick={() => {
                                                const item = {
                                                    key: uuid(),
                                                    locationid: '',
                                                    parameters: [],

                                                };
                                                if (attributes.chartData) {
                                                    attributes.chartData.push(item);
                                                    this.setState({ attributes });

                                                }
                                                else {
                                                    attributes.chartData = [];
                                                    attributes.chartData.push(item);
                                                    this.setState({ attributes });

                                                }
                                            }}>
                                            <AddIcon /> Node
                                        </Button>
                                    </Col>

                                    <Col span={24} style={{ paddingTop: 6 }}>
                                        <Divider />
                                    </Col>

                                    <Col span={24}>
                                        {attributes.chartData !== undefined && attributes.chartData.length > 0 && (
                                            <ReactSortable
                                                handle=".my-handle"
                                                animation={500}
                                                list={attributes.chartData}
                                                setList={(newState) => {
                                                    attributes.chartData = newState;
                                                    this.setState({ attributes })
                                                }}
                                            >
                                                {attributes.chartData.map(chartDataMap)}
                                            </ReactSortable>)}
                                    </Col>
                                    <Col span={24} style={{ paddingTop: 6 }}>
                                        <Button variant={'contained'} display="inline"
                                            fullWidth
                                            style={{
                                                textTransform: 'none', backgroundColor: '#404050', fontSize: 14,
                                            }}
                                            onClick={() => {
                                                const item = {
                                                    key: uuid(),
                                                    locationid: '',
                                                    parameters: [],

                                                };
                                                if (attributes.chartData) {
                                                    attributes.chartData.push(item);
                                                    this.setState({ attributes });

                                                }
                                                else {
                                                    attributes.chartData = [];
                                                    attributes.chartData.push(item);
                                                    this.setState({ attributes });

                                                }
                                            }}>

                                            <AddIcon />  Add Node
                                        </Button>
                                    </Col>
                                </Row>
                            </Col ></TabPanel>
                        <TabPanel value="axises">
                            <Col xs={24} style={{ paddingTop: 4 }} >
                                <Row align="middle" gutter={[12, 12]}>
                                    <Col span={20}>
                                        <Typography fontSize={14} fontWeight={500} variant="body1">Axes</Typography>
                                    </Col>
                                    <Col span={4}>
                                        <Button variant={'contained'} display="inline"
                                            size='small'
                                            style={{
                                                textTransform: 'none', backgroundColor: '#404050', fontSize: 14,
                                            }} onClick={() => {
                                                const item = {
                                                    key: uuid(),
                                                    axisId: attributes.axises !== undefined ? Math.max.apply(Math, attributes.axises.map((o) => { return o.axisId + 1; })) : 0,
                                                    label: '',
                                                    opposite: false,
                                                    customRange: false,
                                                    min: 0,
                                                    max: 100,
                                                    labelRotation: 270,
                                                    axisColor: '#000000',
                                                    gridLineColor: '#000000',
                                                    gridLineDashStyle: 'Solid',
                                                    gridLineWidth: 0.3,
                                                    alignTicks: true,
                                                    endOntick: true,
                                                    reversed: false,
                                                    advancedSettings: false,
                                                    title: {
                                                        text: ''
                                                    }
                                                };

                                                if (attributes.axises) {
                                                    attributes.axises.push(item);
                                                    this.setState({ attributes });

                                                }
                                                else {
                                                    attributes.axises = [];
                                                    attributes.axises.push(item);
                                                    this.setState({ attributes });

                                                }
                                            }}>
                                            <AddIcon /> Axis
                                        </Button>
                                    </Col>

                                    <Col span={24} style={{ paddingTop: 6 }}>
                                        <Divider />
                                    </Col>



                                </Row>
                            </Col>
                        </TabPanel>

                    </TabContext >
                </Col >







            </Row >
        );
    }
} 