import { Box, CircularProgress, Grid, Typography, Slider } from '@mui/material';
import { Col, Row } from 'antd';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudRain, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { styled } from '@mui/material/styles';




const ForecastDays = ({ weather, spacing, screenWidth }) => {
    const [loading, setLoading] = useState('loading');

    
    const width = spacing?.w;

    var dict = [
        { newIcon: 'wi-day-sunny', weatherGovIcon: 'skc' },
        { newIcon: 'wi-day-cloudy', weatherGovIcon: 'few' },
        { newIcon: 'wi-cloud', weatherGovIcon: 'sct' },
        { newIcon: 'wi-cloudy', weatherGovIcon: 'bkn' },
        { newIcon: 'wi-showers', weatherGovIcon: 'rain_showers' },
        { newIcon: 'wi-day-rain-mix', weatherGovIcon: 'rain' },
        { newIcon: 'wi-thunderstorm', weatherGovIcon: 'tsra' },
        { newIcon: 'wi-snow', weatherGovIcon: 'snow' },
        { newIcon: 'wi-fog', weatherGovIcon: 'fog' },
        { newIcon: 'wi-night-clear', weatherGovIcon: 'skc', night: true },
        { newIcon: 'wi-night-alt-cloudy', weatherGovIcon: 'few', night: true },
        { newIcon: 'wi-night-alt-cloudy-high', weatherGovIcon: 'sct', night: true },
        { newIcon: 'wi-night-alt-sprinkle', weatherGovIcon: 'rain_showers', night: true },
        { newIcon: 'wi-night-alt-showers', weatherGovIcon: 'rain', night: true },
        { newIcon: 'wi-night-alt-thunderstorm', weatherGovIcon: 'tsra', night: true },
        { newIcon: 'wi-night-alt-snow', weatherGovIcon: 'snow', night: true },
        { newIcon: 'wi-night-fog', weatherGovIcon: 'fog', night: true },
        { newIcon: 'wi-windy', weatherGovIcon: 'wind_skc' },
        { newIcon: 'wi-snow-wind', weatherGovIcon: 'blizzard' },
        { newIcon: 'wi-hot', weatherGovIcon: 'hot' },
        { newIcon: 'wi-snowflake-cold', weatherGovIcon: 'cold' },
        { newIcon: 'wi-hurricane', weatherGovIcon: 'hurricane' },
        { newIcon: 'wi-tornado', weatherGovIcon: 'tornado' },
        { newIcon: 'wi-day-haze', weatherGovIcon: 'haze' },
        { newIcon: 'wi-smoke', weatherGovIcon: 'smoke' },
        { newIcon: 'wi-dust', weatherGovIcon: 'dust' },
        { newIcon: 'wi-rain-mix', weatherGovIcon: 'rain_sleet' },
        { newIcon: 'wi-sleet', weatherGovIcon: 'snow_sleet' },
        { newIcon: 'wi-rain-mix', weatherGovIcon: 'fzra' },
        { newIcon: 'wi-storm-showers', weatherGovIcon: 'tropical_storm' }
    ]


    // Function to find the correct icon based on the iconURL
    const findIcon = (iconURL) => {
        const weatherGovIcon = iconURL?.split('/').pop().split('.')[0]; // Extract the icon code from URL
        const iconEntry = dict.find(item => weatherGovIcon?.includes(item?.weatherGovIcon)); // Find the icon in the dictionary (if it exists
        return iconEntry ? iconEntry?.newIcon : '';
    };

 

    return (
        <>

            <Grid container spacing={1} style={{ backgroundColor: 'white', borderRadius: 8, }} sx={{ width: "100%", }} alignContent={'center'} alignItems={'center'} justifyContent={'center'} >

                {[weather?.today]?.map((day, index) => {
                    const iconClass = findIcon(day?.icon); // Use the findIcon function here


                    return (
                        <Grid item xs={12}
                            key={index}
                            style={{ textAlign: 'left', padding: 5 }}>
                            <Box style={{ textAlign: 'center', backgroundColor: 'white', borderRadius: 8 }}>
                                <Grid container spacing={1} sx={{ width: "100%", }}>
                                    <Grid item xs={5}>
                                        <Box style={{
                                            paddingBottom: 5,
                                            textAlign: 'center',
                                            paddingTop: 0,
                                            alignContent: 'center',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            display: 'flex',
                                        }}>
                                            <i style={{
                                                marginTop: 10,
                                                fontSize: spacing?.w === 'xs' ? '4rem' : '5rem', // Adjust the size as needed

                                            }} className={`wi ${iconClass}`}></i>

                                        </Box>
                                       
                                    </Grid>
                                    <Grid item xs={7} >
                                        <Grid container spacing={1} sx={{ width: "100%", }} justifyContent={'left'} >
                                            <Grid item xs={12} style={{ textAlign: 'left' }} >
                                                <Typography variant="body1" fontWeight={500} fontSize={16} style={{ textAlign: 'left', display: 'inline' }}>{day?.forecast}</Typography>
                                            </Grid>
                                            <Grid item xs={12} style={{ textAlign: 'left' }} >
                                                <Typography variant="body1" fontWeight={600} fontSize={16} style={{ textAlign: 'left', display: 'inline' }}>H: {day?.maxTemp} L: {day?.minTemp}</Typography>
                                            </Grid>
                                            <Grid item xs={12} style={{ textAlign: 'left' }} alignContent={'center'}>
                                                <FontAwesomeIcon icon={faCloudRain} color="#3f51b5" size="2x" style={{ display: "inline", marginRight: 10 }} />
                                                <Typography variant="body1" fontWeight={600} fontSize={13} style={{ textAlign: 'left', display: 'inline' }}>{day?.avgPrecipProb || 0}%</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    )
                })}


                {weather?.forecast?.map((day, index) => {
                    const iconClass = findIcon(day.icon); // Use the findIcon function here


                    return (
                        <Grid item xs={12}
                            key={index}
                            style={{ textAlign: 'left', padding: 5, borderBottom: '1px solid #e0e0e0' }}>
                            <Box style={{ textAlign: 'center', borderRadius: 8 }}>
                                <Grid container spacing={1} sx={{ width: "100%", }} alignContent={'center'} alignItems={'center'} justifyContent={'center'} >
                                    <Grid item xs={3} alignContent={'center'} style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',

                                    }}>
                                        <Typography variant="body1" fontWeight={800} fontSize={13} style={{ textAlign: 'center', }}>{day?.day}</Typography>
                                    </Grid>
                                    <Grid item xs={4} sm={5} style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        alignContent: 'center',
                                        position: 'relative'
                                    }}>
                                        <div style={{
                                            position: 'absolute', width: '100%',
                                            height: '100%',
                                            textAlign: 'center',
                                            display: 'flex',
                                            justifyContent: 'center',

                                            alignContent: 'center',

                                        }}>
                                            {day?.avgPrecipProb !== null && day?.avgPrecipProb > 0 && (
                                                <Typography variant="body1" fontWeight={800} fontSize={13}
                                                    color="#49bbfb" style={{
                                                        padding: 0, textAlign: 'center',
                                                        position: 'absolute',
                                                        bottom: screenWidth === 'xs' ? 0 : -7,
                                                        marginLeft: screenWidth === 'xs' ? 60 :50,

                                                    }}>
                                                    {day?.avgPrecipProb || 0}%</Typography>
                                            )}
                                        </div>
                                        <i style={{

                                            paddingTop: 7,

                                            fontSize: spacing?.w === 'xs' ? 20 : 30, // Adjust the size as needed

                                        }} className={`wi ${iconClass}`}></i>




                                    </Grid>
                                    <Grid item xs={5} sm={3} >

                                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography variant="body1" fontWeight={700} fontSize={16} color={'#a9abff'}>{day.minTemp}°</Typography>
                                            <Typography variant="body1" fontWeight={700} fontSize={16}>{day.maxTemp}°</Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    )
                })}

            </Grid >


        </>


    );


}

export default ForecastDays;