
import React from "react";


import { Autocomplete, Button, TextField } from '@mui/material';
import { useNavigate } from "react-router-dom";


const StaffPickerSingle = ({
    accountUsers,
    log,
    setLog,
    type,
    updateRecord,
    record

}) => {


    return (
        <Autocomplete
            size="small"

            id="combo-box-demo"
            value={type === 'flItem' ? accountUsers.find(s => s.id === record?.leadStaff) || null :
                accountUsers.find(s => s.id === log?.leadStaff) || null}
            disableCloseOnSelect={false}
            options={accountUsers}
            style={{
                backgroundColor: 'white',
            }}
            renderInput={(params) => <TextField {...params} placeholder="Staff" />}

            onChange={(a, b) => {
                if (type === 'flItem') {
                    updateRecord({ ...record, leadStaff: b?.id || null })
                    return
                }
                setLog({ ...log, leadStaff: b?.id })


            }}

        />
    )
}

export default StaffPickerSingle;