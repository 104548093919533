import { collection, doc, getCountFromServer, getDoc, getDocs, getFirestore, onSnapshot, orderBy, query, setDoc, updateDoc, where } from "firebase/firestore";
import moment from 'moment-timezone';
import axios, * as others from 'axios';
import { SpeedUnit, convertUnits } from 'measurement-unit-converter';
import uuid from "react-uuid";

var convert = require('convert-units')





export const fetchWeather = async (account) => {
    //check if log?.startdate is in the future
    const now = moment().format('x');
    console.log(account)

    const apiKey = 'e60406d8d1152d5b1e8413bb058aa602';
    const proxyurl = "https://mycorslake.herokuapp.com/";
    const url = `https://api.openweathermap.org/data/2.5/weather?lat=${account?.lat}&lon=${account?.lng}&appid=${apiKey}&units=imperial`;



    try {
        const response = await axios.get(proxyurl + url);

        const weather = {
            tempF: response?.data?.main?.temp,
            tempC: Number(convert(response?.data?.main?.temp).from('F').to('C').toFixed(1)),
            weather: response?.data?.weather[0]?.main,
            icon: response?.data?.weather[0]?.icon,
            description: response?.data?.weather[0]?.description,
            wind: response?.data?.wind?.speed,
            humidity: response?.data?.main?.humidity,
            rain: response?.data?.rain?.['1h'] || 0,
        }
        /* make wind to fixed 0 */
        weather.wind = Number(weather.wind.toFixed(0));


        return weather
    } catch (error) {
        console.error("Error fetching weather data:", error);
        return null;
    }


};


async function fetchHistoricalWeather(log, account) {
    try {
        console.log(log?.startDate);
        console.log(moment(log?.startDate, 'x').format('YYYY-MM-DD HH:mm:ss'));
        const proxyurl = "https://mycorslake.herokuapp.com/";

        const response = await axios.post(proxyurl + 'https://us-central1-aquasource3.cloudfunctions.net/widgetsFire3/sqlRead', {
            raw: `SELECT * FROM weather_data WHERE account = '${account.key}' AND time >= '${moment(log?.startDate, 'x').format('YYYY-MM-DD HH:mm:ss')}'
        ORDER BY time LIMIT 1;`
        });

        console.log(response);
        const weather = {
            temp: Number(convert(response?.data[0]?.temp).from('C').to('F').toFixed(1)),
            weather: response?.data[0]?.weathermain,
            icon: response?.data[0]?.weathericon,
            description: response?.data[0]?.weatherdescription,
            wind: Number(convertUnits(response?.data[0]?.wind_speed, SpeedUnit.METER_PER_SECOND, SpeedUnit.MILE_PER_HOUR).toFixed(1)),
            humidity: response?.data[0]?.humidity,
        }
        return weather;
    }
    catch (error) {
        console.error("Error fetching historical weather data:", error);
        return 'no_history';
    }



}


