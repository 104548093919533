import React from 'react';

import { Checkbox, Divider, IconButton, Typography } from '@mui/material';
import { faCheck, faSpinner, faSquareCheck, } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon, } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import ActionsButton from './actionsButton';

export const TableHeaders = (setSelectedLogs, selectedLogs) => [
    {
        title: '',
        headerName: 'selected',
        dataIndex: 'selected',
        key: 'selected',
        width: 70,
        render: (text, record) => (
            <IconButton
                style={{ marginLeft: 10 }}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    const newSelectedLogs = [...selectedLogs];
                    if (selectedLogs.some((t) => t === record.id)) {
                        newSelectedLogs.splice(newSelectedLogs.indexOf(record.id), 1);
                    } else {
                        newSelectedLogs.push(record.id);
                    }
                    setSelectedLogs(newSelectedLogs);
                }}
            >
                <FontAwesomeIcon color="#3f51b5"
                    icon={selectedLogs.some((t) => t === record.id) ? faSquareCheck : faSquare}
                />
            </IconButton>

        ),


    },
    {
        title: 'Number',
        headerName: 'Log Number',
        id: 'logNumber',
        dataIndex: 'logNumber',
        key: 'logNumber',
        getLabel: false,
        type: 'number',
        visible: true,
        sorter: (a, b) => a?.logNumber - b?.logNumber,

    },
    {
        title: 'Account',
        headerName: 'Account',
        id: 'account',
        dataIndex: 'account',
        key: 'account',
        getLabel: false,
        type: 'string',
        visible: true,
        sorter: (a, b) => a?.account?.localeCompare(b?.account),

    },
    {
        title: 'Billed',
        headerName: 'Billed',
        id: 'billed',
        dataIndex: 'billed',
        key: 'billed',
        getLabel: false,
        type: 'string',
        visible: true,
        sorter: (a, b) => a?.billed?.localeCompare(b?.billed),
       

    },
    {
        title: 'Status',
        headerName: 'Status',
        id: 'status',
        dataIndex: 'status',
        key: 'status',
        getLabel: false,
        type: 'string',
        visible: true,
        sorter: (a, b) => a?.status?.localeCompare(b?.status),
        

    },
    {
        title: 'Lead Staff',
        headerName: 'Lead Staff',
        id: 'leadStaff',
        dataIndex: 'leadStaff',
        key: 'leadStaff',
        getLabel: false,
        type: 'string',
        visible: true,
        sorter: (a, b) => a?.leadStaff?.localeCompare(b?.leadStaff),

    },
    {
        title: 'Items',
        headerName: 'Items',
        id: 'items',
        dataIndex: 'items',
        key: 'items',
        getLabel: false,
        type: 'string',
        visible: true,
        width: 400,
        sorter: (a, b) => a?.items?.localeCompare(b?.items),

    },
    {
        title: 'Actions',
        headerName: 'Actions',
        id: null,
        dataIndex: null,
        key: 'actions',
        getLabel: false,
        type: 'actions',
        visible: true,
        render: () => {
            return (
                <ActionsButton />
            )
        }



    },
];