
import MUIDataTable from "mui-datatables";
import { Col, Row } from 'antd';
import React, { useState, useEffect } from 'react';
import { Button, CircularProgress, ThemeProvider, createTheme } from "@mui/material";

const StationsTable = (props) => {
    const { screenWidth, items, loading, handleEdit, handleDelete, setPhotosModalOpen, setCurrentItem } = props;
    const [selectedRows, setSelectedRows] = useState([]);





    const columns = [
        {
            name: 'id',
            label: 'ID',
            options: {
                filter: false,
                display: 'excluded'
            }
        },
        {
            name: 'name',
            label: 'Name',
            options: {
                filter: false,
            }
        },
        {
            name: 'device',
            label: 'Device Name',
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    // value here will be the 'camera' object
                    return value ? value.name : '';
                }
            }
        },
      
        
    ]

    const getMuiTheme = () =>
        createTheme({
            components: {
                MUIDataTable: {
                    styleOverrides: {
                        root: {
                            padding: '8px',
                            boxShadow: 'none'
                        }
                    }
                },
                MuiTableRow: {
                    styleOverrides: {
                        root: {
                            zIndex: 100,
                            cursor: 'pointer',
                            fontSize: '10px',
                            '&:nth-child(even)': {
                                backgroundColor: '#eeeeee52'
                            },
                            "&:hover": {
                                backgroundColor: "#9ec1c933"
                            }
                        },
                    }
                },
                MuiTableCell: {
                    styleOverrides: {
                        root: {
                            fontSize: '16px',
                            fontWeight: 400,
                            padding: '2px'
                        },


                    }
                },





            }
        });


    return (
        <ThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
                title={""}
                data={items}
                columns={columns}
                options={{
                    rowsPerPage: 50,
                    //disable download, print, viewColumns and filter options.
                    download: false,
                    print: false,
                    viewColumns: false,

                    rowsPerPageOptions: [10, 50, 100],
                    textLabels: {
                        body: {
                            noMatch: <div style={{}}>{loading === true ? "Loading Data" : "No devices found"} {loading === true && (<CircularProgress />)}</div>
                        }
                    },
                    selectableRows: screenWidth === 'xs' ? false : true,
                    responsive: screenWidth === 'xs' ? 'stacked' : 'standard',
                    onRowsDelete: (rows) => {
                        const idsToDelete = rows.data.map(d => items[d.dataIndex].id);
                        handleDelete(idsToDelete);


                    },
                    onRowClick: async (a, b) => {
                        const item = items.find(item => item.id === a[0]);
                        handleEdit(item);



                    }
                }}
            />
        </ThemeProvider>

    )
}

export default StationsTable;