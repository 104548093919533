

import {
    Badge,
    IconButton,
    List,
    ListItem,
    Popover,
    Tooltip,
    Typography
} from '@mui/material';
import React from 'react';
import { faCheckCircle, faCircle, faCopy, faEdit, faEye, faEyeSlash, faSquare, faSquareCheck, faGear } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faDownload, faEllipsisH, faList, faPlus, faTags, faTrash } from '@fortawesome/free-solid-svg-icons';
import * as Papa from 'papaparse';
import moment from 'moment-timezone';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import uuid from 'react-uuid';


const MoreTableOptions = ({ visibleHeaders, deleteSelectedData, selectedData, setBulkEditor, getNestedProperty, setCurrentCell,
    setParametersModal, setCodesModal, headers, setHeaders, data, setData,
    type
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setCurrentCell(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const downloadCSV = () => {
        console.log(data);
        console.log(visibleHeaders);

        const csvData = data.map(data => {
            const row = {};
            visibleHeaders.forEach(header => {
                let value = getNestedProperty(data, header.id, header.getLabel, header?.optionType, header?.options, header?.decimals);
                if (header.type === 'date' && value) {
                    value = moment(value, 'x').format('MM/DD/YY HH:mm');
                }
                row[header.headerName] = value;  // Using header.headerName as key
            });
            return row;
        });

        console.log(csvData);

        const csv = Papa.unparse(csvData);
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'data.csv';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    };


    return (
        <div style={{
            display: 'inline',

        }}>
            <IconButton onClick={handleClick}

            >
                <Tooltip title="More Options" placement="bottom">
                    <FontAwesomeIcon icon={faEllipsisH}
                        size="lg" style={{ color: '#3f51b5', cursor: 'pointer' }} />
                </Tooltip>



            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}

                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <List sx={{
                    width: 300,
                    padding: 0,

                }}>
                    <ListItem
                        key={'createTemplate'}
                        dense
                        button
                        disabled={selectedData.length === 0 ? true : false}
                        onClick={() => {
                            deleteSelectedData(selectedData)
                            handleClose();
                        }}
                        sx={{
                            borderBottom: '1px solid #e0e0e0',
                            paddingTop: 1,
                            paddingBottom: 1,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrash} style={{ color: '#3f51b5', marginRight: 10 }} />
                        <Typography variant="body1" color="black" fontSize={16} fontWeight={500}>Delete Selected Data</Typography>
                    </ListItem>
                    <ListItem
                        key={'bulkEdit'}
                        dense
                        disabled={selectedData.length === 0 ? true : false}
                        button
                        onClick={() => {
                            setBulkEditor(true);
                            handleClose();
                        }}
                        sx={{
                            borderBottom: '1px solid #e0e0e0',
                            paddingTop: 1,
                            paddingBottom: 1,
                        }}
                    >
                        <FontAwesomeIcon icon={faEdit} style={{ color: '#3f51b5', marginRight: 10 }} />
                        <Typography variant="body1" color="black" fontSize={16} fontWeight={500}>Bulk Edit Selected</Typography>
                    </ListItem>


                    <ListItem
                        key={'createParameter'}
                        dense
                        button
                        onClick={() => {
                            setParametersModal(true);
                            handleClose();
                        }}
                        sx={{
                            borderBottom: '1px solid #e0e0e0',
                            paddingTop: 1,
                            paddingBottom: 1,
                        }}
                    >
                        <FontAwesomeIcon icon={faList} size="lg" style={{ color: '#3f51b5', marginRight: 10 }} />
                        <Typography variant="body1" color="black" fontSize={16} fontWeight={500}>Edit Parameters</Typography>
                    </ListItem>
                    <ListItem
                        key={'createParameter'}
                        dense
                        button
                        onClick={() => {
                            setCodesModal(true);
                            handleClose();
                        }}
                        sx={{
                            borderBottom: '1px solid #e0e0e0',
                            paddingTop: 1,
                            paddingBottom: 1,
                        }}
                    >
                        <FontAwesomeIcon icon={faTags} size="lg" style={{ color: '#3f51b5', marginRight: 10 }} />
                        <Typography variant="body1" color="black" fontSize={16} fontWeight={500}>Edit Codes</Typography>
                    </ListItem>
                    <ListItem
                        key={'addRow'}
                        dense
                        button
                        onClick={() => {
                            const dateHeader = headers.find(header => header.mappedField === 'date');
                            const codeHeader = headers.find(header => header.mappedField === 'codes');

                            console.log(dateHeader)
                            const item = {
                                id: uuid(), selected: false
                            }
                            headers.forEach(header => {
                                if (header.mappedField === 'codes') {
                                    item[header.name] = [''];
                                } else {
                                    item[header.name] = '';
                                }
                            });

                            const newData = [item, ...data];
                            console.log(newData)

                            setData(newData);

                            handleClose();
                        }
                        }
                        sx={{
                            borderBottom: '1px solid #e0e0e0',
                            paddingTop: 1,
                            paddingBottom: 1,
                        }}
                    >
                        <FontAwesomeIcon icon={faPlus} size="lg" style={{ color: '#3f51b5', marginRight: 10 }} />
                        <Typography variant="body1" color="black" fontSize={16} fontWeight={500}>Add Row</Typography>
                    </ListItem>







                </List>




            </Popover>
        </div>
    );
};

export default MoreTableOptions;
