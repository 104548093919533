import { Button, Checkbox, CircularProgress, Divider, IconButton, Input, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import React from 'react';

import DeleteForever from '@mui/icons-material/DeleteForever';

import axios, * as others from 'axios';

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/data')(Highcharts);

export default class StepperDataClean extends React.Component {
    constructor(props) {
        super(props);
        this.accordionContent = [];
        this.state = {
            data: this.props.data,
            depthColumn: this.props.depthColumn,
            activeParameters: this.props.activeParameters,
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            currentItem: this.props.currentItem,
            parameters: this.props.parameters,
            loading: true,
            newItem: {},
            editMode: false,
            showMarkers: false,
            undoArray: [],
            chartOptions: {
                credits: {
                    enabled: false
                },
                chart: {
                    height: 600,
                    resetZoomButton: {
                        position: {
                            align: 'left',
                            x: 20,
                            y: -20
                        }
                    },
                    plotOptions: {
                        series: {
                            stickyTracking: false
                        }
                    },

                    zoomType: 'x',

                },
                title: {
                    text: null
                },
                plotOptions: {
                    series: {
                        stickyTracking: false
                    }
                },
                yAxis: {
                    min: 0,
                    reversed: true,
                    title: {
                        text: 'Depth'
                    },
                    plotLines: [{
                        value: 0,
                        width: 5,
                        color: '#afcdf4'
                    }]
                },
                xAxis: {
                    min: 0,

                    opposite: true
                },
                legend: {},


                series: [],

                plotOptions: {
                    series: {
                        animation: false
                    },


                },

            },
            newItemDepth: '',
            newItemValue: '',
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
        if (this.props.data !== prevProps.data) {
            console.log(this.props.data)
            this.setState({ data: this.props.data })
        }
        if (this.props.activeParameters !== prevProps.activeParameters) {
            console.log(this.props.activeParameters)
            this.setState({ activeParameters: this.props.activeParameters })
        }
        if (this.props.depthColumn !== prevProps.depthColumn) {
            console.log(this.props.depthColumn)
            this.setState({ depthColumn: this.props.depthColumn })
        }

    }
    componentDidMount() {
        const { currentItem, parameters, data, activeParameters, depthColumn } = this.state;
        console.log(data)
        console.log(activeParameters)
        console.log(depthColumn)



        const newDepthData = depthColumn.data.map((d, i) => { return ({ x: d.x || d, y: d.y || depthColumn.data[i], highlight: false, color: '#afcdf4', marker: { radius: 4, zIndex: 0 } }) })
        depthColumn.data = newDepthData;


        const newActiveParameters = activeParameters.map((l, i) => {
            const data = l.data.map((d, index) => {
                console.log(d)
                return ({
                    x: d.x || d, y: d.y || newDepthData[index].x, highlight: false, color: d.highlight ? 'orange' : d.markerColor, marker: {
                        radius: d.highlight ? 10 : 4, zIndex: d.highlight ? 100 : 0,
                    }
                })
            });

            const series = {
                ...l,
                active: true,
                visible: true,
                color: l.color,
                stickyTracking: false,
                type: 'spline',
                name: l.newParameter.label,
                data,
                marker: {
                    enabled: true,
                },
                cursor: 'pointer',




            }
            return (series)
        })
        console.log(newActiveParameters)
        this.handleCheck(newActiveParameters)

        this.setState({ activeParameters: newActiveParameters, loading: false, depthColumn })
        console.log(depthColumn)

    }




    updateTable = (index, boo, val) => {
        const { currentItem } = this.state;
        currentItem.data[index][boo] = val;
        this.setState({ currentItem })
        this.handleCheck(currentItem);
    }

    deletePoint = (l, index) => {
        const { currentItem, undoArray, activeParameters, depthColumn } = this.state;
        console.log(l)
        console.log(index)
        activeParameters.forEach((parameter) => {
            console.log(parameter)
            undoArray.push({ seriesKey: parameter.key, ...parameter.data[index] });
            parameter.data.splice(index, 1);
            this.setState({ activeParameters, undoArray })
            this.handleCheck(activeParameters);
            console.log(undoArray)

        })


        this.props.openSnack("success", "Points deleted")
        depthColumn.data.splice(index, 1);
        this.setState({ depthColumn })



    }

    undoItem = (length) => {
        const { activeParameters, depthColumn } = this.state;
        const { undoArray } = this.state;
        const keys = [...Array(length - 1).keys()]
        keys.map((key, i) => {
            const { seriesKey, x, y } = undoArray[key];
            this.addPoint(seriesKey, x, y);
            const index = activeParameters.map((l) => { return (l.key) }).indexOf(seriesKey);
            activeParameters[index].data.push(undoArray[key]);
            this.setState({ activeParameters })
            this.handleCheck(activeParameters);
            const newUndoArray = undoArray.splice(0, key + 1);
            this.setState({ undoArray: newUndoArray })

            if (keys.length === i + 1) {
                this.props.openSnack("success", "Points restored")
                depthColumn.data.push(undoArray[key]);
            }


        })


    }

    addPoint = (seriesKey, x, y) => {
        const { activeParameters } = this.state;
        const index = activeParameters.map((l) => { return (l.key) }).indexOf(seriesKey);
        activeParameters[index].data.push({ x, y, highlight: false, color: '#afcdf4', marker: { radius: 4, zIndex: 0 } });
        this.setState({ activeParameters })

        this.handleCheck(activeParameters);

        this.sortData(activeParameters);
    }





    sortData = (data) => {
        const { currentItem, activeParameters } = this.state;
        console.log("SORT DATA")
        data.map((d, i) => {
            activeParameters.data = [];
            this.setState({ activeParameters })
            this.handleCheck(activeParameters);
            setTimeout(() => {
                activeParameters.data = [...data[i].data];
                this.setState({
                    activeParameters
                })
            }, 50);
        })



    }





    highlightPoint = (index) => {



        const { currentItem, undoArray, activeParameters, depthColumn } = this.state;

        activeParameters.forEach((parameter) => {
            parameter.data.forEach(item => item.highlight = false);
            parameter.data[index].highlight = true;
            this.setState({ activeParameters, })


        })



    }
    highlightPoint2 = (index) => {
        const { currentItem, depthColumn } = this.state;
        this.focusTextInput(index);
        depthColumn.data[index].highlight = false;
        this.setState({ depthColumn })
    }

    focusTextInput = (index) => {
        // Explicitly focus the text input using the raw DOM API
        // Note: we're accessing "current" to get the DOM node
        this.accordionContent[index].focus();

    }

    unhighlightPoint = (index) => {
        const { currentItem } = this.state;
        const { activeParameters, depthColumn } = this.state;
        activeParameters.forEach((parameter) => {
            parameter.data.forEach(item => item.highlight = false);
            this.setState({ activeParameters, })
        })
        depthColumn.data.forEach(item => item.highlight = false);
        this.setState({ depthColumn })

    }


    handleCheck = (activeParameters) => {
        // Clears running timer and starts a new one each time the user types
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.toggleCheck(activeParameters);
        }, 1000);
    }

    toggleCheck = async (activeParameters) => {
        this.props.updateActiveParameters(activeParameters)
    }







    render() {

        const { account, loading, chartOptions, currentItem, newItem, newItemDepth, newItemValue, editMode, showMarkers, undoArray, activeParameters, depthColumn } = this.state;

        const data = activeParameters.map((l, i) => {
            const data = l.data.map((d, index) => {

                return ({
                    ...d, color: d.highlight ? 'orange' : d.markerColor, marker: {
                        radius: d.highlight ? 10 : 4, zIndex: d.highlight ? 100 : 0,
                    }, visible: d.active
                })
            });

            const series = {

                ...l,
                data,

                cursor: 'pointer',
                point: {
                    events: {
                        click: (a, b) => {
                            if (editMode === true) {
                                this.deletePoint(l, a.point.index)
                            }
                            else {

                                this.highlightPoint(a.point.index)
                                this.highlightPoint2(a.point.index)
                            }

                        }
                    }
                }



            }
            return (series)
        })




        chartOptions.series = data;



        const options = {
            ...chartOptions,

            tooltip: {
                formatter() {



                    const values = activeParameters.filter((a) => a.active).map((a) => { return ({ data: a.data[this.point.index].x, parameter: a.newParameter.label }) });
                    return `<b>Depth: ${Number(this.point.y).toFixed(2)} FT</b>
                        ${values.map((a) => { return (`<br>${a.parameter}: ${Number(a.data).toFixed(2)}`) }).join('')}`;



                }
            }
        }




        if (loading) {
            return (
                <Row style={{}}>
                    <Col span={23}>
                        <CircularProgress />
                    </Col>

                </Row>
            )
        }
        else {
            return (
                <Row style={{}} gutter={[24, 24]}>
                    <Col span={24}>
                        <Row gutter={[12, 12]}>
                            <Col>
                                <Button style={{ textTransform: 'none' }} variant={editMode ? 'contained' : 'outlined'} color={editMode ? 'primary' : 'secondary'}
                                    onClick={() => {
                                        this.sortData(activeParameters);
                                        this.setState({ editMode: !editMode })
                                    }}>Edit</Button>

                            </Col>
                            {/* {editMode && (
                                <Col>
                                    <Button style={{ textTransform: 'none' }} variant={editMode ? 'outlined' : 'contained'} color={editMode ? 'secondary' : 'primary'}
                                        onClick={() => {
                                            if (undoArray.length > 0) {
                                                this.undoItem(activeParameters.length)
                                            }
                                            else {
                                                this.props.openSnack('error', 'No more undo items')
                                            }


                                        }}
                                    >Undo</Button>
                                </Col>
                            )} */}
                            <Col>
                                <Button style={{ textTransform: 'none' }} variant={showMarkers ? 'contained' : 'outlined'} color={showMarkers ? 'primary' : 'secondary'}
                                    onClick={() => {


                                        chartOptions.series[0].marker.enabled = !chartOptions.series[0].marker.enabled;
                                        this.setState({ chartOptions, showMarkers: !showMarkers })


                                    }}>Show Markers</Button>
                            </Col>

                        </Row>





                    </Col>

                    <Col span={24}>
                        <Row gutter={[12, 12]}>
                            {activeParameters.map((parameter, index) => {
                                return (
                                    <Col><Checkbox key={index} checked={parameter.visible} onChange={(e) => {
                                        activeParameters[index].visible = e.target.checked;
                                        this.setState({ activeParameters })
                                    }
                                    } />
                                        <span> <Typography variant="body2">{parameter.newParameter.name}</Typography></span>

                                    </Col>

                                )
                            })}
                        </Row>

                    </Col>

                    <Col span={16}>
                        <div ref={el => (this.container = el)} style={{ height: 600, width: '100%', }}>
                            {loading === false && (
                                <HighchartsReact
                                    constructorType={"chart"}
                                    highcharts={Highcharts}
                                    ref={"chart"}
                                    options={options}
                                    allowChartUpdate={true}
                                    immutable={false}
                                    updateArgs={[true, true, true]}
                                />
                            )}
                        </div>
                    </Col>
                    <Col span={8} >
                        <Row>
                            <Col span={24}>
                                <Row gutter={[16, 16]}>
                                    <Col span={10}>
                                        <Typography variant="body1" fontSize={16} fontWeight={500}>Depth</Typography>
                                    </Col>
                                    <Col span={4}>
                                        <Typography variant="body1" fontSize={16} fontWeight={500}>Delete</Typography>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={24} style={{ height: 400, overflowY: 'scroll', overflowX: 'hidden' }} >
                                {depthColumn.data.sort((a, b) => a.x - b.x).map((d, i) => {
                                    return (
                                        <Row gutter={[16, 4]}>
                                            <Col span={10}>
                                                <Input inputRef={ref => (this.accordionContent[i] = ref)}
                                                    onBlur={(e) => { this.unhighlightPoint(i) }}
                                                    onFocus={(e) => { this.highlightPoint(i) }}
                                                    value={d.x}
                                                    onChange={(e) => {
                                                        depthColumn.data[i].x = d.x;
                                                        this.setState({ depthColumn })

                                                    }}
                                                />
                                            </Col>
                                            <Col span={4}>
                                                <IconButton onClick={() => {
                                                    this.deletePoint(d, i)
                                                }} ><DeleteForever /></IconButton>
                                            </Col>
                                        </Row>

                                    )
                                })}
                            </Col>

                        </Row>




                    </Col>
                    <Col span={24}>
                        <Divider />
                    </Col>



                </Row >
            );
        }


    }
} 