import { Button, Divider } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { doc, getFirestore, setDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import uuid from 'react-uuid';
import ProbeParameters from '../../../extra/probeParams';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '1px solid rgba(0, 0, 0, .3)',
    boxShadow: 24,
    p: 4,
    borderRadius: '24px',
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
};

const ErrorModal = ({
    errorModal,
    setErrorModal,
    currentRow,
    parameters,
    codes,
    stations,
    unitsArray,
    headers,
    openSnack,
    userInfo,
    setParameters,
    data




}) => {

    const handleOpen = () => setErrorModal(true);
    const handleClose = () => {
        setErrorModal(false);
        setErrors([]);
        setWarnings([]);
    }
    const [errors, setErrors] = useState([])
    const [warnings, setWarnings] = useState([])

    useEffect(() => {

    }, [errorModal])

    useEffect(() => {
        getErrorsAndWarnings(errorModal)
    }, [currentRow, parameters, stations, errorModal])




    async function getErrorsAndWarnings(row) {

        const { errors, warnings } = checkErrors(row);


        setErrors(errors);
        setWarnings(warnings);
    }

    function checkErrors(row) {

        const rowData = row;


        const fieldsToCheck = [
            { name: 'parameterid', label: 'Parameter' },
            { name: 'nodeid', label: 'Node' },
        ];
        const validOptions = {
            'parameterid': [...parameters, ...ProbeParameters],
            'nodeid': stations,
        };

        const errors = [];
        const warnings = [];

        // Checking 'parameters' and 'node'
        fieldsToCheck.forEach(field => {
            const header = headers.find(header => header.name === field.name);

            const options = validOptions[field.name];
            const value = rowData?.[header?.name];

            console.log(field)
            if (!value || value === '' || value === null || value === undefined) {
                console.log(value)
                errors.push({
                    type: `${field.label}-blank`,
                    message: `${field.label} is blank.`
                });
            } else if (!options.find(option => option.label === value)) {

                errors.push({
                    type: `${field.label}-missing`,
                    message: `${field.label} does not exist in the ${field.label} list.`,
                    missingItem: value
                });

            }
        });

        // Check if 'value' and 'date' are blank, null, or undefined
        const valueHeader = headers.find(header => header.name === 'value');
        const value = rowData?.[valueHeader?.name];
        const dateHeader = headers.find(header => header.name === 'time');

        if (value === '' || value === null || value === undefined) {
            console.log(value)
            errors.push({
                type: 'blank',
                message: 'Value is blank.'
            });

        } else if (typeof value !== 'number' && isNaN(Number(value))) {
            errors.push({
                type: 'notNumber',
                message: 'Value is not a valid number.'
            });

        }

        if (rowData?.[dateHeader?.name] == '' || rowData?.[dateHeader?.name] == null || rowData?.[dateHeader?.name] == undefined) {

            errors.push({
                type: 'blankDate',
                message: 'Date is blank.'
            });

        }

        // Checking 'units'
        const unitsHeader = headers.find(header => header.name === 'unitid');
        const unitsValue = rowData?.[unitsHeader?.name];
        const unitExists = unitsArray.some(unit => unit.label === unitsValue);
        if (!unitsValue || !unitExists) {
            warnings.push({
                type: 'units',
                message: 'Units is blank.'
            });

        }

        const codeHeader = headers.find(header => header.name === 'tags');
        const codeValue = rowData?.[codeHeader?.name];
        if (Array.isArray(codeValue) && codeValue.length === 1 && codeValue[0] !== '') {
            codeValue.forEach(code => {
                const check = codes.find((option) => option.label === code);
                if (!check) {
                    warnings.push({
                        type: 'codes',
                        message: `Code ${code} does not exist in the codes list.`
                    });
                }
            })
        }

        return { errors, warnings };
    }


    return (
        <div>
            <Modal
                open={errorModal !== false}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{

                }}

                slotProps={{
                    backdrop: {
                        style: {
                            backgroundColor: 'transparent'
                        }
                    }
                }}
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" fontWeight={700} fontSize={28} component="h2">
                        Errors editor
                    </Typography>
                    <Typography id="modal-modal-description" variant="caption" fontSize={13} sx={{ mt: 2 }}>
                        Make changes to resolve your errors.
                    </Typography>

                    <Typography sx={{ mt: 2 }} id="modal-modal-title" variant="body1" fontWeight={600} fontSize={20} component="h2">
                        Errors
                    </Typography>
                    <Divider sx={{ mb: 1 }} />
                    {errors?.map((error, index) => {

                        return (
                            <div
                                key={index}
                                style={{ display: 'flex' }}
                            >
                                <Typography
                                    sx={{
                                        mt: 1,
                                        color: 'red',
                                        flexShrink: 0 // Ensures this part doesn't shrink
                                    }}
                                    id="modal-modal-title"
                                    variant="body1"
                                    fontWeight={700}
                                    fontSize={15}
                                    component="span"
                                >
                                    {index + 1}.
                                </Typography>
                                <Typography
                                    sx={{
                                        mt: 1,
                                        color: 'red',
                                        marginLeft: '1em' // Indent the warning text
                                    }}
                                    id="modal-modal-title"
                                    variant="body1"
                                    fontWeight={400}
                                    fontSize={15}
                                    component="span"
                                >
                                    {error.message}

                                    {error.type === 'Parameter-missing' && (
                                        <>
                                            <Typography color={'black'} sx={{ display: 'block' }} variant="caption" fontWeight={600} fontSize={13} component="span"
                                            >
                                                Suggestion:
                                            </Typography>
                                            <Typography color={'black'} sx={{ display: 'block' }} variant="caption" fontWeight={400} fontSize={13} component="span"
                                            >
                                                Click here to create Parameter,
                                                <Button
                                                    variant='link'
                                                    style={{
                                                        padding: 0,
                                                        fontSize: 13,
                                                        fontWeight: 600,
                                                        marginLeft: 5,
                                                        textTransform: 'none',
                                                        color: 'blue',
                                                    }}
                                                    onClick={async () => {
                                                        const newParameterItem = {
                                                            company: userInfo.currentCompany,
                                                            account: userInfo.currentAccount,
                                                            key: uuid(),
                                                            archived: false,
                                                            name: error?.missingItem,
                                                            label: error?.missingItem,
                                                        }
                                                        const updatedParameters = [newParameterItem, ...parameters];
                                                        //check if parameter already exists
                                                        const exists = parameters.find((param) => param.label.toLowerCase() === error?.missingItem.toLowerCase());
                                                        if (exists) {
                                                            openSnack('error', 'Parameter already exists');
                                                            return;
                                                        }
                                                        const db = getFirestore();
                                                        const docRef = doc(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'parameters', newParameterItem.key);
                                                        await setDoc(docRef, newParameterItem);
                                                        openSnack('success', 'Parameter added successfully');
                                                        setParameters(updatedParameters);
                                                    }}
                                                >
                                                    {error?.missingItem}
                                                </Button>
                                            </Typography>
                                        </>
                                    )}
                                </Typography>

                            </div>
                        );
                    })}

                    <Typography sx={{ mt: 3 }} id="modal-modal-title" variant="body1" fontWeight={600} fontSize={20} component="h2">
                        Warnings
                    </Typography>
                    <Divider sx={{ mb: 1 }} />

                    {warnings?.map((warning, index) => {
                        return (
                            <div
                                key={index}
                                style={{ display: 'flex' }}
                            >
                                <Typography
                                    sx={{
                                        mt: 1,
                                        color: '#ff7300',
                                        flexShrink: 0 // Ensures this part doesn't shrink
                                    }}
                                    id="modal-modal-title"
                                    variant="body1"
                                    fontWeight={700}
                                    fontSize={15}
                                    component="span"
                                >
                                    {index + 1}.
                                </Typography>
                                <Typography
                                    sx={{
                                        mt: 1,
                                        color: '#ff7300',
                                        marginLeft: '1em' // Indent the warning text
                                    }}
                                    id="modal-modal-title"
                                    variant="body1"
                                    fontWeight={400}
                                    fontSize={15}
                                    component="span"
                                >
                                    {warning.message}
                                </Typography>
                            </div>
                        );
                    })}








                </Box>
            </Modal>
        </div >
    );
}

export default ErrorModal;
