import React, { useState } from "react";
import {
    Box, Grid, Typography, OutlinedInput, FormControl, InputLabel, Select, MenuItem,
    InputAdornment
} from '@mui/material';


const Amount = (
    {
        record,
        items,
        setItems,
        screenWidth,
        openSnack,
        userInfo,
        updateRecord,
        stocks,
        setStocks,
        accounts,
        setDisableDrag





    }

) => {
    const [isFocused, setIsFocused] = useState(false);

    const options = [
        { label: "mg", key: "mg" },
        { label: "g", key: "g" },
        { label: "kg", key: "kg" },
        { label: "oz", key: "oz" },
        { label: "lb", key: "lb" },
        { label: "fl-oz", key: "fl-oz" },
        { label: "cup", key: "cup" },
        { label: "pnt", key: "pnt" },
        { label: "qt", key: "qt" },
        { label: "gal", key: "gal" },
        { label: "ft3", key: "ft3" },
    ];



    return (
        <>
            <Typography variant="h6" component="div" color={'rgba(0,0,0,0.8)'} fontWeight={700} fontSize={13}>
                Total Amount
            </Typography>
            <Grid container alignItems="flex-end" style={{ marginBottom: 10 }}>
                <Grid item xs={7}>

                    <OutlinedInput
                        type="number"
                        size="small"
                        value={record?.quantity || ''}
                        onChange={(e) => {
                            const value = e.target.value;
                            updateRecord({ ...record, quantity: value === '' ? '' : Number(value) });
                        }}
                        onClick={(e) => {
                            console.log('CLICKED')
                            e.stopPropagation();
                            e.preventDefault();
                            setDisableDrag(true);
                        }}
                        onBlur={(e) => {
                            console.log('BLURRED')
                            e.stopPropagation();
                            e.preventDefault();
                            setDisableDrag(false);
                        }}

                        placeholder="Amount"
                        fullWidth
                        style={{
                            borderRadius: 4,
                            fontSize: 14,
                            backgroundColor: 'white'
                            // Ensure input takes full width
                        }}
                    />
                </Grid>
                <Grid item xs={5}>
                    <Select
                        labelId="unit-select-label"
                        id="unit-select"
                        value={record?.units || ''}
                        onChange={(e) => {
                            updateRecord({ ...record, units: e.target.value || null })
                        }}
                        onClick={(e) => {
                            console.log('CLICKED')
                            e.stopPropagation();
                            e.preventDefault();
                            setDisableDrag(true);
                        }}
                        onBlur={(e) => {
                            console.log('BLURRED')
                            e.stopPropagation();
                            e.preventDefault();
                            setDisableDrag(false);
                        }}
                        displayEmpty
                        size="small"
                        style={{
                            fontSize: 14,
                            maxHeight: 40, // Align the text to right
                            backgroundColor: 'white'
                        }}
                        placeholder="units"
                    >
                        {options.map((option, index) => (
                            <MenuItem key={index} value={option.key}>{option.label}</MenuItem>
                        ))}
                    </Select>
                </Grid>
            </Grid>

        </>
    );
};

export default Amount;
