
import React, { useEffect, useState } from 'react';
import {
    CircularProgress,
    Typography,
    Checkbox,
    IconButton,
    TextField,
    Select,
    MenuItem
} from '@mui/material';
import { Col, Row } from 'antd';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faFile, faGripVertical, faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';
import { uploadFile } from './firestore';
import uuid from 'react-uuid';
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import './carouselSettings.css';
import toGeoJSON from '@mapbox/togeojson';
import { DOMParser } from 'xmldom';

const DragHandle = sortableHandle(() => (
    <div
        className="file-handle"
        style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '20px',
            height: '20px',
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'grab',
        }}
    >
        <FontAwesomeIcon icon={faGripVertical} />
    </div>
));
const SortableItem = SortableElement(({ file, index, removefile }) => (

    <Row key={file.key} 
        style={{
            position: 'relative',
            padding: 5,
            border: '1px solid rgba(0, 0, 0, 0.3)',
            backgroundColor: '#f5f5f5',
            borderRadius: 5,
            boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)',
        }}>
        <Col span={20}>
            <DragHandle />
        </Col>
        <Col span={4} styel={{ textAlign: 'right' }}>
            <IconButton
                onClick={() => {
                    console.log('remove file', file);
                    removefile(file.key);
                }}
                style={{
                  
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',

                }}
            >
                X
            </IconButton>
        </Col>
        <Col span={24}>
            <FontAwesomeIcon icon={faFile} size="2xl" />
        </Col>
        <Col span={24}>
            <Typography variant="body1">{file.name}</Typography>
        </Col>
    </Row>








));

const SortableList = SortableContainer(({ files, removefile }) => {
    return (
        <Row gutter={[12, 12]}>
            {files.map((file, index) => (
                <Col span={8} >
                    <SortableItem key={`item-${file.key}`} index={index} file={file} removefile={removefile} />
                </Col>
            ))}
        </Row>
    );
});

export default function KMLFiles(props) {
    const {
        userInfo,
        openSnack,
        handleToggle,
        item,
        handleRemove
    } = props;
    const [loading, setLoading] = useState(true);
    const [uploading, setUploading] = useState(false);
    const [files, setFiles] = useState(item.kmls || []);

    useEffect(() => {
        getInfo();
        console.log(item)
    }, []);


    async function getInfo() {

        setTimeout(() => {
            setLoading(false);
        }
            , 1000);

    }

    const onDrop = async (acceptedFiles) => {
        acceptedFiles.forEach(async (file) => {

            const key = uuid();
            const path = `files/${userInfo.currentCompany}/widgets/${key}-${file.name}`;

            try {
                openSnack('success', 'File Uploading...');
                setUploading(true);
                const fileInfo = await uploadFile(file, path);
                const kml = {
                    downloadURL: fileInfo.downloadURL,
                    title: '',
                    description: '',
                    name: file.name,
                    key,

                }
                console.log(kml)
                files.push(kml);
                setFiles(files);
                handleToggle(files)
                setUploading(false);
                openSnack('success', 'File Uploaded');
            } catch (error) {
                console.log(error);
                openSnack('error', 'File Upload Failed');
            }

        });
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const removefile = (key) => {
        console.log(key)
        const newfiles = files.filter((file) => file.key !== key);
        setFiles(newfiles);
        handleRemove(key)

    };



    const onSortEnd = ({ oldIndex, newIndex }) => {
        setFiles(arrayMoveImmutable(files, oldIndex, newIndex));
    };

    if (loading) {
        return (
            <div className="loading">
                <CircularProgress />
                <Typography variant="body1">Loading Data</Typography>
            </div>
        );
    } else {
        return (
            <Row
                gutter={[8, 8]}
                style={{ height: '100%', width: '100%', display: 'flex' }}
            >



                <Col span={24}>
                    {uploading === true ? (
                        <div className="dropzone drag-active">
                            <FontAwesomeIcon icon={faSpinner} spin />
                            <Typography variant="body1">Uploading...</Typography>
                        </div>
                    ) : (
                        <div
                            className={`dropzone${isDragActive ? ' drag-active' : ''}`}
                            {...getRootProps()}
                            style={{
                                minHeight: '100px',
                                cursor: 'pointer',
                                borderWidth: isDragActive ? '4px' : '2px', // Increase border width when dragging
                                borderStyle: 'dashed',
                                borderColor: '#ccc',
                                transition: 'border-width 0.2s ease-in-out', // Add transition for smooth border-width change,
                            }}
                        >
                            <input {...getInputProps({ multiple: true, })} />
                            <Typography variant="body1" fontSize={16} fontWeight={400}>
                                Drag 'n' drop KML files here or click to add files
                            </Typography>
                        </div>
                    )}
                </Col>
                <Col span={24}>
                    <SortableList
                        axis="xy"
                        files={files}
                        onSortEnd={onSortEnd}
                        removefile={removefile}
                        helperClass="sortable-helper"
                    />
                </Col>




            </Row>
        );
    }
}
