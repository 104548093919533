import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Divider, Fab, IconButton, Input, InputAdornment, Typography } from '@mui/material';
import { Col, Popconfirm, Row } from 'antd';
import { collection, deleteDoc, doc, getDoc, getDocs, getFirestore, query, setDoc, updateDoc, where } from 'firebase/firestore';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import "../../extra/hoverStyle.css";
import PhotoTimeline from './photoTimeline';

export default class Albums extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            data: [],
            filteredData: [],
            filters: [],
            searchText: '',
            loading: true,
            type: this.props.type,
            listType: 'albums'
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0)
        this.queryData()
        this.queryListType()
    }

    queryData = async () => {
        const { userInfo, type } = this.state;
        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'albums',), where('account', '==', userInfo.currentAccount), where('archived', '==', false));
        const data = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            data.push(doc.data())


        });


        data.sort((a, b) => a.name.localeCompare(b.name))
        this.setState({ data, loading: false })

        this.filterData(data, '', [])
    }

    queryListType = async () => {
        const { userInfo } = this.state;
        const db = getFirestore();
        const docRef = doc(db, "users", userInfo.id, 'preferences', 'albumTypes', 'accounts', userInfo.currentAccount);

        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            this.setState({ listType: docSnap.data().listType })
            console.log("Document data:", docSnap.data());
        } else {
            //set listtype
            const docRef = doc(db, "users", userInfo.id, 'preferences', 'albumTypes', 'accounts', userInfo.currentAccount);
            await setDoc(docRef, {
                listType: 'albums'
            })
            console.log('albums')
            this.setState({ listType: 'albums' })
        }
    }




    filterData = (data, searchText, filters) => {
        const filteredData = data.filter(d => d.name.match(new RegExp(searchText, "i")))
        this.setState({ filteredData })

    }

    updateListType = async (listType) => {
        const { userInfo } = this.state;
        const db = getFirestore();
        const docRef = doc(db, "users", userInfo.id, 'preferences', 'albumTypes', 'accounts', userInfo.currentAccount);
        await updateDoc(docRef, {
            listType: listType
        })
        this.setState({ listType })
    }

    render() {

        const { userInfo, account, data, filteredData, loading, searchText, filters, type, listType } = this.state;
        const db = getFirestore();
        const CreateItemButton = () => {
            let navigate = useNavigate();

            return (
                <Fab style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="medium"
                    onClick={async () => {
                        const itemRef = doc(db, "users", userInfo.id, 'currentItems', 'currentAlbum');
                        await setDoc(itemRef, {
                            key: 'none',
                        }).then(() => {
                            navigate('/album')
                        })
                    }
                    }>
                    <AddIcon />
                </Fab >
            )
        }
        const EditItem = ({ item }) => {
            let navigate = useNavigate();
            return (
                <IconButton style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="small"
                    onClick={async () => {
                        const itemRef = doc(db, "users", userInfo.id, 'currentItems', 'currentAlbum');
                        await setDoc(itemRef, {
                            key: item.key
                        }).then(() => {
                            navigate('/album')
                        })
                    }}>
                    <EditIcon />
                </IconButton>
            )
        }

        const DeleteItem = ({ item }) => {
            let navigate = useNavigate();
            return (
                <Popconfirm
                    title="Are you sure to delete this album?"
                    onConfirm={async () => {
                        const itemRef = doc(db, "clients", userInfo.currentCompany, 'albums', item.key);
                        await updateDoc(itemRef, {
                            archived: true
                        }).then(async () => {

                            const queryData = query(collection(db, "clients", userInfo.currentCompany, 'photos'), where('albumKey', '==', item.key));

                            const snap = await getDocs(queryData);
                            snap.forEach(async (doc) => {
                                await deleteDoc(doc(db, "clients", userInfo.currentCompany, 'photos', doc.data().key))


                            });


                        }).then(() => {
                            this.props.openSnack('success', 'Album deleted.')
                            const newData = filteredData.filter((f) => f.key !== item.key)
                            this.setState({ filteredData: newData })
                        })

                    }}
                    okText="Yes"
                    cancelText="No"
                >
                    <IconButton style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="small"
                    >
                        <DeleteForeverIcon />
                    </IconButton>
                </Popconfirm>


            )
        }

        const RowItem = ({ item }) => {

            let navigate = useNavigate();
            return (
                <Col xs={12} sm={6} style={{ cursor: 'pointer', padding: 8 }} className="rowHover"
                >
                    <Row gutter={[8, 8]} align="middle"  >



                        <Col span={24} onClick={async () => {

                            const itemRef = doc(db, "users", userInfo.id, 'currentItems', 'currentAlbum');
                            await setDoc(itemRef, {
                                key: item.key
                            }).then(() => {
                                navigate('/album')
                            }).catch((err) => {
                                console.log(err)
                            })
                        }} >
                            {item.thumbNail ?
                                <img src={item.thumbNail.fileLink || ''} alt="No Photos" style={{ width: '100%', height: 200, borderRadius: 6, objectFit: 'cover' }} />
                                :
                                <img src={"https://firebasestorage.googleapis.com/v0/b/aquasource3.appspot.com/o/3f55e64-f7a3-c4db-5c30-3c2b2461c1f4%2Fphotos%2F4bf6b7-1e2-40-c6c6-272014d014?alt=media&token=50475933-2380-4e7c-9db4-7d413c2fb981"
                                } alt="No Photos" style={{ width: '100%', height: 200, borderRadius: 6 }} />}

                        </Col>
                        <Col span={20} onClick={async () => {

                            const itemRef = doc(db, "users", userInfo.id, 'currentItems', 'currentAlbum');
                            await setDoc(itemRef, {
                                key: item.key
                            }).then(() => {
                                navigate('/album')
                            }).catch((err) => {
                                console.log(err)
                            })
                        }}>
                            <Typography variant='h6'>{item.name}</Typography>
                            <Typography variant='bod1'>{item.description}</Typography>

                        </Col>


                        <Col sm={4} style={{ textAlign: 'right' }}>
                            <EditItem item={item} /> <DeleteItem item={item} />
                        </Col>




                    </Row>
                </Col>
            )
        }

        return (
            <Row style={{}} align="middle">
                <Col span={23}>
                    <Row align="middle">

                        <Col xs={16} sm={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>
                            <Typography variant="h4">Your Photo Albums</Typography>
                        </Col>
                        <Col xs={6} sm={4} style={{ paddingTop: 30, paddingBottom: 15, textAlign: 'right' }}>
                            <CreateItemButton />
                        </Col>
                        <Col span={24}>
                            <Divider />
                        </Col>
                    </Row>
                </Col>

                <Col span={23} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 30 }}>
                    <Row gutter={[8, 8]} align="middle" justify="start" style={{ width: '100%' }}>
                        <Col>
                            <Button style={{ textTransform: 'none' }} variant={listType === 'timeline' ? "contained" : 'outlined'} onClick={() => {
                                this.setState({ listType: 'timeline' })
                                this.updateListType('timeline')
                            }} >Timeline</Button>
                        </Col>
                        <Col>
                            <Button style={{ textTransform: 'none' }} variant={listType === 'albums' ? "contained" : 'outlined'} onClick={() => {
                                this.setState({ listType: 'albums' })
                                this.updateListType('albums')
                            }}>Albums</Button>
                        </Col>
                    </Row>
                </Col>





                <Col span={23} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 30 }}>

                    <Row>
                        {listType === 'albums' && (<Col xs={24} sm={12} style={{ paddingBottom: 8 }}>
                            <Input fullWidth
                                autoComplete='off'
                                value={searchText}
                                onChange={(e) => {
                                    this.setState({ searchText: e.target.value })
                                    if (e.target.value.length > 1) {
                                        this.filterData(data, e.target.value, filters)
                                    }
                                    else {
                                        this.filterData(data, '', filters)
                                    }
                                }}
                                id="input-with-icon-adornment"
                                startAdornment={
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                }
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            edge="end"
                                            onClick={() => {
                                                this.setState({ searchText: '' })
                                                this.filterData(data, '', filters)
                                            }}
                                        >
                                            <HighlightOffIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </Col>)}


                        {listType === 'albums' && (
                            <Col span={24}>
                                <Row align='middle' >
                                    {filteredData.map((d) => {
                                        return (
                                            <RowItem item={d} />

                                        )

                                    })}
                                </Row>
                            </Col>
                        )}

                        {listType === 'timeline' && (
                            <Col span={24}>
                                <PhotoTimeline userInfo={userInfo} openSnack={this.props.openSnack} />
                            </Col>
                        )}
                    </Row>

                </Col>




            </Row>
        );
    }
} 