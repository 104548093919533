import { faGear } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextField, Typography, Autocomplete, Modal, Box, Checkbox, Drawer, Fab } from '@mui/material';
import { Col, Divider, Row } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import { fetchStream, getItems, updateItem } from './utils';
import {
    StackedCarousel,
    ResponsiveContainer,
} from "react-stacked-center-carousel";
import Carousel from 'react-material-ui-carousel';
import Lightbox from 'react-image-lightbox';

const Card = React.memo(function (props) {
    const { data, dataIndex, } = props;
    const downloadURL = data?.[dataIndex]?.downloadURL;

    return (
        <div
            style={{
                width: "100%",
                height: 300,
                userSelect: "none",
            }}
            className="my-slide-component"
        >
            <img
                style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                    borderRadius: 0,
                }}
                draggable={false}
                src={downloadURL}
            />
        </div>
    );
});

const containerStyle = {
    width: "100%",
    height: "100%",
};





const TimelineCarousel = (props) => {
    const { widgetKey, userInfo, setMadeChanges, item, setItem } = props;
    const [photos, setPhotos] = useState([]);
    const [lastVisible, setLastVisible] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [openLightbox, setOpenLightbox] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const refCarousel = useRef(null);

    useEffect(() => {

        getPhotos(item?.camera?.itemID, null, 10)
    }, []);

    async function getPhotos(currentItem, startAfter, photoLimit) {

        const result = await fetchStream({ id: currentItem }, userInfo, 'timelapseImages', startAfter, photoLimit)
        setPhotos(prevPhotos => [...prevPhotos, ...result.items])
        setLastVisible(result.lastVisible);

        setHasMore(result.items.length === photoLimit);
    }

    const fetchMorePhotos = async () => {
        if (hasMore) {
            getPhotos(item.camera.itemID, lastVisible, 10);
        }
    }

    const handleChange = (currentIndex) => {
        if (currentIndex >= photos.length * 0.8) {
            fetchMorePhotos();
        }
    }








    return (
        <Row gutter={[8, 8]}>
            <Col span={24}>
                <Carousel
                    ref={refCarousel}
                    autoPlay={item.autoplay || false}
                    indicators={true}
                    navButtonsAlwaysVisible={true}
                    animation="slide"
                    interval={item.autoplaySeconds ? Number(item.autoplaySeconds) * 1000 : 5000}
                    timeout={500}
                    onChange={(currentIndex, source) => handleChange(currentIndex)}
                >
                    {photos.map((image, index) => (
                        <div
                            key={image.id}
                            style={{
                                width: '100%',
                                height: '100%',
                                position: 'relative',
                                paddingBottom: '56.25%', // 16:9 aspect ratio
                                overflow: 'hidden',
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                setOpenLightbox(true);
                                setPhotoIndex(index);
                            }}

                        >
                            <img
                                src={image.downloadURL}
                                alt={image.title}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                }}
                            />

                        </div>
                    ))}
                </Carousel>

                {openLightbox && (
                    <Lightbox
                        style={{ position: 'relative', zIndex: 9999999 }}
                        mainSrc={photos[photoIndex].downloadURL}
                        nextSrc={photos[(photoIndex + 1) % photos.length].downloadURL}
                        prevSrc={photos[(photoIndex + photos.length - 1) % photos.length].downloadURL}
                        onCloseRequest={() => setOpenLightbox(false)}
                        onMovePrevRequest={() =>
                            setPhotoIndex((photoIndex + photos.length - 1) % photos.length)
                        }
                        onMoveNextRequest={() =>
                            setPhotoIndex((photoIndex + 1) % photos.length)
                        }
                    />
                )}
            </Col>
        </Row>


    )

}



export default TimelineCarousel;