import {

} from "@mui/material";
import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { CodesList } from "../codesList";
import { queryCodes, queryParameters, queryStations } from "../../../../../../firebase/config";
import moment from 'moment-timezone';

import ProbeParameters from "../../../../../../extra/probeParams";
import DataTableHeader from "./databaseTable/dataTableHeader";
import { unitsArray } from "../../../../../../extra/unitsArray";
import { getStreams } from "../../../../../../dataStreams/newComponents/streamFunctions";
import weatherUndergroundParameters from "../../../../../../extra/weatherUndergroundParameters";
import TidalParameters from "../../../../../../extra/tidalParameters";


const DatabaseHome = ({ screenWidth, openSnack, userInfo, selectedPoints, setSelectedPoints, deletedPoints, setDeletedPoints, unselectFromGraph }) => {

    const [data, setData] = useState(selectedPoints);
    const [source, setSource] = useState('node');

    const [parameters, setParameters] = useState([]);
    const [stations, setStations] = useState([]);
    const [weatherStations, setWeatherStations] = useState([]);
    const [tidalStations, setTidalStations] = useState([]);
    const [codes, setCodes] = useState([]);
    const [selectedItems, setSelectedItems] = useState({})
    const [currentSidebar, setCurrentSidebar] = useState('items')
    const [items, setItems] = useState([])
    const [weatherUndergroundItems, setWeatherUndergroundItems] = useState([])
    const [tidalStationItems, setTidalStationItems] = useState([])
    const [drawer, setDrawer] = useState(true)
    const [updatingData, setUpdatingData] = useState(false)

    const [headers, setHeaders] = useState([
        { position: 1, headerName: "Date", name: "time", getLabel: false, width: 120, visible: true, minWidth: 140, optionType: 'date', type: 'date', bulkEdit: false },
        { position: 2, headerName: "Nodes", name: "nodeid", getLabel: false, width: 120, visible: true, minWidth: 140, optionType: 'nodeSelect', type: 'string', bulkEdit: false },
        { position: 3, headerName: "Parameters", name: "parameterid", getLabel: false, width: 140, visible: true, minWidth: 140, optionType: 'parameterSelect', type: 'string', bulkEdit: false },
        { position: 4, headerName: "Value", name: "value", getLabel: false, width: 120, visible: true, minWidth: 120, optionType: 'value', type: 'number', decimals: null, bulkEdit: true },
        { position: 5, headerName: "Units", name: "unitid", getLabel: false, width: 120, visible: true, minWidth: 120, optionType: 'unitSelect', type: 'string', bulkEdit: true },
        { position: 6, headerName: "Codes", name: "tags", getLabel: false, width: 120, visible: true, minWidth: 120, optionType: 'codeSelect', type: 'string', bulkEdit: true },
        { position: 7, headerName: "Offset", name: "offsetvalue", getLabel: false, width: 120, visible: true, minWidth: 120, optionType: 'offsetvalue', type: 'number', bulkEdit: true },
    ])
    const [wundergroundHeaders, setWundergroundHeaders] = useState([
        { position: 1, headerName: "Date", name: "time", getLabel: false, width: 120, visible: true, minWidth: 140, optionType: 'date', type: 'date' },
        { position: 2, headerName: "Stream", name: "streamid", getLabel: false, width: 120, visible: true, minWidth: 140, optionType: 'streamSelect', type: 'string' },

    ])
    const [tidalHeaders, setTidalHeaders] = useState([
        { position: 1, headerName: "Date", name: "time", getLabel: false, width: 120, visible: true, minWidth: 140, optionType: 'date', type: 'date' },
        { position: 2, headerName: "Stream", name: "streamid", getLabel: false, width: 120, visible: true, minWidth: 140, optionType: 'streamSelect', type: 'string' },

    ])

    const [preferences, setPreferences] = useState({
        sortItem: null,
        columnFilters: {},
    });




    useEffect(() => {
        window.scrollTo(0, 0)
        getInfo()
        /* updateDatabasePreferences(userInfo, defaultPreferences, 'sqlDatabase') */



    }, [])




    useEffect(() => {


        const modifiedData = selectedPoints.map(item => ({
            ...item,
            time: moment(item.x, 'x').format('YYYY-MM-DD HH:mm:ss.SSS'),
            value: item.y,
            offsetvalue: item.offset,
            selected: item?.selected || false,
            parameterid: [...parameters, ...ProbeParameters].find(p => p.key === item.parameterid)?.label || item.parameterid,
            nodeid: stations.find(s => s.id === item.nodeid)?.label || item.nodeid,
            unitid: unitsArray.find(u => u.key === item.unitid)?.label || item.unitid,
            tags: item.tags !== undefined ? item.tags : [],
            id: item.id,
            key: item.id,
            seriesKey: item.seriesKey
        }))
        localStorage.setItem('selectedPoints', JSON.stringify(modifiedData))


        setData(modifiedData)
    }, [selectedPoints])


    /* check if data matches prevData */
    useEffect(() => {
        /* check if data matches prevData */
        const prevData = JSON.parse(localStorage.getItem('prevData')) || [];
        if (JSON.stringify(data) !== JSON.stringify(prevData)) {

            localStorage.setItem('prevData', JSON.stringify(data))
            const newSelectedPoints = data.map(item => ({
                x: moment(item.time, 'YYYY-MM-DD HH:mm:ss.SSS').format('x'),
                y: item.value,
                offset: item.offsetvalue,
                parameterid: item.parameterid,
                nodeid: item.nodeid,
                unitid: unitsArray.find(u => u.label === item.unitid)?.key || item.unitid,
                tags: item.tags,
                id: item.id,
                key: item.id,
                selected: item?.selected || false,
                seriesKey: item.seriesKey
            }))

            setSelectedPoints(newSelectedPoints)
        }
        else {
            console.log('data matches prevData')
        }
    }, [data])



    useEffect(() => {
        const combinedList = [...parameters, ...ProbeParameters];
        // Use a Set to remove duplicates based on the 'key' property
        const uniqueList = Array.from(new Set(combinedList.map(item => item.key))).map(key => {
            return combinedList.find(item => item.key === key);
        });

        const masterParameters = uniqueList.sort((a, b) => a.label.localeCompare(b.label)).map((p, i) => {
            return {
                ...p,
                type: 'parameter',
            }
        })
        const masterStations = stations.sort((a, b) => a.stationLabel.localeCompare(b.stationLabel)).map((p, i) => {
            return {
                ...p,
                type: 'node',
            }
        })
        setItems([...masterParameters, ...masterStations])
    }, [parameters, stations])

    useEffect(() => {
        const combinedList = [...weatherUndergroundParameters].filter((k) => !["key", "lat", 'lng', 'time', 'account', 'company', 'location'].includes(k.key));


        // Use a Set to remove duplicates based on the 'key' property
        const uniqueList = Array.from(new Set(combinedList.map(item => item.key))).map(key => {
            return combinedList.find(item => item.key === key);
        });

        const masterParameters = uniqueList.sort((a, b) => a.label.localeCompare(b.label)).map((p, i) => {
            return {
                ...p,
                type: 'parameter',
            }
        })
        const masterStations = weatherStations.map((p, i) => {
            return {
                ...p,
                type: 'node',
            }
        })
        setWeatherUndergroundItems([...masterParameters, ...masterStations])
    }, [weatherStations])
    useEffect(() => {
        const combinedList = [...TidalParameters];
        // Use a Set to remove duplicates based on the 'key' property
        const uniqueList = Array.from(new Set(combinedList.map(item => item.key))).map(key => {
            return combinedList.find(item => item.key === key);
        });
        const masterParameters = uniqueList.sort((a, b) => a.label.localeCompare(b.label)).map((p, i) => {
            return {
                ...p,
                type: 'parameter',
            }
        })
        const masterStations = tidalStations.map((p, i) => {
            return {
                ...p,
                type: 'node',
            }
        })
        setTidalStationItems([...masterParameters, ...masterStations])
    }, [tidalStations])


    useEffect(() => {
        window.scrollTo(0, 0)
        getInfo(userInfo);
    }, []);
    useEffect(() => {
        setSelectedItems({})
    }, [source]);

    async function getInfo(userInfo) {

        const parameters = await queryParameters(userInfo)
        parameters.forEach((p, i) => {
            p.parameterType = 'Custom Paramter';
        })


        setParameters(parameters)
        const stations = await queryStations(userInfo, ['grab', 'both', 'sensor'])
        setStations(stations)
        const codes = await queryCodes(userInfo)
        const list = [...codes, ...CodesList].sort((a, b) => a.label.localeCompare(b.label))
        setCodes(list)

        const weatherStations = await getStreams(userInfo, 'wundergrounds');

        setWeatherStations(weatherStations)


        const tidalStations = await getStreams(userInfo, 'tidalStations');

        setTidalStations(tidalStations)
    }





    function checkErrors(data) {

        // List of mapped fields to be checked
        const fieldsToCheck = ['parameterid', 'nodeid'];

        // Corresponding lists of valid options
        const validOptions = {
            'parameterid': [...parameters, ...ProbeParameters],
            'nodeid': stations,
            'units': unitsArray,
        };

        // Count the errors
        let errorsCount = fieldsToCheck.reduce((count, field) => {
            const header = headers.find((header) => header.name === field);
            const options = validOptions[field];
            const check = options.find((option) => option.label === data?.[header?.name]);

            return count + (check ? 0 : 1);
        }, 0);

        // Check if 'value' is a number
        const valueHeader = headers.find((header) => header.name === 'value');
        const value = data?.[valueHeader?.name];
        if (isNaN(Number(value))) {
            errorsCount++;
        }
        if (value === null || value === undefined || value === '') {
            errorsCount++;
        }

        // Check if 'date' is a valid date
        const dateHeader = headers.find((header) => header.name === 'time');
        const date = data?.[dateHeader?.name];
        if (isNaN(new Date(date).getTime())) {
            errorsCount++;
        }

        let warningsCount = 0;

        const codeHeader = headers.find(header => header.name === 'tags');
        const codeValue = data?.[codeHeader?.name];
        if (Array.isArray(codeValue) && codeValue.length === 1 && codeValue[0] !== '') {
            codeValue.forEach(code => {
                const check = codes.find((option) => option.label === code);
                if (!check) {
                    warningsCount++;
                }
            })
        }

        const unitsHeader = headers.find(header => header.name === 'unitid');
        const unitsValue = data?.[unitsHeader?.name];

        const unitExists = unitsArray.some(unit => unit.label === unitsValue);
        if (!unitsValue || !unitExists) {
            warningsCount++;

        }



        return { errorsCount, warningsCount };


    }

    const getNestedProperty = (obj, path, header) => {


        const firstValue = path?.split('.').reduce((acc, part) => acc && acc[part], obj);
        const value = firstValue;



        if (header?.mappedField === 'value') {
            return Number(value);
        }
        if (header?.mappedField === 'date') {
            return Number(moment(value, header?.dateFormat || "MM/DD/YYYY").format('x'));
        }


        return value;
    };


    const applyFiltersAndSearch = (data, columnFilters) => {

        // Remove any columnFilter that has an array of 0
        Object.keys(columnFilters).forEach(key => {
            if (columnFilters[key].length === 0) {
                delete columnFilters[key];
            }
        });

        // Start by filtering only visible headers

        const visibleHeaders = headers?.filter(header => header?.visible);

        // Now, consider column filters and the search query
        return data.filter(item => {





            const columnFilterMatch = visibleHeaders?.every(header => {
                const value = getNestedProperty(item, header.name, header.getLabel, header?.optionType, header?.options, header?.decimals);



                let filterValue;
                if (header.optionType === 'time' && value !== undefined) {
                    filterValue = moment(value, 'x').format(
                        screenWidth === 'xs' ? 'MMM DD, YY ha' : 'MM/DD/YY ha'
                    );
                } else {
                    filterValue = value !== undefined ? value : "No Value"; // Adjust here
                }

                return columnFilters[header.name]
                    ? columnFilters[header.name].includes(filterValue)
                    : true;
            });

            return columnFilterMatch;
        });
    };



    const selectedNodes = Object.values(selectedItems).filter(item => item.type === 'node');


    const filteredData = applyFiltersAndSearch(
        data,
        preferences?.columnFilters || {},
    );





    return (

        <Row style={{
            display: 'flex',
            position: 'relative',

        }}>


            <Col span={24} style={{
                borderBottom: '1px solid #0000001f',
            }}>
                <Row>

                    <Col span={24}>
                        <Row>


                            <Col span={24} style={{
                                borderBottom: '1px solid #0000001f',

                            }}>

                                <DataTableHeader
                                    unselectFromGraph={unselectFromGraph}
                                    selectedItems={selectedItems}
                                    userInfo={userInfo}
                                    data={filteredData}
                                    fullData={data}
                                    setData={setData}
                                    headers={source === 'node' ? headers : source === 'wunderground' ? wundergroundHeaders : tidalHeaders}
                                    setHeaders={source === 'node' ? setHeaders : source === 'wunderground' ? setWundergroundHeaders : setTidalHeaders}
                                    codes={codes}
                                    weatherStations={weatherStations}
                                    tidalStations={tidalStations}
                                    setCodes={setCodes}
                                    stations={stations}
                                    setStations={setStations}
                                    parameters={parameters}
                                    setParameters={setParameters}
                                    checkErrors={checkErrors}
                                    unitsArray={unitsArray}
                                    openSnack={openSnack}
                                    updatingData={updatingData}
                                    setUpdatingData={setUpdatingData}
                                    columnFilters={preferences?.columnFilters}
                                    getNestedProperty={getNestedProperty}
                                    ProbeParameters={ProbeParameters}
                                    setColumnFilters={(newFilters) => {
                                        const newPreferences = {
                                            ...preferences,
                                            columnFilters: newFilters
                                        };
                                        setPreferences(newPreferences);

                                    }}
                                    deletedPoints={deletedPoints}
                                    setDeletedPoints={setDeletedPoints}
                                    selectedPoints={selectedPoints}
                                    setSelectedPoints={setSelectedPoints}
                                />
                            </Col>
                        </Row>
                    </Col>




                </Row>



            </Col >








        </Row >



    );


};

export default DatabaseHome;
