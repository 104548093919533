import React, { useState } from "react";


import { Box, Button, ButtonGroup, Grid, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import '../../dataTable.css';
import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { faCircleCheck, faCloudArrowUp, faUpload } from "@fortawesome/free-solid-svg-icons";
import {
    useCSVReader,
    lightenDarkenColor,
    formatFileSize,
} from 'react-papaparse';

import './uploadStyle.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import UploadTemplates from "../uploadCSV/templates";
import CSVNextStep from "../uploadCSV/csvNextStep";
import uuid from "react-uuid";


const GREY_DIM = '#686868';

const styles = {

    zoneHover: {
        borderColor: GREY_DIM,
        backgroundColor: 'white'
    },

};



const UploadComponentMulti = (props) => {
    const {
        templates,
        setTemplate,
        template,
        steps,
        setSteps,
        currentStep,
        item,
        setItem,
        nextStep,
        data,
        setData,
        headers,
        setHeaders,
        meta,
        setMeta,
    } = props;
    const { CSVReader } = useCSVReader();
    const [dragging, setDragging] = useState(false);

    const handleDragOver = () => {
        setDragging(true);
    };

    const handleDragLeave = () => {
        setDragging(false);
    };




    console.log(template)

    return (
        <Box sx={{ px: '5vw' }}>
            <Grid container spacing={3}



                sx={{
                    mt: 0,
                    px: 0,
                    pt: 2
                }} >



                <Grid item xs={12} sm={12} md={7} key={'upload'}
                    style={{


                    }}
                    sx={{
                        p: 2
                    }}


                >



                    <CSVReader
                        onUploadAccepted={(data, meta) => {
                            console.log(data)
                            const headers = data?.data[0].map((item) => {
                                return (
                                    {
                                        name: item, included: true, mappedField: null, archived: false, id: uuid(),

                                        minWidth: 160,
                                        width: item.length * 14 > 140 ? item.length * 14 : 140,
                                    }


                                )
                            });

                            const tableData = data?.data
                                .slice(1) // Slice from the second element to the end by default.
                                .filter(d => !d.every(item => item === '')) // Filter out any row that is just ['']
                                .map(d => {
                                    const obj = {};
                                    // Loop through each item in the row array and assign it to the corresponding header name in the object.
                                    for (let i = 0; i < d.length; i++) {
                                        // Make sure the header exists before trying to use its name.
                                        if (headers[i]) {
                                            obj[headers[i].name] = d[i];
                                        }
                                    }
                                    // Generate a unique ID for each row and set the selected property to false.
                                    obj.id = uuid();
                                    obj.selected = false;
                                    return obj;
                                });
                            console.log(headers)
                            console.log(tableData)
                            setHeaders(headers);
                            setData(tableData);
                            setMeta(meta);
                            handleDragLeave();

                        }}

                        onDragOver={(event) => {
                            event.preventDefault();
                            handleDragOver();
                        }}
                        onDragLeave={(event) => {
                            event.preventDefault();
                            handleDragLeave();
                        }}
                        onDrop={(event) => {
                            event.preventDefault();
                            handleDragLeave();
                        }}
                    >
                        {({
                            getRootProps,
                        }) => (
                            <>
                                <div
                                    {...getRootProps()}
                                    style={Object.assign(
                                        {},
                                        styles.zone,
                                        dragging && {
                                            border: '4px solid #3d51b5',
                                            backgroundColor: '#dbdbdb',
                                            color: '#3d51b5',

                                        }
                                    )}

                                    className={`asana-upload-container${dragging ? " dragging" : ""}`}
                                >

                                    <FontAwesomeIcon icon={data ? faCircleCheck : faCloudArrowUp}

                                        size="4x" style={{
                                            display: 'block',
                                            color: data ? 'green' : dragging ? '#3d51b5' : '#7A7A7A',
                                            paddingBottom: 20
                                        }} />
                                    <Typography variant="h6" fontWeight={700} fontSize={18} gutterBottom component="div"
                                        style={{
                                            color: dragging ? '#3d51b5' : '#7A7A7A',
                                        }}>
                                        {data ? "File successfully uploaded" : "Drag and drop your CSV file here"}

                                    </Typography>
                                    <Typography variant="caption" fontSize={14} gutterBottom component="div"
                                        style={{
                                            color: dragging ? '#3d51b5' : '#7A7A7A',
                                        }}>
                                        (this only takes csv files as inputs)
                                    </Typography>



                                </div>
                            </> 
                        )}
                    </CSVReader>





                </Grid>
                <Grid item xs={12} sm={12} md={5}>
                    <UploadTemplates setTemplate={setTemplate} meta={meta} setMeta={setMeta} template={template} templates={templates} setItem={setItem} item={item} />
                    {template && (
                        template?.headers.map((header) => {
                            return (
                                <Grid container spacing={3} key={header.id} style={{ paddingTop: 10 }}>
                                    <Grid item xs={12} sm={12} md={4}>
                                        <Typography variant="body1" fontWeight={400}>
                                            {header.originalName || header.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <Typography variant="body1" fontWeight={600}>
                                             --> {header.mappedFieldLabel ? header.mappedFieldLabel : header.name}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )
                        })

                    )}

                    <CSVNextStep data={data} setData={setData}
                        steps={steps} setSteps={setSteps} item={item} nextStep={nextStep} setItem={setItem} meta={meta} setMeta={setMeta} template={template} setHeaders={setHeaders} />
                </Grid>


            </Grid >
        </Box>

    );
};

export default UploadComponentMulti;
