import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextField, Typography, Autocomplete, Modal, Box, Checkbox } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { getDeviceInfo, getDevices } from './firestore';


const CreateStream = ({
    screenWidth,
    createModalOpen,
    setCreateModalOpen,
    type,
    handleCreate,
    handleSave,
    currentItem,
    openSnack

}) => {

    const [item, setItem] = useState(currentItem);
    const [checkingForDevices, setCheckingForDevices] = useState(false);
    const [items, setItems] = useState([]);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setItem(currentItem);
        console.log('currentItem', currentItem)
        console.log('get device item')
        getDevice(currentItem)


        setItems([]);
    }, [currentItem])



    const [lastItemId, setLastItemId] = useState(null);





    async function getDevice(item) {
        const singleDevice = await getDeviceInfo(item)
        console.log('singleDevice', singleDevice)
        const items = await getDevices(item?.username, item?.apiKey)
        console.log('items', items)
        const device = items.find((item) => item.id === currentItem?.device?.id)
        if (device) {
            console.log('device', device)
            setItem({ ...item, additionalinfo: device })
        }

    }

    const handleClose = () => {
        setOpen(false);
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };



    return (
        <Dialog open={createModalOpen} onClose={() => setCreateModalOpen(false)}>
            <DialogTitle style={{ paddingBottom: 1 }}>{type === 'new' ? "Create a Stream" : "Edit a Stream"}</DialogTitle>
            <form
                style={{ width: screenWidth === 'xs' ? '90%' : '500px' }}

            >
                <DialogContent>
                    <DialogContentText>
                        {type === 'new' ? "Enter a name for the new stream." : "Edit your stream information."}
                    </DialogContentText>
                    <Typography variant="body1" fontSize={15} fontWeight={500} style={{ marginTop: 12, paddingBottom: 0 }}>
                        Stream Name<span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <TextField
                        style={{ paddingTop: 0, marginTop: 0 }}
                        size="small"
                        autoFocus
                        margin="dense"
                        placeholder="Stream Name"
                        type="text"
                        fullWidth
                        value={item?.name}
                        onChange={(e) => {
                            setItem({ ...item, name: e.target.value });
                        }}
                    />

                    <Typography variant="body1" fontSize={15} fontWeight={500} style={{ marginTop: 12, paddingBottom: 0 }}>
                        Username<span style={{ color: 'red' }}>*</span>
                    </Typography>

                    <TextField
                        style={{ paddingTop: 0, marginTop: 0 }}
                        size="small"
                        margin="dense"
                        placeholder="Username"
                        type="text"
                        fullWidth
                        value={item?.username}
                        autoComplete="off"
                        onChange={(e) => {
                            setItem({ ...item, username: e.target.value.trim() });
                        }}
                    />
                    <Typography variant="body1" fontSize={15} fontWeight={500} style={{ marginTop: 12, paddingBottom: 0 }}>
                        API Key<span style={{ color: 'red' }}>*</span>
                    </Typography>

                    <TextField
                        style={{ paddingTop: 0, marginTop: 0 }}
                        size="small"
                        margin="dense"
                        placeholder="API Key"
                        fullWidth
                        type="password"
                        value={item?.apiKey}
                        autoComplete="off"
                        onChange={(e) => {
                            setItem({ ...item, apiKey: e.target.value });
                        }}
                    />
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 12 }}>
                        {item?.username && item?.apiKey && (
                            checkingForDevices ? (
                                <FontAwesomeIcon icon={faSpinner} spin />
                            ) : (
                                <Button
                                    style={{
                                        backgroundColor: '#404050',
                                        color: 'white',
                                        textTransform: 'none',
                                    }}
                                    onClick={async () => {
                                        setCheckingForDevices(true);

                                        console.log(item)
                                        const trimUsername = item?.username.trim();
                                        const items = await getDevices(trimUsername, item?.apiKey)
                                        console.log(items)
                                        setItems(items || []);
                                        if (items.length === 0) {
                                            openSnack('error', "No items found.");
                                        }
                                        else {
                                            openSnack('success', `${items.length} items found.`);
                                        }
                                        setCheckingForDevices(false);

                                    }}
                                >
                                    Check for devices
                                </Button>
                            )
                        )}
                    </div>


                    {(item?.device !== undefined || items.length > 0) && (
                        <Typography variant="body1" fontSize={15} fontWeight={500} style={{ marginTop: 12, paddingBottom: 0 }}>
                            Device<span style={{ color: 'red' }}>*</span>{item?.camera !== undefined && ` (Current Camera: ${item?.camera?.name})`}
                        </Typography>
                    )}
                    {(item?.device !== undefined || item?.additionalinfo) && (
                        <Typography variant="body1" fontSize={15} fontWeight={500} style={{ marginTop: 12, paddingBottom: 0 }}>
                            Device Name: {item?.additionalinfo?.name}
                        </Typography>
                    )}
                    {(item?.device !== undefined || item?.additionalinfo) && (
                        <Typography variant="body1" fontSize={15} fontWeight={500} style={{ marginTop: 12, paddingBottom: 0 }}>
                            Latitude: {item?.additionalinfo?.latitude}
                        </Typography>
                    )}
                    {(item?.device !== undefined || item?.additionalinfo) && (
                        <Typography variant="body1" fontSize={15} fontWeight={500} style={{ marginTop: 12, paddingBottom: 0 }}>
                            Longitude: {item?.additionalinfo?.longitude}
                        </Typography>
                    )}
                    {items.length > 0 && (
                        <Typography variant="caption" style={{ paddingBottom: 0 }}>
                            {items.length} devices found. Select the camera you want to use.
                        </Typography>
                    )}
                    {items.length > 0 && (
                        <Autocomplete
                            size='small'
                            margin="dense"
                            fullWidth
                            id="combo-box-demo"
                            options={items}
                            value={items?.find((item) => item?.id === item?.device?.id)}
                            getOptionLabel={(option) => option?.name || ''}
                            renderInput={(params) => <TextField {...params} placeholder="Devices" variant="outlined" />}
                            onChange={(e, value) => {
                                const device = {
                                    name: value?.name,
                                    id: value?.id,
                                    longitude: value?.longitude,
                                    latitude: value?.latitude,
                                    reference: value?.reference,
                                }

                                setItem({ ...item, device });
                                console.log({ ...item, device })
                            }}
                        />

                    )}


                </DialogContent>
                {item?.device && (<DialogActions>
                    <Button onClick={() => setCreateModalOpen(false)}>Cancel</Button>
                    <Button
                        style={{
                            backgroundColor: '#404050',
                            color: 'white',
                            textTransform: 'none',
                        }}
                        onClick={(e) => {
                            setOpen(true);
                        }}
                    >
                        {type === 'new' ? "Create Stream" : "Save"}
                    </Button>

                </DialogActions>
                )}
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {type === 'new' ? "Create a Device Stream" : "Save stream"}
                        </Typography>
                        <Typography id="body1" fontSize={13} sx={{ mt: 2 }}>
                            This will activate a device stream that will go check for new data every hour and store it.
                            You can add the data to widget on your dashboard with this stream.
                        </Typography>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 12 }}>
                            <Button type="submit"
                                style={{
                                    backgroundColor: '#404050',
                                    color: 'white',
                                    textTransform: 'none',
                                }}
                                onClick={async (e) => {
                                    console.log('submit')

                                    // Check for missing fields
                                    console.log(item)
                                    if (!item.name || !item.device || !item.username || !item.apiKey) {
                                        openSnack('error', "Please fill out all required fields.");
                                        return;
                                    }

                                    // If all fields are filled out, proceed with the submission
                                    type === 'new' ? handleCreate(item) : handleSave(item);
                                    setOpen(false);
                                }}
                            >
                                {type === 'new' ? "Create Stream" : "Save"}
                            </Button>
                        </div>
                    </Box>
                </Modal>
            </form>
        </Dialog>
    );
};

export default CreateStream;
