import MenuIcon from '@mui/icons-material/Menu';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Alert, CircularProgress, Divider, Drawer, Fab, IconButton, Slide, Snackbar, Typography, Button } from '@mui/material';
import { Col, Layout, Row } from 'antd';
import { doc, getDoc, getFirestore } from "firebase/firestore";
import React from 'react';
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import AccountList from '../account/accountList';
import Logo from '../extra/logos/logo.png';
import AccountTools from './accountTools';
import AccountInfo from '../account/accountInfo';
import StationsHome from '../waterQuality/stations/stationsHome';
import SiderMenu from './siderMenu';
import CompanyInfo from '../general/companyInfo';
import UserProfile from '../general/userProfile';
import Item from '../items/item';
import Items from '../items/items';
import ProductInventory from '../items/productInventory/productInventory';
import ProductStocks from '../items/productStocks/productStocks';
import ServicesInventory from '../items/servicesInventory/servicesInventory';
import Application from '../products/use/application';
import ApplicationNew from '../products/use/applicationNew';
import ChemicalApplications from '../products/use/chemicalApplications';
import ChemicalRecords from '../products/use/chemicalRecords';
import EmailApplciation from '../products/use/emailApplication';
import EmailLog from '../products/use/emailLog';
import FieldLogNew from '../products/use/fieldLog';
import FieldLogsNew from '../products/use/fieldLogs';
import HistoryLog from '../products/use/historyLog';
import PublicDashboardHome from '../publicPortal/publicFacing/publicDashboardHome';
import PublicSettings from '../publicPortal/publicSettings';
import TasksHome from '../tasks/tasksHome';
import TasksHomeNew from '../tasks/tasksHomeNew';
import Alerts from '../waterQuality/alerts';
import AdminAlerts from '../waterQuality/adminAlerts';
import Capacity from '../waterQuality/bathymetry/capacity';
import DepthProfiles from '../waterQuality/depthProfiles/depthProfiles';
import Profile from '../waterQuality/depthProfiles/profile';
import MergeData from '../waterQuality/mergeData';
import MergeWeather from '../waterQuality/mergeWeather';
import Parameters from '../waterQuality/parameters/parameters';
import Probes from '../waterQuality/probes/probes';
import QueryOldLabData from '../waterQuality/queryOldLab';
import QueryOldProbeData from '../waterQuality/queryOldProbe';
import RainGauges from '../waterQuality/rainGauge/rainGauges';
import References from '../waterQuality/references/references';
import SamplingSites from '../waterQuality/samplingSites/samplingSites';
import TidalStations from '../waterQuality/tides/tidalStations';
import AddWeather from '../waterQuality/waterQualityData/addweather';
import QueryData from '../waterQuality/waterQualityData/queryData';
import UploadData from '../waterQuality/waterQualityData/uploadData';
import ChangeCompany from './changeCompany';
import CreateCompany from './createCompany';
import FieldLog from '../fieldLogs/fieldLog';
import FieldLogs from '../fieldLogs/fieldLogs';
import Documents from '../account/documents/documents';
import Equipment from '../account/equipment/equipment';
import EquipmentList from '../account/equipment/equipmentList';
import AccountMap from '../account/map/accountMap';
import Album from '../account/photoAlbums/album';
import Albums from '../account/photoAlbums/albums';
import AlbumsNew from '../account/photoAlbums/newPhotoAlbums';
import Dashboard from '../account/account/dashboard/dashboardhome';
import Timelapse from '../spypoint/timelapse';
import TestProbe from '../waterQuality/waterQualityData/testProbe';
import ProductsHistory from '../items/productsHistory/productsHistory';
import AccountDirectory from '../account/account/accountDirectory';
import AccountDistribution from '../account/account/accountDistribution';
import SiteCharacteristics from '../account/account/siteCharacteristics.jsx/characteristicsHome';
import CompanyStaff from '../companyAdmin/companyStaff';
import GetProbeData from '../waterQuality/getProbeData';
import Hobo from '../extra/hobo';
import DataManagementHome from '../waterQuality/dataManagement/dataManagementHome';
import DataStreamsHome from '../dataStreams/dataStreamsHome';
import WeatherUnderground from '../waterQuality/weather/weatherUnderground';
import NewFieldLogHome from '../products/use/newFieldLog/fieldLogsHome';
import NewFieldLogEmail from '../products/use/newFieldLog/fieldLogsEmail';
import TimeLapseHome from '../timelapse/timelapseHome';
import PSHome from '../account/productsAndServices/psHome';
import PSItem from '../account/productsAndServices/psItem';
import ChemicalsHome from '../chemicals/chemicalsHome';
import NewApplicationHome from '../products/use/newApplication/applicationHome';
import PSReports from '../reporting/PSReports';
import StockHome from '../items/productStocks/stockHome';
import HistoryLogApplciation from '../products/use/historyLogApplication';
import CompanyInfoNew from '../general/companyInfoNew';
import SiteInfoHome from '../account/account/siteInfo/siteInfoHome';
import NewApplicationEmail from '../products/use/newApplication/applicationEmail';
import NewDataHome from '../waterQuality/waterQualityData/newData/newDataHome';
import DashboardHomeNew from '../account/account/dashboard/dashboardHomeNew';
import { getLogoImage } from '../firebase/config';
import AccountDirectoryHome from '../account/account/accountDirectory/accountDirectoryHome';
import EditAccountPerson from '../account/account/accountDirectory/editAccountPerson';
import PermissionTemplates from '../account/account/accountDirectory/permissionTemplates';
import EditDistributionGroup from '../account/account/accountDirectory/editDistributionGroup';
import ProductStocksHome from '../items/productStocks/productStocksHome';
import EditStock from '../items/productStocks/editStock';
import CodesHome from '../codes/codesHome';
import ParametersHome from '../waterQuality/parameters/parametersHome';
import PSHomeNew from '../account/productsAndServices/psHomeNew';
import AccountListHome from '../account/accountListhHome';
import EditEquipmentItem from '../account/equipment/editEquimentItem';
import EquipmentHome from '../account/equipment/equipmentHome';
import AccountButton from './accountButton';
import NewUserButton from './userButton';
import DashboardPersonal from '../account/account/dashboard/dashboardhomePersonal';
import SiteToAccount from '../extra/siteToAccount';
import DashboardPortal from '../account/account/dashboard/dashboardhomePortal';
import APITest from '../extra/apiTest';
import MainPage from '../account/documents/components/DocumentMainPage';
import OST from '../dataStreams/OST';
import AccountStaffHome from '../companyAdmin/companyStaff/staffHome';
import Images from '../account/images/components/ImagesMainPage';
import HistoricalAlerts from '../waterQuality/historicalAlerts/HistoricalAlertsHome';
import SpreadsheetTest from '../waterQuality/waterQualityData/newData/spreadsheet';
import AlertsHome from '../waterQuality/alarms/alarmsHome';
import APITestNOAA from '../extra/apiTestNOAA';
import StreamHomeObscape from '../streamObscape/streamHomeObscape';
import SnoozeAlert from '../waterQuality/alarms/snoozeAlerts';
import OSTStreamsHome from '../dataStreams/newComponents/ostComponents/ostStreamsHome';
import WunderStreamsHome from '../dataStreams/newComponents/wunderComponents/wunderStreamsHome';
import ProbeStreamsHome from '../dataStreams/newComponents/probeComponents/probeStreamsHome';
import TidalStreamsHome from '../dataStreams/newComponents/tidalComponents/tidalStreamsHome';
import CamerasHome from '../dataStreams/newComponents/timelapseCamerasHome/camerasHome';
import WaterQuatlityUploadHome from '../waterQualityNew/waterQualityUpload/waterQualityUploadHome';
import WaterQualityHomeNew from '../waterQualityNew/waterQualityHomeNew';
import WaterQualityHomeSpeedTest from '../waterQualityNew/waterQualityHomeSpeedTest';
import PhotosHomeNew from '../photosNew/photosHomeNew';
import ObscapeWeatherStreamsHome from '../dataStreams/newComponents/obscapeWeatherComponents/obscapeWeatherStreamsHome';
import DocumentsHomeNew from '../documentsNew/documentsHomeNew';
import FieldLogsHomeNew from '../fieldLogsNew/fieldLogsHomeNew';
import FieldLogNew2 from '../fieldLogNew/fieldLogHomeNew';
import BioHome from '../bio/bioHome';
import ParametersHomeNew from '../waterQuality/parameters_new/parametersHome';
import StationsHomeV2 from '../waterQuality/stations/stationsHomeV2';
import WeatherFunctionsHome from '../weatherFunctions/weatherFunctionsHome';
import axios from 'axios';

const { Header, Content, Sider, Footer } = Layout;

export default class SignedInRouting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            openDrawer: false,
            openTools: false,
            uid: this.props.uid,
            userInfo: {},
            loadScene: true,
            openSnack: false,
            snackType: '',
            snackDescription: '',
            pathname: '',
            account: {},
            company: {},
            loading: true,
            screenWidthValue: 0,
            logoImage: '',
            loadingLogo: true,
        };
    }
    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            const screenWidthValue = this.props.screenWidth === 'xs' ? 0 : this.props.screenWidth === 'sm' ? 1 : this.props.screenWidth === 'md' ? 2 : this.props.screenWidth === 'lg' ? 3 : this.props.screenWidth === 'xl' ? 4 : 5;
            this.setState({ screenWidth: this.props.screenWidth, screenWidthValue })
        }
        if (this.props.uid !== prevProps.uid) {
            this.getUser(this.props.uid);

            this.setState({ uid: this.props.uid })
        }
    }


    async componentDidMount() {
        const { uid, userInfo } = this.state;

        this.getUser(uid)

        setTimeout(() => {
            this.setState({ loadScene: false })
        }, 1000);











    }

    getUser = async (uid) => {
        const db = getFirestore();

        const docRef = doc(db, "users", uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {

            this.getAccount(docSnap.data().currentCompany, docSnap.data().currentAccount)
            this.getCompany(docSnap.data().currentCompany)
            this.setState({ userInfo: docSnap.data() })
            const logoImage = await getLogoImage(docSnap.data());
            this.setState({ logoImage: logoImage.fileLink, loadingLogo: false })

        } else {
            this.setState({ loadingLogo: false })
        }
    }
    getAccount = async (company, account) => {

        const db = getFirestore();

        if (account !== '' && account !== undefined) {


            const docRef = doc(db, "clients", company, 'accounts', account);
            const docSnap = await getDoc(docRef);



            if (docSnap.exists()) {


                this.setState({ account: docSnap.data() })
            } else {
                this.setState({ account: {} })
            }
        }
    }
    getCompany = async (company) => {
        const { userInfo } = this.state;
        const db = getFirestore();
        this.setState({ loadingLogo: true })

        const docRef = doc(db, "clients", company,);
        const docSnap = await getDoc(docRef);



        if (docSnap.exists()) {

            this.setState({ company: docSnap.data() })
            const logoImage = await getLogoImage(userInfo);
            this.setState({ logoImage: logoImage.fileLink, loadingLogo: false })
        } else {
            console.log("No such document!");
        }


    }

    updateUser = (info) => {
        this.setState({ userInfo: info })
    }

    closeSnack = () => {
        this.setState({ openSnack: false, snackType: '', snackDescription: '' })
    }
    openSnack = (type, description) => {
        this.setState({ openSnack: true, snackType: type, snackDescription: description })
    }

    updateUserInfo = (boo, val) => {
        const { userInfo } = this.state;
        userInfo[boo] = val;
        this.setState({ userInfo })


        this.getAccount(userInfo.currentCompany, userInfo.currentAccount)
    }

    changeCompany = (key) => {
        this.getCompany(key)
        this.updateUserInfo('currentCompany', key)
    }


    render() {
        const { loadingLogo, logoImage, openDrawer, screenWidth, openTools, loadScene, uid, userInfo, openSnack, snackType, snackDescription, account, pathname, loading, company, screenWidthValue } = this.state;

        const UserButton = () => {
            let navigate = useNavigate()
            return (
                <Fab aria-label="upload picture" component="span" size="large" style={{ backgroundColor: 'white' }}
                    onClick={() => {
                        navigate('./userProfile')
                    }}>
                    <PersonOutlineIcon fontSize="large" />
                </Fab>
            )
        }

        const Loading = (props) => {
            return (
                <div style={{ minHeight: '100vh' }}>
                    <Row justify="space-around" gutter={[32, 8]} align="middle" style={{ paddingTop: 18, }}>
                        <Col span={24} style={{ textAlign: 'center', fontSize: 24, fontFamily: 'Roboto, sans-serif', }}>
                            Loading {props.page || "Page"}...
                        </Col>
                        <Col span={24} style={{ textAlign: 'center', fontSize: 24, fontFamily: 'Roboto, sans-serif', }}>
                            <CircularProgress />
                        </Col>
                    </Row>
                </div>
            )
        }

        const GetPath = () => {
            let newLocation = useLocation()
            if (newLocation.pathname !== pathname) {
                this.setState({ pathname: newLocation.pathname, loadScene: true })
                setTimeout(() => {
                    this.setState({ loadScene: false })
                }, 250);
            }
        }


        const CompanyButton = () => {
            let navigate = useNavigate()
            return (
                <Row className='rowHover' onClick={() => {
                    navigate('/')
                }} style={{ padding: 10 }}>

                    <Col span={24}>
                        <Typography fontWeight={300} fontSize={screenWidth === 'md' ? 11 : 14} variant="body2">{companyType === 'district' ? 'Current District' :
                            companyType === 'municipality' ? 'Current City' :
                                companyType === 'citizenScientist' ? 'Citizen Scientist' :
                                    companyType === 'irrigationDistrict' ? 'Current District' :
                                        'Current Account'}</Typography>
                    </Col>
                    <Col span={24}>
                        <Typography variant="h4" fontWeight={600} fontSize={16} style={{ textAlign: 'left', }}>
                            {company.companyName || ''}
                        </Typography>
                    </Col>
                </Row>
            )
        }

        const companyType = company === undefined ? undefined : company.companyTypeKey !== undefined ? company.companyTypeKey : company.companyType !== undefined ? company.companyType : undefined;



        return (
            <Layout hasSider>


                {screenWidth !== 'xs' && (

                    <Sider
                        width={screenWidth === 'sm' ? '30vw' : screenWidth === 'md' ? '25vw' : '20vw'}
                        style={{
                            overflowX: 'hidden',
                            overflow: 'auto',
                            height: '100vh',
                            position: 'fixed',
                            left: 0,
                            top: 0,
                            bottom: 0,
                            background: 'white',

                            borderRight: '1px solid #e8e8e8',

                        }}
                    >




                        <Row align="middle" style={{}}>

                            <Col span={24} style={{ paddingLeft: 15, paddingRight: 15, paddingTop: 10, textAlign: 'center' }}>




                                {loadingLogo ? <CircularProgress /> :
                                    <img src={logoImage || "https://firebasestorage.googleapis.com/v0/b/aquasource3.appspot.com/o/a3be7-12c1-c741-aa1d-a2fa360fd504%2Fphotos%2F5f6454-c782-2a7d-12f8-db0a4b77e226?alt=media&token=3c0ddbdb-5671-4697-a793-9b926506d328"}
                                        style={{ maxWidth: '100%', paddingLeft: 5, maxHeight: 150 }} />
                                }

                            </Col>
                            <Col span={24} style={{ paddingTop: 10 }}>
                                <Divider />
                            </Col>
                            <Col span={24} style={{ cursor: 'pointer' }}>
                                <CompanyButton />
                            </Col>

                            <Col span={24} style={{}}>
                                <Divider />
                            </Col>
                            <Col span={24} style={{ paddingLeft: 10, paddingRight: 10, cursor: 'pointer' }}>
                                <AccountButton type="loggedIn" userRef={userInfo} accountRef={account} companyRef={company} openSnack={openSnack}
                                    updateCurrentAccount={(key) => {
                                        this.updateUserInfo('currentAccount', key)
                                    }}

                                />
                            </Col>
                            <Col span={24} style={{}}>
                                <Divider style={{
                                    marginBottom: 0,
                                    paddingBottom: 0,
                                }} />
                            </Col>
                            <Col span={24} style={{}}>
                                {Object.values(userInfo).length > 0 && (<SiderMenu screenWidth={screenWidth} userInfo={userInfo} company={company} mobile={false} pathname={pathname} />)}
                            </Col>
                            <Col span={24} style={{ cursor: 'pointer' }}>
                                <NewUserButton type="loggedIn" userRef={userInfo} accountRef={account} companyRef={company} openSnack={openSnack} />
                            </Col>
                        </Row>
                    </Sider>)}



                <Layout

                    style={{
                        marginLeft: //100vw - the sider width 
                            screenWidth === 'xs' ? 0 : screenWidth === 'sm' ? '30vw' : screenWidth === 'md' ? '25vw' : '20vw',
                        background: 'white',
                    }}

                >
                    {screenWidth === 'xs' && (
                        <Header style={{
                            zIndex: 3, position: 'relative',
                            backgroundColor: 'white', width: '100%', height: '100px', padding: '0 30px',
                            boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"
                        }}>

                            <Row align="middle" style={{ padding: 12, position: 'relative', paddingTop: 20 }}>
                                <Col xs={4} sm={0}>
                                    {screenWidth === 'xs' && (<IconButton color="primary" aria-label="upload picture" component="span" size="large" style={{ color: 'inherit' }}
                                        onClick={() => {
                                            this.setState({ openDrawer: true, openTools: false })
                                        }}>
                                        <MenuIcon fontSize="large" />
                                    </IconButton>)}
                                </Col>
                                <Col xs={14} sm={10}>

                                </Col>
                                <Col xs={6} sm={14} style={{ textAlign: 'right' }}>
                                    <UserButton />
                                </Col>
                            </Row>
                        </Header>
                    )}



                    <Content style={{ backgroundColor: 'white' }}>
                        <Row style={{ backgroundColor: 'white', minHeight: '100vh' }}>


                            {/* <Button onClick={async () => {
                                try {
                                    const proxyUrl = "https://mycorslake.herokuapp.com/";
                                    let fireurl = 'https://us-central1-aquasource3.cloudfunctions.net/database_test23'

                                    axios.post(proxyUrl + fireurl, {
                                        guestName: 'Guest',
                                        content: 'Hello World'
                                    })
                                        .then((response) => {
                                            console.log(response);
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                        }
                                        )
                                } catch (error) {
                                    console.log(error)
                                }
                            }}>Test</Button>
                            <Button onClick={async () => {
                                try {
                                    const proxyUrl = "https://mycorslake.herokuapp.com/";
                                    let fireurl = 'https://us-central1-aquasource3.cloudfunctions.net/database_37'

                                    axios.post(proxyUrl + fireurl, {
                                        query_type: 'select',
                                    })
                                        .then((response) => {
                                            console.log(response);
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                        }
                                        )
                                } catch (error) {
                                    console.log(error)
                                }
                            }}>Test2</Button>
                            <Button onClick={async () => {
                                try {
                                    const proxyUrl = "https://mycorslake.herokuapp.com/";
                                    let fireurl = 'https://us-central1-aquasource3.cloudfunctions.net/database_37'

                                    {
                                        [
                                            { guestName: 'Greg', content: 'Greg is a good guest' },
                                            { guestName: 'Bob', content: 'Bob is an average guest' },
                                            { guestName: 'Sue', content: 'Sue is a bad guest' },
                                            { guestName: 'Alice', content: 'Alice is a great guest' },
                                            { guestName: 'John', content: 'John is a terrible guest' },
                                            { guestName: 'Jane', content: 'Jane is a good guest' },
                                            { guestName: 'Tom', content: 'Tom is a bad guest' },
                                            { guestName: 'Jerry', content: 'Jerry is a great guest' },
                                            { guestName: 'Mickey', content: 'Mickey is a terrible guest' },
                                            { guestName: 'Minnie', content: 'Minnie is a good guest' },
                                            { guestName: 'Donald', content: 'Donald is an average guest' },
                                            { guestName: 'Daisy', content: 'Daisy is a bad guest' },
                                            { guestName: 'Goofy', content: 'Goofy is a great guest' },
                                            { guestName: 'Pluto', content: 'Pluto is a terrible guest' },

                                        ].forEach(async (guest) => {
                                            try {

                                                axios.post(proxyUrl + fireurl, {
                                                    query_type: 'write',
                                                    guestName: guest.guestName,
                                                    content: guest.content
                                                })
                                                    .then((response) => {
                                                        console.log(response);
                                                    })
                                                    .catch((error) => {
                                                        console.log(error);
                                                    }
                                                    )
                                            } catch (error) {
                                                console.log(error)
                                            }
                                        })
                                    }


                                } catch (error) {
                                    console.log(error)
                                }
                            }}>Test4</Button>
                            <Button onClick={async () => {
                                try {
                                    const proxyUrl = "https://mycorslake.herokuapp.com/";
                                    let fireurl = 'https://us-central1-aquasource3.cloudfunctions.net/database_37'

                                    axios.post(proxyUrl + fireurl, {
                                        query_type: 'delete_all',
                                    })
                                        .then((response) => {
                                            console.log(response);
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                        }
                                        )
                                } catch (error) {
                                    console.log(error)
                                }
                            }}>Test5</Button>
                            <Button onClick={async () => {
                                try {
                                    const proxyUrl = "https://mycorslake.herokuapp.com/";
                                    let fireurl = 'https://us-central1-aquasource3.cloudfunctions.net/database_37_node'

                                    axios.post(proxyUrl + fireurl, {
                                        query_type: 'select',
                                    })
                                        .then((response) => {
                                            console.log(response);
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                        }
                                        )
                                } catch (error) {
                                    console.log(error)
                                }
                            }}>Test6</Button> */}






                            {Object.values(userInfo).length > 2 && loadScene === false && (<Col span={24} style={{ position: 'relative', top: 0 }}>
                                <Routes>
                                    {loadScene === false && (<Route exact path="/"
                                        element={<AccountList type="loggedIn" typeRef="loggedIn" screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} getUser={this.getUser} updateUserInfo={this.updateUserInfo} />} />)}

                                    {loadScene === false && (<Route exact path="/createSite"
                                        element={<AccountInfo type="create" updateUserInfo={this.updateUserInfo} screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} getUser={this.getUser} account={account} company={company} />} />)}

                                    {loadScene === false && (<Route exact path="/accountInfo"
                                        element={<AccountInfo type="info" updateUserInfo={this.updateUserInfo} screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} getUser={this.getUser} account={account} company={company} />} />)}
                                    {loadScene === false && (<Route exact path="/stations"
                                        element={<StationsHome screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} account={account} company={company} />} />)}

                                    {loadScene === false && (<Route exact path="/userProfile"
                                        element={<UserProfile screenWidth={screenWidth} uid={uid} userInfo={userInfo} updateUser={this.updateUser} openSnack={this.openSnack} />} />)}

                                    {loadScene === false && (<Route exact path="/companyInfo"
                                        element={<CompanyInfoNew screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} getUser={this.getUser} updateUserInfo={this.updateUserInfo} changeCompany={this.changeCompany} clearAccount={() => this.setState({ account: { accountName: '' } })} />} />)}

                                    {loadScene === false && (<Route exact path="/services"
                                        element={<Items type="services" screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} getUser={this.getUser} account={account} />} />)}

                                    {loadScene === false && (<Route exact path="/productStocks"
                                        element={<ProductStocksHome screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} getUser={this.getUser} account={account} />} />)}

                                    {loadScene === false && (<Route exact path="/companyInventory"
                                        element={<ProductInventory level="company" updateUserInfo={this.updateUserInfo} screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} getUser={this.getUser} account={account} />} />)}

                                    {loadScene === false && (<Route exact path="/accountInventory"
                                        element={<ProductInventory level="account" updateUserInfo={this.updateUserInfo} screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} getUser={this.getUser} account={account} />} />)}
                                    {loadScene === false && (<Route exact path="/companyServicesInventory"
                                        element={<ServicesInventory level="company" updateUserInfo={this.updateUserInfo} screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} getUser={this.getUser} account={account} />} />)}

                                    {loadScene === false && (<Route exact path="/accountServicesInventory"
                                        element={<ServicesInventory level="account" updateUserInfo={this.updateUserInfo} screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} getUser={this.getUser} account={account} />} />)}

                                    {loadScene === false && (<Route exact path="/products"
                                        element={<Items type="products" screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} getUser={this.getUser} account={account} />} />)}

                                    {loadScene === false && (<Route exact path="/serviceItem"
                                        element={<Item type="service" screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} getUser={this.getUser} account={account} />} />)}

                                    {loadScene === false && (<Route exact path="/productItem"
                                        element={<Item type="product" screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} getUser={this.getUser} account={account} />} />)}

                                    {loadScene === false && (<Route exact path="/accountFieldLogs"
                                        element={<FieldLogs level="account" screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} getUser={this.getUser} account={account} />} />)}

                                    {loadScene === false && (<Route exact path="/companyFieldLogs"
                                        element={<FieldLogs level="company" screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} getUser={this.getUser} account={account} />} />)}

                                    {loadScene === false && (<Route exact path="/fieldLog"
                                        element={<FieldLog screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} getUser={this.getUser} account={account} company={company} />} />)}

                                    {loadScene === false && (<Route exact path="/createSite"
                                        element={<AccountInfo type="create" updateUserInfo={this.updateUserInfo} screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} getUser={this.getUser} account={account} company={company} />} />)}

                                    {loadScene === false && (<Route exact path="/accountInfo"
                                        element={<AccountInfo type="info" updateUserInfo={this.updateUserInfo} screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} getUser={this.getUser} account={account} company={company} />} />)}

                                    {loadScene === false && (<Route exact path="/equipmentList"
                                        element={<EquipmentHome screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} getUser={this.getUser} account={account} company={company} />} />)}
                                    {loadScene === false && (<Route exact path="/editEquipmentItem"
                                        element={<EditEquipmentItem screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} getUser={this.getUser} account={account} company={company} />} />)}

                                    {loadScene === false && (<Route exact path="/equipmentListOld"
                                        element={<EquipmentList screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} getUser={this.getUser} account={account} company={company} />} />)}
                                    {loadScene === false && (<Route exact path="/equipment"
                                        element={<Equipment screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} getUser={this.getUser} account={account} company={company} />} />)}

                                    {loadScene === false && (<Route exact path="/documents"
                                        element={<MainPage screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} getUser={this.getUser} account={account} company={company} />} />)}

                                    {loadScene === false && (<Route exact path="/photoAlbums"
                                        element={<Albums screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} getUser={this.getUser} account={account} company={company} />} />)}
                                    {loadScene === false && (<Route exact path="/photoAlbumsNew"
                                        element={<AlbumsNew screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} getUser={this.getUser} account={account} company={company} />} />)}
                                    {loadScene === false && (<Route exact path="/images"
                                        element={<Images screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} getUser={this.getUser} account={account} company={company} />} />)}
                                    {loadScene === false && (<Route exact path="/album"
                                        element={<Album screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} getUser={this.getUser} account={account} company={company} />} />)}

                                    {loadScene === false && (<Route exact path="/accountMap"
                                        element={<AccountMap screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} getUser={this.getUser} account={account} company={company} />} />)}
                                    {loadScene === false && (<Route exact path="/account"
                                        element={<Dashboard type="account" screenWidth={screenWidth} account={account}
                                            userInfo={userInfo} openSnack={this.openSnack} getUser={this.getUser} company={company} />} />)}
                                    {loadScene === false && (<Route exact path="/publicDashboard"
                                        element={<Dashboard type="public" screenWidth={screenWidth} account={account} userInfo={userInfo} openSnack={this.openSnack} getUser={this.getUser} company={company} />} />)}
                                    {loadScene === false && (<Route exact path="/probes"
                                        element={<ProbeStreamsHome screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} getUser={this.getUser} account={account} company={company} />} />)}
                                    {loadScene === false && (<Route exact path="/probesTest"
                                        element={<ProbeStreamsHome screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} getUser={this.getUser} account={account} company={company} />} />)}

                                    {loadScene === false && (<Route exact path="/adminAlerts"
                                        element={<AdminAlerts screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} getUser={this.getUser} account={account} company={company} />} />)}
                                    {loadScene === false && (<Route exact path="/samplingSites"
                                        element={<SamplingSites screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} getUser={this.getUser} account={account} company={company} />} />)}
                                    {loadScene === false && (<Route exact path="/references"
                                        element={<References screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} getUser={this.getUser} account={account} company={company} />} />)}
                                    {loadScene === false && (<Route exact path="/accountDirectory"
                                        element={<AccountDirectoryHome screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} getUser={this.getUser} account={account} company={company} />} />)}
                                    {loadScene === false && (<Route exact path="/accountDistribution"
                                        element={<AccountDistribution screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} getUser={this.getUser} account={account} company={company} />} />)}

                                    {loadScene === false && (<Route exact path="/uploadData"
                                        element={<UploadData screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} getUser={this.getUser} account={account} company={company} />} />)}
                                    {loadScene === false && (<Route exact path="/parameters"
                                        element={<ParametersHome screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} getUser={this.getUser} account={account} company={company} />} />)}
                                    {loadScene === false && (<Route exact path="/data"
                                        element={<QueryData screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} getUser={this.getUser} account={account} company={company} />} />)}
                                    {loadScene === false && (<Route exact path="/capacity"
                                        element={<Capacity screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} getUser={this.getUser} account={account} company={company} />} />)}
                                    {loadScene === false && (<Route exact path="/queryOld"
                                        element={<QueryOldProbeData screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} getUser={this.getUser} account={account} company={company} />} />)}
                                    {loadScene === false && (<Route exact path="/queryOldLab"
                                        element={<QueryOldLabData screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} getUser={this.getUser} account={account} company={company} />} />)}
                                    {loadScene === false && (<Route exact path="/changeAccount"
                                        element={<ChangeCompany updateUserInfo={this.updateUserInfo} changeCompany={this.changeCompany} clearAccount={() => this.setState({ account: { accountName: '' } })} screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} getUser={this.getUser} account={account} company={company} />} />)}
                                    {loadScene === false && (<Route exact path="/createAccount"
                                        element={<CreateCompany screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} getUser={this.getUser} account={account} company={company} />} />)}
                                    {loadScene === false && (<Route exact path="/depthProfiles"
                                        element={<DepthProfiles screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} getUser={this.getUser} account={account} company={company} />} />)}

                                    {loadScene === false && (<Route exact path="/mergeData"
                                        element={<MergeData screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} getUser={this.getUser} account={account} company={company} />} />)}
                                    {loadScene === false && (<Route exact path="/mergeWeather"
                                        element={<MergeWeather screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} getUser={this.getUser} account={account} company={company} />} />)}
                                    {loadScene === false && (<Route exact path="/tasks"
                                        element={<TasksHomeNew screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} getUser={this.getUser} account={account} company={company} />} />)}


                                    {loadScene === false && (<Route exact path="/chemicalRecords" key="PM"
                                        element={<ChemicalRecords key="PM" screenWidth={screenWidth} level="account" userInfo={userInfo} openSnack={this.openSnack} account={account} company={company} />} />)}
                                    {loadScene === false && (<Route exact path="/chemicalRecordsCompany" key="PM"
                                        element={<ChemicalRecords key="PM" screenWidth={screenWidth} level="company" userInfo={userInfo} openSnack={this.openSnack} account={account} company={company} />} />)}
                                    {loadScene === false && (<Route exact path="/application"
                                        element={<Application screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} account={account} company={company} />} />)}
                                    {loadScene === false && Object.values(account).length > 0 && (<Route exact path="/applicationNew"
                                        element={<ApplicationNew screenWidth={screenWidth} level="account" userInfo={userInfo} openSnack={this.openSnack} account={account} company={company} />} />)}
                                    {loadScene === false && (<Route exact path="/chemicalApplications"
                                        element={<ChemicalApplications screenWidth={screenWidth} level="account" userInfo={userInfo} openSnack={this.openSnack} account={account} company={company} />} />)}
                                    {loadScene === false && (<Route exact path="/chemicalApplicationsCompany"
                                        element={<ChemicalApplications screenWidth={screenWidth} updateAccount={this.getAccount} updateUserInfo={this.updateUserInfo} level="company" userInfo={userInfo} openSnack={this.openSnack} account={account} company={company} />} />)}

                                    {loadScene === false && (<Route exact path="/addWeather"
                                        element={<AddWeather screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} account={account} company={company} />} />)}
                                    {loadScene === false && (<Route exact path="/profile"
                                        element={<Profile screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} account={account} company={company} />} />)}
                                    {loadScene === false && (<Route exact path="/fieldLogsNew"
                                        element={<FieldLogsNew screenWidth={screenWidth} level="account" userInfo={userInfo} openSnack={this.openSnack} account={account} company={company} />} />)}
                                    {loadScene === false && (<Route exact path="/fieldLogsNewCompany"
                                        element={<FieldLogsNew screenWidth={screenWidth} level="company" updateAccount={this.getAccount} updateUserInfo={this.updateUserInfo} userInfo={userInfo} openSnack={this.openSnack} account={account} company={company} />} />)}
                                    {loadScene === false && Object.values(account).length > 0 && (<Route exact path="/fieldLogNew"
                                        element={<FieldLogNew screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} account={account} company={company} />} />)}

                                    {loadScene === false && (<Route exact path="/emailApplication"
                                        element={<NewApplicationEmail screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} account={account} company={company} />} />)}

                                    {loadScene === false && (<Route exact path="/historyLog"
                                        element={<HistoryLog screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} account={account} company={company} />} />)}
                                    {loadScene === false && (<Route exact path="/tidalStations"
                                        element={<TidalStreamsHome screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} account={account} company={company} />} />)}

                                    {loadScene === false && (<Route exact path="/publicSettings"
                                        element={<PublicSettings screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} account={account} company={company} />} />)}
                                    {loadScene === false && (<Route exact path="/publicPreview"
                                        element={<PublicDashboardHome locked={false} screenWidth={screenWidth} openSnack={this.openSnack} preview={true} />} />)}
                                    {loadScene === false && (<Route exact path="/rainGauges"
                                        element={<RainGauges screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} account={account} company={company} />} />)}

                                    {loadScene === false && (<Route exact path="/testProbe"
                                        element={<TestProbe screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} account={account} company={company} />} />)}
                                    {loadScene === false && (<Route exact path="/productHistory"
                                        element={<ProductsHistory screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} account={account} company={company} />} />)}
                                    {loadScene === false && (<Route exact path="/siteCharacteristics"
                                        element={<SiteCharacteristics screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} account={account} company={company} />} />)}

                                    {loadScene === false && (<Route exact path="/stations"
                                        element={<StationsHome screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} account={account} company={company} />} />)}
                                    {loadScene === false && (<Route exact path="/historicalAlerts"
                                        element={<HistoricalAlerts screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} account={account} company={company} />} />)}
                                    {loadScene === false && Object.values(userInfo).length > 0 && (<Route exact path="/alerts"
                                        element={<AlertsHome screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} account={account} company={company} changeCompany={this.changeCompany} updateUserInfo={this.updateUserInfo} />} />)}
                                    {loadScene === false && Object.values(userInfo).length > 0 && (<Route exact path="/snoozeAlerts/:snooze/:alertId/:duration/:companyKey/:accountKey"
                                        element={<SnoozeAlert screenWidth={screenWidth} userInfo={userInfo}
                                            clearAccount={() => this.setState({ account: { accountName: '' } })}
                                            openSnack={this.openSnack} changeCompany={this.changeCompany}
                                            updateUserInfo={this.updateUserInfo} account={account} company={company} />} />)}



                                    {loadScene === false && (<Route exact path="/timeLapse"
                                        element={<CamerasHome screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} account={account} company={company} />} />)}
                                    {loadScene === false && (<Route exact path="/getProbeData"
                                        element={<GetProbeData screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} account={account} company={company} />} />)}
                                    {loadScene === false && (<Route exact path="/hobo"
                                        element={<Hobo screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} account={account} company={company} />} />)}
                                    {loadScene === false && (<Route exact path="/dataManagement"
                                        element={<DataManagementHome screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} account={account} company={company} />} />)}
                                    {loadScene === false && (<Route exact path="/dataStreams"
                                        element={<DataStreamsHome screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} account={account} company={company} />} />)}
                                    {loadScene === false && (<Route exact path="/weatherUnderground"
                                        element={<WunderStreamsHome screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} account={account} company={company} />} />)}
                                    {loadScene === false && (<Route exact path="/obscapeWeather"
                                        element={<ObscapeWeatherStreamsHome screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} account={account} company={company} />} />)}
                                    {loadScene === false && Object.values(account).length > 0 && Object.values(company).length > 0 && (<Route exact path="/newFieldLogHome"
                                        element={<NewFieldLogHome screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} account={account} company={company} />} />)}
                                    {loadScene === false && Object.values(account).length > 0 && Object.values(company).length > 0 && (<Route exact path="/newFieldLogHome-reports"
                                        element={<NewFieldLogHome screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} account={account} company={company} reports={'psReports'} />} />)}
                                    {loadScene === false && Object.values(account).length > 0 && Object.values(company).length > 0 && (<Route exact path="/newFieldLogHome-company"
                                        element={<NewFieldLogHome screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} account={account} company={company} reports={'company'} />} />)}
                                    {loadScene === false && Object.values(account).length > 0 && Object.values(company).length > 0 && (<Route exact path="/emailLog"
                                        element={<NewFieldLogEmail screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} account={account} company={company} />} />)}
                                    {loadScene === false && Object.values(account).length > 0 && Object.values(company).length > 0 && (<Route exact path="/psHome"
                                        element={<PSHomeNew screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} account={account} company={company} />} />)}
                                    {loadScene === false && Object.values(account).length > 0 && Object.values(company).length > 0 && (<Route exact path="/psItem"
                                        element={<PSItem screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} account={account} company={company} />} />)}
                                    {loadScene === false && Object.values(account).length > 0 && Object.values(company).length > 0 && (<Route exact path="/chemicalsHome"
                                        element={<ChemicalsHome screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} account={account} company={company} />} />)}
                                    {loadScene === false && Object.values(account).length > 0 && Object.values(company).length > 0 && (<Route exact path="/newApplicationHome"
                                        element={<NewApplicationHome screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} account={account} company={company} />} />)}
                                    {loadScene === false && Object.values(account).length > 0 && Object.values(company).length > 0 && (<Route exact path="/newApplicationHome-reports"
                                        element={<NewApplicationHome screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} account={account} company={company} reports={'psReports'} />} />)}
                                    {loadScene === false && Object.values(account).length > 0 && Object.values(company).length > 0 && (<Route exact path="/newApplicationHome-company"
                                        element={<NewApplicationHome screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} account={account} company={company} reports={'company'} />} />)}
                                    {loadScene === false && Object.values(company).length > 0 && (<Route exact path="/psReports"
                                        element={<PSReports screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} account={account} company={company} updateAccount={this.getAccount}
                                            updateUserInfo={this.updateUserInfo} />} />)}
                                    {loadScene === false && Object.values(company).length > 0 && (<Route exact path="/stockHome"
                                        element={<StockHome screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} account={account} company={company} updateAccount={this.getAccount}
                                            updateUserInfo={this.updateUserInfo} />} />)}
                                    {loadScene === false && Object.values(account).length > 0 && Object.values(company).length > 0 && (<Route exact path="/historyLogApplication"
                                        element={<HistoryLogApplciation screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} account={account} company={company} />} />)}
                                    {loadScene === false && Object.values(account).length > 0 && Object.values(company).length > 0 && (
                                        <Route path="/siteInfoHome/:step"
                                            element={<SiteInfoHome screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} account={account} company={company} getUser={this.getUser} updateUserInfo={this.updateUserInfo} />} />)}
                                    {loadScene === false && Object.values(company).length > 0 && (<Route exact path="/newDataHome"
                                        element={<NewDataHome screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} account={account} company={company} getUser={this.getUser} updateUserInfo={this.updateUserInfo} />} />)}
                                    {loadScene === false && Object.values(company).length > 0 && Object.values(account).length > 0 && (<Route exact path="/personalDashboard"

                                        element={<DashboardPersonal type="personal" screenWidth={screenWidth} account={account}
                                            userInfo={userInfo} openSnack={this.openSnack} getUser={this.getUser} company={company} />} />)}
                                    {loadScene === false && Object.values(company).length > 0 && Object.values(account).length > 0 && (
                                        <Route path="/publicPortals/:accountID/:portal/"

                                            element={<DashboardPortal type="personal" screenWidth={screenWidth} account={account}
                                                userInfo={userInfo} openSnack={this.openSnack} getUser={this.getUser} company={company} />} />)}
                                    {loadScene === false && Object.values(company).length > 0 && Object.values(account).length > 0 && (<Route exact path="/editAccountPerson"
                                        element={<EditAccountPerson screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} account={account} company={company} getUser={this.getUser} updateUserInfo={this.updateUserInfo} />} />)}
                                    {loadScene === false && Object.values(company).length > 0 && Object.values(account).length > 0 && (<Route exact path="/editDistributionGroup"
                                        element={<EditDistributionGroup screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} account={account} company={company} getUser={this.getUser} updateUserInfo={this.updateUserInfo} />} />)}
                                    {loadScene === false && Object.values(company).length > 0 && Object.values(account).length > 0 && (<Route exact path="/permissionsTemplates"
                                        element={<PermissionTemplates screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} account={account} company={company} getUser={this.getUser} updateUserInfo={this.updateUserInfo} />} />)}
                                    {loadScene === false && Object.values(company).length > 0 && (<Route exact path="/editStock"
                                        element={<EditStock screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} company={company} getUser={this.getUser} updateUserInfo={this.updateUserInfo} updateAccount={this.getAccount} />} />)}
                                    {loadScene === false && Object.values(company).length > 0 && (<Route exact path="/codes"
                                        element={<CodesHome screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} company={company} getUser={this.getUser} updateUserInfo={this.updateUserInfo} updateAccount={this.getAccount} />} />)}
                                    {loadScene === false && Object.values(company).length > 0 && (<Route exact path="/loading"
                                        element={<Row>
                                            <Col span={24} style={{ textAlign: 'center', paddingTop: 50 }}>Loading... <br /><CircularProgress /></Col>
                                        </Row>} />)}
                                    {loadScene === false && Object.values(company).length > 0 && (<Route exact path="/siteToAccount"
                                        element={<SiteToAccount screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} company={company} getUser={this.getUser} updateUserInfo={this.updateUserInfo} updateAccount={this.getAccount} />} />)}
                                    {loadScene === false && Object.values(company).length > 0 && (<Route exact path="/apiTest"
                                        element={<APITest screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} company={company} getUser={this.getUser} updateUserInfo={this.updateUserInfo} updateAccount={this.getAccount} />} />)}
                                    {loadScene === false && Object.values(company).length > 0 && (<Route exact path="/apiTestNoaa"
                                        element={<APITestNOAA screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} company={company} getUser={this.getUser} updateUserInfo={this.updateUserInfo} updateAccount={this.getAccount} />} />)}
                                    {loadScene === false && Object.values(company).length > 0 && (<Route exact path="/ost"
                                        element={<OSTStreamsHome screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} company={company} getUser={this.getUser} updateUserInfo={this.updateUserInfo} updateAccount={this.getAccount} />} />)}
                                    {loadScene === false && Object.values(company).length > 0 && (<Route exact path="/obscapeWeatherOld"
                                        element={<StreamHomeObscape screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} company={company} getUser={this.getUser} updateUserInfo={this.updateUserInfo} updateAccount={this.getAccount} />} />)}
                                    {loadScene === false && Object.values(company).length > 0 && (<Route exact path="/accountStaffHome"
                                        element={<AccountStaffHome screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} company={company} getUser={this.getUser} updateUserInfo={this.updateUserInfo} updateAccount={this.getAccount} />} />)}
                                    {loadScene === false && Object.values(company).length > 0 && (<Route exact path="/spreadsheet"
                                        element={<SpreadsheetTest screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} company={company} getUser={this.getUser} updateUserInfo={this.updateUserInfo} updateAccount={this.getAccount} />} />)}
                                    {loadScene === false && Object.values(company).length > 0 && (<Route exact path="/newWQHome"
                                        element={<WaterQualityHomeNew screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} company={company} getUser={this.getUser} updateUserInfo={this.updateUserInfo} updateAccount={this.getAccount} />} />)}
                                    {loadScene === false && Object.values(company).length > 0 && (<Route exact path="/newWQHomeSpeed"
                                        element={<WaterQualityHomeSpeedTest screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} company={company} getUser={this.getUser} updateUserInfo={this.updateUserInfo} updateAccount={this.getAccount} />} />)}
                                    {loadScene === false && Object.values(company).length > 0 && (<Route exact path="/newPhotosHome"
                                        element={<PhotosHomeNew screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} company={company} getUser={this.getUser} updateUserInfo={this.updateUserInfo} updateAccount={this.getAccount} />} />)}
                                    {loadScene === false && Object.values(company).length > 0 && (<Route exact path="/newDocumentsHome"
                                        element={<DocumentsHomeNew publicPortal={false} screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} company={company} getUser={this.getUser} updateUserInfo={this.updateUserInfo} updateAccount={this.getAccount} />} />)}
                                    {loadScene === false && Object.values(company).length > 0 && (<Route exact path="/newLogsHome"
                                        element={<FieldLogsHomeNew screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} company={company} getUser={this.getUser} updateUserInfo={this.updateUserInfo} updateAccount={this.getAccount} />} />)}
                                    {loadScene === false && Object.values(company).length > 0 && (<Route exact path="/newLogHome/:id"
                                        element={<FieldLogNew2 screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} company={company} getUser={this.getUser} updateUserInfo={this.updateUserInfo} updateAccount={this.getAccount} />} />)}
                                    {loadScene === false && Object.values(company).length > 0 && (<Route exact path="/biohome"
                                        element={<BioHome screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} company={company} account={account} getUser={this.getUser} updateUserInfo={this.updateUserInfo} updateAccount={this.getAccount} />} />)}
                                    {loadScene === false && Object.values(company).length > 0 && (<Route exact path="/parametersHomeNew"
                                        element={<ParametersHomeNew screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} company={company} account={account} getUser={this.getUser} updateUserInfo={this.updateUserInfo} updateAccount={this.getAccount} />} />)}
                                    {loadScene === false && Object.values(company).length > 0 && (<Route exact path="/weatherFunctions"
                                        element={<WeatherFunctionsHome screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} company={company} account={account} getUser={this.getUser} updateUserInfo={this.updateUserInfo} updateAccount={this.getAccount} />} />)}


                                </Routes>
                                {loadScene === true && (<Row><Col span={24} style={{ textAlign: 'center', paddingTop: 50 }}>Loading... <br /><CircularProgress /></Col> </Row>)}

                            </Col>)}

                            {loadScene === true && (
                                <Loading />
                            )}

                            <Drawer

                                PaperProps={{
                                    sx: { width: "50%" },
                                }}
                                open={openDrawer}
                                onClose={() => {
                                    this.setState({ openDrawer: false })
                                }}
                            >
                                <Row>
                                    <Col span={24}>
                                        <SiderMenu closeDrawer={() => this.setState({ openDrawer: false })} screenWidth={screenWidth} userInfo={userInfo} company={company} mobile={true} />

                                    </Col>
                                    <Col span={24}>
                                        <Divider />
                                    </Col>


                                </Row>
                            </Drawer>




                            <Snackbar open={openSnack} autoHideDuration={3000} onClose={this.closeSnack} >
                                <Alert severity={snackType} variant="filled">
                                    {snackDescription}
                                </Alert>
                            </Snackbar>

                        </Row >




                    </Content>

                    <Footer>
                        {loadScene === false && (<Col span={24} style={{ height: '150px', paddingTop: 40 }}>


                        </Col>)}

                    </Footer>
                </Layout>






                <GetPath />

            </Layout >
        );
    }
} 
