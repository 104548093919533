import React from "react";


import { Autocomplete, Box, Button, Grid, TextField, Typography } from '@mui/material';



const UploadHeader = (props) => {


    const { currentStep, backStep, returnHome, uploadType } = props;




    return (


        <Box sx={{ px: '5vw' }}>
            <Grid container spacing={3}

                sx={{
                    mt: 0,
                    pt: 2
                }} >
                <Grid item xs={12} sm={12} md={11} key={'upload'}
                    sx={{
                        pt: 2
                    }}>
                    <Typography variant="h6" fontWeight={700} fontSize={24} gutterBottom component="div">
                        {currentStep?.step === 'upload' ? `Import ${uploadType === 'csv-single-row' ? "Single Row" : uploadType === 'csv-multiple-row' ? "Multiple Row" : ""} CSV File` :
                            currentStep?.step === 'map' ? "Map Data" :
                                currentStep?.step === 'review' ? "Review Data" :
                                    currentStep?.step === 'complete' ? "Complete" : "Import CSV File"}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={1} key={'header'}
                    style={{
                        textAlign: 'right'
                    }}>
                    {currentStep?.step !== 'upload' && (
                        <Button variant="contained" color="primary"
                            onClick={() => backStep()}
                            style={{
                                textTransform: 'none',
                                backgroundColor: '#3f51b5',
                            }}>
                            Back
                        </Button>
                    )}
                    {currentStep?.step === 'upload' && (
                        <Button variant="contained" color="primary"
                            onClick={() => returnHome()}
                            style={{
                                textTransform: 'none',
                                backgroundColor: '#3f51b5',
                            }}>
                            Back
                        </Button>
                    )}
                </Grid>
            </Grid>
        </Box>






    );
};

export default UploadHeader;












