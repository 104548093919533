// Layer.jsx
import React, { useEffect, useRef, useState } from 'react';
import { Typography, IconButton, Switch, Button, ClickAwayListener, Popper, Box, List, ListItem, Divider } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Col, Row } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faBrush, faChevronDown, faChevronUp, faEdit, faEllipsisV, faGripVertical, faPlus } from '@fortawesome/free-solid-svg-icons';
import LayerModal from './LayerModal';
import CreateLayerItemButton from './CreateLayerItemButton';
import { createLayerItem } from './utils';
import { ReactSortable } from 'react-sortablejs';
import { fullMobile } from '../settings/util';
import LayerItemModal from './LayerItemModal';

const LayerItem = ({ item, layerItem, handleDeleteLayerItem, handleToggleLayerItem, company, widgetKey, setLayers, userInfo, setCurrentDrawingLayer, currentDrawingLayer,
    layer, setCurrentLayer, currentLayer, openSnack
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const anchorRef = useRef(null);

    useEffect(() => {
        setAnchorEl(anchorRef.current)
    }, [])




    return (
        <Row style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            border: '1px solid #404050',
            backgroundColor: 'white',
            padding: 4,
            marginBottom: 4,
            borderRadius: 5,

        }}
            align="middle"
        >
            <Col span={2} className='handle' style={{ cursor: 'grab' }} >
                <FontAwesomeIcon icon={faGripVertical} style={{ marginRight: 4 }} />
            </Col>
            <Col span={1}>
                <Divider orientation="vertical" flexItem sx={{ color: 'black' }} />
            </Col>
            <Col span={21}>
                <Row align="middle">
                    <Col span={4}>
                        <Switch
                            size='small'
                            checked={item.visible}
                            onChange={() => {
                                handleToggleLayerItem(layer.id, item.id, { visible: !item.visible })

                            }}
                        />
                    </Col>
                    <Col span={14} style={{ paddingLeft: 3 }} >
                        <Typography variant="body1" fontSize={13} fontWeight={600}>
                            {item.name}
                        </Typography>
                    </Col>
                    {item?.type === 'drawing' && (
                        <Col>
                            <IconButton
                                color="inherit"


                                onClick={(e) => {
                                    setCurrentDrawingLayer(item.id === currentDrawingLayer ? null : item.id)
                                    setCurrentLayer(layer.id)
                                }}
                            >
                                <Typography variant='body1' fontSize={16} fontWeight={400} color='black'>
                                    <FontAwesomeIcon icon={faBrush} style={{
                                        color: currentDrawingLayer === item.id ?
                                            item?.layerItemAttributes?.layerColor || 'orange'
                                            : 'grey'
                                    }} />
                                </Typography>
                            </IconButton>

                        </Col>
                    )}
                    <Col>
                        <IconButton
                            color="inherit"
                            ref={anchorRef}

                            onClick={(e) => {
                                setIsOpen(!isOpen)
                            }}
                        >
                            <Typography variant='body1' fontSize={16} fontWeight={400} color='black'>
                                <FontAwesomeIcon icon={faEllipsisV} />
                            </Typography>
                        </IconButton>

                    </Col>



                </Row>



            </Col>








            <LayerItemModal layer={layer} isOpen={isOpen} item={item} handleToggle={handleToggleLayerItem} setOpen={setIsOpen} 
            handleDelete={handleDeleteLayerItem} userInfo={userInfo} openSnack={openSnack} />








        </Row>
    );
};

export default LayerItem;
