// LayersDrawer.jsx
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { ReactSortable } from 'react-sortablejs';
import { KmlLayer } from '@react-google-maps/api'; // You may need to import this package
import NodeMarkers from './NodeMarkers';
import { getNodesList } from './utils';
import DrawnLayers from './drawnLayers';

const MapLayers = ({ userInfo, widgetKey,
    currentLayer, setCurrentLayer,
    currentDrawingLayer, layers, setLayers, handleToggle, mapRef }) => {
    const [nodes, setNodes] = useState([])

    useEffect(() => {
        getNodes();
    }, [])

    const [layer, setLayer] = useState(null);
    const [item, setItem] = useState(null);

    useEffect(() => {
        if (currentDrawingLayer !== null) {
            const layer = layers.find(layer => layer.id === currentLayer);
            setLayer(layer);
            const item = layer?.layerItems?.find(item => item.id === currentDrawingLayer);
            setItem(item);
        }
    }, [currentDrawingLayer, currentLayer, layers]);

    async function getNodes() {
        const nodesList = await getNodesList(userInfo)
        setNodes(nodesList);
       

    }

    async function handleShapeDelete(index) {
        handleToggle(layer.id, item.id, {
            layerItemAttributes: {
                ...item.layerItemAttributes,
                drawnObjects: item?.layerItemAttributes?.drawnObjects.filter((_, i) => i !== index),
            },
        });

    }

    async function handleShapeDrag(index, e) {
        const draggedObject = item.layerItemAttributes.drawnObjects[index];
        let updatedObject;
     

        if (draggedObject.type === 'marker') {
            updatedObject = { ...draggedObject, position: e.latLng.toJSON() };
        } else if (draggedObject.type === 'circle') {
            updatedObject = { ...draggedObject, center: e.getCenter().toJSON() }
        } else {
           
            return;
        }

        const updatedDrawnObjects = item.layerItemAttributes.drawnObjects.map((obj, i) =>
            i === index ? updatedObject : obj,
        );

        handleToggle(layer.id, item.id, {
            layerItemAttributes: {
                ...item.layerItemAttributes,
                drawnObjects: updatedDrawnObjects,
            },
        });
    }


    // Flatten layerItems from each layer
    const flattenedItems = layers.filter((l) => l.visible === true).flatMap(layer => layer.layerItems);


    // Filter out any undefined values
    const filteredItems = flattenedItems.filter(item => item !== undefined);


    // Map through filtered items and create KML layers for items with type "kml"
    const kmlLayers = filteredItems.map(item => {
        if (item.type === 'kml') {
            return item.visible ? (
                <KmlLayer

                    key={item.id}
                    url={item.url}
                    options={{ preserveViewport: true }}
                />
            ) : null;
        }
        return null;
    });
    const kmlLayersMultiple = filteredItems.map(item => {
        const kmlList = [];
        if (item.type === 'kmlFile') {

            const kmls = item.kmls || [];
            kmls.forEach((kml, index) => {
             
                if (item.visible) {
                    
                    kmlList.push(
                        <KmlLayer
                            key={index}
                            url={kml.downloadURL}
                            options={{ preserveViewport: true }}
                        />
                    );

                }
            })



        }

        return kmlList;

    });
 

    const nodeLayers = filteredItems
        .filter((item) => item.type === 'node' && item.visible)
        .map((item) => (
            <NodeMarkers key={item.id} nodes={item.layerItemAttributes.nodes} markerSymbol={item.layerItemAttributes.markerSymbol}
                accountNodes={nodes} />
        ));
    const drawingLayers = filteredItems
        .filter((item) => item.type === 'drawing' && item.visible)
        .map((item) => (
            <DrawnLayers key={item.id} drawnObjects={item.layerItemAttributes.drawnObjects} editing={currentDrawingLayer === item.id}
                onShapeDrag={handleShapeDrag} mapRef={mapRef}
                onShapeDelete={index => handleShapeDelete(index)} />
        ));


    return (
        <>
            {kmlLayersMultiple}
            {kmlLayers}
            {nodeLayers}
            {drawingLayers}
        </>
    );
};

export default MapLayers;
