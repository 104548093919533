import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import './apiTestStyles.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { } from '@fortawesome/free-solid-svg-icons';

class MuiAutocompleteEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
    };
  }

  getValue() {
    return this.state.value;
  }

  isCancelBeforeStart() {
    return false;
  }

  isCancelAfterEnd() {
    return false;
  }

  afterGuiAttached() {
    this.inputRef.focus();
  }

  handleSelectChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  render() {
    const { value } = this.state;
    const options = ['Toyota', 'Ford', 'Porsche'];

    return (
      <Autocomplete
        size="small"
        autoFocus
        className="no-padding-autocomplete"
        value={value}
        options={options}
        onChange={this.handleSelectChange}
        renderInput={(params) => {
          this.inputRef = params.InputProps.ref;
          return <TextField {...params} fullWidth />;
        }}
      />
    );
  }
}

class NumberInputRenderer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
    };
  }

  getValue() {
    return this.state.value;
  }

  isCancelBeforeStart() {
    return false;
  }

  isCancelAfterEnd() {
    return false;
  }

  afterGuiAttached() {
    this.inputRef.focus();
  }

  handleChange = (event) => {
    this.setState({ value: event.target.value });
  };

  render() {
    const { value } = this.state;

    return (
      <TextField
        size="small"
        autoFocus
        type="number"
        value={value}
        onChange={this.handleChange}
        inputProps={{ ref: (input) => (this.inputRef = input) }}
        fullWidth
      />
    );
  }
}


export { MuiAutocompleteEditor, NumberInputRenderer };

