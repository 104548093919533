import React, { useEffect, useRef, useState } from 'react';

import MapView from "@arcgis/core/views/MapView";
import Map from "@arcgis/core/Map";
import { Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { readGraphicsLayer, setGraphicsToLayer } from './fieldLogNew/components/mainLogComponent/items/tableData/components/mapComponents/symbology/labels';

import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";


const MapComponent = ({
    account,
    mapInfo,
    setDisableDrag,
    record,
    updateRecord,
    open,
    setOpen,
    userInfo,
    getLatestMapInfo,
    type,
    currentGraphic,
    setCurrentGraphic
}) => {

    const mapDiv = useRef(null);
    const graphicsRef = useRef(null);
    const viewRef = useRef(null);
    const highlightRef = useRef(null);
    const [showLabels, setShowLabels] = useState(mapInfo?.showLabels || false);
    const [layerColor, setLayerColor] = useState(mapInfo?.layerColor || { rgb: { r: 255, g: 0, b: 0, a: 1 }, hex: '#ff0000' });
    const [labelAttributes, setLabelAttributes] = useState(mapInfo?.labelAttributes);
    const [graphics, setGraphics] = useState(mapInfo?.graphicsLayer || []);
    const [mapUnits, setMapUnits] = useState(mapInfo?.mapUnits || { area: 'acres', length: 'feet' });
    const [initialLoad, setInitialLoad] = useState(true);

    useEffect(() => {
        /* current graphic changed */
        const view = viewRef.current;
        const graphics = graphicsRef.current;
        if (view !== undefined && view !== null && graphics !== undefined && graphics !== null) {
            highlightGraphic(currentGraphic, view);
        }
    }, [currentGraphic])

    function highlightGraphic(uuid, v) {
        if (v !== undefined && v !== null && graphics !== undefined && graphics !== null) {
       
            v?.whenLayerView(graphicsRef.current)?.then((layerView) => {
                // Ensure that view and graphics are still valid
                if (!viewRef.current || !graphicsRef.current) return;

                // Remove any existing highlight
                if (highlightRef.current) {
                    highlightRef.current.remove();
                    highlightRef.current = null;
                }

                // Add new highlight if uuid is not null
                if (uuid !== null) {
                    const graphicsToBeHighlighted = graphicsRef.current.graphics.filter(graphic => graphic?.attributes?.uuid === uuid && graphic?.attributes?.type !== "label");
                    highlightRef.current = layerView.highlight(graphicsToBeHighlighted);
                }
            }).catch(error => {
                console.error("Error with layerView or view is no longer available:", error);
            });
        }
    }













    const props = {
        showLabels,
        setShowLabels,
        layerColor,
        setLayerColor,
        labelAttributes,
        setLabelAttributes,
        mapUnits,
        setMapUnits
    };


    async function getGraphics(graphicsLayer, view) {
        const { layerColor, labelAttributes, showLabels, mapUnits } = await readGraphicsLayer(graphicsLayer, userInfo, record);
        console.log(mapUnits)
        setLayerColor(layerColor);
        setLabelAttributes(labelAttributes);
        setShowLabels(showLabels);
        setMapUnits(mapUnits);


    }

    useEffect(() => {
        if (mapDiv.current) {
            // Initialize application

            // Inside useEffect or a similar initializing function
            let graphicsLayer = new GraphicsLayer({
                id: 'graphicsLayer',
                title: "Sketch Layer",
            });

            graphicsRef.current = graphicsLayer;



            const webmap = new Map({
                basemap: "satellite",
                layers: [graphicsLayer]
            });



            const view = new MapView({
                container: mapDiv.current,
                map: webmap,
                center: [account?.lng, account?.lat],
                zoom: 16,
                highlightOptions: {
                    color: [255, 255, 0, 1],
                    haloOpacity: 0.9,
                    fillOpacity: 0.2
                }
            });

            /* when mouse scroll over graphic setCurrentGraphic to graphic?.attributes?.uuid */
            view.on("pointer-move", (event) => {
                view.hitTest(event).then((response) => {
                    const graphic = response.results[0]?.graphic;
                    if (graphic) {
                        setCurrentGraphic(graphic?.attributes?.uuid)
                    } else {
                        setCurrentGraphic(null)
                    }
                });
            });

            viewRef.current = view;
            setGraphicsToLayer(graphicsLayer, graphics, getGraphics, view);

            // Cleanup the map view
            return () => view && view.destroy();
        }
    }, [account?.lng, account?.lat]); // Re-run effect if account coordinates change










    // Function to handle edit action
    const handleEdit = () => {
        // Define what happens when you click edit. Possibly open a form or change map mode.
        console.log("Edit button clicked!");
        setOpen(true);
        // setOpen(true/false) or any other action
    }

    return (
        <div style={{ position: 'relative' }}>
            <div className="mapDiv" ref={mapDiv} style={{ height: 400, width: "100%" }}></div>
            <button onClick={handleEdit} style={{
                position: 'absolute',
                top: 10, // Adjust the top and right values to place the button where you want it
                right: 10,
                zIndex: 10, // Ensure the button is above the map layers
                cursor: 'pointer',
            }}>
                <Typography variant="h6" component="div" color={'rgba(0,0,0,0.8)'} fontWeight={700} fontSize={14}>
                    Mark up
                </Typography>
            </button>
        </div>
    );
}

export default MapComponent;
