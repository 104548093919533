import React, { useEffect, useRef, useState } from "react";


import { CircularProgress, Typography } from '@mui/material';
import { Col } from 'antd';
import './taskList.css';


import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import uuid from "react-uuid";

import InfiniteScroll from 'react-infinite-scroller';


import TableRow from "./dataTableRow";



const DataTableRows = (
    {
        userInfo,
        item,
        setItem,
        data,
        setData,
        screenWidth,
        showCompleted,
        loading,
        deleteData,
        updateData,
        setDataDrawer,
        duplicateData,
        updateDataPosition,
        headers,
        getNestedProperty,
        selectable,
        sortItem,
        setSortItem,
        stations,
        parameters,
        codes,
        unitsArray,
        currentCell,
        setCurrentCell,
        setErrorModal,
        currentRow,
        setCurrentRow,
        checkErrors,
        startIndex,
        endIndex,
        currentPage,
        rowsPerPage
    }

) => {

    const [lastClickedIndex, setLastClickedIndex] = useState(null);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const scrollContainerRef = useRef(null);
    const [displayRowCount, setDisplayRowCount] = useState(2);








    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    function onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            data,
            result.source.index,
            result.destination.index
        );


        setSortItem(null)
        updateDataPosition(items);
    }

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer


        // change background colour if dragging


        // styles we need to apply on draggables
        ...draggableStyle
    });

    const getListStyle = isDraggingOver => ({



    });



    async function updateData(data, key) {
        const newData = JSON.parse(JSON.stringify(data));
        const index = newData.findIndex((item) => item.id === data.id);
        newData[index][key] = data[key];
        setData(newData);
    }


    async function deleteData(data) {
        const newData = JSON.parse(JSON.stringify(data));
        const index = newData.findIndex((item) => item.id === data.id);
        newData.splice(index, 1);
        setData(newData);
    }

    async function duplicateData(data) {
        const newData = JSON.parse(JSON.stringify(data));
        const index = newData.findIndex((item) => item.id === data.id);
        const newItem = { ...data, id: uuid() };
        newData.splice(index + 1, 0, newItem);
        setData(newData);
    }



    async function keyDown(e, row) {
        console.log(row)
        console.log(currentCell?.selectedID.split('??')[1])
        const header = headers.find((h) => h.name === currentCell?.selectedID.split('??')[1]);
        console.log(header)
        const headerIndex = headers.findIndex((h) => h.name === header.name);
        const rowIndex = data.findIndex((item) => item.id === row?.id);

        if (e.key === 'Enter') {
            const nextRowIndex = e.shiftKey ? rowIndex - 1 : rowIndex + 1;
            const nextRow = data[nextRowIndex];
            if (nextRow) {
                setCurrentCell({ ...nextRow, selectedID: `${nextRow.id}??${header.name}` });
            } else {
                setCurrentCell(null);
            }
        } else if (e.key === 'Tab') {
            e.preventDefault();  // Prevent the default tab behavior

            const nextHeaderIndex = e.shiftKey ? headerIndex - 1 : headerIndex + 1;
            const nextHeader = headers[nextHeaderIndex];

            if (nextHeader) {
                setCurrentCell({ ...row, selectedID: `${row.id}??${nextHeader.name}` });
            } else {
                setCurrentCell(null);
            }
        }
    }






    return (





        <div ref={scrollContainerRef}>
            <Col span={24} style={{ paddingBottom: 30, paddingRight: 0, marginRight: 0 }}>


                <DragDropContext onDragEnd={(source) => onDragEnd(source)}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}

                            >
                                {[...data]?.slice(startIndex, endIndex).map((row, i) => {


                                    if (i === 0) {
                                       
                                    }

                                    return (
                                        <Draggable key={row.id} draggableId={row.id} index={i} id={row.id}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}

                                                    id={row.id}
                                                >
                                                    <Col span={24} style={{ paddingBottom: 0, paddingRight: 0, marginRight: 0 }}>
                                                        <TableRow rowData={row} headers={headers} data={data} currentCell={currentCell} stations={stations} setErrorModal={setErrorModal}
                                                            setCurrentCell={setCurrentCell} parameters={parameters} unitsArray={unitsArray} keyDown={keyDown} setData={setData} checkErrors={checkErrors}
                                                            codes={codes} duplicateData={duplicateData} deleteData={deleteData} lastClickedIndex={lastClickedIndex} setLastClickedIndex={setLastClickedIndex}

                                                        />
                                                    </Col>

                                                </div>
                                            )
                                            }
                                        </Draggable>




                                    );

                                })}


                            </div>
                        )
                        }
                    </Droppable>
                </DragDropContext>



                {
                    loading === false && data?.length === 0 && (<Col span={24} style={{ paddingBottom: 30, textAlign: 'center' }}>
                        <Typography variant="h4" fontSize={24} fontWeight={600}>No Data</Typography>
                    </Col>)
                }


            </Col >

        </div>



    );
};

export default DataTableRows;
