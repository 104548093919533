import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Box, IconButton, List, ListItem, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { Col, Row } from 'antd';

const ScreenShotPopover = ({ takeScreenshot, customScreenshot, type }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <Tooltip title="Screenshot" placement="top">
                <IconButton size="large"
                    aria-describedby={id}
                >
                    <FontAwesomeIcon
                        fontSize={18}
                        icon={faCamera}
                        onClick={handleClick}
                        color="#9ec1c9"
                    />
                </IconButton>
            </Tooltip>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box sx={{
                    p: 0, bgcolor: 'background.paper',
                    borderRadius: 2,
                    border: '1px solid #e0e0e0',
                    boxShadow: 1,

                }}>
                    <Row style={{}}>
                        <Col span={24} style={{ textAlign: 'center', padding: 8, borderRadius: '2px 2px 0px 0px', backgroundColor: 'white', }}>

                            <List sx={{ width: 240 }}>


                                {type === '123' && (<ListItem button sx={{
                                    borderBottom: '1px solid #e0e0e0',
                                    height: 40,
                                }}
                                    onClick={() => {
                                        customScreenshot()
                                        handleClose()
                                    }}

                                >
                                    <Typography variant="body1" fontWeight={400} fontSize={14} style={{ textAlign: 'left', }}>
                                        Custom Screenshot
                                    </Typography>
                                </ListItem>
                                )}
                                <ListItem button sx={{
                                    borderBottom: '1px solid #e0e0e0',
                                    height: 40,
                                }}
                                    onClick={async () => {
                                        //close popover
                                        takeScreenshot('noTitle')
                                        handleClose()

                                    }}

                                >
                                    <Typography variant="body1" fontWeight={400} fontSize={14} style={{ textAlign: 'left', }}>
                                        Screenshot no title
                                    </Typography>
                                </ListItem>
                                <ListItem button sx={{
                                    borderBottom: '1px solid #e0e0e0',
                                    height: 40,
                                }}
                                    onClick={async () => {
                                        //close popover
                                        takeScreenshot()
                                        handleClose()

                                    }}

                                >
                                    <Typography variant="body1" fontWeight={400} fontSize={14} style={{ textAlign: 'left', }}>
                                        Screenshot
                                    </Typography>
                                </ListItem>



                            </List>


                        </Col>

                    </Row>
                </Box>
            </Popover>
        </div>
    );
}

export default ScreenShotPopover;