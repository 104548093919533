import {
    Autocomplete,
    Checkbox,
    Divider,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { Button, Col, Row } from "antd";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faCopy } from "@fortawesome/free-solid-svg-icons";
import moment from 'moment-timezone';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const OffsetTableRow = ({ index, offset, updateOffset, offsets, deleteOffsets, duplicateOffset, parameters, setOffsets, key, openSnack }) => {



    const rowPadding = 4;

    /* filter out any parameter options that are already selected in an offset */

    const parameterOptions = parameters.sort((a, b) => -b.label.localeCompare(a.label)).filter((param) => !offsets.map((off) => off.parameter).includes(param.id));




    return (

        <Row gutter={[6, 6]} key={index} align={'middle'}
            style={{
                backgroundColor: index % 2 === 0 ? "#e6e6e6" : "white",
                padding: 12,
                paddingLeft: 20,
                paddingRight: 20
            }}>
     

            <Col span={5}>
                {offset.type !== 'locationDrift' && (<Typography fontSize={13} fontWeight={600} variant="body1">Parameter<span style={{ color: 'red' }}>*</span></Typography>)}
                {offset.type !== 'locationDrift' && (<Autocomplete
                    style={{ backgroundColor: 'white' }}
                    size="small"
                    options={parameterOptions}
                    getOptionLabel={(option) => option.label}
                    defaultValue={parameters.find((param) => param.id === offset?.parameter)}
                    onChange={(event, newValue) => {
                        const offIndex = offsets.findIndex((off) => off.id === offset.id);
                        const newOffsets = [...offsets];
                        newOffsets[offIndex].parameter = newValue.id;
                        newOffsets[offIndex].updated = true;
                        setOffsets(newOffsets);
                    }
                    }
                    renderOption={(props, option) => (
                        <li {...props} style={{ fontSize: 14 }}>
                            {option.label}
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField {...params} placeholder="Parameter" />
                    )}
                />)}
            </Col>
            <Col span={4}>
                {offset.type !== 'locationDrift' && (<Typography fontSize={13} fontWeight={600} variant="body1">Value<span style={{ color: 'red' }}>*</span></Typography>)}
                {offset.type !== 'locationDrift' && (<TextField
                    fullWidth
                    style={{ backgroundColor: 'white' }}
                    size="small"
                    type="number"
                    placeholder="Value"
                    defaultValue={offset.value}
                    onChange={(e) => {
                        const offIndex = offsets.findIndex((off) => off.id === offset.id);
                        const newOffsets = [...offsets];
                        newOffsets[offIndex].value = e.target.value;
                        newOffsets[offIndex].updated = true;
                        setOffsets(newOffsets);
                    }}

                />)}
            </Col>







            <Col >
                <Button
                    /* click to delete offset */
                    onClick={() => {
                        deleteOffsets([offset])
                    }}
                    style={{
                        backgroundColor: '#3f51b5',
                        textTransform: 'none',
                        color: 'white',
                        marginRight: 5,
                        marginTop: 5

                    }}
                >
                    Delete
                </Button>




            </Col>
            <Col >
                {offset?.updated === true && (
                    <Button
                        /* click to delete offset */
                        onClick={() => {
                            const offIndex = offsets.findIndex((off) => off.id === offset.id);
                            const newOffset = offsets[offIndex];
                            newOffset.updated = false;
                            updateOffset(newOffset, openSnack)
                        }}
                        style={{
                            backgroundColor: '#3f51b5',
                            textTransform: 'none',
                            color: 'white',
                            marginRight: 5,
                            marginTop: 5

                        }}
                    >
                        Save
                    </Button>
                )}




            </Col>


        </Row >











    );
};

export default OffsetTableRow;
