import React, { useState } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp, faEllipsisH, faPlus, faX } from '@fortawesome/free-solid-svg-icons';
import { IconButton, List, Tooltip, ListItem, OutlinedInput, InputAdornment, ListItemIcon } from '@mui/material';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import uuid from 'react-uuid';
import { useNavigate } from 'react-router-dom';

export default function CreateItem({
    setCurrentAlbum,
    setUploadModal,
    screenWidth

}) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const CreateLog = () => {
        let navigate = useNavigate()
        return (


            <ListItem
                key={'createLog'}
                dense
                button
                onClick={() => {
                    navigate('/newLogHome/create')
                    setAnchorEl(null)
                }}
                sx={{
                    borderBottom: '1px solid #e0e0e0',
                    paddingTop: 2,
                    paddingBottom: 2,
                }}
            >
                <FontAwesomeIcon icon={faPlus} size="lg" style={{ color: 'gray', marginRight: 10 }} />
                <Typography variant="body1" color="black" fontSize={16} fontWeight={500}>Add field log</Typography>
            </ListItem>
        )
    }

    return (
        <>
            <Button
                fullWidth={screenWidth === 'xs' ? true : false}
                style={{
                    textTransform: 'none',
                    fontSize: 14,
                    color: 'rgba(0,0,0,0.9)',
                    fontWeight: 500,
                    padding: 8,
                    border: '1px solid rgba(0,0,0,0.2)',
                }}
                onClick={handleClick}
            >
                <FontAwesomeIcon icon={faPlus} style={{ color: 'rgba(0,0,0,0.5)', marginRight: 5 }} />
                Add new
                <CaretDownOutlined style={{
                    marginLeft: 15
                }} />
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}

                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <List sx={{
                    width: 300,
                    padding: 0,

                }}>

                    <CreateLog />




                </List>




            </Popover>



        </>
    );
}