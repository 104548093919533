import { collection, doc, getDoc, getDocs, getFirestore, onSnapshot, orderBy, query, setDoc, updateDoc, where } from "firebase/firestore";
import moment from 'moment-timezone';
import axios, * as others from 'axios';


export const fetchSensors = async (userInfo) => {
    try {
        const db = getFirestore();
        const sensors = [];
        const queryStations = query(collection(db, "clients", userInfo.currentCompany, 'stations'), where('account', '==', userInfo.currentAccount));
        const snapNodes = await getDocs(queryStations);
        snapNodes.forEach((doc) => {
            if (doc.data().archived !== true && doc.data().children.length > 0) {
                doc.data().children.forEach((child) => {
                    sensors.push({ ...child, stationLabel: doc.data().name, label: child.name, type: 'node', stationType: child.type })
                });
            }
        });
        return sensors;
    }
    catch (error) {
        console.error('Error fetching sensors:', error);
    }
}
