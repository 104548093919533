

import {
    Badge,
    IconButton,
    List,
    ListItem,
    Popover,
    Tooltip,
    Typography,
    lighten
} from '@mui/material';
import React from 'react';
import { faCheckCircle, faCircle, faCircleCheck, faCopy, faEdit, faEye, faEyeSlash, faSquare, faSquareCheck, } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faEllipsisH, faPaintBrush, faTrash } from '@fortawesome/free-solid-svg-icons';
import * as Papa from 'papaparse';
import moment from 'moment-timezone';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';

const ColorCalendar = ({ sortedTasks, visibleHeaders, deleteSelectedTasks, selectedTasks, setBulkEditor, getNestedProperty, setColorCalendar, colorCalendar }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;




    return (
        <div style={{
            display: 'inline',

        }}>
            <IconButton onClick={handleClick}

            >
                <Tooltip title="More Options" placement="bottom">
                    <FontAwesomeIcon icon={faPaintBrush}
                        size="md" style={{ color: '#3f51b5', cursor: 'pointer' }} />
                </Tooltip>



            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}

                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <List sx={{
                    width: 300,
                    padding: 0,

                }}>
                    {[
                        { id: 'colorByTask', label: 'Color by task' },
                        { id: 'colorByStaff', label: 'Color by staff' },
                        { id: 'colorByAccount', label: 'Color by account' },

                    ].map((option) => (
                        <ListItem
                            key={option.id}
                            dense
                            button
                            onClick={() => {
                                setColorCalendar(option.id)
                            }}
                            sx={{
                                borderBottom: '1px solid #e0e0e0',
                                paddingTop: 1,
                                paddingBottom: 1,
                                backgroundColor: colorCalendar === option.id ? lighten('#3f51b5', 0.8) : 'white',
                                
                            }}
                        >
                            <FontAwesomeIcon size="lg" icon={colorCalendar === option.id ? faCircleCheck : faCircle} style={{ color: 'black', marginRight: 10 }} />
                            <Typography variant="body1" color="inherit" fontSize={16} fontWeight={500}>{option.label}</Typography>
                        </ListItem>
                    ))}









                </List>




            </Popover>
        </div>
    );
};

export default ColorCalendar;
