import { Button, CircularProgress } from '@mui/material';
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fullMobile } from './../../util';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, } from 'firebase/firestore';




import { MakePDF } from './logPDF';
import uuid from 'react-uuid';
import moment from 'moment-timezone';
var randomColor = require('randomcolor');

export default function ApplicationHeaderButtons(props) {
    const { userInfo, screenWidth, openSnack, company, account, updateStatus, logRef, updateLog, typeRef, } = props;
    const [log, setLog] = useState(null)
    const [downloading, setDownloading] = useState(false)
    const [type, setType] = useState(typeRef)
    const [saving, setSaving] = useState(false)
    useEffect(() => {
        setLog(logRef)

    }, [logRef])
    useEffect(() => {
        setType(typeRef)

    }, [typeRef])



    const EmailLink = () => {
        let navigate = useNavigate();

        return (

            <Button display="inline" style={{ textTransform: 'none', fontSize: 12, marginLeft: 10 }} color="inherit" variant='outlined'
                onClick={() => {
                    navigate('/emailApplication')
                }}>Email</Button>

        )
    }

    const HistoryLink = () => {
        let navigate = useNavigate();

        return (

            <Button display="inline" style={{ textTransform: 'none', fontSize: 12, marginLeft: 10 }} color="inherit" variant='outlined'
                onClick={() => {
                    navigate('/historyLogApplication')
                }}>History</Button>

        )
    }

    function getFirstLetters(str) {
        if (str) {
            const firstLetters = str
                .split(' ')
                .map(word => word[0])
                .join('');

            return firstLetters;
        }
        else return '';
    }

    const LogID = getFirstLetters(company.companyName) + "-" +
        `${log?.account !== undefined ? getFirstLetters(log?.account === undefined ? '' : `${log?.account?.accountName}`) : ''}`
        + '-' + moment(log?.startDate, 'x').format('MM-DD-YYYY') + "-" + "APP";

    async function saveLog() {

        setSaving(true)
        //check for log name and log leadStaff, make sure they are not empty and not null and not undefined
        //check for log name and log leadStaff, make sure they are not empty and not null and not undefined
        if (log?.leadStaff) {


            const db = getFirestore();

            const docRef = doc(db, "clients", userInfo.currentCompany, 'fieldDocs', log.key || log.tempKey);
            setDoc(docRef,
                {
                    ...log,
                    status: 'built',
                    key: log.tempKey,
                }).then(async () => {

                    if (log.history.length === 0) {
                        const message = `${userInfo.firstName || ''} ${userInfo.lastName || ''} has created this field log.`
                        const messageItem = {
                            key: uuid(),
                            message,
                            user: `${userInfo.firstName || ''} ${userInfo.lastName || ''}`,
                            date: moment().format('MM/DD/YYYY h:mm a'),
                            type: 'Status Change',

                        }

                        const itemString = JSON.stringify(messageItem);
                        //update history with arrayunion
                        await updateDoc(docRef, {
                            history: arrayUnion(itemString)
                        })
                    }

                }).then(async () => {




                    const userRef = doc(db, "users", userInfo.id, 'currentItems', 'currentFieldDoc');
                    await setDoc(userRef, {
                        key: log.tempKey
                    })
                    updateLog('key', log.tempKey)
                    openSnack("success", 'Saved')
                    updateStatus('built');
                    setSaving(false)

                })




        }
        else {
            setSaving(false)
            openSnack("error", 'Please enter lead staff')
        }
    }


    if (['loading'].includes(props.status)) {

        return (
            <Row gutter={[12, 12]}>
                <Col {...fullMobile(12, 24)}>
                    <CircularProgress />
                </Col>
            </Row>
        );
    }
    if (['creating'].includes(props.status)) {

        return (
            <Row gutter={[12, 12]} justify="end" >
                <Col {...fullMobile(24, 24)}>
                    {/*  <Button display="inline" style={{ textTransform: 'none', fontSize: 12, marginLeft: 10 }} color="inherit" variant='outlined'
                        onClick={() => {
                            if (log?.leadStaff) {
                                openSnack("success", 'Saved')

                                const db = getFirestore();
                              
                                
                            }
                            else {
                                openSnack("error", 'Please enter a log name and lead staff')
                            }
                        }}>Save</Button> */}
                    {saving === true ? <CircularProgress /> : <Button display="inline" style={{ textTransform: 'none', fontSize: 12, marginLeft: 10 }} color="inherit" variant='outlined'
                        onClick={saveLog}>Save & Continue</Button>}


                </Col>
            </Row>
        );
    }
    if (['built'].includes(props.status)) {

        if (type === 'email') {
            {
                if (downloading === true) {
                    return (
                        <CircularProgress />
                    )
                } else {
                    return (
                        <Button display="inline" style={{ textTransform: 'none', fontSize: 12, marginLeft: 10 }} color="inherit" variant='outlined'
                            onClick={async () => {
                                setDownloading(true)
                                const pdf = await MakePDF(log, userInfo, openSnack);
                                setDownloading(false)

                            }}>Email</Button>
                    )
                }
            }
        }
        else {



            return (

                <Row gutter={[12, 12]} justify="end">
                    <Col {...fullMobile(24, 24)}>
                        <Button display="inline" style={{ textTransform: 'none', fontSize: 12, marginLeft: 10 }} color="inherit" variant='outlined'
                            onClick={() => {
                                updateStatus('creating');
                            }}>Edit</Button>

                        <HistoryLink />
                        <EmailLink />

                        {downloading === true ? <CircularProgress /> : <Button display="inline" style={{ textTransform: 'none', fontSize: 12, marginLeft: 10 }} color="inherit" variant='outlined'
                            onClick={async () => {
                                setDownloading(true)

                                const pdf = await MakePDF(log, userInfo, openSnack, 'none', company);
                                setDownloading(false)
                                pdf.save(`${moment(log?.startDate, 'x').format('YYYY-MM-DD')}_${LogID}.pdf`);
                            }}>Export</Button>
                        }

                    </Col>
                </Row>
            );
        }
    }



}