import React from "react";

import moment from 'moment-timezone';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Typography } from "@mui/material";
import './dateCellcss.css'

const DateCell = (props) => {


    const { unitsArray, header, row, currentCell, updateRow, keyDown, type,  } = props;

    const editing = currentCell?.selectedID === `${row?.id}??${header.name}` && currentCell !== null;
    const value = row?.[header.name];

    


    if (editing || type === 'bulk') {
       
        return (
            <DatePicker portalId="root-portal"
                placeholderText={'Please select a date'}
                showTimeSelect
                timeFormat="h:mm a"
                timeIntervals={1}

                autoFocus={type === 'bulk' ? false : true}
                timeCaption="time"
                customInput={<input
                    className="dateCell"
                    style={{
                        fontSize: '12px',
                        fontFamily: 'Roboto',



                    }} />}
                dateFormat="MM/dd/yyyy h:mm a"
                selected={row?.[header.name] ? moment(row?.[header.name], 'YYYY-MM-DD HH:mm').toDate() : null}
                onChange={(date) => {
                    const newRow = { ...row, [header.name]: moment(date).format('YYYY-MM-DD HH:mm') };
                    updateRow(newRow, 'date');

                }}
                onKeyDown={(e) => {
                    if (!['Enter', 'Tab'].includes(e.key)) return;
                    if (type === 'bulk') return;
                    keyDown(e, row);

                }}

            />
        )
    }
    else {

        return (

            <Typography variant="body2" fontWeight={500} fontSize={12} color={(value === "" ? "red" : "inherit")}>
                {value === "" ? "--" : moment(value, 'YYYY-MM-DD HH:mm').format("MM/DD/YYYY h:mm a")}
            </Typography>

        )
    }
};

export default DateCell;
