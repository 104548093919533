import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import { useDropzone } from "react-dropzone";





const UploadBox = ({
    screenWidth,
    openSnack,
    userInfo,
    files,
    uploadModal,
    setUploadModal,
    createFiles,


}) => {
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [dragOver, setDragOver] = useState(false);


    const { getRootProps, getInputProps } = useDropzone({
        onDrop: async (acceptedFiles) => {
            createFiles(acceptedFiles)
            setDragOver(false)
        },
        onDragEnter: () => {
            setDragOver(true)
        },
        onDragLeave: () => {
            setDragOver(false)
        },
        accept: 'application/*, image/*, video/*, audio/*',  // Add this line to accept only certain image types
        // Optional: if you want to disable opening the file dialog on click
        // Optional: if you want to disable opening the file dialog with the keyboard
        // ... other configurations
    });



    return (

        <div style={{ marginTop: 20, marginBottom: 20 }}>





            <div {...getRootProps()}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: 20,
                    borderRadius: 5,
                    border: dragOver ? '2px dashed rgba(0,0,0,0.8)' : '2px dashed rgba(0,0,0,0.4)',
                    borderRadius: 5,
                    cursor: 'pointer',
                    backgroundColor: dragOver ? 'rgba(0,0,0,0.3)' : 'white',
                    height: 100,
                    width: '100%',
                    marginBottom: 20,
                    marginTop: 20
                }}



            >
                <input {...getInputProps()} />

                <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography variant="h5" align="center" fontSize={screenWidth === 'xs' ? 16 : 18} fontWeight={700} style={{ color: 'rgba(0,0,0,0.7)', display: 'block', width: '100%' }}>
                        Drag and drop files here
                    </Typography>
                    <br />
                    <Typography variant="h5" align="center" fontSize={screenWidth === 'xs' ? 14 : 16} fontWeight={600} style={{ color: 'rgba(0,0,0,0.7)', display: 'block', width: '100%' }}>
                        Click to add files
                    </Typography>
                </Box>


            </div>


















        </div >

    );

};

export default UploadBox;
