

import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lighten } from '@mui/system';
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Chip,
    Container,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import { Col, Row } from 'antd';
import React from 'react';


const TagCondition = ({
    condition,
    conditionIndex,
    selectedItems,
    setSelectedItems,
    item,
    codes

}) => {


    const codeOptions = codes.map((c) => c.label);


    return (
        <Box style={{ width: '100%' }}>
            <Row align="middle">
                <Col span={5}>
                    <Typography variant='body1' color={'black'} fontWeight={500} fontSize={13} >
                        Codes
                    </Typography>
                </Col>
                <Col span={19}>
                    <Autocomplete

                        multiple
                        id="tags-standard"
                        options={codeOptions}
                        getOptionLabel={(option) => option}
                        defaultValue={condition.tags}
                        allowClear
                        disableCloseOnSelect
                        onChange={(e, b) => {
                            const newSelectedItems = { ...selectedItems };
                            if (newSelectedItems.hasOwnProperty(item.key)) {
                                newSelectedItems[item.key].conditions = newSelectedItems[item.key].conditions.map((c, cIdx) =>
                                    cIdx === conditionIndex ? { ...c, tags: b } : c
                                );
                            }
                            setSelectedItems(newSelectedItems);
                        }}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip
                                    variant="outlined"
                                    label={option}
                                    {...getTagProps({ index })}
                                    sx={{
                                        border: `2px solid #3f51b5`,
                                        color: '#3f51b5',
                                        boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.1)',
                                        backgroundColor: 'white', // Lightens the color
                                        fontSize: '13px',
                                        fontWeight: 500,
                                        '&:hover': {
                                            backgroundColor: lighten('#3f51b5', 0.9),
                                        },
                                       
                                    }}
                                />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                size="small"
                            />
                        )}
                    />

                </Col>
            </Row>


        </Box >
    );
};

export default TagCondition;
