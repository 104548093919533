import {
    Autocomplete,
    Box,
    Button,
    ButtonGroup,
    Checkbox,
    Container,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography
} from "@mui/material";
import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import ProbeParameters from "../../extra/probeParams";
import SearchIcon from '@mui/icons-material/Search';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faMapPin, faVial } from "@fortawesome/free-solid-svg-icons";







const Sidebar = ({

    selectedItems,
    setCurrentSidebar,
    currentSidebar,
    screenWidth,
    source


}) => {

    const [searchQuery, setSearchQuery] = useState('');
    const [filteredItems, setFilteredItems] = useState([]);
    const [items, setItems] = useState([]);





    return (
        <Row style={{}}>
            <Col span={24}>


                <Row >
                    <Col xs={24} sm={18} style={{
                        height: '40px',
                        borderBottom: '1px solid rgba(0,0,0,0.2)',
                        display: 'flex',
                        alignItems: 'flex-end', // Adjusted this line
                        justifyContent: 'flex-start', // Adjusted this line for better readability
                    }}>
                        <ButtonGroup size="small" aria-label="small button group" style={{
                            marginLeft: 10
                        }}>
                            <Button
                                key="items"
                                onClick={(e) => setCurrentSidebar('items')}
                                color='primary'
                                variant={null}
                                style={{
                                    height: '30px',
                                    textTransform: 'none',
                                    borderRadius: 0,
                                    fontWeight: 400,
                                    fontSize: 14,
                                    color: currentSidebar === 'items' ? '#6fa2f1' : null,
                                    borderBottom: currentSidebar === 'items' ? '3px solid #3f51b5' : null
                                }}
                            >
                                Items
                            </Button>
                            <Button
                                key='conditions'
                                onClick={(e) => setCurrentSidebar('conditions')}
                                color='primary'
                                variant={null}
                                style={{
                                    textTransform: 'none',
                                    borderRadius: 0,
                                    height: '30px',
                                    fontWeight: 400,
                                    fontSize: 14,
                                    color: currentSidebar === 'conditions' ? '#6fa2f1' : null,
                                    borderBottom: currentSidebar === 'conditions' ? '3px solid #3f51b5' : null
                                }}
                            >
                                Conditions
                            </Button>
                        </ButtonGroup>
                    </Col>

                    <Col xs={24} sm={6} style={{
                        height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'right',
                        borderBottom: screenWidth === 'xs' ? 'none' :
                            '1px solid rgba(0,0,0,0.2)', paddingLeft: screenWidth === 'xs' ? 10 : 0
                    }}>


                    </Col>

                </Row>

            </Col >



        </Row >
    );
};

export default Sidebar;