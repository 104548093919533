import { Button, Checkbox, Divider, OutlinedInput, Typography, Select, MenuItem, CircularProgress, Paper, FormControl, InputLabel } from '@mui/material';
import { Col, Row } from 'antd';
import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { fullMobile } from '../util';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, } from 'firebase/firestore';

import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import uuid from 'react-uuid';



import ProbeParameters from '../../../extra/probeParameters.json';
import WeatherParameters from '../../../extra/weatherParameters.json';
import queryString from 'query-string';
import axios from 'axios';
import DataBar from './dataBar';
import { queryCodes, queryParameters, queryProbes, queryRainGauges, querySites, queryStations } from '../../../firebase/config';
import QueryItem from './queryItem';
import QueryHeader from './queryHeader';
import DataTable from './dataTable';
import UploadDataNew from '../uploadDataNew';
import { codes } from '../../../codes/codes';


var randomColor = require('randomcolor');

export default function NewDataHome({ userInfo, screenWidth, openSnack, company, account }) {
    const props = { userInfo, screenWidth, openSnack, company, account, };
    const [loading, setLoading] = useState(true);
    const [query, setQuery] = useState({
        dataSource: 'node',
        startDate: new Date(moment().subtract(7, 'days')),
        endDate: new Date(),
        parameters: [],
        nodes: [],
        probes: [],
        rainGauges: [],
        rainParameters: [],
        showQuery: true,
        querying: false,
    });
    const [PPArray, setPPArray] = useState([]);
    const [WPArray, setWPArray] = useState([]);
    const [parameters, setParameters] = useState([]);
    const [nodes, setNodes] = useState([]);
    const [probes, setProbes] = useState([]);
    const [codesList, setCodes] = useState([]);
    const [addData, setAddData] = useState(false);
    const [rainGauges, setRainGauges] = useState([])



    useEffect(() => {
        window.scrollTo(0, 0);
        getInfo()
    }, [])

    async function getInfo() {

        const WPArray = Object.values(WeatherParameters).map((p, i) => {
            return ({
                label: p,
                key: Object.keys(WeatherParameters)[i]
            })
        });

        setWPArray(WPArray)
        const PPArray = Object.values(ProbeParameters).map((p, i) => {
            return ({
                label: p,
                key: Object.keys(ProbeParameters)[i]
            })
        });
        setPPArray(PPArray)
        const parameters = await queryParameters(userInfo)
        setParameters(parameters)
        const nodes = await queryStations(userInfo, ['grab', 'both', 'sensor', 'ostSensor'])

        setNodes(nodes)

        const probes = await queryProbes(userInfo)

        setProbes(probes)

        const tags = await queryCodes(userInfo)
        console.log(tags)
        console.log(codes)
        const codesList = codes.map((c, i) => ({ ...c, key: c.value }))
        const tagsList = tags.map((c, i) => ({ ...c, value: c.key, label: c.name }))
        const masterList = [...codesList, ...tagsList]
        setCodes(masterList)



        const rainGauges = await queryRainGauges(userInfo)
        setRainGauges(rainGauges)








    }


    function updateQuery(boo, val) {


        if (boo) {


            if (boo === 'dataSource') {
                setQuery({ ...query, [boo]: val, nodes: [], probes: [], parameters: [] })
            }
            else {
                setQuery({ ...query, [boo]: val })
            }

        }
    }

    function updateEntireQuery(val) {
        setQuery(val)
    }


    function updateChildren(type, status, key, list, listValues) {
        if (list === true) {
            const uniqueValues = [...new Set(listValues)]
            setQuery({ ...query, [type]: uniqueValues })
        }
        else {
            console.log(query[type])
            const newItem = status ? [...query[type], key] : query[type].filter(p => p !== key)
            setQuery({ ...query, [type]: newItem })
        }

    }

    function unselectAllChildren() {
        setQuery({ ...query, parameters: [], nodes: [], probes: [] })
    }





    return (
        <div style={{ fontFamily: 'Roboto, sans-serif', backgroundColor: '#f6f8fe', minHeight: '100vh' }}>
            <Row style={{ padding: 30 }} align="middle" >
                <Col {...fullMobile(20, 24)}>
                    <Typography variant='h3' fontWeight={500} fontSize={36}>Database</Typography>
                    <Typography style={{ paddingTop: 5 }} variant='h5' fontWeight={300} fontSize={18}>Your database as part of this account.</Typography>

                </Col>
                <Col {...fullMobile(4, 24)} style={{ textAlign: 'right' }}>
                    <Button variant="contained" size="large" display="inline" style={{ textTransform: 'none', backgroundColor: '#404050', fontSize: 14 }} onClick={() => {
                        setAddData(!addData)
                    }}>{addData === true ? "Query Data" : "+ Upload Data"}</Button>

                </Col>
                <Col {...fullMobile(24, 24)}>
                    <Divider style={{ paddingTop: 10 }} />
                </Col>

                {addData === false && (<Col {...fullMobile(24, 24)}>
                    <Paper style={{ padding: 0, borderRadius: 10, backgroundColor: '#fff' }} elevation={8}>
                        <Row>
                            <Col {...fullMobile(6, 6)}>
                                <Row>
                                    <Col span={23} style={{ padding: 20 }}>
                                        <Typography variant='body1' fontWeight={600} color={'#8D8D8D'} fontSize={13}>DATA SOURCE</Typography>
                                        <FormControl fullWidth style={{ paddingTop: 5 }}>

                                            <Select
                                                style={{ borderRadius: 16, height: 70, backgroundColor: '#f6f8fe', fontSize: 24, fontWeight: 600 }}
                                                notched={false}
                                                size="large"
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={query.dataSource}
                                                placeholder="Select Data Source"
                                                label="Age"
                                                onChange={(e) => {
                                                    updateQuery('dataSource', e.target.value);





                                                }}
                                            >
                                                <MenuItem value={'node'}>Node Data</MenuItem>
                                                <MenuItem value={'probe'}>Probe Data</MenuItem>

                                            </Select>
                                        </FormControl>
                                    </Col>
                                    <Col span={1}>
                                        <Divider orientation='vertical' />
                                    </Col>
                                    <Col span={24}>
                                        <Divider />
                                    </Col>
                                    <Col span={23}>
                                        {PPArray.length > 0 && parameters.length > 0 && nodes.length > 0 &&
                                            (<DataBar {...props} queryRef={query} nodes={nodes} PPArray={PPArray} WPArray={WPArray} parameters={parameters} updateQuery={updateQuery}
                                                updateChildren={updateChildren} probes={probes} unselectAllChildren={unselectAllChildren}
                                                rainGauges={rainGauges}
                                            />)}
                                    </Col>
                                    <Col span={1}>
                                        <Divider orientation='vertical' />
                                    </Col>
                                    <Col span={24}>
                                        <Divider />
                                    </Col>

                                </Row>







                            </Col>
                            <Col {...fullMobile(18, 18)} >
                                <Row>
                                    <Col {...fullMobile(24, 24)} style={{ paddingTop: 20, paddingLeft: 20, paddingRight: 20 }}>
                                        {PPArray.length > 0 && parameters.length > 0 && nodes.length > 0 && (
                                            <QueryHeader {...props} queryRef={query} updateQuery={updateQuery} updateEntireQuery={updateEntireQuery} probes={probes} />)}
                                    </Col>
                                    <Col {...fullMobile(24, 24)} style={{ padding: 20 }}>
                                        {PPArray.length > 0 && parameters.length > 0 && nodes.length > 0 && (
                                            <QueryItem {...props} queryRef={query} updateQuery={updateQuery} nodes={nodes} PPArray={PPArray} parameters={parameters} probes={probes} rainGauges={rainGauges}
                                                WPArray={WPArray}
                                            />
                                        )}
                                    </Col>
                                    <Col {...fullMobile(24, 24)} style={{ padding: 0 }}>
                                        {PPArray.length > 0 && parameters.length > 0 && nodes.length > 0 && (
                                            <DataTable {...props} query={query} WPArray={WPArray} rainGauges={rainGauges} updateEntireQuery={updateEntireQuery}
                                                updateQuery={updateQuery} codes={codesList} nodes={nodes} PPArray={PPArray} parameters={parameters} probes={probes} />
                                        )}
                                    </Col>
                                </Row>
                            </Col>


                        </Row>

                    </Paper>
                </Col>)}


                {addData === true && (
                    <Col {...fullMobile(24, 24)}>
                        <UploadDataNew {...props} />
                    </Col>
                )}





            </Row>
        </div>
    );
}