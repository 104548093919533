
import './App.css';
import LakeTech from './components/navigation/router';
import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/700.css';
import "@fontsource/inter"
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { BrowserRouter } from "react-router-dom";
import { initializeApp } from "firebase/app";
import React from 'react';
import 'react-image-lightbox/style.css';
import { useJsApiLoader } from '@react-google-maps/api';
import { GoogleOAuthProvider } from '@react-oauth/google';


const lib = ['places', 'visualization', 'drawing'];

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const googleOAuthClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;



const firebaseConfig = {
  apiKey: "AIzaSyDNY6LFN2dA49sKWmPiJqedq1VGZZ_D1Mw",
  authDomain: "aquasource3.firebaseapp.com",
  databaseURL: "https://aquasource3.firebaseio.com",
  projectId: "aquasource3",
  storageBucket: "aquasource3.appspot.com",
  messagingSenderId: "315598949216",
  appId: "1:315598949216:web:5eff1f92195916e7376c05",
  measurementId: "G-FQFJED0WTW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

function App() {

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    libraries: lib,
    googleMapsApiKey,

  })



  return isLoaded ? (
    <GoogleOAuthProvider clientId={googleOAuthClientId}>
      <BrowserRouter>
        <LakeTech />
      </BrowserRouter>
    </GoogleOAuthProvider>

  ) :
    <div>Loading...</div>

}

export default App;
