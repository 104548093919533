import { loadModules } from 'esri-loader';
import React from 'react';
import uuid from 'react-uuid';

export default class SketchLayer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            map: this.props.map,
            view: this.props.view,
            layerColor: this.props.layerColor,
            logKey: this.props.logKey,
            graphics: this.props.graphics,

        };
    }

    componentDidUpdate(prevProps) {

        if (this.props.layerColor !== prevProps.layerColor) {
            this.changeColor(this.props.layerColor);
            this.changeGraphicsColor(this.props.layerColor);
            this.setState({ layerColor: this.props.layerColor })
            console.log(this.props.layerColor)
        }



    }
    componentDidMount() {
        const { account, map, view, logKey } = this.state;
        const item = view.ui.find('sketchItem');
        if (item === null) {
            this.addItem()
        }
    }

    addItem = () => {
        const { view, map, layerColor, logKey, graphics } = this.state;
        loadModules(["esri/widgets/Sketch",
            "esri/layers/GraphicsLayer",
            "esri/widgets/Sketch/SketchViewModel",
            "esri/geometry/geometryEngine",
            "esri/Graphic", "esri/geometry/SpatialReference"])
            .then(([Sketch, GraphicsLayer, SketchViewModel, geometryEngine, Graphic, SpatialReference]) => {

                const graphicsLayer = new GraphicsLayer({ title: 'sketchLayer', id: 'sketchLayer' });
                map.add(graphicsLayer)

                const polygonSymbol = {
                    type: "simple-fill", // autocasts as new SimpleFillSymbol()
                    color: layerColor,
                    outline: {
                        // autocasts as new SimpleLineSymbol()
                        color: "white",
                        width: 2
                    }
                };

                let polylineSymbol = {
                    type: "simple-line",  // autocasts as new SimpleLineSymbol()
                    color: layerColor,
                    width: "4px",
                };

                let pointSymbol = {
                    type: "simple-marker",  // autocasts as new SimpleMarkerSymbol()
                    style: "square",
                    color: layerColor,
                    size: "8px",  // pixels
                    outline: {  // autocasts as new SimpleLineSymbol()
                        color: [255, 255, 0],
                        width: 3  // points
                    }
                };


                graphics.map((g, i) => {

                    const s = g.attributes.shapeType;
                    g.symbol = s === 'polygon' ? polygonSymbol : s === 'point' ? pointSymbol : polylineSymbol;
                    g.geometry.type = s;
                    let graphic = new Graphic({
                        geometry: g.geometry,
                        symbol: g.symbol,
                        attributes: g.attributes,
                        id: i

                    });
                    graphicsLayer.add(graphic);

                })



                let sketchVM = new SketchViewModel({
                    layer: graphicsLayer,
                    pointSymbol,
                    polylineSymbol,
                    polygonSymbol,
                    view: view
                });

                view.when(() => {
                    this.props.handleCheck()
                    const sketch = new Sketch({
                        layer: graphicsLayer,
                        view: view,
                        id: 'sketchItem',
                        creationMode: "single",
                        viewModel: sketchVM,
                        visibleElements: {
                            settingsMenu: false
                        }
                    });


                    sketch.on("create", (event => {
                        if (event.state === "complete") {

                            const attributes = {
                                type: "fieldLogMap",
                                key: uuid(),
                                logKey,
                                visible: true,
                                area: geometryEngine.geodesicArea(event.graphic.geometry, 'acres'),
                                length: geometryEngine.geodesicLength(event.graphic.geometry, 'feet'),
                                areaUnits: "acres",
                                lengthUnits: "ft",
                                geometryType: SpatialReference.WGS84,
                                shapeType: event.tool === 'circle' ? 'polygon' : event.tool === 'point' ? 'point' : event.tool === 'polyline' ? 'polyline' : 'polygon',
                                lat: event.tool === 'point' ? event.graphic.geometry.latitude :
                                    event.tool === 'polyline' ? event.graphic.geometry.extent.center.latitude : event.graphic.geometry.centroid.latitude,
                                lon: event.tool === 'point' ? event.graphic.geometry.longitude :
                                    event.tool === 'polyline' ? event.graphic.geometry.extent.center.longitude : event.graphic.geometry.centroid.longitude
                            }
                            event.graphic.attributes = attributes;
                            const item = {
                                geometry: event.graphic.geometry,
                                symbol: event.graphic.symbol,
                                attributes

                            }
                            graphics.push(item);
                            this.stringGraphics(graphics)
                            this.setState({ graphics });

                        }
                    }))
                    sketch.on("update", (event => {
                        if (event.state === "complete") {

                            event.graphics.map((g) => {


                                const item = {
                                    geometry: g.geometry,
                                    symbol: g.symbol,
                                    attributes: {
                                        ...g.attributes,
                                        area: geometryEngine.geodesicArea(g.geometry, 'acres'),
                                        length: geometryEngine.geodesicLength(g.geometry, 'feet'),
                                        lat: g.attributes.shapeType === 'point' ? g.geometry.latitude :
                                            g.attributes.shapeType === 'polyline' ? g.geometry.extent.center.latitude : g.geometry.centroid.latitude,
                                        lon: g.attributes.shapeType === 'point' ? g.geometry.longitude :
                                            g.attributes.shapeType === 'polyline' ? g.geometry.extent.center.longitude : g.geometry.centroid.longitude

                                    }
                                }
                                g.attributes = item.attributes;

                                const index = graphics.map((l) => l.attributes.key).indexOf(item.attributes.key);
                                graphics[index] = g;
                                this.stringGraphics(graphics)
                                this.setState({ graphics })

                            })

                        }
                    }))
                    sketch.on("delete", (event => {
                        event.graphics.map((g) => {
                            console.log(g)
                            graphicsLayer.remove(g)
                            console.log(g.attributes.key);
                            const index = graphics.map((l) => l.attributes.key).indexOf(g.attributes.key);
                            console.log(index);


                            if (index > -1) { // only splice array when item is found
                                graphics.splice(index, 1); // 2nd parameter means remove one item only
                            }
                            console.log(graphics)
                            this.stringGraphics(graphics)


                        })

                    }))

                    view.ui.add(sketch, "top-right");
                });


            })


    }

    newGraphics = (graphics, layerColor) => {
        const { view, map, logKey, } = this.state;
        loadModules(["esri/widgets/Sketch",
            "esri/layers/GraphicsLayer",
            "esri/widgets/Sketch/SketchViewModel",
            "esri/geometry/geometryEngine",
            "esri/Graphic"])
            .then(([Sketch, GraphicsLayer, SketchViewModel, geometryEngine, Graphic]) => {

                const graphicsLayer = map.allLayers.find((layer) => {
                    return layer.title === "sketchLayer";
                });

                graphicsLayer.removeAll()



                graphicsLayer.graphics.map((g) => {
                    graphicsLayer.remove(g)
                })

                console.log(graphicsLayer);

                const polygonSymbol = {
                    type: "simple-fill", // autocasts as new SimpleFillSymbol()
                    color: layerColor,
                    outline: {
                        // autocasts as new SimpleLineSymbol()
                        color: "white",
                        width: 2
                    }
                };

                let polylineSymbol = {
                    type: "simple-line",  // autocasts as new SimpleLineSymbol()
                    color: layerColor,
                    width: "4px",
                };

                let pointSymbol = {
                    type: "simple-marker",  // autocasts as new SimpleMarkerSymbol()
                    style: "square",
                    color: layerColor,
                    size: "8px",  // pixels
                    outline: {  // autocasts as new SimpleLineSymbol()
                        color: [255, 255, 0],
                        width: 3  // points
                    }
                };


                graphics.map((g, i) => {

                    const s = g.attributes.shapeType;
                    g.symbol = s === 'polygon' ? polygonSymbol : s === 'point' ? pointSymbol : polylineSymbol;
                    g.geometry.type = s;
                    console.log(g)
                    let graphic = new Graphic({
                        geometry: g.geometry,
                        symbol: g.symbol,
                        attributes: g.attributes,
                        id: i

                    });
                    console.log(graphic)
                    graphicsLayer.add(graphic);

                })


            })


    }

    stringGraphics = (graphics) => {
        const { item } = this.state;
        const string = JSON.stringify(graphics);
        this.props.updateGraphics(string)
    }

    changeColor = (color) => {

        const { view, map, } = this.state;
        loadModules(["esri/widgets/Sketch",
            "esri/layers/GraphicsLayer",
            "esri/widgets/Sketch/SketchViewModel",
            "esri/Color"])
            .then(([Sketch, GraphicsLayer, SketchViewModel, Color]) => {

                const graphicsLayer = map.allLayers.find((layer) => {
                    return layer.title === "sketchLayer";
                });


                const polygonSymbol = {
                    type: "simple-fill", // autocasts as new SimpleFillSymbol()
                    color: color,
                    outline: {
                        // autocasts as new SimpleLineSymbol()
                        color: "white",
                        width: 2
                    }
                };

                let polylineSymbol = {
                    type: "simple-line",  // autocasts as new SimpleLineSymbol()
                    color: color,
                    width: "4px",
                };

                let pointSymbol = {
                    type: "simple-marker",  // autocasts as new SimpleMarkerSymbol()
                    style: "square",
                    color: color,
                    size: "8px",  // pixels
                    outline: {  // autocasts as new SimpleLineSymbol()
                        color: [255, 255, 0],
                        width: 2  // points
                    }
                };

                console.log(color)

                let sketchVM = new SketchViewModel({
                    layer: graphicsLayer,
                    pointSymbol,
                    polylineSymbol,
                    polygonSymbol,
                    view: view,

                });
                console.log(sketchVM)
                const sketch = view.ui.find('sketchItem');
                console.log(sketch)
                sketch.viewModel = sketchVM;
                sketch.activeTool = 'move'





            })

    }

    changeGraphicsColor = (color) => {
        console.log(color)
        const { view, map, } = this.state;
        loadModules(["esri/widgets/Sketch",
            "esri/layers/GraphicsLayer",
            "esri/widgets/Sketch/SketchViewModel",
            "esri/Color",
            "esri/Graphic"])
            .then(([Sketch, GraphicsLayer, SketchViewModel, Color, Graphic]) => {

                const graphicsLayer = map.allLayers.find((layer) => {
                    return layer.title === "sketchLayer";
                });



                const graphics = graphicsLayer.graphics;
                graphics.map((g, i) => {
                    if (g.geometry.rings !== undefined) {
                        g.symbol = {
                            type: "simple-fill",
                            color: color,
                            outline: {
                                color: "white",
                                width: 2
                            }
                        };
                    }
                    if (g.geometry.paths !== undefined) {
                        g.symbol = {
                            type: "simple-line",  // autocasts as new SimpleLineSymbol()
                            color: color,
                            width: "4px",
                        };
                    }
                    if (g.geometry.x !== undefined) {
                        g.symbol = {
                            type: "simple-marker",  // autocasts as new SimpleMarkerSymbol()
                            style: "square",
                            color: color,
                            size: "8px",  // pixels
                            outline: {  // autocasts as new SimpleLineSymbol()
                                color: [255, 255, 0],
                                width: 2  // points
                            }
                        };
                    }

                    g.color = color;

                })











            })
    }



    render() {

        const { } = this.state;

        return null;
    }
} 