import { Divider, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import React from 'react';
import CurrentWeatherSideBar from '../../../blocks/currentWeatherSideBar';

export default class SideBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            company: this.props.company,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
        if (this.props.company !== prevProps.company) {
            this.setState({ company: this.props.company })
        }

    }
    componentDidMount() {
        window.scrollTo(0, 0);
        const { account } = this.state;

    }



    render() {

        const { account, company } = this.state;

        const companyType = company === undefined ? undefined : company.companyTypeKey !== undefined ? company.companyTypeKey : undefined;


        return (
            <Row style={{ paddingLeft: 20, paddingTop: 20 }}>
                <Col span={24}>
                    <Typography style={{ fontWeight: 400 }} variant='h6' >{account.accountName}</Typography>
                </Col>
                <Col span={24}>
                    <Typography style={{ fontWeight: 500 }} variant='subtitle1'>{companyType === 'district' ? 'Site Address' :
                        companyType === 'municipality' ? 'Site Address' : 'Account Address'}</Typography>
                </Col>
                <Col span={24} style={{ paddingTop: 15 }}>
                    <Typography variant="body2">{account.address}</Typography>
                </Col>
                <Col span={24} style={{ paddingTop: 15 }}>
                    <Divider />
                </Col>
                <Col span={24} style={{ paddingTop: 15 }}>
                    <Typography style={{ fontWeight: 500 }} variant='subtitle1'>{companyType === 'district' ? 'Site Weather' :
                        companyType === 'municipality' ? 'Site Weather' : 'Account Weather'}</Typography>
                </Col>
                <Col span={24} style={{ paddingTop: 15 }}>
                 
                </Col>
            </Row>
        );
    }
} 