import { collection, doc, getDoc, getDocs, getFirestore, onSnapshot, orderBy, query, setDoc, updateDoc, where } from "firebase/firestore";





export const getStaff = async (userInfo) => {
    try {
        const db = getFirestore();
        const queryRef = collection(db, "users");
        const snapShot = await getDocs(query(queryRef, where("companies", "array-contains", userInfo.currentCompany)));

        const staff = snapShot.docs.map(doc => {
            return (
                {
                    id: doc.data().id,
                    key: doc.data().id,
                    invited: true,
                    signedUp: true,
                    email: doc.data().email,
                    label: `${doc.data().firstName || ""} ${doc.data().lastName || ""}`,
                    flDistribution: false,
                    adminLevel: 'admin'

                }
            )

        });
        return staff;
    } catch (error) {
        console.error("Error querying data: ", error);
    }
};

export const getAccounts = async (userInfo) => {
    try {
        const db = getFirestore();
        const queryRef = collection(db, "clients", userInfo.currentCompany, "accounts");
        const snapShot = await getDocs(query(queryRef, where("archived", "==", false)));

        const sites = snapShot.docs.map(doc => {
            return (
                {
                    accountName: doc.data().accountName,
                    label: doc.data().accountName,
                    key: doc.data().key,
                    type: doc.data().seasonal || null,
                    city: doc.data().city || null,
                    countyName: doc.data().countyName || null,
                    state: doc.data().state || null,
                    routeDay: doc.data().routeDay || null,
                    routeName: doc.data().routeName || null,
                }
            )
        });
        return sites;
    } catch (error) {
        console.error("Error querying data: ", error);
    }
};


export const getTasks = async (userInfo) => {
    try {
        console.log(userInfo)

        const db = getFirestore();
        const queryRef = collection(db, "clients", userInfo.currentCompany, "taskListNew");
        const snapShot = await getDocs(query(queryRef, where("company", "==", userInfo.currentCompany), where('archived', '==', false), orderBy('position')));

        const tasks = snapShot.docs.map(doc => {
            console.log(doc.data())
            return (
                {
                    ...doc.data(),
                    selected: false
                }
            )
        });

        return tasks;
    } catch (error) {
        console.error("Error querying data: ", error);
    }
};

export const getTaskPreferences = async (userInfo) => {
    try {
        const db = getFirestore();
        const docRef = doc(db, "users", userInfo.id, "taskPreferences", userInfo.currentCompany);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            return docSnap.data();
        } else {
            // docSnap.data() will be undefined in this case
            return 'none'
        }
    } catch (error) {
        console.error("Error querying data: ", error);
    }
};
export const getCompanyPreferences = async (userInfo) => {
    try {
        const db = getFirestore();
        const docRef = doc(db, "clients", userInfo.currentCompany, "taskPreferences", "taskPreferences");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            return docSnap.data();
        } else {
            // docSnap.data() will be undefined in this case
            return 'none'
        }
    } catch (error) {
        console.error("Error querying data: ", error);
    }
};

export async function updateTaskPreferences(userInfo, taskPreferences, type) {
    try {
        const db = getFirestore();
        let docRef;

        if (type === "company") {
            docRef = doc(db, "clients", userInfo.currentCompany, "taskPreferences", "taskPreferences");
        } else {
            docRef = doc(db, "users", userInfo.id, "taskPreferences", userInfo.currentCompany);
        }

        await setDoc(docRef, taskPreferences);
        console.log("Document successfully written!");
    } catch (error) {
        console.error("Error updating document: ", error);
    }
};


export const getTaskTemplates = async (userInfo) => {
    try {
        const db = getFirestore();
        const queryRef = collection(db, "clients", userInfo.currentCompany, "taskTemplates");
        const snapShot = await getDocs(query(queryRef, where("company", "==", userInfo.currentCompany), where('archived', '==', false)));

        const tasks = snapShot.docs.map(doc => {
            return (
                {
                    ...doc.data(),
                    selected: false
                }
            )
        });

        return tasks;
    } catch (error) {
        console.error("Error querying data: ", error);
    }
};




