import React from 'react';
import { Col, Row } from 'antd';
import { Breadcrumbs, Button, CircularProgress, OutlinedInput, Paper, Typography } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import { getFirestore, getDoc, doc, setDoc, deleteDoc } from 'firebase/firestore';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import EBLogo from '../../extra/logos/ebLogo.jpg';
import AccountList from '../../account/accountList';
import DashboardContent from '../../account/account/dashboard/dashboard';
import Dashboard from '../../account/account/dashboard/dashboardhome';
import axios, * as others from 'axios';

export default class EBParksPublic extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            invitation: {},
            creating: false,
            custom: this.props.custom,
            password: '',
            company: '',
            credentials: '',
            step: 'login',
            userInfo: { currentCompany: '', currentAccount: '' },
            account: {},
            company: {}
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.custom !== prevProps.custom) {
            this.setState({ custom: this.props.custom })
        }

    }
    async componentDidMount() {
        const { userInfo } = this.state;
        window.scrollTo(0, 0)
        const db = getFirestore();
        console.log(window.location.href)
        const url = window.location.href;
        const slug = url.split('3000/').pop();
        console.log(slug)
        console.log(url)



        if (url.includes('ebparks')) {
            this.setState({ company: slug })
            const docRef = doc(db, "clients", '38cfab-0eb0-0c3e-f466-71ad3c4865');
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                userInfo.currentCompany = '38cfab-0eb0-0c3e-f466-71ad3c4865';
                this.setState({ password: docSnap.data().publicPW, userInfo, company: docSnap.data() })
            }
        }




    }


    changeAccount = async (key) => {
        const { userInfo } = this.state;
        userInfo.currentAccount = key;


        const db = getFirestore();

        const docRef = doc(db, "clients", userInfo.currentCompany, 'accounts', key);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {


            this.setState({ userInfo, step: 'dashboard', account: docSnap.data() })
            console.log("CHANGE ACOUNT")
            console.log(userInfo)


        } else {
            this.setState({ account: {} })
        }




    }
    goBack = () => {
        const { userInfo } = this.state;
        userInfo.currentAccount = '';
        this.setState({ userInfo, account: {}, step: 'accounts' })
    }


    render() {

        const { invitation, screenWidth, creating, custom, company, credentials, step, password, userInfo, account } = this.state;

        console.log(company)

        if (step === 'login') {
            return (
                <Row style={{ paddingTop: 30 }}>
                    {company.companyName === 'East Bay Parks' && (<Col span={24} style={{ textAlign: 'center' }}>
                        <img src={EBLogo} style={{ height: 100 }} />
                    </Col>)}
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <Typography variant="h4" fontWeight={300}>Welcome to {custom.label} Public Dashboard!</Typography>
                    </Col>
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <Row justify="space-around" >
                            <Col xs={20} sm={8}>

                                <Row gutter={[12, 12]} justify="space-around">
                                    <Col span={24}>
                                        <Typography variant="h6" fontWeight={300} >Credentials</Typography>
                                    </Col>
                                    <Col span={24}>
                                        <OutlinedInput fullWidth notched={false} label="none" placeholder={"Credentials"}
                                            value={credentials || ''} style={{ textAlign: 'center' }}
                                            inputProps={{ style: { textAlign: 'center' } }}
                                            onChange={(e) => {
                                                this.setState({ credentials: e.target.value })
                                            }} />
                                    </Col>
                                    <Col span={8}>
                                        <Button fullWidth color="primary" variant="contained" style={{ padding: 15, fontSize: 16, textTransform: 'none' }} size="large"
                                            onClick={() => {
                                                if (credentials === password) {
                                                    this.setState({ step: 'accounts' })
                                                }
                                                else {
                                                    this.props.openSnack('error', 'Wrong Credentials!')
                                                }
                                            }}>Log in</Button>
                                    </Col>
                                </Row>

                            </Col>
                        </Row>

                    </Col>






                </Row>

            );
        }
        if (step === 'accounts') {
            return (
                <Row style={{ paddingTop: 30, paddingLeft: 30 }}>
                 
                    <Col span={24}>
                        <AccountList userInfo={userInfo} type="public" changeAccount={this.changeAccount} />
                    </Col>
                </Row>


            )
        }
        if (step === 'dashboard') {

            if (Object.values(account).length > 0) {
                return (
                    <Row style={{ paddingTop: 30, paddingLeft: 30 }}>
                        <Col span={24}>
                            <Dashboard userInfo={userInfo} type="open" account={account} company={company} goBack={this.goBack} />
                        </Col>
                    </Row>
                )
            }
            else {
                return null;
            }

        }


    }
} 