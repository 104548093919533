

import { Button, Checkbox, Divider, OutlinedInput, Typography, Select, MenuItem, CircularProgress, Paper, IconButton } from '@mui/material';
import { Col, Row } from 'antd';
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { fullMobile } from '../products/use/util';
import axios, * as others from 'axios';
import moment from 'moment-timezone';
import uuid from 'react-uuid';
import { doc, getFirestore, setDoc, query, collection, where, getDoc, getDocs, updateDoc, collectionGroup, deleteDoc } from 'firebase/firestore';

import './apiTestStyles.css';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import queryString from 'query-string';
import { MuiAutocompleteEditor, NumberInputRenderer } from './cellRenderers';





export default function APITestNOAA({ userInfo }) {

    useEffect(() => {

        getInfo()

    }, [])

    async function getInfo() {
        const data = await getNoaaData();
        console.log(data)
    }





    const getNoaaData = async () => {
        try {
            const proxyUrl = "https://mycorslake.herokuapp.com/";
            const db = getFirestore();
            const getStations = collectionGroup(db, 'tidalStations');
            const stations = await getDocs(getStations);
            const stationData = stations.docs.map(doc => doc.data());

            //create array of unique ids
            const ids = stationData.map((item) => item.id);
            const uniqueIds = [...new Set(ids)];
            console.log(uniqueIds)
            const first = [uniqueIds[8]];

            //for each id, fetch data from NOAA API for the past 7 days
            const data = [];

            for (let i = 0; i < uniqueIds.length; i++) {
                try {
                    const id = uniqueIds[i];
                    const startDate = moment().subtract(6, 'months').format('YYYYMMDD');
                    const endDate = moment().subtract(5, 'months').format('YYYYMMDD')
                    const response = await readNoaaData(id, startDate, endDate)
                    console.log(response)

                    response.data.data.map((item) => {
                        var time = moment(item.t, 'YYYY-MM-DD HH:mm').tz("America/Los_Angeles").format("YYYY-MM-DD HH:mm");
                        data.push({
                            time: `'${time}'`,
                            id: `'${id}'`,
                            key: `'${uuid()}'`,
                            value: Number(item.v),
                        });
                    });
                }
                catch (error) {
                    console.log(error)
                }
            }


            const hourlyVals = data.map((h) => `(${Object.values(h)})`);
            const hourlyString = hourlyVals.join();

              try {
                  await axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                      raw: `INSERT INTO station_data_new VALUES ${hourlyString} ON CONFLICT DO NOTHING; `,
                  });
                  console.log('SUCCESS');
              } catch (error) {
                  console.log(error);
              }

            return data;
        } catch (error) {
            console.error('Error fetching data from NOAA API:', error);
            return null;
        }
    }

    const readNoaaData = async (id, startDate, endDate) => {
        console.log(id)
        const proxyUrl = "https://mycorslake.herokuapp.com/";
        const url = `https://api.tidesandcurrents.noaa.gov/api/prod/datagetter?product=water_level&application=NOS.COOPS.TAC.WL&begin_date=${startDate}&end_date=${endDate}&datum=MLLW&station=${id}&time_zone=LST&units=english&format=json`;

        const response = await axios.get(proxyUrl + url);
        return response;
    }







    return (
        <Row style={{ fontFamily: 'Roboto, sans-serif' }} align="middle">
            <Col {...fullMobile(24, 24)} style={{ paddingTop: 30 }}>
                <Button variant="contained" color="primary">
                    Test
                </Button>


            </Col>

        </Row>
    );
}