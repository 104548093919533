import React, { useState, useEffect } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';


const NodeSelect = (props) => {
    const { dataType, locations, updateData, index, location } = props;



    return (
        <Autocomplete
            disableClearable
            size="small"
            id="combo-box-demo"
            renderGroup={(params) => (
                <Box key={params.key}>
                    <Typography fontWeight={800} fontSize={14} p={1}>
                        {params.group}
                    </Typography>
                    {params.children}
                </Box>
            )}
            groupBy={(option) =>
                option.stationLabel ? option.stationLabel : "No Station Label"
            }
            options={dataType === undefined ? [] : locations.filter((f) => f.type === dataType).sort((a, b) => {
                if (a.stationLabel < b.stationLabel) return -1;
                if (a.stationLabel > b.stationLabel) return 1;
                return 0;
            })}
            value={location || ""}
            renderInput={(params) => (
                <TextField {...params} style={{
                    backgroundColor: '#ffffff',
                }} placeholder="Source" variant="outlined" />
            )}
            onChange={(a, b) => {
                console.log("SELECTING DATA TYPE")
                console.log(b)
                updateData(index, "locationid", b.id || b.key);
                updateData(index, "location", b);
                updateData(index, 'depth', b.depth || 0);
                updateData(index, 'dataType', b.type);
            }}
            InputProps={{ style: { fontSize: 6 } }}
        />
    )
}

export default NodeSelect;