import React from "react";
import { Input } from "@mui/material";

const PercentInput = ({
    currentTask,
    setCurrentTask,
    headerKey,
    options
}) => {
    const [percent, setPercent] = React.useState(currentTask?.[headerKey]);  // Renamed from 'age' to 'percent' for clarity.

    const handleChange = (event) => {
        let val = event.target.value;

        // Make sure the entered value is between 0 and 100.
        if (val >= 0 && val <= 100) {
            console.log({ ...currentTask, [headerKey]: val })
            setCurrentTask({ ...currentTask, [headerKey]: val })
            setPercent(val);
        }
    };

    return (
        <Input
            fullWidth
            variant="standard"
            id="percent-input"
            type="number"
            size="small"
            margin="dense"
            placeholder="Enter percentage"
            value={percent}
            onChange={handleChange}
            inputProps={{
                step: 1, // you can adjust this if you want decimal precision.
                min: 0,
                max: 100
            }}
            endAdornment="%"
            sx={{
                '& .MuiInputBase-input': {
                    color: 'black',
                    fontWeight: 500,
                    fontSize: 14,
                },
            }}
        />
    );
};

export default PercentInput;
