import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import { visuallyHidden } from '@mui/utils';
import { Tag } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBellSlash, faCommentSms, faEnvelope, faPen, faSpinner, faTrashCan, faBell as SolidBell } from '@fortawesome/free-solid-svg-icons';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import { deleteAlerts } from './firestore';
import moment from 'moment-timezone';




function descendingComparator(a, b, orderBy) {
    const splitOrderBy = orderBy.split('.');
    let aValue = a || 'zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz';
    let bValue = b || 'zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz';

    splitOrderBy.forEach(property => {
        aValue = aValue ? aValue[property] : 'zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz';
        bValue = bValue ? bValue[property] : 'zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz';
    });

    if (typeof aValue === 'number' && typeof bValue === 'number') {
        return bValue - aValue;
    } else {
        if (bValue === undefined || bValue < aValue) {
            return -1;
        }
        if (aValue === undefined || bValue > aValue) {
            return 1;
        }
    }
    return 0;
}


function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(b, a, orderBy)
        : (a, b) => -descendingComparator(b, a, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: 'Name',

    },
    {
        id: 'active',
        numeric: false,
        disablePadding: true,
        label: 'Active',

    },
    {
        id: 'snooze',
        numeric: false,
        disablePadding: true,
        label: 'Snooze',

    },
    {
        id: 'node.stationLabel',
        numeric: false,
        disablePadding: true,
        label: 'Station',
    },
    {
        id: 'node.name',
        numeric: false,
        disablePadding: true,
        label: 'Node',
    },
    {
        id: 'parameterLabel',
        numeric: false,
        disablePadding: true,
        label: 'Parameter',

    },
    {
        id: 'type',
        numeric: false,
        disablePadding: true,
        label: 'Type',

    },
    {
        id: 'value',
        numeric: false,
        disablePadding: true,
        label: 'Value',

    },
    {
        id: 'alertType',
        numeric: false,
        disablePadding: true,
        label: 'Frequency',

    },


];

function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, checkboxSelect } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {checkboxSelect === true && (
                    <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{
                                'aria-label': 'select all desserts',
                            }}
                        />
                    </TableCell>
                )}
                {headCells.map((headCell, i) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={{
                            fontWeight: 'bold',
                            fontSize: '16px',
                            width: headCell.width,
                            paddingLeft: checkboxSelect === false ? 2 : null,
                        }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                <TableCell sx={{
                    fontWeight: 'bold',
                    fontSize: '16px',
                    width: 125
                }} padding="checkbox">
                    Actions
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    selected: PropTypes.array.isRequired,
};

function EnhancedTableToolbar(props) {
    const { numSelected, deleteSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 && (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            )}

            {numSelected > 0 && (
                <Tooltip title="Delete">
                    <IconButton onClick={() => {
                        console.log("delete")
                        deleteSelected()
                    }}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>
    );
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    deleteSelected: PropTypes.func.isRequired,
};
const AlarmsTable = ({ searchInput, setSearchInput, screenWidth, checkboxSelect, userInfo, rows, loading, setRows, parameters, setCurrentItem, setOpenModal, openSnack }) => {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [hoveredCell, setHoveredCell] = useState(null);




    let searchInputRegex = new RegExp(searchInput.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i');

    // This is the data to filter from
    const originalData = useMemo(() => rows, []);

    function deepSearch(obj, regex) {
        if (typeof obj === 'string') {
            return regex.test(obj);
        } else if (typeof obj === 'number' || typeof obj === 'boolean') {
            return regex.test(obj.toString());
        } else if (Array.isArray(obj)) {
            return obj.some(val => deepSearch(val, regex));
        } else if (typeof obj === 'object' && obj !== null) {
            return Object.values(obj).some(val => deepSearch(val, regex));
        }
        return false;
    }

    useEffect(() => {
        console.log('searchInput', searchInput)
        console.log(originalData)
        if (searchInput === '') {
            setRows(originalData);
        } else {
            let searchInputRegex = new RegExp(searchInput.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i');
            setRows(originalData.filter((row) => deepSearch(row, searchInputRegex)));
        }
    }, [searchInput, originalData]);


    const handleCellHover = (event, id) => {
        setHoveredCell(id);
    };

    const handleCellLeave = () => {
        setHoveredCell(null);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        if (!event.shiftKey) {
            setSelected(prevSelected => {
                const selectedIndex = prevSelected.indexOf(id);
                let newSelected = [];
                if (selectedIndex === -1) {
                    newSelected = newSelected.concat(prevSelected, id);
                } else if (selectedIndex === 0) {
                    newSelected = newSelected.concat(prevSelected.slice(1));
                } else if (selectedIndex === prevSelected.length - 1) {
                    newSelected = newSelected.concat(prevSelected.slice(0, -1));
                } else if (selectedIndex > 0) {
                    newSelected = newSelected.concat(
                        prevSelected.slice(0, selectedIndex),
                        prevSelected.slice(selectedIndex + 1),
                    );
                }
                return newSelected;
            });
        }
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy))
                .filter((row) => deepSearch(row, searchInputRegex))
                .slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage,
                ),
        [order, orderBy, page, rowsPerPage, rows, searchInput], // <-- add rows and searchInput here
    );

    const cellPadding = 2;

    const EditCell = ({ handleCellHover, handleCellLeave, row }) => {

        return (
            <TableCell
                sx={{
                    padding: 0
                }}
                onMouseEnter={(e) => handleCellHover(e, row.id)}
                onMouseLeave={handleCellLeave}>
                <Tooltip title="Edit Alarm">
                    <IconButton onClick={() => {
                        setCurrentItem(row);
                        setOpenModal(true);

                    }}>
                        <FontAwesomeIcon size="xs" icon={faPen} color="black" />
                    </IconButton>
                </Tooltip>
            </TableCell>
        )
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 5 }}>
                <EnhancedTableToolbar numSelected={selected.length} deleteSelected={async () => {
                    console.log("DELETE ALL SELECTED")
                    console.log(selected)
                    const response = await deleteAlerts(userInfo, selected)
                    console.log(response)
                    if (response === 'success') {
                        openSnack('success', 'Successfully deleted alerts')
                        setSelected([])
                        const newRows = rows.filter(row => !selected.includes(row.id))
                        setRows(newRows)
                    }
                    else {
                        openSnack('error', 'Failed to delete alerts')
                    }
                }} />

                <TableContainer
                    sx={{

                    }}
                >
                    <Table
                        sx={{
                            minWidth: 750,
                            justifyContent: 'center',
                            alignItems: 'center',

                        }}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                    >
                        <EnhancedTableHead
                            //selected rows that are checked
                            selected={selected}
                            checkboxSelect={checkboxSelect}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />

                        <TableBody>
                            {visibleRows.map((row, index) => {
                                const isItemSelected = isSelected(row.id);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        hover

                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.id}
                                        selected={isItemSelected}
                                        sx={{
                                            cursor: 'pointer',
                                            backgroundColor: index % 2 === 0 ? 'white' : '#f7f7f7',
                                            //change background color on hover
                                            '&:hover': {
                                                backgroundColor: '#3283b336 !important',
                                                fontWeight: 'bold',
                                            },
                                        }}
                                    >
                                        {checkboxSelect === true && (
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    onMouseDown={(e) => {
                                                        if (e.shiftKey) {
                                                            const lastSelectedIndex = selected.length ?
                                                                visibleRows.findIndex((r) => r.id === selected[selected.length - 1]) : -1;
                                                            const currentSelectedIndex = index;
                                                            const itemsToSelect = visibleRows.slice(Math.min(lastSelectedIndex + 1, currentSelectedIndex),
                                                                Math.max(lastSelectedIndex + 1, currentSelectedIndex) + 1).map(r => r.id);
                                                            if (isItemSelected) {
                                                                setSelected((prevSelected) => prevSelected.filter((item) => !itemsToSelect.includes(item)));
                                                            } else {
                                                                setSelected((prevSelected) => {
                                                                    const newSelected = [...new Set([...prevSelected, ...itemsToSelect])];
                                                                    return newSelected;
                                                                });
                                                            }
                                                        }
                                                        else {
                                                            handleClick(e, row.id)
                                                        }
                                                    }}

                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                />
                                            </TableCell>
                                        )}
                                        {screenWidth === 'xs' && (
                                            <EditCell row={row} handleCellHover={handleCellHover} handleCellLeave={handleCellLeave} />
                                        )}
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="none"
                                            sx={{
                                                fontWeight: 500,
                                                paddingLeft: checkboxSelect === false ? 2 : null,
                                                paddingTop: cellPadding,
                                                paddingBottom: cellPadding,
                                            }}
                                        >
                                            {row.name}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="none"
                                            sx={{

                                                width: 20
                                            }}
                                        >
                                            {row.active === true && (

                                                <FontAwesomeIcon icon={SolidBell} color='#404050' />

                                            )}
                                            {row?.sms === true && row?.smsDistribution?.length > 0 && (
                                                <FontAwesomeIcon icon={faCommentSms} style={{ marginLeft: 5 }} color='#404050' />
                                            )}
                                            {row?.emailDistribution?.length > 0 && (
                                                <FontAwesomeIcon icon={faEnvelope} style={{ marginLeft: 5 }} color='#404050' />
                                            )}

                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="none"
                                            sx={{

                                                width: 20
                                            }}
                                        >
                                            {moment().isBefore(row?.snoozeDate) && (
                                                'zzz'
                                            )}

                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="none"
                                            sx={{
                                                fontWeight: 500,
                                                paddingLeft: checkboxSelect === false ? 2 : null,
                                                paddingTop: cellPadding,
                                                paddingBottom: cellPadding,
                                            }}
                                        >
                                            {row?.node?.stationLabel}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="none"
                                            sx={{
                                                fontWeight: 500,
                                                paddingLeft: checkboxSelect === false ? 2 : null,
                                                paddingTop: cellPadding,
                                                paddingBottom: cellPadding,
                                            }}
                                        >
                                            {row?.node?.name}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="none"
                                            sx={{
                                                fontWeight: 500,
                                                paddingLeft: checkboxSelect === false ? 2 : null,
                                                paddingTop: cellPadding,
                                                paddingBottom: cellPadding,
                                            }}
                                        >
                                            {parameters.find((p) => p.id === row.parameter)?.label}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="none"
                                            sx={{
                                                fontWeight: 500,
                                                paddingLeft: checkboxSelect === false ? 2 : null,
                                                paddingTop: cellPadding,
                                                paddingBottom: cellPadding,
                                            }}
                                        >
                                            {row?.type?.charAt(0).toUpperCase() + row?.type?.slice(1)}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="none"
                                            sx={{
                                                fontWeight: 500,
                                                paddingLeft: checkboxSelect === false ? 2 : null,
                                                paddingTop: cellPadding,
                                                paddingBottom: cellPadding,
                                            }}
                                        >
                                            {row?.value === undefined ? '-' : row?.value}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="none"
                                            sx={{
                                                fontWeight: 500,
                                                paddingLeft: checkboxSelect === false ? 2 : null,
                                                paddingTop: cellPadding,
                                                paddingBottom: cellPadding,
                                            }}
                                        >
                                            {row?.alertType === undefined ? 'Continuous' : row?.alertType === 'continuous' ? 'Continuous' : 
                                            row?.alertType === 'daily' ? 'Daily' : row?.alertType === 'weekly' ? 'Weekly' : 'One Off'}
                                        </TableCell>

                                        {screenWidth !== 'xs' && (
                                            <EditCell row={row} handleCellHover={handleCellHover} handleCellLeave={handleCellLeave} />
                                        )}


                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>

                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                {loading ?
                    <FontAwesomeIcon icon={faSpinner} spin size="3x" style={{ margin: 'auto', display: 'block', paddingBottom: 20 }} />
                    :
                    null}
            </Paper>

        </Box>
    );
}

export default AlarmsTable