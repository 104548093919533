import { Typography } from '@mui/material';
import { Col, Row } from 'antd';
import { collection, deleteDoc, doc, getDocs, getFirestore, query, setDoc, updateDoc, where, onSnapshot } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { fullMobile } from './util';
import axios from 'axios';
import queryString from 'query-string';
import moment from 'moment';
import 'react-tabs/style/react-tabs.css';

export default function GetProbeData({ userInfo }) {


    const [data, setData] = useState([]);

    const db = getFirestore();

    useEffect(() => {
        //get probes
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'probes'), where('account', '==', userInfo.currentAccount),);
        const unsubscribe = onSnapshot(queryData, (querySnapshot) => {
            const data = [];
            querySnapshot.forEach((doc) => {
                data.push({ ...doc.data() });
                queryProbeData(doc.data())
            });
            setData(data);
            console.log(data)
        });
        return unsubscribe;


    }, []);


    async function queryProbeData(probe) {
        const data = {
            grant_type: 'client_credentials',
            client_id: 'testNew',
            client_secret: '11d28cb08c2444a2966931244ee43e08',
        };
        axios.post('https://mycorslake.herokuapp.com/https://www.hydrovu.com/public-api/oauth/token', queryString.stringify(data))
            .then(response => {
                console.log(response.data);
                getData(response.data.access_token, probe)


            })
            .catch((error) => {
                console.log('error ' + error);
            });
    }


    async function getData(token, probe) {
        const AuthStr = 'Bearer '.concat(token);

        console.log(probe.id)

        axios.get(`https://mycorslake.herokuapp.com/https://www.hydrovu.com/public-api/v1/locations/${probe.id}/data/`,
            {
                headers: { Authorization: AuthStr, "X-ISI-Start-Page": `` },
                params: {
                    startTime: moment().subtract(96, 'hours').format('X')
                },
            })
            .then(response => {
                console.log(response.data);
            }).catch((error) => {
                console.log('error ' + error);
            });



    }


    return (
        <Row style={{ fontFamily: 'Roboto, sans-serif', height: '100%' }} gutter={[0, 0]} align="middle">
            <Col {...fullMobile(24, 24)} style={{}}>
                <Typography variant="h5" component="body1" display="inline" gutterBottom color={'#404050'}>
                    Get Data
                </Typography>

            </Col>





        </Row>
    );
}