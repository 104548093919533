import React, { useEffect } from "react";
import { Autocomplete, Box, Grid, TextField, Tooltip, Typography } from '@mui/material';

const CodeCell = (props) => {

    const { codes, header, row, currentCell, keyDown, type, updateRow } = props;
    const editing = currentCell?.selectedID === `${row?.id}??${header.name}` && currentCell !== null;
    const [value, setValue] = React.useState(row?.[header.name] || []);
    const [actualValues, setActualValues] = React.useState([]);

    useEffect(() => {
      
        const actualValuesFiltered = codes.filter(c => row?.[header.name]?.includes(c.label));
        setValue(row?.[header.name] || []);
        setActualValues(actualValuesFiltered);

       
    }, [row?.[header.name], codes]);

    if (editing || type === 'bulk') {
        return (
            <Autocomplete
                sx={{
                    ".inputRoot": {
                        fontSize: 14,
                        fontWeight: 500,
                    },
                    input: {
                        m: 0,
                        py: 2,
                        px: 1,
                        height: 10,
                    },
                }}
                autoFocus
                fullWidth
                disableCloseOnSelect={true}
                multiple={true}
                options={codes}
                value={actualValues} // Set value to actualValues
                onChange={(e, newValue) => {
                    // Map over new values to find the labels
                    console.log(newValue)
                    const valueKeys = newValue.map(v => v?.label || v);

                    // Filter to only include values that actually exist in the 'codes' list
                    const actualValueKeys = valueKeys.filter(v => {
                        return codes.some(c => c.label === v);
                    });

                    // Update state
                    setValue(valueKeys);
                    setActualValues(actualValueKeys);
                    console.log(valueKeys)

                    const newRow = { ...row, [header.name]: valueKeys };
                    updateRow(newRow);
                }}
                openOnFocus={type === 'bulk' ? false : true}
                onFocus={(e) => e.target.select()}
                renderInput={(params) => <TextField
                    autoFocus

                    {...params} placeholder="Codes" />}
                onKeyDown={(e) => {
                    if (!['Enter', 'Tab'].includes(e.key)) return;
                    if (type === 'bulk') return;
                    keyDown(e, row);
                }}
                renderOption={(props, option, { selected }) => (
                    <Tooltip title={
                        <Typography fontSize={14} fontWeight={500} >
                            {option.description || option.label}
                        </Typography>}
                        arrow placement="right">
                        <li {...props}>

                            {option.label}
                        </li>
                    </Tooltip>
                )}
            />
        )
    } else {





        return (
            <Box>
                {value.map((v, i, arr) => {
                    const isActual = codes.some(c => c.label === v);
                    return (
                        <span key={i}>
                            <Typography
                                variant="body2"
                                fontWeight={500}
                                fontSize={13}
                                color={isActual ? "inherit" : "orange"}
                                component="span" // make it inline
                            >
                                {v === "" ? "--" : v}
                            </Typography>
                            <Typography
                                variant="body2"
                                fontWeight={500}
                                fontSize={13}
                                color={isActual ? "inherit" : "orange"}
                                component="span" // make it inline
                            >
                                {i < arr.length - 2 && ', '}
                                {i === arr.length - 2 && ' & '}
                            </Typography>

                        </span>
                    );
                })}
            </Box>

        );
    }

};

export default CodeCell;
