import {
    Button, Checkbox, Divider, OutlinedInput, Typography, Select, MenuItem, CircularProgress, Breadcrumbs, FormGroup, Box,
    FormControlLabel, Chip, TextField
} from '@mui/material';
import { Col, Row, Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { fullMobile } from '../../util';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, } from 'firebase/firestore';

import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import uuid from 'react-uuid';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import DatePicker from "react-datepicker";
import queryString from 'query-string';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { queryPSItems, queryStocks } from '../../../../firebase/config';
import PSItem from '../../../../account/productsAndServices/psItem';
import Units from '../../../../extra/units.json';
import ConcentrationUnits from '../../../../extra/concentrationUnits.json';
import LogApplicationMap from './logApplicationMap';
var randomColor = require('randomcolor');

export default function LogApplicationInfo(props) {
    const { userInfo, screenWidth, openSnack, company, account, updateLog, logRef, status } = props;
    const [staff, setStaff] = useState([]);
    const [log, setLog] = useState(logRef);
    const [items, setItems] = useState([])
    const [itemModal, setItemModal] = useState(false);
    const [newItem, setNewItem] = useState(false);
    const [newItemType, setNewItemType] = useState('');
    const [stocks, setStocks] = useState([])
    const [concentrationsList, setConcentrationsList] = useState([]);
    const [product, setProduct] = useState(null);

    useEffect(() => {
        getInfo()

    }, [])
    useEffect(() => {
        setLog(logRef)
       

    }, [logRef])


    useEffect(() => {
        setStaff(props.staffRef)
    }, [props.staffRef])

    useEffect(() => {
        if (status === 'built') {


        }



    }, [status])

    async function getInfo() {

        const items = await queryPSItems(userInfo);
        //filter items by item?.productType?.label === 'Chemical'
        const filteredItems = items.filter(item => item?.psType === 'products' && item?.productType?.label === 'Chemical')


        const newItems = filteredItems.map((item) => {
            return {
                ...item,
                label: item.name,
            }
        })


        const product = newItems?.find(item => item.key === log.product)
        if (product !== undefined) {
            setProduct({ ...product })
        }


        setItems(newItems)
        const stocks = await queryStocks(userInfo)
        setStocks(stocks)
        const concentrationsList = Object.values(ConcentrationUnits).map((u, i) => {
            return ({ label: u, key: u, unitid: Object.keys(Units)[i] })
        })
        setConcentrationsList(concentrationsList)

    }


    function updateProductInfo(key) {

        const product = items?.find(item => item.key === key)
        setProduct({ ...product, label: product?.name })
    }


    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: (option) => option.label,
    });




    return (
        <Row gutter={[12, 12]}>
            <Col {...fullMobile(24, 24)} style={{ paddingTop: 10 }}>
                <Typography variant='h5' display="inline" fontWeight={500} fontSize={screenWidth === 'xs' ? 16 : 22}>Application Info</Typography>


            </Col>

            <Col {...fullMobile(12, 24)} style={{}}>
                <Row gutter={[12, 12]}>
                    <Col {...fullMobile(24, 24)} style={{}}>
                        <Typography variant="body1" fontWeight={600}>Target Species:</Typography>
                        {status === 'built' ? <Typography variant="body1" fullWidth>{log?.targetSpecies}</Typography> : <OutlinedInput
                            fullWidth notched={false} label="none" placeholder={"Target Species"} size="small"

                            value={log?.targetSpecies}
                            onChange={(e) => {
                                updateLog(`targetSpecies`, e.target.value)
                            }} />}
                    </Col>
                    <Col {...fullMobile(12, 24)}>
                        <Typography variant="body1" fontSize={13} fontWeight={600}>Percent Covered</Typography>
                        {status === 'built' ? <Typography variant='body2'>{log?.percentApplied}%</Typography> :

                            <OutlinedInput
                                fullWidth notched={false} label="none" placeholder={"Percent"} size="small"
                                defaultValue={log?.percentApplied}
                                type="number"
                                onChange={(e) => {
                                    updateLog('percentApplied', e.target.value === "" ? Number(0) : e.target.value === "0" ? Number(0) : Number(e.target.value))

                                }} />}
                    </Col>
                    <Col {...fullMobile(12, 12)}>
                        <Typography variant="body1" fontSize={13} fontWeight={600}>Application Method</Typography>
                        {status === 'built' ? <Typography variant='body2'>{log?.applicationMethod?.label}</Typography> :
                            <Autocomplete
                                size="small"
                                id="combo-box-demo"
                                value={log.applicationMethod}
                                style={{ fontSize: 13 }}
                                filterSelectedOptions
                                options={[
                                    { label: "Boat", key: 'airBoat' },
                                    { label: "UTV/Gator", key: 'UTV/Gator' },
                                    { label: "Backpack Sprayer", key: 'backpackSprayer' },
                                    { label: "Backpack Blower", key: 'backpackBlower' },
                                    { label: "Hand Broadcast", key: 'handBroadcast' },
                                    { label: "Spray Rig", key: 'sprayRig' },
                                ]}
                                renderInput={(params) => <TextField {...params} placeholder="Method" />}
                                onChange={(a, d) => {
                                    updateLog('applicationMethod', d);
                                }}

                            />}
                    </Col>
                </Row>
            </Col>

            <Col {...fullMobile(12, 24)} style={{ paddingTop: 8 }}>

                {status === 'built' ? log?.staticUrl !== undefined && (
                    <img height={350} src={log?.staticUrl}


                        alt="map" id="map" />
                ) :



                    <LogApplicationMap {...props} dataItem={log} updateMapItems={(items) => {



                        const newMapItems = items !== undefined && items.length > 0 ? items.map((item) => {
                            return {
                                type: item.type,
                                path: item.path,
                                fillColor: item.fillColor,
                                fillOpacity: item.fillOpacity,
                                strokeWeight: item.strokeWeight,
                                clickable: true,
                                editable: true,
                                draggable: true,
                                zIndex: 1,
                                geodesic: true,
                                key: item.key,
                                label: item.label,
                                area: item.area,

                            }
                        }) : [];
                        updateLog('mapItems', newMapItems);
                    



                    }} updateItem={(boo, val) => {


                        updateLog(boo, val);
                    }} />}
                {log.mapItems && log.mapItems.length > 0 && (
                    <Row gutter={[12, 12]}>
                        {log.mapItems.map((item, index) => {
                            return (
                                <Col>
                                    <Chip style={{ backgroundColor: "white", border: '1px solid #404050' }} variant="outlined" label={(
                                        <div><Typography display="inline" variant="body1" fontSize={13} fontWeight={600}>{`Area ${item.label}: `}</Typography>
                                            <Typography display="inline" variant="body1" fontSize={13} fontWeight={400}>{`${item.area.toFixed(2)} acres`}</Typography></div>
                                    )} />
                                </Col>


                            )
                        })}
                    </Row>
                )
                }
            </Col>




            <Col {...fullMobile(24, 24)}>
                <Divider style={{
                    borderColor: 'rgb(148, 186, 195)',
                    borderBottomWidth: 'medium',

                }} />
            </Col>


            <Modal title="" visible={itemModal} onCancel={() => {
                setItemModal(false)
                setNewItem({})

            }} footer={null} width={'60%'} style={{ borderRadius: 40 }} >
                <Row>
                    <Col {...fullMobile(24, 24)} style={{ paddingTop: 8 }}>
                        <PSItem item={newItem} userInfo={userInfo} account={account} appItem={true} openSnack={openSnack} closeModal={async () => {
                            setItemModal(false)
                            setNewItem({})
                            const items = await queryPSItems(userInfo)
                            //filter items by item?.productType?.label === 'Chemical'
                            const filteredItems = items.filter(item => item?.psType === 'products' && item?.productType?.label === 'Chemical')


                            const newItems = filteredItems.map((item) => {
                                return {
                                    ...item,
                                    label: item.name,
                                }
                            }
                            )


                            setItems(newItems)
                        }} />
                    </Col>

                </Row>

            </Modal >

        </Row>
    )



}