import { Button, Checkbox, Divider, OutlinedInput, Typography, Select, MenuItem, CircularProgress, Paper, Input, FormControl, TextField, IconButton, Box, List } from '@mui/material';
import { Col, Popconfirm, Row } from 'antd';
import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { fullMobile } from './util';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, } from 'firebase/firestore';

import MUIDataTable from "mui-datatables";
import uuid from 'react-uuid';
import DataColorCompact from '../../account/account/dashboard/settings/dataColorCompact';
import axios, * as others from 'axios';
import queryString from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { createAlert, getAlerts } from './firestore';
import AlertItem from './AlertItem';


const AlertsList = ({ alerts, updateAlert, deleteAlert, parameters }) => {
    const [openAlert, setOpenAlert] = useState(null);

    const handleClick = (index) => {
        setOpenAlert(openAlert === index ? null : index);
    };

    return (
        <Col span={24}>
            <List>
                {alerts.map((alert, index) => (
                    <AlertItem
                        key={index}
                        alert={alert}
                        parameters={parameters}
                        updateAlert={updateAlert}
                        deleteAlert={deleteAlert}
                        open={openAlert === index}
                        onClick={() => handleClick(index)}
                    />
                ))}
            </List>
        </Col>
    );
};

export default AlertsList;
