import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import React, { useEffect, useState, useRef } from 'react';

import axios, * as others from 'axios';
var convert = require('convert-units')
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/data')(Highcharts);


export default function WidgetProfileNew(props) {
    const { attributes, userInfo, account, stations } = props;
    const chartDiv = useRef(null);
    const chart = useRef(null);

    const [chartOptions, setChartOptions] = useState({
        credits: {
            enabled: false
        },
        exporting: {
            enabled: false
        },
        stockTools: {
            gui: {
                enabled: false
            }
        },
        chart: {

            resetZoomButton: {
                position: {
                    align: 'left',
                    x: 20,
                    y: -20
                }
            },
            zooming: {
                mouseWheel: {
                    enabled: false
                }
            },



            zoomType: 'x',

        },
        title: {
            text: null
        },
        plotOptions: {
            series: {
                stickyTracking: false
            }
        },
        yAxis: {
            min: 0,


            reversed: true,
            title: {
                text: 'Depth'
            },
            plotLines: [{
                value: 0,
                width: 5,
                color: '#afcdf4'
            }]
        },
        xAxis: [
            {
                min: null,
                max: null,
                opposite: true,
                id: "0",
                plotLines: []
            }

        ],
        legend: {
            itemStyle: {
                fontSize: '14px',
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif'
            },
            symbolRadius: 0
        },


        series: [],


    },
    );

    useEffect(() => {
        queryProfiles()

    }, []);

    const queryProfiles = async () => {
        chartOptions.series = [];
        setChartOptions(chartOptions)

        const db = getFirestore();
        const chartData = attributes.chartData || [];
        console.log(attributes)
        chartOptions.xAxis = attributes.axises;
        const newOptions = chartOptions.xAxis.map((c) => {
            console.log(c)
            return ({
                ...c,
                min: c.customRange === true ? c.min : null, max: c.customRange === true ? c.max : null,
                plotLines: c.plotLines || []
            })
        })
        chartOptions.xAxis = newOptions;


        const yPlots = attributes.references.filter((r) => r.axisType === 'yAxis');
        const xPlots = attributes.references.filter((r) => r.axisType === 'xAxis');



        const updatedY = yPlots.map((f) => {
            return ({
                ...f, value: f.yValue, zIndex: 50,
                label: { text: f.useAlias ? f.alias : f.title, textAlign: f.textAlign, verticalAlign: f.verticalAlign, }
            })
        })

        xPlots.map((f) => {

            const index = chartOptions.xAxis.map((d) => `${d.axisId}`).indexOf(f.xAxis);

            if (index !== -1) {
                chartOptions.xAxis[index].plotLines.push({
                    ...f,
                    zIndex: 50,
                    value: f.xValue,
                    label: { text: f.useAlias ? f.alias : f.title, textAlign: f.textAlign, verticalAlign: f.verticalAlign, }
                })
            }

        })


        const axises = chartOptions.xAxis.map((item, i) => {


            return ({
                ...item,

                title: {
                    text: item.label || '',

                }, labels: {
                    style: {
                        fontWeight: 'bold',
                        color: item.color || 'black',
                    }
                }
            })
        })




        setChartOptions((prevState) => ({
            ...prevState,
            xAxis: axises,
            yAxis: {
                ...prevState.yAxis,
                title: {
                    ...prevState.yAxis.title,
                    style: {
                        fontWeight: attributes.yAxisTitleFontWeight || 400,
                        color: attributes.yAxisTitleLabelColor || 'black',
                        fontSize: attributes.yAxisTitleFontSize || '16px',
                    },
                },
                labels: {
                    style: {
                        fontWeight: 'bold',
                        color: attributes.yAxisLabelColor || 'black',
                        fontSize: attributes.yAxisFontSize || '12px',
                    },
                },
                plotLines: updatedY
            },
        }));





        const axisKeys = newOptions.map((a) => a.axisId.toString());


        const keys = chartData.map((item) => item.profile !== undefined ? item.profile.key : '123')
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'profiles'), where('key', 'in', keys));
        const snap = await getDocs(queryData);

        const profiles = [];
        snap.forEach((doc) => {
            profiles.push(doc.data())
        }
        );



        const data = [];
        const dataList = [];
        profiles.forEach((profile) => {

            const index = chartData.map((item) => item.profile.key).indexOf(profile.key);
            const item = chartData[index];
            const newData = profile.data.map((d) => {
                return (
                    [d.value, Number(d.depth)]
                )
            })
            dataList.push({ data: newData })

            //sort new data by depth
            newData.sort((a, b) => {
                return a[1] - b[1]
            })



            data.push({

                ...profile,
                ...item,
                index: item.index || 0,
                data: [...newData],

            })

        });



        const sortedData = data.sort((a, b) => {
            return a.index - b.index
        }
        );



        const series = sortedData.map((item) => {
            return ({
                ...item,
                xAxis: axisKeys.includes(item.xAxis.toString()) ? item.xAxis : '0',
                tooltip: {
                    valueDecimals: 2,
                },
                name: item.label || ''
            })
        }
        );


        chartOptions.series = series;
        setChartOptions(chartOptions)



    };





    useEffect(() => {
        //if height of chartDiv changes, update chart height
        const observer = new ResizeObserver((entries) => {
            const chart = Highcharts.charts[0];
            if (chart) {
                chart.setSize(entries[0].contentRect.width, entries[0].contentRect.height);
            }
        });
        observer.observe(chartDiv.current);
    }, []);

    const Height = () => {
        if (chartDiv.current) {
            if (chartDiv.current.clientHeight !== chart.current.clientHeight) {
                chart.current.chart.setSize(chartDiv.current.clientWidth,
                    //90% of chartDiv height
                    chartDiv.current.clientHeight * 0.95);
            }

        }

    }



    return (
        <div ref={chartDiv} style={{ height: '100%', width: '100%', }}>
            <Height />
            <HighchartsReact
                constructorType={"chart"}
                highcharts={Highcharts}
                ref={chart}
                options={chartOptions}
                allowChartUpdate={true}
                immutable={false}
            />
        </div>

    );
}