import React from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ColorPickerButton from "./customheaderComponents/optionColorPicker";

const SingleSelect = ({
    currentTask,
    setCurrentTask,
    variable,
    options,
    headerKey
}) => {
    const [age, setAge] = React.useState(currentTask?.[headerKey] || '');
    const [isFocused, setIsFocused] = React.useState(false);

    const handleChange = (event) => {
        setAge(event.target.value);
        setCurrentTask({ ...currentTask, [headerKey]: event.target.value })

    };

  

    return (
        <div>
            <FormControl fullWidth variant="standard">
                {!age && !isFocused && (
                    <InputLabel id="demo-simple-select-standard-label">
                        Select one
                    </InputLabel>
                )}
                <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={age}
                    onChange={handleChange}
                    displayEmpty
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    sx={{
                        '& .MuiSelect-standard': {
                            color: 'black',
                            fontWeight: 500,
                            fontSize: 14,
                        },

                    }}
                    renderValue={(selected) => {
                        if (!selected) {
                            return <span style={{
                                color: 'rgba(0,0,0,0.5)',
                                fontSize: 14,
                                fontWeight: 500,
                            }}>Select one</span>
                        }

                        const selectedOption = options?.find(option => option.id === selected);
                        const color = selectedOption?.color;

                        return (
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                                <ColorPickerButton
                                    type={'single'}
                                    disabled={true}
                                    initialColor={color}

                                />

                                <span style={{
                                    marginLeft: 5,
                                    fontSize: 14,
                                    fontWeight: 500,
                                }}>{selectedOption?.name}</span>
                            </div>
                        )
                    }}
                >

                    {options?.map((option, index) => {
                    


                        return (
                            <MenuItem value={option.id} style={{
                                borderBottom: '1px solid rgba(0,0,0,0.2)',
                            }}>
                                <div style={{
                                    marginRight: 10
                                }}>
                                    <ColorPickerButton
                                        type={'single'}
                                        disabled={true}
                                        initialColor={option.color}

                                    />
                                </div>





                                {option.name}
                            </MenuItem>




                        );
                    })}



                </Select>
            </FormControl>
        </div>
    );
};

export default SingleSelect;
