const TidalParameters = [
    { key: "0", label: "Water Temperature", product: 'water_temperature', valueKey: "v" },
    { key: "1", label: "Air Temperature", product: 'air_temperature', valueKey: "v" },
    { key: "2", label: "Preliminary Water Level", product: 'water_level', valueKey: "v", },
    { key: "3", label: "Tide Prediction Level", product: 'predictions', valueKey: "v", dataKey: 'predictions' },
    { key: "4", label: "Wind: Speed", product: 'wind', valueKey: "s" },
    { key: "5", label: "Wind: Direction", product: 'wind', valueKey: "d" },
    { key: "6", label: "Air Pressure", product: 'air_pressure', valueKey: "v" },
    { key: "7", label: "Visibility", product: 'visibility', valueKey: "v" },
    { key: "8", label: "Humidity", product: 'humidity', valueKey: "v" },
    { key: "9", label: "Conductivity", product: 'conductivity', valueKey: "v" },
    { key: "10", label: "Salinity", product: 'salinity', valueKey: "s" },
]

export default TidalParameters;
