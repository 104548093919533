import React, { useEffect, useState } from "react";


import { Box, Checkbox, Divider, Grid, IconButton, Typography } from '@mui/material';
import { Col, Row, Table } from 'antd';
import { collection, collectionGroup, doc, getDoc, getDocs, getFirestore, onSnapshot, query, setDoc, updateDoc, where, writeBatch } from "firebase/firestore";
import moment from 'moment-timezone';
import uuid from "react-uuid";
import { faCheck, faSpinner, faSquareCheck, } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon, } from "@fortawesome/react-fontawesome";

import './newStyle.css';
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { useNavigate } from "react-router-dom";




const MobileLogsTable = (
    {
        filteredLogs,
        selectedLogs,
        setSelectedLogs,
        screenWidth
    }

) => {


    const navigate = useNavigate();
    const navigatePage = (id) => {
        navigate(`/newLogHome/${id}`);
    };



    return (
        <Box>
            {filteredLogs.map((log, index) => (
                <Box
                    key={index}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                        pb: 2,
                        border: "1px solid #ccc",
                    }}
                    onClick={() => navigatePage(log.id)}
                >
                    <Grid container spacing={2} sx={{ width: "100%" }} alignContent={'center'} alignItems={'center'} justifyContent={'center'} >
                        <Grid item xs={2} sm={2} style={{ textAlign: 'left' }}>
                            <IconButton
                                style={{ display: 'inline' }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    const newSelectedLogs = [...selectedLogs];
                                    if (selectedLogs.some((t) => t === log.id)) {
                                        newSelectedLogs.splice(newSelectedLogs.indexOf(log.id), 1);
                                    } else {
                                        newSelectedLogs.push(log.id);
                                    }
                                    setSelectedLogs(newSelectedLogs);
                                }}
                            >
                                <FontAwesomeIcon color="#3f51b5"
                                    icon={selectedLogs.some((t) => t === log.id) ? faSquareCheck : faSquare}
                                />
                            </IconButton>
                        </Grid>
                        <Grid item xs={10} sm={10} style={{ textAlign: 'left' }}>
                            <Typography variant="body1" color="black" style={{ display: 'inline' }} fontSize={18} fontWeight={400}>
                                Date: <span style={{ fontWeight: 800, color: '#5a5a9d' }}>{log?.startDate}</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={2} sm={2} style={{ textAlign: 'left', paddingTop: 0 }}>
                        </Grid>
                        <Grid item xs={10} sm={10} style={{ textAlign: 'left', paddingTop: 0 }}>
                            <Typography variant="body1" color="black" style={{ display: 'inline' }} fontSize={16} fontWeight={400}>
                                Status: <span style={{ fontWeight: 700, color: log?.status === 'Open' ? 'green' : 'orange' }}>{log?.status}</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={2} sm={2} style={{ textAlign: 'left', paddingTop: 0 }}>
                        </Grid>
                        <Grid item xs={10} sm={10} style={{ textAlign: 'left', paddingTop: 0 }}>
                            <Typography variant="body1" color="black" style={{ display: 'inline' }} fontSize={16} fontWeight={400}>
                                Log #: <span style={{ fontWeight: 700 }}>{log?.logNumber}</span>
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            ))
            }



        </Box >


    )



};

export default MobileLogsTable;
