import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Autocomplete, Divider, IconButton, OutlinedInput, TextField, Typography } from '@mui/material';
import { Col, Popconfirm, Row } from 'antd';
import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';
import React from 'react';

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { ReactSortable } from 'react-sortablejs';
import ConcentrationUnits from '../../../extra/concentrationUnits.json';
import Units from '../../../extra/productUnits.json';
import '../hoverStyle.css';
import { fullMobile } from './util';

var convert = require('color-convert');

export default class ApplicationInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            type: this.props.type,
            item: this.props.item,
            loading: true,
            products: [],
            preview: this.props.preview,
            unitList: [],
            concentrationsList: [],
            colorModal: false,
            modalIndex: undefined,
            stocks: [],
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
        if (this.props.preview !== prevProps.preview) {
            this.setState({ preview: this.props.preview })
        }
        if (this.props.item !== prevProps.item) {
            this.setState({ item: this.props.item })

        }


    }
    componentDidMount() {
        const { userInfo, item } = this.state;

        this.queryData()
        this.queryStocks()

        const unitList = Object.values(Units).map((u, i) => {
            return ({ label: u, key: u, unitid: Object.keys(Units)[i] })
        })
        const concentrationsList = Object.values(ConcentrationUnits).map((u, i) => {
            return ({ label: u, key: u, unitid: Object.keys(Units)[i] })
        })
        this.setState({ unitList, concentrationsList })


    }

    queryStocks = async () => {
        const { userInfo, type } = this.state;
        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'productStock'), where('archived', '==', false));
        const data = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            data.push({ ...doc.data(), label: doc.data().name })


        });
        data.sort((a, b) => a.name.localeCompare(b.name))
        this.setState({ stocks: data, loading: false })


    }

    queryData = async () => {
        const { userInfo, type } = this.state;
        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'psItems'), where('psType', '==', 'products'), where('archived', '==', false));
        const data = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            data.push({
                archived: doc.data().archived,
                description: doc.data().description || '',
                name: doc.data().name || '',
                label: doc.data().name || '',
                key: doc.data().key || '',
                visible: true
            })


        });

        this.setState({ products: data, loading: false })


    }




    updateItem = (boo, val) => {
        const { item } = this.state;
        item[boo] = val;

        this.setState({ item })
        this.props.updateEntireItem(item)
    }

    updateProductItem = (index, boo, val) => {
        const { item } = this.state;
        item.products[index][boo] = val;

        this.updateItem('products', item.products)
    }

    deleteProduct = (row) => {
        const { item } = this.state;
        const newProducts = item.products.filter((f) => f.key !== row.key);
        item.products = newProducts;

        this.updateItem('products', item.products)
    }


    render() {

        const { item, screenWidth, preview, products, unitList, concentrationsList, account, userInfo, colorModal, modalIndex, stocks } = this.state;

        const DeleteItem = ({ row, type }) => {
            return (
                <Popconfirm
                    title={`Are you sure to delete this product?`}
                    onConfirm={async () => {

                        this.deleteProduct(row)

                    }}
                    okText="Yes"
                    cancelText="No"
                >
                    <IconButton style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="small"
                    >
                        <DeleteForeverIcon />
                    </IconButton>
                </Popconfirm>


            )
        }







        return (
            <Row gutter={[8, 8]} style={{ paddingTop: 10 }}   >

                {item.products !== undefined && item.products.length > 0 && (<Col span={24} style={{ border: '1px solid rgba(0,0,0,0.3)', borderRadius: 8 }}>

                    {item.products !== undefined && item.products.length > 0 && (

                        <ReactSortable
                            handle=".my-handle"
                            animation={500}
                            list={item.products}
                            setList={(newState) => {
                                item.products = newState;
                                this.updateItem('products', newState)

                            }}
                        >
                            {item.products.map((d, i) => {





                                return (

                                    <Row gutter={[4, 4]} key={d.key} style={{ backgroundColor: i % 2 === 0 ? 'rgba(0,0,0,0.03)' : 'rgba(0,0,0,0)', padding: 15, position: 'relative' }}>
                                        <Col span={2} className="my-handle">
                                            {preview ?
                                                null : <DragIndicatorIcon />}
                                        </Col>
                                        <Col {...fullMobile(22, 24)} style={{}}>
                                            <Row align="middle" gutter={[16, 8]}>
                                                <Col {...fullMobile(8, 24)} style={{}}>
                                                    <Typography variant="body1" fontSize={13} fontWeight={600}>Product</Typography>

                                                    {preview ?
                                                        <Typography variant='body2'>{d.product !== undefined ? d.product.label : ''}</Typography> :
                                                        <Autocomplete
                                                            size="small"
                                                            disablePortal
                                                            id="combo-box-demo"
                                                            value={d.product}
                                                            hideLabel={true}
                                                            options={products.sort((a, b) => a.label.localeCompare(b.label))}
                                                            renderInput={(params) => <TextField {...params} label="Product" />}
                                                            onChange={(a, b) => {
                                                                this.updateProductItem(i, `product`, b)
                                                            }}

                                                        />}
                                                </Col>
                                                <Col {...fullMobile(8, 12)} style={{}}>
                                                    <Typography variant="body1" fontSize={13} fontWeight={600}>Stock</Typography>

                                                    {preview ?
                                                        <Typography variant='body2'>{d.supplyStock !== undefined ? d.supplyStock.label : ''}</Typography> :
                                                        <Autocomplete
                                                            size="small"
                                                            disablePortal
                                                            id="combo-box-demo"
                                                            value={d.supplyStock}
                                                            hideLabel={true}
                                                            options={stocks.sort((a, b) => a.label.localeCompare(b.label))}
                                                            renderInput={(params) => <TextField {...params} label="Stock" />}
                                                            onChange={(a, b) => {
                                                                this.updateProductItem(i, `supplyStock`, b)
                                                            }}

                                                        />}
                                                </Col>
                                                <Col {...fullMobile(8, 12)} style={{}}>
                                                    <Typography variant="body1" fontSize={13} fontWeight={600}>Application Method</Typography>

                                                    {preview ?
                                                        <Typography variant='body2'>{d.applicationMethod !== undefined ? d.applicationMethod.label : ''}</Typography> :
                                                        <Autocomplete
                                                            size="small"
                                                            disablePortal
                                                            id="combo-box-demo"
                                                            value={d.applicationMethod}
                                                            hideLabel={true}
                                                            style={{ fontSize: 13 }}
                                                            options={[
                                                                { label: "Boat", key: 'airBoat' },
                                                                { label: "UTV/Gator", key: 'UTV/Gator' },
                                                                { label: "Backpack Sprayer", key: 'backpackSprayer' },
                                                                { label: "Backpack Blower", key: 'backpackBlower' },
                                                                { label: "Hand Broadcast", key: 'handBroadcast' },
                                                                { label: "Spray Rig", key: 'sprayRig' },
                                                            ]}
                                                            renderInput={(params) => <TextField {...params} label="Method" />}
                                                            onChange={(a, b) => {
                                                                this.updateProductItem(i, `applicationMethod`, b)
                                                            }}

                                                        />}
                                                </Col>
                                                <Col {...fullMobile(5, 12)} style={{}}>
                                                    <Typography variant="body1" fontSize={13} fontWeight={600}>Conc.</Typography>
                                                    {preview ? <Typography variant="body1">{d.concentration}</Typography> : <OutlinedInput
                                                        fullWidth notched={false} label="none" placeholder={"Conc."} size="small"
                                                        defaultValue={d.concentration}
                                                        type="number"
                                                        onChange={(e) => {
                                                            this.updateProductItem(i, 'concentration', e.target.value === "" ? Number(0) : e.target.value === "0" ? Number(0) : Number(e.target.value))
                                                        }} />}
                                                </Col>
                                                <Col {...fullMobile(3, 12)} style={{}}>
                                                    <Typography variant="body1" fontSize={13} fontWeight={600}>Conc.Units</Typography>

                                                    {preview ?
                                                        <Typography variant='body2'>{d.concentrationUnits !== undefined ? d.concentrationUnits.label : ''}</Typography> :
                                                        <Autocomplete
                                                            size="small"
                                                            disablePortal
                                                            id="combo-box-demo"
                                                            value={d.concentrationUnits}
                                                            hideLabel={true}
                                                            options={concentrationsList}
                                                            renderInput={(params) => <TextField {...params} label="Units" />}
                                                            onChange={(a, b) => {
                                                                this.updateProductItem(i, `concentrationUnits`, b)
                                                            }}

                                                        />}
                                                </Col>

                                                <Col {...fullMobile(5, 12)} style={{}}>
                                                    <Typography variant="body1" fontSize={13} fontWeight={600}>Total Amount</Typography>
                                                    {preview ? <Typography variant="body1">{d.quantity}</Typography> : <OutlinedInput
                                                        fullWidth notched={false} label="none" placeholder={"Amount"} size="small"
                                                        defaultValue={d.quantity}
                                                        type="number"
                                                        onChange={(e) => {
                                                            this.updateProductItem(i, 'quantity', e.target.value === "" ? Number(0) : e.target.value === "0" ? Number(0) : Number(e.target.value))
                                                        }} />}
                                                </Col>
                                                <Col {...fullMobile(3, 12)} style={{}}>
                                                    <Typography variant="body1" fontSize={13} fontWeight={600}>Units</Typography>

                                                    {preview ?
                                                        <Typography variant='body2'>{d.units !== undefined ? d.units.label : ''}</Typography> :
                                                        <Autocomplete
                                                            size="small"
                                                            disablePortal
                                                            id="combo-box-demo"
                                                            value={d.units}
                                                            hideLabel={true}
                                                            options={["mg",
                                                                "g",
                                                                "kg",
                                                                "oz",
                                                                "lb",
                                                                "fl-oz",
                                                                "cup",
                                                                "pnt",
                                                                "qt",
                                                                "gal",
                                                                "ft3",
                                                            ].sort((a, b) => a.localeCompare(b)).map((g) => { return ({ key: g, label: g }) })}
                                                            renderInput={(params) => <TextField {...params} label="Units" />}
                                                            onChange={(a, b) => {
                                                                this.updateProductItem(i, `units`, b)
                                                            }}

                                                        />}
                                                </Col>

                                                <Col {...fullMobile(8, 24)} style={{ textAlign: screenWidth === 'xs' ? 'right' : "right" }}>
                                                    {preview ?
                                                        null : <DeleteItem row={d} />}
                                                </Col>



                                            </Row>
                                        </Col>





                                        <Col span={24} style={{ position: 'absolute', bottom: 0 }} >
                                            <Divider />
                                        </Col>
                                    </Row>

                                )
                            })}
                        </ReactSortable>

                    )}

                </Col>)}



















            </Row >
        );
    }
} 