import React, { useEffect, useState } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Box, IconButton, List, ListItem, Tooltip, Dialog, DialogTitle, DialogActions, Chip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faDownload, faEllipsisH, faPaintBrush, faPenToSquare, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Col, Row } from 'antd';
import ExportChartCSVNew from '../charts/exportChartCSVNew';
import { downloadSeriesAsCSV } from './advancedWidgetFunctions';
import moment from 'moment';
import './advancedWidgetSettings.css';
import DataEditorHome from './dataEditor/dataEditorHome';

const AdvancedWidgetSettings = ({ attributes, widgetKey, userInfo, openSnack, disableTooltip, enableTooltip, screenWidth, account, chartTitle, loggedIn }) => {


    const [anchorEl, setAnchorEl] = useState(null);
    const [downloading, setDownloading] = useState(false)
    const [openEditor, setOpenEditor] = useState(false);
    const [dialog, setDialog] = useState(false);
    const [downloadingCSV, setDownloadingCSV] = useState(false);

    useEffect(() => {
        if (openEditor === false && attributes?.chartData?.length > 0) {
            enableTooltip(attributes)
        }
    }, [openEditor])

    const handleClick = (event) => {
        disableTooltip()
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {

        setAnchorEl(null);
    };

    const handleCloseDialog = () => {
        setDialog(false);
    };

    useEffect(() => {
        if (openEditor === false) {

        }
    }, [openEditor])


    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const props = { openEditor, setOpenEditor, widgetKey, userInfo, attributes, openSnack, enableTooltip, screenWidth, account, widgetKey };

    return (
        <div>

            <IconButton onClick={handleClick}

            >
                <Tooltip title="More Options" placement="top">
                    <FontAwesomeIcon icon={faEllipsisH}
                        size="md" style={{ color: '#3f51b5', cursor: 'pointer' }} />
                </Tooltip>



            </IconButton>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}

                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <List sx={{
                    width: 300,
                    padding: 0,

                }}>


                    {[undefined, true].includes(loggedIn) && (<ListItem
                        key={'downloadCSV'}
                        dense
                        button
                        onClick={async () => {
                            setOpenEditor(true);
                            handleClose();

                        }}
                        sx={{

                            borderBottom: '1px solid #e0e0e0',
                            paddingTop: 1,
                            paddingBottom: 1,
                        }}
                    >
                        <FontAwesomeIcon icon={faPenToSquare} style={{ color: '#3f51b5', marginRight: 10 }} />
                        <Typography variant="body1" color="black" fontSize={16} fontWeight={500}>Edit Data
                            <Chip label="BETA" color="primary" variant="outlined" size='small'
                                style={{ borderRadius: 2, marginLeft: 10 }}
                            />

                        </Typography>

                    </ListItem>
                    )}

                    <ListItem
                        key={'downloadCSV'}
                        dense
                        button
                        onClick={async () => {
                            try {
                                setDownloading(true);
                                setDialog(true);
                                openSnack('success', 'CSV downloaded');
                            } catch (error) {
                                console.error(error);
                                openSnack('error', 'Error downloading CSV');
                            }
                        }}
                        sx={{
                            borderBottom: '1px solid #e0e0e0',
                            paddingTop: 1,
                            paddingBottom: 1,
                        }}
                    >
                        {downloading ? (
                            <>
                                <FontAwesomeIcon icon={faSpinner} style={{ color: '#3f51b5', marginRight: 10 }} spin />
                                <Typography variant="body1" color="black" fontSize={16} fontWeight={500}>
                                    Downloading<span className="ellipsis"></span>
                                </Typography>
                            </>
                        ) : (
                            <>
                                <FontAwesomeIcon icon={faDownload} style={{ color: '#3f51b5', marginRight: 10 }} />
                                <Typography variant="body1" color="black" fontSize={16} fontWeight={500}>Download CSV</Typography>
                            </>
                        )}

                    </ListItem>
                    {/*  <ListItem
                        key={'downloadCSV'}
                        dense
                        button
                        disabled
                        onClick={async () => {

                        }}
                        sx={{
                            backgroundColor: '#f5f5f5',
                            color: '#9e9e9e',
                            borderBottom: '1px solid #e0e0e0',
                            paddingTop: 1,
                            paddingBottom: 1,
                        }}
                    >
                        <FontAwesomeIcon icon={faPaintBrush} style={{ color: '#3f51b5', marginRight: 10 }} />
                        <Typography variant="body1" color="black" fontSize={16} fontWeight={500}>Edit Chart</Typography>

                    </ListItem> */}






                </List>




            </Popover>

            <Dialog
                open={dialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Download all data or averaged data? If you're chart is a large timeframe it may be averaging the data.
                </DialogTitle>

                <DialogActions>
                    <Button style={{
                        textTransform: 'none',
                        color: 'red'
                    }} onClick={() => {
                        handleCloseDialog()
                        setDownloading(false);
                    }}>Cancel</Button>
                    <Tooltip title="Download all data for these series even if the chart is averaging the data" placement="top">
                        <Button
                            style={{
                                fontWeight: 600,
                                textTransform: 'none',

                            }}

                            onClick={async () => {
                                setDownloadingCSV(true);
                                await downloadSeriesAsCSV(attributes?.chartData, userInfo, attributes, 'all', chartTitle, widgetKey);

                                setDownloading(false);
                                handleClose();
                                handleCloseDialog();
                                openSnack('success', 'CSV downloaded');
                                setDownloadingCSV(false)
                            }}
                            autoFocus>
                            {downloadingCSV ? (
                                <>
                                    <FontAwesomeIcon icon={faSpinner} style={{ color: '#3f51b5', marginRight: 10 }} spin />

                                    Downloading

                                </>
                            ) : (
                                <>
                                    All Data
                                </>
                            )}
                        </Button>
                    </Tooltip>
                    <Tooltip title="Download exactly what the chart is showing" placement="top">
                        <Button
                            style={{
                                fontWeight: 600,
                                textTransform: 'none',

                            }}

                            onClick={async () => {
                                setDownloadingCSV(true);
                                await downloadSeriesAsCSV(attributes?.chartData, userInfo, attributes, 'chart', chartTitle, widgetKey);

                                setDownloading(false);
                                handleClose();
                                handleCloseDialog();
                                openSnack('success', 'CSV downloaded');
                                setDownloadingCSV(false)
                            }}
                            autoFocus>
                            {downloadingCSV ? (
                                <>
                                    <FontAwesomeIcon icon={faSpinner} style={{ color: '#3f51b5', marginRight: 10 }} spin />

                                    Downloading

                                </>
                            ) : (
                                <>
                                    Chart Data
                                </>
                            )}
                        </Button>
                    </Tooltip>
                </DialogActions>
            </Dialog>


            <DataEditorHome {...props} />

        </div>
    );
}

export default AdvancedWidgetSettings;