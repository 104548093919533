import { Button, Divider, Grid, IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import { Col, Row } from 'antd';
import uuid from 'react-uuid';
import { doc, getFirestore, setDoc } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { formatSQLData, uploadData } from './functions';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '1px solid rgba(0, 0, 0, .3)',
    boxShadow: 24,
    p: 4,
    borderRadius: '24px',
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
};

const TemplateMaker = ({
    templates,
    setTemplates,
    template,
    setTemplate,
    steps,
    setSteps,
    currentStep,
    item,
    setItem,
    nextStep,
    backStep,
    screenWidth,
    openSnack,
    userInfo,
    codes,
    setCodes,
    parameters,
    setParameters,
    stations,
    setStations,
    unitsArray,
    setData,
    data,
    meta,
    setMeta,
    setHeaders,
    headers,
    templateMaker,
    setTemplateMaker,
    sortedData



}) => {

    const handleOpen = () => setTemplateMaker(true);
    const handleClose = () => {
        setTemplateMaker(false)
        setStep('createTemplate');
        setNewTemplate('');
    };
    const [filteredTempaltes, setFilteredTemplates] = useState(templates || null)
    const [newTemplate, setNewTemplate] = useState('');
    const [onHover, setOnHover] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [step, setStep] = useState('createTemplate');
    const [uploading, setUploading] = useState(false);

    useEffect(() => {
        setFilteredTemplates(templates);
    }, [templates, templateMaker]);








    return (
        <div>
            <Modal
                open={templateMaker}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{

                }}

                slotProps={{
                    backdrop: {
                        style: {
                            backgroundColor: 'transparent'
                        }
                    }
                }}
            >
                <>
                    {step === 'createTemplate' && (
                        <Box sx={style}>
                            <Typography id="modal-modal-title" variant="h6" fontWeight={700} fontSize={28} component="h2">
                                Create a template
                            </Typography>
                            <Typography id="modal-modal-description" variant="caption" fontSize={13} sx={{ mt: 2 }}>
                                Use templates to speed up the time it takes to upload data.
                            </Typography>

                            <Typography sx={{ mt: 2 }} id="modal-modal-title" variant="body1" fontWeight={600} fontSize={14} component="h2">
                                Add template
                            </Typography>



                            <Box>
                                <form
                                    onSubmit={async (e) => {
                                        e.preventDefault();  // Prevent form from reloading the page
                                        const newTemplateItem = {
                                            company: userInfo.currentCompany,
                                            account: userInfo.currentAccount,
                                            key: uuid(),
                                            archived: false,
                                            name: newTemplate,
                                            label: newTemplate,
                                            headers: headers
                                        }
                                        const updateTeamplates = [newTemplateItem, ...templates];
                                        //check if parameter already exists
                                        const exists = templates.find((param) => param.label.toLowerCase() === newTemplate.toLowerCase());
                                        if (exists) {
                                            openSnack('error', 'Template already exists');
                                            return;
                                        }

                                        const matchesHeaders = templates.find((param) => JSON.stringify(param.headers) === JSON.stringify(headers));
                                        if (matchesHeaders) {
                                            openSnack('error', `Template with these headers already exists, use the template named ${matchesHeaders.label} next time`);
                                            return;
                                        }





                                        const db = getFirestore();
                                        const docRef = doc(db, "clients", userInfo.currentCompany, 'dataTemplates', newTemplateItem.key);
                                        await setDoc(docRef, newTemplateItem);
                                        openSnack('success', 'Template added successfully');

                                        setTemplates(updateTeamplates);
                                        setNewTemplate('');  // Clear the input field
                                        setStep('uploadData');

                                    }}
                                >
                                    <Row align="middle">
                                        <Col span={20}>
                                            <OutlinedInput
                                                sx={{
                                                    // existing styles
                                                }}
                                                fullWidth
                                                size="small"
                                                value={newTemplate}
                                                placeholder="Add new template"
                                                onChange={(e) => {
                                                    setNewTemplate(e.target.value);
                                                }}
                                            />
                                        </Col>
                                        <Col span={4} style={{ textAlign: 'right' }}>
                                            <Button
                                                type="submit"  // Set type as submit to trigger form submission
                                                style={{
                                                    textTransform: 'none',
                                                    backgroundColor: '#3f51b5',
                                                    color: 'white',
                                                    borderRadius: '5px',
                                                }}
                                                size='small'
                                            >
                                                Add
                                            </Button>
                                        </Col>
                                    </Row>
                                </form>
                            </Box>
                            <Box>
                                {headers?.map((header, index) => {
                                    return (
                                        <Row key={header.id}
                                            style={{
                                                padding: 4,
                                                paddingLeft: 0
                                            }}

                                        >
                                            <Col span={24}>
                                                <Typography
                                                    fontWeight={600}
                                                    sx={{
                                                        mt: 1,
                                                        color: 'black',
                                                        marginLeft: '1em' // Indent the warning text
                                                    }}
                                                    id="modal-modal-title"
                                                    variant="body1"

                                                    fontSize={13}
                                                    component="span"
                                                >
                                                    {header.name} --> {header?.mappedFieldLabel || 'Not mapped'}

                                                </Typography>
                                            </Col>

                                        </Row>

                                    );
                                })}
                            </Box>




                            <Typography sx={{ mt: 2 }} id="modal-modal-title" variant="body1" fontWeight={600} fontSize={14} component="h2">
                                Templates list
                            </Typography>

                            <Box
                                sx={{
                                    mb: 0,
                                    pb: 0,
                                    px: 1
                                }}
                                style={{
                                    borderTop: '1px solid #e0e0e0',
                                    borderLeft: '1px solid #e0e0e0',
                                    borderRight: '1px solid #e0e0e0',
                                    borderRadius: '5px',
                                }}

                            >

                                <OutlinedInput
                                    sx={{
                                        mt: 1,
                                        mb: 1,
                                        width: '100%',
                                        height: '40px',
                                        borderRadius: 8
                                    }}
                                    value={searchQuery}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    }
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                size='small'
                                                aria-label="toggle password visibility"

                                                onClick={() => {
                                                    setFilteredTemplates(templates);
                                                    setSearchQuery('');
                                                }}
                                            >
                                                X
                                            </IconButton>
                                        </InputAdornment>
                                    }


                                    placeholder="Search templates"
                                    onChange={(e) => {
                                        setSearchQuery(e.target.value);
                                        if (e.target.value === '') {
                                            setFilteredTemplates(templates);
                                            return;
                                        }
                                        const filteredTempaltes = templates.filter((param) => {
                                            return param.label.toLowerCase().includes(e.target.value.toLowerCase());
                                        });
                                        setFilteredTemplates(filteredTempaltes);
                                    }}
                                />
                            </Box>
                            <Box
                                sx={{
                                    mt: 0
                                }}

                                style={{
                                    height: '150px',
                                    overflowY: 'scroll',
                                    border: '1px solid #e0e0e0',
                                    borderTop: 'none',
                                    borderRadius: '5px',

                                }}>


                                {filteredTempaltes?.map((param, index) => {
                                    return (
                                        <Row key={param.key}
                                            style={{
                                                cursor: 'pointer',
                                                padding: 4
                                            }}

                                            onMouseEnter={(e) => {
                                                setOnHover(param.key)
                                                e.currentTarget.style.backgroundColor = '#f0f0f0'; // On mouse enter, change background color
                                            }}
                                            onMouseLeave={(e) => {
                                                setOnHover(null)
                                                e.currentTarget.style.backgroundColor = 'transparent'; // On mouse leave, reset background color
                                            }}>
                                            <Col span={24}>
                                                <Typography
                                                    fontWeight={600}
                                                    sx={{
                                                        mt: 1,
                                                        color: 'black',
                                                        marginLeft: '1em' // Indent the warning text
                                                    }}
                                                    id="modal-modal-title"
                                                    variant="body1"

                                                    fontSize={13}
                                                    component="span"
                                                >
                                                    {param.label}

                                                </Typography>
                                            </Col>

                                        </Row>

                                    );
                                })}


                            </Box>

                            <Box
                                style={{ textAlign: 'right' }}

                                sx={{ mt: 2 }}>
                                <Button
                                    onClick={() => setStep('uploadData')}
                                    style={{
                                        textTransform: 'none',
                                        backgroundColor: '#d9d9d9',
                                        color: 'black',
                                        borderRadius: '5px',
                                        fontSize: 14,
                                        fontWeight: 500


                                    }}
                                    size="large"

                                >
                                    Skip & Upload Data
                                </Button>
                            </Box>



                        </Box>
                    )}

                    {step === 'uploadData' && (
                        <Box sx={style}>
                            <Typography id="modal-modal-title" variant="h6" fontWeight={700} fontSize={28} component="h2">
                                Upload data
                            </Typography>
                            <Typography id="modal-modal-description" variant="caption" fontSize={15} sx={{ mt: 2 }}>
                                Click below to upload all your data to your LakeTech database!
                            </Typography>

                            <Button variant="contained" color="primary"
                                fullWidth
                                disabled={uploading}
                                sx={{ mt: 4 }}
                                onClick={async () => {
                                    setUploading(true);
                                    try {
                                        const responseData = await formatSQLData({ userInfo, data, parameters, stations, unitsArray, headers, codes })
                                        const response = await uploadData(responseData, userInfo);

                                        console.log(response)
                                        if (response === 'success!') {

                                            openSnack('success', 'Data uploaded successfully');



                                            setData(null)
                                            setHeaders([])
                                            setMeta({})
                                            setSteps(
                                                [
                                                    {
                                                        step: "upload",
                                                        stepStatus: "Waiting",
                                                        stepTitle: "Upload your CSV file",
                                                        stepDescription: "Please upload your CSV file into the system"
                                                    },
                                                    {
                                                        step: "map",
                                                        stepStatus: "Waiting",
                                                        stepTitle: "Map Data",
                                                        stepDescription: "Please map your data to the appropriate fields"
                                                    },
                                                    {
                                                        step: "review",
                                                        stepStatus: "Waiting",
                                                        stepTitle: "Review Data",
                                                        stepDescription: "Please review your data"
                                                    },
                                                    {
                                                        step: "confirm",
                                                        stepStatus: "Waiting",
                                                        stepTitle: "Confirm",
                                                        stepDescription: "Please confirm your data"
                                                    },
                                                    {
                                                        step: "finished",
                                                        stepStatus: "Current",
                                                        stepTitle: "Confirm",
                                                        stepDescription: "Please confirm your data"
                                                    },

                                                ]
                                            )
                                            setUploading(false);
                                            setStep('createTemplate');
                                            setTemplateMaker(false);

                                        }
                                        else {
                                       
                                            openSnack('error', 'Something went wrong, please try again');
                                            setUploading(false);
                                        }
                                    }
                                    catch (error) {
                                        console.log(error)
                                        openSnack('error', 'Something went wrong, please try again');
                                        setUploading(false);


                                    }
                                }
                                }
                                style={{
                                    textTransform: 'none',
                                    color: uploading === true ? 'gray' : 'white',
                                    backgroundColor: uploading === true ? '#f2f4f7' : '#3f51b5',
                                    height: 50,
                                    fontWeight: 600,
                                    fontSize: 14,
                                    marginTop: 20
                                }}>
                                Upload {data?.length} rows of data
                            </Button>
                            {uploading ? <Box sx={{ mt: 4, textAlign: 'center' }}>

                                <FontAwesomeIcon icon={faSpinner} spin size="3x" color='#3f51b5' />
                            </Box> : <Button
                                sx={{ mt: 6 }}
                                fullWidth
                                variant="contained"
                                onClick={() => setStep('createTemplate')}
                                style={{
                                    textTransform: 'none',
                                    backgroundColor: '#d9d9d9',
                                    color: 'black',
                                    height: 50,
                                    borderRadius: '5px',
                                    fontSize: 12,
                                    fontWeight: 500


                                }}
                                size="large"

                            >
                                Previous
                            </Button>}



                        </Box>
                    )}


                </>



            </Modal>
        </div >
    );
}

export default TemplateMaker;
