import React, { useState, useEffect } from 'react';
import { DrawingManager } from '@react-google-maps/api';
import './DrawingSystem.css';
const DrawingSystem = ({ userInfo, widgetKey,
    currentLayer, setCurrentLayer,
    currentDrawingLayer, layers, setLayers, handleToggle
}) => {

    const [layer, setLayer] = useState(null);
    const [item, setItem] = useState(null);

    useEffect(() => {
        if (currentDrawingLayer !== null) {
            const layer = layers.find(layer => layer.id === currentLayer);
            setLayer(layer);
            const item = layer?.layerItems?.find(item => item.id === currentDrawingLayer);
            setItem(item);
        }
    }, [currentDrawingLayer, currentLayer, layers]);



    const handleCircleComplete = (circle) => {
        // Extract the relevant properties from the circle object
        const center = circle.getCenter().toJSON();
        const radius = circle.getRadius();

        // Create a new circle object
        const newCircle = {
            type: 'circle',
            center,
            radius,
            color: item?.layerItemAttributes?.layerColor || 'orange', // Change the circle color here
        };

        // Update the drawnObjects array in layerItemAttributes
        handleToggle(layer.id, item.id, {
            layerItemAttributes: {
                ...item.layerItemAttributes,
                drawnObjects: [...(item.layerItemAttributes.drawnObjects || []), newCircle],
            },
        });

        // Remove the circle from the map (optional)
        circle.setMap(null);
    };

    const handlePolygonComplete = (polygon) => {
        const paths = polygon.getPaths().getArray().map((path) => path.getArray().map((latLng) => latLng.toJSON()));

        const newPolygon = {
            type: 'polygon',
            paths,
            color: item?.layerItemAttributes?.layerColor || 'orange',
        };

        handleToggle(layer.id, item.id, {
            layerItemAttributes: {
                ...item.layerItemAttributes,
                drawnObjects: [...(item.layerItemAttributes.drawnObjects || []), newPolygon],
            },
        });

        polygon.setMap(null);
    };

    const handlePolylineComplete = (polyline) => {
        const path = polyline.getPath().getArray().map((latLng) => latLng.toJSON());

        const newPolyline = {
            type: 'polyline',
            path,
            color: item?.layerItemAttributes?.layerColor || 'orange',
        };

        handleToggle(layer.id, item.id, {
            layerItemAttributes: {
                ...item.layerItemAttributes,
                drawnObjects: [...(item.layerItemAttributes.drawnObjects || []), newPolyline],
            },
        });

        polyline.setMap(null);
    };

    const handleMarkerComplete = (marker) => {
        const position = marker.getPosition().toJSON();

        const newMarker = {
            type: 'marker',
            position,
            color: item?.layerItemAttributes?.layerColor || 'orange',
        };

        handleToggle(layer.id, item.id, {
            layerItemAttributes: {
                ...item.layerItemAttributes,
                drawnObjects: [...(item.layerItemAttributes.drawnObjects || []), newMarker],
            },
        });

        marker.setMap(null);
    };

    return currentDrawingLayer !== null ? (
        <DrawingManager
            className="custom-drawing-toolbar"
            onCircleComplete={handleCircleComplete}
            onPolygonComplete={handlePolygonComplete}
            onPolylineComplete={handlePolylineComplete}
            onMarkerComplete={handleMarkerComplete}

            options={{
                drawingControl: true,
                drawingControlOptions: {
                    position: window.google.maps.ControlPosition.LEFT_CENTER,
                    drawingModes: [
                        window.google.maps.drawing.OverlayType.MARKER,
                        window.google.maps.drawing.OverlayType.POLYLINE,
                        window.google.maps.drawing.OverlayType.POLYGON,
                        window.google.maps.drawing.OverlayType.CIRCLE,
                    ]
                },
                markerOptions: {
                    icon: {
                        fillColor: item?.layerItemAttributes?.layerColor || 'orange', // Change the marker color here
                    },
                },
                polylineOptions: {
                    strokeColor: item?.layerItemAttributes?.layerColor || 'orange', // Change the polyline color here
                    strokeWeight: 2,
                },
                polygonOptions: {
                    fillColor: item?.layerItemAttributes?.layerColor || 'orange', // Change the polygon fill color here
                    fillOpacity: 0.5,
                    strokeColor: item?.layerItemAttributes?.layerColor || 'orange', // Change the polygon stroke color here
                    strokeWeight: 2,
                },
                circleOptions: {
                    fillColor: item?.layerItemAttributes?.layerColor || 'orange', // Change the circle fill color here
                    fillOpacity: 0.5,
                    strokeColor: item?.layerItemAttributes?.layerColor || 'orange', // Change the circle stroke color here
                    strokeWeight: 2,
                },
            }
            }
        />
    )
        : null;
};

export default DrawingSystem;
