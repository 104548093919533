'use strict';
import { Button, Divider, Typography, TextField, Box, OutlinedInput, IconButton } from '@mui/material';
import { Col, Row, Modal, Skeleton } from 'antd';
import React, { forwardRef, memo, useEffect, useImperativeHandle, useMemo, useRef, useState, useCallback } from 'react';
import { fullMobile } from '../../util';

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import moment from 'moment';
import MUIDataTable from "mui-datatables";
import DatePicker from "react-datepicker";
import uuid from 'react-uuid';
import { queryParameters, querySites, queryStations, queryLogData } from '../../../../firebase/config';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, } from 'firebase/firestore';
import './tableStyle.css';
import "react-datepicker/dist/react-datepicker.css";
import SiteModal from '../../../../waterQuality/samplingSites/siteModal';
import Units from '../../../../extra/units.json';
import { DeleteForever, RemoveRedEye, VisibilityOff } from '@mui/icons-material';
import axios, * as others from 'axios';

import ProbeParameters from '../../../../extra/probeParameters.json';

var randomColor = require('randomcolor');


const getMuiTheme = () =>
    createTheme({
        components: {
            MUIDataTable: {
                styleOverrides: {
                    root: {
                        padding: '8px',
                        boxShadow: 'none'
                    }
                }
            },
            MuiTableRow: {
                styleOverrides: {
                    root: {
                        zIndex: 100,
                        cursor: 'pointer',
                        fontSize: '10px',
                        '&:nth-child(even)': {
                            backgroundColor: '#40405024'
                        },
                        "&:hover": {
                            backgroundColor: "#9ec1c933"
                        }


                    },


                }
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        fontSize: '12px',
                        padding: '2px'
                    }
                }
            }

        }
    });




export default function LogWaterQuality(props) {
    const { userInfo, screenWidth, openSnack, company, account, updateLog, logRef, status, stage } = props;
    const [log, setLog] = useState(logRef);
    const [data, setData] = useState([]);
    const [editingRow, setEditingRow] = useState(null);
    const [parameters, setParameters] = useState([]);
    const [sites, setSites] = useState([]);
    const [unitList, setUnitList] = useState([]);
    const [parameterModal, setParameterModal] = useState(false);
    const [siteModal, setSiteModal] = useState(false);
    const [parameterItem, setParameterItem] = useState({});
    const [siteItem, setSiteItem] = useState({});
    const [stations, setStations] = useState([]);
    const [loading, setLoading] = useState(true);
    const inputRef = useRef();
    const [PPArray, setPPArray] = useState([]);


    const stageKey = stage === 'pre' ? 
    log.logKeyPR : stage === 'event' ? 
    log.logKeyEV : log.logKeyPO

    useEffect(() => {
        localStorage.setItem(`${stageKey}waterquality`, JSON.stringify([]))
    }, [])




  





    useEffect(() => {
        if (status === 'built') {

            purgeData()
            console.log('store WQ')
        }
    }, [status])

    useEffect(() => {
        getInfo()
        const unitList = Object.values(Units).map((u, i) => {
            return ({ label: u, key: u, unitid: Object.keys(Units)[i] })
        })
        setUnitList(unitList)

        const PPArray = Object.values(ProbeParameters).map((p, i) => {
            return ({
                label: p,
                key: Object.keys(ProbeParameters)[i]
            })
        });
        setPPArray(PPArray)

    }, [])

    async function getInfo() {
        const parameters = await queryParameters(userInfo)
        setParameters(parameters)
        const sites = await querySites(userInfo)
        setSites(sites)
        const stations = await queryStations(userInfo, ['grab', 'both'])
        setStations(stations)
        if (log.key !== undefined) {

            console.log(stageKey)

            const proxyUrl = "https://mycorslake.herokuapp.com/";
            axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                raw: `SELECT * FROM node_data_new2 WHERE logkey = '${stageKey}';`
            }).then(async (response) => {
                //filter data where key does no include inspection
                const filteredData = response.data.filter((d) => {
                    return d.key.includes('inspection') === false
                })
                //fore each data tags: JSON.parse(row.tags || '[]'),
                filteredData.forEach((row) => {
                    row.tags = row.tags !== undefined ? JSON.parse(row.tags || '[]') : []
                })

                console.log(filteredData)
                console.log(response.data)
                console.log(`${stageKey}waterquality`)
                localStorage.setItem(`${stageKey}waterquality`, JSON.stringify(filteredData))
                setData(filteredData)
                setLoading(false)

            })
                .catch((error) => {

                    localStorage.setItem(`${stageKey}waterquality`, JSON.stringify([]))
                    setData([])
                    setLoading(false)
                });




        }
        else {
            setLoading(false)
        }
    }

    useEffect(() => {
        setLog(logRef)
        if (log.key === undefined && logRef.key !== undefined) {
            purgeData()
        }
    }, [logRef])


    function updateData(key, item, val, database) {
        const index = data.map((e) => e.key).indexOf(key);
        const newData = [...data];
        newData[index][item] = val;
        setData(newData);

        if (log.key === undefined)
        //add newData to local storage
        {
            localStorage.setItem(`${stageKey}waterquality`, JSON.stringify(newData))
        }

        if (database === true) {
            console.log('update database')
            updateDatabase(newData[index])
        }

    }

    //if valueref is unfocused, update the data
    useEffect(() => {

    }, [inputRef.current])

    function purgeData() {
        //remove all rows that are missing any data or have undefined data for parameterid, nodeid, or value
        const newData = data.filter((row) => {
            return row.parameterid !== undefined && row.nodeid !== undefined && row.value !== undefined && row.parameterid !== '' && row.nodeid !== '' && row.value !== ''
        });
        setData(newData);


        const newDataList = newData.map((row) => {
            const station = stations.find((s) => s.id === row.nodeid)
            const lat = station.lat || 0
            const lng = station.lng || 0

            const item = {
                time: `'${row.time}'`,
                timestamp: Number(moment(row.time, "YYYY-MM-DD HH:mm").format("X")),
                locationid: `'${row.nodeid}'`,
                unitid: `'${row.unitid || ''}'`,
                parameterid: `'${row.parameterid}'`,
                value: row.value,
                account: `'${userInfo.currentAccount}'`,
                company: `'${userInfo.currentCompany}'`,
                nodeid: `'${row.nodeid}'`,
                key: `'${uuid()}'`,
                longitude: lng,
                latitude: lat,
                logkey: `'${stageKey}'`,
                label: `''`,
                tags: `'${row.tags !== undefined ? JSON.stringify(row.tags) : '[]'}'`
            }
            return item
        })
        const newDataListLocal = newData.map((row) => {
            const station = stations.find((s) => s.id === row.nodeid)
            const lat = station.lat || 0
            const lng = station.lng || 0

            const item = {
                time: `${row.time}`,
                timestamp: Number(moment(row.time, "YYYY-MM-DD HH:mm").format("X")),
                locationid: `${row.nodeid}`,
                unitid: `${row.unitid || ''}`,
                parameterid: `${row.parameterid}`,
                value: row.value,
                account: `${userInfo.currentAccount}`,
                company: `${userInfo.currentCompany}`,
                nodeid: `${row.nodeid}`,
                key: `${uuid()}`,
                longitude: lng,
                latitude: lat,
                logkey: `${stageKey}`,
                tags: `${row.tags !== undefined ? JSON.stringify(row.tags) : '[]'}`
            }
            return item
        })
        localStorage.setItem(`${stageKey}waterquality`, JSON.stringify(newDataListLocal))


        const hourlyVals = newDataList.map((h) => `(${Object.values(h)})`);
        const hourlyString = hourlyVals.join();

        const proxyUrl = "https://mycorslake.herokuapp.com/";
        axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
            raw: `INSERT INTO node_data_new2 VALUES ${hourlyString} ON CONFLICT DO NOTHING; `
        }).then(async (response) => {
            //change "' to ' and  '" to ' for all values


        }).catch((error) => {

        }
        )



    }

    const gridRef = useRef(); // Optional - for accessing Grid's API

    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: (option) => option.label,
    });

    async function updateDatabase(item) {
        console.log(item)
        if (item !== undefined) {
            console.log(item)
            const proxyUrl = "https://mycorslake.herokuapp.com/";
            axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                raw: `UPDATE node_data_new2 SET 
                nodeid = '${item.nodeid}'
                , parameterid = '${item.parameterid}'
                , value = ${Number(item.value)}
                , unitid = '${item.unitid}'
                , time = '${item.time}'
                , timestamp = ${Number(moment(item.time, "YYYY-MM-DD HH:mm").format("X"))}
                , locationid = '${item.nodeid}'
                , tags = '${item.tags !== undefined ? JSON.stringify(item.tags) : '[]'}'

                WHERE key = '${item.key}'
                ; `
            }).then(async (response) => {
                // handle success
                console.log(response)
            }).catch((error) => {
                console.log(error)
            })
        }
    }


    const options = {
        filter: false,
        searchable: false,
        search: false,
        searchable: false,
        viewColumns: false,
        download: false,
        print: false,
        draggable: true,
        rowsPerPage: 50,
        rowsPerPageOptions: [50, 100, 200, 500, 1000],
        selectableRows: screenWidth === 'xs' ? false : status === 'built' ? false : true,
        filterType: 'dropdown',
        responsive: screenWidth === 'md' ? 'stacked' : 'standard',
        onRowClick: (rowData, rowMeta, b) => {


            const clickedRow = gridRef.current.state.data[rowMeta.dataIndex];
            const editingkey = clickedRow.data[0];
            setEditingRow(editingkey)


        },
        onRowsDelete: (rows, b) => {
            //use rows.lookup to get the index of the rows to be deleted and remove them from data
            const rowsToDelete = Object.keys(rows.lookup);
            const newData = data.filter((row, index) => {
                return !rowsToDelete.includes(index.toString());
            });
            setData(newData);
            const deletedKeys = rowsToDelete.map((index) => data[index].key);
            const proxyUrl = "https://mycorslake.herokuapp.com/";
            axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                raw: `DELETE FROM node_data_new2 
                    WHERE key IN (${deletedKeys.map((e) => `'${e}'`).join(',')});`

            }).then(async (response) => {
                openSnack('success', 'Data deleted!')
            }).catch((error) => {
                openSnack('error', 'Error deleting data!')
            })

        }
    };

    const columns = [

        {
            name: 'key',
            label: "Edit",

            options: {
                //hide column
                display: false,
                filter: false,
                sort: false,
                empty: true,

            }
        },
        {
            name: "time",
            label: 'Date',
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const key = tableMeta.rowData[0];
                    const editing = key === editingRow;
                    const index = data.map((e) => e.key).indexOf(key);
                    const time = data[index].time
                    if (editing && status !== 'built') {

                        return (

                            <DatePicker dateFormat="MM/dd/yyyy hh:mm a" style={{ width: '200px' }} showTimeSelect timeFormat="h:mm a" timeIntervals={1}
                                timeCaption="Time"
                                portalId="root-portal"
                                selected={[undefined, '', "Invalid date"].includes(time) ? new Date() :
                                    new Date(moment(time, 'YYYY-MM-DD HH:mm').format('MM/DD/YYYY hh:mm a'))}
                                onChange={(date) => {

                                    const unix = moment(date).format('x');
                                    updateData(key, 'time', moment(unix, 'x').tz("America/Los_Angeles").format("YYYY-MM-DD HH:mm:ss"), true);
                                    updateData(key, 'timestamp', moment(unix, 'x').format('X'), true);





                                }} />

                        )
                    }

                    else {
                        return (
                            <div>{moment(time, 'YYYY-MM-DD HH:mm').format('MM/DD/YYYY h:mm a')}</div>
                        )
                    }
                }


            }
        },
        {
            name: "parameterid",
            label: "Parameter",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const key = tableMeta.rowData[0];
                    const editing = key === editingRow;
                    if (editing && status !== 'built') {
                        const index = [...PPArray, ...parameters].map((e) => e.key).indexOf(value);
                        const label = index === -1 ? '' : [...PPArray, ...parameters][index].label;

                        return (

                            <Autocomplete
                                style={{ width: 200 }}
                                inputProps={{ style: { width: 200 } }}
                                size="small"
                                id="combo-box-demo"
                                defaultValue={label}
                                disableClearable={true}
                                options={[...PPArray, ...parameters].sort((a, b) => a.label.localeCompare(b.label))}
                                filterOptions={(options, _ref) => [...filterOptions(options, _ref), { label: 'Add New', key: 'Add New', value: 'addNew' },]}
                                noOptionsText={<Button fullWidth variant='contained' color='primary' style={{ textTransform: 'none' }}
                                    onClick={() => {
                                        setParameterModal(true)
                                        setParameterItem({ tempKey: uuid(), depthFromBottom: 0 });
                                    }}
                                >+ Create Parameter</Button>}
                                renderInput={(params) => <TextField {...params} placeholder="Parameters" size="small" />}
                                renderOption={(params) => {

                                    if (params.key === 'Add New') {
                                        return (
                                            <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 4 }}>
                                                <Button fullWidth variant='contained' color='primary' style={{ textTransform: 'none' }} onClick={(e) => {
                                                    setParameterModal(true)
                                                    setParameterItem({ tempKey: uuid(), depthFromBottom: 0 });


                                                }}>+ Create Parameter</Button>
                                            </Box>

                                        )
                                    }
                                    else {
                                        return (
                                            <Typography {...params}>{params.key}</Typography>
                                        )
                                    }
                                }}
                                onChange={(a, d) => {
                                    if (d.key === 'Add New') {
                                        setParameterModal(true)
                                        setParameterItem({ tempKey: uuid(), depthFromBottom: 0 });
                                    }
                                    else {
                                        updateData(key, 'parameterid', d.key, true);
                                        updateData(key, 'parameterLabel', d.label, true);
                                    }

                                }}

                            />



                        )
                    }

                    else {
                        const index = [...PPArray, ...parameters].map((e) => e.key).indexOf(value);
                        const label = index === -1 ? '' : [...PPArray, ...parameters][index].label;
                        return (
                            <div>{label}</div>
                        )
                    }
                }

            }
        },
        {
            name: "nodeid",
            label: "Node",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const key = tableMeta.rowData[0];
                    const editing = key === editingRow;
                    if (editing && status !== 'built') {

                        const index = stations.map((e) => e.id).indexOf(value);

                        const label = index === -1 ? '' : stations[index].label;

                        return (



                            <Autocomplete
                                style={{ width: 200 }}
                                inputProps={{ style: { width: 200 } }}
                                size="small"
                                id="combo-box-demo"
                                disableClearable={true}
                                value={label}
                                options={stations}
                                filterOptions={(options, _ref) => [...filterOptions(options, _ref),]}



                                //render input as id
                                renderInput={(params) => <TextField {...params} placeholder="Nodes" size="small" />}
                                renderOption={(params) => {

                                    return (
                                        <Typography {...params}>{params.key}</Typography>
                                    )
                                }}
                                onChange={(a, d) => {
                                    if (d.key === 'Add New') {
                                        setSiteModal(true)
                                        setSiteItem({ tempKey: uuid(), });
                                    }
                                    else {

                                        updateData(key, 'nodeid', d.id, true);
                                        updateData(key, 'locationLabel', d.label, true);
                                    }

                                }}

                            />
                        )
                    }

                    else {
                        const index = stations.map((e) => e.id).indexOf(value);
                        const label = index === -1 ? '' : stations[index].label;
                        return (
                            <div>{label}</div>
                        )
                    }
                }
            }
        },
        {
            name: "value",
            label: "Value",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {

                    const key = tableMeta.rowData[0];
                    const editing = key === editingRow;
                    if (editing && status !== 'built') {

                        return (

                            <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                inputRef={inputRef}
                                id={`${key}value`}
                                width={80}
                                inputProps={{ style: { width: 100 } }}
                                placeholder="Value"
                                defaultValue={value}
                                type="number"
                                onBlur={(e) => {
                                    updateData(key, 'value', e.target.value === "" ? "" : Number(e.target.value), true)

                                }}
                                onChange={(e) => {
                                    updateData(key, 'value', e.target.value === "" ? "" : Number(e.target.value))
                                }} />
                        )
                    }

                    else {
                        return (
                            <div>{value}</div>
                        )
                    }
                }

            }
        },
        {
            name: "unitid",
            label: "Units",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {


                    const key = tableMeta.rowData[0];
                    const editing = key === editingRow;
                    if (editing && status !== 'built') {
                        const index = unitList.map((e) => e.key).indexOf(value);
                        const label = index === -1 ? '' : unitList[index].label;
                        return (

                            <Autocomplete
                                size="small"
                                id="combo-box-demo"
                                disableClearable={false}
                                value={label}
                                options={unitList}
                                renderInput={(params) => <TextField {...params} placeholder="Units" size="small" />}
                                renderOption={(params) => {
                                    return (
                                        <Typography {...params}>{params.key}</Typography>
                                    )
                                }}

                                onChange={(a, d) => {
                                    if (d) {
                                        updateData(key, 'unitid', d.key, true);
                                    }
                                    else {
                                        updateData(key, 'unitid', '', true);
                                    }

                                }}
                            />



                        )
                    }

                    else {
                        const index = unitList.map((e) => e.key).indexOf(value);
                        const label = index === -1 ? '' : unitList[index].label;
                        return (
                            <div>{label}</div>
                        )
                    }
                }
            }
        },






    ];


    const AddRowButton = memo(() => {
        const [date, setDate] = useState(new Date());
        const [parameter, setParameter] = useState('');
        const [location, setLocation] = useState('');
        const [value, setValue] = useState('');
        const [unit, setUnit] = useState('');
        const [tags, setTags] = useState([]);

        const handleAddRow = () => {
            const newDate = moment(date).format('YYYY-MM-DD HH:mm');
            const newData = [...data, { key: uuid(), tags: tags, time: newDate, parameterid: parameter, locationid: location, value: value, unitid: unit, editing: false, logkey: stageKey }];
            setData(newData);
            setParameter('');
            setLocation('');
            setValue('');
            setUnit('');
            setTags([]);
            setDate(new Date());
        }

        return (
            <Button size="small"
                variant="contained" color="primary"
                style={{ textTransform: 'none', fontSize: 14, backgroundColor: '#404050' }}
                onClick={handleAddRow} >+ Add Row</Button >
        )
    })


    const CreateParameter = ({ parameterItem, userInfo, account }) => {
        return (
            <Button size="small" style={{ padding: 10, borderRadius: 4, textTransform: 'none', fontSize: 14, }}
                variant='contained' color="primary"
                onClick={async () => {
                    const db = getFirestore()
                    const currentItem = parameterItem;
                    currentItem.name = parameterItem.name || "";
                    const params = data.map((d) => d.name);
                    const checks = [
                        currentItem.name.length === 0 ? false : true,
                        params.includes(currentItem.name) ? false : true,
                    ]
                    if (currentItem.name.length === 0) {
                        this.props.openSnack('error', "Parameter name required!")
                    }
                    if (params.includes(currentItem.name)) {
                        this.props.openSnack('error', "Parameter name already exists!")
                    }
                    if (!checks.includes(false)) {

                        const key = currentItem.key || currentItem.tempKey;
                        currentItem.archived = currentItem.archived || false;

                        currentItem.account = userInfo.currentAccount;
                        currentItem.company = userInfo.currentCompany;


                        const docRef = doc(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'parameters', key);

                        if (currentItem.key) {
                            await updateDoc(docRef, currentItem).then(() => {
                                openSnack('success', currentItem.key ? 'Parameter updated!' : "Parameter created!")
                            })
                        }
                        else {
                            await setDoc(docRef, {
                                ...currentItem,
                                key: currentItem.tempKey
                            }).then(async () => {
                                const parameters = await queryParameters(userInfo);
                                setParameters(parameters);
                                openSnack('success', currentItem.key ? 'Parameter updated!' : "Parameter created!")
                                setParameterModal(false)
                            })
                        }


                    }




                }}>{parameterItem.key ? 'Update Parameter' : "Create Parameter"}</Button>
        )
    }


    function EnterClick(e) {
        //if (e.key === 'Enter') change editing row to null
        if (e.key === 'Enter') {
            setEditingRow(null);
        }

        //if (e.key === 'Escape') change editing row to null
        if (e.key === 'Escape') {

            setEditingRow(null);
        }

    }


    return (
        <Row gutter={[12, 12]} align="middle" >
            < EnterClick />
            <Col {...fullMobile(12, 12)} style={{ paddingTop: 30 }}>
                <Typography display="inline" variant='h5' fontWeight={500} fontSize={screenWidth === 'xs' ? 16 : 22}>Water Quality</Typography>
                {status === 'creating' && (<IconButton display="inline" size="small" onClick={() => {
                    updateLog('hideWQ', !log.hideWQ)
                }}>
                    {log.hideWQ ? <VisibilityOff /> : <RemoveRedEye />}
                </IconButton>)}
                <Typography display="inline" variant='body1' fontWeight={300} fontSize={14}>
                    {stage === 'pre' && ' - Pre-Application Monitoring'}
                    {stage === 'post' && ' - Post-Application Monitoring'}
                    {stage === 'event' && ' - Event Monitoring '}
                </Typography>

            </Col>
            <Col {...fullMobile(12, 12)} style={{ paddingTop: 30, textAlign: 'right' }}>
                {status !== 'built' && (<AddRowButton />)}


            </Col>
            {loading && (
                <Col {...fullMobile(24, 24)} style={{ paddingTop: 10, }}>
                    <Skeleton width={400} height={100} sx={{ animationDuration: "0.3s" }} />
                </Col>
            )}

            {!log.hideWQ && data.length > 0 && (

                <Col onKeyPress={EnterClick} id="table" {...fullMobile(24, 24)} style={{ paddingTop: 10, position: 'relative', zIndex: 10 }}>
                    <ThemeProvider theme={getMuiTheme()}>
                        <MUIDataTable ref={gridRef} data={data} columns={screenWidth === 'xs' ? [...columns, {
                            name: 'key',
                            label: "Delete",
                            options: {
                                filter: true,
                                sort: false,
                                empty: true,
                                customBodyRender: (value, b, updateValue) => {
                                    if (status === 'creating') {
                                        return (
                                            <div>
                                                <IconButton size="small" aria-label="delete" color={'inherit'} onClick={() => {
                                                    //delete this item from data
                                                    const index = data.map((e, i) => e.key).indexOf(value);
                                                    const item = data[index];

                                                    const deletedKeys = [item.key];
                                                    const rowsToDelete = [item.key];
                                                    const newData = data.filter((e) => !rowsToDelete.includes(e.key));
                                                    setData(newData);
                                                    const proxyUrl = "https://mycorslake.herokuapp.com/";
                                                    axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                                                        raw: `DELETE FROM node_data_new2 
                    WHERE key IN (${deletedKeys.map((e) => `'${e}'`).join(',')});`

                                                    }).then(async (response) => {
                                                        openSnack('success', 'Data deleted!')
                                                    }).catch((error) => {
                                                        openSnack('error', 'Error deleting data!')
                                                    })



                                                }}>
                                                    <DeleteForever />
                                                </IconButton>

                                            </div>

                                        )
                                    }
                                    else {
                                        return (
                                            <div></div>
                                        )
                                    }
                                }
                            }
                        },] : columns} options={options} />
                    </ThemeProvider>

                </Col>

            )}

            <Col {...fullMobile(24, 24)}>
                <Divider style={{
                    borderColor: 'rgb(148, 186, 195)',
                    borderBottomWidth: 'medium',

                }} />
            </Col>

            <Modal title="" visible={parameterModal} onCancel={() => {
                setParameterModal(false)
                setParameterItem({})

            }} footer={null} width={'60%'} style={{ borderRadius: 40 }} >
                <Row align="middle" gutter={[16, 16]} >
                    <Col xs={{ offset: 0, span: 24 }} sm={{ offset: 6, span: 18 }} style={{ paddingBottom: 12, }}>
                        <Typography variant='h5'>Parameter</Typography>
                    </Col>
                    <Col xs={{ offset: 0, span: 24 }} sm={{ offset: 6, span: 18 }} style={{ paddingBottom: 12, }}>
                        <Typography fontWeight={300} variant='body1'>Reminder, you will be able to associate all water quality samples with either the parameter ID or parameter name.
                            The parameter name will be associated as case sensitive for all uploaded CSV files.</Typography>
                    </Col>


                    <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                        <Typography variant="body1">Name<span style={{ color: 'red' }}>*</span></Typography>
                    </Col>
                    <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                        <OutlinedInput fullWidth notched={false} label="none" placeholder={"Name"} size="small"
                            value={parameterItem?.name || ''}
                            onChange={(e) => {
                                setParameterItem({ ...parameterItem, name: e.target.value })


                            }} />
                    </Col>






                    < Col xs={{ offset: 0, span: 24 }} sm={{ offset: 6, span: 18 }} style={{ paddingBottom: 12, }}>
                        <CreateParameter parameterItem={parameterItem} userInfo={userInfo} account={account} />
                    </Col>





                </Row>
            </Modal >



        </Row>
    )



}