import { Autocomplete, Button, Checkbox, Collapse, Divider, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material';
import { Row, Col } from 'antd';
import React, { useEffect, useState } from 'react';
import { ReactSortable } from 'react-sortablejs';
import uuid from 'react-uuid';
import { fullMobile } from '../../util';
import AddIcon from '@mui/icons-material/Add';
import { DeleteForever, DragIndicator } from '@mui/icons-material';
import { faVial, faLocationCrosshairs, faFillDrip, faChartArea, faChartBar, faChartLine, faChartColumn, faGear } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataColorCompact from '../settings/dataColorCompact';
import moment from 'moment-timezone';
import DatePicker from "react-datepicker";

var randomColor = require('randomcolor');

export default function ChartSettings(props) {
    const { userInfo, screenWidth, openSnack, widgetRef, updateCurrentWidget, nodes, PPArray, parameters, WPArray, references } = props;
    const [widget, setWidget] = useState(widgetRef);





    useEffect(() => {
        console.log(widgetRef)
    }, [])
    useEffect(() => {
        setWidget(widgetRef)
    }, [widgetRef])






    return (
        <Row gutter={[4, 4]}
        >

            <Col {...fullMobile(24, 24)}  >
                <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                    Share Axis
                </Typography>
                <Checkbox
                    checked={widget.shareAxis}
                    onChange={(e) => {
                        updateCurrentWidget({ ...widget, shareAxis: e.target.checked })
                    }}
                />

            </Col>
            <Col {...fullMobile(24, 24)} >
                <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                    Show Legend
                </Typography>
                <Checkbox
                    checked={widget.legend}
                    onChange={(e) => {
                        updateCurrentWidget({ ...widget, legend: e.target.checked })
                    }}
                />

            </Col>
            <Col >
                <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                    Legend Font Size
                </Typography>
                <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                    defaultValue={widget.legendFontSize}
                    type="number"
                    style={{ width: 150 }}
                    inputProps={{ min: 0 }}
                    onBlur={(e) => {
                        updateCurrentWidget({ ...widget, legendFontSize: e.target.value === "" ? "" : Number(e.target.value) })
                    }} />
            </Col>
            <Col >
                <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                    X-Axis Font Size
                </Typography>
                <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                    defaultValue={widget.xAxisFontSize || 14}
                    type="number"
                    style={{ width: 150 }}
                    inputProps={{ min: 0 }}
                    onBlur={(e) => {
                        updateCurrentWidget({ ...widget, xAxisFontSize: e.target.value === "" ? "" : Number(e.target.value) })
                    }} />
            </Col>
            <Col >
                <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                    X-Axis Label Angle
                </Typography>
                <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                    defaultValue={widget.xAxisLabelAngle || 0}
                    type="number"
                    style={{ width: 150 }}
                    onBlur={(e) => {
                        updateCurrentWidget({ ...widget, xAxisLabelAngle: e.target.value === "" ? "" : Number(e.target.value) })
                    }} />
            </Col>
            <Col {...fullMobile(24, 24)} >
                <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                    X-Axis Padding
                </Typography>
                <Checkbox
                    checked={widget.xPadding}
                    onChange={(e) => {
                        updateCurrentWidget({ ...widget, xPadding: e.target.checked })
                    }}
                />

            </Col>
            <Col >
                <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                    Date Interval
                </Typography>
                <Select size="small" fullWidth
                    notched={false}
                    style={{ width: 200 }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={widget.dateInterval || 'auto'}
                    label="Dash"
                    onChange={(e) => {
                        updateCurrentWidget({ ...widget, dateInterval: e.target.value })
                    }}

                >
                    <MenuItem value={'auto'}>Auto</MenuItem>
                    <MenuItem value={'hourly'}>Hourly</MenuItem>
                    <MenuItem value={'daily'}>Daily</MenuItem>
                    <MenuItem value={'weekly'}>Weekly</MenuItem>
                    <MenuItem value={'biweekly'}>Bi-Weekly</MenuItem>
                    <MenuItem value={'monthly'}>Monthly</MenuItem>
                    <MenuItem value={'bimonthly'}>Bi-Monthly</MenuItem>
                    <MenuItem value={'semiannually'}>Semi Annuall</MenuItem>
                    <MenuItem value={'quarterly'}>Quarterly</MenuItem>
                    <MenuItem value={'yearly'}>Yearly</MenuItem>
                </Select>
            </Col>
            <Col >
                <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                    Date Format
                </Typography>
                <Typography variant="subtitle2" style={{ fontWeight: 400, fontSize: 11 }}>
                    Options: millisecond: '%H:%M:%S.%L',
                    second: '%H:%M:%S',
                    minute: '%H:%M',
                    hour: '%H:%M',
                    day: '%e. %b',
                    week: '%e. %b',
                    month: '%b \'%y',
                    year: '%Y
                </Typography>
                <Typography variant="subtitle2" style={{ fontWeight: 400, fontSize: 11 }}>
                   Example: %Y-%m-%d
                </Typography>
                <Typography variant="subtitle2" style={{ fontWeight: 400, fontSize: 11 }}>
                   Leave blank for default
                </Typography>
                <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                    defaultValue={widget.dateFormat}
                    style={{ width: 150 }}
                    onBlur={(e) => {
                        updateCurrentWidget({ ...widget, dateFormat: e.target.value })
                    }} />
            </Col>









        </Row>
    );
}