import React, { useState } from "react";


import { faCheckCircle, faCircle } from '@fortawesome/free-regular-svg-icons';
import { faArrowRightFromBracket, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Button, Checkbox, Drawer, IconButton, Input, MenuItem, OutlinedInput, Select, Tooltip, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { Col, Row } from 'antd';
import moment from 'moment-timezone';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TaskColor from "../taskList/taskColor";
import DateSelectComponent from "./drawercomponents/dateSelectComponent";
import SiteSelect from "./drawercomponents/siteSelect";
import StaffSelect from "./drawercomponents/staffSelect";
import TaskDrawerMore from "./taskDrawerMore";
import './taskList.css';

import SendIcon from '@mui/icons-material/Send';
import CommentsSection from "./drawercomponents/comments";
import CreateCustomHeader from "./drawercomponents/createCustomHeader";
import CustomHeadersSetup from "./drawercomponents/customHeadersSetup";
import ColorSelect from "./drawercomponents/colorSelect";
const useStyles = makeStyles({
    underline: {
        "&:before": {
            borderBottom: "none", // Remove default underline
        },
        "&:after": {
            borderBottom: "none", // Remove default underline when focused
        },
        "&:hover:before": {
            borderBottom: "1px solid rgba(0, 0, 0, 0.42)", // Add underline on hover
        },
    },
    commentBox: {
        "&:before": {
            borderBottom: "none", // Remove default underline
        },
        "&:after": {
            borderBottom: "none", // Remove default underline when focused
        },
        "&:hover:before": {
            borderBottom: "none", // Add underline on hover
        },
    },
});

const TaskDrawer = (
    {
        staff,
        accounts,
        taskDrawer,
        setTaskDrawer,
        screenWidth,
        currentTask,
        setCurrentTask,
        items,
        setItems,
        userInfo,
        openSnack,
        createTask,
        updateTask,
        duplicateTask,
        deleteTask,
        convertToTemplate,
        headers,
        setHeaders,
        taskTemplates,
        setTaskTemplates,
        stocks,
        setStocks,
    }

) => {
    const [newItem, setNewItem] = useState(false);
    const [newItemType, setNewItemType] = useState('product');
    const [itemModal, setItemModal] = useState(false);
    const [openMorePopover, setOpenMorePopover] = useState(false);
    const [anchoreMoreEl, setAnchorMoreEl] = useState(null);
    const [validationErrors, setValidationErrors] = useState({});
    const [comment, setComment] = useState("");
    const [deleteDialog, setDeleteDialog] = useState(false);
    const classes = useStyles();





    const validateForm = () => {
        const errors = {};

        // Validate title
        if (!currentTask?.title) {
            errors.title = 'Title is required';
            openSnack('error', 'Title is required');
        }

        // Validate recurring tasks
        if (currentTask?.recurring) {
            // Validate startDate
            if (!currentTask?.startDate) {
                errors.startDate = 'Start Date is required for recurring tasks';
                openSnack('error', 'Start Date is required for recurring tasks');
            }

            // Validate endRecurringDate
            if (!currentTask?.endRecurringDate || moment(currentTask?.endRecurringDate, 'x').isSameOrBefore(moment())) {
                errors.endRecurringDate = 'End Recurring Date must be a future date for recurring tasks';
                openSnack('error', 'End Recurring Date must be a future date for recurring tasks');
            }
        }

        setValidationErrors(errors);
        return Object.keys(errors).length === 0; // return true if no errors
    };



    const updateComment = (commentIndex, updatedText) => {
        // Clone the current task to avoid mutating state directly
        const taskClone = { ...currentTask };

        // Check if comment exists at the specified index
        if (taskClone.comments && taskClone.comments[commentIndex]) {
            // Update the comment text
            taskClone.comments[commentIndex].text = updatedText;
            taskClone.comments[commentIndex].lastEdited = moment().format('x');
        }

        // Update the current task state
        setCurrentTask(taskClone);
    };


    const deleteComment = (commentIndex) => {
        // Clone the current task to avoid mutating state directly
        const taskClone = { ...currentTask };

        // Check if comment exists at the specified index and remove it
        if (taskClone.comments && taskClone.comments[commentIndex]) {
            taskClone.comments.splice(commentIndex, 1);
        }

        // Update the current task state
        setCurrentTask(taskClone);
    };

    const createComment = (newComment) => {
        // Clone the current task to avoid mutating state directly
        const taskClone = { ...currentTask };
        newComment.createdAt = moment().format('x');
        newComment.author = userInfo?.id;
        newComment.authorName = userInfo?.firstName + ' ' + userInfo?.lastName;
        newComment.authorInitial = userInfo?.firstName.charAt(0).toUpperCase();
        newComment.lastEdited = moment().format('x');


        // If comments array doesn't exist, initialize it
        if (!taskClone.comments) {
            taskClone.comments = [];
        }

        // Add the new comment to the array
        taskClone.comments.push(newComment);

        // Update the current task state
        setCurrentTask(taskClone);
    };




    const handleSubmit = () => {
        if (validateForm()) {
            if (currentTask?.id === undefined) {
                createTask(currentTask);
            } else {
                updateTask(currentTask);
            }
        }
    };

    const firstLetterUser = userInfo?.firstName?.charAt(0).toUpperCase();

    const customHeaders = headers?.filter((header) => header.customHeader === true);

    const handleClickOpen = () => {
        setDeleteDialog(true);
    };

    const handleClose = () => {
        setDeleteDialog(false);
    };


    return (
        <Drawer
            anchor={'right'}
            onClose={() => {
                updateTask(currentTask)
                setComment('')
            }}
            open={taskDrawer !== false}


        >
            <div
                style={{
                    height: '100vh',
                    position: 'relative',

                    width: screenWidth === 'xs' ? '90vw' : '40vw',
                }}
            >
                <div
                    style={{
                        padding: 10,
                        paddingTop: 15,
                        paddingBottom: 5,
                        boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.55)',
                        height: '10vh',
                        display: 'flex',
                        width: '100%',
                        alignContent: 'center',
                        alignItems: 'center',

                    }}
                >
                    <Row
                        align={'bottom'}
                        style={{
                            width: '100%',
                            display: 'flex',
                            //align items to the bottom of the row


                        }}>
                        <Col span={12}>
                            <Button color={currentTask?.completed ? 'primary' : 'inherit'} variant={currentTask?.completed ? 'contained' : 'outlined'}
                                style={{
                                    height: 40,
                                    textTransform: 'none',
                                    backgroundColor: currentTask?.completed ? '#3f51b5' : 'white',
                                }}
                                onClick={() => {
                                    setCurrentTask({ ...currentTask, completed: !currentTask?.completed, completedBy: userInfo?.id, completedOn: moment().format('x') })
                                }}>
                                <FontAwesomeIcon size="lg" icon={currentTask?.completed ? faCheckCircle : faCircle} style={{ color: currentTask?.completed ? 'white' : 'black', marginRight: 10 }} />

                                {currentTask?.completed ? 'Completed' : 'Mark Completed'}
                            </Button>
                        </Col>
                        {currentTask?.id !== undefined && (
                            <Col xs={12} style={{
                                textAlign: 'right',
                                marginTop: 10,
                                display: 'inline'
                            }} >


                                <IconButton
                                    onClick={() => {
                                        handleClickOpen()
                                    }}
                                >
                                    <Tooltip title="Delete Task" placement="bottom">
                                        <FontAwesomeIcon size="sm" icon={faTrash} style={{ color: 'rgba(0,0,0,0.5)', marginRight: 8 }} />
                                    </Tooltip>

                                </IconButton>

                                <TaskDrawerMore
                                    duplicateTask={duplicateTask}
                                    currentTask={currentTask}
                                    convertToTemplate={() => convertToTemplate(currentTask)}

                                />
                                <IconButton
                                    onClick={() => {
                                        setCurrentTask(null)
                                        setTaskDrawer(false)
                                    }}
                                >
                                    <Tooltip title="Close" placement="bottom">
                                        <FontAwesomeIcon size="sm" icon={faArrowRightFromBracket} style={{ color: 'rgba(0,0,0,0.5)', }} />
                                    </Tooltip>

                                </IconButton>


                            </Col>
                        )}

                        <Dialog
                            open={deleteDialog}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                Are you sure you want to delete this task?
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Deleting these tasks will remove them from calendar as well.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button style={{
                                    textTransform: 'none',
                                }} onClick={handleClose}>Cancel</Button>
                                <Button
                                    style={{
                                        fontWeight: 600,
                                        textTransform: 'none',
                                        color: 'red'
                                    }}

                                    onClick={() => {
                                        deleteTask(currentTask)
                                        handleClose();
                                    }}
                                    autoFocus>
                                    Delete
                                </Button>
                            </DialogActions>
                        </Dialog>


                    </Row>




                </div>
                <div
                    style={{
                        padding: 15,

                        height: '60vh',
                        display: 'flex',
                        width: '100%',

                        overflowY: 'auto',
                        overflowX: 'hidden',
                    }}
                >
                    <Row gutter={[4, 4]} style={{
                        paddingBottom: 20,
                    }}>
                        <Col span={24}>



                            <Input
                                maxRows={4}
                                multiline
                                fullWidth
                                placeholder="Enter Task Name"
                                style={{
                                    fontWeight: 600,
                                    fontSize: 28,
                                    color: "black",
                                }}
                                notched={false}
                                label="none"
                                defaultValue={currentTask?.title || ""}
                                onChange={(e) => setCurrentTask({ ...currentTask, title: e.target.value })}
                                classes={{ underline: classes.underline }} // Apply custom styles here
                            />
                        </Col>
                       
                        <Col xs={24} style={{ paddingTop: 15, display: 'inline' }}>
                            <Row align="middle" gutter={[12, 12,]}>
                                <Col xs={7} sm={4}>
                                    <Typography variant='body1' fontWeight={500} color={'rgba(0,0,0,0.6)'} fontSize={13} >Site</Typography>
                                </Col>
                                <Col xs={17} sm={20}>
                                    <SiteSelect accounts={accounts} currentTask={currentTask} setCurrentTask={setCurrentTask} headerKey={"site"} />

                                </Col>
                            </Row>
                        </Col>




                        <Col span={24} style={{ paddingTop: 15 }}>
                            <Row align="middle" gutter={[12, 12,]}>
                                <Col xs={7} sm={4}>
                                    <Typography variant='body1' fontWeight={500} color={'rgba(0,0,0,0.6)'} fontSize={13}>
                                        {currentTask?.differentEndDate ? 'Start Date' : 'Date'}
                                    </Typography>
                                </Col>
                                <Col xs={17} sm={20}>
                                    <Row>
                                        <Col span={20}>
                                            <DateSelectComponent currentTask={currentTask} setCurrentTask={setCurrentTask} headerKey="startDate" />
                                        </Col>
                                        <Col span={4} style={{ textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
                                            <Typography variant='body1' fontWeight={600} fontSize={10} style={{ paddingBottom: 0 }}>End Date</Typography>

                                            <Checkbox checked={currentTask?.differentEndDate} style={{ paddingTop: 0 }} onChange={(e) => {
                                                if (e.target.checked) {
                                                    setCurrentTask({ ...currentTask, differentEndDate: true })
                                                }
                                                else {
                                                    setCurrentTask({ ...currentTask, differentEndDate: null })
                                                }
                                            }
                                            } />
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>
                        </Col>
                        {currentTask?.differentEndDate && (
                            <Col span={24} style={{ paddingTop: 15 }}>
                                <Row align="middle" gutter={[12, 12,]}>
                                    <Col xs={7} sm={4}>
                                        <Typography variant='body1' fontWeight={500} color={'rgba(0,0,0,0.6)'} fontSize={13}>End Date</Typography>
                                    </Col>
                                    <Col xs={17} sm={20}>
                                        <Row>
                                            <Col span={20}>
                                                <DatePicker portalId="root-portal"
                                                    showTimeSelect
                                                    timeFormat="h:mm a"
                                                    timeIntervals={30}
                                                    timeCaption="time"
                                                    dateFormat="MMMM d, yyyy h:mm a" selected={currentTask?.endDate ? new Date(moment(currentTask.endDate, 'x')) : ''} onChange={(date) => {
                                                        currentTask.endDate = moment(date).format('x');
                                                        setCurrentTask({ ...currentTask })

                                                    }} />
                                            </Col>

                                        </Row>

                                    </Col>
                                </Row>
                            </Col>
                        )}

                        {currentTask?.id === undefined && (
                            <Col span={24} style={{ paddingTop: 15 }}>
                                <Row align="middle" gutter={[12, 12,]}>
                                    <Col xs={7} sm={4}>
                                        <Typography variant='body1' fontWeight={500} color={'rgba(0,0,0,0.6)'} fontSize={13}>Recurring</Typography>
                                    </Col>
                                    <Col xs={17} sm={20}>
                                        <Select
                                            fullWidth
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            size="small"
                                            label="none"
                                            notched={false}
                                            value={currentTask?.recurring || ''}
                                            onChange={(e) => {
                                                setCurrentTask({ ...currentTask, recurring: e.target.value })
                                            }}>
                                            {["One Time", "Daily", "Weekly", "Monthly", 'Quarterly', "Yearly"].map((f) => {
                                                return (
                                                    <MenuItem value={f}>{f}</MenuItem>
                                                )
                                            })}


                                        </Select>
                                    </Col>
                                </Row>
                            </Col>
                        )}
                        {currentTask?.id === undefined && currentTask?.recurring && (
                            <Col span={24} style={{ paddingTop: 15 }}>
                                <Row align="middle" gutter={[12, 12,]}>
                                    <Col xs={7} sm={4}>
                                        <Typography variant='body1' fontWeight={500} color={'rgba(0,0,0,0.6)'} fontSize={13}>End Recurring</Typography>
                                    </Col>
                                    <Col xs={17} sm={20}>
                                        <Row>
                                            <Col span={20}>
                                                <DatePicker portalId="root-portal"
                                                    showTimeSelect
                                                    timeFormat="h:mm a"
                                                    timeIntervals={30}
                                                    timeCaption="time"
                                                    dateFormat="MMMM d, yyyy h:mm a" selected={currentTask?.endRecurringDate ? new Date(moment(currentTask.endRecurringDate, 'x')) : ''
                                                    } onChange={(date) => {
                                                        currentTask.endRecurringDate = moment(date).format('x');
                                                        setCurrentTask({ ...currentTask })

                                                    }} />
                                            </Col>

                                        </Row>

                                    </Col>
                                </Row>
                            </Col>
                        )}

                        <Col span={24} style={{ paddingTop: 15 }}>
                            <Row align="middle" gutter={[12, 12,]}>
                                <Col xs={7} sm={4}>
                                    <Typography variant='body1' fontWeight={500} color={'rgba(0,0,0,0.6)'} fontSize={13}>Color</Typography>
                                </Col>
                                <Col xs={17} sm={20}>
                                    <ColorSelect currentTask={currentTask} setCurrentTask={setCurrentTask} headerKey="color" type="drawer" />
                                </Col>
                            </Row>
                        </Col>

                        <Col span={24} style={{ paddingTop: 15 }}>
                            <Row align="middle" gutter={[12, 12,]}>
                                <Col xs={7} sm={4}>
                                    <Typography variant='body1' fontWeight={500} color={'rgba(0,0,0,0.6)'} fontSize={13}>Add Custom</Typography>
                                </Col>
                                <Col xs={17} sm={20}>
                                    <CreateCustomHeader
                                        screenWidth={screenWidth}
                                        headers={headers}
                                        setHeaders={setHeaders}
                                        currentTask={currentTask}
                                        setCurrentTask={setCurrentTask}
                                        userInfo={userInfo}
                                        openSnack={openSnack} />
                                </Col>
                            </Row>
                        </Col>





                        <CustomHeadersSetup customHeaders={customHeaders} setCurrentTask={setCurrentTask} currentTask={currentTask}
                            accounts={accounts}
                            stocks={stocks}
                            staff={staff}
                            items={items}
                            setItems={setItems}

                            setStocks={setStocks}

                            userInfo={userInfo}
                        />




                        <Col span={24} style={{ paddingTop: 15, paddingBottom: 40, }}>
                            <Row align="middle" gutter={[12, 12,]}>
                                <Col xs={24} sm={24}>
                                    <Typography variant='body1' fontWeight={500} color={'rgba(0,0,0,0.6)'} fontSize={13}>Description</Typography>
                                </Col>
                                <Col xs={24} sm={24}>
                                    <OutlinedInput value={currentTask?.description || ''}
                                        style={{
                                            fontWeight: 500,
                                            fontSize: 14,
                                        }}

                                        onChange={(e) => {
                                            setCurrentTask({ ...currentTask, description: e.target.value })
                                        }}
                                        fullWidth placeholder='Description' notched={false} label="none" multiline={true} rows={3} />
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24} style={{ paddingTop: 15, paddingBottom: 40, }}>
                            <Row align="middle" gutter={[12, 12,]}>
                                <Col xs={24} sm={24}>
                                    <Typography variant='body1' fontWeight={500} color={'rgba(0,0,0,0.6)'} fontSize={13}>Comments</Typography>
                                </Col>
                                <Col xs={24} sm={24}>
                                    <CommentsSection
                                        comments={currentTask?.comments}
                                        setCurrentTask={setCurrentTask}
                                        currentTask={currentTask}
                                        updateComment={updateComment}
                                        deleteComment={deleteComment}

                                    />
                                </Col>
                            </Row>
                        </Col>




                    </Row>
                </div>
                <div
                    style={{
                        padding: 15,
                        height: '30vh',
                        width: '100%',
                        boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.55)',
                        backgroundColor: '#f9f8f8'
                    }}
                >
                    <div style={{ width: '100%' }}>
                        <Typography variant='body1' fontWeight={600} color={'rgba(0,0,0,0.6)'} fontSize={18}>Comments</Typography>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault(); // Prevent the default form submission behavior

                                // Call your submit function here
                                createComment({ text: comment });
                                setComment("");
                            }}
                            style={{
                                marginTop: 10,
                                width: '100%',
                                display: 'inline-flex',
                            }}
                        >
                            <Avatar sx={{
                                bgcolor: '#3f51b5',
                                marginRight: 1,
                            }}>
                                {firstLetterUser}
                            </Avatar>
                            <OutlinedInput
                                maxRows={4}
                                rows={4}
                                multiline
                                fullWidth
                                placeholder="Add a comment"
                                style={{
                                    padding: 10,
                                    background: 'white',
                                    borderRadius: 5,
                                    fontWeight: 500,
                                    fontSize: 15,
                                    color: "black",
                                }}
                                notched={false}
                                label="none"
                                value={comment}
                                onChange={(e) => {
                                    setComment(e.target.value);
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' && !e.shiftKey) {
                                        e.preventDefault();
                                        createComment({ text: comment });
                                        setComment("");
                                    }
                                }}
                                endAdornment={
                                    comment && (
                                        <IconButton
                                            type="submit" // This button will now submit the form when clicked
                                            size="large"
                                        >
                                            <SendIcon />
                                        </IconButton>
                                    )
                                }
                            />
                        </form>
                    </div>

                    <div
                        style={{
                            marginTop: 10,
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button color={'primary'} variant={'contained'}
                            style={{
                                height: 40,
                                textTransform: 'none',
                                backgroundColor: '#3f51b5'
                            }}
                            onClick={handleSubmit}
                        >
                            {currentTask?.id !== undefined ? 'Update Task' : 'Create Task'}
                        </Button>
                    </div>
                </div>

            </div>







        </Drawer >
    );
};

export default TaskDrawer;
