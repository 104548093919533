import React, { useEffect, useState } from 'react';
import { Box, ListItemButton, ListItemText, Collapse, Grid, ButtonGroup, Button, Typography, Tooltip } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsUpDownLeftRight, faMagnifyingGlassPlus } from '@fortawesome/free-solid-svg-icons';
import { faObjectUngroup, faRectangleXmark, faTrashCan } from '@fortawesome/free-regular-svg-icons';

const SelectTool = ({ toolName, toolLabel,  selectType, setSelectType }) => {
    const [open, setOpen] = useState(true);

    const handleClick = () => {
        setOpen(!open);
    };



    const handleButtonClick = (value) => {
        setSelectType(value); // Update the state to the selected button's value

    };

    return (
        <>
            <ListItemButton onClick={handleClick} sx={{ color: 'white', '&:hover': { bgcolor: 'transparent' }, mx: 0, pl: 1 }}>
                <ListItemText primary={toolLabel} sx={{ color: 'white', fontWeight: 'bold', my: 0, py: 0, '& .MuiListItemText-root': { m: 0, p: 0 }, '& .MuiListItemText-primary': { fontWeight: 800, fontSize: 20 } }} />
                {open ? <ExpandLess color="inherit" /> : <ExpandMore color="inherit" />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Box component="div" sx={{ color: 'white', px: 1, py: 1 }}>
                    <Grid container
                        /* horizontally align items middle */
                        alignItems="center"
                        spacing={1}>
                        <Grid item xs={3}>
                            <Typography variant="body1" sx={{ color: 'white' }}>Tools</Typography>


                        </Grid>
                        <Grid item xs={9} style={{ textAlign: 'right' }}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <ButtonGroup variant="contained" aria-label="Basic button group" sx={{

                                    }}>
                                        <Tooltip title="Zoom" placement="top">
                                            <Button
                                                style={{ backgroundColor: selectType === 'zoom' ? 'black' : '#424242', border: '1px solid #424242' }}
                                                onClick={() => handleButtonClick('zoom')} >
                                                <FontAwesomeIcon icon={faMagnifyingGlassPlus} size="xl" style={{ color: 'white' }} />
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title="Pan" placement="top">
                                            <Button
                                                style={{ backgroundColor: selectType === 'pan' ? 'black' : '#424242', border: '1px solid #424242' }}
                                                onClick={() => handleButtonClick('pan')} >
                                                <FontAwesomeIcon icon={faArrowsUpDownLeftRight} size="xl" style={{ color: 'white' }} />
                                            </Button>
                                        </Tooltip>

                                        <Tooltip title="Select" placement="top">
                                            <Button
                                                style={{ backgroundColor: selectType === 'select' ? 'black' : '#424242', border: '1px solid #424242' }}

                                                onClick={() => handleButtonClick('select')}>
                                                <FontAwesomeIcon icon={faObjectUngroup} size="xl" style={{ color: 'white' }} />
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title="Deselect" placement="top">
                                            <Button
                                                style={{ backgroundColor: selectType === 'deselect' ? 'black' : '#424242', border: '1px solid #424242' }}

                                                onClick={() => handleButtonClick('deselect')}>
                                                <FontAwesomeIcon icon={faRectangleXmark} size="xl" style={{ color: 'white' }} />
                                            </Button>
                                        </Tooltip>
                                        {/*  <Tooltip title="Remove" placement="top">
                                            <Button style={{ backgroundColor: selectedButton === 'remove' ? 'black' : '#424242', border: '1px solid #424242' }}

                                                onClick={() => handleButtonClick('remove')}>
                                                <FontAwesomeIcon icon={faTrashCan} size="xl" style={{ color: 'white' }} />
                                            </Button>
                                        </Tooltip> */}
                                    </ButtonGroup>
                                </Grid>
                                {selectType === 'pan' && (
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle" fontSize={10} sx={{ color: 'white' }}>Click and drag to zoom in. Hold down shift key to pan in both directions.</Typography>
                                    </Grid>
                                )}



                            </Grid>


                        </Grid>
                    </Grid>
                </Box>
            </Collapse>
        </>
    );
};

export default SelectTool;
