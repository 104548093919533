import React, { useEffect, useState } from "react";
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Divider, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import PhotosHeaders from "./photosHeaders";
import AlbumsHome from "./pages/albumsHome";
import TimelineHome from "./pages/photosTimeline";
import { mergeOldAlbumsFunction, mergeOldPhotosFunction } from "./utils/miscFunctions";

const PhotosHomeNew = ({
    screenWidth,
    userInfo,
    account,
    openSnack
}) => {


    const [activeTab, setActiveTab] = useState('albums');
    const [loading, setLoading] = useState(true);
    const [preferences, setPreferences] = useState({});
    const [companyPreferences, setCompanyPreferences] = useState({});


    async function mergeOldPhotos() {
        const mergeAlbums = await mergeOldAlbumsFunction(userInfo, openSnack);
        const mergePhotos = await mergeOldPhotosFunction(userInfo, openSnack);
        console.log(mergeAlbums)
        console.log(mergePhotos)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        getInfo()







    }, [])



    async function getInfo() {

        setTimeout(() => {
            setLoading(false)
        }, 1000);

    }

    const props = {
        screenWidth,
        userInfo,
        account,
        openSnack,
        preferences,
        setPreferences,
        companyPreferences,
        setCompanyPreferences,
        activeTab,
        setActiveTab,


    }




    if (loading) {
        return (
            <Row style={{ height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
                {/* Using the full height of the viewport to center content */}
                <Col span={24} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

                    {/* FontAwesome Spinner */}
                    <FontAwesomeIcon icon={faSpinner} spin size="3x" />

                    <Typography variant="h6" style={{ marginTop: 20 }}>
                        Loading your account photos...
                    </Typography>

                </Col>
            </Row>
        )
    }






    else {
        return (
            <Row style={{}} >
                <Col span={24} style={{}}>
                    <Row align="middle">
                        {/* <Col xs={16} sm={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>
                            <Button onClick={mergeOldPhotos}>Merge Old Photos</Button>
                        </Col> */}
                        <Col xs={16} sm={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>
                            <Typography variant="h4" fontWeight={600} fontSize={30} color="black">Photos</Typography>
                        </Col>

                        <Col span={24}>
                            <Divider />
                        </Col>

                        <Col span={24} >
                            <PhotosHeaders {...props}

                            />
                        </Col>


                        {activeTab === 'timeline' && (
                            <Col span={24}>
                                <TimelineHome {...props} />
                            </Col>
                        )}

                        {activeTab === 'albums' && (
                            <Col span={24}  >
                                <DndProvider backend={HTML5Backend}>
                                    <AlbumsHome {...props} />
                                </DndProvider>
                            </Col>
                        )}








                    </Row>
                </Col>

            </Row >
        );
    }

};

export default PhotosHomeNew;
