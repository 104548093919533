import { Button, Divider, OutlinedInput, Typography } from '@mui/material';
import { Col, Popconfirm, Row } from 'antd';
import React, { useEffect, useState, useMemo } from 'react';
import { fullMobile } from '../util';
import 'react-data-grid/lib/styles.css';
import uuid from 'react-uuid';
import { memo } from 'react';
import moment from 'moment-timezone';
import { DataGrid } from '@mui/x-data-grid';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, orderBy, limit } from 'firebase/firestore';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { SearchOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { faSort } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReInvite from '../../../extra/reInvite';

var randomColor = require('randomcolor');

export default function StaffDirectoryTable(props) {
    const { userInfo, screenWidth, openSnack, company, account, combinedRef } = props;
    const [loading, setLoading] = useState(true);
    const [rowCount, setRowCount] = useState(0);
    const [data, setData] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRowsKeys, setSelectedRowsKeys] = useState([]);
    const [sortColumns, setSortColumns] = useState([]);
    const [staff, setStaff] = useState([]);
    const [users, setUsers] = useState([]);
    const [searchValue, setSearchValue] = useState('')
    const [nameSorted, setNameSorted] = useState(false)
    const [emailSorted, setEmailSorted] = useState(false)
    const [companySorted, setCompanySorted] = useState(false)

    const db = getFirestore();

    useEffect(() => {
        setData(combinedRef)
        setFilterData(combinedRef)
    }, [combinedRef])








    //create 50 rows with the schema above columns
    //create array from 0 to 50
    let navigate = useNavigate()
    const EditButton = (props) => {
        let navigate = useNavigate()
        return (
            <Button variant={'outlined'} display="inline"
                size="small"
                style={{
                    width: 50,
                    textTransform: 'none', border: '1px solid #404050', fontSize: 12, color: '#404050', backgroundColor: 'white'
                }}
                onClick={async () => {
                    console.log(props.user)
                    const userRef = doc(db, "users", userInfo.id, 'currentItems', 'currentAccountPerson');
                    await setDoc(userRef, {
                        key: props.user.id
                    }).then(() => {
                        navigate("/editAccountPerson");
                    })
                }}
            >
                Edit
            </Button>

        )
    }


    async function sortData(column) {
        if (column === 'name') {
            filterData.sort((a, b) => {
                if (nameSorted) {
                    return a.label.localeCompare(b.label)
                } else {
                    return b.label.localeCompare(a.label)
                }
            })
            setFilterData([...filterData])
            setNameSorted(!nameSorted)
        }
        if (column === 'company') {
            filterData.sort((a, b) => {
                if (companySorted) {
                    return a.company.localeCompare(b.company)
                } else {
                    return b.company.localeCompare(a.company)
                }
            })
            setFilterData([...filterData])
            setCompanySorted(!companySorted)
        }

    }



    return (

        <Row align="middle" gutter={[12, 12]}>
            <Col {...fullMobile(24, 24)}>
                <OutlinedInput
                    style={{ width: 300, backgroundColor: 'white' }}
                    placeholder="Search Users"
                    value={searchValue}
                    endAdornment={
                        <SearchOutlined />
                    }
                    onChange={(e) => {
                        //if e.target.value is empty, set filterData to data
                        setSearchValue(e.target.value)
                        if (e.target.value === '') {
                            setFilterData(data)
                        } else {
                            //use regex to filter data
                            let filtered = data.filter((item) => {
                                let regex = new RegExp(e.target.value, 'gi')
                                return item.label.match(regex) || item.email.match(regex) || item.company.match(regex)
                            })
                            setFilterData(filtered)
                        }

                    }}
                />
            </Col>



            <Col {...fullMobile(24, 24)}
                style={{
                    overflowX: 'scroll',
                }}
            >
                <Table sx={{
                    minWidth: 650,
                    backgroundColor: 'white',
                    //add horizontal scroll bar if screen is too small
                    overflowX: window.innerWidth < 800 ? 'scroll' : 'hidden',

                }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell  >
                                <div style={{ cursor: 'pointer' }}
                                    onClick={() => { sortData('name') }}
                                >
                                    <Typography variant="body2" display="inline" fontWeight={600} fontSize={16} style={{ color: '#404050' }}>
                                        Name <FontAwesomeIcon fontSize={14} icon={faSort} color="#9ec1c9" />
                                    </Typography>
                                </div>
                            </TableCell>
                            <TableCell >
                                <Typography variant="body2" display="inline" fontWeight={600} fontSize={16} style={{ color: '#404050' }}>
                                    Title
                                </Typography>
                            </TableCell>
                            <TableCell >
                                <Typography variant="body2" display="inline" fontWeight={600} fontSize={16} style={{ color: '#404050' }}>
                                    Email/Phone
                                </Typography>
                            </TableCell>
                            <TableCell >
                                <div style={{ cursor: 'pointer' }}
                                    onClick={() => { sortData('company') }}
                                >
                                    <Typography variant="body2" display="inline" fontWeight={600} fontSize={16} style={{ color: '#404050' }}>
                                        Company <FontAwesomeIcon fontSize={14} icon={faSort} color="#9ec1c9" />
                                    </Typography>
                                </div>
                            </TableCell>
                            <TableCell >
                                <Typography variant="body2" display="inline" fontWeight={600} fontSize={16} style={{ color: '#404050' }}>
                                    Actions
                                </Typography>

                            </TableCell>
                            <TableCell >

                                <Typography variant="body2" display="inline" fontWeight={600} fontSize={16} style={{ color: '#404050' }}>
                                    Delete
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filterData.map((row, i) => {
                            const backgroundColor = i % 2 === 0 ? '#f8f8f8' : 'white';
                            return (
                                <TableRow
                                    key={row.id}
                                    sx={{
                                        '&:last-child td, &:last-child th': { border: '1px solid rgba(0,0,0,0.2)' },
                                        //onhover change background color
                                        '&:hover': {
                                            backgroundColor: '#40405036'
                                        },
                                        backgroundColor: backgroundColor,
                                        cursor: 'pointer'
                                    }}
                                    onClick={async () => {

                                        const userRef = doc(db, "users", userInfo.id, 'currentItems', 'currentAccountPerson');
                                        await setDoc(userRef, {
                                            key: row.id
                                        }).then(() => {
                                            navigate("/editAccountPerson");
                                        })

                                    }}
                                >
                                    <TableCell
                                        style={{ border: '1px solid rgba(0,0,0,0.2)' }}
                                        component="th" scope="row">
                                        {row.signedUp === true && (
                                            <EditButton user={row} />
                                        )}

                                    </TableCell>
                                    <TableCell
                                        //add border to all sides
                                        style={{ border: '1px solid rgba(0,0,0,0.2)' }}

                                    >
                                        <Typography variant="body2" display="inline" fontWeight={600} fontSize={14} style={{ color: '#404050' }}>
                                            {row.label}
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        style={{ border: '1px solid rgba(0,0,0,0.2)' }}
                                    >
                                        <Typography variant="body2" display="inline" fontWeight={400} fontSize={14} style={{ color: '#404050' }}>
                                            {row.title}
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        style={{ border: '1px solid rgba(0,0,0,0.2)' }}
                                    >
                                        <Typography variant="body2" display="inline" fontWeight={400} fontSize={14} style={{ color: '#404050' }}>
                                            {row.email} / {row.phone}
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        style={{ border: '1px solid rgba(0,0,0,0.2)' }} >
                                        <Typography variant="body2" display="inline" fontWeight={400} fontSize={14} style={{ color: '#404050' }}>
                                            {row.company}
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        style={{ border: '1px solid rgba(0,0,0,0.2)' }}
                                        onClick={async (e) => {
                                            e.stopPropagation()
                                            e.preventDefault()
                                        }}
                                        component="th" scope="row">
                                        {row.signedUp === false && (
                                            <ReInvite invitation={row} account={account} userInfo={userInfo} openSnack={openSnack} />
                                        )}

                                    </TableCell>
                                    <TableCell
                                        style={{ border: '1px solid rgba(0,0,0,0.2)' }}
                                        component="th" scope="row"

                                        onClick={async (e) => {
                                            e.stopPropagation()
                                            e.preventDefault()


                                        }}
                                    >
                                        {row.signedUp === true && userInfo.id !== row.id && (
                                            <Popconfirm
                                                title="Are you sure to delete this staff member?"
                                                onConfirm={async () => {
                                                    await deleteDoc(doc(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'staff', row.key)).then(async () => {


                                                        const userRef = doc(db, "users", row.key);

                                                        await updateDoc(userRef, {
                                                            accounts: arrayRemove(userInfo.currentAccount)
                                                        })


                                                        openSnack('success', 'Staff removed.')

                                                        const newData = filterData.filter((f) => f.key !== row.key)
                                                        setFilterData(newData)
                                                    })


                                                }}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <Button variant='contained'
                                                    display={"inline"}
                                                    color='primary'
                                                    size='small'
                                                    style={{
                                                        textTransform: 'none',
                                                        marginLeft: 15,
                                                        backgroundColor: '#404050',
                                                        border: '1px solid #404050'
                                                    }}
                                                >
                                                    Delete User
                                                </Button>
                                            </Popconfirm>

                                        )}

                                        {row.signedUp === false && (
                                            <Popconfirm
                                                title="Are you sure to delete this invite?"
                                                onConfirm={async () => {
                                                    await deleteDoc(doc(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'staff', row.key)).then(async () => {


                                                        const userRef = doc(db, "users", row.key);

                                                        await updateDoc(userRef, {
                                                            accounts: arrayRemove(userInfo.currentAccount)
                                                        })


                                                        openSnack('success', 'Staff removed.')

                                                        const newData = filterData.filter((f) => f.key !== row.key)
                                                        setFilterData(newData)
                                                    })


                                                }}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <Button variant='contained'
                                                    display={"inline"}
                                                    color='primary'
                                                    size='small'
                                                    style={{
                                                        textTransform: 'none',
                                                        marginLeft: 15,
                                                        backgroundColor: '#404050',
                                                        border: '1px solid #404050'
                                                    }}
                                                >
                                                    Delete Invite
                                                </Button>
                                            </Popconfirm>

                                        )}

                                    </TableCell>

                                </TableRow>

                            )
                        })}
                    </TableBody>
                </Table>
            </Col>









        </Row>

    );
}