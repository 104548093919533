import moment from 'moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import * as React from 'react';
import './blockStyles.css';
export default class DefaultTimePicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            time: this.props.time
        };
    }

    componentDidUpdate(prevProps) {


    }
    componentDidMount() {
        const { time } = this.state;
    }

    handleChange = (a,) => {
        console.log(a)
        this.props.updateItem('timeIn', moment(a).format('x'))
    }

    render() {

        const { time } = this.state;
        const format = 'h:mm a';

        return (
            <TimePicker
                style={{ fontFamily: 'Roboto, sans-serif', }}
                showSecond={false}
                defaultValue={moment(time, 'x')}
                className="xxx"
                onChange={(a) => this.handleChange(a)}
                format={format}
                allowEmpty={false}
                use12Hours
                inputReadOnly
            />
        );
    }
}




