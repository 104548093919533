import React, { useEffect, useState } from "react";
import { TextField, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import "react-datepicker/dist/react-datepicker.css";
import { faCheckCircle, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ProbeSelect = ({
    currentStream,
    setCurrentStream,
    headerKey,
    hydroProbes,
    loadingProbes,
    userInfo, // Add userId as a prop
}) => {
    const [value, setValue] = React.useState(null);

    useEffect(() => {
        console.log('newProbe');
        setValue(currentStream?.[headerKey]);
    }, [currentStream]);




    const allowedUserIds = ["EuEZ93ByFZYs8bSUXoYsTt1zu0m2", 'eTevYsI9mQMxhtntd95HmRGasNx2', 'PQWFmTLCP9XqafR9ogNI3Fg1sH43', 'z4y8XVrxYGT7rO4R01Y8tQ2F5z83', 't9bOfGDqwDaQTYV6i008opscpjz2',
        's0x105zrPbZt93cVqg2dEoOe4nC3'
    ];
    console.log(hydroProbes);
    console.log(userInfo);
    const filteredHydroProbes = allowedUserIds.includes(userInfo.id)
        ? hydroProbes
        : hydroProbes.filter(probe => probe.api_id !== 'testNew');

    if (loadingProbes) {
        return (
            <Typography variant="h6" fontSize={12} style={{ display: 'inline' }}>
                {loadingProbes ? 'Loading Probes...' : ''}
                <FontAwesomeIcon
                    icon={loadingProbes ? faSpinner : faCheckCircle}
                    spin={loadingProbes}
                    style={{ color: loadingProbes ? 'gray' : 'green' }}
                />
            </Typography>
        );
    } else {
        console.log(filteredHydroProbes);
        return (
            <Autocomplete
                fullWidth
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                size="small"
                notched={false}
                options={filteredHydroProbes?.sort((a, b) => {
                    // Convert to strings and lowercase
                    const aValue = String(a.label).toLowerCase();
                    const bValue = String(b.label).toLowerCase();
                    // Perform the sorting
                    if (aValue < bValue) return -1;
                    if (aValue > bValue) return 1;
                    return 0;
                })}
                value={value}
                hiddenLabel={true}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder="Probe"
                        variant="outlined"
                        sx={{
                            '& .MuiInputBase-root': {
                                color: 'black',
                                fontWeight: 500,
                                fontSize: 14,
                            },
                        }}
                    />
                )}
                onChange={(e, b) => {
                    setValue(b);
                    setCurrentStream({
                        ...currentStream,
                        [headerKey]: b,
                        hydroID: Number(b?.id),
                        hydroName: b?.name,
                        lat: b?.gps?.latitude,
                        lon: b?.gps?.longitude,
                    });
                    console.log({
                        ...currentStream,
                        [headerKey]: b,
                        hydroID: Number(b?.id),
                        hydroName: b?.name,
                    });
                }}
            />
        );
    }
};

export default ProbeSelect;