

import {
    Box,
    MenuItem,
    OutlinedInput,
    Select
} from "@mui/material";
import React from 'react';
import DataColorChrome from '../../dataColorChrome';


const ValueCondition = ({
    condition,
    conditionIndex,
    updateConditions


}) => {





    return (
        <Box>
            <Select
                style={{ marginLeft: 5, backgroundColor: '#ffffff' }}
                sx={{
                    fontWeight: 700,
                }}
                size="small"
                variant="outlined"
                value={condition.action || 'showOnly'}
                onChange={(e) => {
                    const newCondition = { ...condition };
                    newCondition.action = e.target.value;
                    updateConditions(newCondition);


                }}
            >
                <MenuItem value={'showOnly'}>Show Only</MenuItem>
                <MenuItem value={'hide'}>Hide</MenuItem>
                <MenuItem value={'highlight'}>Highlight</MenuItem>
            </Select>
            <Select
                style={{ marginLeft: 5, backgroundColor: '#ffffff' }}
                sx={{
                    width: 60,
                    fontWeight: 700,
                }}
                size="small"
                variant="outlined"
                value={condition.type}
                onChange={(e) => {
                    const newCondition = { ...condition };
                    newCondition.type = e.target.value;
                    updateConditions(newCondition);


                }}
            >
                <MenuItem value={'>'}>&gt;</MenuItem>
                <MenuItem value={'<'}>&lt;</MenuItem>
                <MenuItem value={'='}>=</MenuItem>
                <MenuItem value={'!='}>&ne;</MenuItem>
                <MenuItem value={'<='}>&le;</MenuItem>
                <MenuItem value={'>='}>&ge;</MenuItem>
            </Select>
            <OutlinedInput
                size="small"
                style={{ marginLeft: 5, backgroundColor: '#ffffff' }}
                sx={{
                    width: 90,
                }}
                type="number"
                value={condition.value}
                onChange={(e) => {
                    const newCondition = { ...condition };
                    newCondition.value = e.target.value;
                    updateConditions(newCondition);

                }}
            />

            {condition.action === 'highlight' &&
                <div style={{ marginLeft: 10, display: 'inline' }}>
                    <DataColorChrome color={condition.color} updateData={(boo, val) => {
                        const newCondition = { ...condition };
                        newCondition.color = val;
                        updateConditions(newCondition);
                    }}
                    />
                </div>
            }
            {condition.action === 'highlight' &&
                <div style={{ marginLeft: 10, display: 'inline' }}>
                    <OutlinedInput
                        size="small"
                        style={{ marginLeft: 5, backgroundColor: '#ffffff' }}
                        sx={{
                            width: 90,
                        }}
                        type="number"
                        value={condition.radius}
                        onChange={(e) => {
                            const newCondition = { ...condition };
                            newCondition.radius = e.target.value;
                            updateConditions(newCondition);

                        }}
                    />
                </div>
            }
        </Box>
    );
};

export default ValueCondition;
