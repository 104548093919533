import React from 'react';
import { Card, Typography, IconButton, Popover, ListItem, List, CardMedia, CardHeader, Box, Checkbox } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faEllipsisV, faEdit, faArrowUp, faTrash } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment-timezone';

const useStyles = makeStyles({
  card: {
    position: 'relative',
    borderRadius: '12px',
    overflow: 'hidden',
    cursor: 'grab',
    background: '#F2F5FC',
    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, border 0.3s ease-in-out',
    '&:hover': {
      transform: 'scale(1.03)',
      boxShadow: '0 5px 15px rgba(0, 0, 0, 0.2)',
      border: '1px solid rgba(0, 0, 0, 0.2)',
    },
  },
  media: {
    height: 130,
    width: '100%',
    objectFit: 'cover',
    cursor: 'zoom-in',
  },
  header: { // Renamed from 'footer' to 'header'
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px 16px',
    background: '#F2F5FC',
    color: 'black',
    borderTopLeftRadius: '12px',
    borderTopRightRadius: '12px',
  },
  title: {
    flexGrow: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  icon: {
    // ... other styles remain unchanged
  },
  ellipsis: {
    // ... other styles remain unchanged
  },
});

const FileCover = ({
  imageSrc,
  title,
  file,
  metaInfo,
  userInfo,
  currentFile,
  setCurrentFile,
  deleteFolder,
  draggingId,
  combineTargetFor,
  moveUpDirectory,
  openSnack,
  state,
  deleteFiles,
  folderBreadCrumbs,
  setFolderBreadCrumbs,
  selectedFiles,
  setSelectedFiles,
  lastIndex,
  setLastIndex,
  index,
  selectMultipleFiles,
  screenWidth,
  handleFileClick,
  type,
  cardType,
  publicPortal


}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;


  async function deleteFile(file) {

    deleteFiles([file])
    // deleteFileFunction(file, userInfo, openSnack)
  }



  return (
    <Card

      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        console.log('clicked')
        if (e.shiftKey && lastIndex !== null) {
          const currentFileIndex = index;
          console.log(currentFileIndex)
          const rangeStart = Math.min(lastIndex, currentFileIndex);
          const rangeEnd = Math.max(lastIndex, currentFileIndex);

          console.log(rangeStart)
          console.log(rangeEnd)
          selectMultipleFiles(rangeStart, rangeEnd)
        } else {
          if (selectedFiles.includes(file.file_id)) {
            setSelectedFiles(selectedFiles.filter((id) => id !== file.file_id))
            setLastIndex(null)
          } else {
            setSelectedFiles([...selectedFiles, file.file_id])
            setLastIndex(index)
          }
        }
      }}

      classes={{ root: classes.card }}
      style={{

        backgroundColor: selectedFiles.includes(file.file_id) ? '#afbcf9' : '#F2F5FC',
        border: state === 'dragging' ? '6px solid #ed8d8d' : 'none',
      }} >
      <CardHeader
        avatar={
          <FontAwesomeIcon icon={faImage} size={screenWidth === 'xs' ? 'sm' : 'xl'}
            color="red" />
        }
        action={
          <Box sx={{ display: 'flex', alignItems: 'center' }}>

            {type !== 'timeline' && (
              <IconButton style={{
                marginLeft: 5
              }} size="medium"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleClick(e)
                }}
                className={classes.ellipsis}>
                <FontAwesomeIcon icon={faEllipsisV} />
              </IconButton>
            )}
          </Box>
        }
        title={<Box sx={{ display: 'flex', alignItems: 'center' }}><Typography variant="body1" color={'black'} fontSize={screenWidth === 'xs' ? 11 : 14}
          fontWeight={600}>
          {title?.length > 20 ? title?.substring(0, 19) + '...' : title}
        </Typography>
        </Box>
        }
      />

      <CardMedia
        classes={{ root: classes.media }}
        component="img"
        image={imageSrc}
        alt={title}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          handleFileClick(index); // You need to ensure 'index' is the index of this file in the images array
        }}
      />
      <CardHeader


        title={<Typography variant="body1" color={'black'} fontSize={screenWidth === 'xs' ? 11 : 14}
          fontWeight={600}>
          {moment(file.created_date, 'x').format('LLL')}
        </Typography>}
      />



      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={(e) => handleClose(e)}

        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <List sx={{
          width: 300,
          padding: 0,

        }}>

          {cardType !== 'item' && (<ListItem
            key={'rename'}
            dense
            button
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setAnchorEl(null)
              if (publicPortal === true) {
                openSnack('error', 'You do not have permission to edit files')
                return
              }
              setCurrentFile(file)

            }}
            sx={{
              borderBottom: '1px solid #e0e0e0',
              paddingTop: 1,
              paddingBottom: 1,
            }}
          >
            <FontAwesomeIcon icon={faEdit} size="lg" style={{ color: 'gray', marginRight: 10 }} />
            <Typography variant="body1" color="black" fontSize={16} fontWeight={500}>Rename</Typography>
          </ListItem>
          )}
          {cardType !== 'item' && (
            <ListItem
              key={'moveUpDirectory'}
              dense
              button

              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setAnchorEl(null)
                if (folderBreadCrumbs?.length === 0) {
                  openSnack('error', 'Cannot move up from root folder')
                  return
                }
                if (publicPortal === true) {
                  openSnack('error', 'You do not have permission to move files')
                  return
                }
                moveUpDirectory('file', file)

              }}
              sx={{
                borderBottom: '1px solid #e0e0e0',
                paddingTop: 1,
                paddingBottom: 1,
              }}
            >
              <FontAwesomeIcon icon={faArrowUp} size="lg" style={{ color: 'gray', marginRight: 10 }} />
              <Typography variant="body1" color={folderBreadCrumbs?.length === 0 ? 'gray' : 'black'}
                fontSize={16} fontWeight={500}>Move up directory</Typography>
            </ListItem>
          )}
          <ListItem
            key={'deleteFile'}
            dense
            button
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setAnchorEl(null)
              if (publicPortal === true) {
                openSnack('error', 'You do not have permission to delete files')
                return
              }
              deleteFile(file)
            }}
            sx={{
              borderBottom: '1px solid #e0e0e0',
              paddingTop: 1,
              paddingBottom: 1,
            }}
          >
            <FontAwesomeIcon icon={faTrash} size="lg" style={{ color: 'gray', marginRight: 10 }} />
            <Typography variant="body1" color="black" fontSize={16} fontWeight={500}>Delete</Typography>
          </ListItem>





        </List>




      </Popover>
    </Card>
  );
};

export default FileCover;



