import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, Button, Checkbox, CircularProgress, Divider, Fab, IconButton, Input, InputAdornment, MenuItem, OutlinedInput, Paper, Select, TextField, Typography } from '@mui/material';
import { Col, Modal, Popconfirm, Row } from 'antd';
import { collection, collectionGroup, deleteDoc, doc, getDocs, getFirestore, query, setDoc, updateDoc, where } from 'firebase/firestore';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import "../../extra/hoverStyle.css";
import axios, * as others from 'axios';
import queryString from 'query-string';
import ReadProbes from './readProbes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { fullMobile } from '../stations/util';


export default class Probes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            openSnack: this.props.openSnack,
            data: [],
            filteredData: [],
            filters: [],
            searchText: '',
            loading: true,
            type: this.props.type,
            currentItem: {},
            openModal: false,
            probesList: [],
            nextPage: '',
            token: '',
            matchProbe: [],
            probeTemplate: {},
            loadingProbes: true
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
    }
    async componentDidMount() {
        window.scrollTo(0, 0)
        this.queryData()
        const { probesList } = this.state;

        const db = getFirestore();
        //get collection group stations
        const q = query(collectionGroup(db, "stations"));
        const stations = []
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            stations.push(doc.data())
        });







        const data = {
            grant_type: 'client_credentials',
            client_id: 'testNew',
            client_secret: '11d28cb08c2444a2966931244ee43e08',
        };

        this.getAllLocations()

        axios.post('https://mycorslake.herokuapp.com/https://www.hydrovu.com/public-api/oauth/token', queryString.stringify(data))
            .then(response => {

                const USER_TOKEN = response.data.access_token;

                this.setState({ token: USER_TOKEN })
                this.getData(USER_TOKEN, '', []);


            })
            .catch((error) => {

            });




    }

    getAllLocations = async () => {
        const proxyUrl = "https://mycorslake.herokuapp.com/";
        const hydrovuTokenURL = 'https://www.hydrovu.com/public-api/oauth/token';
        const hydrovuLocationsURL = 'https://www.hydrovu.com/public-api/v1/locations/list';

        const data = {
            grant_type: 'client_credentials',
            client_id: 'testNew',
            client_secret: '11d28cb08c2444a2966931244ee43e08',
        };

        const response = await axios.post(proxyUrl + hydrovuTokenURL, queryString.stringify(data));
        const { access_token } = response.data;

        this.setState({ token: access_token })
        const AuthStr = 'Bearer '.concat(access_token);

        let locations = [];
        let nextPage;

        while (true) {
            const pageResponse = await axios.get(proxyUrl + hydrovuLocationsURL, {
                headers: { Authorization: AuthStr, "X-ISI-Start-Page": `${nextPage}` },
            });


            if (pageResponse.data.length === 0) {
                break;
            }

            locations = locations.concat(pageResponse.data);
            nextPage = pageResponse.headers['x-isi-next-page'];
            if (!nextPage) {
                break;
            }
        }
        console.log(locations)
        this.setState({ probesList: locations, loading: false })
    }




    queryData = async () => {

        const { userInfo, type } = this.state;
        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'probes'), where('account', '==', userInfo.currentAccount), where('archived', '==', false));
        const data = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            data.push(doc.data())


        })

        data.sort((a, b) => a.name.localeCompare(b.name))
        const probeTemplate = data[0];
        this.setState({ data, probeTemplate })
        const list = [
            { name: "LoggerLANB1", key: 350021934 },
            { name: "LoggerLANB2", key: 940713785 },
            { name: "LoggerLANB3", key: 542840259 },
            { name: "LoggerLANB4", key: 220292925 },
            { name: "LoggerLANB5", key: 669903166 },
            { name: "LoggerLANB6", key: 955973134 },
            { name: 'LoggerLANB7', key: 357155928 },
            { name: "LoggerLANB8", key: 529272357 },
            { name: "LoggerLANB9", key: 727864623 },
            { name: "LoggerLANB10", key: 382522220 },
            { name: "LoggerLANB11", key: 421795267 },
            { name: 'LoggerLANB12', key: 992223262 },
            { name: "LoggerLANB13", key: 719055189 }
        ];

        list.map(async (g, i) => {
            const key = uuid();
            const item = { ...probeTemplate, key, tempKey: key, name: g.name, id: g.key, type: 'Logger', startRecordingTime: moment().format('X'), hydroName: 'logger' };

            const docRef = doc(db, "clients", userInfo.currentCompany, 'probes', key);


        })
        console.log(data)

        this.filterData(data, '', [])
    }

    filterData = (data, searchText, filters) => {
        const filteredData = data.filter(d => d.name.match(new RegExp(searchText, "i")))
        this.setState({ filteredData, loadingProbes: false })

    }
    filterProbes = (data, searchText,) => {
        const matchProbe = data.filter(d => `${d.id}` === searchText)
        this.setState({ matchProbe })
    }

    updateCurrentItem = (boo, val) => {
        const { currentItem } = this.state;
        currentItem[boo] = val;
        this.setState({ currentItem })
    }

    render() {

        const { openSnack, loadingProbes, userInfo, account, data, filteredData, loading, searchText, filters, type, currentItem, accounts, openModal, screenWidth, token, nextPage, probesList, matchProbe } = this.state;
        const db = getFirestore();

        const CreateItemButton = () => {
            let navigate = useNavigate();

            return (
                <Button
                    style={{
                        textTransform: 'none',
                        backgroundColor: '#404050',
                        color: 'white',
                    }}
                    fullWidth={screenWidth === 'xs' ? true : false}
                    variant="contained" color="primary" size="medium"
                    onClick={async () => {
                        this.setState({ openModal: true, currentItem: { tempKey: uuid(), recording: true, depthFromBottom: 0, startRecordingTime: moment().format('x') }, matchProbe: [] })
                    }}>
                    <AddIcon /> Add New Probe Stream
                </Button>
            )
        }

        const EditItem = ({ item }) => {
            let navigate = useNavigate();
            return (
                <IconButton style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="small"
                    onClick={async () => {
                        this.setState({ openModal: true, currentItem: item })
                    }}>
                    <EditIcon />
                </IconButton>
            )
        }

        const DeleteItem = ({ item }) => {
            let navigate = useNavigate();
            return (
                <Popconfirm
                    title="Are you sure to delete this probe?"
                    onConfirm={async () => {

                        //delete probe from firebase
                        //archive probe in firebase
                        await updateDoc(doc(db, "clients", userInfo.currentCompany, 'probes', item.key), {
                            archived: true
                        }).then(() => {
                            this.props.openSnack('success', "Probe archived!")
                            const newData = filteredData.filter((f) => f.key !== item.key)
                            this.setState({ filteredData: newData })
                        })






                    }}
                    okText="Yes"
                    cancelText="No"
                >
                    <IconButton style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="small"
                    >
                        <DeleteForeverIcon />
                    </IconButton>
                </Popconfirm>


            )
        }

        const LastRecorded = ({ item }) => {
            //use token to get the last recording from each probe

            const [lastRecorded, setLastRecorded] = useState(null)
            const [loading, setLoading] = useState(true)


            useEffect(() => {

                getDataProbe()
            }, [item.id])

            async function getDataProbe() {
                const proxyUrl = 'https://mycorslake.herokuapp.com/';
                axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgetsFire1/sqlRead', {
                    raw: `SELECT *
                    FROM node_data_new2
                    WHERE locationid = '${item.id}'
                    ORDER BY time DESC
                    LIMIT 1;`
                }).then((res) => {
                    if (res.data.length > 0) {

                        setLoading(false)
                        setLastRecorded(moment(res.data[0].time, 'YYYY-MM-DD HH:mm').format('MM/DD/YYYY h:mm a'))
                    } else {
                        setLastRecorded("No Data")
                    }
                }).catch((err) => {
                    setLoading('error')
                    console.log(err)
                })
            }

            if (loading) {
                return (
                    <Typography variant='caption' fontWeight={600} fontSize={14}>Loading...</Typography>
                )
            }
            else if (loading === 'error') {
                return (
                    <Typography variant='caption' fontWeight={600} fontSize={14}>Error, double check this probe is assigned to a node.</Typography>
                )
            }

            else {
                return (
                    <Typography variant='caption' fontWeight={600} fontSize={14}>Last Recorded: {lastRecorded} </Typography>
                )
            }
        }










        const RowItem = ({ item, index }) => {
            return (
                <Col span={24} style={{
                    cursor: 'pointer', padding: 4,
                    backgroundColor: index % 2 === 0 ? '#f0f0f0' : 'white',
                    borderBottom: '1px solid #e8e8e8',
                }} className="rowHover"
                >
                    <Row gutter={[8, 8]} align="middle" >

                        <Col xs={14} sm={20} style={{ paddingLeft: 12 }} onClick={async () => {
                            this.filterProbes(probesList, `${item.id}`)
                            this.setState({ currentItem: item, openModal: true })
                        }}>
                            <Typography fontWeight={600} fontSize={15} variant='body1'>{item.name}
                                {item.timezone && (` (${item.timezone})`)}
                            </Typography>
                            <Typography fontSize={13} variant='body1'>{item.description}</Typography>
                            <Typography fontSize={13} variant='body2'>Lat: {item.lat !== undefined ? item.lat.toFixed(6) : ''} | Lon: {item.lon !== undefined ? item.lon.toFixed(6) : ''} |  Depth from Bottom: {item.depthFromBottom} ft | {item.recording ? "Recording" : "Not Recording"}</Typography>



                        </Col>
                        <Col xs={10} sm={4} style={{ textAlign: 'right' }}>
                            <EditItem item={item} /> <DeleteItem item={item} />
                        </Col>


                        <Col span={24} style={{ paddingLeft: 12 }}>

                            <LastRecorded item={item} />
                        </Col>


                    </Row>
                </Col>
            )
        }

        const CreateProbe = () => {
            return (
                <Button size="small" style={{ padding: 10, borderRadius: 4, textTransform: 'none', fontSize: 14, }}
                    variant='contained' color="primary"
                    onClick={async () => {
                        const { currentItem } = this.state;

                        currentItem.name = currentItem.name || "";
                        currentItem.id = currentItem.id || "";



                        const checks = [
                            currentItem.name.length === 0 ? false : true,
                            currentItem.id.length === 0 ? false : true,
                        ]

                        if (currentItem.name.length === 0) {
                            this.props.openSnack('error', "Probe name required!")
                        }
                        if (currentItem.id.length === 0) {
                            this.props.openSnack('error', "Probe ID required!")
                        }

                        if (!checks.includes(false)) {

                            const key = currentItem.key || currentItem.tempKey;
                            currentItem.archived = currentItem.archived || false;

                            currentItem.account = userInfo.currentAccount;
                            currentItem.companyKey = userInfo.currentCompany;
                            currentItem.lat = matchProbe[0].gps.latitude;
                            currentItem.lon = matchProbe[0].gps.longitude;
                            currentItem.hydroName = matchProbe[0].name;
                            currentItem.hydroName = matchProbe[0].name;

                            async function createAdminAlert() {
                                const tempKey = uuid();
                                const alert = {
                                    account: userInfo.currentAccount,
                                    active: true,
                                    archived: false,
                                    companyKey: userInfo.currentCompany,
                                    key: tempKey,
                                    name: currentItem.name,
                                    probeId: key,
                                    tempKey: tempKey
                                }
                                const docRef = doc(db, "clients", userInfo.currentCompany, 'testAdminAlerts', tempKey);
                                await setDoc(docRef, alert).then(() => {

                                })
                            }

                            const docRef = doc(db, "clients", userInfo.currentCompany, 'probes', key);

                            if (currentItem.key) {
                                await updateDoc(docRef, currentItem).then(() => {
                                    this.filterProbes(probesList, '')
                                    const index = filteredData.map((d) => d.key).indexOf(currentItem.key)
                                    filteredData[index] = currentItem;
                                    this.setState({ filteredData: [...filteredData], openModal: false, currentItem: {}, matchProbe: [] })
                                }).then(() => {
                                    this.props.openSnack('success', currentItem.key ? 'Probe updated!' : "Probe created!")
                                })
                            }
                            else {
                                await setDoc(docRef, {
                                    ...currentItem,
                                    inactive: false,
                                    position: false,
                                    key: currentItem.tempKey
                                }).then(async () => {
                                    filteredData.push({ ...currentItem, key: currentItem.tempKey })
                                    this.filterProbes(probesList, '')
                                    // automatically generate admin alert
                                    await createAdminAlert()
                                    this.setState({ filteredData, openModal: false, currentItem: {}, matchProbe: [] })
                                }).then(() => {
                                    this.props.openSnack('success', currentItem.key ? 'Probe updated!' : "Probe created!")
                                })
                            }


                        }




                    }}>{currentItem.key ? 'Update Probe' : "Create Probe"}</Button>
            )
        }


        const UpdateLocationsButton = () => {
            return (
                <Button size="small" style={{ padding: 10, borderRadius: 4, textTransform: 'none', fontSize: 14, marginRight: 5 }}

                    variant='contained' color="primary"
                    onClick={async () => {
                        filteredData.map(async (d) => {
                            //get probe from probeslist based on id
                            const probe = probesList.filter((p) => p.id === d.id)[0]




                            //update probe with new location of lat and lon
                            const docRef = doc(db, "clients", userInfo.currentCompany, 'probes', d.key);
                            await updateDoc(docRef, {
                                lat: probe.gps.latitude,
                                lon: probe.gps.longitude
                            }).then(() => {

                                //update filtered data with new lat and lon
                                const index = filteredData.map((d) => d.key).indexOf(d.key)
                                filteredData[index].lat = probe.gps.latitude
                                filteredData[index].lon = probe.gps.longitude
                                this.setState({ filteredData: [...filteredData] })
                            })

                        })
                    }}>Update Locations</Button>


            )
        }

        async function getStations() {

            try {

                console.log(userInfo)
                openSnack('success', 'Data updated! Please note that if the probes are not added to a station/node, they will not be updated.')
                const proxyurl = "https://mycorslake.herokuapp.com/";
                const response = await axios.post(proxyurl + `https://us-central1-aquasource3.cloudfunctions.net/getStations`, {
                    companies: [
                        userInfo.currentCompany
                    ]
                });
                console.log('Function response:', response.data.triggeredAlerts);

            } catch (error) {
                console.error('Error calling anotherFunction:', error);
            }

        }


        const GetLatestData = () => {
            return (
                <Button size="small" style={{ padding: 10, borderRadius: 4, textTransform: 'none', fontSize: 14, }}

                    variant='contained' color="primary"
                    onClick={async () => {

                        getStations()

                    }}>Get Most Recent Data</Button>


            )
        }

        const ReturnToDataStreams = () => {
            let navigate = useNavigate()
            return (
                <Button size="small" style={{
                    padding: 10, borderRadius: 10, display: 'flex', flexDirection: 'column', justifyContent: 'space-between',
                    alignItems: 'center',
                    justifyContent: 'center', display: 'flex', cursor: 'pointer',
                    backgroundColor: '#404050', color: '#fff', fontWeight: 700,
                    transition: 'box-shadow 400ms, transform 400ms',
                    boxShadow: 'inset 0 0 0 1px #d9d9d9',
                    transform: 'scale(1)',
                    transformOrigin: 'center',
                    textTransform: 'none'
                }}

                    variant='contained'
                    onClick={async () => {
                        navigate('/dataStreams')
                    }}>Return to Data Streams</Button>


            )
        }


        return (
            <div style={{ fontFamily: 'Inter, sans-serif', backgroundColor: '#f6f8fe', minHeight: '100vh' }}>
                <Row style={{}} align="middle" >
                    <Col span={24}>
                        <Row style={{ backgroundColor: '#ffffff', borderBottom: '2px solid #e6e6e6', }} align="middle" >

                            <Col {...fullMobile(16, 24)} style={{
                                textAlign: 'left', paddingTop: 30,
                                paddingBottom: 20,
                                paddingLeft: 42,
                            }}>


                                <Typography variant="h2" fontWeight={700} fontSize={32} style={{ textAlign: 'left', color: 'black', paddingBottom: 0 }}>
                                    Real-time Probes
                                </Typography>


                            </Col>

                            <Col {...fullMobile(8, 24)} style={{ textAlign: 'right', paddingBottom: 20, paddingTop: 30, paddingRight: 10 }}>
                                {loading ?
                                    <FontAwesomeIcon icon={faSpinner} spin color='#404050' size="lg" />
                                    : <CreateItemButton />}

                            </Col>


                        </Row>

                    </Col>


                    <Col span={11} style={{ paddingLeft: 30, paddingTop: 10, }}>
                        {loading ? '' : <ReturnToDataStreams />}

                    </Col>
                    <Col span={12} style={{ paddingLeft: 30, paddingTop: 10, textAlign: 'right' }}>
                        {loading ? '' : <UpdateLocationsButton />}
                        {loading ? '' : <GetLatestData />}

                    </Col>

                    <Col span={23} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 30 }}>
                        {loadingProbes ? <CircularProgress /> :

                            <Row>
                                <Col xs={24} sm={12} style={{ paddingBottom: 8 }}>
                                    <Input fullWidth
                                        autoComplete='off'
                                        value={searchText}
                                        onChange={(e) => {
                                            this.setState({ searchText: e.target.value })
                                            if (e.target.value.length > 1) {
                                                this.filterData(data, e.target.value, filters)
                                            }
                                            else {
                                                this.filterData(data, '', filters)
                                            }
                                        }}
                                        id="input-with-icon-adornment"
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        }
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    edge="end"
                                                    onClick={() => {
                                                        this.setState({ searchText: '' })
                                                        this.filterData(data, '', filters)
                                                    }}
                                                >
                                                    <HighlightOffIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </Col>
                                <Col xs={0} sm={12} style={{ textAlign: 'right' }}>
                                    <Typography variant='h6' fontWeight={700}>Actions</Typography>
                                </Col>

                                <Col span={24}>
                                    <Row align='middle' >
                                        {filteredData.map((d, i) => {
                                            return (
                                                <RowItem item={d} index={i} />

                                            )

                                        })}
                                    </Row>
                                </Col>
                            </Row>

                        }
                    </Col>

                    <Modal title="" visible={openModal} onCancel={() => this.setState({ openModal: false })} footer={null}
                        width={screenWidth === 'xs' ? '100%' : '60%'}
                        style={{ borderRadius: 40 }} >
                        <Row align="middle" gutter={screenWidth === 'xs' ? [0, 0] : [16, 16]} >
                            <Col xs={{ offset: 0, span: 24 }} sm={{ offset: 6, span: 18 }} style={{ paddingBottom: 12, }}>
                                <Typography variant='h5'>Probe</Typography>
                            </Col>


                            <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                                <Typography variant="body1">Probe Name<span style={{ color: 'red' }}>*</span></Typography>
                            </Col>
                            <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 12 : 0 }}>
                                <OutlinedInput fullWidth notched={false} label="none" placeholder={"Name"} size="small"
                                    value={currentItem.name || ''}
                                    onChange={(e) => {
                                        this.updateCurrentItem(`name`, e.target.value)
                                    }} />
                            </Col>
                            <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                                <Typography variant="body1">Probe Select<span style={{ color: 'red' }}>*</span></Typography>
                            </Col>
                            <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 12 : 0 }}>
                                {["EuEZ93ByFZYs8bSUXoYsTt1zu0m2", 'eTevYsI9mQMxhtntd95HmRGasNx2', 'PQWFmTLCP9XqafR9ogNI3Fg1sH43', 'z4y8XVrxYGT7rO4R01Y8tQ2F5z83', 't9bOfGDqwDaQTYV6i008opscpjz2'].includes(userInfo.id) && (
                                    <Autocomplete
                                        margin="dense"
                                        size="small"
                                        id="probeSelect"
                                        options={probesList.sort((a, b) => b.name < a.name ? 1 : -1)}
                                        getOptionLabel={(option) => option.name}
                                        style={{ width: '100%' }}
                                        value={currentItem.probe || null}
                                        freeSolo={false}
                                        onChange={(event, newValue) => {
                                            if (newValue === null) {
                                                this.updateCurrentItem(`probe`, null)
                                                this.updateCurrentItem(`id`, null)
                                                this.updateCurrentItem(`name`, null)
                                                return

                                            }
                                            this.updateCurrentItem(`probe`, newValue)
                                            const id = newValue.id;
                                            this.filterProbes(probesList, id.toString())
                                            this.updateCurrentItem(`id`, Number(id))
                                            this.updateCurrentItem(`name`, newValue.name)
                                        }}
                                        renderOption={(props, option,) => {

                                            return (
                                                <li {...props} style={{ fontSize: 14, backgroundColor: probesList.indexOf(option) % 2 === 0 ? 'white' : '#f6f6f6' }}>
                                                    {option.name}
                                                </li>
                                            )
                                        }
                                        }
                                        renderInput={(params) => <TextField {...params} placeholder="Probe" variant="outlined" />}



                                    />
                                )}
                            </Col>
                            <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                                <Typography variant="body1">Probe ID<span style={{ color: 'red' }}>*</span></Typography>
                            </Col>
                            <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 12 : 0 }}>
                                <OutlinedInput fullWidth notched={false} label="none" placeholder={"ID"} size="small"
                                    value={currentItem.id || ''}
                                    onChange={(e) => {
                                        this.updateCurrentItem(`id`, Number(e.target.value))

                                        this.filterProbes(probesList, e.target.value,)
                                    }} />
                            </Col>
                            <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                                <Typography variant="body1">Recording</Typography>
                            </Col>
                            <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 12 : 0 }}>
                                <Checkbox checked={currentItem.recording} onChange={(e) => {
                                    this.updateCurrentItem(`recording`, e.target.checked)
                                }} />

                            </Col>
                            <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                                <Typography variant="body1">Depth From Bottom (ft)</Typography>
                            </Col>
                            <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 12 : 0 }}>
                                <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                    type="number"
                                    inputProps={{ min: 0 }}
                                    value={currentItem.depthFromBottom || 0}
                                    onChange={(e) => {
                                        this.updateCurrentItem(`depthFromBottom`, e.target.value === "" ? "" : Number(e.target.value))
                                    }} />

                            </Col>
                            <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                                <Typography variant="body1">Time Zone</Typography>
                            </Col>
                            <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 12 : 0 }}>
                                <Select
                                    size="small"
                                    fullWidth
                                    notched={false}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={currentItem.timezone || ''}
                                    label="Type"
                                    onChange={(e) => {
                                        this.updateCurrentItem(`timezone`, e.target.value)
                                    }}
                                >
                                    {["US/Alaska", "US/Arizona", "US/Central", "US/Eastern", "US/Hawaii", "US/Mountain", "US/Pacific", "US/Samoa"].map((l) => {
                                        return (
                                            <MenuItem value={l}>{l}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </Col>

                            <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                                <Typography variant="body1">Type</Typography>
                            </Col>
                            <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 12 : 0 }}>
                                <Select size="small" fullWidth
                                    notched={false}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={currentItem.type || ''}
                                    label="Type"
                                    onChange={(e) => {
                                        this.updateCurrentItem(`type`, e.target.value)
                                    }}

                                >
                                    {["RDO", "AT-500", "AT-600", "Level Troll", "Vu-Link"].map((l) => {
                                        return (
                                            <MenuItem value={l}>{l}</MenuItem>
                                        )
                                    })}


                                </Select>
                            </Col>
                            <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                                <Typography variant="body1">Description</Typography>
                            </Col>
                            <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 12 : 0 }}>
                                <OutlinedInput fullWidth notched={false} label="none" placeholder={"Description"} size="small"
                                    multiline={true}
                                    rows={4}
                                    value={currentItem.description || ''}
                                    onChange={(e) => {
                                        this.updateCurrentItem(`description`, e.target.value)
                                    }} />
                            </Col>


                            <Col xs={{ offset: 0, span: 24 }} sm={{ offset: 6, span: 18 }} style={{ paddingBottom: 12, }}>
                                {matchProbe.length > 0 && (
                                    <Row gutter={[8, 8]} align="middle">
                                        <Col span={24}>
                                            Name: {matchProbe[0].name}
                                        </Col>
                                        <Col span={24}>
                                            Lat: {matchProbe[0].gps.latitude}
                                        </Col>
                                        <Col span={24}>
                                            Lon: {matchProbe[0].gps.longitude}
                                        </Col>

                                    </Row>
                                )}
                            </Col>
                            <Col xs={{ offset: 0, span: 24 }} sm={{ offset: 6, span: 18 }} style={{ paddingBottom: 12, }}>
                                {matchProbe.length > 0
                                    && ["EuEZ93ByFZYs8bSUXoYsTt1zu0m2", 'eTevYsI9mQMxhtntd95HmRGasNx2', 'PQWFmTLCP9XqafR9ogNI3Fg1sH43', 'z4y8XVrxYGT7rO4R01Y8tQ2F5z83', 't9bOfGDqwDaQTYV6i008opscpjz2'].includes(userInfo.id) ?
                                    <CreateProbe /> : "Enter Probe ID to match active probes"
                                }


                            </Col>

                        </Row>
                    </Modal>


                    {filteredData.length > 0 && (<ReadProbes userInfo={userInfo} screenWidth={screenWidth} openSnack={openSnack} probesRef={filteredData} />)}

                </Row >
            </div>

        );
    }
} 