import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import moment from 'moment';
import React from 'react';
import unitsList from '../../../extra/units.json'
import axios, * as others from 'axios';
var convert = require('convert-units')
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/data')(Highcharts);


const parameterMap = {
    '1': {
        default: 'C',
    },
    '3': {
        default: 'm',

    },
    '4': {
        default: 'm',
    },
    '5': {
        default: 'm',
    },
    '8': {
        default: 'm',
    },
    '19': {
        from: '',
        to: ''
    }
};

export default class ReadingChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            attributes: this.props.attributes,
            lastReading: this.props.lastReading,

            userInfo: this.props.userInfo,
            height: null,
            series: {},
            queryInfo: {},
            chartOptions: {
                credits: {
                    enabled: false
                },
                exporting: {
                    enabled: false
                },
                stockTools: {
                    gui: {
                        enabled: false
                    }
                },
                chart: {
                    resetZoomButton: {
                        position: {
                            align: 'left',
                            x: 20,
                            y: -20
                        }
                    },
                    style: {
                        fontFamily: 'Roboto, sans-serif'
                    },




                    zoomType: 'x',
                    zooming: {
                        mouseWheel: {
                            enabled: false
                        }
                    },

                    animation: false
                },
                title: {
                    text: null
                },
                legend: {
                    enabled: false
                },


                series: [],
                yAxis: [{
                    id: 0, gridLineColor: 'transparent', title: { text: null },
                    labels: {
                        style: {
                            fontWeight: '300',
                            fontSize: '12px',
                            color: 'black'
                        }
                    }

                }],
                xAxis: {
                    type: 'datetime',
                    plotLines: [],
                    title: { text: null },
                    minorGridLineWidth: 0,
                    lineColor: 'transparent',
                    gridLineColor: 'transparent',
                    minorGridLineColor: 'transparent',
                    crosshair: {
                        width: 10,
                        color: '#2651d214',
                        dashStyle: 'solid',
                        zIndex: 0,
                    },
                    labels: {
                        style: {
                            fontWeight: '600',
                            fontSize: '12px',
                            color: 'black'
                        }
                    }
                },
                tooltip: {
                    xDateFormat: '%b %d, %Y %I:%M %p',
                    shared: true
                },
                plotOptions: {
                    series: {
                        animation: false
                    },





                },
                time: {
                    useUTC: false,
                }
            },


        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.attributes !== prevProps.attributes) {
            this.updateAttributes(this.props.attributes)
            this.setState({ attributes: this.props.attributes })
        }
        if (this.props.lastReading !== prevProps.lastReading) {
            this.setState({ lastReading: this.props.lastReading })
        }
        if (this.props.chartOptions !== prevProps.chartOptions) {
            this.updateAttributes(this.props.chartOptions)
            this.setState({ chartOptions: this.props.chartOptions })

        }



    }
    componentDidMount() {
        const { attributes, chartOptions, queryInfo, series, } = this.state;
        this.setState({ height: this.container.offsetHeight, });

        this.updateChartSub('chart', 'height', this.container.offsetHeight)




        this.updateAttributes(attributes)



    }



    updateAttributes = (attributes) => {

        const { chartOptions, lastReading } = this.state;

        const qi = {
            interval: null,
            units: 'hours',
            start: attributes.endToday === true ? moment().subtract(1, attributes.timeFrame || 'days').startOf("day").format('MM-DD-YYYY HH:mm') :
                moment(lastReading, 'X').subtract(1, attributes.timeFrame || 'days').startOf("day").format('MM-DD-YYYY HH:mm'),
            iStart: moment().subtract(7, attributes.timeFrame || 'days').startOf("day").format('x'),
            end: attributes.endToday === true ? moment().endOf("day").format('MM-DD-YYYY HH:mm') : moment(lastReading, 'X').format('MM-DD-YYYY HH:mm'),
            iEnd: moment().endOf("day").format('x'),
            customInterval: undefined,
            customIntervalVal: 4,
        };

        this.setState({ queryInfo: qi })



        const series = [{
            label: 'Reading',
            locationid: attributes.locationid,
            parameterid: attributes.parameterid,
            units: attributes.units || '',
            data: [], color: attributes.color, offset: attributes.offset || 0,
            type: 'area',
            //but do not go all the way to 0
            threshold: attributes.startAtZero === true ? 0 : null,
            //add gradient fill and be fully transparent at the bottom
            fillColor: {
                linearGradient: {
                    x1: 0,
                    y1: 0,
                    x2: 0,
                    y2: 1
                },
                stops: [
                    [0, Highcharts.color(attributes.color).setOpacity(0.2).get('rgba')],
                    [1, Highcharts.color(attributes.color).setOpacity(0).get('rgba')]
                ]
            },
            //smooth the line
            lineWidth: 1,
            marker: {
                enabled: false
            },
            states: {
                hover: {
                    lineWidth: 1
                }
            },
            //create a vertical shaded area where the cursor is to highlight the current reading when hovering
            //the chart
            enableMouseTracking: true,
            shadow: false,


        }]




        chartOptions.series = series;

        this.getData(series, qi)


        this.setState({ chartOptions })

    }





    updateChart = (boo, val) => {
        const { chartOptions } = this.state;
        chartOptions[boo] = val;
        this.setState({ chartOptions })

    }
    updateChartSub = (boo, a, val) => {
        const { chartOptions } = this.state;
        chartOptions[boo][a] = val;
        this.setState({ chartOptions })

    }




    querySample = async (q, queryInfo) => {
        const { chartOptions, series, userInfo } = this.state;





        const functionNameMap = [
            "databaseFetch3",
            "databaseFetch4",
            "databaseFetch5",
        ]



        /* pick random number between 0 and 2 */
        const randomIndex = Math.floor(Math.random() * 3);
        const randomFunctionName = functionNameMap[randomIndex];
        const proxyUrl = "https://mycorslake.herokuapp.com/";

        axios.post(proxyUrl + `https://us-central1-aquasource3.cloudfunctions.net/${randomFunctionName}`, {
            raw: `SELECT time, timestamp, value, locationlabel, locationid, parameterid, parameterlabel, account
            FROM lab_data
            WHERE locationid = '${q.locationid}'and parameterid = '${q.parameterid}'
            and time >= '${queryInfo.start}' 
            and time <= '${queryInfo.end}' 
            ORDER BY "time" DESC;`
        })
            .then(async (response) => {

                q.data = response.data.map((d) => {
                    const offset = q.offset || 0;
                    const value = Number(d.value.toFixed(4)) + offset;
                    return ([d.timestamp * 1000, value])
                });

                q.data.sort((a, b,) => a[0] - b[0]);

                const index = chartOptions.series.map((s) => s.key).indexOf(q.key);
                q.name = q.label || null;
                q.tooltip.valueSuffix = q.units === undefined ? null : ` ${q.units}`;
                q.tooltip.valueDecimals = q.decimals || null;

                q.marker.lineColor = q.markerLine || null;
                q.marker.fillColor = q.markerFill || null;
                q.pointWidth = q.columnWidth || 10;
                q.dashStyle = q.dashed || null;
                q.lineWidth = q.lineWidth || 2;
                q.marker.enabled = q.dots || false;
                q.opacity = q.opacity || 1;

                chartOptions.series[index] = q;
                this.setState({ chartOptions })




            })
            .catch((error) => {
                // handle error
                console.log(error);


            })
            .then(function () {
                // always executed
            });

    }
    queryData = (q, queryInfo, i) => {
        const { chartOptions, series, userInfo, attributes } = this.state;



        this.setState({ queryInfo })
        const proxyUrl = "https://mycorslake.herokuapp.com/";

        let value = 'avg(value) as "y", COALESCE(avg(offsetvalue) , 0) as "offset"';

        const customBucket =
            `SELECT time as "t"
        ,locationid, unitid, avg(timestamp) as "x", ${value}
        FROM ${q.dataType === 'probe' ? 'probe_data' : 'node_data_new2'}
        WHERE ${q.dataType === 'probe' ? 'locationid' : 'nodeid'} = '${q.locationid}'and parameterid = '${q.parameterid}'
        and time >= '${queryInfo.start}' 
        and time <= '${queryInfo.end}' 

        GROUP BY "t", locationid, unitid
        ORDER BY "t" DESC; `;


        const queryString = "widgetsFire6";



        axios.post(proxyUrl + `https://us-central1-aquasource3.cloudfunctions.net/${queryString}/sqlRead`, {
            raw: customBucket
        })
            .then(async (response) => {
                let offset = q.offset || 0;

                q.data = response.data.map((d) => {
                    let value = Number(d.y);




                    if (["38", "35", "1", "2"].includes(d.unitid) && d.unitid !== undefined && d.unitid !== null && d.unitid !== '' && q.units) {
                        let parameter = parameterMap[q.parameterid] || {};
                        let defaultUnit = parameter.default || '';


                        const unitLabel = unitsList[d.unitid] ? unitsList[d.unitid] : defaultUnit;

                        value = Number(convert(d.y.toFixed(4)).from(unitLabel).to(q.units));
                    }
                    else {

                        value = q.parameterid === '19' ? Number(value.toFixed(4)) * 1000 : Number(value.toFixed(4));

                    }

                    if (attributes?.dataSourceType === 'readingWithOffset') {
                        value += Number(d.offset);
                    }
                    if (attributes?.dataSourceType === 'offset') {
                        value = Number(d.offset);
                    }

                    const newX = Number(moment(d.t, "YYYY-MM-DD HH:mm:ss").format("x"))
                    return ([newX, value]);
                });

                q.data.sort((a, b,) => a[0] - b[0])



                const index = chartOptions.series.map((s) => s.key).indexOf(q.key);

                const input = q.decimals === 0 || q.decimals ? q.decimals : 2;

                q.name = q.label || null;
                q.tooltip.valueSuffix = q.units === undefined ? null : ` ${q.units}`;
                q.tooltip.valueDecimals = input;
                q.marker.lineColor = q.markerLine || null;
                q.marker.fillColor = q.markerFill || null;
                q.dashStyle = q.dashed || null;
                q.lineWidth = attributes.lineWidth || 3;
                q.marker.enabled = q.dots || false;
                q.opacity = q.opacity || 1;

                chartOptions.series[index] = q;
                this.setState({ chartOptions })


            })
            .catch((error) => {
                // handle error
                console.log(error);


            })
            .then(function () {
                // always executed
            });

    }
    queryWeatherData = (q, queryInfo) => {



        const { chartOptions, series, userInfo } = this.state;


        this.setState({ queryInfo })
        const proxyUrl = "https://mycorslake.herokuapp.com/";
        axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgetsFire3/sqlRead', {
            raw: `SELECT time_bucket('${q.interval} hours', time) as "t"
            ,account, avg(dt) as "x", avg(temp) as "y", avg(rain) as "r"
            FROM weather_data
            WHERE account = '${userInfo.currentAccount}'
            and time >= '${queryInfo.start}' 
            and time <= '${queryInfo.end}' 
            GROUP BY "t", account
            ORDER BY "t" DESC;`
        })
            .then(async (response) => {

                if (q.parameterid === '1') {
                    q.data = response.data.map((d) => {
                        const offset = q.offset || 0;
                        const value = Number(convert(d.y.toFixed(4)).from('C').to(['C', 'F'].includes(q.units) ? q.units : 'C')) + offset;
                        return ([d.x * 1000, value])
                    });
                }
                if (q.parameterid === '2') {
                    q.data = response.data.map((d) => {
                        const offset = q.offset || 0;

                        const value = Number(convert(d.r.toFixed(4)).from('mm').to('in')) + offset;
                        return ([d.x * 1000, value])
                    });
                }

                q.data.sort((a, b,) => a[0] - b[0])

                const index = chartOptions.series.map((s) => s.key).indexOf(q.key);
                q.name = q.label || null;

                q.tooltip.valueSuffix = q.units === undefined ? null : ` ${q.units}`;
                q.tooltip.valueDecimals = q.decimals || null;
                q.marker.lineColor = q.markerLine || null;
                q.marker.fillColor = q.markerFill || null;
                q.dashStyle = q.dashed || null;
                q.lineWidth = q.lineWidth || 2;
                q.marker.enabled = q.dots || false;
                q.opacity = q.opacity || 1;

                chartOptions.series[index] = q;
                this.setState({ chartOptions })


            })
            .catch((error) => {
                // handle error
                console.log(error);


            })
            .then(function () {
                // always executed
            });




    }
    queryStationData = (q, queryInfo) => {



        const { chartOptions, series, userInfo, attributes } = this.state;



        this.setState({ queryInfo })
        const proxyUrl = "https://mycorslake.herokuapp.com/";

        const customBucket = `SELECT time_bucket('1 hours', time) as "t", avg(value) as "y"
        FROM station_data_new
        WHERE id = '${q.location.id}'
        and time >= '${queryInfo.start}' 
        and time <= '${queryInfo.end}' 
        GROUP BY "t", id
        ORDER BY "t" DESC;`;

        const queryString = 'widgetsFire2';



        axios.post(proxyUrl + `https://us-central1-aquasource3.cloudfunctions.net/${queryString}/sqlRead`, {
            raw: customBucket
        })
            .then(async (response) => {

                q.data = response.data.map((d) => {
                    const value = Number(d.y.toFixed(4));

                    const x = moment(d.t, 'YYYY-MM-DD HH:mm:ss').add(attributes.timeOffset || 0, 'hours').format('x');
                    return ([Number(x), value])
                });

                q.data.sort((a, b,) => a[0] - b[0])



                const index = chartOptions.series.map((s) => s.key).indexOf(q.key);

                const input = q.decimals === 0 || q.decimals ? q.decimals : 4;

                q.name = q.label || null;

                q.tooltip.valueSuffix = q.units === undefined ? null : ` ${q.units}`;
                q.tooltip.valueDecimals = input;
                q.marker.lineColor = q.markerLine || null;
                q.marker.fillColor = q.markerFill || null;
                q.dashStyle = q.dashed || null;
                q.lineWidth = q.lineWidth || 2;
                q.marker.enabled = q.dots || false;
                q.opacity = q.opacity || 1;

                chartOptions.series[index] = q;
                this.setState({ chartOptions })


            })
            .catch((error) => {
                // handle error
                console.log(error);


            })
            .then(function () {
                // always executed
            });




    }
    getData = (series, queryInfo) => {

        const { chartOptions, attributes, userInfo } = this.state;
        const proxyUrl = "https://mycorslake.herokuapp.com/";


        chartOptions.tooltip.shared = attributes.shareAxis || false;


        const updateAxises = attributes.axises.map((a) => {

            return (
                {
                    ...a,
                    labels: { style: { color: a.axisColor || 'black' } },
                    tickAmount: a.customTicks === true ? a.tickAmount : undefined,
                    tickInterval: a.customTicks === true ? a.tickInterval : undefined,
                    min: a.customRange === false ? null : a.min,
                    max: a.customRange === false ? null : a.max,
                    id: a.axisId,
                    allowDecimals: false,
                    title: { text: a.label },
                    plotLines: []
                }
            )
        })


        const yPlots = attributes.references.filter((r) => r.horizontal === true);
        const xPlots = attributes.references.filter((r) => r.horizontal === false);

        const updatedY = yPlots.map((f) => {
            return ({
                ...f, value: f.yValue, zIndex: 50,
                label: { text: f.useAlias ? f.alias : f.title, textAlign: f.textAlign, verticalAlign: f.verticalAlign, }
            })
        })
        const updatedX = xPlots.map((f) => {
            return ({
                ...f, value: Number(f.date), zIndex: 50,
                label: { text: f.useAlias ? f.alias : f.title, textAlign: f.textAlign, verticalAlign: f.verticalAlign }
            })
        })

        updatedY.map((y) => {
            const index = updateAxises.map((d) => d.id).indexOf(y.yAxis);
            updateAxises[index].plotLines.push(y)
        })
        updateAxises.plotLines = updatedY;
        chartOptions.xAxis.plotLines = updatedX;
        chartOptions.yAxis = [{
            id: 0, gridLineColor: 'transparent'

        }];

        this.setState({ chartOptions })




        series.map(async (q, i) => {


            q.dataType = attributes.dataType;
            q.tooltip = { valueSuffix: q.suffix === undefined ? null : ` ${q.suffix}` };
            q.marker = { lineColor: q.markerLine || null, fillColor: q.markerFill || null };



            if (['probe', 'node'].includes(attributes.dataType)) {


                this.queryData(q, queryInfo, i)


            }


            if (q.dataType === 'weather') {



                this.queryWeatherData(q)

            }

            if (q.dataType === 'station') {



                this.queryStationData(q)

            }

            if (q.dataType === 'sample') {




                this.querySample(q)


            }

        })




    }

    render() {

        const { account, chartOptions, hoverData, loading, height, dimensions } = this.state;


        const Height = () => {
            if (this.container) {
                if (this.container.offsetHeight !== height) {
                    this.updateChartSub('chart', 'height', this.container.offsetHeight)
                    this.setState({ height: this.container.offsetHeight })

                }

            }

        }


        return (
            <div ref={el => (this.container = el)} style={{ height: '90%', width: '100%', }}>
                <Height />
                <HighchartsReact
                    constructorType={"chart"}
                    highcharts={Highcharts}
                    ref={"chart"}
                    options={chartOptions}
                    allowChartUpdate={true}
                    immutable={false}
                    updateArgs={[true, true, true]}
                />
            </div>

        );
    }
}  