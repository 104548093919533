import { collection, doc, getCountFromServer, getDocs, getFirestore, query, setDoc, where } from "firebase/firestore"
import uuid from "react-uuid";
import moment from 'moment-timezone';



export const createFolderFunction = async (folder, currentParentFolder, userInfo, setCurrentFolder, openSnack) => {
    try {
        folder.folder_id = uuid();
        folder.account = userInfo.currentAccount;
        folder.company = userInfo.currentCompany;
        folder.created_date = Number(moment().format('x'));
        folder.isPublic = false;
        folder.tags = [];
        folder.coverFile = null;
        folder.status = 'active';
        folder.creator = userInfo.id;
        folder.parent_folder_id = currentParentFolder?.folder_id || null;
        const db = getFirestore();
        const folderRef = doc(db, "clients", userInfo.currentCompany, "folders_new", folder.folder_id);
        await setDoc(folderRef, folder);

        setCurrentFolder(null)
        openSnack('success', 'Folder created')
    }
    catch (e) {
        console.log(e)
        openSnack('error', 'Error creating folder')
    }
}

export const deleteFolderFunction = async (folder, userInfo, setCurrentFolder, openSnack) => {
    try {
        const db = getFirestore();
        const folderRef = doc(db, "clients", userInfo.currentCompany, "folders_new", folder.folder_id);
        await setDoc(folderRef, { ...folder, status: 'deleted', deleted_date: new Date() });

        setCurrentFolder(null);
        openSnack('success', 'Folder deleted');
    }
    catch (e) {
        console.log(e);
        openSnack('error', 'Error deleting folder');
    }
}



export const countFolderImagesFunction = async (folder, userInfo,) => {
    try {

        const db = getFirestore();
        const coll = collection(db, "clients", userInfo.currentCompany, "files_new");
        const q = query(coll, where("parent_folder_id", "==", folder.folder_id));
        const snapshot = await getCountFromServer(q);

        return snapshot.data().count;
    }
    catch (e) {
        console.log(e)
        return 0;
    }
}
export const countFoldersFunction = async (folder, userInfo,) => {
    try {
        const db = getFirestore();
        const coll = collection(db, "clients", userInfo.currentCompany, "folders_new");
        const q = query(coll, where("parent_folder_id", "==", folder.folder_id));
        const snapshot = await getCountFromServer(q);

        return snapshot.data().count;
    }
    catch (e) {
        console.log(e)
        return 0;
    }
}

export const updateFolderFunction = async (folder, userInfo, setCurrentFolder, openSnack) => {
    try {
        const db = getFirestore();
        const folderRef = doc(db, "clients", userInfo.currentCompany, "folders_new", folder.folder_id);
        await setDoc(folderRef, folder);
        setCurrentFolder(null)
        openSnack('success', 'Folder updated')
    }
    catch (e) {
        openSnack('error', 'Error updating folder')
    }
}

export const updateParentIdFunction = async (type, item_id, newParentFolder_id, userInfo, setCurrentFolder, openSnack) => {
    try {
        const db = getFirestore();
        const ref = doc(db, "clients", userInfo.currentCompany, type === 'folder' ? "folders_new" : 'files_new', item_id);
        await setDoc(ref, { parent_folder_id: newParentFolder_id }, { merge: true });
        setCurrentFolder(null)
        openSnack('success', 'Folder updated')
    }
    catch (e) {
        openSnack('error', 'Error updating folder')
    }
}






export async function fetchFolders(userInfo, currentParentFolder, setFecthingFolders, openSnack, publicPortal) {
    try {
        const db = getFirestore();
        const ref = collection(db, "clients", userInfo.currentCompany, 'folders_new');
        console.log(publicPortal)
        let queryRef;
        if (currentParentFolder && publicPortal === false) {
            queryRef = query(ref, where("account", "==", userInfo.currentAccount), where("parent_folder_id", "==", currentParentFolder.folder_id), where("status", "!=", 'deleted'));
        }
        else if (currentParentFolder && publicPortal === true) {
            console.log("GET PUBLIC Documents")
            queryRef = query(ref, where("account", "==", userInfo.currentAccount), where("parent_folder_id", "==", currentParentFolder.folder_id), where("status", "!=", 'deleted'), where("isPublic", "==", true));
        }
        else if (publicPortal === true) {
            console.log("GET PUBLIC Documents")
            queryRef = query(ref, where("account", "==", userInfo.currentAccount), where("parent_folder_id", "==", null), where("status", "!=", 'deleted'), where("isPublic", "==", true));
        }
        else {
            queryRef = query(ref, where("account", "==", userInfo.currentAccount), where("parent_folder_id", "==", null), where("status", "!=", 'deleted'));
        }

        const snap = await getDocs(queryRef);
        const data = snap.docs.map((doc) => doc.data());

        openSnack('success', 'Data retrieved successfully');
        setFecthingFolders(false);
        return data;

    } catch (error) {
        console.log(error)
        console.error("Error updating document: ", error);
        openSnack('error', 'Error retrieving data');
        setFecthingFolders(false);
        return [];
    }
};

export const updateFolderImageFunction = async (folder, lastFile, userInfo, openSnack) => {
    try {

        const db = getFirestore();
        console.log(lastFile)
        const folderRef = doc(db, "clients", userInfo.currentCompany, "folders_new", folder.folder_id);
        await setDoc(folderRef, { coverFile: lastFile.medium }, { merge: true });
        openSnack('success', 'Folder updated')
    }
    catch (e) {
        console.log(e)
        openSnack('error', 'Error updating folder')
    }
}
