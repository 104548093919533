import { OutlinedInput, TextField } from '@mui/material';
import { Input } from 'antd';
import React from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

const { Search } = Input; 

 export default class LocationSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          address: this.props.address,
          size: this.props.size,
          };
      }

      componentDidUpdate(prevProps) {
        
        
        if (this.props.address !== prevProps.address) {
          this.setState({address: this.props.address})
        }
      }

      handleChange = address => {
        this.setState({ address: address });
      
      };

      handleSelect = address => {
        this.setState({ address });
        geocodeByAddress(address)
          .then(results => getLatLng(results[0]))
          .then(latLng => this.props.fillLatLng(latLng))
          .catch(error => console.error('Error', error))
          .then(() => {
            geocodeByAddress(address)
            .then(results => this.props.fillAddress(results[0]))
          })
      };

      render () {

        const { size } = this.state;
          return (
            <PlacesAutocomplete
            value={this.state.address}
            onChange={this.handleChange}
            onSelect={this.handleSelect}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div>

              <TextField fullWidth variant="outlined" size={size} label="Search"
                                        style={{ fontFamily: 'Roboto, sans-serif',  }}
               
                    {...getInputProps({
                      placeholder: 'Search Places ...',
                      className: 'location-search-input',
                    })}
                  
                  />
                
                <div className="autocomplete-dropdown-container" style={{ fontFamily: 'Roboto, sans-serif',}}>
                  {loading && <div>Loading...</div>}
                  {suggestions.map(suggestion => {
                    const className = suggestion.active
                      ? 'suggestion-item--active'
                      : 'suggestion-item';
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                      : { backgroundColor: '#ffffff', cursor: 'pointer' };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style, 
                        })}
                      >
                        <span style={{ fontFamily: 'Roboto, sans-serif', fontSize: 16}}>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
          )
      }

 }