
import { Box, Button, Card, Checkbox, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import moment from 'moment-timezone';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./datePicker.css";
import StaffPickerSingle from "./staffPickerSingle";
import StaffPickerMultiple from "./staffPickerMultipl";



const BasicInfo = ({
    log,
    setLog,
    accountUsers,
    screenWidth
}) => {

    const props = {
        log,
        setLog,
        accountUsers
    }

    return (

        <Box
            sx={{ flexGrow: 1, px: 2 }}
            style={{
                textAlign: 'right'
            }}>
            <Grid container spacing={2}
                //horizontally align middle
                alignItems="center"
            >
                <Grid item xs={12} sm={4} style={{ textAlign: screenWidth === 'xs' ? 'left' : 'right', paddingBottom: screenWidth === 'xs' ? 0 : null }}>
                    <Typography variant="body1" color="black" fontSize={16} fontWeight={500}>
                        Start Date:<span style={{ color: 'red' }}>*</span>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={8}
                    style={{ paddingTop: screenWidth === 'xs' ? 2 : null }}>
                    <DatePicker
                        timeCaption="Time"
                        showTimeSelect
                        timeFormat="h:mm a"
                        timeIntervals={1}
                        dateFormat="MM/dd/yyyy hh:mm a"
                        portalId="root-portal"
                        selected={log?.startDate ? new Date(moment(log?.startDate, 'x')) : new Date()}
                        onChange={(date) => {
                            const newLog = { ...log };
                            newLog.startDate = moment(date).format('x');
                            setLog(newLog)
                        }}
                        customInput={<input style={{ fontWeight: 600 }} />}
                    />
                </Grid>
                {/* <Grid item xs={12} sm={4} style={{ textAlign: screenWidth === 'xs' ? 'left' : 'right', paddingBottom: screenWidth === 'xs' ? 0 : null }}>
                    <Typography variant="body1" color="black" fontSize={16} fontWeight={500}>
                        End Date:<span style={{ color: 'red' }}>*</span>
                    </Typography>
                </Grid>
                {!log?.multiDay && (<Grid item xs={12} sm={6}
                    style={{ paddingTop: screenWidth === 'xs' ? 2 : null }}
                >
                    <DatePicker
                        timeCaption="Time"
                        showTimeSelect
                        timeFormat="h:mm a"
                        timeIntervals={1}
                        dateFormat="MM/dd/yyyy hh:mm a"
                        portalId="root-portal"
                        //if date does not exist add one hour to start date
                        selected={log?.endDate ? new Date(moment(log?.endDate, 'x')) : new Date(moment().add(1, 'hours'))}
                        onChange={(date) => {
                            const newLog = { ...log };
                            newLog.endDate = moment(date).format('x');
                            setLog(newLog)
                        }}
                        customInput={<input style={{ fontWeight: 600 }} />}
                    />
                </Grid>)}
                <Grid item xs={12} sm={log?.multiDay ? 8 : 2} style={{

                    textAlign: log?.multiDay ? 'left' : 'center'
                }}>
                    <Button variant={log?.multiDay ? 'contained' : 'outlined'}
                        fullWidth={screenWidth === 'xs' ? true : false}
                        style={{
                            paddingTop: screenWidth === 'xs' ? 2 : null,
                            textTransform: 'none',
                            border: log?.multiDay ? '1px solid rgba(0,0,0,0.2)' : '1px solid rgba(0,0,0,0.5)',
                            backgroundColor: log?.multiDay ? '#3f51b5' : null,
                            height: 45,
                            paddingLeft: 5, paddingRight: 5,
                            color: log?.multiDay ? 'white' : 'black',
                        }}

                        onClick={(e) => {
                            const newLog = { ...log };
                            newLog.multiDay = !log?.multiDay;
                            setLog(newLog)
                        }}

                    >
                        <Typography variant='caption' color="inherit" fontSize={12} fontWeight={500}>
                            {log?.multiDay ? 'Single-day' : 'Multi-day'}
                        </Typography>
                    </Button>


                </Grid> */}
                <Grid item xs={12} sm={4} style={{ textAlign: screenWidth === 'xs' ? 'left' : 'right', paddingBottom: screenWidth === 'xs' ? 0 : null }}>
                    <Typography variant="body1" color="black" fontSize={16} fontWeight={500}>
                        Log Staff:<span style={{ color: 'red' }}>*</span>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={8}
                    style={{
                        paddingTop: screenWidth === 'xs' ? 2 : null,
                    }}>
                    <StaffPickerSingle {...props} />
                </Grid>
                {/* <Grid item xs={12} sm={4} style={{ textAlign: screenWidth === 'xs' ? 'left' : 'right', paddingBottom: screenWidth === 'xs' ? 0 : null }}>
                    <Typography variant="body1" color="black" fontSize={16} fontWeight={500}>
                        Other Staff:<span style={{ color: 'red' }}>*</span>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={8}
                    style={{
                        paddingTop: screenWidth === 'xs' ? 2 : null,
                    }}>
                    <StaffPickerMultiple {...props} />
                </Grid> */}
            </Grid>
        </Box >




    )
}

export default BasicInfo;


