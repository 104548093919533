import { collection, getDocs, getDoc, addDoc, updateDoc, doc, getFirestore, deleteDoc, writeBatch, setDoc, where, query, orderBy, limit, startAfter } from 'firebase/firestore';
import { getStorage, ref, getMetadata, uploadBytes, getDownloadURL } from "firebase/storage";
import moment from 'moment';
import uuid from 'react-uuid';



export const getFolderStructure = async (company) => {
  const db = getFirestore();
  try {
    const folderSnapshot = await getDocs(collection(db, 'clients', company, 'imageAlbums'));
    const folders = folderSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
      type: doc.data().type || 'folder',
      selected: false,
    }));
    return folders;
  } catch (error) { 
    console.error('Error fetching folder structure:', error);
    return [];
  }
};


export const getItems = async (company, account, path, databasePath,) => {
  const db = getFirestore();
  try {

    const folderRef = collection(db, "clients", company, databasePath);
    const folderQuery = query(folderRef, where("parent", "==", path), where('account', '==', account), orderBy("name"));
    const folderSnapshot = await getDocs(folderQuery);
    const folders = folderSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
      type: doc.data().type || 'folder',
      selected: false,
    }));
    folders.sort((a, b) =>
      a.name.localeCompare(b.name))
   
    return folders;
  } catch (error) {
    console.error("Error fetching folder structure:", error);
    return [];
  }
};

export const getFiles = async (company, account, path, databasePath, page, pageSize, lastDoc) => {
  const db = getFirestore();
  try {
    
    const folderRef = collection(db, "clients", company, databasePath);
    let folderQuery = query(
      folderRef,
      where("parent", "==", path),
      where('account', '==', account),
      orderBy("name"),
      limit(pageSize)
    );

    if (lastDoc) {
      folderQuery = query(
        folderRef,
        where("parent", "==", path),
        orderBy("name"),
        startAfter(lastDoc),
        limit(pageSize)
      );
    }

    const folderSnapshot = await getDocs(folderQuery);
    const folders = folderSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
      type: doc.data().type || "folder",
      selected: false,
    }));
    
    console.log("Fetched folders:", folders);
    return { folders, lastDocRef: folderSnapshot.docs[folderSnapshot.docs.length - 1] };
  } catch (error) {
    console.error("Error fetching folder structure:", error);
    return { folders: [], lastDocRef: null };
  }
};






export const addOldAlbums = async (company, account, user,) => {
  const db = getFirestore();

  try {
    const folderRef = collection(db, "clients", company, 'albums');
    const folderQuery = query(folderRef, where("account", "==", account));
    const folderSnapshot = await getDocs(folderQuery);
    const folders = folderSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    for (const document of folders) {


      const folderData = {
        name: document.name,
        id: document.id,
        parent: 'root',
        account: document.account,
        company: document.company,
        type: 'folder',
        createdBy: document.createdBy || 'admin'
      };


      const folderRef = doc(db, 'clients', company, 'imageAlbums', document.id);
      if (document.name !== '') {
        await setDoc(folderRef, folderData);
      }

    }
  } catch (error) {
    console.error('Error adding file:', error);
  }
};
export const addOldPhotos = async (company, account, user) => {
  const db = getFirestore();

  try {
    const fileRef = collection(db, "clients", company, 'photos');
    const fileQuery = query(fileRef, where("account", "==", account));
    const fileSnapshot = await getDocs(fileQuery);
    const files = fileSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    for (const document of files) {


      const folderData = {
        id: document.id,
        name: document.name || 'none',
        downloadUrl: document.fileLink || document.downloadURL,
        account: document.account,
        company: document.company,
        type: 'file',
        createdDate: document.date ? moment(document.date, 'x').format('MM/DD/YYYY HH:mm:ss') : moment().format('MM/DD/YYYY HH:mm:ss'),
        createdBy: document.fileCreatorKey,
        size: document.size || 'none',
        parent: document.logKey !== undefined ? 'fieldLogPhotos' : document.albumKey !== undefined ? document.albumKey : 'root',
        dateTaken: document.date ? moment(document.date, 'x').format('MM/DD/YYYY HH:mm:ss') : moment().format('MM/DD/YYYY HH:mm:ss'),
        latitude: document.latitude || 'none',
        longitude: document.longitude || 'none',
        logKey: document.logKey || 'none',

      };

      const folderRef = doc(db, 'clients', company, 'images', document.id);
      await setDoc(folderRef, folderData);
    }
  } catch (error) {
    console.error('Error adding file:', error);
  }
};














export const addFolder = async (name, parent, company, account, user, id) => {
  const db = getFirestore();
  try {
    const folderData = {
      name,
      id,
      parent: parent || 'root',
      account,
      company,
      type: 'folder',
      createdBy: user,
    };

    const folderRef = doc(db, 'clients', company, 'imageAlbums', folderData.id);
    await setDoc(folderRef, folderData);


  } catch (error) {
    console.error('Error adding folder:', error);
  }
};

export const deleteFolders = async (folders, company) => {
  const db = getFirestore();
  try {
    const batch = writeBatch(db);
    folders.forEach((folder) => {
      const folderRef = doc(db, 'clients', company, 'imageAlbums', folder.id);
      batch.delete(folderRef);
    });
    await batch.commit();
    return 'success'
  } catch (error) {
    console.error('Error deleting folders:', error);
  }
};

export const deleteFiles = async (folders, company) => {
  const db = getFirestore();
  try {
    const batch = writeBatch(db);
    folders.forEach((folder) => {
      const folderRef = doc(db, 'clients', company, 'images', folder.id);
      batch.delete(folderRef);
    });
    await batch.commit();
    return 'success'
  } catch (error) {
    console.error('Error deleting folders:', error);
  }
};




export const addFile = async (name, folderId, downloadUrl, company, account, user, size, id, dateTaken, latitude, longitude, logKey, path) => {
  const db = getFirestore();


  try {
    const folderData = {
      id,
      name,
      downloadUrl,
      account,
      company,
      type: 'file',
      createdDate: moment().format('MM/DD/YYYY HH:mm:ss'),
      createdBy: user,
      size,
      parent: folderId,
      dateTaken,
      latitude,
      longitude,
      logKey: logKey || 'none',
      path

    };
  
    const folderRef = doc(db, 'clients', company, 'images', folderData.id);
    await setDoc(folderRef, folderData);
    return 'success'
  }
  catch (error) {

    console.error('Error adding file:', error);
  }
};


export const renameItem = async (name, type, folderId, fileId, company) => {
  const db = getFirestore();

  try {
    if (type === 'folder' || type === 'nullFile') {
      const folderRef = doc(db, 'clients', company, 'imageAlbums', fileId);
      await updateDoc(folderRef, { name });
    }
    else {
      const fileRef = doc(db, 'clients', company, 'images', fileId);
      await updateDoc(fileRef, { name });

    }
  } catch (error) {
    console.error('Error renaming item:', error);
  }
};
export const updateImageDateTaken = async (fileId, dateTaken, company) => {
  const db = getFirestore();

  try {
    const fileRef = doc(db, 'clients', company, 'images', fileId);
    await updateDoc(fileRef, { updatedDate: dateTaken });
  } catch (error) {
    console.error('Error updating item date:', error);
  }
};

export const moveFile = async (draggedItem, droppedOnItem, company) => {

  const db = getFirestore();


  try {


    //update file with new parent
    const folderRef = doc(db, 'clients', company, 'images', draggedItem.id);
    await updateDoc(folderRef, { parent: droppedOnItem.id });
    return 'success'


  } catch (error) {
    console.error('Error moving file:', error);
  }





};
export const moveFolder = async (draggedItem, droppedOnItem, company) => {

  const db = getFirestore();

  try {

  
    //update folder with new parent
    const folderRef = doc(db, 'clients', company, 'imageAlbums', draggedItem.id);
    await updateDoc(folderRef, { parent: droppedOnItem.id });
    return 'success'
  } catch (error) {
    console.log(error)
  }





};


export const moveLevel = async (company, item, path) => {
  //build a function to move folders up one level
  const db = getFirestore();
  try {
    const parent = doc(db, 'clients', company, "imageAlbums", item.parent);
    const parentSnapshot = await getDoc(parent);

    if (!parentSnapshot.exists()) {
      console.error('Error moving folder: parent not found');
      return;
    }

    const newParent = parentSnapshot.data().parent || 'root';

 


    const folderRef = doc(db, 'clients', company, path, item.id);
    await updateDoc(folderRef, { parent: newParent });
  } catch (error) {
    console.error('Error moving folder:', error);
  }
};




