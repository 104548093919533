import { Autocomplete, Button, CircularProgress, Divider, Grid, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material';
import { Col, Modal, Popconfirm, Row } from 'antd';
import moment from 'moment-timezone';
import React, { useEffect, useMemo, useState } from 'react';

import { codes } from './codes';
import 'react-data-grid/lib/styles.css';
import DataGrid, { SelectColumn, textEditor, Column, CheckboxFormatterProps, SortColumn, SortStatusProps } from 'react-data-grid';
import DownloadIcon from '@mui/icons-material/Download';
import Units from '../../../extra/units.json';
import DatePicker from "react-datepicker";
import axios from 'axios';
import uuid from 'react-uuid';
import { memo } from 'react';
import { Papa } from 'papaparse';
import { jsonToCSV } from 'react-papaparse';
import AddIcon from '@mui/icons-material/Add';
import { useRef } from 'react';
import { fullMobile } from '../../../account/account/util';
import { fetchData } from './dataFunctions';


const BulkEditor = (props) => {
    const { userInfo, screenWidth, openSnack, company, account, updateQuery, logRef, status, PPArray, WPArray, rainGauges, parameters, nodes, query, probes, codes,
        openBulkEdit, setBulkEdit, bulkEdit, setOpenBulkEdit, updateAllCodes, updateAllNodes, updateAllParameters, updateAllUnits, updateAllValues, unitList } = props;


    return (
        <Modal title="" visible={openBulkEdit} onCancel={() => {
            setOpenBulkEdit(false);

        }} footer={null} width={'60%'} style={{ borderRadius: 40 }} >
            <Row align="middle" style={{ padding: 20 }} gutter={[12, 12]} >
                <Col span={24} >
                    <Typography variant="h6" fontWeight={600} >Bulk Edit</Typography>
                </Col>
                <Col {...fullMobile(12, 12)} style={{}}>
                    <Typography variant="body1" fontWeight={600}>Value:</Typography>
                    <OutlinedInput
                        display="inline"
                        allowClear={true}
                        fullWidth notched={false} label="none" placeholder={"Value"} size="small"
                        defaultValue={bulkEdit.value}
                        type="number"
                        onChange={(e) => {
                            setBulkEdit({ ...bulkEdit, value: e.target.value })
                        }} />

                </Col>
                <Col {...fullMobile(8, 8)} style={{}}>

                    <Button
                        display="inline"
                        variant="outlined"
                        color="primary"
                        style={{
                            textTransform: 'none',
                            border: '1px solid #404050',
                            color: '#404050',
                        }}
                        onClick={updateAllValues}


                    >Update All Values</Button>
                </Col>
                <Col {...fullMobile(12, 12)} style={{}}>
                    <Typography variant="body1" fontWeight={600}>Parameter:</Typography>
                    <Autocomplete
                        size="small"
                        allowClear={true}
                        id="combo-box-demo"
                        value={bulkEdit.parameterid}
                        options={[...PPArray, ...parameters].sort((a, b) => a.label.localeCompare(b.label))}
                        filterSelectedOptions
                        renderInput={(params) => <TextField {...params} placeholder="Parameters" size="small" />}
                        onChange={(a, b) => {
                            setBulkEdit({ ...bulkEdit, parameterid: b })
                        }}

                    />
                </Col>
                <Col {...fullMobile(8, 8)} style={{}}>

                    <Button
                        display="inline"
                        variant="outlined"
                        color="primary"
                        style={{
                            textTransform: 'none',
                            border: '1px solid #404050',
                            color: '#404050',
                        }}
                        onClick={updateAllParameters}


                    >Update All Parameters</Button>
                </Col>
                <Col {...fullMobile(12, 12)} style={{}}>
                    <Typography variant="body1" fontWeight={600}>Node:</Typography>
                    <Autocomplete
                        size="small"
                        allowClear={true}
                        id="combo-box-demo"
                        value={bulkEdit.nodeid}
                        options={nodes}
                        filterSelectedOptions
                        renderInput={(params) => <TextField {...params} placeholder="Nodes" size="small" />}
                        onChange={(a, b) => {
                            setBulkEdit({ ...bulkEdit, nodeid: b })
                        }}

                    />
                </Col>
                <Col {...fullMobile(8, 8)} style={{}}>

                    <Button
                        display="inline"
                        variant="outlined"
                        color="primary"
                        style={{
                            textTransform: 'none',
                            border: '1px solid #404050',
                            color: '#404050',
                        }}

                        onClick={updateAllNodes}

                    >Update All Nodes</Button>
                </Col>
                <Col {...fullMobile(12, 12)} style={{}}>
                    <Typography variant="body1" fontWeight={600}>Units:</Typography>
                    <Autocomplete
                        size="small"
                        disableClearable={false}
                        id="combo-box-demo"
                        value={bulkEdit.unitid}
                        options={unitList}
                        filterSelectedOptions
                        renderInput={(params) => <TextField {...params} placeholder="Units" size="small" />}
                        onChange={(a, b) => {
                            setBulkEdit({ ...bulkEdit, unitid: b })
                        }}

                    />
                </Col>
                <Col {...fullMobile(8, 8)} style={{}}>

                    <Button
                        display="inline"
                        variant="outlined"
                        color="primary"
                        style={{
                            textTransform: 'none',
                            border: '1px solid #404050',
                            color: '#404050',
                        }}
                        onClick={updateAllUnits}


                    >Update All Units</Button>
                </Col>
                <Col {...fullMobile(12, 12)} style={{}}>
                    <Typography variant="body1" fontWeight={600}>Codes:</Typography>
                    <Autocomplete
                        size="small"
                        disableClearable={false}
                        multiple
                        id="combo-box-demo"
                        value={codes.filter((c) => bulkEdit.codes.includes(c.value))}
                        options={codes}
                        filterSelectedOptions
                        renderInput={(params) => <TextField {...params} placeholder="Codes" size="small" />}
                        onChange={(a, b) => {
                            const keys = b.map((c) => c.value)
                            setBulkEdit({ ...bulkEdit, codes: keys })
                        }}

                    />
                </Col>
                <Col {...fullMobile(8, 8)} style={{}}>

                    <Button
                        display="inline"
                        variant="outlined"
                        color="primary"
                        style={{
                            textTransform: 'none',
                            border: '1px solid #404050',
                            color: '#404050',
                        }}
                        onClick={updateAllCodes}



                    >Update All Codes</Button>
                </Col>

            </Row>

        </Modal >
    )
}

export default BulkEditor;  