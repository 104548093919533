import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Breadcrumbs, Button, CircularProgress, IconButton, Paper, Typography } from '@mui/material';
import { Col, Popconfirm, Row } from 'antd';
import { doc, getDoc, getFirestore, setDoc, updateDoc } from 'firebase/firestore';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import ItemForm from './itemForm';

export default class Item extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            type: this.props.type,
            item: {},
            loading: true,
            files: [],
            progressBars: {},
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }

    }
    componentDidMount() {
        window.scrollTo(0, 0);
        const { userInfo } = this.state;
        this.queryDataDoc(userInfo);
    }

    queryDataDoc = async (userInfo) => {
        const { type } = this.state;
        const db = getFirestore();

        const docRef = doc(db, "users", userInfo.id, 'currentItems', 'currentPS');
        const docSnap = await getDoc(docRef);

        if (docSnap.data().key === 'none') {
            this.setState({ item: { tempKey: uuid(), psType: type === 'service' ? 'services' : 'products', archived: false, }, loading: false })
        }
        else {
            this.getDocItem(docSnap.data().key)
        }
    }

    getDocItem = async (key) => {
        const { userInfo } = this.state;
        const db = getFirestore();
        const docRef = doc(db, "clients", userInfo.currentCompany, 'psItems', key);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            console.log(docSnap.data())
            this.setState({ item: docSnap.data(), loading: false })
        }
    }

    updateItem = (boo, val) => {
        const { item } = this.state;
        item[boo] = val;

        this.setState({ item })
    }
    updateEntireItem = (value) => {
        this.setState({ item: value })
    }

    render() {

        const { userInfo, item, screenWidth, type, loading, files, progressBars } = this.state;

        const SubmitButton = () => {
            const { item, userInfo } = this.state;
            let navigate = useNavigate();
            return (
                <Button style={{ textTransform: 'none', padding: 16, fontSize: 16 }} fullWidth variant="contained" color="primary"
                    onClick={async () => {
                        if (item.name === undefined || item.name.length === 0) {
                            this.props.openSnack('error', "Name required")
                        }
                        else {
                            const db = getFirestore();
                            const key = item.key || item.tempKey;
                            const docRef = doc(db, "clients", userInfo.currentCompany, 'psItems', key);

                            if (item.key) {
                                await updateDoc(docRef, item).then(() => {
                                    navigate(type === 'service' ? "/psHome" : '/psHome');
                                }).then(() => {
                                    this.props.openSnack('success', item.key ? "Updated item!" : "Created item!")
                                })
                            }
                            else {
                                await setDoc(docRef, { ...item, key: item.tempKey }).then(() => {
                                    navigate(type === 'service' ? "/psHome" : '/psHome');
                                }).then(() => {
                                    this.props.openSnack('success', item.key ? "Updated item!" : "Created item!")
                                })
                            }



                        }

                    }
                    }>
                    {item.key ? `Update ${type === 'service' ? 'Service' : 'Product'}` : `Create ${type === 'service' ? 'Service' : 'Product'}`}
                </Button >
            )
        }



        const Loading = (props) => {
            return (
                <div style={{ minHeight: '100vh' }}>
                    <Row justify="space-around" gutter={[32, 8]} align="middle" style={{ paddingTop: 18, }}>

                        <Col span={24} style={{ textAlign: 'center', fontSize: 24, fontFamily: 'Roboto, sans-serif', }}>
                            <CircularProgress />
                        </Col>
                    </Row>
                </div>
            )
        }



        const productKey = item.productType !== undefined ? item.productType.key : '';
        console.log(productKey)



        return (
            <Row style={{padding: screenWidth === 'xs' ? 15 : 60}}>
                <Col span={22}>
                    <Breadcrumbs>
                        <Link to="/" style={{ textDecoration: 'none', color: 'inherit', }}>
                            Accounts
                        </Link>

                        <Link to="/psHome" style={{ textDecoration: 'none', color: 'inherit', }} >Products and Services</Link>

                    </Breadcrumbs>
                </Col>
                <Col span={24} style={{ paddingTop: 15 }}>
                    {type === 'service' ? <Typography variant="h5">{item.key ? "Update a Service" : "Add a Service"}</Typography>
                        :
                        <Typography variant="h5">{item.key ? "Update a Product" : "Add a Product"}</Typography>}

                </Col>
                <Col  span={24} style={{ paddingTop: 15 }}>
                    <Typography variant="body1">Add an item that can be added to field logs.  This can be anything that is a service for one of your lakes.</Typography>
                </Col>
                <Col  span={24} style={{ paddingTop: 30 }}>
                    <Paper elevation={12} style={{ padding: 12, borderRadius: 12 }}>
                        {loading ? <Loading /> : <ItemForm type={type} userInfo={userInfo} openSnack={this.props.openSnack} />}
                    </Paper>
                </Col>
            </Row>
        );
    }
} 