import React, { useEffect, useState, useRef } from "react";


import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { faCheckCircle, faCircle, faCopy, faEye, faEyeSlash, faSquare, faSquareCheck } from '@fortawesome/free-regular-svg-icons';
import { faFilter, faRedo, faTrash, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Button, CircularProgress, Divider, IconButton, Tooltip, Typography, Badge, Switch, Checkbox, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { Col, Row } from 'antd';
import moment from 'moment-timezone';
import { Resizable } from 'react-resizable';
import { ReactSortable } from 'react-sortablejs';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';




import { Sortable, } from "sortablejs";
import TaskCalendar from "./taskCalendar";
import TasksSubHeader from "../components/tasksSubHeader";






const TasksCalendarHome = (
    {
        tasks,
        sortType,
        setTasks,
        setSortType,
        screenWidth,
        showCompleted,
        setShowCompleted,
        loading,
        setLoading,
        currentTask,
        setCurrentTask,
        deleteTask,
        updateTask,
        setTaskDrawer,
        duplicateTask,
        updateTasksPosition,
        headers,
        setHeaders,
        openSnack,
        getNestedProperty,
        columnFilters,
        setColumnFilters,
        fullTasks,
        sortItem,
        setSortItem,
        createTask,
        selectable,
        setSelectable,
        deleteSelectedTasks,
        bulkEditor,
        setBulkEditor,
        staff,
        accounts,
        updateMultipleTasks,
        items,
        setItems,
        stocks,
        userInfo,
        taskTemplates,
        deleteTaskTemplate,
        columnEditor,
        setColumnEditor,
        deleteHeader,
        colorCalendar,
        setColorCalendar,
        setCalendarEditor,
        calendarEditor,
        calendars,
        setCalendars
    }

) => {

    const [undoHistory, setUndoHistory] = useState([]);
    const [redoHistory, setRedoHistory] = useState([]);
    const [initialWidth, setInitialWidth] = useState(null);







    const props = {
        headers,
        setHeaders,
        openSnack,
        headers, setHeaders, redoHistory, setRedoHistory, undoHistory, setUndoHistory, tasks, initialWidth, getNestedProperty,
        columnFilters,
        setColumnFilters,
        fullTasks,
        setTasks,
        selectable,
        setSelectable,
        updateTask,
        setCurrentTask,
        currentTask,
        showCompleted,
        setTaskDrawer,
        deleteSelectedTasks,
        duplicateTask,
        deleteTask,
        updateTasksPosition,
        sortItem,
        setSortItem,
        setBulkEditor,
        bulkEditor,
        staff,
        accounts,
        updateMultipleTasks,
        getNestedProperty,
        items,
        setItems,
        stocks,
        userInfo,
        taskTemplates,
        deleteHeader,
        setShowCompleted,
        colorCalendar,
        setColorCalendar,
        setCalendarEditor,
        calendarEditor,
        setColumnEditor,
        calendars,
        setCalendars

    }












    return (
        <Row style={{}}>
            <TasksSubHeader {...props} type="calendar"

                setColumnEditor={setColumnEditor}
                setCalendarEditor={(e) => {
                    console.log(e)
                    setCalendarEditor(e)
                }}
            />

            <Col span={24} style={{
                padding: 20,
            }}>
                <TaskCalendar {...props} />
            </Col>



        </Row >
    );
};

export default TasksCalendarHome;
