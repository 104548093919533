import React, { useEffect, useState } from "react";


import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import SettingsIcon from '@mui/icons-material/Settings';
import { Autocomplete, Box, Button, ButtonGroup, Checkbox, CircularProgress, Divider, IconButton, InputAdornment, MenuItem, OutlinedInput, Select, TextField, Typography, Drawer, Input } from '@mui/material';
import { Col, Popconfirm, Row, Switch } from 'antd';
import moment from 'moment-timezone';
import { ReactSortable } from 'react-sortablejs';
import uuid from 'react-uuid';
import { faCheckCircle, faCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './taskList.css'
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import TasksSearch from "./tasksSearch";



const TasksTableHeader = (
    {
        searchQuery,
        setSearchQuery,
        activeTab,
        setActiveTab,
        screenWidth
    }

) => {






    return (
        <Row style={{}}>
            <Col span={24}>


                <Row >
                    <Col xs={24} sm={18} style={{
                        height: '70px',
                        borderBottom: '1px solid rgba(0,0,0,0.2)',
                        display: 'flex',
                        alignItems: 'flex-end', // Adjusted this line
                        justifyContent: 'flex-start', // Adjusted this line for better readability
                    }}>
                        <ButtonGroup size="small" aria-label="small button group" style={{
                            marginLeft: 10
                        }}>
                            <Button
                                key="tasks"
                                onClick={(e) => setActiveTab('tasks')}
                                color='primary'
                                variant={null}
                                style={{
                                    height: '50px',
                                    textTransform: 'none',
                                    borderRadius: 0,
                                    fontWeight: 400,
                                    fontSize: 18,
                                    color: activeTab === 'tasks' ? '#6fa2f1' : null,
                                    borderBottom: activeTab === 'tasks' ? '3px solid #3f51b5' : null
                                }}
                            >
                                Tasks
                            </Button>
                            <Button
                                key='calendar'
                                onClick={(e) => setActiveTab('calendar')}
                                color='primary'
                                variant={null}
                                style={{
                                    textTransform: 'none',
                                    borderRadius: 0,
                                    height: '50px',
                                    fontWeight: 400,
                                    fontSize: 18,
                                    color: activeTab === 'calendar' ? '#6fa2f1' : null,
                                    borderBottom: activeTab === 'calendar' ? '3px solid #3f51b5' : null
                                }}
                            >
                                Calendar
                            </Button>
                        </ButtonGroup>
                    </Col>

                    <Col xs={24} sm={6} style={{
                        height: '70px', display: 'flex', alignItems: 'center', justifyContent: 'right',
                        borderBottom: screenWidth === 'xs' ? 'none' :
                            '1px solid rgba(0,0,0,0.2)', paddingLeft: screenWidth === 'xs' ? 10 : 0
                    }}>
                        <TasksSearch searchQuery={searchQuery}
                            setSearchQuery={setSearchQuery}
                        />

                    </Col>

                </Row>

            </Col >



        </Row >
    );
};

export default TasksTableHeader;
