import React, { useState, useEffect } from "react";
import { Row, Col, Popconfirm } from "antd";
import { Button } from "@mui/material";
import uuid from "react-uuid";
import moment from "moment";

import AddIcon from '@mui/icons-material/Add';




const AddRowButton = ({ addRow, userInfo }) => {

    async function handleAddRow() {
        const newDate = moment(new Date()).format('MM/DD/YYYY HH:mm');
        const newRow = {
            key: uuid(),
            id: uuid(),
            time: newDate,
            parameterid: 'Double Click to Select',
            nodeid: 'Double Click to Select',
            value: '',
            unitid: '',
            logkey: '',
            account: userInfo.currentAccount,
            company: userInfo.currentCompany,
            exists: false,
            tags: [],
        };

        addRow(newRow);

    }

    return (
        <Button variant="outlined" color="primary" size="small" display="inline"
            style={{ textTransform: 'none', border: '1px solid #404050', color: '#404050', marginLeft: 10 }}
            onClick={handleAddRow} ><AddIcon style={{ paddingRight: 4 }} /> Add Data</Button >
    )
}


const TableButtons = ({ addRow, setBulkEdit, setOpenBulkEdit, deleteData, userInfo, selectedRowsKeys, dataSource }) => {

    return (
        <Col span={12} style={{ padding: 8, paddingLeft: 20, textAlign: 'right' }}>

            {selectedRowsKeys?.length > 0 && (
                <Button variant="outlined" color="primary" size="small" display="inline"
                    style={{ textTransform: 'none', border: '1px solid #404050', color: '#404050' }}
                    onClick={() => {
                        setOpenBulkEdit(true);
                        setBulkEdit({
                            parameterid: '', unitid: '', value: '', nodeid: '', unitid: '', codes: []
                        })
                    }}
                >Bulk Edit</Button>
            )}
            {dataSource === 'node' && (<AddRowButton addRow={addRow} userInfo={userInfo} />)}

            {selectedRowsKeys?.length > 0 && (
                <Popconfirm
                    title="Are you sure to delete these records?"
                    placement='leftTop'
                    onConfirm={async () => {
                        //get keys from data for selectedRowsList

                        console.log(selectedRowsKeys)
                        const deletedKeys = selectedRowsKeys;
                        deleteData(deletedKeys)



                    }
                    }>

                    <Button variant="outlined" color="primary" size="small" display="inline"
                        style={{ textTransform: 'none', border: '1px solid red', color: '#404050', marginLeft: 10 }}
                    >Delete</Button>
                </Popconfirm >

            )}

        </Col>
    )
}

export default TableButtons;