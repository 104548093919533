import React, { useState, useRef, useEffect } from "react";
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Typography,
    Button,
    Container,
    Grid
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faComment, faFileCsv } from "@fortawesome/free-solid-svg-icons";
import UploadTypeCards from "./uploadTypeCards";
import CSVUploadSteps from "./uploadCSV/csvUploadSteps";
import UploadCSVHome from "./uploadCSV/uploadCSVHome";
import { Row } from "antd";
import UploadCSVMultipleHome from "./uploadCSVMultiple/uploadCSVMultipleHome";

const WaterQualityUploadHome = ({ screenWidth, openSnack, userInfo }) => {
    const [uploadType, setUploadType] = useState(null);  // default to csv-single-row
    const [step, setStep] = useState(null);  // default to 0
    const [uploading, setUploading] = useState(false);



    async function returnHome() {
        setUploadType(null);
        setStep(null);
    }


    return (
        <Row>
            {uploadType === null && (<UploadTypeCards setUploadType={setUploadType} setStep={setStep} screenWidth={screenWidth} />)}
            {uploadType === 'csv-single-row' && (
                <UploadCSVHome
                    step={step}
                    setStep={setStep}
                    uploading={uploading}
                    setUploading={setUploading}
                    uploadType={uploadType}
                    setUploadType={setUploadType}
                    screenWidth={screenWidth}
                    openSnack={openSnack}
                    userInfo={userInfo}
                    returnHome={returnHome}
                />)}
            {uploadType === 'csv-multiple-row' && (
                <UploadCSVMultipleHome
                    step={step}
                    setStep={setStep}
                    uploading={uploading}
                    setUploading={setUploading}
                    uploadType={uploadType}
                    setUploadType={setUploadType}
                    screenWidth={screenWidth}
                    openSnack={openSnack}
                    userInfo={userInfo}
                    returnHome={returnHome}
                />
            )}
        </Row>
    );

};

export default WaterQualityUploadHome;
