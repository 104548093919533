import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Checkbox, CircularProgress, Divider, Fab, IconButton, Input, InputAdornment, MenuItem, OutlinedInput, Paper, Select, Typography } from '@mui/material';
import { Col, Modal, Popconfirm, Row } from 'antd';
import {
    collection, deleteDoc, doc,
    getDocs,
    getFirestore,
    query, setDoc, updateDoc,
    where
} from 'firebase/firestore';
import React from 'react';
import uuid from 'react-uuid';
import "../extra/hoverStyle.css";
import axios, * as others from 'axios';
import queryString from 'query-string';

export default class Alerts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            data: [],
            filteredData: [],
            filters: [],
            searchText: '',
            loading: true,
            currentItem: {},
            openModal: false,
            probesList: [],
            nextPage: '',
            token: '',
            matchProbe: [],
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
    }

    async componentDidMount() {
        window.scrollTo(0, 0)
        await this.queryData();
        const {userInfo } = this.state;

        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'probes'), where('account', '==', userInfo.currentAccount),);
        const data = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            data.push(doc.data())
        })
        //sort data by name
        data.sort((a, b) => (a.name > b.name) ? 1 : -1)
        //set probelist to be data
        this.setState({ probesList: data })
    }

    query2 = () => {
        const data1 = {
            grant_type: 'client_credentials',
            client_id: 'ebparks',
            client_secret: 'c42b37cea8ff4309bb7a90577e75fc12',
        };
        axios.post('https://mycorslake.herokuapp.com/https://www.hydrovu.com/public-api/oauth/token', queryString.stringify(data1))
            .then(response => {

                const USER_TOKEN = response.data.access_token;

                this.setState({ token: USER_TOKEN })
                this.getData1(USER_TOKEN, '');
            })
            .catch((error) => {
                console.log('error ' + error);
            });
    }

    getData = (token, nextPage) => {
        const { probesList } = this.state;
        const AuthStr = 'Bearer '.concat(token);
        axios.get(`https://mycorslake.herokuapp.com/https://www.hydrovu.com/public-api/v1/locations/list`,
            {
                headers: { Authorization: AuthStr, "X-ISI-Start-Page": `${nextPage}` },
            })
            .then(response => {
                // If request is good...
                const nextPage = response.headers["x-isi-next-page"];

                const array3 = probesList.concat(response.data);
                this.setState({ probesList: [...array3] })


                if (nextPage !== undefined) {
                    this.getData(token, nextPage)
                }
                else {
                    this.setState({ loading: false })
                    this.query2()
                }


            })
            .catch((error) => {
                console.log('error ' + error);
            });
    }

    getData1 = (token, nextPage) => {
        const { probesList } = this.state;
        const AuthStr = 'Bearer '.concat(token);
        axios.get(`https://mycorslake.herokuapp.com/https://www.hydrovu.com/public-api/v1/locations/list`,
            {
                headers: { Authorization: AuthStr, "X-ISI-Start-Page": `${nextPage}` },
            })
            .then(response => {
                // If request is good...
                const nextPage = response.headers["x-isi-next-page"];

                const array3 = probesList.concat(response.data);
                this.setState({ probesList: [...array3] })


                if (nextPage !== undefined) {
                    this.getData1(token, nextPage)
                }
                else {
                    this.setState({ loading: false })

                }


            })
            .catch((error) => {
                console.log('error ' + error);
            });
    }


    queryData = async () => {
        const data = [];
        try {
            const { userInfo } = this.state;

            const db = getFirestore();
            const queryData = query(collection(db, "clients", userInfo.currentCompany, 'testAdminAlerts'), where('account', '==', userInfo.currentAccount), where('archived', '!=', true));
            const snap = await getDocs(queryData);
            snap.forEach((doc) => {
                data.push(doc.data())
            });

            data.sort((a, b) => a.name.localeCompare(b.name))
            this.setState({ data, loading: false })
            this.filterData(data, '', [])
        } catch (e) {
            this.setState({ data, loading: false })
            this.filterData(data, '', [])
        }
    }

    filterData = (data, searchText, filters) => {
        const filteredData = data.filter(d => d.name.match(new RegExp(searchText, "i")))
        this.setState({ filteredData })

    }
    filterAlerts = (data, searchText,) => {
        const matchProbe = data.filter(d => `${d.id}` === searchText)
        this.setState({ matchProbe })
    }

    updateCurrentItem = (key, val) => {
        const { currentItem } = this.state;
        currentItem[key] = val;
        this.setState({ currentItem })
    }


    render() {
        const { userInfo, data, filteredData, loading, searchText, filters, currentItem, openModal, screenWidth, probesList: probesList } = this.state;
        const db = getFirestore();
        const CreateItemButton = () => {
            return (
                <Fab style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="medium"
                    onClick={async () => {
                        this.setState({ openModal: true, currentItem: { tempKey: uuid() } });
                    }}>
                    <AddIcon />
                </Fab>
            )
        }

        const EditItem = ({ item }) => {
            return (
                <IconButton style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="small"
                    onClick={async () => {
                        this.setState({ openModal: true, currentItem: item })
                    }}>
                    <EditIcon />
                </IconButton>
            )
        }

        const DeleteItem = ({ item }) => (
            <Popconfirm
                title="Are you sure to delete this alert?"
                onConfirm={async () => {
                    const itemRef = doc(db, "clients", userInfo.currentCompany, 'testAdminAlerts', item.key);
                    await deleteDoc(itemRef)
                        .then(() => {
                            const newData = filteredData.filter((f) => f.key !== item.key)
                            this.setState({ filteredData: newData })
                            this.props.openSnack('success', 'Alert removed.')
                        })
                }}
                okText="Yes"
                cancelText="No"
            >
                <IconButton style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="small"
                >
                    <DeleteForeverIcon />
                </IconButton>
            </Popconfirm>
        )


        const RowItem = ({ item }) => {

            return (
                <Col span={24} style={{ cursor: 'pointer', padding: 4 }} className="rowHover"
                >
                    <Row gutter={[8, 8]} align="middle" >

                        <Col xs={14} sm={20} onClick={async () => {
                            this.filterAlerts(probesList, `${item.id}`)
                            this.setState({ currentItem: item, openModal: true })
                        }}>
                            <Typography fontWeight={500} variant='subtitle1'>{item.name}</Typography>
                            <Typography fontSize={13} variant='body1'>{item.probeId} | {item.active ? "Active" : "Not Active"}</Typography>
                        </Col>
                        <Col xs={10} sm={4} style={{ textAlign: 'right' }}>
                            <EditItem item={item} /> <DeleteItem item={item} />
                        </Col>


                        <Col span={24}>
                            <Divider />
                        </Col>

                    </Row>
                </Col>
            )
        }

        const ActionButton = () => {
            return (
                <Button size="small" style={{ padding: 10, borderRadius: 4, textTransform: 'none', fontSize: 14, }}
                    variant='contained' color="primary"
                    onClick={async () => {
                        const { currentItem } = this.state;

                        const requiredKeys = ['name', 'probeId'];
                        for (let index = 0; index < requiredKeys.length; index++) {
                            const value = currentItem[requiredKeys[index]] || "";
                            if (value.length === 0) {
                                this.props.openSnack('error', `Alert ${requiredKeys[index]} required!`);
                                return;
                            }
                        }

                        const key = currentItem.key || currentItem.tempKey;
                        currentItem.archived = currentItem.archived || false;

                        currentItem.account = userInfo.currentAccount;
                        currentItem.companyKey = userInfo.currentCompany;

                        const docRef = doc(db, "clients", userInfo.currentCompany, 'testAdminAlerts', key);

                        if (currentItem.key) {
                            await updateDoc(docRef, currentItem).then(() => {
                                this.filterAlerts(probesList, '')
                                const index = filteredData.map((d) => d.key).indexOf(currentItem.key)
                                filteredData[index] = currentItem;
                                this.setState({ filteredData: [...filteredData], openModal: false, currentItem: {}, matchProbe: [] })
                            }).then(() => {
                                this.props.openSnack('success', currentItem.key ? 'Alert updated!' : "Alert created!")
                            })
                        }
                        else {
                            await setDoc(docRef, {
                                ...currentItem,
                                key: currentItem.tempKey
                            }).then(() => {
                                filteredData.push({ ...currentItem, key: currentItem.tempKey })
                                this.filterAlerts(probesList, '')
                                this.setState({ filteredData, openModal: false, currentItem: {}, matchProbe: [] })
                            }).then(() => {
                                this.props.openSnack('success', currentItem.key ? 'Alert updated!' : "Alert created!")
                            })
                        }
                    }}>{currentItem.key ? 'Update Alert' : "Create Alert"}</Button>
            )
        }

        return (
            <Row style={{}} align="middle">
                <Col span={23}>
                    <Row align="middle">
                        <Col xs={16} sm={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>
                            <Typography variant="h4">Admin Alerts</Typography>
                        </Col>
                        <Col xs={6} sm={4} style={{ paddingTop: 30, paddingBottom: 15, textAlign: 'right', position: 'relative', zIndex: 0 }}>
                            {loading ? <CircularProgress /> : <CreateItemButton />}
                        </Col>
                        <Col span={24}>
                            <Divider />
                        </Col>
                    </Row>
                </Col>

                <Col span={23} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 30 }}>
                    {loading ? <CircularProgress /> :
                        <Paper elevation={16} style={{ padding: 20, borderRadius: 12 }}>
                            <Row>
                                <Col xs={24} sm={12} style={{ paddingBottom: 8 }}>
                                    <Input fullWidth
                                        autoComplete='off'
                                        value={searchText}
                                        onChange={(e) => {
                                            this.setState({ searchText: e.target.value })
                                            if (e.target.value.length > 1) {
                                                this.filterData(data, e.target.value, filters)
                                            }
                                            else {
                                                this.filterData(data, '', filters)
                                            }
                                        }}
                                        id="input-with-icon-adornment"
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        }
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    edge="end"
                                                    onClick={() => {
                                                        this.setState({ searchText: '' })
                                                        this.filterData(data, '', filters)
                                                    }}
                                                >
                                                    <HighlightOffIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </Col>
                                <Col xs={0} sm={12} style={{ textAlign: 'right' }}>
                                    <Typography variant='h6'>Actions</Typography>
                                </Col>

                                <Col span={24}>
                                    <Row align='middle' >
                                        {filteredData.map((d) => {
                                            return (
                                                <RowItem item={d} />

                                            )

                                        })}
                                    </Row>
                                </Col>
                            </Row>
                        </Paper>
                    }
                </Col>

                <Modal title="" visible={openModal} onCancel={() => this.setState({ openModal: false })} footer={null} width={'60%'} style={{ borderRadius: 40 }} >
                    <Row align="middle" gutter={[16, 16]} >
                        <Col xs={{ offset: 0, span: 24 }} sm={{ offset: 6, span: 18 }} style={{ paddingBottom: 12, }}>
                            <Typography variant='h5'>Admin Alert</Typography>
                        </Col>


                        <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                            <Typography variant="body1">Alert Name<span style={{ color: 'red' }}>*</span></Typography>
                        </Col>
                        <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                            <OutlinedInput fullWidth notched={false} label="none" placeholder={"Name"} size="small"
                                value={currentItem.name || ''}
                                onChange={(e) => {
                                    this.updateCurrentItem(`name`, e.target.value)
                                }} />
                        </Col>
                        <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                            <Typography variant="body1">Probe<span style={{ color: 'red' }}>*</span></Typography>
                        </Col>
                        <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                            <Select size="small" fullWidth
                                notched={false}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={currentItem.probeId || ''}
                                label="Probe"
                                onChange={(e) => {
                                    this.updateCurrentItem(`probeId`, e.target.value)
                                }}

                            >
                                {probesList.map(({ id, name }) => {
                                    return (
                                        <MenuItem key={id} value={id}>{name}</MenuItem>
                                    )
                                })}


                            </Select>
                        </Col>
                        {/*Active Checkbox*/}
                        <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                            <Typography variant="body1">Active <span style={{ color: 'red' }}>*</span></Typography>
                        </Col>
                        <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                            <Checkbox checked={currentItem.active} onChange={(e) => {
                                this.updateCurrentItem(`active`, e.target.checked)
                            }} />
                        </Col>
                        <Col xs={{ offset: 0, span: 24 }} sm={{ offset: 6, span: 18 }} style={{ paddingBottom: 12, }}>
                            <ActionButton />
                        </Col>

                    </Row>
                </Modal>
            </Row>
        );
    }
}