import React, { useEffect, useState } from "react";


import { Box, Button, ButtonGroup, IconButton, InputAdornment, ListItem, Modal, OutlinedInput, Typography, FormControl, InputLabel, Select, MenuItem, Popover } from '@mui/material';
import { Col, Row } from 'antd';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import SelectOptions from "./drawercomponents/customheaderComponents/selectOptions";
import CustomHeaderNumberFormat from "./drawercomponents/customheaderComponents/numberFormat";
import ItemSelect from "./drawercomponents/itemSelect";
import { faBoxesPacking, faBoxesStacked, faFlask, faMoneyBill, faPercent, faPlus, faX } from "@fortawesome/free-solid-svg-icons";
import { faCheck, faCircleChevronDown, faHouse, faHashtag, faFont } from "@fortawesome/free-solid-svg-icons";
import { faSquareCheck, faCalendar, faUser } from "@fortawesome/free-regular-svg-icons";
import uuid from "react-uuid";
import moment from "moment";

const CalendarEditor = (
    {
        screenWidth,
        headers,
        setHeaders,
        calendarEditor,
        setCalendarEditor,
        openSnack,
        userInfo,
        createCalendar,
        calendars,
        setCalendars



    }

) => {


    const [activeTab, setActiveTab] = useState('calendars');
    const [isFocused, setIsFocused] = useState(false);
    const [customSearch, setCustomSearch] = useState('');
    const [currentCalendar, setCurrentCalendar] = useState(null);


    const [deletePopoverAnchorEl, setDeletePopoverAnchorEl] = useState(null);
    const [calendarToDelete, setCalendarToDelete] = useState(null);

    const openDeletePopover = (event, calendar) => {
        setDeletePopoverAnchorEl(event.currentTarget);
        setCalendarToDelete(calendar);
    };

    const closeDeletePopover = () => {
        setDeletePopoverAnchorEl(null);
        setCalendarToDelete(null);
    };

    const confirmDeleteCalendar = () => {
        const newCalendars = calendars.filter(cal => cal.id !== calendarToDelete.id);
        setCalendars(newCalendars);
        closeDeletePopover();
    };




    useEffect(() => {
        if (calendarEditor === true) {
            setActiveTab('calendars');
            setCustomSearch('');
        }
    }, [calendarEditor]);



    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: screenWidth === 'xs' ? '90vw' : '60vw',
        bgcolor: 'background.paper',
        border: '1px solid #000',
        borderRadius: '10px',
        boxShadow: 24,
        maxHeight: '90vh',
        p: 0,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
    };

    // For Row 1 and Row 3
    const rowFixedStyle = {
        flexShrink: 0,
    };

    // For Row 2
    const rowScrollableStyle = {
        flexGrow: 1,
        overflowY: 'auto',
        overflowX: 'hidden',
    };












    return (
        <div style={{ marginTop: 20, marginBottom: 20 }}>


            <Modal title="" open={calendarEditor}



                onClose={() => {
                    setCalendarEditor(false);


                }} footer={null} width={'60%'}  >
                <Box sx={style}>

                    <Row style={rowFixedStyle} className="row1">
                        <Col span={20} style={{ padding: 30 }}>
                            <Typography variant="h6" component="div" fontWeight={600} fontSize={28}>
                                Google Calendars Sync
                            </Typography>
                        </Col>
                        <Col span={4} style={{
                            textAlign: 'right',
                            paddingTop: 30, paddingRight: 20,
                        }}>
                            <IconButton
                                style={{


                                }} aria-label="close" onClick={() => {
                                    setCalendarEditor(false)
                                }}>
                                <FontAwesomeIcon icon={faX} size="md"
                                    onClick={() => {
                                        setCalendarEditor(false)
                                    }} />
                            </IconButton>
                        </Col>

                        <Col xs={24} sm={24} style={{
                            height: '50px',
                            borderBottom: '1px solid rgba(0,0,0,0.2)',
                            display: 'flex',
                            alignItems: 'flex-end', // Adjusted this line
                            justifyContent: 'flex-start', // Adjusted this line for better readability
                        }}>
                            <ButtonGroup size="small" aria-label="small button group" style={{
                                marginLeft: screenWidth === 'xs' ? 20 : 40
                            }}>
                                {[
                                    { label: 'Calendars', value: 'calendars' },
                                    { label: `${currentCalendar?.id ? 'Edit' : 'Create'}`, value: 'edit' },
                                ].map((item, index) => (
                                    <Button
                                        key={item.value}
                                        onClick={() => {
                                            setActiveTab(item.value)
                                            if (item.value === 'calendars') {
                                                setCustomSearch('');
                                                setCurrentCalendar(null);
                                            }

                                        }}
                                        color='primary'
                                        variant={null}
                                        sx={{
                                            px: 0
                                        }}
                                        style={{
                                            marginRight: 20,
                                            height: '50px',
                                            textTransform: 'none',
                                            borderRadius: 0,
                                            fontWeight: activeTab === item.value ? 700 : 400,
                                            fontSize: 18,
                                            color: activeTab === item.value ? 'rgba(0,0,0,0.9)' : null,
                                            borderBottom: activeTab === item.value ? '3px solid rgba(0,0,0,0.9)' : '3px solid rgba(0,0,0,0.0)'
                                        }}
                                    >
                                        {item.label}
                                    </Button>
                                ))}
                            </ButtonGroup>
                        </Col>

                    </Row>





                    {activeTab === 'calendars' ?
                        <Box>
                            <Row style={{
                                paddingBottom: 20,

                            }} >
                                <Col span={24} style={{
                                    padding: 20
                                }}>
                                    <OutlinedInput

                                        fullWidth
                                        style={{
                                            borderRadius: 16,
                                            fontSize: 16,


                                        }}
                                        sx={{
                                            px: 2,
                                            py: 0,

                                        }}

                                        value={customSearch}
                                        placeholder='Find a field in your account'
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        }
                                        endAdornment={
                                            <InputAdornment position="end">
                                                {customSearch !== '' ? (
                                                    <IconButton
                                                        aria-label="clear search term"
                                                        edge="end"
                                                        onClick={() => setCustomSearch('')}
                                                    >
                                                        <ClearIcon />
                                                    </IconButton>
                                                ) : (
                                                    // Transparent placeholder for the ClearIcon
                                                    <IconButton style={{ visibility: 'hidden' }}>
                                                        <ClearIcon />
                                                    </IconButton>
                                                )}
                                            </InputAdornment>
                                        }
                                        onChange={(e) => setCustomSearch(e.target.value)}


                                    />
                                </Col>
                                <Col span={24} style={{
                                    paddingLeft: 20,
                                    paddingRight: 20,
                                }}>
                                    {calendars?.map((cal, index) => {



                                        return (
                                            <ListItem key={index} dense button onClick={() => {
                                                setCurrentCalendar(cal);
                                                setActiveTab('edit');
                                            }}
                                                sx={{ borderBottom: '1px solid #e0e0e0', paddingTop: 1, paddingBottom: 1, display: 'flex', justifyContent: 'space-between' }}>
                                                <Typography variant="body1" color="black" fontSize={14} fontWeight={600}>
                                                    {cal.name}
                                                </Typography>
                                                <IconButton
                                                    onClick={(e) => {
                                                        e.stopPropagation(); // prevents the ListItem's click event from firing
                                                        openDeletePopover(e, cal);
                                                    }}
                                                    size="small"
                                                >
                                                    <FontAwesomeIcon icon={faX} size="xs" />
                                                </IconButton>
                                            </ListItem>
                                        )

                                    })}
                                </Col>
                            </Row>

                            <Popover
                                open={deletePopoverAnchorEl}
                                anchorEl={deletePopoverAnchorEl}
                                onClose={closeDeletePopover}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                            >
                                <div style={{ padding: '16px', maxWidth: '300px' }}>
                                    <Typography variant="h6"
                                        fontWeight={600}

                                    >Are you sure you want to delete this calendar?</Typography>
                                    <Typography variant="body1" fontSize={14} fontWeight={400} style={{ marginTop: '8px', marginBottom: 10 }}>
                                        Deleting {`${calendarToDelete?.name}`}  will remove it from everyone's google calendar.
                                    </Typography>

                                    <Button onClick={confirmDeleteCalendar}
                                        variant="contained"
                                        color="secondary"
                                        style={{
                                            marginRight: 10,
                                            textTransform: 'none',
                                            backgroundColor: '#3f51b5',
                                        }}
                                    >Confirm</Button>
                                    <Button onClick={closeDeletePopover} style={{
                                        border: '1px solid #3f51b5',
                                        color: '#3f51b5',
                                        textTransform: 'none'
                                    }} variant="outlined">Cancel</Button>
                                </div>
                            </Popover>
                        </Box>
                        :
                        <Box>
                            <Row style={rowFixedStyle} className="row1a">


                                <Col xs={24} sm={24}
                                    style={{
                                        paddingLeft: screenWidth === 'xs' ? 20 : 40,
                                        marginTop: 20,
                                        paddingRight: screenWidth === 'xs' ? 20 : 40,

                                    }} >
                                    <Row >
                                        <Col xs={24} sm={14} >
                                            <Typography variant="h6" component="div" color={'rgba(0,0,0,0.6)'} fontWeight={600} fontSize={16}>
                                                Calendar name
                                            </Typography>
                                            <OutlinedInput
                                                margin="dense"

                                                style={{
                                                    borderRadius: 12,
                                                    marginTop: screenWidth === 'xs' ? 2 : 5,
                                                    marginBottom: 20,
                                                    width: '100%',
                                                    fontSize: 16,
                                                }}
                                                value={currentCalendar?.name}
                                                onChange={(e) => {

                                                    setCurrentCalendar({ ...currentCalendar, name: e.target.value })
                                                }}
                                                placeholder={'Calendar Name'}
                                            />
                                        </Col>
                                        <Col xs={24} sm={14} >
                                            <Typography variant="h6" component="div" color={'rgba(0,0,0,0.6)'} fontWeight={600} fontSize={16} style={{
                                                display: 'inline'
                                            }}>
                                                Calendar owner:
                                            </Typography>
                                            <Typography variant="h6" component="div" color={'rgba(0,0,0,0.9)'} fontWeight={600} fontSize={16}>
                                                {`${userInfo?.firstName} ${userInfo?.lastName}`}
                                            </Typography>

                                        </Col>
                                        <Col xs={24} sm={14} >
                                            <Typography variant="h6" component="div" color={'rgba(0,0,0,0.6)'} fontWeight={600} fontSize={16}>
                                                Shared with
                                            </Typography>
                                            <OutlinedInput
                                                margin="dense"

                                                style={{
                                                    borderRadius: 12,
                                                    marginTop: screenWidth === 'xs' ? 2 : 5,
                                                    marginBottom: 20,
                                                    width: '100%',
                                                    fontSize: 16,
                                                }}
                                                value={currentCalendar?.name}
                                                onChange={(e) => {

                                                    setCurrentCalendar({ ...currentCalendar, name: e.target.value })
                                                }}
                                                placeholder={'Calendar Name'}
                                            />
                                        </Col>



                                    </Row>

                                </Col>

                            </Row>



                            <Row style={rowFixedStyle} className="row3">

                                <Col xs={24} sm={24} style={{
                                    borderTop: '1px solid rgba(0,0,0,0.2)',
                                    marginTop: 20,
                                    paddingTop: 20,
                                    paddingBottom: 40,
                                }}>
                                    <Row
                                        justify="end"
                                        align={'middle'}

                                        style={{
                                            padding: 20,

                                        }}>
                                        <Col style={{

                                        }}>
                                            <Button
                                                variant="default"

                                                style={{
                                                    textTransform: 'none',
                                                    borderRadius: 12,
                                                    fontWeight: 500,
                                                    fontSize: 16,
                                                    marginRight: 20,

                                                    height: 40,
                                                    border: '1px solid rgba(0,0,0,0.4)',


                                                }}
                                                onClick={() => {
                                                    setCalendarEditor(false);
                                                }}

                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                variant="contained"
                                                disabled={!currentCalendar?.name}
                                                style={{
                                                    textTransform: 'none',
                                                    borderRadius: 12,
                                                    fontWeight: 500,
                                                    fontSize: 16,
                                                    height: 40,
                                                    backgroundColor: !currentCalendar?.name ? 'rgba(0,0,0,0.2)' : '#3f51b5',
                                                    color: !currentCalendar?.name ? 'rgba(0,0,0,0.4)' : 'white',
                                                }}
                                                onClick={() => {
                                                    const newCalendars = [...calendars];
                                                    const calendarIndex = newCalendars.findIndex(cal => cal.id === currentCalendar.id);

                                                    if (calendarIndex !== -1) { // Editing existing calendar
                                                        newCalendars[calendarIndex] = currentCalendar;
                                                    } else { // Adding new calendar
                                                        const newCalendar = {
                                                            ...currentCalendar,
                                                            id: uuid(),
                                                            createdBy: userInfo.id,
                                                            createdOn: moment().format('x'),
                                                            company: userInfo.currentCompany,
                                                            owner: userInfo.id,
                                                            memberArray: [userInfo.id],
                                                            memberRoles: [
                                                                { id: userInfo.id, email: userInfo.email, access: 'owner' },
                                                            ],
                                                        }
                                                        newCalendars.push(newCalendar);
                                                        createCalendar(newCalendar)
                                                    }

                                                    setCalendars(newCalendars);
                                                    setActiveTab('calendars');
                                                    setCurrentCalendar(null);
                                                }}
                                            >
                                                {currentCalendar?.id ? 'Save calendar' : 'Create calendar'}
                                            </Button>

                                        </Col>

                                    </Row>

                                </Col>




                            </Row>

                        </Box>







                    }

                </Box>
            </Modal >
        </div >



    );
};

export default CalendarEditor;
