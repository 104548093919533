import React, { useState, useEffect } from 'react';

import FileTree from './FileTree/FileTree';
import AddItem from './AddItem/AddItem';
import { getFiles, getFolderStructure, getFolders } from '../firebase/firestore';
import { Col, Row } from 'antd';
import { fullMobile } from '../../util';
import { Divider, Typography } from '@mui/material';


const App = (props) => {
  const { userInfo, account, screenWidth, openSnack } = props;
  const [folderStructure, setFolderStructure] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [currentPath, setCurrentPath] = useState([]);

  useEffect(() => {

  }, []);

  const updateCurrentPath = (newPath) => {
    setCurrentPath(newPath);
    console.log(newPath)
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', backgroundColor: '#f6f8fe' }}>
      <Row>
        <Col {...fullMobile(23, 23)} style={{ padding: 15 }}>
          <Typography variant="h4">Your Images</Typography>
        </Col>
        <Col {...fullMobile(24, 24)}>
          <Divider />
        </Col>

        <Col {...fullMobile(23, 23)} style={{ padding: 15 }}>
          <h2>{selectedFolder?.name}</h2>
          <FileTree
            openSnack={openSnack}
            selectedFolder={selectedFolder}
            setSelectedFolder={(e) => {
              console.log(e)
            }}
            userInfo={userInfo}
            currentPath={currentPath}
            updateCurrentPath={updateCurrentPath}
          />
        </Col>
      </Row>
    </div>
  );
};

export default App;
