import { Button, Checkbox, Divider, OutlinedInput, Typography, Select, MenuItem, CircularProgress, Paper, IconButton } from '@mui/material';
import { Col, Row } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment-timezone';
import { fullMobile } from './util';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, } from 'firebase/firestore';
import { ChevronDown, ChevronRight, FileText, Folder } from "react-feather";

import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import uuid from 'react-uuid';
import { AddCircleOutlineOutlined, PlusOneOutlined, SearchOutlined, SortByAlphaRounded } from '@mui/icons-material';
import { Tree } from 'react-arborist';


import axios, * as others from 'axios';
import queryString from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { FolderIcon, LeafIcon } from './barComponents';

export default function StationsBar({ userInfo, screenWidth, openSnack, updateItems,
    addChild, company, account, itemsProps, currentItemProps, swapItems, addStation, selectStation, selectChild, currentChildProps, saveStations }) {

    const [loading, setLoading] = useState(true);
    const [locations, setLocations] = useState([]);
    const [items, setItems] = useState(itemsProps);
    const [currentItem, setCurrentItem] = useState(currentItemProps);
    const [currentChild, setCurrentChild] = useState(currentChildProps);
    const [search, setSearch] = useState('');
    const treeRef = useRef(null);
    const treeRef1 = useRef(null);
    const [data, setData] = useState([

    ]);

    const db = getFirestore();

    //volume and area should auto populate
    //volume will be acft/cubic feet/gal

    useEffect(() => {

        setItems(itemsProps);
    }, [itemsProps]);


    useEffect(() => {

        setCurrentItem(currentItemProps);
        //for each item in itemprops, add to items if not already there
        const newItems = [...items];
        itemsProps.forEach(item => {
            if (!newItems.find(i => i.key === item.key)) {
                newItems.push(item);
            }


        })

        //remove items that are not in itemsProps
        newItems.forEach((item, index) => {
            if (!itemsProps.find(i => i.key === item.key)) {
                newItems.splice(index, 1);
            }
        })

        //check if any items were updated
        itemsProps.forEach(item => {
            const index = newItems.findIndex(i => i.key === item.key);
            if (index !== -1) {
                if (newItems[index].name !== item.name) {
                    newItems[index].name = item.name;
                }
                if (newItems[index].children !== item.children) {
                    newItems[index].children = item.children;
                }
                //update visible 
                if (newItems[index].visible !== item.visible) {
                    newItems[index].visible = item.visible;
                }
            }
        })






        setItems(newItems);
    }, [itemsProps, currentItemProps]);


    useEffect(() => {
        setCurrentChild(currentChildProps);
    }, [currentChildProps]);








    const archivedIndex = items.findIndex(item => item.id === 'archived');


    function Node1({ node, style, dragHandle }) {
        /* This node instance can do many things. See the API reference. */
        const [bgColor, setBgColor] = useState('transparent');

        const nodeStyle = {
            ...style,
            cursor: 'pointer',
            backgroundColor: bgColor,
            borderRadius: 3,
            transition: 'background-color 0.3s',
        };
        return (
            <div
                style={nodeStyle}
                ref={dragHandle}
                onMouseOver={() => {
                    setBgColor('rgba(0, 0, 0, 0.1)');
                }}
                onMouseOut={() => {
                    setBgColor('transparent');
                }}
                onClick={() => {


                    if (node.data.id !== currentItem.id) {
                        selectChild({});
                    }
                    if (node.data.id === 'archived') {
                        selectStation({});
                        selectChild({});
                        setCurrentItem({});
                    }
                    else {

                        if (node.children === null) {
                            setCurrentItem({ ...node.parent.data });
                            selectStation(node.parent.data);
                            selectChild(node.data);
                            //setCurrentItem to node parent


                        }
                        else {
                            selectStation(node.data);
                            setCurrentItem(node.data);
                        }
                    }
                }}>

                {node.isLeaf ?
                    <LeafIcon node={node} company={userInfo.currentCompany} /> :
                    <IconButton display="inline" size="small" onClick={() => {
                        selectChild({});
                        node.toggle();
                    }}>
                        <FolderIcon node={node} />
                    </IconButton>}

                <Typography display="inline" variant={"body1"} fontSize={15} onClick={() => {

                }} fontWeight={[currentItem.key, currentChild.key].includes(node.data.key) ? 'bold' : 'normal'}>
                    {node.data.name}{!node.isLeaf && (` (${node.data.children.length})`)}</Typography>


                {!node.isLeaf && node.data.id !== 'archived' && (<IconButton
                    onClick={() => {
                        //update items
                        const itemIndex = items.findIndex(item => item.key === node.data.key);
                        const newItems = [...items];
                        newItems[itemIndex].visible = !newItems[itemIndex].visible;
                        const item = newItems[itemIndex];
                        updateItems(item)
                        setItems(newItems);
                    }}>
                    <FontAwesomeIcon fontSize={18}
                        icon={node.data.visible === true ? faEye : faEyeSlash} color="#9ec1c9" />
                </IconButton>)}
                {!node.isLeaf && node.data.id !== 'archived' &&
                    node.data.archived === false &&
                    (<IconButton display="inline" size="small" onClick={() => {
                        selectChild(node.data);
                        addChild(node);
                        node.open();


                    }}>
                        <AddCircleOutlineOutlined />
                    </IconButton>)}

            </div>
        );
    }

    function setSwapItems(items) {
        items.forEach((item, index) => {
            item.accountIndex = index;
        })
        setItems(items);
        swapItems(items);
    }



    const onMove = async ({ dragIds, parentId, index }) => {
        const db = getFirestore();

        if (parentId === null) {
            //remove from items and add to items at index 0
            const newItems = [...items];
            const movedItem = newItems.find(item => item.id === dragIds[0]);
            const filteredItems = newItems.filter(item => item.id !== dragIds[0]);
            filteredItems.unshift(movedItem);
            setSwapItems(filteredItems);
        }
        else {
            const isChild = items.find(item => item.id === parentId).children.find(item => item.id === dragIds[0]);
            if (isChild) {
                //remove from parent children and add to parent children at index  
                const newItems = [...items];
                const parentItem = newItems.find(item => item.id === parentId);
                const movedItem = parentItem.children.find(item => item.id === dragIds[0]);
                const filteredItems = parentItem.children.filter(item => item.id !== dragIds[0]);
                filteredItems.splice(index, 0, movedItem);
                parentItem.children = filteredItems;
                setSwapItems(newItems);
            }
            else {




                //remove from items and add at index parentindex + 1
                const newItems = [...items];
                const movedItem = newItems.find(item => item.id === dragIds[0]);
                const filteredItems = newItems.filter(item => item.id !== dragIds[0]);
                const parentIndex = filteredItems.findIndex(item => item.id === parentId);
                filteredItems.splice(parentIndex + 1, 0, movedItem);
                setSwapItems(filteredItems);
            }
        }


    }



    //create tree height, should be 85vh to pixels
    const treeHeight = window.innerHeight * 0.80;


    return (
        <Row style={{ fontFamily: 'Roboto, sans-serif', }} gutter={[12, 12]} align="middle">
            <Col {...fullMobile(24, 24)} >
                <Typography variant="h5" component="body1" display="inline" gutterBottom color={'#404050'}>
                    Stations
                </Typography>

            </Col>
            <Col {...fullMobile(24, 24)} >

                <Tabs >
                    <TabList>
                        <Tab>Stations</Tab>
                        <Tab>Archived</Tab>
                    </TabList>

                    <TabPanel>
                        <Row gutter={[0, 6]}
                            style={{}}>
                            <Col span={24}
                                ref={treeRef1} >
                                {itemsProps.length > 0 ?
                                    <Tree
                                        ref={treeRef}
                                        height={treeHeight}
                                        data={itemsProps.filter(item => item.archived === false && item.deleteItem === false)}
                                        onMove={onMove}
                                        indent={30}
                                        paddingTop={20}
                                        rowHeight={30}
                                        disableMultiSelection={true}
                                    >{Node1}</Tree>

                                    :
                                    <Typography variant="h6" fontSize={13} component="body1" display="inline" gutterBottom color={'#404050'}>
                                        No Stations
                                    </Typography>
                                }
                            </Col>
                        </Row>
                    </TabPanel>
                    <TabPanel>
                        <Row gutter={[0, 6]}
                            style={{}}>


                            {itemsProps.filter(item => item.archived === true).length > 0 ?
                                <Tree
                                    data={itemsProps.filter(item => item.archived === true && item.deleteItem === false)}
                                    onMove={onMove}
                                    height={treeHeight}
                                    indent={30}
                                    paddingTop={20}
                                    rowHeight={30}
                                    disableMultiSelection={true}
                                >{Node1}</Tree>

                                :
                                <Typography variant="h6" fontSize={13} component="body1" display="inline" gutterBottom color={'#404050'}>
                                    No Archived Stations
                                </Typography>
                            }
                        </Row>
                    </TabPanel>
                </Tabs>




            </Col>
            <Col {...fullMobile(24, 24)} >



                <Button fullWidth variant="contained" color="primary" size="small" display="inline" style={{ backgroundColor: '#404050', textTransform: 'none' }}
                    onClick={() => {
                        addStation();

                    }}>
                    Create Station
                </Button>
                {/*   <Button variant="outlined" display="inline" size="small" style={{ marginLeft: 10, textTransform: 'none', fontSize: 14, border: '1px solid #404050' }} onClick={() => {
                    openSnack('success', 'Stations Saved')
                    saveStations(items)
                }}>Save Stations</Button> */}
            </Col>




        </Row>
    );
}