

import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    IconButton,
    List,
    ListItem,
    Popover,
    Tooltip,
    Typography
} from '@mui/material';
import React from 'react';


const ConditionOptions = ({
    handleAddCondition,
    item

}) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [gettingData, setGettingData] = React.useState(false);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;




    return (
        <div style={{
            display: 'inline',

        }}>
            <IconButton onClick={handleClick}

            >
                <Tooltip title="Add condition" placement="bottom">
                    <FontAwesomeIcon icon={faPlusCircle}
                        size="md" style={{ color: '#3f51b5', cursor: 'pointer' }} />
                </Tooltip>



            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}

                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <List sx={{
                    width: 300,
                    padding: 0,

                }}>

                    <ListItem
                        key={'bulkEdit'}
                        dense
                        button
                        onClick={() => {
                            handleAddCondition(item.key, 'value')
                        }}
                        sx={{
                            borderBottom: '1px solid #e0e0e0',
                            paddingTop: 1,
                            paddingBottom: 1,
                        }}
                    >

                        <Typography variant="body1" color="black" fontSize={16} fontWeight={500}>+ Value Condition</Typography>
                    </ListItem>
                    <ListItem
                        key={'bulkEdit'}
                        dense
                        button
                        onClick={() => {
                            handleAddCondition(item.key, 'tag')

                        }}
                        sx={{
                            borderBottom: '1px solid #e0e0e0',
                            paddingTop: 1,
                            paddingBottom: 1,
                        }}
                    >

                        <Typography variant="body1" color="black" fontSize={16} fontWeight={500}>+ Tag Condition</Typography>
                    </ListItem>







                </List>




            </Popover>
        </div>
    );
};

export default ConditionOptions;
