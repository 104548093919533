import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SearchIcon from '@mui/icons-material/Search';
import { Card, Divider, Fab, IconButton, Input, InputAdornment, Paper, Typography } from '@mui/material';
import { Button, Col, Row, Table } from 'antd';
import { collection, doc, getDocs, getFirestore, query, updateDoc } from 'firebase/firestore';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { fullMobile } from '../waterQuality/stations/util';

export default class ChangeCompany extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            data: [],
            filteredData: [],
            filters: [],
            searchText: '',
            loading: true
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
            this.queryData()
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0)
        const { userInfo } = this.state;
        if (Object.values(userInfo).length === 0) {
            this.props.getUser()
        } else {
            this.queryData()
        }

    }

    queryData = async () => {
        const { userInfo } = this.state;
        const db = getFirestore();
        const queryData = query(collection(db, "clients",),);
        const data = [];
        const snap = await getDocs(queryData);

        snap.forEach((doc) => {

            if (['EuEZ93ByFZYs8bSUXoYsTt1zu0m2', 'eTevYsI9mQMxhtntd95HmRGasNx2', 'z4y8XVrxYGT7rO4R01Y8tQ2F5z83', 't9bOfGDqwDaQTYV6i008opscpjz2'].includes(userInfo.id)) {

                if (doc.data().archived !== true) {
                    data.push({
                        companyName: doc.data().companyName,
                        key: doc.data().key,
                        companyType: doc.data().companyType || null,
                    })
                }

            }
            else {
                if (userInfo.companies.includes(doc.data().key)) {

                    if (doc.data().archived !== true) {
                        data.push({
                            companyName: doc.data().companyName,
                            key: doc.data().key,
                            companyType: doc.data().companyType || null,
                        })
                    }

                }
            }


        });
        data.sort((a, b) => a.companyName.localeCompare(b.companyName))
        this.setState({ data, loading: false })

        this.filterData(data, '', [])
    }

    filterData = (data, searchText, filters) => {
        if (searchText.length === 0 && filters.length === 0) {
            const filteredData = data.map((d) => { return ({ ...d, visible: true }) })
            this.setState({ filteredData, })
        }
        else {
            const filteredData = data.filter(d => d.companyName.match(new RegExp(searchText, "i")))
            this.setState({ filteredData })
        }

    }

    render() {

        const { userInfo, data, filteredData, loading, searchText, filters } = this.state;
        const db = getFirestore();
        const ViewItem = ({ row }) => {
            let navigate = useNavigate();
            return (
                <IconButton color="primary" size="small" onClick={async () => {

                    const userRef = doc(db, "users", userInfo.id);

                    // Set the "capital" field of the city 'DC'
                    await updateDoc(userRef, {
                        currentAccount: '',
                        currentCompany: row.key
                    }).then(() => {
                        navigate("/");
                    })
                }}>
                    <EditIcon color="inherit" />
                </IconButton>
            )

        }


        const columns = [
            {
                title: 'Company',
                dataIndex: 'companyName',
                key: 'name',
                sorter: (a, b) => {
                    const c = a.companyName === null ? "" : a.companyName;
                    const d = b.companyName === null ? "" : b.companyName;
                    return c.localeCompare(d);
                }
            },
            {
                title: 'Type',
                dataIndex: 'companyType',
                key: 'companyType',
                render: (a) => <div>{a === null ? "None" :
                    a === 'municipality' ? "Municipality" :
                        a === 'district' ? "District" :
                            a === 'lakeManager' ? "Lake Manager" :
                                a === 'golfCourse' ? "Golf Course" :
                                    a === 'winery' ? "Winery" :
                                        a === 'citizenScientist' ? "Citizen Scientist" :
                                            a === 'irrigationDistrict' ? "Irrigation District" :
                                                a.label || "Municipality"

                }</div>,
                sorter: (a, b) => {
                    const c = a.companyType === null ? "" : a.companyType;
                    const d = b.companyType === null ? "" : b.companyType;
                    return c.localeCompare(d);
                }
            },


        ];

        const ClickableTable = () => {
            let navigate = useNavigate();
            return (
                <Table
                    size="small"
                    loading={loading}
                    style={{ fontFamily: 'Inter, sans-serif', cursor: 'pointer', fontSize: 16 }}
                    columns={columns}
                    dataSource={filteredData} scroll={{ x: 'auto' }}
                    pagination={{ pageSize: 100 }}
                    rowClassName={(record, index) => {
                        const rowClasses = ['custom-row'];
                        if (index % 2 === 1) {
                            rowClasses.push('odd-row');
                        }
                        return rowClasses.join(' ');
                    }}
                    onRow={(row, rowIndex) => {
                        return {
                            onClick: async (event) => {

                                const userRef = doc(db, "users", userInfo.id);

                                // Set the "capital" field of the city 'DC'
                                await updateDoc(userRef, {
                                    currentAccount: '',
                                    currentCompany: row.key
                                }).then(() => {
                                    this.props.clearAccount()
                                    this.props.changeCompany(row.key)
                                    this.props.updateUserInfo('currentAccount', '');
                                    this.props.updateUserInfo('currentCompany', row.key);
                                    navigate("/");
                                })
                            }, // click row
                        };
                    }}
                />
            )

        }
        const CreateItemButton = () => {
            let navigate = useNavigate();

            return (
                <Button
                    style={{
                        textTransform: 'none',
                        backgroundColor: '#404050',
                        color: 'white'

                    }} fullWidth variant="contained" color="primary"
                    onClick={async () => {
                        const userRef = doc(db, "users", userInfo.id,);
                        await updateDoc(userRef, {
                            currentAccount: 'none'
                        }).then(() => {
                            navigate("/createCompany");
                        })
                    }}>
                    Create New Account
                </Button>
            )
        }

        return (
            <div style={{ fontFamily: 'inter, sans-serif', backgroundColor: '#f6f8fe', minHeight: '100vh' }}>
                <Row style={{ position: 'relative', top: 0, }}>
                    <Col span={24}>
                        <Row style={{ backgroundColor: '#ffffff', borderBottom: '2px solid #e6e6e6', }} align="middle" >

                            <Col {...fullMobile(19, 19)} style={{
                                textAlign: 'left', paddingTop: 30,
                                paddingBottom: 20,
                                paddingLeft: 42,
                            }}>


                                <Typography variant="h2" fontWeight={700} fontSize={32} style={{ textAlign: 'left', color: 'black', paddingBottom: 0 }}>
                                    Accounts
                                </Typography>


                            </Col>

                            <Col span={4} style={{ textAlign: 'left', paddingLeft: 42, paddingRight: 30, paddingBottom: 20, paddingTop: 30, }}>
                                <CreateItemButton />

                            </Col>


                        </Row>



                    </Col>

                    <Col span={23} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 30 }}>

                        <Row>
                            <Col xs={24} sm={12} style={{ paddingBottom: 12 }}>
                                <Input fullWidth
                                    autoComplete='off'
                                    value={searchText}
                                    onChange={(e) => {
                                        this.setState({ searchText: e.target.value })
                                        if (e.target.value.length > 0) {
                                            this.filterData(data, e.target.value, filters)
                                        }
                                        else {
                                            this.filterData(data, '', filters)
                                        }
                                    }}
                                    id="input-with-icon-adornment"
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    }
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                onClick={() => {
                                                    this.setState({ searchText: '' })
                                                    this.filterData(data, '', filters)
                                                }}
                                            >
                                                <HighlightOffIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </Col>
                            <Col span={24}>
                                <Card
                                    sx={{ borderRadius: 2 }}
                                    elevation={4}>
                                    <ClickableTable />
                                </Card>
                            </Col>
                        </Row>


                    </Col>
                </Row>
            </div>
        );
    }
} 