import React, { useEffect, useState } from "react";
import {
    Box, Grid, Typography, OutlinedInput, FormControl, InputLabel, Select, MenuItem,
    InputAdornment,
    Button
} from '@mui/material';
import StaffPickerSingle from "../../../../staffPickerSingle";
import StaffPickerMultiple from "../../../../staffPickerMultipl";
import WorkingDays from "./workingDaysTable";
import uuid from "react-uuid";


const Days = (
    {
        record,
        items,
        setItems,
        screenWidth,
        openSnack,
        userInfo,
        updateRecord,
        stocks,
        setStocks,
        accounts,
        accountUsers,
        log,
        setDisableDrag

    }

) => {
    const [isFocused, setIsFocused] = useState(false);
    const [days, setDays] = useState(record?.days || []);
    const props = {
        accountUsers,
        record,
        updateRecord,
        userInfo,
        openSnack,
        days,
        setDays,
        setDisableDrag
    }

    async function addDay() {
        /* if first day use log?.startDate, if not first date use moment().format('x') for date and null for hours */
        const newDays = [...days];
        if (newDays.length === 0) {
            newDays.push({ date: log?.startDate, hours: null, id: uuid() })
        }
        else {
            newDays.push({ date: null, hours: null, id: uuid() })
        }
        setDays(newDays);
        updateRecord({ ...record, days: newDays })

    }




    return (
        <>
            {/* make a box for the days and add day button but have them be spread apart */}

            <Grid container alignItems="flex-end" style={{ marginBottom: 10 }}>
                <Grid item xs={12} style={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography variant="h6" component="div" color={'rgba(0,0,0,0.8)'} fontWeight={700} fontSize={13} style={{ display: "inline" }}>
                        Days

                    </Typography>
                    <Button variant="outlined" size="small"
                        style={{
                            marginLeft: 10,
                            textTransform: "none",
                            backgroundColor: "#3f51b5",
                            color: "white",
                        }}
                        onClick={() => {
                            addDay()
                        }}
                    >Add Day
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    {days.length === 0 ? <Typography variant="h6" component="div" color={'rgba(0,0,0,0.8)'} fontWeight={400} fontSize={13} style={{ display: "inline" }}>
                        No days added
                    </Typography> : null}
                    {days.length > 0 ?
                        <WorkingDays {...props} type="flItem" />
                        : null}
                </Grid>

            </Grid>

        </>
    );
};

export default Days;
