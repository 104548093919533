import { CircularProgress, Typography, Checkbox, OutlinedInput, MenuItem, Select } from '@mui/material';
import { Col, Row } from 'antd';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { getFirestore } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import React, { useEffect, useRef, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { Image, ImageButton } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './styleText.css'; // import the CSS file for custom styles
import { fullMobile } from './util';
import DataColorCompact from './dataColorCompact';
import TitleSettings from './titleSettings';

export default function TextSettings(props) {
    const {
        userInfo,
        screenWidth,
        openSnack,
        company,
        account,
        attributes,
        currentWidgetKey,
        updateAttributes,
    } = props;
    const [loading, setLoading] = useState(true);
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );
    const db = getFirestore();
    const timerRef = useRef(null);

    useEffect(() => {

        getInfo();
        console.log(attributes);

        if (attributes.text) {
            const contentState = convertFromRaw(JSON.parse(attributes.text));
            setEditorState(EditorState.createWithContent(contentState));
            setLoading(false);
        }
        else {
            setLoading(false);
        }
    }, []);

    async function getInfo() {
        setLoading(false);
    }

    const handleEditorChange = (state) => {
        setEditorState(state);

        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }


        const contentState = state.getCurrentContent();
        const contentRaw = JSON.stringify(convertToRaw(contentState));
        attributes.text = contentRaw;

        console.log(attributes); // Log the updated attributes

    };

    const uploadImage = async (file) => {
        const storage = getStorage();
        const fileRef = ref(storage, `images/${file.name}`);
        await uploadBytes(fileRef, file);
        const imageURL = await getDownloadURL(fileRef);
        return { data: { link: imageURL } };
    };

    const Loading = () => {
        if (loading) {
            return (
                <div className="loading">
                    <CircularProgress />
                    <Typography variant="body1">Loading Data</Typography>
                </div>
            );
        }
    };


    function updateWidget(boo, val) {
        attributes[boo] = val;



    }


    if (loading) {
        return <Loading />;
    }
    else {
        return (
            <Row gutter={[8, 8]} style={{ height: '100%', width: '100%', display: 'flex' }}>
                <Col span={24}>
                    <Typography variant="body1" display="inline">Transparent Background</Typography>   <Checkbox display="inline"
                        defaultChecked={attributes.transparentBackground}
                        onChange={(e) => {
                            console.log(e.target.checked);
                            attributes.transparentBackground = e.target.checked;
                        }}
                    />
                </Col>


                <Col span={24} style={{ paddingBottom: 10 }}>
                    <div className="comment-box-container">
                        <div className="comment-box">
                            <Editor
                                editorState={editorState}
                                onEditorStateChange={handleEditorChange}
                                toolbar={{
                                    image: {/*  */
                                        uploadCallback: uploadImage,
                                        alt: { present: true, mandatory: false },
                                        previewImage: true,
                                        inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                                    },
                                }}
                                wrapperClassName="comment-box-editor-wrapper"
                                editorClassName="comment-box-editor"
                                toolbarClassName="comment-box-toolbar"
                            />
                        </div>
                    </div>
                </Col>
                <Col span={24} style={{ paddingBottom: 10 }}>
                    <TitleSettings attributes={attributes} updateWidget={updateWidget} />
                </Col>


            </Row>
        );
    }
}
