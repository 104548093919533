import React, { useState } from "react";


import { faCheckCircle, faCircle } from '@fortawesome/free-regular-svg-icons';
import { faArrowRightFromBracket, faSpinner, faToggleOff, faToggleOn, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Button, Checkbox, Drawer, IconButton, Input, MenuItem, OutlinedInput, Select, Tooltip, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Divider, ButtonGroup } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { Col, Row } from 'antd';
import moment from 'moment-timezone';
import { getAllLocations } from "./hydroFunctions";
import KeysTable from "./settingsDrawer/keysTable";

const useStyles = makeStyles({
    underline: {
        "&:before": {
            borderBottom: "none", // Remove default underline
        },
        "&:after": {
            borderBottom: "none", // Remove default underline when focused
        },
        "&:hover:before": {
            borderBottom: "1px solid rgba(0, 0, 0, 0.42)", // Add underline on hover
        },
    },
    commentBox: {
        "&:before": {
            borderBottom: "none", // Remove default underline
        },
        "&:after": {
            borderBottom: "none", // Remove default underline when focused
        },
        "&:hover:before": {
            borderBottom: "none", // Add underline on hover
        },
    },
});

const SettingsDrawer = (
    {

        settingsDrawer,
        setSettingsDrawer,
        screenWidth,
        userInfo,
        openSnack,
        apiCredentials,
        setApiCredentials,
        setHydroProbes,
        loadingProbes,
        setLoadingProbes,
        keys,
        setKeys

    }

) => {
    const [newItem, setNewItem] = useState(false);
    const [newItemType, setNewItemType] = useState('product');
    const [itemModal, setItemModal] = useState(false);
    const [openMorePopover, setOpenMorePopover] = useState(false);
    const [anchoreMoreEl, setAnchorMoreEl] = useState(null);
    const [validationErrors, setValidationErrors] = useState({});
    const [comment, setComment] = useState("");
    const [deleteDialog, setDeleteDialog] = useState(false);
    const classes = useStyles();
    const [credentials, setCredentials] = useState(apiCredentials || {})
    const [activeTab, setActiveTab] = useState('apiKeys')













    return (
        <Drawer
            anchor={'right'}
            onClose={() => {
                setSettingsDrawer(false)
            }}
            open={settingsDrawer !== false}


        >
            <div
                style={{
                    height: '100vh',
                    position: 'relative',

                    width: screenWidth === 'xs' ? '90vw' : '50vw',
                }}
            >
                <div
                    style={{

                        paddingTop: 25,
                        paddingBottom: 5,

                        height: '8vh',
                        display: 'flex',
                        width: '100%',
                        alignContent: 'center',
                        alignItems: 'center',

                    }}
                >
                    <Row
                        align={'bottom'}
                        style={{
                            width: '100%',
                            display: 'flex',
                            //align items to the bottom of the row


                        }}>
                        <Col span={12} style={{ padding: 10 }}>
                            <Typography variant="h6" fontWeight={700} fontSize={24} >
                                Probe Settings
                            </Typography>
                        </Col>




                    </Row>




                </div>
                <div
                    style={{


                        height: '62vh',
                        width: '100%',

                        overflowY: 'auto',
                        overflowX: 'hidden',
                    }}
                >
                    <Row style={{
                        paddingBottom: 20,
                        paddingTop: 20,
                        width: '100%',
                    }}>
                        <Col xs={24} sm={24} style={{
                            marginBottom: 15,
                            height: '60px',
                            borderBottom: '1px solid rgba(0,0,0,0.2)',
                            display: 'flex',
                            alignItems: 'flex-end', // Adjusted this line
                            justifyContent: 'flex-start', // Adjusted this line for better readability
                        }}>
                            <ButtonGroup size="small" aria-label="small button group" style={{
                                marginLeft: 10
                            }}>
                                <Button
                                    key="apiKeys"
                                    onClick={(e) => setActiveTab('apiKeys')}
                                    color='primary'
                                    variant={null}
                                    style={{
                                        height: '50px',
                                        textTransform: 'none',
                                        borderRadius: 0,
                                        fontWeight: 400,
                                        fontSize: 18,
                                        color: activeTab === 'apiKeys' ? '#6fa2f1' : null,
                                        borderBottom: activeTab === 'apiKeys' ? '3px solid #3f51b5' : null
                                    }}
                                >
                                    API Keys
                                </Button>

                            </ButtonGroup>
                        </Col>
                        {activeTab === 'apiKeys' && (<Col key={'123'} span={24} >
                            <KeysTable keys={keys} setKeys={setKeys} userInfo={userInfo} screenWidth={screenWidth} openSnack={openSnack} company={userInfo?.company} account={userInfo?.account} />

                        </Col>
                        )}
























                    </Row>
                </div>
                <div
                    style={{
                        padding: 15,
                        height: '30vh',
                        width: '100%',
                        boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.55)',
                        backgroundColor: '#f9f8f8'
                    }}
                >
                    <div style={{ width: '100%' }}>

                    </div>

                    <div
                        style={{
                            marginTop: 10,
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        {loadingProbes ?
                            <Typography variant="h6" fontSize={12} style={{ display: 'inline', marginLeft: 20 }}>
                                {loadingProbes ? 'Loading Probes...' : ''}
                                <FontAwesomeIcon icon={loadingProbes ? faSpinner : faCheckCircle} spin={loadingProbes} size="xl" style={{ color: loadingProbes ? '#3f51b5' : '#3f51b5' }} />
                            </Typography>
                            :

                            <Button color={'primary'} variant={'contained'}
                                style={{
                                    backgroundColor: '#3f51b5',
                                    height: 40,
                                    marginLeft: 10,
                                    textTransform: 'none',
                                    display: "inline",
                                    marginRight: 20
                                }}

                                onClick={async () => {
                                    setLoadingProbes(true)
                                    const hydroProbes = await getAllLocations('bypass', keys, openSnack)
                                    setHydroProbes(hydroProbes)
                                    setLoadingProbes(false)
                                    openSnack('success', 'Probes Refreshed')
                                }
                                }
                            >
                                Refresh In-Situ Probes
                            </Button>}


                    </div>
                </div>

            </div >







        </Drawer >
    );
};

export default SettingsDrawer;
