import { Autocomplete, Button, CircularProgress, Divider, Grid, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material';
import { Col, Modal, Popconfirm, Row } from 'antd';
import moment from 'moment-timezone';
import React, { useEffect, useMemo, useState } from 'react';

import { codes } from './codes';
import 'react-data-grid/lib/styles.css';
import DataGrid, { SelectColumn, textEditor, Column, CheckboxFormatterProps, SortColumn, SortStatusProps } from 'react-data-grid';
import DownloadIcon from '@mui/icons-material/Download';
import Units from '../../../extra/units.json';
import DatePicker from "react-datepicker";
import axios from 'axios';
import uuid from 'react-uuid';
import { memo } from 'react';
import { Papa } from 'papaparse';
import { jsonToCSV } from 'react-papaparse';
import { useRef } from 'react';
import { fullMobile } from '../../../account/account/util';
import { fetchData } from './dataFunctions';
import BulkEditor from './bulkEditor';
import { TextEditor } from './editors';
import TableButtons from './TableButtons';
var randomColor = require('randomcolor');




function getComparator(sortColumn) {
    switch (sortColumn) {
        case 'parameterid':
        case 'nodeid':
        case 'unitid':

            return (a, b) => {
                return a[sortColumn].localeCompare(b[sortColumn]);
            };
        case 'value':

            return (a, b) => {
                return a[sortColumn] - b[sortColumn];
            };

        case 'time':
            return (a, b) => {
                return moment(a[sortColumn]).diff(moment(b[sortColumn]));
            };

        default:
            throw new Error(`unsupported sortColumn: "${sortColumn}"`);
    }
}


const DataTable = (props) => {
    const { userInfo, screenWidth, openSnack, company, account, updateQuery, status, PPArray, WPArray, rainGauges, query, parameters, nodes, probes, codes, updateEntireQuery } = props;

    const [rowCount, setRowCount] = useState(0);
    const [data, setData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedCell, setSelectedCell] = useState(null);
    const [selectedRowsKeys, setSelectedRowsKeys] = useState([]);
    const [unitList, setUnitList] = useState([]);
    const [sortColumns, setSortColumns] = useState([]);
    const [openBulkEdit, setOpenBulkEdit] = useState(false);
    const [bulkEdit, setBulkEdit] = useState({ parameterid: '', unitid: '', value: '', nodeid: '', unitid: '', codes: [] });
    const gridRef = useRef(null);
    const [value, setValue] = useState('');


    const sortedRows = useMemo(() => {
        if (sortColumns.length === 0) return data;

        return [...data].sort((a, b) => {
            for (const sort of sortColumns) {
                const comparator = getComparator(sort.columnKey);
                const compResult = comparator(a, b);
                if (compResult !== 0) {
                    return sort.direction === 'ASC' ? compResult : -compResult;
                }
            }
            return 0;
        });
    }, [data, sortColumns]);

    useEffect(() => {

        getInfo()





    }, [])

    function getInfo() {
        const unitList = Object.values(Units).map((u, i) => {
            return ({ label: u, key: u, unitid: Object.keys(Units)[i] })
        })
        setUnitList(unitList)

    }



    useEffect(() => {
        if (query.querying) {
            queryData(query)
        }
        if (query.dataSource !== query.dataSource) {
            clearData()
        }

    }, [query])

    function clearData() {
        setData([])
        setRowCount(0)
    }

    async function queryData(info) {


        try {
            const data = await fetchData(info, userInfo, PPArray, parameters, WPArray, nodes, probes, rainGauges, unitList, codes);
            if (data) {
                console.log(data)
                setData(data)
                updateEntireQuery({ ...query, data, querying: false })
                setRowCount(data.length)
                updateQuery('querying', false)
                openSnack('success', 'Query Complete')
            } else {
                setData([])
                updateEntireQuery({ ...query, data: [], querying: false })
                updateQuery('querying', false)
                openSnack('error', 'Query Failed')
            }
        } catch (error) {
            console.error('Error:', error);
            // Handle the error
        }






    }


    const columns = [
        SelectColumn,

        {
            key: 'time',
            name: 'Date',
            //data type of the column
            type: 'date',
            resizable: true,
            frozen: true,
            editor: (p) => {

                return (
                    <DatePicker dateFormat="MM/dd/yyyy hh:mm a" style={{ width: '200px' }} showTimeSelect timeFormat="h:mm a" timeIntervals={1}
                        timeCaption="Time"
                        portalId="root-portal"
                        autoFocus
                        shouldCloseOnSelect={false}
                        selected={[undefined, '', "Invalid date"].includes(p.row.time) ? new Date() :
                            new Date(moment(p.row.time, 'MM/DD/YYYY hh:mm a').format('MM/DD/YYYY hh:mm a'))}
                        onChange={(date) => {
                            const index = data.findIndex((d) => d.id === p.row.id);
                            const newData = [...data];
                            newData[index].time = moment(date).format('MM/DD/YYYY hh:mm a');
                            console.log(newData[index])
                            setData(newData);
                        }}

                        onClickOutside={() => {
                            const date = moment(p.row.time, 'MM/DD/YYYY hh:mm a').format('MM/DD/YYYY hh:mm a')
                            console.log("BLURR")
                            const index = data.findIndex((d) => d.id === p.row.id);
                            const newData = [...data];
                            newData[index].time = moment(date).format('MM/DD/YYYY hh:mm a');
                            console.log(newData[index])
                            updateDatabaseTime(newData[index])
                            setData(newData);
                        }
                        }





                    />
                )
            }
        },
        {
            key: 'parameterid',
            name: 'Parameter',
            resizable: true,
            formatter: (p) => {
                return <div style={{
                    color: p.row.parameterid === 'Double Click to Select' ? '#ff000078' : 'inherit',
                    fontWeight: p.row.parameterid === 'Double Click to Select' ? 500 : 'inherit', fontSize: '18px'
                }}>{p.row.parameterid}</div >
            },
            editor: (p) => {
                const value = p.row.parameterid === 'Double Click to Select' ? '' : p.row.parameterid;
                return (

                    <Select
                        autoFocus
                        value={value}
                        onChange={(e) => {
                            const selectedValue = e.target.value;
                            setValue(selectedValue);
                            const index = data.findIndex((d) => d.id === p.row.id);
                            const newData = [...data];
                            newData[index].parameterid = selectedValue;
                            updateDatabase(newData[index]);
                            setData(newData);
                        }}
                        style={{
                            width: '100%',
                            height: '100%',
                            border: '0',
                            background: 'transparent',
                            fontSize: '18px',
                        }}
                    >
                        <MenuItem value="" disabled>Select an option</MenuItem>
                        {[...PPArray, ...parameters].sort((a, b) => a.label.localeCompare(b.label)).map((p) => (
                            <MenuItem
                                key={p.key}
                                style={{
                                    fontSize: '18px',
                                    cursor: 'pointer',
                                }}
                                onMouseEnter={(e) => {
                                    e.currentTarget.style.backgroundColor = '#E3EEFA';
                                }}
                                onMouseLeave={(e) => {
                                    e.currentTarget.style.backgroundColor = 'transparent';
                                }}
                                value={p.label}
                            >
                                {p.label}
                            </MenuItem>
                        ))}
                    </Select>
                )
            }
        },
        {
            key: 'nodeid',
            name: query.dataSource === 'probe' ? 'Probe' : 'Node',
            resizable: true,
            formatter: (p) => {
                return <div style={{
                    //wrap text if it is too long

                    color: p.row.nodeid === 'Double Click to Select' ? '#ff000078' : 'inherit',
                    fontWeight: p.row.nodeid === 'Double Click to Select' ? 500 : 'inherit', fontSize: '18px'
                }}>{p.row.nodeid}</div >
            },
            editor: (p) => {

                const value = p.row.nodeid === 'Double Click to Select' ? '' : p.row.nodeid;

                return (

                    <Select
                        autoFocus
                        value={value}
                        style={{
                            width: '100%',
                            height: '100%',
                            border: '0',
                            background: 'transparent',
                            fontSize: '18px',
                        }}
                        onChange={(e) => {
                            const selectedValue = e.target.value;
                            const index = data.findIndex((d) => d.id === p.row.id);
                            const newData = [...data];
                            newData[index].nodeid = selectedValue;
                            updateDatabase(newData[index]);
                            setData(newData);
                        }}
                    >
                        <MenuItem value="" disabled>Select an option</MenuItem>
                        {query.dataSource === 'probe'
                            ? probes.sort((a, b) => a.name.localeCompare(b.name)).map((p) => (
                                <MenuItem key={p.id} value={p.name}>
                                    {p.name}
                                </MenuItem>
                            ))
                            : nodes.sort((a, b) => a.label.localeCompare(b.label)).map((p) => (
                                <MenuItem
                                    key={p.id}
                                    value={p.label}
                                    style={{
                                        fontSize: '18px',
                                        cursor: 'pointer',
                                    }}
                                    onMouseEnter={(e) => {
                                        e.currentTarget.style.backgroundColor = '#E3EEFA';
                                    }}
                                    onMouseLeave={(e) => {
                                        e.currentTarget.style.backgroundColor = 'transparent';
                                    }}
                                >
                                    {p.label}
                                </MenuItem>
                            ))}
                    </Select>

                )
            }
        },
        {
            key: 'value',
            name: 'Value',
            resizable: true,
            editor: (p) => {
                return (
                    <input
                        autoFocus
                        defaultValue={p.row.value}
                        style={{
                            width: '100%',
                            height: '100%',
                            border: '0',
                            background: 'transparent',
                        }}
                        onBlur={(e) => {
                            const index = data.findIndex((d) => d.id === p.row.id);
                            const newData = [...data];
                            newData[index].value = e.target.value;
                            updateDatabase(newData[index])
                            setData(newData);
                        }}
                    />
                )
            }
            //onchange event for the column

        },
        {
            key: 'unitid',
            name: 'Units',
            resizable: true,
            formatter: (p) => {

                return <div style={{
                    color: p.row.unitid === 'Double Click to Select' ? '#ff000078' : 'inherit',
                    fontWeight: p.row.unitid === 'Double Click to Select' ? 500 : 'inherit',
                }}>{p.row.unitid}</div >
            },
            editor: (p) => {

                return (

                    <select
                        autoFocus
                        value={p.row.unitid}
                        style={{
                            width: '100%',
                            height: '100%',
                            border: '0',
                            background: 'transparent',
                        }}
                        onChange={(e) => {
                            console.log(e.target.value)
                            const index = data.findIndex((d) => d.id === p.row.id);
                            const newData = [...data];
                            newData[index].unitid = e.target.value;
                            updateDatabaseUnits(newData[index])
                            setData(newData);
                        }}
                    >

                        {unitList.sort((a, b) => a.label.localeCompare(b.label)).map((p) => (
                            <option key={p.unitid}>{p.label}</option>
                        ))}

                    </select>
                )
            }
        },
        {
            key: 'tags',
            name: 'Codes',
            width: 100,

            formatter: (p) => {
                const values = p.row.tags === undefined ? [] : p.row.tags.map((t) => {
                    const tag = codes.find((p) => p.key === t);
                    return tag?.label || t
                })
                return <div style={{


                }}>{values === undefined ? '' : values.join()}</div >
            },
            editor: (p) => {







                return (

                    <Autocomplete
                        multiple
                        fullWidth
                        autoFocus
                        disableCloseOnSelect
                        ChipProps={{ size: 'small', variant: 'outlined', color: 'primary', fontSize: 10 }}
                        value={p.row.tags === undefined ? [] :
                            p.row.tags.map((t) => {
                                console.log(t);
                                console.log(codes)
                                return codes.find((c) => c.key === t)
                            })
                        }
                        placeholder="Codes"
                        label="Codes"
                        onChange={(e, value) => {

                            const index = data.findIndex((d) => d.id === p.row.id);
                            const newData = [...data];
                            const keys = value.map((v) => v.value);
                            newData[index].tags = keys;
                            setData(newData);
                            updateDatabase(newData[index])
                        }}
                        options={codes.sort((a, b) => a.label.localeCompare(b.label))}
                        getOptionLabel={(option) => option?.label}
                        renderInput={(params) => (
                            <TextField

                                {...params}
                                variant="standard"
                                placeholder="Codes"
                            />
                        )}
                    />
                )
            }
        },

    ];

    //create 50 rows with the schema above columns
    //create array from 0 to 50



    function rowKeyGetter(row) {
        return row.id;
    }
    //create array from selectedRows the data is like this Set(3) {0, 3, 4}[[Entries]]size: 3
    //so we need to convert it to an array

    function checkboxFormatter(
        { onChange, ...props }, ref
    ) {
        function handleChange(e) {
            onChange(e.target.checked, (e.nativeEvent).shiftKey);
        }

        return <input type="checkbox" ref={ref} {...props} onChange={handleChange} />;
    }

    function sortStatus({ sortDirection, priority }) {
        return (
            <>
                {sortDirection !== undefined ? (sortDirection === 'ASC' ? '\u2B9D' : '\u2B9F') : null}
                <span style={{
                    color: 'grey',
                    marginLeft: '2px'
                }}>{priority}</span>
            </>
        );
    }




    function isEqual(a, b) {
        return a === b;
    }

    async function updateDatabase(row) {
        const index = data.findIndex((d) => d.id === row.id);
        const newData = [...data];
        newData[index] = row;
        setData(newData);
        console.log(row)
        const key = row.key;
        const parameter = [...PPArray, ...parameters].find((p) => {
            return p.label === row.parameterid
        });

        const node = query.dataSource === 'probe' ? probes.find((n) => n.name === row.nodeid) : nodes.find((n) => n.label === row.nodeid);

        const unit = unitList.find((u) => u.key === row.unitid);
        console.log(unit)



        if (node.id !== undefined && parameter?.key !== undefined && row.value !== undefined && row.time !== undefined &&
            node.id !== '' && parameter?.key !== '' && row.value !== '' && row.time !== '') {

            const timestamp = moment(row.time, 'MM/DD/YYYY hh:mm a').format('X');
            const time = moment(row.time, 'MM/DD/YYYY hh:mm a').format('YYYY-MM-DD HH:mm:ss');



            const proxyUrl = "https://mycorslake.herokuapp.com/";

            if (row.exists !== undefined && row.exists === false) {
                console.log(row)
                console.log(unit)

                const item = {
                    time: `'${time}'`,
                    timestamp: Number(timestamp),
                    locationid: `'${node.id}'`,
                    unitid: `'${unit?.unitid || ''}'`,
                    parameterid: `'${parameter?.key}'`,
                    value: Number(row.value),
                    account: `'${node.account}'`,
                    company: `'${node.company}'`,
                    nodeid: `'${node.id}'`,
                    key: `'${key}'`,
                    longitude: node.lng,
                    latitude: node.lat,
                    logkey: `''`,
                    label: `''`,
                    tags: `'${JSON.stringify(row.tags)}'`,
                }


                console.log(item)
                const hourlyVals = [item].map((h) => `(${Object.values(h)})`);
                const hourlyString = hourlyVals.join();
                const queryString = `INSERT INTO node_data_new2 VALUES ${hourlyString} ON CONFLICT DO NOTHING; `

                axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                    raw: queryString
                }).then((res) => {



                    //find this item in data and delete exists
                    const index = data.findIndex((d) => d.id === row.id);
                    const newData = [...data];
                    delete newData[index].exists;
                    setData(newData);
                    openSnack('success', 'Database updated',)
                }).catch((err) => {
                    console.log(err)
                    console.log("error updating database")
                })

            }
            else {

                console.log(row)
                console.log(unit)



                const queryString = `UPDATE ${query.dataSource === 'probe' ? 'probe_data' : 'node_data_new2'} SET 
            ${query.dataSource === 'probe' ? '' : `nodeid = '${node.id.toString()}',`}
            parameterid = '${parameter?.key}'
            , value = ${Number(row.value)}
            , unitid = '${unit?.unitid || ''}'
            , tags = '${JSON.stringify(row.tags)}'
            , locationid = '${node.id.toString()}'
            ${query.dataSource === 'probe' ? '' : `, account = '${userInfo.currentAccount}',`}
            ${query.dataSource === 'probe' ? '' : `company = '${userInfo.currentCompany}'`}

            WHERE key = '${key}'
            ; `

                console.log(queryString)

                axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                    raw: queryString
                }).then(async (response) => {
                    // handle success

                    openSnack('success', 'Database updated',)

                }).catch((error) => {
                    openSnack('error', 'Error updating database',)
                })

            }
        }


    }

    async function updateDatabaseTime(row) {
        try {
            // Safely retrieve required properties.
            const { id, key, parameterid, nodeid, unitid, value, time, tags } = row || {};

            console.log(row)

            const parameter = [...PPArray, ...parameters].find((p) => p.label === parameterid);
            const node = (query.dataSource === 'probe' ? probes : nodes).find((n) => n.name === nodeid);
            const unit = unitList.find((u) => u.key === unitid);

            // More validation could be done here...
            if (!node?.id || !parameter?.key || !unit?.unitid) {
                throw new Error('Could not find matching parameter, node, or unit.');
            }

            // Convert time format.
            const timestamp = moment(time, 'MM/DD/YYYY hh:mm a').format('X');
            const formattedTime = moment(time, 'MM/DD/YYYY hh:mm a').format('YYYY-MM-DD HH:mm:ss');

            const proxyUrl = "https://mycorslake.herokuapp.com/";
            const newKey = uuid();
            const item = {
                time: `'${formattedTime}'`,
                timestamp: Number(timestamp),
                locationid: `'${node.id}'`,
                unitid: `'${unit.unitid}'`,
                parameterid: `'${parameter.key}'`,
                value: Number(value),
                account: `'${node.account}'`,
                company: `'${node.company}'`,
                nodeid: `'${node.id}'`,
                key: `'${newKey}'`,
                longitude: node.lng,
                latitude: node.lat,
                logkey: `'${row.logkey || ''}'`,
                label: `'${row.label || ''}'`,
                tags: `'${JSON.stringify(tags)}'`,
            };

            console.log(item)

            // Prepare queries.
            const insertQuery = `INSERT INTO ${query.dataSource === 'probe' ? 'probe_data' : 'node_data_new2'} VALUES (${Object.values(item).join()}) ON CONFLICT DO NOTHING;`;
            const deleteQuery = `DELETE FROM ${query.dataSource === 'probe' ? 'probe_data' : 'node_data_new2'} WHERE key = '${row.key}';`;

            // Run queries.
            await axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', { raw: deleteQuery });
            await axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', { raw: insertQuery });


            // Remove 'exists' from the row.
            const newData = data.map((d) => d.key === row.key ? { ...row, exists: undefined, key: newKey } : d);
            setData(newData);
            openSnack('success', 'Database updated');
        } catch (error) {
            console.error(error);

        }
    }
    async function updateDatabaseUnits(row) {
        try {
            // Safely retrieve required properties.
            const { id, key, parameterid, nodeid, unitid, value, time, tags } = row || {};

            console.log(row)

            const unit = unitList.find((u) => u.key === unitid);

            // More validation could be done here...
            if (!unit?.unitid) {
                throw new Error('Could not find matching unit.');
            }

            const proxyUrl = "https://mycorslake.herokuapp.com/";
            const tableName = query.dataSource === 'probe' ? 'probe_data' : 'node_data_new2';
            const newUnitId = `'${unit.unitid}'`;

            // Prepare queries.
            const updateQuery = `UPDATE ${tableName} SET unitid = ${newUnitId} WHERE key = '${key}';`;

            console.log(updateQuery);

            // Run queries.
            await axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', { raw: updateQuery });

            // Remove 'exists' from the row.
            const newData = data.map((d) => d.key === row.key ? { ...row, unitid: unit.label } : d);
            setData(newData);
            openSnack('success', 'Database updated');
        } catch (error) {
            console.error(error);
            openSnack('error', 'Error updating database');
        }
    }


    const ExportDataButton = memo(() => {




        return (
            <Button variant="outlined" color="primary" size="small" display="inline"
                style={{ textTransform: 'none', border: '1px solid #404050', color: '#404050', marginLeft: 10 }}
                onClick={() => {

                    //get company label

                    const csvData = data.map((d) => {
                        return {
                            time: d.time,
                            nodeid: d.nodeid,
                            parameterid: d.parameterid,
                            value: d.value,
                            unitid: d.unitid,
                            account: account.accountName || '',
                            company: company.companyName || '',
                        }
                    })
                    //change array into downloadable csv
                    const results = jsonToCSV(csvData);
                    const csvContent = "data:text/csv;charset=utf-8," + results;
                    const encodedUri = encodeURI(csvContent);
                    const link = document.createElement("a");
                    link.setAttribute("href", encodedUri);
                    link.setAttribute("download", "my_data.csv");
                    document.body.appendChild(link); // Required for FF

                    link.click(); // This will download the data file named "my_data.csv".



                }}
            ><DownloadIcon style={{ paddingRight: 4 }} />  Export Data</Button >
        )
    })




    function rowHeight(p) {
        // should be based on the content of the row
        const rowHeight = p?.row?.tags?.length < 2 ? 30 :
            p?.row?.tags?.length < 4 ? 60 :
                p?.row?.tags?.length < 6 ? 90 :
                    p?.row?.tags?.length < 8 ? 120 :
                        p?.row?.tags?.length < 10 ? 150 :

                            100;
        return rowHeight;
    }


    function updateAllCodes() {
        const selectedRows = selectedRowsKeys;
        const filterData = data.filter((d) => selectedRows.includes(d.key));
        const updatedFilterData = []
        const updateDatabaseList = [];
        filterData.forEach((d) => {
            const tags = bulkEdit.codes !== [] ? bulkEdit.codes : d.tags;
            updateDatabaseList.push({
                ...d,
                tags: tags
            })
            updatedFilterData.push({
                ...d,
                tags: tags
            })

        })
        const keys = updatedFilterData.map((d) => d.key);

        const newTags = bulkEdit.codes || [];


        const proxyUrl = "https://mycorslake.herokuapp.com/";
        axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
            raw: `UPDATE ${query.dataSource === 'probe' ? 'probe_data' : query.dataSource === 'rain' ? 'gauge_data' : 'node_data_new2'} SET 
                        tags = '${JSON.stringify(newTags)}'
                    WHERE key IN (${keys.map((e) => `'${e}'`).join(',')});`

        }).then(async (response) => {
            // handle success

            openSnack('success', 'Data updated!')




        }).catch((error) => {
            console.log(error)
        })










        updatedFilterData.forEach((r, i) => {
            //update data for each updatedFilterData 
            const index = data.findIndex((d) => d.key === r.key);
            data[index] = r;
            if (i === updatedFilterData.length - 1) {
                setData([...data])
            }
        })

    }
    function updateAllUnits() {
        const selectedRows = selectedRowsKeys;
        const filterData = data.filter((d) => selectedRows.includes(d.key));
        const updatedFilterData = [];
        const updateDatabaseList = [];
        filterData.forEach((d) => {
            const unitid = bulkEdit.unitid !== '' ? bulkEdit.unitid?.unitid : unitList.find((u) => u.label === d.unitid)?.unitid;
            const unit = unitList.find((u) => u.unitid === unitid);
            updateDatabaseList.push({
                ...d,
                unitid: unit?.label || '',
            });
            updatedFilterData.push({
                ...d,
                unitid: unit?.label || '',
            });
        });

        const keys = updatedFilterData.map((d) => d.key);
        const newUnitid = bulkEdit.unitid?.unitid || '';

        const proxyUrl = "https://mycorslake.herokuapp.com/";
        axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
            raw: `UPDATE ${query.dataSource === 'probe' ? 'probe_data' : query.dataSource === 'rain' ? 'gauge_data' : 'node_data_new2'} SET 
                        unitid = '${newUnitid}'
                    WHERE key IN (${keys.map((e) => `'${e}'`).join(',')});`
        }).then(async (response) => {
            // handle success
            openSnack('success', 'Data updated!');
        }).catch((error) => {
            console.log(error);
        });

        updatedFilterData.forEach((r, i) => {
            // update data for each updatedFilterData 
            const index = data.findIndex((d) => d.key === r.key);
            data[index] = r;
            if (i === updatedFilterData.length - 1) {
                setData([...data]);
            }
        });
    }




    function updateAllNodes() {
        const selectedRows = selectedRowsKeys;
        const filterData = data.filter((d) => selectedRows.includes(d.key));
        const updatedFilterData = [];
        const updateDatabaseList = [];
        filterData.forEach((d) => {
            const nodeid = bulkEdit.nodeid !== '' ? bulkEdit.nodeid?.id : nodes.find((n) => n.label === d.nodeid)?.id;
            const node = nodes.find((n) => n.id === nodeid);
            updateDatabaseList.push({
                ...d,
                nodeid: query.dataSource === 'probe' ? node?.name : node?.label,
            });
            updatedFilterData.push({
                ...d,
                nodeid: query.dataSource === 'probe' ? node?.name : node?.label,
            });
        });

        const keys = updatedFilterData.map((d) => d.key);
        const newNodeid = bulkEdit.nodeid?.id || '';

        const proxyUrl = "https://mycorslake.herokuapp.com/";
        axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
            raw: `UPDATE ${query.dataSource === 'probe' ? 'probe_data' : query.dataSource === 'rain' ? 'gauge_data' : 'node_data_new2'} SET 
                        nodeid = '${newNodeid}'
                    WHERE key IN (${keys.map((e) => `'${e}'`).join(',')});`
        }).then(async (response) => {
            // handle success
            openSnack('success', 'Data updated!');
        }).catch((error) => {
            console.log(error);
        });

        updatedFilterData.forEach((r, i) => {
            // update data for each updatedFilterData 
            const index = data.findIndex((d) => d.key === r.key);
            data[index] = r;
            if (i === updatedFilterData.length - 1) {
                setData([...data]);
            }
        });
    }


    function updateAllValues() {
        const selectedRows = selectedRowsKeys;
        const filterData = data.filter((d) => selectedRows.includes(d.key));
        const updatedFilterData = []
        const updateDatabaseList = [];
        filterData.forEach((d) => {
            const value = bulkEdit.value !== '' ? bulkEdit.value : d.value;
            updateDatabaseList.push({
                ...d,
                value
            })
            updatedFilterData.push({
                ...d,
                value
            })

        })

        //update database for each updatedFilterData
        updateDatabaseList.forEach((r, i) => {
            updateDatabase({ ...r })
            if (i === updatedFilterData.length - 1) {

                openSnack('success', 'Updated Successfully')
            }
        })

        updatedFilterData.forEach((r, i) => {
            //update data for each updatedFilterData 
            const index = data.findIndex((d) => d.key === r.key);
            data[index] = r;
            if (i === updatedFilterData.length - 1) {
                setData([...data])
            }
        })

    }
    function updateAllParameters() {
        const selectedRows = selectedRowsKeys;
        const filterData = data.filter((d) => selectedRows.includes(d.key));
        const updatedFilterData = [];
        const updateDatabaseList = [];
        filterData.forEach((d) => {
            const parameterid = bulkEdit.parameterid !== '' ? bulkEdit.parameterid?.key : [...PPArray, ...parameters].find((p) => p.label === d.parameterid)?.key;
            const parameter = [...PPArray, ...parameters].find((p) => p.key === parameterid);
            updateDatabaseList.push({
                ...d,
                parameterid: parameter.label || '',
            });
            updatedFilterData.push({
                ...d,
                parameterid: parameter.label || '',
            });
        });

        const keys = updatedFilterData.map((d) => d.key);
        const newParameterid = bulkEdit.parameterid?.key || '';

        const proxyUrl = "https://mycorslake.herokuapp.com/";
        axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
            raw: `UPDATE ${query.dataSource === 'probe' ? 'probe_data' : query.dataSource === 'rain' ? 'gauge_data' : 'node_data_new2'} SET 
                    parameterid = '${newParameterid}'
                WHERE key IN (${keys.map((e) => `'${e}'`).join(',')});`
        }).then(async (response) => {
            // handle success
            openSnack('success', 'Data updated!');
        }).catch((error) => {
            console.log(error);
        });

        updatedFilterData.forEach((r, i) => {
            // update data for each updatedFilterData 
            const index = data.findIndex((d) => d.key === r.key);
            data[index] = r;
            if (i === updatedFilterData.length - 1) {
                setData([...data]);
            }
        });
    }




    return (
        <Row align="middle" >
            <Col span={24}>

                <Divider />

            </Col>
            <Col span={12} style={{ padding: 8, paddingLeft: 20 }}>

                {query.querying ? <CircularProgress size={20} display="inline" /> :
                    <Typography display="inline" variant="body1" fontSize={14} fontWeight={500}>{rowCount} </Typography>}
                <Typography display="inline" variant="body1" fontSize={14} fontWeight={500}>Rows</Typography>

            </Col>
            <Col span={12} style={{ padding: 8, paddingLeft: 20, textAlign: 'right' }}>

                <ExportDataButton />


            </Col>
            <Col span={24}>

                <Divider />

            </Col>
            <Col span={12} style={{ padding: 8, paddingLeft: 20, }}>



            </Col>

            <TableButtons dataSource={query.dataSource} selectedRowsKeys={selectedRowsKeys} setOpenBulkEdit={setOpenBulkEdit} setBulkEdit={setBulkEdit}
                openSnack={openSnack}
                setData={setData}
                deleteData={(deletedKeys) => {
                    const newData = data.filter((d) => !deletedKeys.includes(d.key));
                    const newSelectedRowsKeys = selectedRowsKeys.filter((k) => !deletedKeys.includes(k));
                    setSelectedRowsKeys(newSelectedRowsKeys);
                    setData(newData);
                    const proxyUrl = "https://mycorslake.herokuapp.com/";
                    axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                        raw: `DELETE FROM ${query.dataSource === 'probe' ? 'probe_data' : 'node_data_new2'}
                            WHERE key IN (${deletedKeys.map((e) => `'${e}'`).join(',')});`

                    }).then(async (response) => {
                        openSnack('success', 'Data deleted!')
                    }).catch((error) => {
                        openSnack('error', 'Error deleting data!')
                    })
                }}
                addRow={(newRow) => {
                    const newData = [...data, newRow];
                    setData(newData);
                }}
                userInfo={userInfo}



            />


            <Col span={24}>

                <Divider />

            </Col>
            <Col span={24}>

                <DataGrid
                    rowHeight={rowHeight}

                    ref={gridRef}
                    style={{ minHeight: '100vh' }}
                    columns={columns}
                    rows={sortedRows}
                    selectedRows={new Set(selectedRows)}
                    renderers={{ sortStatus, checkboxFormatter }}
                    onSelectedRowsChange={(rows) => {



                        //change set to array and get keys from data
                        const selectedRowsKeys = Array.from(rows).map((r) => {
                            return data.find((d, i) => d.id === r)?.key
                        })

                        setSelectedRowsKeys(selectedRowsKeys)
                        setSelectedRows(rows);

                    }}

                    rowKeyGetter={rowKeyGetter}

                    defaultColumnOptions={{
                        sortable: true,
                        textwrap: 'wrap',
                    }}
                    enableVirtualization={true}
                    enableCellAutoFocus={true}
                    enableCellSelect={true}
                    sortColumns={sortColumns}
                    onSortColumnsChange={setSortColumns}
                    onRowsChange={(rows) => {

                        //compare which rows are different from rows and data
                        //if the row is different then put them in a different array

                        const changedRows = rows.filter((r) => {

                            const originalRow = data.find((d) => d.id === r.id);
                            return !isEqual(r, originalRow);
                        });


                        //update database of each changedRows
                        changedRows.forEach((r) => {

                            updateDatabase(r)
                        })

                        setData(rows);


                    }}

                    onCellClick={(args, event) => {

                        if (
                            [
                                "parameterid",
                                "nodeid",
                                "unitid",
                                "codes",
                                "value",
                                "time",
                            ].includes(args.column.key)
                        ) {
                            event.stopPropagation();
                            setTimeout(() => args.selectCell(true), 0);
                        }
                    }}







                />

            </Col>

            <BulkEditor
                openBulkEdit={openBulkEdit}
                setOpenBulkEdit={setOpenBulkEdit}
                bulkEdit={bulkEdit}
                setBulkEdit={setBulkEdit}
                updateAllParameters={updateAllParameters}
                updateAllNodes={updateAllNodes}
                updateAllUnits={updateAllUnits}
                updateAllCodes={updateAllCodes}
                PPArray={PPArray}
                parameters={parameters}
                codes={codes}
                unitList={unitList}
                nodes={nodes}
            />











        </Row>
    );
}

export default DataTable