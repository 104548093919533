import { collection, doc, getCountFromServer, getDoc, getDocs, getFirestore, onSnapshot, orderBy, query, setDoc, updateDoc, where } from "firebase/firestore";
import moment from 'moment-timezone';
import axios, * as others from 'axios';
import { SpeedUnit, convertUnits } from 'measurement-unit-converter';
import uuid from "react-uuid";

var convert = require('convert-units')


export const newLogFunction = async (userInfo, log, setLog) => {
    try {
        const logCount = await countLogs(userInfo, 'field_logs_new')
        console.log(logCount)
        const logNumber = logCount + 1;
        const newLog = {
            ...log,
            logNumber,
            id: uuid(),
            status: 'draft',
            startDate: moment().format('x'),
        };
        console.log(newLog)
        setLog(newLog)


        return 'success';
    }
    catch (error) {
        console.error("Error querying data: ", error);
        return 'success';
    }
}


async function countLogs(userInfo, listKey) {
    try {
        console.log(userInfo)
        console.log(listKey)

        const db = getFirestore();
        const queryRef = collection(db, "clients", userInfo.currentCompany, listKey);
        const snapShot = await getDocs(query(queryRef, where("accountKey", "==", userInfo.currentAccount), where('archived', '==', false)));
        const logs = snapShot.docs.map(doc => {
            return (
                {
                    logNumber: doc.data().logNumber,
                }
            )
        });

        //get max log number
        let maxLogNumber = 0;
        logs.forEach(log => {
            if (log.logNumber > maxLogNumber) {
                maxLogNumber = log.logNumber
            }
        })

        console.log(maxLogNumber)


        return maxLogNumber;
    }
    catch (error) {
        console.error("Error querying data: ", error);
        return null;
    }
}


export const fetchWeather = async (log, account) => {
    console.log(log)
    if (!log?.startDate) {
        const apiKey = 'e60406d8d1152d5b1e8413bb058aa602';
        const proxyurl = "https://mycorslake.herokuapp.com/";
        const url = `https://api.openweathermap.org/data/2.5/weather?lat=${account?.lat}&lon=${account?.lng}&appid=${apiKey}&units=imperial`;



        try {
            const response = await axios.get(proxyurl + url);
            console.log(response)
            const weather = {
                temp: response?.data?.main?.temp,
                weather: response?.data?.weather[0]?.main,
                icon: response?.data?.weather[0]?.icon,
                description: response?.data?.weather[0]?.description,
                wind: response?.data?.wind?.speed,
                humidity: response?.data?.main?.humidity,
            }
            return weather;
        } catch (error) {
            console.error("Error fetching weather data:", error);
            return null;
        }
    }
    else {

        //check if log?.startdate is in the future
        const now = moment().format('x');
        if (log?.startDate > now) {
            return 'future';
        }

        const historicalWeather = await fetchHistoricalWeather(log, account)
        return historicalWeather;
    }
};


async function fetchHistoricalWeather(log, account) {
    try {
        console.log(log?.startDate);
        console.log(moment(log?.startDate, 'x').format('YYYY-MM-DD HH:mm:ss'));
        const proxyurl = "https://mycorslake.herokuapp.com/";

        const response = await axios.post(proxyurl + 'https://us-central1-aquasource3.cloudfunctions.net/widgetsFire3/sqlRead', {
            raw: `SELECT * FROM weather_data WHERE account = '${account.key}' AND time >= '${moment(log?.startDate, 'x').format('YYYY-MM-DD HH:mm:ss')}'
        ORDER BY time LIMIT 1;`
        });

        console.log(response);
        const weather = {
            temp: Number(convert(response?.data[0]?.temp).from('C').to('F').toFixed(1)),
            weather: response?.data[0]?.weathermain,
            icon: response?.data[0]?.weathericon,
            description: response?.data[0]?.weatherdescription,
            wind: Number(convertUnits(response?.data[0]?.wind_speed, SpeedUnit.METER_PER_SECOND, SpeedUnit.MILE_PER_HOUR).toFixed(1)),
            humidity: response?.data[0]?.humidity,
        }
        return weather;
    }
    catch (error) {
        console.error("Error fetching historical weather data:", error);
        return 'no_history';
    }



}


