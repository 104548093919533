import React, { useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const HideRow = ({ record, updateRecord, val, openSnack }) => {
    const [isExpanded, setIsExpanded] = useState(false); // State to track expansion

    // Function to toggle expansion and update record accordingly
    const toggleExpansion = () => {
        if (record?.item === null) {
            openSnack('error', 'Please select an item first');
            return;
        }
        setIsExpanded(!isExpanded);
        updateRecord({ ...record, [val]: !record[val] });
    };

    return (
        <div style={{ display: 'inline' }}>
            <IconButton onClick={toggleExpansion}>
                <Tooltip title={isExpanded ? `Show ${val === 'hide' ? 'Row' : 'Map'}` : `Hide ${val === 'hide' ? 'Row' : 'Map'}`}
                    placement="bottom">
                    <FontAwesomeIcon
                        icon={faChevronDown}
                        size="md"
                        style={{
                            color: record?.item === null ? 'grey' : '#3f51b5',
                            cursor: 'pointer',
                            transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                            transition: 'transform 0.3s'  // Smooth transition for rotation
                        }}
                    />
                </Tooltip>
            </IconButton>
        </div>
    );
};

export default HideRow;
