import {
    Autocomplete,
    Checkbox,
    Divider,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { Button, Col, Row } from "antd";
import React from "react";
import AlertRowEmailDistribution from "./AlertRowEmailDistribution";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faCopy } from "@fortawesome/free-solid-svg-icons";
import AlertRowSMSDistribution from "./AlertRowSMSDistribution";
import DataColorGithub from "../../account/account/dashboard/settings/dataColorGithub";
import AlertSnoozeDatePicker from "./AlertSnoozeDatePicker";
import moment from 'moment-timezone';


const AlertTableRow = ({ index, alert, updateAlert, deleteAlert, duplicateAlert, parameters, staff }) => {
    // Dummy data for parameters autocomplete
    const [name, setName] = React.useState(alert.name);
    const [parameter, setParameter] = React.useState(alert.parameter);
    const [active, setActive] = React.useState(alert.active);
    const [value, setValue] = React.useState(alert.value);
    const [type, setType] = React.useState(alert.type);
    const [alertType, setAlertType] = React.useState(alert.alertType);
    const [sms, setSms] = React.useState(alert.sms);
    const [smsDistribution, setSmsDistribution] = React.useState(alert.smsDistribution);
    const [color, setColor] = React.useState(alert.color);

    console.log(alert)
    const rowPadding = 4;


    return (

        <Row gutter={[6, 6]} key={index} align={'middle'}
            style={{
                backgroundColor: index % 2 === 0 ? "#e6e6e6" : "white",
                padding: 12,
                paddingLeft: 20,
                paddingRight: 20
            }}>
            <Col span={2} style={{ textAlign: 'center' }}>
                <Typography fontSize={13} fontWeight={600} variant="body1">Active</Typography>
                <Checkbox checked={active}


                    onChange={(e) => {
                        setActive(e.target.checked)
                        updateAlert(alert.id, 'active', e.target.checked)
                    }

                    } />
            </Col>
            <Col span={5}>
                <Typography fontSize={13} fontWeight={600} variant="body1">Alert Name<span style={{ color: 'red' }}>*</span></Typography>
                <TextField
                    fullWidth
                    style={{ backgroundColor: 'white' }}
                    size="small"
                    value={name}
                    onChange={(e) => {
                        setName(e.target.value)

                    }}

                    onBlur={(e) => {
                        updateAlert(alert.id, 'name', e.target.value)
                    }}
                />
            </Col>

            <Col span={5}>
                {alert.type !== 'locationDrift' && (<Typography fontSize={13} fontWeight={600} variant="body1">Parameter<span style={{ color: 'red' }}>*</span></Typography>)}
                {alert.type !== 'locationDrift' && (<Autocomplete
                    style={{ backgroundColor: 'white' }}
                    size="small"
                    options={parameters.sort((a, b) => -b.label.localeCompare(a.label))}
                    getOptionLabel={(option) => option.label}
                    value={parameters.find((param) => param.id === parameter) || null}
                    onChange={(event, newValue) => {
                        setParameter(newValue ? newValue.id : null)
                        updateAlert(alert.id, 'parameter', newValue ? newValue.id : null)
                        updateAlert(alert.id, 'parameterName', newValue ? newValue.label : '')
                    }
                    }
                    renderOption={(props, option) => (
                        <li {...props} style={{ fontSize: 14 }}>
                            {option.label}
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField {...params} placeholder="Parameter" />
                    )}
                />)}
            </Col>
            <Col span={4}>
                {alert.type !== 'locationDrift' && (<Typography fontSize={13} fontWeight={600} variant="body1">Value<span style={{ color: 'red' }}>*</span></Typography>)}
                {alert.type !== 'locationDrift' && (<TextField
                    fullWidth
                    style={{ backgroundColor: 'white' }}
                    size="small"
                    type="number"
                    placeholder="Value"
                    defaultValue={alert.value}
                    onChange={(e) => {
                        if (e.target.value !== "") {
                            setValue(e.target.value)
                        } else {
                            setValue(0)
                        }

                    }}
                    onBlur={(e) => {
                        if (e.target.value !== "") {
                            updateAlert(alert.id, 'value', e.target.value)
                        } else {
                            updateAlert(alert.id, 'value', 0)
                        }
                    }}
                />)}
            </Col>
            <Col span={4} style={{ paddingRight: 5, marginRight: 5 }}>
                <Typography fontSize={13} fontWeight={600} variant="body1">Type<span style={{ color: 'red' }}>*</span></Typography>
                <Select
                    style={{ minWidth: "140px", backgroundColor: 'white' }}
                    size="small"
                    defaultValue={alert.type}
                    placeholder="Type"
                    onChange={(e) => {
                        setType(e.target.value)
                        updateAlert(alert.id, 'type', e.target.value)
                    }

                    }
                >
                    <MenuItem value={"above"}>Above Threshold</MenuItem>
                    <MenuItem value={"below"}>Below Threshold</MenuItem>
                    {/* <MenuItem value={"locationDrift"}>Location Drift</MenuItem> */}
                </Select>
            </Col>
            <Col span={4} style={{ paddingRight: 5, marginRight: -5 }}>
                <Tooltip title="Alert silenced up to this date.">
                    <Typography fontSize={13} fontWeight={600} variant="body1">Snooze
                        <FontAwesomeIcon icon={faCircleInfo} style={{ marginLeft: 5 }} color="#404050" />
                        {moment().isBefore(alert?.snoozeDate) && (' zzz')}
                        <AlertSnoozeDatePicker alert={alert} updateAlert={updateAlert}
                        />
                    </Typography>
                </Tooltip>

            </Col>

            <Col span={8}>
                <Typography fontSize={13} fontWeight={600} variant="body1">Email Distribution<span style={{ color: 'red' }}>*</span></Typography>
                <AlertRowEmailDistribution alert={alert} updateAlert={updateAlert} staff={staff} />

            </Col>
            <Col span={3} style={{}}>
                <Typography fontSize={13} fontWeight={600} variant="body1">Frequency</Typography>
                <Select
                    style={{ minWidth: "140px", backgroundColor: 'white' }}
                    size="small"
                    defaultValue={alert.alertType || 'continuous'}
                    placeholder="Type"
                    onChange={(e) => {
                        setAlertType(e.target.value)
                        updateAlert(alert.id, 'alertType', e.target.value)
                        console.log(e.target.value)
                    }

                    }
                >
                    <MenuItem value={"continuous"}>Continuous</MenuItem>
                    <MenuItem value={"oneOff"}>One off</MenuItem>
                    <MenuItem value={"daily"}>Daily</MenuItem>
                    <MenuItem value={"weekly"}>Weekly</MenuItem>
                    {/* <MenuItem value={"locationDrift"}>Location Drift</MenuItem> */}
                </Select>
            </Col>
            <Col span={2} style={{ textAlign: 'center' }}>
                <Typography fontSize={13} fontWeight={600} variant="body1">SMS</Typography>
                <Checkbox checked={sms}


                    onChange={(e) => {
                        setSms(e.target.checked)
                        updateAlert(alert.id, 'sms', e.target.checked)
                    }

                    } />
            </Col>
            {
                sms && <Col span={8}>
                    <Typography fontSize={13} fontWeight={600} variant="body1">SMS Distribution<span style={{ color: 'red' }}>*</span></Typography>
                    <AlertRowSMSDistribution alert={alert} updateAlert={updateAlert} staff={staff} />
                </Col>
            }

            <Col >


                <DataColorGithub color={color || '#404050'} updateData={(boo, val) => {
                    setColor(val)
                    updateAlert(alert.id, 'color', val)
                }} />
            </Col>
            <Col >



                {alert.type !== 'locationDrift' && (<Button
                    display="inline"

                    margin="dense"
                    color="primary"
                    variant="contanied"
                    style={{
                        marginRight: 10,
                        backgroundColor: '#404050',
                        textTransform: 'none',
                    }}
                    onClick={() => duplicateAlert(alert)}>
                    <Typography fontSize={13} fontWeight={600} color="white" variant="body1">Duplicate</Typography>
                </Button>
                )}
                {alert.type !== 'locationDrift' && (<Button
                    display="inline"

                    margin="dense"
                    color="danger"
                    variant="outlined"
                    style={{
                        border: '1px solid red',
                        textTransform: 'none',
                    }}
                    onClick={() => deleteAlert(alert)}>
                    <Typography fontSize={13} fontWeight={600} variant="body1">Delete</Typography>
                </Button>
                )}

            </Col>


        </Row >











    );
};

export default AlertTableRow;
