import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect, useState, useCallback } from "react";
import PhotosList from "../components/photosComponents/photoList";
import { collection, doc, getCountFromServer, getDocs, getFirestore, query, setDoc, where, startAfter, limit, orderBy } from "firebase/firestore"
import Timeline from "../components/photosComponents/timeline";
import InfiniteScroll from 'react-infinite-scroll-component';



const TimelineHome = ({ screenWidth, openSnack, userInfo }) => {
    const photosPerFetch = 8;
    const [photos, setPhotos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(true);
    const [lastVisible, setLastVisible] = useState(null); // Add this line

    const fetchTimelinePhotos = useCallback(async () => {
        try {
            const db = getFirestore();
            const ref = collection(db, "clients", userInfo.currentCompany, 'photos_new');

            let queryRef;
            if (lastVisible) {
                queryRef = query(
                    ref,
                    where("account", "==", userInfo.currentAccount),
                    where("status", "!=", 'deleted'),
                    orderBy('status'),
                    orderBy('created_date', 'desc'), // Order by 'created_date' in descending order
                    startAfter(lastVisible),
                    limit(photosPerFetch)
                );
            } else {
                queryRef = query(
                    ref,
                    where("account", "==", userInfo.currentAccount),
                    where("status", "!=", 'deleted'),
                    orderBy('status'),
                    orderBy('created_date', 'desc'), // Order by 'created_date' in descending order
                    limit(photosPerFetch)
                );
            }

            const snap = await getDocs(queryRef);
            const newPhotos = snap.docs.map((doc) => ({ ...doc.data(), photo_id: doc.id }));

            // Set the last document from the results as the starting point for the next fetch
            setLastVisible(snap.docs[snap.docs.length - 1]);

            // Append new photos to the existing photos
            // Since we are retrieving in descending order, we append new photos to the end
            setPhotos(prevPhotos => [...prevPhotos, ...newPhotos]);

            // If less than 'photosPerFetch' were returned, we've reached the end
            if (newPhotos.length < photosPerFetch) {
                setHasMore(false);
            }

            openSnack('success', 'Photos retrieved successfully');
        } catch (error) {
            console.log(error)
            openSnack('error', 'Error retrieving photos');
        }
    }, [userInfo, lastVisible]);

    useEffect(() => {
        fetchTimelinePhotos().then(() => setLoading(false));
    }, []);

    // Call this function from the InfiniteScroll component
    const fetchMorePhotos = () => {
        if (!hasMore) return;
        fetchTimelinePhotos();
    };






    const props = {
        screenWidth, openSnack, userInfo,
        photos,
        setPhotos,
        screenWidth

    };












    if (loading) {
        return (
            <Box py={2}>
                <Grid container justifyContent="center">
                    <Grid item style={{ textAlign: 'center' }}>
                        <FontAwesomeIcon icon={faSpinner} spin size="xl" />
                        <Typography variant="h6" style={{ marginTop: 20 }}>
                            Loading your timeline photos...
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        );
    }
    else {

        return (


            <Box >
                <Grid container alignItems="center" >

                </Grid>

                <Grid container>
                    <Grid item xs={12} sx={{ p: 1, mt: 1, }} >




                        <Box style={{ backgroundColor: 'white' }}>
                            <Typography style={{ marginLeft: 10, display: "inline" }} variant="h5" fontSize={20} fontWeight={700} >
                                Photos Timeline
                            </Typography>
                            <InfiniteScroll
                                dataLength={photos.length}
                                next={fetchMorePhotos}
                                hasMore={hasMore}
                                loader={
                                    <Box py={2} textAlign="center">
                                        <FontAwesomeIcon icon={faSpinner} spin size="xl" />
                                        <Typography variant="h6" mt={2}>
                                            Loading more photos...
                                        </Typography>
                                    </Box>
                                }
                                endMessage={
                                    <Typography textAlign="center" variant="subtitle1" mt={2}>
                                        You have seen all photos!
                                    </Typography>
                                }
                            >
                                <Timeline {...props} />
                            </InfiniteScroll>



                        </Box>





                    </Grid>
                </Grid>

            </Box >


        );
    }
};

export default TimelineHome;
