import React, { useEffect, useState } from 'react';
import { GoogleLogin, googleLogout, GoogleOAuthProvider, hasGrantedAllScopesGoogle, useGoogleLogin } from '@react-oauth/google';
import { Button } from '@mui/material';
import './app.css';
import { checkIfCalendarExists, createCalendarEvent, createNewCalendar, deleteCalendar, listAllEvents } from './calendarFunctions';
const googleOAuthClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;




const App = () => {
    const [tokenData, setTokenData] = useState(null);
    const getCurrentTime = () => new Date().getTime();
    const now = getCurrentTime();



    const login = useGoogleLogin({
        onSuccess: tokenResponse => {
            setTokenData({
                token: tokenResponse.access_token,
                expiryTime: now + (tokenResponse.expires_in * 1000) // Store the exact expiry timestamp
            });
        },
    });

    const onLoginFailure = (response) => {
        console.log('Login Failed:', response);
    };

    const isTokenExpired = () => {
        return tokenData && new Date().getTime() >= tokenData.expiryTime;
    };


    const handleAction = async (action) => {
        if (!tokenData || isTokenExpired()) {
            alert('Session expired. Please log in again.');
            login(); // Trigger login again
            return;
        }

        try {
            await action();
        } catch (error) {
            console.error('Error:', error);
        }
    };


    console.log(tokenData)

    return (
        <div>

            <button className="google-btn" onClick={() => login()}>

                Sign in with Google 🚀
            </button>
            {tokenData?.token && (
                <button onClick={async () => handleAction(async () => {
                    // 1. Check if LakeTech Calendar exists
                    let calendarId = await checkIfCalendarExists("LakeTech Calendar", tokenData?.token);

                    // 2. If not, create it
                    if (!calendarId) {
                        const newCalendar = await createNewCalendar(tokenData?.token);
                        console.log(newCalendar)

                        await createCalendarEvent(newCalendar.id, tokenData?.token);
                    }

                    // 3. Create event
                    if (calendarId) {
                        await createCalendarEvent(calendarId, tokenData?.token);

                        // 4. List events
                        await listAllEvents(calendarId, tokenData?.token);
                    }

                })}>Create Calendar Event</button>
            )}
            {tokenData?.token && (
                <button onClick={async () => handleAction(async () => {
                    // 1. Check if LakeTech Calendar exists
                    let calendarId = await checkIfCalendarExists("LakeTech Calendar", tokenData?.token);
                    console.log(calendarId)
                    //if it does delete it
                    if (calendarId) {
                        await deleteCalendar(calendarId, tokenData?.token);
                    }



                })}>Delete Calendar</button>
            )}
        </div>
    );
};

export default App;
