import React, { useState } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowDown, faCloudArrowUp, faEdit, faEllipsisH, faFilePdf, faPaperPlane, faPlus, faX } from '@fortawesome/free-solid-svg-icons';
import { IconButton, List, Tooltip, ListItem, OutlinedInput, InputAdornment, ListItemIcon } from '@mui/material';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import uuid from 'react-uuid';
import { useNavigate } from 'react-router-dom';

export default function ActionsButton({


}) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;



    return (
        <>
            <IconButton


                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleClick(e);

                }}
            >
                <FontAwesomeIcon icon={faEllipsisH} color={'#3f51b5'} />

            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleClose();
                }}

                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <List sx={{
                    width: 300,
                    padding: 0,

                }}>

                    <ListItem
                        key={'bulkEdit'}
                        dense
                        button
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleClose();
                        }}
                        sx={{
                            borderBottom: '1px solid #e0e0e0',
                            paddingTop: 1,
                            paddingBottom: 1,
                        }}
                    >
                        <FontAwesomeIcon icon={faFilePdf} style={{ color: '#3f51b5', marginRight: 10 }} />
                        <Typography variant="body1" color="black" fontSize={16} fontWeight={500}>Export PDF</Typography>
                    </ListItem>
                    <ListItem
                        key={'bulkEdit'}
                        dense
                        button
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleClose();
                        }}
                        sx={{
                            borderBottom: '1px solid #e0e0e0',
                            paddingTop: 1,
                            paddingBottom: 1,
                        }}
                    >
                        <FontAwesomeIcon icon={faEdit} style={{ color: '#3f51b5', marginRight: 10 }} />
                        <Typography variant="body1" color="black" fontSize={16} fontWeight={500}>Edit</Typography>
                    </ListItem>
                    <ListItem
                        key={'bulkEdit'}
                        dense
                        button
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleClose();
                        }}
                        sx={{
                            borderBottom: '1px solid #e0e0e0',
                            paddingTop: 1,
                            paddingBottom: 1,
                        }}
                    >
                        <FontAwesomeIcon icon={faPaperPlane} style={{ color: '#3f51b5', marginRight: 10 }} />
                        <Typography variant="body1" color="black" fontSize={16} fontWeight={500}>Email</Typography>
                    </ListItem>




                </List>




            </Popover>



        </>
    );
}