import React, { useState } from "react";


import { faBox, faCheck, faListCheck, faPlus, faWrench, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, ButtonGroup, FormControl, IconButton, InputLabel, MenuItem, Modal, OutlinedInput, Select, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import uuid from 'react-uuid';
import './itemList.css';


const ItemCreator = (
    {
        screenWidth,
        setCreateItemModal



    }

) => {
    const [newItem, setNewItem] = useState({});
    const [activeTab, setActiveTab] = useState('create');
    const [isFocused, setIsFocused] = useState(false);
    const [customSearch, setCustomSearch] = useState('');











    return (
        <>

            <Button
                fullWidth variant='contained' color='primary' style={{
                    textTransform: 'none',
                    backgroundColor: '#3f51b5',
                    color: 'white',
                }}

                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setCreateItemModal(true)
                }}>
                <FontAwesomeIcon icon={faPlus} style={{ marginRight: '10px' }} />
                Create item
            </Button>



        </>



    );
};

export default ItemCreator;
