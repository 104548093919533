import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import StaffSelect from './drawercomponents/staffSelect';
import { Col, Row } from 'antd';
import SiteSelect from './drawercomponents/siteSelect';
import { Input, TextField } from '@mui/material';
import DateSelectComponent from './drawercomponents/dateSelectComponent';
import MoneyInput from './drawercomponents/moneyInput';
import PercentInput from './drawercomponents/percentInput';
import StringInput from './drawercomponents/stringInput';
import NumberInput from './drawercomponents/numberInput';
import SingleSelect from './drawercomponents/singleSelect';
import MultipleSelect from './drawercomponents/multipleSelect';
import ItemSelect from './drawercomponents/itemSelect';
import StocksSelect from './drawercomponents/stockSelect';
import ColorSelect from './drawercomponents/colorSelect';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '1px solid rgba(0, 0, 0, .3)',
    boxShadow: 24,
    p: 4,
    borderRadius: '24px',
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
};

const BulkEditTasks = ({
    bulkEditor,
    setBulkEditor,
    tasks,
    selectedTasks,
    staff,
    accounts,
    currentTask,
    setCurrentTask,
    headers,
    openSnack,
    updateTask,
    stocks,
    setStocks,
    items,
    userInfo,
    setItems,
    setTasks,
    updateMultipleTasks }) => {

    const handleOpen = () => setBulkEditor(true);
    const handleClose = () => setBulkEditor(false);
    const [bulkTask, setBulkTask] = useState(null);



    return (
        <div>
            <Modal
                open={bulkEditor}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{

                }}

                slotProps={{
                    backdrop: {
                        style: {
                            backgroundColor: 'transparent'
                        }
                    }
                }}
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" fontWeight={700} fontSize={28} component="h2">
                        Task Bulk Editor
                    </Typography>
                    <Typography id="modal-modal-description" variant="caption" fontSize={13} sx={{ mt: 2 }}>
                        Make changes to multiple tasks with the bulk editor. You are making changes to
                        <Typography variant="caption" fontWeight={700} fontSize={13} sx={{ display: 'inline' }}>
                            {` ${selectedTasks?.length} `}
                        </Typography>
                        tasks.
                    </Typography>
                    {headers?.map((header, index) => {

                        return (
                            <Row align={"middle"} style={{ marginTop: 20 }}>
                                <Col span={6}>
                                    <Typography id="modal-modal-description" variant="body1" fontWeight={600} fontSize={15}>
                                        {header.headerName}
                                    </Typography> 
                                </Col>
                                <Col span={14}>
                                    {header?.optionType === 'money' && (<MoneyInput headerKey={header.id} currentTask={bulkTask} setCurrentTask={setBulkTask} />)}
                                    {header?.optionType === 'percent' && (<PercentInput headerKey={header.id} currentTask={bulkTask} setCurrentTask={setBulkTask} />)}
                                    {header?.optionType === 'string' && (<StringInput headerKey={header.id} currentTask={bulkTask} setCurrentTask={setBulkTask} />)}
                                    {header?.optionType === 'number' && (<NumberInput headerKey={header.id} currentTask={bulkTask} setCurrentTask={setBulkTask} />)}
                                    {header?.optionType === 'singleSelect' && (<SingleSelect headerKey={header.id} currentTask={bulkTask} setCurrentTask={setBulkTask} options={header?.options} />)}
                                    {header?.optionType === 'multipleSelect' && (<MultipleSelect headerKey={header.id} currentTask={bulkTask} setCurrentTask={setBulkTask} options={header?.options} />)}
                                    {header?.optionType === 'staffSelect' && (<StaffSelect staff={staff} userInfo={userInfo} headerKey={header.id} currentTask={bulkTask} setCurrentTask={setBulkTask} />)}
                                    {header?.optionType === 'itemSelect' && (<ItemSelect itemType={header?.itemSelectType} setItems={setItems} userInfo={userInfo} items={items} headerKey={header.id} currentTask={bulkTask} setCurrentTask={setBulkTask} />)}
                                    {header?.optionType === 'stockSelect' && (<StocksSelect stocks={stocks} setStocks={setStocks} userInfo={userInfo} headerKey={header.id} currentTask={bulkTask} setCurrentTask={setBulkTask} />)}
                                    {header?.optionType === 'siteSelect' && (<SiteSelect userInfo={userInfo} accounts={accounts} headerKey={header.id} currentTask={bulkTask} setCurrentTask={setBulkTask} />)}
                                    {header?.optionType === 'dateSelect' && (<DateSelectComponent userInfo={userInfo} headerKey={header.id} currentTask={bulkTask} setCurrentTask={setBulkTask} />)}
                                    {header?.optionType === 'colorSelect' && (<ColorSelect headerKey={header.id} currentTask={bulkTask} setCurrentTask={setBulkTask} />)}
                                </Col>

                                <Col span={4} style={{
                                    textAlign: 'right'
                                }}>
                                    <Button variant="contained" size="small"
                                        sx={{
                                            backgroundColor: '#3f51b5',
                                            color: 'white',
                                            '&:hover': {
                                                backgroundColor: '#3f51b5',
                                                color: 'white',
                                                opacity: 0.8
                                            },
                                            textTransform: 'none',
                                        }}

                                        onClick={() => {
                                            console.log(bulkTask)
                                            updateMultipleTasks(selectedTasks, bulkTask)
                                            setBulkTask(null)
                                        }}>Apply</Button>
                                </Col>
                            </Row>
                        )
                    })}






                </Box>
            </Modal>
        </div >
    );
}

export default BulkEditTasks;
