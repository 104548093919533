import React, { useEffect, useRef, useState } from "react";


import { faCheckCircle, faCircle, faCopy } from '@fortawesome/free-regular-svg-icons';
import { faExclamation, faSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Autocomplete, Badge, Checkbox, CircularProgress, IconButton, Select, TextField, Tooltip, Typography } from '@mui/material';
import { Col } from 'antd';
import moment from 'moment-timezone';
import './taskList.css';
import WarningIcon from '@mui/icons-material/WarningAmber';


import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import uuid from "react-uuid";


import UnitsCell from "./rowComponents/unitsCell";
import ValueCell from "./rowComponents/valueCell";
import ParametersCell from "./rowComponents/parametersCell";
import DateCell from "./rowComponents/dateCell";
import SiteCell from "./rowComponents/siteCell";
import CodeCell from "./rowComponents/codeCell";

import PriorityHighIcon from '@mui/icons-material/PriorityHigh';



const TableRow = ({ rowData, data, setData, headers, setCurrentCell, currentCell, parameters, unitsArray, stations, setErrorModal, codes, duplicateData, deleteData, lastClickedIndex, setLastClickedIndex, keyDown, checkErrors }) => {
    const [row, setRow] = useState(rowData);

    useEffect(() => {
        const { errorsCount, warningsCount } = checkErrors(rowData);
        setRow({ ...rowData, errors: errorsCount, warnings: warningsCount, issues: errorsCount + warningsCount });
    }, [rowData]);


    const [issues, setIssues] = useState(0);


    useEffect(() => {
        const issues = getIssues(row);
        setIssues(issues);
    }, []);



    async function updateRow(newRow) {
        const { errorsCount, warningsCount } = checkErrors(newRow);
        setRow({ ...newRow, errors: errorsCount, warnings: warningsCount, issues: errorsCount + warningsCount });
        //replace this row in data
        const index = data.findIndex((item) => item.id === newRow.id);
        const newData = [...data];
        newData[index] = { ...newRow, errors: errorsCount, warnings: warningsCount, issues: errorsCount + warningsCount };
        setData(newData);

    }

    async function updateMultipleRows(startPosition, endPosition) {
        console.log("UPDATING MULTIPLE ROWS")
        console.log(startPosition)
        console.log(endPosition)
        const newData = [...data];
        for (let i = startPosition; i <= endPosition; i++) {
            const row = newData[i];
            newData[i] = { ...row, selected: true };
        }
        setData(newData);
    }



    function getIssues(row) {
        const { errorsCount, warningsCount } = checkErrors(row);
        setRow({ ...row, errors: errorsCount, warnings: warningsCount, issues: errorsCount + warningsCount });


    }





    const totalProblems = row?.errors + row?.warnings;



    return (





        <div style={{
            display: 'inline-flex',
            width: `auto`,
            //nowrap
            flexWrap: 'nowrap',
            cursor: 'pointer',

        }}
            className={`row-hover-effect`}

        >
            <div className="box"
                id={"draggingIcon"}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 20,
                    borderBottom: '1px solid rgba(0,0,0,0.2)'
                }}>
                <div className="my-handle" style={{ display: 'inline' }}>
                    <DragIndicatorIcon />
                </div>

            </div>
            <div className="box"
                id={"errorsIcon"}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',

                    width: 60,
                    borderBottom: '1px solid rgba(0,0,0,0.2)',
                }}>
                {totalProblems > 0 && (<IconButton
                    onClick={() => {
                        setErrorModal(row);


                    }}

                >
                    <Badge badgeContent={totalProblems} color={totalProblems > row.warnings ? 'error' : 'warning'}>
                        {totalProblems > row.warnings ? <PriorityHighIcon style={{ color: 'gray', cursor: 'pointer' }} /> :
                            <WarningIcon style={{ color: 'gray', cursor: 'pointer' }} />
                        }
                    </Badge>

                </IconButton>
                )}

            </div>

            <div className="box"

                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 60,
                    borderBottom: '1px solid rgba(0,0,0,0.2)'
                }}>
                <Checkbox
                    id={uuid()}
                    checked={row?.selected}
                    sx={{
                        color: '#3f51b5',
                        '&.Mui-checked': {
                            color: '#3f51b5',
                        },
                        '& .MuiSvgIcon-root': { fontSize: 24 }
                    }}
                    onChange={(e) => {
                        const shiftKeyPressed = e.nativeEvent.shiftKey;



                        if (shiftKeyPressed && lastClickedIndex !== null) {
                            console.log("SHIFTED")
                            console.log(lastClickedIndex)
                            // Find the starting and ending position based on Data position
                            const startPosition = lastClickedIndex;
                            const endPosition = data.findIndex((item) => item.id === row?.id);

                            console.log(startPosition)
                            console.log(endPosition)
                            updateMultipleRows(startPosition, endPosition);

                        } else {
                            // If shift key is not pressed, set this row as the first clicked row, but only setFristClickedIndex if the checkbox is not already selected
                            setLastClickedIndex(row?.selected ? null : data.findIndex((item) => item.id === row?.id));
                            const newRow = { ...row, selected: !row?.selected };
                            updateRow(newRow);


                        }
                    }}
                />
            </div>






            {headers.filter((h) => h.included === true).map((header, i) => {






                return (



                    <div
                        className="box"
                        style={{
                            width: `${header.width}px`,
                            borderLeft: '1px solid rgba(0,0,0,0.2)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'left',
                            padding: currentCell?.selectedID === `${row.id}??${header.name}` ? 0 : 8,
                            borderBottom: '1px solid rgba(0,0,0,0.2)'
                        }}
                        id={`${row.id}??${header.name}`}
                        onClick={() => {
                            setCurrentCell({ ...row, selectedID: `${row.id}??${header.name}` });
                        }}
                    >

                        {header?.mappedField === 'value' && (
                            <ValueCell
                                id={`${row.id}??${header.name}`}
                                header={header}
                                row={row}
                                currentCell={currentCell}
                                updateRow={updateRow}
                                keyDown={keyDown}

                            />
                        )}
                        {header?.mappedField === 'units' && (
                            <UnitsCell
                                id={`${row.id}??${header.name}`}
                                header={header}
                                row={row}
                                currentCell={currentCell}
                                unitsArray={unitsArray}
                                updateRow={updateRow}
                                keyDown={keyDown}

                            />
                        )}
                        {header?.mappedField === 'parameters' && (
                            <ParametersCell
                                id={`${row.id}??${header.name}`}
                                header={header}
                                row={row}
                                currentCell={currentCell}
                                parameters={parameters}
                                updateRow={updateRow}
                                keyDown={keyDown}

                            />
                        )}
                        {header?.mappedField === 'date' && (
                            <DateCell
                                id={`${row.id}??${header.name}`}
                                header={header}
                                row={row}
                                currentCell={currentCell}
                                updateRow={updateRow}
                                keyDown={keyDown}

                            />
                        )}
                        {header?.mappedField === 'node' && (
                            <SiteCell
                                id={`${row.id}??${header.name}`}
                                header={header}
                                row={row}
                                currentCell={currentCell}
                                stations={stations}
                                updateRow={updateRow}
                                keyDown={keyDown}
                            />
                        )}
                        {header?.mappedField === 'codes' && (
                            <CodeCell
                                id={`${row.id}??${header.name}`}
                                header={header}
                                row={row}
                                currentCell={currentCell}
                                codes={codes}
                                updateRow={updateRow}
                                keyDown={keyDown}
                            />
                        )}







                    </div>


                );
            })}


            <div className="box fixed-column" style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingRight: 10,
                paddingLeft: 10,
                width: 150,
                borderBottom: '1px solid rgba(0,0,0,0.2)',
                borderLeft: '1px solid rgba(0,0,0,0.2)',
                //little left box shadow
                boxShadow: 'inset 5px 0px 5px -5px rgba(0,0,0,0.2)'
            }}>
                <IconButton size="sm">

                    <Tooltip title="Duplicate" placement="bottom">
                        <FontAwesomeIcon icon={faCopy} size="xs" style={{ color: 'gray', cursor: 'pointer' }}
                            onClick={() => {
                                duplicateData(row)
                            }} />
                    </Tooltip>

                </IconButton>
                <IconButton size="sm">

                    <Tooltip title="Delete" placement="bottom">
                        <FontAwesomeIcon icon={faTrash} size="xs" style={{ color: 'gray', cursor: 'pointer' }}
                            onClick={() => { deleteData(row) }} />
                    </Tooltip>

                </IconButton>
            </div>


        </div>















    );
};

export default TableRow;
