import { Autocomplete, Button, Checkbox, Collapse, Divider, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material';
import { Row, Col } from 'antd';
import React, { useEffect, useState } from 'react';
import { ReactSortable } from 'react-sortablejs';
import uuid from 'react-uuid';
import { fullMobile } from '../../util';
import AddIcon from '@mui/icons-material/Add';
import { DeleteForever, DragIndicator } from '@mui/icons-material';
import { faVial, faLocationCrosshairs, faFillDrip, faChartArea, faChartBar, faChartLine, faChartColumn, faGear } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataColorCompact from '../settings/dataColorCompact';



var randomColor = require('randomcolor');

export default function XAxes(props) {
    const { userInfo, screenWidth, openSnack, widgetRef, updateCurrentWidget, nodes, PPArray, parameters, WPArray } = props;
    const [widget, setWidget] = useState(widgetRef);





    useEffect(() => {
        console.log(widgetRef)
    }, [])
    useEffect(() => {
        setWidget(widgetRef)
    }, [widgetRef])


    const AddSeriesButton = () => {
        return (
            <Button variant={'contained'} display="inline"
                fullWidth
                style={{
                    textTransform: 'none', backgroundColor: '#404050', fontSize: 14,
                }}
                onClick={() => {
                    //find the next available axisId
                    let axisId = 0;
                    let axisIds = [];
                    widget.xAxes.forEach((item) => {
                        axisIds.push(item.axisId)
                    })
                    while (axisIds.includes(axisId)) {
                        axisId++;
                    }

                    const item = {
                        key: uuid(),
                        axisId,
                        label: '',
                        opposite: false,
                        customRange: false,
                        min: 0,
                        max: 100,
                        labelRotation: 270,
                        axisColor: '#000000',
                        gridLineColor: '#000000',
                        gridLineDashStyle: 'Solid',
                        gridLineWidth: 0.3,
                        alignTicks: true,
                        endOntick: true,
                        reversed: false,
                        advancedSettings: false,
                        title: {
                            text: ''
                        }
                    };
                    const newAxes = [...widget.xAxes, item];
                    setWidget({ ...widget, xAxes: newAxes });
                    updateCurrentWidget({ ...widget, xAxes: newAxes });
                }}
            >
                <AddIcon />  Add Axes
            </Button>

        )
    }

    async function updateInfo(index, key, val) {
        const newAxes = [...widget.xAxes];
        newAxes[index][key] = val;
        setWidget({ ...widget, xAxes: newAxes });
        updateCurrentWidget({ ...widget, xAxes: newAxes });

    }

    const xAxes = widget.xAxes || [];

    return (
        <Row gutter={[4, 4]}
        >

            <Col {...fullMobile(24, 24)}>
                <ReactSortable
                    handle=".axes-handle"
                    animation={500}
                    list={xAxes}
                    setList={(newState) => {
                        setWidget({ ...widget, xAxes: newState });
                        updateCurrentWidget({ ...widget, xAxes: newState });
                    }}
                >
                    {xAxes.map((item, index) => (
                        <Row key={item.key} align="middle" gutter={[4, 4]} style={{
                            padding: 10, paddingBottom: 0,
                            //if index is even then color is '#4040502e' else color is 'fff'
                            backgroundColor: index % 2 === 0 ? '#4747a00d' : 'fff',
                        }}>
                            <Col className='axes-handle' style={{ cursor: 'pointer' }} {...fullMobile(1, 1)}>
                                <DragIndicator />
                            </Col>
                            <Col {...fullMobile(23, 23)}>
                                <Row gutter={[8, 8]}>
                                    <Col {...fullMobile(18, 18)}>
                                        <Row gutter={[8, 8]}>
                                            <Col  >
                                                <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                                    Axis ID
                                                </Typography>
                                                <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                    style={{ width: 100 }}
                                                    defaultValue={item.axisId}
                                                    disabled
                                                    type="number"
                                                />
                                            </Col>
                                            <Col  >
                                                <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                                    Label
                                                </Typography>
                                                <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                    defaultValue={item.label || ''}
                                                    onBlur={(e) => {
                                                        updateInfo(index, 'label', e.target.value)
                                                    }} />
                                            </Col>
                                            <Col  >
                                                <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                                    Opposite
                                                </Typography>
                                                <Checkbox checked={item.opposite} onChange={(e) => {
                                                    updateInfo(index, 'opposite', e.target.checked)
                                                }} />

                                            </Col>

                                        </Row>
                                    </Col>

                                    <Col {...fullMobile(6, 6)} style={{ textAlign: 'right' }}>
                                        <Button variant={item.advancedSettings ? 'contained' : 'outlined'} display="inline"
                                            size="small"
                                            style={{
                                                textTransform: 'none', backgroundColor: item.advancedSettings ? 'inherit' : '#404050',
                                                color: item.advancedSettings ? '#404050' : 'white', border: '1px solid #404050',

                                                fontSize: 12,
                                                fontWeight: 300
                                            }}
                                            onClick={() => {
                                                //update this series' advanced settings variable    
                                                updateInfo(index, 'advancedSettings', !item.advancedSettings)

                                            }}
                                        >
                                            {item.advancedSettings ? 'Show Less' : 'Show More'}
                                        </Button>


                                        {widget.axes.length > 1 && (
                                            <Button variant={'link'} display="inline"
                                                size="small"
                                                style={{
                                                    textTransform: 'none', color: '#404050', fontSize: 14, marginLeft: 8,
                                                }}
                                                onClick={() => {
                                                    //remove this series for xAxes
                                                    const newAxes = [...widget.xAxes];
                                                    newAxes.splice(index, 1);
                                                    setWidget({ ...widget, xAxes: newAxes });
                                                    updateCurrentWidget({ ...widget, xAxes: newAxes });

                                                }}
                                            >
                                                <DeleteForever />
                                            </Button>
                                        )}

                                    </Col>
                                    <Col {...fullMobile(24, 24)}>
                                        <Collapse in={item.advancedSettings || false} timeout="auto" unmountOnExit>
                                            <Row style={{ marginTop: 10 }} gutter={[12, 12]}>
                                                <Col >
                                                    <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                                        Custom Range
                                                    </Typography>
                                                    <Checkbox checked={item.customRange}
                                                        onChange={(e) => {
                                                            updateInfo(index, 'customRange', e.target.checked)
                                                        }} />
                                                </Col>

                                                {item.customRange && (
                                                    <Col >
                                                        <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                                            Min
                                                        </Typography>
                                                        <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                            defaultValue={item.min || ''}
                                                            style={{ width: 100 }}
                                                            onBlur={(e) => {
                                                                
                                                                updateInfo(index, 'min', e.target.value === "" ? "" : Number(e.target.value))
                                                            }} />
                                                    </Col>
                                                )}

                                                {item.customRange && (
                                                    <Col >
                                                        <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                                            Max
                                                        </Typography>
                                                        <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                            defaultValue={item.max || ''}
                                                            style={{ width: 100 }}
                                                            onBlur={(e) => {
                                                                updateInfo(index, 'max', e.target.value === "" ? "" : Number(e.target.value))
                                                            }} />
                                                    </Col>
                                                )}


                                                <Col >
                                                    <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                                        Dash
                                                    </Typography>
                                                    <Select size="small" fullWidth
                                                        notched={false}
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={item.gridlineDashed || 'Solid'}
                                                        label="Dash"
                                                        onChange={(e) => {
                                                            updateInfo(index, 'gridlineDashed', e.target.value)
                                                        }}

                                                    >
                                                        <MenuItem value={'Solid'}>Solid</MenuItem>
                                                        <MenuItem value={'ShortDash'}>Short Dash</MenuItem>
                                                        <MenuItem value={'Dash'}>Dash</MenuItem>
                                                        <MenuItem value={'LongDash'}>Long Dash</MenuItem>
                                                        <MenuItem value={'Dot'}>Dot</MenuItem>
                                                    </Select>
                                                </Col>
                                                <Col >
                                                    <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                                        Grid Width
                                                    </Typography>
                                                    <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                        defaultValue={item.gridLineWidth}
                                                        style={{ width: 100 }}
                                                        type="number"
                                                        inputProps={{ min: 0 }}
                                                        onBlur={(e) => {
                                                            updateInfo(index, 'gridLineWidth', e.target.value === "" ? "" : Number(e.target.value))
                                                        }} />
                                                </Col>
                                                <Col >
                                                    <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                                        Label Rotation
                                                    </Typography>
                                                    <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                        defaultValue={item.labelRotation}
                                                        style={{ width: 100 }}
                                                        type="number"
                                                        inputProps={{ min: 0 }}
                                                        onBlur={(e) => {
                                                            updateInfo(index, 'labelRotation', e.target.value === "" ? "" : Number(e.target.value))
                                                        }} />
                                                </Col>
                                                <Col >
                                                    <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                                        Align Ticks
                                                    </Typography>
                                                    <Checkbox
                                                        checked={item?.alignTicks || false}
                                                        onChange={(e) => {
                                                            updateInfo(index, 'alignTicks', e.target.checked)
                                                        }}
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </Col>
                                                <Col >
                                                    <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                                        End On Tick
                                                    </Typography>
                                                    <Checkbox
                                                        checked={item?.endOntick || false}
                                                        onChange={(e) => {
                                                            updateInfo(index, 'endOntick', e.target.checked)
                                                        }}
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </Col>
                                                <Col >
                                                    <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                                        Reversed
                                                    </Typography>
                                                    <Checkbox
                                                        checked={item?.reversed || false}
                                                        onChange={(e) => {
                                                            updateInfo(index, 'reversed', e.target.checked)
                                                        }}
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </Col>
                                                <Col >
                                                    <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                                        Custom Ticks
                                                    </Typography>
                                                    <Checkbox
                                                        checked={item?.customTicks || false}
                                                        onChange={(e) => {
                                                            updateInfo(index, 'customTicks', e.target.checked)
                                                        }}
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </Col>
                                                <Col >
                                                    <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                                        Tick Amount
                                                    </Typography>
                                                    <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                        defaultValue={item.tickAmount}
                                                        style={{ width: 100 }}
                                                        type="number"
                                                        inputProps={{ min: 0 }}
                                                        onBlur={(e) => {
                                                            updateInfo(index, 'tickAmount', e.target.value === "" ? "" : Number(e.target.value))

                                                        }} />
                                                </Col>
                                                <Col >
                                                    <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                                        Font Size
                                                    </Typography>
                                                    <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                        defaultValue={item.fontSize || 14}
                                                        style={{ width: 100 }}
                                                        type="number"
                                                        inputProps={{ min: 0 }}
                                                        onBlur={(e) => {
                                                            updateInfo(index, 'fontSize', e.target.value === "" ? "" : Number(e.target.value))

                                                        }} />
                                                </Col>
                                                <Col >
                                                    <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                                        Label Angle
                                                    </Typography>
                                                    <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                        defaultValue={item.labelAngle || 0}
                                                        style={{ width: 100 }}
                                                        type="number"
                                                        inputProps={{ min: 0 }}
                                                        onBlur={(e) => {
                                                            updateInfo(index, 'labelAngle', e.target.value === "" ? "" : Number(e.target.value))


                                                        }} />
                                                </Col>
                                                <Col >
                                                    <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                                        Tick Intervals
                                                    </Typography>
                                                    <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                        defaultValue={item.tickInterval}
                                                        style={{ width: 100 }}
                                                        type="number"
                                                        inputProps={{ min: 0 }}
                                                        onBlur={(e) => {
                                                            updateInfo(index, 'tickInterval', e.target.value === "" ? "" : Number(e.target.value))


                                                        }} />
                                                </Col>
                                                <Col >
                                                    <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                                        Color
                                                    </Typography>
                                                    <DataColorCompact color={item.axisColor} updateData={(boo, val) => {
                                                        updateInfo(index, 'axisColor', val)
                                                    }} />
                                                </Col>
                                                <Col >
                                                    <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                                        Grid Color
                                                    </Typography>
                                                    <DataColorCompact color={item.gridLineColor} updateData={(boo, val) => {
                                                        updateInfo(index, 'gridLineColor', val)
                                                    }} />
                                                </Col>


                                            </Row>

                                        </Collapse>
                                    </Col>
                                </Row>







                            </Col>






                            <Col span={24} style={{ paddingTop: 10, paddingBottom: 0, }}>
                                <Divider />
                            </Col>


                        </Row>
                    ))}

                </ReactSortable>
            </Col>
            <Col {...fullMobile(24, 24)} style={{ paddingTop: 20 }}>
                <AddSeriesButton />
            </Col>









        </Row>
    );
}