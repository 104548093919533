import { Divider, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import { collection, getFirestore, onSnapshot, query, where } from 'firebase/firestore';
import React from 'react';
import TaskList from './taskList/taskList';

export default class TasksHome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            activeTab: 'tasks',
            companyStaff: [],
            accounts: []
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }

    }
    componentDidMount() {
        window.scrollTo(0, 0)

        this.queryCompanyStaff()
        this.queryAccounts()
    }

    queryAccounts = async () => {
        const { userInfo, type } = this.state;
        const db = getFirestore();


        const q = query(collection(db, "clients", userInfo.currentCompany, 'accounts'), where('archived', '==', false));
        const qPublic = query(collection(db, "clients", userInfo.currentCompany, 'accounts'), where('archived', '==', false), where('publicAccount', '==', true));

        const unsubscribe = onSnapshot(type === 'publice' ? qPublic : q, (snap) => {
            const accounts = [];
            snap.forEach((doc) => {

                if (type === 'public') {
                    accounts.push({
                        accountName: doc.data().accountName,
                        label: doc.data().accountName,
                        key: doc.data().key,
                        type: doc.data().seasonal || null,
                        city: doc.data().city || null,
                        countyName: doc.data().countyName || null,
                        state: doc.data().state || null,
                        routeDay: doc.data().routeDay || null,
                        routeName: doc.data().routeName || null,
                        visible: true
                    })
                }
                else {
                    if (["EuEZ93ByFZYs8bSUXoYsTt1zu0m2", 'eTevYsI9mQMxhtntd95HmRGasNx2', 'PQWFmTLCP9XqafR9ogNI3Fg1sH43', 'z4y8XVrxYGT7rO4R01Y8tQ2F5z83', 't9bOfGDqwDaQTYV6i008opscpjz2'].includes(userInfo.id)) {
                        accounts.push({
                            accountName: doc.data().accountName,
                            label: doc.data().accountName,
                            key: doc.data().key,
                            type: doc.data().seasonal || null,
                            city: doc.data().city || null,
                            countyName: doc.data().countyName || null,
                            state: doc.data().state || null,
                            routeDay: doc.data().routeDay || null,
                            routeName: doc.data().routeName || null,
                            visible: true
                        })
                    }
                    else {
                        if (userInfo.accounts.includes(doc.data().key)) {
                            accounts.push({
                                accountName: doc.data().accountName,
                                label: doc.data().accountName,
                                key: doc.data().key,
                                type: doc.data().seasonal || null,
                                city: doc.data().city || null,
                                countyName: doc.data().countyName || null,
                                state: doc.data().state || null,
                                routeDay: doc.data().routeDay || null,
                                routeName: doc.data().routeName || null,
                                visible: true
                            })
                        }
                    }
                }

            });
            accounts.sort((a, b) => a.accountName.localeCompare(b.accountName))
            this.setState({ accounts, loading: false })



        });



    }

    queryCompanyStaff = async () => {
        const { userInfo, type } = this.state;
        const db = getFirestore()
        const q = query(collection(db, "users"), where('companies', 'array-contains', userInfo.currentCompany));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const companyStaff = [];
            querySnapshot.forEach((doc) => {
                companyStaff.push({
                    id: doc.data().id,
                    key: doc.data().id,
                    accounts: doc.data().accounts,
                    invited: true,
                    signedUp: true,
                    email: doc.data().email,
                    label: `${doc.data().firstName || ""} ${doc.data().lastName || ""}`,
                    flDistribution: false,
                    adminLevel: 'admin'
                })
            });
            this.setState({ companyStaff, })

        });





    }

    render() {

        const { account, activeTab, screenWidth, userInfo, accounts, companyStaff } = this.state;

        return (
            <Row style={{}}>
                <Col span={23}>
                    <Row align="middle">
                        <Col xs={16} sm={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>
                            <Typography variant="h4">Site Tasks</Typography>
                        </Col>
                        <Col xs={6} sm={4} style={{ paddingTop: 30, paddingBottom: 15, textAlign: 'right' }}>

                        </Col>
                        <Col span={24}>
                            <Divider />
                        </Col>




                        <TaskList screenWidth={screenWidth} userInfo={userInfo} openSnack={this.props.openSnack}
                            account={account} companyStaff={companyStaff} accounts={accounts} />



                    </Row>
                </Col>

            </Row>
        );
    }
} 