import { Button, Checkbox, Divider, OutlinedInput, Typography, Select, MenuItem, CircularProgress, Paper, FormControl, InputLabel, Fab, IconButton } from '@mui/material';
import { Col, Drawer, Row } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment-timezone';
import { fullMobile } from './settings/util';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, } from 'firebase/firestore';
import { faCamera, faEye, faEyeSlash, faFloppyDisk, faGear, faMagnifyingGlassMinus, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import uuid from 'react-uuid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddIcon from '@mui/icons-material/Add';
import ProbeParameters from '../../../extra/probeParameters.json';

import html2canvas from 'html2canvas';
import { queryParameters, queryPersonalDashboardLayout, queryPersonalDashboardWidgets, queryProbes, queryProfiles, queryReferences, querySites, queryStations, queryStationsUnflat } from '../../../firebase/config';
import download from 'downloadjs';
import GridLayout from "react-grid-layout";
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import CurrentWidget from './dashboardCurrentWidget';
import WidgetChart from './newWidgets.jsx/widgetChart';
import WidgetReading from './newWidgets.jsx/widgetReading';
import WidgetMap from './newWidgets.jsx/widgetMap';
import WidgetGauge from './newWidgets.jsx/widgetGauge';
import WidgetIsopleth from './newWidgets.jsx/widgetIsopleth';
import WidgetProfile from './newWidgets.jsx/widgetProfile';
import DatePicker from "react-datepicker";
import WeatherParameters from '../../../extra/weatherParameters.json';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './tabStyle.css'
import DataSeries from './newSettings/dataSeries';
import Axes from './newSettings/axes';
import References from './newSettings/references';
import ChartSettings from './newSettings/chartSettings';
import SingleData from './newSettings/singleData';
import IsoplethSettings from './newSettings/isoplethSettings';
import MapSettings from './newSettings/mapSettings';
import MapLayers from './newSettings/mapLayer';
import Profiles from './newSettings/profiles';
import XAxes from './newSettings/xAxes';
import CapacitySettings from './newSettings/capacitySettings';
import WidgetCapacity from './newWidgets.jsx/widgetCapacity';
import WidgetForecast from './widgetForecast';


var randomColor = require('randomcolor');

export default function DashboardHomeNew({ userInfo, screenWidth, openSnack, company, account, type, dashTypeRef }) {
    const props = { userInfo, screenWidth, openSnack, company, account, type, dashTypeRef };
    const [loading, setLoading] = useState(true);
    const [PPArray, setPPArray] = useState([]);
    const [parameters, setParameters] = useState([]);
    const [nodes, setNodes] = useState([]);
    const [stations, setStations] = useState([]);
    const [probes, setProbes] = useState([]);
    const [publicLayout, setPublicLayout] = useState([]);
    const [layout, setLayout] = useState([]);
    const [widgets, setWidgets] = useState([]);
    const gridRef = useRef(null);
    const [isZoomed, setIsZoomed] = useState(false);
    const [takingScreenshot, setTakingScreenshot] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [currentWidget, setCurrentWidget] = useState('');
    const [dashType, setDashType] = useState(dashTypeRef);
    const [loadingWidgets, setLoadingWidgets] = useState(true);
    const [loadingLayout, setLoadingLayout] = useState(true);
    const [WPArray, setWPArray] = useState([]);
    const [references, setReferences] = useState([]);
    const [profiles, setProfiles] = useState([]);
    const [capacityData, setCapacityData] = useState([]);
    const [areaUnits, setAreaUnits] = useState('');
    const [volumeUnits, setVolumeUnits] = useState('');
    const [depthUnits, setDepthUnits] = useState('');



    const db = getFirestore();
    useEffect(() => {
        window.scrollTo(0, 0);
        getInfo()
    }, [])

    async function getInfo() {
        const PPArray = Object.values(ProbeParameters).map((p, i) => {
            return ({
                label: p,
                key: Object.keys(ProbeParameters)[i]
            })
        });
        setPPArray(PPArray)

        const WPArray = Object.values(WeatherParameters).map((p, i) => {
            return ({
                label: p,
                key: Object.keys(WeatherParameters)[i]
            })
        });
        setWPArray(WPArray)

        const parameters = await queryParameters(userInfo)
        setParameters(parameters)

        const nodes = await queryStations(userInfo, ['grab', 'both', 'sensor'])
        setNodes(nodes)
        const stations = await queryStationsUnflat(userInfo)
        setStations(stations)

        const probes = await queryProbes(userInfo)
        setProbes(probes)

        const layout = await queryPersonalDashboardLayout(userInfo);
        const layoutCheck = layout !== null && layout !== undefined ? JSON.parse(layout) : [];
        setLayout(layoutCheck)
        setLoadingLayout(false)

        const widgets = await queryPersonalDashboardWidgets(userInfo);
        const widgetsCheck = widgets !== null && widgets !== undefined ? widgets : [];
        setWidgets(widgetsCheck)
        setLoadingWidgets(false)

        const references = await queryReferences(userInfo);
        setReferences(references)

        const profiles = await queryProfiles(userInfo);
        console.log(profiles)
        setProfiles(profiles)


        const db = getFirestore();
        const docRef = doc(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'bathymetry', 'bathymetry');
        const docSnap = await getDoc(docRef);



        if (docSnap.exists()) {
            setCapacityData(docSnap.data().data)
            setAreaUnits(docSnap.data().areaUnits)
            setVolumeUnits(docSnap.data().volumeUnits)
            setDepthUnits(docSnap.data().depthUnits)




        }


    }






    const AddWidgetButton = () => {


        return (
            <Fab style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="medium"
                onClick={async () => {
                    const key = uuid()

                    const axes = [
                        {
                            key: uuid(),
                            axisId: 0,
                            label: '',
                            opposite: false,
                            customRange: false,
                            min: 0,
                            max: 100,
                            labelRotation: 270,
                            axisColor: '#000000',
                            gridLineColor: '#000000',
                            gridLineDashStyle: 'Solid',
                            gridLineWidth: 0.3,
                            alignTicks: true,
                            endOnTick: true,
                            reversed: false,
                            advancedSettings: false,
                            title: {
                                text: ''
                            }
                        }
                    ]
                    const xAxes = [
                        {
                            key: uuid(),
                            axisId: 0,
                            label: '',
                            opposite: false,
                            customRange: false,
                            min: 0,
                            max: 100,
                            labelRotation: 270,
                            axisColor: '#000000',
                            gridLineColor: '#000000',
                            gridLineDashStyle: 'Solid',
                            gridLineWidth: 0.3,
                            alignTicks: true,
                            endOnTick: true,
                            reversed: false,
                            advancedSettings: false,
                            title: {
                                text: ''
                            }
                        }
                    ]

                    //add a new item to the layout at the bottom 
                    const newLayout = [...layout,
                    {
                        i: key,
                        x: 0,
                        y: Infinity, // puts it at the bottom
                        w: 4, h: 6, minH: 2, minW: 2,
                    }]
                    //add a new item to the widgets array
                    const widget = {
                        key: key,
                        legend: true,
                        legendFontSize: 14,
                        shareAxis: true,
                        rangeSelectors: true,
                        customDepth: false,
                        timeline: true,
                        references: [],
                        axes,
                        xAxes,
                        series: [],
                        single: {
                            key: uuid(),
                            label: '',
                            unitsLabel: '',
                            nodeid: '',
                            dataType: '',
                            parameterid: '',
                            color: randomColor(),
                            markerFill: randomColor(),
                            markerLine: randomColor(),
                            type: 'spline',
                            dashed: 'Solid',
                            lineWidth: 2,
                            yAxis: 0,
                            tooltip: {},
                            marker: {},
                            offset: 0,
                            units: '',
                            showInLegend: true,
                            opacity: 1,
                            decimals: 2,
                            offset: 0,
                            dots: false,
                            depth: 0,
                            showChart: true,
                            startDate: moment().subtract(1, 'years').format('x'),
                            endDate: moment().format('x'),
                            timeframe: 'month'
                        },
                        lat: account.lat,
                        lng: account.lng,
                        zoom: 16,
                        mapType: 'satellite',
                        mapLabels: false,
                        kmlLayers: [],
                        mapStations: [],
                        mapProbes: [],
                        mapItems: [],
                        profiles: [],
                        showProbes: false,
                        showStations: false,
                        xAxisFontSize: 14,
                        xAxisLabelAngle: 0,
                        dateInterval: 'auto',
                        endToday: true,
                        startDate: moment().subtract(1, 'years').format('x'),
                        iStartDate: moment().subtract(1, 'years').format('x'),
                        endDate: moment().format('x'),
                        iEndDate: moment().format('x'),
                        title: '',
                        company: userInfo.currentCompany,
                        account: userInfo.currentAccount,
                        colorBar: true,
                        colorBarAuto: true,
                        colorBarMin: 0,
                        colorBarMax: 100,
                        colorBarTheme: 'standard',
                        isZoomed: false,


                    }

                    setLayout([...newLayout])
                    setWidgets([...widgets, widget])
                    updateLayout(newLayout)


                    //scroll to where the new widget appeared smoothly

                    setTimeout(() => {
                        window.scrollTo({
                            //top should be where the widget got added to the layout
                            top: gridRef.current.getBoundingClientRect().bottom,
                            behavior: 'smooth'
                        });
                    }, 250)




                    const widgetRef = doc(db, "users", userInfo.id, 'widgets', key);
                    await setDoc(widgetRef, widget)
                    const layoutRef = doc(db, "users", userInfo.id, 'layouts', userInfo.currentAccount);
                    await setDoc(layoutRef, { layout: JSON.stringify(newLayout) })




                    //scroll to bottom smoothly





                }}>
                <AddIcon />
            </Fab>
        )
    }

    //build updateLayout function
    async function updateLayout(newLayout) {

        const layoutRef = doc(db, "users", userInfo.id, 'layouts', userInfo.currentAccount);
        await updateDoc(layoutRef, { layout: newLayout })
    }


    async function updateWidget(key, boo, val) {
        const newWidgets = [...widgets]
        const index = newWidgets.findIndex(w => w.key === key)
        newWidgets[index][boo] = val
        setWidgets(newWidgets)


        const widgetRef = doc(db, "users", userInfo.id, 'widgets', key);
        await updateDoc(widgetRef, { [boo]: val })


    }



    async function deleteWidget(widget) {
        const newWidgets = [...widgets]
        const index = newWidgets.findIndex(w => w.key === widget.key)
        newWidgets.splice(index, 1)
        setWidgets(newWidgets)
        //delete widget from layout
        const newLayout = [...layout]
        const index2 = newLayout.findIndex(w => w.i === widget.key)
        newLayout.splice(index2, 1)
        setLayout(newLayout)
        //remove currentWidget 
        setCurrentWidget('')

    }

    async function replaceWidget(item) {
        const newWidgets = [...widgets]
        const index = newWidgets.findIndex(w => w.key === item.key)
        newWidgets[index] = item
        setWidgets(newWidgets)

        const widgetRef = doc(db, "users", userInfo.id, 'widgets', item.key);
        await updateDoc(widgetRef, item)
    }



    async function duplicateWidget(widget) {
        //duplicate new widget with new key and close the drawer, update keys for all series, profiles, axes, xAxes
        const newWidgets = [...widgets]
        const index = newWidgets.findIndex(w => w.key === widget.key)
        const newWidget = JSON.parse(JSON.stringify(widget))
        newWidget.key = uuid()
        newWidget.single.key = uuid()
        if (newWidget.series && newWidget.series.length > 0) {
            newWidget.series.forEach(s => s.key = uuid())
        }
        if (newWidget.profiles && newWidget.profiles.length > 0) {
            newWidget.profiles.forEach(p => p.key = uuid())
        }
        if (newWidget.axes && newWidget.axes.length > 0) {
            newWidget.axes.forEach(a => a.key = uuid())
        }
        if (newWidget.xAxes && newWidget.xAxes.length > 0) {
            newWidget.xAxes.forEach(a => a.key = uuid())
        }
        //update title with Copy
        newWidget.title = newWidget.title + ' Copy'
        newWidgets.splice(index + 1, 0, newWidget)
        setWidgets(newWidgets)



        //add widget to layout
        const newLayout = [...layout]
        const index2 = newLayout.findIndex(w => w.i === widget.key)
        const newLayoutItem = JSON.parse(JSON.stringify(newLayout[index2]))
        //add to layout at bottom of grid
        newLayoutItem.i = newWidget.key

        //change y to infinity so it goes to bottom
        newLayoutItem.y = Infinity
        newLayout.splice(index2 + 1, 0, newLayoutItem)
        setLayout(newLayout)
        //scroll to where the new widget appeared smoothly
        setTimeout(() => {
            window.scrollTo({
                //top should be where the widget got added to the layout
                top: gridRef.current.getBoundingClientRect().bottom,
                behavior: 'smooth'
            });
        }
            , 250)
        //save to db
        const widgetRef = doc(db, "users", userInfo.id, 'widgets', newWidget.key);
        await setDoc(widgetRef, newWidget)
        const layoutRef = doc(db, "users", userInfo.id, 'layouts', userInfo.currentAccount);
        await setDoc(layoutRef, { layout: JSON.stringify(newLayout) })

        //close drawer
        setCurrentWidget('')
        setOpenDrawer(false)


    }



    return (
        <div style={{ fontFamily: 'Roboto, sans-serif', backgroundColor: '#f6f8fe', minHeight: '100vh' }}>
            <Row style={{ padding: 20 }} >
                <Col {...fullMobile(20, 24)}>
                    <Typography variant='h3' fontWeight={500} fontSize={36}>Dashboard</Typography>
                    <Typography style={{ paddingTop: 5 }} variant='h5' fontWeight={300} fontSize={18}>Your personal dashboard as part of this account.</Typography>
                    <Typography style={{ paddingTop: 5 }} variant='body2' fontWeight={300} fontSize={14}>Personal dashboard is in beta.  Widgets may cause breaks. Contact customer support if bugs found.</Typography>


                </Col>
                <Col  {...fullMobile(4, 4)} style={{ textAlign: 'right', position: 'relative', zIndex: 0 }}>
                    <AddWidgetButton />
                </Col>
                <Col {...fullMobile(24, 24)}>
                    <Divider />
                </Col>
                <Col span={24} ref={gridRef}>
                    {[loadingLayout, loadingWidgets].includes(true) && (<CircularProgress />)}


                    {widgets.length > 0 && layout.length > 0 && (
                        <GridLayout
                            layout={layout}
                            cols={12}
                            rowHeight={30}
                            width={//width of gridRef 
                                gridRef.current !== null ? gridRef.current.offsetWidth : 0
                            }
                            draggableHandle=".drag-handle"
                            onLayoutChange={(layout) => {
                                if (!['xs', 'sm', 'md'].includes(screenWidth)) {

                                    setLayout(layout)
                                    updateLayout(JSON.stringify(layout))

                                }

                            }}

                        >
                            {layout?.map((l, i) => {



                                const index = widgets.map((k) => k.key).indexOf(l.i);

                                const widget = widgets[index];



                                if (widget !== undefined) {
                                    return (
                                        <div style={{ width: '100%' }} key={l.i}>
                                            <Paper elevation={12} style={{ height: '100%', width: '100%', borderRadius: 8, position: 'relative', }}>



                                                <Row style={{ padding: 10 }}  >

                                                    <Col  {...fullMobile(2, 0)}
                                                        //cursor: 'pointer'
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        {screenWidth !== 'xs' && type !== 'open' && (<DragIndicatorIcon className="drag-handle" />)}
                                                    </Col>
                                                    <Col  {...fullMobile(22, 24)} style={{ paddingLeft: 5 }} >
                                                        <Row align={'middle'} justify="space-between">
                                                            <Col>
                                                                <Typography display fontWeight={200}
                                                                    variant={screenWidth === 'xs' ? 'body1' :
                                                                        l.w >= 4 ? "h5" : l.w >= 3 ? "h6" : "subtitle1"}>{widget.title || ""}</Typography>
                                                            </Col>
                                                            <Col style={{ textAlign: 'right' }}>
                                                                {widget.widgetType === 'chart' && widget.isZoomed && (

                                                                    <IconButton

                                                                        onClick={() => {
                                                                            setIsZoomed(false)
                                                                            //update this widget
                                                                            updateWidget(widget.key, 'isZoomed', false)
                                                                            console.log("UPDATE ZOOM")

                                                                        }}
                                                                    >
                                                                        <FontAwesomeIcon fontSize={18}
                                                                            icon={faMagnifyingGlassMinus} color="#9ec1c9" />
                                                                    </IconButton>


                                                                )}



                                                                {widget.widgetType !== undefined && (

                                                                    type !== 'open' && (<IconButton onClick={() => {
                                                                        setCurrentWidget(widget)
                                                                        setOpenDrawer(true)
                                                                    }
                                                                    } >
                                                                        <FontAwesomeIcon fontSize={18} icon={faGear} color="#9ec1c9" />
                                                                    </IconButton>)

                                                                )}

                                                                {['chart', 'profile', 'contour', 'reading', 'gauge'].includes(widget.widgetType) && (

                                                                    takingScreenshot === `${l.i}` ? (<IconButton  ><CircularProgress size={20} /></IconButton>) : (<IconButton size='large' onClick={() => {
                                                                        setTakingScreenshot(`${l.i}`)

                                                                        //create a high quality image of the chart with html2canvas

                                                                        html2canvas(document.getElementById(l.i), {
                                                                            scale: 5,
                                                                            useCORS: true,
                                                                            allowTaint: true,


                                                                        }).then((canvas) => {
                                                                            const dataUrl = canvas.toDataURL('image/png');
                                                                            download(dataUrl, `HQ-Chart${widget.title}_${moment().format('YYYY-MM-DD')}.png`);
                                                                        }).then(() => {
                                                                            setTakingScreenshot(null)
                                                                        }).catch((err) => {
                                                                            console.log(err)
                                                                            setTakingScreenshot(null)
                                                                        })



                                                                    }}>


                                                                        <FontAwesomeIcon fontSize={18} icon={faCamera} color="#9ec1c9" />
                                                                    </IconButton>)

                                                                )}


                                                            </Col>
                                                        </Row>



                                                    </Col>



                                                </Row>

                                                <div id={l.i} style={{
                                                    //div should be the remaining height of the paper
                                                    height: `calc(100% - ${screenWidth === 'xs' ? 50 : 70}px)`,
                                                    width: '100%',
                                                    position: 'relative',
                                                    overflow: 'hidden',

                                                    paddingLeft: 10,
                                                    paddingRight: 10,
                                                    paddingBottom: 10,
                                                }}>




                                                    {widget.widgetType === 'chart' && (

                                                        <WidgetChart userInfo={userInfo} openSnack={openSnack} screenWidth={screenWidth} widgetRef={widget}
                                                            zoomRef={widget.isZoomed}
                                                            updateZoom={(zoom) => {
                                                                updateWidget(widget.key, 'isZoomed', zoom)
                                                            }} />


                                                    )}
                                                    {widget.widgetType === 'reading' && (

                                                        <WidgetReading layoutRef={l} userInfo={userInfo} openSnack={openSnack} screenWidth={screenWidth} widgetRef={widget} />


                                                    )}
                                                    {widget.widgetType === 'forecast' && (
                                                        <WidgetForecast layoutRef={l} userInfo={userInfo} openSnack={openSnack} screenWidth={screenWidth} widgetRef={widget} />
                                                    )}
                                                    {widget.widgetType === 'capacity' && (

                                                        <WidgetCapacity capacityData={capacityData} areaUnits={areaUnits} depthUnits={depthUnits} volumeUnits={volumeUnits}
                                                            layoutRef={l} userInfo={userInfo} openSnack={openSnack} screenWidth={screenWidth} widgetRef={widget} />


                                                    )}
                                                    {widget.widgetType === 'map' && (

                                                        <WidgetMap layoutRef={l} nodes={nodes} probes={probes} account={account}
                                                            kmlLayersRef={widget.kmlLayers} stations={stations}
                                                            updateWidget={updateWidget}
                                                            userInfo={userInfo} openSnack={openSnack} screenWidth={screenWidth} widgetRef={widget} />


                                                    )}
                                                    {widget.widgetType === 'gauge' && (

                                                        <WidgetGauge layoutRef={l} userInfo={userInfo} openSnack={openSnack} screenWidth={screenWidth} widgetRef={widget} />


                                                    )}
                                                    {widget.widgetType === 'contour' && (

                                                        <WidgetIsopleth layoutRef={l} userInfo={userInfo} openSnack={openSnack} screenWidth={screenWidth} widgetRef={widget} />


                                                    )}
                                                    {widget.widgetType === 'profile' && (

                                                        <WidgetProfile userInfo={userInfo} openSnack={openSnack} screenWidth={screenWidth} widgetRef={widget} />


                                                    )}
                                                    {widget.widgetType === undefined && (
                                                        <Row align="middle" gutter={[8, 8]} justify="center">

                                                            <Col span={24} style={{ textAlign: 'center' }}>
                                                                <FontAwesomeIcon fontSize={38} icon={faTriangleExclamation} color="#9ec1c9" />
                                                            </Col>
                                                            <Col span={24} style={{ textAlign: 'center' }}>
                                                                <Typography variant='h6'>Select Widget Type</Typography>
                                                            </Col>
                                                            <Col span={16} style={{ textAlign: 'center' }}>
                                                                <Select size="small" fullWidth
                                                                    notched={false}
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    value={widget.widgetType}
                                                                    label="Layer"
                                                                    onChange={(e) => {
                                                                        updateWidget(widget.key, 'widgetType', e.target.value)
                                                                    }}

                                                                >

                                                                    <MenuItem value={'chart'}>Chart</MenuItem>
                                                                    <MenuItem value={'map'}>Map</MenuItem>
                                                                    <MenuItem value={'capacity'}>Capacity</MenuItem>
                                                                    <MenuItem value={'gauge'}>Percent Gauge</MenuItem>
                                                                    <MenuItem value={'reading'}>Reading</MenuItem>
                                                                    <MenuItem value={'profile'}>Depth Profile</MenuItem>
                                                                    <MenuItem value={'contour'}>Isopleth</MenuItem>
                                                                    <MenuItem value={'forecast'}>Forecast</MenuItem>

                                                                </Select>
                                                            </Col>
                                                        </Row>)}

                                                </div>





                                            </Paper>


                                        </div>
                                    )
                                }

                            })}


                        </GridLayout>
                    )}


                </Col>

                <Drawer

                    title=""
                    width={screenWidth === 'xs' ? '80vw' : '75vw'}
                    placement={'right'}
                    closable={false}
                    onClose={() => {
                        setOpenDrawer(false)
                        replaceWidget(currentWidget)
                    }}
                    visible={openDrawer}
                    key={'right'}

                >
                    <Row align="middle" gutter={[8, 8]}>


                        <Col {...fullMobile(24, 24)} style={{ paddingTop: 20, }}>
                            <Typography variant='h6'>Widget Settings</Typography>

                        </Col>
                        <Col {...fullMobile(16, 16)} style={{}}>
                            <Typography variant='subtitle2'>Widget Title</Typography>
                            <OutlinedInput
                                fullWidth
                                size="small"
                                defaultValue={currentWidget.title}
                                onBlur={(e) => {
                                    setCurrentWidget({ ...currentWidget, title: e.target.value })
                                }}
                            />
                        </Col>
                        <Col {...fullMobile(16, 16)} style={{}}>
                            <Typography variant='subtitle2'>Widget Type</Typography>
                            <Select size="small" fullWidth
                                notched={false}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={currentWidget.widgetType}
                                label="Layer"
                                onChange={(e) => {
                                    setCurrentWidget({ ...currentWidget, widgetType: e.target.value })
                                }}
                            >

                                <MenuItem value={'chart'}>Chart</MenuItem>
                                <MenuItem value={'map'}>Map</MenuItem>
                                <MenuItem value={'capacity'}>Capacity</MenuItem>
                                <MenuItem value={'gauge'}>Percent Gauge</MenuItem>
                                <MenuItem value={'reading'}>Reading</MenuItem>
                                <MenuItem value={'profile'}>Depth Profile</MenuItem>
                                <MenuItem value={'contour'}>Isopleth</MenuItem>

                            </Select>
                        </Col>
                        <Col {...fullMobile(24, 24)}>
                            {['chart', 'contour',].includes(currentWidget.widgetType) && (
                                <Row align="middle" gutter={[12, 12]} allign="middle">
                                    <Col style={{}}>
                                        <Typography variant='body1' fontWeight={600} fontSize={13}>Start Date</Typography>
                                        <DatePicker timeCaption="Time" showTimeSelect timeFormat="h:mm a" timeIntervals={1}
                                            dateFormat="MM/dd/yyyy hh:mm a" portalId="root-portal"
                                            selected={new Date(moment(currentWidget?.startDate, 'x'))} onChange={(date) => {
                                                setCurrentWidget({ ...currentWidget, startDate: moment(date).format('x'), iStart: moment(date).format('x') })



                                            }} />
                                    </Col>
                                    {!currentWidget.endToday && (<Col style={{}}>
                                        <Typography variant='body1' fontWeight={600} fontSize={13}>End Date</Typography>
                                        <DatePicker timeCaption="Time" showTimeSelect timeFormat="h:mm a" timeIntervals={1}
                                            dateFormat="MM/dd/yyyy hh:mm a" portalId="root-portal" selected={new Date(moment(currentWidget?.endDate, 'x'))} onChange={(date) => {
                                                setCurrentWidget({ ...currentWidget, endDate: moment(date).format('x'), iEnd: moment(date).format('x') })


                                            }} />
                                    </Col>)}
                                    <Col style={{}}>
                                        <Typography variant='body1' fontWeight={600} fontSize={13}>End Today</Typography>
                                        <Checkbox
                                            checked={currentWidget?.endToday}
                                            onChange={(e) => {
                                                setCurrentWidget({ ...currentWidget, endToday: e.target.checked })
                                            }}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                    </Col>


                                    <Col span={24} style={{}}>
                                        <Row gutter={[12, 12]} align="middle">
                                            <Col>
                                                <Button variant="outlined" color="primary" size="small"
                                                    style={{ textTransform: 'none', border: '1px solid #404050', color: '#404050' }}
                                                    onClick={() => {
                                                        setCurrentWidget({ ...currentWidget, startDate: moment().subtract(1, 'days').format('x'), endDate: moment().format('x') })
                                                    }}
                                                >Last Week</Button>
                                            </Col>
                                            <Col>
                                                <Button variant="outlined" color="primary" size="small"
                                                    style={{ textTransform: 'none', border: '1px solid #404050', color: '#404050' }}
                                                    onClick={() => {
                                                        setCurrentWidget({ ...currentWidget, startDate: moment().subtract(1, 'months').format('x'), endDate: moment().format('x') })
                                                    }}
                                                >Last Month</Button>
                                            </Col>
                                            <Col>
                                                <Button variant="outlined" color="primary" size="small"
                                                    style={{ textTransform: 'none', border: '1px solid #404050', color: '#404050' }}
                                                    onClick={() => {
                                                        setCurrentWidget({ ...currentWidget, startDate: moment().subtract(3, 'months').format('x'), endDate: moment().format('x') })
                                                    }}
                                                >3 Months</Button>
                                            </Col>
                                            <Col>
                                                <Button variant="outlined" color="primary" size="small"
                                                    style={{ textTransform: 'none', border: '1px solid #404050', color: '#404050' }}
                                                    onClick={() => {
                                                        setCurrentWidget({ ...currentWidget, startDate: moment().subtract(6, 'months').format('x'), endDate: moment().format('x') })
                                                    }}
                                                >6 Months</Button>
                                            </Col>
                                            <Col>
                                                <Button variant="outlined" color="primary" size="small"
                                                    style={{ textTransform: 'none', border: '1px solid #404050', color: '#404050' }}
                                                    onClick={() => {
                                                        setCurrentWidget({ ...currentWidget, startDate: moment().subtract(1, 'years').format('x'), endDate: moment().format('x') })
                                                    }}
                                                >1 Year</Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col {...fullMobile(24, 24)}>
                                        <Divider />
                                    </Col>
                                </Row>
                            )}
                        </Col>
                        <Col {...fullMobile(24, 24)}>
                            <Tabs >
                                <TabList>
                                    {['chart', 'contour',].includes(currentWidget.widgetType) && (<Tab style={{ fontFamily: 'Roboto, sans-serif', cursor: 'pointer' }}>Data Series</Tab>)}
                                    {['chart',].includes(currentWidget.widgetType) && (<Tab style={{ fontFamily: 'Roboto, sans-serif', cursor: 'pointer' }}>Axes</Tab>)}
                                    {['chart',].includes(currentWidget.widgetType) && (<Tab style={{ fontFamily: 'Roboto, sans-serif', cursor: 'pointer' }}>References</Tab>)}
                                    {['chart',].includes(currentWidget.widgetType) && (<Tab style={{ fontFamily: 'Roboto, sans-serif', cursor: 'pointer' }}>Chart</Tab>)}
                                    {['gauge', 'reading', 'capacity'].includes(currentWidget.widgetType) && (<Tab style={{ fontFamily: 'Roboto, sans-serif', cursor: 'pointer' }}>Data</Tab>)}
                                    {['contour',].includes(currentWidget.widgetType) && (<Tab style={{ fontFamily: 'Roboto, sans-serif', cursor: 'pointer' }}>Ispoleth</Tab>)}

                                    {['map',].includes(currentWidget.widgetType) && (<Tab style={{ fontFamily: 'Roboto, sans-serif', cursor: 'pointer' }}>Layers</Tab>)}
                                    {['profile',].includes(currentWidget.widgetType) && (<Tab style={{ fontFamily: 'Roboto, sans-serif', cursor: 'pointer' }}>Profiles</Tab>)}
                                    {['profile',].includes(currentWidget.widgetType) && (<Tab style={{ fontFamily: 'Roboto, sans-serif', cursor: 'pointer' }}>X-Axes</Tab>)}
                                    {['profile',].includes(currentWidget.widgetType) && (<Tab style={{ fontFamily: 'Roboto, sans-serif', cursor: 'pointer' }}>Y-Axes</Tab>)}
                                    {['capacity',].includes(currentWidget.widgetType) && (<Tab style={{ fontFamily: 'Roboto, sans-serif', cursor: 'pointer' }}>Capacity</Tab>)}
                                </TabList>

                                {['chart', 'contour',].includes(currentWidget.widgetType) && (
                                    <TabPanel>
                                        <DataSeries userInfo={userInfo} openSnack={openSnack} screenWidth={screenWidth} widgetRef={currentWidget}
                                            nodes={nodes} parameters={parameters} PPArray={PPArray} WPArray={WPArray} updateCurrentWidget={setCurrentWidget} />

                                    </TabPanel>

                                )}
                                {['chart',].includes(currentWidget.widgetType) && (
                                    <TabPanel>
                                        <Axes userInfo={userInfo} openSnack={openSnack} screenWidth={screenWidth} widgetRef={currentWidget}
                                            updateCurrentWidget={setCurrentWidget} />

                                    </TabPanel>

                                )}
                                {['chart',].includes(currentWidget.widgetType) && (
                                    <TabPanel>
                                        <References userInfo={userInfo} openSnack={openSnack} screenWidth={screenWidth} widgetRef={currentWidget} references={references}
                                            updateCurrentWidget={setCurrentWidget} />

                                    </TabPanel>

                                )}
                                {['chart',].includes(currentWidget.widgetType) && (
                                    <TabPanel>
                                        <ChartSettings userInfo={userInfo} openSnack={openSnack} screenWidth={screenWidth} widgetRef={currentWidget}
                                            updateCurrentWidget={setCurrentWidget} />

                                    </TabPanel>

                                )}
                                {['gauge', 'reading', 'capacity'].includes(currentWidget.widgetType) && (
                                    <TabPanel>
                                        <SingleData userInfo={userInfo} openSnack={openSnack} screenWidth={screenWidth} widgetRef={currentWidget}
                                            nodes={nodes} parameters={parameters} PPArray={PPArray} WPArray={WPArray} updateCurrentWidget={setCurrentWidget} />

                                    </TabPanel>

                                )}
                                {['contour'].includes(currentWidget.widgetType) && (
                                    <TabPanel>
                                        <IsoplethSettings userInfo={userInfo} openSnack={openSnack} screenWidth={screenWidth} widgetRef={currentWidget}
                                            nodes={nodes} parameters={parameters} PPArray={PPArray} WPArray={WPArray} updateCurrentWidget={setCurrentWidget} />

                                    </TabPanel>

                                )}

                                {['map'].includes(currentWidget.widgetType) && (
                                    <TabPanel>
                                        <MapLayers userInfo={userInfo} openSnack={openSnack} screenWidth={screenWidth} widgetRef={currentWidget}
                                            updateCurrentWidget={setCurrentWidget} />

                                    </TabPanel>

                                )}
                                {['profile'].includes(currentWidget.widgetType) && (
                                    <TabPanel>
                                        <Profiles profiles={profiles} userInfo={userInfo} openSnack={openSnack} screenWidth={screenWidth} widgetRef={currentWidget}
                                            nodes={nodes} parameters={parameters} PPArray={PPArray} WPArray={WPArray} updateCurrentWidget={setCurrentWidget} />

                                    </TabPanel>

                                )}
                                {['profile',].includes(currentWidget.widgetType) && (
                                    <TabPanel>
                                        <XAxes profiles={profiles} userInfo={userInfo} openSnack={openSnack} screenWidth={screenWidth} widgetRef={currentWidget}
                                            updateCurrentWidget={setCurrentWidget} />

                                    </TabPanel>

                                )}
                                {['profile',].includes(currentWidget.widgetType) && (
                                    <TabPanel>
                                        <Axes profiles={profiles} userInfo={userInfo} openSnack={openSnack} screenWidth={screenWidth} widgetRef={currentWidget}
                                            updateCurrentWidget={setCurrentWidget} />

                                    </TabPanel>

                                )}
                                {['capacity'].includes(currentWidget.widgetType) && (
                                    <TabPanel>
                                        <CapacitySettings userInfo={userInfo} openSnack={openSnack} screenWidth={screenWidth} widgetRef={currentWidget}
                                            nodes={nodes} parameters={parameters} PPArray={PPArray} WPArray={WPArray} updateCurrentWidget={setCurrentWidget} />

                                    </TabPanel>

                                )}





                            </Tabs>

                        </Col>
















                        <Col {...fullMobile(24, 24)} style={{ textAlign: 'right' }}>
                            <Button variant={'contained'} display="inline"

                                style={{
                                    textTransform: 'none', backgroundColor: '#404050', fontSize: 14,
                                }}
                                onClick={() => {
                                    //delete the widget and close the drawer
                                    deleteWidget(currentWidget)
                                    setOpenDrawer(false)
                                }}
                            >
                                Delete Widget
                            </Button>

                        </Col>
                        <Col {...fullMobile(24, 24)} style={{ textAlign: 'right' }}>
                            <Button variant={'contained'} display="inline"

                                style={{
                                    textTransform: 'none', backgroundColor: '#404050', fontSize: 14,
                                }}
                                onClick={() => {

                                    duplicateWidget(currentWidget)
                                }}
                            >
                                Duplicate Widget
                            </Button>

                        </Col>



                    </Row>
                </Drawer>






            </Row>
        </div >
    );
}