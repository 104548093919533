import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Container,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography
} from "@mui/material";
import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faMapPin, faVial, faCircle } from "@fortawesome/free-solid-svg-icons";








const SelectionList = ({
    parameters,
    queryParams,
    setQueryParams,
    stations,
    queryNodes,
    setQueryNodes,
    selectedItems,
    setSelectedItems,
    items,
    setItems,
    source,
    weatherUndergroundItems,
    tidalStationItems,
    wundergroundHeaders,
    setWundergroundHeaders,
    tidalHeaders,
    setTidalHeaders,




}) => {

    const [searchQuery, setSearchQuery] = useState('');
    const [filteredItems, setFilteredItems] = useState([]);

    useEffect(() => {
        setFilteredItems(items);
    }, [items]);

    useEffect(() => {
        setFilteredItems(source === 'node' ? items : source === 'wunderground' ? weatherUndergroundItems : tidalStationItems);
    }, [source]);

    const updateHeaders = (item, headers, setHeaders, action) => {
        const newHeaders = [...headers];
        const index = newHeaders.findIndex(header => header.name === item.key);

        if (action === 'add') {
            newHeaders.push({
                position: newHeaders.length,
                headerName: item.label,
                name: item.key,
                getLabel: false,
                width: 120,
                visible: true,
                minWidth: 140,
                optionType: 'value',
                type: 'number',
                category: item.type,
            });
        } else {
            newHeaders.splice(index, 1);
        }

        setHeaders(newHeaders);
    };

    const handleCheckboxChange = (item) => {
        const key = item.key || item.name;
        const newSelectedItems = { ...selectedItems };

        if (newSelectedItems[key]) {
            delete newSelectedItems[key];
            if (item.type === 'parameter') {
                if (source === 'wunderground') {
                    updateHeaders(item, wundergroundHeaders, setWundergroundHeaders, 'remove');
                }
                if (source === 'tidal') {
                    updateHeaders(item, tidalHeaders, setTidalHeaders, 'remove');
                }
            }
        } else {
            newSelectedItems[key] = { ...item, conditions: [] };
            if (item.type === 'parameter') {
                if (source === 'wunderground') {
                    updateHeaders(item, wundergroundHeaders, setWundergroundHeaders, 'add');
                }
                if (source === 'tidal') {
                    updateHeaders(item, tidalHeaders, setTidalHeaders, 'add');
                }
            }
        }

        setSelectedItems(newSelectedItems);
    };

    const toggleAllItemsOfType = (type, action) => {
        const newSelectedItems = { ...selectedItems };
        const filteredItems = sortedFilteredItems.filter(item => item.type === type);

        filteredItems.forEach(item => {
            const key = item.key || item.name;
            if (action === 'select') {
                newSelectedItems[key] = { ...item, conditions: [] };
            } else {
                delete newSelectedItems[key];
            }
        });

        setSelectedItems(newSelectedItems);

        if (type === 'parameter') {
            let headers, setHeaders;

            if (source === 'wunderground') {
                headers = wundergroundHeaders;
                setHeaders = setWundergroundHeaders;
            } else if (source === 'tidal') {
                headers = tidalHeaders;
                setHeaders = setTidalHeaders;
            }

            const newHeaders = action === 'select'
                ? [...headers, ...filteredItems.map(item => ({
                    position: headers.length,
                    headerName: item.label,
                    name: item.key,
                    getLabel: false,
                    width: 120,
                    visible: true,
                    minWidth: 140,
                    optionType: 'value',
                    type: 'number',
                    category: item.type,
                }))]
                : headers.filter(header => !filteredItems.some(item => item.key === header.name));

            setHeaders(newHeaders);
        }
    };

    const selectAllItemsOfType = type => toggleAllItemsOfType(type, 'select');
    const deselectAllItemsOfType = type => toggleAllItemsOfType(type, 'deselect');



    // Sort filteredItems by type
    const sortedFilteredItems = [...filteredItems].sort((a, b) => {
        if (a.type < b.type) return -1;
        if (a.type > b.type) return 1;
        return 0;
    });

    const categorizedStations = sortedFilteredItems.reduce((acc, item) => {
        if (item.type === 'node') {
            (acc[item.stationLabel] = acc[item.stationLabel] || []).push(item);
        }
        return acc;
    }, {});

    let lastType = null;
    let lastStationLabel = null;

    return (
        <Col span={24} style={{ paddingBottom: 10, borderRight: '1px solid #0000001f' }}>
            <Box style={{ paddingLeft: 10, paddingRight: 10 }}>
                <OutlinedInput

                    sx={{

                        mt: 1,
                        mb: 1,
                        width: '100%',
                        height: '50px',
                        borderRadius: 8
                    }}
                    placeholder="Search"
                    value={searchQuery}
                    startAdornment={
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    }
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                size='small'
                                aria-label="toggle password visibility"

                                onClick={() => {
                                    setFilteredItems(items);
                                    setSearchQuery('');
                                }}
                            >
                                X
                            </IconButton>
                        </InputAdornment>
                    }
                    fullWidth
                    onChange={(e) => {
                        setSearchQuery(e.target.value);
                        if (e.target.value === '') {
                            setFilteredItems(items);
                            return;
                        }
                        const filteredItems = items.filter((param) => {
                            return param.label.toLowerCase().includes(e.target.value.toLowerCase());
                        });
                        setFilteredItems(filteredItems);
                    }}
                />
            </Box>
            <List style={{ maxHeight: '85vh', overflow: 'auto', paddingTop: 0 }}>

                {sortedFilteredItems.map((item, index) => {
                    const displayHeader = lastType !== item.type;
                    const displayStationLabel = lastStationLabel !== item.stationLabel;
                    lastType = item.type;
                    lastStationLabel = item.stationLabel;
                    if (item.type === 'node') {
                       
                    }
                    return (
                        <>
                            {displayHeader && (
                                <>
                                    <ListSubheader
                                        style={{ marginTop: 10 }}
                                        sx={{

                                            borderRadius: 2,
                                            backgroundColor: '#464a57',
                                            color: 'white',
                                            fontSize: 16,
                                            fontWeight: 800,
                                        }}>
                                        <FontAwesomeIcon icon={item.type === "node" ? faMapPin : faVial} size={"lg"} style={{ marginRight: 5 }} />

                                        {item.type === "node" && source === 'wunderground' ? "Weather Station" :
                                            item.type === "node" && source === 'tidal' ? "Tidal Station" :
                                                item.type === "node" ? "Nodes" : "Parameters"}</ListSubheader>
                                    <ListItem key={index} style={{ padding: 0, paddingLeft: 8, borderBottom: '1px solid #0000001f' }}
                                        button

                                    >

                                        <Button
                                            style={{
                                                textTransform: 'none'
                                            }}
                                            size="small"
                                            variant="link"
                                            color="primary"
                                            onClick={() => selectAllItemsOfType(item.type)}
                                        >
                                            Select All
                                        </Button>
                                        <Button
                                            style={{
                                                textTransform: 'none'
                                            }}
                                            size="small"
                                            variant="link"
                                            color="secondary"
                                            onClick={() => deselectAllItemsOfType(item.type)}
                                        >
                                            Deselect All
                                        </Button>
                                    </ListItem>
                                </>
                            )}

                            <ListItem key={index} style={{ padding: 0, paddingLeft: 8, borderBottom: '1px solid #0000001f' }}
                                button
                                onClick={() => handleCheckboxChange(item)}
                            >
                                <Checkbox
                                    checked={!!selectedItems[item.key || item.name]}
                                    onChange={() => handleCheckboxChange(item)}
                                    name={item.label}
                                    style={{ padding: 0, margin: 0, marginLeft: 10, display: 'inline', }}
                                />
                                {item.activelyReporting && (<FontAwesomeIcon icon={faCircle} size={"xs"} style={{ color: item.activelyReporting ? '#07e307' : 'red', marginRight: 1, marginLeft: 5 }} />)}
                                <Typography variant='body1' color={'black'} fontWeight={500} fontSize={13} style={{ marginLeft: 10, display: 'inline', paddingBottom: 8, paddingTop: 8 }}>{item.label || item.name}</Typography>
                            </ListItem>
                        </>
                    );
                })}
            </List>
        </Col >
    );
};

export default SelectionList;