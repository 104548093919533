import { Button, Checkbox, Divider, OutlinedInput, Typography, Select, MenuItem, CircularProgress, Paper, FormControl, InputLabel } from '@mui/material';
import { Col, Row } from 'antd';
import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { fullMobile } from '../util';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, } from 'firebase/firestore';

import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import uuid from 'react-uuid';
import CapacityTable from './capacityTable';




var randomColor = require('randomcolor');

export default function CapacityHome({ userInfo, screenWidth, openSnack, company, account }) {
    const props = { userInfo, screenWidth, openSnack, company, account, };
    const [loading, setLoading] = useState(true);
    const [query, setQuery] = useState({
        dataSource: 'node',
        startDate: new Date(moment().subtract(7, 'days')),
        endDate: new Date(),
        parameters: [],
        nodes: [],
        probes: [],
        rainGauges: [],
        rainParameters: [],
        showQuery: true,
        querying: false,
    });
    const [PPArray, setPPArray] = useState([]);
    const [WPArray, setWPArray] = useState([]);
    const [parameters, setParameters] = useState([]);
    const [nodes, setNodes] = useState([]);
    const [probes, setProbes] = useState([]);
    const [codesList, setCodes] = useState([]);
    const [addData, setAddData] = useState(false);
    const [rainGauges, setRainGauges] = useState([])



    useEffect(() => {
        window.scrollTo(0, 0);
        getInfo()
    }, [])

    async function getInfo() {







    }







    return (
        <div style={{ fontFamily: 'Roboto, sans-serif', backgroundColor: '#f6f8fe', minHeight: '100vh' }}>
            <Row style={{ padding: 30 }} align="middle" >
                <Col {...fullMobile(20, 24)}>
                    <Typography variant='h3' fontWeight={500} fontSize={36}>Capacity</Typography>


                </Col>
                <Col {...fullMobile(4, 24)} style={{ textAlign: 'right' }}>
                    <Button variant="contained" size="large" display="inline" style={{ textTransform: 'none', backgroundColor: '#404050', fontSize: 14 }} onClick={() => {
                        setAddData(!addData)
                    }}>{addData === true ? "Query Data" : "+ Upload Data"}</Button>

                </Col>
                <Col {...fullMobile(24, 24)}>
                    <Divider style={{ paddingTop: 10 }} />
                </Col>

                {addData === false && (<Col {...fullMobile(24, 24)}>
                    <Paper style={{ padding: 0, borderRadius: 10, backgroundColor: '#fff' }} elevation={8}>
                        <Row>

                            <Col {...fullMobile(24, 24)} >
                                <Row>
                                    <Col {...fullMobile(24, 24)} style={{ paddingTop: 20, paddingLeft: 20, paddingRight: 20 }}>

                                        <CapacityTable {...props} />

                                    </Col>
                                </Row>
                            </Col>


                        </Row>

                    </Paper>
                </Col>)}


                {addData === true && (
                    <Col {...fullMobile(24, 24)}>

                    </Col>
                )}





            </Row>
        </div>
    );
}