import React from "react";


import { Box, Button, ButtonGroup, Modal, TextField, Typography } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Col, Row } from 'antd';
import uuid from 'react-uuid';
import '../taskList.css';
import ItemForm from "../../../items/itemForm";
import { fullMobile } from "../../../waterQuality/stations/util";
import { queryItems } from "../../../firebase/config";
import CreateStockForm from "../../../items/productStocks/createStockForm";



const StocksSelect = (
    {
        stocks,
        currentTask,
        setCurrentTask,
        userInfo,
        setStocks,
        openSnack,
        accounts,
        headerKey,
        type,
        updateRecord,
        record,
        setDisableDrag

    }

) => {
    const [itemModal, setItemModal] = React.useState(false);
    const [newItemType, setNewItemType] = React.useState('product');
    const [newItem, setNewItem] = React.useState({});



    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: (option) => option.label,
    });

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',

        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflowX: 'hidden',
        overflowY: 'scroll',
    };


    return (
        <div>

            <Autocomplete
                fullWidth
                size="small"
                style={{ backgroundColor: '#ffffff' }}
                disableCloseOnSelect={false}
                id="combo-box-demo"
                value={type === 'fieldLog' ? stocks.find(x => x.key === record?.stock)?.name : currentTask?.[headerKey]}

                options={stocks?.sort((a, b) => {
                    // Convert to strings and lowercase
                    const aValue = String(a.label).toLowerCase();
                    const bValue = String(b.label).toLowerCase();

                    // Perform the sorting
                    if (aValue < bValue) return -1;
                    if (aValue > bValue) return 1;
                    return 0;
                })}
                onClose={(e) => {
                    console.log('BLURRED')
                    e.stopPropagation();
                    e.preventDefault();
                    setDisableDrag(false);
                }}


                filterOptions={(options, _ref) => [...filterOptions(options, _ref), { psType: 'other', label: 'Add New', key: 'Add New', value: 'addNew' },]}
                noOptionsText={<Button fullWidth variant='contained' color='primary' style={{ textTransform: 'none' }}
                    onClick={() => {
                        setItemModal(true)
                        setNewItem({
                            name: '',
                            description: '',
                            location: null,
                            archived: false,
                            company: userInfo.currentCompany,
                            key: uuid()
                        });
                    }}
                >+ Create Stock</Button>}

                renderInput={(params) => {
                    return (
                        <TextField {...params} placeholder="Stock" variant={type === 'fieldLog' ? 'outlined' : 'standard'}
                            onClick={(e) => {
                                console.log('CLICKED')
                                e.stopPropagation();
                                e.preventDefault();
                                setDisableDrag(true);
                            }}
                            sx={{
                                '& .MuiInputBase-root': {
                                    color: 'black',
                                    fontWeight: 500,
                                    fontSize: 14,
                                    backgroundColor: '#ffffff',

                                },

                            }} />
                    )
                }}
                renderOption={(params) => {

                    if (params.key === 'Add New') {
                        return (
                            <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 4 }}>
                                <Button fullWidth variant='contained' color='primary' style={{ textTransform: 'none', backgroundColor: '#404050' }} onClick={(e) => {
                                    setItemModal(true)
                                    setNewItem({
                                        name: '',
                                        description: '',
                                        location: null,
                                        archived: false,
                                        company: userInfo.currentCompany,
                                        key: uuid()
                                    });


                                }}>+ Create Stock</Button>
                            </Box>

                        )
                    }
                    else {
                        return (
                            <Typography {...params}>{params.key}</Typography>
                        )
                    }
                }}
                onChange={(a, d) => {

                    if (type !== 'fieldLog') {
                        console.log(headerKey)
                        console.log({ ...currentTask, [headerKey]: d })
                        delete currentTask[headerKey]
                        setCurrentTask({ ...currentTask, [headerKey]: d })
                    }

                    if (type === 'fieldLog') {
                        console.log("UPDATE RECORD")
                        console.log(d)
                        updateRecord({ ...record, stock: d?.key || null })
                    }




                }}

            />

            <Modal title="" open={itemModal}



                onClose={() => {
                    setItemModal(false)


                }} footer={null} width={'60%'}  >
                <Box sx={style}>
                    <CreateStockForm setStocks={setStocks} stocks={stocks} setItemModal={setItemModal} openSnack={openSnack} userInfo={userInfo} stock={newItem} setStock={setNewItem} accounts={accounts}
                        setAddStockModal={setItemModal} type={type} updateRecord={updateRecord} record={record}
                    />
                </Box>
            </Modal >
        </div>



    );
};

export default StocksSelect;
