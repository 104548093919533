
import { List, ListItem, ListItemText } from '@mui/material';
import { withStyles } from '@mui/styles';
import React from 'react';



export default class SiderMenuPublic extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,

            productsCollapse: false,
            publicPortal: false,
            publicPortalAccount: false,
            servicesCollapse: false,
            accountCore: false,
            accountAccount: false,
            accountWaterQuality: false,
            companyTools: false,
            accountTools: false,
            openAccount: false,
            staffInfo: {},
            company: this.props.company,
            fieldLogs: false,
            chemicals: false,
            mobile: this.props.mobile,
            page: this.props.page,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ company: this.props.company })
        }


        if (this.props.mobile !== prevProps.mobile) {

            this.setState({ mobile: this.props.mobile })
        }
        if (this.props.page !== prevProps.page) {

            this.setState({ page: this.props.page })
        }
    }
    componentDidMount() {
        const { userInfo, company } = this.state;


    }



    closeDrawer = () => {
        const { screenWidth } = this.state;

        {
            if (screenWidth === 'xs') {
                this.props.closeDrawer()
            }
        }

    }

    render() {

       

        const { company, productsCollapse, publicPortal, publicPortalAccount, servicesCollapse, companyTools, accountTools, userInfo, page,
            screenWidth, accountCore, accountWaterQuality, accountAccount, staffInfo, fieldLogs, chemicals, mobile } = this.state;


        const companyType = company === undefined ? undefined : company.companyTypeKey !== undefined ? company.companyTypeKey : undefined;





        return (
            <List>



                {page !== 'login' && (<ListItem button key={"Sites"} onClick={() => {
                    this.props.changePage('sites')
                }}   >
                    <ListItemText primary={['district', 'municipality', 'citizenScientist'].includes(companyType) ? 'Sites' : 'Sites'} />
                </ListItem>)}


















            </List>
        );
    }
}



