import { collection, getDocs, getDoc, addDoc, updateDoc, doc, getFirestore, deleteDoc, writeBatch, setDoc, where, query, orderBy, limit, startAfter, getCountFromServer } from 'firebase/firestore';
import { getStorage, ref, getMetadata, uploadBytes, getDownloadURL } from "firebase/storage";
import uuid from 'react-uuid';
import axios, * as others from 'axios';
import moment from 'moment-timezone';




export const getItems = async (userInfo, path) => {
    const db = getFirestore();
    try {
        const itemsRef = collection(db, "clients", userInfo.currentCompany, path);
        const itemsQuery = query(itemsRef, where("account", "==", userInfo.currentAccount));
        const itemsSnapshot = await getDocs(itemsQuery);
        const items = itemsSnapshot.docs.map((doc) => ({ ...doc.data() }));
        console.log(items)
        return items;
    } catch (error) {
        return [];
    }
};




export const deleteItems = async (ids, userInfo, path) => {
    const db = getFirestore();
    try {
        const batch = writeBatch(db);
        ids.forEach((id) => {
            const itemRef = doc(db, 'clients', userInfo.currentCompany, path, id);
            batch.delete(itemRef);
        });
        await batch.commit();
        return 'success'
    } catch (error) {
        console.error('Error deleting items:', error);
    }
};




export const createItem = async (item, userInfo) => {
    const db = getFirestore();
    try {
        const itemData = {
            ...item,
            dataType: 'obscape',
            id: item.tempid,
            account: userInfo.currentAccount,
            company: userInfo.currentCompany,
            createdBy: userInfo.email,
        };
        const itemRef = doc(db, 'clients', itemData.company, 'obscapeStations', itemData.id);
        await setDoc(itemRef, itemData);
        if (item?.getHistoricalImages === true) {
            console.log('getting historical images')


        }
        return itemData;
    }
    catch (error) {

        console.error('Error adding item:', error);
    }
};

export const updateItem = async (item, userInfo) => {
    const db = getFirestore();
    try {
        const itemData = {
            ...item,
            account: userInfo.currentAccount,
            company: userInfo.currentCompany,
            createdBy: userInfo.email,
        };
        const itemRef = doc(db, 'clients', itemData.company, 'obscapeStations', itemData.id);
        await updateDoc(itemRef, itemData);
        return itemData;
    }
    catch (error) {

        console.error('Error updating item:', error);
    }
}


export const getDevices = async (username, key) => {
    console.log('Getting Obscape data');
    const proxyurl = "https://mycorslake.herokuapp.com/";
    const obscapeDataURL = 'https://www.obscape.com/portal/api/v3/api'; // Updated URL for Obscape API

    const data = {
        username, // Your username
        key, // Your key
    };

    const url = `${obscapeDataURL}?username=${data.username}&key=${data.key}`;

    const obscapeData = await axios.get(proxyurl + url)
        .then(response => {
            return response.data;
        }).catch((error) => {
            console.log(error);
            return [];
        });

    console.log(obscapeData);

    return obscapeData;
}
export const getDeviceInfo = async (currentItem) => {
    const proxyurl = "https://mycorslake.herokuapp.com/";
    const obscapeDataURL = 'https://www.obscape.com/portal/api/v3/api'; // Updated URL for Obscape API

    const data = {
        username: currentItem.username, // Your username
        key: currentItem.apiKey, // Your key
        station: currentItem?.device?.id,
        tz: 'local',
    };

    const url = `${obscapeDataURL}?username=${data.username}&key=${data.key}&station=${data.station}`;

    const obscapeData = await axios.get(proxyurl + url)
        .then(async response => {
            const data = [];

            response.data.data.map((item) => {
                var time = moment(item.time, 'X').tz("UTC").format("YYYY-MM-DD HH:mm:ss");
                data.push({
                    time: `'${time}'`,
                    solar: item.solar,
                    precipitation: item.precipitation,
                    strikes: item.strikes,
                    strikeDistance: item.strikeDistance,
                    windSpeed: item.windSpeed,
                    windDirection: item.windDirection,
                    gustWindSpeed: item.gustWindSpeed,
                    gustWindDirection: item.gustWindDirection,
                    airTemperature: item.airTemperature,
                    vaporPressure: item.vaporPressure,
                    atmosphericPressure: item.atmosphericPressure,
                    relativeHumidity: item.relativeHumidity,
                    humiditySensorTemperature: item.humiditySensorTemperature,
                    xOrientation: item.xOrientation,
                    yOrientation: item.yOrientation,
                    NorthWindSpeed: item.NorthWindSpeed,
                    EastWindSpeed: item.EastWindSpeed,
                    gustNorthWindSpeed: item.gustNorthWindSpeed,
                    gustEastWindSpeed: item.gustEastWindSpeed,
                    lat: item.lat,
                    lon: item.lon,
                    VBAT: item.VBAT,
                    VSOL: item.VSOL,
                    TEMP: item.TEMP,
                    HUM: item.HUM,
                    PATM: item.PATM,
                    Xtilt: item.Xtilt,
                    Ytilt: item.Ytilt,
                    CSQ: item.CSQ,
                    RAT: item.RAT,
                    oper: `'${item.oper}'`,
                    source: `'${item.source}'`,
                    invalid: `'${item.invalid}'`,
                    tstr: `'${item.tstr}'`,
                    obscapestation: `'${currentItem.id}'`,
                    station: `'${currentItem.device.id}'`,
                    id: `'${uuid()}'`,
                });
            });
            console.log(data)
            const hourlyVals = data.map((h) => `(${Object.values(h)})`);
            const hourlyString = hourlyVals.join();

            try {
                await axios.post(proxyurl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                    raw: `INSERT INTO obscape_new VALUES ${hourlyString} ON CONFLICT DO NOTHING; `,
                });
                console.log('SUCCESS');
            } catch (error) {
                console.log(error);
            }

            console.log(data)

            return data;
        }).catch((error) => {
            console.log(error);
            return [];
        });




    console.log(obscapeData);

    return obscapeData;
}









