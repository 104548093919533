import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import SettingsIcon from '@mui/icons-material/Settings';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Autocomplete, Box, Button, ButtonGroup, Checkbox, CircularProgress, Divider, IconButton, InputAdornment, MenuItem, OutlinedInput, Select, TextField, Typography, Drawer, Input } from '@mui/material';
import Tab from '@mui/material/Tab';
import { Col, Popconfirm, Row, Switch } from 'antd';
import { doc, getDoc, getFirestore, setDoc, updateDoc } from 'firebase/firestore';
import moment from 'moment';
import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ReactSortable } from 'react-sortablejs';
import uuid from 'react-uuid';
import TaskColor from './taskColor';
import { faCheckCircle, faCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './taskList.css'
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { faTrash } from '@fortawesome/free-solid-svg-icons';


const localizer = momentLocalizer(moment);
var randomColor = require('randomcolor');
export default class TaskList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            companyStaff: this.props.companyStaff,
            accounts: this.props.accounts,
            account: this.props.account,
            selectedRecord: {},
            taskOpen: false,
            createTask: false,
            list: [],
            fullList: [],
            showCompleted: false,
            newTask: {},
            staffFilter: [],
            siteFilter: [],
            staffColors: {},
            siteColors: {},
            activeTab: 'tasks',
            activeSettingsTab: 'settings',
            calendarEvents: [],
            loading: true,
            settingsDrawer: false,
            sortType: '',
            searchQuery: '',
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
        if (this.props.accounts !== prevProps.accounts) {
            this.setState({ accounts: this.props.accounts })
        }
        if (this.props.companyStaff !== prevProps.companyStaff) {
            this.setState({ companyStaff: this.props.companyStaff })
        }

    }
    componentDidMount() {
        window.scrollTo(0, 0)
        this.queryData()
    }

    queryData = async () => {
        const { userInfo, } = this.state;
        const db = getFirestore();
        const docRef = doc(db, "clients", userInfo.currentCompany, 'tasks', 'taskInfo');
        const docSnap = await getDoc(docRef);

        console.log(docSnap.exists())

        if (docSnap.exists()) {
            const preferences = JSON.parse(docSnap.data().preferences)
            console.log(preferences)
            const list = JSON.parse(docSnap.data().list)

            const setDates = list.map((d) => {
                return ({ ...d, date: d.date !== undefined ? new Date(moment(d.date)) : undefined })
            })
            console.log(setDates)
            this.setState({
                list: setDates,
                staffFilter: preferences.staffFilter,
                siteFilter: preferences.siteFilter,
                activeTab: preferences.activeTab,
                siteColors: preferences.siteColors || {},
                staffColors: preferences.staffColors || {},
                showCompleted: preferences.showCompleted,
                loading: false
            })
        }
        else {

            const docRef = doc(db, "clients", userInfo.currentCompany, 'tasks', 'taskInfo');
            await setDoc(docRef, {
                list: JSON.stringify([]),
                preferences: JSON.stringify({ staffFilter: [], siteFilter: [], activeTab: 'tasks', showCompleted: false, siteColors: {}, staffColors: {} })
            });
            this.setState({ loading: false })

        }

    }


    selectedRecord = (record) => {
        console.log(record)
        const newSelectedRecord = {
            ...record,
        };
        this.setState({ selectedRecord: newSelectedRecord, })
        setTimeout(() => {
            this.setState({ taskOpen: true })
        }, 250);
        console.log(record)
    }

    onClose = () => {
        this.setState({ taskOpen: false, createTask: false, settingsDrawer: false })
        this.handleCheck()
    }

    updateNewTask = (boo, val) => {
        const { newTask } = this.state;
        newTask[boo] = val;
        this.setState({ newTask })
        console.log(newTask)
    }

    createTask = () => {
        const { newTask, list, fullList } = this.state;
        console.log(newTask)
        newTask.key = uuid()
        newTask.color = newTask.color || "#9acae5";
        newTask.completed = false;
        newTask.filtered = false;
        if (newTask.date !== undefined) {
            newTask.start = moment(newTask.date).format("YYYY-MM-DD HH:mm:ss");
            newTask.end = moment(newTask.date).add(1, 'hour').format("YYYY-MM-DD HH:mm:ss");
        }


        this.setState({
            list: [...list, newTask], fullList: [...fullList, newTask], createTask: false, newTask: {}
        })

        if (["Daily", "Weekly", "Monthly", 'Quarterly', "Yearly"].includes(newTask.recurring) && newTask.endDate !== undefined && newTask.date !== undefined &&
            Number(moment(newTask.endDate).format('x')) > Number(moment(newTask.date).format('x'))) {
            this.createRecurring(newTask)
        }
        this.handleCheck()
    }

    duplicateTask = (record) => {
        const { list } = this.state;
        const key = `123${uuid()}`;
        const attributes = { ...record };
        const newList = [...list, { ...attributes, key, completed: false }]
        this.setState({
            list: newList,
        });
        this.props.openSnack('success', 'Task Duplicated')
    }

    createRecurring = (task) => {
        console.log(task)
        const { list } = this.state;
        const times = [
            { label: "Daily", units: 'days', count: 1 },
            { label: "Weekly", units: 'weeks', count: 1 },
            { label: "Monthly", units: 'months', count: 1 },
            { label: "Quarterly", units: 'months', count: 4 },
            { label: "Yearly", units: 'years', count: 1 },
        ]

        const timeframe = times.filter((f) => f.label === task.recurring)[0];

        var x = new moment(task.date)
        var y = new moment(task.endDate).endOf('day')
        var duration = moment.duration(y.diff(x)).as(timeframe.units);



        const number = Math.floor(duration)


        const array = [...Array(number).keys()]
        const data = [];





        array.map((l, i) => {
            console.log(l)
            const key = `${uuid()}`;
            const attributes = { ...task };
            const add = l + 1;
            const newDate = new Date(moment(task.date).add(add, timeframe.units))
            const sDate = moment(newDate).format("YYYY-MM-DD HH:mm:ss");
            const eDate = moment(newDate).add(1, 'hour').format("YYYY-MM-DD HH:mm:ss");
            const timestamp = moment(newDate).format('x')
            data.push({
                ...attributes, key, completed: false, date: newDate, timestamp, start: new Date(sDate),
                end: new Date(eDate)
            })
            if (i === 0) {
                const key2 = `${uuid()}2`;
                const add = l;
                const newDate = new Date(moment(task.date).add(add, timeframe.units))
                const sDate = moment(newDate).format("YYYY-MM-DD HH:mm:ss");
                const eDate = moment(newDate).add(1, 'hour').format("YYYY-MM-DD HH:mm:ss");
                const timestamp = moment(newDate).format('x')
                data.push({
                    ...attributes, key: key2, completed: false, date: newDate, timestamp, start: new Date(sDate),
                    end: new Date(eDate)
                })
            }

            if (i + 1 === array.length) {
                this.props.openSnack('success', 'Tasks Added')
            }
        })


        const newList = [...list, ...data]
        this.setState({
            list: newList,
        });





    }



    deleteTask = (record) => {
        const { list, userInfo } = this.state;
        const db = getFirestore();
        const filteredList = list.filter((f) => f.key !== record.key)
        this.setState({
            list: filteredList, createTask: false, newTask: {}, selectedRecord: {}, taskOpen: false
        })
        this.handleCheck()



    }

    completeTask = (e, completed) => {
        const { list, fullList, showCompleted } = this.state;
        console.log(e)
        console.log(completed)
        e.completed = completed;
        const index = fullList.map((l) => l.key).indexOf(e.key);
        console.log(index);
        fullList[index].completed = completed;

        if (completed === true) {
            const filteredList = list.filter((f) => f.key !== e.key);
            this.setState({ list: filteredList })
            this.handleCheck()
        }
        else {
            this.setState({ list: [...list, e] })
            this.handleCheck()
        }

        this.setState({ fullList })



    }

    updateTask = (key, boo, val) => {
        const { list } = this.state;

        const index = list.map((l) => l.key).indexOf(key);
        list[index][boo] = val;
        this.setState({ list })

    }


    handleCheck = () => {
        const { loading } = this.state;
        // Clears running timer and starts a new one each time the user types
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {

            if (loading === false) {
                this.toggleCheck();
            }

        }, 1000);
    }

    toggleCheck = async () => {
        console.log("POST DESCRIPTION")
        const { list, userInfo, staffFilter, siteFilter, activeTab, showCompleted, siteColors, staffColors } = this.state;
        console.log(list)
        const db = getFirestore();


        const itemRef = doc(db, "clients", userInfo.currentCompany, 'tasks', 'taskInfo');
        await updateDoc(itemRef, {
            list: JSON.stringify(list),
            preferences: JSON.stringify({ staffFilter, siteFilter, activeTab, showCompleted, siteColors, staffColors })
        })

        const newList = [];
        list.map((l) => {
            if (l.date !== undefined) {
                const newDate = new Date(moment(l.date))
                const sDate = moment(l.date).format("YYYY-MM-DD HH:mm:ss");
                const eDate = moment(l.date).add(1, 'hour').format("YYYY-MM-DD HH:mm:ss");
                const timestamp = moment(newDate).format('x')
                newList.push({
                    ...l, date: newDate, timestamp, start: new Date(sDate),
                    end: new Date(eDate)
                })
            }
            else {
                newList.push({ ...l })
            }

        })
        this.setState({ list: newList })



    }

    filterList = () => {
        const { staffFilter, siteFilter, list } = this.state;
        console.log(staffFilter);
        console.log(siteFilter);
        if ([...staffFilter, ...siteFilter].length === 0) {
            const newList = list.map((l) => {
                const sDate = moment(l.date).format("YYYY-MM-DD HH:mm:ss");
                const eDate = moment(l.date).add(1, 'hour').format("YYYY-MM-DD HH:mm:ss");
                return ({
                    ...l, filtered: false, start: new Date(sDate),
                    end: new Date(eDate)
                })
            })

            console.log(newList)

            this.setState({ list: newList })
            this.handleCheck()

        }
        else {
            const filters = [];
            staffFilter.map((f) => { filters.push(f.id) });
            siteFilter.map((f) => { filters.push(f.key) });
            const newList = list.map((f) => {
                const sDate = moment(f.date).format("YYYY-MM-DD HH:mm:ss");
                const eDate = moment(f.date).add(1, 'hour').format("YYYY-MM-DD HH:mm:ss");

                return ({
                    ...f, filtered: filters.includes(f.site?.key) || filters.includes(f.assignee?.id) ? false : true,
                    start: new Date(sDate),
                    end: new Date(eDate)
                })
            })
            this.setState({ list: newList })
            this.handleCheck()

        }
    }


    clearFilters = () => {
        const { list } = this.state;
        const newList = list.map((l) => { return ({ ...l, filtered: false }) })
        this.setState({ list: newList, staffFilter: [], siteFilter: [] })
        this.handleCheck()

    }

    eventStyleGetter = (event, start, end, isSelected) => {

        var backgroundColor = event.color || 'rgba(0,0,0,0.4)';
        var style = {
            backgroundColor: backgroundColor,
            borderRadius: 8,
            opacity: 0.7,
            fontWeight: 500,
            color: 'black',
            border: event.completed ? '3px #16c80e solid' : '3px #ff8500 solid',
            display: 'block',
            fontFamily: 'Roboto, sans-serif',
        };
        return {
            style: style
        };
    }

    handleSort = (key, compareFunction) => {
        const { list, sortType } = this.state;
        const newSortType = sortType.endsWith('Up') ? `${key}Down` : `${key}Up`;

        list.sort((a, b) => {
            const comparison = compareFunction(a, b);
            return sortType.endsWith('Up') ? comparison : -comparison;
        });

        this.setState({ sortType: newSortType, list });
    };


    handleSearchChange = (e) => {
        this.setState({ searchQuery: e.target.value }, () => {
            this.searchList();
        });
    };
    searchList = () => {
        const { list, searchQuery } = this.state;
        const lowerCaseSearchQuery = searchQuery.toLowerCase();

        console.log(list)

        //create a new list with the filtered values.

//search with regex expression

        const newList = list.map((l) => {
            const sDate = moment(l.date).format("YYYY-MM-DD HH:mm:ss");
            const eDate = moment(l.date).add(1, 'hour').format("YYYY-MM-DD HH:mm:ss");
            return ({
                ...l, filtered: l.title.toLowerCase().includes(lowerCaseSearchQuery) ? false : true,
                start: new Date(sDate),
                end: new Date(eDate)
            })
        }

        )
        console.log(newList)
        this.setState({ list: newList })
        this.handleCheck()




    };





    render() {


        const { account, taskOpen, list, screenWidth, selectedRecord, createTask, newTask, accounts, companyStaff, fullList, showCompleted, loading,
            staffFilter, siteFilter, activeTab, calendarEvents, settingsDrawer, activeSettingsTab, staffColors, siteColors, sortType } = this.state;





        const CalendarList = list.filter((f) => f.filtered === false && showCompleted ? [true, false].includes(f.completed) : [false].includes(f.completed))

        const iconStyle = (type) => ({
            fontSize: sortType === type ? 18 : 0,
            color: sortType === type ? 'black' : 'black',
        });



        return (

            <Col span={23} style={{ paddingLeft: 30, paddingTop: 10, paddingBottom: 30 }}>



                <Row >
                    <Col span={18} style={{
                        height: '70px',
                        borderBottom: '1px solid rgba(0,0,0,0.2)',
                        display: 'flex',
                        alignItems: 'flex-end', // Adjusted this line
                        justifyContent: 'flex-start', // Adjusted this line for better readability
                    }}>
                        <ButtonGroup size="small" aria-label="small button group">
                            <Button
                                key="tasks"
                                onClick={(e) => this.setState({ activeTab: 'tasks' })}
                                color='primary'
                                variant={null}
                                style={{
                                    height: '50px',
                                    textTransform: 'none',
                                    borderRadius: 0,
                                    fontWeight: 400,
                                    fontSize: 18,
                                    color: activeTab === 'tasks' ? '#6fa2f1' : null,
                                    borderBottom: activeTab === 'tasks' ? '3px solid #6fa2f1' : null
                                }}
                            >
                                Tasks
                            </Button>
                            <Button
                                key='calendar'
                                onClick={(e) => this.setState({ activeTab: 'calendar' })}
                                color='primary'
                                variant={null}
                                style={{
                                    textTransform: 'none',
                                    borderRadius: 0,
                                    height: '50px',
                                    fontWeight: 400,
                                    fontSize: 18,
                                    color: activeTab === 'calendar' ? '#6fa2f1' : null,
                                    borderBottom: activeTab === 'calendar' ? '3px solid #6fa2f1' : null
                                }}
                            >
                                Calendar
                            </Button>
                        </ButtonGroup>
                    </Col>

                    <Col span={6} style={{ height: '70px', display: 'flex', alignItems: 'center', justifyContent: 'right', borderBottom: '1px solid rgba(0,0,0,0.2)', }}>
                        <OutlinedInput
                            size="small"
                            style={{
                                borderRadius: 16
                            }}
                            value={this.state.searchQuery}
                            fullWidth
                            placeholder='Search'
                            startAdornment={
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            }
                            endAdornment={
                                <InputAdornment position="end">
                                    {this.state.searchQuery !== '' && (
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            edge="end"
                                            onClick={() => this.setState({ searchQuery: '' }, () => {
                                                this.searchList();
                                            })}
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    )}
                                </InputAdornment>
                            }
                            onChange={this.handleSearchChange}
                        />

                    </Col>

                </Row>
                <Row align="middle">
                    <Col span={23}>



                        <Row align="middle" >
                            <Col xs={24} sm={4} style={{ paddingTop: 30, paddingBottom: 15 }}>
                                <Button variant='contained' color='primary' style={{ textTransform: 'none' }}
                                    onClick={() =>
                                        this.setState({
                                            createTask: true
                                        })}>+ Add Task</Button>
                            </Col>
                            <Col xs={24} sm={4} style={{ paddingTop: 30, paddingBottom: 15, paddingRight: 12, }}>
                                <Autocomplete
                                    fullWidth
                                    multiple={true}
                                    disableCloseOnSelect={true}
                                    filterSelectedOptions={true}
                                    labelId="hidden"
                                    id="hidden"
                                    type="hidden"
                                    size="small"
                                    label="none"
                                    notched={false}
                                    options={companyStaff}
                                    autoComplete={false}
                                    value={staffFilter}
                                    hiddenLabel={true}
                                    renderInput={(params) => <TextField {...params} label="Assignee" />}
                                    onChange={(e, b) => this.setState({ staffFilter: b })}

                                />
                            </Col>
                            <Col xs={24} sm={4} style={{ paddingTop: 30, paddingBottom: 15, paddingRight: 12, }}>
                                <Autocomplete
                                    fullWidth
                                    multiple={true}
                                    filterSelectedOptions={true}
                                    disableCloseOnSelect={true}
                                    labelId="dtest2"
                                    id="test2"
                                    size="small"
                                    label="none"
                                    autoComplete={false}
                                    notched={false}
                                    options={accounts}
                                    value={siteFilter}
                                    hiddenLabel={true}
                                    renderInput={(params) => <TextField {...params} label="Site" />}
                                    onChange={(e, b) => this.setState({ siteFilter: b })}

                                />
                            </Col>

                            <Col xs={8} sm={6} style={{ paddingTop: 30, paddingBottom: 15 }}>
                                <Button variant='outlined' color='inherit' style={{ textTransform: 'none' }}
                                    onClick={() =>
                                        this.filterList()}>Filter</Button> <Button variant='outlined' color='inherit' style={{ textTransform: 'none' }}
                                            onClick={() =>
                                                this.clearFilters()}>Clear Filters</Button>
                            </Col>

                            <Col xs={8} sm={4} style={{ paddingTop: 30, paddingBottom: 15, textAlign: 'right' }}>
                                <Typography variant="body1">Show Completed   <Switch checked={showCompleted} onChange={(e) => {
                                    this.setState({ showCompleted: e })
                                    this.handleCheck()
                                }} /></Typography>


                            </Col>

                            <Col xs={2} sm={2} style={{ paddingTop: 30, paddingBottom: 15, textAlign: 'right' }}>
                                <IconButton color="primary" size="small" onClick={async () => {
                                    this.setState({ settingsDrawer: !settingsDrawer })
                                }}>
                                    <SettingsIcon color="inherit" />
                                </IconButton>


                            </Col>

                        </Row>
                    </Col>
                </Row>

                {activeTab === 'tasks' && (
                    <Row style={{}}>
                        <Col span={24}>


                            <Row align="middle" >

                                <Col span={24}>
                                    <Divider />
                                </Col>
                                <Col span={24} style={{ paddingTop: 5, paddingBottom: 5, backgroundColor: '#f7f7f7' }}>
                                    <Row align="middle" gutter={[12, 12]} >


                                        <Col xs={{ span: 21, offset: 2 }} sm={{ span: 21, offset: 2 }} style={{
                                            cursor: 'pointer',
                                        }}>
                                            <Row align="middle" gutter={[12, 12]}>
                                                <Col xs={10} sm={10} onClick={() => this.handleSort('title', (a, b) => a.title.localeCompare(b.title))} style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'left'
                                                }}>
                                                    <Typography variant="body1" fontWeight={700} fontSize={screenWidth === 'xs' ? 14 : 20}>
                                                        Task
                                                        <CaretUpOutlined style={iconStyle('titleUp')} />
                                                        <CaretDownOutlined style={iconStyle('titleDown')} />
                                                    </Typography>
                                                </Col>
                                                <Col xs={4} sm={4} onClick={() => this.handleSort('date', (a, b) => a.timestamp - b.timestamp)} style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'left'
                                                }}>
                                                    <Typography variant="body1" fontWeight={700} fontSize={screenWidth === 'xs' ? 14 : 20}>
                                                        Date
                                                        <CaretUpOutlined style={iconStyle('dateUp')} />
                                                        <CaretDownOutlined style={iconStyle('dateDown')} />
                                                    </Typography>
                                                </Col>
                                                <Col xs={5} sm={5} onClick={() => this.handleSort('assignee', (a, b) => a.assignee?.label.localeCompare(b.assignee?.label))} style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'left'
                                                }}>
                                                    <Typography variant="body1" fontWeight={700} fontSize={screenWidth === 'xs' ? 14 : 20}>
                                                        {screenWidth === 'xs' ? "Assign." : "Assignee"}
                                                        <CaretUpOutlined style={iconStyle('assigneeUp')} />
                                                        <CaretDownOutlined style={iconStyle('assigneeDown')} />
                                                    </Typography>
                                                </Col>
                                                <Col xs={5} sm={5} onClick={() => this.handleSort('site', (a, b) => a.site?.label.localeCompare(b.site?.label))} style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'left'
                                                }}>
                                                    <Typography variant="body1" fontWeight={700} fontSize={screenWidth === 'xs' ? 14 : 20}>
                                                        Site
                                                        <CaretUpOutlined style={iconStyle('siteUp')} />
                                                        <CaretDownOutlined style={iconStyle('siteDown')} />
                                                    </Typography>
                                                </Col>
                                            </Row>
                                        </Col>

                                    </Row>
                                </Col>

                                <Col span={24} style={{ paddingBottom: 30, paddingRight: 0, marginRight: 0 }}>

                                    <ReactSortable
                                        handle=".my-handle"
                                        animation={500}
                                        list={list}
                                        setList={(newState) => {
                                            this.setState({ list: newState, fullList: showCompleted ? newState : this.state.fullList });
                                            this.handleCheck();
                                        }}
                                    >
                                        {list.map((l, i) => {
                                            const TaskIcon = ({ task, handleTaskUpdate }) => (
                                                <FontAwesomeIcon
                                                    size='xl'
                                                    icon={task.completed ? faCheckCircle : faCircle}
                                                    style={{
                                                        color: task.completed ? '#1bd81b' : 'grey',
                                                        cursor: 'pointer',
                                                        paddingRight: 5,
                                                    }}
                                                    onClick={() => handleTaskUpdate(task.key, 'completed', !task.completed)}
                                                />
                                            );

                                            if ((showCompleted || !l.completed) && !l.filtered) {
                                                return (
                                                    <Col key={i} span={24} style={{}}>
                                                        <Row align="middle" gutter={[0, 0]} className="row-hover-effect" style={{
                                                            border: '1px solid rgba(0,0,0,0.2)', borderLeft: null, borderRight: null, padding: 0,
                                                            margin: 0
                                                        }}>

                                                            <Col xs={0} sm={2}>
                                                                <div className="my-handle" style={{ display: 'inline' }}>
                                                                    <DragIndicatorIcon />
                                                                </div>
                                                                <TaskIcon task={l} handleTaskUpdate={this.updateTask} />
                                                            </Col>
                                                            <Col xs={2} sm={0}>
                                                                <TaskIcon task={l} handleTaskUpdate={this.updateTask} />
                                                            </Col>

                                                            <Col span={21} style={{ cursor: 'pointer', margin: 0, padding: 0, height: '100%', }} onClick={() => { this.selectedRecord(l) }}>
                                                                <Row

                                                                    gutter={[0, 0]}
                                                                    style={{
                                                                        margin: 0,
                                                                        padding: 0,
                                                                        height: '100%',
                                                                        display: 'flex',
                                                                    }}
                                                                >
                                                                    <Col xs={10} sm={10} style={{
                                                                        borderLeft: '1px solid rgba(0,0,0,0.2)',
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'left',
                                                                        padding: 8,

                                                                    }}>
                                                                        <Typography variant="body2" fontWeight={500} fontSize={15}>{l.title}</Typography>
                                                                    </Col>
                                                                    <Col xs={4} sm={4} style={{

                                                                        borderLeft: '1px solid rgba(0,0,0,0.2)',
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'left',
                                                                        padding: 8,
                                                                    }}>
                                                                        <Typography variant="body2" fontWeight={500} fontSize={15}>{l.timestamp !== undefined ? moment(l.timestamp, 'x').format(
                                                                            screenWidth === 'xs' ? 'MMM DD, YY ha' : 'MM/DD/YY ha') : "No Date"}</Typography>
                                                                    </Col>
                                                                    <Col xs={5} sm={5} style={{

                                                                        borderLeft: '1px solid rgba(0,0,0,0.2)',
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'left',
                                                                        padding: 8,
                                                                    }}>
                                                                        <Typography variant="body2" fontWeight={500} fontSize={15}>{l.assignee?.label}</Typography>
                                                                    </Col>


                                                                    <Col xs={4} sm={4} style={{

                                                                        borderLeft: '1px solid rgba(0,0,0,0.2)',
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'left',
                                                                        padding: 8,
                                                                    }}>
                                                                        <Typography variant="body2" fontWeight={500} fontSize={15}>{l.site?.label}</Typography>
                                                                    </Col>

                                                                </Row>

                                                            </Col>
                                                            <Col xs={0} sm={1} style={{


                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'left',
                                                                padding: 8,
                                                            }}>
                                                                <div className="quick-delete" style={{ display: 'inline' }}>
                                                                    <FontAwesomeIcon icon={faTrash} style={{ color: 'gray', cursor: 'pointer' }} onClick={() => { this.deleteTask(l) }} />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                );
                                            } else {
                                                return <div key={i}></div>;
                                            }
                                        })}
                                    </ReactSortable>
                                </Col>

                                {loading === false && list.length === 0 && (<Col span={24} style={{ paddingBottom: 30, textAlign: 'center' }}>
                                    <Typography variant="h4">No Tasks</Typography>
                                </Col>)}
                                {loading && (<Col span={24} style={{ paddingBottom: 30, textAlign: 'center' }}>
                                    <CircularProgress />
                                </Col>)}


                            </Row>
                        </Col >



                    </Row >
                )
                }


                {
                    activeTab === 'calendar' && (

                        <Row gutter={[12, 12]}>
                            <Col span={24}>
                                <Calendar
                                    localizer={localizer}
                                    events={CalendarList}
                                    startAccessor="start"
                                    onSelectEvent={(event) => this.selectedRecord(event)}
                                    endAccessor="end"
                                    style={{ height: '85vh' }}
                                    eventPropGetter={(this.eventStyleGetter)}
                                />
                            </Col>

                        </Row>

                    )
                }



                <Drawer



                    anchor={'right'}

                    onClose={this.onClose}
                    open={taskOpen}



                >

                    <Row style={{ width: screenWidth === 'xs' ? '80vw' : '40vw', padding: 20, backgroundColor: '#f7f7f7', minHeight: '100vh' }}>
                        <Col span={24} style={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            backgroundColor: 'white',
                            padding: 12,
                            borderRadius: 8
                        }}>
                            <Row>

                                <Col xs={16} sm={20} style={{ paddingTop: 30 }}>
                                    <Button color={selectedRecord.completed ? 'primary' : 'inherit'} variant={selectedRecord.completed ? 'contained' : 'outlined'}
                                        style={{
                                            textTransform: 'none',
                                        }}
                                        onClick={() => {
                                            this.updateTask(selectedRecord.key, 'completed', !selectedRecord.completed)
                                        }}>
                                        <FontAwesomeIcon size="lg" icon={selectedRecord.completed ? faCheckCircle : faCircle} style={{ color: selectedRecord.completed ? 'white' : 'black', marginRight: 10 }} />


                                        Mark Complete</Button>
                                </Col>
                                <Col xs={8} sm={4} style={{ paddingTop: 30 }}>


                                    <Button color='secondary' variant="outlined"
                                        style={{
                                            textTransform: 'none',

                                        }}
                                        onClick={() => {
                                            this.deleteTask(selectedRecord)
                                        }}
                                    >
                                        <FontAwesomeIcon size="lg" icon={faTrash} style={{ color: 'black', marginRight: 10 }} />

                                        Delete</Button>


                                </Col>
                                <Col span={24} style={{ paddingTop: 15 }}>
                                    <Divider />
                                </Col>
                                <Col span={24} style={{ paddingTop: 15 }}>
                                    <Input
                                        fullWidth
                                        placeholder='Title'
                                        style={{
                                            fontWeight: 600,
                                            fontSize: 24,
                                            color: 'black'
                                        }}
                                        notched={false} label="none" defaultValue={selectedRecord.title || ''}
                                        onChange={(e) => this.updateTask(selectedRecord.key, 'title', e.target.value)} />
                                </Col>

                                <Col span={24} style={{ paddingTop: 15 }}>
                                    <Row align="middle" gutter={[12, 12,]}>
                                        <Col xs={10} sm={4}>
                                            <Typography variant='body1' fontWeight={600} fontSize={13} >Assignee<span style={{ color: 'red' }}>*</span></Typography>
                                        </Col>
                                        <Col xs={14} sm={20}>

                                            <Autocomplete
                                                fullWidth
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                size="small"
                                                label="none"
                                                notched={false}
                                                options={companyStaff}
                                                defaultValue={selectedRecord.assignee || ''}
                                                hiddenLabel={true}
                                                renderInput={(params) => <TextField {...params} placeholder="Assignee" />}
                                                onChange={(e, b) => this.updateTask(selectedRecord.key, 'assignee', b)}

                                            />


                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={24} style={{ paddingTop: 15 }}>
                                    <Row align="middle" gutter={[12, 12,]}>
                                        <Col xs={10} sm={4}>
                                            <Typography variant='body1' fontWeight={600} fontSize={13}>Site<span style={{ color: 'red' }}>*</span></Typography>
                                        </Col>
                                        <Col xs={14} sm={20}>
                                            <Autocomplete
                                                fullWidth
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                size="small"
                                                label="none"
                                                notched={false}
                                                options={accounts}
                                                defaultValue={selectedRecord.site || ''}
                                                hiddenLabel={true}
                                                renderInput={(params) => <TextField {...params} placeholder="Site" />}
                                                onChange={(e, b) => this.updateTask(selectedRecord.key, 'site', b)}

                                            />


                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={24} style={{ paddingTop: 15 }}>
                                    <Row align="middle" gutter={[12, 12,]}>
                                        <Col xs={10} sm={4}>
                                            <Typography variant='body1' fontWeight={600} fontSize={13}>Status</Typography>
                                        </Col>
                                        <Col xs={14} sm={20}>
                                            <Select
                                                fullWidth
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                size="small"
                                                label="none"
                                                notched={false}
                                                defaultValue={selectedRecord.status || ''}
                                                onChange={(e) => this.updateTask(selectedRecord.key, 'status', e.target.value)}>
                                                <MenuItem value={'Not-Started'}>Not-Started</MenuItem>
                                                <MenuItem value={'In-Progress'}>In-Progress</MenuItem>
                                                <MenuItem value={'Completed'}>Completed</MenuItem>
                                            </Select>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col span={24} style={{ paddingTop: 15 }}>
                                    <Row align="middle" gutter={[12, 12,]}>
                                        <Col xs={10} sm={4}>
                                            <Typography variant='body1' fontWeight={600} fontSize={13}>Date</Typography>
                                        </Col>
                                        <Col xs={14} sm={20}>
                                            <DatePicker portalId="root-portal"
                                                showTimeSelect
                                                timeFormat="h:mm a"
                                                timeIntervals={30}
                                                timeCaption="time"
                                                dateFormat="MMMM d, yyyy h:mm a" selected={selectedRecord.date || ''} onChange={(date) => {
                                                    selectedRecord.date = date;
                                                    selectedRecord.timestamp = moment(date).format('x');
                                                    this.setState({ selectedRecord })
                                                    this.updateTask(selectedRecord.key, 'date', date)
                                                    this.updateTask(selectedRecord.key, 'timestamp', moment(date).format('x'))
                                                }} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={24} style={{ paddingTop: 30 }}>
                                    <Typography variant='body1'>Description</Typography>
                                </Col>
                                <Col span={24} style={{ paddingTop: 15 }}>
                                    <OutlinedInput fullWidth placeholder='Description' notched={false} label="none" multiline={true} rows={3}
                                        defaultValue={selectedRecord.description || ''}
                                        onChange={(e) => {
                                            this.updateTask(selectedRecord.key, `description`, e.target.value,)
                                        }} />
                                </Col>


                                <Col span={24} style={{ paddingTop: 15 }}>
                                    <Row align="middle" gutter={[12, 12,]}>
                                        <Col xs={10} sm={4}>
                                            <Typography variant='body1'>Color</Typography>
                                        </Col>
                                        <Col xs={14} sm={20}>
                                            <TaskColor color={selectedRecord.color || "#9acae5"} updateData={(color, hex) => {

                                                this.updateTask(selectedRecord.key, `color`, hex,)
                                            }} />
                                        </Col>
                                    </Row>
                                </Col>

                                <Col span={24} style={{ paddingTop: 15 }}>
                                    <Divider />
                                </Col>
                                <Col span={24} style={{ paddingTop: 15 }}>
                                    <Button variant="outlined" color="primary" style={{ textTransform: 'none', padding: 13 }}
                                        onClick={() => {
                                            this.duplicateTask(selectedRecord)
                                        }} >Duplicate Task</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>


                </Drawer>
                <Drawer

                    anchor={'right'}
                    onClose={this.onClose}
                    open={createTask}


                >

                    <Row align="middle" style={{ width: screenWidth === 'xs' ? '80vw' : '40vw', padding: 10 }}>
                        <Col span={24} style={{ paddingTop: 30 }}>
                            <Row align="middle" gutter={[12, 12,]}>
                                <Col xs={10} sm={4}>
                                    <Typography variant='h6'>Task</Typography>
                                </Col>
                                {[newTask.assignee, newTask.site, newTask.title].filter((f) => [undefined, ''].includes(f)).length === 0 &&
                                    (<Col xs={14} sm={20} style={{ textAlign: 'right' }}>
                                        <Button variant='contained' color="primary"
                                            onClick={() => this.createTask()}>Add Task</Button>

                                    </Col>)
                                }
                            </Row>
                        </Col>
                        <Col span={24} style={{ paddingTop: 15 }}>
                            <Divider />
                        </Col>
                        <Col span={24} style={{ paddingTop: 15 }}>
                            <OutlinedInput fullWidth placeholder='Title' notched={false} label="none" value={newTask.title || ''}
                                onChange={(e) => this.updateNewTask('title', e.target.value)} />
                        </Col>

                        <Col span={24} style={{ paddingTop: 15 }}>
                            <Row align="middle" gutter={[12, 12,]}>
                                <Col xs={10} sm={4}>
                                    <Typography variant='body1'>Assignee<span style={{ color: 'red' }}>*</span></Typography>
                                </Col>
                                <Col xs={14} sm={20}>

                                    <Autocomplete
                                        fullWidth
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        size="small"
                                        label="none"
                                        notched={false}
                                        options={companyStaff}
                                        value={newTask.assignee || ''}
                                        hiddenLabel={true}
                                        renderInput={(params) => <TextField {...params} label="Assignee" />}
                                        onChange={(e, b) => this.updateNewTask('assignee', b)}

                                    />


                                </Col>
                            </Row>
                        </Col>
                        <Col span={24} style={{ paddingTop: 15 }}>
                            <Row align="middle" gutter={[12, 12,]}>
                                <Col xs={10} sm={4}>
                                    <Typography variant='body1'>Site<span style={{ color: 'red' }}>*</span></Typography>
                                </Col>
                                <Col xs={14} sm={20}>
                                    <Autocomplete
                                        fullWidth
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        size="small"
                                        label="none"
                                        notched={false}
                                        options={accounts}
                                        value={newTask.site || ''}
                                        hiddenLabel={true}
                                        renderInput={(params) => <TextField {...params} label="Site" />}
                                        onChange={(e, b) => this.updateNewTask('site', b)}

                                    />


                                </Col>
                            </Row>
                        </Col>
                        <Col span={24} style={{ paddingTop: 15 }}>
                            <Row align="middle" gutter={[12, 12,]}>
                                <Col xs={10} sm={4}>
                                    <Typography variant='body1'>Status</Typography>
                                </Col>
                                <Col xs={14} sm={20}>
                                    <Select
                                        fullWidth
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        size="small"
                                        label="none"
                                        notched={false}
                                        value={newTask.status || ''}
                                        onChange={(e) => this.updateNewTask('status', e.target.value)}>
                                        <MenuItem value={'Not-Started'}>Not-Started</MenuItem>
                                        <MenuItem value={'In-Progress'}>In-Progress</MenuItem>
                                        <MenuItem value={'Completed'}>Completed</MenuItem>
                                    </Select>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24} style={{ paddingTop: 15 }}>
                            <Row align="middle" gutter={[12, 12,]}>
                                <Col xs={10} sm={4}>
                                    <Typography variant='body1'>Recurring</Typography>
                                </Col>
                                <Col xs={14} sm={20}>
                                    <Select
                                        fullWidth
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        size="small"
                                        label="none"
                                        notched={false}
                                        value={newTask.recurring || ''}
                                        onChange={(e) => this.updateNewTask('recurring', e.target.value)}>
                                        {["One Time", "Daily", "Weekly", "Monthly", 'Quarterly', "Yearly"].map((f) => {
                                            return (
                                                <MenuItem value={f}>{f}</MenuItem>
                                            )
                                        })}


                                    </Select>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24} style={{ paddingTop: 15 }}>
                            <Row align="middle" gutter={[12, 12,]}>
                                <Col xs={10} sm={4}>
                                    <Typography variant='body1'>Date</Typography>
                                </Col>
                                <Col xs={14} sm={20}>
                                    <DatePicker showTimeSelect portalId="root-portal"
                                        timeFormat="h:mm a"
                                        timeIntervals={30}
                                        timeCaption="time"
                                        dateFormat="MMMM d, yyyy h:mm a" selected={newTask.date || ''} onChange={(date) => {
                                            this.updateNewTask('date', date)
                                            this.updateNewTask('timestamp', moment(date).format('x'))
                                        }} />
                                </Col>
                            </Row>
                        </Col>
                        {["Daily", "Weekly", "Monthly", 'Quarterly', "Yearly"].includes(newTask.recurring || '') && (<Col span={24} style={{ paddingTop: 15 }}>
                            <Row align="middle" gutter={[12, 12,]}>
                                <Col xs={10} sm={4}>
                                    <Typography variant='body1'>End Date</Typography>
                                </Col>
                                <Col xs={14} sm={20}>
                                    <DatePicker portalId="root-portal" selected={newTask.endDate || ''} onChange={(date) => {
                                        this.updateNewTask('endDate', date)
                                        this.updateNewTask('endTimestamp', moment(date).format('x'))
                                    }} />
                                </Col>
                            </Row>
                        </Col>)}
                        <Col span={24} style={{ paddingTop: 30 }}>
                            <Typography variant='body1'>Description</Typography>
                        </Col>
                        <Col span={24} style={{ paddingTop: 15 }}>
                            <OutlinedInput value={newTask.description || ''}
                                onChange={(e) => this.updateNewTask('description', e.target.value)}
                                fullWidth placeholder='Description' notched={false} label="none" multiline={true} rows={3} />
                        </Col>

                        <Col span={24} style={{ paddingTop: 15 }}>
                            <Row align="middle" gutter={[12, 12,]}>
                                <Col xs={10} sm={4}>
                                    <Typography variant='body1'>Color</Typography>
                                </Col>
                                <Col xs={14} sm={20}>
                                    <TaskColor color={newTask.color || "#9acae5"} updateData={(color, hex) => {
                                        this.updateNewTask(selectedRecord.key, `color`, hex)
                                    }} />


                                </Col>
                            </Row>
                        </Col>




                    </Row>


                </Drawer>
                <Drawer

                    title=""
                    width={screenWidth === 'xs' ? '80vw' : '40vw'}
                    placement={'right'}
                    closable={false}
                    onClose={this.onClose}
                    visible={settingsDrawer}
                    key={'right'}

                >

                    <Row align="middle">
                        <Col span={24} style={{ paddingTop: 30 }}>
                            <Row align="middle" gutter={[12, 12,]}>
                                <Col xs={24} sm={24}>
                                    <Typography variant='h6'>Task Settings</Typography>
                                </Col>

                            </Row>
                        </Col>
                        <Col span={24} style={{ paddingTop: 15 }}>
                            <Divider />
                        </Col>
                        <Col span={24} style={{ paddingTop: 15 }}>
                            <TabContext value={activeSettingsTab}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={(e, tab) => {
                                        this.setState({ activeSettingsTab: tab })


                                    }} aria-label="lab API tabs example">
                                        <Tab style={{ textTransform: 'none' }} label="Settings" value="settings" />
                                        <Tab style={{ textTransform: 'none' }} label="Staff" value="staff" />
                                        <Tab style={{ textTransform: 'none' }} label="Sites" value="sites" />


                                    </TabList>
                                </Box>
                                <TabPanel value="settings">
                                    <Row gutter={[24, 24]}>
                                        <Col span={24}>
                                            <Typography variant='body1'>Color By Staff</Typography>
                                            <Button variant='contained' color='primary' style={{ textTransform: 'none', padding: 8 }}
                                                onClick={() => {
                                                    const newList = list.map((l) => {
                                                        const color = staffColors[l.assignee.id] || 'rgba(0,0,0,0.4)';
                                                        return ({ ...l, color })
                                                    })
                                                    this.setState({ list: newList })
                                                    this.handleCheck()
                                                    this.props.openSnack('success', 'Colors Updated')
                                                }

                                                }>Color By Staff</Button>
                                        </Col>
                                        <Col span={24}>
                                            <Typography variant='body1'>Color By Site</Typography>
                                            <Button variant='contained' color='primary' style={{ textTransform: 'none', padding: 8 }}
                                                onClick={() => {
                                                    const newList = list.map((l) => {
                                                        const color = siteColors[l.site.key] || 'rgba(0,0,0,0.4)';
                                                        return ({ ...l, color })
                                                    })
                                                    this.setState({ list: newList })
                                                    this.handleCheck()
                                                    this.props.openSnack('success', 'Colors Updated')
                                                }

                                                }>Color By Site</Button>
                                        </Col>

                                    </Row>
                                </TabPanel>
                                <TabPanel value="staff">
                                    <Row gutter={[12, 12]}>
                                        <Col span={24}>
                                            <Row gutter={[12, 12]}>
                                                {companyStaff.sort((a, b) => a.label.localeCompare(b.label)).map((r) => {

                                                    if (staffColors[r.id] === undefined) {
                                                        staffColors[r.id] = randomColor();
                                                        this.setState({ staffColors })
                                                        this.handleCheck()
                                                    }

                                                    return (
                                                        <Col span={24}>
                                                            <Row gutter={[12, 12]}>
                                                                <Col span={12}>
                                                                    {r.label}
                                                                </Col>
                                                                <Col span={6}>
                                                                    <TaskColor color={staffColors[r.id]} updateData={(color, hex) => {
                                                                        staffColors[r.id] = hex;
                                                                        this.setState({ staffColors })
                                                                        this.handleCheck()
                                                                    }} />
                                                                </Col>
                                                                <Col span={24}>
                                                                    <Divider />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    )
                                                })}
                                            </Row>
                                        </Col>

                                    </Row>
                                </TabPanel>
                                <TabPanel value="sites">
                                    <Row gutter={[12, 12]}>
                                        <Col span={24}>
                                            <Row gutter={[12, 12]}>
                                                {accounts.sort((a, b) => a.label.localeCompare(b.label)).map((r) => {


                                                    if (siteColors[r.key] === undefined) {
                                                        siteColors[r.key] = randomColor();
                                                        this.setState({ siteColors })
                                                        this.handleCheck()
                                                    }

                                                    return (
                                                        <Col span={24}>
                                                            <Row gutter={[12, 12]}>
                                                                <Col span={12}>
                                                                    {r.label}
                                                                </Col>
                                                                <Col span={6}>
                                                                    <TaskColor color={siteColors[r.key]} updateData={(color, hex) => {
                                                                        siteColors[r.key] = hex;
                                                                        console.log(siteColors)
                                                                        this.setState({ siteColors })
                                                                        this.handleCheck()
                                                                    }} />
                                                                </Col>
                                                                <Col span={24}>
                                                                    <Divider />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    )
                                                })}
                                            </Row>
                                        </Col>

                                    </Row>
                                </TabPanel>

                            </TabContext>
                        </Col>


                    </Row>


                </Drawer>
            </Col >






        );
    }
} 