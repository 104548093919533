import { collection, doc, getDoc, getDocs, getFirestore, onSnapshot, orderBy, query, setDoc, updateDoc, where } from "firebase/firestore";
import moment from 'moment-timezone';
import axios, * as others from 'axios';
import queryString from 'query-string';
import uuid from "react-uuid";



export const getAllLocations = async (type, keys, openSnack) => {

    /* keys is an array of api keys only get the unique ones based on client_id */
    const uniqueClientIDs = new Set();
    const filteredKeys = keys.filter(key => {
        if (!uniqueClientIDs.has(key.client_id)) {
            uniqueClientIDs.add(key.client_id);
            return true;
        }
        return false;
    });

    console.log(filteredKeys)



    const proxyurl = "https://mycorslake.herokuapp.com/";
    const hydrovuTokenURL = 'https://www.hydrovu.com/public-api/oauth/token';
    const hydrovuLocationsURL = 'https://www.hydrovu.com/public-api/v1/locations/list';

    const TWO_MINUTES = 10 * 60 * 1000;

    // Function to get current time
    const getCurrentTime = () => new Date().getTime();

    const getToken = async (client_id, client_secret) => {
        const savedTokenData = JSON.parse(localStorage.getItem(`tokenData-${client_id}`));
        const now = getCurrentTime();

        // Use saved token if it exists and it's still valid
        if (savedTokenData && now < savedTokenData.time + savedTokenData.expires_in) {
            return savedTokenData.token;
        }

        const data = {
            grant_type: 'client_credentials',
            client_id,
            client_secret,
        };

        const response = await axios.post(proxyurl + hydrovuTokenURL, queryString.stringify(data));

        // Save token and its metadata to local storage  

        const tokenData = {
            token: response.data.access_token,
            expires_in: response.data.expires_in, // Convert to milliseconds
            time: now
        };
        localStorage.setItem(`tokenData-${client_id}`, JSON.stringify(tokenData));

        return response.data.access_token;
    };
    const fetchLocations = async (token, api_id) => {
        const AuthStr = 'Bearer ' + token;
        let locations = [];
        let nextPage;

        while (true) {
            const response = await axios.get(proxyurl + hydrovuLocationsURL, {
                headers: { Authorization: AuthStr, "X-ISI-Start-Page": `${nextPage || 1}` },
            });

            if (response.data.length === 0) {
                break;
            }

            locations = [...locations, ...response.data.map(f => ({ ...f, api_id }))];

            console.log(locations);
            nextPage = response.headers['x-isi-next-page'];
            if (!nextPage) {
                break;
            }
        }
        return locations;
    };

    try {
        // If type is not "bypass", check local storage 
        if (type !== "bypass") {
            const lastCalled = localStorage.getItem('lastCalledTime');
            const now = getCurrentTime();
            const savedLocations = JSON.parse(localStorage.getItem('locations'));

            if (lastCalled && (now - lastCalled) < TWO_MINUTES && savedLocations) {
                return savedLocations;
            }
        }

        const allLocations = [];

        for (const key of filteredKeys) {
            console.log(key)

            try {
                const token = await getToken(key.client_id, key.client_secret);
                const locations = await fetchLocations(token, key.client_id);

                allLocations.push(...locations); // You might want to tag these locations with the client_id to identify them later
            } catch (error) {
                openSnack('warning', `No probes found for API Key: ${key?.keyName}`,);
                allLocations.push(...[]);
            }
        }


        allLocations.forEach((f) => {
            f.label = f.name;
        });

        // Store locations and current time to local storage
        localStorage.setItem('locations', JSON.stringify(allLocations));
        localStorage.setItem('lastCalledTime', getCurrentTime().toString());

        console.log(allLocations)
        return allLocations;

    } catch (error) {
        console.log("Failed to fetch all locations:", error);
        return 'error';
    }
};



export const getStreamData = async (userInfo, keyList) => {

    const uniqueClientIDs = new Set();
    const filteredKeys = keyList.filter(key => {
        if (!uniqueClientIDs.has(key.client_id)) {
            uniqueClientIDs.add(key.client_id);
            return true;
        }
        return false;
    });


    const proxyurl = "https://mycorslake.herokuapp.com/";
    const hydrovuTokenURL = 'https://www.hydrovu.com/public-api/oauth/token';
    const db = getFirestore();

    // Generate promises for each key to fetch the authorization header
    const keyPromises = filteredKeys.map(key => {
        const data = {
            grant_type: 'client_credentials',
            client_id: key.client_id,
            client_secret: key.client_secret,
        };
        return axios.post(proxyurl + hydrovuTokenURL, queryString.stringify(data))
            .then(response => {
                const { access_token } = response.data;
                const authorizationHeader = 'Bearer '.concat(access_token);

                return { key, authorizationHeader };
            });
    });

    // Use Promise.all to wait for all promises to resolve
    const keys = await Promise.all(keyPromises);


    var stations = [];
    const companies = [userInfo.currentCompany];


    for (let company of companies) {

        const queryRef = collection(db, "clients", company, 'stations');
        const querySnapshot = await getDocs(queryRef);

        querySnapshot.forEach(qs => {
            //add latitude and longitude to each child
            if (companies.includes(qs.data().company)) { // Check if company is in the array
                const children = qs.data().children.map(child => {
                    const apiKeyObject = keys.find(k => k.key.client_secret === '11d28cb08c2444a2966931244ee43e08') || keys[0];
                    return {
                        ...child, latitude: qs.data().lat, longitude: qs.data().lng,
                        api_key: apiKeyObject.key,
                        authorizationHeader: apiKeyObject.authorizationHeader
                    }; // Assuming you want to add the authorization header
                })
                stations.push([...children]);
            }
        });
    }



    const flattened = [].concat.apply([], stations);
    const sensors = flattened.filter(item => {
        if (Array.isArray(item.type)) {
            return item.type.some(type => ['sensor', 'both'].includes(type));
        } else {
            return ['sensor', 'both'].includes(item.type);
        }
    });
    const stationsList = [];
    sensors.forEach(sensor => {
        if (sensor.sensor !== undefined && sensor.activelyReporting === true) {

            stationsList.push({ node: sensor, authorizationHeader: sensor.authorizationHeader });
        }
    });



    stationsList.forEach(async ({ node, authorizationHeader }) => {
        const id = node.sensor.id;
        const prepareData = [];
        const hydrovuLocationURL = `https://www.hydrovu.com/public-api/v1/locations/${id}/data`;

        const hoursArray = [12, 8, 4]; // array of hours to subtract


        for (let i = 0; i < hoursArray.length; i++) {
            const response = await axios.get(proxyurl + hydrovuLocationURL, {
                params: {
                    startTime: moment().subtract(hoursArray[i], 'hours').format('X')
                },
                headers: { Authorization: authorizationHeader, "X-ISI-Start-Page": `` },
            }).catch((error) => {


                //end function early if error
                return;
            });




            prepareData.push({ data: response.data, node, hours: hoursArray[i] });


        }


        prepareData.forEach(async ({ data, node, hours }) => {
            const locationid = data.locationId;
            const parameters = data.parameters;
            const datalist = []

            const probe = async (node) => {
                try {
                    if (node.sensor !== null) {

                        const docRef = doc(db, 'clients', node.company, 'probes', node.sensor.key);
                        const probeSnap = await getDoc(docRef);
                        if (probeSnap.exists) {

                            return probeSnap.data();
                        }
                        return null;
                    }
                    else {
                        return null;
                    }
                } catch (error) {

                    return null;

                }
            }

            const timezone = probe === null ? "America/Los_Angeles" : probe.timezone || "America/Los_Angeles";



            parameters.forEach(async (parameter) => {
                parameter.readings.forEach((reading) => {
                    var time = moment(reading.timestamp, 'X').tz(timezone).format("YYYY-MM-DD HH:mm");

                    const item = {
                        time: `'${time}'`,
                        timestamp: reading.timestamp,
                        locationid: `'${locationid}'`,
                        unitid: `'${parameter.unitId}'`,
                        parameterid: `'${parameter.parameterId}'`,
                        value: reading.value,
                        account: `'${node.account}'`,
                        company: `'${node.company}'`,
                        nodeid: `'${node.id}'`,
                        key: `'${uuid()}'`,
                        longitude: node.longitude || null,
                        latitude: node.latitude || null,
                        offsetvalue: parameter.parameterId === '3' ? node.offsetvalue !== undefined ? Number(node.offsetvalue) : 0 : 0,
                    }
                    datalist.push(item)
                })
            })
            const hourlyVals = datalist.map((h) => `(${Object.values(h)})`);
            const hourlyString = hourlyVals.join();

            if (hourlyString.length === 0) {

                return;
            }


            const customBucket = `INSERT INTO node_data_new2 VALUES ${hourlyString} ON CONFLICT DO NOTHING; `;

            const response = await axios.post(proxyurl + `https://us-central1-aquasource3.cloudfunctions.net/widgetsFire4/sqlRead`, { raw: customBucket })






        })


    });








};


export const getStreamData2 = async (userInfo, keyList) => {
    const uniqueClientIDs = new Set();
    const filteredKeys = keyList.filter(key => {
        if (!uniqueClientIDs.has(key.client_id)) {
            uniqueClientIDs.add(key.client_id);
            return true;
        }
        return false;
    });



    const proxyurl = "https://mycorslake.herokuapp.com/";
    const hydrovuTokenURL = 'https://www.hydrovu.com/public-api/oauth/token';
    const db = getFirestore();

    // Generate promises for each key to fetch the authorization header
    const keyPromises = filteredKeys.map(key => {
        const data = {
            grant_type: 'client_credentials',
            client_id: key.client_id,
            client_secret: key.client_secret,
        };
        return axios.post(proxyurl + hydrovuTokenURL, queryString.stringify(data))
            .then(response => {
                const { access_token } = response.data;
                const authorizationHeader = 'Bearer '.concat(access_token);
                return { key, authorizationHeader };
            });
    });

    // Use Promise.all to wait for all promises to resolve
    const keys = await Promise.all(keyPromises);

    var stations = [];
    const companies = [userInfo.currentCompany];

    for (let company of companies) {
        const queryRef = collection(db, "clients", company, 'stations');
        const querySnapshot = await getDocs(queryRef);

        querySnapshot.forEach(qs => {
            if (companies.includes(qs.data().company)) { // Check if company is in the array
                const children = qs.data().children.map(child => {
                    const apiKeyObject = keys.find(k => k.key.client_id === child.probe_api) || keys[0]; // Corrected to match the structure of keys
                    return { ...child, latitude: qs.data().lat, longitude: qs.data().lng, api_key: apiKeyObject.authorizationHeader }; // Assuming you want to add the authorization header
                });
                stations.push(...children); // Flattened to add children directly
            }
        });
    }



    const flattened = [].concat.apply([], stations);
    const sensors = flattened.filter(item => {
        if (Array.isArray(item.type)) {
            return item.type.some(type => ['sensor', 'both'].includes(type));
        } else {
            return ['sensor', 'both'].includes(item.type);
        }
    });
    const stationsList = [];
    sensors.forEach(sensor => {
        if (sensor.sensor !== undefined && sensor.activelyReporting === true) {

            stationsList.push({ node: sensor, authorizationHeader: sensor.api_key.authorizationHeader });
        }
    });



    stationsList.forEach(async ({ node, authorizationHeader }) => {
        const id = node.sensor.id;
        const prepareData = [];
        const hydrovuLocationURL = `https://www.hydrovu.com/public-api/v1/locations/${id}/data`;

        const hoursArray = [12, 8, 4]; // array of hours to subtract


        for (let i = 0; i < hoursArray.length; i++) {
            const response = await axios.get(proxyurl + hydrovuLocationURL, {
                params: {
                    startTime: moment().subtract(hoursArray[i], 'hours').format('X')
                },
                headers: { Authorization: authorizationHeader, "X-ISI-Start-Page": `` },
            }).catch((error) => {


                //end function early if error
                return;
            });




            prepareData.push({ data: response.data, node, hours: hoursArray[i] });


        }


        prepareData.forEach(async ({ data, node, hours }) => {
            const locationid = data.locationId;
            const parameters = data.parameters;
            const datalist = []

            const probe = async (node) => {
                try {
                    if (node.sensor !== null) {

                        const docRef = doc(db, 'clients', node.company, 'probes', node.sensor.key);
                        const probeSnap = await getDoc(docRef);
                        if (probeSnap.exists) {
                            return probeSnap.data();
                        }
                        return null;
                    }
                    else {
                        return null;
                    }
                } catch (error) {

                    return null;

                }
            }

            const timezone = probe === null ? "America/Los_Angeles" : probe.timezone || "America/Los_Angeles";



            parameters.forEach(async (parameter) => {
                parameter.readings.forEach((reading) => {
                    var time = moment(reading.timestamp, 'X').tz(timezone).format("YYYY-MM-DD HH:mm");

                    const item = {
                        time: `'${time}'`,
                        timestamp: reading.timestamp,
                        locationid: `'${locationid}'`,
                        unitid: `'${parameter.unitId}'`,
                        parameterid: `'${parameter.parameterId}'`,
                        value: reading.value,
                        account: `'${node.account}'`,
                        company: `'${node.company}'`,
                        nodeid: `'${node.id}'`,
                        key: `'${uuid()}'`,
                        longitude: node.longitude || null,
                        latitude: node.latitude || null,
                        offsetvalue: parameter.parameterId === '3' ? node.offsetvalue !== undefined ? Number(node.offsetvalue) : 0 : 0,
                    }
                    datalist.push(item)
                })
            })
            const hourlyVals = datalist.map((h) => `(${Object.values(h)})`);
            const hourlyString = hourlyVals.join();

            if (hourlyString.length === 0) {

                return;
            }


            const customBucket = `INSERT INTO node_data_new2 VALUES ${hourlyString} ON CONFLICT DO NOTHING; `;

            const response = await axios.post(proxyurl + `https://us-central1-aquasource3.cloudfunctions.net/widgetsFire4/sqlRead`, { raw: customBucket })






        })


    });








};

