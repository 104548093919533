import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Autocomplete, Box, Button, CircularProgress, Divider, Fab, IconButton, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material';
import Tab from '@mui/material/Tab';
import { Col, Popconfirm, Row, Steps } from 'antd';
import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';
import moment from 'moment';
import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import uuid from 'react-uuid';
import DefaultDatePicker from '../../blocks/defaultDatePicker';
import "../../extra/hoverStyle.css";
import CSVReader from './csvThing';
import axios, * as others from 'axios';
import queryString from 'query-string';

const { Step } = Steps;


export default class UploadData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            data: [],
            filteredData: [],
            filters: [],
            searchText: '',
            loading: true,
            type: this.props.type,
            currentItem: {},
            openModal: false,
            probesList: [],
            nextPage: '',
            token: '',
            matchProbe: [],
            activeTab: 'file',
            step: 'upload',
            keys: [],
            data: [],
            dateFormat: 'MM/DD/YYYY',
            parameterMap: '',
            locationMap: '',
            valueMap: '',
            dateMap: '',
            templates: [],
            previewData: [],
            parameters: [],
            sites: [],
            uploading: false,
            reviewing: false,
            addData: []


        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0)
        this.getTemplates();
        this.getParameters();
        this.getSites();
        const { probesList } = this.state;



    }

    getTemplates = async () => {
        const { userInfo } = this.state;
        const db = getFirestore();

        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'templates',));
        const templates = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            templates.push({ ...doc.data() })


        });
        this.setState({ templates, loading: false })


    }
    getParameters = async () => {
        const { userInfo, type } = this.state;
        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'parameters'));
        const parameters = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            parameters.push({ ...doc.data(), label: doc.data().name })


        });
        console.log(parameters);
        parameters.sort((a, b) => a.name.localeCompare(b.name))
        this.setState({ parameters, })


    }
    getSites = async () => {
        const { userInfo, type } = this.state;
        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'sampleSites'), where('account', '==', userInfo.currentAccount),);
        const sites = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            sites.push({ ...doc.data(), label: doc.data().name })


        });
        sites.sort((a, b) => a.name.localeCompare(b.name))
        console.log(sites);
        this.setState({ sites, loading: false })


    }



    getKeys = (data) => { console.log(data) }

    orderData = () => {
        this.props.openSnack('success', 'Data Uploading...')
        this.setState({ reviewing: true })
        setTimeout(() => {
            this.orderData1()
        }, 100);
    }

    orderData1 = () => {
        this.setState({ reviewing: true })
        const { data, keys, dateFormat, dateMap, valueMap, parameterMap, locationMap, parameters, sites, userInfo } = this.state;

        const array = [];
        const labels = [locationMap.label, parameterMap.label, valueMap.label, dateMap.label];
        const newLabels = ['locationid', 'parameterid', 'value', 'time'];
        const updatedKeys = keys.map((k, i) => {
            if (labels.includes(k.label)) {
                const index = labels.indexOf(k.label);
                const label = newLabels[index];
                return ({ ...k, label })

            }
            else {
                return (k)
            }
        })
        data.map((d) => {
            const keysList = updatedKeys.map((d) => d.label);
            const values = d;
            const merged = keysList.reduce((obj, key, index) => ({ ...obj, [key]: values[index] }), {});
            array.push(merged)
        })
        console.log(array);

        const updateTime = array.map((a) => {
            const parameter = parameters.filter((p) => p.name === a.parameterid)[0];
            const site = sites.filter((p) => p.name === a.locationid)[0];

            const parameterid = parameter !== undefined ? parameter.key : undefined;
            const parameterUnits = parameter !== undefined ? parameter.unitLabel : undefined;
            const locationid = site !== undefined ? site.key : undefined;
            const parameterLabel = parameter !== undefined ? parameter.label : undefined;
            const errorLabelP = a.parameterid;
            const errorLabelS = a.locationid;
            const parameterBorder = parameterLabel ? null : '1px solid red';
            const locationLabel = site !== undefined ? site.label : undefined;
            const locationBorder = locationLabel ? null : '1px solid red';

            var time = moment(a.time, dateFormat).tz("America/Los_Angeles").format("YYYY-MM-DD HH:mm");




            return ({
                ...a,
                time,
                timestamp: Number(moment(a.time, dateFormat).format('X')),
                locationid,
                locationLabel,
                unitid: 'units',
                parameterid: parameterid,
                parameterLabel: parameterLabel,
                nd: a.value === "ND" ? "true" : 'false',
                meta: JSON.stringify(a),
                account: userInfo.currentAccount,
                company: userInfo.currentCompany,
                value: Number(a.value === "ND" ? "0" : a.value),
                key: uuid(),
                parameterUnits,
                locationBorder,
                parameterBorder,
                errorLabelP,
                errorLabelS,
                edit: false
            })
        })

        console.log(updateTime)

        this.setState({ previewData: updateTime, step: 'final', reviewing: false })
    }


    orderDataNew = (data) => {
        this.props.openSnack('success', 'Data Uploading...')
        this.setState({ reviewing: true })
        setTimeout(() => {
            this.orderDataNew1(data)
        }, 100);
    }

    orderDataNew1 = (data) => {
        const { keys, dateMap, locationMap, dateFormat, sites, parameters, userInfo } = this.state;
        console.log(sites);
        console.log(parameters);

        const array = [];
        data.map((d, i) => {
            const time = moment(d[dateMap.key], dateFormat).tz("America/Los_Angeles").format("YYYY-MM-DD HH:mm");
            const timestamp = Number(moment(d[dateMap.key], dateFormat).format('X'));
            const location = d[locationMap.key];



            d.map((dd, i) => {
                if (i !== dateMap.key && i !== locationMap.key && dd !== "" && dd !== null && dd !== undefined) {
                    const param = keys[i].label;
                    const value = dd.includes("ND") ? 0 : dd.includes("<") ? 0 : ["Overrange", 'overrange', 'underrange', 'Underrange'].includes(dd) ? 0 : Number(dd);
                    const nd = dd === "ND" ? "ND" : dd.includes("<") ? "ND" : dd.includes("nd") ? "ND" : '0';
                    const meta = JSON.stringify(d);
                    const key = uuid();
                    const edit = false;
                    const locationid = sites.filter((s) => s.name === location)[0].key;
                    const locationLabel = sites.filter((s) => s.name === location)[0].label;
                    const locationBorder = locationLabel ? null : '1px solid red';
                    const parameter = parameters.filter((p) => p.name === param)[0];
                    const parameterid = parameter !== undefined ? parameter.key : undefined;
                    const parameterUnits = parameter !== undefined ? parameter.unitLabel : undefined;
                    const parameterLabel = parameter !== undefined ? parameter.label : undefined;
                    const parameterBorder = parameterLabel ? null : '1px solid red';
                    const errorLabelP = param;
                    const errorLabelS = location;
                    const account = userInfo.currentAccount;
                    const company = userInfo.currentCompany;
                    const unitid = 'units';

                    array.push({
                        time,
                        timestamp,
                        locationid,
                        locationLabel,
                        unitid,
                        parameterid,
                        parameterLabel,
                        nd,
                        meta,
                        account,
                        company,
                        value,
                        key,
                        parameterUnits,
                        locationBorder,
                        parameterBorder,
                        errorLabelP,
                        errorLabelS,
                        edit
                    })




                }
            })



            if (data.length === i + 1) {
                console.log(array)
                this.setState({ previewData: array, step: 'final', reviewing: false })
            }

        })





    }

    updateData = (index, boo, val) => {
        const { previewData } = this.state;
        previewData[index][boo] = val;
        this.setState({ previewData })
    }
    updateAddData = (index, boo, val) => {
        const { addData } = this.state;
        addData[index][boo] = val;
        this.setState({ addData })
    }

    uploadData = () => {
        const { previewData } = this.state;


    }
    checkData = () => {
        this.setState({ uploading: true })
        setTimeout(() => {
            this.checkData1()
        }, 100);
    }
    checkData1 = () => {
        const db = getFirestore();
        const { previewData, userInfo } = this.state;

        const ps = previewData.map((d) => d.parameterid);
        const ss = previewData.map((d) => d.locationid);

        const nanCheck = previewData.map((d) => d.value).filter((d) => isNaN(d));
        if (nanCheck.length > 0) {
            this.props.openSnack('error', 'Check all values. Some are not numbers.')
            this.setState({ uploading: false })
        }
        else{
            if (ps.includes(undefined)) {
                this.props.openSnack('error', 'Select all parameters.')
                this.setState({ uploading: false })
            }
            if (ss.includes(undefined)) {
                this.props.openSnack('error', 'Select all sites.')
                this.setState({ uploading: false })
            }
    
            if (![...ps, ...ss].includes(undefined)) {
    
                const final = previewData.map((d) => { return ({ ...d, value: d.value === "ND" ? "ND" : Number(d.value), nd: d.value === "ND" ? true : false }) })
    
    
                const data = [];
    
                final.map(async (f, i) => {
                    console.log(f)
    
                    const item = {
                        time: `'${f.time}'`,
                        timestamp: f.timestamp,
                        locationid: `'${f.locationid}'`,
                        locationLabel: `'${f.locationLabel}'`,
                        unitid: `'units'`,
                        parameterid: `'${f.parameterid}'`,
                        parameterLabel: `'${f.parameterLabel}'`,
                        nd: `'${f.value === "ND" ? "true" : 'false'}'`,
                        meta: `'${JSON.stringify(f)}'`,
                        account: `'${userInfo.currentAccount}'`,
                        company: `'${userInfo.currentCompany}'`,
                        value: Number(f.value === "ND" ? "0" : f.value),
                        logkey: `'none'`,
                        key: `'${f.key}'`,
    
    
                    }
    
                    data.push(item)
    
    
                })
    
                console.log(data);
    
                const hourlyVals = data.map((h) => `(${Object.values(h)})`);
                const hourlyString = hourlyVals.join();
    
                console.log(hourlyString.includes("undefined"))
    
                const proxyUrl = "https://mycorslake.herokuapp.com/";
                axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                    raw: `INSERT INTO lab_data VALUES ${hourlyString} ON CONFLICT DO NOTHING; `
                }).then(async (response) => {
                    // handle success
                    console.log(response)
    
                    this.setState({ uploading: false, step: 'upload' })
                    this.props.openSnack('success', 'Data Uploaded!')
                })
    
    
    
            }
        }

        


    }
    checkData2 = () => {
        this.setState({ uploading: true })
        setTimeout(() => {
            this.checkData3()
        }, 100);
    }
    checkData3 = () => {
        const db = getFirestore();
        const { addData, userInfo } = this.state;

        const ps = addData.map((d) => d.parameterid === '' ? undefined : d.parameterid);
        const ss = addData.map((d) => d.locationid === '' ? undefined : d.locationid);

        if (ps.includes(undefined)) {
            this.props.openSnack('error', 'Select all parameters.')
            this.setState({ uploading: false })
        }
        if (ss.includes(undefined)) {
            this.props.openSnack('error', 'Select all sites.')
            this.setState({ uploading: false })
        }

        if (![...ps, ...ss].includes(undefined)) {

            const final = addData.map((d) => { return ({ ...d, value: d.value === "ND" ? "ND" : Number(d.value), nd: d.value === "ND" ? true : false }) })


            const data = [];

            final.map(async (f, i) => {
                console.log(f)

                const item = {
                    time: `'${f.time}'`,
                    timestamp: f.timestamp,
                    locationid: `'${f.locationid}'`,
                    locationLabel: `'${f.locationLabel}'`,
                    unitid: `'units'`,
                    parameterid: `'${f.parameterid}'`,
                    parameterLabel: `'${f.parameterLabel}'`,
                    nd: `'${f.value === "ND" ? "true" : 'false'}'`,
                    meta: `'${JSON.stringify(f)}'`,
                    account: `'${userInfo.currentAccount}'`,
                    company: `'${userInfo.currentCompany}'`,
                    value: Number(f.value === "ND" ? "0" : f.value),
                    logkey: `'none'`


                }

                data.push(item)


            })

            console.log(data);

            const hourlyVals = data.map((h) => `(${Object.values(h)})`);
            const hourlyString = hourlyVals.join();

            console.log(hourlyString)

            const proxyUrl = "https://mycorslake.herokuapp.com/";
            axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                raw: `INSERT INTO lab_data VALUES ${hourlyString} ON CONFLICT DO NOTHING; `
            }).then(async (response) => {
                // handle success
                console.log(response)

                this.setState({ uploading: false, step: 'upload', addData: [] })
                this.props.openSnack('success', 'Data Uploaded!')
            })



        }


    }


    render() {

        const { userInfo, account, activeTab, step, keys, data, dateFormat, dateMap, valueMap, parameterMap, locationMap, screenWidth, templates, uploading,
            previewData, parameters, sites, reviewing, addData } = this.state;
        const db = getFirestore();



        const checks = [
            Object.values(parameterMap).length > 0 ? true : false,
            Object.values(dateMap).length > 0 ? true : false,
            Object.values(locationMap).length > 0 ? true : false,
            Object.values(valueMap).length > 0 ? true : false,
        ]
        const checksNew = [
            Object.values(dateMap).length > 0 ? true : false,
            Object.values(locationMap).length > 0 ? true : false,
        ]

        const DeleteItem = ({ row }) => {
            return (
                <Popconfirm
                    title="Are you sure to delete this row?"
                    onConfirm={async () => {
                        const newData = previewData.filter((f) => f.key !== row.key);
                        this.setState({ previewData: [...newData] })


                    }}
                    okText="Yes"
                    cancelText="No"
                >
                    <IconButton style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="small"
                    >
                        <DeleteForeverIcon />
                    </IconButton>
                </Popconfirm>


            )
        }
        const DeleteAddItem = ({ row }) => {
            return (
                <Popconfirm
                    title="Are you sure to delete this row?"
                    onConfirm={async () => {
                        console.log("DELETE")
                        console.log(row.key)
                        const filterData = addData.filter((f) => f.key !== row.key);
                        this.setState({ addData: [...filterData] })


                    }}
                    okText="Yes"
                    cancelText="No"
                >
                    <IconButton style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="small"
                    >
                        <DeleteForeverIcon />
                    </IconButton>
                </Popconfirm>


            )
        }







        const CreateItemButton = () => {


            return (
                <Fab style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="small"
                    onClick={async () => {

                        var time = moment().tz("America/Los_Angeles").format("YYYY-MM-DD HH:mm");

                        addData.push({
                            time,
                            timestamp: Number(moment().format('X')),
                            locationid: '',
                            locationLabel: '',
                            unitid: 'units',
                            parameterid: '',
                            parameterLabel: '',
                            nd: 'false',

                            account: userInfo.currentAccount,
                            company: userInfo.currentCompany,
                            value: '',
                            key: uuid(),
                            parameterUnits: '',
                            locationBorder: '',
                            parameterBorder: '',
                            errorLabelP: '',
                            errorLabelS: '',
                            edit: false
                        })

                        this.setState({ addData })
                    }}>
                    <AddIcon />
                </Fab>
            )
        }



        return (
            <Row style={{}} align="middle">

                <Col span={23}>
                    <Row align="middle">
                        <Col xs={16} sm={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>
                            <Typography variant="h4">Upload Data</Typography>
                        </Col>
                        <Col xs={6} sm={4} style={{ paddingTop: 30, paddingBottom: 15, textAlign: 'right', position: 'relative', zIndex: 0 }}>

                        </Col>
                        <Col span={24}>
                            <Divider />
                        </Col>

                    </Row>
                </Col>
                <Col span={23} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 30 }}>
                    <TabContext value={activeTab}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={(e, tab) => this.setState({ activeTab: tab })} aria-label="lab API tabs example">
                                <Tab style={{ textTransform: 'none' }} label="Upload File (1 row has one parameter)" value="file" />
                                <Tab style={{ textTransform: 'none' }} label="Upload File (1 row has multiple parameters)" value="fileNew" />
                                <Tab style={{ textTransform: 'none' }} label="Add Single Data" value="data" />


                            </TabList>
                        </Box>
                        <TabPanel value="file">
                            <Row gutter={[12, 12]}>
                                <Col span={24}>
                                    <Steps current={step === 'upload' ? 0 : step === 'review' ? 1 : step === 'final' ? 2 : 0}>
                                        <Step title="Upload" description="Upload a file." />
                                        <Step title="Review" description="Review the data." />
                                        <Step title="Submit" description="Add your data to your database." />
                                    </Steps>
                                </Col>
                                <Col span={24}>
                                    {step === 'upload' && (<CSVReader getKeys={(results) => {
                                        const data = results.data;
                                        const firstElement = data.shift();
                                        console.log(data);
                                        console.log(firstElement);

                                        const keys = firstElement.map((k, i) => { return ({ label: k, key: i }) })
                                        console.log(keys)


                                        this.setState({ step: 'review', keys, data: data })
                                    }}
                                        removeFile={(s) => this.setState({ step: s, keys: [] })} />)}
                                </Col>
                                <Col span={24} style={{ paddingTop: 20 }}>
                                    {step === 'review' && (

                                        <Row gutter={[12, 12]} align="middle">
                                            <Col xs={{ offset: 0, span: 24 }} sm={{ offset: 6, span: 18 }} style={{ paddingBottom: 12, }}>
                                                <Typography variant='h6'>Map Columns</Typography>
                                            </Col>


                                            <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                                                <Typography variant="body1">Parameters<span style={{ color: 'red' }}>*</span></Typography>
                                            </Col>
                                            <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                                <Autocomplete
                                                    size="small"
                                                    disableClearable={true}
                                                    id="combo-box-demo"
                                                    value={parameterMap || ''}

                                                    options={keys}
                                                    filterSelectedOptions
                                                    renderInput={(params) => <TextField {...params} label="Parameters" size="small" />}
                                                    onChange={(a, b) => {
                                                        console.log(b);
                                                        this.setState({ parameterMap: b })
                                                    }}

                                                />
                                            </Col>
                                            <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                                                <Typography variant="body1">Values<span style={{ color: 'red' }}>*</span></Typography>
                                            </Col>
                                            <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                                <Autocomplete
                                                    size="small"
                                                    disableClearable={true}
                                                    id="combo-box-demo"
                                                    value={valueMap || ''}

                                                    options={keys}
                                                    filterSelectedOptions
                                                    renderInput={(params) => <TextField {...params} label="Values" size="small" />}
                                                    onChange={(a, b) => {
                                                        console.log(b);
                                                        this.setState({ valueMap: b })
                                                    }}

                                                />
                                            </Col>
                                            <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                                                <Typography variant="body1">Dates<span style={{ color: 'red' }}>*</span></Typography>
                                            </Col>
                                            <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                                <Autocomplete
                                                    size="small"
                                                    disableClearable={true}
                                                    id="combo-box-demo"
                                                    value={dateMap || ''}

                                                    options={keys}
                                                    filterSelectedOptions
                                                    renderInput={(params) => <TextField {...params} label="Dates" size="small" />}
                                                    onChange={(a, b) => {
                                                        console.log(b);
                                                        this.setState({ dateMap: b })
                                                    }}

                                                />
                                            </Col>
                                            <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                                                <Typography variant="body1">Date Format<span style={{ color: 'red' }}>*</span></Typography>
                                            </Col>
                                            <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                                <Select size="small" fullWidth
                                                    notched={false}
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={dateFormat || ''}
                                                    label="Layer"
                                                    onChange={(e) => {
                                                        this.setState({ dateFormat: e.target.value })
                                                    }}

                                                >
                                                    <MenuItem value={'MM/DD/YYYY'}>MM/DD/YYYY</MenuItem>
                                                    <MenuItem value={'YYYY/MM/DD'}>YYYY/MM/DD</MenuItem>
                                                    <MenuItem value={'YYYY-MM-DD'}>YYYY-MM-DD</MenuItem>
                                                    <MenuItem value={'MM/DD/YYYY h:mm'}>MM/DD/YYYY HH:mm</MenuItem>
                                                    <MenuItem value={'YYYY/DD/MM h:mm'}>YYYY/DD/MM HH:mm</MenuItem>
                                                    <MenuItem value={'YYYY-DD-MM h:mm'}>YYYY/DD/MM HH:mm</MenuItem>
                                                </Select>
                                            </Col>
                                            <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                                                <Typography variant="body1">Sites<span style={{ color: 'red' }}>*</span></Typography>
                                            </Col>
                                            <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                                <Autocomplete
                                                    size="small"
                                                    disableClearable={true}
                                                    id="combo-box-demo"
                                                    value={locationMap || ''}

                                                    options={keys}
                                                    filterSelectedOptions
                                                    renderInput={(params) => <TextField {...params} label="Sites" size="small" />}
                                                    onChange={(a, b) => {
                                                        console.log(b);
                                                        this.setState({ locationMap: b })
                                                    }}

                                                />
                                            </Col>
                                            <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>

                                            </Col>


                                            {!checks.includes(false) && (<Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }} >
                                                {reviewing === true ? <CircularProgress /> :

                                                    <Row gutter={[8, 8]}> <Col span={24}>
                                                        <Button style={{ textTransform: 'none', padding: 12, fontSize: 16 }} variant="contained" color="primary"
                                                            onClick={async () => {
                                                                this.props.openSnack('success', 'Data Reviewing...')
                                                                this.setState({ reviewing: true })
                                                                this.orderData()
                                                            }}>Review Data</Button>



                                                    </Col></Row>

                                                }

                                            </Col>)}



                                        </Row>

                                    )}
                                </Col>
                                <Col span={24} style={{ paddingTop: 20 }}>
                                    {step === 'final' && (

                                        <Row gutter={[12, 12]} align="middle">
                                            <Col span={24} style={{ paddingBottom: 12, }}>
                                                <Typography variant='h6'>Review Data</Typography>
                                            </Col>
                                            <Col span={24} style={{}}>
                                                <Divider />
                                            </Col>
                                            <Col span={24} style={{}}>
                                                <Row gutter={[4, 4]}>
                                                    <Col span={24}>
                                                        <Row>
                                                            <Col span={4}>
                                                                Date
                                                            </Col>
                                                            <Col span={4}>
                                                                Value
                                                            </Col>
                                                            <Col span={5}>
                                                                Parameter
                                                            </Col>
                                                            <Col span={5}>
                                                                Site
                                                            </Col>
                                                            <Col span={2} style={{ textAlign: 'center' }}>
                                                                Error
                                                            </Col>
                                                            <Col span={2} style={{ textAlign: 'center' }}>
                                                                Edit
                                                            </Col>
                                                            <Col span={2} style={{ textAlign: 'center' }}>
                                                                Delete
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Divider />
                                                    </Col>
                                                    {previewData.map((d, i) => {

                                                        const input = d.value === 0 || d.value ? d.value : '';

                                                        return (
                                                            <Col span={24} key={d.key} style={{ backgroundColor: i % 2 === 0 ? 'inherit' : 'rgba(0,0,0,0.03)' }}>
                                                                <Row align="middle" gutter={[4, 4]} >
                                                                    <Col span={4}>
                                                                        {d.edit === true ?
                                                                            <DefaultDatePicker updateItem={(boo, val) => {

                                                                                this.updateData(i, 'time', moment(val, 'x').tz("America/Los_Angeles").format("YYYY-MM-DD HH:mm"))
                                                                                this.updateData(i, 'timestamp', moment(val, 'x').format('X'))
                                                                            }} date={d.time ? moment(d.time).format('x') : moment().format('x')} />
                                                                            :
                                                                            moment(d.time, 'YYYY-MM-DD HH:mm').format('MM/DD/YYYY')}
                                                                    </Col>
                                                                    <Col span={4}>
                                                                        {d.edit === true ?
                                                                            <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                                                id={`${d.key}value`}
                                                                                value={input}
                                                                                type="number"
                                                                                onChange={(e) => {
                                                                                    console.log(e.target.value)
                                                                                    this.updateData(i, 'value', e.target.value === "" ? "" : Number(e.target.value))
                                                                                }} />
                                                                            :
                                                                            d.value}
                                                                    </Col>
                                                                    <Col span={5}>
                                                                        <Row>
                                                                            {d.parameterLabel === undefined && (<Col span={24} style={{ fontSize: 10, color: 'red' }}>
                                                                                error: {d.errorLabelP}
                                                                            </Col>)}
                                                                            <Col span={24}>
                                                                                {d.edit === true ? <Autocomplete
                                                                                    size="small"
                                                                                    id="combo-box-demo"
                                                                                    value={d.parameterLabel}
                                                                                    disableClearable={true}
                                                                                    style={{ border: d.parameterBorder }}
                                                                                    options={parameters}
                                                                                    filterSelectedOptions
                                                                                    renderInput={(params) => <TextField {...params} label="Parameter" size="small" />}
                                                                                    onChange={(a, b) => {
                                                                                        this.updateData(i, 'parameterLabel', b.label)
                                                                                        this.updateData(i, 'parameterid', b.key)
                                                                                        this.updateData(i, 'parameterUnits', b.unitLabel || '')
                                                                                        this.updateData(i, 'parameterBorder', null)


                                                                                    }}

                                                                                /> : d.parameterLabel}
                                                                            </Col>
                                                                        </Row>



                                                                    </Col>
                                                                    <Col span={5}>
                                                                        <Row>
                                                                            {d.locationLabel === undefined && (<Col span={24} style={{ fontSize: 10, color: 'red' }}>
                                                                                error: {d.errorLabelS}
                                                                            </Col>)}
                                                                            <Col span={24}>
                                                                                {d.edit === true ? <Autocomplete
                                                                                    size="small"
                                                                                    id="combo-box-demo"
                                                                                    disableClearable={true}
                                                                                    value={d.locationLabel}
                                                                                    style={{ border: d.locationBorder }}
                                                                                    options={sites}
                                                                                    filterSelectedOptions
                                                                                    renderInput={(params) => <TextField {...params} label="Site" size="small" />}
                                                                                    onChange={(a, b) => {
                                                                                        this.updateData(i, 'locationLabel', b.label)
                                                                                        this.updateData(i, 'locationid', b.key)
                                                                                        this.updateData(i, 'locationBorder', null)

                                                                                    }}

                                                                                /> : d.locationLabel}
                                                                            </Col>
                                                                        </Row>

                                                                    </Col>
                                                                    <Col span={2}>
                                                                        {[d.locationBorder, d.parameterBorder].includes('1px solid red') && (
                                                                            <FontAwesomeIcon icon={faCircleExclamation} color="red" />
                                                                        )}
                                                                    </Col>
                                                                    <Col span={2} style={{ textAlign: 'center' }}>
                                                                        <IconButton style={{ textTransform: 'none' }} color="primary" size="small"
                                                                            onClick={() => {
                                                                                this.updateData(i, 'edit', !d.edit)
                                                                            }}>
                                                                            <EditIcon />
                                                                        </IconButton>
                                                                    </Col>
                                                                    <Col span={2} style={{ textAlign: 'center' }}>
                                                                        <DeleteItem row={d} />
                                                                    </Col>
                                                                    <Col span={24}>
                                                                        <Divider />
                                                                    </Col>

                                                                </Row>
                                                            </Col>
                                                        )
                                                    })}
                                                </Row>
                                            </Col>






                                            <Col xs={24} sm={24} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                                {uploading ? <CircularProgress /> : <Button style={{ textTransform: 'none', padding: 12, fontSize: 16 }} variant="contained" color="primary"
                                                    onClick={async () => {
                                                        this.props.openSnack('success', 'Data Uploading...')
                                                        this.setState({ uploading: true })
                                                        this.checkData()
                                                    }}>Upload Data</Button>}
                                            </Col>


                                        </Row>

                                    )}
                                </Col>

                            </Row>

                        </TabPanel>
                        <TabPanel value="fileNew">
                            <Row gutter={[12, 12]}>
                                <Col span={24}>
                                    <Steps current={step === 'upload' ? 0 : step === 'review' ? 1 : step === 'final' ? 2 : 0}>
                                        <Step title="Upload" description="Upload a file." />
                                        <Step title="Review" description="Review the data." />
                                        <Step title="Submit" description="Add your data to your database." />
                                    </Steps>
                                </Col>
                                <Col span={24}>
                                    {step === 'upload' && (<CSVReader getKeys={(results) => {
                                        const data = results.data;
                                        const firstElement = data.shift();
                                        console.log(data);
                                        console.log(firstElement);

                                        const keys = firstElement.map((k, i) => { return ({ label: k, key: i }) })
                                        console.log(keys)


                                        this.setState({ step: 'review', keys, data: data })


                                    }}
                                        removeFile={(s) => this.setState({ step: s, keys: [] })} />)}
                                </Col>
                                <Col span={24} style={{ paddingTop: 20 }}>
                                    {step === 'review' && (

                                        <Row gutter={[12, 12]} align="middle">
                                            <Col xs={{ offset: 0, span: 24 }} sm={{ offset: 6, span: 18 }} style={{ paddingBottom: 12, }}>
                                                <Typography variant='h6'>Map Columns</Typography>
                                            </Col>



                                            <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                                                <Typography variant="body1">Dates<span style={{ color: 'red' }}>*</span></Typography>
                                            </Col>
                                            <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                                <Autocomplete
                                                    size="small"
                                                    disableClearable={true}
                                                    id="combo-box-demo"
                                                    value={dateMap || ''}

                                                    options={keys}
                                                    filterSelectedOptions
                                                    renderInput={(params) => <TextField {...params} label="Dates" size="small" />}
                                                    onChange={(a, b) => {
                                                        console.log(b);
                                                        this.setState({ dateMap: b })
                                                    }}

                                                />
                                            </Col>
                                            <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                                                <Typography variant="body1">Date Format<span style={{ color: 'red' }}>*</span></Typography>
                                            </Col>
                                            <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                                <Select size="small" fullWidth
                                                    notched={false}
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={dateFormat || ''}
                                                    label="Layer"
                                                    onChange={(e) => {
                                                        this.setState({ dateFormat: e.target.value })
                                                    }}

                                                >
                                                    <MenuItem value={'MM/DD/YYYY'}>MM/DD/YYYY</MenuItem>
                                                    <MenuItem value={'YYYY/MM/DD'}>YYYY/MM/DD</MenuItem>
                                                    <MenuItem value={'MM/DD/YYYY HH:mm'}>MM/DD/YYYY HH:mm</MenuItem>
                                                    <MenuItem value={'YYYY/DD/MM HH:mm'}>YYYY/DD/MM HH:mm</MenuItem>
                                                </Select>
                                            </Col>
                                            <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                                                <Typography variant="body1">Sites<span style={{ color: 'red' }}>*</span></Typography>
                                            </Col>
                                            <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                                <Autocomplete
                                                    size="small"
                                                    disableClearable={true}
                                                    id="combo-box-demo"
                                                    value={locationMap || ''}

                                                    options={keys}
                                                    filterSelectedOptions
                                                    renderInput={(params) => <TextField {...params} label="Sites" size="small" />}
                                                    onChange={(a, b) => {
                                                        console.log(b);
                                                        this.setState({ locationMap: b })
                                                    }}

                                                />
                                            </Col>
                                            <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>

                                            </Col>


                                            {!checksNew.includes(false) && (<Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }} >
                                                {reviewing === true ? <CircularProgress /> :

                                                    <Row gutter={[8, 8]}> <Col span={24}>
                                                        <Button style={{ textTransform: 'none', padding: 12, fontSize: 16 }} variant="contained" color="primary"
                                                            onClick={async () => {
                                                                this.props.openSnack('success', 'Data Reviewing...')
                                                                this.setState({ reviewing: true })
                                                                this.orderDataNew(data)
                                                            }}>Review Data</Button>



                                                    </Col></Row>

                                                }

                                            </Col>)}



                                        </Row>

                                    )}
                                </Col>
                                <Col span={24} style={{ paddingTop: 20 }}>
                                    {step === 'final' && (

                                        <Row gutter={[12, 12]} align="middle">
                                            <Col span={24} style={{ paddingBottom: 12, }}>
                                                <Typography variant='h6'>Review Data</Typography>
                                            </Col>
                                            <Col span={24} style={{}}>
                                                <Divider />
                                            </Col>
                                            <Col span={24} style={{}}>
                                                <Row gutter={[4, 4]}>
                                                    <Col span={24}>
                                                        <Row>
                                                            <Col span={4}>
                                                                Date
                                                            </Col>
                                                            <Col span={4}>
                                                                Value
                                                            </Col>
                                                            <Col span={5}>
                                                                Parameter
                                                            </Col>
                                                            <Col span={5}>
                                                                Site
                                                            </Col>
                                                            <Col span={2} style={{ textAlign: 'center' }}>
                                                                Error
                                                            </Col>
                                                            <Col span={2} style={{ textAlign: 'center' }}>
                                                                Edit
                                                            </Col>
                                                            <Col span={2} style={{ textAlign: 'center' }}>
                                                                Delete
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Divider />
                                                    </Col>
                                                    {previewData.map((d, i) => {

                                                        const input = d.value === 0 || d.value ? d.value : '';

                                                        return (
                                                            <Col span={24} key={d.key} style={{ backgroundColor: i % 2 === 0 ? 'inherit' : 'rgba(0,0,0,0.03)' }}>
                                                                <Row align="middle" gutter={[4, 4]} >
                                                                    <Col span={4}>
                                                                        {d.edit === true ?

                                                                            <DatePicker dateFormat="MM/dd/yyyy hh:mm a" showTimeSelect timeFormat="h:mm a" timeIntervals={1}
                                                                                timeCaption="Time"
                                                                                portalId="root-portal"
                                                                                selected={[undefined, '', "Invalid date"].includes(d.time) ? new Date() :
                                                                                    new Date(moment(d.time))}
                                                                                onChange={(date) => {

                                                                                    const unix = moment(date).format('x');
                                                                                    this.updateData(i, 'time', moment(unix, 'x').tz("America/Los_Angeles").format("YYYY-MM-DD HH:mm"))
                                                                                    this.updateData(i, 'timestamp', moment(unix, 'x').format('X'))
                                                                                    this.updateData(i, 'edited', true)

                                                                                }} />


                                                                            :
                                                                            moment(d.time, 'YYYY-MM-DD HH:mm').format('MM/DD/YYYY h:mm a')}


                                                                    </Col>
                                                                    <Col span={4}>
                                                                        {d.edit === true ?
                                                                            <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                                                id={`${d.key}value`}
                                                                                value={input}
                                                                                type="number"
                                                                                onChange={(e) => {
                                                                                    console.log(e.target.value)
                                                                                    this.updateData(i, 'value', e.target.value === "" ? "" : Number(e.target.value))
                                                                                }} />
                                                                            :
                                                                            d.value}
                                                                    </Col>
                                                                    <Col span={5}>
                                                                        <Row>
                                                                            {d.parameterLabel === undefined && (<Col span={24} style={{ fontSize: 10, color: 'red' }}>
                                                                                error: {d.errorLabelP}
                                                                            </Col>)}
                                                                            <Col span={24}>
                                                                                {d.edit === true ? <Autocomplete
                                                                                    size="small"
                                                                                    id="combo-box-demo"
                                                                                    value={d.parameterLabel}
                                                                                    disableClearable={true}
                                                                                    style={{ border: d.parameterBorder }}
                                                                                    options={parameters}
                                                                                    filterSelectedOptions
                                                                                    renderInput={(params) => <TextField {...params} label="Parameter" size="small" />}
                                                                                    onChange={(a, b) => {
                                                                                        this.updateData(i, 'parameterLabel', b.label)
                                                                                        this.updateData(i, 'parameterid', b.key)
                                                                                        this.updateData(i, 'parameterUnits', b.unitLabel || '')
                                                                                        this.updateData(i, 'parameterBorder', null)


                                                                                    }}

                                                                                /> : d.parameterLabel}
                                                                            </Col>
                                                                        </Row>



                                                                    </Col>
                                                                    <Col span={5}>
                                                                        <Row>
                                                                            {d.locationLabel === undefined && (<Col span={24} style={{ fontSize: 10, color: 'red' }}>
                                                                                error: {d.errorLabelS}
                                                                            </Col>)}
                                                                            <Col span={24}>
                                                                                {d.edit === true ? <Autocomplete
                                                                                    size="small"
                                                                                    id="combo-box-demo"
                                                                                    disableClearable={true}
                                                                                    value={d.locationLabel}
                                                                                    style={{ border: d.locationBorder }}
                                                                                    options={sites}
                                                                                    filterSelectedOptions
                                                                                    renderInput={(params) => <TextField {...params} label="Site" size="small" />}
                                                                                    onChange={(a, b) => {
                                                                                        this.updateData(i, 'locationLabel', b.label)
                                                                                        this.updateData(i, 'locationid', b.key)
                                                                                        this.updateData(i, 'locationBorder', null)

                                                                                    }}

                                                                                /> : d.locationLabel}
                                                                            </Col>
                                                                        </Row>

                                                                    </Col>
                                                                    <Col span={2}>
                                                                        {[d.locationBorder, d.parameterBorder].includes('1px solid red') && (
                                                                            <FontAwesomeIcon icon={faCircleExclamation} color="red" />
                                                                        )}
                                                                    </Col>
                                                                    <Col span={2} style={{ textAlign: 'center' }}>
                                                                        <IconButton style={{ textTransform: 'none' }} color="primary" size="small"
                                                                            onClick={() => {
                                                                                this.updateData(i, 'edit', !d.edit)
                                                                            }}>
                                                                            <EditIcon />
                                                                        </IconButton>
                                                                    </Col>
                                                                    <Col span={2} style={{ textAlign: 'center' }}>
                                                                        <DeleteItem row={d} />
                                                                    </Col>
                                                                    <Col span={24}>
                                                                        <Divider />
                                                                    </Col>

                                                                </Row>
                                                            </Col>
                                                        )
                                                    })}
                                                </Row>
                                            </Col>






                                            <Col xs={24} sm={24} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                                {uploading ? <CircularProgress /> : <Button style={{ textTransform: 'none', padding: 12, fontSize: 16 }} variant="contained" color="primary"
                                                    onClick={async () => {
                                                        this.props.openSnack('success', 'Data Uploading...')
                                                        this.setState({ uploading: true })
                                                        this.checkData()
                                                    }}>Upload Data</Button>}
                                            </Col>


                                        </Row>

                                    )}
                                </Col>

                            </Row>

                        </TabPanel>
                        <TabPanel value="data">
                            <Row gutter={[12, 12]}>
                                <Col span={24} style={{ paddingTop: 20 }}>


                                    <Row gutter={[12, 12]} align="middle">
                                        <Col span={20} style={{ paddingBottom: 12, }}>
                                            <Typography variant='h6'>Add Data</Typography>
                                        </Col>
                                        <Col span={4} style={{ paddingBottom: 12, textAlign: 'right' }}>
                                            <CreateItemButton />
                                        </Col>
                                        <Col span={24} style={{}}>
                                            <Divider />
                                        </Col>
                                        <Col span={24} style={{}}>
                                            <Row gutter={[4, 4]}>
                                                <Col span={24}>
                                                    <Row>
                                                        <Col span={4}>
                                                            Date
                                                        </Col>
                                                        <Col span={4}>
                                                            Value
                                                        </Col>
                                                        <Col span={5}>
                                                            Parameter
                                                        </Col>
                                                        <Col span={5}>
                                                            Site
                                                        </Col>
                                                        <Col span={2} style={{ textAlign: 'center' }}>
                                                            Error
                                                        </Col>
                                                        <Col span={2} style={{ textAlign: 'center' }}>
                                                            Edit
                                                        </Col>
                                                        <Col span={2} style={{ textAlign: 'center' }}>
                                                            Delete
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col span={24}>
                                                    <Divider />
                                                </Col>
                                                {addData.map((d, i) => {

                                                    const input = d.value === 0 || d.value ? d.value : '';

                                                    return (
                                                        <Col span={24} key={d.key} style={{ backgroundColor: i % 2 === 0 ? 'inherit' : 'rgba(0,0,0,0.03)' }}>
                                                            <Row align="middle" gutter={[4, 4]} key={d.key} >
                                                                <Col span={4}>
                                                                    {d.edit === true ?

                                                                        <DatePicker dateFormat="MM/dd/yyyy hh:mm a" showTimeSelect timeFormat="h:mm a" timeIntervals={1}
                                                                            timeCaption="Time"
                                                                            portalId="root-portal"
                                                                            selected={[undefined, '', "Invalid date"].includes(d.time) ? new Date() :
                                                                                new Date(moment(d.time))}
                                                                            onChange={(date) => {

                                                                                const unix = moment(date).format('x');
                                                                                this.updateAddData(i, 'time', moment(unix, 'x').tz("America/Los_Angeles").format("YYYY-MM-DD HH:mm"))
                                                                                this.updateAddData(i, 'timestamp', moment(unix, 'x').format('X'))
                                                                                this.updateAddData(i, 'edited', true)

                                                                            }} />


                                                                        :
                                                                        moment(d.time, 'YYYY-MM-DD HH:mm').format('MM/DD/YYYY h:mm a')}
                                                                </Col>
                                                                <Col span={4}>
                                                                    {d.edit === true ?
                                                                        <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                                            id={`${d.key}value`}
                                                                            value={input}
                                                                            type="number"
                                                                            onChange={(e) => {
                                                                                console.log(e.target.value)
                                                                                this.updateAddData(i, 'value', e.target.value === "" ? "" : Number(e.target.value))
                                                                            }} />
                                                                        :
                                                                        d.value}
                                                                </Col>
                                                                <Col span={5}>
                                                                    <Row>
                                                                        {d.parameterLabel === undefined && (<Col span={24} style={{ fontSize: 10, color: 'red' }}>
                                                                            error: {d.errorLabelP}
                                                                        </Col>)}
                                                                        <Col span={24}>
                                                                            {d.edit === true ? <Autocomplete
                                                                                size="small"
                                                                                id="combo-box-demo"
                                                                                value={d.parameterLabel}
                                                                                disableClearable={true}
                                                                                style={{ border: d.parameterBorder }}
                                                                                options={parameters}
                                                                                filterSelectedOptions
                                                                                renderInput={(params) => <TextField {...params} label="Parameter" size="small" />}
                                                                                onChange={(a, b) => {
                                                                                    this.updateAddData(i, 'parameterLabel', b.label)
                                                                                    this.updateAddData(i, 'parameterid', b.key)
                                                                                    this.updateAddData(i, 'parameterUnits', b.unitLabel || '')
                                                                                    this.updateAddData(i, 'parameterBorder', null)


                                                                                }}

                                                                            /> : d.parameterLabel}
                                                                        </Col>
                                                                    </Row>



                                                                </Col>
                                                                <Col span={5}>
                                                                    <Row>
                                                                        {d.locationLabel === undefined && (<Col span={24} style={{ fontSize: 10, color: 'red' }}>
                                                                            error: {d.errorLabelS}
                                                                        </Col>)}
                                                                        <Col span={24}>
                                                                            {d.edit === true ? <Autocomplete
                                                                                size="small"
                                                                                id="combo-box-demo"
                                                                                disableClearable={true}
                                                                                value={d.locationLabel}
                                                                                style={{ border: d.locationBorder }}
                                                                                options={sites}
                                                                                filterSelectedOptions
                                                                                renderInput={(params) => <TextField {...params} label="Site" size="small" />}
                                                                                onChange={(a, b) => {
                                                                                    this.updateAddData(i, 'locationLabel', b.label)
                                                                                    this.updateAddData(i, 'locationid', b.key)
                                                                                    this.updateAddData(i, 'locationBorder', null)

                                                                                }}

                                                                            /> : d.locationLabel}
                                                                        </Col>
                                                                    </Row>

                                                                </Col>
                                                                <Col span={2}>
                                                                    {[d.locationBorder, d.parameterBorder].includes('1px solid red') && (
                                                                        <FontAwesomeIcon icon={faCircleExclamation} color="red" />
                                                                    )}
                                                                </Col>
                                                                <Col span={2} style={{ textAlign: 'center' }}>
                                                                    <IconButton style={{ textTransform: 'none' }} color="primary" size="small"
                                                                        onClick={() => {
                                                                            this.updateAddData(i, 'edit', !d.edit)
                                                                        }}>
                                                                        <EditIcon />
                                                                    </IconButton>
                                                                </Col>
                                                                <Col span={2} style={{ textAlign: 'center' }}>
                                                                    <DeleteAddItem row={d} />
                                                                </Col>
                                                                <Col span={24}>
                                                                    <Divider />
                                                                </Col>

                                                            </Row>
                                                        </Col>
                                                    )
                                                })}
                                            </Row>
                                        </Col>
                                        {addData.length > 0 && (<Col xs={24} sm={24} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                            {uploading ? <CircularProgress /> : <Button style={{ textTransform: 'none', padding: 12, fontSize: 16 }} variant="contained" color="primary"
                                                onClick={async () => {
                                                    this.props.openSnack('success', 'Data Uploading...')
                                                    this.setState({ uploading: true })
                                                    this.checkData2()
                                                }}>Upload Data</Button>}
                                        </Col>)}
                                    </Row>
                                </Col>

                            </Row>
                        </TabPanel>

                    </TabContext>
                </Col>








            </Row>
        );
    }
} 