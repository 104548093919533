import { Button, OutlinedInput, Paper, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import { getAuth, sendPasswordResetEmail, updateEmail, updateProfile } from "firebase/auth";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
import React from 'react';

export default class UserProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            uid: this.props.uid,
            userInfo: this.props.userInfo,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.uid !== prevProps.uid) {
            this.setState({ uid: this.props.uid })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
    }
    componentDidMount() {
        const { uid, userInfo } = this.state;
        window.scrollTo(0, 0)

    }

    updateState = (item, value) => {
        const { userInfo } = this.state;
        userInfo[item] = value;
        this.setState({ userInfo })
    }

    render() {
        const { screenWidth, userInfo } = this.state;

        const UpdateButton = () => {

            return (
                <Button style={{ textTransform: 'none', padding: 16, fontSize: 16, backgroundColor: '#404050' }} fullWidth={screenWidth === 'xs' ? true : false} variant="contained" color="primary"
                    onClick={async () => {
                        const db = getFirestore();
                        const userRef = doc(db, "users", userInfo.id);

                        await updateDoc(userRef, userInfo);
                        this.props.updateUser(userInfo);
                        this.props.openSnack('success', 'User Updated!');

                        const auth = getAuth();
                        updateProfile(auth.currentUser, {
                            displayName: userInfo.displayName
                        }).then(() => {
                            // Profile updated!
                            // ...
                        }).catch((error) => {
                            // An error occurred
                            // ...
                        });
                        updateEmail(auth.currentUser, userInfo.email).then(() => {
                            // Email updated!
                            // ...
                        }).catch((error) => {
                            // An error occurred
                            // ...
                        });
                    }
                    }>
                    Update Account
                </Button >
            )
        }
        const ResetPasswordButton = () => {

            return (
                <Button style={{ textTransform: 'none', padding: 16, fontSize: 16 }} fullWidth={screenWidth === 'xs' ? true : false} variant="contained" color="secondary"
                    onClick={async () => {
                        const auth = getAuth();
                        sendPasswordResetEmail(auth, userInfo.email)
                            .then(() => {
                                this.props.openSnack('success', 'Password Reset Email Sent!');
                            })
                            .catch((error) => {
                                const errorCode = error.code;
                                const errorMessage = error.message;
                                // ..
                            });
                    }
                    }>
                    Reset Password
                </Button >
            )
        }

        return (
            <Row style={{ padding: 30 }}>
                <Col span={22}>
                    <Typography variant="h5">Update My Profile</Typography>
                </Col>
                <Col span={22} style={{ paddingTop: 30 }}>
                   
                        <Row gutter={[12, 12]}>
                            
                            <Col span={24}>
                                {[
                                    { label: 'Display Name', item: 'displayName' },
                                    { label: 'First Name', item: 'firstName' },
                                    { label: 'Last Name', item: 'lastName' },
                                    { label: 'Company', item: 'company' },
                                    { label: 'Email', item: 'email' },
                                ].map((f) => {
                                    return (
                                        <Row style={{ paddingTop: 20 }}>
                                            <Col span={24}>
                                                <Typography variant="subtitle1">{f.label}</Typography>
                                            </Col>
                                            <Col xs={24} sm={13}>
                                                <OutlinedInput fullWidth notched={false} label="none" placeholder={f.label} size="small"
                                                    value={userInfo[f.item]}
                                                    onChange={(e) => {
                                                        this.updateState(`${f.item}`, e.target.value)
                                                    }} />
                                            </Col>
                                        </Row>
                                    )

                                })}
                            </Col>

                            <Col xs={24} sm={6} style={{ paddingTop: 25 }}>
                                <UpdateButton />
                            </Col>
                            <Col xs={24} sm={6} style={{ paddingTop: 25, }}>
                                <ResetPasswordButton />
                            </Col>
                            <Col xs={24} style={{ paddingTop: 15, }}>

                            </Col>


                        </Row>
                    
                </Col>
            </Row>
        );
    }
} 