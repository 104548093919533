import { Button, Divider, IconButton, OutlinedInput, Typography } from '@mui/material';
import { Col, Modal, Popconfirm, Row } from 'antd';
import React, { useEffect, useState, useMemo } from 'react';
import { fullMobile } from './util';
import 'react-data-grid/lib/styles.css';
import uuid from 'react-uuid';
import { memo } from 'react';
import moment from 'moment-timezone';
import { DataGrid } from '@mui/x-data-grid';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, orderBy, limit } from 'firebase/firestore';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { SearchOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { faCircleXmark, faRemove, faSort } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


var randomColor = require('randomcolor');



export default function ItemsTable(props) {
    const { userInfo, screenWidth, openSnack, company, account, staffRef, usersRef, combinedRef, deleteItem } = props;
    const [loading, setLoading] = useState(true);
    const [rowCount, setRowCount] = useState(0);
    const [data, setData] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRowsKeys, setSelectedRowsKeys] = useState([]);
    const [sortColumns, setSortColumns] = useState([]);
    const [staff, setStaff] = useState([]);
    const [users, setUsers] = useState([]);
    const [searchValue, setSearchValue] = useState('')
    const [nameSorted, setNameSorted] = useState(false)
    const [deleteSorted, setDeleteSorted] = useState(false)
    const [companySorted, setCompanySorted] = useState(false)
    const [itemModal, setItemModal] = useState(false)
    const [item, setItem] = useState({})
    const db = getFirestore();

    useEffect(() => {
        setData(combinedRef)
        setFilterData(combinedRef)
    }, [combinedRef])








    //create 50 rows with the schema above columns
    //create array from 0 to 50

    const EditButton = (props) => {
        let navigate = useNavigate()
        return (
            <Button variant={'outlined'} display="inline"
                size="small"
                style={{
                    width: 50,
                    textTransform: 'none', border: '1px solid #404050', fontSize: 12, color: '#404050', backgroundColor: 'white'
                }}
                onClick={async () => {
                    const userRef = doc(db, "users", userInfo.id);

                    // Set the "capital" field of the city 'DC'
                    await updateDoc(userRef, {
                        currentAccount: props.user.key
                    }).then(() => {
                        navigate("/account");
                    })
                }}
            >
                Edit
            </Button>

        )
    }
    let navigate = useNavigate()

    async function sortData(column) {
        if (column === 'name') {
            filterData.sort((a, b) => {
                if (nameSorted) {
                    return a.accountName.localeCompare(b.accountName)
                } else {
                    return b.accountName.localeCompare(a.accountName)
                }
            })
            setFilterData([...filterData])
            setNameSorted(!nameSorted)
        }
        if (column === 'delete') {
            filterData.sort((a, b) => {
                //sort based on true or false
                if (deleteSorted) {
                    return a.default - b.default
                }
                else {
                    return b.default - a.default
                }
            })
            console.log(filterData)
            setFilterData([...filterData])
            setDeleteSorted(!deleteSorted)
        }


    }



    return (

        <Row gutter={[12, 12]} >
            <Col {...fullMobile(24, 24)}>
                <OutlinedInput
                    style={{ width: 300, backgroundColor: 'white' }}
                    placeholder="Search Sites"
                    value={searchValue}
                    startAdornment={
                        <SearchOutlined />
                    }
                    endAdornment={
                        //create button to clear search
                        <IconButton
                            variant={'link'}
                            size="small"
                            style={{
                                width: 50,
                                textTransform: 'none', fontSize: 12, color: '#404050', backgroundColor: 'white'
                            }}
                            onClick={() => {
                                setSearchValue('')
                                setFilterData(data.sort((a, b) => {
                                    return a.accountName.localeCompare(b.accountName)
                                }))
                            }}
                        >
                            <FontAwesomeIcon icon={faCircleXmark} color="#9ec1c9" size='2x' />
                        </IconButton>
                    }
                    onChange={(e) => {
                        //if e.target.value is empty, set filterData to data
                        setSearchValue(e.target.value)
                        if (e.target.value === '') {
                            setFilterData(data)
                        } else {
                            //use regex to filter data
                            let filtered = data.filter((item) => {
                                let regex = new RegExp(e.target.value, 'gi')
                                return item.accountName.match(regex)
                            })
                            setFilterData(filtered)
                        }

                    }}
                />
            </Col>



            <Col {...fullMobile(24, 24)} style={{
                overflowX: 'scroll',
            }}>
                <Table sx={{
                    minWidth: 650,
                    maxWidth: '100vw',
                    backgroundColor: 'white',
                    //add horizontal scroll bar if screen is too small
                    overflowX: screenWidth === 'xs' ? 'scroll' : 'hidden',


                }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell style={{
                                width: 300,
                            }}   >
                                <div style={{ cursor: 'pointer' }}
                                    onClick={() => { sortData('name') }}
                                >
                                    <Typography variant="body2" display="inline" fontWeight={600} fontSize={16} style={{ color: '#404050' }}>
                                        Name <FontAwesomeIcon fontSize={14} icon={faSort} color="#9ec1c9" />
                                    </Typography>
                                </div>
                            </TableCell>

                            <TableCell style={{ width: 350 }} >
                                <Typography variant="body2" display="inline" fontWeight={600} fontSize={16} style={{ color: '#404050' }}>
                                    County
                                </Typography>
                            </TableCell>
                            <TableCell style={{ width: 350 }} >
                                <Typography variant="body2" display="inline" fontWeight={600} fontSize={16} style={{ color: '#404050' }}>
                                    City
                                </Typography>
                            </TableCell>
                            <TableCell style={{ width: 350 }} >
                                <Typography variant="body2" display="inline" fontWeight={600} fontSize={16} style={{ color: '#404050' }}>
                                    Route Day
                                </Typography>
                            </TableCell>
                            <TableCell style={{ width: 350 }} >
                                <Typography variant="body2" display="inline" fontWeight={600} fontSize={16} style={{ color: '#404050' }}>
                                    Route Name
                                </Typography>
                            </TableCell>



                        </TableRow>
                    </TableHead>
                    <TableBody
                    //scroll bars if screen width is too small



                    >
                        {filterData.map((row, i) => {
                            const backgroundColor = i % 2 === 0 ? '#f8f8f8' : 'white';
                            return (
                                <TableRow
                                    key={row.key}
                                    sx={{
                                        '&:last-child td, &:last-child th': { border: '1px solid rgba(0,0,0,0.2)' },
                                        //onhover change background color
                                        '&:hover': {
                                            backgroundColor: '#40405036'
                                        },
                                        backgroundColor: backgroundColor,
                                        cursor: 'pointer'
                                    }}
                                    onClick={async () => {
                                        const userRef = doc(db, "users", userInfo.id);

                                        // Set the "capital" field of the city 'DC'
                                        await updateDoc(userRef, {
                                            currentAccount: row.key
                                        }).then(() => {
                                            navigate("/account");
                                        })

                                    }}
                                >
                                    <TableCell
                                        style={{ border: '1px solid rgba(0,0,0,0.2)' }}
                                        component="th" scope="row">

                                        <EditButton user={row} />


                                    </TableCell>
                                    <TableCell
                                        style={{
                                            border: '1px solid rgba(0,0,0,0.2)',
                                            width: 300,
                                        }}
                                        sx={{

                                            //if screenwidth is xs, add border right


                                        }}
                                    >
                                        <Typography variant="body2" display="inline" fontWeight={600} fontSize={14} style={{ color: '#404050' }}>
                                            {row?.accountName}
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        style={{ border: '1px solid rgba(0,0,0,0.2)' }}
                                    >
                                        <Typography variant="body2" display="inline" fontWeight={400} fontSize={14} style={{ color: '#404050' }}>
                                            {row?.countyName}
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        style={{ border: '1px solid rgba(0,0,0,0.2)' }}
                                    >
                                        <Typography variant="body2" display="inline" fontWeight={400} fontSize={14} style={{ color: '#404050' }}>
                                            {row?.city}
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        style={{ border: '1px solid rgba(0,0,0,0.2)' }}
                                    >
                                        <Typography variant="body2" display="inline" fontWeight={400} fontSize={14} style={{ color: '#404050' }}>
                                            {row?.routeDay}
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        style={{ border: '1px solid rgba(0,0,0,0.2)' }}
                                    >
                                        <Typography variant="body2" display="inline" fontWeight={400} fontSize={14} style={{ color: '#404050' }}>
                                            {row?.routeName}
                                        </Typography>
                                    </TableCell>





                                </TableRow>

                            )
                        })}
                    </TableBody>
                </Table>
            </Col>











        </Row>

    );
}