import { faGear } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Autocomplete, Box, Checkbox, Collapse, Divider, IconButton, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material';
import Tab from '@mui/material/Tab';
import { Col, Popconfirm, Row } from 'antd';
import React from 'react';

export default class ForecastSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            attributes: this.props.attributes,
            currentWidgetKey: this.props.currentWidgetKey,
            locations: [],
            references: [],
            parameters: [],
            sites: [],
            activeTab: 'data',
            readData: this.props.readData,
            updateAttributes: this.props.updateAttributes,
        };
    }



    render() {

        const { account, attributes, screenWidth, locations, expanded, activeTab, references, currentWidgetKey, parameters, readData, userInfo, updateAttributes } = this.state;





        return (











            <Row align="middle">
                <Col span={24}>
                    <Typography fontSize={16} variant='subtitle2'>Forecast Settings</Typography>
                </Col>

                <Col >
                    <Row>
                        <Col span={24}>
                            <Typography fontSize={13} variant="body1">Time Frame</Typography>
                        </Col>
                        <Col span={24}>
                            <Select size="small" fullWidth
                                notched={false}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={attributes.timeFrame || ''}
                                label="Layer"
                                onChange={(e) => {
                                    updateAttributes('timeFrame', e.target.value)
                                }}

                            >
                                <MenuItem value={'days'}>Latest Day</MenuItem>
                                <MenuItem value={'weeks'}>Latest Week</MenuItem>
                                <MenuItem value={'months'}>Latest Month</MenuItem>

                            </Select>
                        </Col>
                    </Row>


                </Col>















            </Row >
        );
    }
} 