import React from "react";

import moment from 'moment-timezone';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";



const DateSelectComponent = (
    {
        currentTask,
        setCurrentTask,
        headerKey

    }

) => {


 

    return (

        <DatePicker portalId="root-portal"
            placeholderText={'Please select a date'}
            showTimeSelect
            timeFormat="h:mm a"
            timeIntervals={30}
            timeCaption="time"
            dateFormat="MMMM d, yyyy h:mm a" selected={currentTask?.[headerKey] ? new Date(moment(currentTask[headerKey], 'x')) : ''} onChange={(date) => {
                console.log(date)
                setCurrentTask({ ...currentTask, [headerKey]: moment(date).format('x') })

            }} />


    );
};

export default DateSelectComponent;
