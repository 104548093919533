import { Button, ButtonGroup, CircularProgress, Divider, Typography, OutlinedInput, Autocomplete, TextField, Select, MenuItem } from '@mui/material';
import { Col, Modal, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { queryAccountStaff, queryUsers, queryDistributionGroups, queryCompanyStaff, queryCompanyUsers, sendInvite } from '../../../firebase/config';
import { fullMobile } from '../util';
import StaffDirectoryTable from './staffDirectoryTable';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, } from 'firebase/firestore';

import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import DistributionGroupsTable from './distributionGroupsTable';
import uuid from 'react-uuid';




var randomColor = require('randomcolor');

export default function AccountDirectoryHome(props) {
    const { userInfo, screenWidth, openSnack, company, account, } = props;
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [staff, setStaff] = useState([]);
    const [companyStaff, setCompanyStaff] = useState([]);
    const [companyUsers, setCompanyUsers] = useState([]);
    const [loadingStaff, setLoadingStaff] = useState(true);
    const [loadingUsers, setLoadingUsers] = useState(true);
    const [loadingGroups, setLoadingGroups] = useState(true);
    const [currentButton, setCurrentButton] = useState('users')
    const [groups, setGroups] = useState([])
    const [distributionModal, setDistributionModal] = useState(false)
    const [distributionGroup, setDistributionGroup] = useState(null)
    const [combined, setCombined] = useState([])
    const [combinedCompany, setCombinedCompany] = useState([])
    const [addUserModal, setAddUserModal] = useState(false)
    const [invite, setInvite] = useState({
        email: '',
        firstName: '',
        lastName: '',
        role: '',
        id: '',
        email: '',
        phone: '',
        company: '',
    })
    const [checkingUser, setCheckingUser] = useState(false)
    const [addExistingUser, setAddExistingUser] = useState(false)
    const [addUsers, setAddUsers] = useState([])




    const db = getFirestore()


    useEffect(() => {
        window.scrollTo(0, 0);
        getInfo()
    }, [])

    async function getInfo() {

        const users = await queryUsers(userInfo)
        setUsers(users)
        setLoadingUsers(false)

        const staff = await queryAccountStaff(userInfo)
        setStaff(staff)
        setLoadingStaff(false)

        combineUsersAndStaff(users, staff)

        const groups = await queryDistributionGroups(userInfo)
        console.log(groups)
        setGroups(groups)
        setLoadingGroups(false)

        const companyStaff = await queryCompanyStaff(userInfo)
        console.log(companyStaff)
        setCompanyStaff(companyStaff)

        const companyUsers = await queryCompanyUsers(userInfo)
        setCompanyUsers(companyUsers)
        console.log(companyUsers)


        combineCompanyUsersAndStaff(companyUsers, companyStaff)



    }



    function combineUsersAndStaff(users, staff) {
        //find matching ids in users and staff and combine them into single object and add to data
        console.log(users)
        console.log(staff)
        let combined = []
        for (let i = 0; i < users.length; i++) {
            for (let j = 0; j < staff.length; j++) {
                const staffid = staff[j].key || staff[j].id;
                if (users[i].id === staffid) {
                    combined.push({ ...users[i], ...staff[j] })
                }
            }
        }
        console.log(combined)
        setCombined(combined)
    }

    function combineCompanyUsersAndStaff(companyUsers, companyStaff) {
        //find matching ids in users and staff and combine them into single object and add to data
        console.log(companyUsers)
        console.log(companyStaff)
        let combined = []
        for (let i = 0; i < companyUsers.length; i++) {
            for (let j = 0; j < companyStaff.length; j++) {
                const staffid = companyStaff[j].key || companyStaff[j].id;
                if (companyUsers[i].id === staffid) {
                    combined.push({ ...companyUsers[i], ...companyStaff[j] })
                }
            }
        }
        console.log(combined)
        setCombinedCompany(combined)
    }



    const PermissionsTemplatesButton = (params) => {
        let navigate = useNavigate();

        return (
            <Button variant={'contained'} display="inline"
                fullWidth
                style={{
                    padding: 4, paddingTop: 8, paddingBottom: 8,
                    textTransform: 'none', backgroundColor: '#404050', fontSize: 13,
                }}
                onClick={() => {
                    navigate(`/permissionsTemplates`)

                }}
            >
                Permission Templates
            </Button>
        )
    }

    async function checkUserExists(email) {
        const lowerEmail = email.toLowerCase()
        setCheckingUser(true)
        const queryStaff = query(collection(db, "users",), where('email', '==', lowerEmail), where('signedUp', '==', true));
        const data = [];
        const snapStaff = await getDocs(queryStaff);
        snapStaff.forEach((doc) => {
            data.push(doc.data())
        });
        if (data.length > 0) {
            setInvite({ ...data[0], ...invite, label: `${data[0].firstName || ''} ${data[0].lastName || ''}`, found: true })
            addUser(data[0])
            openSnack("success", "User has been found!")
            setCheckingUser(false)






        }
        else {
            openSnack("error", "User not found!")
            setInvite({ ...invite, found: false })
            setCheckingUser(false)
        }
    }


    async function addUser(user) {

        if (user.accounts.includes(userInfo.currentAccount)) {
            openSnack("error", "User already exists in this account!")
            return
        }
        if (user.companies.includes(userInfo.currentCompany)) {
            const userRef = doc(db, "users", user.id,);

            if (!user.accounts.includes(userInfo.currentAccount)) {
                await updateDoc(userRef, {
                    accounts: arrayUnion(userInfo.currentAccount)

                })
                console.log('added account to user')
            }


            if (!combined.map((d) => d.id).includes(user.id)) {
                const item = { ...user, accounts: [...user.accounts, userInfo.currentAccount] }
                combined.push(item);
                setCombined([...combined])
                const docRef = doc(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'staff', user.id);
                delete user.accounts;
                delete user.companies;
                await setDoc(docRef, user)
                console.log('added user to account')
            }

            console.log(user)
            //close modal and open snackbar
            openSnack("success", "User has been added!")
            setAddUserModal(false)
            setAddUsers([])


        }

        if (!user.companies.includes(userInfo.currentCompany)) {

            const userRef = doc(db, "users", user.id,);

            if (!user.companies.includes(userInfo.currentCompany)) {
                await updateDoc(userRef, {
                    companies: arrayUnion(userInfo.currentCompany)

                })
                console.log('added account to user')
            }
            if (!user.accounts.includes(userInfo.currentAccount)) {
                await updateDoc(userRef, {
                    accounts: arrayUnion(userInfo.currentAccount)

                })
                console.log('added account to user')
            }


            if (!combined.map((d) => d.id).includes(user.id)) {
                const item = { ...user, accounts: [...user.accounts, userInfo.currentAccount], companies: [...user.companies, userInfo.currentCompany] }
                combined.push(item);
                setCombined([...combined])
                const docRef = doc(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'staff', user.id);
                delete user.accounts;
                delete user.companies;
                await setDoc(docRef, user)

                const docRefCompany = doc(db, "clients", userInfo.currentCompany, 'staff', user.id);
                await setDoc(docRefCompany, { adminLevel: 'none', key: item.id, id: item.id })


                console.log('added user to account')
            }

            console.log(user)
            //close modal and open snackbar
            openSnack("success", "User has been added!")
            setAddUserModal(false)
            setAddUsers([])
            return
        }




 
    }



    return (
        <div style={{ fontFamily: 'Roboto, sans-serif', backgroundColor: '#f6f8fe', minHeight: '100vh' }}>
            <Row  >
                <Col {...fullMobile(19, 23)} style={{
                    paddingTop: 30,
                    paddingLeft: 30,
                    overflow: 'auto',


                }}>
                    <Row align="middle" >
                        <Col {...fullMobile(20, 24)}>
                            <Typography variant='h3' fontWeight={500} fontSize={36}>Site Directory</Typography>
                            <Typography style={{ paddingTop: 5 }} variant='h5' fontWeight={300} fontSize={18}>Your directory as part of this site.</Typography>

                        </Col>
                        <Col {...fullMobile(0, 24)} style={{


                        }}>
                            <Row style={{ padding: 30, }} gutter={[0, 12]}>
                                <Col {...fullMobile(24, 24)}>
                                    <Button variant={'contained'} display="inline"
                                        fullWidth
                                        style={{
                                            padding: 4, paddingTop: 8, paddingBottom: 8,
                                            textTransform: 'none', backgroundColor: '#404050', fontSize: 13,
                                        }}
                                        onClick={() => {
                                            setAddUserModal(true)

                                        }}
                                    >
                                        <AddIcon fontSize='small' />  Add User
                                    </Button>
                                </Col>
                                {["EuEZ93ByFZYs8bSUXoYsTt1zu0m2", 'eTevYsI9mQMxhtntd95HmRGasNx2', 'PQWFmTLCP9XqafR9ogNI3Fg1sH43', 'z4y8XVrxYGT7rO4R01Y8tQ2F5z83', 't9bOfGDqwDaQTYV6i008opscpjz2'].includes(userInfo.id) && (<Col {...fullMobile(24, 24)}>
                                    <PermissionsTemplatesButton />
                                </Col>)}
                                {["EuEZ93ByFZYs8bSUXoYsTt1zu0m2", 'eTevYsI9mQMxhtntd95HmRGasNx2', 'PQWFmTLCP9XqafR9ogNI3Fg1sH43', 'z4y8XVrxYGT7rO4R01Y8tQ2F5z83', 't9bOfGDqwDaQTYV6i008opscpjz2'].includes(userInfo.id) && (<Col {...fullMobile(24, 24)}>
                                    <Button variant={'contained'} display="inline"
                                        fullWidth
                                        style={{
                                            padding: 4, paddingTop: 8, paddingBottom: 8,
                                            textTransform: 'none', backgroundColor: '#404050', fontSize: 13,
                                        }}
                                        onClick={() => {
                                            setDistributionGroup({ key: uuid(), name: '', members: [] })
                                            setDistributionModal(true)

                                        }}
                                    >
                                        <AddIcon fontSize='small' />  Add Distribution Group
                                    </Button>
                                </Col>)}
                            </Row>

                        </Col>

                        <Col {...fullMobile(24, 24)} style={{ paddingTop: 10, paddingBottom: 10 }}>
                            <Divider />
                        </Col>
                        <Col {...fullMobile(24, 24)} style={{ paddingTop: 10, paddingBottom: 10 }}>
                            <ButtonGroup variant="link" aria-label="contained primary button group">
                                {[{ name: 'users', label: 'Users' }, { name: 'distributionGroups', label: 'Distribution Groups' }].map((item, index) => {
                                    return (
                                        <Button style={{
                                            color: '#404050', textTransform: 'none', fontSize: 14, borderRadius: 0,
                                            //add orange bottom border if current button is users
                                            borderBottom: currentButton === item.name ? '2px solid #f57c00' : 'none'
                                        }}
                                            onClick={() => {
                                                setCurrentButton(item.name)
                                                console.log(item.name)
                                            }}
                                        >
                                            {item.label}
                                        </Button>
                                    )
                                })}

                            </ButtonGroup>

                        </Col>
                        <Col {...fullMobile(24, 24)} >
                            <Divider />
                        </Col>
                        {loadingStaff === false && loadingUsers === false && loadingGroups === false ?
                            <Col {...fullMobile(24, 24)} style={{ padding: 8 }}>
                                {currentButton === 'users' && (
                                    <StaffDirectoryTable {...props} combinedRef={combined} />
                                )}

                                {currentButton === 'distributionGroups' && (
                                    <DistributionGroupsTable {...props} groupsRef={groups} combinedRef={combined} />
                                )}

                            </Col> :
                            <CircularProgress />}
                    </Row>

                </Col>

                <Col xs={{ span: 0 }} sm={{ span: 5 }} style={{
                    minHeight: '100vh',
                    backgroundColor: '#f5f5f5'

                }}>
                    <Row style={{ padding: 30, }} gutter={[0, 12]}>
                        <Col {...fullMobile(24, 24)}>
                            <Button variant={'contained'} display="inline"
                                fullWidth
                                style={{
                                    padding: 4, paddingTop: 8, paddingBottom: 8,
                                    textTransform: 'none', backgroundColor: '#404050', fontSize: 13,
                                }}
                                onClick={() => {
                                    setAddUserModal(true)

                                }}
                            >
                                <AddIcon fontSize='small' />  Add User
                            </Button>
                        </Col>
                        {["EuEZ93ByFZYs8bSUXoYsTt1zu0m2", 'eTevYsI9mQMxhtntd95HmRGasNx2', 'PQWFmTLCP9XqafR9ogNI3Fg1sH43', 'z4y8XVrxYGT7rO4R01Y8tQ2F5z83', 't9bOfGDqwDaQTYV6i008opscpjz2'].includes(userInfo.id) && (<Col {...fullMobile(24, 24)}>
                            <PermissionsTemplatesButton />
                        </Col>)}
                        {["EuEZ93ByFZYs8bSUXoYsTt1zu0m2", 'eTevYsI9mQMxhtntd95HmRGasNx2', 'PQWFmTLCP9XqafR9ogNI3Fg1sH43', 'z4y8XVrxYGT7rO4R01Y8tQ2F5z83', 't9bOfGDqwDaQTYV6i008opscpjz2'].includes(userInfo.id) && (<Col {...fullMobile(24, 24)}>
                            <Button variant={'contained'} display="inline"
                                fullWidth
                                style={{
                                    padding: 4, paddingTop: 8, paddingBottom: 8,
                                    textTransform: 'none', backgroundColor: '#404050', fontSize: 13,
                                }}
                                onClick={() => {
                                    setDistributionGroup({ key: uuid(), name: '', members: [] })
                                    setDistributionModal(true)

                                }}
                            >
                                <AddIcon fontSize='small' />  Add Distribution Group
                            </Button>
                        </Col>)}
                    </Row>

                </Col>






                <Modal title="" visible={distributionModal} onCancel={() => {
                    setDistributionModal(false)
                    setDistributionGroup(null)

                }} footer={null} width={'60%'} style={{ borderRadius: 40 }} >
                    <Row style={{ padding: 20 }}>
                        <Col {...fullMobile(24, 24)} style={{}}>
                            <Typography variant="h5" style={{ fontWeight: 500, color: '#404050' }}>
                                Create New Distribution Group
                            </Typography>
                            <Typography variant="subtitle1" style={{ fontWeight: 300, color: '#404050', paddingTop: 5 }}>
                                Select members below for distribution group.
                            </Typography>
                        </Col>
                        <Col span={12}>
                            <Row gutter={[12, 12]}>
                                <Col {...fullMobile(24, 24)} style={{ paddingTop: 20 }}>
                                    <Typography variant="body1" style={{ fontWeight: 500, color: '#404050' }}>
                                        Group Name
                                    </Typography>
                                    <OutlinedInput
                                        size="small"
                                        style={{ width: 300 }}
                                        id="outlined-adornment-amount"
                                        groupsRef={distributionGroup?.name}
                                        onBlur={(e) => {
                                            setDistributionGroup({ ...distributionGroup, name: e.target.value })
                                        }}
                                    />
                                </Col>
                                <Col {...fullMobile(24, 24)} style={{ paddingTop: 8 }}>
                                    <Typography variant="body1" style={{ fontWeight: 500, color: '#404050' }}>
                                        Description
                                    </Typography>
                                    <OutlinedInput
                                        size="small"
                                        multiline
                                        rows={3}
                                        style={{ width: 300 }}
                                        id="outlined-adornment-amount"
                                        defaultValue={distributionGroup?.description}
                                        onBlur={(e) => {
                                            setDistributionGroup({ ...distributionGroup, description: e.target.value })
                                        }}
                                    />
                                </Col>
                                <Col {...fullMobile(24, 24)} style={{ paddingTop: 20 }}>
                                    <Button variant='contained'
                                        color='primary'
                                        style={{ textTransform: 'none', backgroundColor: '#404050' }}
                                        onClick={async () => {

                                            console.log(distributionGroup)

                                            if (distributionGroup.name !== '') {

                                                const docRef = doc(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'distributionGroups', distributionGroup.key);
                                                await setDoc(docRef, distributionGroup).then(() => {
                                                    setDistributionModal(false)

                                                    //add to permissionTemplates
                                                    const newGroups = [...groups, distributionGroup]
                                                    setGroups([...newGroups])
                                                    setDistributionGroup(null)
                                                }).catch((error) => {
                                                    console.error("Error adding group: ", error);
                                                })
                                            }
                                            else {
                                                openSnack('error', 'Please enter a name for the group')
                                            }
                                        }}
                                    >Create Group</Button>

                                </Col>
                            </Row>
                        </Col>

                        <Col span={12}>
                            <Row gutter={[12, 12]}>
                                <Col {...fullMobile(24, 24)} style={{ paddingTop: 20 }}>
                                    <Typography variant="body1" style={{ fontWeight: 500, color: '#404050' }}>
                                        Select Members
                                    </Typography>
                                    <Autocomplete
                                        multiple
                                        disableCloseOnSelect
                                        id="tags-standard"
                                        options={combined}
                                        getOptionLabel={(option) => option.label}
                                        defaultValue={[]}
                                        onChange={(event, newValue) => {
                                            setDistributionGroup({ ...distributionGroup, members: newValue })
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                placeholder="Members"
                                            />
                                        )}
                                    />
                                </Col>
                            </Row>
                        </Col>



                    </Row>

                </Modal >

                <Modal title="" visible={addUserModal} onCancel={() => {
                    setAddUserModal(false)
                    setDistributionGroup(null)
                }} footer={null} width={screenWidth === 'xs' ? '80%' : '50%'} style={{ borderRadius: 40 }} >
                    <Row gutter={[4, 4]} align="middle" >
                        <Col style={{}}>
                            <Typography variant='h5' display={"inline"}>
                                {addExistingUser ? 'Add Existing Users' : 'Invite New User'}
                            </Typography>

                        </Col>
                        <Col style={{}}>

                            <Button variant='outlined'
                                display={"inline"}
                                color='primary'
                                size='small'
                                style={{
                                    textTransform: 'none',
                                    marginLeft: 15,
                                    color: addExistingUser ? 'white' : '#404050',
                                    backgroundColor: addExistingUser ? '#404050' : 'white',
                                    border: '1px solid #404050'
                                }}
                                onClick={async () => {
                                    setAddExistingUser(!addExistingUser)

                                }}
                            >
                                {addExistingUser ? 'Click to Invite New User' : 'Click to Add Existing Users'}
                            </Button>
                        </Col>
                        <Col {...fullMobile(24, 24)}  >

                            {addExistingUser &&
                                (<Row>
                                    <Col {...fullMobile(24, 24)}  >
                                        <Typography variant="body2" display="inline" fontWeight={600} fontSize={14} style={{ color: '#404050' }}>
                                            Account Users
                                        </Typography>
                                    </Col>

                                    <Col {...fullMobile(24, 24)}  >
                                        <Autocomplete
                                            multiple
                                            disableCloseOnSelect
                                            id="tags-standard"
                                            options={
                                                //combinedCompanyUsers that are not already in this account
                                                combinedCompany.filter((user) => {
                                                    return !combined.find((accountUser) => {
                                                        return accountUser.email === user.email && user.signedUp === true
                                                    })
                                                })
                                            }
                                            getOptionLabel={(option) => option.label}
                                            defaultValue={[]}
                                            onChange={(event, newValue) => {
                                                setAddUsers(newValue)
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    placeholder="Members"
                                                />
                                            )}
                                        />
                                    </Col>
                                    <Col {...fullMobile(24, 24)} style={{ paddingTop: 15 }}  >
                                        {addUsers.length > 0 && (<Button variant='contained'
                                            color='primary'
                                            style={{ textTransform: 'none', backgroundColor: '#404050', }}
                                            onClick={async () => {

                                                addUsers.map(async (u, i) => {
                                                    const userRef = doc(db, "users", u.id,);

                                                    if (!u.accounts.includes(userInfo.currentAccount)) {
                                                        await updateDoc(userRef, {
                                                            accounts: arrayUnion(userInfo.currentAccount)

                                                        })
                                                        console.log('added account to user')
                                                    }


                                                    if (!combined.map((d) => d.id).includes(u.id)) {
                                                        const item = { ...u, accounts: [...u.accounts, userInfo.currentAccount] }
                                                        combined.push(item);
                                                        setCombined([...combined])
                                                        const docRef = doc(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'staff', u.id);
                                                        delete u.accounts;
                                                        delete u.companies;
                                                        await setDoc(docRef, u)
                                                        console.log('added user to account')
                                                    }

                                                    console.log(u)







                                                    if (i + 1 === addUsers.length) {
                                                        setAddUserModal(false)
                                                        setAddUsers([])
                                                    }


                                                })


                                            }}
                                        >Add Users</Button>)}
                                    </Col>
                                </Row>)
                            }

                        </Col>

                        <Col {...fullMobile(24, 24)}  >
                            {!addExistingUser &&
                                (<Row >
                                    <Col {...fullMobile(24, 24)}  >
                                        <Typography variant="body2" display="inline" fontWeight={600} fontSize={14} style={{ color: '#404050' }}>
                                            Email Address
                                        </Typography>
                                    </Col>
                                    <Col {...fullMobile(24, 24)}  >
                                        <OutlinedInput
                                            size="small"
                                            style={{ width: 300 }}
                                            id="outlined-adornment-amount"
                                            value={invite.email}
                                            onChange={(e) => {
                                                setInvite({ ...invite, email: e.target.value })
                                            }}

                                        />
                                    </Col>
                                    {invite.found === undefined && (<Col {...fullMobile(24, 24)} style={{ paddingTop: 15 }}  >
                                        <Button variant='contained'
                                            color='primary'
                                            style={{ textTransform: 'none', backgroundColor: '#404050', }}
                                            onClick={async () => {
                                                checkUserExists(invite.email)

                                            }}
                                        >Add User</Button>
                                    </Col>)}
                                    {invite.found === false && (
                                        <Col {...fullMobile(24, 24)} style={{ paddingTop: 15 }} >
                                            <Typography variant="body2" display="inline" fontWeight={600} fontSize={14} style={{ color: '#404050', }}>
                                                First name
                                            </Typography>
                                        </Col>
                                    )}
                                    {invite.found === false && (
                                        <Col {...fullMobile(24, 24)}  >
                                            <OutlinedInput
                                                size="small"
                                                style={{ width: 300 }}
                                                id="outlined-adornment-amount"
                                                value={invite.firstName}
                                                onChange={(e) => {
                                                    setInvite({ ...invite, firstName: e.target.value })
                                                }}

                                            />
                                        </Col>
                                    )}
                                    {invite.found === false && (
                                        <Col {...fullMobile(24, 24)} style={{ paddingTop: 15 }}  >
                                            <Typography variant="body2" display="inline" fontWeight={600} fontSize={14} style={{ color: '#404050', }}>
                                                Last name
                                            </Typography>
                                        </Col>
                                    )}
                                    {invite.found === false && (
                                        <Col {...fullMobile(24, 24)}  >
                                            <OutlinedInput
                                                size="small"
                                                style={{ width: 300 }}
                                                id="outlined-adornment-amount"
                                                value={invite.lastName}
                                                onChange={(e) => {
                                                    setInvite({ ...invite, lastName: e.target.value })
                                                }}

                                            />
                                        </Col>
                                    )}
                                    {invite.found === false && (
                                        <Col {...fullMobile(24, 24)} style={{ paddingTop: 15 }}  >
                                            <Typography variant="body2" display="inline" fontWeight={600} fontSize={14} style={{ color: '#404050', }}>
                                                Company
                                            </Typography>
                                        </Col>
                                    )}
                                    {invite.found === false && (
                                        <Col {...fullMobile(24, 24)}  >
                                            <OutlinedInput
                                                size="small"
                                                style={{ width: 300 }}
                                                id="outlined-adornment-amount"
                                                value={invite.company}
                                                onChange={(e) => {
                                                    setInvite({ ...invite, company: e.target.value })
                                                }}

                                            />
                                        </Col>
                                    )}
                                    {invite.found === false && (
                                        <Col {...fullMobile(24, 24)} style={{ paddingTop: 15 }}  >
                                            <Typography variant="body2" display="inline" fontWeight={600} fontSize={14} style={{ color: '#404050', }}>
                                                Company Admin level
                                            </Typography>
                                        </Col>
                                    )}
                                    {invite.found === false && (
                                        <Col {...fullMobile(24, 24)}  >
                                            <Select size="small"
                                                notched={false}
                                                style={{ width: 300 }}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={invite.adminLevel || ''}
                                                label="Layer"
                                                onChange={(e) => {
                                                    setInvite({ ...invite, adminLevel: e.target.value })
                                                }}

                                            >
                                                <MenuItem value={'admin'}>Admin</MenuItem>
                                                <MenuItem value={'standard'}>Standard</MenuItem>

                                            </Select>
                                        </Col>
                                    )}
                                    {invite.found === false && (
                                        <Col {...fullMobile(24, 24)} style={{ paddingTop: 30 }}  >
                                            <Button variant='contained'
                                                color='primary'
                                                style={{ textTransform: 'none', backgroundColor: '#404050', }}
                                                onClick={async () => {

                                                    console.log(invite);

                                                    const checks = [
                                                        invite.firstName !== undefined && invite.firstName.length === 0 ? false : true,
                                                        invite.lastName !== undefined && invite.lastName.length === 0 ? false : true,
                                                        invite.email !== undefined && invite.email.length === 0 ? false : true,
                                                    ]

                                                    console.log(checks)
                                                    if (invite.firstName !== undefined && invite.firstName.length === 0) {
                                                        openSnack('error', "First name required!")
                                                    }
                                                    if (invite.lastName !== undefined && invite.lastName.length === 0) {
                                                        openSnack('error', "Last name required!")
                                                    }
                                                    if (invite.email !== undefined && invite.email.length === 0) {
                                                        openSnack('error', "Email required!")
                                                    }

                                                    if (checks.includes(false)) {
                                                        return
                                                    }

                                                    const lowerEmail = invite.email.toLowerCase()

                                                    const user = {
                                                        accounts: [userInfo.currentAccount],
                                                        adminLevel: invite.adminLevel || 'standard',
                                                        companies: [userInfo.currentCompany],
                                                        company: invite.company || '',
                                                        currentAccount: userInfo.currentAccount,
                                                        currentCompany: userInfo.currentCompany,
                                                        displayName: '',
                                                        email: lowerEmail,
                                                        firstName: invite.firstName || '',
                                                        initialCompany: userInfo.currentCompany,
                                                        invited: true,
                                                        lastName: invite.lastName || '',
                                                        signedUp: false,
                                                        id: uuid().substring(0, 15),
                                                        label: invite.firstName || '' + ' ' + invite.lastName || '',

                                                    }

                                                    const res = await sendInvite(user, account, userInfo, lowerEmail)
                                                    if (res === 'success') {


                                                        const docRef = doc(db, "users", user.id);
                                                        await setDoc(docRef, user).then(async () => {
                                                            const accountRef = doc(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'staff', user.id);
                                                            await setDoc(accountRef, { key: user.id, adminLevel: user.adminLevel || 'standard', flDistribution: false }).then(async () => {
                                                                const accountRef = doc(db, "clients", userInfo.currentCompany, 'staff', user.id);
                                                                await setDoc(accountRef, { id: user.id, adminLevel: user.adminLevel || 'standard' })

                                                            })
                                                        }).then(() => {
                                                            //add to combined
                                                            const newCombined = [...combined, user]
                                                            setCombined(newCombined)
                                                        })


                                                        openSnack('success', "Invite sent!")
                                                        setInvite({ ...invite, email: '', firstName: '', lastName: '', company: '', adminLevel: '' })
                                                        setAddUserModal(false)
                                                    } else {
                                                        openSnack('error', "Invite failed!")
                                                    }
                                                    console.log(res)




                                                }}
                                            >Send Invite</Button>
                                        </Col>
                                    )}
                                </Row>
                                )
                            }
                        </Col>


















                    </Row>
                </Modal>






            </Row >
        </div >
    );
}