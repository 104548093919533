import { OutlinedInput, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import React from 'react';

export default class FishItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            type: this.props.type,
            item: this.props.item,
            loading: true
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }

    }
    componentDidMount() {
        const { userInfo } = this.state;
    }




    updateItem = (boo, val) => {
        const { item } = this.state;
        item[boo] = val;

        this.setState({ item })
        this.props.updateEntireItem(item)
    }

    render() {

        const { item, screenWidth, type, loading } = this.state;




        return (
            <Row gutter={[screenWidth === 'xs' ? 4 : 24, screenWidth === 'xs' ? 4 : 24]} align="middle">


                <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                    <Typography variant="body1">Fish Species</Typography>
                </Col>
                <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                    <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"

                        value={item.fishSpecies}
                        onChange={(e) => {
                            this.updateItem(`fishSpecies`, e.target.value)
                        }} />
                </Col>
              
                <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                    <Typography variant="body1">State #</Typography>
                </Col>
                <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                    <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                        multiline={true}
                        rows={4}
                        value={item.fishAdditional}
                        onChange={(e) => {
                            this.updateItem(`fishAdditional`, e.target.value)
                        }} />
                </Col>






            </Row>
        );
    }
} 