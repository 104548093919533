import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Col, Row } from 'antd';
import { Icon, IconButton, Input, TextField } from '@mui/material';
import UnitsSelect from './drawerComponents/unitsSelect';
import ParameterSelect from './drawerComponents/parameterSelect';
import StringInput from './drawerComponents/stringInput';
import TimezonePicker from './drawerComponents/timezonePicker';
import ProbeTypeSelect from './drawerComponents/probeTypeSelect';
import { getTidePreviewData } from './tidalComponents/tidalFunction';
import moment from 'moment-timezone';

import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faSpinner } from '@fortawesome/free-solid-svg-icons';




const PreviewChartModal = ({


    currentStream,
    openSnack,
    previewChart,
    setPreviewChart,
    userInfo,
    screenWidth
}) => {

    const handleOpen = () => setPreviewChart(true);
    const handleClose = () => setPreviewChart(false);
    const [endDate, setEndDate] = useState(moment().format('YYYYMMDD'));
    const [datum, setDatum] = useState('MLLW');
    const [units, setUnits] = useState('english');
    const [format, setFormat] = useState('json');
    const [tz, setTz] = useState('LST');
    const [loadignChart, setLoadingChart] = useState(true);
    const [chart, setchart] = useState({
        title: {
            text: undefined
        },
        credits: {
            enabled: false
        },
        xAxis: {
            type: 'datetime',
            dateTimeLabelFormats: {
                millisecond: '%I:%M %p<br>%m/%d/%y',
                second: '%I:%M %p<br>%m/%d/%y',
                minute: '%I:%M %p<br>%m/%d/%y',
                hour: '%I:%M %p<br>%m/%d/%y',
                day: '%I:%M %p<br>%m/%d/%y',
                week: '%I:%M %p<br>%m/%d/%y', 
                month: '%I:%M %p<br>%m/%d/%y',
                year: '%I:%M %p<br>%m/%d/%y'
            },
            title: {
                text: 'Date',
                style: {
                    fontSize: '12px',  // Increase size as needed
                    fontWeight: '700',  // Set font weight to 500
                    color: 'black'
                }

            },
            labels: {
                style: {
                    fontSize: '12px',  // Increase size as needed
                    fontWeight: '700'  // Set font weight to 500
                }
            },
        },
        tooltip: {
            shared: true,
            crosshairs: true,

        },
        yAxis: {
            title: {
                text: 'Height in feet (MLLW)',
                style: {
                    fontSize: '12px',  // Increase size as needed
                    fontWeight: '700',  // Set font weight to 500
                    color: 'black'
                }
            },
            labels: {
                style: {
                    fontSize: '12px',  // Increase size as needed
                    fontWeight: '700'  // Set font weight to 500
                }
            },
        },
        series: []
    })
    const chartComponent = useRef(null);

    useEffect(() => {
        console.log(previewChart)
        getInitialTideData(previewChart, endDate);
        setLoadingChart(true);
    }, [previewChart])

    async function getInitialTideData(previewChart, date) {
        const data = await getTidePreviewData(userInfo, previewChart, date, datum, units, format, tz);
        console.log(data.map((item) => { return ({ x: Number(moment(item.time, 'YYYY-MM-DD HH:mm').format('x')), y: Number(item["water_level"]) }) }))
        const observedSeries = {
            type: 'spline',
            name: 'Observed',
            color: '#F44336',
            lineWidth: 3,
            data: data.map((item) => { return ({ x: Number(moment(item.time, 'YYYY-MM-DD HH:mm').format('x')), y: Number(item["water_level"]) }) })
        };
        const predictedSeries = {
            type: 'spline',
            name: 'Predicted',
            color: '#1E88E5',
            lineWidth: 3,
            data: data.map((item) => { return ({ x: Number(moment(item.time, 'YYYY-MM-DD HH:mm').format('x')), y: Number(item["predictions"]) }) })
        };
        //sort data by date
        observedSeries.data.sort((a, b) => a.x - b.x);
        predictedSeries.data.sort((a, b) => a.x - b.x);

        const updatedChart = {
            ...chart,
            series: [observedSeries, predictedSeries]
        }

        setchart(updatedChart);
        setLoadingChart(false);
        console.log(updatedChart)

    }


    const nextDay = () => {
        const newDate = moment(endDate, 'YYYYMMDD').add(1, 'days').format('YYYYMMDD');
        setEndDate(newDate);
        setLoadingChart(true);
        getInitialTideData(previewChart, newDate);
    }

    const previousDay = () => {
        const newDate = moment(endDate, 'YYYYMMDD').subtract(1, 'days').format('YYYYMMDD');
        setEndDate(newDate);
        setLoadingChart(true);
        getInitialTideData(previewChart, newDate);
    }


    return (
        <div>
            <Modal
                open={previewChart !== false}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{

                }}

                slotProps={{
                    backdrop: {
                        style: {
                            backgroundColor: 'transparent'
                        }
                    }
                }}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '80%',
                    bgcolor: 'background.paper',
                    border: '1px solid rgba(0, 0, 0, .3)',
                    boxShadow: 24,
                    p: screenWidth === 'xs' ? 1 : 4,
                    borderRadius: '24px',
                    boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.75)',
                }}>
                    <Row>
                        <Col span={20}>
                            <Typography id="modal-modal-title" variant="h6" fontWeight={700} fontSize={28} component="h2">
                                NOAA Observed Water Levels
                            </Typography>
                        </Col>
                        <Col span={4} style={{ display: ' inline' }}>
                            <IconButton aria-label="close" onClick={nextDay} style={{ float: 'right' }}>
                                <FontAwesomeIcon icon={faChevronRight} size="lg" />
                            </IconButton>
                            <IconButton aria-label="close" onClick={previousDay} style={{ float: 'right' }}>
                                <FontAwesomeIcon icon={faChevronRight} size="lg" style={{ transform: 'rotate(180deg)' }} />
                            </IconButton>




                        </Col>
                    </Row>

                    <Typography id="modal-modal-description" variant="caption" fontSize={13} sx={{ mt: 2 }}>
                        Observed at <Typography variant="caption" fontWeight={700} fontSize={13} sx={{ display: 'inline' }}>
                            {`${previewChart?.stationID} `}
                        </Typography>
                        {previewChart?.name}
                    </Typography>

                    {loadignChart ?
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <Typography variant="caption" fontSize={13} sx={{ mt: 2 }}>
                                Loading NOAA Station
                                <FontAwesomeIcon icon={faSpinner} size="xl" spin
                                    style={{
                                        color: '#3f51b5',
                                        marginLeft: '10px'
                                    }} />
                            </Typography>
                        </div>
                        :

                        <HighchartsReact ref={chartComponent} highcharts={Highcharts} options={chart} />
                    }




                </Box>
            </Modal>
        </div >
    );
}

export default PreviewChartModal;
