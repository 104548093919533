import axios, * as others from 'axios';
import TidalParameters from '../../../extra/tidalParameters';
import moment from 'moment-timezone';
import uuid from 'react-uuid';
import { SpeedUnit, convertUnits } from 'measurement-unit-converter';
import convert from 'convert-units';

export const getObscapeWeatherData = async (userInfo, stream, from, to) => {
    try {
        const proxyurl = "https://mycorslake.herokuapp.com/";
        const apiUrl = `https://us-central1-aquasource3.cloudfunctions.net/getUndergroundData`;
        const username = stream?.userName;
        const apiKey = stream?.apiKey;
        const obscapeUrl = `https://www.obscape.com/portal/api/v3/api?username=${username}&key=${apiKey}`

        if (!apiUrl) return;
        const response = await axios.get(proxyurl + obscapeUrl);


        const deviceId = stream?.deviceId;
        if (!deviceId) return 'No device ID provided';

        const matchedItem = response.data.find(item => item.devices.weather.serial === deviceId);

        if (matchedItem) {




            const dataResponse = await axios.get(proxyurl + obscapeUrl, {
                params: {
                    station: matchedItem.id,
                    from: from,
                    to: to,
                    tz: 'local',
                }
            });



            return dataResponse?.data?.data;
        } else {
            console.log('No matching device found');
            return [];
        }
    }
    catch (error) {
        console.log('error', error)
        return []
    }
}
export const checkStatusForObscapeWeatherStations = async (streams) => {
    try {
        const proxyurl = "https://mycorslake.herokuapp.com/";

        const promises = streams.map(async (stream) => {
            try {
                const obscapeUrl = `https://www.obscape.com/portal/api/v3/api?username=${stream?.userName}&key=${stream?.apiKey}`;
                const response = await axios.get(proxyurl + obscapeUrl);

                const deviceId = stream?.deviceId;
                if (!deviceId) {
                    console.error('No device ID provided');
                    return { ...stream, status: false };
                }

                const matchedItem = response.data.find(item => item.devices.weather.serial === deviceId);
                const charged = matchedItem?.devices?.weather?.charged === "1" ? true : false;
                if (matchedItem && matchedItem.devices.weather.online === '1') {
                    const from = moment().subtract(2, 'hours').format('YYYY-MM-DDTHH:mm:ss');
                    const to = moment().format('YYYY-MM-DDTHH:mm:ss');
                    console.log(matchedItem)
                    const dataResponse = await axios.get(`${proxyurl + obscapeUrl}&station=${matchedItem.id}&from=${from}&to=${to}&tz=local`);
                    const data = dataResponse?.data?.data;
                    console.log(data);

                    // Getting the last recorded date
                    const lastDataPoint = data && data.length > 0 ? data[data.length - 1] : null;
                    const lastRecorded = lastDataPoint ? lastDataPoint.tstr : null;
                    const lastRecordedMoment = lastRecorded ? moment(lastRecorded, 'YYYY-MM-DD HH:mm').format('x') : null;


                    return { ...stream, status: true, charged, lastRecorded: lastRecordedMoment, latitude: matchedItem?.latitude, longitude: matchedItem?.longitude, lastData: lastDataPoint };
                } else {
                    return { ...stream, status: false, charged }; // Device is offline or not found
                }
            } catch (err) {
                console.error(err);
                return { ...stream, status: false, charged: false }; // Error occurred, assuming device is offline
            }
        });

        // Wait for all promises to resolve
        const updatedStreams = await Promise.all(promises);
        console.log(updatedStreams);

        // Return the updated streams
        return updatedStreams;
    }
    catch (error) {
        console.error('error', error);
        return [];
    }
}

export const uploadObscapeData = async (userInfo, stream, data) => {
    try {
        const proxyurl = "https://mycorslake.herokuapp.com/";

        console.log(userInfo)
        console.log(stream)
        console.log(data)

        const toSqlString = (value) => {
            if (value === undefined || value === null) {
                return 'null';
            }
            return `'${value}'`;
        };

        const sqlData = data.map((item) => {
            const time = toSqlString(moment(item?.tstr, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm:ss'));
            const solar = item?.solar
            const precipitation = item?.precipitation
            const strikes = item?.strikes
            const strikeDistance = item?.strikeDistance
            const windSpeed = item?.windSpeed
            const windDirection = item?.windDirection
            const gustWindSpeed = item?.gustWindSpeed
            const gustWindDirection = item?.gustWindDirection
            const airTemperature = item?.airTemperature
            const vaporPressure = item?.vaporPressure
            const atmosphericPressure = item?.atmosphericPressure
            const relativeHumidity = item?.relativeHumidity
            const humiditySensorTemperature = item?.humiditySensorTemperature
            const xOrientation = item?.xOrientation
            const yOrientation = item?.yOrientation
            const NorthWindSpeed = item?.NorthWindSpeed
            const EastWindSpeed = item?.EastWindSpeed
            const gustNorthWindSpeed = item?.gustNorthWindSpeed
            const gustEastWindSpeed = item?.gustEastWindSpeed
            const lat = item?.lat
            const lon = item?.lon
            const VBAT = item?.VBAT
            const VSOL = item?.VSOL
            const TEMP = item?.TEMP
            const HUM = item?.HUM
            const PATM = item?.PATM
            const Xtilt = item?.Xtilt
            const Ytilt = item?.Ytilt
            const CSQ = item?.CSQ
            const RAT = item?.RAT
            const oper = toSqlString(item?.oper)
            const source = toSqlString(item?.source)
            const invalid = toSqlString(item?.invalid)
            const tstr = toSqlString(item?.tstr)
            const obscapestation = toSqlString(stream.deviceId)
            const streamid = toSqlString(stream.id)
            const id = toSqlString(uuid())
            const account = toSqlString(userInfo.currentAccount)
            const company = toSqlString(userInfo.currentCompany)
            const archived = toSqlString('false')
 
            return `(${time},${solar},${precipitation},${strikes},${strikeDistance},${windSpeed},${windDirection},${gustWindSpeed},${gustWindDirection},${airTemperature},${vaporPressure},${atmosphericPressure},${relativeHumidity},${humiditySensorTemperature},${xOrientation},${yOrientation},${NorthWindSpeed},${EastWindSpeed},${gustNorthWindSpeed},${gustEastWindSpeed},${lat},${lon},${VBAT},${VSOL},${TEMP},${HUM},${PATM},${Xtilt},${Ytilt},${CSQ},${RAT},${oper},${source},${invalid},${tstr},${obscapestation},${streamid},${id},${account},${company},${archived})`
        })

        const sqlString = sqlData.join(',');

        console.log(sqlString)

        const insertQuery = `INSERT INTO obscape_new2 (  time, solar, precipitation, strikes, strikeDistance, windSpeed, windDirection, gustWindSpeed, gustWindDirection, airTemperature, vaporPressure, atmosphericPressure, relativeHumidity, humiditySensorTemperature, xOrientation, yOrientation, NorthWindSpeed, EastWindSpeed, gustNorthWindSpeed, gustEastWindSpeed, lat, lon, VBAT, VSOL, TEMP, HUM, PATM, Xtilt, Ytilt, CSQ, RAT, oper, source, invalid, tstr, obscapestation, streamid, id, account, company, archived
        ) VALUES ${sqlString} ON CONFLICT DO NOTHING; `

        console.log(insertQuery)
        await axios.post(proxyurl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', { raw: insertQuery });
        return 'success'
    }
    catch (error) {
        console.log('error', error)
        return 'error'
    }
}


export const convertTemperature = (tempCelsius, unitsSystem) => {

    return Number(unitsSystem === "US/Imperial"
        ? convert(tempCelsius).from('C').to('F').toFixed(1)
        : tempCelsius.toFixed(1));
};

export const convertPrecipitation = (precipitationmm, unitsSystem) => {
    return Number(unitsSystem === "US/Imperial"
        ? convert(precipitationmm).from('mm').to('in').toFixed(2)
        : precipitationmm.toFixed(1));
};

// For Wind Speed and Humidity, make sure to return numbers if your chart expects numerical values
export const convertWindSpeed = (speedMetersPerSecond,unitsSystem) => {
    const speed = unitsSystem === "US/Imperial"
        ? convertUnits(speedMetersPerSecond, SpeedUnit.METER_PER_SECOND, SpeedUnit.MILE_PER_HOUR).toFixed(1)
        : speedMetersPerSecond.toFixed(1);
    return Number(speed); // Convert back to number if needed
};

export const convertHumidity = (humidity, unitsSystem) => {
    const numberHumi = Number(humidity * 100).toFixed(1);
    return Number(numberHumi); // Convert back to number if needed
};





