import { collection, getDocs, getDoc, addDoc, updateDoc, doc, getFirestore, deleteDoc, writeBatch, setDoc } from 'firebase/firestore';
import { getStorage, ref, getMetadata, getDownloadURL, uploadBytes } from "firebase/storage";
import uuid from 'react-uuid';



export const uploadFile = async (file, path) => {
    const storage = getStorage();
    try {
      const storageRef = ref(storage, path);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);
      const size = file.size;
      return ({ downloadURL, size });
    } catch (error) {
      console.error('Error uploading file:', error);
      return null;
    }
  };















