import React, { useEffect, useState } from "react";


import { faBox, faBucket, faCheck, faFish, faFlask, faListCheck, faMagnifyingGlass, faPlus, faWheatAlt, faWrench, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, ButtonGroup, Divider, FormControl, Grid, IconButton, Input, InputLabel, MenuItem, Modal, OutlinedInput, Select, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import { makeStyles } from "@mui/styles";
import StocksSelect from "../../../../../../tasks/components/drawercomponents/stockSelect";
import Temperature from "./weatherComponents/temperature";
import Rain from "./weatherComponents/rain";
import DailyRainfall from "./weatherComponents/dailyRainfall";
import Wind from "./weatherComponents/wind";
import LeadStaff from "./additionalInformation/leadStaff";
import OtherStaff from "./additionalInformation/otherStaff";
import Days from "./additionalInformation/days";
import HideOptions from "./hideOtherInformation";



const AdditionalInformation = (
    {
        record,
        items,
        setItems,
        screenWidth,
        openSnack,
        userInfo,
        updateRecord,
        stocks,
        setStocks,
        accounts,
        accountUsers,
        log,
        setDisableDrag

    }



) => {
    const [isFocused, setIsFocused] = useState(false);

    const props = {
        record,
        items,
        setItems,
        screenWidth,
        openSnack,
        userInfo,
        updateRecord,
        stocks,
        setStocks,
        accounts,
        accountUsers,
        log,
        setDisableDrag
    };


    return (
        <>


            <Grid container spacing={1}>
                <Grid item xs={12} md={12}
                    style={{
                        /* flex and space-between */
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}>
                    <Typography variant="h6" component="div" color={'rgba(0,0,0,0.8)'} fontWeight={800} fontSize={15} style={{ display: 'inline' }}>
                        Additional Information
                    </Typography>
                    <HideOptions  {...props} val={'showAdvanced'} />
                </Grid>
                <Grid item xs={12} md={record?.showMap === false ? 6 : 12}>
                    <LeadStaff  {...props} />
                </Grid>
                <Grid item xs={12} md={record?.showMap === false ? 6 : 12}>
                    <OtherStaff  {...props} />
                </Grid>
                <Grid item xs={12} md={record?.showMap === false ? 6 : 12}>
                    <Days  {...props} />
                </Grid>




            </Grid>






        </>



    );
};

export default AdditionalInformation;
