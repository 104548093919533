import React, { useState, useEffect } from "react";
import { Box, Button, Grid, Typography } from '@mui/material';
import axios from "axios";
import { fetchWeather } from "../../utils/functions";

const LogWeather = ({
    account,
    setLog,
    log,
    screenWidth,
}) => {
    const [weather, setWeather] = useState(null);
    const [loading, setLoading] = useState(false);


    const [lastFetchedDate, setLastFetchedDate] = useState(null);

    useEffect(() => {
        if (!log?.weather) {
            console.log('load weather');
            setLoading(true);
            getWeather();
        }
    }, [log?.weather]);

    useEffect(() => {
        if (log?.startDate && log.startDate !== lastFetchedDate) {
            console.log('load weather for new start date');
            setLoading(true);
            getWeather();
        }
    }, [log?.startDate]);

    async function getWeather() {
        if (log?.startDate !== lastFetchedDate) {
            let weatherData = await fetchWeather(log, account);
            console.log(weatherData);
            if (weatherData) {
                const newLog = { ...log, weather: weatherData };
                setWeather(weatherData);
                setLog(newLog);
                setLastFetchedDate(log.startDate);
                setLoading(false);
            }

        }
    }

    const toSentenceCase = (str) => {
        return str?.charAt(0).toUpperCase() + str?.slice(1);
    };

    var dict = {
        '01d': 'wi-day-sunny',
        '02d': 'wi-day-cloudy',
        '03d': 'wi-cloud',
        '04d': 'wi-cloudy',
        '09d': 'wi-showers',
        '10d': 'wi-day-rain-mix',
        '11d': 'wi-thunderstorm',
        '13d': 'wi-snow',
        '50d': 'wi-fog',
        '01n': 'wi-night-clear',
        '02n': 'wi-night-alt-cloudy',
        '03n': 'wi-night-alt-cloudy-high',
        '04n': 'wi-cloudy',
        '09n': 'wi-night-alt-sprinkle',
        '10n': 'wi-night-alt-showers',
        '11n': 'wi-night-alt-thunderstorm',
        '13n': 'wi-night-alt-snow',
        '50n': 'wi-night-fog'
    };

    console.log(log?.weather)
    if (loading) {
        return (
            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 5 }}>
                <Typography variant="body2" color="black" fontSize={16} fontWeight={600} align="center" sx={{ mt: 3 }}>
                    Loading weather...
                </Typography>
            </Box>
        )
    }

    else {
        return (
            <div>
                {!['future', 'no_history'].includes(log?.weather) ? (
                    <Box style={{ paddingTop: 5 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={4} sm={4}>
                                <Box style={{
                                    paddingBottom: 5,
                                    textAlign: 'center',
                                    paddingTop: 10,
                                    alignContent: 'center',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    display: 'flex',
                                }}>
                                    <i style={{
                                        marginTop: 10,
                                        fontSize: screenWidth === 'xs' ? '5rem' : '7rem', // Adjust the size as needed

                                    }} id='my-node' className={`wi ${dict[log?.weather?.icon]}`}></i>
                                </Box>
                            </Grid>
                            <Grid item xs={8} sm={8}>
                                <Typography variant="body1" color="black" fontSize={screenWidth === 'xs' ? 14 : 16} fontWeight={400}>
                                    Temperature: <span style={{ fontWeight: 700 }}>{log?.weather?.temp}°F</span>
                                </Typography>
                                <Typography variant="body1" color="black" fontSize={screenWidth === 'xs' ? 14 : 16} fontWeight={400}>
                                    Weather: <span style={{ fontWeight: 700 }}>{toSentenceCase(log?.weather?.description)}</span>
                                </Typography>
                                <Typography variant="body1" color="black" fontSize={screenWidth === 'xs' ? 14 : 16} fontWeight={400}>
                                    Humidity: <span style={{ fontWeight: 700 }}>{log?.weather?.humidity}%</span>
                                </Typography>
                                <Typography variant="body1" color="black" fontSize={screenWidth === 'xs' ? 14 : 16} fontWeight={400}>
                                    Wind: <span style={{ fontWeight: 700 }}>{log?.weather?.wind} mph</span>
                                </Typography>
                            </Grid>
                        </Grid>


                    </Box>
                ) : (
                    <Typography variant="body2" color="black" fontSize={16} fontWeight={600} align="center" sx={{ mt: 3 }}>
                        {!log?.weather ? 'Loading weather...' : log?.weather === 'future' ? 'Weather data is not available for future logs.' : 'No historical weather data for this date.'}
                    </Typography>
                )}
            </div>
        );
    }
};

export default LogWeather;
