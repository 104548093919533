import React, { useEffect, useState } from "react";
import {
    Box, Grid, Typography, OutlinedInput, FormControl, InputLabel, Select, MenuItem,
    InputAdornment
} from '@mui/material';


const Temperature = (
    {
        record,
        items,
        setItems,
        screenWidth,
        openSnack,
        userInfo,
        updateRecord,
        stocks,
        setStocks,
        accounts





    }

) => {
    const [isFocused, setIsFocused] = useState(false);

    const options = [
        { label: "F", key: "F" },
        { label: "C", key: "C" },
    ];



    const handleTemperatureChange = (e) => {
        const value = e.target.value;
        updateRecord({ ...record, temp: value === '' ? '' : value === '0' ? '0' : Number(value) });
    };

    return (
        <>
            <Typography variant="h6" component="div" color={'rgba(0,0,0,0.8)'} fontWeight={700} fontSize={13}>
                Temperature
            </Typography>
            <Grid container alignItems="flex-end" style={{ marginBottom: 10 }}>
                <Grid item xs={7}>

                    <OutlinedInput
                        type="number"
                        size="small"
                        value={record?.temp || ''}

                        onChange={handleTemperatureChange}
                        placeholder="Temp"
                        fullWidth
                        style={{
                            borderRadius: 4,
                            fontSize: 14,
                            backgroundColor: 'white',
                        }}
                    />
                </Grid>
                <Grid item xs={5}>
                    <Select
                        labelId="unit-select-label"
                        id="unit-select"
                        value={record?.temperatureUnits || ''}
                        onChange={(e) => {
                            updateRecord({ ...record, temperatureUnits: e.target.value || null })
                        }}
                        displayEmpty
                        size="small"
                        style={{
                            fontSize: 14,
                            maxHeight: 40, // Align the text to right
                            backgroundColor: 'white'
                        }}
                        placeholder="units"
                    >
                        {options.map((option, index) => (
                            <MenuItem key={index} value={option.key}>{option.label}</MenuItem>
                        ))}
                    </Select>
                </Grid>
            </Grid>

        </>
    );
};

export default Temperature;
