import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Container,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import ProbeParameters from "../../extra/probeParams";
import SearchIcon from '@mui/icons-material/Search';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faLocationDot, faMapPin, faTrash, faVial } from "@fortawesome/free-solid-svg-icons";
import ConditionOptions from "./conditionOptions";
import ValueCondition from "./conditions/valueCondition";
import TagCondition from "./conditions/tagCondition";







const Conditions = ({ selectedItems, setSelectedItems, items, setItems, codes }) => {
    const [searchQuery, setSearchQuery] = useState('');


    const handleAddCondition = (itemKey, type) => {
        const newSelectedItems = { ...selectedItems };
        if (newSelectedItems.hasOwnProperty(itemKey)) {
            newSelectedItems[itemKey].conditions = [
                ...(newSelectedItems[itemKey].conditions || []),
                { label: 'Condition 1', type: '>', value: '0', conditionType: type, tags: [] }
            ];
        }
        setSelectedItems(newSelectedItems);
    };

    const deleteCondition = (itemKey) => {
        const newSelectedItems = { ...selectedItems };
        if (newSelectedItems.hasOwnProperty(itemKey) && newSelectedItems[itemKey].conditions?.length > 0) {
            newSelectedItems[itemKey].conditions = newSelectedItems[itemKey].conditions.slice(0, -1);
        }
        setSelectedItems(newSelectedItems);
    };









    return (
        <Col span={24} style={{ paddingBottom: 10, borderRight: '1px solid #0000001f' }}>
            <List style={{ maxHeight: '85vh', overflow: 'auto', paddingTop: 0 }}>
                {Object.values(selectedItems).filter((f) => f.type === 'parameter')?.map((item, index) => {
                  
                    return (
                        <div style={{ border: '2px solid #0000001f', }}>
                            <ListItem key={index} style={{ padding: 0, paddingLeft: 8, borderBottom: '1px solid #0000001f' }}>
                                <Box
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        width: '100%',
                                    }}
                                >
                                    <Typography variant="body1" color={'black'} fontWeight={600} fontSize={14} style={{ paddingBottom: 8, paddingTop: 8 }}>
                                        {item.label}
                                    </Typography>
                                    <ConditionOptions handleAddCondition={handleAddCondition} item={item} />
                                </Box>
                            </ListItem>
                            { 
                                item?.conditions.map((condition, conditionIndex) => {
                                    return (
                                        <ListItem key={index} sx={{

                                        }} style={{ padding: 0, paddingLeft: 8, borderBottom: '1px solid #0000001f' }}>
                                            <Box
                                                sx={{
                                                    p: 1,
                                                    pl: 1
                                                }} style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    width: '100%'
                                                }}>
                                                {condition.conditionType === 'value' && <ValueCondition condition={condition} item={item} conditionIndex={conditionIndex} selectedItems={selectedItems} setSelectedItems={setSelectedItems} />}
                                                {condition.conditionType === 'tag' && <TagCondition condition={condition} item={item} codes={codes} conditionIndex={conditionIndex} selectedItems={selectedItems} setSelectedItems={setSelectedItems} />}





                                                <IconButton onClick={() => deleteCondition(item.key)}>
                                                    <Tooltip title="Delete">
                                                        <FontAwesomeIcon icon={faTrash} size="sm" style={{ color: '#9e9e9e' }} />
                                                    </Tooltip>
                                                </IconButton>

                                            </Box>
                                        </ListItem>
                                    )
                                })
                            }
                        </div>
                    )
                })}
                {Object.values(selectedItems).filter((f) => f.type === 'parameter')?.length === 0 && (
                    <Typography variant="body1" color={'black'} fontWeight={500} fontSize={13} style={{ marginLeft: 10, display: 'inline', paddingBottom: 8, paddingTop: 8 }}>
                        No items selected
                    </Typography>
                )}
            </List>
        </Col>
    );
};

export default Conditions;
