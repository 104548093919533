import { collection, doc, getCountFromServer, getDocs, getFirestore, query, setDoc, where, writeBatch } from "firebase/firestore"
import uuid from "react-uuid";
import Pica from 'pica';
import { getStorage, ref, getMetadata, getDownloadURL, uploadBytes, uploadBytesResumable } from "firebase/storage";
import exifr from 'exifr';
import moment from 'moment-timezone';



// This function resizes one image to small, medium, and large, then uploads them
// This function resizes one image to small, medium, and large, then uploads them
async function resizeAndUpload(file, userInfo, currentParentFolder, fileId, setUploadProgress) {
    const pica = Pica();
    const storage = getStorage();

    const sizes = [
        { name: 'small', maxSize: 320 },
        { name: 'medium', maxSize: 640 },
        { name: 'large', maxSize: 1280 },
    ];

    const fileType = file.type.split('/')[1];
    const image = new Image();
    image.src = URL.createObjectURL(file);

    await new Promise((resolve, reject) => {
        image.onload = resolve;
        image.onerror = reject;
    });

    const originalAspectRatio = image.width / image.height;

    const uploadPromises = sizes.map(({ name, maxSize }) => {
        return new Promise(async (resolve, reject) => {
            let targetWidth, targetHeight;
            if (originalAspectRatio > 1) {
                targetWidth = maxSize;
                targetHeight = Math.round(maxSize / originalAspectRatio);
            } else {
                targetWidth = Math.round(maxSize * originalAspectRatio);
                targetHeight = maxSize;
            }

            // Create a canvas element for each size
            const canvas = document.createElement('canvas');
            canvas.width = targetWidth;
            canvas.height = targetHeight;

            await pica.resize(image, canvas, {
                unsharpAmount: 80,
                unsharpRadius: 0.6,
                unsharpThreshold: 2
            });

            const blob = await pica.toBlob(canvas, `image/${fileType}`, 0.90);

            const uniqueFileName = `${fileId}_${name}.${fileType}`;
            const fileRef = ref(storage, `files_new/${userInfo.currentCompany}/${uniqueFileName}`);
            const uploadTask = uploadBytesResumable(fileRef, blob);

            uploadTask.on('state_changed',
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setUploadProgress(prevProgress => ({
                        ...prevProgress,
                        [fileId]: {
                            ...prevProgress[fileId],
                            [name]: progress
                        }
                    }));
                },
                (error) => {
                    reject(error);
                },
                async () => {
                    const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                    resolve({ [name]: downloadURL });
                }
            );
        });
    });

    const urls = await Promise.all(uploadPromises);

    const fileData = Object.assign({}, ...urls, {
        account: userInfo.currentAccount,
        company: userInfo.currentCompany,
        created_date: Number(moment().format('x')),
        is_public: true,
        tags: [],
        status: 'active',
        creator: userInfo.id,
        parent_folder_id: currentParentFolder?.folder_id || null,
        name: file.name,
        fileType: file.type,
    });

    return fileData;
}

async function uploadNonPicture(file, userInfo, currentParentFolder, fileId, setUploadProgress) {
    const storage = getStorage();

    return new Promise(async (resolve, reject) => {
        const uniqueFileName = `${fileId}.${file.type.split('/')[1]}`;
        const fileRef = ref(storage, `files_new/${userInfo.currentCompany}/${uniqueFileName}`);
        const uploadTask = uploadBytesResumable(fileRef, file);

        uploadTask.on('state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
               
                setUploadProgress(prevProgress => ({
                    ...prevProgress,
                    [fileId]: {
                        ...prevProgress[fileId],
                        path: progress
                    }// Updating progress for this specific file
                }));
            },
            (error) => {
                reject(error);
            },
            async () => {
                const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                let screenshotUrl = null;
                if (file.type.startsWith('video/')) {
                    const screenshotBlob = await captureVideoFrame(file);
                    screenshotUrl = await uploadScreenshot(screenshotBlob, userInfo, fileId);
                }
                const fileData = {
                    path: downloadURL,
                    account: userInfo.currentAccount,
                    company: userInfo.currentCompany,
                    created_date: Number(moment().format('x')),
                    is_public: true,
                    tags: [],
                    status: 'active',
                    creator: userInfo.id,
                    parent_folder_id: currentParentFolder?.folder_id || null,
                    name: file.name,
                    fileType: file.type,
                    screenshot: screenshotUrl,
                };
                resolve(fileData);
            }
        );
    });
}

async function captureVideoFrame(file) {
    return new Promise((resolve, reject) => {
        const video = document.createElement('video');
        video.preload = 'auto';
        video.src = URL.createObjectURL(file);

        video.addEventListener('loadedmetadata', () => {
            video.currentTime = 1; // Seek to 1 second into the video
        });

        video.addEventListener('seeked', () => {
            try {
                const canvas = document.createElement('canvas');
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
                canvas.toBlob(blob => resolve(blob), 'image/jpeg', 0.95);
            } catch (e) {
                reject(e);
            }
        });

        video.load();
    });
}

async function uploadScreenshot(screenshotBlob, userInfo, fileId) {
    const storage = getStorage();

    return new Promise(async (resolve, reject) => {
        const uniqueFileName = `screenshot_${fileId}.jpeg`; // Assuming screenshot is a JPEG image
        const screenshotRef = ref(storage, `screenshots/${userInfo.currentCompany}/${uniqueFileName}`);

        try {
            const uploadTaskSnapshot = await uploadBytes(screenshotRef, screenshotBlob);
            const downloadURL = await getDownloadURL(uploadTaskSnapshot.ref);
            resolve(downloadURL);
        } catch (error) {
            reject(error);
        }
    });
}







// This function will be responsible for handling file uploads and Firestore updates
export const createFilesFunction = async (newFiles, files, setFiles, currentParentFolder, userInfo, openSnack, setUploadProgress, psType, item_id) => {
    try {
        // Initialize Firestore
        const db = getFirestore();



        // Create placeholders for new files
        const placeholders = newFiles.map(file => {
            const fileId = uuid();
            return {
                file_id: fileId,
                account: userInfo.currentAccount,
                company: userInfo.currentCompany,
                created_date: Number(moment().format('x')),
                is_public: true,
                tags: [],
                status: 'active',
                creator: userInfo.id,
                parent_folder_id: psType === true ? 'psItem' : currentParentFolder?.folder_id || null,
                path: null,
                small: null,
                medium: null,
                large: null,
                name: file.name,
                isLoading: true, // This is the new flag
                fileType: file.type,
                item_id: item_id || null,
            };
        });

        // Add placeholders to the state immediately
        setFiles((prevFiles) => [...placeholders, ...prevFiles,]);

        // Map over newFiles to create an array of upload tasks
        const uploadTasks = newFiles.map(async (file, index) => {
            const fileId = placeholders[index].file_id;

            // Create a new promise to handle EXIF data extraction


            // Continue with resize and upload after getting EXIF data
            //if image type then resize and upload
            if (file.type.includes('image')) {
                return resizeAndUpload(file, userInfo, currentParentFolder, fileId, setUploadProgress).then(fileData => {

                    const filesRef = doc(db, "clients", userInfo.currentCompany, "files_new", fileId);
                    return setDoc(filesRef, { ...fileData, file_id: fileId, item_id: item_id || null }).then(() => {
                        return { ...fileData, file_id: fileId, isLoading: false, };
                    });
                });
            }
            const fileTypeCategory = file.type.split('/')[0]; // Get the first part of the MIME type (e.g., 'audio' from 'audio/mpeg')

            if (['audio', 'video', 'application'].includes(fileTypeCategory) || file.type === 'application/pdf') {
                return uploadNonPicture(file, userInfo, currentParentFolder, fileId, setUploadProgress).then(fileData => {
                    const filesRef = doc(db, "clients", userInfo.currentCompany, "files_new", fileId);
                    return setDoc(filesRef, { ...fileData, file_id: fileId, item_id: item_id || null }).then(() => {
                        return { ...fileData, file_id: fileId, isLoading: false };
                    });
                });
            }







        });

        // Wait for all uploads and Firestore document creations to complete
        const uploadedFiles = await Promise.all(uploadTasks);

        // Update the files state with the final data
        setFiles((prevFiles) => {
            return prevFiles.map(file => {
                const uploadedFile = uploadedFiles.find(uFile => uFile.file_id === file.file_id);
                // If the file has finished uploading, update its data
                return uploadedFile ? { ...file, ...uploadedFile } : file;
            });
        });
        //match the final state of files
        const lastFile = uploadedFiles[uploadedFiles.length - 1];




        openSnack('success', 'Files uploaded and created successfully');
        return lastFile;
    } catch (e) {
        openSnack('error', 'Error creating files');
    }
};

export const deleteFilesFunction = async (filesToDelete, userInfo, openSnack, files, setFiles) => {
    try {
        const db = getFirestore();
        const batch = writeBatch(db);

        // Queue all delete operations in the batch
        filesToDelete.forEach((file) => {
            const fileRef = doc(db, "clients", userInfo.currentCompany, "files_new", file.file_id);
            batch.update(fileRef, { status: 'deleted', deleted_date: new Date() });
        });

        // Commit the batch
        await batch.commit();

        // Filter out the deleted files from the current state
        const updatedFiles = files.filter(file =>
            !filesToDelete.some(deletedFile => deletedFile.file_id === file.file_id)
        );

        // Update the state with the filtered files

        setFiles(updatedFiles);

        openSnack('success', `${filesToDelete.length} file(s) deleted successfully.`);
    } catch (e) {
        openSnack('error', 'Error deleting file(s).');
    }
};






export const updateFileFunction = async (file, userInfo, setCurrentFile, openSnack, setFiles) => {
    try {
        const db = getFirestore();
        const fileRef = doc(db, "clients", userInfo.currentCompany, "files_new", file.file_id);

        // Update the file in Firestore
        await setDoc(fileRef, file, { merge: true });

        // Update the local state with the new file details
        setFiles((prevFiles) => {
            return prevFiles.map((p) => (p.file_id === file.file_id ? file : p));
        });

        // Optionally clear the current file or set it to the updated file
        setCurrentFile(file); // or setCurrentFile(null) if you wish to clear it

        openSnack('success', 'File updated successfully');
    } catch (e) {
        openSnack('error', 'Error updating file');
    }
};

export const updateFilesFunction = async (filesToUpdate, userInfo, openSnack, setFiles) => {
    try {
        const db = getFirestore();
        const batch = writeBatch(db);

        // Queue all update operations in the batch
        filesToUpdate.forEach((file) => {
            const fileRef = doc(db, "clients", userInfo.currentCompany, "files_new", file.file_id);
            batch.set(fileRef, file, { merge: true });
        });

        // Commit the batch
        await batch.commit();

        // Update the local state with the new files details
        setFiles((prevFiles) => {
            return prevFiles.map((prevFile) => {
                const updatedFile = filesToUpdate.find(p => p.file_id === prevFile.file_id);
                return updatedFile ? updatedFile : prevFile;
            });
        });

        openSnack('success', `${filesToUpdate.length} file(s) updated successfully.`);
    } catch (e) {
        openSnack('error', 'Error updating file(s)');
    }
};






export async function fetchFiles(userInfo, currentParentFolder, setFetchingFiles, openSnack) {
    try {
        const db = getFirestore();
        const ref = collection(db, "clients", userInfo.currentCompany, 'files_new');

        let queryRef;
        if (currentParentFolder) {
            queryRef = query(ref, where("account", "==", userInfo.currentAccount), where("parent_folder_id", "==", currentParentFolder.folder_id), where("status", "!=", 'deleted'));
        } else {
            queryRef = query(ref, where("account", "==", userInfo.currentAccount), where("parent_folder_id", "==", null), where("status", "!=", 'deleted'));
        }

        const snap = await getDocs(queryRef);
        const data = snap.docs.map((doc) => ({ ...doc.data(), file_id: doc.id }));

        openSnack('success', 'Files retrieved successfully');
        setFetchingFiles(false);
        return data;

    } catch (error) {
        openSnack('error', 'Error retrieving files');
        setFetchingFiles(false);
        return [];
    }
};

