import React, { useEffect, useRef, useState } from "react";


import { faCheckCircle, faCircle, faCopy } from '@fortawesome/free-regular-svg-icons';
import { faExclamation, faSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Autocomplete, Badge, Checkbox, CircularProgress, IconButton, Select, TextField, Tooltip, Typography } from '@mui/material';
import { Col } from 'antd';
import moment from 'moment-timezone';
import './taskList.css';
import WarningIcon from '@mui/icons-material/WarningAmber';


import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import uuid from "react-uuid";





import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import UnitsCell from "./rowComponents/unitsCell";
import ValueCell from "./rowComponents/valueCell";
import ParametersCell from "./rowComponents/parametersCell";
import DateCell from "./rowComponents/dateCell";
import SiteCell from "./rowComponents/siteCell";
import CodeCell from "./rowComponents/codeCell";
import { createDatabaseData, updateDatabaseData, updateTimeDatabaseRecored } from "../databaseFunctions";
import ProbeParameters from "../../../../../../../extra/probeParams";



const TableRow = ({ rowData, data, setData, fullData, headers, setCurrentCell, currentCell, parameters, unitsArray, stations, openSnack,
    setErrorModal, codes, duplicateData, deleteData, lastClickedIndex, setLastClickedIndex, keyDown, checkErrors, updatingData, setUpdatingData, userInfo }) => {
    const [row, setRow] = useState(rowData);

    useEffect(() => {
        const { errorsCount, warningsCount } = checkErrors(rowData);
        setRow({ ...rowData, errors: errorsCount, warnings: warningsCount, issues: errorsCount + warningsCount });
    }, [rowData]);


    const [issues, setIssues] = useState(0);


    useEffect(() => {
        const issues = getIssues(row);
        setIssues(issues);
    }, []);



    async function updateRow(newRow, type) {


        const { errorsCount, warningsCount } = checkErrors(newRow);
        const newRowItem = { ...newRow, errors: errorsCount, warnings: warningsCount, issues: errorsCount + warningsCount }
        setRow(newRowItem);

        //replace this row in data
        const index = fullData.findIndex((item) => item.id === newRow.id);
        const newData = [...fullData];
        newData[index] = newRowItem;
        newData[index].value = Number(newRowItem.value);
        console.log(newRowItem)
        setData(newData);

        console.log(newData)


        if (errorsCount === 0) {
            console.log(newRowItem)
            if (newRow.key === undefined) {
                console.log("CREATING NEW ROW")
                setUpdatingData(true)
                const createResponse = await createDatabaseData(newRow, 'node_data_new2', [...parameters, ...ProbeParameters], stations, codes, unitsArray, openSnack, setUpdatingData, userInfo);
                const index = fullData.findIndex((item) => item.id === newRow.id);
                const newData = [...fullData];
                console.log(newRow)
                if (createResponse === 'success') {
                    newData[index] = { ...newRow, key: newRow.id };

                    setData(newData);
                    return;
                } else {
                    return;
                }
            }


            if (type === 'date') {
                setUpdatingData(true)
                updateTimeDatabaseRecored(newRow, 'node_data_new2', openSnack, setUpdatingData);
                return;
            }
            if (type === 'select') {
                setUpdatingData(false)
                return;
            }
            setUpdatingData(true)
            console.log(newRow)
            updateDatabaseData(newRow, 'node_data_new2', [...parameters, ...ProbeParameters], stations, codes, unitsArray, openSnack, setUpdatingData);
        }

    }





    function getIssues(row) {
        const { errorsCount, warningsCount } = checkErrors(row);
        setRow({ ...row, errors: errorsCount, warnings: warningsCount, issues: errorsCount + warningsCount });


    }





    const totalProblems = row?.errors + row?.warnings;



    return (





        <div style={{
            display: 'inline-flex',
            width: headers?.filter((header) => header.visible === true).length === 1 ? '100%' : `auto`,
            //nowrap
            flexWrap: 'nowrap',
            cursor: 'pointer',

        }}
            className={`row-hover-effect`}

        >
            <div className="box"
                id={"draggingIcon"}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 20,
                    borderBottom: '1px solid rgba(0,0,0,0.2)'
                }}>
                <div className="my-handle" style={{ display: 'inline' }}>
                    <DragIndicatorIcon />
                </div>

            </div>
            <div className="box"
                id={"errorsIcon"}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',

                    width: 40,
                    borderBottom: '1px solid rgba(0,0,0,0.2)',
                }}>
                {totalProblems > 0 && (<IconButton
                    onClick={() => {
                        console.log(row)
                        setErrorModal(row);


                    }}

                >
                    <Badge badgeContent={totalProblems} color={totalProblems > row.warnings ? 'error' : 'warning'}>
                        {totalProblems > row.warnings ? <PriorityHighIcon style={{ color: 'gray', cursor: 'pointer', fontSize: 18 }} /> :
                            <WarningIcon style={{ color: 'gray', cursor: 'pointer' }} />
                        }
                    </Badge>

                </IconButton>
                )}

            </div>

            <div className="box"

                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 40,
                    borderBottom: '1px solid rgba(0,0,0,0.2)'
                }}>
                <Checkbox
                    id={uuid()}
                    size="small"
                    checked={row?.selected}
                    sx={{
                        color: '#3f51b5',
                        '&.Mui-checked': {
                            color: '#3f51b5',
                        },
                        '& .MuiSvgIcon-root': { fontSize: 24 }
                    }}
                    onChange={(e) => {
                        const shiftKeyPressed = e.nativeEvent.shiftKey;



                        if (shiftKeyPressed && lastClickedIndex !== null) {
                            console.log("SHIFTED")
                            console.log(lastClickedIndex)
                            // Find the starting and ending position based on Data position
                            const startPosition = lastClickedIndex;
                            const endPosition = data.findIndex((item) => item.id === row?.id);
                            const sortedPositions = [startPosition, endPosition].sort((a, b) => a - b);
                            // Loop to set selected state to true for all rows between startPosition and endPosition
                            let newPositionState = data[startPosition].selected;
                            const switchKeys = data.slice(sortedPositions[0], sortedPositions[1] + 1).map((item) => item.id);
                            const updatedData = [...fullData];
                            switchKeys.forEach((key) => {
                                const index = updatedData.map((d) => d.id).indexOf(key);
                                const row = fullData[index];
                                const newRow = { ...row, selected: newPositionState };
                                updatedData[index] = newRow;
                            });
                            setData(updatedData);



                        } else {
                            console.log(data.findIndex((item) => item.id === row?.id))
                            setLastClickedIndex(row?.selected ? null : data.findIndex((item) => item.id === row?.id));
                            const newRow = { ...row, selected: !row?.selected };
                            updateRow(newRow, 'select');
                        }
                    }}
                />
            </div>





            {headers?.map((header, i) => {


                if (header.visible === false) return null;



                return (

                    <div
                        className="box"
                        style={{
                            width: headers.filter((header) => header.visible === true).length === 1 ? '100%' : `${header.width}px`,
                            borderLeft: '1px solid rgba(0,0,0,0.2)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'left',
                            padding: currentCell?.selectedID === `${row.id}??${header.name}` ? 0 : 8,
                            borderBottom: '1px solid rgba(0,0,0,0.2)',
                            backgroundColor: ['date', 'nodeSelect', 'parameterSelect'].includes(header?.optionType) ? 'rgba(0,0,0,0.1)' : 'inherit',


                        }}
                        id={`${row.id}??${header.name}`}
                        onClick={() => {
                            setCurrentCell({ ...row, selectedID: `${row.id}??${header.name}` });
                        }}
                    >

                        {[null, 'value'].includes(header?.optionType) && (
                            <ValueCell
                                id={`${row.id}??${header.name}`}
                                header={header}
                                row={row}
                                currentCell={currentCell}
                                updateRow={updateRow}
                                keyDown={keyDown}
                            />
                        )}
                        {['offsetvalue'].includes(header?.optionType) && (
                            <ValueCell
                                id={`${row.id}??${header.name}`}
                                header={header}
                                row={row}
                                currentCell={currentCell}
                                updateRow={updateRow}
                                keyDown={keyDown}
                            />
                        )}

                        {header?.optionType === 'unitSelect' && (
                            <UnitsCell
                                id={`${row.id}??${header.name}`}
                                header={header}
                                row={row}
                                currentCell={currentCell}
                                updateRow={updateRow}
                                keyDown={keyDown}
                                unitsArray={unitsArray}
                            />
                        )}
                        {header?.optionType === 'parameterSelect' && (
                            <ParametersCell
                                id={`${row.id}??${header.name}`}
                                header={header}
                                row={row}
                                openSnack={openSnack}
                                currentCell={currentCell}
                                updateRow={updateRow}
                                keyDown={keyDown}
                                parameters={parameters}
                            />
                        )}
                        {header?.optionType === 'date' && (
                            <DateCell
                                id={`${row.id}??${header.name}`}
                                header={header}
                                row={row}
                                openSnack={openSnack}
                                currentCell={currentCell}
                                updateRow={updateRow}
                                keyDown={keyDown}
                            />
                        )}
                        {header?.optionType === 'nodeSelect' && (
                            <SiteCell
                                id={`${row.id}??${header.name}`}
                                header={header}
                                row={row}
                                openSnack={openSnack}
                                currentCell={currentCell}
                                updateRow={updateRow}
                                keyDown={keyDown}
                                stations={stations}
                            />
                        )}
                        {header?.optionType === 'codeSelect' && (
                            <CodeCell
                                id={`${row.id}??${header.name}`}
                                header={header}
                                row={row}
                                currentCell={currentCell}
                                updateRow={updateRow}
                                keyDown={keyDown}
                                codes={codes}
                            />
                        )}







                    </div>


                );
            })}


            <div className="box fixed-column" style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingRight: 10,
                paddingLeft: 10,
                width: 150,
                borderBottom: '1px solid rgba(0,0,0,0.2)',
                borderLeft: '1px solid rgba(0,0,0,0.2)',
                //little left box shadow
                boxShadow: 'inset 5px 0px 5px -5px rgba(0,0,0,0.2)'
            }}>

                <IconButton size="sm">

                    <Tooltip title="Delete" placement="bottom">
                        <FontAwesomeIcon icon={faTrash} size="xs" style={{ color: 'gray', cursor: 'pointer' }}
                            onClick={() => { deleteData(row) }} />
                    </Tooltip>

                </IconButton>
            </div>


        </div>















    );
};

export default TableRow;
