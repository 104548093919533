import { Box, Breadcrumbs, Button, Divider, OutlinedInput, TextField, Typography } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Col, Row } from 'antd';
import { collection, getDocs, getFirestore, onSnapshot, query, where } from 'firebase/firestore';
import moment from 'moment';
import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom';
import uuid from 'react-uuid';
import SiteModal from '../samplingSites/siteModal';
import AppMap from './appMap';
import CSVReader from './csvThing';
import ProfileStepper from './stepper';
import { fullMobile } from './util';

const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option) => option.label,
});

export default class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            depthInfo: { graphics: JSON.stringify([]), data: [], depthColumn: {}, lat: 0, lng: 0, name: '' },
            siteItem: {},
            accounts: [],
            parameters: [],
            sites: [],
            accountsLoad: true,
            siteModal: false,
            goBack: false
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }

    }
    componentDidMount() {
        window.scrollTo(0, 0)
        const { userInfo, account } = this.state;

        if (Object.values(userInfo).length === 0) {
            this.props.getUser()
        } else {

            this.queryParameters()
        }

    }



    queryData = async () => {



    }

    queryAccounts = async () => {
        const { userInfo } = this.state;
        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'accounts'));
        const accounts = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            accounts.push({
                accountName: doc.data().accountName,
                key: doc.data().key,
                type: doc.data().seasonal || null,
                city: doc.data().city || null,
                countyName: doc.data().countyName || null,
                state: doc.data().state || null,
                routeDay: doc.data().routeDay || null,
                routeName: doc.data().routeName || null,
                visible: true
            })


        });

        accounts.sort((a, b) => a.accountName.localeCompare(b.accountName))
        this.setState({ accounts, })
        this.queryData()


    }


    queryParameters = async () => {
        const { userInfo, type } = this.state;
        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'parameters'),);
        const unsubscribe = onSnapshot(queryData, async (querySnapshot) => {
            const data = [];
            querySnapshot.forEach((doc) => {
                data.push({ ...doc.data(), label: doc.data().name })
            });
            data.sort((a, b) => a.name.localeCompare(b.name))
            this.setState({ parameters: data, })

            this.querySites()
        });






    }
    querySites = async () => {
        const { userInfo, type } = this.state;
        const db = getFirestore();

        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'sampleSites'), where('account', '==', userInfo.currentAccount), where('archived', "!=", true));
        const unsubscribe = onSnapshot(queryData, async (querySnapshot) => {
            const data = [];
            querySnapshot.forEach((doc) => {
                data.push({ ...doc.data(), label: doc.data().name })
            });
            data.sort((a, b) => a.name.localeCompare(b.name))
            this.setState({ sites: data, })

            this.queryAccounts()
        });





    }

    depthInfo = (boo, val) => {
        const { depthInfo } = this.state;
        depthInfo[boo] = val;
        this.setState({ depthInfo })
    }

    updateProfileData = (index, boo, val) => {
        const { depthInfo } = this.state;

        if (boo === 'depthColumn') {
            const newProfileData = depthInfo.data.map((d, i) => {
                return ({ ...d, depthColumn: i === index ? true : false })
            })
            depthInfo.data = newProfileData;
            this.setState({ depthInfo })
        }
        else {
            depthInfo.data[index][boo] = val;
            this.setState({ depthInfo })
        }

    }

    createProfiles = async (depthInfo) => {





    }

    render() {

        const { userInfo, data, loading, accounts, account, accountsLoad, stocks, currentItem, sites, infoModal, previewModal, siteItem,
            openModal, screenWidth, products, parameters, activeTab, profileData, activeStep, depthInfo, siteModal, goBack } = this.state;




        return (
            <Row style={{}}>
                <Col span={23}>
                    <Row align="middle">
                        <Col xs={16} sm={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>
                            <Typography variant="h4">Depth Profile</Typography>
                        </Col>
                        <Col span={24}>
                            <Divider />
                        </Col>
                    </Row>
                </Col>
                <Col span={23} style={{ paddingLeft: 30, paddingBottom: 15, paddingTop: 15 }}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/depthProfiles" style={{ textDecoration: 'none', color: 'inherit', }}>
                            Depth Profiles
                        </Link>
                    </Breadcrumbs>
                </Col>


                <Col {...fullMobile(12, 24)} style={{ paddingLeft: 30 }}>
                    <Row align="middle" gutter={[16, 16]} style={{}} >

                        <Col {...fullMobile(12, 24)} >
                            <Typography variant="body1">Date<span style={{ color: 'red' }}>*</span></Typography>
                            <DatePicker dateFormat="MM/dd/yyyy hh:mm a" showTimeSelect timeFormat="h:mm a" timeIntervals={1} timeCaption="Time"
                                portalId="root-portal"
                                selected={[undefined, '', "Invalid date"].includes(depthInfo.date) ? new Date() : new Date(moment(depthInfo.date, 'x'))}
                                onChange={(date) => {

                                    const unix = moment(date).format('x');
                                    this.depthInfo('date', unix)

                                }} />
                        </Col>

                        <Col {...fullMobile(12, 24)} >
                            <Typography variant="body1">Name<span style={{ color: 'red' }}>*</span></Typography>
                            <OutlinedInput
                                fullWidth notched={false} label="none" placeholder={"Name"} size="small"

                                value={depthInfo.name}
                                onChange={(e) => {
                                    this.depthInfo(`name`, e.target.value)
                                }} />
                        </Col>
                        <Col {...fullMobile(12, 24)} >
                            <Typography variant="body1">Sample Site</Typography>
                            <Autocomplete
                                size="small"
                                id="combo-box-demo"
                                disableClearable={true}
                                options={sites}
                                filterOptions={(options, _ref) => [...filterOptions(options, _ref), { label: 'Add New', key: 'Add New', value: 'addNew' },]}
                                noOptionsText={<Button fullWidth variant='contained' color='primary' style={{ textTransform: 'none' }}
                                    onClick={() => {
                                        this.setState({ siteModal: true, siteItem: {tempKey: uuid()} })
                                    }}
                                >+ Create Site</Button>}
                                renderInput={(params) => <TextField {...params} label="Site" />}
                                renderOption={(params) => {

                                    if (params.key === 'Add New') {
                                        return (
                                            <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 4 }}>
                                                <Button fullWidth variant='contained' color='primary' style={{ textTransform: 'none' }} onClick={(e) => {
                                                    this.setState({ siteModal: true, siteItem: {tempKey: uuid(),} })
                                                   


                                                }}>+ Create Site</Button>
                                            </Box>

                                        )
                                    }
                                    else {
                                        return (
                                            <Typography {...params}>{params.key}</Typography>
                                        )
                                    }
                                }}
                                onChange={(a, b) => {

                                    if (b.key === 'Add New') {
                                        this.setState({ siteModal: true, siteItem: {tempKey: uuid(),} })
                                    }
                                    else {
                                        this.depthInfo('site', b)
                                    }

                                }}

                            />

                        </Col>

                        <Col {...fullMobile(24, 24)} >
                            <Typography variant="body1">Description</Typography>
                            <OutlinedInput
                                fullWidth notched={false} label="none" placeholder={"Description"} size="small"
                                multiline={true}
                                rows={2}
                                value={depthInfo.description}
                                onChange={(e) => {
                                    this.depthInfo(`description`, e.target.value)
                                }} />
                        </Col>





                        <Col xs={24} sm={{ offset: 6, span: 16 }}>

                        </Col>














                    </Row>
                </Col>

                <Col {...fullMobile(12, 24)} style={{ paddingLeft: 30, paddingBottom: 10 }}>
                    <Row align="middle" gutter={[16, 16]} style={{}} >
                        <Col {...fullMobile(24, 24)} >
                            <Typography variant="body1">Location</Typography>
                            {depthInfo.lat !== 0 && (<Typography variant="body1" fontSize={13} fontWeight={600}>Latitude: {depthInfo.lat.toFixed(4)}</Typography>)}
                            {depthInfo.lat !== 0 && (<Typography variant="body1" fontSize={13} fontWeight={600}>Longitude: {depthInfo.lng.toFixed(4)}</Typography>)}
                            {Object.values(account).length > 0 &&
                                (
                                    <AppMap layerColo="orange" item={depthInfo} account={account}
                                        screenWidth={screenWidth} userInfo={userInfo}
                                        updateGraphics={(graphics, lat, lng) => {
                                            this.depthInfo(`graphics`, graphics);
                                            this.depthInfo(`lat`, lat)
                                            this.depthInfo(`lng`, lng)
                                        }} />
                                )}
                        </Col>
                    </Row>

                </Col>
                <Col {...fullMobile(24, 24)} style={{ paddingLeft: 30 }}>
                    <Divider />
                </Col>
                <Col {...fullMobile(24, 24)} style={{ paddingLeft: 30, paddingTop: 15 }}> <Typography variant="h4">Data</Typography>
                    <Typography variant="body1" fontSize={13} fontWeight={400}
                    >All CSV files need to have a depth column and parameter columns. The first row needs to be parameter labels.</Typography>
                </Col>
                {depthInfo.data.length === 0 && (<Col {...fullMobile(24, 24)} style={{ paddingLeft: 30, }}>
                    <CSVReader updateProfiles={(profiles) => {
                        depthInfo.data = profiles;
                        this.setState({ depthInfo })
                    }} /> </Col>)}
                {depthInfo.data.length > 0 && parameters.length > 0 && (
                    <Col {...fullMobile(23, 23)} style={{ paddingLeft: 30, paddingTop: 30, }}>
                        <ProfileStepper data={depthInfo.data} parameters={parameters} account={account} userInfo={userInfo} screenWidth={screenWidth}
                            openSnack={this.props.openSnack} depthInfo={JSON.stringify(depthInfo)}
                        />
                    </Col>)}















               


                <SiteModal openModal={siteModal} account={account} userInfo={userInfo} openSnack={this.props.openSnack}
                    screenWidth={screenWidth} onClose={() => { this.setState({ siteModal: false, siteItem: {} }) }} currentItem={siteItem} />

            </Row>
        );
    }
} 