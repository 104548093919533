import { Autocomplete, Button, Divider, FormControl, IconButton, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { fullMobile } from '../util';

import AddIcon from '@mui/icons-material/Add';
import uuid from 'react-uuid';

import { DeleteOutline, DragIndicator } from '@mui/icons-material';
import { ReactSortable } from 'react-sortablejs';
import ProbeParameters from '../../../extra/probeParameters.json';
import { queryEquipment, queryParameters, queryProbes, queryStations } from '../../../firebase/config';



const EmptyInspectionItems = ({ inspectionItems }) => {
    //if inspection items length is 0 return empty inspection item
    if (inspectionItems.length === 0) {

        return (
            <Col {...fullMobile(24, 24)} style={{ paddingTop: 5, paddingBottom: 5 }}>
                <Typography variant='body1' fontWeight={400} fontSize={16}>No Inspection Items</Typography>
            </Col>
        )
    }
}



const InspectionHeaderInfo = ({ inspectionItems }) => {



    return (
        <Row>
            <Col {...fullMobile(24, 24)} style={{ paddingTop: 5, }}>
                <Divider />
            </Col>
            <Col {...fullMobile(16, 16)} style={{ paddingTop: 10, paddingBottom: 20 }}>
                <Typography variant='body1' fontWeight={500} fontSize={20}>Template/Form</Typography>
            </Col>


            <EmptyInspectionItems inspectionItems={inspectionItems} />
           {/*  <Col {...fullMobile(24, 24)} style={{}}>
                <Row align="middle" gutter={[8, 8]}>
                    <Col {...fullMobile(1, 1)} style={{ paddingTop: 5, paddingBottom: 5 }}>

                    </Col>
                    <Col {...fullMobile(22, 22)} style={{ paddingTop: 5,  }}>
                        <Row align="middle" gutter={[4, 4]}>
                            <Col {...fullMobile(12, 12)} style={{ }}>
                                <Typography variant='h6' fontWeight={600} fontSize={18}>Label</Typography>
                            </Col>

                            <Col {...fullMobile(4, 4)} style={{ }}>
                                <Typography variant='h6' fontWeight={600} fontSize={18}>Type</Typography>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col> */}
        </Row>

    )

}

const AddInspectionItem = ({ inspectionItems, userInfo, setInspectionItems }) => {

    return (
        <Button variant={'contained'} display="inline"
            fullWidth
            style={{
                textTransform: 'none',
                backgroundColor: '#404050', fontSize: 14,

            }} onClick={() => {
                let newInspectionItem = {
                    id: uuid(),
                    name: '',
                    description: '',
                    type: '',
                    nodeid: '',
                    parameterid: '',
                    value: '',
                    label: '',
                    account: userInfo.currentAccount,
                    company: userInfo.currentCompany,
                    checked: false,
                    observation: '',
                    equipment: '',
                    equipmentObservation: '',
                    text: '',
                    required: false,
                    value: '',
                    status: 'new',
                    index: inspectionItems.length,
                    unitid: '',
                }
                setInspectionItems([...inspectionItems, newInspectionItem])
            }}><AddIcon /> Add Template Item</Button>
    )
}

export { InspectionHeaderInfo, AddInspectionItem };