import { Button, CircularProgress, Divider, Menu, MenuItem, OutlinedInput, Select, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import { collection, doc, getDoc, getDocs, getFirestore, query, setDoc, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { fullMobile } from './util';


import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import SiteCharacteristics from '../siteCharacteristics.jsx/characteristicsHome';
import AccountDirectory from '../accountDirectory';
import AccountInfo from '../../accountInfo';
import CapacityHome from './capacity/capacityHome';
import Capacity from '../../../waterQuality/bathymetry/capacity';
import PublicPortalHome from './publicPortals/publicPortalsHome';
import { useParams } from 'react-router-dom';



export default function SiteInfoHome({ userInfo, screenWidth, openSnack, company, account, getUser, updateUserInfo }) {
    const [loading, setLoading] = useState(true);
    const [locations, setLocations] = useState([]);
    const [value, setValue] = useState("2");


    let { step } = useParams();

    useEffect(() => {

        console.log(step)
        setValue(step === 'portals' ? 2 : 0)
    }, [])


    const db = getFirestore();

    //volume and area should auto populate
    //volume will be acft/cubic feet/gal

    useEffect(() => {

        window.scrollTo(0, 0)

    }, []);


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <Row style={{ fontFamily: 'Roboto, sans-serif', }} align="middle" >
            <Col {...fullMobile(24, 24)} style={{ padding: 20 }}>
                <Typography variant="h4" component="h4" display="ineline" gutterBottom>
                    Site Info
                </Typography>
            </Col>
            <Col {...fullMobile(24, 24)} style={{}}>
                <Tabs
                    //use react-tabs to make tab with key "2" selected
                    selectedIndex={value}
                    onSelect={index => setValue(index)}

                >
                    <TabList style={{ marginLeft: 10 }} value={"2"}>
                        <Tab >Site Characteristics</Tab>
                        <Tab >Capacity</Tab>
                        <Tab >Site Portals</Tab>
                        <Tab > Settings</Tab>
                    </TabList>

                    <TabPanel>
                        <SiteCharacteristics screenWidth={screenWidth} userInfo={userInfo} openSnack={openSnack} account={account} company={company} />
                    </TabPanel>
                    <TabPanel>
                        <Capacity screenWidth={screenWidth} userInfo={userInfo} openSnack={openSnack} account={account} company={company} />
                    </TabPanel>
                    <TabPanel>
                        <PublicPortalHome screenWidth={screenWidth} userInfo={userInfo} openSnack={openSnack} account={account} company={company} />
                    </TabPanel>

                    <TabPanel>
                        <AccountInfo type="info" updateUserInfo={updateUserInfo} screenWidth={screenWidth} userInfo={userInfo} openSnack={openSnack} getUser={getUser} account={account} company={company} />
                    </TabPanel>
                </Tabs>
            </Col>










        </Row>
    );
}