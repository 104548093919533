import { Button, Checkbox, Divider, OutlinedInput, Typography, Select, MenuItem, CircularProgress, Paper, FormControl, InputLabel, IconButton } from '@mui/material';
import { Col, Row } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment-timezone';
import { fullMobile } from '../util';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, } from 'firebase/firestore';

import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import uuid from 'react-uuid';

import { Tree } from "react-arborist";

import DatePicker from "react-datepicker";
import queryString from 'query-string';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDatabase, faMapLocationDot, faMapMarked, faVialCircleCheck, faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import { faSquare, } from '@fortawesome/free-regular-svg-icons';
import { PlayArrowSharp } from '@mui/icons-material';
import { queryParameters } from '../../../firebase/config';
var randomColor = require('randomcolor');

export default function QueryHeader(props) {
    const { userInfo, screenWidth, openSnack, company, account, updateLog, logRef, status, PPArray, parameters, nodes, queryRef, updateQuery, updateEntireQuery } = props;
    const [query, setQuery] = useState(queryRef);
    const [querying, setQuerying] = useState(false);
    const [showQuery, setShowQuery] = useState(true);


    useEffect(() => {

    }, [])


    useEffect(() => {
        setQuery(queryRef)
    }, [queryRef])

    const checks = [
        [...query.parameters, ...query.nodes, ...query.probes].length > 0 ? true : false,
        //check that start date is not in the future and that start date is not ahead of end date
        moment(query.startDate).isBefore(moment(query.endDate)) && moment(query.startDate).isBefore(moment()) ? true : false,

    ]

    const h1Size = 24;


    function updateTimeframe(time, units) {
        updateEntireQuery({ ...query, startDate: new Date(moment().subtract(time, units).format('L')), endDate: new Date() })
        setQuery({ ...query, startDate: new Date(moment().subtract(time, units).format('L')), endDate: new Date() })




    }



    return (
        <Row align="middle" gutter={[12, 12]} allign="middle">
            <Col span={8} style={{}}>

                {query?.nodes.length === 0 && query?.parameters.length === 0 && query?.rainParameters.length === 0 && query?.rainGauges.length === 0 ?

                    <Button variant='contained'
                        disabled={true}
                        fullWidth color='primary' style={{
                            textTransform: 'none', fontSize: 16, height: 60, color: 'white',
                            backgroundColor: 'gray'
                        }} onClick={() => {
                            updateQuery('querying', true)

                        }}>
                        {query.querying && (<CircularProgress />)}
                        {query.querying && ('  Querying...')}
                        {!query.querying && (<PlayArrowSharp fontSize={'large'} />)}
                        {!query.querying && ('  Run Query')}
                    </Button>
                    :
                    <Button variant='contained'
                        disabled={query.querying}
                        fullWidth color='primary' style={{
                            textTransform: 'none', fontSize: 16, height: 60, color: 'white',
                            backgroundColor: querying ? 'gray' : '#17da88'
                        }} onClick={() => {
                            updateQuery('querying', true)

                        }}>
                        {query.querying && (<CircularProgress />)}
                        {query.querying && ('  Querying...')}
                        {!query.querying && (<PlayArrowSharp fontSize={'large'} />)}
                        {!query.querying && ('  Run Query')}
                    </Button>

                }




            </Col>
            <Col style={{}}>
                <Typography variant='body1' fontWeight={600} fontSize={13}>Start Date</Typography>
                <DatePicker timeCaption="Time" showTimeSelect timeFormat="h:mm a" timeIntervals={1}
                    dateFormat="MM/dd/yyyy hh:mm a" portalId="root-portal" selected={query.startDate} onChange={(date) => {
                        updateQuery('startDate', date)


                    }} />
            </Col>
            <Col style={{}}>
                <Typography variant='body1' fontWeight={600} fontSize={13}>End Date</Typography>
                <DatePicker timeCaption="Time" showTimeSelect timeFormat="h:mm a" timeIntervals={1}
                    dateFormat="MM/dd/yyyy hh:mm a" portalId="root-portal" selected={query.endDate} onChange={(date) => {
                        updateQuery('endDate', date)


                    }} />
            </Col>
            <Col span={8} style={{}}>
                {/*   {
                    query?.nodes.length + query?.parameters.length + query?.probes.length > 0 && (
                        <Button variant='contained' size="small" fullWidth color='primary' style={{ textTransform: 'none', backgroundColor: '#404050', marginBottom: 10 }}
                            onClick={() => {
                                //clear all parameters, nodes, and probes
                                updateEntireQuery({ ...query, parameters: [], nodes: [], probes: [] })

                            }
                            }>{'Clear Query'}
                        </Button>
                    )
                } */}
                <Button variant='contained' size="small" fullWidth color='primary' style={{ textTransform: 'none', backgroundColor: '#404050' }}
                    onClick={() => {
                        setShowQuery(!showQuery)
                        updateQuery('showQuery', !showQuery)
                    }
                    }>{showQuery ? 'Hide Query' : 'Show Query'}
                </Button>
            </Col>

            <Col span={16} style={{}}>
                <Row gutter={[12, 12]} align="middle">
                    <Col>
                        <Button variant="outlined" color="primary" size="small"
                            style={{ textTransform: 'none', border: '1px solid #404050', color: '#404050' }}
                            onClick={() => updateTimeframe('1', 'week')} >Last Week</Button>
                    </Col>
                    <Col>
                        <Button variant="outlined" color="primary" size="small"
                            style={{ textTransform: 'none', border: '1px solid #404050', color: '#404050' }}
                            onClick={() => updateTimeframe('1', 'month')} >Last Month</Button>
                    </Col>
                    <Col>
                        <Button variant="outlined" color="primary" size="small"
                            style={{ textTransform: 'none', border: '1px solid #404050', color: '#404050' }}
                            onClick={() => updateTimeframe('3', 'month')}>3 Months</Button>
                    </Col>
                    <Col>
                        <Button variant="outlined" color="primary" size="small"
                            style={{ textTransform: 'none', border: '1px solid #404050', color: '#404050' }}
                            onClick={() => updateTimeframe('6', 'month')}>6 Months</Button>
                    </Col>
                    <Col>
                        <Button variant="outlined" color="primary" size="small"
                            style={{ textTransform: 'none', border: '1px solid #404050', color: '#404050' }}
                            onClick={() => updateTimeframe('1', 'year')}>1 Year</Button>
                    </Col>
                </Row>
            </Col>










        </Row >
    );
}