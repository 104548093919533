import React, { useEffect, useState } from "react";
import MapComponent from "./bioMap";




const MapHome = (
    {
        screenWidth,
        userInfo,
        account,
        openSnack

    }

) => {



    const props = {
        userInfo,
        account,
        openSnack
    };

    return (
        <>





            <MapComponent {...props} />




        </>



    );
};

export default MapHome;
