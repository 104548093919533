import { Autocomplete, Checkbox, Chip, OutlinedInput, TextField, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';
import React from 'react';

import moment from 'moment';
import DatePicker from "react-datepicker";
import '../hoverStyle.css';

export default class GeneralInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            company: this.props.company,
            type: this.props.type,
            item: this.props.item,
            leadStaff: this.props.item.leadStaff,
            stage: this.props.item.stage, 
            loading: true,
            staff: [],
            additionalStaff: this.props.item.additionalStaff,
            preview: this.props.preview,
            status: this.props.status
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
        if (this.props.company !== prevProps.company) {
            this.setState({ company: this.props.company })
        }
        if (this.props.preview !== prevProps.preview) {
            this.setState({ preview: this.props.preview })
        }
        if (this.props.status !== prevProps.status) {
            this.setState({ status: this.props.status })
        }


    }
    componentDidMount() {
        const { userInfo, item } = this.state;

        this.getStaff()


    }

    getStaff = async () => {
        const { userInfo, item } = this.state;
        const db = getFirestore()
        const queryStaff = query(collection(db, "users",), where('accounts', 'array-contains', userInfo.currentAccount));
        const staff = [];
        const snapStaff = await getDocs(queryStaff);
        snapStaff.forEach((doc) => {
            staff.push({
                id: doc.data().id,
                key: doc.data().id,
                label: `${doc.data().firstName || ""} ${doc.data().lastName || ""}`,
            })


        });

        this.setState({ staff })
    }




    updateItem = (boo, val) => {
        const { item } = this.state;
        item[boo] = val;

        this.setState({ item })
        this.props.updateEntireItem(item)
    }

    render() {

        const { item, screenWidth, staff, leadStaff, additionalStaff, preview, company, account, status, stage } = this.state;

        function getFirstLetters(str) {
            const firstLetters = str
                .split(' ')
                .map(word => word[0])
                .join('');

            return firstLetters;
        }



        const LogID = getFirstLetters(company.companyName) + "-" + `${item.account !== undefined ? getFirstLetters(item.account === undefined ? '' : `${item.account.accountName}`) : ''}` + '-' + moment(item.startDate, 'x').format('MM-DD-YYYY') + "-" + "LOG";


        return (
            <Row gutter={[screenWidth === 'xs' ? 4 : 18, screenWidth === 'xs' ? 4 : 18]}
                style={{ paddingTop: 20, paddingLeft: screenWidth === 'xs' ? 15 : 0 }} align="middle">




                <Col xs={24} sm={10} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                    <Typography variant="body1">Log ID<span style={{ color: 'red' }}>*</span></Typography>
                </Col>
                <Col xs={24} sm={14} style={{ paddingBottom: screenWidth === 'xs' ? 12 : 0 }}>
                    <Typography variant="body1">{LogID}</Typography>
                </Col>
                <Col xs={24} sm={10} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                    <Typography variant="body1">Log #<span style={{ color: 'red' }}>*</span></Typography>
                </Col>
                <Col xs={24} sm={14} style={{ paddingBottom: screenWidth === 'xs' ? 12 : 0 }}>
                    {status === 'built' ? <Typography variant="body1">{item.lognumber}</Typography> : <OutlinedInput
                        notched={false} size="small" label="none" inputProps={{ min: 0 }}
                        value={item.lognumber} onChange={(e) => this.updateItem('lognumber', e.target.value)} type='number' />}
                </Col>
                <Col xs={24} sm={10} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                    <Typography variant="body1">Regulatory</Typography>
                </Col>
                <Col xs={24} sm={14} style={{ paddingBottom: screenWidth === 'xs' ? 12 : 0 }}>
                    {status === 'built' ? <Typography variant="body1">{item.regulatory ? "Regulatory" : "Not-Regulatory"}</Typography> :
                        <Checkbox checked={item.regulatory} onChange={(e) => this.updateItem('regulatory', e.target.checked)} />}
                </Col>

                <Col xs={24} sm={10} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                    <Typography variant="body1">Start Date/Time<span style={{ color: 'red' }}>*</span></Typography>
                </Col>


                <Col xs={24} sm={14} style={{ paddingBottom: screenWidth === 'xs' ? 12 : 0 }}>
                    {status === 'built' ? <Typography variant="body1">{moment(item.startDate, 'x').format('MM/DD/YYYY h:mm a')}</Typography> :
                        <DatePicker portalId="root-portal" size="large" showTimeSelect timeFormat="h:mm a" timeIntervals={15} timeCaption="time" dateFormat="MM/dd/yyyy h:mm a"
                            selected={[undefined, '', "Invalid date"].includes(item.startDate) ? new Date() : new Date(moment(item.startDate, 'x'))}
                            onChange={(date) => {
                                if (date === null) { 
                                    this.updateItem('startDate', moment().format('x')) 
                                    this.props.updateWeather(moment().format('x'))
                                }
                                else { 
                                    const unix = moment(date).format('x'); this.updateItem('startDate', unix) 
                                    this.props.updateWeather(moment().format('x'))
                                }
                            }} />}
                </Col>
                <Col xs={24} sm={10} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                    <Typography variant="body1">End Date/Time<span style={{ color: 'red' }}>*</span></Typography>
                </Col>


                <Col xs={24} sm={14} style={{ paddingBottom: screenWidth === 'xs' ? 12 : 0 }}>
                    {status === 'built' ? <Typography variant="body1">{moment(item.endDate, 'x').format('MM/DD/YYYY h:mm a')}</Typography> :
                        <DatePicker portalId="root-portal" showTimeSelect timeFormat="h:mm a" timeIntervals={15} timeCaption="time" dateFormat="MM/dd/yyyy h:mm a"
                            selected={[undefined, '', "Invalid date"].includes(item.endDate) ? new Date() : new Date(moment(item.endDate, 'x'))}
                            onChange={(date) => {
                                if (date === null) { this.updateItem('endDate', moment().format('x')) }
                                else { const unix = moment(date).format('x'); this.updateItem('endDate', unix) }
                            }} />}
                </Col>












                <Col xs={24} sm={10} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                    <Typography variant="body1">Stage:</Typography>
                </Col>
                <Col xs={24} sm={14} style={{ paddingBottom: screenWidth === 'xs' ? 12 : 0 }}>
                    {status === 'built' ? <Typography variant="body1">{item.stage !== undefined ? item.stage.label : ''}</Typography> : <Autocomplete
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        value={stage || ''}
                        hideLabel={true}
                        options={[
                            { label: "Pre", key: 'pre' },
                            { label: "During", key: 'during' },
                            { label: "Post", key: 'Post' },
                        ]}
                        renderInput={(params) => <TextField {...params} label="Stage" />}
                        onChange={(a, b) => {
                            this.setState({ stage: b })
                            this.props.updateItem('stage', b)
                        }}

                    />}
                </Col>
                <Col xs={24} sm={10} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                    <Typography variant="body1">Lead Staff<span style={{ color: 'red' }}>*</span></Typography>
                </Col>
                <Col xs={24} sm={14} style={{ paddingBottom: screenWidth === 'xs' ? 12 : 0 }}>
                    {status === 'built' ? <Typography variant="body1">{item.leadStaff !== undefined ? item.leadStaff.label : ''}</Typography> : <Autocomplete
                        size="small"

                        id="combo-box-demo"
                        value={leadStaff || ''}
                        disableCloseOnSelect={false}
                        options={staff}
                        renderInput={(params) => <TextField {...params} label="Staff" />}
                        onChange={(a, b) => {
                            this.setState({ leadStaff: b })
                            this.props.updateItem('leadStaff', b)
                        }}

                    />}
                </Col>
                <Col xs={24} sm={10} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                    <Typography variant="body1">Additional Staff</Typography>
                </Col>
                <Col xs={24} sm={14} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                    {status === 'creating' ? <Autocomplete
                        size="small"
                        multiple={true}
                        id="combo-box-demo"
                        value={additionalStaff || []}

                        options={staff}
                        filterSelectedOptions
                        renderInput={(params) => <TextField {...params} label="Staff" />}
                        onChange={(a, b) => {
                            this.setState({ additionalStaff: b })
                            this.props.updateItem('additionalStaff', b)
                        }}

                    /> : item.additionalStaff !== undefined ? item.additionalStaff.map((a) => {
                        return (
                            <Chip label={a.label} variant="outlined" />
                        )
                    }) : ''}
                </Col>





            </Row>
        );
    }
} 