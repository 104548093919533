import { CircularProgress, Typography, Checkbox, ButtonGroup, Button } from '@mui/material';
import { Col, Row } from 'antd';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { getFirestore } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import React, { useEffect, useRef, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { Image, ImageButton } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './styleText.css'; // import the CSS file for custom styles
import { useDropzone } from 'react-dropzone';
import './imageSettings.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { uploadFile } from './firestore';




export default function Map2Settings(props) {
    const {
        userInfo,
        screenWidth,
        openSnack,
        company,
        account,
        attributes,
        currentWidgetKey,
        updateAttributes,
    } = props;
    const [loading, setLoading] = useState(true);
    const [value, setValue] = useState('');
    const [columnCount, setColumnCount] = useState('single');
    const db = getFirestore();
    const timerRef = useRef(null);
    const [uploading, setUploading] = useState(false);






    useEffect(() => {

        getInfo();
        console.log(attributes);

    }, []);

    async function getInfo() {
        
    }



    const Loading = () => {
        if (loading) {
            return (
                <div className="loading">
                    <CircularProgress />
                    <Typography variant="body1">Loading Data</Typography>
                </div>
            );
        }
    };


    if (loading) {
        return <Loading />;
    }
    else {
        return (
            <Row gutter={[8, 8]} style={{ height: '100%', width: '100%', display: 'flex' }}>
                <Col span={24}>
                    <Typography variant="body1" display="inline">Transparent Background</Typography>   <Checkbox display="inline"
                        defaultChecked={attributes.transparentBackground}
                        onChange={(e) => {
                            console.log(e.target.checked);
                            attributes.transparentBackground = e.target.checked;
                        }}
                    />
                </Col>



                <Col span={24}>

                </Col>
            </Row >
        );
    }
}
