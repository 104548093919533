import { collection, getDocs, getDoc, addDoc, updateDoc, doc, getFirestore, deleteDoc, writeBatch, setDoc, where, query, orderBy, limit, startAfter, getCountFromServer } from 'firebase/firestore';
import { getStorage, ref, getMetadata, uploadBytes, getDownloadURL } from "firebase/storage";
import uuid from 'react-uuid';
import axios, * as others from 'axios';
import queryString from 'query-string';
import moment from 'moment-timezone';
import { getOffsets } from '../stations/firestore';

const distributeParameterId = (data, userInfo, probeid, node, timezone, offsets) => {

    const offsetsObject = offsets === null ? null : Object.fromEntries(offsets.map(({ parameter, value }) => [parameter, Number(value)]));

    return data.flatMap(item =>
        item.readings.map(reading => ({
            ...reading,
            time: moment(reading.timestamp, 'X').tz(timezone).format("YYYY-MM-DD HH:mm"),
            parameterid: item.parameterId,
            unitid: item.unitId,
            account: userInfo.currentAccount,
            company: userInfo.currentCompany,
            locationid: probeid,
            nodeid: node.id,
            key: uuid(),
            longitude: node.lng,
            latitude: node.lat,
            offsetvalue: offsetsObject === null ? 0 : offsetsObject[item.parameterId] || 0,
        }))
    );
}

export const retrieveProbeData = async (userInfo, node, probe, startDate, endDate, updateDate) => {

    console.log(node)
    console.log(startDate)
    console.log(endDate)
    console.log(probe)
    try {
        const offsets = await getOffsets(userInfo.currentCompany, node.id)

        const token = await getToken(node, probe);

        const AuthStr = 'Bearer '.concat(token);
        console.log(AuthStr)
        const data = await pingProbeData(AuthStr, probe, startDate, endDate, updateDate);
        const timezone = probe === null ? "America/Los_Angeles" : probe.timezone || "America/Los_Angeles";
        const structureData = distributeParameterId(data, userInfo, probe.id, node, timezone, offsets);
        console.log(structureData)
        const response = await uploadData(structureData);


        return response;


    } catch (error) {
        console.log(error)
        return [];
    }
};


export const getToken = async (node, probe) => {
    try {
        const proxyUrl = "https://mycorslake.herokuapp.com/";
        const hydrovuTokenURL = 'https://www.hydrovu.com/public-api/oauth/token';
        console.log(node)
        console.log(node.sensor.api_key_client_id || probe.api_key_client_id)
        console.log(node.sensor.api_key_client_secret || node.sensor.api_key)
        const data = {
            grant_type: 'client_credentials',
            client_id: probe.api_key_client_id,
            client_secret: probe.api_key_client_secret || node.sensor.api_key_client_secret || node.sensor.api_key,
        };

        console.log(data)

        const response = await axios.post(proxyUrl + hydrovuTokenURL, queryString.stringify(data));
        const { access_token } = response.data;
        return access_token;
    } catch (error) {
        console.log(error)
        return [];
    }
};

export const pingProbeData = async (auth, probe, startDateTime, endDateTime, updateDate, nextPage = '') => {
    try {
        const proxyUrl = "https://mycorslake.herokuapp.com/";
        const dataUrl = `https://www.hydrovu.com/public-api/v1/locations/${probe.id}/data/`;

        // Convert startDateTime and endDateTime from string to timestamp in seconds
        console.log(startDateTime)
        const startTime = Number(moment(startDateTime).format('X'));
        console.log(startTime)
        const endTime = Number(moment(endDateTime).format('X'));
        console.log(endTime)

        let allData = [];
        let currentPage = nextPage;

        while (true) {
            const response = await axios.get(proxyUrl + dataUrl, {
                headers: {
                    Authorization: auth,
                    "X-ISI-Start-Page": currentPage
                },
                params: {
                    startTime, // this is now a Unix timestamp in seconds
                    endTime, // this is now a Unix timestamp in seconds
                }
            });
            console.log(response)

            const data = response.data.parameters;
            console.log(data);
            if (data && data.length > 0) {
                allData = [...allData, ...data];
            }
            console.log(response)
            if (response?.data?.parameters[0]?.readings[0]?.timestamp) {
                updateDate(response?.data?.parameters[0]?.readings[0]?.timestamp)
            }
            currentPage = response.headers['x-isi-next-page'];
            console.log(currentPage);
            if (!currentPage) {
                updateDate(null);
                break;
            }
        }

        return allData;
    } catch (error) {
        console.log(error)
        console.error(error);
        return [];
    }
};

export const uploadData = async (data) => {

    try {
        const datalist = data.map(reading => ({
            time: `'${reading.time}'`,
            timestamp: reading.timestamp,
            locationid: `'${reading.locationid}'`,
            unitid: `'${reading.unitid}'`,
            parameterid: `'${reading.parameterid}'`,
            value: reading.value,
            account: `'${reading.account}'`,
            company: `'${reading.company}'`,
            nodeid: `'${reading.nodeid}'`,
            key: `'${uuid()}'`,
            latitude: reading.latitude,
            longitude: reading.longitude,
            logKey: `'null'`,
            label: `'null'`,
            tags: `'[]'`,
            offsetvalue: reading.offsetvalue,
            archived: `'false'`,
        }));

        console.log(datalist)

        const hourlyVals = datalist.map((h) => `(${Object.values(h)})`);
        const hourlyString = hourlyVals.join();

        const proxyUrl = "https://mycorslake.herokuapp.com/";
        axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
            raw: `INSERT INTO node_data_new2 VALUES ${hourlyString} ON CONFLICT DO NOTHING; `
        }).then(async (response) => {
            console.log(response);
            return 'success';
        }).catch((error) => {
            // Handle error here
            console.log(error)
        });



    }
    catch (error) {
        console.log(error);
    }
};













