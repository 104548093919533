import { Backdrop, IconButton, CardMedia, Box, Typography, Button } from "@mui/material";
import React, { useEffect } from "react";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { makeStyles } from '@mui/styles';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const useStyles = makeStyles({
    img: {
        objectFit: 'contain',
        height: '100%',
        width: '100%'
    },
    // Additional styles for mobile layout
    mobileHeader: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        '& h6': {
            fontSize: '0.85rem' // Smaller font size for the title on mobile
        },
        '& button': {
            padding: '6px', // Smaller padding for the button on mobile
            marginTop: '8px', // Spacing between title and button on mobile
            fontSize: '0.75rem' // Smaller font size for the button text on mobile
        }
    }
});

const LightboxComponent = ({
    image,
    isLightboxOpen,
    setIsLightboxOpen,
    alt,
    nextImage,
    prevImage,
    screenWidth
}) => {

    const classes = useStyles();

    useEffect(() => {
        const handleKeyUp = (e) => {
            if (e.code === 'ArrowRight') {
                nextImage();
            } else if (e.code === 'ArrowLeft') {
                prevImage();
            }
        };

        if (isLightboxOpen) {
            window.addEventListener('keyup', handleKeyUp);
        }

        // Remove event listener on cleanup
        return () => window.removeEventListener('keyup', handleKeyUp);
    }, [isLightboxOpen, nextImage, prevImage]); // Ensure effect is re-run if these values change

    const downloadImage = () => {
        // Prevent function from running if the lightbox is not open.
        if (!isLightboxOpen) return;

        const link = document.createElement('a');
        link.href = image;
        link.setAttribute('download', alt); // Use the alt text or any other desired name
        link.setAttribute('target', '_blank'); // This should help instruct the browser to download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLightboxOpen}
            onClick={() => setIsLightboxOpen(false)}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    zIndex: 3,
                    color: 'white',
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '8px 16px',
                    flexDirection: screenWidth === 'xs' ? 'column' : 'row',
                }}
                className={screenWidth === 'xs' ? classes.mobileHeader : ''}
            >
                <Typography variant="h6">{alt}</Typography>
                <Button
                    variant="contained"
                    style={{
                        textTransform: 'none',
                        color: 'white',
                        backgroundColor: '#3f51b5',
                        marginLeft: screenWidth === 'xs' ? 0 : '8px', // No margin on mobile
                    }}
                    startIcon={<FileDownloadIcon />}
                    onClick={(e) => {
                        e.stopPropagation(); // Prevent backdrop click
                        downloadImage();
                    }}
                >
                    Download
                </Button>
            </Box>
            {/* Left arrow for previous image */}
            <IconButton
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '16px',
                    transform: 'translateY(-50%)',
                    color: 'white',
                    zIndex: 2,
                    '&:hover': {
                        color: 'grey.500',
                    },
                }}
                onClick={(e) => {
                    e.stopPropagation(); // Prevent the backdrop from receiving the click
                    prevImage();
                }}
            >
                <ArrowBackIosNewIcon fontSize="large" />
            </IconButton>

            {/* Right arrow for next image */}
            <IconButton
                sx={{
                    position: 'absolute',
                    top: '50%',
                    right: '16px',
                    transform: 'translateY(-50%)',
                    color: 'white',
                    zIndex: 2,
                    '&:hover': {
                        color: 'grey.500',
                    },
                }}
                onClick={(e) => {
                    e.stopPropagation(); // Prevent the backdrop from receiving the click
                    nextImage();
                }}
            >
                <ArrowForwardIosIcon fontSize="large" />
            </IconButton>

            <CardMedia
                classes={{ root: classes.img }}
                component="img"
                image={image}
                alt={alt}
                style={{ height: '80vh', maxWidth: '90vw' }}
            />
        </Backdrop>
    );
};

export default LightboxComponent;
