

import React, { useEffect, useState } from 'react';
import {
    IconButton,
    Tooltip,
    Popover,
    List,
    ListItem,
    ListItemText,
    Checkbox,
    Typography,
    Button,
    MenuItem,
    Badge,
    OutlinedInput,
    InputAdornment,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import MoreMenuItem from './MoreMenuItem.tsx'

import moment from 'moment-timezone';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';

const FilterTable = ({ headers, setHeaders, openSnack, tasks, columnFilters, setColumnFilters, getNestedProperty, fullData, screenWidth }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    useEffect(() => {
        console.log(headers)
    }, [headers])


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };



    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;



    const populateFilters = (data, headers, screenWidth) => {
        const filters = {};

        headers?.forEach((header) => {
            const uniqueValues = [
                ...new Set(
                    data.map(item => {
                        const value = getNestedProperty(item, header.name, header.getLabel, header?.optionType, header?.options, header?.decimals);

                        if (header.type === 'date' && value !== undefined) {
                            return moment(value, 'x').format(
                                screenWidth === 'xs' ? 'MMM DD, YY ha' : 'MM/DD/YY ha'
                            );
                        }

                        return value;
                    })
                ),
            ].filter(value => value !== undefined);

            const newValues = uniqueValues.sort((a, b) => {
                // Convert to strings and lowercase
                const aValue = String(a).toLowerCase();
                const bValue = String(b).toLowerCase();

                // Perform the sorting
                if (aValue < bValue) return -1;
                if (aValue > bValue) return 1;
                return 0;
            });

            filters[header.name] = [...newValues, "No Value"];
        });


        return filters;
    };



    const filters = populateFilters(fullData, headers);

    const [searchTerms, setSearchTerms] = useState({});  // Add this line

    // ... your existing code

    const handleSearchChange = (headerKey, term) => {
        setSearchTerms(prev => ({ ...prev, [headerKey]: term }));
        console.log(searchTerms)
    };

    const clearSearchTerm = (headerKey) => {
        setSearchTerms(prev => {
            const newSearchTerms = { ...prev };
            delete newSearchTerms[headerKey];
            return newSearchTerms;
        });
    };

    const totalFilters = Object.values(columnFilters || {}).reduce((acc, filters) => acc + (filters ? filters?.length : 0), 0);

    const clearAllFilters = () => {
        setAnchorEl(null); // Close the popover
        setColumnFilters({}); // Reset the column filters to an empty object
    };

    return (
        <div style={{ display: 'inline' }}>
            <IconButton aria-label="Column Selection" onClick={handleClick}>
                {/* Conditionally render the Badge based on the total number of filters */}
                {totalFilters > 0 ? (
                    <Badge badgeContent={totalFilters} color="error">
                        <Tooltip title="Filter Table">
                            <FilterListIcon fontSize="large" sx={{ color: '#3f51b5' }} />
                        </Tooltip>
                    </Badge>
                ) : (
                    <Tooltip title="Filter Table">
                        <FilterListIcon fontSize="large" sx={{ color: '#3f51b5' }} />
                    </Tooltip>
                )}
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Typography variant="h6" fontWeight={700} fontSize={18} sx={{ padding: 1, borderBottom: '1px solid #e0e0e0' }}>
                    Filters
                </Typography>
                <Button
                    style={{ textTransform: 'none' }}
                    variant="text"
                    color="primary"
                    onClick={clearAllFilters} // Call the function when the button is clicked
                >
                    Clear All
                </Button>
                <List sx={{
                    padding: 0, maxHeight: 250, overflow: 'auto',
                    paddingTop: 1,

                }}>
                    {headers?.sort((a, b) => a.position - b.position)
                        .filter(header => {
                            // Include all headers if screenWidth is 'xs'
                            if (screenWidth === 'xs') {
                                return true;
                            }
                            // Otherwise, only include headers that are visible
                            return header.visible;
                        })// Only include headers that are visible
                        .map((header, index) => {
                            if (['id', 'title', 'time', 'value'].includes(header?.name)) {
                                return null;
                            }

                            const currentSearchTerm = searchTerms[header?.name] || '';  // Get the current search term for this header
                            const numberOfFilters = columnFilters[header?.name]?.length || 0; // Calculate the number of filters for this header

                            return (

                                <MoreMenuItem label={header?.headerName} numberOfFilters={numberOfFilters}
                                    onMouseLeave={() => clearSearchTerm(header?.name)}
                                >
                                    <div style={{
                                        display: 'inline',

                                        alignItems: 'center',
                                        justifyContent: 'center',

                                        paddingLeft: 5,
                                        paddingRight: 5,
                                    }}>
                                        <OutlinedInput
                                            size="small"
                                            style={{
                                                borderRadius: 16,

                                            }}

                                            value={currentSearchTerm}
                                            placeholder='Search'
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            }
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    {currentSearchTerm !== '' ? (
                                                        <IconButton
                                                            aria-label="clear search term"
                                                            edge="end"
                                                            onClick={() => clearSearchTerm(header?.name)}
                                                        >
                                                            <ClearIcon />
                                                        </IconButton>
                                                    ) : (
                                                        // Transparent placeholder for the ClearIcon
                                                        <IconButton style={{ visibility: 'hidden' }}>
                                                            <ClearIcon />
                                                        </IconButton>
                                                    )}
                                                </InputAdornment>
                                            }

                                            onChange={(e) => handleSearchChange(header?.name, e.target.value)}
                                        />
                                        <Button
                                            style={{ textTransform: 'none' }}
                                            variant="text"
                                            color="primary"
                                            onClick={() => {
                                                const newColumnFilters = { ...columnFilters };
                                                delete newColumnFilters[header?.name]; // Remove all filters for this header key
                                                setColumnFilters(newColumnFilters); // Update the state
                                            }}
                                        >
                                            Clear All
                                        </Button>

                                    </div>


                                    <List
                                        sx={{
                                            padding: 0, maxHeight: 250, overflow: 'auto',
                                            paddingBottom: 0,
                                            paddingTop: 1,
                                            marginBottom: 0,
                                        }}>
                                        {filters[header?.name]?.filter(filterValue => {  // Add filter logic here
                                            if (!currentSearchTerm) return true;
                                            return String(filterValue).toLowerCase().includes(currentSearchTerm.toLowerCase());
                                        })
                                            .map((filterValue) => {
                                                const isChecked = columnFilters[header?.name]?.includes(filterValue) || false;

                                                return (
                                                    <ListItem

                                                        dense
                                                        button
                                                        onClick={() => {
                                                            const newColumnFilters = { ...columnFilters };

                                                            if (isChecked) {
                                                                newColumnFilters[header?.name] = newColumnFilters[header?.name].filter(value => value !== filterValue);
                                                            } else {
                                                                if (!newColumnFilters[header?.name]) {
                                                                    newColumnFilters[header?.name] = [];
                                                                }
                                                                newColumnFilters[header?.name].push(filterValue === "No Value" ? "No Value" : filterValue); // Adjust the filter value here
                                                            }

                                                            setColumnFilters(newColumnFilters);
                                                        }}
                                                        sx={{
                                                            borderBottom: '1px solid #e0e0e0',
                                                            paddingTop: 0,
                                                            paddingBottom: 0,

                                                        }}

                                                        key={filterValue}>
                                                        <Checkbox checked={isChecked} />
                                                        <Typography variant="body1" fontSize={14}
                                                            fontWeight={filterValue === "No Value" ? 700 : 500}>
                                                            {filterValue}
                                                        </Typography>
                                                    </ListItem>
                                                )
                                            })}
                                    </List>


                                </MoreMenuItem>

                            )
                        })}
                </List>
            </Popover>
        </div >
    );
};

export default FilterTable;
