import React from "react";
import axios, * as others from 'axios';
import moment from 'moment-timezone';

export const formatCurrentWeather = async (weather, userInfo) => {

    try {
        const current = weather?.current;
        const time = moment(current?.dt, 'X').format('YYYY-MM-DD HH:mm:ss');
        /* round time to nearest hour */
        const roundedTime = moment(time).startOf('hour').format('YYYY-MM-DD HH:mm:ss');
        const currentWeatherObject = {
            time: roundedTime,
            temperature: current?.temp,
            wind_speed: current?.wind_speed,
            wind_gust: current?.wind_gust,
            wind_deg: current?.wind_deg,
            clouds: current?.clouds,
            dew_point: current?.dew_point,
            humidity: current?.humidity,
            pressure: current?.pressure,
            uvi: current?.uvi,
            visibility: current?.visibility,
            weatherId: current?.weather[0]?.id,
            weatherMain: current?.weather[0]?.main,
            weatherDescription: current?.weather[0]?.description,
            weatherIcon: current?.weather[0]?.icon,
            latitutde: weather?.lat,
            longitude: weather?.lon,
            timezone: weather?.timezone,
            timezone_offset: weather?.timezone_offset,
            company: userInfo?.currentCompany,
            account: userInfo?.currentAccount,
        }

        return currentWeatherObject
    } catch (error) {
        console.error('Error fetching current weather:', error);
    }
}

export const formatSummaryWeather = async (weather, userInfo) => {

    try {
        const current = weather;


        const time = moment(current?.date, 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm:ss');
        const roundedTime = moment(time).startOf('day').format('YYYY-MM-DD HH:mm:ss');
        const summaryWeatherObject = {
            date: roundedTime,
            temperature_high: current?.temperature?.max,
            temperature_low: current?.temperature?.min,
            temperature_avg: (current?.temperature?.max + current?.temperature?.min) / 2,
            rain: current?.precipitation?.total || 0,
            humidity_high: current?.humidity?.afternoon,
            wind_speed_high: current?.wind?.max?.speed,
            timezone: current?.tz,
            latitutde: current?.lat,
            longitude: current?.lon,
            company: userInfo?.currentCompany,
            account: userInfo?.currentAccount,
        }



        return summaryWeatherObject
    } catch (error) {
        console.error('Error fetching current weather:', error);
    }
}

