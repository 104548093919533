import React, { useEffect, useState } from "react";


import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from '@mui/material';
import { Col, Row } from 'antd';
import TableHeader from "../tableComponents/tableHeader";
import TableHome from "../tableComponents/tableHome";
import GridExample from "../tableComponents/tableExample";



const LabParametersHome = ({
    screenWidth,
    userInfo,
    account,
    openSnack
}) => {


    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [items, setItems] = useState([]);
    const [sortedItems, setSortedItems] = useState([]);
    const [columnFilters, setColumnFilters] = useState({});
    const [sortItem, setSortItem] = useState('name');


    useEffect(() => {
        window.scrollTo(0, 0)
        getInfo()

        console.log(userInfo)




    }, [])



    async function getInfo() {






    }







    const props = {
        screenWidth,
        userInfo,
        searchQuery,
        setSearchQuery,
        items,
        setItems,
        sortedItems,
        setSortedItems,
        columnFilters,
        setColumnFilters,
        sortItem,
        setSortItem,



    }


    if (loading) {
        return (
            <Row style={{ height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
                {/* Using the full height of the viewport to center content */}
                <Col span={24} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

                    {/* FontAwesome Spinner */}
                    <FontAwesomeIcon icon={faSpinner} spin size="3x" />

                    <Typography variant="h6" style={{ marginTop: 20 }}>
                        Loading your parameters...
                    </Typography>

                </Col>
            </Row>
        )
    }






    else {
        return (
            <Row style={{}} >
                <Col span={24} style={{}}>
                    <Row align="middle">

                        <Col span={24}>
                            <TableHeader {...props} />
                        </Col>
                        {screenWidth !== 'xs' && (
                            <Col span={24}>
                                <GridExample {...props} />
                            </Col>
                        )}
                        {screenWidth === 'xs' && (
                            <Col span={24}>
                                Mobile
                            </Col>
                        )}





















                    </Row>
                </Col >

            </Row >
        );
    }

};

export default LabParametersHome;
