import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import * as React from 'react';

export default class DefaultDatePicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            date: this.props.date
        };
    }

    componentDidUpdate(prevProps) {


    }
    componentDidMount() {
        const { date } = this.state;
    }

    handleChange = (a, b, c) => {
        const date = moment(a).format('x')
        this.props.updateItem('date', date)
        this.setState({ date })
    }

    render() {

        const { date } = this.state;

        return (
            <LocalizationProvider dateAdapter={DateAdapter}>


                <MobileDatePicker
                    size="small"
                    label="Date"
                    inputFormat="MM/DD/YYYY"
                    value={Number(date)}
                    onChange={(a, b, c) => this.handleChange(a)}
                    renderInput={(params) => <TextField size="small" {...params} />}
                />


            </LocalizationProvider>
        );
    }
} 
