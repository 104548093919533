import { Alert, Snackbar } from '@mui/material';
import { Col, Layout, Row } from 'antd';
import React from 'react';
import {
    Route, Routes
} from "react-router-dom";
import DashboardPortal from '../account/account/dashboard/dashboardhomePortal';
import EBParksPublic from '../landingPage/ebparks/ebparksLanding';
import HomePage from '../landingPage/homePage';
import Invited from '../landingPage/invited';
import PublicDashboardHome from '../publicPortal/publicFacing/publicDashboardHome';
import NewInvited from '../landingPage/newInvited';



const { Header, Content, Sider, Footer } = Layout;

export default class Routing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            openSnack: false,
            snackType: '',
            snackDescription: '',
        };
    }
    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
    }

    componentDidMount() {
    }

    closeSnack = () => {
        this.setState({ openSnack: false, snackType: '', snackDescription: '' })
    }
    openSnack = (type, description) => {
        this.setState({ openSnack: true, snackType: type, snackDescription: description })
    }

    render() {

        const { openSnack, snackType, snackDescription, screenWidth } = this.state;
        return (

            <Row style={{ backgroundColor: 'white', minHeight: '100vh' }}>

                <Col span={24} style={{ position: 'relative', top: 0 }}>
                    <Routes>
                        <Route path="/" element={<HomePage openSnack={this.openSnack} screenWidth={screenWidth} />} />
                    </Routes>
                    <Routes>
                        <Route path="/invited/:id" element={<NewInvited openSnack={this.openSnack} screenWidth={screenWidth} />} />
                    </Routes>
                    <Routes>
                        <Route path="/public-portal/:id" element={<PublicDashboardHome locked={true} openSnack={this.openSnack} screenWidth={screenWidth} preview={false} />} />
                    </Routes>
                    <Routes>
                        <Route path="/ebparks" element={<EBParksPublic
                            custom={{ label: `East Bay Park's` }} openSnack={this.openSnack} screenWidth={screenWidth} />} />

                    </Routes>
                    <Routes>
                        <Route path="/publicPortals/:accountID/:portal/"

                            element={<DashboardPortal type="personal" screenWidth={screenWidth}
                                openSnack={this.openSnack} />} />

                    </Routes>


                </Col>
                <Snackbar open={openSnack} autoHideDuration={3000} onClose={this.closeSnack} >
                    <Alert severity={snackType} variant="filled">
                        {snackDescription}
                    </Alert>
                </Snackbar>

            </Row>







        );
    }
} 