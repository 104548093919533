import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import ToolTabs from './toolTabs';


const ToolsBarHome = ({ userInfo, attributes, openSnack, widgetKey, chartOptions, setChartOptions, openEditor,
    tooltip, panning, panKey, zoomType, mouseWheel, selectType,
    setTooltip, setPanning, setPanKey, setZoomType, setMouseWheel, setSelectType
}) => {

    console.log(selectType)
    const [currentTab, setCurrentTab] = useState("Data editor");





    const props = {
        currentTab, setCurrentTab, userInfo, attributes, openSnack, widgetKey, chartOptions, setChartOptions, openEditor,
        tooltip, panning, panKey, zoomType, mouseWheel, selectType, setTooltip, setPanning, setPanKey, setZoomType, setMouseWheel, setSelectType
    }


    return (

        <Box style={{ border: '1px solid gray', paddingRight: 30 }}  >
            <ToolTabs   {...props} />
        </Box>

    );


}

export default ToolsBarHome;