import React, { useEffect } from "react";


import { Autocomplete, Box, Button, Grid, TextField, Typography } from '@mui/material';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faSpinner } from "@fortawesome/free-solid-svg-icons";



const UploadDataButton = (props) => {


    const { data, uploading, setUploading, setTemplateMaker } = props;


    const [errors, setErrors] = React.useState(null);

    useEffect(() => {
       
        getErrors();
    }, [data]);


    async function getErrors() {
        //count the errors in the data, and set the errors state
        let errorCount = 0;
        data.forEach(row => {
            if (row.errorsCount) {
                errorCount += row.errorsCount;
            }
        });
        console.log(errorCount)
        setErrors(errorCount);
    }

    if (uploading) {
        return (
            <FontAwesomeIcon icon={faSpinner} spin size="xl" color={'#3f51b5'} />
        )
    }
    else {

        return (



            <Button variant="contained" color="primary"

                disabled={errors === null || errors > 0}
                onClick={() => {
                    console.log(data)
                    setTemplateMaker(true)

                }}
                style={{
                    marginLeft: 10,
                    textTransform: 'none',
                    backgroundColor: errors === null || errors > 0 ? '#e0e0e0' : '#3f51b5',

                    fontWeight: 500,
                    fontSize: 14,
                }}>
                Upload Data
            </Button>





        );
    }
};

export default UploadDataButton;
