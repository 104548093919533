import { Button, Checkbox, Divider, OutlinedInput, Typography, Select, MenuItem, CircularProgress } from '@mui/material';
import { Col, Row } from 'antd';
import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { fullMobile } from './util';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, } from 'firebase/firestore';

import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import uuid from 'react-uuid';
import StationsBarV2 from './stationsBarV2';
import StationsMapV2 from './stationsMapV2';



import queryString from 'query-string';
import axios from 'axios';
import StationDrawer from './stationDrawer';
var randomColor = require('randomcolor');

const StationsHomeV2 = ({ userInfo, screenWidth, openSnack, company, account }) => {
    const [loading, setLoading] = useState(true);
    const [locations, setLocations] = useState([]);
    const [currentItem, setCurrentItem] = useState(null);
    const [currentChild, setCurrentChild] = useState(null);
    const [items, setItems] = useState([]);
    const [archivedItems, setArchivedItems] = useState([]);
    const [nodes, setNodes] = useState([]);
    const [accountProps, setAccountProps] = useState(account);
    const [probes, setProbes] = useState([]);
    const [loadingProbes, setLoadingProbes] = useState(true);
    const [loadingStations, setLoadingStations] = useState(true);
    const [center, setCenter] = useState({ lat: account.lat, lng: account.lng });
    const [osts, setOsts] = useState([]);
    const [open, setOpen] = useState(false);


    useEffect(() => {

        //call axios firebase function newFunctionsTest
        axios.get(`https://mycorslake.herokuapp.com/https://us-central1-aquasource3.cloudfunctions.net/getStations`)

            .then(response => {
                //flatten all arrays in data
                const sensors = response.data;

                const proxyurl = "https://mycorslake.herokuapp.com/";
                const hydrovuTokenURL = 'https://www.hydrovu.com/public-api/oauth/token';

                const data = {
                    grant_type: 'client_credentials',
                    client_id: 'testNew',
                    client_secret: '11d28cb08c2444a2966931244ee43e08',
                };

                axios.post(proxyurl + hydrovuTokenURL, queryString.stringify(data))
                    .then(response => {
                        const token = response.data.access_token;

                        sensors.forEach(async sensor => {
                            queryProbeData(token, sensor)

                        })

                    })
                    .catch((error) => {
                        console.log('error ' + error);
                    });
            })

    }, []);




    async function queryProbeData(token, sensor) {
        const proxyurl = "https://mycorslake.herokuapp.com/";
        const queryUrl = `https://www.hydrovu.com/public-api/v1/locations/5945403018313728/data`;
        const AuthStr = 'Bearer '.concat(token);

        axios.get(proxyurl + queryUrl, {
            params: {
                startTime: moment().subtract(8, 'hours').format('X')
            },
            headers: { Authorization: AuthStr, "X-ISI-Start-Page": `` },
        }).then(response => {

            prepareProbeData(response.data, sensor)
        }).catch(error => {
            console.log(error)
        })
    }

    function prepareProbeData(data, sensor) {
        const locationid = data.locationId;
        const parameters = data.parameters;
        const datalist = []

        parameters.forEach((parameter) => {
            parameter.readings.forEach((reading) => {
                var time = moment(reading.timestamp, 'X').tz("America/Los_Angeles").format("YYYY-MM-DD HH:mm");

                const item = {
                    time,
                    locationid: locationid,
                    parameterid: parameter.parameterId,
                    value: reading.value,
                    timestamp: reading.timestamp,
                    unitid: parameter.unitId,
                    account: userInfo.currentAccount,
                    company: userInfo.currentCompany,
                    nodeid: sensor.id,
                    key: uuid(),
                }
                datalist.push(item)
            })
        })


        const hourlyVals = datalist.map((h) => `(${Object.values(h)})`);
        const hourlyString = hourlyVals.join();



    }






    const db = getFirestore();

    //volume and area should auto populate
    //volume will be acft/cubic feet/gal 

    useEffect(() => {
        window.scrollTo(0, 0)
        getProbes()
        getOsts()
        getStations()

    }, []);
    useEffect(() => {
        setAccountProps(account)
    }, [account]);

    async function getStations() {
        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'stations'), where('account', '==', userInfo.currentAccount),);
        const stations = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            stations.push({ ...doc.data(), })
        })

        if (stations.length > 0) {
            //sort by accountIndex 

            stations.sort((a, b) => a.accountIndex - b.accountIndex)
            setItems(stations)
            setLoadingStations(false)
        }
        else {
            setLoadingStations(false)
        }

    }

    async function getOsts() {
        const db = getFirestore();
        const queryData = query(
            collection(db, 'clients', userInfo.currentCompany, 'osts'),
            where('account', '==', userInfo.currentAccount),
            where('archived', '==', false)  // Only retrieve OSTs where 'inactive' is false
        );

        const ostSnapshot = await getDocs(queryData);
        const osts = [];
        ostSnapshot.docs.map((doc) => {
            const ostData = doc.data();
            if (ostData.inactive === false) { // Only push if 'inactive' is false
                osts.push({ ...ostData, label: ostData.name, type: 'ost' });
            }

        })



        setOsts(osts);
    }


    async function getProbes() {
        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'probes'), where('account', '==', userInfo.currentAccount), where('archived', '==', false));

        const locations = [];

        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            const probeData = doc.data();

            if (probeData.inactive === false) { // Only push if 'inactive' is false
                locations.push({ ...probeData, label: probeData.name, type: 'probe', probeType: probeData.type });
            }
        });


        locations.sort((a, b) => a.name.localeCompare(b.name));
        setProbes(locations);

        setLoadingProbes(false);
    }












    async function addStation() {
        const newStation = {
            name: `New Station ${items.length + 1}`,
            key: uuid(),
            id: uuid(),
            children: [],
            lat: center.lat,
            lng: center.lng,
            color: randomColor({ luminosity: 'light', format: 'rgba', alpha: 0.9 }),
            type: '',
            visible: true,
            archived: false,
            exists: false,
            account: userInfo.currentAccount,
            company: userInfo.currentCompany,
            accountIndex: items.length,
            deleteItem: false,





        }
        const newItems = [...items, newStation]
        setItems(newItems)
        const db = getFirestore();
        const docRef = doc(db, "clients", userInfo.currentCompany, 'stations', newStation.key);
        await setDoc(docRef, newStation);


        setCurrentItem(newStation)
        setCurrentChild({})


    }

    async function addChild(node) {

        const newChild = {
            name: `Sampling Node ${node.children.length + 1}`,
            key: uuid(),
            id: uuid(),
            type: [],
            depth: 0,
            offset: 0,
            sensor: null,
            depthUnit: 'ft',
            offsetUnit: 'ft',
            sensorType: '',
            parentId: node.id,
            archived: false,
            account: userInfo.currentAccount,
            company: userInfo.currentCompany,
            childIndex: node.children.length,
        }
        const newItems = items.map(item => {
            if (item?.key === node.data.key) {
                item.children = [...item.children, newChild]
            }
            return item
        })

        setItems(newItems)

        setCurrentChild(newChild)
        const db = getFirestore();
        const index = items.findIndex(item => item.id === node.id)
        const item = items[index];
        const docRef = doc(db, "clients", userInfo.currentCompany, 'stations', item?.key);
        await updateDoc(docRef, {
            children: item.children,
        });


    }


    function updateItems(val) {
        console.log(val)
        const index = items.findIndex(item => item?.key === val.key)

        items[index] = val
        const newItems = [...items]
        setItems(newItems)
        const db = getFirestore();

        if (val !== undefined && val.key !== undefined) {
            const docRef = doc(db, "clients", userInfo.currentCompany, "stations", val.key);
            updateDoc(docRef, {
                color: val.color,
                name: val.name,
                lat: val.lat,
                lng: val.lng,
                linkLocation: val.linkLocation ? val.linkLocation : null,
                linkedNode: val.linkedNode ? val.linkedNode : null,
            })
        }



    }

    function updateArchivedItems(val) {
        const archivedIndex = items.findIndex(item => item?.key === 'archived')
        const newArchivedItems = items[archivedIndex].children.map(item => {
            if (item.key === val.key) {
                return val
            }
            else {
                return item
            }
        })
        items[archivedIndex].children = newArchivedItems
        const newItems = [...items]
        setItems(newItems)

    }


    function updateChildren(child, parent, update) {
        //find index of parent
        const index = items.findIndex(item => item?.key === parent.key)
        //if update is false update child, or else delete child

        if (!update) {
            //find child index
            const childIndex = items[index].children.findIndex(item => item?.key === child.key)
            //update child
            items[index].children[childIndex] = child
            const newItems = [...items]
            setItems(newItems)
            saveItems(newItems)

        }
        else {
            //filter out child
            const newChildren = items[index].children.filter(item => item?.key !== child.key)
            items[index].children = newChildren
            const newItems = [...items]
            setItems(newItems)
            saveItems(newItems)


        }



    }



    async function deleteItem(deletedItem) {


        //archive deletedItem

        const newItems = items.filter(item => item?.key !== deletedItem.key)
        setItems(newItems)
        setCurrentItem({})

        const db = getFirestore();
        const docRef = doc(db, "clients", userInfo.currentCompany, 'stations', deletedItem.key);
        deleteDoc(docRef).then(() => {
            openSnack("success", 'Station Deleted')
        }).catch((error) => {
            openSnack("error", error.message)
        });



    }


    async function archiveItem(deletedItem) {


        //archive deletedItem

        items.map(item => {
            if (item?.key === deletedItem.key) {
                item.archived = true
            }
            return item
        })
        const newItems = [...items]
        deletedItem.archived = true
        setItems(newItems)
        setCurrentItem(deletedItem)
        const db = getFirestore();
        const docRef = doc(db, "clients", userInfo.currentCompany, 'stations', deletedItem.key);
        await updateDoc(docRef, {
            archived: true,
        })







    }


    async function restoreItem(deletedItem) {


        //archive deletedItem
        items.map(item => {
            if (item.key === deletedItem.key) {
                item.archived = false
            }
            return item
        })
        const newItems = [...items]
        setItems(newItems)
        setCurrentItem({})
        const db = getFirestore();
        const docRef = doc(db, "clients", userInfo.currentCompany, 'stations', deletedItem.key);
        await updateDoc(docRef, {
            archived: false,
        })
    }


    async function updateCurrentItem(boo, val) {

        currentItem[boo] = val
        setCurrentItem({ ...currentItem })
        const index = items.findIndex(item => item?.key === currentItem?.key)
        items[index] = currentItem;
        const newItems = [...items]
        setItems(newItems)

    }

    async function swapItems(items) {
        setItems([...items])
        saveItems(items)

    }

    async function saveItems(items) {
        const db = getFirestore();
        const stationsRef = collection(db, "clients", userInfo.currentCompany, 'stations');

        const uploadItems = []
        const newItems = [...items]

        newItems.map((item, i) => {

            uploadItems.push(item)
        })

        uploadItems.forEach((item, i) => {
            delete item.marker;
            //setDoc for each item

            //map through item children and change probeType to null if undefined
            console.log(item)
            if (item.children) {
                item.children.map(child => {
                    if (child.probeType === undefined) {
                        child.probeType = null
                    }
                    if (child.sensor !== undefined && child.sensor !== null) {
                        if (child.sensor?.probeType === undefined) {
                            child.sensor.probeType = null
                        }
                    }
                    return child
                })
            }

            const docRef = doc(stationsRef, item?.key);
            setDoc(docRef, item).then(() => {

                if (i === newItems.length - 1) {
                    openSnack('success', 'Stations Saved')
                }
            })
        })




    }


    function updateCenter(center) {
        setCenter(center)
    }

  


    async function centerMapOnStation(station) {
       
        setCenter({ lat: station.lat, lng: station.lng })
    }


    return (
        <Row style={{ fontFamily: 'Roboto, sans-serif', paddingLeft: 10, paddingTop: 10, marginLeft: 2, backgroundColor: '#f6f8fe', height: '100vh' }} >


            <Col {...fullMobile(6, 6)} style={{}}>
                <StationsBarV2
                    userInfo={userInfo}
                    screenWidth={screenWidth}
                    openSnack={openSnack}
                    company={company}
                    account={account}
                    items={items}
                    swapItems={swapItems}
                    addChild={addChild}
                    saveStations={saveItems}
                    setCurrentItem={setCurrentItem}
                    setCurrentChild={setCurrentChild}
                    updateItems={updateItems}
                    currentItem={currentItem}
                    currentChild={currentChild}
                    addStation={addStation}
                    setItems={setItems}
                />

            </Col>
            <Col {...fullMobile(18, 18)} style={{}}>
                {Object.keys(accountProps).length > 0 && loadingProbes === false && loadingStations === false && (
                    <StationsMapV2
                        userInfo={userInfo}
                        screenWidth={screenWidth}
                        openSnack={openSnack}
                        company={company}
                        account={accountProps}
                        items={items}
                        setItems={setItems}
                        selectStation={setCurrentItem}
                        updateItems={updateItems}
                        updateCurrentItem={updateCurrentItem}
                        deleteItem={deleteItem}
                        setCurrentItem={(item) => {
                            setCurrentItem(item)
                            setCurrentChild(null)
                        }}
                        currentItem={currentItem}
                        currentChild={currentChild}
                        setCurrentChild={setCurrentChild}
                        probesRef={probes}
                        swapItems={swapItems}
                        updateCenter={updateCenter}
                        setOpen={setOpen}
                        center={center}
                        setCenter={setCenter}
                    />
                )}

            </Col>



            <StationDrawer
                setCurrentItem={setCurrentItem}
                open={open}
                setOpen={setOpen}
                loadingProbes={loadingProbes}
                loadingStations={loadingStations}
                userInfo={userInfo}
                screenWidth={screenWidth}
                openSnack={openSnack}
                company={company}
                account={account}
                currentItem={currentItem}
                updateItems={updateItems}
                deleteItem={deleteItem}
                updateChildren={updateChildren}
                location={JSON.stringify(currentItem?.location || {})}
                currentChild={currentChild}
                setCurrentChild={setCurrentChild}
                osts={osts}
                probes={probes}
                swapItems={swapItems}
                items={items}
                updateArchivedItems={updateArchivedItems}
                archiveItem={archiveItem}
                restoreItem={restoreItem}
                centerMapOnStation={centerMapOnStation}



            />







        </Row>
    );
}

export default StationsHomeV2;