
import { Paper, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import moment from 'moment';
import React from 'react';
import "weather-icons/css/weather-icons.css";
import axios, * as others from 'axios';
var convert = require('convert-units')



const apiKey = 'e60406d8d1152d5b1e8413bb058aa602';


export default class CurrentWeatherSideBarMobile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            weather: {},
            icon: '',
            preferences: {}
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }

    }
    componentDidMount() {
        window.scrollTo(0, 0);
        const { account } = this.state;
        this.getWeather()





    }

    getWeather = () => {



        const { account } = this.state;

        var dateFormat = moment().format('MM/DD/YYYY');
        var timeFormat = moment().format('h:mm a');

        var dateTime = moment(dateFormat + ' ' + timeFormat, 'MM/DD/YYYY h:mm a').format('X');



        const proxyUrl = "https://mycorslake.herokuapp.com/";
        axios.get(proxyUrl + 'https://api.openweathermap.org/data/3.0/onecall?', {
            params: {
                lat: account.lat,
                lon: account.lng,
                appid: 'e60406d8d1152d5b1e8413bb058aa602',
                dt: Number(dateTime),
            }
        })
            .then((response) => {



                const weather = { ...response.data.current, ...response.data.current.weather[0], };

                var prefix = 'wi wi-';
                var code = response.data.current.weather[0].id;
                var icon = response.data.current.weather[0].icon;


                console.log(weather)

                this.setState({ weather, icon })



            })
            .catch(function (error) {
                console.log(error);
            })
            .then(function () {
                // always executed
            });

    }



    render() {

        const { account, weather, icon, screenWidth } = this.state;


        var dict = {
            '01d': 'wi-day-sunny',
            '02d': 'wi-day-cloudy',
            '03d': 'wi-cloud',
            '04d': 'wi-cloudy',
            '09d': 'wi-showers',
            '10d': 'wi-day-rain-mix',
            '11d': 'wi-thunderstorm',
            '13d': 'wi-snow',
            '50d': 'wi-fog',
            '01n': 'wi-night-clear',
            '02n': 'wi-night-alt-cloudy',
            '03n': 'wi-night-alt-cloudy-high',
            '04n': 'wi-cloudy',
            '09n': 'wi-night-alt-sprinkle',
            '10n': 'wi-night-alt-showers',
            '11n': 'wi-night-alt-thunderstorm',
            '13n': 'wi-night-alt-snow',
            '50n': 'wi-night-fog'
        };


        return (
            <Row>
                <Col span={24}>


                    <Paper elevation={12} style={{ display: 'flex', height: '100%', width: '100%', borderRadius: 8 }}>
                        <Row style={{ display: 'flex', height: '100%', width: '100%', padding: 10 }} align="middle">
                            <Col span={12}>
                                <Typography variant='h5'>Today</Typography>
                            </Col>
                            <Col span={12} style={{ textAlign: 'right' }}>
                                <Typography variant='body1'>{moment().format('LT')}</Typography>
                            </Col>
                            <Col span={8} style={{ textAlign: 'center', justifyContent: 'center', alignContent: 'center', fontSize: 48 }}>
                                <i style={{ textAlign: 'center', paddingLeft: 25, justifyContent: 'center', alignContent: 'center', }} id='my-node' class={`wi ${dict[icon]}`}></i>
                            </Col>
                            <Col span={14} style={{}}>
                                <Row align="middle">
                                    <Col span={24}>
                                        <Typography variant='h5'>{`${Number(convert(weather.temp).from('K').to('F')).toFixed(0)} F`}</Typography>
                                    </Col>
                                    <Col span={24} style={{ textTransform: 'capitalize' }}>
                                        <Typography variant='body1'>{weather.description}</Typography>
                                    </Col>
                                </Row>

                            </Col>
                            <Col span={12} style={{ textAlign: 'center' }}>
                                <Row>
                                    <Col span={24}>
                                        Wind
                                    </Col>
                                    <Col span={24}>
                                        {weather.wind_speed} mph
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={12} style={{ textAlign: 'center' }}>
                                <Row>
                                    <Col span={24}>
                                        Humidity
                                    </Col>
                                    <Col span={24}>
                                        {weather.humidity}%
                                    </Col>
                                </Row>
                            </Col>



                        </Row>
                    </Paper>
                </Col>
            </Row>

        );
    }
} 
