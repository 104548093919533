import React from 'react';
import { Col, Row } from 'antd';
import { Breadcrumbs, Button, OutlinedInput, Paper, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { setDefaultOptions, loadModules, } from 'esri-loader';
import Locate from "@arcgis/core/widgets/Locate";

export default class Location extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            map: this.props.map,
            view: this.props.view,
        };
    }

    componentDidUpdate(prevProps) {


    }
    componentDidMount() {
        const { account, map, view } = this.state;
        const sketchItem = view.ui.find('locateItem');
        if (sketchItem === null) {
            this.addLocate()
        }
    }

    addLocate = () => {
        const { view } = this.state;
        const LocateWidget = new Locate({
            view: view,
           
        });
        view.ui.add(LocateWidget, {
            position: "bottom-right",
            index: 2
        });
    }

    render() {

        const { } = this.state;

        return null;
    }
} 