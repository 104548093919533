import React, { useEffect, useState } from "react";


import { faBox, faBucket, faCheck, faFish, faFlask, faListCheck, faMagnifyingGlass, faPlus, faSpinner, faWheatAlt, faWrench, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, ButtonGroup, Divider, FormControl, Grid, IconButton, Input, InputLabel, MenuItem, Modal, OutlinedInput, Select, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import { makeStyles } from "@mui/styles";
import ArcGISMap from "./arcMap";
import MapEditor from "./mapEditor";
import GraphicsList from "./graphicsList";
import { fetchMapInfo } from "./symbology/labels";
import { calculateTotalAreaAndLength } from "./symbology/utils";





const MapHome = (
    {
        record,
        items,
        setItems,
        screenWidth,
        openSnack,
        userInfo,
        updateRecord,
        stocks,
        setStocks,
        accounts,
        account,
        setDisableDrag,
    }

) => {
    const [graphics, setGraphics] = useState([]);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [mapUnits, setMapUnits] = useState({});
    const [currentGraphic, setCurrentGraphic] = useState(null);
    const [totalArea, setTotalArea] = useState(0);
    const [totalLength, setTotalLength] = useState(0);

    useEffect(() => {
        getLatestMapInfo();
    }, [])



    async function getLatestMapInfo() {
        setLoading(true);
        const mapInfo = await fetchMapInfo(userInfo, record);
        setGraphics(mapInfo?.graphics);
        setMapUnits(mapInfo?.mapUnits);
        setLoading(false);

        const { totalLength, totalArea } = await calculateTotalAreaAndLength(mapInfo?.graphics, mapInfo?.mapUnits);
        console.log(totalLength)
        console.log(totalArea)
        setTotalArea(totalArea);
        setTotalLength(totalLength);
    }

    const props = {
        record,
        items,
        setItems,
        screenWidth,
        openSnack,
        userInfo,
        updateRecord,
        stocks,
        setStocks,
        accounts,
        account,
        setDisableDrag,
        open,
        setOpen,
        graphics,
        setGraphics,
        getLatestMapInfo,
        mapUnits,
        currentGraphic,
        setCurrentGraphic
    };

    return (
        <>


            <Grid container >

                <Grid item xs={12} md={12}
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                    }}>
                    {loading === true ?
                        /* make a centered div that is 400px tall */
                        <div style={{ height: 400, display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <FontAwesomeIcon icon={faSpinner} spin size="3x" color="#3f51b5" />
                            <Typography variant="h6" component="div" color={'rgba(0,0,0,0.8)'} fontWeight={700} fontSize={12} style={{}}>
                                Loading map...
                            </Typography>

                        </div> : <ArcGISMap {...props} />}
                </Grid>
                <Grid item xs={12} style={{ display: "flex", justifyContent: "space-between" }}>
                    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                        <Typography component="div" variant="body1">
                            <Box fontWeight="fontWeightBold" display="inline">Total Length:</Box>
                            <Box display="inline" ml={1}>{totalLength}</Box>
                        </Typography>
                        <Typography component="div" variant="body1">
                            <Box fontWeight="fontWeightBold" display="inline">Total Area:</Box>
                            <Box display="inline" ml={1}>{totalArea}</Box>
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    {graphics?.length === 0 ? <Typography variant="h6" component="div" color={'rgba(0,0,0,0.8)'} fontWeight={500} fontSize={14} style={{ display: "inline" }}>
                        No graphics added
                    </Typography> : null}
                    <GraphicsList {...props} />
                </Grid>






            </Grid>


            <MapEditor {...props} />




        </>



    );
};

export default MapHome;
