import { OutlinedInput, TextField } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import { Col, Modal, Row } from 'antd';
import { doc, getFirestore, setDoc, updateDoc } from 'firebase/firestore';
import moment from 'moment';
import * as React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import StepperDataClean from './stepperDataClean';
import { fullMobile } from './util';


function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
    return [...a, ...not(b, a)];
}
const steps = ['Select Depth Column', 'Select Parameters', 'Edit Parameters', 'Clean Up Data'];

export default function ProfileStepper({ data, parameters, account, userInfo, screenWidth, openSnack, depthInfo, }) {
    const [activeStep, setActiveStep] = React.useState(0);
    const [depthColumn, setDepthColumn] = React.useState('');
    const [activeParameters, setActiveParameters] = React.useState([]);
    const [skipped, setSkipped] = React.useState(new Set());
    const [checked, setChecked] = React.useState([]);
    const [left, setLeft] = React.useState(data.map((item) => item.label));
    const [right, setRight] = React.useState([4, 5, 6, 7]);
    const [parameterModal, setParameterModal] = React.useState(false);
    const [parameterItem, setParameterItem] = React.useState({});
    const [depthInfoList, setDepthInfo] = React.useState(JSON.parse(depthInfo));

    useEffect(() => {
        setDepthInfo(JSON.parse(depthInfo));
    }, [depthInfo]);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items) => intersection(checked, items).length;

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };


    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleFinish = () => {

    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {


        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: (option) => option.label,
    });


    const CreateParameter = ({ parameterItem, userInfo, account }) => {
        return (
            <Button size="small" style={{ padding: 10, borderRadius: 4, textTransform: 'none', fontSize: 14, }}
                variant='contained' color="primary"
                onClick={async () => {
                    const db = getFirestore()
                    const currentItem = parameterItem;

                    currentItem.name = parameterItem.name || "";


                    const params = data.map((d) => d.name);



                    const checks = [
                        currentItem.name.length === 0 ? false : true,
                        params.includes(currentItem.name) ? false : true,
                    ]

                    if (currentItem.name.length === 0) {
                        openSnack('error', "Parameter name required!")
                    }
                    if (params.includes(currentItem.name)) {
                        openSnack('error', "Parameter name already exists!")
                    }


                    if (!checks.includes(false)) {
                        console.log("good?")
                        console.log(currentItem)

                        const key = currentItem.key || currentItem.tempKey;
                        currentItem.archived = currentItem.archived || false;

                        currentItem.account = userInfo.currentAccount;
                        currentItem.company = userInfo.currentCompany;


                        const docRef = doc(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'parameters', key);

                        if (currentItem.key) {
                            await updateDoc(docRef, currentItem).then(() => {
                                openSnack('success', currentItem.key ? 'Parameter updated!' : "Parameter created!")
                            })
                        }
                        else {
                            await setDoc(docRef, {
                                ...currentItem,
                                key: currentItem.tempKey
                            }).then(() => {
                                openSnack('success', currentItem.key ? 'Parameter updated!' : "Parameter created!")
                                setParameterModal(false)
                            })
                        }


                    }




                }}>{parameterItem.key ? 'Update Parameter' : "Create Parameter"}</Button>
        )
    }



    const FinishButton = ({ depthInfoList, activeParameters }) => {
        let navigate = useNavigate();
        return (
            <Button onClick={() => {
                console.log(depthInfoList)

                if (depthInfoList.name.length > 0) {
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    const depthInfo = depthInfoList;
                    depthInfo.activeParameters = activeParameters;
                    depthInfo.depthColumn = depthColumn;
                    console.log(activeParameters)

                    const checks = [
                        depthInfo.name.length === 0 ? false : true
                    ]

                    if (depthInfo.name.length === 0) {
                        openSnack("error", 'Profile name required')
                    }

                    if (!checks.includes(false)) {



                        const db = getFirestore();




                        const profiles = [];
                        const profileKey = uuid();
                        activeParameters.forEach((param, i) => {


                            const data = param.data.map((d, i) => { return ({ value: d.x, depth: d.y }) })
                            const key = uuid();
                            profiles.push({
                                account: userInfo.currentAccount,
                                company: userInfo.currentCompany,
                                date: depthInfo.date || moment().format('x'),
                                site: depthInfo.site || '',
                                archived: false,
                                parameterkey: param.newParameter.key,
                                parameter: param,
                                data, profileName: depthInfo.name, lat: depthInfo.lat, lng: depthInfo.lng, key, profileKey, graphics: depthInfo.graphics || ''
                            })
                        })


                        profiles.map(async (profile, i) => {
                            console.log(profile)
                            delete profile.parameter.data;
                            const docRef = doc(db, "clients", userInfo.currentCompany, 'profiles', profile.key);
                            await setDoc(docRef, profile).then(() => {

                                openSnack("success", 'Profiles Created!')
                                navigate('/depthProfiles')


                            }).catch((error) => {
                                console.log(error)
                            })


                        })


                    }


                }
                else {
                    openSnack('error', 'Please enter a profile name')
                }





            }}>Finish</Button>
        )
    }

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>


                <Stepper activeStep={3}>

                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};

                        if (isStepSkipped(index)) {
                            stepProps.completed = false;
                        }
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            </Col>
            <Col span={24} style={{ textAlign: 'center' }}>
                {activeStep === steps.length ? (
                    <React.Fragment>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                            All steps completed - profiles have been created!
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button onClick={handleReset}>Reset</Button>
                        </Box>
                    </React.Fragment>
                ) : (
                    <Row >

                        {activeStep === 0 && (
                            <Col span={24} style={{ textAlign: 'center' }}>
                                <Row justify='center' gutter={[16, 16]}>
                                    <Col {...fullMobile(16, 24)} style={{ textAlign: 'left' }} >
                                        <Typography sx={{ mt: 2, mb: 1 }} >Select Depth Column</Typography>
                                    </Col >
                                    <Col span={16} style={{ textAlign: 'center' }}>
                                        <Autocomplete
                                            value={depthColumn}
                                            disableClearable={true}
                                            onChange={(event, value) => { setDepthColumn(value); console.log(value) }}
                                            id="combo-box-demo"
                                            options={data}

                                            renderInput={(params) => <TextField {...params} label="Depth Column" />}

                                        />
                                    </Col>
                                </Row>

                            </Col>

                        )}
                        {activeStep === 1 && (
                            <Col span={24} style={{ textAlign: 'center' }}>
                                <Row justify='center' gutter={[16, 16]}>
                                    <Col {...fullMobile(16, 24)} style={{ textAlign: 'left' }} >
                                        <Typography sx={{ mt: 2, mb: 1 }}>Select Active Parameters</Typography>
                                    </Col>
                                    <Col span={16} style={{ textAlign: 'center' }}>
                                        <Autocomplete
                                            filterSelectedOptions={true}
                                            disableCloseOnSelect
                                            ChipProps={{ color: 'primary' }}
                                            value={activeParameters}
                                            disableClearable={true}
                                            multiple
                                            onChange={(event, value) => { setActiveParameters(value); }}
                                            id="combo-box-demo"
                                            options={data.filter((f) => f.key !== depthColumn.key)}

                                            renderInput={(params) => <TextField {...params} label="Active Parameters" />}
                                        />
                                    </Col>
                                </Row>

                            </Col>
                        )}
                        {activeStep === 2 && (
                            <Col span={24} style={{ textAlign: 'center' }}>
                                <Row justify='center' gutter={[16, 16]}>
                                    <Col {...fullMobile(16, 24)} style={{ textAlign: 'left' }} >
                                        <Typography sx={{ mt: 2, mb: 1 }}>Map Columns</Typography>
                                    </Col>
                                    {data.filter((f) => activeParameters.map((k) => k.key).includes(f.key)).sort((a, b) => a.label.localeCompare(b.label)).map((t) => {
                                        return (
                                            <Col {...fullMobile(16, 24)} style={{ textAlign: 'left' }} >
                                                <Typography sx={{ mt: 2, mb: 1 }} variant="body1">{t.label}</Typography>
                                                <Autocomplete
                                                    size="small"
                                                    id="combo-box-demo"
                                                    defaultValue={t.newParameter.label}
                                                    disableClearable={true}
                                                    options={parameters}
                                                    filterOptions={(options, _ref) => [...filterOptions(options, _ref), { label: 'Add New', key: 'Add New', value: 'addNew' },]}
                                                    noOptionsText={<Button fullWidth variant='contained' color='primary' style={{ textTransform: 'none' }}
                                                        onClick={() => {
                                                            setParameterModal(true)
                                                        }}
                                                    >+ Create Site</Button>}
                                                    renderInput={(params) => <TextField {...params} label="Parameters" />}
                                                    renderOption={(params) => {

                                                        if (params.key === 'Add New') {
                                                            return (
                                                                <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 4 }}>
                                                                    <Button fullWidth variant='contained' color='primary' style={{ textTransform: 'none' }} onClick={(e) => {
                                                                        setParameterModal(true);
                                                                        setParameterItem({ tempKey: uuid(), depthFromBottom: 0 });


                                                                    }}>+ Create Parameter</Button>
                                                                </Box>

                                                            )
                                                        }
                                                        else {
                                                            return (
                                                                <Typography {...params}>{params.key}</Typography>
                                                            )
                                                        }
                                                    }}
                                                    onChange={(a, b) => {

                                                        const index = activeParameters.findIndex((f) => f.key === t.key);
                                                        const newActiveParameters = [...activeParameters];
                                                        newActiveParameters[index].newParameter = b;
                                                        setActiveParameters(newActiveParameters);

                                                    }}

                                                />
                                            </Col>
                                        )
                                    })}

                                </Row>

                            </Col>
                        )}

                        {activeStep === 3 && (
                            <Col span={24} style={{ textAlign: 'center' }}>
                                <Row justify='center' gutter={[16, 16]}>
                                    <Col {...fullMobile(24, 24)} style={{ textAlign: 'left' }} >
                                        <Typography sx={{ mt: 2, mb: 1 }}>Clean Data</Typography>
                                    </Col>
                                    <Col {...fullMobile(24, 24)}>
                                        <StepperDataClean data={data} activeParameters={activeParameters} depthColumn={depthColumn}
                                            updateActiveParameters={(a) => setActiveParameters(a)}
                                            openSnack={(a, b) => openSnack(a, b)} />
                                    </Col>


                                </Row>

                            </Col>
                        )}
                        <Col span={24} style={{ textAlign: 'right' }}>
                            <Row justify='end'>
                                <Col span={4}>
                                    <Button
                                        color="inherit"
                                        disabled={activeStep === 0}
                                        onClick={handleBack}

                                    >
                                        Back
                                    </Button>
                                </Col>
                                <Col span={4}>



                                    {activeStep === steps.length - 1 && <FinishButton depthInfoList={depthInfoList} activeParameters={activeParameters} />}



                                    {activeStep === 0 && Object.values(depthColumn).length > 0 && <Button onClick={handleNext}>Next</Button>}
                                    {activeStep === 1 && activeParameters.length > 0 && <Button onClick={handleNext}>Next</Button>}
                                    {activeStep === 2 && !activeParameters.map((a) => Object.values(a.newParameter).length === 0 ? false : true).includes(false) > 0 && <Button onClick={handleNext}>Next</Button>}
                                </Col>

                            </Row>
                        </Col>
                    </Row>
                )}
            </Col>





























            <Modal title="" visible={parameterModal} onCancel={() => {
                setParameterModal(false)
                setParameterItem({})
                this.props.onClose()
            }} footer={null} width={'60%'} style={{ borderRadius: 40 }} >
                <Row align="middle" gutter={[16, 16]} >
                    <Col xs={{ offset: 0, span: 24 }} sm={{ offset: 6, span: 18 }} style={{ paddingBottom: 12, }}>
                        <Typography variant='h5'>Parameter</Typography>
                    </Col>
                    <Col xs={{ offset: 0, span: 24 }} sm={{ offset: 6, span: 18 }} style={{ paddingBottom: 12, }}>
                        <Typography fontWeight={300} variant='body1'>Reminder, you will be able to associate all water quality samples with either the parameter ID or parameter name.
                            The parameter name will be associated as case sensitive for all uploaded CSV files.</Typography>
                    </Col>


                    <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                        <Typography variant="body1">Name<span style={{ color: 'red' }}>*</span></Typography>
                    </Col>
                    <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                        <OutlinedInput fullWidth notched={false} label="none" placeholder={"Name"} size="small"
                            value={parameterItem.name || ''}
                            onChange={(e) => {
                                setParameterItem({ ...parameterItem, name: e.target.value })

                            }} />
                    </Col>






                    < Col xs={{ offset: 0, span: 24 }} sm={{ offset: 6, span: 18 }} style={{ paddingBottom: 12, }}>
                        <CreateParameter parameterItem={parameterItem} userInfo={userInfo} account={account} />
                    </Col>



                </Row>
            </Modal >

        </Row>
    );
}








