
import React from "react";


import { Button, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom";


const ReturnToLogsButton = () => {

    let navigate = useNavigate();
    return (
        <Button size="small"
            variant="link"
            style={{
                textAlign: 'left',
                paddingLeft: 0,
                textTransform: 'none'
            }}
            onClick={async () => {
                navigate('/newLogsHome')
            }}>
            <Typography variant="body1" fontWeight={700} style={{ color: '#3f51b5' }}>
                {`<-- Return to Logs`}
            </Typography>
        </Button>
    )
}

export default ReturnToLogsButton;