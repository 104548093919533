'use strict';
import { Button, Divider, Typography, TextField, Box, OutlinedInput, ButtonGroup, List, ListItem, ListItemText, Chip, IconButton, FormControlLabel, Checkbox, Select, MenuItem } from '@mui/material';
import { Col, Row, Modal } from 'antd';
import React, { forwardRef, memo, useEffect, useImperativeHandle, useMemo, useRef, useState, useCallback } from 'react';
import { fullMobile } from '../../util';

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import moment from 'moment';
import MUIDataTable from "mui-datatables";
import DatePicker from "react-datepicker";
import uuid from 'react-uuid';
import { queryItems, queryStocks, queryLogItems, queryParameters, queryStations, queryProbes, queryEquipment } from '../../../../firebase/config';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, } from 'firebase/firestore';
import './tableStyle.css';
import "react-datepicker/dist/react-datepicker.css";
import SiteModal from '../../../../waterQuality/samplingSites/siteModal';
import Units from '../../../../extra/units.json';
import ItemForm from '../../../../items/itemForm';
import ConcentrationUnits from '../../../../extra/concentrationUnits.json';
import LogLineItemMap from './logLineMap';
import MapUpdated from './logLineMapUpdated';
import { DeleteForever, RemoveRedEye, VisibilityOff } from '@mui/icons-material';
import MapIcon from '@mui/icons-material/Map';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faMapLocationDot, faTimes, } from '@fortawesome/free-solid-svg-icons';
import ProbeParameters from '../../../../extra/probeParameters.json';
import axios from 'axios';
import { InspectionForm } from './inspection/InspectionForm';
import InspectionNonEdit from './inspection/InspectionNonEdit';
var randomColor = require('randomcolor');







export default function LogLineItems(props) {
    const { userInfo, screenWidth, openSnack, company, account, updateLog, logRef, status, } = props;
    const [log, setLog] = useState(logRef);
    const [data, setData] = useState([]);
    const [editingRow, setEditingRow] = useState(null);
    const [items, setItems] = useState([]);
    const [stocks, setStocks] = useState([]);
    const [itemModal, setItemModal] = useState(false);
    const [newItem, setNewItem] = useState(false);
    const [newItemType, setNewItemType] = useState('product');
    const [concentrationsList, setConcentrationsList] = useState([]);
    const [mapItems, setMapItems] = useState([]);
    const [PPArray, setPPArray] = useState([]);
    const [parameters, setParameters] = useState([]);
    const [nodes, setNodes] = useState([]);
    const [probes, setProbes] = useState([]);
    const [unitList, setUnitList] = useState([]);
    const [equipmentList, setEquipmentList] = useState([]);





    useEffect(() => {
        localStorage.setItem('lineItemData', JSON.stringify([]))
    }, [])

    useEffect(() => {

        if (status === 'built') {

            if (data.length > 0 && items.length > 0) {

                purgeData(data, items)
            }

        }
    }, [status])

    useEffect(() => {
        getInfo()

        const unitList = Object.values(Units).map((u, i) => {
            return ({ label: u, key: u, unitid: Object.keys(Units)[i] })
        })
        setUnitList(unitList)
    }, [])

    async function getInfo() {
        const items = await queryItems(userInfo)
        setItems(items)
        const stocks = await queryStocks(userInfo)
        setStocks(stocks)
        const concentrationsList = Object.values(ConcentrationUnits).map((u, i) => {
            return ({ label: u, key: u, unitid: Object.keys(Units)[i] })
        })
        setConcentrationsList(concentrationsList)

        if (log.key !== undefined) {
            const data = await queryLogItems(userInfo, log.key)

            localStorage.setItem('lineItemData', JSON.stringify(data))
            setData(data)

            if (data.length > 0 && items.length > 0) {

                purgeData(data, items)


            }
        }

        const PPArray = Object.values(ProbeParameters).map((p, i) => {
            return ({
                label: p,
                key: Object.keys(ProbeParameters)[i]
            })
        });
        setPPArray(PPArray)
        const parameters = await queryParameters(userInfo)
        setParameters(parameters)
        const nodes = await queryStations(userInfo, ['grab', 'both', 'sensor', 'application', 'maintenance'])

        setNodes(nodes)

        const probes = await queryProbes(userInfo)

        setProbes(probes)

        const equipmentList = await queryEquipment(userInfo)
        setEquipmentList(equipmentList)

    }

    useEffect(() => {
        setLog(logRef)
        if (log.key === undefined && logRef.key !== undefined) {
            if (data.length > 0 && items.length > 0) {
                purgeData(data, items)
            }
        }
    }, [logRef])


    function updateData(key, item, val) {
        console.log(key, item, val)
        const index = data.map((e) => e.key).indexOf(key);
        const newData = [...data];
        console.log(newData)
        newData[index][item] = val;
        setData(newData);

        const uploadData = newData.map((row) => {

            const newMapItems = row.mapItems !== undefined && row.mapItems.length > 0 ? row.mapItems.map((item) => {
                if (item.type === 'polygon') {
                    return {
                        type: item.type,
                        path: item.path,
                        fillColor: item.fillColor,
                        fillOpacity: item.fillOpacity,
                        strokeWeight: item.strokeWeight,
                        clickable: true,
                        editable: true,
                        draggable: true,
                        zIndex: 1,
                        geodesic: true,
                        key: item.key,
                        label: item.label,
                        area: item.area,

                    }
                }

                if (item.type === 'marker') {
                    return {
                        type: item.type,
                        position: item.position,
                        clickable: true,
                        draggable: true,
                        key: item.key,
                        label: item.label,
                        zIndex: 1

                    }
                }

            }) : [];
            return {
                ...row, mapItems: newMapItems
            }
        })




        localStorage.setItem('lineItemData', JSON.stringify(uploadData))


    }

    async function purgeData(list, itemsList) {


        //remove all rows that are missing any data or have undefined data for item

        const newData = list.filter((row) => {
            return row.item !== undefined
        });



        setData(newData);


        const db = getFirestore();
        const uploadData = newData.map(async (f, i) => {
            f.time = moment(log.startDate, 'x').format('YYYY-MM-DD HH:mm')



            const newMapItems = f.mapItems !== undefined && f.mapItems.length > 0 ? f.mapItems.map((item) => {
                if (item.type === 'polygon') {
                    return {
                        type: item.type,
                        path: item.path,
                        fillColor: item.fillColor,
                        fillOpacity: item.fillOpacity,
                        strokeWeight: item.strokeWeight,
                        clickable: true,
                        editable: true,
                        draggable: true,
                        zIndex: 1,
                        geodesic: true,
                        key: item.key,
                        label: item.label,
                        area: item.area,

                    }
                }

                if (item.type === 'marker') {
                    return {
                        type: item.type,
                        position: item.position,
                        clickable: true,
                        draggable: true,
                        key: item.key,
                        label: item.label,
                        zIndex: 1

                    }
                }

            }) : [];

            f.mapItems = newMapItems
            return f
        })


        //resolve all promises in uploadData
        const resolvedData = await Promise.all(uploadData)




        const products = resolvedData.map((row) => {
            const product = itemsList?.find(item => item.key === row.item)
            return { ...product, label: product?.name }
        }
        )


        const uniqueProducts = [...new Set(products.map(item => item.label))]



        //create product string with commas and & between if length > 2
        const productString = uniqueProducts.length > 2 ? uniqueProducts.slice(0, -1).join(', ') + ' & ' + uniqueProducts.slice(-1) : uniqueProducts.join(', ')

        updateLog('items', productString)

        const docRef = doc(db, "clients", userInfo.currentCompany, 'fieldLogsNew', log.key || log.tempKey);
        updateDoc(docRef, {
            items: productString
        }).then(() => {

        })



        resolvedData.forEach(async (f, i) => {



            if (f.psType === 'inspection') {
                const inspectionItems = f?.inspectionItems !== undefined && f?.inspectionItems.length > 0 && f?.inspectionItems !== null ? f?.inspectionItems.map((item) => {
                    return ({
                        ...item,
                    })
                })
                    : [];



                //filter inspection items that are not type data
                const filteredInspectionItems = inspectionItems.filter((item) => {
                    return item.type === 'data'
                })
                const equipmentItems = inspectionItems.filter((item) => {
                    return item.type === 'equipment'
                })


                const newData = filteredInspectionItems.filter((row) => {
                    return row.parameterid !== undefined && row.nodeid !== undefined && row.value !== undefined && row.parameterid !== '' && row.nodeid !== '' && row.value !== ''
                });



                const newDataList = newData.map((row) => {
                    const station = nodes.find((s) => s.id === row.nodeid)
                    const lat = station.lat || 0
                    const lng = station.lng || 0
                    const time = moment(log.startDate, 'x').format('YYYY-MM-DD HH:mm')
                    const item = {
                        time: `'${time}'`,
                        timestamp: Number(moment(time, "YYYY-MM-DD HH:mm").format("X")),
                        locationid: `'${row.nodeid}'`,
                        unitid: `'${row.unitid || ''}'`,
                        parameterid: `'${row.parameterid}'`,
                        value: row.value,
                        account: `'${userInfo.currentAccount}'`,
                        company: `'${userInfo.currentCompany}'`,
                        nodeid: `'${row.nodeid}'`,
                        key: `'inspection-${row.id}'`,
                        longitude: lng,
                        latitude: lat,
                        logkey: `'${log.key || log.tempKey}'`,
                    }
                    return item
                })

                const hourlyVals = newDataList.map((h) => `(${Object.values(h)})`);
                const hourlyString = hourlyVals.join();



                const proxyUrl = "https://mycorslake.herokuapp.com/";
                axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                    raw: `INSERT INTO node_data_new2 VALUES ${hourlyString} ON CONFLICT DO NOTHING; `
                }).then(async (response) => {
                    //change "' to ' and  '" to ' for all values


                }).catch((error) => {
                    console.log(error)
                }
                )




                equipmentItems.forEach(async (item) => {

                    const equipmentHistoryItem = {
                        time: moment(log.startDate, 'x').format('YYYY-MM-DD HH:mm'),
                        timestamp: Number(moment(log.startDate, 'x').format('X')),
                        logkey: log.key || log.tempKey,
                        equipmentid: item.equipment,
                        observation: item.observation,
                        company: userInfo.currentCompany,
                        account: userInfo.currentAccount,
                        key: item.key,
                        label: item.label,
                    }





                })
            }





            const docRef = doc(db, "clients", userInfo.currentCompany, 'flItems', f.key);
            await setDoc(docRef, f).then(() => {


            }).catch((error) => {
                console.error("Error updating document: ", error);
            });

            //if last one
            if (i === resolvedData.length - 1) {



                localStorage.setItem('lineItemData', JSON.stringify(resolvedData))
            }
        })





    }

    const gridRef = useRef(); // Optional - for accessing Grid's API

    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: (option) => option.label,
    });

    const options = {
        filter: false,
        searchable: false,
        search: false,
        searchable: false,
        viewColumns: false,
        download: false,
        print: false,
        draggable: true,
        rowsPerPage: 50,
        rowsPerPageOptions: [50, 100, 200, 500, 1000],
        selectableRows: screenWidth === 'xs' ? false : status === 'built' ? false : true,
        filterType: 'dropdown',
        responsive: screenWidth === 'xs' ? 'responsive' : 'standard',
        onRowClick: (rowData, rowMeta, b) => {
            const clickedRow = gridRef.current.state.data[rowMeta.dataIndex];
            const editingkey = clickedRow.data[0];
            setEditingRow(editingkey)


        },
        onRowsDelete: (rows, b) => {
            //use rows.lookup to get the index of the rows to be deleted and remove them from data
            const rowsToDelete = Object.keys(rows.lookup);
            const newData = data.filter((row, index) => {
                return !rowsToDelete.includes(index.toString());
            });
            setData(newData);

            const db = getFirestore();
            rowsToDelete.map(async (f) => {
                const docRef = doc(db, "clients", userInfo.currentCompany, 'flItems', data[f].key);
                await deleteDoc(docRef).catch((error) => {
                    console.error("Error adding document: ", error);
                }).then(() => {
                    localStorage.setItem('lineItemData', JSON.stringify(newData))
                })

                if (f.psType === 'inspection') {
                    const inspectionItems = f?.inspectionItems !== undefined && f?.inspectionItems.length > 0 && f?.inspectionItems !== null ? f?.inspectionItems.map((item) => {
                        return ({
                            ...item,
                        })
                    })
                        : [];

                    //filter inspection items that are not type data
                    const filteredInspectionItems = inspectionItems.filter((item) => {
                        return item.type === 'data'
                    })

                    const deletedKeys = filteredInspectionItems.map((index) => `inspection-${data[index].id}`);
                    const proxyUrl = "https://mycorslake.herokuapp.com/";
                    axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                        raw: `DELETE FROM node_data_new2 
                            WHERE key IN (${deletedKeys.map((e) => `'${e}'`).join(',')});`

                    }).then(async (response) => {
                        openSnack('success', 'Data deleted!')
                    }).catch((error) => {
                        openSnack('error', 'Error deleting data!')
                    })



                }




            })
        }
    };



    async function updateDatabase(item) {

        const proxyUrl = "https://mycorslake.herokuapp.com/";
        axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
            raw: `UPDATE node_data_new2 SET 
            value = ${Number(item.value)}
            , unitid = '${item?.unitid}'

                WHERE key = 'inspection-${item.id}'
                ; `
        }).then(async (response) => {
            // handle success
        }).catch((error) => {
            console.log(error)
        })
    }


    const DeleteRowButton = (props) => {
        const { onClick, ...other } = props;
        return (
            <Button onClick={onClick} {...other}>
                <DeleteForever />
            </Button>
        );
    };


    const columns = [

        {
            name: 'key',
            label: "Edit",

            options: {
                //hide column
                display: false,
                filter: false,
                sort: false,
                empty: true,

            }
        },
        {
            name: "item",
            label: 'Products/Services',
            options: {
                customHeadLabelRender: () => {
                    return ''
                },
                filter: false,
                //hide column label
                customHeadRender: () => null,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const key = tableMeta.rowData[0];
                    const editing = key === editingRow;
                    const item = items.find((item) => item.key === value);
                    const dataItem = data.find((d) => d.key === key);
                    const label = items.find((item) => item.key === value)?.label;



                    if (editing && status !== 'built') {



                        return (
                            <Row gutter={[12, 12]} align="middle">
                                <Col {...fullMobile(item?.psType === 'inspection' ? 24 : dataItem.showMap ? 12 : 24, item?.psType === 'inspection' ? 24 : dataItem.showMap ? 24 : 24)}
                                >
                                    <Row gutter={[8, 8]} align="middle">
                                        <Col {...fullMobile(20, 18)}>
                                            <Autocomplete
                                                size="small"
                                                style={{ backgroundColor: '#ffffff' }}
                                                disableCloseOnSelect={false}
                                                id="combo-box-demo"
                                                value={label || null}
                                                disableClearable={true}
                                                options={items.sort((a, b) => -b.psType.localeCompare(a.psType))}
                                                groupBy={(option) => option.psType || ""}
                                                renderGroup={(params) => (
                                                    <Box key={params.key}>
                                                        <Typography fontWeight={800} fontSize={16} p={1}>
                                                            {params.group === 'inspection' ? 'Templates' :
                                                                params.group === 'products' ? 'Products' :
                                                                    params.group === 'other' ? null :
                                                                        'Services'
                                                            }
                                                        </Typography>
                                                        {React.Children.map(params.children, (child, index) => {
                                                            return (
                                                                <Box style={{
                                                                    borderBottom: '1px solid #e0e0e0'
                                                                }}>
                                                                    {child}
                                                                </Box>
                                                            )
                                                        })}
                                                    </Box>
                                                )}
                                                filterOptions={(options, _ref) => [...filterOptions(options, _ref), { psType: 'other', label: 'Add New', key: 'Add New', value: 'addNew' },]}
                                                noOptionsText={<Button fullWidth variant='contained' color='primary' style={{ textTransform: 'none' }}
                                                    onClick={() => {
                                                        setItemModal(true)
                                                        setNewItem({ tempKey: uuid(), });
                                                    }}
                                                >+ Create Item</Button>}

                                                renderInput={(params) => {
                                                    return (
                                                        <TextField {...params} placeholder="Item" variant="outlined" />
                                                    )
                                                }}
                                                renderOption={(params) => {

                                                    if (params.key === 'Add New') {
                                                        return (
                                                            <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 4 }}>
                                                                <Button fullWidth variant='contained' color='primary' style={{ textTransform: 'none', backgroundColor: '#404050' }} onClick={(e) => {
                                                                    setItemModal(true)
                                                                    setNewItem({ tempKey: uuid(), });


                                                                }}>+ Create Item</Button>
                                                            </Box>

                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            <Typography {...params}>{params.key}</Typography>
                                                        )
                                                    }
                                                }}
                                                onChange={(a, d) => {
                                                    updateData(key, 'item', d.key)
                                                    updateData(key, 'psType', d.psType)
                                                    if (d.psType === 'inspection') {
                                                        updateData(key, 'inspectionItems', JSON.parse(d.inspectionItems))
                                                    }



                                                }}

                                            />
                                        </Col>

                                        <Col {...fullMobile(4, 6)} style={{ paddingTop: 6 }}>
                                            <Button fullWidth variant={dataItem.showMap ? 'outlined' : 'contained'} size="small" color='primary'
                                                style={{
                                                    color: dataItem.showMap ? '#404050' : 'white',
                                                    textTransform: 'none', backgroundColor: dataItem.showMap ? 'inherit' : '#404050',
                                                    border: `1px solid ${dataItem.showMap ? '#404050' : 'inherit'}`
                                                }} onClick={() => {
                                                    updateData(key, 'showMap', !dataItem.showMap)
                                                }}>
                                                {
                                                    screenWidth === 'xs' ? <FontAwesomeIcon icon={faMapLocationDot} size="2x" /> :
                                                        dataItem.showMap ? 'Hide Map' : 'Show Map'}
                                            </Button>
                                        </Col>



                                        {
                                            item?.psType === 'products' && (
                                                <Col {...fullMobile(8, 24)}>
                                                    <Typography variant="body1" fontSize={13} fontWeight={600}>Stock</Typography>
                                                    <Autocomplete
                                                        style={{ backgroundColor: '#ffffff' }}
                                                        size="small"
                                                        id="combo-box-demo"
                                                        value={dataItem?.supplyStock}
                                                        options={stocks}
                                                        filterSelectedOptions
                                                        renderInput={(params) => <TextField {...params} placeholder="Stock" />}
                                                        onChange={(a, d) => {
                                                            updateData(key, 'supplyStock', d);
                                                        }}

                                                    />
                                                </Col>
                                            )
                                        }

                                        {
                                            item?.psType === 'products' && (
                                                <Col {...fullMobile(8, 24)}>
                                                    <Typography variant="body1" fontSize={13} fontWeight={600}>Location</Typography>
                                                    <Autocomplete
                                                        style={{ backgroundColor: '#ffffff' }}
                                                        size="small"
                                                        id="combo-box-demo"
                                                        value={nodes.filter(item => {
                                                            if (Array.isArray(item.stationType)) {
                                                                return item.stationType.some(type => ['application', 'maintenance'].includes(type));
                                                            } else {
                                                                return ['application', 'maintenance'].includes(item.stationType);
                                                            }
                                                        }).find(item => item.id === dataItem?.location)}
                                                        options={nodes.filter(item => {
                                                            if (Array.isArray(item.stationType)) {
                                                                return item.stationType.some(type => ['application', 'maintenance'].includes(type));
                                                            } else {
                                                                return ['application', 'maintenance'].includes(item.stationType);
                                                            }
                                                        })}
                                                        filterSelectedOptions
                                                        renderInput={(params) => <TextField {...params} placeholder="Location" />}
                                                        onChange={(a, d) => {
                                                            if (d?.id) {
                                                                updateData(key, 'location', d.id);
                                                            }

                                                        }}

                                                    />
                                                </Col>
                                            )
                                        }
                                        {
                                            item?.psType === 'products' && (
                                                <Col {...fullMobile(8, 24)}>
                                                    <Typography variant="body1" fontSize={13} fontWeight={600}>Percent Covered</Typography>
                                                    <OutlinedInput
                                                        style={{ backgroundColor: '#ffffff' }}
                                                        fullWidth notched={false} label="none" placeholder={"Percent"} size="small"
                                                        defaultValue={dataItem?.percentApplied}
                                                        type="number"
                                                        onChange={(e) => {
                                                            updateData(key, 'percentApplied', e.target.value === "" ? Number(0) : e.target.value === "0" ? Number(0) : Number(e.target.value))

                                                        }} />
                                                </Col>
                                            )
                                        }
                                        {
                                            item?.psType === 'products' && (
                                                <Col {...fullMobile(24, 24)}>
                                                    <Typography variant="body1" fontSize={13} fontWeight={600}>Application Method</Typography>
                                                    <Autocomplete
                                                        size="small"
                                                        id="combo-box-demo"
                                                        value={dataItem?.applicationMethod}
                                                        style={{ fontSize: 13, backgroundColor: '#ffffff' }}
                                                        filterSelectedOptions
                                                        options={[
                                                            { label: "Boat", key: 'airBoat' },
                                                            { label: "UTV/Gator", key: 'UTV/Gator' },
                                                            { label: "Backpack Sprayer", key: 'backpackSprayer' },
                                                            { label: "Backpack Blower", key: 'backpackBlower' },
                                                            { label: "Hand Broadcast", key: 'handBroadcast' },
                                                            { label: "Spray Rig", key: 'sprayRig' },
                                                        ]}
                                                        renderInput={(params) => <TextField {...params} label="Method" />}
                                                        onChange={(a, d) => {
                                                            updateData(key, 'applicationMethod', d);
                                                        }}

                                                    />
                                                </Col>
                                            )
                                        }
                                        {
                                            item?.psType === 'products' && (
                                                <Col {...fullMobile(12, 12)}>
                                                    <Typography variant="body1" fontSize={13} fontWeight={600}>Rate</Typography>
                                                    <OutlinedInput
                                                        style={{ backgroundColor: '#ffffff' }}
                                                        fullWidth notched={false} label="none" placeholder={"Conc."} size="small"
                                                        defaultValue={dataItem?.concentration}
                                                        type="number"
                                                        onChange={(e) => {
                                                            updateData(key, 'concentration', e.target.value)


                                                        }} />
                                                </Col>
                                            )
                                        }
                                        {
                                            item?.psType === 'products' && (
                                                <Col {...fullMobile(12, 12)}>
                                                    <Typography variant="body1" fontSize={13} fontWeight={600}>Rate Units</Typography>
                                                    <Autocomplete
                                                        style={{ backgroundColor: '#ffffff' }}
                                                        size="small"
                                                        id="combo-box-demo"
                                                        value={dataItem?.concentrationUnits}
                                                        options={concentrationsList}
                                                        renderInput={(params) => <TextField {...params} label="Units" />}
                                                        onChange={(a, d) => {
                                                            updateData(key, 'concentrationUnits', d);
                                                        }}

                                                    />
                                                </Col>
                                            )
                                        }
                                        {
                                            item?.psType === 'products' && (
                                                <Col {...fullMobile(12, 12)}>
                                                    <Typography variant="body1" fontSize={13} fontWeight={600}>Total Amount</Typography>
                                                    <OutlinedInput
                                                        style={{ backgroundColor: '#ffffff' }}
                                                        fullWidth notched={false} label="none" placeholder={"Amount"} size="small"
                                                        defaultValue={dataItem?.quantity}
                                                        type="number"
                                                        onChange={(e) => {
                                                            updateData(key, 'quantity', e.target.value === "" ? Number(0) : e.target.value === "0" ? Number(0) : Number(e.target.value))

                                                        }} />
                                                </Col>
                                            )
                                        }
                                        {
                                            item?.psType === 'products' && (
                                                <Col {...fullMobile(12, 12)}>
                                                    <Typography variant="body1" fontSize={13} fontWeight={600}>Units</Typography>
                                                    <Autocomplete
                                                        style={{ backgroundColor: '#ffffff' }}
                                                        size="small"
                                                        id="combo-box-demo"
                                                        value={dataItem?.units}
                                                        options={["mg",
                                                            "g",
                                                            "kg",
                                                            "oz",
                                                            "lb",
                                                            "fl-oz",
                                                            "cup",
                                                            "pnt",
                                                            "qt",
                                                            "gal",
                                                            "ft3",
                                                        ].sort((a, b) => a.localeCompare(b)).map((g) => { return ({ key: g, label: g }) })}
                                                        renderInput={(params) => <TextField {...params} label="Units" />}
                                                        onChange={(a, d) => {
                                                            updateData(key, 'units', d);

                                                        }}

                                                    />
                                                </Col>
                                            )
                                        }
                                        {
                                            ['inspection'].includes(dataItem?.psType) && (
                                                <Col {...fullMobile(24, 24)} style={{}}>


                                                    <InspectionForm
                                                        inspectionItems={dataItem?.inspectionItems}
                                                        dataItem={dataItem}
                                                        nodes={nodes}
                                                        parameters={[...PPArray, ...parameters]}
                                                        equipmentList={equipmentList}
                                                        key={key}
                                                        updateData={(field, value) => {
                                                            console.log(key, field, value)
                                                            updateData(key, field, value);
                                                        }}
                                                        unitList={unitList}
                                                        updateDatabase={(item) => updateDatabase(item)}


                                                    />






                                                </Col>
                                            )
                                        }
                                        {
                                            ['services', 'inspection'].includes(item?.psType) && (
                                                <Col {...fullMobile(8, 24)}>
                                                    <Typography variant='body1' fontWeight={600} color="#404050" fontSize={16}>Location</Typography>
                                                    <Autocomplete
                                                        size="small"
                                                        id="combo-box-demo"
                                                        value={nodes.filter(item => {
                                                            if (Array.isArray(item.stationType)) {
                                                                return item.stationType.some(type => ['application', 'maintenance'].includes(type));
                                                            } else {
                                                                return ['application', 'maintenance'].includes(item.stationType);
                                                            }
                                                        }).find(item => item.id === dataItem?.location)}
                                                        options={nodes.filter(item => {
                                                            if (Array.isArray(item.stationType)) {
                                                                return item.stationType.some(type => ['application', 'maintenance'].includes(type));
                                                            } else {
                                                                return ['application', 'maintenance'].includes(item.stationType);
                                                            }
                                                        })}
                                                        filterSelectedOptions
                                                        renderInput={(params) => <TextField {...params} placeholder="Location" />}
                                                        onChange={(a, d) => {
                                                            if (d?.id) {
                                                                updateData(key, 'location', d.id);
                                                            }

                                                        }}

                                                    />
                                                </Col>
                                            )
                                        }
                                        {
                                            ['products', 'services', 'inspection'].includes(item?.psType) && (
                                                <Col {...fullMobile(24, 24)} style={{}}>
                                                    <Typography variant="body1" fontSize={13} fontWeight={600}>Notes</Typography>

                                                    <OutlinedInput fullWidth notched={false} label="none" placeholder={"Description"} size="small"
                                                        multiline={true}
                                                        style={{ backgroundColor: '#ffffff' }}
                                                        rows={4}
                                                        onFocus={(e) => {

                                                            //check if it is focused or not

                                                        }}
                                                        //detect if focus is lost
                                                        onBlur={(e) => {

                                                            updateData(key, 'description', e.target.value);
                                                        }}
                                                        defaultValue={dataItem?.description}
                                                    />
                                                </Col>
                                            )
                                        }
                                    </Row>
                                </Col>
                                {dataItem.showMap === true && (<Col {...fullMobile(dataItem.showMap ? 12 : 0, dataItem.showMap ? 24 : 0)}>
                                    <LogLineItemMap {...props} dataItem={dataItem} updateMapItems={(items) => {

                                        updateData(key, 'mapItems', items);

                                    }} updateItem={(boo, val) => {
                                        updateData(key, boo, val);
                                    }} />

                                    {dataItem?.mapItems && dataItem?.mapItems.length > 0 && (
                                        <Row gutter={[12, 12]}>
                                            {dataItem?.mapItems.map((item, index) => {

                                                if (item.type === 'marker') {
                                                    return (
                                                        <Col>
                                                            <Chip style={{ backgroundColor: "white", border: '1px solid #404050' }} variant="outlined" label={(
                                                                <div><Typography display="inline" variant="body1" fontSize={13} fontWeight={600}>{`Marker ${item.label}: `}</Typography>
                                                                </div>
                                                            )} />
                                                        </Col>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <Col>
                                                            <Chip style={{ backgroundColor: "white", border: '1px solid #404050' }} variant="outlined" label={(
                                                                <div><Typography display="inline" variant="body1" fontSize={13} fontWeight={600}>{`Area ${item.label}: `}</Typography>
                                                                    <Typography display="inline" variant="body1" fontSize={13} fontWeight={400}>{`${item.area.toFixed(2)} acres`}</Typography></div>
                                                            )} />
                                                        </Col>


                                                    )
                                                }
                                            })}
                                        </Row>
                                    )
                                    }




                                </Col>
                                )}






                            </Row>


                        )
                    }

                    else {
                        return (

                            <Row>
                                <Col {...fullMobile(dataItem.showMap ? 12 : 24, dataItem.showMap ? 24 : 24)}>
                                    <Row gutter={[8, 8]} align="middle">
                                        <Col {...fullMobile(24, 24)}>
                                            <Typography variant='h6' fontWeight={500} color={label === undefined ? '#404050' : 'black'} >{label || 'Click to Edit Item'}</Typography>
                                        </Col>

                                        {item?.psType === 'products' && (
                                            <Col {...fullMobile(6, 24)}>
                                                <Typography variant="body1" fontSize={13} fontWeight={600}>Stock</Typography>
                                                <Typography variant='body2'>{dataItem?.supplyStock?.label}</Typography>
                                            </Col>
                                        )}
                                        {item?.psType === 'products' && (
                                            <Col {...fullMobile(6, 24)}>
                                                <Typography variant="body1" fontSize={13} fontWeight={600}>Percent Applied</Typography>
                                                <Typography variant='body2'>{dataItem?.percentApplied}</Typography>
                                            </Col>
                                        )}
                                        {item?.psType === 'products' && (
                                            <Col {...fullMobile(6, 24)}>
                                                <Typography variant="body1" fontSize={13} fontWeight={600}>Application Method</Typography>
                                                <Typography variant='body2'>{dataItem?.applicationMethod?.label}</Typography>
                                            </Col>
                                        )}
                                        {item?.psType === 'products' && (
                                            <Col {...fullMobile(6, 0)}>

                                            </Col>
                                        )}
                                        {item?.psType === 'products' && (
                                            <Col {...fullMobile(6, 12)}>
                                                <Typography variant="body1" fontSize={13} fontWeight={600}>Rate</Typography>
                                                <Typography variant="body1">{dataItem?.concentration} {dataItem?.concentrationUnits?.label}</Typography>
                                            </Col>
                                        )}

                                        {item?.psType === 'products' && (
                                            <Col {...fullMobile(6, 12)}>
                                                <Typography variant="body1" fontSize={13} fontWeight={600}>Amount</Typography>
                                                <Typography variant="body1">{dataItem?.quantity} {dataItem?.units?.label}</Typography>
                                            </Col>
                                        )}

                                        {['inspection'].includes(item?.psType) && (
                                            <Col {...fullMobile(24, 24)}>

                                                <InspectionNonEdit
                                                    inspectionItems={dataItem?.inspectionItems}
                                                    dataItem={dataItem}
                                                    nodes={nodes}
                                                    parameters={[...PPArray, ...parameters]}
                                                    equipmentList={equipmentList}
                                                    key={key}
                                                    updateData={(field, value) => {
                                                        console.log(key, field, value)
                                                        updateData(key, field, value);
                                                    }}
                                                    unitList={unitList}
                                                    updateDatabase={(item) => updateDatabase(item)}
                                                />





                                            </Col>
                                        )}

                                        {['products', 'services', 'inspection'].includes(item?.psType) && (
                                            <Col {...fullMobile(24, 24)}>
                                                <Typography variant="body1" fontSize={13} fontWeight={600}>Notes</Typography>
                                                <Typography variant='body2'>{dataItem?.description}</Typography>
                                            </Col>
                                        )}

                                    </Row>
                                </Col>

                                {dataItem.showMap === true && (<Col {...fullMobile(dataItem.showMap ? 12 : 0, dataItem.showMap ? 24 : 0)}>
                                    {dataItem?.staticUrl !== undefined && (
                                        <img height={300} src={dataItem?.staticUrl}


                                            alt="map" id="map" />
                                    )}

                                    {dataItem?.mapItems && dataItem?.mapItems.length > 0 && (
                                        <Row gutter={[12, 12]} style={{ paddingTop: 15 }}>
                                            {dataItem?.mapItems.map((item, index) => {

                                                if (item.type === 'marker') {
                                                    return (
                                                        <Col>
                                                            <Chip style={{ backgroundColor: "white", border: '1px solid #404050' }} variant="outlined" label={(
                                                                <div><Typography display="inline" variant="body1" fontSize={13} fontWeight={600}>{`Marker ${item.label}: `}</Typography>
                                                                </div>
                                                            )} />
                                                        </Col>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <Col>
                                                            <Chip style={{ backgroundColor: "white", border: '1px solid #404050' }} variant="outlined" label={(
                                                                <div><Typography display="inline" variant="body1" fontSize={13} fontWeight={600}>{`Area ${item?.label}: `}</Typography>
                                                                    <Typography display="inline" variant="body1" fontSize={13} fontWeight={400}>{`${item?.area.toFixed(2)} acres`}</Typography></div>
                                                            )} />
                                                        </Col>


                                                    )
                                                }



                                            })}
                                        </Row>
                                    )
                                    }




                                </Col>
                                )}



                                {screenWidth === 'xs' && (<Col span={12} offset={12} >

                                    <DeleteRowButton dataItem={dataItem} onClick={async () => {
                                        const newData = [...data];
                                        const index = newData.findIndex(item => item.key === dataItem.key);
                                        newData.splice(index, 1);
                                        setData(newData);

                                        const db = getFirestore();
                                        const docRef = doc(db, "clients", userInfo.currentCompany, 'flItems', dataItem.key);
                                        await deleteDoc(docRef).catch((error) => {
                                            console.error("Error adding document: ", error);
                                        }).then(() => {
                                            localStorage.setItem('lineItemData', JSON.stringify(newData))
                                        })

                                        if (dataItem.psType === 'inspection') {
                                            const f = dataItem;
                                            const inspectionItems = f?.inspectionItems !== undefined && f?.inspectionItems.length > 0 && f?.inspectionItems !== null ? f?.inspectionItems.map((item) => {
                                                return ({
                                                    ...item,
                                                })
                                            })
                                                : [];

                                            //filter inspection items that are not type data
                                            const filteredInspectionItems = inspectionItems.filter((item) => {
                                                return item.type === 'data'
                                            })

                                            const deletedKeys = filteredInspectionItems.map((index) => `inspection-${data[index].id}`);
                                            const proxyUrl = "https://mycorslake.herokuapp.com/";
                                            axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                                                raw: `DELETE FROM node_data_new2 
                                                    WHERE key IN (${deletedKeys.map((e) => `'${e}'`).join(',')});`

                                            }).then(async (response) => {
                                                openSnack('success', 'Data deleted!')
                                            }).catch((error) => {
                                                openSnack('error', 'Error deleting data!')
                                            })
                                        }




                                    }} />




                                </Col>)}







                            </Row>
                        )
                    }
                }


            }
        },






    ];


    const AddRowButton = memo(() => {
        const [date, setDate] = useState(new Date());


        const handleAddRow = () => {
            const newDate = moment(date).format('YYYY-MM-DD HH:mm');
            const newData = [...data, {
                key: uuid(), time: newDate, archived: false, editing: false, showMap: false, logkey: log.key || log.tempKey, description: '',
                account: userInfo.currentAccount, company: userInfo.currentCompany, user: userInfo.id
            }];
            setData(newData);
            setDate(new Date());
        }

        return (
            <Button size="small"
                variant="contained" color="primary"
                style={{ textTransform: 'none', backgroundColor: '#404050', fontSize: screenWidth === 'xs' ? 12 : 14 }}
                onClick={handleAddRow} > + Add Product or Service</Button >
        )
    })

    //create something that if you click anywhere other than the table it will close the editing row
    const handleOutsideClick = (e) => {
        if (e.target.id !== 'table') {
            setEditingRow(null);
        }
        //but if it is the table, then do nothing


    }




    return (

        <Row gutter={[12, 12]} align="middle" >

            <Col {...fullMobile(12, 12)} style={{ paddingTop: 30 }} onClick={() => setEditingRow(null)}>
                <Typography variant='h5' display="inline" fontWeight={500} fontSize={screenWidth === 'xs' ? 16 : 22}>Products/Services</Typography>
                {status === 'creating' && (<IconButton display="inline" size="small" onClick={() => {
                    updateLog('hidePS', !log.hidePS)
                }}>
                    {log.hidePS ? <VisibilityOff /> : <RemoveRedEye />}
                </IconButton>)}


            </Col>
            <Col {...fullMobile(12, 12)} style={{ paddingTop: screenWidth === 'xs' ? 30 : 30, textAlign: 'right' }}>
                {status !== 'built' && (<AddRowButton />)}


            </Col>
            {!log.hidePS && data.length > 0 && (
                <Col id="table" {...fullMobile(24, 24)} style={{ paddingTop: 10, }}>

                    <MUIDataTable ref={gridRef} data={data} columns={columns} options={options} />

                </Col>)}
            <Col {...fullMobile(24, 24)}>
                <Divider style={{
                    borderColor: 'rgb(148, 186, 195)',
                    borderBottomWidth: 'medium',

                }} />
            </Col>


            <Modal title="" visible={itemModal} onCancel={() => {
                setItemModal(false)
                setNewItem({})

            }} footer={null} width={'60%'} style={{ borderRadius: 40 }} >
                <Row>
                    <Col {...fullMobile(24, 24)} style={{}}>
                        <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group" >
                            <Button variant={newItemType === 'product' ? 'contained' : 'outlined'} onClick={() => {
                                setNewItemType('product')
                            }} style={{ textTransform: 'none', padding: 8, fontSize: 14, }} color={newItemType === 'product' ? 'primary' : 'secondary'}>Product</Button>
                            <Button variant={newItemType === 'service' ? 'contained' : 'outlined'} onClick={() => {
                                setNewItemType('service')
                            }} style={{ textTransform: 'none', padding: 8, fontSize: 14, }} color={newItemType === 'service' ? 'primary' : 'secondary'}>Service</Button>
                            {/*   <Button variant={newItemType === 'inspection' ? 'contained' : 'outlined'} onClick={() => {
                                setNewItemType('inspection')
                            }} style={{ textTransform: 'none', padding: 8, fontSize: 14, }} color={newItemType === 'inspection' ? 'primary' : 'secondary'}>Inspection</Button> */}
                        </ButtonGroup>
                    </Col>
                    {newItemType === 'product' && (
                        <Col {...fullMobile(24, 24)} style={{ paddingTop: 8 }}>
                            <ItemForm type={newItemType} userInfo={userInfo} newItem={true} openSnack={openSnack} closeModal={async () => {
                                setItemModal(false)
                                setNewItem({})
                                const items = await queryItems(userInfo)
                                setItems(items)
                            }} />
                        </Col>
                    )}
                    {newItemType === 'service' && (
                        <Col {...fullMobile(24, 24)} style={{ paddingTop: 8 }}>
                            <ItemForm type={newItemType} userInfo={userInfo} newItem={true} openSnack={openSnack} closeModal={async () => {
                                setItemModal(false)
                                setNewItem({})
                                const items = await queryItems(userInfo)
                                setItems(items)
                            }} />
                        </Col>
                    )}
                    {newItemType === 'inspection' && (
                        <Col {...fullMobile(24, 24)} style={{ paddingTop: 8 }}>
                            <ItemForm type={newItemType} userInfo={userInfo} newItem={true} openSnack={openSnack} closeModal={async () => {
                                setItemModal(false)
                                setNewItem({})
                                const items = await queryItems(userInfo)
                                setItems(items)
                            }} />
                        </Col>
                    )}

                </Row>

            </Modal >

        </Row>

    )



}