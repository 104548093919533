import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import FolderCover from "./folderCover";

import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import emptyFolderImage from './emptyFolder.png';
import FolderIcon from './foldericon.JPG';
import { updateFolderImageFunction } from "../../utils/folderFunctions";

const FoldersList = ({
    screenWidth,
    openSnack,
    userInfo,
    folders,
    filteredFolders,
    setFilteredFolders,
    setCurrentFolder,
    setCurrentParentFolder,
    folderBreadCrumbs,
    setFolderBreadCrumbs,
    moveUpDirectory,
    deleteFolder,
    updateParentId,
    setDragExisting,
    selectedFiles,
    setSelectedFiles,
    files,
    setFiles,
    updateItems,
    setFolders,
    updateFolder,
    publicPortal


}) => {


    const props = {
        screenWidth, openSnack, userInfo, folders, setCurrentFolder, updateFolder, publicPortal,
        setCurrentParentFolder, folderBreadCrumbs, setFolderBreadCrumbs, deleteFolder, moveUpDirectory, updateItems
    };






    // Folder component that is draggable
    const DraggableFolder = ({ folder, updateParentId, selectedFiles, updateItems, ...props }) => {

        const [{ isDragging }, dragRef] = useDrag({
            type: 'folder',
            item: { id: folder.folder_id, type: 'folder' },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),

            }),
            end: (item, monitor) => {
                console.log(`end drag: ${folder.folder_id}`)
                const dropResult = monitor.getDropResult();
                console.log(dropResult)


            },
        });

        // Set up the drop target
        const [{ isOver, canDrop }, dropRef] = useDrop({
            accept: ['folder', 'file'], // Now accepts both folder and file types
            canDrop: (item, monitor) => {
                // Define the logic for when an item can be dropped on this folder
                // For example, you might want to prevent dropping an folder onto itself
                if (item.type === 'folder') {
                    return item.id !== folder.folder_id;
                }
                // If it's a file, you may have different logic
                return true;
            },
            drop: async (item, monitor) => {
                console.log(item)
                // Differentiate actions based on type
                if (publicPortal === true) {
                    openSnack('error', 'You do not have permission to move files and folders')
                    return
                }
                if (item.type === 'folder') {
                    // Folder-specific logic
                    if (monitor.isOver({ shallow: true })) {

                        const folderList = [item.id];
                        const itemsToRemove = [];
                        const itemsToRemain = [];
                        folders.forEach((a) => {
                            if (folderList.includes(a.folder_id)) {
                                itemsToRemove.push(a);
                            } else {
                                itemsToRemain.push(a);
                            }
                        });
                        const newFolders = itemsToRemove.map((a) => {
                            return { ...a, parent_folder_id: folder.folder_id };
                        });
                        console.log(itemsToRemain)
                        console.log(newFolders)

                        setFolders(itemsToRemain);

                        updateItems('folder', newFolders);

                    }
                }
                if (item.type === 'file') {
                    const filesList = [...selectedFiles, item.id];
                    //remove duplicates from array
                    moveFilesToFolder(filesList, folder)




                }
            },
            collect: monitor => ({
                isOver: monitor.isOver({ shallow: true }),
                canDrop: monitor.canDrop(),
            }),
        });
        const combinedRef = (node) => {
            dragRef(node);
            dropRef(node);
        };


        async function moveFilesToFolder(filesList, folder) {
            const uniqueFiles = [...new Set(filesList)];
            console.log(uniqueFiles)
            const lastFile = files.find((p) => p.file_id === uniqueFiles[uniqueFiles.length - 1]);
            const newFolders = [...folders];
            console.log(lastFile)
            newFolders.forEach((a) => {
                if (a.folder_id === folder.folder_id) {
                    a.coverFile = lastFile.medium;
                }
            });
            const newFilteredFolders = [...filteredFolders];
            newFilteredFolders.forEach((a) => {
                if (a.folder_id === folder.folder_id) {
                    a.coverFile = lastFile.medium;
                }
            });
            console.log(newFilteredFolders)
            setFilteredFolders(newFilteredFolders)
            console.log(newFolders)
            setFolders(newFolders);



            const filesToRemove = [];
            const filesToRemain = [];
            files.forEach((file) => {
                if (uniqueFiles.includes(file.file_id)) {
                    filesToRemove.push(file);
                } else {
                    filesToRemain.push(file);
                }
            });
            const newFiles = filesToRemove.map((file) => {
                return { ...file, parent_folder_id: folder.folder_id };
            });

            setSelectedFiles(selectedFiles.filter((id) => !newFiles.map((p) => p.file_id).includes(id)))

            setFiles(filesToRemain);

            updateItems('file', newFiles);

        }



        return (
            <Grid ref={combinedRef} key={folder.folder_id} item xs={6} sm={4} md={3}>
                <FolderCover
                    {...props}
                    moveFilesToFolder={moveFilesToFolder}
                    selectedFiles={selectedFiles}
                    folder={folder}
                    state={isOver && canDrop ? 'dropTarget' : isDragging ? 'dragging' : 'idle'}
                    imageSrc={FolderIcon}
                    title={folder.name}
                    // Style based on isDragging
                    style={{ opacity: isDragging ? 0.8 : 1, cursor: 'move', border: isDragging ? '6px solid red' : isOver && canDrop ? '6px solid #1bff0fe6' : '1px solid gray' }}
                />
            </Grid>
        );
    };



    if (filteredFolders.length === 0) {
        return (
            <Box p={1}>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={12} md={12} sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '10vh'

                    }}>
                        <Typography variant="h3" fontSize={18} fontWeight={700} align="center">
                            No folders found
                        </Typography>
                    </Grid>
                </Grid>
            </Box >
        )
    }
    else {

        return (

            <Box p={1} >



                <Grid container alignItems="center" spacing={2}>
                    {filteredFolders.map((a, index) => {


                        return (

                            <DraggableFolder key={a.folder_id} folder={a}
                                updateParentId={updateParentId}
                                selectedFiles={selectedFiles}
                                {...props} title={a.name}
                            />

                        )
                    })}
                </Grid>
            </Box>

        );
    }
};

export default FoldersList;
