import { collection, collectionGroup, doc, getDoc, getDocs, getFirestore, limit, onSnapshot, orderBy, query, setDoc, updateDoc, where, writeBatch } from "firebase/firestore";
import moment from 'moment-timezone';
import axios, * as others from 'axios';
import queryString from 'query-string';
import uuid from "react-uuid";



export const testAPIConnection = async (stream) => {
    try {
        console.log(stream);
        const proxyurl = "https://mycorslake.herokuapp.com/";
        const hydrovuTokenURL = 'https://www.hydrovu.com/public-api/oauth/token';
        const hydrovuLocationURL = `https://www.hydrovu.com/public-api/v1/locations/${stream?.hydroID}/data`;

        const data = {
            grant_type: 'client_credentials',
            client_id: stream?.api_key_client_id,
            client_secret: stream?.api_key_client_secret,
        };

        const tokenResponse = await axios.post(proxyurl + hydrovuTokenURL, queryString.stringify(data));
        const authorizationHeader = `Bearer ${tokenResponse.data.access_token}`;

        const hoursArray = [8];
        const responses = await Promise.all(hoursArray.map(async (hours) => {
            try {
                return await axios.get(proxyurl + hydrovuLocationURL, {
                    params: {
                        startTime: moment().subtract(hours, 'hours').format('X'),
                    },
                    headers: { Authorization: authorizationHeader },
                });
            } catch (error) {
                console.error(`Error fetching data for ${hours} hours ago:`, error);
                return null; // Continue in case of error
            }
        }));

        const prepareData = responses.filter(response => response !== null).map(response => {
            const data = response.data;
            const locationid = data.locationId;
            const timezone = stream?.timezone || "America/Los_Angeles";
            const datalist = data.parameters.flatMap(parameter =>
                parameter.readings.map(reading => ({
                    time: `'${moment(reading.timestamp, 'X').tz(timezone).format("YYYY-MM-DD HH:mm")}'`,
                    timestamp: reading.timestamp,
                    locationid: `'${locationid}'`,
                    unitid: `'${parameter.unitId}'`,
                    parameterid: `'${parameter.parameterId}'`,
                    value: reading.value,
                    key: `'${uuid()}'`,
                }))
            );


            return datalist.length > 0 ? 'success' : 'error';
        });


        return 'success';
    } catch (error) {
        console.error('Error fetching data:', error);
        return 'error';
    }
};

export const testTokenConnection = async (stream, openSnack) => {
    try {
        console.log(stream);
        const proxyurl = "https://mycorslake.herokuapp.com/";
        const hydrovuTokenURL = 'https://www.hydrovu.com/public-api/oauth/token';

        const data = {
            grant_type: 'client_credentials',
            client_id: stream?.api_key_client_id,
            client_secret: stream?.api_key_client_secret,
        };

        const tokenResponse = await axios.post(proxyurl + hydrovuTokenURL, queryString.stringify(data));
        console.log(tokenResponse)
        const authorizationHeader = `Bearer ${tokenResponse.data.access_token}`;
        openSnack('success', 'Token connection successful');

        return 'success';
    } catch (error) {
        console.log(error);
        openSnack('error', 'Token connection failed');
        return 'error';
    }
};

