import Highcharts from 'highcharts/highstock';
import moment from 'moment';


export const ToolOptions = {
    mouseWheel: true,
    zoomType: 'x',
    panning: true,
    panKey: 'shift',
    selectType: 'zoom',
    tooltip: false,

}

export const ChartOptions = {
    credits: {
        enabled: false
    },
    exporting: {
        enabled: false
    },
    stockTools: {
        gui: {
            enabled: false
        }
    },
    chart: {
        zooming: {
            mouseWheel: {
                enabled: true,

            }
        },


        zoomType: 'xy',

    },
    title: {
        text: null
    },
    legend: {
        itemStyle: {
            fontSize: this?.props?.legendSize !== undefined ? `${this?.props?.legendSize}px` : '14px',
            fontWeight: '500',
            fontFamily: 'Roboto, sans-serif'
        },
    },


    series: [],
    yAxis: [{ id: 0 }],
    xAxis: {
        type: 'datetime',
        startOnTick: false,
        endOnTick: false,
        plotLines: [],
        crosshair: {
            width: 10,
            color: '#2651d214',
            dashStyle: 'solid',
            zIndex: 0,
        },

        title: {
            text: undefined,
            style: {
                fontSize: '12px',  // Increase size as needed
                fontWeight: '800',  // Set font weight to 500
                color: 'black'
            }

        },
        labels: {
            style: {
                fontSize: '12px',  // Increase size as needed
                fontWeight: '700',  // Set font weight to 500
                color: 'black'
            }
        },
    },
    tooltip: {
        xDateFormat: '%b %d, %Y %I:%M %p',
        outside: true,
        enabled: false,
        shared: true,
        crosshairs: true,
        useHTML: true, // Enable HTML in tooltips
        formatter: function () {
            console.log(this)

            var s = `<b style="font-family: Roboto; font-size: 14px; font-weight: 800;">${moment(this.x).format('MMM DD, YYYY hh:mm A')}</b>`;


            this.points.forEach(function (point) {
                // Start with the raw value
                let valueFormatted = point.y;

                // Format the value based on defined decimals
                if (point.series.tooltipOptions.valueDecimals !== undefined) {
                    valueFormatted = valueFormatted.toFixed(point.series.tooltipOptions.valueDecimals);
                }

                // Add a suffix if it's defined, otherwise, don't add anything
                valueFormatted += point.series.tooltipOptions.valueSuffix ? point.series.tooltipOptions.valueSuffix : "";

                // Use toLocaleString for formatting if no decimals are specified
                if (point.series.tooltipOptions.valueDecimals === undefined) {
                    valueFormatted = parseFloat(valueFormatted).toLocaleString();
                }

                // Concatenate the tooltip string
                s += `<br/><span style="color:${point.series.color}; font-size: 15px;">●</span> <b style="font-family: Roboto; font-size: 13px;">${point.series.name}:</b> <span style="font-family: Roboto; font-size: 13px;">${valueFormatted}</span>`;
            });

            return s;
        },
        style: {
            fontFamily: 'Roboto', // Ensure the tooltip font is Roboto
            zIndex: 500,
        }
    },



    plotOptions: {
        series: {
            animation: false,
            allowPointSelect: true,
        },
        column: {
            pointWidth: 20 // set the column width for column series
        },

    },
    time: {
        useUTC: false,
    }
};

export const ParameterMap = {
    '1': {
        default: 'C',
    },
    '3': {
        default: 'm',

    },
    '4': {
        default: 'm',
    },
    '5': {
        default: 'm',
    },
    '8': {
        default: 'm',
    },
    '19': {
        from: '',
        to: ''
    }
};


export async function addZoomFunctions({ attributes, userInfo, widgetKey, openSnack, setChartOptions, chartOptions }) {
    const newOptions = { ...chartOptions };

    function selectPointsByDrag(e) {
        if (e.xAxis != null) {
            const startDate = moment(Math.ceil(e?.xAxis[0]?.min), 'x').format('MM-DD-YYYY HH:mm');
            const endDate = moment(Math.ceil(e?.xAxis[0]?.max), 'x').format('MM-DD-YYYY HH:mm');

            console.log(e)
            console.log(startDate)
            console.log(endDate)
            return false; // Don't zoom
        }
    }

    newOptions.chart.events = {
        //console.log loaded when load
        load: () => {

        },


        selection: selectPointsByDrag
    }
    setChartOptions(newOptions);
}