import {
    Button, Checkbox, Divider, OutlinedInput, Typography, Select, MenuItem, CircularProgress, Breadcrumbs, FormGroup, Box,
    FormControlLabel, Chip, TextField
} from '@mui/material';
import { Col, Row, Modal, Popconfirm } from 'antd';
import React, { useState, useEffect, memo, useMemo, } from 'react';
import moment from 'moment-timezone';
import { fullMobile } from '../../util';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, } from 'firebase/firestore';

import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import uuid from 'react-uuid';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import DatePicker from "react-datepicker";
import queryString from 'query-string';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { queryLogItems, queryPSItems, queryStocks } from '../../../../firebase/config';
import PSItem from '../../../../account/productsAndServices/psItem';
import Units from '../../../../extra/units.json';
import ConcentrationUnits from '../../../../extra/concentrationUnits.json';
import LogApplicationMap from './logApplicationMap';

import { ReactSortable } from "react-sortablejs";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';


var randomColor = require('randomcolor');

const getMuiTheme = () =>
    createTheme({
        components: {
            MUIDataTable: {
                styleOverrides: {
                    root: {
                        padding: '8px',
                        boxShadow: 'none'
                    }
                }
            },
            MuiTableRow: {
                styleOverrides: {
                    root: {
                        zIndex: 100,
                        cursor: 'pointer',
                        fontSize: '10px',
                        '&:nth-child(even)': {
                            backgroundColor: '#40405024'
                        },
                        "&:hover": {
                            backgroundColor: "#9ec1c933"
                        }


                    },


                }
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        fontSize: '12px',
                        padding: '2px'
                    }
                }
            }

        }
    });

export default function LogChemicals(props) {
    const { userInfo, screenWidth, openSnack, company, account, updateLog, logRef, status } = props;
    const [staff, setStaff] = useState([]);
    const [log, setLog] = useState(logRef);
    const [items, setItems] = useState([])
    const [itemModal, setItemModal] = useState(false);
    const [newItem, setNewItem] = useState(false);
    const [newItemType, setNewItemType] = useState('');
    const [stocks, setStocks] = useState([])
    const [concentrationsList, setConcentrationsList] = useState([]);
    const [data, setData] = useState([]);

    useEffect(() => {
        getInfo()

    }, [])
    useEffect(() => {
        setLog(logRef)


    }, [logRef])




    useEffect(() => {
        if (status === 'built') {
            console.log("changing")
            if (data.length > 0 && items.length > 0) {
                purgeData(data, items)
            }
            else {
                const data = [];
                localStorage.setItem('chemicalData', JSON.stringify(data))
                console.log("NO Chems")
            }

        }
    }, [status])

    async function getInfo() {

        const items = await queryPSItems(userInfo);
        //filter items by item?.productType?.label === 'Chemical'
        const filteredItems = items.filter(item => item?.psType === 'products' && item?.productType?.label === 'Chemical')


        const newItems = filteredItems.map((item) => {
            return {
                ...item,
                label: item.name,
            }
        })





        setItems(newItems)
        const stocks = await queryStocks(userInfo)
        setStocks(stocks)
        const concentrationsList = Object.values(ConcentrationUnits).map((u, i) => {
            return ({ label: u, key: u, unitid: Object.keys(Units)[i] })
        })
        setConcentrationsList(concentrationsList)

        if (log.key !== undefined) {
            const data = await queryLogItems(userInfo, log.key)



            localStorage.setItem('chemicalData', JSON.stringify(data))
            console.log("LOAD ITEMS")
            console.log(data)
            setData(data)

            if (data.length > 0 && newItems.length > 0) {

                purgeData(data, newItems)


            }
        }

    }





    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: (option) => option.label,
    });

    const AddRowButton = memo(() => {


        const handleAddRow = () => {

            const newData = [...data,
            {
                key: uuid(), time: '', archived: false, editing: false, logkey: log.key || log.tempKey, description: '', psType: 'products',
                account: userInfo.currentAccount, company: userInfo.currentCompany, user: userInfo.id
            }];
            setData(newData);

        }

        return (
            <Button size="small"
                variant="contained" color="primary"
                style={{ textTransform: 'none', fontSize: 14, backgroundColor: '#404050' }}
                onClick={handleAddRow} >+ Add Chemical</Button >
        )
    })


    function updateData(key, item, val) {
        const index = data.map((e) => e.key).indexOf(key);
        const newData = [...data];
        newData[index][item] = val;
        setData(newData);




        localStorage.setItem('chemicalData', JSON.stringify(newData))


    }

    async function purgeData(list, itemsList) {
        const db = getFirestore();
        //remove all rows that are missing any data or have undefined data for item

        const newData = list.filter((row) => {
            return row.product !== undefined
        });



        setData(newData);
        console.log(newData)
        console.log(itemsList)

        const products = newData.map((row) => {
            const product = itemsList?.find(item => item.key === row.product)
            return { ...product, label: product?.name }
        }
        )
        console.log(products)
        const uniqueProducts = [...new Set(products.map(item => item.label))]
        //create a string from this list 

        //create product string with commas and & between if length > 2
        const productString = uniqueProducts.length > 2 ? uniqueProducts.slice(0, -1).join(', ') + ' & ' + uniqueProducts.slice(-1) : uniqueProducts.join(', ')
        console.log(productString)
        updateLog('products', productString)
        if (log.key !== undefined) {
            const docRef = doc(db, "clients", userInfo.currentCompany, 'fieldDocs', log.key || log.tempKey);
            updateDoc(docRef, {
                products: productString
            }).then(() => {
                console.log('updated products')
            })
        }





        const uploadData = newData.map(async (row) => {

            const item = {
                ...row,
                time: moment(log.startDate, 'x').format('YYYY-MM-DD HH:mm')
            }
            return item
        }
        )


        //resolve all promises in uploadData
        const resolvedData = await Promise.all(uploadData)
        localStorage.setItem('chemicalData', JSON.stringify(resolvedData))

        resolvedData.forEach(async (f, i) => {
            const docRef = doc(db, "clients", userInfo.currentCompany, 'flItems', f.key);
            await setDoc(docRef, f).then(() => {


            }).catch((error) => {
                console.error("Error updating document: ", error);
            });

            //if last one
            if (i === resolvedData.length - 1) {

                localStorage.setItem('chemicalData', JSON.stringify(resolvedData))
            }

        })





    }





    return (
        <Row gutter={[12, 12]}>
            <Col {...fullMobile(12, 12)} style={{ paddingTop: 30 }}>
                <Typography display="inline" variant='h5' fontWeight={500} fontSize={screenWidth === 'xs' ? 16 : 22}>Chemicals</Typography>



            </Col>
            <Col {...fullMobile(12, 12)} style={{ paddingTop: 30, textAlign: 'right' }}>
                {status !== 'built' && (<AddRowButton />)}


            </Col>
            <Col {...fullMobile(24, 24)} style={{}}>
                <Row gutter={[12, 12]}>

                    <ReactSortable list={data} setList={setData} animation={200} handle=".handle" ghostClass="ghost">

                        {data?.length > 0 && data.map((row, index) => {

                            const product = items?.find(item => item.key === row.product)


                            return (
                                <Col span={24} style={{ padding: 10 }}>
                                    <Row gutter={[12, 12]} key={row.key} style={{ border: '1px solid gray', borderRadius: 12, padding: 15 }}>

                                        {status !== 'built' && (<Col {...fullMobile(1, 1)}>
                                            <DragIndicatorIcon className="handle" style={{ color: '#404050', fontSize: 20 }} />
                                        </Col>)}

                                        <Col {...fullMobile(status !== 'built' ? 11 : 12, 24)}>
                                            <Row gutter={[12, 12]}>
                                                <Col {...fullMobile(24, 24)} style={{}}>
                                                    <Typography variant="body1" fontWeight={600}>Chemical:</Typography>
                                                    {status === 'built' ? <Typography variant="body1" fullWidth>{items?.find(item => item.key === row.product)?.name}</Typography> :
                                                        <Autocomplete
                                                            size="small"
                                                            id="combo-box-demo"
                                                            defaultValue={row.product}

                                                            options={items}
                                                            inputValue={items?.find(item => item.key === row.product)?.name}
                                                            filterOptions={(options, _ref) => [...filterOptions(options, _ref), { label: 'Add New', key: 'Add New', value: 'addNew' },]}
                                                            noOptionsText={<Button fullWidth variant='contained' color='primary' style={{ textTransform: 'none' }}
                                                                onClick={async () => {
                                                                    const db = getFirestore()
                                                                    const itemRef = doc(db, "users", userInfo.id, 'currentItems', 'currentPS');
                                                                    await setDoc(itemRef, {
                                                                        key: 'none'
                                                                    }).then(() => {
                                                                        setItemModal(true)
                                                                        setNewItem({ tempKey: uuid(), });
                                                                    })

                                                                }}
                                                            >+ Create Item</Button>}

                                                            renderInput={(params) => {
                                                                return (
                                                                    <TextField {...params} label="Product" variant="outlined" />
                                                                )
                                                            }}
                                                            renderOption={(params) => {

                                                                if (params.key === 'Add New') {
                                                                    return (
                                                                        <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 4 }}>
                                                                            <Button fullWidth variant='contained' color='primary' style={{ textTransform: 'none' }} onClick={async (e) => {
                                                                                const db = getFirestore()
                                                                                const itemRef = doc(db, "users", userInfo.id, 'currentItems', 'currentPS');
                                                                                await setDoc(itemRef, {
                                                                                    key: 'none'
                                                                                }).then(() => {

                                                                                    setItemModal(true)
                                                                                    setNewItem({ tempKey: uuid(), });
                                                                                })


                                                                            }}>+ Create Item</Button>
                                                                        </Box>

                                                                    )
                                                                }
                                                                else {
                                                                    return (
                                                                        <Typography {...params}>{params.key}</Typography>
                                                                    )
                                                                }
                                                            }}
                                                            onChange={(a, d) => {

                                                                updateData(row.key, 'product', d.key)



                                                            }}
                                                        />}
                                                </Col>

                                                <Col {...fullMobile(12, 12)}>
                                                    <Typography variant="body1" fontSize={13} fontWeight={600}>Rate</Typography>
                                                    {status === 'built' ? <Typography variant='body2'>{row?.concentration}</Typography> :
                                                        <OutlinedInput
                                                            fullWidth notched={false} label="none" placeholder={"Conc."} size="small"
                                                            defaultValue={row.concentration}
                                                            type="number"
                                                            onChange={(e) => {
                                                                updateData(row.key, 'concentration', e.target.value)


                                                            }} />
                                                    }
                                                </Col>
                                                <Col {...fullMobile(12, 12)}>
                                                    <Typography variant="body1" fontSize={13} fontWeight={600}>Rate Units</Typography>
                                                    {status === 'built' ? <Typography variant='body2'>{row?.concentrationUnits?.label}</Typography> :
                                                        <Autocomplete
                                                            size="small"
                                                            id="combo-box-demo"
                                                            value={row.concentrationUnits}
                                                            options={concentrationsList}
                                                            renderInput={(params) => <TextField {...params} label="Units" />}
                                                            onChange={(a, d) => {
                                                                updateData(row.key, 'concentrationUnits', d)
                                                            }}

                                                        />}
                                                </Col>
                                                <Col {...fullMobile(12, 12)}>
                                                    <Typography variant="body1" fontSize={13} fontWeight={600}>Total Amount</Typography>
                                                    {status === 'built' ? <Typography variant='body2'>{row?.quantity}</Typography> :
                                                        <OutlinedInput
                                                            fullWidth notched={false} label="none" placeholder={"Amount"} size="small"
                                                            defaultValue={row.quantity}
                                                            type="number"
                                                            onChange={(e) => {
                                                                updateData(row.key, 'quantity', e.target.value === "" ? Number(0) : e.target.value === "0" ? Number(0) : Number(e.target.value))

                                                            }} />
                                                    }
                                                </Col>
                                                <Col {...fullMobile(12, 12)}>
                                                    <Typography variant="body1" fontSize={13} fontWeight={600}>Units</Typography>
                                                    {status === 'built' ? <Typography variant='body2'>{row?.units?.label}</Typography> :
                                                        <Autocomplete
                                                            size="small"
                                                            id="combo-box-demo"
                                                            value={row.units}
                                                            options={["mg",
                                                                "g",
                                                                "kg",
                                                                "oz",
                                                                "lb",
                                                                "fl-oz",
                                                                "cup",
                                                                "pnt",
                                                                "qt",
                                                                "gal",
                                                                "ft3",
                                                            ].sort((a, b) => a.localeCompare(b)).map((g) => { return ({ key: g, label: g }) })}
                                                            renderInput={(params) => <TextField {...params} label="Units" />}
                                                            onChange={(a, d) => {
                                                                updateData(row.key, 'units', d);

                                                            }}

                                                        />}




                                                </Col>

                                            </Row>

                                        </Col>
                                        <Col {...fullMobile(12, 24)}>
                                            <Row gutter={[12, 12]}>
                                                <Col {...fullMobile(24, 24)} style={{}}>

                                                    <Row gutter={[12, 12]}>
                                                        <Col {...fullMobile(24, 24)} style={{}}>
                                                            <Typography variant="body1" fontWeight={600}>Stock:</Typography>
                                                            {status === 'built' ? <Typography variant="body1" fullWidth>{row?.supplyStock?.label}</Typography> :
                                                                <Autocomplete
                                                                    size="small"
                                                                    disableCloseOnSelect={false}
                                                                    id="combo-box-demo"
                                                                    defaultValue={row.supplyStock}
                                                                    disableClearable={true}
                                                                    options={stocks}
                                                                    filterSelectedOptions
                                                                    renderInput={(params) => <TextField {...params} label="Stock" />}
                                                                    onChange={(a, d) => {
                                                                        updateData(row.key, 'supplyStock', d)
                                                                    }}
                                                                />}
                                                        </Col>

                                                        <Col {...fullMobile(12, 12)} style={{}}>
                                                            <Typography variant="body1" fontWeight={600}>Product Type:</Typography>
                                                            <Typography variant="body1" fullWidth>{product?.chemicalType?.label}</Typography>
                                                        </Col>
                                                        <Col {...fullMobile(12, 12)} style={{}}>
                                                            <Typography variant="body1" fontWeight={600}>EPA #:</Typography>
                                                            <Typography variant="body1" fullWidth>{product?.chemEPA}</Typography>
                                                        </Col>
                                                        <Col {...fullMobile(12, 12)} style={{}}>
                                                            <Typography variant="body1" fontWeight={600}>State #:</Typography>
                                                            <Typography variant="body1" fullWidth>{product?.chemState}</Typography>
                                                        </Col>
                                                        <Col {...fullMobile(12, 12)} style={{}}>
                                                            <Typography variant="body1" fontWeight={600}>Active Ingredient:</Typography>
                                                            <Typography variant="body1" fullWidth>{product?.chemAI}</Typography>
                                                        </Col>
                                                        {status !== 'built' && (<Col {...fullMobile(24, 24)} style={{ textAlign: 'right' }}>

                                                            <Popconfirm
                                                                title={`Are you sure to delete this chemical?`}
                                                                onConfirm={async () => {
                                                                    data.splice(index, 1)
                                                                    setData([...data])
                                                                    //delete from flItems
                                                                    const db = getFirestore();
                                                                    await deleteDoc(doc(db, "clients", userInfo.currentCompany, 'flItems', row.key))


                                                                }}
                                                                okText="Yes"
                                                                cancelText="No"
                                                            >
                                                                <Button size="small"
                                                                    variant="outlined" color="primary"
                                                                    style={{ textTransform: 'none', fontSize: 14, border: '1px solid #404050', color: 'red' }}
                                                                >Remove Chemical</Button >
                                                            </Popconfirm>



                                                        </Col>)}
                                                    </Row>
                                                </Col>
                                            </Row>

                                        </Col>



                                    </Row>
                                </Col>
                            )
                        })}
                    </ReactSortable>



                </Row>
            </Col>







            <Col {...fullMobile(24, 24)}>
                <Divider style={{
                    borderColor: 'rgb(148, 186, 195)',
                    borderBottomWidth: 'medium',

                }} />
            </Col>


            <Modal title="" visible={itemModal} onCancel={() => {
                setItemModal(false)
                setNewItem({})

            }} footer={null} width={'60%'} style={{ borderRadius: 40 }} >
                <Row>
                    <Col {...fullMobile(24, 24)} style={{ paddingTop: 8 }}>
                        <PSItem item={newItem} userInfo={userInfo} account={account} appItem={true} openSnack={openSnack} closeModal={async () => {
                            setItemModal(false)
                            setNewItem({})
                            const items = await queryPSItems(userInfo)
                            //filter items by item?.productType?.label === 'Chemical'
                            const filteredItems = items.filter(item => item?.psType === 'products' && item?.productType?.label === 'Chemical')


                            const newItems = filteredItems.map((item) => {
                                return {
                                    ...item,
                                    label: item.name,
                                }
                            }
                            )


                            setItems(newItems)
                        }} />
                    </Col>

                </Row>

            </Modal >

        </Row>
    )



}