import React from "react";
import { Button, Divider, Typography, TextField, Box, OutlinedInput, ButtonGroup, List, ListItem, ListItemText, Chip, IconButton, FormControlLabel, Checkbox, Select, MenuItem, Autocomplete } from '@mui/material';
import { Col, Row, Modal } from 'antd';
import { fullMobile } from '../../../util';
import { faCheck, faMapLocationDot, faTimes, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const InspectionForm = (props) => {
    const { nodes, parameters, equipmentList, updateData, inspectionItems, key, unitList, updateDatabase } = props;

    return (
        <div>
            {
                inspectionItems !== undefined && inspectionItems.length > 0 && inspectionItems
                    .map((item, index) => {
                  


                        const station = nodes.find((n) => n.id === item?.nodeid);

                        const parameter = parameters.find((p) => p.key === item?.parameterid);

                        const equipment = equipmentList.find((e) => e.key === item?.equipment);



                        if (item?.type === 'observation') {
                       

                            return (
                                <Row key={index} style={{ marginTop: 10, }} align="middle" gutter={[12, 12]}>
                                    <Col >
                                        <Typography display="inline" variant='body1' fontWeight={600} color="#404050" fontSize={16}>{item.label}: </Typography>


                                        <OutlinedInput display="inline" fullWidth notched={false} label="none" placeholder={"Observation"} size="small"
                                            defaultValue={inspectionItems[index]?.observation}
                                            multiline
                                            style={{ width: 500, marginLeft: 10 }}
                                            maxRows={4}
                                            onBlur={(e) => {
                                                const newInspectionItems = inspectionItems;
                                                newInspectionItems[index].observation = e.target.value;
                                                updateData('inspectionItems', newInspectionItems)
                                            }} />
                                    </Col>
                                    <Col span={24}>
                                        <Divider />
                                    </Col>
                                </Row>


                            )

                        }
                        if (item?.type === 'equipment') {

                            return (
                                <Row key={index} style={{ marginTop: 10 }} align="middle" gutter={[4, 4]}>
                                    <Col span={24} >
                                        <Typography display="inline" variant='body1' fontWeight={600} color="#404050" fontSize={16}>{item.label}
                                            <Typography display="inline" variant="body2" fontSize={14} fontWeight={400} style={{ marginLeft: '5px' }} >
                                                For equipment item {`${equipment?.label} `}
                                            </Typography>
                                        </Typography>
                                    </Col>
                                    <Col span={24} >

                                        <OutlinedInput display="block" fullWidth notched={false} label="none" placeholder={"Observation"} size="small"
                                            value={inspectionItems[index]?.equipmentObservation}
                                            multiline
                                            style={{ width: 500 }}
                                            maxRows={4}
                                            onChange={(e) => {
                                                const newInspectionItems = inspectionItems;
                                                if (newInspectionItems[index]?.equipmentObservation === undefined) {
                                                    newInspectionItems[index].equipmentObservation = "";
                                                }
                                                newInspectionItems[index].equipmentObservation = e.target.value;
                                                updateData('inspectionItems', newInspectionItems)
                                            }} />
                                    </Col>
                                    <Col span={24}>
                                        <Divider />
                                    </Col>
                                </Row>


                            )

                        }

                        if (item?.type === 'checkbox') {

                            return (
                                <Row key={index} style={{ marginTop: 10 }} align="middle" gutter={[12, 12]}>
                                    <Col >
                                        <Typography variant='body1' display="inline" fontWeight={600} color="#404050" fontSize={16}>{item.label}</Typography>


                                        <Checkbox
                                            display="inline"
                                            defaultChecked={inspectionItems[index]?.checked}
                                            onChange={(e) => {
                                                const newInspectionItems = inspectionItems;
                                                newInspectionItems[index].checked = e.target.checked;
                                                updateData('inspectionItems', newInspectionItems)
                                            }}
                                            name="checkedB"
                                            color="primary"
                                        />



                                    </Col>
                                    <Col span={24}>
                                        <Divider />
                                    </Col>
                                </Row>
                            )
                        }
                        if (item?.type === 'select') {

                            const selected = inspectionItems[index]?.selected || '';
                            return (
                                <Row key={index} style={{ marginTop: 10 }} align="middle" gutter={[12, 12]}>
                                    <Col >
                                        <Typography display="inline" variant='body1' fontWeight={600} color="#404050" fontSize={16}>{item.label}:


                                            <Select
                                                display="inline"
                                                style={{ minWidth: 200, marginLeft: 10, backgroundColor: '#ffffff' }}
                                                size="small"
                                                margin='dense'
                                                value={inspectionItems[index]?.selected}
                                                onChange={(e) => {
                                                    const newInspectionItems = inspectionItems;
                                                    newInspectionItems[index].selected = e.target.value;
                                                    updateData('inspectionItems', newInspectionItems)
                                                }}
                                                name="checkedB"
                                                color="primary"
                                                renderValue={(selected) => {
                                                    const option = item.dropdownOptions.find(option => option.id === selected);
                                                    return (
                                                        <Chip key={selected} label={option.label}


                                                            style={{
                                                                backgroundColor: option.tagColor,
                                                                color: option.textColor,
                                                                fontWeight: 500,
                                                                fontSize: 16,
                                                                marginRight: 5
                                                            }} />
                                                    );
                                                }}
                                            >
                                                {item?.dropdownOptions?.map((option, index) => {
                                                    return (
                                                        <MenuItem key={index} value={option.id}
                                                            style={{
                                                                backgroundColor: option?.tagColor,
                                                                color: option?.textColor,
                                                            }}

                                                        >{option.label}
                                                            {selected === option.id && (
                                                                <FontAwesomeIcon icon={faCheck} style={{ marginLeft: 10, color: '#4caf50' }} />
                                                            )}

                                                            {!selected === option.id && (
                                                                <FontAwesomeIcon icon={faTimes} style={{ marginLeft: 10, color: '#f44336' }} />
                                                            )}

                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </Typography>



                                    </Col>
                                    <Col span={24}>
                                        <Divider />
                                    </Col>
                                </Row>
                            )
                        }
                        if (item?.type === 'selectMulti') {

                            const multi = inspectionItems[index]?.selectedMulti || [];
                            return (
                                <Row key={index} style={{ marginTop: 10 }} align="middle" gutter={[12, 12]}>
                                    <Col >
                                        <Typography display="inline" variant='body1' fontWeight={600} color="#404050" fontSize={16}>{item.label}:


                                            <Select
                                                display="inline"
                                                style={{
                                                    minWidth: 200, marginLeft: 10,
                                                    backgroundColor: '#ffffff'
                                                }}
                                                size="small"
                                                multiple
                                                margin='dense'
                                                value={inspectionItems[index]?.selectedMulti || []}
                                                onChange={(e) => {
                                                    const newInspectionItems = inspectionItems;
                                                    newInspectionItems[index].selectedMulti = e.target.value;
                                                    updateData('inspectionItems', newInspectionItems)
                                                }}
                                                name="checkedB"
                                                color="primary"
                                                renderValue={(selected) => (
                                                    <div>
                                                        {selected.map((value) => {
                                                            const option = item.dropdownOptions.find(option => option.id === value);
                                                            return (
                                                                <Chip key={value} label={option.label}


                                                                    style={{
                                                                        backgroundColor: option.tagColor,
                                                                        color: option.textColor,
                                                                        fontWeight: 500,
                                                                        fontSize: 16,
                                                                        marginRight: 5
                                                                    }} />
                                                            );
                                                        })}
                                                    </div>
                                                )}
                                            >
                                                {item?.dropdownOptions?.map((option, index) => {
                                                    return (
                                                        <MenuItem key={index} value={option.id}
                                                            style={{
                                                                backgroundColor: option?.tagColor,
                                                                color: option?.textColor,
                                                            }}

                                                        >{option.label}
                                                            {multi.includes(option.id) && (
                                                                <FontAwesomeIcon icon={faCheck} style={{ marginLeft: 10, color: '#4caf50' }} />
                                                            )}

                                                            {!multi.includes(option.id) && (
                                                                <FontAwesomeIcon icon={faTimes} style={{ marginLeft: 10, color: '#f44336' }} />
                                                            )}

                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </Typography>



                                    </Col>
                                    <Col span={24}>
                                        <Divider />
                                    </Col>
                                </Row>
                            )
                        }
                        if (item?.type === 'section') {
                            return (
                                <Row key={index} style={{ marginTop: 35 }} align="middle" gutter={[4, 4]}>
                                    <Col >
                                        <Typography display="inline" variant='body1' fontWeight={700} color="black" fontSize={20}>{item.label}: </Typography>

                                    </Col>
                                    <Col span={24} style={{ paddingBottom: 4 }}>
                                        <Divider
                                            //thick and black
                                            style={{ backgroundColor: 'rgba(0,0,0,0.8)', height: '3px' }}

                                        />
                                    </Col>

                                </Row>


                            )

                        }
                        if (item?.type === 'data') {



                            return (
                                <Row key={index} style={{ marginTop: 10 }} align="middle" gutter={[4, 4]}>
                                    <Col span={24} >
                                        <Typography display="inline" variant='body1' fontWeight={600} color="#404050" fontSize={16}>{item.label}
                                            <Typography display="inline" variant="body2" fontSize={14} fontWeight={400} style={{ marginLeft: '5px' }} >
                                                {`Node: ${station?.label} and Parameter: ${parameter?.label}`}
                                            </Typography>
                                        </Typography>
                                    </Col>
                                    <Col >

                                        <Typography variant="body1" fontSize={13} fontWeight={700}>Value</Typography>
                                        <OutlinedInput fullWidth notched={false} label="none" placeholder={"Value"} size="small"
                                            value={inspectionItems[index]?.value}

                                            style={{ width: 200 }}
                                            type="number"
                                            onChange={(e) => {
                                                const newInspectionItems = inspectionItems;
                                                newInspectionItems[index].value = e.target.value === "" ? "" : Number(e.target.value);
                                                updateData('inspectionItems', newInspectionItems)

                                            }}
                                            onBlur={(e) => {
                                                updateDatabase(inspectionItems[index])
                                            }}


                                        />
                                    </Col>
                                    <Col >

                                        <Typography variant="body1" fontSize={13} fontWeight={700}>Units</Typography>
                                        <Autocomplete
                                            size="small"
                                            id="combo-box-demo"
                                            style={{ width: 150 }}
                                            disableClearable={false}
                                            value={unitList.find((u) => u.unitid === inspectionItems[index]?.unitid)?.label || ''}
                                            options={unitList}
                                            renderInput={(params) => <TextField {...params} placeholder="Units" size="small" />}
                                            renderOption={(params) => {
                                                return (
                                                    <Typography {...params}>{params.key}</Typography>
                                                )
                                            }}
                                            onBlur={(e) => {
                                                updateDatabase(inspectionItems[index])
                                            }}
                                            onChange={(a, d) => {

                                                if (d) {
                                                    const newInspectionItems = inspectionItems;
                                                    newInspectionItems[index].unitid = d.unitid;
                                                    updateData('inspectionItems', newInspectionItems)
                                                }
                                                else {
                                                    const newInspectionItems = inspectionItems;
                                                    newInspectionItems[index].unitid = null;
                                                    updateData('inspectionItems', newInspectionItems)
                                                }

                                            }}
                                        />

                                    </Col>
                                    <Col span={24}>
                                        <Divider />
                                    </Col>
                                </Row>
                            )
                        }




                    })
            }
        </div>
    )






}

export { InspectionForm };