import { Autocomplete, Button, CircularProgress, Divider, OutlinedInput, Paper, TextField, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import { doc, getDoc, getFirestore, setDoc, updateDoc } from 'firebase/firestore';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import EquipmentFiles from './equipmentFiles';

export default class Equipment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            type: this.props.type,
            item: {},
            loading: true
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }

    }
    componentDidMount() {
        window.scrollTo(0, 0);
        const { userInfo } = this.state;
        this.queryDataDoc(userInfo);
    }

    queryDataDoc = async (userInfo) => {
        const { type } = this.state;
        const db = getFirestore();

        const docRef = doc(db, "users", userInfo.id, 'currentItems', 'currentEquipment');
        const docSnap = await getDoc(docRef);

        if (docSnap.data().key === 'none') {
            this.setState({ item: { tempKey: uuid(), archived: false, account: userInfo.currentAccount, company: userInfo.currentCompany }, loading: false })
        }
        else {
            this.getDocItem(docSnap.data().key)
        }
    }

    getDocItem = async (key) => {
        const { userInfo } = this.state;
        const db = getFirestore();
        const docRef = doc(db, "clients", userInfo.currentCompany, 'equipment', key);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            this.setState({ item: docSnap.data(), loading: false })
        }
    }

    updateItem = (boo, val) => {
        const { item } = this.state;
        item[boo] = val;

        this.setState({ item })
    }
    updateEntireItem = (value) => {
        this.setState({ item: value })
    }

    render() {

        const { userInfo, item, screenWidth, type, loading, account } = this.state;

        const SubmitButton = () => {
            const { item, userInfo } = this.state;
            let navigate = useNavigate();
            return (
                <Button style={{ textTransform: 'none', padding: 16, fontSize: 16 }} fullWidth variant="contained" color="primary"
                    onClick={async () => {
                        if (item.name === undefined || item.name.length === 0) {
                            this.props.openSnack('error', "Name required")
                        }
                        else {
                            const db = getFirestore();
                            const key = item.key || item.tempKey;
                            const docRef = doc(db, "clients", userInfo.currentCompany, 'equipment', key);

                            if (item.key) {
                                await updateDoc(docRef, item).then(() => {
                                    navigate('/equipmentList');
                                }).then(() => {
                                    this.props.openSnack('success', item.key ? "Updated equipment!" : "Created equipment!")
                                })
                            }
                            else {
                                await setDoc(docRef, { ...item, key: item.tempKey }).then(() => {
                                    navigate('/equipmentList');
                                }).then(() => {
                                    this.props.openSnack('success', item.key ? "Updated equipment!" : "Created equipment!")
                                })
                            }



                        }

                    }
                    }>
                    {item.key ? `Update Equipment` : `Create Equipment`}
                </Button >
            )
        }

        const equipmentTypes = [
            { label: 'Pump', key: 'pump' },
            { label: 'Vehicle', key: 'vehicle' },
            { label: 'Electrical Vault', key: 'electricalVault' },
            { label: 'Flow Valve', key: 'flowValve' },
            { label: 'Aeration & Fountains', key: 'Aeration & Fountains' },
        ];


        const Loading = (props) => {
            return (
                <div style={{ minHeight: '100vh' }}>
                    <Row justify="space-around" gutter={[32, 8]} align="middle" style={{ paddingTop: 18, }}>

                        <Col span={24} style={{ textAlign: 'center', fontSize: 24, fontFamily: 'Roboto, sans-serif', }}>
                            <CircularProgress />
                        </Col>
                    </Row>
                </div>
            )
        }

        return (
            <Row style={{}}>
                <Col span={23}>
                    <Row align="middle">
                        <Col xs={16} sm={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>
                            <Typography variant="h4">{item.key ? "Update Equipment" : "Add Equipment"}</Typography>
                        </Col>
                        <Col xs={6} sm={4} style={{ paddingTop: 30, paddingBottom: 15, textAlign: 'right' }}>
                          
                        </Col>
                        <Col xs={16} sm={20}style={{ paddingTop: 15, paddingLeft: 30, }}>
                            <Typography variant="body1">Add an equipment item to track information about that piece of equipment!</Typography>
                        </Col>
                        <Col span={24}>
                            <Divider />
                        </Col>
                    </Row>
                </Col>






                <Col span={23} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 30 }}>
                    <Paper elevation={12} style={{ padding: 26, borderRadius: 12 }}>
                        {loading ? <Loading /> : <Row gutter={[screenWidth === 'xs' ? 4 : 24, screenWidth === 'xs' ? 4 : 24]} align="middle">
                            <Col xs={24} sm={{ offset: 6, span: 20 }}>
                                <Typography variant="h4">Equipment</Typography>
                            </Col>

                            <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                                <Typography variant="body1">Name<span style={{ color: 'red' }}>*</span></Typography>
                            </Col>
                            <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                <OutlinedInput fullWidth notched={false} label="none" placeholder={"Name"} size="small"
                                    value={item.name}
                                    onChange={(e) => {
                                        this.updateItem(`name`, e.target.value)
                                    }} />
                            </Col>
                            <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                                <Typography variant="body1">Description</Typography>
                            </Col>
                            <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                <OutlinedInput fullWidth notched={false} label="none" placeholder={"Description"} size="small"
                                    multiline={true}
                                    rows={4}
                                    value={item.description}
                                    onChange={(e) => {
                                        this.updateItem(`description`, e.target.value)
                                    }} />
                            </Col>
                            <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                                <Typography variant="body1">Type</Typography>
                            </Col>
                            <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                <Autocomplete
                                    size="small"
                                    disablePortal
                                    id="combo-box-demo"
                                    options={equipmentTypes}
                                    defaultValue={item.type}
                                    renderInput={(params) => <TextField {...params} label="Type" />}
                                    onChange={(a, b) => {
                                        console.log(b);
                                        this.updateItem('type', b)
                                    }}

                                />
                            </Col>
                            <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                                <Typography variant="body1">Supplemental</Typography>
                            </Col>
                            <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                <EquipmentFiles storageURL={`${item.key}/files/`} item={item} screenWidth={screenWidth} userInfo={userInfo} account={account} />
                            </Col>







                            <Col xs={24} sm={{ offset: 6, span: 6 }} style={{ textAlign: 'left' }}>
                                <SubmitButton />
                            </Col>



                        </Row>}
                    </Paper>
                </Col>
            </Row>
        );
    }
} 