import { Autocomplete, Breadcrumbs, Button, CircularProgress, Divider, OutlinedInput, TextField, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import { collection, doc, getDoc, getDocs, getFirestore, limit, orderBy, query, where } from 'firebase/firestore';
import moment from 'moment';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import ApplicationPDF from './applicationComponents/applicationPDF';

import axios, * as others from 'axios';
var randomColor = require('randomcolor');

export default class EmailApplciation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            company: this.props.company,
            distributionList: [],
            doc: {},
            emailDoc: {},
            saveLog: false

        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
        if (this.props.company !== prevProps.company) {
            this.setState({ company: this.props.company })
        }


    }
    componentDidMount() {
        window.scrollTo(0, 0)
        const { userInfo, company } = this.state;
        this.queryDataDoc(userInfo);
        this.queryDistribution(userInfo);

        console.log(company)


    }

    queryDataDoc = async (userInfo) => {
        const { type } = this.state;
        const db = getFirestore();
        const docRef = doc(db, "users", userInfo.id, 'currentItems', 'currentFieldDoc');
        const docSnap = await getDoc(docRef);



        if (docSnap.data() === undefined || docSnap.data().key === 'none') {

            //firestore query fieldlogsnew in order of lognumber
            const queryAccountData = query(collection(db, "clients", userInfo.currentCompany, 'FieldDocs'),
                where('archived', '==', false), where('accountKey', '==', userInfo.currentAccount), orderBy("lognumber", "desc"), limit(1));
            const data = [];

            const snap = await getDocs(queryAccountData);
            snap.forEach((doc) => {
                data.push({ ...doc.data() })
            });
            console.log(data)
            //if data length is 0, set lognumber to 1
            const lognumber = data.length === 0 ? 1 : data[0].lognumber + 1;

            this.setState({
                doc: {
                    tempKey: uuid(),
                    status: 'edit',
                    archived: false,
                    type: 'application',
                    company: userInfo.currentCompany,
                    startDate: moment().format('x'),
                    endDate: moment().add(1, 'hours').format('x'),
                    photos: JSON.stringify([]),
                    preview: false,
                    lognumber



                }, loading: false, status: 'creating'
            })

        }
        else {
            this.getDocItem(docSnap.data().key)
            this.setState({ testKey: docSnap.data().key })
        }
    }
    getDocItem = async (key) => {
        const { userInfo } = this.state;
        const db = getFirestore();
        const docRef = doc(db, "clients", userInfo.currentCompany, 'fieldDocs', key);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const item = docSnap.data();
            console.log(item)
            this.setState({ doc: item, loading: false, status: docSnap.data().status === 'edit' ? 'creating' : docSnap.data().status, testKey: key })

        }
    }

    queryDistribution = async () => {
        const { userInfo, type } = this.state;
        const db = getFirestore()
        const queryStaff = query(collection(db, "users",), where('accounts', 'array-contains', userInfo.currentAccount));
        const users = [];
        const snapStaff = await getDocs(queryStaff);
        snapStaff.forEach((doc) => {
            const item = doc.data();
            delete item.accounts;
            delete item.companies;
            users.push({ firstName: item.firstName, lastName: item.lastName, id: item.id, email: item.email })

        });



        const distributionList = []

        const queryStaffList = query(collection(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'staff',));
        const staffSnap = await getDocs(queryStaffList);
        staffSnap.forEach((doc) => {


            const user = users.filter((u) => u.id === doc.data().key)[0];

            const item = { ...user, flDistribution: doc.data().flDistribution };
            distributionList.push(item)

        });

        const queryCustom = query(collection(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'customDistribution',));
        const snapCustom = await getDocs(queryCustom);
        snapCustom.forEach((doc) => {
            distributionList.push(doc.data())

        });



        this.setState({ distributionList, })

    }







    render() {

        const { account, screenWidth, company, doc, userInfo, loading, preview, distributionList, accounts, saving, type, key, testKey, saveLog, emailDoc,
            spinning, showDistribution, colorModal, modalIndex, printScreen, updateWeather, saveWaterQualityData, status } = this.state;

        const Loading = (props) => {
            return (
                <div style={{ minHeight: '100vh' }}>
                    <Row justify="space-around" gutter={[32, 8]} align="middle" style={{ paddingTop: 18, }}>

                        <Col span={24} style={{ textAlign: 'center', fontSize: 24, fontFamily: 'Roboto, sans-serif', }}>
                            <CircularProgress />
                        </Col>
                    </Row>
                </div>
            )
        }



        const ReturnLink = () => {
            let navigate = useNavigate();

            return (
                <Button style={{ textTransform: 'none', fontSize: 12, }} color="inherit" variant='outlined'
                    onClick={() => {
                        navigate('/applicationNew')
                    }}>Email</Button>
            )
        }
        return (


            <Row align="middle" gutter={[12, 12]}>

                <Col xs={16} sm={20} style={{ paddingLeft: screenWidth === 'xs' ? 10 : 30, paddingTop: screenWidth === 'xs' ? 20 : 30, paddingBottom: 15 }}>
                    <Typography variant="h4">Email Application Log</Typography>
                </Col>
                <Col xs={6} sm={4} style={{ paddingTop: 30, paddingBottom: 15, textAlign: 'right' }}>

                </Col>
                <Col span={24}>
                    <Divider />
                </Col>
                <Col span={22} style={{ paddingTop: 10, }}>
                    <Breadcrumbs>
                        <Link to="/chemicalApplications" style={{ paddingLeft: screenWidth === 'xs' ? 10 : 30, textDecoration: 'none', color: 'inherit', }}>
                            Applications
                        </Link>
                        <Link to="/applicationNew" style={{ paddingLeft: screenWidth === 'xs' ? 10 : 30, textDecoration: 'none', color: 'inherit', }}>
                            Application
                        </Link>

                    </Breadcrumbs>
                </Col>
                <Col span={24} style={{ paddingLeft:  30, }}>
                    <Row gutter={[16, 16]}>
                        <Col span={24} style={{ paddingTop: 10, }}>
                            <Divider />
                        </Col>
                        <Col span={6} style={{ paddingTop: 10, }}>
                            <Typography variant="body1">To:</Typography>
                        </Col>
                        <Col span={16} style={{ paddingTop: 10, }}>
                            <Autocomplete multiple

                                id="combo-box-demo"
                                options={distributionList}
                                getOptionLabel={(option) => option.email}
                                defaultValue={[]}
                                filterSelectedOptions
                                ChipProps={{ color: 'primary' }}
                                disableCloseOnSelect
                                onChange={(event, newValue) => {
                                    this.setState({ emailDoc: { ...emailDoc, recipients: newValue } })
                                }}
                                renderInput={(params) => (
                                    <TextField

                                        {...params}
                                        variant="outlined"
                                        label="Recipients"
                                        notched={false}
                                        placeholder="Distribution List"
                                    />
                                )}
                            />
                        </Col>
                        <Col span={6} style={{ paddingTop: 10, }}>
                            <Typography variant="body1">Subject:</Typography>
                        </Col>
                        <Col span={18} style={{ paddingTop: 10, }}>
                            <OutlinedInput
                                id="standard-basic"
                                label="Subject"
                                placeholder='Subject'
                                notched={false}
                                value={emailDoc.subject}
                                onChange={(e) => {
                                    this.setState({ emailDoc: { ...emailDoc, subject: e.target.value } })
                                }}
                            />
                        </Col>
                        <Col span={6} style={{ paddingTop: 10, }}>
                            <Typography variant="body1">Message:</Typography>
                        </Col>
                        <Col span={16} style={{ paddingTop: 10, }}>
                            <OutlinedInput
                                fullWidth
                                id="standard-basic"
                                label="Message"
                                placeholder='Message'
                                notched={false}
                                value={emailDoc.message}
                                onChange={(e) => {
                                    this.setState({ emailDoc: { ...emailDoc, message: e.target.value } })
                                }}
                            />
                        </Col>

                        <Col span={24} style={{ paddingTop: 10, }}>
                            <Divider />
                        </Col>
                        <Col span={24} style={{ paddingTop: 10, }}>
                            {loading === false && (<ApplicationPDF testKey={testKey} emailDoc={emailDoc} doc={doc} userInfo={userInfo} company={company} account={account}
                                openSnack={this.props.openSnack}
                                type="email" doneSaving={() => this.setState({ saveLog: false })} saveLog={saveLog} />)}
                        </Col>
                    </Row>
                </Col>






            </Row >

        );
    }
} 