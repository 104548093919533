import React, { useEffect } from "react";
import { Autocomplete, Input, TextField } from "@mui/material";

const ParameterSelect = ({
    currentStream,
    setCurrentStream,
    headerKey,
    options,
    ProbeParameters
}) => {

    const [probeParameters, setProbeParameters] = React.useState([]);
    const [value, setValue] = React.useState(null);  // Renamed from 'age' to 'value'
    useEffect(() => {
        console.log("THIS THIS THIS")
        const PPArray = Object.values(ProbeParameters).map((p, i) => {
            return ({
                name: p, label: p, key: Object.keys(ProbeParameters)[i], parameterType: 'Probe Parameter'
            })
        });
        //sort based on label
        PPArray.sort((a, b) => a.label.localeCompare(b.label))

        setProbeParameters(PPArray)
        setValue(PPArray.find(p => p.key === currentStream?.[headerKey]))
    }, [])


    const handleChange = (selectedOption) => {
        if (selectedOption) {
            console.log({ ...currentStream, [headerKey]: selectedOption.key });
            setCurrentStream({ ...currentStream, [headerKey]: selectedOption.key });
            setValue(selectedOption);  // <- Set the entire option as the value
        } else {
            setValue(null);
        }
    };



    return (
        <Autocomplete
            sx={{
                groupLabel: {
                    fontWeight: 'bold',
                    fontSize: 20
                }
            }
            }

            size="small"
            style={{ backgroundColor: 'white' }}
            disableClearable={true}
            id="combo-box-demo"
            value={value}
            getOptionLabel={(option) => option.name}
            options={
                probeParameters

            }
            filterSelectedOptions
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder={"Parameters"}
                    size="small"
                    inputProps={{
                        ...params.inputProps,
                        style: {
                            color: 'black',
                            fontWeight: 500
                        }
                    }}
                />
            )}
            onChange={(event, newValue) => {
                handleChange(newValue);  // <- Pass the entire option
            }}

        />
    );
};

export default ParameterSelect;
