import { Button, Checkbox, Divider, OutlinedInput, Typography, Select, MenuItem, CircularProgress, Paper, Input, FormControl, TextField, IconButton, Box, InputLabel, Tooltip, Dialog, DialogTitle, DialogActions } from '@mui/material';
import { Col, Popconfirm, Row } from 'antd';
import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { fullMobile } from './util';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, } from 'firebase/firestore';

import MUIDataTable from "mui-datatables";
import uuid from 'react-uuid';
import DataColorCompact from '../../account/account/dashboard/settings/dataColorCompact';
import axios, * as others from 'axios';
import queryString from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrosshairs, faEye, faEyeSlash, faSync, faClone, faTrash, faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import NodeAlerts from './NodeAlerts';
import { checkAlert, createAlert, getAlerts } from './firestore';

const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option) => option.label,
});

const StationDetails = ({
    userInfo,
    reloadStations,
    screenWidth,
    openSnack,
    company,
    account,
    currentItem,
    setCurrentItem,
    updateItems,
    probes,
    osts,
    deleteItem,
    currentChild,
    setCurrentChild,
    updateChildren,
    saveItemProps,
    saveChildProps,
    selectChild,
    swapItems,
    items,
    setItems,
    updateArchivedItems,
    archiveItem,
    restoreItem,
    centerMapOnStation }) => {
    const [loading, setLoading] = useState(true);
    const [locations, setLocations] = useState([]);
    const [allowSave, setAllowSave] = useState(saveItemProps);
    const [allowChildSave, setAllowChildSave] = useState(saveChildProps);
    const [takenProbes, setTakenProbes] = useState([]);
    const [alerts, setAlerts] = useState([]);
    const [deleteDialog, setDeleteDialog] = useState(false);

    const db = getFirestore();

    //volume and area should auto populate
    //volume will be acft/cubic feet/gal


    async function copyStation() {
        const { children, lat, lng, color, name, } = currentItem
        const copyStation = {
            name: `${name}-copy`,
            key: uuid(),
            id: uuid(),
            children: children.map(child => {
                return {
                    ...child,
                    name: `${child}-copy`
                }
            }),
            lat: lat,
            lng: lng,
            color: color,
            type: '',
            visible: true,
            archived: false,
            exists: false,
            account: userInfo.currentAccount,
            company: userInfo.currentCompany,
            accountIndex: items.length,
            deleteItem: false,





        }
        const newItems = [...items, copyStation]
        setItems(newItems)
        const db = getFirestore();
        const docRef = doc(db, "clients", userInfo.currentCompany, 'stations', copyStation.key);
        await setDoc(docRef, copyStation);



        reloadStations()
        setCurrentItem(copyStation)
    }






    //update lat and lng



    async function getInfo() {
        const alerts = await getAlerts(userInfo.currentCompany, currentChild?.id)

        setAlerts(alerts);

    }


    function isArray(value) {
        return value && typeof value === 'object' && value.constructor === Array;
    }

    function handleClose() {
        setDeleteDialog(false)
    }


    //if currentitem is not null, then show the details


    return (
        <Row style={{ fontFamily: 'Roboto, sans-serif', height: '95vh', }} gutter={[0, 0]}>
            <Col {...fullMobile(24, 24)} style={{}}>
                <Paper elevation={3} style={{ padding: 10, height: '100%', overflow: 'auto' }}>
                    {Object.keys(currentItem || {}).length > 0 && (
                        <>
                            <Row
                                align={'bottom'}
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    //align items to the bottom of the row


                                }}>
                                <Col span={12}>

                                </Col>

                                <Col xs={12} style={{
                                    textAlign: 'right',
                                    marginTop: 10,
                                    display: 'inline'
                                }} >


                                    <IconButton
                                        onClick={() => {

                                        }}
                                    >
                                        <Tooltip title="Delete Task" placement="bottom">
                                            <FontAwesomeIcon size="sm" icon={faTrash} style={{ color: 'rgba(0,0,0,0.5)', marginRight: 8 }} />
                                        </Tooltip>

                                    </IconButton>


                                    <IconButton
                                        onClick={() => {

                                        }}
                                    >
                                        <Tooltip title="Close" placement="bottom">
                                            <FontAwesomeIcon size="sm" icon={faArrowRightFromBracket} style={{ color: 'rgba(0,0,0,0.5)', }} />
                                        </Tooltip>

                                    </IconButton>


                                </Col>


                                <Dialog
                                    open={deleteDialog}
                                    onClose={handleClose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title">
                                        Are you sure you want to delete this station?
                                    </DialogTitle>

                                    <DialogActions>
                                        <Button style={{
                                            textTransform: 'none',
                                        }} onClick={handleClose}>Cancel</Button>
                                        <Button
                                            style={{
                                                fontWeight: 600,
                                                textTransform: 'none',
                                                color: 'red'
                                            }}

                                            onClick={() => {
                                                console.log('delete station')
                                                handleClose()
                                            }}
                                            autoFocus>
                                            Delete
                                        </Button>
                                    </DialogActions>
                                </Dialog>


                            </Row>









                            <Row>
                                <Col {...fullMobile(20, 20)} style={{ height: '10%' }}>
                                    <Typography variant="h5" component="body1" display="inline" gutterBottom color={'#404050'} >
                                        Station Details
                                        <FontAwesomeIcon fontSize={20} style={{ marginLeft: 5, cursor: 'pointer' }}
                                            icon={faCrosshairs} color="#404050" onClick={() => {
                                                //center map on lat and lng
                                                //openSnack("Centering map on station location", "info");
                                                centerMapOnStation(currentItem)
                                            }} />
                                        <FontAwesomeIcon fontSize={20} style={{ marginLeft: 7, cursor: 'pointer' }}
                                            icon={faClone} color="#404050" onClick={() => {
                                                copyStation()

                                            }} />

                                    </Typography>
                                </Col>
                                <Col {...fullMobile(4, 4)} style={{ height: '10%' }}>
                                    <IconButton
                                        onClick={() => {
                                            updateItems({ ...currentItem, visible: !currentItem?.visible });

                                        }}>
                                        <FontAwesomeIcon fontSize={18}
                                            icon={currentItem?.visible === true ? faEye : faEyeSlash} color="#9ec1c9" />
                                    </IconButton>
                                </Col>
                                <Col {...fullMobile(24, 24)} style={{ height: '80vh', overflowY: 'scroll', padding: 5 }}>

                                    <Row gutter={[4, 4]}>
                                        <Col {...fullMobile(24, 24)} style={{}}>
                                            <Typography variant="body1" display="inline" fontSize={13} fontWeight={600} style={{}}>Station Name:</Typography>

                                        </Col>
                                        <Col {...fullMobile(19, 19)} style={{ paddingBottom: 5 }}>
                                            <Input value={currentItem?.name}
                                                disabled={currentItem?.name === 'Archived' || currentItem?.archived === true}
                                                style={{ fontSize: 13, fontWeight: 400 }}
                                                onChange={(e) => {
                                                    setCurrentItem({ ...currentItem, name: e.target.value });
                                                    updateItems({ ...currentItem, name: e.target.value });
                                                    //update items in props

                                                }} />
                                        </Col>
                                        {currentItem?.archived !== true && (

                                            <Col {...fullMobile(5, 5)} style={{ paddingBottom: 5, position: 'relative', zIndex: 300 }}>
                                                <DataColorCompact color={currentItem?.color} stations={true} updateData={(boo, val) => {

                                                    updateItems({ ...currentItem, color: val })

                                                }} />
                                            </Col>

                                        )}

                                        <Col {...fullMobile(12, 22)} style={{}}>
                                            <Typography variant="body1" display="inline" fontSize={13} fontWeight={600} style={{}}>Latitude:</Typography>
                                            <Input value={currentItem?.lat}
                                                disabled={currentItem?.name === 'Archived' || currentItem?.archived === true}
                                                style={{ fontSize: 11, fontWeight: 400 }}
                                                type="number"
                                                onChange={(e) => {
                                                    setCurrentItem({ ...currentItem, lat: Number(e.target.value) });
                                                    updateItems({ ...currentItem, lat: Number(e.target.value) });
                                                }} />
                                        </Col>


                                        <Col {...fullMobile(12, 22)} style={{}}>
                                            <Typography variant="body1" display="inline" fontSize={13} fontWeight={600} style={{}}>Longitude:</Typography>
                                            <Input value={currentItem?.lng}
                                                disabled={currentItem?.name === 'Archived' || currentItem?.archived === true}
                                                style={{ fontSize: 11, fontWeight: 400 }}
                                                type="number"
                                                onChange={(e) => {
                                                    if (currentItem === null || currentItem === undefined) {
                                                        return
                                                    }
                                                    const newItem = { ...currentItem }
                                                    newItem.lng = Number(e.target.value)
                                                    setCurrentItem(newItem);
                                                    updateItems(newItem);
                                                }} />
                                        </Col>

                                        {/* <Col {...fullMobile(22, 22)} style={{}}>
                                    <Typography variant="body1" display="inline" fontSize={13} fontWeight={600} style={{}}>Link location to node:</Typography>
                                    <Checkbox
                                        disabled={currentItem?.name === 'Archived' || currentItem?.archived === true}
                                        checked={currentItem?.linkLocation || false}
                                        onChange={(e) => {
                                            console.log(e.target.checked)
                                            setCurrentItem({ ...currentItem, linkLocation: e.target.checked });
                                            updateItems({ ...currentItem, linkLocation: e.target.checked });
                                        }} />
                                </Col> */}
                                        {/*    {currentItem?.linkLocation && (
                                  
                                )} */}


                                        {Object.keys(currentChild || {}).length > 0 && (
                                            <Row gutter={[4, 4]}>
                                                <Col {...fullMobile(22, 22)} style={{ paddingTop: 10 }}>
                                                    <Divider style={{}} />
                                                </Col>
                                                <Col {...fullMobile(24, 24)} style={{}}>
                                                    <Typography variant="h6" fontWeight={500} component="body1" display="inline" color={'#404050'}>
                                                        Node
                                                    </Typography>
                                                </Col>
                                                <Col {...fullMobile(24, 24)} style={{}}>
                                                    <Tabs>
                                                        <TabList>
                                                            <Tab>Details</Tab>
                                                            <Tab>Alerts ({alerts.length})</Tab>

                                                        </TabList>
 
                                                        <TabPanel>
                                                            <Row>
                                                                <Col {...fullMobile(24, 24)} style={{}}>
                                                                    <Typography variant="body1" display="inline" fontSize={13} fontWeight={600} style={{}}>Node Name:</Typography>

                                                                </Col>
                                                                <Col {...fullMobile(22, 22)} style={{ paddingBottom: 5 }}>
                                                                    <Input value={currentChild?.name}
                                                                        disabled={currentItem?.name === 'Archived' || currentItem?.archived === true}
                                                                        style={{ fontSize: 13, fontWeight: 400 }}
                                                                        fullWidth
                                                                        onChange={(e) => {
                                                                            setCurrentChild({ ...currentChild, name: e.target.value });
                                                                            updateChildren({ ...currentChild, name: e.target.value }, currentItem, false);
                                                                            //update items in props

                                                                        }} />
                                                                </Col>


                                                                <Col {...fullMobile(11, 24)} style={{ marginTop: 15 }}>
                                                                    <Typography variant="body1" display="inline" fontSize={13} fontWeight={600} style={{}}>Depth:</Typography>
                                                                    <Input value={currentChild?.depth} display="inline"
                                                                        style={{ width: '100%', fontSize: 13, fontWeight: 400 }}
                                                                        disabled={currentItem?.name === 'Archived' || currentItem?.archived === true}
                                                                        type='number'
                                                                        onChange={(e) => {
                                                                            setCurrentChild({ ...currentChild, depth: e.target.value === "" ? "" : Number(e.target.value) });
                                                                            updateChildren({ ...currentChild, depth: e.target.value === "" ? "" : Number(e.target.value) }, currentItem, false);
                                                                            //update items in props

                                                                        }}
                                                                        endAdornment={(
                                                                            <Select display="inline"
                                                                                disabled={currentItem?.name === 'Archived' || currentItem?.archived === true}
                                                                                variant='standard'
                                                                                size="small"
                                                                                labelId="demo-simple-select-standard-label"
                                                                                id="demo-simple-select-standard"
                                                                                defaultValue={currentChild?.depthUnit}
                                                                                value={currentItem?.depthUnit}
                                                                                onChange={(e) => {
                                                                                    setCurrentItem({ ...currentItem, depthUnit: e.target.value });
                                                                                    updateChildren({ ...currentItem, depthUnit: e.target.value }, currentItem, false);
                                                                                }}
                                                                                focu
                                                                                style={{ fontSize: 12, fontWeight: 300 }}
                                                                            >
                                                                                <MenuItem value={'ft'}>ft</MenuItem>
                                                                                <MenuItem value={'m'}>m</MenuItem>
                                                                            </Select>
                                                                        )} />

                                                                </Col>
                                                                <Col {...fullMobile(12, 24)} style={{ marginTop: 15, paddingLeft: 5 }}>
                                                                    <Typography variant="body1" display="inline" fontSize={13} fontWeight={600} style={{}}>Offset:</Typography>
                                                                    <Input value={currentChild?.offset || 0} display="inline"
                                                                        style={{ width: '100%', fontSize: 13, fontWeight: 400 }}
                                                                        disabled={currentItem?.name === 'Archived' || currentItem?.archived === true}
                                                                        type='number'
                                                                        onChange={(e) => {
                                                                            setCurrentChild({ ...currentChild, offset: e.target.value === "" ? "" : Number(e.target.value) });
                                                                            updateChildren({ ...currentChild, offset: e.target.value === "" ? "" : Number(e.target.value) }, currentItem, false);
                                                                            //update items in props

                                                                        }}
                                                                        endAdornment={(
                                                                            <Select display="inline"
                                                                                disabled={currentItem?.name === 'Archived' || currentItem?.archived === true}
                                                                                variant='standard'
                                                                                size="small"
                                                                                labelId="demo-simple-select-standard-label"
                                                                                id="demo-simple-select-standard"
                                                                                defaultValue={currentChild?.offsetUnit}
                                                                                value={currentItem?.offsetUnit}
                                                                                onChange={(e) => {
                                                                                    setCurrentItem({ ...currentItem, offsetUnit: e.target.value });
                                                                                    updateChildren({ ...currentItem, offsetUnit: e.target.value }, currentItem, false);
                                                                                }}
                                                                                focu
                                                                                style={{ fontSize: 12, fontWeight: 300 }}
                                                                            >
                                                                                <MenuItem value={'ft'}>ft</MenuItem>
                                                                                <MenuItem value={'m'}>m</MenuItem>
                                                                            </Select>
                                                                        )} />

                                                                </Col>
                                                                <Col {...fullMobile(24, 24)} style={{ marginTop: 15 }}>
                                                                    <Typography variant="body1" display="inline" fontSize={13} fontWeight={600} style={{}}>Node Type:</Typography>

                                                                </Col>
                                                                <Col {...fullMobile(22, 22)} style={{ paddingBottom: 5 }}>
                                                                    <Select value={isArray(currentChild?.type) ? currentChild?.type : [currentChild?.type]}
                                                                        size="small"
                                                                        disabled={currentItem?.name === 'Archived' || currentItem?.archived === true}
                                                                        style={{ fontSize: 13, fontWeight: 400 }}
                                                                        fullWidth
                                                                        multiple={true}


                                                                        onChange={(e) => {
                                                                            const type = e.target.value.filter((e) => e !== 'both');
                                                                            setCurrentChild({
                                                                                ...currentChild, type,
                                                                                activelyReporting: type.some((element) => ["sensor", "ostSensor"].includes(element)) ? true : false,
                                                                            });
                                                                            updateChildren({
                                                                                ...currentChild, type,
                                                                                activelyReporting: type.some((element) => ["sensor", "ostSensor"].includes(element)) ? true : false,
                                                                            }, currentItem, false);
                                                                            //update items in props

                                                                        }} >
                                                                        <MenuItem sx={{
                                                                            '&.Mui-selected': {
                                                                                backgroundColor: '#ff65001f',
                                                                                color: '#404050',
                                                                                fontWeight: 600,
                                                                                fontSize: 15
                                                                            }
                                                                        }} value={'sensor'}>Sensor</MenuItem>
                                                                        <MenuItem sx={{
                                                                            '&.Mui-selected': {
                                                                                backgroundColor: '#ff65001f',
                                                                                color: '#404050',
                                                                                fontWeight: 600,
                                                                                fontSize: 15
                                                                            }
                                                                        }} value={'grab'}>Grab</MenuItem>

                                                                        <MenuItem sx={{
                                                                            '&.Mui-selected': {
                                                                                backgroundColor: '#ff65001f',
                                                                                color: '#404050',
                                                                                fontWeight: 600,
                                                                                fontSize: 15
                                                                            }
                                                                        }} value={'ostSensor'}>OST Sensor</MenuItem>
                                                                        <MenuItem sx={{
                                                                            '&.Mui-selected': {
                                                                                backgroundColor: '#ff65001f',
                                                                                color: '#404050',
                                                                                fontWeight: 600,
                                                                                fontSize: 15
                                                                            }
                                                                        }} value={'application'}>Application</MenuItem>
                                                                        <MenuItem sx={{
                                                                            '&.Mui-selected': {
                                                                                backgroundColor: '#ff65001f',
                                                                                color: '#404050',
                                                                                fontWeight: 600,
                                                                                fontSize: 15
                                                                            }
                                                                        }} value={'maintenance'}>Maintenance</MenuItem>
                                                                    </Select>
                                                                </Col>

                                                                {(Array.isArray(currentChild?.type)
                                                                    ? currentChild?.type
                                                                    : [currentChild?.type]
                                                                ).some((element) => ["sensor", "ostSensor"].includes(element)) && (
                                                                        < Col {...fullMobile(24, 24)} style={{ marginTop: 5 }}>
                                                                            <Typography variant="body1" display="inline" fontSize={13} fontWeight={600} style={{}}>Actively Reporting:</Typography>
                                                                            <Checkbox
                                                                                disabled={currentItem?.name === 'Archived' || currentItem?.archived === true}
                                                                                checked={currentChild?.activelyReporting === false ? false : true}
                                                                                onChange={(e) => {
                                                                                    setCurrentChild({ ...currentChild, activelyReporting: e.target.checked });
                                                                                    updateChildren({ ...currentChild, activelyReporting: e.target.checked }, currentItem, false);
                                                                                }} />

                                                                        </Col>
                                                                    )}
                                                                {(Array.isArray(currentChild?.type)
                                                                    ? currentChild?.type
                                                                    : [currentChild?.type]
                                                                ).some((element) => ["sensor", "ostSensor"].includes(element)) && (
                                                                        < Col {...fullMobile(24, 24)} style={{ marginTop: 5 }}>
                                                                            <Typography variant="body1" display="inline" fontSize={13} fontWeight={600} style={{}}>Node Sensor:</Typography>
                                                                            <Autocomplete
                                                                                disabled={currentItem?.name === 'Archived' || currentItem?.archived === true}


                                                                                size="small"
                                                                                disablePortal
                                                                                id="combo-box-demo"
                                                                                options={currentChild?.type.includes('ostSensor') ? osts : probes}
                                                                                value={currentChild?.sensor}
                                                                                filterOptions={(options, _ref) => [...filterOptions(options, _ref),
                                                                                { label: 'Clear', key: 'Add New', value: 'addNew' },]}

                                                                                renderInput={(params) => (
                                                                                    <TextField
                                                                                        {...params}
                                                                                        placeholder="Sensor"
                                                                                        variant="outlined"
                                                                                    />
                                                                                )}
                                                                                renderOption={(params) => {

                                                                                    if (params.key === 'undefined') {
                                                                                        return (
                                                                                            <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 4 }}>
                                                                                                <Button fullWidth variant='contained' color='primary' style={{ textTransform: 'none' }} onClick={(e) => {

                                                                                                    setCurrentChild({ ...currentChild, sensor: null, });
                                                                                                    updateChildren({ ...currentChild, sensor: null, }, currentItem, false);

                                                                                                }}>Clear Sensor</Button>
                                                                                            </Box>

                                                                                        )
                                                                                    }
                                                                                    else {
                                                                                        return (
                                                                                            <Typography {...params}>{params.key}</Typography>
                                                                                        )
                                                                                    }
                                                                                }}
                                                                                getOptionLabel={(option) => option.name}
                                                                                onChange={async (a, b) => {


                                                                                    if (b === null) {
                                                                                        setCurrentChild({ ...currentChild, sensor: null });
                                                                                        updateChildren({ ...currentChild, sensor: null }, currentItem, false);
                                                                                    }
                                                                                    else {
                                                                                        if (b.probeType === "Vu-Link") {
                                                                                            const alert = {
                                                                                                id: 'batteryAlert-' + currentChild?.id + '-33',
                                                                                                name: `Low Battery Alert`,
                                                                                                node: currentChild?.id,
                                                                                                company: userInfo.currentCompany,
                                                                                                account: userInfo.currentAccount,
                                                                                                createdDate: moment().format('MM/DD/YYYY HH:mm:ss'),
                                                                                                createdBy: userInfo.id,
                                                                                                parameter: '33',
                                                                                                value: '20',
                                                                                                type: 'below',
                                                                                                active: true,
                                                                                            }
                                                                                            const locationDriftAlert = {
                                                                                                id: 'locationDrift-' + currentChild?.id + '-33',
                                                                                                name: `Location Drift`,
                                                                                                node: currentChild?.id,
                                                                                                company: userInfo.currentCompany,
                                                                                                account: userInfo.currentAccount,
                                                                                                createdDate: moment().format('MM/DD/YYYY HH:mm:ss'),
                                                                                                createdBy: userInfo.id,
                                                                                                parameter: 'none',
                                                                                                value: 'none',
                                                                                                type: 'locationDrift',
                                                                                                active: true,
                                                                                            }

                                                                                            const check = await checkAlert(alert)

                                                                                            if (check === false) {
                                                                                                await createAlert(alert);
                                                                                            }
                                                                                            /* const check2 = await checkAlert(locationDriftAlert)
                                                                                            console.log(check2)
                                                                                            if (check2 === false) {
                                                                                                await createAlert(locationDriftAlert);
                                                                                            } */

                                                                                        }
                                                                                        delete b.marker;
                                                                                        setCurrentChild({ ...currentChild, sensor: b });
                                                                                        updateChildren({ ...currentChild, sensor: b }, currentItem, false);
                                                                                    }

                                                                                }}
                                                                                InputProps={{ style: { fontSize: 6 } }}


                                                                            />
                                                                        </Col>
                                                                    )}
                                                            </Row>
                                                        </TabPanel>
                                                        <TabPanel>
                                                            <NodeAlerts nodeid={currentChild?.id} parentid={currentItem?.key} userInfo={userInfo} updateAlerts={getInfo} />
                                                        </TabPanel>
                                                    </Tabs>
                                                </Col>









                                                <Col {...fullMobile(22, 22)} style={{ paddingBottom: 15, paddingTop: 15 }}>
                                                    <Divider style={{}} />
                                                </Col>
                                                {currentItem?.archived === false && (<Col {...fullMobile(24, 24)} style={{ height: '10%' }}>

                                                    {allowChildSave && (<Button variant="contained" display="inline" style={{ marginRight: 10, textTransform: 'none', backgroundColor: '#404050', fontSize: 14 }} onClick={() => {
                                                        updateChildren(currentChild, currentItem, false);
                                                        setAllowChildSave(false);
                                                    }}>Save Node</Button>)}

                                                    <Popconfirm
                                                        title="Are you sure to delete this Node?"
                                                        onConfirm={async () => {
                                                            updateChildren(currentChild, currentItem, true);
                                                            setCurrentChild({});

                                                        }
                                                        }>

                                                        <Button variant="outlined" display="inline" style={{ marginRight: 10, textTransform: 'none', fontSize: 14, border: '1px solid #404050' }} onClick={() => {

                                                        }}>Delete Node</Button>

                                                    </Popconfirm >

                                                </Col>)}


                                            </Row>
                                        )}






                                    </Row>
                                </Col>





                                {currentItem?.archived === false && (<Col {...fullMobile(24, 24)} style={{ height: '10%' }}>
                                    {allowSave && (<Button variant="contained" display="inline" style={{ marginRight: 10, textTransform: 'none', backgroundColor: '#404050', fontSize: 14 }} onClick={() => {
                                        updateItems(currentItem);
                                        setAllowSave(false);
                                    }}>Save Station</Button>)}
                                    {currentItem?.id !== 'archived' && (<Popconfirm
                                        title="Are you sure to archive this Station?"
                                        onConfirm={async () => {
                                            updateItems({ ...currentItem, });
                                            archiveItem({ ...currentItem, });
                                            setCurrentItem({});

                                        }
                                        }>

                                        <Button variant="outlined" display="inline" style={{ marginRight: 10, textTransform: 'none', fontSize: 14, border: '1px solid #404050' }} onClick={() => {

                                        }}>Archive Station</Button>

                                    </Popconfirm >)}


                                </Col>)}
                                {currentItem?.archived === true && (<Col {...fullMobile(24, 24)} style={{ height: '10%' }}>
                                    <Button variant="outlined" display="inline" style={{ marginRight: 10, textTransform: 'none', fontSize: 14, border: '1px solid #404050' }} onClick={() => {
                                        restoreItem(currentItem);
                                        setCurrentItem(currentItem);
                                    }}>Restore </Button>

                                    <Popconfirm
                                        title="Are you sure to delete this Node?"
                                        onConfirm={async () => {
                                            deleteItem(currentItem);
                                            setCurrentItem({});

                                        }
                                        }>

                                        <Button variant="contained"
                                            display="inline" style={{ marginRight: 10, textTransform: 'none', fontSize: 14, backgroundColor: '#404050' }} onClick={() => {

                                            }}>Delete</Button>

                                    </Popconfirm >




                                </Col>)}


                            </Row>

                        </>

                    )
                    }

                    {
                        Object.keys(currentItem || {}).length === 0 && (
                            <Row>
                                <Col {...fullMobile(24, 24)} style={{ height: '10%' }}>
                                    <Typography variant="h5" component="body1" display="inline" gutterBottom color={'#404050'}>
                                        Station Details
                                    </Typography>
                                </Col>
                                <Col {...fullMobile(24, 24)} style={{ height: '70vh', overflowY: 'scroll', padding: 5 }}>
                                    <Typography variant="body1" display="inline" style={{ fontWeight: 400, marginBottom: 10 }}>Select a station to view details</Typography>
                                </Col>

                            </Row>
                        )
                    }



                </Paper >
            </Col >








        </Row >
    );
}

export default StationDetails;