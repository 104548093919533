import React, { useEffect, useState } from "react";
import {
    Box, Grid, Typography, OutlinedInput, FormControl, InputLabel, Select, MenuItem,
    InputAdornment
} from '@mui/material';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import { faSquare } from "@fortawesome/free-regular-svg-icons";


const Rain = (
    {
        record,
        items,
        setItems,
        screenWidth,
        openSnack,
        userInfo,
        updateRecord,
        stocks,
        setStocks,
        accounts





    }

) => {
    const [isFocused, setIsFocused] = useState(false);

    const options = [
        { label: "F", key: "F" },
        { label: "C", key: "C" },
    ];



    const handleTemperatureChange = (e) => {
        const value = e.target.value;
        updateRecord({ ...record, tempC: value === '' ? '' : value === '0' ? '0' : Number(value) });
    };

    return (
        <>

            <Typography
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: 20
                }}
                variant="h6"
                component="div"
                color={'rgba(0,0,0,0.8)'}
                fontWeight={500}
                fontSize={13}
            >
                Currently raining
                <FontAwesomeIcon
                    icon={record?.raining ? faCheckSquare : faSquare}
                    style={{
                        marginLeft: 10,
                        fontSize: 20,
                        color: record?.raining ? '#3f51b5' : 'black'
                    }}
                    onClick={() => {
                        updateRecord({ ...record, raining: !record?.raining });
                    }}
                />
            </Typography>



        </>
    );
};

export default Rain;
