import { Autocomplete, Button, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import uuid from "react-uuid";
import DatePicker from "react-datepicker";
import moment from 'moment-timezone';
import { Col, Row } from "antd";

const AlertSnoozeDatePicker = ({ alert, updateAlert, }) => {




    return (
        <Row gutter={[8, 8]}>
            <Col span={20}>
                <DatePicker selected={alert.snoozeDate === null ? undefined : alert.snoozeDate !== undefined ? new Date(moment(alert.snoozeDate, 'YYYY-MM-DD HH:mm:ss')) : undefined} onChange={(date) => {
                    console.log(date)
                    if (date === null) {
                        updateAlert(alert.id, 'snoozeDate', undefined)
                        return
                    }
                    const formattedDate = moment(date).tz('America/Los_Angeles').format('YYYY-MM-DD HH:mm:ss')
                    updateAlert(alert.id, 'snoozeDate', formattedDate)
                }}
                    showTimeSelect timeFormat="h:mm a" timeIntervals={1} timeCaption="time" dateFormat="MM/dd/yyyy h:mm a"
                    customInput={
                        <input
                            type="text"
                            id={alert.id}
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                marginLeft: 5,
                                height: 40,
                                border: '1px solid #dadada',
                                borderRadius: 4,
                                fontSize: 14,
                                placeHolder: 'Select Date',
                                fontWeight: 400,
                                cursor: 'pointer',
                                backgroundColor: 'white'
                            }}

                        />
                    }

                />
            </Col>
            <Col span={4} style={{ textAlign: 'center' }}>
                <Button
                    style={{
                        textTransform: 'none',
                        backgroundColor: '#404050',
                        color: 'white',
                    }}
                    onClick={() => {
                        updateAlert(alert.id, 'snoozeDate', undefined)
                    }}
                >
                    Clear
                </Button>
            </Col>
        </Row>


    );

};

export default AlertSnoozeDatePicker;