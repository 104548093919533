import React, { useState, useRef, useEffect } from "react";
import {
    Box,
    Breadcrumbs,
    Button,
    ClickAwayListener,
    Card,
    CardContent,
    CardActions,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    List,
    ListItem,
    Popper,
    TextField,
    ThemeProvider,
    Typography,
    createTheme,
    Checkbox,
    CardMedia,
    CircularProgress,
} from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEllipsisV, faFileAlt, faFolderOpen, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useDrop, useDrag } from 'react-dnd';
import { Col, Row } from "antd";
import { moveFile, moveFolder, updateImageDateTaken } from "../../firebase/firestore";
import { faFile } from "@fortawesome/free-regular-svg-icons";
import DatePicker from "react-datepicker";
import moment from "moment";
import './CustomRow.css';
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import { Font } from "@react-pdf/renderer";


const YourCustomRowComponent = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [checked, setChecked] = useState(false);
    const [renderLink, setRenderLink] = useState(null);
    const [updating, setUpdating] = useState(false);
    const anchorRefs = useRef(new Map()).current;

    const { name, id, type, size, downloadUrl, url250, url500, parent, selected, index, refreshFolderStructure, setRename, setRenameModalOpen,
        setRenameValue, setRenameId, setRenameType, openSnack, company, navigateToFolder, onSelectRow, openLightbox, dateTaken, updatedDate } = props;

    const [imageDate, setImageDate] = useState(updatedDate || dateTaken);


    const db = getFirestore(); // Assuming you've already initialized your Firestore instance
    const [imageUrl, setImageUrl] = useState('updating');

    const getUrl = url => Array.isArray(url) ? url[0] : url;

    // Candidate URLs
    const urlCandidates = [url250, downloadUrl].map(getUrl);


    useEffect(() => {
        const urlCandidates = [renderLink, url250, downloadUrl].map(getUrl);
        setImageUrl(urlCandidates.find(url => !['none', undefined, null].includes(url)) || '');
    }, [renderLink, url250, downloadUrl]);
    useEffect(() => {
        const docRef = doc(db, 'clients', company, 'images', id);

        const unsubscribe = onSnapshot(docRef, (docSnapshot) => {
            if (!docSnapshot.exists()) {
                console.error('Document does not exist!');
                return;
            }
            setImageUrl('updating');
            setUpdating(true)
            const renderLink = docSnapshot.data().url250 !== undefined ? docSnapshot.data().url250[0] : docSnapshot.data().downloadUrl !== undefined ? docSnapshot.data().downloadUrl : null;
           
            setTimeout(() => {
                setImageUrl(renderLink === 'uploading' ? null : renderLink);
                setUpdating(false)
            }, 1000);

        }, (error) => {
            console.error("Encountered error: ", error);
        });

        // Detach the listener when the component unmounts
        return unsubscribe;
    }, [id, db]);





    useEffect(() => {
        setImageDate(dateTaken);
    }, [dateTaken]);

    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'folder',
        item: { name: name, id, type: type, size: size, },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    }));

    const [{ canDrop, isOver }, drop] = useDrop(() => ({
        accept: ['file', 'folder'],
        drop: (item, monitor) => {
            // Properties of the item being dragged
            const draggedItemId = item.id;
            const draggedItemName = item.name;
            const draggedItemType = item.type;
            const draggedItemSize = item.size;
            const draggedParent = item.parent;

            // Properties of the item being dropped on
            const droppedOnItemId = id;
            const droppedOnItemName = name;
            const droppedOnItemType = type;
            const droppedOnItemSize = size;


            const draggedItem = { id: draggedItemId, name: draggedItemName, type: draggedItemType, size: draggedItemSize, parent: draggedParent };
            const droppedOnItem = { id: droppedOnItemId, name: droppedOnItemName, type: droppedOnItemType, size: droppedOnItemSize, };




            //do not allow drop on self
            if (draggedItemId === droppedOnItemId) {
                openSnack('error', 'Cannot move folder into itself!')
                return;
            }

            if (draggedItemType === 'file' && droppedOnItemType === 'file') {
                openSnack('error', 'Cannot move file into another file!')
                return;
            }
            if (draggedItemType === 'folder' && droppedOnItemType === 'file') {
                openSnack('error', 'Cannot move folder into a file!')
                return;
            }
            if (item.id === 'fieldLogPhotos' || item.id === 'applicationPhotos') {
                openSnack('error', 'Cannot move this folder!')
                return;
            }
     
            if (draggedItemType === 'folder') {
                moveItemFolder(draggedItem, droppedOnItem, company);
            }
            else {
                moveItem(draggedItem, droppedOnItem, company);
            }




            // Your logic for handling the drop event goes here
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    }));


    async function moveItem(draggedItem, droppedOnItem, company) {
        const move = await moveFile(draggedItem, droppedOnItem, company);

        if (move === 'success') {
            openSnack('success', 'Moved folder successfully!')
            refreshFolderStructure(parent);
        }
        else {
            openSnack('error', 'Cannot move folder into itself!')
        }
    }


    async function moveItemFolder(draggedItem, droppedOnItem, company) {
        const move = await moveFolder(draggedItem, droppedOnItem, company);

        if (move === 'success') {
            openSnack('success', 'Moved folder successfully!')
            refreshFolderStructure(parent);
        }
        else {
            openSnack('error', 'Cannot move folder into itself!')
        }
    }

    const isActive = canDrop && isOver;
    const backgroundColor = isActive ? '#e2f1fb4f' : //alternate color based on index
        index % 2 === 0 ? '#eeeeee52' : '#ffffff';



    // Utility function to ensure we have a string URL


    if (updating === true) {
        return (
            <FontAwesomeIcon icon={faSpinner} spin />
        )
    }
    else {


        return (
            //create this as a draggable component

            <Card
                key={id}
                sx={{
                    opacity: isDragging ? 0.5 : 1,
                    width: "100%",
                    backgroundColor,
                    marginBottom: 1,
                    cursor: "pointer",
                    transition: "all 0.2s ease-in-out",
                    paddingTop: type === 'folder' ? 1 : 0,
                    paddingRight: type === 'folder' ? 1 : 0,
                    borderRadius: type === 'folder' ? 1 : 2,
                    //onhover slightly darken

                    "&:hover": {
                        backgroundColor: "#e6e6e6",
                        //ever so slightly grow
                        transform: "scale(1.05)",

                    },
                    minHeight: type === 'folder' ? 80 : 0
                }}
                ref={(node) => drag(drop(node))}

            >
                <CardContent style={{
                    padding: 6,
                    //verticalAlign: 'middle'
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: type === 'folder' ? 80 : 0


                }}
                    onClick={() => {
                        if (type === "folder") {
                            navigateToFolder(id);
                        } else {
                            //open file

                        }
                    }}
                >

                    <Row align="middle" gutter={[6, 6]}>
                        <Col xs={12} sm={type === "folder" ? 4 : 12}>
                            <div
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                            >
                                <Checkbox
                                    sx={{
                                        display: ["fieldLogPhotos", "applicationPhotos"].includes(id)
                                            ? "none"
                                            : "block",
                                    }}
                                    checked={selected}
                                    onChange={(event) => {
                                        event.stopPropagation();
                                        event.preventDefault();
                                        event.nativeEvent.stopImmediatePropagation();
                                        onSelectRow(event.target.checked, id);
                                    }}
                                />
                            </div>
                        </Col>

                        {type === "folder" && (
                            <Col sm={4}>

                                <FontAwesomeIcon
                                    size="xl"
                                    icon={faFolderOpen}
                                    style={{ marginRight: 6 }}
                                />

                            </Col>
                        )}
                        {type === "folder" && (
                            <Col sm={12}>
                                <Typography
                                    variant="body1"
                                    fontSize={16}
                                    fontWeight={500}
                                    color="black"
                                >


                                    {name}
                                </Typography>
                            </Col>
                        )}


                        <Col span={type === "folder" ? 4 : 12} style={{ textAlign: 'right' }}>

                            {!["fieldLogPhotos", "applicationPhotos"].includes(id) && (
                                <Button
                                    ref={(node) => anchorRefs.set(index, node)}
                                    style={{ width: 20, textAlign: 'right' }}
                                    onClick={(e) => {
                                        e.stopPropagation();

                                        setIsOpen((prev) => ({ ...prev, [index]: !prev[index] }));
                                    }}
                                >
                                    <Typography variant="body1" fontSize={16} fontWeight={400} color="black">
                                        <FontAwesomeIcon icon={faEllipsisV} />
                                    </Typography>
                                </Button>
                            )}
                            {isOpen[index] && (
                                <ClickAwayListener onClickAway={() => setIsOpen((prev) => ({ ...prev, [index]: false }))}>
                                    <Popper
                                        id={"popper"}
                                        open={isOpen[index]}
                                        anchorEl={anchorRefs.get(index)}
                                        style={{ zIndex: 1000, width: 240 }}
                                    >
                                        <Box
                                            sx={{
                                                p: 0,
                                                bgcolor: "background.paper",
                                                borderRadius: 2,
                                                border: "1px solid #e0e0e0",
                                                boxShadow: 1,
                                            }}
                                        >
                                            <Row>
                                                <List
                                                    component="nav"
                                                    aria-labelledby="nested-list-subheader"
                                                    sx={{
                                                        width: "100%",
                                                        maxWidth: 360,
                                                        bgcolor: "background.paper",
                                                        position: "relative",
                                                        overflow: "auto",
                                                        maxHeight: 300,
                                                    }}
                                                >
                                                    <ListItem
                                                        button
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setIsOpen(false);
                                                            setRenameModalOpen(true);
                                                            setRenameValue(name);
                                                            setRenameId(id);
                                                            setRenameType(type);
                                                        }}
                                                    >
                                                        <Typography variant="body1" fontSize={13} fontWeight={600} color="black">
                                                            <FontAwesomeIcon icon={faEdit} style={{ marginRight: "10px" }} />
                                                            Rename
                                                        </Typography>
                                                    </ListItem>
                                                </List>
                                            </Row>
                                        </Box>
                                    </Popper>
                                </ClickAwayListener>
                            )}

                        </Col>
                    </Row>



                    {type === "file" && (
                        <Row>

                            {imageUrl === null ?
                                <FontAwesomeIcon icon={faSpinner} spin />
                                :

                                <CardMedia
                                    component="img"
                                    height="140"
                                    image={imageUrl}
                                    alt={name}
                                    onClick={() => {
                                        const typeOf = type;
                                        if (typeOf === "folder") {
                                            navigateToFolder(id);
                                        } else {
                                            //open file
                                            openLightbox()
                                        }
                                    }}
                                />
                            }
                        </Row>
                    )}
                    {
                        type === "file" && (<Row align="middle">
                            <Col xs={24} sm={24}>
                                <Typography
                                    variant="body1"
                                    fontSize={14}
                                    fontWeight={500}
                                    color="black"
                                >

                                    {name}
                                </Typography>
                            </Col>
                        </Row>)}





                    {
                        type === "file" && (
                            <Row align="middle" style={{ paddingTop: 10 }}>
                                <Col xs={24} sm={16}>
                                    <DatePicker
                                        className="datepicker-custom" // Add this line
                                        portalId="root-portal" showTimeSelect timeFormat="h:mm a" timeIntervals={1} timeCaption="time" dateFormat="MM/dd/yyyy h:mm a"
                                        selected={new Date(moment(imageDate))}
                                        customInput={
                                            <input
                                                type="text"
                                                id={name}
                                                style={{
                                                    width: '100%',
                                                    height: 30,
                                                    border: '1px solid #404050',
                                                    borderRadius: 2,
                                                    fontSize: 13,
                                                    padding: 2,
                                                    paddingLeft: 6,
                                                    fontWeight: 500,
                                                    cursor: 'pointer',
                                                    backgroundColor: '#f7f7f7'
                                                }}

                                            />
                                        }
                                        onChange={async (date) => {

                                            const formattedDate = moment(date).format('MM/DD/YYYY HH:mm:ss')
                                            setImageDate(formattedDate)

                                            await updateImageDateTaken(id, formattedDate, company)


                                        }} />
                                </Col>
                                <Col xs={24} sm={8} style={{ textAlign: 'right' }}>

                                    <Typography variant="body1" fontSize={12} fontWeight={400} color="black">{Number(size / 1000000).toFixed(2)} MB</Typography>
                                </Col>
                            </Row>
                        )
                    }

                </CardContent >

            </Card >
        );
    }
}


export default YourCustomRowComponent;