import React from 'react';
import WidgetMapGoogle from './widgetMapGoogle';
import axios, * as others from 'axios';


export default class WidgetMap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            height: null,
            attributes: this.props.attributes,
            widgetKey: this.props.widgetKey,
            publicViewable: this.props.publicViewable,
            probes: this.props.probes,
            stations: this.props.stations
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
        if (this.props.widgetKey !== prevProps.widgetKey) {
            this.setState({ widgetKey: this.props.widgetKey })
        }
        if (this.props.attributes !== prevProps.attributes) {
            this.setState({ attributes: this.props.attributes })
        }
        if (this.props.publicViewable !== prevProps.publicViewable) {
            this.setState({ publicViewable: this.props.publicViewable })
        }
        if (this.props.probes !== prevProps.probes) {

            this.setState({ probes: this.props.probes })

        }
        if (this.props.stations !== prevProps.stations) {

            this.setState({ stations: this.props.stations })

        }

    }
    componentDidMount() {
        const { attributes, userInfo, account, stations } = this.state;


        /*   //axios call to get weather
          const latitude = 37.891816;
          const longitude = -122.314799;
          const url = `https://api.weather.gov/points/${latitude},${longitude}`;
  
          axios.get(url).then((response) => {
              const forecast = response.data.properties.forecast;
              axios.get(forecast).then((response) => {
                  const forecast = response.data.properties.periods[0];
                  console.log(forecast)
              }
              );
          }) */

    



    }



    render() {

        const { account, height, attributes, widgetKey, publicViewable, probes, stations } = this.state;

        const Height = () => {
            if (this.container) {
                if (this.container.offsetHeight !== height) {
                    this.setState({ height: this.container.offsetHeight })
                }

            }
 
        }



        return (
            <div ref={el => (this.container = el)} style={{ height: '100%', width: '100%', paddingBottom: 20 }}>
                <Height />
                {height != null && (<WidgetMapGoogle attributes={attributes} probes={probes} stationsProps={stations}
                    publicViewable={publicViewable} height={height} account={account}
                    updateAttributes={this.props.updateAttributes} widgetKey={widgetKey} />)}
            </div>


        );
    }
} 