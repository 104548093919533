import { faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Popper, ClickAwayListener, Box, ListItem, Typography } from '@mui/material';
import { Col, List, Popconfirm, Row } from 'antd';
import React, { useRef, useState, useEffect } from 'react';


const ReinviteButton = ({ sendInvite, staffKey, inviting }) => {


    if (inviting === staffKey) {
        return (
            <Button
                size="small"
                style={{
                    textTransform: 'none',

                    color: '#404050',
                    border: '1px solid #404050',
                    fontSize: 10
                }
                } variant="link"
               
            >
                <FontAwesomeIcon icon={faSpinner} spin style={{ color: '#404050', fontSize: 14 }} />
            </Button>
           
        )
    }
    else {

        return (



            <Button
                size="small"
                style={{
                    textTransform: 'none',

                    color: '#404050',
                    border: '1px solid #404050',
                    fontSize: 10
                }
                } variant="outlined"
                onClick={() => sendInvite(staffKey)}
            >
                Re-invite
            </Button>


        );
    }

};

export default ReinviteButton;
