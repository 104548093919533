import React, { useEffect, useState } from "react";


import { faBox, faBucket, faCheck, faFish, faFlask, faListCheck, faMagnifyingGlass, faPlus, faWheatAlt, faWrench, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, ButtonGroup, Divider, FormControl, Grid, IconButton, InputLabel, MenuItem, Modal, OutlinedInput, Select, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import FilesList from "../files/filesList";



const ServiceInfo = (
    {
        screenWidth,
        newItem,
        setNewItem,
        userInfo,
        openSnack,



    }

) => {
    const [isFocused, setIsFocused] = useState(false);






    return (
        <>




            <Col xs={24}  >
                <Typography variant="h6" component="div" color={'rgba(0,0,0,0.6)'} fontWeight={600} fontSize={16}>
                    Service Information
                </Typography>
                <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                <Grid container spacing={2}>



                    <Grid item xs={12} md={12}>
                        <Typography variant="h6" component="div" color={'rgba(0,0,0,0.8)'} fontWeight={500} fontSize={16}>
                            Instructions
                        </Typography>
                        <OutlinedInput
                            margin="dense"

                            style={{
                                borderRadius: 12,
                                marginTop: screenWidth === 'xs' ? 2 : 5,
                                marginBottom: 10,
                                width: '100%',
                                fontSize: 16,
                            }}
                            multiline={true}
                            rows={4}
                            value={newItem.serviceInstructions}
                            onChange={(e) => {
                                setNewItem({ ...newItem, serviceInstructions: e.target.value })
                            }}
                            placeholder={'Instructions'}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Typography variant="h6" component="div" color={'rgba(0,0,0,0.8)'} fontWeight={500} fontSize={16}>
                            Additional information
                        </Typography>
                        <OutlinedInput
                            margin="dense"

                            style={{
                                borderRadius: 12,
                                marginTop: screenWidth === 'xs' ? 2 : 5,
                                marginBottom: 10,
                                width: '100%',
                                fontSize: 16,
                            }}
                            multiline={true}
                            rows={4}
                            value={newItem.serviceAdditional}
                            onChange={(e) => {
                                setNewItem({ ...newItem, serviceAdditional: e.target.value })
                            }}
                            placeholder={'Additional Information'}
                        />
                    </Grid>

                </Grid>

            </Col>




        </>



    );
};

export default ServiceInfo;
