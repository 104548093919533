import React, { useEffect, useState, useRef } from "react";


import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { faCheckCircle, faCircle, faCopy, faEye, faEyeSlash, faSquare, faSquareCheck } from '@fortawesome/free-regular-svg-icons';
import { faCircleArrowLeft, faCircleArrowRight, faFilter, faRedo, faTrash, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Button, CircularProgress, Divider, IconButton, Tooltip, Typography, Badge, Switch, Checkbox, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { Col, Row } from 'antd';

import './taskList.css';
import ColumnSelection from "./columnSelection";

import FilterTable from "./filterTable";
import TaskCreateList from "./taskCreateList";


import SelectableTool from "./selctableTool";
import MoreTableOptions from "./moreTableOptionsTool";
import BulkEditTasks from "./bulkEditTasks";
import ColumnEditor from "./columnEditor";
import ColorCalendar from "./colorCalendar";
import CalendarEditor from "./calendarEditor";





const TasksSubHeader = (
    {
        type,
        tasks,
        sortType,
        setTasks,
        setSortType,
        screenWidth,
        showCompleted,
        setShowCompleted,
        loading,
        setLoading,
        currentTask,
        setCurrentTask,
        deleteTask,
        updateTask,
        setTaskDrawer,
        duplicateTask,
        updateTasksPosition,
        headers,
        setHeaders,
        openSnack,
        getNestedProperty,
        columnFilters,
        setColumnFilters,
        fullTasks,
        sortItem,
        setSortItem,
        createTask,
        selectable,
        setSelectable,
        deleteSelectedTasks,
        bulkEditor,
        setBulkEditor,
        staff,
        accounts,
        updateMultipleTasks,
        items,
        setItems,
        stocks,
        userInfo,
        taskTemplates,
        deleteTaskTemplate,
        columnEditor,
        setColumnEditor,
        setCalendarEditor,
        calendarEditor,
        deleteHeader,
        undoHistory,
        setUndoHistory,
        redoHistory,
        setRedoHistory,
        colorCalendar,
        setColorCalendar,
        createCalendar,
        calendars, 
        setCalendars
    }

) => {

    const [hoverKey, setHoverKey] = useState(null);
    const [headersHistory, setHeadersHistory] = useState([headers]);
    const [currentIndex, setCurrentIndex] = useState(0);

    const [initialWidth, setInitialWidth] = useState(null);
    const [firstClickedIndex, setFirstClickedIndex] = useState(null);
    const [allSelected, setAllSelected] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);

    const textRef = useRef(null);






    function onResize(index, event, { node, size, handle }) {
        const newHeaders = [...headers];
        newHeaders[index].width = size.width;
        setHeaders(newHeaders);

    }

    const onResizeStop = (index, event, { node, size, handle }) => {
        const newHeaders = [...headers];

        newHeaders[index].width = size.width;
        setHeaders(newHeaders);
        const updatedHeaders = JSON.parse(JSON.stringify(newHeaders));
        updatedHeaders[index].width = initialWidth;

        setRedoHistory([]); // Clear the redo history when you make a new change

        //set each item in undohistory to stringify and remove duplicates and then add to undohistory
        //set undohistory to the new array
        const newundo = [...undoHistory, JSON.parse(JSON.stringify(updatedHeaders))]
        const unique = [...new Set(newundo.map(item => JSON.stringify(item)))];
        const uniqueArray = unique.map(item => JSON.parse(item));
        setUndoHistory(uniqueArray);

    };

    const onResizeStart = (index, event, { node, size, handle }) => {
        const newHeaders = [...headers];
        setInitialWidth(newHeaders[index].width);
    };




    const doubleClickResize = (index) => {
        const newHeaders = [...headers];

        const strings = tasks.map((task) => {
            const value = getNestedProperty(task, newHeaders[index].id, newHeaders[index].getLabel, newHeaders[index]?.optionType, newHeaders[index]?.options, newHeaders[index]?.decimals);
            return value !== undefined ? value.toString() : '';
        }
        )
        const longestString = strings?.reduce((a, b) => a.length > b.length ? a : b, '');
        const width = longestString.length * 8;
        newHeaders[index].width = width < newHeaders[index].minWidth ? newHeaders[index].minWidth : width;
        setHeaders(newHeaders);
        const updatedHeaders = JSON.parse(JSON.stringify(newHeaders));
        updatedHeaders[index].width = initialWidth;
        setUndoHistory([...undoHistory, JSON.parse(JSON.stringify(updatedHeaders))]);
        setRedoHistory([]); // Clear the redo history when you make a new change
    };

 
    const props = {
        headers,
        setHeaders,
        openSnack,
        headers, setHeaders, redoHistory, setRedoHistory, undoHistory, setUndoHistory, tasks, initialWidth, getNestedProperty,
        columnFilters,
        setColumnFilters,
        fullTasks,
        setTasks,
        selectable,
        setSelectable,
        updateTask,
        setCurrentTask,
        currentTask,
        showCompleted,
        setTaskDrawer,
        deleteSelectedTasks,
        duplicateTask,
        deleteTask,
        updateTasksPosition,
        sortItem,
        setSortItem,
        setBulkEditor,
        bulkEditor,
        staff,
        accounts,
        updateMultipleTasks,
        getNestedProperty,
        items,
        setItems,
        stocks,
        userInfo,
        taskTemplates,
        deleteHeader,
        colorCalendar,
        setColorCalendar,
        setColumnEditor,
        columnEditor,
        setCalendarEditor,
        calendarEditor,
        createCalendar,
        calendars, 
        setCalendars,
        type
        

    }









    let sortedTasks = [...tasks]; // make a copy of tasks array

    // Extract the sorting key and direction from the sortItem string
    if (sortItem) {
        const sortKey = sortItem.split(',')[0].split(': ')[1];
        const sortDirection = sortItem.split(',')[1].split(': ')[1];

        const sortedHeader = headers.find(header => header.id === sortKey);

        //if undefined, setSortItem to null
        if (sortedHeader === undefined) {
            setSortItem(null);
            return;
        }

        //filter out any undefined tasks




        sortedTasks = sortedTasks.sort((a, b) => {
            let valueA = getNestedProperty(a, sortKey, sortedHeader?.getLabel, sortedHeader?.optionType, sortedHeader?.options, sortedHeader?.decimals);
            let valueB = getNestedProperty(b, sortKey, sortedHeader?.getLabel, sortedHeader?.optionType, sortedHeader?.options, sortedHeader?.decimals);



            if (sortedHeader.optionType === 'number' || sortedHeader.optionType === 'money') {
                valueA = valueA === undefined ? undefined : Number(valueA);
                valueB = valueB === undefined ? undefined : Number(valueB);
            }
            if (sortedHeader.optionType === 'percent') {
                let removepercent = valueA?.replace('%', '');
                valueA = removepercent === undefined ? undefined : Number(removepercent);
                removepercent = valueB?.replace('%', '');
                valueB = removepercent === undefined ? undefined : Number(removepercent);
            }



            // Handle cases where one or both values are undefined
            if (valueA === undefined && valueB !== undefined) {
                return 1;
            }
            if (valueB === undefined && valueA !== undefined) {
                return -1;
            }
            if (valueA === undefined && valueB === undefined) {
                return 0;
            }

            // Continue with your normal comparison for non-undefined values
            if (valueA < valueB) {
                return sortDirection === 'asc' ? -1 : 1;
            }
            if (valueA > valueB) {
                return sortDirection === 'asc' ? 1 : -1;
            }
            return 0;
        });
    }






    // ... (existing code in the return statement)

    const redo = () => {
        try {
            if (redoHistory.length === 0) return;
            const nextHistory = redoHistory.pop();
            setUndoHistory([...undoHistory, headers]); // Save the current state to undo
            setHeaders(nextHistory);  // Set headers to the next saved state
            setRedoHistory(redoHistory);  // Update redo history
        }
        catch (error) {
            console.error(error);
        }
    };

    const undo = () => {
        if (undoHistory.length === 0) return;
        const lastHistory = undoHistory.pop()
        setRedoHistory([...redoHistory, headers]); // Save the current state to redo

        lastHistory.forEach((header, i) => {
            header.position = i;
        });
        setHeaders(lastHistory);  // Set headers to the last saved state

        setUndoHistory(undoHistory);  // Update undo history
    };


    const selectedTasks = tasks?.filter(task => task.selected === true);


    const visibleHeaders = headers?.filter((header) => header.visible === true);




    const handleClose = () => {
        setDeleteDialog(false);
    };

    return (

        <Col span={24} style={{ paddingTop: 15, paddingBottom: 15 }}>
            <Row>
                <Col span={12} style={{
                    display: 'inline'
                }}>

                    <TaskCreateList
                        createTask={createTask}
                        fullTasks={fullTasks}
                        setCurrentTask={setCurrentTask}
                        setTaskDrawer={setTaskDrawer}
                        taskTemplates={taskTemplates}
                        deleteTaskTemplate={deleteTaskTemplate}
                    />

 

                </Col>
                <BulkEditTasks {...props} bulkEditor={bulkEditor} setBulkEditor={setBulkEditor} selectedTasks={selectedTasks} openSnack={openSnack} />
                <ColumnEditor {...props} columnEditor={columnEditor} setColumnEditor={setColumnEditor} openSnack={openSnack} deleteHeader={deleteHeader} />
                <CalendarEditor {...props} calendarEditor={calendarEditor} setCalendarEditor={setCalendarEditor} openSnack={openSnack} />
                <Col span={12} style={{ textAlign: 'right' }}>


                    <MoreTableOptions {...props} selectedTasks={selectedTasks} setColumnEditor={setColumnEditor} setCalendarEditor={setCalendarEditor} 
                     setBulkEditor={setBulkEditor} sortedTasks={sortedTasks} visibleHeaders={visibleHeaders} />

                    {selectedTasks.length > 0 && (
                        <IconButton onClick={() => setDeleteDialog(true)}
                            disabled={selectedTasks.length === 0 ? true : false}
                        >
                            <Tooltip title="Delete Selected" placement="bottom">
                                <FontAwesomeIcon icon={faTrash} size="md" style={{ color: 'gray', cursor: 'pointer' }} />
                            </Tooltip>

                        </IconButton>
                    )} 
                    <Dialog
                        open={deleteDialog}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            Are you sure you want to delete {selectedTasks.length} tasks?
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Deleting these tasks will remove them from calendar as well.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button style={{
                                textTransform: 'none',
                            }} onClick={handleClose}>Cancel</Button>
                            <Button
                                style={{
                                    fontWeight: 600,
                                    textTransform: 'none',
                                    color: 'red'
                                }}

                                onClick={() => {
                                    deleteSelectedTasks(selectedTasks);
                                    handleClose();
                                }}
                                autoFocus>
                                Delete
                            </Button>
                        </DialogActions>
                    </Dialog>



                    <SelectableTool {...props} totalSelected={selectedTasks.length} />


                    <IconButton onClick={() => setShowCompleted(!showCompleted)}

                    >
                        <Tooltip title="Toggle Completed" placement="bottom">
                            <FontAwesomeIcon icon={showCompleted ? faEye : faEyeSlash}
                                size="md" style={{ color: '#3f51b5', cursor: 'pointer' }} />
                        </Tooltip>

                    </IconButton>
                    <FilterTable {...props} />
                    {type === 'table' && (<ColumnSelection {...props} />)}

                    {type === 'table' && (<IconButton onClick={undo}
                        disabled={undoHistory.length === 0 ? true : false}
                    >
                        <Tooltip title="Undo" placement="bottom">
                            <FontAwesomeIcon icon={faUndo} size="xs" style={{ color: undoHistory.length === 0 ? "white" : '#3f51b5', cursor: 'pointer' }} />
                        </Tooltip>

                    </IconButton>
                    )}

                    {type === 'calendar' && (
                        <ColorCalendar {...props} />
                    )}



                    {type === 'table' && (<IconButton onClick={redo}
                        disabled={redoHistory.length === 0 ? true : false}

                    >
                        <Tooltip title="Redo" placement="bottom">
                            <FontAwesomeIcon icon={faRedo} size="xs" style={{ color: redoHistory.length === 0 ? "white" : '#3f51b5', cursor: 'pointer' }} />
                        </Tooltip>
                    </IconButton>
                    )}
                </Col>
            </Row>
            {/* <Row align="middle">
                <Col span={24}>
                    <Divider />

                </Col>
                <Col span={24} style={{
                    textAlign: 'right',
                    padding: 6
                }}>
                    <Typography variant="body1" style={{ color: 'black', display: "inline", marginRight: 10 }}>
                        Showing 1 - 50 of 100 tasks Page: 1 of 2
                    </Typography>
                    <IconButton onClick={() => { }}>

                        <FontAwesomeIcon icon={faCircleArrowLeft} size="xl" style={{ color: 'gray', cursor: 'pointer' }} />
                    </IconButton>
                    <IconButton onClick={() => { }}>
                        <FontAwesomeIcon icon={faCircleArrowRight} size="xl" style={{ color: 'gray', cursor: 'pointer' }} />
                    </IconButton>

                </Col>
            </Row >
 */}

        </Col >




    );
};

export default TasksSubHeader;
