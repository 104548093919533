import { Autocomplete, Button, Divider, TextField, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';
import moment from 'moment';
import React from 'react';
import CSVReader from 'react-csv-reader';


import axios, * as others from 'axios';
var convert = require('convert-units')

export default class Hobo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            locations: [],
            location: {}, 
            csvLength: 0,
            values: []
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }

    }

    componentDidMount() {
        this.getLocations()
    }

    getLocations = async () => {
        const { userInfo } = this.state;

        const company = '38cfab-0eb0-0c3e-f466-71ad3c4865'

        const db = getFirestore();
        const queryData = query(collection(db, "clients", company, 'probes'), where('archived', '==', false));
        const queryDataSites = query(collection(db, "clients", company, 'sampleSites'),);
        const locations = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            locations.push({ ...doc.data(), label: doc.data().name, type: 'probe', })
        });
        const snapSites = await getDocs(queryDataSites);
        snapSites.forEach((doc) => {
            locations.push({ ...doc.data(), label: doc.data().name, type: 'sample' })
        });
        locations.push({ label: 'Weather', key: 'Weather', name: 'Weather', type: 'weather' })
        locations.sort((a, b) => a.name.localeCompare(b.name))
        this.setState({ locations, })

    }

    render() {

        const { account, locations, location, csvLength, values } = this.state;

        return (
            <Row style={{}}>
                <Col span={23}>
                    <Row align="middle">
                        <Col xs={16} sm={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>
                            <Typography variant="h4">Hobo Logger Upload</Typography>
                        </Col>
                        <Col xs={6} sm={4} style={{ paddingTop: 30, paddingBottom: 15, textAlign: 'right' }}>

                        </Col>
                        <Col span={24}>
                            <Divider />
                        </Col>
                        <Col span={23} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 30 }}>

                            <Autocomplete
                                disableClearable
                                size="small"
                                disablePortal
                                id="combo-box-demo"
                                options={locations}
                                value={location || null}
                                renderInput={(params) => (
                                    <TextField 
                                        {...params}
                                        label="Source"
                                        variant="outlined"
                                    />
                                )}
                                onChange={(a, b) => {
                                    console.log(b);
                                    this.setState({ location: b })
                                }}
                                InputProps={{ style: { fontSize: 6 } }}


                            />
                        </Col>
                        <Col span={23} style={{ paddingLeft: 30, }}>

                            ID: {location.id} <br />
                            Name: {location.name} <br />
                            CSV Length: {csvLength} <br />
                            Samples Length: {values.length} <br />
                        </Col>
                        <Col span={23} style={{ paddingLeft: 30, }}>

                            <CSVReader onFileLoaded={(data, fileInfo, originalFile) => {
                                console.log(data, fileInfo, originalFile)

                                var arr = data;
                                var theRemovedElement = arr.shift(); // theRemovedElement == 1
                                let popped = arr.pop();
                                console.log(arr);

                                this.setState({ csvLength: arr.length })

                                const values = []

                                arr.map((d) => {


                                    const date = new Date(`${d[0]} ${d[1]}`)



                                    var time = moment(date).format("YYYY-MM-DD HH:mm");
                                    var timestamp = moment(date).format("X");

                                    values.push({
                                        time: `'${time}'`,
                                        timestamp: Number(timestamp),
                                        locationid: `'${location.id}'`,
                                        unitid: `'117'`,
                                        parameterid: `'20'`,
                                        value: Number(d[2])
                                    })
                                    values.push({
                                        time: `'${time}'`,
                                        timestamp: Number(timestamp),
                                        locationid: `'${location.id}'`,
                                        unitid: `'1'`,
                                        parameterid: `'1'`,
                                        value: Number(d[3])
                                    })



                                })
                                console.log(values)
                                this.setState({ values })








                            }} />
                        </Col>

                    </Row>
                </Col>

                <Col span={23} style={{ paddingLeft: 30, paddingTop: 30, }}>
                    <Button variant="contained" color="primary" styke={{ TextTransform: 'none', padding: 15 }}
                        onClick={() => {

                            const hourlyVals = values.map((h) => `(${Object.values(h)})`);
                            const hourlyString = hourlyVals.join();

                            const proxyUrl = "https://mycorslake.herokuapp.com/";
                            axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                                raw: `INSERT INTO probe_data VALUES ${hourlyString} ON CONFLICT DO NOTHING; `
                            })
                                .then(async (response) => {
                                    // handle success
                                    console.log(response)
                                   



                                });


                        }}>Upload</Button>

                </Col>

            </Row>
        );
    }
} 