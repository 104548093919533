import { Button, Divider, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';

import uuid from 'react-uuid';





import { useNavigate } from 'react-router-dom';
import CreateStream from './createStream';
import { createItem, deleteItems, getItems, updateItem } from './firestore';
import StationsTable from './stationsTable';

export default function StreamHomeObscape({ userInfo, screenWidth, openSnack, company, account }) {
    const [loading, setLoading] = useState(false)
    const [items, setItems] = useState([])
    const [createModalOpen, setCreateModalOpen] = useState(false)
    const [type, setType] = useState('')
    const [currentItem, setCurrentItem] = useState(null)
    const [photosModalOpen, setPhotosModalOpen] = useState(false)


    useEffect(() => {
        window.scrollTo(0, 0)
        refreshItems()
    }, [])



    const ReturnToDataStreams = () => {
        let navigate = useNavigate()
        return (
            <Button size="small"
                variant="link"
                style={{
                    textTransform: 'none'
                }}
                onClick={async () => {
                    navigate('/dataStreams')
                }}>Return to Data Streams --></Button>
        )
    }

    const handleCreate = async (item) => {
        if (item.name === '') {
            openSnack('error', 'Please enter a name');
            return;
        }
        const createdItem = await createItem(item, userInfo);
        //add item to items
        setItems([...items, createdItem])
        setCreateModalOpen(false);

    };

    const handleEdit = async (item) => {
        setCurrentItem(item)
        setType('edit')
        setCreateModalOpen(true)
    }

    const handleSave = async (item) => {
        if (item.name === '') {
            openSnack('error', 'Please enter a name');
            return;
        }
        const updatedItem = await updateItem(item, userInfo);
        //remove item from items and add new item
        const newItems = items.filter((l) => l.id !== updatedItem.id)
        setItems([...newItems, updatedItem])
        setCreateModalOpen(false);
        setCurrentItem(null)
    }
    const handleDelete = async (deleteIds) => {
        const deleted = await deleteItems(deleteIds, userInfo, 'obscapeStations');
        if (deleted === 'success') {
            openSnack('success', 'Successfully deleted items')
        } else {
            openSnack('error', 'Error deleting items')
        }
        const newItems = items.filter((l) => !deleteIds.includes(l.id))
        setItems(newItems)

    }



    const refreshItems = async () => {
        setLoading(true)
        const data = await getItems(userInfo, 'obscapeStations');
        console.log(data)
        setItems(data);

        /* data.forEach((item) => {
            fetchPhotos(item, userInfo)
        }) */



        setLoading(false)
    }



    return (
        <Row style={{ fontFamily: 'Roboto, sans-serif', paddingLeft: 10, paddingTop: 10, marginLeft: 2, }} >


            <Col span={23}>
                <Row align="middle">
                    <Col xs={24} style={{ paddingTop: 30, paddingBottom: 15 }}>
                        <Typography variant="h4">Weather Station Streams</Typography>
                    </Col>
                    <Col span={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
                        {loading ? '' : <ReturnToDataStreams />}

                    </Col>
                    <Col span={12} style={{ paddingTop: 10, paddingBottom: 10, textAlign: 'right' }}>
                        <Button style={{
                            backgroundColor: '#404050', color: '#fff', fontWeight: 600,
                            textTransform: 'none',
                        }}

                            variant='contained'
                            onClick={async () => {
                                setType('new')
                                setCurrentItem({ tempid: uuid(), name: '', username: '', password: '', type: 'obscapeStations', company: userInfo.currentCompany, account: userInfo.currentAccount, getHistoricalImages: true })
                                setCreateModalOpen(true)
                            }}>Add Device + </Button>

                    </Col>

                    <Col span={24}>
                        <Divider />
                    </Col>
                </Row>
            </Col>




            <Col span={23}>
                <StationsTable
                    screenWidth={screenWidth}
                    items={items}
                    loading={loading}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    setPhotosModalOpen={setPhotosModalOpen}
                    setCurrentItem={setCurrentItem}
                />
            </Col>



            <CreateStream
                createModalOpen={createModalOpen}
                setCreateModalOpen={setCreateModalOpen}
                screenWidth={screenWidth}
                type={type}
                currentItem={currentItem}
                handleCreate={handleCreate}
                handleSave={handleSave}
                openSnack={openSnack}
            />








        </Row >
    );
}
