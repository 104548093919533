import { faEllipsisV, faHandPointer, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, ClickAwayListener, IconButton, List, ListItem, Modal, Popper, Typography } from '@mui/material';
import { Row } from "antd";
import React, { useRef, useState, useEffect } from "react";
import SaveTemplateModal from './SaveTemplateModal';
import { doc, getFirestore, setDoc } from 'firebase/firestore';
import uuid from 'react-uuid';
import PickTemplateModal from './pickTemplateModal';

const DropdownEditOptions = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [saveModalOpen, setSaveModalOpen] = useState(false);
    const [templateValue, setTemplateValue] = useState('');
    const [pickModalOpen, setPickModalOpen] = useState(false);
    const [newTemplates, setNewTemplates] = useState(false);



    const buttonRef = useRef(null);
    const { options, userInfo, openSnack, setOptions } = props;

    useEffect(() => {
        setAnchorEl(buttonRef.current)

    }, [])

    async function handleSaveTemplate(name) {
        console.log(name)

        const db = getFirestore();
        const id = uuid();
        const templateRef = doc(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'dropdownTemplates', id);
        await setDoc(templateRef, {
            name: name,
            options: options,
            id
        }).then(async () => {
            setTemplateValue('')
            setSaveModalOpen(false);
            openSnack('success', 'Template Saved')
            setNewTemplates(true);

        })
    }

    async function handleUpdateTemplate(template) {
        console.log(template)
        setOptions(template.options)
        setPickModalOpen(false);
        openSnack('success', 'Template Picked')
    }




    return (

        <div>
            <IconButton
                ref={buttonRef}
                width="20%"
                onClick={() => setIsOpen(!isOpen)}
            >

                <FontAwesomeIcon icon={faEllipsisV} />

            </IconButton>
            {isOpen && (
                <ClickAwayListener onClickAway={() => setIsOpen(false)}>
                    <Popper
                        id={'popper'}
                        open={isOpen}
                        anchorEl={buttonRef?.current}
                        style={{ zIndex: 1000, width: 240 }}
                    >
                        <Box sx={{
                            p: 0, bgcolor: 'background.paper',
                            borderRadius: 2,
                            border: '1px solid #e0e0e0',
                            boxShadow: 1,

                        }}>
                            <Row style={{}}>
                                <List
                                    component="nav"
                                    aria-labelledby="nested-list-subheader"
                                    sx={{
                                        width: '100%',
                                        maxWidth: 360,
                                        bgcolor: 'background.paper',
                                        position: 'relative',
                                        overflow: 'auto',
                                        maxHeight: 300,
                                    }}
                                >
                                    <ListItem button onClick={(e) => {
                                        e.stopPropagation();
                                        console.log('clicked');
                                        setIsOpen(false);
                                        console.log(options)
                                        setSaveModalOpen(true);

                                    }}>
                                        <Typography variant='body1' fontSize={13} fontWeight={600} color='black'>
                                            <FontAwesomeIcon icon={faSave} style={{ marginRight: '10px' }} />
                                            Save Template</Typography>
                                    </ListItem>
                                    <ListItem button onClick={(e) => {
                                        e.stopPropagation();
                                        console.log('clicked');
                                        setIsOpen(false);
                                        setPickModalOpen(true);


                                    }}>
                                        <Typography variant='body1' fontSize={13} fontWeight={600} color='black'>
                                            <FontAwesomeIcon icon={faHandPointer} style={{ marginRight: '10px' }} />
                                            Pick Template</Typography>
                                    </ListItem>
                                </List>

                            </Row>
                        </Box>
                    </Popper>
                </ClickAwayListener>
            )} 

            <SaveTemplateModal
                saveModalOpen={saveModalOpen}
                setSaveModalOpen={setSaveModalOpen}
                templateValue={templateValue}
                setTemplateValue={setTemplateValue}
                handleSaveTemplate={handleSaveTemplate}
            />

            <PickTemplateModal
                pickModalOpen={pickModalOpen}
                setPickModalOpen={setPickModalOpen}
                handleUpdateTemplate={handleUpdateTemplate}
                userInfo={userInfo}
                openSnack={openSnack}
                newTemplates={newTemplates}
                setNewTemplates={setNewTemplates}
            />


        </div>
    );
}

export default DropdownEditOptions;