// CustomToolbar.js
import React from 'react';

class CustomToolbar extends React.Component {
    render() {
        return (
            <div className="rbc-toolbar">
                <span className="rbc-btn-group">
                    <button type="button" onClick={() => this.navigate('PREV')}>Prev</button>
                    <button type="button" onClick={() => this.navigate('TODAY')}>Today</button>
                    <button type="button" onClick={() => this.navigate('NEXT')}>Next</button>
                </span>
                <span className="rbc-toolbar-label">{this.props.label}</span>
                <span className="rbc-btn-group">
                    {this.viewNamesGroup({
                        views: ['month', 'week', 'day'],
                        onNavigate: this.props.onNavigate,
                        view: this.props.view,
                    })}
                </span>
            </div>
        );
    }

    navigate = action => {
        this.props.onNavigate(action);
    };

    viewNamesGroup({ views, onNavigate, view }) {
        let currentView = view;

        if (views.length > 1) {
            return views.map(name => (
                <button type="button"
                    key={name}
                    className={currentView === name ? 'rbc-active' : ''}
                    onClick={() => onNavigate(name)}>
                    {name}
                </button>
            ));
        }
    }
}

export default CustomToolbar;
