import { Map } from '@esri/react-arcgis';
import { Button, OutlinedInput, Typography } from '@mui/material';
import { Col, Modal, Row } from 'antd';
import { doc, getFirestore, setDoc, updateDoc } from 'firebase/firestore';
import React from 'react';
import Location from '../../blocks/gis/location';
import SearchBar from '../../blocks/gis/searchBar';
import ZoomIcon from '../../blocks/gis/zoomIcon';
import "../../extra/hoverStyle.css";
import Layer from './layer';
import axios, * as others from 'axios';
import queryString from 'query-string';

export default class SiteModal extends React.Component { 
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            data: [],
            filteredData: [],
            filters: [],
            searchText: '',
            loading: true,
            type: this.props.type,
            currentItem: this.props.currentItem,
            openModal: this.props.openModal,
            nextPage: '',
            token: '',
            
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
        if (this.props.openModal !== prevProps.openModal) {
            this.setState({ openModal: this.props.openModal })
        }
        if (this.props.currentItem !== prevProps.currentItem) {
            this.setState({ currentItem: this.props.currentItem })
        }

    }
    componentDidMount() {
        const { userInfo, account } = this.state;
  


    }





  


    updateCurrentItem = (boo, val) => {
        const { currentItem } = this.state;
        currentItem[boo] = val;
        this.setState({ currentItem })
    }



    render() {

        const { userInfo, account, data, filteredData, loading, searchText, filters, type, currentItem, accounts, openModal, screenWidth, } = this.state;
        const db = getFirestore();

  

     

        const CreateSite = () => {
            return (
                <Button size="small" style={{ padding: 10, borderRadius: 4, textTransform: 'none', fontSize: 14, }}
                    variant='contained' color="primary"
                    onClick={async () => {
                        const { currentItem } = this.state;
                       
                        currentItem.name = currentItem.name || "";
                    



                        const checks = [
                            currentItem.name.length === 0 ? false : true,
                            
                        ]

                        if (currentItem.name.length === 0) {
                            this.props.openSnack('error', "Site name required!")
                        }
                        

                        if (!checks.includes(false)) {
                          

                            const key = currentItem.key || currentItem.tempKey;
                            currentItem.archived = currentItem.archived || false;

                            currentItem.account = userInfo.currentAccount;
                            currentItem.company = userInfo.currentCompany;


                            const docRef = doc(db, "clients", userInfo.currentCompany, 'sampleSites', key);

                            if (currentItem.key) {
                                await updateDoc(docRef, currentItem).then(() => {
                                    this.props.openSnack('success', currentItem.key ? 'Site updated!' : "Site created!")
                                })
                            }
                            else {
                                await setDoc(docRef, {
                                    ...currentItem,
                                    key: currentItem.tempKey
                                }).then(() => {
                                   
                                    this.props.openSnack('success', currentItem.key ? 'Site updated!' : "Site created!")
                                    this.props.onClose()
                                })

                            }


                        }




                    }}>{currentItem.key ? 'Update Site' : "Create Site"}</Button>
            )
        }




        return (
          
               
               

                <Modal title="" visible={openModal} onCancel={() => {
                    this.setState({ openModal: false })
                    this.props.onClose()
                } } footer={null} width={'60%'} style={{ borderRadius: 40 }} >
                    <Row align="middle" gutter={[16, 16]} >
                        <Col xs={{ offset: 0, span: 24 }} sm={{ offset: 6, span: 18 }} style={{ paddingBottom: 12, }}>
                            <Typography variant='h5'>Site</Typography>
                        </Col>


                        <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                            <Typography variant="body1">Site Name<span style={{ color: 'red' }}>*</span></Typography>
                        </Col>
                        <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                            <OutlinedInput fullWidth notched={false} label="none" placeholder={"Name"} size="small"
                                value={currentItem.name || ''}
                                onChange={(e) => {
                                    this.updateCurrentItem(`name`, e.target.value)
                                }} />
                        </Col>
                      

                        <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                            <Typography variant="body1">Description</Typography>
                        </Col>
                        <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                            <OutlinedInput fullWidth notched={false} label="none" placeholder={"Description"} size="small"
                                multiline={true}
                                rows={4}
                                value={currentItem.description || ''}
                                onChange={(e) => {
                                    this.updateCurrentItem(`description`, e.target.value)
                                }} />
                        </Col>
                        <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                            <Typography variant="body1">Depth From Bottom (ft)</Typography>
                        </Col>
                        <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                            <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                type="number"
                                inputProps={{ min: 0 }}
                                value={currentItem.depthFromBottom || 0}
                                onChange={(e) => {
                                    this.updateCurrentItem(`depthFromBottom`, e.target.value === "" ? "" : Number(e.target.value))
                                }} />

                        </Col>
                        <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                            <Typography variant="body1">Map</Typography>
                        </Col>
                        <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0, display: 'flex', height: 300, }}>

                            <Map className="Map" style={{ width: '100%', height: '100%', borderRadius: 16, }}
                                mapProperties={{ basemap: 'arcgis-imagery' }}

                                viewProperties={{
                                    center: [account.lng, account.lat],
                                    zoom: 16,
                                    ui: []

                                }}>

                                <Layer map={this.map} view={this.view} updateMarker={(string) => {
                                    this.updateCurrentItem(`marker`, string)
                                }} marker={currentItem.marker || undefined} />

                                <SearchBar map={this.map} view={this.view} />
                                <ZoomIcon map={this.map} view={this.view} />
                                <Location map={this.map} view={this.view} />

                            </Map>

                        </Col>



                        {currentItem.marker !== undefined && (<Col xs={{ offset: 0, span: 24 }} sm={{ offset: 6, span: 18 }} style={{ paddingBottom: 12, }}>
                            <Typography variant='body1'>Lat: {JSON.parse(currentItem.marker).attributes.lat}</Typography>
                            <Typography variant='body1'>lon: {JSON.parse(currentItem.marker).attributes.lon}</Typography>
                        </Col>)}
                        < Col xs={{ offset: 0, span: 24 }} sm={{ offset: 6, span: 18 }} style={{ paddingBottom: 12, }}>
                            <CreateSite />
                        </Col>

                    </Row>
                </Modal >




          
        );
    }
} 