import React from 'react';
import { Col, Row } from 'antd';
import { Breadcrumbs, Button, OutlinedInput, Paper, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { setDefaultOptions, loadModules, } from 'esri-loader';
import { Map, Scene } from '@esri/react-arcgis';
import SearchBar from './searchBar';
import Location from './location';
import UpdateMap from './updateMap';
import RelocateMap from './relocateMap';

loadModules(['esri/config',])
    .then(([config,]) => {
        config.apiKey = "AAPK65ed7997d0524b6582730da31dc1f8986spR1Pjc0oR3qn-NVYrj0jI7Y6156OU89gRRDdPUWC1gjMayxwOkEmxo2aoZH56R";
    })
    .catch(err => {
        // handle any errors
        console.error(err);
    });

export default class LocationSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            gps: this.props.gps,
            zoom: this.props.zoom,
            screenWidth: this.props.screenWidth,
            loading: false

        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.gps !== prevProps.gps) {
            this.setState({ gps: [...this.props.gps], loading: false })

        }

    }
    componentDidMount() {
    }

    updateMap = (data) => {

        const { account, userInfo, zoom } = this.state;
        this.setState({zoom: data.zoom})
        this.props.updateGPS(data);



    }

    render() {

        const { account, screenWidth, gps, loading, zoom } = this.state;

        return (
            <Row gutter={[12, 12]} style={{ height: screenWidth === 'xs' ? '60%' : '70%' }}>

                <Col span={24} style={{ display: 'flex', height: 300 }}>
                    {loading === false && (
                        <Map className="Map" style={{ width: '100%' }}
                            mapProperties={{ basemap: 'arcgis-imagery' }}

                            viewProperties={{
                                center: gps,
                                zoom: zoom,
                                ui: {
                                    components: ["zoom",],
                                }
                            }}>


                            <UpdateMap updateMap={this.updateMap} map={this.map} view={this.view} gps={gps} zoom={zoom} />
                            <RelocateMap view={this.view} gps={gps} />

                            <SearchBar map={this.map} view={this.view} />
                            <Location map={this.map} view={this.view} />

                        </Map>)}
                </Col>


            </Row >
        );
    }
} 