import { Button, Checkbox, Divider, OutlinedInput, Typography, Select, MenuItem, CircularProgress, Breadcrumbs, FormGroup, FormControlLabel, Autocomplete, Chip, TextField, Switch } from '@mui/material';
import { Col, Row } from 'antd';
import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { fullMobile } from './../../util';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, } from 'firebase/firestore';

import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import uuid from 'react-uuid';


import DatePicker from "react-datepicker";
import queryString from 'query-string';
import axios from 'axios';
import { Link } from 'react-router-dom';
import LogWeather from './logWeather';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import './DatePickerStyles.css';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
var randomColor = require('randomcolor');

export default function LogGeneralInfo(props) {
    const { userInfo, screenWidth, openSnack, company, account, log, setLog, updateHistory, updateWeatherItem } = props;
    const [staff, setStaff] = useState([]);
    const [updateWeather, setUpdateWeather] = useState(false)
    const [leadStaff, setLeadStaff] = useState(null)




    useEffect(() => {
        const filteredUsers = props.staffRef.filter(u =>
            !['Maddy', 'Blesso'].some(name => u.label.includes(name))
        )
        setStaff(filteredUsers)
    }, [props.staffRef])





    function updateItem(item, val) {
        console.log(item)
        console.log(val)
        console.log({
            ...log, [item]: val

        })
        setLog({ ...log, [item]: val })
    }




    console.log(updateWeather)

    return (
        <Row gutter={[12, 12]}>
            <Col {...fullMobile(10, 24)} style={{ paddingTop: 10 }}>
                <Typography variant='h5' fontWeight={500} fontSize={22}>General Info</Typography>
                {log.startDate !== undefined && (<LogWeather {...props} log={log}
                    setLog={{ ...log, leadStaff: log.leadStaff || leadStaff, startDate: log.startDate }}
                    updateHistory={updateHistory}
                    updateItem={updateWeatherItem}
                    updateWeatherProps={updateWeather}
                    weatherPropsUpdate={(e) => {
                        console.log(e)
                        setUpdateWeather(e)
                    }} />)}

            </Col>
            <Col {...fullMobile(14, 24)} style={{ paddingTop: 10 }}>
                <Row gutter={[screenWidth === 'xs' ? 4 : 12, screenWidth === 'xs' ? 4 : 12]}
                    style={{ paddingTop: 20, paddingLeft: screenWidth === 'xs' ? 15 : 0 }} align="middle">


                    <Col xs={24} sm={10} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                        <Typography variant="body1" fontWeight={600}>Log #<span style={{ color: 'red' }}>*</span></Typography>
                    </Col>
                    <Col xs={24} sm={14} style={{ paddingBottom: screenWidth === 'xs' ? 12 : 0 }}>
                        {props.status === 'built' ? <Typography variant="body1">{log?.lognumber}</Typography> :
                            <OutlinedInput
                                margin="dense"
                                style={{ fontSize: 14 }}
                                notched={false} size="small" label="none" inputProps={{ min: 0 }}
                                value={log?.lognumber} type='number' fullWidth={screenWidth === 'xs' ? true : false}
                                onChange={(e) => setLog({ ...log, lognumber: e.target.value })}
                            />}
                    </Col>

                    <Col xs={24} sm={10} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                        <Typography variant="body1" fontWeight={600}>Start Date/Time<span style={{ color: 'red' }}>*</span></Typography>
                    </Col>


                    <Col xs={20} sm={10} style={{ paddingBottom: screenWidth === 'xs' ? 12 : 0 }}>
                        {props.status === 'built' ? <Typography variant="body1">{moment(log?.startDate, 'x').format('MM/DD/YYYY h:mm a')}</Typography> :
                            <DatePicker
                                className="datepicker"
                                style={{ height: '40px' }} portalId="root-portal" size="large" showTimeSelect timeFormat="h:mm a"
                                timeIntervals={1} timeCaption="time" dateFormat="MM/dd/yyyy h:mm a"
                                customInput={
                                    <input
                                        size='small'
                                        margin="dense"
                                        type="text"
                                        id={uuid()}
                                        style={{
                                            width: '100%',
                                            height: '40px',

                                            borderRadius: 4,
                                            fontSize: 14,

                                            fontWeight: 400,
                                            cursor: 'pointer',
                                        }}

                                    />
                                }
                                selected={[undefined, '', "Invalid date"].includes(log?.startDate) ? new Date() : new Date(moment(log?.startDate, 'x'))}
                                onChange={(date) => {

                                    const unix = moment(date).format('x');
                                    setLog({ ...log, startDate: unix })
                                    //if unix is not in the future
                                    if (unix < moment().format('x')) {
                                        setUpdateWeather(unix)
                                    }
                                    else {
                                        openSnack('error', 'Cannot set weather for future date')
                                    }

                                }}
                            />}
                    </Col>
                    <Col xs={4} sm={4} style={{}}>
                        <Typography variant="body1" fontSize={10} fontWeight={600}>Multi-Day</Typography>
                        {props.status === 'built' ? <FontAwesomeIcon icon={log?.multiDay ? faCheckCircle : faTimesCircle}
                            style={{ color: log?.multiDay ? 'green' : 'red' }} /> :
                            <Checkbox style={{ paddingTop: 0 }} checked={log?.multiDay} onChange={(e) => updateItem('multiDay', e.target.checked)} />
                        }
                    </Col>
                    <Col xs={24} sm={10} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                        <Typography variant="body1" fontWeight={600}>End Date/Time<span style={{ color: 'red' }}>*</span></Typography>
                    </Col>


                    <Col xs={20} sm={10} style={{ paddingBottom: screenWidth === 'xs' ? 12 : 0 }}>
                        {props.status === 'built' ? <Typography variant="body1">{moment(log?.endDate, 'x').format('MM/DD/YYYY h:mm a')}</Typography> :
                            <DatePicker style={{ height: '40px' }} portalId="root-portal" showTimeSelect timeFormat="h:mm a" timeIntervals={1} timeCaption="time" dateFormat="MM/dd/yyyy h:mm a"
                                selected={[undefined, '', "Invalid date"].includes(log?.endDate) ? new Date() : new Date(moment(log?.endDate, 'x'))}
                                customInput={
                                    <input
                                        size='small'
                                        margin="dense"
                                        type="text"
                                        id={uuid()}
                                        style={{
                                            width: '100%',
                                            height: '40px',

                                            borderRadius: 4,
                                            fontSize: 14,

                                            fontWeight: 400,
                                            cursor: 'pointer',
                                        }}

                                    />
                                }
                                onChange={(date) => {
                                    if (date === null) { setLog({ ...log, endDate: moment().format('x') }) }
                                    else {
                                        const unix = moment(date).format('x');

                                        setLog({ ...log, endDate: unix })
                                    }
                                }}
                            />}
                    </Col>












                    <Col xs={24} sm={10} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                        <Typography variant="body1" fontWeight={600}>Lead Staff<span style={{ color: 'red' }}>*</span></Typography>
                    </Col>
                    <Col xs={24} sm={14} style={{ paddingBottom: screenWidth === 'xs' ? 12 : 0 }}>
                        {props.status === 'built' ? <Typography variant="body1">{log?.leadStaff !== undefined ? log?.leadStaff.label : ''}</Typography> :
                            updateWeather !== false ? <Autocomplete
                                size="small"

                                id="combo-box-demo"
                                value={log?.leadStaff}
                                disableCloseOnSelect={false}
                                options={staff}
                                renderInput={(params) => <TextField {...params}
                                    placeholder="Staff" />}
                                onChange={(a, b) => {
                                    setLeadStaff(b)
                                    setLog({ ...log, leadStaff: b })
                                    setTimeout(() => {
                                        setLeadStaff(b)
                                        setLog({ ...log, leadStaff: b })
                                    }, 500);
                                    setTimeout(() => {
                                        setLeadStaff(b)
                                        setLog({ ...log, leadStaff: b })
                                    }, 1000);
                                    setTimeout(() => {
                                        setLeadStaff(b)
                                        setLog({ ...log, leadStaff: b })
                                    }, 1500);
                                    setTimeout(() => {
                                        setLeadStaff(b)
                                        setLog({ ...log, leadStaff: b })
                                    }, 2000);
                                    setTimeout(() => {
                                        setLeadStaff(b)
                                        setLog({ ...log, leadStaff: b })
                                    }, 2500);
                                    setTimeout(() => {
                                        setLog({ ...log, leadStaff: b })
                                    }, 3000);
                                    setTimeout(() => {
                                        setLog({ ...log, leadStaff: b })
                                    }, 5000);

                                }}

                            /> : null}
                        {updateWeather === false && (
                            <FontAwesomeIcon icon={faSpinner} spin />
                        )}
                    </Col>
                    <Col xs={24} sm={10} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                        <Typography variant="body1" fontWeight={600}>Additional Staff</Typography>
                    </Col>
                    <Col xs={24} sm={14} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                        {props.status === 'creating' ? <Autocomplete
                            size="small"
                            multiple={true}
                            id="combo-box-demo"
                            value={log?.additionalStaff}

                            options={staff}
                            filterSelectedOptions
                            renderInput={(params) => <TextField {...params}
                                placeholder="Staff" />}
                            onChange={(a, b) => {
                                setLog({ ...log, additionalStaff: b })
                            }}

                        /> : log?.additionalStaff !== undefined ? log?.additionalStaff.map((a) => {
                            return (
                                <Chip label={a.label} variant="outlined" />
                            )
                        }) : ''}
                    </Col>
                    <Col xs={24} sm={10} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                        <Typography variant="body1" fontWeight={600}>Billed</Typography>
                    </Col>
                    <Col xs={24} sm={14} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                        {props.status === 'creating' ?
                            <Switch
                                checked={log?.billed}
                                onChange={(e) => {
                                    setLog({ ...log, billed: e.target.checked })
                                    updateHistory(`Billed set to ${e.target.checked ? 'Yes' : 'No'}`)


                                }}
                                name="checkedA"
                                size="lage"
                            />


                            :
                            <Typography variant="body1">{log?.billed ? 'Yes' : 'No'}</Typography>

                        }
                    </Col>





                </Row>
            </Col>
            <Col {...fullMobile(24, 24)}>
                <Divider style={{
                    borderColor: 'rgb(148, 186, 195)',
                    borderBottomWidth: 'medium',

                }} />
            </Col>

        </Row>
    )



}