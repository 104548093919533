import { collection, doc, getCountFromServer, getDocs, getFirestore, query, setDoc, where } from "firebase/firestore"
import uuid from "react-uuid";
import moment from 'moment-timezone';



export const createAlbumFunction = async (album, currentParentAlbum, userInfo, setCurrentAlbum, openSnack) => {
    try {
        album.album_id = album.name === "Field log photos" ? 'field_log_photos' : album.name === 'Application photos' ? 'application_photos' : uuid();
        album.account = userInfo.currentAccount;
        album.company = userInfo.currentCompany;
        album.created_date = Number(moment().format('x'));
        album.isPublic = true;
        album.tags = [];
        album.coverPhoto = null;
        album.status = 'active';
        album.creator = userInfo.id;
        album.parent_album_id = currentParentAlbum?.album_id || null;
        const db = getFirestore();
        const albumRef = doc(db, "clients", userInfo.currentCompany, "albums_new", album.album_id);
        await setDoc(albumRef, album);

        setCurrentAlbum(null)
        openSnack('success', 'Album created')
    }
    catch (e) {
        openSnack('error', 'Error creating album')
    }
}

export const deleteAlbumFunction = async (album, userInfo, setCurrentAlbum, openSnack) => {
    try {
        const db = getFirestore();
        
        const albumRef = doc(db, "clients", userInfo.currentCompany, "albums_new", album.album_id);
        await setDoc(albumRef, { ...album, status: 'deleted', deleted_date: new Date() });

        setCurrentAlbum(null);
        openSnack('success', 'Album deleted');
    }
    catch (e) {
        openSnack('error', 'Error deleting album');
    }
}



export const countAlbumImagesFunction = async (album, userInfo,) => {
    try {
        const db = getFirestore();
        const coll = collection(db, "clients", userInfo.currentCompany, "photos_new");
        const q = query(coll, where("parent_album_id", "==", album.album_id));
        const snapshot = await getCountFromServer(q);

        return snapshot.data().count;
    }
    catch (e) {
        console.log(e)
        return 0;
    }
}
export const countAlbumsFunction = async (album, userInfo,) => {
    try {
        const db = getFirestore();
        const coll = collection(db, "clients", userInfo.currentCompany, "albums_new");
        const q = query(coll, where("parent_album_id", "==", album.album_id));
        const snapshot = await getCountFromServer(q);

        return snapshot.data().count;
    }
    catch (e) {
        console.log(e)
        return 0;
    }
}

export const updateAlbumFunction = async (album, userInfo, setCurrentAlbum, openSnack) => {
    try {
        const db = getFirestore();
        const albumRef = doc(db, "clients", userInfo.currentCompany, "albums_new", album.album_id);
        await setDoc(albumRef, album);
        setCurrentAlbum(null)
        openSnack('success', 'Album updated')
    }
    catch (e) {
        openSnack('error', 'Error updating album')
    }
}

export const updateParentIdFunction = async (type, item_id, newParentAlbum_id, userInfo, setCurrentAlbum, openSnack) => {
    try {
        const db = getFirestore();
        const ref = doc(db, "clients", userInfo.currentCompany, type === 'album' ? "albums_new" : 'photos_new', item_id);
        await setDoc(ref, { parent_album_id: newParentAlbum_id }, { merge: true });
        setCurrentAlbum(null)
        openSnack('success', 'Album updated')
    }
    catch (e) {
        openSnack('error', 'Error updating album')
    }
}






export async function fetchAlbums(userInfo, currentParentAlbum, setFecthingAlbums, openSnack) {
    try {
        const db = getFirestore();
        const ref = collection(db, "clients", userInfo.currentCompany, 'albums_new');

        let queryRef;
        if (currentParentAlbum) {
            queryRef = query(ref, where("account", "==", userInfo.currentAccount), where("parent_album_id", "==", currentParentAlbum.album_id), where("status", "!=", 'deleted'));
        } else {
            queryRef = query(ref, where("account", "==", userInfo.currentAccount), where("parent_album_id", "==", null), where("status", "!=", 'deleted'));
        }

        const snap = await getDocs(queryRef);
        const data = snap.docs.map((doc) => doc.data());

        openSnack('success', 'Data retrieved successfully');
        setFecthingAlbums(false);
        return data;

    } catch (error) {
        openSnack('error', 'Error retrieving data');
        setFecthingAlbums(false);
        return [];
    }
};

export const updateAlbumImageFunction = async (album, lastPhoto, userInfo, openSnack) => {
    try {

        const db = getFirestore();
        const albumRef = doc(db, "clients", userInfo.currentCompany, "albums_new", album.album_id);
        await setDoc(albumRef, { coverPhoto: lastPhoto.medium }, { merge: true });
        openSnack('success', 'Album updated')
    }
    catch (e) {
        console.log(e)
        openSnack('error', 'Error updating album')
    }
}
