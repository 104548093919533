import DeleteForever from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, ButtonGroup, IconButton, OutlinedInput, TextField, Typography } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Col, Modal, Row } from 'antd';
import { collection, doc, getDocs, getFirestore, onSnapshot, query, setDoc, where } from 'firebase/firestore';
import moment from 'moment';
import MUIDataTable from "mui-datatables";
import randomColor from 'randomcolor';
import React from 'react';
import "react-datepicker/dist/react-datepicker.css";
import uuid from 'react-uuid';
import ConcentrationUnits from '../../../extra/concentrationUnits.json';
import Units from '../../../extra/productUnits.json';
import ItemForm from '../../../items/itemForm';
import MyApp from '../applicationComponents/appMap';
import './tableStyle.css';
import { fullMobile } from './util';

export default class FLLineItems extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            doc: this.props.doc,
            preview: this.props.preview,
            loading: false,
            editRow: undefined,
            dataList: [],
            tempData: [],
            items: [],
            sites: [],
            unitList: [],
            concentrationsList: [],
            stocks: [],
            testKey: this.props.testKey,
            uploadingData: false,
            saveWaterQualityData: this.props.saveWaterQualityData,
            status: this.props.status,
            itemModal: false,
            newItemType: '',
            newItem: {}

        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
        if (this.props.status !== prevProps.status) {
            this.setState({ status: this.props.status })
        }

        if (this.props.testKey !== prevProps.testKey) {

            if (this.props.testKey !== undefined) {
                this.setState({ uploadingData: true, })
                this.updateData(this.props.testKey)
                this.queryData(this.props.testKey)
            }
            this.setState({ testKey: this.props.testKey })
        }



        if (this.props.saveWaterQualityData !== prevProps.saveWaterQualityData) {

            this.setState({ saveWaterQualityData: this.props.saveWaterQualityData })
            if (this.props.saveWaterQualityData === true && this.state.testKey !== undefined) {
                this.saveData()
            }
        }

    }
    componentDidMount() {
        const { userInfo, data, testKey } = this.state;


        this.queryStocks()
        this.queryItems()

        if (testKey !== undefined) {
            this.queryData(testKey)
        }

        const unitList = Object.values(Units).map((u, i) => {
            return ({ label: u, key: u, unitid: Object.keys(Units)[i] })
        })
        const concentrationsList = Object.values(ConcentrationUnits).map((u, i) => {
            return ({ label: u, key: u, unitid: Object.keys(Units)[i] })
        })
        this.setState({ unitList, concentrationsList })

    }

    queryData = async (testKey) => {
        const { userInfo, } = this.state;

        const db = getFirestore();
        const q = query(collection(db, "clients", userInfo.currentCompany, 'flItems'), where("logkey", "==", testKey));
        const querySnapshot = await getDocs(q);
        const dataList = [];
        querySnapshot.forEach((doc) => {
            dataList.push(doc.data())
        });

        this.setState({ dataList, uploadingData: false })
    }


    queryItems = async (testKey) => {
        const { userInfo, } = this.state;
        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'psItems'), where('archived', '==', false));
        const unsubscribe = onSnapshot(queryData, async (querySnapshot) => {
            const data = [];
            querySnapshot.forEach((doc) => {
                data.push({ ...doc.data(), label: doc.data().name })
            });
            data.sort((a, b) => a.name.localeCompare(b.name))

            this.setState({ items: data, uploadingData: false })


        });
    }

    updateData = async (key) => {
        const { userInfo, tempData, dataList } = this.state;
        //set  uploadingData true
        this.setState({ uploadingData: true })
        const filteredData = tempData;

        filteredData.forEach(async (f, i) => {
            f.logkey = key;

            const db = getFirestore();
            const docRef = doc(db, "clients", userInfo.currentCompany, 'flItems', f.key);
            await setDoc(docRef, f,).catch((error) => {
                console.error("Error adding document: ", error);
            });
            //if filteredData length === i + 1 then turn off uploadingData
            if (filteredData.length === i + 1) {
                this.setState({ uploadingData: false, editRow: '123' })
            }



        })



    }

    saveData = async () => {
        const { userInfo, tempData, dataList, testKey } = this.state;
        //set  uploadingData true
        this.setState({ uploadingData: true })
        const filteredData = dataList;

        //if filteredData length === 0 then turn off uploadingData
        if (filteredData.length === 0) {
            this.setState({ uploadingData: false, editRow: '123' })
        }

        filteredData.forEach(async (f, i) => {
            //setdoc each f into firebase flitems

            f.logkey = testKey;
            const db = getFirestore();
            const docRef = doc(db, "clients", userInfo.currentCompany, 'flItems', f.key);
            await setDoc(docRef, f,).catch((error) => {
                console.error("Error adding document: ", error);
            });;
            //if filteredData length === i + 1 then turn off uploadingData
            if (filteredData.length === i + 1) {
                this.setState({ uploadingData: false, editRow: '123' })
            }


        })

    }





    queryStocks = async () => {
        const { userInfo, type } = this.state;
        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'productStock'), where('archived', '==', false));
        const unsubscribe = onSnapshot(queryData, async (querySnapshot) => {
            const data = [];
            querySnapshot.forEach((doc) => {
                data.push({ ...doc.data(), label: doc.data().name })
            });
            data.sort((a, b) => a.name.localeCompare(b.name))

            this.setState({ stocks: data, })

        });
    }








    addData = () => {
        const { dataList, userInfo, testKey, tempData } = this.state;
        var time = moment().tz("America/Los_Angeles").format("YYYY-MM-DD HH:mm");

        const item = {
            imgurl: '',
            timestamp: Number(moment().format('X')),
            account: userInfo.currentAccount,
            company: userInfo.currentCompany,
            key: uuid(),
        }

        if (testKey === undefined) {
            tempData.push(item)
            this.setState({ tempData })

        }
        else {
            dataList.push(item)
            this.setState({ dataList })
        }


    }


    updateTable = (key, boo, val) => {
        const { dataList, userInfo, testKey, tempData } = this.state;

        if (testKey === undefined) {

            const index = tempData.findIndex((f) => f.key === key);
            tempData[index][boo] = val;
            this.setState({ tempData })
        }
        else {
            const index = dataList.findIndex((f) => f.key === key);
            dataList[index][boo] = val;
            this.setState({ dataList })
        }

    }

    updateMap = (key, boo, val) => {
        const { dataList, tempData, testKey } = this.state;


        if (testKey === undefined) {
            const index = tempData.map((e) => e.key).indexOf(key);
            tempData[index]["map"][boo] = val;
            this.setState({ tempData })
        }
        else {
            const index = dataList.map((e) => e.key).indexOf(key);
            dataList[index]["map"][boo] = val;
            this.setState({ dataList })
        }
    }

    getMuiTheme = () =>
        createTheme({
            components: {
                MUIDataTable: {
                    styleOverrides: {
                        root: {
                            padding: '8px',
                            boxShadow: 'none'
                        }
                    }
                },
                MuiTableRow: {
                    styleOverrides: {
                        root: {
                            zIndex: 100,
                            cursor: 'pointer',
                            fontSize: '10px',
                            '&:nth-child(even)': {
                                backgroundColor: '#eeeeee52'
                            },
                            "&:hover": {
                                backgroundColor: "#9ec1c933"
                            }


                        },


                    }
                },
                MuiTableCell: {
                    styleOverrides: {
                        root: {
                            fontSize: '12px',
                        }
                    }
                },
                MuiAutocomplete: {
                    styleOverrides: {
                        root: {
                            fontSize: '12px',
                        },
                        input: {
                            fontSize: '12px',
                        },


                    }
                },
                MuiInputBase: {
                    styleOverrides: {
                        root: {
                            fontSize: '12px',
                        }
                    }
                },
                MuiInput: {
                    styleOverrides: {
                        root: {
                            fontSize: '12px',

                        },

                    }
                },




            }
        });


    setNewItem = (item) => { this.setState({ newItem: item }) }



    render() {



        const filterOptions = createFilterOptions({
            matchFrom: 'start',
            stringify: (option) => option.label,
        });

        const { account, preview, screenWidth, loading, editRow, sites, unitList, concentrationsList, stocks, userInfo,
            testKey, tempData, uploadingData, dataList, items, status, newItem, itemModal, newItemType } = this.state;


        const data = testKey === undefined ? tempData : dataList;


        const options = {
            filter: false,
            searchable: false,
            search: false,
            searchable: false,
            viewColumns: false,
            download: false,
            print: false,
            draggable: true,
            responsive: 'simple',
            rowsPerPage: 50,
            rowsPerPageOptions: [10, 50, 100],
            selectableRows: screenWidth === 'xs' ? false : status === 'built' ? false : true,
            filterType: 'dropdown',
            onColumnSortChange: (changedColumn, direction) => console.log('changedColumn: ', changedColumn, 'direction: ', direction),
            onChangeRowsPerPage: numberOfRows => console.log('numberOfRows: ', numberOfRows),
            onChangePage: currentPage => console.log('currentPage: ', currentPage),
            onRowsDelete: (rows) => {


                rows.data.sort((a, b) => b.index - a.index).forEach((row) => {
                    data.splice(row.index, 1)
                })
                this.setState({ data })


            },
            onRowClick: (rowData, rowMeta, b) => {
                if (status === 'creating' && screenWidth !== 'xs') {

                    const index = data.map((f, i) => i).indexOf(rowMeta.dataIndex);
                    const d = data[index];

                    if (d.key !== editRow) {

                        this.updateTable(d.key, 'edit', !d.edit);

                        this.setState({ editRow: this.state.editRow === d.key ? undefined : d.key })
                    }
                }

            },
        };

        const keyColumn = 0;

        const columns = [

            {
                name: 'key',
                label: "Edit",
                options: {
                    display: status === 'creating' ? true : false,
                    filter: false,
                    sort: false,
                    empty: true,
                    customBodyRender: (value, b, updateValue) => {
                        if (status === 'creating') {
                            return (
                                <IconButton size="small" aria-label="delete" color={editRow === value ? 'primary' : 'inherit'} onClick={() => {

                                    this.setState({ editRow: this.state.editRow === value ? undefined : value })
                                }}>
                                    <EditIcon />
                                </IconButton>

                            );
                        }
                        else {
                            return (
                                <div>   </div>
                            )

                        }
                    }
                }
            },

            {
                name: "item",
                label: "Item",
                options: {
                    filter: false,

                    customBodyRender: (a, b, c) => {


                        const itemIndex = a === undefined ? -1 : items.map((e) => e.key).indexOf(a.key);
                        const label = itemIndex === -1 ? '' : items[itemIndex].label;
                        const type = itemIndex === -1 ? '' : items[itemIndex].psType;
                        const dataIndex = data.map((e) => e.key).indexOf(b.rowData[0]);
                        const item = dataIndex === -1 ? undefined : data[dataIndex];
                        const map = item.map === undefined ? undefined : item.map;

                        const totalArea = loading === false && map !== undefined && map.graphics !== undefined ? JSON.parse(map.graphics).length === 0 ? 0 : Number(Number(JSON.parse(map.graphics).map((g) => !['point'].includes(g.attributes.shapeType) ? g.attributes.area : 0).reduce((partialSum, a) => partialSum + a, 0)).toFixed(2)).toLocaleString() : 0;
                        const totalPerimeter = loading === false && map !== undefined && map.graphics !== undefined ? JSON.parse(map.graphics).length === 0 ? 0 : Number(Number(JSON.parse(map.graphics).map((g) => !['point'].includes(g.attributes.shapeType) ? g.attributes.length : 0).reduce((partialSum, a) => partialSum + a, 0)).toFixed(0)).toLocaleString() : 0;

                        return (
                            <Row gutter={[12, 12]} >
                                <Col {...fullMobile(map === undefined ? 22 : 12, 24)} >
                                    <Row gutter={[12, 12]} >
                                        <Col {...fullMobile(map === undefined ? 12 : 24, 24)} style={{}}>
                                            <Typography variant="body1" fontSize={13} fontWeight={600}>Product/Service</Typography>


                                            {editRow !== b.rowData[keyColumn] || preview ?
                                                <Typography variant='body2'>{label}</Typography> :
                                                <Autocomplete
                                                    size="small"
                                                    id="combo-box-demo"
                                                    value={label}
                                                    disableClearable={true}
                                                    options={items}

                                                    filterOptions={(options, _ref) => [...filterOptions(options, _ref), { label: 'Add New', key: 'Add New', value: 'addNew' },]}
                                                    noOptionsText={<Button fullWidth variant='contained' color='primary' style={{ textTransform: 'none' }}
                                                        onClick={() => {
                                                            this.setState({ itemModal: true })
                                                            this.setNewItem({ tempKey: uuid(), });
                                                        }}
                                                    >+ Create Item</Button>}
                                                    renderInput={(params) => <TextField {...params} label="Product/Services" />}
                                                    renderOption={(params) => {

                                                        if (params.key === 'Add New') {
                                                            return (
                                                                <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 4 }}>
                                                                    <Button fullWidth variant='contained' color='primary' style={{ textTransform: 'none' }} onClick={(e) => {
                                                                        this.setState({ itemModal: true })
                                                                        this.setNewItem({ tempKey: uuid(), });

 
                                                                    }}>+ Create Item</Button>
                                                                </Box>

                                                            )
                                                        }
                                                        else {
                                                            return (
                                                                <Typography {...params}>{params.key}</Typography>
                                                            )
                                                        }
                                                    }}
                                                    onChange={(a, d) => {
                                                        console.log(d)
                                                        this.updateTable(b.rowData[keyColumn], 'item', d);
                                                        this.updateTable(b.rowData[keyColumn], 'description', d.description || '');
                                                        this.updateTable(b.rowData[keyColumn], 'psType', d.psType);
                                                    }}

                                                />}
                                        </Col>

                                        {type === 'products' && (<Col {...fullMobile(map === undefined ? 12 : 24, 24)} style={{}}>
                                            <Typography variant="body1" fontSize={13} fontWeight={600}>Stock</Typography>

                                            {editRow !== b.rowData[keyColumn] || preview ?
                                                <Typography variant='body2'>{item.supplyStock !== undefined ? item.supplyStock.label : ''}</Typography> :
                                                <Autocomplete
                                                    size="small"
                                                    id="combo-box-demo"
                                                    value={item.supplyStock}
                                                    options={stocks}
                                                    filterSelectedOptions
                                                    renderInput={(params) => <TextField {...params} label="Stock" />}
                                                    onChange={(a, d) => {
                                                        this.updateTable(b.rowData[keyColumn], 'supplyStock', d);
                                                    }}

                                                />}
                                        </Col>)}
                                        {type === 'products' && (<Col {...fullMobile(map === undefined ? 8 : 24, 12)} style={{}}>
                                            <Typography variant="body1" fontSize={13} fontWeight={600}>Application Method</Typography>

                                            {editRow !== b.rowData[keyColumn] || preview ?
                                                <Typography variant='body2'>{item.applicationMethod !== undefined ? item.applicationMethod.label : ''}</Typography> :
                                                <Autocomplete
                                                    size="small"
                                                    id="combo-box-demo"
                                                    value={item.applicationMethod}
                                                    style={{ fontSize: 13 }}
                                                    filterSelectedOptions
                                                    options={[
                                                        { label: "Boat", key: 'airBoat' },
                                                        { label: "UTV/Gator", key: 'UTV/Gator' },
                                                        { label: "Backpack Sprayer", key: 'backpackSprayer' },
                                                        { label: "Backpack Blower", key: 'backpackBlower' },
                                                        { label: "Hand Broadcast", key: 'handBroadcast' },
                                                        { label: "Spray Rig", key: 'sprayRig' },
                                                    ]}
                                                    renderInput={(params) => <TextField {...params} label="Method" />}
                                                    onChange={(a, d) => {
                                                        this.updateTable(b.rowData[keyColumn], 'applicationMethod', d);
                                                    }}

                                                />}
                                        </Col>)}
                                        {type === 'products' && (<Col {...fullMobile(map === undefined ? 5 : 16, 12)} style={{}}>
                                            <Typography variant="body1" fontSize={13} fontWeight={600}>Rate</Typography>
                                            {editRow !== b.rowData[keyColumn] || preview ? <Typography variant="body1">{item.concentration}</Typography> : <OutlinedInput
                                                fullWidth notched={false} label="none" placeholder={"Conc."} size="small"
                                                defaultValue={item.concentration}
                                                type="number"
                                                onChange={(e) => {
                                                    this.updateTable(b.rowData[keyColumn], 'concentration', e.target.value === "" ? Number(0) : e.target.value === "0" ? Number(0) : Number(e.target.value));

                                                }} />}
                                        </Col>)}
                                        {type === 'products' && (<Col {...fullMobile(map === undefined ? 3 : 8, 12)} style={{}}>
                                            <Typography variant="body1" fontSize={13} fontWeight={600}>Rate Units</Typography>

                                            {editRow !== b.rowData[keyColumn] || preview ?
                                                <Typography variant='body2'>{item.concentrationUnits !== undefined ? item.concentrationUnits.label : ''}</Typography> :
                                                <Autocomplete
                                                    size="small"
                                                    id="combo-box-demo"
                                                    value={item.concentrationUnits}
                                                    options={concentrationsList}
                                                    renderInput={(params) => <TextField {...params} label="Units" />}
                                                    onChange={(a, d) => {
                                                        this.updateTable(b.rowData[keyColumn], 'concentrationUnits', d);
                                                    }}

                                                />}
                                        </Col>)}

                                        {type === 'products' && (<Col {...fullMobile(map === undefined ? 5 : 16, 12)} style={{}}>
                                            <Typography variant="body1" fontSize={13} fontWeight={600}>Total Amount</Typography>
                                            {editRow !== b.rowData[keyColumn] || preview ? <Typography variant="body1">{item.quantity}</Typography> : <OutlinedInput
                                                fullWidth notched={false} label="none" placeholder={"Amount"} size="small"
                                                defaultValue={item.quantity}
                                                type="number"
                                                onChange={(e) => {
                                                    this.updateTable(b.rowData[keyColumn], 'quantity', e.target.value === "" ? Number(0) : e.target.value === "0" ? Number(0) : Number(e.target.value));
                                                }} />}
                                        </Col>)}
                                        {type === 'products' && (<Col {...fullMobile(map === undefined ? 3 : 8, 12)} style={{}}>
                                            <Typography variant="body1" fontSize={13} fontWeight={600}>Units</Typography>

                                            {editRow !== b.rowData[keyColumn] || preview ?
                                                <Typography variant='body2'>{item.units !== undefined ? item.units.label : ''}</Typography> :
                                                <Autocomplete
                                                    size="small"
                                                    id="combo-box-demo"
                                                    value={item.units}
                                                    options={["mg",
                                                        "g",
                                                        "kg",
                                                        "oz",
                                                        "lb",
                                                        "fl-oz",
                                                        "cup",
                                                        "pnt",
                                                        "qt",
                                                        "gal",
                                                        "ft3",
                                                    ].sort((a, b) => a.localeCompare(b)).map((g) => { return ({ key: g, label: g }) })}
                                                    renderInput={(params) => <TextField {...params} label="Units" />}
                                                    onChange={(a, d) => {
                                                        this.updateTable(b.rowData[keyColumn], 'units', d);

                                                    }}

                                                />}
                                        </Col>)}
                                        {['products', 'services'].includes(type) && (<Col {...fullMobile(24, 24)} style={{}}>
                                            <Typography variant="body1" fontSize={13} fontWeight={600}>Notes</Typography>

                                            {editRow !== b.rowData[keyColumn] || preview ?
                                                <Typography variant='body2'>{item.description !== undefined ? item.description : ''}</Typography> :
                                                <OutlinedInput fullWidth notched={false} label="none" placeholder={"Description"} size="small"
                                                    multiline={true}
                                                    rows={4}
                                                    value={item.description}
                                                    onChange={(e) => {
                                                        this.updateTable(b.rowData[keyColumn], 'description', e.target.value);
                                                    }} />}
                                        </Col>)}
                                    </Row>
                                </Col> 
                                <Col {...fullMobile(map === undefined ? 2 : 12, 24)} >
                                    {status !== 'built' && map === undefined && (<Row>
                                        <Col {...fullMobile(24, 24)} style={{}}>
                                            <Button variant="outlined" color="primary" style={{ textTransform: 'none', padding: 3, fontSize: 12 }} onClick={() => {
                                                this.updateTable(b.rowData[keyColumn], 'map', {
                                                    graphics: JSON.stringify([]), layerColor: randomColor({
                                                        format: 'rgba',
                                                        alpha: 0.5 // e.g. 'rgba(9, 1, 107, 0.6482447960879654)'
                                                    }), imgurl: ''
                                                });
                                            }}>Add Map</Button>
                                        </Col>

                                    </Row>)}

                                    {map !== undefined && (<Row gutter={[12, 12]}>
                                        <Col {...fullMobile(12, 24)} style={{}}>
                                            <Typography variant="body2" fontWeight={600}>Total Area (ac)</Typography>
                                            <Typography variant="body2" >{totalArea}</Typography>


                                        </Col>
                                        <Col {...fullMobile(12, 24)} style={{}}>
                                            <Typography variant="body2" fontWeight={600}>Total Linear Ft</Typography>
                                            <Typography variant="body2" >{totalPerimeter}</Typography>


                                        </Col>
                                        <Col {...fullMobile(24, 24)} style={{}}>
                                            {Object.values(map).length > 0 && (

                                                editRow !== b.rowData[keyColumn] || preview ?

                                                    map.imgurl !== undefined && map.imgurl !== '' ? <img src={map.imgurl} style={{ width: '100%', height: 'auto', borderRadius: 16, }} /> :
                                                        <div style={{
                                                            borderRadius: 16, display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            height: '250px', border: '1px solid rgba(0,0,0,0.4)'
                                                        }} >
                                                            <Typography variant="body2" >Edit row to view map</Typography>
                                                        </div>



                                                    :

                                                    <MyApp account={account} graphics={map.graphics} screenWidth={screenWidth} userInfo={userInfo} item={map} layerColor={map.layerColor}
                                                        updateGraphics={(graphics) => this.updateMap(b.rowData[keyColumn], `graphics`, graphics)}
                                                        sendImage={(url) => {
                                                            this.updateMap(b.rowData[keyColumn], `imgurl`, url);

                                                        }} />)}


                                        </Col>
                                        {status === 'creating' && (
                                            <Col {...fullMobile(24, 24)} style={{}}>
                                                <Button variant="outlined" color="primary" fullWidth style={{ textTransform: 'none', padding: 6 }} onClick={() => {
                                                    this.updateTable(b.rowData[keyColumn], 'map', undefined);
                                                }}>Remove Map</Button>
                                            </Col>
                                        )}
                                    </Row>)}

                                </Col>



                            </Row >



                        )



                    }
                }
            },






        ];



        return (
            <Row style={{}}>
                <Col {...fullMobile(16, 24)}  >
                    <Typography variant="h2" style={{ fontSize: 32, }}>Products and Services</Typography>
                </Col>
                <Col {...fullMobile(8, 24)} style={{ textAlign: 'right' }}>
                    {status === 'creating' && loading === false && (<Button size="small" style={{ textTransform: 'none', padding: 8, fontSize: 14, borderRadius: 24 }} variant="contained" color="primary"
                        fullWidth={screenWidth === 'xs' ? true : false}
                        onClick={() => {
                            this.addData()
                            this.props.openSnack('success', 'Item Added')
                        }}>+ Add Product/Service</Button>)}
                </Col>
                <Col {...fullMobile(24, 24)} style={{ paddingTop: 8 }}>
                    {data !== undefined && data.length > 0 && (
                        <ThemeProvider theme={this.getMuiTheme()}>
                            <MUIDataTable data={data} columns={screenWidth === 'xs' ? [...columns, {
                                name: 'key',
                                label: "Delete",
                                options: {
                                    filter: true,
                                    sort: false,
                                    empty: true,

                                    customBodyRender: (value, b, updateValue) => {
                                        if (status === 'creating') {
                                            return (
                                                <div>
                                                    <IconButton size="small" aria-label="delete" color={'inherit'} onClick={() => {
                                                        //delete this item from data
                                                        console.log(testKey)
                                                        console.log(dataList)
                                                        const items = testKey === undefined ? tempData : dataList;
                                                        const newData = items.filter((d) => d.key !== value);

                                                        this.setState({ dataList: newData, tempData: newData })
                                                        console.log('test')


                                                    }}>
                                                        <DeleteForever />
                                                    </IconButton>

                                                </div>

                                            );
                                        }
                                        else {
                                            return (<div></div>)
                                        }
                                    }
                                }
                            },] : columns} options={options} />
                        </ThemeProvider>
                    )}
                    {data === undefined || data.length === 0 && (
                        <Typography variant='body1'>Add Products and Services</Typography>
                    )}
                </Col>

                <Modal title="" visible={itemModal} onCancel={() => {
                    this.setState({ itemModal: false, newItem: {}, newItemType: '' })

                }} footer={null} width={'60%'} style={{ borderRadius: 40 }} >
                    <Row>
                        <Col {...fullMobile(24, 24)} style={{}}>
                            <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group" >
                                <Button onClick={() => {
                                    this.setState({ newItemType: 'product' })
                                }} style={{ textTransform: 'none', padding: 8, fontSize: 14, }} color={newItemType === 'product' ? 'secondary' : 'primary'}>Product</Button>
                                <Button onClick={() => {
                                    this.setState({ newItemType: 'service' })
                                }} style={{ textTransform: 'none', padding: 8, fontSize: 14, }} color={newItemType === 'service' ? 'secondary' : 'primary'}>Service</Button>
                            </ButtonGroup>
                        </Col>
                        {newItemType === 'product' && (
                            <Col {...fullMobile(24, 24)} style={{ paddingTop: 8 }}>
                                <ItemForm type={newItemType} userInfo={userInfo} newItem={true} openSnack={this.props.openSnack} closeModal={() =>
                                    this.setState({ itemModal: false, newItem: {}, newItemType: '' })} />
                            </Col>
                        )}
                        {newItemType === 'service' && (
                            <Col {...fullMobile(24, 24)} style={{ paddingTop: 8 }}>
                                <ItemForm type={newItemType} userInfo={userInfo} newItem={true} openSnack={this.props.openSnack} closeModal={() =>
                                    this.setState({ itemModal: false, newItem: {}, newItemType: '' })} />
                            </Col>
                        )}

                    </Row>

                </Modal >

            </Row>
        );
    }
} 