

import {
    Badge,
    IconButton,
    List,
    ListItem,
    Popover,
    Tooltip,
    Typography
} from '@mui/material';
import React from 'react';
import { faCheckCircle, faCircle, faCopy, faEdit, faEye, faEyeSlash, faSquare, faSquareCheck, } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsSpin, faCheck, faCircleInfo, faCloud, faDatabase, faDownload, faEllipsisH, faFlask, faGear, faGears, faMap, faTrash } from '@fortawesome/free-solid-svg-icons';
import * as Papa from 'papaparse';
import moment from 'moment-timezone';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import axios, * as others from 'axios';



const Settings = ({
    record,
    duplicateRow,
    deleteRow,
    updateRecord,
    openSnack,
}) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        if (record?.item === null) {
            openSnack('error', 'Please select an item first')
            return
        }
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;





    return (
        <div style={{
            display: 'inline',

        }}>
            <IconButton onClick={handleClick}

            >
                <Tooltip title="Turn on/off additional info" placement="bottom">
                    <FontAwesomeIcon icon={faGears}
                        size="md" style={{
                            color: record?.item === null ? 'gray' : '#3f51b5',
                            cursor: 'pointer'
                        }} />
                </Tooltip>



            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}

                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <List sx={{
                    width: 300,
                    padding: 0,

                }}>
                    <ListItem
                        key={'showMap'}
                        dense
                        button
                        onClick={() => {
                            updateRecord({ ...record, showMap: !record?.showMap });
                        }}
                        sx={{
                            borderBottom: '1px solid #e0e0e0',
                            paddingTop: 1,
                            paddingBottom: 1,
                            backgroundColor: record?.showMap === true ? '#EEF7FF' : 'white'
                        }}
                    >
                        {record?.showMap === true ?
                            <div style={{ width: 24, height: 24, marginRight: 10 }}>
                                <FontAwesomeIcon icon={faCheck} size="lg" style={{}} />
                            </div>

                            : <div style={{ width: 24, height: 24, marginRight: 10 }}></div>}
                        <FontAwesomeIcon icon={faMap} style={{ color: '#3f51b5', marginRight: 10 }} />
                        <Typography variant="body1" color="black" fontSize={16} fontWeight={500}>Show Map</Typography>
                    </ListItem>
                    <ListItem
                        key={'showAdvanced'}
                        dense
                        button
                        onClick={() => {
                            updateRecord({ ...record, showAdvanced: !record?.showAdvanced });
                        }}
                        sx={{
                            borderBottom: '1px solid #e0e0e0',
                            paddingTop: 1,
                            paddingBottom: 1,
                            backgroundColor: record?.showAdvanced === true ? '#EEF7FF' : 'white'
                        }}
                    >
                        {record?.showAdvanced === true ?
                            <div style={{ width: 24, height: 24, marginRight: 10 }}>
                                <FontAwesomeIcon icon={faCheck} size="lg" style={{}} />
                            </div>

                            : <div style={{ width: 24, height: 24, marginRight: 10 }}></div>}
                        <FontAwesomeIcon icon={faGears} style={{ color: '#3f51b5', marginRight: 10 }} />
                        <Typography variant="body1" color="black" fontSize={16} fontWeight={500}>Show Advanced</Typography>
                    </ListItem>
                    <ListItem
                        key={'showWeather'}
                        dense
                        button
                        onClick={() => {
                            updateRecord({ ...record, showWeather: !record?.showWeather });
                        }}
                        sx={{
                            borderBottom: '1px solid #e0e0e0',
                            paddingTop: 1,
                            paddingBottom: 1,
                            backgroundColor: record?.showWeather === true ? '#EEF7FF' : 'white'
                        }}
                    >
                        {record?.showWeather === true ?
                            <div style={{ width: 24, height: 24, marginRight: 10 }}>
                                <FontAwesomeIcon icon={faCheck} size="lg" style={{}} />
                            </div>

                            : <div style={{ width: 24, height: 24, marginRight: 10 }}></div>}
                        <FontAwesomeIcon icon={faCloud} style={{ color: '#3f51b5', marginRight: 10 }} />
                        <Typography variant="body1" color="black" fontSize={16} fontWeight={500}>Show Weather</Typography>
                    </ListItem>
                    <ListItem
                        key={'showWaterQuality'}
                        dense
                        button
                        onClick={() => {
                            updateRecord({ ...record, showWaterQuality: !record?.showWaterQuality });
                        }}
                        sx={{
                            borderBottom: '1px solid #e0e0e0',
                            paddingTop: 1,
                            paddingBottom: 1,
                            backgroundColor: record?.showWaterQuality === true ? '#EEF7FF' : 'white'
                        }}
                    >
                        {record?.showWaterQuality === true ?
                            <div style={{ width: 24, height: 24, marginRight: 10 }}>
                                <FontAwesomeIcon icon={faCheck} size="lg" style={{}} />
                            </div>

                            : <div style={{ width: 24, height: 24, marginRight: 10 }}></div>}
                        <FontAwesomeIcon icon={faFlask} style={{ color: '#3f51b5', marginRight: 10 }} />
                        <Typography variant="body1" color="black" fontSize={16} fontWeight={500}>Show Water Quality</Typography>
                    </ListItem>







                </List>




            </Popover>
        </div>
    );
};

export default Settings;
