

export const createNewCalendar = async (accessToken) => {
    const calendar = {
        summary: "LakeTech Calendar",
        timeZone: "America/Los_Angeles",
    };
    const proxyurl = "https://mycorslake.herokuapp.com/";

    try {
        // Step 1: Create the Calendar
        const createResponse = await fetch(
            proxyurl + 'https://www.googleapis.com/calendar/v3/calendars',
            {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(calendar),
            }
        );

        if (!createResponse.ok) {
            throw new Error('Network response was not ok' + createResponse.statusText);
        }
        const createData = await createResponse.json();
        console.log('Calendar created:', createData);

        // Grab the ID of the created calendar for ACL
        const calendarId = createData.id;

        // Step 2: Set ACL to make it Read-only for all Google users
        const aclRule = {
            role: "reader",
            scope: {
                type: "default",
            }
        };

        const aclResponse = await fetch(
            proxyurl + `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/acl`,
            {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(aclRule),
            }
        );


        await checkAclRules(calendarId, accessToken);

        if (!aclResponse.ok) {
            throw new Error('Network response was not ok' + aclResponse.statusText);
        }
        const aclData = await aclResponse.json();
        console.log('ACL set:', aclData);

        return createData;

    } catch (error) {
        console.error('Error:', error);
    }
};

export const checkAclRules = async (calendarId, accessToken) => {
    const proxyurl = "https://mycorslake.herokuapp.com/";

    try {
        const response = await fetch(
            proxyurl + `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/acl`,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            }
        );

        if (!response.ok) {
            throw new Error('Network response was not ok: ' + response.statusText);
        }

        const data = await response.json();
        console.log('ACL Rules:', data);

    } catch (error) {
        console.error('Error:', error);
    }
};

export const checkIfCalendarExists = async (calendarSummary, accessToken) => {
    console.log(accessToken)
    const proxyurl = "https://mycorslake.herokuapp.com/";
    try {
        const response = await fetch(
            proxyurl + 'https://www.googleapis.com/calendar/v3/users/me/calendarList',
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            }
        );

        if (!response.ok) {
            throw new Error('Network response was not ok: ' + response.statusText);
        }
        const data = await response.json();

        const lakeTechCalendar = data.items.find(
            (cal) => cal.summary === calendarSummary
        );
        return lakeTechCalendar ? lakeTechCalendar.id : null;
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
};

export const deleteCalendar = async (calendarId, accessToken) => {
    const proxyurl = "https://mycorslake.herokuapp.com/";
    try {
        const response = await fetch(
            proxyurl + `https://www.googleapis.com/calendar/v3/calendars/${calendarId}`,
            {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            }
        );

        if (!response.ok) {
            throw new Error('Network response was not ok: ' + response.statusText);
        }

        // Note: successful DELETE responses from Google API often do not return a body
        console.log('Calendar deleted:', response.status);
    } catch (error) {
        console.error('Error:', error);
    }
};

export const createCalendarEvent = async (calendarId, accessToken) => {
    const proxyurl = "https://mycorslake.herokuapp.com/";
    const event = {
        summary: "New Event",
        start: {
            dateTime: "2023-10-12T10:00:00Z",
            timeZone: "America/Los_Angeles",
        },
        end: {
            dateTime: "2023-10-12T11:00:00Z",
            timeZone: "America/Los_Angeles",
        },
    };

    try {
        const response = await fetch(
            proxyurl + `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events`,
            {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(event),
            }
        );

        if (!response.ok) {
            throw new Error('Network response was not ok' + response.statusText);
        }
        const data = await response.json();
        console.log('Event created:', data);
    } catch (error) {
        console.error('Error:', error);
    }
};

export const listAllEvents = async (calendarId, accessToken) => {
    const proxyurl = "https://mycorslake.herokuapp.com/";
    try {
        const response = await fetch(
            proxyurl + `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events`,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            }
        );

        if (!response.ok) {
            throw new Error('Network response was not ok' + response.statusText);
        }
        const data = await response.json();
        console.log('Events:', data);
    } catch (error) {
        console.error('Error:', error);
    }
};