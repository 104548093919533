import React from 'react';
import { Card, Typography, IconButton, Popover, ListItem, List, CardMedia, CardHeader, Box, Checkbox } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faEllipsisV, faEdit, faArrowUp, faTrash } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment-timezone';

const useStyles = makeStyles({
  card: {
    position: 'relative',
    borderRadius: '12px',
    overflow: 'hidden',
    cursor: 'grab',
    background: '#F2F5FC',
    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, border 0.3s ease-in-out',
    '&:hover': {
      transform: 'scale(1.03)',
      boxShadow: '0 5px 15px rgba(0, 0, 0, 0.2)',
      border: '1px solid rgba(0, 0, 0, 0.2)',
    },
  },
  media: {
    height: 200,
    width: '100%',
    objectFit: 'cover',
    cursor: 'zoom-in',
  },
  header: { // Renamed from 'footer' to 'header'
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px 16px',
    background: '#F2F5FC',
    color: 'black',
    borderTopLeftRadius: '12px',
    borderTopRightRadius: '12px',
  },
  title: {
    flexGrow: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  icon: {
    // ... other styles remain unchanged
  },
  ellipsis: {
    // ... other styles remain unchanged
  },
});

const PhotoCover = ({
  imageSrc,
  title,
  photo,
  metaInfo,
  userInfo,
  currentPhoto,
  setCurrentPhoto,
  deleteAlbum,
  draggingId,
  combineTargetFor,
  moveUpDirectory,
  openSnack,
  state,
  deletePhotos,
  albumBreadCrumbs,
  setAlbumBreadCrumbs,
  selectedPhotos,
  setSelectedPhotos,
  lastIndex,
  setLastIndex,
  index,
  selectMultiplePhotos,
  screenWidth,
  handlePhotoClick,
  type


}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;


  async function deletePhoto(photo) {

    deletePhotos([photo])
    // deletePhotoFunction(photo, userInfo, openSnack)
  }



  return (
    <Card

      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        console.log('clicked')
        if (e.shiftKey && lastIndex !== null) {
          const currentPhotoIndex = index;
          console.log(currentPhotoIndex)
          const rangeStart = Math.min(lastIndex, currentPhotoIndex);
          const rangeEnd = Math.max(lastIndex, currentPhotoIndex);

          console.log(rangeStart)
          console.log(rangeEnd)
          selectMultiplePhotos(rangeStart, rangeEnd)
        } else {
          if (selectedPhotos.includes(photo.photo_id)) {
            setSelectedPhotos(selectedPhotos.filter((id) => id !== photo.photo_id))
            setLastIndex(null)
          } else {
            setSelectedPhotos([...selectedPhotos, photo.photo_id])
            setLastIndex(index)
          }
        }
      }}

      classes={{ root: classes.card }}
      style={{

        backgroundColor: selectedPhotos.includes(photo.photo_id) ? '#afbcf9' : '#F2F5FC',
        border: state === 'dragging' ? '6px solid #ed8d8d' : 'none',
      }} >
      <CardHeader
        avatar={
          <FontAwesomeIcon icon={faImage} size={screenWidth === 'xs' ? 'sm' : 'xl'}
            color="red" />
        }
        action={
          <Box sx={{ display: 'flex', alignItems: 'center' }}>

            {type !== 'timeline' && (
              <IconButton style={{ 
                marginLeft: 5
              }} size="medium"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleClick(e)
                }}
                className={classes.ellipsis}>
                <FontAwesomeIcon icon={faEllipsisV} />
              </IconButton>
            )}
          </Box>
        }
        title={<Box sx={{ display: 'flex', alignItems: 'center' }}><Typography variant="body1" color={'black'} fontSize={screenWidth === 'xs' ? 11 : 14}
          fontWeight={600}>
          {title?.length > 20 ? title?.substring(0, 19) + '...' : title}
        </Typography>
        </Box>
        }
      />

      <CardMedia
        classes={{ root: classes.media }}
        component="img"
        image={imageSrc}
        alt={title}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          handlePhotoClick(index); // You need to ensure 'index' is the index of this photo in the images array
        }}
      />
      <CardHeader


        title={<Typography variant="body1" color={'black'} fontSize={screenWidth === 'xs' ? 11 : 14}
          fontWeight={600}>
          {moment(photo.created_date, 'x').format('LLL')}
        </Typography>}
      />



      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={(e) => handleClose(e)}

        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <List sx={{
          width: 300,
          padding: 0,

        }}>

          <ListItem
            key={'rename'}
            dense
            button
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setAnchorEl(null)
              setCurrentPhoto(photo)

            }}
            sx={{
              borderBottom: '1px solid #e0e0e0',
              paddingTop: 1,
              paddingBottom: 1,
            }}
          >
            <FontAwesomeIcon icon={faEdit} size="lg" style={{ color: 'gray', marginRight: 10 }} />
            <Typography variant="body1" color="black" fontSize={16} fontWeight={500}>Rename</Typography>
          </ListItem>
          <ListItem
            key={'moveUpDirectory'}
            dense
            button

            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setAnchorEl(null)
              if (albumBreadCrumbs.length === 0) {
                openSnack('error', 'Cannot move up from root album')
                return
              }
              moveUpDirectory('photo', photo)

            }}
            sx={{
              borderBottom: '1px solid #e0e0e0',
              paddingTop: 1,
              paddingBottom: 1,
            }}
          >
            <FontAwesomeIcon icon={faArrowUp} size="lg" style={{ color: 'gray', marginRight: 10 }} />
            <Typography variant="body1" color={albumBreadCrumbs.length === 0 ? 'gray' : 'black'}
              fontSize={16} fontWeight={500}>Move up directory</Typography>
          </ListItem>
          <ListItem
            key={'deletePhoto'}
            dense
            button
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setAnchorEl(null)
              deletePhoto(photo)
            }}
            sx={{
              borderBottom: '1px solid #e0e0e0',
              paddingTop: 1,
              paddingBottom: 1,
            }}
          >
            <FontAwesomeIcon icon={faTrash} size="lg" style={{ color: 'gray', marginRight: 10 }} />
            <Typography variant="body1" color="black" fontSize={16} fontWeight={500}>Delete</Typography>
          </ListItem>





        </List>




      </Popover>
    </Card>
  );
};

export default PhotoCover;



