import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Box, CircularProgress, IconButton, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import { collection, doc, getDocs, getFirestore, query, setDoc, updateDoc, where } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import moment from 'moment';
import React from 'react';
import Dropzone from 'react-dropzone';
import { fullMobile } from './util';

import uuid from 'react-uuid';
import './tableStyle.css';

export default class FLPhotos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            createdLog: this.props.createdLog,
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            dataList: [],
            preview: this.props.preview,
            loading: false,
            isDragActive: false,
            progressBars: {},
            testKey: this.props.testKey,
            uploadingData: false,
            savePhotos: this.props.savePhotos,
            status: this.props.status,
            log: this.props.log,

        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.status !== prevProps.status) {

            this.setState({ status: this.props.status })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
        if (this.props.log !== prevProps.log) {
            this.setState({ log: this.props.log })
        }
        if (this.props.testKey !== prevProps.testKey) {
            console.log(this.props.testKey)
            this.setState({ testKey: this.props.testKey })
            if (this.props.savePhotos === false) {
                this.setState({ loading: true })
                this.queryData(this.props.testKey)
            }
        }



        if (this.props.savePhotos !== prevProps.savePhotos) {

            this.setState({ savePhotos: this.props.savePhotos })
            if (this.props.savePhotos === true) {
                this.saveData(this.state.dataList, this.props.testKey)
            }
        }

    }
    componentDidMount() {
        const { userInfo, testKey } = this.state;

        if (testKey !== undefined) {
            this.setState({ uploadingData: true, }) 
            this.queryData(testKey)
        }


    }


    queryData = async (testKey) => {
        const { userInfo, } = this.state;

        const db = getFirestore();
        const q = query(collection(db, "clients", userInfo.currentCompany, 'photos'), where('archived', '==', false), where("logkey", "==", testKey));
        const querySnapshot = await getDocs(q);
        const dataList = [];
        querySnapshot.forEach((doc) => {
            dataList.push(doc.data())
        });

        this.setState({ dataList, uploadingData: false })
        console.log(dataList)
        this.saveData(dataList)
    }



    saveData = async (filteredData, testKey) => {

        const { userInfo, tempData, dataList, log } = this.state;
        //set  uploadingData true
        this.setState({ uploadingData: true })


        //if filteredData length === 0 then turn off uploadingData
        if (filteredData.length === 0) {
            this.setState({ uploadingData: false, editRow: '123' })
            this.props.doneSaving()
            this.queryData(testKey)
        }

        filteredData.forEach(async (f, i) => {
            //setdoc each f into firebase flitems

            f.logkey = testKey;
            f.date = log.startDate || moment().format('x');
            const db = getFirestore();
            const docRef = doc(db, "clients", userInfo.currentCompany, 'photos', f.key);
            await setDoc(docRef, f,).then(() => {
                this.props.openSnackbar('success', 'Saved Photo')
            }).catch((error) => {
                console.error("Error adding document: ", error);
                this.setState({ uploadingData: false, editRow: '123' })
                this.props.doneSaving()
                this.props.doneSaving()
            });
            //if filteredData length === i + 1 then turn off uploadingData
            if (filteredData.length === i + 1) {
                this.setState({ uploadingData: false, editRow: '123' })
                this.props.doneSaving()
                this.queryData(testKey)
            }


        })

    }






    handleDragEnter = () => {
        this.setState({ isDragActive: true });
    };

    handleDragExit = () => {
        this.setState({ isDragActive: false });

    };

    deletePhoto = (key) => {
        const { userInfo } = this.state;
        //update photo to archived
        const db = getFirestore();
        const docRef = doc(db, "clients", userInfo.currentCompany, 'photos', key);
        updateDoc(docRef, {
            archived: true,
        }).then(() => {
            console.log("Photo successfully removed!");
        }).catch((error) => {
            console.error("Error removing photo: ", error);
        });
    }





    render() {

        const { isDragActive, progressBars, testKey, dataList, tempData, status, userInfo } = this.state;


        const data = dataList;



        return (
            <Row style={{}} >
                <Col {...fullMobile(24, 24)}   >
                    <Typography variant="h2" style={{ fontSize: 32, paddingBottom: 10 }}>Photos</Typography>
                </Col>
                {status === 'built' && data.length === 0 && (
                    <Col {...fullMobile(24, 24)}   >
                        <Typography variant="h2" style={{ fontSize: 16, paddingBottom: 10 }}>No Photos</Typography>
                    </Col>
                )}
                <Col span={24}>
                    <Row gutter={[16, 16]}>
                        {status === 'creating' && (
                        < Col {...fullMobile(8, 12)} style={{ 
                            backgroundColor: isDragActive ? '#5385AD' : '#404050', color: '#fff', padding: '30px', fontWeight: 700,
                            transition: 'box-shadow 400ms, transform 400ms',
                            boxShadow: isDragActive ? '0 4px 8px 0 rgba(0, 0, 0, 0.2)' : 'inset 0 0 0 1px #d9d9d9',
                            transform: isDragActive ? 'scale(1.02)' : 'scale(1)',
                         }} 
                         
                         className="card-container"
                            onDragOver={() => this.handleDragEnter()}
                            onDragEnter={() => this.handleDragEnter()}
                            onDragLeave={() => this.handleDragExit()}
                            onMouseLeave={() => this.handleDragExit()}
                            onMouseEnter={() => this.handleDragEnter()}
                            onDrop={() => this.handleDragExit()} >
                            <Dropzone multiple={true} onDrop={(acceptedFiles) => {

                                acceptedFiles.map((f, i) => {
                                    const storage = getStorage();
                                    const key = uuid()

                                    const storageRef = ref(storage, `fieldLogsNew${key}`);






                                    const uploadTask = uploadBytesResumable(storageRef, f);
                                    uploadTask.on('state_changed',
                                        (snapshot) => {
                                            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

                                            progressBars[key] = progress;
                                            this.setState({ progressBars })

                                            switch (snapshot.state) {
                                                case 'paused':
                                                    console.log('Upload is paused');
                                                    break;
                                                case 'running':
                                                    console.log('Upload is running');
                                                    break;
                                            }
                                        },
                                        (error) => {
                                            console.log(error)
                                        },
                                        () => {
                                            getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {


                                                const photo = {
                                                    fileCreateDate: moment().format('x'),
                                                    date: moment().format('x'),
                                                    timestamp: Number(moment().format('x')),
                                                    fileCreator: `${userInfo.firstName || ""} ${userInfo.lastname}`,
                                                    fileCreatorKey: userInfo.id,
                                                    fileLink: downloadURL,
                                                    downloadURL,
                                                    url: downloadURL,
                                                    description: '',
                                                    key,
                                                    name: f.name,
                                                    size: f.size,
                                                    type: f.type,
                                                    logkey: testKey,
                                                    archived: false,
                                                    account: userInfo.currentAccount,
                                                    company: userInfo.currentCompany,
                                                    
                                                };




                                                delete progressBars[key];
                                                this.setState({ progressBars })




                                                if (testKey === undefined) {
                                                    dataList.push(photo)
                                                    this.setState({ dataList })

                                                }
                                                else {
                                                    const db = getFirestore()
                                                    const docRef = doc(db, "clients", userInfo.currentCompany, 'photos', photo.key);
                                                    await setDoc(docRef, photo)

                                                    dataList.push(photo)
                                                    this.setState({ dataList })
                                                }





                                            });
                                        }
                                    )














                                })
                            }} >
                                {({ getRootProps, getInputProps }) => (
                                    <section style={{ height: '100%' }}>
                                        <div {...getRootProps()} style={{
                                            borderRadius: 12, alignItems: 'center',
                                            justifyContent: 'center', display: 'flex', cursor: 'pointer', height: '100%',
                                            backgroundColor: '#fff', color: '#f47e42', padding: '30px', fontWeight: 600, fontSize: '18px',
                                            boxShadow: isDragActive ? '0 8px 16px 0 rgba(0, 0, 0, 0.2)' : 'inset 0 0 0 1px #d9d9d9',
                                            transition: 'box-shadow 1s ease-in-out 1s'


                                        }}>


                                            <input {...getInputProps()} />
                                            <Row gutter={[12, 12]}>
                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                    <FontAwesomeIcon fontSize={28} icon={faCloudArrowUp} color="#9ec1c9" />
                                                </Col>
                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                    Upload File</Col>
                                            </Row>


                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                        </Col>)}

                        {
                            Object.values(progressBars).map((p, i) => {
                                return (
                                    <Col {...fullMobile(8, 12)} style={{
                                        borderRadius: 12, borderRadius: 12, alignItems: 'center',
                                        justifyContent: 'center', display: 'flex', cursor: 'pointer', height: '100%',
                                        backgroundColor: '#fff', color: '#f47e42', padding: '30px', fontWeight: 600, fontSize: '18px',
                                        boxShadow: isDragActive ? '0 8px 16px 0 rgba(0, 0, 0, 0.2)' : 'inset 0 0 0 1px #d9d9d9',
                                    }} className="card-container"  >
                                        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                                            <CircularProgress variant="determinate" value={p} />
                                            <Box
                                                sx={{
                                                    top: 0,
                                                    left: 0,
                                                    bottom: 0,
                                                    right: 0,
                                                    position: 'absolute',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <Typography variant="caption" component="div" color="text.secondary">
                                                    {`${Math.round(p)}%`}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Col>
                                )
                            })
                        }


                        {
                            data.sort((a, b) => a.name.localeCompare(b.name)).map((item, index) => {



                                return (
                                    <Col {...fullMobile(8, 12)} style={{
                                        borderRadius: 12, padding: 12,
                                        cursor: 'pointer', height: '100%',
                                    }} >
                                        <Row gutter={[12, 12]}>
                                            {status === 'creating' && (<Col span={24} style={{ textAlign: 'right' }}>
                                                <IconButton onClick={() => {
                                                    data.splice(index, 1)
                                                    this.deletePhoto(item.key)
                                                    this.setState({ data: data })
                                                }} style={{ position: 'relative', zIndex: 1 }} size="small" color="inherit" aria-label="upload picture" component="span">
                                                    <DeleteForeverIcon />
                                                </IconButton>
                                            </Col>)}

                                            <Col span={24} style={{ textAlign: 'center' }}>


                                                <img src={item.url || item.preview} style={{ width: '100%', height: '100%', maxHeight: 250, objectFit: 'cover' }} />



                                            </Col>
                                        </Row>
                                    </Col >

                                )






                            })
                        }
                    </Row>
                </Col>









            </Row >
        );
    }
} 