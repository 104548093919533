import { collection, doc, getDoc, getDocs, getFirestore, onSnapshot, orderBy, query, setDoc, updateDoc, where } from "firebase/firestore";
import moment from 'moment-timezone';
import axios, * as others from 'axios';
import { fetchSensors } from "./firebaseFunctions";


export const getStreams = async (userInfo, listKey) => {
    try {


        const db = getFirestore();
        const queryRef = collection(db, "clients", userInfo.currentCompany, listKey);
        const snapShot = await getDocs(query(queryRef, where("account", "==", userInfo.currentAccount), where('archived', '==', false), orderBy('position')));

        const streams = snapShot.docs.map(doc => {
            return (
                {
                    ...doc.data(),
                    inactive: doc.data().inactive ? doc.data().inactive : false,
                    archived: doc.data().archived ? doc.data().archived : false,
                    selected: false
                }
            )
        });

        return streams;
    } catch (error) {
        console.error("Error querying data: ", error);
    }
};
export const getProbes = async (userInfo, listKey) => {
    try {

        const db = getFirestore();
        const queryRef = collection(db, "clients", userInfo.currentCompany, listKey);
        const snapShot = await getDocs(query(queryRef, where("account", "==", userInfo.currentAccount), where('archived', '==', false), orderBy('position')));
        //hydroID has to be used to account for the id being the hydrovu key, and it is a number not a string
        const streams = snapShot.docs.map(doc => {
            return (
                {
                    ...doc.data(),
                    id: doc.data().key,
                    hydroID: doc.data().id || '',
                    inactive: doc.data().inactive ? doc.data().inactive : false,
                    archived: doc.data().archived ? doc.data().archived : false,
                    selected: false,
                }
            )
        });

        return streams;
    } catch (error) {
        console.error("Error querying data: ", error);
    }
};


export const getStreamPreferences = async (userInfo, prefKey) => {
    try {
        const db = getFirestore();
        const docRef = doc(db, "users", userInfo.id, `${prefKey}Preferences`, userInfo.currentCompany);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            return docSnap.data();
        } else {
            // docSnap.data() will be undefined in this case
            return 'none'
        }
    } catch (error) {
        console.error("Error querying data: ", error);
    }
};
export const getCompanyPreferences = async (userInfo, prefKey) => {
    try {
        const db = getFirestore();
        const docRef = doc(db, "clients", userInfo.currentCompany, `${prefKey}Preferences`, `${prefKey}Preferences`);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            return docSnap.data();
        } else {
            // docSnap.data() will be undefined in this case
            return 'none'
        }
    } catch (error) {
        console.error("Error querying data: ", error);
    }
};

export async function updateStreamPreferences(userInfo, preferences, type, prefKey) {
    try {
        const db = getFirestore();
        let docRef;

        if (type === "company") {
            docRef = doc(db, "clients", userInfo.currentCompany, `${prefKey}Preferences`, `${prefKey}Preferences`);
        } else {
            docRef = doc(db, "users", userInfo.id, `${prefKey}Preferences`, userInfo.currentCompany);
        }

        await setDoc(docRef, preferences);

    } catch (error) {
        console.error("Error updating document: ", error);
    }
};


export const getStreamTemplates = async (userInfo, prefKey) => {
    try {
        const db = getFirestore();
        const queryRef = collection(db, "clients", userInfo.currentCompany, `${prefKey}Templates`);
        const snapShot = await getDocs(query(queryRef, where("company", "==", userInfo.currentCompany), where('archived', '==', false)));

        const templates = snapShot.docs.map(doc => {
            return (
                {
                    ...doc.data(),
                    selected: false
                }
            )
        });

        return templates;
    } catch (error) {
        console.error("Error querying data: ", error);
    }
};

export const getLatestdata = async (userInfo, prefKey, openSnack, setGettingData) => {
    try {


        setTimeout(() => {
            openSnack('success', 'Data updated! Please note that if the probes are not added to a station/node, they will not be updated.')
            setGettingData(false);
        }, 2500);
        const proxyurl = "https://mycorslake.herokuapp.com/";
        const apiUrl = prefKey === 'ost' ? `https://us-central1-aquasource3.cloudfunctions.net/getLatestOSTData` :
            prefKey === 'wunderground' ? `https://us-central1-aquasource3.cloudfunctions.net/getLatestWeatherUnderground` :
                prefKey === 'probe' ? `https://us-central1-aquasource3.cloudfunctions.net/getStations` :
                    null;



        if (!apiUrl) return;
        const response = await axios.post(proxyurl + apiUrl, {
            companies: [
                userInfo.currentCompany
            ]
        });



    } catch (error) {
        console.error('Error calling anotherFunction:', error);
    }
}


export const getLastRecorded = async (userInfo, prefKey, streams, setStreams, openSnack, setGettingData, setLoadingMeta, hydroProbes) => {
    try {

        const sensors = await fetchSensors(userInfo);

        const filteredSensors = sensors.filter(sensor => {
            return streams.some(stream => {
                if (prefKey === 'probe') {
                    return stream.hydroID === sensor?.sensor?.id;
                } else {
                    return String(stream.id).includes(String(sensor?.sensor?.id));
                }
            });
        });




        if (prefKey === 'probe') {

            streams.forEach(stream => {
                const matchedProbe = hydroProbes.find(probe => probe.id === stream.hydroID);

                if (matchedProbe) {
                    stream.lat = matchedProbe.gps?.latitude;
                    stream.lon = matchedProbe.gps?.longitude;
                }
            });

            setStreams([...streams]);
        }

      





        const ostStrings = filteredSensors.map(stream => `'${stream.id}'`).join(', ');
        streams.forEach(stream => {
            if (['ost', 'probe'].includes(prefKey)) {
                const matchedSensor = filteredSensors.find(sensor => String(sensor?.sensor?.id).includes(String(prefKey === 'ost' ? stream.id : stream.hydroID)));

                stream.nodeid = matchedSensor?.id;
            } else {
                stream.nodeid = stream.stationName || stream.name;;
            }
        });



        const wundergroundStrings = streams.map(stream => `'${stream.nodeid}'`).join(', ');
        const locationString = ['ost', 'probe'].includes(prefKey) ? ostStrings : wundergroundStrings;

        const columnIdentifier = prefKey === 'wunderground' ? 'location' : 'nodeid';
        const customBucket = `
            SELECT ${columnIdentifier} as location, MAX(time) as "t"
            FROM ${prefKey === 'wunderground' ? 'wunderground_weather_new2' : 'node_data_new2'}
            WHERE ${columnIdentifier} IN (${locationString})
            GROUP BY ${columnIdentifier};
        `;




        const proxyUrl = "https://mycorslake.herokuapp.com/";
        axios.post(proxyUrl + `https://us-central1-aquasource3.cloudfunctions.net/widgetsFire4/sqlRead`, { raw: customBucket })
            .then(async (response) => {
                const data = response.data;



                streams.forEach(stream => {

                    const lastRecorded = data.find(record => record.location === stream.nodeid);

                    stream.lastRecorded = lastRecorded ? moment(lastRecorded.t, 'YYYY-MM-DD HH:mm').format('x') : 'N/A';
                });

                if (prefKey === 'wunderground') {
                    const apiUrl = `https://us-central1-aquasource3.cloudfunctions.net/getLatLon`;
                    if (!apiUrl) return;
                    const res = await axios.post(proxyUrl + apiUrl);
                    const { elevation, latitude, longitude } = res.data;

                    streams.forEach(stream => {
                        stream.elevation = elevation;
                        stream.latitude = latitude;
                        stream.longitude = longitude;
                    })

                }



                setLoadingMeta(false);
                setStreams([...streams]);
                return streams;

            }).catch(error => {
                console.log(error);
                setLoadingMeta(false);
                return 'error';
            });






    } catch (error) {
        setLoadingMeta(false);
        return 'error';
    }
}









