import React, { useState } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp, faEllipsisH, faPlus, faX } from '@fortawesome/free-solid-svg-icons';
import { IconButton, List, Tooltip, ListItem, OutlinedInput, InputAdornment, ListItemIcon } from '@mui/material';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import uuid from 'react-uuid';
import { useNavigate } from 'react-router-dom';

export default function CreateItem({
    log,
    screenWidth,
    addItem,
    openSnack

}) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        /* check if log?.leadStaff is not null */
        if (!log?.leadStaff) {
            openSnack('error', 'Please select a lead staff member before adding items')
            return
        }

        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    return (
        <>
            <Button
                fullWidth={screenWidth === 'xs' ? true : false}
                size="small"
                style={{
                    textTransform: 'none',
                    fontSize: 14,
                    color: 'rgba(0,0,0,0.9)',
                    fontWeight: 500,
                    padding: 8,
                    backgroundColor: log?.leadStaff ? '#3f51b5' : 'rgba(0,0,0,0.2)',
                    color: log?.leadStaff ? 'white' : 'rgba(0,0,0,0.5)',
                    border: '1px solid rgba(0,0,0,0.2)',
                }}
                onClick={handleClick}
            >
                <FontAwesomeIcon icon={faPlus} style={{ color: log?.leadStaff ? 'white' : 'rgba(0,0,0,0.5)', marginRight: 5 }} />
                Add new
                <CaretDownOutlined style={{
                    marginLeft: 15
                }} />
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}

                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <List sx={{
                    width: 300,
                    padding: 0,

                }}>

                    <ListItem
                        key={'createLog'}
                        dense
                        button
                        onClick={() => {
                            addItem()
                            handleClose()
                        }}
                        sx={{
                            borderBottom: '1px solid #e0e0e0',
                            paddingTop: 2,
                            paddingBottom: 2,
                        }}
                    >
                        <FontAwesomeIcon icon={faPlus} size="lg" style={{ color: 'gray', marginRight: 10 }} />
                        <Typography variant="body1" color="black" fontSize={14} fontWeight={500}>Add new item</Typography>
                    </ListItem>
                    <ListItem
                        key={'createLog'}
                        dense
                        button
                        onClick={() => {
                            console.log('ADD Template')
                            handleClose()
                        }}
                        sx={{
                            borderBottom: '1px solid #e0e0e0',
                            paddingTop: 2,
                            paddingBottom: 2,
                        }}
                    >
                        <FontAwesomeIcon icon={faPlus} size="lg" style={{ color: 'gray', marginRight: 10 }} />
                        <Typography variant="body1" color="black" fontSize={14} fontWeight={500}>Add template item</Typography>
                    </ListItem>





                </List>




            </Popover>



        </>
    );
}