import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, Button, Checkbox, Divider, Fab, IconButton, Input, InputAdornment, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';
import { Col, Modal, Popconfirm, Row } from 'antd';
import { arrayRemove, arrayUnion, collection, deleteDoc, doc, getDoc, getFirestore, onSnapshot, query, setDoc, updateDoc, where } from 'firebase/firestore';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import EmailSignUp from '../../extra/emailSignup';
import "../../extra/hoverStyle.css";
import ReInvite from '../../extra/reInvite';

import axios, * as others from 'axios';
import StaffDirectoryTable from './accountDirectory/staffDirectoryTable';

export default class AccountDirectory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            data: [],
            staff: [],
            companyStaff: [],
            filteredData: [],
            inviteUsers: [],
            filters: [],
            searchText: '',
            loading: true,
            type: this.props.type,
            openModal: false,
            currentItem: {},
            addStatus: 'existing',
            invitation: {},
            staffInfo: {},
            infoModal: false,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0)
        this.queryUsers();
        this.queryCompanyStaff();
        this.getStaff();
    }

    queryUsers = async () => {
        const { userInfo, type } = this.state;
        const db = getFirestore()

        const q = query(collection(db, "users"), where('accounts', 'array-contains', userInfo.currentAccount));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const data = [];
            querySnapshot.forEach((doc) => {
                data.push({
                    id: doc.data().id,
                    key: doc.data().id,

                    invited: doc.data().invited,
                    signedUp: doc.data().signedUp,
                    email: doc.data().email,
                    label: `${doc.data().firstName || ""} ${doc.data().lastName || ""}`,
                })
            });
            this.setState({ data, })
            this.queryStaff(data)

        });



    }
    queryCompanyStaff = async () => {
        const { userInfo, type } = this.state;
        const db = getFirestore()


        const q = query(collection(db, "users"), where('companies', 'array-contains', userInfo.currentCompany));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const companyStaff = [];
            querySnapshot.forEach((doc) => {
                companyStaff.push({
                    id: doc.data().id,
                    key: doc.data().id,
                    accounts: doc.data().accounts,
                    invited: true,
                    signedUp: true,
                    email: doc.data().email,
                    label: `${doc.data().firstName || ""} ${doc.data().lastName || ""}`,
                    flDistribution: false,
                    adminLevel: 'admin'
                })
            });
            this.setState({ companyStaff, })

        });





    }
    queryStaff = async (data) => {
        const { userInfo, type } = this.state;
        const db = getFirestore()


        const q = query(collection(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'staff'),);
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const staff = [];
            querySnapshot.forEach((doc) => {

                const item = data.find(d => d.id === doc.data().id)
                if (item) {
                    staff.push({ ...item, ...doc.data() })
                }

            });

            console.log(staff)
            this.setState({ staff, data: staff })
            this.filterData(data,)

        });

    }

    filterData = (data, searchText, filters) => {
        const filteredData = data.filter(d => d.label.match(new RegExp(searchText, "i")))
        this.setState({ filteredData, loading: false })

    }

    updateStaff = async (key, boo, val) => {
        const { userInfo, type, staff } = this.state;

        const db = getFirestore()
        const queryStaff = doc(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'staff', key);
        await updateDoc(queryStaff, {
            [boo]: val
        }).then(() => {
            this.props.openSnack('success', 'Staff Updated')

            const index = staff.map((s) => s.key).indexOf(key);
            staff[index][boo] = val;
            this.setState({ staff })

        })
    }
    updateIU = async (key, boo, val) => {
        const { inviteUsers } = this.state;

        const index = inviteUsers.map((s) => s.key).indexOf(key);
        inviteUsers[index][boo] = val;
        this.setState({ inviteUsers })
    }
    newInvite = async (boo, val) => {
        const { invitation } = this.state;

        invitation[boo] = val;
        this.setState({ invitation })
    }

    getStaff = async () => {
        const { userInfo } = this.state;
        const db = getFirestore()


        const docRef = doc(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'staff', userInfo.id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {

            this.setState({ staffInfo: docSnap.data(), loading: false })
        }

    }

    sendEmail = async () => {

        const proxyUrl = "https://mycorslake.herokuapp.com/";
        axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sendEmail', {
            recipient: 'gregory.m.smith15@gmail.com',
            subject: 'You have been invited',

        })
            .then(async (response) => {
                console.log(response)
            })




    }

    updateUserStaff = async (key, boo, val) => {
        const { userInfo, type, staff } = this.state;
        const db = getFirestore()
        const queryStaff = doc(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'staff', key);
        await updateDoc(queryStaff, {
            [boo]: val
        }).then(() => {
            this.props.openSnack('success', 'Staff Updated')
        })
    }


    render() {

        const { userInfo, account, data, filteredData, loading, searchText, filters, type, staff, openModal, screenWidth, invitation,
            currentItem, addStatus, inviteUsers, companyStaff, staffInfo, infoModal, selectedUser } = this.state;
        const db = getFirestore();
        const CreateItemButton = () => {
            let navigate = useNavigate();

            return (
                <Fab style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="medium"
                    onClick={async () => {
                        this.setState({ openModal: true, currentItem: {} })
                    }}>
                    <AddIcon />
                </Fab>
            )
        }
        const EditItem = ({ item }) => {
            let navigate = useNavigate();
            return (
                <IconButton style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="small"
                    onClick={async () => {
                        const itemRef = doc(db, "users", userInfo.id, 'currentItems', 'currentStaff');
                        await setDoc(itemRef, {
                            key: item.key
                        }).then(() => {
                            navigate('/accountStaff')
                        })
                    }}>
                    <EditIcon />
                </IconButton>
            )
        }



        const InvitePeople = () => {
            return (
                <Button size="small" style={{ padding: 10, borderRadius: 4, textTransform: 'none', fontSize: 14, backgroundColor: '#404050' }}
                    variant='contained' color="primary"
                    onClick={async () => {


                        const staffIds = staff.map((d) => d.key);
                        const currentIds = filteredData.map((d) => d.key);

                        inviteUsers.map(async (u, i) => {
                            const userRef = doc(db, "users", u.id,);

                            if (!currentIds.includes(u.id)) {
                                filteredData.push(u);
                                staff.push(u);
                                this.setState({ filteredData, staff })
                            }


                            if (!u.accounts.includes(userInfo.currentAccount)) {
                                await updateDoc(userRef, {
                                    accounts: arrayUnion(userInfo.currentAccount)

                                })
                            }
                            if (!staffIds.includes(u.id)) {
                                delete u.accounts;

                                const docRef = doc(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'staff', u.id);
                                await setDoc(docRef, u)
                            }

                            if (i + 1 === inviteUsers.length) {
                                this.setState({ openModal: false, inviteUsers: [] })
                            }


                        })






                    }
                    }> Add Users</Button >
            )
        }

        const DeleteItem = ({ item }) => {
            let navigate = useNavigate();
            return (
                <Popconfirm
                    title="Are you sure to delete this staff member?"
                    onConfirm={async () => {
                        await deleteDoc(doc(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'staff', item.id)).then(async () => {


                            const userRef = doc(db, "users", item.id,);

                            await updateDoc(userRef, {
                                accounts: arrayRemove(userInfo.currentAccount)
                            })


                            this.props.openSnack('success', 'Staff removed.')
                            const newData = filteredData.filter((f) => f.key !== item.key)
                            this.setState({ filteredData: newData })
                        })


                    }}
                    okText="Yes"
                    cancelText="No"
                >
                    <IconButton style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="small"
                    >
                        <DeleteForeverIcon />
                    </IconButton>
                </Popconfirm>


            )
        }

        const RowItem = ({ item }) => {
            let navigate = useNavigate();
            const index = staff.map((s) => s.key).indexOf(item.id);
            const member = staff[index];



            return (
                <Col span={24} style={{ cursor: 'pointer', padding: 8 }} className="rowHover"
                >
                    <Row gutter={[8, 8]} align="middle" >

                        <Col xs={24} sm={20} onClick={async () => {
                            /* const itemRef = doc(db, "users", userInfo.id, 'currentItems', 'currentStaff');
                            await setDoc(itemRef, {
                                key: item.key
                            }).then(() => {
                                navigate('/accountStaff')
                            }).catch((err) => {
                                console.log(err)
                            }) */
                        }}>

                            <Row align='middle' gutter={[8, 8]}>
                                <Col xs={12} sm={16} >
                                    <Row>
                                        <Col span={24}>
                                            <Typography variant='h6'>{item.label}</Typography>
                                        </Col>
                                        <Col span={24}>
                                            <Typography fontWeight={300} variant='subtitle'>{item.email}</Typography>
                                        </Col>
                                    </Row>

                                </Col>
                                <Col xs={6} sm={4} >
                                    {staff.length > 0 && data.length > 0 && (<Select size="small" fullWidth
                                        notched={false}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={member !== undefined ? member.adminLevel : ''}
                                        label="Layer"
                                        onChange={(e) => {
                                            this.updateStaff(member.key, 'adminLevel', e.target.value)
                                        }}

                                    >
                                        <MenuItem value={'admin'}>Admin</MenuItem>
                                        <MenuItem value={'customer'}>Customer</MenuItem>
                                        <MenuItem value={'client'}>Client</MenuItem>
                                        <MenuItem value={'contractor'}>Contractor</MenuItem>

                                    </Select>)}
                                </Col>
                                <Col xs={4} sm={4} style={{ textAlign: 'right' }} >
                                    {item.signedUp === false && (
                                        <ReInvite account={account} userInfo={userInfo} user={item} invitation={item} openSnack={this.props.openSnack} />
                                    )}
                                    {item.signedUp === true && (
                                        <Button fullWidth variant="contained" color="primary" size="small" display="inline" style={{ backgroundColor: '#404050', textTransform: 'none' }}
                                            onClick={() => {
                                                console.log(member)
                                                this.setState({ infoModal: true, selectedUser: { ...member } })
                                            }}>
                                            Info
                                        </Button>
                                    )}
                                </Col>
                            </Row>

                        </Col>
                        <Col xs={2} sm={4} style={{ textAlign: 'right' }}>
                            {item.id !== userInfo.id && staffInfo.adminLevel === 'admin' && (<DeleteItem item={item} />)}
                        </Col>


                        <Col span={24}>
                            <Divider />
                        </Col>

                    </Row>
                </Col>
            )
        }

        return (
            <Row style={{}} >

                <Col span={23}>
                    <Row align="middle">
                        <Col xs={16} sm={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>

                        </Col>
                        <Col xs={6} sm={4} style={{ paddingTop: 30, paddingBottom: 15, textAlign: 'right' }}>
                            <CreateItemButton />
                        </Col>

                    </Row>
                </Col>





                <Col span={23} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 30 }}>

                    <Row>
                        <Col xs={24} sm={12} style={{ paddingBottom: 8 }}>
                            <Input fullWidth
                                autoComplete='off'
                                value={searchText}
                                onChange={(e) => {
                                    this.setState({ searchText: e.target.value })
                                    if (e.target.value.length > 1) {
                                        this.filterData(data, e.target.value, filters)
                                    }
                                    else {
                                        this.filterData(data, '', filters)
                                    }
                                }}
                                id="input-with-icon-adornment"
                                startAdornment={
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                }
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            edge="end"
                                            onClick={() => {
                                                this.setState({ searchText: '' })
                                                this.filterData(data, '', filters)
                                            }}
                                        >
                                            <HighlightOffIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </Col>
                        <Col xs={0} sm={12} style={{ textAlign: 'right' }}>
                            <Typography variant='h6'>Actions</Typography>
                        </Col>

                        <Col span={24}>

                            <Row align='middle' >
                                {filteredData.map((d) => {
                                    return (
                                        <RowItem item={d} />

                                    )

                                })}
                            </Row>
                        </Col>
                    </Row>

                </Col>

                <Modal title="" visible={openModal} onCancel={() => this.setState({ openModal: false })} footer={null} width={screenWidth === 'xs' ? '80%' : '50%'} style={{ borderRadius: 40 }} >
                    <Row align="middle" gutter={[16, 16]} >
                        <Col xs={{ offset: 0, span: 24 }} sm={{ offset: 6, span: 18 }} style={{ paddingBottom: 12, }}>
                            <Typography variant='h5'>Add/Invite Users</Typography>
                        </Col>
                        <Col xs={{ offset: 0, span: 24 }} sm={{ offset: 6, span: 18 }} style={{ paddingBottom: 12, textAlign: 'right' }}>
                            <Button variant='outlined' color="primary"
                                style={{
                                    textTransform: 'none',
                                    border: '1px solid #404050',
                                    color: '#404050'
                                }} onClick={() => {
                                    this.setState({ addStatus: addStatus === 'existing' ? 'new' : 'existing' })
                                }}>{addStatus === 'existing' ? 'Invite' : 'Add'}</Button>
                        </Col>

                        {addStatus === 'existing' && (
                            <Col span={24}>
                                <Row align="middle" gutter={[16, 16]} >
                                    <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                                        <Typography variant="body1">Users</Typography>
                                    </Col>
                                    <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                        <Autocomplete
                                            size="small"
                                            disableCloseOnSelect
                                            multiple={true}
                                            id="combo-box-demo"
                                            value={inviteUsers || []}

                                            options={companyStaff}
                                            filterSelectedOptions
                                            renderInput={(params) => <TextField {...params} label="Staff" />}
                                            onChange={(a, b) => {

                                                this.setState({ inviteUsers: b })
                                            }}

                                        />
                                    </Col>
                                    <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>

                                    </Col>
                                    <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>

                                        <Row align="middle" gutter={[8, 8]}>
                                            <Col span={10}>
                                                Name
                                            </Col>

                                            <Col span={8}>
                                                Role
                                            </Col>
                                            <Col span={6}>
                                                FL Distribution
                                            </Col>
                                            <Col span={24}>
                                                <Divider />
                                            </Col>

                                        </Row>
                                    </Col>
                                    <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>

                                    </Col>
                                    <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>

                                        {inviteUsers.map((i) => {
                                            return (
                                                <Row align="middle" gutter={[8, 8]}>
                                                    <Col span={10}>
                                                        {i.label}
                                                    </Col>

                                                    <Col span={8}>
                                                        <Select size="small" fullWidth
                                                            notched={false}
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={i.adminLevel}
                                                            label="Layer"
                                                            onChange={(e) => {
                                                                this.updateIU(i.key, 'adminLevel', e.target.value)
                                                            }}

                                                        >
                                                            <MenuItem value={'admin'}>Admin</MenuItem>
                                                            <MenuItem value={'client'}>Client</MenuItem>

                                                        </Select>
                                                    </Col>
                                                    <Col span={4}>
                                                        <Checkbox checked={i.flDistribution}
                                                            onChange={(e) => {
                                                                this.updateIU(i.key, `flDistribution`, e.target.checked)
                                                            }} />
                                                    </Col>

                                                </Row>
                                            )
                                        })}
                                    </Col>
                                    <Col xs={{ offset: 0, span: 24 }} sm={{ offset: 6, span: 18 }} style={{ paddingBottom: 12, }}>
                                        <InvitePeople />
                                    </Col>

                                </Row>
                            </Col>

                        )}

                        {addStatus === 'new' && (
                            <Col span={24}>
                                <Row align="middle" gutter={[16, 16]} >






                                    <Col sm={24} style={{ paddingBottom: 12, }}>
                                        <EmailSignUp screenWidth={screenWidth} userInfo={userInfo} account={account} openSnack={this.props.openSnack} />
                                    </Col>



                                </Row>
                            </Col>

                        )}










                    </Row>
                </Modal>
                <Modal title="" visible={infoModal}
                    onCancel={() => this.setState({ infoModal: false, selectedUser: null })}
                    footer={null} width={screenWidth === 'xs' ? '80%' : '50%'} style={{ borderRadius: 40 }} >
                    <Row align="middle" gutter={[16, 16]} >
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} style={{ paddingBottom: 12, }}>
                            <Typography variant='h5'>User Info</Typography>
                        </Col>
                        {selectedUser !== null && (<Col xs={{ span: 24 }} sm={{ span: 24 }} style={{ paddingBottom: 12, }}>
                            <Typography display="inline" variant='body1'>Field Log Distribution</Typography>
                            <Checkbox display="inline" checked={selectedUser?.flDistribution}
                                onChange={(e) => {
                                    this.updateUserStaff(selectedUser.id, `flDistribution`, e.target.checked)
                                    this.setState({ selectedUser: { ...selectedUser, flDistribution: e.target.checked } })
                                    const staffMember = staff.find(i => i.id === selectedUser.id)
                                    staffMember.flDistribution = e.target.checked

                                }} />
                        </Col>)}






                    </Row>
                </Modal>




            </Row >
        );
    }
} 