import React from 'react';
import { Col, Row, Grid } from 'antd';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import SignedInRouting from './signedInRouting';
import Routing from './routing';
import { CircularProgress, createTheme, ThemeProvider } from '@mui/material';

const { useBreakpoint } = Grid;


const THEME = createTheme({
    typography: {
        "fontFamily": `"Inter", "Roboto", "Helvetica", "Arial",  sans-serif`,
        "fontSize": 14,
        "fontWeightLight": 300,
        "fontWeightRegular": 400,
        "fontWeightMedium": 500
    }
});

export default class LakeTech extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            auth: false,
            uid: null,
            screenWidth: null
        };
    }


    componentDidMount() {
        const auth = getAuth();

        onAuthStateChanged(auth, (user) => {
            this.setState({ auth: user ? true : false, uid: user ? user.uid : null, loading: false })
        });
        this.setState({ loading: false })


    }

    authChange = (status) => {
        const auth = getAuth();
        if (status === 'signedIn') {
            onAuthStateChanged(auth, (user) => {
                this.setState({ auth: user ? true : false, uid: user ? user.uid : null, loading: false })
            });
        }
        else {
            this.setState({ auth: false, uid: null, loading: false })
        }

    }


    setScreen = (s) => {
        const { screenWidth } = this.state;
        if (s !== screenWidth) {
            this.setState({ screenWidth: s })
        }
    }



    render() {
        const { auth, uid, loading, screenWidth } = this.state;

        const ScreenSize = () => {
            const screens = useBreakpoint();
            const list = Object.entries(screens)
                .filter(screen => !!screen[1]);
            const listLength = list.length;
            const size = list[listLength - 1];
            if (size !== undefined) {
                this.setScreen(size[0])
            }

            return null;
        }


        return (
            <ThemeProvider theme={THEME}>
                <Row style={{ minHeight: '100vh' }}>
                    <Col span={24}>
                        {loading ? <CircularProgress /> : auth === true ?
                            <SignedInRouting authChange={this.authChange} screenWidth={screenWidth} uid={uid} /> : <Routing authChange={this.authChange} screenWidth={screenWidth} />}
                    </Col>
                    <ScreenSize />
                </Row>
            </ThemeProvider>
        );



    }
} 