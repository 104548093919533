import { Button, ButtonGroup, CircularProgress, Divider, Typography, OutlinedInput, Autocomplete, TextField, Select, MenuItem } from '@mui/material';
import { Col, Modal, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { fullMobile } from './util';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, onSnapshot } from 'firebase/firestore';

import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import { getAccounts, queryAccounts, queryCodes } from '../firebase/config';
import ItemsTable from './itemsTable';



var randomColor = require('randomcolor');

export default function AccountListHome(props) {
    const { userInfo, screenWidth, openSnack, company, account, type } = props;
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);
    const [loadingItems, setLoadingItems] = useState(false);
    const [item, setItem] = useState({})
    const [currentButton, setCurrentButton] = useState('sites')
    const [addItemModal, setAdditemModal] = useState(false)
    const [accounts, setAccounts] = useState([])



    const db = getFirestore()


    useEffect(() => {
        window.scrollTo(0, 0);
        getInfo()
    }, [])

    async function getInfo() {

        const q = query(collection(db, "clients", userInfo.currentCompany, 'accounts'), where('archived', '==', false));
        const qPublic = query(collection(db, "clients", userInfo.currentCompany, 'accounts'), where('archived', '==', false), where('publicAccount', '==', true));

        const unsubscribe = onSnapshot(type === 'publice' ? qPublic : q, (snap) => {
            const data = [];
            snap.forEach((doc) => {

                if (type === 'public') {
                    data.push({
                        accountName: doc.data().accountName,
                        key: doc.data().key,
                        type: doc.data().seasonal || null,
                        city: doc.data().city || null,
                        countyName: doc.data().countyName || null,
                        state: doc.data().state || null,
                        routeDay: doc.data().routeDay || null,
                        routeName: doc.data().routeName || null,
                        visible: true
                    })
                }
                else {
                    if (["EuEZ93ByFZYs8bSUXoYsTt1zu0m2", 'eTevYsI9mQMxhtntd95HmRGasNx2', 'PQWFmTLCP9XqafR9ogNI3Fg1sH43', 'z4y8XVrxYGT7rO4R01Y8tQ2F5z83', 't9bOfGDqwDaQTYV6i008opscpjz2' ].includes(userInfo.id)) {
                        data.push({
                            accountName: doc.data().accountName,
                            key: doc.data().key,
                            type: doc.data().seasonal || null,
                            city: doc.data().city || null,
                            countyName: doc.data().countyName || null,
                            state: doc.data().state || null,
                            routeDay: doc.data().routeDay || null,
                            routeName: doc.data().routeName || null,
                            visible: true
                        })
                    }
                    else {
                        if (userInfo.accounts.includes(doc.data().key)) {
                            data.push({
                                accountName: doc.data().accountName,
                                key: doc.data().key,
                                type: doc.data().seasonal || null,
                                city: doc.data().city || null,
                                countyName: doc.data().countyName || null,
                                state: doc.data().state || null,
                                routeDay: doc.data().routeDay || null,
                                routeName: doc.data().routeName || null,
                                visible: true
                            })
                        }
                    }
                }

            });

            data.sort((a, b) => a.accountName.localeCompare(b.accountName))

            setItems(data)
            setLoadingItems(false)

        });



    }




    let navigate = useNavigate();






    return (
        <div style={{ fontFamily: 'Roboto, sans-serif', backgroundColor: '#f6f8fe', minHeight: '100vh' }}>
            <Row  >
                <Col span={23} style={{
                    paddingTop: 30,
                    paddingLeft: 30,
                    overflow: 'auto',


                }}>
                    <Row align="middle" >
                        <Col {...fullMobile(20, 24)}>
                            <Typography variant='h3' fontWeight={500} fontSize={36}>Sites</Typography>
                            <Typography style={{ paddingTop: 5 }} variant='h5' fontWeight={300} fontSize={18}>Your sites for this account.</Typography>

                        </Col>
                        <Col {...fullMobile(4, 24)}>
                            <Button variant={'contained'} display="inline"
                                fullWidth
                                style={{
                                    padding: 4, paddingTop: 8, paddingBottom: 8,
                                    textTransform: 'none', backgroundColor: '#404050', fontSize: 13,
                                }}
                                onClick={async () => {
                                    const userRef = doc(db, "users", userInfo.id,);
                                    await updateDoc(userRef, {
                                        currentAccount: 'none'
                                    }).then(() => {
                                        navigate("/createSite");
                                    })

                                }}
                            >
                                <AddIcon fontSize='small' />  Add Site
                            </Button>

                        </Col>

                        <Col {...fullMobile(24, 24)} style={{ paddingTop: 10, paddingBottom: 10 }}>
                            <Divider />
                        </Col>
                        <Col {...fullMobile(24, 24)} style={{ paddingTop: 10, paddingBottom: 10 }}>
                            <ButtonGroup variant="link" aria-label="contained primary button group">
                                {[{ name: 'sites', label: 'Sites' }].map((item, index) => {
                                    return (
                                        <Button style={{
                                            color: '#404050', textTransform: 'none', fontSize: 14, borderRadius: 0,
                                            //add orange bottom border if current button is users
                                            borderBottom: currentButton === item.name ? '2px solid #f57c00' : 'none'
                                        }}
                                            onClick={() => {
                                                setCurrentButton(item.name)
                                                console.log(item.name)
                                            }}
                                        >
                                            {item.label}
                                        </Button>
                                    )
                                })}

                            </ButtonGroup>

                        </Col>
                        <Col {...fullMobile(24, 24)} >
                            <Divider />
                        </Col>
                        {loadingItems === false ?
                            <Col {...fullMobile(24, 24)} style={{ padding: 8 }}>
                                {currentButton === 'sites' && (
                                    <ItemsTable {...props} combinedRef={items} deleteItem={(key) => {
                                        const newItems = items.filter((i) => i.key !== key)
                                        console.log('delete')
                                        setItems([...newItems])
                                    }} />
                                )}



                            </Col> :
                            <CircularProgress />}
                    </Row>

                </Col>

















            </Row >
        </div >
    );
}