import { Autocomplete, Button, Checkbox, Collapse, Divider, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material';
import { Row, Col } from 'antd';
import React, { useEffect, useState } from 'react';
import { ReactSortable } from 'react-sortablejs';
import uuid from 'react-uuid';
import { fullMobile } from '../../util';
import AddIcon from '@mui/icons-material/Add';
import { DeleteForever, DragIndicator } from '@mui/icons-material';
import { faVial, faLocationCrosshairs, faFillDrip, faChartArea, faChartBar, faChartLine, faChartColumn, faGear } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataColorCompact from '../settings/dataColorCompact';
import DatePicker from "react-datepicker";
import moment from 'moment-timezone';

var randomColor = require('randomcolor');

export default function SingleData(props) {
    const { userInfo, screenWidth, openSnack, widgetRef, updateCurrentWidget, nodes, PPArray, parameters, WPArray } = props;
    const [widget, setWidget] = useState(widgetRef);





    useEffect(() => {

    }, [])
    useEffect(() => {
        setWidget(widgetRef)
    }, [widgetRef])


    const item = widget.single;

    async function updateSingleData(boo, val) {

        if (boo) {
            const newSingle = { ...item };
            newSingle[boo] = val;
            updateCurrentWidget({ ...widget, single: newSingle });
        }

    }



    return (
        <Row
        >

            <Col {...fullMobile(24, 24)}>

                <Row key={item.key} align="middle" gutter={[4, 4]} style={{
                    padding: 10, paddingBottom: 0,
                    //if index is even then color is '#4040502e' else color is 'fff'

                }}>

                    <Col {...fullMobile(24, 24)}>
                        {widget.widgetType === 'reading' && (<Row align="middle" gutter={[12, 12]} allign="middle">

                            <Col >
                                <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                    Show Chart
                                </Typography>
                                <Checkbox checked={item?.showChart || false}
                                    onChange={(e) => {
                                        updateSingleData('showChart', e.target.checked)
                                    }}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />

                            </Col>

                            {!item.endToday && item.showChart && (<Col style={{}}>
                                <Typography variant='body1' fontWeight={600} fontSize={13}>End Date</Typography>
                                <DatePicker timeCaption="Time" showTimeSelect timeFormat="h:mm a" timeIntervals={1}
                                    dateFormat="MM/dd/yyyy hh:mm a" portalId="root-portal" selected={new Date(moment(item?.endDate || new Date(), 'x'))} onChange={(date) => {
                                        updateSingleData('endDate', moment(date).format('x'))


                                    }} />
                            </Col>)}
                            {item.showChart && (<Col style={{}}>
                                <Typography variant='body1' fontWeight={600} fontSize={13}>End Today</Typography>
                                <Checkbox
                                    checked={item?.endToday}
                                    onChange={(e) => {
                                        updateSingleData('endToday', e.target.checked)
                                    }}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                            </Col>)}


                            {item.showChart && (<Col span={24} style={{}}>

                                <Row gutter={[12, 12]} align="middle">
                                    <Col>
                                        <Button variant={item?.timeframe === 'week' ? 'contained' : 'outlined'} color="primary" size="small"
                                            style={{
                                                backgroundColor: item?.timeframe === 'week' ? '#404050' : 'white',
                                                textTransform: 'none', border: '1px solid #404050',
                                                color: item?.timeframe === 'week' ? 'white' : '#404050'
                                            }}
                                            onClick={() => {
                                                updateSingleData('timeframe', 'week')
                                            }}
                                        >Last Week</Button>
                                    </Col>
                                    <Col>
                                        <Button variant={item?.timeframe === 'month' ? 'contained' : 'outlined'} color="primary" size="small"
                                            style={{
                                                textTransform: 'none', border: '1px solid #404050',
                                                color: item?.timeframe === 'month' ? 'white' : '#404050',
                                                backgroundColor: item?.timeframe === 'month' ? '#404050' : 'white'
                                            }}
                                            onClick={() => {
                                                updateSingleData('timeframe', 'month')
                                            }}
                                        >Last Month</Button>
                                    </Col>
                                    <Col>
                                        <Button variant={item?.timeframe === 'quarter' ? 'contained' : 'outlined'} color="primary" size="small"
                                            style={{
                                                backgroundColor: item?.timeframe === 'quarter' ? '#404050' : 'white',
                                                textTransform: 'none', border: '1px solid #404050',
                                                color: item?.timeframe === 'quarter' ? 'white' : '#404050'
                                            }}
                                            onClick={() => {
                                                updateSingleData('timeframe', 'quarter')
                                            }}
                                        >3 Months</Button>
                                    </Col>
                                    <Col>
                                        <Button variant={item?.timeframe === '6months' ? 'contained' : 'outlined'} color="primary" size="small"
                                            style={{
                                                backgroundColor: item?.timeframe === '6months' ? '#404050' : 'white',
                                                textTransform: 'none', border: '1px solid #404050',
                                                color: item?.timeframe === '6months' ? 'white' : '#404050'
                                            }}
                                            onClick={() => {
                                                updateSingleData('timeframe', '6months')
                                            }}
                                        >6 Months</Button>
                                    </Col>
                                    <Col>
                                        <Button variant={item?.timeframe === 'year' ? 'contained' : 'outlined'} color="primary" size="small"
                                            style={{
                                                backgroundColor: item?.timeframe === 'year' ? '#404050' : 'white',
                                                textTransform: 'none',
                                                border: '1px solid #404050',
                                                color: item?.timeframe === 'year' ? 'white' : '#404050'
                                            }}
                                            onClick={() => {
                                                updateSingleData('timeframe', 'year')
                                            }}
                                        >1 Year</Button>
                                    </Col>
                                </Row>
                            </Col>)}
                            <Col {...fullMobile(24, 24)}>
                                <Divider />
                            </Col>
                        </Row>)}

                        <Row gutter={[8, 8]} style={{ paddingTop: 8 }} >
                            <Col {...fullMobile(18, 18)}>
                                <Row gutter={[8, 8]} >
                                    <Col  >
                                        <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                            Data Type
                                        </Typography>
                                        <Select size="small"
                                            style={{ width: 150 }}
                                            notched={false}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={item.dataType}
                                            label="Layer"
                                            onChange={(e) => {
                                                updateSingleData('dataType', e.target.value)
                                            }}

                                        >
                                         
                                            <MenuItem value={'node'}>Node/Station</MenuItem>
                                            <MenuItem value={'weather'}>Weather</MenuItem>
                                            <MenuItem value={'wunder'}>Weather Underground</MenuItem>
                                            <MenuItem value={'station'}>Tide Station</MenuItem>
                                            <MenuItem value={'gauge'}>Rain Gauge</MenuItem>

                                        </Select>
                                    </Col>
                                    <Col  >
                                        <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                            Source
                                        </Typography>
                                        <Autocomplete
                                            style={{ width: 200 }}
                                            disableClearable
                                            size="small"
                                            disablePortal
                                            id="combo-box-demo"
                                            options={item.dataType === '' ? [] : nodes}
                                            hiddenLabel="true"
                                            value={nodes.find((a) => a.id === item?.nodeid)?.label}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder="Source"
                                                    variant="outlined"
                                                />
                                            )}
                                            noOptionsText="Select Data Type First"
                                            onChange={(a, b) => {
                                                updateSingleData('nodeid', b.id)

                                            }}
                                            InputProps={{ style: { fontSize: 6 } }}


                                        />

                                    </Col>
                                    <Col  >
                                        <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                            Parameter
                                        </Typography>
                                        <Autocomplete
                                            style={{ width: 200 }}
                                            disableClearable
                                            size="small"
                                            disablePortal
                                            id="combo-box-demo"
                                            noOptionsText="Select Source First"
                                            options={
                                                item.dataType === '' ? [] :
                                                    item.dataType === 'station' ? [{ value: 'height', label: 'Height', key: 'height' }] :
                                                        item.dataType === 'wunder' ? [{ value: 'precipitation', label: 'Precipitation', key: 'precipitation' }] :
                                                            item.dataType === 'weather' ? WPArray.sort((a, b) => a.label.localeCompare(b.label)) :
                                                                [...PPArray, ...parameters].sort((a, b) => a.label.localeCompare(b.label))
                                            }
                                            value={
                                                item.dataType === 'wunder' ? [{ value: 'precipitation', label: 'Precipitation', key: 'precipitation' }].find((a) => a.key === item.parameterid)?.label :
                                                    item.dataType === 'station' ? [{ value: 'height', label: 'Height', key: 'height' }].find((a) => a.key === item.parameterid)?.label :
                                                        item.dataType === 'weather' ? WPArray.sort((a, b) => a.label.localeCompare(b.label)).find((a) => a.key === item.parameterid)?.label :
                                                            [...PPArray, ...parameters].sort((a, b) => a.label.localeCompare(b.label)).find((a) => a.key === item.parameterid)?.label || null


                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder="Parameter"
                                                    variant="outlined"
                                                />
                                            )}
                                            onChange={(a, b) => {
                                                updateSingleData('parameterid', b.key)

                                            }}

                                        />

                                    </Col>



                                    {widget.widgetType === 'reading' && (<Col  >
                                        <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                            Color
                                        </Typography>
                                        <DataColorCompact color={item.color} updateData={(a, color) => {
                                            updateSingleData('color', color)
                                        }} />
                                    </Col>)}
                                    {widget.widgetType === 'reading' && item.showChart && (
                                        <Col  >
                                            <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                                Type
                                            </Typography>
                                            <Select size="small" fullWidth

                                                notched={false}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={item.type}
                                                label="Layer"
                                                onChange={(e) => {
                                                    updateSingleData('type', e.target.value)
                                                }}

                                            >
                                                <MenuItem value={'spline'}><FontAwesomeIcon fontSize={14} icon={faChartLine} color="rgba(0,0,0,0.8)" /></MenuItem>
                                                <MenuItem value={'column'}><FontAwesomeIcon fontSize={14} icon={faChartColumn} color="rgba(0,0,0,0.8)" /></MenuItem>
                                                <MenuItem value={'area'}><FontAwesomeIcon fontSize={14} icon={faChartArea} color="rgba(0,0,0,0.8)" /></MenuItem>

                                            </Select>
                                        </Col>
                                    )}


                                </Row>

                            </Col>




                            <Col {...fullMobile(6, 6)} style={{ textAlign: 'right' }}>
                                <Button variant={item.advancedSettings ? 'contained' : 'outlined'} display="inline"
                                    size="small"
                                    style={{
                                        textTransform: 'none', backgroundColor: item.advancedSettings ? 'inherit' : '#404050',
                                        color: item.advancedSettings ? '#404050' : 'white', border: '1px solid #404050',

                                        fontSize: 12,
                                        fontWeight: 300
                                    }}
                                    onClick={() => {
                                        //update this series' advanced settings variable    
                                        updateSingleData('advancedSettings', !item.advancedSettings)

                                    }}
                                >
                                    {item.advancedSettings ? 'Show Less' : 'Show More'}
                                </Button>

                            </Col>
                            <Col {...fullMobile(24, 24)}>
                                <Collapse in={item.advancedSettings || false} timeout="auto" unmountOnExit>
                                    <Row style={{ marginTop: 10 }} gutter={[12, 12]}>
                                        <Col >
                                            <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                                Label
                                            </Typography>
                                            <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                style={{ width: 200 }}
                                                defaultValue={item.label || ''}
                                                onBlur={(e) => {
                                                    updateSingleData('label', e.target.value)
                                                }} />

                                        </Col>
                                        <Col >
                                            <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                                Units Label
                                            </Typography>
                                            <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                style={{ width: 100 }}
                                                defaultValue={item.unitsLabel || ''}
                                                onBlur={(e) => {
                                                    updateSingleData('unitsLabel', e.target.value)
                                                }} />

                                        </Col>
                                        {widget.widgetType === 'reading' && (<Col >
                                            <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                                Dash
                                            </Typography>
                                            <Select size="small" fullWidth
                                                notched={false}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={item?.dashed || 'Solid'}
                                                label="Dash"
                                                onChange={(e) => {
                                                    updateSingleData('dashed', e.target.value)
                                                }}

                                            >
                                                <MenuItem value={'Solid'}>Solid</MenuItem>
                                                <MenuItem value={'ShortDash'}>Short Dash</MenuItem>
                                                <MenuItem value={'Dash'}>Dash</MenuItem>
                                                <MenuItem value={'LongDash'}>Long Dash</MenuItem>
                                                <MenuItem value={'Dot'}>Dot</MenuItem>
                                            </Select>
                                        </Col>)}
                                        <Col >
                                            <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                                Units
                                            </Typography>
                                            <Select size="small" fullWidth
                                                notched={false}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={item?.units || ''}
                                                label="Units"
                                                placeholder="Units"
                                                onChange={(e) => {
                                                    updateSingleData('units', e.target.value)
                                                }}

                                            >

                                                {item.parameterid === '1' && (
                                                    ['C', 'F'].map((f) => {
                                                        return (
                                                            <MenuItem value={f}>{f}</MenuItem>
                                                        )
                                                    })
                                                )}
                                                {item.parameterid === '3' && (
                                                    ['m', 'ft'].map((f) => {
                                                        return (
                                                            <MenuItem value={f}>{f}</MenuItem>
                                                        )
                                                    })
                                                )}



                                            </Select>
                                        </Col>
                                        {widget.widgetType === 'reading' && (<Col >
                                            <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                                Line Width
                                            </Typography>
                                            <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                defaultValue={item?.lineWidth || 2}
                                                style={{ width: 100 }}
                                                type="number"
                                                inputProps={{ min: 0 }}
                                                onBlur={(e) => {
                                                    updateSingleData('lineWidth', e.target.value)
                                                }} />
                                        </Col>)}

                                        {widget.widgetType === 'reading' && (<Col >
                                            <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                                Opacity
                                            </Typography>
                                            <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                style={{ width: 100 }}
                                                defaultValue={item.opacity || ''}
                                                type="number"
                                                inputProps={{ min: 0 }}
                                                onBlur={(e) => {
                                                    updateSingleData('opacity', e.target.value)
                                                }} />

                                        </Col>)}
                                        {widget.widgetType === 'reading' && (<Col >
                                            <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                                Fill Opacity
                                            </Typography>
                                            <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                style={{ width: 100 }}
                                                defaultValue={item.fillOpacity || ''}
                                                type="number"
                                                inputProps={{ min: 0 }}
                                                onBlur={(e) => {
                                                    updateSingleData('fillOpacity', e.target.value)
                                                }} />

                                        </Col>)}
                                        <Col >
                                            <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                                Dec. Places
                                            </Typography>
                                            <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                style={{ width: 100 }}
                                                defaultValue={item.decimals === 0 || item.decimals ? item.decimals : ''}
                                                type="number"
                                                inputProps={{ min: 0 }}
                                                onBlur={(e) => {
                                                    updateSingleData('decimals', e.target.value)
                                                }} />

                                        </Col>
                                        {/*    {widget.widgetType === 'reading' && (
                                            <Col >
                                                <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                                    Group By Hours
                                                </Typography>
                                                <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                    style={{ width: 100 }}
                                                    defaultValue={item?.groupByHours || 0}
                                                    type="number"
                                                    inputProps={{ min: 0 }}
                                                    onChange={(e) => {
                                                        updateSingleData('groupByHours', Number(e.target.value))
                                                    }} />

                                            </Col>
                                        )} */}
                                        {widget.widgetType === 'reading' && (
                                            <Col >
                                                <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                                    Axis Label
                                                </Typography>
                                                <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                    style={{ width: 100 }}
                                                    defaultValue={item?.axisLabel || ''}
                                                    onBlur={(e) => {
                                                        updateSingleData('axisLabel', e.target.value)
                                                    }} />

                                            </Col>
                                        )}
                                        <Col >
                                            <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                                Offset
                                            </Typography>
                                            <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                style={{ width: 100 }}
                                                defaultValue={item.offset || ''}
                                                type="number"
                                                inputProps={{ min: 0 }}
                                                onBlur={(e) => {
                                                    updateSingleData('offset', e.target.value)
                                                }} />

                                        </Col>
                                        {/*  {widget.widgetType === 'reading' && (<Col >
                                            <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                                Show in Legend
                                            </Typography>
                                            <Checkbox checked={item?.showInLegend || false}
                                                onChange={(e) => {
                                                    updateSingleData('showInLegend', e.target.checked)
                                                }}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />

                                        </Col>)}
                                        {widget.widgetType === 'reading' && (<Col >
                                            <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                                Dots
                                            </Typography>
                                            <Checkbox
                                                checked={item?.dots || false}
                                                onChange={(e) => {
                                                    updateSingleData('dots', e.target.checked)
                                                }}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />

                                        </Col>)}
                                        {widget.widgetType === 'reading' && (<Col  >
                                            <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                                Dot Color
                                            </Typography>
                                            <DataColorCompact color={item.markerFill} updateData={(a, color) => {
                                                updateSingleData('markerFill', color)
                                            }} />
                                        </Col>)}

                                        {widget.widgetType === 'reading' && (<Col>
                                            <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                                Custom Interval
                                            </Typography>
                                            <Checkbox checked={item?.customInterval || false}
                                                onChange={(e) => {
                                                    updateSingleData('customInterval', e.target.checked)
                                                }}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />

                                        </Col>)}
                                        {item.customInterval === true && widget.widgetType === 'reading' && (<Col>
                                            <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                                Grouping by Hours
                                            </Typography>
                                            <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"

                                                defaultValue={item?.customIntervalVal || 0}
                                                type="number"
                                                inputProps={{ min: 0 }}
                                                onChange={(e) => {
                                                    updateSingleData('customIntervalVal', e.target.value)
                                                }} />
                                        </Col>)} */}



                                    </Row>

                                </Collapse>
                            </Col>
                        </Row>







                    </Col>





                    <Col span={24} style={{ paddingTop: 10, paddingBottom: 0, }}>
                        <Divider />
                    </Col>

                </Row>

            </Col>










        </Row>
    );
}