import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Button, Divider, IconButton, OutlinedInput, Typography } from '@mui/material';
import { Col, Popconfirm, Progress, Row } from 'antd';
import { collection, deleteDoc, doc, getFirestore, onSnapshot, query, setDoc, updateDoc, where } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import moment from 'moment';
import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Dropzone from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import '../../extra/hoverStyle.css';
import Lightbox from 'react-image-lightbox';

export default class Photos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            type: this.props.type,
            item: this.props.item,
            photos: [],
            loading: true,
            progressBars: {},
            storageURL: this.props.storageURL,
            sort: false,
            photoList: [],
            albumKey: this.props.albumKey,
            photoIndex: 0,
            isOpen: false,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
        if (this.props.albumKey !== prevProps.albumKey) {
            console.log(this.props.albumKey)
            if (this.props.albumKey !== undefined) {
                this.queryPhotos(this.props.albumKey)
            }
            this.setState({ albumKey: this.props.albumKey })
        }
        if (this.props.photos !== prevProps.photos) {

            this.setState({ photos: this.props.photos })
        }
        if (this.props.item !== prevProps.item) {
            this.setState({ item: this.props.item, files: this.props.item.files !== undefined ? this.props.item.files : [], })
        }

    }
    componentDidMount() {
        const { userInfo, albumKey, photos } = this.state;
        console.log(albumKey)
        if (this.props.albumKey !== undefined) {
            this.queryPhotos(this.props.albumKey)
        }

    }

    queryPhotos = async (albumKey) => {
        const { userInfo, item } = this.state;
        const db = getFirestore();
        //get photos where albumKey = albumKey

        if (item.name === "Field Log Photos") {
            console.log("FL query")
            const queryData = query(collection(db, "clients", userInfo.currentCompany, 'photos'), where('account', '==', userInfo.currentAccount), where('logkey', '<=', '\uf8ff'));
            const unsubscribe = onSnapshot(queryData, async (querySnapshot) => {
                const data = [];
                querySnapshot.forEach((doc) => {
                    data.push({ ...doc.data(), label: doc.data().name })
                });
                console.log(data)

                data.forEach(async (d, i) => {

                    const photoRef = doc(db, "clients", userInfo.currentCompany, 'photos', d.key);
                    await updateDoc(photoRef, { account: userInfo.currentAccount, company: userInfo.currentCompany, label: d.label || '' })
                    if (i === data.length - 1) {
                        console.log("UPDATED PHOTOS")
                    }
                })

                const photos = data.map((d) => {
                    return ({ ...d, fileLink: d.fileLink || d.url })
                })

                //sort by timestamp
                photos.sort((a, b) => {
                    return b.timestamp - a.timestamp
                })
                this.setState({ photos, loading: false })


            });
        }
        else {
            const queryData = query(collection(db, "clients", userInfo.currentCompany, 'photos'), where("albumKey", "==", albumKey));
            const unsubscribe = onSnapshot(queryData, async (querySnapshot) => {
                const data = [];
                querySnapshot.forEach((doc) => {
                    data.push({ ...doc.data(), label: doc.data().name })
                });
                console.log(data)

                //update each photo in firestore with account and company info
                data.forEach(async (d, i) => {

                    const photoRef = doc(db, "clients", userInfo.currentCompany, 'photos', d.key);
                    await updateDoc(photoRef, { account: userInfo.currentAccount, company: userInfo.currentCompany })
                    if (i === data.length - 1) {
                        console.log("UPDATED PHOTOS")
                    }

                    if (i === 0 && d.fileType.includes('image')) {
                        this.addThumbNail(d)
                    }
                })


                //sort by timestamp
                data.sort((a, b) => {
                    return b.timestamp - a.timestamp
                })
                this.setState({ photos: data, loading: false })


            });
        }

    }



    updateFile = async (index, boo, val, key) => {
        const { userInfo, photos } = this.state;
        photos[index][boo] = val;
        this.setState({ photos })

        this.handleCheck(index, boo, val, key)

    }



    handleCheck = (index, boo, val, key) => {
        // Clears running timer and starts a new one each time the user types
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.toggleCheck(index, boo, val, key);
        }, 1000);
    }

    toggleCheck = async (index, boo, val, key) => {
        const { userInfo, photos } = this.state;
        const db = getFirestore();
        const photoRef = doc(db, "clients", userInfo.currentCompany, 'photos', key);
        this.props.openSnack("success", 'Images Saved!')
        await updateDoc(photoRef, { [boo]: val })



    }

    addThumbNail = async (photo) => {
        const { userInfo, item } = this.state;
        const db = getFirestore();

        console.log(photo)
        console.log(item)
        delete photo.label;

        const albumRef = doc(db, "clients", userInfo.currentCompany, 'albums', item.key);

        await updateDoc(albumRef, { 'thumbNail': photo })

    }

    render() {

        const { item, screenWidth, photos, progressBars, userInfo, preview, storageURL, sort, photoList, photoIndex, isOpen } = this.state;

        const DeleteItem = ({ item }) => {
            let navigate = useNavigate();
            return (
                <Popconfirm
                    title="Are you sure to delete this photo?"
                    onConfirm={async () => {
                        const db = getFirestore()
                        await deleteDoc(doc(db, "clients", userInfo.currentCompany, 'photos', item.key)).then(() => {

                            const newPhotos = photos.filter((f) => f.key !== item.key)
                            this.setState({ photos: newPhotos })
                            this.props.openSnack('success', 'Photo deleted.')
                        })

                    }}
                    okText="Yes"
                    cancelText="No"
                >
                    <IconButton style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="small"
                    >
                        <DeleteForeverIcon />
                    </IconButton>
                </Popconfirm>


            )
        }



        return (
            <Row gutter={[12, 12]} align="middle">
                <Col sm={24}>
                    <Row align="middle" gutter={[24, 24]}>
                        <Col xs={24} >
                            <Divider />
                        </Col>
                        <Col xs={20} >
                            <Typography variant="h6">Photos</Typography>
                        </Col>

                        <Col span={4}>
                            <Button style={{ textTransform: 'none', padding: 12 }} variant="contained" color="primary"
                                onClick={() => {

                                    if (!sort === false) {
                                        this.setState({ sort: !sort, photos: photos.sort((a, b) => Number(a.date) - Number(b.date)) })
                                    }
                                    else {
                                        this.setState({ sort: !sort, photos: photos.sort((a, b) => Number(b.date) - Number(a.date)) })
                                    }

                                }}>Sort</Button>
                        </Col>

                        {Object.values(progressBars).length > 0 && (<Col xs={12} sm={6} >
                            {Object.values(progressBars).map((p) => {
                                return (
                                    <Col span={24}>
                                        {p.progress > 0 && (
                                            <div> {p.fileName} <Progress percent={p.progress} /></div>
                                        )}
                                    </Col>

                                )
                            })}
                        </Col>)}
 
                        {isOpen && (
                            <Lightbox
                                mainSrc={photos[photoIndex].fileLink}
                                nextSrc={photos[(photoIndex + 1) % photos.length].fileLink}
                                prevSrc={photos[(photoIndex + photos.length - 1) % photos.length].fileLink}
                                onCloseRequest={() => this.setState({ isOpen: false })}
                                onMovePrevRequest={() =>
                                    this.setState({
                                        photoIndex: (photoIndex + photos.length - 1) % photos.length,
                                    })
                                }
                                onMoveNextRequest={() =>
                                    this.setState({
                                        photoIndex: (photoIndex + 1) % photos.length,
                                    })
                                }
                            />
                        )}


                        {photos.map((p, i) => {


                            return (
                                <Col xs={12} sm={6} style={{ borderRadius: 8, }}>
                                    <Row align="middle" gutter={[8, 8]} style={{ position: 'relative', bottom: 0 }}>
                                        <Col span={24} style={{ cursor: 'pointer' }} onClick={() => {
                                            this.setState({ isOpen: true, photoIndex: i })
                                        }} >

                                            <img src={p.fileLink} style={{ width: '100%', maxHeight: '300px', borderRadius: 6 }} />


                                        </Col>
                                        <Col span={24}>
                                            <OutlinedInput fullWidth notched={false} label="none" placeholder={"Description"} size="small"

                                                value={p.description}
                                                onChange={(e) => {
                                                    this.updateFile(i, `description`, e.target.value, p.key)
                                                }} />
                                        </Col>

                                        {item.name !== "Field Log Photos" && (
                                            <Col span={20}>
                                                <DatePicker portalId="root-portal" showTimeSelect timeFormat="h:mm a" timeIntervals={1} timeCaption="time" dateFormat="MM/dd/yyyy h:mm a"
                                                    selected={[undefined, '', "Invalid date"].includes(p.date) ? new Date() : new Date(moment(p.date, 'x'))}
                                                    onChange={(date) => {

                                                        const unix = moment(date).format('x');
                                                        this.updateFile(i, `date`, unix, p.key)


                                                    }} />
                                            </Col>
                                        )}


                                        {item.name !== "Field Log Photos" && (<Col span={4} style={{ textAlign: 'right' }}>
                                            <DeleteItem item={p} />
                                        </Col>)}

                                    </Row>
                                </Col>

                            )
                        })}

                        {item.name !== "Field Log Photos" && (<Col className='fileUpload' xs={{ offset: 0, span: 24 }} sm={{ offset: photos.length > 0 ? 0 : 2, span: photos.length > 0 ? 6 : 20 }} style={{ border: '1px dashed gray', borderRadius: 8 }}>
                            <Dropzone onDrop={(acceptedFiles) => {

                                acceptedFiles.map((f) => {

                                    const storage = getStorage();
                                    const key = uuid()

                                    const storageRef = ref(storage, `${storageURL}${key}`);

                                    const uploadTask = uploadBytesResumable(storageRef, f);
                                    uploadTask.on('state_changed',
                                        (snapshot) => {

                                            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                                            console.log('Upload is ' + progress + '% done');

                                            progressBars[key] = { key, progress, fileName: f.name }

                                            this.setState({ progressBars })

                                            switch (snapshot.state) {
                                                case 'paused':
                                                    console.log('Upload is paused');
                                                    break;
                                                case 'running':
                                                    console.log('Upload is running');
                                                    break;
                                            }
                                        },
                                        (error) => {
                                        },
                                        () => {
                                            getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {



                                                const photo = {
                                                    fileCreateDate: moment().format('x'),
                                                    date: moment().format('x'),
                                                    timestamp: Number(moment().format('x')),
                                                    fileCreator: `${userInfo.firstName || ""} ${userInfo.lastName}`,
                                                    fileCreatorKey: userInfo.id,
                                                    fileLink: downloadURL,
                                                    fileName: f.name,
                                                    fileType: f.type,
                                                    description: '',
                                                    account: userInfo.currentAccount,
                                                    company: userInfo.currentCompany,
                                                    key,
                                                    albumKey: item.key
                                                };



                                                if (photos.length === 0) {
                                                    this.addThumbNail(photo)
                                                }
                                                this.addThumbNail(photo)
                                                const db = getFirestore()
                                                const docRef = doc(db, "clients", userInfo.currentCompany, 'photos', photo.key);
                                                await setDoc(docRef, photo)

                                                delete progressBars[key];
                                                photos.push(photo);
                                                this.setState({ photos, progressBars })









                                            });
                                        }
                                    );






                                })
                            }} >
                                {({ getRootProps, getInputProps }) => (
                                    <section>
                                        <div {...getRootProps()}>

                                            <Row align="middle">

                                                <Col span={24} style={{ padding: 6, textAlign: 'center' }}>
                                                    <AddAPhotoIcon color="primary" />
                                                </Col>
                                                <Col span={24} style={{ padding: 6, textAlign: 'center' }}>
                                                    <Typography variant='h6'>Upload Photos</Typography>
                                                </Col> <input {...getInputProps()} /></Row>
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                        </Col>)}
                    </Row>
                </Col>











            </Row>
        );
    }
} 