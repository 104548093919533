import { Button, Checkbox, Divider, OutlinedInput, Typography, Select, MenuItem, CircularProgress, Breadcrumbs, Paper, Input, Autocomplete, TextField, IconButton, Chip } from '@mui/material';
import { Col, Modal, Row } from 'antd';
import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { fullMobile } from './util';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, orderBy, limit } from 'firebase/firestore';

import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import uuid from 'react-uuid';
import EditIcon from '@mui/icons-material/Edit';

import DatePicker from "react-datepicker";

import queryString from 'query-string';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { getUser, getStaff, getAccounts, queryPSItems, queryAllItemsStock, queryStocks } from '../../firebase/config';
import { ArrowUpwardOutlined } from '@mui/icons-material';
const convert = require('convert-units');

var randomColor = require('randomcolor');


const getMuiTheme = () =>
    createTheme({
        components: {
            MUIDataTable: {
                styleOverrides: {
                    root: {
                        padding: '8px',
                        boxShadow: 'none'
                    }
                }
            },
            MuiTableRow: {
                styleOverrides: {
                    height: '40px',
                    //onhover change background to red


                    root: {
                        height: '40px',
                        zIndex: 100,
                        cursor: 'pointer',
                        fontSize: '10px',
                        '&:nth-child(even)': {
                            backgroundColor: '#eaeaeade',

                        },




                    },
                    rowHeight: '40px'



                }
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        fontSize: '12px',
                        padding: '2px',

                    }
                }
            }

        }
    });

export default function CurrentAmounts({ userInfo, screenWidth, openSnack, company, account, reports, updateAccount, updateUserInfo, refresh, updateRefresh }) {
    const [status, setStatus] = useState('creating');
    const [data, setData] = useState([]);
    const [stock, setStock] = useState({})
    const [staff, setStaff] = useState([])
    const [items, setItems] = useState([]);
    const [stocks, setStocks] = useState([]);
    const [accounts, setAccounts] = useState([])
    const [loading, setLoading] = useState(true)
    const [supplyModal, setSupplyModal] = useState(false)
    const [supply, setSupply] = useState({})
    const props = { userInfo, screenWidth, openSnack, company, account, };


    useEffect(() => {

        window.scrollTo(0, 0)
        getUserInfo()


    }, []);

    useEffect(() => {
        if (refresh === true) {
            getUserInfo()
            updateRefresh()
        }

    }, [refresh])




    async function getUserInfo() {
        queryDataDoc()
        const staff = await getStaff(userInfo.currentAccount);
        setStaff(staff)







    }

    async function queryDataDoc() {
        const db = getFirestore();
        const docRef = doc(db, "users", userInfo.id, 'currentItems', 'currentStock');
        const docSnap = await getDoc(docRef);


        if (docSnap.data() === undefined || docSnap.data().key === 'none') {

            setLoading(false)

        }

        else {
            getDocItem(docSnap.data().key)

        }
    }

    async function getDocItem(key) {
        const db = getFirestore();

        const docRef = doc(db, "clients", userInfo.currentCompany, 'productStock', key);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const item = docSnap.data();

            setStock(item)
           
 
            const stocks = await queryStocks(userInfo);
            setStocks(stocks)

            const accounts = await getAccounts(userInfo.id, userInfo.currentCompany, userInfo.accounts);
            setAccounts(accounts)

            const items = await queryPSItems(userInfo);

            setItems(items);



            const data = await queryAllItemsStock(userInfo, item.key);



            const newData = data.map(item => {
                //find account name
                const account = accounts.find(account => account.key === item.account) ?
                    accounts.find(account => account.key === item.account) :
                    accounts.find(account => account.key === item.accountKey) || { accountName: '' }


                const row = items.find(row => row.key === item.item) ?
                    items.find(row => row.key === item.item) :
                    items.find(row => row.key === item.item.key) ?
                        items.find(row => row.key === item.item.key) :
                        items.find(row => row.key === item.product) || { name: '' }

                const supplyStockKey = item.supplyStock !== undefined ? item.supplyStock.key : '';
                const supplyStock = stocks.find(stock => stock.key === supplyStockKey) || { name: '' };


                return {
                    ...item,
                    accountLabel: account ? account.accountName : '',
                    defaultUnits: row ? row.defaultUnits : '',
                    itemLabel: row ? row.name : '',
                    supplyStockLabel: supplyStock ? supplyStock.name : '',
                    type: item.product !== undefined ?
                        'Chemical' : item.psType === 'services' ? 'Service' :
                            item.psType === 'products' ? 'Product' :
                                item.psType === 'supply' ? 'Supply' : '',
                    time:
                        item.time ? moment(item.time, 'YYYY-MM-DD HH:mm a').format('MM-DD-YYYY h:mm a') :
                            item.startDate ? moment(item.startDate, 'x').format('MM-DD-YYYY h:mm a') :
                                moment(item.timestamp, 'X').format('MM-DD-YYYY h:mm a')
                }
            })

            //sort by time
            newData.sort((a, b) => {
                return moment(b.time, 'MM-DD-YYYY h:mm a').unix() - moment(a.time, 'MM-DD-YYYY h:mm a').unix()
            }
            )
            console.log(newData)

            const arr = newData;

            let products = {};
            let supplies = {};

            // Loop over each object in the array
            // Loop over each object in the array
            arr.forEach(obj => {
                console.log(obj)
                // Check if it's a product
                if (obj.psType === 'products') {
                    // If we haven't seen this item before, create it in the products object with quantity 0
                    if (!products[obj.item]) {
                        products[obj.item] = { quantity: 0, name: obj.itemLabel, defaultUnits: obj.defaultUnits };
                    }
                    // Convert the quantity to default units before adding it to the total
                    console.log(obj.defaultUnits)
                    const quantityInDefaultUnits = obj.units.key && obj.defaultUnits ?
                        convert(obj.quantity).from(obj.units.key).to(obj.defaultUnits) :
                        obj.quantity;
                    // Add the quantity to the total for this item in the products object
                    products[obj.item].quantity += quantityInDefaultUnits;
                }
                // Check if it's a supply
                else if (obj.psType === 'supply') {
                    // If we haven't seen this item before, create it in the supplies object with quantity 0
                    const itemKey = obj.item.key;
                    if (!supplies[itemKey]) {
                        supplies[itemKey] = { quantity: 0, name: obj.itemLabel, defaultUnits: obj.defaultUnits };
                    }
                    console.log(obj.defaultUnits)
                    // Convert the quantity to default units before adding it to the total
                    const quantityInDefaultUnits = obj.units.key && obj.defaultUnits ?
                        convert(obj.quantity).from(obj.units.key).to(obj.defaultUnits) :
                        obj.quantity;
                    // Add the quantity to the total for this item in the supplies object
                    supplies[itemKey].quantity += quantityInDefaultUnits;
                }
            });







            // Subtract the product totals from the supply totals
            Object.keys(products).forEach(itemKey => {
                if (supplies[itemKey]) {
                    supplies[itemKey].quantity -= products[itemKey].quantity;
                } else {
                    // If the supply item does not exist, assign a negative value of the product's quantity
                    supplies[itemKey] = { quantity: -products[itemKey].quantity, name: products[itemKey].name };
                }
            });

            // Convert to array
            let result = Object.keys(supplies).map(itemKey => {
                const amountRemaining = supplies[itemKey].quantity;
                const formattedAmount = Number.isInteger(amountRemaining) ? amountRemaining : amountRemaining.toFixed(2);
                return {
                    key: itemKey,
                    name: supplies[itemKey].name,
                    amountRemaining: formattedAmount,
                    units: supplies[itemKey].defaultUnits || '',
                };
            });







            console.log(result);









            setLoading(false)
            setData(result);



        }
        else {
            console.log('history not found')
        }


    }








    const columns = [

        {
            name: 'name',
            label: 'Name',
            options: {
                filterType: 'multiselect',
                filter: true,

                customBodyRender: (a, tableMeta, updateValue) => {


                    return (
                        <Typography variant="body1" fontWeight={400} fontSize={14}>
                            {a}
                        </Typography>
                    )
                }


            }
        },


        {
            name: 'amountRemaining',
            label: 'Amount Remaining',
            options: {
                filter: false,
                customBodyRender: (a, tableMeta, updateValue) => {




                    return (
                        <Typography variant="body1" fontWeight={400} fontSize={14}>
                            {a}
                        </Typography>
                    )
                }


            },

        },

        {
            name: 'units',
            label: 'Units',
            options: {
                filter: false,
                customBodyRender: (a, tableMeta, updateValue) => {
                    return (
                        <Typography variant="body1" fontWeight={400} fontSize={14}>
                            {a}
                        </Typography>
                    )
                }
            }
        },






    ]


    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </div>
        )
    }
    else {



        return (
            <Row style={{
                fontFamily: 'Roboto, sans-serif',
                backgroundColor: '#f6f8fe',//align content to top

            }} >


                <Col {...fullMobile(24, 24)} style={{ paddingTop: 10 }}>
                    <ThemeProvider theme={getMuiTheme()}>
                        <MUIDataTable
                            title={''}
                            data={data}
                            columns={columns}


                            options={{


                                rowsPerPage: 50,
                                selectableRows: false,
                                rowsPerPageOptions: [50, 100, 200, 500, 1000],

                            }}

                        />
                    </ThemeProvider>
                </Col>












            </Row>
        );
    }
}