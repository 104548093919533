import React, { useEffect, useState } from "react";
import {
    Box, Grid, Typography, OutlinedInput, FormControl, InputLabel, Select, MenuItem,
    InputAdornment,
    IconButton,
    Tooltip,
    Button
} from '@mui/material';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faEye } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash, faSquare } from "@fortawesome/free-regular-svg-icons";


const HideOptions = (
    {
        record,
        items,
        setItems,
        screenWidth,
        openSnack,
        userInfo,
        updateRecord,
        stocks,
        setStocks,
        accounts,
        val





    }

) => {
    const [isFocused, setIsFocused] = useState(false);



    return (
        <>
            <Tooltip title={record?.[val] ?
                `Hide ${val === 'showWeather' ? 'Weather' : val === 'showAdvanced' ? 'Advanced' : val === 'hide' ? 'Row' : 'Map'}` : `Show ${val === 'showWeather' ? 'Weather' : val === 'showAdvanced' ? 'Advanced' : val === 'hide' ? 'Row' : 'Map'}`}
                placement='bottom'>
                <Button size="small"
                    variant="outlined"
                    style={{ textTransform: 'none', color: '#3f51b5', borderColor: '#3f51b5', }}
                    onClick={() => {
                        updateRecord({ ...record, [val]: !record[val] })
                    }
                    }>
                    Hide {val === 'showWeather' ? 'Weather' : val === 'showAdvanced' ? 'Advanced' : val === 'hide' ? 'Row' : 'Map'}
                </Button>
            </Tooltip >



        </>
    );
};

export default HideOptions;
