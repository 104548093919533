import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { Button, Checkbox, CircularProgress, Divider, Input, List, ListItemButton, ListItemIcon, ListItemText, Paper, Typography } from '@mui/material';
import { Col, Drawer, Row } from 'antd';
import { collection, doc, getDoc, getDocs, getFirestore, query, setDoc, updateDoc, where } from 'firebase/firestore';
import moment from 'moment';
import React from 'react';
import uuid from 'react-uuid';
import CheckTimeWeather from '../blocks/checkTimeWeather';
import FooterButtons from './components/footerButtons';
import GeneralInfo from './components/generalInfo';
import PhotoList from './components/servicesProducts/photoList';
import ServicesProducts from './components/servicesProducts/servicesProducts';
import MakePDF from './pdf/makePDF';

export default class FieldLog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            company: this.props.company,
            type: this.props.type,
            item: {},
            loading: true,
            preview: false,
            spinning: { on: false, label: '' },
            distributionList: [],
            showDistribution: false,
            progress: 0,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
        if (this.props.company !== prevProps.company) {
            this.setState({ company: this.props.company })
        }

    }
    componentDidMount() {
        window.scrollTo(0, 0);
        const { userInfo, account } = this.state;
        this.queryDataDoc(userInfo);
        this.queryDistribution()

        console.log("TEST1")
    }

    queryDataDoc = async (userInfo) => {
        const { type } = this.state;
        const db = getFirestore();

        const docRef = doc(db, "users", userInfo.id, 'currentItems', 'currentFieldLog');
        const docSnap = await getDoc(docRef);

        if (docSnap.data().key === 'none') {
            this.setState({
                item: {
                    tempKey: uuid(),
                    status: 'created',
                    archived: false,
                    account: userInfo.currentAccount,
                    company: userInfo.currentCompany,
                    date: moment().format('x'),
                    timeIn: moment().format('x'),
                    status: 'edit',
                }, loading: false
            })

        }
        else {
            this.getDocItem(docSnap.data().key)
        }
    }

    queryDistribution = async () => {
        const { userInfo, type } = this.state;
        const db = getFirestore()
        const queryStaff = query(collection(db, "users",), where('accounts', 'array-contains', userInfo.currentAccount));
        const users = [];
        const snapStaff = await getDocs(queryStaff);
        snapStaff.forEach((doc) => {
            const item = doc.data();
            delete item.accounts;
            delete item.companies;
            users.push({ firstName: item.firstName, lastName: item.lastName, id: item.id, email: item.email })

        });



        const distributionList = []

        const queryStaffList = query(collection(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'staff',));
        const staffSnap = await getDocs(queryStaffList);
        staffSnap.forEach((doc) => {


            const user = users.filter((u) => u.id === doc.data().key)[0];

            const item = { ...user, flDistribution: doc.data().flDistribution };
            distributionList.push(item)

        });

        const queryCustom = query(collection(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'customDistribution',));
        const snapCustom = await getDocs(queryCustom);
        snapCustom.forEach((doc) => {
            distributionList.push(doc.data())

        });



        this.setState({ distributionList, })

    }



    getDocItem = async (key) => {
        const { userInfo } = this.state;
        const db = getFirestore();
        const docRef = doc(db, "clients", userInfo.currentCompany, 'fieldLogs', key);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {

            const logRef = doc(db, "clients", userInfo.currentCompany, 'fieldLogs', docSnap.data().key);

            /* await updateDoc(logRef, {
                archived: false,

            }).then(() => console.log("NOT ARCHIVED")) */

            this.setState({ item: docSnap.data(), loading: false, preview: docSnap.data().status === 'edit' ? false : true })


        }
    }

    updateItem = (boo, val) => {
        const { item } = this.state;
        item[boo] = val;

        this.setState({ item, preview: item.status === 'edit' ? false : true })
    }
    updateEntireItem = (value) => {

        this.setState({ item: value, preview: value.status === 'edit' ? false : true })
    }


    createLog = async (status) => {


        const { userInfo, item, } = this.state;
        this.setState({ spinning: { on: true, label: 'Creating Item' } })

        const db = getFirestore();
        const docRef = doc(db, "clients", userInfo.currentCompany, 'fieldLogs', item.key);
        await updateDoc(docRef, {
            status: status
        }).then(() => {
            item.lineItems.map(async (l) => {

                var dateFormat = moment(item.date, 'x').format('MM/DD/YYYY');
                var timeFormat = moment(item.timeIn, 'x').format('h:mm a');

                var dateTime = moment(dateFormat + ' ' + timeFormat, 'MM/DD/YYYY h:mm a').format('x');

                const line = {
                    ...l, weather: item.weather, date: dateTime
                }
                const docRef = doc(db, "clients", userInfo.currentCompany, 'flItems', line.key);
                await setDoc(docRef, line)

            })


        }).then(() => {
            this.updateItem('status', 'created');
            this.setState({ spinning: { on: false, label: '' } })
            this.props.openSnack('success', "Log is officially created!")
        })



    }

    updateStatus = async (status) => {
        const { userInfo, item } = this.state;
        const db = getFirestore();
        const docRef = doc(db, "clients", userInfo.currentCompany, 'fieldLogs', item.key);
        await updateDoc(docRef, {
            status: status
        }).then(() => {
            this.props.openSnack('success', "Status has been changed!")
        })


    }

    updateDistribution = (index, boo, val) => {
        const { distributionList } = this.state;

        distributionList[index][boo] = val;
        this.setState({ distributionList })

    }



    render() {

        const { userInfo, item, screenWidth, type, loading, account, company, preview, spinning, showDistribution, distributionList,progress } = this.state;




        const Loading = (props) => {
            return (
                <div style={{ minHeight: '100vh' }}>
                    <Row justify="space-around" gutter={[32, 8]} align="middle" style={{ paddingTop: 18, }}>

                        <Col span={24} style={{ textAlign: 'center', fontSize: 24, fontFamily: 'Roboto, sans-serif', }}>
                            <CircularProgress />
                        </Col>
                    </Row>
                </div>
            )
        }


        const uniqueEmails = distributionList.filter((item, index, self) => self.indexOf(item) == index);

        return (
            <Row style={{}}>
                <Col span={23}>
                    <Row align="middle">
                        <Col xs={16} sm={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>
                            <Typography variant="h4">Field Log</Typography>
                        </Col>
                        <Col xs={6} sm={4} style={{ paddingTop: 30, paddingBottom: 15, textAlign: 'right' }}>

                        </Col>
                        <Col span={24}>
                            <Divider />
                        </Col>
                    </Row>
                </Col>


                {item.status === 'edit' && (<Col offset={1} span={21} style={{ paddingTop: 15 }}>
                    <Typography fontWeight={500} variant={screenWidth === 'xs' ? 'h4' : "h2"}>{item.key ? "Edit Log" : "New Field Log"}</Typography>

                </Col>)}
                {item.status !== 'edit' && (<Col offset={1} span={21} style={{ paddingTop: 15 }}>
                    <Row align="middle" gutter={[12, 12]}>

                        <Col xs={24} sm={4}>
                            <Typography variant='h5'>Status</Typography>
                            <Typography variant='body1'>{item.status === 'draft' ? "Draft" : item.status === 'created' ? "Created" : "Distributed"}</Typography>

                        </Col>

                        <Col xs={24} sm={6}>
                            <Typography variant='h5'>Account</Typography>
                            <Typography variant='body1'>{account.accountName}</Typography>

                        </Col>



                        <Col xs={24} style={{}}>
                            <Row align="middle" gutter={[24, 24]}>
                                <Col span={24}>

                                    <Paper elevation={12} style={{ borderRadius: 12, padding: 40 }}>
                                        <Row align="middle" gutter={[12, 12]}>
                                            <Col xs={0} sm={4}>
                                                <PostAddIcon fontSize="large" color="primary" />
                                            </Col>
                                            <Col xs={12} sm={10}>
                                                <Typography variant='h6'>Create</Typography>
                                                {item.status === 'draft' ?
                                                    <Typography variant='body1'>This log is still a draft log. Hit the create button in order to complete and distribute.</Typography>
                                                    : <Typography variant='body1'>This log has been created! If you edit the log it will revert back to a draft!</Typography>
                                                }
                                            </Col>
                                            <Col xs={12} sm={10}>
                                                {spinning.on === true ? <Row align="middle" gutter={[12, 12]}>
                                                    <Col span={24} style={{ textAlign: 'right' }}>{spinning.label} <CircularProgress /> {progress.toFixed(0)} % </Col>
                                                </Row>
                                                    :

                                                    <Row align="middle" gutter={[12, 12]}>


                                                        <Col xs={24} sm={12} >



                                                            {item.status === 'draft' && (
                                                                <Button size="small" style={{ padding: 10, borderRadius: 4, textTransform: 'none', fontSize: 14, }}
                                                                    fullWidth variant='contained' color="primary"
                                                                    onClick={() => {
                                                                        this.createLog('created');

                                                                    }}>Create Log</Button>
                                                            )
                                                            }

                                                        </Col>
                                                        <Col xs={24} sm={12}>
                                                            <Button size="small" style={{ padding: 10, borderRadius: 4, textTransform: 'none', fontSize: 14, }}
                                                                fullWidth variant='outlined' color="primary"
                                                                onClick={() => {
                                                                    this.updateStatus('edit');
                                                                    this.updateItem('status', 'edit')
                                                                }}><EditIcon />Edit Log</Button>
                                                        </Col>
                                                    </Row>
                                                }

                                            </Col>
                                        </Row>



                                    </Paper>
                                </Col>

                                {['created', 'distributed'].includes(item.status) && (<Col span={24}>

                                    <Paper elevation={12} style={{ borderRadius: 12, padding: 40 }}>
                                        <Row align="middle" gutter={[12, 12]}>
                                            <Col xs={0} sm={4}>
                                                <EmailIcon fontSize="large" color="primary" />
                                            </Col>
                                            <Col xs={12} sm={10}>
                                                <Typography variant='h6'>Send</Typography>
                                                <Typography variant='body1'>This log has been created! Click distribute to send out!</Typography>
                                                {uniqueEmails.filter((f) => f.flDistribution === true)
                                                    .map((g) => {

                                                        return (`${g.firstName !== undefined ? `${g.firstName || ''} ${g.lastName || ''}` : g.label} (${g.email})`)
                                                    }).join(', ')}
                                            </Col>
                                            <Col xs={12} sm={10}>
                                                <Row align="middle" gutter={[12, 12]}>
                                                    <Col xs={24} sm={12}>
                                                        <MakePDF screenWidth={screenWidth} account={account} company={company} item={item} distributionList={distributionList}
                                                            userInfo={userInfo} type="email" openSnack={this.props.openSnack} updateProgress={(progress) => this.setState({ progress})} />
                                                    </Col>
                                                    <Col xs={24} sm={12}>
                                                        <Button size="small" style={{ padding: 10, borderRadius: 4, textTransform: 'none', fontSize: 14, }}
                                                            fullWidth variant='outlined' color="primary" onClick={() => { this.setState({ showDistribution: true }) }}>Add Emails</Button>
                                                    </Col>
                                                </Row>

                                            </Col>
                                        </Row>



                                    </Paper>
                                </Col>)}

                                {['created', 'distributed'].includes(item.status) && (<Col span={24}>

                                    <Paper elevation={12} style={{ borderRadius: 12, padding: 40 }}>
                                        <Row align="middle" gutter={[12, 12]}>
                                            <Col xs={0} sm={4}>
                                                <DownloadIcon fontSize="large" color="primary" />
                                            </Col>
                                            <Col xs={12} sm={10}>
                                                <Typography variant='h6'>Download</Typography>
                                                <Typography variant='body1'>This log has been created! Click to download!</Typography>
                                            </Col>
                                            <Col xs={12} sm={10}>
                                                <Row align="middle" gutter={[12, 12]}>
                                                    <Col xs={24} sm={12}>

                                                    </Col>
                                                    <Col xs={24} sm={12}>

                                                        <MakePDF screenWidth={screenWidth} account={account} company={company} item={item}
                                                            userInfo={userInfo} type="download" openSnack={this.props.openSnack} />

                                                    </Col>
                                                </Row>

                                            </Col>
                                        </Row>



                                    </Paper>
                                </Col>)}
                            </Row>




                        </Col>




                    </Row>












                </Col>)}











                <Col offset={1} span={21} style={{ paddingTop: 30 }}>
                    <Paper elevation={12} style={{ borderRadius: 12 }}>
                        {loading ? <Loading /> : <Row>

                            <Col xs={24} sm={12}>
                                <Row align="middle" gutter={[4, 4]} style={{ paddingLeft: 20, paddingTop: 20, paddingRight: 20, paddingBottom: screenWidth === 'xs' ? 5 : 20 }}>
                                    <Col xs={24} >
                                        <Typography variant="h5">{company.companyName}</Typography>
                                    </Col>
                                    <Col xs={24} >
                                        <Typography fontWeight={500} variant="h5">{account.accountName}</Typography>
                                    </Col>
                                    <Col xs={24} >
                                        <Typography variant="body1">{account.address || ''}</Typography>
                                    </Col>
                                    <Col xs={24} >
                                        <Typography variant="body1" style={{ textTransform: 'capitalize' }}>{account.countyName || ''} County</Typography>
                                    </Col>
                                    <Col xs={24} >
                                        <Divider style={{
                                            borderColor: 'rgb(148, 186, 195)',
                                            borderBottomWidth: 'medium',
                                            paddingTop: '10px'
                                        }} />
                                    </Col>
                                    <Col xs={24}   >
                                        <CheckTimeWeather weather={item.weather || {}} userInfo={userInfo} account={account} updateItem={this.updateItem} screenWidth={screenWidth} date={item.date} time={item.timeIn} />

                                    </Col>
                                </Row>

                            </Col>

                            <Col xs={24} sm={12} style={{ paddingLeft: 20, paddingTop: screenWidth === 'xs' ? 0 : 20, paddingRight: 20, paddingBottom: 20 }}>
                                <GeneralInfo preview={preview} item={item} screenWidth={screenWidth} userInfo={userInfo} updateItem={this.updateItem} updateEntireItem={this.updateEntireItem} />

                            </Col>


                            <Col xs={24} sm={24}>
                                <ServicesProducts preview={preview} item={item} screenWidth={screenWidth} userInfo={userInfo} account={account} updateItem={this.updateItem} />

                            </Col>
                            <Col xs={24} sm={24} style={{ paddingLeft: 20, paddingTop: screenWidth === 'xs' ? 0 : 20, paddingRight: 20, paddingBottom: 20 }}>
                                <Row align="middle" gutter={[8, 8]}>
                                    <Col xs={24} >
                                        <Divider />
                                    </Col>
                                    <Col xs={24} >
                                        <Typography variant="h6">Notes</Typography>
                                    </Col>
                                    <Col xs={24} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                        {preview ? <Typography variant="body1">{item.fieldNotes}</Typography> :
                                            <Input fullWidth notched={false} label="none" placeholder={"Field Notes"} size="large"
                                                disableUnderline={true}
                                                multiline={true}
                                                rows={4}
                                                value={item.fieldNotes}
                                                onChange={(e) => {
                                                    this.updateItem(`fieldNotes`, e.target.value)
                                                }} />
                                        }

                                    </Col>
                                </Row>

                            </Col>
                            <Col xs={24} sm={24} style={{ paddingLeft: 20, paddingTop: screenWidth === 'xs' ? 0 : 20, paddingRight: 20, paddingBottom: 20 }}>
                                <PhotoList storageURL={`${item.key}/files/`} preview={preview} item={item} screenWidth={screenWidth} userInfo={userInfo} account={account} updateItem={this.updateItem} />
                            </Col>












                        </Row>}
                    </Paper>
                </Col>
                <Col span={24} style={{ paddingTop: 24, pddingBottom: 24 }}>
                    {preview === false && (<FooterButtons item={item} screenWidth={screenWidth} userInfo={userInfo} openSnack={this.props.openSnack} updateEntireItem={this.updateEntireItem} />)}
                </Col>

                <Drawer

                    visible={showDistribution}
                    onClose={() => { this.setState({ showDistribution: false }) }}

                >
                    <List >
                        {distributionList.map((d, i) => {



                            return (
                                <ListItemButton onClick={() => this.updateDistribution(i, 'flDistribution', !d.flDistribution)} dense>
                                    <ListItemIcon>
                                        <Checkbox

                                            checked={d.flDistribution}
                                            color="primary"

                                            onChange={(e) => {
                                                this.updateDistribution(i, 'flDistribution', e.target.checked)
                                            }}

                                        />
                                    </ListItemIcon>
                                    <ListItemText id={i} primary={d.firstName !== undefined ? `${d.firstName || ''} ${d.lastName || ''}` : d.label}
                                        secondary={d.email} />

                                </ListItemButton>
                            )
                        })}








                    </List>


                </Drawer>

            </Row >
        );
    }
} 