import React, { useEffect, useState } from "react";


import { faBox, faBucket, faCheck, faFish, faFlask, faListCheck, faMagnifyingGlass, faPlus, faWheatAlt, faWrench, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, ButtonGroup, Divider, FormControl, Grid, IconButton, InputLabel, MenuItem, Modal, OutlinedInput, Select, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import UploadBox from "./uploadBox";
import { createFilesFunction, deleteFilesFunction } from "../../../../../../../../documentsNew/utils/filesFunctions";
import uuid from "react-uuid";
import { fetchFiles } from "./functions";
import FileLoadingCover from "../../../../../../../../documentsNew/pages/fileLoadingCover";
import FileCover from "../../../../../../../../documentsNew/components/filesComponents/fileCover";
import pdfPlaceholder from './pdfPlaceholder.JPG'
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import LightboxComponent from "../../../../../../../../documentsNew/components/filesComponents/lightBox";


const FilesList = (
    {
        screenWidth,
        newItem,
        setNewItem,
        openSnack,
        userInfo,
        files,
        setFiles,
        setIsLightboxOpen,
        setCurrentImageIndex,
        currentImageIndex,
        isLightboxOpen,



    }

) => {
    const [uploadProgress, setUploadProgress] = useState({});
  
    const [fetchingFiles, setFetchingFiles] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [currentFile, setCurrentFile] = useState(null);

    const [lastIndex, setLastIndex] = useState(0);

    const handleFileClick = index => {
        setCurrentImageIndex(index);
        setIsLightboxOpen(true);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const list = await fetchFiles(userInfo, null, setFetchingFiles, openSnack, newItem.id || null)

                setFiles(list);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false); // Ensure loading is set to false after operations complete
            }
        };


        fetchData();
    }, []);


    const createFiles = async (newFiles) => {
        const item_id = newItem.id || uuid()
        const lastFile = await createFilesFunction(newFiles, files, setFiles, null, userInfo, openSnack, setUploadProgress, true, item_id)

        setNewItem({ ...newItem, id: item_id })
    }
    const deleteFiles = async (filesToDelete) => {
        deleteFilesFunction(filesToDelete, userInfo, openSnack, files, setFiles)
    }



    return (
        <>




            <Grid container spacing={2}>
                {files.map((file, index) => {

                    return (


                        file.isLoading ? (
                            <Grid key={file.file_id} item xs={6} sm={4} md={3}>
                                <FileLoadingCover
                                    file={file}
                                    title={file?.name}
                                    progress={file?.fileType.includes('image') ? Number(`${Math.round(Math.min(
                                        uploadProgress[file.file_id]?.small || 0,
                                        uploadProgress[file.file_id]?.medium || 0,
                                        uploadProgress[file.file_id]?.large || 0,
                                    ))} `)
                                        :
                                        Number(`${Math.round(Math.min(
                                            uploadProgress[file.file_id]?.path || 0,
                                        ))} `)

                                    }
                                />

                            </Grid>
                        ) : (


                            <Grid key={file.file_id} item xs={6} sm={4} md={3}>

                                <FileCover ref={null}
                                    cardType="item"
                                    currentFile={currentFile}
                                    setCurrentFile={setCurrentFile}
                                    selectedFiles={selectedFiles}
                                    file={file}
                                    index={index}
                                    lastIndex={lastIndex}
                                    moveFilesUpDirectory={() => { }}
                                    handleFileClick={handleFileClick}
                                    selected={selectedFiles.includes(file.file_id)}
                                    deleteFiles={deleteFiles}
                                    state={'idle'}
                                    imageSrc={file.medium || (file?.fileType === 'application/pdf' ? pdfPlaceholder : file?.fileType?.includes('video') ? file?.screenshot : null)}
                                    title={file.name}
                                    selectMultipleFiles={null}
                                    // Style based on isDragging
                                    style={{ border: 'none' }}
                                />

                            </Grid>


                        )


                    )
                })}
            </Grid>

            <Col xs={24}  >



                <UploadBox
                    createFiles={createFiles} openSnack={openSnack}
                />

            </Col>

            



        </>



    );
};

export default FilesList;
