import React, { useState, useRef, useEffect } from "react";
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Typography,
    Button,
    Container,
    Grid
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faComment, faFileCsv } from "@fortawesome/free-solid-svg-icons";
import { faFileAlt } from "@fortawesome/free-regular-svg-icons";
import { unitsArray } from "../../extra/unitsArray";

const UploadTypeCards = ({ setUploadType, setStep }) => {

    const DownloadCSV = ({ id }) => {

        const dataSingle = [
            ["Node", "Parameter", "Value", "Units", "Date", "Code"],
            ["Site 1", "E. coli", "8.6", "MPN/100 ml", "10-10-23", "ND"],
            ["Site 1", "pH", "7.6", "", "10-10-23", ""],
            ["Site 1", "Specific conductivity", "195.9", "µS/cm", "10-10-23", ""],
            ["Site 1", "Total hardness", "97.6", "mg/L CaCO3", "10-10-23", ""],
            ["Site 1", "Turbidity", "2.3", "NTU", "10-10-23", ""],
            ["Site 2", "E. coli", "2", "MPN/100 ml", "10-10-23", ""],
            ["Site 2", "BOD", "3", "mg/L", "10-10-23", ""],
            ["Site 2", "pH", "7.6", "", "10-10-23", "OD"],
            ["Site 2", "Specific conductivity", "145.1", "µS/cm", "10-10-23", ""],
            ["Site 2", "Total hardness", "8", "mg/L CaCO3", "10-10-23", ""],
            ["Site 2", "Turbidity", "19", "NTU", "10-10-23", ""]
        ];
        const dataMultiple = [
            ["Site", "Date", "E. coli", "pH", "Specific conductivity", "Total hardness", "Turbidity", "BOD"],
            ["Site 1", "10-10-23", "8.6", "7.6", "195.9", "97.6", "2.3", ""],
            ["Site 2", "10-10-23", "2", "7.6", "145.1", "8", "19", "3"]
        ];



        const handleDownload = () => {
            let csvContent = "data:text/csv;charset=utf-8,";
            let data = id === 'csv-single-row' ? dataSingle : dataMultiple;
            data.forEach((row) => {
                const rowString = row.join(",");
                csvContent += rowString + "\r\n";
            });

            const encodedUri = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", id === 'csv-single-row' ? "example-single-row.csv" : "example-multiple-row.csv");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        };

        return <Typography
            onClick={(e) => {
                e.stopPropagation();
                handleDownload();
            }}
            variant="body1" fontWeight={600}
            onMouseEnter={(e) => {
                e.target.style.color = "orange";
                e.target.style.fontWeight = 700;
            }}
            onMouseLeave={(e) => {
                e.target.style.color = "#67aded";
            }}
            style={{
                display: 'inline',
                color: '#67aded',
                cursor: 'pointer',
                textDecoration: 'underline',  // underline the text
                marginLeft: 5
            }}
            fontSize={14}
        >Download Example {id === 'csv-single-row' && (",")}</Typography>;
    };
    const UnitsCSV = ({ id }) => {
        const dataSingle = [
            ["Units",],
            ...unitsArray.map((unit) => [unit.label])
        ];





        const handleDownload = () => {
            let csvContent = "data:text/csv;charset=utf-8,";
            let data = dataSingle;
            data.forEach((row) => {
                const rowString = row.join(",");
                csvContent += rowString + "\r\n";
            });

            const encodedUri = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "units available.csv");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        };

        return <Typography
            onClick={(e) => {
                e.stopPropagation();
                handleDownload();
            }}
            variant="body1" fontWeight={600}
            onMouseEnter={(e) => {
                e.target.style.color = "orange";
                e.target.style.fontWeight = 700;
            }}
            onMouseLeave={(e) => {
                e.target.style.color = "#67aded";
            }}
            style={{
                marginLeft: 5,
                display: 'inline',
                color: '#67aded',
                cursor: 'pointer',
                textDecoration: 'underline',  // underline the text
                marginLeft: 5
            }}
            fontSize={14}
        >LakeTech units available</Typography>;
    };




    const CardItem = ({ title, description, actionText, index, color, icon, id, setUploadType }) => (
        <Card
            // Attach ref
            variant="outlined"
            style={{ display: 'flex', flexDirection: 'column' }}  // Using flexbox

            onClick={() => {
                if (!['csv-single-row', 'csv-multiple-row'].includes(id)) return;
                setUploadType(id);
                setStep('upload');
            }}
            sx={{
                height: '100%',
                borderRadius: 2,
                cursor: 'pointer',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                transition: 'box-shadow 0.3s',
                '&:hover': {
                    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)'
                },
            }}
        >
            {/* Icon Box */}
            <Box
                sx={{
                    width: 60,
                    height: 60,
                    borderRadius: 2,
                    background: `linear-gradient(.17turn, ${color}, #9198e5)`,
                    position: 'relative',
                    top: 8,
                    left: 8,
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // added boxShadow
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                }}
            >
                <FontAwesomeIcon icon={icon} size="2xl" style={{ color: 'white', position: 'relative', }} />
            </Box>

            {/* Card Header */}
            <CardHeader
                title={
                    <Typography variant="h6" fontWeight={700} fontSize={18}>
                        {title}
                    </Typography>
                }
            />

            {/* Card Content */}
            <CardContent style={{ flex: 1 }}>  {/* Add flex: 1 to occupy available space */}
                <Typography variant="body1" fontWeight={400} style={{ display: 'inline' }}
                    fontSize={14}
                >{description}</Typography>
                {['csv-single-row', 'csv-multiple-row'].includes(id) && (<DownloadCSV id={id} />)}
                {['csv-single-row'].includes(id) && (<UnitsCSV id={id} />)}
            </CardContent>

            {/* Upload Button */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1, pb: 2, px: 2 }}>
                <Button
                    onMouseEnter={(e) => {
                        e.target.style.backgroundColor = '#dbdcf1';
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.backgroundColor = '#f2f4f7';
                    }}
                    onClick={() => {
                        if (!['csv-single-row', 'csv-multiple-row'].includes(id)) return;
                        setUploadType(id);
                        setStep('upload');
                    }}
                    style={{
                        textTransform: 'none',
                        backgroundColor: '#f2f4f7',
                        color: 'black',
                        fontWeight: 600,
                        fontSize: 14,
                    }}
                    fullWidth variant="contained" color="primary">{!['csv-single-row', 'csv-multiple-row'].includes(id) ? 'Coming Soon' : actionText}</Button>
            </Box>
        </Card>
    );


    return (
        <Box sx={{ mt: 4, mb: 8, px: 4 }}>
            <Typography variant="h5" fontWeight={700} fontSize={24} align="center">
                Welcome, to the LakeTech data upload page!
            </Typography>
            <Typography variant="subtitle1" align="center" gutterBottom>
                To start uploading your data, select the upload type below.
            </Typography>



            <Grid container spacing={3} mt={4}>
                {[
                    {
                        index: 0,
                        icon: faFileCsv,
                        color: "#f57722",
                        title: "CSV Upload (single data point per row)",
                        description:
                            "Upload a csv where each row is an individual data point. This means each row has at minimum, a date, a node, and a value.",
                        actionText: "Upload",
                        id: "csv-single-row",
                    },
                    {
                        index: 1,
                        icon: faFileAlt,
                        color: "#63be8d",
                        title: "CSV Upload (multiple data points per row)",
                        description: `Upload a csv where each row  has a node, date, and data columns. The column headers should be "Node", "Parameter", and then the analytes sampled.`,
                        actionText: "Upload",
                        id: "csv-multiple-row",
                    },
                    {
                        index: 3,
                        icon: faComment,
                        disabled: true,
                        color: "#67aded",
                        title: "Speak (coming soon)",
                        description:
                            "Speak your data into the app. This is a beta feature, so please be patient with us as we work out the kinks.",
                        actionText: "Talk now",
                        id: "speak",
                    },
                    {
                        index: 2,
                        disabled: true,
                        icon: faCamera,
                        color: "#d3d3d3",
                        title: "Picture (coming soon)",
                        description:
                            "Upload a picture of your data sheet. This is a beta feature, so please be patient with us as we work out the kinks.",
                        actionText: "Upload",
                        id: "picture",
                    },
                ].map((item) => (
                    <Grid item xs={12} sm={6} md={4} key={item.id}>
                        <CardItem
                            id={item.id}
                            setUploadType={setUploadType}
                            index={item.index}
                            icon={item.icon}
                            color={item.color}
                            title={item.title}
                            description={item.description}
                            actionText={item.actionText}
                            disabled={item.disabled}
                        />
                    </Grid>
                ))}
            </Grid>
        </Box>

    );

};

export default UploadTypeCards;
