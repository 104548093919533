

import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { GithubPicker } from 'react-color';
import reactCSS from 'reactcss';

const DataColorGithub = ({

    layerColor,
    setLayerColor,
    layerOpacity,
    onChange,
    color,

}) => {

    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [defaultColor, setDefaultColor] = useState(color);







    function handleClick() {
        setDisplayColorPicker(!displayColorPicker)
    };

    function handleClose() {
        setDisplayColorPicker(false)
    };

    function handleChange(color) {
        setDefaultColor(color)
        onChange(color)

    };


    const styles = reactCSS({
        'default': {
            color: {
                width: '36px',
                height: '14px',
                borderRadius: '2px',
                background: defaultColor?.hex,
            },
            swatch: {
                leftPadding: 10,
                padding: '5px',
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
            },
            popover: {
                position: 'absolute',
                left: '-0px',
                top: '-0px',
                zIndex: '2',
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
        },
    });

    return (
        <Row >
            <Col style={styles.swatch} onClick={handleClick}>
                <div style={styles.color} />
            </Col>
            {displayColorPicker ? <Col style={styles.popover}>
                <div style={styles.cover} onClick={handleClose} />
                <GithubPicker
                    colors={
                        ['#B80000', '#DB3E00', '#FCCB00', '#008B02', '#006B76',
                            '#1273DE', '#004DCF', '#5300EB', '#EB9694', '#FAD0C3',
                            '#FEF3BD', '#C1E1C5', '#BEDADC', '#C4DEF6', '#BED3F3', '#D4C4FB',
                            '#f57722',
                            '#ff9d2a',
                            '#d3d3d3',
                            '#67aded',
                            '#393939',
                            'black',
                            'white',

                        ]
                    } color={defaultColor.hex} onChange={handleChange} />
            </Col> : null}

        </Row>
    );
}

export default DataColorGithub;


