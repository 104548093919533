import React from "react";
import StringInput from "./stringInput";
import { Col, Row } from "antd";
import { Typography } from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes as faX } from "@fortawesome/free-solid-svg-icons";  // renaming faTimes to faX for clarity
import { IconButton } from "@mui/material";
import UnitsSelect from "./unitsSelect";
import ParameterSelect from "./parameterSelect";
import TimezonePicker from "./timezonePicker";
import DisplayString from "./none";
import StringNone from "./stringNone";
import NumberNone from "./numberNone";
import ProbeTypeSelect from "./probeTypeSelect";
import NumberInput from "./numberInput";
import ProbeSelect from "./probeSelect";
import Map from "./map";
import Status from "./status";
import UnitsSystemPicker from "./unitsSystem";


const CustomHeadersSetup = ({
    currentStream,
    setCurrentStream,
    customHeaders,
    ProbeParameters,
    userInfo,
    hydroProbes,
    loadingProbes,
    prefKey

}) => {





    const [amounts, setAmounts] = React.useState({});



    console.log(prefKey)

    return (
        <>
            {customHeaders?.map((header, index) => {




                return (
                    <Col key={header.id} span={24} style={{ paddingTop: 10 }}>
                        <Row align="middle" gutter={[12, 12]}>
                            <Col xs={7} sm={5}>
                                <Typography variant='body1' fontWeight={500} color={'rgba(0,0,0,0.6)'} fontSize={13}>
                                    {header.headerName}
                                </Typography>
                            </Col>
                            <Col xs={16} sm={17}>
                                {header?.optionType === 'string' && (<StringInput headerKey={header.id} currentStream={currentStream} setCurrentStream={setCurrentStream} />)}
                                {header?.optionType === 'map' && (<Map type={'testing123'} headerKey={header.id} currentStream={currentStream} setCurrentStream={setCurrentStream} />)}
                                {header?.optionType === 'timezonePicker' && (<TimezonePicker headerKey={header.id} currentStream={currentStream} setCurrentStream={setCurrentStream} />)}
                                {header?.optionType === 'unitsSystem' && (<UnitsSystemPicker headerKey={header.id} currentStream={currentStream} setCurrentStream={setCurrentStream} />)}
                                {header?.optionType === 'unitSelect' && (<UnitsSelect headerKey={header.id} currentStream={currentStream} setCurrentStream={setCurrentStream} />)}
                                {header?.optionType === 'parameterSelect' && (<ParameterSelect ProbeParameters={ProbeParameters} headerKey={header.id} currentStream={currentStream} setCurrentStream={setCurrentStream} />)}
                                {header?.optionType === 'none' && (<DisplayString headerKey={header.id} currentStream={currentStream} setCurrentStream={setCurrentStream} />)}
                                {header?.optionType === 'stringNone' && (<StringNone headerKey={header.id} currentStream={currentStream} setCurrentStream={setCurrentStream} />)}
                                {header?.optionType === 'numberNone' && (<NumberNone headerKey={header.id} currentStream={currentStream} setCurrentStream={setCurrentStream} />)}
                                {header?.optionType === 'probeTypeSelect' && (<ProbeTypeSelect headerKey={header.id} currentStream={currentStream} setCurrentStream={setCurrentStream} />)}
                                {header?.optionType === 'number' && (<NumberInput headerKey={header.id} currentStream={currentStream} setCurrentStream={setCurrentStream} />)}
                                {header?.optionType === 'status' && (<Status headerKey={header.id} currentStream={currentStream} setCurrentStream={setCurrentStream} />)}
                                {header?.optionType === 'probeSelect' && (<ProbeSelect loadingProbes={loadingProbes} hydroProbes={hydroProbes} headerKey={header.id} currentStream={currentStream} setCurrentStream={setCurrentStream} />)}

                            </Col>
                            {/* <Col sm={2}>
                                    <IconButton onClick={() => handleRemove(header.id)}>
                                        <FontAwesomeIcon icon={faX} />
                                    </IconButton>
                                </Col> */}
                        </Row>
                    </Col>










                );

            }
            )}
            {/* ... Your other hardcoded components here ... */}
        </>
    );
};

export default CustomHeadersSetup;
