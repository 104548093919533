import { Button, CircularProgress } from '@mui/material';
import { Col, Row } from 'antd';
import { doc, getFirestore, setDoc } from 'firebase/firestore';
import React from 'react';

export default class FooterButtons extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            item: this.props.item,
            saving: false
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.item !== prevProps.item) {
            this.setState({ item: this.props.item })
        }


    }
    componentDidMount() {
        const { userInfo, item } = this.state;

    }

    saveDraft = async () => {
        const { item, userInfo } = this.state;
        this.setState({ saving: true })
        const checks = [
            item.logNumber.length === 0 ? false : true,
            item.leadStaff === undefined ? false : item.leadStaff === null ? false : true
        ]

        if (item.logNumber.length === 0) {
            this.props.openSnack('error', "Log number required!")
            this.setState({ saving: false })
        }
        if ([undefined, null].includes(item.leadStaff)) {
            this.props.openSnack('error', "Lead staff is required!")
            this.setState({ saving: false })
        }
        if (!checks.includes(false)) {
            const db = getFirestore();
            const docRef = doc(db, "clients", userInfo.currentCompany, 'fieldLogs', item.tempKey);
            const newItem = {
                ...item,
                key: item.key || item.tempKey,
                status: 'edit',
            };
            await setDoc(docRef, newItem).then(async () => {
                this.props.openSnack('success', item.key ? "Field Log Saved!" : 'Field Log Saved!')
                this.setState({ saving: false })
                const userRef = doc(db, "users", userInfo.id, 'currentItems', 'currentFieldLog');
                await setDoc(userRef, {
                    key: newItem.key
                }).then(() => {
                    this.props.updateEntireItem(newItem);
                    window.scrollTo(0, 0)
                })

            })

        }

    }

    saveContinue = async () => {
        const { item, userInfo } = this.state;
        this.setState({ saving: true })
        const checks = [
            item.logNumber.length === 0 ? false : true,
            item.leadStaff === undefined ? false : item.leadStaff === null ? false : true
        ]

        if (item.logNumber.length === 0) {
            this.props.openSnack('error', "Log number required!")
            this.setState({ saving: false })
        }
        if ([undefined, null].includes(item.leadStaff)) {
            this.props.openSnack('error', "Lead staff is required!")
            this.setState({ saving: false })
        }
        if (!checks.includes(false)) {
            const db = getFirestore();
            const docRef = doc(db, "clients", userInfo.currentCompany, 'fieldLogs', item.tempKey);
            const newItem = {
                ...item,
                key: item.key || item.tempKey,
                status: 'draft',
            };
            await setDoc(docRef, newItem).then(async () => {
                this.props.openSnack('success', item.key ? "Field Log Saved!" : 'Field Log Saved!')
                this.setState({ saving: false })
                const userRef = doc(db, "users", userInfo.id, 'currentItems', 'currentFieldLog');
                await setDoc(userRef, {
                    key: newItem.key
                }).then(() => {
                    this.props.updateEntireItem(newItem);
                    window.scrollTo(0, 0)
                })

            })

        }

    }



    render() {

        const { item, screenWidth, saving } = this.state;




        return (
            <Row align="middle" >
                <Col offset={1} span={21} style={{ paddingTop: 18 }}>
                    {saving ?
                        <Row align="middle" justify='end' gutter={[18, 18]}>
                            <Col sm={4}>
                                Saving ... <CircularProgress />
                            </Col>
                        </Row>
                        :
                        <Row align="middle" justify='end' gutter={[18, 18]}>
                            
                            <Col xs={24} sm={4}>
                                <Button variant='outlined' fullWidth color="primary" style={{ padding: 16, borderRadius: 12, textTransform: 'none', fontSize: 20, background: 'white' }} size="large"
                                    onClick={() => {
                                        this.saveDraft()
                                    }}>Save Draft</Button>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Button variant='contained' fullWidth color="primary" style={{ padding: 16, borderRadius: 12, textTransform: 'none', fontSize: 20, }} size="large"
                                    onClick={() => {
                                        this.saveContinue()
                                    }}>Save and Continue</Button>
                            </Col>

                        </Row>}
                </Col>

            </Row>
        );
    }
} 