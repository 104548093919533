import { Map } from '@esri/react-arcgis';
import { loadModules } from 'esri-loader';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import React from 'react';
import SketchLayer from './layer';

loadModules(['esri/config',])
  .then(([config,]) => {
    config.apiKey = "AAPK65ed7997d0524b6582730da31dc1f8986spR1Pjc0oR3qn-NVYrj0jI7Y6156OU89gRRDdPUWC1gjMayxwOkEmxo2aoZH56R";
  })
  .catch(err => {
    // handle any errors
    console.error(err);
  });
 
export default class AppMap extends React.Component {
  constructor(props) { 
    super(props);
    this.state = {
      screenWidth: this.props.screenWidth,
      userInfo: this.props.userInfo,
      account: this.props.account,
      graphics: JSON.parse(this.props.item.graphics),
      layerColor: this.props.layerColor,
      item: this.props.item,
      reloadLayer: false,
      printScreen: false
    };
  }

  componentDidUpdate(prevProps) {

    if (this.props.layerColor !== prevProps.layerColor) {

      this.setState({ reloadLayer: true })
      setTimeout(() => {
        this.setState({ layerColor: this.props.layerColor, reloadLayer: false })
      }, 250);
    }
    if (this.props.account !== prevProps.account) {
      this.setState({ account: this.props.account })
    }
    if (this.props.printScreen !== prevProps.printScreen) {
      this.setState({ printScreen: this.props.printScreen })
    }



  }
  componentDidMount() {

   
  }



  handleCheck = () => {
    // Clears running timer and starts a new one each time the user types
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.toggleCheck();
    }, 2000);
  }


  toggleCheck = async () => {
    this.setState({ printScreen: true })

    setTimeout(() => {
      this.setState({ printScreen: false })
    }, 1000);

  }


  render() {

    const { account, graphics, layerColor, userInfo, printScreen, item, } = this.state;

    const PrintScreen = ({ printScreen, view, map }) => {

      if (printScreen === true) {
        loadModules([])
          .then(([]) => {


            view.takeScreenshot({
              format: "png",
              quality: 70
            }).then(async (screenshot) => {

             

              const storage = getStorage();
              const storageRef = ref(storage, `screenshotMap/${item.key || item.tempKey}`);

              const blob = await (await fetch(screenshot.dataUrl)).blob();

              const file = new File([blob], 'fileName.jpg', { type: "image/jpeg", lastModified: new Date() });
            

              const uploadTask = uploadBytesResumable(storageRef, file);
              uploadTask.on('state_changed',
                (snapshot) => {
                

                  switch (snapshot.state) {
                    case 'paused':

                      break;
                    case 'running':

                      break;
                  }
                },
                (error) => {
                  console.log(error)
                },
                () => {
                  getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                   
                    this.props.sendImage(downloadURL)


                  });
                }
              );







            })


          })
      }


    }

    const TestView = ({ view }) => {
      loadModules([])
        .then(([]) => {

          view.on("drag", (event) => {
            // event is the event handle returned after the event fires.

            this.handleCheck()
          });
          view.on("resize", (event) => {
            // event is the event handle returned after the event fires.

            this.handleCheck()
          });
        })
    }

    return (
      <Map className="Map" style={{ width: '100%', height: '400px', borderRadius: 16, }}
        mapProperties={{ basemap: 'arcgis-imagery' }}

        viewProperties={{
          center: [account.lng, account.lat],
          zoom: 16,
          ui: ['zoom', 'sketch']

        }}>

        <TestView view={this.view} />

        <SketchLayer map={this.map} view={this.view} layerColor={layerColor}
          graphics={graphics} layer="productArea"
          handleCheck={this.handleCheck}

          updateGraphics={(string) => {
            this.props.updateGraphics(string);
            this.handleCheck()

          }}
        />

        <PrintScreen printScreen={printScreen} map={this.map} view={this.view} />



      </Map>
    );
  }
} 