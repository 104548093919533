import React, { useState, useEffect } from "react";


import { faCheckCircle, faCircle } from '@fortawesome/free-regular-svg-icons';
import { faArrowRightFromBracket, faArrowsSpin, faToggleOff, faToggleOn, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Button, Checkbox, Drawer, IconButton, Input, MenuItem, OutlinedInput, Select, Tooltip, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { Col, Row } from 'antd';
import moment from 'moment-timezone';
import StreamDrawerMore from "./drawerComponents/streamDrawerMore";
import './streamList.css';

import SendIcon from '@mui/icons-material/Send';
import CommentsSection from "../../tasks/components/drawercomponents/comments";
import CustomHeadersSetup from "./drawerComponents/customHeadersSetup";
import ProbeSelect from "./drawerComponents/probeSelect";
import NumberInput from "./drawerComponents/numberInput";
import StringNone from "./drawerComponents/stringNone";
import HydroIDInput from "./drawerComponents/hydroIDInput";
import Map from "./drawerComponents/map";
import StringInput from "./drawerComponents/stringInput";
import CameraSelect from "./drawerComponents/cameraSelect";
import CameraName from "./drawerComponents/cameraName";
import CameraPhotoPreview from "./drawerComponents/cameraPhotosPreview";
import { getObscapeWeatherData } from "./obscapeWeatherComponents/obscapeFunctions";
import ProbeKeySelect from "./drawerComponents/probeKeySelect";


const useStyles = makeStyles({
    underline: {
        "&:before": {
            borderBottom: "none", // Remove default underline
        },
        "&:after": {
            borderBottom: "none", // Remove default underline when focused
        },
        "&:hover:before": {
            borderBottom: "1px solid rgba(0, 0, 0, 0.42)", // Add underline on hover
        },
    },
    commentBox: {
        "&:before": {
            borderBottom: "none", // Remove default underline
        },
        "&:after": {
            borderBottom: "none", // Remove default underline when focused
        },
        "&:hover:before": {
            borderBottom: "none", // Add underline on hover
        },
    },
});

const StreamDrawer = (
    {

        streamDrawer,
        setStreamDrawer,
        screenWidth,
        currentStream,
        setCurrentStream,
        items,
        setItems,
        userInfo,
        openSnack,
        createStream,
        updateStream,
        duplicateStream,
        deleteStream,
        convertToTemplate,
        headers,
        setHeaders,
        streamTemplates,
        setStreamTemplates,
        stocks,
        setStocks,
        ProbeParameters,
        hydroProbes,
        loadingProbes,
        prefKey,
        getNestedProperty,
        setPreviewChart,
        setGetHistoricalData,
        keys

    }

) => {
    const [newItem, setNewItem] = useState(false);
    const [newItemType, setNewItemType] = useState('product');
    const [itemModal, setItemModal] = useState(false);
    const [openMorePopover, setOpenMorePopover] = useState(false);
    const [anchoreMoreEl, setAnchorMoreEl] = useState(null);
    const [validationErrors, setValidationErrors] = useState({});
    const [comment, setComment] = useState("");
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [cameras, setCameras] = useState([]);
    const [loadingCameras, setLoadingCameras] = useState(false);
    const classes = useStyles();


    useEffect(() => {
        console.log(comment)
    }, [comment])


    const validateForm = () => {
        const errors = {};

        // Validate name
        if (!currentStream?.name) {
            errors.name = 'Name is required';
            openSnack('error', 'Name is required');
        }
        // Validate name
        if (prefKey === 'probe' && !currentStream?.hydroID) {
            errors.name = 'ID is required';
            openSnack('error', 'ID is required');
        }
        // Validate name
        if (prefKey === 'probe' && [undefined, null, ''].includes(currentStream?.api_key)) {
            errors.name = 'API Key is required for probe';
            openSnack('error', 'API Key is required for probe');
        }



        setValidationErrors(errors);
        return Object.keys(errors).length === 0; // return true if no errors
    };



    const updateComment = (commentIndex, updatedText) => {
        // Clone the current stream to avoid mutating state directly
        const streamClone = { ...currentStream };

        // Check if comment exists at the specified index
        if (streamClone.comments && streamClone.comments[commentIndex]) {
            // Update the comment text
            streamClone.comments[commentIndex].text = updatedText;
            streamClone.comments[commentIndex].lastEdited = moment().format('x');
        }

        // Update the current stream state
        setCurrentStream(streamClone);
    };


    const deleteComment = (commentIndex) => {
        // Clone the current stream to avoid mutating state directly
        const streamClone = { ...currentStream };

        // Check if comment exists at the specified index and remove it
        if (streamClone.comments && streamClone.comments[commentIndex]) {
            streamClone.comments.splice(commentIndex, 1);
        }

        // Update the current stream state
        setCurrentStream(streamClone);
    };

    const createComment = (newComment) => {
        // Clone the current stream to avoid mutating state directly
        const streamClone = { ...currentStream };
        newComment.createdAt = moment().format('x');
        newComment.author = userInfo?.id;
        newComment.authorName = userInfo?.firstName + ' ' + userInfo?.lastName;
        newComment.authorInitial = userInfo?.firstName.charAt(0).toUpperCase();
        newComment.lastEdited = moment().format('x');


        // If comments array doesn't exist, initialize it
        if (!streamClone.comments) {
            streamClone.comments = [];
        }

        // Add the new comment to the array
        streamClone.comments.push(newComment);

        // Update the current stream state
        setCurrentStream(streamClone);
    };




    const handleSubmit = () => {
        if (validateForm()) {
            if (currentStream?.id === undefined) {
                createStream(currentStream);
                setStreamDrawer(false)
                setComment('')
            } else {
                updateStream(currentStream);
                setStreamDrawer(false)
                setComment('')
            }
        }
    };

    const firstLetterUser = userInfo?.firstName?.charAt(0).toUpperCase();

    const customHeaders = headers?.filter(header => !['name', 'probe', 'hydroName', 'hydroID', 'api_key'].includes(header.id))

    const handleClickOpen = () => {
        setDeleteDialog(true);
    };

    const handleClose = () => {
        setDeleteDialog(false);
    };



    return (
        <Drawer
            anchor={'right'}
            onClose={() => {
                setStreamDrawer(false)
                setComment('')
            }}
            open={streamDrawer !== false}


        >
            <div
                style={{
                    height: '100vh',
                    position: 'relative',

                    width: screenWidth === 'xs' ? '90vw' : '40vw',
                }}
            >
                <div
                    style={{
                        padding: 10,
                        paddingTop: 15,
                        paddingBottom: 5,
                        boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.55)',
                        height: '10vh',
                        display: 'flex',
                        width: '100%',
                        alignContent: 'center',
                        alignItems: 'center',

                    }}
                >
                    <Row
                        align={'bottom'}
                        style={{
                            width: '100%',
                            display: 'flex',
                            //align items to the bottom of the row


                        }}>
                        <Col span={12}>
                            <Button color={currentStream?.inactive ? 'primary' : 'inherit'} variant={currentStream?.inactive ? 'contained' : 'outlined'}
                                style={{
                                    height: 40,
                                    padding: 1,
                                    paddingRight: 10,
                                    paddingLeft: 10,
                                    textTransform: 'none',
                                    color: 'black',
                                    border: currentStream?.inactive ? '2px solid orange' : '2px solid #19d319',
                                    backgroundColor: 'white',
                                }}
                                onClick={() => {
                                    setCurrentStream({ ...currentStream, inactive: !currentStream?.inactive, inactiveBy: userInfo?.id, inactiveOn: moment().format('x') })
                                }}>
                                <FontAwesomeIcon size="2xl" icon={currentStream?.inactive ? faToggleOff : faToggleOn} style={{
                                    color: currentStream?.inactive ? 'orange' : '#19d319',
                                    marginRight: 10
                                }} />

                                {currentStream?.inactive ? 'Inactive' : 'Active'}
                            </Button>
                        </Col>
                        {currentStream?.id !== undefined && (
                            <Col xs={12} style={{
                                textAlign: 'right',
                                marginTop: 10,
                                display: 'inline'
                            }} >


                                {/* <IconButton
                                    style={{
                                        marginRight: 8
                                    }}
                                    onClick={async () => {
                                        const results = await getObscapeWeatherData(userInfo, currentStream)
                                        console.log(results)
                                    }}
                                >
                                    <Tooltip title="Refresh" placement="bottom">
                                        <FontAwesomeIcon size="sm" icon={faArrowsSpin} style={{ color: 'rgba(0,0,0,0.5)' }} />
                                    </Tooltip>

                                </IconButton> */}
                                <IconButton
                                    style={{
                                        marginRight: 8
                                    }}
                                    onClick={() => {
                                        handleClickOpen()
                                    }}
                                >
                                    <Tooltip title="Delete Stream" placement="bottom">
                                        <FontAwesomeIcon size="sm" icon={faTrash} style={{ color: 'rgba(0,0,0,0.5)' }} />
                                    </Tooltip>

                                </IconButton>

                                <StreamDrawerMore
                                    prefKey={prefKey}
                                    setPreviewChart={setPreviewChart}
                                    duplicateStream={duplicateStream}
                                    currentStream={currentStream}
                                    setGetHistoricalData={setGetHistoricalData}
                                    convertToTemplate={() => convertToTemplate(currentStream)}

                                />
                                <IconButton
                                    onClick={() => {
                                        setCurrentStream(null)
                                        setStreamDrawer(false)
                                    }}
                                >
                                    <Tooltip title="Close" placement="bottom">
                                        <FontAwesomeIcon size="sm" icon={faArrowRightFromBracket} style={{ color: 'rgba(0,0,0,0.5)', }} />
                                    </Tooltip>

                                </IconButton>


                            </Col>
                        )}

                        <Dialog
                            open={deleteDialog}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                Are you sure you want to delete this stream?
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Deleting these streams will permanently remove them.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button style={{
                                    textTransform: 'none',
                                }} onClick={handleClose}>Cancel</Button>
                                <Button
                                    style={{
                                        fontWeight: 600,
                                        textTransform: 'none',
                                        color: 'red'
                                    }}

                                    onClick={() => {
                                        deleteStream(currentStream)
                                        handleClose();
                                    }}
                                    autoFocus>
                                    Delete
                                </Button>
                            </DialogActions>
                        </Dialog>


                    </Row>




                </div>
                <div
                    style={{
                        padding: 15,

                        height: '60vh',
                        display: 'flex',
                        width: '100%',

                        overflowY: 'auto',
                        overflowX: 'hidden',
                    }}
                >
                    <Row gutter={[4, 4]} style={{
                        paddingBottom: 20,
                    }}>
                        <Col span={24}>



                            <Input
                                maxRows={4}
                                multiline
                                fullWidth
                                placeholder="Enter Stream Name"
                                style={{
                                    fontWeight: 600,
                                    fontSize: 28,
                                    color: "black",
                                }}
                                autoFocus
                                notched={false}
                                label="none"
                                defaultValue={currentStream?.name || ""}
                                onChange={(e) => setCurrentStream({ ...currentStream, name: e.target.value })}
                                classes={{ underline: classes.underline }} // Apply custom styles here
                            />
                        </Col>


                        {prefKey === 'probe' && (<Col key={'probe'} span={24} style={{ paddingTop: 10 }}>
                            <Row align="middle" gutter={[12, 12]}>
                                <Col xs={7} sm={5}>
                                    <Typography variant='body1' fontWeight={500} color={'rgba(0,0,0,0.6)'} fontSize={13}>
                                        Select Probe
                                    </Typography>
                                </Col>
                                <Col xs={16} sm={17}>

                                    <ProbeSelect loadingProbes={loadingProbes} hydroProbes={hydroProbes} headerKey={'probe'} currentStream={currentStream} setCurrentStream={setCurrentStream} userInfo={userInfo} />


                                </Col>

                            </Row>
                        </Col>
                        )}
                        {prefKey === 'probe' && (<Col key={'probe'} span={24} style={{ paddingTop: 10 }}>
                            <Row align="middle" gutter={[12, 12]}>
                                <Col xs={7} sm={5}>
                                    <Typography variant='body1' fontWeight={500} color={'rgba(0,0,0,0.6)'} fontSize={13}>
                                        Select API Key
                                    </Typography>
                                </Col>
                                <Col xs={16} sm={17}>

                                    <ProbeKeySelect keys={keys} openSnack={openSnack}
                                        loadingProbes={loadingProbes} headerKey={'api'} currentStream={currentStream} setCurrentStream={setCurrentStream} />


                                </Col>

                            </Row>
                        </Col>
                        )}
                        {prefKey === 'probe' && (<Col key={'hydroID'} span={24} style={{ paddingTop: 10 }}>
                            <Row align="middle" gutter={[12, 12]}>
                                <Col xs={7} sm={5}>
                                    <Typography variant='body1' fontWeight={500} color={'rgba(0,0,0,0.6)'} fontSize={13}>
                                        Hydro ID
                                    </Typography>
                                </Col>
                                <Col xs={16} sm={17}>
                                    <HydroIDInput hydroProbes={hydroProbes} headerKey={'hydroID'} currentStream={currentStream} setCurrentStream={setCurrentStream} />

                                </Col>

                            </Row>
                        </Col>
                        )}
                        {prefKey === 'probe' && (<Col key={'hydroName'} span={24} style={{ paddingTop: 20 }}>
                            <Row align="middle" gutter={[12, 12]}>
                                <Col xs={7} sm={5}>
                                    <Typography variant='body1' fontWeight={500} color={'rgba(0,0,0,0.6)'} fontSize={13}>
                                        Hydro Name
                                    </Typography>
                                </Col>
                                <Col xs={16} sm={17}>
                                    <StringNone headerKey={'hydroName'} currentStream={currentStream} setCurrentStream={setCurrentStream} />

                                </Col>

                            </Row>
                        </Col>
                        )}
                        {prefKey === 'camera' && (<Col key={'cameraAPI'} span={24} style={{ paddingTop: 10 }}>
                            <Row align="middle" gutter={[12, 12]}>
                                <Col xs={7} sm={5}>
                                    <Typography variant='body1' fontWeight={500} color={'rgba(0,0,0,0.6)'} fontSize={13}>
                                        Username
                                    </Typography>
                                </Col>
                                <Col xs={16} sm={17}>
                                    <StringInput headerKey={'username'} currentStream={currentStream} setCurrentStream={setCurrentStream} />

                                </Col>

                            </Row>
                        </Col>
                        )}
                        {prefKey === 'camera' && (<Col key={'password'} span={24} style={{ paddingTop: 10 }}>
                            <Row align="middle" gutter={[12, 12]}>
                                <Col xs={7} sm={5}>
                                    <Typography variant='body1' fontWeight={500} color={'rgba(0,0,0,0.6)'} fontSize={13}>
                                        Password
                                    </Typography>
                                </Col>
                                <Col xs={16} sm={17}>
                                    <StringInput headerKey={'password'} currentStream={currentStream} setCurrentStream={setCurrentStream} />

                                </Col>

                            </Row>
                        </Col>
                        )}

                        {prefKey === 'camera' && (<Col key={'cameraSelect'} span={24} style={{ paddingTop: 10 }}>
                            <Row align="middle" gutter={[12, 12]}>
                                <Col xs={7} sm={5}>
                                    <Typography variant='body1' fontWeight={500} color={'rgba(0,0,0,0.6)'} fontSize={13}>
                                        Select Camera
                                    </Typography>
                                </Col>
                                <Col xs={16} sm={17}>
                                    <CameraSelect loadingCameras={loadingCameras} headerKey={'camera'} currentStream={currentStream} setCurrentStream={setCurrentStream} />

                                </Col>

                            </Row>
                        </Col>
                        )}
                        {prefKey === 'camera' && (<Col key={'cameraName'} span={24} style={{ paddingTop: 10 }}>
                            <Row align="middle" gutter={[12, 12]}>
                                <Col xs={7} sm={5}>
                                    <Typography variant='body1' fontWeight={500} color={'rgba(0,0,0,0.6)'} fontSize={13}>
                                        Camera
                                    </Typography>
                                </Col>
                                <Col xs={16} sm={17}>
                                    <CameraName getNestedProperty={getNestedProperty} headerKey={'name'} currentStream={currentStream} setCurrentStream={setCurrentStream} />

                                </Col>

                            </Row>
                        </Col>
                        )}
                        {prefKey === 'camera' && (<Col key={'photoPreview'} span={24} style={{ paddingTop: 10 }}>
                            <Row align="middle" gutter={[12, 12]}>
                                <Col xs={7} sm={5}>
                                    <Typography variant='body1' fontWeight={500} color={'rgba(0,0,0,0.6)'} fontSize={13}>
                                        Photo Preview
                                    </Typography>
                                </Col>
                                <Col xs={16} sm={17}>
                                    <CameraPhotoPreview screenWidth={screenWidth} headerKey={'photoPreview'} currentStream={currentStream} setCurrentStream={setCurrentStream} />
                                </Col>

                            </Row>
                        </Col>
                        )}



                        <CustomHeadersSetup customHeaders={customHeaders} setCurrentStream={setCurrentStream} currentStream={currentStream}
                            ProbeParameters={ProbeParameters}
                            hydroProbes={hydroProbes}
                            userInfo={userInfo}
                            loadingProbes={loadingProbes}
                            prefKey={prefKey}
                        />




                        {['probe', 'wunderground', 'obscapeWeatherStation'].includes(prefKey) && (<Col key={'hydroName'} span={24} style={{ paddingTop: 20 }}>
                            <Row align="middle" gutter={[12, 12]}>
                                <Col xs={24} sm={5}>
                                    <Typography variant='body1' fontWeight={500} color={'rgba(0,0,0,0.6)'} fontSize={13}>
                                        Map
                                    </Typography>
                                </Col>
                                <Col xs={24} sm={17}>
                                    {currentStream?.id && (<Map headerKey={'map'} currentStream={currentStream} setCurrentStream={setCurrentStream} prefKey={prefKey} />)}

                                </Col>

                            </Row>
                        </Col>
                        )}





                        <Col span={24} style={{ paddingTop: 15, paddingBottom: 40, }}>
                            <Row align="middle" gutter={[12, 12,]}>
                                <Col xs={24} sm={24}>
                                    <Typography variant='body1' fontWeight={500} color={'rgba(0,0,0,0.6)'} fontSize={13}>Description</Typography>
                                </Col>
                                <Col xs={24} sm={24}>
                                    <OutlinedInput value={currentStream?.description || ''}
                                        style={{
                                            fontWeight: 500,
                                            fontSize: 14,
                                        }}

                                        onChange={(e) => {
                                            setCurrentStream({ ...currentStream, description: e.target.value })
                                        }}
                                        fullWidth placeholder='Description' notched={false} label="none" multiline={true} rows={3} />
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24} style={{ paddingTop: 15, paddingBottom: 40, }}>
                            <Row align="middle" gutter={[12, 12,]}>
                                <Col xs={24} sm={24}>
                                    <Typography variant='body1' fontWeight={500} color={'rgba(0,0,0,0.6)'} fontSize={13}>Comments</Typography>
                                </Col>
                                <Col xs={24} sm={24}>
                                    <CommentsSection
                                        comments={currentStream?.comments}
                                        setCurrentStream={setCurrentStream}
                                        currentStream={currentStream}
                                        updateComment={updateComment}
                                        deleteComment={deleteComment}

                                    />
                                </Col>
                            </Row>
                        </Col>




                    </Row>
                </div>
                <div
                    style={{
                        padding: 15,
                        height: '30vh',
                        width: '100%',
                        boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.55)',
                        backgroundColor: '#f9f8f8'
                    }}
                >
                    <div style={{ width: '100%' }}>
                        <Typography variant='body1' fontWeight={600} color={'rgba(0,0,0,0.6)'} fontSize={18}>Comments</Typography>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault(); // Prevent the default form submission behavior

                                // Call your submit function here
                                createComment({ text: comment });
                                setComment("");
                            }}
                            style={{
                                marginTop: 10,
                                width: '100%',
                                display: 'inline-flex',
                            }}
                        >
                            <Avatar sx={{
                                bgcolor: '#3f51b5',
                                marginRight: 1,
                            }}>
                                {firstLetterUser}
                            </Avatar>
                            <OutlinedInput
                                maxRows={4}
                                rows={4}
                                multiline
                                fullWidth
                                placeholder="Add a comment"
                                style={{
                                    padding: 10,
                                    background: 'white',
                                    borderRadius: 5,
                                    fontWeight: 500,
                                    fontSize: 15,
                                    color: "black",
                                }}
                                notched={false}
                                label="none"
                                value={comment}
                                onChange={(e) => {
                                    setComment(e.target.value);
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' && !e.shiftKey) {
                                        e.preventDefault();
                                        createComment({ text: comment });
                                        setComment("");
                                    }
                                }}
                                endAdornment={
                                    comment && (
                                        <IconButton
                                            type="submit" // This button will now submit the form when clicked
                                            size="large"
                                        >
                                            <SendIcon />
                                        </IconButton>
                                    )
                                }
                            />
                        </form>
                    </div>

                    <div
                        style={{
                            marginTop: 10,
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button color={'primary'} variant={'contained'}
                            style={{
                                height: 40,
                                textTransform: 'none',
                                backgroundColor: '#3f51b5'
                            }}
                            onClick={handleSubmit}
                        >
                            {currentStream?.id !== undefined ? 'Update Stream' : 'Create Stream'}
                        </Button>
                    </div>
                </div>

            </div>







        </Drawer >
    );
};

export default StreamDrawer;
