

import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Container,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import React from 'react';


const ValueCondition = ({
    condition,
    conditionIndex,
    selectedItems,
    setSelectedItems,
    item


}) => {





    return (
        <Box>
            <Typography variant='body1' color={'black'} fontWeight={500} fontSize={13} style={{ display: 'inline', paddingBottom: 8, paddingTop: 8 }}>
                Values
            </Typography>
            <Select
                style={{ marginLeft: 5 }}
                sx={{
                    width: 60,
                    fontWeight: 700,
                }}
                size="small"
                variant="outlined"
                value={condition.type}
                onChange={(e) => {
                    const newSelectedItems = { ...selectedItems };
                    if (newSelectedItems.hasOwnProperty(item.key)) {
                        newSelectedItems[item.key].conditions = newSelectedItems[item.key].conditions.map((c, cIdx) =>
                            cIdx === conditionIndex ? { ...c, type: e.target.value } : c
                        );
                    }
                    setSelectedItems(newSelectedItems);


                }}
            >
                <MenuItem value={'>'}>&gt;</MenuItem>
                <MenuItem value={'<'}>&lt;</MenuItem>
                <MenuItem value={'='}>=</MenuItem>
                <MenuItem value={'!='}>&ne;</MenuItem>
                <MenuItem value={'<='}>&le;</MenuItem>
                <MenuItem value={'>='}>&ge;</MenuItem>
            </Select>
            <OutlinedInput
                size="small"
                style={{ marginLeft: 5 }}
                sx={{
                    width: 90,
                }}
                type="number"
                value={condition.value}
                onChange={(e) => {
                    const newSelectedItems = { ...selectedItems };
                    if (newSelectedItems.hasOwnProperty(item.key)) {
                        newSelectedItems[item.key].conditions = newSelectedItems[item.key].conditions.map((c, cIdx) =>
                            cIdx === conditionIndex ? { ...c, value: e.target.value } : c
                        );
                    }
                    setSelectedItems(newSelectedItems);
                }}
            />
        </Box>
    );
};

export default ValueCondition;
