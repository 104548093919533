import { Autocomplete, Button, OutlinedInput, TextField, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import { doc, setDoc, getFirestore } from 'firebase/firestore';
import React from 'react';
import { fullMobile } from './util';





var randomColor = require('randomcolor');

const CreateStockForm = ({ stock, setStock, stocks, setStocks, accounts, setAccounts, setAddStockModal, userInfo, type, updateRecord, record }) => {
 


    const db = getFirestore()


    return (




        <Row gutter={[4, 4]} align="middle" style={{}} >
            <Col style={{}}>
                <Typography variant='h6' fontWeight={700} display={"inline"}>
                    Create Stock
                </Typography>

            </Col>



            <Col {...fullMobile(24, 24)}  >
                <Row >
                    <Col {...fullMobile(24, 24)} style={{ paddingTop: 15 }}  >
                        <Typography variant="body2" display="inline" fontWeight={600} fontSize={14} style={{ color: '#404050' }}>
                            Stock Name<span style={{ color: 'red' }}>*</span>
                        </Typography>
                    </Col>
                    <Col {...fullMobile(24, 24)}  >
                        <OutlinedInput
                            size="small"
                            style={{ width: 300 }}
                            id="outlined-adornment-amount"
                            value={stock?.name}
                            onChange={(e) => {
                                setStock({ ...stock, name: e.target.value })
                            }}

                        />
                    </Col>
                    <Col {...fullMobile(24, 24)} style={{ paddingTop: 15 }} >
                        <Typography variant="body2" display="inline" fontWeight={600} fontSize={14} style={{ color: '#404050' }}>
                            Description
                        </Typography>
                    </Col>
                    <Col {...fullMobile(24, 24)}  >
                        <OutlinedInput
                            size="small"
                            style={{ width: 300 }}
                            multiline
                            rows={2}
                            id="outlined-adornment-amount"
                            value={stock?.description}
                            onChange={(e) => {
                                setStock({ ...stock, description: e.target.value, label: e.target.value })
                            }}

                        />
                    </Col>
                    <Col {...fullMobile(24, 24)} style={{ paddingTop: 15 }} >
                        <Typography variant="body2" display="inline" fontWeight={600} fontSize={14} style={{ color: '#404050' }}>
                            Location<span style={{ color: 'red' }}>*</span>
                        </Typography>
                    </Col>
                    <Col {...fullMobile(24, 24)}  >
                        <Autocomplete
                            size="small"
                            style={{ width: 300 }}
                            disablePortal
                            id="combo-box-demo"
                            //use accountName as value
                            getOptionLabel={(option) => option.accountName}
                            value={stock.location || null}
                            options={accounts?.sort((a, b) => (a.accountName > b.accountName) ? 1 : -1) || []}
                            renderInput={(params) =>
                                <TextField {...params}
                                    placeholder="Select Location"
                                />}
                            onChange={(a, b) => {
                                setStock({
                                    ...stock,
                                    location: {
                                        ...b,
                                        label: b.accountName,
                                    }
                                })
                            }}

                        />
                    </Col>

                    <Col {...fullMobile(24, 24)} style={{ paddingTop: 25 }}  >
                        {
                            stock?.location !== null &&
                            stock.name !== '' && (
                                <Button variant='contained'
                                    color='primary'
                                    style={{ textTransform: 'none', backgroundColor: '#404050', }}
                                    onClick={async () => {
                                        console.log(userInfo)
                                        const newStock = { ...stock, label: stock.name }
                                        console.log(newStock)
                                        const docRef = doc(db, "clients", userInfo.currentCompany, 'productStock', newStock.key || newStock.tempKey);
                                        await setDoc(docRef, newStock).then(async () => {
                                            setAddStockModal(false)
                                            setStock({})
                                            //update stocks
                                            const newStocks = [...stocks, newStock]

                                            setStocks(newStocks)
                                            if (type === 'fieldLog') {
                                                console.log("CREATE AND SELECT NEW STOCK")
                                                console.log(newStock.key)
                                                updateRecord({ ...record, stock: newStock.key })
                                            }
                                        }).catch((error) => {
                                            console.error("Error adding document: ", error);
                                        });
                                    }}
                                >
                                    Create Stock
                                </Button>


                            )

                        }
                    </Col>

                </Row>
            </Col>


















        </Row>

    );
}

export default CreateStockForm;