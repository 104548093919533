import React, { useState, useRef } from "react";
import { Box, Breadcrumbs, Button, ClickAwayListener, TableRow, TableCell, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, List, ListItem, Popper, TextField, ThemeProvider, Typography, createTheme, Checkbox, TableHead } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEllipsisV, faFileAlt, faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { useDrop, useDrag } from 'react-dnd';
import { Row } from "antd";
import { moveFile } from "../../firebase/firestore";
import { faFile } from "@fortawesome/free-regular-svg-icons";

const YourCustomHeadComponent = ({ onSelectAll, selected }) => {
    
    return (
        <TableCell style={{ width: 60, textAlign: 'center' }}>
            <Checkbox
                checked={selected}
                onChange={(e) => onSelectAll(e.target.checked)}
            />
        </TableCell>
    );
};

export default YourCustomHeadComponent;