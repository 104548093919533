import { Map } from '@esri/react-arcgis';
import { Button, CircularProgress, OutlinedInput, Typography } from '@mui/material';
import { Col, Modal, Row } from 'antd';
import { doc, getFirestore, setDoc, updateDoc } from 'firebase/firestore';
import moment from 'moment';
import React from 'react';
import uuid from 'react-uuid';
import Location from '../../blocks/gis/location';
import RelocateMap from '../../blocks/gis/relocateMap';
import SearchBar from '../../blocks/gis/searchBar';
import ZoomIcon from '../../blocks/gis/zoomIcon';
import "../../extra/hoverStyle.css";
import axios, * as others from 'axios';
import queryString from 'query-string';

export default class GaugeModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            data: [],
            filteredData: [],
            filters: [],
            searchText: '',
            loading: true,
            type: this.props.type,
            currentItem: this.props.currentItem,
            openModal: this.props.openModal,
            nextPage: '',
            token: '',
            searchResults: '',
            searching: false

        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
        if (this.props.openModal !== prevProps.openModal) {
            this.setState({ openModal: this.props.openModal })
        }
        if (this.props.currentItem !== prevProps.currentItem) {
            this.setState({ currentItem: this.props.currentItem })
        }

    }
    componentDidMount() {
        const { userInfo, account } = this.state;



    }








    updateCurrentItem = (boo, val) => {
        const { currentItem } = this.state;
        currentItem[boo] = val;
        this.setState({ currentItem })
    }


    handleCheck = (val,) => {
        this.setState({ searching: true })
        // Clears running timer and starts a new one each time the user types
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.toggleCheck(val,);
        }, 1000);
    }

    toggleCheck = async (val) => {
        this.checkStation(val)
    }

    checkStation = async (val) => {
        const { currentItem } = this.state;
        const proxyUrl = "https://mycorslake.herokuapp.com/";
        const apiToken = 'kbWXuKcbrYjwGBpZUWFigDdjhSwwwvuy';
        const endpoint = `stations/`
        const location = val
        const baseUrl = `https://www.ncei.noaa.gov/cdo-web/api/v2/${endpoint}/${location}`;
        axios.get(proxyUrl + baseUrl, {
            headers: {
                'token': apiToken
            }
        })

            .then((response) => {
                console.log(response.data)
                //setstate searching false

                if (response.data !== undefined) {
                    currentItem['id'] = response.data.id;
                    currentItem['gaugeName'] = response.data.name;
                    currentItem['lat'] = response.data.latitude;
                    currentItem['lng'] = response.data.longitude;
                    this.setState({ searching: false, searchResults: 'See gauge information.' })
                    console.log(currentItem)

                    this.updateMap(currentItem.lat, currentItem.lng)


                }
            }
            )
            .catch((error) => {
                console.log(error)
                this.setState({ searching: false, searchResults: 'No station found.' })
            })



    }

    getGaugeData = async (id) => {
        const proxyUrl = "https://mycorslake.herokuapp.com/";
        const apiToken = 'kbWXuKcbrYjwGBpZUWFigDdjhSwwwvuy';
        const endpoint = `data`
        const datasetid = "GHCND"
        const baseUrl = `https://www.ncei.noaa.gov/cdo-web/api/v2/${endpoint}`;


        axios.get(proxyUrl + baseUrl, {
            headers: {
                'token': apiToken
            },
            params: {
                units: 'standard',
                datasetid: datasetid,
                stationid: id,
                startdate: moment().subtract(12, 'months').startOf('day').format('YYYY-MM-DD'),
                enddate: moment().endOf('day').format('YYYY-MM-DD'),
            }



        })
            .then((response) => {
                console.log(response.data)
            }
            )
            .catch((error) => {
                console.log(error)
            })

    }

    updateMap = (lat, lng) => {
        const { currentItem } = this.state;

        currentItem.gps = [lng, lat];

        this.setState({ currentItem })
    }



    render() {

        const { userInfo, account, data, filteredData, loading, searchText, filters, type, currentItem,
            accounts, openModal, screenWidth, searching, searchResults } = this.state;
        const db = getFirestore();





        const CreateSite = () => {
            return (
                <Button size="small" style={{ padding: 10, borderRadius: 4, textTransform: 'none', fontSize: 14, }}
                    variant='contained' color="primary"
                    onClick={async () => {
                        const { currentItem } = this.state;

                        currentItem.name = currentItem.name || "";




                        const checks = [
                            currentItem.name.length === 0 ? false : true,

                        ]

                        if (currentItem.name.length === 0) {
                            this.props.openSnack('error', "Gauge name required!")
                        }


                        if (!checks.includes(false)) {


                            const key = currentItem.key || currentItem.tempKey;
                            currentItem.archived = currentItem.archived || false;

                            currentItem.account = userInfo.currentAccount;
                            currentItem.company = userInfo.currentCompany;


                            const docRef = doc(db, "clients", userInfo.currentCompany, 'rainGauges', key);

                            if (currentItem.key) {
                                await updateDoc(docRef, currentItem).then(() => {
                                    this.props.openSnack('success', currentItem.key ? 'Gauge updated!' : "Gauge created!")
                                })
                            }
                            else {
                                await setDoc(docRef, {
                                    ...currentItem,
                                    key: currentItem.tempKey
                                }).then(() => {
                                    this.props.openSnack('success', currentItem.key ? 'Gauge updated!' : "Gauge created!")
                                    this.props.onClose()
                                }).then(async () => {

                                   

                                    const proxyUrl = "https://mycorslake.herokuapp.com/";
                                    const apiToken = 'kbWXuKcbrYjwGBpZUWFigDdjhSwwwvuy';
                                    const endpoint = `data`;
                                    const datasetid = "GHCND";
                                    const baseUrl = `https://www.ncei.noaa.gov/cdo-web/api/v2/${endpoint}`;

                                    function createArray() {
                                        const result = [];
                                        for (let i = 11; i >= 0; i--) {
                                            result.push(moment().subtract(i, 'months').startOf('month').format('YYYY-MM-DD'));
                                        }
                                        return result;
                                    }

                                    const monthsArray = createArray();

                                    for (const month of monthsArray) {
                                        const response = await axios.get(proxyUrl + baseUrl, {
                                            headers: { 'token': apiToken },
                                            params: {
                                                units: 'standard',
                                                datasetid: datasetid,
                                                stationid: currentItem.stationID,
                                                startdate: month,
                                                enddate: moment(month).endOf('month').format('YYYY-MM-DD'),
                                            }
                                        }).catch((error) => console.log(error));

                                        if (response && response.data && response.data.results) {

                                            const dataItems = response.data.results.map((item) => {
                                                var time = moment(item.date, 'YYYY-MM-DD HH:mm:ss').tz("America/Los_Angeles").format("YYYY-MM-DD HH:mm");
                                                return ({
                                                    time: `'${time}'`,
                                                    account: `'${userInfo.currentAccount}'`,
                                                    company: `'${userInfo.currentCompany}'`,
                                                    key: `'${uuid()}'`,
                                                    datatype: `'${item.datatype}'`,
                                                    station: `'${item.station}'`,
                                                    attributes: `'123'`,
                                                    value: Number(item.value),
                                                    lat: currentItem.lat,
                                                    lng: currentItem.lng
                                                })
                                            });



                                            const hourlyVals = dataItems.map((h) => `(${Object.values(h)})`);
                                            console.log(hourlyVals)
                                            const hourlyString = hourlyVals.join();

                                            await axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                                                raw: `INSERT INTO gauge_data VALUES ${hourlyString} ON CONFLICT DO NOTHING;`
                                            }).catch((error) => console.log(error));
                                        }
                                    }



                                })

                            }


                        }




                    }}>{currentItem.key ? 'Update Gauge' : "Create Gauge"}</Button>
            )
        }

        const CheckGaugeData = () => {
            return (
                <Button size="small" style={{ padding: 10, borderRadius: 4, textTransform: 'none', fontSize: 14, }}
                    variant='contained' color="primary"
                    onClick={async () => {
                        const { currentItem, userInfo } = this.state;

                        const proxyUrl = "https://mycorslake.herokuapp.com/";
                        const apiToken = 'kbWXuKcbrYjwGBpZUWFigDdjhSwwwvuy';
                        const endpoint = `data`;
                        const datasetid = "GHCND";
                        const baseUrl = `https://www.ncei.noaa.gov/cdo-web/api/v2/${endpoint}`;

                        function createArray() {
                            const result = [];
                            for (let i = 11; i >= 0; i--) {
                                result.push(moment().subtract(i, 'months').startOf('month').format('YYYY-MM-DD'));
                            }
                            return result;
                        }

                        const monthsArray = createArray();

                        for (const month of monthsArray) {
                            const response = await axios.get(proxyUrl + baseUrl, {
                                headers: { 'token': apiToken },
                                params: {
                                    units: 'standard',
                                    datasetid: datasetid,
                                    stationid: currentItem.stationID,
                                    startdate: month,
                                    enddate: moment(month).endOf('month').format('YYYY-MM-DD'),
                                }
                            }).catch((error) => console.log(error));

                            if (response && response.data && response.data.results) {

                                const dataItems = response.data.results.map((item) => {
                                    var time = moment(item.date, 'YYYY-MM-DD HH:mm:ss').tz("America/Los_Angeles").format("YYYY-MM-DD HH:mm");
                                    return ({
                                        time: `'${time}'`,
                                        account: `'${userInfo.currentAccount}'`,
                                        company: `'${userInfo.currentCompany}'`,
                                        key: `'${uuid()}'`,
                                        datatype: `'${item.datatype}'`,
                                        station: `'${item.station}'`,
                                        attributes: `'123'`,
                                        value: Number(item.value),
                                        lat: currentItem.lat,
                                        lng: currentItem.lng
                                    })
                                });



                                const hourlyVals = dataItems.map((h) => `(${Object.values(h)})`);
                                console.log(hourlyVals)
                                const hourlyString = hourlyVals.join();

                                await axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                                    raw: `INSERT INTO gauge_data VALUES ${hourlyString} ON CONFLICT DO NOTHING;`
                                }).catch((error) => console.log(error));
                            }
                        }
                    }}>Check Data</Button>
            )
        }






        return (




            <Modal title="" visible={openModal} onCancel={() => {
                this.setState({ openModal: false })
                this.props.onClose()
            }} footer={null} width={'60%'} style={{ borderRadius: 40 }} >
                <Row align="middle" gutter={[16, 16]} >
                    <Col xs={{ offset: 0, span: 24 }} sm={{ offset: 6, span: 18 }} style={{ paddingBottom: 12, }}>
                        <Typography variant='h5'>Gauge</Typography>
                    </Col>


                    <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                        <Typography variant="body1">Gauge Name<span style={{ color: 'red' }}>*</span></Typography>
                    </Col>
                    <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                        <OutlinedInput fullWidth notched={false} label="none" placeholder={"Name"} size="small"
                            value={currentItem.name || ''}
                            onChange={(e) => {
                                this.updateCurrentItem(`name`, e.target.value)
                            }} />
                    </Col>
                    <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                        <Typography variant="body1">Gauge ID<span style={{ color: 'red' }}>*</span></Typography>
                    </Col>
                    <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                        <OutlinedInput fullWidth notched={false} label="none" placeholder={"ID"} size="small"
                            value={currentItem.stationID || ''}
                            onChange={(e) => {
                                this.updateCurrentItem(`stationID`, e.target.value)

                                this.handleCheck(e.target.value)
                            }} />
                        {searching && (
                            <Typography variant="body2" style={{ color: 'red' }}><CircularProgress />Searching...</Typography>

                        )}
                        {searching === false && (
                            <Typography variant="body2" style={{ color: 'green' }}>{searchResults}</Typography>
                        )}
                    </Col>


                    {currentItem.gaugeName !== undefined && (<Col xs={24} sm={{ offset: 6, span: 18 }} >
                        <Typography variant="body1">{currentItem.gaugeName}</Typography>
                    </Col>)}

                    {currentItem.lat !== undefined && (<Col xs={24} sm={{ offset: 6, span: 18 }} >
                        <Typography variant="body1">Lat: {currentItem.lat} </Typography>
                    </Col>)}
                    {currentItem.lng !== undefined && (<Col xs={24} sm={{ offset: 6, span: 18 }} >
                        <Typography variant="body1">Lng: {currentItem.lng} </Typography>
                    </Col>)}












                    <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                        <Typography variant="body1">Description</Typography>
                    </Col>
                    <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                        <OutlinedInput fullWidth notched={false} label="none" placeholder={"Description"} size="small"
                            multiline={true}
                            rows={4}
                            value={currentItem.description || ''}
                            onChange={(e) => {
                                this.updateCurrentItem(`description`, e.target.value)
                            }} />
                    </Col>

                    <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                        <Typography variant="body1">Map</Typography>
                    </Col>
                    <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0, display: 'flex', height: 300, }}>

                        <Map className="Map" style={{ width: '100%', height: '100%', borderRadius: 16, }}
                            mapProperties={{ basemap: 'arcgis-imagery' }}

                            viewProperties={{
                                center: currentItem.gps || [account.lng, account.lat],
                                zoom: 16,
                                ui: []

                            }}>



                            <RelocateMap view={this.view} gps={currentItem.gps || [account.lng, account.lat]} />







                            <SearchBar map={this.map} view={this.view} />
                            <ZoomIcon map={this.map} view={this.view} />
                            <Location map={this.map} view={this.view} />

                        </Map>

                    </Col>




                    {currentItem.gaugeName !== undefined && (< Col xs={{ offset: 0, span: 24 }} sm={{ offset: 6, span: 18 }} style={{ paddingBottom: 12, }}>
                        <CreateSite />
                    </Col>)}
                    {currentItem.gaugeName !== undefined && (< Col xs={{ offset: 0, span: 24 }} sm={{ offset: 6, span: 18 }} style={{ paddingBottom: 12, }}>
                        <CheckGaugeData />
                    </Col>)}

                </Row>
            </Modal >





        );
    }
} 