import React, { useEffect, useState } from "react";


import { faBox, faBucket, faCheck, faFish, faFlask, faListCheck, faMagnifyingGlass, faPlus, faWheatAlt, faWrench, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, ButtonGroup, Divider, FormControl, Grid, IconButton, Input, InputLabel, MenuItem, Modal, OutlinedInput, Select, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import { makeStyles } from "@mui/styles";
import StocksSelect from "../../../../../../tasks/components/drawercomponents/stockSelect";



const StockSelection = (
    {
        record,
        items,
        setItems,
        screenWidth,
        openSnack,
        userInfo,
        updateRecord,
        stocks,
        setStocks,
        accounts,
        setDisableDrag

 



    }

) => {
    const [isFocused, setIsFocused] = useState(false);


    return (
        <>


            <Typography variant="h6" component="div" color={'rgba(0,0,0,0.8)'} fontWeight={700} fontSize={13}>
                Stock
            </Typography>
            <StocksSelect
                stocks={stocks}
                setStocks={setStocks}
                userInfo={userInfo}
                type="fieldLog"
                updateRecord={updateRecord}
                record={record}
                accounts={accounts}
                setDisableDrag={setDisableDrag}
            />







        </>



    );
};

export default StockSelection;
