import { Button, Checkbox, OutlinedInput, ThemeProvider, Typography, createTheme } from '@mui/material';
import { Col, Modal, Row } from 'antd';
import React, { useEffect, useState } from 'react';

import { getInvitation, signupUser } from './firestore';
import { fullMobile } from '../companyAdmin/util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';
import background from './backgroundImage.png'
import logo from './LTLogo.png'
import { LogoDev } from '@mui/icons-material';




const NewInvited = ({ openSnack, screenWidth }) => {
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState({ email: '' })
    let { id } = useParams();
    console.log(id)


    useEffect(() => {
        getInfo()





    }, [])

    const getInfo = async () => {
        const invitation = await getInvitation(id)
        console.log(invitation)
        if (invitation === null) {
            openSnack('error', 'Invitation not found')
            setLoading('notFound')
        }
        else {
            setUser(invitation)
            setLoading(false)

        }



    };

    let navigate = useNavigate();
    const handleSubmit = async (e) => {
        e.preventDefault();


        if (!user.firstName || !user.lastName || !user.company || !user.email || !user.password || !user.confirmPassword || !user.terms || !user.subscriptionTerms) {
            openSnack('error', 'Please fill in all required fields.');
            return;
        }

        if (user?.password?.length < 6) {
            openSnack('error', 'Password must be at least 6 characters long.');
            return;
        }

        if (user.password !== user.confirmPassword) {
            openSnack('error', 'Passwords do not match.');
            return;
        }

        try {
            setLoading('signingUp');
            console.log(user)
            const signup = await signupUser(user, navigate);
            if (signup === 'success') {
                openSnack('success', 'User created successfully');
                //refresh page
                setTimeout(() => {
                    navigate('/')
                    window.location.reload();
                }, 1000);

            }
        } catch (error) {
            console.log(error);
            openSnack('error', 'Error signing up');
        }
    };

    //create logo in the top right corner of the web page
    const Logo = () => {
        return (
            <div style={{
                position: 'absolute', top: 20, right: 20,
                width: 100, height: 100,
                backgroundImage: `url(${logo})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundAttachment: 'fixed',
                zIndex: 1000
            }} />
        )
    }





    if (loading === true) {
        return (
            <Row
                justify="center"
                align="middle"
                style={{ minHeight: '100vh', width: '100%', backgroundColor: 'white' }}
            >
                <Col span={24} style={{ textAlign: 'center' }}>
                    <FontAwesomeIcon icon={faSpinner} spin size="3x" />
                </Col>
            </Row>

        )
    }




    else {


        return (

            <Row  //in the center 
                justify="center"
                align="middle"
                style={{
                    minHeight: '100vh', width: '100%', backgroundColor: 'white',
                    backgroundImage: `url(${background})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundAttachment: 'fixed',

                }}
            >
                <div style={{
                    textAlign: 'center',
                    position: 'absolute', top: 20, left: 20,

                }}>
                    <img src={logo} style={{ height: 40 }} />
                    <Typography variant="body1" fontWeight={300} style={{ color: 'rgba(0,0,0,.6)', position: 'relative', marginTop: 0, marginBottom: 3, paddingLeft: 40 }}>
                        Your eyes on the water.
                    </Typography>
                </div>


                {loading === 'notFound' &&
                    (<Col span={screenWidth === 'xs' ? 24 : 10} style={{ backgroundColor: 'white', borderRadius: 10, boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)', }}>




                        <Row style={{ padding: 40 }} >

                            <Col {...fullMobile(24, 24)} style={{ textAlign: 'left' }}>
                                <Typography variant="h4" fontWeight={700} style={{ color: 'rgba(0,0,0,0.9)', position: 'relative' }}>
                                    Invitation not found
                                </Typography>
                                <Typography variant="body1" fontWeight={600} fontSize={15} style={{ color: '#989898', position: 'relative', marginTop: 0, marginBottom: 3 }}>
                                    Reach out to your company admin to get a new invitation
                                </Typography>


                            </Col>

                            <Col {...fullMobile(24, 24)} style={{ textAlign: 'left', paddingTop: 10 }}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    style={{
                                        textTransform: 'none',
                                        color: 'white',
                                        width: '100%', marginTop: 10,
                                        backgroundColor: '#404050'


                                    }}

                                    onClick={() => {
                                        //sign in user with email and password

                                        navigate('/')



                                    }}

                                >
                                    <Typography variant="body1" display="inline" fontWeight={700} fontSize={16} style={{ color: 'white', position: 'relative', marginTop: 0, marginBottom: 3 }}>
                                        Click to return to home page <FontAwesomeIcon icon={faArrowRight} style={{ marginLeft: 10 }} />
                                    </Typography>
                                </Button>


                            </Col>





                        </Row>
                    </Col>
                    )
                }

                {loading === 'signingUp' &&
                    (<Col span={screenWidth === 'xs' ? 24 : 10} style={{ backgroundColor: 'white', borderRadius: 10, boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)', }}>




                        <Row style={{ padding: 40 }} >

                            <Col {...fullMobile(24, 24)} style={{ textAlign: 'center' }}>
                                <FontAwesomeIcon icon={faSpinner} spin size="2x" style={{ marginRight: 10, color: 'rgba(0,0,0,0.8)' }} />
                                <Typography variant="h5" fontWeight={700} style={{ color: 'rgba(0,0,0,0.9)', position: 'relative' }}>
                                    Signing up...
                                </Typography>

                            </Col>







                        </Row>
                    </Col>
                    )
                }

                {loading === false &&
                    (<Col span={screenWidth === 'xs' ? 24 : 10} style={{ backgroundColor: 'white', borderRadius: 10, boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)', }}>



                        <form onSubmit={handleSubmit} style={{ width: '100%' }} >
                            <Row style={{ padding: 40 }} >

                                <Col {...fullMobile(24, 24)} style={{ textAlign: 'left' }}>
                                    <Typography variant="h4" fontWeight={700} style={{ color: 'rgba(0,0,0,0.9)', position: 'relative' }}>
                                        Welcome to LakeTech
                                    </Typography>


                                </Col>


                                <Col {...fullMobile(24, 24)} style={{ textAlign: 'left', paddingTop: 10 }}>
                                    <Typography variant="body1" fontWeight={600} fontSize={14} style={{ color: 'rgba(0,0,0,0.7)', position: 'relative', marginTop: 0, marginBottom: 3 }}>
                                        Email address<span style={{ color: 'red' }}>*</span>
                                        <span style={{}}> <Typography variant="body1" fontWeight={600} fontSize={14} style={{ color: '#989898', position: 'relative', marginTop: 0, marginBottom: 3 }}>
                                            {`Email has been automatically populated from your invitation and cannot be changed.`}
                                        </Typography></span>

                                    </Typography>
                                    <OutlinedInput
                                        size="small"
                                        value={user?.email}
                                        disabled={true}
                                        onChange={(e) => setUser({ ...user, email: e.target.value })}
                                        style={{ width: '100%', borderRadius: 5, }}
                                        placeholder="Email"
                                    />


                                </Col>
                                <Col {...fullMobile(24, 24)} style={{ textAlign: 'left', paddingTop: 10 }}>
                                    <Typography variant="body1" fontWeight={600} fontSize={14} style={{ color: 'rgba(0,0,0,0.7)', position: 'relative', marginTop: 0, marginBottom: 3 }}>
                                        First Name<span style={{ color: 'red' }}>*</span>
                                    </Typography>
                                    <OutlinedInput
                                        size="small"
                                        value={user?.firstName}
                                        disabled={loading === 'signingUp'}
                                        onChange={(e) => setUser({ ...user, firstName: e.target.value })}
                                        style={{ width: '100%', borderRadius: 5, }}
                                        placeholder="First Name"
                                    />


                                </Col>
                                <Col {...fullMobile(24, 24)} style={{ textAlign: 'left', paddingTop: 10 }}>
                                    <Typography variant="body1" fontWeight={600} fontSize={14} style={{ color: 'rgba(0,0,0,0.7)', position: 'relative', marginTop: 0, marginBottom: 3 }}>
                                        Last Name<span style={{ color: 'red' }}>*</span>
                                    </Typography>
                                    <OutlinedInput
                                        size="small"
                                        value={user?.lastName}
                                        disabled={loading === 'signingUp'}
                                        onChange={(e) => setUser({ ...user, lastName: e.target.value })}
                                        style={{ width: '100%', borderRadius: 5, }}
                                        placeholder="Last Name"
                                    />


                                </Col>
                                <Col {...fullMobile(24, 24)} style={{ textAlign: 'left', paddingTop: 10 }}>
                                    <Typography variant="body1" fontWeight={600} fontSize={14} style={{ color: 'rgba(0,0,0,0.7)', position: 'relative', marginTop: 0, marginBottom: 3 }}>
                                        Company<span style={{ color: 'red' }}>*</span>
                                    </Typography>
                                    <OutlinedInput
                                        size="small"
                                        value={user?.company}
                                        disabled={loading === 'signingUp'}
                                        onChange={(e) => setUser({ ...user, company: e.target.value })}
                                        style={{ width: '100%', borderRadius: 5, }}
                                        placeholder="Company"
                                    />


                                </Col>
                                <Col {...fullMobile(24, 24)} style={{ textAlign: 'left', paddingTop: 10 }}>
                                    <Typography variant="body1" fontWeight={600} fontSize={14} style={{ color: 'rgba(0,0,0,0.7)', position: 'relative', marginTop: 0, marginBottom: 3 }}>
                                        Password<span style={{ color: 'red' }}>*</span>
                                    </Typography>
                                    <OutlinedInput
                                        size="small"
                                        type="password"
                                        disabled={loading === 'signingUp'}
                                        value={user?.password}
                                        onChange={(e) => setUser({ ...user, password: e.target.value })}
                                        style={{ width: '100%', borderRadius: 5, }}
                                        placeholder="Password"
                                    />


                                </Col>
                                <Col {...fullMobile(24, 24)} style={{ textAlign: 'left', paddingTop: 10 }}>
                                    <Typography variant="body1" fontWeight={600} fontSize={14} style={{ color: 'rgba(0,0,0,0.7)', position: 'relative', marginTop: 0, marginBottom: 3 }}>
                                        Confirm Password<span style={{ color: 'red' }}>*</span>
                                    </Typography>
                                    <OutlinedInput
                                        size="small"
                                        type="password"
                                        disabled={loading === 'signingUp'}
                                        value={user?.confirmPassword}
                                        onChange={(e) => setUser({ ...user, confirmPassword: e.target.value })}
                                        style={{ width: '100%', borderRadius: 5, }}
                                        placeholder="Password"
                                    />


                                </Col>
                                <Col {...fullMobile(24, 24)} style={{ textAlign: 'left', paddingTop: 10 }}>
                                    <Typography variant="body1" fontWeight={600} fontSize={14} style={{ color: 'rgba(0,0,0,0.7)', position: 'relative', marginTop: 0, marginBottom: 3, display: "inline" }}>
                                        Agree to <a href='https://www.laketech.com/terms-of-service' target="_blank">terms and services</a><span style={{ color: 'red' }}>*</span>
                                    </Typography>
                                    <Checkbox
                                        disabled={loading === 'signingUp'}
                                        checked={user?.terms}
                                        onChange={(e) => setUser({ ...user, terms: e.target.checked })}
                                        style={{ borderRadius: 5, display: "inline" }}
                                        placeholder="Password"
                                    />


                                </Col>
                                <Col {...fullMobile(24, 24)} style={{ textAlign: 'left', paddingTop: 4 }}>
                                    <Typography variant="body1" fontWeight={600} fontSize={14} style={{ color: 'rgba(0,0,0,0.7)', position: 'relative', marginTop: 0, marginBottom: 3, display: "inline" }}>
                                        Agree to our  <a href='https://www.laketech.com/subscription-agreement' target="_blank">subscription agreement</a><span style={{ color: 'red' }}>*</span>
                                    </Typography>
                                    <Checkbox
                                        disabled={loading === 'signingUp'}
                                        checked={user?.subscriptionTerms}
                                        onChange={(e) => setUser({ ...user, subscriptionTerms: e.target.checked })}
                                        style={{ borderRadius: 5, display: "inline" }}
                                        placeholder="Password"
                                    />


                                </Col>

                                <Col {...fullMobile(24, 24)} style={{ textAlign: 'left', paddingTop: 10 }}>
                                    {loading === 'signingUp' ?
                                        <div style={{ width: '100%', textAlign: 'center' }}>
                                            <FontAwesomeIcon icon={faSpinner} spin size='2x' style={{ color: '#404050', position: 'relative', marginTop: 0, marginBottom: 3 }} />
                                        </div>
                                        :
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            style={{
                                                textTransform: 'none',
                                                color: 'white',
                                                width: '100%', marginTop: 20,
                                                backgroundColor: '#404050'


                                            }}



                                        >
                                            <Typography variant="body1" display="inline" fontWeight={700} fontSize={16} style={{ color: 'white', position: 'relative', marginTop: 0, marginBottom: 3 }}>
                                                Create your account <FontAwesomeIcon icon={faArrowRight} style={{ marginLeft: 10 }} />
                                            </Typography>
                                        </Button>
                                    }


                                </Col>
                                <Col {...fullMobile(24, 24)} style={{ textAlign: 'left', paddingTop: 2 }}>
                                    <Typography variant="body1" fontWeight={600} fontSize={14} style={{ color: 'rgba(0,0,0,0.7)', position: 'relative', marginTop: 0, marginBottom: 3, display: "inline" }}>
                                        <a href='https://www.laketech.com/privacy-policy' target="_blank">Privacy Policy</a>
                                    </Typography>



                                </Col>



                            </Row>

                        </form >
                    </Col>)}










            </Row>

        );
    }
};

export default NewInvited;
