import { collection, doc, getCountFromServer, getDocs, getFirestore, query, setDoc, where, writeBatch } from "firebase/firestore"
import uuid from "react-uuid";
import Pica from 'pica';
import { getStorage, ref, getMetadata, getDownloadURL, uploadBytes, uploadBytesResumable } from "firebase/storage";
import exifr from 'exifr';
import moment from 'moment-timezone';





export async function createItem(item, userInfo, openSnack, setItems, setCreateItemModal) {
    try {
        const db = getFirestore();
        const id = item.id || uuid();
        const createdItem = { ...item, id, key: id, status: 'active', archived: false, label: item.name, company: userInfo.currentCompany, account: userInfo.currentAccount, created: moment().format('YYYY-MM-DD') }
        console.log(createdItem)
        const docRef = doc(db, "clients", userInfo.currentCompany, 'psItems', id);
        await setDoc(docRef, createdItem);
        console.log('createdItem', createdItem)
        setItems(prev => [...prev, createdItem])



        setCreateItemModal(false);
        openSnack('success', 'Item created successfully');
        return createdItem


    } catch (error) {
        console.log(error)
        return 'error'

    }
};

