import React, { useEffect, useRef, useState } from 'react';

import Sketch from "@arcgis/core/widgets/Sketch";
import SketchViewModel from "@arcgis/core/widgets/Sketch/SketchViewModel";
import { polygonSymbol, polylineSymbol, pointSymbol } from "./symbology/renderer";
import TextSymbol from "@arcgis/core/symbols/TextSymbol";
import Graphic from "@arcgis/core/Graphic";
import { createLabel, deleteLabel, readGraphicsLayer, saveGraphicsLayer, setGraphicsToLayer, } from './symbology/labels';
import GroupLayer from "@arcgis/core/layers/GroupLayer.js";
import LayerList from "@arcgis/core/widgets/LayerList.js";
import Slider from "@arcgis/core/widgets/Slider.js";

import uuid from 'react-uuid';
import ButtonMenu from "@arcgis/core/widgets/FeatureTable/Grid/support/ButtonMenu.js";
import Expand from "@arcgis/core/widgets/Expand.js";
import CustomExpandPanel from './expandPanel/customExpandPanel';
import rgbHex from 'rgb-hex';
import ReactDOM from 'react-dom';
import { SketchComponent } from './sketch/sketchComponent';
import MapView from "@arcgis/core/views/MapView";
import Map from "@arcgis/core/Map";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import { toggleLabels, updateLabelText, updateShapeLabels, updateShapeSymbols } from './symbology/utils';
import _ from 'lodash';
import './mapSymbols.css';

import { arrowLeft16 } from "@esri/calcite-ui-icons";

console.log(arrowLeft16)

const MapComponent = ({ account, mapInfo, userInfo, openSnack, record, type }) => {
    const mapDivEditor = useRef(null);
    const viewRef = useRef(null);
    const sketchRef = useRef(null);
    const sketchExpandRef = useRef(null);
    const graphicsRef = useRef(null);
    const layerListExpandRef = useRef(null);
    const [showLabels, setShowLabels] = useState(mapInfo?.showLabels || false);
    const [layerColor, setLayerColor] = useState(mapInfo?.layerColor || { rgb: { r: 255, g: 0, b: 0, a: 1 }, hex: '#ff0000' });
    const [labelAttributes, setLabelAttributes] = useState(mapInfo?.labelAttributes);
    const [graphics, setGraphics] = useState(mapInfo?.graphicsLayer || []);
    const [mapUnits, setMapUnits] = useState(mapInfo?.mapUnits || { area: 'acres', length: 'feet' });
    const [initialLoad, setInitialLoad] = useState(true);


    const props = {
        showLabels,
        setShowLabels,
        layerColor,
        setLayerColor,
        labelAttributes,
        setLabelAttributes,
        mapUnits,
        setMapUnits
    };




    async function getGraphics(graphicsLayer, view) {
        const { layerColor, labelAttributes, showLabels, mapUnits } = await readGraphicsLayer(graphicsLayer, userInfo, record);
        console.log(mapUnits)
        setLayerColor(layerColor);
        setLabelAttributes(labelAttributes);
        setShowLabels(showLabels);
        setMapUnits(mapUnits);
        let sketchModule = SketchComponent(view, graphicsLayer, showLabels, labelAttributes, layerColor, mapUnits, userInfo, record);
        sketchRef.current = sketchModule;
        sketchExpandRef.current.content = sketchModule;
        updateLabelText(graphicsLayer, mapUnits, labelAttributes, layerColor, showLabels, userInfo, record)

    }



    useEffect(() => {
        if (mapDivEditor.current) {
            console.log("LOADING MAP")

            // Inside useEffect or a similar initializing function
            let graphicsLayer = new GraphicsLayer({
                id: 'graphicsLayer',
                title: "Sketch Layer",
            });

            graphicsRef.current = graphicsLayer;


            /* localStorage.removeItem('graphicsLayer'); */






            const webmap = new Map({
                basemap: "satellite",
                layers: [graphicsLayer]
            });

            const view = new MapView({
                container: mapDivEditor.current,
                map: webmap,
                center: [account?.lng, account?.lat],
                zoom: 16
            });
            setGraphicsToLayer(graphicsLayer, graphics, getGraphics, view);


            viewRef.current = view;

            let sketchModule = SketchComponent(view, graphicsLayer, showLabels, labelAttributes, layerColor, mapUnits, userInfo, record);


            sketchRef.current = sketchModule;


            // Now use this node as the content for the Expand widget
            let sketchExpand = new Expand({
                expandIconClass: 'draw-icon',  // Use an appropriate icon
                collapseIcon: "caret-right",  // Use an appropriate icon
                view: view,
                content: sketchModule,

            });

            sketchExpandRef.current = sketchExpand;



            view.ui.add(sketchExpand, "top-right");

            /* make a 100px by 100px mui card */
            // Create a container for your React component
            let node = document.createElement("div");
            node.setAttribute("id", "layerListDiv");
            node.style.width = "250px"; // Adjust size as needed



            ReactDOM.render(<div>Test</div>, node)




            // Now use this node as the content for the Expand widget
            let layerListExpand = new Expand({
                expandIconClass: 'custom-expand-icon',  // Use an appropriate icon
                collapseIcon: "caret-right",  // Use an appropriate icon
                view: view,
                content: node,  // Your React component's container
                closeOnEsc: true,
                autoCollapse: true,
            });

            layerListExpandRef.current = layerListExpand;

            view.ui.add(layerListExpand, "top-right");




            setInitialLoad(false);

            return () => view && view.destroy();
        }





    }, []);


    useEffect(() => {
        const view = viewRef.current;
        const layerList = layerListExpandRef.current;
        const graphicsLayer = graphicsRef.current;
        let sketchModule = sketchRef.current;
        const sketchExpand = sketchExpandRef.current;


        if (view && layerList) {

            function updateSketchComponent(val) {
                sketchModule = SketchComponent(view, graphicsLayer, showLabels, labelAttributes, val, mapUnits, userInfo, record);
                sketchExpand.content = sketchModule;
                console.log('THIS THING ON 1?')
                saveGraphicsLayer({ graphicsLayer, labelAttributes, layerColor: val, showLabels, mapUnits, userInfo, record })

            }
            function updateSketchComponentLabels(val) {
                sketchModule = SketchComponent(view, graphicsLayer, showLabels, val, layerColor, mapUnits, userInfo, record);
                sketchExpand.content = sketchModule;
                console.log('THIS THING ON 11?')
                saveGraphicsLayer({ graphicsLayer, labelAttributes: val, layerColor, showLabels, mapUnits, userInfo, record })

            }





            let node = document.createElement("div");
            node.setAttribute("id", "layerListDiv");
            node.style.width = "250px"; // Adjust size as needed
            // Render your React component inside the node
            ReactDOM.render(<CustomExpandPanel  {...props}

                setLabelAttributes={(boo, val) => {
                    console.log('UPDATING LABELS!!!')
                    console.log(boo)
                    console.log(val)

                    updateShapeLabels(graphicsLayer, boo, val)
                    updateSketchComponentLabels(val)
                    setLabelAttributes(val);

                }}

                updateGraphicsLayerAttributes={(boo, val) => {
                    console.log('UPDATING LAYER AND LABEL ATTRIBUTES1')
                    console.log(boo)
                    console.log(val)
                    if (boo === 'color') {
                        updateShapeSymbols(graphicsLayer, boo, val)
                        updateSketchComponent(val)

                    }
                    if (boo === 'showLabels') {
                        setShowLabels(val);
                        sketchModule = SketchComponent(view, graphicsLayer, val, labelAttributes, layerColor, mapUnits, userInfo, record);
                        toggleLabels(graphicsLayer, val, labelAttributes, layerColor, mapUnits, userInfo, record)
                        console.log('THIS THING ON 3?')
                        saveGraphicsLayer({ graphicsLayer, labelAttributes, layerColor, showLabels: val, mapUnits, userInfo, record })
                    }
                }}

                setMapUnits={(boo, val) => {
                    console.log('UPDATING LAYER AND LABEL ATTRIBUTES2')
                    const newUnits = { ...mapUnits };
                    newUnits[boo] = val;
                    setMapUnits(newUnits);
                    sketchModule = SketchComponent(view, graphicsLayer, showLabels, labelAttributes, layerColor, newUnits, userInfo, record);
                    sketchExpand.content = sketchModule;
                    console.log('THIS THING ON 4?')
                    saveGraphicsLayer({ graphicsLayer, labelAttributes, layerColor, showLabels, mapUnits: newUnits, userInfo, record })
                    updateLabelText(graphicsLayer, newUnits, labelAttributes, layerColor, showLabels, userInfo, record)
                }}
            />, node);


            layerList.content = node;
        }
    }, [layerColor, labelAttributes, showLabels, mapUnits])









    useEffect(() => {
        const view = viewRef.current;
        const graphicsLayer = graphicsRef.current;
        let sketchModule = sketchRef.current;
        const sketchExpand = sketchExpandRef.current;

        if (view && graphicsLayer && sketchModule && sketchExpand && initialLoad === false) {
            console.log('UPDATING LAYER AND LABEL ATTRIBUTES')

            sketchModule = SketchComponent(view, graphicsLayer, showLabels, labelAttributes, layerColor, mapUnits, userInfo, record);
            sketchExpand.content = sketchModule;

            console.log('save graphics layer')

            console.log('THIS THING ON 5?')
            saveGraphicsLayer({ graphicsLayer, labelAttributes, layerColor, showLabels, mapUnits, userInfo, record })
            setLayerColor(layerColor);
            setLabelAttributes(labelAttributes);
        }
    }, [layerColor, labelAttributes, showLabels, mapUnits])




    return (
        <div style={{ height: "100%", width: "100%" }}>
            <div style={{ height: "100%", width: "100%" }} ref={mapDivEditor}>


            </div>
        </div >
    );
}

export default MapComponent;
