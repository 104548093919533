import React from 'react';
import { Marker } from '@react-google-maps/api';





const triangleSymbol = (props) => {
    return ({
        path: 'M 0,0 L 16,0 L 8,16 Z',
        fillColor: props.color,
        fillOpacity: 1,
        strokeWeight: 1,
        scale: props.scale || 1,
    })
};

const squareSymbol = (props) => {
    return ({
        path: 'M 0,0 L 16,0 L 16,16 L 0,16 Z',
        fillColor: props.color,
        fillOpacity: 1,
        strokeWeight: 1,
        scale: props.scale || 1,
    })
};

const diamondSymbol = (props) => {
    return ({
        path: 'M 256 128 L 384 256 L 256 384 L 128 256 Z',
        fillColor: props.color,
        fillOpacity: 1,
        strokeWeight: 1,
        scale: props.scale || 1,
    })
};

const circleSymbol = (props) => {
    return ({
        path: "M60,50 A10,10 0 1,0 40,50 A10,10 0 1,0 60,50",
        fillColor: props.color,
        fillOpacity: 1,
        strokeWeight: 1,
        scale: props.scale || 1,
    })
};

const crossSymbol = (props) => {
    return ({
        path: 'M 240 128 L 272 128 L 272 96 L 304 96 L 304 128 L 336 128 L 336 160 L 304 160 L 304 192 L 272 192 L 272 160 L 240 160 Z',
        fillColor: props.color,
        fillOpacity: 1,
        strokeWeight: 1,
        scale: props.scale || 1,
    })
};

const starSymbol = (props) => {
    return ({
        path: 'M 256 64 L 320 224 L 480 224 L 352 352 L 416 512 L 256 416 L 96 512 L 160 352 L 32 224 L 192 224 Z',
        fillColor: props.color,
        fillOpacity: 1,
        strokeWeight: 1,
        scale: props.scale || 1,
    })
};
const buoySymbol = (props) => {
    return ({
        path: "M 277.5 200 C 277.5 179.87 294.37 163 314.5 163 C 334.63 163 351.5 179.87 351.5 200 C 351.5 220.13 334.63 237 314.5 237 C 294.37 237 277.5 220.13 277.5 200 Z M 314.5 163 L 314.5 0 M 314.5 237 L 314.5 400",
        fillColor: props.color,
        fillOpacity: 1,
        strokeWeight: 1,
        scale: props.scale || 1,
    })
};




const getIcon = (symbol, color, scale) => {

    //change the hexcode to a color name to these colors 'red', 'black', 'blue', 'green', 'grey', 'orange', 'purple', 'white', 'yellow'
    const colorName = {
        '#ff0000': 'red',
        '#000000': 'black',
        '#0000ff': 'blue',
        '#008000': 'green',
        '#808080': 'grey',
        '#ffa500': 'orange',
        '#800080': 'purple',
        '#ffffff': 'white',
        '#ffff00': 'yellow',
    }



    switch (symbol) {
        case 'circle':
            return { url: `https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_${colorName[color?.toLowerCase()]}.png` }
        case 'square':
            return { url: `https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_${colorName[color?.toLowerCase()]}.png` }
        case 'diamond':
            return { url: `https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_${colorName[color?.toLowerCase()]}.png` }
        case 'triangle':
            return { url: `https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_${colorName[color?.toLowerCase()]}.png` }
        case 'cross':
            return { url: `https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_${colorName[color?.toLowerCase()]}.png` }
        case 'star':
            return { url: `https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_${colorName[color?.toLowerCase()]}.png` }
        case 'buoy':
            return { url: `https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_${colorName[color?.toLowerCase()]}.png` }
        default:
            //api-3 pin
            return { url: `https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_${colorName[color?.toLowerCase()] || 'red'}.png` }
    }
};
//red, blue, green, orange
const NodeMarkers = ({ nodes, accountNodes, markerSymbol }) => {
    return (
        <>
            {nodes?.map((node) => {
                if (!node) return null;
                const accountNode = accountNodes.find((n) => n.id === node.id);

                return (
                    <Marker
                        key={node?.id}
                        position={{
                            lat: accountNode?.lat,
                            lng: accountNode?.lng,
                        }}
                        icon={{
                            ...getIcon(node?.marker, node?.color, node?.scale),
                            labelOrigin: new window.google.maps.Point(11, node?.padding || 40),
                        }}
                        label={{
                            text: node?.label || node?.name,
                            color: node?.fontColor || 'white',
                            fontWeight: '900',
                            fontSize: '16px',
                            strokeColor: 'black',
                            strokeWeight: 2,
                        }}
                    />
                );
            })}
        </>
    );
};

export default NodeMarkers;
