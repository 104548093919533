import { collection, getDocs, getFirestore, query, where } from "firebase/firestore";
import axios from 'axios';
import ProbeParameters from "../../../extra/probeParams";

var moment = require('moment-timezone');


export const nextStepFunc = (steps, setSteps,) => {
    // Clone the steps array to avoid directly mutating the state
    console.log(steps)
    const updatedSteps = [...steps];

    // Find the index of the current step
    const currentStepIndex = updatedSteps.findIndex(step => step.stepStatus === "Current");

    // If there's no current step or it's the last step, do nothing
    if (currentStepIndex === -1 || currentStepIndex === updatedSteps.length - 1) {
        return;
    }

    // Update the current step to "Completed"
    updatedSteps[currentStepIndex].stepStatus = "Completed";

    // Update the next step to "Current"
    updatedSteps[currentStepIndex + 1].stepStatus = "Current";

    // Update the state
    setSteps(updatedSteps);



};

export const firstStepFunc = (steps, setSteps, setData, setHeaders, setMeta) => {
    // Clone the steps array to avoid directly mutating the state
    const updatedSteps = [...steps];

    // Find the index of the current step
    const currentStepIndex = updatedSteps.findIndex(step => step.stepStatus === "Current");

    // If there's no current step or it's the last step, do nothing
    if (currentStepIndex === -1 || currentStepIndex === updatedSteps.length - 1) {
        return;
    }

    // Update the current step to "Completed"
    updatedSteps[currentStepIndex].stepStatus = "Completed";

    // Update the next step to "Current"
    updatedSteps[0].stepStatus = "Current";

    // Update the state
    console.log(updatedSteps)
    setSteps(updatedSteps);
    setData(null)
    setHeaders([])
    setMeta({})
};

export const backStepFunc = (steps, setSteps) => {
    // Clone the steps array to avoid directly mutating the state
    const updatedSteps = [...steps];

    // Find the index of the current step
    const currentStepIndex = updatedSteps.findIndex(step => step.stepStatus === "Current");

    // If there's no current step or it's the first step, do nothing
    if (currentStepIndex <= 0) {
        return;
    }

    // Update the current step to "Waiting"
    updatedSteps[currentStepIndex].stepStatus = "Waiting";

    // Update the previous step to "Current"
    updatedSteps[currentStepIndex - 1].stepStatus = "Current";

    // Update the state
    setSteps(updatedSteps);
};

export const getTemplates = async (userInfo) => {
    try {
        const db = getFirestore();
        const queryRef = collection(db, "clients", userInfo.currentCompany, "dataTemplates");
        const snapShot = await getDocs(query(queryRef, where("account", "==", userInfo.currentAccount), where('archived', '==', false)));

        const templates = snapShot.docs.map(doc => doc.data());

        return templates;
    } catch (error) {
        console.error("Error querying data: ", error);
    }
};
export const getTemplatesMulti = async (userInfo) => {
    try {
        const db = getFirestore();
        const queryRef = collection(db, "clients", userInfo.currentCompany, "dataTemplatesMulti");
        const snapShot = await getDocs(query(queryRef, where("account", "==", userInfo.currentAccount), where('archived', '==', false)));

        const templates = snapShot.docs.map(doc => doc.data());

        return templates;
    } catch (error) {
        console.error("Error querying data: ", error);
    }
};




export const formatSQLData = async ({ userInfo, data, parameters, stations, unitsArray, headers, codes }) => {
    try {
        const toSqlString = (value) => {
            if (value === undefined || value === null) {
                return 'null';
            }
            return `'${value}'`;
        };

        console.log(data)

        // Create a key-mapping object from the headers
        const keyMapping = {};
        headers.forEach((item) => {
            keyMapping[item.name] = item.mappedField;
        });

        const dateHeader = headers.find((item) => item.mappedField === 'date');

        // Map through the sortedData and replace keys
        const formattedData = data.map((item) => {
            const newItem = {};  // Initialize newItem object to store longitude and latitude

            const formattedEntries = Object.entries(item).map(([key, value]) => {
                const newKey = keyMapping[key] || key;

                let formattedValue;
                switch (newKey) {
                    case 'codes':
                        console.log(value)
                        const existingcodes = [];
                        value.forEach(code => {
                            const check = codes.find((option) => option.label === code);
                            if (!check) {
                                existingcodes.push(code);
                            }
                        })
                        console.log(existingcodes)
                        newItem['tags'] = `'${JSON.stringify(existingcodes)}'`
                        console.log(newItem['tags'])
                        return [newKey, formattedValue];
                    case 'parameters':
                        newItem['parameterid'] = toSqlString([...parameters, ...ProbeParameters].find((p) => (p.name || p.label) === value)?.key || null);
                        return null;
                    case 'node':
                        const station = stations.find((p) => p.name === value);
                        newItem['longitude'] = station?.lng || null;
                        newItem['latitude'] = station?.lat || null;
                        newItem['nodeid'] = toSqlString(station?.id || null);
                        newItem['locationid'] = toSqlString(station?.id || null);
                        return null;
                    case 'units':
                        newItem['unitid'] = toSqlString(unitsArray.find((p) => p.label === value)?.key || null);
                        return null;
                    case 'date':
                        newItem['time'] = dateHeader.dateFormat ? toSqlString(moment(value, dateHeader?.dateFormat).format("YYYY-MM-DD HH:mm")) : toSqlString(moment(value).format("YYYY-MM-DD HH:mm"));
                        newItem['timestamp'] = dateHeader.dateFormat ? moment(value, dateHeader?.dateFormat).format("X") : moment(value).format("X");
                        return null;
                    case 'value':
                        formattedValue = Number(value);
                        newItem['key'] = toSqlString(item['id']);
                        newItem['account'] = toSqlString(userInfo.currentAccount);
                        newItem['company'] = toSqlString(userInfo.currentCompany);
                        newItem['logkey'] = toSqlString(null);
                        newItem['offsetvalue'] = 0;
                        newItem['label'] = `'${''}'`;
                        return [newKey, formattedValue];
                    default:
                        return [newKey, value];
                }
            }).filter(Boolean); // Remove null entries

            return { ...newItem, ...Object.fromEntries(formattedEntries) };
        });




        const newData = formattedData.map(l => {
            return (
                {
                    time: l.time,
                    timestamp: Number(l.timestamp),
                    locationid: l.locationid,
                    unitid: l.unitid || 'null',
                    parameterid: l.parameterid,
                    value: Number(l.value),
                    account: l.account,
                    company: l.company,
                    nodeid: l.nodeid,
                    key: l.key,
                    longitude: l.longitude,
                    latitude: l.latitude,
                    logkey: l.logkey,
                    label: l.label,
                    tags: l.tags || `'${JSON.stringify([])}'`,
                    offsetvalue: 0,
                    archived: toSqlString('false')
                }
            )
        })
        console.log(newData)
        return newData;
    } catch (error) {
        console.error("Error formatting data: ", error);
    }
};

function isValidDate(dateString) {
    const date = new Date(dateString);
    return date instanceof Date && !isNaN(date.getTime());
}


export const uploadData = async (data, userInfo, setProgress) => {
    let successfulChunks = 0;
    const chunkSize = 10000;
    const totalChunks = Math.ceil(data.length / chunkSize);

    const updateProgress = (progress) => {
        if (typeof setProgress === 'function') {
            setProgress(progress);
        } else {
            console.warn('setProgress is not a function. Progress updates will not be shown.');
        }
    };


    try {
        if (data?.[0]?.time === "'Invalid date'") {
            console.log("INVALID DATE");
            return 'Double check date format in previous page!';
        }

        for (let i = 0; i < data.length; i += chunkSize) {
            const chunk = data.slice(i, i + chunkSize);
            const hourlyVals = chunk.map((h) => `(${Object.values(h)})`);
            const hourlyString = hourlyVals.join();

            const proxyUrl = "https://mycorslake.herokuapp.com/";
            const insertQuery = `INSERT INTO node_data_new2 VALUES ${hourlyString} ON CONFLICT DO NOTHING;`;

            console.log(`Uploading chunk ${successfulChunks + 1} of ${totalChunks}`);
            await axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', { raw: insertQuery });

            successfulChunks++;
            const progress = Math.round((successfulChunks / totalChunks) * 100);
            updateProgress(progress);
            console.log(`Successfully uploaded chunk ${successfulChunks} of ${totalChunks}. Progress: ${progress}%`);
        }

        if (successfulChunks === totalChunks) {
            return 'success';  // Changed to match the expected success message in your component
        } else {
            return `Partial success. Uploaded ${successfulChunks} out of ${totalChunks} chunks.`;
        }
    } catch (error) {
        console.log(error);
        console.log("Error formatting or uploading data: ", error);
        return `Something went wrong after uploading ${successfulChunks} chunks. Please try again.`;
    }
};

export const formatSQLDataMulti = async ({ userInfo, data, parameters, stations, unitsArray, headers, codes }) => {
    try {
        const toSqlString = (value) => {
            if (value === undefined || value === null) {
                return 'null';
            }
            return `'${value}'`;
        };

        const keyMapping = {};
        headers.forEach((item) => {
            keyMapping[item.name] = item.mappedField;
        });

        const dateHeader = headers.find((item) => item.mappedField === 'date');
        const siteHeader = headers.find((item) => item.mappedField === 'node');



        console.log(headers)
        console.log(data)

        const formattedData = [];

        /* list of label unit pairs */
        const labelUnitPairs = headers.map((header) => {
            return ({
                label: header.name,
                unitid: header.units ? header.units.replace(/°/g, '') : header.units
            })
        })

        data.forEach((item) => {
            const commonFields = {};

            delete item.warnings;
            delete item.errors;
            delete item.selected;
            const dateItem = item[dateHeader.name];
            const siteItem = item[siteHeader.name];
            const idItem = item['id'];


            Object.entries(item).forEach(([key, value]) => {
                if (value === '') {
                    return
                }
                if ([dateHeader.name, siteHeader.name, 'id'].includes(key)) {
                    return;
                }
                /* check for header with units */

                const item = {};
                item['time'] = dateHeader.dateFormat ? toSqlString(moment(dateItem, dateHeader?.dateFormat).format("YYYY-MM-DD HH:mm")) : toSqlString(moment(dateItem).format("YYYY-MM-DD HH:mm"));
                item['timestamp'] = dateHeader.dateFormat ? moment(dateItem, dateHeader?.dateFormat).format("X") : moment(dateItem).format("X");
                const station = stations.find((p) => p.name === siteItem);
                item['longitude'] = station?.lng || null;
                item['latitude'] = station?.lat || null;
                item['nodeid'] = toSqlString(station?.id || null);
                item['locationid'] = toSqlString(station?.id || null);
                item['parameterid'] = toSqlString([...parameters, ...ProbeParameters].find((p) => (p.name || p.label) === key)?.key || null);

                // Find the correct unit from labelUnitPairs
                const unitPair = labelUnitPairs.find(pair => pair.label === key);

                // Then find the corresponding unit in unitsArray
                item['unitid'] = toSqlString(unitsArray.find((p) => p.label === unitPair?.unitid)?.key || null);
                item['value'] = Number(value);
                item['key'] = toSqlString(idItem);
                item['account'] = toSqlString(userInfo.currentAccount);
                item['company'] = toSqlString(userInfo.currentCompany);
                item['logkey'] = toSqlString(null);
                item['offsetvalue'] = 0;
                item['label'] = `'${''}'`;
                item['tags'] = `'${JSON.stringify([])}'`;

                formattedData.push(item);

            })

        })






        const newData = formattedData.map(l => {

            return (
                {
                    time: l.time,
                    timestamp: Number(l.timestamp),
                    locationid: l.locationid,
                    unitid: l.unitid,
                    parameterid: l.parameterid,
                    value: Number(l.value),
                    account: l.account,
                    company: l.company,
                    nodeid: l.nodeid,
                    key: l.key,
                    longitude: l.longitude,
                    latitude: l.latitude,
                    logkey: toSqlString('null'),
                    label: l.label,
                    tags: l.tags,
                    offsetvalue: 0,
                    archived: toSqlString('false'),
                    sample_id: toSqlString('null'),
                    sample_type: toSqlString('null'),
                    reading_limit: toSqlString('null'),
                }
            )
        })

        return newData;

    } catch (error) {
        console.log("Error formatting data: ", error);
    }
};








export const formatCodesData = async ({ data, headers }) => {
    try {


        const dataArray = data

        // Second array
        const fieldMappingArray = headers

        // Find the key in the first array that is mapped to 'codes' in the second array
        const mappedFieldForCodes = fieldMappingArray.find(item => item.mappedField === 'codes')?.name;

        // Update the first array
        if (mappedFieldForCodes) {
            dataArray.forEach(item => {
                if (item.hasOwnProperty(mappedFieldForCodes)) {
                    let currentValue = item[mappedFieldForCodes];
                    if (Array.isArray(currentValue)) {
                        return;
                    }
                    else if (currentValue === 'none') {
                        item[mappedFieldForCodes] = ['none'];
                    } else if (currentValue.includes(',')) {
                        item[mappedFieldForCodes] = currentValue.split(',').map(str => str.trim());
                    } else {
                        item[mappedFieldForCodes] = [currentValue];
                    }
                }
            });
        }

        // dataArray is now updated
        console.log(dataArray);
        return dataArray;
    } catch (error) {
        console.error("Error formatting data: ", error);
    }
};



