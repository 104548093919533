import { Breadcrumbs, CircularProgress, Divider, Input, OutlinedInput, Paper, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import { doc, getDoc, getFirestore, setDoc, updateDoc } from 'firebase/firestore';
import React from 'react';
import { Link } from 'react-router-dom';
import uuid from 'react-uuid';
import Photos from './photos';

export default class Album extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            type: this.props.type,
            item: {},
            photos: [],
            loading: true
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }

    }
    async componentDidMount() {
        window.scrollTo(0, 0);
        const { userInfo } = this.state;
        const db = getFirestore();

        const docRef = doc(db, "users", userInfo.id, 'currentItems', 'currentAlbum');
        const docSnap = await getDoc(docRef);

        if (docSnap.data().key === 'none') {
            const item = { key: uuid(), name: 'Untitled Album', description: '', archived: false, account: userInfo.currentAccount, company: userInfo.currentCompany }
            this.setState({ item, loading: false });

            const docRef = doc(db, "clients", userInfo.currentCompany, 'albums', item.key);
            await setDoc(docRef, item).then(() => console.log("ALBUM CREATED")).then(async () => {
                const itemRef = doc(db, "users", userInfo.id, 'currentItems', 'currentAlbum');
                await setDoc(itemRef, {
                    key: item.key,
                })
            })


        }
        else {
            this.getDocItem(docSnap.data().key)
        }
    }

    queryDataDoc = async (userInfo) => {
        const { type } = this.state;

    }

    getDocItem = async (key) => {
        const { userInfo } = this.state;
        const db = getFirestore();
        const docRef = doc(db, "clients", userInfo.currentCompany, 'albums', key);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {

            this.setState({ item: docSnap.data(), loading: false })

        }
    }


    updateItem = async (boo, val) => {
        const { userInfo, item } = this.state;
        item[boo] = val;

        this.setState({ item })
        const db = getFirestore();
        const docRef = doc(db, "clients", userInfo.currentCompany, 'albums', item.key);
        await updateDoc(docRef, { [boo]: val })

    }
    updateEntireItem = (value) => {
        this.setState({ item: value })
    }

    render() {

        const { userInfo, item, screenWidth, type, loading, photos, account } = this.state;




        const Loading = (props) => {
            return (
                <div style={{ minHeight: '100vh' }}>
                    <Row justify="space-around" gutter={[32, 8]} align="middle" style={{ paddingTop: 18, }}>

                        <Col span={24} style={{ textAlign: 'center', fontSize: 24, fontFamily: 'Roboto, sans-serif', }}>
                            <CircularProgress />
                        </Col>
                    </Row>
                </div>
            )
        }

        return (
            <Row style={{}}>


                <Col span={23}>
                    <Row align="middle">
                        <Col xs={16} sm={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>
                            <Typography variant="h4">Photo Album</Typography>
                        </Col>
                        <Col xs={6} sm={4} style={{ paddingTop: 30, paddingBottom: 15, textAlign: 'right' }}>

                        </Col>
                        <Col xs={16} sm={20} style={{ paddingLeft: 30, }}>
                            <Typography variant="body1">Add photos to reference later!</Typography>
                        </Col>

                        <Col span={24}>
                            <Divider />
                        </Col>
                        <Col xs={16} sm={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>
                            <Breadcrumbs>
                                <Link to="/photoAlbums" style={{}}>
                                    Back to Albums
                                </Link>




                            </Breadcrumbs>
                        </Col> 
                    </Row>
                </Col >






                <Col span={23} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 30 }}>
                    <Paper elevation={12} style={{ padding: 26, borderRadius: 12 }}>
                        {loading ? <Loading /> : <Row gutter={[screenWidth === 'xs' ? 4 : 24, screenWidth === 'xs' ? 4 : 24]} align="middle">
                            <Col xs={24} sm={{ span: 20 }}>
                                <Input fullWidth notched={false} label="none" placeholder={"Album Name"} size="small"
                                    value={item.name} disabled={item.name === "Field Log Photos"}
                                    onChange={(e) => {
                                        this.updateItem(`name`, e.target.value)
                                    }} />

                            </Col>

                            <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                <OutlinedInput fullWidth notched={false} label="none" placeholder={"Album Description"} size="small"
                                    multiline={true}
                                    rows={2}
                                    value={item.description}
                                    onChange={(e) => {
                                        this.updateItem(`description`, e.target.value)
                                    }} />
                            </Col>

                            <Col span={24}>
                                <Photos openSnack={this.props.openSnack} albumKey={item.key} storageURL={`${item.key}/photos/`} item={item} screenWidth={screenWidth} userInfo={userInfo} account={account} />
                            </Col>



                        </Row>}
                    </Paper>
                </Col>
            </Row >
        );
    }
} 