import React, { useEffect, useState } from "react";


import { faBox, faBucket, faCheck, faFish, faFlask, faListCheck, faMagnifyingGlass, faPlus, faWheatAlt, faWrench, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, ButtonGroup, Divider, FormControl, Grid, IconButton, Input, InputLabel, MenuItem, Modal, OutlinedInput, Select, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import { makeStyles } from "@mui/styles";
import StocksSelect from "../../../../../../tasks/components/drawercomponents/stockSelect";



const Notes = (
    {
        record,
        items,
        setItems,
        screenWidth,
        openSnack,
        userInfo,
        updateRecord,
        stocks,
        setStocks,
        accounts,
        setDisableDrag





    }

) => {
    const [isFocused, setIsFocused] = useState(false);


    return (
        <>


            <Typography variant="h6" component="div" color={'rgba(0,0,0,0.8)'} fontWeight={700} fontSize={13}>
                Notes
            </Typography>
            <OutlinedInput
                margin="dense"
                size="small"
                type="number"
                fullWidth

                style={{
                    borderRadius: 4,
                    fontSize: 14,
                    backgroundColor: 'white'
                }}
                value={record.description}
                multiline={true}
                rows={3}
                onClick={(e) => {
                    console.log('CLICKED')
                    e.stopPropagation();
                    e.preventDefault();
                    setDisableDrag(true);
                }}
                onBlur={(e) => {
                    console.log('BLURRED')
                    e.stopPropagation();
                    e.preventDefault();
                    setDisableDrag(false);
                }}
                onChange={(e) => {
                    const value = e.target.value;
                    updateRecord({
                        ...record,
                        description: value
                    });
                }}
                placeholder="Notes"
            />








        </>



    );
};

export default Notes;
