import { Autocomplete, Button, Checkbox, Collapse, Divider, IconButton, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material';
import { Row, Col } from 'antd';
import React, { useEffect, useState } from 'react';
import { ReactSortable } from 'react-sortablejs';
import uuid from 'react-uuid';
import { fullMobile } from '../../util';
import AddIcon from '@mui/icons-material/Add';
import { DeleteForever, DragIndicator } from '@mui/icons-material';
import { faVial, faLocationCrosshairs, faFillDrip, faChartArea, faChartBar, faChartLine, faChartColumn, faGear } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataColorCompact from '../settings/dataColorCompact';
import moment from 'moment-timezone';
import DatePicker from "react-datepicker";

var randomColor = require('randomcolor');

export default function MapLayers(props) {
    const { userInfo, screenWidth, openSnack, widgetRef, updateCurrentWidget, nodes, PPArray, parameters, WPArray, references } = props;
    const [widget, setWidget] = useState(widgetRef);





    useEffect(() => {
        console.log(widgetRef)
    }, [])
    useEffect(() => {
        setWidget(widgetRef)
    }, [widgetRef])


    async function updateData(index, key, value) {
        var newWidget = { ...widget }
        newWidget.kmlLayers[index][key] = value
        setWidget({ ...newWidget })
        updateCurrentWidget({ ...newWidget })
    }


    const AddSeriesButton = () => {
        return (
            <Button variant={'contained'} display="inline"
                fullWidth
                style={{
                    textTransform: 'none', backgroundColor: '#404050', fontSize: 14,
                }}
                onClick={() => {
                    const item = {
                        key: uuid(),
                        visible: true,
                        url: '',
                        name: '',
                    };
                    const newKML = [...widget.kmlLayers, item];
                    setWidget({ ...widget, kmlLayers: newKML });
                    updateCurrentWidget({ ...widget, kmlLayers: newKML });

                }}
            >
                <AddIcon />  Add KML Layer
            </Button>

        )
    }



    return (
        <Row gutter={[4, 4]}>

            {widget.kmlLayers || [].map((kml, index) => {
                return (
                    <Col span={24}>
                        <Row align="middle" gutter={[12, 12]}>
                            <Col >
                                <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                    Visible
                                </Typography>
                                <Checkbox checked={kml.visible} onChange={(e) => updateData(index, 'visible', e.target.checked)} />
                            </Col>
                            <Col >
                                <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                    Name
                                </Typography>
                                <OutlinedInput notched={false} label="none" size="small" fullWidth defaultValue={kml.name}
                                    onBlur={(e) => updateData(index, 'name', e.target.value)} />
                            </Col>
                            <Col >
                                <Typography variant="subtitle2" style={{ fontWeight: 500, fontSize: 14 }}>
                                    URL
                                </Typography>
                                <OutlinedInput style={{ width: 350 }} notched={false} label="none" size="small" fullWidth defaultValue={kml.url}
                                    onBlur={(e) => updateData(index, 'url', e.target.value)} />
                            </Col>
                            <Col style={{ textAlign: 'right' }}>

                                <IconButton variant="contained" color="primary"
                                    onClick={() => {
                                        var newWidget = { ...widget }
                                        newWidget.kmlLayers.splice(index, 1);
                                        setWidget(newWidget)
                                        updateCurrentWidget(newWidget)
                                    }}>
                                    <DeleteForever />
                                </IconButton>
                            </Col>
                            <Col span={24}>
                                <Divider />
                            </Col>
                        </Row>
                    </Col>
                )
            })
            }

            <Col span={24} style={{ paddingTop: 10, paddingBottom: 0, }}>
                <Divider />
            </Col>
            <Col {...fullMobile(24, 24)} style={{ paddingTop: 20 }}>
                <AddSeriesButton />
            </Col>



        </Row>
    );
}