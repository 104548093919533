import { Autocomplete, Button, Divider, LinearProgress, OutlinedInput, TextField, Typography } from '@mui/material';
import { Col, Popconfirm, Row } from 'antd';
import { doc, getDoc, getFirestore, updateDoc } from 'firebase/firestore';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { fullMobile } from './util';

import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import uuid from 'react-uuid';

import { faCloudArrowUp, faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropzone from 'react-dropzone';
import { useNavigate } from 'react-router-dom';




export default function CompanyInfoNew(props) {
    const { userInfo, screenWidth, openSnack, company, account, appItem, closeModal } = props;
    const [info, setInfo] = useState({});
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isDragActive, setIsDragActive] = useState(false);
    const [isFileActive, setIsFileActive] = useState('');

    useEffect(() => {
        getInfo()
        window.scrollTo(0, 0);
    }, [])

    const db = getFirestore();
    async function getInfo() {

        const docRef = doc(db, "clients", userInfo.currentCompany);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            setInfo(docSnap.data())
            setLoading(false)
        } else {
        }
    }


    async function updateInfo() {
        const queryRef = doc(db, "clients", info.key);

        console.log(info)
        await updateDoc(queryRef, info);
    }

    const DeleteButton = () => {
        let navigate = useNavigate();
        return (
            <Popconfirm
                title="Are you sure to delete this company? This cannot be undone!"
                onConfirm={async () => {

                    console.log(userInfo.currentCompany)
                    const db = getFirestore();
                    const itemRef = doc(db, "clients", userInfo.currentCompany,);
                    await updateDoc(itemRef, {
                        archived: true
                    }).then(() => {
                        this.props.clearAccount()
                        this.props.changeCompany('none')
                        this.props.updateUserInfo('currentAccount', '');
                        this.props.updateUserInfo('currentCompany', '');
                    }).then(() => {
                        navigate('/changeCompany')
                    }).catch((err) => {
                        console.log(err)
                    })

                }}
                okText="Yes"
                cancelText="No"
            >
                <Button style={{ textTransform: 'none', padding: 16, fontSize: 16 }} fullWidth={screenWidth === 'xs' ? true : false} variant="contained" color="secondary"
                >
                    Delete Company
                </Button >
            </Popconfirm>

        )
    }

    const File = ({ file }) => {

        return (
            <Col {...fullMobile(24, 24)} style={{
                cursor: 'pointer',
                width: '100%', border: '1px solid #d9d9d9', borderRadius: 12, justifyContent: 'center', alignItems: 'center',
                transition: 'box-shadow 400ms, transform 400ms',
                boxShadow: isFileActive === file.key ? '0 4px 8px 0 rgba(0, 0, 0, 0.2)' : 'inset 0 0 0 1px #d9d9d9',
                transform: isFileActive === file.key ? 'scale(1.05)' : 'scale(1)',
                objectFit: 'cover', borderRadius: 12
            }} className="card-container"
                //if mouse over, change the background color

                onMouseEnter={() => {
                    setIsFileActive(file.key)
                }}
                onMouseLeave={() => {
                    setIsFileActive('')
                }}
                onClick={() => {
                    const downloadurl = file.fileLink;
                    window.open(downloadurl, '_blank');

                }}


            >
                <Row gutter={[12, 12]} align="middle" style={{ height: '100%' }}>

                    <Col span={24}
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                        <FontAwesomeIcon icon={faFile} size='3x' />
                    </Col>
                    <Col span={24}

                    >
                        <Typography variant='h6' fontWeight={500} fontSize={16} style={{ textAlign: 'center' }}>{file.fileName}</Typography>



                    </Col>



                </Row>
            </Col>
        )
    }





    return (
        <Row gutter={[12, 12]} style={{ padding: screenWidth === 'xs' ? 15 : 80 }}>


            <Col {...fullMobile(20, 20)} style={{ paddingTop: 10 }}>
                <Typography variant='h2' fontWeight={500} fontSize={28}>Company Info</Typography>



            </Col>

            <Col {...fullMobile(24, 24)} style={{ paddingTop: 10 }}>
                <Divider />
            </Col>
            <Col {...fullMobile(24, 24)} style={{}}>
                <Row align="middle" gutter={[4, 4]}>
                    <Col {...fullMobile(24, 24)} style={{}}>
                        <Typography variant="body1" fontWeight={500} component="h4"  >
                            Company Name
                        </Typography>

                    </Col>
                    <Col {...fullMobile(24, 24)} style={{}}>
                        <OutlinedInput
                            onBlur={updateInfo}
                            fullWidth
                            notched={false}
                            size="small"
                            placeholder="Name"
                            label="none"
                            onChange={(e) => {
                                setInfo({
                                    ...info,
                                    companyName: e.target.value
                                })
                            }
                            }
                            value={info.companyName}
                        />
                    </Col>

                </Row>

            </Col>
            <Col {...fullMobile(24, 24)} style={{}}>
                <Row align="middle" gutter={[4, 4]}>
                    <Col {...fullMobile(24, 24)} style={{}}>
                        <Typography variant="body1" fontWeight={500} component="h4"  >
                            Company Type
                        </Typography>

                    </Col>
                    {loading === false && (<Col {...fullMobile(24, 24)} style={{}}>
                        <Autocomplete
                            size="small"
                            disableClearable={true}
                            disablePortal
                            onBlur={updateInfo}
                            id="combo-box-demo"
                            options={[
                                { label: 'Municipality', key: 'municipality' },
                                { label: 'District', key: 'district' },
                                { label: 'Lake Manager', key: 'lakeManager' },
                                { label: 'Golf Course', key: 'golfCourse' },
                                { label: 'Winery', key: 'winery' },
                                { label: 'Citizen Scientist', key: 'citizenScientist' },
                                { label: 'Irrigation District', key: 'irrigationDistrict' },
                            ]}
                            defaultValue={info.companyType}
                            renderInput={(params) => <TextField {...params} placeholder="Client Type" />}
                            onChange={(a, b) => {
                                setInfo({
                                    ...info,
                                    companyType: b,
                                    companyTypeKey: b.key
                                })


                            }}

                        />
                    </Col>)}

                </Row>

            </Col>




            <Col {...fullMobile(24, 24)} style={{ paddingTop: 15 }} className="card-container"
                //if dragging over, change the background color

                onDragEnter={() => setIsDragActive(true)}
                onDragLeave={() => setIsDragActive(false)}
                onDragOver={() => setIsDragActive(true)}
                onDrop={() => setIsDragActive(false)}
                onMouseOver={() => setIsDragActive(true)}
                onMouseLeave={() => setIsDragActive(false)}

            >
                <Dropzone multiple={false} onDrop={(acceptedFiles) => {
                    const storage = getStorage();

                    acceptedFiles.map((f, i) => {
                        const key = uuid()

                        const file = {
                            fileCreateDate: moment().format('X'),
                            date: moment().format('X'),
                            fileCreator: `${userInfo.firstName || ""} ${userInfo.lastname}`,
                            fileCreatorKey: userInfo.id,
                            fileLink: '',
                            fileName: f.name,
                            fileType: f.type,
                            description: '',
                            key,
                            progress: 0
                        };

                        setInfo({
                            ...info,
                            logo: file
                        })



                        const storage = getStorage();


                        const storageRef = ref(storage, `${userInfo.currentCompany}/logo/`);


                        const uploadTask = uploadBytesResumable(storageRef, f);
                        uploadTask.on('state_changed',
                            (snapshot) => {
                                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;


                                setInfo({
                                    ...info,
                                    logo: {
                                        ...info.logo,
                                        progress
                                    }
                                })


                                switch (snapshot.state) {
                                    case 'paused':
                                        console.log('Upload is paused');
                                        break;
                                    case 'running':
                                        console.log('Upload is running');
                                        break;
                                }
                            },
                            (error) => {
                                console.log(error)
                            },
                            () => {
                                getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                                    console.log(downloadURL)
                                    setInfo({
                                        ...info,
                                        logo: {
                                            ...info.logo,
                                            fileLink: downloadURL,
                                            progress: 100
                                        }
                                    })

                                    const queryRef = doc(db, "clients", info.key);
                                    await updateDoc(queryRef, {
                                        logo: {
                                            ...info.logo,
                                            fileLink: downloadURL,
                                            progress: 100
                                        }
                                    })











                                });
                            }
                        )




                    })
                }} >
                    {({ getRootProps, getInputProps }) => (
                        <section style={{ height: '100%' }}>
                            <div {...getRootProps()} style={{
                                borderRadius: 12, alignItems: 'center',
                                backgroundColor: 'white', padding: '30px', fontWeight: 700,
                                transition: 'box-shadow 400ms, transform 400ms',
                                boxShadow: isDragActive ? '0 4px 8px 0 rgba(0, 0, 0, 0.2)' : 'inset 0 0 0 1px #d9d9d9',
                                transform: isDragActive ? 'scale(1.05)' : 'scale(1)',
                                cursor: 'pointer',
                                border: isDragActive ? '2px solid #404050' : '1px solid #d9d9d9',


                            }}>


                                <input {...getInputProps()} />

                                {info.logo && info.logo.fileLink && (
                                    <Row gutter={[12, 12]}>
                                        <Col {...fullMobile(24, 24)} style={{ paddingTop: 15 }} className="card-container">

                                            <img src={info.logo.fileLink} style={{ height: 100 }} />
                                        </Col>
                                    </Row>
                                )}


                                {info.logo && info.logo.progress < 100 && (
                                    <Row gutter={[12, 12]}>
                                        <Col {...fullMobile(24, 24)} style={{ paddingTop: 15 }} className="card-container">
                                            <LinearProgress variant="determinate" value={info.logo.progress} display="inline" />
                                            <Typography variant="body2" display="inline" style={{ paddingLeft: 10 }}>{info.logo.progress}%</Typography>
                                        </Col>
                                    </Row>
                                )}
                                {info.logo === undefined && (

                                    <Row gutter={[12, 12]}>
                                        <Col span={24} style={{ textAlign: 'center' }}>
                                            <FontAwesomeIcon fontSize={28} icon={faCloudArrowUp} color="#9ec1c9" />
                                        </Col>
                                        <Col span={24} style={{ textAlign: 'center', color: '#404050', }}>
                                            Upload Logo</Col>
                                    </Row>
                                )}



                            </div>
                        </section>
                    )}
                </Dropzone>
            </Col>
            <Col {...fullMobile(12, 24)} style={{}}>
                <Row align="middle" gutter={[4, 4]}>
                    <Col {...fullMobile(24, 24)} style={{}}>
                        <Typography variant="body1" fontWeight={500} component="h4"  >
                            Street Address
                        </Typography>

                    </Col>
                    <Col {...fullMobile(24, 24)} style={{}}>
                        <OutlinedInput
                            onBlur={updateInfo}
                            fullWidth
                            notched={false}
                            size="small"
                            placeholder="Address"
                            label="none"
                            onChange={(e) => {
                                setInfo({
                                    ...info,
                                    streetAddress: e.target.value
                                })
                            }
                            }
                            value={info.streetAddress}
                        />
                    </Col>

                </Row>

            </Col>
            <Col {...fullMobile(12, 24)} style={{}}>
                <Row align="middle" gutter={[4, 4]}>
                    <Col {...fullMobile(24, 24)} style={{}}>
                        <Typography variant="body1" fontWeight={500} component="h4"  >
                            City
                        </Typography>

                    </Col>
                    <Col {...fullMobile(24, 24)} style={{}}>
                        <OutlinedInput
                            onBlur={updateInfo}
                            fullWidth
                            notched={false}
                            size="small"
                            placeholder="City"
                            label="none"
                            onChange={(e) => {
                                setInfo({
                                    ...info,
                                    city: e.target.value
                                })
                            }
                            }
                            value={info.city}
                        />
                    </Col>

                </Row>

            </Col>
            <Col {...fullMobile(12, 24)} style={{}}>
                <Row align="middle" gutter={[4, 4]}>
                    <Col {...fullMobile(24, 24)} style={{}}>
                        <Typography variant="body1" fontWeight={500} component="h4"  >
                            Zip
                        </Typography>

                    </Col>
                    <Col {...fullMobile(24, 24)} style={{}}>
                        <OutlinedInput
                            onBlur={updateInfo}
                            fullWidth
                            notched={false}
                            size="small"
                            placeholder="Zip"
                            label="none"
                            onChange={(e) => {
                                setInfo({
                                    ...info,
                                    zip: e.target.value
                                })
                            }
                            }
                            value={info.zip}
                        />
                    </Col>

                </Row>

            </Col>
            <Col {...fullMobile(12, 24)} style={{}}>
                <Row align="middle" gutter={[4, 4]}>
                    <Col {...fullMobile(24, 24)} style={{}}>
                        <Typography variant="body1" fontWeight={500} component="h4"  >
                            State
                        </Typography>

                    </Col>
                    <Col {...fullMobile(24, 24)} style={{}}>
                        <OutlinedInput
                            onBlur={updateInfo}
                            fullWidth
                            notched={false}
                            size="small"
                            placeholder="State"
                            label="none"
                            onChange={(e) => {
                                setInfo({
                                    ...info,
                                    state: e.target.value
                                })
                            }
                            }
                            value={info.state}
                        />
                    </Col>

                </Row>

            </Col>




            {['EuEZ93ByFZYs8bSUXoYsTt1zu0m2', 'eTevYsI9mQMxhtntd95HmRGasNx2'].includes(userInfo.id) && (<Col xs={24} sm={24} style={{ paddingTop: 25 }}>
                <DeleteButton />
            </Col>)}



        </Row >
    )



}