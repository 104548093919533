import { Button, Checkbox, Divider, OutlinedInput, Typography, Select, MenuItem, CircularProgress, Breadcrumbs, FormGroup, FormControlLabel, Autocomplete, Chip, TextField, Switch } from '@mui/material';
import { Col, Row } from 'antd';
import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { fullMobile } from './../../util';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, } from 'firebase/firestore';

import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import uuid from 'react-uuid';


import DatePicker from "react-datepicker";
import queryString from 'query-string';
import axios from 'axios';
import { Link } from 'react-router-dom';
import LogWeather from '../../newFieldLog/components/logWeather';
var randomColor = require('randomcolor');

export default function LogGeneralInfo(props) {
    const { userInfo, screenWidth, openSnack, company, account, updateLog, logRef, updateHistory, updateWeatherItem } = props;
    const [staff, setStaff] = useState([]);
    const [log, setLog] = useState(logRef);
    const [updateWeather, setUpdateWeather] = useState(false)
    const [leadStaff, setLeadStaff] = useState(null)
    useEffect(() => {
        setLog(logRef)
    }, [logRef])


    useEffect(() => {
        const filteredUsers = props.staffRef.filter(u =>
            !['Maddy', 'Blesso'].some(name => u.label.includes(name))
        )
        setStaff(filteredUsers)
    }, [props.staffRef])


    function getFirstLetters(str) {
        if (str) {
            const firstLetters = str
                .split(' ')
                .map(word => word[0])
                .join('');

            return firstLetters;
        }
        else return '';
    }

    function updateItem(item, val) {
        setLog({ ...log, [item]: val })
        updateLog(item, val)
    }





    const LogID = getFirstLetters(company.companyName) + "-" +
        `${log?.account !== undefined ? getFirstLetters(log?.account === undefined ? '' : `${log?.account?.accountName}`) : ''}`
        + '-' + moment(log?.startDate, 'x').format('MM-DD-YYYY') + "-" + "APP";


    return (
        <Row gutter={[12, 12]}>
            <Col {...fullMobile(10, 24)} style={{ paddingTop: 10 }}>
                <Typography variant='h5' fontWeight={500} fontSize={22}>General Info</Typography>
                {log.startDate !== undefined && (<LogWeather {...props} log={log}
                    setLog={{ ...log, leadStaff: log.leadStaff || leadStaff, startDate: log.startDate }}
                    updateHistory={updateHistory}
                    updateItem={updateWeatherItem}
                    updateWeatherProps={updateWeather}
                    weatherPropsUpdate={(e) => {
                        console.log(e)
                        setUpdateWeather(e)
                    }} />)}

            </Col>
            <Col {...fullMobile(14, 24)} style={{ paddingTop: 10 }}>
                <Row gutter={[screenWidth === 'xs' ? 4 : 18, screenWidth === 'xs' ? 4 : 18]}
                    style={{ paddingTop: 20, paddingLeft: screenWidth === 'xs' ? 15 : 0 }} align="middle">




                    <Col xs={24} sm={10} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                        <Typography variant="body1" fontWeight={600}>Log ID<span style={{ color: 'red' }}>*</span></Typography>
                    </Col>
                    <Col xs={24} sm={14} style={{ paddingBottom: screenWidth === 'xs' ? 12 : 0 }}>
                        <Typography variant="body1" >{LogID}</Typography>
                    </Col>
                    <Col xs={24} sm={10} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                        <Typography variant="body1" fontWeight={600}>Log #<span style={{ color: 'red' }}>*</span></Typography>
                    </Col>
                    <Col xs={24} sm={14} style={{ paddingBottom: screenWidth === 'xs' ? 12 : 0 }}>
                        {props.status === 'built' ? <Typography variant="body1">{log?.lognumber}</Typography> :
                            <OutlinedInput
                                notched={false} size="small" label="none" inputProps={{ min: 0 }}
                                value={log?.lognumber} type='number' fullWidth={screenWidth === 'xs' ? true : false}
                                onChange={(e) => updateLog('lognumber', e.target.value)}
                            />}
                    </Col>

                    <Col xs={24} sm={10} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                        <Typography variant="body1" fontWeight={600}>Start Date/Time<span style={{ color: 'red' }}>*</span></Typography>
                    </Col>


                    <Col xs={24} sm={14} style={{ paddingBottom: screenWidth === 'xs' ? 12 : 0 }}>
                        {props.status === 'built' ? <Typography variant="body1" >{moment(log?.startDate, 'x').format('MM/DD/YYYY h:mm a')}</Typography> :
                            <DatePicker style={{ height: '50px' }} portalId="root-portal" size="large" showTimeSelect timeFormat="h:mm a" timeIntervals={1} timeCaption="time" dateFormat="MM/dd/yyyy h:mm a"

                                selected={[undefined, '', "Invalid date"].includes(log?.startDate) ? new Date() : new Date(moment(log?.startDate, 'x'))}
                                onChange={(date) => {

                                    const unix = moment(date).format('x');
                                    updateItem('startDate', unix)
                                    //if unix is not in the future
                                    if (unix < moment().format('x')) {
                                        setUpdateWeather(unix)
                                    }
                                    else {
                                        openSnack('error', 'Cannot set weather for future date')
                                    }

                                }}
                            />}
                    </Col>
                    <Col xs={24} sm={10} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                        <Typography variant="body1" fontWeight={600}>End Date/Time<span style={{ color: 'red' }}>*</span></Typography>
                    </Col>


                    <Col xs={24} sm={14} style={{ paddingBottom: screenWidth === 'xs' ? 12 : 0 }}>
                        {props.status === 'built' ? <Typography variant="body1">{moment(log?.endDate, 'x').format('MM/DD/YYYY h:mm a')}</Typography> :
                            <DatePicker style={{ height: '50px' }} portalId="root-portal" showTimeSelect timeFormat="h:mm a" timeIntervals={1} timeCaption="time" dateFormat="MM/dd/yyyy h:mm a"
                                selected={[undefined, '', "Invalid date"].includes(log?.endDate) ? new Date() : new Date(moment(log?.endDate, 'x'))}
                                onChange={(date) => {
                                    if (date === null) { updateItem('endDate', moment().format('x')) }
                                    else { const unix = moment(date).format('x'); updateItem('endDate', unix) }
                                }}
                            />}
                    </Col>












                    <Col xs={24} sm={10} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                        <Typography variant="body1" fontWeight={600}>Lead Staff<span style={{ color: 'red' }}>*</span></Typography>
                    </Col>
                    <Col xs={24} sm={14} style={{ paddingBottom: screenWidth === 'xs' ? 12 : 0 }}>
                        {props.status === 'built' ? <Typography variant="body1">{log?.leadStaff !== undefined ? log?.leadStaff.label : ''}</Typography> : <Autocomplete
                            size="small"

                            id="combo-box-demo"
                            value={log?.leadStaff}
                            disableCloseOnSelect={false}
                            options={staff}
                            renderInput={(params) => <TextField {...params} label="Staff" />}
                            onChange={(a, b) => {
                                updateItem('leadStaff', b)
                            }}

                        />}
                    </Col>
                    <Col xs={24} sm={10} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                        <Typography variant="body1" fontWeight={600}>Additional Staff</Typography>
                    </Col>
                    <Col xs={24} sm={14} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                        {props.status === 'creating' ? <Autocomplete
                            size="small"
                            multiple={true}
                            id="combo-box-demo"
                            value={log?.additionalStaff}

                            options={staff}
                            filterSelectedOptions
                            renderInput={(params) => <TextField {...params} label="Staff" />}
                            onChange={(a, b) => {
                                updateItem('additionalStaff', b)
                            }}

                        /> : log?.additionalStaff !== undefined ? log?.additionalStaff.map((a) => {
                            return (
                                <Chip label={a.label} variant="outlined" />
                            )
                        }) : ''}
                    </Col>
                    <Col xs={24} sm={10} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                        <Typography variant="body1" fontWeight={600}>NPDES</Typography>
                    </Col>
                    <Col xs={24} sm={14} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                        {props.status === 'creating' ?
                            <Checkbox
                                checked={log?.npdes}
                                onChange={(e) => updateItem('npdes', e.target.checked)}
                            /> : log?.npdes ? 'Yes' : 'No'}



                    </Col>
                    <Col xs={24} sm={10} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                        <Typography variant="body1" fontWeight={600}>Billed</Typography>
                    </Col>
                    <Col xs={24} sm={14} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                        {props.status === 'creating' ?
                            <Switch
                                checked={log?.billed}
                                onChange={(e) => {
                                    updateItem('billed', e.target.checked)
                                    updateHistory(`Billed set to ${e.target.checked ? 'Yes' : 'No'}`)


                                }}
                                name="checkedA"
                                size="lage"
                            />


                            :
                            <Typography variant="body1">{log?.billed ? 'Yes' : 'No'}</Typography>

                        }
                    </Col>





                </Row>
            </Col>
            <Col {...fullMobile(24, 24)}>
                <Divider style={{
                    borderColor: 'rgb(148, 186, 195)',
                    borderBottomWidth: 'medium',

                }} />
            </Col>

        </Row>
    )



}