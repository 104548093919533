
import { Box, Button, Grid } from "@mui/material";
import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { Document, Outline, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;




const PDFViewerComponent = ({
    pdf
}) => {
    const [pages, setPages] = useState([]);
    const [zoomLevel, setZoomLevel] = useState(1.0);

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const [pageWidths, setPageWidths] = useState([])
    const [pageHeights, setPageHeights] = useState([])
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function zoomOut(e) {
        e.stopPropagation();
        e.preventDefault();
        setZoomLevel(prevZoomLevel => prevZoomLevel - 0.5);
    }
    function zoomIn(e) {
        e.stopPropagation();
        e.preventDefault();
        setZoomLevel(prevZoomLevel => prevZoomLevel + 0.5);
    }

    function previousPage(e) {

        e.stopPropagation();
        e.preventDefault();
        changePage(1);
    }

    function nextPage(e) {
        e.stopPropagation();
        e.preventDefault();
        changePage(1);
    }

    function onItemClick({ pageNumber: itemPageNumber }) {
        setPageNumber(itemPageNumber);
    }

    const onEachPageLoadSuccess = (page) => {
        setPageWidths(prevWidths => {
            const newWidths = [...prevWidths];
            newWidths[page.pageNumber - 1] = page.originalWidth;
            return newWidths;
        });
        setPageHeights(prevHeights => {
            const newHeights = [...prevHeights];
            console.log(page.originalHeight)
            newHeights[page.pageNumber - 1] = page.originalHeight;
            return newHeights;
        });

        console.log(`Width of page ${page.pageNumber}:`, page.originalWidth);
    };

    return (

        <Box style={{
            height: '80vh',
            overflowY: 'auto',
            width: '90vw',
            backgroundColor: '#f0f0f0',
            display: 'flex',
            justifyContent: 'center', // Align children in the center horizontally
        }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} >
                    <Box sx={{ p: 1 }}>
                        <Button style={{ textTransform: 'none', marginRight: 10, border: '1px solid gray', color: 'gray' }} onClick={zoomOut} variant="outlined" >Zoom Out</Button>
                        <Button tyle={{ textTransform: 'none', marginRight: 10, border: '1px solid gray', color: 'gray' }} onClick={zoomIn} variant="outlined" >Zoom In</Button>
                    </Box>
                </Grid>


                <Grid item xs={12} sm={12} md={12} >
                    <Document
                        zoomLevel={zoomLevel}
                        file={pdf}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        <Row>

                            <Col span={24}
                            >

                                {Array.from(new Array(numPages), (el, index) => (
                                    <Box key={`page_${index + 1}`} style={{
                                        marginTop: 10,
                                        //center pages in the middle of the div
                                        display: 'flex',
                                        justifyContent: 'center', // Align children in the center horizontally
                                        alignItems: 'center', // Align children in the center vertically

                                    }}>
                                        <Page
                                            scale={zoomLevel}
                                            width={pageWidths[index]}
                                            onLoadSuccess={onEachPageLoadSuccess}
                                            pageNumber={index + 1}
                                            renderAnnotationLayer={false}
                                            renderTextLayer={false}
                                        />
                                    </Box>
                                ))}
                            </Col>
                        </Row>




                    </Document>
                </Grid>

            </Grid>
        </Box >




    );
};

export default PDFViewerComponent;
