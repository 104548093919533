import React, { useState } from "react";
import { Autocomplete, TextField, Chip } from "@mui/material";
import { lighten } from '@mui/system';
const MultipleSelect = ({
    currentTask,
    setCurrentTask,
    variable,
}) => {

    const [selectedValues, setSelectedValues] = useState([]);
    const [options, setOptions] = useState([
        { label: 'None', value: null, color: '#3f51b5' },
        { label: 'Option 1', value: 'option1', color: '#4caf50' },
    ]);

    return (
        <Autocomplete
            fullWidth
            hiddenLabel={true}
            options={options}
            onChange={(e, newValue) => {
                setSelectedValues(newValue);
            }}
            disableCloseOnSelect
            renderInput={(params) =>
                <TextField {...params}
                    placeholder="Select"
                    variant="standard"
                    sx={{
                        '& .MuiInputBase-root': {
                            color: 'black',
                            fontWeight: 500,
                            fontSize: 14,
                        },
                    }}
                />}
            multiple
            value={selectedValues}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        variant="outlined"
                        label={option.label}
                        {...getTagProps({ index })}
                        sx={{
                            border: `2px solid ${option.color}`,
                            boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.1)',
                            backgroundColor: lighten(option.color, 0.85), // Lightens the color
                            fontSize: '13px',
                            fontWeight: 700,
                            '&:focus': {
                                borderColor: option.color,
                                backgroundColor: lighten(option.color, 0.85),
                            }
                        }}
                    />
                ))
            }

        />
    );
};

export default MultipleSelect;
