import { CircularProgress, Typography, Checkbox, ButtonGroup, Button, OutlinedInput, Select, MenuItem } from '@mui/material';
import { Col, Row } from 'antd';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { getFirestore } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import React, { useEffect, useRef, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { Image, ImageButton } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './styleText.css'; // import the CSS file for custom styles
import { useDropzone } from 'react-dropzone';
import './imageSettings.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { uploadFile } from './firestore';
import { fullMobile } from './util';
import DataColorCompact from './dataColorCompact';




export default function ImageSettings(props) {
    const {
        userInfo,
        screenWidth,
        openSnack,
        company,
        account,
        attributes,
        currentWidgetKey,
        updateAttributes,
    } = props;
    const [loading, setLoading] = useState(true);
    const [value, setValue] = useState('');
    const [columnCount, setColumnCount] = useState('single');
    const db = getFirestore();
    const timerRef = useRef(null);
    const [uploading, setUploading] = useState(false);






    useEffect(() => {

        getInfo();
        console.log(attributes);

    }, []);

    async function getInfo() {
        if (attributes.newText) {
            setValue(attributes.newText);
            setColumnCount(attributes.numberOfColumns);
            setLoading(false);
        }
        setLoading(false);
    }

    const handleEditorChange = (state) => {

        attributes.newText = state;

    };



    const Loading = () => {
        if (loading) {
            return (
                <div className="loading">
                    <CircularProgress />
                    <Typography variant="body1">Loading Data</Typography>
                </div>
            );
        }
    };

    function updateWidget(boo, val) {
        attributes[boo] = val;



    }

    const onDrop = async (acceptedFiles) => {

        //for each accepted file, upload to storage and add to firestore
        acceptedFiles.forEach(async (file) => {


            const path = `files/${userInfo.currentCompany}/widgets/${file.name}`;

            try {
                openSnack('success', 'File Uploading...')
                setUploading(true);
                const fileInfo = await uploadFile(file, path);

                //remove file extension from name
                attributes.image = fileInfo.downloadURL;


                setUploading(false);
                openSnack('success', 'File Uploaded')
            }
            catch (error) {
                console.log(error)
                openSnack('error', 'File Upload Failed')
            }

        });
    };
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    if (loading) {
        return <Loading />;
    }
    else {
        return (
            <Row gutter={[8, 8]} style={{ height: '100%', width: '100%', display: 'flex' }}>
                <Col span={24}>
                    <Typography variant="body1" display="inline">Transparent Background</Typography>   <Checkbox display="inline"
                        defaultChecked={attributes.transparentBackground}
                        onChange={(e) => {
                            console.log(e.target.checked);
                            attributes.transparentBackground = e.target.checked;
                        }}
                    />
                </Col>

                <Col span={24}>
                    <Typography variant="h5">Image</Typography>
                </Col>

                <Col span={24}>
                    {uploading === true ? (
                        <div className="dropzone drag-active">
                            <FontAwesomeIcon icon={faSpinner} spin />
                            <Typography variant="body1">Uploading...</Typography>
                        </div>
                    ) : attributes.image ? (
                        <div
                            className={`dropzone${isDragActive ? ' drag-active' : ''}`}
                            {...getRootProps()}
                            style={{ height: '300px', backgroundSize: 'cover', backgroundImage: `url(${attributes.image})` }}
                        >
                            <input {...getInputProps({ multiple: false, accept: "image/*" })} />
                            <Typography
                                variant="body1"
                                fontSize={16}
                                fontWeight={400}
                                style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', padding: '5px' }}
                            >
                                Drag 'n' drop to replace widget image
                            </Typography>
                        </div>
                    ) : (
                        <div
                            className={`dropzone${isDragActive ? ' drag-active' : ''}`}
                            {...getRootProps()}
                            style={{ height: '300px' }}
                        >
                            <input {...getInputProps({ multiple: false, accept: "image/*" })} />
                            <Typography variant="body1" fontSize={16} fontWeight={400}>
                                Drag 'n' drop widget image here
                            </Typography>
                        </div>
                    )}
                </Col>

                <Col {...fullMobile(6, 12)} style={{ textAlign: 'center' }}>
                    <Row>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <Typography fontSize={13} variant="body1">Title Font Size</Typography>
                        </Col>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                defaultValue={attributes.titleFontSize || 24}
                                type="number"
                                inputProps={{ min: 0 }}
                                onChange={(e) => {
                                    updateWidget('titleFontSize', e.target.value === "" ? "" : Number(e.target.value))
                                }} />
                        </Col>
                    </Row>
                </Col>
                <Col {...fullMobile(6, 12)} style={{ textAlign: 'center' }}>
                    <Row>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <Typography fontSize={13} variant="body1">Title Font Weight</Typography>
                        </Col>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <Select size="small" fullWidth
                                notched={false}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                defaultValue={attributes.titleFontWeight || 300}
                                label="Layer"
                                onChange={(e) => {
                                    updateWidget('titleFontWeight', e.target.value)
                                }}

                            >
                                <MenuItem value={400}>400</MenuItem>
                                <MenuItem value={500}>500</MenuItem>
                                <MenuItem value={600}>600</MenuItem>
                                <MenuItem value={700}>700</MenuItem>
                                <MenuItem value={800}>800</MenuItem>
                            </Select>
                        </Col>
                    </Row>

                </Col>
                <Col {...fullMobile(6, 12)} style={{ textAlign: 'center' }}>
                    <Row>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <Typography fontSize={13} variant="body1">Title Font Color</Typography>
                        </Col>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <DataColorCompact color={attributes.titleFontColor || '#000000'}
                                updateData={(boo, val) =>
                                    updateWidget('titleFontColor', val)}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row >
        );
    }
}
