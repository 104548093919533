import React, { useEffect, useState } from "react";


import { Badge, Box, Button, Checkbox, Divider, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { Col, Row, Table } from 'antd';
import moment from 'moment';
import './newStyle.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faCopy, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import uuid from "react-uuid";
import MoreItemOptions from "./moreItemOptions";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ItemPicker from "./components/itemPicker";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import ItemRowBody from "./tableData/rowBody";
import Settings from "./settings";
import HideOptions from "./tableData/components/hideOtherInformation";
import HideRow from "./hideRow";
import SettingsButtons from "./settingsButtons";

const ItemsTableData = ({
    rows,
    setRows,
    setEditingRecord,
    editingRecord,
    selectedRows,
    setSelectedRows,
    setLog,
    deleteRow,
    duplicateRow,
    log,
    stocks,
    setStocks,
    items,
    setItems,
    screenWidth,
    openSnack,
    userInfo,
    updateRecord,
    updateRowPositions,
    accounts,
    stations,
    setStations,
    accountUsers,
    account
}) => {

    const props = {

    }


    const [hoverKey, setHoverKey] = useState(null);
    const [disableDrag, setDisableDrag] = useState(false);



    const getItemStyle = (isDragging, draggableStyle) => ({

        ...draggableStyle
    });

    const getListStyle = isDraggingOver => ({



    });

    const onDragEnd = (result) => {

        const { source, destination } = result;
        if (!destination) {
            return;
        }
        if (source.index === destination.index) {
            return;
        }
        const newRows = [...rows];
        const [removed] = newRows.splice(source.index, 1);
        newRows.splice(destination.index, 0, removed);
        updateRowPositions(newRows);





    };




    return (






        <Col span={24} style={{ paddingBottom: 30, paddingRight: 0, marginRight: 0, }}>


            <DragDropContext onDragEnd={(source) => onDragEnd(source)}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                        >
                            {rows?.map((record, index) => {








                                return (
                                    <Draggable key={record.id} draggableId={record.id} index={index} isDragDisabled={disableDrag}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                            >
                                                <div



                                                    style={{
                                                        display: 'inline-flex',
                                                        width: '100%',
                                                        backgroundColor: index % 2 === 0 ? '#f2f9ff' : 'rgba(0,0,0,0.1)',
                                                        //nowrap
                                                        flexWrap: 'nowrap',
                                                        cursor: 'pointer',
                                                        borderRadius: '5px',
                                                        marginBottom: '10px',
                                                        boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.2)',

                                                        border: selectedRows.includes(record.id) ? '2px solid blue' :
                                                            '1px solid rgba(0,0,0,0.4)'

                                                    }}
                                                    className={`row-hover-effect ${""}`}
                                                    onMouseEnter={() => setHoverKey(record.id)}
                                                    onMouseLeave={() => setHoverKey(null)}
                                                >



                                                    <Box key={index} style={{ width: '100%', }}
                                                        sx={{ py: 2, px: 1 }}

                                                    >


                                                        <Grid container spacing={1}
                                                            alignItems={'center'}

                                                            style={{ width: '100%', }} >

                                                            <Grid item xs={2} sm={1}
                                                                style={{ display: 'flex', justifyContent: 'left' }}
                                                            >
                                                                <div className="box" style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    width: 20,
                                                                }}>
                                                                    <div className="my-handleTest" style={{ display: 'inline' }}>
                                                                        <DragIndicatorIcon />
                                                                    </div>

                                                                </div>
                                                                <FontAwesomeIcon
                                                                    icon={selectedRows.includes(record.id) ? faCheckSquare : faSquare}
                                                                    size="xl"
                                                                    color={'#3f51b5'}
                                                                    style={{
                                                                        marginLeft: '10px'

                                                                    }}


                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        if (selectedRows.includes(record.id)) {
                                                                            setSelectedRows(selectedRows.filter(x => x !== record.id));
                                                                        } else {
                                                                            setSelectedRows([...selectedRows, record.id]);
                                                                        }
                                                                    }} />
                                                            </Grid>

                                                            <Grid item sm={11} xs={10}
                                                                /* style so that items are at the ends of the grid item */
                                                                style={{ display: 'flex', justifyContent: 'space-between' }}
                                                            >
                                                                <Grid container style={{ width: '100%', }}
                                                                    sx={{ p: 1 }}
                                                                >
                                                                    <Grid item xs={12} sm={9} style={{}}>
                                                                        <ItemPicker
                                                                            updateRecord={updateRecord}
                                                                            record={record}
                                                                            items={items}
                                                                            setItems={setItems}
                                                                            openSnack={openSnack}
                                                                            userInfo={userInfo}
                                                                            screenWidth={screenWidth}
                                                                        />
                                                                    </Grid>

                                                                    <Grid item xs={3} sm={1} style={{ textAlign: 'center' }}>
                                                                        <Settings record={record} updateRecord={updateRecord} duplicateRow={duplicateRow} deleteRow={deleteRow} openSnack={openSnack} />
                                                                    </Grid>
                                                                    <Grid item xs={3} sm={1} style={{ textAlign: 'center' }}>
                                                                        <HideRow record={record} updateRecord={updateRecord} val={'hide'} openSnack={openSnack} />
                                                                    </Grid>
                                                                    <Grid item xs={3} sm={1} style={{ textAlign: 'center' }}>
                                                                        <MoreItemOptions record={record} duplicateRow={duplicateRow} deleteRow={deleteRow} openSnack={openSnack} />
                                                                    </Grid>

                                                                </Grid>



                                                            </Grid>
                                                            <Grid item xs={12}  >
                                                                {record?.hide === false && (
                                                                    <ItemRowBody
                                                                        updateRecord={updateRecord}
                                                                        record={record}
                                                                        items={items}
                                                                        setItems={setItems}
                                                                        openSnack={openSnack}
                                                                        userInfo={userInfo}
                                                                        screenWidth={screenWidth}
                                                                        stocks={stocks}
                                                                        setStocks={setStocks}
                                                                        accounts={accounts}
                                                                        stations={stations}
                                                                        setStations={setStations}
                                                                        accountUsers={accountUsers}
                                                                        log={log}
                                                                        account={account}
                                                                        setDisableDrag={setDisableDrag}
                                                                    />
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    </Box>








                                                </div>
                                            </div>
                                        )
                                        }
                                    </Draggable>




                                );

                            })}


                        </div>
                    )
                    }
                </Droppable>
            </DragDropContext>
        </Col >



    );


};

export default ItemsTableData;
