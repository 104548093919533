import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import { Drawer, Button, List, Divider, ListItem, ListItemButton, ListITemIcon, ListItemText, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { faArrowRightFromBracket, faSave, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataEditorHeader from './dataEditorHeader';
import DataEditorChart from './dataEditorChart';
import DataEditorTable from './dataEditorTable';


import ToolsBarHome from './toolsBar/toolsBarHome';
import { updateSelectTool } from './utils/toolFunctionsSelect';
import { updateTransformTool } from './utils/toolFunctionsTransform';

const DataEditorHome = ({ openEditor, setOpenEditor, attributes, widgetKey, userInfo, openSnack, disableTooltip, enableTooltip, screenWidth, account }) => {


    const [chartData, setChartData] = useState([]);
    const [loading, setLoading] = useState('loadingChartData');
    const [tools, setTools] = useState(null)
    const chartRef = useRef(null)
    const [chartHeight, setChartHeight] = useState(0)
    const chartWrapper = useRef(null);
    const [updatedData, setUpdatedData] = useState([])


    const [selectType, setSelectType] = useState('select')
    const [mouseWheel, setMouseWheel] = useState('zoom')
    const [panKey, setPanKey] = useState('shift')
    const [tooltip, setTooltip] = useState(false)
    const [panning, setPanning] = useState(true)
    const [zoomType, setZoomType] = useState('xy')


    const [selectedPoints, setSelectedPoints] = useState([]);





    async function updateChartHeight() {
        if (chartWrapper.current) {
            setChartHeight(chartWrapper.current.clientHeight)
        }
    }





    const flattenedArray = chartData
        ? Object.values(chartData)?.flatMap((series) =>
            series.map((dataPoint) =>
            ({
                ...dataPoint.custom,
                id: dataPoint.id,
                offset: dataPoint.offset,
                x: dataPoint.x,
                y: dataPoint.y,

            })
            )
            // Handle the case where series.data is not an array
        )
        : [];


    const selectedKeys = selectedPoints.map((point) => point.id);



    const selectedData = flattenedArray.filter((point) => selectedKeys.includes(point.id));



    /* detect change in chartWrapper height */
    useEffect(() => {
        if (chartWrapper.current) {
            setChartHeight(chartWrapper.current.clientHeight)
        }
    }
        , [chartWrapper.current?.clientHeight])

    const props = {
        openEditor, setOpenEditor, widgetKey, userInfo, attributes, chartData, setChartData, screenWidth, account,
        openSnack, selectedPoints, setSelectedPoints, chartHeight,
        updateChartHeight, updateChartData, updatedData, setUpdatedData,
        tooltip, panning, panKey, zoomType, mouseWheel, selectType, setTooltip, setPanning, setPanKey, setZoomType, setMouseWheel, setSelectType

    };


    async function updateChartData(data) {
        // Assuming `updatedData` holds the current state
        // Compare `data` with `updatedData` to decide whether to update
        if (JSON.stringify(data) !== JSON.stringify(updatedData)) {
            setUpdatedData(data); // Update only if `data` is different



            const newChartData = { ...chartData };
            data.forEach((item) => {
                const series = chartData[item.seriesKey];
                const point = series.find((point) => point.id === item.id);
                point.x = item.value;
            });


        } else {
            // If they are the same, do nothing
            console.log("Data is the same as previous state, no update performed.");
        }
    }


    return (

        <Drawer
            anchor={'right'}
            open={openEditor}
            onClose={() => {
                console.log('CLOSING EDITOR')
                setOpenEditor(false)
                enableTooltip(attributes)
            }}

        >
            <Box style={{ width: '95vw', display: 'flex', flexDirection: 'column' }}>
                <Box className='toolbar' style={{ height: '100vh', width: '300px', backgroundColor: '#292929', position: 'fixed' }}>

                    <ToolsBarHome {...props} />
                </Box>
                <Box className='body' style={{ marginLeft: '300px', display: 'flex', minHeight: '100vh', flexDirection: 'column', }}>
                    <Box style={{ textAlign: 'right' }} >
                        <DataEditorHeader {...props} />
                    </Box>
                    <Box ref={chartWrapper} style={{ flexGrow: 1, }} >


                        <DataEditorChart {...props} />

                    </Box>

                </Box>





            </Box>



        </Drawer>

    );
}

export default DataEditorHome;