
import { Box, Card, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect } from "react";
import ItemsTable from "./items/itemsTable";




const Items = (
    props
) => {

    




    return (

        <Box sx={{ flexGrow: 1, px: 2 }} >
            <ItemsTable {...props} />

        </Box>


    )
}

export default Items;