import DesignServicesIcon from '@mui/icons-material/DesignServices';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import { Breadcrumbs, Button, CircularProgress, Divider, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios, * as others from 'axios';
import moment from 'moment';
var randomColor = require('randomcolor');


export default class HistoryLog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            company: this.props.company,
            distributionList: [],
            doc: {},
            emailDoc: {},
            saveLog: false,
            history: []

        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
        if (this.props.company !== prevProps.company) {
            this.setState({ company: this.props.company })
        }


    }
    componentDidMount() {
        window.scrollTo(0, 0)
        const { userInfo, company } = this.state;
        this.queryDataDoc(userInfo);

        console.log(company)


    }

    queryDataDoc = async (userInfo) => {
        const { type } = this.state;
        const db = getFirestore();
        const docRef = doc(db, "users", userInfo.id, 'currentItems', 'currentFieldLogNew');
        const docSnap = await getDoc(docRef);



        if (docSnap.data() === undefined || docSnap.data().key === 'none') {



        }
        else {
            this.getDocItem(docSnap.data().key)
            this.setState({ testKey: docSnap.data().key })
        }
    }
    getDocItem = async (key) => {
        const { userInfo } = this.state;
        const db = getFirestore();
        const docRef = doc(db, "clients", userInfo.currentCompany, 'fieldLogsNew', key);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const history = docSnap.data().history || [];
            console.log(history)
            //JSON.parse each item and sort by date
            const sortedHistory = history.map(item => JSON.parse(item)).sort((a, b) => b.date - a.date)
            this.setState({ history: sortedHistory, })


        }
    }







    render() {

        const { account, screenWidth, company, history } = this.state;

        const Loading = (props) => {
            return (
                <div style={{ minHeight: '100vh' }}>
                    <Row justify="space-around" gutter={[32, 8]} align="middle" style={{ paddingTop: 18, }}>

                        <Col span={24} style={{ textAlign: 'center', fontSize: 24, fontFamily: 'Roboto, sans-serif', }}>
                            <CircularProgress />
                        </Col>
                    </Row>
                </div>
            )
        }



        const ReturnLink = () => {
            let navigate = useNavigate();

            return (
                <Button style={{ textTransform: 'none', fontSize: 12, }} color="inherit" variant='outlined'
                    onClick={() => {
                        navigate('/newFieldLogHome')
                    }}>Back to Log</Button>
            )
        }
        return (


            <Row align="middle" >

                <Col sm={24} style={{ paddingLeft: screenWidth === 'xs' ? 10 : 30, paddingTop: screenWidth === 'xs' ? 20 : 30, paddingBottom: 15 }}>
                    <Typography variant="h4">Log History</Typography>
                </Col>

                <Col span={24}>
                    <Divider />
                </Col>
                <Col span={22} style={{ paddingTop: 10, }}>
                    <Breadcrumbs>
                        <Link to="/fieldLogsNew" style={{ paddingLeft: screenWidth === 'xs' ? 10 : 30, textDecoration: 'none', color: 'inherit', }}>
                            Click to go to Field Logs
                        </Link>
                        <Link to="/newFieldLogHome" style={{ paddingLeft: screenWidth === 'xs' ? 10 : 30, textDecoration: 'none', color: 'inherit', }}>
                            Click to go back to Field Log
                        </Link>

                    </Breadcrumbs>
                </Col>
                <Col span={23} style={{ paddingLeft: screenWidth === 'xs' ? 10 : 30, paddingTop: 5, paddingBottom: 5 }}>
                    <Row gutter={[12, 12]} justify="end">
                        <Col >
                            <ReturnLink />
                        </Col>
                    </Row>
                </Col>
                <Col span={24} style={{ paddingLeft: screenWidth === 'xs' ? 10 : 30, }}>
                    <Row >

                        <Col span={24} style={{ paddingTop: 10, }}>
                            <Divider />
                        </Col>

                        <Col span={23} style={{ paddingTop: 10, }}>
                            {history.sort((a, b) => {
                                console.log(a)
                                return Number(moment(b.date).format('x')) - Number(moment(a.date).format('x'))
                            }).map((itemParsed, index) => {
                                //JSON parse item

                                return (
                                    <Row  style={{ borderRadius: 5, padding: 5, }}>
                                        <Col span={4} style={{ fontSize: 14, fontWeight: 'bold', }}>

                                            <Typography variant="h6" fontSize={14}>{moment(itemParsed.date).format('LLL')}</Typography>
                                        </Col>
                                        <Col span={2} style={{ fontSize: 14, fontWeight: 'bold', }}>
                                            {itemParsed.type !== undefined && itemParsed.type === 'email' && (<MarkEmailReadIcon fontSize="large" color="primary" />)}
                                            {itemParsed.type !== undefined && itemParsed.type === 'created' && (<DesignServicesIcon fontSize="large" color="primary" />)}
                                        </Col>
                                        <Col span={18} style={{ fontSize: 12, }}>

                                            <Typography variant="h6">{itemParsed.user}</Typography>

                                        </Col>
                                        <Col span={18} offset={6} style={{ fontSize: 11, }}>

                                            <Typography variant="caption">{itemParsed.message}</Typography>

                                        </Col>
                                        <Col span={24} style={{}}>
                                            <Divider />
                                        </Col>
                                    </Row>
                                )

                            })}
                        </Col>
                    </Row>
                </Col>







            </Row >

        );
    }
} 