import {
    Autocomplete,
    Divider,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import OffsetTableRow from "./OffsetTableRow";


const OffsetsTable = ({ offsets, setOffsets, updateOffset, deleteOffsets, duplicateOffset, parameters, openSnack }) => {




    return (

        offsets.map((o, index) => (
            <div>
                <OffsetTableRow key={o.id}
                    index={index}
                    offset={o}
                    updateOffset={updateOffset}
                    deleteOffsets={deleteOffsets}
                    duplicateOffset={duplicateOffset}
                    setOffsets={setOffsets}
                    offsets={offsets}
                    parameters={parameters}
                    openSnack={openSnack}

                />

                <Divider
                    //increase thickness to 2px
                    style={{ height: 1, backgroundColor: '#40405057' }}
                />

            </div>

        ))

    );
};

export default OffsetsTable;
