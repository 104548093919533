import { faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Card, Divider, Fab, IconButton, Input, InputAdornment, Paper, Typography } from '@mui/material';
import { Col, Row, Table } from 'antd';
import { collection, doc, getDocs, getFirestore, onSnapshot, query, updateDoc, where } from 'firebase/firestore';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import "../extra/hoverStyle.css";
import EBLogo from '../extra/logos/ebLogo.jpg';
import './accountList.css';
import { fullMobile } from '../waterQuality/stations/util';
import MUIDataTable from "mui-datatables";


export default class AccountList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            data: [],
            filteredData: [],
            filters: [],
            searchText: '',
            loading: true,
            type: this.props.type
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
            this.queryData()
        }
        if (this.props.type !== prevProps.type) {
            this.setState({ type: this.props.type })

        }
    }
    async componentDidMount() {
        window.scrollTo(0, 0)
        const { userInfo } = this.state;
        if (Object.values(userInfo).length === 0) {
            this.props.getUser()
        } else {
            this.queryData()
        }


        const db = getFirestore();
        //get all users
        const queryData = query(collection(db, "users"));
        const snap = await getDocs(queryData);
        const users = [];
        snap.forEach((doc) => {
            users.push({
                firstName: doc.data().firstName,
                lastName: doc.data().lastName,
                id: doc.id,
                email: doc.data().email,
            })
        }
        );


        //split users into 8 groups
        const groups = [];
        for (let i = 0; i < users.length; i += 8) {
            groups.push(users.slice(i, i + 8));
        }



    }

    queryData = async () => {
        const { userInfo, type } = this.state;
        const db = getFirestore();

        const USERS = ["EuEZ93ByFZYs8bSUXoYsTt1zu0m2", 'eTevYsI9mQMxhtntd95HmRGasNx2', 'PQWFmTLCP9XqafR9ogNI3Fg1sH43', 'z4y8XVrxYGT7rO4R01Y8tQ2F5z83', 't9bOfGDqwDaQTYV6i008opscpjz2' ];
        const BASE_QUERY = collection(db, "clients", userInfo.currentCompany, 'accounts');

        const q = query(BASE_QUERY, where('archived', '==', false));
        const qPublic = query(BASE_QUERY, where('archived', '==', false), where('publicAccount', '==', true));

        const queryResult = type === 'publice' ? qPublic : q;

        const constructData = (doc) => ({
            accountName: doc.data().accountName,
            key: doc.data().key,
            seasonal: doc.data().seasonal || null,
            city: doc.data().city || null,
            countyName: doc.data().countyName || null,
            state: doc.data().state || null,
            routeDay: doc.data().routeDay || null,
            routeName: doc.data().routeName || null,
            visible: true,
            siteType: doc.data().siteType || null, // This field was added to match both conditions
        });

        const unsubscribe = onSnapshot(queryResult, (snap) => {
            const data = [];
            snap.forEach((doc) => {
                if (type === 'public' || USERS.includes(userInfo.id) || userInfo.accounts.includes(doc.data().key)) {
                    data.push(constructData(doc));
                }
            });
            data.sort((a, b) => a.accountName.localeCompare(b.accountName));
            this.setState({ data, loading: false });
            this.filterData(data, '', []);
        });
    }

    filterData = (data, searchText, filters) => {
        if (searchText.length === 0 && filters.length === 0) {
            const filteredData = data.map((d) => { return ({ ...d, visible: true }) })
            this.setState({ filteredData, })
        }
        else {
            const filteredData = data.filter(d => d.accountName.match(new RegExp(searchText, "i")))
            this.setState({ filteredData })
        }

    }

    render() {

        const { userInfo, data, filteredData, loading, searchText, filters, type } = this.state;
        const db = getFirestore();
        const ViewItem = ({ row }) => {
            let navigate = useNavigate();
            return (
                <IconButton color="primary" size="small" onClick={async () => {

                    const userRef = doc(db, "users", userInfo.id);

                    // Set the "capital" field of the city 'DC'
                    await updateDoc(userRef, {
                        currentAccount: row.key
                    }).then(() => {
                        navigate("/account");
                    })
                }}>
                    <FontAwesomeIcon icon={faEdit} style={{ color: "#404050" }} />
                </IconButton>
            )

        }


        const columns = [
            {
                title: 'Site Name',
                dataIndex: 'accountName',
                key: 'name',
                sorter: (a, b) => {
                    const c = a.accountName === null ? "" : a.accountName;
                    const d = b.accountName === null ? "" : b.accountName;
                    return c.localeCompare(d);
                }
            },
            {
                title: 'Type',
                dataIndex: 'siteType',
                key: 'siteType',
                render: (a) => {
                    if (a === null) {
                        return <div>None</div>;
                    } else {
                        let capitalized = a.charAt(0).toUpperCase() + a.slice(1);
                        return <div>{capitalized}</div>;
                    }
                },
                sorter: (a, b) => {
                    const c = a.siteType === null ? "" : a.siteType;
                    const d = b.siteType === null ? "" : b.siteType;
                    return c.localeCompare(d);
                }
            },


            {
                title: 'County',
                dataIndex: 'countyName',
                key: 'countyName',
                render: (a) => <div style={{ textTransform: 'capitalize' }}>{a === null ? "" : a}</div>,
                sorter: (a, b) => {
                    const c = a.countyName === null ? "" : a.countyName;
                    const d = b.countyName === null ? "" : b.countyName;
                    return c.localeCompare(d);
                }
            },
            {
                title: 'City',
                dataIndex: 'city',
                key: 'city',
                render: (a) => <div style={{ textTransform: 'capitalize' }}>{a === null ? "" : a}</div>,
                sorter: (a, b) => {
                    const c = a.city === null ? "" : a.city;
                    const d = b.city === null ? "" : b.city;
                    return c.localeCompare(d);
                }
            },

            {
                title: '',
                dataIndex: '',
                key: 'none',
                render: (a, row) => type !== 'public' ? <ViewItem row={row} /> : null,
            }

        ];


        const seasonalColumn = {
            title: 'Seasonal',
            dataIndex: 'seasonal',
            key: 'seasonal',
            render: (a) => <div>{a === null ? "None" : a === 'nonSeasonal' ? "Non-Seasonal" : "Seasonal"}</div>,
            sorter: (a, b) => {
                const c = a.seasonal === null ? "" : a.seasonal;
                const d = b.seasonal === null ? "" : b.seasonal;
                return c.localeCompare(d);
            }
        }
        const routes = [
            {
                title: 'Route Day',
                dataIndex: 'routeDay',
                key: 'routeDay',
                render: (a) => <div style={{ textTransform: 'capitalize' }}>{a === null ? "" : a}</div>,
                sorter: (a, b) => {
                    const c = a?.routeDay === null ? "" : a?.routeDay;
                    const d = b?.routeDay === null ? "" : b?.routeDay;
                    return c.localeCompare(d);
                }
            },
            {
                title: 'Route',
                dataIndex: 'route',
                key: 'route',
                render: (a) => <div style={{ textTransform: 'capitalize' }}>{a === null ? "" : a}</div>,
                sorter: (a, b) => {
                    const c = a?.route || "";
                    const d = b?.route || "";
                    return c.localeCompare(d);
                }
            },
        ]


        if (['Waterworks Aquatic Management2d876562-17f9-3d51-eaa4-ea3cc595f53c', 'e467d47-003-6463-6e6b-ee08215ca686'].includes(userInfo.currentCompany)) {
            columns.splice(2, 0, seasonalColumn); // 3 because array index starts from 0
        }
        if (['Waterworks Aquatic Management2d876562-17f9-3d51-eaa4-ea3cc595f53c', 'e467d47-003-6463-6e6b-ee08215ca686'].includes(userInfo.currentCompany)) {
            columns.splice(5, 0, routes[0]); // 3 because array index starts from 0
            columns.splice(6, 0, routes[1]); // 3 because array index starts from 0
        }

        const ClickableTable = () => {
            let navigate = useNavigate();
            return (
                <Table
                    size="small"
                    loading={loading}
                    style={{
                        fontFamily: 'inter, sans-serif', cursor: 'pointer', fontSize: 16, fontWeight: 400, backgroundColor: 'white',
                        //change color for every other row
                        '& tr:nth-of-type(odd)': {
                            backgroundColor: '#2196f326',
                        },

                    }}
                    columns={columns}
                    dataSource={filteredData}
                    scroll={{ x: 'auto' }}
                    pagination={{ pageSize: 100 }}
                    rowClassName={(record, index) => {
                        return index % 2 === 1 ? 'odd-row' : '';
                    }}
                    onRow={(row, rowIndex) => {
                        return {
                            onClick: async (event) => {

                                if (type === 'public') {
                                    this.props.changeAccount(row.key)
                                }
                                else {
                                    const userRef = doc(db, "users", userInfo.id);

                                    // Set the "capital" field of the city 'DC'
                                    await updateDoc(userRef, {
                                        currentAccount: row.key
                                    }).then(() => {
                                        this.props.updateUserInfo('currentAccount', row.key)
                                        navigate("/account");
                                    })
                                }


                            }, // click row
                        };
                    }}
                />
            )

        }
        const CreateItemButton = () => {
            let navigate = useNavigate();

            return (
                <Button
                    style={{
                        textTransform: 'none',
                        backgroundColor: '#404050'
                    }}
                    fullWidth variant="contained" color="primary" size="medium"
                    onClick={async () => {
                        const userRef = doc(db, "users", userInfo.id,);
                        await updateDoc(userRef, {
                            currentAccount: 'none'
                        }).then(() => {
                            navigate("/createSite");
                        })
                    }}>
                    <FontAwesomeIcon icon={faPlus} style={{ marginRight: 10 }} />
                    Create Site
                </Button>
            )
        }

        return (
            <div style={{ fontFamily: 'inter, sans-serif', backgroundColor: '#f6f8fe', minHeight: '100vh' }}>
                <Row style={{ position: 'relative', top: 0, }}>
                    <Col span={24}>

                        {userInfo.currentCompany === '38cfab-0eb0-0c3e-f466-71ad3c4865' ?

                            <Row align="middle">
                                <Col xs={0} sm={0} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>

                                </Col>
                                <Col xs={16} sm={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>
                                    <Typography variant="h4">East Bay Regional Parks District</Typography>
                                </Col>
                                <Col xs={6} sm={4} style={{ paddingTop: 30, paddingBottom: 15, paddingRight: 15, textAlign: 'right' }}>
                                    {type !== 'public' && (<CreateItemButton />)}
                                </Col>
                                <Col span={24}>
                                    <Divider />
                                </Col>
                            </Row> :
                            <Row style={{ backgroundColor: '#ffffff', borderBottom: '2px solid #e6e6e6', }} align="middle" >

                                <Col {...fullMobile(20, 20)} style={{
                                    textAlign: 'left', paddingTop: 30,
                                    paddingBottom: 20,
                                    paddingLeft: 42,
                                }}>

                                    <Typography variant="h2" fontWeight={600} fontSize={18} style={{ textAlign: 'left', color: '#404050' }}>
                                        Welcome back
                                    </Typography>
                                    <Typography variant="h2" fontWeight={700} fontSize={32} style={{ textAlign: 'left', color: 'black', paddingBottom: 0 }}>
                                        Your Sites
                                    </Typography>


                                </Col>

                                <Col span={4} style={{ textAlign: 'left', paddingLeft: 42, paddingBottom: 20, paddingRight: 15, paddingTop: 30, }}>
                                    {type !== 'public' && (<CreateItemButton />)}

                                </Col>


                            </Row>


                        }

                    </Col>

                    <Col span={24} style={{ padding: 30 }}>

                        <Row>
                            <Col xs={24} sm={12} style={{ paddingBottom: 12 }}>
                                <Input fullWidth
                                    autoComplete='off'
                                    value={searchText}
                                    onChange={(e) => {
                                        this.setState({ searchText: e.target.value })
                                        if (e.target.value.length > 0) {
                                            this.filterData(data, e.target.value, filters)
                                        }
                                        else {
                                            this.filterData(data, '', filters)
                                        }
                                    }}
                                    id="input-with-icon-adornment"
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    }
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                onClick={() => {
                                                    this.setState({ searchText: '' })
                                                    this.filterData(data, '', filters)
                                                }}
                                            >
                                                <HighlightOffIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </Col>
                            <Col span={24}>
                                <Card
                                    sx={{ borderRadius: 2 }}
                                    elevation={4}>
                                    <ClickableTable />
                                </Card>
                            </Col>
                        </Row>


                    </Col>
                </Row>
            </div>
        );
    }
} 