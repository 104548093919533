import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import MapHome from "./mapComponents/mapHome";


const BioHome = ({
    screenWidth,
    userInfo,
    account,
    openSnack
}) => {


    const props = {
        screenWidth,
        userInfo,
        account,
        openSnack
    };








    return (
        <Row style={{ height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
            {/* Using the full height of the viewport to center content */}
            <Col span={24} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <MapHome {...props} />

            </Col>
        </Row>
    )









};

export default BioHome;
