import React, { useEffect, useState } from "react";


import { faBox, faBucket, faCheck, faFish, faFlask, faListCheck, faMagnifyingGlass, faPlus, faWheatAlt, faWrench, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, ButtonGroup, Divider, FormControl, Grid, IconButton, Input, InputLabel, MenuItem, Modal, OutlinedInput, Select, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import { makeStyles } from "@mui/styles";
import StocksSelect from "../../../../../../tasks/components/drawercomponents/stockSelect";
import Temperature from "./weatherComponents/temperature";
import Rain from "./weatherComponents/rain";
import DailyRainfall from "./weatherComponents/dailyRainfall";
import Wind from "./weatherComponents/wind";
import HideOptions from "./hideOtherInformation";
import GetCurrentWeather from "./weatherComponents/getCurrentWeather";



const WeatherBox = (
    {
        record,
        items,
        setItems,
        screenWidth,
        openSnack,
        userInfo,
        updateRecord,
        stocks,
        setStocks,
        accounts,
        account

    }



) => {
    const [isFocused, setIsFocused] = useState(false);

    const props = {
        record,
        items,
        setItems,
        screenWidth,
        openSnack,
        userInfo,
        updateRecord,
        stocks,
        setStocks,
        accounts,
        account
    };


    return (
        <>



            <Grid container spacing={1} style={{ paddingBottom: 10 }}>

                <Grid item xs={12} md={12}
                    style={{
                        /* flex and space-between */
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}>
                    <Typography variant="h6" component="div" color={'rgba(0,0,0,0.8)'} fontWeight={800} fontSize={15} style={{ display: 'inline' }}>
                        Weather
                    </Typography>
                    <Rain  {...props} />
                    <GetCurrentWeather  {...props} />
                    <HideOptions  {...props} val={'showWeather'} />
                </Grid>
                <Grid item xs={12} md={record?.showMap === false ? 2 : 4}>
                    <Temperature  {...props} />
                </Grid>

                <Grid item xs={12} md={record?.showMap === false ? 2 : 4}>
                    <DailyRainfall  {...props} />
                </Grid>
                <Grid item xs={12} md={record?.showMap === false ? 2 : 4}>
                    <Wind  {...props} />
                </Grid>



            </Grid>






        </>



    );
};

export default WeatherBox;
