import { saveGraphicsLayer } from "./labels";
import * as geometryEngine from "@arcgis/core/geometry/geometryEngine";
import Graphic from "@arcgis/core/Graphic";
import TextSymbol from "@arcgis/core/symbols/TextSymbol";


export const updateShapeSymbols = async (graphicsLayer, boo, val) => {
    try {
        graphicsLayer.graphics.forEach(graphic => {
            if (graphic.symbol && graphic.symbol.type !== "text") {
                let updatedSymbol = graphic.symbol.clone();
                updatedSymbol[boo] = val.rgb;
                graphic.symbol = updatedSymbol;
            }
        });


        return 'success';
    }
    catch (error) {
        console.error("Error querying data: ", error);
        return 'success';
    }
}

export const updateShapeLabels = async (graphicsLayer, boo, val) => {
    try {
        console.log("UPDATING LABEL LOOKS")
        graphicsLayer.graphics.forEach(graphic => {
            if (graphic.symbol && graphic.symbol.type === "text") {
                let updatedSymbol = graphic.symbol.clone();
                updatedSymbol.font = val?.font
                updatedSymbol.color = val?.color
                updatedSymbol.haloColor = val?.haloColor
                graphic.symbol = updatedSymbol;
            }
        });


        return 'success';
    }
    catch (error) {
        console.error("Error querying data: ", error);
        return 'success';
    }
}



export const toggleLabels = async (graphicsLayer, boo, labelAttributes, layerColor, mapUnits, userInfo, record) => {
    try {
        graphicsLayer.graphics.forEach(graphic => {
            if (graphic.attributes && graphic.attributes.type === 'label') {
                graphic.visible = boo;
            }
        });
        console.log('THIS THING ON 9?')
        saveGraphicsLayer({ graphicsLayer, labelAttributes, layerColor, boo, mapUnits, userInfo, record })


        return 'success';
    }
    catch (error) {
        console.error("Error querying data: ", error);
        return 'success';
    }
}



export async function updateLabelText(graphicsLayer, newUnits, labelAttributes, layerColor, showLabels, userInfo, record) {
    // Get all graphics that are labels
    const labels = graphicsLayer.graphics.toArray().filter(graphic => graphic.attributes && graphic.attributes.type === 'label');

    labels.forEach(labelGraphic => {
        const graphic = labelGraphic.clone(); // Clone the graphic to modify
        /* find the associated non label graphic based on uuid attribute */
        const associatedGraphic = graphicsLayer.graphics.find(g => g.attributes.uuid === graphic.attributes.uuid && g.attributes.type === 'item');
        const geometry = associatedGraphic.geometry;
        let number = graphic.attributes.labelNumber; // Or however you track unique label numbers
        let newLabel;
        console.log(geometry?.type)
        newLabel = generateShapeLabel(geometry, newUnits, newLabel, number);

        // Create a new TextSymbol or modify the existing one with the new label
        const newTextSymbol = new TextSymbol({
            text: newLabel,
            color: labelAttributes?.color || "black",
            haloColor: labelAttributes?.haloColor || "white",
            haloSize: "2px",
            font: {  // autocast as Font
                size: labelAttributes?.font?.size || 16,
                family: "sans-serif"
            },
            yoffset: -10,  // Adjust y offset as needed
        });

        // Update the graphic's symbol with the new text
        graphic.symbol = newTextSymbol;

        // Replace the old graphic with the updated one in the layer
        graphicsLayer.remove(labelGraphic);
        graphicsLayer.add(graphic);
    });

    // Save or refresh the layer as needed
    // saveGraphicsLayer({ graphicsLayer, ... });

    saveGraphicsLayer({ graphicsLayer, labelAttributes, layerColor, showLabels, mapUnits: newUnits, userInfo, record })

}


export function generateShapeLabel(geometry, newUnits, newLabel, number, type) {     // Determine the type of geometry and calculate new label based on newUnits
    let itemLabel = '';
    switch (geometry.type) {
        case "polyline":
            const length = geometryEngine.geodesicLength(geometry, newUnits.distance);
            // Format length based on unit type
            if (type === 'logTable') {
                itemLabel = length ? `Length #${number} (${formatLength(length, newUnits.distance)})` : `Length #${number}: N/A`;
            }
            else {
                newLabel = length ? `Length #${number}\n${formatLength(length, newUnits.distance)}` : `Length #${number}: N/A`;
            }
            break;
        case "polygon":
            const area = geometryEngine.geodesicArea(geometry, newUnits.area);
            // Format area based on unit type
            if (type === 'logTable') {
                itemLabel = area ? `Area #${number} (${formatArea(area, newUnits.area)})` : `Area #${number}: N/A`;
            }
            else {
                newLabel = area ? `Area #${number}\n${formatArea(area, newUnits.area)}` : `Area #${number}: N/A`;
            }

            break;
        case "point":
            itemLabel = `Point #${number}`;
            break;
        default:
            newLabel = `Geometry #${number}`;
    }

    return type === 'logTable' ? itemLabel : newLabel;
}


// Function to format length based on unit
export function formatLength(length, unit) {
    let formattedLength = length;
    if (unit === 'feet') {
        formattedLength = length.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    } else {
        // Default formatting for other units with 1 decimal place
        formattedLength = length.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 });
    }
    return `${formattedLength} ${unit}`;
}


export function formatArea(area, unit) {
    // Function to format area based on unit

    let formattedArea = area.toLocaleString(undefined, {
        minimumFractionDigits: unit === 'square-feet' ? 0 : 0,
        maximumFractionDigits: unit === 'acres' ? 2 : 1
    });

    switch (unit) {
        case 'square-feet':
            return `${formattedArea} ft\u00B2`;  // "ft²"
        case 'acres':
            return `${formattedArea} acres`;  // keep as is
        case 'square-meters':
            return `${formattedArea} m\u00B2`;  // "m²"
        case 'square-yards':
            return `${formattedArea} yd\u00B2`;  // "yd²"
        // ... add more cases as needed
        default:
            return `${formattedArea} ${unit}`;  // Generic formatting
    }
}


export function calculateTotalAreaAndLength(graphics, newUnits) {
    let totalArea = 0;
    let totalLength = 0;

    graphics.forEach(graphic => {
        const geometry = graphic.geometry;  // Assuming each graphic has a 'geometry' property
        const type = geometry.type;  // The type of geometry ('polyline', 'polygon', etc.)

        switch (type) {
            case "polyline":
                const length = geometryEngine.geodesicLength(geometry, newUnits.distance);
                totalLength += length; // Add up all lengths
                break;
            case "polygon":
                const area = geometryEngine.geodesicArea(geometry, newUnits.area);
                totalArea += area; // Add up all areas
                break;
            // Add more cases as necessary
        }
    });

    // Using provided formatLength and formatArea functions
    const formattedTotalLength = totalLength ? formatLength(totalLength, newUnits.distance) : "N/A";
    const formattedTotalArea = totalArea ? formatArea(totalArea, newUnits.area) : "N/A";

    return {
        totalLength: formattedTotalLength,
        totalArea: formattedTotalArea
    };
}



