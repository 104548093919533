import { collection, doc, getDoc, getDocs, getFirestore, onSnapshot, orderBy, query, setDoc, updateDoc, where } from "firebase/firestore";
import moment from 'moment-timezone';
import axios, * as others from 'axios';



export const getLogs = async (userInfo, listKey) => {
    try {
        console.log(listKey)
        console.log(userInfo)

        const db = getFirestore();
        const queryRef = collection(db, "clients", userInfo.currentCompany, listKey);
        const snapShot = await getDocs(query(queryRef, where("accountKey", "==", userInfo.currentAccount), where('archived', '==', false)));
        const logs = snapShot.docs.map(doc => {
            return (
                {
                    ...doc.data(),
                    inactive: doc.data().inactive ? doc.data().inactive : false,
                    archived: doc.data().archived ? doc.data().archived : false,
                    selected: false
                }
            )
        });
        console.log(logs)
        return logs;
    } catch (error) {
        console.error("Error querying data: ", error);
    }
};



export const getLogPreferences = async (userInfo, prefKey) => {
    try {
        const db = getFirestore();
        const docRef = doc(db, "users", userInfo.id, `${prefKey}Preferences`, userInfo.currentCompany);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            return docSnap.data();
        } else {
            // docSnap.data() will be undefined in this case
            return 'none'
        }
    } catch (error) {
        console.error("Error querying data: ", error);
    }
};
export const getCompanyPreferences = async (userInfo, prefKey) => {
    try {
        const db = getFirestore();
        const docRef = doc(db, "clients", userInfo.currentCompany, `${prefKey}Preferences`, `${prefKey}Preferences`);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            return docSnap.data();
        } else {
            // docSnap.data() will be undefined in this case
            return 'none'
        }
    } catch (error) {
        console.error("Error querying data: ", error);
    }
};

export async function updateLogPreferences(userInfo, preferences, type, prefKey) {
    try {
        const db = getFirestore();
        let docRef;

        if (type === "company") {
            docRef = doc(db, "clients", userInfo.currentCompany, `${prefKey}Preferences`, `${prefKey}Preferences`);
        } else {
            docRef = doc(db, "users", userInfo.id, `${prefKey}Preferences`, userInfo.currentCompany);
        }

        await setDoc(docRef, preferences);

    } catch (error) {
        console.error("Error updating document: ", error);
    }
};