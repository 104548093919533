import { Button, OutlinedInput, Typography } from '@mui/material';
import { Col, Modal, Row } from 'antd';
import { doc, getFirestore, setDoc, updateDoc } from 'firebase/firestore';
import React from 'react';
import "../../extra/hoverStyle.css";
import axios, * as others from 'axios';
import queryString from 'query-string';

export default class ParameterModal extends React.Component { 
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            data: [],
            filteredData: [],
            filters: [],
            searchText: '',
            loading: true,
            type: this.props.type,
            currentItem: this.props.currentItem,
            openModal: this.props.openModal,
            nextPage: '',
            token: '',
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
        if (this.props.openModal !== prevProps.openModal) {
            this.setState({ openModal: this.props.openModal })
        }
        if (this.props.currentItem !== prevProps.currentItem) {
            this.setState({ currentItem: this.props.currentItem })
        }
    }
    componentDidMount() {
      


    }




    updateCurrentItem = (boo, val) => {
        const { currentItem } = this.state;
        currentItem[boo] = val;
        this.setState({ currentItem })
    }



    render() {

        const { userInfo, account, data, filteredData, loading, searchText, filters, type, currentItem, accounts, openModal, screenWidth, } = this.state;
        const db = getFirestore();

        
        const CreateParameter = () => {
            return (
                <Button size="small" style={{ padding: 10, borderRadius: 4, textTransform: 'none', fontSize: 14, }}
                    variant='contained' color="primary"
                    onClick={async () => {
                        const { currentItem } = this.state;

                        currentItem.name = currentItem.name || "";


                        const params = data.map((d) => d.name);



                        const checks = [
                            currentItem.name.length === 0 ? false : true,
                            params.includes(currentItem.name) ? false : true,
                        ]

                        if (currentItem.name.length === 0) {
                            this.props.openSnack('error', "Parameter name required!")
                        }
                        if (params.includes(currentItem.name)) {
                            this.props.openSnack('error', "Parameter name already exists!")
                        }


                        if (!checks.includes(false)) {
                            console.log("good?")
                            console.log(currentItem)

                            const key = currentItem.key || currentItem.tempKey;
                            currentItem.archived = currentItem.archived || false;

                            currentItem.account = userInfo.currentAccount;
                            currentItem.company = userInfo.currentCompany;


                            const docRef = doc(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'parameters', key);

                            if (currentItem.key) {
                                await updateDoc(docRef, currentItem).then(() => {
                                    this.props.openSnack('success', currentItem.key ? 'Parameter updated!' : "Parameter created!")
                                })
                            }
                            else {
                                await setDoc(docRef, {
                                    ...currentItem,
                                    key: currentItem.tempKey
                                }).then(() => {
                                    this.props.openSnack('success', currentItem.key ? 'Parameter updated!' : "Parameter created!")
                                    this.props.onClose();
                                })
                            }


                        }




                    }}>{currentItem.key ? 'Update Parameter' : "Create Parameter"}</Button>
            )
        }

   

      




        return (
            

                <Modal title="" visible={openModal} onCancel={() => {
                    this.setState({ currentItem: {} })
                    setTimeout(() => {
                        this.setState({ openModal: false, currentItem: {} })
                        this.props.onClose()
                    }, 200);
                }} footer={null} width={'60%'} style={{ borderRadius: 40 }} >
                    <Row align="middle" gutter={[16, 16]} >
                        <Col xs={{ offset: 0, span: 24 }} sm={{ offset: 6, span: 18 }} style={{ paddingBottom: 12, }}>
                            <Typography variant='h5'>Parameter</Typography>
                        </Col>
                        <Col xs={{ offset: 0, span: 24 }} sm={{ offset: 6, span: 18 }} style={{ paddingBottom: 12, }}>
                            <Typography fontWeight={300} variant='body1'>Reminder, you will be able to associate all water quality samples with either the parameter ID or parameter name.
                                The parameter name will be associated as case sensitive for all uploaded CSV files.</Typography>
                        </Col>


                        <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                            <Typography variant="body1">Name<span style={{ color: 'red' }}>*</span></Typography>
                        </Col>
                        <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                            <OutlinedInput fullWidth notched={false} label="none" placeholder={"Name"} size="small"
                                value={currentItem.name || ''}
                                onChange={(e) => {
                                    this.updateCurrentItem(`name`, e.target.value)
                                }} />
                        </Col>



                       {/*  <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                            <Typography variant="body1">Units</Typography>
                        </Col>
                        <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                            <Autocomplete
                                disableClearable
                                size="small"
                                disablePortal
                                id="combo-box-demo"
                                options={
                                    UnitsArray}
                                value={currentItem.units || ''}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        size="small"
                                        label="Units"
                                        variant="outlined"
                                    />
                                )}
                                onChange={(a, b) => {
                                    this.updateCurrentItem('unitid', b.key);
                                    this.updateCurrentItem('unitLabel', b.label);
                                    this.updateCurrentItem('units', b);
                                }}

                            />
                        </Col> */}




                        < Col xs={{ offset: 0, span: 24 }} sm={{ offset: 6, span: 18 }} style={{ paddingBottom: 12, }}>
                            <CreateParameter />
                        </Col>

                    </Row>
                </Modal >



        );
    }
} 