

import { faArrowsSpin, faEllipsisH, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    IconButton,
    List,
    ListItem,
    Popover,
    Tooltip,
    Typography
} from '@mui/material';
import React from 'react';


const MoreMapOptions = ({
    resetMapOptions,
    deleteAllGraphics
    
}) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;







    return (
        <div style={{
            display: 'inline',

        }}>
            <IconButton onClick={handleClick}

            >
                <Tooltip title="More Options" placement="bottom">
                    <FontAwesomeIcon icon={faEllipsisH}
                        size="md" style={{ color: '#3f51b5', cursor: 'pointer' }} />
                </Tooltip>



            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}

                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <List sx={{
                    width: 300,
                    padding: 0,

                }}>
                    <ListItem
                        key={'createTemplate'}
                        dense
                        button
                        onClick={() => {
                            resetMapOptions()
                        }}
                        sx={{
                            borderBottom: '1px solid #e0e0e0',
                            paddingTop: 1,
                            paddingBottom: 1,
                        }}
                    >
                        <FontAwesomeIcon icon={faArrowsSpin} style={{ color: '#3f51b5', marginRight: 10 }} />
                        <Typography variant="body1" color="black" fontSize={16} fontWeight={500}>Reset map defaults</Typography>
                    </ListItem>
                    <ListItem
                        key={'bulkEdit'}
                        dense
                        button
                        onClick={() => {
                            deleteAllGraphics();
                        }}
                        sx={{
                            borderBottom: '1px solid #e0e0e0',
                            paddingTop: 1,
                            paddingBottom: 1,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrash} style={{ color: '#3f51b5', marginRight: 10 }} />
                        <Typography variant="body1" color="black" fontSize={16} fontWeight={500}>Delete all graphics</Typography>
                    </ListItem>









                </List>




            </Popover>
        </div>
    );
};

export default MoreMapOptions;
