import { Autocomplete, Button, Checkbox, Divider, FormControlLabel, OutlinedInput, Switch, TextField, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import { collection, doc, getDoc, getFirestore, onSnapshot, query, setDoc, where } from 'firebase/firestore';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { fullMobile } from '../extra/util';
import SearchIcon from '@mui/icons-material/Search';
import PublicPortalHome from './publicPortalHome';
var moment = require('moment-timezone');
const axios = require('axios').default;

export default class PublicSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            settings: {},
            accounts: [],
            loading: true,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }

    }
    componentDidMount() {
        window.scrollTo(0, 0)
        window.scrollTo(0, 0)
        const { userInfo } = this.state;
        this.getDocItem(userInfo);
        this.queryAccounts(userInfo);

        /*  const stationID = 9414290;
         const startDate = moment().subtract(1, 'days').format('YYYYMMDD');
         const endDate = moment().format('YYYYMMDD');
         const url = `https://api.tidesandcurrents.noaa.gov/api/prod/datagetter?product=water_level&application=NOS.COOPS.TAC.WL&begin_date=${startDate}&end_date=${endDate}&datum=MLLW&station=${stationID}&time_zone=LST&units=english&format=json`;
 
         axios.get(url,)
 
             .then((response) => {
                 console.log(response.data)
                 const updatedData = response.data.data.map((item) => {
                     return {
                         date: moment(item.t, 'YYYY-MM-DD HH:mm').tz("America/Los_Angeles").format("YYYY-MM-DD HH:mm"),
                         height: item.v,
                     }
                 })
                 console.log(updatedData)
             }
             )
             .catch((error) => {
                 console.log(error);
             }); */


    }

    getDocItem = async (userInfo) => {
        const db = getFirestore();
        const docRef = doc(db, "publicSettings", userInfo.currentCompany,);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const item = docSnap.data();

            this.setState({ settings: item, })
            setTimeout(() => {
                this.setState({ loading: false })
            }, 500);
        }
        else {
            //create object with string url, string password, array accounts
            const item = {
                url: '',
                password: '',
                accounts: [],
            }
            this.setState({ settings: item, loading: false, })
        }
    }

    queryAccounts = async () => {
        const { userInfo, type } = this.state;
        const db = getFirestore();


        const q = query(collection(db, "clients", userInfo.currentCompany, 'accounts'), where('archived', '==', false));

        const unsubscribe = onSnapshot(q, (snap) => {
            const data = [];
            snap.forEach((doc) => {

                if (["EuEZ93ByFZYs8bSUXoYsTt1zu0m2", 'eTevYsI9mQMxhtntd95HmRGasNx2', 'PQWFmTLCP9XqafR9ogNI3Fg1sH43', 'z4y8XVrxYGT7rO4R01Y8tQ2F5z83', 't9bOfGDqwDaQTYV6i008opscpjz2'].includes(userInfo.id)) {
                    data.push({
                        accountName: doc.data().accountName,
                        label: doc.data().accountName,
                        address: doc.data().address,
                        key: doc.data().key,
                        type: doc.data().seasonal || null,
                        city: doc.data().city || null,
                        countyName: doc.data().countyName || null,
                        state: doc.data().state || null,
                        routeDay: doc.data().routeDay || null,
                        routeName: doc.data().routeName || null,
                        visible: true
                    })
                }
                else {
                    if (userInfo.accounts.includes(doc.data().key)) {
                        data.push({
                            accountName: doc.data().accountName,
                            label: doc.data().accountName,
                            address: doc.data().address,
                            key: doc.data().key,
                            type: doc.data().seasonal || null,
                            city: doc.data().city || null,
                            countyName: doc.data().countyName || null,
                            state: doc.data().state || null,
                            routeDay: doc.data().routeDay || null,
                            routeName: doc.data().routeName || null,
                            visible: true
                        })
                    }
                }

            })

            data.sort((a, b) => a.accountName.localeCompare(b.accountName))
            this.setState({ accounts: data, })



        });



    }

    updateData = async (key, value) => {
        const { settings } = this.state;
        settings[key] = value;
        this.setState({ settings: settings })
    }

    handleCheck = (boo, val,) => {
        // Clears running timer and starts a new one each time the user types
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.toggleCheck(boo, val,);
        }, 300);
    }

    toggleCheck = async (boo, val) => {
        this.updateData(boo, val)
    }

    saveSettings = async () => {
        const { settings, userInfo } = this.state;
        const db = getFirestore();
        settings.url.toLowerCase()
        const docRef = doc(db, "publicSettings", userInfo.currentCompany,);
        await setDoc(docRef, settings, { merge: true });
        this.props.openSnack('success', 'Settings Saved')
    }


    render() {

        const { account, settings, accounts, loading, userInfo, screenWidth, openSnack } = this.state;

        const ViewPortalButton = () => {
            let navigate = useNavigate()
            return (
                <Button variant={'contained'} display="inline"
                    fullWidth
                    style={{
                        textTransform: 'none',
                        backgroundColor: '#404050', fontSize: 14,

                    }}
                    onClick={() => {
                        navigate(`/publicPreview`)

                    }}><SearchIcon /> Preview Public Portal</Button>
            )
        }

        return (
            <Row style={{}}>
                <Col span={23} style={{ paddingLeft: 30, }}>
                    {loading === false && (
                        <Row align="middle" >
                            <Col xs={24} sm={18} style={{ paddingTop: 30, paddingBottom: 15 }}>
                                <Typography variant="h4">Public Portal</Typography>
                            </Col>
                            <Col xs={24} sm={6} style={{ paddingTop: 30, paddingBottom: 15, textAlign: 'right' }}>
                                <ViewPortalButton />
                            </Col>
                            <Col span={24}>
                                <Divider />
                            </Col>
                            <Col span={23} style={{ paddingTop: 30, paddingBottom: 30 }}>
                                <Typography variant="body1">This page allows you to set the url, password, and accounts that are accessible to the public.</Typography>
                            </Col>
                            <Col {...fullMobile(16, 24)} style={{}}>
                                <Typography variant="body1" fontWeight={600} style={{ paddingBottom: 15 }}>url:</Typography>
                                <Typography variant="body2">{`This will be the part of the url after my.laketech.com/public-portal/{your url here}/
                                (e.g. value is 123, public portal will be my.laketech.com/public-portal/123).  This should be unique as it will be public to all!`}</Typography>
                                <OutlinedInput

                                    notched={false} label="none" placeholder={"URL"} size="small"
                                    defaultValue={settings.url || ''}

                                    onChange={(e) => {
                                        this.handleCheck('url', e.target.value)
                                    }} />

                                <Typography variant="body2" fontWeight={400} style={{ paddingTop: 5 }}>
                                    <a href={`www.my.LakeTech.com/public-portal/${settings.url || ""}/`}>{`www.my.LakeTech.com/public-portal/${settings.url || ""}/`}
                                    </a></Typography>
                            </Col>
                            <Col {...fullMobile(16, 24)} style={{ paddingTop: 20 }}>
                                <Typography variant="body1" fontWeight={600}>Password:</Typography>
                                <Typography style={{ paddingBottom: 15 }} variant="body2">
                                    This will be your account password for your public portal.
                                </Typography>

                                {!settings.disablePassword && (
                                    <OutlinedInput
                                        notched={false}
                                        label="none"
                                        placeholder={"Password"}
                                        size="small"
                                        defaultValue={settings.password || ''}
                                        onChange={(e) => {
                                            this.handleCheck('password', e.target.value);
                                        }}
                                    />
                                )}
                                <br />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={settings.disablePassword}
                                            onChange={(e) => {
                                                this.handleCheck('disablePassword', e.target.checked)
                                            }}
                                            color="primary"
                                        />
                                    }
                                    label="Disable password requirement"
                                />
                            </Col>
                            <Col {...fullMobile(14, 24)} style={{ paddingTop: 30, paddingBottom: 15 }}>
                                <Typography variant="body1" fontWeight={600}>Accounts:</Typography>
                                <Typography variant="body2">Select the accounts that you would like to be visible to the public.</Typography>
                            </Col>
                            <Col {...fullMobile(14, 24)} style={{ paddingTop: 10, }}>
                                <Autocomplete
                                    size="small"
                                    id="combo-box-demo"
                                    multiple
                                    defaultValue={settings.accounts}
                                    disableClearable={true}
                                    options={accounts.filter((item) => !settings.accounts.map((a) => a.key).includes(item.key))}

                                    renderInput={(params) => <TextField {...params} label="Account" />}
                                    onChange={(a, b) => {
                                        this.handleCheck('accounts', b)

                                    }}

                                />
                            </Col>
                            <Col {...fullMobile(14, 24)} style={{ paddingTop: 30, paddingBottom: 15 }}>
                                <Typography variant="body1" fontWeight={600}>Disable Rearrange:</Typography>
                                <Typography variant="body2">If on, the public dashboard will be locked in place.</Typography>
                            </Col>
                            <Col {...fullMobile(14, 24)} style={{ paddingTop: 10, }}>
                                <Switch checked={settings.disableRearrange} onChange={(e) => {
                                    this.handleCheck('disableRearrange', e.target.checked)
                                }} />
                            </Col>

                            <Col {...fullMobile(14, 24)} style={{ paddingTop: 30, paddingBottom: 15, }}>
                                <Button variant="contained" color="primary"
                                    style={{ textTransform: 'none', padding: 8 }} onClick={() => {
                                        this.saveSettings()
                                    }}>Save Settings</Button>
                            </Col>



                        </Row>
                    )
                    }
                </Col>

                {/*    <Col span={23} style={{ paddingLeft: 30, }}>
                    <PublicPortalHome openSnack={openSnack} userInfo={userInfo} screenWidth={screenWidth} accounts={accounts} />
                </Col> */}

            </Row >
        );
    }
} 