import React from 'react';
import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';

export default function AddLayersButton({ onClick }) {
    return (
        <button
            style={{
                position: 'absolute',
                zIndex: 10,
                backgroundColor: 'white',
                padding: '6px',
                borderRadius: '3px',
                cursor: 'pointer',
                top: '90px',
                right: '10px',
                color: '#666666'
            }}
            onClick={onClick}
        >
            <FontAwesomeIcon icon={faLayerGroup} size="lg" />
        </button>

    );
}