export const unitsArray = [
  { key: "1", label: "C" },
  { key: "2", label: "F" },
  { key: "17", label: "psi" },
  { key: "19", label: "kPa" },
  { key: "20", label: "bar" },
  { key: "21", label: "mbar" },
  { key: "22", label: "mm Hg" },
  { key: "23", label: "in Hg" },
  { key: "24", label: "cm H₂O" },
  { key: "25", label: "in H₂O" },
  { key: "26", label: "torr" },
  { key: "27", label: "atm" },
  { key: "33", label: "mm" },
  { key: "34", label: "cm" },
  { key: "35", label: "m" },
  { key: "36", label: "km" },
  { key: "37", label: "in" },
  { key: "38", label: "ft" },
  { key: "49", label: "°" },
  { key: "65", label: "µS/cm" },
  { key: "65s", label: "µs/cm" },
  { key: "66", label: "mS/cm" },
  { key: "66s", label: "ms/cm" },
  { key: "81", label: "Ω-cm" },
  { key: "97", label: "psu" },
  { key: "113", label: "ppm" },
  { key: "114", label: "ppt" },
  { key: "117", label: "mg/L" },
  { key: "118", label: "µg/L" },
  { key: "120", label: "g/L" },
  { key: "121", label: "ppb" },
  { key: "122", label: "QSU" },
  { key: "129", label: "g/cm³" },
  { key: "145", label: "pH" },
  { key: "162", label: "mV" },
  { key: "163", label: "V" },
  { key: "177", label: "% sat" },
  { key: "193", label: "FNU" },
  { key: "194", label: "NTU" },
  { key: "209", label: "ft³/s" },
  { key: "212", label: "ft³/day" },
  { key: "213", label: "gal/s" },
  { key: "214", label: "gal/min" },
  { key: "215", label: "gal/hr" },
  { key: "216", label: "Mgal/day" },
  { key: "217", label: "m³/s" },
  { key: "219", label: "m³/hr" },
  { key: "221", label: "L/s" },
  { key: "222", label: "ML/d" },
  { key: "223", label: "mL/min" },
  { key: "224", label: "kL/d" },
  { key: "225", label: "ft³" },
  { key: "226", label: "gal" },
  { key: "227", label: "Mgal" },
  { key: "228", label: "m³" },
  { key: "229", label: "L" },
  { key: "230", label: "ac-ft" },
  { key: "231", label: "mL" },
  { key: "232", label: "ML" },
  { key: "233", label: "kL" },
  { key: "234", label: "ac-in" },
  { key: "241", label: "%" },
  { key: "257", label: "RFU" },
  { key: "273", label: "mL/s" },
  { key: "274", label: "mL/hr" },
  { key: "275", label: "L/min" },
  { key: "276", label: "L/hr" },
  { key: "289", label: "Cells/mL" },
  { key: "290", label: "Cells/L" },
  { key: "291", label: "MPN/100 ml" },
  { key: "322", label: "Hz" },
  { key: "337", label: "ft/s" },
  { key: "338", label: "m/s" },
  { key: "339", label: "mg/L CaCO3" },
  { key: "339mg", label: "mg CaCO₃/L" },
  { key: "340", label: "CaCO₃/L" },
  { key: "yard", label: "yd" },
  { key: "acreInchPerMinute", label: "ac-in/min" },
  { key: "g/mL", label: "g/mL" },
  { key: "acreFeetPerSecond", label: "ac-ft/sec" },
  { key: "kilolitersPerMinute", label: "kL/min" },
  { key: "litersPerDay", label: "L/d" },
  { key: "acreInchPerDay", label: "ac-in/d" },
  { key: "megalitersPerMinute", label: "ML/min" },
  { key: "acreInchPerHour", label: "ac-in/hr" },
  { key: "mile", label: "mi" },
  { key: "acreFeetPerMinute", label: "ac-ft/min" },
  { key: "kilolitersPerSecond", label: "kL/sec" },
  { key: "cubicFeetPerHour", label: "ft³/hr" },
  { key: "cubicMetersPerMinute", label: "m³/min" },
  { key: "cubicMetersPerDay", label: "m³/day" },
  { key: "megalitersPerSecond", label: "ML/sec" },
  { key: "megalitersPerHour", label: "ML/hr" },
  { key: "acreFeetPerDay", label: "ac-ft/d" },
  { key: "kilolitersPerHour", label: "kL/hr" },
  { key: "millilitersPerDay", label: "mL/day" },
  { key: "acreInchPerSecond", label: "ac-in/sec" },
  { key: "acreFeetPerHour", label: "ac-ft/hr" },
  { key: "cubicFeetPerMinute", label: "ft³/min" },
  { key: "kilohertz", label: "kHz" },
  { key: "pounds", label: "pounds" },
  { key: "visual", label: "visual" },
  { key: "cells/mL", label: "cells/mL" },
  { key: "cfm", label: "cfm" },
  { key: "cfs", label: "cfs" },
  { key: "per month", label: "per month" },
  { key: "per day", label: "per day" },
  { key: "per hour", label: "per hour" },
  { key: "per minute", label: "per minute" },
  { key: "per second", label: "per second" },
  { key: "per year", label: "per year" },
  { key: "per week", label: "per week" },
  { key: "°C", label: "°C" },
  { key: "°F", label: "°F" },
];


