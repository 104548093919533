import { Col, Row } from 'antd';
import React from 'react';

import { fullMobile } from '../../extra/util';
import LoginForm from './loginForm';


export default function LoginHome({ openSnack }) {

    return (
        <Row style={{ width: '100%' }} justify="center"  >
            <Col {...fullMobile(24, 24)} style={{ position: 'relative', top: '20%' }}>
                <LoginForm openSnack={openSnack} />

            </Col>

        </Row>
    )




}
