import { DeleteForever } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { Checkbox, Divider, IconButton, OutlinedInput, Switch, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import React from 'react';
import uuid from 'react-uuid';

var randomColor = require('randomcolor');
export default class MapSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            attributes: this.props.attributes,
            currentWidgetKey: this.props.currentWidgetKey,
            locations: [],
            references: [],
            parameters: [],
            activeTab: 'chart'
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }

    }
    componentDidMount() {
        const { attributes } = this.state;
       

        if (attributes.kmlLayers === undefined) {
            attributes.kmlLayers = []
            this.setState({ attributes: attributes })
        }
    }




    updateWidget = (boo, val) => {
        const { attributes, currentWidgetKey } = this.state;
        attributes[boo] = val;
        this.setState({ attributes })
        this.props.updateAttributes(attributes, currentWidgetKey)

    }

    updateData = (index, boo, val) => {
        const { attributes, widgetKey } = this.state;
        attributes.kmlLayers[index][boo] = val;
        this.updateWidget('kmlLayers', attributes.kmlLayers)

    }


    handleCheck = (index, boo, val) => {
        // Clears running timer and starts a new one each time the user types
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.toggleCheck(index, boo, val);
        }, 1000);
    }


    toggleCheck = async (index, boo, val) => {
        const { attributes, widgetKey } = this.state;
        attributes.kmlLayers[index][boo] = val;
        this.updateWidget('kmlLayers', attributes.kmlLayers)



    }
    handleWidget = (boo, val) => {
        // Clears running timer and starts a new one each time the user types
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.toggleCheck(boo, val);
        }, 1000);
    }


    toggleWidget = async (boo, val) => {
        const { attributes, widgetKey } = this.state;
        this.updateWidget(boo, val)



    }



    render() {

        const { account, attributes, screenWidth, locations, parameters, expanded, activeTab, references, currentWidgetKey } = this.state;








        return (











            <Row justify="center" align="middle">



                <Col span={24}>
                    <Typography display={'inline'} variant={'body1'} style={{ marginRight: 10 }}>Show Probes</Typography>
                    <Switch style={{ marginRight: 10 }} display={'inline'} checked={attributes.visibleProbes || false} onChange={(e) => {
                        this.updateWidget('visibleProbes', e.target.checked)
                    }} />
                    {/*   <DataColorCompact display={'inline'} color={attributes.probesColor || 'orange'} updateData={(boo, val) => this.updateWidget('probesColor', val)} /> */}
                </Col>
                <Col span={24}>
                    <Typography display={'inline'} variant={'body1'} style={{ marginRight: 10 }}>Show Stations</Typography>
                    <Switch style={{ marginRight: 10 }} display={'inline'} checked={attributes.visibleStations || false} onChange={(e) => {
                        this.updateWidget('visibleStations', e.target.checked)
                    }} />
                    {/*   <DataColorCompact display={'inline'} color={attributes.probesColor || 'orange'} updateData={(boo, val) => this.updateWidget('probesColor', val)} /> */}
                </Col>
                <Col span={20}>
                    <Typography fontSize={14} variant='body1'>KML Layers</Typography>
                </Col>
                <Col span={4}>
                    <IconButton variant="contained" color="primary"
                        onClick={() => {

                            attributes.kmlLayers.push({
                                key: uuid(),
                                name: 'New Layer',
                                url: '',
                                visible: true
                            })
                            this.setState({ attributes })
                            this.updateWidget('kmlLayers', attributes.kmlLayers)





                        }}>
                        <AddIcon />
                    </IconButton>
                </Col>

                {attributes.kmlLayers !== undefined && (<Col span={24}>
                    {attributes.kmlLayers.map((kml, index) => {
                        return (
                            <Row align="middle" gutter={[12, 12]}>
                                <Col span={2}>

                                    <Checkbox checked={kml.visible} onChange={(e) => this.updateData(index, 'visible', e.target.checked)} />
                                </Col>
                                <Col span={12}>
                                    Name: <OutlinedInput notched={false} label="none" size="small" fullWidth defaultValue={kml.name}
                                        onChange={(e) => this.handleCheck(index, 'name', e.target.value)} />
                                </Col>
                                <Col span={12} offset={2}>
                                    URL: <OutlinedInput notched={false} label="none" size="small" fullWidth defaultValue={kml.url}
                                        onChange={(e) => this.handleCheck(index, 'url', e.target.value)} />
                                </Col>
                                <Col span={10} style={{ textAlign: 'right' }}>

                                    <IconButton variant="contained" color="primary"
                                        onClick={() => {
                                            attributes.kmlLayers.splice(index, 1);
                                            this.updateWidget('kmlLayers', attributes.kmlLayers)
                                        }}>
                                        <DeleteForever />
                                    </IconButton>
                                </Col>
                                <Col span={24}>
                                    <Divider />
                                </Col>
                            </Row>
                        )
                    })}
                </Col>)}








            </Row >
        );
    }
} 