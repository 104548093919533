import Map from "@arcgis/core/Map";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import MapView from "@arcgis/core/views/MapView";
import React, { useEffect, useRef, useState } from 'react';

import Expand from "@arcgis/core/widgets/Expand.js";
import { Button, OutlinedInput, Typography } from "@mui/material";
import Sketch from "@arcgis/core/widgets/Sketch";
import SketchViewModel from "@arcgis/core/widgets/Sketch/SketchViewModel";
import { createPolygonSymbol, createPolylineSymbol, createPointSymbol } from "./symbology/renderer";
import uuid from "react-uuid";
import {
    createFeatureService,
    addToServiceDefinition
} from '@esri/arcgis-rest-service-admin';

import { ApiKey } from '@esri/arcgis-rest-auth';


import FeatureLayer from "@arcgis/core/layers/FeatureLayer.js";
import { UserSession } from '@esri/arcgis-rest-auth';
import Editor from "@arcgis/core/widgets/Editor.js";

const apiKey = new ApiKey("AAPKb20128b2a92440859b73689ff78082bc11eq7juNEr07nic06fVkxSVDf2Q8UhSavofxgh9PXpJhhymXPw3X0Xf2f_d4hC9A");


const MapComponent = ({ account, mapInfo, userInfo, openSnack, record }) => {
    const mapDivEditor = useRef(null);
    const viewRef = useRef(null);
    const sketchRef = useRef(null);
    const sketchExpandRef = useRef(null);
    const graphicsRef = useRef(null);
    const [showLabels, setShowLabels] = useState(mapInfo?.showLabels || false);
    const [layerColor, setLayerColor] = useState(mapInfo?.layerColor || { rgb: { r: 255, g: 0, b: 0, a: 1 }, hex: '#ff0000' });
    const [labelAttributes, setLabelAttributes] = useState(mapInfo?.labelAttributes || {
        font: {
            size: 12,
        },
        color: 'black',
        haloColor: 'white',
    })

    const [mapUnits, setMapUnits] = useState(mapInfo?.mapUnits || { area: 'acres', length: 'feet' });
    const [depth, setDepth] = useState(3);



    const props = {
        showLabels,
        setShowLabels,
        layerColor,
        setLayerColor,
        labelAttributes,
        setLabelAttributes,
        mapUnits,
        setMapUnits
    };





    useEffect(() => {
        if (mapDivEditor.current) {
            console.log("LOADING MAP")

            let graphicsLayer = new GraphicsLayer({
                id: 'graphicsLayer',
                title: "Sketch Layer",
            });

            graphicsRef.current = graphicsLayer;




            /* add featurelayer to map */











            const webmap = new Map({
                basemap: "satellite",
                layers: [graphicsLayer]
            });


            const view = new MapView({
                container: mapDivEditor.current,
                map: webmap,
                center: [account?.lng, account?.lat],
                zoom: 16
            });

            viewRef.current = view;

            let sketchVM = new SketchViewModel({
                layer: graphicsLayer,
                polygonSymbol: createPolygonSymbol(layerColor),
                polylineSymbol: createPolylineSymbol(layerColor),
                pointSymbol: createPointSymbol(layerColor),
                view: view
            });

            sketchVM.on("create", async (event) => {
                if (event.state === "complete") {
                    let graphic = event.graphic;
                    if (!graphic.attributes) {
                        graphic.attributes = {};
                    }
                    // Directly use the graphicsLayer already in scope
                    const graphics = graphicsLayer.graphics.toArray();
                    const items = graphics.filter(g => g.attributes && g.attributes.type === 'item');
                    const maxLabelNumber = Math.max(...items.map(item => Number(item?.attributes?.label)), 0);
                    graphic.attributes.label = `${maxLabelNumber + 1}`;
                    graphic.attributes.type = 'item';
                    graphic.attributes.uuid = uuid();
                    graphic.attributes.depth = Math.floor(Math.random() * 15) + 1;
                    console.log(depth)


                }
            });

            sketchVM.on("delete", (event) => {
                if (event.type === "delete") {
                    // Perform actions after the graphic is deleted
                    console.log('delete1')


                }
            });

            sketchVM.on("update", (event) => {
                if (event?.toolEventInfo?.type === "move-stop") {
                    console.log('update label')
                    console.log(event)
                    // Perform actions after the graphic is updated


                }
            });



            // Initialize Sketch widget with the graphics layer
            const sketch = new Sketch({
                layer: graphicsLayer,  // The layer to which the sketch is bound
                view: view,
                id: 'sketchItem',
                creationMode: "continuous",
                viewModel: sketchVM
            });


            sketchRef.current = sketch;


            // Now use this node as the content for the Expand widget
            let sketchExpand = new Expand({
                expandIcon: "brush-mark",  // Use an appropriate icon
                collapseIcon: "caret-right",  // Use an appropriate icon
                view: view,
                content: sketch,

            });

            sketchExpandRef.current = sketchExpand;



            view.ui.add(sketchExpand, "top-right");






            return () => view && view.destroy();
        }





    }, []);

    /* update the sketch create function when depth changes */

    useEffect(() => {
        if (sketchRef.current) {
            const sketchVM = sketchRef.current.viewModel;
            sketchVM.on("create", async (event) => {
                if (event.state === "complete") {
                    let graphic = event.graphic;
                    if (!graphic.attributes) {
                        graphic.attributes = {};
                    }

                    graphic.attributes.type = 'item';
                    graphic.attributes.uuid = uuid();
                    graphic.attributes.depth = Math.floor(Math.random() * 15) + 1;
                    console.log(depth)
                }
            });
        }
    }, [depth])




    function colorByDepth(depth) {
        // Example: Simple mapping of depth to a color
        // Customize this logic as needed
        if (depth < 5) return { r: 255, g: 0, b: 0, a: 1 }; // Red for shallow depths
        if (depth < 10) return { r: 0, g: 255, b: 0, a: 1 }; // Green for medium depths
        return { r: 0, g: 0, b: 255, a: 1 }; // Blue for deeper
    }



    return (
        <div style={{ height: "100%", width: "100%" }}>
            <div style={{ height: '70vh', width: "100%" }} ref={mapDivEditor}>


            </div>

            <Typography variant="h6" style={{ textAlign: 'center' }}>
                Depth:
            </Typography>
            <OutlinedInput
                type="number"
                size="small"
                margin="dense"
                value={depth}
                onChange={(e) => setDepth(e.target.value)}
                style={{ width: '100%' }}
                inputProps={{
                    style: {
                        textAlign: 'center',
                        fontSize: '1.5rem',
                        fontWeight: 'bold'
                    }
                }}
            />
            {/* adding a color by depth button */}
            <Button
                variant="contained"
                color="primary"
                style={{ width: '100%' }}
                onClick={async () => {

                    /* separate graphics by point and polygon */

                    const graphics = graphicsRef.current.graphics.toArray();
                    const pointGraphics = graphics.filter(g => g.geometry.type === 'point');
                    const polygonGraphics = graphics.filter(g => g.geometry.type === 'polygon');

                    pointGraphics.forEach(graphic => {
                        // Assume the depth is stored in graphic.attributes.depth
                        const depth = graphic.attributes.depth;
                        console.log(depth)
                        const newColor = colorByDepth(depth);
                        // Update the symbol color based on the graphic type
                        if (graphic.symbol) {
                            if (graphic.symbol.type === "simple-marker" || graphic.symbol.type === "simple-line" || graphic.symbol.type === "simple-fill") {
                                // clone the symbol and update its color
                                let updatedSymbol = graphic.symbol.clone();
                                updatedSymbol.color = newColor; // Assuming RGBA format
                                graphic.symbol = updatedSymbol;
                            }
                        }
                    });
                    /* create a hosted featureLayer based on points*/
                    try {
                        const session = new UserSession({
                            username: "gregsmithlaketech",
                            password: "Volley15!!gis"
                        })

                        /*     createFeatureService({
                                authentication: session,
                                item: {
                                    "name": "NewEmptyService",
                                    "capabilities": "Create,Delete,Query,Update,Editing"
                                }
                            }); */
                        console.log('created service')
                    } catch (err) {
                        console.log(err)
                    }






                }}
            >
                Add Color by Depth
            </Button>
            <Button
                variant="contained"
                color="primary"
                style={{ width: '100%' }}
                onClick={async () => {

                    /* separate graphics by point and polygon */

                    const graphics = graphicsRef.current.graphics.toArray();
                    const pointGraphics = graphics.filter(g => g.geometry.type === 'point');
                    const polygonGraphics = graphics.filter(g => g.geometry.type === 'polygon');

                    console.log(polygonGraphics)


                    /* create new featurelayer */
                    const featureLayerPoints = new FeatureLayer({
                        key: "points",
                        id: "points",
                        fields: [
                            {
                                name: "ObjectID",
                                alias: "ObjectID",
                                type: "oid"
                            }, {
                                name: "depth",
                                alias: "Depth",
                                type: "double"
                            },
                            {
                                name: "label",
                                alias: "Label",
                                type: "string"
                            }

                        ],
                        geometryType: "point",
                        spatialReference: {
                            wkid: 4326
                        },
                        source: [],
                        objectIdField: "ObjectID",
                        popupTemplate: {
                            title: "Item {label}",
                            content: [
                                {
                                    type: "fields",
                                    fieldInfos: [
                                        {
                                            fieldName: "label",
                                            label: "Label",
                                            visible: true
                                        },
                                        {
                                            fieldName: "depth",
                                            label: "Depth",
                                            visible: true
                                        }
                                    ]
                                }
                            ]
                        },
                        renderer: {
                            type: "simple",
                            symbol: {
                                type: "simple-marker",
                                color: "red",
                                size: 6,
                                outline: {
                                    color: [255, 255, 255],
                                    width: 1
                                }
                            }
                        }
                    });


                    const featureLayerPolygons = new FeatureLayer({
                        key: "polygons",
                        id: "polygons",
                        fields: [
                            {
                                name: "ObjectID",
                                alias: "ObjectID",
                                type: "oid"
                            }, {
                                name: "depth",
                                alias: "Depth",
                                type: "double"
                            },
                            {
                                name: "label",
                                alias: "Label",
                                type: "string"
                            }

                        ],
                        geometryType: "polygon",
                        spatialReference: {
                            wkid: 4326
                        },
                        source: [],
                        objectIdField: "ObjectID",
                        popupTemplate: {
                            title: "Item {label}",
                            content: [
                                {
                                    type: "fields",
                                    fieldInfos: [
                                        {
                                            fieldName: "label",
                                            label: "Label Area",
                                            visible: true
                                        },
                                        {
                                            fieldName: "depth",
                                            label: "Depth",
                                            visible: true
                                        }
                                    ]
                                }
                            ]
                        },
                        renderer: {
                            type: "simple",
                            symbol: {
                                type: "simple-fill",
                                color: [255, 0, 255, .5],
                                size: 6,
                                outline: {
                                    color: [255, 0, 255, .5],
                                    width: 1
                                }
                            }
                        }
                    });

                    /* add graphics to featurelayer */
                    featureLayerPoints.source.addMany(pointGraphics);
                    featureLayerPolygons.source.addMany(polygonGraphics);





                    /* add featurelayers to map */

                    viewRef.current.map.addMany([featureLayerPoints, featureLayerPolygons]);
                    /* add editor for feature layer */

                    const editor = new Editor({
                        view: viewRef.current,
                        layerInfos: [
                            {
                                layer: featureLayerPoints,
                                fieldConfig: [
                                    {
                                        name: "label",
                                        label: "Label",
                                        editable: true
                                    },
                                    {
                                        name: "depth",
                                        label: "Depth",
                                        editable: true
                                    }
                                ]
                            },
                            {
                                layer: featureLayerPolygons,
                                fieldConfig: [
                                    {
                                        name: "label",
                                        label: "Label",
                                        editable: true
                                    },
                                    {
                                        name: "depth",
                                        label: "Depth",
                                        editable: true
                                    }
                                ]
                            }
                        ]
                    });

                    viewRef.current.ui.add(editor, "top-right");



                    /* delete point graphics from graphics */
                    graphicsRef.current.removeMany(pointGraphics);
                    graphicsRef.current.removeMany(polygonGraphics);



                }}
            >
                Create FeatureLayer
            </Button>

        </div >
    );
}

export default MapComponent;
