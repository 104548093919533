import { Button, IconButton, Tooltip as TooltipMUI } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { Col, Row } from 'antd';

import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';

import { faChevronRight, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Highcharts from 'highcharts/highstock';
import {
    HighchartsChart,
    HighchartsProvider,
    Series,
    Title,
    Tooltip,
    XAxis,
    YAxis
} from "react-jsx-highcharts";
import { convertHumidity, convertPrecipitation, convertTemperature, convertWindSpeed, getObscapeWeatherData } from './obscapeWeatherComponents/obscapeFunctions';
import "./styles.css";

import highchartsWindbarb from 'highcharts/modules/windbarb';
highchartsWindbarb(Highcharts);
const PreviewChartModalObscapeWeather = ({


    currentStream,
    openSnack,
    previewChart,
    setPreviewChart,
    userInfo,
    screenWidth
}) => {

    const handleClose = () => setPreviewChart(false);
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DDTHH:mm:ss'));
    const [startDate, setStartDate] = useState(moment().subtract(7, 'days').format('YYYY-MM-DDTHH:mm:ss'));
    const [loadingChart, setLoadingChart] = useState(true);


    const [chartData, setChartData] = useState(null);



    // Override the Highcharts prototypes
    Highcharts.Pointer.prototype.reset = () => { };
    Highcharts.Point.prototype.highlight = function (event) {
        this.onMouseOver();
        this.series.chart.tooltip.refresh(this);
        this.series.chart.xAxis[0].drawCrosshair(event, this);
    };

    useEffect(() => {
        console.log(previewChart)
        if (previewChart !== false) {
            getInitialWeatherData(previewChart, startDate, endDate)
        }


    }, [previewChart])





    async function getInitialWeatherData(previewChart, from, to) {
        try {
            setLoadingChart(true);



            const data = await getObscapeWeatherData(userInfo, previewChart, from, to);
            const times = data.map((item) => { return (Number(moment(item.time, 'X').format('x'))) })
            console.log(data)
            const tempSeries = {
                type: 'spline',
                name: 'Temperature',
                color: '#D86565',
                unit: previewChart?.unitsSystem === "US/Imperial" ? '°F' : '°C',
                lineWidth: 1,
                data: data.map(item => convertTemperature(Number(item["airTemperature"]), previewChart?.unitsSystem ))
            };
            const precipitationSeries = {
                name: 'Precipitation',
                color: '#D86565',
                columnWidth: 20,
                type: 'column',
                unit: previewChart?.unitsSystem === "US/Imperial" ? 'in' : 'mm',
                data: data.map(item => convertPrecipitation(Number(item["precipitation"]), previewChart?.unitsSystem )),

            };
            const humiditySeries = {
                type: 'spline',
                name: 'Humidity',
                color: '#D86565',
                lineWidth: 1,
                unit: '%',
                data: data.map(item => convertHumidity(Number(item["relativeHumidity"]), previewChart?.unitsSystem ))
            };
            const windSeries = {
                type: 'spline',
                name: 'Wind Speed',
                color: '#D86565',
                lineWidth: 1,
                unit: previewChart?.unitsSystem === "US/Imperial" ? 'mph' : 'm/s',
                data: data.map(item => convertWindSpeed(Number(item["windSpeed"]), previewChart?.unitsSystem )),
                dataDirection: data.map((item, i) => { return ([times[i], convertWindSpeed(Number(item["windSpeed"]), previewChart?.unitsSystem ), Number(item["windDirection"])]) })

            }

            const maxPrecipitation = Math.max(...precipitationSeries.data) + 1;

            console.log(windSeries)


            setChartData({
                "xData": times,
                "datasets": [
                    tempSeries,
                    { ...precipitationSeries, max: maxPrecipitation },
                    windSeries,
                    humiditySeries
                ]
            }
            )

            setLoadingChart(false);
        }
        catch {
            console.log("error")
            setLoadingChart(false);
        }



    }

    const changeWeek = (type) => {
        console.log(type)
        setLoadingChart(true);
        const newEndDate = type === 'next' ? moment(endDate, 'YYYY-MM-DDTHH:mm:ss').add(7, 'days').format('YYYY-MM-DDTHH:mm:ss') : moment(endDate, 'YYYY-MM-DDTHH:mm:ss').subtract(7, 'days').format('YYYY-MM-DDTHH:mm:ss');
        setEndDate(newEndDate);
        const newStartDate = type === 'next' ? moment(startDate, 'YYYY-MM-DDTHH:mm:ss').add(7, 'days').format('YYYY-MM-DDTHH:mm:ss') : moment(startDate, 'YYYY-MM-DDTHH:mm:ss').subtract(7, 'days').format('YYYY-MM-DDTHH:mm:ss');
        setStartDate(newStartDate);

        console.log(newStartDate)
        console.log(newEndDate)
        getInitialWeatherData(previewChart, newStartDate, newEndDate);
    }



    const renderChart = (dataset, index) => {
        const tooltipPositioner = function () {
            return { x: this.chart.chartWidth - this.label.width - (screenWidth === 'xs' ? 0 : 100), y: (screenWidth === 'xs' ? 20 : 10) }
        };
        const data = dataset.data.map((val, i) => [chartData.xData[i], val]);
        const colour = Highcharts.getOptions().colors[index];

        return (
            <HighchartsProvider Highcharts={Highcharts} key={dataset.name}

            >
                <HighchartsChart style={{
                    fontFamily: 'Roboto',
                }}
                    chart={{
                        height: 300,
                        backgroundColor: 'transparent',


                    }}
                    plotOptions={{
                        series: {
                            cursor: 'crosshair',
                            point: {
                                events: {
                                    mouseOver: function () {
                                        this.series.chart.container.style.cursor = 'crosshair';
                                    },
                                    mouseOut: function () {
                                        this.series.chart.container.style.cursor = 'default';
                                    }
                                }
                            }
                        },

                    }}



                >


                    <Title align="left" margin={30} x={30} style={{
                        fontFamily: 'Roboto',
                        fontWeight: 600,
                        fontSize: 16
                    }}>
                        {dataset.name}
                    </Title>
                    {dataset.name === 'Wind Speed' ?
                        <XAxis
                            lineWidth={0} // Hide the axis line
                            labels={{ enabled: false }} // Hide the labels
                            title={{ text: null }} // Hide the title
                            tickWidth={0} // Hide the tick marks
                        >
                            {/* ... other components like <YAxis>, <Series>, etc. */}
                        </XAxis>
                        :
                        <XAxis

                            crosshair={{
                                width: 10,
                                color: '#2651d214',
                                dashStyle: 'solid',
                                zIndex: 0,
                            }}

                            type="datetime"

                            labels={{
                                formatter: function () {
                                    return `<div >
                            <b style="font-weight:800;color:black;font-size: 12px";  >${moment(this.value).format('YYYY-MM-DD')}</b>
                            <br/>${moment(this.value).format('h:mm a')}</div>`;

                                },
                                style: {
                                    fontFamily: 'Roboto'
                                }
                            }} />



                    }

                    <YAxis max={dataset.name === 'Precipitation' ? dataset.max : null}>
                        <Series
                            lineWidth={dataset.lineWidth}
                            columnWidth={dataset.columnWidth}
                            name={dataset.name}
                            type={dataset.type}
                            data={data}
                            color={colour}
                            tooltip={{ valueSuffix: ` ${dataset.unit}` }}
                        />

                    </YAxis>

                    <Tooltip
                        positioner={tooltipPositioner}
                        borderWidth={0}
                        backgroundColor="none"
                        shadow={false}
                        style={{ fontSize: "20px", fontFamily: 'Roboto', marginRight: 15 }}
                        formatter={function () {
                            // Format the date using moment
                            const dateStr = moment(this.x).format('MM/DD/YYYY h:mm a');
                            // Format the content of the tooltip
                            const pointContent = this.points.map(point => {

                                return (
                                    `<span style="color:${point.color}">\u25CF</span> ${point?.series?.name}: <b>${point?.y} ${point?.series?.userOptions?.tooltip?.valueSuffix}</b><br/>`
                                )
                            }

                            ).join('');
                            // Combine the date and point content for the tooltip
                            return `<div style="font-size: ${screenWidth === 'xs' ? '13px' : '16px'};"><b style="font-size: ${screenWidth === 'xs' ? '11px' : '16px'};">${dateStr}</b><br/>${pointContent}</div>`;

                        }}
                        shared={true} // This is important for multi-series charts
                    />
                </HighchartsChart>
                {dataset.name === 'Wind Speed' && (
                    <HighchartsChart style={{
                        fontFamily: 'Roboto',
                    }}
                        exporting={false}
                        chart={{
                            height: 100,
                            backgroundColor: 'transparent',


                        }}
                        plotOptions={{
                            series: {
                                cursor: 'crosshair',
                                point: {
                                    events: {
                                        mouseOver: function () {
                                            this.series.chart.container.style.cursor = 'crosshair';
                                        },
                                        mouseOut: function () {
                                            this.series.chart.container.style.cursor = 'default';
                                        }
                                    }
                                }
                            },

                        }}



                    >



                        <XAxis

                            crosshair={{
                                width: 10,
                                color: '#2651d214',
                                dashStyle: 'solid',
                                zIndex: 0,
                            }}

                            type="datetime"

                            labels={{
                                formatter: function () {
                                    return `<div >
                            <b style="font-weight:800;color:black;font-size: 12px";  >${moment(this.value).format('YYYY-MM-DD')}</b>
                            <br/>${moment(this.value).format('h:mm a')}</div>`;

                                },
                                style: {
                                    fontFamily: 'Roboto'
                                }
                            }} />
                        <YAxis max={dataset.name === 'Precipitation' ? dataset.max : null}>

                            <Series
                                //move down 30

                                lineWidth={3}
                                type={'windbarb'}
                                name={dataset.name}
                                vectorLength={30}

                                turboThreshold={2400}
                                data={dataset?.dataDirection}
                                color={'red'}
                                opacity={1}
                                zIndex={999999}
                                showInLegend={false}

                            />
                        </YAxis>


                    </HighchartsChart>
                )}
            </HighchartsProvider>
        );
    };


    const handleMouseMove = e => {
        let point = null;
        let event = null;

        e.persist();
        Highcharts.charts.forEach(chart => {
            if (!chart) return;
            event = chart.pointer?.normalize(e);
            point = chart.series[0]?.searchPoint(event, true);
            if (point) {
                point.highlight(e);
            }
        });
    };



    return (
        <div>
            <Modal
                open={previewChart !== false}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{

                }}

                slotProps={{
                    backdrop: {
                        style: {
                            backgroundColor: 'transparent'
                        }
                    }
                }}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: screenWidth === 'xs' ? '90%' : '60%',
                    height: screenWidth === 'xs' ? '70vh' : '80vh',
                    bgcolor: 'background.paper',
                    border: '1px solid rgba(0, 0, 0, .3)',
                    boxShadow: 24,
                    p: screenWidth === 'xs' ? 1 : 4,
                    borderRadius: '24px',
                    boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.75)',
                    overflowY: 'scroll'
                }}>
                    <Row>
                        <Col xs={24} sm={16}>
                            <Typography id="modal-modal-title" variant="h6" fontWeight={700} fontSize={screenWidth === 'xs' ? 20 : 28} component="h2">
                                Obscape Station data
                            </Typography>
                            <Typography variant="subtitle" fontWeight={400} fontSize={screenWidth === 'xs' ? 13 : 15} >
                                Date Range: {moment(startDate, 'YYYY-MM-DDTHH:mm:ss').format('MM/DD/YYYY')} - {moment(endDate, 'YYYY-MM-DDTHH:mm:ss').format('MM/DD/YYYY')}
                            </Typography>
                        </Col>
                        <Col xs={24} sm={8} style={{ display: ' inline', paddingTop: screenWidth === 'xs' ? 10 : 0 }}>
                            <Button
                                variant='outlined'

                                onClick={() => changeWeek('prev')} style={{ textTransform: 'none', color: 'black', border: '1px solid gray', marginRight: 10 }}>

                                Previous Week

                            </Button>
                            <Button
                                variant='outlined'
                                onClick={() => changeWeek('next')} style={{ textTransform: 'none', color: 'black', border: '1px solid gray', }}>

                                Next Week

                            </Button>




                        </Col>
                    </Row>

                    {loadingChart ?
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <Typography variant="caption" fontSize={13} sx={{ mt: 2 }}>
                                Loading Weather Station Data
                                <FontAwesomeIcon icon={faSpinner} size="xl" spin
                                    style={{
                                        color: '#3f51b5',
                                        marginLeft: '10px'
                                    }} />
                            </Typography>
                        </div>
                        :

                        <Box className="App" onMouseMove={handleMouseMove} onTouchMove={handleMouseMove}>
                            {chartData.datasets.map((dataset, index) => {
                                return (<div style={{ height: 200 }} key={index}>{renderChart(dataset, index)}</div>)
                            })
                            }
                        </Box>
                    }



                </Box>
            </Modal>
        </div >
    );
}

export default PreviewChartModalObscapeWeather;
