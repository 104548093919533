import { Checkbox, Divider, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import React from 'react';

import moment from 'moment';
import DatePicker from "react-datepicker";
import '../hoverStyle.css';
import { fullMobile } from './util';

export default class ControlsInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            type: this.props.type,
            item: this.props.item,
            loading: true,
            preview: this.props.preview
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
        if (this.props.preview !== prevProps.preview) {
            this.setState({ preview: this.props.preview })
        }


    }
    componentDidMount() {
        const { userInfo, item } = this.state;




    }






    updateItem = (boo, val) => {
        const { item } = this.state;
        item[boo] = val;

        this.setState({ item })
        this.props.updateEntireItem(item)
    }

    render() {

        const { item, screenWidth, preview } = this.state;




        return (
            <Row gutter={[24, 24]} style={{ paddingTop: 10 }} align="middle"  >

                <Col span={24}>
                    <Typography variant="h3" style={{ fontSize: 20, }}>Pre-Application</Typography>
                </Col>


                
                <Col {...fullMobile(8, 12)} style={{}}>
                    <Typography variant="body1" fontWeight={600}>Have Structures Been Inspected for Leaks?</Typography>
                    {preview ? <Typography variant="body1">{item.preControlsInspection === true ? "Yes" : "No"}</Typography> :
                        <Row align="middle">
                            <Col><Checkbox checked={item.preControlsInspection === true}
                                onChange={(e) => {
                                    this.updateItem('preControlsInspection', true)
                                }} /></Col>
                            <Col>
                                <Typography variant="body1">Yes</Typography>
                            </Col>
                            <Col><Checkbox checked={item.preControlsInspection === false}
                                onChange={(e) => {
                                    this.updateItem('preControlsInspection', false)
                                }} /></Col>
                            <Col>
                                <Typography variant="body1">No</Typography>
                            </Col>
                        </Row>}
                </Col>
                <Col {...fullMobile(8, 12)} style={{}}>
                    <Typography variant="body1" fontWeight={600}>If leaks were found were they sealed?</Typography>
                    {preview ? <Typography variant="body1">{item.preLeaksSealed === true ? "Yes" : "No"}</Typography> :
                        <Row align="middle">
                            <Col><Checkbox checked={item.preLeaksSealed === true}
                                onChange={(e) => {
                                    this.updateItem('preLeaksSealed', true)
                                }} /></Col>
                            <Col>
                                <Typography variant="body1">Yes</Typography>
                            </Col>
                            <Col><Checkbox checked={item.preLeaksSealed === false}
                                onChange={(e) => {
                                    this.updateItem('preLeaksSealed', false)
                                }} /></Col>
                            <Col>
                                <Typography variant="body1">No</Typography>
                            </Col>
                        </Row>}
                </Col>
                <Col {...fullMobile(8, 24)} style={{}}>
                    <Typography variant="body1" fontWeight={600}>Have Structures been closed or sealed?</Typography>
                    {preview ? <Typography variant="body1">{item.preClosure === true ? "Yes" : "No"}</Typography> :
                        <Row align="middle">
                            <Col><Checkbox checked={item.preClosure === true}
                                onChange={(e) => {
                                    this.updateItem('preClosure', true)
                                }} /></Col>
                            <Col>
                                <Typography variant="body1">Yes</Typography>
                            </Col>
                            <Col><Checkbox checked={item.preClosure === false}
                                onChange={(e) => {
                                    this.updateItem('preClosure', false)
                                }} /></Col>
                            <Col>
                                <Typography variant="body1">No</Typography>
                            </Col>
                        </Row>}
                </Col>
               
               
                
                <Col span={24}>
                    <Divider />
                </Col>
                <Col span={24}>
                    <Typography variant="h3" style={{ fontSize: 20, }}>Gates</Typography>
                </Col>
                <Col {...fullMobile(12, 24)} style={{}}>
                    <Typography variant="body1" fontWeight={600}>Date and Time Gates closed?</Typography>
                    {preview ? <Typography variant="body1">{moment(item.preCloseTime, 'x').format('MM/DD/YYYY h:mm a')}</Typography> :
                        <DatePicker portalId="root-portal" size="large" showTimeSelect timeFormat="h:mm a" timeIntervals={15} timeCaption="time" dateFormat="MM/dd/yyyy h:mm a"
                            selected={[undefined, '', "Invalid date"].includes(item.preCloseTime) ? new Date() : new Date(moment(item.preCloseTime, 'x'))}
                            onChange={(date) => {
                                if (date === null) { this.updateItem('preCloseTime', moment().format('x')) }
                                else { const unix = moment(date).format('x'); this.updateItem('preCloseTime', unix) }
                            }} />}

                </Col>
                <Col {...fullMobile(12, 24)} style={{}}>
                    <Typography variant="body1" fontWeight={600}>Date and Time Gates opened?</Typography>
                    {preview ? <Typography variant="body1">{moment(item.postOpenTime, 'x').format('MM/DD/YYYY h:mm a')}</Typography> :
                        <DatePicker portalId="root-portal" size="large" showTimeSelect timeFormat="h:mm a" timeIntervals={15} timeCaption="time" dateFormat="MM/dd/yyyy h:mm a"
                            selected={[undefined, '', "Invalid date"].includes(item.postOpenTime) ? new Date() : new Date(moment(item.postOpenTime, 'x'))}
                            onChange={(date) => {
                                if (date === null) { this.updateItem('postOpenTime', moment().format('x')) }
                                else { const unix = moment(date).format('x'); this.updateItem('postOpenTime', unix) }
                            }} />}

                </Col>
























            </Row >
        );
    }
} 