
import { Box, Card, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import moment from 'moment-timezone';




const LogNumber = ({
    log,
    setLog,
    company,
    account,
    screenWidth
}) => {
    function getFirstLetters(str) {
        const firstLetters = str?.split(' ')
            .map(word => word[0])
            .join('');

        return firstLetters;
    }



    const LogID = getFirstLetters(company?.companyName) + "-" + getFirstLetters(account?.accountName) + '-' + (log?.startDate ? `${moment(log?.startDate, 'x').format('MM-DD-YYYY')}-` : "") + "LOG";




    return (


        <Box sx={{ flexGrow: 1, p: 2 }} >
            <Typography variant="body1" color="black" fontSize={screenWidth === 'xs' ? 24 : 40} fontWeight={500}>Field Log</Typography>
            <Typography variant="body1" color="black" fontSize={screenWidth === 'xs' ? 13 : 16} fontWeight={400}>
                Log #: <span style={{ fontWeight: 700 }}>{log?.logNumber}</span>
            </Typography>
            <Typography variant="body1" color="black" fontSize={screenWidth === 'xs' ? 13 : 16} fontWeight={400}>
                Log ID: <span style={{ fontWeight: 700 }}>{LogID}</span>
            </Typography>
        </Box>



    )
}

export default LogNumber;