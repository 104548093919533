
import { Typography } from '@mui/material';
import { Col, Row } from 'antd';
import moment from 'moment';
import React from 'react';
import "weather-icons/css/weather-icons.css";
import { fullMobile } from './util';
import axios, * as others from 'axios';
var convert = require('convert-units')



const apiKey = 'c66109d21021ed0af40cf459d9fe5d37';


export default class CheckTimeWeather extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            date: this.props.date,
            time: this.props.time,
            weather: this.props.weather,
            icon: ''
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
        if (this.props.date !== prevProps.date) {
            this.getWeather(this.props.date, this.props.time)
            this.setState({ date: this.props.date })
        }
        if (this.props.time !== prevProps.time) {
            this.getWeather(this.props.date, this.props.time)
            this.setState({ time: this.props.time })
        }

    }
    componentDidMount() {
        window.scrollTo(0, 0);
        const { date, time, weather } = this.state;

        console.log("CHECK WEATHER")
        console.log(date, time)
        if (Object.values(weather).length === 0) {
            this.getWeather(date, time)
        }
        else {
            this.getWeather(date, time)
        }







    }


    getWeather = (date, time) => {



        const { account } = this.state;

        var dateFormat = moment(date, 'x').format('MM/DD/YYYY');
        var timeFormat = moment(time, 'x').format('h:mm a');

        var dateTime = moment(dateFormat + ' ' + timeFormat, 'MM/DD/YYYY h:mm a').format('X');



        const proxyUrl = "https://mycorslake.herokuapp.com/";
        axios.get(proxyUrl + 'https://api.openweathermap.org/data/3.0/onecall/timemachine?', {
            params: {
                lat: account.lat,
                lon: account.lng,
                appid: 'e60406d8d1152d5b1e8413bb058aa602',
                dt: Number(dateTime),
            }
        })
            .then((response) => {



                const weather = { ...response.data.current, ...response.data.current.weather[0], };

                var prefix = 'wi wi-';
                var code = response.data.current.weather[0].id;
                var icon = response.data.current.weather[0].icon;



                // Finally tack on the prefix.
                this.props.updateItem('weather', weather)
                this.setState({ weather, icon })



            })
            .catch(function (error) {
                console.log(error);
            })
            .then(function () {
                // always executed
            });

    }

    render() {

        const { account, weather, icon, screenWidth } = this.state;

        var dict = {
            '01d': 'wi-day-sunny',
            '02d': 'wi-day-cloudy',
            '03d': 'wi-cloud',
            '04d': 'wi-cloudy',
            '09d': 'wi-showers',
            '10d': 'wi-day-rain-mix',
            '11d': 'wi-thunderstorm',
            '13d': 'wi-snow',
            '50d': 'wi-fog',
            '01n': 'wi-night-clear',
            '02n': 'wi-night-alt-cloudy',
            '03n': 'wi-night-alt-cloudy-high',
            '04n': 'wi-cloudy',
            '09n': 'wi-night-alt-sprinkle',
            '10n': 'wi-night-alt-showers',
            '11n': 'wi-night-alt-thunderstorm',
            '13n': 'wi-night-alt-snow',
            '50n': 'wi-night-fog'
        };



        return (
            <Row align="middle" gutter={[8, 8]} style={{ paddingTop: 10 }} >
                <Col {...fullMobile(6, 12)}>
                    <Typography variant='h4'>{convert(weather.temp).from('K').to('F').toFixed(0)} F</Typography>
                    <Typography variant='h6' style={{ textTransform: 'capitalize' }}>{weather.description}</Typography>
                    <Typography variant='body1' style={{}}>Wind: {convert(weather.wind_speed).from('m/s').to('m/h').toFixed(0)} mph</Typography>
                    <Typography variant='body1' style={{ textTransform: 'capitalize' }}>{weather.humidity}% Humidity</Typography>
                </Col>
                <Col sm={12} style={{ textAlign: 'center', justifyContent: 'center', alignContent: 'center', fontSize: 48 }} >
                    <i style={{ textAlign: 'center', paddingLeft: 25, justifyContent: 'center', alignContent: 'center', }} id='my-node' class={`wi ${dict[icon]}`}></i>
                </Col>

            </Row>

        );
    }
} 
