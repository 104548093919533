import { faBoreHole, faFlask, faFolder, faFolderOpen, faMicrochip, faVial, faWrench } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { faCircle as faSolidCircle } from "@fortawesome/free-solid-svg-icons";
import { faCircle as faRegularCircle, faBell,  } from "@fortawesome/free-regular-svg-icons";
import { getAlerts } from "./firestore";


const LeafIcon = ({ company, node }) => {
    const [alerts, setAlerts] = React.useState([]);
    const type = Array.isArray(node?.data?.type) ? node?.data?.type : [node?.data?.type]
    const activelyReporting = node?.data?.activelyReporting !== false;
   
    useEffect(() => {
        checkAlerts()


    }, [])

    async function checkAlerts() {
        const alerts = await getAlerts(company, node?.data?.id)
        setAlerts(alerts)
       
    }



    return (
        <>
            {alerts.length > 0 && (
                <FontAwesomeIcon icon={faBell} color={'#404050'} style={{ marginRight: '5px' }} size="xs" />
            )}
            {(type.includes('sensor') || type.includes('ostSensor')) && (
                activelyReporting ? (
                    <FontAwesomeIcon icon={faSolidCircle} color={'#3bec18'} style={{ marginRight: '5px' }} size="xs" />
                ) : (
                    <FontAwesomeIcon icon={faRegularCircle} color={'red'} style={{ marginRight: '5px' }} size="xs" />
                )
            )}
            {type.includes('maintenance') && (
                <FontAwesomeIcon icon={faWrench} color={'#404050'} style={{ marginRight: '5px' }} />
            )}
            {type.includes('sensor') && (
                <FontAwesomeIcon icon={faBoreHole} color={'#404050'} style={{ marginRight: '5px' }} />
            )}
            {type.includes('grab') && (
                <FontAwesomeIcon icon={faVial} color={'#404050'} style={{ marginRight: '5px' }} />
            )}
            {type.includes('application') && (
                <FontAwesomeIcon icon={faFlask} color={'#404050'} style={{ marginRight: '5px' }} />
            )}
            {type.includes('ostSensor') && (
                <FontAwesomeIcon icon={faMicrochip} color={'#404050'} style={{ marginRight: '5px' }} />
            )}

        </>
    );
}

const FolderIcon = ({ node }) => {
    const icon = node.isOpen ? faFolderOpen : faFolder;

    return (
        <FontAwesomeIcon
            icon={icon} color={'#404050'}
        />
    );
};

export { LeafIcon, FolderIcon };






