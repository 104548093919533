import { Button, OutlinedInput, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import uuid from 'react-uuid';
import axios, * as others from 'axios';
import queryString from 'query-string';

export default function TestProbe() {
    const [count, setCount] = useState(0);
    const [probeId, setProbeId] = useState('');

    useEffect(() => {
        document.title = `You clicked ${count} times`;
    });

    async function getData(id) {
        console.log("GET DATA")
        const data = {
            grant_type: 'client_credentials',
            client_id: 'testNew',
            client_secret: '11d28cb08c2444a2966931244ee43e08',
        };

        const hydrovuTokenURL = 'https://www.hydrovu.com/public-api/oauth/token';
        const proxyUrl = "https://mycorslake.herokuapp.com/";

        const response = await axios.post(proxyUrl + hydrovuTokenURL, queryString.stringify(data), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }, 
        });
        const token = response.data.access_token;
        console.log(token);

        const hydrovuLocationURL = `https://www.hydrovu.com/public-api/v1/locations/${id}/data`;
        const response2 = await axios.get(proxyUrl + hydrovuLocationURL, {
            headers: { Authorization: `Bearer ${token}` },
            params: {
                startTime: moment().subtract(12, 'hours').format('X')
            },
        }).catch((error) => {
            console.log(error);
        });
        console.log(id)
        console.log(response2.data);

    }


    async function getOldData(id) {
        console.log("GET OLD DATA")
        const creds = {
            grant_type: 'client_credentials',
            client_id: 'testNew',
            client_secret: '11d28cb08c2444a2966931244ee43e08',
        };

        const hydrovuTokenURL = 'https://www.hydrovu.com/public-api/oauth/token';
        const proxyUrl = "https://mycorslake.herokuapp.com/";

        const response = await axios.post(proxyUrl + hydrovuTokenURL, queryString.stringify(creds), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        const token = response.data.access_token;
        console.log(token);

        const hydrovuLocationURL = `https://www.hydrovu.com/public-api/v1/locations/${id}/data`;

        //array 48-150
        const keys = [8,16,24,32,40,48,56,64,72,80,88,96,104,112,120,128,136,144,152,160];

        keys.forEach(async (i) => {

            const response2 = await axios.get(proxyUrl + hydrovuLocationURL, {
                headers: { Authorization: `Bearer ${token}` },
                params: {
                    startTime: moment().subtract(i, 'hours').format('X')
                },
            }).catch((error) => {
                console.log(error);
            });

            try {
                const parameterMap = {}
                const isNumeric = (num) => {
                    return !isNaN(num)
                }
                const data = [];
                response2.data.parameters.forEach(parameter => {
                    parameter.readings.forEach(reading => {
                        var time = moment(reading.timestamp, 'X').tz("America/Los_Angeles").format("YYYY-MM-DD HH:mm");
                        data.push({
                            time: `'${time}'`,
                            timestamp: reading.timestamp,
                            locationid: `'${response2.data.locationId}'`,
                            unitid: `'${parameter.unitId}'`,
                            parameterid: `'${parameter.parameterId}'`,
                            value: reading.value,
                            key: `'${uuid()}'`
                        })
                        if (isNumeric(reading.value)) {
                            const values = parameterMap[parameter.parameterId] || {}
                            parameterMap[parameter.parameterId] = {
                                min: Math.min(values.min || reading.value, reading.value),
                                max: Math.max(values.max || reading.value, reading.value)
                            }
                        }
                    })
                });
                const hourlyVals = data.map((h) => `(${Object.values(h)})`);
                const hourlyString = hourlyVals.join();
                console.log(response2.data);

                axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                    raw: `INSERT INTO probe_data VALUES ${hourlyString} ON CONFLICT DO NOTHING; `
                })
                    .then(async (response) => {
                        // handle success
                        console.log(response)

                        console.log(i)


                    });
            } catch (error) {
                console.log(error);
            }

        })
    }

















    return (
        <Row>
            <Col span={24}>
                <Typography variant="h4" component="h4">Get Data</Typography>
            </Col>

            <Col span={24}>
                <Typography variant="h4" component="h4">Test Probe</Typography>
            </Col>
            <Col span={24}>
                <OutlinedInput fullWidth value={probeId} onChange={(e) => setProbeId(e.target.value)} />
            </Col>
            <Col span={24}>
                <Button variant="contained" onClick={() => {
                    getData(probeId);
                }}>Get Data</Button>
            </Col>
            <Col span={24}>
                <Button variant="contained" onClick={() => {
                    getOldData(probeId);
                }}>Get Data</Button>
            </Col>

        </Row>
    );
}