import React from "react";
import { Button, ButtonGroup } from '@mui/material';
import { Col, Row } from 'antd';
import './dataTable.css';

const ParametersHeader = ({
    activeTab,
    setActiveTab,
    screenWidth
}) => {

    // Define the array of button labels
    const buttonLabels = ['Lab Parameters', 'Probe Parameters', 'Calculated Parameters'];

    return (
        <Row>
            <Col span={24}>
                <Row>
                    <Col xs={24} sm={18} style={{
                        height: '70px',
                        borderBottom: '1px solid rgba(0,0,0,0.2)',
                        display: 'flex',
                        alignItems: 'flex-end',
                        justifyContent: 'flex-start',
                    }}>
                        <ButtonGroup size="small" aria-label="small button group" style={{ marginLeft: 10 }}>
                            {buttonLabels.map((label, index) => (
                                <Button
                                    key={index}
                                    onClick={() => setActiveTab(label.toLowerCase().split(" ").join(""))}
                                    color='primary'
                                    variant={null}
                                    style={{
                                        textTransform: 'none',
                                        borderRadius: 0,
                                        height: '50px',
                                        fontWeight: 400,
                                        fontSize: 18,
                                        color: activeTab.toLowerCase().split(" ").join("") === label.toLowerCase().split(" ").join("") ? '#6fa2f1' : null,
                                        borderBottom: activeTab.toLowerCase().split(" ").join("") === label.toLowerCase().split(" ").join("") ? '3px solid #3f51b5' : null
                                    }}
                                >
                                    {label}
                                </Button>
                            ))}
                        </ButtonGroup>
                    </Col>
                    <Col xs={24} sm={6} style={{
                        height: '70px', display: 'flex', alignItems: 'center', justifyContent: 'right',
                        borderBottom: screenWidth === 'xs' ? 'none' :
                            '1px solid rgba(0,0,0,0.2)', paddingLeft: screenWidth === 'xs' ? 10 : 0
                    }}>
                        {/* Other elements */}
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default ParametersHeader;
