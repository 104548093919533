

import {
    Autocomplete,
    Badge,
    Box,
    Button,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    OutlinedInput,
    Popover,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import React from 'react';
import { faCheckCircle, faCircle, faCopy, faEdit, faEye, faEyeSlash, faSquare, faSquareCheck, faGear } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faDownload, faEllipsisH, faEllipsisV, faList, faPlus, faTags, faTrash } from '@fortawesome/free-solid-svg-icons';
import * as Papa from 'papaparse';
import moment from 'moment-timezone';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';

import SearchIcon from '@mui/icons-material/Search';
import uuid from 'react-uuid';
import { doc, getFirestore, setDoc } from 'firebase/firestore';
import ProbeParameters from '../../../extra/probeParams';
const HeaderUnitsChange = ({ unitsArray, header, sortedData, visibleHeaders, deleteSelectedData, selectedData, setBulkEditor, getNestedProperty, setCurrentCell, setHeaders, headers, data, setData, openSnack,
    setParametersModal, setCodesModal, deleteHeader, parameters, setParameters, userInfo,
    type
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [searchQuery, setSearchQuery] = React.useState('');
    const [filteredUnits, setFilteredUnits] = React.useState(unitsArray);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setCurrentCell(null);
    };



    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;




    return (
        <div style={{
            display: 'inline',

        }}>
            <IconButton onClick={handleClick}

            >
                <Tooltip title="More Options" placement="bottom">
                    <FontAwesomeIcon icon={faEllipsisV}
                        size="sm" style={{ color: 'gray', cursor: 'pointer' }} />
                </Tooltip>



            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}

                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <List sx={{
                    width: 300,
                    padding: 0,

                }}>
                    <Box sx={{ px: 2, mt: 2 }}>
                        <Typography variant="body1" color="black" fontSize={16} fontWeight={600}>Change Parameter</Typography>
                        <Autocomplete
                            size="small"
                            margin="dense"
                            options={[...parameters, ...ProbeParameters].sort((a, b) => a.label.localeCompare(b.label))}
                            sx={{
                                ".inputRoot": {
                                    fontSize: 13,
                                    fontWeight: 500,
                                },
                                input: {
                                    padding: 0,
                                    height: 30,
                                },
                            }}
                            InputPro
                            renderInput={(params) => <TextField {...params}
                                placeholder={[...parameters, ...ProbeParameters].find((u) => u.label === header?.name) ? "Parameter" : "Parameter not in list"}
                                margin="dense"
                                sx={{
                                    ".MuiInputBase-root": {
                                        height: 50,
                                        fontSize: 14,
                                        fontWeight: 600,

                                    },
                                    //placeholder styl
                                    ".MuiInputBase-input::placeholder": {
                                        color: [...parameters, ...ProbeParameters].find((u) => u.label === header?.name) ? "black" : "red",
                                        fontWeight: 600,
                                    },
                                }}

                            />}
                            disableClearable={true}
                            freeSolo={true}
                            value={[...parameters, ...ProbeParameters].find((u) => u.label === header.name)}
                            onChange={(event, newValue) => {
                                console.log(newValue)
                                if (newValue === null) {
                                    const newHeaders = [...headers];
                                    const i = newHeaders.findIndex((h) => h.name === header.name);
                                    console.log(i)
                                    newHeaders[i].name = null;
                                    setHeaders(newHeaders);
                                    return;
                                }
                                const newData = [...data];
                                const oldLabel = header.name;
                                const newLabel = newValue.label;
                                newData.forEach((d) => {
                                    d[newLabel] = d[oldLabel];
                                    delete d[oldLabel];
                                })
                                console.log(newData)
                                setData(newData);





                                const newHeaders = [...headers];
                                const i = newHeaders.findIndex((h) => h.name === header.name);
                                const originalHeader = newHeaders[i];
                                console.log(originalHeader)

                                newHeaders[i].originalName || (newHeaders[i].originalName = header.name);
                                newHeaders[i].name = newValue.label;
                                newHeaders[i].mappedField = 'value';
                                console.log(newHeaders)
                                setHeaders(newHeaders);




                            }}

                        />
                        {![...parameters, ...ProbeParameters].find((u) => u.label === header?.name) && (
                            <>
                                <Typography color={'black'} sx={{ display: 'block' }} variant="caption" fontWeight={600} fontSize={13} component="span"
                                >
                                    Suggestion:
                                </Typography>
                                <Typography color={'black'} sx={{ display: 'block' }} variant="caption" fontWeight={400} fontSize={13} component="span"
                                >
                                    {header.name} is not in the parameters list. Double check the list to see if you misspelled it. Or click the button below to add it to the list.
                                    <Button
                                        variant='link'
                                        style={{
                                            padding: 0,
                                            fontSize: 13,
                                            fontWeight: 600,
                                            marginLeft: 5,
                                            textTransform: 'none',
                                            color: 'blue',
                                        }}
                                        onClick={async () => {
                                            const newParameterItem = {
                                                company: userInfo.currentCompany,
                                                account: userInfo.currentAccount,
                                                key: uuid(),
                                                archived: false,
                                                name: header.name,
                                                label: header.name,
                                            }
                                            const updatedParameters = [newParameterItem, ...parameters];
                                            //check if parameter already exists
                                            const exists = parameters.find((param) => param.label.toLowerCase() === header.name.toLowerCase());
                                            if (exists) {
                                                openSnack('error', 'Parameter already exists');
                                                return;
                                            }
                                            const db = getFirestore();
                                            const docRef = doc(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'parameters', newParameterItem.key);
                                            await setDoc(docRef, newParameterItem);
                                            openSnack('success', 'Parameter added successfully');
                                            setParameters(updatedParameters);
                                        }}
                                    >
                                        Click to add missing parameter
                                    </Button>
                                </Typography>
                            </>
                        )}
                    </Box>


                    <Box sx={{ px: 2, mt: 2 }}>
                        <Typography variant="body1" color="black" fontSize={16} fontWeight={600}>Change Units</Typography>
                        <Autocomplete
                            size="small"
                            margin="dense"
                            options={unitsArray.sort((a, b) => a.label.localeCompare(b.label))}
                            sx={{
                                ".inputRoot": {
                                    fontSize: 13,
                                    fontWeight: 500,
                                },
                                input: {
                                    padding: 0,
                                    height: 30,
                                },
                            }}
                            InputPro
                            renderInput={(params) => <TextField {...params} placeholder="Units"
                                margin="dense"
                                sx={{
                                    ".MuiInputBase-root": {
                                        height: 50,
                                        fontSize: 14,
                                        fontWeight: 600,
                                    },
                                }}

                            />}
                            value={unitsArray.find((u) => u.label === header.units)}
                            onChange={(event, newValue) => {
                                console.log(newValue)
                                if (newValue === null) {
                                    const newHeaders = [...headers];
                                    const i = newHeaders.findIndex((h) => h.name === header.name);
                                    console.log(i)
                                    newHeaders[i].units = null;
                                    setHeaders(newHeaders);
                                    return;
                                }
                                const newHeaders = [...headers];
                                const i = newHeaders.findIndex((h) => h.name === header.name);
                                console.log(i)
                                newHeaders[i].units = newValue.label;

                                setHeaders(newHeaders);


                            }}

                        />
                    </Box>
                    <ListItem

                        key={'deleteHeader'}
                        dense
                        button

                        onClick={() => {
                            deleteHeader(header)
                            handleClose();
                        }}
                        sx={{
                            mt: 3,
                            borderBottom: '1px solid #e0e0e0',
                            paddingTop: 1,
                            paddingBottom: 1,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrash} style={{ color: '#3f51b5', marginRight: 10 }} />
                        <Typography variant="body1" color="black" fontSize={16} fontWeight={500}>Delete Header</Typography>
                    </ListItem>











                </List>




            </Popover>
        </div>
    );
};

export default HeaderUnitsChange;
