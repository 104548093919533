import { Divider, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import { collection, getDocs, getFirestore, limit, orderBy, query, startAt } from 'firebase/firestore';
import React from 'react';

import axios, * as others from 'axios';
var moment = require('moment-timezone');



export default class QueryOldProbeData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }

    }
    componentDidMount() {
        window.scrollTo(0, 0)

        this.queryData(0);





    }

    queryData = async (sa) => {
        const { userInfo } = this.state;
        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'probeDataNew'),
            orderBy("timestamp"), startAt(sa), limit(500));
        const data = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {


            var time = moment(doc.data().timestamp, 'X').tz("America/Los_Angeles").format("YYYY-MM-DD HH:mm");

            data.push({
                time: `'${time}'`,
                timestamp: doc.data().timestamp,
                locationid: `'${doc.data().probe}'`,
                unitid: `'${doc.data().unitId}'`,
                parameterid: `'${doc.data().parameterId}'`,
                value: doc.data().value
            })
        });



 /*        const hourlyVals = data.map((h) => `(${Object.values(h)})`);
        const hourlyString = hourlyVals.join();

        const proxyUrl = "https://mycorslake.herokuapp.com/";
        axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
            raw: `INSERT INTO probe_data VALUES ${hourlyString} ON CONFLICT DO NOTHING; `
        })
            .then(async (response) => {
                // handle success
                console.log(response)
                const lastVisible = snap.docs[snap.docs.length - 1];
                console.log("last", lastVisible);

                if (data.length === 0) {
                    console.log("ALL DATA")
                } else {
                    this.queryData(lastVisible)
                }



            });


 */





        console.log(data)
    }

    render() {

        const { account } = this.state;

        return (
            <Row style={{}} >
                <Col span={23}>
                    <Row align="middle">
                        <Col xs={16} sm={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>
                            <Typography variant="h4">Query Old</Typography>
                        </Col>
                        <Col xs={6} sm={4} style={{ paddingTop: 30, paddingBottom: 15, textAlign: 'right' }}>

                        </Col>
                        <Col span={24}>
                            <Divider />
                        </Col>
                    </Row>
                </Col>

            </Row >
        );
    }
} 