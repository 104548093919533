import React, { useEffect, useRef, useState } from "react";


import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { faSquare, faSquareCheck } from '@fortawesome/free-regular-svg-icons';
import { faEllipsisV, faExclamation, faFilter, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, CircularProgress, Divider, IconButton, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Resizable } from 'react-resizable';
import './taskList.css';
import moment from 'moment-timezone';

import Slider, { SliderThumb } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import { makeStyles } from "@mui/styles";
import DataTableRows from "./dataTableRows";
import MoreTableOptions from "./moreTableOptionsTool";
import BulkEditData from "./bulkEditData";

import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import ErrorModal from "./errorModal";
import ParametersModal from "./parametersModal";
import UploadDataButton from "./uploadDataButton";
import TemplateMaker from "./templateMaker";
import BulkDelete from "./bulkDelete";
import CodesModal from "./codesModal";
import DataTableMultiMulti from "./dataTableRows";
import HeaderUnitsChange from "./headerUnitsChange";
import TablePaginationSection from "../uploadCSV/tablePaginationSection";
import ProbeParameters from "../../../extra/probeParams";


const useStyles = makeStyles({
    thumb: {
        width: 24,
        height: 24,
        border: 'none',
        color: '#000', // Adjust color if needed
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
        '& .thumbIcon': {
            fontSize: '1rem',
        }
    }
});

const AirbnbSlider = styled(Slider)(({ theme }) => ({
    color: '#3f51b5',
    height: 3,
    padding: '13px 0',
    '& .MuiSlider-thumb': {
        height: 27,
        width: 27,
        backgroundColor: '#fff',
        border: '1px solid currentColor',
        '&:hover': {
            boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
        },
        '& .airbnb-bar': {
            height: 9,
            width: 1,
            backgroundColor: 'currentColor',
            marginLeft: 1,
            marginRight: 1,
        },
    },
    '& .MuiSlider-track': {
        height: 3,
    },
    '& .MuiSlider-rail': {
        color: theme.palette.mode === 'dark' ? '#bfbfbf' : '#d8d8d8',
        opacity: theme.palette.mode === 'dark' ? undefined : 1,
        height: 3,
    },
}));

function AirbnbThumbComponent(props) {
    const { children, ...other } = props;
    return (
        <SliderThumb {...other}>
            {children}
            <span className="airbnb-bar" />
            <span className="airbnb-bar" />
            <span className="airbnb-bar" />
        </SliderThumb>
    );
}




const DataTableHeader = (props) => {
    const {
        templates,
        setTemplates,
        template,
        setTemplate,
        steps,
        setSteps,
        currentStep,
        item,
        setItem,
        nextStep,
        backStep,
        screenWidth,
        openSnack,
        userInfo,
        uploading,
        setUploading,
        codes,
        setCodes,
        parameters,
        setParameters,
        stations,
        setStations,
        unitsArray,
        setData,
        data,
        meta,
        setMeta,
        setHeaders,
        headers,
        deleteHeader,
        headerErrors,
        setHeaderErrors,
        checkErrors
    } = props;



    const [initialWidth, setInitialWidth] = useState(null);
    const [sliderValue, setSliderValue] = useState(0);
    const [maxScroll, setMaxScroll] = useState(0);

    const [allSelected, setAllSelected] = useState(false);
    const [loading, setLoading] = useState(false);

    const [bulkEditor, setBulkEditor] = useState(false);
    const [errorModal, setErrorModal] = useState(false);


    const [codesModal, setCodesModal] = useState(false);
    const [currentCell, setCurrentCell] = useState(null);
    const [currentRow, setCurrentRow] = useState(null);
    const [sortedData, setSortedData] = useState([]);
    const [sortItem, setSortItem] = useState(null);
    const textRef = useRef(null);
    const [parametersModal, setParametersModal] = useState(false);
    const [templateMaker, setTemplateMaker] = useState(false);


    const tableContainerRef = useRef(null);
    const classes = useStyles();

    useEffect(() => {
        window.scrollTo(0, 0)

    }, []);





    useEffect(() => {

        const headerErrors = headers.filter((header) => {
            return header?.mappedField === null && !parameters.some((param) => param.label === header?.name);
        });

        const countOfHeaderErrors = headerErrors.length;

        setHeaderErrors(countOfHeaderErrors);

        if (tableContainerRef.current) {
            const maxScrollValue = tableContainerRef?.current?.scrollWidth - tableContainerRef?.current?.clientWidth;
            setMaxScroll(maxScrollValue);

            // Add an event listener to the Col to detect when it is scrolled
            const handleScroll = () => {
                setSliderValue(tableContainerRef?.current?.scrollLeft);
            };

            tableContainerRef?.current?.addEventListener("scroll", handleScroll);

            // Cleanup the listener on component unmount
            return () => {
                tableContainerRef?.current?.removeEventListener("scroll", handleScroll);
            };
        }


    }, [headers]);


    function onResize(index, event, { node, size, handle }) {
        const newHeaders = [...headers];
        newHeaders[index].width = size.width;
        setHeaders(newHeaders);

    }

    const onResizeStop = (index, event, { node, size, handle }) => {
        const newHeaders = [...headers];

        newHeaders[index].width = size.width;
        setHeaders(newHeaders);


    };

    const onResizeStart = (index, event, { node, size, handle }) => {
        const newHeaders = [...headers];
        setInitialWidth(newHeaders[index].width);
    };




    const doubleClickResize = (index) => {
        const newHeaders = [...headers];

        const strings = data.map((task) => {
            const value = task;
            return value !== undefined ? value.toString() : '';
        }
        )
        const longestString = strings?.reduce((a, b) => a.length > b.length ? a : b, '');
        const width = longestString.length * 10;
        newHeaders[index].width = width < newHeaders[index].minWidth ? newHeaders[index].minWidth : width;
        setHeaders(newHeaders);


    };






    const onDragEnd = (result) => {

        const { source, destination } = result;
        if (!destination) {
            return;
        }



        const reorderedHeaders = Array.from(headers);
        const [removed] = reorderedHeaders.splice(source.index, 1);
        reorderedHeaders.splice(destination.index, 0, removed);

        reorderedHeaders.forEach((header, i) => {
            header.position = i;
        });
        setHeaders(reorderedHeaders);




    };



    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer

        color: isDragging ? '#404050' : 'black',
        border: isDragging ? '1px solid #404050' : null,
        backgroundColor: isDragging ? 'white' : '#f7f7f7',
        //slightly rotate on dragging
        transform: isDragging ? 'rotate(5deg)' : 'none',
        borderBottom: isDragging ? '1px solid #404050' : '1px solid rgba(0,0,0,0.2)',


    });

    const getListStyle = isDraggingOver => ({
        display: 'inline-flex',
        height: '40px',
        backgroundColor: '#f7f7f7',

    });

    const getNestedProperty = (obj, path, header, type) => {

        const firstValue = path?.split(',').reduce((acc, part) => acc && acc[part], obj);
        const value = firstValue;



        if (header?.mappedField === 'value') {
            return Number(value);
        }
        if (type === 'other') {
            return Number(value);
        }
        if (header?.mappedField === 'date') {
            return Number(moment(value, header?.dateFormat || "MM/DD/YYYY").format('x'));
        }


        return value;
    };


    useEffect(() => {
        let sortedData = [...data]; // make a copy of data array

        // Extract the sorting key and direction from the sortItem string
        if (sortItem) {
            const sortKey = sortItem.split(',')[0].split(': ')[1];
            const sortDirection = sortItem.split(',')[1].split(': ')[1];

            const sortedHeader = [...headers, { name: 'issues' }].find(header => header.name === sortKey);


            //if undefined, setSortItem to null
            if (sortedHeader === undefined) {
                setSortItem(null);
                return;
            }
            //filter out any undefined data

            const type = ['node', 'date'].includes(sortedHeader?.mappedField) ? 'node-date' : 'other';

            sortedData = sortedData.sort((a, b) => {
                let valueA = getNestedProperty(a, sortKey, sortedHeader, type);
                let valueB = getNestedProperty(b, sortKey, sortedHeader, type);


                // Handle cases where one or both values are undefined
                if (valueA === undefined && valueB !== undefined) {
                    return 1;
                }
                if (valueB === undefined && valueA !== undefined) {
                    return -1;
                }
                if (valueA === undefined && valueB === undefined) {
                    return 0;
                }

                // Continue with your normal comparison for non-undefined values
                if (valueA < valueB) {
                    return sortDirection === 'asc' ? -1 : 1;
                }
                if (valueA > valueB) {
                    return sortDirection === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }
        setData(sortedData);
    }, [sortItem]);











    // Code to update all data to selected/unselected based on allSelected state
    const toggleSelectAll = () => {
        const newData = data.map(task => {
            const { errorsCount, warningsCount } = checkErrors(task);
            return ({ ...task, selected: !allSelected, errorsCount, warningsCount, issues: errorsCount + warningsCount });
        });

        setData(newData); // Assume updateData is your function to update data
        setAllSelected(!allSelected);


    };





    const handleSortChange = (keyToSort) => {
        const currentKey = sortItem?.split(',')[0]?.split(': ')[1];
        const currentDirection = sortItem?.split(',')[1]?.split(': ')[1];

        if (currentKey !== keyToSort) {
            setSortItem(`key: ${keyToSort}, direction: asc`);
        } else if (currentDirection === 'asc') {
            setSortItem(`key: ${keyToSort}, direction: desc`);
        } else if (currentDirection === 'desc') {
            setSortItem(null);
        }
    }


    async function updateDataPosition(data) {
        setData(data)


    }

    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    const pageFunctions = {
        handleNextPage: () => {
            setCurrentPage((prevPage) => Math.min(prevPage + 1, Math.ceil(data.length / rowsPerPage)));
        },
        handlePrevPage: () => {
            setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
        },
        handleLastPage: () => {
            setCurrentPage(Math.ceil(data.length / rowsPerPage));
        },
        handleFirstPage: () => {
            setCurrentPage(1);
        }
    };


    const propList = {
        screenWidth,
        headers,
        setHeaders,

        sortedData,
        setSortItem,
        sortItem,
        allSelected,
        setAllSelected,
        loading,
        setLoading,
        tableContainerRef,
        sliderValue,
        setSliderValue,
        maxScroll,
        setMaxScroll,
        onResize,
        onResizeStart,
        onResizeStop,
        doubleClickResize,
        getItemStyle,
        getListStyle,
        onDragEnd,
        handleSortChange,
        toggleSelectAll,
        updateDataPosition,
        userInfo,
        stations,
        setParameters,
        parameters,
        codes,
        unitsArray,
        currentCell,
        setCurrentCell,
        setErrorModal,
        currentRow,
        setCurrentRow,
        uploading,
        setUploading,
        templateMaker,
        setTemplateMaker,
        templates,
        setTemplates,
        template,
        setTemplate,
        steps,
        setSteps,
        currentStep,
        nextStep,
        backStep,
        openSnack,
        userInfo,
        setData,
        data,
        meta,
        setMeta,
        deleteHeader,
        headerErrors,
        checkErrors,
        startIndex,
        endIndex,
        currentPage,
        rowsPerPage,
        pageFunctions,
        setRowsPerPage

    }

    async function deleteSelectedData(deleteData) {



        openSnack('success', `${deleteData.length} Data(s) Deleted`);

        // Filter out the deleted tasks from the local state
        const updatedData = data.filter(t => !deleteData.some(dt => dt.id === t.id));

        updateDataPosition(updatedData);

    }

    async function updateMultipleData(selectedData, bulkData) {

        openSnack('success', 'Data Updated!');

        // Update local state for the tasks.
        const updatedData = data.map(data => {
            if (selectedData.some(selectedData => selectedData.id === data.id)) {
                const { errorsCount, warningsCount } = checkErrors(data);
                return { ...data, ...bulkData, errorsCount, warningsCount, issues: errorsCount + warningsCount, };
            }
            return data;
        });
        setData(JSON.parse(JSON.stringify(updatedData)));
    }

    const selectedData = data?.filter(d => d.selected === true);





    return (
        <Row style={{}}>
            <Col span={24} style={{ textAlign: 'right', paddingBottom: 5, paddingRight: '5vw' }}>
                <Divider sx={{ mb: 1 }} />
                <BulkEditData {...propList} bulkEditor={bulkEditor} setBulkEditor={setBulkEditor} selectedData={selectedData} openSnack={openSnack} headers={headers} updateMultipleData={updateMultipleData} />
                <ErrorModal {...propList} errorModal={errorModal} setErrorModal={setErrorModal} selectedData={selectedData} openSnack={openSnack} headers={headers} />
                <ParametersModal {...propList} parametersModal={parametersModal} setParametersModal={setParametersModal} parameters={parameters} openSnack={openSnack} setParameters={setParameters} />
                <CodesModal {...propList} codesModal={codesModal} setCodesModal={setCodesModal} codes={codes} openSnack={openSnack} setCodes={setCodes} />
                <TemplateMaker {...propList} templateMaker={templateMaker} setTemplateMaker={setTemplateMaker} templates={templates} openSnack={openSnack} setTemplates={setTemplates} headers={headers} />

                <BulkDelete {...propList} selectedData={selectedData} deleteSelectedData={deleteSelectedData} />
                <TablePaginationSection {...propList} />
                <MoreTableOptions sortedData={sortedData} visibleHeaders={headers} selectedData={selectedData} setBulkEditor={setBulkEditor} deleteSelectedData={deleteSelectedData}
                    setCurrentCell={setCurrentCell} setParametersModal={setParametersModal} setCodesModal={setCodesModal} data={data} setData={setData} headers={headers}

                />
                <UploadDataButton {...propList} />

            </Col>
            <Col span={24} ref={tableContainerRef} style={{
                overflowX: 'auto',
                minHeight: '80vh'
            }}>


                <Row align="middle" >




                    {!uploading && (
                        <>
                            <Col span={24}>
                                <Divider />
                            </Col>
                            <div style={{
                                top: 0,
                                position: 'absolute',
                                display: 'inline-flex',
                                height: '40px',
                                backgroundColor: '#f7f7f7',
                                width: '100%',
                                zIndex: 0,
                                overflowX: 'auto',

                            }} />




                            <DragDropContext onDragEnd={onDragEnd} >
                                <Droppable droppableId="droppable" direction="horizontal">
                                    {(provided, snapshot) => (

                                        <div
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                            style={getListStyle(snapshot.isDraggingOver)}
                                        >

                                            <div className="box" style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                width: 20,
                                                borderBottom: '1px solid rgba(0,0,0,0.2)'
                                            }} />

                                            <div className="box" style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',

                                                width: 60,
                                                borderBottom: '1px solid rgba(0,0,0,0.2)',
                                            }}>
                                                <IconButton
                                                    onClick={() => handleSortChange('issues')}
                                                >
                                                    <Badge badgeContent={headerErrors} color="error">
                                                        {
                                                            sortItem?.split(',')[0]?.split(': ')[1] === 'issues' && sortItem?.split(',')[1]?.split(': ')[1] === 'asc' ? (
                                                                <PriorityHighIcon style={{ color: 'gray', cursor: 'pointer' }} />
                                                            ) : sortItem?.split(',')[0]?.split(': ')[1] === 'issues' && sortItem?.split(',')[1]?.split(': ')[1] === 'desc' ? (
                                                                <PriorityHighIcon style={{ color: 'gray', cursor: 'pointer' }} />
                                                            ) : (
                                                                <PriorityHighIcon style={{ color: 'gray', cursor: 'pointer' }} />
                                                            )
                                                        }
                                                    </Badge>
                                                </IconButton>


                                            </div>
                                            <div className="box" style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',

                                                width: 60,
                                                borderBottom: '1px solid rgba(0,0,0,0.2)',
                                            }}>
                                                <IconButton
                                                    onClick={() => toggleSelectAll()}
                                                >
                                                    <FontAwesomeIcon icon={allSelected ? faSquareCheck : faSquare}
                                                        size="md" style={{ color: '#3f51b5', cursor: 'pointer' }}
                                                    />
                                                </IconButton>

                                            </div>





                                            {headers.filter((h) => h.included === true).map((header, i) => {



                                                if (header.included === false) return null;

                                                return (
                                                    <Draggable draggableId={header.name} index={i} key={header.name}>
                                                        {(provided, snapshot) => (
                                                            <div
                                                                key={header.name}
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                            >
                                                                <Resizable
                                                                    width={header.width}
                                                                    height={40}
                                                                    minConstraints={[header.minWidth, 40]}
                                                                    onResize={(event, size, handle) => onResize(i, event, size, handle)}
                                                                    onResizeStop={(event, size, handle) => onResizeStop(i, event, size, handle)}
                                                                    onResizeStart={(event, size, handle) => onResizeStart(i, event, size, handle)}
                                                                    handle={
                                                                        <div className="foo"
                                                                            onDoubleClick={() => doubleClickResize(i)}
                                                                            style={{
                                                                                width: '8px',
                                                                                height: '100%',
                                                                                position: 'absolute',
                                                                                right: 0,
                                                                                top: 0,
                                                                                height: '40px',
                                                                                cursor: 'col-resize',

                                                                            }}
                                                                        />
                                                                    }
                                                                >
                                                                    <div className={i === 0 ? 'boxHeaderStart' : i === headers.length - 1 ? 'boxHeaderEnd' : 'boxHeader'} style={{
                                                                        //width should be 100% if only one header is visible or else it is `${header.width}px`
                                                                        width: headers.filter((header) => header.included === true).length === 1 ? `${header.width}px` : `${header.width}px`,
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'left',
                                                                        paddingLeft: 8,

                                                                        position: 'relative',
                                                                        height: '40px',
                                                                        ...getItemStyle(
                                                                            snapshot.isDragging,
                                                                            provided.draggableProps.style
                                                                        )
                                                                    }}>

                                                                        <div {...provided.dragHandleProps} style={{ cursor: 'grab' }}>
                                                                            <Typography variant="body1" fontWeight={600} fontSize={screenWidth === 'xs' ? 14 : 16}
                                                                                color={

                                                                                    [...parameters, ...ProbeParameters].find((u) => u.label === header?.name) ? 'inherit' :
                                                                                        ['node', 'date'].includes(header?.mappedField) ? 'inherit' : 'red'}
                                                                            >
                                                                                <span>{header?.mappedFieldLabel || header?.name}{header.required && <span style={{ color: 'red' }}>*</span>}
                                                                                    {!['node', 'date'].includes(header.mappedField) &&
                                                                                        (<span style={{ fontSize: 12, fontWeight: 400 }} > {header.units ? `(${header.units})` : `(n/a)`}</span>)
                                                                                    }
                                                                                </span>
                                                                            </Typography>
                                                                        </div>
                                                                        <IconButton
                                                                            onClick={() => handleSortChange(header.name)}
                                                                        >
                                                                            {
                                                                                sortItem === `key: ${header.name}, direction: asc` ? (
                                                                                    <CaretUpOutlined style={{ color: '#3f51b5', fontSize: 16 }} />
                                                                                ) : sortItem === `key: ${header.name}, direction: desc` ? (
                                                                                    <CaretDownOutlined style={{ color: '#3f51b5', fontSize: 16 }} />
                                                                                ) : (
                                                                                    <CaretDownOutlined style={{ color: 'rgba(0,0,0,0.2)', fontSize: 16 }} />
                                                                                )
                                                                            }
                                                                        </IconButton>
                                                                        {!['node', 'date'].includes(header.mappedField) && (
                                                                            <HeaderUnitsChange {...propList} header={header} />
                                                                        )}

                                                                        <div
                                                                            className="resizeHandle"
                                                                            style={{
                                                                                position: 'absolute',
                                                                                right: 0,
                                                                                top: 0,
                                                                                height: '40px',
                                                                                width: '5px',
                                                                                cursor: 'col-resize',
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </Resizable>

                                                            </div>
                                                        )}
                                                    </Draggable>
                                                )
                                            })}


                                            {provided.placeholder}



                                            <div className="box fixed-column" style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'right',
                                                paddingRight: 20,
                                                paddingLeft: 20,
                                                width: 150,
                                                borderBottom: '1px solid rgba(0,0,0,0.2)'
                                            }} >
                                                {/* Slider that moves the window left and right */}
                                                <AirbnbSlider
                                                    slots={{ thumb: AirbnbThumbComponent }}
                                                    getAriaLabel={(index) => (index === 0 ? 'Minimum price' : 'Maximum price')}
                                                    value={sliderValue}
                                                    onChange={(event, newValue) => {
                                                        if (Number.isInteger(newValue)) {
                                                            setSliderValue(newValue);
                                                            // Adjust the Col's scroll position
                                                            tableContainerRef.current.scrollLeft = newValue;
                                                        }
                                                    }}
                                                    max={maxScroll}
                                                    style={{ width: '100%' }}

                                                />
                                            </div>



                                        </div>

                                    )}
                                </Droppable>
                            </DragDropContext>
                        </>
                    )}




                    <Col span={24} style={{ paddingRight: 0, marginRight: 0 }}>

                        {uploading ? <Col span={24} style={{ paddingBottom: 30, textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                            <FontAwesomeIcon icon={faSpinner} spin size="2xl" color={'#3f51b5'} />
                            <Typography variant="h4" fontSize={24} fontWeight={600} style={{ marginLeft: 10 }}>Uploading Data</Typography>

                        </Col> :
                            <DataTableRows {...propList} headers={headers} updateDataPosition={updateDataPosition} />
                        }

                    </Col>
                    <Col span={24} style={{ paddingBottom: 30, paddingRight: 0, marginRight: 0, textAlign: 'right' }}>

                        <TablePaginationSection {...propList} />

                    </Col>





                    {loading && (<Col span={24} style={{ paddingBottom: 30, textAlign: 'center' }}>
                        <CircularProgress />
                    </Col>)}


                </Row>
            </Col >



        </Row >
    );
};

export default DataTableHeader;
