import React, { useEffect, useState, useRef } from "react";


import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { faCheckCircle, faCircle, faCopy, faEye, faEyeSlash, faSquare, faSquareCheck } from '@fortawesome/free-regular-svg-icons';
import { faFilter, faGripVertical, faRedo, faTrash, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Button, CircularProgress, Divider, IconButton, Tooltip, Typography, Badge, Switch, Checkbox, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, } from '@mui/material';
import { Col, Row } from 'antd';
import moment from 'moment-timezone';
import { Resizable } from 'react-resizable';
import { ReactSortable } from 'react-sortablejs';
import './taskList.css';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'; 
import ColumnSelection from "./columnSelection";

import FilterTable from "./filterTable";
import TaskCreateList from "./taskCreateList";

import { Sortable,  } from "sortablejs";
import TasksTableMulti from "./taskTableMulti";
import SelectableTool from "./selctableTool";
import MoreTableOptions from "./moreTableOptionsTool";
import BulkEditTasks from "./bulkEditTasks";
import ColumnEditor from "./columnEditor";
import TasksSubHeader from "./tasksSubHeader";
import Slider, { SliderThumb } from '@mui/material/Slider';
import { makeStyles } from "@mui/styles";
import { styled } from '@mui/material/styles';
const useStyles = makeStyles({
    thumb: {
        width: 24,
        height: 24,
        border: 'none',
        color: '#000', // Adjust color if needed
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
        '& .thumbIcon': {
            fontSize: '1rem',
        }
    }
});

const AirbnbSlider = styled(Slider)(({ theme }) => ({
    color: '#3f51b5',
    height: 3,
    padding: '13px 0',
    '& .MuiSlider-thumb': {
        height: 27,
        width: 27,
        backgroundColor: '#fff',
        border: '1px solid currentColor',
        '&:hover': {
            boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
        },
        '& .airbnb-bar': {
            height: 9,
            width: 1,
            backgroundColor: 'currentColor',
            marginLeft: 1,
            marginRight: 1,
        },
    },
    '& .MuiSlider-track': {
        height: 3,
    },
    '& .MuiSlider-rail': {
        color: theme.palette.mode === 'dark' ? '#bfbfbf' : '#d8d8d8',
        opacity: theme.palette.mode === 'dark' ? undefined : 1,
        height: 3,
    },
}));

function AirbnbThumbComponent(props) {
    const { children, ...other } = props;
    return (
        <SliderThumb {...other}>
            {children}
            <span className="airbnb-bar" />
            <span className="airbnb-bar" />
            <span className="airbnb-bar" />
        </SliderThumb>
    );
}




const TasksTable = (
    {
        tasks,
        sortType,
        setTasks,
        setSortType,
        screenWidth,
        showCompleted,
        setShowCompleted,
        loading,
        setLoading,
        currentTask,
        setCurrentTask,
        deleteTask,
        updateTask,
        setTaskDrawer,
        duplicateTask,
        updateTasksPosition,
        headers,
        setHeaders,
        openSnack,
        getNestedProperty,
        columnFilters,
        setColumnFilters,
        fullTasks,
        sortItem,
        setSortItem,
        createTask,
        selectable,
        setSelectable,
        deleteSelectedTasks,
        bulkEditor,
        setBulkEditor,
        staff,
        accounts,
        updateMultipleTasks,
        items,
        setItems,
        stocks,
        userInfo,
        taskTemplates,
        deleteTaskTemplate,
        columnEditor,
        setColumnEditor,
        setCalendarEditor,
        calendarEditor,
        deleteHeader,
        colorCalendar,
        setColorCalendar,
        createCalendar,
        calendars, 
        setCalendars
    }

) => {

    const [hoverKey, setHoverKey] = useState(null);
    const [headersHistory, setHeadersHistory] = useState([headers]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [initialWidth, setInitialWidth] = useState(null);
    const [firstClickedIndex, setFirstClickedIndex] = useState(null);
    const [allSelected, setAllSelected] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [undoHistory, setUndoHistory] = useState([]);
    const [redoHistory, setRedoHistory] = useState([]);
    const [sliderValue, setSliderValue] = useState(0);
    const [maxScroll, setMaxScroll] = useState(0);
    const textRef = useRef(null);

    const tableContainerRef = useRef(null);
    const classes = useStyles();

    useEffect(() => {
        if (tableContainerRef.current) {
            const maxScrollValue = tableContainerRef?.current?.scrollWidth - tableContainerRef?.current?.clientWidth;
            setMaxScroll(maxScrollValue);

            // Add an event listener to the Col to detect when it is scrolled
            const handleScroll = () => {
                setSliderValue(tableContainerRef?.current?.scrollLeft);
            };

            tableContainerRef?.current?.addEventListener("scroll", handleScroll);

            // Cleanup the listener on component unmount
            return () => {
                tableContainerRef?.current?.removeEventListener("scroll", handleScroll);
            };
        }
    }, [headers]);


    function onResize(index, event, { node, size, handle }) {
        const newHeaders = [...headers];
        newHeaders[index].width = size.width;
        setHeaders(newHeaders);

    }

    const onResizeStop = (index, event, { node, size, handle }) => {
        const newHeaders = [...headers];

        newHeaders[index].width = size.width;
        setHeaders(newHeaders);
        const updatedHeaders = JSON.parse(JSON.stringify(newHeaders));
        updatedHeaders[index].width = initialWidth;

        setRedoHistory([]); // Clear the redo history when you make a new change

        //set each item in undohistory to stringify and remove duplicates and then add to undohistory
        //set undohistory to the new array
        const newundo = [...undoHistory, JSON.parse(JSON.stringify(updatedHeaders))]
        const unique = [...new Set(newundo.map(item => JSON.stringify(item)))];
        const uniqueArray = unique.map(item => JSON.parse(item));
        setUndoHistory(uniqueArray);

    };

    const onResizeStart = (index, event, { node, size, handle }) => {
        const newHeaders = [...headers];
        setInitialWidth(newHeaders[index].width);
    };




    const doubleClickResize = (index) => {
        const newHeaders = [...headers];

        const strings = tasks.map((task) => {
            const value = getNestedProperty(task, newHeaders[index].id, newHeaders[index].getLabel, newHeaders[index]?.optionType, newHeaders[index]?.options, newHeaders[index]?.decimals);
            return value !== undefined ? value.toString() : '';
        }
        )
        const longestString = strings?.reduce((a, b) => a.length > b.length ? a : b, '');
        const width = longestString.length * 8;
        newHeaders[index].width = width < newHeaders[index].minWidth ? newHeaders[index].minWidth : width;
        setHeaders(newHeaders);
        const updatedHeaders = JSON.parse(JSON.stringify(newHeaders));
        updatedHeaders[index].width = initialWidth;
        setUndoHistory([...undoHistory, JSON.parse(JSON.stringify(updatedHeaders))]);
        setRedoHistory([]); // Clear the redo history when you make a new change
    };
 

    const props = {
        headers,
        setHeaders,
        openSnack,
        headers, setHeaders, redoHistory, setRedoHistory, undoHistory, setUndoHistory, tasks, initialWidth, getNestedProperty,
        columnFilters,
        setColumnFilters,
        fullTasks,
        setTasks,
        selectable,
        setSelectable,
        updateTask,
        setCurrentTask,
        currentTask,
        showCompleted,
        setTaskDrawer,
        deleteSelectedTasks,
        duplicateTask,
        deleteTask,
        updateTasksPosition,
        sortItem,
        setSortItem,
        setBulkEditor,
        bulkEditor,
        staff,
        accounts,
        updateMultipleTasks,
        getNestedProperty,
        items,
        setItems,
        stocks,
        userInfo,
        taskTemplates,
        deleteHeader,
        setShowCompleted,
        undoHistory,
        setUndoHistory,
        redoHistory,
        setRedoHistory,
        colorCalendar,
        setColorCalendar,
        columnEditor,
        setColumnEditor,
        calendarEditor,
        setCalendarEditor,
        createCalendar,
        calendars, 
        setCalendars

    }



    const onDragEnd = (result) => {

        const { source, destination } = result;
        if (!destination) {
            return;
        }



        const reorderedHeaders = Array.from(headers);
        const [removed] = reorderedHeaders.splice(source.index, 1);
        reorderedHeaders.splice(destination.index, 0, removed);

        reorderedHeaders.forEach((header, i) => {
            header.position = i;
        });
        setHeaders(reorderedHeaders);
        setUndoHistory([...undoHistory, JSON.parse(JSON.stringify(headers))]);
        setRedoHistory([]); // Clear the redo history when you make a new change



    };

    // Code to update all tasks to selected/unselected based on allSelected state
    const toggleSelectAll = () => {
        const newTasks = sortedTasks.map(task => ({ ...task, selected: !allSelected }));
        setTasks(newTasks); // Assume updateTasks is your function to update tasks
        setAllSelected(!allSelected);
    };

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer

        color: isDragging ? '#404050' : 'black',
        border: isDragging ? '1px solid #404050' : null,
        backgroundColor: isDragging ? 'white' : '#f7f7f7',
        //slightly rotate on dragging
        transform: isDragging ? 'rotate(5deg)' : 'none',
        borderBottom: isDragging ? '1px solid #404050' : '1px solid rgba(0,0,0,0.2)',


    });

    const getListStyle = isDraggingOver => ({
        display: 'inline-flex',
        height: '40px',
        backgroundColor: '#f7f7f7',

    });




    let sortedTasks = [...tasks]; // make a copy of tasks array

    // Extract the sorting key and direction from the sortItem string
    if (sortItem) {
        const sortKey = sortItem.split(',')[0].split(': ')[1];
        const sortDirection = sortItem.split(',')[1].split(': ')[1];

        const sortedHeader = headers.find(header => header.id === sortKey);

        //if undefined, setSortItem to null
        if (sortedHeader === undefined) {
            setSortItem(null);
            return;
        }

        //filter out any undefined tasks




        sortedTasks = sortedTasks.sort((a, b) => {
            let valueA = getNestedProperty(a, sortKey, sortedHeader?.getLabel, sortedHeader?.optionType, sortedHeader?.options, sortedHeader?.decimals);
            let valueB = getNestedProperty(b, sortKey, sortedHeader?.getLabel, sortedHeader?.optionType, sortedHeader?.options, sortedHeader?.decimals);



            if (sortedHeader.optionType === 'number' || sortedHeader.optionType === 'money') {
                valueA = valueA === undefined ? undefined : Number(valueA);
                valueB = valueB === undefined ? undefined : Number(valueB);
            }
            if (sortedHeader.optionType === 'percent') {
                let removepercent = valueA?.replace('%', '');
                valueA = removepercent === undefined ? undefined : Number(removepercent);
                removepercent = valueB?.replace('%', '');
                valueB = removepercent === undefined ? undefined : Number(removepercent);
            }



            // Handle cases where one or both values are undefined
            if (valueA === undefined && valueB !== undefined) {
                return 1;
            }
            if (valueB === undefined && valueA !== undefined) {
                return -1;
            }
            if (valueA === undefined && valueB === undefined) {
                return 0;
            }

            // Continue with your normal comparison for non-undefined values
            if (valueA < valueB) {
                return sortDirection === 'asc' ? -1 : 1;
            }
            if (valueA > valueB) {
                return sortDirection === 'asc' ? 1 : -1;
            }
            return 0;
        });
    }









    const handleSortChange = (keyToSort) => {
        const currentKey = sortItem?.split(',')[0]?.split(': ')[1];
        const currentDirection = sortItem?.split(',')[1]?.split(': ')[1];

        if (currentKey !== keyToSort) {
            setSortItem(`key: ${keyToSort}, direction: asc`);
        } else if (currentDirection === 'asc') {
            setSortItem(`key: ${keyToSort}, direction: desc`);
        } else if (currentDirection === 'desc') {
            setSortItem(null);
        }
    }



    const handleClickOpen = () => {
        setDeleteDialog(true);
    };

    const handleClose = () => {
        setDeleteDialog(false);
    };

    return (
        <Row style={{}}>
            <TasksSubHeader {...props} type={"table"} />

            <Col span={24} ref={tableContainerRef} style={{
                overflowX: 'auto',
                minHeight: '80vh'
            }}>


                <Row align="middle" >



                    <Col span={24}>
                        <Divider />
                    </Col>
                    <div style={{
                        top: 0,
                        position: 'absolute',
                        display: 'inline-flex',
                        height: '40px',
                        backgroundColor: '#f7f7f7',
                        width: '100%',
                        zIndex: 0,
                        overflowX: 'auto',

                    }} />




                    <DragDropContext onDragEnd={onDragEnd} >
                        <Droppable droppableId="droppable" direction="horizontal">
                            {(provided, snapshot) => (

                                <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    style={getListStyle(snapshot.isDraggingOver)}
                                >

                                    <div className="box" style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: 20,
                                        borderBottom: '1px solid rgba(0,0,0,0.2)'
                                    }} />
                                    {
                                        selectable && (
                                            <div className="box" style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',

                                                width: 60,
                                                borderBottom: '1px solid rgba(0,0,0,0.2)',
                                            }}>
                                                <IconButton
                                                    onClick={() => toggleSelectAll()}
                                                >
                                                    <FontAwesomeIcon icon={allSelected ? faSquareCheck : faSquare}
                                                        size="md" style={{ color: '#3f51b5', cursor: 'pointer' }}
                                                    />
                                                </IconButton>

                                            </div>
                                        )
                                    }
                                    <div className="box" style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: 60,
                                        borderBottom: '1px solid rgba(0,0,0,0.2)',

                                    }} >
                                        <IconButton
                                            onClick={() => handleSortChange('completed')}
                                        >
                                            {
                                                sortItem?.split(',')[0]?.split(': ')[1] === 'completed' && sortItem?.split(',')[1]?.split(': ')[1] === 'asc' ? (
                                                    <CaretUpOutlined style={{ color: '#3f51b5', fontSize: 16 }} />
                                                ) : sortItem?.split(',')[0]?.split(': ')[1] === 'completed' && sortItem?.split(',')[1]?.split(': ')[1] === 'desc' ? (
                                                    <CaretDownOutlined style={{ color: '#3f51b5', fontSize: 16 }} />
                                                ) : (
                                                    <CaretDownOutlined style={{ color: 'rgba(0,0,0,0.2)', fontSize: 16 }} />
                                                )
                                            }
                                        </IconButton>

                                    </div>


                                    {headers?.sort((a, b) => a.position - b.position)

                                        .map((header, i) => {
 
 
                                            const isFiltered = columnFilters && columnFilters[header.id]?.length > 0;

                                            if (header.visible === false) return null;

                                            return (
                                                <Draggable draggableId={header.id} index={i} key={header.id}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            key={header.id}
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                        >
                                                            <Resizable
                                                                width={header.width}
                                                                height={40}
                                                                minConstraints={[header.minWidth, 40]}
                                                                onResize={(event, size, handle) => onResize(i, event, size, handle)}
                                                                onResizeStop={(event, size, handle) => onResizeStop(i, event, size, handle)}
                                                                onResizeStart={(event, size, handle) => onResizeStart(i, event, size, handle)}
                                                                handle={
                                                                    <div className="foo"
                                                                        onDoubleClick={() => doubleClickResize(i)}
                                                                        style={{
                                                                            width: '8px',
                                                                            height: '100%',
                                                                            position: 'absolute',
                                                                            right: 0,
                                                                            top: 0,
                                                                            height: '40px',
                                                                            cursor: 'col-resize',

                                                                        }}
                                                                    />
                                                                }
                                                            >
                                                                <div className={i === 0 ? 'boxHeaderStart' : i === headers.length - 1 ? 'boxHeaderEnd' : 'boxHeader'} style={{
                                                                    //width should be 100% if only one header is visible or else it is `${header.width}px`
                                                                    width: headers.filter((header) => header.visible === true).length === 1 ? `${header.width}px` : `${header.width}px`,
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'left',
                                                                    paddingLeft: 8,

                                                                    position: 'relative',
                                                                    height: '40px',
                                                                    ...getItemStyle(
                                                                        snapshot.isDragging,
                                                                        provided.draggableProps.style
                                                                    )
                                                                }}>

                                                                    <div {...provided.dragHandleProps} style={{ cursor: 'grab' }}

                                                                    >
                                                                        <Typography variant="body1" fontWeight={700} fontSize={screenWidth === 'xs' ? 14 : 20}>
                                                                            <span >{header.headerName}</span>
                                                                            {isFiltered && (
                                                                                <Badge badgeContent={columnFilters[header.id]?.length} color="error" style={{ marginLeft: 5 }}>
                                                                                    <FontAwesomeIcon icon={faFilter} size="md" style={{ color: 'rgba(0,0,0,0.2)', paddingLeft: 5 }} />
                                                                                </Badge>
                                                                            )}

                                                                        </Typography>

                                                                    </div>
                                                                    <IconButton
                                                                        onClick={() => handleSortChange(header.id)}
                                                                    >
                                                                        {
                                                                            sortItem === `key: ${header.id}, direction: asc` ? (
                                                                                <CaretUpOutlined style={{ color: '#3f51b5', fontSize: 16 }} />
                                                                            ) : sortItem === `key: ${header.id}, direction: desc` ? (
                                                                                <CaretDownOutlined style={{ color: '#3f51b5', fontSize: 16 }} />
                                                                            ) : (
                                                                                <CaretDownOutlined style={{ color: 'rgba(0,0,0,0.2)', fontSize: 16 }} />
                                                                            )
                                                                        }
                                                                    </IconButton>

                                                                    <div
                                                                        className="resizeHandle"
                                                                        style={{
                                                                            position: 'absolute',
                                                                            right: 0,
                                                                            top: 0,
                                                                            height: '40px',
                                                                            width: '5px',
                                                                            cursor: 'col-resize',
                                                                        }}
                                                                    />
                                                                </div>
                                                            </Resizable>

                                                        </div>
                                                    )}
                                                </Draggable>
                                            )
                                        })}


                                    {provided.placeholder}



                                    <div className="box fixed-column" style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'right',
                                        paddingRight: 20,
                                        paddingLeft: 20,
                                        width: 150,
                                        borderBottom: '1px solid rgba(0,0,0,0.2)'
                                    }} >
                                        {/* Slider that moves the window left and right */}
                                        <AirbnbSlider
                                            slots={{ thumb: AirbnbThumbComponent }}
                                            getAriaLabel={(index) => (index === 0 ? 'Minimum price' : 'Maximum price')}
                                            value={sliderValue}
                                            onChange={(event, newValue) => {
                                                if (Number.isInteger(newValue)) {
                                                    setSliderValue(newValue);
                                                    // Adjust the Col's scroll position
                                                    tableContainerRef.current.scrollLeft = newValue;
                                                }
                                            }}
                                            max={maxScroll}
                                            style={{ width: '100%' }}

                                        />
                                    </div>



                                </div>

                            )}
                        </Droppable>
                    </DragDropContext>




                    <Col span={24} style={{ paddingBottom: 30, paddingRight: 0, marginRight: 0 }}>
                        <TasksTableMulti {...props} sortedTasks={sortedTasks} updateTasksPosition={updateTasksPosition} />


                    </Col>

 

                    {loading === false && tasks?.length === 0 && (<Col span={24} style={{ paddingBottom: 30, textAlign: 'center' }}>
                        <Typography variant="h4" fontSize={24} fontWeight={600}>No Tasks</Typography>
                    </Col>)}
                    {loading && (<Col span={24} style={{ paddingBottom: 30, textAlign: 'center' }}>
                        <CircularProgress />
                    </Col>)}


                </Row>
            </Col >



        </Row >
    );
};

export default TasksTable;
