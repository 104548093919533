import React, { useState } from "react";


import { faBox, faCheck, faListCheck, faPlus, faSpinner, faWrench, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, ButtonGroup, FormControl, Grid, IconButton, InputLabel, MenuItem, Modal, OutlinedInput, Select, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import uuid from 'react-uuid';
import './itemList.css';
import ItemSubTypeSelector from "./itemComponents/itemSubTypeSelector";
import Bacteria from "./itemComponents/subComponents/products/bacteria";
import FilesList from "./itemComponents/subComponents/files/filesList";
import LightboxComponent from "../../../../../documentsNew/components/filesComponents/lightBox";
import { createItem } from "./utils/functions";
import Chemical from "./itemComponents/subComponents/products/chemical";
import Dye from "./itemComponents/subComponents/products/dye";
import Fish from "./itemComponents/subComponents/products/fish";
import ServiceInfo from "./itemComponents/subComponents/services/generalInfo";


const ItemCreatorModal = (
    {
        screenWidth,
        items,
        setItems,
        userInfo,
        openSnack,
        setCreateItemModal,
        createItemModal,
        updateRecord,
        record




    }

) => {
    const [newItem, setNewItem] = useState({});
    const [activeTab, setActiveTab] = useState('create');
    const [isFocused, setIsFocused] = useState(false);

    const [isLightboxOpen, setIsLightboxOpen] = React.useState(false);
    const [currentImageIndex, setCurrentImageIndex] = React.useState(0);
    const [files, setFiles] = useState([]);
    const [creatingItem, setCreatingItem] = useState(false);


    const menuItems = [
        { label: 'Product', value: 'products', icon: faBox },
        { label: 'Service', value: 'services', icon: faWrench },
        { label: 'Template', value: 'inspection', icon: faListCheck, },

    ];



    const rowFixedStyle = {
        flexShrink: 0,
    };




    const modalContentStyle = {
        display: 'flex',
        flexDirection: 'column',
        height: '90vh', // Make sure it takes the full height of the modal,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: screenWidth === 'xs' ? '90vw' : '60vw',
        bgcolor: 'background.paper',
        border: '1px solid #000',
    };

    // Adjusted style for the content area (Row 1 and Row 2)
    const contentAreaStyle = {
        flexGrow: 1,
        overflowX: 'hidden', // Hide horizontal scrollbar
        overflowY: 'auto', // Add scrolling if content is too long
        // ... other styles you might need
    };

    const optionsAreValid =
        newItem?.options?.length > 0 &&
        newItem?.options?.every(option => option.name && option.name.trim() !== '');


    async function createItemFunction() {
        setCreatingItem(true)
        let res = await createItem(newItem, userInfo, openSnack, setItems, setCreateItemModal)
        if (res !== 'error') {
            setNewItem({})
            setCreatingItem(false)
            console.log(newItem)
            const newRecord = {
                ...record, item: res?.id || res?.key || null, psType: res?.psType || null, itemDescription: res?.description || null,
                subType: res?.productType?.label || res?.serviceType?.label || res?.subType
            }
            console.log(newRecord)
            updateRecord(newRecord)
        }
        else {
            setCreatingItem(false)
            openSnack('error', 'Error creating item')
        }


    }



    return (
        <>




            <Modal title="" open={createItemModal}



                onClose={() => {
                    setCreateItemModal(false)


                }} footer={null} width={'60%'}  >
                <Box sx={modalContentStyle}>

                    <Row style={rowFixedStyle} className="row1">
                        <Col span={20} style={{ padding: 30, paddingBottom: 10 }}>
                            <Typography variant="h6" component="div" fontWeight={600} fontSize={28}>
                                Add Item
                            </Typography>
                            <Typography variant='body1' fontWeight={300} fontSize={16}>
                                Create a product or services that you use/perform on your sites for you clients and customers.
                            </Typography>
                        </Col>
                        <Col span={4} style={{
                            textAlign: 'right',
                            paddingTop: 10, paddingRight: 20,
                        }}>
                            <IconButton
                                style={{


                                }} aria-label="close" onClick={() => {
                                    setCreateItemModal(false)
                                }}>
                                <FontAwesomeIcon icon={faX} size="md"
                                    onClick={() => {
                                        setCreateItemModal(false)
                                    }} />
                            </IconButton>
                        </Col>

                        <Col xs={24} sm={24} style={{
                            height: '50px',
                            borderBottom: '1px solid rgba(0,0,0,0.2)',
                            display: 'flex',
                            alignItems: 'flex-end', // Adjusted this line
                            justifyContent: 'flex-start', // Adjusted this line for better readability
                        }}>
                            <ButtonGroup size="small" aria-label="small button group" style={{
                                marginLeft: screenWidth === 'xs' ? 20 : 40
                            }}>
                                {[
                                    { label: 'Create new', value: 'create' },
                                ].map((item, index) => (
                                    <Button
                                        key={item.value}
                                        onClick={() => setActiveTab(item.value)}
                                        color='primary'
                                        variant={null}
                                        sx={{
                                            px: 0
                                        }}
                                        style={{
                                            marginRight: 20,
                                            height: '50px',
                                            textTransform: 'none',
                                            borderRadius: 0,
                                            fontWeight: activeTab === item.value ? 700 : 400,
                                            fontSize: 18,
                                            color: activeTab === item.value ? 'rgba(0,0,0,0.9)' : null,
                                            borderBottom: activeTab === item.value ? '3px solid rgba(0,0,0,0.9)' : '3px solid rgba(0,0,0,0.0)'
                                        }}
                                    >
                                        {item.label}
                                    </Button>
                                ))}
                            </ButtonGroup>
                        </Col>

                    </Row>
                    <Box sx={contentAreaStyle}>
                        <Row style={rowFixedStyle} className="row1a">


                            <Col xs={24} sm={24}
                                style={{
                                    paddingLeft: screenWidth === 'xs' ? 20 : 40,
                                    marginTop: 20,
                                    paddingRight: screenWidth === 'xs' ? 20 : 40,

                                }} >
                                <Row >
                                    <Col xs={24} sm={14} >
                                        <Typography variant="h6" component="div" color={'rgba(0,0,0,0.6)'} fontWeight={600} fontSize={16}>
                                            Item name <span style={{ color: 'red' }}>*</span>
                                        </Typography>
                                        <OutlinedInput
                                            margin="dense"

                                            style={{
                                                borderRadius: 12,
                                                marginTop: screenWidth === 'xs' ? 2 : 5,
                                                marginBottom: 20,
                                                width: '100%',
                                                fontSize: 16,
                                            }}
                                            value={newItem.name}
                                            onChange={(e) => {
                                                setNewItem({ ...newItem, name: e.target.value })
                                            }}
                                            placeholder={'Item name'}
                                        />
                                    </Col>
                                    <Col xs={24} sm={{ span: 9, offset: 1 }} >
                                        <Typography variant="h6" component="div" color={'rgba(0,0,0,0.6)'} fontWeight={600} fontSize={16}>
                                            Item type <span style={{ color: 'red' }}>*</span>
                                        </Typography>
                                        <FormControl fullWidth variant="standard">
                                            {!newItem?.optionType && !isFocused && (
                                                <InputLabel id="demo-simple-select-standard-label"
                                                    style={{
                                                        marginLeft: 15, marginTop: 2, fontSize: 16,
                                                        color: 'rgba(0,0,0,0.4)',
                                                    }}
                                                >
                                                    Item Type
                                                </InputLabel>
                                            )}
                                            <Select
                                                style={{
                                                    borderRadius: 12,
                                                    marginTop: screenWidth === 'xs' ? 2 : 5,
                                                    marginBottom: 20,
                                                    fontSize: 16,
                                                    width: '100%',
                                                    //no wrap
                                                    whiteSpace: 'nowrap',
                                                    display: 'inline-block',
                                                }}
                                                fullWidth
                                                onFocus={() => setIsFocused(true)}
                                                onBlur={() => setIsFocused(false)}
                                                placeholder="Select"
                                                variant="outlined"
                                                value={newItem.psType}
                                                onChange={(e) => setNewItem({ ...newItem, psType: e.target.value })}

                                                renderValue={(selectedValue) => {
                                                    const selectedItem = menuItems.find(item => item.value === selectedValue);
                                                    if (!selectedItem) return null;

                                                    return (
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <FontAwesomeIcon icon={selectedItem.icon} size="lg" style={{ marginRight: '10px' }} />
                                                            <Typography variant="body1" component="span" color={'rgba(0,0,0,0.6)'} fontWeight={600} fontSize={16}>
                                                                {selectedItem.label}
                                                            </Typography>
                                                        </div>
                                                    );
                                                }}
                                            >
                                                {menuItems.map(item => (
                                                    <MenuItem
                                                        sx={{
                                                            p: 1
                                                        }} key={item.value} value={item.value}>
                                                        {newItem.psType === item.value ?
                                                            <FontAwesomeIcon icon={faCheck} size="lg" style={{ marginRight: '10px' }} />
                                                            : <div style={{ width: 24, height: 24, marginRight: 10 }}></div>}
                                                        <FontAwesomeIcon icon={item.icon} size="lg" style={{ marginRight: '10px' }} />
                                                        <Typography variant="h6" component="div" color={'rgba(0,0,0,0.6)'} fontWeight={600} fontSize={16}>
                                                            {item.label}
                                                        </Typography>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Col>

                                    <Grid item xs={12} md={12}>
                                        <Typography variant="h6" component="div" color={'rgba(0,0,0,0.8)'} fontWeight={500} fontSize={16}>
                                            Description
                                        </Typography>
                                        <OutlinedInput
                                            margin="dense"

                                            style={{
                                                borderRadius: 12,
                                                marginTop: screenWidth === 'xs' ? 2 : 5,
                                                marginBottom: 10,
                                                width: '100%',
                                                fontSize: 16,
                                            }}
                                            multiline={true}
                                            rows={2}
                                            value={newItem.description}
                                            onChange={(e) => {
                                                setNewItem({ ...newItem, description: e.target.value })
                                            }}
                                            placeholder={'Description'}
                                        />
                                    </Grid>

                                    {['services', 'products'].includes(newItem.psType) && (
                                        <ItemSubTypeSelector newItem={newItem} setNewItem={setNewItem} screenWidth={screenWidth} />
                                    )}

                                    {newItem.psType === 'services' && (
                                        <ServiceInfo newItem={newItem} setNewItem={setNewItem} screenWidth={screenWidth} userInfo={userInfo} openSnack={openSnack} />
                                    )}




                                    {'Bacterial' === newItem?.subType && newItem.psType === 'products' && (
                                        <Bacteria newItem={newItem} setNewItem={setNewItem} screenWidth={screenWidth} userInfo={userInfo} openSnack={openSnack} />
                                    )}
                                    {'Chemical' === newItem?.subType && newItem.psType === 'products' && (
                                        <Chemical newItem={newItem} setNewItem={setNewItem} screenWidth={screenWidth} userInfo={userInfo} openSnack={openSnack} />
                                    )}
                                    {'Dye' === newItem?.subType && newItem.psType === 'products' && (
                                        <Dye newItem={newItem} setNewItem={setNewItem} screenWidth={screenWidth} userInfo={userInfo} openSnack={openSnack} />
                                    )}
                                    {'Fish' === newItem?.subType && newItem.psType === 'products' && (
                                        <Fish newItem={newItem} setNewItem={setNewItem} screenWidth={screenWidth} userInfo={userInfo} openSnack={openSnack} />
                                    )}




                                    <Grid item xs={12} md={12}>
                                        <Typography variant="h6" component="div" color={'rgba(0,0,0,0.8)'} fontWeight={500} fontSize={16}>
                                            Documents
                                        </Typography>
                                        <FilesList
                                            newItem={newItem}
                                            setNewItem={setNewItem}
                                            userInfo={userInfo}
                                            openSnack={openSnack}
                                            files={files}
                                            setFiles={setFiles}
                                            setCurrentImageIndex={setCurrentImageIndex}
                                            setIsLightboxOpen={setIsLightboxOpen}

                                        />

                                    </Grid>





                                </Row>

                            </Col>

                        </Row>
                    </Box>

                    <Row style={rowFixedStyle} className="row3">

                        <Col xs={24} sm={24} style={{
                            borderTop: '1px solid rgba(0,0,0,0.2)',
                            marginTop: 20,
                            paddingTop: 10,
                            paddingBottom: 10,
                        }}>
                            <Row
                                justify="end"
                                align={'middle'}

                                style={{
                                    padding: 20,

                                }}>
                                {creatingItem === false ?
                                    <Col style={{

                                    }}>
                                        <Button
                                            variant="default"

                                            style={{
                                                textTransform: 'none',
                                                borderRadius: 12,
                                                fontWeight: 500,
                                                fontSize: 16,
                                                marginRight: 20,

                                                height: 40,
                                                border: '1px solid rgba(0,0,0,0.4)',


                                            }}
                                            onClick={() => {
                                                setCreateItemModal(false)
                                            }}

                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="contained"
                                            style={{
                                                textTransform: 'none',
                                                borderRadius: 12,
                                                fontWeight: 500,
                                                fontSize: 16,
                                                height: 40,
                                                backgroundColor: !newItem.name || !newItem.psType || (['singleSelect', 'multipleSelect'].includes(newItem.psType) && !optionsAreValid) ? 'rgba(0,0,0,0.2)' : '#3f51b5',
                                                color: !newItem.name || !newItem.psType || (['singleSelect', 'multipleSelect'].includes(newItem.psType) && !optionsAreValid) ? 'rgba(0,0,0,0.4)' : 'white',
                                            }}
                                            onClick={async () => {
                                                if (!newItem.name || !newItem.psType || (['singleSelect', 'multipleSelect'].includes(newItem.psType) && !optionsAreValid)) {
                                                    openSnack('error', 'Please fill out all required fields')
                                                    return;
                                                }
                                                createItemFunction()

                                            }}

                                        >
                                            Create item
                                        </Button>

                                    </Col>
                                    :
                                    <Col style={{

                                    }}>
                                        <Typography variant="body1" component="div" color={'rgba(0,0,0,0.6)'} fontWeight={600} fontSize={16}>
                                            Creating item...
                                        </Typography>
                                        <FontAwesomeIcon icon={faSpinner} spin size="lg" style={{ marginRight: '10px' }} />

                                    </Col>
                                }

                            </Row>


                        </Col>




                    </Row>








                </Box>
            </Modal >


            {isLightboxOpen && (
                <LightboxComponent
                    type="item"
                    isLightboxOpen={isLightboxOpen}
                    setIsLightboxOpen={setIsLightboxOpen}
                    file={files[currentImageIndex]}
                    image={files[currentImageIndex].large}
                    alt={files[currentImageIndex].name}
                    screenWidth={screenWidth}
                    nextImage={() => setCurrentImageIndex((currentImageIndex + 1) % files.length)}
                    prevImage={() => setCurrentImageIndex((currentImageIndex + files.length - 1) % files.length)}
                />

            )}
        </>



    );
};

export default ItemCreatorModal;
