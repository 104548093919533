import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Autocomplete, Button, CircularProgress, Divider, IconButton, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';
import { Col, Popconfirm, Row } from 'antd';
import { doc, getDoc, getFirestore, setDoc, updateDoc } from 'firebase/firestore';
import React from 'react';
import CSVReader from './csvThing';

export default class Capacity extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            step: '',
            keys: [],
            previewData: [],
            areaMap: '',
            depthMap: '',
            volumeMap: '',
            depthUnits: '',
            areaUnits: '',
            volumeUnits: '',
            loading: true,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }

    }
    componentDidMount() {
        window.scrollTo(0, 0)
        this.getDocItem()
    }

    getDocItem = async () => {
        const { userInfo } = this.state;
        const db = getFirestore();
        const docRef = doc(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'bathymetry', 'bathymetry');
        const docSnap = await getDoc(docRef);

        console.log(docSnap.data())

        if (docSnap.exists()) {
            this.setState({
                step: 'final',
                previewData: docSnap.data().data,
                areaUnits: docSnap.data().areaUnits,
                volumeUnits: docSnap.data().volumeUnits,
                depthUnits: docSnap.data().depthUnits, loading: false
            })
        }
        else {
            this.setState({ loading: false })
        }
    }

    orderData = () => {
        const { data, keys, dateFormat, depthMap, areaMap, volumeMap, userInfo } = this.state;

        const array = [];
        console.log(depthMap, areaMap, volumeMap)
        const labels = [depthMap.label, areaMap.label, volumeMap.label,];
        const newLabels = ['depth', 'area', 'volume',];
        const updatedKeys = keys.map((k, i) => {
            if (labels.includes(k.label)) {
                const index = labels.indexOf(k.label);
                const label = newLabels[index];
                return ({ ...k, label })

            }
            else {
                return (k)
            }
        })
        data.map((d) => {
            const keysList = updatedKeys.map((d) => d.label);
            const values = d;
            const merged = keysList.reduce((obj, key, index) => ({ ...obj, [key]: Number(values[index]) }), {});
            array.push(merged)
        })
        console.log(array);


        this.setState({ previewData: array, step: 'final' })

    }

    uploadData = async () => {
        const db = getFirestore();
        const { previewData, userInfo, volumeUnits, areaUnits, depthUnits } = this.state;
        const docRef = doc(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'bathymetry', 'bathymetry');
        await setDoc(docRef,
            {
                data: previewData,
                volumeUnits,
                areaUnits,
                depthUnits
            }).then(() => {
                this.props.openSnack('success', "Capacity Uploaded!")
            })
    }

    render() {

        const { account, userInfo, previewData, areaMap, depthMap, volumeMap, screenWidth, keys, step, depthUnits, areaUnits, volumeUnits, loading } = this.state;

        const DeleteItem = ({ item }) => {

            return (
                <Popconfirm
                    title="Are you sure to delete this row?"
                    onConfirm={async () => {
                        this.props.openSnack('success', 'Row removed.')

                        const newData = previewData.filter((f) => f.depth !== item.depth)
                        this.setState({ previewData: newData })


                    }}
                    okText="Yes"
                    cancelText="No"
                >
                    <IconButton style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="small"
                    >
                        <DeleteForeverIcon />
                    </IconButton>
                </Popconfirm>


            )
        }

        const ReviewTable = () => {

            return (
                <Row gutter={[4, 4]} style={{ fontFamily: 'Roboto, sans-serif' }}>
                    <Col span={24}>
                        <Row>
                            <Col span={6}>
                                Depth ({depthUnits})
                            </Col>
                            <Col span={6}>
                                Area ({areaUnits})
                            </Col>
                            <Col span={6}>
                                Volume ({volumeUnits})
                            </Col>
                            <Col span={4}>
                                Delete
                            </Col>

                        </Row>
                    </Col>
                    <Col span={24}>
                        <Divider />
                    </Col>
                    {previewData.map((d, i) => {
                        return (
                            <Col span={24} key={d.key}>
                                <Row align="middle" gutter={[4, 4]} >
                                    <Col span={6}>
                                        {d.depth.toLocaleString()}
                                    </Col>
                                    <Col span={6}>
                                        {d.area.toLocaleString()}
                                    </Col>
                                    <Col span={6}>
                                        {d.volume.toLocaleString()}
                                    </Col>
                                    <Col span={4}>
                                        <DeleteItem item={d} />
                                    </Col>

                                    <Col span={24}>
                                        <Divider />
                                    </Col>

                                </Row>
                            </Col>
                        )
                    })}
                </Row>
            )
        }

        const checks = [
            Object.values(depthMap).length > 0 ? true : false,
            Object.values(volumeMap).length > 0 ? true : false,
            Object.values(areaMap).length > 0 ? true : false,
            depthUnits.length > 0 ? true : false,
            areaUnits.length > 0 ? true : false,
            volumeUnits.length > 0 ? true : false,
        ]

        return (
            <Row style={{}}>
                <Col span={23}>
                    <Row align="middle">

                        <Col span={24} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 30 }}>
                            <Paper elevation={16} style={{ padding: 20, borderRadius: 12 }}>
                                <Row>
                                    <Col xs={24} style={{ paddingBottom: 8 }}>
                                        <CSVReader getKeys={(results) => {
                                            const data = results.data;
                                            const firstElement = data.shift();


                                            const numberData = data.map((d) => {
                                                return d.map((d) => {
                                                    const removeSpaces = d.replace(/\s+/g, '');
                                                    const removeCommas = removeSpaces.replace(/,/g, '');
                                                    return removeCommas
                                                })
                                            })

                                            const numbers = numberData.map((d) => {
                                                return d.map((d) => Number(d))
                                            })

                                            console.log(numbers);
                                            console.log(numberData);

                                            const keys = firstElement.map((k, i) => { return ({ label: k, key: i }) })
                                            console.log(keys)


                                            this.setState({ step: 'review', keys, data: numberData })
                                        }}
                                            removeFile={async (s) => {
                                                console.log('remove capacity data')
                                                const db = getFirestore();
                                                const docRef = doc(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'bathymetry', 'bathymetry');
                                                await updateDoc(docRef,
                                                    {
                                                        data: [],
                                                        volumeUnits,
                                                        areaUnits,
                                                        depthUnits
                                                    }).then(() => {
                                                        this.props.openSnack('success', "Capacity Uploaded!")
                                                    })
                                                this.setState({ step: s, keys: [] })
                                            }} />
                                    </Col>




























                                    <Col span={24} style={{ paddingTop: 20 }}>
                                        {step === 'review' && (

                                            <Row gutter={[12, 12]} align="middle">
                                                <Col xs={{ offset: 0, span: 24 }} sm={{ offset: 6, span: 18 }} style={{ paddingBottom: 12, }}>
                                                    <Typography variant='h6'>Map Columns</Typography>
                                                </Col>


                                                <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                                                    <Typography variant="body1">Depth<span style={{ color: 'red' }}>*</span></Typography>
                                                </Col>
                                                <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                                    <Autocomplete
                                                        size="small"
                                                        disableClearable={true}
                                                        id="combo-box-demo"
                                                        value={depthMap || ''}

                                                        options={keys}
                                                        filterSelectedOptions
                                                        renderInput={(params) => <TextField {...params} label="Depth" size="small" />}
                                                        onChange={(a, b) => {
                                                            console.log(b);
                                                            this.setState({ depthMap: b })
                                                        }}

                                                    />
                                                </Col>
                                                <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                                                    <Typography variant="body1">Depth Units<span style={{ color: 'red' }}>*</span></Typography>
                                                </Col>
                                                <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                                    <Select size="small" fullWidth
                                                        notched={false}
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={depthUnits || ''}
                                                        label="Depth Units"
                                                        onChange={(e) => {
                                                            this.setState({ depthUnits: e.target.value })
                                                        }}

                                                    >
                                                        <MenuItem value={'ft'}>ft</MenuItem>
                                                        <MenuItem value={'m'}>m</MenuItem>

                                                    </Select>
                                                </Col>
                                                <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                                                    <Typography variant="body1">Area<span style={{ color: 'red' }}>*</span></Typography>
                                                </Col>
                                                <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                                    <Autocomplete
                                                        size="small"
                                                        disableClearable={true}
                                                        id="combo-box-demo"
                                                        value={areaMap || ''}

                                                        options={keys}
                                                        filterSelectedOptions
                                                        renderInput={(params) => <TextField {...params} label="Area" size="small" />}
                                                        onChange={(a, b) => {

                                                            this.setState({ areaMap: b })
                                                        }}

                                                    />
                                                </Col>
                                                <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                                                    <Typography variant="body1">Area Units<span style={{ color: 'red' }}>*</span></Typography>
                                                </Col>
                                                <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                                    <Select size="small" fullWidth
                                                        notched={false}
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={areaUnits || ''}
                                                        label="Area Units"
                                                        onChange={(e) => {
                                                            this.setState({ areaUnits: e.target.value })
                                                        }}

                                                    >
                                                        <MenuItem value={'sqft'}>sqft</MenuItem>
                                                        <MenuItem value={'acre'}>acre</MenuItem>

                                                    </Select>
                                                </Col>
                                                <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                                                    <Typography variant="body1">Volume<span style={{ color: 'red' }}>*</span></Typography>
                                                </Col>
                                                <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                                    <Autocomplete
                                                        size="small"
                                                        disableClearable={true}
                                                        id="combo-box-demo"
                                                        value={volumeMap || ''}

                                                        options={keys}
                                                        filterSelectedOptions
                                                        renderInput={(params) => <TextField {...params} label="Volume" size="small" />}
                                                        onChange={(a, b) => {

                                                            this.setState({ volumeMap: b })
                                                        }}

                                                    />
                                                </Col>
                                                <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                                                    <Typography variant="body1">Volume Units<span style={{ color: 'red' }}>*</span></Typography>
                                                </Col>
                                                <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                                    <Select size="small" fullWidth
                                                        notched={false}
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={volumeUnits || ''}
                                                        label="Volume Units"
                                                        onChange={(e) => {
                                                            this.setState({ volumeUnits: e.target.value })
                                                        }}

                                                    >
                                                        <MenuItem value={'gal'}>gal</MenuItem>
                                                        <MenuItem value={'cubic feet'}>cubic feet</MenuItem>
                                                        <MenuItem value={'acre feet'}>acre feet</MenuItem>

                                                    </Select>
                                                </Col>

                                                <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>

                                                </Col>


                                                {!checks.includes(false) && (<Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                                    <Button style={{ textTransform: 'none', padding: 12, fontSize: 16 }} variant="contained" color="primary"
                                                        onClick={async () => {
                                                            this.orderData()
                                                        }}>Review Data</Button>
                                                </Col>)}


                                            </Row>

                                        )}
                                    </Col>

                                    {loading && (
                                        <Col span={24} style={{ paddingTop: 20 }}>
                                            <Row gutter={[12, 12]} align="middle">
                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                    <CircularProgress />
                                                </Col>
                                            </Row>
                                        </Col>
                                    )}
                                    <Col span={24} style={{ paddingTop: 20 }}>
                                        {step === 'final' && (

                                            <Row gutter={[12, 12]} align="middle">


                                                <Col span={24} style={{}}>
                                                    <Divider />
                                                </Col>
                                                <Col span={24} style={{}}>
                                                    <ReviewTable />
                                                </Col>
                                                <Button style={{ textTransform: 'none', padding: 12, fontSize: 16 }} variant="contained" color="primary"
                                                    onClick={async () => {
                                                        this.uploadData()
                                                    }}>Upload Data</Button>









                                            </Row>

                                        )}
                                    </Col>
                                </Row>
                            </Paper>
                        </Col>
                    </Row>
                </Col >

            </Row >
        );
    }
} 