// Layer.jsx
import React, { useEffect, useRef, useState } from 'react';
import { Typography, IconButton, Switch, Button, ClickAwayListener, Popper, Box, List, ListItem, Modal, Backdrop, OutlinedInput, Divider } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Col, Row } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { fullMobile } from '../settings/util';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '1px solid rgba(0,0,0,0.05)',
    borderRadius: 4,
    boxShadow: 24,
    p: 4,
};

const LayerModal = ({ isOpen, setOpen, layer, handleDelete, handleToggle }) => {



    return (


        <Modal
            open={isOpen}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            BackdropComponent={Backdrop}
            BackdropProps={{
                style: {
                    backgroundColor: 'rgba(0, 0, 0, 0.0)', // Change the opacity here
                },
            }}

        >
            <Box sx={style}>
                <Row>

                    <Col {...fullMobile(24, 24)}>
                        <Typography fontSize={13} variant="body1">Layer Name</Typography>
                        <OutlinedInput
                            size="small"
                            id="name"
                            defaultValue={layer.name}
                            sx={{ width: '100%' }}
                            onBlur={(e) => {
                                console.log(e.target.value);
                                handleToggle(layer.id, { name: e.target.value })
                            }}
                        />

                    </Col>
                   

                    <Col span={12} style={{ textAlign: 'right', paddingTop: 20 }}>
                        <Button
                            variant='outlined'
                            color='error'

                            onClick={() => {

                                handleDelete(layer.id);
                                setOpen(false);
                            }}
                        >
                            Delete
                        </Button>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right', paddingTop: 20 }}>
                        <Button
                            variant='contained'
                            style={{ backgroundColor: '#404050' }}
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            Save
                        </Button>
                    </Col>


                </Row>



            </Box>
        </Modal >
    );

};

export default LayerModal;
