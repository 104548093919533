import React from "react";


import { Box, Button, ButtonGroup, Modal, TextField, Typography } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Col, Row } from 'antd';
import uuid from 'react-uuid';
import '../taskList.css';
import ItemForm from "../../../items/itemForm";
import { fullMobile } from "../../../waterQuality/stations/util";
import { queryItems } from "../../../firebase/config";



const ItemSelect = (
    {
        items,
        currentTask,
        setCurrentTask,
        userInfo,
        setItems,
        openSnack,
        itemType,
        headerKey

    }

) => {
    const [itemModal, setItemModal] = React.useState(false);
    const [newItemType, setNewItemType] = React.useState('product');
    const [newItem, setNewItem] = React.useState({});



    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: (option) => option.label,
    });

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '60vw',
        height: '90vh',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflowX: 'hidden',
        overflowY: 'scroll',
    };


    const getFilteredItems = () => {
        switch (itemType) {
            case 'All':
            case undefined:
            case null:
                return items;
            case 'Product':
                return items.filter(item => item.psType === 'products');
            case 'Service':
                return items.filter(item => item.psType === 'services');
            default:
                return items;
        }
    };

    return (
        <div>

            <Autocomplete
                fullWidth
                size="small"
                style={{ backgroundColor: '#ffffff' }}
                disableCloseOnSelect={false}
                id="combo-box-demo"
                value={currentTask?.[headerKey]}
                disableClearable={true}
                options={getFilteredItems()?.sort((a, b) => -b.psType.localeCompare(a.psType))}

                groupBy={(option) => option.psType || ""}
                renderGroup={(params) => (
                    <Box key={params.key}>
                        <Typography fontWeight={800} fontSize={16} p={1}>
                            {params.group === 'inspection' ? 'Templates' :
                                params.group === 'products' ? 'Products' :
                                    params.group === 'other' ? null :
                                        'Services'
                            }
                        </Typography>
                        {React.Children.map(params.children, (child, index) => {
                            return (
                                <Box style={{
                                    borderBottom: '1px solid #e0e0e0'
                                }}>
                                    {child}
                                </Box>
                            )
                        })}
                    </Box>
                )}
                filterOptions={(options, _ref) => [...filterOptions(options, _ref), { psType: 'other', label: 'Add New', key: 'Add New', value: 'addNew' },]}
                noOptionsText={<Button fullWidth variant='contained' color='primary' style={{ textTransform: 'none' }}
                    onClick={() => {
                        setItemModal(true)
                        setNewItem({ tempKey: uuid(), });
                    }}
                >+ Create Item</Button>}

                renderInput={(params) => {
                    return (
                        <TextField {...params} placeholder="Item" variant="standard"
                            sx={{
                                '& .MuiInputBase-root': {
                                    color: 'black',
                                    fontWeight: 500,
                                    fontSize: 14,
                                },

                            }} />
                    )
                }}
                renderOption={(params) => {

                    if (params.key === 'Add New') {
                        return (
                            <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 4 }}>
                                <Button fullWidth variant='contained' color='primary' style={{ textTransform: 'none', backgroundColor: '#404050' }} onClick={(e) => {
                                    setItemModal(true)
                                    setNewItem({ tempKey: uuid(), });


                                }}>+ Create Item</Button>
                            </Box>

                        )
                    }
                    else {
                        return (
                            <Typography {...params}>{params.key}</Typography>
                        )
                    }
                }}
                onChange={(a, d) => {
                    setCurrentTask({ ...currentTask, [headerKey]: d, })





                }}

            />

            <Modal title="" open={itemModal}



                onClose={() => {
                    setItemModal(false)
                    setNewItem({})

                }} footer={null} width={'60%'}  >
                <Box sx={style}>
                    <Row>
                        <Col {...fullMobile(24, 24)} style={{}}>
                            <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group" >
                                <Button variant={newItemType === 'product' ? 'contained' : 'outlined'} onClick={() => {
                                    setNewItemType('product')
                                }} style={{ textTransform: 'none', padding: 8, fontSize: 14, }} color={newItemType === 'product' ? 'primary' : 'secondary'}>Product</Button>
                                <Button variant={newItemType === 'service' ? 'contained' : 'outlined'} onClick={() => {
                                    setNewItemType('service')
                                }} style={{ textTransform: 'none', padding: 8, fontSize: 14, }} color={newItemType === 'service' ? 'primary' : 'secondary'}>Service</Button>
                                {/*   <Button variant={newItemType === 'inspection' ? 'contained' : 'outlined'} onClick={() => {
                setNewItemType('inspection')
            }} style={{ textTransform: 'none', padding: 8, fontSize: 14, }} color={newItemType === 'inspection' ? 'primary' : 'secondary'}>Inspection</Button> */}
                            </ButtonGroup>
                        </Col>
                        {newItemType === 'product' && (
                            <Col {...fullMobile(24, 24)} style={{ paddingTop: 8 }}>
                                <ItemForm type={newItemType} userInfo={userInfo} newItem={true} openSnack={openSnack} closeModal={async () => {
                                    setItemModal(false)
                                    setNewItem({})
                                    const items = await queryItems(userInfo)
                                    setItems(items)
                                }} />
                            </Col>
                        )}
                        {newItemType === 'service' && (
                            <Col {...fullMobile(24, 24)} style={{ paddingTop: 8 }}>
                                <ItemForm type={newItemType} userInfo={userInfo} newItem={true} openSnack={openSnack} closeModal={async () => {
                                    setItemModal(false)
                                    setNewItem({})
                                    const items = await queryItems(userInfo)
                                    setItems(items)
                                }} />
                            </Col>
                        )}
                        {newItemType === 'inspection' && (
                            <Col {...fullMobile(24, 24)} style={{ paddingTop: 8 }}>
                                <ItemForm type={newItemType} userInfo={userInfo} newItem={true} openSnack={openSnack} closeModal={async () => {
                                    setItemModal(false)
                                    setNewItem({})
                                    const items = await queryItems(userInfo)
                                    setItems(items)
                                }} />
                            </Col>
                        )}

                    </Row>
                </Box>
            </Modal >
        </div>



    );
};

export default ItemSelect;
