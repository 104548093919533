import { Button, CircularProgress, Divider, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Col, Row } from 'antd';
import { collection, doc, getDocs, getFirestore, query, setDoc, where } from 'firebase/firestore';
import moment from 'moment';
import MUIDataTable from "mui-datatables";
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ConcentrationUnits from '../../extra/concentrationUnits.json';
import Units from '../../extra/productUnits.json';
import { fullMobile } from './util';

export default class ProductsHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            data: [],
            filteredData: [],
            filters: [],
            searchText: '',
            loading: true,
            level: this.props.level,
            accounts: [],
            accountsLoad: true,
            products: [],
            stocks: [],
            unitList: [],
            concentrationsList: [],
            loadingLog: undefined
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
            this.queryData()
        }
    }
    componentDidMount() {

        const { userInfo } = this.state;
        window.scrollTo(0, 0)

        this.queryAccounts()

        const unitList = Object.values(Units).map((u, i) => {
            return ({ label: u, key: u, unitid: Object.keys(Units)[i] })
        })
        const concentrationsList = Object.values(ConcentrationUnits).map((u, i) => {
            return ({ label: u, key: u, unitid: Object.keys(Units)[i] })
        })
        this.setState({ unitList, concentrationsList })

    }

    queryData = async (accounts, products, stocks) => {
        const { userInfo, level, } = this.state;
        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'flItems'), where('psType', '==', 'products'),);
        const data = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {


            const account = doc.data().accountKey ? accounts.filter(b => b.key === doc.data().accountKey)[0]
                : accounts.filter(b => b.key === doc.data().account)[0];

            const product = doc.data().item !== undefined ? products.filter(b => b.key === doc.data().item)[0] :
                doc.data().product !== undefined ? products.filter(b => b.key === doc.data().product.key)[0] : products.filter(b => b.key === doc.data().itemKey)[0];

            const startDate = doc.data().timestamp !== undefined ? doc.data().timestamp : doc.data().time !== undefined ?
                moment(doc.data().time, "YYYY-MM-DD HH:mm").format('X') : doc.data().date;

            const units = doc.data().units !== undefined ? doc.data().units.label : '';

            data.push({
                ...doc.data(),
                accountLabel: account ? account.accountName : 'N/A', productLabel: product ? product.name : 'N/A', startDate, units,
                quantity: Number(doc.data().quantity),
                edit: doc.data().logkey !== undefined ? `logkey=${doc.data().logkey}` :
                    doc.data().applicationKey !== undefined ? `applicationkey=${doc.data().applicationKey}` :
                        `N/A`,
            })




        });
        console.log(data)
        //sort data by startDate
        data.sort((a, b) => {
            return moment(a.startDate).isBefore(b.startDate) ? 1 : -1
        })

        this.setState({ data, loading: false })


    }

    queryAccounts = async () => {
        const { userInfo } = this.state;
        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'accounts'));
        const accounts = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            accounts.push({
                accountName: doc.data().accountName,
                key: doc.data().key,
            })


        });

        this.setState({ accounts, })
        this.queryProducts(accounts)


    }

    queryProducts = async (accounts) => {
        const { userInfo, type } = this.state;
        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'psItems'), where('psType', '==', 'products'), where('archived', '==', false));
        const data = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            data.push({
                archived: doc.data().archived,
                description: doc.data().description || '',
                name: doc.data().name || '',
                label: doc.data().name || '',
                key: doc.data().key || '',
                visible: true
            })


        });

        this.setState({ products: data, })
        this.getStocks(accounts, data);

    }

    getStocks = async (accounts, products) => {
        const { userInfo, type } = this.state;
        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'productStock'), where('archived', '==', false));
        const data = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            data.push({ ...doc.data(), label: doc.data().name })


        });

        data.sort((a, b) => a.name.localeCompare(b.name))
        this.setState({ stocks: data, })

        this.queryData(accounts, products, data)
    }

    render() {

        const { account, type, data, userInfo, accounts, loading } = this.state;




        const XTable = ({ columns, header, data }) => {
            let navigate = useNavigate();
            const getMuiTheme = () =>
                createTheme({
                    components: {
                        MUIDataTable: {
                            styleOverrides: {
                                root: {
                                    padding: '8px',
                                    boxShadow: 'none'
                                }
                            }
                        },
                        MuiTableRow: {
                            styleOverrides: {
                                root: {
                                    zIndex: 100,
                                    cursor: 'pointer',
                                    fontSize: '10px',
                                    '&:nth-child(even)': {
                                        backgroundColor: '#eeeeee52'
                                    },
                                    "&:hover": {
                                        backgroundColor: "#9ec1c933"
                                    }


                                },


                            }
                        },
                        MuiTableCell: {
                            styleOverrides: {
                                root: {
                                    fontSize: '12px',
                                    padding: '2px'
                                }
                            }
                        }

                    }
                });



            const accountColumns = ['Status', "Date", {
                label: 'Edit', name: 'Edit',
                options: {
                    customBodyRender: this.renderValue = (a) => {
                        return (
                            <Button style={{ textTransform: 'none', padding: 8 }} variant="contained" color="primary" key={a}
                                onClick={(e) => {
                                    e.stopPropagation();

                                }}>Edit</Button>
                        )
                    }
                },
            },];
            const companyColumns = [


                {
                    name: 'startDate',
                    label: 'Date',
                    options: {
                        filter: false,
                        customBodyRender: this.renderValue = (a) => {
                            return (
                                moment(a, 'X').format('MM/DD/YYYY')
                            )
                        }
                    }
                },
                {
                    name: 'accountLabel',
                    label: 'Account',
                    options: {
                        filter: true,

                    },
                },
                {
                    name: 'productLabel',
                    label: 'Product',
                    options: {
                        filter: true,

                    },
                },
                {
                    name: 'quantity',
                    label: 'Amount',
                    options: {
                        filter: false,

                    },
                },
                {
                    name: 'units',
                    label: 'Units',
                    options: {
                        filter: false,

                    },
                },

                {
                    label: 'Edit', name: 'edit',
                    options: {

                        filter: false,
                        viewColumns: false,
                        customBodyRender: this.renderValue = (a) => {
                            const itemtype = a.includes('application');

                            //split a after = sign
                            const itemKey = a.split('=')[1];
                            console.log(itemKey);
                            return (
                                <Button style={{ textTransform: 'none', padding: 8, borderRadius: 24, fontSize: 10 }} variant="contained" color="primary" itemKey={a}
                                    onClick={async (e) => {
                                        e.stopPropagation();
                                        const db = getFirestore()
                                        const userRef = doc(db, "users", userInfo.id, 'currentItems', 'currentFieldDoc');
                                        const userRefField = doc(db, "users", userInfo.id, 'currentItems', 'currentFieldLogNew');
                                        await setDoc(itemtype ? userRef : userRefField, {
                                            key: itemKey
                                        }).then(() => {
                                            navigate(itemtype ? "/applicationNew" : "/newFieldLogHome");
                                        })
                                    }}>Edit</Button>
                            )
                        }
                    },
                },
            ]



            return (
                <ThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        title={''}
                        data={this.state.data}
                        columns={companyColumns}

                        options={{
                            textLabels: {
                                body: {
                                    noMatch: <div style={{}}>{loading ? "Loading Data" : "No Records Found"} {loading && (<CircularProgress />)}</div>
                                }
                            },
                            downloadOptions: {
                                filename: 'Product History'
                            },
                            print: false,
                            rowsPerPage: 40,
                            rowsPerPageOptions: [40, 80, 100],
                            selectableRows: false,
                            async onRowClick(a, b) {
                                console.log(a[a.length - 1].props.itemKey)
                                const itemInfo = a[a.length - 1].props.itemKey;
                                const itemtype = itemInfo.includes('application');
                                const itemKey = itemInfo.split('=')[1];
                                console.log(itemKey)
                                const db = getFirestore()
                                const userRef = doc(db, "users", userInfo.id, 'currentItems', 'currentFieldDoc');
                                const userRefField = doc(db, "users", userInfo.id, 'currentItems', 'currentFieldLogNew');
                                await setDoc(itemtype ? userRef : userRefField, {
                                    key: itemKey
                                }).then(() => {

                                    navigate(itemtype ? "/applicationNew" : "/newFieldLogHome");
                                }
                                )



                            }
                        }}

                    />
                </ThemeProvider>
            );
        }



        return (
            <Row style={{}}>
                <Col span={23}>
                    <Row align="middle">
                        <Col {...fullMobile(24, 24)} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>
                            <Typography variant="h4">Product History</Typography>
                        </Col>


                        <Col span={24}>
                            <Divider />
                        </Col>
                        <Col span={23} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 30 }}>
                            <XTable />

                        </Col>
                    </Row>
                </Col>

            </Row>
        );
    }
}