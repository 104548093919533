import { Button, Checkbox, Divider, OutlinedInput, Typography, Select, MenuItem, CircularProgress, Breadcrumbs, Paper, Input, TextField, IconButton, Chip } from '@mui/material';
import { Col, Modal, Row } from 'antd';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { fullMobile } from './util';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, orderBy, limit } from 'firebase/firestore';

import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import uuid from 'react-uuid';
import EditIcon from '@mui/icons-material/Edit';

import DatePicker from "react-datepicker";

import queryString from 'query-string';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { getUser, getStaff, getAccounts, queryPSItems, queryAllItemsStock, queryStocks } from '../../firebase/config';
import { ArrowUpwardOutlined } from '@mui/icons-material';


var randomColor = require('randomcolor');




const getMuiTheme = () =>
    createTheme({
        components: {
            MUIDataTable: {
                styleOverrides: {
                    root: {
                        padding: '8px',
                        boxShadow: 'none'
                    }
                }
            },
            MuiTableRow: {
                styleOverrides: {
                    height: '40px',
                    //onhover change background to red


                    root: {
                        height: '40px',
                        zIndex: 100,
                        cursor: 'pointer',
                        fontSize: '10px',
                        '&:nth-child(even)': {
                            backgroundColor: '#eaeaeade',

                        },




                    },
                    rowHeight: '40px'



                }
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        fontSize: '12px',
                        padding: '2px',

                    }
                }
            }

        }
    });

export default function StockHome({ userInfo, screenWidth, openSnack, company, account, reports, updateAccount, updateUserInfo, refresh, updateRefresh }) {
    const [status, setStatus] = useState('creating');
    const [data, setData] = useState([]);
    const [stock, setStock] = useState({})
    const [staff, setStaff] = useState([])
    const [items, setItems] = useState([]);
    const [stocks, setStocks] = useState([]);
    const [accounts, setAccounts] = useState([])
    const [loading, setLoading] = useState(true)
    const [supplyModal, setSupplyModal] = useState(false)
    const [supply, setSupply] = useState({})
    const props = { userInfo, screenWidth, openSnack, company, account, };


    useEffect(() => {

        window.scrollTo(0, 0)
        getUserInfo()


    }, []);

    useEffect(() => {
        if (refresh === true) {
            getUserInfo()
            updateRefresh()
        }

    }, [refresh])




    async function getUserInfo() {
        queryDataDoc()
        const staff = await getStaff(userInfo.currentAccount);
        setStaff(staff)







    }

    async function queryDataDoc() {
        const db = getFirestore();
        const docRef = doc(db, "users", userInfo.id, 'currentItems', 'currentStock');
        const docSnap = await getDoc(docRef);

        console.log(docSnap.data())
        if (docSnap.data() === undefined || docSnap.data().key === 'none') {

            setLoading(false)

        }

        else {
            getDocItem(docSnap.data().key)

        }
    }

    async function getDocItem(key) {
        const db = getFirestore();
        console.log(key)
        const docRef = doc(db, "clients", userInfo.currentCompany, 'productStock', key);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const item = docSnap.data();
            console.log(item)
            setStock(item)
          

            const stocks = await queryStocks(userInfo);
            setStocks(stocks)

            const accounts = await getAccounts(userInfo.id, userInfo.currentCompany, userInfo.accounts);
            setAccounts(accounts)

            const items = await queryPSItems(userInfo);
            console.log(items)
            setItems(items);



            const data = await queryAllItemsStock(userInfo, item.key);

            console.log(data)

            const newData = data.map(item => {
                //find account name
                const account = accounts.find(account => account.key === item.account) ?
                    accounts.find(account => account.key === item.account) :
                    accounts.find(account => account.key === item.accountKey) || { accountName: '' }


                const row = items.find(row => row.key === item.item) ?
                    items.find(row => row?.key === item.item) :
                    items.find(row => row?.key === item.item?.key) ?
                        items.find(row => row?.key === item.item?.key) :
                        items.find(row => row?.key === item.product) || { name: '' }

                const supplyStockKey = item.supplyStock !== undefined ? item.supplyStock.key : '';
                const supplyStock = stocks.find(stock => stock.key === supplyStockKey) || { name: '' };

                const time = item.time ? moment(item.time, 'YYYY-MM-DD HH:mm a').format('MM-DD-YYYY h:mm a') :
                    item.startDate ? moment(item.startDate, 'x').format('MM-DD-YYYY h:mm a') :
                        moment(item.timestamp, 'X').format('MM-DD-YYYY h:mm a')

                  

                const xTime = Number(moment(time, 'MM-DD-YYYY h:mm a').format('x'))
                return {
                    ...item,
                    accountLabel: account ? account.accountName : '',
                    itemLabel: row ? row.name : '',
                    supplyStockLabel: supplyStock ? supplyStock.name : '',
                    type: item.product !== undefined ?
                        'Chemical' : item.psType === 'services' ? 'Service' :
                            item.psType === 'products' ? 'Product' :
                                item.psType === 'supply' ? 'Supply' : '',
                    time:
                        xTime
                }
            })

            //sort by time
            newData.sort((a, b) => {
                return Number(moment(b.time, 'x')) - Number(moment(a.time, 'x'))
            }
            )
            console.log(newData)
            setData(newData);
            setLoading(false)



        }
        else {
            console.log('history not found')
        }


    }




    async function updateStock() {
        const db = getFirestore();
        const docRef = doc(db, "clients", userInfo.currentCompany, 'productStock', stock.key);
        await updateDoc(docRef, stock);
        openSnack('success', 'Stock Updated',)
    }


    const db = getFirestore();
    let navigate = useNavigate();

    const columns = [
        {
            name: 'time',
            label: 'Date',
            options: {
                filter: false,

                customBodyRender: (a, tableMeta, updateValue) => {

                    return (
                        <Typography variant="body1" fontWeight={500} fontSize={14}>
                            {moment(a, 'x').format('MM-DD-YYYY h:mm a')}
                        </Typography>
                    )
                }


            }
        },
        {
            name: 'itemLabel',
            label: 'Name',
            options: {
                filterType: 'multiselect',
                filter: true,

                customBodyRender: (a, tableMeta, updateValue) => {


                    return (
                        <Typography variant="body1" fontWeight={400} fontSize={14}>
                            {a}
                        </Typography>
                    )
                }


            }
        },
        {
            name: 'accountLabel',
            label: 'Site',
            options: {
                filterType: 'multiselect',
                filter: true,

                customBodyRender: (a, tableMeta, updateValue) => {

                    return (
                        <Typography variant="body1" fontWeight={400} fontSize={14}>
                            {a}
                        </Typography>
                    )
                }


            }
        },
        {
            name: 'supplyStockLabel',
            label: 'Stock',
            options: {
                filter: false,

                customBodyRender: (a, tableMeta, updateValue) => {

                    return (
                        <Typography variant="body1" fontWeight={400} fontSize={14}>
                            {a}
                        </Typography>
                    )
                }


            }
        },
        {
            name: 'key',
            label: 'Amount',
            options: {
                filter: false,
                customBodyRender: (a, tableMeta, updateValue) => {

                    const item = data.find(item => item.key === a);
                    //check if item has amount
                    const amount = item.quantity ? item.quantity : '';
                    //check if item has units
                    const units = item.units ? item.units.label : '';



                    return (
                        <Typography variant="body1" fontWeight={400} fontSize={14}>
                            {amount} {units}
                        </Typography>
                    )
                }


            }
        },
        {
            name: 'type',
            label: 'Type',
            options: {
                filterType: 'multiselect',
                filter: true,
                customBodyRender: (type, tableMeta, updateValue) => {



                    if (type === '')
                        return null;
                    else

                        return (
                            <Chip label={type}
                                display="inline"
                                color={type === 'Service' ? 'primary' :
                                    type === 'Chemical' ? 'error' :
                                        type === 'Supply' ? 'warning' :

                                            'success'

                                } variant="outlined"
                                style={{ cursor: 'pointer', padding: 5, marginLeft: 10 }} fontWeight={400} fontSize={18}
                                onClick={async () => {

                                }
                                }
                            />
                        )
                }


            }
        },



        {
            name: 'key',
            label: 'Edit',
            options: {
                filter: false,
                viewColumns: false,
                customBodyRender: (a, tableMeta, updateValue) => {
                    const type = data.find(item => item.key === a).psType;

                    if (type !== 'supply') {
                        return (
                            <Button itemKey={a}
                                size="small"
                                variant='contained'
                                color='primary'
                                style={{
                                    textTransform: 'none',
                                    backgroundColor: '#404050',

                                }}
                                onClick={async () => {
                                    const itemRef = doc(db, "users", userInfo.id, 'currentItems', 'currentPS');
                                    await setDoc(itemRef, {
                                        key: a
                                    }).then(() => {
                                        const type = data.find(item => item.key === a).psType;
                                        navigate('/psItem')
                                    })
                                }}>
                                Edit
                            </Button>


                        )
                    }
                    else {
                        return <div itemKey={a}></div>
                    }

                }
            },
        },

    ]


    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </div>
        )
    }
    else {



        return (
            <Row style={{
                fontFamily: 'Roboto, sans-serif',
                backgroundColor: '#f6f8fe',//align content to top

            }} >


                <Col {...fullMobile(24, 24)} style={{ paddingTop: 10 }}>
                    <ThemeProvider theme={getMuiTheme()}>
                        <MUIDataTable
                            title={''}
                            data={data}
                            columns={columns}


                            options={{


                                rowsPerPage: 50,
                                selectableRows: false,
                                rowsPerPageOptions: [50, 100, 200, 500, 1000],
                                async onRowClick(a, b) {


                                    const db = getFirestore();
                                    const item = data.find(item => item.key === a[a.length - 1].props.itemKey);
                                    console.log(item)
                                    if (item.psType !== 'supply') {



                                        if (item.item !== undefined) {
                                            const userRef = doc(db, "users", userInfo.id, 'currentItems', 'currentFieldLogNew');
                                            const accountRef = doc(db, "users", userInfo.id,);
                                            await setDoc(userRef, {
                                                key: item.logkey || item.logKey
                                            }).then(() => {
                                                if (userInfo.currentAccount !== item.account || item.accountKey) {
                                                    updateAccount(userInfo.currentCompany, item.accountKey || item.account)
                                                    updateUserInfo('currentAccount', item.accountKey || item.account)
                                                    const accountRef = doc(db, "users", userInfo.id,);
                                                    updateDoc(accountRef, {
                                                        currentAccount: item.accountKey || item.account
                                                    })

                                                }
                                            })
                                                .then(() => {
                                                    navigate("/newFieldLogHome-reports");
                                                })

                                        }
                                        if (item.product !== undefined) {
                                            const userRef = doc(db, "users", userInfo.id, 'currentItems', 'currentFieldDoc');
                                            await setDoc(userRef, {
                                                key: item.logkey !== undefined ? item.logkey : item.logKey !== undefined ? item.logKey :
                                                    item.applicationKey !== undefined ?
                                                        item.applicationKey : item.applicationkey !== undefined ? item.applicationkey : 'none'
                                            }).then(() => {
                                                if (userInfo.currentAccount !== item.account || item.accountKey) {
                                                    updateAccount(userInfo.currentCompany, item.accountKey || item.account)
                                                    updateUserInfo('currentAccount', item.accountKey || item.account)
                                                    const accountRef = doc(db, "users", userInfo.id,);
                                                    updateDoc(accountRef, {
                                                        currentAccount: item.accountKey || item.account
                                                    })


                                                }
                                            }).then(() => {
                                                navigate("/newApplicationHome-reports");
                                            })



                                        }
                                    }
                                    else {
                                        setSupplyModal(true)
                                        setSupply({ ...item, time: moment(item.time, 'MM-DD-YYYY HH:mm a').format('YYYY-MM-DD HH:mm') })
                                        console.log(item.time)
                                    }




                                }
                            }}

                        />
                    </ThemeProvider>
                </Col>




                <Col {...fullMobile(24, 24)} style={{ paddingTop: 10, textAlign: 'right' }}>

                </Col>

                <Modal title="" visible={supplyModal} onCancel={() => {
                    setSupplyModal(false)
                    setSupply({})
                }} footer={null} width={screenWidth === 'xs' ? '80%' : '50%'} style={{ borderRadius: 40 }} >
                    <Row gutter={[4, 4]} align="middle" >
                        <Col style={{}}>
                            <Typography variant='h5' display={"inline"}>
                                Update Supply
                            </Typography>

                        </Col>



                        <Col {...fullMobile(24, 24)}  >
                            <Row >
                                <Col {...fullMobile(24, 24)} style={{ paddingTop: 15 }}  >
                                    <Typography variant="body2" display="inline" fontWeight={600} fontSize={14} style={{ color: '#404050' }}>
                                        Product Name<span style={{ color: 'red' }}>*</span>
                                    </Typography>
                                </Col>
                                <Col {...fullMobile(24, 24)}  >
                                    <Autocomplete
                                        size="small"
                                        disableCloseOnSelect={false}
                                        id="combo-box-demo"
                                        value={supply.item || null}
                                        disableClearable={true}
                                        options={items}
                                        renderInput={(params) => {
                                            return (
                                                <TextField {...params}
                                                    placeholder="Item"
                                                    variant="outlined" />
                                            )
                                        }}

                                        onChange={(a, d) => {
                                            setSupply({ ...supply, item: d })

                                        }}

                                    />
                                </Col>
                                <Col {...fullMobile(24, 24)} style={{ paddingTop: 15 }}  >
                                    <Typography variant="body2" display="inline" fontWeight={600} fontSize={14} style={{ color: '#404050' }}>
                                        Date<span style={{ color: 'red' }}>*</span>
                                    </Typography>
                                </Col>
                                <Col {...fullMobile(24, 24)}  >
                                    {supply?.time && (
                                        <DatePicker timeCaption="Time" showTimeSelect timeFormat="h:mm a" timeIntervals={1}
                                            dateFormat="MM/dd/yyyy hh:mm a" portalId="root-portal"
                                            selected={supply?.time === 'Invalid date' ? new Date() : new Date(moment(supply?.time, 'YYYY-MM-DD HH:mm'))} onChange={(date) => {
                                                setSupply({ ...supply, time: moment(date).format('YYYY-MM-DD HH:mm') })



                                            }} />
                                    )}
                                </Col>
                                <Col {...fullMobile(24, 24)} style={{ paddingTop: 15 }} >
                                    <Typography variant="body2" display="inline" fontWeight={600} fontSize={14} style={{ color: '#404050' }}>
                                        Amount<span style={{ color: 'red' }}>*</span>
                                    </Typography>
                                </Col>
                                <Col {...fullMobile(24, 24)}  >
                                    <OutlinedInput
                                        size="small"
                                        style={{ width: 300 }}
                                        type="number"
                                        id="outlined-adornment-amount"
                                        value={supply?.quantity}
                                        onChange={(e) => {
                                            setSupply({ ...supply, quantity: e.target.value === "" ? "" : Number(e.target.value) })
                                        }}

                                    />
                                </Col>
                                <Col {...fullMobile(24, 24)} style={{ paddingTop: 15 }} >
                                    <Typography variant="body2" display="inline" fontWeight={600} fontSize={14} style={{ color: '#404050' }}>
                                        Units<span style={{ color: 'red' }}>*</span>
                                    </Typography>
                                </Col>
                                <Col {...fullMobile(24, 24)}  >
                                    <Autocomplete
                                        size="small"
                                        id="combo-box-demo"
                                        value={supply?.units}
                                        options={["mg",
                                            "g",
                                            "kg",
                                            "oz",
                                            "lb",
                                            "fl-oz",
                                            "cup",
                                            "pnt",
                                            "qt",
                                            "gal",
                                            "ft3",
                                        ].sort((a, b) => a.localeCompare(b)).map((g) => { return ({ key: g, label: g }) })}
                                        renderInput={(params) => <TextField {...params} placeholder="Units" />}
                                        onChange={(a, d) => {
                                            setSupply({ ...supply, units: d });

                                        }}

                                    />
                                </Col>

                                <Col {...fullMobile(12, 12)} style={{ paddingTop: 25 }}  >
                                    {
                                        supply?.item !== null &&
                                        supply.date !== null &&
                                        supply.quantity !== 0 &&
                                        supply.units !== null &&

                                        (
                                            <Button variant='contained'
                                                color='primary'
                                                style={{ textTransform: 'none', backgroundColor: '#404050', }}
                                                onClick={async () => {
                                                    const newSupply = { ...supply }
                                                    const docRef = doc(db, "clients", userInfo.currentCompany, 'flItems', newSupply.key);
                                                    await updateDoc(docRef, newSupply).then(() => {
                                                        setSupplyModal(false)
                                                        setSupply({})
                                                        openSnack('success', 'Supply Added')
                                                        queryDataDoc()

                                                    }).catch((error) => {
                                                        console.error("Error updating document: ", error);
                                                    });

                                                }}
                                            >
                                                Update Supply
                                            </Button>


                                        )

                                    }
                                </Col>
                                <Col {...fullMobile(12, 12)} style={{ paddingTop: 25 }}  >
                                    {
                                        supply?.item !== null &&
                                        supply.date !== null &&
                                        supply.quantity !== 0 &&
                                        supply.units !== null &&

                                        (
                                            <Button variant='outlined'
                                                color='secondary'
                                                style={{ textTransform: 'none', }}
                                                onClick={async () => {
                                                    const newSupply = { ...supply }
                                                    const docRef = doc(db, "clients", userInfo.currentCompany, 'flItems', newSupply.key);
                                                    await deleteDoc(docRef, newSupply).then(() => {
                                                        setSupplyModal(false)
                                                        setSupply({})
                                                        openSnack('success', 'Supply Deleted')
                                                        queryDataDoc()

                                                    }).catch((error) => {
                                                        console.error("Error updating document: ", error);
                                                    });

                                                }}
                                            >
                                                Delete Supply
                                            </Button>


                                        )

                                    }
                                </Col>

                            </Row>
                        </Col>


















                    </Row>
                </Modal>






            </Row>
        );
    }
}