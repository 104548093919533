import React, { useEffect, useState, useRef } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import { format, startOfWeek, getDay, add } from 'date-fns';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './CustomCalendarStyles.css';
import moment from 'moment-timezone';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import CustomToolbar from './customToolbar'; // Adjust the path accordingly
import CalendarComponent from '../components/calendarComponents/googleCalendar';
var randomColor = require('randomcolor');

moment.updateLocale('en-gb', {
    weekdaysShort: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
    dayFormat: (date, culture, localizer) =>
        localizer.format(date, 'D', culture)
});

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);







const TaskCalendar = ({
    tasks,
    setTasks,
    currentTask,
    setCurrentTask,
    setTaskDrawer,
    colorCalendar,
    updateTask
}) => {
    const [events, setEvents] = useState([]);
    const getRandomColor = () => randomColor();
    const prevColorCalendarRef = useRef();
    const [staffColors, setStaffColors] = useState({});
    const [siteColors, setSiteColors] = useState({});

    useEffect(() => {
        // Store the current colorCalendar value for future comparisons
        prevColorCalendarRef.current = colorCalendar;
    }, [colorCalendar]);



    const transformTasksToEvents = (tasks, siteColors, staffColors) => {
        return tasks.map(task => {
            let startDate = new Date(Number(task.startDate));
            let endDate = task.endDate ? new Date(Number(task.endDate)) : new Date(Number(task.startDate));
            if (!task.endDate) {
                endDate.setHours(endDate.getHours() + 1);
            }

            let taskColor;
            if (colorCalendar === "colorByStaff" && task.staff) {
                taskColor = staffColors[task.staff.id];
            } else if (colorCalendar === "colorByAccount" && task.site) {
                taskColor = siteColors[task.site.key];
            } else {
                taskColor = task.color; // default color from the task
            }

            return {
                title: task.title,
                start: startDate,
                end: endDate,
                color: taskColor,
                id: task.id,
            };
        });
    };


    useEffect(() => {
        console.log("CALENDAR LOADED")
        const staffColors = {};
        tasks.forEach(task => {
            if (task.staff && !staffColors[task.staff.id]) {
                staffColors[task.staff.id] = getRandomColor();
            }
        });
        setStaffColors(staffColors);

        // Generate a random color for each site
        const siteColors = {};
        tasks.forEach(task => {
            if (task.site && !siteColors[task.site.key]) {
                siteColors[task.site.key] = getRandomColor();
            }
        });
        setSiteColors(siteColors);
        console.log(siteColors)
        setEvents(transformTasksToEvents(tasks, siteColors, staffColors));

    }, []);

    useEffect(() => {
        // Update the events state whenever the tasks state changes

        const staffColors = {};
        tasks.forEach(task => {
            if (task.staff && !staffColors[task.staff.id]) {
                staffColors[task.staff.id] = getRandomColor();
            }
        });
        setStaffColors(staffColors);

        // Generate a random color for each site
        const siteColors = {};
        tasks.forEach(task => {
            if (task.site && !siteColors[task.site.key]) {
                siteColors[task.site.key] = getRandomColor();
            }
        });
        setSiteColors(siteColors);
        console.log(siteColors)
        setEvents(transformTasksToEvents(tasks, siteColors, staffColors));
    }, [colorCalendar]);





    const formats = {
        dateFormat: (date, culture, localizer) =>
            localizer.format(date, 'D', culture),
        dayFormat: (date, culture, localizer) =>
            localizer.format(date, 'D ddd', culture),
        dayRangeHeaderFormat: ({ start, end }, culture, localizer) =>
            localizer.format(start, 'MMMM D', culture) +
            ' — ' +
            localizer.format(end, 'D', culture),
        timeGutterFormat: (date, culture, localizer) =>
            localizer.format(date, 'h A', culture),

    };

    const eventStyleGetter = (event, start, end, isSelected) => {
        const backgroundColor = event.color;
        const style = {
            backgroundColor: backgroundColor,
            borderRadius: '12px',
            color: 'white',
            border: '0px',
            display: 'block'
        };
        return {
            style: style
        };
    };

    const onEventDrop = ({ event, start, end }) => {
        // Update the tasks state
        const task = tasks.find(task => task.id === event.id);
        const updatedTask = {
            ...task,
            startDate: moment(start).format('x'),
            endDate: moment(end).format('x'),
        };
        updateTask(updatedTask);

        // Reflect these changes in the events state
        setEvents(transformTasksToEvents(tasks));
    };

    const onEventResize = ({ event, start, end }) => {
        // Update the tasks statec
        const task = tasks.find(task => task.id === event.id);

        // Check if start and end are on the same day
        const differentEndDate = moment(start).format('YYYY-MM-DD') !== moment(end).format('YYYY-MM-DD');

        const updatedTask = {
            ...task,
            startDate: moment(start).format('x'),
            endDate: moment(end).format('x'),
            differentEndDate
        };

        updateTask(updatedTask);

        // Reflect these changes in the events state
        setEvents(transformTasksToEvents(tasks));
    };

    const handleSelectEvent = (event) => {

        // Find the task that matches the selected event
        const selectedTask = tasks.find(task => task.id === event.id);

        if (selectedTask) {
            setCurrentTask(selectedTask); // Update the current task
            setTaskDrawer(true);         // Open the task drawer
        }
    }

    return (
        <div style={{ height: '100vh' }}>
            <CalendarComponent />
            <DndProvider backend={HTML5Backend}>
                <DnDCalendar
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    onEventDrop={onEventDrop}
                    draggableAccessor={event => true} // This function determines which events are draggable. The example makes all events draggable.
                    resizable
                    formats={formats}
                    eventPropGetter={eventStyleGetter}
                    onEventResize={onEventResize}
                    onSelectEvent={handleSelectEvent}


                />
            </DndProvider>
        </div>
    );
};

export default TaskCalendar;
