import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextField } from '@mui/material';

const RenameFile = ({
  renameModalOpen,
  setRenameModalOpen,
  renameValue,
  setRenameValue,
  handleRename,
}) => {
  return (
    <Dialog open={renameModalOpen} onClose={() => setRenameModalOpen(false)}>
      <DialogTitle>Rename</DialogTitle>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleRename();
        }}
      >
        <DialogContent>
          <DialogContentText>
            Please enter a new name for the item:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="New Name"
            type="text"
            fullWidth
            defaultValue={renameValue}
            onChange={(e) => setRenameValue(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRenameModalOpen(false)}>Cancel</Button>
          <Button type="submit">Rename</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default RenameFile;
