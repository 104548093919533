import { Row } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { fullMobile } from '../../util';
import HighchartsReact from "highcharts-react-official";
import highchartsMore from "highcharts/highcharts-more.js";
import Highcharts from "highcharts/highcharts.js";
import solidGauge from "highcharts/modules/solid-gauge.js";
import moment from 'moment';
import { CircularProgress, Typography } from '@mui/material';
import axios, * as others from 'axios';
var convert = require('convert-units')
highchartsMore(Highcharts);
solidGauge(Highcharts);



require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/data')(Highcharts);

export default function WidgetGauge(props) {
    const { userInfo, screenWidth, openSnack, widgetRef, layoutRef } = props;
    const [layout, setLayout] = useState(layoutRef);
    const [widget, setWidget] = useState(widgetRef);
    const chartRef = useRef(null);
    const chartDiv = useRef(null);
    const [readingTime, setReadingTime] = useState(null);
    const [loading, setLoading] = useState(true);
    const [chartOptions, setChartOptions] = useState({
        credits: {
            enabled: false
        },
        chart: {
            type: 'solidgauge',
            backgroundColor: 'transparent'
        },

        title: null,

        pane: {
            center: ['50%', '50%'],
            size: '80%',
            startAngle: -140,
            endAngle: 140,
            rounded: true,
            background: {
                backgroundColor: 'white',

                borderWidth: 0,
                outerRadius: '100%',
                innerRadius: '70%',
                shape: 'arc',

            }
        },

        exporting: {
            enabled: false
        },
        stockTools: {
            gui: {
                enabled: false
            }
        },

        tooltip: {
            enabled: false
        },

        // the value axis
        yAxis: {

            stops:
                [
                    [0.5, {
                        linearGradient: {
                            x1: 0,
                            x2: 1,
                            y1: 0,
                            y2: 0
                        },
                        stops: [
                            [0, 'rgba(254, 73, 44, 0.9)'],
                            [1, 'rgba(255, 208, 0, 0.9)']
                        ]
                    }],
                    [0.9, {
                        linearGradient: {
                            x1: 0,
                            x2: 1,
                            y1: 0,
                            y2: 0
                        },
                        stops: [
                            [0, 'rgba(254, 73, 44, 0.9)'],
                            [0.5, 'rgba(255, 208, 0, 0.9)'],
                            [1, 'rgba(97, 201, 164, 0.9)']
                        ]
                    }]
                ],
            min: 0,
            max: 100,



            tickLength: 15,
            tickColor: 'black',
            labels: {
                distance: 15

            },
        },
        plotOptions: {
            solidgauge: {
                dataLabels: {
                    y: 5,
                    borderWidth: 0,
                    useHTML: true
                },

                stickyTracking: false,

            }
        },

        legend: {
            enabled: false
        },


        series: [{
            name: 'Speed',
            data: [0],
            dataLabels: {
                format: '<div />'
            },


        }]
    });





    useEffect(() => {
        setWidget(widgetRef)
    }, [widgetRef])
    useEffect(() => {
        setLayout(layoutRef)
    }, [layoutRef])




    //create chart
    useEffect(() => {
        if (widget) {
            let chart = Highcharts.chart(widget.single.key, chartOptions);
            //create reference to chart
            chartRef.current = chart;
            //get data for chart
            queryData(widget)

        }
    }, [widget])

    useEffect(() => {


    }, [])

    //if height or width of chart div changes update chart
    useEffect(() => {
        if (chartRef.current) {
            chartRef.current.reflow();
        }
    }, [chartDiv.current?.clientHeight, chartDiv.current?.clientWidth])

    async function queryData(widget) {
        const proxyUrl = "https://mycorslake.herokuapp.com/";
        const item = widget.single;

        axios.post(proxyUrl + `https://us-central1-aquasource3.cloudfunctions.net/widgetsFire2/sqlRead`, {
            raw: `SELECT * FROM ${item?.dataType === 'probe' ? 'probe_data' : 'node_data_new2'}
            WHERE ${item?.dataType === 'probe' ? 'locationid' : 'nodeid'} = '${item?.nodeid}'and parameterid = '${item?.parameterid}'
            ORDER BY "time" DESC
            LIMIT 1;`
        }).then(async (response) => {
            let data = response.data[0];
            const offset = item?.offset || 0;
            const value = Number((data.value.toFixed(0))) + offset;
            const time = data.timestamp;
            setReadingTime(time);
            setLoading(false);

            chartOptions.series[0].data[0] = value;
            //update chart
            chartRef.current.update(chartOptions);
        }).catch((error) => {
            console.log(error)
            setLoading('No Data Found')
        })
    }


    //if loading is a string show error message

    return (

        //create div that adjust with size of parent div
        <div ref={chartDiv} style={{ width: '100%', height: '100%', position: 'relative',  }}>

            <div id={`${widget.single.key}`} style={{ width: '100%', height: '90%', top: '8%' ,position: 'relative' }}>

            </div>
            <div style={{

              
                position: 'absolute',
                top: '43%',
                textAlign: 'center',
                width: '100%',

            }}>

                {loading === false && (
                    <Typography variant={
                        layout?.h < 7 ? 'h6' :
                            layout?.w < 4 ? 'h6' : 'h4'
                    } fontWeight={500} style={{ color: 'rgba(0,0,0,0.7)', }}>
                        {chartOptions.series[0].data[0]}%
                    </Typography>
                )
                }


            </div>
            <div style={{

             
                position: 'absolute',
                top: '0%',
                textAlign: 'center',
                width: '100%',

            }}>

                {loading === false && (
                    <Typography variant={'h5'} fontWeight={500} fontSize={15} style={{ color: 'rgba(0,0,0,0.7)', }}>
                        {moment(readingTime, 'X').format('LLL')}
                    </Typography>)}

                {loading === true ? <CircularProgress /> : null
                }
                {typeof loading === 'string' && (
                    <Typography variant={'body1'} fontWeight={500} fontSize={14} style={{ color: 'rgba(0,0,0,0.7)', }}>
                        {loading}
                    </Typography>
                )}


            </div>
        </div>

    );




}