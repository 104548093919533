import React, { useState } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import { styled } from '@mui/material/styles';
import TabBox from './tabs/tabBox';

const StyledTab = styled(Tab)({
    textTransform: 'none',
    color: 'gray', // Default color of the tabs
    borderBottom: 'none', // Remove bottom border to merge with the indicator
    marginRight: '4px', // Spacing between tabs
    minHeight: '36px', // Adjust the height of the tabs
    '&.Mui-selected': { // Styles for the selected tab
        color: 'white', // Color of the selected tab
        fontWeight: 'bold', // Make font weight bold for selected
        /* white bottom border */
    },
    // Remove minWidth or set it to a value that ensures all tabs fit in the container
    // minWidth: '100px', 
});
const ToolTabs = ({ setCurrentTab, currentTab, ...otherProps }) => {

    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    return (
        <Box sx={{
            borderBottom: 1, borderColor: 'divider', 
            // overflow: 'hidden', // Uncomment this line to hide overflow
        }}>
            <Tabs
                value={currentTab}
                onChange={handleTabChange}
                aria-label="tool tabs"
                variant="scrollable"
                scrollButtons="auto"
                TabIndicatorProps={{ style: { background: 'white' } }}
            >
                <StyledTab value={"Data editor"} label="Data editor" />
            </Tabs>
            {/* current tab box */}
            <TabBox currentTab={currentTab} setCurrentTab={setCurrentTab} {...otherProps} />
        </Box>
    );
};

export default ToolTabs;