
import React from "react";


import { Button } from '@mui/material';
import { useNavigate } from "react-router-dom";


const ReturnToDataStreams = () => {

    let navigate = useNavigate();
    return (
        <Button size="small"
            variant="link"
            style={{
                textTransform: 'none'
            }}
            onClick={async () => {
                navigate('/dataStreams')
            }}>Return to Data Streams --></Button>
    )
}

export default ReturnToDataStreams;