import { collection, getDocs, getDoc, addDoc, updateDoc, doc, getFirestore, deleteDoc, writeBatch, setDoc, where, query } from 'firebase/firestore';
import { getStorage, ref, getMetadata, uploadBytes, getDownloadURL } from "firebase/storage";
import uuid from 'react-uuid';



export const getFolderStructure = async (company) => {
  const db = getFirestore();
  try {
    const folderSnapshot = await getDocs(collection(db, 'clients', company, 'folders'));
    const folders = folderSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
      type: doc.data().type || 'folder',
      selected: false,
    }));
    return folders;
  } catch (error) {
    console.error('Error fetching folder structure:', error);
    return [];
  }
};


export const getItems = async (company, path, databasePath, account) => {
  const db = getFirestore();
  try {
    const folderRef = collection(db, "clients", company, databasePath);
    const folderQuery = query(folderRef, where("parent", "==", path), where("account", "==", account));
    const folderSnapshot = await getDocs(folderQuery);
    const folders = folderSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
      type: doc.data().type || 'folder',
      selected: false,
    }));
    return folders;
  } catch (error) {
    return [];
  }
};




















export const addFolder = async (name, parent, company, account, user, id) => {
  const db = getFirestore();
  try {
    const folderData = {
      name,
      id,
      parent: parent || 'root',
      account,
      company,
      type: 'folder',
      createdBy: user,
    };
    const folderRef = doc(db, 'clients', company, 'folders', folderData.id);
    await setDoc(folderRef, folderData);


  } catch (error) {
    console.error('Error adding folder:', error);
  }
};

export const deleteFolders = async (folders, company) => {
  const db = getFirestore();
  try {
    const batch = writeBatch(db);
    folders.forEach((folder) => {
      const folderRef = doc(db, 'clients', company, 'folders', folder.id);
      batch.delete(folderRef);
    });
    await batch.commit();
    return 'success'
  } catch (error) {
    console.error('Error deleting folders:', error);
  }
};

export const deleteFiles = async (folders, company) => {
  const db = getFirestore();
  try {
    const batch = writeBatch(db);
    folders.forEach((folder) => {
      const folderRef = doc(db, 'clients', company, 'files', folder.id);
      batch.delete(folderRef);
    });
    await batch.commit();
    return 'success'
  } catch (error) {
    console.error('Error deleting folders:', error);
  }
};




export const addFile = async (name, folderId, downloadUrl, company, account, user, size, id) => {
  const db = getFirestore();
  

  try {
    const folderData = {
      id,
      name,
      downloadUrl,
      account,
      company,
      type: 'file',
      createdBy: user,
      size,
      parent: folderId,
    };
    const folderRef = doc(db, 'clients', company, 'files', folderData.id);
    await setDoc(folderRef, folderData);
    return 'success'
  }
  catch (error) {

    console.error('Error adding file:', error);
  }
};
export const addOldDocuments = async (company, account, user) => {
  const db = getFirestore();
  const storage = getStorage();

  try {
    const documentsSnapshot = await getDocs(collection(db, 'clients', company, 'documents'));
    const documents = documentsSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
      type: doc.data().type || 'folder',
      selected: false,
    }));

    for (const document of documents) {
      const fileRef = ref(storage, document.files[0].fileLink);
      const metadata = await getMetadata(fileRef);
      const size = metadata.size;

      const folderData = {
        id: document.id,
        name: document.name,
        downloadUrl: document.files[0].fileLink,
        account,
        company,
        type: 'file',
        createdBy: user,
        size,
        parent: 'root',
      };

      const folderRef = doc(db, 'clients', company, 'files', document.id);
      await setDoc(folderRef, folderData);
    }
  } catch (error) {
    console.error('Error adding file:', error);
  }
};

export const renameItem = async (name, type, folderId, fileId, company) => {
  const db = getFirestore();

  try {
    if (type === 'folder' || type === 'nullFile') {
      const folderRef = doc(db, 'clients', company, 'folders', fileId);
      await updateDoc(folderRef, { name });
    }
    else {
      const fileRef = doc(db, 'clients', company, 'files', fileId);
      await updateDoc(fileRef, { name });

    }
  } catch (error) {
    console.error('Error renaming item:', error);
  }
};

export const moveFile = async (draggedItem, droppedOnItem, company) => {

  const db = getFirestore();


  try {
    console.log(draggedItem)
    console.log(droppedOnItem)
    console.log(draggedItem.id)
    console.log(droppedOnItem.id)

    //update file with new parent
    const folderRef = doc(db, 'clients', company, 'files', draggedItem.id);
    await updateDoc(folderRef, { parent: droppedOnItem.id });
    return 'success'


  } catch (error) {
    console.error('Error moving file:', error);
  }





};
export const moveFolder = async (draggedItem, droppedOnItem, company) => {

  const db = getFirestore();

  try {

    //update folder with new parent
    const folderRef = doc(db, 'clients', company, 'folders', draggedItem.id);
    await updateDoc(folderRef, { parent: droppedOnItem.id });
    return 'success'
  } catch (error) {
    console.log(error)
  }





};

 
export const moveLevel = async (company, item, path) => {
  //build a function to move folders up one level
  const db = getFirestore();
  try {
    const parent = doc(db, 'clients', company, "folders", item.parent);
    const parentSnapshot = await getDoc(parent);

    if (!parentSnapshot.exists()) {
      console.error('Error moving folder: parent not found');
      return;
    }

    const newParent = parentSnapshot.data().parent || 'root';

    console.log(newParent)


    const folderRef = doc(db, 'clients', company, path, item.id);
    await updateDoc(folderRef, { parent: newParent });
  } catch (error) {
    console.error('Error moving folder:', error);
  }
};




