import { Autocomplete, OutlinedInput, TextField, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import React from 'react';

export default class ChemicalItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            type: this.props.type,
            item: this.props.item,
            loading: true
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }

    }
    componentDidMount() {
        const { userInfo } = this.state;
    }




    updateItem = (boo, val) => {
        const { item } = this.state;
        item[boo] = val;

        this.setState({ item })
        this.props.updateEntireItem(item)
    }

    render() {

        const { item, screenWidth, type, loading } = this.state;




        return (
            <Row gutter={[screenWidth === 'xs' ? 4 : 24, screenWidth === 'xs' ? 4 : 24]} align="middle">


                <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                    <Typography variant="body1">Chemical Type</Typography>
                </Col>
                <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                    <Autocomplete
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        value={item.chemicalType}
                        onsele
                        options={[
                            { label: "Liquid", key: 'liquid' },
                            { label: "Powder", key: 'powder' },
                            { label: "Pellet", key: 'pellet' },
                        ]}
                        renderInput={(params) => <TextField {...params} label="Type" />}
                        onChange={(a, b) => {
                            this.updateItem(`chemicalType`, b)
                        }}

                    />
                </Col>
                <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                    <Typography variant="body1">EPA ID</Typography>
                </Col>
                <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                    <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"

                        value={item.chemEPA}
                        onChange={(e) => {
                            this.updateItem(`chemEPA`, e.target.value)
                        }} />
                </Col>
                <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                    <Typography variant="body1">State #</Typography>
                </Col>
                <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                    <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"

                        value={item.chemState}
                        onChange={(e) => {
                            this.updateItem(`chemState`, e.target.value)
                        }} />
                </Col>
                <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                    <Typography variant="body1">Active Ingredient</Typography>
                </Col>
                <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                    <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"

                        value={item.chemAI}
                        onChange={(e) => {
                            this.updateItem(`chemAI`, e.target.value)
                        }} />
                </Col>
                <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                    <Typography variant="body1">Instructions</Typography>
                </Col>
                <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                    <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                        multiline={true}
                        rows={4}
                        value={item.chemInstructions}
                        onChange={(e) => {
                            this.updateItem(`chemInstructions`, e.target.value)
                        }} />
                </Col>
                <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                    <Typography variant="body1">PPE Information</Typography>
                </Col>
                <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                    <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                        multiline={true}
                        rows={4}
                        value={item.ppeInformation}
                        onChange={(e) => {
                            this.updateItem(`ppeInformation`, e.target.value)
                        }} />
                </Col>
                <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                    <Typography variant="body1">Additional Information</Typography>
                </Col>
                <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                    <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                        multiline={true}
                        rows={4}
                        value={item.chemAdditaional}
                        onChange={(e) => {
                            this.updateItem(`chemAdditaional`, e.target.value)
                        }} />
                </Col>
               




            </Row>
        );
    }
} 