import React, { useState } from "react";


import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { faSquare, faSquareCheck } from '@fortawesome/free-regular-svg-icons';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, CircularProgress, Divider, IconButton, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import './list.css';



import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    thumb: {
        width: 24,
        height: 24,
        border: 'none',
        color: '#000', // Adjust color if needed
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
        '& .thumbIcon': {
            fontSize: '1rem',
        }
    }
});





const TableHome = (
    {
        items,
        setItems,
        columnFilters,
        setColumnFilters,
        sortItem,
        setSortItem,
        screenWidth
    }

) => {

    const [loading, setLoading] = useState(false);
    const [allSelected, setAllSelected] = useState(false);
    const [sortedItems, setSortedItems] = useState(items);
    const classes = useStyles();

    const [headers, setHeaders] = useState([
        { headerName: 'Parameter', id: 'parameter', position: 0, visible: true, width: 60 },
        { headerName: 'Unit', id: 'unit', position: 1, visible: true },
        { headerName: 'Method', id: 'method', position: 2, visible: true },
        { headerName: 'Actions', id: 'actions', position: 4, visible: true },
    ]);








    const props = {

    }




    // Code to update all items to selected/unselected based on allSelected state
    const toggleSelectAll = () => {
        const newItems = sortedItems.map(item => ({ ...item, selected: !allSelected }));
        setItems(newItems); // Assume updateItems is your function to update items
        setAllSelected(!allSelected);
    };



    const remainingHeaders = headers.filter(header => header.id !== 'parameter');
    const totalWidth = '100%'; // Assuming total width of the header is 100%
    const firstColumnWidth = 60; // Width of the first column
    const remainingWidth = totalWidth - firstColumnWidth;
    const eachColumnWidth = remainingWidth / remainingHeaders.length;


    return (
        <Row style={{}}>


            <Col span={24} style={{
                overflowX: 'auto',
                minHeight: '80vh'
            }}>


                <Row align="middle" >



                    <Col span={24}>
                        <Divider />
                    </Col>
                    <div style={{
                        top: 0,
                        position: 'absolute',
                        display: 'inline-flex',
                        height: '40px',
                        backgroundColor: '#f7f7f7',
                        width: '100%',
                        zIndex: 0,
                        overflowX: 'auto',

                    }} />








                    <div className="box" style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 20,
                        borderBottom: '1px solid rgba(0,0,0,0.2)'
                    }} />
                    {
                        true && (
                            <div className="box" style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',

                                width: 60,
                                borderBottom: '1px solid rgba(0,0,0,0.2)',
                            }}>
                                <IconButton
                                    onClick={() => toggleSelectAll()}
                                >
                                    <FontAwesomeIcon icon={allSelected ? faSquareCheck : faSquare}
                                        size="md" style={{ color: '#3f51b5', cursor: 'pointer' }}
                                    />
                                </IconButton>

                            </div>
                        )
                    }
                    <div className="box" style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 60,
                        borderBottom: '1px solid rgba(0,0,0,0.2)',

                    }} >
                        <IconButton
                            onClick={() => setSortItem('inactive')}
                        >
                            {
                                sortItem?.split(',')[0]?.split(': ')[1] === 'inactive' && sortItem?.split(',')[1]?.split(': ')[1] === 'asc' ? (
                                    <CaretUpOutlined style={{ color: '#3f51b5', fontSize: 16 }} />
                                ) : sortItem?.split(',')[0]?.split(': ')[1] === 'inactive' && sortItem?.split(',')[1]?.split(': ')[1] === 'desc' ? (
                                    <CaretDownOutlined style={{ color: '#3f51b5', fontSize: 16 }} />
                                ) : (
                                    <CaretDownOutlined style={{ color: 'rgba(0,0,0,0.2)', fontSize: 16 }} />
                                )
                            }
                        </IconButton>

                    </div>


                    {headers.sort((a, b) => a.position - b.position).map((header, i) => {
                        const headerWidth = eachColumnWidth;




                        if (header.visible === false) return null;

                        return (



                            <div
                                className={i === 0 ? 'boxHeaderStart' : i === headers.length - 1 ? 'boxHeaderEnd' : 'boxHeader'}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'left',
                                    paddingLeft: 8,
                                    position: 'relative',
                                    height: '40px',
                                    width: `${headerWidth}%` // Apply dynamic width here
                                }}
                            >

                                <Typography variant="body1" fontWeight={700} fontSize={screenWidth === 'xs' ? 12 : 14}>
                                    <span >{header.headerName}</span>
                                    {true && (
                                        <Badge badgeContent={columnFilters[header.id]?.length} color="error" style={{ marginLeft: 5 }}>
                                            <FontAwesomeIcon icon={faFilter} size="md" style={{ color: 'rgba(0,0,0,0.2)', paddingLeft: 5 }} />
                                        </Badge>
                                    )}

                                </Typography>


                                <IconButton
                                    onClick={() => setSortItem(`key: ${header.id}, direction: ${sortItem?.split(',')[0]?.split(': ')[1] === header.id && sortItem?.split(',')[1]?.split(': ')[1] === 'asc' ? 'desc' : 'asc'}`)}
                                >
                                    {
                                        sortItem === `key: ${header.id}, direction: asc` ? (
                                            <CaretUpOutlined style={{ color: '#3f51b5', fontSize: 16 }} />
                                        ) : sortItem === `key: ${header.id}, direction: desc` ? (
                                            <CaretDownOutlined style={{ color: '#3f51b5', fontSize: 16 }} />
                                        ) : (
                                            <CaretDownOutlined style={{ color: 'rgba(0,0,0,0.2)', fontSize: 16 }} />
                                        )
                                    }
                                </IconButton>


                            </div>



                        )
                    })}



















                    {loading === false && items?.length === 0 && (<Col span={24} style={{ paddingBottom: 30, textAlign: 'center' }}>
                        <Typography variant="h4" fontSize={24} fontWeight={600}>No parameters</Typography>
                    </Col>)}
                    {loading && (<Col span={24} style={{ paddingBottom: 30, textAlign: 'center' }}>
                        <CircularProgress />
                    </Col>)}


                </Row>
            </Col >



        </Row >
    );
};

export default TableHome;
