import { Button, Checkbox, Divider, OutlinedInput, Typography, Select, MenuItem, CircularProgress, Breadcrumbs, FormGroup, FormControlLabel, Autocomplete, Chip, TextField, Input } from '@mui/material';
import { Col, Row } from 'antd';
import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { fullMobile } from '../../util';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, } from 'firebase/firestore';

import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import uuid from 'react-uuid';


import DatePicker from "react-datepicker";
import queryString from 'query-string';
import axios from 'axios';
import { Link } from 'react-router-dom';
import LogWeather from './logWeather';
var randomColor = require('randomcolor');

export default function LogNotes(props) {
    const { userInfo, screenWidth, openSnack, company, account, updateLog, logRef, } = props;

    const [log, setLog] = useState(logRef);


    useEffect(() => {
        setLog(logRef)
    }, [logRef])




    return (
        <Row gutter={[12, 12]}>
            <Col {...fullMobile(10, 24)} style={{ paddingTop: 10 }}>
                <Typography variant='h5' fontWeight={500} fontSize={22}>Notes</Typography>
            </Col>
            <Col {...fullMobile(24, 24)} style={{ paddingTop: 10 }}>
                {props.status === 'built' ? <Typography variant="body1">{log?.fieldNotes}</Typography> :
                    <Input fullWidth notched={false} label="none" placeholder={"Additional Notes"} size="large"
                        disableUnderline={true}
                        multiline={true}
                        rows={8}
                        defaultValue={log?.fieldNotes}
                        onBlur={(e) => {
                            updateLog('fieldNotes', e.target.value)
                        }}
                         />
                }
            </Col>
            <Col {...fullMobile(24, 24)}>
                <Divider style={{
                    borderColor: 'rgb(148, 186, 195)',
                    borderBottomWidth: 'medium',

                }} />
            </Col> 

        </Row>
    )



}