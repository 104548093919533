import { CircularProgress, Typography } from '@mui/material';
import Highcharts, { attr } from 'highcharts/highstock';
import moment from 'moment';
import React from 'react';
import Plot from 'react-plotly.js';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import unitsList from '../../../extra/units.json'
import axios, * as others from 'axios';
var convert = require('convert-units')
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/data')(Highcharts);

const parameterMap = {
    '1': {
        default: 'C',
    },
    '3': {
        default: 'm',

    },
    '4': {
        default: 'm',
    },
    '5': {
        default: 'm',
    },
    '8': {
        default: 'm',
    },
    '19': {
        from: '',
        to: ''
    }
};

export default class WidgetContour extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            x: [],
            y: [],
            z: [],
            dataMin: null,
            dataMax: null,
            colorscale: [],
            contourData: [],
            contourTimes: [],
            attributes: this.props.attributes,
            userInfo: this.props.userInfo,
            height: null,
            loading: true,
            series: {},
            queryInfo: {},
            chartOptions: {
                credits: {
                    enabled: false
                },
                exporting: {
                    enabled: false
                },
                chart: {
                    resetZoomButton: {
                        position: {
                            align: 'left',
                            x: 20,
                            y: -20
                        }
                    },
                    style: {
                        fontFamily: 'Roboto, sans-serif'
                    },




                    zoomType: 'x',
                    height: null,
                    animation: false
                },
                title: {
                    text: null
                },
                legend: {
                    itemStyle: {
                        fontSize: '14px',
                        fontFamily: 'Roboto, sans-serif'
                    },
                },


                series: [],
                yAxis: [{ id: 0 }],
                xAxis: {
                    type: 'datetime',
                    plotLines: []
                },
                tooltip: {
                    xDateFormat: '%b %d, %Y %I:%M %p',
                    shared: true
                },
                plotOptions: {
                    series: {
                        animation: false
                    },


                },
                time: {
                    useUTC: false,
                }
            },


        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.attributes !== prevProps.attributes) {
            this.updateAttributes(this.props.attributes)
            this.setState({ attributes: this.props.attributes })
        }



    }
    componentDidMount() {
        const { attributes, chartOptions, queryInfo, series, } = this.state;
        this.setState({ height: this.container.offsetHeight, });
        this.updateChartSub('chart', 'height', this.container.offsetHeight)




        this.updateAttributes(attributes)



    }



    updateAttributes = async (attributes) => {
        const { chartOptions } = this.state;
        const qi = {
            interval: null,
            units: 'hours',
            start: moment(attributes.startDate, 'x').startOf("day").format('MM-DD-YYYY HH:mm'),
            iStart: moment(attributes.startDate, 'x').startOf("day").format('x'),
            end: attributes.endToday === true ? moment().endOf("day").format('MM-DD-YYYY HH:mm') : moment(attributes.endDate, 'x').endOf("day").format('MM-DD-YYYY HH:mm'),
            iEnd: attributes.endToday === true ? moment().endOf("day").format('x') : moment(attributes.endDate, 'x').endOf("day").format('x'),
            customInterval: attributes.customInterval || undefined,
            customIntervalVal: attributes.customIntervalVal || 4,
        };

        this.setState({ queryInfo: qi })



        const series = attributes.chartData;

        const queryInfo = qi;





        const proxyUrl = "https://mycorslake.herokuapp.com/";


    


        function someAsyncFunction(q, queryInfo) {

            return new Promise(async (resolve, reject) => {


                const rawNode = `(SELECT time
                    FROM node_data_new2
                    WHERE nodeid = '${q.locationid}'and parameterid = '${q.parameterid}'
                    and time >= '${queryInfo.start}' and time <= '${queryInfo.end}' 
                    ORDER BY time DESC
                    LIMIT 1)
                    
                    UNION ALL
                    
                    (SELECT time
                    FROM node_data_new2
                    WHERE nodeid = '${q.locationid}'and parameterid = '${q.parameterid}'
                    and time >= '${queryInfo.start}' and time <= '${queryInfo.end}'  
                    ORDER BY time ASC    
                    LIMIT 1);`

                const rawProbe = `
                (SELECT time
                    FROM probe_data
                    WHERE locationid = '${q.locationid}'and parameterid = '${q.parameterid}'
                    and time >= '${queryInfo.start}' and time <= '${queryInfo.end}' 
                    ORDER BY time DESC
                    LIMIT 1)
                    
                    UNION ALL
                    
                    (SELECT time
                    FROM probe_data
                    WHERE locationid = '${q.locationid}'and parameterid = '${q.parameterid}'
                    and time >= '${queryInfo.start}' and time <= '${queryInfo.end}'  
                    ORDER BY time ASC    
                    LIMIT 1);`



                axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgetsFire1/sqlRead', {
                    raw: q.dataType === 'node' ? rawNode : rawProbe
                })
                    .then(async (response) => {
                        const data = response.data;

                        var now = moment(data[0].time);
                        var end = moment(data[1].time);
                        var duration = moment.duration(now.diff(end));

                        var hours = duration.asHours();
                        const seriesLength = 350;
                        const slice = Math.floor(hours / seriesLength)
                        const sliceLength = slice === 0 ? 1 : slice

                        let value = 'avg(value) as "y", COALESCE(avg(offsetvalue) , 0) as "offset"';

                        const customBucketProbe =
                            `SELECT time_bucket('${sliceLength || 1} hours', time) as "t"
                    ,locationid, unitid, avg(timestamp) as "x", ${value}
                    FROM probe_data
                    WHERE locationid = '${q.locationid}'and parameterid = '${q.parameterid}'
                    and time >= '${queryInfo.start}' 
                    and time <= '${queryInfo.end}' 
        
                    GROUP BY "t", locationid, unitid
                    ORDER BY "t" DESC;`;
                        const customBucketNode =
                            `SELECT time_bucket('${sliceLength || 1} hours', time) as "t"
                    ,nodeid, unitid, avg(timestamp) as "x", ${value}
                    FROM node_data_new2
                    WHERE nodeid = '${q.locationid}'and parameterid = '${q.parameterid}'
                    and time >= '${queryInfo.start}' 
                    and time <= '${queryInfo.end}' 
        
                    GROUP BY "t", nodeid, unitid
                    ORDER BY "t" DESC;`;

                        const queryString = "widgetsFire5";



                        axios.post(proxyUrl + `https://us-central1-aquasource3.cloudfunctions.net/${queryString}/sqlRead`, {
                            raw: q.dataType === 'node' ? customBucketNode : customBucketProbe
                        }).then(async (response) => {




                            const dataList = [];
                            response.data.map((d) => {
                                let offset = q.offset || 0;
                                let value = q?.dataSourceType === 'reading' ? Number(d.y) :
                                    q?.dataSourceType === 'readingWithOffset' ? Number(d.y) + Number(d.offset) :
                                        q?.dataSourceType === 'offset' ? Number(d.offset) :
                                            Number(d.y);



                                if (["38", "35", "1", "2"].includes(d.unitid) && d.unitid !== undefined && d.unitid !== null && d.unitid !== '' && q.units) {
                                    let parameter = parameterMap[q.parameterid] || {};
                                    let defaultUnit = parameter.default || '';

                                    const unitLabel = unitsList[d.unitid] ? unitsList[d.unitid] : defaultUnit;

                                    value = Number(convert(value.toFixed(4)).from(unitLabel).to(q.units));
                                }
                                else {
                                    value = Number(value.toFixed(4));
                                }

                                value += offset;
                                const time = Number(moment(d.t, 'YYYY-MM-DD HH:mm:ss').format('x'));
                                const depth = Number(q.depth);
                                dataList.push({ value, time, depth });
                            });

                            //sort dataList by time
                            dataList.sort((a, b) => a.time - b.time);



                            resolve(dataList);
                        })



                    })
                    .catch((error) => {
                        // handle error
                        resolve([{ value: null, time: null, depth: Number(q.depth) }])

                    })
                    .then(function () {
                        // always executed
                    });









            });
        }

        //create new series without series that have no depth or depth is null or undefined or ""
        const seriesFiltered = series.filter((i) => i.depth !== null && i.depth !== undefined && i.depth !== "");

        //sort series by depth as a number
        seriesFiltered.sort((a, b) => Number(a.depth) - Number(b.depth));



        const resultArray = await Promise.all(seriesFiltered.map(async (i) => someAsyncFunction(i, qi)));


        //sort each item in resultArray by time
        resultArray.map((i) => i.sort((a, b) => a.time - b.time));


        //create a list of all unique times from each of the items in resultArray and combine them into one list
        const timesList = resultArray.map((i) => i.map((d) => d.time));
        const timesListFlat = timesList.flat(1);
        const timesListUnique = [...new Set(timesListFlat)];
        //sort timesListUnique by smallest number to largest number
        timesListUnique.sort((a, b) => a - b);



        //add null values to each item in resultArray for times that are not in the item
        const resultListArrayWithNulls = resultArray.map((i) => {
            const dataList = [];
            const data = i;
            timesListUnique.forEach((t) => {
                const index = data.map((d) => d.time).indexOf(t);
                if (index > -1) {
                    dataList.push(data[index])
                } else {

                    dataList.push({ value: null, time: t, depth: data[0].depth })
                }
            }
            );
            return dataList;
        }
        );


        //create an array of arrays of values for each depth
        const resultArrayValues = resultListArrayWithNulls.map((i) => i.map((d) => d.value));


        function replaceNullsWithAverage(data) {

            return data.map((subArray) => {
                return subArray.map((item, index) => {
                    if (item !== null) {
                        return item;
                    }

                    let prevValue = null;
                    let nextValue = null;

                    for (let i = index - 1; i >= 0; i--) {
                        if (subArray[i] !== null) {
                            prevValue = subArray[i];
                            break;
                        }
                    }

                    for (let i = index + 1; i < subArray.length; i++) {
                        if (subArray[i] !== null) {
                            nextValue = subArray[i];
                            break;
                        }
                    }

                    if (prevValue === null && nextValue === null) {
                        return null;
                    } else if (prevValue === null) {
                        return nextValue;
                    } else if (nextValue === null) {
                        return prevValue;
                    } else {
                        return (prevValue + nextValue) / 2;
                    }
                });
            });
        }

        const resultArrayValuesWithNullsReplaced = replaceNullsWithAverage(resultArrayValues);


        //sort timesListUnique by smallest number to largest number
        this.setState({ contourData: resultArrayValuesWithNullsReplaced, contourTimes: timesListUnique, })



















        //combine all times from each item in resultArray
        const times = resultArray.map((i) => i.map((d) => d.time));

        const timesFlat = times.flat(1);
        const timesUnique = [...new Set(timesFlat)];



        //check each item in resultArray for times that are in timesUnique and if they are create a new item with value of null
        const resultArrayWithNulls = resultArray.map((i) => {
            const dataList = [];
            const data = i;
            timesUnique.forEach((t) => {
                const index = data.map((d) => d.time).indexOf(t);
                if (index > -1) {
                    dataList.push(data[index])
                } else {

                    dataList.push({ value: null, time: t, depth: data[0].depth })
                }
            }
            );
            return dataList;
        }
        );

        //create a new array with the unique depths
        const uniqueDepths = [...new Set(resultArrayWithNulls.map((i) => i[0].depth))];


        //create an array of arrays of only the values for each depth
        const valuesByDepth = uniqueDepths.map((d) => {
            const values = [];
            resultArrayWithNulls.map((i) => {
                if (i[0].depth === d) {
                    values.push(i.map((d) => d.value))
                }
            }
            );
            return values;
        }
        );
        //flatten the array of arrays
        const valuesByDepthFlat = valuesByDepth.map((i) => i.flat(1));

   

        const y = uniqueDepths
        const z = valuesByDepthFlat
        const x = timesListUnique



        const dataMax = Math.max(...z.flat(1));
        const dataMin = Math.min(...z.flat(1));

     







        chartOptions.chart.events = {

        };


        chartOptions.series = series;


        

        const normalizeValue = (value, min, max) => {
            let normalized = (value - min) / (max - min);
            return Math.min(1, Math.max(0, normalized)); // Clamp between 0 and 1
        };

        // Creating the colorscale for Plotly
        const colorscale = attributes?.colorRanges === undefined ? undefined : attributes?.colorRanges.map(range => {
            let normalizedValue;

            switch (range.type) {
                case 'greater_than':
                    normalizedValue = normalizeValue(Number(range.min), dataMin, dataMax);

                    return [normalizedValue, range.color];
            }
            switch (range.type) {
                case 'less_than':
                    normalizedValue = normalizeValue(Number(range.max), dataMin, dataMax);

                    return [normalizedValue, range.color];
            }
            switch (range.type) {
                case 'greater_or_equal':
                    normalizedValue = normalizeValue(Number(range.min), dataMin, dataMax);

                    return [normalizedValue, range.color];
            }

            switch (range.type) {
                case 'less_or_equal':
                    normalizedValue = normalizeValue(Number(range.max), dataMin, dataMax);

                    return [normalizedValue, range.color];
            }



        });


        if (attributes?.colorRanges !== undefined) {


            /* sort by index 0 */
            colorscale.sort((a, b) => a[0] - b[0]);

            const minColor = colorscale[0][1];
            const maxColor = colorscale[colorscale?.length - 1][1];

            /* add the min and max colors to the colorscale */
            colorscale.unshift([0, minColor]);
            colorscale.push([1, maxColor]);
        }

      
        this.setState({ x, y, z, series, queryInfo, loading: false, colorscale })


    }





    updateChart = (boo, val) => {
        const { chartOptions } = this.state;
        chartOptions[boo] = val;
        this.setState({ chartOptions })

    }
    updateChartSub = (boo, a, val) => {
        const { chartOptions } = this.state;
        chartOptions[boo][a] = val;
        this.setState({ chartOptions })

    }









    render() {

        const { contourData, contourTimes, account, chartOptions, hoverData, loading, height, dimensions, attributes, x, y, z, colorscale } = this.state;


        const Height = () => {
            if (this.container) {
                if (this.container.offsetHeight !== height) {
                    this.updateChartSub('chart', 'height', this.container.offsetHeight)
                    this.setState({ height: this.container.offsetHeight })
                }

            }

        }




        const colorBarColor = attributes.colorBarTheme === undefined ? [
            [0, 'rgba(254, 73, 44, 0.9)'],
            [0.5, 'rgba(255, 208, 0, 0.6)'],
            [1, 'rgba(97, 201, 164, 0.9)']
        ] : attributes.colorBarTheme === 'standard' ? [
            [0, 'rgba(254, 73, 44, 0.9)'],
            [0.5, 'rgba(255, 208, 0, 0.6)'],
            [1, 'rgba(97, 201, 164, 0.9)']
        ] : attributes.colorBarTheme === 'reverseStandard' ?
            [
                [0, 'rgba(97, 201, 164, 0.9)'],
                [0.5, 'rgba(255, 208, 0, 0.6)'],
                [1, 'rgba(254, 73, 44, 0.9)']
            ] : attributes.colorBarTheme === 'blueToRed' ?
                [
                    [0, 'rgba(0, 0, 255, 0.9)'],
                    [0.5, 'rgba(255, 208, 0, 0.6)'],
                    [1, 'rgba(255, 0, 0, 0.9)']
                ] : attributes.colorBarTheme === 'redToBlue' ?
                    [
                        [0, 'rgba(255, 0, 0, 0.9)'],
                        [0.5, 'rgba(255, 208, 0, 0.6)'],
                        [1, 'rgba(0, 0, 255, 0.9)']
                    ] : [
                        [0, 'rgba(254, 73, 44, 0.9)'],
                        [0.5, 'rgba(255, 208, 0, 0.6)'],
                        [1, 'rgba(97, 201, 164, 0.9)']
                    ];

       

        const Loading = () => { if (loading) { return <div className="loading"><CircularProgress /> <Typography variant="body1">Loading Data</Typography></div> } }

        //if loading true show loading screen

        return (
            <div ref={el => (this.container = el)
            } style={{ height: '100%', width: '100%', }}>
                <Height />

                {loading === true && (
                    <div
                        className="chart-container"
                        ref={el => (this.container = el)}
                        style={{
                            height: "95%",
                            width: "100%",
                            position: "relative",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                        }}
                    >
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <FontAwesomeIcon icon={faSpinner} color="#404050" spin size="3x" />
                        </div>
                        <div style={{ marginTop: "10px" }}>
                            <Typography
                                variant="body1"
                                fontSize={13}
                                fontWeight={400}
                                style={{ color: "#404050", textAlign: "center" }}
                            >
                                Loading chart...
                            </Typography>
                        </div>
                    </div>
                )}

                {x.length > 0 && y.length > 0 && z.length > 0 && loading === false && (
                    <Plot
                        data={[{

                            z: contourData,
                            y: [...y],
                            x: [...contourTimes],
                            colorscale: attributes.colorBarTheme === 'custom' ? colorscale : colorBarColor,
                            zmax: attributes.colorBarMax || 20,
                            zmin: attributes.colorBarMin || 0,
                            zauto: attributes.colorBarAuto !== undefined ? attributes.colorBarAuto : true,
                            zsmooth: 'best',
                            type: attributes.showLines !== undefined ? attributes.showLines ? 'contour' : 'heatmap' : 'heatmap',
                            showscale: attributes.colorBar || true,
                            connectgaps: attributes.connectGaps || true,
                            colorbar: { title: { text: attributes.colorBarLabel || '', standoff: 0, font: 'Roboto, sans-serif' } },
                            hovertemplate: `<b>${attributes?.depthLabel || 'Depth'}: %{y:.2f} ${attributes.depthUnits || ' '}</b><br><b>${attributes?.valueLabel || 'Value'}: %{z:.2f} ${attributes.unitsLabel || ' '}</b><br><b>Date Time: %{x|%d. %b %I:%M %p}</b><extra></extra>`
                        }
                        ]}
                        layout={{
                            font: {
                                family: 'Inter, sans-serif'
                            },
                            colorbar: { title: { text: 'mg/L', standoff: 0 } },
                            title: null, autosize: true,
                            yaxis: {
                                autorange: 'reversed',
                                rangemode: 'nonnegative',
                                title: { text: attributes.yAxisLabel || '', standoff: 0 }
                            },
                            xaxis: {
                                title: { text: attributes.xAxisLabel || '', standoff: 0 },
                                type: 'date',
                                tickformat: "%d. %b"
                            },
                            margin: {
                                l: 60,
                                r: 10,
                                b: 50,
                                t: 30,
                                pad: 10
                            },

                        }}

                        useResizeHandler={true}
                        style={{ width: "100%", height: "100%" }}

                    />

                )}
            </div >

        );

    }
} 