import React from "react";
import { Button, Divider, Typography, TextField, Box, OutlinedInput, ButtonGroup, List, ListItem, ListItemText, Chip, IconButton, FormControlLabel, Checkbox, Select, MenuItem, Autocomplete } from '@mui/material';
import { Col, Row, Modal } from 'antd';
import { fullMobile } from '../../../util';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faSquare, faSquareCheck } from '@fortawesome/free-regular-svg-icons';

const InspectionNonEdit = (props) => {
    const { nodes, parameters, equipmentList, updateData, inspectionItems, key, unitList, updateDatabase } = props;

    return (
        <div>
            {
                inspectionItems !== undefined && inspectionItems.length > 0 && inspectionItems
                    .map((item, index) => {
                 

                        const station = nodes.find((n) => n.id === item?.nodeid);

                        const parameter = parameters.find((p) => p.key === item?.parameterid);

                        const equipment = equipmentList.find((e) => e.key === item?.equipment);
                        const selected = item.dropdownOptions?.find((o) => o.id === inspectionItems[index]?.selected) || 'none';

                        const multiSelect = inspectionItems[index]?.selectedMulti || [];



                        if (item?.type === 'observation') {
                            return (
                                <Row key={index} style={{ marginTop: 10 }} align="middle" gutter={[12, 12]}>
                                    <Col >
                                        <Typography display="inline" variant='body1' fontWeight={600} color="#404050" fontSize={16}>{item.label}: </Typography>
                                        <Typography display="inline" variant="body1" fontSize={14} fontWeight={400} style={{ marginLeft: 10 }}    >
                                            {inspectionItems[index]?.observation || ''}</Typography>
                                    </Col>
                                    <Col span={24}>
                                        <Divider />
                                    </Col>

                                </Row>


                            )

                        }

                        if (item?.type === 'section') {
                            return (
                                <Row key={index} style={{ marginTop: 35 }} align="middle" gutter={[4, 4]}>
                                    <Col >
                                        <Typography display="inline" variant='body1' fontWeight={700} color="black" fontSize={20}>{item.label}: </Typography>

                                    </Col>
                                    <Col span={24} style={{ paddingBottom: 4 }}>
                                        <Divider
                                            //thick and black
                                            style={{ backgroundColor: 'rgba(0,0,0,0.8)', height: '3px' }}

                                        />
                                    </Col>

                                </Row>


                            )

                        }
                        if (item?.type === 'equipment') {
                            return (
                                <Row key={index} style={{ marginTop: 10 }} align="middle" gutter={[12, 12]}>
                                    <Col >
                                        <Typography display="inline" variant='body1' fontWeight={600} color="#404050" fontSize={16}>{item.label}:
                                            <Typography variant="body1" fontSize={14} fontWeight={400} display="inline" style={{ marginLeft: '5px' }}   >
                                                For equipment item {`${equipment?.label} `}</Typography>
                                        </Typography>
                                        <Typography variant="body1" fontSize={14} fontWeight={400}   >
                                            {inspectionItems[index]?.equipmentObservation || ''}</Typography>

                                    </Col>

                                </Row>


                            )

                        }

                        if (item?.type === 'checkbox') {

                            return (
                                <Row key={index} style={{ marginTop: 10 }} align="middle" gutter={[12, 12]}>
                                    <Col >
                                        <Typography display="inline" variant='body1' fontWeight={600} color="#404050" fontSize={16}>{item.label}</Typography>


                                        {inspectionItems[index]?.checked === true && <FontAwesomeIcon display="inline" icon={faSquareCheck} size="2x" style={{ marginLeft: 5 }} />}
                                        {inspectionItems[index]?.checked === false && <FontAwesomeIcon display="inline" icon={faSquare} size="2x" style={{ marginLeft: 5 }} />}

                                    </Col>
                                    <Col span={24}>
                                        <Divider />
                                    </Col>
                                </Row>
                            )
                        }
                        if (item?.type === 'select') {

                            return (

                                <Row key={index} style={{ marginTop: 10 }} align="middle" gutter={[12, 12]}>
                                    <Col >
                                        <Typography display="inline" variant='body1' fontWeight={600} color="#404050" fontSize={16}>{item.label}: </Typography>
                                        <Typography display="inline" variant="body1" fontSize={14} fontWeight={400} style={{ marginLeft: 10 }}   >
                                            {selected !== 'none' && (
                                                <Chip key={selected.id} label={selected.label}


                                                    style={{
                                                        backgroundColor: selected.tagColor,
                                                        color: selected.textColor,
                                                        fontWeight: 500,
                                                        fontSize: 16,
                                                        marginRight: 5
                                                    }} />
                                            )}</Typography>
                                    </Col>
                                    <Col span={24}>
                                        <Divider />
                                    </Col>

                                </Row>


                            )
                        }
                        if (item?.type === 'selectMulti') {

                            return (

                                <Row key={index} style={{ marginTop: 10 }} align="middle" gutter={[12, 12]}>
                                    <Col >
                                        <Typography display="inline" variant='body1' fontWeight={600} color="#404050" fontSize={16}>{item.label}: </Typography>
                                        <Typography display="inline" variant="body1" fontSize={14} fontWeight={400} style={{ marginLeft: 10 }}   >
                                            {multiSelect.map((value) => {
                                                const option = item.dropdownOptions.find(option => option.id === value);
                                                return (
                                                    <Chip key={value} label={option.label}


                                                        style={{
                                                            backgroundColor: option.tagColor,
                                                            color: option.textColor,
                                                            fontWeight: 500,
                                                            fontSize: 16,
                                                            marginRight: 5
                                                        }} />
                                                );
                                            })}


                                        </Typography>
                                    </Col>
                                    <Col span={24}>
                                        <Divider />
                                    </Col>

                                </Row>


                            )
                        }
                        if (item?.type === 'data') {

                            return (
                                <Row key={index} style={{ marginTop: 10 }} align="middle" gutter={[4, 4]}>
                                    <Col span={24} >
                                        <Typography display="inline" variant='body1' fontWeight={600} color="#404050" fontSize={16}>{item.label}:
                                            <Typography variant="body1" fontSize={14} fontWeight={400} display="inline" style={{ marginLeft: '5px' }}   >
                                                {`Node: ${station?.label} and Parameter: ${parameter?.label}`}</Typography>
                                        </Typography>


                                    </Col>
                                    <Col style={{
                                        border: '2px solid #404050',
                                        borderRadius: 5,
                                        padding: 2
                                    }} >

                                        <Typography variant="h6" fontSize={18} fontWeight={500} display="inline"
                                            style={{ color: '#404050', marginLeft: 3 }}  >
                                            {inspectionItems[index]?.value || ''}</Typography>
                                        <Typography variant="h6" fontSize={18} fontWeight={400} display="inline" style={{ color: '#404050', marginRight: 3 }}  >
                                            {` ${unitList.find((u) => u.unitid === inspectionItems[index]?.unitid)?.label || ''}`}</Typography>

                                    </Col>
                                    <Col span={24}>
                                        <Divider />
                                    </Col>

                                </Row>
                            )
                        }






                    })
            }
        </div>
    )






}

export default InspectionNonEdit;