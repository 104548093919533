import { Button, Checkbox, Divider, OutlinedInput, Typography, Select, MenuItem, CircularProgress } from '@mui/material';
import { Col, Row } from 'antd';
import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { fullMobile } from './util';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, } from 'firebase/firestore';

import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios, * as others from 'axios';
import queryString from 'query-string';

export default function AccountsUpdate({ currentUser, userInfo }) {
    const [user, setCurrentUser] = useState(JSON.parse(currentUser))
    const [accounts, setAccounts] = useState([])
    const [loading, setLoading] = useState(true)
    const db = getFirestore();

    useEffect(() => {
        setCurrentUser(JSON.parse(currentUser))
        return () => {
            console.log("This will be logged on unmount");
        }
    }, [currentUser]);

    useEffect(() => {
        //get companies accounts

        getAccounts(JSON.parse(currentUser))
    }, [currentUser]);

    async function getAccounts(currentUser) {

        const db = getFirestore();
        const queryAccounts = query(collection(db, "clients", userInfo.currentCompany, 'accounts',), where('archived', '==', false));
        const accounts = [];
        const snapAccounts = await getDocs(queryAccounts);
        snapAccounts.forEach(async (item) => {

            accounts.push({ ...item.data(), })


        })
        //sort accounts by accountName
        accounts.sort((a, b) => (a.accountName > b.accountName) ? 1 : -1)
        editAccounts(accounts)
        console.log(accounts)



    }

    async function editAccounts(accounts) {


        const newArr = await Promise.all(accounts.map(async account => {
            const docRef = doc(db, "clients", userInfo.currentCompany, 'accounts', account.key, 'staff', user.id);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const adminLevel = docSnap.data().adminLevel || 'none'
                console.log(adminLevel)

                return { ...account, adminLevel: adminLevel }
            }
            else {
                return { ...account, adminLevel: 'none' }
            }
        }))

        //sort newArr by accountName
        newArr.sort((a, b) => (a.accountName > b.accountName) ? 1 : -1)


        setAccounts(newArr)
        setLoading(false)




    }



    async function addCurrentUserToAccount(accountKey) {


        //add account to currentUser state
        const newUser = { ...user, accounts: [...user.accounts, accountKey] }
        setCurrentUser(newUser)


        const userRef = doc(db, "users", user.id,);
        await updateDoc(userRef, {
            accounts: arrayUnion(accountKey)
        })

        const docRef = doc(db, "clients", userInfo.currentCompany, 'accounts', accountKey, 'staff', user.id);
        await setDoc(docRef, { adminLevel: 'none', flDistributtion: false, key: newUser.id, id: newUser.id })


    }

    async function removeCurrentUserFromAccount(accountKey) {

        const newAccounts = user.accounts.filter((account) => account !== accountKey);
        const newUser = { ...user, accounts: newAccounts }
        setCurrentUser(newUser)


        await deleteDoc(doc(db, "clients", userInfo.currentCompany, 'accounts', accountKey, 'staff', newUser.id)).then(async () => {
            const userRef = doc(db, "users", newUser.id,);
            await updateDoc(userRef, {
                accounts: arrayRemove(accountKey)
            })
        })



    }

    async function updateAdminLevel(accountKey, adminLevel) {
        const index = accounts.findIndex((account) => account.key === accountKey)
        const newAccounts = [...accounts]
        newAccounts[index].adminLevel = adminLevel
        setAccounts(newAccounts)
        const docRef = doc(db, "clients", userInfo.currentCompany, 'accounts', accountKey, 'staff', user.id);
        await updateDoc(docRef, { adminLevel })

    }





    const XTable = ({ columns, header, data }) => {

        const getMuiTheme = () =>
            createTheme({
                components: {
                    MUIDataTable: {
                        styleOverrides: {
                            root: {
                                padding: '8px',
                                boxShadow: 'none'
                            }
                        }
                    },
                    MuiTableRow: {
                        styleOverrides: {
                            root: {
                                zIndex: 100,
                                cursor: 'pointer',
                                fontSize: '10px',
                                '&:nth-child(even)': {
                                    backgroundColor: '#eeeeee52'
                                },
                                "&:hover": {
                                    backgroundColor: "#9ec1c933"
                                }


                            },


                        }
                    },
                    MuiTableCell: {
                        styleOverrides: {
                            root: {
                                fontSize: '12px',
                                padding: '2px'
                            }
                        }
                    }

                }
            });

        const keyIndex = 4;

        const companyColumns = [




            {
                name: 'accountName',
                label: 'Account',
                options: {
                    filter: false,
                    customBodyRender: (a, b) => {
                        const accountKey = b.rowData[keyIndex];

                        return (
                            <Row align="middle">
                                <Checkbox checked={user.accounts.includes(accountKey)}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            addCurrentUserToAccount(accountKey)
                                        } else {
                                            removeCurrentUserFromAccount(accountKey)
                                        }
                                    }} />


                                <Typography variant="subtitle1" component="subtitle1" display="inline" gutterBottom>
                                    {a}
                                </Typography>
                            </Row>
                        )




                    }

                },
            },
            {
                name: 'routeDay',
                label: 'Day',
                options: {
                    filter: true,
                    customBodyRender: (a, b) => {
                        //capitalize first letter
                        const value = a || 'none'
                        const day = value?.charAt(0).toUpperCase() + value?.slice(1)
                        return (
                            <Typography variant="subtitle1" component="subtitle1" display="inline" gutterBottom>
                                {day}
                            </Typography>
                        )
                    }

                },
            },
            {
                name: 'routeName',
                label: 'Route',
                options: {
                    filter: true,
                    customBodyRender: (a, b) => {
                        //capitalize first letter

                        return (
                            <Typography variant="subtitle1" component="subtitle1" display="inline" gutterBottom>
                                {a}
                            </Typography>
                        )
                    }

                },
            },

            {
                name: 'adminLevel',
                label: 'Admin Level',
                options: {
                    filter: false,
                    customBodyRender: (a, b) => {

                        const accountKey = b.rowData[keyIndex];
                        if (user.accounts.includes(accountKey) && user.id !== currentUser.id) {
                            return (
                                <Select size="small" fullWidth
                                    notched={false}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={a}
                                    label="Layer"
                                    onChange={(e) => {
                                        updateAdminLevel(accountKey, e.target.value)

                                    }}

                                >
                                    <MenuItem value={'admin'}>Admin</MenuItem>
                                    <MenuItem value={'customer'}>Customer</MenuItem>
                                    <MenuItem value={'client'}>Client</MenuItem>
                                    <MenuItem value={'contractor'}>Contractor</MenuItem>
                                    <MenuItem value={'none'}>None</MenuItem>

                                </Select>
                            )
                        }


                    }
                },

            },
            {
                name: 'key',
                label: '',
                options: {
                    display: 'excluded',
                    filter: false,
                    customBodyRender: (a, b) => {

                        return null

                    }
                },

            },





        ]



        return (
            <ThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                    title={''}
                    data={accounts}
                    columns={companyColumns}

                    options={{
                        rowsPerPage: 40,
                        textLabels: {
                            body: {
                                noMatch: <div style={{}}>{loading ? "Loading Data" : "No Records Found"} {loading && (<CircularProgress />)}</div>
                            }
                        },
                        downloadOptions: {
                            filename: 'Users'
                        },
                        print: false,

                        viewColumns: false,
                        selectableRows: false,

                    }}

                />
            </ThemeProvider>
        );
    }



    const AddUserToAllAccountsButton = () => {

        return (
            <Button variant="contained" color="primary"
                size="small"
                style={{
                    textTransform: 'none',
                    backgroundColor: '#404050'
                }}


                onClick={async () => {
                    const accountKeys = accounts.map((account) => account.key)
                    const newUserAccounts = [...user.accounts, ...accountKeys]
                    //only unique accounts
                    const uniqueAccounts = [...new Set(newUserAccounts)]
                    const newUser = { ...user, accounts: uniqueAccounts }
                    setCurrentUser(newUser)

                    accountKeys.forEach(async (accountKey) => {
                        if (!user.accounts.includes(accountKey)) {
                            const docRef = doc(db, "clients", userInfo.currentCompany, 'accounts', accountKey, 'staff', user.id);
                            await setDoc(docRef, { adminLevel: 'none', flDistributtion: false, key: newUser.id, id: newUser.id })
                        }
                    })
                    //update user
                    const userDocRef = doc(db, 'users', user.id);
                    await updateDoc(userDocRef, {
                        accounts: uniqueAccounts
                    })






                }}>
                Add User To All Accounts
            </Button>

        )
    }

    function RemoveUserFromAllAccountsButton() {
        return (
            <Button
                variant="contained"
                color="secondary"
                size="small"
                style={{
                    textTransform: 'none',
                    backgroundColor: '#b71c1c',
                    marginLeft: '10px',
                }}
                onClick={async () => {
                    const accountKeys = accounts.map((account) => account.key);

                    accountKeys.forEach(async (accountKey) => {
                        if (user.accounts.includes(accountKey)) {
                            const docRef = doc(
                                db, 'clients', userInfo.currentCompany, 'accounts', accountKey, 'staff', user.id
                            );
                            await deleteDoc(docRef);

                            const userDocRef = doc(db, 'users', user.id);
                            await updateDoc(userDocRef, {
                                accounts: arrayRemove(accountKey),
                            });


                        }
                    });
                    const newAccounts = user.accounts.filter(
                        (account) => !accountKeys.includes(account)
                    );
                    const newUser = { ...user, accounts: newAccounts };
                    setCurrentUser(newUser);

                    // Update user

                }}
            >
                Remove User From All Accounts
            </Button>
        );
    }






    return (
        <Row style={{ fontFamily: 'Roboto, sans-serif', }}>
            <Col {...fullMobile(24, 24)} style={{}}>
                <Typography variant="h4" component="h4" display="ineline" gutterBottom>
                    {user.firstName}'s Sites
                </Typography>
            </Col>
            <Col {...fullMobile(24, 24)} style={{}}>
                <AddUserToAllAccountsButton />
                <RemoveUserFromAllAccountsButton />
            </Col>
            <Col {...fullMobile(24, 24)} style={{}}>
                <XTable />

            </Col>

        </Row>
    );
}