import React from "react";


import { Autocomplete, Box, Button, Grid, TextField, Typography } from '@mui/material';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";



const CSVNextStep = (props) => {


    const {
        templates,
        setTemplates,
        template,
        setTemplate,
        steps,
        setSteps,
        currentStep,
        item,
        setItem,
        nextStep,
        setData,
        data,
        meta,
        setHeaders,
        setMeta
    } = props;



    return (



        <Box
            sx={{
                mt: 4
            }}
            style={{
                borderTop: '1px solid #e2e2e2',
            }}

        >
            <Typography variant="h6" fontWeight={600} fontSize={18} gutterBottom component="div" style={{ paddingTop: 20 }}>
                File Name:
                <Typography fontWeight={500} fontSize={18} color={'#4a4a4a'} gutterBottom component="div" style={{ display: 'inline', paddingLeft: 10 }}>

                    {meta?.name}
                </Typography>
                {meta?.name && (<FontAwesomeIcon icon={faCircleCheck} size="lg" style={{ color: '#0ce10c', marginLeft: 10 }} />)}
            </Typography>

            <Button variant="contained" color="primary"
                fullWidth
                disabled={data === null}
                onClick={() => {
                    console.log("CLICK")
                    console.log(template)
                    if ([null, undefined].includes(template)) {
                        nextStep()
                        console.log(template)
                    }
                    else {

                        //add minwidth and width to template headers
                        template.headers.forEach((header) => {
                            header.minWidth = 180;
                            header.width =  160;
                        })

                        console.log("THIS THING TRIGGERING?")
                        console.log(template)
                        setHeaders(template?.headers)
                        nextStep()

                    }
                }
                }
                style={{
                    textTransform: 'none',
                    backgroundColor: data === null ? '#f2f4f7' : '#3f51b5',
                    height: 50,
                    fontWeight: 600,
                    fontSize: 14,
                }}>
                Map Data
            </Button>

        </Box >






    );
};

export default CSVNextStep;
