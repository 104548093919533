import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import moment from 'moment';
import React from 'react';
//See the list of possible plotly bundles at https://github.com/plotly/plotly.js/blob/master/dist/README.md#partial-bundles or roll your own

import axios, * as others from 'axios';
var convert = require('convert-units');


require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/data')(Highcharts);
require('highcharts/modules/heatmap')(Highcharts);
require('highcharts/modules/boost')(Highcharts);




export default class WidgetIsopleth extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            attributes: this.props.attributes,
            userInfo: this.props.userInfo,
            height: null,
            width: null,
            series: {},
            queryInfo: {},
            chartOptions: {
                credits: {
                    enabled: false
                },

                chart: {
                    type: 'heatmap',

                },

                boost: {

                },

                title: {
                    text: null
                },
                legend: {},
                tooltip: {
                    formatter: function () {
                        const date = moment(this.point.x, 'x').format('MM/DD/YYYY');
                        const value = this.point.value;
                        const depth = this.point.y;
                        return `${date}, ${value} @ ${depth}`;
                    }
                },

                xAxis: {
                    type: 'datetime',
                    zoomType: 'x',
                    tickLength: 16
                },

                yAxis: {
                    title: {
                        text: null
                    },

                    startOnTick: true,
                    endOnTick: true,
                    minPadding: 0,
                    maxPadding: 0,
                    tickWidth: 1,
                    min: 0,
                    max: 12,
                    reversed: true
                },

                colorAxis: {
                    stops: [],
                    min: 0,
                    max: 10,
                    startOnTick: false,
                    endOnTick: false,
                    labels: {
                        format: '{value}mg/L'
                    }
                },


                series: []
            },
            capacityData: [],
            depthUnits: '',
            areaUnits: '',
            volumeUnits: '',
            contourData: [[], [], [], [], [], [], [], [], [], [], [], []],
            loadingGraph: true


        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.attributes !== prevProps.attributes) {
            this.updateAttributes(this.props.attributes)
            this.setState({ attributes: this.props.attributes })
        }



    }
    componentDidMount() {
        const { attributes, chartOptions, queryInfo, series, } = this.state;
        this.setState({ height: this.container.offsetHeight, width: this.container.offsetWidth });
        this.updateChartSub('chart', 'height', this.container.offsetHeight)
        this.updateChartSub('chart', 'width', this.container.offsetWidth)





        this.updateAttributes(attributes)

        setTimeout(() => {
            this.setState({ loadingGraph: false })
        }, 8000);

    }



    updateAttributes = (attributes) => {
        const { chartOptions } = this.state;
        const qi = {
            interval: null,
            units: 'hours',
            start: moment(attributes.startDate, 'x').startOf("day").format('MM-DD-YYYY HH:mm'),
            iStart: moment(attributes.startDate, 'x').startOf("day").format('x'),
            end: attributes.endToday === true ? moment().endOf("day").format('MM-DD-YYYY HH:mm') : moment(attributes.endDate, 'x').endOf("day").format('MM-DD-YYYY HH:mm'),
            iEnd: attributes.endToday === true ? moment().endOf("day").format('x') : moment(attributes.endDate, 'x').endOf("day").format('x')
        };

        this.setState({ queryInfo: qi })



        const series = attributes.chartData;
        this.getRange(qi, series)




        chartOptions.colorAxis = {
            stops: [

                
                [0, attributes.color1 || 'rgba(254, 73, 44, 0.9)'],
                [0.33, attributes.color2 || 'rgba(255, 208, 0, 0.9)'],
                [0.66, attributes.color3 || 'rgba(255, 208, 0, 0.9)'],
                [1, attributes.color4 || 'rgba(97, 201, 164, 0.9)']
            ],
            min: attributes.colorAxisMin || 0,
            max: attributes.colorAxisMax || 10,
            startOnTick: false,
            endOnTick: false,
            labels: {
                format: `{value}${attributes.colorAxisLabel || ''}`
            }
        }
        chartOptions.yAxis = {
            title: {
                text: `${attributes.yUnits || null}`
            },

            startOnTick: true,
            endOnTick: true,
            minPadding: 0,
            maxPadding: 0,
            tickWidth: 1,
            min: attributes.yAxisMin || 0,
            max: attributes.yAxisMax || 10,
            reversed: true
        };
        chartOptions.tooltip = {
            formatter: function () {
                const date = moment(this.point.x, 'x').format('MM/DD/YYYY');
                const value = this.point.value;
                const depth = this.point.y;
                return `${date}, ${value} @ ${depth} ${attributes.yUnits !== undefined ? `(${attributes.yUnits})` : ''}`;
            }
        };







        this.setState({ chartOptions })

    }





    updateChart = (boo, val) => {
        const { chartOptions } = this.state;
        chartOptions[boo] = val;
        this.setState({ chartOptions })

    }
    updateChartSub = (boo, a, val) => {
        const { chartOptions } = this.state;
        chartOptions[boo][a] = val;
        this.setState({ chartOptions })

    }




    querySample = async (q) => {
        const { chartOptions, series, userInfo } = this.state;


        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'labDataNew'),
            where('locationid', '==', q.locationid), where('parameterid', '==', q.parameterid));
        const data = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            data.push({ ...JSON.parse(doc.data().string), dtt: doc.data().dtt })
        });


        q.data = data.map((d) => {
            const offset = q.offset || 0;
            const value = d.value + offset;

            return ([d.dtt, value])
        });

        q.data.sort((a, b,) => a[0] - b[0]);

        const index = chartOptions.series.map((s) => s.key).indexOf(q.key);
        q.name = q.label || null;

        q.tooltip.valueSuffix = q.suffix === undefined ? null : ` ${q.suffix}`;
        q.tooltip.valueDecimals = q.decimals || null;
        q.marker.lineColor = q.markerLine || null;
        q.marker.fillColor = q.markerFill || null;
        q.pointWidth = q.columnWidth || 10;
        q.dashStyle = q.dashed || null;
        q.lineWidth = q.lineWidth || 2;
        q.marker.enabled = q.dots || false;
        q.opacity = q.opacity || 1;

        chartOptions.series[index] = q;
        this.setState({ chartOptions })

    }
    queryData = (q, queryInfo, i) => {
        const { chartOptions, series, userInfo, contourData } = this.state;


        this.setState({ queryInfo })
        const proxyUrl = "https://mycorslake.herokuapp.com/";
        const queryString = i === 0 ? "widgetsFire8" : i === 1 ? "widgetsFire7" :
            i === 2 ? "widgetsFire6" :
                i === 3 ? "widgetsFire5" :
                    i === 4 ? "widgetsFire4" :
                        i === 5 ? "widgetsFire3" :
                            i === 6 ? "widgetsFire3" :
                                i === 8 ? "widgetsFire2" :
                                    i === 9 ? "widgetsFire1" :
                                        i === 10 ? "widgetsFire8" :
                                            i === 11 ? "widgetsFire7" :
                                                i === 12 ? "widgetsFire6" :
                                                    i === 13 ? "widgetsFire5" :
                                                        i === 14 ? "widgetsFire4" :
                                                            i === 15 ? "widgetsFire3" :
                                                                i === 16 ? "widgetsFire2" :
                                                                    i === 17 ? "widgetsFire1" :

                                                                        "widgetsFire5";



        axios.post(proxyUrl + `https://us-central1-aquasource3.cloudfunctions.net/${queryString}/sqlRead`, {
            raw: `SELECT time_bucket('${q.interval} hours', time) as "t"
            ,locationid, avg(timestamp) as "x", avg(value) as "y"
            FROM probe_data
            WHERE locationid = '${q.locationid}'and parameterid = '${q.parameterid}'
            and time >= '${queryInfo.start}' 
            and time <= '${queryInfo.end}' 
            GROUP BY "t", locationid
            ORDER BY "t" DESC;`
        })
            .then(async (response) => {

                response.data.map((d) => {
                    contourData[q.depth - 1].push(d.y)
                })
                if (q.parameterid === '3') {
                    q.data = response.data.map((d) => {
                        const offset = q.offset || 0;
                        const value = Number(convert(d.y.toFixed(4)).from('m').to(['m', 'ft'].includes(q.units) ? q.units : 'm')) + offset;
                        return ([d.x * 1000, q.depth, value])
                    });
                }
                if (q.parameterid === '1') {
                    q.data = response.data.map((d) => {
                        const offset = q.offset || 0;

                        const value = Number(convert(d.y.toFixed(4)).from('C').to(['C', 'F'].includes(q.units) ? q.units : 'C')) + offset;
                        return ([d.x * 1000, q.depth, value])
                    });
                }
                if (q.parameterid === '19') {
                    q.data = response.data.map((d) => {
                        const offset = q.offset || 0;

                        const value = Number(d.y) * 1000 + offset;
                        return ([d.x * 1000, q.depth, value])
                    });
                }
                if (!['1', '3', '19'].includes(q.parameterid)) {
                    q.data = response.data.map((d) => {
                        const offset = q.offset || 0;
                        const value = Number(d.y.toFixed(4)) + offset;
                        return ([d.x * 1000, q.depth, value])
                    });
                }

                q.data.sort((a, b,) => a[0] - b[0])

               
                const index = chartOptions.series.map((s) => s.key).indexOf(q.key);

                const item = {
                    type: 'heatmap',
                    boostThreshold: 100,
                    borderWidth: 0,
                    data: q.data,
                    nullColor: '#EFEFEF',
                    colsize: 24 * 36e5, // one day
                    tooltip: {
                        valueSuffix: q.suffix === undefined ? null : ` ${q.suffix}`,
                        valueDecimals: q.decimals || null,

                    },

                    turboThreshold: Number.MAX_VALUE // #3404, remove after 4.0.5 release
                }

                chartOptions.series.push(item);
                this.setState({ chartOptions })


            })
            .catch((error) => {
                // handle error
                console.log(error);


            })
            .then(function () {
                // always executed
            });

    }
    queryWeatherData = (q, queryInfo) => {



        const { chartOptions, series, userInfo } = this.state;


        this.setState({ queryInfo })
        const proxyUrl = "https://mycorslake.herokuapp.com/";
        axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgetsNew/sqlReadIso', {
            raw: `SELECT time_bucket('${q.interval} hours', time) as "t"
            ,account, avg(dt) as "x", avg(temp) as "y", avg(rain) as "r"
            FROM weather_data
            WHERE account = '${userInfo.currentAccount}'
            and time >= '${queryInfo.start}' 
            and time <= '${queryInfo.end}' 
            GROUP BY "t", account
            ORDER BY "t" DESC;`
        })
            .then(async (response) => {

                if (q.parameterid === '1') {
                    q.data = response.data.map((d) => {
                        const offset = q.offset || 0;
                        const value = Number(convert(d.y.toFixed(4)).from('C').to(['C', 'F'].includes(q.units) ? q.units : 'C')) + offset;
                        return ([d.x * 1000, value])
                    });
                }
                if (q.parameterid === '2') {
                    q.data = response.data.map((d) => {
                        const offset = q.offset || 0;

                        const value = Number(convert(d.r.toFixed(4)).from('mm').to('in')) + offset;
                        return ([d.x * 1000, value])
                    });
                }

                q.data.sort((a, b,) => a[0] - b[0])

                const index = chartOptions.series.map((s) => s.key).indexOf(q.key);
                q.name = q.label || null;

                q.tooltip.valueSuffix = q.suffix === undefined ? null : ` ${q.suffix}`;
                q.tooltip.valueDecimals = q.decimals || null;
                q.marker.lineColor = q.markerLine || null;
                q.marker.fillColor = q.markerFill || null;
                q.dashStyle = q.dashed || null;
                q.lineWidth = q.lineWidth || 2;
                q.marker.enabled = q.dots || false;
                q.opacity = q.opacity || 1;

                chartOptions.series[index] = q;
                this.setState({ chartOptions })


            })
            .catch((error) => {
                // handle error
                console.log(error);


            })
            .then(function () {
                // always executed
            });




    }
    getRange = (queryInfo, series) => {
        const { chartOptions, attributes, userInfo } = this.state;
        const proxyUrl = "https://mycorslake.herokuapp.com/";
        const proxyUrl2 = "https://gentle-hamlet-01056.herokuapp.com/";





        const updateAxises = attributes.axises.map((a) => {
            return (
                {
                    ...a,
                    min: a.customRange === false ? null : a.min,
                    max: a.customRange === false ? null : a.max,
                    id: a.axisId,
                    allowDecimals: false,
                    title: { text: a.label },
                    plotLines: []
                }
            )
        })
        const yPlots = attributes.references.filter((r) => r.horizontal === true);
        const xPlots = attributes.references.filter((r) => r.horizontal === false);

        const updatedY = yPlots.map((f) => {
            return ({
                ...f, value: f.yValue,
                label: { text: f.useAlias ? f.alias : f.title, textAlign: f.textAlign, verticalAlign: f.verticalAlign }
            })
        })
        const updatedX = xPlots.map((f) => {
            return ({
                ...f, value: Number(f.date),
                label: { text: f.useAlias ? f.alias : f.title, textAlign: f.textAlign, verticalAlign: f.verticalAlign }
            })
        })

        updatedY.map((y) => {
            const index = updateAxises.map((d) => d.id).indexOf(y.yAxis);
            updateAxises[index].plotLines.push(y)
        })
        updateAxises.plotLines = updatedY;


        this.setState({ chartOptions })




        series.map(async (q, i) => {


            if (q.dataType === 'probe') {

                const check = i % 2 === 0;
                const raw = check ? 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead' : 'https://us-central1-aquasource3.cloudfunctions.net/widgetsNew/sqlReadIso'
                const url1 = proxyUrl2 + raw;

                axios.post(url1, {
                    raw: `
                        (SELECT time
                            FROM probe_data
                            WHERE locationid = '${q.locationid}'and parameterid = '${q.parameterid}'
                            and time >= '${queryInfo.start}' and time <= '${queryInfo.end}' 
                            ORDER BY time DESC
                            LIMIT 1)
                            
                            UNION ALL
                            
                            (SELECT time
                            FROM probe_data
                            WHERE locationid = '${q.locationid}'and parameterid = '${q.parameterid}'
                            and time >= '${queryInfo.start}' and time <= '${queryInfo.end}'  
                            ORDER BY time ASC    
                            LIMIT 1);`
                })
                    .then(async (response) => {
                        // handle success
                        const data = response.data;

                        var now = moment(data[0].time);
                        var end = moment(data[1].time);
                        var duration = moment.duration(now.diff(end));
                        var hours = duration.asHours();
                        const seriesLength = 300;
                        const slice = await Math.floor(hours / seriesLength)
                        const sliceLength = slice === 0 ? 1 : slice

                        q.interval = sliceLength;



                        this.queryData(q, queryInfo, i)



                    })
                    .catch((error) => {
                        // handle error
                        console.log(error);
                    })
                    .then(function () {
                        // always executed
                    });
            }


            if (q.dataType === 'weather') {
                axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgetsNew/sqlReadIso', {
                    raw: `
                        (SELECT time
                            FROM weather_data
                            WHERE account = '${userInfo.currentAccount}'
                            and time >= '${queryInfo.start}' and time <= '${queryInfo.end}' 
                            ORDER BY time DESC
                            LIMIT 1)
                            
                            UNION ALL
                            
                            (SELECT time
                            FROM weather_data
                            WHERE account = '${userInfo.currentAccount}'
                            and time >= '${queryInfo.start}' and time <= '${queryInfo.end}'  
                            ORDER BY time ASC    
                            LIMIT 1);`
                })
                    .then(async (response) => {
                        // handle success
                        const data = response.data;

                        var now = moment(data[0].time);
                        var end = moment(data[1].time);
                        var duration = moment.duration(now.diff(end));
                        var hours = duration.asHours();
                        const seriesLength = 300;
                        const slice = await Math.floor(hours / seriesLength)
                        const sliceLength = slice === 0 ? 1 : slice

                        q.interval = sliceLength;



                        this.queryWeatherData(q, queryInfo)



                    })
                    .catch((error) => {
                        // handle error
                        console.log(error);
                    })
                    .then(function () {
                        // always executed
                    });
            }
            if (q.dataType === 'sample') {




                this.querySample(q)


            }





        })




    }

    render() {

        const { account, chartOptions, hoverData, loading, height, dimensions, contourData, loadingGraph, width } = this.state;

     

        const Height = () => {
            if (this.container) {
                if (this.container.offsetHeight !== height) {
                    this.updateChartSub('chart', 'height', this.container.offsetHeight)
                    this.updateChartSub('chart', 'width', this.container.offsetWidth)
                    this.setState({ height: this.container.offsetHeight, width: this.container.offsetWidth })
                }
                if (this.container.offsetWidth !== width) {
                    this.updateChartSub('chart', 'height', this.container.offsetHeight)
                    this.updateChartSub('chart', 'width', this.container.offsetWidth)
                    this.setState({ height: this.container.offsetHeight, width: this.container.offsetWidth })
                }

            }

        }


        var trace4 = {
            z: contourData,
            zsmooth: 'best',
            type: 'heatmap',

            connectgaps: true,
            xaxis: 'x4',
            yaxis: 'y4',
            colorscale: 'Jet',
            reversescale: true,
            contours: {
                ncontours: 5,

            }
        };

        var data = [trace4];

        var layout = {
            autosize: true,
            title: null,
            height,
            width,

            xaxis4: {


            },
            yaxis4: {
                autorange: 'reversed',

            }
        };

        var config = { responsive: true }
        return (
            <div ref={el => (this.container = el)} style={{ height: '100%', width: '100%', }}>
                <Height />
                 {/* {loadingGraph === false && (<Plot
                    data={data}
                    layout={layout}
                    config={config}
                />)} */}


              
                <HighchartsReact
                    constructorType={"chart"}
                    highcharts={Highcharts}
                    ref={"chart"}
                    options={chartOptions}
                    allowChartUpdate={true}
                    immutable={false}
                    updateArgs={[true, true, true]}
                />

            </div>



        );
    }
} 