import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { useEffect } from 'react';
import { queryParameters } from '../../../../firebase/config';
import { getFirestore } from 'firebase/firestore';
import ProbeParameters from '../../../../extra/probeParameters.json';
import WeatherParameters from '../../../../extra/weatherParameters.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Chip, Typography } from '@mui/material';




function sleep(delay = 0) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
}

export default function ParametersLoadMultiple({ userInfo, dataType, nodeid, widgetParameters, updateData }) {
    const [open, setOpen] = useState(false);
    const [PPArray, setPPArray] = useState([]);
    const [WPArray, setWPArray] = useState([]);
    const [options, setOptions] = useState([]);
    const [parameters, setParameters] = useState([])
    const [loadingSelect, setLoadingSelect] = useState(true);
    const loading = open && options.length === 0;


    useEffect(() => {
        getInfo()
    }, [])


    async function getInfo() {


        const parameters = await queryParameters(userInfo)
        parameters.sort((a, b) => a.name.localeCompare(b.name))
        setParameters(parameters)

        const PPArray = Object.values(ProbeParameters).map((p, i) => {
            return ({
                label: p,
                key: Object.keys(ProbeParameters)[i]
            })
        });

        setPPArray(PPArray)


        const WPArray = Object.values(WeatherParameters).map((p, i) => {
            return ({
                title: p,
                label: p,
                key: Object.keys(WeatherParameters)[i]
            })
        });
        setWPArray(WPArray)
        if (widgetParameters !== undefined && nodeid !== '' && nodeid !== undefined) {

            if (['node'].includes(dataType)) {
                const proxyUrl = "https://mycorslake.herokuapp.com/";
                axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgetsFire2/sqlRead', {
                    raw: `
                    SELECT DISTINCT parameterid FROM node_data_new2
                    WHERE nodeid = '${nodeid}';
                    `
                })
                    .then(async (response) => {
                        const data = response.data;
                        const filterData = data.filter((d) => d.parameterid !== 'undefined')
                        const options = filterData.map((d, i) => {

                            const label = [...PPArray, ...parameters].filter((p) => p.key === d.parameterid)[0]?.label || 'undefined'

                            return { title: label, key: d.parameterid }
                        })
                        //sort options by title
                        options.sort((a, b) => a.title.localeCompare(b.title))
                        await sleep(1e3); // For demo purposes.
                  
                        setOptions(options);
                        setLoadingSelect(false)

                    }).catch((e) => {
                        setLoadingSelect(false)
                    })
            }
            if (['probe'].includes(dataType)) {
                const proxyUrl = "https://mycorslake.herokuapp.com/";
                axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgetsFire2/sqlRead', {
                    raw: `
                    SELECT DISTINCT parameterid FROM probe_data
                    WHERE locationid = '${nodeid}';
                    `
                })
                    .then(async (response) => {
                        const data = response.data;
                        const filterData = data.filter((d) => d.parameterid !== 'undefined')
                        const options = filterData.map((d, i) => {
                            const label = [...PPArray, ...parameters].filter((p) => p.key === d.parameterid)[0]?.label || 'undefined'

                            return { title: label, key: d.parameterid }
                        })
                        //sort options by title
                        options.sort((a, b) => a.title.localeCompare(b.title))
                        await sleep(1e3); // For demo purposes.
                        setOptions(options);
                        setLoadingSelect(false)

                    }).catch((e) => {
                        setLoadingSelect(false)
                    })
            }

        }
        else {
            setLoadingSelect(false)
        }

    }




    if (loadingSelect) {
        return (

            <FontAwesomeIcon icon={faSpinner} size="xs" spin />


        )
    }

    else {
        if (dataType === 'station') {
            return (

                <Typography variant="caption" color="textSecondary" component="div" sx={{ display: 'inline' }}>
                    Tidal Fluctuation
                </Typography>


            )
        }
        else {

            return (
                <Autocomplete
                    display="inline"
                    id="asynchronous-demo"
                    size="small"
                    disablePortal
                    open={open}
                    onOpen={async () => {
                        setOpen(true);


                        if (['node'].includes(dataType)) {
                            const proxyUrl = "https://mycorslake.herokuapp.com/";
                            axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgetsFire2/sqlRead', {
                                raw: `
                    SELECT DISTINCT parameterid FROM node_data_new2
                    WHERE nodeid = '${nodeid}';
                    `
                            })
                                .then(async (response) => {
                                    const data = response.data;

                                    const filterData = data.filter((d) => d.parameterid !== 'undefined')
                                    const options = filterData.map((d, i) => {

                                        const label = [...PPArray, ...parameters].filter((p) => p.key === d.parameterid)[0]?.label || 'undefined'

                                        return { title: label, key: d.parameterid }
                                    })

                                    //sort options by title

                                    options.sort((a, b) => a.title.localeCompare(b.title))
                                    await sleep(1e3); // For demo purposes.

                                    setOptions(options);

                                })
                        }
                        if (['probe',].includes(dataType)) {
                            const proxyUrl = "https://mycorslake.herokuapp.com/";
                            axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgetsFire2/sqlRead', {
                                raw: `
                    SELECT DISTINCT parameterid FROM probe_data
                    WHERE locationid = '${nodeid}';
                    `
                            })
                                .then(async (response) => {
                                    const data = response.data;
                                    const filterData = data.filter((d) => d.parameterid !== 'undefined')
                                    const options = filterData.map((d, i) => {

                                        const label = [...PPArray, ...parameters].filter((p) => p.key === d.parameterid)[0]?.label || 'undefined'

                                        return { title: label, key: d.parameterid }
                                    })
                                    //sort options by title
                                    options.sort((a, b) => a.title.localeCompare(b.title))
                                    await sleep(1e3); // For demo purposes.
                                    setOptions(options);

                                })
                        }

                        if (['weather'].includes(dataType)) {
                            await sleep(1e3); // For demo purposes.
                            setOptions(WPArray);
                        }
                        if (['gauge'].includes(dataType)) {
                            await sleep(1e3); // For demo purposes.
                            setOptions(WPArray);
                        }



                    }}
                    onClose={() => {
                        setOpen(false);
                    }}
                    disableCloseOnSelect={true}
                    multiple
                    value={widgetParameters}
                    isOptionEqualToValue={(option, value) => option.title === value.title}
                    options={options}
                    onChange={(a, b) => {
                        console.log(b)
                       
                        updateData('parameters', b || null);
                    }}

                    getOptionLabel={(option) => option.title}
                    loading={loading}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip
                                variant="filled"
                                label={option.title}
                                {...getTagProps({ index })}
                                style={{
                                    color: 'white',
                                    backgroundColor: '#5f6e93',
                                    fontSize: '1em',  // Increase font size for bolder look
                                    fontWeight: 400,
                                    height: '25px',  // Adjust chip's height
                                    margin: '4px'
                                }}
                            />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder="Parameters"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
            );
        }
    }
}
