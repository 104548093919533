import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import React from 'react';

import axios, * as others from 'axios';
var convert = require('convert-units')
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/data')(Highcharts);




export default class WidgetProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            attributes: this.props.attributes,
            userInfo: this.props.userInfo,
            height: null,
            series: {},
            queryInfo: {},
            chartOptions: {
                credits: {
                    enabled: false
                },
                exporting: {
                    enabled: false
                },
                stockTools: {
                    gui: {
                        enabled: false
                    }
                },
                chart: {
                    resetZoomButton: {
                        position: {
                            align: 'left',
                            x: 20,
                            y: -20
                        }
                    },


                    zoomType: 'x',

                },
                title: {
                    text: null
                },
                plotOptions: {
                    series: {
                        stickyTracking: false
                    }
                },
                yAxis: {
                    min: 0,
                    reversed: true,
                    title: {
                        text: 'Depth'
                    },
                    plotLines: [{
                        value: 0,
                        width: 5,
                        color: '#afcdf4'
                    }]
                },
                xAxis: [
                    {
                        min: 0,
                        opposite: true,
                        id: "0",
                        plotLines: []
                    }

                ],
                legend: {},


                series: [],


            },


        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.attributes !== prevProps.attributes) {
            this.updateAttributes(this.props.attributes)
            this.setState({ attributes: this.props.attributes })
        }



    }
    componentDidMount() {
        const { attributes, chartOptions, queryInfo, series, } = this.state;
        this.setState({ height: this.container.offsetHeight, });
        this.updateChartSub('chart', 'height', this.container.offsetHeight)





        this.updateAttributes(attributes)
        this.addAxises(attributes)




    }

    updateAttributes = (attributes) => {
        const { chartOptions } = this.state;





        this.setState({ chartOptions })

    }

    addAxises = (attributes) => {
        const { chartOptions } = this.state;

        chartOptions.xAxis = attributes.axises;
        const newOptions = chartOptions.xAxis.map((c) => {
            return ({ ...c, plotLines: c.plotLines || [] })
        })
        chartOptions.xAxis = newOptions;

        this.setState({ chartOptions })

        this.addReferences(attributes, newOptions)
    }

    addReferences = (attributes, axises) => {
        const { chartOptions } = this.state;
        const yPlots = attributes.references.filter((r) => r.axisType === 'yAxis');
        const xPlots = attributes.references.filter((r) => r.axisType === 'xAxis');



        const updatedY = yPlots.map((f) => {
            return ({
                ...f, value: f.yValue, zIndex: 50,
                label: { text: f.useAlias ? f.alias : f.title, textAlign: f.textAlign, verticalAlign: f.verticalAlign, }
            })
        })

        xPlots.map((f) => {

            const index = chartOptions.xAxis.map((d) => `${d.axisId}`).indexOf(f.xAxis);

            if (index !== -1) {
                chartOptions.xAxis[index].plotLines.push({
                    ...f,
                    zIndex: 50,
                    value: f.xValue,
                    label: { text: f.useAlias ? f.alias : f.title, textAlign: f.textAlign, verticalAlign: f.verticalAlign, }
                })
            }

        })





        chartOptions.yAxis = {
            ...chartOptions.yAxis,
            plotLines: [...chartOptions.yAxis.plotLines, ...updatedY]
        }


        this.setState({ chartOptions })
        this.queryProfiles(attributes, axises)
    }



    queryProfiles = async (attributes, axises) => {
        const { chartOptions, queryInfo, series } = this.state;
        const { userInfo } = this.props;
        const db = getFirestore();
        const chartData = attributes.chartData || [];

        if (chartData.length > 0) {

            const axisKeys = axises.map((a) => a.axisId.toString());
            const keys = chartData.map((item) => item.profile !== undefined ? item.profile.key : '123')
            const queryData = query(collection(db, "clients", userInfo.currentCompany, 'profiles'), where('key', 'in', keys));
            const data = [];
            const dataList = [];
            const snap = await getDocs(queryData);
            snap.forEach((doc,) => {
                const index = chartData.map((item) => item.profile.key).indexOf(doc.data().key);
                const item = chartData[index];
                const newData = doc.data().data.map((d) => {
                    return (
                        [d.value, d.depth]
                    )
                })
                dataList.push({ data: newData })

                //sort new data by depth
                newData.sort((a, b) => {
                    return a[1] - b[1]
                })


                data.push({

                    ...doc.data(),
                    ...item,
                    index: item.index || 0,
                    data: [...newData],
                    xAxis: axisKeys.includes(item.xAxis.toString()) ? item.xAxis : '0',
                    tooltip: {
                        valueDecimals: 2,
                    },
                    name: item.label || ''

                })




            });









            chartOptions.series = dataList;
            this.setState({ chartOptions })
        }


    }





    updateChart = (boo, val) => {
        const { chartOptions } = this.state;
        chartOptions[boo] = val;
        this.setState({ chartOptions })

    }
    updateChartSub = (boo, a, val) => {
        const { chartOptions } = this.state;
        chartOptions[boo][a] = val;
        this.setState({ chartOptions })

    }







    render() {

        const { account, chartOptions, hoverData, loading, height, dimensions, attributes } = this.state;



        const Height = () => {
            if (this.container) {
                if (this.container.offsetHeight !== height) {
                    this.updateChartSub('chart', 'height', this.container.offsetHeight)
                    this.setState({ height: this.container.offsetHeight })
                }

            }

        }
        const axises = chartOptions.xAxis.map((item, i) => {


            return ({
                ...item, min: item.customRange === true ? item.min : null, max: item.customRange === true ? item.max : null,
                title: {
                    text: item.label || '',

                }, labels: {
                    style: {
                        fontWeight: 'bold',
                        color: item.color || 'black',
                    }
                }
            })
        })

        const yAxis = {
            ...chartOptions.yAxis,
            title: {
                text: attributes.depthLabel || 'Depth',

            },
            labels: {
                style: {
                    fontSize: attributes.yAxisFontSize || '12px',
                    color: attributes.yAxisLabelColor || 'black',

                    fontWeight: 'bold',
                }
            }

        }


        const options = {
            ...chartOptions,
            xAxis: axises,
            yAxis,
            tooltip: {
                formatter() {

                    const suffix = this.series.userOptions.suffix || '';
                    const valueDecimals = this.series.userOptions.decimals === 0 ? 0 : this.series.userOptions.decimals || 4;

                    return `<b>Depth: ${Number(this.point.y).toFixed(2)} FT</b><br/><b>${this.series.name}</b>: ${Number(this.point.x).toFixed(valueDecimals)} ${suffix}`;
                }
            }
        }


        return (
            <div ref={el => (this.container = el)} style={{ height: '100%', width: '100%', }}>
                <Height />
                <HighchartsReact
                    constructorType={"chart"}
                    highcharts={Highcharts}
                    ref={"chart"}
                    options={options}
                    allowChartUpdate={true}
                    immutable={false}

                />
            </div>

        );
    }
} 