import React, { useEffect, useState } from "react";


import { faBox, faBucket, faCheck, faFish, faFlask, faListCheck, faMagnifyingGlass, faPlus, faWheatAlt, faWrench, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, ButtonGroup, Divider, FormControl, Grid, IconButton, InputLabel, MenuItem, Modal, OutlinedInput, Select, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import FilesList from "../files/filesList";



const Bacteria = (
    {
        screenWidth,
        newItem,
        setNewItem,
        userInfo,
        openSnack,



    }

) => {
    const [isFocused, setIsFocused] = useState(false);

   
    const unitOptions = [
        "mg",
        "g",
        "kg",
        "oz",
        "lb",
        "fl-oz",
        "cup",
        "pnt",
        "qt",
        "gal",
        "ft3",
    ]



    return (
        <>




            <Col xs={24}  >
                <Typography variant="h6" component="div" color={'rgba(0,0,0,0.6)'} fontWeight={600} fontSize={16}>
                    Bacteria Information
                </Typography>
                <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h6" component="div" color={'rgba(0,0,0,0.8)'} fontWeight={500} fontSize={16}>
                            Brand/Manufacturer
                        </Typography>
                        <OutlinedInput
                            margin="dense"

                            style={{
                                borderRadius: 12,
                                marginTop: screenWidth === 'xs' ? 2 : 5,
                                marginBottom: 10,
                                width: '100%',
                                fontSize: 16,
                            }}
                            value={newItem.manufacturer}
                            onChange={(e) => {
                                setNewItem({ ...newItem, manufacturer: e.target.value })
                            }}
                            placeholder={'Brand/Manufacturer'}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h6" component="div" color={'rgba(0,0,0,0.8)'} fontWeight={500} fontSize={16}>
                            Default units
                        </Typography>

                        <Select
                            style={{
                                borderRadius: 12,
                                marginTop: screenWidth === 'xs' ? 2 : 5,
                                marginBottom: 10,
                                fontSize: 16,
                                width: '100%',
                                //no wrap
                                whiteSpace: 'nowrap',
                                display: 'inline-block',
                            }}
                            fullWidth
                            onFocus={() => setIsFocused(true)}
                            onBlur={() => setIsFocused(false)}
                            placeholder="Select"
                            variant="outlined"
                            value={newItem.defaultUnits}
                            onChange={(e) => {
                                const newNew = { ...newItem };
                                newNew.defaultUnits = e.target.value;
                                setNewItem(newNew);
                            }}

                            renderValue={(selectedValue) => {
                                const selectedItem = unitOptions.find(item => item === selectedValue);
                                if (!selectedItem) return null;

                                return (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <FontAwesomeIcon icon={selectedItem.icon} size="lg" style={{ marginRight: '10px' }} />
                                        <Typography variant="body1" component="span" color={'rgba(0,0,0,0.6)'} fontWeight={600} fontSize={16}>
                                            {selectedItem}
                                        </Typography>
                                    </div>
                                );
                            }}
                        >
                            {
                                unitOptions
                                    .map(units => {


                                        return (
                                            <MenuItem
                                                sx={{
                                                    p: 1
                                                }} key={units} value={units}>
                                                {newItem?.units === units ?
                                                    <FontAwesomeIcon icon={faCheck} size="lg" style={{ marginRight: '10px' }} />
                                                    : <div style={{ width: 24, height: 24, marginRight: 10 }}></div>}
                                                <Typography variant="h6" component="div" color={'rgba(0,0,0,0.6)'} fontWeight={600} fontSize={16}>
                                                    {units}
                                                </Typography>
                                            </MenuItem>
                                        )
                                    })}
                        </Select>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Typography variant="h6" component="div" color={'rgba(0,0,0,0.8)'} fontWeight={500} fontSize={16}>
                            Instructions
                        </Typography>
                        <OutlinedInput
                            margin="dense"

                            style={{
                                borderRadius: 12,
                                marginTop: screenWidth === 'xs' ? 2 : 5,
                                marginBottom: 10,
                                width: '100%',
                                fontSize: 16,
                            }}
                            multiline={true}
                            rows={4}
                            value={newItem.bacterialInstructions}
                            onChange={(e) => {
                                setNewItem({ ...newItem, bacterialInstructions: e.target.value })
                            }}
                            placeholder={'Instructions'}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Typography variant="h6" component="div" color={'rgba(0,0,0,0.8)'} fontWeight={500} fontSize={16}>
                            Additional information
                        </Typography>
                        <OutlinedInput
                            margin="dense"

                            style={{
                                borderRadius: 12,
                                marginTop: screenWidth === 'xs' ? 2 : 5,
                                marginBottom: 10,
                                width: '100%',
                                fontSize: 16,
                            }}
                            multiline={true}
                            rows={4}
                            value={newItem.bacteriaAdditional}
                            onChange={(e) => {
                                setNewItem({ ...newItem, bacteriaAdditional: e.target.value })
                            }}
                            placeholder={'Additional Information'}
                        />
                    </Grid>
                    
                </Grid>

            </Col>




        </>



    );
};

export default Bacteria;
