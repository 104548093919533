import React, { useEffect, useState } from "react";


import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import DataTableHeader from "./dataTableHeader";
import WaterQualityUploadHome from "./waterQualityUpload/waterQualityUploadHome";

const WaterQualityHomeSpeedTest = ({
    screenWidth,
    userInfo,
    account,
    openSnack
}) => {


    const [activeTab, setActiveTab] = useState('data');
    const [loading, setLoading] = useState(true);
    const [preferences, setPreferences] = useState({});
    const [companyPreferences, setCompanyPreferences] = useState({});


    useEffect(() => {
        window.scrollTo(0, 0)
        getInfo()
        console.log(userInfo)

    }, [])



    async function getInfo() {

        setTimeout(() => {
            setLoading(false)
        }, 1500);

    }

    const props = {
        screenWidth,
        userInfo,
        account,
        openSnack,
        preferences,
        setPreferences,
        companyPreferences,
        setCompanyPreferences,
        activeTab,
        setActiveTab,
        
    }
 
    


    if (loading) {
        return (
            <Row style={{ height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
                {/* Using the full height of the viewport to center content */}
                <Col span={24} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

                    {/* FontAwesome Spinner */}
                    <FontAwesomeIcon icon={faSpinner} spin size="3x" />

                    <Typography variant="h6" style={{ marginTop: 20 }}>
                        Loading your data dashboard...
                    </Typography>

                </Col>
            </Row>
        )
    }






    else {
        return (
            <Row style={{}} >
                <Col span={24} style={{}}>
                    <Row align="middle">
                        <Col xs={16} sm={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>
                            <Typography variant="h4" fontWeight={600} fontSize={30} color="black">Water quality data</Typography>
                        </Col>

                        <Col span={24}>
                            <Divider />
                        </Col>

                        <Col span={24}>
                            <DataTableHeader {...props}

                            />
                        </Col>


                        {activeTab === 'data' && screenWidth !== 'xs' && (
                            <Col span={24}>
                                Data Table
                            </Col>
                        )}
                        {activeTab === 'data' && screenWidth === 'xs' && (
                            <Col span={24}>
                                Mobile Data Table
                            </Col>
                        )}
                        {activeTab === 'upload' && (
                            <Col span={24} >
                                <WaterQualityUploadHome {...props} />
                            </Col>
                        )}








                    </Row>
                </Col>

            </Row >
        );
    }

};

export default WaterQualityHomeSpeedTest;
