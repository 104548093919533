

import { Button, Checkbox, Divider, OutlinedInput, Typography, Select, MenuItem, CircularProgress, Paper, IconButton, Tooltip } from '@mui/material';
import { Col, Row } from 'antd';
import React, { useState, useEffect, useRef, useMemo } from 'react';

import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faCircleArrowDown, faCircleArrowUp } from '@fortawesome/free-solid-svg-icons';
var convert = require('convert-units')




const WidgetReadingHeader = ({ attributes, chartOptions, historyData, units, paramterid }) => {
    const rowRef = useRef(null);
    const [widgetWidth, setWidgetWidth] = useState(null);

    useEffect(() => {
        if (historyData !== null) {
            const resizeObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    if (entry.target === rowRef.current) {
                        const width = entry.contentRect.width;
                        setWidgetWidth(width);
                    }
                }
            });

            if (rowRef.current) {
                resizeObserver.observe(rowRef.current);
            }

            return () => {
                resizeObserver.disconnect();
            };
        }

    }, []);

    const breakpoint = 240;


    function calculatePercentageChange(currentValue, averageValue) {
        let percentageChange = ((currentValue - averageValue) / averageValue) * 100;
        return percentageChange.toFixed(2); // returns the value to 2 decimal places
    }



    let chartOptionsValue = chartOptions.value; // substitute with your actual value
    let avgValue = historyData?.data[0]?.avg_value;
    let maxValue = historyData?.data[0]?.max_value;
    let minValue = historyData?.data[0]?.min_value;

    if (attributes.parameterid === '3') {
        avgValue = Number(convert(avgValue?.toFixed(4)).from('m').to(['m', 'ft'].includes(attributes.units) ? attributes.units : 'm')) + Number(attributes.offset || 0);
        maxValue = Number(convert(maxValue?.toFixed(4)).from('m').to(['m', 'ft'].includes(attributes.units) ? attributes.units : 'm')) + Number(attributes.offset || 0);
        minValue = Number(convert(minValue?.toFixed(4)).from('m').to(['m', 'ft'].includes(attributes.units) ? attributes.units : 'm')) + Number(attributes.offset || 0);
    } else if (attributes.parameterid === '1') {

        avgValue = Number(convert(avgValue?.toFixed(4)).from('C').to(['C', 'F'].includes(attributes.units) ? attributes.units : 'C')) + Number(attributes.offset || 0);
        maxValue = Number(convert(maxValue?.toFixed(4)).from('C').to(['C', 'F'].includes(attributes.units) ? attributes.units : 'C'));
        minValue = Number(convert(minValue?.toFixed(4)).from('C').to(['C', 'F'].includes(attributes.units) ? attributes.units : 'C'));

    } else if (attributes.parameterid === '19') {
        avgValue = Number(avgValue?.toFixed(4)) * 1000 + Number(attributes.offset || 0);
        maxValue = Number(maxValue?.toFixed(4)) * 1000 + Number(attributes.offset || 0);
        minValue = Number(minValue?.toFixed(4)) * 1000 + Number(attributes.offset || 0);
    }

    if (chartOptions?.dataSourceType === 'readingWithOffset') {
        avgValue = avgValue + chartOptions?.readingOffset;
        maxValue = maxValue + chartOptions?.readingOffset;
        minValue = minValue + chartOptions?.readingOffset;
    }

    let percentChange = calculatePercentageChange(chartOptionsValue, avgValue);





    return (
        <Col span={24}>
            <Row ref={rowRef} style={{ display: 'flex' }}>
                <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <Typography display="inline" variant='body1' fontSize={32} fontWeight={800} color="black">
                        {Number(Number(chartOptions.value).toFixed(attributes.decimals === undefined ? 2 : attributes.decimals < 0 ? 0 : attributes.decimals)).toLocaleString()}
                        <Typography fontWeight={200} style={{ marginLeft: 2 }} display="inline" variant="h6">{attributes.units || ''}</Typography>
                    </Typography>

                </div>
                {historyData !== null && (
                    <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <Tooltip title="% change to weekly average" placement="bottom">
                            <Typography display="inline" variant='h1' fontSize={18} fontWeight={600}>
                                <FontAwesomeIcon icon={isNaN(percentChange) ? faCircle : percentChange > 0 ? faCircleArrowUp : faCircleArrowDown}
                                    size="lg"
                                    style={{ color: isNaN(percentChange) ? 'gray' : percentChange > 0 ? '#40d4928c' : '#d440409c', marginRight: 2 }}
                                />
                                <Typography display="inline" variant='h1' fontSize={16} fontWeight={600} style={{ color: isNaN(percentChange) ? 'gray' : percentChange > 0 ? '#40d492' : '#d44040' }}>
                                    {isNaN(percentChange) ? 0 : percentChange > 0 ? percentChange : percentChange * -1}%
                                </Typography>
                            </Typography>
                        </Tooltip>

                    </div>
                )}
                <div style={{
                    flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center',
                    alignItems: widgetWidth !== null && widgetWidth < breakpoint ? 'flex-start' : 'flex-end',
                }}>

                    {widgetWidth !== null && widgetWidth > breakpoint ?
                        <div style={{ alignItems: 'flex-end', textAlign: 'right' }}>
                            <Typography variant='caption' display={'block'} fontSize={17} fontWeight={400} style={{ whiteSpace: 'nowrap', alignItems: 'flex-end' }}>
                                {chartOptions.time !== undefined ?
                                    moment(chartOptions.time, 'X').add(attributes.timeOffset || 0, 'hours').tz("America/Los_Angeles").format('h:mm A') : ''}
                            </Typography>
                            <Typography variant='caption' display={'block'} fontSize={14} fontWeight={600} style={{}}>
                                {chartOptions.time !== undefined ?
                                    moment(chartOptions.time, 'X').add(attributes.timeOffset || 0, 'hours').tz("America/Los_Angeles").format('MMM D, YYYY') : ''}
                            </Typography>
                        </div>
                        :
                        <Typography variant='caption' display={'inline'} fontSize={17} fontWeight={400} style={{ whiteSpace: 'nowrap', alignItems: 'flex-end' }}>
                            {chartOptions.time !== undefined ?
                                moment(chartOptions.time, 'X').add(attributes.timeOffset || 0, 'hours').tz("America/Los_Angeles").format('h:mm A') : ''}
                            <Typography variant='caption' display={'inline'} fontSize={14} fontWeight={600} style={{ marginLeft: 2 }}>
                                {chartOptions.time !== undefined ?
                                    moment(chartOptions.time, 'X').add(attributes.timeOffset || 0, 'hours').tz("America/Los_Angeles").format('MMM D, YYYY') : ''}
                            </Typography>
                        </Typography>
                    }


                </div>
                {historyData !== null && (<div style={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100%', paddingBottom: 0, marginBottom: 0 }}>
                    <Typography display="inline" variant='h6' color={'black'} fontSize={12} fontWeight={600}>
                        Weekly Data| Avg: {Number(Number(avgValue).toFixed(attributes.decimals === undefined ? 2 : attributes.decimals < 0 ? 0 : attributes.decimals)).toLocaleString()}
                        <Typography fontWeight={200} style={{ marginLeft: 2 }} display="inline" variant='h6' color={'gray'} fontSize={9}>{attributes.units || '  '}  </Typography>
                        Max: {Number(Number(maxValue).toFixed(attributes.decimals === undefined ? 2 : attributes.decimals < 0 ? 0 : attributes.decimals)).toLocaleString()}
                        <Typography fontWeight={200} style={{ marginLeft: 2 }} display="inline" variant='h6' color={'gray'} fontSize={9}>{attributes.units || '  '} </Typography>
                        Min: {Number(Number(minValue).toFixed(attributes.decimals === undefined ? 2 : attributes.decimals < 0 ? 0 : attributes.decimals)).toLocaleString()}
                        <Typography fontWeight={200} style={{ marginLeft: 2 }} display="inline" variant='h6' color={'gray'} fontSize={9}>{attributes.units || '  '}  </Typography>
                    </Typography>

                </div>
                )}

            </Row>
        </Col>
    );
};

export default WidgetReadingHeader;