import { faGear } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Autocomplete, Box, Collapse, Divider, IconButton, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material';
import Tab from '@mui/material/Tab';
import { Col, Popconfirm, Row } from 'antd';
import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';
import React from 'react';
import ProbeParameters from '../../../../extra/probeParameters.json';
import Units from '../../../../extra/units.json';
import WeatherParameters from '../../../../extra/weatherParameters.json';
import { queryParameters } from '../../../../firebase/config';
import DataColor from './dataColor';
import ParametersLoad from './parametersLoad';
import { fullMobile } from './util';

var randomColor = require('randomcolor');
export default class GaugeSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            attributes: this.props.attributes,
            currentWidgetKey: this.props.currentWidgetKey,
            locations: [],
            references: [],
            parameters: [],
            sites: [],
            activeTab: 'data',
            readData: this.props.readData,
            label: '',
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
        if (this.props.readData !== prevProps.readData) {
            this.setState({ readData: this.props.readData })
            if (this.props.readData === true) {
                this.props.updateAttributes()
                this.setState({ readData: false })
            }
        }

    }
    componentDidMount() {
        this.getLocations()
        this.getParameters();
    }

    getLocations = async () => {
        const { userInfo } = this.state;

        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'probes'), where('account', '==', userInfo.currentAccount), where('archived', '==', false));
        const queryDataSites = query(collection(db, "clients", userInfo.currentCompany, 'sampleSites'), where('account', '==', userInfo.currentAccount),);
        const queryStations = query(collection(db, "clients", userInfo.currentCompany, 'stations'), where('account', '==', userInfo.currentAccount),);
        const locations = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            locations.push({ ...doc.data(), label: doc.data().name, type: 'probe' })
        });
        const snapSites = await getDocs(queryDataSites);
        snapSites.forEach((doc) => {
            locations.push({ ...doc.data(), label: doc.data().name, type: 'sample' })
        });
        const snapNodes = await getDocs(queryStations);
        snapNodes.forEach((doc) => {
            if (doc.data().archived !== true && doc.data().children.length > 0) {
                const children = doc.data().children;
                //for each child create a node and add to locations
                children.forEach((child) => {
                    locations.push({ ...child, stationLabel: doc.data().name, label: child.name, type: 'node', stationType: child.type })
                })

            }

        });

        locations.sort((a, b) => a.name.localeCompare(b.name))
        this.setState({ locations, })

    }


    updateWidget = (boo, val) => {
        const { attributes, currentWidgetKey } = this.state;
        attributes[boo] = val;
        this.setState({ attributes })


    }

    updateData = (index, boo, val) => {
        const { attributes } = this.state;
        attributes.chartData[index][boo] = val;
        this.updateWidget(attributes)

    }

    getParameters = async () => {
        const { userInfo, type } = this.state;
        const db = getFirestore();
        const parameters = await queryParameters(userInfo)
        parameters.sort((a, b) => a.name.localeCompare(b.name))
        this.setState({ parameters, })


    }



    handleCheck = (boo, val) => {
        // Clears running timer and starts a new one each time the user types
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.toggleCheck(boo, val);
        }, 1000);
    }

    toggleCheck = async (boo, val) => {
        this.updateWidget(boo, val)



    }




    render() {

        const { account, attributes, screenWidth, locations, expanded, activeTab, references, currentWidgetKey, parameters, userInfo } = this.state;





        const input = attributes.decimals === 0 || attributes.decimals ? attributes.decimals : '';


        return (











            <Row justify="center" align="middle">
                <Col span={24}>
                    <Typography fontSize={16} variant='subtitle2'>Percent Gauge Settings</Typography>
                </Col>
                <Col span={24}>
                    <TabContext value={activeTab}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={(e, tab) => this.setState({ activeTab: tab })} aria-label="lab API tabs example">
                                <Tab style={{ textTransform: 'none' }} label="Data" value="data" />

                            </TabList>
                        </Box>

                        <TabPanel value="data">
                            <Col xs={24} style={{ paddingTop: 4 }} >
                                <Row align="middle" gutter={[12, 12]}>



                                    <Col span={20}>
                                        <Typography fontSize={14} fontWeight={500} variant="body1">Data</Typography>
                                    </Col>
                                    <Col {...fullMobile(2, 4)} >
                                        <IconButton onClick={() => {
                                            this.updateWidget('advancedSettings', attributes.advancedSettings ? !attributes.advancedSettings : true)
                                        }}>
                                            <FontAwesomeIcon fontSize={18} icon={faGear} color="#9ec1c9" />
                                        </IconButton>
                                    </Col>
                                    <Col {...fullMobile(8, 12)}>
                                        <Row>
                                            <Col span={24}>
                                                <Typography fontSize={13} variant="body1">Data Type<span style={{ color: 'red' }}>*</span></Typography>
                                            </Col>
                                            <Col span={24}>
                                                <Select size="small" fullWidth
                                                    notched={false}
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={attributes.dataType || ''}
                                                    label="Layer"
                                                    onChange={(e) => {
                                                        this.updateWidget('dataType', e.target.value)
                                                    }}

                                                >

                                                    <MenuItem value={'node'}>Node/Station</MenuItem>
                                                    <MenuItem value={'weather'}>Weather</MenuItem>

                                                </Select>
                                            </Col>
                                        </Row>


                                    </Col>


                                    <Col {...fullMobile(8, 12)}>
                                        <Row>
                                            <Col span={24}>
                                                <Typography fontSize={13} variant="body1">Location<span style={{ color: 'red' }}>*</span></Typography>
                                            </Col>
                                            <Col span={24}>
                                                <Autocomplete
                                                    disableClearable
                                                    size="small"
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    renderGroup={(params) => (
                                                        <Box key={params.key}>
                                                            <Typography fontWeight={800} fontSize={14} p={1}>
                                                                {params.group}
                                                            </Typography>
                                                            {params.children}
                                                        </Box>
                                                    )}
                                                    groupBy={(option) => option.stationLabel || ''}
                                                    options={attributes.dataType === undefined ? [] : locations.filter((f) => f.type === attributes.dataType)}
                                                    value={attributes.location || ''}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            placeholder="Source"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                    onChange={(a, b) => {

                                                        this.updateWidget('locationid', b.id || b.key)
                                                        this.updateWidget('location', b)
                                                    }}
                                                    InputProps={{ style: { fontSize: 6 } }}


                                                />

                                            </Col>
                                        </Row>


                                    </Col>
                                    <Col {...fullMobile(8, 12)}>
                                        <Row>
                                            <Col span={24}>
                                                <Typography fontSize={13} variant="body1">Parameter<span style={{ color: 'red' }}>*</span></Typography>
                                            </Col>
                                            <Col span={24}>
                                                <ParametersLoad userInfo={userInfo} nodeid={attributes.locationid} dataType={attributes.dataType} parameterid={attributes.parameterid}
                                                    updateData={(boo, val) => {
                                                        this.updateWidget(boo, val)

                                                    }} />

                                            </Col>
                                        </Row>


                                    </Col>


                                    <Col span={24}>
                                        <Row align="middle" gutter={[8, 8]} style={{ paddingBottom: 5 }} key={attributes.key}>


                                            <Col {...fullMobile(8, 12)}>
                                                <Row>
                                                    <Col span={24}>
                                                        <Typography fontSize={13} variant="body1">Label</Typography>
                                                    </Col>
                                                    <Col span={24}>
                                                        <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                            defaultValue={attributes.label || ''}
                                                            onChange={(e) => {
                                                                this.handleCheck('label', e.target.value)
                                                            }} />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col {...fullMobile(8, 12)}>
                                                <Row>
                                                    <Col span={24}>
                                                        <Typography fontSize={13} variant="body1">Units</Typography>
                                                    </Col>
                                                    <Col span={24}>
                                                        <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                            defaultValue={attributes.suffix || ''}
                                                            onChange={(e) => {
                                                                this.handleCheck('suffix', e.target.value)
                                                            }}


                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>





                                            <Col {...fullMobile(3, 6)}>
                                                <Row>
                                                    <Col span={24}>
                                                        <Typography fontSize={13} variant="body1">Color</Typography>
                                                    </Col>
                                                    <Col span={24}>
                                                        <DataColor color={attributes.color} updateData={(boo, val) => this.updateWidget(boo, val)} />
                                                    </Col>
                                                </Row>


                                            </Col>
                                            <Col {...fullMobile(8, 12)}>
                                                <Row>
                                                    <Col span={24} >
                                                        <Typography fontSize={13} variant="body1">Data Source</Typography>
                                                    </Col>
                                                    <Col span={24} >
                                                        <Select size="small" fullWidth
                                                            notched={false}
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={attributes?.dataSourceType || 'reading'}
                                                            style={{
                                                                backgroundColor: 'white'
                                                            }}
                                                            label="Dash"
                                                            onChange={(e) => {
                                                                this.handleCheck('dataSourceType', e.target.value)
                                                            }}

                                                        >
                                                            <MenuItem value={'reading'}>Reading</MenuItem>
                                                            <MenuItem value={'readingWithOffset'}>Reading w/ offset</MenuItem>
                                                            <MenuItem value={'offset'}>Offset only</MenuItem>



                                                        </Select>
                                                    </Col>
                                                </Row>
                                            </Col>





                                            <Col {...fullMobile(22)}>
                                                <Collapse in={attributes.advancedSettings || false} timeout="auto" unmountOnExit>
                                                    <Row align="middle" gutter={[8, 8]}>
                                                        <Col span={24} style={{ paddingTop: 8 }}>
                                                            <Typography fontSize={14} fontWeight={500} variant="body1">Advanced</Typography>
                                                        </Col>

                                                        <Col {...fullMobile(8, 12)}>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <Typography fontSize={13} variant="body1">Unit Conversion</Typography>
                                                                </Col>
                                                                <Col span={24}>
                                                                    <Select size="small" fullWidth
                                                                        notched={false}
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        value={attributes.units || ''}
                                                                        label="Units"
                                                                        onChange={(e) => {
                                                                            this.handleCheck('units', e.target.value)

                                                                        }}

                                                                    >

                                                                        {attributes.parameterid === '1' && (
                                                                            ['C', 'F'].map((f) => {
                                                                                return (
                                                                                    <MenuItem value={f}>{f}</MenuItem>
                                                                                )
                                                                            })
                                                                        )}
                                                                        {attributes.parameterid === '3' && (
                                                                            ['m', 'ft'].map((f) => {
                                                                                return (
                                                                                    <MenuItem value={f}>{f}</MenuItem>
                                                                                )
                                                                            })
                                                                        )}



                                                                    </Select>
                                                                </Col>
                                                            </Row>
                                                        </Col>




                                                        <Col {...fullMobile(8, 12)}>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <Typography fontSize={13} variant="body1">Dec. Places</Typography>
                                                                </Col>
                                                                <Col span={24}>
                                                                    <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                                        id={`123value`}
                                                                        value={input}
                                                                        type="number"
                                                                        onChange={(e) => {

                                                                            this.handleCheck('decimals', e.target.value === "" ? "" : Number(e.target.value))
                                                                        }} />
                                                                </Col>
                                                            </Row>
                                                        </Col>

                                                        <Col {...fullMobile(8, 12)}>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <Typography fontSize={13} variant="body1">Offset</Typography>
                                                                </Col>
                                                                <Col span={24}>
                                                                    <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                                        value={attributes.offset || ''}
                                                                        type="number"
                                                                        onChange={(e) => {
                                                                            this.handleCheck('offset', e.target.value === "" ? "" : Number(e.target.value))
                                                                        }} />
                                                                </Col>
                                                            </Row>
                                                        </Col>







                                                    </Row>
                                                </Collapse>
                                            </Col>

                                            <Col span={24}>
                                                <Divider />
                                            </Col>


                                        </Row>
                                    </Col>
                                </Row>
                            </Col ></TabPanel>

                    </TabContext>
                </Col>







            </Row >
        );
    }
} 