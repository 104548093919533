import { collection, doc, getCountFromServer, getDocs, getFirestore, query, setDoc, where, writeBatch } from "firebase/firestore"
import uuid from "react-uuid";
import Pica from 'pica';
import { getStorage, ref, getMetadata, getDownloadURL, uploadBytes, uploadBytesResumable } from "firebase/storage";
import exifr from 'exifr';
import moment from 'moment-timezone';





export async function fetchFiles(userInfo, currentParentFolder, setFetchingFiles, openSnack, item_id) {
    try {
        const db = getFirestore();
        const ref = collection(db, "clients", userInfo.currentCompany, 'files_new');

        let queryRef;
        if (currentParentFolder) {
            queryRef = query(ref, where("item_id", "==", item_id), where("status", "!=", 'deleted'));
        } else {
            queryRef = query(ref, where("item_id", "==", item_id), where("status", "!=", 'deleted'));
        }

        const snap = await getDocs(queryRef);
        const data = snap.docs.map((doc) => ({ ...doc.data(), file_id: doc.id }));

        console.log(data)

        openSnack('success', 'Files retrieved successfully');
        setFetchingFiles(false);
        return data;

    } catch (error) {
        openSnack('error', 'Error retrieving files');
        setFetchingFiles(false);
        return [];
    }
};

