import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Checkbox, Divider, Fab, IconButton, Input, InputAdornment, OutlinedInput, Paper, Typography } from '@mui/material';
import { Col, Modal, Popconfirm, Row } from 'antd';
import { collection, deleteDoc, doc, getDocs, getFirestore, query, setDoc, updateDoc, where } from 'firebase/firestore';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import "../../extra/hoverStyle.css";

export default class AccountDistribution extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            data: [],
            staff: [],
            customDistribution: [],
            filteredData: [],
            filters: [],
            searchText: '',
            loading: true,
            type: this.props.type,
            openModal: false,
            currentItem: {}
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0)
        this.queryUsers();
        this.queryStaff();
    }

    queryUsers = async () => {
        const { userInfo, type, customDistribution } = this.state;
        const db = getFirestore()
        const queryStaff = query(collection(db, "users",), where('accounts', 'array-contains', userInfo.currentAccount));
        const data = [];
        const snapStaff = await getDocs(queryStaff);
        snapStaff.forEach((doc) => {
            data.push({
                id: doc.data().id,
                key: doc.data().id,
                invited: doc.data().invited || false,
                signedUp: doc.data().signedUp || false,
                email: doc.data().email,
                label: `${doc.data().firstName || ""} ${doc.data().lastName || ""}`,
            })

        });

        const queryCustom = query(collection(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'customDistribution',));
        const snapCustom = await getDocs(queryCustom);
        snapCustom.forEach((doc) => {
            data.push(doc.data())

        });


        console.log(data);
        this.setState({ data, loading: false })

        this.filterData(data, '', [])
    }
    queryStaff = async () => {
        const { userInfo, type } = this.state;
        const db = getFirestore()
        const queryStaff = query(collection(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'staff',));
        const staff = [];
        const snapStaff = await getDocs(queryStaff);
        snapStaff.forEach((doc) => {
            staff.push({ ...doc.data(), customDistribution: false })

        });
        console.log(staff);
        this.setState({ staff })
    }


    filterData = (data, searchText, filters) => {
        const filteredData = data.filter(d => d.label.match(new RegExp(searchText, "i")))
        this.setState({ filteredData })

    }

    updateStaff = async (key, boo, val) => {
        const { userInfo, type, staff } = this.state;

        const index = staff.map((s) => s.key).indexOf(key);
        staff[index][boo] = val;
        console.log(staff);
        this.setState({ staff })
        const db = getFirestore()
        const queryStaff = doc(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'staff', key);
        await updateDoc(queryStaff, {
            [boo]: val
        }).then(() => {
            this.props.openSnack('success', 'Staff Updated')



        })
    }
    updateCustom = async (key, boo, val) => {
        const { userInfo, type, data } = this.state;

        const index = data.map((s) => s.key).indexOf(key);
        data[index][boo] = val;
        this.setState({ data })
        const db = getFirestore()
        const queryStaff = doc(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'customDistribution', key);
        await updateDoc(queryStaff, {
            [boo]: val
        }).then(() => {
            this.props.openSnack('success', 'Staff Updated')



        })
    }

    updateCurrentItem = (boo, val) => {
        const { currentItem } = this.state;
        currentItem[boo] = val;
        this.setState({ currentItem })
    }

    render() {

        const { userInfo, account, data, filteredData, loading, searchText, filters, type, staff, openModal, currentItem, customDistribution, screenWidth } = this.state;
        const db = getFirestore();
        const CreateItemButton = () => {
            let navigate = useNavigate();

            return (
                <Fab style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="medium"
                    onClick={async () => {
                        this.setState({ openModal: true, currentItem: { tempKey: uuid(), customDistribution: true } })
                    }}>
                    <AddIcon />
                </Fab>
            )
        }
        const CreateCustom = () => {
            return (
                <Button size="small" style={{ padding: 10, borderRadius: 4, textTransform: 'none', fontSize: 14, }}
                    variant='contained' color="primary"
                    onClick={async () => {
                        const { currentItem } = this.state;
                        const key = currentItem.key || currentItem.tempKey;
                        currentItem.label = currentItem.label || '';
                        currentItem.email = currentItem.email || '';
                        currentItem.company = userInfo.currentCompany;
                        const docRef = doc(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'customDistribution', key);


                        const checks = [
                            currentItem.label.length === 0 ? false : true,
                            currentItem.email.length === 0 ? false : true,
                        ]

                        if (currentItem.label.length === 0) {
                            this.props.openSnack('error', "Name required!")
                        }
                        if (currentItem.email.length === 0) {
                            this.props.openSnack('error', "Email required!")
                        }

                        if (!checks.includes(false)) {

                            if (currentItem.key) {
                                await updateDoc(docRef, currentItem).then(() => {
                                    const index = filteredData.map((d) => d.key).indexOf(currentItem.key)
                                    filteredData[index] = currentItem;
                                    this.setState({ filteredData: [...filteredData], openModal: false, currentItem: {}, })
                                }).then(() => {
                                    this.props.openSnack('success', currentItem.key ? 'Distribution updated!' : "Distribution created!")
                                })
                            }
                            else {
                                await setDoc(docRef, {
                                    ...currentItem,
                                    key: currentItem.tempKey
                                }).then(() => {
                                    data.push({ ...currentItem, key: currentItem.tempKey })
                                    filteredData.push({ ...currentItem, key: currentItem.tempKey })
                                    this.setState({ data: [...data], filteredData: [...filteredData], openModal: false, currentItem: {}, })
                                }).then(() => {
                                    this.props.openSnack('success', currentItem.key ? 'Distribution updated!' : "Distribution created!")
                                })
                            }
                        }




                    }}>{currentItem.key ? 'Update Distribution' : "Create Distribution"}</Button>
            )
        }

        const DeleteItem = ({ item }) => {
            let navigate = useNavigate();
            return (
                <Popconfirm
                    title="Are you sure to delete this distribution?"
                    onConfirm={async () => {
                        await deleteDoc(doc(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'customDistribution', item.key)).then(() => {
                            this.props.openSnack('success', 'Staff removed.')
                            const newData = data.filter((f) => f.key !== item.key)
                            const newFiltered = filteredData.filter((f) => f.key !== item.key)
                            this.setState({ data: newData, filteredData: newFiltered })
                        })


                    }}
                    okText="Yes"
                    cancelText="No"
                >
                    <IconButton style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="small"
                    >
                        <DeleteForeverIcon />
                    </IconButton>
                </Popconfirm>


            )
        }

        const EditItem = ({ item }) => {
            let navigate = useNavigate();
            return (
                <IconButton style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="small"
                    onClick={async () => {
                        const itemRef = doc(db, "users", userInfo.id, 'currentItems', 'currentPS');
                        this.setState({ openModal: true, currentItem: item })
                    }}>
                    <EditIcon />
                </IconButton>
            )
        }

        const RowItem = ({ item }) => {
            let navigate = useNavigate();
            const index = staff.map((s) => s.key).indexOf(item.id);
            const member = staff[index];
            console.log(item);
            return (
                <Col span={24} style={{ cursor: 'pointer', padding: 8 }} className="rowHover"
                >
                    <Row gutter={[8, 8]} align="middle" >

                        <Col xs={20} sm={20} onClick={async () => {
                        }}>

                            <Row align='middle' gutter={[8, 8]}>
                                <Col span={16}>
                                    <Row>
                                        <Col span={24}>
                                            <Typography variant='h6'>{item.label}</Typography>
                                        </Col>
                                        <Col span={24}>
                                            <Typography fontWeight={300} variant='subtitle'>{item.email}</Typography>
                                        </Col>
                                    </Row>

                                </Col>
                                <Col span={8}>

                                    {staff.length > 0 && data.length > 0 && (<Row>
                                        <Col span={24} style={{ textAlign: 'center' }}>
                                            <Typography fontSize={13} variant="body1">Field Log Distribution</Typography>
                                        </Col>
                                        <Col span={24} style={{ textAlign: 'center' }}>
                                            {member !== undefined && member.customDistribution === false ? <Checkbox checked={member.flDistribution}
                                                onChange={(e) => {
                                                    this.updateStaff(member.key, 'flDistribution', e.target.checked)
                                                }} /> :
                                                <Checkbox checked={item.flDistribution}
                                                    onChange={(e) => {
                                                        this.updateCustom(member.key, 'flDistribution', e.target.checked)
                                                    }} />}
                                        </Col>
                                    </Row>)}
                                </Col>

                            </Row>

                        </Col>
                        <Col xs={4} sm={4}>
                            {item.customDistribution === true && (
                                <EditItem item={item} />
                            )}
                            {item.customDistribution === true && (
                                <DeleteItem item={item} />
                            )}
                        </Col>



                        <Col span={24}>
                            <Divider />
                        </Col>

                    </Row>
                </Col>
            )
        }

        return (
            <Row style={{}} align="middle">

                <Col span={23}>
                    <Row align="middle">
                        <Col xs={16} sm={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>
                            <Typography variant="h4">Account Distribution</Typography>
                        </Col>
                        <Col xs={6} sm={4} style={{ paddingTop: 30, paddingBottom: 15, textAlign: 'right' }}>
                            <CreateItemButton />
                        </Col>
                        <Col span={24}>
                            <Divider />
                        </Col>
                    </Row>
                </Col>





                <Col span={23} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 30 }}>
                    <Paper elevation={16} style={{ padding: 20, borderRadius: 12 }}>
                        <Row>
                            <Col xs={24} sm={12} style={{ paddingBottom: 8 }}>
                                <Input fullWidth
                                    autoComplete='off'
                                    value={searchText}
                                    onChange={(e) => {
                                        this.setState({ searchText: e.target.value })
                                        if (e.target.value.length > 1) {
                                            this.filterData(data, e.target.value, filters)
                                        }
                                        else {
                                            this.filterData(data, '', filters)
                                        }
                                    }}
                                    id="input-with-icon-adornment"
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    }
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                onClick={() => {
                                                    this.setState({ searchText: '' })
                                                    this.filterData(data, '', filters)
                                                }}
                                            >
                                                <HighlightOffIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </Col>
                            <Col xs={0} sm={12} style={{ textAlign: 'right' }}>
                                <Typography variant='h6'>Actions</Typography>
                            </Col>

                            <Col span={24}>
                                <Row align='middle' >
                                    {filteredData.map((d) => {
                                        return (
                                            <RowItem item={d} />

                                        )

                                    })}
                                </Row>
                            </Col>
                        </Row>
                    </Paper>
                </Col>


                <Modal title="" visible={openModal} onCancel={() => this.setState({ openModal: false })} footer={null} width={'50%'} style={{ borderRadius: 40 }} >
                    <Row align="middle" gutter={[16, 16]} >
                        <Col xs={{ offset: 0, span: 24 }} sm={{ offset: 6, span: 18 }} style={{ paddingBottom: 12, }}>
                            <Typography variant='h5'>Add Custom Distribution</Typography>
                        </Col>


                        <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                            <Typography variant="body1">Name<span style={{ color: 'red' }}>*</span></Typography>
                        </Col>
                        <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                            <OutlinedInput fullWidth notched={false} label="none" placeholder={"Name"} size="small"
                                value={currentItem.label || ''}
                                onChange={(e) => {
                                    this.updateCurrentItem(`label`, e.target.value)
                                }} />
                        </Col>
                        <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                            <Typography variant="body1">Email<span style={{ color: 'red' }}>*</span></Typography>
                        </Col>
                        <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                            <OutlinedInput fullWidth notched={false} label="none" placeholder={"Email"} size="small"
                                value={currentItem.email || ''}
                                onChange={(e) => {
                                    this.updateCurrentItem(`email`, e.target.value)
                                }} />
                        </Col>
                        <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                            <Typography variant="body1">Field Log Distribution</Typography>
                        </Col>
                        <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                            <Checkbox checked={currentItem.flDistribution}
                                onChange={(e) => {
                                    this.updateCurrentItem(`flDistribution`, e.target.checked)
                                }} />
                        </Col>





                        <Col xs={{ offset: 0, span: 24 }} sm={{ offset: 6, span: 18 }} style={{ paddingBottom: 12, }}>
                            <CreateCustom />
                        </Col>

                    </Row>
                </Modal>




            </Row>
        );
    }
} 