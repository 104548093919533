import React, { useEffect, useState } from "react";


import { Autocomplete, Box, Grid, TextField, Typography } from '@mui/material';



const UploadTemplates = (props) => {


    const { templates, setTemplate, template, setItem, item } = props;
    const [value, setValue] = useState(null);






    return (



        <Box sx={{ flexGrow: 1, width: '100%' }}>
            <Typography variant="h6" fontWeight={700} fontSize={20} gutterBottom component="div">
                Templates
            </Typography>
            <Autocomplete
                id="combo-box-demo"
                options={templates}
                value={template} // Changed this line
                onChange={(event, newValue) => {
                    setTemplate(newValue);
                }}
                renderInput={(params) => <TextField {...params} placeholder="Templates" />}
            />
        </Box>






    );
};

export default UploadTemplates;
