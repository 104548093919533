import React from "react";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { Input } from "@mui/material";

const NumberInput = ({
    currentTask,
    setCurrentTask,
    headerKey,
    options
}) => {
    const [age, setAge] = React.useState(currentTask?.[headerKey]);  // Consider renaming this to 'value' or 'number' for clarity.
    const [isFocused, setIsFocused] = React.useState(false);

    const handleChange = (event) => {
        let val = event.target.value;
        console.log({ ...currentTask, [headerKey]: val })
        setCurrentTask({ ...currentTask, [headerKey]: val })
        setAge(val);
    };

    return (
        <Input
            fullWidth
            variant="standard"
            id="number-input"
            type="number"
            size="small"
            margin="dense"
            placeholder="Type here!"
            value={age}
            onChange={handleChange}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            sx={{
                '& .MuiInputBase-input': {
                    color: 'black',
                    fontWeight: 500,
                    fontSize: 14,
                },
            }}
        />
    );
};

export default NumberInput;
