import { Button, CircularProgress } from '@mui/material';
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fullMobile } from './../../util';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, } from 'firebase/firestore';
import * as htmlToImage from 'html-to-image';
import moment from 'moment-timezone';



import { MakePDF } from './logPDF';
var randomColor = require('randomcolor');

export default function FieldLogHeaderButtons(props) {
    const { userInfo, screenWidth, openSnack, company, account, updateStatus, logRef, updateLog, typeRef, } = props;
    const [log, setLog] = useState(null)
    const [downloading, setDownloading] = useState(false)
    const [saving, setSaving] = useState(false)
    const [type, setType] = useState(typeRef)
    useEffect(() => {
        setLog(logRef)

    }, [logRef])
    useEffect(() => {
        setType(typeRef)
    }, [typeRef])



    const EmailLink = () => {
        let navigate = useNavigate();

        return (

            <Button display="inline" style={{ textTransform: 'none', fontSize: 13, fontWeight: 500, marginLeft: 10, backgroundColor: 'white' }} color="inherit" variant='outlined'
                onClick={() => {
                    navigate('/emailLog')
                }}>Email</Button>

        )
    }

    const HistoryLink = () => {
        let navigate = useNavigate();

        return (

            <Button display="inline" style={{ textTransform: 'none', fontSize: 13, fontWeight: 500, marginLeft: 10, backgroundColor: 'white' }} color="inherit" variant='outlined'
                onClick={() => {
                    navigate('/historyLog')
                }}>History</Button>

        )
    }


    function getFirstLetters(str) {
        if (str) {
            const firstLetters = str
                .split(' ')
                .map(word => word[0])
                .join('');

            return firstLetters;
        }
        else return '';
    }

    const LogID = getFirstLetters(company.companyName) + "-" +
        `${log?.account !== undefined ? getFirstLetters(log?.account === undefined ? '' : `${log?.account?.accountName}`) : ''}`
        + '-' + moment(log?.startDate, 'x').format('MM-DD-YYYY') + "-" + "LOG";


    async function saveLog() {
        console.log(log)
        setSaving(true)
        //check for log name and log leadStaff, make sure they are not empty and not null and not undefined
        if (log?.leadStaff) {


            const db = getFirestore();
            const docRef = doc(db, "clients", userInfo.currentCompany, 'fieldLogsNew', log.key || log.tempKey);
            setDoc(docRef,
                {
                    ...log,
                    status: 'built',
                    key: log.tempKey,
                }).then(async () => {
                    const userRef = doc(db, "users", userInfo.id, 'currentItems', 'currentFieldLogNew');
                    await setDoc(userRef, {
                        key: log.tempKey
                    }).then(async () => {
                        const node = document.getElementById('my-node');
                        const dataUrl = await htmlToImage.toPng(node);
                        const weather = log.weather;
                        weather.weatherImage = dataUrl;


                        if (weather.main !== undefined) {
                            updateLog('weather', weather)

                            const db = getFirestore()
                            const docRef = doc(db, "clients", userInfo.currentCompany, 'fieldLogsNew', log.key || log.tempKey);
                            await updateDoc(docRef, {
                                weather: weather
                            }).then(() => {
                                console.log('updated weather')
                            })
                        }
                    }).then(() => {
                        updateLog('key', log.tempKey)
                        updateLog('status', 'built')
                        openSnack("success", 'Saved')
                        updateStatus('built');
                        setSaving(false)

                    })

                })




        }
        else {
            setSaving(false)
            openSnack("error", 'Please enter lead staff')
        }
    }


    if (['loading'].includes(props.status)) {

        return (
            <Row gutter={[12, 12]}>
                <Col {...fullMobile(12, 24)}>
                    <CircularProgress />
                </Col>
            </Row>
        );
    }
    if (['creating'].includes(props.status)) {

        return (
            <Row gutter={[12, 12]} justify="end" >
                <Col {...fullMobile(24, 24)}>


                    {saving === true ? <CircularProgress /> : <Button display="inline" style={{ textTransform: 'none', fontSize: 13, fontWeight: 500, marginLeft: 10, backgroundColor: 'white' }} color="inherit" variant='outlined'
                        onClick={saveLog}>Save & Continue</Button>}
                    {log?.key !== undefined && (

                        <HistoryLink />

                    )}
                </Col>

            </Row>
        );
    }
    if (['built'].includes(props.status)) {

        if (type === 'email') {
            {
                if (downloading === true) {
                    return (
                        <CircularProgress />
                    )
                } else {
                    return (
                        <Button display="inline" style={{ textTransform: 'none', fontSize: 13, fontWeight: 500, marginLeft: 10 }} color="inherit" variant='outlined'
                            onClick={async () => {
                                setDownloading(true)
                                const pdf = await MakePDF(log, userInfo, openSnack);
                                setDownloading(false)

                            }}>Email</Button>
                    )
                }
            }
        }
        else {



            return (

                <Row gutter={[12, 12]} justify="end">
                    <Col {...fullMobile(24, 24)}>
                        <Button display="inline" style={{ textTransform: 'none', fontSize: 13, fontWeight: 500, marginLeft: 10, backgroundColor: 'white' }} color="inherit" variant='outlined'
                            onClick={() => {
                                updateStatus('creating');
                            }}>Edit</Button>

                        <HistoryLink />

                        <EmailLink />

                        {downloading === true ? <CircularProgress /> : <Button display="inline" style={{ textTransform: 'none', fontSize: 13, fontWeight: 500, marginLeft: 10, backgroundColor: 'white' }} color="inherit" variant='outlined'
                            onClick={async () => {
                                setDownloading(true)

                                const pdf = await MakePDF(log, userInfo, openSnack, 'none', company);
                                setDownloading(false)
                                pdf.save(`${moment(log?.startDate, 'x').format('YYYY-MM-DD')}_${LogID}.pdf`);
                            }}>Export</Button>
                        }
                    </Col>
                </Row>
            );
        }
    }



}