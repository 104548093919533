export const obscapeParameters = [
    {
        "name": "solar",
        "symbol": "S",
        "unit": "W/m2",
        "caption": "Solar irradiance",
        "label": "Solar",
        "title": "Solar",
        "key": "solar"
    },
    {
        "name": "precipitation",
        "symbol": "R",
        "unit": "mm",
        "caption": "Rainfall intensity",
        "label": "Precipitation",
        "title": "Precipitation",
        "key": "precipitation"
    },
    {
        "name": "strikes",
        "symbol": "st",
        "unit": "-",
        "caption": "Number of lightning strikes",
        "label": "Strikes",
        "title": "Strikes",
        "key": "strikes"
    },
    {
        "name": "strikeDistance",
        "symbol": "std",
        "unit": "km",
        "caption": "Estimated lightning strike distance",
        "label": "Strike Distance",
        "title": "Strike Distance",
        "key": "strikeDistance"
    },
    {
        "name": "windSpeed",
        "symbol": "Uw",
        "unit": "m/s",
        "caption": "Wind speed",
        "label": "Wind Speed",
        "title": "Wind Speed",
        "key": "windSpeed"
    },
    {
        "name": "windDirection",
        "symbol": "Uwdir",
        "unit": "deg N",
        "caption": "Wind direction",
        "label": "Wind Direction",
        "title": "Wind Direction",
        "key": "windDirection"
    },
    {
        "name": "gustWindSpeed",
        "symbol": "Ug",
        "unit": "m/s",
        "caption": "Wind gust speed",
        "label": "Gust Wind Speed",
        "title": "Gust Wind Speed",
        "key": "gustWindSpeed"
    },
    {
        "name": "gustWindDirection",
        "symbol": "Ugdir",
        "unit": "deg N",
        "caption": "Wind gust direction",
        "label": "Gust Wind Direction",
        "title": "Gust Wind Direction",
        "key": "gustWindDirection"
    },
    {
        "name": "airTemperature",
        "symbol": "T",
        "unit": "deg C",
        "caption": "Air temperature",
        "label": "Air Temperature",
        "title": "Air Temperature",
        "key": "airTemperature"
    },
    {
        "name": "vaporPressure",
        "symbol": "vP",
        "unit": "kPa",
        "caption": "Vapor pressure",
        "label": "Vapor Pressure",
        "title": "Vapor Pressure",
        "key": "vaporPressure"
    },
    {
        "name": "atmosphericPressure",
        "symbol": "P",
        "unit": "kPa",
        "caption": "Atmospheric pressure",
        "label": "Atmospheric Pressure",
        "title": "Atmospheric Pressure",
        "key": "atmosphericPressure"
    },
    {
        "name": "relativeHumidity",
        "symbol": "RH",
        "unit": "-",
        "caption": "Relative humidity",
        "label": "Relative Humidity",
        "title": "Relative Humidity",
        "key": "relativeHumidity"
    },
    {
        "name": "humiditySensorTemperature",
        "symbol": "Th",
        "unit": "deg C",
        "caption": "Air temperature (humidity sensor)",
        "label": "Humidity Sensor Temperature",
        "title": "Humidity Sensor Temperature",
        "key": "humiditySensorTemperature"
    },
    {
        "name": "xOrientation",
        "symbol": "Xtilt",
        "unit": "deg",
        "caption": "Sensor inclination in northward direction",
        "label": "X Orientation",
        "title": "X Orientation",
        "key": "xOrientation"
    },
    {
        "name": "yOrientation",
        "symbol": "Ytilt",
        "unit": "deg",
        "caption": "Sensor inclination in westward direction",
        "label": "Y Orientation",
        "title": "Y Orientation",
        "key": "yOrientation"
    },
    {
        "name": "NorthWindSpeed",
        "symbol": "vw",
        "unit": "m/s",
        "caption": "Northerly wind speed",
        "label": "North Wind Speed",
        "title": "North Wind Speed",
        "key": "NorthWindSpeed"
    },
    {
        "name": "EastWindSpeed",
        "symbol": "uw",
        "unit": "m/s",
        "caption": "Easterly wind speed",
        "label": "East Wind Speed",
        "title": "East Wind Speed",
        "key": "EastWindSpeed"
    },
    {
        "name": "gustNorthWindSpeed",
        "symbol": "vg",
        "unit": "m/s",
        "caption": "Northerly wind gust speed",
        "label": "Gust North Wind Speed",
        "title": "Gust North Wind Speed",
        "key": "gustNorthWindSpeed"
    },
    {
        "name": "gustEastWindSpeed",
        "symbol": "ug",
        "unit": "m/s",
        "caption": "Easterly wind gust speed",
        "label": "Gust East Wind Speed",
        "title": "Gust East Wind Speed",
        "key": "gustEastWindSpeed"
    },
    {
        "name": "lat",
        "symbol": "Lat",
        "unit": "deg N",
        "caption": "Latitude",
        "label": "Latitude",
        "title": "Latitude",
        "key": "lat"
    },
    {
        "name": "lon",
        "symbol": "Lon",
        "unit": "deg E",
        "caption": "Longitude",
        "label": "Longitude",
        "title": "Longitude",
        "key": "lon"
    },
    {
        "name": "VBAT",
        "symbol": "Vbat",
        "unit": "V",
        "caption": "Battery voltage",
        "label": "Battery Voltage",
        "title": "Battery Voltage",
        "key": "VBAT"
    },
    {
        "name": "VSOL",
        "symbol": "Vsol",
        "unit": "V",
        "caption": "Solar panel voltage",
        "label": "Solar Panel Voltage",
        "title": "Solar Panel Voltage",
        "key": "VSOL"
    },
    {
        "name": "TEMP",
        "symbol": "Temp",
        "unit": "deg C",
        "caption": "Internal temperature",
        "label": "Internal Temperature",
        "title": "Internal Temperature",
        "key": "TEMP"
    },
    {
        "name": "HUM",
        "symbol": "RH",
        "unit": "%",
        "caption": "Relative humidity (PTM)",
        "label": "Relative Humidity (PTM)",
        "title": "Relative Humidity (PTM)",
        "key": "HUM"
    },
    {
        "name": "PATM",
        "symbol": "Pa",
        "unit": "kPa",
        "caption": "Atmospheric pressure (PTM)",
        "label": "Atmospheric Pressure (PTM)",
        "title": "Atmospheric Pressure (PTM)",
        "key": "PATM"
    },
    {
        "name": "Xtilt",
        "symbol": "Xinc",
        "unit": "deg",
        "caption": "PTM inclination in x-direction",
        "label": "PTM Inclination in X-Direction",
        "title": "PTM Inclination in X-Direction",
        "key": "Xtilt"
    },
    {
        "name": "Ytilt",
        "symbol": "Yinc",
        "unit": "deg",
        "caption": "PTM inclination in y-direction",
        "label": "PTM Inclination in Y-Direction",
        "title": "PTM Inclination in Y-Direction",
        "key": "Ytilt"
    },
    {
        "name": "CSQ",
        "symbol": "Sig",
        "unit": "-",
        "caption": "Cellular Signal Quality",
        "label": "Cellular Signal Quality",
        "title": "Cellular Signal Quality",
        "key": "CSQ"
    },
    {
        "name": "RAT",
        "symbol": "RAT",
        "unit": "-",
        "caption": "Cellular Radio Access Technology",
        "label": "Cellular Radio Access Technology",
        "title": "Cellular Radio Access Technology",
        "key": "RAT"
    }
];
