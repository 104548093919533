import { Button, ButtonGroup, CircularProgress, Divider, Typography, OutlinedInput, Autocomplete, TextField, Select, MenuItem } from '@mui/material';
import { Col, Modal, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { fullMobile } from './util';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, } from 'firebase/firestore';

import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import StocksTable from './stocksTable';
import { queryAccounts, queryStocks } from '../../firebase/config';
import CreateStockForm from './createStockForm';




var randomColor = require('randomcolor');

export default function ProductStocksHome(props) {
    const { userInfo, screenWidth, openSnack, company, account, } = props;
    const [loading, setLoading] = useState(true);
    const [stocks, setStocks] = useState([]);
    const [loadingStocks, setLoadingStocks] = useState(false);
    const [stock, setStock] = useState({})
    const [currentButton, setCurrentButton] = useState('stocks')
    const [addStockModal, setAddStockModal] = useState(false)
    const [accounts, setAccounts] = useState([])



    const db = getFirestore()


    useEffect(() => {
        window.scrollTo(0, 0);
        getInfo()
    }, [])

    async function getInfo() {

        const stocks = await queryStocks(userInfo)
        setStocks(stocks)
        console.log(stocks)
        setLoadingStocks(false)

        const accounts = await queryAccounts(userInfo)
        //sort by accountName
        accounts.sort((a, b) => (a.accountName > b.accountName) ? 1 : -1)
        //filter out archived accounts
        const newAccounts = accounts.filter((item) => item.archived === false)
        setAccounts(newAccounts)




    }




    const AddItemButton = () => {
        return (
            <Button variant={'contained'} display="inline"
                fullWidth
                style={{
                    padding: 4, paddingTop: 8, paddingBottom: 8,
                    textTransform: 'none', backgroundColor: '#404050', fontSize: 13,
                }}
                onClick={() => {
                    setAddStockModal(true)
                    setStock({
                        name: '',
                        description: '',
                        location: null,
                        archived: false,
                        company: userInfo.currentCompany,
                        key: uuid()
                    })

                }}
            >
                <AddIcon fontSize='small' />  Add Stock
            </Button>
        )
    }






    return (
        <div style={{ fontFamily: 'Roboto, sans-serif', backgroundColor: '#f6f8fe', minHeight: '100vh' }}>
            <Row  >
                <Col {...fullMobile(19, 23)} style={{
                    paddingTop: 30,
                    paddingLeft: 30,
                    overflow: 'auto',


                }}>
                    <Row align="middle" >
                        <Col {...fullMobile(20, 24)}>
                            <Typography variant='h3' fontWeight={500} fontSize={36}>Your Product Stocks</Typography>
                            <Typography style={{ paddingTop: 5 }} variant='h5' fontWeight={300} fontSize={18}>Your stocks as part of this account.</Typography>

                        </Col>
                        <Col {...fullMobile(0, 24)} style={{


                        }}>
                            <AddItemButton />

                        </Col>

                        <Col {...fullMobile(24, 24)} style={{ paddingTop: 10, paddingBottom: 10 }}>
                            <Divider />
                        </Col>
                        <Col {...fullMobile(24, 24)} style={{ paddingTop: 10, paddingBottom: 10 }}>
                            <ButtonGroup variant="link" aria-label="contained primary button group">
                                {[{ name: 'stocks', label: 'Stocks' }].map((item, index) => {
                                    return (
                                        <Button style={{
                                            color: '#404050', textTransform: 'none', fontSize: 14, borderRadius: 0,
                                            //add orange bottom border if current button is users
                                            borderBottom: currentButton === item.name ? '2px solid #f57c00' : 'none'
                                        }}
                                            onClick={() => {
                                                setCurrentButton(item.name)
                                                console.log(item.name)
                                            }}
                                        >
                                            {item.label}
                                        </Button>
                                    )
                                })}

                            </ButtonGroup>

                        </Col>
                        <Col {...fullMobile(24, 24)} >
                            <Divider />
                        </Col>
                        {loadingStocks === false ?
                            <Col {...fullMobile(24, 24)} style={{ padding: 8 }}>
                                {currentButton === 'stocks' && (
                                    <StocksTable {...props} combinedRef={stocks} />
                                )}



                            </Col> :
                            <CircularProgress />}
                    </Row>

                </Col>
                <Col xs={{ span: 0 }} sm={{ span: 5 }} style={{
                    minHeight: '100vh',
                    backgroundColor: '#f5f5f5'

                }}>
                    <Row style={{ padding: 30, }} >
                        <Col {...fullMobile(24, 24)}>
                            <AddItemButton />
                        </Col>

                    </Row>

                </Col>










                <Modal title="" visible={addStockModal} onCancel={() => {
                    setAddStockModal(false)
                    setStock({})
                }} footer={null} width={screenWidth === 'xs' ? '80%' : '50%'} style={{ borderRadius: 40 }} >
                    <CreateStockForm {...props} stocks={stocks} setStocks={setStocks} stock={stock} setStock={setStock} setAddStockModal={setAddStockModal} accounts={accounts} />
                </Modal>






            </Row >
        </div >
    );
}