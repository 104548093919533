import React, { useEffect, useState } from "react";


import { Button, Checkbox, Divider, IconButton, Typography } from '@mui/material';
import { Col, Row, Table } from 'antd';
import { collection, collectionGroup, doc, getDoc, getDocs, getFirestore, onSnapshot, query, setDoc, updateDoc, where, writeBatch } from "firebase/firestore";
import moment from 'moment-timezone';
import uuid from "react-uuid";
import { faCheck, faSpinner, faSquareCheck, } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon, } from "@fortawesome/react-fontawesome";

import './newStyle.css';
import { applyFiltersAndSearch, getNestedProperty } from "../utils/functions";
import { getLogs, getCompanyPreferences, getLogPreferences, updateLogPreferences } from "../utils/firebase";
import LogsSubHeader from "../components/tableComponents/subHeader";
import DataTable from "../components/tableComponents/dataTable";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { TableHeaders } from "../components/tableComponents/headers";
import axios, * as others from 'axios';

const LogsTable = ({
    screenWidth,
    userInfo,
    account,
    openSnack,
}) => {

    const [listKey, setListKey] = useState('field_logs_new');
    const [prefKey, setPrefKey] = useState('fieldLog');

    const [logs, setLogs] = useState([
        { logNumber: 1, startDate: '2021-10-01', status: 'Open', id: '1' },
        { logNumber: 2, startDate: '2021-10-02', status: 'Open', id: '2' },
        { logNumber: 3, startDate: '2021-10-03', status: 'Closed', id: '3' },
    ]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [sortItem, setSortItem] = useState(null);
    const [selectedLogs, setSelectedLogs] = useState([]); // [
    const [columnFilters, setColumnFilters] = useState({});
    const [statusFilter, setStatusFilter] = useState('Saved');

    const headers = TableHeaders(setSelectedLogs, selectedLogs);




    useEffect(() => {
        window.scrollTo(0, 0)
        getInfo()



    }, [])



    async function getInfo() {

        const logs = await getLogs(userInfo, listKey)


        setLoading(false)
    }


    async function deleteLog(log) {
        console.log(log)
        const db = getFirestore();
        const updatedLogs = logs?.filter((t) => t.id !== log.id)
        console.log(updatedLogs)



    }

    async function deleteSelectedLogs(deleteLogs) {

        const db = getFirestore();



        // Filter out the deleted logs from the local state
        const updatedLogs = logs?.filter(t => !deleteLogs.some(dt => dt === t.id));
        setSelectedLogs([])
        setLogs(updatedLogs);

    }

    async function updateLog(log, type) {
        if (log?.id) {

            console.log(log)


            const updatedLogs = logs.map((t) => {
                if (t.id === log.id) {
                    return log
                } else {
                    return t
                }
            })
            setLogs(updatedLogs)
        }
        else {

        }
    }
    async function setCurrentLog(log, type) {
        if (log?.id) {

            console.log(log)

        }
        else {

        }
    }



    console.log(columnFilters)

    const filteredLogs = applyFiltersAndSearch(
        logs,
        searchQuery,
        headers,
        columnFilters || {},
        screenWidth,
        statusFilter
    );





    const props = {
        logs,
        setLogs,
        loading,
        setLoading,
        userInfo,
        account,
        openSnack,
        screenWidth,
        deleteLog,
        columnFilters,
        setColumnFilters,
        headers,
        sortItem,
        setSortItem,
        searchQuery,
        setSearchQuery,
        deleteSelectedLogs,
        prefKey,
        filteredLogs,
        getNestedProperty,
        updateLog,
        setCurrentLog,
        selectedLogs,
        setSelectedLogs,
        statusFilter,
        setStatusFilter

    }

    function getNumbersFrom400To300() {
        let numbers = [];
        for (let i = 400; i >= 300; i -= 4) {
            numbers.push(i);
        }
        return numbers;
    }

    // Usage
    const numbers = getNumbersFrom400To300();
    console.log(numbers);



    if (loading) {
        return (
            <Row style={{ height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
                {/* Using the full height of the viewport to center content */}
                <Col span={24} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

                    {/* FontAwesome Spinner */}
                    <FontAwesomeIcon icon={faSpinner} spin size="3x" />

                    <Typography variant="h6" style={{ marginTop: 20 }}>
                        Loading your logs...
                    </Typography>

                </Col>
            </Row>
        )
    }








    else {
        return (
            <Row style={{}} >
                <Col span={24} style={{}}>
                    <Row align="middle">
                        <Button

                            onClick={async () => {
                                console.log('testing')
                                const proxyurl = "https://mycorslake.herokuapp.com/";
                                const response = await axios.post(proxyurl + `https://us-central1-aquasource3.cloudfunctions.net/getStationsHours`, {
                                    companies: [
                                        'Stanford University2cb9325f-80ca-2420-4dda-60f60edca3b6'

                                    ],
                                    //400 to 350 every 4
                                    hours: numbers,
                                });
                                console.log(response)

                            }}
                        >Get Data</Button>

                        <LogsSubHeader {...props} />
                        <Col span={24}>
                            <DataTable {...props} />
                        </Col>


                    </Row>
                </Col >

            </Row >
        );
    }

};

export default LogsTable;
