import { Col, Row } from 'antd';
import React from 'react';
import { CompactPicker } from 'react-color';
import reactCSS from 'reactcss';

export default class DataColorCompact extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            displayColorPicker: false,
            color: this.props.color,
            stations: this.props.stations
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.color !== prevProps.color) {
            this.setState({ color: this.props.color })
        }


    }


    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };

    handleClose = () => {
        this.setState({ displayColorPicker: false })
    };

    handleChange = (color) => {
        console.log(color)
        this.setState({ color: color.hex })
        console.log(color.hex) 
        this.props.updateData("color", color.hex)
    };

    render() {
        const { stations } = this.state

        const styles = reactCSS({
            'default': {
                color: {
                    width: '36px',
                    height: '14px',
                    borderRadius: '2px',
                    background: `${this.state.color}`,
                    position: 'relative',

                },
                swatch: {
                    leftPadding: 10,
                    padding: '5px',
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                },
                popover: {
                    position: 'absolute',
                    left: stations === true ? '-140px' : '-120px',
                    top: stations === true ? '0px' : '-120px',
                    zIndex: '200',
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
            },
        });

        return (
            <Row style={{ position: 'relative', zIndex: 300 }}>
                <Col style={styles.swatch} onClick={this.handleClick}>
                    <div style={styles.color} />
                </Col>
                {this.state.displayColorPicker ? <Col style={styles.popover}>
                    <div style={styles.cover} onClick={this.handleClose} />
                    <CompactPicker 
                        colors={
                            ['#4D4D4D', '#999999', '#FFFFFF', '#F44E3B', '#FE9200', '#FCDC00', '#DBDF00', '#A4DD00', '#68CCCA', '#73D8FF', '#AEA1FF', '#FDA1FF', '#333333', '#808080', '#cccccc', '#D33115', '#E27300', '#FCC400', '#B0BC00', '#68BC00', '#16A5A5', '#009CE0', '#7B64FF', '#FA28FF', '#000000', '#666666', '#B3B3B3', '#9F0500', '#C45100', '#FB9E00', '#808900', '#194D33', '#0C797D', '#0062B1', '#653294', '#AB149E',
                                '#B80000', '#DB3E00', '#FCCB00', '#008B02', '#006B76',
                                '#1273DE', '#004DCF', '#5300EB', '#EB9694', '#FAD0C3',
                                '#FEF3BD', '#C1E1C5', '#BEDADC', '#C4DEF6', '#BED3F3', '#D4C4FB',
                                '#f57722',
                                '#ff9d2a',
                                '#d3d3d3',
                                '#67aded',
                                '#393939'

                            ]
                        } color={this.state.color} onChange={this.handleChange} />
                </Col> : null}

            </Row>
        )
    }
}

