import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox, CircularProgress, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import { getFirestore } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { ReactSortable, } from 'react-sortablejs';
import { faGripVertical } from '@fortawesome/free-solid-svg-icons';




var randomColor = require('randomcolor');

export default function SiteInfoSettings(props) {
    const { userInfo, screenWidth, openSnack, company, account, attributes, currentWidgetKey, updateAttributes } = props;
    const [loading, setLoading] = useState(true);




    const db = getFirestore()


    const defaultSiteInfoList = [
        { label: 'Access Code', value: 'accessCode', index: 0, visible: true },
        { label: 'Address', value: 'address', index: 1, visible: true },
        { label: 'Site Contact', value: 'projectContact', index: 2, visible: true },
        { label: 'Site Phone', value: 'projectPhone', index: 3, visible: true },
        { label: 'Size', value: 'size', index: 4, visible: true },
        { label: 'Description', value: 'description', index: 5, visible: true },
    ]

    const [list, setList] = useState(attributes?.siteInfoList ? attributes.siteInfoList : defaultSiteInfoList);


    useEffect(() => {
        getInfo()
    }, [])

    async function getInfo() {

        setLoading(false)




    }












    const Loading = () => { if (loading) { return <div className="loading"><CircularProgress /> <Typography variant="body1">Loading Data</Typography></div> } }




    //if loading true show loading screen
    if (loading) { return <Loading /> } else {
        return (
            <Row gutter={[8, 8]} style={{ height: '100%', width: '100%', display: 'flex' }} >


                <Col span={24}>
                    <Typography variant="h5">Site Info Items</Typography>
                </Col>
                <Col span={24}>
                    <ReactSortable
                        handle=".drag-handle"
                        animation={500}
                        list={list}
                        setList={(newState) => {
                            setList(newState);
                            console.log(newState)
                            attributes.siteInfoList = newState

                        }}
                    >
                        {list.map((item, index) => (
                            <Row align="middle" style={{ border: '1px solid #e8e8e8', }}>
                                <Col span={2} className="drag-handle" style={{ cursor: 'pointer', paddingLeft: 5 }}>
                                    <FontAwesomeIcon icon={faGripVertical} />
                                </Col>
                                <Col span={12} key={index} style={{}}>

                                    <Typography variant="body1">{item.label}</Typography>


                                </Col>
                                <Col span={10} key={index} style={{}}>
                                    <Typography variant="body1" fontWeight={700} fontSize={13}>Visible</Typography>
                                    <Checkbox checked={item.visible} onChange={(e) => {
                                        const newList = [...list];
                                        newList[index].visible = e.target.checked;
                                        setList(newList);
                                        attributes.siteInfoList = newList

                                    }} />
                                </Col>
                            </Row>
                        ))}
                    </ReactSortable>
                </Col>






            </Row >

        );

    }
}