import { faFile, faFolderOpen } from '@fortawesome/free-regular-svg-icons';
import { faArrowsUpToLine, faEdit, faEllipsisV, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Breadcrumbs, Button, ClickAwayListener, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, List, ListItem, Popper, TableCell, TextField, ThemeProvider, Typography, createTheme } from '@mui/material';
import { Col, Row } from 'antd';
import MUIDataTable from "mui-datatables";
import React, { useEffect, useRef, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { deleteFiles, deleteFolders, getFolderStructure, getItems, moveLevel, renameItem } from '../../firebase/firestore';
import AddItem from '../AddItem/AddItem';
import YourCustomRowComponent from './CustomRow';
import './FileTree.css';
import RenameFile from './RenameFile';
import CustomTableHeader from './CustomTableHeader';
import YourCustomHeadComponent from './CustomTableHeader';


const FileTree = ({ setSelectedFolder, openSnack, userInfo }) => {
  const [folderStructure, setFolderStructure] = useState([]);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [breadcrumb, setBreadcrumb] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const anchorRefs = useRef(new Map()).current;
  const [renameModalOpen, setRenameModalOpen] = useState(false);
  const [renameValue, setRenameValue] = useState('');
  const [renameId, setRenameId] = useState(null);
  const [renameType, setRenameType] = useState(null);
  const [deletedItems, setDeletedItems] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [folders, setFolders] = useState([]);
  const [files, setFiles] = useState([]);



  useEffect(() => {
    refreshFolderStructure(null);
  }, []);

  const refreshFolderStructure = async (folderId) => {



    const path = [undefined, null].includes(folderId) ? 'root' : folderId;
    console.log(path)
    const itemFolders = await getItems(userInfo.currentCompany, path, 'folders', userInfo.currentAccount);
    const itemFiles = await getItems(userInfo.currentCompany, path, 'files', userInfo.currentAccount);


    setFolders([...itemFolders]);
    setFiles([...itemFiles]);
    console.log(itemFiles)
    setFolderStructure([...itemFolders, ...itemFiles]);

  };

  const navigateToFolder = (folderId) => {

    setSelectedRows([]);
    setCurrentFolder(folderId);
    updateBreadcrumb(folderId);
    setSelectedFolder(folderId)
    refreshFolderStructure(folderId);
  };

  const updateBreadcrumb = (folderId) => {
    if (folderId === null) {
      setBreadcrumb([]);
    } else {
      const newBreadcrumb = [...breadcrumb];
      const folderIndex = newBreadcrumb.findIndex((item) => item.id === folderId);
      if (folderIndex === -1) {
        const folder = folderStructure.find((folder) => folder.id === folderId);
        newBreadcrumb.push({ id: folder.id, name: folder.name });
      } else {
        newBreadcrumb.splice(folderIndex + 1)
      }

      setBreadcrumb(newBreadcrumb);
    }
  };

  const foldersInCurrentFolder = folderStructure.filter(
    (folder) => folder.parent !== undefined
  );

  const handleRename = async () => {


    if (renameValue === '') {
      openSnack('error', 'Please enter a name');
      return;
    }
    //export const renameItem = async (name, type, folderId, fileId, company) => {
    const folder = folderStructure.find((folder) => folder.id === renameId);
    const type = folder.type === 'file' && folder.parent === null ? 'nullFile' : folder.type;
    await renameItem(renameValue, type, folder.parent, renameId, userInfo.currentCompany);

    setRenameModalOpen(false);
    refreshFolderStructure(currentFolder);
    setRenameValue('');
    setRenameId(null);
    setRenameType(null);
  };


  const BreadcrumbButtons = ({ items, navigateToFolder }) => {
    return (
      <Breadcrumbs aria-label="breadcrumb"
        //symbol to be > not /
        separator={<Typography variant='body1' fontSize={24} fontWeight={300} color='black'>></Typography>}
      >
        <Button color="primary" className='breadcrumb-button'
          style={{
            textTransform: 'none',
            fontSize: 22,
            fontWeight: 500,
            color: '#404050'
          }}
          onClick={() => navigateToFolder(null)}>
          <Typography variant='body1' fontSize={24} fontWeight={500} color='#404050'>Root Directory</Typography>
        </Button>
        {items.map((item, index) => (
          <React.Fragment key={item.id}>
            {index < items.length - 1 ? (
              <Button color="primary" className='breadcrumb-button'
                style={{
                  textTransform: 'none',

                }}
                onClick={() => navigateToFolder(item.id)}>
                <Typography variant='body1' fontSize={24} fontWeight={500} color='#404050'>{item.name}</Typography>


              </Button>
            ) : (
              <Typography variant='body1' fontSize={24} fontWeight={300} color='black'>{item.name}</Typography>
            )}
          </React.Fragment>
        ))}
      </Breadcrumbs>
    );
  };

  const columns = [

    {
      name: "id",
      label: "",
      options: {
        filter: false,
        sort: true,

        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {


          return (
            <YourCustomHeadComponent
              selected={selectedRows.length === foldersInCurrentFolder.length}
              onSelectAll={(isChecked) => handleSelectAll(isChecked)}
            />
          );
        },


      },


    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
        sort: true,
      },

    },
    {
      name: "type",
      label: "Type",
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: "size",
      label: "Size",
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: true,
        width: 80,
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          // Get selected folders
          const selectedFolders = folderStructure.filter((folder) => selectedRows.includes(folder.id));

          return (

            <TableCell style={{ textAlign: 'right', backgroundColor: 'white' }}>
              {selectedRows.length > 0 && (
                <Button
                  size="large"
                  onClick={() => handleDelete()}
                  style={{
                    textTransform: 'none',
                    border: '1px solid #404050',
                  }}
                >
                  <Typography variant='body1' fontSize={14} fontWeight={500} color='#404050'>
                    <FontAwesomeIcon icon={faTrash} size="sm" style={{ marginRight: 10 }} />
                    Delete {selectedFolders.length} Items
                  </Typography>
                </Button>
              )}

              {selectedRows.length > 0 && currentFolder !== null && (<Button
                size="large"
                onClick={() => moveUpOneLevel()}
                style={{
                  textTransform: 'none',
                  border: '1px solid #404050',
                  marginLeft: 10,
                }}
              >
                <Typography variant='body1' fontSize={14} fontWeight={500} color='#404050'>
                  <FontAwesomeIcon icon={faArrowsUpToLine} size="sm" style={{ marginRight: 10, }} />
                  Up One Level
                </Typography>
              </Button>)}

            </TableCell>

          );
        },
      }
    },
  ];


  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTable: {
          styleOverrides: {
            root: {
              padding: '8px',
              boxShadow: 'none',
              //minHeight = 400
              minHeight: '400px',
            }
          }
        },
        MuiTableRow: {
          styleOverrides: {
            root: {
              zIndex: 100,
              cursor: 'pointer',
              fontSize: '10px',
              '&:nth-child(even)': {
                backgroundColor: '#eeeeee52'
              },
              "&:hover": {
                backgroundColor: "#9ec1c933"
              }


            },


          }
        },
        MuiTableCell: {
          styleOverrides: {
            root: {
              fontSize: '12px',
              padding: '2px'
            }
          }
        }

      }
    });



  const options = {

    download: false,
    print: false,
    filterType: null,
    rowsPerPage: 50,
    viewColumns: false,
    filter: false,
    rearangeableColumns: false,
    rowsPerPageOptions: [50, 100, 200],
    selectableRows: 'none',





    //lets make the table rows draggable
    customRowRender: (data, dataIndex, rowIndex) => {
      //create an arra from data of ["id", "name", "type"]
      //check if row is selected
      //pass in the selected prop to the row component


      const [id, name, type, size, downloadUrl] = data;
      const downloadLink = folderStructure.find((folder) => folder.id === id).downloadUrl;


      return (
        <YourCustomRowComponent
          key={id}
          name={name}
          id={id}
          downloadUrl={downloadLink}
          type={type}
          size={size}
          parent={currentFolder}
          index={rowIndex}
          selected={selectedRows.includes(id)}
          refreshFolderStructure={refreshFolderStructure}
          setRenameModalOpen={setRenameModalOpen}
          setRenameValue={setRenameValue}
          setRenameId={setRenameId}
          setRenameType={setRenameType}
          openSnack={openSnack}
          company={userInfo.currentCompany}
          navigateToFolder={navigateToFolder}
          onSelectRow={(isChecked, id) => handleSelectRow(isChecked, id)}

        />
      );
    },





  };



  const handleSelectRow = (isChecked, rowId) => {

    if (isChecked) {
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, rowId]);
    } else {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((row) => row !== rowId)
      );
    }
  };

  const handleSelectAll = (isChecked) => {
    if (isChecked) {
      // Replace the selectedRows array with all row indices
      setSelectedRows(foldersInCurrentFolder.map((f) => f.id));
    } else {
      // Empty the selectedRows array
      setSelectedRows([]);
    }
  };

  const handleDelete = async () => {
    const selectedItems = folderStructure.filter((item) => selectedRows.includes(item.id));

    const foldersToDelete = selectedItems.filter((item) => item.type === 'folder');
    const filesToDelete = selectedItems.filter((item) => item.type === 'file');

    // Delete folders
    const deleteFoldersPromise = deleteFolders(foldersToDelete, userInfo.currentCompany);

    // Delete files

    const parent = filesToDelete[0]?.parent;


    const deleteFilesPromise = deleteFiles(filesToDelete, userInfo.currentCompany);


    // Wait for both deletion processes to complete
    await Promise.all([deleteFoldersPromise, deleteFilesPromise]);

    // Clear the selected rows and refresh the folder structure
    setSelectedRows([]);
    refreshFolderStructure(currentFolder);
  };


  const moveUpOneLevel = async () => {
    const selectedItems = folderStructure.filter((item) => selectedRows.includes(item.id));


    const foldersToMove = selectedItems.filter((item) => item.type === 'folder');
    const filesToMove = selectedItems.filter((item) => item.type === 'file');

    // Move folders
    for (const folder of foldersToMove) {


      await moveLevel(userInfo.currentCompany, folder, 'folders');
    }

    // Move files
    for (const file of filesToMove) {
      await moveLevel(userInfo.currentCompany, file, 'files');

    }



    // Clear the selected rows and refresh the folder structure
    setSelectedRows([]);
    refreshFolderStructure(currentFolder);
  };







  return (
    <DndProvider backend={HTML5Backend}>
      <Row className="folder-structure">
        <Col span={20}>
          <BreadcrumbButtons items={breadcrumb} navigateToFolder={navigateToFolder} />
        </Col>
        <Col span={4} style={{ textAlign: 'right' }}>
          <AddItem
            userInfo={userInfo}
            openSnack={openSnack}
            selectedFolder={currentFolder}
            refreshFolderStructure={refreshFolderStructure}
          />
        </Col>
        <Col span={24}>
          <ThemeProvider theme={getMuiTheme()}>
            <MUIDataTable


              data={foldersInCurrentFolder}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </Col>




        <RenameFile
          renameModalOpen={renameModalOpen}
          setRenameModalOpen={setRenameModalOpen}
          renameValue={renameValue}
          setRenameValue={setRenameValue}
          handleRename={handleRename}
        />


      </Row>
    </DndProvider>
  );
};

export default FileTree;
