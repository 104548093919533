import { Button, CircularProgress, Divider, IconButton, Input, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import { doc, getDoc, getFirestore, updateDoc } from 'firebase/firestore';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import moment from 'moment';
import React from 'react';

import DeleteForever from '@mui/icons-material/DeleteForever';

import axios, * as others from 'axios';

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/data')(Highcharts);

export default class PreviewProfile extends React.Component {
    constructor(props) {
        super(props);
        this.accordionContent = [];
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            currentItem: this.props.currentItem,
            parameters: this.props.parameters,
            loading: true,
            newItem: {},
            editMode: false,
            showMarkers: false,
            undoArray: [],
            chartOptions: {
                credits: {
                    enabled: false
                },
                chart: {
                 
                    resetZoomButton: {
                        position: {
                            align: 'left',
                            x: 20,
                            y: -20
                        }
                    },
                    plotOptions: {
                        series: {
                            stickyTracking: false
                        }
                    },

                    zoomType: 'x',

                },
                title: {
                    text: null
                },
                plotOptions: {
                    series: {
                        stickyTracking: false
                    }
                },
                yAxis: {
                    min: 0,
                    reversed: true,
                    title: {
                        text: 'Depth'
                    },
                    plotLines: [{
                        value: 0,
                        width: 5,
                        color: '#afcdf4'
                    }]
                },
                xAxis: {
                    min: 0,

                    opposite: true
                },
                legend: {},


                series: [
                    {

                        stickyTracking: false,
                        type: 'spline',
                        name: '',
                        data: [],
                        marker: {
                            enabled: true,

                        },



                    }
                ],

                plotOptions: {
                    series: {
                        animation: false
                    },


                },

            },
            newItemDepth: '',
            newItemValue: '',
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
        if (this.props.currentItem !== prevProps.currentItem) {
            console.log(this.props.currentItem)
            this.queryData(this.props.currentItem, this.props.parameters)
            this.setState({ currentItem: this.props.currentItem, loading: true })
        }

    }
    componentDidMount() {
        window.scrollTo(0, 0)
        const { currentItem, parameters } = this.state;
        this.queryData(currentItem, parameters)

    }

    queryData = async (currentItem, parameters) => {



        const { userInfo, chartOptions } = this.state;
        const db = getFirestore();
        const docRef = doc(db, "clients", userInfo.currentCompany, 'profiles', currentItem.key);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {


            this.setState({ currentItem: { ...docSnap.data(), ...currentItem }, loading: false })
            console.log({ ...docSnap.data(), ...currentItem, })
        }





    }

    updateTable = (index, boo, val) => {
        const { currentItem } = this.state;
        currentItem.data[index][boo] = val;
        this.setState({ currentItem })
        this.handleCheck(currentItem);
    }

    deletePoint = (index) => {
        const { currentItem, undoArray } = this.state;

        undoArray.push(currentItem.data[index]);
        currentItem.data.splice(index, 1);
        this.setState({ currentItem, undoArray })
        this.handleCheck(currentItem);

    }

    undoItem = () => {
        const { currentItem, undoArray } = this.state;
        currentItem.data.push(undoArray.pop());
        this.setState({ currentItem, undoArray })
        this.sortData(currentItem.data);

    }

    createItem = () => {
        const { newItem, currentItem, c } = this.state;
        currentItem.data.push(newItem);
        currentItem.data.sort((a, b) => a.depth - b.depth);
        const data = currentItem.data.map((item, index) => item);
        data.sort((a, b) => a.depth - b.depth);
        this.sortData(data);







    }

    sortData = (data) => {
        const { currentItem } = this.state;
        currentItem.data = [];
        this.setState({ currentItem })
        this.handleCheck(currentItem);

        setTimeout(() => {
            currentItem.data = [...data];
            this.setState({
                currentItem, newItem: {}, newItemDepth: '',
                newItemValue: '',
            })
        }, 50);
    }



    updateNewItem = (boo, val) => {
        const { newItem } = this.state;
        newItem[boo] = val;
        this.setState({ newItem })
    }

    highlightPoint = (index) => {
        const { currentItem } = this.state;
        console.log(index)
        currentItem.data.forEach(item => item.highlight = false);
        currentItem.data[index].highlight = true;
        this.setState({ currentItem })
    }
    highlightPoint2 = (index) => {
        const { currentItem } = this.state;
        this.focusTextInput(index);
        currentItem.data[index].highlight = false;
        this.setState({ currentItem })
    }

    focusTextInput = (index) => {
        // Explicitly focus the text input using the raw DOM API
        // Note: we're accessing "current" to get the DOM node
        this.accordionContent[index].focus();

    }

    unhighlightPoint = (index) => {
        const { currentItem } = this.state;
        currentItem.data.forEach(item => item.highlight = false);
        currentItem.data[index].highlight = false;
        this.setState({ currentItem })
    }


    handleCheck = (currentItem) => {
        // Clears running timer and starts a new one each time the user types
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.toggleCheck(currentItem);
        }, 1000);
    }

    toggleCheck = async (currentItem) => {
        this.updateCurrentItem(currentItem)
    }

    updateCurrentItem = async (currentItem) => {
        const { userInfo, chartOptions } = this.state;
        const db = getFirestore();

        const docRef = doc(db, "clients", userInfo.currentCompany, 'profiles', currentItem.key);
        //update data in firestore database
        await updateDoc(docRef, currentItem).then(() =>
            this.props.openSnack('success', 'Profile updated successfully'))

        this.setState({ currentItem })
    }





    render() {

        const { account, loading, chartOptions, currentItem, newItem, newItemDepth, newItemValue, editMode, showMarkers, undoArray } = this.state;




        const data = currentItem.data.map((d) => {
            return ({
                x: d.value, y: d.depth, color: d.highlight ? 'orange' : 'blue', marker: {
                    radius: d.highlight ? 10 : 4, zIndex: d.highlight ? 100 : 0,
                }
            })
        })
        chartOptions.series[0].data = data.sort((a, b) => a.y - b.y);

        chartOptions.series[0] = {
            ...chartOptions.series[0],
            cursor: 'pointer',
            point: {
                events: {
                    click: (a, b) => {
                        if (editMode === true) {
                            this.deletePoint(a.point.index)
                        }
                        else {
                            this.highlightPoint2(a.point.index)
                            this.highlightPoint(a.point.index)
                        }

                    }
                }
            }
        }

        const options = {
            ...chartOptions,
            tooltip: {
                formatter() {

                    const suffix = this.series.userOptions.suffix || '';
                    const valueDecimals = this.series.userOptions.decimals === 0 ? 0 : this.series.userOptions.decimals || 4;

                    return `<b>Depth: ${Number(this.point.y).toFixed(2)} FT</b><br/><b>${this.series.name}</b>: ${Number(this.point.x).toFixed(valueDecimals)} ${suffix}`;
                }
            }
        }


        if (loading) {
            return (
                <Row style={{}}>
                    <Col span={23}>
                        <CircularProgress />
                    </Col>

                </Row>
            )
        }
        else {
            return (
                <Row style={{}} gutter={[24, 24]}>
                    <Col span={12}>
                        <Typography variant="body1" fontSize={16} fontWeight={400}>Name: {currentItem.profileName}</Typography>
                        <Typography variant="body1" fontSize={16} fontWeight={400}>Parameter: {currentItem.parameter.newParameter.label}</Typography>
                        <Typography variant="body1" fontSize={16} fontWeight={400}>Date: {moment(currentItem.date, 'x').format('MM/DD/YYYY hh:mm a')}</Typography>
                        <Typography variant="body1" fontSize={16} fontWeight={400}>Site: {currentItem.site !== undefined ? currentItem.site.name : ''}</Typography>
                        <Button style={{ textTransform: 'none' }} variant={editMode ? 'contained' : 'outlined'} color={editMode ? 'primary' : 'secondary'}
                            onClick={() => {
                                this.sortData(currentItem.data);
                                this.setState({ editMode: !editMode })
                            }}>Edit</Button>
                        {editMode && (
                            <Button style={{ textTransform: 'none' }} variant={editMode ? 'outlined' : 'contained'} color={editMode ? 'secondary' : 'primary'}
                                onClick={() => {
                                    if (undoArray.length > 0) {
                                        this.undoItem()
                                    }
                                    else {
                                        this.props.openSnack('error', 'No more undo items')
                                    }


                                }}
                            >Undo</Button>
                        )}
                        <Button style={{ textTransform: 'none' }} variant={showMarkers ? 'contained' : 'outlined'} color={showMarkers ? 'primary' : 'secondary'}
                            onClick={() => {


                                chartOptions.series[0].marker.enabled = !chartOptions.series[0].marker.enabled;
                                this.setState({ chartOptions, showMarkers: !showMarkers })


                            }}>Show Markers</Button>

                        <Typography variant="body1" fontSize={16} fontWeight={400}>{currentItem.data.length} points</Typography>
                    </Col>
                    <Col span={12}>
                        <Row gutter={[16, 4]}>

                            <Col span={10}>
                                <Input onWheel={(e) => e.target.blur()} onChange={(e) => {
                                    this.updateNewItem('depth', Number(e.target.value))
                                    this.setState({ newItemDepth: e.target.value })
                                }}

                                    placeholder="Depth"
                                    type="number"
                                    value={newItemDepth}
                                />
                            </Col> <Col span={10}>
                                <Input onWheel={(e) => e.target.blur()} onChange={(e) => {
                                    this.updateNewItem('value', Number(e.target.value))
                                    this.setState({ newItemValue: e.target.value })

                                }}
                                    placeholder="Value"
                                    type="number"
                                    value={newItemValue}
                                />
                            </Col>
                            <Col span={4}>
                                <Button color="primary" variant="contained" onClick={() => { this.createItem() }} style={{ textTransform: 'none' }}>+ Add</Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <div ref={el => (this.container = el)} style={{ height: 400, width: '100%', }}>

                            {loading === false && (
                                <HighchartsReact
                                    constructorType={"chart"}
                                    highcharts={Highcharts}
                                    ref={"chart"}
                                    options={options}
                                    allowChartUpdate={true}
                                    immutable={false}
                                    updateArgs={[true, true, true]}
                                />
                            )}
                        </div>
                    </Col>
                    <Col span={12} >
                        <Row>
                            <Col span={24}>
                                <Row gutter={[16, 16]}>
                                    <Col span={10}>
                                        <Typography variant="body1" fontSize={16} fontWeight={500}>Depth</Typography>
                                    </Col>
                                    <Col span={10}>
                                        <Typography variant="body1" fontSize={16} fontWeight={500}>Value</Typography>
                                    </Col>
                                    <Col span={4}>
                                        <Typography variant="body1" fontSize={16} fontWeight={500}>Delete</Typography>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={24} style={{ height: 400, overflowY: 'scroll', overflowX: 'hidden' }} >
                                {currentItem.data.sort((a, b) => a.depth - b.depth).map((d, i) => {
                                    return (
                                        <Row gutter={[16, 4]}>
                                            <Col span={10}>
                                                <Input onWheel={(e) => e.target.blur()} inputRef={ref => (this.accordionContent[i] = ref)} onChange={(e) => {
                                                    this.updateTable(i, 'depth', Number(e.target.value))
                                                }}
                                                    type="number"
                                                    value={d.depth}
                                                    onBlur={(e) => { this.unhighlightPoint(i) }}
                                                    onFocus={(e) => { this.highlightPoint(i) }}
                                                />
                                            </Col> <Col span={10}>
                                                <Input onWheel={(e) => e.target.blur()} onChange={(e) => {
                                                    this.updateTable(i, 'value', Number(e.target.value))
                                                }}
                                                    type="number"
                                                    value={d.value}
                                                    onBlur={(e) => { this.unhighlightPoint(i) }}
                                                    onFocus={(e) => { this.highlightPoint(i) }}
                                                />
                                            </Col>
                                            <Col span={4}>
                                                <IconButton onClick={() => {
                                                    this.deletePoint(i)
                                                }} ><DeleteForever /></IconButton>
                                            </Col>
                                        </Row>

                                    )
                                })}
                            </Col>
                        </Row>




                    </Col>
                    <Col span={24}>
                        <Divider />
                    </Col>



                </Row >
            );
        }


    }
} 