import { faGear, faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Autocomplete, Box, Checkbox, Button, Collapse, Divider, Fab, FormControl, FormControlLabel, FormGroup, IconButton, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material';
import Tab from '@mui/material/Tab';
import { Col, Popconfirm, Row } from 'antd';
import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';
import moment from 'moment';
import React from 'react';
import { ReactSortable } from 'react-sortablejs';
import uuid from 'react-uuid';
import DefaultDatePicker from '../../../../blocks/defaultDatePicker';
import { fullMobile } from '../../../../products/use/util';
import DataColor from './dataColor';
import DataColorCompact from './dataColorCompact';

var randomColor = require('randomcolor');
export default class ProfileSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            attributes: this.props.attributes,
            currentWidgetKey: this.props.currentWidgetKey,
            locations: [],
            references: [],
            parameters: [],
            profiles: [],
            sites: [],
            activeTab: 'data',
            readData: this.props.readData
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
        if (this.props.readData !== prevProps.readData) {
            this.setState({ readData: this.props.readData })
            if (this.props.readData === true) {
                this.props.updateAttributes(this.state.attributes, this.state.currentWidgetKey)
                this.setState({ readData: false })
            }
        }

    }
    componentDidMount() {
        this.getLocations()
        this.getReferennces()
        this.getParameters();

    }

    getLocations = async () => {
        const { userInfo } = this.state;

        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'probes'), where('account', '==', userInfo.currentAccount), where('archived', '==', false));
        const queryDataSites = query(collection(db, "clients", userInfo.currentCompany, 'sampleSites'), where('account', '==', userInfo.currentAccount),);
        const locations = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            locations.push({ ...doc.data(), label: doc.data().name, type: 'probe' })
        });
        const snapSites = await getDocs(queryDataSites);
        snapSites.forEach((doc) => {
            locations.push({ ...doc.data(), label: doc.data().name, type: 'sample' })
        });
        locations.push({ label: 'Weather', key: 'Weather', name: 'Weather', type: 'weather' })
        locations.sort((a, b) => a.name.localeCompare(b.name))
        this.setState({ locations, })

    }
    getReferennces = async () => {
        const { userInfo } = this.state;

        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'references'));
        const references = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            references.push(doc.data())
        });

        references.sort((a, b) => a.label.localeCompare(b.label))
        this.setState({ references, })

    }

    updateWidget = (boo, val) => {
        const { attributes, currentWidgetKey } = this.state;
        attributes[boo] = val;
        this.setState({ attributes })


    }

    updateData = (index, boo, val) => {
        const { attributes } = this.state;
        attributes.chartData[index][boo] = val;

        this.updateWidget(attributes)

    }
    updateAxis = (index, boo, val) => {
        const { attributes } = this.state;
        attributes.axises[index][boo] = val;
        this.updateWidget(attributes)
    }
    updateReference = (index, boo, val) => {
        const { attributes } = this.state;
        attributes.references[index][boo] = val;
        this.updateWidget(attributes)
    }
    getParameters = async () => {
        const { userInfo, type } = this.state;
        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'parameters'));
        const parameters = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            parameters.push({ ...doc.data(), label: doc.data().name })


        });
        parameters.sort((a, b) => a.name.localeCompare(b.name))
        this.setState({ parameters, })
        this.getProfiles(parameters)
    }
    getProfiles = async (parameters) => {
        const { userInfo, level } = this.state;
        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'profiles'), where('archived', '==', false));
        const queryAccountData = query(collection(db, "clients", userInfo.currentCompany, 'profiles'), where('archived', '==', false), where('account', '==', userInfo.currentAccount));
        const data = [];
        const snap = await getDocs(level === 'account' ? queryAccountData : queryData);
        snap.forEach((doc) => {
            const parameter = parameters.filter(param => param.key === doc.data().parameterkey)[0];
            console.log(doc.data())

            data.push({ ...doc.data(), label: `${moment(doc.data().date, 'x').format('MM/DD/YYYY')} ${doc.data().profileName} ${parameter !== undefined ? parameter.name : ''}` })






        });
        console.log(data)
        data.sort((a, b) => a.profileName.localeCompare(b.profileName))
        this.setState({ profiles: data, loading: false })



    }

    handleCheck = (index, boo, val,) => {
        // Clears running timer and starts a new one each time the user types
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.toggleCheck(index, boo, val,);
        }, 500);
    }

    toggleCheck = async (index, boo, val) => {
        this.updateData(index, boo, val)
    }

    handleCheck1 = (index, boo, val,) => {
        // Clears running timer and starts a new one each time the user types
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.toggleCheck1(index, boo, val,);
        }, 500);
    }

    toggleCheck1 = async (index, boo, val) => {
        this.updateReference(index, boo, val)
    }
    handleCheckAxis = (index, boo, val,) => {
        // Clears running timer and starts a new one each time the user types
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.toggleCheckAxis(index, boo, val,);
        }, 500);
    }

    toggleCheckAxis = async (index, boo, val) => {
        this.updateAxis(index, boo, val)
    }

    sortSeries = (a) => {
        const { attributes } = this.state;
        const { chartData } = attributes;
        const mapData = a.map((d, i) => { return { ...d, index: i } })
        const sortData = mapData.sort((a, b) => a.index - b.index);
        this.updateWidget('chartData', sortData);

        attributes.chartData = sortData;
        this.setState({ attributes })
    }

    duplicateSeries = (index) => {
        const { attributes } = this.state;
        const { chartData } = attributes;
        const newSeries = { ...chartData[index], key: uuid() }
        chartData.splice(index + 1, 0, newSeries);
        this.updateWidget('chartData', chartData);
        attributes.chartData = chartData;
        this.setState({ attributes })

    }


    render() {

        const { account, attributes, screenWidth, locations, expanded, activeTab, references, currentWidgetKey, profiles, } = this.state;

        const DeleteItem = ({ row, type }) => {
            return (
                <Popconfirm
                    title={`Are you sure to delete this ${type}?`}
                    onConfirm={async () => {

                        if (type === 'axises') {
                            const checkSeries = attributes.chartData.filter((s) => s.xAxis === row.axisId);
                            if (checkSeries.length > 0) {
                                this.props.openSnack('error', `Cannot delete, a series is associated to this axis.`)
                            }
                            else {
                                this.props.openSnack('success', `${type === 'axises' ? "Axes" : "Data"} deleted.`)
                                const newData = attributes[type].filter((f) => f.key !== row.key);
                                attributes[type] = newData;
                                this.props.updateAttributes(attributes, currentWidgetKey)
                                this.setState({ attributes })
                            }
                        }
                        else {
                            this.props.openSnack('success', `${type === 'axises' ? "Axes" : "Data"} deleted.`)
                            const newData = attributes[type].filter((f) => f.key !== row.key);
                            attributes[type] = newData;
                            this.props.updateAttributes(attributes, currentWidgetKey)
                            this.setState({ attributes })
                        }




                    }}
                    okText="Yes"
                    cancelText="No"
                >
                    <IconButton style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="small"
                    >
                        <DeleteForeverIcon />
                    </IconButton>
                </Popconfirm>


            )
        }








        return (











            <Row justify="center" align="middle">
                <Col span={24}>
                    <Typography fontSize={16} variant='subtitle2'>Depth Profile Settings</Typography>
                </Col>
                <Col span={24}>
                    <TabContext value={activeTab}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={(e, tab) => this.setState({ activeTab: tab })} aria-label="lab API tabs example">
                                <Tab style={{ textTransform: 'none' }} label="Data" value="data" />
                                <Tab style={{ textTransform: 'none' }} label="Profile" value="profile" />
                                <Tab style={{ textTransform: 'none' }} label="Axes" value="axises" />
                                <Tab style={{ textTransform: 'none' }} label="References" value="references" />
                            </TabList>
                        </Box>
                        <TabPanel value="profile">
                            <Col xs={24} style={{ paddingTop: 12 }} >
                                <Row gutter={[12, 12]}>
                                    <Col span={24}>
                                        <Row>
                                            <Col span={24}>
                                                <Typography fontSize={13} variant="body1">Depth label</Typography>
                                            </Col>
                                            <Col span={24}>
                                                <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                    defaultValue={attributes.depthLabel || ''}
                                                    style={{ backgroundColor: 'white' }}
                                                    onChange={(e) => {
                                                        this.updateWidget('depthLabel', e.target.value)
                                                    }} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={8}>
                                        <Row>
                                            <Col span={24}>
                                                <Typography fontSize={13} variant="body1">Custom Depth</Typography>
                                            </Col>
                                            <Col span={24}>
                                                <Checkbox checked={attributes.customDepth}
                                                    onChange={(e) => {
                                                        this.updateWidget('customDepth', e.target.checked)
                                                    }} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={8}>
                                        {attributes.customDepth === true && (<Row>
                                            <Col span={24}>
                                                <Typography fontSize={13} variant="body1">Min Depth</Typography>
                                            </Col>
                                            <Col span={24}>
                                                <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                    defaultValue={attributes.minDepth || 0}
                                                    type="number"
                                                    inputProps={{ min: 0 }}
                                                    onChange={(e) => {
                                                        this.updateWidget('minDepth', e.target.value === "" ? "" : Number(e.target.value))
                                                    }} />
                                            </Col>
                                        </Row>)}
                                    </Col>
                                    <Col span={8}>
                                        {attributes.customDepth === true && (<Row>
                                            <Col span={24}>
                                                <Typography fontSize={13} variant="body1">Max Depth</Typography>
                                            </Col>
                                            <Col span={24}>
                                                <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                    defaultValue={attributes.maxDepth || 20}
                                                    type="number"
                                                    inputProps={{ min: 0 }}
                                                    onChange={(e) => {
                                                        this.updateWidget('maxDepth', e.target.value === "" ? "" : Number(e.target.value))
                                                    }} />
                                            </Col>
                                        </Row>)}
                                    </Col>

                                </Row>
                            </Col>
                            <Col xs={24} style={{ paddingTop: 12 }} >
                                <Row>
                                    <Col span={24}>
                                        <Typography fontSize={14} fontWeight={500} variant="body1">Settings</Typography>
                                    </Col>
                                    <Col span={24}>
                                        <FormControl component="settings">

                                            <FormGroup row>

                                                {[
                                                    { label: 'Share Axis', key: 'shareAxis' },
                                                    { label: 'Legend', key: 'legend' },

                                                ].map((r) => {
                                                    return (
                                                        <FormControlLabel
                                                            value={r.label}
                                                            control={
                                                                <Checkbox checked={attributes[r.key]}
                                                                    onChange={(e) => {
                                                                        this.updateWidget(r.key, e.target.checked)
                                                                    }} />
                                                            }
                                                            label={r.label}
                                                            labelPlacement="start"
                                                        />
                                                    )
                                                })}




                                            </FormGroup>
                                        </FormControl>
                                    </Col>
                                    <Col {...fullMobile(5, 12)} style={{ textAlign: 'center' }}>
                                        <Row>
                                            <Col span={24} style={{ textAlign: 'center' }}>
                                                <Typography fontSize={13} variant="body1">Y Axis Labels Font Size</Typography>
                                            </Col>
                                            <Col span={24} style={{ textAlign: 'center' }}>
                                                <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                    defaultValue={attributes.yAxisFontSize || 12}
                                                    type="number"
                                                    inputProps={{ min: 0 }}
                                                    onChange={(e) => {
                                                        this.updateWidget('yAxisFontSize', e.target.value === "" ? "" : Number(e.target.value))
                                                    }} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col {...fullMobile(5, 12)} style={{ textAlign: 'center' }}>
                                        <Box style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}>
                                            <Typography fontSize={13} variant="body1">Y Axis Labels/Line Color</Typography>
                                            <DataColorCompact color={attributes.yAxisLabelColor || '#000000'}
                                                updateData={(boo, val) =>
                                                    this.updateWidget('yAxisLabelColor', val)}
                                            />
                                        </Box>
                                    </Col>
                                    <Col {...fullMobile(5, 12)} style={{ textAlign: 'center' }}>
                                        <Row>
                                            <Col span={24} style={{ textAlign: 'center' }}>
                                                <Typography fontSize={13} variant="body1">Y Axis Title Font Size</Typography>
                                            </Col>
                                            <Col span={24} style={{ textAlign: 'center' }}>
                                                <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                    defaultValue={attributes.yAxisTitleFontSize || 12}
                                                    type="number"
                                                    inputProps={{ min: 0 }}
                                                    onChange={(e) => {
                                                        this.updateWidget('yAxisTitleFontSize', e.target.value === "" ? "" : Number(e.target.value))
                                                    }} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col {...fullMobile(5, 12)} style={{ textAlign: 'center' }}>
                                        <Box style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}>
                                            <Typography fontSize={13} variant="body1">Y Axis Font Color</Typography>
                                            <DataColorCompact color={attributes.yAxisTitleLabelColor || '#000000'}
                                                updateData={(boo, val) =>
                                                    this.updateWidget('yAxisTitleLabelColor', val)}
                                            />
                                        </Box>
                                    </Col>
                                    <Col {...fullMobile(6, 12)} style={{ textAlign: 'center' }}>
                                        <Row>
                                            <Col span={24} style={{ textAlign: 'center' }}>
                                                <Typography fontSize={13} variant="body1">Title Font Weight</Typography>
                                            </Col>
                                            <Col span={24} style={{ textAlign: 'center' }}>
                                                <Select size="small" fullWidth
                                                    notched={false}
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={attributes.yAxisTitleFontWeight || 300}
                                                    label="Layer"
                                                    onChange={(e) => {
                                                        this.updateWidget('yAxisTitleFontWeight', e.target.value)
                                                    }}

                                                >
                                                    <MenuItem value={400}>400</MenuItem>
                                                    <MenuItem value={500}>500</MenuItem>
                                                    <MenuItem value={600}>600</MenuItem>
                                                    <MenuItem value={700}>700</MenuItem>
                                                    <MenuItem value={800}>800</MenuItem>
                                                </Select>
                                            </Col>
                                        </Row>

                                    </Col>
                                </Row>
                            </Col>
                        </TabPanel>
                        <TabPanel value="data">
                            <Col xs={24} style={{ paddingTop: 4 }} >
                                <Row align="middle" gutter={[12, 12]}>

                                    <Col span={20}>
                                        <Typography fontSize={14} fontWeight={500} variant="body1">Data Series</Typography>
                                    </Col>
                                    <Col span={4}>
                                        <Fab style={{ position: 'relative', zIndex: 0 }} size="small" variant='contained' color="primary" onClick={() => {
                                            const item = {
                                                key: uuid(),
                                                parameter: {},
                                                color: randomColor(),
                                                markerFill: randomColor(),
                                                markerLine: randomColor(),
                                                type: 'spline',
                                                dashed: 'Solid',
                                                lineWidth: 3,
                                                xAxis: "0",
                                                tooltip: {},
                                                marker: {},
                                                offset: 0,
                                                units: ''
                                            };
                                            if (attributes.chartData) {
                                                attributes.chartData.push(item);


                                                this.sortSeries(attributes.chartData);
                                                this.setState({ attributes });

                                            }
                                            else {
                                                attributes.chartData = [];
                                                attributes.chartData.push(item);
                                                this.sortSeries(attributes.chartData);
                                                this.setState({ attributes });

                                            }
                                        }}>
                                            <AddIcon />
                                        </Fab>
                                    </Col>

                                    <Col span={24} style={{ paddingTop: 6 }}>
                                        <Divider />
                                    </Col>

                                    <Col span={24}>
                                        {attributes.chartData !== undefined && attributes.chartData.length > 0 && (
                                            <ReactSortable
                                                handle=".my-handle"
                                                animation={500}
                                                list={attributes.chartData}
                                                setList={(newState) => {
                                                    attributes.chartData = newState;
                                                    this.sortSeries(newState);
                                                    this.setState({ attributes })
                                                }}
                                            >

                                                {attributes.chartData.map((d, i) => {

                                                    const input = d.decimals === 0 || d.decimals ? d.decimals : '';


                                                    return (
                                                        <Row align="middle" gutter={[8, 8]} style={{ paddingTop: 5, backgroundColor: i % 2 === 0 ? 'inherit' : 'rgba(0,0,0,0.05)' }} key={d.key}>
                                                            <Col span={2} className="my-handle">
                                                                <DragIndicatorIcon />
                                                            </Col>


                                                            <Col span={14}>
                                                                <Row>
                                                                    <Col span={24}>
                                                                        <Typography fontSize={13} variant="body1">Profile</Typography>
                                                                    </Col>
                                                                    <Col span={24}>

                                                                        <Autocomplete
                                                                            disableClearable
                                                                            size="small"
                                                                            disablePortal
                                                                            id="combo-box-demo"
                                                                            options={profiles}
                                                                            value={d.profile || ''}
                                                                            style={{ backgroundColor: 'white' }}
                                                                            renderInput={(params) => (
                                                                                <TextField
                                                                                    {...params}
                                                                                    placeholder="Profile"
                                                                                    variant="outlined"
                                                                                />
                                                                            )}
                                                                            onChange={(a, b) => {
                                                                                delete b.data;
                                                                                this.updateData(i, 'profile', b);
                                                                            }}

                                                                        />

                                                                    </Col>
                                                                </Row>
                                                            </Col>




                                                            <Col span={4} style={{ textAlign: 'center' }} >
                                                                <IconButton onClick={() => {
                                                                    this.updateData(i, 'advancedSettings', d.advancedSettings ? !d.advancedSettings : true)
                                                                }}>
                                                                    <FontAwesomeIcon fontSize={18} icon={faGear} color="#9ec1c9" />
                                                                </IconButton>
                                                            </Col>
                                                            <Col {...fullMobile(4, 4)} style={{}}>
                                                                <Row>
                                                                    <Col span={24}>
                                                                        <Button size="small" variant="contained" color="primary" onClick={() => {
                                                                            //duplicate series
                                                                            this.duplicateSeries(i)

                                                                        }}
                                                                            style={{
                                                                                color: 'white',
                                                                                backgroundColor: '#404050',

                                                                            }}
                                                                        >
                                                                            <FontAwesomeIcon fontSize={14} icon={faCopy} color="white" />
                                                                        </Button>
                                                                    </Col>
                                                                </Row>
                                                            </Col>

                                                            <Col span={2} className="my-handle">

                                                            </Col>




                                                            <Col span={5}>
                                                                <Row>
                                                                    <Col span={24}>
                                                                        <Typography fontSize={13} variant="body1">Label</Typography>
                                                                    </Col>
                                                                    <Col span={24}>
                                                                        <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                                            defaultValue={d.label || ''}
                                                                            style={{ backgroundColor: 'white' }}
                                                                            onChange={(e) => {
                                                                                this.handleCheck(i, 'label', e.target.value)
                                                                            }} />
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col span={4}>
                                                                <Row>
                                                                    <Col span={24}>
                                                                        <Typography fontSize={13} variant="body1">Suffix</Typography>
                                                                    </Col>
                                                                    <Col span={24}>
                                                                        <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                                            defaultValue={d.suffix || ''}
                                                                            style={{ backgroundColor: 'white' }}
                                                                            onChange={(e) => {
                                                                                this.handleCheck(i, 'suffix', e.target.value)
                                                                            }} />
                                                                    </Col>
                                                                </Row>
                                                            </Col>




                                                            <Col span={3}>
                                                                <Row>
                                                                    <Col span={24}>
                                                                        <Typography fontSize={13} variant="body1">Color</Typography>
                                                                    </Col>
                                                                    <Col span={24}>
                                                                        <DataColorCompact color={d.color} updateData={(boo, val) => this.updateData(i, boo, val)} />
                                                                    </Col>
                                                                </Row>


                                                            </Col>
                                                            <Col span={2}>
                                                                <DeleteItem row={d} type="chartData" />
                                                            </Col>
                                                            <Col offset={2} span={22}>
                                                                <Collapse in={d.advancedSettings || false} timeout="auto" unmountOnExit>
                                                                    <Row align="middle" gutter={[8, 8]}>
                                                                        <Col span={24} style={{ paddingTop: 8 }}>
                                                                            <Typography fontSize={14} fontWeight={500} variant="body1">Advanced</Typography>
                                                                        </Col>
                                                                        <Col span={6}>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Typography fontSize={13} variant="body1">Dash</Typography>
                                                                                </Col>
                                                                                <Col span={24}>
                                                                                    <Select size="small" fullWidth
                                                                                        notched={false}
                                                                                        labelId="demo-simple-select-label"
                                                                                        id="demo-simple-select"
                                                                                        value={d.dashed || 'Solid'}
                                                                                        label="Dash"
                                                                                        onChange={(e) => {
                                                                                            this.updateData(i, 'dashed', e.target.value)
                                                                                        }}
                                                                                        style={{ backgroundColor: 'white' }}

                                                                                    >
                                                                                        <MenuItem value={'Solid'}>Solid</MenuItem>
                                                                                        <MenuItem value={'ShortDash'}>Short Dash</MenuItem>
                                                                                        <MenuItem value={'Dash'}>Dash</MenuItem>
                                                                                        <MenuItem value={'LongDash'}>Long Dash</MenuItem>
                                                                                        <MenuItem value={'Dot'}>Dot</MenuItem>
                                                                                    </Select>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col span={4}>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Typography fontSize={13} variant="body1">Units</Typography>
                                                                                </Col>
                                                                                <Col span={24}>
                                                                                    <Select size="small" fullWidth
                                                                                        notched={false}
                                                                                        labelId="demo-simple-select-label"
                                                                                        id="demo-simple-select"
                                                                                        value={d.units || ''}
                                                                                        style={{ backgroundColor: 'white' }}
                                                                                        label="Units"
                                                                                        onChange={(e) => {
                                                                                            this.updateData(i, 'units', e.target.value)
                                                                                        }}

                                                                                    >

                                                                                        {d.parameterid === '1' && (
                                                                                            ['C', 'F'].map((f) => {
                                                                                                return (
                                                                                                    <MenuItem value={f}>{f}</MenuItem>
                                                                                                )
                                                                                            })
                                                                                        )}
                                                                                        {d.parameterid === '3' && (
                                                                                            ['m', 'ft'].map((f) => {
                                                                                                return (
                                                                                                    <MenuItem value={f}>{f}</MenuItem>
                                                                                                )
                                                                                            })
                                                                                        )}



                                                                                    </Select>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col span={4}>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Typography fontSize={13} variant="body1">Line Width</Typography>
                                                                                </Col>
                                                                                <Col span={24}>
                                                                                    <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                                                        defaultValue={d.lineWidth || 2}
                                                                                        style={{ backgroundColor: 'white' }}
                                                                                        type="number"
                                                                                        inputProps={{ min: 0 }}
                                                                                        onChange={(e) => {
                                                                                            this.handleCheck(i, 'lineWidth', e.target.value === "" ? "" : Number(e.target.value))
                                                                                        }} />
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>

                                                                        <Col span={4}>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Typography fontSize={13} variant="body1">Opacity</Typography>
                                                                                </Col>
                                                                                <Col span={24}>
                                                                                    <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"

                                                                                        defaultValue={d.opacity || ''}
                                                                                        style={{ backgroundColor: 'white' }}
                                                                                        type="number"
                                                                                        inputProps={{ min: 0 }}
                                                                                        onChange={(e) => {
                                                                                            this.handleCheck(i, 'opacity', e.target.value === "" ? "" : Number(e.target.value))
                                                                                        }} />
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        {d.type === 'column' && (<Col span={4}>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Typography fontSize={13} variant="body1">Column Width</Typography>
                                                                                </Col>
                                                                                <Col span={24}>
                                                                                    <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                                                        defaultValue={d.columnWidth || 10}
                                                                                        style={{ backgroundColor: 'white' }}
                                                                                        type="number"
                                                                                        inputProps={{ min: 0 }}
                                                                                        onChange={(e) => {
                                                                                            this.handleCheck(i, 'columnWidth', e.target.value === "" ? "" : Number(e.target.value))
                                                                                        }} />
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>)}
                                                                        <Col span={4}>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Typography fontSize={13} variant="body1">Dec. Places</Typography>
                                                                                </Col>
                                                                                <Col span={24}>
                                                                                    <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                                                        id={`${d.key}value`}
                                                                                        defaultValue={input}
                                                                                        style={{ backgroundColor: 'white' }}
                                                                                        type="number"
                                                                                        onChange={(e) => {

                                                                                            this.handleCheck(i, 'decimals', e.target.value === "" ? Number(0) : e.target.value === "0" ? Number(0) : Number(e.target.value))

                                                                                        }} />
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>

                                                                        <Col span={4}>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Typography fontSize={13} variant="body1">Offset</Typography>
                                                                                </Col>
                                                                                <Col span={24}>
                                                                                    <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                                                        defaultValue={d.offset || ''}
                                                                                        style={{ backgroundColor: 'white' }}
                                                                                        type="number"
                                                                                        onChange={(e) => {
                                                                                            this.handleCheck(i, 'offset', e.target.value === "" ? "" : Number(e.target.value))
                                                                                        }} />
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col span={4}>
                                                                            <Row>
                                                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                                                    <Typography fontSize={13} variant="body1">Dots</Typography>
                                                                                </Col>
                                                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                                                    <Checkbox checked={d.dots}
                                                                                        onChange={(e) => {
                                                                                            this.updateData(i, 'dots', e.target.checked)
                                                                                        }} />
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>

                                                                        <Col span={24} style={{ paddingTop: 8 }}>
                                                                            <Typography fontSize={14} fontWeight={500} variant="body2">Axis Options</Typography>
                                                                        </Col>
                                                                        <Col span={8}>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Typography fontSize={13} variant="body1">X-Axis ID</Typography>
                                                                                </Col>
                                                                                <Col span={24}>

                                                                                    <Select size="small" fullWidth
                                                                                        notched={false}
                                                                                        labelId="demo-simple-select-label"
                                                                                        id="demo-simple-select"
                                                                                        value={d.xAxis || "0"}
                                                                                        label="Dash"
                                                                                        onChange={(e) => {
                                                                                            this.updateData(i, 'xAxis', e.target.value)
                                                                                        }}
                                                                                        style={{ backgroundColor: 'white' }}

                                                                                    >
                                                                                        {attributes.axises.map((a) => {
                                                                                            return (
                                                                                                <MenuItem value={a.axisId}>{a.label || a.axisId}</MenuItem>
                                                                                            )
                                                                                        })}


                                                                                    </Select>


                                                                                </Col>
                                                                            </Row>
                                                                        </Col>


                                                                        <Col span={24} style={{ paddingTop: 8 }}>
                                                                            <Typography fontSize={14} fontWeight={500} variant="body2">Marker Options</Typography>
                                                                        </Col>
                                                                        <Col span={8}>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Typography fontSize={13} variant="body1">Fill</Typography>
                                                                                </Col>
                                                                                <Col span={24}>
                                                                                    <DataColorCompact color={d.markerFill} updateData={(boo, val) => this.updateData(i, 'markerFill', val)} />
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col span={8}>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Typography fontSize={13} variant="body1">Line</Typography>
                                                                                </Col>
                                                                                <Col span={24}>
                                                                                    <DataColorCompact color={d.markerLine} updateData={(boo, val) => this.updateData(i, 'markerLine', val)} />
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>

                                                                    </Row>
                                                                </Collapse>
                                                            </Col>

                                                            <Col span={24}>
                                                                <Divider />
                                                            </Col>


                                                        </Row>
                                                    )
                                                })}

                                            </ReactSortable>)}
                                    </Col>
                                </Row>
                            </Col ></TabPanel>
                        <TabPanel value="axises">
                            <Col xs={24} style={{ paddingTop: 4 }} >
                                <Row align="middle" gutter={[12, 12]}>
                                    <Col span={20}>
                                        <Typography fontSize={14} fontWeight={500} variant="body1">Axes</Typography>
                                    </Col>
                                    <Col span={4}>
                                        <Fab style={{ position: 'relative', zIndex: 0 }} size="small" variant='contained' color="primary" onClick={() => {
                                            const idNumber = attributes.axises !== undefined ? Math.max.apply(Math, attributes.axises.map((o) => { return Number(o.axisId) + 1; })) : 0
                                            const item = {
                                                key: uuid(),
                                                axisId: idNumber.toString(),
                                                id: idNumber.toString(),
                                                label: '',
                                                opposite: false,
                                                customRange: false,
                                                min: null,
                                                max: null,
                                                labelRotation: 270,
                                                axisColor: '#000000',
                                                gridLineColor: '#000000',
                                                gridLineDashStyle: 'Solid',
                                                gridLineWidth: 0.3,
                                                alignTicks: true,
                                                endOntick: true,
                                                reversed: false,
                                                advancedSettings: false,
                                                title: {
                                                    text: ''
                                                },
                                                plotLines: []

                                            };

                                            if (attributes.axises) {
                                                attributes.axises.push(item);
                                                this.setState({ attributes });

                                            }
                                            else {
                                                attributes.axises = [];
                                                attributes.axises.push(item);
                                                this.setState({ attributes });

                                            }
                                        }}>
                                            <AddIcon />
                                        </Fab>
                                    </Col>

                                    <Col span={24} style={{ paddingTop: 6 }}>
                                        <Divider />
                                    </Col>
                                    <Col span={24} style={{ paddingTop: 6 }}>

                                        {attributes.axises !== undefined && attributes.axises.length > 0 && (
                                            <ReactSortable
                                                handle=".my-handle"
                                                animation={500}
                                                list={attributes.axises}
                                                setList={(newState) => {
                                                    attributes.axises = newState;
                                                    this.setState({ attributes })
                                                }}
                                            >

                                                {attributes.axises.map((d, i) => {


                                                    return (
                                                        <Row align="middle" gutter={[8, 8]} style={{ paddingTop: 5, backgroundColor: i % 2 === 0 ? 'inherit' : 'rgba(0,0,0,0.05)' }} key={d.key}>
                                                            <Col span={2} className="my-handle">
                                                                <DragIndicatorIcon />
                                                            </Col>
                                                            <Col span={4}>
                                                                <Row>
                                                                    <Col span={24}>
                                                                        <Typography fontSize={13} variant="body1">ID</Typography>
                                                                    </Col>
                                                                    <Col span={24}>
                                                                        <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                                            defaultValue={d.axisId}
                                                                            disabled
                                                                            type="number"
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col span={6}>
                                                                <Row>
                                                                    <Col span={24}>
                                                                        <Typography fontSize={13} variant="body1">Label</Typography>
                                                                    </Col>
                                                                    <Col span={24}>
                                                                        <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                                            defaultValue={d.label || ''}
                                                                            onChange={(e) => {
                                                                                this.handleCheckAxis(i, 'label', e.target.value)
                                                                            }} />
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col span={4}>
                                                                <Row>
                                                                    <Col span={24} style={{ textAlign: 'center' }}>
                                                                        <Typography fontSize={13} variant="body1">Opposite</Typography>
                                                                    </Col>
                                                                    <Col span={24} style={{ textAlign: 'center' }}>
                                                                        <Checkbox checked={d.opposite}
                                                                            onChange={(e) => {
                                                                                this.updateAxis(i, 'opposite', e.target.checked)
                                                                            }} />
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col span={4} >
                                                                <IconButton onClick={() => {
                                                                    this.updateAxis(i, 'advancedSettings', d.advancedSettings ? !d.advancedSettings : true)
                                                                }}>
                                                                    <FontAwesomeIcon fontSize={18} icon={faGear} color="#9ec1c9" />
                                                                </IconButton>
                                                            </Col>


                                                            <Col span={4}>
                                                                {d.axisId !== 0 && (<DeleteItem row={d} type="axises" />)}
                                                            </Col>
                                                            <Col span={2} className="my-handle">

                                                            </Col>
                                                            <Col span={22}>
                                                                <Collapse in={d.advancedSettings || false} timeout="auto" unmountOnExit>
                                                                    <Row align="middle" gutter={[8, 8]}>
                                                                        <Col span={24} style={{ paddingTop: 8 }}>
                                                                            <Typography fontSize={14} fontWeight={500} variant="body1">Advanced</Typography>
                                                                        </Col>
                                                                        <Col span={8}>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Typography fontSize={13} variant="body1">Custom Range</Typography>
                                                                                </Col>
                                                                                <Col span={24}>
                                                                                    <Checkbox checked={d.customRange}
                                                                                        onChange={(e) => {
                                                                                            this.updateAxis(i, 'customRange', e.target.checked)
                                                                                        }} />
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col span={8}>
                                                                            {d.customRange === true && (<Row>
                                                                                <Col span={24}>
                                                                                    <Typography fontSize={13} variant="body1">Min</Typography>
                                                                                </Col>
                                                                                <Col span={24}>
                                                                                    <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                                                        defaultValue={d.min}
                                                                                        type="number"
                                                                                        inputProps={{ min: 0 }}
                                                                                        onChange={(e) => {
                                                                                            this.handleCheckAxis(i, 'min', e.target.value === "" ? "" : Number(e.target.value))
                                                                                        }} />
                                                                                </Col>
                                                                            </Row>)}
                                                                        </Col>
                                                                        <Col span={8}>
                                                                            {d.customRange === true && (<Row>
                                                                                <Col span={24}>
                                                                                    <Typography fontSize={13} variant="body1">Max</Typography>
                                                                                </Col>
                                                                                <Col span={24}>
                                                                                    <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                                                        defaultValue={d.max}
                                                                                        type="number"
                                                                                        inputProps={{ min: 0 }}
                                                                                        onChange={(e) => {
                                                                                            this.handleCheckAxis(i, 'max', e.target.value === "" ? "" : Number(e.target.value))
                                                                                        }} />
                                                                                </Col>
                                                                            </Row>)}
                                                                        </Col>
                                                                        <Col span={8}>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Typography fontSize={13} variant="body1">Dash</Typography>
                                                                                </Col>
                                                                                <Col span={24}>
                                                                                    <Select size="small" fullWidth
                                                                                        notched={false}
                                                                                        labelId="demo-simple-select-label"
                                                                                        id="demo-simple-select"
                                                                                        value={d.gridlineDashed || 'Solid'}
                                                                                        label="Dash"
                                                                                        onChange={(e) => {
                                                                                            this.updateAxis(i, 'gridlineDashed', e.target.value)
                                                                                        }}

                                                                                    >
                                                                                        <MenuItem value={'Solid'}>Solid</MenuItem>
                                                                                        <MenuItem value={'ShortDash'}>Short Dash</MenuItem>
                                                                                        <MenuItem value={'Dash'}>Dash</MenuItem>
                                                                                        <MenuItem value={'LongDash'}>Long Dash</MenuItem>
                                                                                        <MenuItem value={'Dot'}>Dot</MenuItem>
                                                                                    </Select>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col span={8}>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Typography fontSize={13} variant="body1">Grid Width</Typography>
                                                                                </Col>
                                                                                <Col span={24}>
                                                                                    <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                                                        defaultValue={d.gridLineWidth}
                                                                                        type="number"
                                                                                        inputProps={{ min: 0 }}
                                                                                        onChange={(e) => {
                                                                                            this.handleCheckAxis(i, 'gridLineWidth', e.target.value === "" ? "" : Number(e.target.value))
                                                                                        }} />
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col span={8}>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Typography fontSize={13} variant="body1">Label Rotation</Typography>
                                                                                </Col>
                                                                                <Col span={24}>
                                                                                    <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                                                        defaultValue={d.labelRotation}
                                                                                        type="number"
                                                                                        inputProps={{ min: 0 }}
                                                                                        onChange={(e) => {
                                                                                            this.handleCheckAxis(i, 'labelRotation', e.target.value === "" ? "" : Number(e.target.value))
                                                                                        }} />
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>

                                                                        <Col span={8}>
                                                                            <Row>
                                                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                                                    <Typography fontSize={13} variant="body1">Align Ticks</Typography>
                                                                                </Col>
                                                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                                                    <Checkbox checked={d.alignTicks}
                                                                                        onChange={(e) => {
                                                                                            this.updateAxis(i, 'alignTicks', e.target.checked)
                                                                                        }} />
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col span={8}>
                                                                            <Row>
                                                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                                                    <Typography fontSize={13} variant="body1">End On Tick</Typography>
                                                                                </Col>
                                                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                                                    <Checkbox checked={d.endOnTick}
                                                                                        onChange={(e) => {
                                                                                            this.updateAxis(i, 'endOnTick', e.target.checked)
                                                                                        }} />
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col span={8}>
                                                                            <Row>
                                                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                                                    <Typography fontSize={13} variant="body1">Reversed</Typography>
                                                                                </Col>
                                                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                                                    <Checkbox checked={d.reversed}
                                                                                        onChange={(e) => {
                                                                                            this.updateAxis(i, 'reversed', e.target.checked)
                                                                                        }} />
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>


                                                                        <Col span={24} style={{ paddingTop: 8 }}>
                                                                            <Typography fontSize={14} fontWeight={500} variant="body2">Color</Typography>
                                                                        </Col>
                                                                        <Col span={8}>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Typography fontSize={13} variant="body1">Axis Color</Typography>
                                                                                </Col>
                                                                                <Col span={24}>
                                                                                    <DataColor color={d.axisColor} updateData={(boo, val) => this.updateAxis(i, 'axisColor', val)} />
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col span={8}>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Typography fontSize={13} variant="body1">Grid Color</Typography>
                                                                                </Col>
                                                                                <Col span={24}>
                                                                                    <DataColor color={d.gridLineColor} updateData={(boo, val) => this.updateAxis(i, 'gridLineColor', val)} />
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>

                                                                    </Row>
                                                                </Collapse>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Divider />
                                                            </Col>

                                                        </Row>
                                                    )
                                                })}
                                            </ReactSortable>)}
                                    </Col>


                                </Row>
                            </Col>
                        </TabPanel>
                        <TabPanel value="references">
                            <Col xs={24} style={{ paddingTop: 4 }} >
                                <Row align="middle" gutter={[12, 12]}>
                                    <Col span={20}>
                                        <Typography fontSize={14} fontWeight={500} variant="body1">References</Typography>
                                    </Col>
                                    <Col span={4}>
                                        <Fab style={{ position: 'relative', zIndex: 0 }} size="small" variant='contained' color="primary" onClick={() => {
                                            const item = {
                                                key: uuid(),
                                                xAxis: "0",
                                                yAxis: "0",
                                                axisType: 'yAxis',
                                                reference: null,
                                                date: moment().format('x'),
                                                labelRotation: 270,
                                                color: '#000000',
                                                referenceDashed: 'Solid',
                                                width: 3,
                                                layer: 20,
                                                dashStyle: 'Solid',
                                                verticalAlign: 'top',
                                                textAlign: 'left',
                                                offset: 0,
                                                advancedSettings: false,
                                                useA: false,
                                                title: '',
                                                alias: '',
                                                label: {}
                                            };

                                            if (attributes.references) {
                                                attributes.references.push(item);
                                                this.setState({ attributes });

                                            }
                                            else {
                                                attributes.references = [];
                                                attributes.references.push(item);
                                                this.setState({ attributes });

                                            }
                                        }}>
                                            <AddIcon />
                                        </Fab>
                                    </Col>

                                    <Col span={24} style={{ paddingTop: 6 }}>
                                        <Divider />
                                    </Col>
                                    <Col span={24} style={{ paddingTop: 6 }}>

                                        {attributes.references !== undefined && attributes.references.length > 0 && (
                                            <ReactSortable
                                                handle=".my-handle"
                                                animation={500}
                                                list={attributes.references}
                                                setList={(newState) => {
                                                    attributes.references = newState;
                                                    this.setState({ attributes })
                                                }}
                                            >

                                                {attributes.references.map((d, i) => {

                                                    return (
                                                        <Row align="middle" gutter={[8, 8]} style={{ paddingBottom: 5 }} key={d.key}>
                                                            <Col span={2} className="my-handle">
                                                                <DragIndicatorIcon />
                                                            </Col>
                                                            <Col span={8}>
                                                                <Row>
                                                                    <Col span={24}>
                                                                        <Typography fontSize={13} variant="body1">Reference</Typography>
                                                                    </Col>
                                                                    <Col span={24}>
                                                                        <Autocomplete
                                                                            disableClearable
                                                                            size="small"
                                                                            disablePortal
                                                                            id="combo-box-demo"
                                                                            options={references}
                                                                            value={d.reference || ''}
                                                                            renderInput={(params) => (
                                                                                <TextField
                                                                                    {...params}
                                                                                    label="Reference"
                                                                                    variant="outlined"
                                                                                />
                                                                            )}
                                                                            onChange={(a, b) => {
                                                                                this.updateReference(i, 'referenceid', b.key);
                                                                                this.updateReference(i, 'horizontal', b.horizontal || false);
                                                                                this.updateReference(i, 'yValue', b.yValue || false);
                                                                                this.updateReference(i, 'xValue', b.yValue || false);
                                                                                this.updateReference(i, 'date', b.date || false);
                                                                                this.updateReference(i, 'reference', b);
                                                                                this.updateReference(i, 'title', b.label);
                                                                            }}

                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </Col>

                                                            <Col span={6}>
                                                                <Row>
                                                                    <Col span={24}>
                                                                        <Typography fontSize={13} variant="body1">Axis</Typography>
                                                                    </Col>
                                                                    <Col span={24}>
                                                                        <Select size="small" fullWidth
                                                                            notched={false}
                                                                            labelId="demo-simple-select-label"
                                                                            id="demo-simple-select"
                                                                            defaultValue={d.axisType || 'yAxis'}
                                                                            label="Dash"
                                                                            onChange={(e) => {
                                                                                this.updateReference(i, 'axisType', e.target.value)
                                                                            }}

                                                                        >
                                                                            <MenuItem value={'yAxis'}>Y-Axis</MenuItem>
                                                                            <MenuItem value={'xAxis'}>X-Axis</MenuItem>

                                                                        </Select>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            {d.axisType === 'xAxis' && (
                                                                <Col span={4}>
                                                                    <Row>
                                                                        <Col span={24}>
                                                                            <Typography fontSize={13} variant="body1">Axis ID</Typography>
                                                                        </Col>
                                                                        <Col span={24}>
                                                                            <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                                                defaultValue={d.xAxis || "1"}
                                                                                type="number"
                                                                                inputProps={{ min: 0 }}
                                                                                onChange={(e) => {
                                                                                    this.handleCheck1(i, 'xAxis', e.target.value === "" ? "0" : e.target.value)
                                                                                }} />
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            )}


                                                            <Col span={2} >
                                                                <IconButton onClick={() => {
                                                                    this.updateReference(i, 'advancedSettings', d.advancedSettings ? !d.advancedSettings : true)
                                                                }}>
                                                                    <FontAwesomeIcon fontSize={18} icon={faGear} color="#9ec1c9" />
                                                                </IconButton>
                                                            </Col>
                                                            <Col span={2}>
                                                                {d.axisId !== 0 && (<DeleteItem row={d} type="references" />)}
                                                            </Col>
                                                            <Col span={24}>
                                                                <Collapse in={d.advancedSettings || false} timeout="auto" unmountOnExit>
                                                                    <Row align="middle" gutter={[8, 8]}>
                                                                        <Col span={24} style={{ paddingTop: 8 }}>
                                                                            <Typography fontSize={14} fontWeight={500} variant="body1">Advanced</Typography>
                                                                        </Col>
                                                                        {d.horizontal !== undefined && d.horizontal === true && (
                                                                            <Col span={8}>
                                                                                <Row>
                                                                                    <Col span={24}>
                                                                                        <Typography fontSize={13} variant="body1">y Value</Typography>
                                                                                    </Col>
                                                                                    <Col span={24}>
                                                                                        <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                                                            defaultValue={d.yValue}
                                                                                            type="number"
                                                                                            inputProps={{ min: 0 }}
                                                                                            onChange={(e) => {
                                                                                                this.handleCheck1(i, 'yValue', e.target.value === "" ? "" : Number(e.target.value))
                                                                                            }} />
                                                                                    </Col>
                                                                                </Row>
                                                                            </Col>
                                                                        )}
                                                                        {d.axisType === 'xAxis' && (
                                                                            <Col span={8}>
                                                                                <Row>
                                                                                    <Col span={24}>
                                                                                        <Typography fontSize={13} variant="body1">x Value</Typography>
                                                                                    </Col>
                                                                                    <Col span={24}>
                                                                                        <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                                                            defaultValue={d.xValue}
                                                                                            type="number"
                                                                                            inputProps={{ min: 0 }}
                                                                                            onChange={(e) => {
                                                                                                this.handleCheck1(i, 'xValue', e.target.value === "" ? "" : Number(e.target.value))
                                                                                            }} />
                                                                                    </Col>
                                                                                </Row>
                                                                            </Col>
                                                                        )}
                                                                        {d.horizontal !== undefined && d.horizontal === false && (
                                                                            <Col span={8}>
                                                                                <Row>
                                                                                    <Col span={24}>
                                                                                        <Typography fontSize={13} variant="body1">Date</Typography>
                                                                                    </Col>
                                                                                    <Col span={24}>
                                                                                        <DefaultDatePicker updateItem={(boo, val) => {
                                                                                            this.updateWidget('date', val)
                                                                                        }} date={d.date || moment().format('x')} />
                                                                                    </Col>
                                                                                </Row>
                                                                            </Col>
                                                                        )}
                                                                        <Col span={8}>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Typography fontSize={13} variant="body1">Dash</Typography>
                                                                                </Col>
                                                                                <Col span={24}>
                                                                                    <Select size="small" fullWidth
                                                                                        notched={false}
                                                                                        labelId="demo-simple-select-label"
                                                                                        id="demo-simple-select"
                                                                                        value={d.dashStyle || 'Solid'}
                                                                                        label="Dash"
                                                                                        onChange={(e) => {
                                                                                            this.updateReference(i, 'dashStyle', e.target.value)
                                                                                        }}

                                                                                    >
                                                                                        <MenuItem value={'Solid'}>Solid</MenuItem>
                                                                                        <MenuItem value={'ShortDash'}>Short Dash</MenuItem>
                                                                                        <MenuItem value={'Dash'}>Dash</MenuItem>
                                                                                        <MenuItem value={'LongDash'}>Long Dash</MenuItem>
                                                                                        <MenuItem value={'Dot'}>Dot</MenuItem>
                                                                                    </Select>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col span={8}>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Typography fontSize={13} variant="body1">Text Align</Typography>
                                                                                </Col>
                                                                                <Col span={24}>
                                                                                    <Select size="small" fullWidth
                                                                                        notched={false}
                                                                                        labelId="demo-simple-select-label"
                                                                                        id="demo-simple-select"
                                                                                        value={d.textAlign || 'Solid'}
                                                                                        label="Dash"
                                                                                        onChange={(e) => {
                                                                                            this.updateReference(i, 'textAlign', e.target.value)
                                                                                        }}

                                                                                    >
                                                                                        <MenuItem value={'left'}>Left</MenuItem>
                                                                                        <MenuItem value={'center'}>Center</MenuItem>
                                                                                        <MenuItem value={'right'}>Right</MenuItem>
                                                                                    </Select>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col span={8}>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Typography fontSize={13} variant="body1">Vertical Align</Typography>
                                                                                </Col>
                                                                                <Col span={24}>
                                                                                    <Select size="small" fullWidth
                                                                                        notched={false}
                                                                                        labelId="demo-simple-select-label"
                                                                                        id="demo-simple-select"
                                                                                        value={d.verticalAlign || 'Solid'}
                                                                                        label="Dash"
                                                                                        onChange={(e) => {
                                                                                            this.updateReference(i, 'verticalAlign', e.target.value)
                                                                                        }}

                                                                                    >
                                                                                        <MenuItem value={'top'}>Top</MenuItem>
                                                                                        <MenuItem value={'middle'}>Middle</MenuItem>
                                                                                        <MenuItem value={'bottom'}>Bottom</MenuItem>
                                                                                    </Select>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col span={8}>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Typography fontSize={13} variant="body1">Label Rotation</Typography>
                                                                                </Col>
                                                                                <Col span={24}>
                                                                                    <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                                                        defaultValue={d.labelRotation}
                                                                                        type="number"
                                                                                        inputProps={{ min: 0 }}
                                                                                        onChange={(e) => {
                                                                                            this.handleCheck1(i, 'labelRotation', e.target.value === "" ? "" : Number(e.target.value))
                                                                                        }} />
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col span={8}>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Typography fontSize={13} variant="body1">Layer</Typography>
                                                                                </Col>
                                                                                <Col span={24}>
                                                                                    <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                                                        defaultValue={d.layer}
                                                                                        type="number"
                                                                                        inputProps={{ min: 0 }}
                                                                                        onChange={(e) => {
                                                                                            this.handleCheck1(i, 'layer', e.target.value === "" ? "" : Number(e.target.value))
                                                                                        }} />
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col span={8}>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Typography fontSize={13} variant="body1">Offset</Typography>
                                                                                </Col>
                                                                                <Col span={24}>
                                                                                    <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                                                        defaultValue={d.offset}
                                                                                        type="number"
                                                                                        inputProps={{ min: 0 }}
                                                                                        onChange={(e) => {
                                                                                            this.handleCheck1(i, 'offset', e.target.value === "" ? "" : Number(e.target.value))
                                                                                        }} />
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col span={8}>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Typography fontSize={13} variant="body1">Line Width</Typography>
                                                                                </Col>
                                                                                <Col span={24}>
                                                                                    <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                                                        defaultValue={d.width}
                                                                                        type="number"
                                                                                        inputProps={{ min: 0 }}
                                                                                        onChange={(e) => {
                                                                                            this.handleCheck1(i, 'width', e.target.value === "" ? "" : Number(e.target.value))
                                                                                        }} />
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col span={8}>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Typography fontSize={13} variant="body1">Use Alias</Typography>
                                                                                </Col>
                                                                                <Col span={24}>
                                                                                    <Checkbox checked={d.useAlias}
                                                                                        onChange={(e) => {
                                                                                            this.updateReference(i, 'useAlias', e.target.checked)
                                                                                        }} />
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col span={8}>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Typography fontSize={13} variant="body1">Alias</Typography>
                                                                                </Col>
                                                                                <Col span={24}>
                                                                                    <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                                                        defaultValue={d.alias}
                                                                                        onChange={(e) => {
                                                                                            this.handleCheck1(i, 'alias', e.target.value)
                                                                                        }} />
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>



                                                                        <Col span={24} style={{ paddingTop: 8 }}>
                                                                            <Typography fontSize={14} fontWeight={500} variant="body2">Color</Typography>
                                                                        </Col>
                                                                        <Col span={8}>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Typography fontSize={13} variant="body1">Reference Color</Typography>
                                                                                </Col>
                                                                                <Col span={24}>
                                                                                    <DataColor color={d.color} updateData={(boo, val) => this.updateReference(i, 'color', val)} />
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>


                                                                    </Row>
                                                                </Collapse>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Divider />
                                                            </Col>

                                                        </Row>
                                                    )
                                                })}
                                            </ReactSortable>)}
                                    </Col>


                                </Row>
                            </Col>
                        </TabPanel>
                    </TabContext>
                </Col>







            </Row >
        );
    }
} 