import { loadModules } from 'esri-loader';
import React from 'react';
import uuid from 'react-uuid';

export default class Layer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            map: this.props.map,
            view: this.props.view,
            marker: this.props.marker !== undefined ? JSON.parse(this.props.marker) : {}

        };
    }

    componentDidUpdate(prevProps) {




    }
    componentDidMount() {
        const { account, map, view, marker } = this.state;
        this.addItem(marker)


    }

    addItem = (marker) => {
        const { view, map, layerColor, graphics, } = this.state;
        loadModules(["esri/widgets/Sketch",
            "esri/layers/GraphicsLayer",
            "esri/widgets/Sketch/SketchViewModel",
            "esri/geometry/geometryEngine",
            "esri/Graphic"])
            .then(([Sketch, GraphicsLayer, SketchViewModel, geometryEngine, Graphic]) => {

                const graphicsLayer = new GraphicsLayer({ title: 'marker', id: 'marker' });
                map.add(graphicsLayer)


                let pointSymbol = {
                    type: "simple-marker",  // autocasts as new SimpleMarkerSymbol()
                    style: "square",
                    color: {
                        r: '100',
                        g: '161',
                        b: '39',
                        a: '0.5',
                    },
                    size: "8px",  // pixels
                    outline: {  // autocasts as new SimpleLineSymbol()
                        color: [255, 255, 0],
                        width: 3  // points
                    }
                };

                console.log(marker);
                [marker].map((g, i) => {

                    if (Object.values(g).length > 0) {
                        const s = g.attributes.shapeType;
                        g.symbol = pointSymbol;
                        g.geometry.type = s;
                        let graphic = new Graphic({
                            geometry: g.geometry,
                            symbol: g.symbol,
                            attributes: g.attributes,
                            id: i

                        });
                        graphicsLayer.add(graphic);
                    }



                })

                let sketchVM = new SketchViewModel({
                    layer: graphicsLayer,
                    pointSymbol,
                    view: view
                });

                view.when(() => {
                    const sketch = new Sketch({
                        layer: graphicsLayer,
                        view: view,
                        id: 'marker',
                        creationMode: "single",
                        viewModel: sketchVM,
                        visibleElements: {
                            createTools: {
                                polyline: false,
                                polygon: false,
                                rectangle: false,
                                circle: false
                              },
                            settingsMenu: false,

                        }
                    });


                    sketch.on("create", (event => {
                        if (event.state === "complete") {

                            const key = uuid();
                            const attributes = {
                                type: "accountMap",
                                title: '',
                                key,
                                visible: true,
                                area: geometryEngine.planarArea(event.graphic.geometry, 'acres'),
                                length: geometryEngine.planarLength(event.graphic.geometry, 'feet'),
                                areaUnits: "acres",
                                lengthUnits: "ft",
                                visible: true,
                                geometryType: event.graphic.geometry.spatialReference,
                                shapeType: event.tool === 'circle' ? 'polygon' : event.tool === 'point' ? 'point' : event.tool === 'polyline' ? 'polyline' : 'polygon',
                                lat: event.tool === 'point' ? event.graphic.geometry.latitude :
                                    event.tool === 'polyline' ? event.graphic.geometry.extent.center.latitude : event.graphic.geometry.centroid.latitude,
                                lon: event.tool === 'point' ? event.graphic.geometry.longitude :
                                    event.tool === 'polyline' ? event.graphic.geometry.extent.center.longitude : event.graphic.geometry.centroid.longitude
                            }
                            event.graphic.attributes = attributes;
                            const item = {
                                geometry: event.graphic.geometry,
                                symbol: event.graphic.symbol,
                                attributes

                            }

                            graphicsLayer.graphics.map((g) => {
                                if (g.attributes.key !== key) {
                                    graphicsLayer.remove(g)
                                }

                            })

                            this.stringMarker(item)

                        }
                    }))
                    sketch.on("update", (event => {
                        if (event.state === "complete") {

                            event.graphics.map((g) => {
                                console.log(event)
                                const item = {
                                    geometry: g.geometry,
                                    symbol: g.symbol,
                                    attributes: {
                                        ...g.attributes,
                                        area: geometryEngine.planarArea(g.geometry, 'acres'),
                                        length: geometryEngine.planarLength(g.geometry, 'feet'),
                                        lat: g.attributes.shapeType === 'point' ? g.geometry.latitude :
                                            g.attributes.shapeType === 'polyline' ? g.geometry.extent.center.latitude : g.geometry.centroid.latitude,
                                        lon: g.attributes.shapeType === 'point' ? g.geometry.longitude :
                                            g.attributes.shapeType === 'polyline' ? g.geometry.extent.center.longitude : g.geometry.centroid.longitude

                                    }
                                }
                                g.attributes = item.attributes;



                                this.stringMarker(g)

                            })

                        }
                    }))

                    this.setState({ sketch })
                    view.ui.add(sketch, "top-right");
                });



            })


    }





    stringMarker = (marker) => {
        const { item, layer } = this.state;
        const string = JSON.stringify(marker);
        this.props.updateMarker(string)


    }





    render() {

        const { } = this.state;

        return null;
    }
} 