import { Box, IconButton, ListItem, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { queryCodes } from "../../../../../firebase/config";
import { CodesList } from "../../../../../waterQualityNew/codesList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ValueCondition from "./conditions/valueCondition";
import TagCondition from "./conditions/tagCondition";
import ConditionOptions from "../../../../../waterQualityNew/waterQualityDatabase/conditionOptions";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import uuid from "react-uuid";
import randomColor from 'randomcolor';


const SeriesConditions = ({ conditionsForSeries, updateData, userInfo, series }) => {
    const [codes, setCodes] = useState([])
    const [conditions, setConditions] = useState(conditionsForSeries)

    useEffect(() => {
        getInfo();
    }, []);

    async function getInfo() {
        const codes = await queryCodes(userInfo)
        const list = [...codes, ...CodesList].sort((a, b) => a.label.localeCompare(b.label))
        setCodes(list);

    }

    const handleAddCondition = (seriesKey, type) => {
        const newConditions = [...conditions, {
            label: 'Condition 1',
            type: '>',
            value: '0',
            conditionType: type,
            tags: [],
            id: uuid(),
            action: 'showOnly',
            color: randomColor({ luminosity: 'light', }),
            radius: 2
        }]
        setConditions(newConditions);
        updateData(newConditions)

    };

    const deleteCondition = (condition_id) => {
        setConditions(prev => prev.filter((condition) => condition.id !== condition_id));
        updateData(conditions.filter((condition) => condition.id !== condition_id))

    };

    async function updateConditions(condition) {
        const newConditions = conditions.map((c) => {
            if (c.id === condition.id) {
                return condition
            }
            return c
        })
        setConditions(newConditions);
        updateData(newConditions)
    }


    return (
        < Box >
            <ListItem key={'addCondition'} style={{ padding: 0, paddingLeft: 8, borderBottom: '1px solid #0000001f' }}>
                <Box
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    <Typography variant="body1" color={'black'} fontWeight={600} fontSize={14} style={{ paddingBottom: 8, paddingTop: 8 }}>
                        Conditions
                    </Typography>
                    <ConditionOptions handleAddCondition={handleAddCondition} item={series} />
                </Box>
            </ListItem>
            {
                conditions.map((condition, conditionIndex) => {
                    return (
                        <ListItem key={conditionIndex} sx={{

                        }} style={{ padding: 0, paddingLeft: 8, borderBottom: '1px solid #0000001f' }}>
                            <Box
                                sx={{
                                    p: 1,
                                    pl: 1
                                }} style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%'
                                }}>
                                {condition.conditionType === 'value' &&
                                    <ValueCondition
                                        condition={condition}
                                        conditionIndex={conditionIndex}
                                        updateConditions={(condition) => {
                                            updateConditions(condition)
                                        }}
                                    />
                                }
                                {condition.conditionType === 'tag' &&
                                    <TagCondition
                                        condition={condition}
                                        codes={codes}
                                        conditionIndex={conditionIndex}
                                        updateConditions={(condition) => {
                                            updateConditions(condition)
                                        }}
                                    />}





                                <IconButton onClick={() => deleteCondition(condition.id)}>
                                    <Tooltip title="Delete">
                                        <FontAwesomeIcon icon={faTrash} size="sm" style={{ color: '#9e9e9e' }} />
                                    </Tooltip>
                                </IconButton>

                            </Box>
                        </ListItem>
                    )
                })
            }
        </Box >
    )
};

export default SeriesConditions;

