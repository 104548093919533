import EditIcon from '@mui/icons-material/Edit';
import { Autocomplete, Button, CircularProgress, Divider, IconButton, OutlinedInput, Paper, TextField, Typography } from '@mui/material';
import { Col, Modal, Row, Tag } from 'antd';
import { collection, doc, getDocs, getFirestore, query, setDoc, updateDoc, where } from 'firebase/firestore';
import moment from 'moment';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import MUIDataTable from "mui-datatables";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default class ProductInventory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            data: [],
            filteredData: [],
            filters: [],
            searchText: '',
            loading: true,
            level: this.props.level,
            accounts: [],
            stocks: [],
            products: [],
            accountsLoad: true,
            currentItem: {},

        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
            this.queryData()
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0)
        const { userInfo } = this.state;
        if (Object.values(userInfo).length === 0) {
            this.props.getUser()
        } else {

            this.queryAccounts()
            this.queryStocks()
            this.queryProducts()
        }

    }

    queryData = async () => {
        const { userInfo, level } = this.state;
        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'flItems'), where('archived', '==', false), where('psType', 'in', ['products', 'productApplication']));
        const queryAccountData = query(collection(db, "clients", userInfo.currentCompany, 'flItems'), where('archived', '==', false), where('psType', 'in', ['products', 'productApplication']), where('account', '==', userInfo.currentAccount));
        const data = [];
        const dataList = [];
        const snap = await getDocs(level === 'account' ? queryAccountData : queryData);
        snap.forEach((doc) => {
            const units = doc.data().units || {};
            data.push({
                ...doc.data(),
                account: doc.data().account,
                productKey: doc.data().productKey,
                quantity: doc.data().quantity,
                key: doc.data().key,
                itemType: doc.data().itemType === 'productApplication' ? "Application" : "Supply",
                date: doc.data().date,
                units: units.label,
                docKey: doc.data().docKey,
                logKey: doc.data().logKey,
            })




        });
        console.log(data)
        data.sort((a, b) => b.date - a.date)
        dataList.sort((a, b) => Number(b.date) - Number(a.date))
        this.setState({ data, loading: false })


    }

    queryAccounts = async () => {
        const { userInfo } = this.state;
        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'accounts'));
        const accounts = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            accounts.push({
                accountName: doc.data().accountName,
                key: doc.data().key,
                type: doc.data().seasonal || null,
                city: doc.data().city || null,
                countyName: doc.data().countyName || null,
                state: doc.data().state || null,
                routeDay: doc.data().routeDay || null,
                routeName: doc.data().routeName || null,
                visible: true
            })


        });
        accounts.sort((a, b) => a.accountName.localeCompare(b.accountName))
        this.setState({ accounts, })


    }

    queryStocks = async () => {
        const { userInfo, type } = this.state;
        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'productStock'), where('archived', '==', false));
        const data = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            data.push({ ...doc.data(), label: doc.data().name })


        });
        data.sort((a, b) => a.name.localeCompare(b.name))
        this.setState({ stocks: data, })


    }

    queryProducts = async () => {
        const { userInfo, type } = this.state;
        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'psItems'), where('psType', '==', 'products'), where('archived', '==', false));
        const data = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {

            data.push({
                ...doc.data(),
                label: `${doc.data().name} `
            })


        });
        data.sort((a, b) => a.label.localeCompare(b.label))
        this.setState({ products: data, })
        this.queryData()


    }



    updateCurrentItem = (boo, val) => {
        const { currentItem } = this.state;
        currentItem[boo] = val;
        this.setState({ currentItem })
    }



    render() {

        const { userInfo, data, loading, searchText, filters, level, account, accounts, accountsLoad, stocks, currentItem, openModal, screenWidth, products } = this.state;
        const db = getFirestore();
        const ViewItem = ({ row }) => {
            let navigate = useNavigate();
            return (
                <IconButton color="primary" size="small" onClick={async () => {

                    const userRef = doc(db, "users", userInfo.id, 'currentItems', 'currentFieldLog');
                    await setDoc(userRef, {
                        key: row.logKey
                    }).then(async () => {
                        const userRef = doc(db, "users", userInfo.id);
                        userInfo.currentAccount = row.account;
                        this.props.updateUserInfo('currentAccount', row.account);
                        this.setState({ userInfo })
                        await updateDoc(userRef, {
                            currentAccount: row.account
                        }).then(() => {
                            navigate("/fieldLog");
                        })
                    })
                }}>
                    <EditIcon color="inherit" />
                </IconButton>
            )

        }







        const AddProduct = () => {


            return (
                <Button style={{ textTransform: 'none', padding: 16, fontSize: 16 }} fullWidth variant="contained" color="primary"
                    onClick={() => {

                        this.setState({ openModal: true, currentItem: {} })

                    }}>Add Supply </Button>
            )


        }



        const CreateSupply = () => {
            return (
                <Button size="small" style={{ padding: 10, borderRadius: 4, textTransform: 'none', fontSize: 14, }}
                    variant='contained' color="primary"
                    onClick={async () => {
                        const { currentItem } = this.state;




                        const checks = [
                            currentItem.quantity === undefined ? false : currentItem.quantity === '' ? false : true,
                            currentItem.supplyStock === undefined ? false : currentItem.supplyStock === null ? false : true,
                            currentItem.product === undefined ? false : currentItem.product === null ? false : true,
                            currentItem.units === undefined ? false : currentItem.units === null ? false : true,
                        ]

                        if (currentItem.quantity === undefined || currentItem.quantity === '') {
                            this.props.openSnack('error', "Quantity required!")
                        }
                        if ([undefined, null].includes(currentItem.supplyStock)) {
                            this.props.openSnack('error', "Stock is required!")
                        }
                        if ([undefined, null].includes(currentItem.product)) {
                            this.props.openSnack('error', "Product is required!")
                        }
                        if ([undefined, null].includes(currentItem.units)) {
                            this.props.openSnack('error', "Units are required!")
                        }

                        if (!checks.includes(false)) {



                            const newItem = {
                                ...currentItem.product,
                                supplyStock: currentItem.supplyStock,
                                quantity: currentItem.quantity,
                                date: currentItem.date || moment().format('x'),
                                archived: currentItem.archived || false,
                                productKey: currentItem.product.key,
                                key: currentItem.key || uuid(),
                                account: userInfo.currentAccount,
                                company: userInfo.currentCompany,
                                creator: userInfo.id,
                                itemType: 'addSupply',
                                name: currentItem.product.name
                            };

                            console.log(newItem);

                            const docRef = doc(db, "clients", userInfo.currentCompany, 'flItems', newItem.key);
                            await setDoc(docRef, newItem).then(() => {
                                data.push(newItem)
                                this.setState({ data, openModal: false })
                            }).then(() => {
                                this.setState({ currentItem: {} })
                                this.props.openSnack('success', currentItem.key ? 'Stock updated!' : "Stock created!")
                            })
                        }





                    }}>{currentItem.key ? 'Update Supply' : "Create Supply"}</Button>
            )
        }







        const XTable = ({ columns, header, data, openModal, accounts, products, loading }) => {
            let navigate = useNavigate();
            const getMuiTheme = () =>
                createTheme({
                    components: {
                        MUIDataTable: {
                            styleOverrides: {
                                root: {
                                    padding: '8px',
                                    boxShadow: 'none'
                                }
                            }
                        },
                        MuiTableRow: {
                            styleOverrides: {
                                root: {
                                    cursor: 'pointer',
                                    fontSize: '10px'

                                },
                                hover: {
                                    backgroundColor: 'green'
                                }

                            }
                        },
                        MuiTableCell: {
                            styleOverrides: {
                                root: {
                                    fontSize: '12px',
                                    padding: '2px'
                                }
                            }
                        }

                    }
                });




            const companyColumns = [




                {
                    name: 'productKey',
                    label: 'Product',
                    options: {
                        filter: true,
                        customBodyRender: this.renderValue = (a, b) => products.filter((f) => f.key === a)[0].name,
                    }
                },
                {
                    name: 'date',
                    label: "Date",
                    options: {
                        filter: false,
                        customBodyRender: this.renderValue = (a, b) => moment(a, 'x').format('MM/DD.YYYY')
                    }
                },
                {
                    name: 'itemType',
                    label: 'Type',
                    options: {
                        filter: true,
                        customBodyRender: this.renderValue = (a, b) => a === "Application" ? <Tag color="green">Application</Tag> : <Tag color="orange">Supply</Tag>,
                    }
                },
                {
                    name: 'account',
                    label: 'Account',
                    options: {
                        filter: true,
                        customBodyRender: this.renderValue = (a, b) => accounts.filter((f) => f.key === a)[0].accountName,

                    },
                },
                {
                    name: 'quantity',
                    label: 'Quantity',
                    options: {
                        filter: false,

                    },
                },

                {
                    name: 'units',
                    label: 'Units',
                    options: {
                        filter: false,

                    },
                },
                {
                    name: 'key',
                    label: 'Edit',
                    options: {

                        filter: false,
                        viewColumns: false,
                        customBodyRender: this.renderValue = (a, b) => {
                            const index = this.state.data.map((d) => d.key).indexOf(a);
                            const row = this.state.data[index]
                            return (
                                <Button style={{ textTransform: 'none', padding: 8, borderRadius: 24, fontSize: 10 }} variant={row.itemType === "Application" ? "contained" : 'outlined'} color="primary" itemKey={a}
                                    onClick={async (e) => {


                                        if (row.itemType === "Application") {
                                            e.stopPropagation();
                                            const db = getFirestore()
                                            const userRef = doc(db, "users", userInfo.id, 'currentItems', 'currentFieldDoc');
                                            await setDoc(userRef, {
                                                key: row.docKey
                                            }).then(() => {
                                                navigate("/application");
                                            })
                                        }
                                        else {
                                            this.setState({ currentItem: row, openModal: true })
                                        }

                                    }}>{row.itemType === "Application" ? "View" : "Edit"}</Button>
                            )
                        }
                    },
                },


            ]



            return (
                <ThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        title={''}
                        data={this.state.data}
                        columns={companyColumns}

                        options={{
                            textLabels: {
                                body: {
                                    noMatch: <div style={{}}>{loading ? "Loading Data" : "No Records Found"} {loading && (<CircularProgress />)}</div>
                                }
                            },
                            async onRowClick(a, b) {

                                console.log(a[a.length - 1].props.itemKey);
                                const key = a[a.length - 1].props.itemKey
                                const index = data.map((d) => d.key).indexOf(key);
                                const row = data[index];

                                if (row.itemType === "productApplication") {

                                    const db = getFirestore()
                                    const userRef = doc(db, "users", userInfo.id, 'currentItems', 'currentFieldDoc');
                                    await setDoc(userRef, {
                                        key: row.docKey
                                    }).then(() => {
                                        navigate("/application");
                                    })
                                }
                                else {
                                    console.log(row);
                                    openModal(row)
                                }


                            },
                            downloadOptions: {
                                filename: 'Applications'
                            },
                            print: false,
                            selectableRows: false,

                        }}

                    />
                </ThemeProvider>
            );
        }

        return (
            <Row style={{ position: 'relative', top: 0 }}>



                <Col span={23}>
                    <Row align="middle">
                        <Col xs={16} sm={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>
                            <Typography variant="h4">Your Product Inventory</Typography>
                        </Col>
                        <Col xs={6} sm={4} style={{ paddingTop: 30, paddingBottom: 15, textAlign: 'right' }}>
                            <AddProduct />
                        </Col>
                        <Col span={24}>
                            <Divider />
                        </Col>
                    </Row>
                </Col>



                <Col span={23} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 30 }}>

                    <Paper elevation={16} style={{ padding: 20, borderRadius: 12 }}>
                        <Row>



                            <Col span={24} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 30 }}>
                                <XTable data={data} openModal={(item) => this.setState({ openModal: true, currentItem: item })} accounts={accounts} products={products} loading={loading} />

                            </Col>
                        </Row>
                    </Paper>

                </Col>

                <Modal title="" visible={openModal} onCancel={() => this.setState({ openModal: false, currentItem: {} })} footer={null} width={'50%'} style={{ borderRadius: 40 }} >
                    <Row align="middle" gutter={[16, 16]} >
                        <Col xs={{ offset: 0, span: 24 }} sm={{ offset: 6, span: 18 }} style={{ paddingBottom: 12, }}>
                            <Typography variant='h5'>Add Supply</Typography>
                        </Col>



                        <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                            <Typography variant="body1">Date<span style={{ color: 'red' }}>*</span></Typography>
                        </Col>
                        <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0, position: 'relative', zIndex: 4 }}>

                            <DatePicker portalId="root-portal" dateFormat="MM/dd/yyyy"
                                selected={[undefined, '', "Invalid date"].includes(currentItem.date) ? new Date() : new Date(moment(currentItem.date, 'x'))}
                                onChange={(date) => {
                                    console.log(date);
                                    const unix = moment(date).format('x');
                                    this.updateCurrentItem('date', unix)

                                }} />


                        </Col>
                        <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                            <Typography variant="body1">Product<span style={{ color: 'red' }}>*</span></Typography>
                        </Col>
                        <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                            <Autocomplete
                                size="small"
                                disablePortal
                                id="combo-box-demo"
                                value={currentItem.name || ''}
                                options={products}
                                renderInput={(params) => <TextField {...params} label="Product" />}
                                onChange={(a, b) => {
                                    this.updateCurrentItem(`product`, b)
                                    this.updateCurrentItem(`name`, b.name)
                                }}

                            />
                        </Col>

                        <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                            <Typography variant="body1">Amount<span style={{ color: 'red' }}>*</span></Typography>
                        </Col>
                        <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                            <OutlinedInput fullWidth notched={false} label="none" placeholder={"qty"} size="small"
                                value={currentItem.quantity || ''}
                                type="number"
                                inputProps={{ min: 0 }}
                                onChange={(e) => {
                                    this.updateCurrentItem(`quantity`, e.target.value === "" ? "" : Number(e.target.value))
                                }} />
                        </Col>
                        <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                            <Typography variant="body1">Stock<span style={{ color: 'red' }}>*</span></Typography>
                        </Col>
                        <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                            <Autocomplete
                                size="small"
                                disablePortal
                                id="combo-box-demo"
                                value={currentItem.supplyStock || ''}
                                options={stocks}
                                renderInput={(params) => <TextField {...params} label="Stock" />}
                                onChange={(a, b) => {
                                    this.updateCurrentItem(`supplyStock`, b)
                                }}

                            />
                        </Col>
                        <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                            <Typography variant="body1">Units<span style={{ color: 'red' }}>*</span></Typography>
                        </Col>
                        <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                            <Autocomplete
                                size="small"
                                disablePortal
                                id="combo-box-demo"
                                value={currentItem.units || ''}
                                options={["mg",
                                    "g",
                                    "kg",
                                    "oz",
                                    "lb",
                                    "fl-oz",
                                    "cup",
                                    "pnt",
                                    "qt",
                                    "gal",
                                    "ft3",
                                ].sort((a, b) => a.localeCompare(b)).map((g) => { return ({ key: g, label: g }) })}
                                renderInput={(params) => <TextField {...params} label="Units" />}
                                onChange={(a, b) => {
                                    this.updateCurrentItem(`units`, b)
                                }}

                            />
                        </Col>

                        <Col xs={{ offset: 0, span: 24 }} sm={{ offset: 6, span: 18 }} style={{ paddingBottom: 12, }}>
                            <CreateSupply />
                        </Col>

                    </Row>
                </Modal>
            </Row>
        );
    }
} 