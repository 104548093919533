import React, { useState } from "react";


import { Box, Button, IconButton, Modal, OutlinedInput, Typography } from '@mui/material';
import { Col, Row } from 'antd';

import { faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PhotoEditor = (
    {
        screenWidth,
        currentPhoto,
        setCurrentPhoto,
        openSnack,
        updatePhoto



    }

) => {

    const [item, setItem] = useState({});









    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: screenWidth === 'xs' ? '90vw' : '50vw',
        bgcolor: 'background.paper',
        border: '1px solid #000',
        borderRadius: '10px',
        boxShadow: 24,
        maxHeight: '90vh',
        p: 0,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
    };

    const rowFixedStyle = {
        flexShrink: 0,
    };



    return (
        <div style={{ marginTop: 20, marginBottom: 20 }}>


            <Modal title="" open={currentPhoto !== null ? true : false}



                onClose={() => {
                    setCurrentPhoto(null)


                }} footer={null} width={'60%'}  >
                <Box sx={style}>

                    <Row style={rowFixedStyle} className="row1">
                        <Col span={20} style={{ padding: 30 }}>
                            <Typography variant="h6" component="div" fontWeight={600} fontSize={28}>
                                {currentPhoto?.photo_id ? "Edit" : "Create"} Photo
                            </Typography>
                        </Col>
                        <Col span={4} style={{
                            textAlign: 'right',
                            paddingTop: 30, paddingRight: 20,
                        }}>
                            <IconButton
                                style={{


                                }} aria-label="close" onClick={() => {
                                    setCurrentPhoto(null)
                                }}>
                                <FontAwesomeIcon icon={faX} size="md"
                                    onClick={() => {
                                        setCurrentPhoto(null)
                                    }} />
                            </IconButton>
                        </Col>



                    </Row>








                    <Box>





                        <Row style={rowFixedStyle} className="row3">

                            <Col xs={24} sm={24} style={{
                                borderTop: '1px solid rgba(0,0,0,0.2)',
                                marginTop: 10,
                                paddingTop: 10,
                                paddingBottom: 40,
                            }}>
                                <Row
                                    justify="end"
                                    align={'middle'}

                                    style={{
                                        padding: 20,

                                    }}>
                                    <Col span={24}>
                                        <Typography variant="body1" component="div" fontWeight={600} fontSize={18}>
                                            Photo name
                                        </Typography>
                                        <OutlinedInput

                                            fullWidth
                                            style={{
                                                borderRadius: 16,
                                                fontSize: 16,
                                            }}
                                            sx={{
                                                px: 2,
                                                py: 0,

                                            }}
                                            autoFocus
                                            placeholder='Photo name'
                                            onChange={(e) => {
                                                setCurrentPhoto({ ...currentPhoto, name: e.target.value })
                                            }}
                                            onKeyDown={async (e) => {
                                                if (e.key === 'Enter' && currentPhoto?.name !== '') {
                                                   
                                                    if (currentPhoto?.photo_id) {
                                                        console.log("ENTER")
                                                        
                                                        updatePhoto(currentPhoto)
                                                    }
                                                }
                                            }}
                                            value={currentPhoto?.name}
                                        />
                                    </Col>



                                    <Col style={{
                                        paddingTop: 20,
                                    }}>
                                        <Button
                                            variant="default"

                                            style={{
                                                textTransform: 'none',
                                                borderRadius: 12,
                                                fontWeight: 500,
                                                fontSize: 16,
                                                marginRight: 20,

                                                height: 40,
                                                border: '1px solid rgba(0,0,0,0.4)',


                                            }}
                                            onClick={() => {
                                                setCurrentPhoto(null)
                                            }}

                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="contained"

                                            style={{
                                                textTransform: 'none',
                                                borderRadius: 12,
                                                fontWeight: 500,
                                                fontSize: 16,

                                                disabled: currentPhoto?.name === '' ? true : false,
                                                height: 40,
                                                backgroundColor: currentPhoto?.name === '' ? 'rgba(0,0,0,0.2)' : '#3f51b5',
                                                color: currentPhoto?.name === '' ? 'rgba(0,0,0,0.4)' : 'white',
                                            }}
                                            onClick={async () => {
                                                if (currentPhoto?.name === '') {
                                                    openSnack('error', 'Please enter photo name')
                                                    return
                                                }
                                                if (currentPhoto?.photo_id) {
                                                    updatePhoto(currentPhoto)
                                                }





                                            }}

                                        >
                                            Save photo
                                        </Button>

                                    </Col>

                                </Row>

                            </Col>




                        </Row>

                    </Box>









                </Box>
            </Modal >
        </div >



    );
};

export default PhotoEditor;
