import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import './AddItem.css';
import { addFolder, addFile, getFolderStructure, addOldDocuments, addFiles, getItems, addOldAlbums, addOldPhotos } from '../../firebase/firestore';
import { uploadFile, uploadSingleFile } from '../../firebase/storage'; // Make sure to import the uploadFile function
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import uuid from 'react-uuid';
import { Col, Row } from 'antd';
import { Breadcrumbs, Button, createTheme, ThemeProvider, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions } from '@mui/material';
import { Select } from '@mui/material';
import { MenuItem } from '@mui/material';
import exifr from 'exifr';
import moment from 'moment';
import axios, * as others from 'axios';
import { readAndCompressImage } from 'browser-image-resizer';
import { File } from 'react-feather';


const imageConfig = {
  quality: 0.9,
  maxWidth: 1500,
  maxHeight: 1500,
  autoRotate: true,
  debug: true,
};



const AddItem = ({ selectedFolder, refreshFolderStructure, userInfo, openSnack }) => {
  const [itemName, setItemName] = useState('');
  const [itemType, setItemType] = useState('album');
  const [uploading, setUploading] = useState(false);
  const [uploadModalOpen, setUploadModalopen] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);


  useEffect(() => {
    checkFolders();
  }, []);

  const checkFolders = async () => {
    const path = selectedFolder === null ? 'root' : selectedFolder;
    const itemFolders = await getItems(userInfo.currentCompany, userInfo.currentAccount, path, 'imageAlbums');
    const itemFiles = await getItems(userInfo.currentCompany, userInfo.currentAccount, path, 'images');

    if (itemFolders.length === 0 && itemFiles.length === 0 && path === 'root') {
      await addFolder('Field Log Photos', 'root', userInfo.currentCompany, userInfo.currentAccount, userInfo.id, 'fieldLogPhotos');
      await addFolder('Application Photos', 'root', userInfo.currentCompany, userInfo.currentAccount, userInfo.id, 'applicationPhotos');
      await addOldAlbums(userInfo.currentCompany, userInfo.currentAccount, userInfo.id);
      await addOldPhotos(userInfo.currentCompany, userInfo.currentAccount, userInfo.id);
      refreshFolderStructure(selectedFolder);

    }



  };


  const chunk = (array, size) => {
    const chunked_arr = [];
    for (let i = 0; i < array.length; i += size) {
      chunked_arr.push(array.slice(i, i + size));
    }
    return chunked_arr;
  };



  const onDrop = async (acceptedFiles) => {
    let completedPromises = 0;
    openSnack('success', 'File Uploading...')
    setUploading(true);
    setUploadModalopen(false);
    for (let index = 0; index < acceptedFiles.length; index++) {
      const file = acceptedFiles[index];
      //convert file to blob
      const blob = new Blob([file], { type: file.type });
      const compressedFile = await readAndCompressImage(file, imageConfig);




      const base64Image = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result.split(",")[1]);
        reader.onerror = reject;
        reader.readAsDataURL(compressedFile);
      });



      const folder = selectedFolder === null ? 'root' : selectedFolder;
      const path = `files/${userInfo.currentCompany}/${folder}/${file.name}`;



      const name = file.name.split('.')[0];
      const id = uuid();
      const proxyUrl = "https://mycorslake.herokuapp.com/";

      const metadata = await exifr.parse(file, { gps: true, xmp: true });

      const make = metadata === undefined ? null : metadata.Make || null;


      console.log(metadata)



      const dateTaken = metadata === undefined ? moment() : metadata.CreateDate || metadata?.DateTimeOriginal || moment();
      const latitude = metadata === undefined ? null : metadata.latitude || null;
      const longitude = metadata === undefined ? null : metadata.longitude || null;
      console.log(dateTaken)
      console.log(metadata?.CreateDate)
      const formattedDateTaken = moment(dateTaken).format('MM/DD/YYYY HH:mm:ss');


      console.log(formattedDateTaken)



      const response = await addFile(
        name,
        folder,
        'uploading',
        userInfo.currentCompany,
        userInfo.currentAccount,
        userInfo.id,
        file.size,
        id,
        formattedDateTaken,
        latitude,
        longitude,
        'none',
        path
      );
      //if index is even run uploadPhotos1 else run uploadPhotos
      const functionName = index % 2 === 0 ? 'uploadPhotos1' : 'uploadPhotos';

      axios.post(proxyUrl + `https://us-central1-aquasource3.cloudfunctions.net/${functionName}`,
        {
          id,
          base64Image,
          extension: file.name.split('.')[1],
          userInfo
        },
        { headers: { "Content-Type": "application/json" } });
      console.log('Upload success!');


      completedPromises++;
      setUploadProgress((completedPromises / acceptedFiles.length) * 100);
      console.log((completedPromises / acceptedFiles.length) * 100)
      if (completedPromises === acceptedFiles.length) {
        setUploading(false);
        setUploadProgress(0);
        refreshFolderStructure(selectedFolder);
      }

    }



    openSnack('success', 'File Uploading...');
  };




  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (itemName.trim() === '') return;

    if (itemType === 'album') {
      await addFolder(itemName, selectedFolder, userInfo.currentCompany, userInfo.currentAccount, userInfo.id, uuid());
    }
    openSnack('success', 'Album Created')
    setItemName('');
    refreshFolderStructure(selectedFolder);
    setUploadModalopen(false);
  };


  if (uploading) {
    return (
      <div
        style={{
          height: "95%",
          width: "100%",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <FontAwesomeIcon icon={faSpinner} color="#404050" spin size="1x" />
        </div>
        <div style={{ marginTop: "10px" }}>
          <Typography
            variant="body1"
            fontSize={12}
            fontWeight={400}
            style={{ color: "#404050", textAlign: "center" }}
          >
            Uploading files... {uploadProgress.toFixed(0)}% complete
          </Typography>
        </div>
      </div>
    );
  }
  else {

    return (
      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setUploadModalopen(true)}
            style={{
              textTransform: "none",
              backgroundColor: '#404050'
            }}
          >
            Add Album / Image
          </Button>
        </Col>

        <Dialog open={uploadModalOpen} onClose={() => setUploadModalopen(false)}>
          <DialogTitle>Upload</DialogTitle>
          <form
            onSubmit={handleSubmit}
          >
            <DialogContent>
              <DialogContentText>
                Please select an item type
              </DialogContentText>

              <Select
                size="small"
                fullWidth
                id="itemType"
                value={itemType}
                onChange={(e) => setItemType(e.target.value)}
              >
                <MenuItem value="album">Album</MenuItem>
                <MenuItem value="images">Images</MenuItem>
              </Select>
            </DialogContent>

            {itemType === 'album' && (
              <DialogContent>
                <DialogContentText>
                  Please enter an album name
                </DialogContentText>
                <TextField
                  autoFocus
                  size="small"
                  fullWidth
                  margin="dense"
                  id="itemName"
                  value={itemName}
                  onChange={(e) => setItemName(e.target.value)}

                />
              </DialogContent>
            )}

            {itemType === 'images' && (
              <DialogContent>
                <DialogContentText>
                  Please select images
                </DialogContentText>
                <div
                  className={`dropzone${isDragActive ? ' drag-active' : ''}`}
                  {...getRootProps()}
                >
                  <input {...getInputProps({ accept: 'image/jpeg, image/png', multiple: true })} />
                  <Typography variant="body1" fontSize={16} fontWeight={400}
                  >Drag 'n' drop some images here, or click to select images</Typography>
                </div>
              </DialogContent>
            )}


            <DialogActions>
              <Button onClick={() => setUploadModalopen(false)}>Cancel</Button>
              <Button type="submit">Submit</Button>
            </DialogActions>
          </form>
        </Dialog>
      </Row>
    );
  }
};

export default AddItem;
