import React, { useEffect, useState } from "react";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import { useParams } from 'react-router-dom';
import { fetchInfo, fetchLog } from "./utils/firebase";
import ReturnToLogsButton from "./components/returnToLogsButton";
import LogTabs from "./components/tabs";
import LogBody from "./components/mainLogComponent/body";
import { newLogFunction } from "./utils/functions";
import { queryAccountStaff, queryPSItems, queryStations, queryStocks, queryUsers } from "../firebase/config";
import moment from 'moment-timezone';
import { getAccounts } from "../tasks/functions";

const FieldLogNew2 = ({
    screenWidth,
    userInfo,
    openSnack
}) => {


    const [activeTab, setActiveTab] = useState('log');
    const [listKey, setListKey] = useState('field_logs_new');
    const [loading, setLoading] = useState(true);
    const [log, setLog] = useState(null);
    const [account, setAccount] = useState(null);
    const [company, setCompany] = useState(null);
    const [staff, setStaff] = useState([]);
    const [accountUsers, setAccountUsers] = useState([]);
    const [stocks, setStocks] = useState([]);
    const [items, setItems] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [stations, setStations] = useState([]);

    const { id } = useParams();


    useEffect(() => {
        window.scrollTo(0, 0);

        async function getAccountData() {
            const accounts = await getAccounts(userInfo);
            setAccounts(accounts)
            const account = await fetchInfo(userInfo, 'account');
            setAccount(account)
            const company = await fetchInfo(userInfo, 'company');
            setCompany(company)
            const staff = await queryAccountStaff(userInfo)
            setStaff(staff)
            const accountUsers = await queryUsers(userInfo);
            setAccountUsers(accountUsers)
            const stocks = await queryStocks(userInfo);
            setStocks(stocks)
            const items = await queryPSItems(userInfo);
            const updatedItems = items.map((item) => {
                console.log(item)
                return (
                    {
                        ...item, label: item.name || ''
                    }
                )
            })
            setItems(updatedItems)
            const stations = await queryStations(userInfo, ['grab', 'both', 'sensor'])
            console.log(stations)
            setStations(stations)
        }


        async function getLog() {
            const log = await fetchLog(id, listKey, userInfo);
            if (log === null) {
                console.log("NEW LOG FUNCTION")
                newLog()
                setLoading(false);
            }
            setLoading(false);
            return log;
        }




        getAccountData()
        getLog();



    }, [id]);

    async function newLog() {
        await newLogFunction(userInfo, log, setLog, account, company)
    }






    const props = {
        screenWidth,
        userInfo,
        account,
        openSnack,
        activeTab,
        setActiveTab,
        log,
        setLog,
        account,
        company,
        staff,
        accountUsers,
        stocks,
        setStocks,
        items,
        setItems,
        accounts,
        stations,
        setStations


    }




    if (loading) {
        return (
            <Row style={{ height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
                {/* Using the full height of the viewport to center content */}
                <Col span={24} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

                    {/* FontAwesome Spinner */}
                    <FontAwesomeIcon icon={faSpinner} spin size="3x" />

                    <Typography variant="h6" style={{ marginTop: 20 }}>
                        Loading your field log...
                    </Typography>

                </Col>
            </Row>
        )
    }






    else {
        return (
            <Row align="middle" style={{}} >

                <Col xs={16} sm={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 5 }}>
                    <Typography variant="h4" fontWeight={600} fontSize={30} color="black">{!log?.id ? 'New Field Log' : log?.name}</Typography>
                </Col>
                <Col xs={16} sm={20} style={{ paddingLeft: 30, paddingBottom: 5 }}>
                    <ReturnToLogsButton />
                </Col>

                <Col span={24}>
                    <LogTabs {...props} />
                </Col>
                <Col span={24}>
                    <LogBody {...props} />
                </Col>









            </Row >
        );
    }

};

export default FieldLogNew2;
