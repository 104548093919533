import React from "react";
import { Input } from "@mui/material";

const StringInput = ({
    currentTask,
    setCurrentTask,
    headerKey,
    options
}) => {
    const [value, setValue] = React.useState(currentTask?.[headerKey]);  // Renamed from 'age' to 'value'

    const handleChange = (event) => {
        let val = event.target.value;
        console.log({ ...currentTask, [headerKey]: val })
        setCurrentTask({ ...currentTask, [headerKey]: val })
        setValue(val);
    };

    return (
        <Input
            fullWidth
            variant="standard"
            id="string-input"
            size="small"
            margin="dense"
            placeholder="Enter text"
            value={value}
            onChange={handleChange}
            sx={{
                '& .MuiInputBase-input': {
                    color: 'black',
                    fontWeight: 500,
                    fontSize: 14,
                },
            }}
        />
    );
};

export default StringInput;
