const ProbeParameters = [
    { key: "1", label: "Temperature-sensor" },
    { key: "2", label: "Pressure-sensor" },
    { key: "3", label: "Depth-sensor" },
    { key: "4", label: "Level: Depth to Water-sensor" },
    { key: "5", label: "Level: Elevation-sensor" },
    { key: "6", label: "Latitude-sensor" },
    { key: "7", label: "Longitude-sensor" },
    { key: "8", label: "Elevation-sensor" },
    { key: "9", label: "Actual Conductivity-sensor" },
    { key: "10", label: "Specific Conductivity-sensor" },
    { key: "11", label: "Resistivity-sensor" },
    { key: "12", label: "Salinity-sensor" },
    { key: "13", label: "Total Dissolved Solids-sensor" },
    { key: "14", label: "Density-sensor" },
    { key: "16", label: "Baro-sensor" },
    { key: "17", label: "pH-sensor" },
    { key: "18", label: "pH MV-sensor" },
    { key: "19", label: "ORP-sensor" },
    { key: "20", label: "DO-sensor" },
    { key: "21", label: "% Saturation O₂-sensor" },
    { key: "22", label: "NO₃⁻-sensor" },
    { key: "23", label: "NH₄⁺-sensor" },
    { key: "24", label: "Cl⁻-sensor" },
    { key: "25", label: "Turbidity-sensor" },
    { key: "26", label: "Battery Voltage-sensor" },
    { key: "28", label: "Flow Rate-sensor" },
    { key: "29", label: "Total Flow-sensor" },
    { key: "30", label: "Partial Pressure O₂-sensor" },
    { key: "31", label: "Total Suspended Solids-sensor" },
    { key: "32", label: "External Voltage-sensor" },
    { key: "33", label: "Battery Level-sensor" },
    { key: "35", label: "Rhodamine Fluorescence-sensor" },
    { key: "36", label: "Cl⁻ mV-sensor" },
    { key: "37", label: "NO₃⁻ as N-sensor" },
    { key: "38", label: "NO₃⁻ mV-sensor" },
    { key: "39", label: "NH₄⁺ as N-sensor" },
    { key: "40", label: "NH₄⁺ mV-sensor" },
    { key: "41", label: "NH₃ as N-sensor" },
    { key: "42", label: "Σ NH₃-sensor" },
    { key: "45", label: "Pulse-sensor" },
    { key: "49", label: "Velocity-sensor" },
    { key: "51", label: "Chl-a Fluorescence-sensor" },
    { key: "55", label: "BGA-PC Fluorescence-sensor" },
    { key: "59", label: "BGA-PE Fluorescence-sensor" },
    { key: "62", label: "Total Flow (resettable)-sensor" },
    { key: "63", label: "Total Positive Flow-sensor" },
    { key: "64", label: "Total Positive Flow (resettable)-sensor" },
    { key: "65", label: "Total Negative Flow-sensor" },
    { key: "66", label: "Total Negative Flow (resettable)-sensor" },
    { key: "68", label: "Fluoroscein Fluorescence-sensor" },
    { key: "70", label: "FDOM Fluorescence-sensor" },
    { key: "82", label: "Rainfall" },
    { key: "flowVelocity", label: "Flow Velocity" },
    { key: "concentration", label: "Concentration" }
];

export default ProbeParameters;
