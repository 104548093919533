import React from 'react';
import { Col, Row } from 'antd';
import { Breadcrumbs, Button, CircularProgress, OutlinedInput, Paper, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { setDefaultOptions, loadModules, } from 'esri-loader';
import { Map, Scene } from '@esri/react-arcgis';
import SearchBar from '../../blocks/gis/searchBar';
import Location from '../../blocks/gis/location';
import SketchLayer from '../../blocks/gis/sketchLayer';
import { ChromePicker } from 'react-color'
import Screenshot from '../../blocks/gis/screenshot';
import ZoomIcon from '../../blocks/gis/zoomIcon';
import Layer from './layer';

loadModules(['esri/config',])
    .then(([config,]) => {
        config.apiKey = "AAPK65ed7997d0524b6582730da31dc1f8986spR1Pjc0oR3qn-NVYrj0jI7Y6156OU89gRRDdPUWC1gjMayxwOkEmxo2aoZH56R";
    })
    .catch(err => {
        // handle any errors
        console.error(err);
    });

export default class TypicalMap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            printScreen: this.props.printScreen,
            printingScreen: this.props.printingScreen,
            currentMapLayer: this.props.currentMapLayer,
            map: this.props.map,
            graphics: []
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
        if (this.props.map !== prevProps.map) {
            console.log("NEW MAP")
            this.setState({ map: this.props.map })
        }

        if (this.props.printScreen !== prevProps.printScreen) {
            if (this.props.printScreen === true) {
                this.setState({ printingScreen: true })
            }
            this.setState({ printScreen: this.props.printScreen })
        }
        if (this.props.printingScreen !== prevProps.printingScreen) {
            this.setState({ printingScreen: this.props.printingScreen })
        }
        if (this.props.currentMapLayer !== prevProps.currentMapLayer) {
            console.log(this.props.currentMapLayer)
            this.setState({ currentMapLayer: this.props.currentMapLayer })
        }

    }
    componentDidMount() {
    }



    printScreen = () => {

        this.setState({ printScreen: true, printingScreen: true })

        setTimeout(() => {
            this.setState({ printScreen: false })
        }, 1000);

    }

    render() {

        const { account, screenWidth, item, printScreen, userInfo, printingScreen, graphics, currentMapLayer, map } = this.state;

        return (
            <Row gutter={[12, 12]} style={{ height: '100%', borderRadius: 16, }} align="middle">

                <Col span={24} style={{ display: 'flex', height: '100%', borderRadius: 16, }}>
                    <Map className="Map" style={{ width: '100%', borderRadius: 16, }}
                        mapProperties={{ basemap: 'arcgis-imagery' }}

                        viewProperties={{
                            center: [account.lng, account.lat],
                            zoom: 16,
                            ui: []

                        }}>

                        {Object.values(map).length === 3 && (<Layer map={this.map} view={this.view} item={map} layerColor={map.sampleSites.layerColor}
                            graphics={map.sampleSites.graphics} currentMapLayer={currentMapLayer} layer="sampleSites"
                            addGraphics={this.props.addGraphics} />)}
                        {Object.values(map).length === 3 && (<Layer map={this.map} view={this.view} item={map} layerColor={map.zones.layerColor}
                            graphics={map.zones.graphics} currentMapLayer={currentMapLayer} layer="zones"
                            addGraphics={this.props.addGraphics} />)}
                        {Object.values(map).length === 3 && (<Layer map={this.map} view={this.view} item={map} layerColor={map.other.layerColor}
                            graphics={map.other.graphics} currentMapLayer={currentMapLayer} layer="other"
                            addGraphics={this.props.addGraphics} />)}

                        <Screenshot map={this.map} view={this.view} printScreen={printScreen} item={item} userInfo={userInfo} sendImage={(a, b) => {
                            this.setState({ printingScreen: false })
                            this.props.sendImage(a, b)
                        }} printingScreen={printingScreen} />

                        <SearchBar map={this.map} view={this.view} />
                        <ZoomIcon map={this.map} view={this.view} />
                        <Location map={this.map} view={this.view} />

                    </Map>
                </Col>

            </Row>
        );
    }
} 