import { Button, ButtonGroup, CircularProgress, Divider, Typography, OutlinedInput, Autocomplete, TextField, Select, MenuItem, IconButton } from '@mui/material';
import { Col, Modal, Row } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import 'react-data-grid/lib/styles.css';
import moment from 'moment-timezone';
import './DataGridStyles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { getFirestore, doc, updateDoc } from 'firebase/firestore';
import { updateSetAlert } from './firestore';


const SnoozeAlert = (props) => {

    let { alertId, duration, snooze, companyKey, accountKey, } = useParams();
    const [loading, setLoading] = useState(true);




    useEffect(() => {
        changeCompany(companyKey, accountKey)


    }, [])

    async function changeCompany(companyKey, accountKey) {
        props.clearAccount()
        props.changeCompany(companyKey)
        props.updateUserInfo('currentAccount', '');
        props.updateUserInfo('currentCompany', companyKey);

        setTimeout(() => {
            changeAccount(accountKey)
        }, 1500);
    }

    async function changeAccount(accountKey) {
        const db = getFirestore();
        const userRef = doc(db, "users", props.userInfo.id);

        // Set the "capital" field of the city 'DC'
        await updateDoc(userRef, {
            currentAccount: accountKey
        }).then(() => {
            props.updateUserInfo('currentAccount', accountKey);
            console.log("SUCCESS CHANGE")
            //navigate to /alerts


        }).then(() => {
            console.log(duration)
            const snoozeDate = moment().tz('America/Los_Angeles').add(duration, 'days').format('YYYY-MM-DD HH:mm:ss')
            console.log(snoozeDate)
            updateAlert({ id: alertId, snoozeDate })
        }).then(() => {
            setTimeout(() => {
                window.location.href = '/alerts'
            }, 1500);
        })
    }

    async function updateAlert(alert) {
        console.log("UPDATING ALERT")
        console.log(alert)
        await updateSetAlert(props.userInfo, alert);
        props.openSnack('success', 'Alert updated successfully')

    }


    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Typography variant="h6" style={{}}>
                    Updating alerts...
                </Typography>

                <FontAwesomeIcon icon={faSpinner} spin style={{ marginLeft: '10px' }} />

            </div>
        )
    }
    else {

        return (
            <div style={{ fontFamily: 'Roboto, sans-serif', backgroundColor: '#f6f8fe', minHeight: '100vh' }}>
                <p>Testing</p>
            </div >
        );
    }
}

export default SnoozeAlert;