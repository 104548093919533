import React, { useState } from 'react';
import { List, ListItemButton, ListItemText, Collapse, Box } from '@mui/material';

import SelectTool from './tools/selectTool';
import TransformTool from './tools/transformTool';
import TooltipTool from './tools/tooltipTool';

const ToolCollapseComponent = ({ toolName, toolLabel, selectType, setSelectType,
    tooltip, setTooltip, panning, setPanning, panKey, setPanKey, zoomType, setZoomType, mouseWheel, setMouseWheel

}) => {
    const [open, setOpen] = useState(true);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <List
            style={{
                borderTop: '1px solid #676767',
                borderBottom: '1px solid #676767',
            }}
            sx={{
                color: 'white',
                pt: 0, pb: 0


            }} // Set text color to white, background to black for visibility
            component="nav"
            aria-labelledby="nested-list-subheader"
        >

            {toolName === 'select' && (<SelectTool toolName={toolName} toolLabel={toolLabel} selectType={selectType} setSelectType={setSelectType} />)}
            {toolName === 'transform' && (<TransformTool toolName={toolName} toolLabel={toolLabel} mouseWheel={mouseWheel} />)}
            {toolName === 'tooltip' && (<TooltipTool toolName={toolName} toolLabel={toolLabel} tooltip={tooltip} setTooltip={setTooltip} panning={panning} setPanning={setPanning} panKey={panKey} setPanKey={setPanKey} zoomType={zoomType} setZoomType={setZoomType} mouseWheel={mouseWheel} setMouseWheel={setMouseWheel} />)}

        </List>
    );
};

export default ToolCollapseComponent;