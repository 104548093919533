import { Box, CircularProgress, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { fetchWeather } from './utils/weatherFunctions';
import { fetchInfo } from '../../../fieldLogNew/utils/firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import ForecastDays from './weatherComponents/forecast';





const WidgetForecast = ({ userInfo, screenWidth, openSnack, spacing }) => {
    const [weather, setWeather] = useState(null);
    const [loading, setLoading] = useState('loading');
    

    useEffect(() => {
        getInfo();
    }, []);

    async function getInfo() {
        try {
            const account = await fetchInfo(userInfo, 'account');
            const weather = await fetchWeather(account);
            setWeather(weather);
            setLoading(false);
        } catch (error) {
            console.log(error)
            setLoading("No weather data available");
        }
    }




    if (loading !== false) {
        return (
            <Box style={{
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'column',
                alignContent: 'center',
                justifyContent: 'center',

            }}>
                <Typography variant="body1" fontWeight={600} fontSize={24} style={{ textAlign: 'center', display: 'block', paddingBottom: 10 }}>{loading}</Typography>

                <FontAwesomeIcon icon={faSpinner} spin color="#3f51b5" size="3x" style={{ display: "block", }} />
            </Box>
        )
    }
    else {


        return (
            <Box

                sx={{
                    p: 2
                }}


                style={{
                    display: 'flex',
                    flexGrow: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignContent: 'center',
                    backgroundColor: '#f5f5ff',
                    borderRadius: 12,
                }}>
                <ForecastDays weather={weather} spacing={spacing} screenWidth={screenWidth} />

            </Box>

        );
    }


}

export default WidgetForecast;