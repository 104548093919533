import { Button, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import React, { CSSProperties } from 'react';

import { useCSVReader } from 'react-papaparse';



export default function CSVReader({ getKeys, removeFile }) {
  const { CSVReader } = useCSVReader();

  return (
    <Row>
      <Col span={24}>
        <CSVReader
          onUploadAccepted={(results) => {

            getKeys(results)
          }}
        >
          {({
            getRootProps,
            acceptedFile,
            ProgressBar,
            getRemoveFileProps,
          }) => (
            <>
              <Row align="middle" gutter={[12, 12]} style={{ display: 'flex', width: '100%', height: '100%', }}>
                {acceptedFile ? <Col span={24} type='button' {...getRootProps()} style={{
                  width: '100%', height: '100%',
                  borderRadius: 8, padding: 20,
                }} >

                  <Typography variant='h5' fontWeight={400}>File Loaded: {acceptedFile ? acceptedFile.name : "Drag and Drop File"}</Typography>
                </Col> : <Col span={24} type='button' {...getRootProps()} style={{
                  width: '100%', height: '100%', border: '1px dashed gray',
                  borderRadius: 8, padding: 20, textAlign: 'center', paddingTop: 10
                }} >

                  <Typography variant='h5' fontWeight={400}>{acceptedFile ? acceptedFile.name : "Drag and Drop File"}</Typography>
                </Col>}

                <Col span={24}>
                  <div {...getRemoveFileProps()}>
                    <Button variant='contained' color="secondary" onClick={() => removeFile('upload')}  >
                      Remove
                    </Button>
                  </div>
                </Col>


              </Row>
              <ProgressBar />
            </>
          )}
        </CSVReader>
      </Col>
    </Row>
  );
}