import { faChartArea, faChartColumn, faChartLine, faCopy, faGear, faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Autocomplete, Box, Button, Checkbox, Collapse, Divider, Fab, FormControl, FormControlLabel, FormGroup, Grid, Icon, IconButton, MenuItem, OutlinedInput, Select, TextField, Tooltip, Typography } from '@mui/material';
import Tab from '@mui/material/Tab';
import { Col, Popconfirm, Row } from 'antd';
import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';
import moment from 'moment';
import React from 'react';
import { ReactSortable } from 'react-sortablejs';
import uuid from 'react-uuid';
import ProbeParameters from '../../../../extra/probeParameters.json';
import Units from '../../../../extra/units.json';
import WeatherParameters from '../../../../extra/weatherParameters.json';
import { fullMobile } from './util';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataColorGithub from './dataColorGithub';
import ParametersLoad from './parametersLoad';
import NodeSelect from './NodeSelect';
import './styles.css';
import ItemColorPicker from './contour/colorPicker';
import ColorRanges from './contour/colorRanges';

var randomColor = require('randomcolor');
export default class ContourSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            attributes: this.props.attributes,
            currentWidgetKey: this.props.currentWidgetKey,
            locations: [],
            references: [],
            parameters: [],
            sites: [],
            activeTab: 'data',
            readData: this.props.readData,
            updatingDepth: false,
            colorRanges: [
                { color: 'rgba(255, 0, 0,1)', id: '1', min: 0, max: 0, type: 'greater_than' }, //blue
                { color: 'rgba(134, 206, 139,1)', id: '2', min: 0, max: 0, type: 'less_than' }, //green
            ]
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
        if (this.props.readData !== prevProps.readData) {
            this.setState({ readData: this.props.readData })
            if (this.props.readData === true) {
                this.props.updateAttributes(this.state.attributes, this.state.currentWidgetKey)
                this.setState({ readData: false })
            }
        }


    }
    componentDidMount() {
        const { attributes } = this.state;
        this.getLocations()
        this.getReferences()
        this.getParameters();

    }

    getLocations = async () => {
        const { userInfo } = this.state;

        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'probes'), where('account', '==', userInfo.currentAccount), where('archived', '==', false));
        const queryDataSites = query(collection(db, "clients", userInfo.currentCompany, 'sampleSites'), where('account', '==', userInfo.currentAccount),);
        const queryDataStations = query(collection(db, "clients", userInfo.currentCompany, 'tidalStations'), where('account', '==', userInfo.currentAccount),);
        const queryStations = query(collection(db, "clients", userInfo.currentCompany, 'stations'), where('account', '==', userInfo.currentAccount),);
        const locations = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            locations.push({ ...doc.data(), label: doc.data().name, type: 'probe' })
        });
        const snapSites = await getDocs(queryDataSites);
        snapSites.forEach((doc) => {
            locations.push({ ...doc.data(), label: doc.data().name, type: 'sample' })
        });

        const snapStations = await getDocs(queryDataStations);
        snapStations.forEach((doc) => {
            locations.push({ ...doc.data(), label: doc.data().name, type: 'station' })
        });
        const snapNodes = await getDocs(queryStations);
        snapNodes.forEach((doc) => {
            if (doc.data().archived !== true && doc.data().children.length > 0) {
                const children = doc.data().children;
                //for each child create a node and add to locations
                children.forEach((child) => {
                    locations.push({ ...child, stationLabel: doc.data().name, label: child.name, type: 'node', stationType: child.type })
                })

            }

        });
        locations.push({ label: 'Weather', key: 'Weather', name: 'Weather', type: 'weather' })
        locations.sort((a, b) => a.name.localeCompare(b.name))
        this.setState({ locations, })

    }
    getReferences = async () => {
        const { userInfo } = this.state;

        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'references'));
        const references = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            references.push(doc.data())
        });

        references.sort((a, b) => a.label.localeCompare(b.label))
        this.setState({ references, })


    }

    updateWidget = (boo, val) => {
        const { attributes } = this.state;
        attributes[boo] = val;
        this.setState({ attributes })


    }

    updateData = (index, boo, val) => {
        const { attributes } = this.state;
        attributes.chartData[index][boo] = val;
        this.updateWidget(attributes)

    }
    updateAxis = (index, boo, val) => {
        const { attributes } = this.state;

        attributes.axises[index][boo] = val;
        this.updateWidget(attributes)

    }

    updateReference = (index, boo, val) => {
        const { attributes } = this.state;
        attributes.references[index][boo] = val;
        this.updateWidget(attributes)
    }
    getParameters = async () => {
        const { userInfo, type } = this.state;
        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'parameters'));
        const parameters = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            parameters.push({ ...doc.data(), label: doc.data().name })


        });
        parameters.sort((a, b) => a.name.localeCompare(b.name))
        this.setState({ parameters, })
    }

    handleCheck = (index, boo, val,) => {
        // Clears running timer and starts a new one each time the user types
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.toggleCheck(index, boo, val,);
        }, 1000);
    }

    toggleCheck = async (index, boo, val) => {
        this.updateData(index, boo, val)
    }
    handleCheck1 = (index, boo, val,) => {
        // Clears running timer and starts a new one each time the user types
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.toggleCheck1(index, boo, val,);
        }, 800);
    }

    toggleCheck1 = async (index, boo, val) => {
        this.updateReference(index, boo, val)
    }
    handleCheckAxis = (index, boo, val,) => {
        // Clears running timer and starts a new one each time the user types
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.toggleCheckAxis(index, boo, val,);
        }, 800);
    }

    toggleCheckAxis = async (index, boo, val) => {
        this.updateAxis(index, boo, val)
    }
    handleCheckWidget = (boo, val,) => {
        // Clears running timer and starts a new one each time the user types
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.toggleCheckWidget(boo, val,);
        }, 800);
    }

    toggleCheckWidget = async (boo, val) => {
        this.updateWidget(boo, val)
    }


    duplicateSeries = (index) => {
        const { attributes } = this.state;
        const newSeries = { ...attributes.chartData[index] };
        newSeries.key = uuid();
        attributes.chartData.push(newSeries);
        this.setState({ attributes })
    }

    render() {

        const { account, attributes, screenWidth, locations, expanded, activeTab, references, currentWidgetKey, parameters, userInfo, updatingDepth, colorRanges } = this.state;

        const DeleteItem = ({ row, type }) => {
            return (
                <Popconfirm
                    title={`Are you sure to delete this ${type}?`}
                    onConfirm={async () => {

                        if (type === 'axises') {
                            const checkSeries = attributes.chartData.filter((s) => s.yAxis === row.axisId);
                            if (checkSeries.length > 0) {
                                this.props.openSnack('error', `Cannot delete, a series is associated to this axis.`)
                            }
                            else {
                                this.props.openSnack('success', `${type === 'axises' ? "Axes" : "Data"} deleted.`)
                                const newData = attributes[type].filter((f) => f.key !== row.key);
                                attributes[type] = newData;
                                this.setState({ attributes })
                            }
                        }
                        else {
                            this.props.openSnack('success', `${type === 'axises' ? "Axes" : "Data"} deleted.`)
                            const newData = attributes[type].filter((f) => f.key !== row.key);
                            attributes[type] = newData;
                            this.setState({ attributes })
                        }




                    }}
                    okText="Yes"
                    cancelText="No"
                >
                    <IconButton style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="small"
                    >
                        <DeleteForeverIcon />
                    </IconButton>
                </Popconfirm>


            )
        }


        const PPArray = Object.values(ProbeParameters).map((p, i) => {
            return ({
                label: p,
                key: Object.keys(ProbeParameters)[i]
            })
        });

        const WPArray = Object.values(WeatherParameters).map((p, i) => {
            return ({
                label: p,
                key: Object.keys(WeatherParameters)[i]
            })
        });
        const UnitsArray = Object.values(Units).map((p, i) => {
            return ({
                label: p,
                key: Object.keys(Units)[i]
            })
        });


        const chartDataMap = (d, i) => {
            const input = d.decimals === 0 || d.decimals ? d.decimals : '';



            const gutter = [8, 8]
            const verticalOffset = { marginTop: 20 }
            return (
                <Row gutter={gutter} style={{ backgroundColor: i % 2 === 0 ? 'inherit' : 'rgba(0,1,0,0.05)', paddingTop: 8 }} key={d.key}>
                    {/* Drag Component */}
                    <Col {...fullMobile(2, undefined, { xs: 0 })} className="my-handle">
                        <DragIndicatorIcon />
                    </Col>
                    {/* Data input section */}
                    <Col {...fullMobile(22)}>
                        <Row align="middle" gutter={gutter} style={{ paddingBottom: 8 }}>
                            <Col span={24}>
                                {/* Row 1 */}
                                <Row gutter={gutter}>
                                    <Col {...fullMobile(6)}>
                                        <Typography fontSize={13} fontWeight={600} variant="body1">Data Type<span style={{ color: 'red' }}>*</span></Typography>

                                        <Select size="small" fullWidth
                                            notched={false}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={d.dataType || ''}
                                            label="Layer"
                                            style={{
                                                backgroundColor: 'white'
                                            }}
                                            onChange={(e) => {
                                                this.updateData(i, 'dataType', e.target.value)
                                            }}

                                        >

                                            <MenuItem value={'node'}>Node/Station</MenuItem>


                                        </Select>


                                    </Col>

                                    <Col {...fullMobile(8, 20)}>
                                        <Typography fontSize={13} fontWeight={600} variant="body1">Node/Location<span style={{ color: 'red' }}>*</span></Typography>
                                        <NodeSelect
                                            dataType={d.dataType}
                                            updateData={(a, b, c) => {
                                                this.setState({ updatingDepth: true })
                                                console.log(a, b, c)
                                                this.updateData(a, b, c)
                                                setTimeout(() => {
                                                    this.setState({ updatingDepth: false })
                                                }
                                                    , 250)
                                            }}
                                            index={i}
                                            locations={locations}
                                            location={d.location}
                                        />
                                    </Col>
                                    <Col {...fullMobile(8)}>
                                        <Typography fontSize={13} fontWeight={600} variant="body1">Parameter<span style={{ color: 'red' }}>*</span></Typography>
                                        <ParametersLoad userInfo={userInfo} nodeid={d.locationid} dataType={d.dataType} parameterid={d.parameterid}
                                            updateData={(boo, val) => {

                                                this.updateData(i, boo, val)

                                            }} />
                                    </Col>
                                    <Col {...fullMobile(2, 4)} >
                                        <IconButton style={{ ...verticalOffset }} onClick={() => {
                                            this.updateData(i, 'advancedSettings', d.advancedSettings ? !d.advancedSettings : true)
                                        }}>
                                            <FontAwesomeIcon fontSize={18} icon={faGear} color="#9ec1c9" />
                                        </IconButton>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={24}>
                                {/* Row 2 */}
                                <Row gutter={gutter}>
                                    <Col {...fullMobile(6)}>
                                        <Row>
                                            <Col span={24}>
                                                <Typography fontSize={13} variant="body1">Label</Typography>
                                            </Col>
                                            <Col span={24}>
                                                <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                    defaultValue={d.label || ''}
                                                    style={{
                                                        backgroundColor: 'white'
                                                    }}
                                                    onChange={(e) => {
                                                        this.handleCheck(i, 'label', e.target.value)
                                                    }} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col {...fullMobile(6)}>
                                        <Row>
                                            <Col span={24}>
                                                <Typography fontSize={13} variant="body1">Suffix</Typography>
                                            </Col>
                                            <Col span={24}>
                                                <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                    defaultValue={d.suffix || ''}
                                                    style={{
                                                        backgroundColor: 'white'
                                                    }}
                                                    onChange={(e) => {
                                                        this.handleCheck(i, 'suffix', e.target.value)
                                                    }} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col {...fullMobile(4)}>
                                        <Row>
                                            <Col span={24}>
                                                <Typography fontSize={13} variant="body1">Depth<span style={{ color: 'red' }}>*</span></Typography>
                                            </Col>
                                            <Col span={24}>
                                                {updatingDepth === false && (
                                                    <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                        defaultValue={d.depth || ''}
                                                        style={{
                                                            backgroundColor: 'white'
                                                        }}
                                                        onChange={(e) => {
                                                            this.handleCheck(i, 'depth', e.target.value)
                                                        }} />
                                                )}
                                            </Col>
                                        </Row>
                                    </Col>


                                    <Col {...fullMobile(2, 2)} style={{ paddingTop: 12 }}>
                                        <Row>
                                            <Col span={24}>
                                                <Button size="small" variant="contained" color="primary" onClick={() => {
                                                    //duplicate series
                                                    this.duplicateSeries(i)

                                                }}
                                                    style={{
                                                        color: 'white',
                                                        backgroundColor: '#404050',

                                                    }}
                                                >
                                                    <FontAwesomeIcon fontSize={14} icon={faCopy} color="white" />
                                                </Button>
                                            </Col>
                                        </Row>




                                    </Col>
                                    <Col {...fullMobile(3, 3)} style={{ paddingTop: 12, textAlign: 'right' }}>
                                        <DeleteItem row={d} type="chartData" />
                                    </Col>
                                    {/* Advanced settings */}
                                    <Col {...fullMobile(22)}>
                                        <Row style={{ paddingBottom: 8 }}>
                                            <Col span={24}>
                                                <Collapse in={d.advancedSettings || false} timeout="auto" unmountOnExit>
                                                    <Row align="middle" gutter={[8, 8]}>
                                                        <Col span={24} style={{ paddingTop: 8 }}>
                                                            <Typography fontSize={14} fontWeight={500} variant="body1">Advanced</Typography>
                                                        </Col>
                                                        {/* Dash */}
                                                        <Col {...fullMobile(8, 12)}>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <Typography fontSize={13} variant="body1">Dash</Typography>
                                                                </Col>
                                                                <Col span={24}>
                                                                    <Select size="small" fullWidth
                                                                        notched={false}
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        value={d.dashed || 'Solid'}
                                                                        label="Dash"
                                                                        onChange={(e) => {
                                                                            this.updateData(i, 'dashed', e.target.value)
                                                                        }}
                                                                        style={{
                                                                            backgroundColor: 'white'
                                                                        }}

                                                                    >
                                                                        <MenuItem value={'Solid'}>Solid</MenuItem>
                                                                        <MenuItem value={'ShortDash'}>Short Dash</MenuItem>
                                                                        <MenuItem value={'Dash'}>Dash</MenuItem>
                                                                        <MenuItem value={'LongDash'}>Long Dash</MenuItem>
                                                                        <MenuItem value={'Dot'}>Dot</MenuItem>
                                                                    </Select>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        {/* Units */}
                                                        <Col {...fullMobile(8, 12)}>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <Typography fontSize={13} variant="body1">Units</Typography>
                                                                </Col>
                                                                <Col span={24}>
                                                                    <Select size="small" fullWidth
                                                                        notched={false}
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        value={d.units || ''}
                                                                        label="Units"
                                                                        style={{
                                                                            backgroundColor: 'white'
                                                                        }}
                                                                        onChange={(e) => {
                                                                            this.updateData(i, 'units', e.target.value)
                                                                        }}

                                                                    >

                                                                        {d.parameterid === '1' && (
                                                                            ['C', 'F'].map((f) => {
                                                                                return (
                                                                                    <MenuItem value={f}>{f}</MenuItem>
                                                                                )
                                                                            })
                                                                        )}
                                                                        {d.parameterid === '3' && (
                                                                            ['m', 'ft'].map((f) => {
                                                                                return (
                                                                                    <MenuItem value={f}>{f}</MenuItem>
                                                                                )
                                                                            })
                                                                        )}



                                                                    </Select>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        {/* Line width */}
                                                        <Col {...fullMobile(8, 10)}>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <Typography fontSize={13} variant="body1">Line Width</Typography>
                                                                </Col>
                                                                <Col span={24}>
                                                                    <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                                        defaultValue={d.lineWidth || 2}
                                                                        type="number"
                                                                        inputProps={{ min: 0 }}
                                                                        style={{
                                                                            backgroundColor: 'white'
                                                                        }}
                                                                        onChange={(e) => {
                                                                            this.handleCheck(i, 'lineWidth', e.target.value === "" ? "" : Number(e.target.value))
                                                                        }} />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        {/* Dots */}
                                                        <Col {...fullMobile(8, 4)}>
                                                            <Row>
                                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                                    <Typography fontSize={13} variant="body1">Dots</Typography>
                                                                </Col>
                                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                                    <Checkbox checked={d.dots}
                                                                        style={{
                                                                            backgroundColor: 'white'
                                                                        }}
                                                                        onChange={(e) => {
                                                                            this.updateData(i, 'dots', e.target.checked)
                                                                        }} />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        {/* Opacity */}
                                                        <Col {...fullMobile(8, 10)}>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <Typography fontSize={13} variant="body1">Opacity</Typography>
                                                                </Col>
                                                                <Col span={24}>
                                                                    <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"

                                                                        defaultValue={d.opacity || ''}
                                                                        type="number"
                                                                        inputProps={{ min: 0 }}
                                                                        style={{
                                                                            backgroundColor: 'white'
                                                                        }}
                                                                        onChange={(e) => {
                                                                            this.handleCheck(i, 'opacity', e.target.value === "" ? "" : Number(e.target.value))
                                                                        }} />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        {/* Column Width */}
                                                        {d.type === 'column' && (<Col span={8}>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <Typography fontSize={13} variant="body1">Column Width</Typography>
                                                                </Col>
                                                                <Col span={24}>
                                                                    <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                                        defaultValue={d.columnWidth || 10}
                                                                        type="number"
                                                                        inputProps={{ min: 0 }}
                                                                        style={{
                                                                            backgroundColor: 'white'
                                                                        }}
                                                                        onChange={(e) => {
                                                                            this.handleCheck(i, 'columnWidth', e.target.value === "" ? "" : Number(e.target.value))
                                                                        }} />
                                                                </Col>
                                                            </Row>
                                                        </Col>)}
                                                        {/* Dec. Places */}
                                                        <Col {...fullMobile(8, 12)}>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <Typography fontSize={13} variant="body1">Dec. Places</Typography>
                                                                </Col>
                                                                <Col span={24}>
                                                                    <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                                        id={`${d.key}value`}
                                                                        defaultValue={input}
                                                                        style={{
                                                                            backgroundColor: 'white'
                                                                        }}
                                                                        type="number"
                                                                        onChange={(e) => {
                                                                            this.handleCheck(i, 'decimals', e.target.value === "" ? Number(0) : e.target.value === "0" ? Number(0) : Number(e.target.value))
                                                                        }} />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        {/* Offset */}
                                                        <Col {...fullMobile(8, 12)}>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <Typography fontSize={13} variant="body1">Offset</Typography>
                                                                </Col>
                                                                <Col span={24}>
                                                                    <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                                        defaultValue={d.offset || ''}
                                                                        style={{
                                                                            backgroundColor: 'white'
                                                                        }}
                                                                        type="number"
                                                                        onChange={(e) => {
                                                                            this.handleCheck(i, 'offset', e.target.value === "" ? "" : Number(e.target.value))
                                                                        }} />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col {...fullMobile(8, 12)}>
                                                            <Row>
                                                                <Col span={24} >
                                                                    <Typography fontSize={13} variant="body1">Data Source</Typography>
                                                                </Col>
                                                                <Col span={24} >
                                                                    <Select size="small" fullWidth
                                                                        notched={false}
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        value={d.dataSourceType || 'reading'}
                                                                        style={{
                                                                            backgroundColor: 'white'
                                                                        }}
                                                                        label="Dash"
                                                                        onChange={(e) => {
                                                                            this.handleCheck(i, 'dataSourceType', e.target.value)
                                                                        }}

                                                                    >
                                                                        <MenuItem value={'reading'}>Reading</MenuItem>
                                                                        <MenuItem value={'readingWithOffset'}>Reading w/ offset</MenuItem>
                                                                        <MenuItem value={'offset'}>Offset only</MenuItem>



                                                                    </Select>
                                                                </Col>
                                                            </Row>
                                                        </Col>

                                                        <Col span={24} style={{ paddingTop: 8 }}>
                                                            <Typography fontSize={14} fontWeight={500} variant="body2">Axis Options</Typography>
                                                        </Col>
                                                        <Col span={8}>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <Typography fontSize={13} variant="body1">Y-Axis ID</Typography>
                                                                </Col>
                                                                <Col span={24}>

                                                                    <Select size="small" fullWidth
                                                                        notched={false}
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        value={d.yAxis || 0}
                                                                        style={{
                                                                            backgroundColor: 'white'
                                                                        }}
                                                                        label="Dash"
                                                                        onChange={(e) => {
                                                                            this.updateData(i, 'yAxis', e.target.value)
                                                                        }}

                                                                    >
                                                                        {attributes.axises.map((a) => {
                                                                            return (
                                                                                <MenuItem value={a.axisId}>{a.axisId}</MenuItem>
                                                                            )
                                                                        })}


                                                                    </Select>


                                                                </Col>
                                                            </Row>
                                                        </Col>




                                                    </Row>
                                                </Collapse>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    {/* Horizontal divider */}
                    <Col span={24}>
                        <Divider />
                    </Col>
                </Row>
            )
        }

        const axisMap = (d, i) => {
            const ticks = d.tickAmount === 0 || d.tickAmount ? d.tickAmount : '';
            const tickIntervals = d.tickInterval === 0 || d.tickInterval ? d.tickInterval : '';

            return (
                <Row align="middle" gutter={[8, 8]} style={{ paddingBottom: 5 }} key={d.key}>
                    <Col {...fullMobile(2, undefined, { xs: 0 })} className="my-handle">
                        <DragIndicatorIcon />
                    </Col>
                    <Col {...fullMobile(4, 12)}>
                        <Row>
                            <Col span={24}>
                                <Typography fontSize={13} variant="body1">ID</Typography>
                            </Col>
                            <Col span={24}>
                                <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                    defaultValue={d.axisId}
                                    disabled
                                    type="number"
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col {...fullMobile(6, 12)}>
                        <Row>
                            <Col span={24}>
                                <Typography fontSize={13} variant="body1">Label</Typography>
                            </Col>
                            <Col span={24}>
                                <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                    defaultValue={d.label || ''}
                                    onChange={(e) => {
                                        this.handleCheckAxis(i, 'label', e.target.value)
                                    }} />
                            </Col>
                        </Row>
                    </Col>
                    <Col {...fullMobile(4, 12)}>
                        <Row>
                            <Col span={24} style={{ textAlign: 'center' }}>
                                <Typography fontSize={13} variant="body1">Opposite</Typography>
                            </Col>
                            <Col span={24} style={{ textAlign: 'center' }}>
                                <Checkbox checked={d.opposite}
                                    onChange={(e) => {
                                        this.updateAxis(i, 'opposite', e.target.checked)
                                    }} />
                            </Col>
                        </Row>
                    </Col>
                    <Col {...fullMobile(4, 12)} >
                        <IconButton onClick={() => {
                            this.updateAxis(i, 'advancedSettings', d.advancedSettings ? !d.advancedSettings : true)
                        }}>
                            <FontAwesomeIcon fontSize={18} icon={faGear} color="#9ec1c9" />
                        </IconButton>
                    </Col>
                    <Col span={4}>
                        {d.axisId !== 0 && (<DeleteItem row={d} type="axises" />)}
                    </Col>
                    <Col offset={2} {...fullMobile(22)}>
                        <Collapse in={d.advancedSettings || false} timeout="auto" unmountOnExit>
                            <Row align="middle" gutter={[8, 8]}>
                                <Col span={24} style={{ paddingTop: 8 }}>
                                    <Typography fontSize={14} fontWeight={500} variant="body1">Advanced</Typography>
                                </Col>
                                <Col span={8}>
                                    <Row>
                                        <Col span={24}>
                                            <Typography fontSize={13} variant="body1">Custom Range</Typography>
                                        </Col>
                                        <Col span={24}>
                                            <Checkbox checked={d.customRange}
                                                onChange={(e) => {
                                                    this.updateAxis(i, 'customRange', e.target.checked)
                                                }} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={8}>
                                    {d.customRange === true && (<Row>
                                        <Col span={24}>
                                            <Typography fontSize={13} variant="body1">Min</Typography>
                                        </Col>
                                        <Col span={24}>
                                            <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                defaultValue={d.min}
                                                type="number"
                                                inputProps={{ min: 0 }}
                                                onChange={(e) => {
                                                    this.handleCheckAxis(i, 'min', e.target.value === "" ? "" : Number(e.target.value))
                                                }} />
                                        </Col>
                                    </Row>)}
                                </Col>
                                <Col span={8}>
                                    {d.customRange === true && (<Row>
                                        <Col span={24}>
                                            <Typography fontSize={13} variant="body1">Max</Typography>
                                        </Col>
                                        <Col span={24}>
                                            <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                defaultValue={d.max}
                                                type="number"
                                                inputProps={{ min: 0 }}
                                                onChange={(e) => {
                                                    this.handleCheckAxis(i, 'max', e.target.value === "" ? "" : Number(e.target.value))
                                                }} />
                                        </Col>
                                    </Row>)}
                                </Col>
                                <Col {...fullMobile(8)}>
                                    <Row>
                                        <Col span={24}>
                                            <Typography fontSize={13} variant="body1">Dash</Typography>
                                        </Col>
                                        <Col span={24}>
                                            <Select size="small" fullWidth
                                                notched={false}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={d.gridlineDashed || 'Solid'}
                                                label="Dash"
                                                onChange={(e) => {
                                                    this.handleCheckAxis(i, 'gridlineDashed', e.target.value)
                                                }}

                                            >
                                                <MenuItem value={'Solid'}>Solid</MenuItem>
                                                <MenuItem value={'ShortDash'}>Short Dash</MenuItem>
                                                <MenuItem value={'Dash'}>Dash</MenuItem>
                                                <MenuItem value={'LongDash'}>Long Dash</MenuItem>
                                                <MenuItem value={'Dot'}>Dot</MenuItem>
                                            </Select>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col {...fullMobile(8, 12)}>
                                    <Row>
                                        <Col span={24}>
                                            <Typography fontSize={13} variant="body1">Grid Width</Typography>
                                        </Col>
                                        <Col span={24}>
                                            <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                defaultValue={d.gridLineWidth}
                                                type="number"
                                                inputProps={{ min: 0 }}
                                                onChange={(e) => {
                                                    this.handleCheckAxis(i, 'gridLineWidth', e.target.value === "" ? "" : Number(e.target.value))
                                                }} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col {...fullMobile(8, 12)}>
                                    <Row>
                                        <Col span={24}>
                                            <Typography fontSize={13} variant="body1">Label Rotation</Typography>
                                        </Col>
                                        <Col span={24}>
                                            <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                defaultValue={d.labelRotation}
                                                type="number"
                                                inputProps={{ min: 0 }}
                                                onChange={(e) => {
                                                    this.handleCheckAxis(i, 'labelRotation', e.target.value === "" ? "" : Number(e.target.value))
                                                }} />
                                        </Col>
                                    </Row>
                                </Col>

                                <Col {...fullMobile(8, 12)}>
                                    <Row>
                                        <Col span={24} style={{ textAlign: 'center' }}>
                                            <Typography fontSize={13} variant="body1">Align Ticks</Typography>
                                        </Col>
                                        <Col span={24} style={{ textAlign: 'center' }}>
                                            <Checkbox checked={d.alignTicks}
                                                onChange={(e) => {
                                                    this.handleCheckAxis(i, 'alignTicks', e.target.checked)
                                                }} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col {...fullMobile(8, 12)}>
                                    <Row>
                                        <Col span={24} style={{ textAlign: 'center' }}>
                                            <Typography fontSize={13} variant="body1">End On Tick</Typography>
                                        </Col>
                                        <Col span={24} style={{ textAlign: 'center' }}>
                                            <Checkbox checked={d.endOnTick}
                                                onChange={(e) => {
                                                    this.updateAxis(i, 'endOnTick', e.target.checked)
                                                }} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col {...fullMobile(8, 12)}>
                                    <Row>
                                        <Col span={24} style={{ textAlign: 'center' }}>
                                            <Typography fontSize={13} variant="body1">Reversed</Typography>
                                        </Col>
                                        <Col span={24} style={{ textAlign: 'center' }}>
                                            <Checkbox checked={d.reversed}
                                                onChange={(e) => {
                                                    this.updateAxis(i, 'reversed', e.target.checked)
                                                }} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col {...fullMobile(8, 12)}>
                                    <Row>
                                        <Col span={24}>
                                            <Typography fontSize={13} variant="body1">Custom Ticks</Typography>
                                        </Col>
                                        <Col span={24}>
                                            <Checkbox checked={d.customTicks}
                                                onChange={(e) => {
                                                    this.updateAxis(i, 'customTicks', e.target.checked)
                                                }} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={8}>
                                    {d.customTicks === true && (<Row>
                                        <Col span={24}>
                                            <Typography fontSize={13} variant="body1">Tick Amount</Typography>
                                        </Col>
                                        <Col span={24}>
                                            <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                id={`${d.key}value`}
                                                defaultValue={ticks}
                                                type="number"
                                                onChange={(e) => {
                                                    this.handleCheckAxis(i, 'tickAmount', e.target.value === "" ? Number(0) : e.target.value === "0" ? Number(0) : Number(e.target.value))
                                                }} />
                                        </Col>
                                    </Row>)}
                                </Col>
                                <Col span={8}>
                                    {d.customTicks === true && (<Row>
                                        <Col span={24}>
                                            <Typography fontSize={13} variant="body1">Tick Intervals</Typography>
                                        </Col>
                                        <Col span={24}>
                                            <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                id={`${d.key}value`}
                                                defaultValue={tickIntervals}
                                                type="number"
                                                onChange={(e) => {
                                                    this.handleCheckAxis(i, 'tickInterval', e.target.value === "" ? Number(0) : e.target.value === "0" ? Number(0) : Number(e.target.value))
                                                }} />
                                        </Col>
                                    </Row>)}
                                </Col>


                                <Col span={24} style={{ paddingTop: 8 }}>
                                    <Typography fontSize={14} fontWeight={500} variant="body2">Color</Typography>
                                </Col>
                                <Col span={8}>
                                    <Row>
                                        <Col span={24}>
                                            <Typography fontSize={13} variant="body1">Axis Color</Typography>
                                        </Col>
                                        <Col span={24}>
                                            <DataColorGithub color={d.axisColor} updateData={(boo, val) => this.updateAxis(i, 'axisColor', val)} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={8}>
                                    <Row>
                                        <Col span={24}>
                                            <Typography fontSize={13} variant="body1">Grid Color</Typography>
                                        </Col>
                                        <Col span={24}>
                                            <DataColorGithub color={d.gridLineColor} updateData={(boo, val) => this.updateAxis(i, 'gridLineColor', val)} />
                                        </Col>
                                    </Row>
                                </Col>

                            </Row>
                        </Collapse>
                    </Col>
                    <Col span={24}>
                        <Divider />
                    </Col>

                </Row>
            )
        }

        const referencesMap = (d, i) => {

            return (
                <Row align="middle" gutter={[8, 8]} style={{ paddingBottom: 5 }} key={d.key}>
                    <Col {...fullMobile(2, undefined, { xs: 0 })} className="my-handle">
                        <DragIndicatorIcon />
                    </Col>
                    <Col {...fullMobile(4, 12)}>
                        <Row>
                            <Col span={24}>
                                <Typography fontSize={13} variant="body1">Axis ID</Typography>
                            </Col>
                            <Col span={24}>
                                <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                    value={d.yAxis || 1}
                                    type="number"
                                    inputProps={{ min: 0 }}
                                    onChange={(e) => {
                                        this.updateReference(i, 'yAxis', e.target.value === "" ? "" : Number(e.target.value))
                                    }} />
                            </Col>
                        </Row>
                    </Col>
                    <Col {...fullMobile(8, 12)}>
                        <Row>
                            <Col span={24}>
                                <Typography fontSize={13} variant="body1">Reference</Typography>
                            </Col>
                            <Col span={24}>
                                <Autocomplete
                                    disableClearable
                                    size="small"
                                    disablePortal
                                    id="combo-box-demo"
                                    options={references}
                                    value={d.reference || ''}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Reference"
                                            variant="outlined"
                                        />
                                    )}
                                    onChange={(a, b) => {
                                        this.updateReference(i, 'referenceid', b.key);
                                        this.updateReference(i, 'horizontal', b.horizontal || false);
                                        this.updateReference(i, 'yValue', b.yValue || false);
                                        this.updateReference(i, 'date', b.date || false);
                                        this.updateReference(i, 'reference', b);
                                        this.updateReference(i, 'title', b.label);
                                    }}

                                />
                            </Col>
                        </Row>
                    </Col>

                    <Col span={4} >
                        <IconButton onClick={() => {
                            this.updateReference(i, 'advancedSettings', d.advancedSettings ? !d.advancedSettings : true)
                        }}>
                            <FontAwesomeIcon fontSize={18} icon={faGear} color="#9ec1c9" />
                        </IconButton>
                    </Col>
                    <Col {...fullMobile(4, 12)}>
                        {d.axisId !== 0 && (<DeleteItem row={d} type="references" />)}
                    </Col>
                    <Col offset={2} {...fullMobile(22)}>
                        <Collapse in={d.advancedSettings || false} timeout="auto" unmountOnExit>
                            <Row align="middle" gutter={[8, 8]}>
                                <Col span={24} style={{ paddingTop: 8 }}>
                                    <Typography fontSize={14} fontWeight={500} variant="body1">Advanced</Typography>
                                </Col>
                                {d.horizontal !== undefined && d.horizontal === true && (
                                    <Col {...fullMobile(8)}>
                                        <Row>
                                            <Col span={24}>
                                                <Typography fontSize={13} variant="body1">y Value</Typography>
                                            </Col>
                                            <Col span={24}>
                                                <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                    defaultValue={d.yValue}
                                                    type="number"
                                                    inputProps={{ min: 0 }}
                                                    onChange={(e) => {
                                                        this.handleCheck1(i, 'layer', e.target.value === "" ? "" : Number(e.target.value))
                                                    }} />
                                            </Col>
                                        </Row>
                                    </Col>
                                )}
                                {d.horizontal !== undefined && d.horizontal === false && (
                                    <Col {...fullMobile(8)}>
                                        <Row>
                                            <Col span={24}>
                                                <Typography fontSize={13} variant="body1">Date</Typography>
                                            </Col>
                                            <Col span={24}>
                                                <DatePicker portalId="root-portal" showTimeSelect timeFormat="h:mm a" timeIntervals={1} timeCaption="time" dateFormat="MM/dd/yyyy h:mm a"
                                                    selected={[undefined, '', "Invalid date"].includes(d.date) ? new Date() : new Date(moment(d.date, 'x'))}
                                                    onChange={(date) => {

                                                        if (date === null) { this.handleCheck1(i, 'date', moment().format('x')) }
                                                        else { const unix = moment(date).format('x'); this.handleCheck1(i, 'date', unix) }
                                                    }} />
                                            </Col>
                                        </Row>
                                    </Col>
                                )}
                                <Col {...fullMobile(8, 12)}>
                                    <Row>
                                        <Col span={24}>
                                            <Typography fontSize={13} variant="body1">Dash</Typography>
                                        </Col>
                                        <Col span={24}>
                                            <Select size="small" fullWidth
                                                notched={false}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={d.dashStyle || 'Solid'}
                                                label="Dash"
                                                onChange={(e) => {
                                                    this.updateReference(i, 'dashStyle', e.target.value)
                                                }}

                                            >
                                                <MenuItem value={'Solid'}>Solid</MenuItem>
                                                <MenuItem value={'ShortDash'}>Short Dash</MenuItem>
                                                <MenuItem value={'Dash'}>Dash</MenuItem>
                                                <MenuItem value={'LongDash'}>Long Dash</MenuItem>
                                                <MenuItem value={'Dot'}>Dot</MenuItem>
                                            </Select>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col {...fullMobile(8, 12)}>
                                    <Row>
                                        <Col span={24}>
                                            <Typography fontSize={13} variant="body1">Text Align</Typography>
                                        </Col>
                                        <Col span={24}>
                                            <Select size="small" fullWidth
                                                notched={false}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={d.textAlign || 'Solid'}
                                                label="Dash"
                                                onChange={(e) => {
                                                    this.updateReference(i, 'textAlign', e.target.value)
                                                }}

                                            >
                                                <MenuItem value={'left'}>Left</MenuItem>
                                                <MenuItem value={'center'}>Center</MenuItem>
                                                <MenuItem value={'right'}>Right</MenuItem>
                                            </Select>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col {...fullMobile(8, 12)}>
                                    <Row>
                                        <Col span={24}>
                                            <Typography fontSize={13} variant="body1">Vertical Align</Typography>
                                        </Col>
                                        <Col span={24}>
                                            <Select size="small" fullWidth
                                                notched={false}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={d.verticalAlign || 'Solid'}
                                                label="Dash"
                                                onChange={(e) => {
                                                    this.updateReference(i, 'verticalAlign', e.target.value)
                                                }}

                                            >
                                                <MenuItem value={'top'}>Top</MenuItem>
                                                <MenuItem value={'middle'}>Middle</MenuItem>
                                                <MenuItem value={'bottom'}>Bottom</MenuItem>
                                            </Select>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col {...fullMobile(8, 12)}>
                                    <Row>
                                        <Col span={24}>
                                            <Typography fontSize={13} variant="body1">Label Rotation</Typography>
                                        </Col>
                                        <Col span={24}>
                                            <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                defaultValue={d.labelRotation}
                                                type="number"
                                                inputProps={{ min: 0 }}
                                                onChange={(e) => {
                                                    this.handleCheck1(i, 'labelRotation', e.target.value === "" ? "" : Number(e.target.value))
                                                }} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col {...fullMobile(8, 12)}>
                                    <Row>
                                        <Col span={24}>
                                            <Typography fontSize={13} variant="body1">Layer</Typography>
                                        </Col>
                                        <Col span={24}>
                                            <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                defaultValue={d.layer}
                                                type="number"
                                                inputProps={{ min: 0 }}
                                                onChange={(e) => {
                                                    this.handleCheck1(i, 'layer', e.target.value === "" ? "" : Number(e.target.value))
                                                }} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col {...fullMobile(8, 12)}>
                                    <Row>
                                        <Col span={24}>
                                            <Typography fontSize={13} variant="body1">Offset</Typography>
                                        </Col>
                                        <Col span={24}>
                                            <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                defaultValue={d.offset}
                                                type="number"
                                                inputProps={{ min: 0 }}
                                                onChange={(e) => {
                                                    this.handleCheck1(i, 'offset', e.target.value === "" ? "" : Number(e.target.value))
                                                }} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col {...fullMobile(8, 12)}>
                                    <Row>
                                        <Col span={24}>
                                            <Typography fontSize={13} variant="body1">Line Width</Typography>
                                        </Col>
                                        <Col span={24}>
                                            <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                defaultValue={d.width}
                                                type="number"
                                                inputProps={{ min: 0 }}
                                                onChange={(e) => {
                                                    this.handleCheck1(i, 'width', e.target.value === "" ? "" : Number(e.target.value))
                                                }} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col {...fullMobile(8, 12)}>
                                    <Row>
                                        <Col span={24}>
                                            <Typography fontSize={13} variant="body1">Use Alias</Typography>
                                        </Col>
                                        <Col span={24}>
                                            <Checkbox checked={d.useAlias}
                                                onChange={(e) => {
                                                    this.updateReference(i, 'useAlias', e.target.checked)
                                                }} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col {...fullMobile(8, 12)}>
                                    <Row>
                                        <Col span={24}>
                                            <Typography fontSize={13} variant="body1">Alias</Typography>
                                        </Col>
                                        <Col span={24}>
                                            <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                defaultValue={d.alias}
                                                onChange={(e) => {
                                                    this.handleCheck1(i, 'alias', e.target.value)
                                                }} />
                                        </Col>
                                    </Row>
                                </Col>



                                <Col span={24} style={{ paddingTop: 8 }}>
                                    <Typography fontSize={14} fontWeight={500} variant="body2">Color</Typography>
                                </Col>
                                <Col span={8}>
                                    <Row>
                                        <Col span={24}>
                                            <Typography fontSize={13} variant="body1">Reference Color</Typography>
                                        </Col>
                                        <Col span={24}>
                                            <DataColorGithub color={d.color} updateData={(boo, val) => this.updateReference(i, 'color', val)} />
                                        </Col>
                                    </Row>
                                </Col>


                            </Row>
                        </Collapse>
                    </Col>
                    <Col span={24}>
                        <Divider />
                    </Col>

                </Row>
            )
        }

        return (
            <Row justify="center" align="middle">
                <Col span={24}>
                    <Typography fontSize={16} variant='subtitle2'>Chart Settings</Typography>
                </Col>
                <Col span={24}>
                    <TabContext value={activeTab}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={(e, tab) => this.setState({ activeTab: tab })} aria-label="lab API tabs example">
                                <Tab style={{ textTransform: 'none' }} label="Data" value="data" />
                                <Tab style={{ textTransform: 'none' }} label="Chart" value="chart" />
                                <Tab style={{ textTransform: 'none' }} label="Axes" value="axises" />
                                <Tab style={{ textTransform: 'none' }} label="References" value="references" />
                            </TabList>
                        </Box>
                        <TabPanel value="chart">
                            <Col xs={24} style={{ paddingTop: 12 }} >
                                <Row>
                                    <Col span={24}>
                                        <Typography fontSize={13} variant="body1">Chart Type<span style={{ color: 'red' }}>*</span></Typography>
                                    </Col>
                                    <Col span={24}>
                                        <Select size="small" fullWidth
                                            notched={false}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={attributes.chartType}
                                            label="Layer"
                                            onChange={(e) => {
                                                this.updateWidget('chartType', e.target.value)
                                            }}

                                        >
                                            <MenuItem value={'standard'}>Standard</MenuItem>
                                            {/* <MenuItem value={'storageVolume'}>Storage Volume</MenuItem> */}
                                        </Select>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={24} style={{ paddingTop: 12, }} >
                                <Row gutter={[8, 8]}>
                                    <Col span={24}>
                                        <Typography fontSize={14} fontWeight={500} variant="body1">Settings</Typography>
                                    </Col>
                                    <Col span={24} style={{ paddingBottom: 20 }}>
                                        <FormControl component="settings">

                                            <FormGroup row>

                                                {[
                                                    { label: 'Share Axis', key: 'shareAxis' },
                                                    { label: 'Legend', key: 'legend' },

                                                ].map((r) => {
                                                    return (
                                                        <FormControlLabel
                                                            value={r.label}
                                                            control={
                                                                <Checkbox checked={attributes[r.key]}
                                                                    onChange={(e) => {
                                                                        this.updateWidget(r.key, e.target.checked)
                                                                    }} />
                                                            }
                                                            label={r.label}
                                                            labelPlacement="start"
                                                        />
                                                    )
                                                })}




                                            </FormGroup>
                                        </FormControl>
                                    </Col>

                                    <Col span={24} style={{ paddingTop: 15 }}>
                                        <Typography fontSize={14} fontWeight={500} variant="body1">Settings</Typography>
                                    </Col>


                                    <Col {...fullMobile(8)}>
                                        <Row>
                                            <Col {...fullMobile(24, 12)} style={{ textAlign: 'center' }}>
                                                <Typography fontSize={13} variant="body1">X-Axis Label</Typography>
                                            </Col>
                                            <Col {...fullMobile(24, 2)} style={{ textAlign: 'center' }}>
                                                <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                    defaultValue={attributes.xAxisLabel || ''}
                                                    onChange={(e) => {
                                                        this.updateWidget('xAxisLabel', e.target.value)
                                                    }} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col {...fullMobile(8)}>
                                        <Row>
                                            <Col {...fullMobile(24, 12)} style={{ textAlign: 'center' }}>
                                                <Typography fontSize={13} variant="body1">Y-Axis Label</Typography>
                                            </Col>
                                            <Col {...fullMobile(24, 2)} style={{ textAlign: 'center' }}>
                                                <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                    defaultValue={attributes.yAxisLabel || ''}
                                                    onChange={(e) => {
                                                        this.updateWidget('yAxisLabel', e.target.value)
                                                    }} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col {...fullMobile(8)}>
                                        <Row>
                                            <Col {...fullMobile(24, 12)} style={{ textAlign: 'center' }}>
                                                <Typography fontSize={13} variant="body1">Color-Bar Label</Typography>
                                            </Col>
                                            <Col {...fullMobile(24, 2)} style={{ textAlign: 'center' }}>
                                                <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                    defaultValue={attributes.colorBarLabel || ''}
                                                    onChange={(e) => {
                                                        this.updateWidget('colorBarLabel', e.target.value)
                                                    }} />
                                            </Col>
                                        </Row>
                                    </Col>



                                    <Col {...fullMobile(4)}>
                                        <Row>
                                            <Col {...fullMobile(24, 12)} style={{ textAlign: 'center' }}>
                                                <Typography fontSize={13} variant="body1">Color Bar</Typography>
                                            </Col>
                                            <Col {...fullMobile(24, 2)} style={{ textAlign: 'center' }}>
                                                <Checkbox checked={attributes.colorBar || false}
                                                    onChange={(e) => {
                                                        this.updateWidget('colorBar', e.target.checked)

                                                    }} />
                                            </Col>
                                        </Row>
                                    </Col>

                                    {attributes.colorBar === true && <Col {...fullMobile(4)}>
                                        <Row>
                                            <Col {...fullMobile(24, 12)} style={{ textAlign: 'center' }}>
                                                <Typography fontSize={13} variant="body1">Color Bar Auto</Typography>
                                            </Col>
                                            <Col {...fullMobile(24, 2)} style={{ textAlign: 'center' }}>
                                                <Checkbox checked={attributes.colorBarAuto === undefined ? true : attributes.colorBarAuto}

                                                    onChange={(e) => {
                                                        this.updateWidget('colorBarAuto', e.target.checked)

                                                    }} />
                                            </Col>


                                        </Row>
                                    </Col>}
                                    {attributes.colorBarAuto === false && <Col {...fullMobile(6)}>
                                        <Row>
                                            <Col {...fullMobile(24, 12)} style={{ textAlign: 'center' }}>
                                                <Typography fontSize={13} variant="body1">Color Bar Min</Typography>
                                            </Col>
                                            <Col {...fullMobile(24, 2)} style={{ textAlign: 'center' }}>
                                                <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                    value={attributes.colorBarMin || 0}
                                                    type="number"

                                                    onChange={(e) => {
                                                        this.updateWidget('colorBarMin', e.target.value === "" ? "" : Number(e.target.value))
                                                    }} />
                                            </Col>


                                        </Row>
                                    </Col>}

                                    {attributes.colorBarAuto === false && <Col {...fullMobile(6)}>
                                        <Row>
                                            <Col {...fullMobile(24, 12)} style={{ textAlign: 'center' }}>
                                                <Typography fontSize={13} variant="body1">Color Bar Max</Typography>
                                            </Col>
                                            <Col {...fullMobile(24, 2)} style={{ textAlign: 'center' }}>
                                                <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                    value={attributes.colorBarMax || 20}
                                                    type="number"

                                                    onChange={(e) => {
                                                        this.updateWidget('colorBarMax', e.target.value === "" ? "" : Number(e.target.value))
                                                    }} />
                                            </Col>


                                        </Row>
                                    </Col>}
                                    <Col {...fullMobile(12)}>
                                        <Row>
                                            <Col {...fullMobile(24, 12)} style={{ textAlign: 'center' }}>
                                                <Typography fontSize={13} variant="body1">Color Bar Theme (low to high)</Typography>
                                            </Col>
                                            <Col {...fullMobile(24, 2)} style={{ textAlign: 'center' }}>
                                                <Select size="small" fullWidth
                                                    notched={false}
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={attributes.colorBarTheme || 'standard'}
                                                    label="Layer"
                                                    onChange={(e) => {
                                                        this.updateWidget('colorBarTheme', e.target.value)
                                                    }}

                                                >
                                                    <MenuItem value={'standard'}>Red to Green</MenuItem>
                                                    <MenuItem value={'reverseStandard'}>Green to Red</MenuItem>
                                                    <MenuItem value={'blueToRed'}>Blue to Red</MenuItem>
                                                    <MenuItem value={'redToBlue'}>Red to Blue</MenuItem>
                                                    <MenuItem value={'custom'}>Custom Color Ranges</MenuItem>

                                                </Select>
                                            </Col>


                                        </Row>
                                    </Col>
                                    {attributes?.colorBarTheme === 'custom' &&
                                        (<Col {...fullMobile(24)}>
                                            <Row>

                                                <Col {...fullMobile(24, 24)} style={{ paddingTop: 10 }}>
                                                    <ColorRanges colorRanges={attributes?.colorRanges || colorRanges} setColorRanges={(colorRanges) => {
                                                        this.updateWidget('colorRanges', colorRanges)
                                                    }} openSnack={this.props.openSnack}

                                                    />

                                                </Col>


                                            </Row>


                                        </Col>)
                                    }













                                </Row>
                            </Col>
                        </TabPanel>
                        <TabPanel value="data" style={{ height: "auto" }}>
                            <Col xs={24} style={{ paddingTop: 4 }} >
                                <Row align="middle" gutter={[12, 12]}>
                                    <Col {...fullMobile(4)}>
                                        <Typography fontSize={14} fontWeight={500} variant="body1">Date</Typography>
                                    </Col>
                                    <Col {...fullMobile(4, 10)}>
                                        <Row>
                                            <Col span={24} style={{ textAlign: 'center' }}>
                                                <Typography fontSize={13} variant="body1">End Date Today</Typography>
                                            </Col>
                                            <Col span={24} style={{ textAlign: 'center' }}>
                                                <Checkbox checked={attributes.endToday}
                                                    onChange={(e) => {
                                                        this.updateWidget('endToday', e.target.checked)

                                                    }} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col {...fullMobile(8, 14)}>
                                        <Row>
                                            <Col span={24}>
                                                <Typography fontSize={13} variant="body1">Start Date</Typography>
                                            </Col>
                                            <Col span={24}>

                                                <DatePicker portalId="root-portal"
                                                    showTimeSelect
                                                    timeFormat="h:mm a"
                                                    timeIntervals={1}
                                                    timeCaption="time"
                                                    dateFormat="MM/dd/yyyy h:mm a"
                                                    selected={
                                                        [undefined, '', "Invalid date"].includes(attributes.startDate) ? new Date() : new Date(moment(attributes.startDate, 'x'))}
                                                    onChange={(date) => {

                                                        if (date === null) {
                                                            this.handleCheckWidget('startDate', moment().format('x'))
                                                        }
                                                        else {
                                                            const unix = moment(date).format('x');
                                                            this.handleCheckWidget('startDate', unix)
                                                        }

                                                    }} />


                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col {...fullMobile(8, 14)}>
                                        <Row>
                                            <Col span={24}>
                                                <Typography fontSize={13} variant="body1">End Date</Typography>
                                            </Col>
                                            <Col span={24}>
                                                {attributes.endToday === true ?
                                                    <Typography fontSize={14} variant="body1">{moment().format('L')}</Typography>
                                                    :
                                                    <DatePicker portalId="root-portal"
                                                        showTimeSelect
                                                        timeFormat="h:mm a"
                                                        timeIntervals={1}
                                                        timeCaption="time"
                                                        dateFormat="MM/dd/yyyy h:mm a"
                                                        selected={
                                                            [undefined, '', "Invalid date"].includes(attributes.endDate) ? new Date() : new Date(moment(attributes.endDate, 'x'))}
                                                        onChange={(date) => {

                                                            if (date === null) {
                                                                this.handleCheckWidget('endDate', moment().format('x'))
                                                            }
                                                            else {
                                                                const unix = moment(date).format('x');
                                                                this.handleCheckWidget('endDate', unix)
                                                            }

                                                        }} />
                                                }

                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col {...fullMobile(4)}>
                                        <Typography fontSize={14} fontWeight={500} variant="body1"></Typography>
                                    </Col>
                                    <Col {...fullMobile(20)}>
                                        <Row gutter={[8, 8]}>

                                            {[
                                                { label: '1-Week', value: 1, units: 'weeks' },
                                                { label: '1-Month', value: 1, units: 'months' },
                                                { label: '3-Month', value: 3, units: 'months' },
                                                { label: '6-Month', value: 6, units: 'months' },
                                                { label: '1-Year', value: 1, units: 'year' },
                                            ].map((p) => {
                                                return (
                                                    <Col>
                                                        <Button variant="outlined" color="inherit" style={{ textTransform: 'none' }} size="small"
                                                            onClick={() => {
                                                                this.updateWidget('startDate', moment().subtract(p.value, p.units).format('x'))
                                                                this.updateWidget('endDate', moment().format('x'))
                                                            }}>{p.label}</Button>
                                                    </Col>
                                                )
                                            })}



                                        </Row>

                                    </Col>
                                    <Col {...fullMobile(4)}>
                                        <Typography fontSize={14} fontWeight={500} variant="body1">Show Lines</Typography>
                                    </Col>
                                    <Col {...fullMobile(4)}>
                                        <Row>
                                            <Col {...fullMobile(24, 12)} style={{ textAlign: 'center' }}>
                                                <Typography fontSize={13} variant="body1">Show Lines</Typography>
                                            </Col>
                                            <Col {...fullMobile(24, 2)} style={{ textAlign: 'center' }}>
                                                <Checkbox checked={attributes.showLines || false}
                                                    onChange={(e) => {
                                                        this.updateWidget('showLines', e.target.checked)

                                                    }} />
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col {...fullMobile(4)}>
                                        <Row>
                                            <Col {...fullMobile(24, 12)} style={{ textAlign: 'center' }}>
                                                <Typography fontSize={13} variant="body1">Connect Gaps</Typography>
                                            </Col>
                                            <Col {...fullMobile(24, 2)} style={{ textAlign: 'center' }}>
                                                <Checkbox checked={attributes.connectGaps || false}
                                                    onChange={(e) => {
                                                        this.updateWidget('connectGaps', e.target.checked)

                                                    }} />
                                            </Col>
                                        </Row>
                                    </Col>







                                    <Col {...fullMobile(4)}>
                                        <Typography fontSize={14} fontWeight={500} variant="body1">Data Group</Typography>
                                    </Col>
                                    <Col {...fullMobile(4)}>
                                        <Row>
                                            <Col {...fullMobile(24, 12)} style={{ textAlign: 'center' }}>
                                                <Typography fontSize={13} variant="body1">Custom Interval</Typography>
                                            </Col>
                                            <Col {...fullMobile(24, 2)} style={{ textAlign: 'center' }}>
                                                <Checkbox checked={attributes.customInterval || false}
                                                    onChange={(e) => {
                                                        this.updateWidget('customInterval', e.target.checked)

                                                    }} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col {...fullMobile(8)}>
                                        {attributes.customInterval === true && (<Row>
                                            <Col span={24}>
                                                <Typography fontSize={13} variant="body1">Grouping by Hours</Typography>
                                            </Col>
                                            <Col span={24}>
                                                <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                                                    value={attributes.customIntervalVal || 4}
                                                    type="number"
                                                    inputProps={{ min: 0.001 }}
                                                    onChange={(e) => {
                                                        this.updateWidget('customIntervalVal', e.target.value === "" ? "" : Number(e.target.value))
                                                    }} />
                                            </Col>
                                        </Row>)}
                                    </Col>


                                    <Col span={20}>
                                        <Divider />
                                    </Col>
                                    <Col span={20}>
                                        <Typography fontSize={14} fontWeight={500} variant="body1">Data Series</Typography>
                                    </Col>
                                    <Col span={4}>
                                        <Fab style={{ position: 'relative', zIndex: 0 }} size="small" variant='contained' color="primary" onClick={() => {
                                            const item = {
                                                key: uuid(),
                                                locationid: '',
                                                parameterid: '',
                                                color: randomColor(),
                                                markerFill: randomColor(),
                                                markerLine: randomColor(),
                                                type: 'spline',
                                                dashed: 'Solid',
                                                lineWidth: 2,
                                                yAxis: 0,
                                                tooltip: {},
                                                marker: {},
                                                offset: 0,
                                                units: ''
                                            };
                                            if (attributes.chartData) {
                                                attributes.chartData.push(item);
                                                this.setState({ attributes });

                                            }
                                            else {
                                                attributes.chartData = [];
                                                attributes.chartData.push(item);
                                                this.setState({ attributes });

                                            }
                                        }}>
                                            <AddIcon />
                                        </Fab>
                                    </Col>

                                    <Col span={24} style={{ paddingTop: 6 }}>
                                        <Divider />
                                    </Col>

                                    <Col span={24}>
                                        {attributes.chartData !== undefined && attributes.chartData.length > 0 && (
                                            <ReactSortable
                                                handle=".my-handle"
                                                animation={500}
                                                list={attributes.chartData}
                                                setList={(newState) => {
                                                    attributes.chartData = newState;
                                                    this.setState({ attributes })
                                                }}
                                            >
                                                {attributes.chartData.map(chartDataMap)}
                                            </ReactSortable>)}
                                    </Col>
                                    <Col span={24} style={{ paddingTop: 6 }}>
                                        <Button variant={'contained'} display="inline"
                                            fullWidth
                                            style={{
                                                textTransform: 'none', backgroundColor: '#404050', fontSize: 14,
                                            }}
                                            onClick={() => {
                                                const item = {
                                                    key: uuid(),
                                                    locationid: '',
                                                    parameterid: '',
                                                    color: randomColor(),
                                                    markerFill: randomColor(),
                                                    markerLine: randomColor(),
                                                    type: 'spline',
                                                    dashed: 'Solid',
                                                    lineWidth: 2,
                                                    yAxis: 0,
                                                    tooltip: {},
                                                    marker: {},
                                                    offset: 0,
                                                    units: ''
                                                };
                                                if (attributes.chartData) {
                                                    attributes.chartData.push(item);
                                                    this.setState({ attributes });

                                                }
                                                else {
                                                    attributes.chartData = [];
                                                    attributes.chartData.push(item);
                                                    this.setState({ attributes });

                                                }
                                            }}>

                                            <AddIcon />  Add Series
                                        </Button>
                                    </Col>
                                </Row>
                            </Col ></TabPanel>
                        <TabPanel value="axises">
                            <Col xs={24} style={{ paddingTop: 4 }} >
                                <Row align="middle" gutter={[12, 12]}>
                                    <Col span={20}>
                                        <Typography fontSize={14} fontWeight={500} variant="body1">Axes</Typography>
                                    </Col>
                                    <Col span={4}>
                                        <Fab style={{ position: 'relative', zIndex: 0 }} size="small" variant='contained' color="primary" onClick={() => {
                                            const item = {
                                                key: uuid(),
                                                axisId: attributes.axises !== undefined ? Math.max.apply(Math, attributes.axises.map((o) => { return o.axisId + 1; })) : 0,
                                                label: '',
                                                opposite: false,
                                                customRange: false,
                                                min: 0,
                                                max: 100,
                                                labelRotation: 270,
                                                axisColor: '#000000',
                                                gridLineColor: '#000000',
                                                gridLineDashStyle: 'Solid',
                                                gridLineWidth: 0.3,
                                                alignTicks: true,
                                                endOntick: true,
                                                reversed: false,
                                                advancedSettings: false,
                                                title: {
                                                    text: ''
                                                }
                                            };

                                            if (attributes.axises) {
                                                attributes.axises.push(item);
                                                this.setState({ attributes });

                                            }
                                            else {
                                                attributes.axises = [];
                                                attributes.axises.push(item);
                                                this.setState({ attributes });

                                            }
                                        }}>
                                            <AddIcon />
                                        </Fab>
                                    </Col>

                                    <Col span={24} style={{ paddingTop: 6 }}>
                                        <Divider />
                                    </Col>
                                    <Col span={24} style={{ paddingTop: 6 }}>

                                        {attributes.axises !== undefined && attributes.axises.length > 0 && (
                                            <ReactSortable
                                                handle=".my-handle"
                                                animation={500}
                                                list={attributes.axises}
                                                setList={(newState) => {
                                                    attributes.axises = newState;
                                                    this.setState({ attributes })
                                                }}
                                            >

                                                {attributes.axises.map(axisMap)}
                                            </ReactSortable>)}
                                    </Col>


                                </Row>
                            </Col>
                        </TabPanel>
                        <TabPanel value="references">
                            <Col xs={24} style={{ paddingTop: 4 }} >
                                <Row align="middle" gutter={[12, 12]}>
                                    <Col span={20}>
                                        <Typography fontSize={14} fontWeight={500} variant="body1">References</Typography>
                                    </Col>
                                    <Col span={4}>
                                        <Fab style={{ position: 'relative', zIndex: 0 }} size="small" variant='contained' color="primary" onClick={() => {
                                            const item = {
                                                key: uuid(),
                                                yAxis: 0,
                                                reference: null,
                                                date: moment().format('x'),
                                                labelRotation: 270,
                                                color: '#000000',
                                                referenceDashed: 'Solid',
                                                width: 3,
                                                layer: 20,
                                                dashStyle: 'Solid',
                                                verticalAlign: 'top',
                                                textAlign: 'left',
                                                offset: 0,
                                                advancedSettings: false,
                                                useA: false,
                                                title: '',
                                                alias: '',
                                                label: {}
                                            };

                                            if (attributes.references) {
                                                attributes.references.push(item);
                                                this.setState({ attributes });

                                            }
                                            else {
                                                attributes.references = [];
                                                attributes.references.push(item);
                                                this.setState({ attributes });

                                            }
                                        }}>
                                            <AddIcon />
                                        </Fab>
                                    </Col>

                                    <Col span={24} style={{ paddingTop: 6 }}>
                                        <Divider />
                                    </Col>
                                    <Col span={24} style={{ paddingTop: 6 }}>

                                        {attributes.references !== undefined && attributes.references.length > 0 && (
                                            <ReactSortable
                                                handle=".my-handle"
                                                animation={500}
                                                list={attributes.references}
                                                setList={(newState) => {
                                                    attributes.references = newState;
                                                    this.setState({ attributes })
                                                }}
                                            >
                                                {attributes.references.map(referencesMap)}
                                            </ReactSortable>)}
                                    </Col>


                                </Row>
                            </Col>
                        </TabPanel>
                    </TabContext>
                </Col>







            </Row >
        );
    }
} 