import { Button, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import React from 'react';

import { useCSVReader } from 'react-papaparse';



export default function CSVReader({ getKeys, removeFile }) {
  const { CSVReader } = useCSVReader();

  return (
    <Row>
      <Col span={24}>
        <CSVReader
          onUploadAccepted={(results) => {

            getKeys(results)
          }}
        >
          {({
            getRootProps,
            acceptedFile,
            ProgressBar,
            getRemoveFileProps,
          }) => (

            <Row align="middle" gutter={[12, 12]} style={{}}>
              <Col span={24} type='button' {...getRootProps()} style={{
                width: '100%', height: '100%', border: '1px dashed gray',
                borderRadius: 8, padding: 20, textAlign: 'center', paddingTop: 10
              }} >

                <Typography variant='h5' fontWeight={400}>{acceptedFile ? acceptedFile.name : "Drag and Drop File"}</Typography>
              </Col>

              <Col span={24}>

                <Button variant='contained' {...getRemoveFileProps()}
                  size="large"
                  style={{
                    textTransform: 'none',
                    backgroundColor: '#3f51b5',
                  }}

                  onClick={() => removeFile('upload')}  >
                  Remove all capacity data
                </Button>

              </Col>
              <Col span={24}>

                <ProgressBar />

              </Col>


            </Row>


          )}
        </CSVReader>
      </Col>
    </Row>
  );
}