import { Button, Divider, OutlinedInput, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import React, { useEffect, useState, useMemo } from 'react';
import { fullMobile } from '../util';
import 'react-data-grid/lib/styles.css';
import uuid from 'react-uuid';
import { memo } from 'react';
import moment from 'moment-timezone';
import { DataGrid } from '@mui/x-data-grid';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, orderBy, limit } from 'firebase/firestore';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { SearchOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { faSort } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';





export default function DistributionGroupsTable(props) {
    const { userInfo, screenWidth, openSnack, company, account, staffRef, usersRef, groupsRef } = props;
    const [loading, setLoading] = useState(true);
    const [rowCount, setRowCount] = useState(0);
    const [data, setData] = useState(groupsRef);
    const [filterData, setFilterData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRowsKeys, setSelectedRowsKeys] = useState([]);
    const [sortColumns, setSortColumns] = useState([]);
    const [staff, setStaff] = useState([]);
    const [users, setUsers] = useState([]);
    const [searchValue, setSearchValue] = useState('')

    useEffect(() => {
        window.scrollTo(0, 0)
        getInfo()
    }, [])

    useEffect(() => {
        setData(groupsRef)
        setFilterData(groupsRef)
    }, [groupsRef])

    const db = getFirestore()

    async function getInfo() {


    }







    const EditButton = (props) => {
        let navigate = useNavigate()
        return (
            <Button variant={'outlined'} display="inline"
                size="small"
                style={{
                    width: 50,
                    textTransform: 'none', border: '1px solid #404050', fontSize: 12, color: '#404050', backgroundColor: 'white'
                }}
                onClick={async () => {
                    const userRef = doc(db, "users", userInfo.id, 'currentItems', 'currentDistributionGroup');
                    await setDoc(userRef, {
                        key: props.group.key
                    }).then(() => {
                        navigate("/editDistributionGroup");
                    })
                }}
            >
                Edit
            </Button>

        )
    }

    let navigate = useNavigate()

    return (

        <Row align="middle" gutter={[12, 12]}>
            <Col {...fullMobile(24, 24)}>
                <OutlinedInput
                    style={{ width: 300, backgroundColor: 'white' }}
                    placeholder="Search Groups"
                    value={searchValue}
                    endAdornment={
                        <SearchOutlined />
                    }
                    onChange={(e) => {
                        //if e.target.value is empty, set filterData to data
                        setSearchValue(e.target.value)
                        if (e.target.value === '') {
                            setFilterData(data)
                        } else {
                            //use regex to filter data
                            let filtered = data.filter((item) => {
                                let regex = new RegExp(e.target.value, 'gi')
                                return item.name.match(regex) || item.description.match(regex)
                            })
                            setFilterData(filtered)
                        }

                    }}
                />
            </Col>



            <Col {...fullMobile(24, 24)}
             style={{
                overflowX: 'scroll',
            }}
            >
                <Table sx={{ minWidth: 650, backgroundColor: 'white' }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell >
                                <Typography variant="body2" display="inline" fontWeight={600} fontSize={16} style={{ color: '#404050' }}>
                                    Name <FontAwesomeIcon fontSize={14} icon={faSort} color="#9ec1c9" />
                                </Typography>
                            </TableCell>
                            <TableCell >
                                <Typography variant="body2" display="inline" fontWeight={600} fontSize={16} style={{ color: '#404050' }}>
                                    Description
                                </Typography>
                            </TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filterData.map((row, i) => {
                            const backgroundColor = i % 2 === 0 ? '#f8f8f8' : 'white';
                            return (
                                <TableRow
                                    key={row.id}
                                    sx={{
                                        '&:last-child td, &:last-child th': { border: '1px solid rgba(0,0,0,0.2)' },
                                        //onhover change background color
                                        '&:hover': {
                                            backgroundColor: '#40405036'
                                        },
                                        backgroundColor: backgroundColor,
                                        cursor: 'pointer'
                                    }}
                                    onClick={async () => {

                                        const userRef = doc(db, "users", userInfo.id, 'currentItems', 'currentDistributionGroup');
                                        await setDoc(userRef, {
                                            key: row.key
                                        }).then(() => {
                                            navigate("/editDistributionGroup");
                                        })

                                    }}
                                >
                                    <TableCell
                                        style={{ border: '1px solid rgba(0,0,0,0.2)' }}
                                        component="th" scope="row">
                                        <EditButton group={row} />
                                    </TableCell>
                                    <TableCell
                                        //add border to all sides
                                        style={{ border: '1px solid rgba(0,0,0,0.2)' }}

                                    >
                                        <Typography variant="body2" display="inline" fontWeight={600} fontSize={14} style={{ color: '#404050' }}>
                                            {row.name}
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        style={{ border: '1px solid rgba(0,0,0,0.2)' }}
                                    >
                                        <Typography variant="body2" display="inline" fontWeight={400} fontSize={14} style={{ color: '#404050' }}>
                                            {row.description}
                                        </Typography>
                                    </TableCell>


                                </TableRow>

                            )
                        })}
                    </TableBody>
                </Table>
            </Col>


        </Row >




    );
}