import React, { useEffect } from 'react';
import { Row, Col } from 'antd';
import { Typography } from '@mui/material';
import Carousel from 'react-material-ui-carousel';

export default function WidgetCarousel(props) {
    const { attributes } = props;

    useEffect(() => {
      
        
    }, []);

    if (!attributes.carouselImages || attributes.carouselImages.length === 0) {
        return (
            <div>
                <Typography>Loading Data</Typography>
            </div>
        );
    } else {
        return (
            <Row gutter={[8, 8]}>
                <Col span={24}>
                    <Carousel
                        autoPlay={attributes.autoplay || false}
                        indicators={true}
                        navButtonsAlwaysVisible={true}
                        animation="slide"
                        timeout={500}
                    >
                        {attributes.carouselImages.map((image) => (
                            <div
                                key={image.key}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    position: 'relative',
                                    paddingBottom: '56.25%', // 16:9 aspect ratio
                                    overflow: 'hidden',
                                }}
                            >
                                <img
                                    src={image.downloadURL}
                                    alt={image.title}
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        objectFit: attributes.objectFit || 'scale-down',
                                    }}
                                />
                                <div
                                    style={{
                                        position: 'absolute',
                                        bottom: 0,
                                        left: 0,
                                        width: '100%',
                                        padding: '20px',
                                        background: 'rgba(0, 0, 0, 0.5)',
                                        color: 'white',
                                    }}
                                >
                                    <Typography variant="h6">{image.title}</Typography>
                                    <Typography variant="body1">{image.description}</Typography>
                                </div>
                            </div>
                        ))}
                    </Carousel>
                </Col>
            </Row>
        );
    }
}