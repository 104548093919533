import { Col, Row } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { fullMobile } from '../../util';
import HighchartsReact from "highcharts-react-official";
import highchartsMore from "highcharts/highcharts-more.js";
import Highcharts from "highcharts/highcharts.js";
import moment from 'moment';
import { CircularProgress, Divider, Typography } from '@mui/material';
import axios, * as others from 'axios';
var convert = require('convert-units')
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/data')(Highcharts);




export default function WidgetChart(props) {
    const { userInfo, screenWidth, openSnack, widgetRef, layoutRef, updateZoom, zoomRef } = props;
    const [layout, setLayout] = useState(layoutRef);
    const [widget, setWidget] = useState(widgetRef);
    const chartDiv = useRef(null);
    const plotRef = useRef(null);
    const chartRef = useRef(null);
    const [loading, setLoading] = useState(true);
    const [zoom, setZoom] = useState(zoomRef);
    const [chartOptions, setChartOptions] = useState({
        credits: {
            enabled: false
        },
        exporting: {
            enabled: false
        },
        stockTools: {
            gui: {
                enabled: false
            }
        },
        chart: {
            resetZoomButton: {
                theme: {
                    style: {
                        display: 'none'

                    }
                }
            },





            zoomType: 'x',

            height: null,
            animation: false
        },
        title: {
            text: null
        },
        legend: {
            itemStyle: {
                fontSize: '14px',
                fontFamily: 'Roboto, sans-serif'
            },
        },


        series: [],
        yAxis: [{ id: 0, title: { text: null }, }],
        xAxis: {
            type: 'datetime',
            startOnTick: false,
            endOnTick: false,
            plotLines: [],

        },
        tooltip: {
            xDateFormat: '%b %d, %Y %I:%M %p',
            shared: true
        },
        plotOptions: {
            series: {
                animation: false
            },


        },
        time: {
            useUTC: false,
        }
    });


    useEffect(() => {
        console.log(widget)
        if (zoomRef === false) {
            setLoading(true)
            queryData(widget)
        }


    }, [zoomRef])


    useEffect(() => {

        setWidget(widget)

        chartOptions.chart.events = {

            selection: (event) => {
                console.log(event)
                if (event.resetSelection) {
                    updateZoom(false)
                } else {
                    updateZoom(true)
                }
                if (event.xAxis != null) {

                    const start = moment(Math.ceil(event.xAxis[0].min), 'x').format('x');
                    const end = moment(Math.ceil(event.xAxis[0].max), 'x').format('x');

                    setWidget(widget)
                    queryData(widget, start, end)


                }

                if (event.xAxis === undefined) {




                    setWidget(widget)
                    queryData(widget)



                }



            }
        };
        setChartOptions(chartOptions)
    }, [])





    useEffect(() => {
        setLoading(true)
        setWidget(widgetRef)
    }, [widgetRef])
    useEffect(() => {
        setLayout(layoutRef)
    }, [layoutRef])




    //create chart
    useEffect(() => {
        if (widget) {
            //creeate chart

            let chart = Highcharts.chart(chartDiv.current, chartOptions);
            chartRef.current = chart;
            const dateInterval = widget.dateInterval;
            //update chart shareAxis

            chartRef.current.update({
                chart: {
                    ...chartOptions.chart,
                    shareAxis: widget.shareAxis
                },
                legend: {
                    enabled: widget.legend,
                    itemStyle: {
                        fontSize: `${widget.legendFontSize || 14}px`,
                        fontFamily: 'Roboto, sans-serif'
                    },
                },
                xAxis: {
                    ...chartOptions.xAxis,
                    labels: {
                        style: {
                            fontSize: `${widget.xAxisFontSize || 14}px`,
                            fontFamily: 'Roboto, sans-serif',

                        },
                        rotation: widget.xAxisLabelAngle || 0,
                        format: widget.dateFormat !== undefined ? `{value:${widget.dateFormat}}` : undefined,



                    },
                    tickInterval: dateInterval === 'auto' ? undefined :
                        dateInterval === 'monthly' ? 30 * 24 * 3600 * 1000 :
                            dateInterval === 'weekly' ? 7 * 24 * 3600 * 1000 :
                                dateInterval === 'daily' ? 24 * 3600 * 1000 :
                                    dateInterval === 'hourly' ? 3600 * 1000 :
                                        //biweekly
                                        dateInterval === 'biweekly' ? 14 * 24 * 3600 * 1000 :
                                            //quarterly
                                            dateInterval === 'quarterly' ? 90 * 24 * 3600 * 1000 :
                                                //semiannually
                                                dateInterval === 'semiannually' ? 180 * 24 * 3600 * 1000 :
                                                    //bimonthly
                                                    dateInterval === 'bimonthly' ? 60 * 24 * 3600 * 1000 :
                                                        //annually
                                                        dateInterval === 'yearly' ? 365 * 24 * 3600 * 1000 : undefined


                },

            }, false, false, false)



            setLoading(true)
            console.log("UPDATE ZOOM")
            addAxes(widget)


        }
    }, [widget])

    useEffect(() => {


    }, [])

    //if height or width of chart div changes update chart
    useEffect(() => {
        if (chartRef.current) {
            //find out what percent of the parent div is infoDiv and set plotRef height to 100% - infoDiv height

            chartRef.current.reflow();





        }
    }, [chartDiv.current?.clientHeight, chartDiv.current?.clientWidth])

    //add axes
    async function addAxes(widget) {

        const axes = widget.axes;

        //add axes to chart
        if (axes) {
            //check if axes are already added
            axes.forEach((axis, index) => {
                const item = {
                    ...axis,
                    min: axis.customRange ? axis.min : null,
                    max: axis.customRange ? axis.max : null,
                    title: {
                        text: axis.label || null,
                        style: { fontSize: `${axis.fontSize || 12}px` },
                    },
                    labels: {

                        style: { fontSize: `${axis.fontSize || 12}px` },

                        autoRotation: [-10, -20, -30, -40, -50, -60, -70, -80, -90, -100, -110, -120, -130, -140, -150, -160, -170, -180],
                        rotation: axis.labelAngle || 0,
                        fontFamily: 'Roboto, sans-serif'
                    },

                }
                //if axis is not already added
                if (!chartRef.current.yAxis[index]) {
                    //add axis
                    console.log(axis)
                    chartRef.current.addAxis({
                        ...item,
                    }, false, false, false);
                }
                else {
                    //update axis
                    console.log(axis)
                    chartRef.current.yAxis[index].update({
                        ...item,

                    }, false);
                }
            });
            //redraw chart
            chartRef.current.redraw();
            addReferences(widget)
        }
    }


    //add references
    async function addReferences(widget) {
        const references = widget.references;
        //add references to chart
        if (references) {
            //remove all references
            chartRef.current.xAxis[0].removePlotLine();
            //remove all from each yAxis
            chartRef.current.yAxis.forEach((axis, index) => {
                axis.removePlotLine();
            });


            if (references.length === 0) {
                queryData(widget)
            }
            //check if references are already added
            references.forEach((reference, index) => {
                const item = {
                    id: reference.id,

                    dashStyle: reference.dashStyle || 'solid',
                    color: reference.color || '#000000',
                    value: reference.orientation === 'vertical' ? reference.date : reference.yValue,
                    width: reference.width || 3,
                    zIndex: 5,
                    label: {
                        align: reference.textAlign || 'left',
                        verticalAlign: reference.verticalAlign || 'top',
                        text: reference.label || null,
                        style: {
                            color: reference.color || '#000000',
                            fontSize: `${reference.fontSize || 14}px`,
                            fontFamily: 'Roboto, sans-serif'
                        }
                    }
                }
                //if reference is not already added
                //add reference to xAxis if orientation is vertical
                if (reference.orientation === 'vertical') {
                    //if reference is not already added
                    const index = chartRef.current.xAxis[0].plotLinesAndBands.findIndex(plotLine => plotLine.id === reference.id);
                    if (index === -1) {
                        //add reference
                        chartRef.current.xAxis[0].addPlotLine({
                            ...item,

                        },);
                    }
                    else {
                        //update reference
                        chartRef.current.xAxis[0].plotLinesAndBands[index].update({
                            ...item,
                        },);

                    }

                }
                else {

                    //add reference to associated yAxis, yaxis not xaxis
                    const yAxisIndex = widget.axes.findIndex(yAxis => yAxis.axisId === reference.yAxis);
                    console.log(yAxisIndex)
                    //if yAxis is found
                    if (yAxisIndex > -1) {
                        console.log(reference)
                        console.log(yAxisIndex)
                        //if reference is not already added
                        if (!chartRef.current.yAxis[yAxisIndex].plotLinesAndBands[index]) {
                            //add reference
                            chartRef.current.yAxis[yAxisIndex].addPlotLine({
                                ...item,
                            },);
                        }
                        else {
                            //update reference
                            chartRef.current.yAxis[yAxisIndex].plotLinesAndBands[index].update({
                                ...item,
                            },);
                        }
                    }
                }

                //if last reference is added
                if (index === references.length - 1) {
                    //redraw chart
                    chartRef.current.redraw();
                    queryData(widget)
                }
            });
        }

    }









    async function queryData(widget, start, end) {

        const proxyUrl = "https://mycorslake.herokuapp.com/";

        const series = widget.series;

        const startDate = moment(start || widget.startDate, 'x').format('YYYY-MM-DD HH:mm:ss')
        const endDate = moment(end || widget.endDate, 'x').format('YYYY-MM-DD HH:mm:ss')

        function someAsyncFunction(q, index) {

            return new Promise(async (resolve, reject) => {

                const item = q;

                const raw = `SELECT COUNT (*) FROM ${item?.dataType === 'probe' ? 'probe_data' : 'node_data_new2'}
            WHERE ${item?.dataType === 'probe' ? 'locationid' : 'nodeid'} = '${item?.nodeid}'and parameterid = '${item?.parameterid}'
            AND time >= '${startDate}' AND time <= '${endDate}'
        
        ;`

                axios.post(proxyUrl + `https://us-central1-aquasource3.cloudfunctions.net/widgetsFire2/sqlRead`, {
                    raw
                }).then(async (response) => {
                    let count = response.data[0].count;


                    //max series length should be 500 points, if count is more than 500 then time bucket data into intervals that will provide 500 points
                    const interval = Math.ceil(count / 750)

                    const customBucketNode = `SELECT time_bucket('${item?.groupByhours === 0 ? interval : item?.groupByhours !== undefined && item?.groupByhours !== '' ?
                        item?.groupByhours : interval} hours', time) as time, AVG(value) as value 
                FROM ${item?.dataType === 'probe' ? 'probe_data' : 'node_data_new2'}
                WHERE ${item?.dataType === 'probe' ? 'locationid' : 'nodeid'} = '${item?.nodeid}'and parameterid = '${item?.parameterid}'
                AND time >= '${startDate}' AND time <= '${endDate}'
                GROUP BY time_bucket('${item?.groupByhours === 0 ? interval : item?.groupByhours !== undefined && item?.groupByhours !== '' ?
                            item?.groupByhours : interval} hours', time)
                ORDER BY time_bucket('${item?.groupByhours === 0 ? interval : item?.groupByhours !== undefined && item?.groupByhours !== '' ?
                            item?.groupByhours : interval} hours', time) ASC;`

                    axios.post(proxyUrl + `https://us-central1-aquasource3.cloudfunctions.net/widgetsFire2/sqlRead`, {
                        raw: customBucketNode
                    }).then(async (response) => {
                        const data = response.data;
                        const convertData = [];
                        const decimals = item?.decimals || 2;
                        const offset = item?.offset || 0;
                        data.forEach((d) => {
                            const value =
                                item.parameterid === '3' ? Number(convert(d.value).from('m').to(['m', 'ft'].includes(item.units) ? item.units : 'm')) :
                                    item.parameterid === '1' ? Number(convert(d.value).from('C').to(['C', 'F'].includes(item.units) ? item.units : 'C')) :
                                        item.parameterid === '19' ? Number(d.value) * 1000 :
                                            !['1', '3', '19'].includes(item.parameterid) ? Number(d.value) : Number(d.value);

                            const finalValue = value + offset;
                            convertData.push([Number(moment(d.time, 'YYYY-MM-DD HH:mm').format('x')), finalValue])
                        })

                        //check if series is already in chart by id, if it is then update data, if not then add series
                        if (chartRef.current.get(item.key)) {
                            console.log(convertData.length)
                            chartRef.current.get(item.key).setData(convertData, true, true)
                            //chart is not zooming out when new data is added
                            chartRef.current.xAxis[0].setExtremes(
                                convertData[0][0],
                                convertData[convertData.length - 1][0]
                            );
                        } else {
                            chartRef.current.addSeries({
                                id: item.key,
                                name: item.label || `Series ${index}`,
                                data: convertData,
                                type: item.type,
                                color: item.color,
                                yAxis: item.yAxis,
                                visible: item.visible,
                                marker: {
                                    enabled: item.dots,
                                },
                                tooltip: {
                                    valueDecimals: decimals,
                                    valueSuffix: item.unitsLabel ? ` ${item.unitsLabel}` : '',
                                },
                                zIndex: item.zIndex,
                                dashStyle: item.dashStyle,
                                lineWidth: item.lineWidth,
                                fillOpacity: item.fillOpacity,
                                fillColor: item.fillColor,
                                negativeColor: item.negativeColor,
                                threshold: item.threshold,
                                step: item.step,



                            })
                        }







                        resolve(convertData);

                    }).catch((error) => {
                        console.log(error)

                    })





                }).catch((error) => {
                    console.log(error)


                })


            });
        }











        const resultArray = await Promise.all(series.map(async (i, index) => someAsyncFunction(i, index + 1)));
        setLoading(false);



    }

    const attributes = widget;




    //if loading is a string show error message

    return (

        //create div that adjust with size of parent div
        <div style={{ width: '100%', height: '100%', position: 'relative', }}>

            {loading === true && (<div style={{
                zIndex: 100,
                height: '100%',
                top: '0%',
                textAlign: 'center',
                position: 'absolute',
                width: '100%',
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                //center div vertically and horizontally
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',

            }}>
                <Row gutter={[12, 12]}>
                    <Col>
                        <Typography variant="h6" style={{ color: 'gray', paddingBottom: 20 }}>
                            Query Data...
                        </Typography>
                        <CircularProgress />
                    </Col>

                </Row>



            </div>)}
            <div ref={chartDiv} style={{ width: '100%', height: '100%', position: 'relative', }}>


                <div id={`${widget.key}`} ref={plotRef} style={{
                    height: '50%',
                    width: '100%',
                    alignContent: 'center',
                    position: 'relative'
                }}>

                </div>

            </div>




        </div >

    );




}