

import { Button, Checkbox, Divider, OutlinedInput, Typography, Select, MenuItem, CircularProgress, Paper, IconButton } from '@mui/material';
import { Col, Row } from 'antd';
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { fullMobile } from '../products/use/util';
import axios, * as others from 'axios';
import moment from 'moment-timezone';
import uuid from 'react-uuid';
import { doc, getFirestore, setDoc, query, collection, where, getDoc, getDocs, updateDoc, collectionGroup, deleteDoc } from 'firebase/firestore';

import './apiTestStyles.css';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import queryString from 'query-string';
import { MuiAutocompleteEditor, NumberInputRenderer } from './cellRenderers';





export default function APITest({ userInfo }) {

    useEffect(() => {

        getStations()

    }, [])








    async function getStations() {
        const node = '5188ac66-56cf-91f3-f1cc-635bd4fae438';

        try {


            const proxyurl = "https://mycorslake.herokuapp.com/";
            const response = await axios.post(proxyurl + `https://us-central1-aquasource3.cloudfunctions.net/getStations`, {
                companies: [
                    `38cfab-0eb0-0c3e-f466-71ad3c4865`
                ]
            });
            console.log('Function response:', response.data.triggeredAlerts);
        } catch (error) {
            console.error('Error calling anotherFunction:', error);
        }

    }


    async function getObscapeData() {
        console.log('Getting Obscape data');
        const proxyurl = "https://mycorslake.herokuapp.com/";
        const obscapeDataURL = 'https://www.obscape.com/portal/api/v3/api'; // Updated URL for Obscape API

        const data = {
            username: 'gregorymsmith15', // Your username
            key: 'e6D3k28SGw7zACHUoOXo7dmZqvf0loTzuwCPyKHfgNOQqwLOkI', // Your key
            station: '4470', // Your station ID
            from: '2023-06-07T08:00:00',
            to: '2023-06-09T08:00:00', // Now
            tz: 'local', // Optional: to format time strings in the API output to your project’s local timezone
            // latest: 48, // Optional: to customize the default option that returns the latest 24 hours of observation data
            // parameters: 'Hm0,Tp,Dirp', // Optional: to select a limited set of output parameters
        };

        const url = `${obscapeDataURL}?username=${data.username}&key=${data.key}&station=${data.station}`;

        const obscapeData = await axios.get(proxyurl + url)
            .then(response => {
                return response.data;
            }).catch((error) => {
                console.log(error);
                return 'error';
            });

        console.log(obscapeData);

        return obscapeData;
    }









    async function checkSpypoint() {
        const proxyurl = "https://mycorslake.herokuapp.com/";
        const response = await axios.get(proxyurl + `https://us-central1-aquasource3.cloudfunctions.net/getNewTimelapsePhotosHTTPS`);
        console.log(response)
        console.log(`Function response: success`);
    }




    async function getAllLocations() {
        console.log('Getting all locations')
        const proxyUrl = "https://mycorslake.herokuapp.com/";
        const hydrovuTokenURL = 'https://www.hydrovu.com/public-api/oauth/token';
        const hydrovuLocationsURL = 'https://www.hydrovu.com/public-api/v1/locations/list';


        const data = {
            grant_type: 'client_credentials',
            client_id: 'testNew',
            client_secret: '11d28cb08c2444a2966931244ee43e08',
        };

        const response = await axios.post(proxyUrl + hydrovuTokenURL, queryString.stringify(data));
        const { access_token } = response.data;
        console.log(access_token)
        const AuthStr = 'Bearer '.concat(access_token);

        const pingData = {
            headers: { Authorization: AuthStr, "X-ISI-Start-Page": `` },
            params: {
                startTime: moment().subtract(96, 'hours').format('X')
            },
        }


        const pingData2 = await axios.get(`https://mycorslake.herokuapp.com/https://www.hydrovu.com/public-api/v1/locations/${5945403018313728}/data/`,
            {
                headers: { Authorization: AuthStr, "X-ISI-Start-Page": `` },
                params: {
                    startTime: moment().subtract(24, 'hours').format('X')
                },
            })
            .then(response => {
                return response.data;
            }).catch((error) => {
                console.log(error);
                return 'error'
            });

        console.log(pingData2)


        let locations = [];
        let nextPage;

        /*   while (true) {
              const pageResponse = await axios.get(proxyUrl + hydrovuLocationsURL, {
                  headers: { Authorization: AuthStr, "X-ISI-Start-Page": `${nextPage}` },
              });
              console.log(pageResponse.data)
  
              if (pageResponse.data.length === 0) {
                  break;
              }
  
              locations = locations.concat(pageResponse.data);
              nextPage = pageResponse.headers['x-isi-next-page'];
              if (!nextPage) {
                  break;
              }
          } */

        return locations;
    }




    const getAccounts = async () => {
        const db = getFirestore();
        //collection group
        const accountsRef = collectionGroup(db, 'accounts');
        const accountsSnapshot = await getDocs(accountsRef);
        const accounts = accountsSnapshot.docs.map((doc) => {
            return { ...doc.data(), id: doc.id }
        });
        console.log(accounts.length)
        const accountNames = accounts.map((account) => account.accountName);
        console.log(accountNames)
        //remove undefined or duplicates
        const filteredAccountNames = accountNames.filter((accountName) => accountName !== undefined);
        const uniqueAccountNames = [...new Set(filteredAccountNames)];
        console.log(uniqueAccountNames)
    }


    const callAnotherFunction = async () => {
        const node = '5188ac66-56cf-91f3-f1cc-635bd4fae438';

        try {
            const db = getFirestore();
            const alertsRef = collection(db, 'clients', userInfo.currentCompany, 'newAlerts');
            const alertsSnapshot = await getDocs(alertsRef);
            const alerts = alertsSnapshot.docs.map((doc) => {
                return { ...doc.data(), id: doc.id }
            });

            const proxyurl = "https://mycorslake.herokuapp.com/";
            const response = await axios.get(proxyurl + `https://us-central1-aquasource3.cloudfunctions.net/anotherFunction?node=${node}`);
            console.log('Function response:', response.data.triggeredAlerts);
        } catch (error) {
            console.error('Error calling anotherFunction:', error);
        }
    };
    const callAnother = async () => {
        const node = '5188ac66-56cf-91f3-f1cc-635bd4fae438';

        try {
            const db = getFirestore();
            const alertsRef = collection(db, 'clients', userInfo.currentCompany, 'newAlerts');
            const alertsSnapshot = await getDocs(alertsRef);
            const alerts = alertsSnapshot.docs.map((doc) => {
                return { ...doc.data(), id: doc.id }
            });

            console.log("PROXY")

            const proxyurl = "https://mycorslake.herokuapp.com/";
            const response = await axios.get(proxyurl + `https://us-central1-aquasource3.cloudfunctions.net/anotherFunction?node=${node}`);
            console.log('Function response:', response.data.triggeredAlerts);
        } catch (error) {
            console.error('Error calling anotherFunction:', error);
        }
    };












    async function DeleteAllFoldersFiles() {
        const db = getFirestore();
        //get all clients
        const clientsRef = collection(db, 'clients');
        const clientsSnapshot = await getDocs(clientsRef);
        const clients = clientsSnapshot.docs.map((doc) => doc.id);
        console.log(clients)

        for (let i = 0; i < clients.length; i++) {
            //delete folders collection
            const foldersRef = collection(db, 'clients', clients[i], 'folders');
            const foldersSnapshot = await getDocs(foldersRef);
            const folders = foldersSnapshot.docs.map((doc) => doc.id);
            console.log(folders)
            for (let j = 0; j < folders.length; j++) {
                await deleteDoc(doc(db, 'clients', clients[i], 'folders', folders[j]));
            }

            //delete files collection
            const filesRef = collection(db, 'clients', clients[i], 'files');
            const filesSnapshot = await getDocs(filesRef);
            const files = filesSnapshot.docs.map((doc) => doc.id);
            console.log(files)
            for (let j = 0; j < files.length; j++) {
                await deleteDoc(doc(db, 'clients', clients[i], 'files', files[j]));
            }



        }
    }








    async function runDataForDays() {
        const daysAgo = 14;
        for (let day = daysAgo; day > 0; day--) {
            const startTime = moment().subtract(day, 'days').startOf('day');
            const endTime = moment(startTime).add(100, 'days');
            console.log(`Fetching data for day: ${day} days ago`);
            await getData(startTime, endTime);
        }
    }




    const proxyurl = "https://mycorslake.herokuapp.com/";

    async function fetchToken() {
        const hydrovuTokenURL = "https://www.hydrovu.com/public-api/oauth/token";
        const data = {
            grant_type: "client_credentials",
            client_id: "testNew",
            client_secret: "11d28cb08c2444a2966931244ee43e08",
        };

        const response = await axios.post(proxyurl + hydrovuTokenURL, queryString.stringify(data));
        const { access_token } = response.data;
        return "Bearer ".concat(access_token);
    }

    async function fetchSensorData(sensor, token, startTime) {
        const id = sensor.sensor.id;
        const hydrovuLocationURL = proxyurl + `https://www.hydrovu.com/public-api/v1/locations/${id}/data`;

        const response = await axios.get(hydrovuLocationURL, {
            params: { startTime: startTime.format('X') },
            headers: { Authorization: token, "X-ISI-Start-Page": `` },
        });

        return { sensor, data: response.data };
    }

    async function getData(startTime, endTime) {
        const token = await fetchToken();
        const db = getFirestore();
        const stationsRef = collection(db, 'clients', userInfo.currentCompany, 'stations');
        const stationsSnapshot = await getDocs(stationsRef);
        const sensors = stationsSnapshot.docs.flatMap((doc) => doc.data().children || []).filter((child) => child.type === 'sensor');

        for (let currentTime = startTime; currentTime.isBefore(endTime); currentTime.add(12, 'hours')) {
            const responses = await Promise.all(sensors.map((sensor) => fetchSensorData(sensor, token, currentTime)));

            for (const { sensor, data: originalData } of responses) {
                const transformedData = originalData.parameters.flatMap((parameter) =>
                    parameter.readings.map((reading) => {
                        const time = moment(reading.timestamp, 'X').tz("America/Los_Angeles").format("YYYY-MM-DD HH:mm");
                        const locationid = originalData.locationId;
                        const item = {
                            time: `'${time}'`,
                            timestamp: reading.timestamp,
                            locationid: `'${locationid}'`,
                            unitid: `'${parameter.unitId}'`,
                            parameterid: `'${parameter.parameterId}'`,
                            value: reading.value,
                            account: `'${sensor.account}'`,
                            company: `'${sensor.company}'`,
                            nodeid: `'${sensor.id}'`,
                            key: `'${uuid()}'`,
                            longitude: sensor.sensor.lat,
                            latitude: sensor.sensor.lon,
                        };
                        return item;
                    })
                );

                const hourlyVals = transformedData.map((h) => `(${Object.values(h)})`);
                const hourlyString = hourlyVals.join();

                try {
                    await axios.post(proxyurl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                        raw: `INSERT INTO node_data_new2 VALUES ${hourlyString} ON CONFLICT DO NOTHING; `,
                    });
                    console.log('SUCCESS');
                } catch (error) {
                    console.log(error);
                }
            }
        }
    }




    return (
        <Row style={{ fontFamily: 'Roboto, sans-serif' }} align="middle">
            <Col {...fullMobile(24, 24)} style={{ paddingTop: 30 }}>
                <Button variant="contained" color="primary">
                    Test
                </Button>


            </Col>

        </Row>
    );
}