import { Button, Checkbox, Divider, OutlinedInput, Typography, Select, MenuItem, CircularProgress, Breadcrumbs, FormGroup, FormControlLabel, Autocomplete, Chip, TextField } from '@mui/material';
import { Col, Row } from 'antd';
import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { fullMobile } from '../../util';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, } from 'firebase/firestore';

import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import uuid from 'react-uuid';


import DatePicker from "react-datepicker";
import queryString from 'query-string';
import axios from 'axios';
import { Link } from 'react-router-dom';
var randomColor = require('randomcolor');

export default function LogWaterConditions(props) {
    const { userInfo, screenWidth, openSnack, company, account, updateLog, logRef, status } = props;
    const [staff, setStaff] = useState([]);
    const [log, setLog] = useState(logRef);


    useEffect(() => {
        setLog(logRef)
    }, [logRef])


    useEffect(() => {
        setStaff(props.staffRef)
    }, [props.staffRef])

    useEffect(() => {
        if (status === 'built') {
            console.log('purge data')

        }
    }, [status])


    function updateItem(item, val) {
        setLog({ ...log, [item]: val })
        updateLog(item, val)
    }








    return (
        <Row gutter={[12, 12]}>
            <Col {...fullMobile(24, 24)} style={{ paddingTop: 10 }}>
                <Typography variant='h5' display="inline" fontWeight={500} fontSize={screenWidth === 'xs' ? 16 : 22}>Water Conditions</Typography>


            </Col>
            <Col {...fullMobile(8, 24)} style={{}}>
                <Typography variant="body1" fontWeight={600}>Water Body Type:</Typography>
                {status === 'built' ? <Typography variant="body1">{log?.waterBodyType === undefined ? '' : log?.waterBodyType.label}</Typography> : <Autocomplete
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    value={log?.waterBodyType}
                    hideLabel={true}
                    options={[
                        { label: "Basin", key: 'basin' },
                        { label: "Creek", key: 'creek' },
                        { label: "Ditch", key: 'ditch' },
                        { label: "Drain", key: 'drain' },
                        { label: "Lagoon", key: 'lagoon' },
                        { label: "Lake", key: 'lake' },
                        { label: "Lined Canal", key: 'linedCanal' },
                        { label: "Pond", key: 'pond' },
                        { label: "Reservoir", key: 'reservoir' },
                        { label: "Unlined Canal", key: 'unlinedCanal' },
                    ]}
                    renderInput={(params) => <TextField {...params} label="Water Body Type" />}
                    onChange={(a, b) => {
                        updateLog(`waterBodyType`, b)
                    }}

                />}
            </Col>
            <Col {...fullMobile(8, 12)} style={{}}> 
                <Typography variant="body1" fontWeight={600}>Water Temp (F):</Typography>
                {status === 'built' ? <Typography variant="body1">{log?.waterTemp}</Typography> : <OutlinedInput
                    fullWidth notched={false} label="none" placeholder={"Water Temp"} size="small"
                    defaultValue={log?.waterTemp || ''}
                    type="number"
                    onChange={(e) => {
                        updateLog('waterTemp', e.target.value === "" ? Number(0) : e.target.value === "0" ? Number(0) : Number(e.target.value))
                    }} />}
            </Col>
            <Col {...fullMobile(8, 12)} style={{}}>
                <Typography variant="body1" fontWeight={600}>Water Depth (ft):</Typography>
                {status === 'built' ? <Typography variant="body1">{log?.waterDepth}</Typography> : <OutlinedInput
                    fullWidth notched={false} label="none" placeholder={"Water Depth"} size="small"
                    defaultValue={log?.waterDepth}
                    type="number"
                    onChange={(e) => {
                        updateLog('waterDepth', e.target.value === "" ? Number(0) : e.target.value === "0" ? Number(0) : Number(e.target.value))
                    }} />}
            </Col>
            <Col {...fullMobile(8, 12)} style={{}}>
                <Typography variant="body1" fontWeight={600}>Water Flow (ft/s cfs):</Typography>
                {status === 'built' ? <Typography variant="body1">{log?.waterFlow}</Typography> : <OutlinedInput
                    fullWidth notched={false} label="none" placeholder={"Water Flow"} size="small"
                    defaultValue={log?.waterFlow}
                    type="number"
                    onChange={(e) => {
                        updateLog('waterFlow', e.target.value === "" ? Number(0) : e.target.value === "0" ? Number(0) : Number(e.target.value))
                    }} />}
            </Col>
            <Col {...fullMobile(8, 12)} style={{}}>
                <Typography variant="body1" fontWeight={600}>Percent Cloud Cover:</Typography>
                {status === 'built' ? <Typography variant="body1">{log?.percentCloudCover}</Typography> : <OutlinedInput
                    fullWidth notched={false} label="none" placeholder={"Cloud Cover"} size="small"
                    defaultValue={log?.percentCloudCover}
                    type="number"
                    onChange={(e) => {
                        updateLog('percentCloudCover', e.target.value === "" ? Number(0) : e.target.value === "0" ? Number(0) : Number(e.target.value))
                    }} />}
            </Col>

            <Col {...fullMobile(8, 12)} style={{}}>
                <Typography variant="body1" fontWeight={600}>Water Color:</Typography>
                {status === 'built' ? <Typography variant="body1">{log?.waterColor === undefined ? '' : log?.waterColor.label}</Typography> :
                    <Autocomplete
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        value={log?.waterColor}
                        hideLabel={true}
                        options={[
                            { label: "Blue", key: 'blue' },
                            { label: "Green", key: 'green' },
                            { label: "Brown", key: 'brown' },
                        ]}
                        renderInput={(params) => <TextField {...params} label="Water Color" />}
                        onChange={(a, b) => {
                            updateLog(`waterColor`, b)
                        }}

                    />}
            </Col>
            <Col {...fullMobile(8, 12)} style={{}}>
                <Typography variant="body1" fontWeight={600}>Water Clarity:</Typography>
                {status === 'built' ? <Typography variant="body1">{log?.waterClarity === undefined ? '' : log?.waterClarity.label}</Typography> :
                    <Autocomplete
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        value={log?.waterClarity}
                        hideLabel={true}
                        options={[
                            { label: "Poor", key: 'poor' },
                            { label: "Fair", key: 'fair' },
                            { label: "Good", key: 'Good' },
                        ]}
                        renderInput={(params) => <TextField {...params} label="Water Clarity" />}
                        onChange={(a, b) => {
                            updateLog(`waterClarity`, b)
                        }}

                    />}
            </Col>
            <Col {...fullMobile(8, 24)} style={{}}>
                <Typography variant="body1" fontWeight={600}>Water Sheen:</Typography>
                {status === 'built' ? <Typography variant="body1">{log?.waterSheen === true ? "Sheen" : "No Sheen"}</Typography> :
                    <Row align="middle">
                        <Col><Checkbox checked={log.waterSheen}
                            onChange={(e) => {
                                
                                updateLog('waterSheen', !log.waterSheen || true)
                            }} /></Col>
                        <Col>
                            <Typography variant="body1">(check if present)</Typography>
                        </Col>
                    </Row>}
            </Col>

            <Col {...fullMobile(24, 24)}>
                <Divider style={{
                    borderColor: 'rgb(148, 186, 195)',
                    borderBottomWidth: 'medium',

                }} />
            </Col>

        </Row>
    )



}