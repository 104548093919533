import React, { useEffect, useState } from "react";


import { Divider, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import { collection, doc, getDoc, getDocs, getFirestore, onSnapshot, query, setDoc, updateDoc, where, writeBatch } from "firebase/firestore";
import moment from 'moment-timezone';
import TasksTable from "./components/taskTable";
import TasksTableHeader from "./components/taskTableHeader";
import TaskDrawer from "./components/taskDrawer";
import { getAccounts, getCompanyPreferences, getStaff, getTaskPreferences, getTaskTemplates, getTasks, updateTaskPreferences } from "./functions";
import { queryPSItems, queryStocks } from "../firebase/config";
import uuid from "react-uuid";
import ColumnSizingTable from "./components/columnSizingTable.tsx";
import MobileTaskTable from "./components/mobileTasktable";
import ColumnSelection from "./components/columnSelection";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TasksCalendarHome from "./taskCalendar/tasksCalendarHome";

const TasksHomeNew = ({
    screenWidth,
    userInfo,
    account,
    openSnack
}) => {


    const [activeTab, setActiveTab] = useState('tasks');
    const [sortType, setSortType] = useState('');
    const [accounts, setAccounts] = useState([]);
    const [staff, setStaff] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [taskTemplates, setTaskTemplates] = useState([]); // [
    const [items, setItems] = useState([]);
    const [stocks, setStocks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentTask, setCurrentTask] = useState(null);
    const [taskDrawer, setTaskDrawer] = useState(false);
    const [bulkEditor, setBulkEditor] = useState(false);
    const [columnEditor, setColumnEditor] = useState(false);
    const [calendarEditor, setCalendarEditor] = useState(false);
    const [calendars, setCalendars] = useState([]); // [
    const [searchQuery, setSearchQuery] = useState('');
    const [defaultPreferences, setdefaultPreferences] = useState({
        sortItem: null,
        colorCalendar: 'colorByTask',
        columnFilters: {},
        showCompleted: false
    }); 
    const [preferences, setPreferences] = useState({});
    const [defaultCompanyPreferences, setDefaultCompanyPreferences] = useState({

        headers: [
            { position: 0, headerName: "Task", id: "title", getLabel: false, width: 250, visible: true, minWidth: 140, type: 'string', mobile: true, mobileWidth: '60%', optionType: 'string' },
            { position: 1, headerName: "Date", id: "startDate", getLabel: false, width: 160, visible: true, minWidth: 140, type: 'date', mobile: true, mobileWidth: '30%', optionType: 'dateSelect' },
            { position: 2, headerName: "Assignee", id: "staff", getLabel: true, width: 180, visible: true, minWidth: 180, type: 'string', optionType: 'staffSelect' },
            { position: 3, headerName: "Site", id: "site", getLabel: true, width: 160, visible: true, minWidth: 140, type: 'string', optionType: 'siteSelect' },
            { position: 4, headerName: "Color", id: "color", getLabel: false, width: 130, visible: true, minWidth: 130, type: 'color', optionType: 'colorSelect' },
        ]
    })
    const [companyPreferences, setCompanyPreferences] = useState({});


    useEffect(() => {
        window.scrollTo(0, 0)
        getInfo()
        /* updateTaskPreferences(userInfo, defaultPreferences)
         updateTaskPreferences(userInfo, defaultCompanyPreferences, 'company') */

    }, [])



    async function getInfo() {


        const accounts = await getAccounts(userInfo);
        setAccounts(accounts)
        const staff = await getStaff(userInfo);
        setStaff(staff)
        const stocks = await queryStocks(userInfo);
        setStocks(stocks)
        const items = await queryPSItems(userInfo);
        const updatedItems = items.map((item) => {
            return (
                {
                    ...item, label: item.name || ''
                }
            )
        })
        setItems(updatedItems)

        const taskPreferences = await getTaskPreferences(userInfo);
        setPreferences(taskPreferences === 'none' ? defaultPreferences : taskPreferences)
        if (taskPreferences === 'none') {
            updateTaskPreferences(userInfo, defaultPreferences)
        }

        const companyPreferences = await getCompanyPreferences(userInfo);
        setCompanyPreferences(companyPreferences === 'none' ? defaultCompanyPreferences : companyPreferences)
        if (defaultCompanyPreferences === 'none') {
            updateTaskPreferences(userInfo, defaultCompanyPreferences, 'company')
        }


        const tasks = await getTasks(userInfo)
        console.log(tasks)


        setTasks(tasks)

        const calendars = await getCalendars(userInfo)
        setCalendars(calendars)

        updateTasksPosition(tasks)


        const taskTemplates = await getTaskTemplates(userInfo)
        setTaskTemplates(taskTemplates)
        setLoading(false)

    }


    async function updateTasksPosition(tasks) {
        setTasks(tasks)
        const db = getFirestore();
        tasks.forEach(async (task, i) => {
            await updateDoc(doc(db, 'clients', userInfo.currentCompany, 'taskListNew', task.id), { position: i })
        })
    }





    async function deleteTask(task) {
        const db = getFirestore();
        await updateDoc(doc(db, 'clients', userInfo.currentCompany, 'taskListNew', task.id), { archived: true });
        openSnack('success', 'Task Deleted')
        const updatedTasks = tasks.filter((t) => t.id !== task.id)
        updateTasksPosition(updatedTasks)
        setCurrentTask(null)
        setTaskDrawer(false)

    }

    async function deleteSelectedTasks(deleteTasks) {

        const db = getFirestore();

        // Using batch to perform multiple updates in one go for efficiency
        const batch = writeBatch(db);

        // Iterate over tasks to delete and add them to the batch
        deleteTasks.forEach(task => {
            const taskRef = doc(db, 'clients', userInfo.currentCompany, 'taskListNew', task.id);
            batch.update(taskRef, { archived: true });
        });

        // Commit the batch
        await batch.commit();

        openSnack('success', `${deleteTasks.length} Task(s) Deleted`);

        // Filter out the deleted tasks from the local state
        const updatedTasks = tasks.filter(t => !deleteTasks.some(dt => dt.id === t.id));

        updateTasksPosition(updatedTasks);
        setCurrentTask(null);
        setTaskDrawer(false);
    }


    async function duplicateTask(t) {
        const task = { ...t };
        task.id = uuid();
        task.creator = userInfo.id;
        task.title = `${task.title} (Copy)`;
        task.created = moment().format('x');

        const db = getFirestore();
        await setDoc(doc(db, 'clients', userInfo.currentCompany, 'taskListNew', task.id), task);
        openSnack('success', 'Task Duplicated');
        setTaskDrawer(false);

        const newTasks = [...tasks];
        const originalTaskIndex = newTasks.findIndex(task => task.id === t.id);
        newTasks.splice(originalTaskIndex + 1, 0, task);
        updateTasksPosition(newTasks);
    }



    async function convertToTemplate(task) {
        const db = getFirestore();
        const newTask = { ...task };
        newTask.template = true;
        await setDoc(doc(db, 'clients', userInfo.currentCompany, 'taskTemplates', newTask.id), newTask);
        const newTaskTemplates = [...taskTemplates, newTask];
        setTaskTemplates(newTaskTemplates);
        openSnack('success', 'Task Converted to Template')
        setCurrentTask(null)
        setTaskDrawer(false)

    }

    async function deleteTaskTemplate(task) {
        const db = getFirestore();
        await updateDoc(doc(db, 'clients', userInfo.currentCompany, 'taskTemplates', task.id), { archived: true });
        openSnack('success', 'Task Template Deleted')
        const updatedTaskTemplates = taskTemplates.filter((t) => t.id !== task.id)
        setTaskTemplates(updatedTaskTemplates)
        setCurrentTask(null)
        setTaskDrawer(false)
    }

    async function createCalendar(calendar) {
        console.log(calendar)
        const db = getFirestore();
        await setDoc(doc(db, 'calendars', calendar.id), calendar);
        openSnack('success', 'Calendar Created')
    }

    async function getCalendars(userInfo) {
        const db = getFirestore();
        const q = query(collection(db, "calendars"), where("owner", "==", userInfo.id));
        const querySnapshot = await getDocs(q);
        const calendars = querySnapshot.docs.map((doc) => doc.data());
        return calendars
    }



    async function createTask(task) {
        const db = getFirestore();
        const baseTask = {
            id: uuid(),
            creator: userInfo.id,
            account: userInfo.currentAccount,
            company: userInfo.currentCompany,
            created: moment().format('x'),
            archived: false,
            completed: false,
            completedBy: null,
            completedOn: null,
            section: '',
            template: false,
            ...task  // overwrite with actual task details
        };

        let newTasks = [];  // To hold the new recurring tasks
        let positionCounter = 1;  // Initialize the position counter

        if (task.recurring) {
            const startDate = moment(task.startDate, 'x');
            const endRecurringDate = moment(task.endRecurringDate, 'x');
            let currentDate = moment(startDate);

            while (currentDate.isBefore(endRecurringDate)) {
                let id = uuid();  // Generate a new ID for each task

                // Create each individual task
                const recurringTask = {
                    ...baseTask,
                    id,
                    startDate: currentDate.format('x'),
                    position: positionCounter  // Assign the position here
                };

                newTasks.push(recurringTask);  // Add this task to the newTasks array

                await setDoc(doc(db, 'clients', userInfo.currentCompany, 'taskListNew', recurringTask.id), recurringTask);

                // Increment the currentDate based on the recurrence rule
                switch (task.recurring) {
                    case 'Daily':
                        currentDate.add(1, 'days');
                        break;
                    case 'Weekly':
                        currentDate.add(1, 'weeks');
                        break;
                    case 'Monthly':
                        currentDate.add(1, 'months');
                        break;
                    case 'Quarterly':
                        currentDate.add(3, 'months');
                        break;
                    case 'Yearly':
                        currentDate.add(1, 'years');
                        break;
                    default:
                        break;
                }

                positionCounter++;  // Increment the position counter
            }

            updateTasksPosition([...newTasks, ...tasks]);  // Call updateTasksPosition with the new and existing tasks
        } else {
            await setDoc(doc(db, 'clients', userInfo.currentCompany, 'taskListNew', baseTask.id), baseTask);
            updateTasksPosition([baseTask, ...tasks]);
        }

        openSnack('success', 'Task Created');
        setTaskDrawer(false);
    }



    async function updateTask(task, type) {
        if (task?.id) {

            task.lastUpdated = moment().format('x');
            const db = getFirestore();
            await updateDoc(doc(db, 'clients', userInfo.currentCompany, 'taskListNew', task.id), task);
            if (type === "completed") {
                openSnack('success', 'Task Completed!')
            }
            else {
                openSnack('success', 'Task Updated')
            }
            setTaskDrawer(false)
            const updatedTasks = tasks.map((t) => {
                if (t.id === task.id) {
                    return task
                } else {
                    return t
                }
            })
            setTasks(updatedTasks)
        }
        else {
            setCurrentTask(null)
            setTaskDrawer(false)
        }
    }



    async function updateMultipleTasks(selectedTasks, bulkTask) {
        const db = getFirestore();
        const batch = writeBatch(db);

        // Iterate through each selected task.
        selectedTasks.forEach(task => {
            const taskRef = doc(db, 'clients', userInfo.currentCompany, 'taskListNew', task.id);

            // For each task, iterate through each property in bulkTask and update it.
            for (const [key, value] of Object.entries(bulkTask)) {
                batch.update(taskRef, { [key]: value });
            }
        });

        await batch.commit();
        openSnack('success', 'Tasks Updated!');

        // Update local state for the tasks.
        const updatedTasks = tasks.map(task => {
            if (selectedTasks.some(selectedTask => selectedTask.id === task.id)) {
                return { ...task, ...bulkTask };  // Spreading in bulkTask will overwrite properties in task.
            }
            return task;
        });
        setTasks(JSON.parse(JSON.stringify(updatedTasks)));
    }





    const getNestedProperty = (obj, path, getLabel, optionType, options, decimals) => {


        const firstValue = path?.split('.').reduce((acc, part) => acc && acc[part], obj);
        const value = getLabel === true ? firstValue?.label : firstValue;


        if (!optionType || !options) {
            return value;
        }

        if (optionType === 'money') {
            return value !== undefined ? Number(value)?.toFixed(2) : value;
        }
        if (optionType === 'colorSelect') {
            console.log(value)
            return value;
        }
        if (optionType === 'number') {
            return value !== undefined ? Number(value)?.toFixed(decimals) : value;
        }
        if (optionType === 'percent') {
            return value !== undefined ? `${Number(value)}%` : value;
        }

        if (optionType === 'singleSelect') {
            const matchingOption = options.find(option => option.id === value);
            return matchingOption ? matchingOption.name : value;
        }

        if (optionType === 'multiSelect' && Array.isArray(value)) {
            const matchingOptions = value.map(id => {
                const option = options.find(option => option.id === id);
                return option ? option.name : id;
            });
            // Return as comma-separated string, if you want it as an array just return `matchingOptions`
            return matchingOptions.join(', ');
        }

        return value;
    };


    const applyFiltersAndSearch = (tasks, searchQuery, headers, columnFilters, showCompleted) => {


        // Remove any columnFilter that has an array of 0
        Object.keys(columnFilters).forEach(key => {
            if (columnFilters[key].length === 0) {
                delete columnFilters[key];
            }
        });

        // Start by filtering only visible headers
        const visibleHeaders = headers?.filter(header => header.visible);

        // Now, consider column filters and the search query
        return tasks.filter(task => {

            if (!showCompleted && task.completed) {
                return false;
            }

            const searchMatch = visibleHeaders?.some(header => {

                const value = getNestedProperty(task, header.id, header.getLabel, header?.optionType, header?.options, header?.decimals);
                let searchValue;
                if (header.type === 'date' && value !== undefined) {
                    searchValue = moment(value, 'x').format(
                        screenWidth === 'xs' ? 'MMM DD, YY ha' : 'MM/DD/YY ha'
                    );
                } else {
                    searchValue = value !== undefined ? value : "No Value"; // Adjust here
                }

                return (
                    searchValue.toString().toLowerCase().includes(searchQuery.toLowerCase())
                );
            });

            const columnFilterMatch = visibleHeaders?.every(header => {
                const value = getNestedProperty(task, header.id, header.getLabel, header?.optionType, header?.options, header?.decimals);

                let filterValue;
                if (header.type === 'date' && value !== undefined) {
                    filterValue = moment(value, 'x').format(
                        screenWidth === 'xs' ? 'MMM DD, YY ha' : 'MM/DD/YY ha'
                    );
                } else {
                    filterValue = value !== undefined ? value : "No Value"; // Adjust here
                }

                return columnFilters[header.id]
                    ? columnFilters[header.id].includes(filterValue)
                    : true;
            });

            return searchMatch && columnFilterMatch;
        });
    };


    function deleteHeader(header) {
        const newHeaders = companyPreferences?.headers.filter((h) => h.id !== header.id)
        const newPreferences = {
            ...preferences,
            headers: newHeaders
        };
        setCompanyPreferences(newPreferences);
        // Update Firestore
        updateTaskPreferences(userInfo, newPreferences, 'company');
    }



    const filteredTasks = applyFiltersAndSearch(
        tasks,
        searchQuery,
        companyPreferences?.headers,
        preferences?.columnFilters || {},
        preferences?.showCompleted
    );





    const props = {
        activeTab,
        setActiveTab,
        tasks,
        setTasks,
        accounts,
        staff,
        items,
        setItems,
        stocks,
        setStocks,
        loading,
        setLoading,
        currentTask,
        setCurrentTask,
        userInfo,
        account,
        openSnack,
        screenWidth,
        deleteTask,
        updateTask,
        createTask,
        taskDrawer,
        setTaskDrawer,
        duplicateTask,
        updateTasksPosition,
        preferences,
        setPreferences,
        searchQuery,
        setSearchQuery,
        getNestedProperty,
        duplicateTask,
        convertToTemplate,
        deleteSelectedTasks,
        bulkEditor,
        setBulkEditor,
        updateMultipleTasks,
        taskTemplates,
        deleteTaskTemplate,
        columnEditor,
        setColumnEditor,
        deleteHeader,
        calendarEditor,
        setCalendarEditor,
        createCalendar,
        calendars,
        setCalendars




    }


    if (loading) {
        return (
            <Row style={{ height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
                {/* Using the full height of the viewport to center content */}
                <Col span={24} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

                    {/* FontAwesome Spinner */}
                    <FontAwesomeIcon icon={faSpinner} spin size="3x" />

                    <Typography variant="h6" style={{ marginTop: 20 }}>
                        Loading your tasks...
                    </Typography>

                </Col>
            </Row>
        )
    }






    else {
        return (
            <Row style={{}} >
                <Col span={24} style={{}}>
                    <Row align="middle">
                        <Col xs={16} sm={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>
                            <Typography variant="h4" fontWeight={600} fontSize={30} color="black">Site Tasks</Typography>
                        </Col>

                        <Col span={24}>
                            <Divider />
                        </Col>

                        <Col span={24}>
                            <TasksTableHeader {...props}

                            />
                        </Col>


                        {activeTab === 'tasks' && screenWidth !== 'xs' && (
                            <Col span={24}>
                                <TasksTable  {...props}
                                    sortItem={preferences?.sortItem}
                                    setSortItem={(newSortItem) => {
                                        const newPreferences = {
                                            ...preferences,
                                            sortItem: newSortItem
                                        };

                                        setPreferences(newPreferences);
                                        // Update Firestore
                                        updateTaskPreferences(userInfo, newPreferences);

                                    }}
                                    showCompleted={preferences?.showCompleted}
                                    setShowCompleted={(e) => {
                                        const newPreferences = {
                                            ...preferences,
                                            showCompleted: e
                                        };
                                        setPreferences(newPreferences);
                                        // Update Firestore
                                        updateTaskPreferences(userInfo, newPreferences);
                                    }}
                                    selectable={preferences?.selectable}
                                    setSelectable={(e) => {
                                        const newPreferences = {
                                            ...preferences,
                                            selectable: e
                                        };
                                        setPreferences(newPreferences);
                                        // Update Firestore
                                        updateTaskPreferences(userInfo, newPreferences);
                                        //make all tasks unselected
                                        const updatedTasks = tasks.map((task) => {
                                            return (
                                                {
                                                    ...task,
                                                    selected: false
                                                }
                                            )
                                        })
                                        setTasks(updatedTasks)
                                    }}

                                    colorCalendar={preferences?.colorCalendar}
                                    setColorCalendar={(e) => {
                                        const newPreferences = {
                                            ...preferences,
                                            colorCalendar: e
                                        };
                                        setPreferences(newPreferences);
                                        // Update Firestore
                                        updateTaskPreferences(userInfo, newPreferences);
                                    }} 

                                    tasks={filteredTasks}
                                    fullTasks={tasks}
                                    columnFilters={preferences?.columnFilters}
                                    setColumnFilters={(newFilters) => {
                                        const newPreferences = {
                                            ...preferences,
                                            columnFilters: newFilters
                                        };
                                        setPreferences(newPreferences);
                                        // Update Firestore
                                        updateTaskPreferences(userInfo, newPreferences);
                                    }}
                                    headers={companyPreferences?.headers}
                                    setHeaders={(newHeaders) => {
                                        const newPreferences = {
                                            ...preferences,
                                            headers: newHeaders
                                        };
                                        setCompanyPreferences(newPreferences);
                                        // Update Firestore
                                        updateTaskPreferences(userInfo, newPreferences, 'company');
                                    }} />
                            </Col>
                        )}
                        {activeTab === 'tasks' && screenWidth === 'xs' && (
                            <Col span={24}>
                                <MobileTaskTable  {...props}
                                    sortItem={preferences?.sortItem}
                                    setSortItem={(newSortItem) => {
                                        const newPreferences = {
                                            ...preferences,
                                            sortItem: newSortItem
                                        };
                                        setPreferences(newPreferences);
                                        // Update Firestore
                                        updateTaskPreferences(userInfo, newPreferences);

                                    }}
                                    showCompleted={preferences?.showCompleted}
                                    setShowCompleted={(e) => {
                                        const newPreferences = {
                                            ...preferences,
                                            showCompleted: e
                                        };
                                        setPreferences(newPreferences);
                                        // Update Firestore
                                        updateTaskPreferences(userInfo, newPreferences);
                                    }}
                                    headers={companyPreferences?.headers}
                                    tasks={filteredTasks}
                                    fullTasks={tasks}
                                    columnFilters={preferences?.columnFilters}
                                    setColumnFilters={(newFilters) => {
                                        const newPreferences = {
                                            ...preferences,
                                            columnFilters: newFilters
                                        };
                                        setPreferences(newPreferences);
                                        // Update Firestore
                                        updateTaskPreferences(userInfo, newPreferences);
                                    }}

                                    setHeaders={(newHeaders) => {
                                        const newPreferences = {
                                            ...preferences,
                                            headers: newHeaders
                                        };
                                        setCompanyPreferences(newPreferences);
                                        // Update Firestore
                                        updateTaskPreferences(userInfo, newPreferences, 'company');
                                    }}
                                />
                            </Col>
                        )}
                        {activeTab === 'calendar' && (
                            <Col span={24}>
                                <TasksCalendarHome {...props}
                                    sortItem={preferences?.sortItem}
                                    setSortItem={(newSortItem) => {
                                        const newPreferences = {
                                            ...preferences,
                                            sortItem: newSortItem
                                        };

                                        setPreferences(newPreferences);
                                        // Update Firestore
                                        updateTaskPreferences(userInfo, newPreferences);

                                    }}
                                    showCompleted={preferences?.showCompleted}
                                    setShowCompleted={(e) => {
                                        const newPreferences = {
                                            ...preferences,
                                            showCompleted: e
                                        };
                                        setPreferences(newPreferences);
                                        // Update Firestore
                                        updateTaskPreferences(userInfo, newPreferences);
                                    }}
                                    selectable={preferences?.selectable}
                                    setSelectable={(e) => {
                                        const newPreferences = {
                                            ...preferences,
                                            selectable: e
                                        };
                                        setPreferences(newPreferences);
                                        // Update Firestore
                                        updateTaskPreferences(userInfo, newPreferences);
                                        //make all tasks unselected
                                        const updatedTasks = tasks.map((task) => {
                                            return (
                                                {
                                                    ...task,
                                                    selected: false
                                                }
                                            )
                                        })
                                        setTasks(updatedTasks)
                                    }}
                                    colorCalendar={preferences?.colorCalendar}
                                    setColorCalendar={(e) => {
                                        const newPreferences = {
                                            ...preferences,
                                            colorCalendar: e
                                        };
                                        setPreferences(newPreferences);
                                        // Update Firestore
                                        updateTaskPreferences(userInfo, newPreferences);
                                    }}

                                    tasks={filteredTasks}
                                    fullTasks={tasks}
                                    columnFilters={preferences?.columnFilters}
                                    setColumnFilters={(newFilters) => {
                                        const newPreferences = {
                                            ...preferences,
                                            columnFilters: newFilters
                                        };
                                        setPreferences(newPreferences);
                                        // Update Firestore
                                        updateTaskPreferences(userInfo, newPreferences);
                                    }}
                                    setCalendarEditor={(e) => {
                                        console.log(e)
                                        setCalendarEditor(e)
                                    }}
                                    headers={companyPreferences?.headers}
                                    setHeaders={(newHeaders) => {
                                        const newPreferences = {
                                            ...preferences,
                                            headers: newHeaders
                                        };
                                        setCompanyPreferences(newPreferences);
                                        // Update Firestore
                                        updateTaskPreferences(userInfo, newPreferences, 'company');
                                    }} />
                            </Col>
                        )}



                        <TaskDrawer {...props} headers={companyPreferences?.headers}
                            setHeaders={(newHeaders) => {
                                const newPreferences = {
                                    ...companyPreferences,
                                    headers: newHeaders
                                };
                                setCompanyPreferences(newPreferences);
                                // Update Firestore
                                updateTaskPreferences(userInfo, newPreferences, 'company');
                            }}
                            setTaskTemplates={setTaskTemplates}
                            taskTemplates={taskTemplates}

                        />




                    </Row>
                </Col>

            </Row >
        );
    }

};

export default TasksHomeNew;
