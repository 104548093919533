import { Autocomplete, Chip, OutlinedInput, TextField, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';
import moment from 'moment';
import React from 'react';
import DefaultDatePicker from '../../blocks/defaultDatePicker';
import DefaultTimePicker from '../../blocks/defaultTimePicker';

export default class GeneralInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            type: this.props.type,
            item: this.props.item,
            leadStaff: this.props.item.leadStaff,
            loading: true,
            staff: [],
            additionalStaff: this.props.item.additionalStaff,
            preview: this.props.preview
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
        if (this.props.preview !== prevProps.preview) {
            this.setState({ preview: this.props.preview })
        }


    }
    componentDidMount() {
        const { userInfo, item } = this.state;
        if (item.logNumber === undefined) {
            this.getLogNumber()
        }
        this.getStaff()

    }

    getStaff = async () => {
        const { userInfo, item } = this.state;
        const db = getFirestore()
        const queryStaff = query(collection(db, "users",), where('accounts', 'array-contains', userInfo.currentAccount));
        const staff = [];
        const snapStaff = await getDocs(queryStaff);
        snapStaff.forEach((doc) => {
            staff.push({
                id: doc.data().id,
                key: doc.data().id,
                label: `${doc.data().firstName || ""} ${doc.data().lastName || ""}`,
            })


        });

        this.setState({ staff })
    }

    getLogNumber = async () => {
        const { userInfo, item } = this.state;
        const db = getFirestore()
        const queryAccountData = query(collection(db, "clients", userInfo.currentCompany, 'fieldLogs'), where('archived', '==', false), where('account', '==', userInfo.currentAccount));
        const data = [];
        const snap = await getDocs(queryAccountData);
        snap.forEach((doc) => {
            data.push({
                key: doc.data().key,
            })


        });
        item.logNumber = data.length + 1;
        this.setState({ item })

    }


    updateItem = (boo, val) => {
        const { item } = this.state;
        item[boo] = val;

        this.setState({ item })
        this.props.updateEntireItem(item)
    }

    render() {

        const { item, screenWidth, staff, leadStaff, additionalStaff, preview } = this.state;




        return (
            <Row gutter={[screenWidth === 'xs' ? 4 : 18, screenWidth === 'xs' ? 4 : 18]} style={{ paddingTop: 20 }} align="middle">




                <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                    <Typography variant="body1">Log Number<span style={{ color: 'red' }}>*</span></Typography>
                </Col>
                <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 12 : 0 }}>
                    {preview ? <Typography variant="body1">{item.logNumber}</Typography> : <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                        type="number"
                        inputProps={{ min: 0 }}
                        value={item.logNumber}
                        onChange={(e) => {
                            this.updateItem(`logNumber`, e.target.value === "" ? "" : Number(e.target.value))
                        }} />}
                </Col> 

                <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                    <Typography variant="body1">Log Date<span style={{ color: 'red' }}>*</span></Typography>
                </Col>
                <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 12 : 0 }}>
                    {preview ? <Typography variant="body1">{moment(item.date, 'x').format('MM/DD/YYYY')}</Typography> : <DefaultDatePicker updateItem={this.updateItem} date={item.date} />}
                </Col>
                <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                    <Typography variant="body1">Time in<span style={{ color: 'red' }}>*</span></Typography>
                </Col>
                <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 12 : 0 }}>
                    {preview ? <Typography variant="body1">{moment(item.timeIn, 'x').format('h:mm a')}</Typography> : <DefaultTimePicker updateItem={this.updateItem} time={item.timeIn} />}
                </Col>

                <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                    <Typography variant="body1">Lead Staff<span style={{ color: 'red' }}>*</span></Typography>
                </Col>
                <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 12 : 0 }}>
                    {preview ? <Typography variant="body1">{item.leadStaff !== undefined ? item.leadStaff.label : ''}</Typography> : <Autocomplete
                        size="small"

                        id="combo-box-demo"
                        value={leadStaff || ''}
                        disableCloseOnSelect={false}
                        options={staff}
                        renderInput={(params) => <TextField {...params} label="Staff" />}
                        onChange={(a, b) => {
                            this.setState({ leadStaff: b })
                            this.props.updateItem('leadStaff', b)
                        }}

                    />}
                </Col>
                <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                    <Typography variant="body1">Additional Staff</Typography>
                </Col>
                <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                    {!preview ? <Autocomplete
                        size="small"
                        multiple={true}
                        id="combo-box-demo"
                        value={additionalStaff || []}

                        options={staff}
                        filterSelectedOptions
                        renderInput={(params) => <TextField {...params} label="Staff" />}
                        onChange={(a, b) => {
                            this.setState({ additionalStaff: b })
                            this.props.updateItem('additionalStaff', b)
                        }}

                    /> : item.additionalStaff !== undefined ? item.additionalStaff.map((a) => {
                        return (
                            <Chip label={a.label} variant="outlined" />
                        )
                    }) : ''}
                </Col>





            </Row>
        );
    }
} 