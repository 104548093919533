import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { Typography } from '@mui/material';

const Query = ({ selectedItems, startDate, endDate, source, items, codes }) => {
    const [sqlParts, setSqlParts] = useState({
        query: '',
        parameters: '',
        and: '',
        nodes: '',
        date: '',
    });

    useEffect(() => {

        const selectedParameters = Object.values(selectedItems).filter(item => item.type === 'parameter');
        const selectedNodes = Object.values(selectedItems).filter(item => item.type === 'node');

        const query = `SELECT ${source}_data WHERE `;
        const parameters = selectedParameters.length === 0 ? 'parameters are blank ' :
            `parameters are ${selectedParameters.map(p => p.label || p.name).join(", ")} `;
        const and = 'AND ';
        const nodes = selectedNodes.length === 0 ? 'nodes are blank ' : `nodes are ${selectedNodes.map(n => n.label || n.name).join(", ")} `;
        const date = `FOR date BETWEEN ${moment(startDate).format("MM/DD/YYYY h:mm a")} AND ${moment(endDate).format("MM/DD/YYYY h:mm a")}, `;

        // Adding conditions
        const totalConditions = selectedParameters.reduce((acc, p) => acc + (p.conditions ? p.conditions.length : 0), 0);
        const conditionsList = totalConditions === 0 ? '' : 'CONDITIONS: ';


        const conditions = selectedParameters.map((p) => {

            return p.conditions.map((c) => {
                if (c.conditionType === 'value') {

                    return (`${p.label || p.name} ${c.type} ${c.value || ''}`)
                }
                if (c.conditionType === 'tag') {
                    return (`Tags are ${c.tags.join(", ")}`)
                }

            }).join(" AND ")
        }).join(" AND ");

        setSqlParts({ query, parameters, and, nodes, date, conditionsList, conditions });
    }, [selectedItems, startDate, endDate, source, items]);

    return (
        <div style={{ padding: 10 }}>
            <Typography variant="h6" fontWeight={700} fontSize={14} style={{ display: 'inline' }}>
                {Object.keys(sqlParts).map((key) => (
                    <Typography
                        variant="body1"
                        fontWeight={key === 'parameters' || key === 'nodes' || key === 'conditions' ? 800 : 500}
                        fontSize={14}
                        style={{ display: 'inline' }}
                        key={key}
                    >
                        {sqlParts[key]}
                    </Typography>
                ))}
            </Typography>
        </div>
    );
};

export default Query;
