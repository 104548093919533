import { Button, ButtonGroup, CircularProgress, Divider, Typography, OutlinedInput, Autocomplete, TextField, Select, MenuItem } from '@mui/material';
import { Col, Modal, Row } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, } from 'firebase/firestore';

import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import { fullMobile } from '../stations/util';
import 'react-data-grid/lib/styles.css';
import DataGrid, { SelectColumn, textEditor, Column, CheckboxFormatterProps, SortColumn, SortStatusProps } from 'react-data-grid';
import { deleteAlerts, getAlerts, getNodes } from './firestore';
import ProbeParameters from '../../extra/probeParameters.json';
import moment from 'moment';
import './DataGridStyles.css';


var randomColor = require('randomcolor');

const HistoricalAlertsHome = (props) => {
    const { userInfo, screenWidth, openSnack, company, account, } = props;
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);
    const [loadingItems, setLoadingItems] = useState(false);
    const [item, setItem] = useState({})
    const [currentButton, setCurrentButton] = useState('historicalAlerts')
    const [addItemModal, setAdditemModal] = useState(false)
    const [alerts, setAlerts] = useState([])
    const [selectedRows, setSelectedRows] = useState([])
    const [parameters, setParameters] = useState([])
    const [sorts, setSorts] = useState([]);
    const [sortColumns, setSortColumns] = useState([]);


    const db = getFirestore()
    function getComparator(sortColumn) {
        switch (sortColumn) {
            case 'parameter':
            case 'name':

                return (a, b) => {
                    return a[sortColumn].localeCompare(b[sortColumn]);
                };
            case 'value':
            case 'alertLevel':

                return (a, b) => {
                    return a[sortColumn] - b[sortColumn];
                };
            case 'node.name':

                return (a, b) => {
                    return a.node.name.localeCompare(b.node.name);
                };
            case 'node.stationLabel':

                return (a, b) => {
                    return a.node.stationLabel.localeCompare(b.node.stationLabel);
                };
            case 'alertDate':

                return (a, b) => {
                    return moment(a.alertDate).diff(moment(b.alertDate));
                };
        }
    }




    useEffect(() => {
        window.scrollTo(0, 0);
        getInfo()
    }, [])

    async function getInfo() {
        const alertsData = await getAlerts(userInfo.currentCompany, userInfo.currentAccount);
        const nodes = await getNodes(userInfo.currentCompany, userInfo.currentAccount);

        console.log(alertsData);
        console.log(nodes);

        const updatedAlerts = alertsData.map(alert => {
            console.log(alert.node)
            return {
                ...alert,
                node: nodes.find(node => node.id === alert.node)
            };
        });

        console.log(updatedAlerts);
        setAlerts(updatedAlerts);
        const parameters = Object.values(ProbeParameters).map((parameter, index) => {
            return { id: Object.keys(ProbeParameters)[index], label: parameter }
        })
        setParameters(parameters)
        setLoading(false)
    }



    const NodeNameFormatter = ({ row }) => {
        return <>{row.node?.name}</>;
    };

    const StationNameFormatter = ({ row }) => {
        return <>{row.node?.stationLabel}</>;
    };
    const ParameterNameFormatter = ({ row }) => {
        const parameter = parameters.find(parameter => parameter.id === row.parameter)
        //replace -sensor with ''
        const label = parameter?.label?.replace('-sensor', '')
        return <>{label}</>;
    };
    const AlarmSetPointFormatter = ({ row }) => {
        return <>{row.setPoint}</>;
    };
    const AlarmLevelFormatter = ({ row }) => {
        return <>{row.level}</>;
    };
    const handleSort = (sortColumns) => {
        setSorts(sortColumns);
    };


    const sortedRows = useMemo(() => {
        if (sortColumns.length === 0) return alerts;

        return [...alerts].sort((a, b) => {
            for (const sort of sortColumns) {
                const comparator = getComparator(sort.columnKey) || (() => 0);
                const compResult = comparator(a, b);
                if (compResult !== 0) {
                    return sort.direction === 'ASC' ? compResult : -compResult;
                }
            }
            return 0;
        });
    }, [alerts, sortColumns]);



    const columns = [
        SelectColumn,
        { key: 'alertDate', name: 'Date', sortable: true, width: 200 },
        { key: 'name', name: 'Name', sortable: true },
        { key: 'node.name', name: 'Node', formatter: NodeNameFormatter, sortable: true },
        { key: 'node.stationLabel', name: 'Station', formatter: StationNameFormatter, sortable: true },
        { key: 'parameter', name: 'Parameter', formatter: ParameterNameFormatter, sortable: true },
        { key: 'type', name: 'Type', sortable: true, width: 40 },
        { key: 'value', name: 'Alarm Set Point', sortable: true, width: 10 },
        { key: 'alertLevel', name: 'Alarm Level', sortable: true, },
    ];

    const handleRowSelectionChange = (newSelectedRows) => {
        setSelectedRows(newSelectedRows.map((row) => row.id));
    };

    function checkboxFormatter(
        { onChange, ...props }, ref
    ) {
        function handleChange(e) {
            onChange(e.target.checked, (e.nativeEvent).shiftKey);
        }

        return <input type="checkbox" ref={ref} {...props} onChange={handleChange} />;
    }

    function sortStatus({ sortDirection, priority }) {
        return (
            <>
                {sortDirection !== undefined ? (sortDirection === 'ASC' ? '\u2B9D' : '\u2B9F') : null}
                <span style={{
                    color: 'grey',
                    marginLeft: '2px'
                }}>{priority}</span>
            </>
        );
    }

    function rowKeyGetter(row) {
        return row.id;
    }

    const DeleteButton = () => {
        return (
            <Button
                size="small"
                style={{
                    backgroundColor: '#404050',
                    color: 'white',
                    textTransform: 'none',
                }}
                variant='contained'
                onClick={async () => {
                    await deleteAlerts(userInfo.currentCompany, userInfo.currentAccount, selectedRows)
                    await getInfo()
                    openSnack('success', 'Alerts deleted successfully')
                }}
                disabled={selectedRows.length === 0}
            >
                Delete Rows
            </Button>


        )
    }

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </div>
        )
    }
    else {

        return (
            <div style={{ fontFamily: 'Roboto, sans-serif', backgroundColor: '#f6f8fe', minHeight: '100vh' }}>
                <Row  >
                    <Col {...fullMobile(24, 24)} style={{

                        overflow: 'auto',


                    }}>
                        <Row align="middle" >
                            {/*  <Col {...fullMobile(20, 24)}>
                            <Typography variant='h3' fontWeight={500} fontSize={36}>Historical Alerts</Typography>
                            <Typography style={{ paddingTop: 5 }} variant='h5' fontWeight={300} fontSize={18}>All the alerts triggered for this site.</Typography>

                        </Col>
                        <Col {...fullMobile(4, 24)} style={{


                        }}>
                           

                        </Col>

                        <Col {...fullMobile(24, 24)} style={{ paddingTop: 10, paddingBottom: 10 }}>
                            <Divider />
                        </Col>
                        <Col {...fullMobile(24, 24)} style={{ paddingTop: 10, paddingBottom: 10 }}>
                            <ButtonGroup variant="link" aria-label="contained primary button group">
                                {[{ name: 'historicalAlerts', label: 'Alerts History' }].map((item, index) => {
                                    return (
                                        <Button style={{
                                            color: '#404050', textTransform: 'none', fontSize: 14, borderRadius: 0,
                                            //add orange bottom border if current button is users
                                            borderBottom: currentButton === item.name ? '2px solid #f57c00' : 'none'
                                        }}
                                            onClick={() => {
                                                setCurrentButton(item.name)
                                                console.log(item.name)
                                            }}
                                        >
                                            {item.label}
                                        </Button>
                                    )
                                })}

                            </ButtonGroup>

                        </Col>
                        <Col {...fullMobile(24, 24)} >
                            <Divider />
                        </Col> */}
                            <Col {...fullMobile(24, 24)} style={{ textAlign: 'right', paddingTop: 5 }} >
                                <DeleteButton />
                            </Col>

                            <Col {...fullMobile(24, 24)} style={{ padding: 8, }}>

                                <DataGrid
                                    style={{ minHeight: '80vh' }}
                                    columns={columns}
                                    rows={sortedRows} // Update this
                                    rowKeyGetter={rowKeyGetter}
                                    renderers={{ sortStatus, checkboxFormatter }}
                                    selectedRows={new Set(selectedRows)}
                                    onSelectedRowsChange={setSelectedRows}
                                    sorts={sorts}
                                    onSortsChange={handleSort}
                                    sortColumns={sortColumns}
                                    onSortColumnsChange={setSortColumns}
                                    defaultColumnOptions={{
                                        sortable: true,
                                        textwrap: 'wrap',
                                        resizable: true
                                    }}
                                    rowClassGetter={(rowIdx) => (rowIdx % 2 === 1 ? 'data-grid-row-dark' : '')}
                                />


                            </Col>
                        </Row>

                    </Col>
















                </Row >
            </div >
        );
    }
}

export default HistoricalAlertsHome;