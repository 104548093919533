import { Button, Divider, OutlinedInput, Typography } from '@mui/material';
import { Col, Modal, Popconfirm, Row } from 'antd';
import React, { useEffect, useState, useMemo } from 'react';
import { fullMobile } from './util';
import 'react-data-grid/lib/styles.css';
import uuid from 'react-uuid';
import { memo } from 'react';
import moment from 'moment-timezone';
import { DataGrid } from '@mui/x-data-grid';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, orderBy, limit } from 'firebase/firestore';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { SearchOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { faSort } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

var randomColor = require('randomcolor');

export default function ItemsTable(props) {
    const { userInfo, screenWidth, openSnack, company, account, staffRef, usersRef, combinedRef, deleteItem } = props;
    const [loading, setLoading] = useState(true);
    const [rowCount, setRowCount] = useState(0);
    const [data, setData] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRowsKeys, setSelectedRowsKeys] = useState([]);
    const [sortColumns, setSortColumns] = useState([]);
    const [staff, setStaff] = useState([]);
    const [users, setUsers] = useState([]);
    const [searchValue, setSearchValue] = useState('')
    const [nameSorted, setNameSorted] = useState(false)
    const [typeSorted, setTypeSorted] = useState(false)
    const [deleteSorted, setDeleteSorted] = useState(false)
    const [companySorted, setCompanySorted] = useState(false)
    const [itemModal, setItemModal] = useState(false)
    const [item, setItem] = useState({})

    const db = getFirestore();

    useEffect(() => {
        setData(combinedRef)
        setFilterData(combinedRef)
    }, [combinedRef])








    //create 50 rows with the schema above columns
    //create array from 0 to 50

    const EditButton = (props) => {
        let navigate = useNavigate()
        return (
            <Button variant={'outlined'} display="inline"
                size="small"
                style={{
                    width: 50,
                    textTransform: 'none', border: '1px solid #404050', fontSize: 12, color: '#404050', backgroundColor: 'white'
                }}
                onClick={async () => {
                    console.log(props.user)
                    const userRef = doc(db, "users", userInfo.id, 'currentItems', 'currentEquipment');
                    await setDoc(userRef, {
                        key: props.user.key
                    }).then(() => {
                        navigate("/editEquipmentItem");
                    })
                }}
            >
                Edit
            </Button>

        )
    }
    let navigate = useNavigate()

    async function sortData(column) {
        if (column === 'name') {
            filterData.sort((a, b) => {
                if (nameSorted) {
                    return a.name.localeCompare(b.name)
                } else {
                    return b.name.localeCompare(a.name)
                }
            })
            setFilterData([...filterData])
            setNameSorted(!nameSorted)
        }
        if (column === 'delete') {
            filterData.sort((a, b) => {
                //sort based on true or false
                if (deleteSorted) {
                    return a.default - b.default
                }
                else {
                    return b.default - a.default
                }
            })
            console.log(filterData)
            setFilterData([...filterData])
            setDeleteSorted(!deleteSorted)
        }
        if (column === 'type') {
            filterData.sort((a, b) => {
                if (typeSorted) {
                    return a.type.localeCompare(b.type)
                } else {
                    return b.type.localeCompare(a.type)
                }
            })
            setFilterData([...filterData])
            setNameSorted(!typeSorted)
        }


    }



    return (

        <Row gutter={[12, 12]} >
            <Col {...fullMobile(24, 24)}>
                <OutlinedInput
                    style={{ width: 300, backgroundColor: 'white' }}
                    placeholder="Search Equipment"
                    value={searchValue}
                    endAdornment={
                        <SearchOutlined />
                    }
                    onChange={(e) => {
                        //if e.target.value is empty, set filterData to data
                        setSearchValue(e.target.value)
                        if (e.target.value === '') {
                            setFilterData(data)
                        } else {
                            //use regex to filter data
                            let filtered = data.filter((item) => {
                                let regex = new RegExp(e.target.value, 'gi')
                                return item.name.match(regex)
                            })
                            setFilterData(filtered)
                        }

                    }}
                />
            </Col>



            <Col {...fullMobile(24, 24)}
                style={{
                    overflowX: 'scroll',
                }}

            >
                <Table sx={{
                    minWidth: 650,
                    backgroundColor: 'white',
                    //add horizontal scroll bar if screen is too small
                    overflowX: window.innerWidth < 800 ? 'scroll' : 'hidden',

                }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell  >
                                <div style={{ cursor: 'pointer' }}
                                    onClick={() => { sortData('name') }}
                                >
                                    <Typography variant="body2" display="inline" fontWeight={600} fontSize={16} style={{ color: '#404050' }}>
                                        Name <FontAwesomeIcon fontSize={14} icon={faSort} color="#9ec1c9" />
                                    </Typography>
                                </div>
                            </TableCell>
                            <TableCell  >
                                <div style={{ cursor: 'pointer' }}
                                    onClick={() => { sortData('type') }}
                                >
                                    <Typography variant="body2" display="inline" fontWeight={600} fontSize={16} style={{ color: '#404050' }}>
                                        Type <FontAwesomeIcon fontSize={14} icon={faSort} color="#9ec1c9" />
                                    </Typography>
                                </div>
                            </TableCell>


                            <TableCell >
                                <div style={{ cursor: 'pointer' }}

                                >
                                    <Typography variant="body2" display="inline" fontWeight={600} fontSize={16} style={{ color: '#404050' }}>
                                        Delete
                                    </Typography>
                                </div>
                            </TableCell>


                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filterData.map((row, i) => {
                            const backgroundColor = i % 2 === 0 ? '#f8f8f8' : 'white';
                            return (
                                <TableRow
                                    key={row.key}
                                    sx={{
                                        '&:last-child td, &:last-child th': { border: '1px solid rgba(0,0,0,0.2)' },
                                        //onhover change background color
                                        '&:hover': {
                                            backgroundColor: '#40405036'
                                        },
                                        backgroundColor: backgroundColor,
                                        cursor: 'pointer'
                                    }}
                                    onClick={async () => {


                                        const userRef = doc(db, "users", userInfo.id, 'currentItems', 'currentEquipment');
                                        await setDoc(userRef, {
                                            key: row.key
                                        }).then(() => {
                                            navigate("/editEquipmentItem");
                                        })

                                    }}
                                >
                                    <TableCell
                                        style={{ border: '1px solid rgba(0,0,0,0.2)' }}
                                        component="th" scope="row">

                                        <EditButton user={row} />


                                    </TableCell>

                                    <TableCell
                                        //add border to all sides
                                        style={{ border: '1px solid rgba(0,0,0,0.2)' }}

                                    >
                                        <Typography variant="body2" display="inline" fontWeight={600} fontSize={14} style={{ color: '#404050' }}>
                                            {row?.name}
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        //add border to all sides
                                        style={{ border: '1px solid rgba(0,0,0,0.2)' }}

                                    >
                                        <Typography variant="body2" display="inline" fontWeight={400} fontSize={14} style={{ color: '#404050' }}>
                                            {//capitalize first letter of row.type
                                                row?.type.charAt(0).toUpperCase() + row?.type.slice(1)}
                                        </Typography>
                                    </TableCell>


                                    <TableCell
                                        style={{ border: '1px solid rgba(0,0,0,0.2)' }}
                                        onClick={async (e) => {
                                            //stop event from bubbling up to parent
                                            e.stopPropagation()
                                            e.preventDefault()
                                        }}
                                    >

                                        <Popconfirm
                                            title="Are you sure to delete this equipment?"
                                            onConfirm={async () => {
                                                const docRef = doc(db, "clients", userInfo.currentCompany, 'equipment', row.key);

                                                await updateDoc(docRef, {
                                                    archived: true
                                                }).then(async () => {
                                                    openSnack('success', 'Equipment removed.')

                                                    const newData = filterData.filter((f) => f.key !== row.key)
                                                    setFilterData(newData)
                                                    //set data to newData
                                                    const newMasterData = data.filter((f) => f.key !== row.key)
                                                    setData(newMasterData)
                                                    deleteItem(row.key)

                                                }).catch((error) => {
                                                    openSnack('error', error.message)
                                                })





                                            }}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button variant='contained'
                                                display={"inline"}
                                                color='primary'
                                                size='small'
                                                style={{
                                                    textTransform: 'none',
                                                    marginLeft: 15,
                                                    backgroundColor: '#404050',
                                                    border: '1px solid #404050'
                                                }}
                                            >
                                                Delete
                                            </Button>
                                        </Popconfirm>


                                    </TableCell>



                                </TableRow>

                            )
                        })}
                    </TableBody>
                </Table>
            </Col>











        </Row>

    );
}