import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Drawer, Button, List, Divider, ListItem, ListItemButton, ListITemIcon, ListItemText, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { faArrowRightFromBracket, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatabaseHome from './waterQualityDataEditor/waterQualityDatabase/databaseHome';
import { Col, Row } from "antd";

const DataEditorTable = ({ userInfo, attributes, openSnack, widgetKey, chartOptions, setChartOptions, openEditor, tools,
    selectedPoints, setSelectedPoints, chartData, setChartData, chartHeight, updateChartHeight, screenWidth, account, deletedPoints, setDeletedPoints, unselectFromGraph

}) => {
    const [activeTab, setActiveTab] = useState('data');
    const [loading, setLoading] = useState(true);
    const [preferences, setPreferences] = useState({});
    const [companyPreferences, setCompanyPreferences] = useState({});


    useEffect(() => {
        window.scrollTo(0, 0)
        getInfo()
    }, [])




    async function getInfo() {

        setTimeout(() => {
            setLoading(false)
        }, 1500);

    }

    const props = {
        screenWidth,
        userInfo,
        account,
        openSnack,
        preferences,
        setPreferences,
        companyPreferences,
        setCompanyPreferences,
        activeTab,
        setActiveTab,
        selectedPoints,
        setSelectedPoints,
        deletedPoints,
        setDeletedPoints,
        unselectFromGraph


    }


    return (







        <DatabaseHome {...props} />











    );
}

export default DataEditorTable;