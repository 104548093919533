import { collection, getDocs, getDoc, addDoc, updateDoc, doc, getFirestore, deleteDoc, writeBatch, setDoc } from 'firebase/firestore';
import { getStorage, ref, getMetadata } from "firebase/storage";
import uuid from 'react-uuid';
import axios from 'axios';
import moment from 'moment-timezone';

export const fetchData = async (query, userInfo, PPArray, parameters, WPArray, nodes, probes, rainGauges, unitList, codes) => {

  const items = query.dataSource === 'probe' ? 'time, timestamp, locationid, parameterid, value, key, unitid' :
    query.dataSource === 'rain' ? 'time, value, station, key, account, gaugekey' :
      '*';

  const sqlQuery = `SELECT ${items} FROM ${query.dataSource === 'probe' ? 'probe_data' : query.dataSource === 'rain' ? 'gauge_data' : 'node_data_new2'}
WHERE time >= '${moment(query.startDate).format('YYYY-MM-DD HH:mm')}' AND time <= '${moment(query.endDate).format('YYYY-MM-DD HH:mm')}'
${query.parameters.length > 0 ? `AND parameterid IN (${query.parameters.map((p) => `'${p}'`).join(',')})` : ''}
${query.dataSource === 'probe' ? query.probes.length > 0 ? `AND locationid IN (${query.probes.map((p) => `'${p}'`).join(',')})` : '' :
      query.dataSource === 'rain' ? query.rainGauges.length > 0 ? `AND gaugekey IN (${query.rainGauges.map((p) => `'${p}'`).join(',')})` : '' :

        query.nodes.length > 0 ? `AND nodeid IN (${query.nodes.map((p) => `'${p}'`).join(',')})` : ''}
        ${query.dataSource === 'probe' ? '' : query.dataSource === 'rain' ? '' : `AND account = '${userInfo.currentAccount}'`}
ORDER BY time DESC `;



  const proxyUrl = "https://mycorslake.herokuapp.com/";

  return new Promise((resolve, reject) => {
    axios
      .post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
        raw: sqlQuery,
      })
      .then(async (response) => {

        const data = [];
        const parametersList = ['probe', 'node'].includes(query.dataSource) ? [...PPArray, ...parameters] : [...WPArray];
        // ... Rest of the code remains the same ...

        response.data.forEach((row, i) => {



          const parameter = parametersList.find((p) => {

            return p.key === row.parameterid
          });

          const code = codes.find((p) => {

            return p.key === row.code
          });





          const node = query.dataSource === 'probe' ? probes.find((n) => n.id.toString() === row.locationid) :
            query.dataSource === 'rain' ? rainGauges.find((n) => n.key.toString() === row.station) :
              nodes.find((n) => n.id === row.nodeid);

          if (parameter && node) {
            console.log(row)
            const key = row.key === 'null' ? uuid() : row.key === null ? uuid() : row.key === undefined ? uuid() : row.key;

            if ([undefined, null, "null"].includes(row.key) && query.dataSource !== 'rain') {
              const location = query.dataSource === 'probe' ? row.locationid :
                query.dataSource === 'rain' ? row.station :
                  row.nodeid;
              const proxyUrl = "https://mycorslake.herokuapp.com/";
              axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                raw: `UPDATE ${query.dataSource === 'probe' ? 'probe_data' : query.dataSource === 'rain' ? 'gauge_data' : 'node_data_new2'} SET 
                        key = '${key}'
                        WHERE time = '${row.time}'
                        AND parameterid = '${row.parameterid}'
                        AND ${query.dataSource === 'probe' ? 'locationid' : 'nodeid'} = '${location}'
                        ; `
              }).then(async (response) => {
                // handle success

              }).catch((error) => {
                console.log(error)
              })
            }



            if (query.dataSource === 'rain') {
              data.push({
                id: i,
                time: moment(row.time, 'YYYY-MM-DD HH:mm').format('MM/DD/YYYY HH:mm'),
                parameterid: parameter.label || '',
                value: row.value,
                station: node,
                key: key
              })
            } else {
              console.log(row)
              const tagLabels = row.tags ? JSON.parse(row.tags).map((t) => {
                const tag = codes.find((p) => p.key === t);
                return tag?.value || t
              }) : [];

              data.push({
                id: i,
                //random date within the last month
                ...row,
                time: moment(row.time, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YYYY HH:mm'),

                parameterid: parameter.label || '',
                nodeid: query.dataSource === 'probe' ? node?.name : node?.label,
                value: row.value.toFixed(2),
                unitid: unitList.find((u) => u.unitid === row.unitid)?.label || '',
                tags: tagLabels,
                key: key
              })
            }
          }
        })

        resolve(data); // Resolve the Promise with data
      })
      .catch((error) => {
        console.log(error);
        reject(null); // Reject the Promise with null
      });
  });
};



export const createRow = async () => {
  // Create a new row in your SQL database and return it in the required format for ReactGrid
};

export const updateRow = async (updatedRow) => {
  // Update the row in your SQL database and return the updated row in the required format for ReactGrid
};

export const deleteRow = async (rowToDelete) => {
  // Delete the row from your SQL database
};







