import React, { useEffect, useState } from "react";


import { TextField, Typography, Button } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import "react-datepicker/dist/react-datepicker.css";
import { faCheckCircle, faCircleXmark, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col } from "antd";
import { testAPIConnection } from "./componentFunctions";



const ProbeKeySelect = (
    {
        currentStream,
        setCurrentStream,
        headerKey,
        hydroProbes,
        loadingProbes,
        keys,
        openSnack

    }

) => {

    const [value, setValue] = React.useState(null);
    const [testConnection, setTestConnection] = useState(null);

    useEffect(() => {
        console.log('newProbe')
        setValue(currentStream?.[headerKey]);
    }, [currentStream]);


    async function checkConnection(stream) {
        const res = await testAPIConnection(stream);
        if (res === 'success') {
            setTestConnection('success')
            openSnack('success', 'Connection successful')
        }
        else {
            setTestConnection('fail')
            openSnack('error', 'Connection failed')
        }
    }

    if (loadingProbes) return (
        <Typography variant="h6" fontSize={12} style={{ display: 'inline', }}>
            {loadingProbes ? 'Loading Probes...' : ''}
            <FontAwesomeIcon icon={loadingProbes ? faSpinner : faCheckCircle} spin={loadingProbes} style={{ color: loadingProbes ? 'gray' : 'green' }} />
        </Typography>
    )

    else {



        return (
            <>
                <Row align="middle">
                    <Col span={18}>
                        <Autocomplete
                            style={{ width: '70%', display: 'inline', }}
                            fullWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            size="small"
                            notched={false}
                            options={keys?.sort((a, b) => {
                                // Convert to strings and lowercase
                                const aValue = String(a.label).toLowerCase();
                                const bValue = String(b.label).toLowerCase();

                                // Perform the sorting
                                if (aValue < bValue) return -1;
                                if (aValue > bValue) return 1;
                                return 0;
                            })}
                            value={keys?.find((key) => key.id === currentStream?.api_key_id)?.label || ''}
                            hiddenLabel={true}
                            renderInput={(params) => <TextField {...params} placeholder="API Key" variant="outlined"
                                sx={{
                                    '& .MuiInputBase-root': {
                                        color: 'black',
                                        fontWeight: 500,
                                        fontSize: 14,
                                    },

                                }} />}
                            onChange={(e, b) => {
                                setValue(b)
                                setTestConnection('testing')
                                setCurrentStream({
                                    ...currentStream,
                                    api_key: b?.client_secret || null,
                                    api_key_id: b?.id || null,
                                    api_key_client_id: b?.client_id || null,
                                    api_key_client_secret: b?.client_secret || null,
                                })
                                console.log(b)
                                checkConnection({
                                    ...currentStream,
                                    api_key: b?.client_secret || null,
                                    api_key_id: b?.id || null,
                                    api_key_client_id: b?.client_id || null,
                                    api_key_client_secret: b?.client_secret || null,
                                })



                            }
                            }

                        />
                    </Col>
                    <Col span={6} style={{ textAlign: 'center' }}>


                        {testConnection === 'testing' ?
                            <FontAwesomeIcon icon={faSpinner} spin={true} style={{ color: 'gray' }} />
                            :
                            <Button size="small"
                                variant="contained"
                                style={{
                                    display: 'inline',
                                    textTransform: 'none',
                                    backgroundColor: testConnection === 'success' ? 'green' : testConnection === 'fail' ? 'red' : 'white',
                                    border: testConnection === 'success' ? '1px solid green' : testConnection === 'fail' ? '1px solid red' : '1px solid black',
                                    color: testConnection === 'success' ? 'white' : testConnection === 'fail' ? 'white' : 'black',

                                }}
                                onClick={async () => {
                                    console.log(currentStream)
                                    if ([undefined, null, ''].includes(currentStream?.api_key_id)) {
                                        openSnack('error', 'Please select an API key')
                                        return
                                    }
                                    setTestConnection('testing')
                                    checkConnection(currentStream)

                                }}
                            >
                                Connection {testConnection === 'success' && (<FontAwesomeIcon icon={faCheckCircle} style={{ color: 'white' }} />)}
                                {testConnection === 'fail' && (<FontAwesomeIcon icon={faCircleXmark} style={{ color: 'white' }} />)}
                            </Button>
                        }
                    </Col>
                </Row>


            </>


        );
    }
};

export default ProbeKeySelect;
