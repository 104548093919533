import React, { useEffect, useState } from "react";


import { faBox, faBucket, faCheck, faFish, faFlask, faListCheck, faMagnifyingGlass, faPlus, faWheatAlt, faWrench, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, ButtonGroup, Divider, FormControl, Grid, IconButton, Input, InputLabel, MenuItem, Modal, OutlinedInput, Select, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import './taskList.css';
import { makeStyles } from "@mui/styles";
import StockSelection from "./components/stockSelection";
import NodeSelection from "./components/nodeSelection";
import PercentCovered from "./components/percentCovered";
import ApplicationMethod from "./components/applicationMethod";
import Rate from "./components/rate";
import Amount from "./components/amount";
import Notes from "./components/notes";
import MapBox from "./components/map";
import WeatherBox from "./components/weather";
import AdditionalInformation from "./components/metaInformation";

const useStyles = makeStyles({
    underline: {
        "&:before": {
            borderBottom: "none", // Remove default underline
        },
        "&:after": {
            borderBottom: "none", // Remove default underline when focused
        },
        "&:hover:before": {
            borderBottom: "1px solid rgba(0, 0, 0, 0.42)", // Add underline on hover
        },
    },
    commentBox: {
        "&:before": {
            borderBottom: "none", // Remove default underline
        },
        "&:after": {
            borderBottom: "none", // Remove default underline when focused
        },
        "&:hover:before": {
            borderBottom: "none", // Add underline on hover
        },
    },
});

const ItemRowBody = (
    {
        record,
        items,
        setItems,
        screenWidth,
        openSnack,
        userInfo,
        updateRecord,
        stocks,
        setStocks,
        accounts,
        stations,
        setStations,
        accountUsers,
        log,
        account,
        setDisableDrag




    }

) => {
    const [isFocused, setIsFocused] = useState(false);

    const classes = useStyles();

    const props = {
        openSnack: openSnack,
        userInfo: userInfo,
        items: items,
        setItems: setItems,
        screenWidth: screenWidth,
        updateRecord: updateRecord,
        record: record,
        stocks,
        setStocks,
        accounts,
        stations,
        setStations,
        accountUsers,
        log,
        account,
        setDisableDrag
    }



    return (
        <>




            <Col xs={24}  >
                {record?.item === null && (
                    /* create a box that has typography centered vertically and horizontally that says Select Product/Service above */
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                        <Typography variant="h6" component="div" color={'rgba(0,0,0,0.8)'} fontWeight={600} fontSize={18}>
                            Select Product/Service above
                        </Typography>
                    </Box>
                )}
                {record?.item !== null && (
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3} style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography style={{ display: 'inline' }} variant="h6" component="div" color={'rgba(0,0,0,0.8)'} fontWeight={700} fontSize={13}>
                                Item type:
                            </Typography>
                            <Typography style={{ display: 'inline', marginLeft: 10 }} variant="h6" component="div" color={'rgba(0,0,0,0.8)'} fontWeight={400} fontSize={13}>
                                {record?.psType === 'products' ? 'Product' : record?.psType === 'inspection' ? 'Template' : record?.psType === 'services' ? 'Service' : ''}
                            </Typography>

                        </Grid>
                        <Grid item xs={12} md={3} style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography style={{ display: 'inline' }} variant="h6" component="div" color={'rgba(0,0,0,0.8)'} fontWeight={700} fontSize={13}>
                                Item Subtype:
                            </Typography>
                            <Typography style={{ display: 'inline', marginLeft: 10 }} variant="h6" component="div" color={'rgba(0,0,0,0.8)'} fontWeight={400} fontSize={13}>
                                {record?.subType}
                            </Typography>

                        </Grid>
                        <Grid item xs={12} md={5} style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography
                                variant="h6"
                                component="div"
                                color="rgba(0,0,0,0.8)"
                                fontWeight={700}
                                fontSize={13}
                                style={{ marginRight: 10 }} // Add some space between the label and the input
                            >
                                Description:
                            </Typography>
                            <Input
                                maxRows={4}
                                multiline
                                fullWidth
                                placeholder="Enter item description"
                                style={{
                                    fontWeight: 500,
                                    fontSize: 13,
                                    color: "black",
                                }}
                                notched={false}
                                label="none"
                                value={record?.itemDescription}
                                onChange={(e) => {
                                    const newRecord = { ...record };
                                    newRecord.itemDescription = e.target.value;
                                    updateRecord(newRecord);
                                }}
                                classes={{ underline: classes.underline }} // Apply custom styles here
                            />
                        </Grid>


                    </Grid>
                )}
                {record?.item !== null && (<Divider style={{ marginTop: 10, marginBottom: 10 }} />)}
                <Grid container spacing={2}>

                    <Grid item xs={12} md={record?.showMap ? 6 : 12}>
                        <Grid container spacing={1}>

                            {record?.showAdvanced && record?.item !== null && (
                                <Grid item xs={12} md={12}>
                                    <AdditionalInformation {...props} />
                                    <Divider />


                                </Grid>
                            )}
                            {record?.showWeather && record?.item !== null && (
                                <Grid item xs={12} md={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
                                    <WeatherBox {...props} />
                                    <Divider />


                                </Grid>
                            )}


                            {record?.psType === 'products' && (
                                <Grid item xs={12} md={12}>
                                    <Grid container spacing={0}> {/* Reduced spacing */}
                                        <Grid item xs={12} sm={12} style={{ display: 'flex', alignItems: 'center' }}>
                                            <Typography variant="h6" component="div" color={'rgba(0,0,0,0.8)'} fontWeight={800} fontSize={18} style={{ display: 'inline', margin: 0, padding: 0, paddingBottom: 4 }}>
                                                {record?.subType} Information
                                            </Typography>
                                        </Grid>
                                        {record?.subType === 'Chemical' && (
                                            <>
                                                <Grid item xs={12} sm={record?.showMap ? 6 : 3}>
                                                    <Typography
                                                        variant="h6"
                                                        component="div"
                                                        color="rgba(0,0,0,0.8)"
                                                        fontWeight={700}
                                                        fontSize={13}
                                                    // Add some space between the label and the input
                                                    >
                                                        Active-Ingredient:
                                                    </Typography>
                                                    <Input

                                                        placeholder="Ingredient"
                                                        style={{
                                                            fontWeight: 500,
                                                            fontSize: 13,
                                                            color: "black",
                                                        }}
                                                        notched={false}
                                                        label="none"
                                                        value={record?.chemAI}
                                                        onChange={(e) => {
                                                            const newRecord = { ...record };
                                                            newRecord.chemAI = e.target.value;
                                                            updateRecord(newRecord);
                                                        }}
                                                        classes={{ underline: classes.underline }} // Apply custom styles here
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={record?.showMap ? 6 : 3}>
                                                    <Typography
                                                        variant="h6"
                                                        component="div"
                                                        color="rgba(0,0,0,0.8)"
                                                        fontWeight={700}
                                                        fontSize={13}
                                                    // Add some space between the label and the input
                                                    >
                                                        Target Species:
                                                    </Typography>
                                                    <Input

                                                        placeholder="Target species"
                                                        style={{
                                                            fontWeight: 500,
                                                            fontSize: 13,
                                                            color: "black",
                                                        }}
                                                        notched={false}
                                                        label="none"
                                                        value={record?.targetSpecies}
                                                        onChange={(e) => {
                                                            const newRecord = { ...record };
                                                            newRecord.targetSpecies = e.target.value;
                                                            updateRecord(newRecord);
                                                        }}
                                                        classes={{ underline: classes.underline }} // Apply custom styles here
                                                    />
                                                </Grid>
                                                <Grid item xs={6} sm={record?.showMap ? 6 : 3}>
                                                    <Typography
                                                        variant="h6"
                                                        component="div"
                                                        color="rgba(0,0,0,0.8)"
                                                        fontWeight={700}
                                                        fontSize={13}
                                                    // Add some space between the label and the input
                                                    >
                                                        EPA #:
                                                    </Typography>
                                                    <Input

                                                        placeholder="EPA #"
                                                        style={{
                                                            fontWeight: 500,
                                                            fontSize: 13,
                                                            color: "black",
                                                        }}
                                                        notched={false}
                                                        label="none"
                                                        value={record?.chemEPA}
                                                        onChange={(e) => {
                                                            const newRecord = { ...record };
                                                            newRecord.chemEPA = e.target.value;
                                                            updateRecord(newRecord);
                                                        }}
                                                        classes={{ underline: classes.underline }} // Apply custom styles here
                                                    />
                                                </Grid>
                                                <Grid item xs={6} sm={record?.showMap ? 6 : 3}>
                                                    <Typography
                                                        variant="h6"
                                                        component="div"
                                                        color="rgba(0,0,0,0.8)"
                                                        fontWeight={700}
                                                        fontSize={13}
                                                    // Add some space between the label and the input
                                                    >
                                                        State #:
                                                    </Typography>
                                                    <Input

                                                        placeholder="State #"
                                                        style={{
                                                            fontWeight: 500,
                                                            fontSize: 13,
                                                            color: "black",
                                                        }}
                                                        notched={false}
                                                        label="none"
                                                        value={record?.chemState}
                                                        onChange={(e) => {
                                                            const newRecord = { ...record };
                                                            newRecord.chemState = e.target.value;
                                                            updateRecord(newRecord);
                                                        }}
                                                        classes={{ underline: classes.underline }} // Apply custom styles here
                                                    />
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>
                                </Grid>

                            )}
                            {record?.psType === 'products' && (
                                <Grid item xs={12} md={6}>
                                    <StockSelection {...props} />
                                </Grid>
                            )}

                            {record?.psType === 'products' && (
                                <Grid item xs={12} md={6}>
                                    <NodeSelection {...props} />
                                </Grid>
                            )}

                            {record?.psType === 'products' && record?.subType === 'Chemical' && (
                                <Grid item xs={12} md={6}>
                                    <PercentCovered {...props} />
                                </Grid>
                            )}

                            {record?.psType === 'products' && (
                                <Grid item xs={12} md={6}>
                                    <ApplicationMethod {...props} />
                                </Grid>
                            )}

                            {record?.psType === 'products' && (
                                <Grid item xs={12} md={6}>
                                    <Rate {...props} />
                                </Grid>
                            )}


                            {record?.psType === 'products' && (
                                <Grid item xs={12} md={6}>
                                    <Amount {...props} />
                                </Grid>
                            )}

                            {record?.item !== null && (
                                <Grid item xs={12} md={12}>
                                    <Notes {...props} />
                                </Grid>
                            )}

                        </Grid>
                    </Grid>
                    {record?.showMap && (
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={1}>

                                <Grid item xs={12} md={12}>
                                    <MapBox {...props} />
                                </Grid>

                            </Grid>
                        </Grid>
                    )}


                </Grid>

            </Col>




        </>



    );
};

export default ItemRowBody;
