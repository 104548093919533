import React, { useEffect, useState } from "react";
import { Divider, Box, Grid, Typography, IconButton, Badge, Tooltip, Button } from "@mui/material";
import CreateItem from "../components/albumComponents/createItem";
import SearchAlbums from "../components/albumComponents/searchAlbums";
import AlbumEditor from "../components/albumComponents/albumEditor";
import { countAlbumImagesFunction, countAlbumsFunction, createAlbumFunction, deleteAlbumFunction, fetchAlbums, updateAlbumFunction, updateAlbumImageFunction, updateParentIdFunction } from "../utils/albumFunctions";
import AlbumsList from "../components/albumComponents/albumList";
import uuid from "react-uuid";
import AlbumBreadCrumbs from "../components/albumComponents/albumBreadCrumbs";
import { faArrowAltCircleUp, faCloudArrowUp, faSpinner, faTrash, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDropzone } from 'react-dropzone';
import PhotosList from "../components/photosComponents/photoList";
import { createPhotosFunction, fetchPhotos } from "../utils/photosFunctions";
import UploadPhotosModal from "../components/photosComponents/uploadPhotosModal";

import withScrolling from 'react-dnd-scrolling';
import { deleteItemsFunction, fetchParentAlbumFunction, movePhotosUpDirectoryFunction, updateItemsFuction } from "../utils/miscFunctions";
import PhotoEditor from "../components/photosComponents/photoEditor";
const ScrollingComponent = withScrolling('div');


const AlbumsHome = ({ screenWidth, openSnack, userInfo }) => {
    const [searchQuery, setSearchQuery] = useState("");
    const [currentAlbum, setCurrentAlbum] = useState(null);
    const [currentPhoto, setCurrentPhoto] = useState(null);
    const [currentParentAlbum, setCurrentParentAlbum] = useState(null);
    const [albums, setAlbums] = useState([]);
    const [photos, setPhotos] = useState([]);
    const [fecthingAlbums, setFecthingAlbums] = useState(false);
    const [albumBreadCrumbs, setAlbumBreadCrumbs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filteredAlbums, setFilteredAlbums] = useState([]);
    const [filteredPhotos, setFilteredPhotos] = useState([]);
    const [draggingOver, setDraggingOver] = useState(false);
    const [uploadProgress, setUploadProgress] = useState({});
    const [uploadModal, setUploadModal] = useState(false);
    const [dragExisting, setDragExisting] = useState(false);
    const [selectedPhotos, setSelectedPhotos] = useState([]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                window.scrollTo(0, 0)
                setLoading(true);
                let albums = await fetchAlbums(userInfo, currentParentAlbum, setFecthingAlbums, openSnack);

                const photos = await fetchPhotos(userInfo, currentParentAlbum, setFecthingAlbums, openSnack);

                if (albums.length === 0 && currentParentAlbum === null) {
                    await createAlbumFunction({ name: 'Field log photos' }, null, userInfo, setCurrentAlbum, openSnack);
                    await createAlbumFunction({ name: 'Application photos' }, null, userInfo, setCurrentAlbum, openSnack);
                    albums = await fetchAlbums(userInfo, currentParentAlbum, setFecthingAlbums, openSnack);
                }

                // Use Promise.all to wait for all promises to resolve
                const albumsWithCounts = await Promise.all(albums.map(async (album) => {
                    const imageCount = await countAlbumImagesFunction(album, userInfo);
                    const albumCount = await countAlbumsFunction(album, userInfo);
                    // Return a new object with the counts included
                    return {
                        ...album,
                        imageCount,
                        albumCount
                    };
                }));

                albumsWithCounts.sort((a, b) => a.name.localeCompare(b.name));

                photos.sort((a, b) => b.created_date - a.created_date);
                console.log(photos)
                setAlbums(albumsWithCounts);
                setFilteredAlbums(albumsWithCounts);
                setPhotos(photos);
                setFilteredPhotos(photos);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false); // Ensure loading is set to false after operations complete
            }
        };

        setSelectedPhotos([]);
        fetchData();
    }, [currentParentAlbum]); // Only re-run the effect if currentParentAlbum changes



    const updateAlbum = (album) => {
        const newAlbums = albums.map((a) => {
            if (a.album_id === album.album_id) {
                return album;
            }
            return a;
        });
        setAlbums(newAlbums);
        updateAlbumFunction(album, userInfo, setCurrentAlbum, openSnack);
    };

    const updatePhoto = async (photo) => {
        const newPhotos = photos.map((a) => {
            if (a.photo_id === photo.photo_id) {
                return photo;
            }
            return a;
        });
        setPhotos(newPhotos);
        setCurrentPhoto(null)

        await updateItemsFuction('photo', newPhotos, userInfo, openSnack);
    };

    const createAlbum = (album) => {
        const newAlbums = [album, ...albums];
        setAlbums(newAlbums);
        createAlbumFunction(album, currentParentAlbum, userInfo, setCurrentAlbum, openSnack);
    };
    const deleteAlbum = (album) => {
        if (album.album_id === 'field_log_photos' || album.album_id === 'application_photos') {
            openSnack('error', 'Cannot delete this album');
            return;
        }
        const newAlbums = albums.filter((a) => a.album_id !== album.album_id);
        setAlbums(newAlbums);
        deleteAlbumFunction(album, userInfo, setCurrentAlbum, openSnack);
    };

    const updateParentId = (type, id, newParentAlbumId) => {
        let updateEntity;
        let setEntity;

        // Determine the type and set the respective entities and state setters
        if (type === 'album') {
            updateEntity = albums;
            setEntity = setAlbums;
        } else if (type === 'photo') {
            updateEntity = photos;
            setEntity = setPhotos;
        } else {
            // If the type is not recognized, handle the error
            console.error(`Unrecognized type: ${type}`);
            return;
        }

        // Filter out the entity with the given ID
        const newEntities = updateEntity.filter(entity => entity[`${type}_id`] !== id);
        setEntity(newEntities);

        // Call the update function with the correct parameters
        updateParentIdFunction(type, id, newParentAlbumId, userInfo, setCurrentAlbum, openSnack);
    };

    const moveUpDirectory = async (type, item) => {
        const parentAlbum = await fetchParentAlbumFunction(item.parent_album_id, userInfo, openSnack);
        item.parent_album_id = parentAlbum.parent_album_id;
        if (type === 'album') {
            const newAlbums = albums.filter((a) => a.album_id !== item.album_id);
            setAlbums(newAlbums);
            console.log(parentAlbum.parent_album_id)
            setCurrentParentAlbum(parentAlbum.parent_album_id)
            setAlbumBreadCrumbs(albumBreadCrumbs.slice(0, albumBreadCrumbs.length - 1))
            await updateItems('album', [item]);
        }
        else if (type === 'photo') {
            const newPhotos = photos.filter((a) => a.photo_id !== item.photo_id);
            setPhotos(newPhotos);
            setSelectedPhotos((prev) => prev.filter((a) => a !== item.photo_id))
            await updateItems('photo', [item]);
        }
    }

    const movePhotosUpDirectory = async (selectedPhotos) => {

        const stayPhotos = photos.filter((a) => !selectedPhotos.includes(a.photo_id));
        const newPhotos = photos.filter((a) => selectedPhotos.includes(a.photo_id)).map((photo) => {
            return { ...photo, parent_album_id: currentParentAlbum.parent_album_id || null };
        });
        setPhotos(stayPhotos)
        setSelectedPhotos([])
        await updateItemsFuction('photo', newPhotos, userInfo, openSnack);

    }


    const createPhotos = async (newPhotos) => {
        const lastPhoto = await createPhotosFunction(newPhotos, photos, setPhotos, currentParentAlbum, userInfo, openSnack, setUploadProgress)

        await updateAlbumImageFunction(currentParentAlbum, lastPhoto, userInfo, openSnack)

    }


    const updateItems = async (type, newItems) => {
        console.log(type)
        console.log(newItems)
        await updateItemsFuction(type, newItems, userInfo, openSnack);
    };
    const deleteItems = async (type, newItems) => {
        console.log(type)
        console.log(newItems)
        const newPhotos = photos.filter((a) => !newItems.includes(a.photo_id));
        setPhotos(newPhotos);
        setSelectedPhotos([])
        await deleteItemsFunction(type, newItems, userInfo, openSnack);
    };



    const props = {
        screenWidth, openSnack, userInfo, albums,
        setCurrentAlbum,
        setCurrentParentAlbum,
        albumBreadCrumbs,
        setAlbumBreadCrumbs,
        deleteAlbum,
        filteredAlbums,
        setFilteredAlbums,
        updateParentId,
        moveUpDirectory,
        currentPhoto,
        setCurrentPhoto,
        photos,
        setPhotos,
        filteredPhotos,
        setFilteredPhotos,
        uploadProgress,
        setUploadProgress,
        setUploadModal,
        setDragExisting,
        uploadModal,
        setUploadModal,
        selectedPhotos,
        setSelectedPhotos,
        updateItems,
        deleteItems,
        updatePhoto,
        setAlbums,
        screenWidth

    };



    useEffect(() => {
        const filteredAlbums = albums.filter((album) => {
            if (searchQuery === "") {
                return true;
            } else if (album.name.toLowerCase().includes(searchQuery.toLowerCase())) {
                return true;
            } else {
                return false;
            }
        });
        setFilteredAlbums(filteredAlbums);
    }, [searchQuery, albums]);










    if (loading) {
        return (
            <Box py={2}>
                <Grid container justifyContent="center">
                    <Grid item style={{ textAlign: 'center' }}>
                        <FontAwesomeIcon icon={faSpinner} spin size="xl" />
                        <Typography variant="h6" style={{ marginTop: 20 }}>
                            Loading your album and photos...
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        );
    }
    else {

        return (


            <Box >
                <Grid container alignItems="center" >
                    <Grid item xs={6} sm={6} md={3} sx={{ p: 1, mt: 1, }}  >
                        <CreateItem setCurrentAlbum={setCurrentAlbum} screenWidth={screenWidth} setUploadModal={setUploadModal} />
                    </Grid>
                    <Grid item xs={6} sm={3} sx={{ p: 1, mt: 1, }} >
                        <SearchAlbums searchQuery={searchQuery} setSearchQuery={setSearchQuery} screenWidth={screenWidth} />
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ textAlign: "right" }} sx={{ p: 1, mt: 1, }} >
                        <Box>
                            {selectedPhotos.length > 0 && (
                                <IconButton variant="link"
                                    style={{ marginRight: 15 }}
                                    onClick={() => {
                                        deleteItems('photos', selectedPhotos)
                                    }}>
                                    <Tooltip title="Delete">
                                        <Badge badgeContent={selectedPhotos.length} color="error">
                                            <FontAwesomeIcon icon={faTrash} color="gray" size="xl" />
                                        </Badge>
                                    </Tooltip>
                                </IconButton>
                            )}
                            {selectedPhotos.length > 0 && currentParentAlbum !== null && (
                                <IconButton variant="link"
                                    style={{ marginRight: 15 }}
                                    onClick={() => {
                                        movePhotosUpDirectory(selectedPhotos)
                                    }}>
                                    <Tooltip title="Move Up Directory">
                                        <Badge badgeContent={selectedPhotos.length} color="success">
                                            <FontAwesomeIcon icon={faArrowAltCircleUp} color="gray" size="xl" />
                                        </Badge>
                                    </Tooltip>
                                </IconButton>
                            )}

                        </Box>
                    </Grid>
                </Grid>
                <Box pt={1}>
                    <Divider />
                </Box>
                <Grid container>
                    <Grid item xs={12} sx={{ p: 1, mt: 1, }}>
                        <AlbumBreadCrumbs {...props} />
                    </Grid>
                    <Grid item xs={12} sx={{ p: 1, mt: 1, }} >
                        {uploadModal && (<UploadPhotosModal {...props}
                            createPhotos={createPhotos}

                        />)}

                        <DndProvider backend={HTML5Backend}>
                            <ScrollingComponent className="container">
                                <Typography style={{ marginLeft: 10, }} variant="h5" fontSize={16} fontWeight={700}>
                                    Albums
                                </Typography>

                                <AlbumsList {...props} />
                                <Box style={{ backgroundColor: 'white' }}>
                                    <Typography style={{ marginLeft: 10, display: "inline" }} variant="h5" fontSize={16} fontWeight={700} >
                                        Photos
                                    </Typography>
                                    <Button variant="link" style={{ marginLeft: 10, textTransform: 'none', display: "inline" }} onClick={() => {
                                        //select all photos
                                        setSelectedPhotos(photos.map((photo) => photo.photo_id))
                                    }
                                    }>
                                        <Typography variant="body1" fontSize={14} fontWeight={500} >
                                            Select All
                                        </Typography>
                                    </Button>
                                    <Button variant="link" style={{ marginLeft: 10, textTransform: 'none', display: "inline" }} onClick={() => {
                                        //select all photos
                                        setSelectedPhotos([])
                                    }
                                    }>
                                        <Typography variant="body1" fontSize={14} fontWeight={500} >
                                            Deselect All
                                        </Typography>
                                    </Button>


                                </Box>
                                <PhotosList {...props} />
                            </ScrollingComponent>
                        </DndProvider>




                    </Grid>
                </Grid>
                <AlbumEditor
                    currentAlbum={currentAlbum}
                    setCurrentAlbum={setCurrentAlbum}
                    updateAlbum={updateAlbum}
                    createAlbum={createAlbum}
                    openSnack={openSnack}
                    screenWidth={screenWidth}
                />
                <PhotoEditor
                    currentPhoto={currentPhoto}
                    setCurrentPhoto={setCurrentPhoto}
                    updatePhoto={updatePhoto}
                    openSnack={openSnack}
                    screenWidth={screenWidth}
                />
            </Box >


        );
    }
};

export default AlbumsHome;
