import React, { useEffect, useState } from "react";
import {
    Box, Typography, OutlinedInput,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton
} from '@mui/material';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { generateShapeLabel } from "./symbology/utils";

const GraphicsList = ({ record, updateRecord, graphics, setGraphics, openSnack, mapUnits,
    currentGraphic,
    setCurrentGraphic
}) => {
    // Assume this is the data for the working days - you might load this from props, state, or an API

    const [graphicsList, setGraphicsList] = useState([])


    useEffect(() => {
        const fetchLabels = async () => {
            const labelPromises = graphics.filter((graphic) => graphic?.attributes?.type !== "label")
                .map(async (graphic, index) => {
                    let number = graphic.attributes.label;
                    return generateShapeLabel(graphic?.geometry, mapUnits, undefined, number, "logTable");
                });

            const labels = await Promise.all(labelPromises);
            const graphicsWithLabels = graphics.map((graphic, index) => ({
                ...graphic, // spread the original graphic to keep its data
                label: labels[index]  // add the label
            }));

            setGraphicsList(graphicsWithLabels);
        };

        if (graphics.length > 0) {
            fetchLabels();
        }
    }, [graphics, mapUnits]);


    return (
        <>
            {graphicsList.length > 0 && (
                <TableContainer component={Paper} sx={{ maxHeight: 175, overflow: 'auto' }}>
                    <Table sx={{ width: '100%' }} aria-label="simple table" size="small">
                        <TableBody>
                            {graphicsList.map((graphic, index) => (
                                <TableRow
                                    onMouseEnter={() => setCurrentGraphic(graphic?.attributes?.uuid)}
                                    onMouseLeave={() => setCurrentGraphic(null)}
                                    key={graphic?.attributes?.uuid}
                                    style={{
                                        backgroundColor: currentGraphic === graphic?.attributes?.uuid ? 'rgba(0,0,0,0.1)' : index % 2 ? 'rgba(0,0,0,0.05)' : null,
                                    }}
                                    sx={{

                                        borderColor: currentGraphic === graphic?.attributes?.uuid ? 'yellow' : 'rgba(0,0,0,0.1)', // Changes border color to yellow on hover
                                        borderWidth: currentGraphic === graphic?.attributes?.uuid ? 2 : 1, // Adjust border width as needed
                                        borderStyle: 'solid', // Defines a solid border
                                        /* every other row different background */




                                        '&:last-child td, &:last-child th': { border: 0 },

                                    }}
                                >
                                    <TableCell component="th" scope="row" sx={{ padding: '2px', px: 2, }}> {/* Reduced padding */}
                                        <Typography variant="h6" component="div"
                                            fontWeight={currentGraphic === graphic?.attributes?.uuid ? 700 : 500}
                                            fontSize={16}>
                                            {graphic?.label}
                                        </Typography>
                                    </TableCell>

                                    <TableCell align="right" sx={{ padding: '1px' }}>

                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

            )}
        </>
    );
};

export default GraphicsList;
