


import React, { useEffect, useState } from "react";


import { faBox, faBucket, faCheck, faFish, faFlask, faListCheck, faMagnifyingGlass, faPlus, faWheatAlt, faWrench, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Autocomplete, Box, Button, ButtonGroup, Divider, FormControl, Grid, IconButton, Input, InputLabel, MenuItem, Modal, OutlinedInput, Select, TextField, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import { makeStyles } from "@mui/styles";
import StocksSelect from "../../../../../../tasks/components/drawercomponents/stockSelect";



const ApplicationMethod = (
    {
        record,
        items,
        setItems,
        screenWidth,
        openSnack,
        userInfo,
        updateRecord,
        stocks,
        setStocks,
        accounts,
        setDisableDrag





    }

) => {
    const [isFocused, setIsFocused] = useState(false);
    const options = [
        { label: "Boat", key: 'airBoat' },
        { label: "UTV/Gator", key: 'UTV/Gator' },
        { label: "Backpack Sprayer", key: 'backpackSprayer' },
        { label: "Backpack Blower", key: 'backpackBlower' },
        { label: "Hand Broadcast", key: 'handBroadcast' },
        { label: "Spray Rig", key: 'sprayRig' },
    ]


    return (
        <>


            <Typography variant="h6" component="div" color={'rgba(0,0,0,0.8)'} fontWeight={700} fontSize={13}>
                Application Method
            </Typography>


            <Autocomplete
                fullWidth
                size="small"
                style={{ backgroundColor: '#ffffff' }}
                disableCloseOnSelect={false}

                id="combo-box-demo"
                value={options.find(x => x.key === record?.applicationMethod)?.label}


                options={options?.sort((a, b) => {
                    // Convert to strings and lowercase
                    const aValue = String(a.label).toLowerCase();
                    const bValue = String(b.label).toLowerCase();

                    // Perform the sorting
                    if (aValue < bValue) return -1;
                    if (aValue > bValue) return 1;
                    return 0;
                })}



                renderInput={(params) => {
                    return (
                        <TextField {...params} placeholder="Application" variant={'outlined'}
                            onClick={(e) => {
                                console.log('CLICKED')
                                e.stopPropagation();
                                e.preventDefault();
                                setDisableDrag(true);
                            }}
                            sx={{
                                '& .MuiInputBase-root': {
                                    color: 'black',
                                    fontWeight: 500,
                                    fontSize: 14,
                                    backgroundColor: '#ffffff',

                                },

                            }} />
                    )
                }}


                onClose={(e) => {
                    setDisableDrag(false);

                }}

                onChange={(a, d) => {
                    updateRecord({ ...record, applicationMethod: d?.key || null })
                }}

            />







        </>



    );
};

export default ApplicationMethod;
