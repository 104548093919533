

import {
    Badge,
    IconButton,
    List,
    ListItem,
    Popover,
    Tooltip,
    Typography
} from '@mui/material';
import React from 'react';
import { faCheckCircle, faCircle, faCopy, faEdit, faEye, faEyeSlash, faSquare, faSquareCheck, } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsSpin, faDatabase, faDownload, faEllipsisH, faTrash } from '@fortawesome/free-solid-svg-icons';
import * as Papa from 'papaparse';
import moment from 'moment-timezone';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import axios, * as others from 'axios';



const MoreTableOptions = ({ sortedLogs, visibleHeaders, deleteSelectedLogs, selectedLogs, getNestedProperty, ProbeParameters,


}) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const downloadCSV = () => {
        console.log(sortedLogs);
        console.log(visibleHeaders);

        const csvData = sortedLogs.map(log => {
            const row = {};
            visibleHeaders.forEach(header => {
                let nestValue = getNestedProperty(log, header.id, header.getLabel, header?.optionType, header?.options, header?.decimals);

                let value = ProbeParameters[nestValue] || nestValue;
                if (header.type === 'date' && value) {
                    value = moment(value, 'x').format('MM/DD/YY h:mm a');
                }
                row[header.headerName] = value;  // Using header.headerName as key
            });
            return row;
        });

        console.log(csvData);

        const csv = Papa.unparse(csvData);
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'logs.csv';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    };





    return (
        <div style={{
            display: 'inline',

        }}>
            <IconButton onClick={handleClick}

            >
                <Tooltip title="More Options" placement="bottom">
                    <FontAwesomeIcon icon={faEllipsisH}
                        size="md" style={{ color: '#3f51b5', cursor: 'pointer' }} />
                </Tooltip>



            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}

                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <List sx={{
                    width: 300,
                    padding: 0,

                }}>
                   {/*  <ListItem
                        key={'createTemplate'}
                        dense
                        button
                        disabled={selectedLogs.length === 0 ? true : false}
                        onClick={() => {
                            deleteSelectedLogs(selectedLogs)
                        }}
                        sx={{
                            borderBottom: '1px solid #e0e0e0',
                            paddingTop: 1,
                            paddingBottom: 1,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrash} style={{ color: '#3f51b5', marginRight: 10 }} />
                        <Typography variant="body1" color="black" fontSize={16} fontWeight={500}>Delete Selected Logs</Typography>
                    </ListItem> */}

                    <ListItem
                        key={'bulkEdit'}
                        dense
                        button
                        onClick={() => {
                            downloadCSV();
                            handleClose();
                        }}
                        sx={{
                            borderBottom: '1px solid #e0e0e0',
                            paddingTop: 1,
                            paddingBottom: 1,
                        }}
                    >
                        <FontAwesomeIcon icon={faDownload} style={{ color: '#3f51b5', marginRight: 10 }} />
                        <Typography variant="body1" color="black" fontSize={16} fontWeight={500}>Download CSV</Typography>
                    </ListItem>







                </List>




            </Popover>
        </div>
    );
};

export default MoreTableOptions;
