import { Backdrop, Box, Button, CardMedia, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { DndProvider, useDrag, useDrop, useDragLayer } from 'react-dnd';
import { deletePhotosFunction } from "../../utils/photosFunctions";
import PhotoCover from "./photoCover";
import PhotoLoadingCover from "../../pages/photoLoadingCover";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import LightboxComponent from "./lightBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleUp } from "@fortawesome/free-solid-svg-icons";






const PhotosList = ({
    screenWidth,
    openSnack,
    userInfo,
    photos,
    filteredPhotos,
    setCurrentPhoto,
    setFilteredPhotos,
    updatePhotoParentId,
    uploadProgress,
    setPhotos,
    albumBreadCrumbs,
    setAlbumBreadCrumbs,
    moveUpDirectory,
    selectedPhotos,
    setSelectedPhotos,
    updatePhoto,
    currentPhoto,
}) => {



    const [lastIndex, setLastIndex] = useState(0);
    const [isLightboxOpen, setIsLightboxOpen] = React.useState(false);
    const [currentImageIndex, setCurrentImageIndex] = React.useState(0);


    const props = {
        screenWidth, openSnack, userInfo, photos, setCurrentPhoto, currentPhoto, albumBreadCrumbs, setAlbumBreadCrumbs,
        moveUpDirectory, selectedPhotos, setSelectedPhotos, selectedPhotos, setSelectedPhotos, lastIndex, setLastIndex
    };

    const handlePhotoClick = photo => {
        const index = photos.findIndex(p => p.photo_id === photo.photo_id);
        setCurrentImageIndex(index);
        setIsLightboxOpen(true);
    };

    // Function to scroll to the top of the page
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };
    // Photo component that is draggable
    const DraggablePhoto = ({ photo, deletePhotos, index, selectMultiplePhotos, ...props }) => {
        const [{ isDragging }, dragRef] = useDrag({
            type: 'photo',
            item: { id: photo.photo_id, type: 'photo' },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),

            }),
            end: (item, monitor) => {
                console.log(`end drag: ${photo.photo_id}`)
                const dropResult = monitor.getDropResult();


            },
        });






        return (
            <Grid ref={dragRef} key={photo.photo_id} item xs={6} sm={4} md={3}>
                <PhotoCover ref={dragRef}
                    {...props}
                    photo={photo}
                    index={index}
                    handlePhotoClick={() => handlePhotoClick(photo)}
                    selected={selectedPhotos.includes(photo.photo_id)}
                    deletePhotos={deletePhotos}
                    state={isDragging ? 'dragging' : 'idle'}
                    imageSrc={photo.medium}
                    title={photo.name}
                    selectMultiplePhotos={selectMultiplePhotos}
                    // Style based on isDragging
                    style={{ opacity: isDragging ? 0.8 : 1, cursor: 'move', border: isDragging ? '6px solid red' : 'none' }}
                />
            </Grid>

        );
    };



    if (photos.length === 0) {
        return (
            <Box p={1}>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={12} md={12} sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '20vh'

                    }}>
                        <Typography variant="h3" fontSize={18} fontWeight={700} align="center">
                            No Photos found
                            Click the upload button to add photos
                        </Typography>
                    </Grid>
                </Grid>
            </Box >
        )
    }
    else {

        return (

            <Box p={1} >

                {isLightboxOpen && (
                    <LightboxComponent
                        isLightboxOpen={isLightboxOpen}
                        setIsLightboxOpen={setIsLightboxOpen}
                        image={photos[currentImageIndex].large}
                        alt={photos[currentImageIndex].name}
                        screenWidth={screenWidth}
                        nextImage={() => setCurrentImageIndex((currentImageIndex + 1) % photos.length)}
                        prevImage={() => setCurrentImageIndex((currentImageIndex + photos.length - 1) % photos.length)}
                    />

                )}


                <Grid container alignItems="center" spacing={2}>
                    {photos.map((photo, index) => (


                        photo.isLoading ? (
                            <Grid key={photo.photo_id} item xs={6} sm={4} md={3}>
                                <PhotoLoadingCover
                                    photo={photo}
                                    title={photo?.name}
                                    progress={Number(`${Math.round(Math.min(
                                        uploadProgress[photo.photo_id]?.small || 0,
                                        uploadProgress[photo.photo_id]?.medium || 0,
                                        uploadProgress[photo.photo_id]?.large || 0
                                    ))}`)}
                                />

                            </Grid>
                        ) : (



                            <DraggablePhoto key={photo.photo_id} photo={photo}
                                {...props} imageSrc={photo.medium} title={photo?.name}
                                selectedPhotos={selectedPhotos} index={index}
                                setSelectedPhotos={setSelectedPhotos}
                                handlePhotoClick={handlePhotoClick}
                                selectMultiplePhotos={(rangeStart, rangeEnd) => {
                                    console.log(rangeStart)
                                    console.log(rangeEnd)
                                    const range = [...Array(rangeEnd - rangeStart + 1).keys()].map(i => i + rangeStart);
                                    console.log(range)
                                    const newSelectedPhotos = range.map(i => photos[i].photo_id)
                                    console.log(newSelectedPhotos)
                                    setSelectedPhotos(prev => [...Array.from(new Set([...prev, ...newSelectedPhotos]))])
                                }}
                                deletePhotos={(photosToDelete) => {
                                    console.log(photosToDelete)
                                    deletePhotosFunction(photosToDelete, userInfo, openSnack, photos, setPhotos)

                                }}
                            />


                        )


                    ))}
                </Grid>

                <Button
                    onClick={scrollToTop}
                    style={{
                        position: 'fixed',
                        bottom: 20,
                        right: 20,
                        zIndex: 1000 // Ensure it's above other elements
                    }}
                >
                    <FontAwesomeIcon icon={faArrowCircleUp} size="2x" style={{ color: '#1bff0f' }} />
                </Button>
            </Box>

        );
    }
};

export default PhotosList;
