import Sketch from "@arcgis/core/widgets/Sketch";
import SketchViewModel from "@arcgis/core/widgets/Sketch/SketchViewModel";
import { createLabel, deleteLabel, updateLabel } from '../symbology/labels';
import { createPolygonSymbol, createPolylineSymbol, createPointSymbol } from "../symbology/renderer";
import React, { useEffect } from "react";

import uuid from 'react-uuid';

export const SketchComponent = (view, graphicsLayer, showLabels, labelAttributes, layerColor, mapUnits, userInfo, record) => {

    


    let sketchVM = new SketchViewModel({
        layer: graphicsLayer,
        polygonSymbol: createPolygonSymbol(layerColor),  // Updated to use layerColor
        polylineSymbol: createPolylineSymbol(layerColor),  // Updated to use layerColor
        pointSymbol: createPointSymbol(layerColor),  // Updated to use layerColor
        view: view
    });



    // Inside useEffect, after initializing sketchVM
    sketchVM.on("create", async (event) => {
        if (event.state === "complete") {
            // Perform actions after the graphic is created
            let graphic = event.graphic;
            if (!graphic.attributes) {
                graphic.attributes = {};
            }
            const graphics = graphicsLayer.graphics.toArray(); // Ensure it's an array to use .filter
            // Filter out labels
            const items = graphics.filter(g => g.attributes && g.attributes.type === 'item');
            /* get the max labelNumber for the items */
            const maxLabelNumber = Math.max(...items.map(item => Number(item?.attributes?.label)), 0);
            console.log(maxLabelNumber)
            graphic.attributes.label = `${maxLabelNumber + 1}`;
            graphic.attributes.type = 'item';
            graphic.attributes.uuid = uuid();
            await view.whenLayerView(graphicsLayer)
            createLabel(graphicsLayer, event.graphic, showLabels, labelAttributes, layerColor, mapUnits, userInfo, record);

        }
    });

    sketchVM.on("delete", (event) => {
        if (event.type === "delete") {
            // Perform actions after the graphic is deleted
            console.log('delete1')
            deleteLabel(graphicsLayer, event.graphics, labelAttributes, layerColor, showLabels, mapUnits, userInfo, record);

        }
    });

    sketchVM.on("update", (event) => {
        if (event?.toolEventInfo?.type === "move-stop") {
            console.log('update label')
            console.log(event)
            // Perform actions after the graphic is updated
            updateLabel(graphicsLayer, event.graphics, showLabels, labelAttributes, layerColor, mapUnits, userInfo, record);

        }
    });



    // Initialize Sketch widget with the graphics layer
    const sketch = new Sketch({
        layer: graphicsLayer,  // The layer to which the sketch is bound
        view: view,
        id: 'sketchItem',
        creationMode: "update",  // Other properties as needed for your Sketch
        viewModel: sketchVM
    });

    return sketch;
}