import React, { useState, useRef, useEffect } from "react";
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Typography,
    Button,
    Container,
    Grid
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faComment, faFileCsv, faSpinner } from "@fortawesome/free-solid-svg-icons";
import CSVUploadSteps from "./csvUploadSteps";
import UploadComponent from "./uploadComponent";
import { backStepFunc, firstStepFunc, getTemplates, nextStepFunc } from "./functions";
import UploadHeader from "./uploadHeader";
import MapData from "./mapData";
import DataTable from "./dataTable";
import { unitsArray } from "../../../extra/unitsArray";
import { queryCodes, queryParameters, queryStations } from "../../../firebase/config";
import { Col, Row } from "antd";



const UploadCSVHome = ({ screenWidth, openSnack, userInfo, uploading, setUploading, returnHome, uploadType }) => {
    const [templates, setTemplates] = useState([]);
    const [template, setTemplate] = useState(null);
    const [item, setItem] = useState(null);
    const [data, setData] = useState(null);
    const [headers, setHeaders] = useState([]);
    const [codes, setCodes] = useState([]);
    const [parameters, setParameters] = useState([]);
    const [stations, setStations] = useState([]);
    const [meta, setMeta] = useState({});
    const [loading, setLoading] = useState(false);




    useEffect(() => {
        window.scrollTo(0, 0)
        getInfo(userInfo);
    }, []);

    async function getInfo(userInfo) {
       
        const parameters = await queryParameters(userInfo)
        parameters.forEach((p, i) => {
            p.parameterType = 'Custom Paramter';
        })
        setParameters(parameters)
        const stations = await queryStations(userInfo, ['grab', 'both', 'sensor'])

        setStations(stations)
        const codes = await queryCodes(userInfo)

        setCodes(codes)

        const templates = await getTemplates(userInfo)
        setTemplates(templates)

    }



    const [steps, setSteps] = useState([
        {
            step: "upload",
            stepStatus: "Current",
            stepTitle: "Upload your CSV file",
            stepDescription: "Please upload your CSV file into the system"
        },
        {
            step: "map",
            stepStatus: "Waiting",
            stepTitle: "Map Data",
            stepDescription: "Please map your data to the appropriate fields"
        },
        {
            step: "review",
            stepStatus: "Waiting",
            stepTitle: "Review Data",
            stepDescription: "Please review your data"
        },
        {
            step: "confirm",
            stepStatus: "Waiting",
            stepTitle: "Confirm",
            stepDescription: "Please confirm your data"
        }
    ]);







    const nextStep = () => nextStepFunc(steps, setSteps);
    const backStep = () => backStepFunc(steps, setSteps);

    const currentStep = steps.find(step => step.stepStatus === "Current");

    function checkErrors(data) {
       
        // List of mapped fields to be checked
        const fieldsToCheck = ['parameters', 'node'];

        // Corresponding lists of valid options
        const validOptions = {
            'parameters': parameters,
            'node': stations,
            'units': unitsArray,
        };

        // Count the errors
        let errorsCount = fieldsToCheck.reduce((count, field) => {
            const header = headers.find((header) => header.mappedField === field);
            const options = validOptions[field];
            const check = options.find((option) => option.label === data?.[header?.name]);

            return count + (check ? 0 : 1);
        }, 0);

        // Check if 'value' is a number
        const valueHeader = headers.find((header) => header.mappedField === 'value');
        const value = data?.[valueHeader?.name];
        if (isNaN(Number(value))) {
            errorsCount++;
        }
        if (value === null || value === undefined || value === '') {
            errorsCount++;
        }

        // Check if 'date' is a valid date
        const dateHeader = headers.find((header) => header.mappedField === 'date');
        const date = data?.[dateHeader?.name];
        if (isNaN(new Date(date).getTime())) {
            errorsCount++;
        }

        let warningsCount = 0;

        const codeHeader = headers.find(header => header.mappedField === 'codes' && header.included === true);
        const codeValue = data?.[codeHeader?.name];
        if (Array.isArray(codeValue) && codeValue.length === 1 && codeValue[0] !== '') {
            codeValue.forEach(code => {
                const check = codes.find((option) => option.label === code);
                if (!check) {
                    warningsCount++;
                }
            })
        }

        const unitsHeader = headers.find(header => header.mappedField === 'units');
        const unitsValue = data?.[unitsHeader?.name];

        const unitExists = unitsArray.some(unit => unit.label === unitsValue);
        if (!unitsValue || !unitExists) {
            warningsCount++;

        }







        return { errorsCount, warningsCount };


    }

    const props = {
        templates,
        setTemplates,
        template,
        setTemplate,
        steps,
        setSteps,
        currentStep,
        item,
        setItem,
        nextStep,
        backStep,
        screenWidth,
        openSnack,
        userInfo,
        uploading,
        setUploading,
        codes,
        setCodes,
        parameters,
        setParameters,
        stations,
        setStations,
        unitsArray,
        setData,
        data,
        meta,
        setMeta,
        setHeaders,
        headers,
        returnHome,
        uploadType,
        checkErrors,



    };


    useEffect(() => {
        //when steps goes back to first step, clear data
        if (currentStep.step === "finished") {
            setData(null)
            setHeaders([])
            setMeta({})
            setTemplate(null)
            setSteps(
                [
                    {
                        step: "upload",
                        stepStatus: "Current",
                        stepTitle: "Upload your CSV file",
                        stepDescription: "Please upload your CSV file into the system"
                    },
                    {
                        step: "map",
                        stepStatus: "Waiting",
                        stepTitle: "Map Data",
                        stepDescription: "Please map your data to the appropriate fields"
                    },
                    {
                        step: "review",
                        stepStatus: "Waiting",
                        stepTitle: "Review Data",
                        stepDescription: "Please review your data"
                    },
                    {
                        step: "confirm",
                        stepStatus: "Waiting",
                        stepTitle: "Confirm",
                        stepDescription: "Please confirm your data"
                    }
                ]
            )
        }
    }, [currentStep])


    if (loading) {
        return (
            <Container sx={{
                p: 0,
                m: 0
            }}>
                <Box sx={{}}>

                    <Grid container spacing={3} mt={4}>
                        <FontAwesomeIcon icon={faSpinner} spin size="3x" color="#3f51b5" />
                    </Grid>
                </Box>
            </Container>
        )
    }
    else {





        return (

            <Row sx={{}}>
                <Col span={24}>
                    <CSVUploadSteps steps={steps} />
                </Col>
                <Col span={24} >
                    <Box sx={{ px: 1 }}>
                        <UploadHeader {...props} />
                    </Box>
                </Col>



                <Col span={24} >
                    {currentStep.step === "upload" && (<UploadComponent {...props} />)}
                    {currentStep.step === "map" && (<MapData {...props} />)}
                    {currentStep.step === "review" && (<DataTable {...props} />)}
                </Col>

                <Grid container spacing={3} mt={4}>

                </Grid>
            </Row>

        );
    }

};

export default UploadCSVHome;
