import { faBuilding, faComment, faComments, faFile, faFileLines, faQuestionCircle, faUser } from '@fortawesome/free-regular-svg-icons';
import { faAdd, faArrowRight, faBars, faChartLine, faDatabase, faGauge, faGear, faMoneyBill, faPeopleGroup, faPlus, faQuestion, faSignOut, faSort, faTable, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popper, Typography, Button, Box, Divider, ClickAwayListener, Paper, OutlinedInput, List, Menu, ListItem, Avatar, IconButton } from '@mui/material';
import { Col, Popconfirm, Row } from 'antd';
import { getAuth, signOut } from 'firebase/auth';
import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './styles.css';
import { collection, doc, getFirestore, onSnapshot, query, updateDoc, where, getDoc } from 'firebase/firestore';
import { fullMobile } from '../../../waterQuality/stations/util';

import axios, * as others from 'axios';
import uuid from 'react-uuid';


export default function AddToMaster({ openSnack, userRef, companyRef, accountRef, type, widget, layout }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [footerAnchorEl, setFooterAnchorEl] = useState(null);
    const [projectSearch, setProjectSearch] = useState('');
    const projectSearchRef = useRef(null);
    const projectRef = useRef(null);
    const footerRef = useRef(null);
    const [accounts, setAccounts] = useState([]);
    const [filterData, setFilterData] = useState([]);

    const [account, setAccount] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    const [company, setCompany] = useState(null);

    const [isOpen, setIsOpen] = useState(false);
    const [isFooterOpen, setIsFooterOpen] = useState(false);


    useEffect(() => {
        if (companyRef !== undefined) {
            setCompany(companyRef)

        }
    }, [companyRef])
    useEffect(() => {
        if (accountRef !== undefined) {

        }
    }, [accountRef])
    useEffect(() => {
        if (userRef !== undefined) {
            setUserInfo(userRef)

        }
    }, [userRef])

    useEffect(() => {

    }, [])




    const handleClick = (event) => {
        setAnchorEl(anchorEl === null ? event.currentTarget : null);
    };
    const handleClose = (type) => {
        setAnchorEl(null);
    };

    useEffect(() => {
        //set anchorEl to projectRef
        setAnchorEl(projectRef.current)
        setFooterAnchorEl(footerRef.current)
    }, [])


    const getWidgets = (docSnap) => {
        if (docSnap.data().widgets1 === "" || docSnap.data().widgets1 === undefined) {
            return JSON.parse(docSnap.data().widgets);
        }
        return JSON.parse(docSnap.data().widgets + docSnap.data().widgets1);
    };

    async function getLayout(widget, l) {
        console.log(widget)
        console.log(l)
        //replace widget.key and layout.i with new uuid 
        const newKey = uuid();
        const newl = { ...l, i: newKey };
        const newWidget = { ...widget, key: newKey };
        const account = userInfo.currentAccount;
        const db = getFirestore();
        const docRefCheck = doc(db, "clients", userInfo.currentCompany, 'accounts', account, 'dashboard', 'newDash');
        const docSnapNew = await getDoc(docRefCheck);

        if (docSnapNew.exists()) {

            const widgets = getWidgets(docSnapNew);
            const layout = JSON.parse(docSnapNew.data().layout);
            console.log(widgets)
            console.log(layout)

            const highestLayoutItem = layout.length > 0 ? layout.reduce((prev, current) => (prev.y + prev.h > current.y + current.h ? prev : current)) : { y: 0, h: 0 };

            // Add the new layout item to the bottom of the layout by setting its y value to the highest y value found plus the height of the highest item
            const newLayout = [
                ...layout,
                {
                    ...newl,
                    x: 0,
                    y: highestLayoutItem.y + highestLayoutItem.h,
                },
            ];
            const newWidgets = [...widgets, newWidget];
            updateWidgets(newWidgets);
            updateLayout(newLayout);

            setIsOpen(false)


        } else {
            const docRef = doc(db, "clients", userInfo.currentCompany, 'accounts', account, 'dashboard', 'dashboard');

            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const layout = JSON.parse(docSnap.data().layout);
                const widgets = getWidgets(docSnap);
                console.log(widgets)
                console.log(layout)



                const highestLayoutItem = layout.length > 0 ? layout.reduce((prev, current) => (prev.y + prev.h > current.y + current.h ? prev : current)) : { y: 0, h: 0 };

                // Add the new layout item to the bottom of the layout by setting its y value to the highest y value found plus the height of the highest item
                const newLayout = [
                    ...layout,
                    {
                        ...newl,
                        x: 0,
                        y: highestLayoutItem.y + highestLayoutItem.h,
                    },
                ];
                const newWidgets = [...widgets, newWidget];
                updateWidgets(newWidgets);
                updateLayout(newLayout);

                setIsOpen(false)



            } else {

            }
        }
    };

    const clearChartData = (chartData) => {
        return chartData.map((d) => ({ ...d, data: [] }));
    };

    const prepareWidgetsForUpdate = (widgets) => {
        return widgets.map((w) => {
            const newChartData = clearChartData(w.attributes.chartData);
            const newAttributes = { ...w.attributes, chartData: newChartData };
            return { ...w, attributes: newAttributes };
        });
    };

    const splitWidgetsString = (widgetsString) => {
        const halfLength = Math.floor(widgetsString.length / 2);
        const firstHalf = widgetsString.substring(0, halfLength);
        const secondHalf = widgetsString.substring(halfLength, widgetsString.length);
        return { firstHalf, secondHalf };
    };

    const updateWidgetsOnServer = async (somevalue, lessthan, userInfo) => {
        const proxyUrl = "https://mycorslake.herokuapp.com/";

        try {
            await axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/dashboardUpdates/updateWidgets', {
                widgets: somevalue,
                widgets1: lessthan,
                userInfo,
            });
        } catch (err) {
            console.log(err);
        }
    };

    async function updateWidgets(widgets) {
        const newWidgets = prepareWidgetsForUpdate(widgets);
        const widgetsString = JSON.stringify(newWidgets);
        const { firstHalf, secondHalf } = splitWidgetsString(widgetsString);

        await updateWidgetsOnServer(firstHalf, secondHalf, userInfo);

        openSnack('success', 'Added to master dashboard!');
    };


    const updateLayoutOnServer = async (layoutString, userInfo) => {
        const proxyUrl = "https://mycorslake.herokuapp.com/";

        try {
            await axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/dashboardUpdates/updateLayout', {
                layout: layoutString,
                userInfo,
            });
        } catch (err) {
            console.log(err);
        }
    };

    async function updateLayout(layout) {
        const layoutString = JSON.stringify(layout);

        await updateLayoutOnServer(layoutString, userInfo);

        openSnack('success', 'Added to master dashboard!');
    };





    return (
        <Row  style={{ display: 'inline' }} >


            <Col  {...fullMobile(24)} style={{ position: 'relative', textAlign: 'left', marginTop: 10, marginBottom: 10, }}

            >

                <IconButton
                    ref={projectRef}
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <FontAwesomeIcon fontSize={18}
                        icon={faUpload} color="#9ec1c9" />
                </IconButton>


                {isOpen && (
                    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
                        <Popper
                            id={'popper'}
                            open={isOpen}
                            anchorEl={anchorEl}

                            style={{ zIndex: 1000, width: 240 }}
                        >
                            <Box sx={{
                                p: 0, bgcolor: 'background.paper',
                                borderRadius: 2,
                                border: '1px solid #e0e0e0',
                                boxShadow: 1,

                            }}>
                                <Row style={{}}>
                                    <Col span={24} style={{ textAlign: 'center', padding: 8, borderRadius: '2px 2px 0px 0px', backgroundColor: 'white', height: 80 }}>
                                        <Popconfirm
                                            //make popup width only 200
                                            placement="top"
                                            overlayStyle={{ width: 350 }}
                                            title="Please note, this will add the widget to the master dashboard. 
                                            These widgets will not be directly tied together. It will be added to the bottom of the dashboard. 
                                            Are you sure you want to continue?
                                            "
                                            okText="Yes"
                                            cancelText="Close"
                                            onConfirm={async () => {
                                                getLayout(widget, layout)
                                            }}
                                            onCancel={async () => {
                                                setIsOpen(false)

                                            }}


                                        >

                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                style={{ width: '100%', height: '100%', borderRadius: 0, textTransform: 'none', backgroundColor: '#404050' }}
                                                onClick={() => {

                                                }}
                                            >
                                                <FontAwesomeIcon fontSize={18}
                                                    icon={faUpload} color="white" />
                                                <Typography style={{ marginLeft: 10, color: 'white' }} variant="body1">Add to Master Dashboard</Typography>
                                            </Button>
                                        </Popconfirm >



                                    </Col>

                                </Row>
                            </Box>
                        </Popper>
                    </ClickAwayListener>
                )}

            </Col>


        </Row >
    )




}
