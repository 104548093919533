import React, { useState } from "react";


import { Grid, IconButton, Input, Tooltip } from '@mui/material';
import { makeStyles } from "@mui/styles";
import NodeDepthSelect from "./nodeDepthSelect";
import NodeTypeSelect from "./nodeTypeSelect";
import NodeActivelyReporting from "./nodeActivelyReporting";
import NodeSensorSelect from "./nodeSensorSelect";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AlertsIcon from "./alertsIcon";
import OffsetsButton from "./offsetsButton";


const useStyles = makeStyles({
    // ... your existing styles

    underline: {
        "&:before": {
            borderBottom: "none", // Remove default underline
        },
        "&:after": {
            borderBottom: "none", // Remove default underline when focused
        },
        "&:hover:before": {
            borderBottom: "1px solid rgba(0, 0, 0, 0.42)", // Add underline on hover
        },
    },
    // ... other styles
});

const NodeDetails = (
    {
        currentItem,
        setCurrentItem,
        currentChild,
        setCurrentChild,
        userInfo,
        setStep


    }

) => {


    const classes = useStyles();
    // Function to close the drawer

    const props = {
        currentItem,
        setCurrentItem,
        currentChild,
        setCurrentChild,
        userInfo,
        setStep

    };


    return (



        <div
            style={{



                width: '100%',

                overflowY: 'auto',
                overflowX: 'hidden',
            }}
        >
            <Grid container alignItems="center" style={{ width: '100%' }}>
                <Grid item xs={12} md={8} style={{ display: 'flex', alignItems: 'center', paddingBottom: 10 }}>
                    <Input
                        maxRows={4}
                        multiline
                        fullWidth
                        placeholder="Enter Task Name"
                        style={{
                            fontWeight: 600,
                            fontSize: 16,
                            color: "black",
                        }}
                        notched={false}
                        label="none"
                        value={currentChild?.name}
                        onChange={(e) => setCurrentChild({ ...currentChild, name: e.target.value })}
                        classes={{ underline: classes.underline }} // Apply custom styles here
                    />
                </Grid>
                <Grid item xs={12} md={2} style={{ display: 'flex', alignItems: 'center', paddingBottom: 10 }}>
                    <AlertsIcon {...props} />
                </Grid>
                <Grid item xs={12} md={2} style={{ display: 'flex', alignItems: 'center', paddingBottom: 10 }}>
                    <OffsetsButton {...props} />
                </Grid>
                <Grid item xs={12} md={12} >
                    <NodeDepthSelect  {...props} />
                </Grid>
                <Grid item xs={12} md={12} >
                    <NodeTypeSelect  {...props} />
                </Grid>
                <Grid item xs={12} md={12} >
                    <NodeActivelyReporting  {...props} />
                </Grid>
                <Grid item xs={12} md={12} >
                    <NodeSensorSelect  {...props} />
                </Grid>




            </Grid>
        </div>



    );
};

export default NodeDetails;
