import { Button, Checkbox, Divider, OutlinedInput, Typography, Select, MenuItem, CircularProgress, Breadcrumbs, FormGroup, FormControlLabel, Autocomplete, Chip, TextField, IconButton, Fab } from '@mui/material';
import { Col, Popconfirm, Row } from 'antd';
import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { fullMobile } from './util';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, } from 'firebase/firestore';

import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import uuid from 'react-uuid';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';

import DatePicker from "react-datepicker";
import queryString from 'query-string';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { queryAccounts, queryAllItems, queryPSItems, queryStocks } from '../firebase/config';
import { DeleteForever } from '@mui/icons-material';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import './tableStyles.css'
import { faTablets, faToolbox } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
var randomColor = require('randomcolor');


const getMuiTheme = () =>
    createTheme({
        components: {
            MUIDataTable: {
                styleOverrides: {
                    root: {
                        padding: '8px',
                        boxShadow: 'none'
                    }
                }
            },
            MuiTableRow: {
                styleOverrides: {
                    height: '40px',
                    //onhover change background to red


                    root: {
                        height: '40px',
                        zIndex: 100,
                        cursor: 'pointer',
                        fontSize: '10px',
                        '&:nth-child(even)': {
                            backgroundColor: '#eaeaeade',

                        },




                    },
                    rowHeight: '40px'



                }
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        fontSize: '12px',
                        padding: '2px',

                    }
                }
            }

        }
    });


export default function PSReports(props) {
    const { userInfo, screenWidth, openSnack, company, account, updateAccount, updateUserInfo } = props;
    const [data, setData] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [items, setItems] = useState([]);
    const [editingRow, setEditingRow] = useState(null);
    const [accounts, setAccounts] = useState([]);
    const [stocks, setStocks] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    useEffect(() => {
        getInfo();
        window.scrollTo(0, 0);
    }, [])

    async function getInfo() {
        const accounts = await queryAccounts(userInfo);
        setAccounts(accounts);

        const stocks = await queryStocks(userInfo);
        setStocks(stocks)
        const items = await queryPSItems(userInfo); 

        setItems(items);

        const data = await queryAllItems(userInfo);

        const newData = data.map(item => {
            //find account name
            const account = accounts.find(account => account.key === item.account) ?
                accounts.find(account => account.key === item.account) :
                accounts.find(account => account.key === item.accountKey) || { accountName: '' }


            const row = items.find(row => row.key === item.item) ?
                items.find(row => row.key === item.item) :
                items.find(row => row.key === item.product) || { name: '' }

            const supplyStockKey = item.supplyStock !== undefined ? item?.supplyStock?.key : '';
            const supplyStock = stocks.find(stock => stock.key === supplyStockKey) || { name: '' };


            return {
                ...item,
                accountLabel: account ? account.accountName : '',
                county: account ? account?.countyName : '',
                itemLabel: row ? row.name : '',
                supplyStockLabel: supplyStock ? supplyStock.name : '',
                amount: item.quantity ? item?.quantity : item?.amount ? item?.amount : '',
                units: item?.units ? item.units.label : '',
                type: item.product !== undefined ? 'Chemical' : item.psType === 'services' ? 'Service' : item.psType === 'products' ? 'Product' : '',
                time:
                    item.time ? moment(item.time, 'YYYY-MM-DD HH:mm a').format('MM-DD-YYYY h:mm a') :
                        item.startDate ? moment(item.startDate, 'x').format('MM-DD-YYYY h:mm a') :
                            moment(item.timestamp, 'X').format('MM-DD-YYYY h:mm a')
            }
        })

        //sort by time
        newData.sort((a, b) => {
            return moment(b.time, 'MM-DD-YYYY h:mm a').unix() - moment(a.time, 'MM-DD-YYYY h:mm a').unix()
        }
        )

        console.log(newData)

        setData(newData);
        setFilterData(newData);


    }

    const db = getFirestore();
    let navigate = useNavigate();
    const columns = [
        {
            name: 'time',
            label: 'time',
            options: {
                filter: false,

                customBodyRender: (a, tableMeta, updateValue) => {

                    return (
                        <Typography variant="body1" fontWeight={500} fontSize={14}>
                            {a}
                        </Typography>
                    )
                }


            }
        },
        {
            name: 'itemLabel',
            label: 'Name',
            options: {
                filterType: 'multiselect',
                filter: true,

                customBodyRender: (a, tableMeta, updateValue) => {

                    return (
                        <Typography variant="body1" fontWeight={400} fontSize={14}>
                            {a}
                        </Typography>
                    )
                }


            }
        },

        {
            name: 'accountLabel',
            label: 'Site',
            options: {
                filterType: 'multiselect',
                filter: true,

                customBodyRender: (a, tableMeta, updateValue) => {

                    return (
                        <Typography variant="body1" fontWeight={400} fontSize={14}>
                            {a}
                        </Typography>
                    )
                }


            }
        },
        {
            name: 'county',
            label: 'County',
            options: {
                filterType: 'multiselect',
                filter: true,

                customBodyRender: (a, tableMeta, updateValue) => {

                    return (
                        <Typography variant="body1" fontWeight={400} fontSize={14}>
                            {a}
                        </Typography>
                    )
                }


            }
        },
        {
            name: 'supplyStockLabel',
            label: 'Stock',
            options: {
                filter: false,

                customBodyRender: (a, tableMeta, updateValue) => {

                    return (
                        <Typography variant="body1" fontWeight={400} fontSize={14}>
                            {a}
                        </Typography>
                    )
                }


            }
        },
        {
            name: 'amount',
            label: 'Amount',
            options: {
                filter: false,



                customBodyRender: (a, tableMeta, updateValue) => {



                    return (
                        <Typography variant="body1" fontWeight={400} fontSize={14}>
                            {a}
                        </Typography>
                    )
                }


            }
        },
        {
            name: 'units',
            label: 'Units',
            options: {
                filter: false,



                customBodyRender: (a, tableMeta, updateValue) => {



                    return (
                        <Typography variant="body1" fontWeight={400} fontSize={14}>
                            {a}
                        </Typography>
                    )
                }


            }
        },
        {
            name: 'type',
            label: 'Type',
            options: {
                filterType: 'multiselect',
                filter: true,
                customBodyRender: (type, tableMeta, updateValue) => {



                    if (type === '')
                        return null;
                    else

                        return (
                            <Chip label={type} display="inline" color={type === 'Service' ? 'primary' : type === 'Chemical' ? 'error' : 'success'
                            } variant="outlined"
                                style={{ cursor: 'pointer', padding: 5, marginLeft: 10 }} fontWeight={400} fontSize={18}
                                onClick={async () => {

                                }
                                }
                            />
                        )
                }


            }
        },



        {
            name: 'key',
            label: 'Edit',
            options: {
                filter: false,
                viewColumns: false,
                customBodyRender: (a, tableMeta, updateValue) => {
                    return (
                        <IconButton style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="small" itemKey={a}
                            onClick={async () => {
                                const itemRef = doc(db, "users", userInfo.id, 'currentItems', 'currentPS');
                                await setDoc(itemRef, {
                                    key: a
                                }).then(() => {
                                    const type = data.find(item => item.key === a).psType;
                                    navigate('/psItem')
                                })
                            }}>
                            <EditIcon />
                        </IconButton>
                    )
                }
            },
        },

    ]

 

    return (
        <Row style={{ paddingTop: screenWidth === 'xs' ? 15 : 80, paddingLeft: screenWidth === 'xs' ? 15 : 30, paddingRight: screenWidth === 'xs' ? 15 : 30 }}>
            <Col {...fullMobile(20, 20)} style={{ paddingTop: 10 }}>
                <Typography variant='h2' fontWeight={500} fontSize={28}>Reports: Products and Services</Typography>


            </Col>

            <Col {...fullMobile(24, 24)} style={{ paddingTop: 10 }}>
                <Row gutter={[12, 12]} align="middle">
                    <Col >
                        <Typography variant='h2' fontWeight={500} fontSize={12}>Start Date</Typography>
                        <DatePicker portalId="root-portal"
                            showTimeSelect
                            timeFormat="h:mm a"
                            timeIntervals={1}
                            timeCaption="time"
                            dateFormat="MM/dd/yyyy h:mm a"
                            selected={
                                [undefined, '', "Invalid date", null].includes(startDate) ? new Date() : new Date(moment(startDate, 'x'))}
                            onChange={(date) => {
                                setStartDate(moment(date).format('x'))


                            }} />

                    </Col>

                    <Col >
                        <Typography variant='h2' fontWeight={500} fontSize={12}>End Date</Typography>
                        <DatePicker portalId="root-portal"
                            showTimeSelect
                            timeFormat="h:mm a"
                            timeIntervals={1}
                            timeCaption="time"
                            dateFormat="MM/dd/yyyy h:mm a"
                            selected={
                                [undefined, '', "Invalid date", null].includes(endDate) ? new Date() : new Date(moment(endDate, 'x'))}
                            onChange={(date) => {
                                setEndDate(moment(date).format('x'))


                            }} />
                    </Col>

                    <Col >
                        <Button
                            size="small"
                            variant='contained'
                            style={{
                                backgroundColor: '#404050',
                                textTransform: 'none',
                            }}
                            onClick={async () => {
                                const filterData = data.filter(item => {
                                    const itemDate = moment(item.time, 'MM-DD-YYYY h:mm a');
                                    const startDateMoment = moment(startDate, 'x');
                                    const endDateMoment = moment(endDate, 'x');
                                    return itemDate.isBetween(startDateMoment, endDateMoment, 'day', '[]')


                                })
                                console.log(filterData)
                                setFilterData(filterData)
                            }}
                        >
                            <Typography variant='body1' fontWeight={500} >Filter</Typography>
                        </Button>
                    </Col>

                </Row>
            </Col>
            <Col {...fullMobile(24, 24)} >
                <Typography variant='h2' fontWeight={500} fontSize={12}>Total: {filterData.length}</Typography>
            </Col>
            <Col {...fullMobile(24, 24)} style={{ paddingTop: 10 }}>
                <Divider />
            </Col>
            <Col {...fullMobile(24, 24)} style={{ paddingTop: 10 }}>
                <ThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        title={''}
                        data={filterData}
                        columns={columns}


                        options={{

                            //csv download



                            rowsPerPage: 50,
                            selectableRows: false,
                            rowsPerPageOptions: [50, 100, 200, 500, 1000],
                            async onRowClick(a, b) {
                                const db = getFirestore();
                                const item = data.find(item => item.key === a[a.length - 1].props.itemKey);



                                if (item.item !== undefined) {
                                    const userRef = doc(db, "users", userInfo.id, 'currentItems', 'currentFieldLogNew');
                                    const accountRef = doc(db, "users", userInfo.id,);
                                    await setDoc(userRef, {
                                        key: item.logkey || item.logKey
                                    }).then(() => {
                                        if (userInfo.currentAccount !== item.account || item.accountKey) {
                                            updateAccount(userInfo.currentCompany, item.accountKey || item.account)
                                            updateUserInfo('currentAccount', item.accountKey || item.account)
                                            const accountRef = doc(db, "users", userInfo.id,);
                                            updateDoc(accountRef, {
                                                currentAccount: item.accountKey || item.account
                                            })

                                        }
                                    })
                                        .then(() => {
                                            navigate("/newFieldLogHome-reports");
                                        })

                                }
                                if (item.product !== undefined) {
                                    const userRef = doc(db, "users", userInfo.id, 'currentItems', 'currentFieldDoc');
                                    await setDoc(userRef, {
                                        key: item.logkey !== undefined ? item.logkey : item.logKey !== undefined ? item.logKey :
                                            item.applicationKey !== undefined ?
                                                item.applicationKey : item.applicationkey !== undefined ? item.applicationkey : 'none'
                                    }).then(() => {
                                        if (userInfo.currentAccount !== item.account || item.accountKey) {
                                            updateAccount(userInfo.currentCompany, item.accountKey || item.account)
                                            updateUserInfo('currentAccount', item.accountKey || item.account)
                                            const accountRef = doc(db, "users", userInfo.id,);
                                            updateDoc(accountRef, {
                                                currentAccount: item.accountKey || item.account
                                            })


                                        }
                                    }).then(() => {
                                        navigate("/newApplicationHome-reports");
                                    })



                                }




                            }
                        }}

                    />
                </ThemeProvider>
            </Col>


        </Row>
    )



}