import React, { useState } from "react";


import { Box, Button, ButtonGroup, Grid, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import '../../dataTable.css';
import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { faCircleCheck, faCloudArrowUp, faUpload } from "@fortawesome/free-solid-svg-icons";
import {
    useCSVReader,
    lightenDarkenColor,
    formatFileSize,
} from 'react-papaparse';

import './uploadStyle.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import UploadTemplates from "./templates";
import CSVNextStep from "./csvNextStep";
import uuid from "react-uuid";


const GREY_DIM = '#686868';

const styles = {

    zoneHover: {
        borderColor: GREY_DIM,
        backgroundColor: 'white'
    },

};



const UploadComponent = (props) => {
    const {
        templates,
        setTemplate,
        template,
        steps,
        setSteps,
        currentStep,
        item,
        setItem,
        nextStep,
        data,
        setData,
        headers,
        setHeaders,
        meta,
        setMeta,
        checkErrors,
        openSnack
    } = props;
    const { CSVReader } = useCSVReader();
    const [dragging, setDragging] = useState(false);

    const handleDragOver = () => {
        setDragging(true);
    };

    const handleDragLeave = () => {
        setDragging(false);
    };






    return (
        <Box sx={{ px: '5vw' }}>
            <Grid container spacing={3}



                sx={{
                    mt: 0,
                    px: 0,
                    pt: 2
                }} >



                <Grid item xs={12} sm={12} md={7} key={'upload'}
                    style={{


                    }}
                    sx={{
                        p: 2
                    }}


                >



                    <CSVReader
                        onUploadAccepted={(data, meta) => {
                            try {
                                /* make sure if there is any data that is [''] it is filtered out from  */

                                const filterTableData = data?.data.filter((item) => item.length > 1);

                                const headers = filterTableData[0].map((item) => ({
                                    name: item,
                                    included: true,
                                    mappedField: null,
                                    archived: false,
                                    id: uuid(),
                                    minWidth: 180,
                                    width: item.length * 20 > 160 ? item.length * 20 : 160,
                                }));

                                console.log(filterTableData);

                                const tableData = filterTableData.slice(1).reduce((acc, d) => {
                                    // Check if the row has more data elements than headers
                                    if (d.length > headers.length) {
                                        openSnack('error', 'More data in columns than headers');

                                    }

                                    // Filter out any rows that are just an empty array ['']
                                    if (!d.every(item => item === '')) {
                                        // Create a new object and populate it with the data
                                        const obj = d.reduce((acc, item, index) => {
                                            acc[headers[index].name] = item;
                                            return acc;
                                        }, {});

                                        // Add the unique identifier and selected properties
                                        obj.id = uuid();
                                        obj.selected = false;

                                        acc.push(obj);
                                    }
                                    return acc;
                                }, []);

                                console.log(tableData);
                                console.log(headers)
                                setHeaders(headers);
                                setData(tableData);
                                setMeta(meta)
                                handleDragLeave();
                            }
                            catch (error) {
                                console.log(error)
                                openSnack('error', 'Error uploading file');
                            }

                        }}

                        onDragOver={(event) => {
                            event.preventDefault();
                            handleDragOver();
                        }}
                        onDragLeave={(event) => {
                            event.preventDefault();
                            handleDragLeave();
                        }}
                        onDrop={(event) => {
                            event.preventDefault();
                            handleDragLeave();
                        }}
                    >
                        {({
                            getRootProps,
                        }) => (
                            <>
                                <div
                                    {...getRootProps()}
                                    style={Object.assign(
                                        {},
                                        styles.zone,
                                        dragging && {
                                            border: '4px solid #3d51b5',
                                            backgroundColor: '#dbdbdb',
                                            color: '#3d51b5',

                                        }
                                    )}

                                    className={`asana-upload-container${dragging ? " dragging" : ""}`}
                                >

                                    <FontAwesomeIcon icon={data ? faCircleCheck : faCloudArrowUp}

                                        size="4x" style={{
                                            display: 'block',
                                            color: data ? 'green' : dragging ? '#3d51b5' : '#7A7A7A',
                                            paddingBottom: 20
                                        }} />
                                    <Typography variant="h6" fontWeight={700} fontSize={18} gutterBottom component="div"
                                        style={{
                                            color: dragging ? '#3d51b5' : '#7A7A7A',
                                        }}>
                                        {data ? "File successfully uploaded" : "Drag and drop your CSV file here"}

                                    </Typography>
                                    <Typography variant="caption" fontSize={14} gutterBottom component="div"
                                        style={{
                                            color: dragging ? '#3d51b5' : '#7A7A7A',
                                        }}>
                                        (this only takes csv files as inputs)
                                    </Typography>



                                </div>
                            </>
                        )}
                    </CSVReader>





                </Grid>
                <Grid item xs={12} sm={12} md={5}>
                    <UploadTemplates setTemplate={setTemplate} meta={meta} setMeta={setMeta} template={template} templates={templates} setItem={setItem} item={item} />
                    <CSVNextStep data={data} setData={setData}
                        steps={steps} setSteps={setSteps} item={item} nextStep={nextStep} setItem={setItem} meta={meta} setMeta={setMeta} template={template} setHeaders={setHeaders} />
                </Grid>


            </Grid >
        </Box>

    );
};

export default UploadComponent;
