import { Button, Divider, OutlinedInput, Typography } from '@mui/material';
import { Col, Modal, Popconfirm, Row } from 'antd';
import React, { useEffect, useState, useMemo } from 'react';
import { fullMobile } from './util';
import 'react-data-grid/lib/styles.css';
import uuid from 'react-uuid';
import { memo } from 'react';
import moment from 'moment-timezone';
import { DataGrid } from '@mui/x-data-grid';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, orderBy, limit } from 'firebase/firestore';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { SearchOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { faSort } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

var randomColor = require('randomcolor');

export default function ItemsTable(props) {
    const { userInfo, screenWidth, openSnack, company, account, staffRef, usersRef, combinedRef, deleteItem } = props;
    const [loading, setLoading] = useState(true);
    const [rowCount, setRowCount] = useState(0);
    const [data, setData] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRowsKeys, setSelectedRowsKeys] = useState([]);
    const [sortColumns, setSortColumns] = useState([]);
    const [staff, setStaff] = useState([]);
    const [users, setUsers] = useState([]);
    const [searchValue, setSearchValue] = useState('')
    const [nameSorted, setNameSorted] = useState(false)
    const [deleteSorted, setDeleteSorted] = useState(false)
    const [companySorted, setCompanySorted] = useState(false)
    const [itemModal, setItemModal] = useState(false)
    const [item, setItem] = useState({})

    const db = getFirestore();

    useEffect(() => {
        setData(combinedRef)
        setFilterData(combinedRef)
    }, [combinedRef])








    //create 50 rows with the schema above columns
    //create array from 0 to 50

    const EditButton = (props) => {
        let navigate = useNavigate()
        return (
            <Button variant={'outlined'} display="inline"
                size="small"
                style={{
                    width: 50,
                    textTransform: 'none', border: '1px solid #404050', fontSize: 12, color: '#404050', backgroundColor: 'white'
                }}
                onClick={async () => {
                    setItem(props.user)
                    setItemModal(true)
                }}
            >
                Edit
            </Button>

        )
    }


    async function sortData(column) {
        if (column === 'name') {
            filterData.sort((a, b) => {
                if (nameSorted) {
                    return a.name.localeCompare(b.name)
                } else {
                    return b.name.localeCompare(a.name)
                }
            })
            setFilterData([...filterData])
            setNameSorted(!nameSorted)
        }
        if (column === 'delete') {
            filterData.sort((a, b) => {
                //sort based on true or false
                if (deleteSorted) {
                    return a.default - b.default
                }
                else {
                    return b.default - a.default
                }
            })
            console.log(filterData)
            setFilterData([...filterData])
            setDeleteSorted(!deleteSorted)
        }


    }



    return (

        <Row gutter={[12, 12]} >
            <Col {...fullMobile(24, 24)}>
                <OutlinedInput
                    style={{ width: 300, backgroundColor: 'white' }}
                    placeholder="Search Parameters"
                    value={searchValue}
                    endAdornment={
                        <SearchOutlined />
                    }
                    onChange={(e) => {
                        //if e.target.value is empty, set filterData to data
                        setSearchValue(e.target.value)
                        if (e.target.value === '') {
                            setFilterData(data)
                        } else {
                            //use regex to filter data
                            let filtered = data.filter((item) => {
                                let regex = new RegExp(e.target.value, 'gi')
                                return item.name.match(regex)
                            })
                            setFilterData(filtered)
                        }

                    }}
                />
            </Col>



            <Col {...fullMobile(24, 24)}
                style={{
                    overflowX: 'scroll',
                }}

            >
                <Table sx={{
                    minWidth: 650,
                    backgroundColor: 'white',
                    //add horizontal scroll bar if screen is too small
                    overflowX: window.innerWidth < 800 ? 'scroll' : 'hidden',

                }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell  >
                                <div style={{ cursor: 'pointer' }}
                                    onClick={() => { sortData('name') }}
                                >
                                    <Typography variant="body2" display="inline" fontWeight={600} fontSize={16} style={{ color: '#404050' }}>
                                        Name <FontAwesomeIcon fontSize={14} icon={faSort} color="#9ec1c9" />
                                    </Typography>
                                </div>
                            </TableCell>


                            <TableCell >
                                <div style={{ cursor: 'pointer' }}
                                    onClick={() => { sortData('delete') }}
                                >
                                    <Typography variant="body2" display="inline" fontWeight={600} fontSize={16} style={{ color: '#404050' }}>
                                        Delete
                                    </Typography>
                                </div>
                            </TableCell>


                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filterData.map((row, i) => {
                            const backgroundColor = i % 2 === 0 ? '#f8f8f8' : 'white';
                            return (
                                <TableRow
                                    key={row.key}
                                    sx={{
                                        '&:last-child td, &:last-child th': { border: '1px solid rgba(0,0,0,0.2)' },
                                        //onhover change background color
                                        '&:hover': {
                                            backgroundColor: '#40405036'
                                        },
                                        backgroundColor: backgroundColor,
                                        cursor: 'pointer'
                                    }}
                                    onClick={async () => {

                                        setItem(row)
                                        setItemModal(true)

                                    }}
                                >
                                    <TableCell
                                        style={{ border: '1px solid rgba(0,0,0,0.2)' }}
                                        component="th" scope="row">

                                        <EditButton user={row} />


                                    </TableCell>
                                    <TableCell
                                        //add border to all sides
                                        style={{ border: '1px solid rgba(0,0,0,0.2)' }}

                                    >
                                        <Typography variant="body2" display="inline" fontWeight={600} fontSize={14} style={{ color: '#404050' }}>
                                            {row?.name}
                                        </Typography>
                                    </TableCell>


                                    <TableCell
                                        style={{ border: '1px solid rgba(0,0,0,0.2)' }}
                                        onClick={async (e) => {
                                            //stop event from bubbling up to parent
                                            e.stopPropagation()
                                            e.preventDefault()
                                        }}
                                    >

                                        <Popconfirm
                                            title="Are you sure to delete this parameter?"
                                            onConfirm={async () => {
                                                const docRef = doc(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'parameters', row.key);

                                                await updateDoc(docRef, {
                                                    archived: true
                                                }).then(async () => {
                                                    openSnack('success', 'Parameter removed.')

                                                    const newData = filterData.filter((f) => f.key !== row.key)
                                                    setFilterData(newData)
                                                    //set data to newData
                                                    const newMasterData = data.filter((f) => f.key !== row.key)
                                                    setData(newMasterData)
                                                    deleteItem(row.key)

                                                }).catch((error) => {
                                                    openSnack('error', error.message)
                                                })





                                            }}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button variant='contained'
                                                display={"inline"}
                                                color='primary'
                                                size='small'
                                                style={{
                                                    textTransform: 'none',
                                                    marginLeft: 15,
                                                    backgroundColor: '#404050',
                                                    border: '1px solid #404050'
                                                }}
                                            >
                                                Delete
                                            </Button>
                                        </Popconfirm>


                                    </TableCell>



                                </TableRow>

                            )
                        })}
                    </TableBody>
                </Table>
            </Col>

            <Modal title="" visible={itemModal} onCancel={() => {
                setItemModal(false)
                setItem({})
            }} footer={null} width={screenWidth === 'xs' ? '80%' : '50%'} style={{ borderRadius: 40 }} >
                <Row gutter={[4, 4]} align="middle" >
                    <Col style={{}}>
                        <Typography variant='h5' display={"inline"}>
                            Update Parameter
                        </Typography>

                    </Col>



                    <Col {...fullMobile(24, 24)}  >
                        <Row >
                            <Col {...fullMobile(24, 24)} style={{ paddingTop: 15 }}  >
                                <Typography variant="body2" display="inline" fontWeight={600} fontSize={14} style={{ color: '#404050' }}>
                                    Parameter Name<span style={{ color: 'red' }}>*</span>
                                </Typography>
                            </Col>
                            <Col {...fullMobile(24, 24)}  >
                                <OutlinedInput
                                    size="small"
                                    style={{ width: 300 }}
                                    id="outlined-adornment-amount"
                                    value={item?.name}
                                    //disabled if default is true
                                    onChange={(e) => {
                                        setItem({ ...item, name: e.target.value })
                                    }}

                                />
                            </Col>
                            <Col {...fullMobile(24, 24)} style={{ paddingTop: 15 }} >
                                <Typography variant="body2" display="inline" fontWeight={600} fontSize={14} style={{ color: '#404050' }}>
                                    Description
                                </Typography>
                            </Col>
                            <Col {...fullMobile(24, 24)}  >
                                <OutlinedInput
                                    size="small"
                                    style={{ width: 300 }}
                                    multiline
                                    rows={2}
                                    id="outlined-adornment-amount"
                                    value={item?.description}
                                    onChange={(e) => {
                                        setItem({ ...item, description: e.target.value })
                                    }}

                                />
                            </Col>

                            <Col {...fullMobile(24, 24)} style={{ paddingTop: 25 }}  >
                                {

                                    item.name !== '' && (
                                        <Button variant='contained'
                                            color='primary'
                                            style={{ textTransform: 'none', backgroundColor: '#404050', }}
                                            onClick={async () => {
                                                const newItem = { ...item }
                                                const docRef = doc(db, "clients", userInfo.currentCompany, 'accounts', userInfo.currentAccount, 'parameters', newItem.key);

                                                await updateDoc(docRef, newItem).then(async () => {

                                                    //update item in data and filterData
                                                    const newData = filterData.map((f) => {
                                                        if (f.key === newItem.key) {
                                                            return newItem
                                                        } else {
                                                            return f
                                                        }
                                                    })
                                                    setFilterData(newData)
                                                    //set data to newData
                                                    const newMasterData = data.map((f) => {
                                                        if (f.key === newItem.key) {
                                                            return newItem
                                                        } else {
                                                            return f
                                                        }
                                                    })
                                                    setData(newMasterData)

                                                    openSnack('success', 'Parameter Updated')

                                                }).catch((error) => {
                                                    console.error("Error adding document: ", error);
                                                });
                                                setItemModal(false)
                                                setItem({})
                                            }}
                                        >
                                            Update Parameter
                                        </Button>


                                    )

                                }
                            </Col>

                        </Row>
                    </Col>


















                </Row>
            </Modal>









        </Row>

    );
}