import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Col, Row } from 'antd';
import { Input, TextField } from '@mui/material';
import UnitsSelect from './drawerComponents/unitsSelect';
import ParameterSelect from './drawerComponents/parameterSelect';
import StringInput from './drawerComponents/stringInput';
import TimezonePicker from './drawerComponents/timezonePicker';
import ProbeTypeSelect from './drawerComponents/probeTypeSelect';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '1px solid rgba(0, 0, 0, .3)',
    boxShadow: 24,
    p: 4,
    borderRadius: '24px',
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
};

const BulkEditStreams = ({
    bulkEditor,
    setBulkEditor,
    streams,
    selectedStreams,
    staff,
    accounts,
    currentStream,
    setCurrentStream,
    headers,
    openSnack,
    updateStream,
    stocks,
    setStocks,
    items,
    userInfo,
    setItems,
    setStreams,
    ProbeParameters,
    updateMultipleStreams }) => {

    const handleOpen = () => setBulkEditor(true);
    const handleClose = () => setBulkEditor(false);
    const [bulkStream, setBulkStream] = useState(null);



    return (
        <div>
            <Modal
                open={bulkEditor}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{

                }}

                slotProps={{
                    backdrop: {
                        style: {
                            backgroundColor: 'transparent'
                        }
                    }
                }}
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" fontWeight={700} fontSize={28} component="h2">
                        Stream Bulk Editor
                    </Typography>
                    <Typography id="modal-modal-description" variant="caption" fontSize={13} sx={{ mt: 2 }}>
                        Make changes to multiple streams with the bulk editor. You are making changes to
                        <Typography variant="caption" fontWeight={700} fontSize={13} sx={{ display: 'inline' }}>
                            {` ${selectedStreams?.length} `}
                        </Typography>
                        streams.
                    </Typography>
                    {headers?.filter(header => !['name', 'probe', 'hydroName', 'lat', 'lon', 'lastRecorded', 'hydroID', 'latitude', 'longitude', 'elevation'].includes(header.id))?.map((header, index) => {

                        return (
                            <Row align={"middle"} style={{ marginTop: 20 }}>
                                <Col span={6}>
                                    <Typography id="modal-modal-description" variant="body1" fontWeight={600} fontSize={15}>
                                        {header.headerName}
                                    </Typography>
                                </Col>
                                <Col span={14}>
                                    {header?.optionType === 'timezonePicker' && (<TimezonePicker headerKey={header.id} currentStream={bulkStream} setCurrentStream={setBulkStream} />)}
                                    {header?.optionType === 'string' && (<StringInput headerKey={header.id} currentStream={bulkStream} setCurrentStream={setBulkStream} />)}
                                    {header?.optionType === 'unitSelect' && (<UnitsSelect headerKey={header.id} currentStream={bulkStream} setCurrentStream={setBulkStream} />)}
                                    {header?.optionType === 'parameterSelect' && (<ParameterSelect ProbeParameters={ProbeParameters} headerKey={header.id} currentStream={bulkStream} setCurrentStream={setBulkStream} />)}
                                    {header?.optionType === 'probeTypeSelect' && (<ProbeTypeSelect headerKey={header.id} currentStream={bulkStream} setCurrentStream={setBulkStream} />)}
                                </Col>

                                <Col span={4} style={{
                                    textAlign: 'right'
                                }}>
                                    <Button variant="contained" size="small"
                                        sx={{
                                            backgroundColor: '#3f51b5',
                                            color: 'white',
                                            '&:hover': {
                                                backgroundColor: '#3f51b5',
                                                color: 'white',
                                                opacity: 0.8
                                            },
                                            textTransform: 'none',
                                        }}

                                        onClick={() => {
                                            console.log(bulkStream)
                                            updateMultipleStreams(selectedStreams, bulkStream)
                                            setBulkStream(null)
                                        }}>Apply</Button>
                                </Col>
                            </Row>
                        )
                    })}






                </Box>
            </Modal>
        </div >
    );
}

export default BulkEditStreams;
