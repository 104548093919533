import React from "react";


import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputAdornment, OutlinedInput } from '@mui/material';




const SearchAlbums = (
    {
        searchQuery,
        setSearchQuery,
        screenWidth
    }

) => {






    return (

        <OutlinedInput
            
            size="small"
            style={{
                borderRadius: 16,
                marginRight: 10,
            }}
            value={searchQuery}
            fullWidth
            placeholder='Search'
            startAdornment={
                <InputAdornment position="start">
                    <SearchIcon />
                </InputAdornment>
            }
            endAdornment={
                <InputAdornment position="end">
                    {searchQuery !== '' && (
                        <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                            onClick={() =>
                                setSearchQuery('')
                            }
                        >
                            <ClearIcon />
                        </IconButton>
                    )}
                </InputAdornment>
            }
            onChange={(e) => setSearchQuery(e.target.value)}
        />


    );
};

export default SearchAlbums;
