'use strict';
import { Box, Button, CircularProgress, Divider, IconButton, OutlinedInput, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { fullMobile } from '../../util';

import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DeleteForever, RemoveRedEye, VisibilityOff } from '@mui/icons-material';
import { doc, getFirestore, setDoc, updateDoc } from 'firebase/firestore';
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import Dropzone from 'react-dropzone';
import Lightbox from 'react-image-lightbox';
import uuid from 'react-uuid';
import { getFieldLogsPhotosAlbum, getFieldLogsPhotosAlbumApp, queryFieldLogPhotos } from '../../../../firebase/config';
import './tableStyle.css';
var randomColor = require('randomcolor');







export default function LogPhotos(props) {
    const { userInfo, screenWidth, openSnack, company, account, updateLog, logRef, status, savePhotosRef } = props;
    const [log, setLog] = useState(logRef);
    const [data, setData] = useState([]);
    const [isDragActive, setIsDragActive] = useState(false);
    const [isPhotoActive, setIsPhotoActive] = useState('');
    const [progressBars, setProgressBars] = useState({});
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);

    useEffect(() => {
        if (savePhotosRef === true) {

        }
    }, [savePhotosRef])

    useEffect(() => {
        getInfo()
        localStorage.setItem('logPhotos', JSON.stringify([]))
    }, [])

    async function getInfo() {
        if (log.key) {
            const data = await queryFieldLogPhotos(userInfo, log.key)
            localStorage.setItem('logPhotos', JSON.stringify(data))

            setData(data)
        }
    }

    useEffect(() => {
        setLog(logRef)
        if (log.key === undefined && logRef.key !== undefined) {
            purgeData()

        }
    }, [logRef])

    useEffect(() => {
        if (status === 'built') {
            console.log('built')
            purgeData()
        }
    }, [status])

    async function purgeData() {

        const db = getFirestore()


        //find field log photos albume
        const album = await getFieldLogsPhotosAlbumApp(userInfo)
        console.log('album', album)
       if (data.length > 0)
       {
        localStorage.setItem('logPhotos', JSON.stringify([...data]))
       }


        if (album === null) {

            const albumKey = uuid()
            const item = { key: albumKey, name: 'Application Photos', description: '', archived: false, account: userInfo.currentAccount, company: userInfo.currentCompany }

            const docRef = doc(db, "clients", userInfo.currentCompany, 'albums', item.key);
            await setDoc(docRef, item).then(() => console.log("ALBUM CREATED")).then(async () => {
                const itemRef = doc(db, "users", userInfo.id, 'currentItems', 'currentAlbum');
                await setDoc(itemRef, {
                    key: item.key,
                })
            }).then(() => {
                data.forEach(async (d) => {
                    d.albumKey = albumKey
                    const docRef = doc(db, "clients", userInfo.currentCompany, 'photos', d.key);
                    await setDoc(docRef, d)
                })

            })

        }
        else {
            data.forEach(async (d) => {
                d.albumKey = album
                const docRef = doc(db, "clients", userInfo.currentCompany, 'photos', d.key);
                await setDoc(docRef, d)
            })
        }


    }


 


    const DeletePhotoButton = ({ photo }) => {
        return (
            //create a button that is in the top right corner of the image
            <Button
                style={{ position: 'absolute', top: 0, right: 0, zIndex: 1, borderRadius: 12 }}
                onClick={() => {
                    //delete photo, if log.key exists, delete from firebase, if not, delete from data
                    if (log.key) {
                        const storage = getStorage();
                        const storageRef = ref(storage, `fieldLogsNew/${userInfo.currentCompany}/${photo.key}`);
                        deleteObject(storageRef).then(() => {
                            // File deleted successfully
                            //update data
                            const newData = data.filter((d) => d.key !== photo.key)
                            const unique = [...new Set(newData.map(item => item))];
                            localStorage.setItem('logPhotos', JSON.stringify([...unique]))
                            setData([...unique])
                        }).catch((error) => {
                            // Uh-oh, an error occurred!
                        });

                        //delete from firestore
                        const db = getFirestore();
                        const docRef = doc(db, "clients", userInfo.currentCompany, 'photos', photo.key);
                        updateDoc(docRef, {
                            archived: true,
                        }).then(() => {
                            console.log("Photo successfully removed!");
                        }).catch((error) => {
                            console.error("Error removing photo: ", error);
                        });
                    } else {
                        const newData = data.filter((d) => d.key !== photo.key)
                        const unique = [...new Set(newData.map(item => item))];
                        console.log(unique)
                        localStorage.setItem('logPhotos', JSON.stringify([...unique]))
                        setData([...unique])
                    }
                }}
            >
                <DeleteForever />
            </Button>
        )
    }


    //create a const named photo, it should be an image with a button to delete it
    const Photo = ({ photo }) => {

        return (
            <div></div>
        )
    }








    return (

        <Row gutter={[12, 12]} align="middle" >

            <Col {...fullMobile(24, 24)} style={{ paddingTop: 30 }} >
                <Typography variant='h5' display="inline" fontWeight={500} fontSize={screenWidth === 'xs' ? 16 : 22}>Photos</Typography>
                {status === 'creating' && (<IconButton display="inline" size="small" onClick={() => {
                    updateLog('hidePhotos', !log.hidePhotos)
                }}>
                    {log.hidePhotos ? <VisibilityOff /> : <RemoveRedEye />}
                </IconButton>)}


            </Col>

            {!log.hidePhotos && status === 'creating' && (

                < Col {...fullMobile(8, 12)} className="card-container"
                    //if dragging over, change the background color
                    style={{}}
                    onDragEnter={() => setIsDragActive(true)}
                    onDragLeave={() => setIsDragActive(false)}
                    onDragOver={() => setIsDragActive(true)}
                    onDrop={() => setIsDragActive(false)}
                    onMouseOver={() => setIsDragActive(true)}
                    onMouseLeave={() => setIsDragActive(false)}

                >
                    <Dropzone multiple={true} onDrop={(acceptedFiles) => {
                        const storage = getStorage();

                        acceptedFiles.map((f, i) => {
                            const key = uuid()
                            const photo = {
                                fileCreateDate: moment().format('x'),
                                date: moment().format('x'),
                                timestamp: Number(moment().format('x')),
                                fileCreator: `${userInfo.firstName || ""} ${userInfo.lastName}`,
                                fileCreatorKey: userInfo.id,
                                description: '',
                                key,
                                name: f.name,
                                size: f.size,
                                type: f.type,
                                logkey: log.key || log.tempKey,
                                archived: false,
                                account: userInfo.currentAccount,
                                company: userInfo.currentCompany,
                                progress: 0

                            };

                            data.push(photo)

                            setData([...data])


                            const storage = getStorage();


                            const storageRef = ref(storage, `fieldLogsNew/${userInfo.currentCompany}/${key}`);


                            const uploadTask = uploadBytesResumable(storageRef, f);
                            uploadTask.on('state_changed',
                                (snapshot) => {
                                    var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

                                    const index = data.findIndex((d) => d.key === key)
                                    data[index].progress = progress
                                    const unique = [...new Map(data.map(item => [item['key'], item])).values()];
                                    setData([...unique])

                                    switch (snapshot.state) {
                                        case 'paused':
                                            console.log('Upload is paused');
                                            break;
                                        case 'running':
                                            console.log('Upload is running');
                                            break;
                                    }
                                },
                                (error) => {
                                    console.log(error)
                                },
                                () => {
                                    getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {

                                        const index = data.findIndex((d) => d.key === key)
                                        data[index].downloadURL = downloadURL
                                        data[index].url = downloadURL
                                        data[index].fileLink = downloadURL
                                        //set progress to 100
                                        data[index].progress = 100

                                        //only unique data  
                                        const unique = [...new Map(data.map(item => [item['key'], item])).values()];
                                        setData([...unique])
                                        console.log(unique)






                                        if (log.key === undefined) {

                                            localStorage.setItem('logPhotos', JSON.stringify([...unique]))


                                        }
                                        else {
                                            const db = getFirestore()
                                            const docRef = doc(db, "clients", userInfo.currentCompany, 'photos', photo.key);
                                            await setDoc(docRef, photo)
                                            localStorage.setItem('logPhotos', JSON.stringify([...unique]))

                                            setData([...unique])
                                        }





                                    });
                                }
                            )














                        })
                    }} >
                        {({ getRootProps, getInputProps }) => (
                            <section style={{ height: '100%' }}>
                                <div {...getRootProps()} style={{
                                    borderRadius: 12, alignItems: 'center',
                                    backgroundColor: 'white', padding: '30px', fontWeight: 700,
                                    transition: 'box-shadow 400ms, transform 400ms',
                                    boxShadow: isDragActive ? '0 4px 8px 0 rgba(0, 0, 0, 0.2)' : 'inset 0 0 0 1px #d9d9d9',
                                    transform: isDragActive ? 'scale(1.05)' : 'scale(1)',
                                    cursor: 'pointer',
                                    border: isDragActive ? '2px solid #404050' : '1px solid #d9d9d9',


                                }}>


                                    <input {...getInputProps()} />
                                    <Row gutter={[12, 12]}>
                                        <Col span={24} style={{ textAlign: 'center' }}>
                                            <FontAwesomeIcon fontSize={28} icon={faCloudArrowUp} color="#9ec1c9" />
                                        </Col>
                                        <Col span={24} style={{ textAlign: 'center', color: '#404050', }}>
                                            Upload Photos</Col>
                                    </Row>


                                </div>
                            </section>
                        )}
                    </Dropzone>
                </Col>
            )}

            {isOpen && (
                <Lightbox
                    mainSrc={data[photoIndex].fileLink}
                    nextSrc={data[(photoIndex + 1) % data.length].fileLink}
                    prevSrc={data[(photoIndex + data.length - 1) % data.length].fileLink}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                        setPhotoIndex((photoIndex + data.length - 1) % data.length)
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % data.length)
                    }
                />
            )}



            {data.map((d, i) => {

                return (
                    <Col {...fullMobile(8, 12)} style={{ borderRadius: 12, }} className="card-container" >
                        {d.progress < 100 && (
                            <Col {...fullMobile(8, 12)} style={{
                                borderRadius: 12, borderRadius: 12, alignItems: 'center',
                                justifyContent: 'center', display: 'flex', cursor: 'pointer', height: '100%',
                                backgroundColor: '#fff', color: '#f47e42', padding: '30px', fontWeight: 600, fontSize: '18px',
                                boxShadow: isDragActive ? '0 8px 16px 0 rgba(0, 0, 0, 0.2)' : 'inset 0 0 0 1px #d9d9d9',
                            }} className="card-container"  >
                                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                                    <CircularProgress variant="determinate" value={d.progress} />
                                    <Box
                                        sx={{
                                            top: 0,
                                            left: 0,
                                            bottom: 0,
                                            right: 0,
                                            position: 'absolute',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Typography variant="caption" component="div" color="text.secondary">
                                            {`${Math.round(d.progress)}%`}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Col>)}

                        {d.progress === 100 && (
                            <Col {...fullMobile(24, 24)} className="card-container"
                                //if mouse over, change the background color
                                style={{ borderRadius: 12, }}
                                onMouseEnter={() => {
                                    setIsPhotoActive(d.key)
                                }}
                                onMouseLeave={() => {
                                    setIsPhotoActive('')
                                }}


                            >
                                <Row gutter={[12, 12]} align="middle" style={{ height: '100%' }}>
                                    <Col span={24} style={{ textAlign: 'center' }}>
                                        <DeletePhotoButton photo={d} />
                                        <img src={data.url || d.fileLink} onClick={() => {
                                            setIsOpen(true)
                                            const index = data.findIndex((l) => l.key === d.key)
                                            setPhotoIndex(index)
                                        }} style={{
                                            transition: 'box-shadow 400ms, transform 400ms',
                                            boxShadow: isPhotoActive === d.key ? '0 4px 8px 0 rgba(0, 0, 0, 0.2)' : 'inset 0 0 0 1px #d9d9d9',
                                            transform: isPhotoActive === d.key ? 'scale(1.05)' : 'scale(1)',
                                            width: '100%', height: '100%', objectFit: 'cover', borderRadius: 12
                                        }} />
                                    </Col>
                                    <Col span={24} style={{ textAlign: 'left' }}>
                                        <Typography variant="body1" fontSize={13} fontWeight={600}>Description</Typography>
                                        {status === 'built' ? <Typography variant='body2'>{d.description || 'No description'}</Typography> :
                                            <OutlinedInput
                                                fullWidth notched={false} label="none" placeholder={"Description"} size="small"

                                                value={d.description}
                                                onBlur={purgeData}
                                                onChange={(e) => {
                                                    const index = data.findIndex((l) => l.key === d.key)
                                                    data[index].description = e.target.value
                                                    setData([...data])

                                                }} />
                                        }
                                    </Col>



                                </Row>
                            </Col>
                        )}
                        {d.progress === undefined && (
                            <Col {...fullMobile(24, 24)} className="card-container"
                            //if mouse over, change the background color
                            style={{ borderRadius: 12, }}
                            onMouseEnter={() => {
                                setIsPhotoActive(d.key)
                            }}
                            onMouseLeave={() => {
                                setIsPhotoActive('')
                            }}


                        >
                            <Row gutter={[12, 12]} align="middle" style={{ height: '100%' }}>
                                <Col span={24} style={{ textAlign: 'center' }}>
                                    <DeletePhotoButton photo={d} />
                                    <img src={data.url || d.fileLink} onClick={() => {
                                        setIsOpen(true)
                                        const index = data.findIndex((l) => l.key === d.key)
                                        setPhotoIndex(index)
                                    }} style={{
                                        transition: 'box-shadow 400ms, transform 400ms',
                                        boxShadow: isPhotoActive === d.key ? '0 4px 8px 0 rgba(0, 0, 0, 0.2)' : 'inset 0 0 0 1px #d9d9d9',
                                        transform: isPhotoActive === d.key ? 'scale(1.05)' : 'scale(1)',
                                        width: '100%', height: '100%', objectFit: 'cover', borderRadius: 12
                                    }} />
                                </Col>
                                <Col span={24} style={{ textAlign: 'left' }}>
                                    <Typography variant="body1" fontSize={13} fontWeight={600}>Description</Typography>
                                    {status === 'built' ? <Typography variant='body2'>{d.description || 'No description'}</Typography> :
                                        <OutlinedInput
                                            fullWidth notched={false} label="none" placeholder={"Description"} size="small"

                                            value={d.description}
                                            onBlur={purgeData}
                                            onChange={(e) => {
                                                const index = data.findIndex((l) => l.key === d.key)
                                                data[index].description = e.target.value
                                                setData([...data])
                                             
                                            }} />
                                    }
                                </Col>



                            </Row>
                        </Col>
                        )}
                    </Col>
                )
            })
            }
            {
                data.length === 0 && (
                    <Col {...fullMobile(24, 24)}   >
                        <Typography variant="h2" style={{ fontSize: 16, paddingBottom: 10 }}>No Photos</Typography>
                    </Col>
                )
            }
            <Col {...fullMobile(24, 24)}>
                <Divider style={{
                    borderColor: 'rgb(148, 186, 195)',
                    borderBottomWidth: 'medium',

                }} />
            </Col>




        </Row >

    )



}