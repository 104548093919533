import React, { useState, useEffect } from 'react';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, } from 'firebase/firestore';
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import moment from 'moment';
import { queryItems, queryParameters, queryStations, queryStocks } from '../../../../firebase/config';
import * as htmlToImage from 'html-to-image';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";

import ProbeParameters from '../../../../extra/probeParameters.json';
import Units from '../../../../extra/units.json';
var convert = require('convert-units')



function getDataUri(url) {
    return new Promise(resolve => {
        var image = new Image();
        image.setAttribute('crossOrigin', 'anonymous'); //getting images from external domain

        image.onload = function () {
            var canvas = document.createElement('canvas');
            canvas.width = this.naturalWidth;
            canvas.height = this.naturalHeight;

            //next three lines for white background in case png has a transparent background
            var ctx = canvas.getContext('2d');
            ctx.fillStyle = '#fff';  /// set white fill style
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            canvas.getContext('2d').drawImage(this, 0, 0);

            resolve(canvas.toDataURL('image/jpeg'));
        };

        image.src = url;
    })
}



export const MakePDF = async (log, userInfo, openSnack, type, company) => {





    const pdf = new jsPDF({

    });

    const PPArray = Object.values(ProbeParameters).map((p, i) => {
        return ({
            label: p,
            name: p,
            key: Object.keys(ProbeParameters)[i]
        })
    });

    const unitList = Object.values(Units).map((u, i) => {
        return ({ label: u, name: u, key: u, unitid: Object.keys(Units)[i] })
    })



    const items = await queryItems(userInfo)
    const stocks = await queryStocks(userInfo)
    const stations = await queryStations(userInfo, ['grab', 'both'])
    const parameters = await queryParameters(userInfo)
    const weatherIcon = await GetWeatherIcon(log, type)
    const preWaterData = await GetPreWQData(log, pdf, weatherIcon);
    const eventWaterData = await GetEventWQData(log, pdf, weatherIcon);
    const postWaterData = await GetPostWQData(log, pdf, weatherIcon);
    const chemicalData = await GetChemicalData(log, pdf);
    console.log(chemicalData)
    const getLogo = await getLogoImage(userInfo);
    const photos = await GetPhotos(log, pdf, weatherIcon);
    const header = await MakeHeader(pdf, log, weatherIcon, preWaterData, eventWaterData, postWaterData, chemicalData, photos, items, stocks, openSnack, stations, parameters, type, userInfo, company, getLogo,
        PPArray, unitList);

    if (type === 'email') {
        return header.output('blob');
    }
    else {
        return header
    }
};

export const GetWeatherIcon = async (log, type) => {
    const dataUrl = log?.weather?.weatherImage


    if (type === 'email') {
        return dataUrl;
    }
    else {
        if ([undefined, 'none'].includes(dataUrl)) {
            const node = document.getElementById('my-node');
            const dataUrl = await htmlToImage.toPng(node);
            return dataUrl;
        }

        return dataUrl;
    }


}

export const getLogoImage = async (userInfo) => {
    const db = getFirestore();
    const docRef = doc(db, "clients", userInfo.currentCompany);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        console.log(docSnap.data().logo)
        if (docSnap.data().logo !== undefined) {

            const getLogo = await getDownloadURL(ref(getStorage(), `${userInfo.currentCompany}/logo/`));
            //if not found getlogo will be undefined
            var photourl = await getDataUri(getLogo);
            return photourl;
        }
        else {
            return null;
        }
    } else {
        return null;
    }

}


export const GetPreWQData = async (log, pdf, weatherIcon) => {

    //get waterQualityData from local storage
    console.log(log.logKeyPR)

    const waterQualityData = JSON.parse(localStorage.getItem(`${log.logKeyPR}waterquality`));


    if (waterQualityData !== null && waterQualityData !== undefined) {
        const newData = waterQualityData.filter((row) => {
            return row.parameterid !== undefined && row.locationid !== undefined && row.value !== undefined && row.parameterid !== '' && row.locationid !== '' && row.value !== ''
        });
        return newData;
    }
    else {

        return [];
    }


}
export const GetEventWQData = async (log, pdf, weatherIcon) => {

    //get waterQualityData from local storage
    console.log(log.logKeyEV)

    const waterQualityData = JSON.parse(localStorage.getItem(`${log.logKeyEV}waterquality`));


    if (waterQualityData !== null && waterQualityData !== undefined) {
        const newData = waterQualityData.filter((row) => {
            return row.parameterid !== undefined && row.locationid !== undefined && row.value !== undefined && row.parameterid !== '' && row.locationid !== '' && row.value !== ''
        });
        return newData;
    }
    else {

        return [];
    }


}
export const GetPostWQData = async (log, pdf, weatherIcon) => {

    //get waterQualityData from local storage


    const waterQualityData = JSON.parse(localStorage.getItem(`${log.logKeyPO}waterquality`));


    if (waterQualityData !== null && waterQualityData !== undefined) {
        const newData = waterQualityData.filter((row) => {
            return row.parameterid !== undefined && row.locationid !== undefined && row.value !== undefined && row.parameterid !== '' && row.locationid !== '' && row.value !== ''
        });
        return newData;
    }
    else {

        return [];
    }


}

export const GetChemicalData = async (log, pdf, weatherIcon) => {

    //get chemicalData from local storage
    const chemicalData = JSON.parse(localStorage.getItem('chemicalData'));
    console.log(chemicalData)

    if (chemicalData !== null) {
        const newData = chemicalData.filter((row) => {
            return row.product !== undefined
        });
        return newData;
    }
    else {
        return chemicalData;
    }


}

export const GetPhotos = async (log, pdf, weatherIcon) => {

    //get photos from local storage
    const photos = JSON.parse(localStorage.getItem('logPhotos'));


    const newData = photos.map(async (photo) => {
        var photourl = await getDataUri(photo.url);

        return ({ ...photo, url: photourl })

    });


    //resolve all promises in newData
    const resolvedData = await Promise.all(newData);

    //get unique resolvedData by key    
    const unique = [...new Map(resolvedData.map(item => [item['key'], item])).values()];


    return unique;

}









export const MakeHeader = async (pdf, log, weatherIcon, wqData, eventWaterData, postWaterData, chemicalData, photos, items, stocks, openSnack, stations, parameters, type, userInfo, company, logo, PPArray, unitList) => {
    function getFirstLetters(str) {
        if (str) {
            const firstLetters = str
                .split(' ')
                .map(word => word[0])
                .join('');

            return firstLetters;
        }
        else return '';
    }

    try {

        //create image from weatherIcon
        const weatherImage = new Image();
        weatherImage.src = weatherIcon;



        const LogID = getFirstLetters(log.companyName || company.companyName) + "-" +
            `${log?.account !== undefined ? getFirstLetters(log?.account === undefined ? '' : `${log?.account?.accountName}`) : ''}`
            + '-' + moment(log?.startDate, 'x').format('MM-DD-YYYY') + "-" + "LOG";


        const startY = 1;
        //add autotable to pdf with "Field Log" in first cell with font size 18
        pdf.autoTable({
            pageBreak: 'auto',
            rowPageBreak: 'auto',
            columns: ['', '', '', '', '', '',],
            body: [

                [
                    {
                        dataKey: 'logo', content: '',
                        colSpan: 3, rowSpan: 1, styles: {}
                    },
                    { dataKey: 'header', content: '', colSpan: 3, rowSpan: 1, styles: { halign: 'center', } },

                ],

                [
                    {
                        dataKey: 'line', content: '',
                        colSpan: 6, rowSpan: 1, styles: { lineWidth: 0, lineColor: 'rgb(148, 186, 195)' }
                    },


                ],
                [
                    {
                        dataKey: 'weather', content: '',
                        colSpan: 3, rowSpan: 1, styles: {}
                    },
                    { dataKey: 'generalInfo', content: '', colSpan: 3, rowSpan: 1, styles: { halign: 'center', } },

                ],
                [
                    {
                        dataKey: 'line', content: '',
                        colSpan: 6, rowSpan: 1, styles: { lineWidth: 0, lineColor: 'rgb(148, 186, 195)' }
                    },


                ],









            ],
            didParseCell: function (data) {
                if (data?.cell?.raw?.dataKey === 'header') {
                    data.row.height = 25;
                }
                if (data?.cell?.raw?.dataKey === 'generalInfo') {
                    const additionalStaffLabels = log.additionalStaff !== undefined && log.additionalStaff.length > 0 ?
                        log.additionalStaff.map((staff) => {
                            return staff.label
                        }) : ["No additional staff"]
                    //count the number of characters in additionalStaffLabels join
                    const additionalStaffLabelsLength = additionalStaffLabels.join('').length;
                    //divide this by 33
                    const additionalStaffLabelsHeight = Math.ceil(additionalStaffLabelsLength / 33);
                    //multiply by 5
                    const additionalStaffLabelsHeightMultiplier = additionalStaffLabelsHeight * 5;
                    data.row.height = 40 + additionalStaffLabelsHeightMultiplier;
                }


            },
            didDrawCell: function (data) {
                if (data?.cell?.raw?.dataKey === 'id') {
                }
                if (data?.cell?.raw?.dataKey === 'line') {
                    //add a line to the bottom of the cell with color lineColor: 'rgb(148, 186, 195)' and line width to 3
                    pdf.setLineWidth(1);
                    pdf.setDrawColor(148, 186, 195);
                    pdf.line(data.cell.x, data.cell.y + data.cell.height, data.cell.x + data.cell.width, data.cell.y + data.cell.height);

                }
                if (data?.cell?.raw?.dataKey === 'logo') {
                    //add logo to pdf if logo is not null or not undefined
                    if (logo !== null && logo !== undefined) {
                        const ratio = pdf.getImageProperties(logo).height / pdf.getImageProperties(logo).width;
                        const height = 20;
                        const width = height / ratio;
                        pdf.addImage(logo, 'PNG', data.cell.x, data.cell.y, width, height);

                    }

                }






                if (data?.cell?.raw?.dataKey === 'header') {
                    pdf.autoTable({
                        showHead: 'never',
                        theme: 'plain',
                        startY: data.cell.y + 1,
                        margin: { left: data.cell.x },
                        //width of table is the width of the cell
                        tableWidth: data.cell.width,
                        styles: {
                            padding: 0,
                            overflow: 'linebreak',
                            halign: 'right',
                        },
                        columns: [
                            { dataKey: 'id', header: 'ID', styles: {} },

                        ],

                        body: [
                            [{ dataKey: 'name', content: `Field Log`, colSpan: 1, rowSpan: 1, styles: { cellPadding: 0, textColor: 'black', fontSize: 18, } }],
                            [{ dataKey: 'name', content: `${log?.companyName || company.companyName}`, colSpan: 1, rowSpan: 1, styles: { cellPadding: 0, textColor: 'black', fontSize: 18, } }],
                            [{ dataKey: 'name', content: `${log?.account?.accountName}`, colSpan: 1, rowSpan: 1, styles: { cellPadding: 0, } }],
                            [{ dataKey: 'name', content: `${log?.account?.address}`, colSpan: 1, rowSpan: 1, styles: { cellPadding: 0, } }],

                        ],
                    })
                }
                if (data?.cell?.raw?.dataKey === 'weather') {
                    const temperature = log?.weather?.temp > 0 && log?.weather?.temp < 150 ? log?.weather?.temp : convert(log?.weather?.temp).from('K').to('F');
                    //capitalize log?.weather.description
                    const description = log?.weather.description.charAt(0).toUpperCase() + log?.weather.description.slice(1);

                    pdf.autoTable({
                        showHead: 'never',
                        theme: 'plain',
                        startY: data.cell.y + 1,
                        margin: { left: data.cell.x },
                        //width of table is the width of the cell
                        tableWidth: data.cell.width,
                        styles: {
                            padding: 0,
                            overflow: 'linebreak',
                            halign: 'left',
                        },
                        columns: [
                            '', '', '', '', ''

                        ],

                        body: [
                            [{ dataKey: 'name', content: `General Info`, colSpan: 5, rowSpan: 1, styles: { cellPadding: { top: 3, right: 0, bottom: 2, left: 0 }, textColor: 'black', fontSize: 18, } }],

                            [{ dataKey: 'name', content: `${temperature.toFixed(0)} F`, colSpan: 3, rowSpan: 1, styles: { fontStyle: 'bold', cellPadding: { top: 4, right: 0, bottom: 4, left: 0 }, fontSize: 18, textColor: 'black' } },
                            { dataKey: 'weatherImage', content: ``, colSpan: 2, rowSpan: 3, styles: { cellPadding: 0, } }],

                            [{ dataKey: 'name', content: `${description}`, colSpan: 3, rowSpan: 1, styles: { cellPadding: { top: 2, right: 0, bottom: 2, left: 0 }, fontSize: 13, } },
                            ],

                            [{
                                dataKey: 'name', content: `${convert(log?.weather.wind_speed).from('m/s').to('m/h').toFixed(0)} mph ${log?.weather.windDirection || ''}`,
                                colSpan: 3, rowSpan: 1, styles: { cellPadding: { top: 2, right: 0, bottom: 2, left: 0 }, fontSize: 13, }
                            },
                            ],


                        ],
                        didDrawCell: async (data) => {
                            if (data?.cell?.raw?.dataKey === 'weatherImage') {
                                const ratio = pdf.getImageProperties(weatherImage).height / pdf.getImageProperties(weatherImage).width;
                                const width = 30;
                                const height = width * ratio;
                                pdf.addImage(weatherImage, 'PNG', data.cell.x, data.cell.y + 6, 20, 20);
                            }
                        }
                    })
                }
                if (data?.cell?.raw?.dataKey === 'generalInfo') {
                    const additionalStaffLabels = log.additionalStaff !== undefined && log.additionalStaff.length > 0 ?
                        log.additionalStaff.map((staff) => {
                            return staff.label
                        }) : ["No additional staff"]



                    pdf.autoTable({
                        showHead: 'never',
                        theme: 'plain',
                        startY: data.cell.y + 1,
                        margin: { left: data.cell.x },
                        //width of table is the width of the cell
                        tableWidth: data.cell.width,
                        styles: {
                            padding: 0,
                            overflow: 'linebreak',
                            halign: 'right',
                            fontSize: 12,
                            textColor: 'black',
                            cellPadding: 1
                        },
                        columns: [
                            { dataKey: 'id', header: 'ID', styles: { lineWidth: 0.05 } },

                        ],

                        body: [
                            [{ dataKey: 'name', content: `Log ID:  ${LogID}`, colSpan: 1, rowSpan: 1, styles: {} }],
                            [{ dataKey: 'name', content: `Log Number:  ${log?.lognumber}`, colSpan: 1, rowSpan: 1, styles: {} }],
                            [{ dataKey: 'name', content: `Start Date/Time: ${moment(log?.startDate, 'x').format('MM-DD-YYYY hh:mm a')}`, colSpan: 1, rowSpan: 1, styles: {} }],
                            [{ dataKey: 'name', content: `End Date/Time: ${moment(log?.endDate, 'x').format('MM-DD-YYYY hh:mm a')}`, colSpan: 1, rowSpan: 1, styles: {} }],
                            [{ dataKey: 'name', content: `Lead Staff: ${log?.leadStaff?.label}`, colSpan: 1, rowSpan: 1, styles: {} }],
                            [{ dataKey: 'name', content: `Additional Staff: ${additionalStaffLabels.join()}`, colSpan: 1, rowSpan: 1, styles: {} }],


                        ],
                    })
                }






            },






            startY: startY,
            theme: 'plain',

            styles: {
                fontSize: 20,
                halign: 'left',
                valign: 'top',
                padding: 0,
                overflow: 'linebreak',
                columnWidth: 'wrap',
            },
            columnStyles: {

            },
            headStyles: {
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
            },
        });

        //water conditions table
        const waterBottom = pdf.lastAutoTable.finalY;

        pdf.autoTable({
            showHead: 'never',
            theme: 'plain',
            startY: waterBottom + 3,
            margin: 'auto',
            //width of table is the width of the cell
            tableWidth: 'auto',
            styles: {
                padding: 0,
                overflow: 'linebreak',
                fontSize: 11,
                textColor: 'black',
                cellPadding: 1
            },
            columns: [
                '', '', ''

            ],

            body: [
                [
                    {
                        dataKey: 'wq', content: 'Water Conditions',
                        colSpan: 3, rowSpan: 1, styles: { textColor: 'black', fontStyle: 'bold', fontSize: 18, cellPadding: { top: 0, right: 0, bottom: 2, left: 0 }, }
                    },
                ],
                [
                    { dataKey: 'name', content: `Water Body Type`, colSpan: 1, rowSpan: 1, styles: { fontStyle: 'bold' } },
                    { dataKey: 'name', content: `Water Temperature F`, colSpan: 1, rowSpan: 1, styles: { fontStyle: 'bold' } },
                    { dataKey: 'name', content: `Water Depth (ft)`, colSpan: 1, rowSpan: 1, styles: { fontStyle: 'bold' } },
                ],


                [
                    { dataKey: 'name', content: `${log?.waterBodyType === undefined ? '' : log?.waterBodyType.label}`, colSpan: 1, rowSpan: 1, styles: {} },
                    { dataKey: 'name', content: `${log?.waterTemp || ''} F`, colSpan: 1, rowSpan: 1, styles: {} },
                    { dataKey: 'name', content: `${log?.waterDepth || ''} ft`, colSpan: 1, rowSpan: 1, styles: {} },
                ],
                [
                    { dataKey: 'name', content: `Water Flow (ft/s cfs)`, colSpan: 1, rowSpan: 1, styles: { fontStyle: 'bold' } },
                    { dataKey: 'name', content: `Percent Weed Cover`, colSpan: 1, rowSpan: 1, styles: { fontStyle: 'bold' } },
                    { dataKey: 'name', content: `Water Color`, colSpan: 1, rowSpan: 1, styles: { fontStyle: 'bold' } },
                ],
                [
                    { dataKey: 'name', content: `${log?.waterFlow || ''} ft/s cfs`, colSpan: 1, rowSpan: 1, styles: {} },
                    { dataKey: 'name', content: `${log?.percentWeedCover || ''} F`, colSpan: 1, rowSpan: 1, styles: {} },
                    { dataKey: 'name', content: `${log?.waterColor === undefined ? '' : log?.waterColor.label}`, colSpan: 1, rowSpan: 1, styles: {} },
                ],
                [
                    { dataKey: 'name', content: `Water Clarity`, colSpan: 1, rowSpan: 1, styles: { fontStyle: 'bold' } },
                    { dataKey: 'name', content: `Water Sheen`, colSpan: 1, rowSpan: 1, styles: { fontStyle: 'bold' } },
                ],
                [
                    { dataKey: 'name', content: `${log?.waterClarity === undefined ? '' : log?.waterClarity.label}`, colSpan: 1, rowSpan: 1, styles: {} },
                    { dataKey: 'name', content: `${log?.waterSheen === undefined ? "No Sheen" : log?.waterSheen === false ? "No Sheen" : "Sheen Present"} `, colSpan: 1, rowSpan: 1, styles: {} },
                ],
                [
                    {
                        dataKey: 'line', content: '',
                        colSpan: 3, rowSpan: 1, styles: { lineWidth: 0, lineColor: 'rgb(148, 186, 195)' }
                    },


                ],



            ],
            didDrawCell: function (data) {

                if (data?.cell?.raw?.dataKey === 'line') {
                    //add a line to the bottom of the cell with color lineColor: 'rgb(148, 186, 195)' and line width to 3
                    pdf.setLineWidth(1);
                    pdf.setDrawColor(148, 186, 195);
                    pdf.line(data.cell.x, data.cell.y + data.cell.height, data.cell.x + data.cell.width, data.cell.y + data.cell.height);

                }
            },
        })

        //get the bottom of the table and add 10px to it



        const bottomAppInfo = pdf.lastAutoTable.finalY;

        console.log(log)
        const mapItems = log?.mapItems || [];
        const areas = mapItems.map(item => item?.area || 0);
        const totalArea = areas.reduce((a, b) => a + b, 0);
        const totalAreaString = totalArea.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

        //creat string listing all the areas
        const areaString = mapItems.map(item => `Area ${item?.label}: ${item?.area.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} acres `).join(', ');


        pdf.autoTable({
            showHead: 'never',
            theme: 'plain',
            startY: bottomAppInfo + 3,
            margin: 'auto',
            //width of table is the width of the cell
            tableWidth: 'auto',
            styles: {
                padding: 0,
                overflow: 'linebreak',
                fontSize: 11,
                textColor: 'black',
                cellPadding: 1,
                cellWidth: 90
            },
            columns: [
                '', '',

            ],


            body: [
                [
                    {
                        dataKey: 'wq', content: 'Application Info',
                        colSpan: 1, rowSpan: 1, styles: { textColor: 'black', fontStyle: 'bold', fontSize: 18, cellPadding: { top: 0, right: 0, bottom: 2, left: 0 }, }
                    },


                ],
                [

                    { dataKey: 'name', content: `Target Species`, colSpan: 1, rowSpan: 1, styles: { fontStyle: 'bold' } },
                    { dataKey: log?.staticUrl, content: "map", colSpan: 1, rowSpan: 6, styles: { fontStyle: 'bold' } },


                ],


                [
                    { dataKey: 'name', content: `${log?.targetSpecies}`, colSpan: 1, rowSpan: 1, styles: {} },
                ],
                [

                    { dataKey: 'name', content: `Percent Covered`, colSpan: 1, rowSpan: 1, styles: { fontStyle: 'bold' } },

                ],
                [

                    { dataKey: 'name', content: `${log?.percentApplied}%`, colSpan: 1, rowSpan: 1, styles: {} },
                ],
                [

                    { dataKey: 'name', content: `Application Method`, colSpan: 1, rowSpan: 1, styles: { fontStyle: 'bold' } },

                ],
                [
                    { dataKey: 'name', content: `${log?.applicationMethod?.label}`, colSpan: 1, rowSpan: 1, styles: {} },


                ],
                [
                    { dataKey: 'spacer', content: `${areaString} Total: ${totalAreaString} acres`, colSpan: 1, rowSpan: 1, styles: { halign: 'left' } },


                ],


                [
                    {
                        dataKey: 'line', content: '',
                        colSpan: 3, rowSpan: 1, styles: { lineWidth: 0, lineColor: 'rgb(148, 186, 195)' }
                    },


                ],



            ],
            didParseCell: (data) => {

                if (data?.cell?.raw?.dataKey === 'spacer') {
                    data.row.height = 50;
                }




            },
            didDrawCell: function (data) {

                if (data?.cell?.raw?.dataKey === 'line') {
                    //add a line to the bottom of the cell with color lineColor: 'rgb(148, 186, 195)' and line width to 3
                    pdf.setLineWidth(1);
                    pdf.setDrawColor(148, 186, 195);
                    pdf.line(data.cell.x, data.cell.y + data.cell.height, data.cell.x + data.cell.width, data.cell.y + data.cell.height);

                }
                if (data?.cell?.raw?.content === 'map') {

                    pdf.addImage(data?.cell?.raw?.dataKey, 'PNG', data.cell.x + 0, data.cell.y + 0, 85, 85);
                }
            },
        })


        //addpage
        pdf.addPage();
        console.log(chemicalData)


        const chemicalTable = chemicalData === null ? [] : chemicalData.map((row, i) => {


            const product = items?.find(item => item.key === row.product)


            const row1 = [
                { dataKey: 'name', content: `Chemical`, colSpan: 3, rowSpan: 1, styles: { fontStyle: 'bold' } },
                { dataKey: 'name', content: `Stock`, colSpan: 3, rowSpan: 1, styles: { fontStyle: 'bold' } },
            ];
            const row2 = [
                { dataKey: 'name', content: `${items?.find(item => item.key === row.product)?.name}`, colSpan: 3, rowSpan: 1, styles: { fontSize: 14 } },
                { dataKey: 'name', content: `${row?.supplyStock?.label || ''}`, colSpan: 3, rowSpan: 1, styles: {} },
            ];
            const row3 = [
                { dataKey: 'name', content: `Rate`, colSpan: 2, rowSpan: 1, styles: { fontStyle: 'bold' } },
                { dataKey: 'name', content: `Amount`, colSpan: 2, rowSpan: 1, styles: { fontStyle: 'bold' } },
                { dataKey: 'name', content: `product Type`, colSpan: 2, rowSpan: 1, styles: { fontStyle: 'bold' } },
            ];
            const row4 = [
                { dataKey: 'name', content: `${row?.concentration || ''} ${row?.concentrationUnits?.label || ''}`, colSpan: 2, rowSpan: 1, styles: {} },
                { dataKey: 'name', content: `${row?.quantity || ''} ${row?.units?.label || ''}`, colSpan: 2, rowSpan: 1, styles: {} },
                { dataKey: 'name', content: `${product?.chemicalType?.label || ''}`, colSpan: 2, rowSpan: 1, styles: {} },
            ];
            const row5 = [
                { dataKey: 'name', content: `EPA #`, colSpan: 2, rowSpan: 1, styles: { fontStyle: 'bold' } },
                { dataKey: 'name', content: `State #`, colSpan: 2, rowSpan: 1, styles: { fontStyle: 'bold' } },
                { dataKey: 'name', content: `Active Ingredient`, colSpan: 2, rowSpan: 1, styles: { fontStyle: 'bold' } },
            ];
            const row6 = [
                { dataKey: 'name', content: `${product?.chemEPA || ''}`, colSpan: 2, rowSpan: 1, styles: {} },
                { dataKey: 'name', content: `${product?.chemState || ''}`, colSpan: 2, rowSpan: 1, styles: {} },
                { dataKey: 'name', content: `${product?.chemAI || ''}`, colSpan: 2, rowSpan: 1, styles: {} },
            ];
            const row7 = [
                {
                    dataKey: 'grayline', content: '',
                    colSpan: 6, rowSpan: 1, styles: { lineWidth: 0, lineColor: 'rgb(148, 186, 195)', cellPadding: { top: 0, right: 0, bottom: 0, left: 0 } }
                },


            ];








            const lineTable = [row1, row2, row3, row4, row5, row6, row7];
            return lineTable
        })
        console.log(chemicalTable)


        const flatChemicalTable = chemicalTable.flat(1)

        console.log(flatChemicalTable)


        const oneInch = 25.4;
        pdf.autoTable({
            showHead: 'never',
            theme: 'plain',
            startY: oneInch,
            margin: 'auto',
            //width of table is the width of the cell
            tableWidth: 'auto',
            styles: {
                padding: 0,
                overflow: 'linebreak',
                fontSize: 11,
                textColor: 'black',
                cellPadding: 1,
                cellWidth: 30
            },
            columns: [
                '', '', '', '', '', '',

            ],


            body: [

                [
                    {
                        dataKey: 'wq', content: 'Chemicals',
                        colSpan: 6, rowSpan: 1, styles: { textColor: 'black', fontStyle: 'bold', fontSize: 18, cellPadding: { top: 0, right: 0, bottom: 2, left: 0 }, }
                    },


                ],
                [
                    {
                        dataKey: 'line', content: '',
                        colSpan: 6, rowSpan: 1, styles: { lineWidth: 0, lineColor: 'rgb(148, 186, 195)', cellPadding: { top: 0, right: 0, bottom: 0, left: 0 } }
                    },


                ],



            ],
            didParseCell: (data) => {

                if (data?.cell?.raw?.dataKey === 'spacer') {
                    data.row.height = 50;
                }




            },
            didDrawCell: function (data) {

                if (data?.cell?.raw?.dataKey === 'line') {
                    //add a line to the bottom of the cell with color lineColor: 'rgb(148, 186, 195)' and line width to 3
                    pdf.setLineWidth(1);
                    pdf.setDrawColor(148, 186, 195);
                    pdf.line(data.cell.x, data.cell.y, data.cell.x + data.cell.width, data.cell.y);

                }
                if (data?.cell?.raw?.dataKey === 'grayline') {
                    //add a line to the bottom of the cell with color lineColor: 'rgb(148, 186, 195)' and line width to 3
                    pdf.setLineWidth(1);
                    pdf.setDrawColor('#c9c9c9');
                    pdf.line(data.cell.x, data.cell.y, data.cell.x + data.cell.width, data.cell.y);

                }
                if (data?.cell?.raw?.content === 'map') {

                    pdf.addImage(data?.cell?.raw?.dataKey, 'PNG', data.cell.x + 0, data.cell.y + 0, 85, 85);
                }
            },
        })
        const wqBottomChem = pdf.lastAutoTable.finalY + 2;

        pdf.autoTable({
            showHead: 'never',
            theme: 'grid',
            startY: wqBottomChem,
            margin: 'auto',
            //width of table is the width of the cell
            tableWidth: 'auto',
            styles: {
                padding: 0,
                overflow: 'linebreak',
                fontSize: 11,
                textColor: 'black',
                cellPadding: 1,
                cellWidth: 30
            },
            columns: [
                '', '', '', '', '', '',

            ],


            body: [

                ...flatChemicalTable,




                [
                    {
                        dataKey: 'line', content: '',
                        colSpan: 6, rowSpan: 1, styles: { lineWidth: 0, lineColor: 'rgb(148, 186, 195)' }
                    },


                ],



            ],
            didParseCell: (data) => {

                if (data?.cell?.raw?.dataKey === 'spacer') {
                    data.row.height = 50;
                }




            },
            didDrawCell: function (data) {

                if (data?.cell?.raw?.dataKey === 'line') {
                    //add a line to the bottom of the cell with color lineColor: 'rgb(148, 186, 195)' and line width to 3
                    pdf.setLineWidth(1);
                    pdf.setDrawColor(148, 186, 195);
                    pdf.line(data.cell.x, data.cell.y + data.cell.height, data.cell.x + data.cell.width, data.cell.y + data.cell.height);

                }
                if (data?.cell?.raw?.dataKey === 'grayline') {
                    //add a line to the bottom of the cell with color lineColor: 'rgb(148, 186, 195)' and line width to 3
                    pdf.setLineWidth(1);
                    pdf.setDrawColor('#c9c9c9');
                    pdf.line(data.cell.x, data.cell.y, data.cell.x + data.cell.width, data.cell.y);

                }
                if (data?.cell?.raw?.content === 'map') {

                    pdf.addImage(data?.cell?.raw?.dataKey, 'PNG', data.cell.x + 0, data.cell.y + 0, 85, 85);
                }
            },
        })











        const wqBottom = pdf.lastAutoTable.finalY;


        pdf.autoTable({
            columns: ['', '', '', '', ''],
            styles: {
                padding: 0,
                overflow: 'linebreak',
                fontSize: 12,
                textColor: 'black',
                cellPadding: 1
            },
            body: [

                [
                    {
                        dataKey: 'wq', content: 'Water Quality (pre-application monitoring)',
                        colSpan: 5, rowSpan: 1, styles: { textColor: 'black', fontStyle: 'bold', fontSize: 16, cellPadding: { top: 0, right: 0, bottom: 0, left: 0 }, }
                    },

                ],


            ],
            headStyles: { fontStyle: 'bold' },
            startY: wqBottom + 2,

            theme: "plain",
            margin: 'auto',
            //width of table is the width of the cell
            tableWidth: 'auto',

        })
        const wqBottom2 = pdf.lastAutoTable.finalY;
        const wqTable = [null, undefined].includes(wqData) ? [] : wqData.map((row) => {

         
            const stationsIndex = stations.map((station) => station.id.toString()).indexOf(row.nodeid.toString())

            const station = stations.find((station) => station.id === row.nodeid)

            row.locationLabel = station.name || ''
            row.parameterLabel = [...PPArray, ...parameters].find((parameter) => parameter.key === row.parameterid)?.name || ''
            return [moment(row.time, 'YYYY-MM-DD HH:mm').format('MM-DD-YYYY h:mm a'), row.parameterLabel, row.locationLabel, row.value, row.unitid || '']
        })

        //add text saying no data if wqdata has length of 0
        if (wqTable.length === 0) {
            pdf.text('No Water Quality Data', 15, wqBottom2 + 5, { fontSize: 12, fontStyle: 'bold' });

            pdf.autoTable({
                columns: [''],
                theme: 'plain',
                body: [




                    [
                        {
                            dataKey: 'line', content: '',
                            colSpan: 1, rowSpan: 1, styles: { lineWidth: 0, lineColor: 'rgb(148, 186, 195)' }
                        },


                    ],
                ],
                didDrawCell: function (data) {

                    if (data?.cell?.raw?.dataKey === 'line') {
                        //add a line to the bottom of the cell with color lineColor: 'rgb(148, 186, 195)' and line width to 3
                        pdf.setLineWidth(1);
                        pdf.setDrawColor(148, 186, 195);
                        pdf.line(data.cell.x, data.cell.y + data.cell.height, data.cell.x + data.cell.width, data.cell.y + data.cell.height);

                    }


                },
                headStyles: { fontStyle: 'bold' },

                startY: wqBottom2 + 6,

                margin: 'auto',
                //width of table is the width of the cell
                tableWidth: 'auto',

            })
        }
        else {
            pdf.autoTable({
                head: [["Date", "Parameter", "Station", "Value", "Units"]],
                body: [

                    ...wqTable,


                    [
                        {
                            dataKey: 'line', content: '',
                            colSpan: 5, rowSpan: 1, styles: { lineWidth: 0, lineColor: 'rgb(148, 186, 195)' }
                        },


                    ],
                ],
                didDrawCell: function (data) {

                    if (data?.cell?.raw?.dataKey === 'line') {
                        //add a line to the bottom of the cell with color lineColor: 'rgb(148, 186, 195)' and line width to 3
                        pdf.setLineWidth(1);
                        pdf.setDrawColor(148, 186, 195);
                        pdf.line(data.cell.x, data.cell.y + data.cell.height, data.cell.x + data.cell.width, data.cell.y + data.cell.height);

                    }


                },
                headStyles: { fontStyle: 'bold' },

                startY: wqBottom2 + 3,
                margin: 1,
                theme: "striped",
                margin: 'auto',
                //width of table is the width of the cell
                tableWidth: 'auto',

            })

        }



        const eventwqBottom = pdf.lastAutoTable.finalY;


        pdf.autoTable({
            columns: ['', '', '', '', ''],
            styles: {
                padding: 0,
                overflow: 'linebreak',
                fontSize: 12,
                textColor: 'black',
                cellPadding: 1
            },
            body: [

                [
                    {
                        dataKey: 'wq', content: 'Water Quality (event monitoring)',
                        colSpan: 5, rowSpan: 1, styles: { textColor: 'black', fontStyle: 'bold', fontSize: 16, cellPadding: { top: 0, right: 0, bottom: 0, left: 0 }, }
                    },

                ],


            ],
            headStyles: { fontStyle: 'bold' },
            startY: eventwqBottom + 2,

            theme: "plain",
            margin: 'auto',
            //width of table is the width of the cell
            tableWidth: 'auto',

        })
        const eventwqBottom2 = pdf.lastAutoTable.finalY;
        const eventwqTable = [null, undefined].includes(eventWaterData) ? [] : eventWaterData.map((row) => {

            console.log(stations.map((station) => station.id))
            const stationsIndex = stations.map((station) => station.id.toString()).indexOf(row.nodeid.toString())

            const station = stations.find((station) => station.id === row.nodeid)

            row.locationLabel = station.name || ''
            row.parameterLabel = [...PPArray, ...parameters].find((parameter) => parameter.key === row.parameterid)?.name || ''
            return [moment(row.time, 'YYYY-MM-DD HH:mm').format('MM-DD-YYYY h:mm a'), row.parameterLabel, row.locationLabel, row.value, row.unitid || '']
        })

        //add text saying no data if wqdata has length of 0
        if (wqTable.length === 0) {
            pdf.text('No Water Quality Data', 15, eventwqBottom2 + 5, { fontSize: 12, fontStyle: 'bold' });

            pdf.autoTable({
                columns: [''],
                theme: 'plain',
                body: [




                    [
                        {
                            dataKey: 'line', content: '',
                            colSpan: 1, rowSpan: 1, styles: { lineWidth: 0, lineColor: 'rgb(148, 186, 195)' }
                        },


                    ],
                ],
                didDrawCell: function (data) {

                    if (data?.cell?.raw?.dataKey === 'line') {
                        //add a line to the bottom of the cell with color lineColor: 'rgb(148, 186, 195)' and line width to 3
                        pdf.setLineWidth(1);
                        pdf.setDrawColor(148, 186, 195);
                        pdf.line(data.cell.x, data.cell.y + data.cell.height, data.cell.x + data.cell.width, data.cell.y + data.cell.height);

                    }


                },
                headStyles: { fontStyle: 'bold' },

                startY: eventwqBottom2 + 6,

                margin: 'auto',
                //width of table is the width of the cell
                tableWidth: 'auto',

            })
        }
        else {
            pdf.autoTable({
                head: [["Date", "Parameter", "Station", "Value", "Units"]],
                body: [

                    ...eventwqTable,


                    [
                        {
                            dataKey: 'line', content: '',
                            colSpan: 5, rowSpan: 1, styles: { lineWidth: 0, lineColor: 'rgb(148, 186, 195)' }
                        },


                    ],
                ],
                didDrawCell: function (data) {

                    if (data?.cell?.raw?.dataKey === 'line') {
                        //add a line to the bottom of the cell with color lineColor: 'rgb(148, 186, 195)' and line width to 3
                        pdf.setLineWidth(1);
                        pdf.setDrawColor(148, 186, 195);
                        pdf.line(data.cell.x, data.cell.y + data.cell.height, data.cell.x + data.cell.width, data.cell.y + data.cell.height);

                    }


                },
                headStyles: { fontStyle: 'bold' },

                startY: eventwqBottom2 + 3,
                margin: 1,
                theme: "striped",
                margin: 'auto',
                //width of table is the width of the cell
                tableWidth: 'auto',

            })

        }






        const postwqBottom = pdf.lastAutoTable.finalY;


        pdf.autoTable({
            columns: ['', '', '', '', ''],
            styles: {
                padding: 0,
                overflow: 'linebreak',
                fontSize: 12,
                textColor: 'black',
                cellPadding: 1
            },
            body: [

                [
                    {
                        dataKey: 'wq', content: 'Water Quality (post-application monitoring)',
                        colSpan: 5, rowSpan: 1, styles: { textColor: 'black', fontStyle: 'bold', fontSize: 16, cellPadding: { top: 0, right: 0, bottom: 0, left: 0 }, }
                    },

                ],


            ],
            headStyles: { fontStyle: 'bold' },
            startY: postwqBottom + 2,

            theme: "plain",
            margin: 'auto',
            //width of table is the width of the cell
            tableWidth: 'auto',

        })
        const postwqBottom2 = pdf.lastAutoTable.finalY;
        const postwqTable = [null, undefined].includes(postWaterData) ? [] : postWaterData.map((row) => {

            console.log(stations.map((station) => station.id))
            const stationsIndex = stations.map((station) => station.id.toString()).indexOf(row.nodeid.toString())

            const station = stations.find((station) => station.id === row.nodeid)

            row.locationLabel = station.name || ''
            row.parameterLabel = [...PPArray, ...parameters].find((parameter) => parameter.key === row.parameterid)?.name || ''
            return [moment(row.time, 'YYYY-MM-DD HH:mm').format('MM-DD-YYYY h:mm a'), row.parameterLabel, row.locationLabel, row.value, row.unitid || '']
        })

        //add text saying no data if wqdata has length of 0
        if (wqTable.length === 0) {
            pdf.text('No Water Quality Data', 15, postwqBottom2 + 5, { fontSize: 12, fontStyle: 'bold' });

            pdf.autoTable({
                columns: [''],
                theme: 'plain',
                body: [




                    [
                        {
                            dataKey: 'line', content: '',
                            colSpan: 1, rowSpan: 1, styles: { lineWidth: 0, lineColor: 'rgb(148, 186, 195)' }
                        },


                    ],
                ],
                didDrawCell: function (data) {

                    if (data?.cell?.raw?.dataKey === 'line') {
                        //add a line to the bottom of the cell with color lineColor: 'rgb(148, 186, 195)' and line width to 3
                        pdf.setLineWidth(1);
                        pdf.setDrawColor(148, 186, 195);
                        pdf.line(data.cell.x, data.cell.y + data.cell.height, data.cell.x + data.cell.width, data.cell.y + data.cell.height);

                    }


                },
                headStyles: { fontStyle: 'bold' },

                startY: postwqBottom2 + 6,

                margin: 'auto',
                //width of table is the width of the cell
                tableWidth: 'auto',

            })
        }
        else {
            pdf.autoTable({
                head: [["Date", "Parameter", "Station", "Value", "Units"]],
                body: [

                    ...postwqTable,


                    [
                        {
                            dataKey: 'line', content: '',
                            colSpan: 5, rowSpan: 1, styles: { lineWidth: 0, lineColor: 'rgb(148, 186, 195)' }
                        },


                    ],
                ],
                didDrawCell: function (data) {

                    if (data?.cell?.raw?.dataKey === 'line') {
                        //add a line to the bottom of the cell with color lineColor: 'rgb(148, 186, 195)' and line width to 3
                        pdf.setLineWidth(1);
                        pdf.setDrawColor(148, 186, 195);
                        pdf.line(data.cell.x, data.cell.y + data.cell.height, data.cell.x + data.cell.width, data.cell.y + data.cell.height);

                    }


                },
                headStyles: { fontStyle: 'bold' },

                startY: postwqBottom2 + 3,
                margin: 1,
                theme: "striped",
                margin: 'auto',
                //width of table is the width of the cell
                tableWidth: 'auto',

            })

        }









        const newBottomForNotes = pdf.lastAutoTable.finalY + 10;






        pdf.setFont(undefined, 'bold')
        pdf.text('Notes', 15, newBottomForNotes + 5, { fontSize: 18, fontStyle: 'bold' });
        pdf.setFont(undefined, 'normal')



        const descriptionItem = log?.fieldNotes || 'No notes';

        var output = "";
        for (var i = 0; i < descriptionItem.length; i++) {
            if (descriptionItem.charCodeAt(i) <= 127) {
                output += descriptionItem.charAt(i);
            }
        }


        pdf.autoTable({

            columns: [''],
            body: [
                [{ dataKey: 'notes', content: output, colSpan: 1, rowSpan: 1, styles: {} }],
                [
                    {
                        dataKey: 'line', content: '',
                        colSpan: 1, rowSpan: 1, styles: { lineWidth: 0, lineColor: 'rgb(148, 186, 195)' }
                    },


                ],
            ],
            didParseCell: (data) => {


                if (data?.cell?.raw?.dataKey === 'line') {
                    data.row.height = 15;
                }


            },
            didDrawCell: function (data) {
                if (['line'].includes(data?.cell?.raw?.dataKey)) {
                    //add a line to the bottom of the cell with color lineColor: 'rgb(148, 186, 195)' and line width to 3
                    pdf.setLineWidth(1);
                    pdf.setDrawColor(148, 186, 195);
                    pdf.line(data.cell.x, data.cell.y + data.cell.height, data.cell.x + data.cell.width, data.cell.y + data.cell.height);

                }

            },
            startY: newBottomForNotes + 5,
            theme: 'plain',

            styles: {
                overflow: 'linebreak',
                halign: 'left',
            },
            columnStyles: {

            },
            headStyles: {
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
            },
        });



        const newBottom = pdf.lastAutoTable.finalY + 10;

        //if new bottom is within one inch of the bottom of the page, add a new page, and then adjust photos to be to top of page
        if (newBottom > 275) {
            pdf.addPage();

        }



        //add text of Photos
        pdf.setFont(undefined, 'bold')
        pdf.text('Photos', 15, newBottom > 275 ? 30 : newBottom + 5, { fontSize: 18, });
        pdf.setFont(undefined, 'normal')



        const photoList = photos.map((photo, index) => {
            return (
                { dataKey: photo.key, content: '', photo: photo, colSpan: 1, rowSpan: 1, styles: { lineWidth: 0, lineColor: 'rgb(148, 186, 195)', cellPadding: { top: 1, right: 1, bottom: 1, left: 1 }, } }
            )


        })

        //split photolist into an array of arrays of 2
        const photoListSplit = photoList.reduce((acc, val, i) => {
            if (i % 2 === 0) {
                acc.push([val]);
            } else {
                acc[acc.length - 1].push(val);
            }
            return acc;
        }, []);








        pdf.autoTable({
            pageBreak: 'auto',
            rowPageBreak: 'avoid',
            columns: ['', '',],
            body: photoListSplit,
            didParseCell: (data) => {


                if (data?.cell?.raw?.content === '') {
                    //find index of photo in photos array
                    const index = photos.findIndex(photo => photo.key === data?.cell?.raw?.dataKey)
                    const url = photos[index].url
                    const orientation = pdf.getImageProperties(url).height > pdf.getImageProperties(url).width ? 'portrait' : 'landscape';
                    const width = orientation === 'landscape' ? 80 : pdf.getImageProperties(url).width * 80 / pdf.getImageProperties(url).height;
                    const height = orientation === 'landscape' ? pdf.getImageProperties(url).height * 80 / pdf.getImageProperties(url).width : 80;
                    const rowHeight = height + 20;
                    const actualRowHeight = rowHeight > 85 ? rowHeight : 85;
                    data.row.height = actualRowHeight;
                }


            },
            didDrawCell: (data) => {

                if (data?.cell?.raw?.content === '') {
                    const index = photos.findIndex(photo => photo.key === data?.cell?.raw?.dataKey)
                    const url = photos[index].url
                    const orientation = pdf.getImageProperties(url).height > pdf.getImageProperties(url).width ? 'portrait' : 'landscape';
                    const width = orientation === 'landscape' ? 80 : pdf.getImageProperties(url).width * 80 / pdf.getImageProperties(url).height;
                    const height = orientation === 'landscape' ? pdf.getImageProperties(url).height * 80 / pdf.getImageProperties(url).width : 80;

                    pdf.addImage(url, 'PNG', data.cell.x + 2, data.cell.y, width, height);
                    //add text to bottom of photo

                    pdf.text(photos[index].description || "No Description", data.cell.x + 2, data.cell.y + height + 4, { fontSize: 11, });



                }
            },






            startY: newBottom > 275 ? 30 : newBottom + 5,
            theme: 'plain',

            styles: {
                fontSize: 12,
                halign: 'left',
                valign: 'top',
                padding: 0,
                overflow: 'linebreak',
                columnWidth: 'wrap',
            },
            columnStyles: {

            },
            headStyles: {
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
            },
        });





        //wait for downloadurl to be generated and then return it




        return pdf;


    }
    catch (error) {
        console.log(error)
        openSnack('error', 'Error generating PDF')

    }






};







