import { Button, IconButton, Tooltip as TooltipMUI } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { Col, Row } from 'antd';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import DateSelection from '../../../waterQualityNew/waterQualityDatabase/selectionDate';
import { getObscapeWeatherData, uploadObscapeData } from './obscapeFunctions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';





const GetHistoricalObscapeWeatherData = ({
    getHistoricalData,
    setGetHistoricalData,

    openSnack,
    previewChart,
    userInfo,
    screenWidth,
}) => {

    const handleClose = () => setGetHistoricalData(false);
    const [startDate, setStartDate] = useState(new Date(moment().subtract(1, 'days')));
    const [endDate, setEndDate] = useState(new Date());
    const [dateRange, setDateRange] = useState(`1day`);
    const [checkingForData, setCheckingForData] = useState(false);
    const [uploadingData, setUploadingData] = useState(false);
    const [data, setData] = useState([]);




    const handleSubmit = async () => {
        try {
            setCheckingForData(true);
            const from = moment(startDate).format('YYYY-MM-DDTHH:mm:ss')
            const to = moment(endDate).format('YYYY-MM-DDTHH:mm:ss')
            const data = await getObscapeWeatherData(userInfo, getHistoricalData, from, to);
            setCheckingForData(false);
            openSnack('success', 'Data retrieved')
            console.log(data)
            setData(data)
        }
        catch (error) {
            console.log(error)
            setCheckingForData(false);
            openSnack('error', 'Error getting data')
        }
    }



    return (
        <div>
            <Modal
                open={previewChart !== false}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{

                }}

                slotProps={{
                    backdrop: {
                        style: {
                            backgroundColor: 'transparent'
                        }
                    }
                }}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: screenWidth === 'xs' ? '90%' : '60%',
                    height: screenWidth === 'xs' ? '70vh' : '50vh',
                    bgcolor: 'background.paper',
                    border: '1px solid rgba(0, 0, 0, .3)',
                    boxShadow: 24,
                    p: screenWidth === 'xs' ? 1 : 4,
                    borderRadius: '24px',
                    boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.75)',
                }}>
                    <Row>
                        <Col xs={24} sm={16}>
                            <Typography id="modal-modal-title" variant="h6" fontWeight={700} fontSize={screenWidth === 'xs' ? 20 : 28} component="h2">
                                Get Historical Obscape Station data
                            </Typography>

                        </Col>
                        <Col xs={24} sm={16}>
                            <DateSelection startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} dateRange={dateRange} setDateRange={setDateRange} />

                        </Col>
                        <Col xs={24} sm={16} style={{ paddingTop: 10 }}>
                            {checkingForData ?
                                <FontAwesomeIcon icon={faSpinner} size="xl" color="#3f51b5" spin />

                                :
                                <Button color={'primary'} variant={'outlined'}
                                    style={{
                                        height: 40,
                                        textTransform: 'none',
                                        backgroundColor: 'white',
                                        border: '1px solid #3f51b5'
                                    }}
                                    onClick={handleSubmit}
                                >
                                    Check For Data
                                </Button>
                            }

                        </Col>
                        <Col xs={24} sm={16}>

                            <Typography id="modal-modal-title" variant="h6" fontWeight={500} fontSize={screenWidth === 'xs' ? 12 : 16} component="h2">
                                Found {data.length === 0 ? "no" : data.length} data points
                            </Typography>

                        </Col>

                        <Col xs={24} sm={16}>

                            {uploadingData ?
                                <FontAwesomeIcon icon={faSpinner} size="xl" color="#3f51b5" spin />

                                : <Button color={'primary'} variant={'contained'}
                                    style={{
                                        height: 40,
                                        textTransform: 'none',
                                        color: data.length > 0 ? 'white' : 'white',
                                        backgroundColor: data.length > 0 ? '#3f51b5' : 'rgba(0,0,0,0.5)'
                                    }}
                                    onClick={async () => {
                                        try {
                                            if (data.length === 0) {
                                                openSnack('error', 'No data to upload')
                                                return
                                            }
                                            setUploadingData(true)
                                            const res = await uploadObscapeData(userInfo, getHistoricalData, data)
                                            if (res === 'success') {
                                                openSnack('success', 'Data uploaded')
                                                setUploadingData(false)
                                                setData([])
                                            }
                                            else {
                                                openSnack('error', 'Error uploading data')
                                                setUploadingData(false)
                                            }
                                            

                                        }
                                        catch {
                                            setUploadingData(false)
                                        }
                                    }}
                                >
                                    Upload to database
                                </Button>
                            }
                        </Col>


                    </Row>





                </Box>
            </Modal>
        </div >
    );
}

export default GetHistoricalObscapeWeatherData;
