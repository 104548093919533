import { Button, Checkbox, Divider, OutlinedInput, Typography, Select, MenuItem, CircularProgress, Breadcrumbs, FormGroup, FormControlLabel, Autocomplete, Chip, TextField, IconButton, Fab, ButtonGroup } from '@mui/material';
import { Col, Popconfirm, Row } from 'antd';
import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { fullMobile } from './../util';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, } from 'firebase/firestore';

import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import uuid from 'react-uuid';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';

import DatePicker from "react-datepicker";
import queryString from 'query-string';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { queryPSItems } from '../../../firebase/config';
import { DeleteForever } from '@mui/icons-material';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { faTablets, faToolbox } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
var randomColor = require('randomcolor');



export default function PSEquipment(props) {
    const { userInfo, screenWidth, openSnack, company, account, item, setItem, updateItem } = props;

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);











    return (
        <Row gutter={[12, 12]} >
            <Col {...fullMobile(20, 20)} style={{ paddingTop: 10 }}>
                <Typography variant='h6' fontWeight={500} fontSize={20}>Equipment Item</Typography>



            </Col>
            <Col {...fullMobile(24, 24)} style={{ paddingTop: 10 }}>
                <Divider />
            </Col>
            <Col {...fullMobile(24, 24)} style={{}}>
                <Row align="middle" gutter={[4, 4]}>
                    <Col {...fullMobile(24, 24)} style={{}}>
                        <Typography variant="body1" fontWeight={500} component="h4"  >
                            Manufacturer
                        </Typography>

                    </Col>
                    <Col {...fullMobile(24, 24)} style={{}}>
                        <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"

                            value={item.equipmentManufacturer}
                            onChange={(e) => {
                                setItem({ ...item, equipmentManufacturer: e.target.value })
                            }} />
                    </Col>

                </Row>

            </Col>
            <Col {...fullMobile(24, 24)} style={{}}>
                <Row align="middle" gutter={[4, 4]}>
                    <Col {...fullMobile(24, 24)} style={{}}>
                        <Typography variant="body1" fontWeight={500} component="h4"  >
                            Model #
                        </Typography>

                    </Col>
                    <Col {...fullMobile(24, 24)} style={{}}>
                        <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"

                            value={item.equipmentModel}
                            onChange={(e) => {
                                setItem({ ...item, equipmentModel: e.target.value })
                            }} />
                    </Col>

                </Row>

            </Col>

            <Col {...fullMobile(24, 24)} style={{}}>
                <Row align="middle" gutter={[4, 4]}>
                    <Col {...fullMobile(24, 24)} style={{}}>
                        <Typography variant="body1" fontWeight={500} component="h4"  >
                            Additional Information
                        </Typography>

                    </Col>
                    <Col {...fullMobile(24, 24)} style={{}}>
                        <OutlinedInput fullWidth notched={false} label="none" placeholder={""} size="small"
                            multiline={true}
                            rows={4}
                            value={item.equipmentAdditional}
                            onChange={(e) => {
                                setItem({ ...item, equipmentAdditional: e.target.value })
                            }} />
                    </Col>

                </Row>

            </Col>



        </Row>
    )



}