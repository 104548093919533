
import { CircularProgress, Typography } from '@mui/material';
import HighchartsReact from "highcharts-react-official";
import highchartsMore from "highcharts/highcharts-more.js";
import Highcharts from "highcharts/highcharts.js";
import solidGauge from "highcharts/modules/solid-gauge.js";
import moment from 'moment';
import React from 'react';
import axios, * as others from 'axios';
var convert = require('convert-units')
highchartsMore(Highcharts);
solidGauge(Highcharts);



require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/data')(Highcharts);




export default class WidgetGauge extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            attributes: this.props.attributes,
            userInfo: this.props.userInfo,
            index: this.props.index,
            height: null,
            loading: true,
            series: {},
            queryInfo: {},
            chartOptions: {
                credits: {
                    enabled: false
                },
                chart: {
                    type: 'solidgauge',
                    backgroundColor: 'transparent'
                },

                title: null,

                pane: {
                    center: ['50%', '50%'],
                    size: '80%',
                    startAngle: -140,
                    endAngle: 140,
                    rounded: true,
                    background: {
                        backgroundColor: 'white',

                        borderWidth: 0,
                        outerRadius: '100%',
                        innerRadius: '70%',
                        shape: 'arc',

                    }
                },

                exporting: {
                    enabled: false
                },
                stockTools: {
                    gui: {
                        enabled: false
                    }
                },

                tooltip: {
                    enabled: false
                },

                // the value axis
                yAxis: {

                    stops:
                        [
                            [0.5, {
                                linearGradient: {
                                    x1: 0,
                                    x2: 1,
                                    y1: 0,
                                    y2: 0
                                },
                                stops: [
                                    [0, 'rgba(254, 73, 44, 0.9)'],
                                    [1, 'rgba(255, 208, 0, 0.9)']
                                ]
                            }],
                            [0.9, {
                                linearGradient: {
                                    x1: 0,
                                    x2: 1,
                                    y1: 0,
                                    y2: 0
                                },
                                stops: [
                                    [0, 'rgba(254, 73, 44, 0.9)'],
                                    [0.5, 'rgba(255, 208, 0, 0.9)'],
                                    [1, 'rgba(97, 201, 164, 0.9)']
                                ]
                            }]
                        ],
                    min: 0,
                    max: 100,



                    tickLength: 15,
                    tickColor: 'black',
                    labels: {
                        distance: 15

                    },
                },
                plotOptions: {
                    solidgauge: {
                        dataLabels: {
                            y: 5,
                            borderWidth: 0,
                            useHTML: true
                        },

                        stickyTracking: false,

                    }
                },

                legend: {
                    enabled: false
                },


                series: [{
                    name: 'Speed',
                    data: [{
                        color: Highcharts.getOptions().colors[0],
                        radius: '100%',
                        innerRadius: '70%',
                        y: 0
                    },],
                    dataLabels: {
                        format:
                            '<div style="text-align:center">' +
                            '<span style="font-size:24px;padding-bottom:6px"></span><br/>' +
                            '</div>'
                    },

                }]
            },

            capacityData: [],
            depthUnits: '',
            areaUnits: '',
            volumeUnits: '',


        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.attributes !== prevProps.attributes) {
            this.updateAttributes(this.props.attributes)
            this.setState({ attributes: this.props.attributes })
        }



    }
    componentDidMount() {
        const { attributes, chartOptions, queryInfo, series, } = this.state;
        this.setState({ height: this.container.offsetHeight, });
        this.updateChartSub('chart', 'height', this.container.offsetHeight)



        setTimeout(() => {
            this.updateAttributes(attributes)
        }, 500);




    }





    updateAttributes = (attributes) => {
        const { chartOptions } = this.state;

        const qi = {
            parameterid: attributes.parameterid,
            locationid: attributes.locationid,
            color: attributes.color || 'blue',
            colorDisplacement: attributes.colorDisplacement || 'orange',
            decimals: attributes.decimals || 0,
            suffix: attributes.suffix || '',
            offset: attributes.offset || 0,
        };


        this.queryData(qi)



        this.setState({ chartOptions })

    }





    updateChart = (boo, val) => {
        const { chartOptions } = this.state;
        chartOptions[boo] = val;
        this.setState({ chartOptions })

    }
    updateChartSub = (boo, a, val) => {
        const { chartOptions } = this.state;
        chartOptions[boo][a] = val;
        this.setState({ chartOptions })

    }





    queryData = (q) => {
        const { chartOptions, series, userInfo, capacityData, depthUnits, areaUnits, volumeUnits, index, attributes } = this.state;









        const functionNameMap = [
            "databaseFetch3",
            "databaseFetch4",
            "databaseFetch5",
        ]



        /* pick random number between 0 and 2 */
        const randomIndex = Math.floor(Math.random() * 3);
        const randomFunctionName = functionNameMap[randomIndex];
        const proxyUrl = "https://mycorslake.herokuapp.com/";

        axios.post(proxyUrl + `https://us-central1-aquasource3.cloudfunctions.net/${randomFunctionName}`, {
            raw: `SELECT * FROM ${attributes.dataType === 'probe' ? 'probe_data' : 'node_data_new2'}
            WHERE ${attributes.dataType === 'probe' ? 'locationid' : 'nodeid'} = '${q.locationid}'and parameterid = '${q.parameterid}'
            ORDER BY "time" DESC
            LIMIT 1;`
        })
            .then(async (response) => {

                console.log(response)

                q.data = [];
                q.tooltip = {};

                const r = { ...q };
                const d = response.data[0];
                const readingOffset = response.data[0].offsetvalue || 0;
                const offset = q.offset || 0;
                let value = q?.dataSourceType === 'reading' ? Number(response.data[0].value) :
                    q?.dataSourceType === 'readingWithOffset' ? Number(response.data[0].value) + Number(readingOffset) :
                        q?.dataSourceType === 'offset' ? Number(readingOffset) :
                            Number(response.data[0].value);

                value += offset;

                value = Number((value.toFixed(0)))




                const time = Number(moment(d.time, 'YYYY-MM-DD HH:mm:ss').format('X'));







                chartOptions.series[0].data[0].y = value;
                chartOptions.time = time;
                this.setState({ chartOptions, loading: false })









            })
            .catch((error) => {
                // handle error
                console.log(error);
                this.setState({ loading: false })


            })
            .then(function () {
                // always executed
            });

    }



    render() {

        const { account, chartOptions, hoverData, loading, height, dimensions, } = this.state;


        const Height = () => {
            if (this.container) {
                if (this.container.offsetHeight !== height) {
                    this.updateChartSub('chart', 'height', this.container.offsetHeight)
                    this.setState({ height: this.container.offsetHeight })
                }

            }

        }


        const Loading = () => { if (loading) { return <div className="loading"><CircularProgress /> <Typography variant="body1">Loading Data</Typography></div> } }

        //if loading true show loading screen
        if (loading) {
            return <div ref={el => (this.container = el)} style={{ position: 'relative', height: '90%', width: '100%', padding: 6, textAlign: 'center' }}>
                <Height /> <Loading /></div>
        } else {
            const { suffix } = this.state.attributes

            return (
                <div ref={el => (this.container = el)} style={{ position: 'relative', height: '90%', width: '100%', padding: 6, textAlign: 'center' }}>
                    <Height />
                    <Typography variant='subtitle1' fontSize={14} fontWeight={500}>{chartOptions.time !== undefined ? moment(chartOptions.time, 'X').format('LLL') : ''}</Typography>

                    <div style={{ position: 'relative' }}>
                        <div style={{ position: 'relative', top: '50%', left: 0, right: 0, bottom: 0, zIndex: 1, }}>
                            <HighchartsReact
                                constructorType={"chart"}
                                highcharts={Highcharts}
                                ref={"chart"}
                                options={chartOptions}
                                allowChartUpdate={true}
                                immutable={false}
                                updateArgs={[true, true, true]}
                            />
                            <div style={{ position: 'absolute', top: '40%', left: 0, right: 0, bottom: 0, zIndex: 2, paddingLeft: 5 }}>
                                <Typography display={'inline'} variant='h4' fontWeight={300} fontSize={32}
                                >{chartOptions.series[0].data[0].y}
                                    <Typography display={'inline'} fontWeight={suffix ? 500 : 300} fontSize={suffix ? 20 : 32}>{suffix || "%"} </Typography>
                                </Typography>

                            </div>
                            <div style={{ marginTop: '-6%', fontSize: '20px' }}>
                                <h1>{this.state.attributes.label}</h1>


                            </div>
                        </div>

                    </div>





                </div>

            );
        }
    }
} 