import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { IconButton } from '@mui/material';
import { Col, Layout, Row } from 'antd';
import { doc, getDoc, getFirestore, setDoc } from "firebase/firestore";
import React from 'react';
import DashboardContent from './dashboard';
import SideBar from './sideBar';

const { Header, Footer, Sider, Content } = Layout;

export default class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            company: this.props.company,
            type: this.props.type,
            preferences: { sideBar: true },
            loadContent: false
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
        if (this.props.company !== prevProps.company) {
            this.setState({ company: this.props.company })
        }
        if (this.props.type !== prevProps.type) {
            this.setState({ type: this.props.type })
        }

    }
    componentDidMount() {
        window.scrollTo(0, 0);
        const { userInfo, type } = this.state;
        if (type !== 'open') {
            this.getPreferences(userInfo.id)
        }
        else {
            this.setState({ loadContent: true })
        }

    }

    getPreferences = async (uid) => {
        const db = getFirestore();
        const { userInfo } = this.state;

        const docRef = doc(db, "users", uid, 'preferences', 'newDash');
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {

            this.setState({ preferences: docSnap.data(), })
            setTimeout(() => {
                this.setState({ loadContent: true })
            }, 500);
        } else {
            this.setState({ preferences: { sideBar: true } })
            setTimeout(() => {
                this.setState({ loadContent: true })
            }, 500);
            await setDoc(docRef, { sideBar: true })
        }
    }
    updatePreferences = async (preferences) => {
        const db = getFirestore();
        const { userInfo } = this.state;

        const docRef = doc(db, "users", userInfo.id, 'preferences', 'newDash');
        const docSnap = await getDoc(docRef);

        await setDoc(docRef, preferences)
    }



    render() {

        const { account, preferences, screenWidth, userInfo, loadContent, type, company } = this.state;

      

        return (

            <Layout>


                <Content style={{ backgroundColor: 'white', minHeight: '100vh', }}>

                    <Row>

                        < Col span={24}>
                            {Object.values(account).length > 0 && loadContent && (<DashboardContent goBack={this.props.goBack} type={type} account={account} userInfo={userInfo} screenWidth={screenWidth} openSnack={this.props.openSnack} />)}
                        </Col>

                    </Row>


                </Content>
                


            </Layout >
        );
    }
}

