import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { Divider, Fab, IconButton, Paper, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Col, Popconfirm, Row } from 'antd';
import { collection, doc, getDocs, getFirestore, query, setDoc, updateDoc, where } from 'firebase/firestore';
import MUIDataTable from "mui-datatables";
import React from 'react';
import { useNavigate } from 'react-router-dom';
import "../extra/hoverStyle.css";

export default class Items extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            data: [],
            filteredData: [],
            filters: [],
            searchText: '',
            loading: true,
            type: this.props.type,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0)
        this.queryData()
    }

    queryData = async () => {
        const { userInfo, type } = this.state;
        const db = getFirestore();
        const queryData = query(collection(db, "clients", userInfo.currentCompany, 'psItems'), where('psType', '==', type), where('archived', '==', false));
        const data = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            data.push([
                [doc.data().name || '',
                doc.data().description || '',],
                doc.data().key || '',
                doc.data().key || ''

            ]
            )


        });

        data.sort((a, b) => a[0][0].localeCompare(b[0][0]))
        this.setState({ data, loading: false })


    }



    render() {

        const { userInfo, account, data, filteredData, loading, searchText, filters, type } = this.state;
        const db = getFirestore();
        const CreateItemButton = () => {
            let navigate = useNavigate();

            return (
                <Fab style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="medium"
                    onClick={async () => {
                        const itemRef = doc(db, "users", userInfo.id, 'currentItems', 'currentPS');
                        await setDoc(itemRef, {
                            key: 'none'
                        }).then(() => {
                            navigate(type === 'services' ? '/serviceItem' : '/productItem')
                        })
                    }}>
                    <AddIcon />
                </Fab>
            )
        }







        const XTable = ({ columns, header, data }) => {
            let navigate = useNavigate();
            const getMuiTheme = () =>
                createTheme({
                    components: {
                        MUIDataTable: {
                            styleOverrides: {
                                root: {
                                    padding: '8px',
                                    boxShadow: 'none'
                                }
                            }
                        },
                        MuiTableRow: {
                            styleOverrides: {
                                root: {
                                    cursor: 'pointer',
                                    fontSize: '10px'

                                },
                                hover: {
                                    backgroundColor: 'green'
                                }

                            }
                        },
                        MuiTableCell: {
                            styleOverrides: {
                                root: {
                                    fontSize: '12px',
                                    padding: '6px'
                                }
                            }
                        }

                    }
                });




            const xColumns = [
                {
                    name: 'Name',
                    options: {
                        filter: false,

                        customBodyRender: this.renderValue = (a, b) => {
                            return (
                                <div onClick={async () => {
                                    const db = getFirestore()

                                    console.log(a)
                                    console.log(b)
                                    const itemRef = doc(db, "users", userInfo.id, 'currentItems', 'currentPS');
                                    await setDoc(itemRef, {
                                        key: b.rowData[1]
                                    }).then(() => {
                                        navigate(type === 'services' ? '/serviceItem' : '/productItem')
                                    })
                                }}>
                                    <Typography variant="h4" fontSize={16} fontWeight={600}>{a[0]}</Typography>
                                    <Typography variant="body1" fontSize={13} >{a[1]}</Typography>
                                </div>
                            )
                        }
                    }
                },


                {
                    name: '',
                    options: {
                        filter: false,
                        viewColumns: false,
                        customBodyRender: this.renderValue = (a) => {
                            return (
                                <IconButton style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="small" itemKey={a}
                                    onClick={async () => {
                                        const itemRef = doc(db, "users", userInfo.id, 'currentItems', 'currentPS');
                                        await setDoc(itemRef, {
                                            key: a
                                        }).then(() => {
                                            navigate(type === 'services' ? '/serviceItem' : '/productItem')
                                        })
                                    }}>
                                    <EditIcon />
                                </IconButton>
                            )
                        }
                    },
                },
                {
                    name: '',
                    options: {
                        filter: false,
                        viewColumns: false,
                        customBodyRender: this.renderValue = (a) => {
                            return (
                                <Popconfirm itemKey={a}
                                    title="Are you sure to delete this item?"
                                    onConfirm={async () => {
                                        const itemRef = doc(db, "clients", userInfo.currentCompany, 'psItems', a);
                                        await updateDoc(itemRef, {
                                            archived: true
                                        }).then(() => {
                                            const newData = filteredData.filter((f) => f.key !== a)
                                            this.setState({ filteredData: newData })
                                        })

                                    }}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <IconButton style={{ textTransform: 'none' }} fullWidth variant="contained" color="primary" size="small" itemKey={a}
                                    >
                                        <DeleteForeverIcon />
                                    </IconButton>
                                </Popconfirm>
                            )
                        }
                    },
                },
            ]
 


            return (
                <ThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        title={''}
                        data={this.state.data}
                        columns={xColumns}

                        options={{
                            customSort: (data, colIndex, order, meta) => {
                                console.log(data)
                                return data.sort((a, b) => {
                                    console.log(a)
                                    return (a.data[0][0].localeCompare(b.data[0][0])) * (order === 'desc' ? 1 : -1);
                                });
                            },
                            selectableRows: false,
                            async onRowClick(a, b) {
                                console.log(a);
                                console.log(b);
                                console.log(a[a.length - 1].props.itemKey)


                            }
                        }}

                    />
                </ThemeProvider>
            );
        }

        return (
            <Row style={{}} align="middle">
                <Col span={23}>
                    <Row align="middle">
                        <Col xs={16} sm={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>
                            <Typography variant="h4">Your {type === 'services' ? 'Services' : 'Products'}</Typography>
                        </Col>
                        <Col xs={6} sm={4} style={{ paddingTop: 30, paddingBottom: 15, textAlign: 'right' }}>
                            <CreateItemButton />
                        </Col>
                        <Col span={24}>
                            <Divider />
                        </Col>
                    </Row>
                </Col>



                <Col span={23} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 30 }}>
                    <Paper elevation={16} style={{ padding: 20, borderRadius: 12 }}>
                        <Row>



                            <Col span={24}>
                                <XTable />
                            </Col>
                        </Row>
                    </Paper>
                </Col>




            </Row>
        );
    }
} 