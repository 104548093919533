import { CircularProgress, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { fullMobile } from './../../util';
import * as htmlToImage from 'html-to-image';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, } from 'firebase/firestore';

import axios from 'axios';
var convert = require('convert-units')
export default function LogWeather(props) {
    const { userInfo, screenWidth, openSnack, company, account, log, setLog, updateItem, updateWeatherProps, weatherPropsUpdate } = props;
    const [loading, setLoading] = useState(true);
    const [weather, setWeather] = useState({});
    const [icon, setIcon] = useState('');
    const [updateWeather, setUpdateWeather] = useState(null)



    useEffect(() => {

        if (log.weather !== undefined) {
            setWeather(log.weather)
            console.log(log.weather.weathericon || log.weather.icon)
            setIcon(log.weather.weathericon || log.weather.icon)
            setLoading(false)
            weatherPropsUpdate({ ...null })
            console.log(log.weather)
            setTimeout(async () => {
                const response = await getWeatherImage();
                console.log(response)
                const updateWeather = { ...log.weather, weatherImage: response }
                console.log(updateWeather)
                console.log(log)
                updateItem('weather', updateWeather)
                setWeather(updateWeather)
            }, 2000);

        } else {
            getWeather(log.startDate)
        }



    }, [])
    useEffect(() => {
        console.log(updateWeatherProps)
        if (updateWeatherProps !== null && Object.keys(updateWeatherProps).length > 0) {
            getWeather(updateWeatherProps)
            setLoading(true)
        }
    }, [updateWeatherProps])





    const getWeatherImage = async () => {
        console.log('GET WEATHER IMAGE')
        const node = document.getElementById('my-node');
        const dataUrl = await htmlToImage.toPng(node);


        return dataUrl
    }






    function getWeather(date) {
        var dateFormat = moment(date, 'x').format('MM/DD/YYYY h:mm a');

        var dateTime = moment(dateFormat, 'MM/DD/YYYY h:mm a').format('X');

        const itemAccount = log.account || {};


        const proxyUrl = "https://mycorslake.herokuapp.com/";




        //if date is within 4 hours of current time, use openweather api

        if (Math.abs(moment().diff(moment(date, 'x'), 'hours')) < 4) {
            console.log('using openweather')
            console.log(account);
            console.log(itemAccount)
            axios.get(proxyUrl + 'https://api.openweathermap.org/data/2.5/weather?', {
                params: {
                    lat: itemAccount.lat || account.lat,
                    lon: itemAccount.lng || account.lng,
                    appid: '30573b68170d7f4400c7ac9c1c671ccf',
                    units: 'imperial',
                    dt: Number(dateTime),
                }
            })
                .then(async (response) => {

                    const weather = { ...response.data, };
                    console.log(weather)
                    var icon = response.data.weather[0].icon;
                    var index = Math.round(((weather.wind.deg %= 360) < 0 ? weather.wind.deg + 360 : weather.wind.deg) / 45) % 8;
                    const windDirection = ["North", "North-West", "West", "South-West", "South", "South-East", "East", "North-East"][index];

                    // Finally tack on the prefix.
                    weather.windDirection = windDirection;
                    weather.wind_speed = weather.wind.speed;
                    weather.humidity = weather.main.humidity;
                    weather.weatherImage = 'none';
                    weather.description = weather.weather[0].description;
                    weather.weathericon = icon;
                    //convert to fahrenheit
                    weather.temp = weather.main.temp;
                    console.log(weather)
                    updateItem('weather', weather)
                    setWeather(weather)
                    console.log(icon)
                    setIcon(icon)
                    setLoading(false)

                    weatherPropsUpdate(null)
                    console.log('GET IMAGE')
                    setTimeout(async () => {
                        const response = await getWeatherImage();
                        console.log(response)
                        const updateWeather = { ...weather, weatherImage: response }
                        console.log(updateWeather)
                        updateItem('weather', updateWeather)
                        setWeather(updateWeather)
                    }, 2000);



                })
                .catch((error, message) => {
                    console.log(error)
                })


        }

        //if date is more than 4 hours ago, use firebase
        if (Math.abs(moment().diff(moment(date, 'x'), 'hours')) > 4) {
            axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgetsFire3/sqlRead', {
                raw: `SELECT * FROM weather_data WHERE account = '${userInfo.currentAccount}' AND time >= '${moment(date, 'x').format('YYYY-MM-DD HH:mm:ss')}'
                  ORDER BY time LIMIT 1;`

            })
                .then(async (response) => {
                    console.log(response.data)
                    console.log('we here?')
                    if (response.data.length > 0) {
                        console.log('or here?')
                        const weather = { ...response.data[0] };
                        var index = Math.round(((weather.wind_deg %= 360) < 0 ? weather.wind_deg + 360 : weather.wind_deg) / 45) % 8;
                        const windDirection = ["North", "North-West", "West", "South-West", "South", "South-East", "East", "North-East"][index];

                        // Finally tack on the prefix.
                        weather.windDirection = windDirection;
                        weather.weatherImage = 'none';
                        weather.description = weather.weathermain;
                        //convert to fahrenheit
                        weather.temp = Math.round((weather.temp * 9 / 5) + 32);


                        updateItem('weather', weather)
                        setWeather(weather)
                        setIcon(weather.weathericon)
                        setLoading(false)
                        weatherPropsUpdate(null)
                        setTimeout(async () => {
                            const response = await getWeatherImage();
                            console.log(response)
                            const updateWeather = { ...weather, weatherImage: response }
                            console.log(updateWeather)
                            updateItem('weather', updateWeather)
                            setWeather(updateWeather)
                        }, 2000);


                    }
                    else {
                        //no data found
                        console.log('no data found')
                        setWeather({})
                        setIcon('')
                        setLoading(false)
                        weatherPropsUpdate(null)
                        setTimeout(async () => {
                            const response = await getWeatherImage();
                            console.log(response)
                            const updateWeather = { ...weather, weatherImage: response }
                            console.log(updateWeather)
                            updateItem('weather', updateWeather)
                            setWeather(updateWeather)
                        }, 2000);

                    }

                })
                .catch((error) => {
                    console.log('we here2?')




                })
        }
















    }


    var dict = {
        '01d': 'wi-day-sunny',
        '02d': 'wi-day-cloudy',
        '03d': 'wi-cloud',
        '04d': 'wi-cloudy',
        '09d': 'wi-showers',
        '10d': 'wi-day-rain-mix',
        '11d': 'wi-thunderstorm',
        '13d': 'wi-snow',
        '50d': 'wi-fog',
        '01n': 'wi-night-clear',
        '02n': 'wi-night-alt-cloudy',
        '03n': 'wi-night-alt-cloudy-high',
        '04n': 'wi-cloudy',
        '09n': 'wi-night-alt-sprinkle',
        '10n': 'wi-night-alt-showers',
        '11n': 'wi-night-alt-thunderstorm',
        '13n': 'wi-night-alt-snow',
        '50n': 'wi-night-fog'
    };


    if (loading === true) {
        return (
            <Row align="middle" gutter={[8, 8]} style={{ paddingTop: 10 }} >
                <Col {...fullMobile(6, 12)}>
                    <CircularProgress />
                </Col>
            </Row>
        )

    }
    else {

        const temperature = weather.temp > 0 && weather.temp < 150 ? weather.temp : convert(weather.temp).from('K').to('F');

        return (
            <Row align="middle" gutter={[8, 8]} style={{ paddingTop: 10 }} >
                <Col {...fullMobile(12, 12)}>
                    <Typography variant='h4'>{temperature.toFixed(0)} F</Typography>
                    <Typography variant='h6' style={{ textTransform: 'capitalize' }}>{weather.description}</Typography>
                    <Typography variant='body1' style={{}}>Wind: {convert(weather.wind_speed).from('m/s').to('m/h').toFixed(0)} mph {weather.windDirection || ''}</Typography>
                    < Typography variant='body1' style={{ textTransform: 'capitalize' }}>{weather.humidity}% Humidity</Typography>
                </Col>
                <Col {...fullMobile(12, 12)} style={{ textAlign: 'center', justifyContent: 'center', alignContent: 'center', fontSize: 48 }}  >
                    <i style={{ textAlign: 'center', justifyContent: 'center', alignContent: 'center', }} id='my-node' class={`wi ${dict[icon]}`}></i>
                </Col>

            </Row>

        );
    }



}





