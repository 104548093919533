import { Button, Checkbox, Divider, OutlinedInput, Typography, Select, MenuItem, CircularProgress, Paper, Input, FormControl, TextField, IconButton, Box, Modal } from '@mui/material';
import { Col, Popconfirm, Row } from 'antd';
import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { fullMobile } from './util';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where, setDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, } from 'firebase/firestore';

import MUIDataTable from "mui-datatables";
import uuid from 'react-uuid';
import DataColorCompact from '../../account/account/dashboard/settings/dataColorCompact';
import axios, * as others from 'axios';
import queryString from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { createAlert, createOffset, deleteNewAlert, deleteOffsets, getAlerts, getStaff, updateNewAlert, updateOffset } from './firestore';
import AlertsList from './AlertsList';
import AlertsTable from './AlertsTable';
import ProbeParameters from '../../extra/probeParameters.json';
import OffsetsTable from './offsets/offsetsTableHome';
import GridExample from './offsets/offsetTableTest';

const NodeOffsets = ({ currentChild, currentItem, userInfo, offsets, setOffsets, parameters, openSnack }) => {

    const [showModal, setShowModal] = useState(false);
    const [staff, setStaff] = useState([]);
    const handleOpen = () => setShowModal(true);
    const handleClose = () => {
        setShowModal(false);

    }





    async function addOffset() {
        const item = {
            id: uuid(),
            name: `New offset ${offsets.length + 1}`,
            node: currentChild.id,
            station: currentItem.id,
            company: userInfo.currentCompany,
            account: userInfo.currentAccount,
            createdDate: moment().format('MM/DD/YYYY HH:mm:ss'),
            onGoing: false,
            createdBy: userInfo.id,
            parameter: null,
            offsetValue: '',
        }
        await createOffset(item);
        const newOffsets = [item, ...offsets];
        setOffsets(newOffsets);



    }

    async function deleteItems(items) {
        console.log(items)
        await deleteOffsets(items);
        console.log(offsets)
        const newOffsets = offsets.filter((item) => !items.map(i => i.id).includes(item.id));
        console.log(newOffsets)
        setOffsets(newOffsets);

    }

    async function duplicateAlert(item) {
        const newItem = {
            ...item,
            id: uuid(),
            name: `${item.name} (Copy)`,
            createdDate: moment().format('MM/DD/YYYY HH:mm:ss'),
            createdBy: userInfo.id,
        }
        await createOffset(newItem);
        const newOffsets = [...offsets, newItem];
        setOffsets(newOffsets);

    }

    async function updateItem(item, openSnack, offsets) {
        try {
           
            updateOffset(item, openSnack);

           

            // Update logic here if no overlap
            // For example: setOffsets([...offsets, item]); or similar logic to update the state

        } catch (error) {
            console.log(error)

        }
    }




    return (
        <Row>
            <Col span={24}>
                <Button
                    fullWidth
                    variant="outlined"
                    color="primary"
                    style={{
                        border: '1px solid #404050',
                        textTransform: 'none',
                        color: '#404050',

                    }}
                    onClick={() => setShowModal(true)}
                >Edit Offsets</Button>

            </Col>
            <Col span={24}>

            </Col>

            <Modal
                open={showModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                bodyStyle={{ backgroundColor: 'white', padding: 0 }}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '75vw',
                    height: 600,
                    bgcolor: 'background.paper',
                    border: '1px solid #404050',
                    boxShadow: 24,
                    p: 0,
                    padding: 0
                }}>
                    <Row>
                        <Col span={12} style={{ padding: 12 }}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Node Offsets ({offsets.length})
                            </Typography>
                        </Col>
                        <Col span={12} style={{ textAlign: 'right', paddingBottom: 20, padding: 12 }}>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{
                                    backgroundColor: '#404050',
                                    textTransform: 'none',
                                    color: 'white',

                                }}
                                onClick={() => addOffset()}
                            >Create Offset +</Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                style={{
                                    border: '1px solid #404050',
                                    textTransform: 'none',
                                    color: '404050',
                                    marginLeft: '5px'

                                }}
                                onClick={() => handleClose()}
                            >Save Offsets</Button>
                        </Col>
                        <Col span={24}
                            style={{
                                overflowY: 'scroll',
                                height: 500,
                            }}>
                            {offsets.length === 0 ? (
                                <div style={{ textAlign: 'center' }}>
                                    <Typography variant="h6" component="h2">
                                        No Offsets for this node
                                    </Typography>
                                </div>
                            ) : (
                                <OffsetsTable
                                    offsets={offsets}
                                    setOffsets={setOffsets}
                                    parameters={parameters}
                                    deleteOffsets={deleteItems}
                                    updateOffset={updateItem}
                                    openSnack={openSnack}
                                />
                            )}
                        </Col>



                    </Row>


                </Box>
            </Modal>

        </Row>

    )
}

export default NodeOffsets;