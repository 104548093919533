import React from "react";


import { Button, ButtonGroup } from '@mui/material';
import { Col, Row } from 'antd';
import './list.css';
import SearchComponent from "./searchComponent";



const TableHeader = (
    {
        searchQuery,
        setSearchQuery,
        activeTab,
        setActiveTab,
        screenWidth,
        prefKey
    }

) => {


    const props = {
        searchQuery,
        setSearchQuery,
    }



    return (
        <Row style={{}}>
            <Col span={24}>


                <Row >
                    <Col xs={24} sm={12} style={{
                        height: '70px', display: 'flex', alignItems: 'center', justifyContent: 'left',
                        borderBottom: screenWidth === 'xs' ? 'none' :
                            '1px solid rgba(0,0,0,0.2)', paddingLeft: screenWidth === 'xs' ? 10 : 0,
                        paddingLeft: 5
                    }}>
                        <SearchComponent searchQuery={searchQuery}
                            setSearchQuery={setSearchQuery}
                        />

                    </Col>

                    <Col xs={24} sm={12} style={{
                        height: '70px', display: 'flex', alignItems: 'center', justifyContent: 'right',
                        borderBottom: screenWidth === 'xs' ? 'none' :
                            '1px solid rgba(0,0,0,0.2)', paddingLeft: screenWidth === 'xs' ? 10 : 0
                    }}>


                    </Col>

                </Row>

            </Col >



        </Row >
    );
};

export default TableHeader;
