import React from "react";
import { FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { Col, Row } from 'antd';

const CustomHeaderNumberFormat = ({
    screenWidth,
    headers,
    customHeader,
    setCustomHeader,
}) => {
    const getPreview = () => {
        const decimals = customHeader?.decimals || 0;
        return (100).toFixed(decimals);
    };

    return (
        <Row>
            <Col xs={24} sm={24} style={{ marginTop: 20 }}>
                <Row>
                    <Col xs={24} sm={4} >
                        <Typography variant="h6" component="div" color={'rgba(0,0,0,0.6)'} fontWeight={600} fontSize={16}>
                            Decimals
                        </Typography>
                        <Select
                            style={{
                                borderRadius: 12,
                                marginTop: screenWidth === 'xs' ? 2 : 5,
                                marginBottom: 20,
                                fontSize: 20,
                                width: '100%',
                                whiteSpace: 'nowrap',
                                display: 'inline-block',
                            }}
                            sx={{
                                '& .MuiSelect-root': {
                                    fontSize: 24,
                                    fontWeight: 600,
                                }
                            }}
                            renderValue={(value) => {
                                return (
                                    <Typography variant="h6" component="div" color={'rgba(0,0,0,0.6)'} fontWeight={600} fontSize={18}>
                                        {value}
                                    </Typography>
                                )
                            }}
                            fullWidth
                            placeholder="Select"
                            variant="outlined"
                            value={customHeader?.decimals || 0}
                            onChange={(e) => {
                                setCustomHeader({ ...customHeader, decimals: e.target.value })
                            }}
                        >
                            {[0, 1, 2, 3, 4, 5, 6].map(item => (
                                <MenuItem
                                    sx={{ p: 1 }}
                                    key={item}
                                    value={item}
                                >
                                    <Typography variant="h6" component="div" color={'rgba(0,0,0,0.6)'} fontWeight={600} fontSize={16}>
                                        {item}
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Select>
                    </Col>
                    <Col xs={24} sm={{ span: 10, offset: 2 }} >
                        <Typography variant="h6" component="div" color={'rgba(0,0,0,0.6)'} fontWeight={600} fontSize={16}>
                            Preview
                        </Typography>
                        <Typography variant="body1" component="div" color={'rgba(0,0,0,0.6)'} fontWeight={600} fontSize={16}
                            style={{

                                marginTop: screenWidth === 'xs' ? 5 : 10,
                                marginBottom: 20,
                                fontSize: 24,
                                width: '100%',
                                whiteSpace: 'nowrap',
                                display: 'inline-block',
                                color: 'black'

                            }}>
                            {getPreview()}
                        </Typography>
                    </Col>
                </Row>
            </Col >
        </Row >
    );
};

export default CustomHeaderNumberFormat;
