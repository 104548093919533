import { Col, Row } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

export default class AccountTools extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
    }
    componentDidMount() {
    }

    render() {
        return (
            <Row gutter={[16, 16]} style={{ paddingTop: 20, fontSize: 16, fontFamily: 'Roboto, sans-serif', color: 'rgba(0,0,0,0.8)' }}>
                <Col span={6} offset={2}>
                    <b>Account Tools</b>
                    <hr />
                    <div style={{ fontSize: 15, listStyleType: 'none', color: 'rgba(0,0,0,0.8)' }}>
                        <li style={{ paddingBottom: 4 }} onClick={this.props.closeTools}>
                            <Link style={{ textDecoration: 'none', cursor: 'pointer', width: '100%', color: 'rgba(0,0,0,0.8)' }} to="/account">
                                Home
                            </Link>
                        </li>

                        <li style={{ paddingBottom: 4 }} onClick={this.props.closeTools}>
                            <Link style={{ textDecoration: 'none', cursor: 'pointer', width: '100%', color: 'rgba(0,0,0,0.8)' }} to="/accountDirectory">
                                Account Directory
                            </Link>
                        </li>
                        <li style={{ paddingBottom: 4 }} onClick={this.props.closeTools}>
                            <Link style={{ textDecoration: 'none', cursor: 'pointer', width: '100%', color: 'rgba(0,0,0,0.8)' }} to="/accountDistribution">
                                Account Distribution
                            </Link>
                        </li>
                        <li style={{ paddingBottom: 4 }} onClick={this.props.closeTools}>
                            <Link style={{ textDecoration: 'none', cursor: 'pointer', width: '100%', color: 'rgba(0,0,0,0.8)' }} to="/accountInfo">
                                Settings
                            </Link>
                        </li>
                    </div>
                </Col>
                <Col span={6}>
                    <b>Core Tools</b>
                    <hr />
                    <div style={{ fontSize: 15, listStyleType: 'none' }}>

                        <li style={{ paddingBottom: 4 }} onClick={this.props.closeTools}>
                            <Link style={{ textDecoration: 'none', cursor: 'pointer', width: '100%', color: 'rgba(0,0,0,0.8)' }} to="/accountMap">
                                Map
                            </Link>

                        </li>
                        <li style={{ paddingBottom: 4 }} onClick={this.props.closeTools}>
                            <Link style={{ textDecoration: 'none', cursor: 'pointer', width: '100%', color: 'rgba(0,0,0,0.8)' }} to="/accountFieldLogs">
                                Field Logs
                            </Link>

                        </li>
                        <li style={{ paddingBottom: 4 }} onClick={this.props.closeTools}>
                            <Link style={{ textDecoration: 'none', cursor: 'pointer', width: '100%', color: 'rgba(0,0,0,0.8)' }} to="/accountInventory">
                            Product Inventory
                            </Link>

                        </li>
                        <li style={{ paddingBottom: 4 }} onClick={this.props.closeTools}>
                            <Link style={{ textDecoration: 'none', cursor: 'pointer', width: '100%', color: 'rgba(0,0,0,0.8)' }} to="/accountServicesInventory">
                                Services Inventory
                            </Link>

                        </li>

                        <li style={{ paddingBottom: 4 }} onClick={this.props.closeTools}>
                            <Link style={{ textDecoration: 'none', cursor: 'pointer', width: '100%', color: 'rgba(0,0,0,0.8)' }} to="/equipmentList">
                                Equipment
                            </Link>
                        </li>
                        <li style={{ paddingBottom: 4 }} onClick={this.props.closeTools}>
                            <Link style={{ textDecoration: 'none', cursor: 'pointer', width: '100%', color: 'rgba(0,0,0,0.8)' }} to="/documents">
                                Documents
                            </Link>
                        </li>
                        <li style={{ paddingBottom: 4 }} onClick={this.props.closeTools}>
                            <Link style={{ textDecoration: 'none', cursor: 'pointer', width: '100%', color: 'rgba(0,0,0,0.8)' }} to="/photoAlbums">
                                Photos
                            </Link>
                        </li>





                    </div>
                </Col>
                <Col span={6}>
                    <b>Water Quality</b>
                    <hr />
                    <div style={{ fontSize: 15, listStyleType: 'none' }}>

                        <li style={{ paddingBottom: 4 }} onClick={this.props.closeTools}>
                            <Link style={{ textDecoration: 'none', cursor: 'pointer', width: '100%', color: 'rgba(0,0,0,0.8)' }} to="/uploadData">
                                Upload Data
                            </Link>
                        </li>


                        <li style={{ paddingBottom: 4 }} onClick={this.props.closeTools}>
                            <Link style={{ textDecoration: 'none', cursor: 'pointer', width: '100%', color: 'rgba(0,0,0,0.8)' }} to="/references">
                                References
                            </Link>
                        </li>
                        <li style={{ paddingBottom: 4 }} onClick={this.props.closeTools}>
                            <Link style={{ textDecoration: 'none', cursor: 'pointer', width: '100%', color: 'rgba(0,0,0,0.8)' }} to="/masterData">
                                Query Data
                            </Link>
                        </li>
                        <li style={{ paddingBottom: 4 }} onClick={this.props.closeTools}>
                            <Link style={{ textDecoration: 'none', cursor: 'pointer', width: '100%', color: 'rgba(0,0,0,0.8)' }} to="/samplingSites">
                                Sampling Sites
                            </Link>
                        </li>
                        <li style={{ paddingBottom: 4 }} onClick={this.props.closeTools}>
                            <Link style={{ textDecoration: 'none', cursor: 'pointer', width: '100%', color: 'rgba(0,0,0,0.8)' }} to="/probes">
                                Probes
                            </Link>
                        </li>

                        <li style={{ paddingBottom: 4 }} onClick={this.props.closeTools}>
                            <Link style={{ textDecoration: 'none', cursor: 'pointer', width: '100%', color: 'rgba(0,0,0,0.8)' }} to="/waterQualitySettings">
                                Settings
                            </Link>
                        </li>



                    </div>
                </Col>

            </Row>
        );
    }
} 