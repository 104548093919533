import { Paper, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import React from 'react';

export default class GraphicSpatialList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
            type: this.props.type,
            item: this.props.lineItem,
            index: this.props.index,
            loading: true,
            graphics: this.props.graphics,
        };
    }

    componentDidUpdate(prevProps) {

        if (this.props.graphics !== prevProps.graphics) {
            
            this.setState({ graphics: this.props.graphics })
        }

    }

    componentDidMount() {
        const { item } = this.state;
    }




    render() {

        const { item, graphics } = this.state;




        return (
            <Row gutter={[12, 12]} align="middle">

                {graphics.map((g) => {
                    return (
                        <Col xs={12} sm={8}>
                            <Paper elevation={6}>
                                <Row style={{ padding: 4 }} >

                                    <Col span={24}>
                                        <Typography fontSize={11} variant="body1">Area: {g.attributes.area.toFixed(2)} acres</Typography>
                                    </Col>
                                    <Col span={24}>
                                        <Typography fontSize={11} variant="body1">Perimeter: {g.attributes.length.toFixed(0)} feet</Typography>
                                    </Col>
                                    <Col span={24}>
                                        <Typography fontSize={11} variant="body1">Lat: {g.attributes.lat.toFixed(6)}</Typography>
                                    </Col>
                                    <Col span={24}>
                                        <Typography fontSize={11} variant="body1">Lon: {g.attributes.lon.toFixed(6)}</Typography>
                                    </Col>

                                </Row>
                            </Paper>

                        </Col>
                    )
                })}








            </Row>
        );
    }
} 