import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const ReturnHomeButton = ({ onClick }) => {
    return (
        <button
            style={{
                position: 'absolute',
                zIndex: 10,
                backgroundColor: 'white',
                padding: '6px',
                borderRadius: '3px',
                cursor: 'pointer',
                top: '10px',
                right: '10px',
                color: '#666666'
            }}
            onClick={onClick}
        >
            <FontAwesomeIcon icon={faHome} size="lg" />
        </button>
    );
};

export default ReturnHomeButton;
