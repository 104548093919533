import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import AlbumCover from "./albumCover";

import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import emptyAlbumImage from './emptyAlbum.png';
import { updateAlbumImageFunction } from "../../utils/albumFunctions";

const AlbumsList = ({
    screenWidth,
    openSnack,
    userInfo,
    albums,
    filteredAlbums,
    setFilteredAlbums,
    setCurrentAlbum,
    setCurrentParentAlbum,
    albumBreadCrumbs,
    setAlbumBreadCrumbs,
    moveUpDirectory,
    deleteAlbum,
    updateParentId,
    setDragExisting,
    selectedPhotos,
    setSelectedPhotos,
    photos,
    setPhotos,
    updateItems,
    setAlbums,

}) => {


    const props = {
        screenWidth, openSnack, userInfo, albums, setCurrentAlbum,
        setCurrentParentAlbum, albumBreadCrumbs, setAlbumBreadCrumbs, deleteAlbum, moveUpDirectory, updateItems
    };






    // Album component that is draggable
    const DraggableAlbum = ({ album, updateParentId, selectedPhotos, updateItems, ...props }) => {

        const [{ isDragging }, dragRef] = useDrag({
            type: 'album',
            item: { id: album.album_id, type: 'album' },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),

            }),
            end: (item, monitor) => {
                console.log(`end drag: ${album.album_id}`)
                const dropResult = monitor.getDropResult();
                console.log(dropResult)


            },
        });

        // Set up the drop target
        const [{ isOver, canDrop }, dropRef] = useDrop({
            accept: ['album', 'photo'], // Now accepts both album and photo types
            canDrop: (item, monitor) => {
                // Define the logic for when an item can be dropped on this album
                // For example, you might want to prevent dropping an album onto itself
                if (item.type === 'album') {
                    return item.id !== album.album_id;
                }
                // If it's a photo, you may have different logic
                return true;
            },
            drop: async (item, monitor) => {
                console.log(item)
                // Differentiate actions based on type
                if (item.type === 'album') {
                    // Album-specific logic
                    if (monitor.isOver({ shallow: true })) {

                        const albumList = [item.id];
                        const itemsToRemove = [];
                        const itemsToRemain = [];
                        albums.forEach((a) => {
                            if (albumList.includes(a.album_id)) {
                                itemsToRemove.push(a);
                            } else {
                                itemsToRemain.push(a);
                            }
                        });
                        const newAlbums = itemsToRemove.map((a) => {
                            return { ...a, parent_album_id: album.album_id };
                        });
                        console.log(itemsToRemain)
                        console.log(newAlbums)

                        setAlbums(itemsToRemain);

                        updateItems('album', newAlbums);

                    }
                }
                if (item.type === 'photo') {
                    const photosList = [...selectedPhotos, item.id];
                    //remove duplicates from array
                    movePhotosToAlbum(photosList, album)




                }
            },
            collect: monitor => ({
                isOver: monitor.isOver({ shallow: true }),
                canDrop: monitor.canDrop(),
            }),
        });
        const combinedRef = (node) => {
            dragRef(node);
            dropRef(node);
        };


        async function movePhotosToAlbum(photosList, album) {
            const uniquePhotos = [...new Set(photosList)];
            console.log(uniquePhotos)
            const lastPhoto = photos.find((p) => p.photo_id === uniquePhotos[uniquePhotos.length - 1]);
            const newAlbums = [...albums];
            console.log(lastPhoto)
            newAlbums.forEach((a) => {
                if (a.album_id === album.album_id) {
                    a.coverPhoto = lastPhoto.medium;
                }
            });
            const newFilteredAlbums = [...filteredAlbums];
            newFilteredAlbums.forEach((a) => {
                if (a.album_id === album.album_id) {
                    a.coverPhoto = lastPhoto.medium;
                }
            });
            console.log(newFilteredAlbums)
            setFilteredAlbums(newFilteredAlbums)
            console.log(newAlbums)
            setAlbums(newAlbums);



            const photosToRemove = [];
            const photosToRemain = [];
            photos.forEach((photo) => {
                if (uniquePhotos.includes(photo.photo_id)) {
                    photosToRemove.push(photo);
                } else {
                    photosToRemain.push(photo);
                }
            });
            const newPhotos = photosToRemove.map((photo) => {
                return { ...photo, parent_album_id: album.album_id };
            });

            setSelectedPhotos(selectedPhotos.filter((id) => !newPhotos.map((p) => p.photo_id).includes(id)))

            setPhotos(photosToRemain);

            updateItems('photo', newPhotos);
            await updateAlbumImageFunction(album, lastPhoto, userInfo, openSnack)
        }



        return (
            <Grid ref={combinedRef} key={album.album_id} item xs={6} sm={4} md={3}>
                <AlbumCover
                    {...props}
                    movePhotosToAlbum={movePhotosToAlbum}
                    selectedPhotos={selectedPhotos}
                    album={album}
                    state={isOver && canDrop ? 'dropTarget' : isDragging ? 'dragging' : 'idle'}
                    imageSrc={album?.coverPhoto || emptyAlbumImage}
                    title={album.name}
                    // Style based on isDragging
                    style={{ opacity: isDragging ? 0.8 : 1, cursor: 'move', border: isDragging ? '6px solid red' : isOver && canDrop ? '6px solid #1bff0fe6' : '1px solid gray' }}
                />
            </Grid>
        );
    };



    if (filteredAlbums.length === 0) {
        return (
            <Box p={1}>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={12} md={12} sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '10vh'

                    }}>
                        <Typography variant="h3" fontSize={18} fontWeight={700} align="center">
                            No albums found
                        </Typography>
                    </Grid>
                </Grid>
            </Box >
        )
    }
    else {

        return (

            <Box p={1} >



                <Grid container alignItems="center" spacing={2}>
                    {filteredAlbums.map((a, index) => {


                        return (

                            <DraggableAlbum key={a.album_id} album={a}
                                updateParentId={updateParentId}
                                selectedPhotos={selectedPhotos}
                                {...props} title={a.name}
                            />

                        )
                    })}
                </Grid>
            </Box>

        );
    }
};

export default AlbumsList;
