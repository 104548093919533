import React, { useState } from "react";
import { Button, Checkbox, FormControl, IconButton, Input, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronDown, faGripVertical, faPlus, faX } from "@fortawesome/free-solid-svg-icons";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import uuid from "react-uuid";
import { makeStyles } from "@mui/styles";
import ColorPickerButton from "./optionColorPicker";




var randomColor = require('randomcolor');


const useStyles = makeStyles({
    underline: {
        '&:before': {
            borderBottom: 'none', // This removes the default underline
        },
        '&:hover:not(.Mui-disabled):before': {
            borderBottom: 'none' // This ensures the underline doesn't show on hover
        },
        '&:after': {
            borderBottom: 'none' // This ensures the underline doesn't show when the input is focused
        },
        '&.Mui-focused:before': {
            borderBottom: 'none' // This removes the underline for focused input as well
        }
    }
});

const SelectOptions = ({
    screenWidth,
    headers,
    customHeader,
    setCustomHeader,
    type,
    updateOptions
}) => {
    const classes = useStyles();
    const [isHoveredIndex, setIsHoveredIndex] = useState(null);
    const [isFocusedIndex, setIsFocusedIndex] = useState(null);

    const onDragEnd = (result) => {
        if (!result.destination) return;

        const reorderedOptions = Array.from(customHeader.options);
        const [movedItem] = reorderedOptions.splice(result.source.index, 1);
        reorderedOptions.splice(result.destination.index, 0, movedItem);
        setCustomHeader({ ...customHeader, options: reorderedOptions });
    };


    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer


        // change background colour if dragging


        // styles we need to apply on draggables
        ...draggableStyle,
        position: 'relative', // Ensure the positioning is static
        left: 0,  // Reset left position
        top: 0,   // Reset top position
    });

    const getListStyle = isDraggingOver => ({



    });

    return (
        <Row>
            <Col xs={24} sm={24} style={{ marginTop: 20 }}>
                <Row>
                    <Col xs={24}  >
                        <Typography variant="h6" component="div" color={'rgba(0,0,0,0.6)'} fontWeight={600} fontSize={16}>
                            Options
                        </Typography>
                    </Col>
                    <Col xs={24}  >
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="selectOptions">
                                {(provided, snapshot) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        style={getListStyle(snapshot.isDraggingOver)}
                                    >
                                        {customHeader?.options?.map((option, index) => {
                                            const isHovered = isHoveredIndex === option.id;
                                            const isFocused = isFocusedIndex === option.id;

                                            return (
                                                <Draggable key={option.id} draggableId={option.id} index={index}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={getItemStyle(
                                                                snapshot.isDragging,
                                                                provided.draggableProps.style
                                                            )}
                                                        >

                                                            <Row
                                                                key={option.id}
                                                                align={'middle'}
                                                                onMouseEnter={() => setIsHoveredIndex(option.id)}
                                                                onMouseLeave={() => setIsHoveredIndex(null)}
                                                                style={{
                                                                    marginBottom: 0,
                                                                    borderTop: isHovered || isFocused ? '1px solid rgba(0,0,0,0.2)' : 'none',
                                                                    borderBottom: isHovered || isFocused ? '1px solid rgba(0,0,0,0.2)' : 'none',
                                                                    borderColor: isFocused ? 'blue' : 'rgba(0,0,0,0.2)',
                                                                }}
                                                            >
                                                                <Col span={1} style={{ visibility: isHovered ? 'visible' : 'hidden' }}>

                                                                    <FontAwesomeIcon icon={faGripVertical} size="xl"
                                                                        color={'rgba(0,0,0,0.4)'}

                                                                        style={{
                                                                            cursor: 'grab',
                                                                        }} />


                                                                </Col>
                                                                <Col span={23}>
                                                                    <Row
                                                                        align={'middle'}
                                                                        style={{

                                                                            borderBottom: isHovered || isFocused ? 'none' : '1px solid rgba(0,0,0,0.2)',
                                                                            marginBottom: 0
                                                                        }}
                                                                    >

                                                                        <Col span={2}>
                                                                            <ColorPickerButton
                                                                                type={type}
                                                                                initialColor={option.color}
                                                                                disabled={false}
                                                                                onChange={(newColor) => {
                                                                                    const newOptions = [...customHeader?.options];
                                                                                    newOptions[index].color = newColor;
                                                                                    setCustomHeader({ ...customHeader, options: newOptions });
                                                                                }}
                                                                            />
                                                                        </Col>
                                                                        <Col span={18} style={{
                                                                            display: 'flex',
                                                                            alignItems: 'bottom',
                                                                            justifyContent: 'flex-start',

                                                                        }}>
                                                                            <Input
                                                                                onFocus={() => setIsFocusedIndex(option.id)}
                                                                                onBlur={() => setIsFocusedIndex(null)}
                                                                                fullWidth
                                                                                variant="standard"
                                                                                id="string-input"
                                                                                size="small"
                                                                                margin="dense"
                                                                                placeholder="Type an option name"
                                                                                value={option.name}
                                                                                onChange={(e) => {
                                                                                    const newOptions = [...customHeader?.options];
                                                                                    newOptions[index].name = e.target.value;
                                                                                    setCustomHeader({ ...customHeader, options: newOptions });
                                                                                    if (typeof updateOptions === "function") {
                                                                                        updateOptions(newOptions);
                                                                                    }
                                                                                }}
                                                                                classes={{ underline: classes.underline }}
                                                                                sx={{
                                                                                    '& .MuiInputBase-input': {
                                                                                        color: 'black',
                                                                                        fontWeight: 500,
                                                                                        fontSize: 18,
                                                                                        marginBottom: 0,
                                                                                        pb: 0,
                                                                                        borderBottom: 'none',
                                                                                    },
                                                                                }}
                                                                            />
                                                                        </Col>
                                                                        <Col span={2}>
                                                                            <IconButton
                                                                                onClick={() => {
                                                                                    const duplicate = { ...option, id: uuid() };
                                                                                    const newOptions = [...customHeader?.options];
                                                                                    newOptions.splice(index + 1, 0, duplicate);
                                                                                    setCustomHeader({ ...customHeader, options: newOptions });
                                                                                }}
                                                                            >
                                                                                <FontAwesomeIcon icon={faCopy} />
                                                                            </IconButton>
                                                                        </Col>
                                                                        <Col span={2} style={{
                                                                            textAlign: 'right'
                                                                        }}>
                                                                            <IconButton
                                                                                onClick={() => {
                                                                                    const newOptions = [...customHeader?.options];
                                                                                    newOptions.splice(index, 1);
                                                                                    setCustomHeader({ ...customHeader, options: newOptions });
                                                                                }}
                                                                            >
                                                                                <FontAwesomeIcon icon={faX} size="sm" />
                                                                            </IconButton>
                                                                        </Col>




                                                                    </Row>
                                                                </Col>

                                                            </Row>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            );
                                        })}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </Col>
                    <Col xs={24} style={{ marginTop: 20 }}  >
                        <Row>
                            <Col span={16} offset={1} style={{ display: 'inline' }}>
                                <Button
                                    variant="link"
                                    style={{
                                        textTransform: 'none',
                                        color: 'rgba(0,0,0,0.8)',
                                    }}
                                    onClick={() => {
                                        const newOptions = [...customHeader?.options || []];
                                        newOptions.push({
                                            name: '',
                                            color: randomColor(),
                                            id: uuid(),
                                        });
                                        setCustomHeader({ ...customHeader, options: newOptions });
                                    }}
                                >
                                    <FontAwesomeIcon icon={faPlus} color={'rgba(0,0,0,0.8)'} style={{ marginRight: 10 }} />
                                    <Typography variant="h6" component="div" color={'rgba(0,0,0,0.8)'} fontWeight={700} fontSize={16}>
                                        Add an option
                                    </Typography>
                                </Button>
                            </Col>
                        </Row>
                    </Col>

                </Row>
            </Col>
        </Row>
    );
};

export default SelectOptions;
