import { faAngleDoubleLeft, faAngleDoubleRight, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, MenuItem, Select } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { Col, Row } from 'antd';
import React, { useState } from 'react';




const TablePaginationSection = ({
    pageFunctions,
    currentPage,
    data,
    rowsPerPage,
    setRowsPerPage,
    setCurrentPage


}) => {

    const handleRowsPerPageChange = (e) => {
        const newRowsPerPage = parseInt(e.target.value, 10);
        const newTotalPages = Math.ceil(data.length / newRowsPerPage);

        // Ensure currentPage isn't greater than the new total pages
        if (currentPage > newTotalPages) {
            setCurrentPage(newTotalPages); // Assuming setPage function exists
        }

        setRowsPerPage(newRowsPerPage);
    };

    return (
        <div style={{
            display: 'inline',
            alignItems: 'center',

        }}>
            <Typography variant="body1" fontSize={12} fontWeight={500} style={{ display: 'inline' }}>
                Records: {(currentPage - 1) * rowsPerPage + 1} - {currentPage * rowsPerPage > data.length ? data.length : currentPage * rowsPerPage} of {data.length}
            </Typography>

            <IconButton onClick={pageFunctions.handleFirstPage} disabled={currentPage === 1}>
                <FontAwesomeIcon icon={faAngleDoubleLeft} size="sm" style={{
                    color: currentPage === 1 ? '#e0e0e0' : '#3f51b5',
                    cursor: 'pointer'
                }} />
            </IconButton>
            <IconButton onClick={pageFunctions.handlePrevPage} disabled={currentPage === 1}>
                <FontAwesomeIcon icon={faArrowLeft} size="sm" style={{
                    color: currentPage === 1 ? '#e0e0e0' : '#3f51b5',
                    cursor: 'pointer'
                }} />
            </IconButton>
            <Typography variant="body1" fontWeight={500} style={{ display: 'inline' }}>
                Page:
                <Typography variant="body1" fontWeight={700} style={{ display: 'inline', color: '#3f51b5', marginLeft: 5, marginRight: 5, }}>
                    {currentPage}
                </Typography>
                of
                <Typography variant="body1" fontWeight={700} style={{ display: 'inline', color: '#3f51b5', marginLeft: 5 }}>
                    {Math.ceil(data.length / rowsPerPage)}
                </Typography>
            </Typography>
            <IconButton onClick={pageFunctions.handleNextPage} disabled={currentPage === Math.ceil(data.length / rowsPerPage)}>
                <FontAwesomeIcon icon={faArrowRight} size="sm" style={{
                    color: currentPage === Math.ceil(data.length / rowsPerPage) ? '#e0e0e0' : '#3f51b5',
                    cursor: 'pointer'
                }} />
            </IconButton>
            <IconButton onClick={pageFunctions.handleLastPage} disabled={currentPage === Math.ceil(data.length / rowsPerPage)}>
                <FontAwesomeIcon icon={faAngleDoubleRight} size="sm" style={{
                    color: currentPage === Math.ceil(data.length / rowsPerPage) ? '#e0e0e0' : '#3f51b5',
                    cursor: 'pointer'
                }} />
            </IconButton>
            <Select size="small" value={rowsPerPage} onChange={handleRowsPerPageChange} style={{ marginLeft: '10px' }}>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
            </Select>



        </div>
    );
}

export default TablePaginationSection;



