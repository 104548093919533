import { Autocomplete, Button, CircularProgress, Divider, OutlinedInput, Paper, TextField, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import { arrayUnion, collection, doc, getDoc, getDocs, getFirestore, query, setDoc, updateDoc, where } from 'firebase/firestore';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import { createProbeAPIKey } from '../dataStreams/newComponents/settingsDrawer/firebaseFunctions';

export default class CreateCompany extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            type: this.props.type,
            loading: true,
            item: {},
            products: []
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }


    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        const { userInfo } = this.state;
        this.queryDataDoc();


        const db = getFirestore();
        const queryData = query(collection(db, "clients", 'ProducStockTestdb50ba-b17f-3670-33e2-0386c5e34a', 'psItems'), where('psType', '==', 'products'), where('archived', '==', false));
        const data = [];
        const snap = await getDocs(queryData);
        snap.forEach((doc) => {
            data.push({ ...doc.data(), key: uuid() })


        });
        console.log(data);
        this.setState({ products: data })





    }

    queryDataDoc = async () => {
        const { type } = this.state;

        this.setState({ item: { key: uuid(), companyName: '', companyType: '' }, loading: false })

    }

    getDocItem = async (key) => {
        const { userInfo } = this.state;
        const db = getFirestore();
        const docRef = doc(db, "clients", key);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            console.log(docSnap.data())
            this.setState({ item: docSnap.data(), loading: false })
        }
    }



    updateItem = (boo, val) => {
        const { item } = this.state;
        item[boo] = val;

        this.setState({ item })
    }

    render() {

        const { account, type, screenWidth, item, loading, products } = this.state;


        const SubmitButton = () => {
            const { item, userInfo } = this.state;
            let navigate = useNavigate();
            return (
                <Button style={{
                    textTransform: 'none', padding: 16, fontSize: 16,
                    backgroundColor: '#3f51b5', color: 'white', borderRadius: 12, marginTop: 12
                }} fullWidth variant="contained" color="primary"
                    onClick={async () => {

                        const checks = [
                            item.companyName.length === 0 ? false : true,
                            item.companyType.length === 0 ? false : true,
                        ]

                        if (item.companyName.length === 0) {
                            this.props.openSnack('error', "Client name is required")
                        }
                        if (item.companyType.length === 0) {
                            this.props.openSnack('error', "Client type is required!")
                        }

                        if (!checks.includes(false)) {
                            console.log(item);
                            this.setState({ loading: true })

                            const db = getFirestore();
                            item.key = `${item.companyName}${item.key}`

                            const docRef = doc(db, "clients", item.key);
                            await setDoc(docRef, item).then(async () => {
                                this.props.openSnack('success', 'Company Created!')


                                const staffRefE = doc(db, "clients", item.key, 'staff', 'EuEZ93ByFZYs8bSUXoYsTt1zu0m2');
                                await setDoc(staffRefE, { id: 'EuEZ93ByFZYs8bSUXoYsTt1zu0m2', key: 'EuEZ93ByFZYs8bSUXoYsTt1zu0m2', flDistribution: false, adminLevel: 'admin' })
                                const staffRefG = doc(db, "clients", item.key, 'staff', 'eTevYsI9mQMxhtntd95HmRGasNx2');
                                await setDoc(staffRefG, { id: 'eTevYsI9mQMxhtntd95HmRGasNx2', key: 'eTevYsI9mQMxhtntd95HmRGasNx2', flDistribution: false, adminLevel: 'admin' })
                                const staffRefM = doc(db, "clients", item.key, 'staff', 'nKtrg9A7qSSllfNy0zpVyhn1dSc2');
                                await setDoc(staffRefM, { id: 'nKtrg9A7qSSllfNy0zpVyhn1dSc2', key: 'nKtrg9A7qSSllfNy0zpVyhn1dSc2', flDistribution: false, adminLevel: 'admin' })


                                console.log("SEET ALL STAFF")


                            }).then(async () => {
                                const userRef = doc(db, "users", userInfo.id,);


                                console.log("UPDATED USERS")
                                const staffRefE = doc(db, "users", 'EuEZ93ByFZYs8bSUXoYsTt1zu0m2');
                                await updateDoc(staffRefE, {
                                    companies: arrayUnion(item.key)

                                }).then(async () => {
                                    const staffRefG = doc(db, "users", 'eTevYsI9mQMxhtntd95HmRGasNx2');
                                    await updateDoc(staffRefG, {
                                        companies: arrayUnion(item.key)

                                    }).then(async () => {
                                        products.map(async (d, i) => {
                                            const docRef = doc(db, "clients", item.key, 'psItems', d.key,);
                                            await setDoc(docRef, d)

                                        })
                                        console.log("UPDATED PRODUCTS")

                                    }).then(async () => {
                                        const stock = { archived: false, key: uuid(), location: { label: 'General Stock', key: '123' }, name: "General Stock", description: "Initial stock of all your products." }
                                        const docRef = doc(db, "clients", item.key, 'productStock', stock.key,);
                                        await setDoc(docRef, stock)
                                        console.log("ADDED Stocks")
                                        /* await createProbeAPIKey(item.key) */
                                        console.log("ADDED API KEY")

                                    })
                                        .then(() => {
                                            this.props.openSnack('success', 'Company Created!')
                                            this.setState({ item: { key: uuid(), companyName: '', companyType: '' }, loading: false })

                                        })
                                })







                            })


                        }

                    }
                    }>
                    Create Account
                </Button >
            )
        }





        const Loading = (props) => {
            return (
                <div style={{ minHeight: '100vh' }}>
                    <Row justify="space-around" gutter={[32, 8]} align="middle" style={{ paddingTop: 18, }}>

                        <Col span={24} style={{ textAlign: 'center', fontSize: 24, fontFamily: 'Roboto, sans-serif', }}>
                            <CircularProgress />
                        </Col>
                    </Row>
                </div>
            )
        }



        return (
            <Row style={{}}>


                <Col span={23}>
                    <Row align="middle">
                        <Col xs={16} sm={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>
                            <Typography variant="h4">Create Account</Typography>
                        </Col>
                        <Col xs={6} sm={4} style={{ paddingTop: 30, paddingBottom: 15, textAlign: 'right' }}>

                        </Col>
                        <Col span={24}>
                            <Divider />
                        </Col>
                    </Row>
                </Col>





                <Col span={23} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 30 }}>
                    <Paper elevation={12} style={{ padding: 26, borderRadius: 12 }}>
                        {loading ? <Loading /> : <Row gutter={[screenWidth === 'xs' ? 4 : 24, screenWidth === 'xs' ? 4 : 24]} align="middle">
                            <Col xs={24} sm={{ offset: 6, span: 20 }}>
                                <Typography variant="h4">Client</Typography>
                            </Col>
                            <Col xs={24} sm={{ offset: 6, span: 20 }}>

                                <Typography variant="body1">Create a new client below.</Typography>

                            </Col>

                            <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                                <Typography variant="body1">Client Name<span style={{ color: 'red' }}>*</span></Typography>
                            </Col>
                            <Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                <OutlinedInput fullWidth notched={false} label="none" placeholder={"Name"} size="small"
                                    value={item.companyName || ''}
                                    onChange={(e) => {
                                        this.updateItem(`companyName`, e.target.value)
                                    }} />
                            </Col>


                            <Col xs={24} sm={6} style={{ textAlign: screenWidth === 'xs' ? "left" : "right" }}>
                                <Typography variant="body1">Client Type</Typography>
                            </Col>
                            {loading === false && (<Col xs={24} sm={16} style={{ paddingBottom: screenWidth === 'xs' ? 25 : 0 }}>
                                <Autocomplete
                                    size="small"
                                    disableClearable={true}
                                    disablePortal

                                    id="combo-box-demo"
                                    options={[
                                        { label: 'Municipality', key: 'municipality' },
                                        { label: 'District', key: 'district' },
                                        { label: 'Lake Manager', key: 'lakeManager' },
                                        { label: 'Golf Course', key: 'golfCourse' },
                                        { label: 'Winery', key: 'winery' },
                                        { label: 'Citizen Scientist', key: 'citizenScientist' },
                                        { label: 'Irrigation District', key: 'irrigationDistrict' },
                                    ]}
                                    defaultValue={{ label: item.companyType || '' }}
                                    renderInput={(params) => <TextField {...params} label="Client Type" />}
                                    onChange={(a, b) => {
                                        this.updateItem('companyType', b)
                                    }}

                                />
                            </Col>)}





                            <Col xs={24} sm={{ offset: 6, span: 6 }} style={{ textAlign: 'left' }}>
                                {loading ? <CircularProgress /> : <SubmitButton />}
                            </Col>



                        </Row>}
                    </Paper>
                </Col>
            </Row >
        );
    }
} 