import { Map } from '@esri/react-arcgis';
import { loadModules } from 'esri-loader';
import React from 'react';
import SketchLayer from './layer';

loadModules(['esri/config',]) 
  .then(([config,]) => {
    config.apiKey = "AAPK65ed7997d0524b6582730da31dc1f8986spR1Pjc0oR3qn-NVYrj0jI7Y6156OU89gRRDdPUWC1gjMayxwOkEmxo2aoZH56R";
  })
  .catch(err => {
    // handle any errors
    console.error(err);
  });

export default class AppMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      screenWidth: this.props.screenWidth,
      userInfo: this.props.userInfo,
      account: this.props.account,
      graphics: JSON.parse(this.props.item.graphics),
      layerColor: this.props.layerColor,
      item: this.props.item,
      reloadLayer: false,
      printScreen: false
    };
  }

  componentDidUpdate(prevProps) {

    if (this.props.layerColor !== prevProps.layerColor) {

      this.setState({ reloadLayer: true })
      setTimeout(() => {
        this.setState({ layerColor: this.props.layerColor, reloadLayer: false })
      }, 250);
    }
    if (this.props.account !== prevProps.account) {
      this.setState({ account: this.props.account })
    }
    if (this.props.printScreen !== prevProps.printScreen) {
      this.setState({ printScreen: this.props.printScreen })
    }



  }
  componentDidMount() {

    setTimeout(() => {
      this.handleCheck()
    }, 5000);
  }



  handleCheck = () => {
    // Clears running timer and starts a new one each time the user types
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.toggleCheck();
    }, 2000);
  }


  toggleCheck = async () => {
    this.setState({ printScreen: true })

    setTimeout(() => {
      this.setState({ printScreen: false })
    }, 1000);

  }


  render() {

    const { account, graphics, layerColor, userInfo, printScreen, item, } = this.state;




    return (
      <Map className="Map" style={{ width: '95%', height: '300px', borderRadius: 16, }}
        mapProperties={{ basemap: 'arcgis-imagery' }}

        viewProperties={{
          center: [account.lng, account.lat],
          zoom: 16,
          ui: ['zoom', 'sketch']

        }}>



        <SketchLayer map={this.map} view={this.view} layerColor={layerColor}
          graphics={graphics} layer="productArea"

          updateGraphics={(string, lat, lng) => {
            this.props.updateGraphics(string, lat, lng);
            this.handleCheck()

          }}
        />




      </Map>
    );
  }
} 