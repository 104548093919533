


import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import './AddItem.css';
import { addFolder, addFile, getFolderStructure, addOldDocuments, addFiles, getItems } from '../../firebase/firestore';
import { uploadFile } from '../../firebase/storage'; // Make sure to import the uploadFile function
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import uuid from 'react-uuid';
import { Col, Row } from 'antd';
import { Breadcrumbs, Button, createTheme, ThemeProvider, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions } from '@mui/material';
import { Select } from '@mui/material';
import { MenuItem } from '@mui/material';

const AddItem = ({ selectedFolder, refreshFolderStructure, userInfo, openSnack }) => {
  const [itemName, setItemName] = useState('');
  const [itemType, setItemType] = useState('folder');
  const [uploading, setUploading] = useState(false);
  const [uploadModalOpen, setUploadModalopen] = useState(false);


  useEffect(() => {
    checkFolders();
  }, []);

  const checkFolders = async () => {


    const path = selectedFolder === null ? 'root' : selectedFolder;
    const itemFolders = await getItems(userInfo.currentCompany, path, 'folders', userInfo.currentAccount);
    const itemFiles = await getItems(userInfo.currentCompany, path, 'files', userInfo.currentAccount);
    console.log(itemFolders, itemFiles)

    if (itemFolders.length === 0 && itemFiles.length === 0) {
      await addFolder('Reports', 'root', userInfo.currentCompany, userInfo.currentAccount, userInfo.id, uuid());
      await addFolder('Plans', 'root', userInfo.currentCompany, userInfo.currentAccount, userInfo.id, uuid());
      await addFolder('Permits', 'root', userInfo.currentCompany, userInfo.currentAccount, userInfo.id, uuid());
      await addFolder('Inspections', 'root', userInfo.currentCompany, userInfo.currentAccount, userInfo.id, uuid());
      await addFolder('Other', 'root', userInfo.currentCompany, userInfo.currentAccount, userInfo.id, uuid());
      await addOldDocuments(userInfo.currentCompany, userInfo.currentAccount, userInfo.id)
      refreshFolderStructure(selectedFolder);

    }
  };




  const onDrop = async (acceptedFiles) => {

    //for each accepted file, upload to storage and add to firestore


    acceptedFiles.forEach(async (file) => {
      if (itemType === 'files') {

        const folder = selectedFolder === null ? 'root' : selectedFolder;
        const path = `files/${userInfo.currentCompany}/${folder}/${file.name}`;

        try {
          openSnack('success', 'File Uploading...')
          setUploading(true);
          const fileInfo = await uploadFile(file, path);

          //remove file extension from name
          const name = file.name.split('.')[0];
          await addFile(name,
            folder,
            fileInfo.downloadURL,
            userInfo.currentCompany,
            userInfo.currentAccount,
            userInfo.id,
            fileInfo.size,
            uuid());

          refreshFolderStructure(selectedFolder);
          setUploading(false);
          setUploadModalopen(false);
          openSnack('success', 'File Uploaded')
        }
        catch (error) {
          console.log(error)
          openSnack('error', 'File Upload Failed')
        }
      }
    });



  };


  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (itemName.trim() === '') return;

    if (itemType === 'folder') {
      await addFolder(itemName, selectedFolder, userInfo.currentCompany, userInfo.currentAccount, userInfo.id, uuid());
    }
    openSnack('success', 'Folder Created')
    setItemName('');
    refreshFolderStructure(selectedFolder);
    setUploadModalopen(false);
  };


  if (uploading) {
    return (
      <div
        style={{
          height: "95%",
          width: "100%",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <FontAwesomeIcon icon={faSpinner} color="#404050" spin size="1x" />
        </div>
        <div style={{ marginTop: "10px" }}>
          <Typography
            variant="body1"
            fontSize={12}
            fontWeight={400}
            style={{ color: "#404050", textAlign: "center" }}
          >
            Uploading files...
          </Typography>
        </div>
      </div>
    );
  }
  else {

    return (
      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setUploadModalopen(true)}
            style={{
              textTransform: "none",
              backgroundColor: '#404050'
            }}
          >
            Add Item
          </Button>
        </Col>

        <Dialog open={uploadModalOpen} onClose={() => setUploadModalopen(false)}>
          <DialogTitle>Upload</DialogTitle>
          <form
            onSubmit={handleSubmit}
          >
            <DialogContent>
              <DialogContentText>
                Please select an item type
              </DialogContentText>

              <Select
                size="small"
                fullWidth
                id="itemType"
                value={itemType}
                onChange={(e) => setItemType(e.target.value)}
              >
                <MenuItem value="folder">Folder</MenuItem>
                <MenuItem value="files">Files</MenuItem>
              </Select>
            </DialogContent>

            {itemType === 'folder' && (
              <DialogContent>
                <DialogContentText>
                  Please enter a folder name
                </DialogContentText>
                <TextField
                  autoFocus
                  size="small"
                  fullWidth
                  margin="dense"
                  id="itemName"
                  value={itemName}
                  onChange={(e) => setItemName(e.target.value)}

                />
              </DialogContent>
            )}

            {itemType === 'files' && (
              <DialogContent>
                <DialogContentText>
                  Please select files
                </DialogContentText>
                <div
                  className={`dropzone${isDragActive ? ' drag-active' : ''}`}
                  {...getRootProps()}
                >
                  <input {...getInputProps({ multiple: true })} />
                  <Typography variant="body1" fontSize={16} fontWeight={400}
                  >Drag 'n' drop some files here, or click to select files</Typography>
                </div>
              </DialogContent>
            )}


            <DialogActions>
              <Button onClick={() => setUploadModalopen(false)}>Cancel</Button>
              <Button type="submit">Submit</Button>
            </DialogActions>
          </form>
        </Dialog>
      </Row>
    );
  }
};

export default AddItem;
